import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import FilterConstants from '../FilterConstants'
import { exceptionalHeaderModelIds } from 'app/base/Constants'
import { DefaultProps as DEFAULT_VALUES } from '@inzeraty/helpers'

const {
	PRICE_FROM,
	PRICE_TO,
	OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM,
	OPERATING_LEASE_PRICE_WITHOUT_VAT_TO,
	OPERATING_LEASE_ANNUAL_DISTANCE_FROM,
	OPERATING_LEASE_ANNUAL_DISTANCE_TO,
	OPERATING_LEASE_PERIOD_FROM,
	OPERATING_LEASE_PERIOD_TO,
	OPERATING_LEASE_SERVICES_NAME,
	OPERATING_LEASE_INTENDED_FOR_NAME,
	CONDITION_NAME,
	BRANDS_MODELS,
	BRAND_NAME,
	MODEL_NAME,
	AGE_FROM,
	AGE_TO,
	FUEL_NAME,
	TACHOMETER_FROM,
	TACHOMETER_TO,
	VEHICLE_BODY_NAME,
	GEARBOX_NAME,
	PERFORMANCE_FROM,
	PERFORMANCE_TO,
	COUNTRY_OF_ORIGIN_NAME,
	VENDOR_NAME,
	REGION_NAME,
	DISTRICT_NAME,
	DISPLACEMENT_FROM,
	DISPLACEMENT_TO,
	AIR_CONDITIONING_NAME,
	FUEL_CONSUMPTION_FROM,
	FUEL_CONSUMPTION_TO,
	CERTIFIED_PROGRAM_NAME,
	AIRBAGS_FROM,
	AIRBAGS_TO,
	COLOR_NAME,
	CAPACITY_FROM,
	CAPACITY_TO,
	DOORS_FROM,
	DOORS_TO,
	BUS_SEATS_NAME,
	ITEM_AGE_NAME,
	PRICE_OPTION_NAME,
	HISTORY_INFO_NAME,
	EQUIPMENT_NAME,
	DRIVE_NAME,
	OTHER_SPECIFICATION_NAME,
	WEIGHT_FROM,
	WEIGHT_TO,
	BEDS_FROM,
	BEDS_TO,
	MACHINE_HOURS_FROM,
	MACHINE_HOURS_TO,
	PHRASE
} = AdvertListUrlConvertor.constants.URL_APP_PARAMS

const {
	CONDITION,
	AGE,
	PRICE,
	FUEL,
	TACHOMETER,
	VEHICLE_BODY,
	GEARBOX,
	PERFORMANCE,
	COUNTRY_OF_ORIGIN,
	VENDOR,
	DISPLACEMENT,
	AIR_CONDITIONING,
	FUEL_CONSUMPTION,
	CERTIFIED_PROGRAM,
	AIRBAGS,
	COLOR,
	CAPACITY,
	DOORS,
	BUS_SEATS,
	PRICE_OPTION,
	HISTORY_INFO,
	EQUIPMENT,
	DRIVE,
	OTHER_SPECIFICATION,
	WEIGHT,
	BEDS,
	MACHINE_HOURS,
	OPERATING_LEASE_PRICE_WITHOUT_VAT,
	OPERATING_LEASE_ANNUAL_DISTANCE,
	OPERATING_LEASE_PERIOD,
	OPERATING_LEASE_SERVICES,
	OPERATING_LEASE_INTENDED_FOR
} = FilterConstants.formLineIds

const { SELLER_ID } = SellerUrlConvertor.constants.URL_APP_PARAMS

const findOption = (options, valueFromParam = 0, valueToParam) => {
	return options.find(
		({ value_from = 0, value_to }) => value_from === valueFromParam && value_to === valueToParam
	)
}

/**
 * @class
 * Převádí url parametry, týkající se filtrů, na struktury a hodnoty, kterým rozumí filtry (javascript).
 */
export default class FilterFromUrlAppConvertor {
	/**
	 * Vrati objekt s values pro FormLines
	 * checkFixedFromToParams je vhodny treba pro formLines stari vozu, kde vzdy bude definovany parametr 'od' a 'do'
	 * @method checkFixedFromToParams
	 * @param {Object} urlParams
	 * @param {Object} formLineData
	 * @return {Object}
	 */
	static checkFixedFromToParams(urlParams = {}, formLineData = {}) {
		let VALUE_TO, VALUE_FROM

		if (formLineData.name === AGE) {
			VALUE_TO = AGE_TO
			VALUE_FROM = AGE_FROM
		}

		if (!urlParams[VALUE_TO] && urlParams[VALUE_FROM]) {
			const { options = [] } = formLineData
			const option = findOption(options, parseInt(urlParams[VALUE_FROM], 10), undefined)

			return {
				value: {
					value: option ? option.value : options.length,
					valueFrom: option ? option.value_from : Number(urlParams[VALUE_FROM])
				},
				emptyValue: DEFAULT_VALUES.OBJECT
			}
		} else if (urlParams[VALUE_TO] && !urlParams[VALUE_FROM]) {
			const { options = [] } = formLineData
			const option = findOption(options, undefined, parseInt(urlParams[VALUE_TO], 10))

			return {
				value: {
					value: option ? option.value : options.length,
					valueTo: option ? option.value_to : Number(urlParams[VALUE_TO])
				},
				emptyValue: DEFAULT_VALUES.OBJECT
			}
		} else if (urlParams[VALUE_TO] && urlParams[VALUE_FROM]) {
			const { options = [] } = formLineData

			const { value, value_to, value_from } =
				options.find(
					(option) =>
						option.value_to === Number(urlParams[VALUE_TO]) &&
						option.value_from === Number(urlParams[VALUE_FROM])
				) || {}

			return {
				value: {
					value: value ? value : options.length,
					valueTo: value_to ? value_to : Number(urlParams[VALUE_TO]),
					valueFrom: value_from ? value_from : Number(urlParams[VALUE_FROM])
				},
				emptyValue: DEFAULT_VALUES.OBJECT
			}
		}

		return {
			value: {},
			emptyValue: DEFAULT_VALUES.OBJECT
		}
	}

	/**
	 * Vrati objekt s values pro FormLines
	 * checkVariableFromToParams je vhodny pro formLines, kde parametry 'od' a 'do' nebudou vzdy zadane
	 * @method checkVariableFromToParams
	 * @param {Object} urlParams
	 * @param {Object} formLineData
	 * @return {Object}
	 */
	static checkVariableFromToParams(urlParams = {}, formLineData = {}) {
		let VALUE_TO, VALUE_FROM

		if (formLineData.name === PRICE) {
			VALUE_TO = PRICE_TO
			VALUE_FROM = PRICE_FROM
		} else if (formLineData.name === OPERATING_LEASE_PRICE_WITHOUT_VAT) {
			VALUE_TO = OPERATING_LEASE_PRICE_WITHOUT_VAT_TO
			VALUE_FROM = OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM
		} else if (formLineData.name === OPERATING_LEASE_ANNUAL_DISTANCE) {
			VALUE_TO = OPERATING_LEASE_ANNUAL_DISTANCE_TO
			VALUE_FROM = OPERATING_LEASE_ANNUAL_DISTANCE_FROM
		} else if (formLineData.name === OPERATING_LEASE_PERIOD) {
			VALUE_TO = OPERATING_LEASE_PERIOD_TO
			VALUE_FROM = OPERATING_LEASE_PERIOD_FROM
		} else if (formLineData.name === TACHOMETER) {
			VALUE_TO = TACHOMETER_TO
			VALUE_FROM = TACHOMETER_FROM
		} else if (formLineData.name === PERFORMANCE) {
			VALUE_TO = PERFORMANCE_TO
			VALUE_FROM = PERFORMANCE_FROM
		} else if (formLineData.name === DISPLACEMENT) {
			VALUE_TO = DISPLACEMENT_TO
			VALUE_FROM = DISPLACEMENT_FROM
		} else if (formLineData.name === FUEL_CONSUMPTION) {
			VALUE_TO = FUEL_CONSUMPTION_TO
			VALUE_FROM = FUEL_CONSUMPTION_FROM
		} else if (formLineData.name === AIRBAGS) {
			VALUE_TO = AIRBAGS_TO
			VALUE_FROM = AIRBAGS_FROM
		} else if (formLineData.name === CAPACITY) {
			VALUE_TO = CAPACITY_TO
			VALUE_FROM = CAPACITY_FROM
		} else if (formLineData.name === DOORS) {
			VALUE_TO = DOORS_TO
			VALUE_FROM = DOORS_FROM
		} else if (formLineData.name === WEIGHT) {
			VALUE_TO = WEIGHT_TO
			VALUE_FROM = WEIGHT_FROM
		} else if (formLineData.name === BEDS) {
			VALUE_TO = BEDS_TO
			VALUE_FROM = BEDS_FROM
		} else if (formLineData.name === MACHINE_HOURS) {
			VALUE_TO = MACHINE_HOURS_TO
			VALUE_FROM = MACHINE_HOURS_FROM
		}

		if (!urlParams[VALUE_FROM] && urlParams[VALUE_TO]) {
			const { options = [] } = formLineData
			const option = findOption(options, undefined, parseInt(urlParams[VALUE_TO], 10))

			return {
				value: {
					value: option ? option.value : options.length,
					valueTo: option ? option.value_to : parseInt(urlParams[VALUE_TO], 10)
				},
				emptyValue: DEFAULT_VALUES.OBJECT
			}
		} else if (urlParams[VALUE_FROM] && !urlParams[VALUE_TO]) {
			const { options = [] } = formLineData
			const option = findOption(options, parseInt(urlParams[VALUE_FROM], 10))

			return {
				value: {
					value: option ? option.value : options.length,
					valueFrom:
						option && option.value_from ? option.value_from : parseInt(urlParams[VALUE_FROM], 10)
				},
				emptyValue: DEFAULT_VALUES.OBJECT
			}
		} else if (urlParams[VALUE_FROM] && urlParams[VALUE_TO]) {
			const parsedUrlParamValueFrom = parseInt(urlParams[VALUE_FROM], 10)
			const parsedUrlParamValueTo = parseInt(urlParams[VALUE_TO], 10)

			const { options = [] } = formLineData

			const option = findOption(options, parsedUrlParamValueFrom, parsedUrlParamValueTo)

			return {
				value: {
					value: option ? option.value : options.length,
					valueFrom: option && option.value_from ? option.value_from : parsedUrlParamValueFrom,
					valueTo: option ? option.value_to : parsedUrlParamValueTo
				},
				emptyValue: DEFAULT_VALUES.OBJECT
			}
		}

		return {
			value: {},
			emptyValue: DEFAULT_VALUES.OBJECT
		}
	}

	/**
	 * Vrati objekt s values pro FormLines
	 * @method checkNamedParams
	 * @param {Object} urlParams
	 * @param {Object} formLineData
	 * @return {Object}
	 */
	static checkNamedParams(urlParams = {}, formLineData = {}) {
		let NAME_VALUE

		if (formLineData.name === CONDITION) {
			NAME_VALUE = CONDITION_NAME
		} else if (formLineData.name === OPERATING_LEASE_SERVICES) {
			NAME_VALUE = OPERATING_LEASE_SERVICES_NAME
		} else if (formLineData.name === OPERATING_LEASE_INTENDED_FOR) {
			NAME_VALUE = OPERATING_LEASE_INTENDED_FOR_NAME
		} else if (formLineData.name === FUEL) {
			NAME_VALUE = FUEL_NAME
		} else if (formLineData.name === VEHICLE_BODY) {
			NAME_VALUE = VEHICLE_BODY_NAME
		} else if (formLineData.name === GEARBOX) {
			NAME_VALUE = GEARBOX_NAME
		} else if (formLineData.name === COUNTRY_OF_ORIGIN) {
			NAME_VALUE = COUNTRY_OF_ORIGIN_NAME
		} else if (formLineData.name === VENDOR) {
			NAME_VALUE = VENDOR_NAME
		} else if (formLineData.name === AIR_CONDITIONING) {
			NAME_VALUE = AIR_CONDITIONING_NAME
		} else if (formLineData.name === CERTIFIED_PROGRAM) {
			NAME_VALUE = CERTIFIED_PROGRAM_NAME
		} else if (formLineData.name === COLOR) {
			NAME_VALUE = COLOR_NAME
		} else if (formLineData.name === BUS_SEATS) {
			NAME_VALUE = BUS_SEATS_NAME
		} else if (formLineData.name === PRICE_OPTION) {
			NAME_VALUE = PRICE_OPTION_NAME
		} else if (formLineData.name === HISTORY_INFO) {
			NAME_VALUE = HISTORY_INFO_NAME
		} else if (formLineData.name === EQUIPMENT) {
			NAME_VALUE = EQUIPMENT_NAME
		} else if (formLineData.name === DRIVE) {
			NAME_VALUE = DRIVE_NAME
		} else if (formLineData.name === OTHER_SPECIFICATION) {
			NAME_VALUE = OTHER_SPECIFICATION_NAME
		}

		if (urlParams[NAME_VALUE]) {
			const { options = [] } = formLineData

			const values = new Map()
			const namedParams = urlParams[NAME_VALUE].split(',')

			namedParams.forEach((param) => {
				const { value, name } = options.find((option) => option.seo_name === param) || {}

				if (value && name) {
					values.set(value, name)
				}
			})

			return {
				value: values,
				emptyValue: DEFAULT_VALUES.MAP
			}
		} else if (formLineData.name === CONDITION && !urlParams[NAME_VALUE] && !urlParams[SELLER_ID]) {
			// nastavime defaultne hodnoty pre filter Stav vozu (Nove, Ojete, Predvadeci), pokud nejde o stranku prodejce
			const defaultValues = Array.from(FilterConstants.defaultConditionValues).map((value) => {
				const { options = [] } = formLineData
				const { name } = options.find((o) => o.value === value) || {}

				return [value, name]
			})

			return {
				value: new Map(defaultValues),
				emptyValue: DEFAULT_VALUES.MAP
			}
		} else {
			return {
				value: new Map(),
				emptyValue: DEFAULT_VALUES.MAP
			}
		}
	}

	/**
	 * Vrati data znacky/modelu pro FormLines
	 * @method checkBrandModelParams
	 * @param {Object} urlParams
	 * @param {Object} formLineData
	 * @return {Object}
	 */
	static checkBrandModelParams(urlParams = {}, formLineData = {}) {
		const urlBrandName = urlParams[BRAND_NAME]
		const urlBrandsModels = urlParams[BRANDS_MODELS]

		if (urlBrandName) {
			const { options = [] } = formLineData
			const value = new Map()
			const model = new Set()

			const { value: brandValue, children: brandModels } =
				options.find((option) => option.seo_name === urlBrandName) || {}

			if (brandValue) {
				if (urlParams[MODEL_NAME]) {
					const { value: modelValue } =
						brandModels.find((brandModel) => brandModel.seo_name === urlParams[MODEL_NAME]) || {}

					if (modelValue) {
						model.add(modelValue)
					}
				}

				value.set(brandValue, model)
			}

			return {
				value,
				emptyValue: DEFAULT_VALUES.MAP
			}
		} else if (urlBrandsModels) {
			return {
				value: FilterFromUrlAppConvertor.convertBrandModelIdsStringToMap(
					urlBrandsModels,
					formLineData.options
				),
				emptyValue: DEFAULT_VALUES.MAP
			}
		} else {
			return {
				value: new Map(),
				emptyValue: DEFAULT_VALUES.MAP
			}
		}
	}

	/**
	 * Vrati data fráze FormLines
	 * @method checkPhraseParams
	 * @param {Object} urlParams
	 * @param {Object} formLineData
	 * @return {Object}
	 */
	static checkPhraseParams(urlParams = {}, formLineData = {}) {
		return {
			value: urlParams[PHRASE] || '',
			emptyValue: ''
		}
	}

	/**
	 * Vrati data kraje/okresu pro FormLines
	 * @method checkRegionDistrictParams
	 * @param {Object} urlParams
	 * @param {Object} formLineData
	 * @return {Object}
	 */
	static checkRegionDistrictParams(urlParams = {}, formLineData = {}) {
		const regionSeoNames = (urlParams[REGION_NAME] || '').split(',')
		const districtSeoNames = (urlParams[DISTRICT_NAME] || '').split(',')

		const { options: regions = [] } = formLineData
		const values = new Map()

		regionSeoNames.forEach((regionSeoName) => {
			const region = regions.find((region) => region.seo_name === regionSeoName)

			if (region) {
				const { value: regionValue } = region
				values.set(regionValue, new Set())
			}
		})

		const districtsMap = new Map()

		regions.forEach((region) => {
			const { value: regionValue, children: districts } = region

			districts.forEach((district) => {
				const { seo_name: districtSeoName } = district
				districtsMap.set(districtSeoName, Object.assign({}, district, { regionValue }))
			})
		})

		districtSeoNames.forEach((districtsSeoName) => {
			const districtWithRegionValue = districtsMap.get(districtsSeoName)

			if (districtWithRegionValue) {
				const { regionValue, value: districtValue } = districtWithRegionValue

				if (values.has(regionValue)) {
					const districts = values.get(regionValue)
					const newDistricts = new Set(districts)

					newDistricts.add(districtValue)

					values.set(regionValue, newDistricts)
				} else {
					values.set(regionValue, new Set([districtValue]))
				}
			}
		})

		return {
			value: values,
			emptyValue: DEFAULT_VALUES.MAP
		}
	}

	/**
	 * Převede řetězec s idečky značek a modelů na mapu ideček.
	 *
	 * @static
	 * @method convertBrandModelIdsStringToMap
	 * @param {String} brandsModels Formát: "z1:z1m1,z1m2|z2:z2m1,z2m2".
	 * @param {Array}  options
	 * @return {Map} Mapa ideček značek a modelů.
	 */
	static convertBrandModelIdsStringToMap(brandsModels = '', options = []) {
		const brandsModelsArray = brandsModels
			.split('|')
			.map((brandModels) => {
				const brandModelsSplit = brandModels.split(':')
				const brandId = Number(brandModelsSplit[0])
				const brandOption = options.find((option) => option.value === brandId)

				if (brandOption) {
					const { children = [] } = brandOption
					const modelIds = (brandModelsSplit[1] || '')
						.split(',')
						.map((modelId) => Number(modelId))
						.filter((modelId) => children.find((option) => option.value === modelId))

					return [brandId, new Set(modelIds)]
				} else {
					return undefined
				}
			})
			.filter((brandModels) => !!brandModels)

		return new Map(brandsModelsArray)
	}

	/**
	 * Převede mapu ideček značek a modelů na mapu názvů.
	 *
	 * @static
	 * @method convertBrandModelIdsToNames
	 * @param {Map}   brandsModels Mapa ideček značek a modelů.
	 * @param {Array} options
	 * @return {Map} Mapa názvů značek a modelů.
	 */
	static convertBrandModelIdsToNames(brandsModels = new Map(), options = []) {
		const brandsModelsNames = Array.from(brandsModels).map((brandModels) => {
			const { name, children } = options.find((option) => option.value === brandModels[0])
			const models = Array.from(brandModels[1])
				// - pro vyjimecne modely zobrazime jmeno hlavni kategorie a vyrobce
				.filter((model) => exceptionalHeaderModelIds.indexOf(model) === -1)
				.map((model) => {
					const option = children.find((option) => option.value === model)
					return option ? option.name : ''
				})

			return [name, new Set(models)]
		})

		return new Map(brandsModelsNames)
	}

	/**
	 * Vrátí jednoduchý seznam seo názvů vybraných značek a modelů.
	 *
	 * @static
	 * @method getSeoNamesBrandsModels
	 * @param {Map}   brandsModels Mapa ideček značek a modelů.
	 * @param {Array} options
	 * @return {Array<String>} Seznam seo názvů značek a modelů v jednoduchém seznamu.
	 */
	static getSeoNamesBrandsModels(brandsModels = new Map(), options = []) {
		const result = []

		Array.from(brandsModels).forEach((brandModels) => {
			const { seoName, children } = options.find((option) => option.value === brandModels[0])

			// eslint-disable-next-line no-undef
			if (!onlyModel) {
				result.push(seoName)
			}

			Array.from(brandModels[1]).forEach((model) => {
				result.push(`${seoName}-${children.find((option) => option.value === model).seoName}`)
			})
		})

		return result
	}

	/**
	 * Vrátí jednoduchý seznam seo názvů vybraných značek
	 *
	 * @static
	 * @method getSeoNamesBrands
	 * @param {Map}   brands Mapa ideček značek
	 * @param {Array} options
	 * @return {Array<String>} Seznam seo názvů značek jednoduchém seznamu.
	 */
	static getSeoNamesBrands(brands = new Map(), options = []) {
		const result = []

		Array.from(brands).forEach((brand) => {
			const { seoName } = options.find((option) => option.value === brand[0])

			//zmena znaku na pozadavek od reklamy
			result.push(seoName.replace('-', '_'))
		})

		return result
	}

	/**
	 * Vrátí jednoduchý seznam seo názvů vybraných modelů.
	 *
	 * @static
	 * @method getSeoNamesModels
	 * @param {Map}   models Mapa ideček modelů.
	 * @param {Array} options
	 * @return {Array<String>} Seznam seo názvů modelů v jednoduchém seznamu.
	 */
	static getSeoNamesModels(models = new Map(), options = []) {
		const result = []

		Array.from(models).forEach((model) => {
			const { seoName, children } = options.find((option) => option.value === model[0])

			Array.from(model[1]).forEach((name) => {
				result.push(`${seoName}-${children.find((option) => option.value === name).seoName}`)
			})
		})

		return result
	}

	static checkItemAgeParams(urlParams = {}, formLineData = {}) {
		const { options = [] } = formLineData

		const selectedOption = options.find((option) => option.seo_name === urlParams[ITEM_AGE_NAME])

		if (selectedOption) {
			return {
				value: {
					value: selectedOption.value
				},
				emptyValue: DEFAULT_VALUES.OBJECT
			}
		} else {
			return {
				value: {},
				emptyValue: DEFAULT_VALUES.OBJECT
			}
		}
	}
}
