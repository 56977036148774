import { FORM_LINES_IDS as BASE_FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'

export const FORM_LINES_IDS = Object.assign({}, BASE_FORM_LINES_IDS, {
	REVIEW_TITLE: 'title',
	REVIEW_TYPE: 'review_type',
	REVIEW_DECRIPTION: 'review_description',
	POSITIVES: 'positives',
	NEGATIVES: 'negatives'
})

export const ALTERNATIVE_FORM_LINES_IDS = {
	MANUFACTURING_YEAR: 'manufacturing_year',
	ENGINE_POWER: 'engine_power'
}

export const FORM_LINES_NAMES = {
	CATEGORY_ID: 'category_id',
	MANUFACTURER_CB: 'manufacturer_cb',
	MODEL_CB: 'model_cb',
	MANUFACTURING_YEAR: 'manufacturing_year',
	FUEL_CB: 'fuel_cb',
	ENGINE_POWER: 'engine_power',
	REVIEW_TITLE: 'title',
	REVIEW_TYPE: 'type',
	REVIEW_DECRIPTION: 'review',
	POSITIVES: 'positives',
	NEGATIVES: 'negatives'
}
