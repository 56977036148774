import React from 'react'
import classnames from 'classnames'
import { Radio } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import PAYMENT_STATE_KEYS from '../../PaymentStateKeys'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import PaymentPlaceholders from '../placeholders/PaymentPlaceholders'
import PropTypes from 'prop-types'
import PaymentPropTypes from 'app/model/payment/PaymentPropTypes'

import './PublicationLengthCS.json'
import './PublicationLength.less'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-publication-length'

const PublicationLength = (props) => {
	const localize = useLocalize()

	const {
		payments = [],
		publicationLength,
		paymentType = '',
		setPublicationLength,
		isSms14daysPaymentAvailable = false
	} = props

	// zoradime platby od najdlhsej
	const paymentTypes = payments
		.sort((a, b) => b.advertPublicationLength - a.advertPublicationLength)
		.filter((payment) => payment.paymentType === paymentType)

	// pre typ platby SMS namokujeme dlzku 14 dni a za 119 Kc a zaroven to bude disabled
	// to neplati pre kategorie motorky a ctyrkolky
	if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS && !isSms14daysPaymentAvailable) {
		const smsMockType = {
			advertPublicationLength: PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.TWO_WEEKS,
			price: '119',
			paymentType: PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS,
			credit: undefined
		}

		paymentTypes.unshift(smsMockType)
	}

	const renderRadio = (payment) => {
		const { advertPublicationLength, price, paymentType } = payment
		let labelValue
		if (advertPublicationLength) {
			labelValue = localize('PublicationLength.length', {
				LENGTH: advertPublicationLength,
				PRICE: price,
				CURRENCY: localize('Base.CZK')
			})
		} else {
			labelValue = localize('PublicationLength.top', {
				PRICE: price,
				CURRENCY: localize('Base.CZK')
			})
		}

		const isSelected = advertPublicationLength === publicationLength
		const isRadioDisabled =
			paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS &&
			advertPublicationLength === PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.TWO_WEEKS &&
			!isSms14daysPaymentAvailable
		const key = `${paymentType}__${price}__${advertPublicationLength}`

		return (
			<Radio
				key={key}
				className={classnames({
					[`${CLASSNAME}__item`]: true,
					[`${CLASSNAME}__item--active`]: isSelected
				})}
				label={
					<span className='notranslate' translate='no' lang='cs-cz'>
						{labelValue}
					</span>
				}
				value={advertPublicationLength}
				checked={isSelected}
				disabled={isRadioDisabled}
				data-e2e={`publication-length-${advertPublicationLength}-days`}
				onChange={(event) => {
					event.stopPropagation()
					setPublicationLength(advertPublicationLength)
				}}
			/>
		)
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__header`}>{localize('PublicationLength.header')}</div>
			<div className={`${CLASSNAME}__list`}>
				{paymentTypes.length > 0 ? (
					paymentTypes.map((payment) => renderRadio(payment))
				) : (
					<PaymentPlaceholders />
				)}
			</div>
		</div>
	)
}

const selectors = (state) => ({
	payments: state[PAYMENT_STATE_KEYS.PAYMENTS],
	paymentType: state[PAYMENT_STATE_KEYS.PAYMENT_TYPE],
	publicationLength: state[PAYMENT_STATE_KEYS.PUBLICATION_LENGTH],
	setPublicationLength: state[PAYMENT_STATE_KEYS.SET_PUBLICATION_LENGTH],
	isSms14daysPaymentAvailable: state[PAYMENT_STATE_KEYS.IS_SMS_14_DAYS_PAYMENT_AVAILABLE]
})

PublicationLength.propTypes = {
	payments: PropTypes.arrayOf(PropTypes.shape(PaymentPropTypes)),
	publicationLength: PropTypes.number,
	paymentType: PropTypes.string,
	setPublicationLength: PropTypes.func,
	isSms14daysPaymentAvailable: PropTypes.bool
}

export default React.memo(select(selectors)(PublicationLength))
