import PropTypes from 'prop-types'

const OperatingLeasePropTypes = {
	id: PropTypes.number.isRequired,
	period: PropTypes.number.isRequired,
	annualDistance: PropTypes.number.isRequired,
	servicesCb: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.number.isRequired
		})
	),
	additionalInfo: PropTypes.string,
	priceWithVat: PropTypes.number.isRequired,
	priceWithoutVat: PropTypes.number.isRequired
}

export default OperatingLeasePropTypes

const OperatingLeaseDefinitionPropTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		})
	),
	required: PropTypes.bool,
	text: PropTypes.string,
	unit: PropTypes.string
}

export { OperatingLeaseDefinitionPropTypes as operatingLeaseDefinitionPropTypes }

const IntendedForPropTypes = {
	name: PropTypes.string,
	seoName: PropTypes.string,
	value: PropTypes.number
}

export { IntendedForPropTypes as intendedForPropTypes }
