import AbstractNewAdvertAdminView from '../AbstractNewAdvertAdminView'
import React from 'react'
import HistoryForm from './component/historyForm/HistoryForm'

import './HistoryView.less'
import './HistoryViewCS.json'

const CLASSNAME = 'p-uw-history'

/**
 * Stranka pro vlozeni noveho inzeratu (historie).
 * @class HistoryView
 * @namespace app.page.userweb.newAdvert
 * @extends app.page.userweb.newAdvert.AbstractNewAdvertAdminView
 * @module app
 * @submodule app.page
 */
export default class HistoryView extends AbstractNewAdvertAdminView {
	constructor(props, context) {
		super(props, context)
	}

	_getLayoutProps(defaultLayoutProps) {
		return Object.assign({}, defaultLayoutProps, {
			className: CLASSNAME
		})
	}

	_renderContent() {
		return (
			<div className={`${CLASSNAME}__content`}>
				<HistoryForm />
			</div>
		)
	}
}
