import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import Context from 'ima/page/context'
import SspHelper from 'app/helpers/sspHelper/SspHelper'
import { RESPONSIVE } from 'app/base/Constants'
import SspCollocationOptions from 'app/helpers/sspCollocation/SspCollocationOptions'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import SspContext from '../SspContext'
import ROUTE_NAMES from 'app/base/RouteNames'
import classnames from 'classnames'
import { useHideAdvert } from 'app/component/sspAdverts/sspHelpers'

import './Rectangle.less'

const CLASSNAME = 'c-ssp-rectangle'
const ID = 'footer-rectangle'

const Rectangle = () => {
	const context = useContext(Context)
	const sspContextData = useContext(SspContext)
	const userEntity = useContext(UserEntityContext)

	// priznak, zda si uzivatel plati "Seznam bez reklam" (SBR)
	const isSbrUser = userEntity?.isSbr

	const sspHelper = context.SspHelper
	const router = context.$Router

	const advertRef = useRef(null)
	const advertComponentRef = useRef(null)

	const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true)
	const [isAdFull, setIsAdFull] = useState(false)

	const hideAdvert = useHideAdvert()

	useEffect(() => {
		setIsPlaceholderVisible(sspHelper.isEnabled())
		getAdForSsp(sspContextData)
	}, [sspContextData])

	const getAdForSsp = (pageData) => {
		const { current } = advertRef

		if (current) {
			sspHelper.cleanElement(current)
			current.classList.remove(SspHelper.adFullClassName)
		}

		const { matches } = window.matchMedia(`(min-width: ${RESPONSIVE.TABLET / 16}em)`)
		const options = SspCollocationOptions.getOptions(pageData)

		if (matches && typeof options !== 'undefined') {
			getAd(options)
		} else {
			setIsPlaceholderVisible(false)
		}
	}

	const getZoneId = () => {
		const { route = {} } = router.getCurrentRouteInfo()
		const routeName = route.getName()

		let zoneId = 55014 // detail prodejce, seznam prodejcu, ...

		if (routeName === ROUTE_NAMES.USERWEB.HOMEPAGE) {
			zoneId = 237546
		} else if (
			routeName === ROUTE_NAMES.USERWEB.ADVERT_DETAIL ||
			routeName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL ||
			routeName === ROUTE_NAMES.USERWEB.SELLER ||
			routeName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES ||
			routeName === ROUTE_NAMES.USERWEB.SELLER_OLD
		) {
			zoneId = 71260
		}

		return zoneId
	}

	const getAd = (options = {}) => {
		const zoneId = getZoneId()

		sspHelper.getAds([
			{
				zoneId,
				id: ID,
				width: 970,
				height: 310,
				options,
				callback: (advert, zone) => {
					const { current: elem } = advertComponentRef

					if (advert.type !== 'empty' && elem) {
						setIsAdFull(true)
						elem.style.maxWidth = `${advert.width}px`
					}

					//Prvne tomu nastavime adFull (minimalni vysku a maximalni sirku), potom zavolame writeAd... kdyz toto bylo nad nastavením těchto parametrů, rozstřelila se reklama
					setIsPlaceholderVisible(false)
					sspHelper.writeAd(advert, zone)
				}
			}
		])
	}

	if (isSbrUser) return null

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}__placeholder`]: isPlaceholderVisible && !hideAdvert,
				[SspHelper.adFullClassName]: isAdFull
			})}
			ref={advertComponentRef}
		>
			<div className={`${CLASSNAME}__wrap`}>
				<div ref={advertRef} id={ID}></div>
			</div>
		</div>
	)
}

export default memo(Rectangle)
