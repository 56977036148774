import React, { useContext } from 'react'
import HomeUrlConvertor from 'app/page/userweb/home/HomeUrlConvertor'
import { useLocalize } from 'app/base/componentHelpers'
import ImaContext from 'ima/page/context'
import RouteNames from 'app/base/RouteNames'
import NavButton, { BUTTON_TYPE } from 'app/component/navButton/NavButtonView'
import classnames from 'classnames'
import select from 'ima-plugin-select'
import BackToHpExtension from './BackToHpExtension'
import PropTypes from 'prop-types'

import './BackToHpButton.less'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-back-to-hp-btn'

const BackToHpButton = (props) => {
	const { className = '', categorySeoName = '' } = props

	const localize = useLocalize()
	const context = useContext(ImaContext)
	const router = context.$Router

	const seoName = HomeUrlConvertor.getCategorySeoNameForAppParams(categorySeoName)
	const url = router.link(RouteNames.USERWEB.HOMEPAGE, {
		[HomeUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_SEO_NAME]: seoName
	})

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<NavButton text={localize('Base.goBackHome')} url={url} type={BUTTON_TYPE.BACK} />
		</div>
	)
}

const BackToHpButtonSelect = (state) => ({
	categorySeoName: state[BackToHpExtension.STATE_KEYS.CATEGORY_SEO_NAME]
})

BackToHpButton.propTypes = {
	className: PropTypes.string,
	categorySeoName: PropTypes.string
}

export default select(BackToHpButtonSelect)(React.memo(BackToHpButton))
