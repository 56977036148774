import deepEqual from 'fast-deep-equal'
import * as FormLines from '@inzeraty/form-lines'
import RegionService from 'app/model/region/RegionService'
import ClientBaseController from 'app/base/ClientBaseController'
import STATE_KEYS from './ClientAdvertListStateKeys'
import ClientAdvertListUrlConvertor from './ClientAdvertListUrlConvertor'
import {
	STATUS_CONSTANTS,
	DEACTIVATION_REASON,
	APPROVAL_ADMIN
} from 'app/model/advert/AdvertConstants'
import { ACTION_TYPE } from './advertActions/AdvertActionsConstants'
import ClientAdminSortConstants from './ClientAdminSortConstants'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import {
	PREMISE_FORM_LINES_IDS,
	getPremiseFiltersFormLines
} from './premiseFilters/PremiseFiltersFormLines'
import { CATEGORIES } from 'app/base/Constants'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import UserwebBaseController from 'app/base/UserwebBaseController'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { UserService } from '@inzeraty/models'

import 'app/base/ActionMessageCS.json'

class ClientAdvertListController extends ClientBaseController {
	constructor(
		dependenciesHelper,
		advertService,
		premiseService,
		categoryService,
		codebooksService,
		paymentService,
		pageLoaderExtension,
		advertStatisticsService
	) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._advertService = advertService
		this._premiseService = premiseService
		this._categoryService = categoryService
		this._codebooksService = codebooksService
		this._paymentService = paymentService
		this._pageLoaderExtension = pageLoaderExtension
		this._advertStatisticsService = advertStatisticsService

		this._changePremiseFiltersFormLines = this._changePremiseFiltersFormLines.bind(this)
		this._loadPremiseAdvertsTotal = this._loadPremiseAdvertsTotal.bind(this)
		this._loadAllBrandsForCategory = this._loadAllBrandsForCategory.bind(this)
		this._loadAllModelsForBrand = this._loadAllModelsForBrand.bind(this)
		this._updatePremiseInfo = this._updatePremiseInfo.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._pageLoaderExtension)

		this._pageLoaderExtension.show()
	}

	load() {
		const superState = super.load()
		const { [UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfState } = superState

		return Object.assign(superState, {
			[STATE_KEYS.IS_OPERATING_LEASE]: this._isOperatingLease(),
			[STATE_KEYS.IS_LOADING]: true,
			[STATE_KEYS.FILTER_STATUS]: this._getFilterStatus(),
			[STATE_KEYS.SELECTED_ADVERTS]: new Set(),
			[STATE_KEYS.CHANGE_PREMISE_FILTERS_FORM_LINES]: this._changePremiseFiltersFormLines,
			[STATE_KEYS.LOAD_PREMISE_ADVERTS_TOTAL]: this._loadPremiseAdvertsTotal,
			[STATE_KEYS.LOAD_ALL_BRANDS_FOR_CATEGORY]: this._loadAllBrandsForCategory,
			[STATE_KEYS.LOAD_ALL_MODELS_FOR_BRAND]: this._loadAllModelsForBrand,
			[STATE_KEYS.BANNED_ADVERTS]: this._getBannedAdverts(),
			[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]: this._getPremiseWalletInfo(
				userSelfState
			)
		})
	}

	update() {
		this._pageLoaderExtension.show()

		this._fetchAdvertList()
		this._getPremiseFiltersFormLines()

		return {
			[STATE_KEYS.IS_OPERATING_LEASE]: this._isOperatingLease(),
			[STATE_KEYS.IS_LOADING]: true,
			[STATE_KEYS.FILTER_STATUS]: this._getFilterStatus(),
			[STATE_KEYS.BANNED_ADVERTS]: this._getBannedAdverts()
		}
	}

	activate() {
		super.activate()

		this._pageLoaderExtension.show()

		this._fetchAdvertList({ tryRedirectIfSaleEmpty: true })
		this._getPremiseFiltersFormLines()
	}

	_isOperatingLease() {
		const routeParams = this.getRouteParams()

		return (
			routeParams[ClientAdvertListUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE] ===
			ClientAdvertListUrlConvertor.constants.DEAL_TYPE_OPTIONS.OPERATING_LEASE
		)
	}

	async _redirectIfOperatingLeaseNotEmpty() {
		try {
			const { id, isPremise } = await this._getLoggedEntityInfo()

			if (!isPremise) {
				return
			}

			const newUrlAppParams = Object.assign({}, this.getRouteParams(), {
				[ClientAdvertListUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE]:
					ClientAdvertListUrlConvertor.dealType.OPERATING_LEASE.APP
			})

			const newApiParams = ClientAdvertListUrlConvertor.getApiParams(isPremise, id, newUrlAppParams)

			const advertListOperatingLeaseData = await this._advertService.getAdvertList(newApiParams, {
				cache: false
			})

			const { advertList = [] } = advertListOperatingLeaseData

			if (advertList.length) {
				const { route } = this._router.getCurrentRouteInfo()

				this._router.redirect(this._router.link(route.getName(), newUrlAppParams))
			}
		} catch (e) {
			// na chybu nebudeme nijak reagovat, zustavame na stejne URL
		}
	}

	async _fetchAdvertList({ fetchAlsoStatistics = true, tryRedirectIfSaleEmpty = false } = {}) {
		const fetchStatisticsForAdvertList = async (
			userId,
			isPremise,
			advertListData,
			isOperatingLease = false
		) => {
			const { advertList = [] } = advertListData

			const statisticsForAdverts = advertList.map(({ id }) => id)

			if (statisticsForAdverts.length) {
				const statisticsByAdvert = await this._advertStatisticsService.getStatisticsForListItems(
					Object.assign(
						{
							item_ids: statisticsForAdverts,
							operating_lease: isOperatingLease
						},
						isPremise && { premise_id: userId }
					),
					{ cache: false }
				)

				return statisticsByAdvert
			}
		}

		const { id, isPremise } = await this._getLoggedEntityInfo()
		const urlAppParams = this.getRouteParams()
		const params = ClientAdvertListUrlConvertor.getApiParams(isPremise, id, urlAppParams)

		try {
			if (id) {
				const advertListData = await this._advertService.getAdvertList(params, { cache: false })
				const { advertList = [] } = advertListData

				const tryRedirectToOperatingLeasing =
					tryRedirectIfSaleEmpty &&
					isPremise &&
					advertList.length === 0 &&
					Object.keys(urlAppParams).length === 0

				// pokud jsme na /moje-inzeraty bez jakykoliv dalsich parametru v URL a
				// zaroven bazarnik nema zadne inzeraty na bezny prodej, tak se pokusime
				// presmerovat na vypis s operaky. Ale pouze v pripad, ze nejake operaky
				// ma v nabidce, jinak zustavame na stejne URL.
				if (tryRedirectToOperatingLeasing) {
					await this._redirectIfOperatingLeaseNotEmpty()
				}

				this._pageLoaderExtension.hide()

				this.setState({
					[STATE_KEYS.IS_LOADING]: false,
					[STATE_KEYS.SELECTED_ADVERTS]: new Set(),
					[STATE_KEYS.ADVERT_LIST]: advertListData
				})

				if (fetchAlsoStatistics) {
					this.setState({
						[STATE_KEYS.IS_LOADING_STATISTICS]: true
					})

					try {
						const statisticsByAdvert = await fetchStatisticsForAdvertList(
							id,
							isPremise,
							advertListData,
							this._isOperatingLease()
						)

						this.setState({
							[STATE_KEYS.IS_LOADING_STATISTICS]: false,
							[STATE_KEYS.STATISTICS]: statisticsByAdvert
						})
					} catch (e) {
						// na chybu nebudeme nijak reagovat, nechame zobrazene placeholdery
						// pro statistiky, at uzivatel vidi, ze se neco pokazilo
					}
				}
			} else {
				this.setState({
					[STATE_KEYS.ADVERT_LIST]: undefined,
					[STATE_KEYS.STATISTICS]: undefined
				})
			}
		} catch (e) {
			this.setState({
				[STATE_KEYS.ADVERT_LIST]: undefined,
				[STATE_KEYS.STATISTICS]: undefined
			})
		}

		this._pageLoaderExtension.hide()

		this.setState({
			[STATE_KEYS.IS_LOADING]: false,
			[STATE_KEYS.SELECTED_ADVERTS]: new Set()
		})
	}

	async _getBannedAdverts() {
		const { id, isPremise } = await this._getLoggedEntityInfo()

		if (id) {
			return this._advertService.getAdvertList(
				{
					[isPremise
						? UrlConvertor.constants.URL_API_PARAMS.PREMISE_ID
						: UrlConvertor.constants.URL_API_PARAMS.USER_ID]: id,
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.ADVERT_STATUS]: [
						STATUS_CONSTANTS.INACTIVE,
						STATUS_CONSTANTS.DISABLED
					],
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.APPROVAL_ADMIN]:
						APPROVAL_ADMIN.REJECTED
				},
				{
					cache: false
				}
			)
		}
	}

	async _getLoggedEntityInfo() {
		const userSelfState = await this._getUserSelfState()

		if (UserService.isUserLogged(userSelfState)) {
			const isPremise = await this._isPremise()
			const id = isPremise ? await this._getPremiseId() : userSelfState.id

			return {
				isPremise,
				id
			}
		} else {
			return {}
		}
	}

	_getFilterStatus() {
		const { URL_API_PARAMS: CLIENT_URL_API_PARAMS } = ClientAdvertListUrlConvertor.constants
		const { [CLIENT_URL_API_PARAMS.ADVERT_STATUS]: filterStatus = '' } = this.getRouteParams()

		return filterStatus
	}

	_resetUrl() {
		const baseRouteUrl = this._router.link(this.getRouteName())

		history.replaceState(history.state, '', baseRouteUrl)
	}

	async onSuspend(value) {
		const { URL_API_PARAMS } = ClientAdvertListUrlConvertor.constants

		this._updateStatus(ACTION_TYPE.SUSPEND, value, {
			[URL_API_PARAMS.STATUS.ACTIVE]: false
		})
	}

	async onPublish(value) {
		const { URL_API_PARAMS } = ClientAdvertListUrlConvertor.constants

		this._updateStatus(ACTION_TYPE.PUBLISH, value, {
			[URL_API_PARAMS.STATUS.ACTIVE]: true
		})
	}

	async onRemove(value) {
		const { URL_API_PARAMS } = ClientAdvertListUrlConvertor.constants

		this._updateStatus(ACTION_TYPE.REMOVE, value, {
			[URL_API_PARAMS.STATUS.DELETED]: true
		})
	}

	async onAddToPO(advertId) {
		this._handlePreferredOffer(advertId, true, ACTION_TYPE.ADD_TO_PO)
	}

	async onRemoveFromPO(advertId) {
		this._handlePreferredOffer(advertId, false, ACTION_TYPE.REMOVE_FROM_PO)
	}

	async onAdvertTop({ advertIds, premiseId }) {
		this._pageLoaderExtension.show()

		let messageData

		try {
			await this._paymentService.topPayment({
				payment_type: PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET,
				payment_reason: PAYMENTS_CONSTANTS.PAYMENT_REASON.TOP,
				advert_ids: advertIds,
				premise_id: premiseId
			})

			await this._fetchAdvertList({ fetchAlsoStatistics: false })

			await this._utils.$EventBus.fire(this._utils.$Window.getWindow(), 'getPremiseWalletInfo')

			messageData = {
				type: StatusMessage.TYPE.SUCCESS,
				title: this._dictionary.get('ActionMessage.titleSuccess'),
				text: this._dictionary.get('ActionMessage.toppedSuccess', {
					COUNT: advertIds.length,
					IDS: advertIds.join(', ')
				})
			}
		} catch (error) {
			const { body } = error.getParams ? error.getParams() : {}
			const errors = !isNullOrUndefined(body) ? body.errors : []

			const [toppedError = {}] = errors
			const {
				ERROR: { WALLET }
			} = PAYMENTS_CONSTANTS

			const {
				[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]: walletInfo = {}
			} = this.getState()

			const { error_code: errorCode = '' } = toppedError
			const { walletUserId, credit } = walletInfo

			let reason = ''

			if (errorCode === WALLET.WITHOUT_WALLET) {
				reason = walletUserId
					? this._dictionary.get('ActionMessage.toppedNotActivatedWallet')
					: this._dictionary.get('ActionMessage.toppedNotConnectedWallet')
			} else if (errorCode === WALLET.LOW_CREDIT) {
				reason =
					walletUserId && isNullOrUndefined(credit)
						? this._dictionary.get('ActionMessage.toppedNotActivatedWallet')
						: this._dictionary.get('ActionMessage.toppedLowCredit')
			} else if (errorCode === WALLET.NOT_ACTIVE_ADVERT) {
				reason = this._dictionary.get('ActionMessage.toppedNotActive')
			}

			messageData = {
				type: StatusMessage.TYPE.ERROR,
				title: this._dictionary.get('ActionMessage.titleError'),
				text: this._dictionary.get('ActionMessage.toppedError', {
					COUNT: advertIds.length,
					IDS: advertIds.join(', '),
					REASON: reason
				})
			}
		} finally {
			if (messageData) {
				this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
			}

			this._pageLoaderExtension.hide()
		}
	}

	async _handlePreferredOffer(advertIdValue, value, action) {
		const { URL_API_PARAMS } = ClientAdvertListUrlConvertor.constants
		const advertIdsArr = advertIdValue instanceof Array ? advertIdValue : [advertIdValue]
		let messageData
		let dictData = {
			COUNT: advertIdsArr.length,
			IDS: advertIdsArr.join(', ')
		}

		this._pageLoaderExtension.show()

		try {
			const failIds = await this._advertService.updateAdvert({
				[URL_API_PARAMS.ADVERT_IDS]: advertIdsArr,
				[URL_API_PARAMS.ENTITY]: {
					[URL_API_PARAMS.PREFERRED_OFFER]: value
				}
			})

			await this._fetchAdvertList({ fetchAlsoStatistics: false })

			if (failIds && failIds.length > 0) {
				dictData = {
					COUNT: failIds.length,
					IDS: failIds.join(', ')
				}

				messageData = {
					type: StatusMessage.TYPE.ERROR,
					title: this._dictionary.get('ActionMessage.titleError')
				}

				switch (action) {
					case ACTION_TYPE.ADD_TO_PO:
						messageData.text = this._dictionary.get('ActionMessage.addToPOError', dictData)
						break

					case ACTION_TYPE.REMOVE_FROM_PO:
						messageData.text = this._dictionary.get('ActionMessage.removeFromPOError', dictData)
						break
				}
			} else {
				messageData = {
					type: StatusMessage.TYPE.SUCCESS,
					title: this._dictionary.get('ActionMessage.titleSuccess')
				}

				switch (action) {
					case ACTION_TYPE.ADD_TO_PO:
						messageData.text = this._dictionary.get('ActionMessage.addToPOSuccess', dictData)
						break

					case ACTION_TYPE.REMOVE_FROM_PO:
						messageData.text = this._dictionary.get('ActionMessage.removeFromPOSuccess', dictData)
						break
				}
			}
		} catch (error) {
			messageData = {
				type: StatusMessage.TYPE.ERROR,
				title: this._dictionary.get('ActionMessage.titleError')
			}

			switch (action) {
				case ACTION_TYPE.ADD_TO_PO:
					messageData.text = this._dictionary.get('ActionMessage.addToPOError', dictData)
					break

				case ACTION_TYPE.REMOVE_FROM_PO:
					messageData.text = this._dictionary.get('ActionMessage.removeFromPOError', dictData)
					break
			}
		} finally {
			if (messageData) {
				this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
			}
			this._pageLoaderExtension.hide()
		}
	}

	async _updateStatus(action, value, status) {
		const { URL_API_PARAMS } = ClientAdvertListUrlConvertor.constants
		const advertIds = value instanceof Array ? value : [value]

		let messageData

		this._pageLoaderExtension.show()

		let dictData = {
			COUNT: advertIds.length,
			IDS: advertIds.join(', ')
		}

		try {
			const statusSummary = await this._advertService.updateAdvertStatus({
				...status,
				[URL_API_PARAMS.ADVERT_IDS]: advertIds
			})

			// - zjistim si inzeraty, ktere se podarilo ulozit bez chyb modulu,
			// - napr. se nepodarilo aktivovat z duvodu nedostatku fotek
			const advertsWithoutModuleError = statusSummary.filter(({ errors = [] }) => {
				const [error = ''] = errors
				return !error
			})

			this._fetchAdvertList({ fetchAlsoStatistics: false })

			this._checkMissingModuleCount(statusSummary)
			this._checkInsufficientImagesCount(statusSummary)
			this._checkVinDuplication(statusSummary)
			this._checkVinDuplicationFraud(statusSummary)

			this._updatePremiseInfo()

			if (advertsWithoutModuleError.length) {
				messageData = {
					type: StatusMessage.TYPE.SUCCESS,
					title: this._dictionary.get('ActionMessage.titleSuccess')
				}

				dictData = {
					COUNT: advertsWithoutModuleError.length,
					IDS: advertsWithoutModuleError.map(({ advert_id }) => advert_id).join(', ')
				}

				switch (action) {
					case ACTION_TYPE.SUSPEND:
						messageData.text = this._dictionary.get('ActionMessage.suspendSuccess', dictData)
						break

					case ACTION_TYPE.PUBLISH:
						messageData.text = this._dictionary.get('ActionMessage.publishSuccess', dictData)
						break

					case ACTION_TYPE.REMOVE:
						messageData.text = this._dictionary.get('ActionMessage.removeSuccess', dictData)
						break
				}
			}
		} catch (error) {
			messageData = {
				type: StatusMessage.TYPE.ERROR,
				title: this._dictionary.get('ActionMessage.titleError')
			}

			switch (action) {
				case ACTION_TYPE.SUSPEND:
					messageData.text = this._dictionary.get('ActionMessage.suspendError', dictData)
					break

				case ACTION_TYPE.PUBLISH:
					messageData.text = this._dictionary.get('ActionMessage.publishError', dictData)
					break

				case ACTION_TYPE.REMOVE:
					messageData.text = this._dictionary.get('ActionMessage.removeError', dictData)
					break
			}
		} finally {
			if (messageData) {
				this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
			}

			this._pageLoaderExtension.hide()
		}
	}

	async _updatePremiseInfo() {
		const {
			[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfState,
			[ClientBaseController.STATE_KEYS.PREMISE_INFO]: premiseInfo
		} = this.getState()

		if (premiseInfo) {
			const newPremiseInfo = await this._getPremiseInfo(userSelfState, { cache: false })

			this.setState({
				[ClientBaseController.STATE_KEYS.PREMISE_INFO]: newPremiseInfo
			})
		}
	}

	_checkMissingModuleCount(statusSummary = []) {
		const advertsWithModuleError = statusSummary.filter(({ errors = [] }) => {
			const [error] = errors
			return error === DEACTIVATION_REASON.MODULES
		})

		const missingModuleCount = advertsWithModuleError.length

		if (missingModuleCount) {
			const { [ClientBaseController.STATE_KEYS.PREMISE_INFO]: premiseInfo } = this.getState()
			const { admins } = premiseInfo

			const advertErrorIds = advertsWithModuleError.map(({ advert_id }) => advert_id)

			const messageData = {
				type: StatusMessage.TYPE.WARNING,
				title: this._dictionary.get('ActionMessage.moduleMissTitle'),
				text: this._dictionary.get('ActionMessage.moduleMissBody', {
					COUNT: missingModuleCount,
					IDS: advertErrorIds.join(', ')
				})
			}

			if (admins.length) {
				Object.assign(messageData, {
					links: [
						{
							tagProps: {
								href: `mailto:${admins[0].email}`
							},
							text: this._dictionary.get('ActionMessage.moduleMissLink')
						}
					]
				})
			}

			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
		}
	}

	_checkInsufficientImagesCount(statusSummary = []) {
		const advertsWithImagesError = statusSummary.filter((status) => {
			const { errors = [] } = status
			const [error] = errors

			return error === DEACTIVATION_REASON.IMAGES
		})

		const advertsWithImagesErrorCount = advertsWithImagesError.length

		if (advertsWithImagesErrorCount) {
			const advertErrorIds = advertsWithImagesError.map(({ advert_id }) => advert_id)

			const messageData = {
				type: StatusMessage.TYPE.WARNING,
				title: this._dictionary.get('ActionMessage.insufficientImagesErrorTitle', {
					COUNT: advertsWithImagesErrorCount
				}),
				text: this._dictionary.get('ActionMessage.insufficientImagesErrorBody', {
					COUNT: advertsWithImagesErrorCount,
					IDS: advertErrorIds.join(', ')
				})
			}

			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
		}
	}

	_checkVinDuplication(statusSummary = []) {
		const advertsWithVinDuplicationError = statusSummary.filter((status) => {
			const { errors = [] } = status
			const [error] = errors

			return error === DEACTIVATION_REASON.VIN_DUPLICATION
		})
		const advertsWithVinDuplicationErrorCount = advertsWithVinDuplicationError.length

		if (advertsWithVinDuplicationErrorCount) {
			const advertErrorIds = advertsWithVinDuplicationError.map(({ advert_id }) => advert_id)

			const messageData = {
				type: StatusMessage.TYPE.WARNING,
				title: this._dictionary.get('ActionMessage.vinDuplicationErrorTitle', {
					COUNT: advertsWithVinDuplicationErrorCount
				}),
				text: this._dictionary.get('ActionMessage.vinDuplicationErrorBody', {
					COUNT: advertsWithVinDuplicationErrorCount,
					IDS: advertErrorIds.join(', ')
				})
			}

			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
		}
	}

	_checkVinDuplicationFraud(statusSummary = []) {
		const advertsWithVinDuplicationFraudError = statusSummary.filter((status) => {
			const { errors = [] } = status
			const [error] = errors

			return error === DEACTIVATION_REASON.VIN_DUPLICATION_FRAUD
		})
		const advertsWithVinDuplicationFraudErrorCount = advertsWithVinDuplicationFraudError.length

		if (advertsWithVinDuplicationFraudErrorCount) {
			const advertErrorIds = advertsWithVinDuplicationFraudError.map(({ advert_id }) => advert_id)

			const messageData = {
				type: StatusMessage.TYPE.WARNING,
				title: this._dictionary.get('ActionMessage.vinDuplicationFraudErrorTitle', {
					COUNT: advertsWithVinDuplicationFraudErrorCount
				}),
				text: this._dictionary.get('ActionMessage.vinDuplicationFraudErrorBody', {
					COUNT: advertsWithVinDuplicationFraudErrorCount,
					IDS: advertErrorIds.join(', ')
				})
			}

			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
		}
	}

	onSelect(id) {
		const { [STATE_KEYS.SELECTED_ADVERTS]: selectedAdverts } = this.getState()

		selectedAdverts.add(id)

		this.setState({
			[STATE_KEYS.SELECTED_ADVERTS]: new Set(selectedAdverts)
		})
	}

	onDeselect(id) {
		const { [STATE_KEYS.SELECTED_ADVERTS]: selectedAdverts } = this.getState()

		selectedAdverts.delete(id)

		this.setState({
			[STATE_KEYS.SELECTED_ADVERTS]: new Set(selectedAdverts)
		})
	}

	onSelectAll() {
		const {
			[STATE_KEYS.ADVERT_LIST]: { advertList }
		} = this.getState()

		this.setState({
			[STATE_KEYS.SELECTED_ADVERTS]: new Set(advertList.map(({ id }) => id))
		})
	}

	onDeselectAll() {
		this.setState({
			[STATE_KEYS.SELECTED_ADVERTS]: new Set()
		})
	}

	onSortLinkClick(value) {
		const { route } = this._router.getCurrentRouteInfo()
		const { defaultValue } = ClientAdminSortConstants

		const {
			[UrlConvertor.constants.URL_APP_PARAMS.SORT]: sortParam,
			[UrlConvertor.constants.URL_APP_PARAMS.PAGE]: pageParam,
			...restParams
		} = this.getRouteParams()

		const newParams =
			value === defaultValue
				? restParams
				: {
						...restParams,
						[UrlConvertor.constants.URL_APP_PARAMS.SORT]:
							ClientAdminSortConstants.sortValues[value].APP
				  }

		this._router.redirect(this._router.link(route.getName(), newParams))
	}

	onChangedPerPage(value) {
		const { ...restParams } = this.getRouteParams()
		const { route } = this._router.getCurrentRouteInfo()
		const { URL_APP_PARAMS } = UrlConvertor.constants
		delete restParams[URL_APP_PARAMS.PAGE]
		const newParams = { ...restParams, [URL_APP_PARAMS.PER_PAGE]: value }
		this._router.redirect(this._router.link(route.getName(), newParams))
	}

	onSelectFilterSubmit({ filterBy, filterByValue }) {
		const { route } = this._router.getCurrentRouteInfo()
		const { URL_APP_PARAMS } = ClientAdvertListUrlConvertor.constants

		const restParams = Object.assign({}, this.getRouteParams())

		delete restParams[URL_APP_PARAMS.FILTER_BY]
		delete restParams[URL_APP_PARAMS.FILTER_BY_VALUE]
		delete restParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE]

		const newParams = Object.assign({}, restParams, {
			filterBy,
			filterByValue
		})

		if (
			filterByValue === null ||
			filterByValue === undefined ||
			(typeof filterByValue === 'string' && filterByValue.trim() === '')
		) {
			delete newParams.filterBy
			delete newParams.filterByValue
		}

		this._router.redirect(this._router.link(route.getName(), newParams))
	}

	async _getPremiseFiltersFormLines() {
		const { [ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise } = this.getState()

		const sortAlphabetically = (itemA = {}, itemB = {}) => {
			const { name: nameA = '' } = itemA
			const { name: nameB = '' } = itemB

			return nameA.localeCompare(nameB)
		}

		const getSelectedBrand = (brands = [], selectedBrandValue) =>
			brands.find((brand) => brand.value === Number(selectedBrandValue))

		const { [STATE_KEYS.PREMISE_FILTERS_FORM_LINES]: loadedFormLineEntities } = this.getState()

		if (await isPremise) {
			let formLineEntities = loadedFormLineEntities || getPremiseFiltersFormLines(this._dictionary)

			// pokud uzivatel otevre panel s filtry drive nez se nactou vsechna
			// data, tak at nemusime zobrazovat placeholdery
			this.setState({
				[STATE_KEYS.PREMISE_FILTERS_FORM_LINES]: formLineEntities
			})

			const { URL_APP_PARAMS } = ClientAdvertListUrlConvertor.constants
			const {
				[URL_APP_PARAMS.PREMISE_CATEGORY]: premiseCategoryId,
				[URL_APP_PARAMS.PREMISE_MANUFACTURER]: premiseBrandValue
			} = this.getRouteParams()

			const brands = await this._loadAllBrandsForCategory(premiseCategoryId)

			const selectedBrand = getSelectedBrand(brands, premiseBrandValue)

			const models = await this._loadAllModelsForBrand(
				premiseCategoryId,
				selectedBrand ? selectedBrand.id : undefined
			)

			formLineEntities = FormLines.updateEntities(formLineEntities, [
				{
					id: PREMISE_FORM_LINES_IDS.CATEGORY_SELECT_ID,
					options: Object.values(CATEGORIES).map(({ id, name, seoName }) => ({
						id,
						name,
						seoName
					}))
				},
				{
					id: PREMISE_FORM_LINES_IDS.REGION_SELECT_ID,
					options: RegionService.getRegions().sort(sortAlphabetically)
				},
				{
					id: PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID,
					options: brands
				},
				{
					id: PREMISE_FORM_LINES_IDS.MODEL_AUTO_COMPLETE_ID,
					options: models
				}
			])

			// musi byt volane jako posledni, az se nactou vsechny options pro formliny
			formLineEntities = FormLines.updateEntities(
				formLineEntities,
				ClientAdvertListUrlConvertor.getFormLinesValuesFromUrlAppParams(
					formLineEntities,
					this.getRouteParams()
				)
			)

			this.setState({
				[STATE_KEYS.PREMISE_FILTERS_FORM_LINES]: formLineEntities
			})
		}
	}

	_changePremiseFiltersFormLines(changes, { autoRedirect = false } = {}) {
		const { [STATE_KEYS.PREMISE_FILTERS_FORM_LINES]: formLineEntities = [] } = this.getState()

		const updatedFormLineEntities = FormLines.updateEntities(formLineEntities, changes)

		this.setState({
			[STATE_KEYS.PREMISE_FILTERS_FORM_LINES]: updatedFormLineEntities
		})

		autoRedirect && this._redirectOnPremiseFiltersChange(updatedFormLineEntities)
	}

	async _loadPremiseAdvertsTotal(formLineEntities = []) {
		const getNewApiParams = async () => {
			const id = await this._getPremiseId()

			const newPremiseFilterAppParams = ClientAdvertListUrlConvertor.createAppParamsForFilters(
				formLineEntities,
				this.getRouteParams()
			)

			return ClientAdvertListUrlConvertor.getApiParams(true, id, newPremiseFilterAppParams)
		}

		const params = Object.assign({}, await getNewApiParams(), {
			// dotazeme se jen na celkovou sumu, zadne inzeraty stahovat nebudeme
			[UrlConvertor.constants.URL_API_PARAMS.LIMIT]: 0
		})

		const advertListData = await this._advertService.getAdvertList(params, { cache: false })

		const { paginationEntity: { total } = {} } = advertListData || {}

		return total
	}

	async _loadAllBrandsForCategory(selectedCategoryId) {
		if (selectedCategoryId) {
			const getManufacturerCodebookForCategory = async (selectedCategoryId) => {
				const codebooks = (await this._getCategoryCodebooks(selectedCategoryId)) || []

				return codebooks.find(({ nameId }) => nameId === 'manufacturer_cb') || {}
			}

			try {
				const { id: manufacturerCodebookId } = await getManufacturerCodebookForCategory(
					selectedCategoryId
				)

				const params = {
					codebook_id: manufacturerCodebookId,
					limit: 1000
				}

				return await this._codebooksService.getCodebooks(params)
			} catch (e) {
				return undefined
			}
		}
	}

	async _loadAllModelsForBrand(selectedCategoryId, selectedBrandId) {
		if (selectedCategoryId && selectedBrandId) {
			const getModelCodebookForCategory = async (selectedCategoryId) => {
				const codebooks = (await this._getCategoryCodebooks(selectedCategoryId)) || []

				return codebooks.find(({ nameId }) => nameId === 'model_cb') || {}
			}

			try {
				const { id: modelCodebookId } = await getModelCodebookForCategory(selectedCategoryId)

				const params = {
					codebook_id: modelCodebookId,
					parent_id: selectedBrandId,
					limit: 1000
				}

				return await this._codebooksService.getCodebooks(params)
			} catch (e) {
				return undefined
			}
		}
	}

	async _getCategoryCodebooks(categoryId) {
		const { categoryId: savedCategoryId, codebooks = [] } = this._tmpCategoryCodebooks || {}

		if (categoryId === savedCategoryId) {
			return codebooks
		} else {
			try {
				const codebooks = await this._codebooksService.getCategoryCodebooks(categoryId)

				this._tmpCategoryCodebooks = {
					categoryId,
					codebooks
				}

				return codebooks
			} catch (e) {
				return []
			}
		}
	}

	_redirectOnPremiseFiltersChange(formLineEntities) {
		const routeParams = this.getRouteParams()

		const {
			// strankovani pri zmene filtru chceme vymazat, tim
			// zobrazime zase prvni stranku
			[UrlConvertor.constants.URL_APP_PARAMS.PAGE]: pageParam,
			...restParams
		} = routeParams

		const newParams = ClientAdvertListUrlConvertor.createAppParamsForFilters(
			formLineEntities,
			restParams
		)

		if (!deepEqual(routeParams, newParams)) {
			this._router.redirect(this._router.link(this.getRouteName(), newParams))
		}
	}
}

export default ClientAdvertListController
