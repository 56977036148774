import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { InternalAdminHeader } from 'app/component/layout/internalAdmin/LayoutInternalAdmin'
import { useTouchState } from '@inzeraty/components'
import ImaContext from 'ima/page/context'
import { isInternalAdminRoute } from 'app/base/RouteNames'

import './ErrorLayout.less'

const CLASSNAME = 'c-err-layout'

const ErrorLayout = (props) => {
	const { children } = props
	const isTouch = useTouchState()

	const { $Router } = useContext(ImaContext)

	let routeName
	try {
		routeName = $Router.getCurrentRouteInfo().route.getName()
	} catch (e) {
		routeName = undefined
	}

	return (
		<div className={classnames(CLASSNAME, isTouch && 'touch')}>
			{isInternalAdminRoute(routeName) && <InternalAdminHeader />}
			<div className={`${CLASSNAME}__content`}>{children}</div>
		</div>
	)
}

ErrorLayout.propTypes = {
	children: PropTypes.node
}

export default ErrorLayout
