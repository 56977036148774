
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SellerView'] = {
  backToDetail: function(d) { return "Zpět na detail"; },
  backToList: function(d) { return "Zpět na výpis"; },
  metaTitle: function(d) { return d.NAZEV + " | Sauto"; },
  metaDescription: function(d) { return "Mrkněte na " + d.POCET + " vozidel na prodej od " + d.NAZEV + ". " + d.POPIS; },
  backToSellerList: function(d) { return "Prodejci a autobazary"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	