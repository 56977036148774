import React, { useState, useEffect } from 'react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import ModelAutoComplete from 'app/component/autoComplete/model/ModelAutoComplete'
import Label from '../../label/Label'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const ModelAutoCompleteWidget = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		disabled = false,
		changeFormLineEntities = DEFAULT_PROPS.FUNCTION
	} = props

	const {
		id,
		value: { name: modelName = '' } = {},
		label,
		placeholder,
		options = DEFAULT_PROPS.ARRAY
	} = formLineEntity

	const [searchedModelName, setSearchedModelName] = useState(modelName)

	useEffect(() => {
		const { value: { name: modelName = '' } = {} } = formLineEntity

		setSearchedModelName(modelName)
	}, [formLineEntity])

	const handleSelect = (selectedItem) => {
		const selectedModel = options.find(({ value }) => selectedItem && value === selectedItem.value)

		changeFormLineEntities([
			{
				id,
				value: selectedModel ? selectedModel : undefined
			}
		])
	}

	const handleSelectedItemDelete = () => {
		changeFormLineEntities([
			{
				id,
				value: undefined
			}
		])
	}

	const linkedSelectAndLabel = AutoComplete.getIdsPropsHelper(id)

	return (
		<div>
			<Label id={linkedSelectAndLabel.labelId} htmlFor={linkedSelectAndLabel.inputId}>
				{label}
			</Label>

			<div data-dot='select-model'>
				<ModelAutoComplete
					{...linkedSelectAndLabel}
					inputValue={searchedModelName}
					onInputValueChange={setSearchedModelName}
					allItems={options}
					placeholder={placeholder}
					disabled={disabled}
					size='small'
					onSelect={handleSelect}
					onSelectedItemDelete={handleSelectedItemDelete}
				/>
			</div>
		</div>
	)
}

ModelAutoCompleteWidget.propTypes = {
	changeFormLineEntities: PropTypes.func,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	disabled: PropTypes.bool
}

export default React.memo(ModelAutoCompleteWidget)
