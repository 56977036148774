export default class FeedbackEntity {
	/**
	 * @constructor
	 * @param {Number} data.id Idecko zaznamu
	 * @param {String} data.email Email uzivatele, ktery vyplnil zpetnou vazbu
	 * @param {String} data.url Url adresa, na ktere byla zpetna vazba vyplnena
	 * @param {Date} data.createDate Datum vytvoreni
	 * @param {String} data.feedbackType Typ, napr. dobry, vyborny atd.
	 * @param {String} data.feedbackSubject Kategorie, napr. ze se na webu spatne hleda atd.
	 * @param {String} data.feedbackText Vlastni text zpetne vazby
	 * @param {Boolean} data.hasText Priznak, zda-li je vyplnen feedbackText (text zpetne vazby)
	 * @param {String} data.userAgent Prohlizec, jaky dany uzivatel pouziva
	 */
	constructor(data) {
		this.id = data.id
		this.email = data.email
		this.url = data.url
		this.createDate = data.createDate
		this.feedbackType = data.feedbackType
		this.feedbackSubject = data.feedbackSubject
		this.feedbackText = data.feedbackText
		this.hasText = data.hasText
		this.userAgent = data.userAgent
	}
}
