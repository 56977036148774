import { CATEGORIES } from 'app/base/Constants'

import './HomeCS.json'
import 'app/base/BaseCS.json'

/**
 * @class HomeMeta
 * @description Trieda obsahuje metody pre ziskanie Title a Description
 */
export default class HomeMeta {
	static getTitleValue(dictionary, selectedCategory) {
		const titleValues = new Map([
			[CATEGORIES.PASSENGER_CARS.seoName, dictionary.get('Home.titleCategoryOsobni')],
			[CATEGORIES.UTILITY_CARS.seoName, dictionary.get('Home.titleCategoryUzitkova')],
			[CATEGORIES.TRUCKS.seoName, dictionary.get('Home.titleCategoryNakladni')],
			[CATEGORIES.MOTORCYCLE.seoName, dictionary.get('Home.titleCategoryMotorky')],
			[CATEGORIES.QUAD.seoName, dictionary.get('Home.titleCategoryCtyrkolky')],
			[CATEGORIES.TRAILERS.seoName, dictionary.get('Home.titleCategoryPrivesyNavesy')],
			[CATEGORIES.CAMPER.seoName, dictionary.get('Home.titleCategoryObytne')],
			[CATEGORIES.WORKING_MACHINES.seoName, dictionary.get('Home.titleCategoryPracovniStroje')],
			[CATEGORIES.BUSES.seoName, dictionary.get('Home.titleCategoryAutobusy')]
		])

		return titleValues.get(selectedCategory)
	}

	static getDescriptionValue(dictionary, selectedCategory) {
		const descriptionValues = new Map([
			[CATEGORIES.PASSENGER_CARS.seoName, dictionary.get('Home.descriptionCategoryOsobni')],
			[CATEGORIES.UTILITY_CARS.seoName, dictionary.get('Home.descriptionCategoryUzitkova')],
			[CATEGORIES.TRUCKS.seoName, dictionary.get('Home.descriptionCategoryNakladni')],
			[CATEGORIES.MOTORCYCLE.seoName, dictionary.get('Home.descriptionCategoryMotorky')],
			[CATEGORIES.QUAD.seoName, dictionary.get('Home.descriptionCategoryCtyrkolky')],
			[CATEGORIES.TRAILERS.seoName, dictionary.get('Home.descriptionCategoryPrivesyNavesy')],
			[CATEGORIES.CAMPER.seoName, dictionary.get('Home.descriptionCategoryObytne')],
			[
				CATEGORIES.WORKING_MACHINES.seoName,
				dictionary.get('Home.descriptionCategoryPracovniStroje')
			],
			[CATEGORIES.BUSES.seoName, dictionary.get('Home.descriptionCategoryAutobusy')]
		])

		return descriptionValues.get(selectedCategory)
	}
}
