import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import StateMark, { TYPE } from 'app/component/stateMark/StateMark'
import ActionButton from 'app/component/actionButton/ActionButton'
import { TRASH_FILLED, EDIT_FILLED } from '@sznds/icons'
import IASoftwareKeyListController from './IASoftwareKeyListController'
import { useFire } from 'app/base/componentHelpers'
import * as FormLines from '@inzeraty/form-lines'
import { PaginationEntity, SoftwareKeyEntity } from '@inzeraty/models'
import EntityForm from 'app/component/entityForm/EntityForm'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import EntityFormPopup from '../components/entityFormPopup/EntityFormPopup'
import TableHead from 'app/component/tableHead/TableHead'
import AddNewButton from '../components/addNewButton/AddNewButton'

const IASoftwareKeyListView = (props) => {
	const fire = useFire()
	const { ACTIONS } = IASoftwareKeyListController

	const {
		entitiesAndPagination,
		filterFormLineEntities,
		isFilterLoading,
		isEditKeyPopupOpened,
		formLineEntities = DEFAULT_PROPS.ARRAY,
		entityFormError,
		isWaiting,
		isLoading
	} = props

	const getActions = () => {
		return [
			(entity, actionButtonProps = {}) => (
				<ActionButton
					icon={EDIT_FILLED}
					key='editovat'
					title='Editovat'
					onClick={() =>
						fire('action', {
							action: ACTIONS.OPEN_EDIT_KEY_POPUP,
							entities: [entity]
						})
					}
					{...actionButtonProps}
				/>
			),
			(entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='smazat'
					title='Smazat'
					onClick={() =>
						fire('action', {
							action: ACTIONS.DELETE,
							entities: [entity]
						})
					}
					{...actionButtonProps}
				/>
			)
		]
	}

	return (
		<Layout title='Výpis softwarových klíčů'>
			<AddNewButton
				text='Přidat softwarový klíč'
				onClick={() =>
					fire('action', {
						action: ACTIONS.OPEN_EDIT_KEY_POPUP
					})
				}
			/>

			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHead key='id' alignRight={true}>
						ID
					</TableHead>,
					<TableHead key='value'>Hodnota</TableHead>,
					<TableHead key='name'>Název</TableHead>,
					<TableHead key='state'>Stav</TableHead>
				]}
				rowCells={[
					({ id }) => [id, { isNumber: true }],
					({ value }) => value,
					({ name }) => name,
					({ enabled }) => (
						<StateMark type={enabled ? TYPE.OK : TYPE.ERROR}>
							{enabled ? 'Povolený' : 'Zakázaný'}
						</StateMark>
					)
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>

			{isEditKeyPopupOpened && (
				<EntityFormPopup
					title={
						formLineEntities.find((f) => f.id === 'id' && f.value)
							? 'Editace softwarového klíče'
							: 'Nový softwarový klíč'
					}
					onClose={() =>
						fire('action', {
							action: ACTIONS.CLOSE_EDIT_KEY_POPUP
						})
					}
				>
					<EntityForm
						variant={VARIANT.POPUP_FORM}
						formLineEntities={formLineEntities}
						entityFormError={entityFormError}
						isWaiting={isWaiting}
					/>
				</EntityFormPopup>
			)}
		</Layout>
	)
}

export default IASoftwareKeyListView

IASoftwareKeyListView.propTypes = {
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(SoftwareKeyEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	isEditKeyPopupOpened: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	isWaiting: PropTypes.bool,
	isLoading: PropTypes.bool
}
