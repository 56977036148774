import React, { memo, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import ContentTabs from 'app/component/contentTabs/ContentTabs'
import { UserService, UserPropTypes } from '@inzeraty/models'
import { Button, Surface } from '@sznds/react'
import Context from 'ima/page/context'
import RouteNames from 'app/base/RouteNames'
import IMAGES from 'app/base/ImagesConstants'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import 'app/base/BaseCS.json'
import './PromoInsertCS.json'
import './PromoInsert.less'

const CLASSNAME = 'c-promo-insert'

const ADVERTS_PRICE_LEVELS = [
	{
		adverts: '1 – 10',
		price: '22 500'
	},
	{
		adverts: '11 – 100',
		price: '45 900'
	},
	{
		adverts: '101 – 200',
		price: '59 500'
	},
	{
		adverts: '201 – 300',
		price: '73 100'
	},
	{
		adverts: '301 – 500',
		price: '86 700'
	},
	{
		adverts: '501 – 1000',
		price: '173 400'
	},
	{
		adverts: '1001 – 2000',
		price: '346 800'
	},
	{
		adverts: '2001 – 3000',
		price: '520 200'
	},
	{
		adverts: '3001 – 4000',
		price: '693 600'
	},
	{
		adverts: '4001 – 5000',
		price: '867 000'
	}
]

const LocalSurface = ({ tagName, children, ...restProps }) => {
	return (
		<Surface surface={5} tagName={tagName} {...restProps}>
			{children}
		</Surface>
	)
}

LocalSurface.propTypes = {
	tagName: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired
}

LocalSurface.defaultProps = {
	tagName: 'div',
	className: ''
}

const CreateCard = (days, price, smsPrice, className) => {
	const localize = useLocalize()
	const context = useContext(Context)
	const walletUrl = context.$Settings.$App.walletUrl
	return (
		<LocalSurface
			className={classnames({
				[`${CLASSNAME}__card-wrapper`]: true,
				[className]: !!className
			})}
		>
			<p className={`${CLASSNAME}__card-inner-text`}>
				{' '}
				{localize('PromoInsert.cardDays', { COUNT: days })}
			</p>
			<h3 className={`notranslate ${CLASSNAME}__card-title`} translate='no' lang='cs-cz'>
				{price} {localize('Base.CZK')}
			</h3>
			<p className={`${CLASSNAME}__wallet-text`}>
				{localize('PromoInsert.walletText')}{' '}
				<a
					href={`${walletUrl}${encodeURIComponent('/credit?language=cz&service=sauto')}`}
					target='_blank'
					rel='noopener noreferrer'
					className={`${CLASSNAME}__link`}
				>
					{localize('PromoInsert.wallets')}
				</a>
			</p>
			<div className={`${CLASSNAME}__card-sms`}>
				{smsPrice && (
					<p>
						<span className='notranslate' translate='no' lang='cs-cz'>
							{smsPrice} {localize('Base.CZK')}
						</span>{' '}
						{localize('PromoInsert.smsText')}
					</p>
				)}
				{!smsPrice && localize('PromoInsert.smsDisabled')}
			</div>
		</LocalSurface>
	)
}

CreateCard.propTypes = {
	days: PropTypes.number,
	price: PropTypes.number,
	smsPrice: PropTypes.number,
	className: PropTypes.string
}

const InfoList = ({ listItems, isFullWidth }) => {
	if (listItems.length) {
		return (
			<ul className={`${CLASSNAME}__list`}>
				{listItems.map((info, index) => (
					<li
						key={index}
						className={classnames({
							[`${CLASSNAME}__list-item`]: true,
							[`${CLASSNAME}__list-item--full-width`]: isFullWidth
						})}
					>
						<img src={info.iconUrl} className={`${CLASSNAME}__list-item-icon`} alt='' />
						<p>{info.text}</p>
					</li>
				))}
			</ul>
		)
	}
}

InfoList.propTypes = {
	listItems: PropTypes.array,
	isFullWidth: PropTypes.bool
}

InfoList.defaultProps = {
	listItems: DEFAULT_PROPS.ARRAY,
	isFullWidth: true
}

const RusContent = ({ isLogged, changeTab, isAddNewAdvertPage }) => {
	const { $Router } = useContext(Context)
	const localize = useLocalize()
	const listItems = [
		{
			text: localize('PromoInsert.getListed'),
			iconUrl: IMAGES['image__logo-promo-insert-magnify-car']
		},
		{ text: localize('PromoInsert.getStats'), iconUrl: IMAGES['image__logo-promo-insert-eye'] },
		{ text: localize('PromoInsert.getSupport'), iconUrl: IMAGES['image__logo-promo-insert-human'] },
		{
			text: localize('PromoInsert.getMobileApp'),
			iconUrl: IMAGES['image__logo-promo-insert-mobile']
		},
		{
			text: localize('PromoInsert.getMobileEdit'),
			iconUrl: IMAGES['image__logo-promo-insert-edit']
		}
	]

	const addButtonProps = {
		primary: true,
		href: $Router.link(RouteNames.CLIENT_ADMIN.NEW_ADVERT),
		className: `${CLASSNAME}__button`
	}

	if (isLogged) {
		addButtonProps.text = localize('PromoInsert.insertAdvert')
	} else {
		addButtonProps.text = localize('PromoInsert.logAndInsert')
		addButtonProps.onClick = (event) => {
			event.preventDefault()
			LoginHelper.openLogin({
				sourceComponent: LoginHelper.SOURCE_COMPONENT.ADVERT_ADD_LOGIN
			})
		}
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__content-wrapper`}>
				<div>
					<h3 className={`${CLASSNAME}__tab-heading ${CLASSNAME}__tab-heading--indentation`}>
						{localize('PromoInsert.title')}
					</h3>
					<InfoList listItems={listItems} isFullWidth={isAddNewAdvertPage} />
				</div>

				{!isAddNewAdvertPage && (
					<LocalSurface className={`${CLASSNAME}__sign-in`}>
						<h3 className={`${CLASSNAME}__tab-heading`}>
							{localize(`PromoInsert.wannaInsert`)}
							{!isLogged && (
								<>
									<br />
									{localize(`PromoInsert.signIn`)}
								</>
							)}
						</h3>
						<Button {...addButtonProps} />
						<div>
							<p>
								<a
									href='https://registrace.seznam.cz/'
									target='_blank'
									rel='noopener noreferrer'
									className={`${CLASSNAME}__link`}
									onClick={(event) => {
										event.preventDefault()
										LoginHelper.openRegister({
											sourceComponent: LoginHelper.SOURCE_COMPONENT.ADVERT_ADD_REGISTER
										})
									}}
								>
									{localize(`PromoInsert.signUp`)}
								</a>
								{localize(`PromoInsert.signUpText`)}
							</p>
						</div>
					</LocalSurface>
				)}
			</div>
			<h3 className={`${CLASSNAME}__tab-heading`}>{localize('PromoInsert.rusPrice')}</h3>
			<div className={`${CLASSNAME}__card-container`}>
				<div className={`${CLASSNAME}__card-subcontainer`}>
					<p className={`${CLASSNAME}__card-upper-text`}>{localize(`PromoInsert.sautoAdvert`)}</p>
					<div className={`${CLASSNAME}__cards`}>
						{CreateCard(7, 89, 99)}
						{CreateCard(14, 119)}
					</div>
				</div>
				<div className={`${CLASSNAME}__card-subcontainer`}>
					<p className={`${CLASSNAME}__card-upper-text`}>{localize(`PromoInsert.motoAdvert`)}</p>
					<div className={`${CLASSNAME}__cards`}>
						{CreateCard(7, 30, 39)}
						{CreateCard(14, 40, 50, `${CLASSNAME}__card-wrapper--end`)}
					</div>
				</div>
			</div>
			<p>
				{localize('PromoInsert.flatRate')}
				<br />
				<button
					onClick={() => {
						changeTab(1)
						setTimeout(() => {
							const scrollToElement = document.querySelector('#premise-price')
							if (scrollToElement) {
								animateScrollTo(scrollToElement)
							}
						})
					}}
					className={`${CLASSNAME}__link`}
				>
					{localize('PromoInsert.priceList')}
				</button>
			</p>
		</div>
	)
}

RusContent.propTypes = {
	isLogged: PropTypes.bool,
	changeTab: PropTypes.func,
	isAddNewAdvertPage: PropTypes.bool
}

RusContent.defaultProps = {
	isLogged: false,
	changeTab: DEFAULT_PROPS.FUNCTION,
	isAddNewAdvertPage: false
}

const PriceTableContent = () => {
	const localize = useLocalize()
	return ADVERTS_PRICE_LEVELS.map((level) => (
		<tr key={level.price}>
			<td>
				{level.adverts} {localize(`PromoInsert.adverts`)}
			</td>
			<td className='notranslate' translate='no' lang='cs-cz'>
				{level.price} {localize(`Base.CZK`)}
			</td>
		</tr>
	))
}

const PremiseContent = ({ isLogged, isAddNewAdvertPage }) => {
	const localize = useLocalize()
	const { $Router } = useContext(Context)

	const listItems = [
		{
			text: localize('PromoInsert.getListed'),
			iconUrl: IMAGES['image__logo-promo-insert-magnify-car']
		},
		{
			text: localize('PromoInsert.getDetailStats'),
			iconUrl: IMAGES['image__logo-promo-insert-eye']
		},
		{ text: localize('PromoInsert.getSupport'), iconUrl: IMAGES['image__logo-promo-insert-human'] },
		{
			text: localize('PromoInsert.getMobileApp'),
			iconUrl: IMAGES['image__logo-promo-insert-mobile']
		},
		{
			text: localize('PromoInsert.getMobileEdit'),
			iconUrl: IMAGES['image__logo-promo-insert-edit']
		},
		{
			text: localize('PromoInsert.getPromotion'),
			iconUrl: IMAGES['image__logo-promo-insert-speaker']
		},
		{
			text: localize('PromoInsert.getMassActions'),
			iconUrl: IMAGES['image__logo-promo-insert-import']
		},
		{ text: localize('PromoInsert.getPromoCard'), iconUrl: IMAGES['image__logo-promo-insert-card'] }
	]

	const addButtonProps = {
		href: $Router.link(RouteNames.CLIENT_ADMIN.NEW_ADVERT)
	}

	if (isLogged) {
		addButtonProps.text = localize('PromoInsert.insertAdvert')
	} else {
		addButtonProps.text = localize('PromoInsert.logAndInsert')
		addButtonProps.onClick = () => {
			LoginHelper.openLogin()
		}
	}

	return (
		<div className={CLASSNAME}>
			<h3 className={`${CLASSNAME}__tab-heading`}>{localize('PromoInsert.premiseTitle')}</h3>
			<div className={`${CLASSNAME}__content-wrapper`}>
				<InfoList listItems={listItems} />
			</div>

			<div className={`${CLASSNAME}__buttons`}>
				<Button
					primary={true}
					text={localize('PromoInsert.registerNewPremise')}
					href={$Router.link(RouteNames.USERWEB.REGISTER_COMPANY)}
				/>
				{!isAddNewAdvertPage && <Button {...addButtonProps} />}
			</div>
			<div className={`${CLASSNAME}__price--heading`}>
				<h3 id='premise-price' className={`${CLASSNAME}__tab-heading`}>
					{localize('PromoInsert.premisePrice')}
				</h3>
				<p className={`${CLASSNAME}__price--info`}>{localize('PromoInsert.premisePriceInfo')}</p>
			</div>
			<LocalSurface className={`${CLASSNAME}__price-list-wrap`}>
				<table className={`${CLASSNAME}__price-list-table`}>
					<thead>
						<tr>
							<th>{localize(`PromoInsert.adverts_header`)}</th>
							<th>{localize(`PromoInsert.price_header`)}</th>
						</tr>
					</thead>
					<tbody>
						<PriceTableContent />
					</tbody>
				</table>
			</LocalSurface>
			<div className={`${CLASSNAME}__price-list-download`}>
				{localize(`PromoInsert.complete`)}
				<a
					className={`${CLASSNAME}__link`}
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.seznam.cz/reklama/cz/obsahovy-web/ceniky/'
				>
					{localize(`PromoInsert.priceListDownload`)}
				</a>
			</div>
		</div>
	)
}

PremiseContent.propTypes = {
	isLogged: PropTypes.bool,
	isAddNewAdvertPage: PropTypes.bool
}

PremiseContent.defaultProps = {
	isLogged: false,
	isAddNewAdvertPage: false
}

const PromoInsert = memo(({ className, userSelf, isPremise }) => {
	const localize = useLocalize()

	const [isLogged, setIsLogged] = useState(false)
	const [activeTabIndex, setActiveTabIndex] = useState(isPremise ? 1 : 0)

	const { $Router } = useContext(Context)
	const { route } = $Router.getCurrentRouteInfo()
	const isAddNewAdvertPage = route.getName() === RouteNames.CLIENT_ADMIN.NEW_ADVERT

	useEffect(() => {
		setIsLogged(UserService.isUserLogged(userSelf))
	}, [userSelf])

	const tabs = [
		{
			text: localize('PromoInsert.tabRus'),
			content: (
				<RusContent
					isLogged={isLogged}
					changeTab={setActiveTabIndex}
					isAddNewAdvertPage={isAddNewAdvertPage}
				/>
			)
		},
		{
			text: localize('PromoInsert.tabPremise'),
			content: (
				<PremiseContent
					isLogged={isLogged}
					isPremise={isPremise}
					isAddNewAdvertPage={isAddNewAdvertPage}
				/>
			)
		}
	]

	return (
		<div
			className={classnames({
				[className]: !!className,
				[CLASSNAME]: true
			})}
		>
			<ContentTabs
				tabs={tabs}
				setActiveTabIndex={setActiveTabIndex}
				activeTabIndex={activeTabIndex}
			/>
		</div>
	)
})

PromoInsert.propTypes = {
	className: PropTypes.string,
	isPremise: PropTypes.bool,
	userSelf: PropTypes.shape(UserPropTypes)
}

PromoInsert.defaultProps = {
	className: '',
	isPremise: false,
	userSelf: DEFAULT_PROPS.OBJECT
}

export default PromoInsert
