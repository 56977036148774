import React from 'react'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { useLocalize } from 'app/base/componentHelpers'
import CategorySelectWidget from './selects/CategorySelectWidget'
import SelectWidget from './selects/SelectWidget'
import BrandAutoCompleteWidget from './autoCompletes/brand/BrandAutoCompleteWidget'
import ModelAutoCompleteWidget from './autoCompletes/model/ModelAutoCompleteWidget'
import RangeDateWidget from './rangeDate/RangeDateWidget'
import CheckboxWidget from './checkbox/CheckboxWidget'
import { PREMISE_FORM_LINES_IDS } from '../PremiseFiltersFormLines'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './Form.less'
import './FormCS.json'

const CLASSNAME = 'c-client-form'

const Form = (props) => {
	const {
		premiseFiltersFormLines = DEFAULT_PROPS.ARRAY,
		changePremiseFiltersFormLines = DEFAULT_PROPS.FUNCTION,
		renderSubmitButton = DEFAULT_PROPS.FUNCTION
	} = props

	const localize = useLocalize()

	const getFormLineEntityById = (id) => premiseFiltersFormLines.find((f) => f.id === id)

	const renderTextForSubmitButton = (
		total,
		isLoadingTotal,
		renderDefaultLoadingDots = DEFAULT_PROPS.FUNCTION
	) => {
		if (isLoadingTotal) {
			return (
				<>
					{localize('Form.showAdvertsPrefix')}
					{renderDefaultLoadingDots()}
					{localize('Form.showAdvertsSuffix')}
				</>
			)
		} else {
			if (!isNullOrUndefined(total) && total > 0) {
				return localize('Form.showAdverts', {
					ADVERT_COUNT: total,
					COUNT_NICE: Format.number(total)
				})
			} else {
				return localize('Form.noResults')
			}
		}
	}

	const _changePremiseFiltersFormLines = (changes) => {
		const isCategoryGoingToChange = (changes) =>
			changes.filter(
				(change) =>
					change.id === PREMISE_FORM_LINES_IDS.CATEGORY_SELECT_ID && change.hasOwnProperty('value')
			).length > 0

		const isBrandGoingToChange = (changes) =>
			changes.filter(
				(change) =>
					change.id === PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID &&
					change.hasOwnProperty('value')
			).length > 0

		const isCategoryChange = isCategoryGoingToChange(changes)

		let updatedChanges = isCategoryChange
			? [
					...changes,
					{
						id: PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID,
						disabled: false,
						value: undefined,
						options: undefined
					}
			  ]
			: changes

		const isBrandChange = isBrandGoingToChange(updatedChanges)

		updatedChanges = isBrandChange
			? [
					...updatedChanges,
					{
						id: PREMISE_FORM_LINES_IDS.MODEL_AUTO_COMPLETE_ID,
						disabled: false,
						value: undefined,
						options: undefined
					}
			  ]
			: updatedChanges

		changePremiseFiltersFormLines(updatedChanges)
	}

	const categoryFormLineEntity = getFormLineEntityById(PREMISE_FORM_LINES_IDS.CATEGORY_SELECT_ID)

	const { value: selectedCategory } = categoryFormLineEntity

	const brandFormLineEntity = getFormLineEntityById(PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID)

	const { value: selectedBrand } = brandFormLineEntity

	const modelFormLineEntity = getFormLineEntityById(PREMISE_FORM_LINES_IDS.MODEL_AUTO_COMPLETE_ID)

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__content`}>
				<CategorySelectWidget
					formLineEntity={categoryFormLineEntity}
					changeFormLineEntities={_changePremiseFiltersFormLines}
				/>

				<BrandAutoCompleteWidget
					formLineEntity={brandFormLineEntity}
					disabled={selectedCategory ? brandFormLineEntity.disabled : true}
					categoryData={selectedCategory}
					changeFormLineEntities={_changePremiseFiltersFormLines}
				/>

				<ModelAutoCompleteWidget
					formLineEntity={modelFormLineEntity}
					disabled={selectedBrand ? modelFormLineEntity.disabled : true}
					changeFormLineEntities={_changePremiseFiltersFormLines}
				/>

				<SelectWidget
					formLineEntity={getFormLineEntityById(PREMISE_FORM_LINES_IDS.ADVERT_STATUS_SELECT_ID)}
					changeFormLineEntities={_changePremiseFiltersFormLines}
					dataDotText='select-state'
				/>

				<SelectWidget
					formLineEntity={getFormLineEntityById(PREMISE_FORM_LINES_IDS.DEAL_TYPE_SELECT_ID)}
					changeFormLineEntities={_changePremiseFiltersFormLines}
					dataDotText='deal-type'
					allowNoSelection={false}
				/>

				<CheckboxWidget
					className={`${CLASSNAME}__checkbox-wrapper`}
					formLineEntity={getFormLineEntityById(PREMISE_FORM_LINES_IDS.TOPPED_ADVERTS_CHECKBOX_ID)}
					changeFormLineEntities={_changePremiseFiltersFormLines}
					dataDotText='isTopped'
				/>

				<CheckboxWidget
					className={`${CLASSNAME}__checkbox-wrapper`}
					formLineEntity={getFormLineEntityById(PREMISE_FORM_LINES_IDS.PREFERRED_OFFER_CHECKBOX_ID)}
					changeFormLineEntities={_changePremiseFiltersFormLines}
					dataDotText='isPrefered'
				/>

				<RangeDateWidget
					formLineEntityDateFrom={getFormLineEntityById(PREMISE_FORM_LINES_IDS.CREATE_DATE_FROM_ID)}
					formLineEntityDateTo={getFormLineEntityById(PREMISE_FORM_LINES_IDS.CREATE_DATE_TO_ID)}
					changeFormLineEntities={_changePremiseFiltersFormLines}
				/>

				<SelectWidget
					formLineEntity={getFormLineEntityById(PREMISE_FORM_LINES_IDS.REGION_SELECT_ID)}
					changeFormLineEntities={_changePremiseFiltersFormLines}
					dataDotText='country'
				/>
			</div>

			<div className={`${CLASSNAME}__submit`} data-dot='filter-cta'>
				{renderSubmitButton(renderTextForSubmitButton)}
			</div>
		</div>
	)
}

Form.propTypes = {
	premiseFiltersFormLines: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	changePremiseFiltersFormLines: PropTypes.func,
	renderSubmitButton: PropTypes.func
}

export default React.memo(Form)
