import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import SortConstants from 'app/component/sortButton/SortConstants'

export default class UrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			DEFAULTS: {
				PER_PAGE: 20
			},

			URL_APP_PARAMS: {
				PAGE: 'strana',
				PER_PAGE: 'na-strance',
				SORT: 'razeni',
				CODE: 'code',
				STATE: 'state'
			},

			URL_API_PARAMS: {
				LIMIT: 'limit',
				OFFSET: 'offset',
				SORT: 'sort',
				CATEGORY_ID: 'category_id',
				TIMESTAMP_TO: 'timestamp_to',
				USER_ID: 'user_id',
				PREMISE_ID: 'premise_id',
				VALID_TO: 'valid_to'
			}
		})
	}

	/**
	 * Prevede parametry strankovani z URL APP na parametry pro API
	 *
	 * @method getApiPaging
	 * @param {number} page - stranka
	 * @param {number} perPage - pocet zaznamu na strance
	 * @return {Object}
	 */
	static getApiPaging(page = 1, perPage = UrlConvertor.constants.DEFAULTS.PER_PAGE) {
		const { URL_API_PARAMS } = UrlConvertor.constants

		return {
			[URL_API_PARAMS.LIMIT]: perPage,
			[URL_API_PARAMS.OFFSET]: (page - 1) * perPage
		}
	}

	/**
	 * Vráti url
	 *
	 * @method getUrl
	 * @static
	 * @param  {Object} router
	 * @param  {Object} routeParams parametry routy
	 * @return {String} url
	 */
	static getUrl(router, routeParams) {
		const routeInfo = UrlConvertor.getRouteInfo(router)
		const { route } = routeInfo
		const routeName = route ? route.getName() : ''

		const params = routeParams || routeInfo.params

		if (routeName) {
			return router.link(routeName, params)
		} else {
			return ''
		}
	}

	/**
	 * Vráti URL pro nasledujíci a předchozí stránku pro stránkovaní
	 *
	 * @method getPaginationUrl
	 * @static
	 * @param {Object} router
	 * @param {Object} advertListAndPagination
	 *
	 * @return {Object<String, String>} prevPageUrl, nextPageUrl URL pro predchozi a nasledujici stranku
	 */
	static getPaginationUrl(router, advertListAndPagination) {
		const { actualPage, lastPage } = UrlConvertor.getPages(router, advertListAndPagination)

		let prevPageUrl = ''
		let nextPageUrl = ''

		const prevPage = actualPage - 1
		const nextPage = actualPage + 1

		const isFirstPage = actualPage === 1

		const isLastPage = actualPage === lastPage

		const isOnePage = isFirstPage && isLastPage

		if (isFirstPage && !isOnePage) {
			nextPageUrl = UrlConvertor.createPaginationUrl(nextPage, router)
		} else if (actualPage === 2 && !isLastPage) {
			nextPageUrl = UrlConvertor.createPaginationUrl(nextPage, router)
			prevPageUrl = UrlConvertor.createPaginationUrl(0, router)
		} else if (!isFirstPage && !isLastPage) {
			prevPageUrl = UrlConvertor.createPaginationUrl(prevPage, router)
			nextPageUrl = UrlConvertor.createPaginationUrl(nextPage, router)
		} else if (!isOnePage) {
			prevPageUrl = UrlConvertor.createPaginationUrl(prevPage, router)
		}

		return { prevPageUrl, nextPageUrl }
	}

	/**
	 * Vráti číslo aktuálni a poslední stránky na stránce
	 *
	 * @method getPages
	 * @static
	 * @param {Object} router
	 * @param {Object} advertListAndPagination
	 *
	 * @return {Object<number, number>} číslo aktuálni a poslední stránky
	 */
	static getPages(router, advertListAndPagination = {}) {
		const { advertList, paginationEntity } = advertListAndPagination

		if (advertList) {
			const { params } = router.getCurrentRouteInfo()

			//kdyz neni definovany parametr strankovani v route, tak se nachazime na prvni strance
			const actualPage = parseInt(params[UrlConvertor.constants.URL_APP_PARAMS.PAGE], 10) || 1

			const { limit, total } = paginationEntity

			const lastPage = parseInt(Math.ceil(total / limit), 10)

			return { actualPage, lastPage }
		} else {
			return {}
		}
	}

	/**
	 * Vytvoří novou URL z nazvu routy a čísla stránky
	 *
	 * @createPaginationUrl
	 *
	 * @static
	 * @param  {Number} page číslo stránky
	 * @param {Object} router
	 * @return {String} URL
	 */
	static createPaginationUrl(page, router) {
		const { params } = router.getCurrentRouteInfo()

		if (page > 0) {
			params[UrlConvertor.constants.URL_APP_PARAMS.PAGE] = page
		} else {
			delete params[UrlConvertor.constants.URL_APP_PARAMS.PAGE]
		}

		return UrlConvertor.getUrl(router, params)
	}

	/**
	 * Vrátí informace o aktuální routě
	 *
	 * @method getRouteInfo
	 * @static
	 * @param {Object} router
	 * @return {Object}
	 */
	static getRouteInfo(router) {
		let routeInfo

		try {
			routeInfo = router.getCurrentRouteInfo()
		} catch (exception) {
			return {}
		}

		return routeInfo
	}

	/**
	 * Upraví parametry pro stránku strankovaní
	 *
	 * @method getPagingPageParams
	 * @param {number} page Číslo stránky pro kterou potřebuji sestavit URL
	 * @param {Object} routeParams Parametry url (routy).
	 * @return {Object}
	 */
	static getPagingPageParams(page, routeParams = {}) {
		const { URL_APP_PARAMS } = UrlConvertor.constants

		const newParams = Object.assign({}, routeParams)
		let pageParam = {}

		delete newParams[URL_APP_PARAMS.PAGE]

		if (page && page > 1) {
			pageParam = { [URL_APP_PARAMS.PAGE]: page }
		}

		return Object.assign({}, newParams, pageParam)
	}

	/**
	 * Vrátí objekt s url APP parmetry (routy) pro řazení.
	 *
	 * @method getAppSorting
	 * @static
	 * @param {Object} routeParams Parametry url (routy).
	 * @return {Object}
	 */
	static getAppSorting(routeParams = {}) {
		const { [UrlConvertor.constants.URL_APP_PARAMS.SORT]: sortParam } = routeParams

		if (sortParam) {
			return {
				[UrlConvertor.constants.URL_APP_PARAMS.SORT]: sortParam
			}
		} else {
			return {}
		}
	}

	/**
	 * Vrati objekt s API parametry pro řazení.
	 *
	 * @method getApiSorting
	 * @param {Object} routeParams Parametry url (routy).
	 * @param {Object} sortConstants Jiný SortConstants
	 * @static
	 * @return {Object}
	 */
	static getApiSorting(routeParams = {}, sortConstants = SortConstants) {
		const { URL_APP_PARAMS, URL_API_PARAMS } = UrlConvertor.constants

		const sortType = routeParams[URL_APP_PARAMS.SORT]
		const sortKey = Object.keys(sortConstants.sortValues).find(
			(key) => sortConstants.sortValues[key].APP === sortType
		)

		if (sortType && sortKey) {
			return {
				[URL_API_PARAMS.SORT]: sortConstants.sortValues[sortKey].API
			}
		} else {
			return {}
		}
	}
}
