const RESOURCE = '/service/feedbacks'

export default class FeedbackResource {
	constructor(restClient, $window) {
		this._restClient = restClient
		this._window = $window
	}

	getFeedbacks(params = {}, options = {}) {
		return this._restClient.get(RESOURCE, params, options)
	}

	sendFeedback(params = {}, options = {}) {
		const updatedParams = Object.assign(
			{
				url: this._window.getUrl()
			},
			params
		)

		return this._restClient.post(RESOURCE, updatedParams, options)
	}

	deleteFeedback(feedbackId) {
		return this._restClient.delete(`${RESOURCE}/${feedbackId}`)
	}
}
