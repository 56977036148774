import { FORM_LINES_IDS } from './HistoryFormLineIds'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './HistoryFormCS.json'

export const getErrorDictionary = (localize) => ({
	[FORM_LINES_IDS.GUARANTEE_DATE]: {
		[ERROR_CODES.NOT_VALID]: localize('HistoryForm.guaranteeDateNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('HistoryForm.guaranteeDateNotValid')
	},
	[FORM_LINES_IDS.EURO_LEVEL_CB]: {
		[ERROR_CODES.NOT_VALID]: localize('HistoryForm.euroLevelNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('HistoryForm.euroLevelNotValid')
	},
	[FORM_LINES_IDS.ENVIRONMENTAL_TAX_PAID]: {
		[ERROR_CODES.NOT_VALID]: localize('HistoryForm.environmentalTaxPaidNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('HistoryForm.environmentalTaxPaidNotValid')
	},
	[FORM_LINES_IDS.FIRST_OWNER]: {
		[ERROR_CODES.NOT_VALID]: localize('HistoryForm.firstOwnerNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('HistoryForm.firstOwnerNotValid')
	},
	[FORM_LINES_IDS.ADJUSTMENTS_FOR_HANDICAPPED]: {
		[ERROR_CODES.NOT_VALID]: localize('HistoryForm.adjustmentsForHandicappedNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('HistoryForm.adjustmentsForHandicappedNotValid')
	},
	[FORM_LINES_IDS.CRASHED_IN_PAST]: {
		[ERROR_CODES.NOT_VALID]: localize('HistoryForm.crashedInPastNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('HistoryForm.crashedInPastNotValid')
	},
	[FORM_LINES_IDS.TUNING]: {
		[ERROR_CODES.NOT_VALID]: localize('HistoryForm.tuningNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('HistoryForm.tuningNotValid')
	},
	[FORM_LINES_IDS.SERVICE_BOOK]: {
		[ERROR_CODES.NOT_VALID]: localize('HistoryForm.serviceBookNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('HistoryForm.serviceBookNotValid')
	}
})
