import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'

export default class SellerUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				SELLER_SEO_NAME: 'sellerSeoName',
				SELLER_ID: 'sellerId',
				SELLER_OLD_ID: 'company', //jedna se o ID premise pouzivane v historickych variantach url
				CATEGORY: 'kategorie'
			},

			URL_API_PARAMS: {
				SELLER_ID: 'premise_id',
				OPERATING_LEASE: 'operating_lease'
			}
		})
	}

	/**
	 * Prevede parametry z url na parametry pro API.
	 *
	 * @static
	 * @method getApiParams
	 * @param {Object} params Parametry z url.
	 * @return {Object} Parametry pro API.
	 */
	static getApiParams(params = {}, routeName = ROUTE_NAMES.USERWEB.SELLER) {
		const appParams = Object.assign({}, params)
		const apiParams = {}

		const {
			URL_APP_PARAMS: URL_CONVERTOR_APP_PARAMS,
			URL_API_PARAMS: URL_CONVERTOR_API_PARAMS
		} = UrlConvertor.constants
		const { URL_APP_PARAMS, URL_API_PARAMS } = SellerUrlConvertor.constants

		// stránkování
		const apiPagingParams = UrlConvertor.getApiPaging(
			appParams[URL_CONVERTOR_APP_PARAMS.PAGE],
			appParams[URL_CONVERTOR_APP_PARAMS.PER_PAGE]
		)

		// id prodejce
		const premiseId = params[URL_APP_PARAMS.SELLER_ID]
		if (premiseId) {
			apiParams[URL_API_PARAMS.SELLER_ID] = premiseId
		}
		// kategorie prodejce
		const premiseCategory = params[URL_CONVERTOR_API_PARAMS.CATEGORY_ID]
		if (premiseCategory) {
			apiParams[URL_CONVERTOR_API_PARAMS.CATEGORY_ID] = premiseCategory
		}

		// operativni leasing
		if (routeName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES) {
			apiParams[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE] = true
		}

		//filter parametry pre predajcu
		const filterApiParams = Object.assign(
			{},
			AdvertListUrlConvertor.getApiParams(appParams, routeName)
		)

		return Object.assign(apiParams, filterApiParams, apiPagingParams)
	}

	/**
	 * Upraví parametry pro stránku strankovaní
	 *
	 * @method getPagingPageParams
	 * @param {number} page - číslo stránky pro kterou potřebuji sestavit URL
	 * @param {Object} params - parametry routy pro sestaveni URL
	 * @return {Object}
	 */
	static getPagingPageParams(page, params = {}) {
		return UrlConvertor.getPagingPageParams(page, params)
	}
}
