import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

const categorySeoNameForHome = 'osobni'

/**
 * @class HomeUrlConvertor
 * @namespace app.page.userweb.home
 * @module app
 */
export default class HomeUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				CATEGORY_SEO_NAME: 'categorySeoName'
			},
			URL_API_PARAMS: {
				CATEGORY_ID: UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID,
				OPERATING_LEASE: 'operating_lease'
			}
		})
	}

	/**
	 * Vrati SEO jmeno kategorie z URL. Kategorie 'osobni' je nasmerovana na '/'.
	 *
	 * @method parseCategorySeoNameFromAppParams
	 * @return {Object}
	 */
	static parseCategorySeoNameFromAppParams(params = {}) {
		const {
			[HomeUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_SEO_NAME]: origCategorySeoName
		} = params

		if (origCategorySeoName) {
			return {
				origCategorySeoName,
				parsedCategorySeoName: origCategorySeoName,
				redirectToHome: origCategorySeoName === categorySeoNameForHome
			}
		} else {
			return {
				origCategorySeoName,
				parsedCategorySeoName: categorySeoNameForHome,
				redirectToHome: false
			}
		}
	}

	/**
	 * Vrati SEO jmeno kategorie, ktere se pak pouzije pro vytvoreni URL.
	 *
	 * @method getCategorySeoNameForAppParams
	 * @return {string}
	 */
	static getCategorySeoNameForAppParams(categorySeoName) {
		return categorySeoName === categorySeoNameForHome ? null : categorySeoName
	}
}
