import React from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'

import './BasicInfoHeaderCS.json'
import './BasicInfoHeader.less'

const CLASSNAME = 'c-basic-info-header'

const BasicInfoHeader = (props) => {
	const { advertEntity = {} } = props

	const { category = {}, conditionCb = {}, vin = '' } = advertEntity

	const { name: categoryName = '' } = category
	const { name: conditionName = '' } = conditionCb

	const localize = useLocalize()

	const renderItem = (text, value) => {
		return (
			<div className={`${CLASSNAME}__item`}>
				<span className={`${CLASSNAME}__item-text`}>{text}:</span>
				{value ? <span>{value}</span> : <span className={`${CLASSNAME}__placeholder`}></span>}
			</div>
		)
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__heading`}>{localize('BasicInfoHeader.heading')}</div>
			<div className={`${CLASSNAME}__info`}>
				{renderItem(localize('BasicInfoHeader.category'), categoryName)}
				{renderItem(localize('BasicInfoHeader.condition'), conditionName)}
				{vin && renderItem(localize('BasicInfoHeader.vin'), vin)}
			</div>
		</div>
	)
}

BasicInfoHeader.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes)
}

export default React.memo(BasicInfoHeader)
