import PropTypes from 'prop-types'
import { localityPropTypes, UserPropTypes } from '@inzeraty/models'
import premisePropTypes from 'app/model/premise/PremisePropTypes'
import operatingLeasePropTypes, {
	intendedForPropTypes
} from 'app/model/operatingLease/OperatingLeasePropTypes'

const advertPropTypes = {
	additionalModelName: PropTypes.string,
	adjustmentsForHandicapped: PropTypes.bool,
	advertReportsCount: PropTypes.number,
	airbags: PropTypes.number,
	airconditionCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	approvalAdmin: PropTypes.string,
	availabilityCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	averageElectricMileage: PropTypes.number,
	averageGasMileage: PropTypes.number,
	batteryCapacity: PropTypes.number,
	beds: PropTypes.number,
	capacity: PropTypes.number,
	category: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		seoName: PropTypes.string
	}),
	cebiaReport: PropTypes.string,
	cebiaSmartCodeUrl: PropTypes.string,
	certificationCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	colorCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	colorToneCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	colorTypeCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	conditionCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	countryOfOriginCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	crashedInPast: PropTypes.bool,
	createDate: PropTypes.instanceOf(Date),
	customId: PropTypes.string,
	deactivationDate: PropTypes.instanceOf(Date),
	deactivationReason: PropTypes.string,
	dealType: PropTypes.string,
	deliveryDate: PropTypes.instanceOf(Date),
	description: PropTypes.string,
	dontShowPhone: PropTypes.bool,
	doors: PropTypes.number,
	driveCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	editDate: PropTypes.instanceOf(Date),
	email: PropTypes.string,
	enginePower: PropTypes.number,
	engineVolume: PropTypes.number,
	environmentalTaxPaid: PropTypes.bool,
	equipmentCb: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			value: PropTypes.number
		})
	),
	euroLevelCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	firstOwner: PropTypes.bool,
	fuelCb: PropTypes.shape({
		name: PropTypes.string,
		seoName: PropTypes.string,
		value: PropTypes.number
	}),
	hideVin: PropTypes.bool,
	homeDelivery: PropTypes.bool,
	gearboxCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	gearboxLevelsCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	guaranteeDate: PropTypes.instanceOf(Date),
	id: PropTypes.number,
	images: PropTypes.arrayOf(
		PropTypes.shape({
			url: PropTypes.string
		})
	),
	imagesTotalCount: PropTypes.number,
	inOperationDate: PropTypes.instanceOf(Date),
	isCebiaSmartCodeUrlVerified: PropTypes.bool,
	loadCapacity: PropTypes.number,
	lastToppedDate: PropTypes.instanceOf(Date),
	locality: PropTypes.shape(localityPropTypes),
	machineHours: PropTypes.number,
	manufacturerCb: PropTypes.shape({
		name: PropTypes.string,
		seoName: PropTypes.string,
		value: PropTypes.number
	}),
	manufacturingDate: PropTypes.instanceOf(Date),
	modelCb: PropTypes.shape({
		name: PropTypes.string,
		seoName: PropTypes.string,
		value: PropTypes.number
	}),
	name: PropTypes.string,
	namePrefix: PropTypes.string,
	nameSuffix: PropTypes.string,
	oldId: PropTypes.number,
	operatingLeaseIntendedForCb: PropTypes.arrayOf(PropTypes.shape(intendedForPropTypes)),
	operatingLeaseVariants: PropTypes.arrayOf(PropTypes.shape(operatingLeasePropTypes)),
	operatingLeasePriceWithVat: PropTypes.number,
	operatingLeasePriceWithoutVat: PropTypes.number,
	phone: PropTypes.string,
	price: PropTypes.number,
	priceOriginal: PropTypes.number,
	priceCompensation: PropTypes.number,
	priceLeasing: PropTypes.number,
	priceNote: PropTypes.string,
	pricePayment: PropTypes.number,
	pricePaymentCount: PropTypes.number,
	priceIsVatDeductible: PropTypes.bool,
	priceWithoutVat: PropTypes.number,
	priceIsWithoutVat: PropTypes.bool,
	seatsCb: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.number
	}),
	seoName: PropTypes.string,
	serviceBook: PropTypes.bool,
	sortingDate: PropTypes.instanceOf(Date),
	source: PropTypes.string,
	status: PropTypes.string,
	stkDate: PropTypes.instanceOf(Date),
	tachometer: PropTypes.number,
	topped: PropTypes.bool,
	toppedCount: PropTypes.number,
	tuning: PropTypes.bool,
	user: PropTypes.shape(UserPropTypes),
	premise: PropTypes.shape(premisePropTypes),
	preferredOffer: PropTypes.bool,
	validToDate: PropTypes.instanceOf(Date),
	vehicleBodyCb: PropTypes.shape({
		name: PropTypes.string,
		seoName: PropTypes.string,
		value: PropTypes.number
	}),
	vehicleRange: PropTypes.number,
	videos: PropTypes.array,
	vin: PropTypes.string,
	weight: PropTypes.number,
	windshieldNote: PropTypes.string
}

export default advertPropTypes
