import * as FormLines from '@inzeraty/form-lines'
import AbstractNewAdvertBaseController from '../AbstractNewAdvertBaseController'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import STATE_KEYS from './EquipmentStateKeys'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../AbstractNewAdvertStateKeys'
import { EQUIPMENT_CB } from './EquipmentConstants'
import { createEquipmentFormLineEntity } from './EquipmentFormLine'

export default class EquipmentController extends AbstractNewAdvertBaseController {
	constructor(dependenciesHelper, VINVehicleDataService, advertService) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary

		this._VINVehicleDataService = VINVehicleDataService
		this._advertService = advertService

		this._changeFormLineEntity = this._changeFormLineEntity.bind(this)
		this._submitForm = this._submitForm.bind(this)

		this._setFormStatus = this._setFormStatus.bind(this)

		this._loadEquipmentDataFromCebia = this._loadEquipmentDataFromCebia.bind(this)

		this._scrollPageToTop = this._scrollPageToTop.bind(this)
	}

	load() {
		const superState = super.load()

		const { [ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise } = superState

		// volani na create page, ktera nam vrati, jake prvky vybavy patri k dane kategorii
		const createPagePromise = advertEntityPromise.then((advertEntity) => {
			if (!advertEntity) {
				return
			}

			const { category: { id: categoryId } = {} } = advertEntity
			const { URL_API_PARAMS } = AdvertAdminUrlConvertor.constants

			const params = {
				[URL_API_PARAMS.CATEGORY_ID]: categoryId
			}

			return this._advertService.getCreatePage(params, { cache: true })
		})

		const formLineEntity = Promise.all([createPagePromise, advertEntityPromise]).then(
			([createPage, advertEntity]) => {
				if (!advertEntity) {
					return
				}

				const elements = createPage.sections.reduce((allElements, section) => {
					const { elements } = section
					return [...allElements, ...elements]
				}, [])

				const { options: equipmentOptions = [] } =
					elements.find((e) => e.name === EQUIPMENT_CB) || {}

				return createEquipmentFormLineEntity(advertEntity, equipmentOptions)
			}
		)

		return Object.assign({}, superState, {
			[STATE_KEYS.FORM_LINE]: formLineEntity,
			[STATE_KEYS.ON_CHANGE]: this._changeFormLineEntity,

			[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false,
			[STATE_KEYS.SUBMIT_FORM]: this._submitForm,

			[STATE_KEYS.SET_FORM_STATUS]: this._setFormStatus,

			[STATE_KEYS.LOAD_EQUIPMENT_FROM_CEBIA]: this._loadEquipmentDataFromCebia,

			[STATE_KEYS.SCROLL_PAGE_TO_TOP]: this._scrollPageToTop
		})
	}

	async _loadEquipmentDataFromCebia(vin) {
		const mergeWithFormLineValue = (cebiaValues = []) => {
			const { [STATE_KEYS.FORM_LINE]: formLineEntity } = this.getState()

			const { value = [], options = [] } = formLineEntity

			return Array.from(
				new Set([
					...value,
					...cebiaValues.filter((value) => options.find((o) => o.value === value))
				])
			)
		}

		if (vin && vin.trim()) {
			// pokud byl uzivatelem zadan VIN vozidla, tak se dotazeme na API,
			// ktere nam z Cebie stahne dostupna data o vybave vozidla.
			// V tomto kroku zadavani noveho inzeratu predvyplnime uzivateli
			// temito daty formular.
			this.setState({
				[STATE_KEYS.IS_EQUIPMENT_FROM_CEBIA_BEING_LOADED]: true
			})

			try {
				const data = await this._VINVehicleDataService.getVehicleEquipmentData(vin)

				this._changeFormLineEntity({
					value: mergeWithFormLineValue(data)
				})
			} catch (error) {
				// pokud se neco pokazi pri stahovani dat z Cebie,
				// tak i presto umoznime uzivateli pokracovat v
				// zadavani noveho inzeratu
			} finally {
				this.setState({
					[STATE_KEYS.IS_EQUIPMENT_FROM_CEBIA_BEING_LOADED]: false
				})
			}
		}
	}

	_changeFormLineEntity(change) {
		const { [STATE_KEYS.FORM_LINE]: formLineEntity } = this.getState()

		this.setState({
			[STATE_KEYS.FORM_LINE]: FormLines.updateEntity(formLineEntity, change)
		})
	}

	async _submitForm({ formLineEntity, onErrorCallback }) {
		const { id, value, extra: { getFormData } = {} } = formLineEntity

		if (!getFormData) {
			/* eslint-disable */
			console.warn(`Field "${id}" is missing "getFormData" function.`)
			/* eslint-enable */
		}

		const formData = getFormData && getFormData(value)

		try {
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: true
			})

			const advertId = this._getAdvertId()

			await this._advertService.patchAdvert(advertId, formData)

			this._onSubmitFormSuccessCallback()
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)
			let errors = []

			if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				errors = body.errors
			}

			this._onSubmitFormErrorCallback()

			onErrorCallback(errors)

			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false
			})
		}
	}

	_setFormStatus({ statusCode, extra }) {
		this.setState({
			[STATE_KEYS.FORM_STATUS]: { statusCode, extra }
		})
	}

	_scrollPageToTop() {
		animateScrollTo(0)
	}
}
