import React from 'react'
import BaseWidget from '../base/BaseWidget'
import Select from 'app/component/select/Select'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './SelectWidget.less'

export const SELECT_WIDGET_ID = 'select_widget'

const CLASSNAME = 'c-cp-select-widget'

function SelectWidget({ formLineEntity, onChange, section }) {
	return (
		<BaseWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={SELECT_WIDGET_ID}
			renderLabel={(formLineEntity, props) => {
				const { label } = formLineEntity
				const { labelId, inputId } = AutoComplete.getIdsPropsHelper(formLineEntity.id)

				return (
					<label {...props} id={labelId} htmlFor={inputId} className={`${CLASSNAME}__label`}>
						{label}
					</label>
				)
			}}
			renderInput={(formLineEntity, { className, value: formValue }) => {
				const { id, disabled, extra: { values } = {} } = formLineEntity

				return (
					<div className={`${CLASSNAME}__input-wrapper`}>
						<Select
							className={`${className} ${CLASSNAME}__input`}
							onSelect={({ value }) =>
								onChange({
									id: formLineEntity.id,
									value,
									section
								})
							}
							size='small'
							disabled={Boolean(disabled)}
							items={values}
							selectedItem={values.find(({ value }) => value === formValue) || null}
							{...AutoComplete.getIdsPropsHelper(id)}
						/>
					</div>
				)
			}}
		/>
	)
}

SelectWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func.isRequired,
	section: PropTypes.string
}

export default SelectWidget
