import React, { useState, useContext } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import STATE_KEYS from './BoardBehindWindscreenStateKeys'
import AdvertTitle from 'app/component/advertTitle/AdvertTitle'
import { Format, HttpStatusCodes } from '@inzeraty/helpers'
import Logo from 'app/component/logo/Logo'
import { UserService } from '@inzeraty/models'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import Error from 'app/page/error/Error'
import classnames from 'classnames'
import { Button } from '@sznds/react'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'

import 'app/base/BaseCS.json'
import './BoardBehindWindscreenCS.json'
import './BoardBehindWindscreenView.less'

const CLASSNAME = 'p-ca-board-behind-windscreen'

const BoardBehindWindscreenView = ({ [STATE_KEYS.ADVERT_ENTITY]: advertEntity }) => {
	const localize = useLocalize()
	const userEntity = useContext(UserEntityContext) || {}

	const [isLandscape, setIsLandscape] = useState(false)

	// Format.dateShort vrati datum bez mesice jestli jde o prvni mesic
	const DATE_WITHOUT_FIRT_MONTH = false

	const _renderLabel = (labelName, labelInfo) => {
		return (
			labelInfo && (
				<dl className={`${CLASSNAME}__row`}>
					<dt className={`${CLASSNAME}__label`}>{labelName}:</dt>{' '}
					<dd className={`${CLASSNAME}__value`}>{labelInfo}</dd>
				</dl>
			)
		)
	}

	const renderDate = () => {
		const { manufacturingDate, inOperationDate } = advertEntity

		const manufacturYear = manufacturingDate
			? Format.dateShort(manufacturingDate, DATE_WITHOUT_FIRT_MONTH)
			: null
		const inOperationYear = inOperationDate
			? Format.dateShort(inOperationDate, DATE_WITHOUT_FIRT_MONTH)
			: null

		// primarne zobrazujeme rok vyroby
		if (manufacturYear) {
			return _renderLabel(localize('BoardBehindWindscreen.manufacturingDate'), manufacturYear)
		} else {
			return _renderLabel(localize('BoardBehindWindscreen.date'), inOperationYear)
		}
	}

	const renderTachometer = () => {
		const { tachometer } = advertEntity
		const tachometerValue = tachometer ? `${Format.number(tachometer)} ${localize('Base.KM')}` : ''

		return _renderLabel(localize('BoardBehindWindscreen.tachometer'), tachometerValue)
	}

	const renderFuel = () => {
		const { fuelCb = {} } = advertEntity
		const { name: fuelName } = fuelCb

		const fuel = fuelName ? Format.capitalizeFirstLetter(fuelName) : ''

		return _renderLabel(localize('BoardBehindWindscreen.fuelCb'), fuel)
	}

	const renderEngineVolume = () => {
		const { engineVolume } = advertEntity

		const volume = engineVolume
			? localize('BoardBehindWindscreen.engineVolumeValue', {
					ENGINE_VOLUME: Format.number(engineVolume)
			  })
			: ''

		return _renderLabel(localize('BoardBehindWindscreen.engineVolume'), volume)
	}

	const renderEnginePower = () => {
		const { enginePower } = advertEntity

		const power = enginePower ? `${Format.number(enginePower)} ${localize('Base.KW')}` : ''

		return _renderLabel(localize('BoardBehindWindscreen.enginePower'), power)
	}

	const renderStkDate = () => {
		const { stkDate } = advertEntity

		const stkYear = stkDate ? Format.dateShort(stkDate, DATE_WITHOUT_FIRT_MONTH) : ''

		return _renderLabel(localize('BoardBehindWindscreen.validStkDate'), stkYear)
	}

	const getBasicInfoCount = () => {
		const {
			manufacturingDate,
			inOperationDate,
			tachometer,
			fuelCb,
			engineVolume,
			enginePower,
			stkDate
		} = advertEntity

		return [
			manufacturingDate || inOperationDate,
			tachometer,
			fuelCb,
			engineVolume,
			enginePower,
			stkDate
		].filter((value) => !!value).length
	}

	const renderBasicInfo = () => {
		const basicInfoCount = getBasicInfoCount()

		return (
			<div
				className={classnames({
					[`${CLASSNAME}__basic-info-section`]: true,
					[`${CLASSNAME}__basic-info-section--count-${basicInfoCount}`]: true
				})}
			>
				{renderDate()}
				{renderTachometer()}
				{renderFuel()}
				{renderEngineVolume()}
				{renderEnginePower()}
				{renderStkDate()}
			</div>
		)
	}

	const renderState = () => {
		const {
			conditionCb: { name: conditionName = '' } = {},
			crashedInPast,
			firstOwner,
			serviceBook
		} = advertEntity

		const text = [
			conditionName,
			crashedInPast === false ? localize('BoardBehindWindscreen.notCrashedInPast') : '',
			firstOwner === true ? localize('BoardBehindWindscreen.firstOwner') : '',
			serviceBook === true ? localize('BoardBehindWindscreen.serviceBook') : ''
		]
			.filter((text) => !!text)
			.join(', ')

		return (
			text && (
				<div className={`${CLASSNAME}__status-section`}>
					{_renderLabel(localize('BoardBehindWindscreen.conditionCb'), text)}
				</div>
			)
		)
	}

	const renderEquipments = () => {
		const { equipmentCb = [] } = advertEntity

		const { heightLandscape, heightPortrait } = getEquipmentMaxRowsValue()

		return (
			equipmentCb.length > 0 && (
				<div
					className={classnames({
						[`${CLASSNAME}__equipment-section`]: true,
						[`${CLASSNAME}__equipment-section--${heightLandscape}`]: isLandscape,
						[`${CLASSNAME}__equipment-section--${heightPortrait}`]: !isLandscape
					})}
				>
					{_renderLabel(
						localize('BoardBehindWindscreen.equipment'),
						equipmentCb.map((equipment) => Format.capitalizeFirstLetter(equipment.name)).join(', ')
					)}
				</div>
			)
		)
	}

	const getEquipmentMaxRowsValue = () => {
		const {
			windshieldNote,
			conditionCb: { name: conditionName = '' } = {},
			crashedInPast,
			firstOwner,
			serviceBook,
			premise = {},
			stkDate,
			enginePower
		} = advertEntity

		const { logo } = premise

		const ROWS_COUNT = {
			LANDSCAPE: {
				TWO: 'two',
				THREE: 'three',
				FOUR: 'four',
				FIVE: 'five',
				SIX: 'six',
				SEVEN: 'seven',
				EIGHT: 'eight'
			},
			PORTRAIT: {
				NINE: 'nine',
				TEN: 'ten',
				ELEVEN: 'eleven',
				TWELVE: 'twelve',
				THIRTEEN: 'thirteen',
				FOURTEEN: 'fourteen',
				FIFTEEN: 'fifteen',
				SIXTEEN: 'sixteen',
				SEVENTEEN: 'seventeen'
			}
		}

		let heightLandscape = ''
		let heightPortrait = ''

		const hasFiveOrMoreBasicInfoValues = stkDate || enginePower

		const hasCondition = conditionName || !crashedInPast || firstOwner || serviceBook

		if (hasFiveOrMoreBasicInfoValues && windshieldNote && hasCondition && logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.TWO
			heightPortrait = ROWS_COUNT.PORTRAIT.NINE
		} else if (!hasFiveOrMoreBasicInfoValues && windshieldNote && hasCondition && logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.TWO
			heightPortrait = ROWS_COUNT.PORTRAIT.TEN
		} else if (hasFiveOrMoreBasicInfoValues && !windshieldNote && hasCondition && logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.FIVE
			heightPortrait = ROWS_COUNT.PORTRAIT.TWELVE
		} else if (hasFiveOrMoreBasicInfoValues && windshieldNote && !hasCondition && logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.THREE
			heightPortrait = ROWS_COUNT.PORTRAIT.ELEVEN
		} else if (hasFiveOrMoreBasicInfoValues && windshieldNote && hasCondition && !logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.THREE
			heightPortrait = ROWS_COUNT.PORTRAIT.ELEVEN
		} else if (!hasFiveOrMoreBasicInfoValues && !windshieldNote && hasCondition && logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.FOUR
			heightPortrait = ROWS_COUNT.PORTRAIT.THIRTEEN
		} else if (hasFiveOrMoreBasicInfoValues && !windshieldNote && !hasCondition && logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.SIX
			heightPortrait = ROWS_COUNT.PORTRAIT.THIRTEEN
		} else if (hasFiveOrMoreBasicInfoValues && windshieldNote && !hasCondition && !logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.FIVE
			heightPortrait = ROWS_COUNT.PORTRAIT.THIRTEEN
		} else if (!hasFiveOrMoreBasicInfoValues && !windshieldNote && !hasCondition && logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.FIVE
			heightPortrait = ROWS_COUNT.PORTRAIT.FOURTEEN
		} else if (hasFiveOrMoreBasicInfoValues && !windshieldNote && !hasCondition && !logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.SEVEN
			heightPortrait = ROWS_COUNT.PORTRAIT.FIFTEEN
		} else if (!hasFiveOrMoreBasicInfoValues && !windshieldNote && hasCondition && !logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.SIX
			heightPortrait = ROWS_COUNT.PORTRAIT.FIFTEEN
		} else if (!hasFiveOrMoreBasicInfoValues && windshieldNote && !hasCondition && logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.THREE
			heightPortrait = ROWS_COUNT.PORTRAIT.TWELVE
		} else if (!hasFiveOrMoreBasicInfoValues && windshieldNote && hasCondition && !logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.THREE
			heightPortrait = ROWS_COUNT.PORTRAIT.TWELVE
		} else if (hasFiveOrMoreBasicInfoValues && !windshieldNote && hasCondition && !logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.SIX
			heightPortrait = ROWS_COUNT.PORTRAIT.FOURTEEN
		} else if (!hasFiveOrMoreBasicInfoValues && windshieldNote && !hasCondition && !logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.FIVE
			heightPortrait = ROWS_COUNT.PORTRAIT.FOURTEEN
		} else if (!hasFiveOrMoreBasicInfoValues && !windshieldNote && !hasCondition && !logo) {
			heightLandscape = ROWS_COUNT.LANDSCAPE.SEVEN
			heightPortrait = ROWS_COUNT.PORTRAIT.SIXTEEN
		} else {
			heightLandscape = ROWS_COUNT.LANDSCAPE.TWO
			heightPortrait = ROWS_COUNT.PORTRAIT.NINE
		}

		return {
			heightPortrait,
			heightLandscape
		}
	}

	const renderWindshieldNote = () => {
		const { windshieldNote } = advertEntity

		return (
			windshieldNote && (
				<div className={`${CLASSNAME}__windshield-note-section`}>
					{_renderLabel(localize('BoardBehindWindscreen.windshieldNote'), windshieldNote)}
				</div>
			)
		)
	}

	const renderPrice = () => {
		const {
			dealType,
			operatingLeasePriceWithoutVat,
			price,
			priceWithoutVat,
			priceIsWithoutVat,
			priceIsVatDeductible,
			priceOriginal
		} = advertEntity

		const priceText = price ? (
			<span className='notranslate' translate='no' lang='cs-cz'>
				{Format.number(price)},- {localize('Base.CZK')}
			</span>
		) : (
			''
		)
		const priceWithoutVatText =
			priceIsWithoutVat || priceIsVatDeductible ? (
				<span className='notranslate' translate='no' lang='cs-cz'>
					{Format.number(priceWithoutVat || priceOriginal)},- {localize('Base.CZK')}
				</span>
			) : (
				''
			)
		const operatingLeasePriceWithoutVatText = operatingLeasePriceWithoutVat ? (
			<span className='notranslate' translate='no' lang='cs-cz'>
				{localize('BoardBehindWindscreen.operatingLeasePriceWithoutVat', {
					PRICE: Format.number(operatingLeasePriceWithoutVat),
					CURRENCY: localize('Base.CZK')
				})}
			</span>
		) : (
			''
		)

		const renderPriceForOperLeaseOnly = () => (
			<div className={`${CLASSNAME}__price-leasing`}>{operatingLeasePriceWithoutVatText}</div>
		)

		const renderPriceForSale = () => (
			<>
				<div className={`${CLASSNAME}__price`}>{priceText}</div>
				{_renderLabel(localize('BoardBehindWindscreen.priceWithoutVat'), priceWithoutVatText)}
			</>
		)

		const renderPriceForOperLeaseWhenCombined = () =>
			_renderLabel(
				localize('BoardBehindWindscreen.operatingLeasePriceLabel'),
				operatingLeasePriceWithoutVatText
			)

		return (
			<div className={`${CLASSNAME}__price-section`}>
				{dealType === DEAL_TYPE.OPERATING_LEASE ? (
					renderPriceForOperLeaseOnly()
				) : (
					<>
						{renderPriceForSale()}
						{dealType === DEAL_TYPE.SALE_OR_LEASE && renderPriceForOperLeaseWhenCombined()}
					</>
				)}
			</div>
		)
	}

	const renderSeller = () => {
		const { premise = {} } = advertEntity
		const { name, logo, web } = premise

		return (
			<div className={`${CLASSNAME}__premise-box`}>
				{!!logo && <img className={`${CLASSNAME}__premise-logo`} alt='' src={logo} />}
				{!!name && (
					<div>
						{name}
						<br />
						{web}
					</div>
				)}
			</div>
		)
	}

	const renderContent = () => {
		if (UserService.isUserLogged(userEntity)) {
			const { user = {}, premise = {} } = advertEntity
			const { id: advertUserId } = user
			const { id: advertPremiseId = '' } = premise
			const { id: userId, premises = [] } = userEntity

			const printCss = `@page { size: A4 ${isLandscape ? 'landscape' : 'portrait'}; margin: 0; }`

			if (advertUserId === userId || premises.some((premise) => premise.id === advertPremiseId)) {
				return (
					<div
						className={classnames({
							[CLASSNAME]: true,
							[`${CLASSNAME}--portrait`]: !isLandscape,
							[`${CLASSNAME}--landscape`]: isLandscape
						})}
					>
						<style type='text/css' media='print'>
							{printCss}
						</style>
						<div className={`${CLASSNAME}__print-menu`}>
							<Button
								className={classnames({
									[`${CLASSNAME}__orientation-btn`]: true,
									[`${CLASSNAME}__orientation-btn--portrait`]: true,
									[`${CLASSNAME}__orientation-btn--selected`]: !isLandscape
								})}
								onClick={() => {
									setIsLandscape(false)
								}}
								text={localize('BoardBehindWindscreen.a4Portrait')}
							/>
							<Button
								className={classnames({
									[`${CLASSNAME}__orientation-btn`]: true,
									[`${CLASSNAME}__orientation-btn--landscape`]: true,
									[`${CLASSNAME}__orientation-btn--selected`]: isLandscape
								})}
								onClick={() => {
									setIsLandscape(true)
								}}
								text={localize('BoardBehindWindscreen.a4Landscape')}
							/>
							<Button
								primary={true}
								className={`${CLASSNAME}__orientation-btn ${CLASSNAME}__orientation-btn--print`}
								onClick={() => {
									window.print()
								}}
								text={localize('BoardBehindWindscreen.print')}
							/>
						</div>
						<div className={`${CLASSNAME}__page`}>
							<AdvertTitle advertEntity={advertEntity} />
							{renderBasicInfo()}
							{renderState()}
							{renderEquipments()}
							{renderWindshieldNote()}
							<div className={`${CLASSNAME}__spacer`}></div>
							{renderPrice()}
							<div className={`${CLASSNAME}__footer`}>
								{renderSeller()}
								<Logo className={`${CLASSNAME}__logo-sauto`} />
							</div>
						</div>
					</div>
				)
			} else {
				return <Error status={HttpStatusCodes.FORBIDDEN} />
			}
		} else {
			return <Error status={HttpStatusCodes.UNAUTHORIZED} />
		}
	}

	return <>{advertEntity && renderContent()}</>
}

export default BoardBehindWindscreenView
