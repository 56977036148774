
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['DialogInternetExplorer'] = {
  title: function(d) { return "Upozornění"; },
  text: function(d) { return "Ve vašem prohlížeči nemusí Sauto.cz v budoucnu fungovat správně"; },
  whatNext: function(d) { return "Co s tím?"; },
  "try": function(d) { return "Zkuste třeba"; },
  sBrowser: function(d) { return "Seznam prohlížeč"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	