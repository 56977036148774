import { DrtgHelper as InzeratyDrtgHelper } from '@inzeraty/helpers'
import RouterEvents from 'ima/router/Events'
import svg4everybody from 'svg4everybody'
import SelfXSS from 'ima-plugin-self-xss'
import { PageLoader } from '@inzeraty/components'
// import HomeUrlConvertor from 'app/page/userweb/home/HomeUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import * as Sentry from '@sentry/browser'
import '@Sklik-aa/fisher'
import { CLICK_FROM_VALUES } from 'app/component/advertList/AdvertListConstants'
import { getDetailInfoFromLocalStorage } from 'app/component/advertList/AdvertListHelper'
import { STATUS_CONSTANTS } from 'app/model/advert/AdvertConstants'
import { getSearchTypeSessionOnAdvertDetail } from 'app/helpers/sessionStorageHelpers/getSearchTypeSessionHelper'

export const init = (ns, oc, config) => {
	// jshint ignore:line
	const $window = oc.get('$Window')
	const $router = oc.get('$Router')
	const $dispatcher = oc.get('$Dispatcher')
	const dotAnalytic = oc.get('DotAnalytic')
	// const gemiusAnalytic = oc.get('GemiusAnalytic')
	const bfCache = oc.get('BFCache')
	const historyStorage = oc.get('HistoryStorage')
	const sspHelper = oc.get('SspHelper')
	const drtgHelper = oc.get('DrtgHelper')
	const selfXSS = oc.get(SelfXSS)
	const $settings = oc.get('$Settings')
	const $httpUrlTransformer = oc.get('$HttpUrlTransformer')
	const loginHelper = oc.get('LoginHelper')
	const geoLocation = oc.get('Geolocation')
	const scriptLoaderPlugin = oc.get('ScriptLoaderPlugin')
	const abTestHelper = oc.get('ABTestHelper')
	const CmpHelper = oc.get('CmpHelper')
	const $SessionStorage = oc.get('$SessionStorage')

	let errorReportCount = 0

	const MAX_ERROR_REPORT_COUNT = 2
	const IGNORE_EXCEPTION_NAMES = ['SecurityError']
	// seznam skriptu tretich stran, u kterych budeme ignorovat chyby. Nechceme,
	// aby nam tyto skripty pri nejake chybe "rozbily" webovku. Tedy aby nedoslo k
	// zobrazeni chybove stranky.
	const IGNORE_EXCEPTION_FROM_SCRIPTS = [
		'xgemius.js',
		'login.js',
		'dot-small.js',
		'ssp.js',
		'rc.js',
		'geo.js',
		'scmp.js',
		'seno.js'
	]

	config.$IMA.fatalErrorHandler = (e) => {
		console.error('FATAL ERROR HANDLER:', e)
	}

	$window.bindEventListener($window.getWindow(), 'error', (e) => {
		const error = e.error

		if (oc.get('$Env') !== 'prod') {
			console.error(error)
		}

		if (
			error &&
			error.name &&
			error.name === 'SyntaxError' &&
			error.message &&
			error.message.includes('JSON')
		) {
			hitClientErrorToRealtimeAnalytic(error)
			return
		}

		if (error && error.name && IGNORE_EXCEPTION_NAMES.indexOf(error.name) === -1) {
			hitClientErrorToRealtimeAnalytic(error)

			// ze stack trace se snazime zjistit, v jakem skriptu doslo k chybe
			const stack = error.stack
			const regExp = new RegExp('(' + IGNORE_EXCEPTION_FROM_SCRIPTS.join('|') + ')')

			if (stack && typeof stack === 'string' && stack.search(regExp) > 0) {
				// na chybu zpusobenou skriptem tretich stran nebudeme nijak reagovat
				return
			}

			let currentRouteOptions = {}
			try {
				currentRouteOptions = $router.getCurrentRouteInfo().route.getOptions()
			} catch (e) {
				// na chybu nebudeme nijak reagovat
			}

			// nastavime odpovidajici chybovou stranku
			$router.handleError({ error }, currentRouteOptions).catch((fatalError) => {
				config.$IMA.fatalErrorHandler(fatalError)
			})
		} else {
			hitClientErrorToRealtimeAnalytic(error || new Error('Null error.'))
		}
	})

	if (!$window.isClient()) {
		if (oc.get('$Env') === 'prod') {
			$httpUrlTransformer.addRule($settings.api.baseUrl, $settings.api.internalApiUrl)
		}
	}

	if ($window.isClient()) {
		if (config.$IMA.$Env === 'prod') {
			// pro zapnuti Sentry na lokale (pro ucely testovani):
			//   1) nastavit podminku nahore na true nebo uplne zakomentovat
			//   2) v Sentry.init dole nastavit environment na 'test'
			//
			// Logy je pak mozne sledovat na https://sentry.sklik.cz/oborovky/sauto/?environment=test
			Sentry.init({
				release: `sauto:${config.$IMA.$Version}`,
				environment: config.$IMA.$Host.includes('.test') ? 'test' : 'production',
				dsn: 'https://4da2408313df4a048ee98439f0511804@sentry.sklik.cz/237'
			})
		}

		selfXSS.init()

		loginHelper.init()

		// Podpora práce s externím svg spritem pro všechny browsery.
		svg4everybody()

		historyStorage.init()

		// gemiusAnalytic.init()
		// gemiusAnalytic.load()

		// insert analytic script to page and initialization analytic
		dotAnalytic.init()
		dotAnalytic.load().then(() => {
			abTestHelper.init()

			// Inicializuje ssp (výdej reklamy)
			sspHelper.init()
		})

		// Inicializace (stažení a nastavení) retargeting scriptu
		InzeratyDrtgHelper.init(scriptLoaderPlugin)

		// stahne skript pro praci se seznamackou geolokaci
		geoLocation.init()

		CmpHelper.init()

		bfCache.init()

		$dispatcher.listen(RouterEvents.BEFORE_HANDLE_ROUTE, (pageData) => {
			PageLoader.start()
		})

		//set hitting page view to analytic
		$dispatcher.listen(RouterEvents.AFTER_HANDLE_ROUTE, (pageData) => {
			PageLoader.end()

			if (
				pageData &&
				pageData.response &&
				pageData.response.status >= 200 &&
				pageData.response.status < 500
			) {
				dotAnalytic.hit({
					action: 'test_impress'
				})
				dotAnalyticImpress(pageData)
				// geminiHitPageView(pageData)
				hitAdvertDetail(pageData)

				drtgHelper.hitPageView(pageData)
			} else {
				const { error = {} } = pageData.params

				dotAnalytic.reconfiguration({
					impress: true
				})

				// TODO - až bude hotový monitoring, tak odkomentovat.
				hitClientErrorToRealtimeAnalytic(error)

				const msg = error.message

				// report server chyby do reportera
				dotAnalytic.hitError({
					type: 'server',
					message: msg ? `Server error: ${msg}` : 'Server error'
				})
			}
		})
	}

	// function geminiHitPageView(pageData) {
	// 	let hitData = null

	// 	switch (pageData.route.getName()) {
	// 		case ROUTE_NAMES.USERWEB.HOMEPAGE:
	// 			hitData = geminiHpHitPageView(
	// 				pageData.params[HomeUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_SEO_NAME]
	// 			)
	// 			break

	// 		case ROUTE_NAMES.USERWEB.ADVERT_LIST:
	// 		case ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES:
	// 			hitData = {
	// 				identifier: 'p4AwrPtNL_d6CQPBZtJKTacT.ogIMkdmiggmZhok3Vb.H7',
	// 				parameters: 'gA=sauto.cz/Vypis_inzeratu'
	// 			}
	// 			break

	// 		case ROUTE_NAMES.USERWEB.ADVERT_DETAIL:
	// 		case ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL:
	// 			hitData = {
	// 				identifier: 'oliQa7diPzEARN53bLmuH8d.zZXY0wNw9K1VshcytQ..07',
	// 				parameters: 'gA=sauto.cz/Detail_inzeratu'
	// 			}
	// 			break

	// 		case ROUTE_NAMES.USERWEB.SELLER:
	// 		case ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES:
	// 			hitData = {
	// 				identifier: 'nL6abwBbG0nVDxTLP4Hrt9TaLaA1X8OgmSAfSYzVDVH.K7',
	// 				parameters: 'gA=sauto.cz/Stranky_prodejcu'
	// 			}
	// 			break
	// 	}

	// 	if (hitData) {
	// 		gemiusAnalytic.hit(hitData)
	// 	}
	// }

	// function geminiHpHitPageView(categorySeoName = '') {
	// 	let identifier
	// 	let parameters

	// 	switch (categorySeoName) {
	// 		case '':
	// 		case 'osobni':
	// 			identifier = 'B3NLoOtwEAcUMQD.RdOkr5Qb.oL6UUb4rt65dAgLlSz.i7'
	// 			parameters = 'gA=sauto.cz/HP/HP_osobni'
	// 			break

	// 		case 'uzitkova':
	// 			identifier = 'p4CQavtNPzG0JPQf9UuyHqdzXtEI8ge36qtWspJozXn.A7'
	// 			parameters = 'gA=sauto.cz/HP/HP_uzitkova'
	// 			break

	// 		case 'nakladni':
	// 			identifier = '.co6r2LpC8mgnoU5oobLGceC.ofYbEdm9D8GHhdOHPf.A7'
	// 			parameters = 'gA=sauto.cz/HP/HP_nakladni'
	// 			break

	// 		case 'motorky':
	// 			identifier = 'd2CbIgMsCGo9LUkkNO8DuOVy7FlpCkPbZJ6gGTcOnMP.q7'
	// 			parameters = 'gA=smoto.cz/HP_Motocykly'
	// 			break

	// 		case 'ctyrkolky':
	// 			identifier = 'dvaVJgMPrTvdjDCLXKPHHJbfTKNs.AQsFaawLevpdDf.b7'
	// 			parameters = 'gA=smoto.cz/HP_Ctyrkolky'
	// 			break

	// 		case 'privesy-navesy':
	// 			identifier = 'B7YwDetSL2eEsE5o5UyGkNU7Xmc1BAerb89W77f_JWn.07'
	// 			parameters = 'gA=sauto.cz/HP/HP_privesy'
	// 			break

	// 		case 'obytne':
	// 			identifier = '.WCQbWLcP2gG7T6GczD_wccQTIPYpewVVAYZjD8F_Dn.h7'
	// 			parameters = 'gA=sauto.cz/HP/HP_obytne'
	// 			break

	// 		case 'pracovni-stroje':
	// 			identifier = 'p4Ca0PtNGwEaObU_.scTnKdz7DoI8ivFtHNDPJcXSAv.S7'
	// 			parameters = 'gA=sauto.cz/HP/HP_stroje'
	// 			break

	// 		case 'autobusy':
	// 			identifier = 'B7Y6setSCw.qyuuntskOAdU77Do1BCvFOPzpQJVKiCz.M7'
	// 			parameters = 'gA=sauto.cz/HP/HP_busy'
	// 			break
	// 	}

	// 	if (identifier && parameters) {
	// 		return {
	// 			identifier,
	// 			parameters
	// 		}
	// 	} else {
	// 		return null
	// 	}
	// }

	function hitClientErrorToRealtimeAnalytic(error) {
		if (error && errorReportCount < MAX_ERROR_REPORT_COUNT) {
			errorReportCount += 1

			//const http = oc.get('$Http');

			const { message } = error
			//stack = JSON.stringify(stack);

			// report chyb do reportera
			dotAnalytic.hitError({
				message
			})

			// http.post(
			// 	$settings.plugin.monitoring.errorUrl,
			// 	{ name, message, stack, params, referrer, url }
			// )
			// 	.catch(requestError => {
			// 		console.error(requestError)
			// 	})
		}
	}

	function hitAdvertDetail(pageData) {
		const actualRouteName = pageData.route.getName()

		if (
			actualRouteName === ROUTE_NAMES.USERWEB.ADVERT_DETAIL ||
			actualRouteName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
		) {
			const isOperatingLeasePage = actualRouteName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
			const { advertEntity = {} } = pageData.response.pageState
			const { status = '' } = advertEntity

			if (status === STATUS_CONSTANTS.ACTIVE) {
				const clickedAdvert = getDetailInfoFromLocalStorage(advertEntity.id)

				if (clickedAdvert && Number(clickedAdvert.advertId) === advertEntity.id) {
					const { from: clickedAdvertFrom } = clickedAdvert

					dotAnalytic.hit(
						Object.assign(
							{
								route: 'advertDetail',
								action: 'advertDetailEvent',
								isOperatingLeasePage,
								fromList: clickedAdvertFrom === CLICK_FROM_VALUES.FROM_LIST,
								fromPO: clickedAdvertFrom === CLICK_FROM_VALUES.FROM_PREFERRED_OFFER,
								fromElsewhere: false
							},
							getAdvertDataDotData(advertEntity),
							getSearchTypeSessionOnAdvertDetail($SessionStorage)
						)
					)
				} else {
					dotAnalytic.hit(
						Object.assign(
							{
								route: 'advertDetail',
								action: 'advertDetailEvent',
								isOperatingLeasePage,
								fromList: false,
								fromPO: false,
								fromElsewhere: true
							},
							getAdvertDataDotData(advertEntity),
							getSearchTypeSessionOnAdvertDetail($SessionStorage)
						)
					)
				}
			}
		}
	}

	/**
	 * Ziska data-dot-data pro detail inzeratu
	 * @param {Object} advertEntity
	 */
	function getAdvertDataDotData(advertEntity = {}) {
		const {
			id: advertId,
			oldId,
			customId,
			dealType = '',
			premise: premiseEntity = {},
			user = {},
			category = {},
			manufacturerCb = {},
			modelCb = {},
			locality = {},
			price = '',
			operatingLeasePriceWithoutVat = '',
			source = '',
			status,
			deactivationReason,
			approvalAdmin,
			vehicleBodyCb = {}
		} = advertEntity

		const {
			id: premise_id = null,
			externalId: external_id,
			name: customerName = null
		} = premiseEntity
		const { id: user_id = null } = user
		const { id: category_id } = category
		const { value: manufacturer_id } = manufacturerCb
		const { value: model_id } = modelCb
		const { regionId: region_id, districtId: district_id } = locality
		const { value: vehicle_body_id } = vehicleBodyCb

		return {
			advertId,
			oldId,
			deal_type: dealType,
			custom_id: customId || null,
			premise_id,
			external_id,
			customerName,
			user_id,
			category_id,
			manufacturer_id,
			model_id,
			region_id,
			district_id,
			price,
			operating_lease_price_without_vat: operatingLeasePriceWithoutVat,
			source,
			status,
			deactivation_reason: deactivationReason,
			approval_admin: approvalAdmin,
			vehicle_body_id
		}
	}

	/**
	 * Provede zaměření
	 * @param {Object} pageData
	 */
	function dotAnalyticImpress(pageData) {
		const actualRouteName = pageData.route.getName()

		let dataDotData

		if (
			actualRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST ||
			actualRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
		) {
			const isOperatingLeasePage =
				actualRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES

			const { advertListAndPagination = {}, advertListPreferred = [] } = pageData.response.pageState

			const { advertList = [] } = advertListAndPagination

			const advertIds = advertList.map((advertEntity) => getAdvertDataDotData(advertEntity))
			const preferredIds = advertListPreferred.map((advertEntity) =>
				getAdvertDataDotData(advertEntity)
			)

			dataDotData = {
				isOperatingLeasePage,
				items: advertIds,
				route: 'advertList',
				preferredOffers: preferredIds
			}
		} else if (
			actualRouteName === ROUTE_NAMES.USERWEB.ADVERT_DETAIL ||
			actualRouteName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
		) {
			const isOperatingLeasePage = actualRouteName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
			const { advertEntity } = pageData.response.pageState

			dataDotData = Object.assign(
				{
					route: 'advertDetail',
					isOperatingLeasePage
				},
				getAdvertDataDotData(advertEntity)
			)
		} else if (
			actualRouteName === ROUTE_NAMES.USERWEB.SELLER ||
			actualRouteName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
		) {
			dataDotData = {
				route: 'seller'
			}
		} else if (actualRouteName === ROUTE_NAMES.USERWEB.HOMEPAGE) {
			dataDotData = {
				route: 'homepage'
			}
		} else {
			dataDotData = {}
		}

		// Před zaměřením imprese je potřeba nastavit data-dot-data o data, která chceme impresem poslat.
		document.body.setAttribute('data-dot-data', JSON.stringify(dataDotData))

		// spravime hit az ked sa spravne nastavia data
		dotAnalytic.reconfiguration({
			impress: true
		})
	}
}
