import AbstractExtension from 'ima/extension/AbstractExtension'
import ReviewsUrlConvertor from './ReviewsUrlConvertor'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import { FORM_LINES_NAMES as REVIEW_FORM_LINES_NAMES } from './reviewForm/ReviewFormLineConstants'
import { FORM_LINES_NAMES as REVIEW_REPLY_FORM_LINES_NAMES } from './reviewReplyForm/ReviewReplyFormLineConstants'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import ReviewEntity from 'app/model/review/ReviewEntity'
import ReviewReplyEntity from 'app/model/review/ReviewReplyEntity'
import UserwebBaseController from 'app/base/UserwebBaseController'
import { UserService } from '@inzeraty/models'
import { ASSIGN_EMAIL_EVENTS } from '@inzeraty/components'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

export const REVIEW_REPLIES_PERPAGE = 5

export default class ReviewsExtension extends AbstractExtension {
	static get STATE_IDS() {
		return {
			PARAMS: 'reviewListParams',
			LIST_PAGINATION: 'reviewListPagination',
			IS_LOADING: 'reviewListIsLoading',
			CREATE_PAGE_DATA: 'reviewCreatePageData',
			ON_ADD_REVIEW_SUBMIT: 'reviewOnSubmit',
			ON_ADD_REVIEW_REPLY_SUBMIT: 'reviewReplyOnSubmit',
			LOADING_ERROR: 'loadingError'
		}
	}

	static get PARAMS() {
		return {
			ADVERT_ENTITY: 'advert_entity',
			TYPE: 'type'
		}
	}

	static get TYPE() {
		return {
			ALL: '',
			POSITIVE: 'positive',
			NEGATIVE: 'negative',
			NEUTRAL: 'neutral'
		}
	}

	static get USER_FEEDBACK_TYPE() {
		return {
			POSITIVE: 'positive',
			NEGATIVE: 'negative',
			NONE: null
		}
	}

	constructor(reviewService, advertService, utils, dispatcher) {
		super()

		this._reviewService = reviewService
		this._advertService = advertService
		this._utils = utils
		this._dispatcher = dispatcher

		this._afterLoginAction = null

		this._userSelfUpdated = this._userSelfUpdated.bind(this)

		this._onSubmitAddReviewForm = this._onSubmitAddReviewForm.bind(this)
		this._saveReviewFeedback = this._saveReviewFeedback.bind(this)
		this._changeReviewFeedback = this._changeReviewFeedback.bind(this)
		this._deleteReviewFeedback = this._deleteReviewFeedback.bind(this)

		this._onSubmitAddReviewReplyForm = this._onSubmitAddReviewReplyForm.bind(this)
		this._saveReplyFeedback = this._saveReplyFeedback.bind(this)
		this._changeReplyFeedback = this._changeReplyFeedback.bind(this)
		this._deleteReplyFeedback = this._deleteReplyFeedback.bind(this)
	}

	load() {
		return {
			[ReviewsExtension.STATE_IDS.IS_LOADING]: true,
			[ReviewsExtension.STATE_IDS.ON_ADD_REVIEW_SUBMIT]: this._onSubmitAddReviewForm,
			[ReviewsExtension.STATE_IDS.ON_ADD_REVIEW_REPLY_SUBMIT]: this._onSubmitAddReviewReplyForm,
			[ReviewsExtension.STATE_IDS.LOADING_ERROR]: false,
			[ReviewsExtension.STATE_IDS.CREATE_PAGE_DATA]: null
		}
	}

	update() {
		this._cleanEventListeners()

		return {
			[ReviewsExtension.STATE_IDS.IS_LOADING]: true,
			[ReviewsExtension.STATE_IDS.LOADING_ERROR]: false
		}
	}

	deactivate() {
		this._cleanEventListeners()
	}

	_cleanEventListeners() {
		if (this._afterLoginAction) {
			this._afterLoginAction = null
			this._dispatcher.unlisten(
				UserwebBaseController.EVENT.USER_SELF_UPDATED,
				this._userSelfUpdated
			)
		}
	}

	_getStateReviewEntitiy(reviewId) {
		const {
			[ReviewsExtension.STATE_IDS.LIST_PAGINATION]: { reviewEntities = [] } = {}
		} = this.getState()

		return reviewEntities.find(({ id }) => id === reviewId) || {}
	}

	_updateStateReviewEntity(newReviewEntityData) {
		const {
			[ReviewsExtension.STATE_IDS.LIST_PAGINATION]: {
				reviewEntities: oldReviewEntities = [],
				paginationEntity
			} = {}
		} = this.getState()

		const { id: reviewId } = newReviewEntityData

		const newReviewEntities = [...oldReviewEntities].map((reviewEntity) => {
			const { id } = reviewEntity

			if (reviewId === id) {
				return new ReviewEntity(newReviewEntityData)
			} else {
				return reviewEntity
			}
		})

		this.setState({
			[ReviewsExtension.STATE_IDS.LIST_PAGINATION]: {
				reviewEntities: newReviewEntities,
				paginationEntity
			}
		})
	}

	_updateReviewListFeedback(
		reviewId,
		replyId,
		newUserFeedbackType,
		newPositiveFeedbackCount,
		newNegativeFeedbackCount
	) {
		const {
			[ReviewsExtension.STATE_IDS.LIST_PAGINATION]: { reviewEntities = [], paginationEntity } = {}
		} = this.getState()

		const newReviewEntities = [...reviewEntities].map((reviewEntity) => {
			const { id } = reviewEntity

			if (reviewId === id) {
				if (replyId) {
					return new ReviewEntity(
						Object.assign(reviewEntity, {
							reviewReplies: reviewEntity.reviewReplies.map((replyEntity) => {
								if (replyEntity.id === replyId) {
									return Object.assign(replyEntity, {
										userFeedbackType: newUserFeedbackType,
										negativeFeedbackCount: newNegativeFeedbackCount,
										positiveFeedbackCount: newPositiveFeedbackCount
									})
								} else {
									return replyEntity
								}
							})
						})
					)
				} else {
					return new ReviewEntity(
						Object.assign(reviewEntity, {
							userFeedbackType: newUserFeedbackType,
							negativeFeedbackCount: newNegativeFeedbackCount,
							positiveFeedbackCount: newPositiveFeedbackCount
						})
					)
				}
			} else {
				return reviewEntity
			}
		})

		this.setState({
			[ReviewsExtension.STATE_IDS.LIST_PAGINATION]: {
				reviewEntities: newReviewEntities,
				paginationEntity
			}
		})
	}

	async onGetCreatePageData() {
		const { [ReviewsExtension.STATE_IDS.PARAMS]: reviewListParamsPromise } = this.getState()
		const reviewListParams = await reviewListParamsPromise

		const {
			[ReviewsExtension.PARAMS.ADVERT_ENTITY]: {
				category: { id: categoryId }
			}
		} = reviewListParams

		this.setState({
			[ReviewsExtension.STATE_IDS.CREATE_PAGE_DATA]: null
		})

		const createPageData = await this._advertService.getCreatePage(
			{
				[AdvertAdminUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId
			},
			{ cache: true }
		)

		this.setState({
			[ReviewsExtension.STATE_IDS.CREATE_PAGE_DATA]: createPageData
		})
	}

	async onFetchReviews() {
		this.setState({
			[ReviewsExtension.STATE_IDS.IS_LOADING]: true
		})

		const { [ReviewsExtension.STATE_IDS.PARAMS]: reviewListParamsPromise } = this.getState()
		const reviewListParams = await reviewListParamsPromise

		let reviewData = null

		try {
			reviewData = await this._getReviewsAndPagination(reviewListParams).then((listPagination) => ({
				[ReviewsExtension.STATE_IDS.LIST_PAGINATION]: listPagination,
				[ReviewsExtension.STATE_IDS.LOADING_ERROR]: false
			}))
		} catch (e) {
			reviewData = {
				[ReviewsExtension.STATE_IDS.LOADING_ERROR]: true
			}
		}

		this.setState({
			[ReviewsExtension.STATE_IDS.IS_LOADING]: false,
			...reviewData
		})
	}

	async onUpdateParams(params = {}) {
		const {
			URL_APP_PARAMS: { PAGE }
		} = UrlConvertor.constants
		const { REVIEW_TYPE } = ReviewsUrlConvertor.constants.URL_APP_PARAMS

		const { [ReviewsExtension.STATE_IDS.PARAMS]: reviewListParamsPromise } = this.getState()

		// - pokud se meni typ recenze, prepnu na prvni stranku
		if (params.hasOwnProperty(REVIEW_TYPE)) {
			params[PAGE] = 1
		}

		const newParams = Object.assign({}, reviewListParamsPromise, params)

		const {
			[PAGE]: oldPage = 1,
			[REVIEW_TYPE]: oldReviewType = ReviewsExtension.TYPE.ALL
		} = reviewListParamsPromise
		const {
			[PAGE]: newPage = 1,
			[REVIEW_TYPE]: newReviewType = ReviewsExtension.TYPE.ALL
		} = newParams

		const isReadyToLoad = oldPage !== newPage || oldReviewType !== newReviewType

		this.setState({
			[ReviewsExtension.STATE_IDS.IS_LOADING]: isReadyToLoad,
			[ReviewsExtension.STATE_IDS.PARAMS]: newParams
		})

		if (isReadyToLoad) {
			this.onFetchReviews()
		}
	}

	async onDeleteReview(reviewId) {
		try {
			await this._reviewService.deleteReview(reviewId)
			this._showStatusMessageToast(
				this._utils.$Dictionary.get('Reviews.removeReviewSuccess'),
				StatusMessage.TYPE.SUCCESS
			)

			this.onFetchReviews()
		} catch (error) {
			this._showStatusMessageToast(
				this._utils.$Dictionary.get('Base.statusMessageError'),
				StatusMessage.TYPE.ERROR
			)
		}
	}

	async onDeleteReviewReply({ reviewId, replyId }) {
		try {
			await this._reviewService.deleteReply(reviewId, replyId)
			this._showStatusMessageToast(
				this._utils.$Dictionary.get('Reviews.removeReplySuccess'),
				StatusMessage.TYPE.SUCCESS
			)

			const reviewEntity = this._getStateReviewEntitiy(reviewId)
			const { reviewReplies, repliesCount } = reviewEntity

			let newReviewReplies = reviewReplies.filter(({ id }) => id !== replyId)
			let newRepliesCount = repliesCount - 1

			// pokud nejsou zobrazeny vsechny odpovedi, tak si musim stahnout prvni odpoved z nasledujici stranky
			if (reviewReplies.length < repliesCount) {
				const nextPageFirstReplyList = await this.getReviewReplies({
					reviewId,
					offset: reviewReplies.length - 1,
					limit: 1
				})

				const {
					reviewEntities: nextPageFirstReply,
					paginationEntity: { total }
				} = nextPageFirstReplyList

				newReviewReplies = [...newReviewReplies, ...nextPageFirstReply]
				newRepliesCount = total
			}

			const newReviewEntityData = Object.assign({}, reviewEntity, {
				reviewReplies: newReviewReplies,
				repliesCount: newRepliesCount
			})

			this._updateStateReviewEntity(newReviewEntityData)
		} catch (error) {
			this._showStatusMessageToast(
				this._utils.$Dictionary.get('Base.statusMessageError'),
				StatusMessage.TYPE.ERROR
			)
		}
	}

	async onGetReviewReplies({ reviewId, limit, offset }) {
		const replyListAndPagination = await this.getReviewReplies({ reviewId, limit, offset })

		if (replyListAndPagination) {
			const {
				reviewEntities,
				paginationEntity: { total }
			} = replyListAndPagination

			const reviewEntity = this._getStateReviewEntitiy(reviewId)

			const newReviewEntityData = Object.assign(reviewEntity, {
				reviewReplies:
					offset > 0 ? [...reviewEntity.reviewReplies, ...reviewEntities] : reviewEntities,
				repliesCount: total
			})

			this._updateStateReviewEntity(newReviewEntityData)
		}
	}

	async getReviewReplies({ reviewId, limit, offset }) {
		const {
			URL_API_PARAMS: { OFFSET, LIMIT }
		} = UrlConvertor.constants

		const params = {
			[LIMIT]: limit,
			[OFFSET]: offset
		}

		try {
			return this._reviewService.getRepliesList(reviewId, params)
		} catch (error) {
			this._showStatusMessageToast(
				this._utils.$Dictionary.get('Base.statusMessageError'),
				StatusMessage.TYPE.ERROR
			)

			return null
		}
	}

	_showStatusMessageToast(message, type) {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			title: message,
			type
		})
	}

	getProcessedFeedback(entity = {}, type) {
		let newUserFeedbackType
		let newPositiveFeedbackCount
		let newNegativeFeedbackCount

		const {
			userFeedbackType = ReviewsExtension.USER_FEEDBACK_TYPE.NONE,
			positiveFeedbackCount,
			negativeFeedbackCount
		} = entity

		const apiData = {}

		if (type === ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE) {
			apiData.type = 'positive'
			newUserFeedbackType =
				userFeedbackType !== ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE
					? ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE
					: ReviewsExtension.USER_FEEDBACK_TYPE.NONE
			newNegativeFeedbackCount =
				negativeFeedbackCount +
				(userFeedbackType === ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE ? -1 : 0)
			newPositiveFeedbackCount =
				positiveFeedbackCount +
				(userFeedbackType === ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE ? -1 : 1)
		} else if (type === ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE) {
			apiData.type = 'negative'
			newUserFeedbackType =
				userFeedbackType !== ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE
					? ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE
					: ReviewsExtension.USER_FEEDBACK_TYPE.NONE
			newNegativeFeedbackCount =
				negativeFeedbackCount +
				(userFeedbackType === ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE ? -1 : 1)
			newPositiveFeedbackCount =
				positiveFeedbackCount +
				(userFeedbackType === ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE ? -1 : 0)
		}

		return {
			apiData,
			userFeedbackType,
			newUserFeedbackType,
			newNegativeFeedbackCount,
			newPositiveFeedbackCount
		}
	}

	async onClickReviewFeedback({ reviewId, type }) {
		const replyId = null
		const reviewEntity = this._getStateReviewEntitiy(reviewId)

		const {
			apiData,
			userFeedbackType,
			newUserFeedbackType,
			newNegativeFeedbackCount,
			newPositiveFeedbackCount
		} = this.getProcessedFeedback(reviewEntity, type)

		let actionSuccess = false

		if (newUserFeedbackType === ReviewsExtension.USER_FEEDBACK_TYPE.NONE) {
			actionSuccess = await this._deleteReviewFeedback(reviewId)
		} else if (userFeedbackType !== ReviewsExtension.USER_FEEDBACK_TYPE.NONE) {
			actionSuccess = await this._changeReviewFeedback(reviewId, apiData)
		} else {
			actionSuccess = await this._saveReviewFeedback(reviewId, apiData)
		}

		if (actionSuccess) {
			this._updateReviewListFeedback(
				reviewId,
				replyId,
				newUserFeedbackType,
				newPositiveFeedbackCount,
				newNegativeFeedbackCount
			)
		}
	}

	async onClickReplyFeedback({ reviewId, replyId = null, type }) {
		const reviewEntity = this._getStateReviewEntitiy(reviewId)
		const replyEntity = reviewEntity?.reviewReplies?.find(({ id }) => id === replyId)

		const {
			apiData,
			userFeedbackType,
			newUserFeedbackType,
			newNegativeFeedbackCount,
			newPositiveFeedbackCount
		} = this.getProcessedFeedback(replyEntity, type)

		let actionSuccess = false

		if (newUserFeedbackType === ReviewsExtension.USER_FEEDBACK_TYPE.NONE) {
			actionSuccess = await this._deleteReplyFeedback(reviewId, replyId)
		} else if (userFeedbackType !== ReviewsExtension.USER_FEEDBACK_TYPE.NONE) {
			actionSuccess = await this._changeReplyFeedback(reviewId, replyId, apiData)
		} else {
			actionSuccess = await this._saveReplyFeedback(reviewId, replyId, apiData)
		}

		if (actionSuccess) {
			this._updateReviewListFeedback(
				reviewId,
				replyId,
				newUserFeedbackType,
				newPositiveFeedbackCount,
				newNegativeFeedbackCount
			)
		}
	}

	async _saveReviewFeedback(reviewId, apiData) {
		try {
			await this._reviewService.saveReviewFeedback(reviewId, apiData)
			return true
		} catch (error) {
			this._showFeedbackError()
			return false
		}
	}

	async _changeReviewFeedback(reviewId, apiData) {
		try {
			await this._reviewService.changeReviewFeedback(reviewId, apiData)
			return true
		} catch (error) {
			this._showFeedbackError()
			return false
		}
	}

	async _deleteReviewFeedback(reviewId) {
		try {
			await this._reviewService.deleteReviewFeedback(reviewId)
			return true
		} catch (error) {
			this._showFeedbackError()
			return false
		}
	}

	async _onSubmitAddReviewReplyForm(reviewId, reviewReplyData, onSubmitSuccess, onSubmitError) {
		const { URL_API_PARAMS } = ReviewsUrlConvertor.constants

		const { [REVIEW_REPLY_FORM_LINES_NAMES.REVIEW_REPLY]: reply } = reviewReplyData

		const apiData = {
			[URL_API_PARAMS.REVIEW_ID]: reviewId,
			[URL_API_PARAMS.REVIEW_REPLY]: reply
		}

		try {
			const result = await this._reviewService.saveReply(reviewId, apiData)

			const {
				body: { id: replyId }
			} = result

			this._showStatusMessageToast(
				this._utils.$Dictionary.get('Reviews.addReplySuccess'),
				StatusMessage.TYPE.SUCCESS
			)

			onSubmitSuccess(result)

			this._updateReviewReplyAfterAdd(reviewId, replyId, reply)
		} catch (error) {
			const { status, body } = error.getParams()
			const { errors = [] } = body || {}

			if (status === HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY) {
				onSubmitError(errors)
			} else {
				onSubmitError()
				this._showStatusMessageToast(
					this._utils.$Dictionary.get('Base.statusMessageError'),
					StatusMessage.TYPE.ERROR
				)
			}
		}
	}

	async _saveReplyFeedback(reviewId, replyId, apiData) {
		try {
			await this._reviewService.saveReplyFeedback(reviewId, replyId, apiData)
			return true
		} catch (error) {
			this._showFeedbackError()
			return false
		}
	}

	async _changeReplyFeedback(reviewId, replyId, apiData) {
		try {
			await this._reviewService.changeReplyFeedback(reviewId, replyId, apiData)
			return true
		} catch (error) {
			this._showFeedbackError()
			return false
		}
	}

	async _deleteReplyFeedback(reviewId, replyId) {
		try {
			await this._reviewService.deleteReplyFeedback(reviewId, replyId)
			return true
		} catch (error) {
			this._showFeedbackError()
			return false
		}
	}

	_showFeedbackError() {
		this._showStatusMessageToast(
			this._utils.$Dictionary.get('Base.statusMessageError'),
			StatusMessage.TYPE.ERROR
		)
	}

	async _updateReviewReplyAfterAdd(reviewId, replyId, reply) {
		const reviewEntity = this._getStateReviewEntitiy(reviewId)
		const { reviewReplies, repliesCount } = reviewEntity

		const updates = {
			repliesCount: repliesCount + 1
		}

		// - pokud mam zobrazeny vsechny odpovedi, tak novou pridam na konec
		if (reviewReplies.length >= repliesCount) {
			const { userSelf } = this.getState()
			const { id: userId, name: userName, login } = await userSelf

			const userEmailPrefix = login.split('@')[0]

			const addedReply = new ReviewReplyEntity({
				id: replyId,
				userId,
				userName,
				userEmailPrefix,
				reviewReply: reply,
				createDate: new Date()
			})

			updates.reviewReplies = [...reviewReplies, addedReply]
		}

		const newReviewEntityData = Object.assign({}, reviewEntity, updates)

		this._updateStateReviewEntity(newReviewEntityData)
	}

	async _onSubmitAddReviewForm(reviewData, onSubmitSuccess, onSubmitError) {
		const { URL_API_PARAMS } = ReviewsUrlConvertor.constants

		const {
			[REVIEW_FORM_LINES_NAMES.CATEGORY_ID]: categoryId,
			[REVIEW_FORM_LINES_NAMES.MANUFACTURER_CB]: { value: manufacturerCbId } = {},
			[REVIEW_FORM_LINES_NAMES.MODEL_CB]: { value: modelCbId } = {},
			[REVIEW_FORM_LINES_NAMES.MANUFACTURING_YEAR]: manufacturingYear,
			[REVIEW_FORM_LINES_NAMES.FUEL_CB]: { value: fuelCbId } = {},
			[REVIEW_FORM_LINES_NAMES.ENGINE_POWER]: enginePower,
			[REVIEW_FORM_LINES_NAMES.REVIEW_TITLE]: title,
			[REVIEW_FORM_LINES_NAMES.REVIEW_TYPE]: type,
			[REVIEW_FORM_LINES_NAMES.REVIEW_DECRIPTION]: review,
			[REVIEW_FORM_LINES_NAMES.POSITIVES]: positives,
			[REVIEW_FORM_LINES_NAMES.NEGATIVES]: negatives
		} = reviewData

		const apiData = {
			[URL_API_PARAMS.CATEGORY_ID]: categoryId,
			[URL_API_PARAMS.MANUFACTURER_CB_ID]: manufacturerCbId,
			[URL_API_PARAMS.MODEL_CB_ID]: modelCbId,
			[URL_API_PARAMS.MANUFACTURING_YEAR]: manufacturingYear,
			[URL_API_PARAMS.FUEL_CB_ID]: fuelCbId,
			[URL_API_PARAMS.ENGINE_POWER]: enginePower,
			[URL_API_PARAMS.REVIEW_TITLE]: title,
			[URL_API_PARAMS.REVIEW_TYPE]: type,
			[URL_API_PARAMS.REVIEW_DESCRIPTION]: review,
			[URL_API_PARAMS.REVIEW_POSITIVES]: positives,
			[URL_API_PARAMS.REVIEW_NEGATIVES]: negatives
		}

		try {
			const result = await this._reviewService.saveReview(apiData)

			this._showStatusMessageToast(
				this._utils.$Dictionary.get('Reviews.addReviewSuccess'),
				StatusMessage.TYPE.SUCCESS
			)

			onSubmitSuccess(result)

			this.onFetchReviews()
		} catch (error) {
			const { status, body } = error.getParams()
			const { errors = [] } = body || {}

			if (status === HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY) {
				onSubmitError(errors)
			} else {
				onSubmitError()
				this._showStatusMessageToast(
					this._utils.$Dictionary.get('Base.statusMessageError'),
					StatusMessage.TYPE.ERROR
				)
			}
		}
	}

	/**
	 * Vrátí zkusenosti majitelu se stránkováním.
	 *
	 * @private
	 * @method _getReviewsAndPagination
	 * @return {Promise<Object>}
	 */
	_getReviewsAndPagination(params) {
		const apiParams = ReviewsUrlConvertor.getApiParams(this._getParamsToConvert(params))
		return this._reviewService.getReviewsList(apiParams)
	}

	_getParamsToConvert(params) {
		const {
			URL_APP_PARAMS: { REVIEW_TYPE, CATEGORY_ID, MODEL_CB_ID }
		} = ReviewsUrlConvertor.constants
		const {
			URL_APP_PARAMS: { PER_PAGE, PAGE }
		} = UrlConvertor.constants

		const {
			[ReviewsExtension.PARAMS.ADVERT_ENTITY]: advertEntity,
			[PER_PAGE]: perPage,
			[REVIEW_TYPE]: type,
			[PAGE]: page
		} = params

		const { category: { id: categoryId } = {}, modelCb: { value: modelCbId } = {} } =
			advertEntity || {}

		return {
			[CATEGORY_ID]: categoryId,
			[MODEL_CB_ID]: modelCbId,
			[PER_PAGE]: perPage,
			[REVIEW_TYPE]: type,
			[PAGE]: page
		}
	}

	_userSelfUpdated() {
		if (this._afterLoginAction) {
			this._afterLoginAction()
			this._afterLoginAction = null
		}
	}

	async onCheckIsUserLogged({
		reason = '',
		onLogin = () => {},
		reloadList = false,
		sourceComponent = ''
	} = {}) {
		const { userSelf } = this.getState()
		const userEntity = await userSelf

		const checkAssigneEmail = () => {
			this._dispatcher.fire(ASSIGN_EMAIL_EVENTS.SHOW_DIALOG, {
				reason,
				onSuccess: onLogin
			})
		}

		if (UserService.isUserLogged(userEntity)) {
			checkAssigneEmail()
		} else {
			LoginHelper.openLogin({ sourceComponent })
			this._dispatcher.listen(UserwebBaseController.EVENT.USER_SELF_UPDATED, this._userSelfUpdated)
			this._afterLoginAction = () => {
				if (reloadList) {
					this.onFetchReviews().then(() => {
						checkAssigneEmail()
					})
				} else {
					checkAssigneEmail()
				}
			}
		}
	}

	getAllowedStateKeys() {
		return [
			ReviewsExtension.STATE_IDS.IS_LOADING,
			ReviewsExtension.STATE_IDS.PARAMS,
			ReviewsExtension.STATE_IDS.LIST_PAGINATION,
			ReviewsExtension.STATE_IDS.CREATE_PAGE_DATA,
			ReviewsExtension.STATE_IDS.LOADING_ERROR
		]
	}
}
