import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import BrandIcon from '../../brandIcon/BrandIconView'
import TextLink from 'app/component/textLink/TextLink'
import ModelsSearch from 'app/component/filters/forms/main/openers/brandModelOpener/modelsSearch/ModelsSearch'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import { Tag } from '@sznds/react'
import select from 'ima-plugin-select'
import { Loading } from '@inzeraty/components'
import { Format } from '@inzeraty/helpers'

import './AdvancedBrandModel.less'
import './AdvancedBrandModelCS.json'

const CLASSNAME = 'cf-advanced-brand-models'

class AdvancedBrandModel extends AbstractPureComponent {
	static get propTypes() {
		return {
			selectedBrand: PropTypes.shape({
				children: PropTypes.arrayOf(
					PropTypes.shape({
						value: PropTypes.number.isRequired,
						name: PropTypes.string.isRequired
					})
				),
				id: PropTypes.number,
				value: PropTypes.number,
				name: PropTypes.string,
				seoName: PropTypes.string
			}).isRequired,
			selectedModelValues: PropTypes.arrayOf(PropTypes.number).isRequired,
			onRemoveBrand: PropTypes.func.isRequired,
			categorySeoName: PropTypes.string,
			changeFilter: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			seoName: '',
			categorySeoName: ''
		}
	}

	constructor(props, context) {
		super(props, context)
	}

	render() {
		const {
			selectedModelValues,
			formLineEntity,
			changeFilter,
			selectedBrand,
			advertsCountForModels
		} = this.props

		return (
			<div className={CLASSNAME}>
				{this._renderBrandIcon()}
				<div className={`${CLASSNAME}__wrap`}>
					<div className={`${CLASSNAME}__models-brand-wrap`}>
						{selectedModelValues.length > 0 && this._renderSelectedModels()}
						<div>
							<ModelsSearch
								formLineEntity={formLineEntity}
								changeFilter={changeFilter}
								brand={selectedBrand}
								advertsCountForModels={advertsCountForModels}
								showLeftIcon={false}
							/>
						</div>
					</div>
					{this._renderRemoveBrand()}
				</div>
			</div>
		)
	}

	_renderBrandIcon() {
		const {
			selectedBrand,
			categorySeoName,
			advertsCountForBrands,
			advertsCountForModels,
			selectedModelValues
		} = this.props
		const { value: brandValue, name, seoName } = selectedBrand
		const { filteredAdvertsCount, isLoadingFilteredAdvertsCount } = advertsCountForBrands
		const { filteredAdvertsCount: filteredAdvertsCountForModels } = advertsCountForModels

		const brandCount = selectedModelValues.length
			? selectedModelValues.reduce(
					(total, modelValue) => total + (filteredAdvertsCountForModels[modelValue] || 0),
					0
			  )
			: filteredAdvertsCount[brandValue] || 0

		return (
			<div className={`${CLASSNAME}__icon-text`}>
				<BrandIcon
					value={brandValue}
					className={`${CLASSNAME}__icon`}
					seoName={seoName}
					categorySeoName={categorySeoName}
				/>
				<div>
					<span className={`${CLASSNAME}__text`}>{name}</span>
					{isLoadingFilteredAdvertsCount ? (
						<Loading
							className={`${CLASSNAME}__loading-dots`}
							loadingType={Loading.loadingType.ONLY_DOT}
						/>
					) : (
						<span className={`${CLASSNAME}__count`}>({Format.number(brandCount)})</span>
					)}
				</div>
			</div>
		)
	}

	_renderSelectedModels() {
		const { selectedBrand, selectedModelValues } = this.props
		const { value: brandValue, children: models } = selectedBrand

		return selectedModelValues.map((modelValue, key) => {
			const selectedModel = models.find((model) => model.value === modelValue)

			return (
				<div className={`${CLASSNAME}__tag`} key={key}>
					<Tag
						surface={3}
						size='small'
						onClick={() => {
							this._removeModel(brandValue, selectedModel.value)
						}}
					>
						{selectedModel.name}
					</Tag>
				</div>
			)
		})
	}

	_removeModel(brandValue, modelValue) {
		const {
			formLineEntity: { value, id },
			changeFilter
		} = this.props

		const selectedModelsForBrand = new Set(value.get(brandValue))
		selectedModelsForBrand.delete(modelValue)

		const newFormLineEntityValue = new Map(value)
		newFormLineEntityValue.set(brandValue, selectedModelsForBrand)

		changeFilter(id, newFormLineEntityValue)
	}

	_renderRemoveBrand() {
		const { onRemoveBrand, selectedBrand } = this.props
		const { value: brandValue } = selectedBrand

		return (
			<TextLink
				onClick={(event) => onRemoveBrand(event, brandValue)}
				dataDot='delete'
				className={`${CLASSNAME}__remove`}
			>
				{this.localize('AdvancedBrandModel.removeBrand')}
			</TextLink>
		)
	}
}

const selectors = (state) => ({
	openedFilterDropdown: state[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN]
})

export default select(selectors)(AdvancedBrandModel)
