import AbstractComponent from 'app/base/AbstractComponent'
import React from 'react'
import PropTypes from 'prop-types'
import IMAGES from 'app/base/ImagesConstants'

import './SvgIconView.less'

const CLASSNAME = 'atm-icon'

/**
 * @class SvgIconView
 * @namespace app.component.atoms.svgIcon
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.component
 */
export default class SvgIconView extends AbstractComponent {
	static get ICON_ID() {
		return {
			LIST: 'list',
			FILTERS: 'filters',
			LOGO_CEBIA: 'logo-cebia',
			RATING_STAR: 'rating-star',
			NO_IMAGE: 'no-image',
			LOCATION: 'location',
			CATEGORY_OSOBNI: 'category-osobni',
			CATEGORY_UZITKOVE: 'category-uzitkove',
			CATEGORY_NAKLADNI: 'category-nakladni',
			CATEGORY_MOTORKY: 'category-motorky',
			CATEGORY_CTYRKOLKY: 'category-ctyrkolky',
			CATEGORY_PRIVESY: 'category-privesy',
			CATEGORY_OBYTNE: 'category-obytne',
			CATEGORY_PRACOVNI: 'category-pracovni',
			CATEGORY_AUTOBUSY: 'category-autobusy',
			CAR_BODY_CUV: 'car-body-cuv',
			CAR_BODY_HATCHBACK: 'car-body-hatchback',
			CAR_BODY_KABRIOLET: 'car-body-kabriolet',
			CAR_BODY_KOMBI: 'car-body-kombi',
			CAR_BODY_KUPE: 'car-body-kupe',
			CAR_BODY_LIFTBACK: 'car-body-liftback',
			CAR_BODY_MPV: 'car-body-mpv',
			CAR_BODY_PICKUP: 'car-body-pick-up',
			CAR_BODY_ROADSTER: 'car-body-roadster',
			CAR_BODY_SEDANLIMUZINA: 'car-body-sedanlimuzina',
			CAR_BODY_SUV: 'car-body-suv',
			CAR_BODY_TERENNI: 'car-body-terenni',
			CAR_BODY_VAN: 'car-body-van'
		}
	}

	static get propTypes() {
		return {
			icon: PropTypes.oneOf(Object.keys(SvgIconView.ICON_ID)).isRequired,
			title: PropTypes.string,
			description: PropTypes.string,
			className: PropTypes.string,
			onClick: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			title: '',
			description: '',
			className: '',
			onClick: () => {}
		}
	}

	render() {
		const { icon, title, description, className, onClick } = this.props

		return (
			<svg
				role='img'
				className={this.cssClasses({
					[className]: !!className,
					[CLASSNAME]: true,
					[`${CLASSNAME}--${SvgIconView.ICON_ID[icon]}`]: true
				})}
				onClick={onClick}
			>
				{title && <title>{title}</title>}
				{description && <desc>{description}</desc>}

				<use xlinkHref={`${IMAGES.image__sprite}#${SvgIconView.ICON_ID[icon]}`} />
			</svg>
		)
	}
}
