import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import Downshift from 'downshift'
import { Format } from '@inzeraty/helpers'
import { Surface } from '@sznds/react'
import { Popup } from '@inzeraty/components'
import { useLocalize } from 'app/base/componentHelpers'
import PropTypes from 'prop-types'
import canBeDropdownRenderedBelow from 'app/helpers/dropdown/DropdownHelper'

import './AutoComplete.less'
import './AutoCompleteCS.json'

const CLASSNAME = 'c-auto-complete'

const DROPDOWN = 'DROPDOWN'
const POPUP = 'POPUP'

export const filterItems = (inputValue, filterValue) => {
	if (!inputValue) {
		return true
	} else {
		const inputValueTransformed = Format.removeDiacritics(inputValue).toLowerCase().trim()

		const filterValueTransformed = Format.removeDiacritics(filterValue).toLowerCase().trim()

		return filterValueTransformed.includes(inputValueTransformed)
	}
}

export const filterItemsIgnoreSpecChars = (inputValue, filterValue) => {
	const ignoreChars = ['-', ' ']

	return (
		filterItems(inputValue, filterValue) ||
		ignoreChars.some((ignoreChar) => filterItems(inputValue, filterValue.replace(ignoreChar, '')))
	)
}

// funkce pipe zretezi funkce, ktere se ji predaji jako parametry - tzn.
// prvni funkce vrati neco, to se preda na vstup dalsi funkci,
// ta zase neco vrati, to jde na vstup treti funkci atd., tedy:
// pipe(fn1, fn2, fn3, ...)(x) = fn3(fn2(fn1(x)))
export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x)

const AutoComplete = (props) => {
	const localize = useLocalize()

	const inputRef = useRef()
	const dropdownRef = useRef()

	// pri zmene nepotrebujeme prekreslovat (proto useRef misto useState)
	const activeModalTypeRef = useRef()

	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [isDropdownInDefaultPlace, setIsDropdownInDefaultPlace] = useState(true)

	useEffect(() => {
		if (isDropdownOpen && activeModalTypeRef.current === DROPDOWN) {
			setIsDropdownInDefaultPlace(canBeDropdownRenderedBelow(inputRef.current, dropdownRef.current))
		}
	}, [isDropdownOpen])

	const renderDropdown = (props) => {
		activeModalTypeRef.current = DROPDOWN

		return <Surface {...props} ref={dropdownRef} />
	}

	const renderPopup = (props) => {
		activeModalTypeRef.current = POPUP

		const restProps = Object.assign({}, props)

		delete restProps.children

		return <Popup {...restProps}>{props.children}</Popup>
	}

	const stateReducer = (state, changes) => {
		const suppressDefaultPopupClose = (changes) => {
			const { isOpen, type } = changes

			// nedovolime zavrit popup pro defaultni akce downshiftu
			if (type && activeModalTypeRef.current === POPUP && !isOpen) {
				// s jednou vyjimkou - kliknuti na polozku ze seznamu
				if (type === Downshift.stateChangeTypes.clickItem) {
					return changes
				} else {
					const updatedChanges = Object.assign({}, changes)

					delete updatedChanges.isOpen

					return updatedChanges
				}
			}

			return changes
		}

		const clearSelectedItemAutomatically = (changes) => {
			const { type } = changes

			if (type === Downshift.stateChangeTypes.changeInput) {
				return Object.assign({}, changes, {
					selectedItem: undefined
				})
			}

			return changes
		}

		const dontChangeInputAutomatically = (changes) => {
			const { type } = changes

			if (type !== Downshift.stateChangeTypes.changeInput) {
				const newChanges = Object.assign({}, changes)

				delete newChanges.inputValue

				return newChanges
			}

			return changes
		}

		return pipe(
			suppressDefaultPopupClose,
			clearSelectedItemAutomatically,
			dontChangeInputAutomatically
		)(changes)
	}

	const { children, size } = props

	const restProps = Object.assign({}, props)

	delete restProps.stateReducer
	delete restProps.children
	delete restProps.size

	return (
		<Downshift
			stateReducer={(state, changes) => {
				const newChanges = stateReducer(state, changes)

				if (props.stateReducer) {
					return props.stateReducer(state, newChanges)
				} else {
					return newChanges
				}
			}}
			itemToString={(item) => (item ? item.name : '')}
			{...restProps}
		>
			{(downshift) => {
				setIsDropdownOpen(downshift.isOpen)

				const getInputProps = (customInputProps = {}) => {
					const { onClick, onBlur } = customInputProps

					return downshift.getInputProps(
						Object.assign({}, customInputProps, {
							ref: inputRef,
							onClick: () => {
								downshift.openMenu()
								onClick && onClick()
							},
							onBlur: () => {
								restProps.onInputBlur && restProps.onInputBlur(downshift)
								onBlur && onBlur()
							}
						})
					)
				}

				const getToggleButtonProps = (props = {}) =>
					Object.assign(
						{},
						downshift.getToggleButtonProps(
							Object.assign(
								{},
								{
									'aria-label': downshift.isOpen
										? localize('AutoComplete.closeMenu')
										: localize('AutoComplete.openMenu')
								},
								props
							)
						)
					)

				const getClearButtonProps = (customClearButtonProps = {}) => {
					const { onClick: customOnClick } = customClearButtonProps

					return Object.assign({}, customClearButtonProps, {
						'aria-label': localize('AutoComplete.clearSelection'),
						onClick: () => {
							downshift.setState({
								inputValue: '',
								selectedItem: undefined
							})

							customOnClick && customOnClick()
						}
					})
				}

				const getDropdownProps = (dropdownProps = {}) =>
					Object.assign({}, dropdownProps, {
						className: classnames({
							[`${CLASSNAME}__dropdown`]: true,
							[`${CLASSNAME}__dropdown--small`]: size === 'small',
							[`${CLASSNAME}__dropdown--opens-above-input`]: !isDropdownInDefaultPlace,
							[dropdownProps.className]: !!dropdownProps.className,
							[props.dropdownClassName]: !!props.dropdownClassName
						})
					})

				const getPopupProps = (props = {}) =>
					Object.assign(
						{},
						{
							isOpen: true,
							dictionary: {
								close: localize('AutoComplete.close')
							},
							stickyHeader: false,
							onClose: downshift.closeMenu
						},
						props
					)

				return (
					<div>
						{children(
							Object.assign({}, downshift, {
								getInputProps,
								getToggleButtonProps,
								getClearButtonProps,
								getDropdownProps,
								getPopupProps,

								filterItems,

								renderDropdown,
								renderPopup
							})
						)}
					</div>
				)
			}}
		</Downshift>
	)
}

AutoComplete.propTypes = {
	children: PropTypes.node,
	stateReducer: PropTypes.func,
	dropdownClassName: PropTypes.string,
	size: PropTypes.string
}

AutoComplete.defaultProps = {
	size: 'regular'
}

AutoComplete.stateChangeTypes = Downshift.stateChangeTypes

// pomocna metoda pro prepsani konfigurace Downshiftu, vhodne pokud chceme
// select provazat s labelem
AutoComplete.getIdsPropsHelper = (customElementId) => ({
	id: customElementId,
	inputId: `${customElementId}-input`,
	labelId: `${customElementId}-label`,
	getItemId: (index) => `${customElementId}-item-${index}`
})

export default AutoComplete
