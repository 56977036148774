import ClientBaseController from 'app/base/ClientBaseController'
import STATE_KEYS from './ClientProfileStateKeys'
import * as FormLines from '@inzeraty/form-lines'
import { SOURCE_WIDGET_ID } from './widgets/source/SourceWidget'
import {
	getValidationErrorMessage,
	OPENING_HOURS_WIDGET_ID
} from './widgets/openingHours/OpeningHoursWidget'
import { createFormLineEntities, updateDepentantFormLineEntities } from './ClientProfileFormLines'
import { PROFILE_SECTIONS, splitFormLinesIntoSections } from './ClientProfileSections'
import UserwebBaseController from 'app/base/UserwebBaseController'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { FORM_LINES_IDS } from './ClientProfileFormLineIds'

class ClientProfileController extends ClientBaseController {
	constructor(
		dependenciesHelper,
		premiseService,
		localitySelectInputExtension,
		clientProfileValidationExtension,
		pageLoaderExtension
	) {
		super(dependenciesHelper, true /* uzivatel musi byt prihlasen jako bazarnik */)

		this._premiseService = premiseService
		this._localitySelectInputExtension = localitySelectInputExtension
		this._clientProfileValidationExtension = clientProfileValidationExtension
		this._pageLoaderExtension = pageLoaderExtension
		this._errorFilterCallbackFn = this._errorFilterCallbackFn.bind(this)

		this._dictionary = this._utils.$Dictionary
		this._router = this._utils.$Router
	}

	init() {
		super.init()

		this.addExtension(this._localitySelectInputExtension)
		this.addExtension(this._clientProfileValidationExtension)
		this.addExtension(this._pageLoaderExtension)
		this._pageLoaderExtension.show()
	}

	load() {
		const superState = super.load()
		const { [UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfState } = superState

		return Object.assign(superState, {
			[STATE_KEYS.PREMISE_DATA]: this._getPremiseInfo(userSelfState, { cache: false }),
			[STATE_KEYS.IS_SENDING]: false,
			[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]: this._getPremiseWalletInfo(
				userSelfState
			)
		})
	}

	async activate() {
		super.activate()

		const { [STATE_KEYS.PREMISE_DATA]: premiseData } = this.getState()

		const formLineEntities = await this._getFormLineEntities(premiseData)

		this._pageLoaderExtension.hide()

		if (premiseData) {
			this.setState({
				[STATE_KEYS.FORM_DATA]: formLineEntities
			})
		}
	}

	async _getFormLineEntities(premiseDataPromise) {
		const premiseData = await premiseDataPromise
		const formLineEntities = createFormLineEntities(premiseData, this._dictionary, false)
		const updatedFormLineEntities = updateDepentantFormLineEntities(formLineEntities)

		return splitFormLinesIntoSections(updatedFormLineEntities)
	}

	onChange({ widget, section, ...change }) {
		const { [STATE_KEYS.FORM_DATA]: formData } = this.getState()

		for (const sectionKey in formData) {
			if (section === sectionKey) {
				formData[sectionKey] = FormLines.updateEntities(formData[sectionKey], [change])

				if (widget === SOURCE_WIDGET_ID) {
					formData[sectionKey] = updateDepentantFormLineEntities(formData[sectionKey])
				}
			}
		}

		this.setState({
			[STATE_KEYS.FORM_DATA]: { ...formData }
		})
	}

	_errorFilterCallbackFn(formLineEntity) {
		if (formLineEntity.widget === OPENING_HOURS_WIDGET_ID) {
			const { id } = formLineEntity
			const errorMessage = getValidationErrorMessage(formLineEntity) || {}
			this.onChange({
				id,
				section: PROFILE_SECTIONS.OPENING_HOURS,
				errorMessage: Object.assign({}, errorMessage)
			})
			return Object.keys(errorMessage).length !== 0
		}
		return formLineEntity.errorMessage
	}

	async onSubmit(formLineEntities) {
		const {
			[STATE_KEYS.PREMISE_DATA]: { id },
			[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]: walletInfo
		} = this.getState()

		const errors = this._clientProfileValidationExtension.getFormlineEntitiesErrors(
			this._errorFilterCallbackFn
		)

		if (errors.length) {
			const texts = []

			errors.forEach(({ id }) => {
				switch (id) {
					case FORM_LINES_IDS.MONDAY_OPENING_HOURS:
					case FORM_LINES_IDS.TUESDAY_OPENING_HOURS:
					case FORM_LINES_IDS.WEDNESDAY_OPENING_HOURS:
					case FORM_LINES_IDS.THURSDAY_OPENING_HOURS:
					case FORM_LINES_IDS.FRIDAY_OPENING_HOURS:
					case FORM_LINES_IDS.SATURDAY_OPENING_HOURS:
					case FORM_LINES_IDS.SUNDAY_OPENING_HOURS: {
						const text = this._dictionary.get('ClientProfile.errorToastOpeningHours')
						if (!texts.includes(text)) {
							texts.push(text)
						}
						break
					}
					case FORM_LINES_IDS.ADDRESS:
						texts.push(this._dictionary.get('ClientProfile.errorToastAddress'))
						break
				}
			})

			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				title: this._dictionary.get('ClientProfile.sendErrorMessage'),
				text: texts.join('\n'),
				type: StatusMessage.TYPE.ERROR
			})

			return
		}

		this.setState({
			[STATE_KEYS.IS_SENDING]: true
		})

		const formLinesWithData = formLineEntities.filter(({ extra: { getData } = {} }) =>
			Boolean(getData)
		)
		const formValues = formLinesWithData
			.filter(({ disabled = false }) => !disabled)
			.reduce(
				(formValues, { value, extra: { getData } }) =>
					Object.assign(formValues, getData(value, formValues)),
				{}
			)

		this._pageLoaderExtension.show()

		try {
			await this._premiseService.patchPremise(id, formValues)
			const updatedPremiseEntity = await this._premiseService.getPremise(id, {}, { cache: false })
			const updatedFormData = await this._getFormLineEntities(updatedPremiseEntity, walletInfo)

			this.setState({
				[STATE_KEYS.PREMISE_DATA]: updatedPremiseEntity,
				[STATE_KEYS.FORM_DATA]: updatedFormData
			})

			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				title: this._dictionary.get('ClientProfile.sendSuccessMessage'),
				type: StatusMessage.TYPE.SUCCESS
			})
		} catch (error) {
			this._clientProfileValidationExtension.processErrors(error)
		} finally {
			this._pageLoaderExtension.hide()

			this.setState({
				[STATE_KEYS.IS_SENDING]: false
			})
		}
	}
}

export default ClientProfileController
