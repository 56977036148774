import { HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import {
	createFormLineEntities,
	updateDepentantFormLineEntities
} from 'app/page/userweb/clientProfile/ClientProfileFormLines'
import { splitFormLinesIntoSections } from 'app/page/userweb/clientProfile/ClientProfileSections'
import * as FormLines from '@inzeraty/form-lines'
import { SOURCE_WIDGET_ID } from 'app/page/userweb/clientProfile/widgets/source/SourceWidget'
import {
	OPENING_HOURS_WIDGET_ID,
	getValidationErrorMessage
} from 'app/page/userweb/clientProfile/widgets/openingHours/OpeningHoursWidget'
import { PROFILE_SECTIONS } from 'app/page/userweb/clientProfile/ClientProfileSections'
import { createIAFormLineEntities } from 'app/page/internalAdmin/premiseEdit/IAPremiseEditFormLines'
import { IA_SECTIONS } from 'app/page/internalAdmin/premiseEdit/IAPremiseEditSections'
import ROUTE_NAMES from 'app/base/RouteNames'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

import 'app/page/userweb/clientProfile/ClientProfileCS.json'
import './IAPremiseEditCS.json'
import 'app/base/ActionMessageCS.json'
import '../components/dissectPremisePopup/DissectPremisePopupCS.json'

export default class IAPremiseEditController extends AbstractInternalAdminController {
	constructor(
		dependenciesHelper,
		premiseService,
		localitySelectInputExtension,
		softwareKeyService,
		clientProfileValidationExtension
	) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._router = this._utils.$Router
		this._premiseService = premiseService
		this._localitySelectInputExtension = localitySelectInputExtension
		this._clientProfileValidationExtension = clientProfileValidationExtension
		this._softwareKeyService = softwareKeyService

		this.onSetIsDissectPremisePopupOpened = this.onSetIsDissectPremisePopupOpened.bind(this)
		this.onDissectPremise = this.onDissectPremise.bind(this)
		this._errorFilterCallbackFn = this._errorFilterCallbackFn.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._localitySelectInputExtension)
		this.addExtension(this._clientProfileValidationExtension)
	}

	load(state = {}) {
		return super.load(
			Object.assign({}, state, {
				premiseEntity: this._getPremise(),
				isSending: false,
				walletInfo: this._getPremiseWalletInfo(),
				softwareKeys: this._softwareKeyService
					.getSoftwareKeys({ limit: 1000 })
					.then(({ softwareKeyEntities }) => softwareKeyEntities),
				[AbstractInternalAdminController.stateId.GO_BACK_LIST_URL]: this._getBackUrl(
					ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_LIST
				),
				[AbstractInternalAdminController.stateId.DIRECT_LINK_DATA]: this._getDirectLinkData(),
				isDissectPremisePopupOpened: false,
				dissetPremiseStatusErrorData: {}
			})
		)
	}

	_getPremise() {
		const {
			[InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID]: premiseId
		} = this.getRouteParams()
		return this._premiseService.getPremise(premiseId, {}, { cache: false })
	}

	_getDirectLinkData() {
		const {
			[InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID]: premiseId
		} = this.getRouteParams()

		return {
			text: 'Historie změn',
			url: this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_HISTORY, { premiseId })
		}
	}

	async activate() {
		super.activate()

		const { premiseEntity, softwareKeys, walletInfo } = this.getState()

		if (premiseEntity) {
			// formData nacitame v activate hlavne kvuli SSR, aby
			// uzivatel nedostaval vyrenderovany formular,
			// ve kterem behem nacitani JS nejde prepinat zdoj dat
			this.setState({
				formData: await this._getFormLineEntities(premiseEntity, softwareKeys, walletInfo)
			})
		}
	}

	async _getFormLineEntities(premiseEntity, softwareKeys, walletInfo = {}) {
		const premiseData = await premiseEntity

		const clientAdminFormLines = createFormLineEntities(premiseData, this._dictionary, true)

		const {
			[AbstractInternalAdminController.stateId.ADMIN_SELF]: { rights = [] } = {}
		} = this.getState()

		const internalAdminFormLines = createIAFormLineEntities(
			premiseData,
			softwareKeys,
			walletInfo,
			this._dictionary,
			this._router,
			rights
		)

		const mergedFormLines = this._mergeFormLines(clientAdminFormLines, internalAdminFormLines)

		const updatedFormLineEntities = updateDepentantFormLineEntities(mergedFormLines)

		return splitFormLinesIntoSections(updatedFormLineEntities, IA_SECTIONS)
	}

	async _getPremiseWalletInfo() {
		const {
			[InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID]: premiseId
		} = this.getRouteParams()

		try {
			return await this._premiseService.getPremiseWallet(premiseId, {}, { cache: false })
		} catch (error) {
			return {
				serverError: true
			}
		}
	}

	async onGetPremiseWalletInfo() {
		this._pageLoaderExtension.show()

		try {
			const { premiseEntity, softwareKeys } = this.getState()

			const walletInfo = await this._getPremiseWalletInfo()

			this.setState({
				walletInfo,
				formData: await this._getFormLineEntities(premiseEntity, softwareKeys, walletInfo)
			})
		} catch (error) {
			this.setState({
				walletInfo: {
					serverError: true
				}
			})
		} finally {
			this._pageLoaderExtension.hide()
		}
	}

	_mergeFormLines(clientAdminFormLines, internalAdminFormLines) {
		const merge = [...clientAdminFormLines]

		internalAdminFormLines.forEach((formLine) => {
			const index = merge.findIndex(({ id }) => id === formLine.id)

			if (index >= 0) {
				merge[index] = formLine
			} else {
				merge.push(formLine)
			}
		})

		return merge
	}

	onCloseSuccessMessage() {
		this.setState({
			showSuccessMessage: false
		})
	}

	onChange({ widget, section, ...change }) {
		const { formData } = this.getState()

		for (const sectionKey in formData) {
			if (section === sectionKey) {
				formData[sectionKey] = FormLines.updateEntities(formData[sectionKey], [change])

				if (widget === SOURCE_WIDGET_ID) {
					formData[sectionKey] = updateDepentantFormLineEntities(formData[sectionKey])
				}
			}
		}

		this.setState({
			formData: { ...formData }
		})
	}

	async onUpdateUserOnPremise({
		formLineEntity,
		itemId,
		section,
		oldUserLogin,
		newUserLogin,
		onSuccessCallback = () => {}
	}) {
		const setNewErrorsOnFormLineEntity = (newErrors) => {
			const { id, extra = {} } = formLineEntity

			this.onChange({
				id,
				section,
				extra: Object.assign({}, extra, { errors: newErrors })
			})
		}

		const addErrorToUserLogin = () => {
			const { extra: { errors = {} } = {} } = formLineEntity

			const newErrors = Object.assign({}, errors)
			newErrors[itemId] = true

			setNewErrorsOnFormLineEntity(newErrors)
		}

		const clearErrorFromUserLogin = () => {
			const { extra: { errors = {} } = {} } = formLineEntity

			const newErrors = Object.assign({}, errors)
			delete newErrors[itemId]

			setNewErrorsOnFormLineEntity(newErrors)
		}

		const {
			premiseEntity: { id: premiseId }
		} = this.getState()

		const defaultErrorTitle = 'Chyba s přihlašovacím emailem'
		const defaultErrorMessage = `Uživatele s přihlašovacím emailem '${newUserLogin}' se nepovedlo k firmě přidat.`

		let dbAssignedUsers = []

		clearErrorFromUserLogin()

		this._pageLoaderExtension.show()

		try {
			const { users } = await this._premiseService.getPremise(premiseId, {}, { cache: false })

			dbAssignedUsers = users
		} catch (e) {
			addErrorToUserLogin()
			this.renderErrorToast(defaultErrorTitle, defaultErrorMessage)
			return
		}

		try {
			if (oldUserLogin) {
				const { id: oldUserId } = dbAssignedUsers.find((u) => u.login === oldUserLogin) || {}

				if (oldUserId) {
					await this._premiseService.removeUserFromPremise(premiseId, oldUserId)
				}
			}
		} catch (error) {
			const status = HttpErrorHelper.getHttpStatus(error)

			if (status !== HttpStatusCodes.NOT_FOUND) {
				addErrorToUserLogin()
				this.renderErrorToast(
					defaultErrorTitle,
					`Uživatele s přihlašovacím emailem '${oldUserLogin}' se nepovedlo z firmy smazat.`
				)
				return
			}
		}

		try {
			if (newUserLogin) {
				const isUserAlreadyAssignedToPremise = !!dbAssignedUsers.find(
					({ login }) => login === newUserLogin
				)

				if (isUserAlreadyAssignedToPremise) {
					// uzivatel uz byl pridan pomoci jineho tabu nebo jinym adminem
					addErrorToUserLogin()
					this.renderErrorToast(
						defaultErrorTitle,
						`Uživatel s přihlašovacím emailem '${newUserLogin}' je již přiřazen k této firmě.`
					)
				} else {
					await this._premiseService.addUserToPremise(premiseId, {
						user_email: newUserLogin
					})

					onSuccessCallback()

					this.renderSuccessToast(
						'Úspěšné přidání přihlašovacího emailu',
						`Uživatele s přihlašovacím emailem '${newUserLogin}' se povedlo k firmě úspěšně přidat.`
					)
				}
			}
		} catch (error) {
			const status = HttpErrorHelper.getHttpStatus(error)

			let errorMessage = defaultErrorMessage

			if (status === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				const { errors = [] } = body

				const [userAssignedToOtherPremiseError] = errors
					.map(({ error_code: errorCode }) => {
						if (errorCode === 'user_has_premise') {
							return `Uživatel s přihlašovacím emailem '${newUserLogin}' je již přiřazen k jiné firmě.`
						}
					})
					.filter((error) => !!error)

				if (userAssignedToOtherPremiseError) {
					errorMessage = userAssignedToOtherPremiseError
				}
			}

			addErrorToUserLogin()
			this.renderErrorToast(defaultErrorTitle, errorMessage)
		}

		this._pageLoaderExtension.hide()
	}

	async onRemoveUserFromPremise({ userLoginToRemove, onSuccessCallback = () => {} }) {
		const {
			premiseEntity: { id: premiseId }
		} = this.getState()

		const defaultSuccessTitle = 'Úspěšné smazání přihlašovacího emailu'
		const defaultErrorTitle = 'Chyba s přihlašovacím emailem'

		this._pageLoaderExtension.show()

		try {
			const { users: dbAssignedUsers = [] } = await this._premiseService.getPremise(
				premiseId,
				{},
				{ cache: false }
			)

			const { id: userIdToRemove } =
				dbAssignedUsers.find((user) => user.login === userLoginToRemove) || {}

			if (userIdToRemove) {
				if (dbAssignedUsers.length === 1) {
					this.renderErrorToast(
						defaultErrorTitle,
						`Uživatele s přihlašovacím emailem '${userLoginToRemove}' nejde smazat. Firma musí mít přiřazený minimálně jeden příhlašovací email.`
					)
				} else {
					await this._premiseService.removeUserFromPremise(premiseId, userIdToRemove)

					this.renderSuccessToast(
						defaultSuccessTitle,
						`Uživatele s přihlašovacím emailem '${userLoginToRemove}' se povedlo z firmy úspěšně smazat.`
					)

					onSuccessCallback()
				}
			} else {
				if (userLoginToRemove) {
					// uzivatel byl jiz smazany (napr. v jinem tabu nebo uplne jinym adminem)
					this.renderSuccessToast(
						defaultSuccessTitle,
						`Uživatele s přihlašovacím emailem '${userLoginToRemove}' se povedlo z firmy úspěšně smazat.`
					)
				}

				onSuccessCallback()
			}
		} catch (e) {
			this.renderErrorToast(
				defaultErrorTitle,
				`Uživatele s přihlašovacím emailem '${userLoginToRemove}' se nepovedlo z firmy smazat.`
			)
		} finally {
			this._pageLoaderExtension.hide()
		}
	}

	renderSuccessToast(title, body) {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			title,
			text: body,
			type: StatusMessage.TYPE.SUCCESS
		})
	}

	renderErrorToast(title, body) {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			title,
			text: body,
			type: StatusMessage.TYPE.ERROR
		})
	}

	_errorFilterCallbackFn(formLineEntity) {
		if (formLineEntity.widget === OPENING_HOURS_WIDGET_ID) {
			const { id } = formLineEntity
			const errorMessage = getValidationErrorMessage(formLineEntity) || {}
			this.onChange({
				id,
				section: PROFILE_SECTIONS.OPENING_HOURS,
				errorMessage: Object.assign({}, errorMessage)
			})
			return Object.keys(errorMessage).length !== 0
		}
		return formLineEntity.errorMessage
	}

	async onSubmit(formLineEntities) {
		const {
			premiseEntity: { id },
			softwareKeys
		} = this.getState()

		if (
			this._clientProfileValidationExtension.getFormlineEntitiesErrors(this._errorFilterCallbackFn)
				.length
		) {
			this.renderErrorToast('Chyba s uložením formuláře', 'Profil se nepodařilo uložit.')
			return
		}
		this.setState({
			isSending: true
		})

		const formLinesWithData = formLineEntities.filter(({ extra: { getData } = {} }) =>
			Boolean(getData)
		)
		const formValues = formLinesWithData.reduce((formValues, { value, extra: { getData } }) => {
			return Object.assign(formValues, getData(value, formValues))
		}, {})

		try {
			await this._premiseService.patchPremise(id, formValues)
			const updatedPremiseEntity = await this._premiseService.getPremise(id, {}, { cache: false })
			const walletInfo = await this._getPremiseWalletInfo(id)
			const updatedFormData = await this._getFormLineEntities(
				updatedPremiseEntity,
				softwareKeys,
				walletInfo
			)

			this.setState({
				premiseEntity: updatedPremiseEntity,
				formData: updatedFormData,
				showSuccessMessage: true,
				formError: false,
				walletInfo
			})

			this.renderSuccessToast(this._dictionary.get('ClientProfile.sendSuccessMessage'))
		} catch (error) {
			this._clientProfileValidationExtension.processErrors(error)
		} finally {
			this.setState({
				isSending: false
			})
		}
	}

	onSetIsDissectPremisePopupOpened(isOpened) {
		this.setState({
			isDissectPremisePopupOpened: isOpened,
			dissetPremiseStatusErrorData: {}
		})
	}

	async onDissectPremise(data) {
		const { premiseEntity } = this.getState()
		const { oldAskId, withAdverts } = data

		const entities = ['external_id', 'registrations']

		if (withAdverts) {
			entities.push('adverts')
		}

		const params = {
			premise_id_from: oldAskId,
			entities
		}

		this.setState({
			dissetPremiseStatusErrorData: {}
		})

		try {
			await this._premiseService.dissectPremise(premiseEntity.id, params)

			this.onSetIsDissectPremisePopupOpened(false)

			this.renderSuccessToast(
				this._dictionary.get('ActionMessage.titleSuccess'),
				'Přepárování proběhlo úspěšně'
			)

			this._pageLoaderExtension.show()

			const { softwareKeys, walletInfo } = this.getState()
			const updatedPremiseEntity = await this._getPremise()
			const formData = await this._getFormLineEntities(
				updatedPremiseEntity,
				softwareKeys,
				walletInfo
			)

			this.setState({
				premiseEntity: updatedPremiseEntity,
				formData
			})
		} catch (error) {
			let oldAskIdErrorMessage = ''
			let statusMessage = 'Přepárování se nezdařilo'

			const status = HttpErrorHelper.getHttpStatus(error)

			switch (status) {
				case HttpStatusCodes.NOT_FOUND:
					statusMessage = `Firma s ASK ID ${oldAskId} neexistuje`
					break

				case HttpStatusCodes.CONFLICT:
					statusMessage = `Firma s ASK ID ${premiseEntity.id} již má registrace`
					break

				case HttpStatusCodes.UNPROCESSABLE_ENTITY: {
					const { body = {} } = HttpErrorHelper.getParams(error)
					const { errors = [] } = body

					errors.forEach((error) => {
						const { error_code: errorCode, fields } = error

						fields.forEach((field_id) => {
							if (field_id === 'premise_id_from') {
								oldAskIdErrorMessage =
									errorCode === 'not_valid'
										? this._dictionary.get('DissectPremisePopup.oldAskIdErrorNotValid')
										: this._dictionary.get('DissectPremisePopup.oldAskIdErrorRequired')
							}
						})
					})
					break
				}
			}

			this.setState({
				dissetPremiseStatusErrorData: {
					statusMessage,
					oldAskIdErrorMessage
				}
			})
		} finally {
			this._pageLoaderExtension.hide()
		}
	}
}
