
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['FavoritesLink'] = {
  title: function(d) { return "Oblíbené inzeráty"; },
  logoutSecondLine: function(d) { return "Pro přídání se stačí"; },
  logoutLogin: function(d) { return "přihlásit"; },
  itemsCount: function(d) { return "Máte " + d.COUNT + " " + plural(d.COUNT, 0, cs, { one: "oblíbený", few: "oblíbené", other: "oblíbených" }); },
  itemsNotCount: function(d) { return "Nemáte žádné oblíbené"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	