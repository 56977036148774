import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { Responsive, Loading } from '@inzeraty/components'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { RESPONSIVE } from 'app/base/Constants'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import AutoComplete, {
	filterItemsIgnoreSpecChars,
	pipe
} from 'app/component/autoComplete/AutoComplete'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import AutoCompleteFilteredForm from 'app/component/autoComplete/forms/filtered/AutoCompleteFilteredForm'
import AutoCompleteAllForm from 'app/component/autoComplete/forms/all/AutoCompleteAllForm'
import { StickyPanelVisibilityManager } from 'app/component/filters/FiltersUtils'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import { Icon } from '@sznds/react'
import { sortModelsAlphabetically } from 'app/helpers/models/ModelsHelper'

import './ModelsSearch.less'
import './ModelsSearchCS.json'

const CLASSNAME = 'c-models-search'

const DROPDOWN = 'DROPDOWN'
const POPUP = 'POPUP'

const stickyPanelVisibilityManager = new StickyPanelVisibilityManager()

const ModelsSearch = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		changeFilter = DEFAULT_PROPS.FUNCTION,
		brand = DEFAULT_PROPS.OBJECT,
		advertsCountForModels: {
			filteredAdvertsCount = DEFAULT_PROPS.OBJECT,
			isLoadingFilteredAdvertsCount,
			loadFilteredAdvertsCount = DEFAULT_PROPS.FUNCTION
		} = DEFAULT_PROPS.OBJECT,
		showLeftIcon = true
	} = props

	const localize = useLocalize()
	const fire = useFire()

	const [searchedModelName, setSearchedModelName] = useState('')
	const [areModelsBeingLoaded, setAreModelsBeingLoaded] = useState(false)

	// pri zmene nepotrebujeme prekreslovat (proto useRef misto useState)
	const activeModalTypeRef = useRef()

	const isStickySelector = 'is-sticky'

	useEffect(() => {
		const observeElement = getWrapperElement()
		if (observeElement) {
			const observer = new IntersectionObserver(
				([element]) =>
					element.target.classList.toggle(isStickySelector, element.intersectionRatio < 1),
				{ threshold: [1] }
			)

			observer.observe(observeElement)

			return function () {
				observer.unobserve(observeElement)
			}
		}
	}, [])

	useEffect(() => {
		const loadModels = (brandId) => fire('downloadModels', { brandId })

		const { id: brandId, children: models = [] } = brand

		if (!models.length && !areModelsBeingLoaded) {
			loadModels(brandId)
			setAreModelsBeingLoaded(true)
		}

		if (models.length) {
			setAreModelsBeingLoaded(false)
		}
	}, [brand])

	const renderInput = (formLineEntity, downshift) => {
		const {
			getInputProps,
			getToggleButtonProps,
			getClearButtonProps,

			isOpen,
			inputValue
		} = downshift

		const { name, value: selectedBrands = new Map() } = formLineEntity

		const { name: brandName, children: models = [], value } = brand

		const selectedModels = selectedBrands.get(value)

		const filteredItems = models
			.filter((model) => {
				const { value: modelvalue } = model
				const isSelectedModel = selectedModels.has(modelvalue)

				return !isSelectedModel
			})
			.filter((model) => filterItemsIgnoreSpecChars(inputValue, model.name))

		const isModelSelected = selectedModels?.size

		const placeholderValue = isModelSelected ? 'ModelsSearch.addModel' : 'ModelsSearch.searchModel'

		return (
			<div className={`${CLASSNAME}__wrapper`}>
				<AutoCompleteInput
					isLoading={areModelsBeingLoaded}
					inputSurfaceProps={{
						size: 'small',
						className: `${CLASSNAME}__search-model`
					}}
					inputProps={getInputProps({
						name,
						placeholder: localize(placeholderValue),
						'data-dot': 'show-values-model',
						'data-dot-data': `{"type": "${brandName}"}`
					})}
					toggleButtonProps={
						!inputValue
							? getToggleButtonProps({
									isOpen
							  })
							: undefined
					}
					clearButtonProps={inputValue ? getClearButtonProps() : undefined}
					renderLeftIcon={showLeftIcon && renderSearchIcon}
				/>

				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => renderPopup(formLineEntity, filteredItems, downshift)}
					renderDesktopElement={() => renderDropdown(formLineEntity, filteredItems, downshift)}
				/>
			</div>
		)
	}

	const getWrapperElement = () => {
		return document.querySelector('.cf-advanced-brand-model-opener__selected-brands-wrapper')
	}

	const isWrapperSticky = () => {
		return getWrapperElement()?.classList?.contains(isStickySelector)
	}

	const renderDropdown = (formLineEntity, filteredItems, downshift) => {
		activeModalTypeRef.current = DROPDOWN

		const {
			isOpen,

			getDropdownProps,
			renderDropdown: Dropdown
		} = downshift

		const dropdownProps = getDropdownProps()
		if (isWrapperSticky())
			dropdownProps.className += ` ${dropdownProps.className}--opens-above-input`

		return (
			isOpen && (
				<Dropdown {...dropdownProps}>
					{Boolean(filteredItems.length) && renderItems(filteredItems, downshift)}
				</Dropdown>
			)
		)
	}

	const renderPopup = (formLineEntity, filteredItems, downshift) => {
		activeModalTypeRef.current = POPUP

		const {
			isOpen,
			inputValue,
			getInputProps,
			getClearButtonProps,
			getPopupProps,
			renderPopup: Popup
		} = downshift

		return (
			isOpen && (
				<PopupRenderer
					renderItems={(items) => renderItems(items, downshift)}
					showLeftIcon={showLeftIcon}
					renderSearchIcon={renderSearchIcon}
					inputValue={inputValue}
					getInputProps={getInputProps}
					getClearButtonProps={getClearButtonProps}
					getPopupProps={getPopupProps}
					popupComponent={Popup}
					downshift={downshift}
					models={filteredItems}
				/>
			)
		)
	}

	const renderSearchIcon = () => {
		return <Icon symbol={SEARCH_OUTLINE_24} />
	}

	const renderItems = (items, downshift) => {
		const { inputValue, getItemProps, highlightedIndex } = downshift

		const renderItem = (option, index) => {
			const { value: modelValue, name } = option

			return (
				<AutoCompleteOption
					{...getItemProps({
						key: modelValue,
						index,
						item: option,
						isHighlighted: highlightedIndex === index,
						className: `${CLASSNAME}__item`,
						'data-dot': 'choose-value',
						'data-dot-data': `{"value": "${name}"}`
					})}
				>
					<span>
						{isLoadingFilteredAdvertsCount ? (
							<Loading
								className={`${CLASSNAME}__loading-dots`}
								loadingType={Loading.loadingType.ONLY_DOT}
							/>
						) : (
							name
						)}
					</span>

					<div className={`${CLASSNAME}__count`}>
						{isLoadingFilteredAdvertsCount ? (
							<Loading
								className={`${CLASSNAME}__loading-dots`}
								loadingType={Loading.loadingType.ONLY_DOT}
							/>
						) : (
							filteredAdvertsCount[modelValue] || 0
						)}
					</div>
				</AutoCompleteOption>
			)
		}
		if (isLoadingFilteredAdvertsCount) return <Loading />
		items.forEach((item) => (item.count = filteredAdvertsCount[item.value] || 0))
		const recordsWithAdverts = sortModelsAlphabetically(items.filter((item) => item.count > 0))
		const recordsWithZeroAdverts = sortModelsAlphabetically(items.filter((item) => item.count == 0))
		const sortedItems = [...recordsWithAdverts, ...recordsWithZeroAdverts]
		return inputValue ? (
			<AutoCompleteFilteredForm
				filteredItemsLabel={localize('ModelsSearch.labelFound')}
				filteredItems={sortedItems}
				renderItem={renderItem}
			/>
		) : (
			<AutoCompleteAllForm
				allItemsLabel={localize('ModelsSearch.labelAll')}
				items={sortedItems}
				favoriteItemsLabel={localize('ModelsSearch.labelFavorite')}
				favoriteItems={items.filter(({ isFavorite }) => isFavorite)}
				renderItem={renderItem}
				renderFavoriteItem={renderItem}
			/>
		)
	}

	const handleSelect = (selectedItem) => {
		if (selectedItem) {
			const { id, value: selectedBrands = new Map() } = formLineEntity
			const { value: brandValue } = brand
			const { value: modelValue } = selectedItem

			const newSelectedModels = new Set()
			selectedBrands.get(brandValue).forEach((model) => {
				newSelectedModels.add(model)
			})

			newSelectedModels.add(modelValue)

			changeFilter(id, new Map([...selectedBrands, [brandValue, newSelectedModels]]))

			setSearchedModelName('')
		}
	}

	const stateReducer = (state, changes) => {
		const loadFilteredAdvertsCountOnOpen = (changes) => {
			// pri otevreni dropdownu nebo popupu automaticky nacteme
			// cisilka poctu inzeraty pro jednotlive modely
			if (!state.isOpen && changes.isOpen) {
				loadFilteredAdvertsCount()
			}

			return changes
		}

		const evalStickyPanelVisibility = (changes) => {
			if (!state.isOpen && changes.hasOwnProperty('isOpen') && changes.isOpen) {
				stickyPanelVisibilityManager.hide()
			}

			if (state.isOpen && changes.hasOwnProperty('isOpen') && !changes.isOpen) {
				stickyPanelVisibilityManager.restore()
			}

			// kvuli enteru na popupu
			if (state.isOpen && changes.type === AutoComplete.stateChangeTypes.keyDownEnter) {
				stickyPanelVisibilityManager.restore()
			}

			return changes
		}

		const closeDropdownOnNothingFound = (changes) => {
			// pokud se nepodari pri zafiltrovani najit zadny model,
			// tak dropdown zavreme
			if ((state.isOpen || changes.isOpen) && activeModalTypeRef.current === DROPDOWN) {
				const { children: models = [] } = brand
				const inputValue = changes.inputValue || state.inputValue

				const filteredModels = models.filter((option) =>
					filterItemsIgnoreSpecChars(inputValue, option.name)
				)

				if (!filteredModels.length) {
					return Object.assign({}, changes, {
						isOpen: false
					})
				}
			}

			return changes
		}

		return pipe(
			loadFilteredAdvertsCountOnOpen,
			evalStickyPanelVisibility,
			closeDropdownOnNothingFound
		)(changes)
	}

	return (
		<AutoComplete
			inputValue={searchedModelName}
			onInputValueChange={setSearchedModelName}
			onSelect={handleSelect}
			stateReducer={stateReducer}
			size='small'
		>
			{(downshift) => renderInput(formLineEntity, downshift)}
		</AutoComplete>
	)
}

ModelsSearch.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	changeFilter: PropTypes.func.isRequired,
	brand: PropTypes.object.isRequired,
	advertsCountForModels: PropTypes.object.isRequired,
	showLeftIcon: PropTypes.bool
}

export default React.memo(ModelsSearch)

const PopupRenderer = ({
	renderItems,
	showLeftIcon,
	renderSearchIcon,
	inputValue,
	getClearButtonProps,
	getPopupProps,
	popupComponent: Popup,
	downshift,
	models
}) => {
	const localize = useLocalize()
	const [searchedModelName, setSearchedModelName] = useState('')

	useEffect(() => {
		// HACK: nechceme, aby na mobilu po zavreni popupu byl focus na inputu
		document.activeElement?.blur()
	}, [])

	const filteredItems = models.filter((model) =>
		filterItemsIgnoreSpecChars(searchedModelName, model.name)
	)

	return (
		<Popup
			{...getPopupProps({
				title: localize('ModelsSearch.chooseModel'),
				className: `${CLASSNAME}__popup`
			})}
		>
			<div className={`${CLASSNAME}__popup-input-wrapper`}>
				<AutoCompleteInput
					inputSurfaceProps={{
						size: 'small'
					}}
					inputProps={{
						placeholder: localize('ModelsSearch.searchModel'),
						value: searchedModelName,
						onChange: (event) => setSearchedModelName(event.target.value)
					}}
					clearButtonProps={
						searchedModelName
							? getClearButtonProps({
									onClick: () => setSearchedModelName('')
							  })
							: undefined
					}
					renderLeftIcon={showLeftIcon && renderSearchIcon}
				/>
			</div>

			{Boolean(filteredItems.length) && renderItems(filteredItems, downshift)}
		</Popup>
	)
}

PopupRenderer.propTypes = {
	renderItems: PropTypes.func,
	getPopupProps: PropTypes.func,
	getClearButtonProps: PropTypes.func,
	popupComponent: PropTypes.func,
	downshift: PropTypes.object,
	showLeftIcon: PropTypes.bool,
	renderSearchIcon: PropTypes.func,
	inputValue: PropTypes.string,
	models: PropTypes.arrayOf(PropTypes.object)
}
