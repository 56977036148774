import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@sznds/react'
import {
	VISIBILITY_FILLED_24,
	PHONE_FILLED_24,
	MAIL_ALT1_FILLED_24,
	FAVORITE_FILLED_24
} from '@sznds/icons'
import { useLocalize } from 'app/base/componentHelpers'
import { Format } from '@inzeraty/helpers'
import SimpleTooltip from 'app/component/simpleTooltip/SimpleTooltipView'

import './AdvertStatsCS.json'
import './AdvertStatsView.less'

const CLASSNAME = 'c-item-stats'

const AdvertStatsView = ({
	isLoadingStatistics = false,
	isOperatingLease = false,
	statistics = {}
}) => {
	const localize = useLocalize()

	const {
		detailView = 0,
		detailViewOperatingLease = 0,
		phoneView = 0,
		phoneViewOperatingLease = 0,
		replySent = 0,
		replySentOperatingLease = 0,
		favorite = 0
	} = statistics

	const renderPlaceholderOrContent = (content) => {
		return isLoadingStatistics ? (
			<span className={`${CLASSNAME}__count-content`}>
				<span className={`${CLASSNAME}__placeholder`} />
			</span>
		) : (
			content
		)
	}

	return (
		<div className={CLASSNAME}>
			<SimpleTooltip
				className={`${CLASSNAME}__count`}
				description={localize('AdvertStats.detailView')}
				ariaHidden={true}
				renderContent={(className) =>
					renderPlaceholderOrContent(
						<>
							{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
							<span tabIndex={0} className={`${CLASSNAME}__count-content ${className}`}>
								<Icon symbol={VISIBILITY_FILLED_24} className={`${CLASSNAME}__icon`} />
								<span className={`${CLASSNAME}__accessible-count`}>
									{localize('AdvertStats.detailView')}
								</span>
								{`${Format.number(isOperatingLease ? detailViewOperatingLease : detailView)}x`}
							</span>
						</>
					)
				}
			/>
			<SimpleTooltip
				className={`${CLASSNAME}__count`}
				description={localize('AdvertStats.phoneView')}
				ariaHidden={true}
				renderContent={(className) =>
					renderPlaceholderOrContent(
						<>
							{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
							<span tabIndex={0} className={`${CLASSNAME}__count-content ${className}`}>
								<Icon symbol={PHONE_FILLED_24} className={`${CLASSNAME}__icon`} />
								<span className={`${CLASSNAME}__accessible-count`}>
									{localize('AdvertStats.phoneView')}
								</span>
								{`${Format.number(isOperatingLease ? phoneViewOperatingLease : phoneView)}x`}
							</span>
						</>
					)
				}
			/>
			<SimpleTooltip
				className={`${CLASSNAME}__count`}
				description={localize('AdvertStats.replySent')}
				ariaHidden={true}
				renderContent={(className) =>
					renderPlaceholderOrContent(
						<>
							{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
							<span tabIndex={0} className={`${CLASSNAME}__count-content ${className}`}>
								<Icon symbol={MAIL_ALT1_FILLED_24} className={`${CLASSNAME}__icon`} />
								<span className={`${CLASSNAME}__accessible-count`}>
									{localize('AdvertStats.replySent')}
								</span>
								{`${Format.number(isOperatingLease ? replySentOperatingLease : replySent)}x`}
							</span>
						</>
					)
				}
			/>
			<SimpleTooltip
				className={`${CLASSNAME}__count`}
				description={localize('AdvertStats.favorite')}
				ariaHidden={true}
				renderContent={(className) =>
					renderPlaceholderOrContent(
						<>
							{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
							<span tabIndex={0} className={`${CLASSNAME}__count-content ${className}`}>
								<Icon symbol={FAVORITE_FILLED_24} className={`${CLASSNAME}__icon`} />
								<span className={`${CLASSNAME}__accessible-count`}>
									{localize('AdvertStats.favorite')}
								</span>
								{`${Format.number(favorite)}x`}
							</span>
						</>
					)
				}
			/>
		</div>
	)
}

AdvertStatsView.propTypes = {
	isLoadingStatistics: PropTypes.bool,
	isOperatingLease: PropTypes.bool,
	statistics: PropTypes.shape({
		detailView: PropTypes.number,
		detailViewOperatingLease: PropTypes.number,
		phoneView: PropTypes.number,
		phoneViewOperatingLease: PropTypes.number,
		replySent: PropTypes.number,
		replySentOperatingLease: PropTypes.number,
		favorite: PropTypes.number
	})
}

export default AdvertStatsView
