import deepEqual from 'fast-deep-equal'

export default function filterUrlParamsCompare(objA = {}, objB = {}) {
	if (Object.keys(objA).length === Object.keys(objB).length) {
		return Object.keys(objA).every((key) => {
			const objAPropValue = objA[key]
			const objBPropValue = objB[key]

			if (typeof objAPropValue === 'string' && typeof objBPropValue === 'string') {
				// kvuli multioption filtrum, kde se nelze spolehat na poradi
				// (to je dane tim, jak uzivatel jednotlive polozky filtru vybira)
				const listA = objAPropValue.split(',') || []
				const listB = objBPropValue.split(',') || []

				if (listA.length === listB.length) {
					return listA.every((valA) => listB.includes(valA))
				} else {
					return false
				}
			} else if (
				(typeof objAPropValue === 'number' || typeof objBPropValue === 'number') &&
				!isNaN(Number(objAPropValue)) &&
				!isNaN(Number(objBPropValue)) &&
				Number(objAPropValue) === Number(objBPropValue)
			) {
				return true
			} else {
				return deepEqual(objAPropValue, objBPropValue)
			}
		})
	}

	return false
}
