
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertDetail'] = {
  reviewTitle: function(d) { return "Zkušenosti majitelů:"; },
  shareCopied: function(d) { return "Odkaz inzerátu byl zkopírován do schránky"; },
  insurance: function(d) { return "Povinné ručení"; },
  certificates: function(d) { return "Certifikáty"; },
  insuranceRange: function(d) { return "Povinné ručení pro tento vůz vychází v rozmezí:"; },
  insuranceMoreInfo: function(d) { return "Zjistit více o možnostech pojištění"; },
  insuranceMoreInfoMobile: function(d) { return "Zjistit více o možnostech"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	