import React, { useContext, useState, useEffect } from 'react'
import { Button, Textarea } from '@sznds/react'
import { AdvertReportsService, AdvertConstants, UserPropTypes } from '@inzeraty/models'
import { Format } from '@inzeraty/helpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import { useFire } from 'app/base/componentHelpers'
import Context from 'ima/page/context'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import PropTypes from 'prop-types'
import PremiseService from 'app/model/premise/PremiseService'
import AdvertPropTypes from 'app/model/advert/AdvertPropTypes'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHead from 'app/component/tableHead/TableHead'
import IAAdvertListReportedController from 'app/page/internalAdmin/advertListReported/IAAdvertListReportedController'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import EmptyList from 'app/component/emptyList/EmptyList'
import AdvertHistoryRow from 'app/page/internalAdmin/advertHistory/advertHistoryRow/AdvertHistoryRow'
import { PaginationEntity } from '@inzeraty/models'
import IAAdvertReportUrlConvertor from './IAAdvertReportUrlConvertor'
import ForbidUserPopup from '../components/forbidUserPopup/ForbidUserPopup'
import ForbidAdvertPopup from '../components/forbidAdvertPopup/ForbidAdvertPopup'
import IAAdvertReportController from './IAAdvertReportController'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import { STATUS_CONSTANTS, DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import UserService from 'app/model/user/UserService'
import { USER_HISTORY_STATUSES } from 'app/base/Constants'

import './IAAdvertReportView.less'

const CLASSNAME = 'p-ia-report'

const Row = ({ head, cell }) => {
	return (
		<tr>
			<th>{head}</th>
			<td>{cell}</td>
		</tr>
	)
}
Row.propTypes = {
	head: PropTypes.string.isRequired,
	cell: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
}

const Note = ({ note, onSubmit, loading }) => {
	const [newNote, setNewNote] = useState('')

	useEffect(() => {
		setNewNote(note)
	}, [note])

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault()
				onSubmit(newNote)
			}}
		>
			<Textarea
				className={`${CLASSNAME}__note`}
				disabled={loading}
				value={newNote}
				onChange={(event) => {
					setNewNote(event.target.value)
				}}
			/>
			<Button text='Uložit' size='small' type='submit' loading={loading} />
		</form>
	)
}

Note.propTypes = {
	note: PropTypes.string,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool
}

export default function IAAdvertReportView({
	isFullyLoaded = false,
	advertEntity = {},
	reportsCount,
	reportsAndPagination = {},
	tableIsLoading,
	historyIsLoading,
	userHistoryIsLoading,
	isUserForbidOpened,
	userEntity = {},
	isNewNoteSaving,
	historyEntitiesAndPagination = {},
	userHistoryAndPagination
}) {
	const { $Dictionary, $Router } = useContext(Context)
	const fire = useFire()

	const {
		id,
		dealType,
		category = {},
		manufacturerCb = {},
		modelCb = {},
		premise = {},
		phone,
		approvalAdmin
	} = advertEntity

	const { id: premiseId } = premise
	const isPremise = !!premiseId

	const { reportsEntities = [], paginationEntity = {} } = reportsAndPagination

	const renderRow = ({ rowId, entityData, rowCells }) => {
		return <AdvertHistoryRow key={rowId} entityData={entityData} rowCells={rowCells} />
	}

	const action = (action) => {
		fire('action', {
			action,
			entities: [advertEntity]
		})
	}

	const { serviceEnabled } = UserService.getSautoServiceData(userEntity)
	const isUserEnabled = userEntity.enabled && serviceEnabled

	const { ACTIONS } = IAAdvertReportController
	const { URL_APP_PARAMS } = AdvertDetailUrlConvertor.constants

	return (
		<Layout title='Detail nahlášeného inzerátu'>
			{isFullyLoaded && (
				<>
					<div className={`${CLASSNAME}__actions`}>
						{advertEntity.status !== STATUS_CONSTANTS.ACTIVE && (
							<button onClick={() => action(ACTIONS.ACTIVATE)}>Aktivovat inzerát</button>
						)}
						{advertEntity.status !== STATUS_CONSTANTS.INACTIVE && (
							<button onClick={() => action(ACTIONS.DEACTIVATE)}>Vypnout inzerát</button>
						)}
						<a
							href={$Router.link(
								dealType === DEAL_TYPE.OPERATING_LEASE
									? ROUTE_NAMES.INTERNAL_ADMIN.OPERATING_LEASE_PREVIEW
									: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_PREVIEW,
								{
									[URL_APP_PARAMS.ADVERT_ID]: id
								}
							)}
						>
							Náhled na inzerát
						</a>
						{!isPremise && (
							<button onClick={() => action(ACTIONS.OPEN_USER_FORBID)}>
								{isUserEnabled ? 'Zakázat uživatele' : 'Povolit uživatele'}
							</button>
						)}
						{approvalAdmin !== AdvertConstants.APPROVAL_STATUS.REJECTED && (
							<button onClick={() => action(ACTIONS.OPEN_ADVERT_FORBID)}>Zakázat inzerát</button>
						)}
						{reportsEntities.some(
							({ reportStatus }) => reportStatus === AdvertReportsService.STATUS.NEW
						) && (
							<button onClick={() => action(ACTIONS.STATUS_PROCESSED)}>
								Označit jako zpracovaný
							</button>
						)}
						{reportsEntities.some(
							({ reportStatus }) => reportStatus === AdvertReportsService.STATUS.PROCESSED
						) && <button onClick={() => action(ACTIONS.STATUS_NEW)}>Označit jako nový</button>}
					</div>
					<table className={`${CLASSNAME}__table`}>
						<Row
							head='ID inzerátu'
							cell={
								<>
									{id}{' '}
									<a
										href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO, {
											[InternalAdminUrlConvertor.constants.ADVERT_ID]: id
										})}
									>
										Editovat inzerát
									</a>
								</>
							}
						/>
						<Row head='Kategorie' cell={category.name} />
						<Row head='Výrobce' cell={manufacturerCb.name} />
						<Row head='Model' cell={modelCb.name} />
						<Row
							head='Nahlášení'
							cell={
								<>
									<a
										href={$Router.link(
											ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST_REPORTED,
											isPremise
												? {
														filterBy: 'premise_id',
														filterByValue: premiseId
												  }
												: {
														filterBy: 'user_id',
														filterByValue: userEntity.id
												  }
										)}
									>
										{reportsCount}
									</a>
								</>
							}
						/>
						{isPremise && (
							<Row
								head='Firma'
								cell={
									<>
										{PremiseService.getPremiseDataForPublic(premise).name} ({premiseId}) -{' '}
										<a
											href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
												filterBy: 'premise_id',
												filterByValue: premiseId
											})}
										>
											Inzeráty firmy
										</a>
										,{' '}
										<a
											href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_EDIT, {
												[InternalAdminUrlConvertor.constants.PREMISE_ID]: premiseId
											})}
										>
											Detail firmy
										</a>
									</>
								}
							/>
						)}
						{!isPremise && (
							<>
								<Row
									head='RUS email'
									cell={
										<a
											href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
												filterBy: 'email',
												filterByValue: userEntity.login
											})}
										>
											{userEntity.login}
										</a>
									}
								/>
								<Row
									head='Telefon'
									cell={
										<a
											href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
												filterBy: 'phone',
												filterByValue: phone
											})}
										>
											{Format.phoneNumber(phone)}
										</a>
									}
								/>
								<Row head='Stav RUSe' cell={isUserEnabled ? 'Povolen' : 'Zakázán'} />
								<Row
									head='RUS info'
									cell={
										<Note
											note={userEntity.note}
											onSubmit={(newNote) => {
												fire('saveNewNote', { note: newNote })
											}}
											loading={isNewNoteSaving}
										/>
									}
								/>
								<Row head='Stav vkládání inzerce' cell={isUserEnabled ? 'Povoleno' : 'Zakázáno'} />
							</>
						)}
					</table>
					{userHistoryAndPagination && userHistoryAndPagination.entities && (
						<div>
							<h3 className={`${CLASSNAME}__table-heading`}>Historie s účtem</h3>
							<div className={`${CLASSNAME}__history`}>
								<EntitiesTable
									entitiesAndPagination={userHistoryAndPagination}
									headCells={[
										<TableHead key='status'>Stav</TableHead>,
										<TableHead key='user'>Uživatel</TableHead>,
										<TableHead key='changedBy'>Důvod změny stavu</TableHead>,
										<TableHead key='date'>Datum</TableHead>
									]}
									rowCells={[
										({ action }) =>
											USER_HISTORY_STATUSES[action] ? USER_HISTORY_STATUSES[action] : action,
										({ changedBy }) => (changedBy ? changedBy.match(/[^(1-9)]+/g)[0] : ''),
										({ change }) => change.note,
										({ time }) => Format.dateTime(time)
									]}
									isLoading={userHistoryIsLoading}
									limitParam={IAAdvertReportUrlConvertor.userHistoryConstants.USER_HISTORY_LIMIT}
									pageParam={IAAdvertReportUrlConvertor.userHistoryConstants.USER_HISTORY_PAGE}
								/>
							</div>
						</div>
					)}
					{Boolean(reportsEntities.length) && (
						<>
							<h3 className={`${CLASSNAME}__table-heading`}>Výpis nahlášení</h3>
							<EntitiesTable
								entitiesAndPagination={{
									entities: reportsEntities,
									paginationEntity
								}}
								headCells={[
									<TableHead key='createDate'>Datum</TableHead>,
									<TableHead key='reporter'>Nahlašovatel</TableHead>,
									<TableHead key='reason'>Důvod</TableHead>
								]}
								rowCells={[
									({ createDate }) => Format.dateTime(createDate),
									({ userEmail, ipAddress }) => (
										<>
											<div>{userEmail}</div>
											<div>{ipAddress}</div>
										</>
									),
									({ reportReason, reportMessage = '' }) => (
										<>
											<div>
												{IAAdvertListReportedController.getReasonText(reportReason, $Dictionary)}
											</div>
											<div>{reportMessage}</div>
										</>
									)
								]}
								isLoading={tableIsLoading}
							/>
						</>
					)}
					{Boolean(historyEntitiesAndPagination.entities.length) && (
						<>
							<h3 className={`${CLASSNAME}__table-heading`}>Historie změn inzerátu </h3>
							<div className={`${CLASSNAME}__history`}>
								<EntitiesTable
									entitiesAndPagination={historyEntitiesAndPagination}
									headCells={[
										<TableHeadSorter
											key='time'
											sortParamName={IAAdvertReportUrlConvertor.constants.HISTORY_SORT}
											sortParam={IAAdvertReportUrlConvertor.constants.ID}
											isDefaultDescending={true}
											pageParamName={IAAdvertReportUrlConvertor.constants.HISTORY_PAGE}
										>
											Změněno
										</TableHeadSorter>,
										<TableHead key='changedBy'>Autor</TableHead>,
										<TableHead key='ipAddress'>IP adresa</TableHead>,
										<TableHead key='changes'>Změny</TableHead>
									]}
									rowCells={[
										({ time }) => Format.dateTime(time),
										({ changedBy }) => changedBy,
										({ ipAddress }) => ipAddress,
										() => ['Zobrazit detaily', { isOpener: true }]
									]}
									renderEmptyBody={() => (
										<EmptyList title='Nenašli jsme žadnou historii změn' text='' />
									)}
									renderRow={renderRow}
									isLoading={historyIsLoading}
									limitParam={IAAdvertReportUrlConvertor.constants.HISTORY_LIMIT}
									pageParam={IAAdvertReportUrlConvertor.constants.HISTORY_PAGE}
								/>
							</div>
						</>
					)}
					{isUserForbidOpened && <ForbidUserPopup />}
					<ForbidAdvertPopup />
				</>
			)}
		</Layout>
	)
}

IAAdvertReportView.propTypes = {
	isFullyLoaded: PropTypes.bool,
	advertEntity: PropTypes.shape(AdvertPropTypes),
	reportsCount: PropTypes.number,
	reportsAndPagination: PropTypes.object,
	tableIsLoading: PropTypes.bool,
	historyIsLoading: PropTypes.bool,
	userHistoryIsLoading: PropTypes.bool,
	buttonProcessedLoading: PropTypes.bool,
	isUserForbidOpened: PropTypes.bool,
	userEntity: PropTypes.shape(UserPropTypes),
	isNewNoteSaving: PropTypes.bool,
	historyEntitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.object),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	userHistoryAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.object),
		pagination: PropTypes.instanceOf(PaginationEntity)
	})
}
