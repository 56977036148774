/**
 * Stavy navyseni registraci.
 */
const RegistrationIncreaseStatus = Object.freeze({
	ORDER: 'order', // objednano
	RUNNING: 'running', // bezi
	PAUSED: 'paused', // pozastaveno
	FINISHED: 'finished', // uspesne ukonceno
	CANCELED: 'canceled' // zruseno
})

export default RegistrationIncreaseStatus
