import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AutoComplete, { filterItems } from 'app/component/autoComplete/AutoComplete'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import InputNumber from './InputNumber'

import './InputNumberWithOptions.less'

const CLASSNAME = 'c-input-number-with-options'

const InputNumberWithOptions = ({
	value = '',
	onChange = DEFAULT_PROPS.FUNCTION,
	options = DEFAULT_PROPS.ARRAY,
	renderInput = DEFAULT_PROPS.FUNCTION,
	renderOptions = DEFAULT_PROPS.FUNCTION
}) => {
	return (
		<AutoComplete
			inputValue={value}
			onInputValueChange={onChange}
			onSelect={(value) => value && onChange(value)}
		>
			{(downshift) => {
				const {
					inputValue,

					isOpen,
					getDropdownProps,
					renderDropdown: Dropdown,
					highlightedIndex
				} = downshift

				const getAutoCompleteInputProps = () => ({
					customInput: InputNumber,
					toggleButtonProps: getToggleButtonProps(),
					clearButtonProps: getClearButtonProps()
				})

				const getInputProps = (overrides = {}) =>
					downshift.getInputProps({
						...overrides,
						customInput: undefined
					})

				const getToggleButtonProps = (overrides = {}) =>
					!inputValue
						? downshift.getToggleButtonProps({
								isOpen,
								...overrides
						  })
						: undefined

				const getClearButtonProps = (overrides = {}) => {
					const { onClick: overrideOnClick, ...restOverrides } = overrides

					return inputValue
						? downshift.getClearButtonProps({
								onClick: (event) => {
									downshift.setState({
										inputValue: '',
										selectedItem: undefined
									})
									overrideOnClick && overrideOnClick(event)
								},
								...restOverrides
						  })
						: undefined
				}

				const filteredOptions = options.filter((option) =>
					filterItems(String(inputValue), String(option.value))
				)

				const isDropdownOpen = isOpen && filteredOptions.length > 0

				const getItemProps = (option = {}, index, overrides = {}) =>
					downshift.getItemProps({
						key: option.value,
						index,
						item: String(option.value),
						isHighlighted: highlightedIndex === index,
						className: `${CLASSNAME}__option-item`,
						...overrides
					})

				return (
					<div className={`${CLASSNAME}__content-wrapper`}>
						{renderInput({
							AutoCompleteInput,
							getAutoCompleteInputProps,
							getInputProps
						})}
						{isDropdownOpen &&
							renderOptions({
								filteredOptions,
								Dropdown,
								getDropdownProps,
								AutoCompleteOption,
								getItemProps
							})}
					</div>
				)
			}}
		</AutoComplete>
	)
}

InputNumberWithOptions.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		})
	),
	renderInput: PropTypes.func,
	renderOptions: PropTypes.func
}

export default InputNumberWithOptions
