export class StickyPanelVisibilityManager {
	// TODO
	// tohle je jen docasny zpusob, jakym schovat/zobrazit sticky listu s CTA
	// tlacitkem na popupu se vsemi filtry (nechceme uz informaci o tom, ktery
	// popup je otevren ukladat do globalniho stavu).

	constructor() {
		this._hiddenByMe = false
	}

	hide() {
		this._setStickyPanelVisibility(false)
		this._hiddenByMe = true
	}

	restore() {
		if (this._hiddenByMe) {
			this._setStickyPanelVisibility(true)
			this._hiddenByMe = false
		}
	}

	_setStickyPanelVisibility(visible) {
		if (typeof document !== 'undefined') {
			const stickyPanel = document.querySelector(
				'.c-extended-filter-popup-with-opener__sticky-panel'
			)

			if (stickyPanel && stickyPanel.style) {
				if (visible) {
					stickyPanel.style.visibility = 'visible'
				} else {
					stickyPanel.style.visibility = 'hidden'
				}
			}
		}
	}
}
