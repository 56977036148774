import UserwebBaseController from 'app/base/UserwebBaseController'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import GenericError from 'ima/error/GenericError'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import { getIdFromSeoName } from '../helper/IdFromSeoName'
import CatalogMeta from '../CatalogMeta'

export default class ModelsCatalogController extends UserwebBaseController {
	constructor(dependenciesHelper, catalogService) {
		super(dependenciesHelper)

		this._catalogService = catalogService
	}

	static get STATE_KEYS() {
		return {
			MODELS_LIST: 'MODELS_LIST'
		}
	}

	init() {
		super.init()
	}

	load() {
		const modelsList = this._loadAllModelsForBrand()

		return super.load({
			[ModelsCatalogController.STATE_KEYS.MODELS_LIST]: modelsList
		})
	}

	getDataForSsp() {
		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {}
		}
	}

	_loadAllModelsForBrand() {
		const { URL_API_PARAMS, URL_APP_PARAMS } = CatalogUrlConvertor.constants

		const { [URL_APP_PARAMS.BRAND_SEO_NAME]: seoName } = this.getRouteParams()

		const manufacturerId = getIdFromSeoName(seoName)

		if (manufacturerId) {
			return this._catalogService.getModels({ [URL_API_PARAMS.MANUFACTURER_ID]: manufacturerId })
		} else {
			// neplatna URL, vracime 400.
			const error = new GenericError('Neplatna URL.', {
				status: HTTP_STATUS_CODES.BAD_REQUEST
			})
			return Promise.reject(error)
		}
	}

	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const { [ModelsCatalogController.STATE_KEYS.MODELS_LIST]: modelsList = [] } = loadedResources
		const { manufacturer = {} } = modelsList ? modelsList[0] || {} : {}
		const { name = '' } = manufacturer

		const info = {
			count: modelsList.length,
			brandName: name.toUpperCase()
		}

		const { title, description } = this._getMetas(info)

		const metaTitle = title + dictionary.get('Base.titleSuffix')
		metaManager.setTitle(metaTitle)
		metaManager.setMetaName('twitter:title', metaTitle)
		metaManager.setMetaProperty('og:title', metaTitle)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('twitter:description', description)
		metaManager.setMetaProperty('og:description', description)
	}

	_getMetas(info) {
		const { route } = this._utils.$Router.getCurrentRouteInfo()
		const routeName = route.getName()

		return CatalogMeta.getMetas(routeName, this._utils.$Dictionary, info)
	}
}
