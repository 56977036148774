export default class CarCompareService {
	/**
	 * @constructor
	 * @param {carCompareResource} carCompareResource
	 * @param {carCompareFactory} carCompareFactory
	 */
	constructor(carCompareResource, carCompareFactory) {
		this._carCompareResource = carCompareResource
		this._carCompareFactory = carCompareFactory
	}
	/**
	 * Vrátí porovanie modelov so zadanym id inzeratu
	 *
	 * @method getCompareCars
	 * @public
	 * @param {Number}          advertId
	 * @param {Object}          options
	 * @return {Promise<Array>}	Data podobných inzerátů
	 */
	getCompareCars(advertId, options) {
		return this._carCompareResource.getCompareCars(advertId, options).then((response) => {
			return this._carCompareFactory.transformData(response.body.result)
		})
	}
}
