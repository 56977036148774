import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import HomeUrlConvertor from 'app/page/userweb/home/HomeUrlConvertor'
import FilterConstants from 'app/model/filter/FilterConstants'
import ROUTE_NAMES from 'app/base/RouteNames'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

const { URL_APP_PARAMS } = AdvertListUrlConvertor.constants
const { URL_APP_PARAMS: HP_URL_APP_PARAMS } = HomeUrlConvertor.constants

// seznam checkboxovych filtru, ktere kvuli lepsimu SEO chceme mit neustale v DOMu
const SPECIAL_FILTERS = {
	[FilterConstants.formLineIds.FUEL]: URL_APP_PARAMS.FUEL_NAME,
	[FilterConstants.formLineIds.VEHICLE_BODY]: URL_APP_PARAMS.VEHICLE_BODY_NAME,
	[FilterConstants.formLineIds.CONDITION]: URL_APP_PARAMS.CONDITION_NAME,
	[FilterConstants.formLineIds.COUNTRY_OF_ORIGIN]: URL_APP_PARAMS.COUNTRY_OF_ORIGIN_NAME,
	[FilterConstants.formLineIds.CERTIFIED_PROGRAM]: URL_APP_PARAMS.CERTIFIED_PROGRAM_NAME,
	[FilterConstants.formLineIds.VENDOR]: URL_APP_PARAMS.VENDOR_NAME
}

export const isSpecialSeoMultiOptionFilter = (formLineEntity) =>
	!!SPECIAL_FILTERS[formLineEntity.id]

export const getSeoLink = (router, formLineEntity, formLineOptionSeoName) => {
	const { route, params: currentRouteParams = {} } = router.getCurrentRouteInfo()

	let linkRouteName = route.getName()

	if (linkRouteName === ROUTE_NAMES.USERWEB.HOMEPAGE) {
		linkRouteName = ROUTE_NAMES.USERWEB.ADVERT_LIST

		const { parsedCategorySeoName } = HomeUrlConvertor.parseCategorySeoNameFromAppParams(
			currentRouteParams
		)

		Object.assign(currentRouteParams, {
			[URL_APP_PARAMS.CATEGORY_NAME]: parsedCategorySeoName
		})

		delete currentRouteParams[HP_URL_APP_PARAMS.CATEGORY_SEO_NAME]
	}

	delete currentRouteParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE]

	const { id: formLineId } = formLineEntity

	const newRouteParams = Object.assign({}, currentRouteParams, {
		[SPECIAL_FILTERS[formLineId]]: formLineOptionSeoName
	})

	const newLink = router.link(linkRouteName, newRouteParams)

	return sortQueryParamsAlphabetically(newLink)
}

export const getAdvertListSeoLink = (router, formLineEntity, formLineOptionSeoName) => {
	const { route } = router.getCurrentRouteInfo()

	const currentRouteName = route.getName()

	if (
		isSpecialSeoMultiOptionFilter(formLineEntity) &&
		(currentRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST ||
			currentRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES)
	) {
		return getSeoLink(router, formLineEntity, formLineOptionSeoName)
	}
}

const sortQueryParamsAlphabetically = (link = '') => {
	const linkSplit = link.split('?')
	const baseUrl = linkSplit[0]
	const params = linkSplit[1]

	if (!baseUrl || !params) {
		return link
	}

	const splitParams = params.split('&')

	// ak su v poli menej ako 2 query parametre, tak nepotrebujeme zoradit
	// a preto rovno vraciame danu url
	if (splitParams.length < 2) {
		return link
	}

	const sortedParams = splitParams.sort((a, b) => a.localeCompare(b)).join('&')

	return `${baseUrl}?${sortedParams}`
}
