import React, { useContext, useEffect, useState, useRef } from 'react'
import select from 'ima-plugin-select'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import { ElementInViewportDetector, Tabs } from '@inzeraty/components'
import ImaContext from 'ima/page/context'
import ReviewItem from './reviewItem/ReviewItem'
import classnames from 'classnames'
import ReviewsExtension from './ReviewsExtension'
import Paging from 'app/component/paging/PagingView'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import ReviewsUrlConvertor from './ReviewsUrlConvertor'
import ReviewEntity from 'app/model/review/ReviewEntity'
import ReviewForm from './reviewForm/ReviewForm'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import { STICKY_PANEL_CLASSNAME } from 'app/page/userweb/advertDetail/sellerContactSection/SellerContactSection'
import { Button } from '@sznds/react'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'

import './ReviewsCS.json'
import './Reviews.less'

const CLASSNAME = 'c-reviews'

const reviewEntitiesPlh = new Array(ReviewsUrlConvertor.constants.DEFAULTS.PER_PAGE).fill(
	new ReviewEntity({})
)

const Reviews = React.memo(
	({
		changeUrlParams = false,
		isLazyLoaded = false,
		isLoading,
		listParams,
		listAndPagination = {
			paginationEntity: {},
			reviewEntities: []
		},
		isAdvertEntityLoaded,
		loadingError = false
	}) => {
		const reviewContainerRef = useRef(null)
		const fire = useFire()
		const [visible, setVisible] = useState(!isLazyLoaded)
		const [isEmpty, setIsEmpty] = useState(false)
		const [type, setType] = useState(ReviewsExtension.TYPE.ALL)

		const URL_APP_PARAMS = UrlConvertor.constants.URL_APP_PARAMS
		const REVIEW_URL_APP_PARAMS = ReviewsUrlConvertor.constants.URL_APP_PARAMS

		useEffect(() => {
			const { [REVIEW_URL_APP_PARAMS.REVIEW_TYPE]: type = ReviewsExtension.TYPE.ALL } =
				listParams || {}

			setType(type)
		}, [listParams])

		useEffect(() => {
			const { paginationEntity: { total = 0 } = {} } = listAndPagination

			setIsEmpty(total === 0 && !isLoading)
		}, [listAndPagination])

		useEffect(() => {
			if (visible) fire('fetchReviews')
		}, [visible])

		const [isReadyToSendAnalytics, setIsReadyToSendAnalytics] = useState(false)
		useEffect(() => {
			setVisible(false)
			setIsReadyToSendAnalytics(true)
		}, [isAdvertEntityLoaded])

		const localize = useLocalize()

		const { $Dictionary, $Router, AnalyticsDataSender } = useContext(ImaContext)

		const { params: routeParams, route } = $Router.getCurrentRouteInfo()
		const routeName = route.getName()

		/**
		 * Sestaví URL adresu pro stránkování.
		 *
		 * @method _urlPageCreator
		 * @param {number} page - číslo stránky pro kterou potřebuji sestavit URL
		 * @return {string}
		 */
		const urlPageCreator = (page) => {
			if (changeUrlParams) {
				const newParams = UrlConvertor.getPagingPageParams(page, routeParams)

				return $Router.link(routeName, newParams)
			} else {
				return ''
			}
		}

		const changeParams = (params) => {
			fire('updateParams', params)
		}

		const renderPagination = () => {
			const { paginationEntity } = listAndPagination

			const pagingProps = {
				paginationEntity,
				urlPageCreator,
				size: 'small'
			}

			if (!changeUrlParams) {
				pagingProps.pageOnClick = (page) => {
					if (reviewContainerRef.current) {
						// Na mobilu mame sticky listu, ktera by po scrollu
						// prekryvala taby a CTA.
						//
						// Aby se to nestalo, potrebujeme vypocitat offset,
						// ktery se bude rovnat vysce te listy.
						let offset = 0
						const stickyPanel = document.querySelector(`.${STICKY_PANEL_CLASSNAME}`)

						if (stickyPanel) {
							offset = stickyPanel.offsetHeight
						}

						animateScrollTo(reviewContainerRef.current, {
							minDuration: 0,
							maxDuration: 0,
							offset: -8 - offset
						})
					}

					changeParams({ [URL_APP_PARAMS.PAGE]: page })
				}
			}

			return <Paging {...pagingProps} />
		}

		const renderReviewList = () => {
			const reviewEntities = isLoading ? reviewEntitiesPlh : listAndPagination.reviewEntities

			if (!isLoading && loadingError) {
				return (
					<>
						<div className={`${CLASSNAME}__status`}>
							<p className={`${CLASSNAME}__error`}>{localize('Reviews.loadingError')}</p>
						</div>
						<Button
							size='small'
							primary={true}
							text={localize('Reviews.tryAgain')}
							className={`${CLASSNAME}__refetch-button`}
							onClick={changeParams}
						/>
					</>
				)
			} else if (!isLoading && isEmpty) {
				return renderEmptyMessage()
			} else if (reviewEntities.length) {
				return (
					<>
						<ul className={`${CLASSNAME}__list`}>
							{reviewEntities.map((reviewEntity, index) => {
								return <ReviewItem key={reviewEntity.id || index} reviewEntity={reviewEntity} />
							})}
						</ul>

						<div className={`${CLASSNAME}__pagination-link`}>{renderPagination()}</div>
					</>
				)
			}
		}

		const renderReviewFormOpener = () => {
			return (
				<div className={`${CLASSNAME}__opener-wrap`}>
					<ReviewForm />
				</div>
			)
		}

		const renderTabs = () => {
			const tabs = [
				{
					text: localize('Reviews.tabAll'),
					selected: type === ReviewsExtension.TYPE.ALL,
					params: { [REVIEW_URL_APP_PARAMS.REVIEW_TYPE]: ReviewsExtension.TYPE.ALL }
				},
				{
					text: localize('Reviews.tabPositive'),
					selected: type === ReviewsExtension.TYPE.POSITIVE,
					params: { [REVIEW_URL_APP_PARAMS.REVIEW_TYPE]: ReviewsExtension.TYPE.POSITIVE }
				},
				{
					text: localize('Reviews.tabNeutral'),
					selected: type === ReviewsExtension.TYPE.NEUTRAL,
					params: { [REVIEW_URL_APP_PARAMS.REVIEW_TYPE]: ReviewsExtension.TYPE.NEUTRAL }
				},
				{
					text: localize('Reviews.tabNegative'),
					selected: type === ReviewsExtension.TYPE.NEGATIVE,
					params: { [REVIEW_URL_APP_PARAMS.REVIEW_TYPE]: ReviewsExtension.TYPE.NEGATIVE }
				}
			]

			const renderedTabs = tabs.map((tab) => {
				tab.disabled = isLoading

				if (!changeUrlParams) {
					tab.onClick = () => {
						changeParams(tab.params)
					}
				} else {
					const newParams = Object.assign({}, routeParams, tab.params)
					delete newParams[URL_APP_PARAMS.PAGE]

					tab.href = $Router.link(routeName, newParams)
				}

				return tab
			})

			return (
				<Tabs
					tabs={renderedTabs}
					className={classnames({
						[`${CLASSNAME}__tabs`]: true,
						[`${CLASSNAME}__tabs--loading`]: isLoading
					})}
				/>
			)
		}

		const renderEmptyMessage = () => {
			const typeLocalizeKey = `Reviews.type_${type}`
			const typeName = $Dictionary.has(typeLocalizeKey) ? localize(typeLocalizeKey) : ''

			return (
				<div className={`${CLASSNAME}__status`}>
					<p>
						{`${localize('Reviews.emptyPerex1')} `}
						{type !== ReviewsExtension.TYPE.ALL && `${localize('Reviews.emptyPerex2')} `}
						<strong>
							{type === ReviewsExtension.TYPE.ALL && `${localize('Reviews.emptyPerex2')} `}
							{localize('Reviews.noExperience', { TYPE_NAME: typeName })}
						</strong>
						.
					</p>
					{type === ReviewsExtension.TYPE.ALL && <p>{localize('Reviews.emptySufix')}</p>}
				</div>
			)
		}

		const renderReviews = () => {
			return (
				<div
					className={classnames({
						[CLASSNAME]: true,
						[`${CLASSNAME}--empty`]: isEmpty,
						[`${CLASSNAME}--all`]: type === ReviewsExtension.TYPE.ALL
					})}
					ref={reviewContainerRef}
				>
					{!loadingError && renderReviewFormOpener()}
					{!(!isLoading && isEmpty && type === ReviewsExtension.TYPE.ALL) && renderTabs()}
					{renderReviewList()}
				</div>
			)
		}

		if (isLazyLoaded) {
			return (
				<>
					<ElementInViewportDetector
						offsetTop={500}
						onRecalc={({ isElementInsideViewport }) => {
							if (isAdvertEntityLoaded && !visible && isElementInsideViewport) {
								setVisible(true)
							}
						}}
					></ElementInViewportDetector>

					<ElementInViewportDetector
						offsetTop={-150}
						onRecalc={({ isElementInsideViewport }) => {
							if (isElementInsideViewport && isAdvertEntityLoaded && isReadyToSendAnalytics) {
								setIsReadyToSendAnalytics(false)
								AnalyticsDataSender.hitForVisibleImpress({
									elementType: 'Review',
									page: routeName
								})
							}
						}}
					>
						{renderReviews()}
					</ElementInViewportDetector>
				</>
			)
		} else {
			return renderReviews()
		}
	}
)

const selectors = (state) => {
	return {
		loadingError: state[ReviewsExtension.STATE_IDS.LOADING_ERROR],
		isLoading: state[ReviewsExtension.STATE_IDS.IS_LOADING],
		listParams: state[ReviewsExtension.STATE_IDS.PARAMS],
		listAndPagination: state[ReviewsExtension.STATE_IDS.LIST_PAGINATION]
	}
}

Reviews.propTypes = {
	changeUrlParams: PropTypes.bool,
	isLazyLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	listParams: PropTypes.object,
	listAndPagination: PropTypes.shape({
		reviewEntities: PropTypes.arrayOf(PropTypes.instanceOf(ReviewEntity)),
		paginationEntity: PropTypes.instanceOf(PaginationEntity)
	}),
	isAdvertEntityLoaded: PropTypes.bool,
	loadingError: PropTypes.bool
}

export default select(selectors)(Reviews)
