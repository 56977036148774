import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import MultiOptionFilterLogic from 'app/component/filters/logic/MultiOptionFilterLogic'
import MultioptionOpener from 'app/component/filters/forms/main/openers/multioptionOpener/MultioptionOpener'
import MultioptionForm from 'app/component/filters/forms/multioption/Multioption'
import FilterDropdownButton from './shared/FilterDropdownButton'
import DropdownLogic from 'app/component/filters/logic/modals/DropdownLogic'
import FilterDropdownWithOpener from './shared/FilterDropdownWithOpener'
import DropdownOpenerLogic from 'app/component/filters/logic/openers/DropdownOpenerLogic'
import ScopedFormLineEntity from 'app/component/filters/logic/ScopedFormLineEntity'
import FloatingDropdown from 'app/component/floatingDropdown/FloatingDropdown'
import { getSeoLink } from 'app/component/filters/seo/multiOptionFilterLinks'
import { SEO_MAPPING_FOR_TESTING } from 'app/base/SeoTesting'

import './MultiOptionFilterDropdownWithOpener.less'

export default class MultiOptionFilterDropdownWithOpener extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			dropdownDefinition: PropTypes.object,
			context: PropTypes.string.isRequired,
			value: PropTypes.number,
			filterIndex: PropTypes.number,
			renderOpener: PropTypes.func,
			className: PropTypes.string,
			seoAdvertCounts: PropTypes.object
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			className: '',
			seoAdvertCounts: DEFAULT_PROPS.OBJECT
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderOpener = this._renderOpener.bind(this)
		this._renderDropdown = this._renderDropdown.bind(this)

		this._renderFilterForm = this._renderFilterForm.bind(this)
		this._renderButton = this._renderButton.bind(this)

		this._openerRef = React.createRef()
	}

	render() {
		const { dropdownDefinition, context, value } = this.props

		return (
			<FilterDropdownWithOpener
				dropdownDefinition={dropdownDefinition}
				context={context}
				value={value}
				renderOpener={this._renderOpener}
				renderDropdown={this._renderDropdown}
			/>
		)
	}

	_renderOpener() {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			dropdownDefinition,
			context,
			filterIndex,
			renderOpener
		} = this.props

		return (
			<MultiOptionFilterLogic
				formLineEntity={globalFormLineEntity}
				changeFilter={globalChangeFilter}
				context={context}
			>
				{(filterLogicChildrenProps) => {
					const {
						form: { changeFilter }
					} = filterLogicChildrenProps

					return (
						<DropdownOpenerLogic
							formLineEntity={globalFormLineEntity}
							changeFilter={changeFilter}
							context={context}
						>
							{(dropdownOpenerLogicData) => {
								const { openDropdown, clearFilter } = dropdownOpenerLogicData

								return renderOpener ? (
									renderOpener(dropdownOpenerLogicData) //TODO proc neposilame ven i filterLogicChildrenProps, respektive nemusime tady renderovat cely strom
								) : (
									<>
										{this._renderSeoOptions(globalFormLineEntity)}
										<MultioptionOpener
											formLineEntity={globalFormLineEntity}
											context={context}
											filterIndex={filterIndex}
											isOpened={!!dropdownDefinition}
											onOpen={openDropdown}
											onResetFilter={clearFilter}
											surface={1}
											openerRef={this._openerRef}
										/>
									</>
								)
							}}
						</DropdownOpenerLogic>
					)
				}}
			</MultiOptionFilterLogic>
		)
	}

	_renderSeoOptions(formLineEntity = {}) {
		const { seoAdvertCounts = {} } = this.props
		const { options = [], id } = formLineEntity
		const router = this.utils.$Router

		const { counts: filterCounts = {} } = seoAdvertCounts || {}

		if (Object.keys(filterCounts).length > 0) {
			return (
				<>
					{options
						.filter(({ value }) => filterCounts[value] > 0)
						.map(({ seoName, name }) => {
							const link = getSeoLink(router, formLineEntity, seoName)
							return (
								<a key={seoName} href={link} data-seoth={SEO_MAPPING_FOR_TESTING[id]}>
									{name}
								</a>
							)
						})}
				</>
			)
		} else {
			return null
		}
	}

	_renderDropdown() {
		const { formLineEntity: globalFormLineEntity, context } = this.props

		return (
			<ScopedFormLineEntity formLineEntity={globalFormLineEntity}>
				{(scopedFormLineEntityChildrenProps) => {
					const { localFormLineEntity, localChangeFilter } = scopedFormLineEntityChildrenProps

					return (
						<MultiOptionFilterLogic
							formLineEntity={localFormLineEntity}
							changeFilter={localChangeFilter}
							context={context}
							scopedContext={true}
						>
							{(filterLogicChildrenProps) =>
								this._renderFilterDropdown(
									scopedFormLineEntityChildrenProps,
									filterLogicChildrenProps
								)
							}
						</MultiOptionFilterLogic>
					)
				}}
			</ScopedFormLineEntity>
		)
	}

	_renderFilterDropdown(scopedFormLineEntityChildrenProps, filterLogicChildrenProps) {
		const { changeFilter: globalChangeFilter, dropdownDefinition, value } = this.props
		const { localFormLineEntity } = scopedFormLineEntityChildrenProps

		return (
			<DropdownLogic
				formLineEntity={localFormLineEntity}
				changeFilter={globalChangeFilter}
				dropdownDefinition={dropdownDefinition}
				value={value}
				filterLogicData={filterLogicChildrenProps}
			>
				{(filterDropdownLogicChildrenProps) => {
					const { closeDropdown } = filterDropdownLogicChildrenProps

					return (
						<FloatingDropdown onClose={closeDropdown} openerRef={this._openerRef}>
							{this._renderFilterForm(filterDropdownLogicChildrenProps, filterLogicChildrenProps)}
							{this._renderButton(filterDropdownLogicChildrenProps, filterLogicChildrenProps)}
						</FloatingDropdown>
					)
				}}
			</DropdownLogic>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderFilterForm(filterDropdownLogicChildrenProps, filterLogicChildrenProps) {
		const {
			form: { changeFilter },
			advertsCount: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity }
		} = filterLogicChildrenProps

		const { className } = this.props

		const formClassName = className ? { className } : {}

		return (
			<MultioptionForm
				formLineEntity={formLineEntity}
				onChange={changeFilter}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				filteredAdvertsCount={filteredAdvertsCount}
				// nechceme v bublinach s pocty inzeratu vypisovat
				// text 'xxx nabidek', ale pouze 'xxx'
				isCountShownWithAddText={false}
				{...formClassName}
			/>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderButton(filterDropdownLogicChildrenProps, filterLogicChildrenProps) {
		return (
			<FilterDropdownButton
				filterDropdownLogicData={filterDropdownLogicChildrenProps}
				filterFormLogicData={filterLogicChildrenProps}
			/>
		)
	}
}
