import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocalize } from 'app/base/componentHelpers'
import { Tooltip } from '@inzeraty/components'

import './HomeDelivery.less'
import './HomeDeliveryCS.json'

const CLASSNAME = 'c-home-delivery'

const HomeDelivery = ({ hasTooltip }) => {
	const localize = useLocalize()

	const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

	const tooltipDictionary = {
		ariaClose: localize('HomeDelivery.tooltipAriaClose'),
		blindClosePrefix: localize('HomeDelivery.tooltipBlindClosePrefix'),
		ariaOpener: localize('HomeDelivery.tooltipAriaOpener'),
		ariaTitle: localize('HomeDelivery.tooltipAriaTitle')
	}

	return (
		<span
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}--tooltip-open`]: tooltipIsOpen
			})}
		>
			{localize('HomeDelivery.carToHome')}
			{hasTooltip && (
				<Tooltip
					title={localize('HomeDelivery.carToHome')}
					dictionary={tooltipDictionary}
					setIsOpenCallback={setTooltipIsOpen}
				>
					{localize('HomeDelivery.tooltipText')}
				</Tooltip>
			)}
		</span>
	)
}

HomeDelivery.propTypes = {
	hasTooltip: PropTypes.bool
}

HomeDelivery.defaultProps = {
	hasTooltip: false
}

export default React.memo(HomeDelivery)
