import React, { useContext } from 'react'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import { Surface } from '@sznds/react'
import Context from 'ima/page/context'
import { StatusMessage } from '@inzeraty/components'
import VerifyPinAndPay from './verifyPinAndPay/VerifyPinAndPay'
import LinkArrow, { ARROW_TYPE } from 'app/component/linkArrow/LinkArrowView'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import FormStepper from '../../component/stepper/FormStepperView'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import PAYMENT_STATE_KEYS from '../PaymentStateKeys'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'

import './SMSPayment.less'
import './SMSPaymentCS.json'

const CLASSNAME = 'c-sms-payment'
const SMS_PHONE_NUMBER_GATEWAY_PREFIX = '903 15 '

const SMSPayment = (props) => {
	const context = useContext(Context)
	const router = context.$Router

	const { errorMessage, disabled, payments = [], publicationLength, isDraft } = props

	const { price } =
		payments.find((payment) => {
			const { advertPublicationLength, paymentType } = payment

			return (
				publicationLength === advertPublicationLength &&
				paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS
			)
		}) || {}

	const { params } = router.getCurrentRouteInfo()
	const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
	const { [URL_APP_PARAMS.ADVERT_ID]: advertId } = params

	const localize = useLocalize()
	const fire = useFire()

	const mobileBackBtnProps = () => {
		const props = {
			type: ARROW_TYPE.LEFT,
			children: localize('SMSPayment.backMobile'),
			className: `${CLASSNAME}__back-mobile`
		}

		if (disabled) {
			props.disabled = disabled
		} else {
			props.url = router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, {
				[URL_APP_PARAMS.ADVERT_ID]: advertId
			})
		}

		return props
	}

	return (
		<div className={CLASSNAME}>
			{errorMessage && (
				<StatusMessage
					type={StatusMessage.TYPE.ERROR}
					title={localize('PaymentInfo.smsPaymentErrorHeader')}
					onClose={() => {
						fire('errorClose')
					}}
					className={`${CLASSNAME}__error`}
				>
					{localize(`PaymentInfo.${errorMessage}`)}
				</StatusMessage>
			)}

			<Surface surface={5} className={`${CLASSNAME}__form`}>
				<LinkArrow {...mobileBackBtnProps()} />
				<div className={`${CLASSNAME}__header`}>{localize('SMSPayment.header')}</div>
				<StatusMessage
					className={`${CLASSNAME}__instructions`}
					title={localize('SMSPayment.instructionHeader')}
					type={StatusMessage.TYPE.INFO}
					style={StatusMessage.STYLE.INLINE}
				>
					<div className={`${CLASSNAME}__subtitle`}>
						<span>{localize('SMSPayment.infoText1')}</span>
						<span className={`${CLASSNAME}__text-bold`}>
							{localize('SMSPayment.infoTextBold1')}
						</span>
						<span>{localize('SMSPayment.infoText2')}</span>
						{price ? (
							<span
								className={`${CLASSNAME}__text-bold`}
							>{`${SMS_PHONE_NUMBER_GATEWAY_PREFIX}${price}`}</span>
						) : (
							<span className={`${CLASSNAME}__number-loading`}></span>
						)}
						<span>{localize('SMSPayment.infoText3')}</span>
						<div className={`${CLASSNAME}__text-bold`}>{localize('SMSPayment.infoTextBold2')}</div>
					</div>
					<div>{localize('SMSPayment.infoText4')}</div>
				</StatusMessage>
				<VerifyPinAndPay isDraft={isDraft} />
			</Surface>
			<FormStepper
				prevBtnProps={{
					href: router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, {
						[URL_APP_PARAMS.ADVERT_ID]: advertId
					}),
					text: localize('SMSPayment.backDesktop')
				}}
				saveBtnProps={{ text: '' }}
			/>
		</div>
	)
}

const selectors = (state) => ({
	payments: state[PAYMENT_STATE_KEYS.PAYMENTS],
	publicationLength: state[PAYMENT_STATE_KEYS.PUBLICATION_LENGTH]
})

SMSPayment.propTypes = {
	disabled: PropTypes.bool,
	errorMessage: PropTypes.string,
	payments: PropTypes.array,
	publicationLength: PropTypes.number,
	isDraft: PropTypes.bool
}

export default React.memo(select(selectors)(SMSPayment))
