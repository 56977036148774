
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['VINWidget'] = {
  vinExplanation: function(d) { return "Co je VIN kód?"; },
  vinExplanationTitle: function(d) { return "Co je to VIN kód?"; },
  vinExplanationTextFirst: function(d) { return "VIN je 17-ti místný kód, který jednoznačně a jedinečně identifikuje vozidlo. Je tedy možné říci, že se jedná o jakési rodné číslo vozidla."; },
  vinExplanationTextSecond: function(d) { return "Nálezt ho můžete ve všech technických průkazech vozidla. VIN je ale také umisťováno na několika různých místech ve vozidle a minimálně jednou je vyraženo do karoserie nebo nosného rámu."; },
  close: function(d) { return "zavřít"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	