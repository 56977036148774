import React, { useState, useContext, useEffect } from 'react'
import classnames from 'classnames'
import { Input, Icon, Spinner, Checkbox } from '@sznds/react'
import { CHECKER_NORMAL_OUTLINE_24, PHONE_FILLED_24 } from '@sznds/icons'
import Context from 'ima/page/context'
import PhoneInput from 'react-input-mask'
import { useLocalize } from 'app/base/componentHelpers'
import { PHONE_WIDGET_CONSTANTS } from './PhoneWidgetConstants'
import { CONTACTS_STATUS as PHONE_WIDGET_STATUS } from '../contacts/shared/ContactsStatus'
import SendSMS from '../contacts/shared/sendSMS/SendSMS'
import VerifyPin from '../contacts/shared/verifyPin/VerifyPin'
import BasicWidget from '../../../../component/widgets/basic/BasicWidget'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

export const PHONE_WIDGET_ID = 'phone_id'

const CLASSNAME = 'c-phone-widget'

import './PhoneWidget.less'
import './PhoneWidgetCS.json'

const PhoneWidget = (props) => {
	const context = useContext(Context)
	const eventBus = context.$EventBus

	const localize = useLocalize()

	const [verifying, setIsVerifying] = useState(false)

	const [status, setStatus] = useState(
		props.formLineEntity.extra.isPhoneValid
			? PHONE_WIDGET_STATUS.VERIFIED
			: PHONE_WIDGET_STATUS.DEFAULT
	)

	const [messageSending, setMessageSending] = useState(false)

	useEffect(() => {
		const { extra: { isPhoneValid } = {} } = props.formLineEntity

		if (isPhoneValid) {
			setStatus(PHONE_WIDGET_STATUS.VERIFIED)
		}
	}, [props.formLineEntity])

	const renderWidget = (
		formLineEntity,
		renderLabel,
		renderInput,
		renderMessage,
		renderErrorMessage
	) => {
		const { errorMessage, disabled } = formLineEntity

		return (
			<>
				<div className='c-basic-widget__label-box'>{renderLabel()}</div>
				<div className='c-basic-widget__content'>
					{renderInput()}
					{errorMessage && renderErrorMessage()}

					{!disabled && renderVerificationMessages()}
				</div>
			</>
		)
	}

	const renderInput = (formLineEntity, props) => {
		const { PHONE_PREFIX, INPUT_MASK } = PHONE_WIDGET_CONSTANTS.INPUT_PHONE
		const {
			disabled,
			value: { phone } = {},
			errorMessage,
			required = false,
			extra: { isPhoneValid, isLoaded = true, pending } = {}
		} = formLineEntity

		const noPrefixNumber = getNoPrefixNumber(phone)

		const isCheckboxRendered = isPhoneValid && !pending

		const isInputDisabled = disabled || isPinSent()

		return (
			<div className={`${CLASSNAME}__input-wrap`}>
				<div className={`${CLASSNAME}__input-msg`}>
					<div className={`${CLASSNAME}__input-spinner`}>
						<Input
							key='phone-prefix'
							disabled={isInputDisabled}
							className={classnames({
								[`${CLASSNAME}__phone-prefix`]: true,
								['sds-input--disabled']: isInputDisabled
							})}
							value={`+${PHONE_PREFIX}`}
							readOnly={true}
						/>
						<PhoneInput
							{...props}
							key='phone-input'
							mask={INPUT_MASK}
							maskChar={null}
							alwaysShowMask={true}
							type='tel'
							value={noPrefixNumber}
							disabled={isInputDisabled}
							error={errorMessage}
							aria-required={required}
						>
							{(inputProps) => (
								<Input
									{...inputProps}
									disabled={isInputDisabled}
									className={classnames({
										[props.className]: !!props.className,
										[`${CLASSNAME}__input`]: true,
										['sds-input--disabled']: isInputDisabled
									})}
								/>
							)}
						</PhoneInput>
						{(verifying || !isLoaded) && <Spinner className={`${CLASSNAME}__spinner`} />}
					</div>
					{!isInputDisabled &&
						(isCheckboxRendered
							? renderHideNumberCheckbox(`${CLASSNAME}__desktop`)
							: renderHideNumberMessage())}
				</div>
				{!isInputDisabled && renderVerifiedNumberMessage()}
				{isCheckboxRendered && renderHideNumberCheckbox(`${CLASSNAME}__mobile`)}
			</div>
		)
	}

	const renderHideNumberCheckbox = (wrapperClassname) => {
		const { value: { dontShowPhone = false } = {}, disabled } = props.formLineEntity

		if (status === PHONE_WIDGET_STATUS.VERIFIED) {
			return (
				<span className={wrapperClassname}>
					<Checkbox
						className={`${CLASSNAME}__hide-number-checkbox`}
						checked={dontShowPhone}
						disabled={disabled}
						label={localize('PhoneWidget.hideNumber')}
						data-e2e='hide-phone-number-checkbox'
						onChange={handleCheckboxChange}
					/>
				</span>
			)
		}
	}

	const handleCheckboxChange = (event) => {
		const {
			formLineEntity: { id, value },
			onChange
		} = props
		const { checked } = event.target

		onChange({
			id,
			value: Object.assign({}, value, {
				dontShowPhone: checked
			})
		})
	}

	const renderHideNumberMessage = () => {
		return (
			<div className={`${CLASSNAME}__hide-msg`}>{localize('PhoneWidget.privacyStatement')}</div>
		)
	}

	const getNoPrefixNumber = (value = '') => {
		let newValue = value.replace(/ /g, '')

		if (newValue.length >= 12) {
			newValue = newValue.substr(-9)
		}

		return newValue
	}

	const validatePhone = (phone) => {
		setIsVerifying(true)

		eventBus.fire(window, 'validatePhone', {
			phone,
			callback: handlePhoneValidation
		})
	}

	const handlePhoneValidation = (phone, isPhoneValid) => {
		const { extra } = props.formLineEntity
		const { PHONE_LENGTH } = PHONE_WIDGET_CONSTANTS.INPUT_PHONE

		if (isPhoneValid) {
			setStatusInfo(PHONE_WIDGET_STATUS.VERIFIED)
			change({
				errorMessage: undefined,
				extra: Object.assign({}, extra, {
					pending: false,
					isPhoneValid: true,
					isLoaded: true
				})
			})
		} else if (phone && phone.length === PHONE_LENGTH && !isPhoneValid) {
			setStatusInfo(PHONE_WIDGET_STATUS.UNKNOWN)
			change({
				extra: Object.assign({}, extra, {
					pending: true,
					isPhoneValid: false,
					isLoaded: true
				})
			})
		} else if (phone && phone.length < PHONE_LENGTH && !isPhoneValid) {
			change({
				errorMessage: localize('PhoneWidget.wrongNumber'),
				extra: Object.assign({}, extra, {
					pending: true,
					isPhoneValid: false,
					isLoaded: true
				})
			})
			setIsVerifying(false)
		} else {
			setStatusInfo()
		}
	}

	const setStatusInfo = (newStatus = PHONE_WIDGET_STATUS.DEFAULT) => {
		if (status !== newStatus && newStatus === PHONE_WIDGET_STATUS.DEFAULT) {
			change({ errorMessage: undefined })
		}

		setStatus(newStatus)
		setIsVerifying(false)
	}

	const renderVerificationMessages = () => {
		const { UNKNOWN, PIN_SENT } = PHONE_WIDGET_STATUS

		switch (status) {
			case UNKNOWN: {
				return (
					<SendSMS
						icon={PHONE_FILLED_24}
						sendMessage={sendMessage}
						loading={messageSending}
						dict={{
							boldText: localize('PhoneWidget.unknown'),
							buttonText: localize('PhoneWidget.sendPin')
						}}
					/>
				)
			}

			case PIN_SENT: {
				return renderPinSet()
			}

			default:
				return null
		}
	}

	const renderVerifiedNumberMessage = () => {
		if (status === PHONE_WIDGET_STATUS.VERIFIED) {
			return (
				<div className={`${CLASSNAME}__succ`}>
					<Icon symbol={CHECKER_NORMAL_OUTLINE_24} className={`${CLASSNAME}__succ-icon`} />
					<div className={`${CLASSNAME}__succ-sentence`}>{localize('PhoneWidget.pinVerified')}</div>
				</div>
			)
		}
	}

	const renderPinSet = () => {
		const { formLineEntity } = props
		const { value: { phone = '' } = {} } = formLineEntity

		const noPrefixNumber = getNoPrefixNumber(phone)

		const dict = {
			pinMsg1: localize('PhoneWidget.pinMsg1'),
			contactValue: `+${PHONE_WIDGET_CONSTANTS.INPUT_PHONE.PHONE_PREFIX} ${noPrefixNumber}`,
			pinMsg2: localize('PhoneWidget.pinMsg2')
		}

		return (
			<VerifyPin
				icon={PHONE_FILLED_24}
				dict={dict}
				messageLoading={messageSending}
				verifyPin={verifyPin}
				onVerifyPinSuccess={onVerifyPinSuccess}
				onVerifyPinError={onVerifyPinError}
				resendPin={sendMessage}
				cancelVerification={cancelPinVerification}
			/>
		)
	}

	const onVerifyPinSuccess = () => {
		setStatusInfo(PHONE_WIDGET_STATUS.VERIFIED)
		change({
			errorMessage: undefined,
			extra: Object.assign({}, props.formLineEntity.extra, {
				pending: false,
				isPhoneValid: true,
				isLoaded: true
			})
		})
	}

	const onVerifyPinError = () => {
		setStatusInfo(PHONE_WIDGET_STATUS.PIN_SENT)
	}

	const cancelPinVerification = () => {
		setStatusInfo(PHONE_WIDGET_STATUS.UNKNOWN)
	}

	const isPinSent = () => {
		const { PIN_SENT } = PHONE_WIDGET_STATUS

		return status === PIN_SENT
	}

	const sendMessage = (event) => {
		event.preventDefault()
		const { formLineEntity } = props
		const { value: { phone = '' } = {} } = formLineEntity

		eventBus.fire(window, 'sendMessage', {
			phone,
			callback: handleSendMessage
		})

		setMessageSending(true)
	}

	const handleSendMessage = (error) => {
		if (!error) {
			setStatusInfo(PHONE_WIDGET_STATUS.PIN_SENT)
		} else {
			change({
				errorMessage: localize('PhoneWidget.wrongNumber'),
				extra: Object.assign({}, props.formLineEntity.extra, {
					pending: true,
					isPhoneValid: false
				})
			})
		}

		setMessageSending(false)
	}

	const verifyPin = (pin, callback, saveToProfile) => {
		const { formLineEntity } = props
		const { value: { phone } = {} } = formLineEntity

		eventBus.fire(window, 'verifyPin', {
			callback,
			pin,
			phone,
			saveToProfile
		})
	}

	const change = (formLineDataToBeChanged) => {
		const { formLineEntity, onChange } = props

		onChange(
			Object.assign({}, formLineDataToBeChanged, {
				id: formLineEntity.id
			})
		)
	}

	const handleInputChange = (_, newPhoneValue = '') => {
		const { value, extra } = props.formLineEntity
		const { phone: currentPhoneValue = '' } = value

		const currentPhoneValueRaw = getNoPrefixNumber(currentPhoneValue)
		const newPhoneValueRaw = getNoPrefixNumber(newPhoneValue)

		if (currentPhoneValueRaw !== newPhoneValueRaw) {
			change({
				value: Object.assign({}, value, {
					phone: newPhoneValueRaw
				}),
				errorMessage: undefined,
				extra: Object.assign({}, extra, {
					isPhoneValid: false,
					pending: true,
					isLoaded: true
				})
			})

			const { PHONE_LENGTH } = PHONE_WIDGET_CONSTANTS.INPUT_PHONE

			if (newPhoneValueRaw.length === PHONE_LENGTH) {
				validatePhone(newPhoneValueRaw)
			} else {
				setStatusInfo()
			}
		}
	}

	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={props.formLineEntity}
			name={PHONE_WIDGET_ID}
			onChange={handleInputChange}
			renderWidget={renderWidget}
			renderInput={renderInput}
		/>
	)
}

PhoneWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string
}

export default React.memo(PhoneWidget)
