import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Checkbox } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import operatingLeasePropTypes from 'app/model/operatingLease/OperatingLeasePropTypes'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import { useLocalize } from 'app/base/componentHelpers'
import NewFeatureFlag from 'app/component/newFeatureFlag/NewFeatureFlag'
import NewAdvertSectionHeading from 'app/component/newAdvertSectionHeading/NewAdvertSectionHeading'

import './DealTypeWidget.less'
import './DealTypeWidgetCS.json'

export const DEAL_TYPE_WIDGET_ID = 'deal_type_widget_id'

const CLASSNAME = 'c-deal-type'

const DealTypeCheckbox = ({
	isChecked = false,
	'data-e2e': dataE2E,
	onChange = DEFAULT_PROPS.FUNCTION,
	children
}) => {
	return (
		<div className={`${CLASSNAME}__checkbox-wrapper`}>
			<Checkbox checked={isChecked} label={children} data-e2e={dataE2E} onChange={onChange} />
		</div>
	)
}

DealTypeCheckbox.propTypes = {
	isChecked: PropTypes.bool,
	'data-e2e': PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onChange: PropTypes.func
}

const DealTypeWidget = ({
	className,
	formLineEntity = DEFAULT_PROPS.OBJECT,
	isFeatureFlagShown = true,
	operatingLeaseVariants = DEFAULT_PROPS.ARRAY,
	onChange = DEFAULT_PROPS.FUNCTION
}) => {
	const localize = useLocalize()

	const { id, value = DEAL_TYPE.SALE, errorMessage } = formLineEntity

	const isSaleChecked = value === DEAL_TYPE.SALE || value === DEAL_TYPE.SALE_OR_LEASE
	const isOperLeasingChecked =
		value === DEAL_TYPE.OPERATING_LEASE || value === DEAL_TYPE.SALE_OR_LEASE

	useEffect(() => {
		if (operatingLeaseVariants.length) {
			onChange({
				id,
				errorMessage: undefined
			})
		}
	}, [operatingLeaseVariants])

	const handleSaleOnChange = (event) => {
		event.stopPropagation()

		const isSaleCheckedNew = event.target.checked
		let newValue = DEAL_TYPE.SALE

		if (isSaleCheckedNew && isOperLeasingChecked) {
			newValue = DEAL_TYPE.SALE_OR_LEASE
		} else if (!isSaleCheckedNew) {
			newValue = DEAL_TYPE.OPERATING_LEASE
		}

		onChange({
			id,
			value: newValue,
			errorMessage: undefined
		})
	}

	const handleOperatingLeaseOnChange = (event) => {
		event.stopPropagation()

		const isOperLeasingCheckedNew = event.target.checked
		let newValue = DEAL_TYPE.SALE

		if (isSaleChecked && isOperLeasingCheckedNew) {
			newValue = DEAL_TYPE.SALE_OR_LEASE
		} else if (!isSaleChecked && isOperLeasingCheckedNew) {
			newValue = DEAL_TYPE.OPERATING_LEASE
		}

		onChange({
			id,
			value: newValue,
			errorMessage: undefined
		})
	}

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<NewAdvertSectionHeading>{localize('DealTypeWidget.type')}</NewAdvertSectionHeading>
			<div className={`${CLASSNAME}__layout`}>
				<DealTypeCheckbox
					isChecked={isSaleChecked}
					data-e2e='sale-deal-type-checkbox'
					onChange={handleSaleOnChange}
				>
					{localize('DealTypeWidget.sale')}
				</DealTypeCheckbox>
				<DealTypeCheckbox
					isChecked={isOperLeasingChecked}
					data-e2e='operating-lease-deal-type-checkbox'
					onChange={handleOperatingLeaseOnChange}
				>
					{localize('DealTypeWidget.operatingLease')}
					{isFeatureFlagShown && <NewFeatureFlag className={`${CLASSNAME}__new-feature`} />}
				</DealTypeCheckbox>
			</div>
			{!!errorMessage && <div className={`${CLASSNAME}__error`}>{errorMessage}</div>}
		</div>
	)
}

DealTypeWidget.propTypes = {
	className: PropTypes.string,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	isFeatureFlagShown: PropTypes.bool,
	operatingLeaseVariants: PropTypes.arrayOf(PropTypes.shape(operatingLeasePropTypes)),
	onChange: PropTypes.func.isRequired
}

export default DealTypeWidget
