import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import MySavedSearchPropTypes from 'app/model/mySavedSearch/MySavedSearchPropTypes'
import MySavedSearchPrint from 'app/model/mySavedSearch/print/MySavedSearchPrint'

import './MySavedSearchParamsPrint.less'
import './MySavedSearchParamsPrintCS.json'

const CLASSNAME = 'c-my-saved-search-params-print'

const MySavedSearchParamsPrint = ({ className, mySavedSearch }) => {
	const localize = useLocalize()

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<div className={`${CLASSNAME}__heading`}>{localize('MySavedSearchParamsPrint.heading')}</div>
			<div className={`${CLASSNAME}__title`}>{MySavedSearchPrint.printTitle(mySavedSearch)}</div>
			<div className={`${CLASSNAME}__sentence`}>
				{MySavedSearchPrint.printSentence(localize, mySavedSearch)}
			</div>
		</div>
	)
}

MySavedSearchParamsPrint.propTypes = {
	className: PropTypes.string,
	mySavedSearch: PropTypes.shape(MySavedSearchPropTypes).isRequired
}

MySavedSearchParamsPrint.defaultProps = {
	mySavedSearch: DEFAULT_PROPS.OBJECT
}

export default React.memo(MySavedSearchParamsPrint)
