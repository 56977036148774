import FilterConstants from 'app/model/filter/FilterConstants'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import AbstractOpenerLogic from './AbstractOpenerLogic'

export default class PopupOpenerLogic extends AbstractOpenerLogic {
	constructor(props, context) {
		super(props, context)

		this._openPopup = this._openPopup.bind(this)
	}

	render() {
		const { children } = this.props

		return children({
			openPopup: this._openPopup,
			clearFilter: this._clearFilter
		})
	}

	_openPopup(value) {
		const {
			formLineEntity: { id },
			context
		} = this.props

		this.fire('openFilterPopup', {
			filterId: id,
			openedByValue: value,
			context,
			filterPopupType:
				context === FILTER_CONTEXT.EXTENDED_FILTER
					? FilterConstants.filterPopupType.BACK
					: FilterConstants.filterPopupType.DEFAULT,
			filterButtonType: FilterConstants.filterButtonType.CONTINUE,
			updateUrlParams: false
		})
	}
}
