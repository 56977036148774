import React, { useContext } from 'react'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import classnames from 'classnames'
import { useLocalize } from 'app/base/componentHelpers'
import { Button } from '@sznds/react'
import PropTypes from 'prop-types'

import './CreateAdvertButton.less'
import './CreateAdvertButtonCS.json'

const CLASSNAME = 'c-client-create-item-button'

export default function CreateAdvertBtn({ className }) {
	const localize = useLocalize()
	const context = useContext(Context)
	const router = context.$Router

	const text = (
		<>
			<span
				className={classnames({
					[`${CLASSNAME}-prefix`]: true,
					[`${className}-prefix`]: Boolean(className)
				})}
			>
				{localize('CreateAdvertButton.createNewAdvertPrefix')}
			</span>
			{localize('CreateAdvertButton.createNewAdvert')}
		</>
	)

	return (
		<Button
			primary={true}
			size='small'
			text={text}
			href={router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT)}
			className={classnames({
				[CLASSNAME]: true,
				[className]: Boolean(className)
			})}
		/>
	)
}

CreateAdvertBtn.propTypes = {
	className: PropTypes.string
}
