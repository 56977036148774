import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tooltip as TooltipComp } from '@inzeraty/components'
import { useLocalize } from 'app/base/componentHelpers'

import './TooltipCS.json'

const CLASSNAME = 'c-tooltip'

const Tooltip = (props) => {
	const localize = useLocalize()

	const { elementId, tooltipProps } = props

	const defaultAriaDescribedById = 'tooltipAriaDescribedBy'

	/*- componentDidMount -*/
	useEffect(() => {
		// - pridani atributu 'aria-describedby' k prislusnemu labelu
		if (tooltipProps) {
			const { ariaDescribedById = defaultAriaDescribedById } = tooltipProps

			if (ariaDescribedById) {
				const label = document.querySelector(`*[for="${elementId}"]`)

				if (label) {
					label.setAttribute('aria-describedby', ariaDescribedById)
				}
			}
		}
	}, [])

	/*- componentWillUnmount -*/
	useEffect(() => {
		return () => {
			// - odebrani atributu 'aria-describedby' z prislusnemu labelu
			if (tooltipProps) {
				const { ariaDescribedById = defaultAriaDescribedById } = tooltipProps

				if (ariaDescribedById) {
					const label = document.querySelector(`*[for="${elementId}"]`)

					if (label) {
						label.removeAttribute('aria-describedby', ariaDescribedById)
					}
				}
			}
		}
	}, [])

	const getTooltipProps = () => {
		if (tooltipProps) {
			const { ariaDescribedById = defaultAriaDescribedById, title } = tooltipProps

			return Object.assign(
				{
					dictionary: {
						ariaClose: localize('Tooltip.ariaClose'),
						blindClosePrefix: localize('Tooltip.blindClosePrefix'),
						ariaOpener: `${localize('Tooltip.ariaOpener')}: "${title}"`,
						ariaTitle: localize('Tooltip.ariaTitle'),
						ariaDescribe: localize('Tooltip.ariaDescribe')
					},
					ariaDescribedById
				},
				tooltipProps
			)
		} else {
			return null
		}
	}

	if (tooltipProps) {
		return (
			<span className={CLASSNAME}>
				<TooltipComp {...getTooltipProps()} />
			</span>
		)
	} else {
		return null
	}
}

Tooltip.propTypes = {
	elementId: PropTypes.string, // identifikator prvku (napr. input ve formulari), pro ktery pridavame tooltip
	tooltipProps: PropTypes.object
}

export default Tooltip
