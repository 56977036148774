import filterFormLinesForCebia from './filterFormLinesForCebia'

/**
 * Rozhodne, jestli pro vstupni formlines je mozne nacist z Cebie
 * nejaka dalsi data - tzn. jestli formularove prvky, pro ktere
 * bysme mohli nacist z Cebie, jsou jiz vyplnene nebo ne.
 *
 * @public
 * @method shouldLoadDataFromCebia
 * @param {Array<FormLineEntity>} formLinesEntities formlines formulare
 * @return {Boolean}
 */
const shouldLoadDataFromCebia = (formLineEntities) => {
	const requiredCebiaFormLineEntities = filterFormLinesForCebia(formLineEntities)

	return requiredCebiaFormLineEntities.some((formLineEntity) => {
		const { value } = formLineEntity

		return value === undefined || value === null
	})
}

export default shouldLoadDataFromCebia
