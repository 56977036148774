import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import { SDN } from 'app/base/Constants'

export default class CatalogImageResponsiveSettings {
	/**
	 * Vrati nasteveni pro responzivni obrazky nahledu
	 *
	 * @method previewSettings
	 * @return {Object}
	 */
	static get previewSettings() {
		return deepFreeze({
			src: (url) => url + SDN.w200
		})
	}

	/**
	 * Vrati nasteveni pro responzivni obrazky
	 *
	 * @method normalSettings
	 * @return {Object}
	 */
	static get normalSettings() {
		return deepFreeze({
			src: (url) => url + SDN.w320,
			sizes: `
				(min-width: 768px) 240px,
				(min-width: 511px) 100vh,
				calc(100vw - 2 * 8px)
			`,
			srcSet: (url) => `
				${url + SDN.w200} 320w,
				${url + SDN.w640_WATERMARK_CROP} 511w
			`
		})
	}

	/**
	 * Vrati nasteveni pro responzivni obrazky fullscreenu
	 *
	 * @method fullscreenSettings
	 * @return {Object}
	 */
	static get fullscreenSettings() {
		return deepFreeze({
			src: (url) => url + SDN.w1024_WATERMARK,
			sizes: '100vw',
			srcSet: (url) => `
				${url + SDN.w320_WATERMARK} 320w,
				${url + SDN.w640_WATERMARK} 640w,
				${url + SDN.w800_WATERMARK} 800w,
				${url + SDN.w1024_WATERMARK} 1024w
			`
		})
	}
}
