import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Format } from '@inzeraty/helpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { useLocalize } from 'app/base/componentHelpers'
import percentageGrowthCalc from './PercentageGrowthCalc'

import './KPIMetricTile.less'
import './KPIMetricTileCS.json'

const CLASSNAME = 'c-kpi-metric-tile'

// nahradi nezalomitelnou mezeru s obycejnou mezerou
const formatNumber = (num) => Format.number(num).replace(/\xa0/g, ' ')

const KPIMetricTile = (props) => {
	const { className, title, unitLabel, amount1, amount2 } = props

	const localize = useLocalize()

	const renderViewForOneAmount = () => {
		return (
			<div
				className={classnames({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				<div
					className={classnames({
						[`${CLASSNAME}__box`]: true,
						[`${className}__box`]: !!className,
						[`${CLASSNAME}--one-amount`]: true
					})}
				>
					<div className={`${CLASSNAME}__title`}>{title}</div>
					<div className={`${CLASSNAME}__amount-wrapper`}>
						<span className={`${CLASSNAME}__amount`}>{formatNumber(amount1)}</span>
						<span className={`${CLASSNAME}__unit`}>{unitLabel}</span>
					</div>
				</div>
			</div>
		)
	}

	const renderViewForTwoAmounts = () => {
		const percentage = percentageGrowthCalc(amount2, amount1)

		const formatPercentage = (percentage) => {
			if (percentage >= 0) {
				return `+${formatNumber(percentage)}%`
			} else if (percentage < 0) {
				return `${formatNumber(percentage)}%`
			}
		}

		return (
			<div
				className={classnames({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				<div
					className={classnames({
						[`${CLASSNAME}__box`]: true,
						[`${className}__box`]: !!className,
						[`${CLASSNAME}--two-amounts`]: true
					})}
				>
					<div className={`${CLASSNAME}__title`}>{title}</div>
					<div
						className={classnames({
							[`${CLASSNAME}__percentage`]: true,
							[`${CLASSNAME}__percentage--negative`]: percentage < 0
						})}
					>
						{formatPercentage(percentage)}
					</div>
					<div className={`${CLASSNAME}__amount-wrapper`}>
						<span className={`${CLASSNAME}__label`}>{localize('KPIMetricTile.timeSpan1')}:</span>
						<span>{formatNumber(amount1)}</span>
					</div>
					<div className={`${CLASSNAME}__amount-wrapper`}>
						<span className={`${CLASSNAME}__label`}>{localize('KPIMetricTile.timeSpan2')}:</span>
						<span>{formatNumber(amount2)}</span>
					</div>
				</div>
			</div>
		)
	}

	return !isNullOrUndefined(amount2) ? renderViewForTwoAmounts() : renderViewForOneAmount()
}

KPIMetricTile.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	unitLabel: PropTypes.string.isRequired,
	amount1: PropTypes.number.isRequired,
	amount2: PropTypes.number
}

export default React.memo(KPIMetricTile)
