/**
 * Konstanty potrebne pro konfiguraci IndexedDB
 *
 * NAME: nazev tabulky
 * KEY_PATH: primarni klic
 * INDEXES: indexy
 */
const DEFAULT_OBJECT_STORES = Object.freeze({
	// standardni detail inzeratu
	ADVERT_DETAIL: {
		NAME: 'advertDetail',
		KEY_PATH: 'idKey'
	},
	// detail inzeratu na operativni leasing
	ADVERT_DETAIL_WITH_OPERATING_LEASE: {
		NAME: 'advertDetailWithOperatingLease',
		KEY_PATH: 'idKey'
	},
	// standardni vypis inzeratu
	ADVERT_LIST: {
		NAME: 'advertList',
		KEY_PATH: 'listKey',
		INDEXES: ['id', 'oldId', 'offset']
	},
	PAGINATION: {
		NAME: 'pagination',
		KEY_PATH: 'timestamp_to'
	},
	// vypis inzeratu na operativni leasing
	ADVERT_LIST_WITH_OPERATING_LEASES: {
		NAME: 'advertListWithOperatingLeases',
		KEY_PATH: 'listKey',
		INDEXES: ['id', 'oldId', 'offset']
	},
	PAGINATION_WITH_OPERATING_LEASES: {
		NAME: 'paginationWithOperatingLeases',
		KEY_PATH: 'timestamp_to'
	},
	// standardni vypis inzeratu na strance prodejce
	SELLER_LIST: {
		NAME: 'sellerList',
		KEY_PATH: 'sellerKey',
		INDEXES: ['id', 'oldId', 'offset']
	},
	SELLER_PAGINATION: {
		NAME: 'sellerPagination',
		KEY_PATH: 'premise_id'
	},
	// vypis inzeratu na operativni leasing na strance prodejce
	SELLER_LIST_WITH_OPERATING_LEASES: {
		NAME: 'sellerListWithOperatingLeases',
		KEY_PATH: 'sellerKey',
		INDEXES: ['id', 'oldId', 'offset']
	},
	SELLER_PAGINATION_WITH_OPERATING_LEASES: {
		NAME: 'sellerPaginationWithOperatingLeases',
		KEY_PATH: 'premise_id'
	}
})

const HOMEPAGE_OBJECT_STORE = Object.freeze({
	HOMEPAGE_LIST: {
		NAME: 'homepageList',
		KEY_PATH: 'listKey',
		INDEXES: ['id', 'oldId', 'vehicleBodyName']
	},
	HOMEPAGE_PAGINATION: {
		NAME: 'homepagePagination',
		KEY_PATH: 'vehicleBodyName'
	}
})

export { DEFAULT_OBJECT_STORES as default, HOMEPAGE_OBJECT_STORE }
