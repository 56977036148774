import DateRangePicker from 'app/page/userweb/clientStatistics/components/dateRangePicker/DateRangePicker'
import React, { useEffect, useState } from 'react'
import ClientStatisticsUrlConvertor from 'app/page/userweb/clientStatistics/ClientStatisticsUrlConvertor'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import PropTypes from 'prop-types'
import { Icon } from '@sznds/react'
import { TRASH_FILLED_16 } from '@sznds/icons'
import classnames from 'classnames'
import { Format, DateHelpers } from '@inzeraty/helpers'

import './DatePickerGroup.less'
import './DatePickerGroupCS.json'

const CLASSNAME = 'c-stats-date-picker-group'

const getPreviousDateRange = ({ valueFrom, valueTo }) => {
	const { URL_APP_PARAMS } = ClientStatisticsUrlConvertor.constants

	const dateFrom = DateHelpers.getDateObject(valueFrom)
	const dateTo = DateHelpers.getDateObject(valueTo)

	const diffTime = Math.abs(dateFrom - dateTo)
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

	const previousTo = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate() - 1)
	const previousFrom = new Date(
		previousTo.getFullYear(),
		previousTo.getMonth(),
		previousTo.getDate() - diffDays
	)

	return {
		[URL_APP_PARAMS.TIME_SPAN_2_FROM]: Format.dateEN(previousFrom, true),
		[URL_APP_PARAMS.TIME_SPAN_2_TO]: Format.dateEN(previousTo, true)
	}
}

const DatePickerGroup = ({ firstPeriodDates, secondPeriodDates }) => {
	const [showSecondPeriod, setShowSecondPeriod] = useState(
		secondPeriodDates.valueFrom || secondPeriodDates.valueTo
	)
	const { URL_APP_PARAMS } = ClientStatisticsUrlConvertor.constants

	useEffect(() => {
		setShowSecondPeriod(secondPeriodDates.valueFrom || secondPeriodDates.valueTo)
	}, [secondPeriodDates.valueFrom, secondPeriodDates.valueTo])

	const localize = useLocalize()
	const fire = useFire()

	return (
		<form className={CLASSNAME}>
			<div className={`${CLASSNAME}__input-row`}>
				{showSecondPeriod && (
					<span className={`${CLASSNAME}__date-label`}>{localize('DatePickerGroup.period1')}</span>
				)}
				<div className={`${CLASSNAME}__input-wrapper`}>
					<DateRangePicker
						nameFrom={URL_APP_PARAMS.TIME_SPAN_1_FROM}
						nameTo={URL_APP_PARAMS.TIME_SPAN_1_TO}
						className={classnames(
							`${CLASSNAME}__input-group`,
							showSecondPeriod && `${CLASSNAME}__input-group--with-icon`
						)}
						{...firstPeriodDates}
					/>
				</div>
			</div>
			{showSecondPeriod ? (
				<div className={`${CLASSNAME}__input-row`}>
					<span className={`${CLASSNAME}__date-label`}>{localize('DatePickerGroup.period2')}</span>
					<div className={`${CLASSNAME}__input-wrapper`}>
						<DateRangePicker
							nameFrom={URL_APP_PARAMS.TIME_SPAN_2_FROM}
							nameTo={URL_APP_PARAMS.TIME_SPAN_2_TO}
							className={classnames(
								`${CLASSNAME}__input-group`,
								showSecondPeriod && `${CLASSNAME}__input-group--with-icon`
							)}
							{...secondPeriodDates}
						/>
						<button
							onClick={() => {
								fire('removeSecondPeriod')
								setShowSecondPeriod(false)
							}}
							type='button'
							className={`${CLASSNAME}__action-icon`}
						>
							<span className={`${CLASSNAME}__accessible-label`}>
								{localize('DatePickerGroup.removeSecondPeriod')}
							</span>
							<Icon symbol={TRASH_FILLED_16} className={`${CLASSNAME}__icon`} />
						</button>
					</div>
				</div>
			) : (
				<button
					onClick={() => {
						fire('dateRangeChange', getPreviousDateRange(firstPeriodDates))
						setShowSecondPeriod(true)
					}}
					type='button'
					className={`${CLASSNAME}__action-button`}
				>
					{localize('DatePickerGroup.addSecondPeriod')}
				</button>
			)}
		</form>
	)
}

DatePickerGroup.propTypes = {
	firstPeriodDates: PropTypes.shape({
		valueFrom: PropTypes.string,
		valueTo: PropTypes.string
	}),
	secondPeriodDates: PropTypes.shape({
		valueFrom: PropTypes.string,
		valueTo: PropTypes.string
	})
}

export default DatePickerGroup
