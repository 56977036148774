
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdminMenu'] = {
  adverts: function(d) { return "Inzeráty"; },
  profile: function(d) { return "Profil"; },
  statistics: function(d) { return "Statistiky"; },
  closeNavigation: function(d) { return "Zavřít navigační panel"; },
  openNavigation: function(d) { return "Otevřít navigační panel"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	