import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import FiltersExtension from 'app/component/filters/FiltersExtension'

class RadioFilterLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			context: PropTypes.string.isRequired,
			scopedContext: PropTypes.bool,
			isLastOptionCustomInput: PropTypes.bool,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			filterUrlParams: props.filterUrlParams,

			filteredAdvertsCountTotal: props.filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal: props.isLoadingFilteredAdvertsCountTotal,

			// pro cisilka u presetu (par nejcastejsich polozek)
			filteredAdvertsCount: {},
			isLoadingFilteredAdvertsCount: false,

			// pro cisilka v dropdownu pro input 'od'
			filteredAdvertsCountFromInput: {},
			isLoadingFilteredAdvertsCountFromInput: false,

			// pro cisilka v dropdownu pro input 'do'
			filteredAdvertsCountToInput: {},
			isLoadingFilteredAdvertsCountToInput: false,

			beenFilterChanged: false
		}

		this._changeFilter = this._changeFilter.bind(this)

		this._validateForm = this._validateForm.bind(this)

		this._loadFilteredAdvertsCount = this._loadFilteredAdvertsCount.bind(this)
		this._loadFilteredAdvertsCountForFromInput = this._loadFilteredAdvertsCountForFromInput.bind(
			this
		)
		this._loadFilteredAdvertsCountForToInput = this._loadFilteredAdvertsCountForToInput.bind(this)
		this._calcFilteredAdvertsCountTotal = this._calcFilteredAdvertsCountTotal.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			filterUrlParams,
			formLineEntity,
			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal
		} = this.props
		const {
			filterUrlParams: prevFilterUrlParams,
			filteredAdvertsCountTotal: prevFilteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal: prevIsLoadingFilteredAdvertsCountTotal
		} = prevProps

		const { filteredAdvertsCount } = this.state
		const { filteredAdvertsCount: prevFilteredAdvertsCount } = prevState

		if (filterUrlParams !== prevFilterUrlParams) {
			this.setState({
				filterUrlParams
			})
		}

		if (filteredAdvertsCountTotal !== prevFilteredAdvertsCountTotal) {
			this.setState({
				filteredAdvertsCountTotal
			})
		}

		if (isLoadingFilteredAdvertsCountTotal !== prevIsLoadingFilteredAdvertsCountTotal) {
			this.setState({
				isLoadingFilteredAdvertsCountTotal
			})
		}

		if (filteredAdvertsCount !== prevFilteredAdvertsCount) {
			this._calcFilteredAdvertsCountTotal(formLineEntity)
		}
	}

	componentWillUnmount() {
		const {
			setFilteredAdvertsCountTotal: globalSetFilteredAdvertsCountTotal,
			scopedContext
		} = this.props
		const { filteredAdvertsCountTotal } = this.state

		if (scopedContext) {
			globalSetFilteredAdvertsCountTotal(filteredAdvertsCountTotal)
		}
	}

	render() {
		const { children, formLineEntity, categoryEntity } = this.props
		const {
			filteredAdvertsCount,
			isLoadingFilteredAdvertsCount,
			filterUrlParams,
			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal,
			beenFilterChanged,

			filteredAdvertsCountFromInput,
			isLoadingFilteredAdvertsCountFromInput,
			filteredAdvertsCountToInput,
			isLoadingFilteredAdvertsCountToInput
		} = this.state

		return children({
			form: {
				validateForm: this._validateForm,
				changeFilter: this._changeFilter,
				beenFilterChanged
			},
			advertsCount: {
				filteredAdvertsCountTotal,
				isLoadingFilteredAdvertsCountTotal,
				calcFilteredAdvertsCountTotal: this._calcFilteredAdvertsCountTotal,

				filteredAdvertsCount,
				isLoadingFilteredAdvertsCount,
				loadFilteredAdvertsCount: this._loadFilteredAdvertsCount,

				filteredAdvertsCountFromInput,
				isLoadingFilteredAdvertsCountFromInput,
				loadFilteredAdvertsCountFromInput: this._loadFilteredAdvertsCountForFromInput,

				filteredAdvertsCountToInput,
				isLoadingFilteredAdvertsCountToInput,
				loadFilteredAdvertsCountToInput: this._loadFilteredAdvertsCountForToInput
			},
			other: {
				categoryEntity,
				formLineEntity,
				filterUrlParams
			}
		})
	}

	_changeFilter(id, value, callback = () => {}) {
		const { changeFilter, isLastOptionCustomInput, getFilterUrlParams } = this.props

		this.setState({
			beenFilterChanged: true
		})

		changeFilter(id, value, (newFormLineEntity) => {
			const { value: valueData } = newFormLineEntity
			const { filteredAdvertsCount } = this.state
			const lastOption = this._getLastOption(newFormLineEntity)
			const isLastOptionWithCustomInputSelected =
				isLastOptionCustomInput && lastOption.value === valueData.value

			if (isLastOptionWithCustomInputSelected) {
				const { success, countForLastOption } = this._tryCalcFilteredAdvertsCountForLastOption(
					newFormLineEntity
				)

				if (success) {
					const newFilteredAdvertsCount = Object.assign({}, filteredAdvertsCount, {
						[lastOption.value]: countForLastOption
					})

					this.setState(
						{
							filteredAdvertsCount: newFilteredAdvertsCount,
							filterUrlParams: getFilterUrlParams(newFormLineEntity)
						},
						() => {
							this._calcFilteredAdvertsCountTotal(newFormLineEntity)
						}
					)
				} else {
					const urlParams = getFilterUrlParams(newFormLineEntity)
					this._loadFilteredAdvertsCount(urlParams)

					this.setState({
						filterUrlParams: urlParams
					})
				}
			} else {
				this.setState(
					{
						filterUrlParams: getFilterUrlParams(newFormLineEntity)
					},
					() => {
						this._calcFilteredAdvertsCountTotal(newFormLineEntity)
					}
				)
			}

			callback(newFormLineEntity)
		})
	}

	_getLastOption(formLineEntity) {
		const { options } = formLineEntity
		return options[options.length - 1] || {}
	}

	_tryGetMatchedPresetOption(formLineEntity) {
		const { options, value = {} } = formLineEntity
		const { valueFrom: actualValueFrom = 0, valueTo: actualValueTo } = value

		return options
			.filter((o) => o.valueFrom > 0 || o.valueTo > 0)
			.find(
				({ valueFrom = 0, valueTo }) => valueFrom === actualValueFrom && valueTo === actualValueTo
			)
	}

	_tryCalcFilteredAdvertsCountForLastOption(formLineEntity) {
		const { filteredAdvertsCount } = this.state
		const match = this._tryGetMatchedPresetOption(formLineEntity)
		const isFormValid = this._validateForm(formLineEntity)

		if (isFormValid) {
			if (match) {
				return {
					success: true,
					countForLastOption: filteredAdvertsCount[match.value]
				}
			} else {
				return {
					success: false // nelze vypocitat, musime docist data ze serveru
				}
			}
		} else {
			return {
				success: true,
				countForLastOption: 0
			}
		}
	}

	_validateForm(formLineEntity) {
		const {
			value: { valueFrom, valueTo }
		} = formLineEntity || this.props.formLineEntity

		if (valueFrom >= 0 && valueTo >= 0) return valueFrom <= valueTo
		else return true
	}

	_loadFilteredAdvertsCountHelper({
		filterName,
		customUrlParams,
		stateToUpdateBefore = {},
		successCallback
	} = {}) {
		const { loadFilteredAdvertsCount } = this.props

		this.setState(stateToUpdateBefore, async () => {
			try {
				const { counts } = await loadFilteredAdvertsCount(filterName, customUrlParams)

				successCallback && successCallback(counts)
			} catch (e) {
				// na chybu nebudeme nijak reagovat
			}
		})
	}

	_loadFilteredAdvertsCount(customUrlParams) {
		const {
			formLineEntity: { id }
		} = this.props

		const stateToUpdateBefore = {
			isLoadingFilteredAdvertsCount: true
		}

		this._loadFilteredAdvertsCountHelper({
			filterName: id,
			customUrlParams,
			stateToUpdateBefore,
			successCallback: (counts) =>
				this.setState({
					filteredAdvertsCount: counts,
					isLoadingFilteredAdvertsCount: false
				})
		})
	}

	_loadFilteredAdvertsCountForFromInput(customUrlParams) {
		const {
			formLineEntity: { id }
		} = this.props

		const stateToUpdateBefore = {
			isLoadingFilteredAdvertsCountFromInput: true
		}

		this._loadFilteredAdvertsCountHelper({
			filterName: `${id}_from`,
			customUrlParams,
			stateToUpdateBefore,
			successCallback: (counts) =>
				this.setState({
					filteredAdvertsCountFromInput: counts,
					isLoadingFilteredAdvertsCountFromInput: false
				})
		})
	}

	_loadFilteredAdvertsCountForToInput(customUrlParams) {
		const {
			formLineEntity: { id }
		} = this.props

		const stateToUpdateBefore = {
			isLoadingFilteredAdvertsCountToInput: true
		}

		this._loadFilteredAdvertsCountHelper({
			filterName: `${id}_to`,
			customUrlParams,
			stateToUpdateBefore,
			successCallback: (counts) =>
				this.setState({
					filteredAdvertsCountToInput: counts,
					isLoadingFilteredAdvertsCountToInput: false
				})
		})
	}

	async _calcFilteredAdvertsCountTotal(newFormLineEntity) {
		const {
			loadFilteredAdvertsCountTotal,
			setFilteredAdvertsCountTotal: globalSetFilteredAdvertsCountTotal,
			categoryEntity,
			getFilterUrlParams,
			scopedContext
		} = this.props

		const setFilteredAdvertsCountTotal = (total) => {
			if (scopedContext) {
				this.setState({
					filteredAdvertsCountTotal: total
				})
			} else {
				globalSetFilteredAdvertsCountTotal(total)
			}
		}

		if (this._validateForm(newFormLineEntity)) {
			const urlParams = getFilterUrlParams(newFormLineEntity)
			const total = await loadFilteredAdvertsCountTotal(urlParams, categoryEntity)
			setFilteredAdvertsCountTotal(total)
		} else {
			setFilteredAdvertsCountTotal(0)
		}
	}
}

//TODO pridat shouldComponentUpdate, at se nam porad tato komponenta zbytecne neprekresluje
const selectors = (state) => ({
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS],
	loadFilteredAdvertsCount: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT],
	loadFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT_TOTAL],
	setFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.SET_FILTERED_ADVERTS_COUNT_TOTAL],
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	filterUrlParams: state[FiltersExtension.stateIds.URL_PARAMS],
	getFilterUrlParams: state[FiltersExtension.stateIds.GET_URL_PARAMS],
	redirect: state[FiltersExtension.stateIds.REDIRECT],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL],
	isLoadingFilteredAdvertsCountTotal:
		state[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(selectors)(RadioFilterLogic)
