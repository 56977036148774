import { AbstractFactory } from '@inzeraty/models'
import CarCompareEntity from './CarCompareEntity'

export default class CarCompareFactory extends AbstractFactory {
	/**
	 * @constructor
	 */
	constructor() {
		super(CarCompareEntity)
	}

	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformData(data) {
		return {
			result: data.compare_result,
			price: data.price,
			priceLevels: data.price_levels ? this._mapPriceLevels(data.price_levels) : undefined,
			totalMatches: data.total_matches,
			filters: data.filters ? this._mapFilters(data.filters) : undefined
		}
	}

	_mapPriceLevels(levels = []) {
		return levels.map((level) => {
			return {
				priceFrom: level.price_from,
				priceTo: level.price_to,
				priceType: level.price_type
			}
		})
	}

	_mapFilters(filter) {
		return {
			category: filter.category,
			condition: filter.condition,
			enginePowerFrom: filter.engine_power_from,
			enginePowerTo: filter.engine_power_to,
			model: filter.model,
			tachometerFrom: filter.tachometer_from,
			tachometerTo: filter.tachometer_to,
			vehicleAgeFrom: filter.vehicle_age_from,
			vehicleAgeTo: filter.vehicle_age_to,
			vehicleBody: filter.vehicle_body
		}
	}
}
