import cebiaToFormLines from './cebiaToFormLines'
import filterFormLinesForCebia from './filterFormLinesForCebia'

const processDataFromCebia = (cebiaData, formLineEntities) => {
	const cebiaFormLineEntities = filterFormLinesForCebia(formLineEntities)

	const processedAllData = cebiaFormLineEntities
		.map((formLineEntity) => {
			const { id, value } = formLineEntity

			// musime otestovat, jestli pro danou formline jiz neni nejaka hodnota
			// zadana, pokud ano, tak nesmime tuto hodnotu prepsat s daty z Cebie
			if (!value) {
				const { processData = () => undefined } =
					cebiaToFormLines.find((cebiaToFormLine) => cebiaToFormLine.formLineId === id) || {}

				const res = processData(formLineEntities, cebiaData)

				if (res) {
					return {
						id,
						value: res
					}
				} else {
					return {
						id,
						error: true
					}
				}
			}

			return undefined
		})
		.filter((data) => !!data)

	return {
		// uspesne zpracovana data z Cebie
		processedData: processedAllData.filter((data) => !data.error),

		// id formline, pro ktere se nepovedlo nacist data z Cebie
		failedFormLineIds: processedAllData.filter((data) => data.error).map((data) => data.id)
	}
}

export default processDataFromCebia
