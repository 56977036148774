
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PreviewMessage'] = {
  description: function(d) { return "Toto je pouze náhled vašeho inzerátu"; },
  "return": function(d) { return "Odejít z náhledu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	