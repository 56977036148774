export default class AdvertStatisticsService {
	constructor(advertStatisticsResource, advertStatisticsFactory, paginationFactory) {
		this._advertStatisticsResource = advertStatisticsResource
		this._advertStatisticsFactory = advertStatisticsFactory

		this._paginationFactory = paginationFactory
	}

	/**
	 * Vraci vsechny statistiky.
	 *
	 * @method getStatistics
	 * @public
	 * @param {Object}	params
	 * @param {Object}	options
	 * @return {Array<AdvertStatisticsEntity>}
	 */
	async getStatistics(params, options = {}) {
		const response = await this._advertStatisticsResource.getStatistics(params, options)

		const { results = [], pagination = {} } = response.body
		const statistics = results.map((stat) => this._advertStatisticsFactory.transformData(stat))

		return {
			statisticsEntities: this._advertStatisticsFactory.createEntityList(statistics),
			paginationEntity: this._paginationFactory.createEntity(pagination)
		}
	}

	/**
	 * Ziska dilci statistiky pro jednotlive inzeraty na vypise inzeratu v KA.
	 *
	 * @method getStatisticsForListItems
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<Object>} Asociativni pole, kde klicem jsou idecka inzeratu
	 **/
	async getStatisticsForListItems(params = {}, options = {}) {
		const response = await this._advertStatisticsResource.getStatisticsForListItems(params, options)

		const { results = {} } = response.body

		return this._advertStatisticsFactory.transformDataForListItems(results)
	}

	exportStatistics(params = {}, options = {}) {
		return this._advertStatisticsResource.exportStatistics(params, options)
	}
}
