
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AutoComplete'] = {
  close: function(d) { return "zavřít"; },
  closeMenu: function(d) { return "zavřít nabídku"; },
  openMenu: function(d) { return "otevřít nabídku"; },
  clearSelection: function(d) { return "smazat"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	