import { UserService } from '@inzeraty/models'
import ClientBaseController from 'app/base/ClientBaseController'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import { CATEGORIES } from 'app/base/Constants'
import STEPS_STATE_KEYS from './component/stepper/StepperStateKeys'
import { loadDataForSteps, getNextStepUrl } from './component/stepper/StepperUtils'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from './AbstractNewAdvertStateKeys'
import UserwebBaseController from 'app/base/UserwebBaseController'
import getAdvertState, {
	ADVERT_STATE,
	UNFINISHED_STATES
} from 'app/page/userweb/clientAdvertList/getAdvertState'
import ClientAdvertListUrlConvertor from 'app/page/userweb/clientAdvertList/ClientAdvertListUrlConvertor'
import { STATUS_CONSTANTS } from 'app/model/advert/AdvertConstants'
import { DEACTIVATION_REASON } from 'app/model/advert/AdvertConstants'
import ToastHelper from 'app/component/toastMessages/ToastHelper'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './AbstractNewAdvertCS.json'
import 'app/base/ActionMessageCS.json'

const isPremise = (userEntity = {}) => {
	const { premises = [] } = userEntity
	return Boolean(premises.length)
}

/**
 * @class AbstractNewAdvertBaseController
 * @namespace app.base
 * @extends app.base.ClientBaseController
 * @module app
 * @submodule app.base
 */
export default class AbstractNewAdvertBaseController extends ClientBaseController {
	/**
	 * @constructor
	 * @method constructor
	 * @param {ns.app.helpers.dependenciesHelper.DependenciesHelper} dependenciesHelper
	 */
	constructor(dependenciesHelper) {
		super(dependenciesHelper)

		this._router = this._utils.$Router
		this._dictionary = this._utils.$Dictionary

		this._advertService = this._dependenciesHelper.getDependency('advertService')
		this._historyStorage = this._dependenciesHelper.getDependency('historyStorage')

		this._getSteps = this._getSteps.bind(this)

		this._isPremiseLastStep = false

		this._onFormSubmitSuccessRedirectUrl = this._getNextStepUrl()

		this._onSubmitFormSuccessCallback = DEFAULT_PROPS.FUNCTION
		this._onSubmitFormErrorCallback = DEFAULT_PROPS.FUNCTION

		this._handlePremisePayment = this._handlePremisePayment.bind(this)
	}

	load(state = {}) {
		const superState = super.load(state)

		const { [UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfPromise } = superState

		const advertId = this._getAdvertId()

		const advertEntityPromise = this._getAdvertEntityPromise()

		const steps = userSelfPromise.then((userSelf) => {
			return loadDataForSteps(
				advertId,
				advertEntityPromise,
				this._advertService,
				(categoryId, advertState) => this._getSteps(categoryId, advertState, userSelf)
			)
		})

		const allPromises = Promise.all([advertEntityPromise, userSelfPromise])

		const canBeAdvertSeenPromise = allPromises.then(([advertEntity, userEntity]) => ({
			canBeAdvertSeen: this._canBeAdvertSeenByUser(advertEntity, userEntity),
			advertEntity,
			userEntity
		}))

		return Object.assign(
			{
				[STEPS_STATE_KEYS.STEPS]: steps,
				[STEPS_STATE_KEYS.IS_EDIT]: allPromises.then(([advertEntity, userEntity]) => {
					const isEditAdvertState = !UNFINISHED_STATES.includes(
						getAdvertState(advertEntity, isPremise(userEntity))
					)
					return this._getIsComeToEditAdvert(isEditAdvertState)
				}),
				[STEPS_STATE_KEYS.ADVERT_STATE]: advertEntityPromise.then(
					(advertEntity) => advertEntity.status
				),
				[ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: canBeAdvertSeenPromise.then(
					({ canBeAdvertSeen, advertEntity }) => (canBeAdvertSeen ? advertEntity : undefined)
				),
				[ABSTRACT_NEW_ADVERT_STATE_KEYS.HIDDEN_ADVERT_ENTITY]: canBeAdvertSeenPromise.then(
					({ advertEntity }) => advertEntity
				),
				[ABSTRACT_NEW_ADVERT_STATE_KEYS.HTTP_STATUS]: canBeAdvertSeenPromise.then(
					({ canBeAdvertSeen, userEntity }) => {
						if (!canBeAdvertSeen) {
							// prenastavime defaultni http status kod
							this.status = UserService.isUserLogged(userEntity)
								? HTTP_STATUS_CODE.FORBIDDEN
								: HTTP_STATUS_CODE.UNAUTHORIZED
						}

						return this.status
					}
				)
			},
			superState
		)
	}

	_canBeAdvertSeenByUser(advertEntity = {}, userEntity = {}) {
		const isAdvertCreatedBySameUser = (advertEntity, userEntity) => {
			const { user: { id: advertCreatedByUserId } = {} } = advertEntity
			const { id: loggedUserId } = userEntity

			return loggedUserId === advertCreatedByUserId
		}

		const isAdvertCreatedBySamePremise = (advertEntity, userEntity) => {
			const { premise: { id: advertCreatedByPremiseId } = {} } = advertEntity
			const { premises: userPremises = [] } = userEntity

			return !!userPremises.find(
				({ id: userPremiseId }) => userPremiseId === advertCreatedByPremiseId
			)
		}

		if (isPremise(userEntity)) {
			const { status } = advertEntity

			if (status === STATUS_CONSTANTS.DRAFT) {
				// mezi krokem pro zadani VINu a krokem Zakladni udaje je inzerat ve stavu,
				// kdy neni ani u bazarnika vyplnena premise. Musime porovnavat na
				// zaklade vyplneneho uzivatele.
				return isAdvertCreatedBySameUser(advertEntity, userEntity)
			} else {
				return isAdvertCreatedBySamePremise(advertEntity, userEntity)
			}
		} else {
			return isAdvertCreatedBySameUser(advertEntity, userEntity)
		}
	}

	_getIsComeToEditAdvert(isEditAdvertState = false) {
		const newAdvertRouteName = ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT

		const advertEditRouteNames = [
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PHOTOS,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_HISTORY,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_EQUIPMENT,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT_CONFIRMATION
		]

		// - zda jde o editaci zjistim primarne podle toho odkud jsem prisel pokud mam dostupnou historii
		if (this._historyStorage.getPreviousRouteName()) {
			let prevEditAdvertRouteName = this._historyStorage.getPreviousRouteName()
			let prevIndex = this._historyStorage.getActualIndex() - 1

			// - dohledam stranku ze ktere jsem prisel na editaci/vkladani inzeratu
			while (advertEditRouteNames.includes(prevEditAdvertRouteName)) {
				prevIndex--
				prevEditAdvertRouteName = this._historyStorage.getIndexRouteName(prevIndex)
			}

			return newAdvertRouteName !== prevEditAdvertRouteName
		} else {
			// - prop. ze stavu inzeratu
			// - pokud jsem refreshnul v prubehu editace - nelze zarucit ve vsech krocich editace spravne chovani
			return isEditAdvertState
		}
	}

	_getGoBackToListRouteData() {
		const newAdvertRoutes = [
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PHOTOS,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_HISTORY,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_EQUIPMENT,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT,
			ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT_CONFIRMATION
		]

		let prevRouteParams = {}

		if (this._historyStorage.getPreviousRouteName()) {
			let prevNewAdvertRouteName = this._historyStorage.getPreviousRouteName()
			let prevIndex = this._historyStorage.getActualIndex() - 1

			// - dohledam stranku ze ktere jsem prisel na vkladani
			while (newAdvertRoutes.includes(prevNewAdvertRouteName)) {
				prevIndex--
				prevNewAdvertRouteName = this._historyStorage.getIndexRouteName(prevIndex)
			}

			// - pokud predchozi stranka je "moje inzeraty", tak si ulozim parametry stranky
			if (prevNewAdvertRouteName === ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST) {
				prevRouteParams = this._historyStorage.getIndexRouteParams(prevIndex)
			}
		}

		return {
			routeName: ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST,
			routeParams: prevRouteParams
		}
	}

	_getAdvertEntityPromise() {
		const advertId = this._getAdvertId()
		return this._advertService.getAdvertDetail(
			Number(advertId),
			{ for_editing: 1 },
			{ cache: false }
		)
	}

	_getSteps(categoryId, advertState, userEntity) {
		const advertId = this._getAdvertId()

		const { route } = this._router.getCurrentRouteInfo()
		const currentRouteName = route.getName()

		const isUserPremise = isPremise(userEntity)

		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants

		const steps = [
			{
				routeName: ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO,
				url: this._router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO, {
					[URL_APP_PARAMS.ADVERT_ID]: advertId
				}),
				text: this._dictionary.get('AbstractNewAdvert.basicInfo'),
				text4: this._dictionary.get('AbstractNewAdvert.basicInfo4'),
				actual: currentRouteName === ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO
			},
			{
				routeName: ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PHOTOS,
				url: this._router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PHOTOS, {
					[URL_APP_PARAMS.ADVERT_ID]: advertId
				}),
				text: this._dictionary.get('AbstractNewAdvert.photos'),
				text4: this._dictionary.get('AbstractNewAdvert.photos4'),
				actual: currentRouteName === ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PHOTOS
			},
			{
				routeName: ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_HISTORY,
				url: this._router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_HISTORY, {
					[URL_APP_PARAMS.ADVERT_ID]: advertId
				}),
				text: this._dictionary.get('AbstractNewAdvert.history'),
				text4: this._dictionary.get('AbstractNewAdvert.history4'),
				actual: currentRouteName === ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_HISTORY
			},
			{
				routeName: ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_EQUIPMENT,
				url: this._router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_EQUIPMENT, {
					[URL_APP_PARAMS.ADVERT_ID]: advertId
				}),
				text: this._dictionary.get('AbstractNewAdvert.equipment'),
				text4: this._dictionary.get('AbstractNewAdvert.equipment4'),
				actual: currentRouteName === ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_EQUIPMENT,
				skip: categoryId === CATEGORIES.WORKING_MACHINES.id // kategorie 'Pracovni stroje' nema vybavu
			},
			{
				routeName: ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT,
				url: this._router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, {
					[URL_APP_PARAMS.ADVERT_ID]: advertId
				}),
				text: this._dictionary.get('AbstractNewAdvert.payment'),
				text4: this._dictionary.get('AbstractNewAdvert.payment4'),
				actual: currentRouteName === ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT,
				skip:
					isUserPremise ||
					[ADVERT_STATE.DISABLED, ADVERT_STATE.DISABLED_REJECTED].includes(advertState)
			}
		]

		const renderedSteps = steps.filter((step) => !step.skip)
		this._isPremiseLastStep =
			isUserPremise && renderedSteps.findIndex((step) => step.actual) === renderedSteps.length - 1

		return steps
	}

	async onGotoBackToList() {
		await this.onSubmitAndClose()
		this._onSubmitFormSuccessCallback()
	}

	async onSubmitAndClose() {
		const { [STEPS_STATE_KEYS.IS_EDIT]: isEdit } = this.getState()

		const isAdvertEdit = await isEdit

		this._onSubmitFormSuccessCallback = () => {
			if (this._isPremiseLastStep && !isAdvertEdit) {
				this._handlePremisePayment()
			} else {
				const messageData = {
					type: StatusMessage.TYPE.SUCCESS,
					title: this._dictionary.get('ActionMessage.titleSuccess'),
					text: this._dictionary.get('ActionMessage.saveSuccess', {
						IDS: this._getAdvertId()
					})
				}

				ToastHelper.setToast(messageData)

				this._redirectToAdvertList()
			}
		}

		this._onSubmitFormErrorCallback = () => {
			this.onSaveError()

			this._onSubmitFormErrorCallback = DEFAULT_PROPS.FUNCTION
		}
	}

	onSaveError() {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			type: StatusMessage.TYPE.ERROR,
			title: this._dictionary.get('ActionMessage.titleError'),
			text: this._dictionary.get('ActionMessage.saveError', {
				IDS: this._getAdvertId()
			})
		})
	}

	onSubmitAndContinue() {
		this._onSubmitFormSuccessCallback = () => {
			this._router.redirect(this._getNextStepUrl())
		}
	}

	_getNextStepUrl() {
		const { [STEPS_STATE_KEYS.STEPS]: steps } = this.getState()

		return getNextStepUrl(steps)
	}

	_getAdvertId() {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const { [URL_APP_PARAMS.ADVERT_ID]: advertId } = this.getRouteParams()

		return advertId
	}

	async _getIsAdvertActive() {
		const { [ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise } = this.getState()

		const { status } = await advertEntityPromise

		return status === STATUS_CONSTANTS.ACTIVE
	}

	_redirectToAdvertList(urlParams = {}) {
		const { routeName, routeParams } = this._getGoBackToListRouteData()

		this._router.redirect(this._router.link(routeName, Object.assign(routeParams, urlParams)))
	}

	_handlePremisePayment() {
		const { URL_API_PARAMS } = ClientAdvertListUrlConvertor.constants
		const advertId = this._getAdvertId()

		this._advertService
			.updateAdvertStatus({
				[URL_API_PARAMS.STATUS.ACTIVE]: true,
				[URL_API_PARAMS.ADVERT_IDS]: [advertId]
			})
			.then((data) => {
				this._handleAdvertActivation(data)
			})
			.catch((err) => {
				const messageData = {
					type: StatusMessage.TYPE.ERROR,
					title: this._dictionary.get('ActionMessage.titleError'),
					text: this._dictionary.get('ActionMessage.publishError', {
						COUNT: 1,
						IDS: advertId
					})
				}

				ToastHelper.setToast(messageData)

				this._redirectToAdvertList()
			})
	}

	_handleAdvertActivation(statusSummary) {
		const advertsWithError = statusSummary.filter(({ errors = [] }) => {
			const [error = ''] = errors
			return error === DEACTIVATION_REASON.MODULES || error === DEACTIVATION_REASON.IMAGES
		})

		let messageData
		const errorCount = advertsWithError.length

		if (errorCount) {
			// pri chybe pre nedostatok fotiek nevytvarame zvlast toast,
			// pretoze uzivatel preskocil sam od seba tento krok zmenou url
			// pri spravnom prechode vkladanim sa fotky nahrat musia - takze chyba nenastane
			messageData = {
				type: StatusMessage.TYPE.WARNING,
				title: this._dictionary.get('ActionMessage.moduleMissTitle'),
				text: this._dictionary.get('ActionMessage.moduleMissBody', {
					COUNT: errorCount
				})
			}
		} else {
			messageData = {
				type: StatusMessage.TYPE.SUCCESS,
				title: this._dictionary.get('ActionMessage.titleSuccess'),
				text: this._dictionary.get('ActionMessage.activateSuccess', {
					COUNT: 1,
					IDS: this._getAdvertId()
				})
			}
		}

		ToastHelper.setToast(messageData)

		this._redirectToAdvertList()
	}
}
