import React, { useContext } from 'react'
import classnames from 'classnames'
import Context from 'ima/page/context'
import { Radio } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import PAYMENT_STATE_KEYS from '../../PaymentStateKeys'
import IMAGES from 'app/base/ImagesConstants'
import PaymentPlaceholders from '../placeholders/PaymentPlaceholders'
import PropTypes from 'prop-types'
import PaymentPropTypes from 'app/model/payment/PaymentPropTypes'

import './PaymentTypeCS.json'
import './PaymentType.less'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-payment-type'

const PaymentType = (props) => {
	const context = useContext(Context)
	const settings = context.$Settings.$App

	const localize = useLocalize()

	const { payments = [], paymentType = '', setPaymentType, refreshPayments } = props

	const fixedPaymentTypes = Object.keys(PAYMENTS_CONSTANTS.PAYMENT_TYPE).reduce(
		(paymentTypesArr, type) => {
			// kontrola ci typ platby prisiel aj zo serveru
			if (
				payments.some((payment) => payment.paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE[type])
			) {
				paymentTypesArr.push(PAYMENTS_CONSTANTS.PAYMENT_TYPE[type])
			}
			return paymentTypesArr
		},
		[]
	)

	const credit = payments.filter(
		(payment) => payment.paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET
	)[0]

	const walletCredit = credit && credit.credit ? credit.credit : 0

	const renderPaymentType = (type) => {
		const { PAYMENT_CREDIT_CARD, PAYMENT_WALLET, PAYMENT_SMS } = PAYMENTS_CONSTANTS.PAYMENT_TYPE

		if (type === PAYMENT_CREDIT_CARD) {
			return renderCheckbox(localize('PaymentType.creditCard'), type)
		} else if (type === PAYMENT_WALLET) {
			return renderCheckbox(localize('PaymentType.wallet'), type)
		} else if (type === PAYMENT_SMS) {
			return renderCheckbox(localize('PaymentType.sms'), type)
		}
	}

	/**
	 * Vrati URL pro dobiti penezenky
	 */
	const getPaymentWalletUrl = () => {
		const service = 'drobneplatby'

		return `${settings.walletUrl}${encodeURIComponent(`?language=cz&new-order&service=${service}`)}`
	}

	const renderRadioContent = (type, isRadioDisabled) => {
		const { PAYMENT_CREDIT_CARD, PAYMENT_WALLET, PAYMENT_SMS } = PAYMENTS_CONSTANTS.PAYMENT_TYPE

		const contentProps = {
			className: classnames({
				[`${CLASSNAME}__item-info`]: true,
				[`${CLASSNAME}__item-info--disabled`]: isRadioDisabled
			})
		}

		const walletUrl = getPaymentWalletUrl()

		if (type === PAYMENT_CREDIT_CARD) {
			return <div {...contentProps}>{localize('PaymentType.infoCreditCard')}</div>
		} else if (type === PAYMENT_WALLET) {
			return (
				<div {...contentProps}>
					<div>{localize('PaymentType.infoWallet1')}</div>
					<div>
						<span>{localize('PaymentType.infoWallet2')}</span>
						<span className={`notranslate ${CLASSNAME}__credit`} translate='no' lang='cs-cz'>
							{walletCredit} {localize('Base.CZK')}.{' '}
						</span>
						<div className={`${CLASSNAME}__refresh-wrapper`}>
							(
							<button
								type='button'
								onClick={() => {
									refreshPayments()
								}}
							>
								<img
									alt=''
									src={IMAGES['image__payment-refresh']}
									className={`${CLASSNAME}__refresh-img`}
								/>
							</button>
							).
						</div>
						<a href={walletUrl} className={`${CLASSNAME}__link`} target='seznam-penezenka'>
							{localize('PaymentType.infoWalletLink')}
						</a>
					</div>
				</div>
			)
		} else if (type === PAYMENT_SMS) {
			return <div {...contentProps}>{localize('PaymentType.infoSMS')}</div>
		}
	}

	const renderCheckbox = (label, type) => {
		const isSelected = paymentType === type
		const isRadioDisabled =
			type === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET && Number(walletCredit) === 0

		return (
			<label key={type} className={`${CLASSNAME}__item`}>
				<Radio
					className={classnames({
						[`${CLASSNAME}__item--active`]: isSelected
					})}
					label={label}
					value={type}
					disabled={isRadioDisabled}
					checked={isSelected}
					data-e2e={`payment-type-${type}`}
					onChange={(event) => {
						event.stopPropagation()
						setPaymentType(type)
					}}
				/>
				{renderRadioContent(type, isRadioDisabled)}
			</label>
		)
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__header`}>{localize('PaymentType.header')}</div>
			<div className={`${CLASSNAME}__list`}>
				{fixedPaymentTypes.length > 0 ? (
					fixedPaymentTypes.map((paymentType) => renderPaymentType(paymentType))
				) : (
					<PaymentPlaceholders isPaymentType={true} />
				)}
			</div>
		</div>
	)
}

const selectors = (state) => ({
	payments: state[PAYMENT_STATE_KEYS.PAYMENTS],
	paymentType: state[PAYMENT_STATE_KEYS.PAYMENT_TYPE],
	setPaymentType: state[PAYMENT_STATE_KEYS.SET_PAYMENT_TYPE],
	refreshPayments: state[PAYMENT_STATE_KEYS.REFRESH_PAYMENTS]
})

PaymentType.propTypes = {
	payments: PropTypes.arrayOf(PropTypes.shape(PaymentPropTypes)),
	paymentType: PropTypes.string,
	setPaymentType: PropTypes.func,
	refreshPayments: PropTypes.func
}

export default React.memo(select(selectors)(PaymentType))
