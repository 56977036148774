import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { PaginationEntity } from '@inzeraty/models'
import { Format } from '@inzeraty/helpers'
import MirroringEntity from 'app/model/mirroring/MirroringEntity'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import ExportButton from 'app/page/internalAdmin/components/exportButton/ExportButton'
import IAMirroringListController from './IAMirroringListController'

const IAMirroringListView = ({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading,
	exportMirroring
}) => {
	return (
		<Layout title='Výpis zrcadlení inzerce na Sbazar'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<ExportButton
				onClick={() => {
					exportMirroring && exportMirroring()
				}}
				buttonText='Exportovat zrcadlení inzerce'
			/>

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='id' sortParam='id' isDefaultDescending={true} alignRight={true}>
						ID zrcadlení
					</TableHeadSorter>,
					<TableHeadSorter
						key='premise_external_id'
						sortParam='premise_external_id'
						alignRight={true}
					>
						Sauto ID
					</TableHeadSorter>,
					<TableHeadSorter key='premise_id' sortParam='premise_id' alignRight={true}>
						ASK ID
					</TableHeadSorter>,
					<TableHead key='premise_name'>Název firmy</TableHead>,
					<TableHead key='product'>Produkt</TableHead>,
					<TableHead key='state'>Stav</TableHead>,
					<TableHeadSorter key='date_from' sortParam='date_from'>
						Datum od
					</TableHeadSorter>,
					<TableHeadSorter key='date_to' sortParam='date_to'>
						Datum do
					</TableHeadSorter>
				]}
				rowCells={[
					({ id }) => [id, { isNumber: true }],
					({ premiseExternalId }) => [premiseExternalId, { isNumber: true }],
					({ premiseId }) => [premiseId, { isNumber: true }],
					({ premiseName }) => premiseName,
					() => 'Zrcadlení',
					({ status }) =>
						Object.values(IAMirroringListController.MIRRORING_STATUS).find(
							(option) => option.id === status
						)?.name ?? status,
					({ dateFrom }) => Format.date(dateFrom),
					({ dateTo }) => Format.date(dateTo)
				]}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAMirroringListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(MirroringEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool,
	exportMirroring: PropTypes.func
}

export default IAMirroringListView
