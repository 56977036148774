import React from 'react'
import BaseWidget from '../base/BaseWidget'
import { InputSurface } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './TextWidget.less'

export const TEXT_WIDGET_ID = 'text_widget'

const CLASSNAME = 'c-cp-text-widget'

function TextWidget({ formLineEntity, onChange = DEFAULT_PROPS.FUNCTION, section }) {
	const {
		extra: { getValidationMessage },
		value,
		required
	} = formLineEntity

	return (
		<BaseWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={TEXT_WIDGET_ID}
			onChange={(_, value) => {
				onChange({
					id: formLineEntity.id,
					value,
					section
				})
			}}
			onBlur={() => {
				if (getValidationMessage) {
					const errorMessage = getValidationMessage(value, required) || ''
					onChange({
						id: formLineEntity.id,
						errorMessage,
						section
					})
				}
			}}
			renderInput={(formLineEntity, { className, ...props }) => {
				const { extra: { limit, type = 'text' } = {} } = formLineEntity

				return (
					<>
						{/* 
						Firefox provadel nejaky vlastni autocomplete nad polickem 
						'Penezenka ucet' u editace firmy v IA, kdy automaticky doplnoval 
						do prazdneho inputu uz jednou zadana data (klidne i z jine firmy). 
						Pridanim schovaneho inputu docilime toho, ze bude Firefoxem vyplnen
						prave tento schovany input.
						*/}
						<input
							className={`${CLASSNAME}__hidden`}
							type='text'
							name='email'
							placeholder='email'
							value=''
						/>

						<InputSurface
							tagName='input'
							className={`${className} ${CLASSNAME}__input`}
							type={type}
							size='small'
							maxLength={limit}
							disabled={Boolean(formLineEntity.disabled)}
							{...props}
							error={!!formLineEntity.errorMessage}
						/>
					</>
				)
			}}
		/>
	)
}

TextWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func.isRequired,
	section: PropTypes.string
}

export default TextWidget
