import RouteNames from 'app/base/RouteNames'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import FilterConstants from 'app/model/filter/FilterConstants'
import FilterFromUrlAppConvertor from 'app/model/filter/filterConvertor/FilterFromUrlAppConvertor'
import SspCollocationParams from 'app/helpers/sspCollocation/SspCollocation'
import { CATEGORIES } from 'app/base/Constants'

/**
 * Získá options pro žádost o reklamu na výpisu inzerátů.
 *
 * @private
 * @method _getAdvertListOptions
 * @param {Object} pageData
 * @return {Object}
 */
const getAdvertListOptions = (pageData = {}, isNative) => {
	const PRMS = AdvertListUrlConvertor.constants.URL_APP_PARAMS
	const { params = {} } = pageData
	const { [PRMS.CATEGORY_NAME]: category } = params

	let collocationParams = []

	// Nastavení seo názvů vybraných značek a modelů
	const { filterFormLineEntities = {} } = getPageState(pageData)
	const { options, value } =
		filterFormLineEntities.find(
			(formLineEntity) => formLineEntity.id === FilterConstants.formLineIds.BRAND_MODEL
		) || {}

	collocationParams = [
		...collocationParams,
		...FilterFromUrlAppConvertor.getSeoNamesModels(value, options)
	]

	const brands = [...FilterFromUrlAppConvertor.getSeoNamesBrands(value, options)]

	collocationParams = [...collocationParams, ...brands]

	collocationParams = [
		...collocationParams,
		...SspCollocationParams({
			fuels: params[PRMS.FUEL_NAME],
			vehicleBodies: params[PRMS.VEHICLE_BODY_NAME],
			priceFrom: params[PRMS.PRICE_FROM],
			priceTo: params[PRMS.PRICE_TO],
			tachometerFrom: params[PRMS.TACHOMETER_FROM],
			tachometerTo: params[PRMS.TACHOMETER_TO],
			yearFrom: params[PRMS.AGE_FROM],
			yearTo: params[PRMS.AGE_TO],
			categorySeoName: params[PRMS.CATEGORY_NAME]
		})
	]

	const sspSection = _getSspSection(category)
	const section = `/${sspSection}`

	return {
		section,
		collocation: collocationParams.join(' ').trim()
	}
}

const _getSspSection = (category) => {
	const seoName = category.seoName ? category.seoName : category
	for (const constant in CATEGORIES) {
		if (CATEGORIES[constant].seoName === seoName) {
			return CATEGORIES[constant].sspSection
		}
	}
	return category.seoName
}

/**
 * Získá options pro žádost o reklamu na detailu inzerátu.
 *
 * @private
 * @method _getAdvertDetailOptions
 * @param {Object} pageData
 * @return {Object}
 */
const getAdvertDetailOptions = (pageData) => {
	const { advertEntity } = getPageState(pageData)
	const {
		price,
		operatingLeasePriceWithoutVat,
		category = {},
		manufacturerCb = {},
		modelCb = {},
		premise = {},
		customId = '',
		oldId,
		id
	} = advertEntity
	const manufacturer = manufacturerCb.seoName
	const userClient = premise.id ? 'klient' : 'user'
	const ico = premise.subject && premise.subject.ico ? `ico-${premise.subject.ico}` : ''
	const askId = premise.id ? `ask-id-${premise.id}` : ''

	const collocationParams = [
		manufacturer,
		`${manufacturer}-${modelCb.seoName}`,
		userClient,
		ico,
		premise.externalId || '',
		askId
	]

	if (premise && premise.softwareKeys && premise.softwareKeys.length) {
		for (const { id } of premise.softwareKeys) {
			collocationParams.push(`key${id}`)
		}
	}

	const passons = {
		customId: customId.replace(/#/g, encodeURIComponent('#')),
		priceFormat: price || operatingLeasePriceWithoutVat,
		id: oldId || id
	}

	const section = `/${_getSspSection(category)}`

	return {
		section,
		collocation: collocationParams.join(' '),
		passons
	}
}

/**
 * Získá options pro žádost o reklamu na stránce prodejce.
 *
 * @private
 * @method _getSellerOptions
 * @param {Object} pageData
 * @return {Object}
 */
const getSellerOptions = (pageData) => {
	const { premiseEntity = {} } = getPageState(pageData)
	const { subject = {}, externalId } = premiseEntity

	const collocationParams = [subject.ico ? `ico-${subject.ico}` : '', externalId || '']

	return {
		collocation: collocationParams.join(' ')
	}
}

/**
 * Získá options pro žádost o reklamu na HP.
 *
 * @private
 * @method _getHomepageOptions
 * @param {Object} pageData
 * @return {Object}
 */
const getHomepageOptions = (pageData) => {
	const { category = {} } = getPageState(pageData)
	const section = `/${_getSspSection(category)}`

	return {
		section
	}
}

const getPageState = (pageData = {}) => {
	return pageData.pageState ? pageData.pageState : {}
}

export default class SspCollocationOptions {
	/**
	 * Vrati options pre jednotlive routy
	 * ktore sluzia pre cielenie reklamy
	 * @param {Object} pageData
	 */
	static getOptions(pageData = {}, isNative = false) {
		const { routeName = '' } = pageData

		const getDefaultOptions = (pageData, isNative) => ({})

		const optionsGetters = {
			[RouteNames.USERWEB.HOMEPAGE]: getHomepageOptions,
			[RouteNames.USERWEB.ADVERT_LIST]: getAdvertListOptions,
			[RouteNames.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES]: getAdvertListOptions,
			[RouteNames.USERWEB.ADVERT_DETAIL]: getAdvertDetailOptions,
			[RouteNames.USERWEB.OPERATING_LEASE_DETAIL]: getAdvertDetailOptions,
			[RouteNames.USERWEB.SELLER]: getSellerOptions,
			[RouteNames.USERWEB.SELLER_WITH_OPERATING_LEASES]: getSellerOptions,
			[RouteNames.USERWEB.SELLER_LIST]: getDefaultOptions,
			[RouteNames.USERWEB.ARTICLES]: getDefaultOptions,
			[RouteNames.USERWEB.FAVORITE_LIST]: getDefaultOptions,
			[RouteNames.USERWEB.CATALOG]: getDefaultOptions,
			[RouteNames.USERWEB.CATALOG_MODELS]: getDefaultOptions,
			[RouteNames.USERWEB.CATALOG_MODEL_LINES]: getDefaultOptions,
			[RouteNames.USERWEB.CATALOG_MODIFICATIONS]: getDefaultOptions,
			[RouteNames.USERWEB.CATALOG_MODIFICATION_DETAIL]: getDefaultOptions
		}

		if (optionsGetters.hasOwnProperty(routeName)) {
			return optionsGetters[routeName](pageData, isNative)
		}
	}
}
