import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Tag } from '@inzeraty/components'

export default class DistrictTag extends AbstractPureComponent {
	static get propTypes() {
		return {
			regionValue: PropTypes.number.isRequired,
			districtValue: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
			getValues: PropTypes.func,
			id: PropTypes.string,
			onOpen: PropTypes.func,
			deleteTag: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			regionValue: -1,
			districtValue: -1,
			text: '',
			onOpen: DEFAULT_PROPS.FUNCTION,
			deleteTag: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._openFilterPopup = this._openFilterPopup.bind(this)
		this._deleteTag = this._deleteTag.bind(this)
	}

	render() {
		const { text } = this.props

		return (
			<Tag
				size='small'
				onClick={this._deleteTag}
				data-dot='delete'
				data-dot-data={`{"value": "${text}"}`}
			>
				{text}
			</Tag>
		)
	}

	_openFilterPopup() {
		const { onOpen, regionValue, districtValue } = this.props

		onOpen(regionValue, districtValue)
	}

	_deleteTag() {
		const { id, regionValue, districtValue, getValues, deleteTag } = this.props

		const value = getValues(id)

		if (value.has(regionValue)) {
			const selectedDistrictsForRegion = new Set(value.get(regionValue))
			selectedDistrictsForRegion.delete(districtValue)

			const newFormLineEntityValue = new Map(value)
			newFormLineEntityValue.set(regionValue, selectedDistrictsForRegion)

			if (!selectedDistrictsForRegion.size) {
				newFormLineEntityValue.delete(regionValue)
			}

			deleteTag(newFormLineEntityValue)
		}
	}
}
