import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const WidgetHidden = ({ formLineEntity = {} }) => {
	const { id, value = '' } = formLineEntity

	return <input type='hidden' id={id} value={value} />
}

WidgetHidden.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)])
}

export default WidgetHidden
