import { StatusMessage, PageLoader } from '@inzeraty/components'
import AbstractController from 'ima/controller/AbstractController'
import GenericError from 'ima/error/GenericError'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'

import 'app/base/ActionMessageCS.json'

export default class AbstractInternalAdminController extends AbstractController {
	constructor(dependenciesHelper) {
		super()

		this._dependenciesHelper = dependenciesHelper
		this._adminService = this._dependenciesHelper.getDependency('adminService')
		this._logoutService = this._dependenciesHelper.getDependency('logoutService')
		this._premiseService = this._dependenciesHelper.getDependency('premiseService')
		this._advertService = this._dependenciesHelper.getDependency('advertService')
		this._utils = this._dependenciesHelper.getDependency('utils')
		this._historyStorage = this._dependenciesHelper.getDependency('historyStorage')
		this._internalAdminHelper = this._dependenciesHelper.getDependency('internalAdminHelper')
		this._pageLoaderExtension = this._dependenciesHelper.getExtension('pageLoaderExtension')

		this._dictionary = this._utils.$Dictionary
	}

	static get stateId() {
		return {
			ADMIN_SELF: 'adminSelf',
			STATUS_MESSAGE: 'statusMessage',
			GO_BACK_LIST_URL: 'goBackListUrl',
			DIRECT_LINK_DATA: 'directLinkData'
		}
	}

	init() {
		if (this._dependenciesHelper) {
			this._dependenciesHelper.addExtensionsToController(this)
			this._pageLoaderExtension.show()
		}
	}

	load(state = {}) {
		return Object.assign({}, state, {
			[AbstractInternalAdminController.stateId
				.ADMIN_SELF]: this._internalAdminHelper.getAdminSelf(),
			[AbstractInternalAdminController.stateId.STATUS_MESSAGE]: null
		})
	}

	activate() {
		this._utils.$Dispatcher.listen('admin-logout', this._handleAdminLogout, this)
		this._pageLoaderExtension.hide()
	}

	deactivate() {
		this._utils.$Dispatcher.unlisten('admin-logout', this._handleAdminLogout, this)
	}

	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		metaManager.setTitle('Interní admin')
	}

	onShowStatusMessage(data) {
		this.setState({
			[AbstractInternalAdminController.stateId.STATUS_MESSAGE]: data || null
		})
	}

	_showStatusMessageToast(messageData) {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
	}

	async onAction({ action, entities }) {
		const {
			promise,
			getErrorMessage = () => '',
			getSuccessMessage = () => ''
		} = await this._handleAction({
			action,
			entities,
			entitiesIds: this._getEntitiesIds(entities)
		})

		if (promise) {
			const pageLoaderId = this._pageLoaderExtension.show()

			promise
				.then((data) => {
					this._processSuccess(data, pageLoaderId).then((hasOperationError = false) => {
						// - pokud s odpovedi z BE neprisla zadna chyba zobrazim hlasku o uspesne akci
						if (!hasOperationError) {
							this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
								type: StatusMessage.TYPE.SUCCESS,
								title: this._dictionary.get('ActionMessage.titleSuccess'),
								text: getSuccessMessage(data)
							})
						}
					})
				})
				.catch((error) => {
					this._pageLoaderExtension.hide(pageLoaderId)

					this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
						type: StatusMessage.TYPE.ERROR,
						title: this._dictionary.get('ActionMessage.titleError'),
						text: getErrorMessage(error)
					})
				})
		}
	}

	async _processSuccess(data, pageLoaderId) {
		const hasOperationError = await this._processSuccessAction(data)
		this._pageLoaderExtension.hide(pageLoaderId)
		return hasOperationError
	}

	onExportPremises() {
		PageLoader.start()

		this._premiseService
			.downloadPremisesCSV()
			.catch(() => {
				this._showStatusMessageToast({
					type: StatusMessage.TYPE.ERROR,
					title: 'Nepodařilo se provést export firem.'
				})
			})
			.finally(() => {
				PageLoader.end()
			})
	}

	onExportAdverts() {
		PageLoader.start()

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = '-id'
		}

		// - predame info BE, ze jde o export
		params.export = 1

		params.limit = 10000
		params.offset = 0

		this._advertService
			.downloadAdvertsCSV(params)
			.catch(() => {
				this._showStatusMessageToast({
					type: StatusMessage.TYPE.ERROR,
					title: 'Nepodařilo se provést export inzerátů.'
				})
			})
			.finally(() => {
				PageLoader.end()
			})
	}

	/**
	 * Ziska url pro tlacitko zpet na ...
	 *
	 * @method _getBackUrl
	 * @private
	 **/
	_getBackUrl(backRouteName) {
		// - pokusim se dohledat v historii nejblizsi vypis inzeratu
		let prevIndex = this._historyStorage.getActualIndex() - 1
		while (this._historyStorage.getIndexRouteName(prevIndex) !== backRouteName && prevIndex > 0) {
			prevIndex--
		}

		// - pokud jsem dohledal v historii nejblizsi vypis, tak vratim URL na nej, jinak na vypis bez filtru
		if (prevIndex > 0) {
			return this._router.link(
				this._historyStorage.getIndexRouteName(prevIndex),
				this._historyStorage.getIndexRouteParams(prevIndex)
			)
		} else {
			return this._router.link(backRouteName, {})
		}
	}

	_handleAction({ action, entities, entitiesIds }) {
		throw new GenericError(
			'Je nutné implementovat megodu _handleAction, která by měla vracet objekt { promise, getErrorMessage }. Kde promise je promise s aplikováním akce. A getErrorMessage je metoda pro ziskani chybové hlášky, která se vypíše v případě nedopadnutí promise.'
		)
	}

	async _processSuccessAction(data) {
		throw new GenericError(
			'Je nutné implementovat asynchronni megodu _processSuccessAction, která se vyvolá po uspěšném provedení akce. Pravděpodobně by měla provést aktualizaci výpisu entit.'
		)
	}

	_getEntitiesIds(entities = []) {
		return entities.map(({ id }) => id)
	}

	_handleAdminLogout() {
		//odhlaseni nejdriv z naseho API, pak z neznamu
		this._logoutService.logout().then(() => {
			this._utils.$Router.redirect(this._utils.$Settings.api.logoutUrl)
		})
	}

	_getAdvertId() {
		return InternalAdminUrlConvertor.getAdvertId(this._utils.$Router)
	}
}
