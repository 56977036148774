import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import TextLink from 'app/component/textLink/TextLink'
import ROUTE_NAMES from 'app/base/RouteNames'

import './PathSeparator.less'
import './PathSeparatorCS.json'

const CLASSNAME = 'c-path-sepataror'

export const getHomePath = (localize, router) => {
	return {
		name: localize('PathSeparator.home'),
		url: router.link(ROUTE_NAMES.USERWEB.CATALOG)
	}
}

const PathSeparator = (props) => {
	const { paths = DEFAULT_PROPS.ARRAY, dataLoaded } = props

	const renderPathList = () => {
		return (
			<ol
				itemScope
				itemType='http://schema.org/BreadcrumbList'
				className={`${CLASSNAME}__list`}
				data-dot='breadcrumbs'
			>
				{paths.map((path, idx) => {
					const { url, name } = path
					const key = `${idx}__${name}___key`

					const listItemProps = {
						itemProp: 'itemListElement',
						itemType: 'https://schema.org/ListItem',
						key,
						className: `${CLASSNAME}__list-item`,
						'data-dot': 'item'
					}

					const renderMetaProp = (idx) => {
						return <meta itemProp='position' content={idx + 1} />
					}

					if (idx === paths.length - 1) {
						return (
							<li {...listItemProps} itemScope>
								<div itemProp='name' className={`${CLASSNAME}__path-name`}>
									{name}
								</div>
								{renderMetaProp(idx)}
							</li>
						)
					} else if (idx === 0) {
						return (
							<li {...listItemProps} itemScope>
								<TextLink itemProp='item' href={url} className={`${CLASSNAME}__path-link`}>
									<span itemProp='name'>{name}</span>
								</TextLink>
								<span className={`${CLASSNAME}__separator`}>&gt;</span>
								{renderMetaProp(idx)}
							</li>
						)
					} else {
						return (
							<li {...listItemProps} itemScope>
								<TextLink itemProp='item' href={url} className={`${CLASSNAME}__path-link`}>
									<span itemProp='name'>{name}</span>
								</TextLink>
								<span className={`${CLASSNAME}__separator`}>&gt;</span>
								{renderMetaProp(idx)}
							</li>
						)
					}
				})}
			</ol>
		)
	}

	return (
		<div className={CLASSNAME}>
			{dataLoaded ? renderPathList() : <div className={`${CLASSNAME}__placeholder`}></div>}
		</div>
	)
}

PathSeparator.propTypes = {
	paths: PropTypes.array,
	dataLoaded: PropTypes.bool
}

export default React.memo(PathSeparator)
