import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import deepEqual from 'fast-deep-equal'

export default class AdvertCountsService {
	constructor(advertCountsResource, advertCountsFactory) {
		this._advertCountsResource = advertCountsResource
		this._advertCountsFactory = advertCountsFactory
	}

	/**
	 * Vrati pocty inzeratu dostupnych pro jeden filtr v zavislosti na ostatnich filtrech.
	 *
	 * @method getFilteredAdvertsCount
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>} Pocty inzeratu pro jeden dany filtr.
	 */
	getFilteredAdvertsCount(params, options) {
		const { [AdvertListUrlConvertor.constants.URL_API_PARAMS.FILTER_NAME]: filterName } = params

		// musime si pamatovat s jakymi URL parametry jsme volali API. Muze se
		// stat, ze nam odpovedi nemusi vzdy prijit ve stejnem poradi, jak jsme
		// odeslali dotazy. Tak at nam nejaka starsi opozdena odpoved neprepise
		// spravna data.
		this._latestUrlParamsPerFilter = Object.assign({}, this._latestUrlParamsPerFilter, {
			[filterName]: params
		})

		return this._advertCountsResource.getFilteredAdvertsCount(params, options).then((response) => {
			const { [filterName]: latestUrlParams } = this._latestUrlParamsPerFilter

			// stare odpovedi ignorujeme
			if (!deepEqual(latestUrlParams, params)) throw new Error('It is a delayed response.')

			const apiData = response.body.result
			const appData = this._advertCountsFactory.transformData(apiData)

			return appData
		})
	}
}
