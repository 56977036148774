export default class ModelsCatalogFactory {
	transformData(data) {
		return this._mapModels(data)
	}

	_mapModels(models = []) {
		return models.map((model) => {
			const manufacturer = model.manufacturer || {}

			return {
				id: model.id,
				images: model.images,
				modelCb: model.model_cb,
				name: model.name,
				seoName: model.seo_name,
				hasNoData: model.no_data,
				manufacturer: {
					id: manufacturer.id,
					name: manufacturer.name,
					seoName: manufacturer.seo_name
				}
			}
		})
	}
}
