import React, { useContext } from 'react'
import RibbonSearch from './ribbonSearch/RibbonSearch'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import IMAGES from 'app/base/ImagesConstants'
import classNames from 'classnames'
import { useRouteName } from 'app/base/componentHelpers'
import AdminMenu from 'app/component/adminMenu/AdminMenu'
import PropTypes from 'prop-types'
import { ROUTE_NAMES_WITH_ADMIN_MENU, CLASSNAME as RIBBON_CLASSNAME } from './Ribbon'

import './FallbackRibbon.less'

const CLASSNAME = 'c-fallback'

const FallbackRibbon = ({ isPremise = false }) => {
	const { $Router } = useContext(Context)
	const routeName = useRouteName()
	const isUserweb = Object.values(ROUTE_NAMES.USERWEB).includes(routeName)

	const getRibbonCenterContent = () => {
		if (isUserweb) return <RibbonSearch />
		return ROUTE_NAMES_WITH_ADMIN_MENU.includes(routeName) && isPremise ? <AdminMenu /> : null
	}

	return (
		<div
			className={classNames({
				[CLASSNAME]: true,
				[RIBBON_CLASSNAME]: true,
				[`${RIBBON_CLASSNAME}--with-search`]: isUserweb
			})}
		>
			<header
				aria-label='Hlavička'
				role='banner'
				className={classNames({
					[`${CLASSNAME}__ribbon`]: true,
					[`${CLASSNAME}__ribbon-base`]: true,
					[`${CLASSNAME}__ribbon-fixed`]: true
				})}
			>
				<div className={`${CLASSNAME}__ribbon-start`}>
					<a href='/' data-dot='lista_sauto' className={`${CLASSNAME}__ribbon-service`}>
						<img
							src={IMAGES.image__logo}
							className={`${CLASSNAME}__ribbon-service-logo`}
							alt='Sauto.cz'
						/>
					</a>
				</div>
				<div className={`${CLASSNAME}__ribbon-center`}>{getRibbonCenterContent()}</div>
				<div className={`${CLASSNAME}__ribbon-end`}>
					<div className={`${CLASSNAME}__ribbon-end-reversed`}>
						<div className={`${CLASSNAME}__ribbon-seznam`}>
							<a
								href={$Router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT)}
								rel='noopener noreferrer'
								data-dot='lista_tlacitko'
								className={`${CLASSNAME}__ribbon-insert`}
							>
								<img src='/static/img/plus.svg' alt='Ikona' />
								<div className={`${CLASSNAME}__ribbon-insert-text`}>Vložit inzerát</div>
							</a>
							<a
								href='https://www.seznam.cz'
								rel='noopener noreferrer'
								target='_blank'
								data-dot='lista_odkaz_seznam'
								className={`${CLASSNAME}__ribbon-seznam-link`}
							>
								Seznam.cz
							</a>
						</div>
					</div>
				</div>
			</header>
		</div>
	)
}

FallbackRibbon.propTypes = {
	isPremise: PropTypes.bool
}

export default FallbackRibbon
