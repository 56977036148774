export default Object.freeze({
	SUBMIT_SAVE_CERT_FORM: 'submitSaveCertForm',
	CEBIA_REPORT_VERIFIED: 'cebiaRaportVerified',

	FORM_LINES: 'FORM_LINES',
	ON_CHANGE: 'ON_CHANGE',
	IS_FORM_BEING_SUBMITTED: 'IS_FORM_BEING_SUBMITTED',
	SUBMIT_FORM: 'SUBMIT_FORM',
	SCROLL_PAGE_TO_TOP: 'SCROLL_PAGE_TO_TOP',
	FORM_STATUS: 'FORM_STATUS',
	SET_FORM_STATUS: 'SET_FORM_STATUS',

	PREV_PAGE_URL: 'PREV_PAGE_URL'
})
