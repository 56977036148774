import React, { memo, useState, useEffect } from 'react'
import { Icon } from '@sznds/react'
import { SEARCH } from '@sznds/icons'
import { useLocalize } from 'app/base/componentHelpers'
import HPLink from 'app/page/userweb/home/hpLink/HPLink'
import SearchHelper from 'app/helpers/searchHelper/SearchHelper'
import LinkPlaceholder from 'app/page/userweb/home/placeholder/linkPlaceholder/LinkPlaceholder'

import './LastSearch.less'
import './LastSearchCS.json'

const CLASSNAME = 'c-last-search'

const LastSearch = memo(() => {
	const localize = useLocalize()

	const [title, setTitle] = useState(undefined)
	const [sentence, setSentence] = useState(undefined)
	const [url, setUrl] = useState(undefined)
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		const { title, sentence, url } = SearchHelper.loadLastSearch()

		setSentence(sentence)
		setTitle(title)
		setUrl(url)
		setIsLoaded(true)
	}, [])

	const renderLastSearch = () => {
		if (title && sentence && url) {
			return (
				<div className={CLASSNAME} data-dot='go-to-latest-search'>
					<div className={`${CLASSNAME}__header`}>{localize('LastSearch.lastSearch')}</div>
					<HPLink
						href={url}
						iconLeft={<Icon symbol={SEARCH} />}
						firstLine={title}
						secondLine={sentence}
					/>
				</div>
			)
		} else {
			return null
		}
	}

	if (isLoaded) {
		return renderLastSearch()
	} else {
		return <LinkPlaceholder showHeader={true} />
	}
})

export default LastSearch
