import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import DropdownLogic from './DropdownLogic'

class DependentDropdownsLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			dropdownDefinition: PropTypes.object,
			dependentDropdownDefinition: PropTypes.object,
			value: PropTypes.number, //TODO lepsi nazev?
			dependentValue: PropTypes.number,
			filterLogicData: PropTypes.object.isRequired,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			filterLogicData: DEFAULT_PROPS.OBJECT
		}
	}

	constructor(props, context) {
		super(props, context)

		this._canBeDependentDropdownClosed = this._canBeDependentDropdownClosed.bind(this)
		this._closeDependentDropdown = this._closeDependentDropdown.bind(this)
		this._closeDependentDropdownWithoutSavingChanges = this._closeDependentDropdownWithoutSavingChanges.bind(
			this
		)
	}

	render() {
		const { filterLogicData, children } = this.props

		return (
			<DropdownLogic
				{...this.props}
				filterLogicData={Object.assign({}, filterLogicData, {
					advertsCount: filterLogicData.advertsCountForMainDropdown
				})}
				autoCloseOnUnmount={false}
			>
				{(dropdownLogicChildrenProps) => {
					return children({
						mainDropdown: dropdownLogicChildrenProps,

						dependentDropdown: {
							closeDropdown: () => this._closeDependentDropdown(dropdownLogicChildrenProps),
							closeDropdownWithoutSavingChanges: this._closeDependentDropdownWithoutSavingChanges,
							canBeDropdownClosed: this._canBeDependentDropdownClosed
						}
					})
				}}
			</DropdownLogic>
		)
	}

	componentDidMount() {
		const {
			filterLogicData: {
				advertsCountForDependentDropdown: {
					loadFilteredAdvertsCount: loadFilteredAdvertsCountForDependentDropdown = () => {}
				} = {}
			},
			dependentValue
		} = this.props

		// nacteme cisla jen pro zavisly dropdown, cisla pro hlavni dropdown se nacitaji v
		// komponente DropdownLogic
		if (dependentValue) {
			loadFilteredAdvertsCountForDependentDropdown(dependentValue)
		}
	}

	_closeDependentDropdown(mainDropdownLogicChildrenProps) {
		const {
			dependentDropdownDefinition: { updateUrlParams } = {},
			formLineEntity: { id, value },
			dependentValue,
			redirect,
			changeFilter,
			filterLogicData: {
				advertsCountForDependentDropdown: { calcFilteredAdvertsCountTotal = () => {} } = {}
			}
		} = this.props

		const canBeDependentDropdownClosed = this._canBeDependentDropdownClosed()

		if (canBeDependentDropdownClosed) {
			this.fire('closeFilterDropdown', {
				filterId: id,
				openedByValue: dependentValue
			})

			changeFilter(id, value, (newFormLineEntity) => {
				calcFilteredAdvertsCountTotal(newFormLineEntity)

				if (updateUrlParams) {
					redirect()
				}
			})
		}
	}

	_closeDependentDropdownWithoutSavingChanges() {
		const {
			formLineEntity: { id },
			dependentValue
		} = this.props

		const canBeDependentDropdownClosed = this._canBeDependentDropdownClosed()

		if (canBeDependentDropdownClosed) {
			this.fire('closeFilterDropdown', {
				filterId: id,
				openedByValue: dependentValue
			})
		}
	}

	_canBeDependentDropdownClosed() {
		const {
			formLineEntity,
			filterLogicData: { form: { validateForm = () => true } = {} }
		} = this.props

		return validateForm(formLineEntity)
	}
}

const selectors = (state) => ({
	redirect: state[FiltersExtension.stateIds.REDIRECT],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(selectors)(DependentDropdownsLogic)
