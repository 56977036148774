import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import MultiOptionFilterLogic from '../../logic/MultiOptionFilterLogic'
import InlineFormAdvertsCountLoader from '../../forms/main/inlineForms/InlineFormAdvertsCountLoader'
import MultioptionInlineForm from '../../forms/main/inlineForms/multioptionInlineForm/MultioptionInlineForm'
import { CategoryEntity } from '@inzeraty/models'

const DEFAULT_CATEGORY_PROP = new CategoryEntity(DEFAULT_PROPS.OBJECT)

export default class MultiOptionInlineFilter extends React.Component {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
			context: PropTypes.string.isRequired,
			isVisible: PropTypes.bool,
			categoryEntity: PropTypes.instanceOf(CategoryEntity)
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			formLineEntities: DEFAULT_PROPS.ARRAY,
			isVisible: true,
			categoryEntity: DEFAULT_CATEGORY_PROP
		}
	}

	render() {
		const { formLineEntity, formLineEntities, context, changeFilter, isVisible } = this.props

		return (
			<MultiOptionFilterLogic
				formLineEntity={formLineEntity}
				changeFilter={changeFilter}
				context={context}
			>
				{(filterLogicChildrenProps) => {
					const {
						form: { isInlineFormVisible },
						advertsCount: { loadFilteredAdvertsCount }
					} = filterLogicChildrenProps

					return (
						<InlineFormAdvertsCountLoader
							formLineEntity={formLineEntity}
							formLineEntities={formLineEntities}
							isVisible={isVisible && isInlineFormVisible}
							loadFilteredAdvertsCount={loadFilteredAdvertsCount}
						>
							{this._renderFilterForm(filterLogicChildrenProps)}
						</InlineFormAdvertsCountLoader>
					)
				}}
			</MultiOptionFilterLogic>
		)
	}

	_renderFilterForm(filterLogicChildrenProps) {
		const { formLineEntity, context, categoryEntity } = this.props
		const {
			form: { changeFilter },
			advertsCount: { filteredAdvertsCount, isLoadingFilteredAdvertsCount }
		} = filterLogicChildrenProps

		return (
			<MultioptionInlineForm
				formLineEntity={formLineEntity}
				onChange={changeFilter}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				filteredAdvertsCount={filteredAdvertsCount}
				// pokud je videt CTA tlacitko s poctem nabidek (a textem 'Zobrazit xxx
				// nabidek'), tak nechceme v bublinach s pocty inzeratu znovu vypisovat
				// text 'xxx nabidek', ale pouze 'xxx'
				isCountShownWithAddText={false}
				context={context}
				categoryEntity={categoryEntity}
			/>
		)
	}
}
