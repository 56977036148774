import React, { useState } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { Input, Button } from '@sznds/react'
import PAYMENT_STATE_KEYS from '../../PaymentStateKeys'
import select from 'ima-plugin-select'
import PropTypes from 'prop-types'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'

import './VerifyPinAndPay.less'
import './VerifyPinAndPayCS.json'
import '../../PaymentViewCS.json'

const CLASSNAME = 'c-verify-and-pay'

const VerifyPinAndPay = (props) => {
	const localize = useLocalize()

	const { handleSMSPay, loading, isDraft } = props

	const [pinValue, setPinValue] = useState('')

	const [paymentFailed, setPaymentFailed] = useState(false)
	const [errorCode, setErrorCode] = useState('')

	const renderPinErrors = () => {
		const text =
			errorCode === PAYMENTS_CONSTANTS.ERROR.VIN_DUPLICATION
				? localize('PaymentView.vinDuplicationError')
				: localize('VerifyPinAndPay.wrongPin')
		return (
			<div className={`${CLASSNAME}__error`}>
				<p>{text}</p>
			</div>
		)
	}

	return (
		<div className={CLASSNAME}>
			<form
				onSubmit={(event) => {
					event.preventDefault()
					handleSMSPay(pinValue, (errorCode = '') => {
						setPaymentFailed(true)
						setErrorCode(errorCode)
					})
				}}
			>
				<label className={`${CLASSNAME}__pin-wrapper`}>
					<div className={`${CLASSNAME}__label`}>{localize('VerifyPinAndPay.placeholder')}</div>
					<Input
						className={`${CLASSNAME}__pin`}
						error={paymentFailed}
						placeholder={localize('VerifyPinAndPay.placeholder')}
						type='tel'
						value={pinValue}
						onChange={(e) => {
							setPinValue(e.target.value)
							setPaymentFailed(false)
						}}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus
						aria-required={true}
						data-e2e='payment-verify-pin-input'
					/>
					<div className={`${CLASSNAME}__wrong-pin-mobile`}>
						{paymentFailed && renderPinErrors()}
					</div>
				</label>
				<Button
					className={`${CLASSNAME}__submit`}
					primary={true}
					loading={loading}
					text={localize('VerifyPinAndPay.submit')}
					type='submit'
					data-e2e='payment-verify-pin-submit'
					disabled={loading || isDraft}
				/>
			</form>
			<div className={`${CLASSNAME}__wrong-pin-desktop`}>{paymentFailed && renderPinErrors()}</div>
		</div>
	)
}

const selectors = (state) => ({
	handleSMSPay: state[PAYMENT_STATE_KEYS.HANDLE_SMS_PAYMENT],
	loading: state[PAYMENT_STATE_KEYS.LOADING]
})

VerifyPinAndPay.propTypes = {
	handleSMSPay: PropTypes.func,
	loading: PropTypes.bool,
	isDraft: PropTypes.bool
}

export default React.memo(select(selectors)(VerifyPinAndPay))
