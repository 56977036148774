
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AddMySavedSearch'] = {
  success: function(d) { return "Vaše hledání bylo úspěšně uloženo."; },
  saveError: function(d) { return "Hledání se nepodařilo uložit."; },
  loadError: function(d) { return "Došlo k chybě, opakujte prosím akci znovu."; },
  limitReachedError: function(d) { return "Dosáhli jste limitu možných uložených hledaní. Aby bylo možné uložit toto hledání, musíte některé vymazat. Jděte na stránku Uložená hledání a přes \"tři tečky\" otevřete nastavení a libovolné hledání smažete."; },
  alreadyExistsError: function(d) { return "Stejné hledání je již jednou uloženo."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	