import UserwebBaseController from 'app/base/UserwebBaseController'

export default class ErrorController extends UserwebBaseController {
	constructor(dependenciesHelper) {
		super(dependenciesHelper)

		this.status = 500
	}

	/**
	 * @return {Object}
	 */
	load() {
		return {
			status: this.status
		}
	}

	getDataForSsp() {
		return {}
	}
}
