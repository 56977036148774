import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import FilterConstants from 'app/model/filter/FilterConstants'
import { Tag } from '@inzeraty/components'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'

import 'app/base/BaseCS.json'
import './RangeTagCS.json'

export default class RangeTag extends AbstractPureComponent {
	static get propTypes() {
		return {
			emptyValue: PropTypes.object,
			id: PropTypes.string,
			unit: PropTypes.string,
			valueFrom: PropTypes.number,
			valueTo: PropTypes.number,
			onOpen: PropTypes.func,
			deleteTag: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			onOpen: DEFAULT_PROPS.FUNCTION,
			deleteTag: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._openFilter = this._openFilter.bind(this)
		this._deleteTag = this._deleteTag.bind(this)
	}

	render() {
		const tagText = this._getText()

		return (
			tagText && (
				<Tag
					size='small'
					onClick={this._deleteTag}
					data-dot='delete'
					data-dot-data={`{"value": "${tagText}"}`}
				>
					<span className='notranslate' translate='no' lang='cs-cz'>
						{tagText}
					</span>
				</Tag>
			)
		)
	}

	_getText() {
		const { valueFrom, valueTo, id } = this.props
		const isNumber = (num) => typeof num === 'number'
		const isAgeTag = id === FilterConstants.formLineIds.AGE
		let text = ''

		if (!isNullOrUndefined(valueFrom) && valueFrom >= 0 && !isNumber(valueTo)) {
			const unitValue = this._getUnit(valueFrom)

			if (isAgeTag) {
				text = `${this.localize('RangeTag.fromAge')} ${valueFrom}`
			} else {
				text = unitValue
					? `${this.localize('RangeTag.From')} ${Format.number(valueFrom)} ${unitValue}`
					: `${this.localize('RangeTag.From')} ${valueFrom}`
			}
		} else if (!isNumber(valueFrom) && !isNullOrUndefined(valueTo) && valueTo >= 0) {
			const unitValue = this._getUnit(valueTo)

			if (isAgeTag) {
				text = `${this.localize('RangeTag.toAge')} ${valueTo}`
			} else {
				text = unitValue
					? `${this.localize('RangeTag.To')} ${Format.number(valueTo)} ${unitValue}`
					: `${this.localize('RangeTag.To')} ${valueTo}`
			}
		} else if (
			!isNullOrUndefined(valueFrom) &&
			valueFrom >= 0 &&
			!isNullOrUndefined(valueTo) &&
			valueTo >= 0
		) {
			const unitValue = this._getUnit(valueTo, true)

			if (valueFrom === valueTo) {
				text = unitValue ? `${Format.number(valueFrom)} ${unitValue}` : `${valueFrom}`
			} else {
				text = unitValue
					? `${Format.number(valueFrom)}–${Format.number(valueTo)} ${unitValue}`
					: `${valueFrom}–${valueTo}`
			}
		}

		return text
	}

	_getUnit(number, isRange = false) {
		const { id, unit } = this.props

		if (unit) {
			if (FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT === id) {
				return this.localize('Base.pricePerMonth', { CURRENCY: this.localize('Base.CZK') })
			} else {
				return unit
			}
		} else if (FilterConstants.formLineIds.AIRBAGS === id) {
			if (isRange) {
				return this.localize('RangeTag.airbagsRange', { AIRBAGS_COUNT: number })
			} else {
				return this.localize('RangeInputOpener.airbags', { AIRBAGS_COUNT: number })
			}
		} else if (FilterConstants.formLineIds.CAPACITY === id) {
			if (isRange) {
				return this.localize('RangeTag.capacityRange', { CAPACITY_COUNT: number })
			} else {
				return this.localize('RangeInputOpener.capacity', { CAPACITY_COUNT: number })
			}
		} else if (FilterConstants.formLineIds.DOORS === id) {
			if (isRange) {
				return this.localize('RangeTag.doorsRange', { DOORS_COUNT: number })
			} else {
				return this.localize('RangeInputOpener.doors')
			}
		} else if (FilterConstants.formLineIds.WEIGHT === id) {
			return this.localize('RangeInputOpener.weight')
		} else if (FilterConstants.formLineIds.BEDS === id) {
			return this.localize('RangeInputOpener.beds', { BEDS_COUNT: number })
		} else if (FilterConstants.formLineIds.MACHINE_HOURS === id) {
			return this.localize('RangeInputOpener.machineHours')
		} else if (FilterConstants.formLineIds.OPERATING_LEASE_PERIOD === id) {
			if (isRange) {
				return this.localize('Base.month', { MONTH: number, MONTH_FORMATTED: '' })
			} else {
				return this.localize('RangeTag.month', { MONTH: number })
			}
		} else {
			return ''
		}
	}

	_openFilter() {
		const { onOpen } = this.props

		onOpen()
	}

	_deleteTag() {
		const { deleteTag, emptyValue } = this.props

		deleteTag(emptyValue)
	}
}
