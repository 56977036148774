import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { uuid } from 'uuidv4'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import ReviewsExtension from '../ReviewsExtension'
import { Format } from '@inzeraty/helpers'
import ShowMoreText from 'app/component/showMoreText/ShowMoreTextView'
import ReviewFeedback from '../reviewFeedback/ReviewFeedback'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import { Button } from '@sznds/react'
import { TRASH_FILLED } from '@sznds/icons'
import SimpleTooltip from 'app/component/simpleTooltip/SimpleTooltipView'
import { UserService } from '@inzeraty/models'

import './ReviewReply.less'
import 'app/base//BaseCS.json'

const CLASSNAME = 'c-review-reply'
const PLACEHOLDER = '--plh'

const ReviewReply = ({ reviewReplyEntity, reviewId }) => {
	const userEntity = useContext(UserEntityContext) || {}
	const localize = useLocalize()
	const isPlaceholder = Boolean(!reviewReplyEntity.id)
	const fire = useFire()

	const {
		createDate,
		id,
		negativeFeedbackCount,
		positiveFeedbackCount,
		reviewReply = '',
		userEmailPrefix = '',
		userId,
		userName = '',
		userFeedbackType = ReviewsExtension.USER_FEEDBACK_TYPE.NONE
	} = isPlaceholder ? { id: uuid() } : reviewReplyEntity

	const renderActions = () => {
		const { id: loggedUserId } = userEntity
		const isMine = loggedUserId && userId === loggedUserId

		return (
			<div className={`${CLASSNAME}__actions`}>
				{!isPlaceholder && (
					<>
						<ReviewFeedback
							className={`${CLASSNAME}__feedback`}
							negativeCount={negativeFeedbackCount}
							positiveCount={positiveFeedbackCount}
							userFeedbackType={userFeedbackType}
							disabled={isMine}
							onClick={(type) => {
								fire('clickReplyFeedback', {
									reviewId,
									replyId: id,
									type
								})
							}}
						/>
						<>
							<span className={`${CLASSNAME}__space-btn`}></span>
							{UserService.isUserLogged(userEntity) && isMine && (
								<SimpleTooltip
									description={localize('Base.delete')}
									ariaHidden={true}
									align={SimpleTooltip.ALIGN.RIGHT}
									renderContent={(className) => (
										<Button
											icon={TRASH_FILLED}
											size='x-small'
											surface={0}
											onClick={() => {
												fire('deleteReviewReply', {
													reviewId,
													replyId: id
												})
											}}
											className={classnames({
												[className]: !!className,
												[`${CLASSNAME}__remove-btn`]: true
											})}
											data-e2e='review-reply-delete'
										/>
									)}
								/>
							)}
						</>
					</>
				)}
			</div>
		)
	}

	return (
		<li
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}${PLACEHOLDER}`]: isPlaceholder
			})}
			data-e2e={`review-reply-${id}`}
		>
			<div className={`${CLASSNAME}__title`}>
				{userName || userEmailPrefix}
				{', '}
				<span className={`${CLASSNAME}__date`}>{Format.date(createDate)}</span>
			</div>

			<ShowMoreText
				tagName='p'
				className={`${CLASSNAME}__text`}
				text={reviewReply}
				limitLength={160}
				dataDotData={'{"type": "reviewReply"}'}
			/>

			{renderActions()}
		</li>
	)
}

ReviewReply.propTypes = {
	reviewId: PropTypes.number.isRequired,
	reviewReplyEntity: PropTypes.object.isRequired
}

export default ReviewReply
