import AbstractExtension from 'ima/extension/AbstractExtension'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'

export default class ReportExtension extends AbstractExtension {
	static get STATE_IDS() {
		return {
			ADVERT_ENTITY: 'advertEntity',
			ON_SUBMIT: 'reportOnSubmit'
		}
	}

	constructor(advertService, utils) {
		super()

		this._advertService = advertService
		this._utils = utils

		this._onSubmitForm = this._onSubmitForm.bind(this)
	}

	load() {
		return {
			[ReportExtension.STATE_IDS.ON_SUBMIT]: this._onSubmitForm
		}
	}

	_showStatusMessageToast(message, type) {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			title: message,
			type
		})
	}

	_getAdvertId() {
		const { [ReportExtension.STATE_IDS.ADVERT_ENTITY]: advertEntity } = this.getState()
		const { id: advertId = '' } = advertEntity
		return advertId
	}

	async _onSubmitForm(formData, onSubmitSuccess, onSubmitError) {
		const advertId = this._getAdvertId()

		try {
			const result = await this._advertService.saveReport(advertId, formData)

			this._showStatusMessageToast(
				this._utils.$Dictionary.get('Report.sendReportSuccess'),
				StatusMessage.TYPE.SUCCESS
			)

			onSubmitSuccess(result)
		} catch (error) {
			const { status, body } = error.getParams()
			const { errors = [] } = body || {}

			if (status === HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY) {
				onSubmitError(errors)
			} else {
				onSubmitError()
				this._showStatusMessageToast(
					this._utils.$Dictionary.get('Report.sendReportError'),
					StatusMessage.TYPE.ERROR
				)
			}
		}
	}
}
