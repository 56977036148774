import ROUTE_NAMES from 'app/base/RouteNames'

export default class CatalogMeta {
	static getMetas(routeName, dictionary, info = {}) {
		const { brandName = '', count, modelName = '', modificationName = '', textInfo = '' } = info

		const metas = {
			title: '',
			description: ''
		}

		const detailTextTitle = textInfo
			? dictionary.get('Catalog.titleModificationDetailWithInfo', {
					BRAND: brandName,
					MODEL: modelName,
					MODIFICATION: modificationName,
					INFO: textInfo
			  })
			: dictionary.get('Catalog.titleModificationDetailWithoutInfo', {
					BRAND: brandName,
					MODEL: modelName,
					MODIFICATION: modificationName
			  })

		const detailTextDescription = textInfo
			? dictionary.get('Catalog.descriptionModificationDetailWithInfo', {
					BRAND: brandName,
					MODEL: modelName,
					MODIFICATION: modificationName,
					INFO: textInfo
			  })
			: dictionary.get('Catalog.descriptionModificationDetailWithoutInfo', {
					BRAND: brandName,
					MODEL: modelName,
					MODIFICATION: modificationName
			  })

		switch (routeName) {
			case ROUTE_NAMES.USERWEB.CATALOG:
				metas.title = dictionary.get('Catalog.titleBrands')
				metas.description = dictionary.get('Catalog.descriptionBrands')
				break

			case ROUTE_NAMES.USERWEB.CATALOG_MODELS:
				metas.title = dictionary.get('Catalog.titleModels', {
					NAME: brandName
				})
				metas.description = dictionary.get('Catalog.descriptionModels', {
					NAME: brandName,
					COUNT: count
				})
				break

			case ROUTE_NAMES.USERWEB.CATALOG_MODEL_LINES:
				metas.title = dictionary.get('Catalog.titleModelLine', {
					BRAND: brandName,
					MODEL: modelName
				})
				metas.description = dictionary.get('Catalog.descriptionModelLine', {
					BRAND: brandName,
					MODEL: modelName,
					COUNT: count
				})
				break

			case ROUTE_NAMES.USERWEB.CATALOG_MODIFICATIONS:
				metas.title = dictionary.get('Catalog.titleModifications', {
					BRAND: brandName,
					MODIFICATION: modificationName
				})
				metas.description = dictionary.get('Catalog.descriptionModifications', {
					BRAND: brandName,
					MODIFICATION: modificationName,
					COUNT: count
				})
				break

			case ROUTE_NAMES.USERWEB.CATALOG_MODIFICATION_DETAIL:
				metas.title = detailTextTitle
				metas.description = detailTextDescription
				break
		}

		return metas
	}
}
