
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Favorite'] = {
  addError: function(d) { return "Inzerát se nepodařilo přidat do oblíbených. Zkuste to ještě jednou."; },
  removeError: function(d) { return "Inzerát se nepodařilo odebrat z oblíbených. Zkuste to ještě jednou."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	