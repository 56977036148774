import * as FormLines from '@inzeraty/form-lines'
import ReviewsExtension from '../ReviewsExtension'
import { FORM_LINES_IDS, FORM_LINES_NAMES } from './ReviewFormLineConstants'
import { BASIC_SELECT_WIDGET_ID } from './widgets/basicSelect/BasicSelectWidget'
import { TEXT_WIDGET_ID } from './widgets/text/TextWidget'
import { NUMBER_WIDGET_ID } from './widgets/number/NumberWidget'
import { TEXTAREA_WIDGET_ID } from './widgets/textarea/TextareaWidget'
import { RADIOS_WIDGET_ID } from './widgets/radios/RadiosWidget'

import './ReviewFormCS.json'
import 'app/page/userweb/newAdvert/basicInfo/BasicInfoCS.json'

export const reviewFirstStepFormLineEntities = (reviewData = {}, dictionary) => {
	return FormLines.createEntityList([
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.FUEL_CB,
			label: dictionary.get('BasicInfo.fuelLabel'),
			placeholder: dictionary.get('BasicInfo.fuelPlaceholder'),
			value: reviewData[FORM_LINES_NAMES.FUEL_CB],
			required: true,
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.FUEL_CB]: value }),
				isFormLineValid: (formLine) => {
					const { value, required } = formLine

					if (required) {
						return value && value.value >= 0
					} else {
						return true
					}
				}
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.MANUFACTURING_DATE,
			label: dictionary.get('BasicInfo.manufacturingDateLabel'),
			value: reviewData[FORM_LINES_NAMES.MANUFACTURING_YEAR],
			required: true,
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.MANUFACTURING_YEAR]: value }),
				isFormLineValid: (formLine) => formLine.value && formLine.value > 1880,
				maxLength: 4,
				thousandSeparator: ''
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.ENGINE_POWER,
			label: dictionary.get('BasicInfo.enginePowerLabel'),
			placeholder: dictionary.get('BasicInfo.enginePowerPlaceholder'),
			value: reviewData[FORM_LINES_NAMES.ENGINE_POWER],
			required: true,
			extra: {
				description: dictionary.get('Base.KW'),
				getData: (value) => ({ [FORM_LINES_NAMES.ENGINE_POWER]: value }),
				isFormLineValid: (formLine) => formLine.value && Number.isInteger(formLine.value)
			}
		}
	])
}

export const getReviewTypeOptions = (dictionary) => {
	return [
		{
			id: 1,
			name: dictionary.get('ReviewForm.reviewTypeNeutral'),
			value: ReviewsExtension.TYPE.NEUTRAL
		},
		{
			id: 2,
			name: dictionary.get('ReviewForm.reviewTypePositive'),
			value: ReviewsExtension.TYPE.POSITIVE
		},
		{
			id: 3,
			name: dictionary.get('ReviewForm.reviewTypeNegative'),
			value: ReviewsExtension.TYPE.NEGATIVE
		}
	]
}

export const reviewSecondStepFormLineEntities = (reviewData = {}, dictionary) => {
	return FormLines.createEntityList([
		{
			widget: TEXT_WIDGET_ID,
			id: FORM_LINES_IDS.REVIEW_TITLE,
			label: dictionary.get('ReviewForm.reviewTitle'),
			required: true,
			value: reviewData[FORM_LINES_NAMES.REVIEW_TITLE],
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.REVIEW_TITLE]: value }),
				isFormLineValid: (formLine) => formLine.value
			}
		},
		{
			widget: RADIOS_WIDGET_ID,
			id: FORM_LINES_IDS.REVIEW_TYPE,
			label: dictionary.get('ReviewForm.reviewType'),
			options: getReviewTypeOptions(dictionary),
			value: reviewData[FORM_LINES_NAMES.REVIEW_TYPE],
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.REVIEW_TYPE]: value }),
				isFormLineValid: (formLine) => formLine.value
			}
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.REVIEW_DECRIPTION,
			label: dictionary.get('ReviewForm.reviewDescription'),
			placeholder: dictionary.get('ReviewForm.reviewDescriptionPlaceholder'),
			required: true,
			value: reviewData[FORM_LINES_NAMES.REVIEW_DECRIPTION],
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.REVIEW_DECRIPTION]: value }),
				isFormLineValid: (formLine) => formLine.value
			}
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.POSITIVES,
			label: dictionary.get('ReviewForm.reviewPositives'),
			placeholder: dictionary.get('ReviewForm.reviewPositivesPlaceholder'),
			value: reviewData[FORM_LINES_NAMES.POSITIVES],
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.POSITIVES]: value }),
				isFormLineValid: (formLine) => formLine.value
			}
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.NEGATIVES,
			label: dictionary.get('ReviewForm.reviewNegatives'),
			placeholder: dictionary.get('ReviewForm.reviewNegativesPlaceholder'),
			value: reviewData[FORM_LINES_NAMES.NEGATIVES],
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.NEGATIVES]: value }),
				isFormLineValid: (formLine) => formLine.value
			}
		}
	])
}

export const validateFormLines = (allFormLineEntities = []) => {
	const invalidFormLines = allFormLineEntities.filter((formLine) => {
		const { required, extra: { isFormLineValid } = {} } = formLine

		if (required && !isFormLineValid) {
			/* eslint-disable */
			console.warn(`Required field "${formLine.id}" is missing "isFormLineValid" function.`)
			/* eslint-enable */
			return false
		} else if (isFormLineValid) {
			return required && !isFormLineValid(formLine, allFormLineEntities)
		} else {
			return false
		}
	})

	const isValid = !invalidFormLines.length

	return [isValid, invalidFormLines]
}
