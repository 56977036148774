import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import ShowItemsButton from 'app/component/showItemsButton/ShowItemsButton'
import ContinueButton from 'app/component/filters/components/continueButton/ContinueButtonView'
import FilterConstants from 'app/model/filter/FilterConstants'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'

import './FilterPopupButton.less'

const CLASSNAME = 'c-filter-popup-button'

export default class FilterPopupButton extends AbstractPureComponent {
	static get propTypes() {
		return {
			isOperatingLease: PropTypes.bool,
			filterPopupLogicData: PropTypes.object.isRequired,
			filterFormLogicData: PropTypes.object.isRequired,
			popupDefinition: PropTypes.object
		}
	}

	static get defaultProps() {
		return {
			isOperatingLease: false,
			filterPopupLogicData: DEFAULT_PROPS.OBJECT,
			filterFormLogicData: DEFAULT_PROPS.OBJECT,
			popupDefinition: DEFAULT_PROPS.OBJECT
		}
	}

	render() {
		const {
			isOperatingLease,
			popupDefinition,
			filterPopupLogicData,
			filterFormLogicData
		} = this.props

		const { closePopup, closePopupWithoutSavingChanges, canBePopupClosed } = filterPopupLogicData

		const {
			form: { validateForm },
			advertsCount: { filteredAdvertsCountTotal, isLoadingFilteredAdvertsCountTotal },
			other: { categoryEntity = {}, filterUrlParams }
		} = filterFormLogicData

		const { filterButtonType } = popupDefinition

		if (filterButtonType === FilterConstants.filterButtonType.SEARCH) {
			const getLinkUrl = (routeName, params) => {
				return this.link(routeName, Object.assign({}, params, filterUrlParams))
			}

			const { params, route } = this.utils.$Router.getCurrentRouteInfo()

			const isSellerRoute = [
				ROUTE_NAMES.USERWEB.SELLER,
				ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
			].includes(route.getName())

			let url

			if (isSellerRoute) {
				const company = params[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_OLD_ID]
				const category = params[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]
				const sellerParams = {
					[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID]:
						params[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID],
					[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_SEO_NAME]:
						params[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_SEO_NAME]
				}

				url = getLinkUrl(
					isOperatingLease
						? ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
						: ROUTE_NAMES.USERWEB.SELLER,
					Object.assign(
						{},
						sellerParams,
						company && { [SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_OLD_ID]: company },
						category && { [SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: category }
					)
				)
			} else {
				url = getLinkUrl(
					isOperatingLease
						? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
						: ROUTE_NAMES.USERWEB.ADVERT_LIST,
					{
						[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categoryEntity.seoName
					}
				)
			}

			return (
				<ShowItemsButton
					className={`${CLASSNAME}__show-items-button`}
					url={url}
					count={filteredAdvertsCountTotal}
					isLoading={isLoadingFilteredAdvertsCountTotal}
					disabled={!canBePopupClosed()}
					data-dot='show-adverts'
					data-e2e='filters-submit'
					onClick={() => {
						this.fire('resetCache')
						closePopupWithoutSavingChanges()
					}}
				/>
			)
		} else if (filterButtonType === FilterConstants.filterButtonType.CONTINUE) {
			return (
				<ContinueButton
					className={`${CLASSNAME}__continue-button`}
					disabled={!validateForm()}
					onClick={closePopup}
				/>
			)
		} else {
			return null
		}
	}
}
