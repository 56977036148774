import React, { useReducer, useRef, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Context from 'ima/page/context'
import { Input, Textarea, Button } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Popup, StatusMessage } from '@inzeraty/components'
import { FEEDBACK_TYPE, FEEDBACK_SUBJECT } from 'app/model/feedback/FeedbackConstants'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import IMAGES from 'app/base/ImagesConstants'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import Select from 'app/component/select/Select'
import Label from 'app/component/label/Label'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'

import './PopupFeedbackForm.less'
import './PopupFeedbackFormCS.json'

const CLASSNAME = 'c-popup-feedback'

const initialState = {
	// popup s formularem zpetne vazby
	isPopupOpen: false,
	formErrorMessage: '',
	isFormSubmiting: false,

	// vybrany smajlik
	emojiType: undefined,

	// select v cem se muzeme zlepsit
	isFeedbackSubjectVisible: false,
	feedbackSubject: undefined,
	feedbackSubjectErrorMessage: '',

	// textarea s presnujici zpetnou vazbou, kde se muze uzivatel rozepsat
	isFeedbackVisible: false,
	feedback: '',
	feedbackErrorMessage: '',

	// input s emailem
	email: '',
	emailErrorMessage: ''
}

const isFullFeedbackRequired = (emojiType) =>
	[FEEDBACK_TYPE.NEUTRAL, FEEDBACK_TYPE.BAD, FEEDBACK_TYPE.TERRIBLE].includes(emojiType)

const reducer = (state, action) => {
	switch (action.type) {
		case 'reset':
			return initialState
		case 'clearAllErrorMessages':
			return {
				...state,
				formErrorMessage: '',
				feedbackSubjectErrorMessage: '',
				feedbackErrorMessage: '',
				emailErrorMessage: ''
			}
		case 'setPopupOpen':
			return {
				...state,
				isPopupOpen: action.payload
			}
		case 'setFormSubmiting':
			return {
				...state,
				isFormSubmiting: action.payload
			}
		case 'setFormErrorMessage':
			return {
				...state,
				formErrorMessage: action.payload
			}
		case 'setEmoji': {
			const emojiType = action.payload

			return {
				...state,
				emojiType: action.payload,
				isFeedbackSubjectVisible: isFullFeedbackRequired(emojiType),
				isFeedbackVisible: isFullFeedbackRequired(emojiType)
			}
		}
		case 'setEmail':
			return {
				...state,
				email: action.payload,
				emailErrorMessage: ''
			}
		case 'setEmailErrorMessage':
			return {
				...state,
				emailErrorMessage: action.payload
			}
		case 'setFeedback':
			return {
				...state,
				feedback: action.payload,
				feedbackErrorMessage: ''
			}
		case 'setFeedbackErrorMessage':
			return {
				...state,
				feedbackErrorMessage: action.payload
			}
		case 'setFeedbackSubject':
			return {
				...state,
				feedbackSubject: action.payload,
				feedbackSubjectErrorMessage: ''
			}
		case 'setFeedbackSubjectErrorMessage':
			return {
				...state,
				feedbackSubjectErrorMessage: action.payload
			}
		default:
			return state
	}
}

const PopupFeedbackForm = ({ renderOpener }) => {
	const localize = useLocalize()
	const fire = useFire()
	const { $Dispatcher } = useContext(Context)

	const userEntity = useContext(UserEntityContext)
	const [state, dispatch] = useReducer(reducer, initialState)

	const dictionary = useRef({
		close: localize('PopupFeedbackForm.ariaClose')
	})

	useEffect(() => {
		// automaticke predvyplneni emailu podle prihlaseneho uzivatele
		if (state.isPopupOpen && !state.email && userEntity && userEntity.login) {
			dispatch({
				type: 'setEmail',
				payload: userEntity.login
			})
		}
	}, [state.isPopupOpen])

	useEffect(() => {
		// vymazani chybovych zprav po zavreni popupu. Popup nechavame v DOMu pro pripad, ze
		// uzivatel vyplni zpetnou vazbu a omylem treba okno zavre, tak at neprijde o vyplneny
		// text. Data z formularem se mazou az po uspesnem odeslani.
		if (!state.isPopupOpen) {
			dispatch({ type: 'clearAllErrorMessages' })
		}
	}, [state.isPopupOpen])

	const validateForm = () => {
		const isFullFeedback = isFullFeedbackRequired(state.emojiType)

		const additionalFields = {
			feedbackSubject: [
				state.feedbackSubject,
				() =>
					dispatch({
						type: 'setFeedbackSubjectErrorMessage',
						payload: localize('PopupFeedbackForm.feedbackSubjectError')
					})
			],
			feedback: [
				(state.feedback || '').trim(),
				() =>
					dispatch({
						type: 'setFeedbackErrorMessage',
						payload: localize('PopupFeedbackForm.feedbackTextError')
					})
			]
		}

		const formFields = Object.assign(
			{
				email: [
					(state.email || '').trim(),
					() =>
						dispatch({
							type: 'setEmailErrorMessage',
							payload: localize('PopupFeedbackForm.emailError')
						})
				]
			},
			isFullFeedback ? additionalFields : undefined
		)

		let isValid = true

		Object.values(formFields).forEach(([value, showError] = []) => {
			if (!value) {
				isValid = false
				showError && showError()
			}
		})

		return isValid
	}

	const handleEmojiClick = (emojiType) => {
		dispatch({
			type: 'setEmoji',
			payload: emojiType
		})
	}

	const handleEmailChange = (event) => {
		dispatch({
			type: 'setEmail',
			payload: event.target.value
		})
	}

	const handleFeedbackTextChange = (event) => {
		dispatch({
			type: 'setFeedback',
			payload: event.target.value
		})
	}

	const handleFeedbackSubjectSelect = (selectedItemId) => {
		dispatch({
			type: 'setFeedbackSubject',
			payload: selectedItemId
		})
	}

	const setPopupOpen = (isOpen) => {
		dispatch({
			type: 'setPopupOpen',
			payload: isOpen
		})
	}

	const openPopup = () => setPopupOpen(true)
	const closePopup = () => setPopupOpen(false)

	const handleSuccessSubmit = () => {
		closePopup()
		dispatch({ type: 'reset' })

		$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			title: localize('PopupFeedbackForm.formSubmitSuccessHeader'),
			text: localize('PopupFeedbackForm.formSubmitSuccess'),
			type: StatusMessage.TYPE.SUCCESS
		})
	}

	const handleErrorSubmit = () => {
		dispatch({ type: 'setFormSubmiting', payload: false })
		dispatch({
			type: 'setFormErrorMessage',
			payload: localize('PopupFeedbackForm.formSubmitError')
		})
	}

	const handleSubmit = (event) => {
		event.preventDefault()

		dispatch({ type: 'setFormSubmiting', payload: true })

		const isValid = validateForm()

		if (isValid) {
			dispatch({ type: 'setFormErrorMessage', payload: '' })

			const additionalFormData = isFullFeedbackRequired(state.emojiType)
				? {
						feedback_subject: state.feedbackSubject,
						feedback_text: state.feedback
				  }
				: {}

			const formData = {
				email: state.email,
				feedback_type: state.emojiType,
				...additionalFormData
			}

			fire('feedbackSubmit', {
				formData,
				onSuccessCallback: handleSuccessSubmit,
				onErrorCallback: handleErrorSubmit
			})
		} else {
			dispatch({ type: 'setFormSubmiting', payload: false })
		}
	}

	const renderEmojis = () => {
		const emojis = [
			{ type: FEEDBACK_TYPE.EXCELLENT },
			{ type: FEEDBACK_TYPE.GOOD },
			{ type: FEEDBACK_TYPE.NEUTRAL },
			{ type: FEEDBACK_TYPE.BAD },
			{ type: FEEDBACK_TYPE.TERRIBLE }
		]

		return (
			<div className={`${CLASSNAME}__satisfaction-container`}>
				<div className={`${CLASSNAME}__satisfaction-text`}>
					{localize('PopupFeedbackForm.satisfaction')}
				</div>
				<div className={`${CLASSNAME}__emojis-container`}>
					{emojis.map((emojiProps) => {
						const { type } = emojiProps

						return (
							<Emoji
								{...emojiProps}
								key={type}
								isSelected={state.emojiType === type}
								data-e2e={type}
								onClick={handleEmojiClick}
							/>
						)
					})}
				</div>
			</div>
		)
	}

	const renderEmailInput = () => {
		const elementId = 'email'
		const error = state.emailErrorMessage

		return (
			<div>
				<Label htmlFor={elementId} isRequired={true}>
					{localize('PopupFeedbackForm.emailLabel')}
				</Label>
				<Input
					className={`${CLASSNAME}__input`}
					id={elementId}
					size='small'
					value={state.email}
					error={!!error}
					onChange={handleEmailChange}
					placeholder={localize('PopupFeedbackForm.emailPlaceholder')}
				/>
				{error && <ErrorMessage text={error} />}
			</div>
		)
	}

	const renderFeedbackSubjectSelect = () => {
		const items = [
			{
				name: localize('PopupFeedbackForm.feedbackSubjectAdvertInfo'),
				id: FEEDBACK_SUBJECT.ADVERT_INFO
			},
			{
				name: localize('PopupFeedbackForm.feedbackSubjectAdvertSearchAndFiltering'),
				id: FEEDBACK_SUBJECT.ADVERT_SEARCH_AND_FILTERING
			},
			{
				name: localize('PopupFeedbackForm.feedbackSubjectIrrelevantSearchResults'),
				id: FEEDBACK_SUBJECT.IRRELEVANT_SEARCH_RESULTS
			},
			{
				name: localize('PopupFeedbackForm.feedbackSubjectWebClarity'),
				id: FEEDBACK_SUBJECT.WEB_CLARITY
			},
			{
				name: localize('PopupFeedbackForm.feedbackSubjectTechnicalIssues'),
				id: FEEDBACK_SUBJECT.TECHNICAL_ISSUES
			},
			{
				name: localize('PopupFeedbackForm.feedbackSubjectOther'),
				id: FEEDBACK_SUBJECT.OTHER
			}
		]
		const elementId = 'subject-select'
		const error = state.feedbackSubjectErrorMessage

		const renderItem = ({ id, name } = {}) => <span data-e2e={id}>{name}</span>

		return (
			<div className={`${CLASSNAME}__select-container`}>
				<Label htmlFor={elementId} isRequired={true}>
					{localize('PopupFeedbackForm.feedbackSubjectLabel')}
				</Label>
				<Select
					inputId={elementId}
					placeholder={localize('PopupFeedbackForm.feedbackSubjectPlaceholder')}
					size='small'
					items={items}
					hasError={!!error}
					onSelect={({ id } = {}) => handleFeedbackSubjectSelect(id)}
					selectedItem={items.find(({ id }) => id === state.feedbackSubject) || null}
					renderItem={renderItem}
				/>
				{error && <ErrorMessage text={error} />}
			</div>
		)
	}

	const renderFeedbackTextarea = () => {
		const elementId = 'feedback-text'
		const error = state.feedbackErrorMessage

		return (
			<div className={`${CLASSNAME}__textarea-container`}>
				<Label htmlFor={elementId} isRequired={true}>
					{localize('PopupFeedbackForm.feedbackTextLabel')}
				</Label>
				<Textarea
					className={`${CLASSNAME}__textarea`}
					id={elementId}
					value={state.feedback}
					error={!!error}
					onChange={handleFeedbackTextChange}
					placeholder={localize('PopupFeedbackForm.feedbackTextPlaceholder')}
				/>
				{error && <ErrorMessage text={error} />}
			</div>
		)
	}

	const renderButtons = () => {
		const isSubmitButtonEnabled = !!state.emojiType && !state.isFormSubmiting

		return (
			<div className={`${CLASSNAME}__buttons-container`}>
				<Button
					className={`${CLASSNAME}__close-button`}
					primary={false}
					text={localize('PopupFeedbackForm.close')}
					data-dot='close'
					onClick={closePopup}
				/>
				<Button
					primary={true}
					type='submit'
					loading={state.isFormSubmiting}
					disabled={!isSubmitButtonEnabled}
					text={localize('PopupFeedbackForm.submit')}
					data-dot='submit'
				/>
			</div>
		)
	}

	return (
		<>
			{renderOpener({ onClick: () => openPopup() })}
			{state.isPopupOpen && (
				<div className={CLASSNAME}>
					<Popup
						isOpen={true}
						onClose={closePopup}
						isClosedOnClickOutside={false}
						dictionary={dictionary.current}
						title={localize('PopupFeedbackForm.title')}
						hasLightbox={true}
						stickyHeader={true}
					>
						<form className={`${CLASSNAME}__form`} data-dot='feedback' onSubmit={handleSubmit}>
							{state.formErrorMessage && (
								<StatusMessage
									className={`${CLASSNAME}__status-message`}
									type={StatusMessage.TYPE.ERROR}
									title={state.formErrorMessage}
								/>
							)}

							{renderEmojis()}
							{state.isFeedbackSubjectVisible && renderFeedbackSubjectSelect()}
							{state.isFeedbackVisible && renderFeedbackTextarea()}
							{renderEmailInput()}

							{renderButtons()}
						</form>
					</Popup>
				</div>
			)}
		</>
	)
}

PopupFeedbackForm.propTypes = {
	renderOpener: PropTypes.func
}

PopupFeedbackForm.defaultProps = {
	renderOpener: DEFAULT_PROPS.FUNCTION
}

export default PopupFeedbackForm

const Emoji = ({ type, isSelected = false, onClick, ...restProps }) => {
	const localize = useLocalize()

	const LABELS = {
		[FEEDBACK_TYPE.EXCELLENT]: localize('PopupFeedbackForm.excellent'),
		[FEEDBACK_TYPE.GOOD]: localize('PopupFeedbackForm.good'),
		[FEEDBACK_TYPE.NEUTRAL]: localize('PopupFeedbackForm.neutral'),
		[FEEDBACK_TYPE.BAD]: localize('PopupFeedbackForm.bad'),
		[FEEDBACK_TYPE.TERRIBLE]: localize('PopupFeedbackForm.terrible')
	}

	const ICONS = {
		[FEEDBACK_TYPE.EXCELLENT]: [
			IMAGES['image__emoji-excellent'],
			IMAGES['image__emoji-excellent-gray']
		],
		[FEEDBACK_TYPE.GOOD]: [IMAGES['image__emoji-good'], IMAGES['image__emoji-good-gray']],
		[FEEDBACK_TYPE.NEUTRAL]: [IMAGES['image__emoji-neutral'], IMAGES['image__emoji-neutral-gray']],
		[FEEDBACK_TYPE.BAD]: [IMAGES['image__emoji-bad'], IMAGES['image__emoji-bad-gray']],
		[FEEDBACK_TYPE.TERRIBLE]: [
			IMAGES['image__emoji-terrible'],
			IMAGES['image__emoji-terrible-gray']
		]
	}

	const label = LABELS[type] || ''
	const [srcNormalEmoji = '', srcGrayEmoji = ''] = ICONS[type] || []

	const handleOnClick = (event) => {
		event.preventDefault()
		onClick && onClick(type)
	}

	return (
		<button
			className={`${CLASSNAME}__emoji-button`}
			title={label}
			aria-label={label}
			type='button'
			onClick={handleOnClick}
			{...restProps}
		>
			{/* nacitame zaroven obe verze ikonky, jinak by pri prepinani dochazelo k probliku */}
			<img
				className={classNames({
					[`${CLASSNAME}__emoji-img`]: true,
					[`${CLASSNAME}__emoji-img--hidden`]: isSelected
				})}
				src={srcGrayEmoji}
				alt=''
			/>
			<img
				className={classNames({
					[`${CLASSNAME}__emoji-img`]: true,
					[`${CLASSNAME}__emoji-img--hidden`]: !isSelected
				})}
				src={srcNormalEmoji}
				alt=''
			/>
		</button>
	)
}

Emoji.propTypes = {
	type: PropTypes.string.isRequired,
	isSelected: PropTypes.bool,
	onClick: PropTypes.func
}

const ErrorMessage = ({ text = '' }) => {
	return <div className={`${CLASSNAME}__error-message`}>{text}</div>
}

ErrorMessage.propTypes = {
	text: PropTypes.string.isRequired
}
