
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['InfoPopup'] = {
  lowest_info: function(d) { return "Cena je výrazně pod běžnou cenou. Inzeráty mohou obsahovat v titulku či popisku závady či jiné vlastnosti."; },
  low_info: function(d) { return "Cena je mírně pod současnou běžnou cenou."; },
  regular_info: function(d) { return "Cena, která se nejčastěji objevuje při srovnání podobných inzerátů."; },
  high_info: function(d) { return "Cena je mírně nad současnou běžnou cenou."; },
  highest_info: function(d) { return "Cena je výrazně nad současnou běžnou cenou. Vozidla mohou obsahovat nadstandardní výbavu, která není v atributech inzerátu uvedena, nebo i jiné vlastnosti. Podívejte se pozorněji na popis inzerátu nebo se rovnou zeptejte prodejce."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	