import React, { useState, useContext } from 'react'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import ClientAdvertListUrlConvertor from '../ClientAdvertListUrlConvertor'
import StatusTabs from '../statusTabs/StatusTabsView'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import CreateAdvertButton from '../createAdvertButton/CreateAdvertButton'
import { Checkbox } from '@sznds/react'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import SortButton from 'app/component/sortButton/SortButtonView'
import PerPageButton from 'app/component/perPageButton/PerPageButtonView'
import Context from 'ima/page/context'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import Select from 'app/component/select/Select'
import SearchInput from 'app/component/searchInput/SearchInput'
import CreateAdvertLink from '../createAdvertLink/CreateAdvertLink'
import FiltersOpener from '../premiseFilters/PremiseFiltersOpener'
import PropTypes from 'prop-types'
import PremiseHeaderAndSlots from 'app/component/premiseHeaderAndSlots/PremiseHeaderAndSlots'
import * as FormLines from '@inzeraty/form-lines'
import ROUTE_NAMES from 'app/base/RouteNames'
import TextLink from 'app/component/textLink/TextLink'
import ClientAdminSortConstants from '../ClientAdminSortConstants'

import './ListHeaderCS.json'
import './ListHeader.less'

const CLASSNAME = 'c-client-item-list-header'

const PremiseHeader = ({
	isLoading,
	advertListData,
	selectedAdvertsCount,
	premiseFiltersFormLines,
	changePremiseFiltersFormLines,
	loadPremiseAdvertsTotal,
	loadAllBrandsForCategory,
	loadAllModelsForBrand,
	bannedAdverts
}) => {
	const { $Router } = useContext(Context)
	const localize = useLocalize()
	const fire = useFire()

	const {
		params: { [UrlConvertor.constants.URL_APP_PARAMS.SORT]: advertListSortParam }
	} = $Router.getCurrentRouteInfo()
	const { paginationEntity, advertList } = advertListData
	const { total = 0, offset } = paginationEntity
	const allChecked = advertList.length && advertList.length === selectedAdvertsCount

	const { URL_APP_PARAMS } = ClientAdvertListUrlConvertor.constants
	const { params: routeParams } = $Router.getCurrentRouteInfo()

	const filterByItems = [
		{
			name: localize('ListHeader.filterById'),
			id: URL_APP_PARAMS.ADVERT_ID
		},
		{
			name: localize('ListHeader.filterByName'),
			id: URL_APP_PARAMS.ADVERT_NAME
		},
		{
			name: localize('ListHeader.filterByVin'),
			id: URL_APP_PARAMS.ADVERT_VIN
		},
		{
			name: localize('ListHeader.filterByImportId'),
			id: URL_APP_PARAMS.IMPORT_ID
		}
	]

	const selectedFilterByItem =
		filterByItems.find((item) => item.id === routeParams[URL_APP_PARAMS.FILTER_BY]) ||
		filterByItems[0]

	const [filterBy, setFilterBy] = useState(selectedFilterByItem && selectedFilterByItem.id)

	return (
		<>
			<div>
				<PremiseHeaderAndSlots />
				<div className={`${CLASSNAME}__premise-actions`}>
					<div className={`${CLASSNAME}__premise-search`} data-dot='search'>
						<div className={`${CLASSNAME}__select-box`} data-dot='select-item'>
							<Select
								onSelect={(selectedItem = {}) => setFilterBy(selectedItem.id)}
								selectedItem={filterByItems.find((item) => item.id === filterBy) || null}
								items={filterByItems}
								size='small'
							/>
						</div>
						<div className={`${CLASSNAME}__search-input`}>
							<SearchInput
								inputValue={routeParams[URL_APP_PARAMS.FILTER_BY_VALUE] || ''}
								onSubmit={(filterByValue) =>
									fire('selectFilterSubmit', {
										filterBy,
										filterByValue
									})
								}
							/>
						</div>
					</div>
					<div className={`${CLASSNAME}__premise-additional`}>
						<div className={`${CLASSNAME}__create-link`} data-dot='new-advert'>
							<CreateAdvertLink />
						</div>
						<div data-dot='filters'>
							<FiltersOpener
								premiseFiltersFormLines={premiseFiltersFormLines}
								changePremiseFiltersFormLines={changePremiseFiltersFormLines}
								total={total}
								isLoadingTotal={isLoading}
								loadPremiseAdvertsTotal={loadPremiseAdvertsTotal}
								loadAllBrandsForCategory={loadAllBrandsForCategory}
								loadAllModelsForBrand={loadAllModelsForBrand}
							/>
						</div>
					</div>
				</div>
			</div>
			<BanStatusMessage bannedAdverts={bannedAdverts} />
			{!isLoading && advertList && advertList.length > 0 && (
				<div className={`${CLASSNAME}__header-line ${CLASSNAME}__list-header`} data-dot='adverts'>
					<div className={`${CLASSNAME}__list-info`}>
						<Checkbox
							className={`${CLASSNAME}__checkbox`}
							checked={allChecked}
							data-dot='select-all'
							data-dot-data={`{"checked": "${!allChecked}"}`}
							onChange={(event) => {
								event.stopPropagation()

								if (allChecked) {
									fire('deselectAll')
								} else {
									fire('selectAll')
								}
							}}
							label={
								<span className={`${CLASSNAME}__accessible-label`}>
									{localize(`ListHeader.${allChecked ? 'deselect' : 'select'}`)}
								</span>
							}
						/>
						<span className={`${CLASSNAME}__count`}>
							{localize('ListHeader.premiseCount', {
								FROM: Format.number(offset + 1),
								TO: Format.number(offset + advertList.length),
								TOTAL: Format.number(total)
							})}
						</span>
					</div>
					<PerPageButton className={`${CLASSNAME}__header-btn`} />
					<SortButton
						className={`${CLASSNAME}__header-btn`}
						advertListSortParam={advertListSortParam}
						sortConstants={ClientAdminSortConstants}
					/>
				</div>
			)}
		</>
	)
}

const RegularHeader = ({ isLoading, advertListData, filterStatus, bannedAdverts }) => {
	const localize = useLocalize()

	const { paginationEntity, advertList } = advertListData
	const { total = 0 } = paginationEntity
	const hasItems = (paginationEntity && total) || filterStatus !== ''

	return (
		<>
			<div className={`${CLASSNAME}__header-line`}>
				<h1 className={`${CLASSNAME}__title`}>{localize('ListHeader.header')}</h1>
				{hasItems && <CreateAdvertButton className={`${CLASSNAME}__create-btn`} />}
			</div>
			<BanStatusMessage bannedAdverts={bannedAdverts} />
			<div className={`${CLASSNAME}__subheader`}>
				<div className={`${CLASSNAME}__count`}>
					{!isLoading &&
						advertList &&
						advertList.length > 0 &&
						localize('ListHeader.count', { COUNT: Format.number(total) })}
				</div>
				{hasItems && <StatusTabs className={`${CLASSNAME}__filter`} filterStatus={filterStatus} />}
			</div>
		</>
	)
}

function BanStatusMessage({ bannedAdverts = DEFAULT_PROPS.OBJECT }) {
	const { $Router } = useContext(Context)
	const localize = useLocalize()
	const { advertList = [] } = bannedAdverts

	if (advertList.length) {
		const bannedAdvertsForSale = advertList.filter(
			(advert) => advert.dealType !== DEAL_TYPE.OPERATING_LEASE
		)
		const bannedAdvertsForOperatingLeaseOnly = advertList.filter(
			(advert) => advert.dealType === DEAL_TYPE.OPERATING_LEASE
		)

		const bannedAdvertsForSaleUrlParams = {
			[ClientAdvertListUrlConvertor.constants.URL_APP_PARAMS.ADVERT_STATUS]:
				ClientAdvertListUrlConvertor.advertStatus.BAN.APP
		}
		const bannedAdvertsForOperatingLeaseOnlyUrlParams = {
			[ClientAdvertListUrlConvertor.constants.URL_APP_PARAMS.ADVERT_STATUS]:
				ClientAdvertListUrlConvertor.advertStatus.BAN.APP,
			[ClientAdvertListUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE]:
				ClientAdvertListUrlConvertor.dealType.OPERATING_LEASE.APP
		}

		const showBothLinks = bannedAdvertsForSale.length && bannedAdvertsForOperatingLeaseOnly.length

		return (
			<StatusMessage
				type={StatusMessage.TYPE.ERROR}
				title={localize('ListHeader.banStatusTitle')}
				className={`${CLASSNAME}__ban-status`}
				onClose={DEFAULT_PROPS.FUNCTION}
			>
				<p>{localize('ListHeader.banStatusText')}</p>
				{showBothLinks ? (
					<>
						<TextLink
							href={$Router.link(
								ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST,
								bannedAdvertsForSaleUrlParams
							)}
							className={`${CLASSNAME}__link`}
						>
							{localize('ListHeader.banStatusLinkRichSaleText')}
						</TextLink>
						{localize('ListHeader.banStatusLinkRichSeparator')}
						<TextLink
							href={$Router.link(
								ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST,
								bannedAdvertsForOperatingLeaseOnlyUrlParams
							)}
							className={`${CLASSNAME}__link`}
						>
							{localize('ListHeader.banStatusLinkRichOperatingLeaseText')}
						</TextLink>
					</>
				) : (
					<TextLink
						href={$Router.link(
							ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST,
							bannedAdvertsForOperatingLeaseOnly.length
								? bannedAdvertsForOperatingLeaseOnlyUrlParams
								: bannedAdvertsForSaleUrlParams
						)}
						className={`${CLASSNAME}__link`}
					>
						{localize('ListHeader.banStatusLink')}
					</TextLink>
				)}
			</StatusMessage>
		)
	} else {
		return null
	}
}

BanStatusMessage.propTypes = {
	bannedAdverts: PropTypes.shape({
		advertList: PropTypes.arrayOf(PropTypes.shape(advertPropTypes)),
		paginationEntity: PropTypes.object
	})
}

export default function ListHeader({ isPremise, ...props }) {
	return (
		<div className={CLASSNAME}>
			{isPremise ? <PremiseHeader {...props} /> : <RegularHeader {...props} />}
		</div>
	)
}

ListHeader.propTypes = {
	isPremise: PropTypes.bool
}

RegularHeader.propTypes = {
	isLoading: PropTypes.bool,
	filterStatus: PropTypes.string,
	advertListData: PropTypes.shape({
		advertList: PropTypes.arrayOf(PropTypes.shape(advertPropTypes)),
		paginationEntity: PropTypes.object
	}),
	bannedAdverts: PropTypes.shape({
		advertList: PropTypes.arrayOf(PropTypes.shape(advertPropTypes)),
		paginationEntity: PropTypes.object
	})
}

PremiseHeader.propTypes = {
	isLoading: PropTypes.bool,
	advertListData: PropTypes.shape({
		advertList: PropTypes.arrayOf(PropTypes.shape(advertPropTypes)),
		paginationEntity: PropTypes.object
	}),
	selectedAdvertsCount: PropTypes.number,
	premiseFiltersFormLines: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	changePremiseFiltersFormLines: PropTypes.func,
	loadPremiseAdvertsTotal: PropTypes.func,
	loadAllBrandsForCategory: PropTypes.func,
	loadAllModelsForBrand: PropTypes.func,
	bannedAdverts: PropTypes.shape({
		advertList: PropTypes.arrayOf(PropTypes.shape(advertPropTypes)),
		paginationEntity: PropTypes.object
	})
}
