import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { Button } from '@sznds/react'

import './ContinueButtonCS.json'
import './ContinueButtonView.less'

const CLASSNAME = 'cf-continue'

export default class ContinueButtonView extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			disabled: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			className: '',
			disabled: false
		}
	}

	render() {
		const { className, disabled } = this.props

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				<Button
					className={`${CLASSNAME}__btn`}
					disabled={disabled}
					type='button'
					text={this.localize('ContinueButton.continue')}
					surface={4}
					onClick={(event) => {
						this.props.onClick(event)
					}}
					data-dot='submit'
					data-dot-data='{"type": "continue"}'
				/>
			</div>
		)
	}
}
