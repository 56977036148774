import AbstractComponent from 'app/base/AbstractComponent'
import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'app/component/layout/LayoutView'
import { CategoryEntity, ArticleEntity } from '@inzeraty/models'
import Filters from 'app/component/filters/FiltersView'
import Menu from 'app/page/userweb/home/menu/Menu'
import HomeHeader from 'app/page/userweb/home/homeHeader/HomeHeaderView'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import { Surface } from '@sznds/react'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import RouteNames from 'app/base/RouteNames'
import { DATA_SEOT } from 'app/base/SeoTesting'
import FilterConstants from 'app/model/filter/FilterConstants'
import CarTypes from 'app/page/userweb/home/carTypes/CarTypes'
import GarazArticles from 'app/component/garazArticles/GarazArticles'
import IMAGES from 'app/base/ImagesConstants'
import MiddleRectangle from 'app/component/sspAdverts/middleRectangle/MiddleRectangle'
import LastSearch from './lastSearch/LastSearch'
import STATE_KEYS from './HomeStateKeys'
import FavoritesLink from './favoritesLink/FavoritesLink'
import HPTitle from './hpTitle/HPTitle'
import MySavedSearchesLink from './mySavedSearchesLink/MySavedSearchesLink'

import './HomeView.less'
import './HomeCS.json'

const CLASSNAME = 'p-uw-homepage'

/**
 * Advert page
 * @class HomeView
 * @namespace app.page.userweb.home
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.page
 */
export default class HomeView extends AbstractComponent {
	static get propTypes() {
		return {
			[STATE_KEYS.ARTICLE_ENTITIES]: PropTypes.arrayOf(PropTypes.instanceOf(ArticleEntity)),
			categories: PropTypes.arrayOf(PropTypes.instanceOf(CategoryEntity)),
			[FiltersExtension.stateIds.CATEGORY]: PropTypes.instanceOf(CategoryEntity)
		}
	}

	componentDidMount() {
		document.body.setAttribute('data-dot', 'homepage')
	}

	componentWillUnmount() {
		document.body.removeAttribute('data-dot')
	}

	render() {
		const { categories, [FiltersExtension.stateIds.CATEGORY]: category } = this.props

		if (categories && category) {
			return (
				<Layout className={CLASSNAME}>
					{this._renderBrandsSeoLinks()}
					{this._renderContent()}
				</Layout>
			)
		}

		return null
	}

	_renderContent() {
		return (
			<>
				<Surface surface={4} className={`${CLASSNAME}__filter`} data-dot='filters-selection'>
					<div className={`${CLASSNAME}__filter-wrapper`}>
						<HomeHeader />
						<Menu />
						<Filters isOnHP={true} />
					</div>
				</Surface>
				<div className={`${CLASSNAME}__links`}>
					<LastSearch />
					<FavoritesLink />
					<MySavedSearchesLink />
				</div>
				<MiddleRectangle
					className={`${CLASSNAME}__middle-rect`}
					elementId='hpMiddleRectangle'
					zoneId={146540}
					width={1040}
					height={310}
					isOnHP={true}
				/>
				<CarTypes />
				<GarazArticles articleEntities={this.props.articleEntities} />
				{this._renderUsefulInformations()}
			</>
		)
	}

	_renderUsefulInformations() {
		return (
			<div className={`${CLASSNAME}__useful`} data-dot='useful-links'>
				<HPTitle>{this.localize('Home.usefulInformations')}</HPTitle>
				<div className={`${CLASSNAME}__useful-links`}>
					{this._renderUsefulLink(
						this.link(RouteNames.USERWEB.ARTICLES),
						this.localize('Home.autoMotoArticles'),
						IMAGES['image__useful-auto-moto-articles']
					)}
					{this._renderUsefulLink(
						this.link(RouteNames.USERWEB.CATALOG),
						this.localize('Home.catalog'),
						IMAGES['image__useful-catalog']
					)}
					{this._renderUsefulLink(
						this.link(RouteNames.USERWEB.SELLER_LIST),
						this.localize('Home.sellerList'),
						IMAGES['image__useful-seller-list']
					)}
				</div>
			</div>
		)
	}

	_renderUsefulLink(link, text, imageSrc) {
		return (
			<Surface
				surface={5}
				tagName='a'
				className={`${CLASSNAME}__useful-link`}
				href={link}
				data-dot='item'
				data-dot-data={`{"value": "${link}"}`}
			>
				<img src={imageSrc} className={`${CLASSNAME}__useful-icon`} alt='' />
				<span className={`${CLASSNAME}__useful-text`}>{text}</span>
			</Surface>
		)
	}

	_renderBrandsSeoLinks() {
		const {
			seoBrandsWithAdvertsValues = {},
			[FiltersExtension.stateIds.CATEGORY]: category,
			filterFormLineEntities = []
		} = this.props

		const { counts: brandsCount = {} } = seoBrandsWithAdvertsValues

		if (Object.keys(brandsCount).length > 0) {
			const { options = [] } =
				filterFormLineEntities.find(
					(widget) => widget.id === FilterConstants.formLineIds.BRAND_MODEL
				) || {}

			return (
				<div data-seoth={DATA_SEOT.BRANDS_LINKS}>
					{options
						.filter(({ value }) => brandsCount[value] > 0)
						.map(({ name: brandName, seoName: brandSeoName }) => {
							const url = this.link(RouteNames.USERWEB.ADVERT_LIST, {
								[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: category.seoName,
								[AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: brandSeoName
							})

							return (
								<a key={url} href={url}>
									{brandName}
								</a>
							)
						})}
				</div>
			)
		}
	}
}
