
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Tooltip'] = {
  ariaClose: function(d) { return "Zavřít"; },
  blindClosePrefix: function(d) { return "Konec tooltipu - "; },
  ariaOpener: function(d) { return "Nápověda k formulářovému prvku "; },
  ariaTitle: function(d) { return "Nápověda zní: "; },
  ariaDescribe: function(d) { return "Nápovědu najdete na tlačítku před formulářovým prvkem"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	