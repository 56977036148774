
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['ClientStatistics'] = {
  heading: function(d) { return "Statistiky"; },
  sale: function(d) { return "Prodej"; },
  operatingLease: function(d) { return "Operativní leasing"; },
  mirroring: function(d) { return "Zrcadlení"; },
  graphHeader: function(d) { return "Vývoj v čase"; },
  listView: function(d) { return "Zobrazení na výpise"; },
  replySent: function(d) { return "Odeslání formuláře"; },
  phoneView: function(d) { return "Telefon – Zobrazit / Volat"; },
  detailView: function(d) { return "Detail inzerátu"; },
  detailViewFromList: function(d) { return "Detail inzerátu – výpis"; },
  detailViewFromOther: function(d) { return "Detail inzerátu – neznám"; },
  POView: function(d) { return "Zobrazení na výpise – PV"; },
  detailViewFromPO: function(d) { return "Detail inzerátu – PV"; },
  pagingSummary: function(d) { return "z " + plural(d.TOTAL, 0, cs, { one: d.TOTAL_FORMATTED + " inzerátu", other: d.TOTAL_FORMATTED + " inzerátů" }); },
  advertId: function(d) { return "Id inzerátu"; },
  customId: function(d) { return "Id importu"; },
  vin: function(d) { return "VIN"; },
  noDataAvailable: function(d) { return "Nemůžeme najít žádné data"; },
  noDataAvailableDescription: function(d) { return "Zkuste změnit nastavení filtrů ve vašem hledání"; },
  error: function(d) { return "Někde se stala chyba, nemůžeme najít všechna data"; },
  errorAction: function(d) { return "Načíst chybějící data znovu"; },
  toppedPrice: function(d) { return "Cena za topování s DPH"; },
  toppedCount: function(d) { return "Počet topování"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	