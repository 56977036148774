import UserwebBaseController from 'app/base/UserwebBaseController'

export default class PromoCebiaController extends UserwebBaseController {
	constructor(dependenciesHelper, backToHpExtension) {
		super(dependenciesHelper)
		this._backToHpExtension = backToHpExtension
	}

	init() {
		super.init()

		this.addExtension(this._backToHpExtension)
	}

	getDataForSsp() {
		return {}
	}
}
