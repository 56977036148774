import { AdvertResource as AdvertResourceBase } from '@inzeraty/models'

/**
 * @class AdvertResource
 * @namespace models.advert
 * @module models
 */
export default class AdvertResource extends AdvertResourceBase {
	/**
	 * Ulozi cebia certifikat k inzerátu s daným id.
	 *
	 * @method saveCebiaCert
	 * @public
	 * @param {Number|String}	id		Id inzerátu
	 * @param {Object}			data	Form data
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	saveCebiaCert(id, data = {}) {
		return this._restClient.post(`${this.RESOURCE}/${id}/cebia_report`, data)
	}

	/**
	 * Ulozi kupon cebia certifikat k inzerátu s daným id.
	 *
	 * @method saveCebiaCoupon
	 * @public
	 * @param {Number|String}	id		Id inzerátu
	 * @param {Object}			data	Form data
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	saveCebiaCoupon(id, data = {}) {
		return this._restClient.post(`${this.RESOURCE}/${id}/cebia_coupon`, data)
	}

	/**
	 * Ulozi URL kuponu cebia certifikat k inzerátu s daným id.
	 *
	 * @method saveCebiaSmartCodeUrl
	 * @public
	 * @param {Number|String}	id		Id inzerátu
	 * @param {Object}			data	Form data
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	saveCebiaSmartCodeUrl(id, data = {}) {
		return this._restClient.post(`${this.RESOURCE}/${id}/cebia_smart_code_url`, data)
	}

	/**
	 * Vrátí podobné inzeráty k inzerátu s daným id.
	 *
	 * @method getRelated
	 * @public
	 * @param {Object}          params
	 * @param {Object}			options
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	getRelated(params = {}, options = {}) {
		const { id, ...restParams } = params
		return this._restClient.get(`${this.RESOURCE}/${id}/related`, restParams, options)
	}

	/**
	 * Vraci data o historii zmen provedenych pri editaci inzeratu.
	 *
	 * @method getAdvertHistory
	 * @public
	 * @param {Number|String} advertId
	 * @param {Object}        params
	 * @param {Object}        options
	 * @return {Promise<JSON>}
	 */
	getAdvertHistory(advertId, params = {}, options = {}) {
		return this._restClient.get(`${this.RESOURCE}/${advertId}/history`, params, options)
	}

	/**
	 * Upravy inzeraty podla zadanych parametrov
	 *
	 * @param {Object} params
	 * @param {Object} options
	 */
	updateAdvert(params, options) {
		return this._restClient.post(`${this.RESOURCE}/update`, params, options)
	}

	/** Vrati inzeraty z prednostneho vypisu
	 *
	 * @method getPreferred
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 */
	getPreferred(params = {}, options = {}) {
		return this._restClient.get(`${this.RESOURCE}/preferred`, params, options)
	}

	getMTLPPrices(params = {}, options = {}) {
		return this._restClient.get('/mtpl/prices', params, options)
	}
}
