import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { createEntityList } from '@inzeraty/form-lines'
import {
	EQUIPMENT_CB,
	CATEGORIES as EQUIPMENT_CATEGORIES
} from 'app/page/userweb/newAdvert/equipment/EquipmentConstants'
import { CATEGORIES } from 'app/base/Constants'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { nonValue, nonValueOption } from 'app/component/entitiesFilter/EntitiesFilter'
import { Validation } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import { HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'

import 'app/page/userweb/newAdvert/equipment/components/widgets/equipment/EquipmentWidgetCS.json'

export const ACTIONS = {
	ADD_VALUE_TO_SEARCH: 'codebookValueAddToSearch',
	REMOVE_VALUE_FROM_SEARCH: 'codebookValueRemoveFromSearch',
	DELETE_VALUE: 'codebookValueDalete',
	OPEN_EDIT_POPUP: 'openEditPopup',
	CLOSE_EDIT_POPUP: 'closeEditPopup'
}

const booleanOptions = [
	{
		value: true,
		name: 'Ano'
	},
	{
		value: false,
		name: 'Ne'
	}
]

export default class IACodebookEquipmentListController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, entitiesFilterExtension, codebooksService, entityFormExtension) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._codebooksService = codebooksService
		this._entityFormExtension = entityFormExtension

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)

		this._successMessage = ''
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)

		this.addExtension(this._entityFormExtension)

		this._entityFormExtension.initialize({
			getSubmitPromise: this._getEditFormSubmitPromise.bind(this),
			validations: this._editFormValidations.bind(this),
			handleSubmitSuccess: this._handleEditFormSubmitSuccess.bind(this),
			handleSubmitError: this._handleEditFormSubmitError.bind(this)
		})
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isEditPopupOpened: false
		})
	}

	update() {
		return {
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		}
	}

	async getEntitiesAndPagination() {
		const fetchCodebooksList = async (params) => {
			try {
				const { entities, paginationEntity } = await this._codebooksService.getCodebooksValues(
					params
				)

				return {
					entities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const pageLoaderId = this._pageLoaderExtension.show()

		const routeParams = this.getRouteParams()
		const params = Object.assign(
			{
				[InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: EQUIPMENT_CB
			},
			InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)
		)

		if (!params.sort) {
			params.sort = 'name'
		}

		const entitiesAndPagination = await fetchCodebooksList(params)

		this._pageLoaderExtension.hide(pageLoaderId)

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	async _handleAction({ action, entities = [] }) {
		const [entity = {}] = entities
		const { value, codebookCategoryId, codebookCategoryName, name } = entity

		let promise
		let getErrorMessage = () => ''

		switch (action) {
			case ACTIONS.OPEN_EDIT_POPUP:
				this._entityFormExtension.setFormLineEntitiesData([])

				this.setState({
					isEditPopupOpened: true,
					entityFormError: null,
					isWaiting: true
				})

				this._entityFormExtension.setFormLineEntitiesData(
					await this._getFormLineEntitiesForEditForm(entity)
				)

				this.setState({
					isWaiting: false
				})
				return {}

			case ACTIONS.CLOSE_EDIT_POPUP:
				this.setState({
					isEditPopupOpened: false
				})
				this._entityFormExtension.setFormLineEntitiesData([])
				return {}

			case ACTIONS.ADD_VALUE_TO_SEARCH:
				promise = this._changeForFilters(entity, true)
				getErrorMessage = () =>
					`Nepodařilo se přidat do vyhledávání číselník "${name}" pro kategorii "${codebookCategoryName}"`
				break

			case ACTIONS.REMOVE_VALUE_FROM_SEARCH:
				promise = this._changeForFilters(entity, false)
				getErrorMessage = () =>
					`Nepodařilo se odebrat z vyhledávání číselník "${name}" pro kategorii "${codebookCategoryName}"`
				break

			case ACTIONS.DELETE_VALUE:
				if (window.confirm(`Opravdu si přejete smazat číselník "${name}"`)) {
					const urlParams = {
						[InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: EQUIPMENT_CB,
						[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]: codebookCategoryId
					}
					promise = this._codebooksService.deleteCodebooksValues(value, urlParams)
					getErrorMessage = (error) => {
						if (this._isHasAdvertError(error)) {
							return `Nepodařilo se smazat číselník "${name}", protože je použit u inzerátu`
						} else {
							return `Nepodařilo se smazat číselník "${name}"`
						}
					}
				} else {
					return {}
				}
				break
		}

		return {
			promise,
			getErrorMessage
		}
	}

	_isHasAdvertError(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)
		const { body: { errors = [] } = {} } = HttpErrorHelper.getParams(error)
		const { error_code: errorCode } = errors[0] || {}

		return httpStatus === HttpStatusCodes.FORBIDDEN && errorCode === 'codebook_value_has_adverts'
	}

	async _filterInitiezerClb(routeParams) {
		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'name',
					name: 'Název výbavy'
				},
				{
					value: 'value',
					name: 'ID výbavy'
				}
			]),

			this._entitiesFilterExtension.getSelect(
				InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID,
				'Kategorie',
				'Vyberte kategorii',
				this._getCarCategories()
			),

			this._entitiesFilterExtension.getSelect(
				InternalAdminUrlConvertor.constants.CODEBOOKS_SECTION,
				'Skupina',
				'Vyberte skupinu',
				this._getEquipmentsCategories()
			),

			this._entitiesFilterExtension.getSelect(
				InternalAdminUrlConvertor.constants.CODEBOOKS_FOR_FILTERS,
				'Vyhledávání',
				'',
				booleanOptions
			)
		])
	}

	_getCarCategories() {
		return Object.values(CATEGORIES).map((category) => ({
			value: category.id,
			name: category.name
		}))
	}

	_getEquipmentsCategories() {
		return [...EQUIPMENT_CATEGORIES]
			.map((category) => {
				return {
					name: this._dictionary.get(`EquipmentWidget.${category}`),
					value: category
				}
			})
			.sort((a, b) => a.name.localeCompare(b.name))
	}

	_changeForFilters(entity, newValue) {
		const { value, codebookCategoryId } = entity

		const params = {
			[InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: EQUIPMENT_CB,
			[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_IDS]: [codebookCategoryId],
			[InternalAdminUrlConvertor.constants.CODEBOOKS_FOR_FILTERS]: newValue
		}

		return this._codebooksService.patchCodebooksValues(value, params)
	}

	async _getFormLineEntitiesForEditForm(codebookValueEntity = {}) {
		const { id, value, forFilters = true, isFavorite = false } = codebookValueEntity
		const isNew = !id
		const formLineEntities = []

		const valueCategories = await this._getValueCategories(value)

		if (!isNew) {
			formLineEntities.push({
				id: 'value',
				value: value,
				widget: IDS.HIDDEN
			})
		}

		formLineEntities.push({
			id: 'name',
			label: 'Název',
			value: codebookValueEntity.name,
			widget: IDS.TEXT,
			required: true
		})

		formLineEntities.push({
			id: InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_IDS,
			label: 'Kategorie',
			value: valueCategories,
			widget: IDS.CHECKBOX_OPTIONS,
			options: this._getCarCategories(),
			required: isNew
		})

		formLineEntities.push({
			id: InternalAdminUrlConvertor.constants.CODEBOOKS_SECTION,
			label: 'Sekce',
			value: codebookValueEntity.category || nonValue,
			widget: IDS.SELECT,
			options: [nonValueOption, ...this._getEquipmentsCategories()],
			required: true
		})

		formLineEntities.push({
			id: 'is_favorite',
			label: 'Nejčastější výbava',
			value: isFavorite,
			widget: IDS.SELECT,
			options: booleanOptions,
			required: true
		})

		if (isNew) {
			formLineEntities.push({
				id: InternalAdminUrlConvertor.constants.CODEBOOKS_FOR_FILTERS,
				label: 'Vyhledávání',
				value: forFilters,
				widget: IDS.SELECT,
				options: booleanOptions
			})
		}

		return formLineEntities
	}

	_handleEditFormSubmitError(error) {
		this._pageLoaderExtension.hide()

		let content = ''

		if (this._isHasAdvertError(error)) {
			content = 'Nelze odebrat z kategorie, kde je výbava použita u inzerátu'
		}

		return {
			entityFormError: {
				title: 'Nepodařilo se odeslat formulář',
				content
			}
		}
	}

	_handleEditFormSubmitSuccess() {
		this._pageLoaderExtension.hide()

		this._processSuccessAction()
		this.setState({
			isEditPopupOpened: false
		})
	}

	async _processSuccessAction() {
		this.setState({
			isLoading: true
		})

		const entitiesAndPagination = await this.getEntitiesAndPagination()

		if (this._successMessage) {
			this._showStatusMessageToast({
				type: StatusMessage.TYPE.SUCCESS,
				title: this._dictionary.get('ActionMessage.titleSuccess'),
				text: `${this._successMessage}`,
				autoDismissTimeout: 6000
			})

			this._successMessage = ''
		}

		this.setState({
			entitiesAndPagination
		})
	}

	_editFormValidations() {
		return {
			name: ({ value }) => (Validation.isNotEmpty(value) ? '' : 'Zadejte název číselníku'),
			[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_IDS]: ({ value = [] }) =>
				value.length > 0 ? '' : 'Vyberte minimálně jednu kategorii',
			[InternalAdminUrlConvertor.constants.CODEBOOKS_SECTION]: ({ value }) =>
				value !== nonValue ? '' : 'Zadejte sekci'
		}
	}

	_getEditFormSubmitPromise(formData) {
		const { value, ...data } = formData
		const {
			name,
			[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_IDS]: categoryIds,
			[InternalAdminUrlConvertor.constants.CODEBOOKS_SECTION]: section
		} = formData

		let promise

		const saveData = Object.assign(
			{ [InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: EQUIPMENT_CB },
			data
		)

		const categoryNames = this._getCarCategories()
			.filter(({ value }) => categoryIds.includes(value))
			.map(({ name }) => name)

		const { name: sectionName } = this._getEquipmentsCategories().find(
			({ value }) => section === value
		)

		if (value) {
			promise = this._codebooksService.putCodebooksValues(value, saveData)
			this._successMessage = `Výbava "${name}" v sekci "${sectionName}" byla přidána do ${
				categoryNames.length > 1 ? 'kategorií' : 'kategorie'
			} "${categoryNames.join(', ')}"`
		} else {
			promise = this._codebooksService.postCodebooksValues(saveData)
			this._successMessage = `Výbava "${name}" v sekci "${sectionName}" a ${
				categoryNames.length > 1 ? 'kategoriích' : 'kategori'
			} "${categoryNames.join(', ')}" byla úspěšně upravena`
		}

		return promise
	}

	async _getValueCategories(value) {
		const valueCategories = async (value) => {
			try {
				const { entities } = await this._codebooksService.getCodebooksValues({
					[InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: EQUIPMENT_CB,
					value
				})

				return entities.map(({ codebookCategoryId }) => codebookCategoryId)
			} catch (e) {
				return []
			}
		}

		if (value) {
			return valueCategories(value)
		} else {
			return this._getCarCategories().map(({ value }) => value)
		}
	}
}
