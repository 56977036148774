
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PerPageButton'] = {
  perPage: function(d) { return "Inzerátů na stránku"; },
  perPagePrefix: function(d) { return "Počet inzerátů:"; },
  page: function(d) { return "stránka"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	