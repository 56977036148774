import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Label.less'

const CLASSNAME = 'c-label'

const Label = ({ className, children, isRequired, ...restProps }) => {
	return (
		<label
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
			{...restProps}
		>
			{children} {isRequired ? <span className={`${CLASSNAME}__required`}>*</span> : ''}
		</label>
	)
}

Label.propTypes = {
	className: PropTypes.string,
	isRequired: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default Label
