import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Surface } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import ModelLinesPlaceholder from '../../../placeholders/modelLinesPlaceholder/ModelLinesPlaceholder'
import { useLocalize } from 'app/base/componentHelpers'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import { sortModels } from '../../../helper/SortModels'
import { filterNoDataItem } from '../../../helper/filterNoDataItems'

import './ModelsOverviewList.less'
import './ModelsOverviewListCS.json'

const CLASSNAME = 'c-catalog-models-overview-list'

const ModelsOverviewList = (props) => {
	const { modelLinesList = DEFAULT_PROPS.ARRAY } = props

	const context = useContext(Context)
	const router = context.$Router
	const localize = useLocalize()

	const dataLoaded = modelLinesList && modelLinesList.length > 0

	const getYear = (from, to) => {
		const yearTo = to ? to : localize('ModelsOverviewList.present')

		if (from && yearTo) {
			return <span>{`${from} – ${yearTo}`}</span>
		} else {
			return undefined
		}
	}

	const getUrlToModifications = (modelLine) => {
		const { seoName, model = {} } = modelLine
		const { seoName: modelSeoName = '', manufacturer = {} } = model
		const { seoName: manufacturerSeoName } = manufacturer

		return router.link(ROUTE_NAMES.USERWEB.CATALOG_MODIFICATIONS, {
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: manufacturerSeoName,
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME]: modelSeoName,
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.GENERATION_SEO_NAME]: seoName
		})
	}

	const renderModelsList = () => {
		return (
			<div className={`${CLASSNAME}__list`}>
				{modelLinesList
					.filter(filterNoDataItem)
					.sort(sortModels)
					.map((model) => {
						const { name, id, yearStart, yearEnd } = model

						const year = getYear(yearStart, yearEnd)

						const url = getUrlToModifications(model)

						return (
							<a
								href={url}
								key={id}
								className={`${CLASSNAME}__item`}
								data-dot='item'
								data-dot-data={`{"value": "${name}"}`}
							>
								<div className={`${CLASSNAME}__item-name`}>{name}</div>
								{year && <div className={`${CLASSNAME}__item-year`}>{year}</div>}
							</a>
						)
					})}
			</div>
		)
	}

	return (
		<Surface surface={3} className={CLASSNAME} data-dot='generations'>
			{dataLoaded ? renderModelsList() : <ModelLinesPlaceholder />}
		</Surface>
	)
}

ModelsOverviewList.propTypes = {
	modelLinesList: PropTypes.array
}

export default React.memo(ModelsOverviewList)
