import { CATEGORIES } from 'app/base/Constants'

export default class FilterConstants {
	static get formLineIds() {
		return {
			CONDITION: 'condition_cb', // Stav vozu
			BRAND_MODEL: 'manufacturer_cb', // Značky a modely
			PHRASE: 'phrase', // Hledaná fráze
			AGE: 'vehicle_age', // Stáří vozu (Rok vyroby)
			PRICE: 'price', // Cena vozu
			FUEL: 'fuel_cb', // Palivo
			TACHOMETER: 'tachometer', // Najeto kilometrů
			VEHICLE_BODY: 'vehicle_body_cb', // Karosérie vozu
			GEARBOX: 'gearbox_cb', // Převodovka
			PERFORMANCE: 'engine_power', // Výkon
			COUNTRY_OF_ORIGIN: 'country_of_origin_cb', // Země původu
			VENDOR: 'vendor_cb', // Prodejce
			REGION_DISTRICT: 'region_cb', // Místo prodeje
			DISPLACEMENT: 'engine_volume', // Objem
			AIR_CONDITIONING: 'aircondition_cb', // Klimatizace
			FUEL_CONSUMPTION: 'average_gas_mileage', // Spotřeba
			CERTIFIED_PROGRAM: 'certified_program_cb', // Ověřovací programy
			AIRBAGS: 'airbag', // Airbagy
			COLOR: 'color_cb', // Farba
			CAPACITY: 'capacity', // Pocet mist
			DOORS: 'door', // Dvere
			BUS_SEATS: 'seats_cb', // Sedadla
			ITEM_AGE: 'item_age_cb', // Stari inzeratu
			PRICE_OPTION: 'price_option_cb', // Dalsi moznosti ceny
			HISTORY_INFO: 'history_info_cb', // Dalsi informace o historii
			EQUIPMENT: 'equipment_cb', // Vybava
			DRIVE: 'drive_cb', // Pohon
			OTHER_SPECIFICATION: 'other_specification_cb', // Dalsi specifikace vozu
			WEIGHT: 'weight', // Hmotnost
			BEDS: 'beds', // Luzka
			MACHINE_HOURS: 'machine_hours', // Motohodiny
			OPERATING_LEASE_PRICE_WITHOUT_VAT: 'operating_lease_price_without_vat', // Mesicni sazba
			OPERATING_LEASE_ANNUAL_DISTANCE: 'operating_lease_annual_distance', // Rocni najezd
			OPERATING_LEASE_PERIOD: 'operating_lease_period', // Doba pronajmu
			OPERATING_LEASE_SERVICES: 'operating_lease_services_cb', // Sluzby
			OPERATING_LEASE_INTENDED_FOR: 'operating_lease_intended_for_cb' // Pro koho je operativni leasing urcen
		}
	}

	static get widgetIds() {
		const IDS = Object.freeze({
			BRAND: 'filter-manufacturer',
			MULTIOPTION: 'filter-multioption',
			RANGE: 'filter-range',
			REGION: 'filter-region',
			PHRASE: 'filter-phrase'
		})

		/**
		 * TODO
		 * Jakmile bude v provozu nasazena úprava api, která nám bude posílat nové widgety, můžeme OLD_IDS smazat.
		 */
		const OLD_IDS = Object.freeze({
			CONDITION: 'filter-condition',
			BRAND: 'filter-manufacturer',
			AGE: 'filter-vehicle-age',
			PRICE: 'filter-price',
			FUEL: 'filter-fuel',
			TACHOMETER: 'filter-tachometer',
			VEHICLE_BODY: 'filter-vehicle-body',
			GEARBOX: 'filter-gearbox',
			MULTIOPTION: 'filter-multioption',
			PERFORMANCE: 'filter-engine-power',
			COUNTRY_OF_ORIGIN: 'filter-country-of-origin',
			VENDOR: 'filter-vendor',
			REGION: 'filter-region',
			DISPLACEMENT: 'filter-engine-volume',
			AIR_CONDITIONING: 'filter-aircondition',
			FUEL_CONSUMPTION: 'filter-average-gas-mileage',
			CERTIFIED_PROGRAM: 'filter-certified-program',
			AIRBAGS: 'filter-airbag',
			COLOR: 'filter-color',
			CAPACITY: 'filter-capacity',
			DOORS: 'filter-door',
			BUS_SEATS: 'filter-seat',
			ITEM_AGE: 'filter-item-age',
			PRICE_OPTION: 'filter-price-option',
			HISTORY_INFO: 'filter-history-info',
			EQUIPMENT: 'filter-equipment',
			DRIVE: 'filter-drive',
			OTHER_SPECIFICATION: 'filter-other-specification',
			WEIGHT: 'filter-weight',
			BEDS: 'filter-beds',
			MACHINE_HOURS: 'filter-machine-hours',
			OPERATING_LEASE_PRICE_WITHOUT_VAT: 'filter-lease-price',
			OPERATING_LEASE_ANNUAL_DISTANCE: 'filter-annual-distance',
			OPERATING_LEASE_PERIOD: 'filter-lease-period',
			OPERATING_LEASE_SERVICES: 'filter-lease-services',
			OPERATING_LEASE_INTENDED_FOR: 'filter-lease-intended-for'
		})

		return Object.assign({}, IDS, OLD_IDS)
	}

	static get defaultFiltersOrder() {
		return [
			FilterConstants.formLineIds.BRAND_MODEL,
			FilterConstants.formLineIds.PHRASE,
			FilterConstants.formLineIds.CONDITION,
			FilterConstants.formLineIds.PRICE,
			FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR,
			FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT,
			FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE,
			FilterConstants.formLineIds.OPERATING_LEASE_PERIOD,
			FilterConstants.formLineIds.OPERATING_LEASE_SERVICES,
			FilterConstants.formLineIds.AGE,
			FilterConstants.formLineIds.TACHOMETER,
			FilterConstants.formLineIds.FUEL,
			FilterConstants.formLineIds.VEHICLE_BODY,
			FilterConstants.formLineIds.GEARBOX,
			FilterConstants.formLineIds.DRIVE,
			FilterConstants.formLineIds.PERFORMANCE,
			FilterConstants.formLineIds.DISPLACEMENT,
			FilterConstants.formLineIds.EQUIPMENT,
			FilterConstants.formLineIds.FUEL_CONSUMPTION,
			FilterConstants.formLineIds.AIR_CONDITIONING,
			FilterConstants.formLineIds.AIRBAGS,
			FilterConstants.formLineIds.CAPACITY,
			FilterConstants.formLineIds.DOORS,
			FilterConstants.formLineIds.BUS_SEATS,
			FilterConstants.formLineIds.BEDS,
			FilterConstants.formLineIds.COLOR,
			FilterConstants.formLineIds.MACHINE_HOURS,
			FilterConstants.formLineIds.WEIGHT,
			FilterConstants.formLineIds.REGION_DISTRICT,
			FilterConstants.formLineIds.COUNTRY_OF_ORIGIN,
			FilterConstants.formLineIds.VENDOR,
			FilterConstants.formLineIds.CERTIFIED_PROGRAM,
			FilterConstants.formLineIds.HISTORY_INFO,
			FilterConstants.formLineIds.ITEM_AGE,
			FilterConstants.formLineIds.OTHER_SPECIFICATION
		]
	}

	// poradi filtru pro textovy vypis hledani - napr. 'Stav vozidla: Nové, Hledání: cross'.
	// Pouziva se pro 'Posledni hledani' nebo 'Ulozena hledani'. Zmeny v tomto seznamu je
	// potreba probrat s BE, at nam sedi napr. u ulozenych hledani poradi v emailovych
	// sablonach.
	static get searchPrintOrder() {
		const preferredFilters = [
			FilterConstants.formLineIds.PRICE,
			FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT,
			FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR,
			FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE,
			FilterConstants.formLineIds.OPERATING_LEASE_PERIOD,
			FilterConstants.formLineIds.OPERATING_LEASE_SERVICES,
			FilterConstants.formLineIds.TACHOMETER,
			FilterConstants.formLineIds.AGE,
			FilterConstants.formLineIds.VEHICLE_BODY,
			FilterConstants.formLineIds.FUEL,
			FilterConstants.formLineIds.DISPLACEMENT,
			FilterConstants.formLineIds.PERFORMANCE,
			FilterConstants.formLineIds.COLOR
		]

		// nazvy filtru na BE serazene podle abecedy
		// (BRAND_MODEL se vypisuje solo, takze v seznamu neni)
		const regularFilters = [
			FilterConstants.formLineIds.PHRASE,
			FilterConstants.formLineIds.PRICE_OPTION,
			FilterConstants.formLineIds.CONDITION,
			FilterConstants.formLineIds.GEARBOX,
			FilterConstants.formLineIds.DRIVE,
			FilterConstants.formLineIds.HISTORY_INFO,
			FilterConstants.formLineIds.CAPACITY,
			FilterConstants.formLineIds.DOORS,
			FilterConstants.formLineIds.BUS_SEATS,
			FilterConstants.formLineIds.BEDS,
			FilterConstants.formLineIds.AIR_CONDITIONING,
			FilterConstants.formLineIds.AIRBAGS,
			FilterConstants.formLineIds.FUEL_CONSUMPTION,
			FilterConstants.formLineIds.MACHINE_HOURS,
			FilterConstants.formLineIds.WEIGHT,
			FilterConstants.formLineIds.COUNTRY_OF_ORIGIN,
			FilterConstants.formLineIds.VENDOR,
			FilterConstants.formLineIds.CERTIFIED_PROGRAM,
			FilterConstants.formLineIds.REGION_DISTRICT,
			FilterConstants.formLineIds.ITEM_AGE,
			FilterConstants.formLineIds.OTHER_SPECIFICATION,
			FilterConstants.formLineIds.EQUIPMENT
		].sort()

		return [...preferredFilters, ...regularFilters]
	}

	static get hpHideFilterByCategory() {
		return {
			[CATEGORIES.TRAILERS.id]: [FilterConstants.formLineIds.VEHICLE_BODY]
		}
	}

	static get multiOptionFilters() {
		return [
			FilterConstants.formLineIds.CONDITION,
			FilterConstants.formLineIds.FUEL,
			FilterConstants.formLineIds.VEHICLE_BODY,
			FilterConstants.formLineIds.GEARBOX,
			FilterConstants.formLineIds.COUNTRY_OF_ORIGIN,
			FilterConstants.formLineIds.VENDOR,
			FilterConstants.formLineIds.AIR_CONDITIONING,
			FilterConstants.formLineIds.CERTIFIED_PROGRAM,
			FilterConstants.formLineIds.COLOR,
			FilterConstants.formLineIds.BUS_SEATS,
			FilterConstants.formLineIds.PRICE_OPTION,
			FilterConstants.formLineIds.HISTORY_INFO,
			FilterConstants.formLineIds.EQUIPMENT,
			FilterConstants.formLineIds.DRIVE,
			FilterConstants.formLineIds.OTHER_SPECIFICATION,
			FilterConstants.formLineIds.OPERATING_LEASE_SERVICES,
			FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR
		]
	}

	// defaultne hodnoty pre filter stav vozidla
	static get defaultConditionOptions() {
		return [
			{ value: 1, name: 'Nové', seoName: 'nove' },
			{ value: 2, name: 'Ojeté', seoName: 'ojete' },
			{ value: 4, name: 'Předváděcí', seoName: 'predvadeci' }
		]
	}

	static get defaultConditionValues() {
		return new Set(FilterConstants.defaultConditionOptions.map((o) => o.value))
	}

	static get allConditionApiParam() {
		return 'vse'
	}

	static get defaultConditionApiParams() {
		return FilterConstants.defaultConditionOptions.map((o) => o.seoName).join(',')
	}

	// seznam specialnich filtru, kde musime nacitat pocty inzeratu s kazdou zmenou -
	// at uz dilci pocty nebo celkovou sumu
	static get specialFilters() {
		return [
			FilterConstants.formLineIds.PRICE_OPTION,
			FilterConstants.formLineIds.HISTORY_INFO,
			FilterConstants.formLineIds.EQUIPMENT
		]
	}

	static get filterIds() {
		return {
			EXTENDED_FILTER_ID: 'extendedFilterId',
			MODEL: 'model_cb',
			DISTRICT: 'district_cb'
		}
	}

	static get filterPopupType() {
		return {
			DEFAULT: 'defaultPopupType',
			BACK: 'backPopupType'
		}
	}

	static get filterDropdownType() {
		return {
			DEFAULT: 'defaultDropdownType',
			BACK: 'backDropdownType'
		}
	}

	static get filterButtonType() {
		return {
			CONTINUE: 'continueButton',
			SEARCH: 'searchButton'
		}
	}

	static get filtersOpenedByDefault() {
		return [
			FilterConstants.formLineIds.BRAND_MODEL,
			FilterConstants.formLineIds.PHRASE,
			FilterConstants.formLineIds.AGE,
			FilterConstants.formLineIds.PRICE,
			FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR,
			FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT,
			FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE,
			FilterConstants.formLineIds.OPERATING_LEASE_PERIOD,
			FilterConstants.formLineIds.OPERATING_LEASE_SERVICES,
			FilterConstants.formLineIds.TACHOMETER,
			FilterConstants.formLineIds.FUEL,
			FilterConstants.formLineIds.VEHICLE_BODY,
			FilterConstants.formLineIds.CONDITION,
			FilterConstants.formLineIds.GEARBOX,
			FilterConstants.formLineIds.DRIVE,
			FilterConstants.formLineIds.PERFORMANCE,
			FilterConstants.formLineIds.EQUIPMENT
		]
	}
}
