
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AbstractNewAdvert'] = {
  basicInfo: function(d) { return "Základní údaje"; },
  basicInfo4: function(d) { return "Základní údaje"; },
  photos: function(d) { return "Fotogalerie"; },
  photos4: function(d) { return "Fotogalerii"; },
  history: function(d) { return "Historie"; },
  history4: function(d) { return "Historii"; },
  equipment: function(d) { return "Výbava"; },
  equipment4: function(d) { return "Výbavu"; },
  payment: function(d) { return "Platba"; },
  payment4: function(d) { return "Platbu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	