import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

class FavoriteListUrlConvertor {
	static get constants() {
		return deepFreeze({
			SORT: {
				CREATE: '-create_date'
			}
		})
	}

	static getApiParams(appParams = {}) {
		const { URL_APP_PARAMS } = UrlConvertor.constants

		return {
			...UrlConvertor.getApiPaging(
				appParams[URL_APP_PARAMS.PAGE],
				appParams[URL_APP_PARAMS.PER_PAGE]
			),
			[UrlConvertor.constants.URL_API_PARAMS.SORT]: FavoriteListUrlConvertor.constants.SORT.CREATE
		}
	}

	static createPaginationUrl(page, router) {
		const { params } = router.getCurrentRouteInfo()

		if (page > 1) {
			params[UrlConvertor.constants.URL_APP_PARAMS.PAGE] = page
		} else {
			delete params[UrlConvertor.constants.URL_APP_PARAMS.PAGE]
		}

		return UrlConvertor.getUrl(router, params)
	}
}

export default FavoriteListUrlConvertor
