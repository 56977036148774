import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { Button } from '@sznds/react'

import './ConfirmButton.less'
import './ConfirmButtonCS.json'

const CLASSNAME = 'cf-confirm'

export default class ConfirmButton extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			disabled: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			className: '',
			disabled: false
		}
	}

	render() {
		const { className, disabled, onClick } = this.props

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				<Button
					className={`${CLASSNAME}__btn`}
					disabled={disabled}
					type='button'
					size='small'
					text={this.localize('ConfirmButton.confirm')}
					surface={4}
					onClick={onClick}
					data-dot='submit'
					data-dot-data='{"type": "confirm"}'
				/>
			</div>
		)
	}
}
