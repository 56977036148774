import PropTypes from 'prop-types'

const localityPropTypes = {
	address: PropTypes.string,
	district: PropTypes.string,
	entityId: PropTypes.number,
	entityType: PropTypes.string,
	municipality: PropTypes.string,
	quarter: PropTypes.string,
	street: PropTypes.string
}

export default localityPropTypes
