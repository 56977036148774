import React, { memo, useState } from 'react'
import classnames from 'classnames'
import { Input } from '@sznds/react'
import { CLASSNAME } from '../InternalAdminWidgets'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { CLOSE_24, ADD_24 } from '@sznds/icons'
import { Validation, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './WidgetContacts.less'

const CONTACTS_CLASSNAME = 'c-ia-widget__contacts'

const WidgetContacts = memo(
	({ className, formLineEntity = {}, onChange = DEFAULT_PROPS.FUNCTION }) => {
		const {
			id,
			label,
			value = [],
			placeholder = '',
			required = false,
			disabled = false,
			errorMessage = '',
			type = 'text',
			extra: { valueName } = {}
		} = formLineEntity

		const readonly = true

		const [addValue, setAddValue] = useState('')
		const [addErrorMessage, setAddErrorMessage] = useState('')

		const onChangeContacts = ({ id: contactId, value: contactValue }) => {
			onChange({
				id: contactId,
				value: contactValue
			})
		}

		const addContact = () => {
			let errorMsg = ''

			const isNotEmpty = Validation.isNotEmpty(addValue)

			if (isNotEmpty) {
				if (type === 'email') {
					const isValid = Validation.checkEmail(addValue)
					errorMsg = isValid ? '' : 'Email nemá správný formát'
				} else if (type === 'tel') {
					const isValid = Validation.checkPhone(addValue)
					errorMsg = isValid ? '' : 'Telefon nemá správný formát'
				}
			} else {
				errorMsg = 'Nelze přidat prázdný kontakt'
			}

			if (!errorMsg) {
				const newValue = [...value]
				newValue.push({
					[valueName]: addValue,
					isDeleted: false,
					verified: true
				})

				onChange({
					id,
					value: newValue,
					errorMessage: ''
				})

				setAddErrorMessage('')
				setAddValue('')
			} else {
				setAddErrorMessage(errorMsg)
			}
		}

		const deleteContact = (index) => {
			const newValue = [...value]
			newValue.splice(index, 1)

			onChange({
				id,
				value: newValue
			})
		}

		const renderAddRow = () => {
			const id = `newContact-${type}`
			const isError = !!addErrorMessage

			return (
				<>
					<Input
						key={id}
						className={`${CLASSNAME}__input`}
						type={type}
						id={id}
						error={isError || !!errorMessage}
						value={addValue}
						placeholder={placeholder}
						iconRight={ADD_24}
						onIconRightClick={addContact}
						onChange={(event) => {
							setAddValue(event.target.value)
						}}
						onFocus={() => {
							setAddErrorMessage('')
						}}
					/>
					{isError && <div className={`${CLASSNAME}__error`}>{addErrorMessage}</div>}
				</>
			)
		}

		return (
			<div className={className}>
				<div className={`${CLASSNAME}__input-box`}>
					{!!label && (
						<label htmlFor={id} className={`${CLASSNAME}__label`}>
							{label}
							{required && <span className={`${CLASSNAME}__req`}>*</span>}
						</label>
					)}

					<div className={CONTACTS_CLASSNAME}>
						{value.map(
							({ [valueName]: value, id, verified, errorMessage: rowErrorMessage = '' }, index) => {
								const isRowError = !!rowErrorMessage

								const inputActionProps = {}

								if (!disabled) {
									Object.assign(inputActionProps, {
										onChange: (event) => {
											onChangeContacts({
												id,
												value: event.target.value
											})
										},
										iconRight: CLOSE_24,
										onIconRightClick: (event) => {
											deleteContact(index)
										}
									})
								}

								return (
									<>
										<Input
											key={id}
											className={classnames({
												[`${CLASSNAME}__input`]: true,
												[`${CLASSNAME}__input--disabled`]: disabled || readonly,
												[`${CLASSNAME}__input--verified`]: verified,
												[`${CLASSNAME}__input--not-verified`]: !verified
											})}
											type={type}
											id={id}
											value={value}
											error={isRowError}
											placeholder={placeholder}
											readOnly={readonly}
											disabled={disabled || readonly}
											{...inputActionProps}
										/>
										{isRowError && <div className={`${CLASSNAME}__error`}>{rowErrorMessage}</div>}
									</>
								)
							}
						)}
						{!disabled && renderAddRow()}
						{!!errorMessage && <div className={`${CLASSNAME}__error`}>{errorMessage}</div>}
					</div>
				</div>
			</div>
		)
	}
)

WidgetContacts.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func,
	className: PropTypes.string,
	type: PropTypes.string
}

export default WidgetContacts
