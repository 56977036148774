import React from 'react'
import LinkArrow, { ARROW_TYPE } from 'app/component/linkArrow/LinkArrowView'

import './NavButtonView.less'

const CLASSNAME = 'c-nav-btn'

const BUTTON_TYPE = {
	BACK: 'back',
	FORWARD: 'forward'
}

const DEFAULT_DATA_DOT = {
	[BUTTON_TYPE.BACK]: 'go-back',
	[BUTTON_TYPE.FORWARD]: 'go-to-next'
}

const NavButton = (props) => {
	const newProps = Object.assign({}, props)
	const { text, url, dataDot, type } = newProps

	delete newProps.text
	delete newProps.url
	delete newProps.dataDot
	delete newProps.type

	return (
		<div className={`${CLASSNAME}__wrapper`}>
			<LinkArrow
				url={url}
				dataDot={dataDot || DEFAULT_DATA_DOT[type]}
				className={`${CLASSNAME} ${CLASSNAME}-${type}`}
				{...newProps}
				type={
					type === BUTTON_TYPE.BACK
						? ARROW_TYPE.LEFT
						: type === BUTTON_TYPE.FORWARD
						? ARROW_TYPE.RIGHT
						: ''
				}
			>
				<span className={`${CLASSNAME}__text`}>{text}</span>
			</LinkArrow>
		</div>
	)
}

export default React.memo(NavButton)
export { BUTTON_TYPE }
