import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import { Radio } from '@sznds/react'
import BasicCompWidget from '../basic/BasicCompWidget'

import './RadiosCompWidget.less'

export const RADIOS_WIDGET_ID = 'radios_id'

const CLASSNAME = 'c-radios-comp-widget'

const RadiosCompWidget = ({
	formLineEntity = DEFAULT_PROPS.OBJECT,
	onChange = DEFAULT_PROPS.FUNCTION
}) => {
	return (
		<BasicCompWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={RADIOS_WIDGET_ID}
			renderInput={(formLineEntity, props) => {
				const { options = [] } = formLineEntity

				return (
					<div className={`${CLASSNAME}__input-wrapper`}>
						{options.map((option) => {
							const { id, name, value } = option

							return (
								<Radio
									{...props}
									key={id}
									value={value}
									checked={formLineEntity.value === value}
									label={name}
									className={`${CLASSNAME}__input`}
									onChange={(event) => {
										onChange({
											id: formLineEntity.id,
											value,
											errorMessage: ''
										})
									}}
								/>
							)
						})}
					</div>
				)
			}}
		/>
	)
}

RadiosCompWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(RadiosCompWidget)
