export default class FilterService {
	/**
	 * @constructor
	 * @param {FilterResource}   filterResource
	 * @param {FilterFactory}    filterFactory
	 * @param {CodebooksService} codebooksService
	 */
	constructor(filterResource, filterFactory, codebooksService) {
		this._filterResource = filterResource
		this._filterFactory = filterFactory
		this._codebooksService = codebooksService
	}

	/**
	 * Získá formLineEntities pro filtr.
	 *
	 * @method getFormLineEntities
	 * @public
	 * @param {Object} params
	 * @param {Number} params.category_id Id kategorie.
	 * @param {Object} urlParams
	 * @return {Promise<Array<inzeraty/form-lines/Entity>>} FormLineEntities pro filtr.
	 */
	getFormLineEntities(params = {}, urlParams = {}) {
		return this._filterResource.getFilter(params).then((data) => {
			const { sections = [] } = data.body.result
			let allElements = []

			sections.forEach((section) => {
				const { elements = [], title, id } = section

				allElements = allElements.concat(
					elements.map((element) => {
						return Object.assign({}, element, {
							filterSection: {
								title,
								id
							}
						})
					})
				)
			})

			return this._filterFactory.getFormLineEntities(allElements, urlParams)
		})
	}

	/**
	 * Vrátí formLineEntities filtru rozšířený o modely aut konkrétní značky.
	 *
	 * @method getFilterWithModels
	 * @public
	 * @param {Array<FormLineEntity>} formLineEntities Filtr který bude rozšířen o modely.
	 * @param {Number}                brandId          Id značky, o jejíž modely bude filtr rozšířen.
	 * @return {Promse<Array<FormLineEntity>>} Promise jejíž hodnotou bude filtr rozšířený o modely značky.
	 */
	getFilterWithModels(formLineEntities, brandId) {
		const params = {
			parent_id: brandId
		}

		return this._codebooksService
			.getCodebooks(params)
			.then((codebooksEntities) =>
				this._filterFactory.updateFormLineEntitiesModels(
					formLineEntities,
					brandId,
					codebooksEntities
				)
			)
	}

	/**
	 * Provede update formLineEntities filtru.
	 *
	 * @method updateFormLineEntities
	 * @public
	 * @param {Array<inzeraty/form-lines/Entity>} FormLineEntities FormLineEntities filtru, které budou upraveny.
	 * @param {Array<Object>} updates Updaty, které mají být aplikovány nad upravovanými formLineEntities.
	 * @return {Array<inzeraty/form-lines/Entity>} Upravené formLineEntities pro filtr.
	 */
	updateFormLineEntities(formLineEntities, updates) {
		return this._filterFactory.updateFormLineEntities(formLineEntities, updates)
	}
}
