import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'

import './TextLink.less'

const CLASSNAME = 'c-text-link'

export default class TextLink extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			onClick: PropTypes.func,
			iconLeft: PropTypes.node,
			iconRight: PropTypes.node,
			children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
			dataDot: PropTypes.string,
			dataE2E: PropTypes.string,
			newTab: PropTypes.bool,
			itemProp: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			className: '',
			onClick: () => {},
			newTab: false
		}
	}

	render() {
		const {
			className,
			onClick,
			iconLeft,
			iconRight,
			children,
			dataDot,
			dataE2E,
			href,
			newTab,
			itemProp
		} = this.props

		const optionalProps = {}

		if (dataDot) {
			optionalProps['data-dot'] = dataDot
		}

		if (dataE2E) {
			optionalProps['data-e2e'] = dataE2E
		}

		if (itemProp) {
			optionalProps.itemProp = itemProp
		}

		if (!href) {
			optionalProps.type = 'button'
		} else {
			optionalProps.href = href

			if (newTab) {
				optionalProps.target = '_blank'
				optionalProps.rel = 'noopener noreferrer'
			}
		}

		const TagName = href ? 'a' : 'button'

		return (
			<TagName
				className={this.cssClasses({
					[`${CLASSNAME}`]: true,
					[className]: !!className
				})}
				onClick={onClick}
				{...optionalProps}
			>
				{iconLeft}
				<div className={`${CLASSNAME}__text`}>{children}</div>
				{iconRight}
			</TagName>
		)
	}
}
