import { BLOCKED_COOKIES_CMP, BLOCKED_LOCALSTORAGE_CMP } from 'app/base/StorageIds'
import { LocalStorage } from '@inzeraty/helpers'

/**
 * Cookie, kterou nastavuje CMP a která říká jestli uživatel povolil použití storages pro personalizaci.
 */
const SZNCMPONE_COOKIE = 'szncmpone'
/**
 * Hodnota cookie pro personalizaci, která říká že uživatel s personalizací souhlasí.
 */
const SZNCMPONE_COOKIE_OK_VALUE = '1'

export default class CmpHelper {
	constructor(cookieStorage) {
		this._cookieStorage = cookieStorage
	}

	init() {
		if (!this.hasCmp()) {
			BLOCKED_COOKIES_CMP.forEach((cookieName) => this._cookieStorage.delete(cookieName))
			BLOCKED_LOCALSTORAGE_CMP.forEach((localStorageName) =>
				LocalStorage.removeItem(localStorageName)
			)
		}
	}

	hasCmp() {
		return this._cookieStorage.get(SZNCMPONE_COOKIE) === SZNCMPONE_COOKIE_OK_VALUE
	}
}
