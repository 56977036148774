import { CATEGORIES } from 'app/base/Constants'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'

export default class RibbonSuggestionToUrlAppParamsConvertor {
	static convert(brandName = '', modelName = '', categoryId = CATEGORIES.PASSENGER_CARS.id) {
		const category = Object.values(CATEGORIES).find((category) => category.id === categoryId)
		return Object.assign(
			{},
			{
				[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: category?.seoName
			},
			{
				brandName,
				modelName
			}
		)
	}
}
