import React, { useContext } from 'react'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import Context from 'ima/page/context'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import {
	FILTER_BY_ADVERT,
	applyDefaultSort
} from 'app/page/internalAdmin/statistics/IAStatisticsController'
import {
	getDimensionHeading,
	getDimensionRowCellValue
} from 'app/page/internalAdmin/statistics/IAStatisticsView'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { PaginationEntity } from '@inzeraty/models'
import AdvertStatisticsEntity from 'app/model/advert/statistics/AdvertStatisticsEntity'
import { useLocalize } from 'app/base/componentHelpers'

import './IAPaymentsView.less'
import 'app/base/BaseCS.json'

const CLASSNAME = 'ia-payments'

export default function IAPaymentsView({
	filterFormLineEntities = DEFAULT_PROPS.ARRAY,
	isFilterLoading,
	entitiesAndPagination = DEFAULT_PROPS.OBJECT,
	isLoading
}) {
	const context = useContext(Context)
	const router = context.$Router
	const localize = useLocalize()

	const { params } = router.getCurrentRouteInfo()

	const showActiveAdvertColumn =
		params[InternalAdminUrlConvertor.constants.FILTER_BY] !== FILTER_BY_ADVERT

	const { errorMessage } = entitiesAndPagination

	return (
		<Layout title='Platby'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			{errorMessage ? <div className={`${CLASSNAME}__error`}>{errorMessage}</div> : null}

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					params[InternalAdminUrlConvertor.constants.GROUP_BY] === 'date' ? (
						<TableHeadSorter sortParam='id' isDefaultAscending={true}>
							{getDimensionHeading(params[InternalAdminUrlConvertor.constants.GROUP_BY])}
						</TableHeadSorter>
					) : (
						<TableHead>
							{getDimensionHeading(params[InternalAdminUrlConvertor.constants.GROUP_BY])}
						</TableHead>
					),
					showActiveAdvertColumn && (
						<TableHeadSorter
							sortParam='active_advert'
							isDefaultDescending={applyDefaultSort(params)}
							alignRight={true}
						>
							Počet inzerátů
						</TableHeadSorter>
					),
					showActiveAdvertColumn && (
						<TableHeadSorter key='topped' sortParam='topped' alignRight={true}>
							Počet topovaných inzerátů
						</TableHeadSorter>
					),
					<TableHeadSorter
						key='topped_price_sum_without_vat'
						sortParam='topped_price_sum_without_vat'
						alignRight={true}
					>
						Suma za topnutí bez DPH
					</TableHeadSorter>,
					<TableHeadSorter key='topped_price_sum' sortParam='topped_price_sum' alignRight={true}>
						Suma za topnutí s DPH
					</TableHeadSorter>
				].filter((head) => !!head)}
				rowCells={[
					getDimensionRowCellValue,
					showActiveAdvertColumn &&
						(({ metrics = {} }) => [Format.number(metrics.activeAdvert), { isNumber: true }]),
					showActiveAdvertColumn &&
						(({ metrics = {} }) => [Format.number(metrics.topped), { isNumber: true }]),
					({ metrics = {} }) => [
						`${Format.number(metrics.toppedPriceSumWithoutVat / 100, 2)} ${localize('Base.CZK')}`,
						{ isNumber: true }
					],
					({ metrics = {} }) => [
						`${Format.number(metrics.toppedPriceSum / 100, 2)} ${localize('Base.CZK')}`,
						{ isNumber: true }
					]
				].filter((row) => !!row)}
				summaryCells={[
					() => <EntitiesTable.SummaryLabel />,
					showActiveAdvertColumn &&
						((entities = [], calcSum) => [
							Format.number(calcSum(entities, ({ metrics = {} }) => metrics.activeAdvert)),
							{ isNumber: true }
						]),
					showActiveAdvertColumn &&
						((entities = [], calcSum) => [
							Format.number(calcSum(entities, ({ metrics = {} }) => metrics.topped)),
							{ isNumber: true }
						]),
					(entities = [], calcSum) => [
						`${Format.number(
							calcSum(entities, ({ metrics = {} }) => metrics.toppedPriceSumWithoutVat / 100),
							2
						)} ${localize('Base.CZK')}`,
						{ isNumber: true }
					],
					(entities = [], calcSum) => [
						`${Format.number(
							calcSum(entities, ({ metrics = {} }) => metrics.toppedPriceSum / 100),
							2
						)} ${localize('Base.CZK')}`,
						{ isNumber: true }
					]
				].filter((summary) => !!summary)}
				isLoading={isLoading}
				entityIdGetter={({ groupBy: { id } = {} }) => id}
			/>
		</Layout>
	)
}

IAPaymentsView.propTypes = {
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(AdvertStatisticsEntity)),
		paginationEntity: PropTypes.instanceOf(PaginationEntity),
		errorMessage: PropTypes.string
	}),
	isLoading: PropTypes.bool
}
