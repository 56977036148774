import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Tag } from '@inzeraty/components'

export default class BrandTag extends AbstractPureComponent {
	static get propTypes() {
		return {
			id: PropTypes.string,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
			getValues: PropTypes.func,
			onOpen: PropTypes.func,
			deleteTag: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			value: -1,
			text: '',
			onOpen: DEFAULT_PROPS.FUNCTION,
			deleteTag: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._openFilterPopup = this._openFilterPopup.bind(this)
		this._deleteTag = this._deleteTag.bind(this)
	}

	render() {
		const { text } = this.props

		return (
			<Tag
				size='small'
				onClick={this._deleteTag}
				data-dot='delete'
				data-dot-data={`{"value": "${text}"}`}
			>
				{text}
			</Tag>
		)
	}

	_openFilterPopup() {
		const { onOpen, value: brandValue } = this.props

		onOpen(brandValue)
	}

	_deleteTag() {
		const { id, value: valueToDelete, getValues, deleteTag } = this.props

		const newFormLineEntityValue = new Map(getValues(id))

		newFormLineEntityValue.delete(valueToDelete)

		deleteTag(newFormLineEntityValue)
	}
}
