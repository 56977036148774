import React from 'react'

import './ModelsPlaceholder.less'

const CLASSNAME = 'c-catalog-models-placeholder'

const ModelsPlaceholder = () => {
	const renderGroup = (itemsCount) => {
		const itemsArr = Array.from(Array(itemsCount).keys())

		return (
			<div className={`${CLASSNAME}__group-wrap`}>
				<div className={`${CLASSNAME}__title`}></div>
				<div className={`${CLASSNAME}__group`}>
					{itemsArr.map((item) => {
						return renderItem(`${item}__key`)
					})}
				</div>
			</div>
		)
	}

	const renderItem = (key) => {
		return (
			<div key={key} className={`${CLASSNAME}__item`}>
				<div className={`${CLASSNAME}__name`}></div>
			</div>
		)
	}

	return (
		<div className={CLASSNAME}>
			{renderGroup(5)}
			{renderGroup(3)}
			{renderGroup(2)}
			{renderGroup(6)}
			{renderGroup(8)}
		</div>
	)
}

export default ModelsPlaceholder
