import UserwebBaseController from 'app/base/UserwebBaseController'
import JsonLd from 'app/helpers/jsonLd/JsonLd'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import GenericError from 'ima/error/GenericError'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import FilterConstants from 'app/model/filter/FilterConstants'
import FilterFromUrlAppConvertor from 'app/model/filter/filterConvertor/FilterFromUrlAppConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertListMeta from './AdvertListMeta'
import AdvertListOperatingLeaseMeta from './AdvertListOperatingLeaseMeta'
import AdvertListExtension from 'app/component/advertList/AdvertListExtension'
import STATE_KEYS from 'app/page/userweb/advertList/AdvertListStateKeys'
import { CATEGORIES } from 'app/base/Constants'
import SearchHelper from 'app/helpers/searchHelper/SearchHelper'
import FilterFormLinesToMySavedSearchConvertor from 'app/model/mySavedSearch/convertors/FilterFormLinesToMySavedSearchConvertor'
import MySavedSearchCache from 'app/component/mySavedSearch/MySavedSearchCache'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import ScrollRestore from 'app/helpers/scrollRestore/ScrollRestore'
import { getSearchTypeSession } from 'app/helpers/sessionStorageHelpers/getSearchTypeSessionHelper'
import { SEARCH_TYPE_SESSION } from 'app/base/StorageIds'
import deepEqual from 'fast-deep-equal'
import clearObject from 'app/helpers/clearObject/clearObject'

import 'app/base/BaseCS.json'

/**
 * @class AdvertListController
 * @namespace app.page.userweb.advertList
 * @extends app.base.UserwebBaseController
 * @module app
 * @submodule app.page.userweb
 */
export default class AdvertListController extends UserwebBaseController {
	/**
	 * @method constructor
	 * @constructor
	 * @param {ns.app.helpers.dependenciesHelper.DependenciesHelper} dependenciesHelper
	 * @param {AdvertListExtension} advertListExtension
	 */
	constructor(
		dependenciesHelper,
		advertListExtension,
		categoryService,
		advertFactory,
		filtersExtension,
		advertsCountService,
		mySavedSearchHelper
	) {
		super(dependenciesHelper)

		this._advertListExtension = advertListExtension
		this._categoryService = categoryService
		this._advertFactory = advertFactory
		this._filtersExtension = filtersExtension
		this._advertsCountService = advertsCountService
		this._mySavedSearchHelper = mySavedSearchHelper

		this._categoryPromise = undefined
		this._filterEntitiesPromise = undefined

		this._evalIfMySavedSearchIsAlreadySaved = this._evalIfMySavedSearchIsAlreadySaved.bind(this)
		this._addMySavedSearch = this._addMySavedSearch.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._advertListExtension)
		this.addExtension(this._filtersExtension)

		this._advertListExtension.setLoadPreferred(!this._isOperatingLeasePage())
	}

	/**
	 * Load advert for page.
	 *
	 * @method load
	 * @return {Object}
	 */
	load() {
		const routeParams = this.getRouteParams()
		const {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName
		} = routeParams

		const categoryEntities = this._categoryService.getCategoriesIncludingAdvertsCount()
		const category = this._getCategory(categoryEntities, categorySeoName)
		const filterFormLineEntities = category.then((categoryEntity) =>
			this._getFormLineEntities(categoryEntity)
		)

		this._advertListExtension.setOnAdvertPaginationLoaded((adverListPagination) =>
			this._sendSearchHitToAnalytics(filterFormLineEntities, adverListPagination)
		)

		return super.load({
			[AdvertListExtension.stateIds
				.ADVERT_LIST_PARAMS_PROMISE]: this._getAdvertsAndPaginationParams(
				categoryEntities,
				categorySeoName
			),
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: this._isOperatingLeasePage(),
			[FiltersExtension.stateIds.CATEGORY]: category,
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntities,
			[FiltersExtension.stateIds.URL_PARAMS]: filterFormLineEntities.then((formLineEntities) =>
				this._filtersExtension.getAdvertListUrlParams(formLineEntities)
			),
			[STATE_KEYS.HEADER]: this._getHeader(category, filterFormLineEntities),
			[STATE_KEYS.PREV_ROUTE_NAME]: this._historyStorage.getPreviousRouteName(),
			seoModelsWithAdvertsValues: this._getSeoModelsWithAdvertsValues(routeParams),
			seoConditionWithAdvertsValues: this._getSeoCountsForSelectedFilter(
				FilterConstants.formLineIds.CONDITION
			),
			seoVehicleBodyWithAdvertsValues: this._getSeoCountsForSelectedFilter(
				FilterConstants.formLineIds.VEHICLE_BODY
			),
			seoFuelWithAdvertsValues: this._getSeoCountsForSelectedFilter(
				FilterConstants.formLineIds.FUEL
			),
			seoCountryOfOriginWithAdvertsValues: this._getSeoCountsForSelectedFilter(
				FilterConstants.formLineIds.COUNTRY_OF_ORIGIN
			),
			seoVendorWithAdvertsValues: this._getSeoCountsForSelectedFilter(
				FilterConstants.formLineIds.VENDOR
			),
			seoCertifiedProgramWithAdvertsValues: this._getSeoCountsForSelectedFilter(
				FilterConstants.formLineIds.CERTIFIED_PROGRAM
			),
			isMySavedSearchAlreadySaved: Promise.all([
				category,
				filterFormLineEntities
			]).then(([category, filterFormLineEntities]) =>
				this._isMySavedSearchAlreadySaved(category, filterFormLineEntities)
			),
			evalIfMySavedSearchIsAlreadySaved: this._evalIfMySavedSearchIsAlreadySaved,
			addMySavedSearch: this._addMySavedSearch
		})
	}

	update(prevParams) {
		this._advertListExtension.setLoadPreferred(!this._isOperatingLeasePage())

		this.setState({
			isMySavedSearchAlreadySaved: undefined
		})

		this._updateMySavedSearchesCache()

		const routeParams = this.getRouteParams()
		const {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName
		} = routeParams

		this._categoryPromise = undefined
		this._filterEntitiesPromise = undefined

		const categoryEntities = this._categoryService.getCategoriesIncludingAdvertsCount()
		const category = this._getCategory(categoryEntities, categorySeoName)
		const filterFormLineEntities = category.then(async (categoryEntity) => {
			const filterFormLineEntities = await this._getFormLineEntities(categoryEntity)
			this._saveLastSearch(filterFormLineEntities)
			return filterFormLineEntities
		})

		this._advertListExtension.setOnAdvertPaginationLoaded((adverListPagination) =>
			this._sendSearchHitToAnalytics(filterFormLineEntities, adverListPagination)
		)

		return super.update(prevParams, {
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: this._isOperatingLeasePage(),
			[FiltersExtension.stateIds.CATEGORY]: category,
			[AdvertListExtension.stateIds
				.ADVERT_LIST_PARAMS_PROMISE]: this._getAdvertsAndPaginationParams(
				categoryEntities,
				categorySeoName
			),
			[STATE_KEYS.HEADER]: this._getHeader(category, filterFormLineEntities),
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntities,
			[FiltersExtension.stateIds.URL_PARAMS]: filterFormLineEntities.then((formLineEntities) =>
				this._filtersExtension.getAdvertListUrlParams(formLineEntities)
			),
			seoConditionWithAdvertsValues: undefined,
			seoVehicleBodyWithAdvertsValues: undefined,
			seoFuelWithAdvertsValues: undefined,
			seoCountryOfOriginWithAdvertsValues: undefined,
			seoVendorWithAdvertsValues: undefined,
			seoCertifiedProgramWithAdvertsValues: undefined,
			isMySavedSearchAlreadySaved: Promise.all([
				category,
				filterFormLineEntities
			]).then(([category, filterFormLineEntities]) =>
				this._isMySavedSearchAlreadySaved(category, filterFormLineEntities)
			)
		})
	}

	activate() {
		super.activate()

		const {
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntities
		} = this.getState()

		this._saveLastSearch(filterFormLineEntities)

		this._updateMySavedSearchesCache()
	}

	async getDataForSsp() {
		const routeParams = this.getRouteParams()
		const {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName
		} = routeParams

		const categoryEntities = this._categoryService.getCategoriesIncludingAdvertsCount()
		const category = await this._getCategory(categoryEntities, categorySeoName)
		const filterFormLineEntities = await this._getFormLineEntities(category)

		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {
				filterFormLineEntities
			},
			params: routeParams
		}
	}

	async _sendSearchHitToAnalytics(filterFormLineEntities, advertListPagination) {
		const areFiltersSame = (routeParamsA, routeParamsB) => {
			const removeUnwantedRouteParams = (routeParams) => {
				const newRouteParams = Object.assign({}, routeParams)

				delete newRouteParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE]
				delete newRouteParams[UrlConvertor.constants.URL_APP_PARAMS.SORT]

				return clearObject(newRouteParams)
			}

			return deepEqual(
				removeUnwantedRouteParams(routeParamsA),
				removeUnwantedRouteParams(routeParamsB)
			)
		}

		const convertFiltersToText = (formLineEntities = [], routeParams = {}) => {
			const { value: manufacturerformLineValue } =
				formLineEntities.find(
					(formLineEntity) => formLineEntity?.id === FilterConstants.formLineIds.BRAND_MODEL
				) || {}

			const manufacturerModelText = Array.from(manufacturerformLineValue || {})
				.map(([brandValue, modelsSet = new Set()]) => {
					const modelsValues = Array.from(modelsSet).join(',')

					return [brandValue, modelsValues].filter((text) => !!text).join(':')
				})
				.join('|')

			return Object.assign({}, routeParams, {
				[AdvertListUrlConvertor.constants.URL_API_PARAMS
					.BRANDS_MODELS_VALUES]: manufacturerModelText
			})
		}

		const routeParams = this.getRouteParams()

		const { lastUrlRouteParams: lastRouteParams, ...searchTypeSession } =
			getSearchTypeSession(this._utils.$SessionStorage) || {}

		if (!areFiltersSame(routeParams, lastRouteParams)) {
			const { paginationEntity } = (await advertListPagination) || {}
			const formLineEntities = await filterFormLineEntities

			this._utils.DotAnalytic.hit({
				action: 'search',
				actionType: 'show-results',
				count: paginationEntity?.total ?? 0,
				filters: convertFiltersToText(formLineEntities, routeParams),
				...searchTypeSession
			})

			this._utils.$SessionStorage.set(
				SEARCH_TYPE_SESSION,
				Object.assign({}, searchTypeSession, {
					lastUrlRouteParams: routeParams
				})
			)
		}
	}

	_updateMySavedSearchesCache() {
		const { [UserwebBaseController.STATE_KEYS.USER_SELF]: userSelf } = this.getState()

		const {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_ID]: mySavedSearchId,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_HASH]: mySavedSearchHash,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS
				.MY_SAVED_SEARCH_TIMESTAMP]: mySavedSearchTimestamp
		} = this.getRouteParams()

		if (mySavedSearchId && mySavedSearchHash && mySavedSearchTimestamp) {
			MySavedSearchCache.onVisitedAdvertListWithNewAdverts(userSelf ? userSelf.id : undefined, {
				mySavedSearchId: parseInt(mySavedSearchId),
				mySavedSearchHash
			})
		}
	}

	async _evalIfMySavedSearchIsAlreadySaved() {
		const {
			[FiltersExtension.stateIds.CATEGORY]: category,
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntities
		} = this.getState()

		const isMySavedSearchAlreadySaved = await this._isMySavedSearchAlreadySaved(
			category,
			filterFormLineEntities
		)

		this.setState({
			isMySavedSearchAlreadySaved
		})

		return isMySavedSearchAlreadySaved
	}

	async _isMySavedSearchAlreadySaved(category, filterFormLineEntities) {
		try {
			const mySavedSearchFilter = FilterFormLinesToMySavedSearchConvertor.convert(
				category,
				filterFormLineEntities
			)
			const isOperatingLeasePage = this._isOperatingLeasePage()

			return await this._mySavedSearchHelper.isMySavedSearchAlreadySaved(
				mySavedSearchFilter,
				isOperatingLeasePage
			)
		} catch {
			return false
		}
	}

	async _addMySavedSearch(...args) {
		const res = await this._mySavedSearchHelper.addMySavedSearch(...args)

		this.setState({
			isMySavedSearchAlreadySaved: true
		})

		return res
	}

	_saveLastSearch(filterFormLineEntities = []) {
		const routeParams = this.getRouteParams()
		const {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName
		} = routeParams

		const routeName = this._isOperatingLeasePage()
			? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
			: ROUTE_NAMES.USERWEB.ADVERT_LIST
		const updatedParams = AdvertListUrlConvertor.getPagingPageParams(1, routeParams)

		// odmazeme parametry pro ulozena hledani
		delete updatedParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_ID]
		delete updatedParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_HASH]
		delete updatedParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP]

		const url = this._utils.$Router.link(routeName, updatedParams)

		if (this._utils.CmpHelper.hasCmp()) {
			SearchHelper.saveLastSearch(
				this._utils.$Dictionary.get.bind(this._utils.$Dictionary),
				categorySeoName,
				filterFormLineEntities,
				url,
				this._isOperatingLeasePage()
			)
		}
	}

	/**
	 * Vrátí seznam ideček modelů dané značky. Vrací v případě, že jsme na výpisu jedné značky a není vybrán žádný model.
	 * @private
	 * @param {Object} routeParams Routovací paramegtry.
	 * @return {Promise|Undefined} Promise jejíž hodnotou je pole s idečky modelů dané značky.
	 */
	_getSeoModelsWithAdvertsValues(routeParams = {}) {
		const apiParams = AdvertListUrlConvertor.getApiParams(routeParams, this.getRouteName())
		const actualCategory = this._getActualCategory(routeParams)
		const API_PARAMS = AdvertListUrlConvertor.constants.URL_API_PARAMS
		const { [API_PARAMS.BRANDS_MODELS_SEO]: brandModelsSeo } = apiParams

		// Pokud je vybrána jen jedna značka a žádný model, tak chceme zobrazit odkazy na modely dané značky.
		if (
			typeof actualCategory !== 'undefined' &&
			brandModelsSeo &&
			brandModelsSeo.indexOf(':') === -1
		) {
			return this._advertsCountService
				.getFilteredAdvertsCount({
					[API_PARAMS.BRANDS_MODELS_SEO]: brandModelsSeo,
					[API_PARAMS.CATEGORY_ID]: actualCategory.id,
					[API_PARAMS.FILTER_NAME]: FilterConstants.filterIds.MODEL,
					[API_PARAMS.OPERATING_LEASE]: this._isOperatingLeasePage()
				})
				.then(({ counts = {} }) => {
					return Object.keys(counts).map((value) => Number(value))
				})
				.catch(() => undefined)
		} else {
			return undefined
		}
	}

	/**
	 * Vrátí object s poctami pre jednotlive idečka.
	 * @private
	 * @param {String} filterName nazov filtru
	 * @return {Promise|Undefined} Promise jejíž hodnotou je object s poctami pre jednotlive idečka.
	 */
	_getSeoCountsForSelectedFilter(filterName) {
		const API_PARAMS = AdvertListUrlConvertor.constants.URL_API_PARAMS
		const { id: categoryId } = this._getActualCategory() || {}

		const routeParams = Object.assign({}, this.getRouteParams())

		delete routeParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE]
		delete routeParams[UrlConvertor.constants.URL_APP_PARAMS.PER_PAGE]
		delete routeParams[UrlConvertor.constants.URL_APP_PARAMS.SORT]
		delete routeParams[UrlConvertor.constants.URL_APP_PARAMS.CODE]

		delete routeParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]
		delete routeParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]
		delete routeParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]

		const urlQueryParamsLength = Object.keys(routeParams).length

		const apiParams = Object.assign(
			{},
			AdvertListUrlConvertor.getApiParams(this.getRouteParams(), this.getRouteName()),
			{
				[API_PARAMS.CATEGORY_ID]: categoryId,
				[API_PARAMS.FILTER_NAME]: filterName,
				[API_PARAMS.OPERATING_LEASE]: this._isOperatingLeasePage()
			}
		)

		// pre 2 a viac query parametrov link nezobrazujeme
		if (urlQueryParamsLength >= 2) {
			return undefined
		} else {
			return this._advertsCountService
				.getFilteredAdvertsCount(apiParams)
				.then(({ counts = {} }) => {
					return {
						counts,
						apiParams
					}
				})
				.catch(() => undefined)
		}
	}

	/**
	 * Vrátí informacem o aktuální kategorii.
	 * @private
	 * @param {Object} routeParams Routovací parametry.
	 * @return {Object} Informace o aktuální kategorii (z app/base/Contstants - CATEGORIES).
	 */
	_getActualCategory(routeParams = this.getRouteParams()) {
		const {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName
		} = routeParams

		return Object.values(CATEGORIES).find((category) => category.seoName === categorySeoName)
	}

	/**
	 * Na základě nastavených filtrů a kategorie vrátí správnou podobu nadpisu stránky.
	 *
	 * @private
	 * @method _getHeader
	 * @param {Promise} categoryPromise
	 * @param {Promise} filterFormLineEntitiesPromise
	 * @return {Promise<String>} Promise jehož hodnotou je nadpis stránky.
	 */
	_getHeader(categoryPromise, filterFormLineEntitiesPromise) {
		return Promise.all([categoryPromise, filterFormLineEntitiesPromise]).then(
			([category, filterFormLineEntities]) => {
				const { options = [], value = new Map() } =
					filterFormLineEntities.find(
						(formLineEntity) => formLineEntity.id === FilterConstants.formLineIds.BRAND_MODEL
					) || {}

				const brandsModelsNames = Array.from(
					FilterFromUrlAppConvertor.convertBrandModelIdsToNames(value, options)
				)

				const { name: categoryName } = category

				let header

				if (brandsModelsNames.length > 1) {
					header = this._utils.$Dictionary.get('AdvertList.headerBrandsModels')
				} else if (brandsModelsNames.length === 1) {
					const brand = brandsModelsNames[0][0]
					const models = Array.from(brandsModelsNames[0][1])

					if (models.length > 1) {
						header = this._utils.$Dictionary.get('AdvertList.headerBrandModels', {
							BRAND: brand
						})
					} else if (models.length === 1) {
						header = `${brand} ${models[0]}`
					} else {
						header =
							categoryName === CATEGORIES.PASSENGER_CARS.name ? brand : `${categoryName} ${brand}` //z hlediska SEO nechceme zobrazovat osobni
					}
				} else {
					header = categoryName
				}

				return header
			}
		)
	}

	_getAdvertsAndPaginationParams(categoryEntitiesPromise, categorySeoName) {
		return categoryEntitiesPromise.then((categoryEntities) => {
			const categoryEntity = categoryEntities.find(
				(category) => category.seoName === categorySeoName
			)

			if (categoryEntity) {
				const { id } = categoryEntity

				return Object.assign(
					{},
					AdvertListUrlConvertor.getApiParams(this.getRouteParams(), this.getRouteName()),
					{
						[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: id,
						[AdvertListUrlConvertor.constants.URL_API_PARAMS
							.OPERATING_LEASE]: this._isOperatingLeasePage()
					}
				)
			} else {
				const error = new GenericError(`Kategorie '${categorySeoName}' neexistuje.`, {
					status: HTTP_STATUS_CODES.NOT_FOUND
				})

				return Promise.reject(error)
			}
		})
	}

	onAdvertClick(advertId) {
		const routeName = this._isOperatingLeasePage()
			? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
			: ROUTE_NAMES.USERWEB.ADVERT_LIST
		ScrollRestore.setId(advertId, routeName)

		this._historyStorage.setActualData({
			scrollPosition: {
				x: window ? window.scrollX : 0,
				y: window ? window.scrollY : 0
			}
		})
	}

	/**
	 * Vrati aktualni kategorii
	 *
	 * @method _getCategory
	 * @private
	 * @param {Array} categoryEntities
	 * @param {String} categorySeoName
	 * @return {Promise} Aktualni kategorie
	 */
	_getCategory(categoryEntities, categorySeoName) {
		if (!this._categoryPromise) {
			this._categoryPromise = categoryEntities.then((categories) => {
				const categoryEntity = categories.find((c) => c.seoName === categorySeoName)

				if (categoryEntity) {
					return categoryEntity
				} else {
					const error = new GenericError(`Kategorie '${categorySeoName}' neexistuje.`, {
						status: HTTP_STATUS_CODES.NOT_FOUND
					})

					return Promise.reject(error)
				}
			})
		}

		return this._categoryPromise
	}

	/**
	 * Vráti formLineEntities pro filtr.
	 *
	 * @method _getFormLineEntities
	 * @private
	 * @param {Object} categoryEntity
	 * @return {Promise<Array<inzeraty/form-lines/Entity>>}
	 */
	_getFormLineEntities(categoryEntity, isOperatingLease = this._isOperatingLeasePage()) {
		const { id } = categoryEntity

		if (!this._filterEntitiesPromise) {
			this._filterEntitiesPromise = this._filtersExtension.getFormLineEntities({
				[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: id,
				[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease
			})
		}

		return this._filterEntitiesPromise
	}

	_isOperatingLeasePage() {
		return this.getRouteName() === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
	}

	async onDealTypeChanged(isOperatingLeaseSelected) {
		const { [FiltersExtension.stateIds.CATEGORY]: categoryEntity } = this.getState()

		// vymazeme cache
		this._filterEntitiesPromise = undefined

		// zavolame api pro definice odpovidajicich filtru
		const newFormLineEntities = await this._getFormLineEntities(
			categoryEntity,
			isOperatingLeaseSelected
		)

		// nastavime nove formliny
		this._filtersExtension.setFormLineEntitiesOnDealTypeChange(
			newFormLineEntities,
			isOperatingLeaseSelected
		)
	}

	/**
	 * Set seo params.
	 *
	 * @method setSeoParams
	 * @param {Object} loadedResources
	 * @param {Core.Interface.MetaManager} metaManager
	 * @param {Core.Interface.Router} router
	 * @param {Core.Interface.Dictionary} dictionary
	 * @param {Object} settings
	 */
	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		this._setHttpStatusForZeroAdverts(loadedResources)

		const {
			category = {},
			filterFormLineEntities = [],
			filteredAdvertsCountTotal
		} = loadedResources

		const isOperatingLeasePage = this._isOperatingLeasePage()

		const title = isOperatingLeasePage
			? AdvertListOperatingLeaseMeta.getTitleValue(dictionary, filterFormLineEntities)
			: AdvertListMeta.getTitleValue(dictionary, category, filterFormLineEntities)
		const description = isOperatingLeasePage
			? AdvertListOperatingLeaseMeta.getDescriptionValue(
					dictionary,
					filterFormLineEntities,
					filteredAdvertsCountTotal
			  )
			: AdvertListMeta.getDescriptionValue(
					dictionary,
					category,
					filterFormLineEntities,
					filteredAdvertsCountTotal
			  )

		const keywords = dictionary.get('Base.keywords')

		metaManager.setTitle(title)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('keywords', keywords)

		metaManager.setMetaName('twitter:title', title)
		metaManager.setMetaName('twitter:description', description)

		metaManager.setMetaProperty('og:title', title)
		metaManager.setMetaProperty('og:description', description)

		// generovani json-LD
		const jsonLd = JsonLd.getHomepage(settings.images.logo)

		metaManager.setMetaProperty('JSON-LD', jsonLd)
	}

	/**
	 * V případě 0 inzerátů nastaví http status stránky na 404.
	 *
	 * @private
	 * @method _setHttpStatusForZeroAdverts
	 * @param {Object} loadedResources
	 */
	_setHttpStatusForZeroAdverts(loadedResources) {
		const {
			[AdvertListExtension.stateIds.ADVERT_LIST_PAGINATION]: advertListAngPagination = {}
		} = loadedResources
		const { advertList = [] } = advertListAngPagination

		if (!advertList.length) {
			this.status = HTTP_STATUS_CODES.NOT_FOUND
		}
	}
}
