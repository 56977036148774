const SCRIPT_URL = '/static/js/blueimp-canvas-to-blob/canvas-to-blob.min.js'

export default class CanvasToBlob {
	constructor(window, scriptLoader) {
		this._window = window
		this._scriptLoader = scriptLoader

		this._isLoaded = false
	}

	load() {
		return new Promise((resolve, reject) => {
			if (this._window.isClient()) {
				if (this._isLoaded) {
					resolve()
				} else {
					this._scriptLoader
						.load(SCRIPT_URL)
						.then(() => {
							this._isLoaded = true
							resolve()
						})
						.catch(() => {
							reject()
						})
				}
			} else {
				reject()
			}
		})
	}

	toBlob(canvas, callback, mimeType) {
		this.load().then(() => {
			canvas.toBlob(callback, mimeType)
		})
	}
}
