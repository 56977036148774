import xss from 'xss'
import ns from 'ima/namespace'

ns.namespace('app.helpers.jsonLd')

export default class JsonLd {
	/**
	 * Vrátí JSON-LD pro homepage.
	 *
	 * @static
	 * @method getHomepage
	 * @param {String} logo Relativní url loga.
	 * @return {String} JSON-LD pro homepage.
	 */
	static getHomepage(logo) {
		return `{
			"@context": "http://schema.org",
			"@type": "WebSite",
			"name": "Sauto",
			"alternateName": "Sauto.cz",
			"url": "https://www.sauto.cz/",
			"sameAs": "",
			"potentialAction": {
				"@type": "SearchAction",
				"target": "https://www.sauto.cz/inzerce/osobni?varianta={search_term_string}",
				"query-input": "required name=search_term_string"
			},
			"publisher": {
				"@type": "Organization",
				"@id": "https://www.sauto.cz/",
				"url": "https://www.sauto.cz/",
				"name": "Sauto.cz",
				"logo": ${JsonLd.escape(`https://www.sauto.cz${logo}`)}
			}
		}`
	}

	/**
	 * Vrátí JSON-LD pro UserwebAdvertDetail.
	 *
	 * @static
	 * @method getUserwebAdvertDetail
	 * @param {Object<{{ itemName : string, itemDescription : string, itemPrice : string, itemImageURL : string, itemURL : string }}>} params - parametry nastavení schematu.
	 * @return {String} JSON-LD pro UserwebAdvertDetail.
	 */
	static getUserwebAdvertDetail(params) {
		const { itemURL, itemName, itemPrice, itemDescription, itemImageURL } = params

		return `{
			"@context": "http://schema.org",
			"@type": "Product",
			"name": ${JsonLd.escape(itemName)},
			"image": ${JsonLd.escape(itemImageURL)},
			"description": ${JsonLd.escape(itemDescription)},
			"offers": {
				"@type": "Offer",
				"priceCurrency": "CZK",
				"price": ${JsonLd.escape(String(itemPrice))},
				"url": ${JsonLd.escape(itemURL)}
			}
		}`
	}

	/**
	 * Escapuje json.
	 *
	 * @static
	 * @method escape
	 * @param {String|Object} jsonToEscape
	 * @return {String}
	 */
	static escape(jsonToEscape) {
		const unicodeEscape = (str = '') =>
			str.replace(/[\u0080-\uFFFF]/g, (match) => {
				return '\\u' + ('0000' + match.charCodeAt(0).toString(16)).slice(-4)
			})

		const strJson = unicodeEscape(JSON.stringify(jsonToEscape))

		return xss(strJson)
	}
}

ns.app.helpers.jsonLd.JsonLd = JsonLd
