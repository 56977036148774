import { useContext, useRef, useEffect } from 'react'
import ImaContext from 'ima/page/context'

export const useLocalize = () => {
	const { $Dictionary } = useContext(ImaContext)
	return $Dictionary.get.bind($Dictionary)
}

export const useFire = () => {
	const { $EventBus, $Window } = useContext(ImaContext)

	return (eventName, data, eventTarget = $Window.getWindow()) => {
		$EventBus.fire(eventTarget, eventName, data)
	}
}

export const useLink = () => {
	const router = useRouter()
	return router.link.bind(router)
}

export const useRouter = () => {
	const { $Router } = useContext(ImaContext)
	return $Router
}

export const usePrevious = (value) => {
	const ref = useRef()

	useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current
}

export const useABTest = () => {
	const { ABTestHelper } = useContext(ImaContext)
	return ABTestHelper
}

export const useRouteName = () => {
	const router = useRouter()

	try {
		const { route } = router.getCurrentRouteInfo()
		return route.getName()
	} catch (e) {
		return ''
	}
}
