
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['DoorsSelect'] = {
  doors: function(d) { return "Počet dveří"; },
  doorsPlaceholder: function(d) { return "Dveří"; },
  capacity: function(d) { return "Počet míst"; },
  capacityPlaceholder: function(d) { return "Míst"; },
  seats: function(d) { return "Počet sedadel"; },
  seatsPlaceholder: function(d) { return "Sedadel"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	