import React from 'react'
import PropTypes from 'prop-types'

import './PaymentPlaceholders.less'

const CLASSNAME = 'c-payment-pl'

const PaymentPlaceholders = (props) => {
	const { isPaymentType = false } = props

	const priceAndLengthArr = [0, 1]
	const paymentTypeArr = [0, 1, 2]

	const chosenArray = isPaymentType ? paymentTypeArr : priceAndLengthArr

	const renderTextInputItem = (key) => {
		const keyPreset = 'text-radio'
		return (
			<div key={`${keyPreset}-${key}`} className={`${CLASSNAME}__item`}>
				<div className={`${CLASSNAME}__radio-${key}`}></div>
			</div>
		)
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__list`}>
				{chosenArray.map((item) => renderTextInputItem(item))}
			</div>
		</div>
	)
}

PaymentPlaceholders.propTypes = {
	isPaymentType: PropTypes.bool
}

export default React.memo(PaymentPlaceholders)
