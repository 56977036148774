const getRouteName = (router) => {
	try {
		const { route } = router.getCurrentRouteInfo()
		return route.getName()
	} catch (e) {
		return undefined
	}
}

export default getRouteName
