import React, { useContext, useRef, useEffect } from 'react'
import Layout from 'app/component/layout/LayoutView'
import AdvertList from 'app/component/advertList/AdvertListView'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import SellerUrlConvertor from './SellerUrlConvertor'
import SellerSubheader from 'app/page/userweb/seller/sellerSubheader/SellerSubheader'
import CategoryPickerButton from 'app/component/pickerButton/CategoryPickerButtonView'
import SidePanel from 'app/component/filters/forms/sidePanel/SidePanel'
import Filters from 'app/component/filters/FiltersView'
import STATE_KEYS from 'app/page/userweb/seller/SellerStateKeys'
import ImaContext from 'ima/page/context'

import './SellerView.less'

const CLASSNAME = 'p-uw-seller'

export default function SellerView({
	[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease,
	[STATE_KEYS.CATEGORIES]: categories
}) {
	const context = useContext(ImaContext)
	const router = context.$Router

	const listRef = useRef()

	const isAnyCategory = categories?.length > 0

	const urlPageCreator = (page) => {
		const { route, params } = router.getCurrentRouteInfo()
		const newParams = SellerUrlConvertor.getPagingPageParams(page, params)

		return router.link(route.getName(), newParams)
	}

	useEffect(() => {
		document.body.setAttribute('data-dot', 'seller-detail')

		return () => {
			document.body.removeAttribute('data-dot')
		}
	}, [])

	return (
		<Layout className={CLASSNAME}>
			<SellerSubheader />
			<div className={`${CLASSNAME}__main-container`}>
				{isAnyCategory && (
					<div className={`${CLASSNAME}__mobile`}>
						<CategoryPickerButton className={`${CLASSNAME}__sort-btn`} />
					</div>
				)}
				<SidePanel
					showCategoryPicker={isAnyCategory}
					isSeller={true}
					className={`${CLASSNAME}__side-filter`}
				/>
				<div className={`${CLASSNAME}__filters-button`}>
					<Filters isOnHp={false} />
				</div>
				<AdvertList
					isOperatingLease={isOperatingLease}
					showFilterButton={false}
					urlPageCreator={urlPageCreator}
					listRef={listRef}
				/>
			</div>
		</Layout>
	)
}
