/**
 * Tento Login Helper je duplicitní k @ima/plugin-login.
 * Takto je to kvůli staré verzi IMA.js, která neumožňuje nainstalovat novější plugin-login.
 */

export default class LoginHelper {
	static openLogin({ sourceComponent = '' }) {
		if (LoginHelper.isLogin()) window.login.open({ sourceComponent })
	}

	static openRegister({ sourceComponent = '' }) {
		if (LoginHelper.isLogin()) window.login.register.open({ sourceComponent })
	}

	static isLogin() {
		return 'login' in window
	}
}

LoginHelper.SOURCE_COMPONENT = {
	FAVORITES_SHOW: 'favourites_show',
	FAVORITES_ADD: 'favourites_add',
	SAVED_SEARCH_SHOW: 'saved_search_show',
	SAVED_SEARCH_ADD: 'saved_search_add',
	ADVERT_ADD_LOGIN: 'advert_add_login',
	ADVERT_ADD_REGISTER: 'advert_add_register',
	REVIEW_ADD: 'review_add',
	REVIEW_LIKE: 'review_like',
	REVIEW_REPLY: 'review_reply',
	SELLER_LOGIN: 'seller_login',
	SELLER_REGISTER: 'seller_register'
}
