import React from 'react'
import PropTypes from 'prop-types'
import { Surface } from '@sznds/react'
import { Popup, StatusMessage, FormSuccess } from '@inzeraty/components'
import AbstractComponent from 'app/base/AbstractComponent'
import DemandForm from 'app/component/demandForm/DemandFormView'
import { Format } from '@inzeraty/helpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import operatingLeasePropTypes from 'app/model/operatingLease/OperatingLeasePropTypes'
import AdvertImageResponsiveSettings from 'app/page/userweb/advertDetail/AdvertImageResponsiveSettings'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './PopupDemandForm.less'
import './PopupDemandFormCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-popup-demand-form'

export default class PopupDemandForm extends AbstractComponent {
	static get propTypes() {
		return {
			advertEntity: PropTypes.shape(advertPropTypes).isRequired,
			selectedOperatingLeaseVariant: PropTypes.shape(operatingLeasePropTypes),
			isOpen: PropTypes.bool,
			onClose: PropTypes.func,
			sellerName: PropTypes.string,
			getLocationOnPage: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			isOpen: false,
			onClose: DEFAULT_PROPS.FUNCTION,
			getLocationOnPage: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._dictionary = {
			close: this.localize('PopupDemandForm.close')
		}

		this.state = {
			isSuccess: false,
			isError: false,
			errorMessage: '',
			isImgPortrait: false
		}

		this._imgRef = React.createRef()

		this._renderFormHeader = this._renderFormHeader.bind(this)
		this._onSubmit = this._onSubmit.bind(this)
		this._onError = this._onError.bind(this)
		this._onErrorClose = this._onErrorClose.bind(this)
		this._onSuccess = this._onSuccess.bind(this)
		this._onSuccessClose = this._onSuccessClose.bind(this)
		this._onImgLoaded = this._onImgLoaded.bind(this)
	}

	_renderFormHeader(renderCloseButton) {
		const title = this.localize('PopupDemandForm.title')
		const { advertEntity, sellerName = '' } = this.props
		const { images, namePrefix, nameSuffix } = advertEntity
		const { isSuccess, isImgPortrait } = this.state

		const hasImgUrl = images.length && images[0].url
		let imageProps = null

		if (hasImgUrl) {
			imageProps = {
				src: AdvertImageResponsiveSettings.normalSettings.src(images[0].url),
				srcSet: AdvertImageResponsiveSettings.normalSettings.srcSet(images[0].url)
			}
		}

		if (!isSuccess) {
			return (
				<Surface tagName='div' surface={4} className={`${CLASSNAME}__header`}>
					<div className={`${CLASSNAME}__title`}>{title}</div>

					{renderCloseButton()}

					<div className={`${CLASSNAME}__header-locket`}>
						{hasImgUrl && (
							<div
								className={this.cssClasses({
									[`${CLASSNAME}__header-image`]: true,
									[`${CLASSNAME}__header-image--portrait`]: isImgPortrait
								})}
							>
								<img
									className={`${CLASSNAME}__image`}
									ref={this._imgRef}
									onLoad={this._onImgLoaded}
									alt=''
									{...imageProps}
								/>
							</div>
						)}
						<div className={`${CLASSNAME}__header-texts`}>
							<div className={`${CLASSNAME}__header-car-caption`}>
								<span className={`${CLASSNAME}__header-car-caption--preffix`}>{namePrefix}</span>
								{nameSuffix && (
									<span className={`${CLASSNAME}__header-car-caption--suffix`}>{nameSuffix}</span>
								)}
							</div>
							<div className={`${CLASSNAME}__header-seller-caption`}>{sellerName}</div>
						</div>
					</div>
				</Surface>
			)
		}
	}

	render() {
		const { isSuccess, isError, errorMessage } = this.state
		const { advertEntity, isOpen, onClose, getLocationOnPage } = this.props
		const dataDotProps = {
			form: 'answer',
			close: 'close'
		}

		return (
			<Popup
				className={`${CLASSNAME}__section`}
				isOpen={isOpen}
				onClose={onClose}
				dictionary={this._dictionary}
				renderHeader={this._renderFormHeader}
				dataDot={dataDotProps}
				hasLightbox={true}
			>
				{isError && (
					<div className={`${CLASSNAME}__error-message`}>
						<StatusMessage
							title={errorMessage}
							type={StatusMessage.TYPE.ERROR}
							style={StatusMessage.STYLE.INLINE}
							onClose={this._onErrorClose}
						/>
					</div>
				)}
				{isSuccess && (
					<FormSuccess
						successText={this.localize('PopupDemandForm.messageSentText')}
						buttonText={this.localize('PopupDemandForm.backToAd')}
						onClose={this._onSuccessClose}
					/>
				)}
				{!isSuccess && (
					<UserEntityContext.Consumer>
						{(userEntity) => {
							return (
								<DemandForm
									userEntity={userEntity}
									className={this.cssClasses({
										[`${CLASSNAME}__content`]: true,
										[`${CLASSNAME}__content--shifted`]: isError
									})}
									defaultMessage={this._composeDefaultMessage()}
									onSubmit={this._onSubmit}
									onError={this._onError}
									onSuccess={this._onSuccess}
									advertEntity={advertEntity}
									getLocationOnPage={getLocationOnPage}
								/>
							)
						}}
					</UserEntityContext.Consumer>
				)}
			</Popup>
		)
	}

	_composeDefaultMessage() {
		const { advertEntity, selectedOperatingLeaseVariant } = this.props
		const { namePrefix = '', manufacturingDate, inOperationDate, tachometer, price } = advertEntity

		const removeTrailingComma = (text) => text.replace(/, ?$/g, '')

		const name = removeTrailingComma(namePrefix)
		const operationDate = inOperationDate ? new Date(inOperationDate) : new Date(manufacturingDate)
		const operationYear = operationDate.getFullYear()
		const formattedMileage = tachometer
			? `${Format.number(Number(tachometer))} ${this.localize('Base.KM')}`
			: ''
		const formattedPrice = price
			? `${this.localize('PopupDemandForm.price')}${Format.number(Number(price))},- ${this.localize(
					'Base.CZK'
			  )}`
			: ''
		const formattedOperatingLeasePrice = selectedOperatingLeaseVariant
			? `${this.localize('PopupDemandForm.price')}${Format.number(
					Number(selectedOperatingLeaseVariant.priceWithoutVat)
			  )},- ${this.localize('Base.pricePerMonthWithoutVAT', {
					CURRENCY: this.localize('Base.CZK')
			  })}`
			: ''
		const formattedAnnualDistance = selectedOperatingLeaseVariant
			? `${this.localize('PopupDemandForm.annualDistance')}${Format.number(
					selectedOperatingLeaseVariant.annualDistance
			  )} ${this.localize('Base.KM')}`
			: ''
		const formattedPeriod = selectedOperatingLeaseVariant
			? `${this.localize('PopupDemandForm.period')}${this.localize('Base.month', {
					MONTH: selectedOperatingLeaseVariant.period,
					MONTH_FORMATTED: Format.number(selectedOperatingLeaseVariant.period)
			  })}`
			: ''
		const formattedServices = selectedOperatingLeaseVariant
			? `${this.localize('PopupDemandForm.services')}${
					selectedOperatingLeaseVariant.servicesCb?.map(({ name }) => name).join(', ') || '–⁠'
			  }`
			: ''

		const isOperatingLease = selectedOperatingLeaseVariant && selectedOperatingLeaseVariant.id

		const advertInfoText = [
			name,
			operationYear,
			formattedMileage,
			isOperatingLease && formattedAnnualDistance,
			isOperatingLease && formattedPeriod,
			isOperatingLease && formattedServices,
			isOperatingLease ? formattedOperatingLeasePrice : formattedPrice
		]
			.filter((text) => !!text)
			.join(', ')

		return this.localize('PopupDemandForm.messageText', {
			ADVERT_INFO_TEXT: advertInfoText
		})
	}

	_onSuccess() {
		this.setState({
			isError: false,
			errorMessage: '',
			isSuccess: true
		})
	}

	_onSuccessClose() {
		const { onClose } = this.props

		this.setState({
			isSuccess: false
		})

		onClose()
	}

	_onError(errorMessage) {
		this.setState({
			isError: true,
			errorMessage
		})
	}

	_onErrorClose() {
		this.setState({
			isError: false,
			errorMessage: ''
		})
	}

	_onSubmit(formData, onSuccessCallback, onErrorCallback) {
		const { advertEntity, selectedOperatingLeaseVariant } = this.props

		const operatingLeaseVariantId =
			selectedOperatingLeaseVariant && selectedOperatingLeaseVariant.id

		this.fire('demandSubmit', {
			advertEntity,
			formData: Object.assign(
				{},
				formData,
				!isNullOrUndefined(operatingLeaseVariantId) && {
					operating_lease_id: operatingLeaseVariantId
				}
			),
			onSuccessCallback,
			onErrorCallback
		})
	}

	_onImgLoaded(image) {
		if (image.target.width <= image.target.height) {
			this.setState({
				isImgPortrait: true
			})
		}
	}
}
