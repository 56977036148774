import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import TableHead from 'app/component/tableHead/TableHead'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import CodebooksValuesEntity from 'app/model/codebooks/CodebooksValuesEntity'
import { PaginationEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import ActionButton from 'app/component/actionButton/ActionButton'
import AddNewButton from '../../components/addNewButton/AddNewButton'
import { EDIT_FILLED, TRASH_FILLED, CLOSE_OUTLINE, CHECKER_NORMAL } from '@sznds/icons'
import { ACTIONS } from './IACodebookEquipmentListController'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import EntityFormPopup from '../../components/entityFormPopup/EntityFormPopup'
import EntityForm from 'app/component/entityForm/EntityForm'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import 'app/page/userweb/newAdvert/equipment/components/widgets/equipment/EquipmentWidgetCS.json'
import './IACodebookEquipmentListView.less'

const CLASSNAME = 'p-ia-codebook-list'

export default function IACodebookEquipmentListView({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading,
	isEditPopupOpened,
	formLineEntities = DEFAULT_PROPS.ARRAY,
	entityFormError,
	isWaiting
}) {
	const localize = useLocalize()
	const fire = useFire()
	const { rights = [] } = useContext(AdminEntityContext) || {}

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		const actions = []

		if (rights.includes(ADMIN_RIGHTS.CODEBOOK_VALUES_EDIT)) {
			// - pridavani / odebirani z vyhledavani
			actions.push((entity, actionButtonProps = {}) => {
				const { forFilters } = entity

				if (forFilters) {
					return (
						<ActionButton
							key='forFilterRemove'
							icon={CLOSE_OUTLINE}
							title='Odebrat z vyhledávání'
							onClick={() => action(ACTIONS.REMOVE_VALUE_FROM_SEARCH, [entity])}
							{...actionButtonProps}
						/>
					)
				} else {
					return (
						<ActionButton
							key='forFilterAdd'
							icon={CHECKER_NORMAL}
							title='Přidat do vyhledávání'
							onClick={() => action(ACTIONS.ADD_VALUE_TO_SEARCH, [entity])}
							{...actionButtonProps}
						/>
					)
				}
			})

			// - editace
			actions.push((entity, actionButtonProps = {}) => {
				return (
					<ActionButton
						icon={EDIT_FILLED}
						key='edit'
						title='Upravit číselník'
						onClick={() => action(ACTIONS.OPEN_EDIT_POPUP, [entity])}
						{...actionButtonProps}
					/>
				)
			})
		}

		if (rights.includes(ADMIN_RIGHTS.CODEBOOK_VALUES_DELETE)) {
			actions.push((entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='delete'
					title='Smazat'
					onClick={() => action(ACTIONS.DELETE_VALUE, [entity])}
					{...actionButtonProps}
				/>
			))
		}

		return actions
	}

	return (
		<Layout title='Číselníky výbavy'>
			{rights.includes(ADMIN_RIGHTS.CODEBOOK_VALUES_CREATE) && (
				<AddNewButton
					text='Přidat číselník'
					onClick={() =>
						fire('action', {
							action: ACTIONS.OPEN_EDIT_POPUP
						})
					}
				/>
			)}

			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='name' sortParam='name' isDefaultAscending={true}>
						Název výbavy
					</TableHeadSorter>,
					<TableHead key='codebook_category_name'>Kategorie</TableHead>,
					<TableHead key='category'>Skupina</TableHead>,
					<TableHead key='for_filters'>Vyhledávání</TableHead>
				]}
				rowCells={[
					({ name }) => name,
					({ codebookCategoryName }) => codebookCategoryName,
					({ category }) => (category ? localize(`EquipmentWidget.${category}`) : ''),
					({ forFilters }) => (forFilters ? 'Ano' : 'Ne')
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>

			{isEditPopupOpened && (
				<EntityFormPopup
					title={
						formLineEntities.length > 0
							? formLineEntities.find((f) => f.id === 'id' && f.value)
								? 'Editace číselníku'
								: 'Nový číselník'
							: ''
					}
					onClose={() =>
						fire('action', {
							action: ACTIONS.CLOSE_EDIT_POPUP
						})
					}
				>
					<EntityForm
						variant={VARIANT.POPUP_FORM}
						formLineEntities={formLineEntities}
						entityFormError={entityFormError}
						isWaiting={isWaiting}
						className={`${CLASSNAME}__edit-form`}
					/>
				</EntityFormPopup>
			)}
		</Layout>
	)
}

IACodebookEquipmentListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(CodebooksValuesEntity)),
		paginationEntity: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool,
	isEditPopupOpened: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	isWaiting: PropTypes.bool
}
