
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['MonthYearSelect'] = {
  linkedRequiredFieldsDescription: function(d) { return "Vyplňte jednu z položek \"Rok výroby\" nebo \"V provozu od\"."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	