
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['RatingTooltip'] = {
  blindClosePrefix: function(d) { return "Konec informací o kontrole recenzí"; },
  ariaOpener: function(d) { return "Informace o kontrole recenzí"; },
  description: function(d) { return "Seznam.cz, a.s. recenze neověřuje, ale kontroluje soulad recenzí s pravidly či odstraňuje podvodný obsah, pokud je nahlášen nebo identifikován."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	