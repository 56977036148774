import React from 'react'
import classnames from 'classnames'
import { useLocalize } from 'app/base/componentHelpers'
import FilterPopup from '../../components/filterPopup/FilterPopup'
import FilterConstants from 'app/model/filter/FilterConstants'
import PropTypes from 'prop-types'

import './EquipmentPopup.less'
import './EquipmentPopupCS.json'

const CLASSNAME = 'c-equipment-popup'

const EquipmentPopup = (props) => {
	const { isSmall, children, onClose } = props

	const localize = useLocalize()

	const renderHeaderContent = (data) => {
		const { renderCloseButton } = data

		return (
			<div>
				<div className={`${CLASSNAME}__heading`}>
					<span className={`${CLASSNAME}__title`}>{localize('EquipmentPopup.name')}</span>
				</div>
				{renderCloseButton()}
			</div>
		)
	}

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}--small`]: isSmall
			})}
		>
			<FilterPopup
				filterId={FilterConstants.formLineIds.EQUIPMENT}
				hasStickyHeader={true}
				hasLightbox={true}
				renderHeaderContent={renderHeaderContent}
				onClose={onClose}
			>
				{children}
			</FilterPopup>
		</div>
	)
}

EquipmentPopup.propTypes = {
	children: PropTypes.node,
	isSmall: PropTypes.bool,
	onClose: PropTypes.func
}

export default React.memo(EquipmentPopup)
