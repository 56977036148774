import React, { forwardRef } from 'react'
import classnames from 'classnames'
import { Icon, InputSurface, Spinner } from '@sznds/react'
import { CHEVRON_UP_OUTLINE_24, CHEVRON_DOWN_OUTLINE_24 } from '@sznds/icons'
import { useLocalize } from 'app/base/componentHelpers'
import PropTypes from 'prop-types'

import './DropDownButton.less'
import './DropDownButtonCS.json'

const CLASSNAME = 'c-drop-down-button'

const DropDownButton = forwardRef((props, forwardedRef) => {
	const localize = useLocalize()

	const restProps = Object.assign({}, props)

	delete restProps.className
	delete restProps.isDisabled
	delete restProps.isLoading
	delete restProps.isOpen
	delete restProps.isOptionSelected
	delete restProps.renderText
	delete restProps.error

	const {
		className,
		isDisabled,
		isLoading,
		isOpen,
		isOptionSelected,
		renderText,
		error,
		size
	} = props

	return (
		<InputSurface
			type='button'
			tagName='button'
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}--small`]: size === 'small',
				[className]: !!className
			})}
			error={error}
			disabled={isLoading || isDisabled}
			ref={forwardedRef}
			{...restProps}
		>
			<span
				className={classnames({
					[`${CLASSNAME}__selected-option`]: isOptionSelected,
					[`${CLASSNAME}__no-selected-option`]: !isOptionSelected
				})}
			>
				{renderText(isLoading ? localize('DropDownButton.loading') : undefined)}
			</span>

			{isLoading ? (
				<Spinner className={`${CLASSNAME}__spinner`} />
			) : (
				<Icon
					className={`${CLASSNAME}__icon-arrow`}
					symbol={isOpen ? CHEVRON_UP_OUTLINE_24 : CHEVRON_DOWN_OUTLINE_24}
				/>
			)}
		</InputSurface>
	)
})

DropDownButton.propTypes = {
	className: PropTypes.string,
	isDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	isOpen: PropTypes.bool,
	isOptionSelected: PropTypes.bool,
	renderText: PropTypes.func,
	error: PropTypes.bool,
	size: PropTypes.string
}

export default DropDownButton
