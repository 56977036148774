import { createEntityList } from '@inzeraty/form-lines'
import { Validation } from '@inzeraty/helpers'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'

export default class IARoleListController extends AbstractInternalAdminController {
	static get ACTIONS() {
		return Object.freeze({
			DELETE: 'delete',
			OPEN_EDIT_ROLE_POPUP: 'openEditRolePopup',
			CLOSE_EDIT_ROLE_POPUP: 'closeEditRolePopup'
		})
	}

	constructor(dependenciesHelper, entitiesFilterExtension, roleService, entityFormExtension) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._roleService = roleService
		this._entityFormExtension = entityFormExtension

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)

		this.addExtension(this._entityFormExtension)

		this._entityFormExtension.initialize({
			getSubmitPromise: this._getEditFormSubmitPromise.bind(this),
			validations: this._editFormValidations.bind(this),
			handleSubmitSuccess: this._handleEditFormSubmitSuccess.bind(this),
			handleSubmitError: this._handleEditFormSubmitError.bind(this)
		})
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			statusErrorMessage: null,
			isEditRolePopupOpened: false
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			statusErrorMessage: null,
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const fetchRoles = async (params) => {
			try {
				const { roleEntities, paginationEntity } = await this._roleService.getRoles(params)

				return {
					entities: roleEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		this._pageLoaderExtension.show()

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)
		const entitiesAndPagination = await fetchRoles(params)

		this.setState({
			isLoading: false
		})

		this._pageLoaderExtension.hide()

		return entitiesAndPagination
	}

	_handleAction({ action, entities = [], entitiesIds = [] }) {
		let promise
		let errorMessage = ''

		const [entity = {}] = entities
		const { name } = entity

		switch (action) {
			case IARoleListController.ACTIONS.OPEN_EDIT_ROLE_POPUP:
				this._entityFormExtension.setFormLineEntitiesData(
					this._getFormLineEntitiesForEditForm(entity)
				)
				this.setState({
					isEditRolePopupOpened: true,
					entityFormError: null
				})
				return {}

			case IARoleListController.ACTIONS.CLOSE_EDIT_ROLE_POPUP:
				this.setState({
					isEditRolePopupOpened: false
				})
				return {}

			case IARoleListController.ACTIONS.DELETE:
				if (window.confirm(`Opravdu si přejete smazat roli ${name}`)) {
					promise = this._roleService.deleteRole(entitiesIds[0])
					errorMessage = `Nepodařilo se smazat ${name}`
				} else {
					return {}
				}
				break
		}

		return {
			promise,
			getErrorMessage: () => errorMessage
		}
	}

	async _processSuccessAction(data) {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	_filterInitiezerClb(routeParams) {
		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'id',
					name: 'ID'
				}
			])
		])
	}

	_getFormLineEntitiesForEditForm(roleEntity = {}) {
		const isNew = !roleEntity.id
		const formLineEntities = []

		if (!isNew) {
			formLineEntities.push({
				id: 'id',
				label: 'ID',
				value: roleEntity.id,
				widget: IDS.TEXT,
				required: true,
				disabled: true
			})
		}

		formLineEntities.push({
			id: 'name',
			label: 'Název',
			value: roleEntity.name,
			widget: IDS.TEXT,
			required: true
		})

		return formLineEntities
	}

	_editFormValidations() {
		return {
			name: ({ value }) => (Validation.isNotEmpty(value) ? '' : 'Zadejte název role')
		}
	}

	_getEditFormSubmitPromise(formData) {
		const { id, ...data } = formData
		let promise

		if (id) {
			promise = this._roleService.updateRole(id, data)
		} else {
			promise = this._roleService.createRole(formData)
		}

		return promise
	}

	_handleEditFormSubmitError() {
		this._pageLoaderExtension.hide()
		return {
			entityFormError: {
				title: 'Nepodařilo se odeslat formulář'
			}
		}
	}

	_handleEditFormSubmitSuccess() {
		this._pageLoaderExtension.hide()

		this._processSuccessAction()
		this.setState({
			isEditRolePopupOpened: false
		})
	}
}
