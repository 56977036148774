
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['MySavedSearchList'] = {
  newsCount: function(d) { return plural(d.COUNT, 0, cs, { one: d.COUNT + " nový", few: d.COUNT + " nové", other: d.COUNT + " nových" }); },
  itemsCount: function(d) { return plural(d.COUNT, 0, cs, { one: d.COUNT + " inzerát", few: d.COUNT + " inzeráty", other: d.COUNT + " inzerátů" }); },
  savedSearchActions: function(d) { return "Možnosti"; },
  actionsBlindClosePrefix: function(d) { return "Konec možností uloženého hledání"; },
  actionEditNotification: function(d) { return "Nastavení uloženého hledání"; },
  failedToLoadMySavedSearch: function(d) { return "Uložené hledání se nepodařilo načíst. Zkuste to znovu."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	