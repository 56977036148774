import React, { useState, useContext } from 'react'
import { Popup, StatusMessage } from '@inzeraty/components'
import { Button } from '@sznds/react'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import { Format, DateHelpers } from '@inzeraty/helpers'
import { getPreviousMonth } from 'app/helpers/date/dateHelpers'
import { useRouteName } from 'app/base/componentHelpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import ImaContext from 'ima/page/context'
import { TERMS_AGREEMENT_CLOSED_SESSION } from 'app/base/StorageIds'

import './TermsAgreement.less'
import './TermsAgreementCS.json'

const CLASSNAME = 'c-terms-agreement'

const TermsAgreement = () => {
	const [isOpen, setIsOpen] = useState(true)
	const [showErrorMessage, setShowErrorMessage] = useState(false)
	const localize = useLocalize()
	const routeName = useRouteName()
	const SHOW_ON_ROUTES = [
		ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST,
		ROUTE_NAMES.CLIENT_ADMIN.CLIENT_PROFILE,
		ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS
	]

	const { $SessionStorage } = useContext(ImaContext)
	const userEntity = useContext(UserEntityContext)
	const {
		premiseVopVersion,
		premiseVopVersionAccepted,
		premiseVopVersionDateFrom: vopValidFrom,
		premises = []
	} = userEntity

	const fire = useFire()

	const validFrom = DateHelpers.getDateObject(vopValidFrom)
	const today = new Date()
	const allowClosePopup = validFrom ? DateHelpers.compareDates(validFrom, today) !== -1 : true

	const canShowDueToDate = () => {
		const showFromDefault = getPreviousMonth(validFrom)
		/*
	    	customShowFrom reprezentuje pripad, ze se obchod rozhodne, ze chce zobrazovat 
			popup jindy, nez je defaultně uvedeno měsíc dopředu 
			showFrom => od kdy chceme zobrazovat (můžeme změnit po 28.2.2022 pokud přijdou nové SP)
			validFrom => definuje, ke kterému validFrom se showFrom váže (můžeme změnit po 28.2.2022 pokud přijdou nové SP)
		*/
		const customShowFrom = {
			showFrom: new Date(2022, 0, 10),
			validFrom: new Date(2022, 1, 28)
		}

		return (
			DateHelpers.compareDates(showFromDefault, today) === -1 ||
			(customShowFrom &&
				DateHelpers.compareDates(validFrom, customShowFrom.validFrom) === 0 &&
				DateHelpers.compareDates(customShowFrom.showFrom, today) === -1)
		)
	}

	const showPopup =
		SHOW_ON_ROUTES.includes(routeName) &&
		!!premises.length &&
		canShowDueToDate() &&
		premiseVopVersion !== premiseVopVersionAccepted &&
		(allowClosePopup ? !$SessionStorage.has(TERMS_AGREEMENT_CLOSED_SESSION) : true)

	const isRenderedOnServer = typeof window === 'undefined'

	if (isRenderedOnServer || !showPopup) return null

	const handleClosePopup = () => {
		if (allowClosePopup) {
			$SessionStorage.set(TERMS_AGREEMENT_CLOSED_SESSION, 'true')
			setIsOpen(false)
		}
	}

	return (
		isOpen && (
			<div className={CLASSNAME}>
				<Popup
					isOpen={true}
					onClose={handleClosePopup}
					hasLightbox={true}
					renderHeader={(renderCloseButton) => {
						return (
							<div>
								<div className={`${CLASSNAME}__header`}>{localize('TermsAgreement.change')}</div>
								{renderCloseButton()}
								<div className={`${CLASSNAME}__divider`} />
							</div>
						)
					}}
				>
					{showErrorMessage && (
						<StatusMessage
							className={`${CLASSNAME}__status-message`}
							type={StatusMessage.TYPE.ERROR}
							title={localize('TermsAgreement.errorMessage')}
						/>
					)}
					<div className={`${CLASSNAME}__text-content`}>
						{localize('TermsAgreement.greetings')}
						<br />
						<br />
						{localize('TermsAgreement.text', {
							DATE: Format.date(vopValidFrom)
						})}
						<a
							className={`${CLASSNAME}__link`}
							target='_blank'
							rel='noopener noreferrer'
							href='https://napoveda.seznam.cz/cz/sauto-cz/sauto-pravidla/smluvni-podminky-sluzby-sauto.cz'
						>
							{localize('TermsAgreement.ourPage')}
						</a>
						. {localize('TermsAgreement.ifDisagree')}
						<br />
						<br />
						{localize('TermsAgreement.salesAvailable')}
					</div>
					<div className={`${CLASSNAME}__button-section`}>
						<Button
							onClick={handleClosePopup}
							text={localize('TermsAgreement.btnClose')}
							primary={false}
						/>
						<Button
							className={`${CLASSNAME}__button`}
							onClick={() => {
								fire('agreeWithTerms', {
									id: !!premises.length && premises[0].id,
									callback: () => {
										setIsOpen(false)
										$SessionStorage.set(TERMS_AGREEMENT_CLOSED_SESSION, 'true')
									},
									errorCallback: () => {
										setShowErrorMessage(true)
									}
								})
							}}
							text={localize('TermsAgreement.btnAgree')}
							primary={true}
						/>
					</div>
				</Popup>
			</div>
		)
	)
}

export default TermsAgreement
