const SKODA_PLUS_VALUE = 1
const DAS_WELT_AUTO_VALUE = 2
const CITROEN_SELECT_VALUE = 3
const PEUGEOT_VALUE = 4
const EMIL_FREY_VALUE = 6
const GARANT_VALUE = 7
const OPEL_VALUE = 8

const forbiddenCertifiedProgramsValues = [
	SKODA_PLUS_VALUE,
	DAS_WELT_AUTO_VALUE,
	CITROEN_SELECT_VALUE,
	PEUGEOT_VALUE,
	EMIL_FREY_VALUE,
	GARANT_VALUE,
	OPEL_VALUE
]

export function isForbiddenCertifiedProgram(certificationCbValue) {
	return forbiddenCertifiedProgramsValues.some((value) => value === certificationCbValue)
}
