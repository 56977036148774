import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import FilterRow from '../../components/filterRow/FilterRowView'
import BrandIcon from '../../components/brandIcon/BrandIconView'
import { Format } from '@inzeraty/helpers'
import { CategoryEntity } from '@inzeraty/models'
import RouteNames from 'app/base/RouteNames'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import { CATEGORIES } from 'app/base/Constants'

import './BrandForm.less'
import './BrandFormCS.json'

const CLASSNAME = 'cf-brand-form'

export default class BrandForm extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			context: PropTypes.string,
			categoryEntity: PropTypes.instanceOf(CategoryEntity).isRequired,
			filteredBrandName: PropTypes.string,
			filteredAdvertsCount: PropTypes.object,
			isLoadingFilteredAdvertsCount: PropTypes.bool,
			onBrandClick: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			className: '',
			formLineEntity: DEFAULT_PROPS.OBJECT,
			categoryEntity: new CategoryEntity({}),
			filteredBrandName: '',
			filteredAdvertsCount: DEFAULT_PROPS.OBJECT,
			isLoadingFilteredAdvertsCount: false,
			onBrandClick: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderBrand = this._renderBrand.bind(this)
		this._renderFavoriteBrand = this._renderFavoriteBrand.bind(this)
		this._renderBrandItem = this._renderBrandItem.bind(this)
		this._renderBrandLink = this._renderBrandLink.bind(this)
		this._filterBrands = this._filterBrands.bind(this)

		this._showBrandIconInAll = false
	}

	render() {
		return this._renderFullForm()
	}

	_renderFullForm() {
		const { className, formLineEntity, categoryEntity } = this.props
		const { options = [] } = formLineEntity
		const { id: categoryId } = categoryEntity

		const filteredFavoriteBrands = this.utils.FilterHelper.getSortedFavoriteOptions(options)
			.filter(this._filterBrands)
			.map(this._renderFavoriteBrand)

		this._showBrandIconInAll = CATEGORIES.PASSENGER_CARS.id === categoryId

		const filteredAllBrands = options.filter(this._filterBrands).map(this._renderBrand)

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{this._renderList(this.localize('BrandForm.favoriteBrands'), filteredFavoriteBrands)}
				{this._renderList(this.localize('BrandForm.allBrands'), filteredAllBrands)}
			</div>
		)
	}

	_filterBrands(brand) {
		const brandLabel = Format.removeDiacritics(brand.name).toLowerCase()
		const filteredBrandName = Format.removeDiacritics(this.props.filteredBrandName).toLowerCase()

		return brandLabel.includes(filteredBrandName)
	}

	_renderFavoriteBrand(brand, index) {
		return this._renderBrandItem(brand, index, 'manufacturer-top', true)
	}

	_renderBrand(brand, index) {
		return this._renderBrandItem(brand, index, 'manufacturer-everything', this._showBrandIconInAll)
	}

	_renderBrandLink(brand) {
		const url = this._getBrandUrl(brand)
		const { filteredAdvertsCount } = this.props
		const { value } = brand

		const count = filteredAdvertsCount[value] || 0

		if (count > 0) {
			return (
				<a
					key={brand.value}
					href={url}
					onClick={(event) => {
						event.preventDefault()
					}}
				>
					{brand.name}
				</a>
			)
		}
	}

	_getBrandUrl(brand) {
		const { seoName: brandSeoName } = brand
		const categorySeoName = this._getCategorySeoName()

		return this.link(RouteNames.USERWEB.ADVERT_LIST, {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: brandSeoName
		})
	}

	_renderBrandItem(brand, index, dataDotDataType, withLogo = false) {
		const { value, seoName } = brand
		const url = this._getBrandUrl(brand)
		const { filteredAdvertsCount, isLoadingFilteredAdvertsCount } = this.props

		return (
			<li key={value}>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
				<div className={`${CLASSNAME}__brand`} onClick={() => this._onBrandClick(value)}>
					<FilterRow
						count={filteredAdvertsCount[value]}
						isCountLoading={isLoadingFilteredAdvertsCount}
						label={brand.name}
						url={url}
						dataDot='choose-value'
						index={index}
						dataDotDataType={dataDotDataType}
					>
						{withLogo && (
							<BrandIcon
								value={value}
								seoName={seoName}
								categorySeoName={this._getCategorySeoName()}
							/>
						)}
					</FilterRow>
				</div>
			</li>
		)
	}

	_renderList(header, items = []) {
		if (items.length) {
			return (
				<React.Fragment>
					{header && <div className={`${CLASSNAME}__header`}>{header}</div>}

					<ul>{items}</ul>
				</React.Fragment>
			)
		}
	}

	_getCategorySeoName() {
		const { categoryEntity } = this.props

		return categoryEntity.seoName
	}

	_onBrandClick(brandValue) {
		const { formLineEntity, onBrandClick } = this.props
		const { options } = formLineEntity
		const { id: brandId, isFavorite } = options.find((option) => option.value === brandValue)

		if (isFavorite) {
			this.utils.FilterHelper.increaseFavoriteBrandClick(brandValue)
		}

		this.fire('downloadModels', {
			brandId
		})

		onBrandClick(brandValue)
	}
}
