
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['TermsAgreement'] = {
  change: function(d) { return "Změna smluvních podmínek"; },
  greetings: function(d) { return "Hezký den."; },
  text: function(d) { return "Rádi bychom vás informovali, že " + d.DATE + " začnou platit nové smluvní podmínky serveru Sauto.cz. Úplné znění nových smluvních podmínek najdete už teď na "; },
  ourPage: function(d) { return "našich webových stránkách"; },
  ifDisagree: function(d) { return "Pokud byste s novými podmínkami nesouhlasili, můžete je odmítnout tak, jak je v nich uvedeno."; },
  salesAvailable: function(d) { return "V případě dalších otázek jsou vám k dispozici obchodní zástupci."; },
  btnClose: function(d) { return "Zavřít"; },
  btnAgree: function(d) { return "Souhlasím"; },
  errorMessage: function(d) { return "Při zpracování požadavku došlo k chybě, opakujte tuto akci později"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	