import IAAdvertEditBaseController from '../IAAdvertEditBaseController'
import STATE_KEYS from './IAAdvertPhotosEditStateKeys'
import PhotosUploaderExtension from 'app/component/photosUploader/PhotosUploaderExtension'
import VideoUploaderExtension from 'app/component/videoUploader/VideoUploaderExtension'
import STEPS_STATE_KEYS from 'app/page/userweb/newAdvert/component/stepper/StepperStateKeys'
import { loadDataForSteps } from 'app/page/userweb/newAdvert/component/stepper/StepperUtils'
import { getSteps } from '../stepper/steps'

import 'app/page/userweb/newAdvert/photos/PhotosCS.json'

export default class IAAdvertPhotosEditController extends IAAdvertEditBaseController {
	constructor(dependenciesHelper, advertService, photosUploaderExtension, videosUploaderExtension) {
		super(dependenciesHelper)

		this._advertService = advertService
		this._photosUploaderExtension = photosUploaderExtension
		this._videosUploaderExtension = videosUploaderExtension

		this._dictionary = this._utils.$Dictionary
	}

	init() {
		super.init()

		this.addExtension(this._photosUploaderExtension)
		this.addExtension(this._videosUploaderExtension)
	}

	load() {
		const advertId = this._getAdvertId()

		const advertEntity = this._advertService.getAdvertDetail(
			Number(advertId),
			{ for_editing: 1 },
			{ cache: false }
		)

		const steps = loadDataForSteps(advertId, advertEntity, this._advertService, (categoryId) =>
			getSteps(categoryId, advertId, this._utils.$Router)
		)

		return super.load({
			[STEPS_STATE_KEYS.STEPS]: steps,

			[STATE_KEYS.ADVERT_ENTITY]: advertEntity,
			[PhotosUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntity,
			[VideoUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntity
		})
	}
}
