import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import { MapView } from '@inzeraty/components'
import localityPropTypes from 'app/model/locality/LocalityPropTypes'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './LocalityView.less'

const CLASSNAME = 'c-locality'

export default class LocalityView extends AbstractComponent {
	static get propTypes() {
		return {
			locality: PropTypes.shape(localityPropTypes).isRequired,
			className: PropTypes.string,
			mapProps: PropTypes.object,
			onClick: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			locality: DEFAULT_PROPS.OBJECT,
			className: '',
			mapProps: DEFAULT_PROPS.OBJECT,
			onClick: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)
	}

	render() {
		const { locality, className, mapProps, onClick } = this.props
		const { entityId, entityType, gpsLat, gpsLon } = locality

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				<div
					className={this.cssClasses({
						[`${CLASSNAME}__map`]: true,
						[`${className}-map`]: !!className,
						[`${CLASSNAME}__map--plh`]: !entityId
					})}
				>
					{entityType && entityId && (
						<MapView
							entityId={entityId}
							entityType={entityType}
							mapServerUrl={this.utils.$Settings.mapserverUrl}
							longitude={gpsLon}
							latitude={gpsLat}
							showMapPin={true}
							dataDot='go-to-mapy'
							onClick={onClick}
							{...mapProps}
						/>
					)}
				</div>
			</div>
		)
	}
}
