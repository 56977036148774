import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Surface, Icon } from '@sznds/react'
import { CHEVRON_RIGHT_24 } from '@sznds/icons'
import classnames from 'classnames'

import './HPLink.less'

const CLASSNAME = 'c-hp-link'

const RightIcon = () => {
	return <Icon symbol={CHEVRON_RIGHT_24} className={`${CLASSNAME}__icon-right`} />
}

const HPLink = memo(
	({
		className = '',
		href,
		onClick,
		iconLeft,
		firstLine,
		secondLine,
		renderRightIcon = () => <RightIcon />
	}) => {
		const linkProps = {
			surface: 4,
			tagName: href ? 'a' : 'button',
			className: classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})
		}

		if (onClick) {
			Object.assign(linkProps, { onClick })
		}

		if (href) {
			Object.assign(linkProps, { href })
		}

		return (
			<Surface {...linkProps}>
				<div className={`${CLASSNAME}__icon-left`}>{iconLeft}</div>
				<div className={`${CLASSNAME}__text`}>
					<div className={`${CLASSNAME}__first`}>{firstLine}</div>
					<div className={`${CLASSNAME}__second`}>{secondLine}</div>
				</div>
				{renderRightIcon()}
			</Surface>
		)
	}
)

HPLink.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func,
	iconLeft: PropTypes.node.isRequired,
	firstLine: PropTypes.string.isRequired,
	secondLine: PropTypes.string.isRequired,
	renderRightIcon: PropTypes.func
}

export default HPLink
