import React from 'react'

import './ModificationDetailPlaceholder.less'

const CLASSNAME = 'c-catalog-mod-detail-placeholder'

const ModificationDetailPlaceholder = () => {
	const renderTitle = () => {
		return <div className={`${CLASSNAME}__title`}></div>
	}

	const renderItem = () => {
		return (
			<div className={`${CLASSNAME}__item`}>
				<div className={`${CLASSNAME}__item-name`}></div>
				<div className={`${CLASSNAME}__item-value`}></div>
			</div>
		)
	}

	const renderList = (items, key) => {
		const itemsArr = Array.from(Array(items).keys())

		return (
			<div>
				{renderTitle()}
				<div className={`${CLASSNAME}__list`}>
					{itemsArr.map((item) => {
						return <div key={`${item}__${key}`}>{renderItem()}</div>
					})}
				</div>
			</div>
		)
	}

	return (
		<div className={CLASSNAME}>
			{renderList(6, 'list1')}
			{renderList(8, 'list2')}
			{renderList(4, 'list3')}
		</div>
	)
}

export default ModificationDetailPlaceholder
