//Seradime modely podle abecedy ("Ostatni" ale zustava na poslednim miste)
export const sortModelsAlphabetically = (models) => {
	return [...models].sort((a, b) => {
		const othersName = 'Ostatní' // Hodnota modelu Ostatní
		const nameA = a.name
		const nameB = b.name
		if (nameA === othersName) return 1
		if (nameB === othersName) return -1
		return nameA.localeCompare(nameB)
	})
}
