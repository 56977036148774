import { createEntityList } from '@inzeraty/form-lines'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { FEEDBACK_TYPE, FEEDBACK_SUBJECT } from 'app/model/feedback/FeedbackConstants'

import 'app/component/feedback/PopupFeedbackFormCS.json'

export default class IAFeedbackListController extends AbstractInternalAdminController {
	static get ACTIONS() {
		return Object.freeze({
			OPEN_FEEDBACK_DETAIL_POPUP: 'openFeedbackDetailPopup',
			CLOSE_FEEDBACK_DETAIL_POPUP: 'closeFeedbackDetailPopup',
			DELETE: 'deleteFeedback'
		})
	}

	constructor(dependenciesHelper, entitiesFilterExtension, feedbackService) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._feedbackService = feedbackService

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
		this._handleAction = this._handleAction.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isFeedbackDetailPopupOpened: false,
			feedbackEntityDetail: undefined
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const fetchFeedback = async (params) => {
			try {
				const { feedbackEntities, paginationEntity } = await this._feedbackService.getFeedbacks(
					params
				)

				return {
					entities: feedbackEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		this._pageLoaderExtension.show()

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = '-create_date'
		}

		const entitiesAndPagination = await fetchFeedback(params)

		this.setState({
			isLoading: false
		})

		this._pageLoaderExtension.hide()

		return entitiesAndPagination
	}

	_handleAction({ action, entities = [] }) {
		const [entity = {}] = entities
		const { id, email } = entity

		let promise
		let errorMessage

		switch (action) {
			case IAFeedbackListController.ACTIONS.DELETE:
				if (window.confirm(`Opravdu si přejete smazat zpětnou vazbu od uživatele ${email}?`)) {
					promise = this._feedbackService.deleteFeedback(id)
					errorMessage = `Nepodařilo se smazat zpětnou vazbu od uživatele ${email}.`
				} else {
					return {}
				}
				break

			case IAFeedbackListController.ACTIONS.OPEN_FEEDBACK_DETAIL_POPUP:
				this.setState({
					isFeedbackDetailPopupOpened: true,
					feedbackEntityDetail: entity
				})
				return {}

			case IAFeedbackListController.ACTIONS.CLOSE_FEEDBACK_DETAIL_POPUP:
				this.setState({
					isFeedbackDetailPopupOpened: false,
					feedbackEntityDetail: undefined
				})
				return {}
		}

		return {
			promise,
			getErrorMessage: () => errorMessage
		}
	}

	async _processSuccessAction(data) {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	_filterInitiezerClb() {
		return createEntityList([
			this._entitiesFilterExtension.getSearch(
				InternalAdminUrlConvertor.constants.FEEDBACK_USER,
				'Uživatel',
				'Email'
			),
			this._entitiesFilterExtension.getSelect(
				InternalAdminUrlConvertor.constants.FEEDBACK_TYPE,
				'Hodnocení',
				'Vyberte',
				[
					{
						value: FEEDBACK_TYPE.EXCELLENT,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.excellent')
					},
					{
						value: FEEDBACK_TYPE.GOOD,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.good')
					},
					{
						value: FEEDBACK_TYPE.NEUTRAL,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.neutral')
					},
					{
						value: FEEDBACK_TYPE.BAD,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.bad')
					},
					{
						value: FEEDBACK_TYPE.TERRIBLE,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.terrible')
					}
				]
			),
			this._entitiesFilterExtension.getSelect(
				InternalAdminUrlConvertor.constants.FEEDBACK_SUBJECT,
				'Předmět',
				'Vyberte',
				[
					{
						value: FEEDBACK_SUBJECT.ADVERT_INFO,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.feedbackSubjectAdvertInfo')
					},
					{
						value: FEEDBACK_SUBJECT.ADVERT_SEARCH_AND_FILTERING,
						name: this._utils.$Dictionary.get(
							'PopupFeedbackForm.feedbackSubjectAdvertSearchAndFiltering'
						)
					},
					{
						value: FEEDBACK_SUBJECT.IRRELEVANT_SEARCH_RESULTS,
						name: this._utils.$Dictionary.get(
							'PopupFeedbackForm.feedbackSubjectIrrelevantSearchResults'
						)
					},
					{
						value: FEEDBACK_SUBJECT.WEB_CLARITY,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.feedbackSubjectWebClarity')
					},
					{
						value: FEEDBACK_SUBJECT.TECHNICAL_ISSUES,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.feedbackSubjectTechnicalIssues')
					},
					{
						value: FEEDBACK_SUBJECT.OTHER,
						name: this._utils.$Dictionary.get('PopupFeedbackForm.feedbackSubjectOther')
					}
				]
			),
			this._entitiesFilterExtension.getSelect(
				InternalAdminUrlConvertor.constants.FEEDBACK_TEXT,
				'Vlastní text',
				'Vyberte',
				[
					{
						value: true,
						name: 'Ano'
					},
					{
						value: false,
						name: 'Ne'
					}
				]
			)
		])
	}
}
