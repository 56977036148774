import React from 'react'
import LayoutAdmin from 'app/component/layout/admin/LayoutAdmin'
import { Spinner, Button } from '@sznds/react'
import { useLocalize, useFire } from 'app/base/componentHelpers'

import './PaymentConfirmationView.less'
import './PaymentConfirmationCS.json'

const CLASSNAME = 'p-uw-payment-confirmation'

const PaymentConfirmationView = () => {
	const localize = useLocalize()
	const fire = useFire()

	return (
		<LayoutAdmin className={CLASSNAME}>
			<div className={`${CLASSNAME}__content-wrapper`}>
				<div className={`${CLASSNAME}__content`}>
					<Spinner className={`${CLASSNAME}__spinner`} />
					<h1 className={`${CLASSNAME}__header`}>
						{localize('PaymentConfirmation.processingHeader')}
					</h1>
					<p className={`${CLASSNAME}__description`}>
						{localize('PaymentConfirmation.processingDescription1')}
					</p>
					<p className={`${CLASSNAME}__description`}>
						{localize('PaymentConfirmation.processingDescription2')}
					</p>
					<Button
						primary={true}
						text={localize('PaymentConfirmation.processingCTA')}
						className={`${CLASSNAME}__submit`}
						onClick={() => fire('redirectToAdvertListClick')}
					/>
				</div>
			</div>
		</LayoutAdmin>
	)
}

export default PaymentConfirmationView
