
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['SellerContactHeader'] = {
  regularSeller: function(d) { return "Soukromý prodejce"; },
  companyLogo: function(d) { return "Logo firmy " + d.NAME; },
  sellerOffers: function(d) { return plural(d.AMOUNT, 0, cs, { one: d.FORMATTED + " inzerát", few: d.FORMATTED + " inzeráty", other: d.FORMATTED + " inzerátů" }); },
  otherSellerOffers: function(d) { return plural(d.AMOUNT, 0, cs, { one: d.FORMATTED + " další inzerát", few: d.FORMATTED + " další inzeráty", other: d.FORMATTED + " dalších inzerátů" }); },
  showSellerProfile: function(d) { return "Zobrazit detail prodejce"; },
  sellerOffersPlaceholder: function(d) { return "Zobrazit inzeráty"; },
  sellerNoOffers: function(d) { return "Žádné inzeráty vozů"; },
  reviewLink: function(d) { return "Ohodnoťte firmu jako první"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	