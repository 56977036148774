import React, { useContext } from 'react'
import classnames from 'classnames'
import { Format } from '@inzeraty/helpers'
import ImaContext from 'ima/page/context'
import Layout from 'app/component/layout/LayoutView'
import Paging from 'app/component/paging/PagingView'
import SearchInput from 'app/component/searchInput/SearchInput'
import TextLink from 'app/component/textLink/TextLink'
import { useLocalize } from 'app/base/componentHelpers'
import PremiseItem from 'app/page/userweb/sellerList/premiseItem/PremiseItem'
import ROUTE_NAMES from 'app/base/RouteNames'
import { REGIONS, DISTRICTS } from 'app/base/Constants'
import IMAGES from 'app/base/ImagesConstants'
import SellerListUrlConvertor from './SellerListUrlConvertor'
import SellerListController from './SellerListController'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'

import './SellerListView.less'
import './SellerListCS.json'

const CLASSNAME = 'p-uw-seller-list'
export const CONTENT_CLASSNAME = `${CLASSNAME}__content`

const SellerListView = ({
	[SellerListController.STATE_KEYS.ROUTE_PARAMS]: routeParams = {},
	[SellerListController.STATE_KEYS.PREMISES_PAGINATION]: premisesAndPagination = {}
}) => {
	const context = useContext(ImaContext)
	const router = context.$Router
	const localize = useLocalize()

	const search = routeParams[SellerListUrlConvertor.constants.URL_APP_PARAMS.SEARCH]

	const regionEntities = Object.values(REGIONS)
	const districtEntities = Object.values(DISTRICTS)

	const {
		selectedRegionEntity,
		seledtedDicstrictEntity
	} = SellerListController.getSelectedLocality(routeParams)

	const {
		premiseEntities = SellerListController.placeholders,
		paginationEntity = {}
	} = premisesAndPagination

	const getSellerListUrl = (params) => router.link(ROUTE_NAMES.USERWEB.SELLER_LIST, params)

	const renderRegions = () => {
		if (seledtedDicstrictEntity) {
			return null
		} else {
			let entities = []

			if (selectedRegionEntity) {
				const { id } = selectedRegionEntity
				entities =
					id === REGIONS.PRAHA.id
						? []
						: districtEntities.filter(({ region = {} }) => region.id === id)
			} else {
				entities = regionEntities.map((regionEntity) =>
					SellerListUrlConvertor.getUpdatedRegionData(regionEntity)
				)
			}

			return (
				<>
					{entities
						.sort((a, b) => {
							return a.name.localeCompare(b.name)
						})
						.map(({ id, seoName, name }) => {
							return (
								<TextLink
									key={id}
									href={getSellerListUrl({
										[SellerListUrlConvertor.constants.URL_APP_PARAMS.LOCALITY]: seoName
									})}
									className={`${CLASSNAME}__locality-link`}
								>
									{name}
								</TextLink>
							)
						})}
				</>
			)
		}
	}

	const renderSearch = () => {
		return (
			<SearchInput
				className={`${CLASSNAME}__search`}
				onSubmit={(value) => {
					const url = SellerListUrlConvertor.getSearchUrl(value, router)
					router.redirect(url)
				}}
				inputValue={search || ''}
			/>
		)
	}

	const renderPath = () => {
		const path = [
			{
				id: -1,
				seoName: '',
				name: localize('SellerList.czechRepublic')
			}
		]

		if (seledtedDicstrictEntity) {
			const { region = {} } = seledtedDicstrictEntity
			path.push(SellerListUrlConvertor.getUpdatedRegionData(region))
			path.push(seledtedDicstrictEntity)
		} else if (selectedRegionEntity) {
			path.push(selectedRegionEntity)
		}

		return (
			<div className={`${CLASSNAME}__path`}>
				{path.map(({ id, seoName, name }, idx) => {
					const props = {
						className: `${CLASSNAME}__path-item`,
						children: name,
						key: id
					}

					if (idx === path.length - 1) {
						// eslint-disable-next-line jsx-a11y/heading-has-content
						return <h1 {...props} />
					} else {
						return (
							<React.Fragment key={idx}>
								<TextLink
									{...props}
									href={getSellerListUrl({
										[SellerListUrlConvertor.constants.URL_APP_PARAMS.LOCALITY]: seoName
									})}
								/>
								<span className={`${CLASSNAME}__path-separator`}>&gt;</span>
							</React.Fragment>
						)
					}
				})}
			</div>
		)
	}

	const renderCount = () => {
		const { total } = paginationEntity
		const isPlaceholder = typeof total === 'undefined'

		if (total === 0) {
			return null
		} else {
			return (
				<div
					className={classnames({
						[`${CLASSNAME}__count`]: true,
						[`${CLASSNAME}__count--plh`]: isPlaceholder
					})}
				>
					{!isPlaceholder && (
						<>
							{Format.number(total)}{' '}
							{localize('SellerList.sellersCount', {
								COUNT: total
							})}
						</>
					)}
				</div>
			)
		}
	}

	const renderPremises = () => {
		if (premiseEntities.length) {
			return premiseEntities.map((premiseData) => (
				<PremiseItem
					data-premise-id={premiseData.oldId || premiseData.id}
					key={premiseData.id}
					premiseData={premiseData}
				/>
			))
		} else {
			return (
				<div className={`${CLASSNAME}__zero`}>
					<img
						src={IMAGES['image__useful-seller-list']}
						className={`${CLASSNAME}__zero-icon`}
						alt=''
					/>
					<p>
						{!search ? (
							localize('SellerList.zero')
						) : (
							<>
								{localize('SellerList.zeroPart1')}
								<strong>{search}</strong>
								{localize('SellerList.zeroPart2')}
							</>
						)}
					</p>
				</div>
			)
		}
	}

	return (
		<Layout className={CLASSNAME}>
			<div className={CONTENT_CLASSNAME}>
				<div className={`${CLASSNAME}__head`}>
					<BackToHpButton />
					<h1 className={`${CLASSNAME}__header`}>{localize('SellerList.header')}</h1>

					{renderSearch()}
					{renderPath()}
					{renderRegions()}
					{renderCount()}
				</div>

				{renderPremises()}

				<Paging
					paginationEntity={paginationEntity}
					urlPageCreator={(page) => {
						return SellerListUrlConvertor.createPaginationUrl(page, router)
					}}
				/>
			</div>
		</Layout>
	)
}

export default SellerListView
