import EntityFormPopup from '../entityFormPopup/EntityFormPopup'
import React from 'react'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import EntityForm from 'app/component/entityForm/EntityForm'
import { useFire } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import ForbidAdvertPopupExtension from './ForbidAdvertPopupExtension'

const ForbidAdvertPopup = ({ formLineEntities, entityFormError, isWaiting, isPopupOpen }) => {
	const fire = useFire()
	return isPopupOpen ? (
		<EntityFormPopup
			title='Zakázat inzerát'
			onClose={() => {
				fire('closeAdvertPopup')
			}}
		>
			<EntityForm
				variant={VARIANT.POPUP_FORM}
				formLineEntities={formLineEntities}
				entityFormError={entityFormError}
				isWaiting={isWaiting}
				onSubmit={() => {
					fire('submitAdvertForbid')
				}}
			/>
		</EntityFormPopup>
	) : null
}

const selector = (state) => ({
	isWaiting: state[ForbidAdvertPopupExtension.STATE_KEYS.IS_WAITING],
	formLineEntities: state[ForbidAdvertPopupExtension.STATE_KEYS.FORM_LINE_ENTITIES],
	entityFormError: state[ForbidAdvertPopupExtension.STATE_KEYS.ENTITY_FORM_ERROR],
	isPopupOpen: state[ForbidAdvertPopupExtension.STATE_KEYS.IS_ADVERT_FORBID_OPENED]
})

ForbidAdvertPopup.propTypes = {
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	isWaiting: PropTypes.bool,
	isPopupOpen: PropTypes.bool
}

export default select(selector)(ForbidAdvertPopup)
