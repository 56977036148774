import ROUTE_NAMES from 'app/base/RouteNames'

export const areRegressionTestsRunning = (router) => {
	if (router) {
		try {
			const { route } = router.getCurrentRouteInfo()
			const currentRoute = route.getName()

			return currentRoute === ROUTE_NAMES.REGRESSION_TEST
		} catch {
			return false
		}
	}

	return false
}
