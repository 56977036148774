import { Format } from '@inzeraty/helpers'
import {
	getAppliedFilters,
	printAppliedFilters
} from 'app/page/userweb/advertList/AdvertListOperatingLeaseMeta'

import './SellerOperatingLeaseMetaCS.json'

export default class SellerOperatingLeaseMeta {
	static getTitleValue(dictionary, premiseName = '', filterFormLineEntities = []) {
		const [brandAndModelsFilter, restFilters] = getAppliedFilters(
			dictionary,
			filterFormLineEntities
		)

		const filtersText = printAppliedFilters(brandAndModelsFilter, restFilters)

		if (filtersText) {
			return dictionary.get('SellerOperatingLeaseMeta.title', {
				PREMISE_NAME: premiseName,
				FILTERS: filtersText
			})
		} else {
			return dictionary.get('SellerOperatingLeaseMeta.titleNoFilters', {
				PREMISE_NAME: premiseName
			})
		}
	}

	static getDescriptionValue(
		dictionary,
		premiseName = '',
		filterFormLineEntities = [],
		filteredAdvertsCountTotal
	) {
		const countText = dictionary.get('SellerOperatingLeaseMeta.advertCount', {
			COUNT: filteredAdvertsCountTotal,
			COUNT_FORMATTED: Format.number(filteredAdvertsCountTotal)
		})

		const [brandAndModelsFilter, restFilters] = getAppliedFilters(
			dictionary,
			filterFormLineEntities
		)

		const filtersText = printAppliedFilters(brandAndModelsFilter, restFilters)

		if (filtersText) {
			return dictionary.get('SellerOperatingLeaseMeta.description', {
				PREMISE_NAME: premiseName,
				ADVERT_COUNT_TEXT: countText,
				FILTERS: filtersText
			})
		} else {
			return dictionary.get('SellerOperatingLeaseMeta.descriptionNoFilters', {
				PREMISE_NAME: premiseName,
				ADVERT_COUNT_TEXT: countText
			})
		}
	}
}
