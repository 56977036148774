import React, { useContext, useState } from 'react'
import ImaContext from 'ima/page/context'
import PropTypes from 'prop-types'
import Layout from 'app/component/layout/LayoutView'
import { useLocalize } from 'app/base/componentHelpers'
import RegisterController from './RegisterCompanyController'
import { DefaultProps as DEFAULT_PROPS, HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import NavButton, { BUTTON_TYPE } from 'app/component/navButton/NavButtonView'
import { Surface, Button } from '@sznds/react'
import ActionElm from 'app/component/actionElement/ActionElement'
import * as FormLines from '@inzeraty/form-lines'
import { Validation } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import EmailCompWidget, {
	EMAIL_WIDGET_ID
} from 'app/component/formLineWidgets/email/EmailCompWidget'
import PhoneCompWidget, {
	PHONE_WIDGET_ID
} from 'app/component/formLineWidgets/phone/PhoneCompWidget'
import BasicCompWidget, {
	BASIC_WIDGET_ID
} from 'app/component/formLineWidgets/basic/BasicCompWidget'

const { Form } = FormLines

import './RegisterCompany.less'
import './RegisterCompanyCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'p-register'

const FORM_IDS = Object.freeze({
	PREMISE_NAME: 'premise_name',
	IC: 'ic',
	CONTACT_NAME: 'contact_name',
	PHONE: 'phone',
	EMAIL: 'email',
	LOCALITY: 'locality'
})

const WIDGETS_BY_ID = Object.freeze({
	[BASIC_WIDGET_ID]: BasicCompWidget,
	[EMAIL_WIDGET_ID]: EmailCompWidget,
	[PHONE_WIDGET_ID]: PhoneCompWidget
})

const FormNote = () => {
	const localize = useLocalize()

	return (
		<p className={`${CLASSNAME}__note`}>
			{localize('RegisterCompany.agreementNote')}{' '}
			<ActionElm
				href={`https://${localize('RegisterCompany.agreementUrl')}`}
				rel='noopener noreferrer'
				target='_blank'
			>
				{localize('RegisterCompany.here')}
			</ActionElm>
			.
			<br />
			{localize('RegisterCompany.helpNote')}{' '}
			<ActionElm href={`mailto:${encodeURIComponent(localize('RegisterCompany.helpEmail'))}`}>
				{localize('RegisterCompany.helpEmail')}
			</ActionElm>
			.
		</p>
	)
}

const getFormLineEntities = (localize) => {
	return FormLines.createEntityList([
		{
			widget: BASIC_WIDGET_ID,
			id: FORM_IDS.PREMISE_NAME,
			label: localize('RegisterCompany.input_premise_name'),
			placeholder: localize('RegisterCompany.input_premise_name_plh'),
			required: true
		},
		{
			widget: BASIC_WIDGET_ID,
			id: FORM_IDS.IC,
			label: localize('RegisterCompany.input_ic'),
			placeholder: localize('RegisterCompany.input_ic_plh'),
			required: true
		},
		{
			widget: BASIC_WIDGET_ID,
			id: FORM_IDS.CONTACT_NAME,
			label: localize('RegisterCompany.input_contact_name'),
			placeholder: localize('RegisterCompany.input_contact_name_plh'),
			required: true
		},
		{
			widget: PHONE_WIDGET_ID,
			id: FORM_IDS.PHONE,
			label: localize('RegisterCompany.input_phone'),
			placeholder: localize('RegisterCompany.input_phone_plh'),
			required: true
		},
		{
			widget: EMAIL_WIDGET_ID,
			id: FORM_IDS.EMAIL,
			label: localize('RegisterCompany.input_email'),
			placeholder: localize('RegisterCompany.input_email_plh'),
			required: true
		},
		{
			widget: BASIC_WIDGET_ID,
			id: FORM_IDS.LOCALITY,
			label: localize('RegisterCompany.input_locality'),
			placeholder: localize('RegisterCompany.input_locality_plh'),
			required: true
		}
	])
}

const RegistrationForm = ({ onSubmit, goBackData: { text: goBackText, url: goBackUrl } }) => {
	const localize = useLocalize()
	const { $Dictionary, $Dispatcher } = useContext(ImaContext)

	const [formLineEntities, setFormLineEntities] = useState(getFormLineEntities(localize))
	const [isSending, setIsSending] = useState(false)

	const getValidationErrors = (formLineEntities) => {
		return FormLines.validate(formLineEntities, {
			[FORM_IDS.PREMISE_NAME]: validate,
			[FORM_IDS.IC]: validate,
			[FORM_IDS.CONTACT_NAME]: validate,
			[FORM_IDS.PHONE]: validate,
			[FORM_IDS.EMAIL]: validate,
			[FORM_IDS.LOCALITY]: validate
		})
	}

	const onSubmitForm = () => {
		setIsSending(true)

		const validationErrors = getValidationErrors(formLineEntities)

		if (FormLines.isSomeError(validationErrors)) {
			setIsSending(false)
			const updatedFormLineEntities = FormLines.updateEntities(formLineEntities, validationErrors)
			setFormLineEntities(updatedFormLineEntities)
			FormLines.focusFirstInvalidInput(updatedFormLineEntities)
		} else {
			onSubmit(FormLines.getFormData(formLineEntities))
				.catch((error) => {
					const status = HttpErrorHelper.getHttpStatus(error)

					if (status === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
						const { body = {} } = HttpErrorHelper.getParams(error)
						const { errors = [] } = body

						const updateErrors = []

						errors.forEach((error) => {
							const { fields, error_code } = error

							fields.forEach((field) => {
								const distKey = `RegisterCompany.input_${field}_${error_code}`
								const errorMessage = localize(
									$Dictionary.has(distKey) ? distKey : `RegisterCompany.input_${error_code}`
								)

								updateErrors.push({
									id: field,
									errorMessage
								})
							})
						})

						const updatedFormLineEntities = FormLines.updateEntities(formLineEntities, updateErrors)
						setFormLineEntities(updatedFormLineEntities)
						FormLines.focusFirstInvalidInput(updatedFormLineEntities)
					} else {
						$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
							title: localize('Base.statusMessageError'),
							type: StatusMessage.TYPE.ERROR
						})
					}
				})
				.finally(() => {
					setIsSending(false)
				})
		}
	}

	const onBlur = (event, id) => {
		const formLineEntity = FormLines.getFormLineById(id, formLineEntities)

		setFormLineEntities(
			FormLines.updateEntities(formLineEntities, [
				{
					id,
					errorMessage: validate(formLineEntity)
				}
			])
		)
	}

	const onChange = (eventOrData, newValue, id) => {
		if (eventOrData.preventDefault) {
			setFormLineEntities(
				FormLines.updateEntities(formLineEntities, [
					{
						id,
						value: newValue
					}
				])
			)
		} else {
			setFormLineEntities(FormLines.updateEntities(formLineEntities, [eventOrData]))
		}
	}

	const validate = (formLineEntity) => {
		const { id, value } = formLineEntity
		const isEmpty = (val) => !Validation.isNotEmpty(val)

		switch (id) {
			case FORM_IDS.PREMISE_NAME:
				return isEmpty(value) ? localize('RegisterCompany.input_premise_name_required') : ''

			case FORM_IDS.IC:
				return isEmpty(value)
					? localize('RegisterCompany.input_ic_required')
					: !Validation.checkIco(value)
					? localize('RegisterCompany.input_ic_not_valid')
					: ''

			case FORM_IDS.CONTACT_NAME:
				return isEmpty(value) ? localize('RegisterCompany.input_contact_name_required') : ''

			case FORM_IDS.PHONE:
				return isEmpty(value)
					? localize('RegisterCompany.input_phone_required')
					: !Validation.checkPhone(value)
					? localize('RegisterCompany.input_phone_not_valid')
					: ''

			case FORM_IDS.EMAIL:
				return isEmpty(value)
					? localize('RegisterCompany.input_email_required')
					: !Validation.checkEmail(value)
					? localize('RegisterCompany.input_email_not_valid')
					: ''

			case FORM_IDS.LOCALITY:
				return isEmpty(value) ? localize('RegisterCompany.input_locality_required') : ''
		}

		return ''
	}

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			formLineEntities,
			{
				onChange,
				onBlur
			},
			WIDGETS_BY_ID
		)
	}

	return (
		<>
			<div className={`${CLASSNAME}__go-back-wrap`}>
				<NavButton text={goBackText} url={goBackUrl} type={BUTTON_TYPE.BACK} />
			</div>
			<h1 className={`${CLASSNAME}__header-main`}>{localize('RegisterCompany.heading')}</h1>
			<Surface surface={5} className={`${CLASSNAME}__form-wrap`}>
				{localize('RegisterCompany.description')}

				<Form
					className={`${CLASSNAME}__form`}
					formLineEntities={formLineEntities}
					isSending={isSending}
					renderWidgets={renderWidgets}
					renderSubmitButton={() => {}}
				/>
				<FormNote />
				<div className={`${CLASSNAME}__action-btns`}>
					<Button
						size='small'
						loading={isSending}
						className={`${CLASSNAME}__submit-btn`}
						primary={true}
						text={localize('RegisterCompany.submitForm')}
						onClick={onSubmitForm}
					/>
				</div>
			</Surface>
		</>
	)
}

RegistrationForm.propTypes = {
	onSubmit: PropTypes.func,
	goBackData: PropTypes.shape({
		text: PropTypes.string,
		url: PropTypes.string
	})
}

const RegisterSuccess = () => {
	const localize = useLocalize()

	return (
		<div className={`${CLASSNAME}__success-wrap`}>
			<div className={`${CLASSNAME}__success-content`}>
				<h1 className={`${CLASSNAME}__success-title`}>
					{localize('RegisterCompany.successTitle')}
				</h1>
				<p className={`${CLASSNAME}__success-note`}>{localize('RegisterCompany.successNote')}</p>
				<Button
					size='small'
					className={`${CLASSNAME}__success-cta`}
					primary={true}
					text={localize('RegisterCompany.successGoBack')}
					href='/'
				/>
			</div>
		</div>
	)
}

const RegisterCompany = ({
	[RegisterController.STATE_KEYS.GO_BACK_DATA]: goBackData,
	[RegisterController.STATE_KEYS.ON_SUBMIT]: onSubmit
}) => {
	const [successfullySent, setSuccessfullySent] = useState(false)

	const onSubmitForm = (data) => {
		return onSubmit(data).then((result) => {
			setSuccessfullySent(true)
		})
	}

	return (
		<Layout>
			<div className={CLASSNAME}>
				{successfullySent ? (
					<RegisterSuccess />
				) : (
					<RegistrationForm onSubmit={onSubmitForm} goBackData={goBackData} />
				)}
			</div>
		</Layout>
	)
}

RegisterCompany.propTypes = {
	[RegisterController.STATE_KEYS.GO_BACK_DATA]: PropTypes.object,
	[RegisterController.STATE_KEYS.ON_SUBMIT]: PropTypes.func
}

RegisterCompany.defaultProps = {
	[RegisterController.STATE_KEYS.GO_BACK_DATA]: DEFAULT_PROPS.OBJECT,
	[RegisterController.STATE_KEYS.ON_SUBMIT]: DEFAULT_PROPS.FUNCTION
}

export default RegisterCompany
