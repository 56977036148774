import React from 'react'
import PropTypes from 'prop-types'
import BasicWidget from '../../../../component/widgets/basic/BasicWidget'
import * as FormLines from '@inzeraty/form-lines'
import { useLocalize } from 'app/base/componentHelpers'
import BaseTextWidget, {
	TEXT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/text/TextWidget'

import './TextWidget.less'
import './TextWidgetCS.json'

export { TEXT_WIDGET_ID }

const CLASSNAME = 'c-text-widget'

const TextWidget = ({ formLineEntity, onChange }) => {
	const localize = useLocalize()

	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={TEXT_WIDGET_ID}
			renderInput={(formLineEntity, inputProps) => {
				return (
					<div className={`${CLASSNAME}__input-wrapper`}>
						<BaseTextWidget {...inputProps} formLineEntity={formLineEntity} onChange={onChange} />
						<div className={`${CLASSNAME}__description`}>
							<span className={`${CLASSNAME}__example`}>
								{localize('TextWidget.description')}
								<span className={`${CLASSNAME}__example ${CLASSNAME}__example--italic`}>
									{localize('TextWidget.descriptionExample')}
								</span>
							</span>
						</div>
					</div>
				)
			}}
		/>
	)
}

TextWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default TextWidget
