import ns from 'ima/namespace'
import StatusCode from 'ima/http/StatusCode'

ns.namespace('app.base')

/**
 * HTTP status kódy
 */
const HTTP_STATUS_CODE = Object.freeze({
	/**
	 * OK (200)
	 *
	 * @property HTTP_STATUS_CODE.OK
	 * @type {number}
	 */
	OK: StatusCode.OK,

	/**
	 * CREATED (201)
	 *
	 * @property HTTP_STATUS_CODE.CREATED
	 * @type {number}
	 */
	CREATED: 201,

	/**
	 * OK (202)
	 *
	 * @property HTTP_STATUS_CODE.ACCEPTED
	 * @type {number}
	 */
	ACCEPTED: 202,

	/**
	 * Moved Permanently (301)
	 *
	 * @property HTTP_STATUS_CODE.MOVED_PERMANENTLY
	 * @type {Number}
	 */
	MOVED_PERMANENTLY: 301,

	/**
	 * Bad request (400)
	 *
	 * @property HTTP_STATUS_CODE.BAD_REQUEST
	 * @type {number}
	 */
	BAD_REQUEST: StatusCode.BAD_REQUEST,

	/**
	 * Unauthorized (401)
	 *
	 * @property HTTP_STATUS_CODE.UNAUTHORIZED
	 * @type {number}
	 */
	UNAUTHORIZED: StatusCode.UNAUTHORIZED,

	/**
	 * Forbidden (403)
	 *
	 * @property HTTP_STATUS_CODE.FORBIDDEN
	 * @type {number}
	 */
	FORBIDDEN: StatusCode.FORBIDDEN,

	/**
	 * Not found (404)
	 *
	 * @property HTTP_STATUS_CODE.NOT_FOUND
	 * @type {number}
	 */
	NOT_FOUND: StatusCode.NOT_FOUND,

	/**
	 * Method Not Allowed
	 *
	 * @property HTTP_STATUS_CODE.METHOD_NOT_ALLOWED
	 * @type {number}
	 */
	METHOD_NOT_ALLOWED: 405,

	/**
	 * Request timeout (408)
	 *
	 * @property HTTP_STATUS_CODE.TIMEOUT
	 * @type {number}
	 */
	TIMEOUT: StatusCode.TIMEOUT,

	/**
	 * Gone
	 *
	 * @property HTTP_STATUS_CODE.GONE
	 * @type {number}
	 */
	GONE: 410,

	/**
	 * Unprocessable Entity
	 *
	 * @property HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY
	 * @type {number}
	 */
	UNPROCESSABLE_ENTITY: 422,

	/**
	 * Too many requests Entity
	 *
	 * @property HTTP_STATUS_CODE.TOO_MANY_REQUESTS
	 * @type {number}
	 */
	TOO_MANY_REQUESTS: 429,

	/**
	 * Internal Server Error (500)
	 *
	 * @property HTTP_STATUS_CODE.SERVER_ERROR
	 * @type {number}
	 */
	SERVER_ERROR: StatusCode.SERVER_ERROR
})

export default HTTP_STATUS_CODE

ns.app.base.HTTP_STATUS_CODE = HTTP_STATUS_CODE
