import { createEntityList } from '@inzeraty/form-lines'
import { StatusMessage, PageLoader } from '@inzeraty/components'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import MirroringStatus from 'app/model/mirroring/MirroringStatus'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'

const DATE_FROM_FORM_LINE_ENTITY_ID = 'date_from_egreater'
const DATE_TO_FORM_LINE_ENTITY_ID = 'date_to_eless'

export default class IAMirroringListController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, entitiesFilterExtension, mirroringService) {
		super(dependenciesHelper)

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
		this._entitiesFilterExtension = entitiesFilterExtension
		this._mirroringService = mirroringService

		this.exportMirroring = this.exportMirroring.bind(this)
	}

	static get MIRRORING_STATUS() {
		return Object.freeze({
			ORDER: {
				id: MirroringStatus.ORDER,
				name: 'Objednávka'
			},
			RUNNING: {
				id: MirroringStatus.RUNNING,
				name: 'Běží'
			},
			PAUSED: {
				id: MirroringStatus.PAUSED,
				name: 'Pozastaveno'
			},
			FINISHED: {
				id: MirroringStatus.FINISHED,
				name: 'Úspěšně ukončeno'
			},
			CANCELED: {
				id: MirroringStatus.CANCELED,
				name: 'Stornováno'
			}
		})
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			exportMirroring: this.exportMirroring
		})
	}

	update() {
		return {
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		}
	}

	_getApiParams() {
		const routeParams = this.getRouteParams()
		const params = Object.assign(
			{},
			InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)
		)

		if (!params.sort) {
			params.sort = '-id'
		}

		return params
	}

	async getEntitiesAndPagination() {
		const fetchMirrorings = async (params) => {
			try {
				const { mirroringEntities, paginationEntity } = await this._mirroringService.getMirrorings(
					params
				)

				return {
					entities: mirroringEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const params = this._getApiParams()

		this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchMirrorings(params)

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	async _filterInitiezerClb() {
		const getOptions = (source = {}) => {
			return Object.values(source).map(({ id, name }) => ({
				value: id,
				name
			}))
		}

		const routeParams = this.getRouteParams()

		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'id',
					name: 'ID zrcadlení'
				},
				{
					value: 'premise_external_id',
					name: 'Sauto ID'
				},
				{
					value: 'premise_id',
					name: 'ASK ID'
				},
				{
					value: 'premise_name',
					name: 'Název firmy'
				}
			]),
			this._entitiesFilterExtension.getSelect(
				'status',
				'Stav',
				'Vyberte stav',
				getOptions(IAMirroringListController.MIRRORING_STATUS)
			),
			{
				widget: IDS.DATE,
				id: DATE_FROM_FORM_LINE_ENTITY_ID,
				label: 'Datum od',
				value: routeParams[DATE_FROM_FORM_LINE_ENTITY_ID] || ''
			},
			{
				widget: IDS.DATE,
				id: DATE_TO_FORM_LINE_ENTITY_ID,
				label: 'Datum do',
				value: routeParams[DATE_TO_FORM_LINE_ENTITY_ID] || ''
			}
		])
	}

	async exportMirroring() {
		try {
			PageLoader.start()

			const params = Object.assign({}, this._getApiParams(), {
				offset: 0,
				limit: 100000
			})

			await this._mirroringService.downloadMirroringsCSV(
				params,
				IAMirroringListController.MIRRORING_STATUS
			)
		} catch {
			this._showStatusMessageToast({
				type: StatusMessage.TYPE.ERROR,
				title: 'Nepodařilo se provést export zrcadlení inzerce.'
			})
		} finally {
			PageLoader.end()
		}
	}
}
