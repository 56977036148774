
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertActions'] = {
  actions: function(d) { return "Možnosti"; },
  advertActions: function(d) { return "Možnosti inzerátu"; },
  close: function(d) { return "Zavřít"; },
  edit: function(d) { return "Editovat"; },
  deactivate: function(d) { return "Zneaktivnit"; },
  activate: function(d) { return "Aktivovat"; },
  pay: function(d) { return "Přejít na platbu"; },
  remove: function(d) { return "Smazat"; },
  createBoardBehindWindscreen: function(d) { return "Vytvořit ceduli za okno"; },
  actionsBlindClosePrefix: function(d) { return "Konec možností inzerátu"; },
  addToPO: function(d) { return "Přidat do Pv"; },
  removeFromPO: function(d) { return "Odebrat z Pv"; },
  topped: function(d) { return "Topovat"; },
  statistics: function(d) { return "Zobrazit statistiky"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	