
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['IAImportDetail'] = {
  title: function(d) { return "Informace o importu"; },
  sessionId: function(d) { return "Session ID"; },
  premiseId: function(d) { return "ASK ID"; },
  premiseName: function(d) { return "Jméno klienta"; },
  softwareKey: function(d) { return "SW klíč"; },
  beginning: function(d) { return "Zahájení"; },
  carsAll: function(d) { return "Vozů celkem"; },
  carsStored: function(d) { return "Vozů zatříděných"; },
  importErrorCount: function(d) { return "Počet chyb v importu"; },
  error: function(d) { return d.COUNT + " " + plural(d.COUNT, 0, cs, { "0": "chyb", one: "chyba", few: "chyby", other: "chyb" }); },
  action: function(d) { return "Akce"; },
  timestamp: function(d) { return "Čas akce"; },
  status: function(d) { return "Stav"; },
  statusMessage: function(d) { return "Stavová zpráva"; },
  advertId: function(d) { return "ID inzerátu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	