
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['BoardBehindWindscreen'] = {
  manufacturingDate: function(d) { return "Rok výroby"; },
  date: function(d) { return "V provozu od"; },
  tachometer: function(d) { return "Najeto"; },
  fuelCb: function(d) { return "Palivo"; },
  engineVolume: function(d) { return "Motor"; },
  engineVolumeValue: function(d) { return d.ENGINE_VOLUME + " ccm"; },
  enginePower: function(d) { return "Výkon"; },
  validStkDate: function(d) { return "STK do"; },
  conditionCb: function(d) { return "Stav"; },
  notCrashedInPast: function(d) { return "Nehavarováno"; },
  firstOwner: function(d) { return "1. majitel"; },
  serviceBook: function(d) { return "Servisní knížka"; },
  equipment: function(d) { return "Výbava"; },
  windshieldNote: function(d) { return "Poznámka"; },
  priceWithoutVat: function(d) { return "Cena bez DPH"; },
  operatingLeasePriceLabel: function(d) { return "Operativní leasing"; },
  operatingLeasePriceWithoutVat: function(d) { return "od " + d.PRICE + ",- " + d.CURRENCY + " / měs. bez DPH"; },
  a4Portrait: function(d) { return "A4 na výšku"; },
  a4Landscape: function(d) { return "A4 na šířku"; },
  print: function(d) { return "Vytisknout"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	