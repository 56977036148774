import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './TableHead.less'

const CLASSNAME = 'c-table-head'

const TableHead = ({ children, alignRight = false }) => (
	<span
		className={classnames({
			[CLASSNAME]: true,
			[`${CLASSNAME}--align-right`]: alignRight
		})}
	>
		{children}
	</span>
)

TableHead.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	alignRight: PropTypes.bool
}

export default TableHead
