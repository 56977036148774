
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PaymentConfirmation'] = {
  processingHeader: function(d) { return "Platba je na cestě"; },
  processingDescription1: function(d) { return "Někdy chvilku trvá, než peníze dorazí. Peněženku kontrolujeme každých pár vteřin a dáme vědět hned, jak to bude hotové."; },
  processingDescription2: function(d) { return "Nechce se vám čekat? Klidně stránku zavřete, inzerát bude dostupný hned jak platba dojde."; },
  processingCTA: function(d) { return "Přejít na výpis inzerátů"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	