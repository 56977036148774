import React from 'react'
import AbstractComponent from 'app/base/AbstractComponent'
import TagsList from 'app/component/filters/components/tagsList/TagsList'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './AdvertListFilterItems.less'

const CLASSNAME = 'c-item-list-filter-items'

class AdvertListFilterItems extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			className: ''
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			currentTagsSlide: 0
		}

		this._onSelectedTagsSlideIndexChange = this._onSelectedTagsSlideIndexChange.bind(this)
	}

	render() {
		const { className } = this.props
		const { currentTagsSlide } = this.state

		const tagsListProps = {
			selectedSlideIndex: currentTagsSlide,
			onSelectedSlideIndexChange: this._onSelectedTagsSlideIndexChange,
			renderChildren: (defaultTags) => [defaultTags]
		}

		return (
			<div
				className={classnames({
					[CLASSNAME]: true,
					[className]: !!className
				})}
				data-dot='filters-shortcuts'
			>
				<TagsList {...tagsListProps} />
			</div>
		)
	}

	_onSelectedTagsSlideIndexChange(newTagsSlideIndex) {
		this.setState({ currentTagsSlide: newTagsSlideIndex })
	}
}

export { CLASSNAME as filterItemsClassName }
export default AdvertListFilterItems
