import { FAVORITE_BRANDS_USAGE_COOKIE } from 'app/base/StorageIds'

/**
 * @constant
 * @type {String}
 * Oddělovač jednotlivých záznamů používanosti značek.
 */
const BRANDS_USAGE_SEPARATOR = ','

/**
 * @constant
 * @type {String}
 * Oddělovač identifikátoru značky od počtu klinutí.
 */
const BRAND_CLICKS_SEPARATOR = ':'

export default class FilterHelper {
	/**
	 * @constructor
	 * @param {ima.storage.CookieStorage} cookieStorage
	 */
	constructor(cookieStorage, cmpHelper) {
		this._cookieStorage = cookieStorage
		this._cmpHelper = cmpHelper

		this.increaseFavoriteBrandClick = this.increaseFavoriteBrandClick.bind(this)
		this.getSortedFavoriteOptions = this.getSortedFavoriteOptions.bind(this)
	}

	/**
	 * Zvýší počet klikanosti na oblíbenou značku pro konkrétního uživatele.
	 * A uloží informaci do cookie.
	 *
	 * @public
	 * @method increaseFavoriteBrandClick
	 * @param {Number} brandValue
	 */
	increaseFavoriteBrandClick(brandValue) {
		if (this._cmpHelper.hasCmp()) {
			const brandClicks = this._getFavoriteBrandsClicks()

			if (brandClicks[brandValue]) {
				brandClicks[brandValue] = Number(brandClicks[brandValue]) + 1
			} else {
				brandClicks[brandValue] = 1
			}

			const brandClicksString = Object.entries(brandClicks)
				.map(([brandValue, clicks]) => {
					return `${brandValue}${BRAND_CLICKS_SEPARATOR}${clicks}`
				})
				.join(BRANDS_USAGE_SEPARATOR)

			this._cookieStorage.set(FAVORITE_BRANDS_USAGE_COOKIE, brandClicksString)
		}
	}

	/**
	 * Z options formLineEntity vyfiltruje oblíbené a seřadí je podle oblíbenosti
	 * a podle klikanosti daného uživatele (podle cookie).
	 *
	 * @public
	 * @method getSortedFavoriteOptions
	 * @param {ima.storage.CookieStorage}
	 * @param {Array<Object>} options
	 * @return {Array<Object>} Vrátí oblíbené optiony seřazené podle oblíbenosti.
	 */
	getSortedFavoriteOptions(options = []) {
		return options
			.map((option) => {
				const { value } = option
				const brandClicks = this._getFavoriteBrandsClicks()
				return Object.assign(
					{
						__usageOrder: brandClicks[value] || 0
					},
					option
				)
			})
			.filter((option) => option.isFavorite)
			.sort((a, b) => {
				if (b.__usageOrder === a.__usageOrder) {
					return a.favoriteOrder - b.favoriteOrder
				} else {
					return b.__usageOrder - a.__usageOrder
				}
			})
			.map((option) => {
				// __usageOrder je pouze pomocný parametr pro správné seřazení, takže ho smaže
				delete option.__usageOrder
				return option
			})
	}

	/**
	 * Z options formLineEntity vyfiltruje oblíbené a seřadí je podle pořadí vyhledávanosti.
	 *
	 * @public
	 * @method getSortedFavoriteOptionsByFavoriteOrder
	 * @param {Array<Object>} options
	 * @return {Array<Object>} Vrátí oblíbené optiony seřazené podle vyhledávanosti.
	 */
	getSortedFavoriteOptionsByFavoriteOrder(options = []) {
		return options
			.filter((option) => option.isFavorite)
			.sort((a, b) => a.favoriteOrder - b.favoriteOrder)
	}

	/**
	 * Vrátí klikanost na jednotlivé značky konkrétního uživatele.
	 *
	 * @private
	 * @method _getFavoriteBrandsClicks
	 * @returns {Object}
	 */
	_getFavoriteBrandsClicks() {
		if (this._cmpHelper.hasCmp()) {
			const cookie = this._cookieStorage.get(FAVORITE_BRANDS_USAGE_COOKIE)

			if (cookie) {
				return cookie.split(BRANDS_USAGE_SEPARATOR).reduce((accumulator, currentValue) => {
					const [brandValue, clicks] = currentValue.split(BRAND_CLICKS_SEPARATOR)
					accumulator[brandValue] = clicks
					return accumulator
				}, {})
			}
		}

		return {}
	}
}
