import React from 'react'
import AbstractComponent from 'app/base/AbstractComponent'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import STATE_KEYS from 'app/page/userweb/seller/SellerStateKeys'
import { Surface } from '@sznds/react'
import NavButton, { BUTTON_TYPE } from 'app/component/navButton/NavButtonView'
import SellerContactSection from 'app/page/userweb/seller/sellerContactSection/SellerContactSectionView'

import './SellerSubheader.less'

const CLASSNAME = 'c-seller-subheader'

class SellerSubheader extends AbstractComponent {
	constructor() {
		super()

		this.clickGoBack = this.clickGoBack.bind(this)
	}

	static get propTypes() {
		return {
			goBackData: PropTypes.object
		}
	}

	render() {
		return (
			<Surface className={CLASSNAME} surface={4}>
				<div className={`${CLASSNAME}__content`}>
					{this._renderBackToDetail()}
					<SellerContactSection className={`${CLASSNAME}__seller-section`} />
				</div>
			</Surface>
		)
	}

	/**
	 * Vyrenderuje tlacitko zpet
	 * @private
	 * @method _renderBackToDetail
	 * @return {HTMLElement}
	 */
	_renderBackToDetail() {
		const { goBackData = {} } = this.props
		const { text = '', url = '', callback } = goBackData
		const btnProps = {}

		if (callback) {
			btnProps.onClick = this.clickGoBack
		}

		if (text && url) {
			return <NavButton text={text} url={url} type={BUTTON_TYPE.BACK} {...btnProps} />
		}
	}

	/**
	 * Odchytava klik na tlacitko zpet
	 * @private
	 * @method clickGoBack
	 * @param {Event} event
	 */
	clickGoBack(event) {
		const { goBackData } = this.props
		const { callback = () => {} } = goBackData

		callback(event)
	}
}

const SellerSubheaderSelector = (state) => ({
	goBackData: state[STATE_KEYS.GO_BACK_DATA]
})

export default select(SellerSubheaderSelector)(SellerSubheader)
