import React, { memo, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Context from 'ima/page/context'
import classnames from 'classnames'
import { CHEVRON_DOWN_OUTLINE_16 } from '@sznds/icons'
import { Icon, Button } from '@sznds/react'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import operatingLeasePropTypes from 'app/model/operatingLease/OperatingLeasePropTypes'
import STATE_KEYS from 'app/page/userweb/advertDetail/AdvertDetailStateKeys'
import { useLocalize } from 'app/base/componentHelpers'
import { AVAILABILITY, CATEGORIES, SHOW_CAR_INSURANCE } from 'app/base/Constants'
import AdvertTitle from 'app/component/advertTitle/AdvertTitle'
import OperatingLeaseDetail from 'app/component/operatingLease/detail/OperatingLeaseDetail'
import SaleOperatingLeaseDetailSwitch from 'app/component/operatingLease/detail/switch/SaleOperatingLeaseDetailSwitch'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'

import './AdvertBasicInfo.less'
import './AdvertBasicInfoCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-a-basic-info'

const AdvertBasicInfo = memo(
	({
		[STATE_KEYS.ADVERT_ENTITY]: advertEntity,
		[STATE_KEYS.CACHED_ADVERT_ENTITY]: cachedAdvertEntity = DEFAULT_PROPS.OBJECT,
		[STATE_KEYS.IS_OPERATING_LEASE_PAGE]: isOperatingLeasePage,
		[STATE_KEYS.SELECTED_OPERATING_LEASE_VARIANT]: selectedOperatingLeaseVariant,
		[STATE_KEYS.SET_SELECTED_OPERATING_LEASE_VARIANT]: setSelectedOperatingLeaseVariant
	}) => {
		const localize = useLocalize()
		const [isPriceInfoShowed, setIsPriceInfoShowed] = useState(false)
		const currentAdvertEntity = advertEntity || cachedAdvertEntity
		const { DotAnalytic } = useContext(Context)

		const showMorePriceInfo = () => {
			setIsPriceInfoShowed(true)
		}

		const isDataLoaded = () => {
			const { status } = currentAdvertEntity

			return !!status
		}

		const renderPrice = () => {
			const { price } = currentAdvertEntity

			if (price >= 0) {
				return (
					<div className={`notranslate ${CLASSNAME}__price`} translate='no' lang='cs-cz'>
						{Format.number(price)} {localize('Base.CZK')}
					</div>
				)
			} else {
				return <div className={`${CLASSNAME}__placeholder-price`}></div>
			}
		}

		const renderPriceWithoutVat = () => {
			const {
				priceWithoutVat,
				priceIsWithoutVat,
				priceIsVatDeductible,
				priceOriginal
			} = currentAdvertEntity

			if (priceIsWithoutVat || priceIsVatDeductible) {
				const title = localize('AdvertBasicInfo.priceWithoutVat')
				const content = (
					<span className='notranslate' translate='no' lang='cs-cz'>
						{Format.number(priceWithoutVat || priceOriginal)} {localize('Base.CZK')}
					</span>
				)
				return renderDetailPrice(title, content)
			}
		}

		const renderShowMorePriceInfoButton = () => {
			return (
				<button
					className={`${CLASSNAME}__show-more-info`}
					onClick={showMorePriceInfo}
					data-dot='show-more'
					data-dot-data='{"type": "price"}'
				>
					<span className={`${CLASSNAME}__more-text`}>
						{localize('AdvertBasicInfo.showMorePriceInfo')}
					</span>
					&nbsp;
					<Icon symbol={CHEVRON_DOWN_OUTLINE_16} className={`${CLASSNAME}__more-ico`} />
				</button>
			)
		}

		const renderDetailPrice = (title, content) => {
			return (
				<dl className={`${CLASSNAME}__row`} key={title}>
					<dt className={`${CLASSNAME}__label`}>{title}:</dt>
					<dd>{content}</dd>
				</dl>
			)
		}

		const renderMorePriceInfo = () => {
			const {
				priceLeasing,
				priceNote,
				pricePayment,
				pricePaymentCount,
				priceCompensation
			} = currentAdvertEntity

			const morePriceInfoItems = [
				{
					condition: priceLeasing > 0,
					title: localize('AdvertBasicInfo.priceLeasing'),
					content: (
						<span className='notranslate' translate='no' lang='cs-cz'>
							{Format.number(priceLeasing)} {localize('Base.CZK')}
						</span>
					)
				},
				{
					condition: priceCompensation > 0,
					title: localize('AdvertBasicInfo.priceCompensation'),
					content: (
						<span className='notranslate' translate='no' lang='cs-cz'>
							{Format.number(priceCompensation)} {localize('Base.CZK')}
						</span>
					)
				},
				{
					condition: pricePayment > 0 && pricePaymentCount > 0,
					title: localize('AdvertBasicInfo.pricePayment'),
					content: (
						<span className='notranslate' translate='no' lang='cs-cz'>
							{`${Format.number(pricePaymentCount)} x ${Format.number(pricePayment)} ${localize(
								'Base.CZK'
							)}`}
						</span>
					)
				},
				{
					condition: !!priceNote,
					title: localize('AdvertBasicInfo.priceNote'),
					content: priceNote
				}
			].filter(({ condition }) => condition)

			if (morePriceInfoItems.length) {
				return (
					<React.Fragment>
						{!isPriceInfoShowed && renderShowMorePriceInfoButton()}
						<div
							className={classnames({
								[`${CLASSNAME}__hidden`]: !isPriceInfoShowed // Kvůli SEO musí být tyto informace v DOMU a jen skryté pomocí css
							})}
						>
							{morePriceInfoItems.map(({ title, content }) => renderDetailPrice(title, content))}
						</div>
					</React.Fragment>
				)
			}
		}

		const renderSubTitleInfo = () => {
			const { conditionCb = {} } = currentAdvertEntity
			const condition = conditionCb.name

			return (
				<div className={`${CLASSNAME}__sub-title`}>
					{isDataLoaded() ? (
						<>
							<span className={`${CLASSNAME}__subtitle-info`}>
								{condition}
								{getDate()}
								{getTachometer()}
							</span>
							{getDelivery()}
						</>
					) : (
						<div className={`${CLASSNAME}__placeholder-subtitle`}></div>
					)}
				</div>
			)
		}

		const getDate = () => {
			const { manufacturingDate, inOperationDate } = currentAdvertEntity
			const date = manufacturingDate || inOperationDate
			return date ? `, ${Format.dateShort(date, true)}` : ''
		}

		const getTachometer = () => {
			const { tachometer } = currentAdvertEntity
			const tachometerValue =
				typeof tachometer === 'number'
					? `, ${Format.number(tachometer)} ${localize('Base.KM')}`
					: ''

			return tachometerValue
		}

		const getDelivery = () => {
			const {
				availabilityCb: { value: availabilityValue } = {},
				deliveryDate
			} = currentAdvertEntity

			let text = ''
			let additionalClassName = ''

			if (availabilityValue === AVAILABILITY.DATE.value && deliveryDate) {
				text = localize('AdvertBasicInfo.dateAvailability', {
					DATE: Format.dateShort(deliveryDate)
				})
			} else if (availabilityValue === AVAILABILITY.IN_STOCK.value) {
				text = localize('AdvertBasicInfo.inStockAvailability')
				additionalClassName = `${CLASSNAME}__subtitle-delivery--green`
			} else if (availabilityValue === AVAILABILITY.ORDER.value) {
				text = localize('AdvertBasicInfo.orderAvailability')
			}

			return (
				!!text && (
					<span
						className={classnames({
							[`${CLASSNAME}__subtitle-delivery`]: true,
							[additionalClassName]: !!additionalClassName
						})}
					>
						{text}
					</span>
				)
			)
		}

		const renderRegularDetail = () => {
			return (
				<>
					<SaleOperatingLeaseDetailSwitch
						dealType={currentAdvertEntity.dealType}
						showSwitchToOperatingLease={true}
					/>
					<div className={`${CLASSNAME}__price-wrapper`}>
						<div>
							{renderPrice()}
							{renderPriceWithoutVat()}
							{renderMorePriceInfo()}
						</div>
						{SHOW_CAR_INSURANCE && renderCarInsuranceButton()}
					</div>
				</>
			)
		}

		const renderCarInsuranceButton = () => {
			const { engineVolume } = currentAdvertEntity
			if (
				currentAdvertEntity?.category?.id !== CATEGORIES.PASSENGER_CARS.id ||
				isOperatingLeasePage ||
				!engineVolume
			)
				return null

			const OFFSET_TOP = 70

			const scrollToInsurance = (insuranceElement) => {
				animateScrollTo(insuranceElement, {
					offset: -OFFSET_TOP
				})
			}

			return (
				<div className={`${CLASSNAME}__insurance-btn-wrapper`}>
					<Button
						className={`${CLASSNAME}__insurance-btn`}
						size='small'
						text={localize('AdvertBasicInfo.countInsurance')}
						onClick={() => {
							const scrollToElement = document.querySelector('#insurance')

							if (scrollToElement) {
								scrollToInsurance(scrollToElement, { maxDuration: 2000 })

								window.setTimeout(() => {
									scrollToInsurance(scrollToElement)
								}, 2000)
							}

							DotAnalytic.hit({
								action: 'car-insurance',
								actionType: 'click-to-section'
							})
						}}
					/>
				</div>
			)
		}

		const renderOperatingLeaseDetail = () => {
			const isLoading = !(
				currentAdvertEntity.operatingLeaseVariants &&
				currentAdvertEntity.operatingLeaseVariants.length
			)

			return (
				<>
					<SaleOperatingLeaseDetailSwitch
						dealType={currentAdvertEntity.dealType}
						showSwitchToOperatingLease={false}
					/>
					<OperatingLeaseDetail
						isLoading={isLoading}
						intendedFor={currentAdvertEntity.operatingLeaseIntendedForCb}
						variants={currentAdvertEntity.operatingLeaseVariants}
						selectedVariant={selectedOperatingLeaseVariant}
						setSelectedVariant={setSelectedOperatingLeaseVariant}
					/>
				</>
			)
		}

		return (
			<div className={CLASSNAME} data-dot='detail-basic'>
				<AdvertTitle advertEntity={currentAdvertEntity} />
				{renderSubTitleInfo()}
				{isOperatingLeasePage ? renderOperatingLeaseDetail() : renderRegularDetail()}
			</div>
		)
	}
)

AdvertBasicInfo.propTypes = {
	[STATE_KEYS.ADVERT_ENTITY]: PropTypes.shape(advertPropTypes),
	[STATE_KEYS.CACHED_ADVERT_ENTITY]: PropTypes.shape(advertPropTypes),
	[STATE_KEYS.IS_OPERATING_LEASE_PAGE]: PropTypes.bool,
	[STATE_KEYS.SELECTED_OPERATING_LEASE_VARIANT]: PropTypes.shape(operatingLeasePropTypes),
	[STATE_KEYS.SET_SELECTED_OPERATING_LEASE_VARIANT]: PropTypes.func
}

export default AdvertBasicInfo
