import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'
import Split from '../../split/Split'
import BasicSelectWidget from '../basicSelect/BasicSelectWidget'

import './DoorsSelectCS.json'

export const DOORS_AND_CAPACITY_SELECT_WIDGET_ID = 'doors_and_capacity_select_widget'
export const DOORS_AND_SEATS_SELECT_WIDGET_ID = 'doors_and_seats_select_widget'

const CUSTOM_DOORS_FORM_LINE_ID = 'doors'
const CUSTOM_CAPACITY_TONE_FORM_LINE_ID = 'doors-capacity'
const CUSTOM_SEATS_TONE_FORM_LINE_ID = 'doors-seats'

const DoorsSelect = (props) => {
	const localize = useLocalize()

	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		onChange = DEFAULT_PROPS.FUNCTION,
		doorsSelectId
	} = props

	const onDoorsChange = (change) => {
		const { value: newDoors } = change
		const { id, value: [, currentCapacityOrSeatsValue] = [] } = formLineEntity

		onChange(
			Object.assign(
				{},
				change,
				{ id },
				{
					value: [newDoors, currentCapacityOrSeatsValue]
				}
			)
		)
	}

	const onCapacityOrSeatsChange = (change) => {
		const { value: newCapacityOrSeats } = change
		const { id, value: [currentDoors] = [] } = formLineEntity

		onChange(
			Object.assign(
				{},
				change,
				{ id },
				{
					value: [currentDoors, newCapacityOrSeats]
				}
			)
		)
	}

	const { value: [currentDoors] = [] } = formLineEntity

	return (
		<Split
			renderLeft={() => (
				<BasicSelectWidget
					inputId={doorsSelectId}
					formLineEntity={FormLines.createEntity(
						Object.assign({}, formLineEntity, {
							id: CUSTOM_DOORS_FORM_LINE_ID,
							label: localize('DoorsSelect.doors'),
							placeholder: localize('DoorsSelect.doorsPlaceholder'),
							value: currentDoors
						})
					)}
					onChange={onDoorsChange}
				/>
			)}
			renderRight={() => {
				const { id } = formLineEntity

				if (id === FORM_LINES_IDS.DOORS_AND_CAPACITY) {
					const { value: [, currentCapacity] = [] } = formLineEntity

					return (
						<BasicSelectWidget
							formLineEntity={FormLines.createEntity(
								Object.assign({}, formLineEntity, {
									id: CUSTOM_CAPACITY_TONE_FORM_LINE_ID,
									label: localize('DoorsSelect.capacity'),
									placeholder: localize('DoorsSelect.capacityPlaceholder'),
									value: currentCapacity,
									options: formLineEntity.extra.options
								})
							)}
							onChange={onCapacityOrSeatsChange}
						/>
					)
				} else if (id === FORM_LINES_IDS.DOORS_AND_SEATS) {
					const { value: [, currentSeats] = [] } = formLineEntity

					return (
						<BasicSelectWidget
							formLineEntity={FormLines.createEntity(
								Object.assign({}, formLineEntity, {
									id: CUSTOM_SEATS_TONE_FORM_LINE_ID,
									label: localize('DoorsSelect.seats'),
									placeholder: localize('DoorsSelect.seatsPlaceholder'),
									value: currentSeats,
									options: formLineEntity.extra.options
								})
							)}
							onChange={onCapacityOrSeatsChange}
						/>
					)
				}
			}}
		/>
	)
}

DoorsSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	doorsSelectId: PropTypes.string
}

export default React.memo(DoorsSelect)
