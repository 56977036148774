import React, { memo, useContext } from 'react'
import classnames from 'classnames'
import Context from 'ima/page/context'
import { CHEVRON_UP_FILLED_16, CHEVRON_DOWN_FILLED_16 } from '@sznds/icons'
import { Surface, Icon } from '@sznds/react'
import PropTypes from 'prop-types'

import './TableHeadSorter.less'

const CLASSNAME = 'c-table-head-sorter'

const TableHeadSorter = ({
	children,
	sortParamName = 'sort',
	sortParam,
	pageParamName = 'page',
	isDefaultAscending = false,
	isDefaultDescending = false,
	alignRight = false
}) => {
	const { $Router } = useContext(Context)
	const { params = {}, route } = $Router.getCurrentRouteInfo()

	const getUrl = (ascending) => {
		const sortParams = { [sortParamName]: ascending ? sortParam : `-${sortParam}` }
		const updatedParams = Object.assign({}, params, sortParams)

		delete updatedParams[pageParamName]

		return $Router.link(route.getName(), updatedParams)
	}

	const isAscending =
		params[sortParamName] === sortParam ||
		(isDefaultAscending && !params.hasOwnProperty(sortParamName))
	const isDescending =
		params[sortParamName] === `-${sortParam}` ||
		(isDefaultDescending && !params.hasOwnProperty(sortParamName))
	const isSelected = isAscending || isDescending

	const renderedButtonText = <span className={`${CLASSNAME}__text`}>{children}</span>

	return (
		<span
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}--selected`]: isSelected,
				[`${CLASSNAME}--align-right`]: alignRight
			})}
		>
			{isDescending ? (
				<Button
					icon={<Icon symbol={CHEVRON_DOWN_FILLED_16} />}
					href={getUrl(true)}
					text={renderedButtonText}
				/>
			) : isAscending ? (
				<Button
					icon={<Icon symbol={CHEVRON_UP_FILLED_16} />}
					href={getUrl(false)}
					text={renderedButtonText}
				/>
			) : (
				<Button
					icon={
						<span className={`${CLASSNAME}__arrows`}>
							<Icon symbol={CHEVRON_UP_FILLED_16} />
							<Icon symbol={CHEVRON_DOWN_FILLED_16} />
						</span>
					}
					href={getUrl(false)}
					text={renderedButtonText}
				/>
			)}
		</span>
	)
}

TableHeadSorter.propTypes = {
	children: PropTypes.node,
	sortParamName: PropTypes.string,
	sortParam: PropTypes.string,
	pageParamName: PropTypes.string,
	isDefaultAscending: PropTypes.bool,
	isDefaultDescending: PropTypes.bool,
	alignRight: PropTypes.bool
}

export default TableHeadSorter

const Button = memo((props) => {
	const { icon, text, href, ...restProps } = props

	return (
		<Surface
			tagName='button'
			surface={0}
			className={`sds-button sds-button--xsmall ${CLASSNAME}__btn`}
			role='button'
			type='button'
			href={href}
			{...restProps}
		>
			{icon}
			{text}
		</Surface>
	)
})

Button.propTypes = {
	icon: PropTypes.node,
	text: PropTypes.node,
	href: PropTypes.string
}
