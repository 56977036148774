import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import BrandModelOpener from 'app/component/filters/forms/main/openers/brandModelOpener/BrandModelOpener'
import AdvertListBrandModelOpener from 'app/component/filters/forms/main/openers/brandModelOpener/AdvertListBrandModelOpener'
import BrandForm from 'app/component/filters/forms/brand/BrandForm'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import SearchFilterPopup from './shared/SearchFilterPopup'
import FilterPopupWithOpener from './shared/FilterPopupWithOpener'
import DependentPopupsOpenerLogic from 'app/component/filters/logic/openers/DependentPopupsOpenerLogic'
import BrandModelFilterLogic from 'app/component/filters/logic/BrandModelFilterLogic'
import ScopedFormLineEntity from 'app/component/filters/logic/ScopedFormLineEntity'
import DependentPopupsLogic from 'app/component/filters/logic/modals/DependentPopupsLogic'
import FilterPopupButton from './shared/FilterPopupButton'
import UndoablePopup from './shared/UndoablePopup'
import BrandModelForm from 'app/component/filters/forms/brand/BrandModelForm'
import FilterConstants from 'app/model/filter/FilterConstants'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'

import './BrandModelFilterPopupWithOpener.less'
import './BrandModelFilterPopupWithOpenerCS.json'

class BrandModelFilterPopupWithOpener extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			popupDefinition: PropTypes.object,
			context: PropTypes.string.isRequired,
			value: PropTypes.number, //TODO lepsi nazev? brandValue mozna
			filterIndex: PropTypes.number,
			renderOpener: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderOpener = this._renderOpener.bind(this)
		this._renderPopup = this._renderPopup.bind(this)
		this._renderBrandForm = this._renderBrandForm.bind(this)
	}

	render() {
		const { popupDefinition, context, value: brandValue } = this.props

		return (
			<FilterPopupWithOpener
				popupDefinition={popupDefinition}
				context={context}
				value={brandValue}
				renderOpener={this._renderOpener}
				renderPopup={this._renderPopup}
			/>
		)
	}

	_renderOpener() {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			context,
			renderOpener
		} = this.props

		return (
			<BrandModelFilterLogic
				formLineEntity={globalFormLineEntity}
				changeFilter={globalChangeFilter}
				context={context}
			>
				{(brandModelFilterLogic) => {
					const {
						form: { changeFilter }
					} = brandModelFilterLogic
					return (
						<DependentPopupsOpenerLogic
							formLineEntity={globalFormLineEntity}
							changeFilter={changeFilter}
							context={context}
						>
							{(popupOpenerLogicData) => {
								return renderOpener
									? renderOpener(popupOpenerLogicData, brandModelFilterLogic)
									: this._rendelModelOpener(popupOpenerLogicData, brandModelFilterLogic)
							}}
						</DependentPopupsOpenerLogic>
					)
				}}
			</BrandModelFilterLogic>
		)
	}

	_rendelModelOpener(popupOpenerLogicData, brandModelFilterLogic) {
		const { context } = this.props

		if (context === FILTER_CONTEXT.MAIN_MENU) {
			return <div>{this._renderBrandModelOpener(popupOpenerLogicData, brandModelFilterLogic)}</div>
		} else {
			return this._renderBrandModelOpener(popupOpenerLogicData, brandModelFilterLogic)
		}
	}

	_renderBrandModelOpener(popupOpenerLogicData, brandModelFilterLogic) {
		const {
			formLineEntity: globalFormLineEntity,
			categoryEntity,
			context,
			filterIndex
		} = this.props

		const {
			openMainPopup: openAllBrandsPopup,
			openDependentPopup: openBrandModelsPopup,
			clearDependentFilter: clearBrandModelsFilter
		} = popupOpenerLogicData

		const {
			form: { changeFilter },
			advertsCountForBrands,
			advertsCountForModels
		} = brandModelFilterLogic

		return context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL ||
			context === FILTER_CONTEXT.EXTENDED_FILTER ? (
			<AdvertListBrandModelOpener
				formLineEntity={globalFormLineEntity}
				categoryEntity={categoryEntity}
				changeFilter={changeFilter}
				advertsCountForBrands={advertsCountForBrands}
				advertsCountForModels={advertsCountForModels}
				getSortedFavoriteBrands={this.utils.FilterHelper.getSortedFavoriteOptions}
				increaseFavoriteBrandHit={this.utils.FilterHelper.increaseFavoriteBrandClick}
			/>
		) : (
			<BrandModelOpener
				formLineEntity={globalFormLineEntity}
				context={context}
				filterIndex={filterIndex}
				categoryEntity={categoryEntity}
				onOpen={openAllBrandsPopup}
				onOpenBrandModels={openBrandModelsPopup}
				clearBrandModelsFilter={clearBrandModelsFilter}
				isPopup={true}
			/>
		)
	}

	_renderPopup() {
		const { formLineEntity: globalFormLineEntity, openedFilterPopups, context } = this.props

		const { id } = globalFormLineEntity

		//TODO ne podle id, protoze to je manufacturer_cb
		const modelPopupDefinition = openedFilterPopups.find(
			(popup) => popup.filterId === id && popup.openedByValue > 0 && popup.context === context
		)

		return (
			<ScopedFormLineEntity formLineEntity={globalFormLineEntity}>
				{(scopedFormLineEntityChildrenProps) => {
					const { localFormLineEntity, localChangeFilter } = scopedFormLineEntityChildrenProps

					return (
						<BrandModelFilterLogic
							formLineEntity={localFormLineEntity}
							changeFilter={localChangeFilter}
							context={context}
							brandValue={modelPopupDefinition ? modelPopupDefinition.openedByValue : undefined}
						>
							{(filterLogicChildrenProps) => {
								return (
									<React.Fragment>
										{context !== FILTER_CONTEXT.TAGS &&
											this._renderBrandPopup(filterLogicChildrenProps)}

										{this._renderBrandModelPopup(filterLogicChildrenProps)}
									</React.Fragment>
								)
							}}
						</BrandModelFilterLogic>
					)
				}}
			</ScopedFormLineEntity>
		)
	}

	_renderBrandPopup(filterLogicChildrenProps) {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			popupDefinition,
			value
		} = this.props
		const {
			other: { formLineEntity: localFormLineEntity }
		} = filterLogicChildrenProps

		return (
			<DependentPopupsLogic
				formLineEntity={localFormLineEntity}
				changeFilter={globalChangeFilter}
				popupDefinition={popupDefinition}
				value={value}
				filterLogicData={Object.assign({}, filterLogicChildrenProps, {
					advertsCountForMainPopup: filterLogicChildrenProps.advertsCountForBrands,
					advertsCountForDependentPopup: filterLogicChildrenProps.advertsCountForModels
				})}
			>
				{(filterPopupLogicChildrenProps) => {
					const { mainPopup: mainPopupLogicData } = filterPopupLogicChildrenProps

					return (
						<SearchFilterPopup
							formLineEntity={globalFormLineEntity}
							title={globalFormLineEntity.placeholder}
							searchInputPlaceholder={this.localize(
								'BrandModelFilterPopupWithOpener.searchPlaceholder'
							)}
							value={value}
							filterPopupLogicData={mainPopupLogicData}
							renderContent={(_, searchTerm) =>
								this._renderBrandForm(
									filterPopupLogicChildrenProps,
									filterLogicChildrenProps,
									searchTerm
								)
							}
							headerDataDotData={'{"type":"manufacturer"}'}
						/>
					)
				}}
			</DependentPopupsLogic>
		)
	}

	_renderBrandModelPopup(filterLogicChildrenProps) {
		const {
			changeFilter: globalChangeFilter,
			popupDefinition,
			value,
			context,
			openedFilterPopups
		} = this.props
		const {
			form: { changeFilter: localChangeFilter },
			other: { formLineEntity: localFormLineEntity }
		} = filterLogicChildrenProps

		const { id } = localFormLineEntity

		//TODO ne podle id, protoze to je manufacturer_cb
		const modelPopupDefinition = openedFilterPopups.find(
			(popup) => popup.filterId === id && popup.openedByValue > 0 && popup.context === context
		)

		if (modelPopupDefinition) {
			const { openedByValue: brandValue } = modelPopupDefinition

			const { options = [] } = localFormLineEntity
			const { name: brandName } = options.find((option) => option.value === brandValue) || {}

			return (
				<DependentPopupsLogic
					formLineEntity={localFormLineEntity}
					changeFilter={globalChangeFilter}
					popupDefinition={popupDefinition}
					value={value}
					dependentPopupDefinition={modelPopupDefinition}
					dependentValue={modelPopupDefinition ? modelPopupDefinition.openedByValue : undefined}
					filterLogicData={Object.assign({}, filterLogicChildrenProps, {
						advertsCountForMainPopup: filterLogicChildrenProps.advertsCountForBrands,
						advertsCountForDependentPopup: filterLogicChildrenProps.advertsCountForModels
					})}
				>
					{(filterPopupLogicChildrenProps) => {
						const { dependentPopup } = filterPopupLogicChildrenProps

						return (
							<UndoablePopup
								formLineEntity={localFormLineEntity}
								onChange={localChangeFilter}
								title={brandName}
								filterPopupLogicData={dependentPopup}
								renderContent={() =>
									this._renderBrandModelForm(
										filterPopupLogicChildrenProps,
										filterLogicChildrenProps,
										brandValue
									)
								}
								renderFooter={() =>
									this._renderButton(filterPopupLogicChildrenProps, filterLogicChildrenProps)
								}
								headerDataDotData={'{"type":"models"}'}
							/>
						)
					}}
				</DependentPopupsLogic>
			)
		} else {
			return null
		}
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderBrandForm(filterPopupLogicChildrenProps, filterLogicChildrenProps, searchTerm) {
		const { context } = this.props
		const {
			advertsCountForBrands: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity, categoryEntity }
		} = filterLogicChildrenProps

		return (
			<BrandForm
				formLineEntity={formLineEntity}
				context={context}
				categoryEntity={categoryEntity}
				filteredBrandName={searchTerm}
				filteredAdvertsCount={filteredAdvertsCount}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				onBrandClick={(brandValue) => {
					this.fire('openFilterPopup', {
						filterId: formLineEntity.id,
						openedByValue: brandValue,
						context,
						filterPopupType: FilterConstants.filterPopupType.BACK,
						filterButtonType: FilterConstants.filterButtonType.CONTINUE,
						updateUrlParams: false
					})
				}}
			/>
		)
	}

	_renderBrandModelForm(filterPopupLogicChildrenProps, filterLogicChildrenProps, brandValue) {
		const { popupDefinition = {} } = this.props
		const { filterButtonType } = popupDefinition
		const {
			form: { changeFilter },
			advertsCountForModels: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity }
		} = filterLogicChildrenProps

		return (
			<BrandModelForm
				formLineEntity={formLineEntity}
				onChange={changeFilter}
				brandValue={brandValue}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				filteredAdvertsCount={filteredAdvertsCount}
				// pokud je videt CTA tlacitko s poctem nabidek (a textem 'Zobrazit xxx
				// nabidek'), tak nechceme v bublinach s pocty inzeratu znovu vypisovat
				// text 'xxx nabidek', ale pouze 'xxx'
				isCountShownWithAddText={filterButtonType !== FilterConstants.filterButtonType.SEARCH}
			/>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderButton(filterPopupLogicChildrenProps, filterLogicChildrenProps) {
		const { popupDefinition } = this.props
		const { dependentPopup } = filterPopupLogicChildrenProps

		return (
			<FilterPopupButton
				filterPopupLogicData={dependentPopup}
				filterFormLogicData={Object.assign({}, filterLogicChildrenProps, {
					advertsCount: filterLogicChildrenProps.advertsCountForModels
				})}
				popupDefinition={popupDefinition}
			/>
		)
	}
}

const selector = (state) => ({
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS]
})

export default select(selector)(BrandModelFilterPopupWithOpener)
