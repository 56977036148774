import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import select from 'ima-plugin-select'
import FilterConstants from 'app/model/filter/FilterConstants'
import FiltersExtension from 'app/component/filters/FiltersExtension'

class TagLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			context: PropTypes.string.isRequired,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._openPopup = this._openPopup.bind(this)
		this._openDropdown = this._openDropdown.bind(this)
		this._deleteTag = this._deleteTag.bind(this)
	}

	render() {
		const { children } = this.props

		return children({
			openPopup: this._openPopup,
			openDropdown: this._openDropdown,
			deleteTag: this._deleteTag
		})
	}

	_openPopup(value) {
		const {
			formLineEntity: { id },
			context
		} = this.props

		this.fire('openFilterPopup', {
			filterId: id,
			openedByValue: value,
			context,
			filterPopupType: FilterConstants.filterPopupType.DEFAULT,
			filterButtonType: FilterConstants.filterButtonType.SEARCH,
			updateUrlParams: true
		})
	}

	_openDropdown(value, dependentValue) {
		const {
			formLineEntity: { id },
			context
		} = this.props

		this.fire('openFilterDropdown', {
			filterId: id,
			openedByValue: value,
			openedByDependentValue: dependentValue, // model nebo okres
			context,
			filterDropdownType: FilterConstants.filterDropdownType.DEFAULT,
			updateUrlParams: true
		})
	}

	_deleteTag(newValue) {
		const {
			formLineEntity: { id },
			changeFilter,
			redirect
		} = this.props

		changeFilter(id, newValue, (newFormLineEntity) => {
			redirect()
		})
	}
}

const selectors = (state) => ({
	redirect: state[FiltersExtension.stateIds.REDIRECT]
})

export default select(selectors)(TagLogic)
