import React from 'react'
import { LayoutAdvertEdit } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import IAEquipmentForm from './components/IAEquipmentForm'

export default function IAEquipmentView(props) {
	return (
		<LayoutAdvertEdit>
			<IAEquipmentForm />
		</LayoutAdvertEdit>
	)
}
