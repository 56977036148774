import { AB_TEST_COOKIE } from 'app/base/StorageIds'

/**
 * Při zapnutí nového testu je potřeba upravit názvy variant.
 * napr. 'ab_test_cebia/a'
 */
export const VARIANTS = {
	VARIANT_A: 'nothing/a',
	VARIANT_B: 'nothing/b',
	VARIANT_C: 'nothing/c'
}

const ABTestOptions = [
	{
		variant: VARIANTS.VARIANT_A,
		min: 0,
		max: 0.1
	},
	{
		variant: VARIANTS.VARIANT_B,
		min: 0.1,
		max: 0.2
	},
	{
		variant: VARIANTS.VARIANT_C,
		min: 0.2,
		max: 1
	}
]
export default class ABTestHelper {
	constructor(cookieStorage, dotAnalytic, cmpHelper) {
		this._cookieStorage = cookieStorage
		this._dotAnalytic = dotAnalytic
		this._cmpHelper = cmpHelper
		this._variant = null
	}

	init() {
		const variant = this.getVariant()

		if (variant) {
			this._dotAnalytic.setAbtest(variant)
		}
	}

	getVariant() {
		if (this._variant === null && this._cmpHelper.hasCmp()) {
			let variant

			variant = this._cookieStorage.get(AB_TEST_COOKIE) || null

			if (variant === null) {
				this._variant = this._setVariant()
			} else {
				this._variant = variant
			}
		}

		return this._variant
	}

	_setVariant() {
		if (this._cmpHelper.hasCmp()) {
			const random = Math.random()

			for (var i = 0, l = ABTestOptions.length; i < l; i++) {
				if (ABTestOptions[i].min <= random && random < ABTestOptions[i].max) {
					const variant = ABTestOptions[i].variant

					try {
						this._cookieStorage.set(AB_TEST_COOKIE, variant, {
							expires: this._getCookieExpirationDate(180)
						})
					} catch (e) {
						//nebudeme reagovat
					}

					return variant
				}
			}
		}
	}

	_getCookieExpirationDate(days) {
		const now = new Date()

		const time = now.getTime()
		const expireTime = time + 1000 * 60 * 60 * 24 * days
		now.setTime(expireTime)

		return now
	}
}
