import React, { useContext } from 'react'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import Context from 'ima/page/context'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import IAStatisticsController, {
	FILTER_BY_ADVERT,
	applyDefaultSort
} from './IAStatisticsController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { PaginationEntity } from '@inzeraty/models'
import AdvertStatisticsEntity from 'app/model/advert/statistics/AdvertStatisticsEntity'
import { useFire } from 'app/base/componentHelpers'
import ExportButton from 'app/page/internalAdmin/components/exportButton/ExportButton'
import MetricsDataHelper from 'app/page/userweb/clientStatistics/components/MetricsDataHelper'

import './IAStatisticsView.less'

const CLASSNAME = 'ia-statistics'

export const getDimensionHeading = (selectedDimension) => {
	switch (selectedDimension) {
		case IAStatisticsController.GROUP_BY.PREMISE.key:
			return 'Firma'
		case IAStatisticsController.GROUP_BY.DATE.key:
			return 'Datum'
		case IAStatisticsController.GROUP_BY.CATEGORY.key:
			return 'Kategorie'
		case IAStatisticsController.GROUP_BY.MANUFACTURER.key:
			return 'Výrobce'
		case IAStatisticsController.GROUP_BY.ADVERT.key:
			return 'Inzerát'
		case IAStatisticsController.GROUP_BY.REGION.key:
			return 'Kraj'
		case IAStatisticsController.GROUP_BY.DISTRICT.key:
			return 'Okres'
		case IAStatisticsController.GROUP_BY.SOURCE.key:
			return 'Zadáno'
		default:
			return 'Firma'
	}
}

export const getDimensionRowCellValue = (advertStatisticsEntity = {}) => {
	const { groupBy: { dimension, id, name } = {} } = advertStatisticsEntity

	return dimension === IAStatisticsController.GROUP_BY.SOURCE.key
		? IAStatisticsController.SOURCE[id]
		: name
}

export default function IAStatisticsView({
	filterFormLineEntities = DEFAULT_PROPS.ARRAY,
	isFilterLoading,
	entitiesAndPagination = DEFAULT_PROPS.OBJECT,
	isLoading,
	isOperatingLease = false,
	isMirroring = false
}) {
	const context = useContext(Context)
	const router = context.$Router
	const fire = useFire()

	const { params } = router.getCurrentRouteInfo()

	const showActiveAdvertColumn =
		params[InternalAdminUrlConvertor.constants.FILTER_BY] !== FILTER_BY_ADVERT

	const { errorMessage } = entitiesAndPagination

	const isSales = !isOperatingLease && !isMirroring

	return (
		<Layout title='Statistiky'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />
			{errorMessage ? <div className={`${CLASSNAME}__error`}>{errorMessage}</div> : null}

			<ExportButton
				onClick={() => {
					fire('exportStatistics')
				}}
				buttonText='Exportovat statistiky'
			/>

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					params[InternalAdminUrlConvertor.constants.GROUP_BY] === 'date' ? (
						<TableHeadSorter sortParam='id' isDefaultAscending={true}>
							{getDimensionHeading(params[InternalAdminUrlConvertor.constants.GROUP_BY])}
						</TableHeadSorter>
					) : (
						<TableHead>
							{getDimensionHeading(params[InternalAdminUrlConvertor.constants.GROUP_BY])}
						</TableHead>
					),
					showActiveAdvertColumn && (
						<TableHeadSorter
							sortParam='active_advert'
							isDefaultDescending={applyDefaultSort(params)}
							alignRight={true}
						>
							Počet inzerátů
						</TableHeadSorter>
					),
					<TableHeadSorter
						key='detail_view'
						sortParam={
							isOperatingLease
								? 'detail_view_operating_lease'
								: isMirroring
								? 'detail_view_mirroring'
								: 'detail_view'
						}
						alignRight={true}
					>
						Zobrazení detailu
					</TableHeadSorter>,
					<TableHeadSorter
						key='list_view'
						sortParam={
							isOperatingLease
								? 'list_view_operating_lease'
								: isMirroring
								? 'list_view_mirroring'
								: 'list_view'
						}
						alignRight={true}
					>
						Náhled výpisu
					</TableHeadSorter>,
					<TableHeadSorter
						key='detail_view_from_list'
						sortParam={
							isOperatingLease
								? 'detail_view_from_list_operating_lease'
								: isMirroring
								? 'detail_view_from_list_mirroring'
								: 'detail_view_from_list'
						}
						alignRight={true}
					>
						Detail z výpisu
					</TableHeadSorter>,
					<TableHeadSorter
						key='detail_view_from_other'
						sortParam={
							isOperatingLease
								? 'detail_view_from_other_operating_lease'
								: isMirroring
								? 'detail_view_from_other_mirroring'
								: 'detail_view_from_other'
						}
						alignRight={true}
					>
						Zdroj prokliku neznám
					</TableHeadSorter>,
					<TableHeadSorter key='po_view' sortParam='po_view' alignRight={true}>
						Náhled výpisu PV
					</TableHeadSorter>,
					<TableHeadSorter
						key='detail_view_from_po'
						sortParam='detail_view_from_po'
						alignRight={true}
					>
						Detail z PV
					</TableHeadSorter>,
					<TableHeadSorter
						key='reply_sent'
						sortParam={
							isOperatingLease
								? 'reply_sent_operating_lease'
								: isMirroring
								? 'reply_sent_mirroring'
								: 'reply_sent'
						}
						alignRight={true}
					>
						Počet odpovědí
					</TableHeadSorter>,
					<TableHeadSorter
						key='phone_view'
						sortParam={
							isOperatingLease
								? 'phone_view_operating_lease'
								: isMirroring
								? 'phone_view_mirroring'
								: 'phone_view'
						}
						alignRight={true}
					>
						Počet zobrazení telefonu
					</TableHeadSorter>
				].filter((head) => !!head)}
				rowCells={[
					getDimensionRowCellValue,
					showActiveAdvertColumn &&
						(({ metrics = {} }) => [Format.number(metrics.activeAdvert), { isNumber: true }]),
					({ metrics = {} }) => [
						Format.number(
							MetricsDataHelper.getDetailViewMetric(isOperatingLease, isMirroring, metrics)
						),
						{ isNumber: true }
					],
					({ metrics = {} }) => [
						Format.number(
							MetricsDataHelper.getListViewMetric(isOperatingLease, isMirroring, metrics)
						),
						{ isNumber: true }
					],
					({ metrics = {} }) => [
						Format.number(
							MetricsDataHelper.getDetailViewFromListMetric(isOperatingLease, isMirroring, metrics)
						),
						{ isNumber: true }
					],
					({ metrics = {} }) => [
						Format.number(
							MetricsDataHelper.getDetailViewFromOtherMetric(isOperatingLease, isMirroring, metrics)
						),
						{ isNumber: true }
					],
					({ metrics = {} }) => [isSales ? Format.number(metrics.POView) : 0, { isNumber: true }],
					({ metrics = {} }) => [
						isSales ? Format.number(metrics.detailViewFromPO) : 0,
						{ isNumber: true }
					],
					({ metrics = {} }) => [
						Format.number(
							MetricsDataHelper.getReplySentMetric(isOperatingLease, isMirroring, metrics)
						),
						{ isNumber: true }
					],
					({ metrics = {} }) => [
						Format.number(
							MetricsDataHelper.getPhoneViewMetric(isOperatingLease, isMirroring, metrics)
						),
						{ isNumber: true }
					]
				].filter((row) => !!row)}
				isLoading={isLoading}
				entityIdGetter={({ groupBy: { id } = {} }) => id}
			/>
		</Layout>
	)
}

IAStatisticsView.propTypes = {
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(AdvertStatisticsEntity)),
		paginationEntity: PropTypes.instanceOf(PaginationEntity),
		errorMessage: PropTypes.string
	}),
	isLoading: PropTypes.bool,
	isOperatingLease: PropTypes.bool,
	isMirroring: PropTypes.bool
}
