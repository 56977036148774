
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['DemandForm'] = {
  nameLabel: function(d) { return "Jméno a příjmení"; },
  nameErrorEmpty: function(d) { return "Vyplňte prosím své jméno."; },
  phoneLabel: function(d) { return "Telefonní číslo"; },
  phoneErrorEmpty: function(d) { return "Vyplňte prosím svůj telefon."; },
  phoneErrorValue: function(d) { return "Zadejte telefonní číslo ve správném formátu +420123456789."; },
  messageLabel: function(d) { return "Zpráva prodejci"; },
  messageErrorEmpty: function(d) { return "Vyplňte prosím zprávu prodejci."; },
  submit: function(d) { return "Odeslat"; },
  nameError_required: function(d) { return "Vyplňte prosím své jméno."; },
  emailError_required: function(d) { return "Vyplňte prosím svůj e-mail."; },
  phoneError_required: function(d) { return "Vyplňte prosím svůj telefon."; },
  messageError_required: function(d) { return "Vyplňte prosím zprávu prodejci."; },
  formSendError: function(d) { return "Formulář se nepodařilo odeslat."; },
  dataProcessing: function(d) { return "Zadané údaje zpracováváme dle těchto "; },
  terms: function(d) { return "podmínek"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	