import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { InputSurface, Radio, Icon } from '@sznds/react'
import { TRASH_FILLED_24 } from '@sznds/icons'
import InputMask from 'react-input-mask'
import SimpleTooltip from 'app/component/simpleTooltip/SimpleTooltipView'
import classnames from 'classnames'
import ActionElm from 'app/component/actionElement/ActionElement'
import { CONTACT_INPUT_NAMES } from './ContactsConstants'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import PropTypes from 'prop-types'

import './ContactsWidget.less'
import './ContactsCS.json'

export const CONTACTS_WIDGET_ID = 'contacts_widget'

const CLASSNAME = 'c-cp-contacts-widget'

export const CONTACT_INPUT_TYPE = {
	PHONE: 'tel',
	EMAIL: 'email',
	TEXT: 'text'
}

export const CONTACT_ERROR_MESSAGE = {
	INDEX: 'index',
	MESSAGE: 'message'
}

function ContactsWidget({ className = '', ...restProps }) {
	const { formLineEntity, onChange, section } = restProps

	const localize = useLocalize()

	const {
		id,
		label,
		value,
		extra,
		disabled,
		required,
		errorMessage = DEFAULT_PROPS.OBJECT
	} = formLineEntity

	const {
		valueInputName,
		inputValueType = CONTACT_INPUT_TYPE.TEXT,
		inputValuePatern,
		inputValueMode,
		inputValueMaskProps,
		inputValuePlaceholder = '',
		inputNamePlaceholder = '',
		defaultContact = localize('Contacts.defaultContact'),
		addContact = localize('Contacts.addContact'),
		limits = {},
		getValidationMessage
	} = extra

	const getInputValueProps = (inputValue, rowIndex, errorMsg) => {
		const inputProps = {
			tagName: 'input',
			value: inputValue,
			onChange: ({ target: { value: newValue } }) => {
				value[rowIndex][valueInputName] = newValue

				onChange({
					id,
					value,
					section
				})
			},
			onBlur: () => {
				setErrorMessage(valueInputName, rowIndex, inputValue)
			},
			name: `${valueInputName}-${rowIndex}`,
			className: `${CLASSNAME}__input`,
			type: inputValueType,
			size: 'small',
			disabled,
			error: !!errorMsg,
			placeholder: inputValuePlaceholder
		}

		if (inputValueMode) {
			inputProps.inputMode = inputValueMode
		}

		if (inputValuePatern) {
			inputProps.pattern = inputValuePatern
		}

		if (limits.value) {
			inputProps.maxLength = limits.value
		}

		if (inputValueMaskProps) {
			return { ...inputProps, ...inputValueMaskProps }
		} else {
			return inputProps
		}
	}

	const getInputNameProps = (inputValue, rowIndex, errorMsg) => {
		const inputProps = {
			tagName: 'input',
			className: `${CLASSNAME}__input`,
			type: 'text',
			size: 'small',
			disabled,
			error: !!errorMsg,
			value: inputValue,
			name: `${CONTACT_INPUT_NAMES.CONTACT_NAME}-${rowIndex}`,
			placeholder: inputNamePlaceholder,
			onChange: ({ target: { value: newName } }) => {
				value[rowIndex].name = newName

				onChange({
					id,
					value,
					section
				})
			},
			onBlur: () => {
				setErrorMessage(CONTACT_INPUT_NAMES.CONTACT_NAME, rowIndex, inputValue)
			}
		}

		if (limits.name) {
			inputProps.maxLength = limits.name
		}

		return inputProps
	}

	const getErrorMessage = (name, rowIndex) => {
		if (errorMessage[name]) {
			const errorMsg =
				errorMessage[name].find((msg) => msg[CONTACT_ERROR_MESSAGE.INDEX] === rowIndex) || {}
			return errorMsg[CONTACT_ERROR_MESSAGE.MESSAGE] || ''
		} else {
			return ''
		}
	}

	const setErrorMessage = (name, rowIndex, value) => {
		if (getValidationMessage) {
			const errorMsg = getValidationMessage(value, name, rowIndex === 0 && required, id) || ''

			let newErrorMessage = getReducedErrorMessage(rowIndex, name)

			if (errorMsg) {
				newErrorMessage = { ...newErrorMessage } || {}

				if (!newErrorMessage[name]) {
					newErrorMessage[name] = []
				}

				newErrorMessage[name].push({
					[CONTACT_ERROR_MESSAGE.INDEX]: rowIndex,
					[CONTACT_ERROR_MESSAGE.MESSAGE]: errorMsg
				})
			}

			onChange({
				id,
				errorMessage: newErrorMessage,
				section
			})
		}
	}

	const getReducedErrorMessage = (rowIndex, inputName) => {
		// pokud nnei definovan nazev inputu budu mazat cely radek
		const removeLine = !inputName

		const inputNames = removeLine ? [valueInputName, CONTACT_INPUT_NAMES.CONTACT_NAME] : [inputName]

		const newErrorMessage = {}
		const reducedErroMessage = { ...errorMessage }

		inputNames.forEach((inputName) => {
			if (reducedErroMessage && errorMessage[inputName]) {
				newErrorMessage[inputName] = []

				// ulozim si jen errorMessage s nizsim indexem, u texh s vyssim indexkem jej ponizim a ulozim
				errorMessage[inputName].forEach((msg) => {
					if (removeLine) {
						if (msg[CONTACT_ERROR_MESSAGE.INDEX] < rowIndex) {
							newErrorMessage[inputName].push(msg)
						} else if (msg[CONTACT_ERROR_MESSAGE.INDEX] > rowIndex) {
							newErrorMessage[inputName].push({
								[CONTACT_ERROR_MESSAGE.INDEX]: msg[CONTACT_ERROR_MESSAGE.INDEX] - 1,
								[CONTACT_ERROR_MESSAGE.MESSAGE]: msg[CONTACT_ERROR_MESSAGE.MESSAGE]
							})
						}
					} else {
						if (msg[CONTACT_ERROR_MESSAGE.INDEX] !== rowIndex) {
							newErrorMessage[inputName].push(msg)
						}
					}
				})

				reducedErroMessage[inputName] = newErrorMessage[inputName]

				// pokud pro sloupec hodnot neni zadna zprava, tak smazu zpravy pro cely sloupec
				if (!reducedErroMessage[inputName].length) {
					delete reducedErroMessage[inputName]
				}
			}
		})

		return Object.keys(reducedErroMessage).length ? reducedErroMessage : undefined
	}

	const renderContactInput = (inputProps, errorMsg = '') => {
		if (inputProps.mask) {
			return (
				<div className={`${CLASSNAME}__input-wrapper`}>
					<InputMask {...inputProps}>
						{(inputMaskProps) => <InputSurface disabled={disabled} {...inputMaskProps} />}
					</InputMask>
					{renderErrorMessage(errorMsg)}
				</div>
			)
		} else {
			return (
				<div className={`${CLASSNAME}__input-wrapper`}>
					<InputSurface {...inputProps} />
					{renderErrorMessage(errorMsg)}
				</div>
			)
		}
	}

	const renderContactValueInput = (contact, rowIndex) => {
		const { [valueInputName]: inputValue = '' } = contact

		const errorMsg = getErrorMessage(valueInputName, rowIndex)

		const inputProps = getInputValueProps(inputValue, rowIndex, errorMsg)

		return renderContactInput(inputProps, errorMsg)
	}

	const renderContactNameInput = (contact, rowIndex) => {
		const { name: inputValue = '' } = contact

		const errorMsg = getErrorMessage(CONTACT_INPUT_NAMES.CONTACT_NAME, rowIndex)

		const inputProps = getInputNameProps(inputValue, rowIndex, errorMsg)

		return renderContactInput(inputProps, errorMsg)
	}

	const renderContactDefaultInput = (contact, rowIndex) => {
		const { default: isDefault = false } = contact

		return (
			<div className={`${CLASSNAME}__default-contact`}>
				<Radio
					className={classnames({
						[`${CLASSNAME}__radio`]: true,
						[`${CLASSNAME}__radio--selected`]: isDefault,
						[`${CLASSNAME}__radio--disabled`]: disabled
					})}
					value={rowIndex}
					name={`${valueInputName}-is-default-${rowIndex}`}
					checked={!!isDefault || value.length === 1}
					disabled={disabled}
					label={defaultContact}
					onChange={() => {
						value.map((contact, ind) => Object.assign(contact, { default: ind === rowIndex }))

						onChange({
							id,
							value,
							section
						})
					}}
				/>
			</div>
		)
	}

	const renderAddButton = () => {
		return (
			<div className={`${CLASSNAME}__row ${CLASSNAME}__row--add`}>
				<div
					className={classnames({
						[`${CLASSNAME}__description`]: true,
						[`${CLASSNAME}__description--spacer`]: value.length
					})}
				>
					{value.length === 0 && label}
				</div>

				<div className={`${CLASSNAME}__value-box`}>
					<ActionElm
						onClick={() => {
							onChange({
								id,
								value: [
									...value,
									{
										name: '',
										[valueInputName]: '',
										default: !value.length
									}
								],
								section
							})
						}}
						type='button'
					>
						{addContact}
					</ActionElm>
				</div>
			</div>
		)
	}

	const removeContact = (rowIndex) => {
		value.splice(rowIndex, 1)
		const errorMessage = getReducedErrorMessage(rowIndex)

		onChange({
			id,
			value,
			errorMessage,
			section
		})
	}

	const renderRemoveButton = (rowIndex) => {
		return (
			// nedovolime uzivatelovi zmazat posledny kontakt
			value.length > 1 && (
				<SimpleTooltip
					description={localize('Contacts.remove')}
					ariaHidden={true}
					align={SimpleTooltip.ALIGN.RIGHT}
					renderContent={(className) => (
						<ActionElm
							onClick={() => removeContact(rowIndex)}
							type='button'
							className={`${className} ${CLASSNAME}__action-icon`}
						>
							<span className={`${className} ${CLASSNAME}__accessible-label`}>
								{`${localize('Contacts.remove')} ${value[rowIndex].name}`}
							</span>
							<Icon symbol={TRASH_FILLED_24} className={`${className} ${CLASSNAME}__icon`} />
						</ActionElm>
					)}
				/>
			)
		)
	}

	const renderContacts = () => {
		return (
			<>
				{value.map((contact, index) => {
					const { is_deleted } = contact

					// - pokud je kontakt oznacen jako smazany, tak jej nezobrazim
					if (is_deleted) {
						return null
					}

					return (
						<div key={index} className={`${CLASSNAME}__row`}>
							<div className={`${CLASSNAME}__description`}>
								{label}
								{required && <span className={`${CLASSNAME}__required`}>*</span>}
							</div>

							<div className={`${CLASSNAME}__inputs-row`}>
								<div className={`${CLASSNAME}__inputs-wrapper`}>
									{renderContactValueInput(contact, index)}
									<div className={`${CLASSNAME}__input-spacer`}></div>
									{renderContactNameInput(contact, index)}
								</div>

								{renderContactDefaultInput(contact, index)}

								{!disabled && (
									<div className={`${CLASSNAME}__actions`}>{renderRemoveButton(index)}</div>
								)}
							</div>
						</div>
					)
				})}
			</>
		)
	}

	const renderErrorMessage = (errorMessage = '') => {
		const props = {
			id: `wdg-${id}-err`,
			className: 'wdg__error',
			role: 'tooltip'
		}

		return <>{errorMessage && <div {...props}>{errorMessage}</div>}</>
	}

	return (
		<div className={`${CLASSNAME} ${className}`}>
			{renderContacts()}
			{!disabled && renderAddButton()}
		</div>
	)
}

ContactsWidget.propTypes = {
	className: PropTypes.string
}

export default ContactsWidget
