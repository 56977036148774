import React, { useContext } from 'react'
import Context from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import ROUTE_NAMES from 'app/base/RouteNames'
import ClientAdvertListUrlConvertor from '../ClientAdvertListUrlConvertor'
import ActionElm from 'app/component/actionElement/ActionElement'
import PropTypes from 'prop-types'

import './StatusTabsCS.json'
import './StatusTabsView.less'

const CLASSNAME = 'c-client-status-tabs'

const StatusTabsView = ({ filterStatus, className = '' }) => {
	const localize = useLocalize()
	const { $Router } = useContext(Context)

	const tabs = [
		{
			text: localize('StatusTabs.all'),
			status: ''
		},
		{
			text: localize('StatusTabs.inactive'),
			status: ClientAdvertListUrlConvertor.advertStatus.INACTIVE.APP
		},
		{
			text: localize('StatusTabs.ban'),
			status: ClientAdvertListUrlConvertor.advertStatus.BAN.APP
		}
	]

	return (
		<ul className={classnames(CLASSNAME, className)}>
			{tabs.map((tab, index) => {
				const { text, status } = tab
				const isActive = status === filterStatus

				const tagName = 'a'

				const params = {}
				if (status) {
					params[ClientAdvertListUrlConvertor.constants.URL_APP_PARAMS.ADVERT_STATUS] = status
				}

				const tagProps = {
					className: classnames({
						[`${CLASSNAME}__tab-link`]: true,
						[`${CLASSNAME}__tab-link--active`]: isActive
					}),
					href: $Router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST, params)
				}

				return (
					<li key={index} className={`${CLASSNAME}__tab`}>
						<ActionElm tagName={tagName} {...tagProps}>
							{text}
						</ActionElm>
					</li>
				)
			})}
		</ul>
	)
}

StatusTabsView.propTypes = {
	filterStatus: PropTypes.string,
	className: PropTypes.string
}

export default StatusTabsView
