import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import FilterConstants from 'app/model/filter/FilterConstants'
import FilterDropdownButton from './shared/FilterDropdownButton'
import DropdownLogic from 'app/component/filters/logic/modals/DropdownLogic'
import FilterDropdownWithOpener from './shared/FilterDropdownWithOpener'
import DropdownOpenerLogic from 'app/component/filters/logic/openers/DropdownOpenerLogic'
import ScopedFormLineEntity from 'app/component/filters/logic/ScopedFormLineEntity'
import RadioFilterLogic from 'app/component/filters/logic/RadioFilterLogic'
import RangeInputOpener from 'app/component/filters/forms/main/openers/rangeInputOpener/RangeInputOpener'
import AgeOpener from 'app/component/filters/forms/main/openers/ageOpener/AgeOpener'
import ItemAgeOpener from 'app/component/filters/forms/main/openers/itemAgeOpener/ItemAgeOpener'
import RadioInputForm from 'app/component/filters/forms/rangeInput/RangeInputForm'
import AgeForm from 'app/component/filters/forms/age/AgeForm'
import ItemAgeForm from 'app/component/filters/forms/itemAge/ItemAgeForm'
import FloatingDropdown from '../../../floatingDropdown/FloatingDropdown'

import './RadioFilterDropdownWithOpener.less'

export default class RadioFilterDropdownWithOpener extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			dropdownDefinition: PropTypes.object,
			context: PropTypes.string.isRequired,
			value: PropTypes.number, //TODO lepsi nazev?
			filterIndex: PropTypes.number,
			renderOpener: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderOpener = this._renderOpener.bind(this)
		this._renderDropdown = this._renderDropdown.bind(this)

		this._renderFilterForm = this._renderFilterForm.bind(this)
		this._renderButton = this._renderButton.bind(this)
	}

	render() {
		const { dropdownDefinition, context, value } = this.props

		return (
			<FilterDropdownWithOpener
				dropdownDefinition={dropdownDefinition}
				context={context}
				value={value}
				renderOpener={this._renderOpener}
				renderDropdown={this._renderDropdown}
			/>
		)
	}

	_renderOpener() {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			dropdownDefinition,
			context,
			filterIndex,
			renderOpener
		} = this.props
		const { id } = globalFormLineEntity

		const RadioOpener =
			id === FilterConstants.formLineIds.AGE
				? AgeOpener
				: id === FilterConstants.formLineIds.ITEM_AGE
				? ItemAgeOpener
				: RangeInputOpener

		return (
			<RadioFilterLogic
				formLineEntity={globalFormLineEntity}
				changeFilter={globalChangeFilter}
				context={context}
				isLastOptionCustomInput={id !== FilterConstants.formLineIds.ITEM_AGE}
			>
				{(filterLogicChildrenProps) => {
					const {
						form: { changeFilter }
					} = filterLogicChildrenProps

					return (
						<DropdownOpenerLogic
							formLineEntity={globalFormLineEntity}
							changeFilter={changeFilter}
							context={context}
						>
							{(dropdownOpenerLogicData) => {
								const { openDropdown, clearFilter } = dropdownOpenerLogicData

								return renderOpener ? (
									renderOpener(dropdownOpenerLogicData)
								) : (
									<RadioOpener
										formLineEntity={globalFormLineEntity}
										context={context}
										filterIndex={filterIndex}
										isOpened={!!dropdownDefinition}
										onOpen={openDropdown}
										onResetFilter={clearFilter}
									/>
								)
							}}
						</DropdownOpenerLogic>
					)
				}}
			</RadioFilterLogic>
		)
	}

	_renderDropdown() {
		const { formLineEntity: globalFormLineEntity, context } = this.props

		return (
			<ScopedFormLineEntity formLineEntity={globalFormLineEntity}>
				{(scopedFormLineEntityChildrenProps) => {
					const { localFormLineEntity, localChangeFilter } = scopedFormLineEntityChildrenProps

					return (
						<RadioFilterLogic
							formLineEntity={localFormLineEntity}
							changeFilter={localChangeFilter}
							context={context}
							isLastOptionCustomInput={
								localFormLineEntity.id !== FilterConstants.formLineIds.ITEM_AGE
							}
							scopedContext={true}
						>
							{(filterLogicChildrenProps) =>
								this._renderFilterDropdown(
									scopedFormLineEntityChildrenProps,
									filterLogicChildrenProps
								)
							}
						</RadioFilterLogic>
					)
				}}
			</ScopedFormLineEntity>
		)
	}

	_renderFilterDropdown(scopedFormLineEntityChildrenProps, filterLogicChildrenProps) {
		const { changeFilter: globalChangeFilter, dropdownDefinition, value } = this.props
		const { localFormLineEntity } = scopedFormLineEntityChildrenProps

		return (
			<DropdownLogic
				formLineEntity={localFormLineEntity}
				changeFilter={globalChangeFilter}
				dropdownDefinition={dropdownDefinition}
				value={value}
				filterLogicData={filterLogicChildrenProps}
			>
				{(filterDropdownLogicChildrenProps) => {
					const { closeDropdown } = filterDropdownLogicChildrenProps
					return (
						<FloatingDropdown onClose={closeDropdown}>
							{this._renderFilterForm(filterDropdownLogicChildrenProps, filterLogicChildrenProps)}
							{this._renderButton(filterDropdownLogicChildrenProps, filterLogicChildrenProps)}
						</FloatingDropdown>
					)
				}}
			</DropdownLogic>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderFilterForm(filterDropdownLogicChildrenProps, filterLogicChildrenProps) {
		const { closeDropdown } = filterDropdownLogicChildrenProps
		const {
			form: { changeFilter, validateForm },
			advertsCount: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity }
		} = filterLogicChildrenProps
		const { id } = formLineEntity

		const isFormValid = validateForm()

		const Form =
			id === FilterConstants.formLineIds.AGE
				? AgeForm
				: id === FilterConstants.formLineIds.ITEM_AGE
				? ItemAgeForm
				: RadioInputForm

		return (
			<Form
				formLineEntity={formLineEntity}
				isFormValid={isFormValid}
				onChange={changeFilter}
				onPresetClick={closeDropdown}
				filteredAdvertsCount={filteredAdvertsCount}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				isLastOptionCustomInput={id !== FilterConstants.formLineIds.ITEM_AGE}
				// nechceme v bublinach s pocty inzeratu vypisovat
				// text 'xxx nabidek', ale pouze 'xxx'
				isCountShownWithAddText={false}
			/>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderButton(filterDropdownLogicChildrenProps, filterLogicChildrenProps) {
		return (
			<FilterDropdownButton
				filterDropdownLogicData={filterDropdownLogicChildrenProps}
				filterFormLogicData={filterLogicChildrenProps}
			/>
		)
	}
}
