import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import AbstractComponent from 'app/base/AbstractComponent'
import Opener from 'app/component/filters/components/opener/OpenerView'
import GenericError from 'ima/error/GenericError'
import { DefaultProps } from '@inzeraty/helpers'
import { FILTER_CONTEXT } from '../../../FiltersExtension'
import { CHEVRON_UP_OUTLINE_24, CHEVRON_DOWN_OUTLINE_24 } from '@sznds/icons'

const ID_PREFIX = 'cf-opener-'
const FILTER_CLASSNAME = 'cf-filter'

const FILTER_INDEX_DEFAULT_VALUE = -1

export default class AbstractOpener extends AbstractComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			context: PropTypes.string,
			filterIndex: PropTypes.number, // poradi daneho filtru v ramci vsech aktualne zobrazenych filtru
			isOpened: PropTypes.bool,
			onOpen: PropTypes.func,
			onResetFilter: PropTypes.func,
			isPopup: PropTypes.bool,
			surface: PropTypes.number
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DefaultProps.OBJECT,
			filterIndex: FILTER_INDEX_DEFAULT_VALUE,
			isOpened: false,
			onOpen: DefaultProps.FUNCTION,
			onResetFilter: DefaultProps.FUNCTION,
			surface: 4
		}
	}

	render() {
		const { formLineEntity } = this.props

		return (
			<div
				className={FILTER_CLASSNAME}
				id={ID_PREFIX + formLineEntity.id}
				data-dot='filter'
				data-dot-data={`{"type": "${formLineEntity.id}"}`}
			>
				{this._renderOpener()}
			</div>
		)
	}

	_renderOpener() {
		const { context, isOpened, onOpen, onResetFilter, isPopup, surface, openerRef } = this.props

		const { text, hasValue } = this._getTextAndHasValue()

		const iconSymbol =
			context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL ||
			(context === FILTER_CONTEXT.MAIN_MENU && !isPopup)
				? isOpened
					? CHEVRON_UP_OUTLINE_24
					: CHEVRON_DOWN_OUTLINE_24
				: undefined

		return (
			<Opener
				text={text}
				hasValue={hasValue}
				iconSymbol={iconSymbol}
				onClick={onOpen}
				onResetClick={onResetFilter}
				surface={surface}
				openerRef={openerRef}
			/>
		)
	}

	_getTextAndHasValue() {
		throw new GenericError(
			`Je potřeba implementovat metodu _getTextAndHasValue openeru pro formLineEntity.id = '${this.props.formLineEntity.id}'. Ta bude vracet object: { text: <String> Text openeru , hasValue: <Boolean> Info zda je vybrána hodnota }`
		)
	}
}
