import AbstractNewAdvertAdminView from '../AbstractNewAdvertAdminView'
import React from 'react'
import ClientBaseController from 'app/base/ClientBaseController'
import BasicInfoForm from './components/BasicInfoForm'
import BasicInfoHeader from './components/header/BasicInfoHeader'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../AbstractNewAdvertStateKeys'
import BASIC_INFO_STATE_KEYS from './BasicInfoStateKeys'

import './BasicInfoView.less'
import './BasicInfoViewCS.json'

const CLASSNAME = 'p-uw-basic-info'

/**
 * Stranka pro vlozeni noveho inzeratu (krok s vlozenim zakladnych informacii).
 * @class BasicInfoView
 * @namespace app.page.userweb.newAdvert
 * @extends app.page.userweb.newAdvert.AbstractNewAdvertAdminView
 * @module app
 * @submodule app.page
 */
export default class BasicInfoView extends AbstractNewAdvertAdminView {
	_getLayoutProps(defaultLayoutProps) {
		return Object.assign({}, defaultLayoutProps, {
			className: CLASSNAME
		})
	}

	_renderContent() {
		const {
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntity,

			[ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise,

			[BASIC_INFO_STATE_KEYS.FORM_LINES_BY_SECTION]: formLineEntitiesBySections,
			[BASIC_INFO_STATE_KEYS.ON_CHANGE]: onChange,

			[BASIC_INFO_STATE_KEYS.IS_FORM_BEING_SUBMITTED]: isFormBeingSubmitted,
			[BASIC_INFO_STATE_KEYS.SUBMIT_FORM]: submitForm,

			[BASIC_INFO_STATE_KEYS.FORM_STATUS]: formStatus,
			[BASIC_INFO_STATE_KEYS.SET_FORM_STATUS]: setFormStatus,

			[BASIC_INFO_STATE_KEYS.SCROLL_PAGE_TO_TOP]: scrollPageToTop,

			[BASIC_INFO_STATE_KEYS.OPERATING_LEASE_DEFINITION_DATA]: operatingLeaseDefinitionData,
			[BASIC_INFO_STATE_KEYS.OPERATING_LEASE_VARIANTS]: operatingLeaseVariants,
			[BASIC_INFO_STATE_KEYS.FETCH_OPERATING_LEASES_VARIANTS]: fetchOperatingLeasesVariants,
			[BASIC_INFO_STATE_KEYS.ADD_NEW_OPERATING_LEASE_VARIANT]: addNewOperatingLeaseVariant,
			[BASIC_INFO_STATE_KEYS.EDIT_OPERATING_LEASE_VARIANT]: editOperatingLeaseVariant,
			[BASIC_INFO_STATE_KEYS.DELETE_OPERATING_LEASE_VARIANT]: deleteOperatingLeaseVariant
		} = this.props

		return (
			<div className={`${CLASSNAME}__content`}>
				<BasicInfoHeader advertEntity={advertEntity} />
				<BasicInfoForm
					advertEntity={advertEntity}
					isPremise={isPremise}
					formLineEntitiesBySections={formLineEntitiesBySections}
					onChange={onChange}
					isFormBeingSubmitted={isFormBeingSubmitted}
					submitForm={submitForm}
					formStatus={formStatus}
					setFormStatus={setFormStatus}
					scrollPageToTop={scrollPageToTop}
					operatingLeaseDefinitionData={operatingLeaseDefinitionData}
					operatingLeaseVariants={operatingLeaseVariants}
					fetchOperatingLeasesVariants={fetchOperatingLeasesVariants}
					addNewOperatingLeaseVariant={addNewOperatingLeaseVariant}
					editOperatingLeaseVariant={editOperatingLeaseVariant}
					deleteOperatingLeaseVariant={deleteOperatingLeaseVariant}
				/>
			</div>
		)
	}
}
