import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { Input } from '@sznds/react'

const InputNumber = ({ allowNegative = false, thousandSeparator = ' ', ...restProps }) => {
	const isAllowed = (values) => {
		const { floatValue } = values

		if (floatValue !== undefined) {
			return floatValue >= 0 && floatValue <= 999999999999
		} else {
			return true
		}
	}

	return (
		<NumberFormat
			customInput={Input}
			allowNegative={allowNegative}
			thousandSeparator={thousandSeparator}
			type='tel'
			tagName='input'
			isAllowed={isAllowed}
			{...restProps}
		/>
	)
}

InputNumber.propTypes = {
	allowNegative: PropTypes.bool,
	thousandSeparator: PropTypes.string
	// vice props v dokumentaci na
	// https://github.com/s-yadav/react-number-format a
	// https://github.com/seznam/SDS/tree/master/react/src/Input
}

export default InputNumber
