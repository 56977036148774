import { FORM_LINES_IDS as BASE_FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'

export const FORM_LINES_IDS = Object.assign({}, BASE_FORM_LINES_IDS, {
	USER_EMAIL: 'user_email',
	REPORT_REASON: 'report_reason',
	REPORT_MESSAGE: 'report_message'
})

export const FORM_LINES_NAMES = {
	USER_EMAIL: 'user_email',
	REPORT_REASON: 'report_reason',
	REPORT_MESSAGE: 'report_message'
}

export const REASONS = {
	SUSPICIOUS_ADVERT: 'reason_suspicious_advert',
	WRONG_CATEGORY: 'reason_wrong_category',
	FALSE_INFO: 'reason_false_info',
	BAD_PHOTOS: 'reason_bad_photos',
	OTHER: 'reason_other'
}
