import React, { useContext, useEffect, useState } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import PropTypes from 'prop-types'
import ImaContext from 'ima/page/context'
import Modal from 'app/component/modal/ModalView'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import select from 'ima-plugin-select'
import ReportExtension from 'app/component/report/ReportExtension'
import { Button } from '@sznds/react'
import classnames from 'classnames'
import { reportFormLineEntities, validateFormLines } from './ReportFormLines'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'
import { getErrorDictionary } from './ReportFormErrors'
import RadiosCompWidget, {
	RADIOS_WIDGET_ID
} from 'app/component/formLineWidgets/radios/RadiosCompWidget'
import EmailCompWidget, {
	EMAIL_WIDGET_ID
} from 'app/component/formLineWidgets/email/EmailCompWidget'
import TextareaCompWidget, {
	TEXTAREA_WIDGET_ID
} from 'app/component/formLineWidgets/textarea/TextareaCompWidget'
import { FORM_LINES_IDS, REASONS } from './ReportFormLineConstants'
import UserwebBaseController from 'app/base/UserwebBaseController'
import { UserPropTypes } from '@inzeraty/models'

import 'app/base/BaseCS.json'
import './Report.less'
import './ReportCS.json'

const { SZNDS_WIDGET_BY_ID, Form } = FormLines

const WIDGETS_BY_ID = Object.freeze(
	Object.assign(
		{
			[RADIOS_WIDGET_ID]: RadiosCompWidget,
			[EMAIL_WIDGET_ID]: EmailCompWidget,
			[TEXTAREA_WIDGET_ID]: TextareaCompWidget
		},
		SZNDS_WIDGET_BY_ID
	)
)

const CLASSNAME = 'c-report'

const Report = ({
	isOpen: isOpenComp = false,
	setIsOpenCallback = DEFAULT_PROPS.FUNCTION,
	onSubmit = DEFAULT_PROPS.FUNCTION,
	userSelf = DEFAULT_PROPS.OBJECT
}) => {
	const localize = useLocalize()
	const { $Dictionary } = useContext(ImaContext)
	const ERROR_DICTIONARY = getErrorDictionary(localize)

	const getFormLineEntities = () => {
		const { login } = userSelf || {}
		const formLineEntities = reportFormLineEntities($Dictionary)

		const { value: userEmail } =
			formLineEntities.find(({ id }) => id === FORM_LINES_IDS.USER_EMAIL) || {}
		if (!userEmail && login) {
			return FormLines.updateEntities(formLineEntities, [
				{
					id: FORM_LINES_IDS.USER_EMAIL,
					value: userEmail || login
				}
			])
		} else {
			return formLineEntities
		}
	}

	const [isOpen, setIsOpen] = useState(isOpenComp)
	const [isSending, setIsSending] = useState(false)
	const [formLineEntities, setFormLineEntities] = useState(isOpenComp ? getFormLineEntities() : [])
	const [serverErrorValues, setServerErrorValues] = useState([])

	useEffect(() => {
		if (isOpen) {
			setFormLineEntities(getFormLineEntities())
			setServerErrorValues([])
			setIsSending(false)
		}
		setIsOpenCallback(isOpen)
	}, [isOpen])

	useEffect(() => {
		setIsOpen(isOpenComp)
	}, [isOpenComp])

	useEffect(() => {
		setFormLineEntities(FormLines.updateEntities(formLineEntities, serverErrorValues))
	}, [serverErrorValues])

	useEffect(() => {
		setIsSending(false)
	}, [serverErrorValues])

	const onChange = ({ id, value, errorMessage }) => {
		const changes = [
			{
				id,
				value,
				errorMessage
			}
		]

		if (id === FORM_LINES_IDS.REPORT_REASON) {
			changes.push({
				id: FORM_LINES_IDS.REPORT_MESSAGE,
				required: value === REASONS.OTHER,
				errorMessage: ''
			})
		}

		setFormLineEntities(FormLines.updateEntities(formLineEntities, changes))
	}

	const onBlur = () => {}

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			formLineEntities,
			{
				onChange,
				onBlur
			},
			WIDGETS_BY_ID
		)
	}

	const renderSubmitButton = (props) => {
		return (
			<div className={`${CLASSNAME}__form-btns`}>
				<Button
					text={localize('Base.close')}
					onClick={() => {
						setIsOpen(false)
					}}
					className={classnames({
						[`${CLASSNAME}__form-btn`]: true,
						[`${CLASSNAME}__form-btn--close`]: true
					})}
				/>
				<Button
					{...props}
					disabled={isSending}
					loading={isSending}
					primary={true}
					text={localize('Report.btnContinue')}
					className={classnames({
						[`${CLASSNAME}__form-btn`]: true,
						[`${CLASSNAME}__form-btn--submit`]: true
					})}
				/>
			</div>
		)
	}

	const onSubmitForm = () => {
		const [isValide, invalideFormLineEntities] = validateFormLines(formLineEntities)

		if (isValide) {
			setIsSending(true)
			const formData = FormLines.getFormData(formLineEntities)
			onSubmit(formData, onSubmitSuccess, onSubmitError)
		} else {
			const updatedValues = invalideFormLineEntities.map((formLineEntitiy) => {
				const { id, value } = formLineEntitiy

				const errorMessage = value
					? ERROR_DICTIONARY[id][ERROR_CODES.NOT_VALID]
					: ERROR_DICTIONARY[id][ERROR_CODES.REQUIRED]

				return {
					id,
					errorMessage
				}
			})

			setFormLineEntities(FormLines.updateEntities(formLineEntities, updatedValues))
		}
	}

	const onSubmitSuccess = (result) => {
		setIsOpen(false)
	}

	const onSubmitError = (errors = []) => {
		setIsSending(false)

		if (errors.length) {
			const errorValues = []

			errors.forEach((error) => {
				const { error_code: errorCode, fields } = error

				const error_code =
					errorCode === ERROR_CODES.REQUIRED ? ERROR_CODES.REQUIRED : ERROR_CODES.NOT_VALID

				fields.forEach((field_id) => {
					const dictKey = `Report.${field_id}_${error_code}`

					let errorMessage = $Dictionary.has(dictKey)
						? localize(dictKey)
						: localize(`Report.value_${error_code}`)

					let id = field_id

					errorValues.push({
						id,
						errorMessage
					})
				})
			})

			setServerErrorValues(errorValues)
		}
	}

	const renderForm = () => {
		return (
			<Form
				formLineEntities={formLineEntities}
				isSending={isSending}
				renderWidgets={renderWidgets}
				onSubmit={onSubmitForm}
				renderSubmitButton={renderSubmitButton}
			/>
		)
	}

	return (
		<Modal
			isOpen={isOpen}
			setIsOpenCallback={(newIsOpen) => {
				setIsOpen(newIsOpen)
			}}
			title={localize('Report.title')}
			renderOpener={DEFAULT_PROPS.FUNCTION}
			className={CLASSNAME}
			modalPosition={Modal.POSITION.BOTTOM}
			modalStyle={Modal.STYLE.FULLSIZE}
		>
			{renderForm()}
		</Modal>
	)
}

Report.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpenCallback: PropTypes.func,
	onSubmit: PropTypes.func,
	userSelf: PropTypes.shape(UserPropTypes)
}

const selectors = (state) => {
	return {
		onSubmit: state[ReportExtension.STATE_IDS.ON_SUBMIT],
		userSelf: state[UserwebBaseController.STATE_KEYS.USER_SELF]
	}
}

export default select(selectors)(Report)
