export default class BrandsCatalogFactory {
	transformData(data) {
		return this._mapBrands(data)
	}

	_mapBrands(brands = []) {
		return brands.map((brand) => {
			return {
				id: brand.id,
				name: brand.name,
				seoName: brand.seo_name,
				logoUrl: brand.logo_url
			}
		})
	}
}
