
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AddVINForm'] = {
  vehicleCategoryLabel: function(d) { return "Typ vozidla"; },
  vehicleCategoryRequired: function(d) { return "Typ vozidla je povinný"; },
  vehicleCategoryNotValid: function(d) { return "Neplatný typ vozidla"; },
  vehicleConditionLabel: function(d) { return "Stav vozidla"; },
  vehicleConditionRequired: function(d) { return "Stav vozidla je povinný"; },
  vehicleConditionNotValid: function(d) { return "Neplatný stav vozidla"; },
  vinLabel: function(d) { return "VIN kód vozidla"; },
  vinRequired: function(d) { return "VIN kód je povinný pro daný typ a stav vozidla"; },
  vinNotValid: function(d) { return "Neplatný formát VIN"; },
  vinDuplication: function(d) { return "Inzerát se zadaným VIN kódem již existuje"; },
  vinPlaceholder: function(d) { return "Vložte VIN kód"; },
  vinExplanation: function(d) { return "Co je VIN kód?"; },
  vinShortDescription: function(d) { return "Po zadání VIN kódu načteme základní údaje o vozu z katalogu Cebia"; },
  submit: function(d) { return "Načíst data z VIN"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	