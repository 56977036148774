import React, { useContext } from 'react'
import { FavoriteIcon, StatusMessage } from '@inzeraty/components'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import Context from 'ima/page/context'
import STATE_KEYS from 'app/component/favorite/FavoriteStateKeys'
import PropTypes from 'prop-types'

import '@inzeraty/components/dist/components/favoriteIcon/FavoriteIconCS.json'
import './FavoriteCS.json'

const Favorite = ({ id, className, favoriteIds, type }) => {
	const localize = useLocalize()
	const fire = useFire()
	const { $Dispatcher } = useContext(Context)
	const isFavorite = favoriteIds && favoriteIds.has(id)

	const onError = (message) => {
		$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			title: localize(`Favorite.${message}`),
			type: StatusMessage.TYPE.ERROR
		})
	}

	return (
		<FavoriteIcon
			id={id}
			isFavorite={isFavorite}
			onClick={(onSuccess) => {
				fire('favoriteClick', { id, isFavorite, onSuccess, onError })
			}}
			className={className}
			dictionary={{
				addToFavorites: localize('FavoriteIcon.addToFavorites'),
				removeFromFavorites: localize('FavoriteIcon.removeFromFavorites'),
				successfullyAdded: localize('FavoriteIcon.successfullyAdded'),
				successfullyRemoved: localize('FavoriteIcon.successfullyRemoved')
			}}
			type={type}
		/>
	)
}

const selector = (state) => ({
	favoriteIds: state[STATE_KEYS.FAVORITE_IDS]
})

Favorite.TYPE = FavoriteIcon.TYPE

Favorite.propTypes = {
	id: PropTypes.number,
	className: PropTypes.string,
	favoriteIds: PropTypes.instanceOf(Set),
	type: PropTypes.string
}

export default select(selector)(Favorite)
