import React, { useContext } from 'react'
import { HISTORY, EDIT_FILLED, CLOSE_OUTLINE, CHECKER_NORMAL } from '@sznds/icons'
import Context from 'ima/page/context'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import { useFire } from 'app/base/componentHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import ActionButton from 'app/component/actionButton/ActionButton'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import IAPremiseListController from './IAPremiseListController'
import PremiseService from 'app/model/premise/PremiseService'
import LocalityHelper from 'app/component/locality/LocalityHelper'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import PremiseEntity from 'app/model/premise/PremiseEntity'
import EntityForm from 'app/component/entityForm/EntityForm'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import AddNewButton from '../components/addNewButton/AddNewButton'
import EntityFormPopup from '../components/entityFormPopup/EntityFormPopup'
import { filterBy, filterByValue } from 'app/component/entitiesFilter/EntitiesFilter'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import ExportButton from 'app/page/internalAdmin/components/exportButton/ExportButton'

const { PREMISE_ID, PREMISE_EDIT_ID } = InternalAdminUrlConvertor.constants
const { ACTIONS, NEW_PREMISE_FORM_WIZARD } = IAPremiseListController

export default function IAPremiseListView({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading,
	isNewPremisePopupOpened,
	newPremiseStepShown = NEW_PREMISE_FORM_WIZARD.STEP1,
	formLineEntitiesNewPremiseStep1 = DEFAULT_PROPS.ARRAY,
	entityFormErrorNewPremiseStep1,
	isWaitingNewPremiseStep1,
	formLineEntitiesNewPremiseStep2 = DEFAULT_PROPS.ARRAY,
	entityFormErrorNewPremiseStep2,
	isWaitingNewPremiseStep2,
	validateNewPremiseStep2
}) {
	const { $Router, InternalAdminHelper } = useContext(Context)
	const fire = useFire()
	const { rights = [], roleNameId = '' } = useContext(AdminEntityContext) || {}

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		let actions = []

		if (rights.includes(ADMIN_RIGHTS.PREMISE_EDIT)) {
			actions = [
				({ id }, actionButtonProps = {}) => (
					<ActionButton
						icon={EDIT_FILLED}
						key='editovat'
						title='Editovat'
						href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_EDIT, {
							[PREMISE_EDIT_ID]: id
						})}
						{...actionButtonProps}
					/>
				),
				({ id: premiseId }, actionButtonProps = {}) => (
					<ActionButton
						icon={HISTORY}
						key='historie'
						title='Historie změn'
						href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_HISTORY, { premiseId })}
						{...actionButtonProps}
					/>
				),
				(entity, actionButtonProps = {}) => {
					const { enabled } = entity
					const { serviceEnabled } = PremiseService.getPremiseDataByService(entity)

					if (enabled && serviceEnabled) {
						return (
							<ActionButton
								icon={CLOSE_OUTLINE}
								key='deaktivovat'
								title='Zakázat firmu'
								onClick={() => action(ACTIONS.DEACTIVATE, [entity])}
								{...actionButtonProps}
							/>
						)
					} else if (!serviceEnabled && enabled) {
						// Aktivovat firmu můžeme jen když je na firmách aktivní a na sautu zakázaná.
						return (
							<ActionButton
								icon={CHECKER_NORMAL}
								key='aktivovat'
								title='Povolit firmu'
								onClick={() => action(ACTIONS.ACTIVATE, [entity])}
								{...actionButtonProps}
							/>
						)
					}
				}
			]
		}

		return actions
	}

	return (
		<Layout title='Výpis firem'>
			<AddNewButton
				text='Přidat firmu'
				onClick={() =>
					fire('action', {
						action: ACTIONS.OPEN_NEW_PREMISE_POPUP
					})
				}
			/>

			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<ExportButton
				onClick={() => {
					fire('exportPremises')
				}}
				buttonText='Exportovat firmy'
			/>

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='id' sortParam='id' alignRight={true}>
						ASK&nbsp;ID
					</TableHeadSorter>,
					<TableHeadSorter
						key='external_id'
						sortParam='external_id'
						isDefaultAscending={true}
						alignRight={true}
					>
						Sauto&nbsp;ID
					</TableHeadSorter>,
					<TableHeadSorter key='name' sortParam='name'>
						Název
					</TableHeadSorter>,
					<TableHead key='address'>Adresa</TableHead>,
					<TableHead key='ic' alignRight={true}>
						IČ
					</TableHead>,
					<TableHead key='adverts_count' alignRight={true}>
						Počet inzerátů
					</TableHead>,
					<TableHead key='report_count' alignRight={true}>
						Nahlášení
					</TableHead>,
					<TableHeadSorter key='enabled' sortParam='enabled'>
						Stav
					</TableHeadSorter>
				]}
				rowCells={[
					({ id }) => [id, { isNumber: true }],
					(entity) => [
						PremiseService.getPremiseDataByService(entity).externalId,
						{ isNumber: true }
					],
					(entity) => PremiseService.getPremiseDataForPublic(entity).name,
					(entity) =>
						LocalityHelper.localitySentence(
							PremiseService.getPremiseDataForPublic(entity).locality
						),
					({ subject = {} }) => [subject.ico, { isNumber: true }],
					({ id, allAdvertsCount }) => [
						<a
							key={id}
							href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
								[filterBy]: PREMISE_ID,
								[filterByValue]: id
							})}
						>
							{allAdvertsCount}
						</a>,
						{ isNumber: true }
					],
					({ id, advertReportsCount }) => {
						const isLink = !InternalAdminHelper.getIsRouteForbidden(
							ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST_REPORTED,
							roleNameId
						)
						const count = isLink ? (
							<a
								key={id}
								href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST_REPORTED, {
									[filterBy]: PREMISE_ID,
									[filterByValue]: id
								})}
							>
								{advertReportsCount}
							</a>
						) : (
							advertReportsCount
						)
						return [count, { isNumber: true }]
					},
					(entity) => {
						const { enabled } = entity
						const { serviceEnabled } = PremiseService.getPremiseDataByService(entity)

						if (enabled && serviceEnabled) {
							return 'Aktivní'
						} else if (!enabled) {
							return 'Zakázaný (na firmy.cz)'
						} else if (!serviceEnabled) {
							return 'Zakázaný (na sauto.cz)'
						} else {
							return 'Zakázaný'
						}
					}
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>

			{isNewPremisePopupOpened && (
				<EntityFormPopup
					title='Přidat firmu'
					onClose={() =>
						fire('action', {
							action: ACTIONS.CLOSE_NEW_PREMISE_POPUP
						})
					}
				>
					{newPremiseStepShown === NEW_PREMISE_FORM_WIZARD.STEP1 ? (
						<EntityForm
							variant={VARIANT.POPUP_FORM}
							formLineEntities={formLineEntitiesNewPremiseStep1}
							entityFormError={entityFormErrorNewPremiseStep1}
							isWaiting={isWaitingNewPremiseStep1}
							submitButtonText='Načíst data o firmě'
							onChange={(data) => fire('newPremiseStep1Change', data)}
							onSubmit={() => fire('newPremiseStep1Submit')}
						/>
					) : (
						<EntityForm
							variant={VARIANT.POPUP_FORM}
							formLineEntities={formLineEntitiesNewPremiseStep2}
							entityFormError={entityFormErrorNewPremiseStep2}
							isWaiting={isWaitingNewPremiseStep2}
							submitButtonText='Dokončit přidání firmy'
							isSubmitButtonEnabled={validateNewPremiseStep2()}
							onChange={(data) => fire('newPremiseStep2Change', data)}
							onSubmit={() => fire('newPremiseStep2Submit')}
						/>
					)}
				</EntityFormPopup>
			)}
		</Layout>
	)
}

IAPremiseListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(PremiseEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool,
	isNewPremisePopupOpened: PropTypes.bool,
	newPremiseStepShown: PropTypes.string,
	formLineEntitiesNewPremiseStep1: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormErrorNewPremiseStep1: PropTypes.object,
	isWaitingNewPremiseStep1: PropTypes.bool,
	formLineEntitiesNewPremiseStep2: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormErrorNewPremiseStep2: PropTypes.object,
	isWaitingNewPremiseStep2: PropTypes.bool,
	validateNewPremiseStep2: PropTypes.func
}
