import ClientRouter from 'ima/router/ClientRouter'
import {
	isClientAdminRoute,
	isInternalAdminRoute,
	isRegressionTestRoute,
	isDetailRoute
} from './RouteNames'

export default class CustomClientRouter extends ClientRouter {
	init(...args) {
		super.init(...args)

		this.blockClientRoute = this._window.getWindow()?.CWL?.consent === false
		this._window.getWindow().addEventListener('cwl:consent', ({ detail }) => {
			this.blockClientRoute = !detail.consent
		})
	}

	async route(newPath, options, action, locals) {
		const isCurrentPathInternalOrClientAdmin = this._isInternalOrClientAdminRoute(this.getPath())
		const isNewPathInternalOrClientAdmin = this._isInternalOrClientAdminRoute(newPath)
		const isNewPathDetail = this._isNewPathDetailRoute(newPath)

		// "route" metoda je volana i pri refreshi, "action.url" je pak prazdne. Pri skutecne
		// zmene URL "action.url" obsahuje URL, kam se chceme presmerovat.
		// Volani metody "_setCWLVisibility" by bylo citelnejsi v "init" metode, bohuzel tam
		// byl problem se zjistenim, na ktere route prave jsme (funkce "_getRouteByPath"
		// z IMA vracela null).
		this._setCWLVisibility(!isCurrentPathInternalOrClientAdmin)

		if (this.blockClientRoute && action?.url && isNewPathDetail) {
			if (isCurrentPathInternalOrClientAdmin || isNewPathInternalOrClientAdmin) {
				this._setCWLVisibility(!isNewPathInternalOrClientAdmin)
				return super.route(newPath, options, action, locals)
			}

			this._window
				.getWindow()
				.dispatchEvent(new CustomEvent('cwl:open-dialog', { detail: { url: action.url } }))

			return
		}

		return super.route(newPath, options, action, locals)
	}

	_isInternalOrClientAdminRoute(path) {
		try {
			const route = this._getRouteByPath(path)
			return isInternalAdminRoute(route.getName()) || isClientAdminRoute(route.getName())
		} catch (e) {
			return false
		}
	}

	_isNewPathDetailRoute(path) {
		try {
			const route = this._getRouteByPath(path)
			return isDetailRoute(route.getName())
		} catch (e) {
			return false
		}
	}

	_setCWLVisibility(visible) {
		try {
			const cwlElement = document.querySelector('szn-cwl')

			if (cwlElement && cwlElement.style) {
				const currentRoute = this._getRouteByPath(this.getPath())

				cwlElement.style.visibility =
					visible && !isRegressionTestRoute(currentRoute.getName()) ? 'visible' : 'hidden'
			}
		} catch (e) {
			// na chybu nebudeme nijak reagovat
		}
	}

	// kvuli problemum na IE11 s obrazkovymi linky. Z nejakeho duvodu img mel
	// automaticky href na src, takze webovka se snazila prejit na neznamou
	// routu.
	_getAnchorElement(target) {
		let self = this

		while (target && !hasReachedAnchor(target)) {
			target = target.parentNode
		}

		function hasReachedAnchor(nodeElement) {
			return (
				nodeElement.parentNode &&
				nodeElement !== self._window.getBody() &&
				nodeElement.href !== undefined &&
				nodeElement.href !== null &&
				// doplnena cast kodu, zbytek je zkopirovany z IMA
				nodeElement.tagName &&
				nodeElement.tagName.toLowerCase() === 'a'
			)
		}

		return target
	}
}
