import AbstractExtension from 'ima/extension/AbstractExtension'
import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import getRouteName from 'app/helpers/routeName/getRouteName'

export default class PopupDemandFormExtension extends AbstractExtension {
	constructor(analyticsDataSender, advertService, utils) {
		super()

		this._analyticsDataSender = analyticsDataSender
		this._advertService = advertService
		this._router = utils.$Router
	}

	load() {
		return {}
	}

	onDemandSubmit({ advertEntity, formData, onSuccessCallback, onErrorCallback }) {
		const routeName = getRouteName(this._router) || ''

		this._advertService
			.contactSeller(advertEntity.id, formData)
			.then(() => {
				this._analyticsDataSender.hitForDemandForm(advertEntity, routeName)
				onSuccessCallback()
			})
			.catch((error) => {
				const httpStatus = HttpErrorHelper.getHttpStatus(error)
				let errors = []

				if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
					const { body = {} } = HttpErrorHelper.getParams(error)
					errors = body.errors
				}

				onErrorCallback(errors)
			})
	}
}
