import React, { memo } from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { Radio } from '@sznds/react'

import './WidgetRadio.less'

const CLASSNAME = 'c-radio-widget'

const WidgetRadio = memo(({ className, formLineEntity = {}, onChange = () => {} }) => {
	const { id: entityId, label: entityLabel, value: entityValue, options = [] } = formLineEntity

	return (
		<div className={`${CLASSNAME}__input-wrapper ${className}`}>
			{options && options.length > 0 && <div className={`${CLASSNAME}__label`}>{entityLabel}</div>}
			{options.map((option) => {
				const { id, label, value } = option

				return (
					<Radio
						key={id}
						value={value}
						checked={entityValue === value}
						label={label}
						className={`${CLASSNAME}__input`}
						onChange={(event) => {
							event.stopPropagation()
							onChange({
								id: entityId,
								value,
								errorMessage: ''
							})
						}}
					/>
				)
			})}
		</div>
	)
})

WidgetRadio.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	onChange: PropTypes.func,
	className: PropTypes.string
}

WidgetRadio.displayName = 'WidgetRadio'

export default WidgetRadio
