import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import * as FormLines from '@inzeraty/form-lines'
import { HorizontalScrollingMenu, Tag } from '@inzeraty/components'
import FilterConstants from 'app/model/filter/FilterConstants'
import RangeTag from '../../tags/rangeTag/RangeTag'
import ListTag from '../../tags/listTag/ListTag'
import BrandTag from '../../tags/brandTag/BrandTag'
import ModelTag from '../../tags/modelTag/ModelTag'
import RegionTag from '../../tags/regionTag/RegionTag'
import DistrictTag from '../../tags/districtTag/DistrictTag'
import ItemAgeTag from '../../tags/itemAgeTag/ItemAgeTag'
import MultiOptionFilterPopupWithOpener from '../../factories/popups/MultiOptionFilterPopupWithOpener'
import MultiOptionFilterDropdownWithOpener from '../../factories/dropdowns/MultiOptionFilterDropdownWithOpener'
import RadioFilterPopupWithOpener from '../../factories/popups/RadioFilterPopupWithOpener'
import RadioFilterDropdownWithOpener from '../../factories/dropdowns/RadioFilterDropdownWithOpener'
import BrandModelFilterPopupWithOpener from '../../factories/popups/BrandModelFilterPopupWithOpener'
import BrandModelFilterDropdownWithOpener from '../../factories/dropdowns/BrandModelFilterDropdownWithOpener'
import RegionDistrictFilterPopupWithOpener from '../../factories/popups/RegionDistrictFilterPopupWithOpener'
import RegionDistrictFilterDropdownWithOpener from '../../factories/dropdowns/RegionDistrictFilterDropdownWithOpener'
import select from 'ima-plugin-select'
import FiltersExtension, { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import TagLogic from '../../logic/TagLogic'
import { SEARCH_TYPE_SESSION } from 'app/base/StorageIds'

import './TagsList.less'
import './TagsListCS.json'

const CLASSNAME = 'cf-tags-list'

class TagsList extends AbstractComponent {
	static get propTypes() {
		return {
			formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
			selectedSlideIndex: PropTypes.number,
			onSelectedSlideIndexChange: PropTypes.func,
			renderChildren: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			formLineEntities: DEFAULT_PROPS.ARRAY,
			renderChildren: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderPreviousButton = this._renderPreviousButton.bind(this)
		this._renderNextButton = this._renderNextButton.bind(this)
		this._getTagValues = this._getTagValues.bind(this)
		this._redirectOnChange = this._redirectOnChange.bind(this)
		this._utils = context.$Utils
	}

	render() {
		const {
			formLineEntities,
			renderChildren,
			selectedSlideIndex,
			onSelectedSlideIndexChange
		} = this.props

		const renderTagWrapper = (renderedTag, index) => {
			if (renderedTag) {
				const dataDotProps = { 'data-dot': 'filter', 'data-dot-data': `{"order": "${index + 1}"}` }

				return (
					<li key={renderedTag.props.text} className={`${CLASSNAME}__item`} {...dataDotProps}>
						{renderedTag}
					</li>
				)
			} else {
				return null
			}
		}

		const renderedTag = this._renderTags()

		const renderCarousel = () => (
			<HorizontalScrollingMenu
				className={`${CLASSNAME}__list-carousel`}
				selectedSlideIndex={selectedSlideIndex}
				onSelectedSlideIndexChange={onSelectedSlideIndexChange}
				spacingBetweenSlides={this._calcSpacingBetweenSlides}
				alignLastSlideToRight={true}
				renderPreviousButton={this._renderPreviousButton}
				renderNextButton={this._renderNextButton}
				renderWrappingContainer={this._renderWrappingContainer}
				dataDot='scroll-component'
			>
				{React.Children.map(renderChildren(renderedTag), renderTagWrapper)}
			</HorizontalScrollingMenu>
		)

		const renderPlaceholders = () => {
			return (
				<div className={`${CLASSNAME}__list-carousel`}>
					<div className={`${CLASSNAME}__list-container`}>
						{[0, 1, 2].map((key) => (
							<div key={key} className={`${CLASSNAME}__placeholder`} />
						))}
					</div>
				</div>
			)
		}

		return formLineEntities.length
			? renderedTag.length
				? renderCarousel()
				: null
			: renderPlaceholders()
	}

	_redirectOnChange(id, value, callback = () => {}) {
		const { changeFilter, formLineEntities } = this.props
		const formLineEntity = formLineEntities.find((formLineEntity) => formLineEntity.id === id)

		const { AnalyticsDataSender, $Router } = this._utils

		const { route } = $Router.getCurrentRouteInfo()
		const routeName = route.getName()

		AnalyticsDataSender.hitFilterChange(formLineEntity, value, 'Tags', routeName)

		changeFilter(id, value, callback)
	}

	_renderTags() {
		const { formLineEntities } = this.props

		return [...formLineEntities]
			.reverse()
			.filter((formLineEntity) => this._tagHasValue(formLineEntity))
			.map((formLineEntity) => {
				const { id } = formLineEntity

				if (
					id === FilterConstants.formLineIds.AGE ||
					id === FilterConstants.formLineIds.PRICE ||
					id === FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT ||
					id === FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE ||
					id === FilterConstants.formLineIds.OPERATING_LEASE_PERIOD ||
					id === FilterConstants.formLineIds.TACHOMETER ||
					id === FilterConstants.formLineIds.PERFORMANCE ||
					id === FilterConstants.formLineIds.DISPLACEMENT ||
					id === FilterConstants.formLineIds.FUEL_CONSUMPTION ||
					id === FilterConstants.formLineIds.AIRBAGS ||
					id === FilterConstants.formLineIds.CAPACITY ||
					id === FilterConstants.formLineIds.DOORS ||
					id === FilterConstants.formLineIds.WEIGHT ||
					id === FilterConstants.formLineIds.BEDS ||
					id === FilterConstants.formLineIds.MACHINE_HOURS
				) {
					return this._renderRangeTag(formLineEntity)
				} else if (
					id === FilterConstants.formLineIds.CONDITION ||
					id === FilterConstants.formLineIds.OPERATING_LEASE_SERVICES ||
					id === FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR ||
					id === FilterConstants.formLineIds.FUEL ||
					id === FilterConstants.formLineIds.VEHICLE_BODY ||
					id === FilterConstants.formLineIds.GEARBOX ||
					id === FilterConstants.formLineIds.COUNTRY_OF_ORIGIN ||
					id === FilterConstants.formLineIds.VENDOR ||
					id === FilterConstants.formLineIds.AIR_CONDITIONING ||
					id === FilterConstants.formLineIds.CERTIFIED_PROGRAM ||
					id === FilterConstants.formLineIds.COLOR ||
					id === FilterConstants.formLineIds.BUS_SEATS ||
					id === FilterConstants.formLineIds.PRICE_OPTION ||
					id === FilterConstants.formLineIds.HISTORY_INFO ||
					id === FilterConstants.formLineIds.EQUIPMENT ||
					id === FilterConstants.formLineIds.DRIVE ||
					id === FilterConstants.formLineIds.OTHER_SPECIFICATION
				) {
					return this._renderListTags(formLineEntity)
				} else if (id === FilterConstants.formLineIds.BRAND_MODEL) {
					return this._renderBrandModelTags(formLineEntity)
				} else if (id === FilterConstants.formLineIds.REGION_DISTRICT) {
					return this._renderRegionDistrictTags(formLineEntity)
				} else if (id === FilterConstants.formLineIds.ITEM_AGE) {
					return this._renderItemAgeTag(formLineEntity)
				} else if (id === FilterConstants.formLineIds.PHRASE) {
					return this._renderPhraseTag(formLineEntity)
				}

				return null
			})
			.filter((tag) => tag)
	}

	_tagHasValue(formLineEntity) {
		const { value } = formLineEntity

		let hasValue = false
		if (value instanceof Map) {
			hasValue = !!value.size
		} else if (value instanceof Object) {
			hasValue = !!Object.keys(value).length
		} else {
			hasValue = !!value
		}

		return hasValue
	}

	_getTagValues(id) {
		const { formLineEntities } = this.props
		const { value } = formLineEntities.find((formLineEntity) => formLineEntity.id === id)

		return value
	}

	_renderPhraseTag(formLineEntity = {}) {
		const { value } = formLineEntity

		return (
			<TagLogic
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				context={FILTER_CONTEXT.TAGS}
			>
				{({ deleteTag }) => (
					<Tag
						size='small'
						onClick={() => {
							deleteTag('')
						}}
						data-dot='delete'
						data-dot-data={`{"value": "${value}"}`}
					>
						{value}
					</Tag>
				)}
			</TagLogic>
		)
	}

	_renderRangeTag(formLineEntity = {}) {
		const {
			id,
			value: { valueTo, valueFrom } = {},
			extra: { unit, emptyValue } = {}
		} = formLineEntity
		const { openedFilterPopups, openedFilterDropdown } = this.props

		const popupDefinition = openedFilterPopups.find((popup) => popup.filterId === id)
		const dropdownDefinition =
			openedFilterDropdown.filterId === id ? openedFilterDropdown : undefined

		const renderPopupOpener = () => (
			<RadioFilterPopupWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				popupDefinition={popupDefinition}
				context={FILTER_CONTEXT.TAGS}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openPopup, deleteTag }) => (
							<RangeTag
								id={id}
								valueTo={valueTo}
								valueFrom={valueFrom}
								unit={unit}
								emptyValue={emptyValue}
								onOpen={openPopup}
								deleteTag={deleteTag}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		const renderDropdownOpener = () => (
			<RadioFilterDropdownWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				dropdownDefinition={dropdownDefinition}
				context={FILTER_CONTEXT.TAGS}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openDropdown, deleteTag }) => (
							<RangeTag
								id={id}
								valueTo={valueTo}
								valueFrom={valueFrom}
								unit={unit}
								emptyValue={emptyValue}
								onOpen={openDropdown}
								deleteTag={deleteTag}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		if (valueFrom !== undefined || valueTo !== undefined) {
			return (
				<>
					<div className={`${CLASSNAME}__popup-opener`}>{renderPopupOpener()}</div>
					<div className={`${CLASSNAME}__dropdown-opener`}>{renderDropdownOpener()}</div>
				</>
			)
		}
	}

	_renderListTags(formLineEntity = {}) {
		const { id, value = new Map() } = formLineEntity
		const { openedFilterPopups, openedFilterDropdown } = this.props

		const popupDefinition = openedFilterPopups.find((popup) => popup.filterId === id)
		const dropdownDefinition =
			openedFilterDropdown.filterId === id ? openedFilterDropdown : undefined

		const handleTagDeleteClick = (event, deleteTagCallback) => {
			this._utils.$SessionStorage.set(SEARCH_TYPE_SESSION, {
				searchType: 'filters',
				query: undefined,
				lastUrlRouteParams: this._utils.$Router.getCurrentRouteInfo().params
			})
			deleteTagCallback?.(event)
		}

		const renderPopupOpener = (value, name) => (
			<MultiOptionFilterPopupWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				popupDefinition={popupDefinition}
				context={FILTER_CONTEXT.TAGS}
				value={value}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openPopup, deleteTag }) => (
							<ListTag
								id={id}
								getValues={this._getTagValues}
								value={value}
								text={name}
								onOpen={openPopup}
								deleteTag={(event) => handleTagDeleteClick(event, deleteTag)}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		const renderDropdownOpener = (value, name) => (
			<MultiOptionFilterDropdownWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				dropdownDefinition={dropdownDefinition}
				context={FILTER_CONTEXT.TAGS}
				value={value}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openDropdown, deleteTag }) => (
							<ListTag
								id={id}
								getValues={this._getTagValues}
								value={value}
								text={name}
								onOpen={openDropdown}
								deleteTag={(event) => handleTagDeleteClick(event, deleteTag)}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		return Array.from(value)
			.reverse()
			.map(([value, name]) => {
				return (
					<>
						<div className={`${CLASSNAME}__popup-opener`}>{renderPopupOpener(value, name)}</div>
						<div className={`${CLASSNAME}__dropdown-opener`}>
							{renderDropdownOpener(value, name)}
						</div>
					</>
				)
			})
	}

	_renderRegionDistrictTags(formLineEntity = {}) {
		const { id } = formLineEntity
		const { openedFilterPopups, openedFilterDropdown } = this.props

		const popupDefinition = openedFilterPopups.find((popup) => popup.filterId === id)

		const renderPopupOpenerForRegion = (regionName, regionValue) => (
			<RegionDistrictFilterPopupWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				popupDefinition={popupDefinition}
				context={FILTER_CONTEXT.TAGS}
				value={regionValue}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openPopup, deleteTag }) => (
							<RegionTag
								getValues={this._getTagValues}
								id={id}
								value={regionValue}
								text={regionName}
								onOpen={openPopup}
								deleteTag={deleteTag}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		const renderPopupOpenerForDistrict = (regionValue, districtName, districtValue) => (
			<TagLogic
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				context={FILTER_CONTEXT.TAGS}
			>
				{({ openPopup, deleteTag }) => (
					<DistrictTag
						getValues={this._getTagValues}
						id={id}
						regionValue={regionValue}
						districtValue={districtValue}
						text={districtName}
						onOpen={openPopup}
						deleteTag={deleteTag}
					/>
				)}
			</TagLogic>
		)

		const dropdownDefinitionForRegion =
			openedFilterDropdown.filterId === id && !openedFilterDropdown.openedByDependentValue
				? openedFilterDropdown
				: undefined

		const renderDropdownOpenerForRegion = (regionName, regionValue) => (
			<RegionDistrictFilterDropdownWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				dropdownDefinition={dropdownDefinitionForRegion}
				context={FILTER_CONTEXT.TAGS}
				value={regionValue}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openDropdown, deleteTag }) => (
							<RegionTag
								getValues={this._getTagValues}
								id={id}
								value={regionValue}
								text={regionName}
								onOpen={openDropdown}
								deleteTag={deleteTag}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		const renderDropdownOpenerForDistrict = (regionValue, districtName, districtValue) => {
			const dropdownDefinitionForDistrict =
				openedFilterDropdown.filterId === id &&
				openedFilterDropdown.openedByValue === regionValue &&
				openedFilterDropdown.openedByDependentValue === districtValue
					? openedFilterDropdown
					: undefined

			return (
				<RegionDistrictFilterDropdownWithOpener
					formLineEntity={formLineEntity}
					changeFilter={this._redirectOnChange}
					dropdownDefinition={dropdownDefinitionForDistrict}
					context={FILTER_CONTEXT.TAGS}
					value={regionValue}
					renderOpener={() => (
						<TagLogic
							formLineEntity={formLineEntity}
							changeFilter={this._redirectOnChange}
							context={FILTER_CONTEXT.TAGS}
						>
							{({ openDropdown, deleteTag }) => (
								<DistrictTag
									getValues={this._getTagValues}
									id={id}
									regionValue={regionValue}
									districtValue={districtValue}
									text={districtName}
									onOpen={openDropdown}
									deleteTag={deleteTag}
								/>
							)}
						</TagLogic>
					)}
				/>
			)
		}

		return this._renderDependentTags(
			formLineEntity,
			(regionName, regionValue, districtsValues) =>
				!districtsValues.length && (
					<>
						<div className={`${CLASSNAME}__popup-opener`}>
							{renderPopupOpenerForRegion(regionName, regionValue)}
						</div>
						<div className={`${CLASSNAME}__dropdown-opener`}>
							{renderDropdownOpenerForRegion(regionName, regionValue)}
						</div>
					</>
				),
			(regionName, regionValue, districtName, districtValue, index, districtsTotal) => (
				<>
					<div className={`${CLASSNAME}__popup-opener`}>
						{renderPopupOpenerForDistrict(regionValue, districtName, districtValue)}
					</div>
					<div className={`${CLASSNAME}__dropdown-opener`}>
						{renderDropdownOpenerForDistrict(regionValue, districtName, districtValue)}
					</div>
				</>
			)
		)
	}

	_renderBrandModelTags(formLineEntity = {}) {
		const { id } = formLineEntity
		const { openedFilterPopups, openedFilterDropdown } = this.props

		const popupDefinition = openedFilterPopups.find((popup) => popup.filterId === id)

		const renderPopupOpenerForBrand = (brandName, brandValue) => (
			<BrandModelFilterPopupWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				popupDefinition={popupDefinition}
				context={FILTER_CONTEXT.TAGS}
				value={brandValue}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openPopup, deleteTag }) => (
							<BrandTag
								getValues={this._getTagValues}
								id={id}
								value={brandValue}
								text={brandName}
								onOpen={openPopup}
								deleteTag={deleteTag}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		const renderPopupOpenerForModel = (brandValue, modelName, modelValue) => (
			<TagLogic
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				context={FILTER_CONTEXT.TAGS}
			>
				{({ openPopup, deleteTag }) => (
					<ModelTag
						getValues={this._getTagValues}
						id={id}
						brandValue={brandValue}
						modelValue={modelValue}
						text={modelName}
						onOpen={openPopup}
						deleteTag={deleteTag}
					/>
				)}
			</TagLogic>
		)

		const dropdownDefinitionForBrand =
			openedFilterDropdown.filterId === id && !openedFilterDropdown.openedByDependentValue
				? openedFilterDropdown
				: undefined

		const renderDropdownOpenerForBrand = (brandName, brandValue) => (
			<BrandModelFilterDropdownWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				dropdownDefinition={dropdownDefinitionForBrand}
				context={FILTER_CONTEXT.TAGS}
				value={brandValue}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openDropdown, deleteTag }) => (
							<BrandTag
								getValues={this._getTagValues}
								id={id}
								value={brandValue}
								text={brandName}
								onOpen={openDropdown}
								deleteTag={deleteTag}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		const renderDropdownOpenerForModel = (brandValue, modelName, modelValue) => {
			const dropdownDefinitionForModel =
				openedFilterDropdown.filterId === id &&
				openedFilterDropdown.openedByValue === brandValue &&
				openedFilterDropdown.openedByDependentValue === modelValue
					? openedFilterDropdown
					: undefined

			return (
				<BrandModelFilterDropdownWithOpener
					formLineEntity={formLineEntity}
					changeFilter={this._redirectOnChange}
					dropdownDefinition={dropdownDefinitionForModel}
					context={FILTER_CONTEXT.TAGS}
					value={brandValue}
					renderOpener={() => (
						<TagLogic
							formLineEntity={formLineEntity}
							changeFilter={this._redirectOnChange}
							context={FILTER_CONTEXT.TAGS}
						>
							{({ openDropdown, deleteTag }) => (
								<ModelTag
									getValues={this._getTagValues}
									id={id}
									brandValue={brandValue}
									modelValue={modelValue}
									text={modelName}
									onOpen={openDropdown}
									deleteTag={deleteTag}
								/>
							)}
						</TagLogic>
					)}
				/>
			)
		}

		return this._renderDependentTags(
			formLineEntity,
			(brandName, brandValue) => (
				<>
					<div className={`${CLASSNAME}__popup-opener`}>
						{renderPopupOpenerForBrand(brandName, brandValue)}
					</div>
					<div className={`${CLASSNAME}__dropdown-opener`}>
						{renderDropdownOpenerForBrand(brandName, brandValue)}
					</div>
				</>
			),
			(_, brandValue, modelName, modelValue) => (
				<>
					<div className={`${CLASSNAME}__popup-opener`}>
						{renderPopupOpenerForModel(brandValue, modelName, modelValue)}
					</div>
					<div className={`${CLASSNAME}__dropdown-opener`}>
						{renderDropdownOpenerForModel(brandValue, modelName, modelValue)}
					</div>
				</>
			)
		)
	}

	_renderDependentTags(
		formLineEntity = {},
		renderRootTag = () => {},
		renderDependentTag = () => {}
	) {
		const { value = new Map(), options = [] } = formLineEntity
		const rootLookup = options.reduce(
			(lookup, { children: dependents, value: rootValue, name: rootName }) =>
				Object.assign(lookup, { [rootValue]: { dependents, rootName } }),
			{}
		)

		return Array.from(value)
			.reverse()
			.map(([rootValue, dependentsValues]) => {
				const root = rootLookup[rootValue]

				if (root) {
					const { rootName, dependents } = root
					const dependentsLookup = dependents.reduce(
						(lookup, { value: dependentValue, name: dependentName }) =>
							Object.assign(lookup, { [dependentValue]: dependentName }),
						{}
					)

					const rootTag = renderRootTag(rootName, rootValue, Array.from(dependentsValues))

					const dependentTags = Array.from(dependentsValues)
						.reverse()
						.map((dependentValue, index, arr) => {
							const dependentName = dependentsLookup[dependentValue]

							if (dependentName) {
								return renderDependentTag(
									rootName,
									rootValue,
									dependentName,
									dependentValue,
									index,
									arr.length
								)
							}

							return null
						})

					return [...dependentTags, rootTag]
				}

				return null
			})
	}

	_renderItemAgeTag(formLineEntity = {}) {
		const { id } = formLineEntity
		const { name } = formLineEntity.options.find(
			(option) => option.value === formLineEntity.value.value
		)

		const { openedFilterPopups, openedFilterDropdown } = this.props

		const popupDefinition = openedFilterPopups.find((popup) => popup.filterId === id)
		const dropdownDefinition =
			openedFilterDropdown.filterId === id ? openedFilterDropdown : undefined

		const renderPopupOpener = () => (
			<RadioFilterPopupWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				popupDefinition={popupDefinition}
				context={FILTER_CONTEXT.TAGS}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openPopup, deleteTag }) => (
							<ItemAgeTag
								text={name}
								getValues={this._getTagValues}
								id={id}
								onOpen={openPopup}
								deleteTag={deleteTag}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		const renderDropdownOpener = () => (
			<RadioFilterDropdownWithOpener
				formLineEntity={formLineEntity}
				changeFilter={this._redirectOnChange}
				dropdownDefinition={dropdownDefinition}
				context={FILTER_CONTEXT.TAGS}
				renderOpener={() => (
					<TagLogic
						formLineEntity={formLineEntity}
						changeFilter={this._redirectOnChange}
						context={FILTER_CONTEXT.TAGS}
					>
						{({ openDropdown, deleteTag }) => (
							<ItemAgeTag
								text={name}
								getValues={this._getTagValues}
								id={id}
								onOpen={openDropdown}
								deleteTag={deleteTag}
							/>
						)}
					</TagLogic>
				)}
			/>
		)

		return (
			<>
				<div className={`${CLASSNAME}__popup-opener`}>{renderPopupOpener()}</div>
				<div className={`${CLASSNAME}__dropdown-opener`}>{renderDropdownOpener()}</div>
			</>
		)
	}

	_renderWrappingContainer(children) {
		return (
			<ul
				className={`${CLASSNAME}__list-container`}
				data-dot-data={`{"totalCount": ${children.length}}`}
			>
				{children}
			</ul>
		)
	}

	_calcSpacingBetweenSlides(containerHeight) {
		return (slideIndex) => ({ left: 0, right: 32 })
	}

	_renderPreviousButton(currentSlideIndex, slidesTotal, showPreviousSlide) {
		if (currentSlideIndex > 0) {
			return (
				<button
					className={`${CLASSNAME}__previous-button`}
					onClick={showPreviousSlide}
					tabIndex={-1}
					aria-hidden='false'
					data-dot='previous'
				>
					{this.localize('TagsList.previous')}
				</button>
			)
		}
	}

	_renderNextButton(currentSlideIndex, slidesTotal, showNextSlide) {
		if (slidesTotal > 1 && currentSlideIndex < slidesTotal - 1) {
			return (
				<button
					className={`${CLASSNAME}__next-button`}
					onClick={showNextSlide}
					tabIndex={-1}
					aria-hidden='false'
					data-dot='next'
				>
					{this.localize('TagsList.next')}
				</button>
			)
		}
	}
}

const selectors = (state) => ({
	formLineEntities: state[FiltersExtension.stateIds.FORM_LINE_ENTITIES],
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS],
	openedFilterDropdown: state[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN],
	changeFilter: state[FiltersExtension.stateIds.CHANGE_FILTER]
})

export default select(selectors)(TagsList)
