
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['RegisterCompany'] = {
  backToInsertionPromo: function(d) { return "Zpět na Proč prodávat na Sautu"; },
  backToInsertionStart: function(d) { return "Zpět na vložení inzerátu"; },
  heading: function(d) { return "Registrace nové firmy"; },
  description: function(d) { return "Po odeslání požadavku k registraci Vás bude kontaktovat náš zástupce, který v Vámi dohodne detaily spolupráce na službě Sauto.cz."; },
  agreementNote: function(d) { return "Odesláním formuláře souhlasíte s obchodními podmínkami uvedenými "; },
  agreementUrl: function(d) { return "www.napoveda.seznam.cz/cz/smluvni-podminky/sauto.cz/smluvni-podminky-sauto-8.2.2021"; },
  helpNote: function(d) { return "V případě jakýchkoliv nejasností nás prosím kontaktujte na "; },
  helpEmail: function(d) { return "podpora@sauto.cz"; },
  input_required: function(d) { return "Pole je nutné vyplnit"; },
  input_not_valid: function(d) { return "Chybný formát"; },
  input_premise_name: function(d) { return "Autobazar"; },
  input_premise_name_plh: function(d) { return "Název autobazaru"; },
  input_premise_name_required: function(d) { return "Je nutné vyplnit název autobazaru"; },
  input_ic: function(d) { return "IČO"; },
  input_ic_plh: function(d) { return "např. 12345678"; },
  input_ic_required: function(d) { return "Je nutné vyplnit IČO"; },
  input_ic_not_valid: function(d) { return "Chybný formát IČO"; },
  input_contact_name: function(d) { return "Kontaktní osoba"; },
  input_contact_name_plh: function(d) { return "např. Jan Novák"; },
  input_contact_name_required: function(d) { return "Je nutné vyplnit kontaktní osobu"; },
  input_phone: function(d) { return "Telefonní číslo"; },
  input_phone_plh: function(d) { return "+420123456789"; },
  input_phone_required: function(d) { return "Je nutné vyplnit kontaktní telefon"; },
  input_phone_not_valid: function(d) { return "Chybný formát kontaktního telefonu"; },
  input_email: function(d) { return "Váš email"; },
  input_email_plh: function(d) { return "např. vas@email.cz"; },
  input_email_required: function(d) { return "Je nutné vyplnit kontaktní email"; },
  input_email_not_valid: function(d) { return "Chybný formát kontaktního emailu"; },
  input_locality: function(d) { return "Město"; },
  input_locality_plh: function(d) { return "např. Starý Plzenec"; },
  input_locality_required: function(d) { return "Je nutné vyplnit město"; },
  submitForm: function(d) { return "Odeslat"; },
  successTitle: function(d) { return "Požadavek k registraci firmy byl odeslán"; },
  successNote: function(d) { return "Nyní vyčkejte až se s Vámi spojí obchodník a dokončí s Vámi registraci."; },
  successGoBack: function(d) { return "Přejít na úvodní stránku"; },
  here: function(d) { return "zde"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	