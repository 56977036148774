import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import Layout from 'app/component/layout/LayoutView'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'
import BrandsList from './components/brandsList/BrandsList'
import BrandsCatalogController from './BrandsCatalogController'

import './BrandsCatalogCS.json'
import './BrandsCatalogView.less'

const CLASSNAME = 'p-uw-catalog'

const BrandsCatalogView = (props) => {
	const { [BrandsCatalogController.STATE_KEYS.BRANDS_LIST]: brandsList } = props

	const localize = useLocalize()

	return (
		<Layout className={CLASSNAME}>
			<div className={`${CLASSNAME}__content`} data-dot='catalog'>
				<div className={`${CLASSNAME}__header-content`}>
					<BackToHpButton />
					<h1 className={`${CLASSNAME}__header`}>{localize('BrandsCatalog.header')}</h1>
				</div>
				<BrandsList brandsList={brandsList} />
			</div>
		</Layout>
	)
}

export default BrandsCatalogView
