import React, { useContext } from 'react'
import SkyscraperComponent from '../skyscraperComponent/SkyscraperComponent'
import SspContext from '../SspContext'

const ID = 'skyscraper'
const ZONE_ID = 69801

const Skyscraper = () => {
	const sspContextData = useContext(SspContext)

	return (
		<SkyscraperComponent
			id={ID}
			zoneId={ZONE_ID}
			callAdd={true}
			dependencyArrayItem={sspContextData}
		/>
	)
}

export default Skyscraper
