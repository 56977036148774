import { HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import IAStatisticsController from 'app/page/internalAdmin/statistics/IAStatisticsController'

export default class IAPaymentsController extends AbstractInternalAdminController {
	static get SOURCE() {
		return IAStatisticsController.SOURCE
	}

	static get GROUP_BY() {
		return IAStatisticsController.GROUP_BY
	}

	constructor(dependenciesHelper, entitiesFilterExtension, advertStatisticsService) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._advertStatisticsService = advertStatisticsService

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)
		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const routeParams = this.getRouteParams()
		const params = Object.assign(
			{},
			IAStatisticsController.processRouteParamsForEntityList(routeParams),
			{
				// pro platby nas zajimaji jen tyto metriky
				metrics: ['active_advert', 'topped', 'topped_price_sum']
			}
		)

		if (params.sort) {
			// BE nepodporuje razeni podle 'topped_price_sum_without_vat', takze pokud na FE
			// chceme radit podle tohoto parametru, tak musime nahradit s 'topped_price_sum'.
			// Ten je na BE podporovan a vysledky budou stejne.
			params.sort = params.sort.replace('topped_price_sum_without_vat', 'topped_price_sum')
		}

		this._pageLoaderExtension.show()

		try {
			const {
				statisticsEntities,
				paginationEntity
			} = await this._advertStatisticsService.getStatistics(params)

			return {
				entities: statisticsEntities,
				paginationEntity,
				errorMessage: undefined
			}
		} catch (error) {
			const status = HttpErrorHelper.getHttpStatus(error)

			if (status === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				const { errors = [] } = body

				const [errorMessage] = errors.map(({ error_code: errorCode }) => {
					if (errorCode === 'invalid_manufacturer_group_by') {
						return 'Nelze seskupovat podle výrobce bez filtru na kategorii.'
					} else if (errorCode === 'invalid_advert_group_by') {
						return 'Nelze seskupovat podle inzerátu bez filtru na klienta nebo konkrétní inzerát.'
					}
				})

				return {
					entities: [],
					paginationEntity: {},
					errorMessage
				}
			} else {
				return {
					entities: [],
					paginationEntity: {},
					errorMessage: undefined
				}
			}
		} finally {
			this._pageLoaderExtension.hide()
			this.setState({
				isLoading: false
			})
		}
	}

	_filterInitiezerClb(routeParams) {
		return IAStatisticsController.filterInitiezerClb(this._entitiesFilterExtension, routeParams)
	}
}
