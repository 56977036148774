
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['VinInfo'] = {
  showInfo: function(d) { return "Informace o skrytém VIN"; },
  hiddenVin: function(d) { return "Skrytý VIN na přání prodejce"; },
  cebiaCertificate: function(d) { return "Certifikát Cebia"; },
  sixHiddenCharacters: function(d) { return "xxxxxx"; },
  eightHiddenCharacters: function(d) { return "xxxxxxxx"; },
  textLoaded: function(d) { return "Načteno"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	