import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import ROUTE_NAMES from 'app/base/RouteNames'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'

export default class IARightsController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, entityFormExtension, roleService) {
		super(dependenciesHelper)

		this._entityFormExtension = entityFormExtension

		this._roleService = roleService
	}

	init() {
		super.init()

		this.addExtension(this._entityFormExtension)

		this._entityFormExtension.initialize({
			getFormLineEntitiesData: this._getFormLineEntitiesData.bind(this),
			getSubmitPromise: this._getSubmitPromise.bind(this),
			handleSubmitSuccess: this._handleSubmitSuccess.bind(this),
			handleSubmitError: this._handleSubmitError.bind(this)
		})
	}

	load() {
		return super.load({})
	}

	_handleSubmitSuccess(data) {
		this._pageLoaderExtension.hide()

		this._utils.$Router.redirect(this._utils.$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ROLE_LIST))
	}

	_handleSubmitError(error, formLineEntities, formData) {
		this._pageLoaderExtension.hide()

		return {
			formLineEntities,
			entityFormError: {
				title: 'Nepodařilo se uložit změnu práv.'
			}
		}
	}

	_getSubmitPromise(formData) {
		const { id } = this.getRouteParams()
		const right_ids = []

		for (const [key, value] of Object.entries(formData)) {
			if (value === true) {
				right_ids.push(key)
			}
		}

		const apiParams = {
			name: formData.name,
			right_ids
		}

		this._pageLoaderExtension.show()

		return this._roleService.updateRole(id, apiParams)
	}

	async _getFormLineEntitiesData() {
		this._pageLoaderExtension.show()

		const { id } = this.getRouteParams()
		const formLineEntities = []

		const roleEntity = await this._roleService.getRole(id)

		const groupedRights = roleEntity.rights.reduce((accumulator, currentVal) => {
			const rightCategoryName = currentVal.rightCategory.name
			accumulator[rightCategoryName] = accumulator[rightCategoryName] || []
			accumulator[rightCategoryName].push(currentVal)
			return accumulator
		}, {})

		formLineEntities.push({
			id: 'name',
			label: 'Role',
			value: roleEntity.name,
			widget: IDS.TEXT,
			required: true,
			disabled: true
		})

		for (const [key, value] of Object.entries(groupedRights)) {
			formLineEntities.push({
				id: key,
				label: key,
				widget: IDS.HEADER
			})

			value.forEach(({ id, name, set }) => {
				formLineEntities.push({
					id,
					label: name,
					value: set,
					widget: IDS.CHECKBOX
				})
			})
		}

		this._pageLoaderExtension.hide()

		return formLineEntities
	}
}
