import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { STATUS_CONSTANTS, APPROVAL_ADMIN, DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import { PREMISE_FORM_LINES_IDS } from './premiseFilters/PremiseFiltersFormLines'
import ClientAdminSortConstants from './ClientAdminSortConstants'

const getApiStatusParams = (routeParams = {}) => {
	const { URL_APP_PARAMS } = ClientAdvertListUrlConvertor.constants
	const { advertStatus } = ClientAdvertListUrlConvertor
	const { [URL_APP_PARAMS.ADVERT_STATUS]: status } = routeParams

	for (const statusParams of Object.values(advertStatus)) {
		const { APP, API } = statusParams

		if (APP === status) {
			return API
		}
	}
}
const getApiDealTypeParams = (routeParams = {}) => {
	const { URL_APP_PARAMS } = ClientAdvertListUrlConvertor.constants
	const { dealType } = ClientAdvertListUrlConvertor
	const { [URL_APP_PARAMS.DEAL_TYPE]: dealTypeAppParam } = routeParams

	for (const dealTypeParams of Object.values(dealType)) {
		const { APP, API } = dealTypeParams

		if (APP === dealTypeAppParam) {
			return API
		}
	}

	return dealType.SALE.API
}

class ClientAdvertListUrlConvertor {
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				DEAL_TYPE: 'typ-prodeje',
				ADVERT_STATUS: 'status',
				ADVERT_ID: 'id',
				ADVERT_NAME: 'name',
				ADVERT_VIN: 'vin',
				IMPORT_ID: 'custom_id',
				FILTER_BY: 'filterBy',
				FILTER_BY_VALUE: 'filterByValue',

				PREMISE_CATEGORY: 'kategorie',
				PREMISE_REGION: 'kraj',
				PREMISE_MANUFACTURER: 'vyrobce',
				PREMISE_MODEL: 'model',
				PREMISE_CREATE_DATE_FROM: 'datum-vlozeni-od',
				PREMISE_CREATE_DATE_TO: 'datum-vlozeni-do',

				TOPPED_ADVERTS: 'topovano',
				PREFERRED_OFFER: 'pv'
			},

			DEAL_TYPE_OPTIONS: {
				OPERATING_LEASE: 'operativni-leasing'
			},

			URL_API_PARAMS: {
				DEAL_TYPE: 'deal_type',
				ADVERT_STATUS: 'status',
				ADVERT_IDS: 'ids',
				STATUS: {
					ACTIVE: 'status_active',
					DISABLED: 'status_disabled',
					DELETED: 'status_deleted'
				},
				TOPPED_ADVERTS: 'topped',
				PREFERRED_OFFER: 'preferred_offer',
				ENTITY: 'entity',

				PREMISE_CATEGORY: 'category_id',
				PREMISE_REGION: 'locality',
				PREMISE_MANUFACTURER: 'manufacturer_cb',
				PREMISE_MODEL: 'model_cb',
				PREMISE_CREATE_DATE_FROM: 'create_date_from',
				PREMISE_CREATE_DATE_TO: 'create_date_to',
				APPROVAL_ADMIN: 'approval_admin'
			}
		})
	}

	static get advertStatus() {
		return deepFreeze({
			ALL: {
				APP: '',
				API: {
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.ADVERT_STATUS]: [
						STATUS_CONSTANTS.ACTIVE,
						STATUS_CONSTANTS.INACTIVE,
						STATUS_CONSTANTS.DISABLED
					]
				}
			},

			ACTIVE: {
				APP: 'aktivni',
				API: {
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.ADVERT_STATUS]:
						STATUS_CONSTANTS.ACTIVE
				}
			},

			INACTIVE: {
				APP: 'neaktivni',
				API: {
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.ADVERT_STATUS]:
						STATUS_CONSTANTS.INACTIVE
				}
			},

			BAN: {
				APP: 'zablokovane',
				API: {
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.ADVERT_STATUS]: [
						STATUS_CONSTANTS.INACTIVE,
						STATUS_CONSTANTS.DISABLED
					],
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.APPROVAL_ADMIN]:
						APPROVAL_ADMIN.REJECTED
				}
			}
		})
	}

	static get dealType() {
		return deepFreeze({
			SALE: {
				APP: undefined, // aby se parametr smazal uplne z URL
				API: {
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.DEAL_TYPE]: [
						DEAL_TYPE.SALE,
						DEAL_TYPE.SALE_OR_LEASE
					]
				}
			},

			OPERATING_LEASE: {
				APP: ClientAdvertListUrlConvertor.constants.DEAL_TYPE_OPTIONS.OPERATING_LEASE,
				API: {
					[ClientAdvertListUrlConvertor.constants.URL_API_PARAMS.DEAL_TYPE]: [
						DEAL_TYPE.SALE_OR_LEASE,
						DEAL_TYPE.OPERATING_LEASE
					]
				}
			}
		})
	}

	static getApiParams(isPremise, id, appParams = {}) {
		const { URL_APP_PARAMS, URL_API_PARAMS } = UrlConvertor.constants
		const {
			URL_APP_PARAMS: CLIENT_URL_APP_PARAMS,
			URL_API_PARAMS: CLIENT_URL_API_PARAMS
		} = ClientAdvertListUrlConvertor.constants

		const apiParams = Object.assign(
			{
				[isPremise ? URL_API_PARAMS.PREMISE_ID : URL_API_PARAMS.USER_ID]: id,
				[UrlConvertor.constants.URL_API_PARAMS.SORT]: '-create_date'
			},
			getApiStatusParams(appParams),
			getApiDealTypeParams(appParams)
		)

		for (const param in appParams) {
			if (
				param === CLIENT_URL_APP_PARAMS.ADVERT_STATUS ||
				param === CLIENT_URL_APP_PARAMS.DEAL_TYPE
			) {
				// Nic nedělá, je vyřešeno defautlně výše.
			} else if (param === CLIENT_URL_APP_PARAMS.FILTER_BY) {
				const filterByValue = appParams[CLIENT_URL_APP_PARAMS.FILTER_BY_VALUE]

				if (param && filterByValue) {
					apiParams[appParams[param]] = filterByValue
				}
			} else if (param === CLIENT_URL_APP_PARAMS.FILTER_BY_VALUE) {
				// Nic nedělá, vyřešeno v podmínce výše (param = CLIENT_URL_APP_PARAMS.FILTER_BY)
			} else if (param === URL_APP_PARAMS.SORT) {
				Object.assign(apiParams, UrlConvertor.getApiSorting(appParams, ClientAdminSortConstants))
			} else if (param === CLIENT_URL_APP_PARAMS.PREMISE_REGION) {
				apiParams[CLIENT_URL_API_PARAMS.PREMISE_REGION] = `region,${appParams[param]}`
			} else {
				const key = Object.keys(CLIENT_URL_APP_PARAMS).find(
					(key) => CLIENT_URL_APP_PARAMS[key] === param
				)
				apiParams[CLIENT_URL_API_PARAMS[key]] = appParams[param]
			}
		}

		const limitParam = appParams[URL_APP_PARAMS.PER_PAGE]
			? Number(appParams[URL_APP_PARAMS.PER_PAGE])
			: UrlConvertor.constants.DEFAULTS.PER_PAGE
		Object.assign(apiParams, UrlConvertor.getApiPaging(appParams[URL_APP_PARAMS.PAGE], limitParam))

		return apiParams
	}

	static get formLinesToUrlParamsMapping() {
		const { URL_APP_PARAMS, URL_API_PARAMS } = ClientAdvertListUrlConvertor.constants

		return deepFreeze({
			[PREMISE_FORM_LINES_IDS.CATEGORY_SELECT_ID]: [
				URL_APP_PARAMS.PREMISE_CATEGORY,
				URL_API_PARAMS.PREMISE_CATEGORY
			],
			[PREMISE_FORM_LINES_IDS.ADVERT_STATUS_SELECT_ID]: [
				URL_APP_PARAMS.ADVERT_STATUS,
				URL_API_PARAMS.ADVERT_STATUS
			],
			[PREMISE_FORM_LINES_IDS.DEAL_TYPE_SELECT_ID]: [
				URL_APP_PARAMS.DEAL_TYPE,
				URL_API_PARAMS.DEAL_TYPE
			],
			[PREMISE_FORM_LINES_IDS.REGION_SELECT_ID]: [
				URL_APP_PARAMS.PREMISE_REGION,
				URL_API_PARAMS.PREMISE_REGION
			],
			[PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID]: [
				URL_APP_PARAMS.PREMISE_MANUFACTURER,
				URL_API_PARAMS.PREMISE_MANUFACTURER
			],
			[PREMISE_FORM_LINES_IDS.MODEL_AUTO_COMPLETE_ID]: [
				URL_APP_PARAMS.PREMISE_MODEL,
				URL_API_PARAMS.PREMISE_MODEL
			],
			[PREMISE_FORM_LINES_IDS.CREATE_DATE_FROM_ID]: [
				URL_APP_PARAMS.PREMISE_CREATE_DATE_FROM,
				URL_API_PARAMS.PREMISE_CREATE_DATE_FROM
			],
			[PREMISE_FORM_LINES_IDS.CREATE_DATE_TO_ID]: [
				URL_APP_PARAMS.PREMISE_CREATE_DATE_TO,
				URL_API_PARAMS.PREMISE_CREATE_DATE_TO
			],
			[PREMISE_FORM_LINES_IDS.TOPPED_ADVERTS_CHECKBOX_ID]: [
				URL_APP_PARAMS.TOPPED_ADVERTS,
				URL_API_PARAMS.TOPPED_ADVERTS
			],
			[PREMISE_FORM_LINES_IDS.PREFERRED_OFFER_CHECKBOX_ID]: [
				URL_APP_PARAMS.PREFERRED_OFFER,
				URL_API_PARAMS.PREFERRED_OFFER
			]
		})
	}

	static createAppParamsForFilters(formLineEntities = [], routeParams = {}) {
		const updatedRouteParams = Object.assign({}, routeParams)

		formLineEntities.forEach((formLineEntity) => {
			const { id, extra: { getUrlAppParamValueFromFormLine = () => {} } = {} } = formLineEntity

			const param = ClientAdvertListUrlConvertor.formLinesToUrlParamsMapping[id][0]
			const appParamValue = getUrlAppParamValueFromFormLine(formLineEntity)

			if (!isNullOrUndefined(appParamValue)) {
				updatedRouteParams[param] = appParamValue
			} else {
				delete updatedRouteParams[param]
			}
		})

		return updatedRouteParams
	}

	static getFormLinesValuesFromUrlAppParams(premiseFormLineEntities = [], currentAppParams = {}) {
		const mapping = ClientAdvertListUrlConvertor.formLinesToUrlParamsMapping
		const res = []

		for (const formLineId in mapping) {
			const [urlAppParamName] = mapping[formLineId]
			const urlAppParamValue = currentAppParams[urlAppParamName]
			const formLineEntity = premiseFormLineEntities.find((f) => f.id === formLineId)

			if (formLineEntity) {
				const { id, extra: { getFormLineValueFromUrlAppParam = () => {} } = {} } = formLineEntity

				res.push({
					id,
					value: getFormLineValueFromUrlAppParam(formLineEntity, urlAppParamValue)
				})
			}
		}

		return res
	}
}

export default ClientAdvertListUrlConvertor
