import UserwebBaseController from 'app/base/UserwebBaseController'
import FavoriteListUrlConvertor from 'app/page/userweb/favoriteList/FavoriteListUrlConvertor'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

class FavoriteListController extends UserwebBaseController {
	constructor(dependenciesHelper, favoriteService, backToHpExtension) {
		super(dependenciesHelper)

		this._favoriteService = favoriteService
		this._backToHpExtension = backToHpExtension

		this.setIsWholePageAfterLogin()
	}

	static get STATE_KEYS() {
		return Object.assign({
			FAVORITE_LIST: 'favoriteList',
			PLACEHOLDERS: 'placeholder'
		})
	}

	init() {
		super.init()

		this.addExtension(this._backToHpExtension)
	}

	load() {
		return super.load({
			[FavoriteListController.STATE_KEYS.FAVORITE_LIST]: this._fetchFavoriteList(),
			[FavoriteListController.STATE_KEYS.PLACEHOLDERS]: {
				advertList: Array(UrlConvertor.constants.DEFAULTS.PER_PAGE).fill({}),
				pagination: {
					total: UrlConvertor.constants.DEFAULTS.PER_PAGE
				}
			}
		})
	}

	update(prevParams) {
		this._setPlaceholders()

		return super.update(prevParams, {
			[FavoriteListController.STATE_KEYS.FAVORITE_LIST]: this._fetchFavoriteList()
		})
	}

	onFavoriteClick(params) {
		this._favoriteExtension.onFavoriteClick(params, async () => {
			this.setState({
				[FavoriteListController.STATE_KEYS.FAVORITE_LIST]: await this._fetchFavoriteList()
			})
		})
	}

	getDataForSsp() {
		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {}
		}
	}

	_setPlaceholders() {
		const {
			[FavoriteListController.STATE_KEYS.FAVORITE_LIST]: { pagination }
		} = this.getState()

		this.setState({
			[FavoriteListController.STATE_KEYS.FAVORITE_LIST]: {
				advertList: Array(UrlConvertor.constants.DEFAULTS.PER_PAGE).fill({}),
				pagination
			}
		})
	}

	async _fetchFavoriteList() {
		try {
			const params = FavoriteListUrlConvertor.getApiParams(this.getRouteParams())
			return await this._favoriteService.getFavorites(params, { cache: false })
		} catch (e) {
			return {
				advertList: [],
				pagination: {}
			}
		}
	}
}

export default FavoriteListController
