import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'

import './MySavedSearchAddButtonCS.json'

export const ICON = {
	d:
		'm24 19.613c-1.609-1.557-2.064-2.768-2.064-4.875 0-2.106-1.849-3.9-4.129-3.9-2.281 0-4.13 1.794-4.13 3.9 0 2.107-0.454 3.318-2.064 4.875 1.032 0.841 3.25 1.949 6.194 1.949 2.942 1e-3 5.161-1.108 6.193-1.949zm-19.708-10.012c0-4.333 3.666-7.856 8.171-7.856 4.508 0 8.174 3.523 8.174 7.856 0 0.128-4e-3 0.256-0.011 0.383 0.647 0.412 1.2 0.94 1.622 1.55 0.136-0.636 0.204-1.284 0.204-1.933 0-5.295-4.479-9.601-9.989-9.601-5.507 0-9.988 4.306-9.988 9.6 0 2.595 1.08 4.948 2.826 6.675-0.771 0.412-1.483 0.883-2.17 1.373-0.953 0.685-1.872 1.401-2.688 2.219-0.584 0.576-0.592 1.517-0.016 2.102 0.02 0.02 0.041 0.039 0.061 0.059 0.625 0.566 1.578 0.566 2.203 0 0.849-0.785 1.594-1.67 2.307-2.584 0.531-0.688 1.041-1.398 1.481-2.174 1.15 0.83 2.485 1.431 3.938 1.727 0.333-0.507 0.595-1.057 0.777-1.635-3.905-0.589-6.902-3.844-6.902-7.761zm11.192 12.851c0 0.854 1.039 1.548 2.322 1.548 1.282 0 2.322-0.693 2.322-1.548h-4.644z',
	size: 24
}

const MySavedSearchAddButton = ({ className, text, onClick = DEFAULT_PROPS.FUNCTION }) => {
	const localize = useLocalize()

	const [isLoading, setIsLoading] = useState(false)

	const handleOnClick = async () => {
		try {
			setIsLoading(true)
			await onClick()
		} catch {
			// na chybu nebudeme nijak reagovat
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Button
			className={classnames({
				[className]: !!className
			})}
			loading={isLoading}
			size='small'
			icon={ICON}
			text={text || localize('MySavedSearchAddButton.save')}
			data-dot='open'
			onClick={handleOnClick}
		/>
	)
}

MySavedSearchAddButton.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	onClick: PropTypes.func.isRequired
}

export default React.memo(MySavedSearchAddButton)
