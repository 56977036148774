import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from '@inzeraty/components'

import './EntityFormPopup.less'

const CLASSNAME = 'c-entity-form-popup'

const EntityFormPopup = (props) => {
	const { children, ...restProps } = props

	return (
		<div className={`${CLASSNAME}__popup-wrapper`}>
			<Popup
				className={`${CLASSNAME}__popup`}
				isOpen={true}
				hasLightbox={true}
				isClosedOnClickOutside={false}
				{...restProps}
			>
				{children}
			</Popup>
		</div>
	)
}

EntityFormPopup.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default React.memo(EntityFormPopup)
