import { CATEGORIES } from 'app/base/Constants'

export default class CatalogService {
	constructor(
		catalogResource,
		brandsCatalogFactory,
		modelsCatalogFactory,
		modelLinesOverviewFactory,
		modificationsFactory,
		codebooksService,
		modificationDetailFactory
	) {
		this._catalogResource = catalogResource
		this._brandsCatalogFactory = brandsCatalogFactory
		this._modelsCatalogFactory = modelsCatalogFactory
		this._modelLinesOverviewFactory = modelLinesOverviewFactory
		this._modificationsFactory = modificationsFactory
		this._codebooksService = codebooksService
		this._modificationDetailFactory = modificationDetailFactory
	}

	getBrands(options = {}) {
		return this._catalogResource.getBrands(options).then((response) => {
			return this._brandsCatalogFactory.transformData(response.body.results)
		})
	}

	getModels(params, options = {}) {
		return this._catalogResource.getModels(params, options).then((response) => {
			return this._modelsCatalogFactory.transformData(response.body.results)
		})
	}

	getModelLines(params, options = {}) {
		return this._catalogResource.getModels(params, options).then((response) => {
			return this._modelLinesOverviewFactory.transformData(response.body.results)
		})
	}

	getModifications(params, options = {}) {
		return this._catalogResource.getModels(params, options).then((response) => {
			const results = response.body.results
			const parent = response.body.parent

			return this._modificationsFactory.transformData(results, parent)
		})
	}

	getModificationDetail(params, options = {}) {
		return this._catalogResource.getModels(params, options).then((response) => {
			return this._modificationDetailFactory.transformData(response.body.results)
		})
	}

	async loadAllBrands() {
		const getManufacturerCodebook = async () => {
			const codebooks = (await this._getCategoryCodebooks()) || []

			return codebooks.find(({ nameId }) => nameId === 'manufacturer_cb') || {}
		}

		try {
			const { id: manufacturerCodebookId } = await getManufacturerCodebook()

			const params = {
				codebook_id: manufacturerCodebookId,
				limit: 1000
			}

			return await this._codebooksService.getCodebooks(params)
		} catch (e) {
			return undefined
		}
	}

	async loadAllModelsForBrand(selectedBrandId) {
		if (selectedBrandId) {
			const getModelCodebookForCategory = async () => {
				const codebooks = (await this._getCategoryCodebooks()) || []

				return codebooks.find(({ nameId }) => nameId === 'model_cb') || {}
			}

			try {
				const { id: modelCodebookId } = await getModelCodebookForCategory()

				const params = {
					codebook_id: modelCodebookId,
					parent_id: selectedBrandId,
					limit: 1000
				}

				return await this._codebooksService.getCodebooks(params)
			} catch (e) {
				return undefined
			}
		}
	}

	async _getCategoryCodebooks() {
		const categoryId = CATEGORIES.PASSENGER_CARS.id

		const { categoryId: savedCategoryId, codebooks = [] } = this._tmpCategoryCodebooks || {}

		if (categoryId === savedCategoryId) {
			return codebooks
		} else {
			try {
				const codebooks = await this._codebooksService.getCategoryCodebooks(categoryId)

				this._tmpCategoryCodebooks = {
					categoryId,
					codebooks
				}

				return codebooks
			} catch (e) {
				return []
			}
		}
	}
}
