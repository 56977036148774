import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps } from '@inzeraty/helpers'
import AbstractOpener from '../AbstractOpener'
import SelectedItem, {
	defaultAdditionalTextBuilder
} from 'app/component/filters/components/selectedItem/SelectedItem'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'

import './RegionOpenerCS.json'

export default class RegionOpener extends AbstractOpener {
	static get propTypes() {
		return Object.assign({}, AbstractOpener.propTypes, {
			onOpenRegionDistricts: PropTypes.func,
			clearRegionDistrictsFilter: PropTypes.func
		})
	}

	static get defaultProps() {
		return Object.assign({}, AbstractOpener.defaultProps, {
			onOpenRegionDistricts: DefaultProps.FUNCTION,
			clearRegionDistrictsFilter: DefaultProps.FUNCTION
		})
	}

	_renderOpener() {
		const {
			formLineEntity: { value, label }
		} = this.props

		return (
			<React.Fragment>
				<div>{label}</div>
				{value.size > 0 && this._renderSelectedRegions()}

				{super._renderOpener()}
			</React.Fragment>
		)
	}

	_getTextAndHasValue() {
		const {
			formLineEntity: { placeholder, value }
		} = this.props

		return {
			text: value.size ? this.localize('RegionOpener.addMoreRegions') : placeholder
		}
	}

	_renderSelectedRegions() {
		const {
			formLineEntity: { value, options }
		} = this.props
		const selectedRegions = []

		value.forEach((selectedDistricts, regionValue) => {
			const region = options.find((region) => regionValue === region.value) || {}
			const { name, children: districts } = region

			selectedRegions.push(
				<SelectedItem
					key={regionValue}
					mainText={name}
					additionalText={this._getTextForDistricts(districts, Array.from(selectedDistricts))}
					onClick={() => this._openRegionDistrictModal(regionValue)}
					onRemove={() => this._resetFilter(regionValue)}
				/>
			)
		})

		// - obaleno divem kvuli css first-child
		return <div>{selectedRegions}</div>
	}

	_getTextForDistricts(districts, selectedDistricts) {
		return defaultAdditionalTextBuilder(
			districts,
			selectedDistricts,
			this.localize('RegionOpener.entireRegion')
		)
	}

	_openAllRegionsModal() {
		const { onOpen: openAllRegionsModal } = this.props

		openAllRegionsModal()
	}

	_openRegionDistrictModal(regionValue) {
		const { onOpenRegionDistricts, context } = this.props

		// TODO tohle nechceme volat ani pro popup, zbytecne otevirame
		if (context !== FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL) {
			this._openAllRegionsModal()
		}

		onOpenRegionDistricts(regionValue)
	}

	_resetFilter(regionValue) {
		const {
			formLineEntity: { value: valueDataMap },
			clearRegionDistrictsFilter
		} = this.props

		const getMapWithoutValue = (map, value) => {
			const newMap = new Map(map)
			newMap.delete(value)

			return newMap
		}

		clearRegionDistrictsFilter(getMapWithoutValue(valueDataMap, regionValue))
	}
}
