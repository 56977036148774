
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['OperatingLeaseDetailPrice'] = {
  intendedFor: function(d) { return "Určeno pro:"; },
  price: function(d) { return " " + d.CURRENCY + " / měs. s DPH"; },
  priceNoVat: function(d) { return " " + d.CURRENCY + " / měs. bez DPH"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	