import React from 'react'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import PropTypes from 'prop-types'
import { CHEVRON_UP_OUTLINE_24, CHEVRON_DOWN_OUTLINE_24 } from '@sznds/icons'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import ArrowButton from './arrowButton/ArrowButton'

import './ExtendedInfo.less'

const CLASSNAME = 'c-extended-info'

export default class ExtendedInfo extends AbstractPureComponent {
	static get propTypes() {
		return {
			text: PropTypes.string,
			children: PropTypes.oneOfType([
				PropTypes.arrayOf(PropTypes.node),
				PropTypes.node,
				PropTypes.func
			]),
			dataDot: PropTypes.shape({
				opener: PropTypes.string,
				content: PropTypes.string
			}),
			dataDotData: PropTypes.shape({
				type: PropTypes.string
			}),
			isOpened: PropTypes.bool,
			isSectionOpenedByDefault: PropTypes.bool,
			iconPath: PropTypes.string,
			updateCallback: PropTypes.func,
			onToggle: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			text: '',
			isSectionOpenedByDefault: false,
			iconPath: '',
			updateCallback: DEFAULT_PROPS.FUNCTION,
			onToggle: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			isOpened: this.props.isSectionOpenedByDefault
		}

		this._toggleOpen = this._toggleOpen.bind(this)
	}

	render() {
		const {
			text,
			children,
			dataDot,
			dataDotData,
			iconPath,
			isOpened: isOpenedFromProps
		} = this.props
		const { isOpened: isOpenedFromState } = this.state

		const isOpened = isOpenedFromProps !== undefined ? isOpenedFromProps : isOpenedFromState

		const dataDotOpenerProps = dataDot && dataDot.opener ? { 'data-dot': dataDot.opener } : {}
		const dataDotContentProps = dataDot && dataDot.content ? { 'data-dot': dataDot.content } : {}
		const dataDotDataOpenerProps =
			dataDotData && dataDotData.type
				? { 'data-dot-data': `{"type": "${dataDotData.type}", "opened": "${isOpened}"}` }
				: {}
		const dataDotDataContentProps =
			dataDotData && dataDotData.type ? { 'data-dot-data': `{"type": "${dataDotData.type}"}` } : {}

		return (
			<React.Fragment>
				<button
					className={this.cssClasses({
						[`${CLASSNAME}__opener`]: true,
						[`${CLASSNAME}__opener--open`]: isOpened
					})}
					type='button'
					onClick={this._toggleOpen}
					{...dataDotOpenerProps}
					{...dataDotDataOpenerProps}
				>
					<ArrowButton
						text={text}
						symbol={isOpened ? CHEVRON_UP_OUTLINE_24 : CHEVRON_DOWN_OUTLINE_24}
						iconPath={iconPath}
					/>
				</button>
				<div
					className={this.cssClasses({
						[`${CLASSNAME}__content`]: true,
						[`${CLASSNAME}__hidden`]: !isOpened
					})}
					{...dataDotContentProps}
					{...dataDotDataContentProps}
				>
					{typeof children === 'function' ? children(isOpened) : children}
				</div>
			</React.Fragment>
		)
	}

	_toggleOpen() {
		const { onToggle, isOpened: isOpenedFromProps } = this.props

		if (isOpenedFromProps !== undefined) {
			onToggle(!isOpenedFromProps)
		} else {
			this.setState(
				(prevState) => ({
					isOpened: !prevState.isOpened
				}),
				() => {
					const { isOpened } = this.state
					onToggle(isOpened)
				}
			)
		}
	}
}
