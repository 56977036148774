import ns from 'ima/namespace'
import DependenciesHelper from 'app/helpers/dependenciesHelper/DependenciesHelper'

ns.namespace('app.helpers.dependenciesHelper')

/**
 * Helper nastavovani zavislosti pro userweb.
 *
 * @class UserwebDependenciesHelper
 * @namespace app.helpers.dependenciesHelper
 * @module app
 * @submodule app.helpers
 * @extends app.helpers.dependenciesHelper.DependenciesHelper
 */
export default class UserwebDependenciesHelper extends DependenciesHelper {
	/**
	 * @method constructor
	 * @constructor
	 * @param {Object<ima.extension.AbstractExtension>} extensions
	 * @param {Object} dependencies
	 */
	constructor(extensions, dependencies) {
		super(extensions, dependencies)
	}
}

ns.app.helpers.dependenciesHelper.UserwebDependenciesHelper = UserwebDependenciesHelper
