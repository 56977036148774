import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isIOS from 'is-ios'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import IMAGES from 'app/base/ImagesConstants'

import './BrandIconView.less'

const CLASSNAME = 'cf-brand-icon'

const Logo = ({ seoName, categorySeoName, imageUrl }) => {
	const [hasLogo, setHasLogo] = useState(false)
	const image = imageUrl ? imageUrl : IMAGES[`image__logo__v2__${categorySeoName}__${seoName}`]
	const placeholder = IMAGES[`image__logo__v2__${categorySeoName}__placeholder`]

	useEffect(() => {
		if (image) {
			const logoImage = new Image()
			logoImage.src = image
			logoImage.onload = () => {
				setHasLogo(true)
			}
		}
	}, [image])

	const isPlaceholder = !hasLogo && placeholder

	return (
		<img
			// na iOSu je porad problem s lazy loadingem, i kdyz by mel byt od verze 15.4 podporovan
			loading={isIOS ? 'defer' : 'lazy'}
			src={isPlaceholder ? placeholder : image}
			alt=''
			data-e2e={`${categorySeoName}__${seoName}`}
			className={classnames({
				[`${CLASSNAME}__logo`]: true,
				[`${CLASSNAME}__logo-img`]: !isPlaceholder
			})}
		/>
	)
}

Logo.propTypes = {
	seoName: PropTypes.string,
	categorySeoName: PropTypes.string,
	imageUrl: PropTypes.string
}

const BrandIcon = ({ className, onClick, value, svgIcon, seoName, categorySeoName, imageUrl }) => {
	let Element
	const props = {
		className: classnames({
			[CLASSNAME]: true,
			[className]: !!className,
			[`${CLASSNAME}--logo`]: !svgIcon
		})
	}

	if (onClick && typeof onClick === 'function') {
		Element = 'button'

		props.type = 'button'
		props.onClick = (event) => {
			onClick(event, value)
		}
	} else {
		Element = 'span'
	}

	return (
		<Element {...props}>
			{svgIcon ? (
				<SvgIcon className={`${CLASSNAME}__icon`} icon={svgIcon} />
			) : (
				<Logo seoName={seoName} categorySeoName={categorySeoName} imageUrl={imageUrl} />
			)}
		</Element>
	)
}

BrandIcon.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	value: PropTypes.number,
	svgIcon: PropTypes.oneOf(Object.keys(SvgIcon.ICON_ID)),
	seoName: PropTypes.string,
	categorySeoName: PropTypes.string,
	imageUrl: PropTypes.string
}

BrandIcon.defaultProps = {
	className: ''
}

export default BrandIcon
