import AbstractNewAdvertBaseController from '../AbstractNewAdvertBaseController'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../AbstractNewAdvertStateKeys'
import PhotosUploaderExtension from 'app/component/photosUploader/PhotosUploaderExtension'
import VideoUploaderExtension from 'app/component/videoUploader/VideoUploaderExtension'

export default class PhotosController extends AbstractNewAdvertBaseController {
	constructor(dependenciesHelper, photosUploaderExtension, videosUploaderExtension) {
		super(dependenciesHelper)

		this._photosUploaderExtension = photosUploaderExtension
		this._videosUploaderExtension = videosUploaderExtension
	}

	init() {
		super.init()
		this.addExtension(this._photosUploaderExtension)
		this.addExtension(this._videosUploaderExtension)
	}

	load() {
		const superState = super.load()

		const { [ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise } = superState

		return Object.assign({}, superState, {
			advertDetail: advertEntityPromise,
			[PhotosUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntityPromise,
			[VideoUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntityPromise
		})
	}
}
