import { AbstractFactory } from '@inzeraty/models'
import PremiseWalletEntity from './PremiseWalletEntity'

export default class PremiseWalletFactory extends AbstractFactory {
	constructor() {
		super(PremiseWalletEntity)
	}

	transformData(data = {}) {
		return {
			credit: data.credit,
			walletUserId: data.wallet_user_id,
			walletLogin: data.wallet_login
		}
	}
}
