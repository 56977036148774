import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from '@sznds/react'
import { ADD_SQUIRCLE_FILLED_16 } from '@sznds/icons'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import TextLink from 'app/component/textLink/TextLink'
import RegionDistrictSearch from './regionDistrictSearch/RegionDistrictSearch'

import './AdvertListRegionDistrictOpener.less'
import './AdvertListRegionDistrictOpenerCS.json'

const CLASSNAME = 'c-item-list-region-district-opener'

const AdvertListRegionDistrictOpener = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		changeFilter = DEFAULT_PROPS.FUNCTION,
		advertsCount = DEFAULT_PROPS.OBJECT
	} = props

	const localize = useLocalize()

	const [showAddButton, setShowAddButton] = useState(true)
	const [showPopup, setShowPopup] = useState(false)

	const renderAddAnotherPlace = () => {
		const renderAddButton = (additionalProps = {}) => {
			return (
				<TextLink
					className={`${CLASSNAME}__add-button`}
					iconLeft={<Icon symbol={ADD_SQUIRCLE_FILLED_16} />}
					{...additionalProps}
				>
					{localize('AdvertListRegionDistrictOpener.addPlace')}
				</TextLink>
			)
		}

		return (
			<>
				<div className={`${CLASSNAME}__mobile`}>
					{renderAddButton({
						onClick: () => setShowPopup(true)
					})}

					{showPopup &&
						renderSearch({
							initialIsOpen: true,
							onClose: () => setShowPopup(false)
						})}
				</div>

				<div className={`${CLASSNAME}__desktop`}>
					{showAddButton
						? renderAddButton({
								onClick: () => setShowAddButton(false)
						  })
						: renderSearch({
								className: `${CLASSNAME}__search-wrapper`,
								autoFocus: true,
								initialIsOpen: true,
								onClose: () => setShowAddButton(true)
						  })}
				</div>
			</>
		)
	}

	const renderSearch = (additionalProps = {}) => {
		const restProps = Object.assign({}, additionalProps)

		delete restProps.className

		return (
			<div
				className={classnames({
					[additionalProps.className]: !!additionalProps.className
				})}
			>
				<RegionDistrictSearch
					formLineEntity={formLineEntity}
					changeFilter={changeFilter}
					advertsCount={advertsCount}
					{...restProps}
				/>
			</div>
		)
	}

	const renderSelectedPlaces = () => {
		const { value: regions = new Map(), options: allRegions = [] } = formLineEntity

		const allDistricts = allRegions
			.map((region) => {
				const { children: districtsForOneRegion } = region

				return districtsForOneRegion.map((district) =>
					Object.assign({}, district, {
						parentRegion: region
					})
				)
			})
			.reduce((acc, arr) => acc.concat(arr), [])

		const renderRegion = (region = {}) => {
			const removeRegion = () => {
				const { id, value: selectedRegions = new Map() } = formLineEntity

				const newSelectedRegions = new Map(selectedRegions)
				newSelectedRegions.delete(regionValue)

				changeFilter(id, newSelectedRegions)
			}

			const { value: regionValue } = region

			return (
				<div key={`region__${regionValue}`} className={`${CLASSNAME}__selected-item`}>
					<RegionDistrictSearch
						formLineEntity={formLineEntity}
						selectedItem={region}
						changeFilter={changeFilter}
						removeFilter={removeRegion}
						advertsCount={advertsCount}
					/>
				</div>
			)
		}

		const renderDistrict = (district = {}) => {
			const removeDistrict = () => {
				const { id, value: selectedRegions = new Map() } = formLineEntity
				const { parentRegion: { value: regionValue } = {} } = district

				const newSelectedDistrictForRegion = new Set(selectedRegions.get(regionValue))

				newSelectedDistrictForRegion.delete(districtValue)

				const newSelectedRegions = new Map([
					...selectedRegions,
					[regionValue, newSelectedDistrictForRegion]
				])

				if (!newSelectedDistrictForRegion.size) {
					newSelectedRegions.delete(regionValue)
				}

				changeFilter(id, newSelectedRegions)
			}

			const { value: districtValue } = district

			return (
				<div key={`district__${districtValue}`} className={`${CLASSNAME}__selected-item`}>
					<RegionDistrictSearch
						formLineEntity={formLineEntity}
						selectedItem={district}
						changeFilter={changeFilter}
						removeFilter={removeDistrict}
						advertsCount={advertsCount}
					/>
				</div>
			)
		}

		const sortAlphabetically = (itemA = {}, itemB = {}) => {
			const { name: nameA = '' } = itemA
			const { name: nameB = '' } = itemB

			return nameA.localeCompare(nameB)
		}

		return (
			Array.from(regions)
				// nachystame si data k vykresleni
				.map(([regionValue, districtsValues = new Set()]) => {
					if (districtsValues.size) {
						return Array.from(districtsValues).map((districtValue) => {
							const district = allDistricts.find((district) => district.value === districtValue)

							return {
								name: district.name,
								renderItem: () => renderDistrict(district)
							}
						})
					} else {
						const region = allRegions.find((region) => region.value === regionValue)

						return {
							name: region.name,
							renderItem: () => renderRegion(region)
						}
					}
				})
				// sloucime kraje a okresy do jednoho pole
				.reduce((all, item) => all.concat(item), [])
				// seradime podle nazvu
				.sort(sortAlphabetically)
				// serazene pole vykreslime
				.map(({ renderItem } = {}) => renderItem && renderItem())
		)
	}

	const { value = new Map() } = formLineEntity

	return (
		<div
			data-e2e={formLineEntity.id}
			data-dot='filter'
			data-dot-data={`{"type": "${formLineEntity.id}"}`}
		>
			{value.size ? (
				<>
					{renderSelectedPlaces()}
					{renderAddAnotherPlace()}
				</>
			) : (
				renderSearch()
			)}
		</div>
	)
}

AdvertListRegionDistrictOpener.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	changeFilter: PropTypes.func.isRequired,
	advertsCount: PropTypes.object.isRequired
}

export default React.memo(AdvertListRegionDistrictOpener)
