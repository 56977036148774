import React from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import BasicWidget from '../../../../component/widgets/basic/BasicWidget'
import BasicInfoTooltip from 'app/page/userweb/newAdvert/basicInfo/components/tooltip/BasicInfoTooltip'
import BASIC_INFO_STATE_KEYS from 'app/page/userweb/newAdvert/basicInfo/BasicInfoStateKeys'
import { SECTIONS_ID } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoSections'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'
import ModelSelectWidget, {
	MODEL_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/modelSelect/ModelSelect'

export { MODEL_SELECT_WIDGET_ID }

const ModelSelect = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT } = props

	const linkedSelectAndLabel = AutoComplete.getIdsPropsHelper(formLineEntity.id)

	return (
		<BasicWidget
			formLineEntity={formLineEntity}
			name={MODEL_SELECT_WIDGET_ID}
			renderLabel={(formLineEntity, props) => {
				const { label, required } = formLineEntity
				const { labelId, inputId } = linkedSelectAndLabel

				return (
					<>
						<label {...props} id={labelId} htmlFor={inputId}>
							{label}
							{required && <span className='c-basic-widget__required'>*</span>}
						</label>
						<BasicInfoTooltip formLineEntity={formLineEntity} />
					</>
				)
			}}
			renderInput={(formLineEntity, inputWrapperProps) => {
				return (
					<div className={inputWrapperProps.className}>
						<ModelSelectWidget {...props} {...linkedSelectAndLabel} />
					</div>
				)
			}}
		/>
	)
}

ModelSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	formLineEntityForBrand: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func
}

ModelSelect.defaultProps = {
	onChange: DEFAULT_PROPS.FUNCTION
}

const selectors = (state) => {
	const formLineEntities =
		state[BASIC_INFO_STATE_KEYS.FORM_LINES_BY_SECTION][SECTIONS_ID.TYPE_AND_MODEL_SECTION]

	if (formLineEntities) {
		return {
			formLineEntityForBrand: formLineEntities.find((f) => f.id === FORM_LINES_IDS.MANUFACTURER_CB)
		}
	}

	return {}
}

export default React.memo(select(selectors)(ModelSelect))
