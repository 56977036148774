import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import { InputSurface } from '@sznds/react'
import NumberFormat from 'react-number-format'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'

import './NumberWidget.less'

export const NUMBER_WIDGET_ID = 'number_id'

const CLASSNAME = 'c-number-widget-base'

const MAX_NUMBER = 999999999999
const DECIMAL_SEPARATOR = ','

const NumberWidget = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const inputRef = useRef(null)

	const {
		id,
		placeholder = '',
		disabled = false,
		value = '',
		required,
		errorMessage,
		extra: { description: unit, cebiaError, thousandSeparator = ' ', maxLength = '' } = {}
	} = formLineEntity

	const decimalProps =
		id === FORM_LINES_IDS.AVERAGE_GAS_MILEAGE
			? {
					decimalScale: 1
			  }
			: {}

	const restProps = Object.assign({}, props)

	delete restProps.formLineEntity
	delete restProps.onChange

	return (
		<div className={CLASSNAME}>
			<NumberFormat
				placeholder={placeholder}
				disabled={disabled}
				value={value}
				maxLength={maxLength}
				required={required}
				error={!!errorMessage}
				{...restProps}
				className={classnames({
					[`${CLASSNAME}__input`]: true,
					[props.className]: !!props.className,
					['c-basic-widget__cebia-warn']: !!cebiaError
				})}
				customInput={InputSurface}
				thousandSeparator={thousandSeparator}
				decimalSeparator={DECIMAL_SEPARATOR}
				decimalScale={0}
				allowNegative={false}
				isAllowed={(values) => {
					const { floatValue } = values

					if (floatValue !== undefined && floatValue !== null)
						return floatValue >= 0 && floatValue <= MAX_NUMBER
					else return true
				}}
				type='tel'
				tagName='input'
				getInputRef={inputRef}
				onChange={(event) => {
					const value = event.target.value
					const trimmedValue = value
						.replace(/[\s]+/g, '')
						.replace(new RegExp(DECIMAL_SEPARATOR, 'g'), '.')

					onChange({
						id: formLineEntity.id,
						value: value ? Number(trimmedValue) : undefined,
						errorMessage: ''
					})
				}}
				{...decimalProps}
			/>
			{unit && (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				<span className={`${CLASSNAME}__unit`} onClick={() => inputRef.current.focus()}>
					{unit}
				</span>
			)}
		</div>
	)
}

NumberWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string
}

export default NumberWidget
