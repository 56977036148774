import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { StatusMessage } from '@inzeraty/components'
import FormStatusMessageWrap from '../../component/formStatusMessage/FormStatusMessageWrap'
import select from 'ima-plugin-select'
import { Surface, Button } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import EQUIPMENT_STATE_KEYS from '../EquipmentStateKeys'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../../AbstractNewAdvertStateKeys'
import EQUIPMENT_STATUS_CODES from '../EquipmentStatusCodes'
import EquipmentWidget, { EQUIPMENT_WIDGET_ID } from './widgets/equipment/EquipmentWidget'
import FormStepper from '../../component/stepper/FormStepperView'
import EquipmentPlaceholders from './placeholders/EquipmentPlaceholders'
import PropTypes from 'prop-types'
import AdvertPropTypes from 'app/model/advert/AdvertPropTypes'

import '../../component/stepper/FormStepperCS'
import './EquipmentFormCS.json'
import './EquipmentForm.less'

const CLASSNAME = 'c-equipment-form'

const { Form } = FormLines

const WIDGETS_BY_ID = Object.freeze({
	[EQUIPMENT_WIDGET_ID]: EquipmentWidget
})

const EquipmentForm = (props) => {
	const localize = useLocalize()

	const {
		advertEntity,
		formLineEntity,
		onChange,
		isFormBeingSubmitted = false,
		submitForm,
		scrollPageToTop,
		formStatus,
		setFormStatus,
		isEquipmentFromCebiaBeingLoaded,
		loadEquipmentFromCebia
	} = props

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			[formLineEntity],
			{
				onChange
			},
			WIDGETS_BY_ID
		)
	}

	const renderSubmitButton = () => {
		return <FormStepper loading={isFormBeingSubmitted} />
	}

	const renderFormContent = (renderWidgets, renderSubmitButton) => {
		return (
			<>
				<Surface surface={5} className={`${CLASSNAME}__form`}>
					<h1 className={`${CLASSNAME}__heading`}>{localize('EquipmentForm.equipmentHeading')}</h1>

					<FormStatusMessageWrap>
						{renderFormStatusMessage()}
						{renderBoxForLoadingDataFromCebia()}
					</FormStatusMessageWrap>

					{formLineEntity ? renderWidgets() : <EquipmentPlaceholders />}
				</Surface>

				{renderSubmitButton()}
			</>
		)
	}

	const onSubmit = () => {
		submitForm({
			formLineEntity,
			onErrorCallback: onSubmitFormError
		})
	}

	const onSubmitFormError = (errors) => {
		// doslo k nezname chybe, napr. chyba site, ...
		setFormStatus({
			statusCode: EQUIPMENT_STATUS_CODES.VALIDATION_ERRORS_UNKNOWN
		})

		scrollPageToTop()
	}

	const renderFormStatusMessage = () => {
		if (formStatus && formStatus.statusCode === EQUIPMENT_STATUS_CODES.VALIDATION_ERRORS_UNKNOWN) {
			return (
				<StatusMessage
					type={StatusMessage.TYPE.ERROR}
					title={localize('EquipmentForm.statusValidationErrorsUnknown')}
				/>
			)
		}
	}

	const renderBoxForLoadingDataFromCebia = () => {
		const { vin } = advertEntity || {}

		return (
			Boolean(vin && vin.trim()) && (
				<StatusMessage
					type={StatusMessage.TYPE.INFO}
					title={localize('EquipmentForm.statusCebiaDataLoad')}
				>
					<p>{localize('EquipmentForm.statusCebiaDataLoadDescription')}</p>

					<Button
						className={`${CLASSNAME}__cebia-button`}
						text={localize('EquipmentForm.statusCebiaDataLoadButton')}
						loading={isEquipmentFromCebiaBeingLoaded}
						onClick={() => loadEquipmentFromCebia(vin)}
					/>
				</StatusMessage>
			)
		)
	}

	return (
		<Form
			formLineEntities={[]}
			isSending={isFormBeingSubmitted}
			renderWidgets={renderWidgets}
			renderFormContent={renderFormContent}
			onSubmit={onSubmit}
			renderSubmitButton={renderSubmitButton}
		/>
	)
}

const selectors = (state) => ({
	advertEntity: state[ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY],
	formLineEntity: state[EQUIPMENT_STATE_KEYS.FORM_LINE],
	onChange: state[EQUIPMENT_STATE_KEYS.ON_CHANGE],

	isFormBeingSubmitted: state[EQUIPMENT_STATE_KEYS.IS_FORM_BEING_SUBMITTED],
	submitForm: state[EQUIPMENT_STATE_KEYS.SUBMIT_FORM],

	formStatus: state[EQUIPMENT_STATE_KEYS.FORM_STATUS],
	setFormStatus: state[EQUIPMENT_STATE_KEYS.SET_FORM_STATUS],

	isEquipmentFromCebiaBeingLoaded: state[EQUIPMENT_STATE_KEYS.IS_EQUIPMENT_FROM_CEBIA_BEING_LOADED],
	loadEquipmentFromCebia: state[EQUIPMENT_STATE_KEYS.LOAD_EQUIPMENT_FROM_CEBIA],

	scrollPageToTop: state[EQUIPMENT_STATE_KEYS.SCROLL_PAGE_TO_TOP]
})

EquipmentForm.propTypes = {
	advertEntity: PropTypes.shape(AdvertPropTypes),
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	onChange: PropTypes.func,
	isFormBeingSubmitted: PropTypes.bool,
	submitForm: PropTypes.func,
	formStatus: PropTypes.object,
	setFormStatus: PropTypes.func,
	isEquipmentFromCebiaBeingLoaded: PropTypes.bool,
	loadEquipmentFromCebia: PropTypes.func,
	scrollPageToTop: PropTypes.func
}

export default React.memo(select(selectors)(EquipmentForm))
