import cebiaToFormLines from './cebiaToFormLines'

/**
 * Pro formlines na vstupu vrati pouze ty formlines, pro ktere
 * jsme schopni nacist data z Cebie.
 *
 * @public
 * @method filterFormLinesForCebia
 * @param {Array<FormLineEntity>} formLinesEntities formlines formulare
 * @return {Array<FormLineEntity>} formlines formulare, ktere se tykaji Cebie
 */
const filterFormLinesForCebia = (formLineEntities) => {
	const requiredFormLineIds = formLineEntities.filter((f) => f.required).map((f) => f.id)

	const cebiaFormLinesIds = cebiaToFormLines.map((c) => c.formLineId)

	// id povinnych policek, pro ktere muzeme data nacist z Cebie
	const requiredCebiaFormLinesIds = requiredFormLineIds.filter((id) =>
		cebiaFormLinesIds.includes(id)
	)

	return formLineEntities.filter((f) => requiredCebiaFormLinesIds.includes(f.id))
}

export default filterFormLinesForCebia
