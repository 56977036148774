import { Parser } from 'json2csv'
import { DownloadFile, Format } from '@inzeraty/helpers'

export default class RegistrationService {
	constructor(registrationResource, registrationFactory, paginationFactory) {
		this._registrationResource = registrationResource
		this._registrationFactory = registrationFactory
		this._paginationFactory = paginationFactory
	}

	getRegistrations(params = {}, options = {}) {
		return this._registrationResource.getRegistrations(params, options).then((response) => {
			const { results, pagination } = response.body
			const registrationsData = results.map((registration) =>
				this._registrationFactory.transformData(registration)
			)

			return {
				registrationEntities: this._registrationFactory.createEntityList(registrationsData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	getIncrases(params = {}, options = {}) {
		return this._registrationResource.getIncrases(params, options).then((response) => {
			const { results, pagination } = response.body
			const registrationsData = results.map((registration) =>
				this._registrationFactory.transformData(registration)
			)

			return {
				registrationEntities: this._registrationFactory.createEntityList(registrationsData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	_parseAndDownloadFile(data, filename = '') {
		const parser = new Parser()
		const csv = parser.parse(data)

		DownloadFile({
			content: ['\ufeff' + csv], //"\ufeff" je BOM -> pro windows fixne kodovani csv v excelu
			type: 'text/csv;charset=utf-8;',
			fileName: filename
		})
	}

	_getId(data) {
		const { id } = data
		return id
	}

	_getRegistrationId(data) {
		const { registrationId } = data
		return registrationId
	}

	_getPremiseExternalId(data) {
		const { premiseExternalId } = data
		return premiseExternalId
	}

	_getPremiseId(data) {
		const { premiseId } = data
		return premiseId
	}

	_getPremiseName(data) {
		const { premiseName } = data
		return premiseName
	}

	_getProduct(data) {
		const { registrationId, registrationModule = {} } = data

		//Pokud je registrationId, jedna se o Navyseni registrace, pokud ne, jedna se o registraci samotnou
		const PRODUCT_PREFIX = registrationId ? 'Navýšení registrace' : 'Registrace'

		return registrationModule.id !== 100
			? `${PRODUCT_PREFIX}: ${registrationModule.modules_from} - ${registrationModule.modules_to} modulů`
			: `${PRODUCT_PREFIX}: Extra`
	}

	_getRegistrationStatus(data, registrationStatus) {
		const { status } = data

		return Object.values(registrationStatus).find((option) => option.id === status)?.name ?? status
	}

	_getRegistrationIncreaseStatus(data, registrationIncreaseStatus) {
		const { status } = data

		return (
			Object.values(registrationIncreaseStatus).find((option) => option.id === status)?.name ??
			status
		)
	}

	_getDateFrom(data) {
		const { dateFrom } = data
		return Format.date(dateFrom)
	}

	_getDateTo(data) {
		const { dateTo } = data
		return Format.date(dateTo)
	}

	/**
	 * Provede stažení csv souboru se seznamem registrací.
	 *
	 * @public
	 * @param {Object} params
	 * @returns {Promise}
	 */
	async downloadRegistrationCSV(params, registrationStatus) {
		const { body: { results = [] } = {} } = await this._registrationResource.getRegistrations(
			params
		)
		const transformedData = results.map((item) => this._registrationFactory.transformData(item))

		const registrations = transformedData.map((record) => {
			const registration = {
				'ID registrace': this._getId(record),
				'Sauto ID': this._getPremiseExternalId(record),
				'ASK ID': this._getPremiseId(record),
				'Název firmy': this._getPremiseName(record),
				Produkt: this._getProduct(record),
				Stav: this._getRegistrationStatus(record, registrationStatus),
				'Datum od': this._getDateFrom(record),
				'Datum do': this._getDateTo(record)
			}
			return registration
		})

		return this._parseAndDownloadFile(registrations, 'registrations.csv')
	}

	/**
	 * Provede stažení csv souboru se seznamem navýšení registrací.
	 *
	 * @public
	 * @param {Object} params
	 * @returns {Promise}
	 */
	async downloadIncreasesCSV(params, registrationIncreaseStatus) {
		const { body: { results = [] } = {} } = await this._registrationResource.getIncrases(params)

		const transformedData = results.map((item) => this._registrationFactory.transformData(item))

		const increases = transformedData.map((record) => {
			const increase = {
				'ID navýšení': this._getId(record),
				'ID registrace': this._getRegistrationId(record),
				'Sauto ID': this._getPremiseExternalId(record),
				'ASK ID': this._getPremiseId(record),
				'Název firmy': this._getPremiseName(record),
				Produkt: this._getProduct(record),
				Stav: this._getRegistrationIncreaseStatus(record, registrationIncreaseStatus),
				'Datum od': this._getDateFrom(record),
				'Datum do': this._getDateTo(record)
			}
			return increase
		})

		return this._parseAndDownloadFile(increases, 'increases.csv')
	}
}
