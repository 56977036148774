import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'

export default class IAImporDetailController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, premiseService) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._router = this._utils.$Router
		this._premiseService = premiseService
	}

	init() {
		super.init()
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			importLogEntity: this.getEntity(),
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true,
			[AbstractInternalAdminController.stateId.GO_BACK_LIST_URL]: this._getBackUrl(
				ROUTE_NAMES.INTERNAL_ADMIN.IMPORT_LIST
			),
			filterStatusCodes: this.getFilterStatusCodes()
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true,
			filterStatusCodes: this.getFilterStatusCodes()
		}
	}

	getFilterStatusCodes() {
		return InternalAdminUrlConvertor.getStatusCodesParams(this.getRouteParams())
	}

	async getEntity() {
		const routeParams = this.getRouteParams()
		const { [InternalAdminUrlConvertor.constants.SESSION_ID]: sessionId, ...params } = routeParams

		const logParams = Object.assign({}, params)
		delete logParams[InternalAdminUrlConvertor.constants.PAGE]

		return await this._premiseService.getPremiseImportLog(sessionId, logParams)
	}

	async getEntitiesAndPagination() {
		const routeParams = this.getRouteParams()
		const {
			[InternalAdminUrlConvertor.constants.SESSION_ID]: sessionId,
			...params
		} = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = 'timestamp'
		}

		this._pageLoaderExtension.show()

		const {
			premiseEntities,
			paginationEntity
		} = await this._premiseService.getPremiseImportLogItems(sessionId, params, {
			timeout: 60000
		})

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return {
			entities: premiseEntities,
			paginationEntity
		}
	}
}
