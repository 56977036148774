import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps } from '@inzeraty/helpers'
import { CategoryEntity } from '@inzeraty/models'
import { Checkbox } from '@sznds/react'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import AdvancedBrandModel from 'app/component/filters/components/selectedItem/advancedBrandModel/AdvancedBrandModel'
import AbstractOpener from '../AbstractOpener'
import { Icon, Surface } from '@sznds/react'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import { filterItemsIgnoreSpecChars } from 'app/component/autoComplete/AutoComplete'
import ActionElm from 'app/component/actionElement/ActionElement'
import { CATEGORIES } from 'app/base/Constants'
import classnames from 'classnames'

import './AdvancedBrandModelOpener.less'
import './AdvancedBrandModelOpenerCS.json'

const CLASSNAME = 'cf-advanced-brand-model-opener'

const BRANDS_COUNT_TO_DISPLAY = 11
const BRANDS_COUNT_TO_DISPLAY_FOR_PERSONAL_CATEGORY = 15

// hodnota od kolika znacek nebudu zobrazovat zacatecni pismeno skupiny
const BRANDS_COUNT_WITHOUT_GROUP_KEY = 12
const BRANDS_COUNT_WITHOUT_GROUP_KEY_FOR_PERSONAL_CATEGORY = 16

const DEF_PROP_CATEGORY = new CategoryEntity({})

const sortArrayAlphabetically = (array) => {
	return array.sort((a, b) => a.seoName.localeCompare(b.seoName))
}

export default class AdvancedBrandModelOpener extends AbstractOpener {
	static get propTypes() {
		return Object.assign({}, AbstractOpener.propTypes, {
			categoryEntity: PropTypes.instanceOf(CategoryEntity),
			changeFilter: PropTypes.func
		})
	}

	static get defaultProps() {
		return Object.assign({}, AbstractOpener.defaultProps, {
			categoryEntity: DEF_PROP_CATEGORY,
			changeFilter: DefaultProps.FUNCTION
		})
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			showAllBrands: false
		}

		this._removeSelectedBrand = this._removeSelectedBrand.bind(this)
		this._onBrandChange = this._onBrandChange.bind(this)
		this._showAllBrands = this._showAllBrands.bind(this)
		this._onShowAllBrandsReset = this._onShowAllBrandsReset.bind(this)

		this.state = {
			filterValue: ''
		}
	}

	componentDidMount() {
		const { advertsCountForModels, advertsCountForBrands, formLineEntity } = this.props
		const { value } = formLineEntity

		this.utils.$Dispatcher.listen('showAllBrandsReset', this._onShowAllBrandsReset, this)

		if (value.size) {
			advertsCountForBrands.loadFilteredAdvertsCount()
			if (Array.from(value).some(([_, modelsValues = new Set()]) => modelsValues.size)) {
				advertsCountForModels.loadFilteredAdvertsCount()
			}
		}
	}

	componentWillUnmount() {
		this.utils.$Dispatcher.unlisten('showAllBrandsReset', this._onShowAllBrandsReset, this)
	}

	componentDidUpdate(prevProps) {
		const {
			categoryEntity: { id: currentCategoryId }
		} = this.props
		const {
			categoryEntity: { id: prevCategoryId }
		} = prevProps

		if (currentCategoryId !== prevCategoryId) {
			this.setState({
				filterValue: ''
			})
		}

		const filtersChangedSinceLastTime = (
			previousFormLineEntities = [],
			currentFormLineEntities = []
		) => {
			if (previousFormLineEntities === currentFormLineEntities) {
				return []
			} else {
				return (
					previousFormLineEntities
						.filter((previousFormLineEntity, index) => {
							const { value: prevValue } = previousFormLineEntity
							const { value: currentValue } = currentFormLineEntities[index] || {}

							return prevValue !== currentValue
						})
						.map((previousFormLineEntity) => previousFormLineEntity.id) || []
				)
			}
		}

		const { formLineEntity, filteredFormLineEntities: formLineEntities } = this.props

		const changedFilters = filtersChangedSinceLastTime(
			this._previousFormLineEntities,
			formLineEntities
		)

		const otherFiltersChangedSinceLastTime = changedFilters.filter(
			(formLineEntityId) => formLineEntityId !== formLineEntity.id
		)

		if (!this._previousFormLineEntities || otherFiltersChangedSinceLastTime.length) {
			const {
				formLineEntity: { value } = {},
				advertsCountForBrands = {},
				advertsCountForModels = {}
			} = this.props

			advertsCountForBrands.loadFilteredAdvertsCount()

			if (Array.from(value).some(([_, modelsValues = new Set()]) => modelsValues.size)) {
				advertsCountForModels.loadFilteredAdvertsCount()
			}

			this._previousFormLineEntities = [...formLineEntities]
		}
	}

	_renderOpener() {
		const {
			formLineEntity: { options, value },
			categoryEntity: { id: categoryId }
		} = this.props

		const { showAllBrands } = this.state

		const favoriteBrands = options.filter((option) => option.isFavorite)
		const allBrands = sortArrayAlphabetically([...options])

		const favoriteBrandsToDisplay = this._getFavoriteBrandsToDisplay(favoriteBrands)

		const showBrandIconInAll = CATEGORIES.PASSENGER_CARS.id === categoryId

		return (
			<React.Fragment>
				<div className={`${CLASSNAME}__opener`}>
					{this._renderFavoriteBrands(favoriteBrandsToDisplay)}
					{showAllBrands && this._renderAllBrands(allBrands, showBrandIconInAll)}
				</div>
				{value.size > 0 && this._renderSelectedBrands()}
			</React.Fragment>
		)
	}

	_isPassengerCarCategory() {
		const { categoryEntity: { name: currentCategoryName = '' } = {} } = this.props
		const { name: passengerCarsCategoryName } = CATEGORIES.PASSENGER_CARS
		return currentCategoryName === passengerCarsCategoryName
	}

	_getFavoriteBrandsToDisplay(favoriteBrands = []) {
		const isPassengerCarCategory = this._isPassengerCarCategory()

		let sortedFavoriteBrands = []
		if (isPassengerCarCategory) {
			sortedFavoriteBrands = this.utils.FilterHelper.getSortedFavoriteOptionsByFavoriteOrder(
				favoriteBrands
			)
		} else {
			sortedFavoriteBrands = this.utils.FilterHelper.getSortedFavoriteOptions(favoriteBrands)
		}

		const brandsCount = isPassengerCarCategory
			? BRANDS_COUNT_TO_DISPLAY_FOR_PERSONAL_CATEGORY
			: BRANDS_COUNT_TO_DISPLAY
		const favoriteBrandsToDisplay = sortedFavoriteBrands.slice(0, brandsCount)

		return favoriteBrandsToDisplay
	}

	_getTextAndHasValue() {
		const {
			formLineEntity: { placeholder, value }
		} = this.props
		const textValue = value.size > 0 ? this.localize('BrandModelOpener.addBrand') : placeholder

		return {
			text: textValue
		}
	}

	_renderSelectedBrands() {
		const {
			formLineEntity,
			formLineEntity: { value, options },
			changeFilter,
			context,
			dropdownDefinition = {},
			advertsCountForBrands,
			advertsCountForModels
		} = this.props
		const { openedByValue } = dropdownDefinition
		const selectedBrands = []

		value.forEach((selectedModels, key) => {
			const brand = options.find((brand) => key === brand.value)
			const isOpened = openedByValue && openedByValue === key

			selectedBrands.push(
				<AdvancedBrandModel
					key={key}
					formLineEntity={formLineEntity}
					selectedBrand={brand}
					selectedModelValues={Array.from(selectedModels)}
					onRemoveBrand={this._removeSelectedBrand}
					categorySeoName={this._getCategorySeoName()}
					changeFilter={changeFilter}
					context={context}
					isOpened={isOpened}
					advertsCountForBrands={advertsCountForBrands}
					advertsCountForModels={advertsCountForModels}
				/>
			)
		})

		// - obaleno divem kvuli css first-child
		return (
			<div className={`${CLASSNAME}__selected-brands-wrapper`}>
				<Surface surface={4} className={`${CLASSNAME}__selected-brands`}>
					{selectedBrands}
				</Surface>
			</div>
		)
	}

	_renderFavoriteBrands(favoriteBrands) {
		const {
			formLineEntity: { value: selectedBrands = new Map() }
		} = this.props
		const { showAllBrands } = this.state

		return (
			<div className={`${CLASSNAME}__brands`}>
				{favoriteBrands
					.sort((a, b) => a.name.localeCompare(b.name, 'cz'))
					.map((brand, index) => {
						const { value, seoName, name } = brand
						const isChecked = selectedBrands.has(value)

						return (
							<label
								key={value}
								className={`${CLASSNAME}__brands-item`}
								data-dot='show-values'
								data-dot-data={`{"type": "manufacturer-top", "value": "${name}", "order": "${
									index + 1
								}"}`}
							>
								<Checkbox
									className={`${CLASSNAME}__checkbox`}
									value={value}
									checked={isChecked}
									onChange={this._onBrandChange}
								/>
								<BrandIcon
									className={`${CLASSNAME}__brandicon`}
									value={value}
									seoName={seoName}
									categorySeoName={this._getCategorySeoName()}
								/>
								<div
									className={this.cssClasses({
										[`${CLASSNAME}__checked`]: isChecked
									})}
								>
									{name}
								</div>
							</label>
						)
					})}

				<div
					className={`${CLASSNAME}__brands-item`}
					data-dot='show-values'
					data-dot-data={`{"type": "all", "order": "${favoriteBrands.length + 1}"`}
				>
					<ActionElm
						onClick={this._showAllBrands}
						type='button'
						dataDot='show-all-brands'
						dataE2E='all-brands'
					>
						{showAllBrands
							? this.localize('AdvancedBrandModelOpener.hideAllBrands')
							: this.localize('AdvancedBrandModelOpener.allBrands')}
					</ActionElm>
				</div>
			</div>
		)
	}

	_renderAllBrands(brandsArray, showBrandIcon) {
		const { filterValue } = this.state
		const {
			formLineEntity: { value: selectedBrands = new Map() }
		} = this.props

		const alphabetBrandsGrous = {}

		// - vyfiltruji si znacky podle hledani
		const filteredBrandsArray = brandsArray.filter((brand) =>
			filterItemsIgnoreSpecChars(filterValue, brand.name)
		)

		// - roztridim si znacky do skupin podle zacatecniho pismene
		filteredBrandsArray.forEach((brand) => {
			const { seoName } = brand
			let firstCheracter = seoName.charAt(0)

			if (firstCheracter === 'c') {
				const secondCharacter = seoName.charAt(1)

				if (secondCharacter === 'h') {
					firstCheracter += secondCharacter
				}
			}

			if (!alphabetBrandsGrous[firstCheracter]) {
				alphabetBrandsGrous[firstCheracter] = []
			}

			alphabetBrandsGrous[firstCheracter].push(brand)
		})

		// - seradim si klice skupin znacek podle abecedy
		const alphabetBrandsGrousKeys = Object.keys(alphabetBrandsGrous).sort((a, b) =>
			a.localeCompare(b, 'cs')
		)

		const alphabetBrandsListItems = alphabetBrandsGrousKeys.reduce((flatten, groupKey) => {
			const group = alphabetBrandsGrous[groupKey] || []

			const subItems = group.map((brand, index) => {
				const { value } = brand
				const isChecked = selectedBrands.has(value)
				return this._renderAllBrandsItem(
					brand,
					isChecked,
					`${groupKey}-${index + 1}`,
					`${CLASSNAME}__brands-item`,
					showBrandIcon
				)
			})

			// pokud je vykreslovanych znacek mene nez BRANDS_COUNT_WITHOUT_GROUP_KEY, tak nebudu pridavat zacatecni pismeno skupiny
			const brandsCountWithourGroupKey = this._isPassengerCarCategory()
				? BRANDS_COUNT_WITHOUT_GROUP_KEY_FOR_PERSONAL_CATEGORY
				: BRANDS_COUNT_WITHOUT_GROUP_KEY
			if (filteredBrandsArray.length <= brandsCountWithourGroupKey) {
				return [...flatten, ...subItems]
			} else {
				const title = (
					<div key={groupKey} className={`${CLASSNAME}__brands-group-title`}>
						{groupKey.toUpperCase()}
					</div>
				)

				return [...flatten, title, ...subItems]
			}
		}, [])

		return (
			<div className={`${CLASSNAME}__brands-groups`}>
				{this._renderFilterInput()}
				{alphabetBrandsListItems.length > 0 && (
					<div className={`${CLASSNAME}__brands-columns`}>{alphabetBrandsListItems}</div>
				)}
				{alphabetBrandsListItems.length === 0 && (
					<div className={`${CLASSNAME}__brands-zero`}>
						{this.localize('AdvancedBrandModelOpener.filterZeroPart1')}
						<strong>{filterValue}</strong>
						{this.localize('AdvancedBrandModelOpener.filterZeroPart2')}
					</div>
				)}
			</div>
		)
	}

	_renderFilterInput() {
		return (
			<div className={`${CLASSNAME}__search-wrap`}>
				<AutoCompleteInput
					inputProps={{
						placeholder: this.localize('AdvancedBrandModelOpener.filterPlaceholder'),
						autoFocus: true,
						onChange: (e) => {
							this.setState({
								filterValue: e.target.value
							})
						}
					}}
					clearButtonProps={undefined}
					renderLeftIcon={() => <Icon symbol={SEARCH_OUTLINE_24} />}
				/>
			</div>
		)
	}

	_renderAllBrandsItem(brandObject, isChecked, order, className, showBrandIcon = false) {
		const { value, name, seoName } = brandObject

		return (
			<label
				key={value}
				className={classnames({
					[className]: true,
					[`${CLASSNAME}__all-brands-label`]: true,
					[`${CLASSNAME}__all-brands-label--icon`]: showBrandIcon
				})}
				data-dot='show-values'
				data-dot-data={`{"type": "manufacturer-everything", "value": "${name}", "order": "${order}"}`}
			>
				<Checkbox
					className={`${CLASSNAME}__checkbox`}
					value={value}
					checked={isChecked}
					onChange={this._onBrandChange}
				/>
				{showBrandIcon && (
					<BrandIcon
						className={`${CLASSNAME}__brandicon`}
						value={value}
						seoName={seoName}
						categorySeoName={this._getCategorySeoName()}
					/>
				)}
				<div
					className={this.cssClasses({
						[`${CLASSNAME}__item-name`]: !showBrandIcon,
						[`${CLASSNAME}__checked`]: isChecked
					})}
				>
					{name}
				</div>
			</label>
		)
	}

	_showAllBrands() {
		this.setState((prevState) => ({
			filterValue: '',
			showAllBrands: !prevState.showAllBrands
		}))
	}

	_onShowAllBrandsReset() {
		this.setState({
			showAllBrands: false
		})
	}

	_onBrandChange(event) {
		const { advertsCountForBrands } = this.props
		const { loadFilteredAdvertsCount, filteredAdvertsCount } = advertsCountForBrands
		const { value: stringValue, checked } = event.target
		const value = Number(stringValue)

		const isBrandsLoaded = Boolean(Object.keys(filteredAdvertsCount).length)

		if (!isBrandsLoaded) {
			loadFilteredAdvertsCount()
		}

		if (event) {
			event.stopPropagation()
		}

		this._onChange(value, checked)
	}

	_onChange(brandValue, checked) {
		const {
			formLineEntity: { id, value: selectedBrands = new Map() },
			changeFilter
		} = this.props

		const newFormLineEntityValue = new Map(selectedBrands)

		if (checked) {
			newFormLineEntityValue.set(brandValue, new Set())
		} else {
			newFormLineEntityValue.delete(brandValue)
		}

		changeFilter(id, newFormLineEntityValue)
	}

	_getCategorySeoName() {
		const { categoryEntity = {} } = this.props
		const { seoName } = categoryEntity

		return seoName
	}

	_removeSelectedBrand(_, brandValue) {
		const {
			formLineEntity: { value: valueDataMap, id },
			changeFilter
		} = this.props

		const getMapWithoutValue = (map, value) => {
			const newMap = new Map(map)
			newMap.delete(value)

			return newMap
		}

		changeFilter(id, getMapWithoutValue(valueDataMap, brandValue))
	}
}
