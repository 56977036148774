import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@sznds/react'
import { CLOSE_OUTLINE_16 } from '@sznds/icons'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'

import './SelectedItem.less'

const CLASSNAME = 'cf-selected-item'

export function defaultAdditionalTextBuilder(nameValueArray, selectedValueArray, noSelectionText) {
	if (selectedValueArray && selectedValueArray.length) {
		return selectedValueArray
			.map((value) => {
				const nameValue = nameValueArray.find((nameValue) => value === nameValue.value)
				return nameValue ? nameValue.name : ''
			})
			.filter((name) => !!name)
			.join(', ')
	} else {
		return noSelectionText
	}
}

export default class SelectedItem extends AbstractPureComponent {
	static get propTypes() {
		return {
			mainText: PropTypes.string.isRequired,
			additionalText: PropTypes.string.isRequired,
			renderContent: PropTypes.func,
			onClick: PropTypes.func.isRequired,
			onRemove: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			mainText: '',
			additionalText: '',
			onRemove: () => {}
		}
	}

	render() {
		const { onClick, onRemove, mainText, additionalText } = this.props

		return (
			<div className={CLASSNAME}>
				{onClick ? (
					<button
						type='button'
						className={`${CLASSNAME}__btn`}
						onClick={onClick}
						data-dot='show-values'
						data-dot-data={`{"type": "${mainText}", "value": "${additionalText}"}`}
					>
						{this._renderContent()}
					</button>
				) : (
					this._renderContent()
				)}

				<button
					type='button'
					className={`${CLASSNAME}__remove`}
					onClick={onRemove}
					data-dot='delete'
				>
					<Icon symbol={CLOSE_OUTLINE_16} />
				</button>
			</div>
		)
	}

	_renderContent() {
		const { mainText, additionalText, renderContent } = this.props

		const renderedMainText = <span className={`${CLASSNAME}__main`}>{mainText}</span>

		const renderedAdditionalText = (
			<span className={`${CLASSNAME}__additional`}>{additionalText}</span>
		)

		return renderContent ? (
			renderContent(renderedMainText, renderedAdditionalText)
		) : (
			<span className={`${CLASSNAME}__text`}>
				{renderedMainText}
				{renderedAdditionalText}
			</span>
		)
	}
}
