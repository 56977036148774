
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['CebiaPopup'] = {
  title: function(d) { return "Zvolena kategorie se neshoduje s VIN"; },
  keepIt: function(d) { return "Ponechat stávající"; },
  changeIt: function(d) { return "Změnit kategorii"; },
  close: function(d) { return "Zavřít"; },
  info: function(d) { return "Podle informací z VIN kódu vozidlo odpovídá kategorii " + d.CEBIA_CAT + ", ale Vy uvádíte kategorii " + d.USER_CAT + ". Chcete ji změnit? "; },
  infoNote: function(d) { return "V případě, že ji ponecháte, bude potřeba zkontrolovat a případně doplnit všechna data. "; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	