
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['LoginEmailWidget'] = {
  placeholder: function(d) { return "Zadejte přihlašovací email"; },
  add: function(d) { return "Přidat další přihlašovací email"; },
  remove: function(d) { return "Smazat přihlašovací email"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	