import { FORM_LINES_IDS } from './ClientProfileFormLineIds'
import { CONTACT_INPUT_NAMES } from './widgets/contacts/ContactsConstants'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './ClientProfileCS.json'

// server nam vraci error code a fields, coz je pole s idenifikatory
// formularovych prvku, kterych se tato chyba tyka. Pro slozitejsi
// formularove prvky nemusi identifikator ve fields odpovidat
// identifikatoru formline.
export const SPECIAL_ERROR_TO_FORM_LINE_ID_MAPPING = {
	locality: FORM_LINES_IDS.ADDRESS
}

export const getErrorDictionary = (dictionary) => {
	const localize = (key) => {
		if (dictionary instanceof Function) {
			return dictionary(key)
		} else {
			return dictionary.get(key)
		}
	}

	return {
		[FORM_LINES_IDS.NAME]: {
			[ERROR_CODES.REQUIRED]: localize('ClientProfile.nameRequired'),
			[ERROR_CODES.NOT_VALID]: localize('ClientProfile.nameNotValid'),
			[ERROR_CODES.UNKNOWN]: localize('ClientProfile.nameNotValid')
		},
		[FORM_LINES_IDS.DESCRIPTION]: {
			[ERROR_CODES.REQUIRED]: localize('ClientProfile.descriptionRequired'),
			[ERROR_CODES.NOT_VALID]: localize('ClientProfile.descriptionNotValid'),
			[ERROR_CODES.UNKNOWN]: localize('ClientProfile.descriptionNotValid')
		},

		[FORM_LINES_IDS.OPENING_HOURS_DESCRIPTION]: {
			[ERROR_CODES.REQUIRED]: localize('ClientProfile.openingOursDescriptionRequired'),
			[ERROR_CODES.NOT_VALID]: localize('ClientProfile.openingOursDescriptionNotValid'),
			[ERROR_CODES.UNKNOWN]: localize('ClientProfile.openingOursDescriptionNotValid')
		},

		[FORM_LINES_IDS.CONTACT_EMAILS]: {
			[CONTACT_INPUT_NAMES.CONTACT_EMAIL]: {
				[ERROR_CODES.REQUIRED]: localize('ClientProfile.emailRequired'),
				[ERROR_CODES.NOT_VALID]: localize('ClientProfile.emailNotValid'),
				[ERROR_CODES.UNKNOWN]: localize('ClientProfile.emailNotValid')
			},
			[CONTACT_INPUT_NAMES.CONTACT_NAME]: {
				[ERROR_CODES.REQUIRED]: localize('ClientProfile.emailNameRequired'),
				[ERROR_CODES.NOT_VALID]: localize('ClientProfile.emailNameNotValid'),
				[ERROR_CODES.UNKNOWN]: localize('ClientProfile.emailNameNotValid')
			}
		},
		[FORM_LINES_IDS.CONTACT_WEB]: {
			[ERROR_CODES.REQUIRED]: localize('ClientProfile.webRequired'),
			[ERROR_CODES.NOT_VALID]: localize('ClientProfile.webNotValid'),
			[ERROR_CODES.UNKNOWN]: localize('ClientProfile.webNotValid')
		},
		[FORM_LINES_IDS.CONTACT_PHONES]: {
			[CONTACT_INPUT_NAMES.CONTACT_PHONE]: {
				[ERROR_CODES.REQUIRED]: localize('ClientProfile.phoneRequired'),
				[ERROR_CODES.NOT_VALID]: localize('ClientProfile.phoneNotValid'),
				[ERROR_CODES.UNKNOWN]: localize('ClientProfile.phoneNotValid')
			},
			[CONTACT_INPUT_NAMES.CONTACT_NAME]: {
				[ERROR_CODES.REQUIRED]: localize('ClientProfile.phoneNameRequired'),
				[ERROR_CODES.NOT_VALID]: localize('ClientProfile.phoneNameNotValid'),
				[ERROR_CODES.UNKNOWN]: localize('ClientProfile.phoneNameNotValid')
			}
		},

		[FORM_LINES_IDS.ADDRESS]: {
			[ERROR_CODES.REQUIRED]: localize('ClientProfile.addressRequired'),
			[ERROR_CODES.NOT_VALID]: localize('ClientProfile.addressNotValid'),
			[ERROR_CODES.UNKNOWN]: localize('ClientProfile.addressNotValid')
		},
		[FORM_LINES_IDS.WALLET]: {
			[ERROR_CODES.REQUIRED]: localize('ClientProfile.walletNotValid'),
			[ERROR_CODES.NOT_VALID]: localize('ClientProfile.walletNotValid'),
			[ERROR_CODES.UNKNOWN]: localize('ClientProfile.walletNotValid')
		}
	}
}
