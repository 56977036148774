import React, { useState } from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import { Tag } from '@inzeraty/components'
import { CategoryEntity } from '@inzeraty/models'
import { Input, Button } from '@sznds/react'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import * as FormLines from '@inzeraty/form-lines'
import FiltersExtension from 'app/component/filters/FiltersExtension'

import './Phrase.less'

const CLASSNAME = 'cf-phrase'

const Phrase = ({
	formLineEntity,
	changeFilter,
	setFilteredAdvertsCountTotal,
	loadFilteredAdvertsCountTotal,
	categoryEntity,
	getFilterUrlParams
}) => {
	const { id, placeholder, value = '' } = formLineEntity
	const [phrase, setPhrase] = useState('')

	const change = (phrase = '') => {
		changeFilter(id, phrase, async (newFormLineEntity) => {
			const urlParams = getFilterUrlParams(newFormLineEntity)
			const total = await loadFilteredAdvertsCountTotal(urlParams, categoryEntity)
			setFilteredAdvertsCountTotal(total)
		})
	}

	return (
		<div className={CLASSNAME}>
			{Boolean(value) && (
				<div className={`${CLASSNAME}__tags`}>
					<Tag
						surface={1}
						size='small'
						onClick={() => {
							change()
						}}
						data-dot='delete'
						data-dot-data={`{"value": "${value}"}`}
					>
						{value}
					</Tag>
				</div>
			)}
			<form
				className={`${CLASSNAME}__line`}
				onSubmit={(event) => {
					event.preventDefault()
					if (phrase.trim() !== '') {
						change(phrase)
						setPhrase('')
					}
				}}
			>
				<Input
					placeholder={placeholder}
					size='small'
					className={`${CLASSNAME}__input`}
					value={phrase}
					onChange={(event) => {
						setPhrase(event.target.value)
					}}
				/>
				<Button surface={1} size='small' icon={SEARCH_OUTLINE_24} type='submit' />
			</form>
		</div>
	)
}

Phrase.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	changeFilter: PropTypes.func,

	loadFilteredAdvertsCountTotal: PropTypes.func,
	setFilteredAdvertsCountTotal: PropTypes.func,
	categoryEntity: PropTypes.instanceOf(CategoryEntity),
	getFilterUrlParams: PropTypes.func
}

const selectors = (state) => ({
	loadFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT_TOTAL],
	setFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.SET_FILTERED_ADVERTS_COUNT_TOTAL],
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	getFilterUrlParams: state[FiltersExtension.stateIds.GET_URL_PARAMS]
})

export default select(selectors)(Phrase)
