import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Tag } from '@inzeraty/components'

export default class ModelTag extends AbstractPureComponent {
	static get propTypes() {
		return {
			brandValue: PropTypes.number.isRequired,
			modelValue: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
			getValues: PropTypes.func,
			id: PropTypes.string,
			onOpen: PropTypes.func,
			deleteTag: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			brandValue: -1,
			modelValue: -1,
			text: '',
			onOpen: DEFAULT_PROPS.FUNCTION,
			deleteTag: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._openFilterPopup = this._openFilterPopup.bind(this)
		this._deleteTag = this._deleteTag.bind(this)
	}

	render() {
		const { text } = this.props

		return (
			<Tag
				size='small'
				onClick={this._deleteTag}
				data-dot='delete'
				data-dot-data={`{"value": "${text}"}`}
			>
				{text}
			</Tag>
		)
	}

	_openFilterPopup() {
		const { onOpen, brandValue, modelValue } = this.props

		onOpen(brandValue, modelValue)
	}

	_deleteTag() {
		const { id, brandValue, modelValue, getValues, deleteTag } = this.props

		const value = getValues(id)

		if (value.has(brandValue)) {
			const selectedModelsForBrand = new Set(value.get(brandValue))
			selectedModelsForBrand.delete(modelValue)

			const newFormLineEntityValue = new Map(value)
			newFormLineEntityValue.set(brandValue, selectedModelsForBrand)

			deleteTag(newFormLineEntityValue)
		}
	}
}
