import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Popup } from '@inzeraty/components'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import { PRICE_LEVELS_MAPPING, getDictionaryText } from '../CarCompare'
import ImaContext from 'ima/page/context'

import './InfoPopupCS.json'
import '../CarCompareCS.json'
import './InfoPopup.less'

const CLASSNAME = 'c-car-compare-popup'

const InfoPopup = (props) => {
	const { onClose } = props

	const localize = useLocalize()
	const { $Dictionary } = useContext(ImaContext)

	return (
		<div className={CLASSNAME}>
			<Popup
				title={localize('CarCompare.info')}
				hasLightbox={true}
				isOpen={true}
				onClose={onClose}
				className={`${CLASSNAME}__popup`}
			>
				<div className={`${CLASSNAME}__price-info`}>
					{Object.keys(PRICE_LEVELS_MAPPING).map((level) => {
						const levelVal = PRICE_LEVELS_MAPPING[level]

						return (
							!!levelVal && (
								<div key={`${levelVal}__popup`} className={`${CLASSNAME}__item`}>
									<div
										className={classnames({
											[`${CLASSNAME}__price-info-point`]: true,
											[`${CLASSNAME}__level--${levelVal}`]: !!levelVal
										})}
									></div>
									<div className={`${CLASSNAME}__info-text-price`}>
										<div className={`${CLASSNAME}__info-text-price-value`}>
											{getDictionaryText(`CarCompare.${levelVal}`, $Dictionary, localize)}
										</div>
										<div className={`${CLASSNAME}__info-text-price-info`}>
											{getDictionaryText(`InfoPopup.${levelVal}_info`, $Dictionary, localize)}
										</div>
									</div>
								</div>
							)
						)
					})}
				</div>
			</Popup>
		</div>
	)
}

InfoPopup.propTypes = {
	onClose: PropTypes.func.isRequired
}

export default React.memo(InfoPopup)
