import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import RegionDistrictOpener from 'app/component/filters/forms/main/openers/regionOpener/RegionOpener'
import AdvertListRegionDistrictOpener from 'app/component/filters/forms/main/openers/regionOpener/AdvertListRegionDistrictOpener'
import RegionForm from 'app/component/filters/forms/region/RegionForm'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import SearchFilterPopup from './shared/SearchFilterPopup'
import FilterPopupWithOpener from './shared/FilterPopupWithOpener'
import DependentPopupsOpenerLogic from 'app/component/filters/logic/openers/DependentPopupsOpenerLogic'
import RegionDistrictFilterLogic from 'app/component/filters/logic/RegionDistrictFilterLogic'
import ScopedFormLineEntity from 'app/component/filters/logic/ScopedFormLineEntity'
import DependentPopupsLogic from 'app/component/filters/logic/modals/DependentPopupsLogic'
import FilterPopupButton from './shared/FilterPopupButton'
import UndoablePopup from './shared/UndoablePopup'
import RegionDistrictForm from 'app/component/filters/forms/region/RegionDistrictForm'
import FilterConstants from 'app/model/filter/FilterConstants'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'

import './RegionDistrictFilterPopupWithOpenerCS.json'

class RegionDistrictFilterPopupWithOpener extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			popupDefinition: PropTypes.object,
			context: PropTypes.string.isRequired,
			value: PropTypes.number, //TODO lepsi nazev? regionValue mozna
			filterIndex: PropTypes.number,
			renderOpener: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderOpener = this._renderOpener.bind(this)
		this._renderPopup = this._renderPopup.bind(this)

		this._renderRegionForm = this._renderRegionForm.bind(this)
	}

	render() {
		const { popupDefinition, context, value: regionValue } = this.props

		return (
			<FilterPopupWithOpener
				popupDefinition={popupDefinition}
				context={context}
				value={regionValue}
				renderOpener={this._renderOpener}
				renderPopup={this._renderPopup}
			/>
		)
	}

	_renderOpener() {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			categoryEntity,
			context,
			filterIndex,
			renderOpener
		} = this.props

		return (
			<RegionDistrictFilterLogic
				formLineEntity={globalFormLineEntity}
				changeFilter={globalChangeFilter}
				context={context}
			>
				{(regionDistrictFilterLogic) => {
					const {
						form: { changeFilter },
						advertsCountForAll
					} = regionDistrictFilterLogic

					return (
						<DependentPopupsOpenerLogic
							formLineEntity={globalFormLineEntity}
							changeFilter={changeFilter}
							context={context}
						>
							{(popupOpenerLogicData) => {
								const {
									openMainPopup: openAllRegionsPopup,
									openDependentPopup: openRegionDistrictsPopup,
									clearDependentFilter: clearRegionDistrictsFilter
								} = popupOpenerLogicData

								return renderOpener ? (
									renderOpener(popupOpenerLogicData)
								) : context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL ||
								  context === FILTER_CONTEXT.EXTENDED_FILTER ? (
									<AdvertListRegionDistrictOpener
										formLineEntity={globalFormLineEntity}
										changeFilter={changeFilter}
										advertsCount={advertsCountForAll}
									/>
								) : (
									<RegionDistrictOpener
										formLineEntity={globalFormLineEntity}
										context={context}
										filterIndex={filterIndex}
										categoryEntity={categoryEntity}
										onOpen={openAllRegionsPopup}
										onOpenRegionDistricts={openRegionDistrictsPopup}
										clearRegionDistrictsFilter={clearRegionDistrictsFilter}
									/>
								)
							}}
						</DependentPopupsOpenerLogic>
					)
				}}
			</RegionDistrictFilterLogic>
		)
	}

	_renderPopup() {
		const { formLineEntity: globalFormLineEntity, openedFilterPopups, context } = this.props

		const { id } = globalFormLineEntity

		//TODO ne podle id, protoze to je region_cb
		const districtPopupDefinition = openedFilterPopups.find(
			(popup) => popup.filterId === id && popup.openedByValue > 0 && popup.context === context
		)

		return (
			<ScopedFormLineEntity formLineEntity={globalFormLineEntity}>
				{(scopedFormLineEntityChildrenProps) => {
					const { localFormLineEntity, localChangeFilter } = scopedFormLineEntityChildrenProps

					return (
						<RegionDistrictFilterLogic
							formLineEntity={localFormLineEntity}
							changeFilter={localChangeFilter}
							context={context}
							regionValue={
								districtPopupDefinition ? districtPopupDefinition.openedByValue : undefined
							}
						>
							{(filterLogicChildrenProps) => {
								return (
									<React.Fragment>
										{context !== FILTER_CONTEXT.TAGS &&
											this._renderRegionPopup(filterLogicChildrenProps)}

										{this._renderRegionDistrictPopup(filterLogicChildrenProps)}
									</React.Fragment>
								)
							}}
						</RegionDistrictFilterLogic>
					)
				}}
			</ScopedFormLineEntity>
		)
	}

	_renderRegionPopup(filterLogicChildrenProps) {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			popupDefinition,
			value
		} = this.props
		const {
			other: { formLineEntity: localFormLineEntity }
		} = filterLogicChildrenProps

		return (
			<DependentPopupsLogic
				formLineEntity={localFormLineEntity}
				changeFilter={globalChangeFilter}
				popupDefinition={popupDefinition}
				value={value}
				filterLogicData={Object.assign({}, filterLogicChildrenProps, {
					advertsCountForMainPopup: filterLogicChildrenProps.advertsCountForRegions,
					advertsCountForDependentPopup: filterLogicChildrenProps.advertsCountForDistricts
				})}
			>
				{(filterPopupLogicChildrenProps) => {
					const { mainPopup: mainPopupLogicData } = filterPopupLogicChildrenProps

					return (
						<SearchFilterPopup
							formLineEntity={globalFormLineEntity}
							title={this.localize('RegionDistrictFilterPopupWithOpener.regionTitle')}
							searchInputPlaceholder={this.localize(
								'RegionDistrictFilterPopupWithOpener.searchPlaceholder'
							)}
							value={value}
							filterPopupLogicData={mainPopupLogicData}
							renderContent={(_, searchTerm) =>
								this._renderRegionForm(
									filterPopupLogicChildrenProps,
									filterLogicChildrenProps,
									searchTerm
								)
							}
						/>
					)
				}}
			</DependentPopupsLogic>
		)
	}

	_renderRegionDistrictPopup(filterLogicChildrenProps) {
		const {
			changeFilter: globalChangeFilter,
			popupDefinition,
			value,
			context,
			openedFilterPopups
		} = this.props
		const {
			form: { changeFilter: localChangeFilter },
			other: { formLineEntity: localFormLineEntity }
		} = filterLogicChildrenProps

		const { id } = localFormLineEntity

		//TODO ne podle id, protoze to je region_cb
		const districtPopupDefinition = openedFilterPopups.find(
			(popup) => popup.filterId === id && popup.openedByValue > 0 && popup.context === context
		)

		if (districtPopupDefinition) {
			const { openedByValue: regionValue } = districtPopupDefinition

			const { options = [] } = localFormLineEntity
			const { name: regionName } = options.find((option) => option.value === regionValue) || {}

			return (
				<DependentPopupsLogic
					formLineEntity={localFormLineEntity}
					changeFilter={globalChangeFilter}
					popupDefinition={popupDefinition}
					value={value}
					dependentPopupDefinition={districtPopupDefinition}
					dependentValue={
						districtPopupDefinition ? districtPopupDefinition.openedByValue : undefined
					}
					filterLogicData={Object.assign({}, filterLogicChildrenProps, {
						advertsCountForMainPopup: filterLogicChildrenProps.advertsCountForRegions,
						advertsCountForDependentPopup: filterLogicChildrenProps.advertsCountForDistricts
					})}
				>
					{(filterPopupLogicChildrenProps) => {
						const { dependentPopup } = filterPopupLogicChildrenProps

						return (
							<UndoablePopup
								formLineEntity={localFormLineEntity}
								onChange={localChangeFilter}
								title={regionName}
								filterPopupLogicData={dependentPopup}
								renderContent={() =>
									this._renderRegionDistrictForm(
										filterPopupLogicChildrenProps,
										filterLogicChildrenProps,
										regionValue
									)
								}
								renderFooter={() =>
									this._renderButton(filterPopupLogicChildrenProps, filterLogicChildrenProps)
								}
							/>
						)
					}}
				</DependentPopupsLogic>
			)
		} else {
			return null
		}
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderRegionForm(filterPopupLogicChildrenProps, filterLogicChildrenProps, searchTerm) {
		const { context } = this.props
		const {
			advertsCountForRegions: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity, categoryEntity }
		} = filterLogicChildrenProps

		return (
			<RegionForm
				formLineEntity={formLineEntity}
				context={context}
				categoryEntity={categoryEntity}
				filteredRegionName={searchTerm}
				filteredAdvertsCount={filteredAdvertsCount}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				onRegionClick={(regionValue) => {
					this.fire('openFilterPopup', {
						filterId: formLineEntity.id,
						openedByValue: regionValue,
						context,
						filterPopupType: FilterConstants.filterPopupType.BACK,
						filterButtonType: FilterConstants.filterButtonType.CONTINUE,
						updateUrlParams: false
					})
				}}
			/>
		)
	}

	_renderRegionDistrictForm(filterPopupLogicChildrenProps, filterLogicChildrenProps, regionValue) {
		const { popupDefinition = {} } = this.props
		const { filterButtonType } = popupDefinition
		const {
			form: { changeFilter },
			advertsCountForDistricts: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity }
		} = filterLogicChildrenProps

		return (
			<RegionDistrictForm
				formLineEntity={formLineEntity}
				onChange={changeFilter}
				regionValue={regionValue}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				filteredAdvertsCount={filteredAdvertsCount}
				// pokud je videt CTA tlacitko s poctem nabidek (a textem 'Zobrazit xxx
				// nabidek'), tak nechceme v bublinach s pocty inzeratu znovu vypisovat
				// text 'xxx nabidek', ale pouze 'xxx'
				isCountShownWithAddText={filterButtonType !== FilterConstants.filterButtonType.SEARCH}
			/>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderButton(filterPopupLogicChildrenProps, filterLogicChildrenProps) {
		const { popupDefinition } = this.props
		const { dependentPopup } = filterPopupLogicChildrenProps

		return (
			<FilterPopupButton
				filterPopupLogicData={dependentPopup}
				filterFormLogicData={Object.assign({}, filterLogicChildrenProps, {
					advertsCount: filterLogicChildrenProps.advertsCountForDistricts
				})}
				popupDefinition={popupDefinition}
			/>
		)
	}
}

const selector = (state) => ({
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS]
})

export default select(selector)(RegionDistrictFilterPopupWithOpener)
