import * as FormLines from '@inzeraty/form-lines'
import FilterFromUrlAppConvertor from './filterConvertor/FilterFromUrlAppConvertor'
import FilterConstants from './FilterConstants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { isForbiddenCertifiedProgram } from 'app/helpers/forbiddenCertifiedPrograms/ForbiddenCertifiedPrograms'

export default class FilterFactory {
	constructor(codebooksFactory) {
		this._codebooksFactory = codebooksFactory
	}

	transform(data) {
		const { options = [] } = data

		return {
			widget: data.widget,
			id: data.name,
			label: data.text,
			value: data.value,
			placeholder: data.placeholder,
			options: options.map((option) => {
				const { children = [] } = option

				return {
					id: option.id,
					value: option.value,
					name: option.name,
					seoName: option.seo_name,
					seoOrder: option.seo_order,
					seoTitle: option.seo_title || option.name || '',
					isFavorite: option.is_favorite,
					favoriteOrder: option.favorite_order,
					valueFrom: option.value_from,
					valueTo: option.value_to,
					children: children.map((child) =>
						Object.assign(
							{},
							this._codebooksFactory.transformData(child),
							!isNullOrUndefined(child.seo_order) && {
								seoOrder: child.seo_order,
								seoTitle: child.seo_title || child.name || ''
							}
						)
					),
					colorCode: option.color_code,
					category: option.category
				}
			}),
			extra: {
				unit: data.unit,
				emptyValue: data.emptyValue,
				inline: data.inline || false,
				filterSection: data.filterSection
			}
		}
	}

	getFormLineEntities(data = [], urlParams = {}) {
		const transformedData = data.map((formLineData) => {
			let overrideData = {}

			if (formLineData.name === FilterConstants.formLineIds.PHRASE) {
				overrideData = FilterFromUrlAppConvertor.checkPhraseParams(urlParams, formLineData)
			} else if (formLineData.name === FilterConstants.formLineIds.BRAND_MODEL) {
				overrideData = FilterFromUrlAppConvertor.checkBrandModelParams(urlParams, formLineData)
			} else if (formLineData.name === FilterConstants.formLineIds.REGION_DISTRICT) {
				overrideData = FilterFromUrlAppConvertor.checkRegionDistrictParams(urlParams, formLineData)
			} else if (formLineData.name === FilterConstants.formLineIds.AGE) {
				overrideData = FilterFromUrlAppConvertor.checkFixedFromToParams(urlParams, formLineData)
			} else if (formLineData.name === FilterConstants.formLineIds.ITEM_AGE) {
				overrideData = FilterFromUrlAppConvertor.checkItemAgeParams(urlParams, formLineData)
			} else if (
				formLineData.name === FilterConstants.formLineIds.PRICE ||
				formLineData.name === FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT ||
				formLineData.name === FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE ||
				formLineData.name === FilterConstants.formLineIds.OPERATING_LEASE_PERIOD ||
				formLineData.name === FilterConstants.formLineIds.TACHOMETER ||
				formLineData.name === FilterConstants.formLineIds.PERFORMANCE ||
				formLineData.name === FilterConstants.formLineIds.DISPLACEMENT ||
				formLineData.name === FilterConstants.formLineIds.FUEL_CONSUMPTION ||
				formLineData.name === FilterConstants.formLineIds.AIRBAGS ||
				formLineData.name === FilterConstants.formLineIds.CAPACITY ||
				formLineData.name === FilterConstants.formLineIds.DOORS ||
				formLineData.name === FilterConstants.formLineIds.WEIGHT ||
				formLineData.name === FilterConstants.formLineIds.BEDS ||
				formLineData.name === FilterConstants.formLineIds.MACHINE_HOURS
			) {
				overrideData = FilterFromUrlAppConvertor.checkVariableFromToParams(urlParams, formLineData)
			} else if (
				formLineData.name === FilterConstants.formLineIds.CONDITION ||
				formLineData.name === FilterConstants.formLineIds.OPERATING_LEASE_SERVICES ||
				formLineData.name === FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR ||
				formLineData.name === FilterConstants.formLineIds.FUEL ||
				formLineData.name === FilterConstants.formLineIds.VEHICLE_BODY ||
				formLineData.name === FilterConstants.formLineIds.GEARBOX ||
				formLineData.name === FilterConstants.formLineIds.COUNTRY_OF_ORIGIN ||
				formLineData.name === FilterConstants.formLineIds.VENDOR ||
				formLineData.name === FilterConstants.formLineIds.AIR_CONDITIONING ||
				formLineData.name === FilterConstants.formLineIds.CERTIFIED_PROGRAM ||
				formLineData.name === FilterConstants.formLineIds.COLOR ||
				formLineData.name === FilterConstants.formLineIds.BUS_SEATS ||
				formLineData.name === FilterConstants.formLineIds.PRICE_OPTION ||
				formLineData.name === FilterConstants.formLineIds.HISTORY_INFO ||
				formLineData.name === FilterConstants.formLineIds.EQUIPMENT ||
				formLineData.name === FilterConstants.formLineIds.DRIVE ||
				formLineData.name === FilterConstants.formLineIds.OTHER_SPECIFICATION
			) {
				overrideData = FilterFromUrlAppConvertor.checkNamedParams(urlParams, formLineData)
			}

			if (formLineData.name === FilterConstants.formLineIds.CERTIFIED_PROGRAM) {
				const overrideCertifiedOptions = (formLineData.options || []).filter(
					({ value }) => !isForbiddenCertifiedProgram(value)
				)
				overrideData = Object.assign({}, overrideData, { options: overrideCertifiedOptions })
			}

			return this.transform(Object.assign({}, formLineData, overrideData))
		})

		const implementedIds = Object.values(FilterConstants.formLineIds)

		return (
			FormLines.createEntityList(transformedData)
				// Vyfiltruje a vrátí seznam formLineEntities, pro které máme hotovou implementaci filtrů.
				.filter((formLineEntity) => implementedIds.includes(formLineEntity.id))
		)
	}

	updateFormLineEntities(formLineEntities, updates) {
		return FormLines.updateEntities(formLineEntities, updates)
	}

	/**
	 * Upraví a vrátí formLineEntities filtru rozšířené o modely dané značky.
	 *
	 * @method updateFormLineEntitiesModels
	 * @public
	 * @param {Array<FormLineEntity>}  formLineEntities  Upravované formLineEntities filtru.
	 * @param {Number}                 brandId           Id značky.
	 * @param {Array<CodebooksEntity>} codebooksEntities Seznam modelů dané značky.
	 * @return {Array<FormLineEntity>} Upravené formLineEntities filtru.
	 */
	updateFormLineEntitiesModels(formLineEntities, brandId, codebooksEntities = []) {
		const id = FilterConstants.formLineIds.BRAND_MODEL
		const { options = [] } = formLineEntities.find((formLineEntity) => id === formLineEntity.id)

		const updatedOptions = options.map((option) => {
			const updatedOption = Object.assign({}, option)

			if (updatedOption.id === brandId) {
				updatedOption.children = codebooksEntities
			}

			return updatedOption
		})

		return this.updateFormLineEntities(formLineEntities, [
			{
				id,
				options: updatedOptions
			}
		])
	}
}
