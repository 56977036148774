import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'

const percentageGrowthCalc = (amount1, amount2) => {
	if (!isNullOrUndefined(amount1) && !isNullOrUndefined(amount2)) {
		if (amount1 !== 0) {
			return ((amount2 - amount1) / Math.abs(amount1)) * 100
		} else {
			if (amount2 === 0) {
				// zmenu z nuly na nulu povazujeme jako prirustek 0%
				return 0
			} else if (amount2 > 0) {
				// zmenu z nuly na kladne cislo povazujeme jako prirustek 100%
				return 100
			} else {
				// zmenu z nuly na zaporne cislo povazujeme jako prirustek -100%
				return -100
			}
		}
	} else {
		return NaN
	}
}

export default percentageGrowthCalc
