import { createEntityList, updateEntities } from '@inzeraty/form-lines'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { FILTERS } from 'app/component/entitiesFilter/EntitiesFilterExtension'
import { nonValue, nonValueOption } from 'app/component/entitiesFilter/EntitiesFilter'
import EntityFormExtension from 'app/component/entityForm/EntityFormExtension'

export default class IAArticlesDetailController extends AbstractInternalAdminController {
	constructor(
		dependenciesHelper,
		articlesService,
		entitiesFilterExtension,
		entityFormExtension,
		filterService,
		codebooksService
	) {
		super(dependenciesHelper)

		this._articlesService = articlesService
		this._entitiesFilterExtension = entitiesFilterExtension
		this._router = this._utils.$Router
		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
		this._entityFormExtension = entityFormExtension
		this._filterService = filterService
		this._codebooksService = codebooksService

		// Slouží k uložení informací o codeboocích pro danou kategorii
		this._tmpCategoryCodebooks = {
			forCategory: null,
			entities: []
		}
	}

	static get SOURCE() {
		return {
			novinky: 'Novinky.cz',
			garaz: 'Garáž.cz'
		}
	}

	static get IS_SHOWN() {
		return {
			yes: 'Ano',
			no: 'Ne'
		}
	}

	static get ACTIONS() {
		return Object.freeze({
			OPEN_EDIT_POPUP: 'openEditPopup',
			CLOSE_EDIT_POPUP: 'closeEditPopup',
			DELETE: 'delete'
		})
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)
		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)

		this.addExtension(this._entityFormExtension)
		this._entityFormExtension.initialize({
			getSubmitPromise: this._getEditFormSubmitPromise.bind(this),
			handleSubmitError: this._handleEditFormSubmitError.bind(this),
			handleSubmitSuccess: this._handleEditFormSubmitSuccess.bind(this),
			onEntityFormChangeCallback: this._onEntityFormChangeCallback.bind(this)
		})
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		})
	}

	async getEntitiesAndPagination() {
		const pageLoaderId = this._pageLoaderExtension.show()

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)
		const entitiesAndPagination = await this._articlesService.getArticlesDetail(params)

		this._pageLoaderExtension.hide(pageLoaderId)

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		}
	}

	_filterInitiezerClb() {
		return createEntityList([
			this._entitiesFilterExtension.getSelect(
				'is_shown',
				'Zobrazeno',
				'Vyberte',
				Object.keys(IAArticlesDetailController.IS_SHOWN).map((isShownKey) => {
					return {
						value: isShownKey,
						name: IAArticlesDetailController.IS_SHOWN[isShownKey]
					}
				})
			),
			this._entitiesFilterExtension.getSelect(
				'source',
				'Zdroj',
				'Vyberte',
				Object.keys(IAArticlesDetailController.SOURCE).map((sourceKey) => {
					return {
						value: sourceKey,
						name: IAArticlesDetailController.SOURCE[sourceKey]
					}
				})
			)
		])
	}

	_getEditFormSubmitPromise(formData) {
		const {
			fuel_cb,
			manufacture_year_from,
			manufacture_year_to,
			vehicle_body_cb,
			engine_volume,
			model_cb,
			category_id,
			is_shown,
			id
		} = formData

		const data = Object.assign(
			{},
			{
				is_shown,
				category_id: Number(category_id),
				model_id: Number(model_cb),
				filters: {
					fuel_cb: Number(fuel_cb),
					manufacture_year_from:
						manufacture_year_from > 0 ? Number(manufacture_year_from) : undefined,
					manufacture_year_to: manufacture_year_to > 0 ? Number(manufacture_year_to) : undefined,
					vehicle_body_cb: Number(vehicle_body_cb),
					engine_volume: engine_volume > 0 ? Number(engine_volume) : undefined
				}
			}
		)

		return this._articlesService.editArticle(id, data)
	}

	_handleEditFormSubmitError() {
		return {
			entityFormError: {
				title: 'Nepodařilo se odeslat formulář.'
			}
		}
	}

	async _processSuccessAction() {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	_handleEditFormSubmitSuccess() {
		this._processSuccessAction()
		this.setState({
			isEditPopupOpened: false
		})
	}

	async _onEntityFormChangeCallback(data) {
		const {
			[EntityFormExtension.STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities
		} = this.getState()
		let updatedFilterFormLineEntities = updateEntities(formLineEntities, [data])

		if (data.id === FILTERS.CATEGORY.ID) {
			await this._setCategoryCodebooks(data.value)
		}

		// Pokud se změní kategorie, tak vymaže hodnotu pro výrobce.
		if (data.id === FILTERS.CATEGORY.ID) {
			updatedFilterFormLineEntities = updateEntities(updatedFilterFormLineEntities, [
				{
					id: FILTERS.MANUFACTURER.ID,
					value: undefined
				}
			])
		}

		// Pokud se změní kategorie nebo výrobce, tak vymaže hodnotu pro model.
		if (data.id === FILTERS.CATEGORY.ID || data.id === FILTERS.MANUFACTURER.ID) {
			updatedFilterFormLineEntities = updateEntities(updatedFilterFormLineEntities, [
				{
					id: FILTERS.MODEL.ID,
					value: undefined
				}
			])
		}

		this.setState({
			[EntityFormExtension.STATE_KEYS.FORM_LINE_ENTITIES]: updateEntities(
				updatedFilterFormLineEntities,
				await this._updateDependentSelects(updatedFilterFormLineEntities, data.id)
			)
		})
	}

	async getFormLineEntities(entity, title) {
		const pageLoaderId = this._pageLoaderExtension.show()
		const routeParams = this.getRouteParams()
		//Dotaz na filter_page abych mohl dotahnout data do editacniho formulare (karoserie, palivo, ...)
		const filters = await this._filterService.getFormLineEntities(
			Object.assign({}, entity, { category_id: routeParams[FILTERS.CATEGORY.ID] })
		)
		if (filters) {
			let formLineEntities = this._getFormLineEntitiesForEditForm(entity, filters)
			// U filtrů, kde se používá kategorie nastaví číselníky pro tuto kategorii.
			await this._setCategoryCodebooks(routeParams[FILTERS.CATEGORY.ID])
			formLineEntities = await this._updateDependentSelects(formLineEntities)
			this._entityFormExtension.setFormLineEntitiesData(formLineEntities)
			this.setState({
				isEditPopupOpened: true,
				entityFormError: null,
				popupTitle: title
			})
		}
		this._pageLoaderExtension.hide(pageLoaderId)
	}

	_handleAction({ action, entities = [], entitiesIds = [] }) {
		const [entity = {}] = entities
		const { id, title = '' } = entity

		let errorMessage = ''
		let promise = {}

		switch (action) {
			case IAArticlesDetailController.ACTIONS.OPEN_EDIT_POPUP:
				this.getFormLineEntities(entity, title)
				return {}
			case IAArticlesDetailController.ACTIONS.CLOSE_EDIT_POPUP:
				this.setState({
					isEditPopupOpened: false
				})
				return {}

			case IAArticlesDetailController.ACTIONS.DELETE:
				if (window.confirm('Opravdu si přejete smazat tento článek?')) {
					promise = this._articlesService.deleteArticle(id)
					errorMessage = 'Nepodařilo se smazat článek.'
				} else {
					return {}
				}
		}

		return {
			promise,
			getErrorMessage: () => errorMessage
		}
	}

	_getFormLineEntitiesForEditForm(articlesDetailEntity = {}, filters) {
		const vehicleBody = this._getFormLineEntityFromFilters(filters, 'vehicle_body_cb')
		const fuel = this._getFormLineEntityFromFilters(filters, 'fuel_cb')

		const defaultValue = { value: 0, name: 'Nevybráno' }
		const formLineEntities = []
		formLineEntities.push(
			...this._getDependantCategoryManufacturerModel(),
			{
				widget: IDS.SELECT,
				label: vehicleBody.label,
				id: vehicleBody.id,
				options: [defaultValue, ...vehicleBody.options],
				value: articlesDetailEntity?.filters?.vehicle_body_cb
			},
			{
				widget: IDS.TEXT,
				id: 'manufacture_year_from',
				label: 'Rok výroby od',
				value: articlesDetailEntity?.filters?.manufacture_year_from
			},
			{
				widget: IDS.TEXT,
				id: 'manufacture_year_to',
				label: 'Rok výroby do',
				value: articlesDetailEntity?.filters?.manufacture_year_to
			},
			{
				widget: IDS.SELECT,
				label: fuel.label,
				id: fuel.id,
				options: [defaultValue, ...fuel.options],
				value: articlesDetailEntity?.filters?.fuel_cb
			},
			{
				widget: IDS.TEXT,
				id: 'engine_volume',
				label: 'Objem',
				value: articlesDetailEntity?.filters?.engine_volume
			},
			{
				widget: IDS.RADIO,
				id: 'is_shown',
				label: 'Zobrazeno:',
				value: articlesDetailEntity?.isShown,
				options: [
					{
						id: 'yes',
						label: 'Ano',
						value: true
					},
					{
						id: 'no',
						label: 'Ne',
						value: false
					}
				]
			},
			{
				id: 'id',
				value: articlesDetailEntity?.id,
				widget: IDS.HIDDEN
			}
		)

		return formLineEntities
	}

	_getFormLineEntityFromFilters(formLineEntities, searchId) {
		return formLineEntities.find((formLineEntity) => formLineEntity.id === searchId)
	}

	_getDependantCategoryManufacturerModel() {
		const routeParams = this.getRouteParams()
		return [
			this.getSelect(
				FILTERS.CATEGORY.ID,
				FILTERS.CATEGORY.NAME,
				FILTERS.CATEGORY.PLACEHOLDER,
				FILTERS.CATEGORY.OPTIONS,
				routeParams[FILTERS.CATEGORY.ID]
			),
			this.getSelect(
				FILTERS.MANUFACTURER.ID,
				FILTERS.MANUFACTURER.NAME,
				FILTERS.MANUFACTURER.PLACEHOLDER,
				[],
				routeParams[FILTERS.MANUFACTURER.ID]
			),
			this.getSelect(
				FILTERS.MODEL.ID,
				FILTERS.MODEL.NAME,
				FILTERS.MODEL.PLACEHOLDER,
				[],
				routeParams[FILTERS.MODEL.ID]
			)
		]
	}

	async _updateDependentSelects(formLineEntities, changeFilterId) {
		let updatedFormLineEntities = formLineEntities

		const updateAll = changeFilterId === undefined

		// Provede update závislých filtrů pro kategorii, výrobce a model (pokud jsou použity).
		if (
			updatedFormLineEntities.find(({ id }) => id === FILTERS.CATEGORY.ID) &&
			updatedFormLineEntities.find(({ id }) => id === FILTERS.MANUFACTURER.ID) &&
			updatedFormLineEntities.find(({ id }) => id === FILTERS.MODEL.ID) &&
			(updateAll ||
				changeFilterId === FILTERS.CATEGORY.ID ||
				changeFilterId === FILTERS.MANUFACTURER.ID ||
				changeFilterId === FILTERS.MODEL.ID)
		) {
			updatedFormLineEntities = await this._updateCategoryManufacturerModel(updatedFormLineEntities)
		}

		return updatedFormLineEntities
	}

	async _updateCategoryManufacturerModel(formLineEntities) {
		let updatedFilterFormLineEntities = formLineEntities

		// Řeší update filtru výrobců, který je závislý na filtru kategorie
		updatedFilterFormLineEntities = await this._updateCodebooksSelect(
			updatedFilterFormLineEntities,
			FILTERS.MANUFACTURER,
			FILTERS.CATEGORY.ID,
			false
		)

		// Řeší update filtru modelů, který je závislý na filtru výrobců
		updatedFilterFormLineEntities = await this._updateCodebooksSelect(
			updatedFilterFormLineEntities,
			FILTERS.MODEL,
			FILTERS.MANUFACTURER.ID,
			true
		)

		return updatedFilterFormLineEntities
	}

	async _updateCodebooksSelect(formLineEntities, actualFilter, parentId, useParentId) {
		let updatedFormLineEntities = formLineEntities
		const { value, placeholder } = updatedFormLineEntities.find(({ id }) => id === actualFilter.ID)
		const parent = updatedFormLineEntities.find(({ id }) => id === parentId)
		const { value: parentValue } = parent

		if (typeof parentValue === 'undefined' || parentValue === nonValue) {
			updatedFormLineEntities = updateEntities(updatedFormLineEntities, [
				this._getDisabledSelect(actualFilter.ID, actualFilter.NAME, actualFilter.PLACEHOLDER)
			])
		} else {
			const { id, name, nameId } = this._getCodeBookEntity(actualFilter.ID)

			const params = {
				codebook_id: id,
				limit: 1000
			}
			if (useParentId) {
				const { id: parentId } = parent.options.find(({ value }) => value === Number(parentValue))
				params.parent_id = parentId
			}

			const codebooksEntities = await this._codebooksService.getCodebooks(params)
			const select = this.getSelect(
				nameId,
				name,
				placeholder,
				codebooksEntities.map(({ id, value, name }) => ({
					id,
					value,
					name
				}))
			)

			updatedFormLineEntities = updateEntities(updatedFormLineEntities, [
				Object.assign(select, {
					value
				})
			])
		}

		return updatedFormLineEntities
	}

	_getDisabledSelect(id, label, placeholder) {
		return {
			widget: IDS.SELECT,
			id,
			label,
			placeholder,
			options: [nonValueOption],
			value: undefined,
			extra: {
				disabled: true
			}
		}
	}

	getSelect(id, label, placeholder, options = [], value = '', hasNoValueOption = true) {
		const selectOptions = [...options]

		if (hasNoValueOption) {
			selectOptions.unshift(nonValueOption)
		}

		return {
			widget: IDS.SELECT,
			id,
			label,
			placeholder,
			value: value,
			options: selectOptions,
			extra: {}
		}
	}

	async _setCategoryCodebooks(category) {
		const { forCategory, entities } = this._tmpCategoryCodebooks

		if (typeof category === 'undefined' || category === nonValue) {
			this._tmpCategoryCodebooks = {
				forCategory: null,
				entities: []
			}
			return []
		} else if (category === forCategory) {
			return entities
		} else {
			const newEntities = await this._codebooksService.getCategoryCodebooks(category)
			this._tmpCategoryCodebooks = {
				forCategory: category,
				entities: newEntities
			}
			return newEntities
		}
	}

	_getCodeBookEntity(id) {
		const { entities } = this._tmpCategoryCodebooks
		return entities.find(({ nameId }) => nameId === id)
	}
}
