import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import Context from 'ima/page/context'
import RouterEvents from 'ima/router/Events'
import Downshift from 'downshift'
import { InputSurface } from '@sznds/react'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import { SEARCH_OUTLINE_24, CLOSE } from '@sznds/icons'
import { Icon } from '@sznds/react'
import PropTypes from 'prop-types'
import RibbonSearchExtension from './RibbonSearchExtension'
import select from 'ima-plugin-select'
import { CATEGORIES } from 'app/base/Constants'
import ROUTE_NAMES from 'app/base/RouteNames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import classnames from 'classnames'
import RibbonSuggestionToUrlAppParamsConvertor from './RibbonSuggestionToUrlAppParamsConvertor'
import { SEARCH_TYPE_SESSION } from 'app/base/StorageIds'
import ImaContext from 'ima/page/context'

import './RibbonSearch.less'
import './RibbonSearchCS.json'

const CLASSNAME = 'c-ribbon-search'

const RibbonSearch = ({ suggestions = DEFAULT_PROPS.ARRAY }) => {
	const localize = useLocalize()
	const fire = useFire()
	const [searchValue, setSearchValue] = useState('')
	const [isMenuOpen, setIsMenuOpen] = useState(suggestions.length > 0)
	const { $Dispatcher, $Router } = useContext(Context)
	const inputElement = useRef()
	const { $SessionStorage } = useContext(ImaContext)

	const setUsedFulltextSession = (suggestedValue) => {
		$SessionStorage.set(SEARCH_TYPE_SESSION, {
			searchType: 'fulltext',
			query: suggestedValue ?? searchValue,
			lastUrlRouteParams: $Router.getCurrentRouteInfo().params
		})
	}

	const handleChange = (value) => {
		setSearchValue(value ? value : '')
		if (value) {
			if (value.length > 50) return
			fire('suggest', { phrase: value })
			setIsMenuOpen(true)
		} else {
			setIsMenuOpen(false)
		}
	}

	useEffect(() => {
		$Dispatcher.listen(RouterEvents.BEFORE_HANDLE_ROUTE, handleRouteChange)

		return () => {
			$Dispatcher.unlisten(RouterEvents.BEFORE_HANDLE_ROUTE, handleRouteChange)
		}
	}, [])

	const handleRouteChange = useCallback((pageData) => {
		try {
			const routeName = pageData.route.getName()
			if (routeName !== ROUTE_NAMES.USERWEB.ADVERT_LIST) {
				setSearchValue('')
			}
		} catch (e) {
			// na chybu nebudeme nijak reagovat
		}
	}, [])

	const getCategoryName = (categoryId) => {
		const { name = '' } =
			Object.values(CATEGORIES).find((category) => category.id === categoryId) || {}
		return name
	}

	const stateReducer = (state, changes) => {
		if (
			changes.type === Downshift.stateChangeTypes.keyDownArrowUp ||
			changes.type === Downshift.stateChangeTypes.keyDownArrowDown
		) {
			setSearchValue(suggestions[changes?.highlightedIndex]?.sentence || '')
		}
		return changes
	}

	const search = (value, searchFn) => {
		setSearchValue(value)
		fire('resetCache')
		inputElement.current.blur()
		setIsMenuOpen(false)

		searchFn()
	}

	const handleSubmit = (value) => {
		search(value, () => {
			setUsedFulltextSession()
			fire('search', { phrase: value })
		})
	}

	const handleSelectionChange = (selection) => {
		const { sentence = '', categories = [], modelCb = {}, manufacturerCb = {} } = selection || {}
		const manufacturerName = manufacturerCb?.seo_name
		const modelName = modelCb?.seo_name

		if (manufacturerName && modelName) {
			search(sentence, () => {
				setUsedFulltextSession(manufacturerName + ' ' + modelName)
				$Router.redirect(
					$Router.link(
						ROUTE_NAMES.USERWEB.ADVERT_LIST,
						RibbonSuggestionToUrlAppParamsConvertor.convert(
							manufacturerName,
							modelName,
							categories[0]
						)
					)
				)
			})
		} else {
			search(sentence, () => {
				setUsedFulltextSession()
				fire('search', { phrase: sentence, category: categories[0] })
			})
		}
	}

	return (
		<Downshift
			onChange={handleSelectionChange}
			itemToString={(item) => (item ? item.sentence : '')}
			onOuterClick={() => setIsMenuOpen(false)}
			inputValue={searchValue}
			stateReducer={stateReducer}
		>
			{({
				getInputProps,
				getItemProps,
				getMenuProps,
				inputValue,
				highlightedIndex,
				getRootProps
			}) => (
				<div className={CLASSNAME}>
					<div
						className={`${CLASSNAME}__suggest-input-wrapper`}
						{...getRootProps({}, { suppressRefError: true })}
					>
						<InputSurface className={`${CLASSNAME}__suggest-input-surface`}>
							<input
								className={`${CLASSNAME}__suggest-input`}
								placeholder={localize('RibbonSearch.placeholder')}
								value={searchValue}
								{...getInputProps({
									onChange: (event) => {
										handleChange(event.target.value)
									},
									onKeyDown: (event) => {
										event.keyCode === 13 && highlightedIndex === null && handleSubmit(inputValue)
									}
								})}
								ref={inputElement}
							/>
							{searchValue && (
								<div className={`${CLASSNAME}__reset-btn-wrap`}>
									<button
										className={`${CLASSNAME}__reset-btn`}
										title={localize('RibbonSearch.delete')}
										onClick={() => {
											setSearchValue('')
											inputElement.current.focus()
											setIsMenuOpen(false)
										}}
									>
										<Icon symbol={CLOSE} />
									</button>
								</div>
							)}
							<button
								className={`${CLASSNAME}__submit-btn`}
								title={localize('RibbonSearch.search')}
								onClick={() => {
									handleSubmit(inputValue)
								}}
							>
								<Icon symbol={SEARCH_OUTLINE_24} />
							</button>
						</InputSurface>
					</div>
					{isMenuOpen && suggestions.length > 0 && (
						<ul className={`${CLASSNAME}__suggest-menu`} {...getMenuProps()}>
							{suggestions.map((item, index) => (
								<li
									className={classnames({
										[`${CLASSNAME}__options`]: true,
										[`${CLASSNAME}__options--highlighted`]: highlightedIndex === index
									})}
									key={item}
									{...getItemProps({
										index,
										item
									})}
								>
									<div className={`${CLASSNAME}__suggest-text`}>{item.sentence}</div>
									<div className={`${CLASSNAME}__categories`}>
										{getCategoryName(item?.categories[0])}
									</div>
								</li>
							))}
						</ul>
					)}
				</div>
			)}
		</Downshift>
	)
}

RibbonSearch.propTypes = {
	suggestions: PropTypes.array
}

const RibbonSearchSelector = (state) => ({
	suggestions: state[RibbonSearchExtension.STATE_KEYS.SUGGESTIONS]
})

export default select(RibbonSearchSelector)(RibbonSearch)
