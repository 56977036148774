import { Format } from '@inzeraty/helpers'
import AdvertListMeta from './AdvertListMeta'

import 'app/base/BaseCS.json'
import './AdvertListOperatingLeaseMetaCS.json'

export const getAppliedFilters = (dictionary, filterFormLineEntities = []) => {
	const brandAndModelsFilter = AdvertListMeta.getAppliedOptionsForManufacturerFilter(
		filterFormLineEntities
	)

	const restFilters = [
		...AdvertListMeta.getAppliedOptionsForMultiOptionFilters(filterFormLineEntities),
		...AdvertListMeta.getAppliedOptionsForRangeFilters(dictionary, filterFormLineEntities)
	]

	return [brandAndModelsFilter, restFilters]
}

export const printAppliedFilters = (brandAndModelsFilter = [], restFilters = []) => {
	const brandAndModelsFilterText = AdvertListMeta.convertToText(brandAndModelsFilter, ' ')
	const restFiltersText = AdvertListMeta.convertToText(restFilters, ', ')

	return [brandAndModelsFilterText, restFiltersText].filter((text) => !!text).join(' ')
}

export default class AdvertListOperatingLeaseMeta {
	static getTitleValue(dictionary, filterFormLineEntities = []) {
		const [brandAndModelsFilter, restFilters] = getAppliedFilters(
			dictionary,
			filterFormLineEntities
		)

		const filtersText = printAppliedFilters(brandAndModelsFilter, restFilters)

		if (filtersText) {
			return dictionary.get('AdvertListOperatingLeaseMeta.title', {
				FILTERS: filtersText
			})
		} else {
			return dictionary.get('AdvertListOperatingLeaseMeta.titleNoFilters')
		}
	}

	static getDescriptionValue(dictionary, filterFormLineEntities = [], filteredAdvertsCountTotal) {
		const operatingLeaseCountText = dictionary.get('AdvertListOperatingLeaseMeta.operatingLease', {
			COUNT: filteredAdvertsCountTotal,
			COUNT_FORMATTED: Format.number(filteredAdvertsCountTotal)
		})

		const [brandAndModelsFilter, restFilters] = getAppliedFilters(
			dictionary,
			filterFormLineEntities
		)

		const filtersText = printAppliedFilters(brandAndModelsFilter, restFilters)

		if (filtersText) {
			return dictionary.get('AdvertListOperatingLeaseMeta.description', {
				OPER_LEASE_COUNT_TEXT: operatingLeaseCountText,
				FILTERS: filtersText
			})
		} else {
			return dictionary.get('AdvertListOperatingLeaseMeta.descriptionNoFilters', {
				OPER_LEASE_COUNT_TEXT: operatingLeaseCountText
			})
		}
	}
}
