import React from 'react'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import PropTypes from 'prop-types'
import { UserPropTypes } from '@inzeraty/models'
import EntityForm from 'app/component/entityForm/EntityForm'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHead from 'app/component/tableHead/TableHead'
import EmptyList from 'app/component/emptyList/EmptyList'
import { Format } from '@inzeraty/helpers'
import { USER_HISTORY_STATUSES } from 'app/base/Constants'
import { PaginationEntity } from '@inzeraty/models'

import './IAUserEditView.less'

const CLASSNAME = 'p-ia-user-edit'

const IAUserEditView = ({
	userEntity,
	isLoading = false,
	formLineEntities = DEFAULT_PROPS.ARRAY,
	entityFormError,
	isWaiting,
	tableIsLoading,
	entitiesAndPagination
}) => {
	const { login = '...' } = userEntity || {}

	const renderContent = () => {
		return (
			<>
				<EntityForm
					className={`${CLASSNAME}__content`}
					formLineEntities={formLineEntities}
					entityFormError={entityFormError}
					isWaiting={isWaiting}
				/>

				<h3 className={`${CLASSNAME}__second-title`}>Historie s účtem</h3>
				<div>
					<div className={`${CLASSNAME}__history`}>
						<EntitiesTable
							entitiesAndPagination={entitiesAndPagination}
							headCells={[
								<TableHead key='status'>Stav</TableHead>,
								<TableHead key='user'>Uživatel</TableHead>,
								<TableHead key='changedBy'>Důvod změny stavu</TableHead>,
								<TableHead key='date'>Datum</TableHead>
							]}
							rowCells={[
								({ action }) =>
									USER_HISTORY_STATUSES[action] ? USER_HISTORY_STATUSES[action] : action,
								({ changedBy }) => (changedBy ? changedBy.match(/[^(1-9)]+/g)[0] : ''),
								({ change }) => change.note,
								({ time }) => Format.dateTime(time)
							]}
							renderEmptyBody={() => (
								<EmptyList title='Nenašli jsme žadnou historii s účtem' text='' />
							)}
							isLoading={tableIsLoading}
						/>
					</div>
				</div>
			</>
		)
	}

	return (
		<Layout title={`Detail uživatele ${isLoading ? '...' : login}`}>
			{!isLoading && userEntity && renderContent()}
		</Layout>
	)
}

IAUserEditView.propTypes = {
	userEntity: PropTypes.shape(UserPropTypes),
	isLoading: PropTypes.bool,
	tableIsLoading: PropTypes.bool,
	isWaiting: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.object),
		pagination: PropTypes.instanceOf(PaginationEntity)
	})
}

export default IAUserEditView
