import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'

import './ImgCountView.less'

const CLASSNAME = 'c-img-count'

/**
 * @class ImgCountView
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.component
 */
export default class ImgCountView extends AbstractComponent {
	static get propTypes() {
		return {
			text: PropTypes.string.isRequired
		}
	}

	static get defaultProps() {
		return {}
	}

	constructor(props, context) {
		super(props, context)
	}

	render() {
		const { text } = this.props

		return (
			<span className={CLASSNAME}>
				<span className={`${CLASSNAME}__icon`} />
				<span className={`${CLASSNAME}__text`}>{text}</span>
			</span>
		)
	}
}
