import React from 'react'
import { StatusMessage } from '@inzeraty/components'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import PropTypes from 'prop-types'
import ActionElm from 'app/component/actionElement/ActionElement'
import classnames from 'classnames'

import './Toast.less'

const CLASSNAME = 'c-toast'

const Toast = ({
	removeToast = DEFAULT_PROPS.FUNCTION,
	links = DEFAULT_PROPS.ARRAY,
	text = '',
	wrapperClassName = '',
	...restProps
}) => {
	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[wrapperClassName]: !!wrapperClassName
			})}
		>
			<StatusMessage {...restProps} style={StatusMessage.STYLE.TOAST} onClose={removeToast}>
				<p className={`${CLASSNAME}__text`}>{text}</p>
				{links.map(({ tagProps, text }, index) => (
					<ActionElm {...tagProps} key={index}>
						{text}
					</ActionElm>
				))}
			</StatusMessage>
		</div>
	)
}

Toast.propTypes = {
	removeToast: PropTypes.func,
	links: PropTypes.array,
	text: PropTypes.string,
	wrapperClassName: PropTypes.string
}

export default React.memo(Toast)
