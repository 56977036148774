/**
 * @class ArticlesEntity
 * @description Obsahuje data pro porovnání článků
 * @module models
 */
export default class ArticlesEntity {
	constructor(data) {
		this.categoryId = data.categoryId
		this.articlesCount = data.articlesCount
		this.manufacturerCb = data.manufacturerCb
		this.modelCb = data.modelCb
	}
}
