import React from 'react'
import PropTypes from 'prop-types'
import { HorizontalScrollingMenu } from '@inzeraty/components'
import { DefaultProps } from '@inzeraty/helpers'
import { CategoryEntity } from '@inzeraty/models'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import BrandModels from 'app/component/filters/components/selectedItem/brandModels/BrandModelsView'
import AbstractOpener from '../AbstractOpener'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'

import './BrandModelOpener.less'
import './BrandModelOpenerCS.json'

const CLASSNAME = 'cf-brand-model-opener'

const DEF_PROP_CATEGORY = new CategoryEntity({})

export default class BrandModelOpener extends AbstractOpener {
	static get propTypes() {
		return Object.assign({}, AbstractOpener.propTypes, {
			context: PropTypes.string,
			categoryEntity: PropTypes.instanceOf(CategoryEntity),
			onOpenBrandModels: PropTypes.func,
			clearBrandModelsFilter: PropTypes.func
		})
	}

	static get defaultProps() {
		return Object.assign({}, AbstractOpener.defaultProps, {
			categoryEntity: DEF_PROP_CATEGORY,
			onOpenBrandModels: DefaultProps.FUNCTION,
			clearBrandModelsFilter: DefaultProps.FUNCTION
		})
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			brandCarouselSelectedSlideIndex: 0
		}

		this._onFavoriteBrandClick = this._onFavoriteBrandClick.bind(this)
		this._onSelectedBrandClick = this._onSelectedBrandClick.bind(this)

		this._openAllBrandsModal = this._openAllBrandsModal.bind(this)
		this._removeSelectedBrand = this._removeSelectedBrand.bind(this)

		this._calcSpacingBetweenSlides = this._calcSpacingBetweenSlides.bind(this)
		this._renderPreviousButton = this._renderPreviousButton.bind(this)
		this._renderNextButton = this._renderNextButton.bind(this)
		this._onBrandCarouselSelectedSlideChange = this._onBrandCarouselSelectedSlideChange.bind(this)
		this._onBrandCarouselReset = this._onBrandCarouselReset.bind(this)
	}

	componentDidMount() {
		this.utils.$Dispatcher.listen('brandCarouselReset', this._onBrandCarouselReset, this)
	}

	componentWillUnmount() {
		this.utils.$Dispatcher.unlisten('brandCarouselReset', this._onBrandCarouselReset, this)
	}

	componentDidUpdate(prevProps) {
		const {
			categoryEntity: { id: previousCategoryId }
		} = prevProps
		const {
			categoryEntity: { id: currentCategoryId }
		} = this.props

		if (previousCategoryId !== currentCategoryId) {
			this.setState({
				brandCarouselSelectedSlideIndex: 0
			})
		}
	}

	_renderOpener() {
		const {
			formLineEntity: { value, label }
		} = this.props

		return (
			<React.Fragment>
				<div>{label}</div>
				{value.size ? this._renderSelectedBrands() : this._renderBrandCarousel()}

				{super._renderOpener()}
			</React.Fragment>
		)
	}

	_getTextAndHasValue() {
		const {
			formLineEntity: { placeholder, value }
		} = this.props
		const textValue = value.size > 0 ? this.localize('BrandModelOpener.addBrand') : placeholder

		return {
			text: textValue
		}
	}

	_renderSelectedBrands() {
		const {
			formLineEntity: { value, options }
		} = this.props
		const selectedBrands = []

		value.forEach((selectedModels, key) => {
			const brand = options.find((brand) => key === brand.value)

			selectedBrands.push(
				<BrandModels
					key={key}
					brandValue={key}
					brand={brand.name}
					models={brand.children}
					selectedModelIds={Array.from(selectedModels)}
					onClick={this._onSelectedBrandClick}
					onRemove={this._removeSelectedBrand}
					seoName={brand.seoName}
					categorySeoName={this._getCategorySeoName()}
				/>
			)
		})

		// - obaleno divem kvuli css first-child
		return <div>{selectedBrands}</div>
	}

	_renderBrandCarousel() {
		const {
			formLineEntity: { options },
			context
		} = this.props
		const { brandCarouselSelectedSlideIndex } = this.state

		const favoriteBrands = this.utils.FilterHelper.getSortedFavoriteOptions(options)

		if (context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL) {
			return null
		} else {
			return (
				<HorizontalScrollingMenu
					className={`${CLASSNAME}__carousel`}
					selectedSlideIndex={brandCarouselSelectedSlideIndex}
					onSelectedSlideIndexChange={this._onBrandCarouselSelectedSlideChange}
					onSlidesRearrange={this._onBrandCarouselReset}
					spacingBetweenSlides={this._calcSpacingBetweenSlides}
					alignLastSlideToRight={true}
					renderPreviousButton={this._renderPreviousButton}
					renderNextButton={this._renderNextButton}
					dataDot='scroll-component'
				>
					{favoriteBrands.map((brand, index) => {
						const { value, seoName, name } = brand

						return (
							<div
								key={value}
								className={`${CLASSNAME}__carousel-item`}
								data-dot='show-values'
								data-dot-data={`{"type": "manufacturer-top", "value": "${name}", "order": "${
									index + 1
								}"}`}
							>
								<BrandIcon
									onClick={this._onFavoriteBrandClick}
									value={value}
									seoName={seoName}
									categorySeoName={this._getCategorySeoName()}
								/>
							</div>
						)
					})}

					<div
						className={`${CLASSNAME}__carousel-item`}
						data-dot='show-values'
						data-dot-data={`{"type": "all", "order": "${favoriteBrands.length + 1}"`}
					>
						<BrandIcon svgIcon='LIST' onClick={this._openAllBrandsModal} />
					</div>
				</HorizontalScrollingMenu>
			)
		}
	}

	_getCategorySeoName() {
		const { categoryEntity = {} } = this.props
		const { seoName } = categoryEntity

		return seoName
	}

	_calcSpacingBetweenSlides(containerHeight) {
		const defaultSpace =
			(12 / 52) *
			containerHeight /* chceme, aby spacing byl 12px ->
		(16px okraj stranky - 4px padding na ikonce). Vyska kontejneru je 52px. */

		return (slideIndex) =>
			slideIndex === 0
				? { left: 0, right: defaultSpace }
				: { left: defaultSpace, right: defaultSpace }
	}

	_renderPreviousButton(currentSlideIndex, slidesTotal, showPreviousSlide) {
		const { context } = this.props
		const usedFromExtendedFilters = context === FILTER_CONTEXT.EXTENDED_FILTER

		if (currentSlideIndex > 0) {
			return (
				<button
					className={this.cssClasses({
						[`${CLASSNAME}__previous-button`]: true,
						[`${CLASSNAME}__previous-button--dark`]: usedFromExtendedFilters
					})}
					onClick={showPreviousSlide}
					tabIndex='-1'
					aria-hidden='true'
					data-dot='previous'
				>
					{this.localize('BrandModelOpener.previous')}
				</button>
			)
		} else {
			return (
				<div
					className={this.cssClasses({
						[`${CLASSNAME}__gradient-left`]: true,
						[`${CLASSNAME}__gradient-left--dark`]: usedFromExtendedFilters
					})}
				/>
			)
		}
	}

	_renderNextButton(currentSlideIndex, slidesTotal, showNextSlide) {
		const { context } = this.props
		const usedFromExtendedFilters = context === FILTER_CONTEXT.EXTENDED_FILTER

		if (slidesTotal > 1 && currentSlideIndex < slidesTotal - 1) {
			return (
				<button
					className={this.cssClasses({
						[`${CLASSNAME}__next-button`]: true,
						[`${CLASSNAME}__next-button--dark`]: usedFromExtendedFilters
					})}
					onClick={showNextSlide}
					tabIndex='-1'
					aria-hidden='true'
					data-dot='next'
				>
					{this.localize('BrandModelOpener.next')}
				</button>
			)
		} else {
			return (
				<div
					className={this.cssClasses({
						[`${CLASSNAME}__gradient-right`]: true,
						[`${CLASSNAME}__gradient-right--dark`]: usedFromExtendedFilters
					})}
				/>
			)
		}
	}

	_openAllBrandsModal() {
		const { onOpen: openAllBrandsModal } = this.props

		openAllBrandsModal()
	}

	_onSelectedBrandClick(_, brandValue) {
		this._openAllBrandsAndBrandModelsModal(brandValue)
	}

	_onFavoriteBrandClick(_, brandValue) {
		this.utils.FilterHelper.increaseFavoriteBrandClick(brandValue)
		this._openAllBrandsAndBrandModelsModal(brandValue)
	}

	_openAllBrandsAndBrandModelsModal(brandValue) {
		const {
			formLineEntity: { options = [] },
			context,
			onOpenBrandModels
		} = this.props
		const { id: brandId } = options.find((option) => option.value === brandValue)

		this.fire('downloadModels', {
			brandId
		})

		// TODO tohle nechceme volat ani pro popup, zbytecne otevirame
		if (context !== FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL) {
			this._openAllBrandsModal()
		}

		onOpenBrandModels(brandValue)
	}

	_removeSelectedBrand(_, brandValue) {
		const {
			formLineEntity: { value: valueDataMap },
			clearBrandModelsFilter
		} = this.props

		const getMapWithoutValue = (map, value) => {
			const newMap = new Map(map)
			newMap.delete(value)

			return newMap
		}

		clearBrandModelsFilter(getMapWithoutValue(valueDataMap, brandValue))

		// v DOMu muze byt vice BrandModelOpeneru zaroven, toto je zpusob komunikace mezi nimi.
		// Pri smazani znacky chceme vyresetovat vsechny carousely znacek ve vsech BrandModelOpenerech.
		this.utils.$Dispatcher.fire('brandCarouselReset')
	}

	_onBrandCarouselSelectedSlideChange(newSlideIndex) {
		this.setState({
			brandCarouselSelectedSlideIndex: newSlideIndex
		})
	}

	_onBrandCarouselReset() {
		this.setState({
			brandCarouselSelectedSlideIndex: 0
		})
	}
}
