import RouteNames from 'app/base/RouteNames'

import Error500Controller from 'app/page/error/Error500Controller'
import Error4xxController from 'app/page/error/Error4xxController'
import Error from 'app/page/error/Error'

import UserwebHomeController from 'app/page/userweb/home/HomeController'
import UserwebHomeView from 'app/page/userweb/home/HomeView'

import UserwebAdvertListController from 'app/page/userweb/advertList/AdvertListController'
import UserwebAdvertListView from 'app/page/userweb/advertList/AdvertListView'

import UserwebAdvertDetailController from 'app/page/userweb/advertDetail/AdvertDetailController'
import UserwebAdvertDetailView from 'app/page/userweb/advertDetail/AdvertDetailView'

import BoardBehindWindscreenController from 'app/page/userweb/boardBehindWindscreen/BoardBehindWindscreenController'
import BoardBehindWindscreenView from 'app/page/userweb/boardBehindWindscreen/BoardBehindWindscreenView'

import HomeUrlConvertor from 'app/page/userweb/home/HomeUrlConvertor'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import ArticlesUrlConvertor from 'app/page/userweb/articles/ArticlesUrlConvertor'

import UserwebArticlesController from 'app/page/userweb/articles/ArticlesController'
import UserwebArticlesView from 'app/page/userweb/articles/ArticlesView'

import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import BrandsCatalogController from 'app/page/userweb/catalog/brandsCatalog/BrandsCatalogController'
import BrandsCatalogView from 'app/page/userweb/catalog/brandsCatalog/BrandsCatalogView'

import ModelsCatalogController from 'app/page/userweb/catalog/modelsCatalog/ModelsCatalogController'
import ModelsCatalogView from 'app/page/userweb/catalog/modelsCatalog/ModelsCatalogView'

import ModelLinesOverviewCatalogController from 'app/page/userweb/catalog/modelLinesOverviewCatalog/ModelLinesOverviewController'
import ModelLinesOverviewView from 'app/page/userweb/catalog/modelLinesOverviewCatalog/ModelLinesOverviewView'

import ModificationDetailController from 'app/page/userweb/catalog/modificationDetail/ModificationDetailController'
import ModificationDetailView from 'app/page/userweb/catalog/modificationDetail/ModificationDetailView'

import ModelLineModificationsCatalogController from 'app/page/userweb/catalog/modelLineModifications/ModelLineModificationsController'
import ModelLineModificationsView from 'app/page/userweb/catalog/modelLineModifications/ModelLineModificationsView'

import UserwebSellerController from 'app/page/userweb/seller/SellerController'
import UserwebSellerView from 'app/page/userweb/seller/SellerView'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import SellerListController from 'app/page/userweb/sellerList/SellerListController'
import SellerListView from 'app/page/userweb/sellerList/SellerListView'
import SellerListUrlConvertor from 'app/page/userweb/sellerList/SellerListUrlConvertor'

import UserwebAddVINController from 'app/page/userweb/newAdvert/addVIN/AddVINController'
import UserwebAddVINView from 'app/page/userweb/newAdvert/addVIN/AddVINView'

import UserwebBasicInfoController from 'app/page/userweb/newAdvert/basicInfo/BasicInfoController'
import UserwebBasicInfoView from 'app/page/userweb/newAdvert/basicInfo/BasicInfoView'

import UserwebPhotosController from 'app/page/userweb/newAdvert/photos/PhotosController'
import UserwebPhotosView from 'app/page/userweb/newAdvert/photos/PhotosView'

import UserwebHistoryController from 'app/page/userweb/newAdvert/history/HistoryController'
import UserwebHistoryView from 'app/page/userweb/newAdvert/history/HistoryView'

import UserwebEquipmentController from 'app/page/userweb/newAdvert/equipment/EquipmentController'
import UserwebEquipmentView from 'app/page/userweb/newAdvert/equipment/EquipmentView'

import UserwebPaymentController from 'app/page/userweb/newAdvert/payment/PaymentController'
import UserwebPaymentView from 'app/page/userweb/newAdvert/payment/PaymentView'

import UserwebPaymentConfirmationController from 'app/page/userweb/newAdvert/paymentConfirmation/PaymentConfirmationController'
import UserwebPaymentConfirmationView from 'app/page/userweb/newAdvert/paymentConfirmation/PaymentConfirmationView'

import ClientAdvertListController from 'app/page/userweb/clientAdvertList/ClientAdvertListController'
import ClientAdvertListView from 'app/page/userweb/clientAdvertList/ClientAdvertListView'

import MySavedSearchController from 'app/page/userweb/mySavedSearch/MySavedSearchController'
import MySavedSearchView from 'app/page/userweb/mySavedSearch/MySavedSearchView'

import ClientProfileController from 'app/page/userweb/clientProfile/ClientProfileController'
import ClientProfileView from 'app/page/userweb/clientProfile/ClientProfileView'

import FavoriteListController from 'app/page/userweb/favoriteList/FavoriteListController'
import FavoriteListView from 'app/page/userweb/favoriteList/FavoriteListView'

import ClientStatisticsController from 'app/page/userweb/clientStatistics/ClientStatisticsController'
import ClientStatisticsView from 'app/page/userweb/clientStatistics/ClientStatisticsView'

import ToppedPaymentView from 'app/page/userweb/clientAdvertList/toppedPayment/ToppedPaymentView'
import ToppedPaymentController from 'app/page/userweb/clientAdvertList/toppedPayment/ToppedPaymentController'


import IAAdvertListController from 'app/page/internalAdmin/advertList/IAAdvertListController'
import IAAdvertListView from 'app/page/internalAdmin/advertList/IAAdvertListView'

import IAAdvertListReportedController from 'app/page/internalAdmin/advertListReported/IAAdvertListReportedController'
import IAAdvertListReportedView from 'app/page/internalAdmin/advertListReported/IAAdvertListReportedView'

import IAAdvertReportController from 'app/page/internalAdmin/advertReport/IAAdvertReportController'
import IAAdvertReportView from 'app/page/internalAdmin/advertReport/IAAdvertReportView'

import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import IAAdvertBasicInfoEditController from 'app/page/internalAdmin/advertEdit/basicInfo/IAAdvertBasicInfoEditController'
import IAAdvertBasicInfoEditView from 'app/page/internalAdmin/advertEdit/basicInfo/IAAdvertBasicInfoEditView'

import IAAdvertPhotosEditController from 'app/page/internalAdmin/advertEdit/photos/IAAdvertPhotosEditController'
import IAAdvertPhotosEditView from 'app/page/internalAdmin/advertEdit/photos/IAAdvertPhotosEditView'

import IAEquipmentController from 'app/page/internalAdmin/advertEdit/equipment/IAEquipmentController'
import IAEquipmentView from 'app/page/internalAdmin/advertEdit/equipment/IAEquipmentView'

import IAHistoryController from 'app/page/internalAdmin/advertEdit/history/IAHistoryController'
import IAHistoryView from 'app/page/internalAdmin/advertEdit/history/IAHistoryView'

import IAAdvertHistoryController from 'app/page/internalAdmin/advertHistory/IAAdvertHistoryController'
import IAAdvertHistoryView from 'app/page/internalAdmin/advertHistory/IAAdvertHistoryView'

import IAAdminListController from 'app/page/internalAdmin/adminList/IAAdminListController'
import IAAdminListView from 'app/page/internalAdmin/adminList/IAAdminListView'

import IAPremiseListController from 'app/page/internalAdmin/premiseList/IAPremiseListController'
import IAPremiseListView from 'app/page/internalAdmin/premiseList/IAPremiseListView'

import IAPremiseEditController from 'app/page/internalAdmin/premiseEdit/IAPremiseEditController'
import IAPremiseEditView from 'app/page/internalAdmin/premiseEdit/IAPremiseEditView'

import IAPremiseHistoryController from 'app/page/internalAdmin/premiseHistory/IAPremiseHistoryController'
import IAPremiseHistoryView from 'app/page/internalAdmin/premiseHistory/IAPremiseHistoryView'

import IAImportListController from 'app/page/internalAdmin/importList/IAImportListController'
import IAImportListView from 'app/page/internalAdmin/importList/IAImportListView'

import IAImportDetailController from 'app/page/internalAdmin/importDetail/IAImportDetailController'
import IAImportDetailView from 'app/page/internalAdmin/importDetail/IAImportDetailView'

import IAUserListController from 'app/page/internalAdmin/userList/IAUserListController'
import IAUserListView from 'app/page/internalAdmin/userList/IAUserListView'
import IAUserEditController from 'app/page/internalAdmin/userEdit/IAUserEditController'
import IAUserEditView from 'app/page/internalAdmin/userEdit/IAUserEditView'

import IARoleListController from 'app/page/internalAdmin/roleList/IARoleListController'
import IARoleListView from 'app/page/internalAdmin/roleList/IARoleListView'
import IARightsController from 'app/page/internalAdmin/rights/IARightsController'
import IARightsView from 'app/page/internalAdmin/rights/IARightsView'
import IAForbiddenAccessController from 'app/page/internalAdmin/forbiddenAccess/IAForbiddenAccessController'
import IAForbiddenAccessView from 'app/page/internalAdmin/forbiddenAccess/IAForbiddenAccessView'

import IAStatisticsController from 'app/page/internalAdmin/statistics/IAStatisticsController'
import IAStatisticsView from 'app/page/internalAdmin/statistics/IAStatisticsView'
import IAPaymentsController from 'app/page/internalAdmin/payments/IAPaymentsController'
import IAPaymentsView from 'app/page/internalAdmin/payments/IAPaymentsView'

import IAReviewListController from 'app/page/internalAdmin/reviewList/IAReviewListController'
import IAReviewListView from 'app/page/internalAdmin/reviewList/IAReviewListView'

import IAReviewReplyListController from 'app/page/internalAdmin/reviewReplyList/IAReviewReplyListController'
import IAReviewReplyListView from 'app/page/internalAdmin/reviewReplyList/IAReviewReplyListView'

import IAFeedbackListController from 'app/page/internalAdmin/feedbackList/IAFeedbackListController'
import IAFeedbackListView from 'app/page/internalAdmin/feedbackList/IAFeedbackListView'

import DocumentView from 'app/component/document/DocumentView'
import ManagedRootView from 'app/component/managedRootView/ManagedRootView'

import InternalAdminManagedRootView from 'app/component/managedRootView/InternalAdminManagedRootView'

import WEB_TYPE from 'app/base/WebType'
import IASoftwareKeyListController from '../page/internalAdmin/softwareKeyList/IASoftwareKeyListController'
import IASoftwareKeyListView from '../page/internalAdmin/softwareKeyList/IASoftwareKeyListView'

import IARegistrationsListView from '../page/internalAdmin/registrationsList/IARegistrationsListView'
import IARegistrationsListController from '../page/internalAdmin/registrationsList/IARegistrationsListController'
import IARegistrationIncrasesListView from '../page/internalAdmin/registrationIncrasesList/IARegistrationIncrasesListView'
import IARegistrationIncrasesListController from '../page/internalAdmin/registrationIncrasesList/IARegistrationIncrasesListController'
import IAPreferredOffersView from '../page/internalAdmin/preferredOffers/IAPreferredOffers'
import IAPreferredOffersController from '../page/internalAdmin/preferredOffers/IAPreferredOffersController'
import IAMirroringListController from 'app/page/internalAdmin/mirroringList/IAMirroringListController'
import IAMirroringListView from 'app/page/internalAdmin/mirroringList/IAMirroringListView'

import IASeoFooterEditController from 'app/page/internalAdmin/seoFooterEdit/IASeoFooterEditController'
import IASeoFooterEditView from 'app/page/internalAdmin/seoFooterEdit/IASeoFooterEditView'

import ImportDocumentationController from '../page/userweb/importDocumentation/ImportDocumentationController'
import ImportDocumentationView from '../page/userweb/importDocumentation/ImportDocumentationView'

import PromoCebiaController from '../page/userweb/promoCebia/PromoCebiaController'
import PromoCebiaView from '../page/userweb/promoCebia/PromoCebiaView'

import PromoInsertionController from '../page/userweb/promoInsertion/PromoInsertionController'
import PromoInsertionView from '../page/userweb/promoInsertion/PromoInsertionView'

import RegisterCompanyController from '../page/userweb/registerCompany/RegisterCompanyController'
import RegisterCompanyView from '../page/userweb/registerCompany/RegisterCompanyView'

import IACodebookEquipmentListController from '../page/internalAdmin/codebookList/equipment/IACodebookEquipmentListController'
import IACodebookEquipmentListView from '../page/internalAdmin/codebookList/equipment/IACodebookEquipmentListView'

import IACodebookManufacturerListController from '../page/internalAdmin/codebookList/manufacturer/IACodebookManufacturerListController'
import IACodebookManufacturerListView from '../page/internalAdmin/codebookList/manufacturer/IACodebookManufacturerListView'

import IACodebookModelListController from '../page/internalAdmin/codebookList/model/IACodebookModelListController'
import IACodebookModelListView from '../page/internalAdmin/codebookList/model/IACodebookModelListView'

import IAArticlesListView from 'app/page/internalAdmin/articlesList/IAArticlesListView'
import IAArticlesListController from 'app/page/internalAdmin/articlesList/IAArticlesListController'

import IAArticlesDetailView from 'app/page/internalAdmin/articlesDetail/IAArticlesDetailView'
import IAArticlesDetailController from 'app/page/internalAdmin/articlesDetail/IAArticlesDetailController'

export const init = (ns, oc) => {
	const router = oc.get('$Router')

	const getUserwebOptions = (options = {}) => {
		return Object.assign(
			{
				documentView: DocumentView,
				managedRootView: ManagedRootView,
				webType: WEB_TYPE.USERWEB
			},
			options
		)
	}

	const getClientAdminOptions = (options = {}) => {
		return Object.assign(
			{
				documentView: DocumentView,
				managedRootView: ManagedRootView,
				webType: WEB_TYPE.CLIENT_ADMIN
			},
			options
		)
	}

	const getInternalAdminOptions = (options = {}) => {
		return Object.assign(
			{
				documentView: DocumentView,
				managedRootView: InternalAdminManagedRootView,
				webType: WEB_TYPE.INTERNAL_ADMIN
			},
			options
		)
	}

	router
		/*
			Ostatní ***********************************************************
		 */
		.add(RouteNames.ERROR, '/error', Error500Controller, Error, getUserwebOptions())
		.add(RouteNames.NOT_FOUND, '/not-found', Error4xxController, Error, getUserwebOptions())

		/*
			Interní admin
		 */

		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_LIST,
			'/interni-admin',
			IAAdvertListController,
			IAAdvertListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ARTICLES_LIST,
			`/interni-admin/clanky`,
			IAArticlesListController,
			IAArticlesListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ARTICLES_DETAIL,
			`/interni-admin/clanky-detail`,
			IAArticlesDetailController,
			IAArticlesDetailView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_PREVIEW,
			`/interni-admin/inzerat/nahled/:${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			UserwebAdvertDetailController,
			UserwebAdvertDetailView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.OPERATING_LEASE_PREVIEW,
			`/interni-admin/inzerat/nahled/operativni-leasing/:${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			UserwebAdvertDetailController,
			UserwebAdvertDetailView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_LIST_REPORTED,
			'/interni-admin/inzeraty/nahlasene',
			IAAdvertListReportedController,
			IAAdvertListReportedView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_REPORT,
			`/interni-admin/nahlaseni/:${InternalAdminUrlConvertor.constants.ADVERT_ID}`,
			IAAdvertReportController,
			IAAdvertReportView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.PREMISE_LIST,
			'/interni-admin/firmy',
			IAPremiseListController,
			IAPremiseListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.PREMISE_EDIT,
			`/interni-admin/firma-editace/:${InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID}`,
			IAPremiseEditController,
			IAPremiseEditView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.PREMISE_HISTORY,
			`/interni-admin/firma-historie/:${InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID}`,
			IAPremiseHistoryController,
			IAPremiseHistoryView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.IMPORT_LIST,
			'/interni-admin/importy',
			IAImportListController,
			IAImportListView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.IMPORT_DETAIL,
			`/interni-admin/import/:?${InternalAdminUrlConvertor.constants.SESSION_ID}`,
			IAImportDetailController,
			IAImportDetailView,
			getInternalAdminOptions({
				onlyUpdate: true
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.USER_LIST,
			'/interni-admin/uzivatele',
			IAUserListController,
			IAUserListView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.USER_DETAIL,
			`/interni-admin/uzivatel/:?${InternalAdminUrlConvertor.constants.USER_EDIT_ID}`,
			IAUserEditController,
			IAUserEditView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO,
			`/interni-admin/inzerat/zakladni-udaje/:?${InternalAdminUrlConvertor.constants.ADVERT_ID}`,
			IAAdvertBasicInfoEditController,
			IAAdvertBasicInfoEditView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_EDIT_PHOTOS,
			`/interni-admin/inzerat/fotografie/:?${InternalAdminUrlConvertor.constants.ADVERT_ID}`,
			IAAdvertPhotosEditController,
			IAAdvertPhotosEditView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_EDIT_EQUIPMENT,
			`/interni-admin/inzerat/vybava/:?${InternalAdminUrlConvertor.constants.ADVERT_ID}`,
			IAEquipmentController,
			IAEquipmentView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_EDIT_HISTORY,
			`/interni-admin/inzerat/historie/:?${InternalAdminUrlConvertor.constants.ADVERT_ID}`,
			IAHistoryController,
			IAHistoryView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADVERT_HISTORY,
			`/interni-admin/inzerat-historie/:${InternalAdminUrlConvertor.constants.ADVERT_ID}`,
			IAAdvertHistoryController,
			IAAdvertHistoryView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ADMIN_LIST,
			'/interni-admin/admini',
			IAAdminListController,
			IAAdminListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.ROLE_LIST,
			'/interni-admin/prava-role',
			IARoleListController,
			IARoleListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.RIGHTS,
			'/interni-admin/prava/:?id',
			IARightsController,
			IARightsView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.FORBIDDEN_ACCESS,
			'/interni-admin/nepovoleny-pristup',
			IAForbiddenAccessController,
			IAForbiddenAccessView,
			getInternalAdminOptions()
		)
		.add(
			RouteNames.INTERNAL_ADMIN.STATISTICS,
			'/interni-admin/statistiky',
			IAStatisticsController,
			IAStatisticsView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.INTERNAL_ADMIN.PAYMENTS,
			'/interni-admin/platby',
			IAPaymentsController,
			IAPaymentsView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.SOFTWARE_KEYS,
			'/interni-admin/importni-klice',
			IASoftwareKeyListController,
			IASoftwareKeyListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.REGISTRATIONS_LIST,
			'/interni-admin/produkt-registrace',
			IARegistrationsListController,
			IARegistrationsListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.REGISTRATION_INCRASES_LIST,
			'/interni-admin/produkt-navyseni-registraci',
			IARegistrationIncrasesListController,
			IARegistrationIncrasesListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.PREFERRED_OFFERS,
			'/interni-admin/prednostni-vypis',
			IAPreferredOffersController,
			IAPreferredOffersView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.MIRRORING_LIST,
			'/interni-admin/zrcadleni',
			IAMirroringListController,
			IAMirroringListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.REVIEW_LIST,
			'/interni-admin/recenze',
			IAReviewListController,
			IAReviewListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.REVIEW_REPLY_LIST,
			'/interni-admin/recenze-odpovedi',
			IAReviewReplyListController,
			IAReviewReplyListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.SEO_FOOTER_EDIT,
			'/interni-admin/seo-paticka',
			IASeoFooterEditController,
			IASeoFooterEditView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.CODEBOOK_EQUIPMENT_LIST,
			'/interni-admin/ciselniky',
			IACodebookEquipmentListController,
			IACodebookEquipmentListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.CODEBOOK_MANUFACTURER_LIST,
			'/interni-admin/vyrobci',
			IACodebookManufacturerListController,
			IACodebookManufacturerListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.CODEBOOK_MODEL_LIST,
			'/interni-admin/modely',
			IACodebookModelListController,
			IACodebookModelListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.INTERNAL_ADMIN.FEEDBACK_LIST,
			'/interni-admin/zpetna-vazba',
			IAFeedbackListController,
			IAFeedbackListView,
			getInternalAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		/*
			Stránka pro zobarzení komponenty kvůli regresnímu testu ***********
		 */

		.add(
			RouteNames.CLIENT_ADMIN.CLIENT_ADVERT_LIST,
			`/moje-inzeraty`,
			ClientAdvertListController,
			ClientAdvertListView,
			getClientAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.CLIENT_ADMIN.CLIENT_ADVERT_TOPPED_PAYMENT,
			`/platba-topovani/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			ToppedPaymentController,
			ToppedPaymentView,
			getClientAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.CLIENT_ADMIN.CLIENT_STATISTICS,
			`/statistiky`,
			ClientStatisticsController,
			ClientStatisticsView,
			getClientAdminOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.CLIENT_ADMIN.CLIENT_PROFILE,
			'/profil-prodejce',
			ClientProfileController,
			ClientProfileView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.CLIENT_ADMIN.BOARD_BEHIND_WINDSCREEN,
			`/cedule-za-okno/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			BoardBehindWindscreenController,
			BoardBehindWindscreenView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.CLIENT_ADMIN.NEW_ADVERT,
			'/muj-inzerat/prvni-krok',
			UserwebAddVINController,
			UserwebAddVINView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO,
			`/muj-inzerat/zakladni-udaje/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			UserwebBasicInfoController,
			UserwebBasicInfoView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.CLIENT_ADMIN.NEW_ADVERT_PHOTOS,
			`/muj-inzerat/fotografie/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			UserwebPhotosController,
			UserwebPhotosView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.CLIENT_ADMIN.NEW_ADVERT_HISTORY,
			`/muj-inzerat/historie/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			UserwebHistoryController,
			UserwebHistoryView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.CLIENT_ADMIN.NEW_ADVERT_EQUIPMENT,
			`/muj-inzerat/vybava/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			UserwebEquipmentController,
			UserwebEquipmentView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.CLIENT_ADMIN.NEW_ADVERT_PAYMENT,
			`/muj-inzerat/platba/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.PAYMENT_TYPE}`,
			UserwebPaymentController,
			UserwebPaymentView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.CLIENT_ADMIN.NEW_ADVERT_PAYMENT_CONFIRMATION,
			`/muj-inzerat/overeni-platby/:?${AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}`,
			UserwebPaymentConfirmationController,
			UserwebPaymentConfirmationView,
			getClientAdminOptions()
		)

		.add(
			RouteNames.USERWEB.MY_SAVED_SEARCH,
			'/moje-ulozena-hledani',
			MySavedSearchController,
			MySavedSearchView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.USERWEB.FAVORITE_LIST,
			'/oblibene',
			FavoriteListController,
			FavoriteListView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.USERWEB.ADVERT_LIST,
			`/inzerce/:${AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME}/:?${AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME}/:?${AdvertListUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME}`,
			UserwebAdvertListController,
			UserwebAdvertListView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES,
			`/operativni-leasing/:${AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME}/:?${AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME}/:?${AdvertListUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME}`,
			UserwebAdvertListController,
			UserwebAdvertListView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.USERWEB.ARTICLES,
			`/auto-moto-clanky/:?${ArticlesUrlConvertor.constants.URL_APP_PARAMS.CATEGORY}`,
			UserwebArticlesController,
			UserwebArticlesView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		// Katalog
		.add(
			RouteNames.USERWEB.CATALOG,
			'/katalog-modelu',
			BrandsCatalogController,
			BrandsCatalogView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.CATALOG_MODELS,
			`/katalog-modelu/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME}`,
			ModelsCatalogController,
			ModelsCatalogView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.CATALOG_MODEL_LINES,
			`/katalog-modelu/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME}/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME}`,
			ModelLinesOverviewCatalogController,
			ModelLinesOverviewView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.CATALOG_MODIFICATIONS,
			`/katalog-modelu/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME}/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME}/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.GENERATION_SEO_NAME}`,
			ModelLineModificationsCatalogController,
			ModelLineModificationsView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.CATALOG_MODIFICATION_DETAIL,
			`/katalog-modelu/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME}/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME}/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.GENERATION_SEO_NAME}/:${CatalogUrlConvertor.constants.URL_APP_PARAMS.MODIFITACION_SEO_NAME}`,
			ModificationDetailController,
			ModificationDetailView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.ADVERT_DETAIL,
			`/:${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME}/detail/:${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME}/:${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME}/:?${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}/:?${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.PREVIEW}`,
			UserwebAdvertDetailController,
			UserwebAdvertDetailView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.USERWEB.OPERATING_LEASE_DETAIL,
			`/operativni-leasing/:${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME}/detail/:${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME}/:${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME}/:?${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID}/:?${AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.PREVIEW}`,
			UserwebAdvertDetailController,
			UserwebAdvertDetailView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.USERWEB.SELLER,
			`/detail-prodejce/:${SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_SEO_NAME}/:${SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID}`,
			UserwebSellerController,
			UserwebSellerView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
		.add(
			RouteNames.USERWEB.SELLER_WITH_OPERATING_LEASES,
			`/detail-prodejce/:${SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_SEO_NAME}/:${SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID}/operativni-leasingy`,
			UserwebSellerController,
			UserwebSellerView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.USERWEB.SELLER_OLD,
			'/hledani',
			UserwebSellerController,
			UserwebSellerView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.USERWEB.SELLER_LIST,
			`/seznam-prodejcu/:?${SellerListUrlConvertor.constants.URL_APP_PARAMS.LOCALITY}`,
			SellerListController,
			SellerListView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)

		.add(
			RouteNames.USERWEB.IMPORT_DOCUMENTATION,
			`/dokumentace-importu`,
			ImportDocumentationController,
			ImportDocumentationView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.PROMO_CEBIA,
			`/promo-cebia`,
			PromoCebiaController,
			PromoCebiaView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.PROMO_INSERTION,
			`/promo-vkladani`,
			PromoInsertionController,
			PromoInsertionView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.REGISTER_COMPANY,
			`/registrace-firmy`,
			RegisterCompanyController,
			RegisterCompanyView,
			getUserwebOptions()
		)

		.add(
			RouteNames.USERWEB.HOMEPAGE,
			`/:?${HomeUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_SEO_NAME}`,
			UserwebHomeController,
			UserwebHomeView,
			getUserwebOptions({
				onlyUpdate: true,
				autoScroll: false
			})
		)
}
