import { HttpErrorHelper } from '@inzeraty/helpers'
import ClientBaseController from 'app/base/ClientBaseController'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import ROUTE_NAMES from 'app/base/RouteNames'
import STATE_KEYS from 'app/page/userweb/newAdvert/addVIN/AddVINStateKeys'
import { FORM_IDS } from './components/AddVINForm'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import { CATEGORIES } from 'app/base/Constants'

export const URL_API_PARAM_CONDITION = 'codebook_name_id'
export const CODEBOOK_FOR_CONDITION = 'condition_cb'

export default class AddVINController extends ClientBaseController {
	constructor(dependenciesHelper, VINVehicleDataService, codebooksService) {
		super(dependenciesHelper)

		this._advertService = this._dependenciesHelper.getDependency('advertService')
		this._codebooksService = codebooksService
		this._VINVehicleDataService = VINVehicleDataService

		this.submitVINForm = this.submitVINForm.bind(this)
		this._loadDataFromCebia = this._loadDataFromCebia.bind(this)
		this._redirectRoute = this._redirectRoute.bind(this)
		this.closeCebiaPopup = this.closeCebiaPopup.bind(this)
		this._processServerErrors = this._processServerErrors.bind(this)
		this.keepCebiaCategory = this.keepCebiaCategory.bind(this)
		this.changeCebiaCategory = this.changeCebiaCategory.bind(this)

		this._formData = {}
	}

	load() {
		const conditions = this._codebooksService
			.getCodebooks({
				[URL_API_PARAM_CONDITION]: CODEBOOK_FOR_CONDITION
			})
			.then((codebooksEntities) => this._getVehicleConditions(codebooksEntities))

		return super.load({
			[STATE_KEYS.SUBMIT_VIN_FORM]: this.submitVINForm,
			[STATE_KEYS.CATEGORIES]: Object.values(CATEGORIES),
			[STATE_KEYS.CONDITIONS]: conditions,
			[STATE_KEYS.CEBIA_POPUP_OPEN]: false,
			[STATE_KEYS.CLOSE_CEBIA_POPUP]: this.closeCebiaPopup,
			[STATE_KEYS.KEEP_CEBIA_CATEGORY]: this.keepCebiaCategory,
			[STATE_KEYS.CHANGE_CEBIA_CATEGORY]: this.changeCebiaCategory,
			[STATE_KEYS.CEBIA_CATEGORY]: '',
			[STATE_KEYS.USER_CATEGORY]: ''
		})
	}

	async submitVINForm({ formData, onErrorCallback, onSuccessCallback }) {
		const { [ClientBaseController.STATE_KEYS.PREMISE_INFO]: premiseInfo } = this.getState()

		try {
			const { [FORM_IDS.VEHICLE_VIN]: vin } = formData

			const newFormData = Object.assign({}, formData, {
				// na API nemuzeme posilat prazdny string, povazovalo by se to
				// za zadany VIN, nad kterym by probehla validace
				[FORM_IDS.VEHICLE_VIN]: vin ? vin : undefined,
				premise_id: premiseInfo ? premiseInfo.id : undefined
			})

			if (vin) {
				const cebia = await this._loadDataFromCebia(vin)

				const { categoryId: cebiaResponseCategoryId } = cebia
				const { category_id: formDataCategoryId } = formData

				// ak sa kategorie zhoduju, alebo cebia podla vin kategoriu nepozna
				// presmerujeme uzivatela na dalsi krok
				if (formDataCategoryId === cebiaResponseCategoryId || !cebiaResponseCategoryId) {
					this._redirectRoute(newFormData, onErrorCallback)
				} else {
					const { categories } = this.getState()

					const userCategory = categories.find((cat) => cat.id === formDataCategoryId)
					const cebiaCategory = categories.find((cat) => cat.id === cebiaResponseCategoryId)

					const { name: userCategoryName } = userCategory
					const { name: cebiaCategoryName } = cebiaCategory

					this._formData = {
						userCategory,
						cebiaCategory,
						formData: newFormData,
						onErrorCallback
					}

					this.setState({
						[STATE_KEYS.CEBIA_POPUP_OPEN]: true,
						[STATE_KEYS.CEBIA_CATEGORY]: cebiaCategoryName,
						[STATE_KEYS.USER_CATEGORY]: userCategoryName
					})

					onSuccessCallback()
				}
			} else {
				this._redirectRoute(newFormData, onErrorCallback)
			}
		} catch (error) {
			const errors = this._processServerErrors(error)
			onErrorCallback(errors)
		}
	}

	async _loadDataFromCebia(vin) {
		if (vin && vin.trim()) {
			// pokud byl uzivatelem zadan VIN vozidla, tak se dotazeme na API,
			// ktere nam z Cebie stahne dostupna data o vozidle (znacka,
			// model atd.).
			try {
				return await this._VINVehicleDataService.getVehicleData(vin)
			} catch (error) {
				// pokud se neco pokazi pri stahovani dat z Cebie,
				// tak i presto umoznime uzivateli pokracovat v
				// zadavani noveho inzeratu
			}
		}

		return Promise.resolve({})
	}

	async _redirectRoute(newFormData, onErrorCallback) {
		try {
			const router = this._utils.$Router
			const response = await this._advertService.createDraft(newFormData)

			const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
			const { id: advertId } = response.body

			const nextStepUrl = router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO, {
				[URL_APP_PARAMS.ADVERT_ID]: advertId
			})
			router.redirect(nextStepUrl)
		} catch (error) {
			const errors = this._processServerErrors(error)
			onErrorCallback(errors)
		} finally {
			this.setState({
				[STATE_KEYS.CEBIA_POPUP_OPEN]: false
			})
		}
	}

	_processServerErrors(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)
		let errors = []

		if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(error)
			errors = body.errors
		}

		return errors
	}

	_getVehicleConditions(codebooksEntities) {
		// zlucene hodnoty zo vsetkych kategorii pre stav vozidla, takze id nemusi odpovedat vybranej kategorii,
		// zaujima nas iba value a name a seoName
		return codebooksEntities.filter(
			(codebookEntity, index, array) =>
				array.map((c) => c.seoName).indexOf(codebookEntity.seoName) === index
		)
	}

	closeCebiaPopup() {
		this.setState({
			[STATE_KEYS.CEBIA_POPUP_OPEN]: false
		})
	}

	changeCebiaCategory() {
		const { cebiaCategory, onErrorCallback, formData } = this._formData
		const { id } = cebiaCategory

		const newFormData = Object.assign({}, formData, {
			category_id: id
		})

		this._redirectRoute(newFormData, onErrorCallback)
	}

	keepCebiaCategory() {
		const { formData, onErrorCallback } = this._formData
		this._redirectRoute(formData, onErrorCallback)
	}
}
