import React, { memo } from 'react'
import WidgetSelect from './WidgetSelect'
import WidgetCheckbox from './WidgetCheckbox'
import WidgetInput from './WidgetInput'
import WidgetHeader from './WidgetHeader'
import WidgetPremiseInfo from './WidgetPremiseInfo'
import WidgetContent from './WidgetContent'
import WidgetContacts from './WidgetContacts'
import WidgetTextarea from './WidgetTextarea'
import WidgetCheckboxOptions from './WidgetCheckboxOptions'
import WidgetHidden from './WidgetHidden'
import WidgetRadio from './WidgetRadio'

const WidgetDate = memo((props) => <WidgetInput type='date' {...props} />)

const IDS = {
	SELECT: 'select',
	CHECKBOX: 'checkbox',
	TEXT: 'text',
	DATE: 'date',
	HEADER: 'header',
	PREMISE_INFO: 'premise_info',
	CONTENT: 'content',
	CONTACTS: 'contacts',
	TEXTAREA: 'textarea',
	CHECKBOX_OPTIONS: 'checkbox_options',
	HIDDEN: 'hidden',
	RADIO: 'radio'
}

const WIDGET_BY_ID = {
	[IDS.SELECT]: WidgetSelect,
	[IDS.CHECKBOX]: WidgetCheckbox,
	[IDS.TEXT]: WidgetInput,
	[IDS.DATE]: WidgetDate,
	[IDS.HEADER]: WidgetHeader,
	[IDS.PREMISE_INFO]: WidgetPremiseInfo,
	[IDS.CONTENT]: WidgetContent,
	[IDS.CONTACTS]: WidgetContacts,
	[IDS.TEXTAREA]: WidgetTextarea,
	[IDS.CHECKBOX_OPTIONS]: WidgetCheckboxOptions,
	[IDS.HIDDEN]: WidgetHidden,
	[IDS.RADIO]: WidgetRadio
}

export { IDS, WIDGET_BY_ID }
