
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['MySavedSearchPrint'] = {
  operatingLease: function(d) { return "Operativní leasing"; },
  operatingLeasePriceWithoutVat: function(d) { return "Měsíční sazba"; },
  operatingLeaseIntendedFor: function(d) { return "Pro koho je určen"; },
  operatingLeaseAnnualDistance: function(d) { return "Roční nájezd"; },
  operatingLeasePeriod: function(d) { return "Doba pronájmu"; },
  operatingLeaseServices: function(d) { return "Další služby"; },
  condition: function(d) { return "Stav vozidla"; },
  phrase: function(d) { return "Hledání"; },
  age: function(d) { return "Rok výroby"; },
  price: function(d) { return "Cena"; },
  fuel: function(d) { return "Palivo"; },
  tachometer: function(d) { return "Stav tachometru"; },
  vehicleBody: function(d) { return "Karoserie"; },
  gearbox: function(d) { return "Převodovka"; },
  performance: function(d) { return "Výkon"; },
  countryOfOrigin: function(d) { return "Země původu"; },
  vendor: function(d) { return "Prodejce"; },
  regionDistrict: function(d) { return "Místo prodeje"; },
  displacement: function(d) { return "Objem"; },
  airConditioning: function(d) { return "Klimatizace"; },
  fuelConsumption: function(d) { return "Spotřeba"; },
  certifiedProgram: function(d) { return "Ověřovací programy"; },
  airbags: function(d) { return "Počet airbagů"; },
  color: function(d) { return "Barva"; },
  capacity: function(d) { return "Počet míst"; },
  doors: function(d) { return "Počet dveří"; },
  busSeats: function(d) { return "Počet sedadel"; },
  itemAge: function(d) { return "Stáří inzerátu"; },
  priceOption: function(d) { return "Další možnosti ceny"; },
  historyInfo: function(d) { return "Historie vozidla"; },
  equipment: function(d) { return "Výbava"; },
  drive: function(d) { return "Pohon kol"; },
  otherSpecification: function(d) { return "Další specifikace vozu"; },
  weight: function(d) { return "Hmotnost"; },
  beds: function(d) { return "Počet lůžek"; },
  machineHours: function(d) { return "Motohodiny"; },
  from: function(d) { return "od"; },
  to: function(d) { return "do"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	