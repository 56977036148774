import { AbstractFactory } from '@inzeraty/models'
import PreferredOffersEntity from './PreferredOffersEntity'

export default class PreferredOffersFactory extends AbstractFactory {
	constructor() {
		super(PreferredOffersEntity)
	}

	transformData(data = {}) {
		return {
			id: data.id,
			premiseId: data.premise.id,
			premiseName: data.premise.name,
			status: data.status,
			dateFrom: data.date_from,
			dateTo: data.date_to,
			registrationId: data.registration_id,
			categoryId: data.category_id,
			manufacturerCb: data.manufacturer_cb
		}
	}
}
