import { AbstractFactory } from '@inzeraty/models'
import AdvertHistoryEntity from './AdvertHistoryEntity'

export default class AdvertHistoryFactory extends AbstractFactory {
	constructor() {
		super(AdvertHistoryEntity)
	}

	transformData(data = {}) {
		const { changes = [] } = data

		return {
			id: data.id,
			ipAddress: data.ip_address,
			changes: changes.map(({ item, new_value: newValue, old_value: oldValue }) => ({
				item,
				newValue,
				oldValue
			})),
			changedBy: data.changed_by,
			time: data.time
		}
	}
}
