import React, { memo, useState, useEffect } from 'react'
import classnames from 'classnames'
import { Input } from '@sznds/react'
import { CLASSNAME } from '../InternalAdminWidgets'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const WidgetInput = ({ className, type = 'text', formLineEntity = {}, onChange }) => {
	const {
		id,
		label,
		value = '',
		placeholder,
		required = false,
		disabled = false,
		errorMessage,
		extra: { iconLeft } = {}
	} = formLineEntity

	const [text, setText] = useState(value)

	useEffect(() => setText(value), [value])

	const isError = Boolean(errorMessage)

	const onKeyDown = (event) => {
		const isEnterPressed = event.which === 13 || event.keyCode === 13

		if (isEnterPressed) {
			// pred submitem formu musime upravit formline
			onChange({
				id,
				value: text
			})
		}
	}

	return (
		<div className={className}>
			<div className={`${CLASSNAME}__input-box`}>
				{!!label && (
					<label htmlFor={id} className={`${CLASSNAME}__label`}>
						{label}
						{required && <span className={`${CLASSNAME}__req`}>*</span>}
					</label>
				)}

				<Input
					className={classnames({
						[`${CLASSNAME}__input`]: true,
						[`${CLASSNAME}__input--disabled`]: disabled
					})}
					type={type}
					id={id}
					value={text}
					placeholder={placeholder}
					iconLeft={iconLeft}
					onChange={(event) => setText(event.target.value)}
					onKeyDown={onKeyDown}
					onBlur={() =>
						onChange({
							id,
							value: text
						})
					}
					disabled={disabled}
					error={isError}
				/>
			</div>
			{isError && <div className={`${CLASSNAME}__error`}>{errorMessage}</div>}
		</div>
	)
}

WidgetInput.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func,
	className: PropTypes.string,
	type: PropTypes.string
}

export default memo(WidgetInput)
