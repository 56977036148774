import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './AutoCompleteAllForm.less'

const CLASSNAME = 'c-auto-complete-all-form'

const AutoCompleteAllForm = (props) => {
	const {
		allItemsLabel = '',
		items = DEFAULT_PROPS.ARRAY,
		favoriteItemsLabel = '',
		favoriteItems = DEFAULT_PROPS.ARRAY,
		renderItem = DEFAULT_PROPS.FUNCTION,
		renderFavoriteItem = DEFAULT_PROPS.FUNCTION
	} = props

	return (
		<>
			{Boolean(favoriteItems.length) && (
				<>
					{favoriteItemsLabel && (
						<div className={`${CLASSNAME}__group-favorite`}>{favoriteItemsLabel}</div>
					)}
					<div>{favoriteItems.map((option, index) => renderFavoriteItem(option, index))}</div>
				</>
			)}

			{Boolean(items.length) && (
				<>
					{allItemsLabel && (
						<div
							className={classnames({
								[`${CLASSNAME}__group-all`]: true,
								[`${CLASSNAME}__group-all--offset`]: Boolean(favoriteItems.length)
							})}
						>
							{allItemsLabel}
						</div>
					)}
					<div>
						{items.map((option, index) => renderItem(option, index + favoriteItems.length))}
					</div>
				</>
			)}
		</>
	)
}

AutoCompleteAllForm.propTypes = {
	allItemsLabel: PropTypes.string,
	items: PropTypes.array.isRequired,
	favoriteItemsLabel: PropTypes.string,
	favoriteItems: PropTypes.array,
	renderItem: PropTypes.func.isRequired,
	renderFavoriteItem: PropTypes.func
}

export default React.memo(AutoCompleteAllForm)
