import React, { useState, useContext } from 'react'
import { Button } from '@sznds/react'
import Context from 'ima/page/context'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import ClientBaseController from 'app/base/ClientBaseController'
import LayoutAdmin from 'app/component/layout/admin/LayoutAdmin'
import AdminMenu from 'app/component/adminMenu/AdminMenu'
import Select from 'app/component/select/Select'
import PremiseHeaderAndSlots from 'app/component/premiseHeaderAndSlots/PremiseHeaderAndSlots'
import EmptyList from 'app/component/emptyList/EmptyList'
import KPIMetricsList from './components/KPIMetrics/KPIMetricsList'
import STATE_KEYS from './ClientStatisticsStateKeys'
import ClientStatisticsGraph from './components/graph/ClientStatisticsGraph'
import DatePickerGroup from 'app/page/userweb/clientStatistics/components/datePickerGroup/DatePickerGroup'
import ClientStatisticsTable from './components/table/ClientStatisticsTable'
import Search from './components/search/Search'
import ClientStatisticsUrlConvertor from './ClientStatisticsUrlConvertor'
import ClientStatisticsController from './ClientStatisticsController'

import './ClientStatisticsView.less'
import './ClientStatisticsCS.json'

const CLASSNAME = 'p-uw-stats'

const ClientStatisticsView = (props) => {
	const {
		[ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise,
		[STATE_KEYS.IS_LOADING]: isLoading,
		[STATE_KEYS.IS_OPERATING_LEASE]: isOperatingLease,
		[STATE_KEYS.IS_MIRRORING]: isMirroring,
		[STATE_KEYS.IS_COMPARISON_MODE_ACTIVE]: isComparisonModeActive,
		[STATE_KEYS.KPI_METRICS_TIME_SPAN_1_DATA]: kpiMetricsTimeSpan1Data,
		[STATE_KEYS.KPI_METRICS_TIME_SPAN_1_ERROR]: kpiMetricsTimeSpan1Error,
		[STATE_KEYS.KPI_METRICS_TIME_SPAN_2_DATA]: kpiMetricsTimeSpan2Data,
		[STATE_KEYS.KPI_METRICS_TIME_SPAN_2_ERROR]: kpiMetricsTimeSpan2Error,
		[STATE_KEYS.FIRST_PERIOD_DATES]: firstPeriodDates,
		[STATE_KEYS.SECOND_PERIOD_DATES]: secondPeriodDates,
		[STATE_KEYS.GRAPH_METRICS_SPAN_1]: graphMetricsSpan1,
		[STATE_KEYS.GRAPH_METRICS_SPAN_1_ERROR]: graphMetricsSpan1Error,
		[STATE_KEYS.GRAPH_METRICS_SPAN_2]: graphMetricsSpan2,
		[STATE_KEYS.GRAPH_METRICS_SPAN_2_ERROR]: graphMetricsSpan2Error,
		[STATE_KEYS.TABLE_TIME_SPAN_1_DATA]: tableTimeSpan1Data,
		[STATE_KEYS.TABLE_TIME_SPAN_1_ERROR]: tableTimeSpan1Error,
		[STATE_KEYS.TABLE_TIME_SPAN_2_DATA]: tableTimeSpan2Data,
		[STATE_KEYS.TABLE_TIME_SPAN_2_ERROR]: tableTimeSpan2Error
	} = props

	const localize = useLocalize()
	const fire = useFire()

	const [isRetryLoading, setIsRetryLoading] = useState(false)

	const { $Router: router } = useContext(Context)
	const { params: routeParams } = router.getCurrentRouteInfo()

	const getAllErrors = () => {
		const timeSpan1Errors = [kpiMetricsTimeSpan1Error, graphMetricsSpan1Error, tableTimeSpan1Error]
		const timeSpan2Errors = [kpiMetricsTimeSpan2Error, graphMetricsSpan2Error, tableTimeSpan2Error]

		const allErrors = isComparisonModeActive
			? [...timeSpan1Errors, ...timeSpan2Errors]
			: timeSpan1Errors

		return allErrors.filter((error) => error && typeof error === 'function')
	}

	const isErrorOccurred = () => {
		const allErrors = getAllErrors()

		return allErrors.length > 0
	}

	const retryFailedDataRequests = async () => {
		const allErrors = getAllErrors()

		setIsRetryLoading(true)

		await Promise.all(allErrors.map((retryCallback) => retryCallback()))

		setIsRetryLoading(false)
	}

	const isAllDataLoaded = () => {
		const isDataLoadedForKPIMetrics = (data) => {
			return data ? Object.keys(data).length > 0 : false
		}

		const isDataLoadedForGraphMetrics = (data) => {
			return !!data
		}

		const isDataLoadedForTable = (data) => {
			if (data) {
				const { entities } = data
				return !!entities
			}

			return false
		}

		const timeSpan1DataLoaded =
			isDataLoadedForKPIMetrics(kpiMetricsTimeSpan1Data) &&
			isDataLoadedForGraphMetrics(graphMetricsSpan1) &&
			isDataLoadedForTable(tableTimeSpan1Data)
		const timeSpan2DataLoaded =
			isDataLoadedForKPIMetrics(kpiMetricsTimeSpan2Data) &&
			isDataLoadedForGraphMetrics(graphMetricsSpan2) &&
			isDataLoadedForTable(tableTimeSpan2Data)

		return timeSpan1DataLoaded && (isComparisonModeActive ? timeSpan2DataLoaded : true)
	}

	const renderKPIMetricTilesSection = () => {
		return (
			<KPIMetricsList
				isOperatingLease={isOperatingLease}
				isMirroring={isMirroring}
				isComparisonMode={isComparisonModeActive}
				timeSpan1Data={kpiMetricsTimeSpan1Data}
				timeSpan2Data={kpiMetricsTimeSpan2Data}
			/>
		)
	}

	const renderDataTableSection = () => {
		return (
			<div>
				<h2 className={`${CLASSNAME}__table-section-heading`}>
					{localize('ClientStatistics.heading')}
				</h2>

				<ClientStatisticsTable
					isOperatingLease={isOperatingLease}
					isMirroring={isMirroring}
					isComparisonMode={isComparisonModeActive}
					advertStatistics={tableTimeSpan1Data}
					advertStatisticsCompare={tableTimeSpan2Data}
				/>
			</div>
		)
	}

	const renderValidContentView = () => (
		<>
			{renderKPIMetricTilesSection()}

			<ClientStatisticsGraph
				isOperatingLease={isOperatingLease}
				isMirroring={isMirroring}
				advertStatisticEntities={graphMetricsSpan1}
				advertStatisticEntitiesCompare={graphMetricsSpan2}
			/>

			{renderDataTableSection()}
		</>
	)

	const renderNoDataView = () => {
		return (
			<EmptyList
				renderImage={() => <EmptyList.NothingFoundImage />}
				title={localize('ClientStatistics.noDataAvailable')}
				text={localize('ClientStatistics.noDataAvailableDescription')}
			/>
		)
	}

	const renderErrorView = () => {
		return (
			<EmptyList
				title={localize('ClientStatistics.error')}
				text=''
				renderLink={() => (
					<Button
						primary={true}
						size='small'
						text={localize('ClientStatistics.errorAction')}
						loading={isRetryLoading}
						onClick={retryFailedDataRequests}
					/>
				)}
			/>
		)
	}

	const renderContent = (isLoading, isError, isNoDataAvailable) => {
		if (isLoading) {
			return null
		} else if (isError) {
			return renderErrorView()
		} else if (isNoDataAvailable) {
			return renderNoDataView()
		} else {
			return renderValidContentView()
		}
	}

	const isError = isErrorOccurred()
	const isNoDataAvailable = !isAllDataLoaded()

	const dealTypeItems = [
		{
			name: localize(`ClientStatistics.sale`),
			type: ClientStatisticsController.DEAL_TYPE.SALES
		},
		{
			name: localize(`ClientStatistics.operatingLease`),
			type: ClientStatisticsController.DEAL_TYPE.OPERATING_LEASE
		},
		{
			name: localize(`ClientStatistics.mirroring`),
			type: ClientStatisticsController.DEAL_TYPE.MIRRORING
		}
	]

	return (
		<LayoutAdmin
			isPremiseAdmin={true}
			headerContent={isPremise ? <AdminMenu /> : null}
			className={CLASSNAME}
		>
			<div className={`${CLASSNAME}__content`}>
				<PremiseHeaderAndSlots />
				<div className={`${CLASSNAME}__filters`}>
					<Search
						initiallySelectedItem={
							routeParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.FILTER_BY]
						}
						initialInputValue={
							routeParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.FILTER_BY_VALUE]
						}
					/>
					<DatePickerGroup
						firstPeriodDates={firstPeriodDates}
						secondPeriodDates={secondPeriodDates}
					/>
				</div>

				<div className={`${CLASSNAME}__heading-wrapper`}>
					<h2 className={`${CLASSNAME}__heading`}>{localize('ClientStatistics.heading')}</h2>
					<div className={`${CLASSNAME}__deal-type-select`}>
						<Select
							onSelect={(selectedItem) => {
								if (selectedItem) {
									fire('dealTypeChange', selectedItem.type)
								}
							}}
							selectedItem={
								dealTypeItems.find((item) => {
									if (isOperatingLease) {
										return item.type === ClientStatisticsController.DEAL_TYPE.OPERATING_LEASE
									} else if (isMirroring) {
										return item.type === ClientStatisticsController.DEAL_TYPE.MIRRORING
									} else {
										return item.type === ClientStatisticsController.DEAL_TYPE.SALES
									}
								}) || null
							}
							items={dealTypeItems}
							size='small'
						/>
					</div>
				</div>

				{renderContent(isLoading, isError, isNoDataAvailable)}
			</div>
		</LayoutAdmin>
	)
}

ClientStatisticsView.propTypes = {}

export default ClientStatisticsView
