import React from 'react'
import { Button } from '@sznds/react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import STEPS_STATE_KEYS from './StepperStateKeys'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import LinkArrow, { ARROW_TYPE } from 'app/component/linkArrow/LinkArrowView'
import PremiseSlots from 'app/component/premiseSlots/PremiseSlots'
import ROUTE_NAMES from 'app/base/RouteNames'
import { ADVERT_STATE } from 'app/page/userweb/clientAdvertList/getAdvertState'

import './FormStepperCS.json'
import './FormStepperView.less'

const CLASSNAME = 'c-form-stepper'

const FormStepperView = ({
	steps = [],
	prevBtnProps,
	nextBtnProps,
	saveBtnProps,
	disableNext,
	continueBySubmit,
	loading,
	isAdmin,
	isEdit,
	advertState
}) => {
	const localize = useLocalize()
	const fire = useFire()

	const isDraft = advertState === ADVERT_STATE.DRAFT
	const curentStepIndex = steps.findIndex((step) => step.actual)
	const isActive = advertState === ADVERT_STATE.ACTIVE
	const prevStep = curentStepIndex > 0 ? steps[curentStepIndex - 1] : null
	const nextStep = curentStepIndex < steps.length - 1 ? steps[curentStepIndex + 1] : null
	const isLastStep = curentStepIndex === steps.length - 1
	const isPaymentStep =
		steps.length && steps[curentStepIndex]
			? steps[curentStepIndex].routeName === ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT
			: false
	const isNextStepPayment = nextStep
		? nextStep.routeName === ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT
		: false

	const getPrevStepButtonProps = () => {
		const props = {}

		if (prevStep) {
			Object.assign(props, {
				href: prevStep.url,
				text: localize('FormStepper.gotoPrev', { STEP: prevStep.text4 }),
				type: 'button'
			})
		}

		Object.assign(props, prevBtnProps)

		Object.assign(props, {
			url: props.href,
			children: props.text
		})

		delete props.href
		delete props.text
		delete props.type

		return props
	}

	const getNextStepButtonProps = () => {
		const props = {}

		if (nextStep) {
			let text = localize('FormStepper.gotoNext', {
				STEP: nextStep.text4
			})

			if (isActive && isNextStepPayment) {
				text = localize('FormStepper.prolongPublishing')
			}

			Object.assign(props, {
				href: continueBySubmit ? '' : nextStep.url,
				text,
				type: continueBySubmit ? 'submit' : 'button'
			})
		} else if (!isPaymentStep) {
			Object.assign(props, {
				text: localize(isEdit ? 'FormStepper.saveAndLeave' : 'EquipmentForm.paymentPromise'),
				type: 'submit'
			})
		}

		return Object.assign(props, nextBtnProps, {
			onClick: (event) => {
				fire(nextStep ? 'submitAndContinue' : 'submitAndClose')

				if (nextBtnProps.onClick && typeof nextBtnProps.onClick === 'function') {
					nextBtnProps.onClick(event)
				}
			}
		})
	}

	const getSaveAndLeaveButtonProps = () => {
		const props = {}

		if (!isLastStep || isPaymentStep) {
			props.text = localize(
				isEdit || isAdmin ? 'FormStepper.saveAndLeave' : 'FormStepper.finishLater'
			)

			if (continueBySubmit) {
				props.type = 'submit'
				props.onClick = () => {
					fire('submitAndClose')
				}
			} else {
				props.onClick = () => {
					fire('gotoBackToList')
				}
			}
		}

		return Object.assign(props, saveBtnProps)
	}

	const btnPrevProps = getPrevStepButtonProps()
	const btnNextProps = getNextStepButtonProps()
	const btnSaveAndLeaveProps = getSaveAndLeaveButtonProps()

	const isOnlyNext = !btnPrevProps.children && btnNextProps.text

	if (isAdmin || advertState) {
		return (
			<div
				className={classnames({
					[CLASSNAME]: true,
					[`${CLASSNAME}--only-next`]: isOnlyNext
				})}
			>
				{btnPrevProps.children && (
					<LinkArrow
						{...btnPrevProps}
						disabled={loading}
						type={ARROW_TYPE.LEFT}
						className={classnames({
							[btnPrevProps.className]: !!btnPrevProps.className,
							[`${CLASSNAME}__btn-back`]: true
						})}
					/>
				)}

				<div className={`${CLASSNAME}__buttons-wrap`}>
					{!!btnNextProps.text && <PremiseSlots className={`${CLASSNAME}__slots`} />}

					{!!btnNextProps.text && (
						<Button
							primary={true}
							{...btnNextProps}
							disabled={disableNext}
							loading={loading}
							className={`${CLASSNAME}__btn-submit ${CLASSNAME}__btn-save-continue`}
							data-e2e='go-to-next-step'
						/>
					)}

					{!!btnSaveAndLeaveProps.text && !isDraft && (
						<Button
							{...btnSaveAndLeaveProps}
							loading={loading}
							className={`${CLASSNAME}__btn-submit ${CLASSNAME}__btn-save-close`}
						/>
					)}
				</div>
			</div>
		)
	} else {
		return null
	}
}

FormStepperView.propTypes = {
	steps: PropTypes.array.isRequired,
	prevBtnProps: PropTypes.object,
	nextBtnProps: PropTypes.object,
	saveBtnProps: PropTypes.object,
	continueBySubmit: PropTypes.bool,
	disableNext: PropTypes.bool,
	loading: PropTypes.bool,
	isAdmin: PropTypes.bool,
	isEdit: PropTypes.bool,
	isDraft: PropTypes.bool,
	advertState: PropTypes.string
}

FormStepperView.defaultProps = {
	continueBySubmit: true,
	disableNext: false,
	loading: false,
	prevBtnProps: DEFAULT_PROPS.OBJECT,
	nextBtnProps: DEFAULT_PROPS.OBJECT,
	saveBtnProps: DEFAULT_PROPS.OBJECT,
	isAdmin: false,
	isEdit: false,
	advertState: ''
}

const selectors = (state) => ({
	steps: state[STEPS_STATE_KEYS.STEPS],
	isEdit: state[STEPS_STATE_KEYS.IS_EDIT],
	advertState: state[STEPS_STATE_KEYS.ADVERT_STATE]
})

export default React.memo(select(selectors)(FormStepperView))
