const RESOURCE = '/preferred_offers'

export default class PreferredOffersResource {
	constructor(restClient) {
		this._restClient = restClient
	}

	getPreferredOffers(params, options) {
		return this._restClient.get(`${RESOURCE}`, params, options)
	}
}
