import UserwebBaseController from 'app/base/UserwebBaseController'
import { HttpErrorHelper } from '@inzeraty/helpers'

export default class Error4xxController extends UserwebBaseController {
	constructor(dependenciesHelper) {
		super(dependenciesHelper)

		this.status = 404
	}

	/**
	 * @return {{status: number}}
	 */
	load() {
		const { error = {} } = this.params

		const httpStatus = HttpErrorHelper.getHttpStatus(error)

		return {
			status: httpStatus
		}
	}

	getDataForSsp() {
		return {}
	}
}
