import React from 'react'
import { UserPropTypes } from '@inzeraty/models'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import EntityForm from 'app/component/entityForm/EntityForm'
import EntityFormPopup from 'app/page/internalAdmin/components/entityFormPopup/EntityFormPopup'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import { useFire } from 'app/base/componentHelpers'
import ForbidUserPopupExtension from './ForbidUserPopupExtension'
import './ForbidUserPopup.less'
import select from 'ima-plugin-select'

const CLASSNAME = 'c-ia-user'

const ForbidUserPopup = ({
	formLineEntities,
	entityFormError,
	isWaiting,
	isPopupOpen,
	userEntity
}) => {
	const fire = useFire()
	return isPopupOpen ? (
		<EntityFormPopup
			title='Stavy uživatele'
			onClose={() => {
				fire('closeUserPopup')
			}}
		>
			{userEntity && (
				<div className={`${CLASSNAME}__user-info`}>
					<div>ID: {userEntity.id}</div>
					<div>Login: {userEntity.login}</div>
				</div>
			)}
			<EntityForm
				variant={VARIANT.POPUP_FORM}
				formLineEntities={formLineEntities}
				entityFormError={entityFormError}
				isWaiting={isWaiting}
				onSubmit={() => {
					fire('submitUserForbid')
				}}
			/>
		</EntityFormPopup>
	) : null
}

const selector = (state) => ({
	isWaiting: state[ForbidUserPopupExtension.STATE_KEYS.IS_WAITING],
	formLineEntities: state[ForbidUserPopupExtension.STATE_KEYS.FORM_LINE_ENTITIES],
	entityFormError: state[ForbidUserPopupExtension.STATE_KEYS.ENTITY_FORM_ERROR],
	isPopupOpen: state[ForbidUserPopupExtension.STATE_KEYS.IS_USER_FORBID_OPENED],
	userEntity: state[ForbidUserPopupExtension.STATE_KEYS.USER_ENTITY]
})

ForbidUserPopup.propTypes = {
	isWaiting: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	isPopupOpen: PropTypes.bool,
	userEntity: PropTypes.shape(UserPropTypes)
}

export default select(selector)(ForbidUserPopup)
