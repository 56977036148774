export default class ArticlesService {
	/**
	 * constructor
	 * @param {ArticlesResource} articlesResource
	 * @param {ArticlesFactory}  articlesFactory
	 * @param {ArticlesDetailFactory}  articlesDetailFactory
	 * @param {PaginationFactory} paginationFactory
	 */
	constructor(articlesResource, articlesFactory, articlesDetailFactory, paginationFactory) {
		this._articlesResource = articlesResource
		this._articlesFactory = articlesFactory
		this._articlesDetailFactory = articlesDetailFactory
		this._paginationFactory = paginationFactory
	}
	/**
	 * Získá a vrátí články
	 *
	 * @method getArticlesList
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<Array<ArticleEntity>>} Promise, která vrací články.
	 */

	async getArticlesList(params, options) {
		const {
			body: { results, pagination }
		} = await this._articlesResource.getArticlesList(params, options)
		const transformedData = results.map((item) => this._articlesFactory.transformData(item))

		const articlesEntities = this._articlesFactory.createEntityList(transformedData)
		const paginationEntity = this._paginationFactory.createPagination(pagination)

		return {
			entities: articlesEntities,
			paginationEntity
		}
	}

	/**
	 * Získá a vrátí detail článků
	 *
	 * @method getArticlesList
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<Array<ArticleEntity>>} Promise, která vrací detail článků.
	 */

	async getArticlesDetail(params, options) {
		const {
			body: { results, pagination }
		} = await this._articlesResource.getArticlesDetail(params, options)
		const transformedData = results.map((item) =>
			this._articlesDetailFactory.transformDetailData(item)
		)

		const articlesDetailEntities = this._articlesDetailFactory.createEntityList(transformedData)
		const paginationEntity = this._paginationFactory.createPagination(pagination)

		return {
			entities: articlesDetailEntities,
			paginationEntity
		}
	}

	async getArticlesCachedDetail(params, options) {
		const {
			body: { results, pagination }
		} = await this._articlesResource.getArticlesCachedDetail(params, options)
		const transformedData = results.map((item) =>
			this._articlesDetailFactory.transformDetailData(item)
		)

		const articlesDetailEntities = this._articlesDetailFactory.createEntityList(transformedData)
		const paginationEntity = this._paginationFactory.createPagination(pagination)

		return {
			entities: articlesDetailEntities,
			paginationEntity
		}
	}

	deleteArticle(id, params, options) {
		return this._articlesResource.deleteArticle(id, params, options)
	}

	editArticle(id, params, options) {
		return this._articlesResource.editArticle(id, params, options)
	}
}
