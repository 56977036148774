import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Surface } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { sortModels } from '../../../helper/SortModels'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'

import './ModificationsList.less'
import './ModificationsListCS.json'
import 'app/base/BaseCS.json'
import '../../../CatalogCS.json'

const CLASSNAME = 'c-catalog-modifications-list'

export const getYear = (localize, from, to) => {
	const yearTo = to ? to : localize('ModelsOverviewList.present')

	if (from && yearTo) {
		return <span>{`${from} – ${yearTo}`}</span>
	} else {
		return undefined
	}
}

const ModificationsList = (props) => {
	const { modificationsList = DEFAULT_PROPS.ARRAY } = props

	const context = useContext(Context)
	const router = context.$Router
	const localize = useLocalize()

	const dataLoaded = modificationsList && modificationsList.length > 0

	const getFuelConsumptionValue = (data) => {
		const {
			fuelConsumptionUrbanMin,
			fuelConsumptionUrbanMax,
			fuelConsumptionExtraurbanMin,
			fuelConsumptionExtraurbanMax,
			fuelConsumptionCombinedMin,
			fuelConsumptionCombinedMax
		} = data

		const consupmtionArr = [
			fuelConsumptionUrbanMin,
			fuelConsumptionUrbanMax,
			fuelConsumptionExtraurbanMin,
			fuelConsumptionExtraurbanMax,
			fuelConsumptionCombinedMin,
			fuelConsumptionCombinedMax
		].filter((val) => !!val)

		if (consupmtionArr.length > 0) {
			const min = Math.min(...consupmtionArr)
			const max = Math.max(...consupmtionArr)

			if (min !== max) {
				return `${min} – ${max}`
			} else {
				return min || max
			}
		} else {
			return undefined
		}
	}

	const renderInfoItem = (name, value, unit = '') => {
		return (
			value && (
				<div className={`${CLASSNAME}__info-item`}>
					<span className={`${CLASSNAME}__info-item-name`}>{name}</span>
					<span className={`${CLASSNAME}__info-item-value`}>
						{value} {!!unit && <span>{unit}</span>}
					</span>
				</div>
			)
		)
	}

	const renderInfo = (modification) => {
		const { data = {} } = modification
		const { maxSpeed, engineVolume, fuel } = data

		return (
			<div className={`${CLASSNAME}__info`}>
				{renderInfoItem(
					localize('ModificationsList.speed'),
					Format.number(maxSpeed),
					localize('Catalog.speedUnit')
				)}
				{renderInfoItem(
					localize('ModificationsList.engineVolume'),
					Format.number(engineVolume),
					localize('Catalog.engineVolumeUnit')
				)}
				{renderInfoItem(localize('ModificationsList.fuel'), fuel)}
				{renderInfoItem(
					localize('ModificationsList.fuelConsumption'),
					getFuelConsumptionValue(data),
					localize('Base.gasMileage')
				)}
			</div>
		)
	}

	const getUrlToModificationDetail = (modification) => {
		const { seoName, generation = {} } = modification
		const { seoName: generationSeoName, model = {} } = generation
		const { seoName: modelSeoName = '', manufacturer = {} } = model
		const { seoName: manufacturerSeoName } = manufacturer

		return router.link(ROUTE_NAMES.USERWEB.CATALOG_MODIFICATION_DETAIL, {
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: manufacturerSeoName,
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME]: modelSeoName,
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.GENERATION_SEO_NAME]: generationSeoName,
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.MODIFITACION_SEO_NAME]: seoName
		})
	}

	const renderModificationsList = () => {
		return (
			<div className={`${CLASSNAME}__list`}>
				{modificationsList.sort(sortModels).map((modification) => {
					const { name, id, yearStart, yearEnd, data = {} } = modification
					const { powerKw } = data

					const modificationName = powerKw ? `${name} (${powerKw} ${localize('Base.KW')})` : name

					const year = getYear(localize, yearStart, yearEnd)

					const url = getUrlToModificationDetail(modification)

					return (
						<div
							key={id}
							className={`${CLASSNAME}__item`}
							data-dot='item'
							data-dot-data={`{"value": "${name}"}`}
						>
							<a className={`${CLASSNAME}__item-link`} href={url}>
								{modificationName}
							</a>
							{year && <div className={`${CLASSNAME}__item-year`}>{year}</div>}
							{renderInfo(modification)}
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<Surface surface={3} className={CLASSNAME} data-dot='modifications'>
			{dataLoaded && renderModificationsList()}
		</Surface>
	)
}

ModificationsList.propTypes = {
	modificationsList: PropTypes.array
}

export default React.memo(ModificationsList)
