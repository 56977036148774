import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import SelectWidget from '../select/SelectWidget'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './VehicleConditionWidget.less'
import './VehicleConditionWidgetCS.json'

export const CONDITION_WIDGET_ID = 'condition'
const CLASSNAME = 'c-condition-widget'

const CategoryWidget = (props) => {
	const localize = useLocalize()

	const dict = {
		ariaTitle: localize('VehicleConditionWidget.category'),
		popupTitle: localize('VehicleConditionWidget.popupTitle'),
		popupClose: localize('VehicleConditionWidget.close')
	}

	const selectModalItem = (event, option) => {
		const { formLineEntity } = props
		const { value, id: formLineEntityId } = formLineEntity
		if (option.value !== value) {
			props.onChange(event, option.value, formLineEntityId)
		}
	}

	const renderInputText = () => {
		const { formLineEntity } = props
		const { options = [], value } = formLineEntity
		const { name = '' } = options.find((option) => option.value === value) || {}

		return <span className={`${CLASSNAME}__text`}>{name}</span>
	}

	return (
		<SelectWidget
			{...props}
			widgetId={CONDITION_WIDGET_ID}
			dictionary={dict}
			onModalItemClick={selectModalItem}
			renderInputText={renderInputText}
		/>
	)
}

CategoryWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(CategoryWidget)
