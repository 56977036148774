export const ACTION_TYPE = {
	EDIT: 'edit',
	SUSPEND: 'suspend',
	PUBLISH: 'publish',
	PAY: 'pay',
	REMOVE: 'remove',
	ADD_TO_PO: 'addToPO',
	REMOVE_FROM_PO: 'removeFromPO',
	TOPPED_ADVERT: 'toppedAdvert',

	ACTIVATE: 'activate',
	PROLONG: 'prolong',
	DELETE: 'delete',
	FORBID: 'forbid',
	ALLOW: 'allow',
	RESTORE: 'restore',

	MISSING_MODULE_COUNT: 'missingModuleCount',
	INSUFFICIENT_IMAGES_COUNT: 'insufficientImagesCount'
}
