import React from 'react'

import './BasicInfoPlaceholders.less'

const CLASSNAME = 'c-basic-info-pl'

const BasicInfoPlaceholders = () => {
	const itemArray = [0, 1, 2, 3, 4, 5]

	const renderItem = (key) => {
		return (
			<div key={key} className={`${CLASSNAME}__item`}>
				<div className={`${CLASSNAME}__text-${key}`}></div>
				<div className={`${CLASSNAME}__input`}></div>
			</div>
		)
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__heading`} />

			<div className={`${CLASSNAME}__list`}>{itemArray.map((item) => renderItem(item))}</div>
		</div>
	)
}

export default BasicInfoPlaceholders
