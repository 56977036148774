
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['BasicInfoForm'] = {
  typeAndModelSection: function(d) { return "Typ a model vozu"; },
  dealTypeNotValid: function(d) { return "Při volbě \"Operativní leasing\" je nutné zadat minimálně jednu variantu v sekci \"Nastavení operativního leasingu\"."; },
  manufacturerRequired: function(d) { return "Vyberte prosím výrobce."; },
  manufacturerNotValid: function(d) { return "Neplatný výrobce."; },
  modelRequired: function(d) { return "Vyberte prosím model."; },
  modelNotValid: function(d) { return "Neplatný model."; },
  additionalModelNotValid: function(d) { return "Neplatný titulek inzerátu."; },
  manufacturingDateRequired: function(d) { return "Zadejte prosím rok výroby."; },
  manufacturingDateNotValid: function(d) { return "Neplatný rok výroby."; },
  inOperationDateRequired: function(d) { return "Zadejte prosím \"V provozu od\"."; },
  inOperationDateNotValid: function(d) { return "Neplatná hodnota \"V provozu od\"."; },
  vehicleBodyRequired: function(d) { return "Vyberte prosím typ karosérie."; },
  vehicleBodyNotValid: function(d) { return "Neplatný typ karosérie."; },
  doorsAndCapacityNotValid: function(d) { return "Neplatný počet dveří a míst."; },
  capacityNotValid: function(d) { return "Neplatný počet míst."; },
  doorsAndSeatsNotValid: function(d) { return "Neplatný počet dveří a sedadel."; },
  colorAndToneRequired: function(d) { return "Vyberte prosím barvu."; },
  colorAndToneNotValid: function(d) { return "Neplatná barva a odstín."; },
  colorTypeNotValid: function(d) { return "Neplatná úprava barvy."; },
  airconditionNotValid: function(d) { return "Neplatný typ klimatizace."; },
  airbagsNotValid: function(d) { return "Neplatný počet airbagů."; },
  weightNotValid: function(d) { return "Neplatná zadaná hmotnost."; },
  loadCapacityNotValid: function(d) { return "Neplatně zadaná nosnost."; },
  bedsNotValid: function(d) { return "Neplatný počet lůžek."; },
  engineSection: function(d) { return "Motorizace"; },
  engineVolumeRequired: function(d) { return "Zadejte prosím objem motoru."; },
  engineVolumeNotValid: function(d) { return "Neplatně zadaný objem motoru."; },
  enginePowerRequired: function(d) { return "Zadejte prosím výkon."; },
  enginePowerNotValid: function(d) { return "Neplatně zadaný výkon."; },
  fuelRequired: function(d) { return "Vyberte prosím palivo."; },
  fuelNotValid: function(d) { return "Neplatné palivo."; },
  fuelConsumptionNotValid: function(d) { return "Neplatná průměrná spotřeba."; },
  batteryCapacityNotValid: function(d) { return "Neplatná kapacita akumulátoru."; },
  vehicleRangeNotValid: function(d) { return "Neplatný dojezd."; },
  electricMileageNotValid: function(d) { return "Neplatná spotřeba."; },
  gearboxRequired: function(d) { return "Vyberte prosím typ převodovky."; },
  gearboxNotValid: function(d) { return "Neplatný typ převodovky."; },
  gearboxLevelsNotValid: function(d) { return "Neplatný počet rychlostí."; },
  driveNotValid: function(d) { return "Neplatný pohon."; },
  vehicleConditionSection: function(d) { return "Stav vozu"; },
  tachometerRequired: function(d) { return "Zadejte prosím nájezd."; },
  tachometerNotValid: function(d) { return "Neplatně zadaný nájezd."; },
  tachometerNewCarInvalidCondition: function(d) { return "Nový vůz musí mít nájezd do 6 000 km"; },
  tachometerDemoCarInvalidCondition: function(d) { return "Předváděcí vůz musí mít nájezd do 25 000 km"; },
  deliveryDateRequired: function(d) { return "Zadejte prosím datum dodání."; },
  deliveryDateNotValid: function(d) { return "Neplatná hodnota datumu dodání."; },
  machineHoursRequired: function(d) { return "Zadejte prosím počet motohodin."; },
  machineHoursNotValid: function(d) { return "Neplatný počet motohodin."; },
  stkDateNotValid: function(d) { return "Neplatná hodnota \"Platnost STK\"."; },
  countryOfOriginRequired: function(d) { return "Vyberte prosím zemi původu."; },
  countryOfOriginNotValid: function(d) { return "Neplatná země původu."; },
  descriptionNotValid: function(d) { return "Neplatná poznámku o stavu vozidla."; },
  priceSection: function(d) { return "Cena"; },
  priceRequired: function(d) { return "Zadejte prosím vaši prodejní cenu."; },
  priceNotValid: function(d) { return "Neplatná prodejní cena."; },
  priceLeasingNotValid: function(d) { return "Neplatná cena při využití leasingu."; },
  pricePaymentNotValid: function(d) { return "Neplatná výše splátky."; },
  pricePaymentCountNotValid: function(d) { return "Neplatný počet splátek."; },
  priceNoteRequired: function(d) { return "Zadejte prosím poznámku k ceně."; },
  priceNoteNotValid: function(d) { return "Neplatná poznámka k ceně."; },
  contactSection: function(d) { return "Kontaktní údaje"; },
  localityRequired: function(d) { return "Zadejte prosím adresu."; },
  localityNotValid: function(d) { return "Neplatná adresa."; },
  phoneRequired: function(d) { return "Zadejte prosím váš telefon."; },
  phoneNotValid: function(d) { return "Neplatný telefon."; },
  emailRequired: function(d) { return "Zadejte prosím e-mail."; },
  emailNotValid: function(d) { return "Neplatný e-mail."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	