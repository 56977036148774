import { getRibbonHeight, getRibbonIsSticky } from '@inzeraty/components'

const canBeDropdownRenderedBelow = (referenceElm, dropdownElm) => {
	if (referenceElm && dropdownElm && window) {
		const headerHeight = getRibbonIsSticky() ? getRibbonHeight() : 0

		const { top: scrollY, height: vh } = document.body.getBoundingClientRect()
		const { top: rTop, bottom: rBottom } = referenceElm.getBoundingClientRect()
		const { height: dHeight } = dropdownElm.getBoundingClientRect()

		const dStyle = dropdownElm.currentStyle || window.getComputedStyle(dropdownElm)
		const dropdownHeight = dHeight + parseInt(dStyle.marginTop)

		// - zobrazi se dole pokud;
		// - je ve viewportu pod referencnim elementem misto na cely dropdown
		// - nebo referencni element je nad polovinou vysky viewportu
		// - nebo nad referencnim elementem neni dost mista pro cely dropdown
		return (
			vh - headerHeight > rBottom - headerHeight + dropdownHeight ||
			rTop - headerHeight < (vh - headerHeight) / 2 ||
			rTop - headerHeight - scrollY < dropdownHeight
		)
	}

	return true
}

export default canBeDropdownRenderedBelow
