
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PremiseFiltersFormLines'] = {
  vehicleCategoryLabel: function(d) { return "Typ vozidla"; },
  vehicleCategoryPlaceholder: function(d) { return "Všechny typy vozidel"; },
  advertStatusLabel: function(d) { return "Stav inzerátu"; },
  advertStatusPlaceholder: function(d) { return "Všechny stavy"; },
  advertStatusActive: function(d) { return "Aktivní"; },
  advertStatusInactive: function(d) { return "Neaktivní"; },
  advertStatusBan: function(d) { return "Zablokovaný administrátorem"; },
  dealTypeLabel: function(d) { return "Typ prodeje"; },
  dealTypePlaceholder: function(d) { return "Všechny typy"; },
  dealTypeSale: function(d) { return "Prodej"; },
  dealTypeOperatingLease: function(d) { return "Operativní leasing"; },
  regionLabel: function(d) { return "Kraj"; },
  regionPlaceholder: function(d) { return "Celá ČR"; },
  brandsLabel: function(d) { return "Výrobce"; },
  brandsPlaceholder: function(d) { return "Všichni výrobci"; },
  modelsLabel: function(d) { return "Model"; },
  modelsPlaceholder: function(d) { return "Všechny modely"; },
  createDateFromLabel: function(d) { return "Datum vložení od"; },
  createDateFromPlaceholder: function(d) { return "Datum od"; },
  createDateToLabel: function(d) { return "Datum vložení do"; },
  createDateToPlaceholder: function(d) { return "Datum do"; },
  toppedAdverts: function(d) { return "Topované inzeráty"; },
  preferredOffer: function(d) { return "Přednostní výpis"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	