export default Object.freeze({
	CATEGORIES: 'categories',
	CONDITIONS: 'conditions',
	SUBMIT_VIN_FORM: 'submitVINForm',
	CEBIA_POPUP_OPEN: 'cebiaPopupOpen',
	CLOSE_CEBIA_POPUP: 'closeCebiaPopup',
	KEEP_CEBIA_CATEGORY: 'keepCebiaCategory',
	CHANGE_CEBIA_CATEGORY: 'changeCebiaCategory',
	CEBIA_CATEGORY: 'cebiaCategory',
	USER_CATEGORY: 'userCategory'
})
