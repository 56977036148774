
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Error'] = {
  description: function(d) { return "Můžete zkusit zadat adresu znovu nebo přejít na hlavní stránku."; },
  buttonText: function(d) { return "Přejít na hlavní stránku"; },
  login: function(d) { return "Přihlásit"; },
  registerSentence: function(d) { return "Pokud ještě nemáte účet, tady ho "; },
  register: function(d) { return "vytvoříte"; },
  errorTitle401: function(d) { return "Pro zobrazení " + d.PAGE_NAME + " je třeba se přihlásit."; },
  errorTitle403: function(d) { return "Pro zobrazení " + d.PAGE_NAME + " nemáte dostatečná oprávnění."; },
  errorTitle404: function(d) { return "Jejda, stránka neexistuje"; },
  errorTitle500: function(d) { return "Jejda, něco se pokazilo"; },
  pageNameDefault: function(d) { return "stránky"; },
  pageNameBoardBehindWindscreen: function(d) { return "cedule za okno"; },
  pageNameClientAdvertList: function(d) { return "vašich inzerátů"; },
  pageNameFavorite: function(d) { return "oblíbených inzerátů"; },
  pageNameMySaveSearch401: function(d) { return "vašich uložených hledání"; },
  pageNameMySaveSearch: function(d) { return "uložených hledání"; },
  pageNameProfile: function(d) { return "profilu"; },
  pageNameStatistics: function(d) { return "statistik"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	