import ns from 'ima/namespace'
import React from 'react'
import ImaAbstractDocumentView from 'ima/page/AbstractDocumentView'
import GenericError from 'ima/error/GenericError'

ns.namespace('app.component.document')

/**
 * Abstraktní třída pro DocumentView
 * @class AbstractDocumentView
 * @namespace app.component.document
 * @extends ima.page.ImaAbstractDocumentView
 * @module app
 * @submodule app.component
 */
export default class AbstractDocumentView extends ImaAbstractDocumentView {
	/**
	 * Vrátí html id elementu do nějž se renderuje obsah stránky.
	 * @return {String}
	 */
	static get masterElementId() {
		return 'page'
	}

	render() {
		return (
			<html lang={this.utils.$Settings.$Language}>
				{this._renderHead()}
				{this._renderBody()}
			</html>
		)
	}

	_renderBody() {
		throw new GenericError(
			'Je potřeba implementovat metodu pro renderovaní těla stránky "_renderBody".'
		)
	}

	_renderHead() {
		throw new GenericError(
			'Je potřeba implementovat metodu pro renderovaní hlavičky stránky "_renderHead".'
		)
	}
}

ns.app.component.document.AbstractDocumentView = AbstractDocumentView
