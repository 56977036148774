import React from 'react'
import { Icon } from '@sznds/react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { CHEVRON_LEFT_OUTLINE_16, CHEVRON_RIGHT_OUTLINE_16 } from '@sznds/icons'

import './LinkArrowView.less'

const CLASSNAME = 'c-link-arrow'

const ARROW_TYPE = {
	LEFT: 'left',
	RIGHT: 'right'
}

const LinkArrow = (props) => {
	const newProps = Object.assign({}, props)
	const {
		url,
		dataDot,
		dataDotData,
		type,
		children,
		disabled,
		className: propsClassName
	} = newProps

	delete newProps.url
	delete newProps.dataDot
	delete newProps.dataDotData
	delete newProps.type
	delete newProps.children
	delete newProps.className

	const className = classnames({
		[propsClassName]: !!propsClassName,
		[CLASSNAME]: true,
		[`${CLASSNAME}-${type}`]: !!type,
		[`${CLASSNAME}--disabled`]: disabled
	})

	const linkProps = Object.assign({}, newProps, { className })

	const renderIcon = (icon) => {
		return <Icon className={`${CLASSNAME}__icon`} symbol={icon} />
	}

	const newChildren = (
		<>
			{type === ARROW_TYPE.LEFT && renderIcon(CHEVRON_LEFT_OUTLINE_16)}
			{children}
			{type === ARROW_TYPE.RIGHT && renderIcon(CHEVRON_RIGHT_OUTLINE_16)}
		</>
	)

	if (dataDot) {
		linkProps['data-dot'] = dataDot
	}

	if (dataDotData) {
		linkProps['data-dot-data'] = dataDotData
	}

	if (url) {
		const TagName = disabled ? 'span' : 'a'

		if (disabled) {
			delete linkProps.href
		}

		delete linkProps.disabled

		return (
			<TagName href={url} {...linkProps}>
				{newChildren}
			</TagName>
		)
	} else {
		return <button {...linkProps}>{newChildren}</button>
	}
}

LinkArrow.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	url: PropTypes.string,
	dataDot: PropTypes.string,
	dataDotData: PropTypes.string,
	type: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

LinkArrow.defaultProps = {
	className: '',
	disabled: false
}

export default LinkArrow
export { ARROW_TYPE }
