import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

/**
 * @class AdvertAdminUrlConvertor
 * @namespace app.page.userweb.newAdvert
 * @module app
 * @submodule app.page
 */
export default class AdvertAdminUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				ADVERT_ID: 'advertId',
				PAYMENT_TYPE: 'paymentType',
				PAYMENT_ID: 'payment_id',
				PAYMENT_LENGTH: 'delka'
			},

			URL_API_PARAMS: {
				CATEGORY_ID: UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID,
				PARENT_ID: 'parent_id'
			}
		})
	}
}
