import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import ModelSelectWidget, {
	MODEL_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/modelSelect/ModelSelect'
import { CLASSNAME as IA_CLASSNAME } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import BaseWidget from '../baseWidget/BaseWidget'

export { MODEL_SELECT_WIDGET_ID }

const ModelSelect = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT } = props

	const { id } = formLineEntity

	const linkedSelectAndLabel = AutoComplete.getIdsPropsHelper(id)
	const { labelId, inputId } = linkedSelectAndLabel

	return (
		<BaseWidget formLineEntity={formLineEntity} labelId={labelId} inputId={inputId}>
			<div className={`${IA_CLASSNAME}__input`}>
				<ModelSelectWidget {...props} {...linkedSelectAndLabel} />
			</div>
		</BaseWidget>
	)
}

ModelSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	formLineEntityForBrand: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func
}

export default React.memo(ModelSelect)
