import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Button } from '@sznds/react'
import { Responsive } from '@inzeraty/components'
import AutoComplete, { filterItemsIgnoreSpecChars } from 'app/component/autoComplete/AutoComplete'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import AutoCompleteFilteredForm from 'app/component/autoComplete/forms/filtered/AutoCompleteFilteredForm'
import AutoCompleteAllForm from 'app/component/autoComplete/forms/all/AutoCompleteAllForm'
import { RESPONSIVE } from 'app/base/Constants'
import { useLocalize } from 'app/base/componentHelpers'
import { sortModelsAlphabetically } from 'app/helpers/models/ModelsHelper'

export const MODEL_SELECT_WIDGET_ID = 'model'

import './ModelSelectCS.json'
import './ModelSelect.less'

const CLASSNAME = 'c-model-select'

const ModelSelect = (props) => {
	const localize = useLocalize()

	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		formLineEntityForBrand = DEFAULT_PROPS.OBJECT,
		onChange = DEFAULT_PROPS.FUNCTION
	} = props

	const [searchedModelName, setSearchedModelName] = useState('')

	useEffect(() => {
		const { value: { name: modelName = '' } = {}, options = [] } = formLineEntity

		if (!modelName && !options.length) {
			setSearchedModelName('')
		} else if (!searchedModelName) {
			setSearchedModelName(modelName)
		}
	}, [formLineEntity])

	useEffect(() => {
		const { value: { value: brandValue } = {} } = formLineEntityForBrand || {}

		if (!brandValue) {
			setSearchedModelName('')
		}
	}, [formLineEntityForBrand])

	const renderNoOpts = (searchTerm, downshift) => {
		const { options = [] } = formLineEntity

		const othersOpt = options.find((option) => {
			const text = Format.removeDiacritics(option.name).toLowerCase()
			return text === 'ostatni'
		})

		return (
			<div className={`${CLASSNAME}__no-opts-wrap`}>
				<div className={`${CLASSNAME}__no-opts-title`}>
					{localize('ModelSelect.noOptsTitle', { TERM: searchTerm })}
				</div>
				{localize('ModelSelect.noOptsText')}
				<Button
					className={`${CLASSNAME}__no-opts-btn`}
					onClick={() => {
						setSearchedModelName(othersOpt.name)
						handleOnChange({
							value: othersOpt.value,
							name: othersOpt.name
						})
						downshift.closeMenu()
					}}
					text={localize('ModelSelect.othersBtnText')}
				/>
			</div>
		)
	}

	const handleOnChange = (newValue) => {
		const { id } = formLineEntity

		onChange({
			id,
			value: newValue,
			errorMessage: undefined
		})
	}

	const handleInputValueChange = (inputValue) => {
		setSearchedModelName(inputValue)

		const { options = [] } = formLineEntity

		const option = options.find((o) => o.name === inputValue)

		if (option) {
			handleOnChange({
				name: option.name,
				value: option.value
			})
		} else {
			// dilci zmeny nechceme ukladat do formline, ale
			// az platnou hodnotu
			handleOnChange(undefined)
		}
	}

	const handleSelect = (selectedItem) => {
		if (selectedItem) {
			handleOnChange({
				name: selectedItem.name,
				value: selectedItem.value
			})
		}
	}

	const renderItems = (items, downshift) => {
		const { inputValue, getItemProps, highlightedIndex } = downshift

		const renderItem = (option, index) => {
			const { value: modelValue, name } = option

			return (
				<AutoCompleteOption
					{...getItemProps({
						key: modelValue,
						index,
						item: option,
						isHighlighted: highlightedIndex === index,
						className: `${CLASSNAME}__item`
					})}
				>
					<span className={`${CLASSNAME}__name`}>{name}</span>
				</AutoCompleteOption>
			)
		}

		return inputValue ? (
			<AutoCompleteFilteredForm
				filteredItemsLabel={localize('ModelSelect.labelFound')}
				filteredItems={sortModelsAlphabetically(items)}
				renderItem={renderItem}
			/>
		) : (
			<AutoCompleteAllForm
				allItemsLabel={localize('ModelSelect.labelAll')}
				items={sortModelsAlphabetically(items)}
				favoriteItemsLabel={localize('ModelSelect.labelFavorite')}
				favoriteItems={sortModelsAlphabetically(items.filter((option) => option.isFavorite))}
				renderItem={renderItem}
				renderFavoriteItem={renderItem}
			/>
		)
	}

	const renderInput = (formLineEntity, downshift) => {
		const {
			getInputProps,
			getToggleButtonProps,
			getClearButtonProps,

			isOpen,
			inputValue
		} = downshift

		const { options = [], extra: { cebiaError = false } = {} } = formLineEntity

		const { value: { value: brandValue } = {} } = formLineEntityForBrand || {}

		const filteredItems = options.filter((option) =>
			filterItemsIgnoreSpecChars(inputValue, option.name)
		)

		const areModelsLoaded = brandValue
			? formLineEntity.options
				? formLineEntity.options.length
				: false
			: true

		return (
			<div className={`${CLASSNAME}__content`}>
				<div className={`${CLASSNAME}__wrapper`}>
					<AutoCompleteInput
						isLoading={!areModelsLoaded}
						inputSurfaceProps={{
							className: classnames({
								'c-basic-widget__cebia-warn': cebiaError
							})
						}}
						inputProps={getInputProps({
							name: formLineEntity.name,
							className: formLineEntity.className,
							placeholder: formLineEntity.placeholder,
							required: formLineEntity.required,
							disabled: brandValue ? formLineEntity.disabled : true,
							error: !!formLineEntity.errorMessage
						})}
						toggleButtonProps={
							!inputValue
								? getToggleButtonProps({
										isOpen
								  })
								: undefined
						}
						clearButtonProps={inputValue ? getClearButtonProps() : undefined}
					/>

					<Responsive
						breakpoint={RESPONSIVE.TABLET}
						renderMobileElement={() => renderPopup(formLineEntity, filteredItems, downshift)}
						renderDesktopElement={() => renderDropdown(formLineEntity, filteredItems, downshift)}
					/>
				</div>
			</div>
		)
	}

	const renderDropdown = (formLineEntity, filteredItems, downshift) => {
		const {
			isOpen,

			getDropdownProps,
			renderDropdown: Dropdown
		} = downshift

		return (
			isOpen && (
				<Dropdown {...getDropdownProps()}>
					{filteredItems.length
						? renderItems(filteredItems, downshift)
						: renderNoOpts(searchedModelName, downshift)}
				</Dropdown>
			)
		)
	}

	const renderPopup = (formLineEntity, filteredItems, downshift) => {
		const {
			isOpen,
			inputValue,

			getInputProps,
			getClearButtonProps,
			getPopupProps,
			renderPopup: Popup
		} = downshift

		return (
			isOpen && (
				<Popup
					{...getPopupProps({
						title: formLineEntity.label
					})}
				>
					<div className={`${CLASSNAME}__popup-input-wrapper`}>
						<AutoCompleteInput
							inputProps={getInputProps({
								placeholder: formLineEntity.placeholder,
								autoFocus: true
							})}
							clearButtonProps={inputValue ? getClearButtonProps() : undefined}
						/>
					</div>

					{filteredItems.length
						? renderItems(filteredItems, downshift)
						: renderNoOpts(searchedModelName, downshift)}
				</Popup>
			)
		)
	}

	return (
		<AutoComplete
			{...props}
			inputValue={searchedModelName}
			onInputValueChange={handleInputValueChange}
			onSelect={handleSelect}
		>
			{(downshift) => renderInput(formLineEntity, downshift)}
		</AutoComplete>
	)
}

ModelSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	formLineEntityForBrand: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func
}

ModelSelect.defaultProps = {
	onChange: DEFAULT_PROPS.FUNCTION
}

export default React.memo(ModelSelect)
