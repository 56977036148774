import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import PropTypes from 'prop-types'
import { Validation, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import { Input } from '@sznds/react'
import BasicCompWidget from '../basic/BasicCompWidget'

import './PhoneCompWidget.less'
import './PhoneCompWidgetCS.json'

export const PHONE_WIDGET_ID = 'phone_id'

const CLASSNAME = 'c-phone-comp-widget'

const PhoneCompWidget = ({
	formLineEntity = DEFAULT_PROPS.OBJECT,
	onChange = DEFAULT_PROPS.FUNCTION,
	onBlur
}) => {
	const localize = useLocalize()

	return (
		<BasicCompWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={PHONE_WIDGET_ID}
			renderInput={(formLineEntity, inputProps) => {
				const {
					id,
					placeholder = '',
					disabled = false,
					value,
					required,
					errorMessage
				} = formLineEntity

				return (
					<div className={`${CLASSNAME}__input-wrapper`}>
						<Input
							placeholder={placeholder}
							disabled={disabled}
							value={value}
							required={required}
							error={!!errorMessage}
							{...inputProps}
							className={`${CLASSNAME}__input`}
							type='tel'
							onChange={(event) => {
								const value = event.target.value

								onChange({
									id,
									value,
									errorMessage: ''
								})
							}}
							onBlur={(event) => {
								if (onBlur) {
									onBlur(event, id)
								} else {
									const value = event.target.value
									const isValidePhone = Validation.checkPhone(value)

									let errorMessage = ''

									if (!isValidePhone) {
										errorMessage = value
											? localize('PhoneCompWidget.not_valid')
											: localize('PhoneCompWidget.required')
									}

									onChange({
										id,
										value,
										errorMessage
									})
								}
							}}
						/>
					</div>
				)
			}}
		/>
	)
}

PhoneCompWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func
}

export default React.memo(PhoneCompWidget)
