import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import UserwebAdvert from 'app/component/adverts/UserwebAdvertView'
import { useLocalize } from 'app/base/componentHelpers'
import { Surface } from '@sznds/react'
import classnames from 'classnames'
import { CLICK_FROM_VALUES } from '../AdvertListConstants'
import { addDetailInfoToLocalStorage } from '../AdvertListHelper'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import ImaContext from 'ima/page/context'
import { getSearchTypeSession } from 'app/helpers/sessionStorageHelpers/getSearchTypeSessionHelper'

import './PreferredListCS.json'
import './PreferredList.less'

const CLASSNAME = 'c-preferred-list'

const PreferredList = ({ preferredList, className, tagName, imageLazyload }) => {
	const localize = useLocalize()
	const { DotAnalytic, $SessionStorage } = useContext(ImaContext)

	const handleAdvertClick = (preferredAdvert) => {
		const { lastUrlRouteParams, ...searchTypeSession } = getSearchTypeSession($SessionStorage) || {}

		DotAnalytic.hit({
			action: 'list-to-advert-detail',
			...searchTypeSession,
			categoryId: preferredAdvert?.category?.id
		})
		addDetailInfoToLocalStorage(preferredAdvert?.id, CLICK_FROM_VALUES.FROM_PREFERRED_OFFER)
	}

	if (preferredList.length > 0) {
		return (
			<Surface
				surface={1}
				className={classnames({
					[CLASSNAME]: true,
					[className]: !!className
				})}
				tagName={tagName}
			>
				<div className={`${CLASSNAME}__header`}>{localize('PreferredList.title')}</div>
				<ul className={`${CLASSNAME}__list`}>
					{preferredList.map((preferredAdvert, index) => (
						<UserwebAdvert
							onHandle={() => handleAdvertClick(preferredAdvert)}
							advertEntity={preferredAdvert}
							key={preferredAdvert.id}
							imageLazyload={imageLazyload}
							index={index}
						/>
					))}
				</ul>
			</Surface>
		)
	} else {
		return null
	}
}

PreferredList.propTypes = {
	preferredList: PropTypes.arrayOf(PropTypes.instanceOf(advertPropTypes)),
	className: PropTypes.string,
	tagName: PropTypes.string,
	imageLazyload: PropTypes.bool
}

PreferredList.defaultProps = {
	preferredList: [],
	className: '',
	tagName: 'div',
	imageLazyload: false
}

export default React.memo(PreferredList)
