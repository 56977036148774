import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import ROUTE_NAMES from 'app/base/RouteNames'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

export default class IAPremiseHistoryController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, premiseService) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._router = this._utils.$Router
		this._premiseService = premiseService
	}

	init() {
		super.init()
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			premiseEntity: this._getPremise(),
			entitiesAndPagination: this._getPremiseHistory(),
			isLoading: true,
			[AbstractInternalAdminController.stateId.GO_BACK_LIST_URL]: this._getBackUrl(
				ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_LIST
			),
			[AbstractInternalAdminController.stateId.DIRECT_LINK_DATA]: this._getDirectLinkData()
		})
	}

	update() {
		return {
			entitiesAndPagination: this._getPremiseHistory(),
			isLoading: true
		}
	}

	_getPremise() {
		const {
			[InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID]: premiseId
		} = this.getRouteParams()
		return this._premiseService.getPremise(premiseId, {}, { cache: false })
	}

	_getDirectLinkData() {
		const {
			[InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID]: premiseId
		} = this.getRouteParams()

		return {
			text: 'Editace firmy',
			url: this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_EDIT, { premiseId })
		}
	}

	async _getPremiseHistory() {
		const routeParams = this.getRouteParams()
		const { [InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID]: premiseId } = routeParams
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = '-id'
		}

		this._pageLoaderExtension.show()

		const {
			premiseHistoryEntities,
			paginationEntity
		} = await this._premiseService.getPremiseHistory(premiseId, params, { cache: false })

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return {
			entities: premiseHistoryEntities,
			paginationEntity
		}
	}
}
