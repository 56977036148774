//import vendorLinker from 'ima/vendorLinker'
import { LoginHelper } from '@ima/plugin-login'
import { DotAnalytic } from '@ima/plugin-analytic-dot'
import { GemiusAnalytic } from '@ima/plugin-analytic-gemius'
import { UserAgent } from '@ima/plugin-useragent'
import { HistoryStorage } from '@ima/plugin-history-storage'
import { BFCache } from '@ima/plugin-bfcache'
import {
	PaginationFactory,
	AdvertAttachmentsService,
	AdvertAttachmentsResource,
	CategoryFactory,
	CategoryResource,
	CategoryService,
	CodebooksFactory,
	LocalityFactory,
	LocalityResource,
	LocalitySuggestFactory,
	LocalitySuggestResource,
	LocalityService,
	PhoneResource,
	PhoneService,
	EmailService,
	EmailResource,
	UserFactory,
	UserHistoryFactory,
	UserResource,
	AdminService,
	AdminResource,
	AdminFactory,
	LogoutService,
	LogoutResource,
	RoleFactory,
	RoleResource,
	RoleService,
	SoftwareKeyFactory,
	SoftwareKeyResource,
	SoftwareKeyService,
	ArticleFactory,
	ArticleResource,
	ArticleService,
	FavoriteResource,
	AdvertReportsFactory,
	AdvertReportsResource,
	AdvertReportsService,
	WatchDogResource as MySavedSearchResource,
	WatchDogService as MySavedSearchService,
	RibbonService,
	RibbonResource
} from '@inzeraty/models'

import FeedbackFactory from 'app/model/feedback/FeedbackFactory'
import FeedbackResource from 'app/model/feedback/FeedbackResource'
import FeedbackService from 'app/model/feedback/FeedbackService'

import UserService from 'app/model/user/UserService'

import FavoriteService from 'app/model/favorite/FavoriteService'

import { ScriptLoaderPlugin } from 'ima-plugin-script-loader'
import DrtgHelper from 'app/helpers/drtg/DrtgHelper'
import CacheHelper from 'app/helpers/cache/cacheHelper'
import ABTestHelper from 'app/helpers/abTest/ABTestHelper'
import MySavedSearchHelper from 'app/component/mySavedSearch/MySavedSearchHelper'

import CmpHelper from 'app/helpers/cmpHelper/CmpHelper'

import AutoScrollManager from 'app/helpers/autoScrollManager/AutoScrollManager'

import AdvertFactory from 'app/model/advert/AdvertFactory'
import AdvertService from 'app/model/advert/AdvertService'
import AdvertResource from 'app/model/advert/AdvertResource'

import AdvertHistoryFactory from 'app/model/advert/history/AdvertHistoryFactory'

import AdvertStatisticsResource from 'app/model/advert/statistics/AdvertStatisticsResource'
import AdvertStatisticsFactory from 'app/model/advert/statistics/AdvertStatisticsFactory'
import AdvertStatisticsService from 'app/model/advert/statistics/AdvertStatisticsService'

import FilterService from 'app/model/filter/FilterService'
import FilterResource from 'app/model/filter/FilterResource'
import FilterFactory from 'app/model/filter/FilterFactory'
import FilterHelper from 'app/model/filter/FilterHelper'

import RibbonSearchService from 'app/model/ribbonSearch/RibbonSearchService'
import RibbonSearchResource from 'app/model/ribbonSearch/RibbonSearchResource'
import RibbonSearchFactory from 'app/model/ribbonSearch/RibbonSearchFactory'
import RibbonSearchDetectedFiltersFactory from 'app/model/ribbonSearch/RibbonSearchDetectedFiltersFactory'

import AdvertCountsService from 'app/model/filter/advertCounts/AdvertCountsService'
import AdvertCountsResource from 'app/model/filter/advertCounts/AdvertCountsResource'
import AdvertCountsFactory from 'app/model/filter/advertCounts/AdvertCountsFactory'

import PremiseService from 'app/model/premise/PremiseService'
import PremiseResource from 'app/model/premise/PremiseResource'
import PremiseFactoryPublic from 'app/model/premise/PremiseFactoryPublic'
import PremiseFactory from 'app/model/premise/PremiseFactory'
import PremiseFactoryExport from 'app/model/premise/PremiseFactoryExport'
import PremiseFactoryHistory from 'app/model/premise/PremiseFactoryHistory'
import PremiseFactoryImportLog from 'app/model/premise/PremiseFactoryImportLog'
import PremiseFactoryImportLogItem from 'app/model/premise/PremiseFactoryImportLogItem'
import PremiseWalletFactory from 'app/model/premise/PremiseWalletFactory'

import VINVehicleDataService from 'app/model/vin/VINVehicleDataService'
import VINVehicleDataResource from 'app/model/vin/VINVehicleDataResource'
import VINVehicleDataFactory from 'app/model/vin/VINVehicleDataFactory'

import PaymentService from 'app/model/payment/PaymentService'
import PaymentResource from 'app/model/payment/PaymentResource'
import PaymentFactory from 'app/model/payment/PaymentFactory'

import ReviewService from 'app/model/review/ReviewService'
import ReviewResource from 'app/model/review/ReviewResource'
import ReviewFactory from 'app/model/review/ReviewFactory'
import ReviewReplyFactory from 'app/model/review/ReviewReplyFactory'

import CarCompareResource from 'app/model/carCompare/CarCompareResource'
import CarCompareFactory from 'app/model/carCompare/CarCompareFactory'
import CarCompareService from 'app/model/carCompare/CarCompareService'

import CatalogResource from 'app/model/catalog/CatalogResource'
import CatalogService from 'app/model/catalog/CatalogService'
import BrandsCatalogFactory from 'app/model/catalog/BrandsCatalogFactory'
import ModelsCatalogFactory from 'app/model/catalog/ModelsCatalogFactory'
import ModelLinesOverviewCatalogFactory from 'app/model/catalog/ModelLinesOverviewCatalogFactory'
import ModificationsFactory from 'app/model/catalog/ModificationsFactory'
import ModificationDetailFactory from 'app/model/catalog/ModificationDetailFactory'
import MySavedSearchFactory from 'app/model/mySavedSearch/MySavedSearchFactory'

import AnalyticsDataSender from 'app/helpers/analyticsDataSender/AnalyticsDataSender'
import BlueimpLoadImageLoader from 'app/helpers/blueimpLoadImageLoader/BlueimpLoadImageLoader'
import CanvasToBlob from 'app/helpers/canvasToBlob/CanvasToBlob'

import Error500Controller from 'app/page/error/Error500Controller'
import Error4xxController from 'app/page/error/Error4xxController'

import CodebooksResource from 'app/model/codebooks/CodebooksResource'
import CodebooksService from 'app/model/codebooks/CodebooksService'
import CodebooksValuesFactory from 'app/model/codebooks/CodebooksValuesFactory'

import ArticlesResource from 'app/model/articles/ArticlesResource'
import ArticlesFactory from 'app/model/articles/ArticlesFactory'
import ArticlesDetailFactory from 'app/model/articles/ArticlesDetailFactory'
import ArticlesService from 'app/model/articles/ArticlesService'

import OperatingLeaseResource from 'app/model/operatingLease/OperatingLeaseResource'
import OperatingLeaseFactory from 'app/model/operatingLease/OperatingLeaseFactory'
import OperatingLeaseService from 'app/model/operatingLease/OperatingLeaseService'

import UserwebHomeController from 'app/page/userweb/home/HomeController'
import UserwebAdvertListController from 'app/page/userweb/advertList/AdvertListController'
import UserwebAdvertDetailController from 'app/page/userweb/advertDetail/AdvertDetailController'
import UserwebSellerController from 'app/page/userweb/seller/SellerController'
import UserwebSellerListController from 'app/page/userweb/sellerList/SellerListController'
import UserwebArticlesController from 'app/page/userweb/articles/ArticlesController'
import UserwebAddVINController from 'app/page/userweb/newAdvert/addVIN/AddVINController'
import UserwebBasicInfoController from 'app/page/userweb/newAdvert/basicInfo/BasicInfoController'
import UserwebPhotosController from 'app/page/userweb/newAdvert/photos/PhotosController'
import UserwebHistoryController from 'app/page/userweb/newAdvert/history/HistoryController'
import UserwebEquipmentController from 'app/page/userweb/newAdvert/equipment/EquipmentController'
import UserwebPaymentController from 'app/page/userweb/newAdvert/payment/PaymentController'
import UserwebPaymentConfirmationController from 'app/page/userweb/newAdvert/paymentConfirmation/PaymentConfirmationController'
import ClientAdvertListController from 'app/page/userweb/clientAdvertList/ClientAdvertListController'
import MySavedSearchController from 'app/page/userweb/mySavedSearch/MySavedSearchController'
import ClientProfileController from 'app/page/userweb/clientProfile/ClientProfileController'
import ClientStatisticsController from 'app/page/userweb/clientStatistics/ClientStatisticsController'
import ClientBoardBehindWindscreenController from 'app/page/userweb/boardBehindWindscreen/BoardBehindWindscreenController'
import FavoriteListController from 'app/page/userweb/favoriteList/FavoriteListController'
import BrandsCatalogController from 'app/page/userweb/catalog/brandsCatalog/BrandsCatalogController'
import ModelsCatalogController from 'app/page/userweb/catalog/modelsCatalog/ModelsCatalogController'
import ModelLinesOverviewCatalogController from 'app/page/userweb/catalog/modelLinesOverviewCatalog/ModelLinesOverviewController'
import ModelLineModificationsCatalogController from 'app/page/userweb/catalog/modelLineModifications/ModelLineModificationsController'
import ModificationDetailController from 'app/page/userweb/catalog/modificationDetail/ModificationDetailController'
import ImportDocumentationController from 'app/page/userweb/importDocumentation/ImportDocumentationController'
import PromoCebiaController from 'app/page/userweb/promoCebia/PromoCebiaController'
import PromoInsertionController from 'app/page/userweb/promoInsertion/PromoInsertionController'
import RegisterCompanyController from 'app/page/userweb/registerCompany/RegisterCompanyController'

import InternalAdminHelper from 'app/helpers/internalAdmin/InternalAdminHelper'
import IAAdvertListController from 'app/page/internalAdmin/advertList/IAAdvertListController'
import IAAdvertListReportedController from 'app/page/internalAdmin/advertListReported/IAAdvertListReportedController'
import IAAdvertReportController from 'app/page/internalAdmin/advertReport/IAAdvertReportController'
import IAAdvertHistoryController from 'app/page/internalAdmin/advertHistory/IAAdvertHistoryController'
import IAPremiseListController from 'app/page/internalAdmin/premiseList/IAPremiseListController'
import IAPremiseEditController from 'app/page/internalAdmin/premiseEdit/IAPremiseEditController'
import IAPremiseHistoryController from 'app/page/internalAdmin/premiseHistory/IAPremiseHistoryController'
import IAImportListController from 'app/page/internalAdmin/importList/IAImportListController'
import IAImportDetailController from 'app/page/internalAdmin/importDetail/IAImportDetailController'
import IAUserListController from 'app/page/internalAdmin/userList/IAUserListController'
import IAUserEditController from 'app/page/internalAdmin/userEdit/IAUserEditController'
import IAAdminListController from 'app/page/internalAdmin/adminList/IAAdminListController'
import IAFeedbackListController from 'app/page/internalAdmin/feedbackList/IAFeedbackListController'
import IAAdvertBasicInfoEditController from 'app/page/internalAdmin/advertEdit/basicInfo/IAAdvertBasicInfoEditController'
import IAAdvertPhotosEditController from 'app/page/internalAdmin/advertEdit/photos/IAAdvertPhotosEditController'
import IARoleListController from 'app/page/internalAdmin/roleList/IARoleListController'
import IARightsController from 'app/page/internalAdmin/rights/IARightsController'
import IAForbiddenAccessController from 'app/page/internalAdmin/forbiddenAccess/IAForbiddenAccessController'
import IAEquipmentController from 'app/page/internalAdmin/advertEdit/equipment/IAEquipmentController'
import IAHistoryController from 'app/page/internalAdmin/advertEdit/history/IAHistoryController'
import IAStatisticsController from 'app/page/internalAdmin/statistics/IAStatisticsController'
import IAPaymentsController from 'app/page/internalAdmin/payments/IAPaymentsController'
import IASeoFooterEditController from 'app/page/internalAdmin/seoFooterEdit/IASeoFooterEditController'
import IACodebookEquipmentListController from 'app/page/internalAdmin/codebookList/equipment/IACodebookEquipmentListController'
import IACodebookManufacturerListController from 'app/page/internalAdmin/codebookList/manufacturer/IACodebookManufacturerListController'
import IACodebookModelListController from 'app/page/internalAdmin/codebookList/model/IACodebookModelListController'
import IAArticlesListController from 'app/page/internalAdmin/articlesList/IAArticlesListController'
import IAArticlesDetailController from 'app/page/internalAdmin/articlesDetail/IAArticlesDetailController'

import FiltersExtension from 'app/component/filters/FiltersExtension'
import EntitiesFilterExtension from 'app/component/entitiesFilter/EntitiesFilterExtension'
import EntityFormExtension from 'app/component/entityForm/EntityFormExtension'
import PopupDemandFormExtension from 'app/page/userweb/advertDetail/sellerContactSection/popupDemandForm/PopupDemandFormExtension'
import { PopupFeedbackFormExtension } from '@inzeraty/components'
import AdvertListExtension from 'app/component/advertList/AdvertListExtension'
import ClientProfileValidationExtension from 'app/page/userweb/clientProfile/ClientProfileValidationExtension'

import ContactSectionExtension from 'app/page/userweb/newAdvert/basicInfo/extensions/ContactSectionExtension'
import PhotosUploaderExtension from 'app/component/photosUploader/PhotosUploaderExtension'
import ReviewsExtension from 'app/component/reviews/ReviewsExtension'
import VideoUploaderExtension from 'app/component/videoUploader/VideoUploaderExtension'
import FavoriteExtension from 'app/component/favorite/FavoriteExtension'
import LocalitySelectInputExtension from 'app/component/localitySelectInput/LocalitySelectInputExtension'
import BackToHpExtension from 'app/component/backToHpButton/BackToHpExtension'
import ReportExtension from 'app/component/report/ReportExtension'
import PageLoaderExtension from 'app/component/pageLoader/PageLoaderExtension'
import RibbonSearchExtension from 'app/component/ribbon/ribbonSearch/RibbonSearchExtension'


import PageStateManager from 'ima/page/state/PageStateManager'
import PageHandlerRegistry from 'ima/page/handler/PageHandlerRegistry'
import { BasePageHandler, Geolocation } from '@inzeraty/helpers'
import SspHelper from 'app/helpers/sspHelper/SspHelper'

import ServerRouter from 'ima/router/ServerRouter'
import Router from 'ima/router/Router'
import CustomClientRouter from 'app/base/CustomClientRouter'

import UserwebDependenciesHelper from 'app/helpers/dependenciesHelper/UserwebDependenciesHelper'
import InternalAdminDependenciesHelper from 'app/helpers/dependenciesHelper/InternalAdminDependenciesHelper'
import RestClient from 'app/base/RestClient'
import IASoftwareKeyListController from 'app/page/internalAdmin/softwareKeyList/IASoftwareKeyListController'

import IARegistrationsListController from '../page/internalAdmin/registrationsList/IARegistrationsListController'
import IARegistrationIncrasesListController from '../page/internalAdmin/registrationIncrasesList/IARegistrationIncrasesListController'
import IAPreferredOffersController from '../page/internalAdmin/preferredOffers/IAPreferredOffersController'
import IAMirroringListController from 'app/page/internalAdmin/mirroringList/IAMirroringListController'

import IAReviewListController from 'app/page/internalAdmin/reviewList/IAReviewListController'
import IAReviewReplyListController from 'app/page/internalAdmin/reviewReplyList/IAReviewReplyListController'
import ToppedPaymentController from 'app/page/userweb/clientAdvertList/toppedPayment/ToppedPaymentController'

import RegistrationFactory from '../model/registration/RegistrationFactory'
import RegistrationResource from '../model/registration/RegistrationResource'
import RegistrationService from '../model/registration/RegistrationService'

import PreferredOffersFactory from '../model/preferredOffers/PreferredOffersFactory'
import PreferredOffersResource from '../model/preferredOffers/PreferredOffersResource'
import PreferredOffersService from '../model/preferredOffers/PreferredOffersService'

import MirroringFactory from 'app/model/mirroring/MirroringFactory'
import MirroringResource from 'app/model/mirroring/MirroringResource'
import MirroringService from 'app/model/mirroring/MirroringService'

import { RibbonExtension } from '@inzeraty/components'

import ForbidUserPopupExtension from 'app/page/internalAdmin/components/forbidUserPopup/ForbidUserPopupExtension.js'
import ForbidAdvertPopupExtension from 'app/page/internalAdmin/components/forbidAdvertPopup/ForbidAdvertPopupExtension'

import SeoService from '../model/seo/SeoService'
import SeoResource from '../model/seo/SeoResource'

export const init = (ns, oc, config) => {
	oc.constant('BASE_URL', oc.get('$Settings').api.baseUrl)

	oc.bind('UserAgent', UserAgent)
	oc.bind('LoginHelper', LoginHelper)
	oc.bind('DotAnalytic', DotAnalytic)
	oc.bind('GemiusAnalytic', GemiusAnalytic)
	oc.bind('BFCache', BFCache)
	oc.bind('HistoryStorage', HistoryStorage)
	oc.bind('AnalyticsDataSender', AnalyticsDataSender, [DotAnalytic, '$SessionStorage'])
	oc.bind('BlueimpLoadImageLoader', BlueimpLoadImageLoader, ['$Window', ScriptLoaderPlugin])
	oc.bind('CanvasToBlob', CanvasToBlob, ['$Window', ScriptLoaderPlugin])
	oc.bind('ScriptLoaderPlugin', ScriptLoaderPlugin)
	oc.bind('SspHelper', SspHelper, [
		'$Settings',
		'$Window',
		'$Dispatcher',
		ScriptLoaderPlugin,
		DotAnalytic
	])
	oc.bind('DrtgHelper', DrtgHelper, [AnalyticsDataSender])
	oc.bind('CacheHelper', CacheHelper)
	oc.bind('Geolocation', Geolocation, ['$Window', '$Settings', ScriptLoaderPlugin])

	if (oc.get('$Window').isClient()) {
		// prepiseme defaultni klientsky router
		oc.provide(Router, CustomClientRouter)
	} else {
		oc.provide(Router, ServerRouter)
	}
	oc.bind('$Router', Router)

	oc.bind('RestClient', RestClient, [
		'$Settings',
		'$Http',
		'$Request',
		'$Window',
		'$Cache',
		'UserAgent',
		'$Router',
		'$CookieStorage'
	])

	oc.bind('CmpHelper', CmpHelper, ['$CookieStorage'])

	oc.bind('AutoScrollManager', AutoScrollManager, ['$Router', '$Dispatcher'])

	oc.bind('ABTestHelper', ABTestHelper, ['$CookieStorage', DotAnalytic, 'CmpHelper'])

	oc.inject(BasePageHandler, ['$Window'])

	if (oc.get('$Window').isClient()) {
		oc.inject(PageHandlerRegistry, [BasePageHandler])
	} else {
		oc.inject(PageHandlerRegistry, [])
	}

	oc.inject(PaginationFactory, [])

	oc.inject(AdvertFactory, [PremiseFactoryPublic])
	oc.inject(AdvertHistoryFactory, [])
	oc.inject(AdvertResource, ['RestClient'])
	oc.bind('AdvertService', AdvertService, [
		AdvertResource,
		AdvertFactory,
		AdvertHistoryFactory,
		PaginationFactory,
		'CacheHelper',
		'HistoryStorage'
	])

	oc.inject(AdvertStatisticsFactory, [])
	oc.inject(AdvertStatisticsResource, ['RestClient'])
	oc.inject(AdvertStatisticsService, [
		AdvertStatisticsResource,
		AdvertStatisticsFactory,
		PaginationFactory
	])

	oc.inject(AdvertAttachmentsResource, ['RestClient', 'BASE_URL'])
	oc.inject(AdvertAttachmentsService, [AdvertAttachmentsResource])

	oc.inject(CategoryFactory, [])
	oc.inject(CategoryResource, ['RestClient'])
	oc.inject(CategoryService, [CategoryResource, CategoryFactory])

	oc.inject(CodebooksFactory, [])
	oc.inject(CodebooksValuesFactory, [])
	oc.inject(CodebooksResource, ['RestClient'])
	oc.inject(CodebooksService, [
		CodebooksResource,
		CodebooksFactory,
		CodebooksValuesFactory,
		PaginationFactory
	])

	oc.inject(FilterFactory, [CodebooksFactory])
	oc.inject(FilterResource, ['RestClient'])
	oc.bind('FilterService', FilterService, [FilterResource, FilterFactory, CodebooksService])
	oc.bind('FilterHelper', FilterHelper, ['$CookieStorage', 'CmpHelper'])

	oc.inject(SeoService, [SeoResource])
	oc.inject(SeoResource, ['RestClient'])

	oc.inject(AdvertCountsFactory, [])
	oc.inject(AdvertCountsResource, ['RestClient'])
	oc.inject(AdvertCountsService, [AdvertCountsResource, AdvertCountsFactory])

	oc.inject(PremiseFactoryPublic, [])
	oc.inject(PremiseFactory, [])
	oc.inject(PremiseFactoryExport, [])
	oc.inject(PremiseFactoryHistory, [])
	oc.inject(PremiseFactoryImportLog, [])
	oc.inject(PremiseFactoryImportLogItem, [])
	oc.inject(PremiseWalletFactory, [])
	oc.inject(PremiseResource, ['RestClient'])
	oc.inject(PremiseService, [
		PremiseResource,
		PremiseFactoryPublic,
		PremiseFactory,
		PremiseFactoryExport,
		PremiseFactoryHistory,
		PremiseFactoryImportLog,
		PremiseFactoryImportLogItem,
		PaginationFactory,
		PremiseWalletFactory
	])

	oc.inject(AdminFactory, [])
	oc.inject(AdminResource, ['RestClient', '$Cache'])
	oc.inject(AdminService, [AdminResource, AdminFactory, PaginationFactory])

	oc.inject(AdvertReportsFactory, [])
	oc.inject(AdvertReportsResource, ['RestClient'])
	oc.inject(AdvertReportsService, [AdvertReportsResource, AdvertReportsFactory, PaginationFactory])

	oc.inject(VINVehicleDataFactory, [])
	oc.inject(VINVehicleDataResource, ['RestClient'])
	oc.inject(VINVehicleDataService, [VINVehicleDataResource, VINVehicleDataFactory])

	oc.inject(SoftwareKeyFactory, [])
	oc.inject(SoftwareKeyResource, ['RestClient'])
	oc.inject(SoftwareKeyService, [SoftwareKeyResource, SoftwareKeyFactory, PaginationFactory])

	oc.inject(PaymentFactory, [])
	oc.inject(PaymentResource, ['RestClient'])
	oc.inject(PaymentService, [PaymentResource, PaymentFactory])

	oc.inject(ReviewFactory, [])
	oc.inject(ReviewReplyFactory, [])
	oc.inject(ReviewResource, ['RestClient'])
	oc.inject(ReviewService, [ReviewResource, ReviewFactory, ReviewReplyFactory, PaginationFactory])

	oc.inject(CarCompareFactory, [])
	oc.inject(CarCompareResource, ['RestClient'])
	oc.inject(CarCompareService, [CarCompareResource, CarCompareFactory])

	oc.inject(FeedbackFactory, [])
	oc.inject(FeedbackResource, ['RestClient', '$Window'])
	oc.inject(FeedbackService, [FeedbackResource, FeedbackFactory, PaginationFactory])

	oc.inject(ArticlesFactory, [])
	oc.inject(ArticlesDetailFactory, [])
	oc.inject(ArticlesResource, ['RestClient'])
	oc.inject(ArticlesService, [
		ArticlesResource,
		ArticlesFactory,
		ArticlesDetailFactory,
		PaginationFactory
	])

	oc.inject(OperatingLeaseFactory, [])
	oc.inject(OperatingLeaseResource, ['RestClient'])
	oc.inject(OperatingLeaseService, [OperatingLeaseResource, OperatingLeaseFactory])

	oc.inject(PhoneResource, ['RestClient'])
	oc.inject(PhoneService, [PhoneResource])

	oc.inject(FavoriteResource, ['RestClient'])
	oc.inject(FavoriteService, [FavoriteResource, AdvertFactory, PaginationFactory])

	oc.inject(EmailResource, ['RestClient'])
	oc.inject(EmailService, [EmailResource])

	oc.inject(UserFactory, [])
	oc.inject(UserHistoryFactory, [])
	oc.inject(UserResource, ['RestClient'])
	oc.inject(UserService, [UserResource, UserFactory, PaginationFactory, UserHistoryFactory])

	oc.inject(LocalityFactory, [])
	oc.inject(LocalitySuggestFactory, [])
	oc.inject(LocalityResource, ['RestClient'])
	oc.inject(LocalitySuggestResource, ['RestClient'])
	oc.inject(LocalityService, [
		LocalityResource,
		LocalitySuggestResource,
		LocalityFactory,
		LocalitySuggestFactory
	])

	oc.inject(LogoutResource, ['RestClient', '$Cache'])
	oc.inject(LogoutService, [LogoutResource])

	oc.inject(RoleFactory, [])
	oc.inject(RoleResource, ['RestClient'])
	oc.inject(RoleService, [RoleResource, RoleFactory, PaginationFactory])

	oc.inject(SoftwareKeyFactory, [])
	oc.inject(SoftwareKeyResource, ['RestClient'])
	oc.inject(SoftwareKeyService, [SoftwareKeyResource, SoftwareKeyFactory, PaginationFactory])

	oc.inject(ArticleFactory, [])
	oc.inject(ArticleResource, ['RestClient'])
	oc.inject(ArticleService, [ArticleResource, ArticleFactory, PaginationFactory])

	oc.inject(RibbonSearchFactory, [])
	oc.inject(RibbonSearchDetectedFiltersFactory, [])
	oc.inject(RibbonSearchResource, ['RestClient'])
	oc.inject(RibbonSearchService, [
		RibbonSearchResource,
		RibbonSearchFactory,
		RibbonSearchDetectedFiltersFactory
	])

	oc.inject(BrandsCatalogFactory, [])
	oc.inject(ModelsCatalogFactory, [])
	oc.inject(ModelLinesOverviewCatalogFactory, [])
	oc.inject(ModificationsFactory, [])
	oc.inject(ModificationDetailFactory, [])
	oc.inject(CatalogResource, ['RestClient'])
	oc.inject(CatalogService, [
		CatalogResource,
		BrandsCatalogFactory,
		ModelsCatalogFactory,
		ModelLinesOverviewCatalogFactory,
		ModificationsFactory,
		CodebooksService,
		ModificationDetailFactory
	])

	oc.inject(MySavedSearchFactory, [])
	oc.inject(MySavedSearchResource, ['RestClient'])
	oc.inject(MySavedSearchService, [MySavedSearchResource, MySavedSearchFactory, PaginationFactory])

	oc.inject(RibbonResource, ['$Settings', '$Http', '$Cache'])
	oc.inject(RibbonService, [RibbonResource])
	oc.bind('RibbonExtension', RibbonExtension, [RibbonService, '$Utils'])

	oc.inject(RegistrationFactory, [])
	oc.inject(RegistrationResource, ['RestClient'])
	oc.bind('RegistrationService', RegistrationService, [
		RegistrationResource,
		RegistrationFactory,
		PaginationFactory
	])

	oc.inject(PreferredOffersFactory, [])
	oc.inject(PreferredOffersResource, ['RestClient'])
	oc.bind('PreferredOffersService', PreferredOffersService, [
		PreferredOffersResource,
		PreferredOffersFactory,
		PaginationFactory
	])

	oc.inject(MirroringFactory, [])
	oc.inject(MirroringResource, ['RestClient'])
	oc.bind('MirroringService', MirroringService, [
		MirroringResource,
		MirroringFactory,
		PaginationFactory
	])

	oc.bind('InternalAdminHelper', InternalAdminHelper, ['$Router', AdminService])

	//COMPONENT Utils
	oc.constant('$Utils', {
		$Helper: oc.get('$Helper'),
		$Router: oc.get('$Router'),
		$Dispatcher: oc.get('$Dispatcher'),
		$EventBus: oc.get('$EventBus'),
		$Dictionary: oc.get('$Dictionary'),
		$Settings: oc.get('$Settings'),
		$CssClasses: oc.get('$CssClasses'),
		$Window: oc.get('$Window'),
		$CookieStorage: oc.get('$CookieStorage'),
		$SessionStorage: oc.get('$SessionStorage'),
		$PageStateManager: oc.get(PageStateManager),
		UserAgent: oc.get('UserAgent'),
		DotAnalytic: oc.get('DotAnalytic'),
		AnalyticsDataSender: oc.get('AnalyticsDataSender'),
		BlueimpLoadImageLoader: oc.get('BlueimpLoadImageLoader'),
		SspHelper: oc.get('SspHelper'),
		DrtgHelper: oc.get('DrtgHelper'),
		FilterHelper: oc.get('FilterHelper'),
		FilterService: oc.get('FilterService'),
		LoginHelper: oc.get('LoginHelper'),
		Geolocation: oc.get('Geolocation'),
		ABTestHelper: oc.get('ABTestHelper'),
		InternalAdminHelper: oc.get('InternalAdminHelper'),
		CmpHelper: oc.get('CmpHelper')
	})

	oc.bind('MySavedSearchHelper', MySavedSearchHelper, [MySavedSearchService, UserService])

	oc.bind('FiltersExtension', FiltersExtension, [
		FilterService,
		'$Utils',
		AdvertService,
		AdvertCountsService,
		'CacheHelper'
	])
	oc.bind('EntitiesFilterExtension', EntitiesFilterExtension, [CodebooksService])
	oc.bind('EntityFormExtension', EntityFormExtension, [])
	oc.bind('PopupDemandFormExtension', PopupDemandFormExtension, [
		AnalyticsDataSender,
		AdvertService,
		'$Utils'
	])
	oc.bind('PopupFeedbackFormExtension', PopupFeedbackFormExtension, [FeedbackService])
	oc.bind('AdvertListExtension', AdvertListExtension, [
		AdvertService,
		AdvertFactory,
		'$Utils',
		'CacheHelper',
		'HistoryStorage'
	])
	oc.bind('ClientProfileValidationExtension', ClientProfileValidationExtension, ['$Utils'])
	oc.bind('ContactSectionExtension', ContactSectionExtension, [PhoneService, EmailService])
	oc.bind('ReviewsExtension', ReviewsExtension, [
		ReviewService,
		AdvertService,
		'$Utils',
		'$Dispatcher'
	])
	oc.bind('PhotosUploaderExtension', PhotosUploaderExtension, [
		AdvertAttachmentsService,
		'BlueimpLoadImageLoader',
		'CanvasToBlob'
	])
	oc.bind('VideoUploaderExtension', VideoUploaderExtension, [AdvertAttachmentsService])
	oc.bind('FavoriteExtension', FavoriteExtension, [FavoriteService, '$Dispatcher', '$Utils'])
	oc.bind('BackToHpExtension', BackToHpExtension, ['HistoryStorage'])
	oc.bind('ReportExtension', ReportExtension, [AdvertService, '$Utils', '$Dispatcher'])
	oc.bind('PageLoaderExtension', PageLoaderExtension, [])
	oc.bind('RibbonSearchExtension', RibbonSearchExtension, [
		RibbonSearchService,
		CategoryService,
		'$Utils'
	])

	oc.bind('LocalitySelectInputExtension', LocalitySelectInputExtension, [LocalityService])

	oc.constant('UserwebExtensions', {
		popupFeedbackFormExtension: oc.get('PopupFeedbackFormExtension'),
		favoriteExtension: oc.get('FavoriteExtension'),
		ribbonExtension: oc.get('RibbonExtension'),
		ribbonSearchExtension: oc.get('RibbonSearchExtension')
	})
	oc.constant('UserwebDependencies', {
		utils: oc.get('$Utils'),
		historyStorage: oc.get('HistoryStorage'),
		cacheHelper: oc.get('CacheHelper'),
		userService: oc.get(UserService),
		premiseService: oc.get(PremiseService),
		internalAdminHelper: oc.get('InternalAdminHelper'),
		seoService: oc.get(SeoService),
		autoScrollManager: oc.get(AutoScrollManager)
	})
	oc.constant('NewAdvertDependencies', {
		utils: oc.get('$Utils'),
		historyStorage: oc.get('HistoryStorage'),
		cacheHelper: oc.get('CacheHelper'),
		userService: oc.get(UserService),
		advertService: oc.get(AdvertService),
		premiseService: oc.get(PremiseService),
		seoService: oc.get(SeoService)
	})

	oc.constant('InternalAdminExtensions', {
		pageLoaderExtension: oc.get('PageLoaderExtension')
	})
	oc.constant('InternalAdminDependencies', {
		utils: oc.get('$Utils'),
		adminService: oc.get(AdminService),
		logoutService: oc.get(LogoutService),
		premiseService: oc.get(PremiseService),
		historyStorage: oc.get('HistoryStorage'),
		internalAdminHelper: oc.get('InternalAdminHelper')
	})

	oc.bind('UserwebDependenciesHelper', UserwebDependenciesHelper, [
		'UserwebExtensions',
		'UserwebDependencies'
	])
	oc.bind('NewAdvertDependenciesHelper', UserwebDependenciesHelper, [
		'UserwebExtensions',
		'NewAdvertDependencies'
	])
	oc.bind('InternalAdminDependenciesHelper', InternalAdminDependenciesHelper, [
		'InternalAdminExtensions',
		'InternalAdminDependencies'
	])

	// Page Error
	oc.inject(Error500Controller, ['UserwebDependenciesHelper'])

	// Page Not Found
	oc.inject(Error4xxController, ['UserwebDependenciesHelper'])

	// Stránka pro zobrazení komponenty kvůli regresnímu testu

	// USERWEB
	// Homepage
	oc.inject(UserwebHomeController, [
		'UserwebDependenciesHelper',
		CategoryService,
		FiltersExtension,
		AdvertService,
		AdvertCountsService,
		ArticleService,
		MySavedSearchHelper
	])

	//dokumentace importu
	oc.inject(ImportDocumentationController, ['UserwebDependenciesHelper', BackToHpExtension])

	// Seznam inzerátů
	oc.inject(UserwebAdvertListController, [
		'UserwebDependenciesHelper',
		AdvertListExtension,
		CategoryService,
		AdvertFactory,
		FiltersExtension,
		AdvertCountsService,
		MySavedSearchHelper
	])

	// Detail inzerátu
	oc.inject(UserwebAdvertDetailController, [
		'UserwebDependenciesHelper',
		AdvertService,
		PopupDemandFormExtension,
		FavoriteService,
		ReviewsExtension,
		ReportExtension,
		CarCompareService,
		ArticlesService,
		DotAnalytic
	])

	// Stránka prodejce
	oc.inject(UserwebSellerController, [
		'UserwebDependenciesHelper',
		PremiseService,
		AdvertListExtension,
		CategoryService,
		FiltersExtension
	])

	// Seznam prodejců
	oc.inject(UserwebSellerListController, [
		'UserwebDependenciesHelper',
		PremiseService,
		CategoryService,
		BackToHpExtension
	])

	// Promo Cebia
	oc.inject(PromoCebiaController, ['UserwebDependenciesHelper', BackToHpExtension])

	// Promo vkládání
	oc.inject(PromoInsertionController, ['UserwebDependenciesHelper', BackToHpExtension])

	// Registrace firmy
	oc.inject(RegisterCompanyController, ['UserwebDependenciesHelper', PremiseService])

	// Články
	oc.inject(UserwebArticlesController, [
		'UserwebDependenciesHelper',
		ArticleService,
		BackToHpExtension
	])

	// Katalog
	oc.inject(BrandsCatalogController, ['UserwebDependenciesHelper', CatalogService])
	oc.inject(ModelsCatalogController, ['UserwebDependenciesHelper', CatalogService])
	oc.inject(ModelLinesOverviewCatalogController, ['UserwebDependenciesHelper', CatalogService])
	oc.inject(ModelLineModificationsCatalogController, [
		'UserwebDependenciesHelper',
		CatalogService,
		AdvertService
	])
	oc.inject(ModificationDetailController, [
		'UserwebDependenciesHelper',
		CatalogService,
		AdvertService
	])

	// Stranka pro vlozeni noveho inzeratu - prvy krok
	oc.inject(UserwebAddVINController, [
		'NewAdvertDependenciesHelper',
		VINVehicleDataService,
		CodebooksService
	])

	// Stranka pro vlozeni noveho inzeratu - druhy krok
	oc.inject(UserwebBasicInfoController, [
		'NewAdvertDependenciesHelper',
		ContactSectionExtension,
		LocalitySelectInputExtension,
		VINVehicleDataService,
		CodebooksService,
		PremiseService,
		OperatingLeaseService
	])

	// Stranka pro vlozeni noveho inzeratu - treti krok
	oc.inject(UserwebPhotosController, [
		'NewAdvertDependenciesHelper',
		'PhotosUploaderExtension',
		'VideoUploaderExtension'
	])

	// Stranka pro vlozeni noveho inzeratu - ctvrty krok
	oc.inject(UserwebHistoryController, ['NewAdvertDependenciesHelper'])

	// Stranka pro vlozeni noveho inzeratu - paty krok
	oc.inject(UserwebEquipmentController, [
		'NewAdvertDependenciesHelper',
		VINVehicleDataService,
		AdvertService
	])

	// Stranka pro vlozeni noveho inzeratu - sesty krok
	oc.inject(UserwebPaymentController, [
		'NewAdvertDependenciesHelper',
		PaymentService,
		PageLoaderExtension
	])

	// Stranka overeni platby
	oc.inject(UserwebPaymentConfirmationController, ['NewAdvertDependenciesHelper', PaymentService])

	oc.inject(ClientAdvertListController, [
		'UserwebDependenciesHelper',
		AdvertService,
		PremiseService,
		CategoryService,
		CodebooksService,
		PaymentService,
		PageLoaderExtension,
		AdvertStatisticsService
	])

	oc.inject(ClientProfileController, [
		'UserwebDependenciesHelper',
		PremiseService,
		LocalitySelectInputExtension,
		ClientProfileValidationExtension,
		PageLoaderExtension
	])

	oc.inject(ClientBoardBehindWindscreenController, ['UserwebDependenciesHelper', AdvertService])

	oc.inject(FavoriteListController, [
		'UserwebDependenciesHelper',
		FavoriteService,
		BackToHpExtension
	])

	oc.inject(MySavedSearchController, [
		'UserwebDependenciesHelper',
		BackToHpExtension,
		MySavedSearchHelper
	])

	oc.inject(ToppedPaymentController, ['UserwebDependenciesHelper', PaymentService, AdvertService])

	oc.inject(ClientStatisticsController, [
		'UserwebDependenciesHelper',
		AdvertService,
		AdvertStatisticsService,
		PageLoaderExtension
	])

	// INTERNÍ ADMIN
	oc.inject(ForbidUserPopupExtension, [UserService, AdvertReportsService, '$Utils'])
	oc.inject(ForbidAdvertPopupExtension, [AdvertService, AdvertReportsService, '$Utils'])
	oc.inject(IAAdvertListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		AdvertService,
		PaymentService,
		PremiseService,
		ForbidAdvertPopupExtension
	])
	oc.inject(IAAdvertListReportedController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		AdvertReportsService,
		AdvertService,
		UserService,
		ForbidUserPopupExtension,
		ForbidAdvertPopupExtension
	])
	oc.inject(IAAdvertReportController, [
		'InternalAdminDependenciesHelper',
		AdvertService,
		AdvertReportsService,
		UserService,
		ForbidUserPopupExtension,
		ForbidAdvertPopupExtension
	])
	oc.inject(IAAdvertBasicInfoEditController, [
		'InternalAdminDependenciesHelper',
		LocalitySelectInputExtension,
		AdvertService,
		CodebooksService,
		VINVehicleDataService,
		OperatingLeaseService
	])
	oc.inject(IAAdvertPhotosEditController, [
		'InternalAdminDependenciesHelper',
		AdvertService,
		'PhotosUploaderExtension',
		'VideoUploaderExtension'
	])

	oc.inject(IAArticlesListController, [
		'InternalAdminDependenciesHelper',
		ArticlesService,
		EntitiesFilterExtension
	])

	oc.inject(IAArticlesDetailController, [
		'InternalAdminDependenciesHelper',
		ArticlesService,
		EntitiesFilterExtension,
		EntityFormExtension,
		FilterService,
		CodebooksService
	])

	oc.inject(IAEquipmentController, [
		'InternalAdminDependenciesHelper',
		AdvertService,
		VINVehicleDataService
	])
	oc.inject(IAHistoryController, ['InternalAdminDependenciesHelper', AdvertService])
	oc.inject(IAAdvertHistoryController, ['InternalAdminDependenciesHelper', AdvertService])
	oc.inject(IAPremiseListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		SoftwareKeyService
	])
	oc.inject(IAPremiseEditController, [
		'InternalAdminDependenciesHelper',
		PremiseService,
		LocalitySelectInputExtension,
		SoftwareKeyService,
		ClientProfileValidationExtension
	])
	oc.inject(IAPremiseHistoryController, ['InternalAdminDependenciesHelper', PremiseService])
	oc.inject(IAImportListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		PremiseService,
		SoftwareKeyService
	])
	oc.inject(IAImportDetailController, ['InternalAdminDependenciesHelper', PremiseService])
	oc.inject(IAUserListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		UserService
	])
	oc.inject(IAUserEditController, [
		'InternalAdminDependenciesHelper',
		UserService,
		EntityFormExtension
	])
	oc.inject(IAAdminListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		RoleService,
		EntityFormExtension
	])
	oc.inject(IARoleListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		RoleService,
		EntityFormExtension
	])
	oc.inject(IARightsController, [
		'InternalAdminDependenciesHelper',
		EntityFormExtension,
		RoleService
	])
	oc.inject(IAForbiddenAccessController, ['InternalAdminDependenciesHelper'])
	oc.inject(IAStatisticsController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		AdvertStatisticsService
	])
	oc.inject(IAPaymentsController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		AdvertStatisticsService
	])
	oc.inject(IASoftwareKeyListController, [
		'InternalAdminDependenciesHelper',
		SoftwareKeyService,
		EntitiesFilterExtension,
		EntityFormExtension
	])
	oc.inject(IARegistrationsListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		RegistrationService
	])
	oc.inject(IARegistrationIncrasesListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		RegistrationService
	])
	oc.inject(IAPreferredOffersController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		PreferredOffersService
	])
	oc.inject(IAMirroringListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		MirroringService
	])
	oc.inject(IAReviewListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		ReviewService
	])
	oc.inject(IAReviewReplyListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		ReviewService
	])
	oc.inject(IASeoFooterEditController, [
		'InternalAdminDependenciesHelper',
		AdminService,
		SeoService,
		EntityFormExtension
	])
	oc.inject(IACodebookEquipmentListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		CodebooksService,
		EntityFormExtension
	])
	oc.inject(IACodebookManufacturerListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		CodebooksService,
		EntityFormExtension
	])
	oc.inject(IACodebookModelListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		CodebooksService,
		EntityFormExtension
	])
	oc.inject(IAFeedbackListController, [
		'InternalAdminDependenciesHelper',
		EntitiesFilterExtension,
		FeedbackService
	])
}
