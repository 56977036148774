const SCRIPT_LOAD_IMAGE = '/static/js/blueimp-load-image/load-image.all.min.js'
import { uuid } from 'uuidv4'

export default class BlueimpLoadImageLoader {
	constructor(window, scriptLoader) {
		this._window = window
		this._scriptLoader = scriptLoader

		this._isLoaded = false
	}

	load() {
		return new Promise((resolve, reject) => {
			if (this._window.isClient()) {
				if (this._isLoaded) {
					resolve()
				} else {
					this._scriptLoader
						.load(SCRIPT_LOAD_IMAGE)
						.then(() => {
							this._isLoaded = true
							resolve()
						})
						.catch(() => {
							reject()
						})
				}
			} else {
				reject()
			}
		})
	}

	loadImage(source, options) {
		return new Promise((resolve, reject) => {
			this.load().then(() => {
				loadImage(
					source,
					(image, data) => {
						if (image.type === 'error') {
							reject()
						} else {
							resolve({ image, data })
						}
					},
					options
				)
			})
		})
	}

	loadImages(fileArray, options) {
		return Promise.all(
			Array.from(fileArray).map(async (file) => {
				const { image, data } = await this.loadImage(file, options)

				return {
					id: uuid(),
					file,
					data,
					image
				}
			})
		)
	}
}
