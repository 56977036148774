import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Context from 'ima/page/context'
import { Format } from '@inzeraty/helpers'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import ROUTE_NAMES from 'app/base/RouteNames'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { filterBy, filterByValue } from 'app/component/entitiesFilter/EntitiesFilter'
import TextLink from 'app/component/textLink/TextLink'
import { Input, Button } from '@sznds/react'

import './AdvertOwnerInfo.less'

const CLASSNAME = 'c-item-owner-info'

function AdvertReportLink({ advertId }) {
	const { $Router } = useContext(Context)

	const advertReportUrl = $Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_REPORT, {
		[InternalAdminUrlConvertor.constants.ADVERT_ID]: advertId
	})

	return <TextLink href={advertReportUrl}>Nahlášení inzerátu</TextLink>
}

AdvertReportLink.propTypes = {
	advertId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

function LabelAndDefinition({ label, children }) {
	return (
		<div className={`${CLASSNAME}__line`}>
			<span className={`${CLASSNAME}__label`}>{label}</span>
			<div className={`${CLASSNAME}__definition`}>{children}</div>
		</div>
	)
}

LabelAndDefinition.propTypes = {
	label: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const Separator = () => {
	return <span className={`${CLASSNAME}__separator`}>|</span>
}

export default function AdvertOwnerInfo({ advertEntity = {}, submitExpirationDateChange }) {
	const { $Router } = useContext(Context)
	const { premise = {}, user = {}, id: advertId, phone, email, validToDate: validTo } = advertEntity
	const { id: premiseId, name: premiseName } = premise
	const { enabled, id: userId, note = '' } = user
	const [date, setDate] = useState()
	const [time, setTime] = useState()

	function getFilterByAdvertListUrl(by, value) {
		return $Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
			[filterBy]: by,
			[filterByValue]: value
		})
	}

	const onSubmit = (event) => {
		event.preventDefault()
		submitExpirationDateChange(date + 'T' + time + ':00')
	}

	useEffect(() => {
		const formattedDate = validTo ? Format.dateEN(validTo, true) : null
		const formattedTime = validTo
			? Format.padding(validTo.getHours()) + ':' + Format.padding(validTo.getMinutes())
			: null
		setDate(formattedDate)
		setTime(formattedTime)
	}, [validTo])

	if (premiseId) {
		const premiseDetailUrl = $Router.link(ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_EDIT, {
			[InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID]: premiseId
		})
		return (
			<div className={CLASSNAME}>
				<div className={`${CLASSNAME}__title`}>Vlastník</div>
				<LabelAndDefinition label='Název'>
					<TextLink href={premiseDetailUrl}>{premiseName}</TextLink>
					<Separator />
					<TextLink
						href={getFilterByAdvertListUrl(
							InternalAdminUrlConvertor.constants.PREMISE_ID,
							premiseId
						)}
					>
						Zobrazit inzeráty
					</TextLink>
				</LabelAndDefinition>
				<LabelAndDefinition label='Id'>
					{premiseId}, <AdvertReportLink advertId={advertId} />
				</LabelAndDefinition>
			</div>
		)
	} else if (userId) {
		const userDetailUrl = $Router.link(ROUTE_NAMES.INTERNAL_ADMIN.USER_DETAIL, {
			[InternalAdminUrlConvertor.constants.USER_EDIT_ID]: userId
		})

		return (
			<div className={CLASSNAME}>
				<div className={`${CLASSNAME}__title`}>RUS info</div>
				<LabelAndDefinition label='Telefon'>
					<TextLink
						href={getFilterByAdvertListUrl(InternalAdminUrlConvertor.constants.PHONE, phone)}
					>
						{Format.phoneNumber(phone)}
					</TextLink>
				</LabelAndDefinition>
				<LabelAndDefinition label='Email'>
					<TextLink href={userDetailUrl}>{email}</TextLink>
					<Separator />
					<TextLink
						href={getFilterByAdvertListUrl(InternalAdminUrlConvertor.constants.EMAIL, email)}
					>
						Zobrazit inzeráty
					</TextLink>
				</LabelAndDefinition>
				<LabelAndDefinition label='Expirace inzerátu'>
					<form onSubmit={onSubmit}>
						<Input
							size='small'
							className={`${CLASSNAME}__date-pick`}
							type='date'
							value={date}
							onChange={(event) => {
								setDate(event.target.value)
							}}
						/>
						<Input
							size='small'
							className={`${CLASSNAME}__date-pick`}
							type='time'
							value={time}
							onChange={(event) => {
								setTime(event.target.value)
							}}
						/>
						<Button size='small' text='Uložit' primary={true} type='submit' />
					</form>
				</LabelAndDefinition>
				<LabelAndDefinition label='Stav uživatele'>
					{enabled ? 'Povolen' : 'Zakázán'} <TextLink href={userDetailUrl}>Upravit</TextLink>
				</LabelAndDefinition>
				<LabelAndDefinition label='RUS admin info'>{note}</LabelAndDefinition>
				<LabelAndDefinition label='Stav zakázání RUSe'>
					<AdvertReportLink advertId={advertId} />
				</LabelAndDefinition>
			</div>
		)
	} else {
		return null
	}
}

AdvertOwnerInfo.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes),
	submitExpirationDateChange: PropTypes.func
}
