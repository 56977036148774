import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import select from 'ima-plugin-select'
import { Button } from '@sznds/react'
import { CLOSE_OUTLINE } from '@sznds/icons'
import {
	Carousel,
	Gallery as GalleryBase,
	GalleryPhoto,
	GallerySpherePhoto,
	GalleryVideo
} from '@inzeraty/components'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { SDN } from 'app/base/Constants'
import IMAGES from 'app/base/ImagesConstants'
import AbstractComponent from 'app/base/AbstractComponent'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import Gallery from 'app/component/gallery/GalleryView'
import CebiaReport from 'app/component/cebiaReport/CebiaReportView'
import AdvertImageResponsiveSettings from 'app/page/userweb/advertDetail/AdvertImageResponsiveSettings'
import STATE_KEYS from 'app/page/userweb/advertDetail/AdvertDetailStateKeys'
import HomeDelivery from 'app/component/homeDelivery/HomeDelivery'
import Favorite from 'app/component/favorite/Favorite'

import './AdvertGallery.less'
import './AdvertGalleryCS.json'

const CLASSNAME = 'c-a-gallery'

const THUMBNAILS_COUNT_MOBILE = 4
const THUMBNAILS_COUNT_DESKTOP = 6
const THUMBNAILS_COUNT_FULLSCREEN = 12 // kdyz je galerie otevrena ve fullscreenu

const VIDEO_POSITION = 1
const SPHERE_PHOTO_POSITION = 3

const GalleryItemTypes = Object.freeze({
	PHOTO: 'photo',
	SPHERE_PHOTO: 'sphere_photo',
	VIDEO: 'video'
})

const AdvertGalleryThumbnails = ({
	items = DEFAULT_PROPS.ARRAY,
	thumbnailsCount, // pocet nahledovych obrazku zobrazenych v jeden okamzik
	selectedImageIndex, // index obrazku aktualne zobrazeneho v galerii
	isFullscreen, // priznak, zda-li jsou nahledy zobrazeny pro galerii, ktere je ve fullscreenu
	onClick = DEFAULT_PROPS.FUNCTION
}) => {
	// ulozime si indexy videi/obrazku do fotogalerie
	const thumbnails = items.map((media, index) => Object.assign({}, media, { index }))

	const thumbnailIndex =
		thumbnails.find((thumbnail) => thumbnail.index === selectedImageIndex)?.index || 0

	// vypocitame si index nahledove fotky, ktera bude zobrazena uplne vlevo. Fotku,
	// ktera je realne vybrana (zobrazena v galerii), chceme v nahledu drzet
	// idealne na druhe pozici. Dale chceme, aby pri pohybu na posledni fotky
	// nevznikaly v nahledu vpravo "prazdne" pozice s zadnymi fotkami.
	const selectedThumbnailIndex = Math.min(
		Math.max(thumbnailIndex - 1, 0),
		thumbnails.length - thumbnailsCount
	)

	const isEnoughThumbnails = isFullscreen
		? thumbnails.length > 0
		: thumbnails.length >= thumbnailsCount

	const renderThumbnailsContent = (thumbnailsCountToRender = thumbnails.length) => {
		return thumbnails.slice(0, thumbnailsCountToRender).map((media, index) => (
			<button
				key={media.data.id}
				className={classnames({
					[`${CLASSNAME}__thumbnail-wrapper`]: true,
					[`${CLASSNAME}__thumbnail-wrapper--fullscreen`]: isFullscreen
				})}
				onClick={() => onClick(media.index)}
				data-dot='strip-photo'
			>
				<div className={`${CLASSNAME}__thumbnail-placeholder`} />
				<div
					className={classnames({
						[`${CLASSNAME}__thumbnail`]: true,
						[`${CLASSNAME}__thumbnail--selected`]:
							selectedImageIndex === media.index && (isFullscreen ? true : media.index !== 0)
					})}
				>
					{media.type === GalleryItemTypes.VIDEO ? (
						<GalleryVideo
							key={`${media.data.url}_${index}`}
							type={GalleryBase.itemsTypes.PREVIEW}
							video={media.data}
						/>
					) : media.type === GalleryItemTypes.SPHERE_PHOTO ? (
						<CustomGallerySpherePhoto
							key={`${media.data.url}_${index}`}
							type={GalleryBase.itemsTypes.PREVIEW}
							image={media.data}
						/>
					) : (
						<GalleryPhoto
							key={`${media.data.url}_${index}`}
							type={GalleryBase.itemsTypes.PREVIEW}
							image={media.data}
						/>
					)}
				</div>
			</button>
		))
	}

	const renderThumbnailsClientSide = () => {
		return (
			<Carousel
				index={selectedThumbnailIndex}
				pause={true} // nechceme na mobilu dovolit swipovat
				size={thumbnailsCount}
				dataDot={{
					carousel: 'inpage',
					carouselItem: 'show-full-one'
				}}
			>
				{renderThumbnailsContent()}
			</Carousel>
		)
	}

	const renderThumbnailsServerSide = () => {
		return (
			<div className={`${CLASSNAME}__thumbnails-ssr-wrapper`}>
				{renderThumbnailsContent(thumbnailsCount)}
			</div>
		)
	}

	if (isEnoughThumbnails) {
		// komponenta <Carousel /> potrebuje vedet informace o velikosti okna atd.,
		// takze pri SSR ji nejde pouzit
		const isBeingRenderedOnClient = typeof document !== 'undefined'

		// tyto komponenty se zvlastne vyrenderovavaly kvuli "podminenemu" renderovani,
		// proto jsou zabaleny do divu, ktery je v DOMu vzdy.
		return (
			<>
				<div key='carousel'>{isBeingRenderedOnClient && renderThumbnailsClientSide()}</div>
				<div key='ssr'>{!isBeingRenderedOnClient && renderThumbnailsServerSide()}</div>
			</>
		)
	}

	return null
}

AdvertGalleryThumbnails.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			data: PropTypes.object.isRequired
		})
	),
	thumbnailsCount: PropTypes.number,
	selectedImageIndex: PropTypes.number,
	isFullscreen: PropTypes.bool,
	onClick: PropTypes.func
}

const CustomGallerySpherePhoto = (props) => {
	const localize = useLocalize()

	const renderStartSphereViewControls = ({ type, start } = {}) => {
		const isPreview = type === GalleryBase.itemsTypes.PREVIEW

		return (
			<div className={`${CLASSNAME}__start-sphere-view-overlay`}>
				<div
					className={classnames({
						[`${CLASSNAME}__start-sphere-view-panel`]: true,
						[`${CLASSNAME}__start-sphere-view-panel--small`]: isPreview
					})}
				>
					<img
						className={classnames({
							[`${CLASSNAME}__sphere-viewer-img`]: true,
							[`${CLASSNAME}__sphere-viewer-img--small`]: isPreview
						})}
						src={IMAGES['image__360-viewer']}
						alt=''
					/>

					{type !== GalleryBase.itemsTypes.PREVIEW && (
						<>
							<Button
								text={localize('AdvertGallery.startSphereView')}
								size='x-small'
								type='button'
								data-dot='360-photo-view-start'
								onClick={(event) => {
									event.stopPropagation()
									start && start()
								}}
							/>
							<div className={`${CLASSNAME}__sphere-viewer-usage-wrapper`}>
								<img
									className={`${CLASSNAME}__sphere-viewer-mouse`}
									src={IMAGES['image__mouse']}
									alt=''
								/>
								<span className={`${CLASSNAME}__sphere-viewer-usage-text`}>
									{localize('AdvertGallery.sphereViewUsage')}
								</span>
							</div>
						</>
					)}
				</div>
			</div>
		)
	}

	const renderStopSphereViewControls = ({ stop } = {}) => {
		return (
			<div className={`${CLASSNAME}__stop-sphere-view-overlay-top`}>
				<Button
					text={localize('AdvertGallery.stopSphereView')}
					size='x-small'
					type='button'
					icon={CLOSE_OUTLINE}
					data-dot='360-photo-view-stop'
					onClick={() => stop && stop()}
				/>
			</div>
		)
	}

	return (
		<GallerySpherePhoto
			{...props}
			renderOverlay={(stateAndActions = {}) => {
				const { isRunning } = stateAndActions

				return isRunning
					? renderStopSphereViewControls(stateAndActions)
					: renderStartSphereViewControls(stateAndActions)
			}}
		/>
	)
}

CustomGallerySpherePhoto.propTypes = {
	type: PropTypes.string,
	image: PropTypes.object.isRequired
}

class AdvertGallery extends AbstractComponent {
	static get propTypes() {
		return {
			advertEntity: PropTypes.shape(advertPropTypes),
			cachedAdvertEntity: PropTypes.shape(advertPropTypes),
			isAdvertInactive: PropTypes.bool,
			isFavoriteDisplayed: PropTypes.bool
		}
	}

	render() {
		const {
			advertEntity,
			cachedAdvertEntity,
			isAdvertInactive = false,
			isFavoriteDisplayed = true
		} = this.props
		const advertEntityToShow = advertEntity || cachedAdvertEntity

		return (
			<div className={CLASSNAME}>
				{advertEntityToShow
					? this._renderContent(advertEntityToShow, isFavoriteDisplayed)
					: this._renderPlaceholder()}
				{isAdvertInactive && (
					<div className={`${CLASSNAME}__grey-out`}>
						<div className={`${CLASSNAME}__inactive-label`}>
							{this.localize('AdvertGallery.inactiveAdvert')}
						</div>
					</div>
				)}
			</div>
		)
	}

	_renderContent(advertEntity, isFavoriteDisplayed) {
		const { premise: { homeDelivery } = {} } = advertEntity

		const videos = this._getVideos(advertEntity)
		const images = this._getImages(advertEntity)

		const videosWrapped = videos.map((video) => ({
			type: GalleryItemTypes.VIDEO,
			data: video
		}))

		const normalImagesWrapped = images
			.filter((image) => image.image_kind === 0 || typeof image.image_kind === 'undefined')
			.map((normalImage) => ({
				type: GalleryItemTypes.PHOTO,
				data: normalImage
			}))

		const sphereImagesWrapped = images
			.filter((image) => image.image_kind === 1)
			.map((sphereImage) => {
				const { url = '' } = sphereImage

				return {
					type: GalleryItemTypes.SPHERE_PHOTO,
					data: Object.assign({}, sphereImage, {
						// protokol http prohlizec zablokoval kvuli CORS, musime pouzit https
						url: url.startsWith('//') ? `https:${url}` : url
					})
				}
			})

		const [firstVideoWrapped] = videosWrapped
		const [firstSphereImageWrapped] = sphereImagesWrapped

		const addItemOnPosition = (item, position, array) =>
			[...array.slice(0, position - 1), item, ...array.slice(position - 1)].filter(
				(items) => !!items
			)

		// video bude vzdy na prvnim miste (vice videi stejne nahrat nejde)
		const videoAndNormalImagesWrapped = addItemOnPosition(
			firstVideoWrapped,
			VIDEO_POSITION,
			normalImagesWrapped
		)

		// sfericka fotka bude vzdy na tretim miste (vice sferickych fotek stejne neumoznujeme nahrat)
		const allItems = addItemOnPosition(
			firstSphereImageWrapped,
			SPHERE_PHOTO_POSITION,
			videoAndNormalImagesWrapped
		)

		return (
			<Gallery
				previewImageResponsiveSettings={AdvertImageResponsiveSettings.previewSettings}
				normalImageResponsiveSettings={AdvertImageResponsiveSettings.normalSettings}
				fullscreenImageResponsiveSettings={AdvertImageResponsiveSettings.fullscreenSettings}
				previewSphereImageResponsiveSettings={AdvertImageResponsiveSettings.previewSphereSettings}
				normalSphereImageResponsiveSettings={AdvertImageResponsiveSettings.normalSphereSettings}
				fullscreenSphereImageResponsiveSettings={
					AdvertImageResponsiveSettings.fullscreenSphereSettings
				}
				renderContent={({ renderDefaultContent } = {}) =>
					this._renderGalleryContent(advertEntity, renderDefaultContent)
				}
				renderFullscreenContent={({
					renderDefaultFullscreenContent,
					selectImage,
					state: { isListShown, selectedImageIndex } = {}
				} = {}) =>
					this._renderFullscreenGalleryContent(
						allItems,
						renderDefaultFullscreenContent,
						selectImage,
						isListShown,
						selectedImageIndex
					)
				}
				renderFooter={({ openImageInFullscreen, state: { selectedImageIndex } = {} } = {}) => {
					const thumbnailsProps = {
						items: allItems,
						selectedImageIndex,
						isFullscreen: false,
						onClick: openImageInFullscreen
					}

					return (
						<>
							{/* srdicko pro oblibene inzeraty je pozicovane absolutne, proto
							muze byt zde v paticce. V samotnem obsahu galerie (renderContent) byt
							nemuze, protoze po kliku na srdicko dochazelo i k otevreni galerie. */}
							{isFavoriteDisplayed && (
								<Favorite id={advertEntity.id} className={`${CLASSNAME}__favorite-icon`} />
							)}
							{/* stejny problem jako u oblibenych inzeratu */}
							{homeDelivery && <HomeDelivery hasTooltip={true} />}

							<div className={`${CLASSNAME}__mobile`}>
								<AdvertGalleryThumbnails
									{...thumbnailsProps}
									thumbnailsCount={THUMBNAILS_COUNT_MOBILE}
								/>
							</div>
							<div className={`${CLASSNAME}__desktop`}>
								<AdvertGalleryThumbnails
									{...thumbnailsProps}
									thumbnailsCount={THUMBNAILS_COUNT_DESKTOP}
								/>
							</div>
						</>
					)
				}}
			>
				{allItems.map(({ type, data }, index) => {
					if (type === GalleryItemTypes.VIDEO) {
						return <GalleryVideo key={`${data.url}_${index}`} video={data} />
					} else if (type === GalleryItemTypes.SPHERE_PHOTO) {
						return <CustomGallerySpherePhoto key={`${data.url}_${index}`} image={data} />
					} else {
						return <GalleryPhoto key={`${data.url}_${index}`} image={data} />
					}
				})}
			</Gallery>
		)
	}

	_renderGalleryContent(advertEntity, renderDefaultContent) {
		const { cebiaSmartCodeUrl } = advertEntity

		return (
			<>
				{renderDefaultContent && renderDefaultContent()}
				{!!cebiaSmartCodeUrl && <CebiaReport className={`${CLASSNAME}__cebia-box`} />}
			</>
		)
	}

	_renderFullscreenGalleryContent(
		allItems,
		renderDefaultFullscreenContent,
		selectImage,
		isListShown,
		selectedImageIndex
	) {
		return (
			<div className={`${CLASSNAME}__fullscreen-content-wrapper`}>
				<div className={`${CLASSNAME}__fullscreen-default-content`}>
					{renderDefaultFullscreenContent && renderDefaultFullscreenContent()}
				</div>

				{/* Nahledove fotky zobrazujeme pouze v normalnim rezimu galerie, tedy kdyz
					je v galerie zobrazena jen jedna fotka. Pokud je v galerii zobrazen
					prehled vsech fotek (isListShown je true), tak nahledove fotky nechceme
					zobrazovat. Skryti resime pres CSSka tak, aby nahledove fotky zustaly v
					DOMu. Je to nutne kvuli 360kovym fotkam. */}
				<div
					className={classnames({
						[`${CLASSNAME}__desktop`]: true,
						[`${CLASSNAME}__fullscreen-thumbnails-wrapper`]: true,
						[`${CLASSNAME}__fullscreen-thumbnails-wrapper--hidden`]: isListShown
					})}
				>
					<AdvertGalleryThumbnails
						items={allItems}
						thumbnailsCount={THUMBNAILS_COUNT_FULLSCREEN}
						selectedImageIndex={selectedImageIndex}
						isFullscreen={true}
						onClick={selectImage}
					/>
				</div>
			</div>
		)
	}

	_renderPlaceholder() {
		return <div className={`${CLASSNAME}__placeholder`}></div>
	}

	_getImages(advertEntity = {}) {
		const { images = [], imagesTotalCount, name = '' } = advertEntity
		const imagesCount = imagesTotalCount || images.length
		const newImages = [...images]

		// Pokud tato podmínka není platná, tak to znamená, že máme advertEntity z cache.
		// Ta nemusí obsahovat všechny obrázky (advertEntity.images), proto je prázdné doplníme.
		// Je to kvůli zobrazení správných čísel ve chvíli kdy advertEntity detailu ještě není načtena.
		if (imagesCount !== images.length) {
			for (let i = 0; i < imagesCount; i++) {
				if (!newImages[i]) {
					newImages.push({ id: undefined, url: '' })
				}
			}
		}

		return newImages.map((image) => Object.assign({ alt: name }, image))
	}

	_getVideos(advertEntity = {}) {
		const { images = [], videos = [] } = advertEntity

		return videos
			.filter((video) => !!video.playlist)
			.map((video) => {
				const imageUrl = images[0].url || ''

				return {
					id: video.id,
					url: imageUrl,
					content: {
						id: `gallerySeznamPlayer_${video.id}`,
						source: {
							src: video.playlist,
							type: 'application/vnd.seznam-cz.spl+json',
							options: {
								scheme: 'https',
								type: 'VOD'
							}
						},
						poster: imageUrl ? imageUrl + SDN.w800_WATERMARK : ''
					}
				}
			})
	}
}

const AdvertGallerySelector = (state) => ({
	advertEntity: state[STATE_KEYS.ADVERT_ENTITY],
	cachedAdvertEntity: state[STATE_KEYS.CACHED_ADVERT_ENTITY]
})

export default select(AdvertGallerySelector)(AdvertGallery)
