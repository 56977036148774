import React, { useState, useContext, useRef, useEffect } from 'react'
import classnames from 'classnames'
import { Input, Icon, Spinner } from '@sznds/react'
import { CHECKER_NORMAL_OUTLINE_24, MAIL_ALT1_FILLED_24 } from '@sznds/icons'
import Context from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import { CONTACTS_STATUS as EMAIL_WIDGET_STATUS } from '../contacts/shared/ContactsStatus'
import SendSMS from '../contacts/shared/sendSMS/SendSMS'
import VerifyPin from '../contacts/shared/verifyPin/VerifyPin'
import BasicWidget from '../../../../component/widgets/basic/BasicWidget'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './EmailWidget.less'
import './EmailWidgetCS.json'

export const EMAIL_WIDGET_ID = 'email_id'

const CLASSNAME = 'c-email-widget'

const EmailWidget = (props) => {
	const context = useContext(Context)
	const eventBus = context.$EventBus

	const localize = useLocalize()

	const [verifying, setIsVerifying] = useState(false)

	const [status, setStatus] = useState(
		props.formLineEntity.extra.isEmailValid
			? EMAIL_WIDGET_STATUS.VERIFIED
			: EMAIL_WIDGET_STATUS.DEFAULT
	)

	const [messageSending, setMessageSending] = useState(false)

	const lastlyValidatedEmail = useRef(undefined)

	useEffect(() => {
		const { extra: { isEmailValid } = {} } = props.formLineEntity

		if (isEmailValid) {
			setStatus(EMAIL_WIDGET_STATUS.VERIFIED)
		}
	}, [props.formLineEntity])

	const renderWidget = (
		formLineEntity,
		renderLabel,
		renderInput,
		renderMessage,
		renderErrorMessage
	) => {
		const { errorMessage } = formLineEntity

		return (
			<>
				<div className='c-basic-widget__label-box'>{renderLabel()}</div>
				<div className='c-basic-widget__content'>
					{renderInput()}
					{errorMessage && renderErrorMessage()}

					{renderVerificationMessages()}
				</div>
			</>
		)
	}

	const renderInput = (formLineEntity, props) => {
		const { disabled, errorMessage, extra = {} } = formLineEntity
		const value = formLineEntity.value || ''

		const { isLoaded = true } = extra

		const isInputDisabled = disabled || isPinSent()

		return (
			<div className={`${CLASSNAME}__input-wrap`}>
				<div className={`${CLASSNAME}__input-msg`}>
					<div className={`${CLASSNAME}__input-spinner`}>
						<Input
							{...props}
							type='email'
							value={value}
							onBlur={handleBlur}
							disabled={isInputDisabled}
							error={errorMessage}
							className={classnames({
								[props.className]: true,
								[`${CLASSNAME}__input`]: true,
								[`${CLASSNAME}__input--disabled`]: isInputDisabled
							})}
						/>
						{(verifying || !isLoaded) && <Spinner className={`${CLASSNAME}__spinner`} />}
					</div>
					{renderEmailInfo()}
				</div>
				{!disabled && renderVerifiedEmailMessage()}
			</div>
		)
	}

	const renderEmailInfo = () => {
		const { formLineEntity: { disabled } = {} } = props

		return (
			!disabled && <div className={`${CLASSNAME}__info`}>{localize('EmailWidget.emailInfo')}</div>
		)
	}

	const handleBlur = () => {
		const { formLineEntity: { value: email } = {} } = props

		if (email !== lastlyValidatedEmail.current) {
			validateEmail()
			lastlyValidatedEmail.current = email
		}
	}

	const validateEmail = () => {
		const { formLineEntity: { value = '', extra } = {} } = props

		if (value) {
			setIsVerifying(true)

			eventBus.fire(window, 'validateEmail', {
				formLineEntity: props.formLineEntity,
				callback: handleFormLineValidation
			})
		} else {
			setStatusInfo()
			change({
				extra: Object.assign({}, extra, {
					pending: false,
					isEmailValid: false,
					isLoaded: true
				})
			})
		}
	}

	const handleFormLineValidation = (isEmailValid, errorCode = '') => {
		const { value = '', extra } = props.formLineEntity

		if (isEmailValid && errorCode === '') {
			setStatusInfo(EMAIL_WIDGET_STATUS.VERIFIED)
			change({
				value,
				errorMessage: undefined,
				extra: Object.assign({}, extra, {
					pending: false,
					isEmailValid: true,
					isLoaded: true
				})
			})
		} else if ((errorCode === ERROR_CODES.EMAIL_ERROR_CODE || errorCode === '') && !isEmailValid) {
			setStatusInfo(EMAIL_WIDGET_STATUS.UNKNOWN)
			change({
				value,
				errorMessage: Boolean(errorCode) && localize('EmailWidget.wrongEmail'),
				extra: Object.assign({}, extra, {
					pending: true,
					isEmailValid: false,
					isLoaded: true
				})
			})
		} else if (errorCode === ERROR_CODES.NOT_VALID && !isEmailValid) {
			setStatusInfo()
			setIsVerifying(false)
			change({
				value,
				errorMessage: localize('EmailWidget.wrongEmail'),
				extra: Object.assign({}, extra, {
					pending: true,
					isEmailValid: false,
					isLoaded: true
				})
			})
		} else {
			setStatusInfo()
		}
	}

	const setStatusInfo = (newStatus = EMAIL_WIDGET_STATUS.DEFAULT) => {
		if (status !== newStatus && newStatus === EMAIL_WIDGET_STATUS.DEFAULT) {
			change({ errorMessage: undefined })
		}

		setStatus(newStatus)
		setIsVerifying(false)
	}

	const renderVerificationMessages = () => {
		const { UNKNOWN, PIN_SENT } = EMAIL_WIDGET_STATUS

		switch (status) {
			case UNKNOWN: {
				return (
					<SendSMS
						icon={MAIL_ALT1_FILLED_24}
						sendMessage={sendMessage}
						loading={messageSending}
						dict={{
							boldText: localize('EmailWidget.unknown'),
							buttonText: localize('EmailWidget.sendPin')
						}}
					/>
				)
			}

			case PIN_SENT: {
				return renderPinSet()
			}

			default:
				return null
		}
	}

	const renderVerifiedEmailMessage = () => {
		if (status === EMAIL_WIDGET_STATUS.VERIFIED) {
			return (
				<div className={`${CLASSNAME}__succ`}>
					<Icon symbol={CHECKER_NORMAL_OUTLINE_24} className={`${CLASSNAME}__succ-icon`} />
					<div className={`${CLASSNAME}__succ-sentence`}>{localize('EmailWidget.pinVerified')}</div>
				</div>
			)
		}
	}

	const renderPinSet = () => {
		const { formLineEntity } = props
		const { value = '' } = formLineEntity

		const dict = {
			pinMsg1: localize('EmailWidget.pinMsg1'),
			contactValue: value,
			pinMsg2: localize('EmailWidget.pinMsg2')
		}

		return (
			<VerifyPin
				icon={MAIL_ALT1_FILLED_24}
				dict={dict}
				messageLoading={messageSending}
				verifyPin={verifyPin}
				onVerifyPinSuccess={onVerifyPinSuccess}
				onVerifyPinError={onVerifyPinError}
				resendPin={sendMessage}
				cancelVerification={cancelPinVerification}
			/>
		)
	}

	const onVerifyPinSuccess = () => {
		const { formLineEntity } = props
		const { value = '', extra } = formLineEntity

		setStatusInfo(EMAIL_WIDGET_STATUS.VERIFIED)
		change({
			value,
			errorMessage: undefined,
			extra: Object.assign({}, extra, {
				pending: false,
				isEmailValid: true,
				isLoaded: true
			})
		})
	}

	const onVerifyPinError = () => {
		setStatusInfo(EMAIL_WIDGET_STATUS.PIN_SENT)
	}

	const cancelPinVerification = () => {
		setStatusInfo(EMAIL_WIDGET_STATUS.UNKNOWN)
	}

	const isPinSent = () => {
		const { PIN_SENT } = EMAIL_WIDGET_STATUS

		return status === PIN_SENT
	}

	const sendMessage = (event) => {
		event.preventDefault()
		const { formLineEntity } = props
		const { value = '' } = formLineEntity

		eventBus.fire(
			window,
			'sendEmailMessage',
			Object.assign(
				{ callback: handleSendMessage },
				{
					email: value
				}
			)
		)

		setMessageSending(true)
	}

	const handleSendMessage = (error) => {
		if (!error) {
			setStatusInfo(EMAIL_WIDGET_STATUS.PIN_SENT)
		} else {
			change({
				errorMessage: localize('EmailWidget.wrongEmail'),
				extra: Object.assign({}, props.formLineEntity.extra, {
					pending: true,
					isEmailValid: false
				})
			})
		}

		setMessageSending(false)
	}

	const verifyPin = (pin, callback) => {
		const { formLineEntity } = props
		const { value = '' } = formLineEntity

		eventBus.fire(window, 'verifyEmailPin', {
			callback,
			pin,
			email: value
		})
	}

	const change = (formLineDataToBeChanged) => {
		const { formLineEntity, onChange } = props

		onChange(
			Object.assign({}, formLineDataToBeChanged, {
				id: formLineEntity.id
			})
		)
	}

	const handleInputChange = (event, newValue) => {
		change({
			value: newValue,
			errorMessage: undefined,
			extra: Object.assign({}, props.formLineEntity.extra, {
				isEmailValid: false,
				pending: true,
				isLoaded: true
			})
		})

		setStatusInfo()
	}

	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={props.formLineEntity}
			name={EMAIL_WIDGET_ID}
			onChange={handleInputChange}
			renderWidget={renderWidget}
			renderInput={renderInput}
		/>
	)
}

EmailWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string
}

export default React.memo(EmailWidget)
