import AbstractExtension from 'ima/extension/AbstractExtension'
import { FAVORITE_CONSTANTS } from '@inzeraty/models'
import { Events as ImaLoginEvents } from '@ima/plugin-login'
import { UserService } from '@inzeraty/models'
import STATE_KEYS from 'app/component/favorite/FavoriteStateKeys'
import { HttpStatusCodes } from '@inzeraty/helpers'
import UserwebBaseController from 'app/base/UserwebBaseController'
import { ASSIGN_EMAIL_EVENTS } from '@inzeraty/components'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import 'app/base/AssignEmailReasonsCS.json'

export default class FavoriteExtension extends AbstractExtension {
	constructor(favoriteService, dispatcher, utils) {
		super()

		this._favoriteService = favoriteService
		this._dispatcher = dispatcher
		this._dictionary = utils.$Dictionary

		this._onLogout = this._onLogout.bind(this)
		this._onBadge = this._onBadge.bind(this)
		this._userSelfUpdated = this._userSelfUpdated.bind(this)
	}

	init() {
		super.init()

		// Pomocna promenna, ve ktere drzime akci,
		// kterou se snazil provest neprihlaseny uzivatel
		this._actionAfterLogin = null

		this._dispatcher.listen(ImaLoginEvents.BADGE, this._onBadge)
		this._dispatcher.listen(ImaLoginEvents.LOGOUT, this._onLogout)

		this._dispatcher.listen(UserwebBaseController.EVENT.USER_SELF_UPDATED, this._userSelfUpdated)
	}

	load() {
		return {
			[STATE_KEYS.FAVORITE_IDS]: this._getFavoriteIds()
		}
	}

	async _getFavoriteIds() {
		const { userSelf } = this.getState()
		const userEntity = await userSelf

		// Nacitame seznam IDcek jen pro prihlasene uzivatele
		if (userEntity && UserService.isUserLogged(userEntity)) {
			return this._favoriteService.getFavoritesIds()
		}

		return new Set()
	}

	async _userSelfUpdated() {
		this._favoriteService.clearCache()

		const favoriteIds = await this._favoriteService.getFavoritesIds()

		this.setState({
			[STATE_KEYS.FAVORITE_IDS]: favoriteIds
		})

		if (this._actionAfterLogin) {
			this.onFavoriteClick(this._actionAfterLogin)
			this._actionAfterLogin = null
		}
	}

	deactivate() {
		super.deactivate()

		this._dispatcher.unlisten(ImaLoginEvents.BADGE, this._onBadge)
		this._dispatcher.unlisten(ImaLoginEvents.LOGOUT, this._onLogout)

		this._dispatcher.unlisten(UserwebBaseController.EVENT.USER_SELF_UPDATED, this._userSelfUpdated)
	}

	async _onBadge(event = {}) {
		const { state = '' } = event.detail || {}

		if (state === 'login') {
			this._userSelfUpdated()
		}
	}

	async _onLogout() {
		this._favoriteService.clearCache()

		this.setState({
			[STATE_KEYS.FAVORITE_IDS]: new Set()
		})
	}

	_onCheckAssigneEmail(callback) {
		this._dispatcher.fire(ASSIGN_EMAIL_EVENTS.SHOW_DIALOG, {
			reason: this._dictionary.get('AssignEmailReasons.favorite'),
			onSuccess: callback
		})
	}

	async onFavoriteClick(action, callback = () => {}) {
		const { userSelf } = this.getState()
		const userEntity = await userSelf

		if (userEntity && UserService.isUserLogged(userEntity)) {
			this._onCheckAssigneEmail(() => {
				this._processFavoriteClick(action, callback)
			})
		} else {
			this._actionAfterLogin = action
			this._openLogin()
		}
	}

	async _processFavoriteClick(action, callback = () => {}) {
		const { id, isFavorite, onSuccess, onError } = action

		try {
			// Necekame na BE a nastavime spravny stav hned
			const { [STATE_KEYS.FAVORITE_IDS]: favoriteIds } = this.getState()
			const newStateValues = new Set(favoriteIds)

			if (isFavorite) {
				newStateValues.delete(id)
			} else {
				newStateValues.add(id)
			}

			this.setState({
				[STATE_KEYS.FAVORITE_IDS]: newStateValues
			})

			if (isFavorite) {
				await this._favoriteService.removeFromFavorites(id)
				this._favoriteService.removeFromCache(id)
			} else {
				await this._favoriteService.addToFavorites(id)
				this._favoriteService.addToCache(id)
			}

			onSuccess()
			this._onCheckAssigneEmail(callback)
		} catch (error) {
			const { status, body } = error.getParams()
			const { errors = [] } = body
			const [errorContent] = errors
			const { error_code } = errorContent || {}

			// Uzivatel neni prihlaseny
			if (status === HttpStatusCodes.UNAUTHORIZED) {
				this._actionAfterLogin = action
				this._openLogin()
			} else if (isFavorite) {
				// Inzerat uz byl odebran z oblibenych
				if (status === HttpStatusCodes.NOT_FOUND) {
					this._favoriteService.removeFromCache(id)
					this._onCheckAssigneEmail(callback)
					return onSuccess()
				} else {
					onError('removeError')
				}
			} else {
				// Inzerat uz byl pridan do oblibenych
				if (
					status === HttpStatusCodes.UNPROCESSABLE_ENTITY &&
					error_code === FAVORITE_CONSTANTS.ERROR.NOT_UNIQUE
				) {
					this._favoriteService.addToCache(id)
					this._onCheckAssigneEmail(callback)
					return onSuccess()
				} else {
					onError('addError')
				}
			}

			// Pokud dojde k chybe, vratime IDcko do puvodniho stavu
			const { [STATE_KEYS.FAVORITE_IDS]: favoriteIds } = this.getState()
			const newStateValues = new Set(favoriteIds)

			if (isFavorite) {
				newStateValues.add(id)
			} else {
				newStateValues.delete(id)
			}

			this.setState({
				[STATE_KEYS.FAVORITE_IDS]: newStateValues
			})
		}
	}

	_openLogin() {
		LoginHelper.openLogin({
			sourceComponent: LoginHelper.SOURCE_COMPONENT.FAVORITES_ADD
		})
	}

	getAllowedStateKeys() {
		return [STATE_KEYS.FAVORITE_IDS]
	}
}
