import React from 'react'

export const getSource = (imageUrl, type, extraData, sizes) => {
	return (
		<source
			srcSet={`
                ${imageUrl}${getImageSDNFilter(extraData, type, 320)} 320w,
                ${imageUrl}${getImageSDNFilter(extraData, type, 480)} 480w,
                ${imageUrl}${getImageSDNFilter(extraData, type, 600)} 600w
            `}
			sizes={sizes}
			type={type === 'webp' ? 'image/webp' : 'image/jpeg'}
		/>
	)
}

export const getImageSDNFilter = (extraData, type, width) => {
	let filter = '?'

	if (extraData && extraData.image_trim) {
		const { trimX, trimY, trimW, trimH } = extraData.image_trim || {}

		if (
			typeof trimX !== 'undefined' &&
			typeof trimY !== 'undefined' &&
			typeof trimW !== 'undefined' &&
			typeof trimH !== 'undefined'
		) {
			filter += `fl=cro,${Math.floor(trimX)},${Math.floor(trimY)},${Math.floor(trimW)},${Math.floor(
				trimH
			)}`
			filter += '|'
		}
	}
	filter += `res,${width},,1%7C${type},75`
	return filter
}
