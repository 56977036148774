import { createEntityList } from '@inzeraty/form-lines'
import { HttpErrorHelper, Format } from '@inzeraty/helpers'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { filterBy, filterByValue } from 'app/component/entitiesFilter/EntitiesFilter'
import { getYesterday } from 'app/helpers/date/dateHelpers'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { StatusMessage } from '@inzeraty/components'

export const FILTER_BY_ADVERT = 'advert_id'

const DEAL_TYPE_FORM_LINE_ENTITY_ID = InternalAdminUrlConvertor.constants.DEAL_TYPE
const GROUP_BY_FORM_LINE_ENTITY_ID = InternalAdminUrlConvertor.constants.GROUP_BY
const DATE_FROM_FORM_LINE_ENTITY_ID = 'date_from'
const DATE_TO_FORM_LINE_ENTITY_ID = 'date_to'

const GROUP_BY = {
	PREMISE: { key: 'premise_external_id', name: 'Firmy' },
	DATE: { key: 'date', name: 'Datum' },
	CATEGORY: { key: 'category_id', name: 'Kategorie' },
	MANUFACTURER: { key: 'manufacturer_id', name: 'Výrobce' },
	ADVERT: { key: 'advert_id', name: 'Inzerát' },
	REGION: { key: 'region_id', name: 'Kraj' },
	DISTRICT: { key: 'district_id', name: 'Okres' },
	SOURCE: { key: 'source_id', name: 'Zadáno' }
}

const DEAL_TYPE = {
	SALES: { key: 'sales', name: 'Prodej' },
	OPERATING_LEASE: { key: 'operating_lease', name: 'Operativní leasing' },
	MIRRORING: { key: 'mirroring', name: 'Zrcadlení' }
}

const yesterday = Format.dateEN(getYesterday(), true)

const DEFAULT_VALUES = {
	[DEAL_TYPE_FORM_LINE_ENTITY_ID]: DEAL_TYPE.SALES.key,
	[GROUP_BY_FORM_LINE_ENTITY_ID]: GROUP_BY.PREMISE.key,
	[DATE_FROM_FORM_LINE_ENTITY_ID]: yesterday,
	[DATE_TO_FORM_LINE_ENTITY_ID]: yesterday
}

export const applyDefaultSort = (params = []) =>
	params[InternalAdminUrlConvertor.constants.GROUP_BY] !== GROUP_BY.DATE.key && !params.sort

export const applySortByDate = (params = []) =>
	params[InternalAdminUrlConvertor.constants.GROUP_BY] === GROUP_BY.DATE.key && !params.sort

export default class IAStatisticsController extends AbstractInternalAdminController {
	static get SOURCE() {
		return {
			'0': 'Ručně',
			'1': 'Import'
		}
	}

	static get GROUP_BY() {
		return GROUP_BY
	}

	static get DEAL_TYPE() {
		return DEAL_TYPE
	}

	constructor(dependenciesHelper, entitiesFilterExtension, advertStatisticsService) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension

		this._advertStatisticsService = advertStatisticsService

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			isOperatingLease: this._isOperatingLease(),
			isMirroring: this._isMirroring(),
			entitiesAndPagination: this.getEntitiesAndPagination()
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isOperatingLease: this._isOperatingLease(),
			isMirroring: this._isMirroring(),
			isLoading: true
		}
	}

	_isOperatingLease() {
		const routeParams = this.getRouteParams()
		return routeParams[DEAL_TYPE_FORM_LINE_ENTITY_ID] === DEAL_TYPE.OPERATING_LEASE.key
	}

	_isMirroring() {
		const routeParams = this.getRouteParams()
		return routeParams[DEAL_TYPE_FORM_LINE_ENTITY_ID] === DEAL_TYPE.MIRRORING.key
	}

	async getEntitiesAndPagination() {
		const routeParams = this.getRouteParams()
		const params = IAStatisticsController.processRouteParamsForEntityList(routeParams)

		this._pageLoaderExtension.show()

		try {
			const {
				statisticsEntities,
				paginationEntity
			} = await this._advertStatisticsService.getStatistics(params)

			return {
				entities: statisticsEntities,
				paginationEntity,
				errorMessage: undefined
			}
		} catch (error) {
			const status = HttpErrorHelper.getHttpStatus(error)

			if (status === HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				const { errors = [] } = body

				const [errorMessage] = errors.map(({ error_code: errorCode }) => {
					if (errorCode === 'invalid_manufacturer_group_by') {
						return 'Nelze seskupovat podle výrobce bez filtru na kategorii.'
					} else if (errorCode === 'invalid_advert_group_by') {
						return 'Nelze seskupovat podle inzerátu bez filtru na klienta nebo konkrétní inzerát.'
					}
				})

				return {
					entities: [],
					paginationEntity: {},
					errorMessage
				}
			} else {
				return {
					entities: [],
					paginationEntity: {},
					errorMessage: undefined
				}
			}
		} finally {
			this._pageLoaderExtension.hide()
			this.setState({
				isLoading: false
			})
		}
	}

	static processRouteParamsForEntityList(routeParams) {
		const RENAME_FROM_TO_MAPPING = {
			manufacturer_cb: 'manufacturer_id',
			model_cb: 'model_id',
			source: 'source_id',
			region_cb: 'region_id',
			district_cb: 'district_id'
		}

		const defaultInternalAdminParams = InternalAdminUrlConvertor.processRouteParamsForEntityList(
			routeParams
		)

		const resultParams = Object.assign({}, routeParams, defaultInternalAdminParams)

		delete resultParams.locality
		delete resultParams[filterBy]
		delete resultParams[filterByValue]

		Object.entries(RENAME_FROM_TO_MAPPING).forEach(([fromName, toName]) => {
			const fromValue = resultParams[fromName]

			if (fromValue) {
				resultParams[toName] = fromValue
				delete resultParams[fromName]
			}
		})

		Object.entries(DEFAULT_VALUES).forEach(([urlParamName, defaultValue]) => {
			const currentRouteParamValue = resultParams[urlParamName]

			if (!currentRouteParamValue) {
				resultParams[urlParamName] = defaultValue
			}
		})

		// razeni
		if (applyDefaultSort(routeParams)) {
			resultParams.sort = '-active_advert'
		} else if (applySortByDate(routeParams)) {
			resultParams.sort = 'id'
		}

		delete resultParams[DEAL_TYPE_FORM_LINE_ENTITY_ID]
		const dealType = routeParams[DEAL_TYPE_FORM_LINE_ENTITY_ID]
		if (dealType === IAStatisticsController.DEAL_TYPE.OPERATING_LEASE.key) {
			resultParams[InternalAdminUrlConvertor.constants.OPERATING_LEASE] = true
		}

		return resultParams
	}

	_filterInitiezerClb(routeParams) {
		return IAStatisticsController.filterInitiezerClb(this._entitiesFilterExtension, routeParams)
	}

	onExportStatistics() {
		const routeParams = this.getRouteParams()
		const params = IAStatisticsController.processRouteParamsForEntityList(routeParams)
		const errorToast = () => {
			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				type: StatusMessage.TYPE.ERROR,
				title: 'Nepodařilo se provést export statistik.'
			})
		}
		this._advertStatisticsService
			.exportStatistics(params)
			.then((response) => {
				const { status } = response
				if (status === HTTP_STATUS_CODE.CREATED) {
					this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
						type: StatusMessage.TYPE.SUCCESS,
						title: 'Statistiky byly úspěšně exportovány, brzy se Vám objeví na emailu.'
					})
				} else {
					errorToast()
				}
			})
			.catch(() => {
				errorToast()
			})
	}

	static filterInitiezerClb(entitiesFilterExtension, routeParams) {
		return createEntityList([
			...entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'premise_name',
					name: 'Název klienta'
				},
				{
					value: 'premise_external_id',
					name: 'ID Sauta'
				},
				{
					value: FILTER_BY_ADVERT,
					name: 'ID inzerátu'
				}
			]),
			...entitiesFilterExtension.getCategoryManufacturerModelSelects(),
			entitiesFilterExtension.getSelect(
				'source',
				'Zadáno',
				'Vyberte',
				Object.keys(IAStatisticsController.SOURCE).map((sourceKey) => {
					return {
						value: sourceKey,
						name: IAStatisticsController.SOURCE[sourceKey]
					}
				})
			),
			{
				widget: IDS.DATE,
				id: DATE_FROM_FORM_LINE_ENTITY_ID,
				label: 'Datum vložení od',
				value:
					routeParams[DATE_FROM_FORM_LINE_ENTITY_ID] ||
					DEFAULT_VALUES[DATE_FROM_FORM_LINE_ENTITY_ID]
			},
			{
				widget: IDS.DATE,
				id: DATE_TO_FORM_LINE_ENTITY_ID,
				label: 'Datum vložení do',
				value:
					routeParams[DATE_TO_FORM_LINE_ENTITY_ID] || DEFAULT_VALUES[DATE_TO_FORM_LINE_ENTITY_ID]
			},
			...entitiesFilterExtension.getRegionDistrictSelects(),
			{
				widget: IDS.SELECT,
				id: DEAL_TYPE_FORM_LINE_ENTITY_ID,
				label: 'Typ prodeje',
				options: Object.values(IAStatisticsController.DEAL_TYPE).map(
					({ key: dealTypeKey, name }) => {
						return {
							value: dealTypeKey,
							name
						}
					}
				),
				value:
					routeParams[DEAL_TYPE_FORM_LINE_ENTITY_ID] ||
					DEFAULT_VALUES[DEAL_TYPE_FORM_LINE_ENTITY_ID]
			},
			{
				widget: IDS.SELECT,
				id: GROUP_BY_FORM_LINE_ENTITY_ID,
				label: 'Seskupit podle',
				options: Object.values(IAStatisticsController.GROUP_BY).map(({ key: groupByKey, name }) => {
					return {
						value: groupByKey,
						name
					}
				}),
				value:
					routeParams[GROUP_BY_FORM_LINE_ENTITY_ID] || DEFAULT_VALUES[GROUP_BY_FORM_LINE_ENTITY_ID]
			}
		])
	}
}
