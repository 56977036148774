import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { uuid } from 'uuidv4'
import { Icon, InputSurface } from '@sznds/react'
import { TRASH_FILLED_24 } from '@sznds/icons'
import * as FormLines from '@inzeraty/form-lines'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import SimpleTooltip from 'app/component/simpleTooltip/SimpleTooltipView'

import './LoginEmailWidget.less'
import './LoginEmailWidgetCS.json'

export const LOGIN_EMAIL_WIDGET_ID = 'login_email_widget'

const CLASSNAME = 'c-ia-login-email-widget'

function LoginEmailWidget({ formLineEntity, onChange, section }) {
	const {
		label,
		id,
		disabled = false,
		value: loginEmails,
		extra,
		extra: { errors = {} } = {}
	} = formLineEntity

	const localize = useLocalize()
	const fire = useFire()

	const handleOnInputBlur = (itemId, newInputValue) => {
		const currentItem = findEmailLoginByItemId(itemId)
		const oldInputValue = currentItem ? currentItem.login : undefined
		const currentError = errors[itemId]

		if (oldInputValue !== newInputValue || !!currentError) {
			fire('updateUserOnPremise', {
				formLineEntity,
				itemId,
				section,
				oldUserLogin: oldInputValue,
				newUserLogin: newInputValue,
				onSuccessCallback: () => {
					const index = findEmailLoginIndexByItemId(itemId)

					if (index !== -1) {
						const newLoginEmails = [...loginEmails]

						newLoginEmails[index] = Object.assign({}, currentItem, {
							login: newInputValue
						})

						onChange({
							id,
							value: newLoginEmails,
							section
						})
					}
				}
			})
		}
	}

	const addInputRow = () => {
		onChange({
			id,
			value: [...loginEmails, { itemId: uuid(), login: undefined }],
			section
		})
	}

	const removeInputRow = (itemId) => {
		const removeItem = (itemId) => {
			const newLoginEmails = [...loginEmails]

			const index = findEmailLoginIndexByItemId(itemId)

			if (index !== -1) {
				newLoginEmails.splice(index, 1)
			}

			// odmazeme chybu pro tento zaznam
			const newErrors = Object.assign({}, errors)
			delete newErrors[itemId]

			onChange({
				id,
				value: newLoginEmails,
				section,
				extra: Object.assign({}, extra, {
					errors: newErrors
				})
			})
		}

		const currentItem = findEmailLoginByItemId(itemId)
		const userLoginToRemove = currentItem ? currentItem.login : undefined
		const error = errors[itemId]

		if (error) {
			// pokud zaznam s emailem obsahuje chybu, tak nebudeme volat API.
			// Jedna se o nevalidni zaznam, ktery stejne neni v databazi.
			removeItem(itemId)
		} else {
			fire('removeUserFromPremise', {
				userLoginToRemove,
				onSuccessCallback: () => removeItem(itemId)
			})
		}
	}

	const findEmailLoginByItemId = (itemId) => {
		const index = findEmailLoginIndexByItemId(itemId)

		if (index === -1) {
			return undefined
		} else {
			return loginEmails[index]
		}
	}

	const findEmailLoginIndexByItemId = (itemId) => {
		return loginEmails.findIndex((item) => item.itemId === itemId)
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__label`}>{label}</div>
			<div className={`${CLASSNAME}__content`}>
				{loginEmails.map(({ itemId, login }) => (
					<InputRow
						key={itemId}
						initialInputValue={login}
						disabled={disabled}
						error={!!errors[itemId]}
						onInputBlur={(newValue) => handleOnInputBlur(itemId, newValue)}
						onRemove={() => removeInputRow(itemId)}
					/>
				))}
				<button onClick={addInputRow} type='button' className={`${CLASSNAME}__add-button`}>
					{localize('LoginEmailWidget.add')}
				</button>
			</div>
		</div>
	)
}

LoginEmailWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	section: PropTypes.string
}

export default LoginEmailWidget

const InputRow = (props) => {
	const { initialInputValue, disabled, error, onInputBlur, onRemove } = props

	const localize = useLocalize()

	const [inputValue, setInputValue] = useState(initialInputValue || '')

	return (
		<div className={`${CLASSNAME}__input-row`}>
			<div className={`${CLASSNAME}__input`}>
				{/* 
				Google prováděl autocomplete nad všemi inputy, které jsme pomocí tlačítka přidat další email přidali.
				Z toho důvodu se zde vložil tento hidden input, který tomuto globálnímu autocomplete zabrání.
				*/}
				<input
					className={`${CLASSNAME}__hidden`}
					type='text'
					name='hiddenInput'
					placeholder='hiddenInput'
					value=''
					tabIndex='-1'
				/>

				<InputSurface
					tagName='input'
					size='small'
					placeholder={localize('LoginEmailWidget.placeholder')}
					value={inputValue}
					disabled={disabled}
					error={error}
					onChange={(event) => setInputValue(event.target.value)}
					onBlur={() => onInputBlur(inputValue)}
				/>
			</div>

			{!disabled && (
				<SimpleTooltip
					description={localize('LoginEmailWidget.remove')}
					align={SimpleTooltip.ALIGN.CENTER}
					renderContent={(className) => (
						<button
							onClick={onRemove}
							type='button'
							className={`${className} ${CLASSNAME}__remove-button`}
						>
							<Icon symbol={TRASH_FILLED_24} className={`${CLASSNAME}__remove-icon`} />
						</button>
					)}
				/>
			)}
		</div>
	)
}

InputRow.propTypes = {
	initialInputValue: PropTypes.string,
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	onInputBlur: PropTypes.func,
	onRemove: PropTypes.func
}
