import { AbstractFactory } from '@inzeraty/models'
import RegistrationEntity from './RegistrationEntity'

export default class RegistrationFactory extends AbstractFactory {
	constructor() {
		super(RegistrationEntity)
	}

	transformData(data = {}) {
		return {
			id: data.id,
			premiseId: data.premise.id,
			premiseName: data.premise.name,
			status: data.status,
			dateFrom: data.date_from,
			dateTo: data.date_to,
			registrationId: data.registration_id,
			registrationModule: data.registration_module,
			premiseExternalId: data.premise.external_id
		}
	}
}
