import React from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import FilterConstants from 'app/model/filter/FilterConstants'

export default class InlineFormAdvertsCountLoader extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity),
			formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
			isVisible: PropTypes.bool,
			loadFilteredAdvertsCount: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			formLineEntities: DEFAULT_PROPS.ARRAY,
			isVisible: false,
			loadFilteredAdvertsCount: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._inView = false

		this._onElementInViewportChange = this._onElementInViewportChange.bind(this)
	}

	componentDidUpdate() {
		if (this._inView) {
			this._loadAdvertsCountIfNecessary()
		}
	}

	_filtersChangedSinceLastTime(previousFormLineEntities = [], currentFormLineEntities = []) {
		if (previousFormLineEntities === currentFormLineEntities) {
			return []
		} else {
			return (
				previousFormLineEntities
					.filter((previousFormLineEntity, index) => {
						const { value: prevValue } = previousFormLineEntity
						const { value: currentValue } = currentFormLineEntities[index] || {}

						return prevValue !== currentValue
					})
					.map((previousFormLineEntity) => previousFormLineEntity.id) || []
			)
		}
	}

	_loadAdvertsCountIfNecessary() {
		const {
			formLineEntity: { id },
			isVisible,
			formLineEntities,
			loadFilteredAdvertsCount
		} = this.props

		const filtersChangedSinceLastTime = this._filtersChangedSinceLastTime(
			this._previousFormLineEntities,
			formLineEntities
		)
		const otherFiltersChangedSinceLastTime = filtersChangedSinceLastTime.filter(
			(formLineEntityId) => formLineEntityId !== id
		)

		// nacitame pocty inzeraty pouze v pripade, ze doslo ke zmene v nastaveni ostatnich
		// filtru a zaroven tento filtr je viditelny pro uzivatele, neni prekryt napr. jinym
		// popupem.
		const shouldLoadAdvertsCount = isVisible
			? !this._previousFormLineEntities || otherFiltersChangedSinceLastTime.length > 0
			: false

		// pro specialni filtry nacitame pocty inzeraru i v pripade, ze doslo ke zmene
		// v nastaveni samotneho filtru (nejen u ostatnich)
		const shouldLoadAdvertsCountForSpecialFilters = isVisible
			? !this._previousFormLineEntities ||
			  (FilterConstants.specialFilters.includes(id) && filtersChangedSinceLastTime.includes(id))
			: false

		if (shouldLoadAdvertsCount || shouldLoadAdvertsCountForSpecialFilters) {
			loadFilteredAdvertsCount()

			this._previousFormLineEntities = formLineEntities
		}
	}

	_onElementInViewportChange(inView) {
		this._inView = inView

		if (inView) {
			this._loadAdvertsCountIfNecessary()
		}
	}

	render() {
		const { children } = this.props

		return <InView onChange={this._onElementInViewportChange}>{children}</InView>
	}
}
