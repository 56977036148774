import React, { useContext, useEffect, useState } from 'react'
import ImaContext from 'ima/page/context'
import SspContext from '../SspContext'
import { RENDER_BRANDING_EVENT } from 'app/component/layout/LayoutView'
import SkyscraperComponent from '../skyscraperComponent/SkyscraperComponent'

const ID = 'skyscraperList'
const ZONE_ID = 51839

const SKYSCRAPER_LIST_BREAKPOINT = 1760

const SkyscraperList = () => {
	const imaContext = useContext(ImaContext)
	const sspContextData = useContext(SspContext)
	const dispatcher = imaContext.$Dispatcher

	const [renderSky, setRenderSky] = useState(false)

	const handleSkyRender = (data) => {
		const { isWide } = data

		// Reklamu sky volame len v pripade ze na brandingu pride rectangle - obdlznik so sirkou 970px
		if (isWide) {
			setRenderSky(true)
		}
	}

	useEffect(() => {
		dispatcher.listen(RENDER_BRANDING_EVENT, handleSkyRender)

		return () => {
			setRenderSky(false)
			dispatcher.unlisten(RENDER_BRANDING_EVENT, handleSkyRender)
		}
	}, [sspContextData])

	return (
		<SkyscraperComponent
			zoneId={ZONE_ID}
			id={ID}
			callAdd={renderSky}
			dependencyArrayItem={renderSky}
			minWidthBreakpoint={SKYSCRAPER_LIST_BREAKPOINT}
			callbackFunc={() => {
				dispatcher.fire(RENDER_BRANDING_EVENT, {
					isWide: true,
					isSkyOnList: true
				})
			}}
		/>
	)
}

export default SkyscraperList
