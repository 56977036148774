import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import operatingLeasePropTypes, {
	intendedForPropTypes
} from 'app/model/operatingLease/OperatingLeasePropTypes'
import OperatingLeaseDetailPrice from './price/OperatingLeaseDetailPrice'
import OperatingLeaseDetailServices from './services/OperatingLeaseDetailServices'
import OperatingLeaseDetailMainChoice from './mainChoice/OperatingLeaseDetailMainChoice'

const OperatingLeaseDetail = ({
	isLoading,
	intendedFor,
	variants = DEFAULT_PROPS.ARRAY,
	selectedVariant = DEFAULT_PROPS.OBJECT,
	setSelectedVariant = DEFAULT_PROPS.FUNCTION
}) => {
	const sortedVariantsByPrice = useMemo(
		() => [...variants].sort((a, b) => a.priceWithVat - b.priceWithVat),
		[variants]
	)

	const selectedAnnualDistance = selectedVariant.annualDistance
	const selectedPeriod = selectedVariant.period

	const handleDistancePeriodChange = (newAnnualDistance, newPeriod) => {
		setSelectedVariant(
			sortedVariantsByPrice.find(
				(variant) => variant.annualDistance === newAnnualDistance && variant.period === newPeriod
			)
		)
	}

	return (
		<div>
			<OperatingLeaseDetailMainChoice
				isLoading={isLoading}
				variants={variants}
				selectedAnnualDistance={selectedAnnualDistance}
				selectedPeriod={selectedPeriod}
				onChange={handleDistancePeriodChange}
			/>
			<OperatingLeaseDetailServices
				isLoading={isLoading}
				variants={sortedVariantsByPrice.filter(
					(variant) =>
						variant.annualDistance === selectedAnnualDistance && variant.period === selectedPeriod
				)}
				selectedVariant={selectedVariant}
				onSelect={setSelectedVariant}
			/>
			<OperatingLeaseDetailPrice
				isLoading={isLoading}
				intendedFor={intendedFor}
				priceWithVat={selectedVariant.priceWithVat}
				priceWithoutVat={selectedVariant.priceWithoutVat}
			/>
		</div>
	)
}

OperatingLeaseDetail.propTypes = {
	isLoading: PropTypes.bool,
	intendedFor: PropTypes.arrayOf(PropTypes.shape(intendedForPropTypes)),
	variants: PropTypes.arrayOf(PropTypes.shape(operatingLeasePropTypes)),
	selectedVariant: PropTypes.shape(operatingLeasePropTypes),
	setSelectedVariant: PropTypes.func
}

export default OperatingLeaseDetail
