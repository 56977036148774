import * as FormLines from '@inzeraty/form-lines'
import FilterConstants from 'app/model/filter/FilterConstants'
import FilterToUrlAppConvertor from 'app/model/filter/filterConvertor/FilterToUrlAppConvertor'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import radioFilters from 'app/model/mySavedSearch/convertors/radioFiltersList'

export default class RibbonSearchToUrlAppParamsConvertor {
	static convert(detectedFilters = [], category) {
		return Object.assign(
			{},
			{
				[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: category
			},
			RibbonSearchToUrlAppParamsConvertor.convertMultiOptionFilterParams(detectedFilters),
			RibbonSearchToUrlAppParamsConvertor.convertRadioFilterParams(detectedFilters),
			RibbonSearchToUrlAppParamsConvertor.convertBrandModelsFilterParams(detectedFilters),
			RibbonSearchToUrlAppParamsConvertor.convertPhraseFilterParam(detectedFilters),
			RibbonSearchToUrlAppParamsConvertor.convertRegionDistrictsFilterParams(detectedFilters)
		)
	}

	static convertMultiOptionFilterParams(detectedFilters = []) {
		const fakeFormLines = detectedFilters
			.filter((item) => FilterConstants.multiOptionFilters.includes(item.filterName))
			.map((item) => {
				const apiValue = item.options || []

				return {
					id: item.filterName,
					value: apiValue.reduce((result, { value }) => {
						result.add(value)
						return result
					}, new Set()),
					options: apiValue.map(({ value, seo_name: seoName, name }) => ({
						value,
						seoName,
						name
					}))
				}
			})

		const isConditionInserted = fakeFormLines.find(
			(formLine) => formLine.id === FilterConstants.formLineIds.CONDITION
		)

		const multiChoiceUrlParams = FilterToUrlAppConvertor.getMultiChoiceUrlParams(
			FormLines.getFormData(fakeFormLines),
			fakeFormLines
		)

		//Pokud jsme nemeli zadny stav zadany, tak se do url dalo stav=vse, to se Jardovi z hlediska SEO nelibilo,
		//tudiz to tady odebereme a stav nechame, pouze pokud je nejaky zahledany
		if (!isConditionInserted) {
			delete multiChoiceUrlParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CONDITION_NAME]
		}

		return multiChoiceUrlParams
	}

	static convertRadioFilterParams(detectedFilters = []) {
		const fakeFormData = radioFilters.reduce((formData, { formLineId }) => {
			const filter = detectedFilters.find((item) => item.filterName === formLineId)
			const valueFrom = filter?.valueFrom
			const valueTo = filter?.valueTo

			if (!isNullOrUndefined(valueFrom) || !isNullOrUndefined(valueTo)) {
				return Object.assign({}, formData, {
					[formLineId]: Object.assign(
						{},
						!isNullOrUndefined(valueFrom)
							? {
									valueFrom
							  }
							: undefined,
						!isNullOrUndefined(valueTo)
							? {
									valueTo
							  }
							: undefined
					)
				})
			} else {
				return formData
			}
		}, {})

		return Object.assign(
			{},
			FilterToUrlAppConvertor.getFromToUrlParams(fakeFormData),
			FilterToUrlAppConvertor.getPerformanceParams(fakeFormData)
		)
	}

	static convertRegionDistrictsFilterParams(detectedFilters = []) {
		const { URL_API_PARAMS } = AdvertListUrlConvertor.constants
		const regionDistricts = detectedFilters.find(
			(item) => item.filterName === URL_API_PARAMS.REGIONS_DISTRICTS_VALUES
		)

		const { options = [] } = regionDistricts || {}

		const fakeFormLines = [
			{
				id: FilterConstants.formLineIds.REGION_DISTRICT,
				value: options.reduce((result, { value: regionValue, districts = [] }) => {
					result.set(regionValue, new Set(districts.map(({ value }) => value)))
					return result
				}, new Map()),
				options: options.map((region) => {
					const {
						value: regionValue,
						seo_name: regionSeoName,
						name: regionName,
						districts = []
					} = region

					return {
						value: regionValue,
						seoName: regionSeoName,
						name: regionName,
						children: districts.map(
							({ value: districtValue, seo_name: districtSeoName, name: districtName }) => ({
								value: districtValue,
								seoName: districtSeoName,
								name: districtName
							})
						)
					}
				})
			}
		]

		return FilterToUrlAppConvertor.getRegionDistrictUrlParams(
			FormLines.getFormData(fakeFormLines),
			fakeFormLines
		)
	}

	static convertBrandModelsFilterParams(detectedFilters = []) {
		const { URL_API_PARAMS } = AdvertListUrlConvertor.constants
		const apiValue =
			detectedFilters.filter((item) => item.filterName === URL_API_PARAMS.BRANDS_MODELS_VALUES) ||
			[]

		const fakeFormLines = [
			{
				id: FilterConstants.formLineIds.BRAND_MODEL,
				value:
					apiValue.length > 0
						? apiValue[0].options.reduce((result, { value: brandValue, models = [] }) => {
								result.set(brandValue, new Set(models.map(({ value }) => value)))
								return result
						  }, new Map())
						: undefined,
				options:
					apiValue.length > 0
						? apiValue[0].options.map((brand) => {
								const {
									value: brandValue,
									seo_name: brandSeoName,
									name: brandName,
									models = []
								} = brand

								return {
									value: brandValue,
									seoName: brandSeoName,
									name: brandName,
									children: models.map(
										({ value: modelValue, seo_name: modelSeoName, name: modelName }) => ({
											value: modelValue,
											seoName: modelSeoName,
											name: modelName
										})
									)
								}
						  })
						: undefined
			}
		]

		return FilterToUrlAppConvertor.getBrandModelUrlParams(
			FormLines.getFormData(fakeFormLines),
			fakeFormLines
		)
	}

	static convertPhraseFilterParam(detectedFilters = []) {
		const { URL_API_PARAMS } = AdvertListUrlConvertor.constants
		const phrase = detectedFilters.find((item) => item.filterName === URL_API_PARAMS.PHRASE)

		const fakeFormLines = [
			{
				id: FilterConstants.formLineIds.PHRASE,
				value: phrase?.value
			}
		]

		return FilterToUrlAppConvertor.getPhraseUrlParams(FormLines.getFormData(fakeFormLines))
	}
}
