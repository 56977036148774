export default Object.freeze({
	ADVERT_LIST: 'advertList',
	IS_OPERATING_LEASE: 'isOperatingLease',
	IS_LOADING_STATISTICS: 'isLoadingStatistics',
	STATISTICS: 'statistics',
	IS_LOADING: 'isLoading',
	FILTER_STATUS: 'filterStatus',
	SELECTED_ADVERTS: 'selectedAdverts',
	PREMISE_FILTERS_FORM_LINES: 'premiseFiltersFormLines',
	CHANGE_PREMISE_FILTERS_FORM_LINES: 'changePremiseFiltersFormLines',
	LOAD_PREMISE_ADVERTS_TOTAL: 'loadPremiseAdvertsTotal',
	LOAD_ALL_BRANDS_FOR_CATEGORY: 'loadAllBrandsForCategory',
	LOAD_ALL_MODELS_FOR_BRAND: 'loadAllModelsForBrand',
	BANNED_ADVERTS: 'bannedAdverts'
})
