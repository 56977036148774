import React, { useContext } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import Layout from 'app/component/layout/LayoutView'
import Paging from 'app/component/paging/PagingView'
import ImaContext from 'ima/page/context'
import FavoriteListUrlConvertor from 'app/page/userweb/favoriteList/FavoriteListUrlConvertor'
import UserwebAdvertView from 'app/component/adverts/UserwebAdvertView'
import classnames from 'classnames'
import { Button } from '@sznds/react'
import PageTitle from 'app/component/pageTitle/PageTitle'
import FavoriteListController from './FavoriteListController'
import { Format } from '@inzeraty/helpers'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'
import UserwebBaseController from 'app/base/UserwebBaseController'

import './FavoriteListView.less'
import './FavoriteListCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'p-uw-client-favorite'
export const CONTENT_CLASSNAME = `${CLASSNAME}__content`

const FavoriteList = ({
	[FavoriteListController.STATE_KEYS.FAVORITE_LIST]: favoriteList,
	[FavoriteListController.STATE_KEYS.PLACEHOLDERS]: placeholders,
	[UserwebBaseController.STATE_KEYS.HTTP_STATUS]: httpStatus
}) => {
	const localize = useLocalize()
	const context = useContext(ImaContext)
	const router = context.$Router

	const { advertList, pagination = {} } = favoriteList || placeholders

	const { total } = pagination
	const isPlaceholder = typeof total === 'undefined' || !favoriteList
	const hasFavorites = total > 0

	const renderCount = () => {
		if (hasFavorites || isPlaceholder) {
			return (
				<div
					className={classnames({
						[`${CLASSNAME}__count`]: true,
						[`${CLASSNAME}__count--plh`]: isPlaceholder
					})}
				>
					{!isPlaceholder &&
						localize('FavoriteList.count', {
							COUNT: Format.number(total) || 0
						})}
				</div>
			)
		}
	}

	const renderHeader = () => {
		return (
			<PageTitle className={`${CLASSNAME}__header`}>{localize('FavoriteList.header')}</PageTitle>
		)
	}

	const renderAdvertList = () => {
		return (
			<ul className={`${CLASSNAME}__list`}>
				{advertList.map((advert, index) => (
					<UserwebAdvertView
						className={`${CLASSNAME}__item`}
						advertEntity={advert}
						isOperatingLease={advert.dealType === DEAL_TYPE.OPERATING_LEASE}
						index={index}
						key={advert.id}
						isHybrid={true}
					/>
				))}
			</ul>
		)
	}

	const renderPagination = () => {
		return (
			<Paging
				paginationEntity={pagination}
				urlPageCreator={(page) => {
					return FavoriteListUrlConvertor.createPaginationUrl(page, router)
				}}
			/>
		)
	}

	const renderList = () => {
		if (hasFavorites) {
			return (
				<>
					{renderAdvertList()}
					{renderPagination()}
				</>
			)
		} else {
			return (
				<div className={`${CLASSNAME}__empty`}>
					<span className={`${CLASSNAME}__empty-header`}>
						{localize('FavoriteList.emptyHeader')}
					</span>
					<span className={`${CLASSNAME}__empty-text`}>{localize('FavoriteList.emptyText')}</span>
					<Button
						className={`${CLASSNAME}__empty-button`}
						primary={true}
						href='/'
						text={localize('Base.goBackHome')}
					/>
				</div>
			)
		}
	}

	return (
		<Layout className={CLASSNAME} httpStatus={httpStatus}>
			<div className={CONTENT_CLASSNAME}>
				{hasFavorites && <BackToHpButton />}
				{(isPlaceholder || hasFavorites) && renderHeader()}
				{renderCount()}
				{renderList()}
			</div>
		</Layout>
	)
}

export default FavoriteList
