import { AbstractFactory } from '@inzeraty/models'
import PremiseEntityImportLog from './PremiseEntityImportLog'

export default class PremiseFactoryImportLog extends AbstractFactory {
	constructor() {
		super(PremiseEntityImportLog)
	}

	transformData(data = {}) {
		const { premise = {}, counts = {} } = data
		const { all = 0, successful = 0 } = counts

		return {
			counts: {
				all,
				successful,
				errorsCount: all - successful,
				addEditCarAll: counts.add_edit_car_all || 0,
				addEditCarSuccessful: counts.add_edit_car_successful || 0,
				statusCodes: counts.status_codes || {}
			},
			premise: premise
				? {
						id: premise.id,
						name: premise.name
				  }
				: undefined,
			sessionId: data.session_id,
			softwareKey: data.software_key,
			timestamp: data.timestamp
		}
	}
}
