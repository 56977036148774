import * as FormLines from '@inzeraty/form-lines'
import { EQUIPMENT_CB } from './EquipmentConstants'
import { EQUIPMENT_WIDGET_ID } from './components/widgets/equipment/EquipmentWidget'

export const createEquipmentFormLineEntity = (advertEntity = {}, equipmentOptions = []) => {
	const { equipmentCb = [] } = advertEntity

	return FormLines.createEntity({
		widget: EQUIPMENT_WIDGET_ID,
		id: EQUIPMENT_CB,
		value: equipmentCb.map(({ value }) => value),
		options: equipmentOptions.map(({ category, name, value }) => ({
			category,
			name,
			value
		})),
		extra: {
			getFormData: (value) => ({
				equipment_cb: value
			})
		}
	})
}
