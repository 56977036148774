export default class PremiseEntityHistory {
	/**
	 * @constructor
	 * @param {Object} data
	 * @param {Number} data.id
	 * @param {String} data.ipAddress
	 * @param {String} data.changedBy     			Autor zmeny.
	 * @param {Object} data.changes      			Emaily.
	 * @param {Object} data.changes.$category
	 * @param {String} data.changes.$category.item
	 * @param {String} data.changes.$category.new
	 * @param {String} data.changes.$category.old
	 * @param {String} data.serviceName				Sluzba
	 * @param {Date}   data.time
	 */
	constructor(data) {
		this.id = data.id
		this.ipAddress = data.ipAddress
		this.changedBy = data.changedBy
		this.changes = data.changes
		this.serviceName = data.serviceName
		this.time = data.time
	}
}
