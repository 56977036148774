
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var number = function (value, name, offset) {
  if (!offset) return value;
  if (isNaN(value)) throw new Error("Can't apply offset:" + offset + ' to argument `' + name + '` with non-numerical value ' + JSON.stringify(value) + '.');
  return value - offset;
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['BasicInfo'] = {
  manufacturerLabel: function(d) { return "Výrobce"; },
  manufacturerPlaceholder: function(d) { return "Vyberte značku"; },
  modelLabel: function(d) { return "Model"; },
  modelPlaceholder: function(d) { return "Vyberte model"; },
  additionalModelName: function(d) { return "Titulek inzerátu"; },
  additionalModelPlaceholder: function(d) { return "Např. 1.9 TDI, servisováno"; },
  manufacturingDateLabel: function(d) { return "Rok výroby"; },
  vehicleBodyLabel: function(d) { return "Typ karosérie"; },
  vehicleBodyPlaceholder: function(d) { return "Vyberte typ karosérie"; },
  doorsAndCapacityLabel: function(d) { return "Počet dveří a míst"; },
  capacityLabel: function(d) { return "Počet míst"; },
  capacityPlaceholder: function(d) { return "Vyberte počet míst"; },
  capacityOption: function(d) { return plural(d.COUNT, 0, cs, { "0": "Žádné místo", one: number(d.COUNT, "COUNT") + " místo", few: number(d.COUNT, "COUNT") + " místa", other: number(d.COUNT, "COUNT") + " míst" }); },
  doorsAndSeatsLabel: function(d) { return "Počet dveří a sedadel"; },
  doorsOption: function(d) { return plural(d.COUNT, 0, cs, { "0": "Bez dveří", one: number(d.COUNT, "COUNT") + " dveře", few: number(d.COUNT, "COUNT") + " dveře", other: number(d.COUNT, "COUNT") + " dveří" }); },
  colorAndToneLabel: function(d) { return "Barva a odstín"; },
  colorTypeLabel: function(d) { return "Úprava barvy"; },
  colorTypePlaceholder: function(d) { return "Vyberte úpravu barvy"; },
  airconditionLabel: function(d) { return "Klimatizace"; },
  airconditionPlaceholder: function(d) { return "Vyberte typ klimatizace"; },
  airbagsLabel: function(d) { return "Počet airbagů"; },
  airbagsPlaceholder: function(d) { return "Vyberte počet airbagů"; },
  airbagsOption: function(d) { return plural(d.COUNT, 0, cs, { "0": "Bez airbagu", one: number(d.COUNT, "COUNT") + " airbag", few: number(d.COUNT, "COUNT") + " airbagy", other: number(d.COUNT, "COUNT") + " airbagů" }); },
  weightLabel: function(d) { return "Hmotnost"; },
  weightPlaceholder: function(d) { return "Zadejte hmotnost vozu"; },
  loadCapacityLabel: function(d) { return "Nosnost"; },
  loadCapacityPlaceholder: function(d) { return "Zadejte nosnost vozu"; },
  bedsLabel: function(d) { return "Počet lůžek"; },
  bedsPlaceholder: function(d) { return "Vyberte počet lůžek"; },
  bedsOption: function(d) { return plural(d.COUNT, 0, cs, { "0": "Bez lůžka", one: number(d.COUNT, "COUNT") + " lůžko", few: number(d.COUNT, "COUNT") + " lůžka", other: number(d.COUNT, "COUNT") + " lůžek" }); },
  engineVolumeLabel: function(d) { return "Objem motoru"; },
  engineVolumePlaceholder: function(d) { return "Zadejte objem"; },
  batteryCapacityLabel: function(d) { return "Kapacita akumulátoru"; },
  batteryCapacityPlaceholder: function(d) { return "Zadejte kapacitu"; },
  vehicleRangeLabel: function(d) { return "Dojezd"; },
  vehicleRangePlaceholder: function(d) { return "Zadejte dojezd"; },
  enginePowerLabel: function(d) { return "Výkon"; },
  enginePowerPlaceholder: function(d) { return "Zadejte výkon"; },
  fuelLabel: function(d) { return "Palivo"; },
  fuelPlaceholder: function(d) { return "Vyberte palivo"; },
  fuelConsumptionLabel: function(d) { return "Průměrná spotřeba"; },
  fuelConsumptionPlaceholder: function(d) { return "Zadejte spotřebu"; },
  gearboxLabel: function(d) { return "Typ převodovky"; },
  gearboxPlaceholder: function(d) { return "Vyberte typ převodovky"; },
  gearboxLevelsLabel: function(d) { return "Počet rychlostí"; },
  gearboxLevelsPlaceholder: function(d) { return "Vyberte počet rychlostí"; },
  driveLabel: function(d) { return "Pohon"; },
  drivePlaceholder: function(d) { return "Vyberte pohon"; },
  tachometerLabel: function(d) { return "Nájezd"; },
  tachometerPlaceholder: function(d) { return "Opište stav tachometru"; },
  availabilityLabel: function(d) { return "Termín dodání"; },
  availabilityPlaceholder: function(d) { return "Vyberte termín dodání"; },
  deliveryDateLabel: function(d) { return "Datum termínu dodání"; },
  machineHoursLabel: function(d) { return "Motohodiny"; },
  machineHoursPlaceholder: function(d) { return "Počet motohodin"; },
  inOperationDateLabel: function(d) { return "V provozu od"; },
  stkDateLabel: function(d) { return "Platnost STK"; },
  countryOfOriginLabel: function(d) { return "Země původu"; },
  countryOfOriginPlaceholder: function(d) { return "Vyberte zemi původu"; },
  descriptionLabel: function(d) { return "Poznámka o stavu vozidla"; },
  descriptionPlaceholder: function(d) { return "Vaše poznámka"; },
  windshieldNoteLabel: function(d) { return "Poznámka k ceduli za okno"; },
  windshieldNotePlaceholder: function(d) { return "Vaše poznámka k ceduli za okno"; },
  priceLabel: function(d) { return "Cena/odstupné"; },
  pricePlaceholder: function(d) { return "Jaká je vaše cenová představa"; },
  priceNoteLabel: function(d) { return "Poznámka k ceně"; },
  localityLabel: function(d) { return "Adresa"; },
  localityPlaceholder: function(d) { return "Zadejte lokaci vozidla"; },
  phoneLabel: function(d) { return "Váš telefon"; },
  phonePlaceholder: function(d) { return "Zadejte váš telefon"; },
  emailLabel: function(d) { return "E-mail"; },
  emailPlaceholder: function(d) { return "Zadejte váš e-mail"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	