export const FORM_LINES_IDS = {
	BASIC_INFORMATION_SOURCE: 'basic_information_source',
	LOGO: 'logo',
	NAME: 'name',
	ICO: 'ico',
	ACTIVE_UNTIL: 'active_until',
	BUSINESS_REPRESENTATIVE: 'business_representative',
	LOGIN_EMAIL: 'login_email',
	DESCRIPTION: 'description',
	WALLET_ACOUNT: 'wallet_acount',
	WALLET_INFO: 'wallet_info',

	OPENING_HOURS_SOURCE: 'opening_hours_source',
	MONDAY_OPENING_HOURS: 'monday_opening_hours',
	TUESDAY_OPENING_HOURS: 'tuesday_opening_hours',
	WEDNESDAY_OPENING_HOURS: 'wednesday_opening_hours',
	THURSDAY_OPENING_HOURS: 'thursday_opening_hours',
	FRIDAY_OPENING_HOURS: 'friday_opening_hours',
	SATURDAY_OPENING_HOURS: 'saturday_opening_hours',
	SUNDAY_OPENING_HOURS: 'sunday_opening_hours',
	OPENING_HOURS_DESCRIPTION: 'opening_hours_description',

	CONTACT_SOURCE: 'contact_source',
	CONTACT_EMAILS: 'emails',
	CONTACT_WEB: 'web',
	CONTACT_PHONES: 'phones',

	ADDRESS_SOURCE: 'address_source',
	ADDRESS: 'address'
}
