import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@sznds/react'
import AbstractComponent from 'app/base/AbstractComponent'
import * as FormLines from '@inzeraty/form-lines'
import FilterRow from '../../components/filterRow/FilterRowView'
import { CategoryEntity } from '@inzeraty/models'
import RouteNames from 'app/base/RouteNames'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'

import './BrandModelForm.less'
import './BrandModelFormCS.json'

const CLASSNAME = 'cf-brand-model-form'

const ALL_MODELS_VALUE_CONST = -1
const NO_BRAND_VALUE_CONST = -1

export default class BrandModelForm extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			brandValue: PropTypes.number.isRequired,
			categoryEntity: PropTypes.instanceOf(CategoryEntity).isRequired,
			renderOnlyModelLinksForSEO: PropTypes.bool,
			onChange: PropTypes.func,
			filteredAdvertsCount: PropTypes.object,
			isLoadingFilteredAdvertsCount: PropTypes.bool,
			isCountShownWithAddText: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			className: '',
			formLineEntity: {},
			brandValue: NO_BRAND_VALUE_CONST,
			categoryEntity: new CategoryEntity({}),
			renderOnlyModelLinksForSEO: false,
			onChange: () => {},
			filteredAdvertsCount: {},
			isLoadingFilteredAdvertsCount: false,
			isCountShownWithAddText: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderModel = this._renderModel.bind(this)
		this._renderModelLink = this._renderModelLink.bind(this)
		this._onModelChange = this._onModelChange.bind(this)
	}

	componentDidMount() {
		this._setChosenBrandIntoState()
	}

	componentDidUpdate() {
		// kvuli dotahujicim se modelum se musime ujistit, ze opravdu mame
		// nastavenou odpovidajici znacku
		this._setChosenBrandIntoState()
	}

	_setChosenBrandIntoState() {
		const {
			formLineEntity: { value = new Map() },
			brandValue
		} = this.props

		if (!value.has(brandValue)) this._onChange(ALL_MODELS_VALUE_CONST, true)
	}

	render() {
		const { renderOnlyModelLinksForSEO } = this.props

		return renderOnlyModelLinksForSEO ? this._renderLimitedFormForSEO() : this._renderFullForm()
	}

	_renderLimitedFormForSEO() {
		const { className } = this.props
		const { children: models = [] } = this._getSelectedBrand()

		return <div className={className}>{models.map(this._renderModelLink)}</div>
	}

	_renderFullForm() {
		const { className } = this.props
		const { children: models = [] } = this._getSelectedBrand()
		const placeholders = this._placeholdersList()

		const favoriteModels = this.utils.FilterHelper.getSortedFavoriteOptions(models)
		const allModelsHeader = favoriteModels.length ? this.localize('BrandModelForm.allModels') : ''

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{this._renderModel({
					value: ALL_MODELS_VALUE_CONST,
					name: this.localize('BrandModelForm.allModels')
				})}

				{models.length > 0 ? (
					// pokial je tam len Ostatni modely, tak to nebudeme zobrazovat
					models.length > 1 && (
						<React.Fragment>
							{this._renderList(
								this.localize('BrandModelForm.favoriteModels'),
								favoriteModels.map((favModel, index) =>
									this._renderModel(favModel, index, 'models-top')
								)
							)}
							{this._renderList(
								allModelsHeader,
								models.map((allModel, index) =>
									this._renderModel(allModel, index, 'models-everything')
								)
							)}
						</React.Fragment>
					)
				) : (
					<div className={`${CLASSNAME}__placeholders`}>
						{this._renderList(allModelsHeader, placeholders.map(this._renderPlaceholder))}
					</div>
				)}
			</div>
		)
	}

	_renderList(header, items = []) {
		if (items.length) {
			return (
				<React.Fragment>
					{header && <div className={`${CLASSNAME}__header`}>{header}</div>}

					<ul>{items}</ul>
				</React.Fragment>
			)
		}
	}

	static getBrand(formLineEntity, brandValue) {
		const { options = [] } = formLineEntity

		return options.find((option) => option.value === brandValue) || {}
	}

	_getSelectedBrand() {
		const { brandValue, formLineEntity } = this.props

		return BrandModelForm.getBrand(formLineEntity, brandValue)
	}

	_getAdvertsCountTotalForBrand() {
		const { filteredAdvertsCount } = this.props
		const { children: brandModels = [] } = this._getSelectedBrand()

		const total = brandModels.reduce((total, { value: modelValue }) => {
			const countForModel = filteredAdvertsCount[modelValue] || 0
			return total + countForModel
		}, 0)

		return total
	}

	_renderModelLink(model) {
		const url = this._getModelUrl(model)
		const { filteredAdvertsCount } = this.props
		const { value } = model

		const count = filteredAdvertsCount[value] || 0

		if (count > 0) {
			return (
				<a
					key={model.value}
					href={url}
					onClick={(event) => {
						event.preventDefault()
					}}
				>
					{model.name}
				</a>
			)
		}
	}

	_getModelUrl(model) {
		const { seoName: modelSeoName } = model
		const { seoName: brandSeoName } = this._getSelectedBrand()
		const {
			categoryEntity: { seoName: categorySeoName }
		} = this.props

		return this.link(RouteNames.USERWEB.ADVERT_LIST, {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: brandSeoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]: modelSeoName
		})
	}

	_renderModel(model, index, dataDotDataType = 'all') {
		const { value: brandValue } = this._getSelectedBrand()
		const {
			filteredAdvertsCount,
			isLoadingFilteredAdvertsCount,
			isCountShownWithAddText
		} = this.props
		const { value, seoName: modelSeoName } = model
		const {
			formLineEntity: { value: selectedBrands = new Map() }
		} = this.props

		const selectedModels = selectedBrands.get(brandValue) || new Set()

		const isChecked =
			value === ALL_MODELS_VALUE_CONST ? selectedModels.size === 0 : selectedModels.has(value)

		const url = this._getModelUrl(model)

		return (
			// eslint-disable-next-line jsx-a11y/label-has-associated-control
			<label key={value}>
				<FilterRow
					count={
						value === ALL_MODELS_VALUE_CONST
							? this._getAdvertsCountTotalForBrand()
							: filteredAdvertsCount[value]
					}
					isCountShownWithAddText={isCountShownWithAddText}
					isCountLoading={isLoadingFilteredAdvertsCount}
					label={model.name}
					isSelected={isChecked}
					url={modelSeoName ? url : null}
					preventDefaultOnElementOnly={true}
					dataDot='choose-value'
					index={index}
					dataDotDataType={dataDotDataType}
				>
					<Checkbox
						className={`${CLASSNAME}__checkbox`}
						value={value}
						checked={isChecked}
						onChange={this._onModelChange}
					/>
				</FilterRow>
			</label>
		)
	}

	_onModelChange(event) {
		const { value: stringValue, checked } = event.target
		const value = Number(stringValue)
		this._onChange(value, checked)
	}

	_onChange(value, checked) {
		const {
			formLineEntity: { id, value: selectedBrands = new Map() },
			brandValue,
			onChange
		} = this.props

		const selectedModelsForBrand = new Set(selectedBrands.get(brandValue)) || new Set()

		if (value !== ALL_MODELS_VALUE_CONST && checked) {
			selectedModelsForBrand.add(value)
		} else if (value !== ALL_MODELS_VALUE_CONST && !checked) {
			selectedModelsForBrand.delete(value)
		}

		if (value === ALL_MODELS_VALUE_CONST || selectedModelsForBrand.size === 0) {
			selectedModelsForBrand.clear()
		}

		const newFormLineEntityValue = new Map(selectedBrands)
		newFormLineEntityValue.set(brandValue, selectedModelsForBrand)

		onChange(id, newFormLineEntityValue)
	}

	_placeholdersList() {
		const placeholderLength = 3
		const placeholders = []

		for (let i = 0; i < placeholderLength; i++) {
			placeholders.push({
				value: i
			})
		}

		return placeholders
	}

	_renderPlaceholder(placeholder) {
		const { value } = placeholder

		return (
			// eslint-disable-next-line jsx-a11y/label-has-associated-control
			<label key={value}>
				<div className={`${CLASSNAME}__placeholder`}>
					<span
						className={`${CLASSNAME}__placeholder-icon ${CLASSNAME}__placeholder-position--${value}`}
					/>
					<span
						className={`${CLASSNAME}__placeholder-text ${CLASSNAME}__placeholder-position--${value}`}
					/>
					<span className={`${CLASSNAME}__placeholder-count`}>
						<span
							className={`${CLASSNAME}__placeholder-count-value ${CLASSNAME}__placeholder-position--${value}`}
						/>
					</span>
				</div>
			</label>
		)
	}
}
