import React from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import ResponsiveAutoComplete from '../ResponsiveAutoComplete'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import AutoCompleteFilteredForm from 'app/component/autoComplete/forms/filtered/AutoCompleteFilteredForm'
import AutoCompleteAllForm from 'app/component/autoComplete/forms/all/AutoCompleteAllForm'
import { CATEGORIES } from 'app/base/Constants'

import './BrandAutoComplete.less'
import './BrandAutoCompleteCS.json'

const CLASSNAME = 'c-brand-auto-complete'

const BrandAutoComplete = (props) => {
	const localize = useLocalize()

	const {
		categoryData: { id: categoryId, seoName: categorySeoName } = {},
		placeholder = localize('BrandAutoComplete.placeholder'),
		popupTitle = localize('BrandAutoComplete.popupTitle'),
		popupPlaceholder = localize('BrandAutoComplete.popupPlaceholder')
	} = props

	const showBrandIconInAll = CATEGORIES.PASSENGER_CARS.id === categoryId

	const _renderItems = ({ items, inputValue, getItemProps, highlightedIndex }) => {
		const _renderItem = (item, index, isRenderedWithBrandIcon = false) => {
			const { value: brandValue, name, seoName } = item

			return (
				<AutoCompleteOption
					{...getItemProps({
						key: `${brandValue}__${isRenderedWithBrandIcon}`,
						index,
						item,
						isHighlighted: highlightedIndex === index
					})}
				>
					{isRenderedWithBrandIcon && seoName && categorySeoName && (
						<BrandIcon
							value={brandValue}
							className={`${CLASSNAME}__icon`}
							seoName={seoName}
							categorySeoName={categorySeoName}
						/>
					)}

					<span>{name}</span>
				</AutoCompleteOption>
			)
		}

		return inputValue ? (
			<AutoCompleteFilteredForm
				filteredItemsLabel={localize('BrandAutoComplete.labelFound')}
				filteredItems={items}
				renderItem={(...args) => _renderItem(...args, showBrandIconInAll)}
			/>
		) : (
			<AutoCompleteAllForm
				allItemsLabel={localize('BrandAutoComplete.labelAll')}
				items={items}
				favoriteItemsLabel={localize('BrandAutoComplete.labelFavorite')}
				favoriteItems={items.filter((item) => item.isFavorite)}
				renderItem={(...args) => _renderItem(...args, showBrandIconInAll)}
				renderFavoriteItem={(...args) => _renderItem(...args, true)}
			/>
		)
	}

	_renderItems.propTypes = {
		items: PropTypes.array,
		getItemProps: PropTypes.func,
		inputValue: PropTypes.string,
		highlightedIndex: PropTypes.number
	}

	const _renderDropdown = ({
		Dropdown,
		getDropdownProps,
		filteredItems,
		getItemProps,
		downshift
	}) => {
		const { inputValue, highlightedIndex } = downshift

		return (
			filteredItems.length > 0 && (
				<Dropdown {...getDropdownProps()}>
					{_renderItems({
						items: filteredItems,
						inputValue,
						getItemProps,
						highlightedIndex
					})}
				</Dropdown>
			)
		)
	}

	_renderDropdown.propTypes = {
		Dropdown: PropTypes.func,
		getDropdownProps: PropTypes.func,
		filteredItems: PropTypes.array,
		getItemProps: PropTypes.func,
		downshift: PropTypes.object
	}

	const _renderPopup = ({
		Popup,
		getPopupProps,
		PopupInput,
		getPopupInputProps,
		filteredItems,
		getItemProps,
		inputValue
	}) => {
		return (
			<Popup
				{...getPopupProps({
					title: popupTitle
				})}
			>
				<PopupInput
					{...getPopupInputProps({
						placeholder: popupPlaceholder
					})}
				/>

				{_renderItems({
					items: filteredItems,
					inputValue,
					getItemProps
				})}
			</Popup>
		)
	}

	_renderPopup.propTypes = {
		Popup: PropTypes.func,
		getPopupProps: PropTypes.func,
		PopupInput: PropTypes.func,
		getPopupInputProps: PropTypes.func,
		filteredItems: PropTypes.array,
		getItemProps: PropTypes.func,
		inputValue: PropTypes.string
	}

	return (
		<ResponsiveAutoComplete
			renderDropdown={_renderDropdown}
			renderPopup={_renderPopup}
			{...props}
			placeholder={placeholder}
		/>
	)
}

BrandAutoComplete.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	popupTitle: PropTypes.string,
	popupPlaceholder: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	size: PropTypes.string, // stejne jak v SDS: 'small' nebo 'regular'
	categoryData: PropTypes.object, // kvuli ikonkam znacek
	allItems: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			seoName: PropTypes.string, // kvuli ikonce znacky
			isFavorite: PropTypes.bool
		})
	),
	renderDropdown: PropTypes.func,
	renderPopup: PropTypes.func,
	onSelect: PropTypes.func,
	onSelectedItemDelete: PropTypes.func
}

export default React.memo(BrandAutoComplete)
