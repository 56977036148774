import IMAGES from 'app/base/ImagesConstants'
import { SERVICE } from 'app/base/Constants'
import { postProcessorFactory } from '@ima/plugin-http-cookie-cleaner'

export const init = (ns, oc, config) => {
	// jshint ignore:line

	let adminAuthHost

	// Aby fungovalo spouštění testů (testy nemají nastavený $App)
	if (config.$App.adminAuth && config.$App.adminAuth.host) {
		adminAuthHost = config.$App.adminAuth.host
	} else {
		adminAuthHost = ''
	}

	const versionStamp = `?version=${config.$Version}`

	const BASE_URL = `${config.$Protocol}//${config.$Host}`

	const DOT_DEFAULT_CONFIG = {
		service: SERVICE,
		host: config.$App.dot.host,
		impress: false,
		lazyLoadingEnabled: true,
		spa: true
	}

	return {
		prod: {
			$Http: {
				defaultRequestOptions: {
					timeout: 15 * 1000, // Request timeout
					repeatRequest: 0, // Count of automatic repeated request after failing request.
					ttl: 0, // Default time to live for cached request in ms.
					headers: {
						// Set default request headers
						Accept: 'application/json',
						'Accept-Language': config.$Language
					},
					withCredentials: true, //od IMA 0.16.0 je defaultne nastavena tato option na false, ak chceme posielat v hlavicke cookies, je potrebne nastavit na true
					cache: true, // if value exists in cache then returned it else make request to remote server.
					postProcessor: postProcessorFactory()
				},
				cacheOptions: {
					prefix: 'http.' // Cache key prefix for response bodies (already parsed as JSON) of completed HTTP requests.
				}
			},
			$Cache: {
				enabled: true, //Turn on/off cache for all application.
				ttl: 60000 // Default time to live for cached value in ms.
			},
			$Page: {
				$Render: {
					scripts: [
						'/static/js/locale/' + config.$Language + '.js' + versionStamp,
						'/static/js/app.bundle.min.js' + versionStamp
					],
					esScripts: [
						'/static/js/locale/' + config.$Language + '.js' + versionStamp,
						'/static/js/app.bundle.es.min.js' + versionStamp
					]
				}
			},
			$Static: {
				image: '/static/img',
				css: '/static/css',
				js: '/static/js',
				metronic: '/static/metronic',
				fonts: '/static/fonts'
			},
			api: {
				baseUrl: config.$Protocol + '//' + config.$Host + '/api/v1',
				internalApiUrl: config.$App.internalApiUrl,
				logoutUrl: `${adminAuthHost}/logout`
			},
			plugin: {
				analytic: {
					dot: {
						defaultConfig: DOT_DEFAULT_CONFIG,
						scriptUrl: config.$App.dot.scriptUrl
					}
				},
				monitoring: {
					// errorUrl: '/pro/report/error'
				},
				login: {
					scriptUrl: config.$App.loginUrl,
					serviceId: SERVICE,
					links: [
						{
							href: BASE_URL + '/oblibene',
							text: 'Oblíbené inzeráty'
						},
						{
							href: BASE_URL + '/moje-ulozena-hledani',
							text: 'Uložená hledání'
						},
						{
							href: BASE_URL + '/moje-inzeraty',
							text: 'Moje inzeráty / Vstup pro prodejce'
						}
					]
				}
			},
			resources: {
				preconnect: [
					'//ssp.seznam.cz',
					'//login.szn.cz',
					'//h.seznam.cz',
					'//geo.seznam.cz',
					'//gacz.hit.gemius.pl',
					'//image6.pubmatic.com'
				],
				dnsPrefetch: ['//i.seznam.cz', '//ads.pubmatic.com', '//secure.adnxs.com']
			},
			images: {
				logo: IMAGES.image__logo
			},
			ssp: {
				server: config.$App.ssp.server,
				url: config.$App.ssp.url,
				protocol: 'https://',
				badge: true
			},
			geoLocation: {
				server: config.$App.geoLocationServer,
				showDialogs: false
			},
			mapserverUrl: config.$App.mapserverUrl
		},

		dev: {
			$Http: {
				defaultRequestOptions: {
					timeout: 7000
				}
			},
			$Page: {
				$Render: {
					scripts: [
						'/static/js/polyfill.js' + versionStamp,
						'/static/js/shim.js' + versionStamp,
						'/static/js/vendor.client.js' + versionStamp,
						'/static/js/locale/' + config.$Language + '.js' + versionStamp,
						'/static/js/app.client.js' + versionStamp,
						'/static/js/hot.reload.js' + versionStamp
					],
					esScripts: [
						'/static/js/polyfill.es.js' + versionStamp,
						'/static/js/shim.es.js' + versionStamp,
						'/static/js/vendor.client.es.js' + versionStamp,
						'/static/js/locale/' + config.$Language + '.js' + versionStamp,
						'/static/js/app.client.es.js' + versionStamp,
						'/static/js/hot.reload.js' + versionStamp
					]
				}
			},
			plugin: {
				analytic: {
					dot: {
						defaultConfig: Object.assign({}, DOT_DEFAULT_CONFIG, {
							host: 'h.seznam.test.dszn.cz'
						}),
						scriptUrl: 'https://h.seznam.test.dszn.cz/js/dot-small.js'
					}
				}
			},
			ssp: {
				server: 'ssp.seznam.test.dszn.cz',
				url: 'https://ssp.seznam.test.dszn.cz/static/js/ssp.js',
				badge: true
			},
			geoLocation: {
				server: config.$App.geoLocationServer,
				showDialogs: false
			}
		}
	}
}
