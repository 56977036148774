const RESOURCE = '/items'

export default class RibbonSearchResource {
	constructor(restClient) {
		this._restClient = restClient
	}

	getSuggest(params, options) {
		return this._restClient.get(`${RESOURCE}/suggest`, params, options)
	}

	getDetectedFilters(params, options) {
		return this._restClient.get(`${RESOURCE}/search/detect_filters`, params, options)
	}
}
