import React from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import BasicWidget from '../../../../component/widgets/basic/BasicWidget'
import BasicInfoTooltip from 'app/page/userweb/newAdvert/basicInfo/components/tooltip/BasicInfoTooltip'
import BASIC_INFO_STATE_KEYS from 'app/page/userweb/newAdvert/basicInfo/BasicInfoStateKeys'
import BrandSelectWidget, {
	BRAND_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/brandSelect/BrandSelect'

export { BRAND_SELECT_WIDGET_ID }

const BrandSelect = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT } = props

	const linkedSelectAndLabel = AutoComplete.getIdsPropsHelper(formLineEntity.id)

	return (
		<BasicWidget
			formLineEntity={formLineEntity}
			name={BRAND_SELECT_WIDGET_ID}
			renderLabel={(formLineEntity, props) => {
				const { label, required } = formLineEntity
				const { labelId, inputId } = linkedSelectAndLabel

				return (
					<>
						<label {...props} id={labelId} htmlFor={inputId}>
							{label}
							{required && <span className='c-basic-widget__required'>*</span>}
						</label>
						<BasicInfoTooltip formLineEntity={formLineEntity} />
					</>
				)
			}}
			renderInput={(formLineEntity, inputWrapperProps) => {
				return (
					<div className={inputWrapperProps.className}>
						<BrandSelectWidget {...props} {...linkedSelectAndLabel} />
					</div>
				)
			}}
		/>
	)
}

BrandSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	categorySeoName: PropTypes.string,
	onChange: PropTypes.func,
	onBrandDeleted: PropTypes.func,
	loadModels: PropTypes.func
}

BrandSelect.defaultProps = {
	categorySeoName: '',
	onChange: DEFAULT_PROPS.FUNCTION,
	onBrandDeleted: DEFAULT_PROPS.FUNCTION,
	loadModels: DEFAULT_PROPS.FUNCTION
}

const selectors = (state) => ({
	loadModels: state[BASIC_INFO_STATE_KEYS.LOAD_MODELS],
	onBrandDeleted: state[BASIC_INFO_STATE_KEYS.CLEAR_MODELS]
})

export default React.memo(select(selectors)(BrandSelect))
