const RESOURCE = '/items'

export default class PaymentService {
	/**
	 * @constructor
	 * @param {RestClient} restClient
	 **/
	constructor(restClient) {
		this._restClient = restClient
	}

	/**
	 * Vrati dostupne platobne metody k inzeratu
	 *
	 * @method getAvailablePayments
	 * @public
	 * @param  {String} advertId ID inzeratu
	 * @param  {Object} params
	 * @param  {Object} options
	 * @return {Promise<JSON>} Promise jeho hodnota su data o platebnych metodach
	 */
	getAvailablePayments(advertId = '', params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/${advertId}/available_payments`, params, options)
	}

	/**
	 * Zasle platbu
	 *
	 * @method postPayment
	 * @public
	 * @param  {String} advertId ID inzeratu
	 * @param  {Object} params
	 * @param  {Object} options
	 * @return {Promise<JSON>} Vrati informaciu o spracovani platby
	 */
	postPayment(advertId = '', params = {}, options = {}) {
		return this._restClient.post(`${RESOURCE}/${advertId}/payments`, params, options)
	}

	closePayment(advertId = '', paymentId = '', params = {}, options = {}) {
		return this._restClient.update(`${RESOURCE}/${advertId}/payments/${paymentId}`, params, options)
	}

	topPayment(params = {}, options = {}) {
		return this._restClient.post(`${RESOURCE}/payments`, params, options)
	}
}
