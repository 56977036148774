import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import RegionService from 'app/model/region/RegionService'
import DistrictService from 'app/model/district/DistrictService'
import { REGIONS, DISTRICTS } from 'app/base/Constants'

export default class SellerListUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				PAGE: 'page',
				SEARCH: 'search',
				LOCALITY: 'locality',
				LETTER: 'letter',
				PER_PAGE: 'perPage'
			},
			URL_API_PARAMS: {
				SEARCH: 'name',
				LOCALITY: 'locality'
			}
		})
	}

	static getApiParams(routeParams = {}) {
		const regionEntities = RegionService.getRegions()
		const districtEntities = DistrictService.getDistricts()

		const {
			[SellerListUrlConvertor.constants.URL_APP_PARAMS.PAGE]: page,
			[SellerListUrlConvertor.constants.URL_APP_PARAMS.SEARCH]: search,
			[SellerListUrlConvertor.constants.URL_APP_PARAMS.LOCALITY]: locality
		} = routeParams

		const apiParams = UrlConvertor.getApiPaging(page)

		if (search) {
			apiParams[SellerListUrlConvertor.constants.URL_API_PARAMS.SEARCH] = search
		}

		if (locality) {
			const region = regionEntities.find(({ id, seoName }) => {
				return (
					(locality === 'praha' && id === REGIONS.PRAHA.id) ||
					(locality === 'vysocina' && id === REGIONS.VYSOCINA.id) ||
					locality === seoName.replace('-kraj', '')
				)
			})
			const district = districtEntities.find(({ seoName }) => locality === seoName)

			if (district) {
				apiParams[
					SellerListUrlConvertor.constants.URL_API_PARAMS.LOCALITY
				] = `district,${district.id}`
			} else if (region) {
				apiParams[SellerListUrlConvertor.constants.URL_API_PARAMS.LOCALITY] = `region,${region.id}`
			}
		}

		return apiParams
	}

	static createPaginationUrl(page, router) {
		const { params } = router.getCurrentRouteInfo()

		if (page > 1) {
			params[SellerListUrlConvertor.constants.URL_APP_PARAMS.PAGE] = page
		} else {
			delete params[SellerListUrlConvertor.constants.URL_APP_PARAMS.PAGE]
		}

		return UrlConvertor.getUrl(router, params)
	}

	static getSearchUrl(search, router) {
		const { params } = UrlConvertor.getRouteInfo(router)
		const { [SellerListUrlConvertor.constants.URL_APP_PARAMS.LOCALITY]: locality } = params

		const updatedParams = {}

		if (search) {
			updatedParams[SellerListUrlConvertor.constants.URL_APP_PARAMS.SEARCH] = search
		}
		if (locality) {
			updatedParams[SellerListUrlConvertor.constants.URL_APP_PARAMS.LOCALITY] = locality
		}

		return UrlConvertor.getUrl(router, updatedParams)
	}

	static getUpdatedRegionData({ id, name, seoName }) {
		let updatedName
		let updatedSeoName

		if (id === REGIONS.PRAHA.id) {
			updatedName = 'Praha'
			updatedSeoName = 'praha'
		} else if (id === REGIONS.VYSOCINA.id) {
			updatedName = 'Vysočina'
			updatedSeoName = 'vysocina'
		} else {
			updatedName = name.replace(' kraj', '')
			updatedSeoName = seoName.replace('-kraj', '')
		}

		return {
			id,
			name: updatedName,
			seoName: updatedSeoName
		}
	}

	static getUpdatedDistrictData({ id, name = '', seoName = '' }) {
		let districtName = name

		if (id === DISTRICTS.BRNO_MESTO.id) {
			districtName = 'Brno'
		} else if (id === DISTRICTS.OSTRAVA_MESTO.id) {
			districtName = 'Ostrava'
		} else if (id === DISTRICTS.PLZEN_MESTO.id) {
			districtName = 'Plzeň'
		} else if (id === DISTRICTS['HLAVNI_MESTO-PRAHA'].id) {
			districtName = 'Praha'
		}

		return {
			id,
			name: districtName,
			seoName
		}
	}
}
