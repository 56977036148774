import { createEntityList } from '@inzeraty/form-lines'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { getReviewTypeOptions } from 'app/component/reviews/reviewForm/ReviewFormLines'

export default class IAReviewListController extends AbstractInternalAdminController {
	static get ACTIONS() {
		return Object.freeze({
			DELETE: 'delete'
		})
	}

	constructor(dependenciesHelper, entitiesFilterExtension, reviewService) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._reviewService = reviewService

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)
		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		})
	}

	update() {
		return {
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		}
	}

	async getEntitiesAndPagination() {
		const fetchReviewsList = async (params) => {
			try {
				const { reviewEntities, paginationEntity } = await this._reviewService.getReviewsList(
					params
				)

				return {
					entities: reviewEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const pageLoaderId = this._pageLoaderExtension.show()

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = '-create_date'
		}

		const entitiesAndPagination = await fetchReviewsList(params)

		this._pageLoaderExtension.hide(pageLoaderId)

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	async _handleAction({ action, entities = [] }) {
		const [entity = {}] = entities
		const { id } = entity

		let promise
		let errorMessage

		switch (action) {
			case IAReviewListController.ACTIONS.DELETE:
				if (window.confirm(`Opravdu si přejete smazat recenzi ${id}`)) {
					promise = this._reviewService.deleteReview(id)
					errorMessage = `Nepodařilo se smazat recenzi ${id}`
				} else {
					return {}
				}
				break
		}

		return {
			promise,
			getErrorMessage: () => errorMessage
		}
	}

	async _processSuccessAction() {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	async _filterInitiezerClb() {
		return createEntityList([
			...this._entitiesFilterExtension.getCategoryManufacturerModelSelects(),
			this._entitiesFilterExtension.getSelect(
				'type',
				'Hodnocení',
				'Vyberte',
				getReviewTypeOptions(this._utils.$Dictionary)
			)
		])
	}
}
