
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Articles'] = {
  titleAll: function(d) { return "Auto-Moto články"; },
  titleNews: function(d) { return "Auto-Moto novinky"; },
  titleTests: function(d) { return "Recenze a testy aut"; },
  titleTips: function(d) { return "Auto moto tipy a rady"; },
  headerAll: function(d) { return "Auto-Moto články"; },
  headerNews: function(d) { return "Auto-Moto novinky"; },
  headerTests: function(d) { return "Recenze a testy aut"; },
  headerTips: function(d) { return "Tipy a rady"; },
  descriptionAll: function(d) { return "Auto moto magazín od Seznam.cz. Aktuality o nových i ojetých autech - představení nových vozů, testy a recenze, trendy v automoto, tipy, jak se starat o auto, jak řídit a vše co vlastník auta i autíčkář potřebuje vědět."; },
  descriptionNews: function(d) { return "Auto moto novinky od Seznam.cz. Aktuality o nových i ojetých autech - představení nových vozů, aktuality z veletrhů a velkých auto-moto akcí, fotky novinek o autech."; },
  descriptionTests: function(d) { return "Testy a recenze aut od Seznam.cz. Redakční testy nových aut i bazarovek. Od profesionálních recenzentů získáte skvělé zhodnocení vzhledu, jízdních vlastností a případně i poruchovostí aut, abyste věděli více a mohli si vybrat správné auto."; },
  descriptionTips: function(d) { return "Tipy a rady pro řidiče od Seznam.cz, Pomůžeme vám občerstvit si pravidla provozu a legislativu okolo řízení, dáme vám tipy na pečování o auto a jiné praktické rady."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	