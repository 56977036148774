import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Icon } from '@sznds/react'
import { CHECKER_NORMAL_OUTLINE_24 } from '@sznds/icons'
import { useLocalize } from 'app/base/componentHelpers'

import './MySavedSearchAlreadySavedBanner.less'
import './MySavedSearchAlreadySavedBannerCS.json'

const CLASSNAME = 'c-my-saved-search-already-saved-banner'

const MySavedSearchAlreadySavedBanner = ({ className }) => {
	const localize = useLocalize()

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<div className={`${CLASSNAME}__wrapper`}>
				<Icon symbol={CHECKER_NORMAL_OUTLINE_24} className={`${CLASSNAME}__icon`} />
				<span>{localize('MySavedSearchAlreadySavedBanner.text')}</span>
			</div>
		</div>
	)
}

MySavedSearchAlreadySavedBanner.propTypes = {
	className: PropTypes.string
}

export default React.memo(MySavedSearchAlreadySavedBanner)
