
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['ActionMessage'] = {
  titleSuccess: function(d) { return "Akce byla úspěšně provedena."; },
  titleError: function(d) { return "Nastala chyba při provedení akce."; },
  allowSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl povolen", other: "Inzeráty " + d.IDS + " byly povoleny" }) + "."; },
  allowError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo povolit."; },
  activateSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl aktivován", other: "Inzeráty " + d.IDS + " byly aktivovány" }) + "."; },
  activateError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo aktivovat."; },
  deactivateSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl deaktivován", other: "Inzeráty " + d.IDS + " byly deaktivovány" }) + "."; },
  deactivateError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo deaktivovat."; },
  removeSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl smazán", other: "Inzeráty " + d.IDS + " byly smazány" }) + "."; },
  removeConfirmation: function(d) { return "Opravdu si přejete smazat " + plural(d.COUNT, 0, cs, { one: "inzerát", other: "inzeráty" }) + " " + d.IDS + "?"; },
  removeError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo smazat."; },
  forbidSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl zakázán", other: "Inzeráty " + d.IDS + " byly zakázány" }) + "."; },
  forbidError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo zakázat."; },
  restoreSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl obnoven", other: "Inzeráty " + d.IDS + " byly obnoveny" }) + "."; },
  restoreConfirmation: function(d) { return "Opravdu si přejete obnovit " + plural(d.COUNT, 0, cs, { one: "inzerát", other: "inzeráty" }) + " " + d.IDS + "?"; },
  restoreError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo obnovit."; },
  toppedSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl topován", other: "Inzeráty " + d.IDS + " byly topovány" }) + "."; },
  toppedError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo topovat" + d.REASON + "."; },
  toppedOnlyOnePremiseError: function(d) { return "Nelze topovat inzeráty od více bazarů najednou."; },
  toppedLowCredit: function(d) { return ", nedostatek kreditu"; },
  toppedNotConnectedWallet: function(d) { return ", peněženka není připojená"; },
  toppedNotActivatedWallet: function(d) { return ", peněženka není aktivovaná"; },
  toppedNotActive: function(d) { return ", inzerát není aktivní"; },
  addToPOSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl přidán do PV", other: "Inzeráty " + d.IDS + " byly přidány do PV" }) + "."; },
  addToPOError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo přidat do PV."; },
  removeFromPOSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl odebrán z PV", other: "Inzeráty " + d.IDS + " byly odebrány z PV" }) + "."; },
  removeFromPOError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo odebrat z PV."; },
  suspendSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl zneaktivněn", other: "Inzeráty " + d.IDS + " byly zneaktivněny" }) + "."; },
  suspendError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo zneaktivnit."; },
  publishSuccess: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát " + d.IDS + " byl zveřejněn", other: "Inzeráty " + d.IDS + " byly zveřejněny" }) + "."; },
  publishError: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " " + d.IDS + " se nepodařilo zveřejnit."; },
  prolongSuccess: function(d) { return "Inzerát " + d.IDS + " byl prodloužen do " + d.DATE + "."; },
  prolongError: function(d) { return "Inzerát " + d.IDS + " se nepodařilo prodloužit"; },
  saveSuccess: function(d) { return "Inzerát " + d.IDS + " byl uložen."; },
  saveError: function(d) { return "Inzerát " + d.IDS + " se nepodařilo uložit."; },
  forbidUserSuccess: function(d) { return "Stav uživatele " + d.USER + " byl úspěšně změněn."; },
  forbidUserError: function(d) { return "Stav uživatele " + d.USER + " se nepodařilo změnit."; },
  moduleMissTitle: function(d) { return "Vyčerpali jste limit inzerátů"; },
  moduleMissBody: function(d) { return plural(d.COUNT, 0, cs, { one: "Vybraný inzerát", other: "Vybrané inzeráty" }) + " " + d.IDS + " se nepodařilo aktivovat. Vyčerpali jste povolený limit. Pro navýšení limitu kontaktujte Vašeho obchodního zástupce."; },
  moduleMissLink: function(d) { return "Kontaktovat obchodního zástupce"; },
  insufficientImagesErrorTitle: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát nesplňuje", other: "Inzeráty nesplňují" }) + " min. počet fotografií"; },
  insufficientImagesErrorBody: function(d) { return plural(d.COUNT, 0, cs, { one: "Vybraný inzerát", other: "Vybrané inzeráty" }) + " " + d.IDS + " se nepodařilo aktivovat. Přidejte fotografie k inzerátům, které nesplňují min. počet fotek."; },
  vinDuplicationErrorTitle: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " s duplicitním VIN kódem."; },
  vinDuplicationErrorBody: function(d) { return plural(d.COUNT, 0, cs, { one: "Vybraný inzerát", other: "Vybrané inzeráty" }) + " " + d.IDS + " se nepodařilo aktivovat. Snažíte se aktivovat " + plural(d.COUNT, 0, cs, { one: "inzerát", other: "inzeráty" }) + " s duplicitním VIN kódem."; },
  vinDuplicationFraudErrorTitle: function(d) { return plural(d.COUNT, 0, cs, { one: "Inzerát", other: "Inzeráty" }) + " s opakovaně vkládaným VIN kódem."; },
  vinDuplicationFraudErrorBody: function(d) { return plural(d.COUNT, 0, cs, { one: "Vybraný inzerát", other: "Vybrané inzeráty" }) + " " + d.IDS + " se nepodařilo aktivovat. Snažíte se aktivovat " + plural(d.COUNT, 0, cs, { one: "inzerát", other: "inzeráty" }) + " s opakovaně vkládaným VIN kódem."; },
  actionError: function(d) { return "U " + plural(d.COUNT, 0, cs, { one: "inzerátu", other: "inzerátů" }) + " s id " + d.IDS + " se nepodařila provést akce."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	