import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import ROUTE_NAMES from 'app/base/RouteNames'
import IMAGES from 'app/base/ImagesConstants'

import './Logo.less'

const CLASSNAME = 'c-logo'

export default class Logo extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			isPremiseAdmin: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			className: '',
			isPremiseAdmin: false
		}
	}

	constructor(props, context) {
		super(props, context)
	}

	render() {
		const { className, isPremiseAdmin } = this.props
		const homeUrl = this.link(ROUTE_NAMES.USERWEB.HOMEPAGE)

		return (
			<a
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
				href={homeUrl}
				data-dot='go-to-homepage'
			>
				<img
					className={classnames({
						[`${CLASSNAME}__img`]: true,
						[`${CLASSNAME}__img--admin`]: isPremiseAdmin
					})}
					src={isPremiseAdmin ? IMAGES.image__logo_admin : IMAGES.image__logo}
					alt='sauto.cz'
				/>
			</a>
		)
	}
}
