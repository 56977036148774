import React, { useState, useEffect, useRef } from 'react'
import * as FormLines from '@inzeraty/form-lines'
import { Popup as BasePopup } from '@inzeraty/components'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import ShowItemsButton from 'app/component/showItemsButton/ShowItemsButton'
import Header from '../form/header/Header'
import Form from '../form/Form'
import { PREMISE_FORM_LINES_IDS } from '../PremiseFiltersFormLines'
import PropTypes from 'prop-types'

import './Popup.less'

const CLASSNAME = 'c-client-popup'

const Popup = (props) => {
	const {
		premiseFiltersFormLines = DEFAULT_PROPS.ARRAY,
		total,
		isLoadingTotal,
		// vraci novy celkovy pocet pro CTA tlacitko
		loadPremiseAdvertsTotal = DEFAULT_PROPS.FUNCTION,
		// vraci znacky pro vybranou kategorii
		loadAllBrandsForCategory = DEFAULT_PROPS.FUNCTION,
		// vraci modely pro vybranou znacku
		loadAllModelsForBrand = DEFAULT_PROPS.FUNCTION,
		// zavola se, pokud zmenou velikosti okna doslo k zavreni popupu (mobil -> desktop)
		onUnmount = DEFAULT_PROPS.FUNCTION,
		// zavola se, pokud uzivatel zavrel popup krizkem
		onClose = DEFAULT_PROPS.FUNCTION
	} = props

	// v ramci popupu si musime drzet vlastni formlines, protoze
	// nechceme, aby dochazelo pri zmene na popupu k prubeznym
	// redirectum, ale jen k jednomu, az se cely popup zavre
	const [internalPremiseFiltersFormLines, setInternalPremiseFiltersFormLines] = useState(
		premiseFiltersFormLines
	)

	const internalPremiseFiltersFormLinesRef = useRef()

	const [internalTotal, setInternalTotal] = useState(total)

	const [internalIsLoadingTotal, setInternalIsLoadingTotal] = useState(isLoadingTotal)

	const isPopupBeingClosed = useRef(false)

	React.useEffect(() => {
		internalPremiseFiltersFormLinesRef.current = internalPremiseFiltersFormLines
	}, [internalPremiseFiltersFormLines])

	useEffect(() => {
		return () => {
			if (!isPopupBeingClosed.current) {
				isPopupBeingClosed.current = true
				// pri zmene velikost okna muze take dojit k zavreni popupu (prechod mobil ->
				// desktop), v tomto pripade chceme interni formliny zachovat
				onUnmount(internalPremiseFiltersFormLinesRef.current)
			}
		}
	}, [])

	useEffect(() => {
		const loadBrandsForCategory = async (categoryId) => {
			try {
				return await loadAllBrandsForCategory(categoryId)
			} catch (e) {
				// na chybu nebudeme nijak reagovat
			}
		}

		const fetchBrandsIfNeeded = async () => {
			const { value: category } = internalPremiseFiltersFormLines.find(
				({ id }) => id === PREMISE_FORM_LINES_IDS.CATEGORY_SELECT_ID
			)

			const { options } = internalPremiseFiltersFormLines.find(
				({ id }) => id === PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID
			)

			if (!options && category) {
				const brands = await loadBrandsForCategory(category.id)

				if (!isPopupBeingClosed.current) {
					const updatedFormLineEntities = FormLines.updateEntities(
						internalPremiseFiltersFormLines,
						[
							{
								id: PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID,
								options: brands
							}
						]
					)

					setInternalPremiseFiltersFormLines(updatedFormLineEntities)
				}
			}
		}

		fetchBrandsIfNeeded()
	}, [internalPremiseFiltersFormLines])

	useEffect(() => {
		const loadModelsForBrand = async (categoryId, brandId) => {
			try {
				return await loadAllModelsForBrand(categoryId, brandId)
			} catch (e) {
				// na chybu nebudeme nijak reagovat
			}
		}

		const fetchModelsIfNeeded = async () => {
			const { value: category } = internalPremiseFiltersFormLines.find(
				({ id }) => id === PREMISE_FORM_LINES_IDS.CATEGORY_SELECT_ID
			)

			const { value: brand } = internalPremiseFiltersFormLines.find(
				({ id }) => id === PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID
			)

			const { options } = internalPremiseFiltersFormLines.find(
				({ id }) => id === PREMISE_FORM_LINES_IDS.MODEL_AUTO_COMPLETE_ID
			)

			if (!options && category && brand) {
				const models = await loadModelsForBrand(category.id, brand.id)

				if (!isPopupBeingClosed.current) {
					const updatedFormLineEntities = FormLines.updateEntities(
						internalPremiseFiltersFormLines,
						[
							{
								id: PREMISE_FORM_LINES_IDS.MODEL_AUTO_COMPLETE_ID,
								options: models
							}
						]
					)

					setInternalPremiseFiltersFormLines(updatedFormLineEntities)
				}
			}
		}

		fetchModelsIfNeeded()
	}, [internalPremiseFiltersFormLines])

	const handleClose = () => {
		isPopupBeingClosed.current = true
		onClose(internalPremiseFiltersFormLines)
	}

	const handleReset = () => {
		changeInternalPremiseFiltersFormLines(
			internalPremiseFiltersFormLines.map(({ id }) => ({ id, value: undefined }))
		)
	}

	const changeInternalPremiseFiltersFormLines = (changes) => {
		const updatedFormLineEntities = FormLines.updateEntities(
			internalPremiseFiltersFormLines,
			changes
		)

		setInternalPremiseFiltersFormLines(updatedFormLineEntities)

		loadPremiseAdvertsTotalForSubmitButton(updatedFormLineEntities)
	}

	const loadPremiseAdvertsTotalForSubmitButton = async (updatedFormLineEntities) => {
		try {
			setInternalIsLoadingTotal(true)

			const total = await loadPremiseAdvertsTotal(updatedFormLineEntities)

			!isPopupBeingClosed.current && setInternalTotal(total)
		} catch (e) {
			// na chybu nebudeme nijak reagovat, nechame nastavene puvodni cislo
		} finally {
			!isPopupBeingClosed.current && setInternalIsLoadingTotal(false)
		}
	}

	const isSubmitButtonEnabled = isNullOrUndefined(internalTotal) ? true : internalTotal > 0

	return (
		<BasePopup
			className={CLASSNAME}
			isOpen={true}
			isFullscreen={true}
			stickyHeader={false}
			renderHeader={() => <Header onClose={handleClose} onReset={handleReset} />}
		>
			<Form
				premiseFiltersFormLines={internalPremiseFiltersFormLines}
				changePremiseFiltersFormLines={changeInternalPremiseFiltersFormLines}
				renderSubmitButton={(renderTextForSubmitButton) => (
					<ShowItemsButton
						className={`${CLASSNAME}__submit`}
						count={internalTotal}
						isLoading={internalIsLoadingTotal}
						disabled={!isSubmitButtonEnabled}
						showIcon={false}
						onClick={handleClose}
						renderText={(renderDefaultLoadingDots) =>
							renderTextForSubmitButton(
								internalTotal,
								internalIsLoadingTotal,
								renderDefaultLoadingDots
							)
						}
					/>
				)}
			/>
		</BasePopup>
	)
}

Popup.propTypes = {
	premiseFiltersFormLines: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	total: PropTypes.number,
	isLoadingTotal: PropTypes.bool,
	loadPremiseAdvertsTotal: PropTypes.func,
	loadAllBrandsForCategory: PropTypes.func,
	loadAllModelsForBrand: PropTypes.func,
	onUnmount: PropTypes.func,
	onClose: PropTypes.func
}

export default React.memo(Popup)
