import { Sort } from '@inzeraty/helpers'

const CZECH_CHARS_ORDERED = {
	0: '0',
	1: '1',
	2: '2',
	3: '3',
	4: '4',
	5: '5',
	6: '6',
	7: '7',
	8: '8',
	9: '9',
	a: 'a',
	á: 'á',
	b: 'b',
	c: 'c',
	č: 'č',
	d: 'd',
	ď: 'ď',
	e: 'e',
	é: 'é',
	ě: 'ě',
	f: 'f',
	g: 'g',
	h: 'h',
	ch: 'ch',
	i: 'i',
	í: 'í',
	j: 'j',
	k: 'k',
	l: 'l',
	m: 'm',
	n: 'n',
	ň: 'ň',
	o: 'o',
	ó: 'ó',
	p: 'p',
	q: 'q',
	r: 'r',
	ř: 'ř',
	s: 's',
	š: 'š',
	t: 't',
	ť: 'ť',
	u: 'u',
	ú: 'ú',
	ů: 'ů',
	v: 'v',
	w: 'w',
	x: 'x',
	y: 'y',
	ý: 'ý',
	z: 'z',
	ž: 'ž'
}

const getSortedList = (list) => {
	const sortOptionsAlphabetically = (optionA = {}, optionB = {}) => {
		const { name: nameA = '' } = optionA
		const { name: nameB = '' } = optionB

		return Sort.sortAlphabetically(nameA, nameB)
	}

	return list.sort(sortOptionsAlphabetically)
}

export const orderedListToAlphabetGroups = (list) => {
	const allSortedList = getSortedList(list)

	return Object.keys(CZECH_CHARS_ORDERED).reduce((result, char) => {
		const alphabetGroup = allSortedList.filter((item) => {
			const { name } = item

			let firstCharacter = name.charAt(0).toLowerCase()

			if (firstCharacter === 'c') {
				const secondCharacter = name.charAt(1).toLowerCase()

				if (secondCharacter === 'h') {
					firstCharacter += secondCharacter
				}
			}

			return firstCharacter === char
		})

		const title = char.toUpperCase()

		if (alphabetGroup.length > 0) {
			return Object.assign({}, result, {
				[title]: [...alphabetGroup]
			})
		} else {
			return result
		}
	}, [])
}
