
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PromoCebia'] = {
  mainHeader: function(d) { return "Základní prověření vozidla od Cebia zdarma"; },
  paragraph_1: function(d) { return "Cebia je více než 30 let leaderem na trhu v prověřování původu a historie ojetých vozidel. Poskytuje nástroje k prověřování inzerovaných vozidel, které zkvalitňují naše služby, zjednodušují vaše rozhodování při procházení inzerátů a pomáhají vám s bezpečným nákupem auta."; },
  paragraph_2: function(d) { return "Na portálu Sauto.cz poskytuje Cebia bezplatné základní prověření vozidla. Tím vám umožňuje online přístup k důležitým datům o původu a historii vozidla."; },
  link_1: function(d) { return "Ukázka základního prověření vozidla zdarma"; },
  listTitle: function(d) { return "Základní prověření obsahuje"; },
  check1: function(d) { return "Kontrolu najetých kilometrů"; },
  check2: function(d) { return "Kontrolu odcizení vozidla"; },
  check3: function(d) { return "Kontrolu financování"; },
  check4: function(d) { return "Kontrolu taxi"; },
  imgAlt_1: function(d) { return "Prověření Cebia"; },
  paragraph_3: function(d) { return "Navazující kompletní prověření vozidla zahrnující dalších 6 parametrů, můžete od společnosti Cebia získat s 50% slevou."; },
  header_1: function(d) { return "Jak si zobrazit prověřené vozy"; },
  paragraph_4: function(d) { return "Výpis všech vozidel se základním prověřením Cebia získáte po označení položky ve filtrech „Ověřené vozy“ na stránce výpisu vozidel."; },
  link_2: function(d) { return "Zobrazit vozy s prověřením Cebia"; },
  imgAlt_2: function(d) { return "Filtrování prověřených vozidel"; },
  paragraph_5: function(d) { return "U inzerátů, u kterých je dostupné základní prověření vozidla, je uvedeno logo Cebia na úvodní fotografii v přehledu inzerátů. Na první pohled tak vidíte inzeráty obsahující prověření zdarma."; },
  imgAlt_3: function(d) { return "Logo Cebia na inzerátu vozidla"; },
  paragraph_6: function(d) { return "Výsledek prověření historie vozidla si pak zobrazíte po kliknutí na odkaz “Zobrazit historii vozu zdarma”."; },
  header_2: function(d) { return "Prověření kompletní historie vozidla Cebia"; },
  imgAlt_4: function(d) { return "Prověření historie vozidla zdarma"; },
  paragraph_7: function(d) { return "Pokud inzerát neobsahuje základní prověření vozidla zdarma, můžete si vozidlo kompletně prověřit v systému společnosti Cebia se slevou 100 Kč. S pomocí tohoto prověření se vyhnete zatajeným haváriím, stočeným kilometrům či omlazenému, odcizenému či jinak defektnímu vozidlu."; },
  paragraph_8: function(d) { return "Vozidlo si můžete prověřit zadáním VIN do pole níže."; },
  paragraph_9: function(d) { return "Nebo můžete kliknout na odkaz v inzerátu vozidla, pokud inzerent uvedl k vozidlu VIN."; },
  imgAlt_5: function(d) { return "Prověření historie vozidla"; },
  paragraph_10: function(d) { return "Po kliknutí na odkaz „Zobrazit historii vozu“ se vám zdarma zobrazí, kolik informací je o daném vozidle v systému Cebia k dispozici. Po zaplacení obratem zjistíte maximum důležitých informací o vámi vybraném vozidle, jako je záznam vývoje stavu tachometru, záznamy o poškození, ověření roku výroby, historii inzerce, servisní historii, ověření aktuální tržní ceny vozidla a řadu dalších informací."; },
  vinValidateText: function(d) { return "Prověření historie vozidla"; },
  vinValidateDiscount: function(d) { return "SLEVA 100 " + d.CURRENCY; },
  validate: function(d) { return "Prověřit"; },
  validationError: function(d) { return "Chybný formát VIN"; },
  insertVinCode: function(d) { return "Zadejte VIN kód"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	