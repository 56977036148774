export default class MetricsDataHelper {
	static getListViewMetric(isOperatingLease, isMirroring, metrics = {}) {
		return isOperatingLease
			? metrics.listViewOperatingLease
			: isMirroring
			? metrics.listViewMirroring
			: metrics.listView
	}

	static getDetailViewMetric(isOperatingLease, isMirroring, metrics = {}) {
		return isOperatingLease
			? metrics.detailViewOperatingLease
			: isMirroring
			? metrics.detailViewMirroring
			: metrics.detailView
	}

	static getDetailViewFromListMetric(isOperatingLease, isMirroring, metrics = {}) {
		return isOperatingLease
			? metrics.detailViewFromListOperatingLease
			: isMirroring
			? metrics.detailViewFromListMirroring
			: metrics.detailViewFromList
	}

	static getDetailViewFromOtherMetric(isOperatingLease, isMirroring, metrics = {}) {
		return isOperatingLease
			? metrics.detailViewFromOtherOperatingLease
			: isMirroring
			? metrics.detailViewFromOtherMirroring
			: metrics.detailViewFromOther
	}

	static getReplySentMetric(isOperatingLease, isMirroring, metrics = {}) {
		return isOperatingLease
			? metrics.replySentOperatingLease
			: isMirroring
			? metrics.replySentMirroring
			: metrics.replySent
	}

	static getPhoneViewMetric(isOperatingLease, isMirroring, metrics = {}) {
		return isOperatingLease
			? metrics.phoneViewOperatingLease
			: isMirroring
			? metrics.phoneViewMirroring
			: metrics.phoneView
	}
}
