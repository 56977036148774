
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['CarDetailsSection'] = {
  mainText: function(d) { return "Technické údaje a historie"; },
  yesAnswer: function(d) { return "Ano"; },
  noAnswer: function(d) { return "Ne"; },
  condition: function(d) { return "Stav vozu"; },
  engine: function(d) { return "Pohon"; },
  engineVolume: function(d) { return "Objem"; },
  engineVolumeValue: function(d) { return d.ENGINE_VOLUME + " ccm"; },
  enginePower: function(d) { return "Výkon"; },
  enginePowerValue: function(d) { return d.POWER_KW + " kW (" + plural(d.POWER_HP, 0, cs, { one: d.POWER_HP + " kůň", few: d.POWER_HP + " koně", other: d.POWER_HP + " koní" }) + ")"; },
  batteryCapacity: function(d) { return "Kapacita akumulátoru"; },
  batteryCapacityValue: function(d) { return d.BATTERY_CAPACITY + " kWh"; },
  averageElectricMileage: function(d) { return "Spotřeba"; },
  averageElectricMileageValue: function(d) { return d.AVERAGE_ELECTRIC_MILEAGE + " kWh / 100 km"; },
  vehicleRange: function(d) { return "Dojezd"; },
  vehicleRangeValue: function(d) { return d.VEHICLE_RANGE + " km"; },
  gearboxCb: function(d) { return "Převodovka"; },
  gearboxLevelsCb: function(d) { return "Převodový stupeň"; },
  driveCb: function(d) { return "Pohon"; },
  fuelCb: function(d) { return "Palivo"; },
  averageGasMileage: function(d) { return "Průměrná spotřeba"; },
  averageGasMileageValue: function(d) { return d.FUEL_CONSUMPTION + " l / 100 km"; },
  euroLevelCb: function(d) { return "Plní EURO"; },
  certificationCb: function(d) { return "Ověřovací program"; },
  cebiaReport: function(d) { return "Ověřovací program"; },
  historyAndDocuments: function(d) { return "Historie a dokumenty"; },
  conditionCb: function(d) { return "Stav"; },
  tachometer: function(d) { return "Najeto"; },
  tachometerValue: function(d) { return d.TACHOMETER_VALUE + " km"; },
  firstOwner: function(d) { return "1. majitel"; },
  serviceBook: function(d) { return "Serv. knížka"; },
  countryOfOriginCb: function(d) { return "Země původu"; },
  manufacturingDate: function(d) { return "Vyrobeno"; },
  inOperationDate: function(d) { return "V provozu od"; },
  guaranteeDate: function(d) { return "Platnost záruky"; },
  stkDate: function(d) { return "STK"; },
  vin: function(d) { return "VIN"; },
  crashedInPast: function(d) { return "Havarováno"; },
  environmentalTaxPaid: function(d) { return "Ekologická daň"; },
  vehicleSpec: function(d) { return "Specifikace vozu"; },
  vehicleBodyCb: function(d) { return "Karosérie"; },
  colorCb: function(d) { return "Barva"; },
  airconditionCb: function(d) { return "Klimatizace"; },
  airbags: function(d) { return "Počet airbagů"; },
  doors: function(d) { return "Počet dveří"; },
  capacity: function(d) { return "Počet míst"; },
  tuning: function(d) { return "Tuning"; },
  adjustmentsForHandicapped: function(d) { return "Úpravy ZTP"; },
  weight: function(d) { return "Hmotnost"; },
  weightValue: function(d) { return d.WEIGHT_VALUE + " kg"; },
  loadCapacity: function(d) { return "Nosnost"; },
  loadCapacityValue: function(d) { return d.LOAD_CAPACITY_VALUE + " kg"; },
  beds: function(d) { return "Počet lůžek"; },
  equipmentHeading: function(d) { return "Výbava vozu"; },
  equipmentnNoSection: function(d) { return "Další výbava"; },
  showMore: function(d) { return "Zobrazit více"; },
  showLess: function(d) { return "Zobrazit méně"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	