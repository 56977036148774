
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SMSPayment'] = {
  header: function(d) { return "Platba skrze SMS bránu"; },
  instructionHeader: function(d) { return "Instrukce k platbě"; },
  infoText1: function(d) { return "pošlete SMS ve tvaru: "; },
  infoTextBold1: function(d) { return "SAUTO"; },
  infoText2: function(d) { return " na telefonní číslo "; },
  infoText3: function(d) { return " a zašleme vám zpět SMS obsahující PIN kód"; },
  infoTextBold2: function(d) { return "(PIN kód je platný pouze 30 minut)"; },
  infoText4: function(d) { return "PIN kód zadejte do políčka pod touto zprávou a potvrďte tlačítkem \"Potvrdit a zveřejnit\""; },
  backDesktop: function(d) { return "Zpět na Výběr platby"; },
  backMobile: function(d) { return "Zpět"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	