import React from 'react'
import PropTypes from 'prop-types'
import { NO_RESULTS } from 'app/base/IconsConstants'
import { Button } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'

import './EmptyCS.json'
import './Empty.less'

const CLASSNAME = 'c-catalog-empty'

const Empty = (props) => {
	const { name = '', url = '' } = props

	const localize = useLocalize()

	const renderImage = () => {
		return <img className={`${CLASSNAME}__img`} src={NO_RESULTS} alt='' />
	}

	return (
		<div className={CLASSNAME}>
			{renderImage()}
			<h1 className={`${CLASSNAME}__title`}>{localize('Empty.title')}</h1>
			<div className={`${CLASSNAME}__subtitle`}>{localize('Empty.subTitle')}</div>
			<Button
				className={`${CLASSNAME}__button`}
				primary={true}
				text={localize('Empty.buttonName', {
					NAME: name
				})}
				href={url}
			/>
		</div>
	)
}

Empty.propTypes = {
	name: PropTypes.string,
	url: PropTypes.string
}

export default React.memo(Empty)
