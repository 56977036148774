import { createEntityList } from '@inzeraty/form-lines'
import { Validation } from '@inzeraty/helpers'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'

export default class IASoftwareKeyListController extends AbstractInternalAdminController {
	constructor(
		dependenciesHelper,
		softwareKeyService,
		entitiesFilterExtension,
		entityFormExtension
	) {
		super(dependenciesHelper)

		this._softwareKeyService = softwareKeyService
		this._entitiesFilterExtension = entitiesFilterExtension
		this._entityFormExtension = entityFormExtension

		this._filterInitCallback = this._filterInitCallback.bind(this)
	}

	static get ACTIONS() {
		return Object.freeze({
			OPEN_EDIT_KEY_POPUP: 'openEditKeyPopup',
			CLOSE_EDIT_KEY_POPUP: 'closeEditKeyPopup',
			DELETE: 'delete'
		})
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitCallback)

		this.addExtension(this._entityFormExtension)

		this._entityFormExtension.initialize({
			getSubmitPromise: this._getEditFormSubmitPromise.bind(this),
			validations: this._editFormValidations.bind(this),
			handleSubmitError: this._handleEditFormSubmitError.bind(this),
			handleSubmitSuccess: this._handleEditFormSubmitSuccess.bind(this)
		})
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isEditKeyPopupOpened: false
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const fetchSoftwareKeys = async (params) => {
			try {
				const {
					softwareKeyEntities,
					paginationEntity
				} = await this._softwareKeyService.getSoftwareKeys(params)

				return {
					entities: softwareKeyEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchSoftwareKeys(params)

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	async _filterInitCallback() {
		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'name',
					name: 'Název'
				},
				{
					value: 'value',
					name: 'Hodnota'
				},
				{
					value: 'id',
					name: 'ID'
				}
			]),
			this._entitiesFilterExtension.getSelect('enabled', 'Stav', 'Vyberte stav', [
				{
					value: '1',
					name: 'Aktivní'
				},
				{
					value: '0',
					name: 'Zakázaný'
				}
			])
		])
	}

	async _processSuccessAction(data) {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	_handleAction({ action, entities = [], entitiesIds = [] }) {
		const [entity = {}] = entities
		const { id } = entity

		let errorMessage = ''
		let promise = {}

		switch (action) {
			case IASoftwareKeyListController.ACTIONS.OPEN_EDIT_KEY_POPUP:
				this._entityFormExtension.setFormLineEntitiesData(
					this._getFormLineEntitiesForEditForm(entity)
				)
				this.setState({
					isEditKeyPopupOpened: true,
					entityFormError: null
				})
				return {}

			case IASoftwareKeyListController.ACTIONS.CLOSE_EDIT_KEY_POPUP:
				this.setState({
					isEditKeyPopupOpened: false
				})
				return {}

			case IASoftwareKeyListController.ACTIONS.DELETE:
				if (window.confirm('Opravdu si přejete smazat klíč?')) {
					promise = this._softwareKeyService.deleteSoftwareKey(id)
					errorMessage = 'Nepodařilo se smazat klíč.'
				} else {
					return {}
				}
		}

		return {
			promise,
			getErrorMessage: () => errorMessage
		}
	}

	_getFormLineEntitiesForEditForm(softwareKeyEntity = {}) {
		const isNew = !softwareKeyEntity.id
		const formLineEntities = []

		if (!isNew) {
			formLineEntities.push(
				{
					id: 'id',
					label: 'ID',
					value: softwareKeyEntity.id,
					widget: IDS.TEXT,
					required: true,
					disabled: true
				},
				{
					id: 'value',
					label: 'Hodnota',
					value: softwareKeyEntity.value || '',
					widget: IDS.TEXT,
					required: true,
					disabled: true
				}
			)
		}

		formLineEntities.push(
			{
				id: 'name',
				label: 'Název',
				value: softwareKeyEntity.name || '',
				widget: IDS.TEXT,
				required: true
			},
			{
				id: 'key_type',
				label: 'Typ',
				value: softwareKeyEntity.keyType || 'private',
				widget: IDS.SELECT,
				required: true,
				options: [
					{
						name: 'public',
						value: 'public'
					},
					{
						name: 'private',
						value: 'private'
					}
				]
			},
			{
				id: 'enabled',
				label: 'Stav',
				value: isNew ? true : softwareKeyEntity.enabled,
				widget: IDS.SELECT,
				required: true,
				options: [
					{
						name: 'povolen',
						value: true
					},
					{
						name: 'zakázán',
						value: false
					}
				]
			}
		)

		return formLineEntities
	}

	_editFormValidations() {
		return {
			value: ({ value }) =>
				Validation.isNotEmpty(value) ? '' : 'Zadejte hodnotu softwarového klíče',
			name: ({ value }) => (Validation.isNotEmpty(value) ? '' : 'Zadejte název softwarového klíče')
		}
	}

	_getEditFormSubmitPromise(formData) {
		const { id, ...rest } = formData
		const data = Object.assign({}, rest)
		let promise

		if (id) {
			promise = this._softwareKeyService.updateSoftwareKey(id, data)
		} else {
			promise = this._softwareKeyService.createSoftwareKey(formData)
		}

		return promise
	}

	_handleEditFormSubmitError() {
		return {
			entityFormError: {
				title: 'Nepodařilo se odeslat formulář.'
			}
		}
	}

	_handleEditFormSubmitSuccess() {
		this._processSuccessAction()
		this.setState({
			isEditKeyPopupOpened: false
		})
	}
}
