import * as FormLines from '@inzeraty/form-lines'
import { STATUS_CONSTANTS, DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import { Format } from '@inzeraty/helpers'
import ClientAdvertListUrlConvertor from '../ClientAdvertListUrlConvertor'

import './PremiseFiltersFormLinesCS.json'

export const PREMISE_FORM_LINES_IDS = Object.freeze({
	CATEGORY_SELECT_ID: 'category_select_id',
	ADVERT_STATUS_SELECT_ID: 'advert_status_select_id',
	REGION_SELECT_ID: 'region_select_id',
	BRAND_AUTO_COMPLETE_ID: 'brand_auto_complete_id',
	MODEL_AUTO_COMPLETE_ID: 'model_auto_complete_id',
	CREATE_DATE_FROM_ID: 'create_date_from_id',
	CREATE_DATE_TO_ID: 'create_date_to_id',
	TOPPED_ADVERTS_CHECKBOX_ID: 'topped_adverts_checkbox_id',
	PREFERRED_OFFER_CHECKBOX_ID: 'preferred_offer_checkbox_id',
	DEAL_TYPE_SELECT_ID: 'deal_type_select_id'
})

export const getPremiseFiltersFormLines = (dictionary) => {
	return FormLines.createEntityList([
		{
			widget: PREMISE_FORM_LINES_IDS.CATEGORY_SELECT_ID,
			id: PREMISE_FORM_LINES_IDS.CATEGORY_SELECT_ID,
			label: dictionary.get('PremiseFiltersFormLines.vehicleCategoryLabel'),
			placeholder: dictionary.get('PremiseFiltersFormLines.vehicleCategoryPlaceholder'),
			extra: {
				getUrlAppParamValueFromFormLine: ({ value: { id: categoryId } = {} }) => {
					return categoryId
				},
				getFormLineValueFromUrlAppParam: ({ options = [] }, categoryIdUrlAppParam) => {
					return options.find((o) => o.id === Number(categoryIdUrlAppParam))
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.ADVERT_STATUS_SELECT_ID,
			id: PREMISE_FORM_LINES_IDS.ADVERT_STATUS_SELECT_ID,
			label: dictionary.get('PremiseFiltersFormLines.advertStatusLabel'),
			placeholder: dictionary.get('PremiseFiltersFormLines.advertStatusPlaceholder'),
			options: [
				{
					id: STATUS_CONSTANTS.ACTIVE,
					name: dictionary.get('PremiseFiltersFormLines.advertStatusActive'),
					seoName: ClientAdvertListUrlConvertor.advertStatus.ACTIVE.APP
				},
				{
					id: STATUS_CONSTANTS.INACTIVE,
					name: dictionary.get('PremiseFiltersFormLines.advertStatusInactive'),
					seoName: ClientAdvertListUrlConvertor.advertStatus.INACTIVE.APP
				},
				{
					id: 'BAN',
					name: dictionary.get('PremiseFiltersFormLines.advertStatusBan'),
					seoName: ClientAdvertListUrlConvertor.advertStatus.BAN.APP
				}
			],
			extra: {
				getUrlAppParamValueFromFormLine: ({ value: { seoName: advertStatusSeoName } = {} }) => {
					return advertStatusSeoName
				},
				getFormLineValueFromUrlAppParam: ({ options = [] }, advertStatusUrlAppParam) => {
					return options.find((o) => o.seoName === advertStatusUrlAppParam)
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.DEAL_TYPE_SELECT_ID,
			id: PREMISE_FORM_LINES_IDS.DEAL_TYPE_SELECT_ID,
			label: dictionary.get('PremiseFiltersFormLines.dealTypeLabel'),
			placeholder: dictionary.get('PremiseFiltersFormLines.dealTypePlaceholder'),
			options: [
				{
					id: DEAL_TYPE.SALE,
					name: dictionary.get('PremiseFiltersFormLines.dealTypeSale'),
					seoName: ClientAdvertListUrlConvertor.dealType.SALE.APP
				},
				{
					id: DEAL_TYPE.OPERATING_LEASE,
					name: dictionary.get('PremiseFiltersFormLines.dealTypeOperatingLease'),
					seoName: ClientAdvertListUrlConvertor.dealType.OPERATING_LEASE.APP
				}
			],
			extra: {
				getUrlAppParamValueFromFormLine: ({ value: { seoName: dealTypeSeoName } = {} }) => {
					return dealTypeSeoName
				},
				getFormLineValueFromUrlAppParam: ({ options = [] }, dealTypeUrlAppParam) => {
					const saleOptionAsDefault = options.find((o) => o.id === DEAL_TYPE.SALE)

					// pokud neni nic v URL nebo nevalidni hodnota, tak nastavime typ 'Prodej' jako vychozi volbu
					return options.find((o) => o.seoName === dealTypeUrlAppParam) || saleOptionAsDefault
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.REGION_SELECT_ID,
			id: PREMISE_FORM_LINES_IDS.REGION_SELECT_ID,
			label: dictionary.get('PremiseFiltersFormLines.regionLabel'),
			placeholder: dictionary.get('PremiseFiltersFormLines.regionPlaceholder'),
			extra: {
				getUrlAppParamValueFromFormLine: ({ value: { id: regionId } = {} }) => {
					return regionId
				},
				getFormLineValueFromUrlAppParam: ({ options = [] }, regionIdUrlAppParam) => {
					return options.find((o) => o.id === Number(regionIdUrlAppParam))
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID,
			id: PREMISE_FORM_LINES_IDS.BRAND_AUTO_COMPLETE_ID,
			label: dictionary.get('PremiseFiltersFormLines.brandsLabel'),
			placeholder: dictionary.get('PremiseFiltersFormLines.brandsPlaceholder'),
			extra: {
				getUrlAppParamValueFromFormLine: ({ value: { value: brandValue } = {} }) => {
					return brandValue
				},
				getFormLineValueFromUrlAppParam: ({ options = [] }, brandUrlAppParam) => {
					return options.find((o) => o.value === Number(brandUrlAppParam))
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.MODEL_AUTO_COMPLETE_ID,
			id: PREMISE_FORM_LINES_IDS.MODEL_AUTO_COMPLETE_ID,
			label: dictionary.get('PremiseFiltersFormLines.modelsLabel'),
			placeholder: dictionary.get('PremiseFiltersFormLines.modelsPlaceholder'),
			extra: {
				getUrlAppParamValueFromFormLine: ({ value: { value: modelValue } = {} }) => {
					return modelValue
				},
				getFormLineValueFromUrlAppParam: ({ options = [] }, modelUrlAppParam) => {
					return options.find((o) => o.value === Number(modelUrlAppParam))
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.CREATE_DATE_FROM_ID,
			id: PREMISE_FORM_LINES_IDS.CREATE_DATE_FROM_ID,
			label: dictionary.get('PremiseFiltersFormLines.createDateFromLabel'),
			placeholder: dictionary.get('PremiseFiltersFormLines.createDateFromPlaceholder'),
			extra: {
				getUrlAppParamValueFromFormLine: ({ value }) => {
					return Format.dateEN(value, true) || null
				},
				getFormLineValueFromUrlAppParam: (formLineEntity, createDateFromUrlAppParam) => {
					return Format.dateEN(createDateFromUrlAppParam, true)
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.CREATE_DATE_TO_ID,
			id: PREMISE_FORM_LINES_IDS.CREATE_DATE_TO_ID,
			label: dictionary.get('PremiseFiltersFormLines.createDateToLabel'),
			placeholder: dictionary.get('PremiseFiltersFormLines.createDateToPlaceholder'),
			extra: {
				getUrlAppParamValueFromFormLine: ({ value }) => {
					return Format.dateEN(value, true) || null
				},
				getFormLineValueFromUrlAppParam: (formLineEntity, createDateToUrlAppParam) => {
					return Format.dateEN(createDateToUrlAppParam, true)
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.TOPPED_ADVERTS_CHECKBOX_ID,
			id: PREMISE_FORM_LINES_IDS.TOPPED_ADVERTS_CHECKBOX_ID,
			label: dictionary.get('PremiseFiltersFormLines.toppedAdverts'),
			extra: {
				getUrlAppParamValueFromFormLine: ({ value }) => {
					// false se nesmi dostat do URL (pokud neni zaskrtnut checkbox,
					// tak chceme zobrazovat vsechno)
					return value ? true : undefined
				},
				getFormLineValueFromUrlAppParam: (formLineEntity, toppedAdvertUrlAppParam) => {
					return !!toppedAdvertUrlAppParam
				}
			}
		},
		{
			widget: PREMISE_FORM_LINES_IDS.PREFERRED_OFFER_CHECKBOX_ID,
			id: PREMISE_FORM_LINES_IDS.PREFERRED_OFFER_CHECKBOX_ID,
			label: dictionary.get('PremiseFiltersFormLines.preferredOffer'),
			extra: {
				getUrlAppParamValueFromFormLine: ({ value }) => {
					// false se nesmi dostat do URL (pokud neni zaskrtnut checkbox,
					// tak chceme zobrazovat vsechno)
					return value ? true : undefined
				},
				getFormLineValueFromUrlAppParam: (formLineEntity, preferredOfferUrlAppParam) => {
					return !!preferredOfferUrlAppParam
				}
			}
		}
	])
}
