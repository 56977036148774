import React, { useState, useRef, useEffect } from 'react'
import { Icon } from '@sznds/react'
import { INFO_OUTLINE_24 } from '@sznds/icons'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import PropTypes from 'prop-types'

import './VinInfoView.less'
import './VinInfoCS.json'

const CLASSNAME = 'c-vin-info'

const VinInfoView = ({ advertEntity, localize }) => {
	const { vin, hideVin } = advertEntity

	const [showInfo, setShowInfo] = useState(false)
	const infoText = useRef()
	useEffect(() => infoText.current && infoText.current.focus(), [showInfo])

	/**
	 * Skryty VIN muze mit bud' 6 skrytych znaku na konci, nebo 8 uprostred.
	 */
	const getHiddenVin = () => (/X{6}$/.test(vin) ? getHiddenVinEnd() : getHiddenVinMiddle())

	const getHiddenVinEnd = () => (
		<React.Fragment>
			{vin.substring(0, 11)}
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
			<span
				className={`${CLASSNAME}__hidden-vin`}
				onClick={() => setShowInfo(true)}
				tabIndex='-1'
				role='button'
				data-dot='show-more'
				data-dot-data='{"type": "hidden-vin"}'
			>
				{localize('VinInfo.sixHiddenCharacters')}
			</span>
			{!showInfo && <InfoIcon />}
		</React.Fragment>
	)

	const getHiddenVinMiddle = () => (
		<React.Fragment>
			{vin.substring(0, 6)}
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
			<span
				className={`${CLASSNAME}__hidden-vin`}
				onClick={() => setShowInfo(true)}
				tabIndex='-1'
				role='button'
				data-dot='show-more'
				data-dot-data='{"type": "hidden-vin"}'
			>
				{localize('VinInfo.eightHiddenCharacters')}
			</span>
			{vin.substring(14)}
			{!showInfo && <InfoIcon />}
		</React.Fragment>
	)

	const InfoIcon = () => (
		<button
			className={`${CLASSNAME}__info-button`}
			onClick={() => setShowInfo(true)}
			aria-label={localize('VinInfo.showInfo')}
			aria-expanded={false}
			data-dot='show-more'
			data-dot-data='{"type": "hidden-vin"}'
		>
			<Icon symbol={INFO_OUTLINE_24} className={`${CLASSNAME}__info-icon`} />
		</button>
	)

	const VinNumber = () => (
		<div className={`${CLASSNAME}__vin`}>{hideVin ? getHiddenVin() : vin}</div>
	)

	const HiddenVinNote = () => (
		<div
			className={`${CLASSNAME}__hidden-vin-note`}
			ref={infoText}
			tabIndex='-1'
			aria-label={localize('VinInfo.textLoaded')}
		>
			{localize('VinInfo.hiddenVin')}
		</div>
	)

	const AccessibleNote = () => (
		<span className={`${CLASSNAME}__accessible-vin-note`}>{localize('VinInfo.hiddenVin')}</span>
	)

	return (
		<React.Fragment>
			<VinNumber />
			{showInfo && <HiddenVinNote />}
			{showInfo && <AccessibleNote />}
		</React.Fragment>
	)
}

VinInfoView.propTypes = {
	localize: PropTypes.func,
	advertEntity: PropTypes.shape(advertPropTypes)
}

export default React.memo(VinInfoView)
