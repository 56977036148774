import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { Format } from '@inzeraty/helpers'
import { Surface } from '@sznds/react'
import { Loading } from '@inzeraty/components'

import './CountView.less'
import './CountCS.json'

const CLASSNAME = 'cf-count'

export default class CountView extends AbstractPureComponent {
	static get propTypes() {
		return {
			count: PropTypes.number,
			isCountShownWithAddText: PropTypes.bool,
			isCountLoading: PropTypes.bool,
			isSelected: PropTypes.bool,
			className: PropTypes.string,
			isHpFilterCount: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			count: 0,
			isCountShownWithAddText: false,
			isCountLoading: false,
			isSelected: false,
			className: '',
			isHpFilterCount: true
		}
	}

	render() {
		const {
			count,
			isCountShownWithAddText,
			isSelected,
			isCountLoading,
			className,
			isHpFilterCount
		} = this.props

		return (
			<Surface
				tagName='span'
				surface={isSelected && !isCountLoading ? 4 : 0}
				className={this.cssClasses({
					[CLASSNAME]: true,
					[`${CLASSNAME}--selected`]: isSelected && !isCountLoading,
					[className]: true,
					[`${CLASSNAME}--compact`]: !isHpFilterCount
				})}
			>
				{isCountLoading ? (
					<Loading
						className={`${CLASSNAME}__loading-dots`}
						loadingType={Loading.loadingType.ONLY_DOT}
					/>
				) : (
					<React.Fragment>
						{this._renderCount()}
						{isSelected && isCountShownWithAddText
							? ` ${this.localize('Count.cars', { COUNT: count })}`
							: ''}
					</React.Fragment>
				)}
			</Surface>
		)
	}

	_renderCount() {
		const { count, isHpFilterCount, isCountShownWithAddText } = this.props

		if (!isHpFilterCount && !isCountShownWithAddText) {
			return <span>({Format.number(count)})</span>
		} else {
			return Format.number(count)
		}
	}
}
