import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import RouteNames from 'app/base/RouteNames'
import select from 'ima-plugin-select'
import STATE_KEYS from 'app/page/userweb/advertDetail/AdvertDetailStateKeys'

import './AdvertTitle.less'

const CLASSNAME = 'c-item-title'

class AdvertTitle extends AbstractComponent {
	static get propTypes() {
		return {
			advertEntity: PropTypes.shape(advertPropTypes)
		}
	}

	static get defaultProps() {
		return {
			advertEntity: DEFAULT_PROPS.OBJECT
		}
	}

	render() {
		const { nameSuffix } = this.props.advertEntity

		return (
			<h1 className={`${CLASSNAME}`}>
				{this._renderNamePrefix()}
				{nameSuffix && <span className={`${CLASSNAME}--suffix`}>{` ${nameSuffix}`}</span>}
			</h1>
		)
	}

	_renderNamePrefix() {
		const { advertEntity } = this.props
		const {
			namePrefix = '',
			category: { seoName: categorySeoName } = {},
			manufacturerCb: { name: brandName, seoName: brandSeoName } = {},
			modelCb: { name: modelName, seoName: modelSeoName } = {}
		} = advertEntity
		const { URL_APP_PARAMS } = AdvertListUrlConvertor.constants

		const brandLink =
			categorySeoName && brandSeoName
				? this.link(RouteNames.USERWEB.ADVERT_LIST, {
						[URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName,
						[URL_APP_PARAMS.BRAND_NAME]: brandSeoName
				  })
				: null

		const modelLink =
			categorySeoName && brandSeoName && modelSeoName
				? this.link(RouteNames.USERWEB.ADVERT_LIST, {
						[URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName,
						[URL_APP_PARAMS.BRAND_NAME]: brandSeoName,
						[URL_APP_PARAMS.MODEL_NAME]: modelSeoName
				  })
				: null

		const renderLink = (text, link, ariaLabel) =>
			link ? (
				<a
					className={`${CLASSNAME}__link`}
					href={link}
					onClick={() => this.fire('resetCache')}
					aria-labelledby={ariaLabel}
				>
					{text}
				</a>
			) : (
				text
			)

		const space = ' '
		const separator = ', '
		const shouldRenderModelName = namePrefix.includes(modelName) && modelSeoName !== 'ostatni'

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__name-prefix`]: true,
					[`${CLASSNAME}__name-prefix--placeholder`]: !brandName
				})}
			>
				{renderLink(brandName, brandLink, brandName)}
				{shouldRenderModelName && space}
				{shouldRenderModelName && renderLink(modelName, modelLink, `${brandName} ${modelName}`)}
				{namePrefix.endsWith(separator) && separator}
			</div>
		)
	}
}

const AdvertTitleSelector = (state) => ({
	advertEntity: state[STATE_KEYS.ADVERT_ENTITY]
})

export default select(AdvertTitleSelector)(AdvertTitle)
