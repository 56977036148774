import React, { useContext } from 'react'
import ImaContext from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import Layout from 'app/component/layout/LayoutView'
import ModelsCatalogController from './ModelsCatalogController'
import ModelsList from './components/modelsList/ModelsList'
import PathSeparator, { getHomePath } from '../components/pathSeparator/PathSeparator'

import './ModelsCatalogView.less'

const CLASSNAME = 'p-uw-catalog-models'

const ModelsCatalogView = (props) => {
	const { [ModelsCatalogController.STATE_KEYS.MODELS_LIST]: modelsList } = props

	const context = useContext(ImaContext)
	const router = context.$Router
	const localize = useLocalize()

	const dataLoaded = !!(modelsList && modelsList.length)

	const getPaths = () => {
		if (dataLoaded) {
			const name = getBrandName()

			return [
				getHomePath(localize, router),
				{
					name
				}
			]
		} else {
			return []
		}
	}

	const getBrandName = () => {
		const { manufacturer = {} } = modelsList ? modelsList[0] || {} : {}
		const { name = '' } = manufacturer

		return name
	}

	const renderTitle = () => {
		if (dataLoaded) {
			const name = getBrandName()

			return <h1 className={`${CLASSNAME}__header`}>{name}</h1>
		} else {
			return <div className={`${CLASSNAME}__header-placeholder`}></div>
		}
	}

	const paths = getPaths()

	return (
		<Layout className={CLASSNAME}>
			<div className={`${CLASSNAME}__content`} data-dot='catalog'>
				<div className={`${CLASSNAME}__header-content`}>
					<PathSeparator paths={paths} dataLoaded={dataLoaded} />
					{renderTitle()}
				</div>
				<ModelsList modelsList={modelsList} />
			</div>
		</Layout>
	)
}

export default ModelsCatalogView
