import { CATEGORIES } from 'app/base/Constants'
import ROUTE_NAMES from 'app/base/RouteNames'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

export const getSteps = (categoryId, advertId, router) => {
	const { route } = router.getCurrentRouteInfo()
	const currentRouteName = route.getName()

	const { ADVERT_ID: ADVERT_ID_URL_APP_PARAM } = InternalAdminUrlConvertor.constants

	const steps = [
		{
			routeName: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO,
			url: router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO, {
				[ADVERT_ID_URL_APP_PARAM]: advertId
			}),
			text: 'Základní údaje',
			text4: 'Základní údaje',
			actual: currentRouteName === ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO
		},
		{
			routeName: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_PHOTOS,
			url: router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_PHOTOS, {
				[ADVERT_ID_URL_APP_PARAM]: advertId
			}),
			text: 'Fotogalerie',
			text4: 'Fotogalerii',
			actual: currentRouteName === ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_PHOTOS
		},
		{
			routeName: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_HISTORY,
			url: router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_HISTORY, {
				[ADVERT_ID_URL_APP_PARAM]: advertId
			}),
			text: 'Historie',
			text4: 'Historii',
			actual: currentRouteName === ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_HISTORY
		},
		{
			routeName: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_EQUIPMENT,
			url: router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_EQUIPMENT, {
				[ADVERT_ID_URL_APP_PARAM]: advertId
			}),
			text: 'Výbava',
			text4: 'Výbavu',
			actual: currentRouteName === ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_EQUIPMENT,
			skip: categoryId === CATEGORIES.WORKING_MACHINES.id // kategorie 'Pracovni stroje' nema vybavu
		}
	]

	return steps
}
