import React, { useContext } from 'react'
import classnames from 'classnames'
import select from 'ima-plugin-select'
import { Button } from '@sznds/react'
import { VISIBILITY_FILLED } from '@sznds/icons'
import Context from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import UserwebAdvert from 'app/component/adverts/UserwebAdvertView'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../../../AbstractNewAdvertStateKeys'
import PropTypes from 'prop-types'
import AdvertPropTypes from 'app/model/advert/AdvertPropTypes'

import './AdvertPreview.less'
import 'app/component/adverts/UserwebAdvertCS.json'

const CLASSNAME = 'c-item-preview'

const AdvertPreview = ({ className, advertEntity, showCard }) => {
	const context = useContext(Context)
	const localize = useLocalize()

	const router = context.$Router

	const [routeName, routeParams] = UserwebAdvert.getAdvertUrlParams(advertEntity)

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			{showCard ? (
				<div className={`${CLASSNAME}__card`}>
					<UserwebAdvert lookType={UserwebAdvert.getLookType.PREVIEW} advertEntity={advertEntity} />
				</div>
			) : (
				<Button
					className={`${CLASSNAME}__button`}
					text={localize('UserwebAdvert.showPreview')}
					icon={VISIBILITY_FILLED}
					disabled={!advertEntity}
					href={router.link(
						routeName,
						Object.assign({}, routeParams, {
							[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.PREVIEW]:
								AdvertDetailUrlConvertor.constants.URL_APP_PARAMS_VALUES.PREVIEW
						})
					)}
				/>
			)}
		</div>
	)
}

const selector = (state) => ({
	advertEntity: state[ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]
})

AdvertPreview.propTypes = {
	className: PropTypes.string,
	advertEntity: PropTypes.shape(AdvertPropTypes),
	showCard: PropTypes.bool
}

export default React.memo(select(selector)(AdvertPreview))
