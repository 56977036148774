import React, { memo } from 'react'
import { Button } from '@sznds/react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './ActionButton.less'

const CLASSNAME = 'c-action-btn'

const ActionButton = memo(
	({ icon, title, href, onClick, isCompact = true, className = '', ...restProps }) => {
		return isCompact ? (
			<span
				className={classnames({
					[CLASSNAME]: true,
					[className]: !!className
				})}
				data-title={title}
			>
				<Button size='x-small' icon={icon} href={href} onClick={onClick} {...restProps} />
			</span>
		) : (
			<div className={`${CLASSNAME}--full`}>
				<Button
					size='x-small'
					text={title}
					icon={icon}
					href={href}
					onClick={onClick}
					{...restProps}
				/>
			</div>
		)
	}
)

ActionButton.propTypes = {
	icon: PropTypes.object,
	title: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func,
	isCompact: PropTypes.bool,
	className: PropTypes.string
}

export default ActionButton
