import SellerCard from 'app/component/sellerCard/SellerCard'
import * as FormLines from '@inzeraty/form-lines'
import ROUTE_NAMES from 'app/base/RouteNames'
import FilterToUrlAppConvertor from 'app/model/filter/filterConvertor/FilterToUrlAppConvertor'
import { getSearchTypeSessionOnAdvertDetail } from 'app/helpers/sessionStorageHelpers/getSearchTypeSessionHelper'

export default class AnalyticsDataSender {
	constructor(dotAnalytic, sessionStorage) {
		this._dotAnalytic = dotAnalytic
		this._sessionStorage = sessionStorage
	}

	hitForShowPhoneNumber(advertEntity, routeName) {
		this.hitForPhoneNumber(advertEntity, routeName)
	}

	getAdvertDataDotData(advertEntity) {
		const {
			id: advertId,
			oldId,
			customId,
			dealType = '',
			premise: premiseEntity = {},
			user = {},
			category,
			manufacturerCb,
			modelCb,
			locality,
			source = '',
			price = '',
			operatingLeasePriceWithoutVat = ''
		} = advertEntity

		if (advertId) {
			const { id: premise_id = null, externalId: external_id } = premiseEntity
			const { id: user_id = null } = user
			const { id: category_id } = category
			const { value: manufacturer_id } = manufacturerCb
			const { value: model_id } = modelCb
			const { regionId: region_id, districtId: district_id } = locality

			return {
				advertId,
				oldId,
				deal_type: dealType,
				custom_id: customId || null,
				premise_id,
				external_id,
				user_id,
				category_id,
				manufacturer_id,
				model_id,
				region_id,
				district_id,
				source,
				price,
				operating_lease_price_without_vat: operatingLeasePriceWithoutVat
			}
		} else {
			return {}
		}
	}

	hitForPhoneNumber(advertEntity, routeName) {
		const { sellerId, sellerName } = this._getData(advertEntity)

		this._dotAnalytic.setDefaultHitData({})
		this._dotAnalytic.hit(
			Object.assign(
				{
					action: 'reply',
					replyType: 'phone',
					customerId: sellerId,
					customerName: sellerName,
					isOperatingLeasePage: this._isOperatingLeasePage(routeName)
				},
				this.getAdvertDataDotData(advertEntity),
				getSearchTypeSessionOnAdvertDetail(this._sessionStorage)
			)
		)
	}

	hitForShowEmail(advertEntity, routeName) {
		const { sellerId, sellerName } = this._getData(advertEntity)

		this._dotAnalytic.setDefaultHitData({})
		this._dotAnalytic.hit(
			Object.assign(
				{
					action: 'reply',
					replyType: 'e-mail',
					customerId: sellerId,
					customerName: sellerName,
					isOperatingLeasePage: this._isOperatingLeasePage(routeName)
				},
				this.getAdvertDataDotData(advertEntity),
				getSearchTypeSessionOnAdvertDetail(this._sessionStorage)
			)
		)
	}

	hitForDemandForm(advertEntity, routeName) {
		const { sellerId, sellerName } = this._getData(advertEntity)

		this._dotAnalytic.setDefaultHitData({})
		this._dotAnalytic.hit(
			Object.assign(
				{
					action: 'reply',
					replyType: 'form',
					customerId: sellerId,
					customerName: sellerName,
					isOperatingLeasePage: this._isOperatingLeasePage(routeName)
				},
				this.getAdvertDataDotData(advertEntity),
				getSearchTypeSessionOnAdvertDetail(this._sessionStorage)
			)
		)
	}

	_getData(advertEntity) {
		const { user, premise } = advertEntity

		if (premise) {
			//bazar
			return {
				sellerId: premise.id,
				sellerName: premise.name
			}
		} else if (user) {
			//soukromnik
			return {
				sellerId: user.id,
				sellerName: 'soukromý prodejce'
			}
		} else {
			return {
				sellerId: '',
				sellerName: ''
			}
		}
	}

	hitContactSeller(data = {}) {
		const analyticLocation = {
			[SellerCard.LOCATION_ON_PAGE.STICKY_TOP]: 'StickyTop',
			[SellerCard.LOCATION_ON_PAGE.STICKY_BOTTOM]: 'StickyBottom',
			[SellerCard.LOCATION_ON_PAGE.STATIC]: 'SellerCard'
		}

		const { page: routeName } = data

		const hitData = Object.assign({}, data, {
			location:
				analyticLocation[data.location] || analyticLocation[SellerCard.LOCATION_ON_PAGE.STATIC],
			page: this._operatingLeasePageToRegularPage(routeName)
		})

		this._dotAnalytic.setDefaultHitData({})
		this._dotAnalytic.hit(
			Object.assign(
				{
					action: 'ContactSeller',
					isOperatingLeasePage: this._isOperatingLeasePage(routeName)
				},
				hitData,
				getSearchTypeSessionOnAdvertDetail(this._sessionStorage)
			)
		)
	}

	hitForVisibleImpress(data = {}) {
		const { page: routeName } = data

		this._dotAnalytic.setDefaultHitData({})
		this._dotAnalytic.hit(
			Object.assign(
				{
					action: 'visibleImpress',
					isOperatingLeasePage: this._isOperatingLeasePage(routeName)
				},
				data,
				{
					page: this._operatingLeasePageToRegularPage(routeName)
				}
			)
		)
	}

	hitFilterChange(formLineEntity, newValue, place, routeName) {
		const { options = [] } = formLineEntity
		const oldParams = this._getFilterUrlParams(formLineEntity)
		const newFormLineEntity = FormLines.updateEntity(formLineEntity, { value: newValue })
		const newParams = this._getFilterUrlParams(newFormLineEntity)

		// - promazu si filtry, ktere se nemenily, protoze se mi vraci i defaultni params
		const allKeys = Object.keys(Object.assign({}, oldParams, newParams))
		allKeys.forEach((key) => {
			if (newParams[key] === oldParams[key]) {
				delete newParams[key]
				delete oldParams[key]
			}
		})

		const newParamsKeys = Object.keys(newParams)
		const oldParamsKeys = Object.keys(oldParams)

		const filterName = newParamsKeys[0] ? newParamsKeys[0] : oldParamsKeys[0]
		const filterValue = Object.values(newParams)[0] || ''
		const isPresetUsed = this._getIsPresetUsed(newValue, options)

		const hitData = {
			filterName,
			filterValue,
			place,
			pageType: routeName,
			actionType: this._getFilterActionType(newParams, oldParams, isPresetUsed)
		}

		this._dotAnalytic.setDefaultHitData({})
		this._dotAnalytic.hit(
			Object.assign(
				{
					action: 'Filter',
					isOperatingLeasePage: this._isOperatingLeasePage(routeName)
				},
				hitData
			)
		)
	}

	_getFilterUrlParams(formLineEntity) {
		return FilterToUrlAppConvertor.getFilterAdvertListUrlParams([formLineEntity], true)
	}

	_getIsPresetUsed(newValue, options) {
		const { valueFrom, valueTo } = newValue

		if (options.length && (valueFrom || valueTo)) {
			const preset = options.find((option) => {
				const { valueFrom: optValueFrom, valueTo: optValueTo } = option
				return optValueFrom === valueFrom && optValueTo === valueTo
			})

			return !!preset
		}

		return false
	}

	_getFilterActionType(newParams, oldParams, isPresetUsed) {
		const newValues = Object.values(newParams)
		const oldValues = Object.values(oldParams)

		if (isPresetUsed) {
			return 'filter-select-preset'
		} else if (!newValues.length) {
			return 'filter-unselect'
		} else if (!oldValues.length) {
			return 'filter-select'
		} else {
			return 'filter-change'
		}
	}

	hitCreateAdvert() {
		this._dotAnalytic.setDefaultHitData({})
		this._dotAnalytic.hit({
			action: 'inzerat_vlozen'
		})
	}

	_isOperatingLeasePage(routeName = '') {
		return [
			ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES,
			ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL,
			ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
		].includes(routeName)
	}

	_operatingLeasePageToRegularPage(routeName = '') {
		switch (routeName) {
			case ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES:
				return ROUTE_NAMES.USERWEB.ADVERT_LIST
			case ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL:
				return ROUTE_NAMES.USERWEB.ADVERT_DETAIL
			case ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES:
				return ROUTE_NAMES.USERWEB.SELLER
			default:
				return routeName
		}
	}
}
