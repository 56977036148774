import { createEntityList, updateEntities } from '@inzeraty/form-lines'
import { HttpErrorHelper, HttpStatusCodes, Validation } from '@inzeraty/helpers'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { nonValue, nonValueOption } from 'app/component/entitiesFilter/EntitiesFilter'

export default class IAAdminListController extends AbstractInternalAdminController {
	static get ACTIONS() {
		return Object.freeze({
			ACTIVATE: 'activate',
			DEACTIVATE: 'deactivate',
			DELETE: 'delete',
			OPEN_EDIT_ADMIN_POPUP: 'openEditAdminPopup',
			CLOSE_EDIT_ADMIN_POPUP: 'closeEditAdminPopup'
		})
	}

	constructor(dependenciesHelper, entitiesFilterExtension, roleService, entityFormExtension) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._roleService = roleService
		this._entityFormExtension = entityFormExtension

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)

		this.addExtension(this._entityFormExtension)

		this._entityFormExtension.initialize({
			getSubmitPromise: this._getEditFormSubmitPromise.bind(this),
			validations: this._editFormValidations.bind(this),
			handleSubmitSuccess: this._handleEditFormSubmitSuccess.bind(this),
			handleSubmitError: this._handleEditFormSubmitError.bind(this)
		})
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isEditAdminPopupOpened: false
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const fetchAdmins = async (params) => {
			try {
				const { adminEntities, paginationEntity } = await this._adminService.getAdmins(params)

				return {
					entities: adminEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		this._pageLoaderExtension.show()

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)
		const entitiesAndPagination = await fetchAdmins(params)

		this.setState({
			isLoading: false
		})

		this._pageLoaderExtension.hide()

		return entitiesAndPagination
	}

	async _handleAction({ action, entities = [], entitiesIds = [] }) {
		const [entity = {}] = entities
		const { id, login } = entity

		let promise
		let errorMessage

		switch (action) {
			case IAAdminListController.ACTIONS.ACTIVATE:
				promise = this._adminService.patchAdmin(id, { enabled: true })
				errorMessage = `Nepodařilo se aktivovat admina ${login}`
				break

			case IAAdminListController.ACTIONS.DEACTIVATE:
				promise = this._adminService.patchAdmin(id, { enabled: false })
				errorMessage = `Nepodařilo se deaktivovat admina ${login}`
				break

			case IAAdminListController.ACTIONS.DELETE:
				if (window.confirm(`Opravdu si přejete smazat admina ${login}`)) {
					promise = this._adminService.deleteAdmin(id)
					errorMessage = `Nepodařilo se smazat admina ${login}`
				} else {
					return {}
				}
				break

			case IAAdminListController.ACTIONS.OPEN_EDIT_ADMIN_POPUP:
				this._entityFormExtension.setFormLineEntitiesData(
					await this._getFormLineEntitiesForEditForm(entity)
				)
				this.setState({
					isEditAdminPopupOpened: true,
					entityFormError: null
				})
				return {}

			case IAAdminListController.ACTIONS.CLOSE_EDIT_ADMIN_POPUP:
				this.setState({
					isEditAdminPopupOpened: false
				})
				return {}
		}

		return {
			promise,
			getErrorMessage: () => errorMessage
		}
	}

	async _processSuccessAction(data) {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	async _filterInitiezerClb(routeParams) {
		const { roleEntities = [] } = await this._roleService.getRoles()

		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'login',
					name: 'Login'
				},
				{
					value: 'name',
					name: 'Jméno'
				},
				{
					value: 'id',
					name: 'ID admina'
				},
				{
					value: 'email',
					name: 'Email'
				}
			]),
			this._entitiesFilterExtension.getSelect('enabled', 'Stav', 'Vyberte stav', [
				{
					value: '1',
					name: 'Povolený'
				},
				{
					value: '0',
					name: 'Zakázaný'
				}
			]),
			this._entitiesFilterExtension.getSelect(
				InternalAdminUrlConvertor.constants.ROLE_ID,
				'Role',
				'Vyberte roli',
				roleEntities.map(({ id, name }) => ({
					value: id,
					name
				}))
			)
		])
	}

	async _getFormLineEntitiesForEditForm(adminEntity = {}) {
		const isNew = !adminEntity.id
		const formLineEntities = []

		this._pageLoaderExtension.show()

		const [{ roleEntities }, { adminEntities }] = await Promise.all([
			this._roleService.getRoles(),
			this._adminService.getAdmins({ limit: 1000 })
		])

		this._pageLoaderExtension.hide()

		if (!isNew) {
			formLineEntities.push({
				id: 'id',
				label: 'ID',
				value: adminEntity.id,
				widget: IDS.TEXT,
				required: true,
				disabled: true
			})
		}

		formLineEntities.push({
			id: 'login',
			label: 'Login',
			value: adminEntity.login,
			widget: IDS.TEXT,
			required: true,
			disabled: !isNew
		})

		if (!isNew) {
			formLineEntities.push({
				id: 'name',
				label: 'Jméno',
				value: adminEntity.name,
				widget: IDS.TEXT,
				required: true
			})
		}

		formLineEntities.push({
			id: InternalAdminUrlConvertor.constants.ROLE_ID,
			label: 'Role',
			value: adminEntity.roleId || roleEntities[0].id,
			widget: IDS.SELECT,
			required: true,
			options: roleEntities.map(({ id, name }) => ({
				value: id,
				name
			}))
		})

		formLineEntities.push({
			id: 'superior_id',
			label: 'Nadřízený',
			value: adminEntity.superiorId || nonValue,
			widget: IDS.SELECT,
			options: [
				nonValueOption,
				...adminEntities.map(({ id, name }) => ({
					value: id,
					name
				}))
			]
		})

		if (!isNew) {
			formLineEntities.push({
				id: 'email',
				label: 'Email',
				value: adminEntity.email,
				widget: IDS.TEXT,
				disabled: true
			})

			formLineEntities.push({
				id: 'phone',
				label: 'Telefon',
				value: adminEntity.phone,
				widget: IDS.TEXT
			})
		}

		return formLineEntities
	}

	_editFormValidations() {
		return {
			login: ({ value }) => (Validation.isNotEmpty(value) ? '' : 'Zadejte login'),
			name: ({ value }) => (Validation.isNotEmpty(value) ? '' : 'Zadejte jméno'),
			phone: ({ value }) =>
				Validation.isNotEmpty(value) && !Validation.checkPhone(value)
					? 'Zadejte validní telefonní číslo'
					: ''
		}
	}

	_getEditFormSubmitPromise(formData) {
		const { id, ...data } = formData
		let promise

		if (id) {
			promise = this._adminService.updateAdmin(id, data)
		} else {
			promise = this._adminService.createAdmin(formData)
		}

		return promise
	}

	_handleEditFormSubmitError(error, formLineEntities, formData) {
		this._pageLoaderExtension.hide()

		let updatedFormLineEntities = formLineEntities
		const status = HttpErrorHelper.getHttpStatus(error)

		if (status === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(error)
			const { errors = [] } = body

			errors.forEach(({ error_code }) => {
				if (error_code === 'login_not_exists') {
					updatedFormLineEntities = updateEntities(formLineEntities, [
						{
							id: 'login',
							errorMessage: `Login '${formData.login}' nebyl nalezen v Neznámu.`
						}
					])
				} else if (error_code === 'admin_already_exists') {
					updatedFormLineEntities = updateEntities(formLineEntities, [
						{
							id: 'login',
							errorMessage: `Admin s loginem '${formData.login}' již existuje.`
						}
					])
				}
			})
		}

		return {
			formLineEntities: updatedFormLineEntities,
			entityFormError: {
				title: 'Nepodařilo se odeslat formulář'
			}
		}
	}

	_handleEditFormSubmitSuccess() {
		this._pageLoaderExtension.hide()

		this._processSuccessAction()
		this.setState({
			isEditAdminPopupOpened: false
		})
	}
}
