import React, { useContext } from 'react'
import ImaContext from 'ima/page/context'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import { Tabs } from '@inzeraty/components'
import { RESPONSIVE } from 'app/base/Constants'
import IMAGES from 'app/base/ImagesConstants'
import { useLocalize, useLink } from 'app/base/componentHelpers'
import { Surface, Button } from '@sznds/react'
import { getSource, getImageSDNFilter } from 'app/component/garazArticles/articlesHelper'
import Paging from 'app/component/paging/PagingView'
import ROUTE_NAMES from 'app/base/RouteNames'
import ArticlesUrlConvertor from '../../ArticlesUrlConvertor'
import AutoMotoArticlesPlaceholders from '../placeholders/AutoMotoArticlesPlaceholders'
import PropTypes from 'prop-types'
import { ArticleEntity, PaginationEntity } from '@inzeraty/models'

const CLASSNAME = 'c-auto-moto-articles'

import 'app/base/BaseCS.json'
import './AutoMotoArticlesCS.json'
import './AutoMotoArticles.less'

const AutoMotoArticles = (props) => {
	const { articles = DEFAULT_PROPS.OBJECT } = props
	const { articlesList, paginationEntity: pagination = {} } = articles

	const link = useLink()
	const localize = useLocalize()
	const context = useContext(ImaContext)
	const router = context.$Router

	const renderImage = (imageUrl, extraData, alt = '') => {
		if (imageUrl) {
			const sizes = `
                    (min-width: ${RESPONSIVE.DESKTOP}px) 299px,
                    (min-width: ${RESPONSIVE.MOBILE_WIDE}px) calc((100vw - 24px - 16px - 24px) / 2),
                    calc(100vw - 2 * 8px)
                `

			return (
				<picture>
					{getSource(imageUrl, 'webp', extraData, sizes)}
					{getSource(imageUrl, 'jpg', extraData, sizes)}
					<img
						src={imageUrl + getImageSDNFilter(extraData, 'jpg', 600)}
						className={`${CLASSNAME}__img`}
						alt={alt}
					/>
				</picture>
			)
		} else {
			return <img src={IMAGES['image__garaz-placeholder']} className={`${CLASSNAME}__img`} alt='' />
		}
	}

	const renderPerex = (perex) => {
		if (perex) {
			const MAX_CHARS = 200
			const isPerexLong = perex.length > MAX_CHARS
			let per

			if (isPerexLong) {
				per = perex.substring(0, MAX_CHARS)
				per += '...'
			} else {
				per = perex
			}

			return per
		} else {
			return null
		}
	}

	const renderArticle = (article) => {
		const { title = '', imageUrl, url, extraData, created, source, perex } = article

		return (
			<div key={url} className={`${CLASSNAME}__article-wrapper`}>
				<Surface
					surface={5}
					href={url}
					tagName='a'
					rel='noopener noreferrer'
					target='_blank'
					className={`${CLASSNAME}__article`}
				>
					<div className={`${CLASSNAME}__img-wrap`}>{renderImage(imageUrl, extraData, title)}</div>
					<div className={`${CLASSNAME}__article-content`}>
						<div className={`${CLASSNAME}__title-wrapper`}>
							<div className={`${CLASSNAME}__title`}>{title}</div>
							{created && source && (
								<div className={`${CLASSNAME}__subtitle`}>
									{Format.date(created)} - {`${source}${localize('AutoMotoArticles.sourceSuffix')}`}
								</div>
							)}
						</div>
						<div className={`${CLASSNAME}__perex`}>{renderPerex(perex)}</div>
					</div>
				</Surface>
			</div>
		)
	}

	const renderNoArticlesMsg = () => {
		return (
			<div className={`${CLASSNAME}__empty`}>
				<div className={`${CLASSNAME}__empty-text`}>{localize('AutoMotoArticles.noArticles')}</div>
				<Button
					className={`${CLASSNAME}__empty-button`}
					primary={true}
					href='/'
					text={localize('Base.backToHp')}
				/>
			</div>
		)
	}

	const renderTabs = () => {
		const categoryParam = ArticlesUrlConvertor.constants.URL_APP_PARAMS.CATEGORY
		const { params } = router.getCurrentRouteInfo()
		const { [categoryParam]: actualCategory } = params
		const tabs = [
			{
				text: localize('AutoMotoArticles.all'),
				selected: !actualCategory,
				href: link(ROUTE_NAMES.USERWEB.ARTICLES)
			},
			{
				text: localize('AutoMotoArticles.news'),
				selected: actualCategory === ArticlesUrlConvertor.categories.NEWS,
				href: link(ROUTE_NAMES.USERWEB.ARTICLES, {
					[categoryParam]: ArticlesUrlConvertor.categories.NEWS
				})
			},
			{
				text: localize('AutoMotoArticles.tests'),
				selected: actualCategory === ArticlesUrlConvertor.categories.TESTS,
				href: link(ROUTE_NAMES.USERWEB.ARTICLES, {
					[categoryParam]: ArticlesUrlConvertor.categories.TESTS
				})
			},
			{
				text: localize('AutoMotoArticles.tipsAndAdvices'),
				selected: actualCategory === ArticlesUrlConvertor.categories.TIPS,
				href: link(ROUTE_NAMES.USERWEB.ARTICLES, {
					[categoryParam]: ArticlesUrlConvertor.categories.TIPS
				})
			}
		]

		return (
			<Tabs
				tabs={tabs}
				className={`${CLASSNAME}__tabs`}
				accessibilitySelectedPerexText={localize('Base.selected')}
			/>
		)
	}

	const renderArticles = () => {
		const areArticlesLoaded = !!articlesList

		if (areArticlesLoaded) {
			if (articlesList.length > 0) {
				return (
					<>
						<div className={`${CLASSNAME}__articles`}>{articlesList.map(renderArticle)}</div>
						{renderPagination()}
					</>
				)
			} else {
				return renderNoArticlesMsg()
			}
		} else {
			return <AutoMotoArticlesPlaceholders />
		}
	}

	const renderPagination = () => {
		return (
			<Paging
				paginationEntity={pagination}
				urlPageCreator={(page) => {
					return ArticlesUrlConvertor.createPaginationUrl(page, router)
				}}
			/>
		)
	}

	return (
		<>
			{renderTabs()}
			{renderArticles()}
		</>
	)
}

AutoMotoArticles.propTypes = {
	articles: PropTypes.shape({
		articlesList: PropTypes.arrayOf(PropTypes.instanceOf(ArticleEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	})
}

export default React.memo(AutoMotoArticles)
