import { createEntityList } from '@inzeraty/form-lines'
import { AdvertReportsService } from '@inzeraty/models'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { REASONS } from 'app/component/report/ReportFormLineConstants'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { StatusMessage } from '@inzeraty/components'
import 'app/component/report/ReportCS.json'

export default class IAAdvertListReportedController extends AbstractInternalAdminController {
	static get STATUS() {
		return Object.freeze({
			NEW: {
				value: AdvertReportsService.STATUS.NEW,
				name: 'Nový'
			},
			PROCESSED: {
				value: AdvertReportsService.STATUS.PROCESSED,
				name: 'Zpracovaný'
			}
		})
	}

	static get ACTIONS() {
		return Object.freeze({
			STATUS_PROCESSED: 'processed',
			STATUS_NEW: 'new',
			ACTIVATE: 'activate',
			DEACTIVATE: 'deactivate',
			OPEN_FORBID_USER_POPUP: 'openForbidUserPopUp',
			OPEN_FORBID_ADVERT_POPUP: 'openForbidAdvertPopUp',
			CLOSE_POPUP: 'closePopUp'
		})
	}

	static getReasonText(value, dictionary) {
		const hashTable = {
			[REASONS.SUSPICIOUS_ADVERT]: dictionary.get('Report.formSuspiciousAdvertLabel'),
			[REASONS.WRONG_CATEGORY]: dictionary.get('Report.formWrongCategoryLabel'),
			[REASONS.FALSE_INFO]: dictionary.get('Report.formFalseInfoLabel'),
			[REASONS.BAD_PHOTOS]: dictionary.get('Report.formBadPhotosLabel'),
			[REASONS.OTHER]: dictionary.get('Report.formOtherLabel')
		}

		return hashTable[value]
	}

	constructor(
		dependenciesHelper,
		entitiesFilterExtension,
		advertReportsService,
		advertService,
		userService,
		forbidUserPopupExtension,
		forbidAdvertPopupExtension
	) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._advertReportsService = advertReportsService
		this._advertService = advertService
		this._userService = userService
		this._forbidUserPopupExtension = forbidUserPopupExtension
		this._forbidAdvertPopupExtension = forbidAdvertPopupExtension
		this._router = this._utils.$Router

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
		this._setAdvertEntitiesToState = this._setAdvertEntitiesToState.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)
		this.addExtension(this._forbidAdvertPopupExtension)
		this.addExtension(this._forbidUserPopupExtension)

		this._forbidAdvertPopupExtension.setSuccessHandler(this._setAdvertEntitiesToState)
		this._forbidUserPopupExtension.setSuccessHandler(this._setAdvertEntitiesToState)
		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			statusErrorMessage: null,
			isUserForbidOpened: false,
			userEntity: {},
			advertEntity: {}
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			statusErrorMessage: null,
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const fetchReports = async (params) => {
			try {
				const { reportsEntities, paginationEntity } = await this._advertReportsService.getReports(
					params
				)

				return {
					entities: reportsEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (typeof params.sort === 'undefined') {
			params.sort = '-create_date'
		}

		this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchReports(params)

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	onSaveNewNote({ note }) {
		const id = this._getUserId()
		this._userService
			.saveUser(id, { note })
			.then(() => {
				this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
					title: 'RUS info bylo úspěšně uloženo',
					type: StatusMessage.TYPE.SUCCESS
				})
			})
			.catch(() => {
				this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
					title: 'Nepodařilo se uložit RUS info',
					type: StatusMessage.TYPE.ERROR
				})
			})
	}

	_getUserId() {
		const { userEntity = {} } = this.getState()
		const { id } = userEntity
		return id
	}

	_handleAction({ action, entities, entitiesIds = [] }) {
		let promise
		let getErrorMessage = () => ''
		const [entity] = entities
		const {
			id: entityId,
			advert: { id: advertId }
		} = entity

		const textAdvert = entitiesIds.length === 1 ? 'inzerát' : 'inzeráty'
		const textSuffix = `${textAdvert} s id ${entitiesIds.join(', ')}`

		switch (action) {
			case IAAdvertListReportedController.ACTIONS.STATUS_NEW:
				promise = this._advertReportsService.update(advertId, entityId, {
					report_status: IAAdvertListReportedController.STATUS.NEW.value
				})
				getErrorMessage = () => 'Nepodařilo se změnit stav nahlášení inzerátu.'
				break

			case IAAdvertListReportedController.ACTIONS.STATUS_PROCESSED:
				promise = this._advertReportsService.update(advertId, entityId, {
					report_status: IAAdvertListReportedController.STATUS.PROCESSED.value
				})
				getErrorMessage = () => 'Nepodařilo se změnit stav nahlášení inzerátu.'
				break

			case IAAdvertListReportedController.ACTIONS.ACTIVATE:
				promise = this._advertService.updateAdvertStatus({
					ids: [entity.advert.id],
					status_active: true
				})
				getErrorMessage = () => `Nepodařilo se aktivovat ${textSuffix}`
				break

			case IAAdvertListReportedController.ACTIONS.DEACTIVATE:
				promise = this._advertService.updateAdvertStatus({
					ids: [entity.advert.id],
					status_active: false
				})
				getErrorMessage = () => `Nepodařilo se deaktivovat ${textSuffix}`
				break

			case IAAdvertListReportedController.ACTIONS.OPEN_FORBID_ADVERT_POPUP:
				this._forbidAdvertPopupExtension.openPopup(entity.advert.id)
				break

			case IAAdvertListReportedController.ACTIONS.OPEN_FORBID_USER_POPUP:
				this._forbidUserPopupExtension.openPopup(entity.advert.id, entity.advert.user?.id)
				break
		}

		return {
			promise,
			getErrorMessage
		}
	}

	_processSuccessAction() {
		this._setAdvertEntitiesToState()
	}

	async _setAdvertEntitiesToState() {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	_getReasonText(value) {
		return IAAdvertListReportedController.getReasonText(value, this._utils.$Dictionary)
	}

	_filterInitiezerClb(routeParams) {
		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'advert_id',
					name: 'ID inzerátu'
				},
				{
					value: 'user_email',
					name: 'Email'
				},
				{
					value: 'user_id',
					name: 'ID RUS'
				},
				{
					value: 'premise_id',
					name: 'ASK ID'
				},
				{
					value: 'ip_address',
					name: 'IP'
				},
				{
					value: 'geo_ip_address',
					name: 'Geo IP'
				}
			]),
			this._entitiesFilterExtension.getSelect('report_reason', 'Důvod', 'Vyberte', [
				{
					value: REASONS.SUSPICIOUS_ADVERT,
					name: this._getReasonText(REASONS.SUSPICIOUS_ADVERT)
				},
				{
					value: REASONS.WRONG_CATEGORY,
					name: this._getReasonText(REASONS.WRONG_CATEGORY)
				},
				{
					value: REASONS.FALSE_INFO,
					name: this._getReasonText(REASONS.FALSE_INFO)
				},
				{
					value: REASONS.BAD_PHOTOS,
					name: this._getReasonText(REASONS.BAD_PHOTOS)
				},
				{
					value: REASONS.OTHER,
					name: this._getReasonText(REASONS.OTHER)
				}
			]),
			this._entitiesFilterExtension.getSelect('report_status', 'Stav nahlášení', 'Vyberte', [
				IAAdvertListReportedController.STATUS.NEW,
				IAAdvertListReportedController.STATUS.PROCESSED
			]),
			this._entitiesFilterExtension.getAdvertStatusSelect(),
			...this._entitiesFilterExtension.getAdvertisingSelects()
		])
	}
}
