import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { uuid } from 'uuidv4'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import ActionElm from 'app/component/actionElement/ActionElement'
import ReviewsExtension, { REVIEW_REPLIES_PERPAGE } from '../ReviewsExtension'
import ReviewEntity from 'app/model/review/ReviewEntity'
import { Format } from '@inzeraty/helpers'
import ShowMoreText from 'app/component/showMoreText/ShowMoreTextView'
import ReviewFeedback from '../reviewFeedback/ReviewFeedback'
import { UserService } from '@inzeraty/models'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import ReviewReply from '../reviewReply/ReviewReply'
import ReviewReplyForm from '../reviewReplyForm/ReviewReplyForm'
import { Button } from '@sznds/react'
import { TRASH_FILLED } from '@sznds/icons'
import SimpleTooltip from 'app/component/simpleTooltip/SimpleTooltipView'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import 'app/base/BaseCS.json'
import './ReviewItemCS.json'
import './ReviewItem.less'
import 'app/base/AssignEmailReasonsCS.json'

const CLASSNAME = 'c-review-item'
const PLACEHOLDER = '--plh'

const ReviewItem = ({ reviewEntity }) => {
	const userEntity = useContext(UserEntityContext) || {}
	const localize = useLocalize()
	const isPlaceholder = Boolean(!reviewEntity.id)
	const fire = useFire()

	const [isOpenFeedbackForm, setIsOpenFeedbackForm] = useState(false)
	const [nextRepliesCount, setNextRepliesCount] = useState(0)
	const [isWaitingForNext, setIsWaitingForNext] = useState(false)

	useEffect(() => {
		const { reviewReplies, repliesCount } = reviewEntity || {}

		const restRepliesCount = repliesCount - reviewReplies.length

		if (restRepliesCount > 0) {
			if (restRepliesCount > REVIEW_REPLIES_PERPAGE) {
				setNextRepliesCount(REVIEW_REPLIES_PERPAGE)
			} else {
				setNextRepliesCount(restRepliesCount)
			}
		} else {
			setNextRepliesCount(0)
		}

		setIsWaitingForNext(false)
	}, [reviewEntity.reviewReplies, reviewEntity.repliesCount])

	const {
		createDate,
		id,
		negatives,
		negativeFeedbackCount,
		positives,
		positiveFeedbackCount,
		review,
		reviewReplies,
		title,
		type,
		userEmailPrefix = '',
		userId = '',
		userName,
		userFeedbackType = ReviewsExtension.USER_FEEDBACK_TYPE.NONE
	} = isPlaceholder ? { id: uuid() } : reviewEntity

	const renderPlusMinus = (type, text) => {
		const isTypePositive = type === ReviewsExtension.TYPE.POSITIVE
		const isTypeNegative = type === ReviewsExtension.TYPE.NEGATIVE

		if (isPlaceholder) {
			return <div className={`${CLASSNAME}__plusminus`}></div>
		} else if (text) {
			return (
				<dl
					className={classnames({
						[`${CLASSNAME}__plusminus`]: true,
						[`${CLASSNAME}__plusminus--plus`]: isTypePositive,
						[`${CLASSNAME}__plusminus--minus`]: isTypeNegative
					})}
				>
					<dt>
						<span
							className={classnames({
								[`${CLASSNAME}__icon`]: true,
								[`${CLASSNAME}__icon--plus`]: isTypePositive,
								[`${CLASSNAME}__icon--minus`]: isTypeNegative
							})}
						/>
						{isTypePositive && localize('ReviewItem.pluses')}
						{isTypeNegative && localize('ReviewItem.minuses')}
					</dt>
					<dd>{text}</dd>
				</dl>
			)
		} else {
			return null
		}
	}

	const renderDeleteBtn = () => {
		if (UserService.isUserLogged(userEntity)) {
			const { id: loggedUserId } = userEntity
			const isMine = loggedUserId && userId === loggedUserId

			if (isMine && !isPlaceholder) {
				return (
					<>
						<span className={`${CLASSNAME}__space-btn`}></span>
						<SimpleTooltip
							description={localize('Base.delete')}
							ariaHidden={true}
							align={SimpleTooltip.ALIGN.RIGHT}
							renderContent={(className) => (
								<Button
									icon={TRASH_FILLED}
									size='x-small'
									surface={0}
									onClick={() => {
										fire('deleteReview', id)
									}}
									className={classnames({
										[`${CLASSNAME}__remove-btn`]: true,
										[className]: !!className
									})}
									data-e2e='delete-review'
								/>
							)}
						/>
					</>
				)
			}
		}
	}

	const onAddFeedbackClick = () => {
		fire('checkIsUserLogged', {
			reason: localize('AssignEmailReasons.reviewFeedback'),
			onLogin: () => {
				setIsOpenFeedbackForm(!isOpenFeedbackForm)
			},
			sourceComponent: LoginHelper.SOURCE_COMPONENT.REVIEW_REPLY
		})
	}

	const renderActions = () => {
		const { id: loggedUserId } = userEntity || {}
		const isMine = loggedUserId && userId === loggedUserId

		return (
			<div className={`${CLASSNAME}__actions`}>
				{!isPlaceholder && (
					<>
						<ReviewFeedback
							className={`${CLASSNAME}__feedback`}
							negativeCount={negativeFeedbackCount}
							positiveCount={positiveFeedbackCount}
							userFeedbackType={userFeedbackType}
							disabled={isMine}
							onClick={(type) => {
								fire('clickReviewFeedback', {
									reviewId: id,
									type
								})
							}}
						/>
						<ActionElm
							onClick={onAddFeedbackClick}
							className={classnames({
								[`${CLASSNAME}__add-reply-btn`]: true,
								[`${CLASSNAME}__add-reply-btn--waiting`]: isWaitingForNext
							})}
							data-e2e='reply-to-review'
						>
							{localize('ReviewItem.addFeedback')}
						</ActionElm>
						{renderDeleteBtn()}
					</>
				)}
			</div>
		)
	}

	const getNextReviewReplies = () => {
		if (!isWaitingForNext) {
			setIsWaitingForNext(true)

			fire('getReviewReplies', {
				reviewId: id,
				limit: nextRepliesCount,
				offset: reviewReplies.length
			})
		}
	}

	const renderReplyPaging = () => {
		if (nextRepliesCount > 0) {
			return (
				<ActionElm onClick={getNextReviewReplies} className={`${CLASSNAME}__next-replies`}>
					{localize('ReviewItem.showNextReplies', { COUNT: nextRepliesCount })}
				</ActionElm>
			)
		}
	}

	return (
		<li
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}${PLACEHOLDER}`]: isPlaceholder
			})}
			data-e2e={`review-${id}`}
		>
			<h5 className={`${CLASSNAME}__title`}>{title}</h5>

			<div className={`${CLASSNAME}__rating`}>
				{type && (
					<>
						<span
							className={classnames({
								[`${CLASSNAME}__rating-perex`]: true,
								[`${CLASSNAME}__rating-perex--${type}`]: !!type
							})}
						>
							{localize(`ReviewItem.${type}`)}
						</span>
						{', '}
						{userName || userEmailPrefix}
						{', '}
						{Format.date(createDate)}
					</>
				)}
			</div>

			<ShowMoreText
				tagName='p'
				className={`${CLASSNAME}__text`}
				text={review}
				limitLength={160}
				dataDotData={'{"type": "review"}'}
			/>

			{(positives || negatives || isPlaceholder) && (
				<div className={`${CLASSNAME}__plusminuses`}>
					{renderPlusMinus(ReviewsExtension.TYPE.POSITIVE, positives)}
					{renderPlusMinus(ReviewsExtension.TYPE.NEGATIVE, negatives)}
				</div>
			)}

			{renderActions()}

			{!isPlaceholder && (isOpenFeedbackForm || reviewReplies.length > 0) && (
				<div
					className={classnames({
						[`${CLASSNAME}__replies`]: true,
						[`${CLASSNAME}__replies--waiting`]: isWaitingForNext
					})}
				>
					{isOpenFeedbackForm && (
						<ReviewReplyForm reviewId={id} setIsOpen={setIsOpenFeedbackForm} />
					)}
					<ul>
						{reviewReplies.map((reviewReplyEntity, index) => (
							<ReviewReply
								reviewId={id}
								reviewReplyEntity={reviewReplyEntity}
								key={reviewReplyEntity.id || index}
							/>
						))}
					</ul>
					{renderReplyPaging()}
				</div>
			)}
		</li>
	)
}

ReviewItem.propTypes = {
	reviewEntity: PropTypes.instanceOf(ReviewEntity).isRequired
}

export default ReviewItem
