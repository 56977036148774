
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ModelSelect'] = {
  popupTitle: function(d) { return "Vyberte model"; },
  placeholderSearch: function(d) { return "Vyberte model"; },
  noOptsTitle: function(d) { return "Žádné výsledky pro “" + d.TERM + "”"; },
  noOptsText: function(d) { return "Inzerát můžete zařadit do kategorie Ostatní"; },
  othersBtnText: function(d) { return "Zařadit do Ostatních"; },
  waiting: function(d) { return "Načítání"; },
  labelFavorite: function(d) { return "Nejhledanější modely"; },
  labelAll: function(d) { return "Všechny modely"; },
  labelFound: function(d) { return "Našli jsme"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	