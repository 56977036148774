import React from 'react'
import PremiseService from 'app/model/premise/PremiseService'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import PropTypes from 'prop-types'
import PremiseEntity from 'app/model/premise/PremiseEntity'
import { PaginationEntity } from '@inzeraty/models'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHead from 'app/component/tableHead/TableHead'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import { Format } from '@inzeraty/helpers'
import PremiseHistoryRow from './premiseHistoryRow/PremiseHistoryRowView'
import { useLocalize } from 'app/base/componentHelpers'
import EmptyList from 'app/component/emptyList/EmptyList'

import 'app/page/userweb/clientProfile/ClientProfileCS.json'
import './IAPremiseHistoryCS.json'

export default function IAPremiseHistoryView({
	premiseEntity,
	entitiesAndPagination = DEFAULT_PROPS.ARRAY,
	isLoading = false
}) {
	const localize = useLocalize()
	const { name = '' } = PremiseService.getPremiseDataForPublic(premiseEntity)

	const renderRow = ({ rowId, entityData, rowCells }) => {
		return <PremiseHistoryRow key={rowId} entityData={entityData} rowCells={rowCells} />
	}

	return (
		<Layout title={localize('IAPremiseHistory.title', { PREMISE_NAME: name })}>
			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='time' sortParam='id' isDefaultDescending={true}>
						{localize('IAPremiseHistory.date')}
					</TableHeadSorter>,
					<TableHead key='changedBy'>{localize('IAPremiseHistory.author')}</TableHead>,
					<TableHead key='ipAddress'>{localize('IAPremiseHistory.ipAddress')}</TableHead>,
					<TableHead key='changes'>{localize('IAPremiseHistory.changes')}</TableHead>
				]}
				rowCells={[
					({ time }) => Format.dateTime(time),
					({ changedBy }) => changedBy,
					({ ipAddress }) => ipAddress,
					() => [localize('IAPremiseHistory.showDetails'), { isOpener: true }]
				]}
				renderEmptyBody={() => (
					<EmptyList title={localize('IAPremiseHistory.nothingFound')} text='' />
				)}
				renderRow={renderRow}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAPremiseHistoryView.propTypes = {
	premiseEntity: PropTypes.instanceOf(PremiseEntity),
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.object),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	isLoading: PropTypes.bool
}
