import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import { useLocalize } from 'app/base/componentHelpers'
import {
	CATEGORIES,
	COLOR_CODES,
	CAR_BODY,
	ELECTRIC_VEHICLE_FUEL_OPTION_NAME
} from 'app/base/Constants'
import IMAGES from 'app/base/ImagesConstants'
import STATE_KEYS from 'app/page/userweb/advertDetail/AdvertDetailStateKeys'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import TextLink from 'app/component/textLink/TextLink'
import classnames from 'classnames'

import './CarProperties.less'
import './CarPropertiesCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-car-properties'

const CarProperties = memo(
	({ [STATE_KEYS.ADVERT_ENTITY]: advertEntity = DEFAULT_PROPS.OBJECT }) => {
		const localize = useLocalize()
		const {
			category = {},
			fuelCb = {},
			engineVolume,
			enginePower,
			gearboxCb = {},
			vehicleBodyCb = {},
			colorCb = {},
			averageGasMileage,
			batteryCapacity,
			vehicleRange,
			averageElectricMileage,
			description
		} = advertEntity
		const { name: fuelName } = fuelCb
		const { name: vehicleBodyName, seoName: vehicleBodySeoName } = vehicleBodyCb
		const { name: colorName, value: colorValue } = colorCb
		const { name: gearboxName } = gearboxCb
		const { id: categoryId } = category
		const isElectricVehicle = fuelName === ELECTRIC_VEHICLE_FUEL_OPTION_NAME

		const [isDescriptionOpen, setIsDescriptionOpen] = useState(false)

		const renderTile = (icon, label, value) => {
			return (
				<li className={`${CLASSNAME}__tile`}>
					<span className={`${CLASSNAME}__tile-icon`}>{icon}</span>
					<span className={`${CLASSNAME}__tile-text`}>
						<div className={`${CLASSNAME}__tile-label`}>{localize(`CarProperties.${label}`)}</div>
						<div className={`${CLASSNAME}__tile-value`}>{value}</div>
					</span>
				</li>
			)
		}

		const isDataLoaded = () => {
			const { status } = advertEntity

			return !!status
		}

		const renderCarPropertiesPlaceholder = () => {
			const PLACEHOLDER_ARRAY = Array(7).fill()

			return (
				<div className={`${CLASSNAME}__placeholder`}>
					<ul className={`${CLASSNAME}__placeholder-list`}>
						{PLACEHOLDER_ARRAY.map((_, index) => {
							return (
								<li key={`${index}__car-prop-key`} className={`${CLASSNAME}__placeholder-tile`}>
									<span className={`${CLASSNAME}__tile-icon`}>
										<span className={`${CLASSNAME}__icon-placeholder`}></span>
									</span>
									<div className={`${CLASSNAME}__tile-text`}>
										<div className={`${CLASSNAME}__text-placeholder`}></div>
									</div>
								</li>
							)
						})}
					</ul>
					<div className={`${CLASSNAME}__desc-placeholder`}>
						<div className={`${CLASSNAME}__placeholder-sentence-1`}></div>
						<div className={`${CLASSNAME}__placeholder-sentence-2`}></div>
					</div>
				</div>
			)
		}

		const renderIcon = (src) => {
			return <img src={src} className={`${CLASSNAME}__icon`} alt='' />
		}

		const renderVehicleBodyTile = () => {
			const { svgIcon } =
				categoryId === CATEGORIES.PASSENGER_CARS.id
					? Object.values(CAR_BODY).find(({ seoName }) => seoName === vehicleBodySeoName) || {}
					: Object.values(CATEGORIES).find(({ id }) => id === categoryId) || {}

			const icon = <SvgIcon icon={svgIcon} className={`${CLASSNAME}__icon-body`} />

			return vehicleBodyName ? renderTile(icon, 'body', vehicleBodyName) : null
		}

		const renderFuel = () => {
			const icon = renderIcon(IMAGES['image__car-properties__fuel'])
			return fuelName ? renderTile(icon, 'fuel', fuelName) : null
		}

		const renderEngineVolume = () => {
			const icon = renderIcon(IMAGES['image__car-properties__engine'])
			return engineVolume && !isElectricVehicle
				? renderTile(icon, 'engineVolume', `${Format.number(engineVolume)} ${localize('Base.CCM')}`)
				: null
		}

		const renderBatteryCapacity = () => {
			const icon = renderIcon(IMAGES['image__car-properties__battery'])
			return batteryCapacity && isElectricVehicle
				? renderTile(
						icon,
						'batteryCapacity',
						`${Format.number(batteryCapacity)} ${localize('Base.batteryCapacity')}`
				  )
				: null
		}

		const renderVehicleRange = () => {
			const icon = renderIcon(IMAGES['image__car-properties__range'])
			return vehicleRange && isElectricVehicle
				? renderTile(icon, 'vehicleRange', `${Format.number(vehicleRange)} ${localize('Base.KM')}`)
				: null
		}

		const renderGearbox = () => {
			const icon = renderIcon(IMAGES['image__car-properties__gearbox'])
			return gearboxName ? renderTile(icon, 'gearbox', gearboxName) : null
		}

		const renderEnginePower = () => {
			const icon = renderIcon(IMAGES['image__car-properties__performance'])
			return enginePower
				? renderTile(icon, 'enginePower', `${Format.number(enginePower)} ${localize('Base.KW')}`)
				: null
		}

		const renderAverageGasMileage = () => {
			const icon = renderIcon(IMAGES['image__car-properties__mileage'])
			return averageGasMileage && !isElectricVehicle
				? renderTile(
						icon,
						'averageGasMileage',
						`${averageGasMileage} ${localize('CarProperties.liters')}`
				  )
				: null
		}

		const renderAverageElectricMileage = () => {
			const icon = renderIcon(IMAGES['image__car-properties__mileage'])
			return averageElectricMileage && isElectricVehicle
				? renderTile(
						icon,
						'averageElectricMileage',
						`${Format.number(averageElectricMileage)} ${localize('Base.electricMileage')}`
				  )
				: null
		}

		const renderColor = () => {
			const icon = (
				<span
					style={{ background: COLOR_CODES[colorValue] }}
					className={`${CLASSNAME}__color`}
				></span>
			)
			return colorName ? renderTile(icon, 'color', colorName) : null
		}

		const renderDescription = () => {
			if (description) {
				const MAX_CHARS = 210
				const MAX_LINES = 6
				const isDescriptionLong = description.length > MAX_CHARS
				let desc

				if (isDescriptionLong && !isDescriptionOpen) {
					desc = description.substring(0, MAX_CHARS).split(/\r|\n/).slice(0, MAX_LINES).join('\r\n')
					desc += '...'
				} else {
					desc = description
				}

				return (
					<div className={`${CLASSNAME}__description`}>
						<div className={`${CLASSNAME}__label`}>{localize('CarProperties.description')}:</div>
						<p className={`${CLASSNAME}__text`}>
							{desc}
							{isDescriptionLong && !isDescriptionOpen && (
								<TextLink
									onClick={() => {
										setIsDescriptionOpen(true)
									}}
									className={`${CLASSNAME}__more`}
								>
									{localize('CarProperties.showMore')}
								</TextLink>
							)}
						</p>
					</div>
				)
			} else {
				return null
			}
		}

		if (!advertEntity || categoryId === CATEGORIES.TRAILERS.id) {
			return (
				isDataLoaded() && (
					<div
						className={classnames({
							[CLASSNAME]: true,
							[`${CLASSNAME}--empty`]: !description
						})}
					>
						{renderDescription()}
					</div>
				)
			)
		} else {
			return (
				<div className={CLASSNAME}>
					{isDataLoaded() ? (
						<>
							<ul className={`${CLASSNAME}__tiles`}>
								{renderVehicleBodyTile()}
								{renderFuel()}
								{renderEngineVolume()}
								{renderBatteryCapacity()}
								{renderVehicleRange()}
								{renderGearbox()}
								{renderAverageGasMileage()}
								{renderAverageElectricMileage()}
								{renderEnginePower()}
								{renderColor()}
							</ul>
							{renderDescription()}
						</>
					) : (
						renderCarPropertiesPlaceholder()
					)}
				</div>
			)
		}
	}
)

CarProperties.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes)
}

export default CarProperties
