import React, { memo } from 'react'
import { Select } from '@inzeraty/components'
import { CLASSNAME } from '../InternalAdminWidgets'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { nonValueOption } from 'app/component/entitiesFilter/EntitiesFilter'

const WidgetSelect = memo(({ className, formLineEntity = {}, onChange }) => {
	const {
		id,
		label,
		value,
		placeholder,
		options = [],
		extra = {},
		required,
		errorMessage
	} = formLineEntity
	const { disabled = false } = extra

	const isError = Boolean(errorMessage)

	return (
		<div className={className}>
			<div className={`${CLASSNAME}__input-box`}>
				<label htmlFor={id} className={`${CLASSNAME}__label`}>
					{label}
					{required && <span className={`${CLASSNAME}__req`}>*</span>}
				</label>
				<Select
					className={`${CLASSNAME}__input`}
					id={id}
					placeholder={placeholder}
					value={value === nonValueOption.value ? undefined : value}
					onChange={(event) => {
						onChange({
							id,
							value: event.target.value
						})
					}}
					disabled={disabled}
					error={isError}
				>
					{options.map(({ value, name }) => {
						return (
							<option key={value} value={value}>
								{name}
							</option>
						)
					})}
				</Select>
				{isError && <div className={`${CLASSNAME}__error`}>{errorMessage}</div>}
			</div>
		</div>
	)
})

WidgetSelect.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func,
	className: PropTypes.string
}

export default WidgetSelect
