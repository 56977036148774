import UserwebBaseController from 'app/base/UserwebBaseController'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import GenericError from 'ima/error/GenericError'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import { getIdFromSeoName } from '../helper/IdFromSeoName'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import { CATEGORIES, GEARBOX } from 'app/base/Constants'
import FilterConstants from 'app/model/filter/FilterConstants'
import ROUTE_NAMES from 'app/base/RouteNames'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import {
	getBrandModelSeoName,
	isBrandAndModelExistForModification,
	getPowerKwRange,
	getEngineVolumeRange
} from '../helper/modificationsHelper'
import CatalogMeta from '../CatalogMeta'

export default class ModificationDetailController extends UserwebBaseController {
	constructor(dependenciesHelper, catalogService, advertService) {
		super(dependenciesHelper)

		this._catalogService = catalogService
		this._advertService = advertService
		this._router = this._utils.$Router
	}

	static get STATE_KEYS() {
		return {
			MODIFICATION_DETAIL: 'MODIFICATION_DETAIL',
			ADVERTS_COUNT: 'ADVERTS_COUNT',
			LINK_URL: 'LINK_URL'
		}
	}

	init() {
		super.init()
	}

	load() {
		const modificationDetail = this._loadModificationDetail()

		return super.load({
			[ModificationDetailController.STATE_KEYS.MODIFICATION_DETAIL]: modificationDetail,
			[ModificationDetailController.STATE_KEYS.LINK_URL]: modificationDetail.then((detail) => {
				return this._getLinkToList(detail)
			}),
			[ModificationDetailController.STATE_KEYS.ADVERTS_COUNT]: modificationDetail.then((detail) => {
				return this._getAdvertsCount(detail)
			})
		})
	}

	getDataForSsp() {
		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {}
		}
	}

	_loadModificationDetail() {
		const { URL_API_PARAMS, URL_APP_PARAMS } = CatalogUrlConvertor.constants

		const { [URL_APP_PARAMS.MODIFITACION_SEO_NAME]: seoName } = this.getRouteParams()

		const modificationId = getIdFromSeoName(seoName)

		if (modificationId) {
			return this._catalogService.getModificationDetail({
				[URL_API_PARAMS.MODIFICATION_ID]: modificationId
			})
		} else {
			// neplatna URL, vracime 400.
			const error = new GenericError('Neplatna URL.', {
				status: HTTP_STATUS_CODES.BAD_REQUEST
			})
			return Promise.reject(error)
		}
	}

	/**
	 * Ziska pocet inzeratov pre parametry:
	 * Znacka, Model, Karoserie, Rok vyroby, Vykov
	 * @param {Object} detail
	 */
	async _getAdvertsCount(detail) {
		if (detail) {
			const isBrandAndModelExists = await this._isBrandAndModelExists(detail)

			if (isBrandAndModelExists) {
				const defaultAppParams = this._getDefaultAppParams(detail)
				const optionalAppParams = this._getOptionalAppParams(detail)

				const filterAppParams = Object.assign({}, defaultAppParams, optionalAppParams)

				const apiParams = Object.assign(
					AdvertListUrlConvertor.getApiParams(filterAppParams, this.getRouteName()),
					{
						[UrlConvertor.constants.URL_API_PARAMS.LIMIT]: 0,
						[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]:
							CATEGORIES.PASSENGER_CARS.id
					}
				)

				return this._advertService.getAdvertsTotalCount(apiParams, {}, false)
			} else {
				return 0
			}
		} else {
			return 0
		}
	}

	async _getLinkToList(detail) {
		if (detail) {
			const isBrandAndModelExists = await this._isBrandAndModelExists(detail)

			if (isBrandAndModelExists) {
				const defaultAppParams = this._getDefaultAppParams(detail)
				const optionalAppParams = this._getOptionalAppParams(detail)

				const urlParams = Object.assign({}, defaultAppParams, optionalAppParams)

				return this._router.link(ROUTE_NAMES.USERWEB.ADVERT_LIST, urlParams)
			} else {
				return undefined
			}
		} else {
			return undefined
		}
	}

	async _isBrandAndModelExists(detail = {}) {
		const { brandSeoName, modelSeoName } = this._getDetailInfo(detail)

		const isBrandAndModelExists = await isBrandAndModelExistForModification(
			this._catalogService,
			brandSeoName,
			modelSeoName
		)

		return isBrandAndModelExists
	}

	_getOptionalAppParams(detail = {}) {
		const { data = {} } = detail
		const {
			engineVolume,
			powerKw,
			gearboxATNumber,
			gearboxMTNumber,
			fuelSeo,
			vehicleBodySeo
		} = data

		const { powerKwFrom, powerKwTo } = getPowerKwRange(powerKw)
		const { engineVolumeFrom, engineVolumeTo } = getEngineVolumeRange(engineVolume)

		let gearbox = ''

		if (gearboxATNumber) {
			gearbox = GEARBOX.AUTOMATIC
		} else if (gearboxMTNumber) {
			gearbox = GEARBOX.MANUAL
		}

		const optionalAppParams = {}

		if (engineVolumeFrom) {
			optionalAppParams[
				AdvertListUrlConvertor.constants.URL_APP_PARAMS.DISPLACEMENT_FROM
			] = engineVolumeFrom
		}

		if (engineVolumeTo) {
			optionalAppParams[
				AdvertListUrlConvertor.constants.URL_APP_PARAMS.DISPLACEMENT_TO
			] = engineVolumeTo
		}

		if (powerKwFrom) {
			optionalAppParams[
				AdvertListUrlConvertor.constants.URL_APP_PARAMS.PERFORMANCE_FROM
			] = powerKwFrom
		}

		if (powerKwTo) {
			optionalAppParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.PERFORMANCE_TO] = powerKwTo
		}

		if (gearbox) {
			optionalAppParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.GEARBOX_NAME] = gearbox
		}

		if (fuelSeo) {
			optionalAppParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS.FUEL_NAME] = fuelSeo
		}

		if (vehicleBodySeo) {
			optionalAppParams[
				AdvertListUrlConvertor.constants.URL_APP_PARAMS.VEHICLE_BODY_NAME
			] = vehicleBodySeo
		}

		return optionalAppParams
	}

	_getDetailInfo(detail = {}) {
		const { generation = {}, yearStart: min, yearEnd } = detail
		const { model = {} } = generation
		const { manufacturer = {}, seoName: modelSeo } = model
		const { seoName: brandSeo } = manufacturer

		const brandSeoName = getBrandModelSeoName(brandSeo)
		const modelSeoName = getBrandModelSeoName(modelSeo)

		const actualYear = new Date().getFullYear()

		const max = isNullOrUndefined(yearEnd) ? actualYear : yearEnd

		return {
			brandSeoName,
			modelSeoName,
			min,
			max
		}
	}

	_getDefaultAppParams(detail = {}) {
		const { brandSeoName, modelSeoName, min, max } = this._getDetailInfo(detail)

		return {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]:
				CATEGORIES.PASSENGER_CARS.seoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: brandSeoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]: modelSeoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CONDITION_NAME]:
				FilterConstants.allConditionApiParam,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.AGE_FROM]: min,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.AGE_TO]: max
		}
	}

	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const {
			[ModificationDetailController.STATE_KEYS.MODIFICATION_DETAIL]: detail = {}
		} = loadedResources

		const { generation = {}, name: modificationName, data = {} } = detail || {}
		const { model = {} } = generation
		const { name: modelName, manufacturer = {} } = model
		const { name: brandName } = manufacturer

		const { powerKw } = data

		const { min, max } = this._getDetailInfo(detail)

		let additionalInfo = ''

		if (powerKw) {
			additionalInfo += `${powerKw} ${dictionary.get('Base.KW')}`
		}

		if (min && max) {
			additionalInfo += powerKw ? ` (${min}-${max})` : `(${min}-${max})`
		}

		const info = {
			brandName,
			modelName,
			modificationName,
			textInfo: additionalInfo
		}

		const { title, description } = this._getMetas(info)

		const metaTitle = title + dictionary.get('Base.titleSuffix')
		metaManager.setTitle(metaTitle)
		metaManager.setMetaName('twitter:title', metaTitle)
		metaManager.setMetaProperty('og:title', metaTitle)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('twitter:description', description)
		metaManager.setMetaProperty('og:description', description)
	}

	_getMetas(info) {
		const { route } = this._utils.$Router.getCurrentRouteInfo()
		const routeName = route.getName()

		return CatalogMeta.getMetas(routeName, this._utils.$Dictionary, info)
	}
}
