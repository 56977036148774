import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import BaseDoorsSelect, {
	DOORS_AND_CAPACITY_SELECT_WIDGET_ID,
	DOORS_AND_SEATS_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/doorsSelect/DoorsSelect'
import { CLASSNAME as IA_CLASSNAME } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import BaseWidget from '../baseWidget/BaseWidget'

export { DOORS_AND_CAPACITY_SELECT_WIDGET_ID }
export { DOORS_AND_SEATS_SELECT_WIDGET_ID }

const DoorsSelect = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const { id } = formLineEntity

	const doorsSelectId = `${id}-input`

	return (
		<BaseWidget formLineEntity={formLineEntity} inputId={doorsSelectId}>
			<div className={`${IA_CLASSNAME}__input`}>
				<BaseDoorsSelect
					formLineEntity={formLineEntity}
					onChange={onChange}
					doorsSelectId={doorsSelectId}
				/>
			</div>
		</BaseWidget>
	)
}

DoorsSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(DoorsSelect)
