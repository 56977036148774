import AbstractExtension from 'ima/extension/AbstractExtension'
import { uuid } from 'uuidv4'

export default class PageLoaderExtension extends AbstractExtension {
	static get STATE_KEYS() {
		return Object.freeze({
			IS_LOADING: 'pageLoaderIsLoading',
			LOADING_NOTE: 'pageLoaderLoadingNote'
		})
	}

	constructor() {
		super()

		this._isLoading = false
		this._note = ''
		this._pageLoaderQueue = []
	}

	load() {
		return {
			[PageLoaderExtension.STATE_KEYS.IS_LOADING]: this._isLoading,
			[PageLoaderExtension.STATE_KEYS.LOADING_NOTE]: this._note
		}
	}

	show(note = '') {
		this._isLoading = true
		this._note = note

		const pageLoaderId = uuid()

		this._pageLoaderQueue.push({ id: pageLoaderId, note })

		this.setState({
			[PageLoaderExtension.STATE_KEYS.IS_LOADING]: this._isLoading,
			[PageLoaderExtension.STATE_KEYS.LOADING_NOTE]: this._note
		})

		return pageLoaderId
	}

	hide(pageLoaderId = '') {
		let prevPageLoader
		let actualPageLoader

		this._pageLoaderQueue.forEach((pageLoader) => {
			if (!actualPageLoader) {
				if (pageLoader.id === pageLoaderId) {
					actualPageLoader = pageLoader
				} else {
					prevPageLoader = pageLoader
				}
			}
		})

		// pokud jsem nasel pageLoader podle ID, tak si ulozim seznam bez nej
		if (actualPageLoader) {
			this._pageLoaderQueue = this._pageLoaderQueue.filter(
				(pageLoader) => pageLoader.id !== actualPageLoader.id
			)

			if (prevPageLoader) {
				this._isLoading = true
				this._note = prevPageLoader.note
			}
		}

		// pokud neni defionvani pageLoaderId pro smazani nebo
		// se jej nepodarilo dohledat a nebo
		// je fronta jiz prazdna,
		// tak smazu cely seznam a pageloader zavru
		if (!pageLoaderId || !actualPageLoader || !this._pageLoaderQueue.length) {
			this._pageLoaderQueue = []
			this._isLoading = false
			this._note = ''
		}

		this.setState({
			[PageLoaderExtension.STATE_KEYS.IS_LOADING]: this._isLoading,
			[PageLoaderExtension.STATE_KEYS.LOADING_NOTE]: this._note
		})
	}

	getAllowedStateKeys() {
		return [PageLoaderExtension.STATE_KEYS.IS_LOADING, PageLoaderExtension.STATE_KEYS.LOADING_NOTE]
	}
}
