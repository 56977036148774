import { MERGED_FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'

const applyOptionsForFormLineEntities = (formLineEntities, newOptionsForFormLineEntities) => {
	const chooseOptions = (currentOptions, newOptions) => {
		if (currentOptions && currentOptions.length) {
			return currentOptions
		} else {
			return newOptions
		}
	}

	return formLineEntities.map((formLineEntity) => {
		const mergedFormLine = MERGED_FORM_LINES_IDS[formLineEntity.id]

		if (mergedFormLine) {
			const [basicFormLineIdA, basicFormLineIdB] = mergedFormLine

			const newOptionsForFormLineEntityA =
				newOptionsForFormLineEntities.find((f) => f.id === basicFormLineIdA) || {}

			const newOptionsForFormLineEntityB =
				newOptionsForFormLineEntities.find((f) => f.id === basicFormLineIdB) || {}

			const { id, options = [], extra = {} } = formLineEntity

			return {
				id,
				options: chooseOptions(options, newOptionsForFormLineEntityA.options),
				extra: Object.assign({}, extra, {
					options: chooseOptions(extra.options, newOptionsForFormLineEntityB.options)
				})
			}
		} else {
			const newOptionsForFormLineEntity =
				newOptionsForFormLineEntities.find((f) => f.id === formLineEntity.id) || {}

			const { id, options = [] } = formLineEntity

			return {
				id,
				options: chooseOptions(options, newOptionsForFormLineEntity.options)
			}
		}
	})
}

export default applyOptionsForFormLineEntities
