const RESOURCE = '/premises'
const RESOURCE_PUBLIC = `${RESOURCE}/public`

export default class PremiseResource {
	/**
	 * constructor
	 * @method constructor
	 * @param {RestClient} restClient
	 */
	constructor(restClient) {
		this._restClient = restClient
	}

	/**
	 * Získá a vrátí firmy.
	 *
	 * @method getPremises
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>} Promise, která vrací firmy.
	 */
	getPremises(params, options) {
		return this._restClient.get(RESOURCE, params, options)
	}

	getPremise(id, params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/${id}`, params, options)
	}

	createPremise(params = {}, options = {}) {
		return this._restClient.post(RESOURCE, params, options)
	}

	patchPremise(id, params = {}, options = {}) {
		return this._restClient.patch(`${RESOURCE}/${id}`, params, options)
	}

	getPublicPremises(params = {}, options = {}) {
		return this._restClient.get(RESOURCE_PUBLIC, params, options)
	}

	/**
	 * Stáhne data o firmě (prodejci).
	 *
	 * @method getFilter
	 * @public
	 * @param {Object}        params
	 * @param {Number|String} params.id Id firmy.
	 * @param {Object}        options
	 * @return {Promise<JSONResource>}
	 */
	getPublicPremise(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE_PUBLIC}/${params.id}`, params, options)
	}

	/**
	 * Stáhne data o firmách, která jsou potřebná pro export.
	 *
	 * @method getPremisesExport
	 * @public
	 * @param {Object}        params
	 * @param {Object}        options
	 * @return {Promise<JSONR>}
	 */
	getPremisesExport(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/export`, params, options)
	}

	/**
	 * Stáhne data o historii zmen u firmy.
	 *
	 * @method getPremiseHistory
	 * @public
	 * @param {Object}        params
	 * @param {Object}        options
	 * @return {Promise<JSONR>}
	 */
	getPremiseHistory(id, params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/${id}/history`, params, options)
	}

	/**
	 * Stáhne data o importnich lozich.
	 *
	 * @method getPremiseImportLogs
	 * @public
	 * @param {Object}        params
	 * @param {Object}        options
	 * @return {Promise<JSONR>}
	 */
	getPremiseImportLogs(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/import/logs`, params, options)
	}

	/**
	 * Stáhne count (informace o počtu vozů) k importním logům
	 *
	 * @method getPremiseImportLogsCount
	 * @public
	 * @param {Object}        params
	 * @param {Object}        options
	 * @return {Promise<JSONR>}
	 */
	getPremiseImportLogsCount(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/import/logs/counts`, params, options)
	}

	/**
	 * Stáhne data o importnim logu.
	 *
	 * @method getPremiseImportLog
	 * @public
	 * @param {Object}        params
	 * @param {Object}        options
	 * @return {Promise<JSONR>}
	 */
	getPremiseImportLog(id, params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/import/logs/${id}`, params, options)
	}

	/**
	 * Stáhne data o polozkach importniho logu.
	 *
	 * @method getPremiseImportLogItems
	 * @public
	 * @param {Object}        params
	 * @param {Object}        options
	 * @return {Promise<JSONR>}
	 */
	getPremiseImportLogItems(id, params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/import/logs/${id}/items`, params, options)
	}

	addUserToPremise(premiseId, params = {}, options = {}) {
		return this._restClient.post(`${RESOURCE}/${premiseId}/users`, params, options)
	}

	removeUserFromPremise(premiseId, userId, params = {}, options = {}) {
		return this._restClient.delete(`${RESOURCE}/${premiseId}/users/${userId}`, params, options)
	}

	addAdminToPremise(premiseId, params = {}, options = {}) {
		return this._restClient.post(`${RESOURCE}/${premiseId}/admins`, params, options)
	}

	sendRegistrationEmail(data, params = {}, options = {}) {
		return this._restClient.post(`${RESOURCE}/send_registration_email`, data, params, options)
	}

	/**
	 * Ziska data o penazenke
	 *
	 * @public
	 * @method getPremiseWallet
	 * @param {Object}        params
	 * @param {Object}        options
	 * @return {Promise<JSONR>}
	 */
	getPremiseWallet(id, params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/${id}/wallet`, params, options)
	}

	dissectPremise(id, params = {}, options = {}) {
		return this._restClient.post(`${RESOURCE}/${id}/join`, params, options)
	}

	agreeWithTerms(id, params = {}, options = {}) {
		return this._restClient.patch(`${RESOURCE}/${id}`, params, options)
	}
}
