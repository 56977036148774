import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import { CLASSNAME as CLASSNAME_TABLE } from 'app/component/entitiesTable/EntitiesTable'

import './HistoryTableRowCS.json'
import './HistoryTableRow.less'

const CLASSNAME = 'c-ia-history-table-row'

export default function HistoryTableRow({
	entityData,
	rowCells,
	renderChanges,
	isDetailOpen = false
}) {
	const [isOpen, setIsOpen] = useState(isDetailOpen)

	useEffect(() => {
		setIsOpen(isDetailOpen)
	}, [isDetailOpen])

	const renderOpener = (content) => {
		return (
			<button
				className={classnames({
					[`${CLASSNAME}__opener`]: true,
					[`${CLASSNAME}__opener--opened`]: isOpen,
					[`${CLASSNAME}__opener--closed`]: !isOpen
				})}
				onClick={() => setIsOpen(!isOpen)}
			>
				{content}
			</button>
		)
	}

	return (
		<>
			<tr className={`${CLASSNAME}__row`}>
				{rowCells.map((cell, index) => {
					const result = cell(entityData)
					const [renderedCell, options = {}] = Array.isArray(result) ? result : [result]
					const { isNumber = false, isOpener = false } = options

					return (
						<td
							key={index}
							className={classnames({
								[`${CLASSNAME_TABLE}__cell`]: true,
								[`${CLASSNAME_TABLE}__cell--content`]: true,
								[`${CLASSNAME_TABLE}__cell--number`]: isNumber
							})}
						>
							{isOpener ? renderOpener(renderedCell) : renderedCell}
						</td>
					)
				})}
			</tr>
			{isOpen && (
				<tr className={`${CLASSNAME}__changes`}>
					<td
						className={classnames({
							[`${CLASSNAME_TABLE}__cell`]: true,
							[`${CLASSNAME_TABLE}__cell--content`]: true
						})}
						colSpan={rowCells.length}
					>
						{renderChanges()}
					</td>
				</tr>
			)}
		</>
	)
}

HistoryTableRow.propTypes = {
	entityData: PropTypes.object.isRequired,
	rowCells: PropTypes.array.isRequired,
	renderChanges: PropTypes.func.isRequired,
	isDetailOpen: PropTypes.bool
}

const ChangesGroup = ({ title, children }) => {
	const localize = useLocalize()

	return (
		<fieldset className={`${CLASSNAME}__group`}>
			{title && <legend className={`${CLASSNAME}__title`}>{title}</legend>}
			<table
				className={classnames({
					[`${CLASSNAME}__box`]: true,
					[`${CLASSNAME}__box--with-offset`]: !!title
				})}
			>
				<thead>
					<tr className={`${CLASSNAME}__group-heading`}>
						<th className={`${CLASSNAME}__item ${CLASSNAME}__item--heading`}>
							{localize('HistoryTableRow.item')}
						</th>
						<th className={`${CLASSNAME}__value ${CLASSNAME}__value--heading`}>
							{localize('HistoryTableRow.newValue')}
						</th>
						<th className={`${CLASSNAME}__value ${CLASSNAME}__value--heading`}>
							{localize('HistoryTableRow.oldValue')}
						</th>
					</tr>
				</thead>
				<tbody>{children}</tbody>
			</table>
		</fieldset>
	)
}

ChangesGroup.propTypes = {
	title: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const ChangesGroupContentLine = ({ name, newValue, oldValue }) => {
	return (
		<tr className={`${CLASSNAME}__content-line`}>
			<td className={`${CLASSNAME}__item`}>{name}</td>
			<td className={`${CLASSNAME}__value ${CLASSNAME}__value--new`}>{newValue}</td>
			<td className={`${CLASSNAME}__value ${CLASSNAME}__value--old`}>{oldValue}</td>
		</tr>
	)
}

ChangesGroupContentLine.propTypes = {
	name: PropTypes.string,
	newValue: PropTypes.string,
	oldValue: PropTypes.string
}

HistoryTableRow.ChangesGroup = ChangesGroup
HistoryTableRow.ChangesGroupContentLine = ChangesGroupContentLine
