import React from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import * as FormLines from '@inzeraty/form-lines'
import Wallet from 'app/component/wallet/Wallet'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { SimpleData } from 'app/page/userweb/clientProfile/widgets/simpleData/SimpleDataWidget'

import './WalletWidget.less'

export const IA_WALLET_WIDGET_ID = 'wallet_widget-ia'

const CLASSNAME = 'c-cp-wallet-widget-ia'

const WalletWidget = ({ formLineEntity, walletInfo, premiseInfo }) => {
	const { walletLogin } = walletInfo
	const { label } = formLineEntity

	if (!isNullOrUndefined(walletLogin)) {
		return (
			<SimpleData label={label}>
				<Wallet.Info
					walletInfo={walletInfo}
					premiseInfo={premiseInfo}
					className={`${CLASSNAME}__wallet`}
					showActionButton={false}
				/>
			</SimpleData>
		)
	} else {
		return null
	}
}

WalletWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	walletInfo: PropTypes.shape({
		credit: PropTypes.number,
		walletLogin: PropTypes.string,
		walletUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		serverError: PropTypes.bool
	}),
	premiseInfo: PropTypes.object
}

WalletWidget.defaultProps = {
	walletInfo: DEFAULT_PROPS.OBJECT,
	premiseInfo: DEFAULT_PROPS.OBJECT
}

const selectors = (state) => ({
	premiseInfo: state.premiseEntity,
	walletInfo: state.walletInfo
})

export default React.memo(select(selectors)(WalletWidget))
