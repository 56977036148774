import { USER_HISTORY_BLOCKING_ACTIONS } from 'app/helpers/internalAdmin/InternalAdminHelper'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

export default class IAAdvertReportUrlConvertor {
	static get constants() {
		return Object.freeze({
			HISTORY_PAGE: 'historyPage',
			HISTORY_LIMIT: 'historyLimit',
			HISTORY_SORT: 'historySort',
			ID: 'id'
		})
	}

	static get userHistoryConstants() {
		return Object.freeze({
			USER_HISTORY_PAGE: 'userHistoryPage',
			USER_HISTORY_LIMIT: 'userHistoryLimit',
			USER_HISTORY_SORT: 'userHistorySort',
			ID: 'id'
		})
	}

	static getApiParamsForHistoryTable(routeParams) {
		const { HISTORY_LIMIT, HISTORY_PAGE, HISTORY_SORT, ID } = this.constants
		return InternalAdminUrlConvertor.getTableApiParams(routeParams, {
			defaultSort: ID,
			limitRouteParamName: HISTORY_LIMIT,
			pageRouteParamName: HISTORY_PAGE,
			sortRouteParamName: HISTORY_SORT
		})
	}

	static getApiParamsForUserHistoryTable(routeParams) {
		const {
			USER_HISTORY_LIMIT,
			USER_HISTORY_PAGE,
			USER_HISTORY_SORT,
			ID
		} = this.userHistoryConstants
		let params = InternalAdminUrlConvertor.getTableApiParams(routeParams, {
			defaultSort: ID,
			limitRouteParamName: USER_HISTORY_LIMIT,
			pageRouteParamName: USER_HISTORY_PAGE,
			sortRouteParamName: USER_HISTORY_SORT
		})
		params.action = USER_HISTORY_BLOCKING_ACTIONS.join()
		return params
	}
}
