import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocalize } from 'app/base/componentHelpers'

import './NewFeatureFlag.less'
import './NewFeatureFlagCS.json'

const CLASSNAME = 'c-new-feature-flag'

const NewFeatureFlag = ({ className }) => {
	const localize = useLocalize()

	return (
		<span
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			{localize('NewFeatureFlag.new')}
		</span>
	)
}

NewFeatureFlag.propTypes = {
	className: PropTypes.string
}

export default NewFeatureFlag
