export default class OperatingLeaseService {
	constructor(operatingLeaseResource, operatingLeaseFactory) {
		this._operatingLeaseResource = operatingLeaseResource
		this._operatingLeaseFactory = operatingLeaseFactory
	}

	/**
	 * Vrati dostupne varianty operativniho leasingu.
	 *
	 * @method getOperatingLeaseVariants
	 * @public
	 * @param  {Object} params
	 * @param  {Object} options
	 * @return {Promise<JSON>} Pole variant operativniho leasingu.
	 */
	async getOperatingLeaseVariants(params, options) {
		const response = await this._operatingLeaseResource.getOperatingLeaseVariants(params, options)

		const { results } = response.body
		const variantsData = results.map((variant) =>
			this._operatingLeaseFactory.transformData(variant)
		)

		return {
			operatingLeaseVariantEntities: this._operatingLeaseFactory.createEntityList(variantsData)
		}
	}

	/**
	 * Prida k inzeratu novou variantu operativniho leasingu.
	 *
	 * @method addOperatingLeaseVariant
	 * @public
	 * @param  {Object} params
	 * @param  {Object} options
	 */
	addOperatingLeaseVariant(params, options) {
		return this._operatingLeaseResource.addOperatingLeaseVariant(params, options)
	}

	/**
	 * Upravi variantu operativniho leasingu.
	 *
	 * @method editOperatingLeaseVariant
	 * @public
	 * @param  {Object} params
	 * @param  {Object} options
	 */
	editOperatingLeaseVariant(params, options) {
		return this._operatingLeaseResource.editOperatingLeaseVariant(params, options)
	}

	/**
	 * Smaze variantu operativniho leasingu.
	 *
	 * @method deleteOperatingLeaseVariant
	 * @public
	 * @param  {Number} operatingLeaseVariantId		ID varianty
	 */
	deleteOperatingLeaseVariant(operatingLeaseVariantId) {
		return this._operatingLeaseResource.deleteOperatingLeaseVariant(operatingLeaseVariantId)
	}
}
