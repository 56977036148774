import React, { useRef, useContext } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ImaContext from 'ima/page/context'
import { Icon, Spinner } from '@sznds/react'
import { CLOSE_OUTLINE_24 } from '@sznds/icons'
import { useLocalize } from 'app/base/componentHelpers'
import { SeznamPlayer } from '@inzeraty/components'

import './VideoPreview.less'
import './VideoPreviewCS.json'

const CLASSNAME = 'c-video-prev'

const VideoPreview = React.memo(
	({ video: { id, error, serverId, isLoading, isDeleting, playlist, isProcessing } }) => {
		const { $EventBus } = useContext(ImaContext)
		const ref = useRef(null)
		const localize = useLocalize()

		const textContent =
			!isLoading && !isDeleting && !isProcessing && playlist
				? localize('VideoPreview.firstVideo')
				: ''

		const showPlayer = !isDeleting && !error && playlist

		const renderText = () => (
			<div className={`${CLASSNAME}__text`}>
				{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
				<span tabIndex={0}>{textContent}</span>
			</div>
		)

		const renderRemove = () => (
			<button
				type='button'
				className={`${CLASSNAME}__remove`}
				onClick={() => {
					$EventBus.fire(ref.current, 'removeVideo')
				}}
				aria-label={localize('VideoPreview.removeLabel')}
			>
				<Icon symbol={CLOSE_OUTLINE_24} />
			</button>
		)

		const renderPlayer = () => {
			const content = {
				id: `vkladaniEditaceSeznamPlayer_${id}`,
				source: {
					src: playlist,
					type: 'application/vnd.seznam-cz.spl+json',
					options: {
						scheme: 'https',
						type: 'VOD'
					}
				}
			}

			return <SeznamPlayer content={content} className={`${CLASSNAME}__player`} />
		}

		const renderProcessing = () => {
			let processTitle, processNote

			const loading = isLoading && !serverId
			const processing = (!isLoading && serverId && !playlist) || isProcessing

			if (loading) {
				processTitle = localize('VideoPreview.loading')
				processNote = localize('VideoPreview.loadingNote')
			} else if (isDeleting) {
				processTitle = localize('VideoPreview.removing')
				processNote = localize('VideoPreview.removingNote')
			} else if (processing) {
				processTitle = localize('VideoPreview.processing')
				processNote = localize('VideoPreview.processingNote')
			}

			if (!processTitle && !processNote) {
				return null
			}

			return (
				<div className={`${CLASSNAME}__process`}>
					<Spinner className={`${CLASSNAME}__spinner`} />
					<div className={`${CLASSNAME}__process-content`}>
						<div className={`${CLASSNAME}__process-title`}>{processTitle}</div>
						<div className={`${CLASSNAME}__process-note`}>{processNote}</div>
						{loading && !serverId && (
							<button
								className={`${CLASSNAME}__loader-cancel-btn`}
								onClick={() => {
									$EventBus.fire(ref.current, 'cancelUploadVideo')
								}}
							>
								{localize('VideoPreview.loadingCancel')}
							</button>
						)}
					</div>
				</div>
			)
		}

		const renderPreview = () => (
			<>
				<div className={`${CLASSNAME}__inner`}>
					<div className={`${CLASSNAME}__wrap`}>{renderPlayer()}</div>

					{textContent && renderText()}
					{!isLoading && !isDeleting && renderRemove()}
				</div>
			</>
		)

		return (
			<div
				ref={ref}
				className={classnames({
					[CLASSNAME]: true,
					[`${CLASSNAME}--has-player`]: showPlayer
				})}
			>
				{showPlayer && renderPreview()}
				{renderProcessing()}
			</div>
		)
	}
)

VideoPreview.propTypes = {
	/**
	 * Soubor videa, jehož náhled se pokusí zobrazit.
	 */
	video: PropTypes.object.isRequired,
	/**
	 * Poradi videa.
	 */
	index: PropTypes.number,
	/**
	 * Příznak načítání videa.
	 */
	isLoading: PropTypes.bool,
	/**
	 * Příznak mazání videa.
	 */
	isDeleting: PropTypes.bool
}

VideoPreview.defaultProps = {
	isLoading: false,
	isDeleting: false
}

export default VideoPreview
