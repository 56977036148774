import React from 'react'
import { useLink, useLocalize, useFire } from 'app/base/componentHelpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import PremiseService from 'app/model/premise/PremiseService'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import LocalityHelper, { SEPARATORS } from 'app/component/locality/LocalityHelper'
import Rating from 'app/component/rating/Rating'
import { Format } from '@inzeraty/helpers'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import premisePropTypes from 'app/model/premise/PremisePropTypes'

import './PremiseItem.less'
import './PremiseItemCS.json'

const CLASSNAME = 'c-premise-item'

const PremiseItem = ({ premiseData, ...restProps }) => {
	const { name, logo, locality = {}, seoName, advertsCount, description, reviews, id } = premiseData

	const isPlaceholder = !name
	const link = useLink()
	const localize = useLocalize()
	const fire = useFire()
	const { district, ward, ...desktopLocality } = locality
	const { zip, ...mobileLocality } = desktopLocality

	const desktopAddress = LocalityHelper.localitySentence(desktopLocality, SEPARATORS.COMMA)
	const mobileAddress = LocalityHelper.localitySentence(mobileLocality, SEPARATORS.COMMA)
	const routeParams = {
		[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_SEO_NAME]: seoName,
		[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID]: PremiseService.getPremiseId(
			premiseData
		)
	}
	const sellerUrl = link(ROUTE_NAMES.USERWEB.SELLER, routeParams)

	const handleSellerLinkClick = (event) => {
		event.preventDefault()
		fire('resetCache')
		fire('sellerLinkClick', {
			id,
			routeParams
		})
	}

	const renderName = () => {
		return isPlaceholder ? (
			<div className={`${CLASSNAME}__name-plh`}></div>
		) : (
			<>
				<a
					className={`${CLASSNAME}__stealth-link`}
					href={sellerUrl}
					onClick={handleSellerLinkClick}
				>
					{name}
				</a>
				<span className={`${CLASSNAME}__link`}>{name}</span>
			</>
		)
	}

	const renderLocality = () => {
		if (isPlaceholder) {
			return <div className={`${CLASSNAME}__locality-plh`}></div>
		} else {
			return locality ? (
				<>
					<span className={`${CLASSNAME}__address ${CLASSNAME}__address--desktop`}>
						{desktopAddress}
					</span>
					<span className={`${CLASSNAME}__address ${CLASSNAME}__address--mobile`}>
						{mobileAddress}
					</span>
				</>
			) : null
		}
	}

	const renderLogo = () => {
		if (isPlaceholder) {
			return <div className={`${CLASSNAME}__logo-plh`}></div>
		} else if (logo) {
			return <img className={`${CLASSNAME}__logo`} src={logo} alt={`Logo firmy ${name}`} />
		} else {
			return (
				<SvgIcon icon='NO_IMAGE' className={`${CLASSNAME}__logo ${CLASSNAME}__logo--placeholder`} />
			)
		}
	}

	const renderAdvertsCount = () => {
		return isPlaceholder ? (
			<div className={`${CLASSNAME}__adverts-count-plh`}></div>
		) : (
			<>
				{localize('PremiseItem.advertsCount')}
				<span className={`${CLASSNAME}__count`}>{Format.number(advertsCount)}</span>
			</>
		)
	}

	return (
		<div
			className={classnames(CLASSNAME, isPlaceholder && `${CLASSNAME}--placeholder`)}
			{...restProps}
		>
			<div className={`${CLASSNAME}__section ${CLASSNAME}__section-logo`}>
				<div className={`${CLASSNAME}__logo-wrapper`}>{renderLogo()}</div>
			</div>
			<div className={`${CLASSNAME}__section ${CLASSNAME}__section-info`}>
				{renderName()}
				{reviews && (
					<Rating
						className={`${CLASSNAME}__rating`}
						count={reviews.count}
						stars={reviews.stars}
						reviewLink={`https://www.firmy.cz/detail/${id}#hodnoceni`}
					/>
				)}
				{renderLocality()}
				<div>
					<div className={`${CLASSNAME}__description`}>
						{isPlaceholder ? <div className={`${CLASSNAME}__description-plh`}></div> : description}
					</div>
					<div className={`${CLASSNAME}__adverts-count`}>{renderAdvertsCount()}</div>
				</div>
			</div>
		</div>
	)
}

PremiseItem.propTypes = {
	premiseData: PropTypes.shape(premisePropTypes)
}

export default PremiseItem
