import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import RatingStars from 'app/component/ratingStars/RatingStars'
import { Format } from '@inzeraty/helpers'
import PropTypes from 'prop-types'
import RatingTooltip from './ratingTooltip/RatingTooltip'

const CLASSNAME = 'c-rating'

import './Rating.less'
import './RatingCS.json'

const Rating = (props) => {
	const { count = 0, stars = 0, reviewLink = '', className = '' } = props

	const localize = useLocalize()

	const renderRatingText = () => {
		const tagProps = {}

		if (reviewLink) {
			tagProps.href = reviewLink
			tagProps.target = '_blank'
			tagProps.rel = 'noopener noreferrer'
			tagProps['data-dot'] = 'add-review'
		}

		const TagName = reviewLink ? 'a' : 'div'

		return (
			<TagName
				className={classnames({
					[`${CLASSNAME}__text`]: true,
					[`${CLASSNAME}__text-link`]: !!reviewLink
				})}
				{...tagProps}
			>
				({Format.number(count)} {localize('Rating.reviews')})
			</TagName>
		)
	}

	return (
		<div className={classnames(CLASSNAME, className)}>
			<RatingStars stars={stars} className={`${CLASSNAME}__stars`} />
			<div className={`${CLASSNAME}__text-wrap`}>
				{renderRatingText()}
				<RatingTooltip className={`${CLASSNAME}__tooltip`} />
			</div>
		</div>
	)
}

Rating.propTypes = {
	count: PropTypes.number,
	stars: PropTypes.number,
	reviewLink: PropTypes.string,
	className: PropTypes.string
}

export default React.memo(Rating)
