import React, { useContext } from 'react'
import {
	CHECKER_NORMAL,
	REFRESH,
	BOOK,
	EXTERNAL_LINK_OUTLINE,
	IMAGE,
	BOOKMARK,
	WARNING_FILLED,
	CLOSE_OUTLINE,
	USER_FILLED
} from '@sznds/icons'
import { Format } from '@inzeraty/helpers'
import { useFire } from 'app/base/componentHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import ActionButton from 'app/component/actionButton/ActionButton'
import TableHead from 'app/component/tableHead/TableHead'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import Context from 'ima/page/context'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import IAAdvertListReportedController from './IAAdvertListReportedController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import { STATUS_CONSTANTS, DEACTIVATION_REASON, DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import ForbidUserPopup from '../components/forbidUserPopup/ForbidUserPopup'
import ForbidAdvertPopup from '../components/forbidAdvertPopup/ForbidAdvertPopup'

const { ACTIONS, STATUS } = IAAdvertListReportedController

const getAdvertStatus = (advertEntity) => {
	const { status, deactivationReason, preferredOffer } = advertEntity

	const textAddition = preferredOffer ? ', Pv' : ''

	const STATUSES = {
		[STATUS_CONSTANTS.ACTIVE]: 'Aktivní',
		[STATUS_CONSTANTS.INACTIVE]: 'Neaktivní',
		[STATUS_CONSTANTS.DELETED]: 'Smazaný',
		[STATUS_CONSTANTS.DISABLED]: 'Zakázaný'
	}

	const INACTIVE_DEACTIVATION_REASONS = {
		[DEACTIVATION_REASON.USER]: 'Vypnutý uživatelem',
		[DEACTIVATION_REASON.ADMIN]: 'Vypnutý administrátorem',
		[DEACTIVATION_REASON.EXPIRED]: 'Expirovaný',
		[DEACTIVATION_REASON.IMAGES]: 'Nesplňuje min. počet fotek',
		[DEACTIVATION_REASON.NOT_PAID]: 'Nezaplacený',
		[DEACTIVATION_REASON.MODULES]: 'Překročen limit zaplacených inzerátů'
	}

	return (
		<>
			{deactivationReason
				? INACTIVE_DEACTIVATION_REASONS[deactivationReason] + textAddition
				: STATUSES[status] + textAddition}
		</>
	)
}

export default function IAAdvertListReportedView({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading
}) {
	const { $Dictionary, $Router } = useContext(Context)
	const { rights = [] } = useContext(AdminEntityContext) || {}
	const fire = useFire()

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		if (rights.includes(ADMIN_RIGHTS.ADVERTS_REPORTS_EDIT)) {
			return [
				(entity, actionButtonProps = {}) =>
					entity.reportStatus === STATUS.NEW.value && (
						<ActionButton
							icon={BOOKMARK}
							key='processed'
							title='Označit jako zpracovaný'
							onClick={() => action(ACTIONS.STATUS_PROCESSED, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					entity.reportStatus === STATUS.PROCESSED.value &&
					entity.advert.status !== STATUS_CONSTANTS.DISABLED && (
						<ActionButton
							icon={REFRESH}
							key='new'
							title='Označit jako nový'
							onClick={() => action(ACTIONS.STATUS_NEW, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) => (
					<ActionButton
						icon={BOOK}
						key='detail'
						title='Detail nahlášení'
						href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_REPORT, {
							[InternalAdminUrlConvertor.constants.ADVERT_ID]: entity.advert.id
						})}
						{...actionButtonProps}
					/>
				),
				(entity, actionButtonProps = {}) => {
					const { id, deal_type: dealType } = entity.advert

					const routeName =
						dealType === DEAL_TYPE.OPERATING_LEASE
							? ROUTE_NAMES.INTERNAL_ADMIN.OPERATING_LEASE_PREVIEW
							: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_PREVIEW

					const routeParams = {
						[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: id
					}

					return (
						<ActionButton
							icon={EXTERNAL_LINK_OUTLINE}
							key='nahled'
							title='Náhled inzerátu'
							target='_blank'
							rel='noopener noreferrer'
							href={$Router.link(routeName, routeParams)}
							{...actionButtonProps}
						/>
					)
				},
				(entity, actionButtonProps = {}) => (
					<ActionButton
						icon={IMAGE}
						key='inzerát'
						title='Detail inzerátu'
						href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO, {
							[InternalAdminUrlConvertor.constants.ADVERT_ID]: entity.advert.id
						})}
						{...actionButtonProps}
					/>
				),
				(entity, actionButtonProps = {}) =>
					entity.advert.status !== STATUS_CONSTANTS.ACTIVE &&
					entity.advert.status !== STATUS_CONSTANTS.DISABLED && (
						<ActionButton
							icon={CHECKER_NORMAL}
							key='aktivovat'
							title='Aktivovat inzerát'
							onClick={() => action(ACTIONS.ACTIVATE, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					entity.advert.status === STATUS_CONSTANTS.ACTIVE && (
						<ActionButton
							icon={CLOSE_OUTLINE}
							key='vypnout'
							title='Vypnout inzerát'
							onClick={() => action(ACTIONS.DEACTIVATE, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					entity.advert.status !== STATUS_CONSTANTS.DISABLED &&
					entity.advert.status !== STATUS_CONSTANTS.DELETED && (
						<ActionButton
							icon={WARNING_FILLED}
							key='zakazat'
							title='Zakázat inzerát'
							onClick={() => action(ACTIONS.OPEN_FORBID_ADVERT_POPUP, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) => {
					const {
						enabled = false,
						user_service: { service_enabled: serviceEnabled = false } = {}
					} = entity.advert.user || {}
					const title = enabled && serviceEnabled ? 'Zakázat uživatele' : 'Povolit uživatele'
					return (
						!!entity.advert.user?.id && (
							<ActionButton
								icon={USER_FILLED}
								key='uzivatel'
								title={title}
								onClick={() => action(ACTIONS.OPEN_FORBID_USER_POPUP, [entity])}
								{...actionButtonProps}
							/>
						)
					)
				}
			]
		} else {
			return []
		}
	}

	return (
		<Layout title='Nahlášené inzeráty'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='createDate' sortParam='create_date' isDefaultDescending={true}>
						Datum
					</TableHeadSorter>,
					<TableHead key='advertId'>ID inzerátu</TableHead>,
					<TableHead key='advertName'>Název inzerátu</TableHead>,
					<TableHead key='reporter'>Nahlašovatel</TableHead>,
					<TableHead key='reason'>Důvod</TableHead>,
					<TableHead key='status'>Stav nahlášení</TableHead>,
					<TableHead key='advertStatus'>Stav inzerátu</TableHead>
				]}
				rowCells={[
					({ createDate }) => Format.dateTime(createDate),
					({ advert = {} }) => advert.id,
					({ advert = {} }) => (
						<a
							href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO, {
								[InternalAdminUrlConvertor.constants.ADVERT_ID]: advert.id
							})}
						>
							{advert.name}
						</a>
					),
					({ userEmail, ipAddress }) => (
						<>
							<div>{userEmail}</div>
							<div>{ipAddress}</div>
						</>
					),
					({ reportReason, reportMessage = '' }) => (
						<>
							<div>{IAAdvertListReportedController.getReasonText(reportReason, $Dictionary)}</div>
							<div>{reportMessage}</div>
						</>
					),
					({ reportStatus }) =>
						reportStatus === STATUS.NEW.value ? STATUS.NEW.name : STATUS.PROCESSED.name,
					({ advert = {} }) => getAdvertStatus(advert)
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>
			<ForbidUserPopup />
			<ForbidAdvertPopup />
		</Layout>
	)
}

IAAdvertListReportedView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(), // todo
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool
}
