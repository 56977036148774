import AbstractExtension from 'ima/extension/AbstractExtension'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import FilterToUrlAppConvertor from 'app/model/filter/filterConvertor/FilterToUrlAppConvertor'
import FilterConstants from 'app/model/filter/FilterConstants'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import clearObject from 'app/helpers/clearObject/clearObject'
import ROUTE_NAMES from 'app/base/RouteNames'
import CategoryPickerConstants from 'app/component/pickerButton/CategoryPickerConstants'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import STATE_KEYS from 'app/page/userweb/seller/SellerStateKeys'
import filterUrlParamsCompare from 'app/helpers/filterUrlParamsCompare/FilterUrlParamsCompare'

function callIf(predicate, callback) {
	return (...args) => {
		if (predicate && predicate() && callback) {
			return callback(...args)
		}
	}
}

export const FILTER_CONTEXT = Object.freeze({
	MAIN_MENU: 'mainMenu',
	MAIN_MENU_INLINE: 'mainMenuInline',
	EXTENDED_FILTER: 'extendedFilter',
	EXTENDED_FILTER_INLINE: 'extendedFilterInline',
	ADVERT_LIST_SIDE_PANEL: 'advertListSidePanel',
	ADVERT_LIST_SIDE_PANEL_INLINE: 'advertListSidePanelInline',
	TAGS: 'tags'
})

export const FILTER_CONTEXT_INLINE_MAPPING = Object.freeze({
	[FILTER_CONTEXT.MAIN_MENU]: FILTER_CONTEXT.MAIN_MENU_INLINE,
	[FILTER_CONTEXT.EXTENDED_FILTER]: FILTER_CONTEXT.EXTENDED_FILTER_INLINE,
	[FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL]: FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL_INLINE
})

export default class FiltersExtension extends AbstractExtension {
	static get stateIds() {
		return {
			// priznak, zda se jedna o obycejny prodej nebo operativni leasing
			IS_OPERATING_LEASE: 'isOperatingLease',

			// kategorie vozu - seznam filtru muze byt pro kazdou kategorii jiny
			CATEGORY: 'category',

			// pole s otevrenymi popupy pro filtry
			OPENED_FILTER_POPUPS: 'filterOpenedPopups',

			// otevreny dropdown pro filtr
			OPENED_FILTER_DROPDOWN: 'filterOpenedDropdown',

			// formlines je pole s definicemi jednotlivych filtru.
			FORM_LINE_ENTITIES: 'filterFormLineEntities',
			CHANGE_FILTER: 'changeFilter',

			// URL parametry pro dane nastaveni filtru
			URL_PARAMS: 'filterUrlParams',
			GET_URL_PARAMS: 'getAdvertListUrlParams',

			// metoda pro nacteni cisel na jednotlivych filtrech
			LOAD_FILTERED_ADVERTS_COUNT: 'loadFilteredAdvertsCount',

			// celkova suma dostupnych inzeratu pro dane nastaveni filtru
			FILTERED_ADVERTS_COUNT_TOTAL: 'filteredAdvertsCountTotal',
			IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL: 'isLoadingFilteredAdvertsCountTotal',
			LOAD_FILTERED_ADVERTS_COUNT_TOTAL: 'loadFilteredAdvertsCountTotal',
			SET_FILTERED_ADVERTS_COUNT_TOTAL: 'setFilteredAdvertsCountTotal',

			// presmerovani na jinou stranku v zavislosti na URL parametrech
			REDIRECT: 'filterRedirect'
		}
	}

	constructor(filterService, utils, advertService, advertsCountService, cacheHelper) {
		super()

		this._router = utils.$Router
		this._filterService = filterService
		this._advertService = advertService
		this._advertsCountService = advertsCountService
		this._cacheHelper = cacheHelper

		const callIfFormLinesLoaded = (callback) =>
			callIf(() => !this._areFormLineEntitiesLoading(), callback)

		this.onDownloadModels = callIfFormLinesLoaded(this.onDownloadModels.bind(this))
		this.onOpenFilterPopup = callIfFormLinesLoaded(this.onOpenFilterPopup.bind(this))
		this.onCloseFilterPopup = callIfFormLinesLoaded(this.onCloseFilterPopup.bind(this))
		this.onFilterResetAll = callIfFormLinesLoaded(this.onFilterResetAll.bind(this))
		this.onOpenFilterDropdown = callIfFormLinesLoaded(this.onOpenFilterDropdown.bind(this))
		this.onCloseFilterDropdown = callIfFormLinesLoaded(this.onCloseFilterDropdown.bind(this))

		this._changeFilter = callIfFormLinesLoaded(this._changeFilter.bind(this))
		this._loadFilteredAdvertsCount = /*callIfFormLinesLoaded*/ this._loadFilteredAdvertsCount.bind(
			this
		)
		this._loadFilteredAdvertsCountTotal = /*callIfFormLinesLoaded*/ this._loadFilteredAdvertsCountTotal.bind(
			this
		) //TODO callIfFormLinesLoaded
		this._setFilteredAdvertsCountTotal = callIfFormLinesLoaded(
			this._setFilteredAdvertsCountTotal.bind(this)
		)
		this._getAdvertListUrlParamsForFilter = callIfFormLinesLoaded(
			this._getAdvertListUrlParamsForFilter.bind(this)
		)
		this._redirect = callIfFormLinesLoaded(this._redirect.bind(this))
	}

	load() {
		return {
			[FiltersExtension.stateIds.OPENED_FILTER_POPUPS]: [],
			[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN]: {},
			[FiltersExtension.stateIds.CHANGE_FILTER]: this._changeFilter,
			[FiltersExtension.stateIds.GET_URL_PARAMS]: this._getAdvertListUrlParamsForFilter,

			[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT]: this._loadFilteredAdvertsCount,

			[FiltersExtension.stateIds
				.FILTERED_ADVERTS_COUNT_TOTAL]: this._getFilteredAdvertsCountTotal(),
			[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL]: false,
			[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT_TOTAL]: this
				._loadFilteredAdvertsCountTotal,
			[FiltersExtension.stateIds.SET_FILTERED_ADVERTS_COUNT_TOTAL]: this
				._setFilteredAdvertsCountTotal,

			[FiltersExtension.stateIds.REDIRECT]: this._redirect
		}
	}

	update() {
		const { [FiltersExtension.stateIds.CATEGORY]: categoryPromise } = this.getState()

		const filterUrlParamsPromise = this._getFilterUrlParams()

		return {
			[FiltersExtension.stateIds.URL_PARAMS]: filterUrlParamsPromise,
			[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]: this._loadFilteredAdvertsCountTotal(
				filterUrlParamsPromise,
				categoryPromise
			).then((total) => total)
		}
	}

	async _getFilterUrlParams() {
		const {
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntitiesPromise
		} = this.getState()

		return this.getAdvertListUrlParams(await filterFormLineEntitiesPromise)
	}

	/**
	 * Vrátí názvy klíčů stavu, které může extension nastavovat.
	 *
	 * @public
	 * @return {Array<String>} Názvy klíčů stavu, které může extension nastavovat.
	 */
	getAllowedStateKeys() {
		return [
			FiltersExtension.stateIds.IS_OPERATING_LEASE,

			FiltersExtension.stateIds.CATEGORY,

			FiltersExtension.stateIds.OPENED_FILTER_POPUPS,

			FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN,

			FiltersExtension.stateIds.FORM_LINE_ENTITIES,
			FiltersExtension.stateIds.CHANGE_FILTER,

			FiltersExtension.stateIds.URL_PARAMS,
			FiltersExtension.stateIds.GET_URL_PARAMS,

			FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT,

			FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL,
			FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL,
			FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT_TOTAL,
			FiltersExtension.stateIds.SET_FILTERED_ADVERTS_COUNT_TOTAL,

			FiltersExtension.stateIds.REDIRECT
		]
	}

	/**
	 * Vrátí formLineEntities filtru pro dané parametry.
	 *
	 * @method getFormLineEntities
	 * @param {Object} params
	 * @param {Number} params.category_id Id kategorie.
	 * @return {Promise<Array<inzeraty/form-lines/Entity>>} FormLineEntities pro filtr.
	 */
	getFormLineEntities(params) {
		const routeParams = this.getRouteParams()

		// vrati parametry znacek pro filter_page z URL. Takze nam pak filter_page vrati
		// filtr pro znacky a modely uz rovnou s nactenymi modely pro danou znacku.
		// Usetrime si jedno volani na server.
		const getBrandFromUrlParams = (routeParams) => {
			const { BRANDS_MODELS, BRAND_NAME } = AdvertListUrlConvertor.constants.URL_APP_PARAMS
			// TODO ted pouzivame natvrdo AdvertListUrlConvertor, filtry ale chceme pouzivat
			// i na strance prodejce. Doresit v budoucnu - asi se prepinat podle aktualni routy
			// na prislusny urlConvertor. Nebo lepe vytvorit v danych url konvertorech prislusne
			// funkce.

			const {
				BRANDS_MODELS_SEO,
				BRANDS_MODELS_VALUES
			} = AdvertListUrlConvertor.constants.URL_API_PARAMS

			const { [BRANDS_MODELS]: brandsModels, [BRAND_NAME]: brandName } = routeParams

			if (brandName) {
				return {
					[BRANDS_MODELS_SEO]: brandName
				}
			} else if (brandsModels) {
				return {
					[BRANDS_MODELS_VALUES]: brandsModels
				}
			} else {
				return {}
			}
		}

		const newParams = Object.assign({}, params, getBrandFromUrlParams(routeParams))

		return this._filterService.getFormLineEntities(newParams, routeParams)
	}

	async setFormLineEntitiesOnDealTypeChange(
		newFormLineEntities = [],
		isOperatingLeaseSelected = false
	) {
		const {
			[FiltersExtension.stateIds.CATEGORY]: categoryEntity,
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: previousFilterFormLineEntities = []
		} = this.getState()

		this.setState({
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLeaseSelected
		})

		// mergneme s predchozim stavem
		const filterFormLineEntities = newFormLineEntities.map((formLine) => {
			const previousFormLine = previousFilterFormLineEntities.find((f) => f.id === formLine.id)

			if (previousFormLine) {
				return previousFormLine
			} else {
				// vracime nove formliny pro zvoleny typ prodeje, akorat musime u nich vyresetovat
				// nastavene hodnoty. Ty se totiz standardne berou z URL, ale v popupu se vsemi
				// filtry chceme URL ignorovat - uz to nedava smysl, popup si po otevreni zije
				// svym zivotem.
				const { extra: { emptyValue } = {} } = formLine

				return Object.assign({}, formLine, { value: emptyValue })
			}
		})

		const filterUrlParams = this.getAdvertListUrlParams(filterFormLineEntities)
		const total = await this._loadFilteredAdvertsCountTotal(filterUrlParams, categoryEntity)

		this.setState({
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntities,
			[FiltersExtension.stateIds.URL_PARAMS]: filterUrlParams,
			[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]: total
		})
	}

	/**
	 * Testuje, jestli formlines pro filtry se stale nacitaji nebo uz ne.
	 *
	 * @method _areFormLineEntitiesLoading
	 * @private
	 * @return {Boolean} Vraci true, kdyz se data stale nacitaji.
	 */
	_areFormLineEntitiesLoading() {
		const { [FiltersExtension.stateIds.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()

		return formLineEntities instanceof Promise
	}

	/**
	 * Nacte jednotlive pocty inzeratu pro zobrazeni v formularich filtru.
	 *
	 * @method _loadFilteredAdvertsCount
	 * @private
	 * @param {String} filterName				Jmeno filtru, pro ktery se nacte pocet inzeratu.
	 * @param {Object} filterUrlParamsOverride 	Vlastni paramety pro URL.
	 * @return {Promise<Object>}
	 */
	async _loadFilteredAdvertsCount(filterName, filterUrlParamsOverride, cachedData = {}) {
		if (filterName !== FilterConstants.filterIds.EXTENDED_FILTER_ID) {
			const {
				[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease = false,
				[FiltersExtension.stateIds.URL_PARAMS]: filterUrlParams,
				[FiltersExtension.stateIds.CATEGORY]: category
			} = this.getState()

			const routeName = this._router.getCurrentRouteInfo().route.getName()

			const { id: categoryId } = (await category) || {}

			const appParams = filterUrlParamsOverride
				? Object.assign({}, filterUrlParamsOverride)
				: Object.assign({}, (await filterUrlParams) || {})

			const apiParams = Object.assign(
				{},
				AdvertListUrlConvertor.getApiParams(appParams, routeName),
				{
					[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId,
					[AdvertListUrlConvertor.constants.URL_API_PARAMS.FILTER_NAME]: filterName,
					[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease
				}
			)

			if (
				[ROUTE_NAMES.USERWEB.SELLER, ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES].includes(
					routeName
				)
			) {
				const { [STATE_KEYS.PREMISE_ENTITY]: premisePromise = {} } = this.getState()
				const { id: premiseId } = (await premisePromise) || {}
				apiParams[SellerUrlConvertor.constants.URL_API_PARAMS.SELLER_ID] = premiseId
			}

			const { counts, apiParams: cachedApiParams = {} } = cachedData

			const cachedApiParamsClone = Object.assign({}, cachedApiParams)

			// odmazani zbytecnych URL parametru
			delete apiParams[UrlConvertor.constants.URL_API_PARAMS.LIMIT]
			delete apiParams[UrlConvertor.constants.URL_API_PARAMS.OFFSET]
			delete apiParams[UrlConvertor.constants.URL_API_PARAMS.SORT]

			delete cachedApiParamsClone[UrlConvertor.constants.URL_API_PARAMS.LIMIT]
			delete cachedApiParamsClone[UrlConvertor.constants.URL_API_PARAMS.OFFSET]
			delete cachedApiParamsClone[UrlConvertor.constants.URL_API_PARAMS.SORT]

			if (
				counts &&
				cachedApiParamsClone &&
				filterUrlParamsCompare(cachedApiParamsClone, apiParams)
			) {
				return { counts, filterName }
			} else {
				return await this._advertsCountService.getFilteredAdvertsCount(apiParams)
			}
		}
	}

	/**
	 * Nacte aktualni celkovy pocet inzeratu z API.
	 *
	 * @method _loadFilteredAdvertsCountTotal
	 * @private
	 * @return {Promise<Number>}
	 */
	async _loadFilteredAdvertsCountTotal(filterUrlParamsPromise, categoryPromise) {
		const {
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease = false
		} = this.getState()

		const filterUrlParams = (await filterUrlParamsPromise) || {}
		const { id, advertsCount } = (await categoryPromise) || {}

		const routeName = this._router.getCurrentRouteInfo().route.getName()

		const apiParams = Object.assign(
			AdvertListUrlConvertor.getApiParams(filterUrlParams, routeName),
			{
				[UrlConvertor.constants.URL_API_PARAMS.LIMIT]: 0,
				[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: id,
				[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease
			}
		)

		if (
			[ROUTE_NAMES.USERWEB.SELLER, ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES].includes(
				routeName
			)
		) {
			const { [STATE_KEYS.PREMISE_ENTITY]: premisePromise = {} } = this.getState()
			const { id: premiseId } = (await premisePromise) || {}
			apiParams[SellerUrlConvertor.constants.URL_API_PARAMS.SELLER_ID] = premiseId
		}

		const {
			[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN]: openedFilterDropdown
		} = this.getState()

		// pokud nacitame celkovou sumu a mame otevreny dropdown, tak nechceme
		// zobrazovat nacitajici tecky na hlavnim CTA tlacitku, pro uzivatele to
		// pusobi rusive. Navic celkovou sumu stejne menime az ve chvili, kdy
		// dropdown zavirame.
		const loadTotalSilently = openedFilterDropdown ? !!openedFilterDropdown.filterId : false

		this.setState({
			[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL]: !loadTotalSilently
		})

		try {
			return await this._advertService.getAdvertsTotalCount(apiParams, {}, false)
		} catch (e) {
			const {
				[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]: currentTotal
			} = this.getState()

			// pokud nastane chyba na API, vratime puvodni pocet nebo pocet z CategoryEntity
			return currentTotal >= 0 ? currentTotal : advertsCount
		} finally {
			this.setState({
				[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL]: false
			})
		}
	}

	/**
	 * Nacte a vrati celkovy pocet inzeratu.
	 *
	 * @method _getFilteredAdvertsCountTotal
	 * @private
	 * @return {Promise<Number>}
	 */
	async _getFilteredAdvertsCountTotal() {
		const {
			[FiltersExtension.stateIds.URL_PARAMS]: filterUrlParams,
			[FiltersExtension.stateIds.CATEGORY]: categoryPromise
		} = this.getState()

		return await this._loadFilteredAdvertsCountTotal(await filterUrlParams, categoryPromise)
	}

	/**
	 * Nastavi novou celkovou sumu dostupnych inzeratu.
	 *
	 * @method _setFilteredAdvertsCountTotal
	 * @private
	 * @param {String} newTotal	Nova celkova suma.
	 */
	_setFilteredAdvertsCountTotal(newTotal) {
		this.setState({
			[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]: newTotal
		})
	}

	/**
	 * Provede rozšíření fitlru o modely určité značky.
	 *
	 * @method onDownloadModels
	 * @public
	 * @param {Number} options.brandId Id značky.
	 */
	onDownloadModels({ brandId }) {
		const { [FiltersExtension.stateIds.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()
		const callIfFormLinesLoaded = (callback) =>
			callIf(() => !this._areFormLineEntitiesLoading(), callback)

		this._filterService.getFilterWithModels(formLineEntities, brandId).then(
			callIfFormLinesLoaded((formLineEntitiesWithModels) => {
				// pre pomale pripojenie ak uzivatel klikne na POKRACOVAT a
				// modely este nie su nacitane, ziskame value z aktualneho state,
				// aby vybrany model nezmizol po donacitani modelov
				const { [FiltersExtension.stateIds.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()
				const { value } = formLineEntities.find(
					(formLineEntity) => formLineEntity.id === FilterConstants.formLineIds.BRAND_MODEL
				)

				this.setState({
					[FiltersExtension.stateIds
						.FORM_LINE_ENTITIES]: this._filterService.updateFormLineEntities(
						formLineEntitiesWithModels,
						[
							{
								id: FilterConstants.formLineIds.BRAND_MODEL,
								value
							}
						]
					)
				})
			})
		)
	}

	/**
	 * Přidá popup do seznamu otevřených popupů.
	 *
	 * @method onOpenFilterPopup
	 * @public
	 * @param {String} data.filterId Id filtru, ke kterému patří daný popup.
	 * @param {String} data.filterPopupType Typ filtru, který se má otevřít.
	 * @param {String} data.filterButtonType Typ tlačítka, které se má vykreslit.
	 * @param {Boolean} data.updateUrlParams Příznak, jestli se má při zavření/restartu upravit
	 * 										 URL adresa (respektive parametry pro filtrování).
	 */
	onOpenFilterPopup(data) {
		const {
			[FiltersExtension.stateIds.OPENED_FILTER_POPUPS]: openedFilterPopups = []
		} = this.getState()

		this.setState({
			[FiltersExtension.stateIds.OPENED_FILTER_POPUPS]: [...openedFilterPopups, data]
		})
	}

	/**
	 * Odebere popup ze seznamu otevřených popupů.
	 *
	 * @method onCloseFilterPopup
	 * @public
	 * @param {String} options.filterId Id filtru, ke kterému patří daný popup.
	 */
	onCloseFilterPopup({ filterId }) {
		this._closeLastFilterPopup(filterId)
	}

	/**
	 * Odebere popup ze seznamu otevřených popupů. Dle konfigurace popupu může provést
	 * přesměrování s novými parametry pro filtrování.
	 *
	 * @method onCloseFilterPopupAndRedirect
	 * @public
	 * @param {String} options.filterId Id filtru, ke kterému patří daný popup.
	 */
	onCloseFilterPopupAndRedirect({ filterId }) {
		const popup = this._closeLastFilterPopup(filterId)

		if (popup && popup.updateUrlParams) {
			this._redirect()
		}
	}

	/**
	 * Nastavi objekt drzici aktualne otevreny dropdown.
	 *
	 * @method onOpenFilterDropdown
	 * @public
	 * @param {String} data.filterId Id filtru, ke kterému patří daný dropdown.
	 * @param {Boolean} data.updateUrlParams Příznak, jestli se má při zavření/restartu upravit
	 * 										 URL adresa (respektive parametry pro filtrování).
	 */
	onOpenFilterDropdown(data) {
		const {
			[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN]: openedFilterDropdown
		} = this.getState()

		const oneDropdownAlreadyOpened = openedFilterDropdown && openedFilterDropdown.filterId

		if (!oneDropdownAlreadyOpened) {
			// soucasne muze byt otevreny jen jeden dropdown
			this.setState({
				[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN]: Object.assign({}, data)
			})
		}
	}

	/**
	 * Vynuluje objekt drzici aktualne otevreny dropdown.
	 *
	 * @method onCloseFilterDropdown
	 * @public
	 * @param {String} options.filterId Id filtru, ke kterému patří daný dropdown.
	 */
	onCloseFilterDropdown({ filterId }) {
		this._closeFilterDropdown(filterId)
	}

	/**
	 * Odebere popup se všemi filtry (tzv. rozšířený filtr) ze seznamu otevřených popupů a
	 * vyresetuje hodnoty všech formLines.
	 * Dle konfigurace popupu může provést přesměrování s novými parametry pro filtrování.
	 *
	 * @method onFilterResetAll
	 * @public
	 */
	async onFilterResetAll() {
		const { [FiltersExtension.stateIds.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()

		formLineEntities.forEach(({ id, extra }) => this._changeFilter(id, extra.emptyValue))

		const {
			[FiltersExtension.stateIds.URL_PARAMS]: filterUrlParams,
			[FiltersExtension.stateIds.CATEGORY]: category
		} = this.getState()

		const total = await this._loadFilteredAdvertsCountTotal(await filterUrlParams, category)

		this._setFilteredAdvertsCountTotal(total)
	}

	/**
	 * Odebere poslední popup splňující daná kritéria ze seznamu otevřených popupů.
	 *
	 * @method _closeLastFilterPopup
	 * @private
	 * @param {String} filterId Id filtru, ke kterému patří daný popup.
	 * @return {Object} Definice zavřeného popupu.
	 */
	_closeLastFilterPopup(filterId) {
		const {
			[FiltersExtension.stateIds.OPENED_FILTER_POPUPS]: openedFilterPopups = []
		} = this.getState()

		const [lastPopup] = openedFilterPopups.filter((popup) => popup.filterId === filterId).slice(-1)

		const indexLastPopup = openedFilterPopups.findIndex((p) => p === lastPopup)

		if (indexLastPopup >= 0) {
			const updatedOpenedFilterPopups = [
				...openedFilterPopups.slice(0, indexLastPopup),
				...openedFilterPopups.slice(indexLastPopup + 1)
			]

			this.setState({
				[FiltersExtension.stateIds.OPENED_FILTER_POPUPS]: updatedOpenedFilterPopups
			})

			return lastPopup
		}
	}

	/**
	 * Vynuluje objekt drzici aktualne otevreny dropdown.
	 *
	 * @method _closeFilterDropdown
	 * @private
	 * @param {String} filterId Id filtru, ke kterému patří daný dropdown.
	 * @return {Object} Definice zavřeného dropdownu.
	 */
	_closeFilterDropdown(filterId) {
		const {
			[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN]: openedFilterDropdown
		} = this.getState()

		const dropdown = openedFilterDropdown && openedFilterDropdown.filterId === filterId

		if (dropdown) {
			this.setState({
				[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN]: {}
			})

			return dropdown
		}
	}

	/**
	 * Aplikuje změny na formLineEntities pro filtry.
	 *
	 * @method _changeFilter
	 * @public
	 * @param {String}  	id    		Identifikátor formLine.
	 * @param {Any}     	value 		Nová hodnota pro danou formLine.
	 * @param {Function}    callback 	Callback s nove upravenou formLine.
	 */
	_changeFilter(id, value, callback = () => {}) {
		const { [FiltersExtension.stateIds.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()

		const updatedFormLineEntities = this._filterService.updateFormLineEntities(formLineEntities, [
			{
				id,
				value
			}
		])

		this.setState({
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: updatedFormLineEntities,
			[FiltersExtension.stateIds.URL_PARAMS]: this.getAdvertListUrlParams(updatedFormLineEntities)
		})

		callback(updatedFormLineEntities.find((formLineEntity) => formLineEntity.id === id))
	}

	_getAdvertListUrlParamsForFilter(formLineEntity = {}) {
		const { [FiltersExtension.stateIds.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()
		const { id, value } = formLineEntity

		const updatedFormLineEntities = this._filterService.updateFormLineEntities(formLineEntities, [
			{
				id,
				value
			}
		])

		return this.getAdvertListUrlParams(updatedFormLineEntities)
	}

	getAdvertListUrlParams(formLineEntities = []) {
		const routeName = this._router.getCurrentRouteInfo().route.getName()
		const isSellerRoute = [
			ROUTE_NAMES.USERWEB.SELLER,
			ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
		].includes(routeName)
		const params = FilterToUrlAppConvertor.getFilterAdvertListUrlParams(
			formLineEntities,
			isSellerRoute
		)
		return Object.assign({}, params, UrlConvertor.getAppSorting(this.getRouteParams()))
	}

	_getSelectedCategory() {
		const {
			params: { [SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: selectedCategoryParam }
		} = this._router.getCurrentRouteInfo()

		return selectedCategoryParam ? selectedCategoryParam : CategoryPickerConstants.defaultValue
	}

	/**
	 * Přesměřuje stránku samu na sebe s novými parametry pro filtry.
	 *
	 * @method _redirect
	 * @public
	 */
	_redirect() {
		const routeName = this._router.getCurrentRouteInfo().route.getName()

		let newRouteName
		let newUrlParams

		const {
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease = false,
			[FiltersExtension.stateIds.URL_PARAMS]: newFilterUrlParams
		} = this.getState()

		const isSeller = [
			ROUTE_NAMES.USERWEB.SELLER,
			ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
		].includes(routeName)

		if (isSeller) {
			const routeParams = Object.assign({}, this.getRouteParams())
			for (const appParam in AdvertListUrlConvertor.constants.URL_APP_PARAMS) {
				delete routeParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS[appParam]]
			}

			const { [UrlConvertor.constants.URL_APP_PARAMS.PAGE]: page } = routeParams

			if (page) {
				delete routeParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE]
			}

			newRouteName = isOperatingLease
				? ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
				: ROUTE_NAMES.USERWEB.SELLER
			newUrlParams = Object.assign(
				{},
				UrlConvertor.getAppSorting(this.getRouteParams()),
				routeParams,
				newFilterUrlParams
			)
		} else {
			const {
				[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categoryName
			} = this.getRouteParams()

			newRouteName = isOperatingLease
				? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
				: ROUTE_NAMES.USERWEB.ADVERT_LIST
			newUrlParams = Object.assign(
				{},
				newFilterUrlParams,
				{
					[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categoryName
				},
				UrlConvertor.getAppSorting(this.getRouteParams())
			)
		}

		const currentUrl = this._router.getUrl()
		const newUrl = this._router.link(newRouteName, clearObject(newUrlParams))

		if (newUrl !== currentUrl) {
			this._router.redirect(newUrl)
		}
	}
}
