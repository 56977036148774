
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertDetailOperatingLeaseMeta'] = {
  title: function(d) { return "Operativní leasing " + d.FULL_ADVERT_NAME + " od " + d.PRICE + " " + d.CURRENCY + "/měsíc (bez DPH) | Sauto.cz"; },
  description: function(d) { return d.FULL_ADVERT_NAME + " na operativní leasign od " + d.PRICE + " " + d.CURRENCY + "/měsíc (bez DPH) na Sauto.cz ⭐ Parametry vozu: " + d.VEHICLE_PARAMS + ". ⭐ Parametry operáku: " + d.OPER_LEASE_PARAMS + "."; },
  availabilityLabel: function(d) { return "termín dodání"; },
  annualDistanceLabel: function(d) { return "nájezd od"; },
  periodLabel: function(d) { return "doba od"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	