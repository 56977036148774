import React, { useCallback, useContext } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from '@sznds/react'
import { UserService, UserPropTypes } from '@inzeraty/models'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import MySavedSearchAddButton from '../addButton/MySavedSearchAddButton'
import Context from 'ima/page/context'
import { ASSIGN_EMAIL_EVENTS } from '@inzeraty/components'

import './MySavedSearchAddBanner.less'
import './MySavedSearchAddBannerCS.json'
import 'app/base/AssignEmailReasonsCS.json'

const CLASSNAME = 'c-my-saved-search-add-banner'

const MySavedSearchAddBanner = ({
	className,
	isPlaceholderShown,
	userSelf,
	onMySavedSearchAddClick = DEFAULT_PROPS.FUNCTION,
	onLoginClick = DEFAULT_PROPS.FUNCTION
}) => {
	const localize = useLocalize()
	const { $Dispatcher } = useContext(Context)

	const isUserLoggedIn = UserService.isUserLogged(userSelf)

	const checkAssigneEmail = useCallback(() => {
		$Dispatcher.fire(ASSIGN_EMAIL_EVENTS.SHOW_DIALOG, {
			reason: localize('AssignEmailReasons.addMySaveSearch'),
			onSuccess: onMySavedSearchAddClick
		})
	}, [$Dispatcher, onMySavedSearchAddClick])

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<div>
				<span
					className={classnames({
						[`${CLASSNAME}__heading`]: true,
						[`${CLASSNAME}__heading--placeholder`]: isPlaceholderShown
					})}
				>
					{isPlaceholderShown
						? null
						: isUserLoggedIn
						? localize('MySavedSearchAddBanner.heading')
						: localize('MySavedSearchAddBanner.headingNotLoggedIn')}
				</span>
				<div
					className={classnames({
						[`${CLASSNAME}__description`]: true,
						[`${CLASSNAME}__description--placeholder`]: isPlaceholderShown
					})}
				>
					{isPlaceholderShown
						? null
						: isUserLoggedIn
						? localize('MySavedSearchAddBanner.description')
						: localize('MySavedSearchAddBanner.descriptionNotLoggedIn')}
				</div>
			</div>

			{isPlaceholderShown ? (
				<div className={`${CLASSNAME}__button ${CLASSNAME}__button--placeholder`} />
			) : isUserLoggedIn ? (
				<MySavedSearchAddButton className={`${CLASSNAME}__button`} onClick={checkAssigneEmail} />
			) : (
				<Button
					className={`${CLASSNAME}__button`}
					size='small'
					text={localize('MySavedSearchAddBanner.login')}
					onClick={onLoginClick}
				/>
			)}
		</div>
	)
}

MySavedSearchAddBanner.propTypes = {
	className: PropTypes.string,
	isPlaceholderShown: PropTypes.bool,
	userSelf: PropTypes.shape(UserPropTypes),
	onMySavedSearchAddClick: PropTypes.func,
	onLoginClick: PropTypes.func
}

export default React.memo(MySavedSearchAddBanner)
