import React from 'react'
import Select from 'app/component/select/Select'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import { useLocalize } from 'app/base/componentHelpers'
import { Icon } from '@sznds/react'
import SimpleTooltip from 'app/component/simpleTooltip/SimpleTooltipView'
import { TRASH_FILLED_24 } from '@sznds/icons'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './SoftwareKeyWidget.less'
import './SoftwareKeyWidgetCS.json'

export const SOFTWARE_KEY_WIDGET_ID = 'software_key_widget'

const CLASSNAME = 'c-cp-software-key-widget'

function SoftwareKeyWidget({ formLineEntity, onChange, section }) {
	const { label, id, value: formValues, extra: { values } = {}, disabled } = formLineEntity
	const localize = useLocalize()

	const getUpdatedFormlineValue = (value, index) => {
		const newValue = [...formValues]
		newValue[index] = value
		return newValue
	}

	const removeFormLineValue = (index) => {
		const newValue = [...formValues]
		newValue.splice(index, 1)
		return newValue
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__description-box`}>
				<span className={`${CLASSNAME}__description`}>{label}</span>
			</div>
			<div className={`${CLASSNAME}__content`}>
				{formValues.map((formValue, index) => {
					const idProps = AutoComplete.getIdsPropsHelper(`${id}-${index}`)

					return (
						<div className={`${CLASSNAME}__input-row`} key={`${formValue}-${index}`}>
							<label
								className={`${CLASSNAME}__accessible-label`}
								id={idProps.labelId}
								htmlFor={idProps.inputId}
							>
								{localize('SoftwareKeyWidget.chooseKey')}
							</label>
							<div className={`${CLASSNAME}__input`}>
								<Select
									onSelect={({ value }) => {
										onChange({
											id,
											value: getUpdatedFormlineValue(value, index),
											section
										})
									}}
									size='small'
									disabled={Boolean(disabled)}
									items={values}
									selectedItem={values.find(({ value }) => value === formValue) || null}
									{...idProps}
								/>
							</div>
							<SimpleTooltip
								description={localize('SoftwareKeyWidget.removeKey')}
								ariaHidden={true}
								align={SimpleTooltip.ALIGN.CENTER}
								renderContent={(className) => (
									<button
										onClick={() => {
											onChange({
												id: formLineEntity.id,
												value: removeFormLineValue(index),
												section
											})
										}}
										type='button'
										className={`${className} ${CLASSNAME}__action-icon`}
									>
										<span className={`${CLASSNAME}__accessible-label`}>
											{localize('SoftwareKeyWidget.removeKey')}
										</span>
										<Icon symbol={TRASH_FILLED_24} className={`${CLASSNAME}__icon`} />
									</button>
								)}
							/>
						</div>
					)
				})}
				<button
					onClick={() => {
						const newValue = values[0].value
						const newIndex = formValues.length

						onChange({
							id,
							value: getUpdatedFormlineValue(newValue, newIndex),
							section
						})
					}}
					type='button'
					className={`${CLASSNAME}__action`}
				>
					{localize('SoftwareKeyWidget.addKey')}
				</button>
			</div>
		</div>
	)
}

SoftwareKeyWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func.isRequired,
	section: PropTypes.string
}

export default SoftwareKeyWidget
