import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import { Surface } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import HISTORY_STATE_KEYS from '../../HistoryStateKeys'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../../../AbstractNewAdvertStateKeys'
import FORM_STATUS_CODES from '../../../component/formStatusMessage/FormStatusCodes'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'
import { getErrorDictionary } from './HistoryFormErrors'
import FormStatusMessage from '../../../component/formStatusMessage/FormStatusMessage'
import FormStatusMessageWrap from '../../../component/formStatusMessage/FormStatusMessageWrap'
import BasicSelectWidget, {
	BASIC_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/basicInfo/components/widgets/basicSelect/BasicSelectWidget'
import MonthYearSelectWidget, {
	GUARANTEE_MONTH_AND_YEAR_SELECT_WIDGET_ID
} from '../widgets/monthYearSelect/MonthYearSelect'
import YesNoNotStatedWidget, {
	YES_NO_NOT_STATED_WIDGET_ID
} from '../widgets/yesNoNotStated/YesNoNotStatedWidget'
import Cebia from '../cebia/Cebia'
import FormStepper from '../../../component/stepper/FormStepperView'
import HistoryPlaceholders from '../placeholders/HistoryPlaceholders'
import PropTypes from 'prop-types'
import AdvertPropTypes from 'app/model/advert/AdvertPropTypes'
import NewAdvertSectionHeading from 'app/component/newAdvertSectionHeading/NewAdvertSectionHeading'

import './HistoryFormCS.json'
import './HistoryForm.less'

const CLASSNAME = 'c-history-form'

const { SZNDS_WIDGET_BY_ID, Form } = FormLines

const WIDGETS_BY_ID = Object.freeze(
	Object.assign(
		{
			[BASIC_SELECT_WIDGET_ID]: BasicSelectWidget,
			[GUARANTEE_MONTH_AND_YEAR_SELECT_WIDGET_ID]: MonthYearSelectWidget,
			[YES_NO_NOT_STATED_WIDGET_ID]: YesNoNotStatedWidget
		},
		SZNDS_WIDGET_BY_ID
	)
)

const HistoryForm = (props) => {
	const localize = useLocalize()

	const {
		formLineEntities = [],
		isFormBeingSubmitted = false,
		submitForm,
		scrollPageToTop,
		formStatus,
		setFormStatus,
		advertEntity,
		cebiaVerified,
		submitSaveCertForm
	} = props

	const ERROR_DICTIONARY = getErrorDictionary(localize)

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			formLineEntities,
			{
				onChange
			},
			WIDGETS_BY_ID
		)
	}

	const renderSubmitButton = () => {
		return <FormStepper loading={isFormBeingSubmitted} />
	}

	const renderFormContent = (renderWidgets, renderSubmitButton) => {
		return (
			<>
				<Surface surface={5} className={`${CLASSNAME}__form`}>
					<FormStatusMessageWrap>{renderFormStatusMessage()}</FormStatusMessageWrap>

					<div className={`${CLASSNAME}__section`}>
						<NewAdvertSectionHeading>
							{localize('HistoryForm.historySection')}
						</NewAdvertSectionHeading>

						{formLineEntities.length > 0 ? renderWidgets() : <HistoryPlaceholders />}
					</div>
				</Surface>

				<Cebia
					verified={cebiaVerified}
					submitSaveCertForm={submitSaveCertForm}
					advertEntity={advertEntity}
				/>

				<div className={`${CLASSNAME}__submit`}>{renderSubmitButton()}</div>
			</>
		)
	}

	const renderFormStatusMessage = () => {
		if (formStatus) {
			return <FormStatusMessage status={formStatus} />
		}
	}

	const onChange = (changes) => {
		if (changes instanceof Array) {
			props.onChange(changes)
		} else {
			props.onChange([changes])
		}
	}

	const onSubmit = () => {
		const displayedErrors = formLineEntities.filter((formLine) => !!formLine.errorMessage)
		const invalidFields = formLineEntities.filter((formLine) => {
			const { value, extra: { isFormLineValid } = {} } = formLine

			if (value && isFormLineValid) {
				return !isFormLineValid(formLine, formLineEntities)
			} else {
				return false
			}
		})

		const canBeFormSubmittedToServer = !displayedErrors.length && !invalidFields.length

		if (canBeFormSubmittedToServer) {
			// odesleme zadana data ve formulari na server
			submitForm({
				formLineEntities,
				onErrorCallback: onSubmitFormError
			})
		} else {
			// nastavime chybovou hlasku pro nevyplnene povinne prvky ve formulari
			onClientSideFormError(displayedErrors, invalidFields)
		}
	}

	const onClientSideFormError = (displayedErrorsFormLines, invalidFields) => {
		clearAllPreviousErrors()

		const errorsCount = new Set([
			...displayedErrorsFormLines.map((f) => f.id),
			...invalidFields.map((f) => f.id)
		]).size

		setFormStatus({
			statusCode: FORM_STATUS_CODES.VALIDATION_ERRORS,
			extra: { failedFieldsCount: errorsCount }
		})

		onChange(
			invalidFields.map((formLineEntity) => {
				const { id, value, extra: { getErrorMessages } = {} } = formLineEntity

				if (getErrorMessages) {
					return {
						id,
						errorMessage: getErrorMessages(formLineEntity)
					}
				} else {
					const errorMessage = value
						? ERROR_DICTIONARY[id][ERROR_CODES.NOT_VALID]
						: ERROR_DICTIONARY[id][ERROR_CODES.REQUIRED]

					return {
						id,
						errorMessage: errorMessage || ERROR_DICTIONARY[id][ERROR_CODES.UNKNOWN]
					}
				}
			})
		)

		scrollPageToTop()
	}

	const onSubmitFormError = (errors) => {
		clearAllPreviousErrors()

		const formLinesErrors = errors.reduce((processedErrors, newError) => {
			const { error_code: errorCode, fields } = newError

			const moreProcessedErrors = fields
				.map((id) => {
					if (ERROR_DICTIONARY[id]) {
						return {
							id,
							errorMessage:
								ERROR_DICTIONARY[id][errorCode] || ERROR_DICTIONARY[id][ERROR_CODES.UNKNOWN]
						}
					} else {
						return undefined
					}
				})
				.filter((err) => !!err)

			return processedErrors.concat(moreProcessedErrors)
		}, [])

		if (formLinesErrors.length) {
			setFormStatus({
				statusCode: FORM_STATUS_CODES.VALIDATION_ERRORS,
				extra: { failedFieldsCount: formLinesErrors.filter((f) => !!f.errorMessage).length }
			})

			onChange(formLinesErrors)
		} else {
			// doslo k nezname chybe, napr. chyba site, ...
			setFormStatus({
				statusCode: FORM_STATUS_CODES.VALIDATION_ERRORS_UNKNOWN
			})
		}

		scrollPageToTop()
	}

	const clearAllPreviousErrors = () => {
		onChange(
			formLineEntities.map(({ id }) => ({
				id,
				errorMessage: undefined
			}))
		)
	}

	const formLineEntitiesWithErrors = formLineEntities.filter((f) => !!f.errorMessage)

	return (
		<Form
			className={CLASSNAME}
			formLineEntities={formLineEntitiesWithErrors}
			isSending={isFormBeingSubmitted}
			renderWidgets={renderWidgets}
			renderFormContent={renderFormContent}
			onSubmit={onSubmit}
			renderSubmitButton={renderSubmitButton}
		/>
	)
}

const selectors = (state) => ({
	formLineEntities: state[HISTORY_STATE_KEYS.FORM_LINES],
	onChange: state[HISTORY_STATE_KEYS.ON_CHANGE],

	isFormBeingSubmitted: state[HISTORY_STATE_KEYS.IS_FORM_BEING_SUBMITTED],
	submitForm: state[HISTORY_STATE_KEYS.SUBMIT_FORM],

	formStatus: state[HISTORY_STATE_KEYS.FORM_STATUS],
	setFormStatus: state[HISTORY_STATE_KEYS.SET_FORM_STATUS],

	scrollPageToTop: state[HISTORY_STATE_KEYS.SCROLL_PAGE_TO_TOP],

	prevPageUrl: state[HISTORY_STATE_KEYS.PREV_PAGE_URL],

	advertEntity: state[ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY],
	cebiaVerified: state[HISTORY_STATE_KEYS.CEBIA_REPORT_VERIFIED],
	submitSaveCertForm: state[HISTORY_STATE_KEYS.SUBMIT_SAVE_CERT_FORM]
})

HistoryForm.propTypes = {
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	onChange: PropTypes.func,
	isFormBeingSubmitted: PropTypes.bool,
	submitForm: PropTypes.func,
	formStatus: PropTypes.object,
	setFormStatus: PropTypes.func,
	scrollPageToTop: PropTypes.func,
	prevPageUrl: PropTypes.string,
	advertEntity: PropTypes.shape(AdvertPropTypes),
	cebiaVerified: PropTypes.bool,
	submitSaveCertForm: PropTypes.func
}

export default React.memo(select(selectors)(HistoryForm))
