import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import ROUTE_NAMES from 'app/base/RouteNames'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { CLASSNAME_DIRECT_LINK } from 'app/component/layout/internalAdmin/LayoutInternalAdmin'
import STEPS_STATE_KEYS from 'app/page/userweb/newAdvert/component/stepper/StepperStateKeys'
import { getNextStepUrl } from 'app/page/userweb/newAdvert/component/stepper/StepperUtils'
import ToastHelper from 'app/component/toastMessages/ToastHelper'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import 'app/base/ActionMessageCS.json'

export default class IAAdvertEditBaseController extends AbstractInternalAdminController {
	constructor(dependenciesHelper) {
		super(dependenciesHelper)

		this._historyStorage = this._dependenciesHelper.getDependency('historyStorage')

		this._router = this._utils.$Router

		this._onSubmitFormSuccessCallback = DEFAULT_PROPS.FUNCTION
		this._onSubmitFormErrorCallback = DEFAULT_PROPS.FUNCTION
	}

	init() {
		super.init()
	}

	load(state = {}) {
		return super.load(
			Object.assign({}, state, {
				[AbstractInternalAdminController.stateId.DIRECT_LINK_DATA]: this._getDirectLinkData(),
				[STEPS_STATE_KEYS.GO_BACK_TO_LIST_URL]: this._getGoBackToAdvertListUrl()
			})
		)
	}

	_getDirectLinkData() {
		return {
			text: 'Historie změn',
			className: `${CLASSNAME_DIRECT_LINK}--advert-edit`,
			url: this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_HISTORY, {
				[InternalAdminUrlConvertor.constants.ADVERT_ID]: this._getAdvertId()
			})
		}
	}

	_getAdvertId() {
		const { [InternalAdminUrlConvertor.constants.ADVERT_ID]: advertId } = this.getRouteParams()
		return advertId
	}

	onGotoBackToList() {
		this.onSubmitAndClose()
		this._onSubmitFormSuccessCallback()
	}

	onSubmitAndClose() {
		this._onSubmitFormSuccessCallback = () => {
			const messageData = {
				type: StatusMessage.TYPE.SUCCESS,
				title: this._dictionary.get('ActionMessage.titleSuccess'),
				text: this._dictionary.get('ActionMessage.saveSuccess', {
					IDS: this._getAdvertId()
				})
			}

			ToastHelper.setToast(messageData)

			this._router.redirect(this._getGoBackToAdvertListUrl())
		}

		this._onSubmitFormErrorCallback = () => {
			this.onSaveError()

			this._onSubmitFormErrorCallback = DEFAULT_PROPS.FUNCTION
		}
	}

	onSubmitAndContinue() {
		this._onSubmitFormSuccessCallback = () => {
			this._router.redirect(this._getNextStepUrl())
		}
	}

	onSaveError() {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			type: StatusMessage.TYPE.ERROR,
			title: this._dictionary.get('ActionMessage.titleError'),
			text: this._dictionary.get('ActionMessage.saveError', {
				IDS: this._getAdvertId()
			})
		})
	}

	_getNextStepUrl() {
		const { [STEPS_STATE_KEYS.STEPS]: steps } = this.getState()

		return getNextStepUrl(steps)
	}

	_getGoBackToAdvertListUrl() {
		const editAdvertRoutes = [
			ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO,
			ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_PHOTOS,
			ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_EQUIPMENT,
			ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_HISTORY
		]

		let prevRouteParams = {}

		if (this._historyStorage.getPreviousRouteName()) {
			let prevNewAdvertRouteName = this._historyStorage.getPreviousRouteName()
			let prevIndex = this._historyStorage.getActualIndex() - 1

			// - dohledam stranku ze ktere jsem prisel na vkladani
			while (editAdvertRoutes.includes(prevNewAdvertRouteName)) {
				prevIndex--
				prevNewAdvertRouteName = this._historyStorage.getIndexRouteName(prevIndex)
			}

			// - pokud predchozi stranka je "vypis inzeratu", tak si ulozim parametry stranky
			if (prevNewAdvertRouteName === ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST) {
				prevRouteParams = this._historyStorage.getIndexRouteParams(prevIndex)
			}
		}

		return this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, prevRouteParams)
	}
}
