const RESOURCE = '/items/mirroring'

export default class MirroringResource {
	constructor(restClient) {
		this._restClient = restClient
	}

	getMirrorings(params, options) {
		return this._restClient.get(RESOURCE, params, options)
	}
}
