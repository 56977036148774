import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'
import { Format } from '@inzeraty/helpers'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Button } from '@sznds/react'
import { Responsive } from '@inzeraty/components'
import AutoComplete, { filterItemsIgnoreSpecChars } from 'app/component/autoComplete/AutoComplete'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import AutoCompleteFilteredForm from 'app/component/autoComplete/forms/filtered/AutoCompleteFilteredForm'
import AutoCompleteAllForm from 'app/component/autoComplete/forms/all/AutoCompleteAllForm'
import { RESPONSIVE } from 'app/base/Constants'
import { useLocalize } from 'app/base/componentHelpers'

export const BRAND_SELECT_WIDGET_ID = 'brand'

import './BrandSelectCS.json'
import './BrandSelect.less'

const CLASSNAME = 'c-brand-select'

const BrandSelect = (props) => {
	const localize = useLocalize()

	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		categorySeoName = '',
		onChange = DEFAULT_PROPS.FUNCTION,
		onBrandDeleted = DEFAULT_PROPS.FUNCTION,
		loadModels = DEFAULT_PROPS.FUNCTION
	} = props

	const [searchedBrandName, setSearchedBrandName] = useState('')
	const [oldSearchedBrandName, setOldSearchedBrandName] = useState('')

	useEffect(() => {
		setOldSearchedBrandName(searchedBrandName)

		const { value: { name: brandName = '' } = {} } = formLineEntity

		if (!searchedBrandName || searchedBrandName === oldSearchedBrandName) {
			setNewSearchedBrandName(brandName)
		}
	}, [formLineEntity])

	// - potrebuji odchytavat zmenu psane hodnoty inputu,
	//   proto pred jejim nastavenim si ulozim puvodni hodnotu
	const setNewSearchedBrandName = (name) => {
		setOldSearchedBrandName(searchedBrandName)
		setSearchedBrandName(name)
	}

	const renderNoOpts = (searchTerm, downshift) => {
		const { options = [] } = formLineEntity

		const othersOpt = options.find((option) => {
			const text = Format.removeDiacritics(option.name).toLowerCase()
			return text === 'ostatni'
		})

		return (
			<div className={`${CLASSNAME}__no-opts-wrap`}>
				<div className={`${CLASSNAME}__no-opts-title`}>
					{localize('BrandSelect.noOptsTitle', { TERM: searchTerm })}
				</div>
				{localize('BrandSelect.noOptsText')}
				<Button
					className={`${CLASSNAME}__no-opts-btn`}
					onClick={() => {
						setNewSearchedBrandName(othersOpt.name)
						handleOnChange({
							value: othersOpt.value,
							name: othersOpt.name
						})
						downshift.closeMenu()
					}}
					text={localize('BrandSelect.othersBtnText')}
				/>
			</div>
		)
	}

	const handleOnChange = (newValue) => {
		const { id, value: { value: currentBrandValue } = {} } = formLineEntity
		const { value: brandValue } = newValue || {}

		onChange({
			id,
			value: newValue,
			errorMessage: undefined
		})

		if (currentBrandValue !== brandValue) {
			// stahneme modely pro nove vybranou znacku
			loadModels(brandValue)
		}

		if (!brandValue) {
			// zadna znacka vybrana
			onBrandDeleted()
		}
	}

	const handleInputValueChange = (inputValue) => {
		setNewSearchedBrandName(inputValue)

		const { options = [] } = formLineEntity

		const option = options.find((o) => o.name === inputValue)

		if (option) {
			handleOnChange({
				name: option.name,
				value: option.value
			})
		} else {
			// dilci zmeny nechceme ukladat do formline, ale
			// az platnou hodnotu
			handleOnChange(undefined)
		}
	}

	const handleSelect = (selectedItem) => {
		if (selectedItem) {
			handleOnChange({
				name: selectedItem.name,
				value: selectedItem.value
			})
		}
	}

	const renderItems = (items, downshift) => {
		const { inputValue, getItemProps, highlightedIndex } = downshift

		const favoriteItems = items.filter((option) => option.isFavorite)

		const renderItem = (option, index) => {
			const { value: brandValue, name, seoName, imageUrl = '' } = option

			return (
				<AutoCompleteOption
					{...getItemProps({
						key: brandValue,
						index,
						item: option,
						isHighlighted: highlightedIndex === index,
						className: `${CLASSNAME}__item`
					})}
				>
					{imageUrl && (
						<BrandIcon
							value={brandValue}
							className={`${CLASSNAME}__icon`}
							seoName={seoName}
							categorySeoName={categorySeoName}
						/>
					)}

					<span className={`${CLASSNAME}__name`}>{name}</span>
				</AutoCompleteOption>
			)
		}

		return inputValue ? (
			<AutoCompleteFilteredForm
				filteredItemsLabel={localize('BrandSelect.labelFound')}
				filteredItems={items}
				renderItem={renderItem}
			/>
		) : (
			<AutoCompleteAllForm
				allItemsLabel={localize('BrandSelect.labelAll')}
				items={items}
				favoriteItemsLabel={localize('BrandSelect.labelFavorite')}
				favoriteItems={favoriteItems}
				renderItem={renderItem}
				renderFavoriteItem={renderItem}
			/>
		)
	}

	const renderInput = (formLineEntity, downshift) => {
		const {
			getInputProps,
			getToggleButtonProps,
			getClearButtonProps,

			isOpen,
			inputValue
		} = downshift

		const { options = [], extra: { cebiaError = false } = {} } = formLineEntity

		const filteredItems = options.filter((option) =>
			filterItemsIgnoreSpecChars(inputValue, option.name)
		)

		const areBrandsLoaded = formLineEntity.options ? formLineEntity.options.length : false

		return (
			<div className={`${CLASSNAME}__content`}>
				<div className={`${CLASSNAME}__wrapper`}>
					<AutoCompleteInput
						isLoading={!areBrandsLoaded}
						inputSurfaceProps={{
							className: classnames({
								'c-basic-widget__cebia-warn': cebiaError
							})
						}}
						inputProps={getInputProps({
							name: formLineEntity.name,
							placeholder: formLineEntity.placeholder,
							required: formLineEntity.required,
							disabled: formLineEntity.disabled,
							error: !!formLineEntity.errorMessage
						})}
						toggleButtonProps={
							!inputValue
								? getToggleButtonProps({
										isOpen
								  })
								: undefined
						}
						clearButtonProps={inputValue ? getClearButtonProps() : undefined}
					/>

					<Responsive
						breakpoint={RESPONSIVE.TABLET}
						renderMobileElement={() => renderPopup(formLineEntity, filteredItems, downshift)}
						renderDesktopElement={() => renderDropdown(formLineEntity, filteredItems, downshift)}
					/>
				</div>
			</div>
		)
	}

	const renderDropdown = (formLineEntity, filteredItems, downshift) => {
		const {
			isOpen,

			getDropdownProps,
			renderDropdown: Dropdown
		} = downshift

		return (
			isOpen && (
				<Dropdown {...getDropdownProps()}>
					{filteredItems.length
						? renderItems(filteredItems, downshift)
						: renderNoOpts(searchedBrandName, downshift)}
				</Dropdown>
			)
		)
	}

	const renderPopup = (formLineEntity, filteredItems, downshift) => {
		const {
			isOpen,
			inputValue,

			getInputProps,
			getClearButtonProps,
			getPopupProps,
			renderPopup: Popup
		} = downshift

		return (
			isOpen && (
				<Popup
					{...getPopupProps({
						title: formLineEntity.label
					})}
				>
					<div className={`${CLASSNAME}__popup-input-wrapper`}>
						<AutoCompleteInput
							inputProps={getInputProps({
								placeholder: formLineEntity.placeholder,
								autoFocus: true
							})}
							clearButtonProps={inputValue ? getClearButtonProps() : undefined}
						/>
					</div>

					{filteredItems.length
						? renderItems(filteredItems, downshift)
						: renderNoOpts(searchedBrandName, downshift)}
				</Popup>
			)
		)
	}

	return (
		<AutoComplete
			{...props}
			inputValue={searchedBrandName}
			onInputValueChange={handleInputValueChange}
			onSelect={handleSelect}
		>
			{(downshift) => renderInput(formLineEntity, downshift)}
		</AutoComplete>
	)
}

BrandSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	categorySeoName: PropTypes.string,
	onChange: PropTypes.func,
	onBrandDeleted: PropTypes.func,
	loadModels: PropTypes.func
}

export default React.memo(BrandSelect)
