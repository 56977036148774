import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ImaContext from 'ima/page/context'
import { Format } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import HistoryTableRow from 'app/page/internalAdmin/components/historyTableRow/HistoryTableRow'

import './AdvertHistoryRowCS.json'

export default function AdvertHistoryRow({ entityData, rowCells, isAllRowsDetailsOpen = false }) {
	const localize = useLocalize()
	const { $Dictionary } = useContext(ImaContext)

	const printEquipment = (value, change = {}) => {
		const { item, newValue = '', oldValue = '' } = change
		const separator = ','

		const getSetFromArray = (arr) => new Set(arr.split(separator).map((v) => v.trim()))
		const getDiffSet = (setA, setB) => new Set([...setA].filter((x) => !setB.has(x)))

		if (item === 'data.equipment_cb') {
			const newEquipmentSet = getSetFromArray(newValue)
			const oldEquipmentSet = getSetFromArray(oldValue)

			if (value === newValue) {
				const diffSet = getDiffSet(newEquipmentSet, oldEquipmentSet)
				return Array.from(diffSet).join(separator)
			} else if (value === oldValue) {
				const diffSet = getDiffSet(oldEquipmentSet, newEquipmentSet)
				return Array.from(diffSet).join(separator)
			}
		}
	}

	const printDate = (value) => {
		return Format.date(new Date(value * 1000))
	}

	const printValue = (value, change) => {
		const { item } = change

		if (item === 'data.equipment_cb') {
			return JSON.stringify(printEquipment(value, change))
		} else if (
			[
				'data.in_operation_date',
				'data.manufacturing_date',
				'data.stk_date',
				'data.guarantee_date',
				'data.out_of_order_date'
			].includes(item)
		) {
			return JSON.stringify(printDate(value))
		} else {
			return JSON.stringify(value)
		}
	}

	const renderChanges = () => {
		const { changes = [] } = entityData

		return (
			<HistoryTableRow.ChangesGroup>
				{changes.map((change, index) => {
					const { item, newValue, oldValue } = change

					const titleLocalizeKey = `AdvertHistoryRow.${item.replace(/\./g, '__')}`
					const title = $Dictionary.has(titleLocalizeKey) ? localize(titleLocalizeKey) : item

					return (
						<HistoryTableRow.ChangesGroupContentLine
							key={`change-${item}-${index}`}
							name={title}
							newValue={printValue(newValue, change)}
							oldValue={printValue(oldValue, change)}
						/>
					)
				})}
			</HistoryTableRow.ChangesGroup>
		)
	}

	return (
		<HistoryTableRow
			entityData={entityData}
			rowCells={rowCells}
			renderChanges={renderChanges}
			isDetailOpen={isAllRowsDetailsOpen}
		/>
	)
}

AdvertHistoryRow.propTypes = {
	entityData: PropTypes.object.isRequired,
	rowCells: PropTypes.array.isRequired,
	isAllRowsDetailsOpen: PropTypes.bool
}
