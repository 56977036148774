import { Format } from '@inzeraty/helpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'

import 'app/base/BaseCS.json'
import './AdvertDetailOperatingLeaseMetaCS.json'

const joinTexts = (textArray = [], separator = ', ') =>
	textArray.filter((text) => !!text).join(separator)

const getCodebookName = (codebook = {}) => (codebook && codebook.name ? codebook.name : '')

const getAdvertFullName = (advertEntity = {}) => {
	const { manufacturerCb, modelCb, nameSuffix } = advertEntity

	const brandName = getCodebookName(manufacturerCb)
	const modelName = getCodebookName(modelCb)

	return joinTexts([brandName, modelName, nameSuffix], ' ')
}

const getVehicleBodyText = (advertEntity = {}) => getCodebookName(advertEntity.vehicleBodyCb)

const getYearText = (advertEntity = {}) => {
	const { manufacturingDate, inOperationDate } = advertEntity

	const manufacturYear = manufacturingDate?.getFullYear()
	const inOperationYear = inOperationDate?.getFullYear()

	return manufacturYear ?? inOperationYear
}

const getEngineVolumeText = (dictionary, advertEntity = {}) => {
	const { engineVolume } = advertEntity

	return engineVolume ? `${Format.number(engineVolume)} ${dictionary.get('Base.CCM')}` : ''
}

const getEnginePowerText = (dictionary, advertEntity = {}) => {
	const { enginePower } = advertEntity

	return !isNullOrUndefined(enginePower)
		? `${Format.number(enginePower)} ${dictionary.get('Base.KW')}`
		: ''
}

const getGearboxText = (advertEntity = {}) => {
	const { gearboxCb, gearboxLevelsCb } = advertEntity

	const gearboxText = getCodebookName(gearboxCb)
	const gearboxLevelsText = getCodebookName(gearboxLevelsCb)

	return joinTexts([gearboxText, gearboxLevelsText], ' ')
}

const getFuelText = (advertEntity = {}) => getCodebookName(advertEntity.fuelCb)

const getColorText = (advertEntity = {}) => getCodebookName(advertEntity.colorCb)

const getTachometerText = (dictionary, advertEntity = {}) => {
	const { tachometer } = advertEntity

	return !isNullOrUndefined(tachometer)
		? `${Format.number(tachometer)} ${dictionary.get('Base.KM')}`
		: ''
}

const formatLabelAndValue = (label, value) => `${label}: ${value}`

const getAvailabilityText = (dictionary, advertEntity = {}) => {
	const valueText = getCodebookName(advertEntity.availabilityCb)

	if (valueText) {
		return formatLabelAndValue(
			dictionary.get('AdvertDetailOperatingLeaseMeta.availabilityLabel'),
			valueText
		)
	}
}

const getAnnualDistanceText = (dictionary, advertEntity = {}) => {
	const { operatingLeaseVariants = [] } = advertEntity

	const smallestAnnualDistance = Math.min(
		...operatingLeaseVariants.map(({ annualDistance }) => annualDistance)
	)

	if (!isNullOrUndefined(smallestAnnualDistance)) {
		const valueText = `${Format.number(smallestAnnualDistance)} ${dictionary.get('Base.KM')}`

		return formatLabelAndValue(
			dictionary.get('AdvertDetailOperatingLeaseMeta.annualDistanceLabel'),
			valueText
		)
	}
}

const getPeriodText = (dictionary, advertEntity = {}) => {
	const { operatingLeaseVariants = [] } = advertEntity

	const smallestPeriod = Math.min(...operatingLeaseVariants.map(({ period }) => period))

	if (!isNullOrUndefined(smallestPeriod)) {
		const valueText = `${dictionary.get('Base.month', {
			MONTH: smallestPeriod,
			MONTH_FORMATTED: Format.number(smallestPeriod)
		})}`

		return formatLabelAndValue(
			dictionary.get('AdvertDetailOperatingLeaseMeta.periodLabel'),
			valueText
		)
	}
}

export default class AdvertDetailOperatingLeaseMeta {
	static getDetailTitle(dictionary, advertEntity = {}) {
		const { operatingLeasePriceWithoutVat } = advertEntity

		return dictionary.get('AdvertDetailOperatingLeaseMeta.title', {
			FULL_ADVERT_NAME: getAdvertFullName(advertEntity),
			PRICE: Format.number(operatingLeasePriceWithoutVat),
			CURRENCY: dictionary.get('Base.CZK')
		})
	}

	static getDetailDescription(dictionary, advertEntity = {}) {
		const { operatingLeasePriceWithoutVat } = advertEntity

		const vehicleParamsText = joinTexts([
			getVehicleBodyText(advertEntity),
			getYearText(advertEntity),
			getEngineVolumeText(dictionary, advertEntity),
			getEnginePowerText(dictionary, advertEntity),
			getGearboxText(advertEntity),
			getFuelText(advertEntity),
			getColorText(advertEntity),
			getTachometerText(dictionary, advertEntity)
		])

		const operatingLeaseParamsText = joinTexts([
			getAvailabilityText(dictionary, advertEntity),
			getAnnualDistanceText(dictionary, advertEntity),
			getPeriodText(dictionary, advertEntity)
		])

		return dictionary.get('AdvertDetailOperatingLeaseMeta.description', {
			FULL_ADVERT_NAME: getAdvertFullName(advertEntity),
			PRICE: Format.number(operatingLeasePriceWithoutVat),
			VEHICLE_PARAMS: vehicleParamsText,
			OPER_LEASE_PARAMS: operatingLeaseParamsText,
			CURRENCY: dictionary.get('Base.CZK')
		})
	}
}
