
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['CarCompare'] = {
  title1: function(d) { return "Srovnání ceny pro "; },
  compareInfo: function(d) { return "Inzeráty porovnáváme dle těchto parametrů (Výrobce, Model, Rok výroby, Stav vozidla, Karosérie a procentuální rozmezí nájezdu kilometrů)."; },
  text1: function(d) { return "Na základě porovnání "; },
  textNumber: function(d) { return plural(d.COUNT, 0, cs, { one: d.COUNT + " podobné nabídky", other: d.COUNT + " podobných nabídek" }) + ", "; },
  text2: function(d) { return plural(d.COUNT, 0, cs, { one: "inzerované", other: "inzerovaných" }) + " v několika posledních měsících na portálu Sauto.cz, spadá tento inzerát do kategorie:"; },
  price: function(d) { return d.PRICE + " " + d.CURRENCY; },
  lowest: function(d) { return "Minimální cena"; },
  low: function(d) { return "Nižší cena"; },
  regular: function(d) { return "Běžná cena"; },
  high: function(d) { return "Vyšší cena"; },
  highest: function(d) { return "Maximální cena"; },
  range: function(d) { return d.COUNT1 + " - " + d.COUNT2 + " "; },
  engineRange: function(d) { return d.COUNT1 + "-" + d.COUNT2 + " "; },
  info: function(d) { return "Více informací"; },
  parametersTitle: function(d) { return "Porovnávané parametry"; },
  manufacturer: function(d) { return "Výrobce:"; },
  model: function(d) { return "Model:"; },
  vehicleAge: function(d) { return "Rok výroby:"; },
  vehicleBody: function(d) { return "Karosérie:"; },
  tachometer: function(d) { return "Stav tachometru:"; },
  engine: function(d) { return "Výkon:"; },
  compareInfoData: function(d) { return "Srovnání probíhá na základě až 50 parametrů, mimo jiné: země původu, roku výroby, tachometru, výkonu a objemu motoru, paliva, převodovky, pohonu kol, karoserie a výbavy vozidla."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	