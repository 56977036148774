import animateScrollTo from 'animated-scroll-to'

// offset je bud ciselna hodnota nebo element, ke kteremu
// chceme zascrollovat.
export default (offset, options) => {
	try {
		animateScrollTo(offset, options)
	} catch (e) {
		if (offset) {
			offset.scrollIntoView()
		}
	}
}
