import React from 'react'
import PropTypes from 'prop-types'
import { Surface } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'
import { Format } from '@inzeraty/helpers'
import { getYear } from '../../../modelLineModifications/components/modificationsList/ModificationsList'
import ModificationDetailPlaceholder from '../../../placeholders/modificationDetail/ModificationDetailPlaceholder'

import './DetailInfoCS.json'
import '../../../CatalogCS.json'
import 'app/base/BaseCS.json'
import './DetailInfo.less'

const CLASSNAME = 'c-catalog-modification-detail-info'

const DetailInfo = (props) => {
	const { detail } = props

	const localize = useLocalize()

	const dataLoaded = !!detail

	const renderInfo = () => {
		return (
			<div className={`${CLASSNAME}__info`}>
				{renderGeneralInformation()}
				{renderPerformanceInformation()}
				{renderEngineInformation()}
				{renderVehicleBodyInformation()}
			</div>
		)
	}

	const renderInformationTitle = (title) => {
		return <h2 className={`${CLASSNAME}__title`}>{title}</h2>
	}

	const renderInformationItem = (name, value, unit = '') => {
		return (
			value && (
				<div className={`${CLASSNAME}__item`}>
					<div className={`${CLASSNAME}__item-name`}>{name}</div>
					<div className={`${CLASSNAME}__item-value`}>
						{value} {!!unit && <span>{unit}</span>}
					</div>
				</div>
			)
		)
	}

	const renderGeneralInformation = () => {
		const { generation = {}, name: modificationName, yearStart, yearEnd, data = {} } = detail || {}
		const { vehicleBody, seats, doors } = data
		const { name: generationName, model = {} } = generation
		const { name: modelName, manufacturer = {} } = model
		const { name: brandName } = manufacturer

		return (
			<div>
				{renderInformationTitle(localize('DetailInfo.generalInformation'))}
				<div className={`${CLASSNAME}__list`}>
					{renderInformationItem(localize('DetailInfo.brand'), brandName)}
					{renderInformationItem(localize('DetailInfo.model'), modelName)}
					{renderInformationItem(localize('DetailInfo.generation'), generationName)}
					{renderInformationItem(localize('DetailInfo.modification'), modificationName)}
					{renderInformationItem(localize('DetailInfo.bodyType'), vehicleBody)}
					{renderInformationItem(localize('DetailInfo.seats'), seats)}
					{renderInformationItem(localize('DetailInfo.doors'), doors)}
					{renderInformationItem(
						localize('DetailInfo.startProduction'),
						getYear(localize, yearStart, yearEnd)
					)}
				</div>
			</div>
		)
	}

	const getMinMaxData = (min, max, dash = ' – ') => {
		if (min && max) {
			if (min === max) {
				return min
			} else {
				return `${min}${dash}${max}`
			}
		} else if (min && !max) {
			return min
		} else if (!min && max) {
			return max
		} else {
			return undefined
		}
	}

	const renderPerformanceInformation = () => {
		const { data = {} } = detail || {}
		const {
			fuelConsumptionUrbanMin,
			fuelConsumptionUrbanMax,
			fuelConsumptionExtraurbanMin,
			fuelConsumptionExtraurbanMax,
			fuelConsumptionCombinedMin,
			fuelConsumptionCombinedMax,
			co2Max,
			co2Min,
			maxSpeed,
			emissionStandard,
			acceleration,
			acceleration200,
			trailerLoadBraked12perc,
			trailerLoadUNBraked
		} = data

		const emission = getMinMaxData(co2Min, co2Max)
		const fuelConsumptionUrban = getMinMaxData(fuelConsumptionUrbanMin, fuelConsumptionUrbanMax)
		const fuelConsumptionExtraurban = getMinMaxData(
			fuelConsumptionExtraurbanMin,
			fuelConsumptionExtraurbanMax
		)
		const fuelConsumptionCombined = getMinMaxData(
			fuelConsumptionCombinedMin,
			fuelConsumptionCombinedMax
		)

		return (
			<div className={`${CLASSNAME}__performance`}>
				{renderInformationTitle(localize('DetailInfo.perfomaceInformation'))}
				<div className={`${CLASSNAME}__list`}>
					{renderInformationItem(localize('DetailInfo.emissionStandart'), emissionStandard)}
					{renderInformationItem(
						localize('DetailInfo.emission'),
						emission,
						localize('DetailInfo.gramPerKg')
					)}
					{renderInformationItem(
						localize('DetailInfo.fuelConsumptionUrban'),
						fuelConsumptionUrban,
						localize('Base.gasMileage')
					)}
					{renderInformationItem(
						localize('DetailInfo.fuelConsumptionExtraUrban'),
						fuelConsumptionExtraurban,
						localize('Base.gasMileage')
					)}
					{renderInformationItem(
						localize('DetailInfo.fuelConsumptionCombined'),
						fuelConsumptionCombined,
						localize('Base.gasMileage')
					)}
					{renderInformationItem(
						localize('DetailInfo.acceleration'),
						acceleration,
						localize('DetailInfo.second')
					)}
					{renderInformationItem(
						localize('DetailInfo.acceleration200'),
						acceleration200,
						localize('DetailInfo.second')
					)}
					{renderInformationItem(
						localize('DetailInfo.maxSpeed'),
						maxSpeed,
						localize('Catalog.speedUnit')
					)}
					{renderInformationItem(
						localize('DetailInfo.trailerLoadBraked12perc'),
						trailerLoadBraked12perc,
						localize('Base.KG')
					)}
					{renderInformationItem(
						localize('DetailInfo.trailerLoadUNBraked'),
						trailerLoadUNBraked,
						localize('Base.KG')
					)}
				</div>
			</div>
		)
	}

	const renderEngineInformation = () => {
		const { data = {} } = detail || {}
		const {
			gearboxATNumber,
			gearboxMTNumber,
			gearboxATType,
			maxEngineSpeed,
			engineCode,
			engineVolume,
			fuel,
			fuelSystem,
			positionCilinders,
			compressionRatio,
			stroke,
			bore,
			turbine,
			valvetrain,
			engineOilCapacity,
			power,
			powerHp,
			powerKw,
			powerRpmHigh,
			powerRpmLow,
			torqueNm,
			torqueRpmHigh,
			torqueRpmLow
		} = data

		const horseUnit = localize('DetailInfo.horses')
		const newtonMeterUnit = localize('DetailInfo.newtonMeter')

		const powerRpmValue = getMinMaxData(powerRpmLow, powerRpmHigh)

		const powerUnit = powerRpmValue
			? `${horseUnit})${localize('DetailInfo.powerSpeedPerMinuteUnit', {
					VALUE: powerRpmValue
			  })} ${localize('DetailInfo.speedPerMinute')}`
			: horseUnit

		let powerValue

		if (powerKw) {
			powerValue = `${powerKw} ${localize('Base.KW')}`

			if (powerHp) {
				powerValue += ` (${powerHp}`
			} else {
				if (power) {
					powerValue += ` (${power.split('/')[0]}`
				}
			}
		}

		const torqueRpmValue = getMinMaxData(torqueRpmLow, torqueRpmHigh)

		const torqueUnit = torqueRpmValue
			? `${newtonMeterUnit}${localize('DetailInfo.powerSpeedPerMinuteUnit', {
					VALUE: torqueRpmValue
			  })} ${localize('DetailInfo.speedPerMinute')}`
			: newtonMeterUnit

		return (
			<div className={`${CLASSNAME}__engine`}>
				{renderInformationTitle(localize('DetailInfo.engineInformation'))}
				<div className={`${CLASSNAME}__list`}>
					{renderInformationItem(localize('DetailInfo.power'), powerValue, powerUnit)}
					{renderInformationItem(localize('DetailInfo.torque'), torqueNm, torqueUnit)}
					{renderInformationItem(
						localize('DetailInfo.gearboxATNumber'),
						gearboxATNumber,
						gearboxATType
					)}
					{renderInformationItem(localize('DetailInfo.gearboxMTNumber'), gearboxMTNumber)}
					{renderInformationItem(
						localize('DetailInfo.maxEngineSpeed'),
						maxEngineSpeed,
						localize('DetailInfo.speedPerMinute')
					)}
					{renderInformationItem(localize('DetailInfo.engineCode'), engineCode)}
					{renderInformationItem(
						localize('DetailInfo.engineVolume'),
						Format.number(engineVolume),
						localize('Catalog.engineVolumeUnit')
					)}
					{renderInformationItem(localize('DetailInfo.fuelType'), fuel)}
					{renderInformationItem(localize('DetailInfo.fuelSystem'), fuelSystem)}
					{renderInformationItem(localize('DetailInfo.positionCilinders'), positionCilinders)}
					{renderInformationItem(localize('DetailInfo.compressionRatio'), compressionRatio)}
					{renderInformationItem(
						localize('DetailInfo.stroke'),
						stroke,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.bore'),
						bore,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(localize('DetailInfo.turbine'), turbine)}
					{renderInformationItem(localize('DetailInfo.valvetrain'), valvetrain)}
					{renderInformationItem(
						localize('DetailInfo.engineOilCapacity'),
						engineOilCapacity,
						localize('DetailInfo.liter')
					)}
				</div>
			</div>
		)
	}

	const renderVehicleBodyInformation = () => {
		const { data = {} } = detail || {}

		const {
			carLength,
			carHeight,
			carWidth,
			wheelbase,
			frontTrackMin,
			frontTrackMax,
			frontOverhang,
			rearTrackMin,
			rearTrackMax,
			rearOverhang,
			curbWeightMin,
			curbWeightMax,
			carMaxWeigh,
			noseWeight,
			roofLoad,
			luggageMin,
			luggageMax,
			tankVolume,
			adblueTankVolume,
			rim
		} = data

		const rearTrackValue = getMinMaxData(rearTrackMin, rearTrackMax)
		const frontTrackValue = getMinMaxData(frontTrackMin, frontTrackMax)
		const curbWeightValue = getMinMaxData(curbWeightMin, curbWeightMax)
		const luggageValue = getMinMaxData(luggageMin, luggageMax)

		return (
			<div className={`${CLASSNAME}__vehicle-body`}>
				{renderInformationTitle(localize('DetailInfo.vehicleBodyInformation'))}
				<div className={`${CLASSNAME}__list`}>
					{renderInformationItem(
						localize('DetailInfo.carLength'),
						carLength,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.carHeight'),
						carHeight,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.carWidth'),
						carWidth,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.wheelbase'),
						wheelbase,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.frontTrack'),
						frontTrackValue,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.frontOverhang'),
						frontOverhang,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.rearTrack'),
						rearTrackValue,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.rearOverhang'),
						rearOverhang,
						localize('DetailInfo.milimeters')
					)}
					{renderInformationItem(
						localize('DetailInfo.curbWeight'),
						curbWeightValue,
						localize('Base.KG')
					)}
					{renderInformationItem(
						localize('DetailInfo.carMaxWeigh'),
						carMaxWeigh,
						localize('Base.KG')
					)}
					{renderInformationItem(
						localize('DetailInfo.noseWeight'),
						noseWeight,
						localize('Base.KG')
					)}
					{renderInformationItem(localize('DetailInfo.roofLoad'), roofLoad, localize('Base.KG'))}
					{renderInformationItem(
						localize('DetailInfo.luggage'),
						luggageValue,
						localize('DetailInfo.liter')
					)}
					{renderInformationItem(
						localize('DetailInfo.tankVolume'),
						tankVolume,
						localize('DetailInfo.liter')
					)}
					{renderInformationItem(
						localize('DetailInfo.adblueTankVolume'),
						adblueTankVolume,
						localize('DetailInfo.liter')
					)}
					{renderInformationItem(localize('DetailInfo.rim'), rim)}
				</div>
			</div>
		)
	}

	return (
		<Surface surface={3} className={CLASSNAME}>
			{dataLoaded ? renderInfo() : <ModificationDetailPlaceholder />}
		</Surface>
	)
}

DetailInfo.propTypes = {
	detail: PropTypes.object
}

export default React.memo(DetailInfo)
