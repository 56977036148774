import React, { useContext, useState, useEffect } from 'react'
import Context from 'ima/page/context'
import { Button } from '@sznds/react'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import CreateAdvertButton from '../createAdvertButton/CreateAdvertButton'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import Paging from 'app/component/paging/PagingView'
import ROUTE_NAMES from 'app/base/RouteNames'
import ClientAdvertItem from '../clientAdvertItem/ClientAdvertItemView'
import ClientAdvertListUrlConvertor from '../ClientAdvertListUrlConvertor'
import { getActions } from '../advertActions/AdvertActions'
import { Icon, Surface } from '@sznds/react'
import { OVERFLOW_MENU_HORIZONTAL_FILLED } from '@sznds/icons'
import classnames from 'classnames'
import { RESPONSIVE } from 'app/base/Constants'
import { Popup, Responsive } from '@inzeraty/components'
import PropTypes from 'prop-types'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import AdvertPropTypes from 'app/model/advert/AdvertPropTypes'
import { ACTION_TYPE } from '../advertActions/AdvertActionsConstants'
import PublishModal from '../publishModal/PublishModal'
import getAdvertState, { ADVERT_STATE } from '../getAdvertState'
import ToppedModal from '../toppedModal/ToppedModal'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import EmptyList from 'app/component/emptyList/EmptyList'

import 'app/base/BaseCS.json'
import './ClientAdminAdvertListCS.json'
import './ClientAdminAdvertList.less'

const CLASSNAME = 'c-client-admin-item-list'

const Pagination = ({ paginationEntity }) => {
	const { $Router: router } = useContext(Context)

	if (paginationEntity) {
		return (
			<Paging
				paginationEntity={paginationEntity}
				urlPageCreator={(page) => {
					const { params } = router.getCurrentRouteInfo()
					const newParams = UrlConvertor.getPagingPageParams(page, params)
					return router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST, newParams)
				}}
				pageOnClick={() => animateScrollTo(0, { minDuration: 0, maxDuration: 0 })}
			/>
		)
	}
}

const AdvertActions = ({ selectedAdvertIds, isPremise, advertList, premiseId }) => {
	const [mobileOpen, setMobileOpen] = useState(false)
	const { $Router: router } = useContext(Context)
	const fire = useFire()
	const localize = useLocalize()

	const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
	const [isToppedModalOpen, setIsToppedModalOpen] = useState(false)

	const selectedItems = advertList.filter(({ id }) => selectedAdvertIds.has(id))

	const findAdvertWithAdminDeactivatedReason = () => {
		return selectedItems.find((advertEntity) => {
			const advertState = getAdvertState(advertEntity, isPremise)
			return advertState === ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED
		})
	}

	const fireAction = (action) => {
		// Pokud je jeden z inzerátů deaktivovaný adminem a uživatel ho chce aktivovat, tak ho upozorníme aby si inzerát upravil.
		if (action === ACTION_TYPE.PUBLISH && findAdvertWithAdminDeactivatedReason()) {
			setIsPublishModalOpen(true)
		} else if (action === ACTION_TYPE.TOPPED_ADVERT) {
			setIsToppedModalOpen(true)
		} else {
			fire(action, [...selectedAdvertIds])
		}
	}

	const getSelectedAdvertDeactivatedByAdminId = () => {
		const { id } = findAdvertWithAdminDeactivatedReason() || {}
		return id
	}

	return (
		<>
			<Surface surface={5} className={`${CLASSNAME}__item-actions`}>
				<div className={`${CLASSNAME}__item-actions-content`}>
					<div className={`${CLASSNAME}__count-wrapper`}>
						<span className={`${CLASSNAME}__count-prefix`}>
							{localize('ClientAdminAdvertList.chosen', { COUNT: selectedAdvertIds.size })}
						</span>
						<span className={`${CLASSNAME}__count`}>
							{localize('ClientAdminAdvertList.adverts', { COUNT: selectedAdvertIds.size })}
						</span>
						<span className={`${CLASSNAME}__separator`}>–</span>
					</div>
					<div className={`${CLASSNAME}__actions`}>
						<Responsive
							breakpoint={RESPONSIVE.TABLET}
							renderMobileElement={() => (
								<>
									<button className={`${CLASSNAME}__action`} onClick={() => fire('deselectAll')}>
										{localize('ClientAdminAdvertList.clear')}
									</button>
									<button className={`${CLASSNAME}__action`} onClick={() => setMobileOpen(true)}>
										<Icon
											symbol={OVERFLOW_MENU_HORIZONTAL_FILLED}
											className={`${CLASSNAME}__icon`}
										/>
										{localize('ClientAdminAdvertList.actions')}
									</button>
								</>
							)}
							renderDesktopElement={() => (
								<>
									{getActions({
										router,
										localize,
										isPremise,
										preferredOffer: {
											addPOButton: selectedItems.some((item) => !item.preferredOffer),
											removePOButton: selectedItems.some((item) => item.preferredOffer)
										},
										isForMassActionsBar: true,
										showToppedAction: selectedItems.every(
											(item) => item.status === ADVERT_STATE.ACTIVE
										),
										showDeactivatedAction: selectedItems.every(
											(item) => item.status === ADVERT_STATE.ACTIVE
										),
										showActivatedAction: selectedItems.every(
											(item) =>
												item.status === ADVERT_STATE.INACTIVE ||
												item.status === ADVERT_STATE.INACTIVE_USER_DEACTIVATED ||
												item.status === ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED ||
												item.status === ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED
										)
									}).map(({ action, text }) => (
										<button
											key={action}
											className={`${CLASSNAME}__action`}
											onClick={() => fireAction(action)}
										>
											{text}
										</button>
									))}
									<button
										key='deselectAll'
										className={`${CLASSNAME}__action`}
										onClick={() => fire('deselectAll')}
									>
										{localize('ClientAdminAdvertList.clear')}
									</button>
								</>
							)}
						/>
					</div>
				</div>
			</Surface>
			{mobileOpen && (
				<Popup
					className={`${CLASSNAME}__popup`}
					isOpen={true}
					onClose={() => setMobileOpen(false)}
					dictionary={{
						close: localize('Base.close')
					}}
					title={localize('ClientAdminAdvertList.advertActions')}
					isFullscreen={false}
					hasLightbox={true}
				>
					{getActions({ router, localize, isPremise }).map(({ action, text }) => {
						return (
							<button
								key={action}
								className={`${CLASSNAME}__popup-action`}
								onClick={() => {
									setMobileOpen(false)
									fireAction(action)
								}}
							>
								{text}
							</button>
						)
					})}
				</Popup>
			)}

			<PublishModal
				isOpen={isPublishModalOpen}
				onClose={() => setIsPublishModalOpen(false)}
				advertId={getSelectedAdvertDeactivatedByAdminId()}
			/>
			<ToppedModal
				isOpen={isToppedModalOpen}
				onClose={() => setIsToppedModalOpen(false)}
				advertIds={[...selectedAdvertIds]}
				premiseId={premiseId}
			/>
		</>
	)
}

export default function AdvertList({
	isOperatingLease,
	isPremise,
	advertListData,
	isLoadingStatistics,
	statistics = DEFAULT_PROPS.OBJECT,
	isLoading,
	filterStatus,
	selectedAdverts,
	premiseInfo = DEFAULT_PROPS.OBJECT
}) {
	const localize = useLocalize()
	const { $Router: router } = useContext(Context)

	useEffect(() => {
		const CA_ACTION_PANEL_OPENED_CLASSNAME = 'ca-action-panel-opened'

		// pokud je otevren v KA dolni panel pro hromadne akce, tak na body pripneme
		// odpovidajici CSS tridu
		if (selectedAdverts?.size) {
			document.body.classList.add(CA_ACTION_PANEL_OPENED_CLASSNAME)
		} else {
			document.body.classList.remove(CA_ACTION_PANEL_OPENED_CLASSNAME)
		}
	}, [selectedAdverts])

	const { id: premiseId } = premiseInfo

	if (isLoading) {
		return null
	}

	const { advertList, paginationEntity } = advertListData

	if (advertList.length) {
		return (
			<>
				<ul
					className={classnames(`${CLASSNAME}__list`, isPremise && `${CLASSNAME}__list--premise`)}
					data-dot='adverts'
				>
					{advertList.map((advertEntity) => (
						<ClientAdvertItem
							isSelected={selectedAdverts.has(advertEntity.id)}
							isOperatingLease={isOperatingLease}
							isPremise={isPremise}
							advertEntity={advertEntity}
							isLoadingStatistics={isLoadingStatistics}
							statistics={statistics[advertEntity.id]}
							key={advertEntity.id}
						/>
					))}
				</ul>
				<Pagination paginationEntity={paginationEntity} />
				{Boolean(selectedAdverts.size) && (
					<AdvertActions
						isOperatingLease={isOperatingLease}
						isPremise={isPremise}
						selectedAdvertIds={selectedAdverts}
						advertList={advertList}
						premiseId={premiseId}
					/>
				)}
			</>
		)
	} else {
		const { params } = router.getCurrentRouteInfo()

		const renderedBackToAllAdvertsButton = (
			<Button
				type='button'
				size='small'
				primary={true}
				text={localize('ClientAdminAdvertList.backToAllAdverts')}
				onClick={() => animateScrollTo(0, { minDuration: 0, maxDuration: 0 })}
				href={router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST)}
			/>
		)
		const renderedCreateNewAdvertButton = (
			<CreateAdvertButton className={`${CLASSNAME}__create-advert-btn`} />
		)
		const renderedDefaultNothingFound = (
			<EmptyList
				renderImage={() => <EmptyList.NothingFoundImage />}
				title={localize('ClientAdminAdvertList.emptyListNothingFound')}
				text={localize('ClientAdminAdvertList.emptyListNothingFoundDescription')}
				renderLink={() => renderedBackToAllAdvertsButton}
			/>
		)
		const renderedDefaultEmpty = (
			<EmptyList
				title={localize('ClientAdminAdvertList.emptyList')}
				text={localize('ClientAdminAdvertList.emptyListDescription')}
				renderLink={() => renderedCreateNewAdvertButton}
			/>
		)

		if (!isPremise) {
			switch (filterStatus) {
				case ClientAdvertListUrlConvertor.advertStatus.INACTIVE.APP:
					if (Object.keys(params).length > 1) {
						return (
							<EmptyList
								renderImage={() => <EmptyList.NothingFoundImage />}
								title={localize('ClientAdminAdvertList.emptyListNothingFoundInactive')}
								text={localize('ClientAdminAdvertList.emptyListNothingFoundDescription')}
								renderLink={() => renderedBackToAllAdvertsButton}
							/>
						)
					} else {
						return <EmptyList title={localize('ClientAdminAdvertList.emptyListInactive')} text='' />
					}
				case ClientAdvertListUrlConvertor.advertStatus.BAN.APP:
					if (Object.keys(params).length > 1) {
						return (
							<EmptyList
								renderImage={() => <EmptyList.NothingFoundImage />}
								title={localize('ClientAdminAdvertList.emptyListNothingFoundBanned')}
								text={localize('ClientAdminAdvertList.emptyListNothingFoundDescription')}
								renderLink={() => renderedBackToAllAdvertsButton}
							/>
						)
					} else {
						return <EmptyList title={localize('ClientAdminAdvertList.emptyListBanned')} text='' />
					}
			}
		}

		return Object.keys(params).length ? renderedDefaultNothingFound : renderedDefaultEmpty
	}
}

AdvertActions.propTypes = {
	selectedAdvertIds: PropTypes.instanceOf(Set),
	isPremise: PropTypes.bool,
	advertList: PropTypes.arrayOf(PropTypes.shape(AdvertPropTypes)),
	premiseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
