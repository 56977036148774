import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './AutoCompleteOption.less'

const CLASSNAME = 'c-auto-complete-option'

const AutoCompleteOption = (props) => {
	const { isHighlighted, children } = props

	const restProps = Object.assign({}, props)

	delete restProps.isHighlighted
	delete restProps.children

	return (
		<li
			{...restProps}
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}__highlighted`]: isHighlighted,
				[restProps.className]: !!restProps.className
			})}
		>
			{children}
		</li>
	)
}

AutoCompleteOption.propTypes = {
	isHighlighted: PropTypes.bool,
	children: PropTypes.node
}

export default AutoCompleteOption
