
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ToppedPayment'] = {
  backToAdvertListUrl: function(d) { return "Zpět na výpis"; },
  goToPayGate: function(d) { return "Přejít na platební bránu"; },
  payWithWallet: function(d) { return "Zaplatit peněženkou"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	