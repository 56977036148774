
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Report'] = {
  title: function(d) { return "Nahlásit závadný inzerát"; },
  formReasonsLabel: function(d) { return "Důvody nahlášení"; },
  formSuspiciousAdvertLabel: function(d) { return "Podezření na podvodný inzerát."; },
  formWrongCategoryLabel: function(d) { return "Inzerát je ve špatné kategorii."; },
  formFalseInfoLabel: function(d) { return "Falešné nebo zavádějící informace."; },
  formBadPhotosLabel: function(d) { return "Fotografie se neshodují s vozem."; },
  formOtherLabel: function(d) { return "Jiné."; },
  formEmailLabel: function(d) { return "Váš email"; },
  formReportMessageLabel: function(d) { return "Důvod nahlášení"; },
  formReportMessagePlaceholder: function(d) { return "Napište důvod nahlášení"; },
  btnContinue: function(d) { return "Nahlásit"; },
  value_not_valid: function(d) { return "Chybný formát"; },
  user_email_required: function(d) { return "Je třeba vyplnit Váš email"; },
  user_email_not_valid: function(d) { return "Váš email nemá správný formát"; },
  report_reason_required: function(d) { return "Je potřeba vybrat důvod nahlášení"; },
  report_message_required: function(d) { return "Je pořeba popsat důvod nahlášení"; },
  sendReportSuccess: function(d) { return "Inzerát byl úspěšně nahlášen."; },
  sendReportError: function(d) { return "Inzerát se nepodařilo nahlásit."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	