import React, { memo } from 'react'
import { CLASSNAME } from '../InternalAdminWidgets'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const CONTENT_CLASSNAME = 'c-ia-widget__content'

const WidgetContent = memo(({ className, formLineEntity = {} }) => {
	const { label, value } = formLineEntity

	return (
		<div className={className}>
			<div className={`${CLASSNAME}__input-box`}>
				{!!label && <span className={`${CLASSNAME}__label`}>{label}</span>}

				<div className={`${CLASSNAME}__input ${CONTENT_CLASSNAME}`}>{value}</div>
			</div>
		</div>
	)
})

WidgetContent.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	className: PropTypes.string
}

export default WidgetContent
