import React from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import ClientBaseController from 'app/base/ClientBaseController'
import PremiseService from 'app/model/premise/PremiseService'
import classnames from 'classnames'

import './PremiseSlotsCS.json'
import './PremiseSlots.less'

const CLASSNAME = 'c-premise-slots'

const PremiseSlots = ({ className, isPremise, premiseInfo }) => {
	const localize = useLocalize()

	const renderPremiseSlots = () => {
		if (isPremise) {
			const { premiseServices = [], activeAdvertsCount = 0, admins = [] } = premiseInfo
			const advertsLimit = premiseServices.length ? premiseServices[0].advertsLimit : undefined
			const email = admins.length ? admins[0].email : ''

			const renderLimit = (limit) => {
				return (
					<div className={`${CLASSNAME}__limits`}>
						<span className={`${CLASSNAME}__limit-used`}>{localize('PremiseSlots.limitUsed')}</span>
						<span
							className={`${CLASSNAME}__limit-value`}
						>{`${activeAdvertsCount}/${limit} ${localize('PremiseSlots.limitValue')}`}</span>
					</div>
				)
			}

			const renderLink = () => {
				const { name } = PremiseService.getPremiseDataForPublic(premiseInfo)
				return (
					<a
						className={`${CLASSNAME}__mailto-link`}
						href={`mailto:${email}?subject=${encodeURIComponent(
							localize('PremiseSlots.emailSubject', {
								PREMISE_NAME: name
							})
						)}`}
					>
						{localize('PremiseSlots.limitLink')}
					</a>
				)
			}

			if (typeof advertsLimit === 'number') {
				return (
					<div
						className={classnames({
							[className]: !!className,
							[CLASSNAME]: true
						})}
					>
						{renderLimit(advertsLimit)}
						{renderLink()}
					</div>
				)
			}
		}

		return null
	}

	return renderPremiseSlots()
}

PremiseSlots.propTypes = {
	className: PropTypes.string,
	isPremise: PropTypes.bool,
	premiseInfo: PropTypes.object
}

PremiseSlots.defaultProps = {
	className: '',
	isPremise: false,
	premiseInfo: DEFAULT_PROPS.OBJECT
}

const selectors = (state) => ({
	isPremise: state[ClientBaseController.STATE_KEYS.IS_PREMISE],
	premiseInfo: state[ClientBaseController.STATE_KEYS.PREMISE_INFO]
})

export default React.memo(select(selectors)(PremiseSlots))
