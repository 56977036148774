
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Home'] = {
  titleCategoryOsobni: function(d) { return "Sauto.cz - Největší online autobazar, prodej aut, inzerce automobilů"; },
  titleCategoryNakladni: function(d) { return "Nákladní auta - Sauto.cz"; },
  titleCategoryObytne: function(d) { return "Obytné přívěsy, vozy a karavany na prodej - Sauto.cz"; },
  titleCategoryPracovniStroje: function(d) { return "Pracovní stroje - traktory, vysokozdvižné vozíky, bagry - Sauto.cz"; },
  titleCategoryUzitkova: function(d) { return "Dodávky a užitkové vozy - Sauto.cz"; },
  titleCategoryCtyrkolky: function(d) { return "Čtyřkolky na prodej - Sauto.cz"; },
  titleCategoryAutobusy: function(d) { return "Autobusy na prodej - Sauto.cz"; },
  titleCategoryPrivesyNavesy: function(d) { return "Návěsy a přívěsy za auto - Sauto.cz"; },
  titleCategoryMotorky: function(d) { return "Motorky na prodej - Sauto.cz"; },
  descriptionCategoryOsobni: function(d) { return "Chcete si koupit auto? Na Sauto.cz v inzerci automobilů najdete přes 80 000 inzerátů nových i ojetých aut na prodej od autobazarů i od lidí. Prodáváte auto? Inzerujte na Sauto.cz."; },
  descriptionCategoryUzitkova: function(d) { return "Nabídka dodávek a užitkových vozů na prodej obsahuje tisíce aktuálních inzerátů. Na Sauto.cz si jistě vyberete nový užitkový vůz nebo svůj prodáte."; },
  descriptionCategoryNakladni: function(d) { return "Vybíráte nákladní auto? Projděte si stovky inzerátů nákladních automobilů na Sauto.cz. Prodáváte-li, zadejte inzerát na prodej nákladních vozidel."; },
  descriptionCategoryMotorky: function(d) { return "Chcete si koupit motorku? Na Sauto.cz v inzerci motorek najdete přes 7 000 inzerátů nových i ojetých motorek na prodej. Prodáváte motorku? Inzerujte na Sauto.cz."; },
  descriptionCategoryObytne: function(d) { return "Cestujte ve vlastním pohodlí, pořiďte si obytné auto! Na Sauto.cz najdete stovky inzerátů. Nákup i prodej karavanů a obytných přívěsů na Sauto.cz je snadný."; },
  descriptionCategoryPracovniStroje: function(d) { return "Vy nejlépe víte, co potřebujete – traktor, bagr, vysokozdvižný vozík nebo nakladač? Pomocníky pro práci najdete v nabídce pracovních strojů na Sauto.cz."; },
  descriptionCategoryCtyrkolky: function(d) { return "Čtyřkolka se hodí na projížďky, závody, ale také pomůže při práci. Zkontrolujte čtyřkolky na prodej na Smoto.cz a vyberte si pro sebe tu nejlepší."; },
  descriptionCategoryAutobusy: function(d) { return "Zvyšte kvalitu svých služeb, pořiďte si nový autobus. Na Sauto.cz si jistě vyberete ten nejvhodnější autobus na prodej."; },
  descriptionCategoryPrivesyNavesy: function(d) { return "Často něco stěhujete či převážíte? Přívěs za auto se bude hodit. Vyberte si na Sauto.cz z bohaté nabídky přívěsů a návěsů ten, který potřebujete."; },
  usefulInformations: function(d) { return "Užitečné informace"; },
  sellerList: function(d) { return "Seznam prodejců"; },
  autoMotoArticles: function(d) { return "Auto-moto články"; },
  catalog: function(d) { return "Katalog modelů"; },
  previous: function(d) { return "Předchozí"; },
  next: function(d) { return "Následující"; },
  whatsNew: function(d) { return "Podívat se co je nového"; },
  close: function(d) { return "Zavřít okno"; },
  welcome: function(d) { return "Vítejte na novém Sauto.cz"; },
  welcomeNews: function(d) { return "Připravili jsme si nový vzhled a spoustu nových funkcí."; },
  lastSearch: function(d) { return "Poslední hledání"; },
  lastSearchNews: function(d) { return "Jedním klikem se dostanete na výpis inzerátů s aktivovanými filtry."; },
  favouriteOffers: function(d) { return "Oblíbené nabídky"; },
  favouriteOffersNews: function(d) { return "Uložte si inzeráty do oblíbených a můžete se k nim pozdeji vrátit."; },
  savedSearch: function(d) { return "Uložené hledání"; },
  savedSearchNews: function(d) { return "Na výpisu inzerátů si uložíte vaše hledání a už vám neunikne žádný inzerát."; },
  comparing: function(d) { return "Porovnání cen s podobnými nabídkami"; },
  comparingNews: function(d) { return "Srovnáváme auta několik měsíců a až z desítek technických parametrů vozidla."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	