
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PopupDemandForm'] = {
  close: function(d) { return "zavřít"; },
  title: function(d) { return "Napsat prodejci zprávu"; },
  price: function(d) { return "cena: "; },
  annualDistance: function(d) { return "roční nájezd: "; },
  period: function(d) { return "doba pronájmu: "; },
  services: function(d) { return "služby: "; },
  messageText: function(d) { return "Hezký den.\nZajímaly by mě bližší informace k inzerátu:\n\n" + d.ADVERT_INFO_TEXT + "\n\nPředem děkuji za odpověď."; },
  messageSentText: function(d) { return "Vaši zprávu jsme úspěšně předali. Brzy se vám někdo ozve"; },
  backToAd: function(d) { return "Zpět na inzerát"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	