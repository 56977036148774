import { FORM_LINES_IDS } from '../BasicInfoFormLineIds'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './BasicInfoFormCS.json'

// server nam vraci error code a fields, coz je pole s idenifikatory
// formularovych prvku, kterych se tato chyba tyka. Pro slozitejsi
// formularove prvky nemusi identifikator ve fields odpovidat
// identifikatoru formline.
export const SPECIAL_ERROR_TO_FORM_LINE_ID_MAPPING = {
	'locality.entity_id': FORM_LINES_IDS.LOCALITY,
	'locality.entity_type': FORM_LINES_IDS.LOCALITY
}

export const getErrorDictionary = (localize) => ({
	[FORM_LINES_IDS.DEAL_TYPE]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.dealTypeNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.dealTypeNotValid')
	},
	[FORM_LINES_IDS.MANUFACTURER_CB]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.manufacturerRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.manufacturerNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.manufacturerNotValid')
	},
	[FORM_LINES_IDS.MODEL_CB]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.modelRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.modelNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.modelNotValid')
	},
	[FORM_LINES_IDS.ADDITIONAL_MODEL_NAME]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.additionalModelNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.additionalModelNotValid')
	},
	[FORM_LINES_IDS.MANUFACTURING_DATE]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.manufacturingDateRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.manufacturingDateNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.manufacturingDateNotValid')
	},
	[FORM_LINES_IDS.VEHICLE_BODY_CB]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.vehicleBodyRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.vehicleBodyNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.vehicleBodyNotValid')
	},
	[FORM_LINES_IDS.DOORS_AND_CAPACITY]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.doorsAndCapacityNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.doorsAndCapacityNotValid')
	},
	[FORM_LINES_IDS.CAPACITY]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.capacityNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.capacityNotValid')
	},
	[FORM_LINES_IDS.DOORS_AND_SEATS]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.doorsAndSeatsNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.doorsAndSeatsNotValid')
	},
	[FORM_LINES_IDS.COLOR_AND_TONE_CB]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.colorAndToneRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.colorAndToneNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.colorAndToneNotValid')
	},
	[FORM_LINES_IDS.COLOR_TYPE_CB]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.colorTypeNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.colorTypeNotValid')
	},
	[FORM_LINES_IDS.AIRCONDITION_CB]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.airconditionNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.airconditionNotValid')
	},
	[FORM_LINES_IDS.AIRBAGS]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.airbagsNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.airbagsNotValid')
	},
	[FORM_LINES_IDS.WEIGHT]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.weightNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.weightNotValid')
	},
	[FORM_LINES_IDS.LOAD_CAPACITY]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.loadCapacityNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.loadCapacityNotValid')
	},
	[FORM_LINES_IDS.BEDS]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.bedsNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.bedsNotValid')
	},
	[FORM_LINES_IDS.ENGINE_VOLUME]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.engineVolumeRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.engineVolumeNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.engineVolumeNotValid')
	},
	[FORM_LINES_IDS.ENGINE_POWER]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.enginePowerRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.enginePowerNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.enginePowerNotValid')
	},
	[FORM_LINES_IDS.FUEL_CB]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.fuelRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.fuelNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.fuelNotValid')
	},
	[FORM_LINES_IDS.AVERAGE_GAS_MILEAGE]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.fuelConsumptionNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.fuelConsumptionNotValid')
	},
	[FORM_LINES_IDS.BATTERY_CAPACITY]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.batteryCapacityNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.batteryCapacityNotValid')
	},
	[FORM_LINES_IDS.VEHICLE_RANGE]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.vehicleRangeNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.vehicleRangeNotValid')
	},
	[FORM_LINES_IDS.AVERAGE_ELECTRIC_MILEAGE]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.electricMileageNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.electricMileageNotValid')
	},
	[FORM_LINES_IDS.GEARBOX_CB]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.gearboxRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.gearboxNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.gearboxNotValid')
	},
	[FORM_LINES_IDS.GEARBOX_LEVELS_CB]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.gearboxLevelsNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.gearboxLevelsNotValid')
	},
	[FORM_LINES_IDS.DRIVE_CB]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.driveNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.driveNotValid')
	},
	[FORM_LINES_IDS.TACHOMETER]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.tachometerRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.tachometerNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.tachometerNotValid'),
		invalid_condition_new_car_tachometer: localize(
			'BasicInfoForm.tachometerNewCarInvalidCondition'
		),
		invalid_condition_demo_car_tachometer: localize(
			'BasicInfoForm.tachometerDemoCarInvalidCondition'
		)
	},
	[FORM_LINES_IDS.MACHINE_HOURS]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.machineHoursRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.machineHoursNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.machineHoursNotValid')
	},
	[FORM_LINES_IDS.IN_OPERATION_DATE]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.inOperationDateRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.inOperationDateNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.inOperationDateNotValid')
	},
	[FORM_LINES_IDS.STK_DATE]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.stkDateNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.stkDateNotValid')
	},
	[FORM_LINES_IDS.COUNTRY_OF_ORIGIN_CB]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.countryOfOriginRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.countryOfOriginNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.countryOfOriginNotValid')
	},
	[FORM_LINES_IDS.DELIVERY_DATE]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.deliveryDateRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.deliveryDateNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.deliveryDateNotValid')
	},
	[FORM_LINES_IDS.DESCRIPTION]: {
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.descriptionNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.descriptionNotValid')
	},
	[FORM_LINES_IDS.PRICE]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.priceRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.priceNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.priceNotValid')
	},
	[FORM_LINES_IDS.PRICE_NOTE]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.priceNoteRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.priceNoteNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.priceNoteNotValid')
	},
	[FORM_LINES_IDS.LOCALITY]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.localityRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.localityNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.localityNotValid')
	},
	[FORM_LINES_IDS.PHONE]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.phoneRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.phoneNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.phoneNotValid')
	},
	[FORM_LINES_IDS.EMAIL]: {
		[ERROR_CODES.REQUIRED]: localize('BasicInfoForm.emailRequired'),
		[ERROR_CODES.NOT_VALID]: localize('BasicInfoForm.emailNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('BasicInfoForm.emailNotValid')
	}
})
