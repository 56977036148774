import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'

const getBrandAndModelsFormLineEntities = (formlineEntities) => {
	const formLineEntityForBrand =
		formlineEntities.find((f) => f.id === FORM_LINES_IDS.MANUFACTURER_CB) || {}

	const formLineEntityForModel =
		formlineEntities.find((f) => f.id === FORM_LINES_IDS.MODEL_CB) || {}

	return [formLineEntityForBrand, formLineEntityForModel]
}

export default getBrandAndModelsFormLineEntities
