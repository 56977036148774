export default class PremiseEntityExport {
	/**
	 * @constructor
	 * @param {Object} data
	 * @param {Number} data.id
	 * @param {String} data.name         Název firmy.
	 * @param {Array}  data.emails       Emaily.
	 * @param {Object} data.subject      Informace o subjektu (ičo, ...).
	 * @param {Array<Object>} data.admins
	 * @param {Number} data.advertReportsCount Počet nahlášení
	 * @param {Number} data.allAdvertsCount Počet inzerátů
	 * @param {Boolean} data.enabled     Určuje jestli je firma aktivní nebo zakázaná.
	 * @param {Array<Object>} data.premiseServices
	 * @param {Array<Object>} data.registrations
	 * @param {Array<Object>} data.softwareKeys
	 * @param {Array<Object>} data.users
	 */
	constructor(data) {
		this.id = data.id
		this.name = data.name
		this.emails = data.emails
		this.subject = data.subject
		this.admins = data.admins
		this.advertReportsCount = data.advertReportsCount
		this.allAdvertsCount = data.allAdvertsCount
		this.enabled = data.enabled
		this.premiseServices = data.premiseServices
		this.registrations = data.registrations
		this.softwareKeys = data.softwareKeys
		this.users = data.users
	}
}
