import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'

export default class FilterDropdownWithOpener extends React.Component {
	static get propTypes() {
		return {
			dropdownDefinition: PropTypes.object,
			context: PropTypes.string,
			value: PropTypes.number,

			renderOpener: PropTypes.func,
			renderDropdown: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			renderOpener: DEFAULT_PROPS.FUNCTION,
			renderDropdown: DEFAULT_PROPS.FUNCTION
		}
	}

	render() {
		const { renderOpener, renderDropdown } = this.props

		const isDropdownOpened = this._isDropdownOpened()

		if (isDropdownOpened) {
			return (
				<React.Fragment>
					{renderOpener()}
					{renderDropdown()}
				</React.Fragment>
			)
		} else {
			return renderOpener()
		}
	}

	_isDropdownOpened() {
		const { dropdownDefinition, value, context } = this.props

		if (context === FILTER_CONTEXT.TAGS && value) {
			return dropdownDefinition && dropdownDefinition.openedByValue === value
		} else if (context) {
			return dropdownDefinition && dropdownDefinition.context === context
		} else {
			return !!dropdownDefinition
		}
	}
}
