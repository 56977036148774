
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Contacts'] = {
  defaultContact: function(d) { return "Hlavní kontakt"; },
  addContact: function(d) { return "Přidat kontakt"; },
  remove: function(d) { return "Odebrat"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	