import React from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import { Spinner } from '@sznds/react'

import './Preloader.less'
import './PreloaderCS.json'

const CLASSNAME = 'c-preloader'

export default function Preloader({ className }) {
	const localize = useLocalize()

	return (
		<div className={`${className} ${CLASSNAME}`}>
			<Spinner className={`${CLASSNAME}__spinner`} />
			<div className={`${CLASSNAME}__title`}>{localize('Preloader.title')}</div>
		</div>
	)
}

Preloader.propTypes = {
	className: PropTypes.string
}

Preloader.defaultProps = {
	className: ''
}
