import RouterEvents from 'ima/router/Events'
import ROUTE_NAMES from 'app/base/RouteNames'
import deepEqual from 'fast-deep-equal'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import ScrollRestore from 'app/helpers/scrollRestore/ScrollRestore'
import UserwebAdvertView from 'app/component/adverts/UserwebAdvertView'

import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import SellerListUrlConvertor from 'app/page/userweb/sellerList/SellerListUrlConvertor'
import ArticlesUrlConvertor from 'app/page/userweb/articles/ArticlesUrlConvertor'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'

import { CLASSNAME as ADVERT_LIST_CLASSNAME } from 'app/component/advertList/AdvertListView'
import { CONTAINER_CLASSNAME as ADVERT_LIST_CONTAINER_CLASSNAME } from 'app/page/userweb/advertList/AdvertListView'
import { CONTENT_CLASSNAME as FAVORITE_LIST_CONTENT_CLASSNAME } from 'app/page/userweb/favoriteList/FavoriteListView'

import { RESPONSIVE } from 'app/base/Constants'

export default class AutoScrollManager {
	constructor($Router, $Dispatcher) {
		this._$Router = $Router
		this._$Dispatcher = $Dispatcher

		this._newRouteName = ''
		this._newRouteParams = {}
		this._prevRouteName = ''
		this._prevRouteParams = {}

		this.handleRouteChange = this.handleRouteChange.bind(this)
	}

	init() {
		try {
			const { params, route } = this._$Router.getCurrentRouteInfo()
			this._newRouteName = route.getName()
			this._newRouteParams = Object.assign({}, params)
		} catch {
			// na chybu nebudeme nijak reagovat
		}

		this._$Dispatcher.listen(RouterEvents.BEFORE_HANDLE_ROUTE, this.handleRouteChange)
	}

	activated() {
		let advertElm

		// Pokud se vracim z detailu inzeratu na vypis inzeratu nebo vypis inzeratu prodejce
		if (
			(this._prevRouteName === ROUTE_NAMES.USERWEB.ADVERT_DETAIL ||
				this._prevRouteName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL) &&
			(this._newRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST ||
				this._newRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES ||
				this._newRouteName === ROUTE_NAMES.USERWEB.SELLER ||
				this._newRouteName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES)
		) {
			const advertId = ScrollRestore.getId(this._newRouteName)

			advertElm = advertId
				? document.querySelector(`.${UserwebAdvertView.getItemClassName(advertId)}`)
				: null

			ScrollRestore.setId(undefined, this._newRouteName)
		}

		// Pokud se vracim z detailu prodejce na vypis prodejcu
		if (
			(this._prevRouteName === ROUTE_NAMES.USERWEB.SELLER ||
				this._prevRouteName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES) &&
			this._newRouteName === ROUTE_NAMES.USERWEB.SELLER_LIST
		) {
			const {
				[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID]: sellerId
			} = this._prevRouteParams

			advertElm = document.querySelector(`[data-premise-id="${sellerId}"]`)
		}

		if (advertElm) {
			animateScrollTo(advertElm.offsetTop + advertElm.clientHeight / 2 - window.innerHeight / 2, {
				minDuration: 0,
				maxDuration: 0
			})
		}
	}

	didOnlyPageChange(perPageParam = UrlConvertor.constants.URL_APP_PARAMS.PAGE) {
		const comparePrevRouteParams = Object.assign({}, this._prevRouteParams)
		const compareNewRouteParams = Object.assign({}, this._newRouteParams)

		const notCompareParams = [
			perPageParam,
			AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_ID,
			AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_HASH,
			AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP
		]

		// pred porovnanim vymazi parametry ktere nechci porovnavat
		notCompareParams.forEach((param) => {
			delete comparePrevRouteParams[param]
			delete compareNewRouteParams[param]
		})

		return deepEqual(comparePrevRouteParams, compareNewRouteParams)
	}

	handleRouteChange(pageData) {
		this._prevRouteName = this._newRouteName
		this._prevRouteParams = Object.assign({}, this._newRouteParams)

		this._newRouteName = pageData.route.getName()
		this._newRouteParams = Object.assign({}, pageData.params)

		// Kam ma stranka nascrolovat pri prichodu na stranku ci zmene strankovani
		switch (this._newRouteName) {
			case ROUTE_NAMES.USERWEB.HOMEPAGE:
				animateScrollTo(0, { minDuration: 0, maxDuration: 0 })
				break

			case ROUTE_NAMES.USERWEB.ADVERT_LIST:
			case ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES: {
				const isMySavedSearchPage = this._newRouteParams.hasOwnProperty(
					AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP
				)
				const wasMySavedSearchPage = this._prevRouteParams.hasOwnProperty(
					AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP
				)

				// poukd predchozi stranka byla ulozene hledani
				if (wasMySavedSearchPage && !isMySavedSearchPage) {
					const isSamePage =
						this._newRouteParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE] ===
						this._prevRouteParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE]

					const hasSortParam = this._prevRouteParams.hasOwnProperty(
						UrlConvertor.constants.URL_APP_PARAMS.SORT
					)

					// a meni se stranka nebo je nastavene sortovani, tak zascroluji na zacatek vypisu
					if (!isSamePage || hasSortParam) {
						this.scrollToStartOfAdvertList()
					}
				} else if (this.didOnlyPageChange()) {
					this.scrollToStartOfAdvertList()
				}
				break
			}

			case ROUTE_NAMES.USERWEB.SELLER_LIST: {
				if (this.didOnlyPageChange(SellerListUrlConvertor.constants.URL_APP_PARAMS.PAGE)) {
					this.scrollToStartOfAdvertList()
				}
				break
			}

			case ROUTE_NAMES.USERWEB.SELLER:
			case ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES: {
				const {
					[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: prevCategory
				} = this._prevRouteParams
				const {
					[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: currentCategory
				} = this._newRouteParams

				if (prevCategory !== currentCategory || this.didOnlyPageChange()) {
					this.scrollToStartOfAdvertList()
				}
				break
			}

			case ROUTE_NAMES.USERWEB.ARTICLES: {
				if (this.didOnlyPageChange(ArticlesUrlConvertor.constants.URL_APP_PARAMS.PAGE)) {
					animateScrollTo(0, { minDuration: 0, maxDuration: 0 })
				}
				break
			}

			case ROUTE_NAMES.USERWEB.ADVERT_DETAIL:
			case ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL: {
				const {
					[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: prevAdvertId
				} = this._prevRouteParams
				const {
					[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: newAdvertId
				} = this._newRouteParams
				if (prevAdvertId !== newAdvertId) {
					animateScrollTo(0, { minDuration: 0, maxDuration: 0 })
				}
				break
			}

			case ROUTE_NAMES.USERWEB.FAVORITE_LIST: {
				const favoriteList = document.querySelector(`.${FAVORITE_LIST_CONTENT_CLASSNAME}`)

				if (favoriteList) {
					animateScrollTo(favoriteList, { minDuration: 0, maxDuration: 0 })
				}
				break
			}
		}
	}

	scrollToStartOfAdvertList(noAnimation = true) {
		const { matches } = window.matchMedia(`(min-width: ${RESPONSIVE.DESKTOP_BRANDING / 16}em)`)

		/**
		 * Ziska element na ktory chceme zascrolovat pri strankovani
		 * pre mobil zascrolujeme na konkretny element
		 * pre desktop kvoli reklame vzdy uplne hore
		 */
		const _getScrollToElement = (elementClassName) => {
			if (matches) {
				return document.body
			} else {
				return document.querySelector(`.${elementClassName}`)
			}
		}

		let scrollToElement
		switch (this._newRouteName) {
			case ROUTE_NAMES.USERWEB.ADVERT_LIST:
			case ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES:
				scrollToElement = _getScrollToElement(ADVERT_LIST_CONTAINER_CLASSNAME)
				break

			case ROUTE_NAMES.USERWEB.SELLER_LIST:
				scrollToElement = _getScrollToElement(ADVERT_LIST_CLASSNAME)
				break

			default:
				scrollToElement = document.querySelector(`.${ADVERT_LIST_CLASSNAME}`)
				break
		}

		if (window && scrollToElement && window.scrollY > scrollToElement.offsetTop) {
			const options = noAnimation ? { minDuration: 0, maxDuration: 0 } : {}

			animateScrollTo(scrollToElement, options)
		}
	}
}
