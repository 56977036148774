
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['SellerList'] = {
  header: function(d) { return "Seznam prodejců a autobazarů"; },
  headerMeta: function(d) { return "Prodejci aut a autobazary"; },
  headerMetaSecond: function(d) { return plural(d.COUNT, 0, cs, { one: "prodejce", few: "prodejci", other: "prodejců" }) + " na jednom místě"; },
  headerLocality: function(d) { return "Autobazar"; },
  czechRepublic: function(d) { return "Česká republika"; },
  sellersCount: function(d) { return plural(d.COUNT, 0, cs, { one: "Prodejce", few: "Prodejci", other: "Prodejců" }); },
  zero: function(d) { return "Nenašli jsme žádný výsledek"; },
  zeroPart1: function(d) { return "Pro \""; },
  zeroPart2: function(d) { return "\" jsme nenašli žádný výsledek"; },
  description: function(d) { return "Hledáte autobazar ve svém okolí? Na Sauto.cz rychle najdete prodejce nových i ojetých automobilů v celé ČR. Včetně nabídky vozů, kontaktů a základních informací."; },
  descriptionLocality: function(d) { return d.COUNT + " " + plural(d.COUNT, 0, cs, { one: "autobazar", few: "autobazary", other: "autobazarů" }) + " a " + plural(d.COUNT, 0, cs, { one: "prodejce", few: "prodejci", other: "prodejců" }) + " vozů"; },
  descriptionLocalitySecond: function(d) { return "Na Sauto.cz rychle najdete prodejce nových i ojetých automobilů."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	