import { AbstractFactory } from '@inzeraty/models'
import MySavedSearchEntity from './MySavedSearchEntity'

export default class MySavedSearchFactory extends AbstractFactory {
	/**
	 * @constructor
	 */
	constructor() {
		super(MySavedSearchEntity)

		this.transformData = this.transformData.bind(this)
		this.transformSummaryData = this.transformSummaryData.bind(this)
	}

	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformData(data = {}) {
		const transformDate = (dateStr) => (dateStr ? new Date(dateStr) : undefined)

		const filter = data.filter || {}

		return {
			id: data.id,
			watchdogHash: data.watchdog_hash,
			notificationInterval: data.notification_interval,
			ribbonNotificationEnabled: data.notification_enabled,
			operatingLease: data.operating_lease,
			filter,
			newCount: data.new_count,
			count: data.count,
			timestamp: data.timestamp,
			createDate: transformDate(data.create_date)
		}
	}

	/**
	 * Prevede data z api na data pro app.
	 *
	 * @method transformSummaryData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformSummaryData(data = {}) {
		return {
			newAdvertsCount: data.new_adverts_count,
			mySavedSearchesCount: data.watchdogs_count
		}
	}
}
