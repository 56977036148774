import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import FilterPopup from 'app/component/filters/components/filterPopup/FilterPopup'

import './BasicFilterPopup.less'

const CLASSNAME = 'c-basic-filter-popup'

export default class BasicFilterPopup extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			title: PropTypes.string.isRequired,
			filterPopupLogicData: PropTypes.object.isRequired,
			renderContent: PropTypes.func.isRequired,
			renderFooter: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			filterPopupLogicData: DEFAULT_PROPS.OBJECT,
			renderContent: DEFAULT_PROPS.FUNCTION,
			renderFooter: DEFAULT_PROPS.FUNCTION
		}
	}

	render() {
		const { formLineEntity, title, filterPopupLogicData, renderContent, renderFooter } = this.props
		const { isDefaultPopupType, closePopup, resetFilter } = filterPopupLogicData

		// kvuli problemum se scrollovanim na iOS je potreba predat
		// referenci na scrollovany obsah
		const scrollContentRef = React.createRef()

		return (
			<FilterPopup
				className={`${CLASSNAME}__content`}
				filterId={formLineEntity.id}
				title={title}
				defaultType={isDefaultPopupType}
				onClose={closePopup}
				onReset={resetFilter}
				contentRef={scrollContentRef}
			>
				<div
					data-e2e={`cf-popup-form-${formLineEntity.id}`}
					className={`${CLASSNAME}__form`}
					ref={scrollContentRef}
				>
					{renderContent(filterPopupLogicData)}
				</div>

				{renderFooter(filterPopupLogicData)}
			</FilterPopup>
		)
	}
}
