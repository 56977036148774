
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['BasicInfoHeader'] = {
  heading: function(d) { return "Typ inzerátu"; },
  category: function(d) { return "Kategorie"; },
  condition: function(d) { return "Stav"; },
  vin: function(d) { return "VIN"; },
  edit: function(d) { return "Upravit"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	