import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Input } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import { Icon } from '@sznds/react'
import PropTypes from 'prop-types'

import './SearchInput.less'
import './SearchInputCS.json'

const CLASSNAME = 'c-search-input'

export default function SearchInput({ onSubmit, inputValue, className }) {
	const localize = useLocalize()
	const [value, setValue] = useState(inputValue)

	useEffect(() => {
		setValue(inputValue)
	}, [inputValue])

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					onSubmit(value)
				}}
				noValidate
				role='search'
			>
				<Input
					className={`${CLASSNAME}__input`}
					placeholder={localize('SearchInput.search')}
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
				<button
					type='submit'
					className={`${CLASSNAME}__submit-btn`}
					title={localize('SearchInput.search')}
					onSubmit={(e) => {
						e.preventDefault()
						onSubmit(value)
					}}
				>
					<Icon symbol={SEARCH_OUTLINE_24} />
				</button>
			</form>
		</div>
	)
}

SearchInput.propTypes = {
	onSubmit: PropTypes.func,
	inputValue: PropTypes.string,
	className: PropTypes.string
}
