import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import BasicWidget from '../basic/BasicWidget'
import * as FormLines from '@inzeraty/form-lines'
import BaseNumberWidget, {
	NUMBER_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/number/NumberWidget'

export { NUMBER_WIDGET_ID }

const CLASSNAME = 'c-review-number-widget'

const NumberWidget = ({
	formLineEntity = DEFAULT_PROPS.OBJECT,
	onChange = DEFAULT_PROPS.FUNCTION,
	onBlur = DEFAULT_PROPS.FUNCTION
}) => {
	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={NUMBER_WIDGET_ID}
			renderInput={(formLineEntity, props) => (
				<BaseNumberWidget
					{...props}
					formLineEntity={formLineEntity}
					onChange={onChange}
					onBlur={onBlur}
				/>
			)}
		/>
	)
}

NumberWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func
}

export default React.memo(NumberWidget)
