import * as FormLines from '@inzeraty/form-lines'
import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import IAAdvertEditBaseController from '../IAAdvertEditBaseController'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import {
	createFormLineEntities,
	createDeliveryFormLineEntities,
	getFormData
} from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLines'
import { isNewVehicleConditionSelected } from './IAAdvertBasicInfoEditFormLines'
import clearCebiaErrorFlags from 'app/page/userweb/newAdvert/basicInfo/cebia/clearCebiaErrorFlags'
import {
	flattenCreatePageData,
	processOptionsForFormLineEntities
} from 'app/page/userweb/newAdvert/basicInfo/createPage/processDataForFormLineEntities'
import applyOptionsForFormLineEntities from 'app/page/userweb/newAdvert/basicInfo/createPage/applyOptionsForFormLineEntities'
import getBrandOption from 'app/page/userweb/newAdvert/basicInfo/models/getBrandOption'
import getBrandAndModelsFormLineEntities from 'app/page/userweb/newAdvert/basicInfo/models/getBrandAndModelsFormLineEntities'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'
import STEPS_STATE_KEYS from 'app/page/userweb/newAdvert/component/stepper/StepperStateKeys'
import { loadDataForSteps } from 'app/page/userweb/newAdvert/component/stepper/StepperUtils'
import STATE_KEYS from './IAAdvertBasicInfoEditStateKeys'
import { getSteps } from '../stepper/steps'
import { VIN_WIDGET_ID } from '../widgets/vin/VINWidget'
import { CATEGORY_WIDGET_ID } from '../widgets/vehicleCategory/VehicleCategoryWidget'
import { CONDITION_WIDGET_ID } from '../widgets/vehicleCondition/VehicleConditionWidget'
import ADD_VIN_STATE_KEYS from 'app/page/userweb/newAdvert/addVIN/AddVINStateKeys'
import { FORM_IDS } from 'app/page/userweb/newAdvert/addVIN/components/AddVINForm'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import filterFormLinesForCebia from 'app/page/userweb/newAdvert/basicInfo/cebia/filterFormLinesForCebia'
import processDataFromCebia from 'app/page/userweb/newAdvert/basicInfo/cebia/processDataFromCebia'
import { CATEGORIES, CEBIA_CATEGORY_ID } from 'app/base/Constants'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { StatusMessage } from '@inzeraty/components'
import OPERATING_LEASE_DEFINITION from 'app/component/operatingLease/OperatingLeaseDefinition'

import 'app/page/userweb/newAdvert/basicInfo/BasicInfoCS.json'
import 'app/base/ActionMessageCS.json'

const TERMS_OF_USE_API_PARAM = 'terms_of_use'
const PREMISE_ID = 'premise_id'

export default class IAAdvertBasicInfoEditController extends IAAdvertEditBaseController {
	constructor(
		dependenciesHelper,
		localitySelectInputExtension,
		advertService,
		codebooksService,
		VINVehicleDataService,
		operatingLeaseService
	) {
		super(dependenciesHelper)

		this._localitySelectInputExtension = localitySelectInputExtension

		this._advertService = advertService
		this._codebooksService = codebooksService
		this._VINVehicleDataService = VINVehicleDataService
		this._operatingLeaseService = operatingLeaseService

		this._dictionary = this._utils.$Dictionary

		this._loadModels = this._loadModels.bind(this)
		this._clearModels = this._clearModels.bind(this)
		this._loadDataFromCebia = this._loadDataFromCebia.bind(this)

		this._changeFormLineEntities = this._changeFormLineEntities.bind(this)
		this._submitForm = this._submitForm.bind(this)
		this._submitExpirationDateChange = this._submitExpirationDateChange.bind(this)

		this.closeCebiaPopup = this.closeCebiaPopup.bind(this)
		this.useUserCategory = this.useUserCategory.bind(this)
		this.useCebiaCategory = this.useCebiaCategory.bind(this)

		this._refetchAllOperatingLeasesVariants = this._refetchAllOperatingLeasesVariants.bind(this)
		this._addNewOperatingLeaseVariant = this._addNewOperatingLeaseVariant.bind(this)
		this._editOperatingLeaseVariant = this._editOperatingLeaseVariant.bind(this)
		this._deleteOperatingLeaseVariant = this._deleteOperatingLeaseVariant.bind(this)

		this._addVINFormLineEntities = []
		this._categories = Object.values(CATEGORIES)
		this._advertId = ''
		this._cebiaData = {}
		this._onGetCebiaDataCallback = DEFAULT_PROPS.FUNCTION
	}

	init() {
		super.init()

		this.addExtension(this._localitySelectInputExtension)
	}

	load() {
		this._advertId = this._getAdvertId()

		const advertEntityPromise = this._advertService.getAdvertDetail(
			Number(this._advertId),
			{ for_editing: 1 },
			{ cache: false }
		)

		const steps = this._getSteps(advertEntityPromise)

		// sestaveni prvku na formulari
		const formLineEntitiesPromise = advertEntityPromise.then((advertEntity) => {
			const { category = {}, conditionCb: condition = {}, vin = '' } = advertEntity

			this._addVINFormLineEntities = FormLines.createEntityList([
				{
					widget: CATEGORY_WIDGET_ID,
					id: FORM_IDS.VEHICLE_CATEGORY,
					label: this._dictionary.get('AddVINForm.vehicleCategoryLabel'),
					required: true,
					options: this._categories,
					value: category.id || undefined,
					extra: {
						getFormData: (value) => ({
							category_id: value
						}),
						isFormLineValid: () => true
					}
				},
				{
					widget: CONDITION_WIDGET_ID,
					id: FORM_IDS.VEHICLE_CONDITION,
					label: this._dictionary.get('AddVINForm.vehicleConditionLabel'),
					required: true,
					options: [],
					value: condition.value || undefined,
					extra: {
						getFormData: (value) => ({
							condition_cb: value
						}),
						isFormLineValid: () => true
					}
				},
				{
					widget: VIN_WIDGET_ID,
					id: FORM_IDS.VEHICLE_VIN,
					label: this._dictionary.get('AddVINForm.vinLabel'),
					required: true,
					placeholder: this._dictionary.get('AddVINForm.vinPlaceholder'),
					value: vin,
					extra: {
						getFormData: (value) => ({
							vin: value
						}),
						// validaci nechame na serveru
						isFormLineValid: () => true
					}
				}
			])

			return this._getFormLineEntities(advertEntity)
		})

		// nacteni vsech moznosti pro selecty na formulari
		const dataForFormLineEntitiesLoadedPromise = Promise.all([
			formLineEntitiesPromise,
			advertEntityPromise
		]).then(async ([formLineEntities, advertEntity]) => {
			const { category: { id: categoryId } = {}, premise } = advertEntity

			const premiseDisabledWidgetIds = [
				FORM_LINES_IDS.LOCALITY,
				FORM_LINES_IDS.PHONE,
				FORM_LINES_IDS.EMAIL
			]

			formLineEntities.map((formLineEntity) => {
				if (premiseDisabledWidgetIds.includes(formLineEntity.id)) {
					formLineEntity.disabled = !!premise
				}
			})

			await this._getCreatePage(categoryId, formLineEntities)
		})

		// nacteni dat pro specialni select s modely, kde nejprve musime pockat,
		// az bude nacten seznam znacek
		Promise.all([advertEntityPromise, dataForFormLineEntitiesLoadedPromise]).then(
			([advertEntity]) => {
				const { manufacturerCb: { value: selectedBrandValue } = {} } = advertEntity

				if (selectedBrandValue) {
					this._loadModels(selectedBrandValue)
				}
			}
		)

		const loadOperatingLeaseFieldsData = async (advertEntityPromise) => {
			const { category: { id: categoryId } = {} } = await advertEntityPromise

			// getCreatePage si muzeme dovolit zavolat znovu, protoze data jsou nacacheovana
			const createPageData = await this._advertService.getCreatePage(
				{
					[AdvertAdminUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId
				},
				{ cache: true }
			)

			const flatCreatePageData = flattenCreatePageData(createPageData)

			const operatingLeaseDefData = flatCreatePageData.filter(({ name }) =>
				Object.values(OPERATING_LEASE_DEFINITION).includes(name)
			)

			this.setState({
				[STATE_KEYS.OPERATING_LEASE_DEFINITION_DATA]: operatingLeaseDefData
			})
		}

		loadOperatingLeaseFieldsData(advertEntityPromise)

		return super.load({
			[STEPS_STATE_KEYS.STEPS]: steps,

			[STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntitiesPromise,
			[STATE_KEYS.ON_CHANGE]: this._changeFormLineEntities,

			[STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise,

			[STATE_KEYS.LOAD_MODELS]: this._loadModels,
			[STATE_KEYS.CLEAR_MODELS]: this._clearModels,

			[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false,
			[STATE_KEYS.SUBMIT_FORM]: this._submitForm,
			[STATE_KEYS.SUBMIT_EXPIRATION_DATE_CHANGE]: this._submitExpirationDateChange,

			[ADD_VIN_STATE_KEYS.CEBIA_POPUP_OPEN]: false,
			[ADD_VIN_STATE_KEYS.CLOSE_CEBIA_POPUP]: this.closeCebiaPopup,
			[ADD_VIN_STATE_KEYS.KEEP_CEBIA_CATEGORY]: this.useUserCategory,
			[ADD_VIN_STATE_KEYS.CHANGE_CEBIA_CATEGORY]: this.useCebiaCategory,
			[ADD_VIN_STATE_KEYS.CEBIA_CATEGORY]: '',
			[ADD_VIN_STATE_KEYS.USER_CATEGORY]: '',

			[STATE_KEYS.OPERATING_LEASE_VARIANTS]: advertEntityPromise.then(
				(advertEntity = {}) => advertEntity.operatingLeaseVariants
			),
			[STATE_KEYS.FETCH_OPERATING_LEASES_VARIANTS]: this._refetchAllOperatingLeasesVariants,
			[STATE_KEYS.ADD_NEW_OPERATING_LEASE_VARIANT]: this._addNewOperatingLeaseVariant,
			[STATE_KEYS.EDIT_OPERATING_LEASE_VARIANT]: this._editOperatingLeaseVariant,
			[STATE_KEYS.DELETE_OPERATING_LEASE_VARIANT]: this._deleteOperatingLeaseVariant
		})
	}

	async _refetchAllOperatingLeasesVariants() {
		const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity = {} } = this.getState()
		const { id } = advertEntity

		if (id) {
			const {
				operatingLeaseVariantEntities
			} = await this._operatingLeaseService.getOperatingLeaseVariants(
				{ advert_id: id, limit: 100 },
				{ cache: false }
			)

			this.setState({
				[STATE_KEYS.OPERATING_LEASE_VARIANTS]: operatingLeaseVariantEntities
			})
		}
	}

	async _addNewOperatingLeaseVariant(formData = {}) {
		const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity = {} } = this.getState()
		const { id } = advertEntity

		if (id) {
			await this._operatingLeaseService.addOperatingLeaseVariant(
				Object.assign({ advert_id: id }, formData)
			)

			await this._refetchAllOperatingLeasesVariants()
		}
	}

	async _editOperatingLeaseVariant(variantToEdit = {}) {
		const { id } = variantToEdit

		if (id) {
			await this._operatingLeaseService.editOperatingLeaseVariant(variantToEdit)

			await this._refetchAllOperatingLeasesVariants()
		}
	}

	async _deleteOperatingLeaseVariant(variantToDelete = {}) {
		const { id } = variantToDelete

		if (id) {
			await this._operatingLeaseService.deleteOperatingLeaseVariant(id)

			await this._refetchAllOperatingLeasesVariants()
		}
	}

	closeCebiaPopup() {
		this.setState({
			[ADD_VIN_STATE_KEYS.CEBIA_POPUP_OPEN]: false
		})
	}

	useUserCategory() {
		this.closeCebiaPopup()
		this._useCebiaData()
	}

	useCebiaCategory() {
		this.closeCebiaPopup()

		const { [ADD_VIN_STATE_KEYS.CEBIA_CATEGORY]: cebiaCategoryName } = this.getState()

		const cebiaCategory = this._categories.find((cat) => cat.name === cebiaCategoryName)
		const { id: cebiaCategoryId } = cebiaCategory || ''

		this._useCebiaData(cebiaCategoryId)
	}

	_getSteps(advertEntityPromise) {
		return loadDataForSteps(
			this._advertId,
			advertEntityPromise,
			this._advertService,
			(categoryId) => getSteps(categoryId, this._advertId, this._utils.$Router)
		)
	}

	_getFormLineEntities(advertEntity) {
		const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities = [] } = this.getState()
		const isAdvertEnteredByPremise = !!advertEntity.premise

		// - pokud jsou ve state addVINFormLineEntities, tak se je preberu i s aktualnimi hodnotami
		const addVINFormLineEntities = this._addVINFormLineEntities.map((formLineEntity) => {
			const stateFormLineEntity = formLineEntities.find(
				(stateFormLineEntity) => stateFormLineEntity.id === formLineEntity.id
			)
			return stateFormLineEntity || formLineEntity
		})

		return [
			...addVINFormLineEntities,
			...createFormLineEntities(advertEntity, isAdvertEnteredByPremise, this._dictionary)
		]
	}

	async _loadDataFromCebia(vin) {
		if (vin && vin.trim()) {
			// pokud byl uzivatelem zadan VIN vozidla, tak se dotazeme na API,
			// ktere nam z Cebie stahne dostupna data o vozidle (znacka,
			// model atd.). V tomto kroku zadavani noveho inzeratu
			// predvyplnime uzivateli temito daty formular.
			const cachedCebiaData = this._VINVehicleDataService.getVehicleDataCached(vin)

			if (cachedCebiaData) {
				return Promise.resolve(cachedCebiaData)
			} else {
				try {
					return await this._VINVehicleDataService.getVehicleData(vin)
				} catch (error) {
					// pokud se neco pokazi pri stahovani dat z Cebie,
					// tak i presto umoznime uzivateli pokracovat v
					// zadavani noveho inzeratu
				}
			}
		}

		return Promise.resolve({})
	}

	async onGetCebiaData({ vin = '', callback = DEFAULT_PROPS.FUNCTION }) {
		this._onGetCebiaDataCallback = callback

		this._clearCebiaData()

		const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()
		const categoryId = this._getFormLineEntityValue(formLineEntities, FORM_IDS.VEHICLE_CATEGORY)

		this._cebiaData = await this._loadDataFromCebia(vin)

		// - pokud je kategorie z CEBIE stejna jako vyplnena,
		// - nebo kategorie z CEBIE neni znama, tak rovnou pouziji data z CEBIE
		if (categoryId === this._cebiaData[CEBIA_CATEGORY_ID] || !this._cebiaData[CEBIA_CATEGORY_ID]) {
			this._useCebiaData()
		} else {
			const userCategory = this._categories.find((cat) => cat.id === categoryId)
			const cebiaCategory = this._categories.find(
				(cat) => cat.id === this._cebiaData[CEBIA_CATEGORY_ID]
			)

			const { name: userCategoryName } = userCategory || {}
			const { name: cebiaCategoryName } = cebiaCategory || {}

			this.setState({
				[ADD_VIN_STATE_KEYS.CEBIA_POPUP_OPEN]: true,
				[ADD_VIN_STATE_KEYS.CEBIA_CATEGORY]: cebiaCategoryName,
				[ADD_VIN_STATE_KEYS.USER_CATEGORY]: userCategoryName
			})
		}
	}

	async _useCebiaData(cebiaCategoryId = '') {
		if (cebiaCategoryId) {
			this._changeFormLineEntities([
				{
					id: FORM_IDS.VEHICLE_CATEGORY,
					value: cebiaCategoryId,
					errorMessage: ''
				}
			])

			const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntitiesForCreatePage } = this.getState()

			await this._getCreatePage(cebiaCategoryId, formLineEntitiesForCreatePage)
		}

		await this._loadModels(this._cebiaData.manufacturerCb)

		const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()

		const { processedData, failedFormLineIds } = processDataFromCebia(
			this._cebiaData,
			formLineEntities
		)

		this._changeFormLineEntities([
			...processedData,
			...failedFormLineIds.map((id) => {
				const failedFormLineEntity = formLineEntities.find((f) => f.id === id)

				return {
					id,
					extra: failedFormLineEntity
						? Object.assign({}, failedFormLineEntity.extra, { cebiaError: true })
						: {}
				}
			})
		])

		this._onGetCebiaDataCallback()
	}

	_getStateCategoryId() {
		const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()
		return this._getFormLineEntityValue(formLineEntities, FORM_IDS.VEHICLE_CATEGORY)
	}

	_clearCebiaData() {
		const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities } = this.getState()

		// - promazu udeje, ktere mohu nacist z CEBIE
		const clearChenges = filterFormLinesForCebia(formLineEntities).map((formLineEntity) => {
			const { id } = formLineEntity

			return {
				id,
				value: undefined,
				errorMessage: undefined
			}
		})

		this._changeFormLineEntities(clearChenges)
	}

	async _getCreatePage(categoryId, formLineEntities) {
		const createPageData = await this._advertService.getCreatePage(
			{
				[UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId
			},
			{ cache: true }
		)

		const formLineEntitiesOptions = processOptionsForFormLineEntities(
			createPageData,
			this._dictionary
		)

		const changes = applyOptionsForFormLineEntities(formLineEntities, formLineEntitiesOptions)

		this._changeFormLineEntities(changes)
	}

	_getChangesForDeliveryFormLinesUpdate(formLineEntities = [], changes = []) {
		const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity } = this.getState()

		const isVehicleConditionChanged = !!changes.find(({ id }) => id === FORM_IDS.VEHICLE_CONDITION)

		if (isVehicleConditionChanged) {
			const isAdvertEnteredByPremise = !!advertEntity.premise

			// pri zmene stavu vozidla upravime 'extra' na formlinech pro doruceni vozidla.
			// V 'extra' jsou funkce, ktere se volaji pri odeslani formulare, takze aby
			// bylo vse nachystane, pokud se uzivatel rozhodne zmeny ulozit.
			const deliveryFormLinesUpdate = createDeliveryFormLineEntities(
				advertEntity,
				isAdvertEnteredByPremise,
				isNewVehicleConditionSelected(formLineEntities),
				this._dictionary
			).map(({ id, extra }) => ({ id, extra }))

			return deliveryFormLinesUpdate
		}

		return []
	}

	_changeFormLineEntities(changesParam = []) {
		const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities = [] } = this.getState()

		const changes = changesParam.filter((change) => !!change)

		const updatedChanges = clearCebiaErrorFlags(formLineEntities, changes)
		const updatedFormLineEntities = FormLines.updateEntities(formLineEntities, updatedChanges)

		const deliveryChanges = this._getChangesForDeliveryFormLinesUpdate(
			updatedFormLineEntities,
			updatedChanges
		)
		const newFormLineEntities = FormLines.updateEntities(updatedFormLineEntities, deliveryChanges)

		const isCategoryChanged = this._hasFormLineEntityChanged(
			newFormLineEntities,
			FORM_IDS.VEHICLE_CATEGORY
		)

		// pokud se zmenila kategorie, nactu si nove formLineEntities a createPage
		if (isCategoryChanged) {
			this._categoryDidChanged(newFormLineEntities, updatedChanges)
		} else {
			this.setState({
				[STATE_KEYS.FORM_LINE_ENTITIES]: newFormLineEntities
			})
		}
	}

	_categoryDidChanged(formLineEntities, changes) {
		const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity } = this.getState()

		const categoryId = this._getFormLineEntityValue(formLineEntities, FORM_IDS.VEHICLE_CATEGORY)

		const category = this._categories.find((category) => category.id === categoryId)
		const newAdvertEntity = Object.assign({}, advertEntity, {
			category,
			manufacturerCb: undefined,
			modelCb: undefined,
			vehicleBodyCb: undefined
		})

		const categoryFormLineEntities = FormLines.updateEntities(
			this._getFormLineEntities(newAdvertEntity),
			[
				...changes,
				{
					id: FORM_LINES_IDS.MANUFACTURER_CB,
					value: undefined,
					errorMessage: undefined,
					options: []
				},
				{
					id: FORM_LINES_IDS.MODEL_CB,
					value: undefined,
					errorMessage: undefined,
					options: []
				}
			]
		)

		this._getCreatePage(categoryId, categoryFormLineEntities)

		// - pro ziskani dat pro krokovatko si ulozim k aktualnimu id inzeratu novou kategorii
		this._advertService.saveAdvertCachedData(this._advertId, { categoryId }, false)

		this._getSteps(Promise.resolve(advertEntity)).then((steps) => {
			this.setState({
				[STEPS_STATE_KEYS.STEPS]: steps
			})
		})

		this.setState({
			[STATE_KEYS.ADVERT_ENTITY]: newAdvertEntity,
			[STATE_KEYS.FORM_LINE_ENTITIES]: categoryFormLineEntities
		})
	}

	_getFormLineEntityValue(formLineEntities = [], formLineEntityId = '') {
		const { value } = formLineEntities.find((change) => change.id === formLineEntityId) || {}
		return value
	}

	_hasFormLineEntityChanged(newFormLineEntities, formLineEntityId) {
		const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities = [] } = this.getState()

		const newValue = this._getFormLineEntityValue(newFormLineEntities, formLineEntityId)
		const value = this._getFormLineEntityValue(formLineEntities, formLineEntityId)

		return value !== newValue
	}

	async _loadModels(brandValueToLoadModelsFor) {
		const changeModelsFormLineEntity = (data) => {
			this._changeFormLineEntities([Object.assign({}, data, { id: FORM_LINES_IDS.MODEL_CB })])
		}

		if (brandValueToLoadModelsFor) {
			const { [STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities = [] } = this.getState()

			const [formLineEntityForBrand, formLineEntityForModel] = getBrandAndModelsFormLineEntities(
				formLineEntities
			)

			const brand = getBrandOption(brandValueToLoadModelsFor, formLineEntityForBrand)

			if (brand) {
				changeModelsFormLineEntity({
					options: [],
					extra: Object.assign({}, formLineEntityForModel.extra, { waiting: true })
				})

				const models = await this._codebooksService.getCodebooks({
					[AdvertAdminUrlConvertor.constants.URL_API_PARAMS.PARENT_ID]: brand.id
				})

				changeModelsFormLineEntity({
					options: models,
					extra: Object.assign({}, formLineEntityForModel.extra, { waiting: false })
				})
			}
		}
	}

	_clearModels() {
		this._changeFormLineEntities([
			{
				id: FORM_LINES_IDS.MODEL_CB,
				value: undefined,
				errorMessage: undefined,
				options: []
			}
		])
	}

	async _submitForm({ formLineEntities = [], onErrorCallback = () => {} }) {
		const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity = {} } = this.getState()
		const { premise } = advertEntity

		// povinna data, ktera musime poslat s daty z formulare, aby
		// prosla validace na serveru
		const defaultFormData = {
			[TERMS_OF_USE_API_PARAM]: true //TODO je potreba doresit s backendem, zatim posilame porad true
		}

		const formData = Object.assign(
			{},
			getFormData(formLineEntities),
			defaultFormData,
			// pro bazarnika posleme i jeho idecko, at neztratime prolinkovani mezi inzeratem a bazarem
			premise && {
				[PREMISE_ID]: premise.id
			}
		)

		const { [FORM_IDS.VEHICLE_VIN]: vin } = formData

		const newFormData = Object.assign({}, formData, {
			// na API nemuzeme posilat prazdny string, povazovalo by se to
			// za zadany VIN, nad kterym by probehla validace
			[FORM_IDS.VEHICLE_VIN]: vin || null
		})

		this._pageLoaderExtension.show()

		try {
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: true
			})

			// pokud inzerat neobsahuje vyrobce, tak predpokladame,
			// ze se jedna o prvni pruchod timto krokem editace
			const firstRun = !advertEntity.manufacturerCb

			if (firstRun) {
				await this._advertService.putAdvert(this._advertId, newFormData)
			} else {
				// pro dalsiho pruchody timto krokem je potreba volat PATCH,
				// aby nedoslo k prepsani cele entity a tim padem i dat z dalsich
				// kroku editace
				await this._advertService.patchAdvert(this._advertId, newFormData)
			}

			this._onSubmitFormSuccessCallback()
		} catch (error) {
			const errors = this._processServerErrors(error)
			this._onSubmitFormErrorCallback()
			onErrorCallback(errors)
		} finally {
			this._pageLoaderExtension.hide()
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false
			})
		}
	}

	async _submitExpirationDateChange(validToDate = '') {
		try {
			const apiParam = Object.freeze({
				[UrlConvertor.constants.URL_API_PARAMS.VALID_TO]: validToDate
			})

			await this._advertService.patchAdvert(this._advertId, apiParam)

			try {
				await this._advertService.updateAdvertStatus({
					ids: [this._advertId],
					status_active: true
				})

				this._showStatusMessageToast({
					type: StatusMessage.TYPE.SUCCESS,
					title: this._dictionary.get('ActionMessage.titleSuccess'),
					text: this._dictionary.get('ActionMessage.prolongSuccess', {
						IDS: this._getAdvertId(),
						DATE: Format.date(validToDate)
					})
				})
			} catch (err) {
				this._showStatusMessageToast({
					type: StatusMessage.TYPE.ERROR,
					title: this._dictionary.get('ActionMessage.titleError'),
					text: `${this._dictionary.get('ActionMessage.prolongError', {
						IDS: this._getAdvertId()
					})}, zkuste to prosím manuálně na seznamu inzerátů`
				})
			}
		} catch (error) {
			const errors = this._processServerErrors(error)
			this._showStatusMessageToast({
				type: StatusMessage.TYPE.ERROR,
				title: this._dictionary.get('ActionMessage.titleError'),
				text: errors.length
					? 'Datum a čas expirace nesmí být naplánován na více než 30 dní dopředu a zároveň nesmí být v minulosti'
					: 'Datum a čas expirace se nepovedlo uložit'
			})
		}
	}

	_showStatusMessageToast(messageData) {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, messageData)
	}

	_processServerErrors(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)
		let errors = []

		if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(error)
			errors = body.errors
		}

		return errors
	}

	// - v IA je mozne editovat telefon bez validace
	onValidatePhone({ phone = '', callback = () => {} } = {}) {
		callback(phone, true)
	}

	// - v IA je mozne editovat email bez validace
	onValidateEmail({ callback = () => {} }) {
		callback(true)
	}
}
