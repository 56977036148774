
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['MySavedSearchesLink'] = {
  title: function(d) { return "Uložená hledání"; },
  logoutSecondLine: function(d) { return "Nejste přihlášen"; },
  searchCount: function(d) { return "Máte"; },
  searchCountPlural: function(d) { return plural(d.COUNT, 0, cs, { one: "uložené", few: "uložená", other: "uložených" }); },
  searches: function(d) { return "hledání"; },
  noSearchesSaved: function(d) { return "Nemáte žádná uložená hledání"; },
  newAdvertsCount: function(d) { return plural(d.COUNT, 0, cs, { one: d.COUNT + " nový", few: d.COUNT + " nové", other: d.COUNT + " nových" }); }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	