
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['VideoPreview'] = {
  removeError: function(d) { return "Video se nepodařilo smazat"; },
  cancelError: function(d) { return "Zrušení nahrávání videa se nepodařilo"; },
  removeLabel: function(d) { return "Smazat video"; },
  pinTitle: function(d) { return "Nastavit jako titulní video"; },
  pinTitleAria: function(d) { return "Nastavit " + d.INDEX + ". z " + d.COUNT + " jako titulní video"; },
  loading: function(d) { return "Nahráváme …"; },
  loadingNote: function(d) { return "Velké soubory mohou trvat nahrát až několik minut"; },
  loadingCancel: function(d) { return "Zrušit nahrávání"; },
  removing: function(d) { return "Mažeme …"; },
  removingNote: function(d) { return "Provádí se mazání videa ze serveru"; },
  canceling: function(d) { return "Rušíme …"; },
  cancelingNote: function(d) { return "Provádí se zrušení nahrávaní videa na server"; },
  processing: function(d) { return "Ukládáme …"; },
  processingNote: function(d) { return "Nahrané video se objeví na profilu do několika minut. Mezitím můžete pokračovat dál v editaci inzerátu"; },
  pinConfirmation: function(d) { return "Titulní video bylo změněno"; },
  firstVideo: function(d) { return "Titulní video"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	