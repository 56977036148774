import React, { memo } from 'react'
import { Checkbox } from '@sznds/react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const WidgetCheckbox = memo(({ className, formLineEntity = {}, onChange }) => {
	const { id, label, value } = formLineEntity

	return (
		<div className={className}>
			<Checkbox
				label={label}
				checked={value}
				onChange={(event) => {
					event.stopPropagation()
					onChange({
						id,
						value: !value
					})
				}}
			/>
		</div>
	)
})

WidgetCheckbox.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func,
	className: PropTypes.string
}

export default WidgetCheckbox
