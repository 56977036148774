import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Icon, Surface } from '@sznds/react'
import { CLOSE_OUTLINE_16, CHEVRON_RIGHT_OUTLINE_24 } from '@sznds/icons'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './OpenerView.less'

const CLASSNAME = 'cf-opener'

const ResetButton = ({ onResetClick }) => {
	return (
		<button
			type='button'
			className={`${CLASSNAME}__clear`}
			onClick={onResetClick}
			data-dot='delete'
		>
			<Icon symbol={CLOSE_OUTLINE_16} />
		</button>
	)
}

ResetButton.propTypes = {
	hasValue: PropTypes.bool,
	onResetClick: PropTypes.func.isRequired
}

ResetButton.defaultProps = {
	hasValue: false,
	onResetClick: DEFAULT_PROPS.FUNCTION
}

const Opener = ({ hasValue, iconSymbol, onClick, surface, text, onResetClick, openerRef }) => {
	const [isFocused, setFocused] = useState(false)

	return (
		<Surface surface={surface} clickable='true' className={CLASSNAME} onClick={() => {}}>
			<button
				type='button'
				className={classnames({
					'sds-button': true,
					[`${CLASSNAME}__btn`]: true,
					[`${CLASSNAME}__btn--with-val`]: hasValue,
					[`${CLASSNAME}--focused`]: isFocused
				})}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				onClick={() => onClick()}
				data-dot='show-values'
				ref={openerRef}
			>
				<span className={`${CLASSNAME}__text`}>{text}</span>
				{!hasValue && <Icon symbol={iconSymbol} />}
			</button>
			{hasValue && <ResetButton onResetClick={onResetClick} />}
		</Surface>
	)
}

Opener.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	hasValue: PropTypes.bool,
	iconSymbol: PropTypes.object,
	onClick: PropTypes.func.isRequired,
	onResetClick: PropTypes.func.isRequired,
	surface: PropTypes.number,
	openerRef: PropTypes.object
}

Opener.defaultProps = {
	hasValue: false,
	onResetClick: DEFAULT_PROPS.FUNCTION,
	iconSymbol: CHEVRON_RIGHT_OUTLINE_24,
	surface: 4
}

export default Opener
