import { AbstractFactory } from '@inzeraty/models'
import MirroringEntity from './MirroringEntity'

export default class MirroringFactory extends AbstractFactory {
	constructor() {
		super(MirroringEntity)
	}

	transformData(data = {}) {
		return {
			id: data.id,
			premiseId: data.premise?.id,
			premiseName: data.premise?.name,
			premiseExternalId: data.premise?.external_id,
			status: data.status,
			dateFrom: data.date_from,
			dateTo: data.date_to,
			registrationId: data.registration_id
		}
	}
}
