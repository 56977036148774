import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import SelectWidget from '../select/SelectWidget'
import IMAGES from 'app/base/ImagesConstants'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './VehicleCategoryWidget.less'
import './VehicleCategoryWidgetCS.json'

export const CATEGORY_WIDGET_ID = 'category'
const CLASSNAME = 'c-category-widget'

const CategoryWidget = (props) => {
	const localize = useLocalize()

	const dict = {
		ariaTitle: localize('VehicleCategoryWidget.category'),
		popupTitle: localize('VehicleCategoryWidget.popupTitle'),
		popupClose: localize('VehicleCategoryWidget.close')
	}

	const selectModalItem = (event, option) => {
		const { formLineEntity } = props
		const { value, id: formLineEntityId } = formLineEntity
		if (option.id !== value) {
			props.onChange(event, option.id, formLineEntityId)
		}
	}

	const renderInputText = () => {
		const { formLineEntity } = props
		const { options = [], value } = formLineEntity
		const { name = '', seoName = '' } = options.find((option) => option.id === value) || {}
		const image = IMAGES[`image__categories__${seoName}`]
		const iconStyles = {
			backgroundImage: `url(${image})`
		}

		return (
			<div className={`${CLASSNAME}__input`}>
				<div className={`${CLASSNAME}__icon-category`} style={iconStyles}></div>
				<span>{name}</span>
			</div>
		)
	}

	return (
		<SelectWidget
			{...props}
			widgetId={CATEGORY_WIDGET_ID}
			dictionary={dict}
			onModalItemClick={selectModalItem}
			renderInputText={renderInputText}
		/>
	)
}

CategoryWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(CategoryWidget)
