import { LocalStorage } from '@inzeraty/helpers'
import { mySavedSearchesListToSummary } from './MySavedSearchHelper'
import { MY_SAVED_SEARCHES_LOCAL_STORAGE } from 'app/base/StorageIds'

// interval, jak casto muzeme volat BE
const CACHE_TIMEOUT = 60 * 1000 // 60 sekund

export default class MySavedSearchCache {
	static saveIntoCache(userId, mySavedSearches = []) {
		const data = _stripDataToSave(mySavedSearches)
		_setMySavedSearches(userId, data)
	}

	static getMySavedSearches(userId) {
		return _getMySavedSearches(userId)
	}

	static getSummary(userId) {
		const mySavedSearches = _getMySavedSearches(userId)

		if (mySavedSearches) {
			return mySavedSearchesListToSummary(mySavedSearches)
		}
	}

	static onMySavedSearchAdded(
		userId,
		{ mySavedSearchId, mySavedSearchHash, mySavedSearchFilter, mySavedSearchIsOperatingLease } = {}
	) {
		const cachedMySavedSearches = _getMySavedSearches(userId)

		if (cachedMySavedSearches) {
			const newCachedMySavedSearches = [
				...cachedMySavedSearches,
				{
					id: mySavedSearchId,
					watchdogHash: mySavedSearchHash,
					filter: mySavedSearchFilter,
					operatingLease: mySavedSearchIsOperatingLease
				}
			]

			_updateCachedMySavedSearches(userId, newCachedMySavedSearches)
		}
	}

	static onMySavedSearchDeleted(userId, mySavedSearchId) {
		const cachedMySavedSearches = _getMySavedSearches(userId)

		if (cachedMySavedSearches) {
			const newCachedMySavedSearches = cachedMySavedSearches.filter(
				({ id }) => id !== mySavedSearchId
			)

			_updateCachedMySavedSearches(userId, newCachedMySavedSearches)
		}
	}

	static onVisitedAdvertListWithNewAdverts(userId, { mySavedSearchId, mySavedSearchHash } = {}) {
		const cachedMySavedSearches = _getMySavedSearches(userId)

		if (cachedMySavedSearches && mySavedSearchId && mySavedSearchHash) {
			const mySavedSearch = cachedMySavedSearches.find(
				({ id, watchdogHash: hash }) => id === mySavedSearchId && hash === mySavedSearchHash
			)

			if (mySavedSearch) {
				const newCachedMySavedSearches = [
					...cachedMySavedSearches.filter(({ id }) => id !== mySavedSearchId),
					Object.assign({}, mySavedSearch, {
						newCount: 0
					})
				]

				_updateCachedMySavedSearches(userId, newCachedMySavedSearches)
			}
		}
	}
}

const _stripDataToSave = (mySavedSearches = []) => {
	return mySavedSearches.map(({ id, watchdogHash, filter, newCount, operatingLease }) => ({
		id,
		watchdogHash,
		filter,
		newCount,
		operatingLease
	}))
}

const _getCachedItem = (userId) => {
	try {
		const item = LocalStorage.getItem(MY_SAVED_SEARCHES_LOCAL_STORAGE)

		if (item) {
			const parsedItem = JSON.parse(item)
			return parsedItem.userId === userId ? parsedItem : undefined
		}
	} catch (e) {
		return undefined
	}
}

const _setCachedItem = (item) => {
	LocalStorage.setItem(MY_SAVED_SEARCHES_LOCAL_STORAGE, JSON.stringify(item))
}

const _getMySavedSearches = (userId) => {
	const cachedItem = _getCachedItem(userId)

	if (
		cachedItem &&
		// lastSyncWithServer nesmi ukazovat do budoucnosti
		Date.now() >= cachedItem.lastSyncWithServer &&
		// lastSyncWithServer nesmi byt prilis stare
		Date.now() - cachedItem.lastSyncWithServer < CACHE_TIMEOUT
	) {
		return cachedItem.mySavedSearches
	}

	return undefined
}

const _setMySavedSearches = (userId, mySavedSearches = []) => {
	const item = {
		mySavedSearches,
		userId,
		// timestamp posledniho syncu s api na backendu
		lastSyncWithServer: Date.now()
	}

	_setCachedItem(item)
}

const _updateCachedMySavedSearches = (userId, mySavedSearches = []) => {
	const cachedItem = _getCachedItem(userId)

	if (cachedItem) {
		// neaktualizuje lastSyncWithServer, na rozdil od _setMySavedSearches
		const newCachedItem = Object.assign({}, cachedItem, {
			mySavedSearches: _stripDataToSave(mySavedSearches)
		})

		_setCachedItem(newCachedItem)
	}
}
