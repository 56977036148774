import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Button, Icon, Surface, Input, Checkbox } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'

const CLASSNAME = 'c-verify-pin'

import './VerifyPin.less'
import './VerifyPinCS.json'

const PIN_STATUS = Object.freeze({
	DEFAULT: 'default', // Defaultní stav.
	PIN_EXPIRED: 'expired', // Zaslaný PIN ztratil platnost
	PIN_VERIFIED: 'pinVerified', // Zaslaný PIN ověřil telefon
	WRONG_PIN: 'wrongPin' // Neplatny PIN
})

const VerifyPin = (props) => {
	const localize = useLocalize()

	const [pinValue, setPinValue] = useState('')

	const [isPinVerified, setIsPinVerified] = useState(null)

	const [pinVerifying, setPinVerifying] = useState(false)

	const [pinStatus, setPinStatus] = useState(PIN_STATUS.DEFAULT)

	const [checked, setChecked] = useState(true)

	const {
		icon,
		dict,
		verifyPin,
		onVerifyPinSuccess,
		onVerifyPinError,
		cancelVerification,
		resendPin,
		messageLoading
	} = props

	const { pinMsg1, pinMsg2, contactValue } = dict

	const renderPinErrors = () => {
		const { WRONG_PIN, PIN_EXPIRED } = PIN_STATUS

		if (pinStatus === WRONG_PIN) {
			return (
				<div className={`${CLASSNAME}__error`}>
					<p>{localize('VerifyPin.wrongPin')}</p>
				</div>
			)
		} else if (pinStatus === PIN_EXPIRED) {
			return (
				<div className={`${CLASSNAME}__error ${CLASSNAME}__expired`}>
					<p className={`${CLASSNAME}__error-sentence`}>{localize('VerifyPin.pinExpired')}</p>
					{renderSendNewPin()}
				</div>
			)
		} else {
			return null
		}
	}

	const renderSendNewPin = () => {
		return (
			<div className={`${CLASSNAME}__send-again`}>
				<Button
					loading={messageLoading}
					onClick={resendPin}
					className={`${CLASSNAME}__btn-send-again`}
					text={localize('VerifyPin.sendAgain')}
				/>
			</div>
		)
	}

	const renderCancelVerificationButton = () => {
		return (
			<button
				onClick={() => {
					setPinStatus(PIN_STATUS.DEFAULT)
					setPinValue('')
					cancelVerification()
				}}
				className={`${CLASSNAME}__cancel`}
			>
				{localize('VerifyPin.cancelVerification')}
			</button>
		)
	}

	return (
		<Surface surface={5} className={CLASSNAME}>
			<Icon symbol={icon} className={`${CLASSNAME}__icon`} />
			<div className={`${CLASSNAME}__text`}>
				<p>
					<span>{pinMsg1}</span>
					<span className={`${CLASSNAME}__sentence--bold`}>{contactValue}</span>
					<span>{pinMsg2}</span>
				</p>
				<p className={`${CLASSNAME}__sentence`}>
					<span>{localize('VerifyPin.retypePin')}</span>
				</p>
				<div className={`${CLASSNAME}__checkbox`}>
					<Checkbox
						checked={checked}
						label={localize('VerifyPin.checkbox')}
						onChange={(e) => {
							setChecked(e.target.checked)
						}}
					/>
				</div>
				<div className={`${CLASSNAME}__pin-wrap`}>
					<div className={`${CLASSNAME}__control`}>
						<Input
							className={classnames({
								[`${CLASSNAME}__pin`]: true,
								[`${CLASSNAME}__pin-error`]: isPinVerified === false
							})}
							placeholder={localize('VerifyPin.placeholder')}
							type='number'
							value={pinValue}
							onChange={(e) => setPinValue(e.target.value)}
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							aria-required={true}
						/>
						<div className={`${CLASSNAME}__error-pin-mobile`}>{renderPinErrors()}</div>
						<Button
							loading={pinVerifying}
							onClick={() => {
								if (pinValue) {
									setPinVerifying(true)

									verifyPin(
										pinValue,
										(errors) => {
											if (errors && errors.length > 0) {
												const { error_code: errorCode } = errors[0]

												if (errorCode === 'verification_pin_expired') {
													setPinStatus(PIN_STATUS.PIN_EXPIRED)
												} else {
													setPinStatus(PIN_STATUS.WRONG_PIN)
												}
												setIsPinVerified(false)
												setPinVerifying(false)
												onVerifyPinError()
											} else {
												setPinVerifying(false)
												onVerifyPinSuccess()
											}
										},
										checked
									)
								} else {
									setPinStatus(PIN_STATUS.WRONG_PIN)
									setIsPinVerified(false)
								}
							}}
							className={`${CLASSNAME}__btn-pin`}
							text={localize('VerifyPin.checkPin')}
						/>
					</div>
					{renderCancelVerificationButton()}
				</div>
				<div className={`${CLASSNAME}__error-pin-desktop`}>{renderPinErrors()}</div>
			</div>
		</Surface>
	)
}

VerifyPin.propTypes = {
	icon: PropTypes.string.isRequired,
	dict: PropTypes.object.isRequired,
	messageLoading: PropTypes.bool,
	verifyPin: PropTypes.func.isRequired,
	onVerifyPinSuccess: PropTypes.func,
	onVerifyPinError: PropTypes.func,
	resendPin: PropTypes.func,
	cancelVerification: PropTypes.func
}

VerifyPin.defaultProps = {
	messageLoading: false,
	onVerifyPinSuccess: DEFAULT_PROPS.FUNCTION,
	onVerifyPinError: DEFAULT_PROPS.FUNCTION,
	resendPin: DEFAULT_PROPS.FUNCTION,
	cancelVerification: DEFAULT_PROPS.FUNCTION
}

export default React.memo(VerifyPin)
