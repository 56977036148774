import React from 'react'
import { LayoutAdvertEdit } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import IAAdvertBasicInfoEditForm from './components/IAAdvertBasicInfoEditForm'
import ADD_VIN_STATE_KEYS from 'app/page/userweb/newAdvert/addVIN/AddVINStateKeys'
import CebiaPopup from 'app/page/userweb/newAdvert/addVIN/components/cebiaPopup/CebiaPopup'

export default function IAAdvertBasicInfoEditView(props) {
	const { [ADD_VIN_STATE_KEYS.CEBIA_POPUP_OPEN]: cebiaPopupOpen } = props

	return (
		<LayoutAdvertEdit>
			{cebiaPopupOpen && <CebiaPopup />}
			<IAAdvertBasicInfoEditForm {...props} />
		</LayoutAdvertEdit>
	)
}
