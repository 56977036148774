import React from 'react'
import AbstractRadioButtonForm from '../AbstractRadioButtonForm'
import { Input } from '@sznds/react'
import NumberFormat from 'react-number-format'

import './RangeInputForm.less'
import './RangeInputFormCS.json'

const CLASSNAME = 'cf-range-input'

const RANGE_INPUT_FROM = 'range-input-from'
const RANGE_INPUT_TO = 'range-input-to'

export default class RangeInputForm extends AbstractRadioButtonForm {
	static get propTypes() {
		return AbstractRadioButtonForm.propTypes
	}

	static get defaultProps() {
		return AbstractRadioButtonForm.defaultProps
	}

	constructor(props, context) {
		super(props, context)

		const {
			formLineEntity: {
				value: { valueFrom, valueTo }
			}
		} = props

		this.state = {
			// kvuli validaci az pri opusteni inputu je potreba drzet jejich stav zde,
			// nikoliv pouze primo v props.formLineEntity. Jinak by k validaci dochazelo
			// okamzite behem psani. K zapisu do props.formLineEntity dojde pri opusteni
			// inputu, takze az v tuto chvili se pripadne vypise chybova hlaska.
			inputValueFrom: valueFrom,
			inputValueTo: valueTo,
			lastlyChangedInputValue: null
		}

		this._onInputClick = this._onInputClick.bind(this)
		this._onInputFromChange = this._onInputFromChange.bind(this)
		this._onInputToChange = this._onInputToChange.bind(this)
		this._onInputFromBlur = this._onInputFromBlur.bind(this)
		this._onInputToBlur = this._onInputToBlur.bind(this)
	}

	componentDidUpdate(prevProps) {
		const { formLineEntity } = this.props

		if (prevProps.formLineEntity !== formLineEntity) {
			const {
				value: { valueFrom, valueTo }
			} = formLineEntity

			this.setState({
				inputValueFrom: valueFrom,
				inputValueTo: valueTo
			})
		}
	}

	_getDictionary() {
		const {
			formLineEntity: { id }
		} = this.props

		return {
			from: this.localize(`RangeInputForm.${id}-from`),
			to: this.localize(`RangeInputForm.${id}-to`),
			rangeValidationError: this.localize(`RangeInputForm.${id}-rangeValidationError`),
			placeholderFrom: this.localize(`RangeInputForm.${id}-placeholderFrom`),
			placeholderTo: this.localize(`RangeInputForm.${id}-placeholderTo`)
		}
	}

	render() {
		const { inputValueFrom = '', inputValueTo = '', lastlyChangedInputValue } = this.state
		const { formLineEntity, className, isFormValid } = this.props
		const {
			value: { value: actualValue }
		} = formLineEntity

		const lastValue = this._getLastOptionValue(formLineEntity)
		const isLastValue = actualValue === lastValue

		const dictionary = this._getDictionary()

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{this._renderShortCuts()}

				<div
					className={`${CLASSNAME}__range`}
					ref={(elm) => (this._rangeInputsRef = elm)}
					data-dot='choose-range'
				>
					<label className={`${CLASSNAME}__range-item`}>
						<span className={`${CLASSNAME}__range-label`}>{dictionary.from}</span>
						<NumberFormat
							customInput={Input}
							thousandSeparator=' '
							type='tel'
							value={inputValueFrom}
							onChange={this._onInputFromChange}
							error={!isFormValid && lastlyChangedInputValue === RANGE_INPUT_FROM}
							onClick={this._onInputClick}
							tabIndex={isLastValue ? 0 : -1}
							onBlur={this._onInputFromBlur}
							placeholder={dictionary.placeholderFrom ? dictionary.placeholderFrom : ''}
						/>
					</label>
					<span className={`${CLASSNAME}__range-separator`}>&ndash;</span>
					<label className={`${CLASSNAME}__range-item`}>
						<span className={`${CLASSNAME}__range-label`}>{dictionary.to}</span>
						<NumberFormat
							customInput={Input}
							thousandSeparator=' '
							type='tel'
							error={!isFormValid && lastlyChangedInputValue === RANGE_INPUT_TO}
							onClick={this._onInputClick}
							tabIndex={isLastValue ? 0 : -1}
							value={inputValueTo}
							onChange={this._onInputToChange}
							onBlur={this._onInputToBlur}
							placeholder={dictionary.placeholderTo ? dictionary.placeholderTo : ''}
						/>
					</label>
				</div>
				{!isFormValid && (
					<div className={`${CLASSNAME}__error`}>{dictionary.rangeValidationError}</div>
				)}
			</div>
		)
	}

	_onChange(event) {
		event.stopPropagation()

		const value = Number(event.target.value)
		const {
			formLineEntity: { options }
		} = this.props

		const valueData = {
			value
		}

		if (value !== this._getLastOptionValue()) {
			const { valueFrom = 0, valueTo } = options.find((option) => option.value === value)

			valueData.valueFrom = valueFrom
			valueData.valueTo = valueTo
		} else {
			this._rangeInputsRef.querySelector('input').focus()
		}

		this._changeValue(valueData)
	}

	_onInputClick(event) {
		this._changeValue({
			value: Number(this._getLastOptionValue())
		})
	}

	_onInputFromChange(event) {
		this.setState({
			inputValueFrom: this._getNumberFromString(event.target.value)
		})
	}

	_onInputFromBlur() {
		const {
			formLineEntity: {
				value: { valueFrom: formLineValueFrom }
			}
		} = this.props
		const { inputValueFrom } = this.state

		if (formLineValueFrom !== inputValueFrom) {
			this.setState({
				lastlyChangedInputValue: RANGE_INPUT_FROM
			})
		}

		this._changeValue({
			valueFrom: inputValueFrom
		})
	}

	_onInputToChange(event) {
		this.setState({
			inputValueTo: this._getNumberFromString(event.target.value)
		})
	}

	_onInputToBlur() {
		const {
			formLineEntity: {
				value: { valueTo: formLineValueTo }
			}
		} = this.props
		const { inputValueTo } = this.state

		if (formLineValueTo !== inputValueTo) {
			this.setState({
				lastlyChangedInputValue: RANGE_INPUT_TO
			})
		}

		this._changeValue({
			valueTo: inputValueTo
		})
	}

	_getNumberFromString(value = '') {
		if (value) {
			return Number(value.replace(/[^\d]/g, ''))
		} else {
			return undefined
		}
	}
}
