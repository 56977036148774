
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['RegionOpener'] = {
  addMoreRegions: function(d) { return "Přidat další místo prodeje"; },
  entireRegion: function(d) { return "Celý kraj"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	