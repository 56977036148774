import { createEntityList } from '@inzeraty/form-lines'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

export default class IAImporListController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, entitiesFilterExtension, premiseService, softwareKeyService) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._router = this._utils.$Router
		this._entitiesFilterExtension = entitiesFilterExtension
		this._premiseService = premiseService
		this._softwareKeyService = softwareKeyService

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const fetchPremiseImportLogs = async (params) => {
			try {
				const {
					premiseEntities,
					paginationEntity
				} = await this._premiseService.getPremiseImportLogs(params)

				const sessionIds = premiseEntities.map(({ sessionId }) => sessionId)

				if (sessionIds.length) {
					this._premiseService
						.getPremiseImportLogsCount(
							Object.assign({}, params, {
								session_ids: sessionIds
							})
						)
						.then((logsCount) => {
							const mergedData = premiseEntities.map((premise) => {
								const logCount = logsCount.find((item) => item.sessionId === premise.sessionId)

								if (logCount) {
									return Object.assign({}, premise, { counts: logCount.counts })
								} else {
									return premise
								}
							})

							this.setState({
								entitiesAndPagination: {
									entities: mergedData,
									paginationEntity
								}
							})
						})
				}

				return {
					entities: premiseEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = 'timestamp'
		}

		this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchPremiseImportLogs(params)

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	async _filterInitiezerClb(routeParams) {
		const { softwareKeyEntities } = await this._softwareKeyService.getSoftwareKeys({ limit: 1000 })

		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'premise_name',
					name: 'Název klienta'
				},
				{
					value: 'premise_id',
					name: 'ASK ID'
				}
			]),
			{
				widget: IDS.DATE,
				id: 'date_from',
				label: 'Datum importu od',
				value: routeParams.date_from
			},
			{
				widget: IDS.DATE,
				id: 'date_to',
				label: 'Datum importu do',
				value: routeParams.date_to
			},
			this._entitiesFilterExtension.getSelect(
				'software_key',
				'Softwarový klíč',
				'Vyberte softwarový klíč',
				softwareKeyEntities
					.sort((a, b) => a.name.localeCompare(b.name))
					.map(({ name, value }) => ({
						value,
						name
					}))
			)
		])
	}
}
