import AbstractExtension from 'ima/extension/AbstractExtension'
import { UPLOADER_CONSTANTS } from 'app/base/Constants'
import { uuid } from 'uuidv4'
import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'

export default class VideoUploaderExtension extends AbstractExtension {
	constructor(advertAttachmentsService) {
		super()

		this._advertAttachmentsService = advertAttachmentsService
		this._isUploadCanceled = false
	}

	static get stateKeys() {
		return deepFreeze({
			ADVERT_ENTITY: 'videoUploaderExtensionAdvertEntity',
			VIDEO: 'videoUploaderExtensionVideo'
		})
	}

	load() {
		return {
			[VideoUploaderExtension.stateKeys.VIDEO]: this._getAdvertVideo()
		}
	}

	/**
	 * Provede přidání souboru vida. Nad souborem se provede nastavení potřebných parametrů a následné nahrání na server.
	 *
	 * @method onAddVideo
	 * @private
	 * @param {FileList} files Soubor videa.
	 */
	async onAddVideo(files) {
		const video = files[0]

		const {
			DATA_LIMIT: { VIDEO_MAX_DATA },
			ERROR
		} = UPLOADER_CONSTANTS

		const uid = uuid()

		const { size } = video

		/*- TODO - zkontrolovat velikost videa -*/
		const isTooBig = size > VIDEO_MAX_DATA

		Object.assign(video, {
			id: uid,
			error: isTooBig ? ERROR.DATA_TOO_BIG : '',
			isLoading: !isTooBig,
			isDeleting: false
		})

		this._setVideoToState(video)

		if (!video.error) {
			this._upload(video)
		}
	}

	/**
	 * Vrátí videa, která jsou už uložena k inzerátu.
	 *
	 * @method _getAdvertVideo
	 * @private
	 * @returns {Array<Object>} Pole objektů s daty videí, která jsou už uložená.
	 */
	async _getAdvertVideo() {
		const { [VideoUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntity } = this.getState()

		const { videos = [] } = (await advertEntity) || {}

		const [video] = videos.map(({ url, id, playlist = '', status }) => ({
			id: `${id}`,
			video: url,
			serverId: id,
			playlist,
			isProcessing: status === 'processing'
		}))

		return video || {}
	}

	/**
	 * Provede nahrání videa.
	 *
	 * @method _upload
	 * @private
	 */
	_upload(video) {
		const advertId = this._getAdvertId()

		this._isUploadCanceled = false

		this._advertAttachmentsService
			.saveVideo(advertId, { file: video })
			.then((data) => {
				this._updateVideoState({
					isLoading: false,
					serverId: data.id
				})
			})
			.catch((err) => {
				if (this._isUploadCanceled) {
					this._removeVideo()
				} else {
					const { TOO_SMALL, ADVERT_HAS_VIDEO, BACKEND_ERROR } = UPLOADER_CONSTANTS.ERROR

					let error

					switch (err) {
						case TOO_SMALL:
							error = TOO_SMALL
							break

						case ADVERT_HAS_VIDEO:
							error = ADVERT_HAS_VIDEO
							break

						default:
							error = BACKEND_ERROR
							break
					}

					this._updateVideoState({
						isLoading: false,
						error
					})
				}
			})
	}

	/**
	 * Upraví data jednoho objektu video a přenastaví stav.
	 *
	 * @method _updateVideoState
	 * @private
	 * @param {Object} update  Data, která se mají změnit.
	 */
	_updateVideoState(update) {
		const video = this._getVideo()

		const updatedVideo = Object.assign({}, video, update)

		this._setVideoToState(updatedVideo)
	}

	/**
	 * Zrusi nahravani videa
	 *
	 * @method onCancelUploadVideo
	 * @public
	 */
	onCancelUploadVideo() {
		const video = this._getVideo()

		this._isUploadCanceled = true

		if (video) {
			this._advertAttachmentsService.cancelRequest(video.id)
			this._removeVideoFromState()
		}
	}

	/**
	 * Provede úplně smazání videa (ze state i z backendu).
	 *
	 * @method onRemoveVideo
	 * @private
	 */
	onRemoveVideo() {
		this._removeVideo()
	}

	/**
	 * Provede úplně smazání videa (ze state i z backendu).
	 *
	 * @method _removeVideo
	 * @private
	 */
	_removeVideo() {
		const advertId = this._getAdvertId()
		const video = this._getVideo()

		if (video && video.serverId) {
			this._updateVideoState({
				isDeleting: true
			})

			this._advertAttachmentsService
				.deleteVideo(video.serverId, advertId)
				.then(() => {
					this._removeVideoFromState()
				})
				.catch((err) => {
					const { REMOVE_ERROR } = UPLOADER_CONSTANTS.ERROR

					// Todo - zobrazit stavovou hlášku, že se nepodařilo video smazat
					this._updateVideoState({
						isDeleting: false,
						error: REMOVE_ERROR
					})
				})
		} else {
			this._removeVideoFromState()
		}
	}

	/**
	 * Provede smazání videa s errorem (ze state).
	 *
	 * @method onRemoveVideoError
	 * @private
	 */
	onRemoveVideoError() {
		this._removeVideoFromState()
	}

	/**
	 * Odebere video ze state.
	 *
	 * @method _removeVideoFromState
	 * @private
	 */
	_removeVideoFromState() {
		this._setVideoToState(null)
	}

	/**
	 * Nastaví do state pole s daty videí. Provede u nich seřezení, tak aby nejprve byla videa, která se nahrála v pořádku a až poté videa s chybou.
	 *
	 * @method _setVideoToState
	 * @private
	 * @param {Object} video Objekt s daty videa, které nastaví do state.
	 */
	_setVideoToState(video) {
		this.setState({
			[VideoUploaderExtension.stateKeys.VIDEO]: video
		})
	}

	_getAdvertId() {
		const { [VideoUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntity } = this.getState()
		return advertEntity.id
	}

	_getVideo() {
		const { [VideoUploaderExtension.stateKeys.VIDEO]: video } = this.getState()
		return video
	}
}
