import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import GenericError from 'ima/error/GenericError'

export default class AbstractModalLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			value: PropTypes.number,
			filterLogicData: PropTypes.object.isRequired,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			filterLogicData: DEFAULT_PROPS.OBJECT
		}
	}

	constructor(props, context) {
		super(props, context)

		this._canBeModalClosed = this._canBeModalClosed.bind(this)
		this._closeModal = this._closeModal.bind(this)
		this._closeModalWithoutSavingChanges = this._closeModalWithoutSavingChanges.bind(this)
	}

	render() {
		const {
			formLineEntity: { id }
		} = this.props

		throw new GenericError(
			`Je potřeba implementovat metodu render logiky modalu pro formLineEntity.id = '${id}'`
		)
	}

	componentDidMount() {
		const {
			filterLogicData: { advertsCount: { loadFilteredAdvertsCount = () => {} } = {} }
		} = this.props

		loadFilteredAdvertsCount()
	}

	_closeModal(
		modalDefinition = {},
		canBeModalClosedFunction = () => {},
		successCallback = () => {}
	) {
		const { updateUrlParams } = modalDefinition
		const {
			formLineEntity: { id, value },
			redirect,
			changeFilter,
			filterLogicData: {
				advertsCount: { calcFilteredAdvertsCountTotal = () => {} } = {},
				form: { beenFilterChanged = true } = {}
			}
		} = this.props

		if (canBeModalClosedFunction()) {
			changeFilter(id, value, async (newFormLineEntity) => {
				if (updateUrlParams) {
					successCallback(id)

					redirect()
				} else {
					if (beenFilterChanged) {
						await calcFilteredAdvertsCountTotal(newFormLineEntity)
					}

					successCallback(id)
				}
			})
		}
	}

	_closeModalWithoutSavingChanges(canBeModalClosedFunction = () => {}, successCallback = () => {}) {
		const {
			formLineEntity: { id }
		} = this.props

		if (canBeModalClosedFunction()) {
			successCallback(id)
		}
	}

	_canBeModalClosed() {
		const {
			formLineEntity,
			filterLogicData: { form: { validateForm = () => true } = {} }
		} = this.props

		const isFormValid = validateForm(formLineEntity)

		return isFormValid
	}
}
