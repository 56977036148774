import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import BasicInfoTooltip from 'app/page/userweb/newAdvert/basicInfo/components/tooltip/BasicInfoTooltip'
import BaseDoorsSelect, {
	DOORS_AND_CAPACITY_SELECT_WIDGET_ID,
	DOORS_AND_SEATS_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/doorsSelect/DoorsSelect'
import BasicWidget from 'app/page/userweb/newAdvert/component/widgets/basic/BasicWidget'

export { DOORS_AND_CAPACITY_SELECT_WIDGET_ID }
export { DOORS_AND_SEATS_SELECT_WIDGET_ID }

const DoorsSelect = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const doorsSelectId = `${formLineEntity.id}-input`

	const renderLabel = () => {
		const { label, required } = formLineEntity

		return (
			<>
				<label className='wdg__label' htmlFor={doorsSelectId}>
					{label}
					{required && <span className='c-basic-widget__required'>*</span>}
				</label>
				<BasicInfoTooltip formLineEntity={formLineEntity} />
			</>
		)
	}

	return (
		<BasicWidget
			formLineEntity={formLineEntity}
			renderLabel={renderLabel}
			renderInput={() => (
				<div className='c-basic-widget__input'>
					<BaseDoorsSelect
						formLineEntity={formLineEntity}
						onChange={onChange}
						doorsSelectId={doorsSelectId}
					/>
				</div>
			)}
		/>
	)
}

DoorsSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(DoorsSelect)
