import React from 'react'
import AbstractOpener from '../AbstractOpener'

import './AgeOpenerCS.json'

export default class AgeOpener extends AbstractOpener {
	static get propTypes() {
		return AbstractOpener.propTypes
	}

	static get defaultProps() {
		return AbstractOpener.defaultProps
	}

	_renderOpener() {
		const {
			formLineEntity: { label }
		} = this.props

		return (
			<React.Fragment>
				<div>{label}</div>
				{super._renderOpener()}
			</React.Fragment>
		)
	}

	_getTextAndHasValue() {
		let text = ''
		let hasValue
		const { formLineEntity } = this.props

		if (formLineEntity.value && formLineEntity.value.value) {
			const { valueFrom, valueTo } = formLineEntity.value

			if (valueFrom === valueTo) {
				text = valueFrom
			} else {
				text = this.localize('AgeOpener.fromTo', {
					FROM: valueFrom || this._getMinYear(),
					TO: valueTo || this._getMaxYear()
				})
			}

			hasValue = true
		} else {
			text = formLineEntity.placeholder
			hasValue = false
		}

		return {
			text,
			hasValue
		}
	}

	_getLastOption() {
		const { formLineEntity: { options = [] } = {} } = this.props

		return options[options.length - 1] || {}
	}

	_getMaxYear() {
		return this._getLastOption().valueTo
	}

	_getMinYear() {
		return this._getLastOption().valueFrom
	}
}
