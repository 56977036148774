import React, { useState } from 'react'
import { Widget } from '@inzeraty/form-lines'
import { InputSurface, Icon } from '@sznds/react'
import { CHEVRON_UP_OUTLINE_24, CHEVRON_DOWN_OUTLINE_24 } from '@sznds/icons'
import { Popup, Responsive, Dropdown } from '@inzeraty/components'
import { RESPONSIVE } from 'app/base/Constants'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import classnames from 'classnames'

import './VehicleSelectWidget.less'

const CLASSNAME = 'c-vehicle-select-widget'

const VehicleSelectWidget = (props) => {
	const { widgetId: WIDGET_ID, dictionary, renderInputText, inputId } = props

	const [isModalOpen, setIsModalOpen] = useState(false)

	const renderWidget = (
		formLineEntity,
		renderLabel,
		renderInput,
		renderMessage,
		renderErrorMessage
	) => {
		return (
			<div className={`${CLASSNAME}__widget`}>
				{renderInput(formLineEntity)}
				{isModalOpen && (
					<Responsive
						breakpoint={RESPONSIVE.TABLET}
						renderMobileElement={renderPopup}
						renderDesktopElement={renderDropdown}
					/>
				)}
			</div>
		)
	}

	const renderInput = (formLineEntity) => {
		const { errorMessage } = formLineEntity
		const iconSymbol = isModalOpen ? CHEVRON_UP_OUTLINE_24 : CHEVRON_DOWN_OUTLINE_24

		return (
			<InputSurface
				type='button'
				tagName='button'
				onClick={() => setIsModalOpen(true)}
				data-dot='show-values'
				className={classnames({
					[`${CLASSNAME}__opener`]: true,
					'sds-inputsurface--error': !!errorMessage
				})}
				id={inputId}
			>
				{renderInputText()}
				<Icon symbol={iconSymbol} />
			</InputSurface>
		)
	}

	const renderMessage = (formLineEntity, props) => {
		return <span {...props}>{formLineEntity.message}</span>
	}

	const renderErrorMessage = (formLineEntity, props) => {
		return <span {...props}>{formLineEntity.errorMessage}</span>
	}

	const renderPopup = () => {
		const { popupTitle, popupClose } = dictionary

		return (
			<Popup
				title={popupTitle}
				isOpen={true}
				onClose={() => setIsModalOpen(false)}
				dictionary={{
					close: popupClose
				}}
			>
				{renderModalItems()}
			</Popup>
		)
	}

	const renderModalItems = () => {
		const { formLineEntity, onModalItemClick } = props
		const { options = [] } = formLineEntity
		const { ariaTitle } = dictionary

		if (options.length > 0) {
			return (
				<ul className={`${CLASSNAME}__list`}>
					{options.map((option) => (
						<li key={option.id} className={`${CLASSNAME}__item`}>
							<button
								type='button'
								className={`${CLASSNAME}__link`}
								aria-label={ariaTitle + option.name.toLowerCase()}
								onClick={() => {
									onModalItemClick(event, option)

									setIsModalOpen(false)
								}}
								data-dot='choose-sorting'
								data-dot-data={`{"value": "${option.seoName}"}`}
							>
								<div className={`${CLASSNAME}__category-icon-name`}>{option.name}</div>
							</button>
						</li>
					))}
				</ul>
			)
		}
	}

	const renderDropdown = () => {
		return (
			<Dropdown className={`${CLASSNAME}__dropdown`} onClose={() => setIsModalOpen(false)}>
				{renderModalItems()}
			</Dropdown>
		)
	}

	return (
		<div className={CLASSNAME}>
			<Widget
				{...props}
				name={WIDGET_ID}
				renderWidget={renderWidget}
				renderInput={renderInput}
				renderMessage={renderMessage}
				renderErrorMessage={renderErrorMessage}
			/>
		</div>
	)
}

VehicleSelectWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	widgetId: PropTypes.string,
	dictionary: PropTypes.object,
	renderInputText: PropTypes.func,
	onModalItemClick: PropTypes.func,
	inputId: PropTypes.string
}

export default React.memo(VehicleSelectWidget)
