import UserwebBaseController from 'app/base/UserwebBaseController'
import ROUTE_NAMES from 'app/base/RouteNames'

import './RegisterCompanyCS.json'
import 'app/base/BaseCS.json'

export default class RegisterCompanyController extends UserwebBaseController {
	static get STATE_KEYS() {
		return Object.freeze({
			GO_BACK_DATA: 'goBackData',
			ON_SUBMIT: 'onSubmit'
		})
	}

	constructor(dependenciesHelper, premiseService) {
		super(dependenciesHelper)

		this._router = this._utils.$Router
		this._dictionary = this._utils.$Dictionary

		this._premiseService = premiseService

		this.onSubmit = this.onSubmit.bind(this)
	}

	load() {
		return super.load({
			[RegisterCompanyController.STATE_KEYS.GO_BACK_DATA]: this._getGoBackData(),
			[RegisterCompanyController.STATE_KEYS.ON_SUBMIT]: this.onSubmit
		})
	}

	getDataForSsp() {
		return {}
	}

	_getGoBackData() {
		const goBackData = {
			text: '',
			url: ''
		}

		const previousRouteName = this._historyStorage.getPreviousRouteName()
		const previousRouteParams = this._historyStorage.getPreviousRouteParams()

		if (previousRouteName && previousRouteParams) {
			goBackData.url = this._router.link(previousRouteName, previousRouteParams)
		}

		switch (previousRouteName) {
			case ROUTE_NAMES.USERWEB.PROMO_INSERTION:
				goBackData.text = this._dictionary.get('RegisterCompany.backToInsertionPromo')
				break

			case ROUTE_NAMES.USERWEB.NEW_ADVERT:
				goBackData.text = this._dictionary.get('RegisterCompany.backToInsertionStart')
				break

			default:
				goBackData.text = this._dictionary.get('Base.goBackHome')
				goBackData.url = '/'
				break
		}

		return goBackData
	}

	async onSubmit(data) {
		return this._premiseService.sendRegistrationEmail(data)
	}
}
