import React, { useContext, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SspHelper from 'app/helpers/sspHelper/SspHelper'
import { Surface } from '@sznds/react'
import SspContext from '../SspContext'
import ImaContext from 'ima/page/context'
import SspCollocationOptions from 'app/helpers/sspCollocation/SspCollocationOptions'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'

import './NativeView.less'

/**
 * ClassName komponenty.
 * @constant
 * @type {String}
 */
const CLASSNAME = 'c-ssp-native'

/**
 * Zone Id ssp reklamní pozice.
 * @constant
 * @type {Number}
 */
const ZONE_ID = 56533

/**
 * V JSON chodia 3 typy reklamy
 * @constant
 * @type {String}
 */
const COMBINED_AD = 'COMBINED' //kombinovana reklama z SKLIKU
const DRTG_AD = 'DRTG' // drtg reklama z SKLIKU
const ADVERT_AD = 'ADVERT' // primy prodej Advert
const RTB_TYPE = 'native'
const RTB_DSP = 'APPNEXUS'

const NativeView = (props) => {
	const { id } = props

	const imaContext = useContext(ImaContext)
	const sspContextData = useContext(SspContext)
	const userEntity = useContext(UserEntityContext)

	// priznak, zda si uzivatel plati "Seznam bez reklam" (SBR)
	const isSbrUser = userEntity?.isSbr

	const sspHelper = imaContext.SspHelper

	const [isAdvertLoaded, setIsAdvertLoaded] = useState(false)
	const [advertData, setAdvertData] = useState({})

	const nativeComponentRef = useRef(null)
	const nativeAdvertRef = useRef(null)

	useEffect(() => {
		getAdForSsp(sspContextData)
	}, [sspContextData])

	const getAdForSsp = (pageData) => {
		sspHelper.cleanElement(nativeAdvertRef.current)
		nativeComponentRef.current?.classList.remove(SspHelper.adFullClassName)

		const options = SspCollocationOptions.getOptions(pageData, true)

		if (options) {
			const { collocation = '' } = options

			const updatedOptions = Object.assign({}, options, { collocation })

			getAd(updatedOptions)
		}
	}

	const getAd = (options = {}) => {
		sspHelper.getAds([
			{
				zoneId: ZONE_ID, // pri testovani na lokale pouzit: 201266
				id, // pri testovani na lokale pouzit: 'seznam.sauto.ks.inzerat.test'
				width: 600,
				height: 300,
				options,
				callback: (advert, zone) => {
					const { current: elem } = nativeComponentRef

					sendImpress(advert)

					if (advert.type !== 'empty' && elem) {
						elem.classList.add(SspHelper.adFullClassName)

						// u ADVERT chodi nevalidni JSON, proto ho musime pred parsovanim nejdriv upravit
						const jsonData = sspHelper.sanitizeJsonAdvertData(advert.data)
						let parsedData

						try {
							parsedData = JSON.parse(jsonData)
						} catch (error) {
							console.error('Native rozbita reklama: ', advert)
							return
						}

						let advertTitle = ''
						let advertUrl = ''
						let advertImageUrl = ''
						let advertPrice = ''
						let advertShortHeadline = ''
						let advertLongHeadline = ''
						let advertDescription = ''
						let tabletImageUrl = ''

						if (advert.type === RTB_TYPE && advert.dsp === RTB_DSP) {
							const nativeData = parsedData.native || {}

							elem.classList.add(`${CLASSNAME}--drtg`)

							const { assets = [], link = {}, imptrackers = [] } = nativeData

							imptrackers.forEach((trackerUrl) => {
								sspHelper.measureByImg(trackerUrl)
							})

							const rtbTabletImage = assets.find((asset) => asset.id === 1) || {} // obrazok v pomere 1,91:1
							const rtbImage = assets.find((asset) => asset.id === 2) || {} // obrazok v pomere 1:1
							const rtbTitle = assets.find((asset) => asset.id === 11) || {}
							const rtbDesc = assets.find((asset) => asset.id === 12) || {}

							advertUrl = link.url || ''

							advertImageUrl = rtbImage.img ? rtbImage.img.url : ''
							tabletImageUrl = rtbTabletImage.img ? rtbTabletImage.img.url : ''
							advertTitle = rtbTitle.title ? rtbTitle.title.text : ''
							advertDescription = rtbDesc.data ? rtbDesc.data.value : ''
						} else {
							const advertData = parsedData.ads[0]

							const isCombined = advertData.adType === COMBINED_AD
							const isAdvert = advertData.adType === ADVERT_AD
							const isDrtg = advertData.adType === DRTG_AD

							//Mereni imprese - u DRTG, kombinovane reklamy chodi confirmImpUrl
							const { confirmImpUrl = '' } = parsedData

							if (confirmImpUrl) {
								sspHelper.measureByImg(confirmImpUrl)
							}

							// Mereni impresse - u Advertu chodi externalImpress
							const { externalImpress = '' } = advertData

							if (externalImpress) {
								sspHelper.measureByImg(externalImpress)
							}

							if (isCombined || isAdvert) {
								advertUrl = advertData.clickUrl
								advertShortHeadline = advertData.shortHeadline
								advertLongHeadline = advertData.longHeadline
								advertDescription = advertData.description

								if (isCombined) {
									elem.classList.add(`${CLASSNAME}--combined`)
									advertImageUrl = `${advertData.adSquareImageUrl}?fl=res,200,,1|jpg,90`
									tabletImageUrl = `${advertData.adImageUrl}?fl=res,300,,1|jpg,90`
									advertTitle = advertData.companyName
								} else if (isAdvert) {
									elem.classList.add(`${CLASSNAME}--advert`)
									advertImageUrl = advertData.adImageUrlDesktop
								}
							} else if (isDrtg) {
								elem.classList.add(`${CLASSNAME}--drtg`)

								const drtgShopData = JSON.parse(advert.data).ads[1]

								advertTitle = drtgShopData.shopName
								advertUrl = advertData.clickUrl
								advertPrice = advertData.price
								advertImageUrl = `${advertData.imageUrl}?fl=res,200,200,1,fff`
								tabletImageUrl = `${advertData.imageUrl}?fl=res,1200,628,1,fff`
								advertDescription = advertData.title
							}
						}

						setIsAdvertLoaded(true)
						setAdvertData({
							advertTitle,
							advertUrl,
							advertImageUrl,
							advertPrice,
							advertDescription,
							advertShortHeadline,
							advertLongHeadline,
							tabletImageUrl
						})
					}
				}
			}
		])
	}

	const sendImpress = (advertData) => {
		const { tracking = {} } = advertData
		const { served = [] } = tracking

		served.forEach((url) => sspHelper.measureByImg(url))
	}

	if (isSbrUser) return null

	return (
		<div className={CLASSNAME} ref={nativeComponentRef}>
			<div className={`${CLASSNAME}__wrap`} id={id} ref={nativeAdvertRef}>
				{isAdvertLoaded && <NativeComponent advertData={advertData} />}
			</div>
		</div>
	)
}

const NativeComponent = ({ advertData = {} }) => {
	const {
		advertDescription,
		advertTitle,
		advertUrl,
		advertImageUrl,
		advertPrice,
		advertShortHeadline,
		advertLongHeadline,
		tabletImageUrl
	} = advertData

	return (
		<div className={`${CLASSNAME}__content`}>
			<div className={`${CLASSNAME}__data-part`}>
				<Surface tagName='a' surface={0} href={advertUrl} className={`${CLASSNAME}__link`}>
					<div className={`${CLASSNAME}__long-headline`}>{advertLongHeadline}</div>
					<div className={`${CLASSNAME}__short-headline`}>{advertShortHeadline}</div>
					<div className={`${CLASSNAME}__description`}>{advertDescription}</div>

					<div className={`${CLASSNAME}__price`}>{advertPrice}</div>

					<div className={`${CLASSNAME}__columns`}>
						<div className={`${CLASSNAME}__company-name`}>{advertTitle}</div>

						<div className={`${CLASSNAME}__ad`}>Reklama</div>
					</div>
				</Surface>
			</div>

			<div className={`${CLASSNAME}__image-wrap`}>
				<NativeImage defaultSrc={advertImageUrl} tabletSrc={tabletImageUrl} />
			</div>
		</div>
	)
}

const NativeImage = ({ defaultSrc, tabletSrc }) => {
	const imageProps = {
		className: `${CLASSNAME}__image`,
		src: defaultSrc
	}

	if (tabletSrc) {
		return (
			<picture>
				<source media='(max-width: 511px)' srcSet={defaultSrc} />
				<source media='(min-width: 512px)' srcSet={tabletSrc} />
				<img {...imageProps} alt='' />
			</picture>
		)
	} else {
		return <img {...imageProps} alt='' />
	}
}

NativeImage.propTypes = {
	defaultSrc: PropTypes.string.isRequired,
	tabletSrc: PropTypes.string
}

NativeView.propTypes = {
	id: PropTypes.string.isRequired
}

NativeComponent.propTypes = {
	advertData: PropTypes.shape({
		advertDescription: PropTypes.string,
		advertTitle: PropTypes.string,
		advertUrl: PropTypes.string,
		advertImageUrl: PropTypes.string,
		advertPrice: PropTypes.string,
		advertShortHeadline: PropTypes.string,
		advertLongHeadline: PropTypes.string
	})
}

export default React.memo(NativeView)
