export default Object.freeze({
	FORM_LINE: 'FORM_LINE',
	ON_CHANGE: 'ON_CHANGE',
	IS_FORM_BEING_SUBMITTED: 'IS_FORM_BEING_SUBMITTED',
	SUBMIT_FORM: 'SUBMIT_FORM',
	FORM_STATUS: 'FORM_STATUS',
	SET_FORM_STATUS: 'SET_FORM_STATUS',
	IS_EQUIPMENT_FROM_CEBIA_BEING_LOADED: 'IS_EQUIPMENT_FROM_CEBIA_BEING_LOADED',
	LOAD_EQUIPMENT_FROM_CEBIA: 'LOAD_EQUIPMENT_FROM_CEBIA',
	SCROLL_PAGE_TO_TOP: 'SCROLL_PAGE_TO_TOP',
	HANDLE_PREMISE_PAYMENT: 'HANDLE_PREMISE_PAYMENT'
})
