export default Object.freeze({
	FORM_LINE_ENTITIES: 'FORM_LINE_ENTITIES',
	ON_CHANGE: 'ON_CHANGE',

	IS_FORM_BEING_SUBMITTED: 'IS_FORM_BEING_SUBMITTED',
	SUBMIT_FORM: 'SUBMIT_FORM',

	SCROLL_PAGE_TO_TOP: 'SCROLL_PAGE_TO_TOP',

	FORM_STATUS: 'FORM_STATUS',
	SET_FORM_STATUS: 'SET_FORM_STATUS',

	ADVERT_ENTITY: 'ADVERT_ENTITY',

	LOAD_MODELS: 'LOAD_MODELS',
	CLEAR_MODELS: 'CLEAR_MODELS',

	SUBMIT_EXPIRATION_DATE_CHANGE: 'submitExpirationDateChange',

	OPERATING_LEASE_DEFINITION_DATA: 'OPERATING_LEASE_DEFINITION_DATA',
	OPERATING_LEASE_VARIANTS: 'OPERATING_LEASE_VARIANTS',
	FETCH_OPERATING_LEASES_VARIANTS: 'FETCH_OPERATING_LEASES_VARIANTS',
	ADD_NEW_OPERATING_LEASE_VARIANT: 'ADD_NEW_OPERATING_LEASE_VARIANT',
	EDIT_OPERATING_LEASE_VARIANT: 'EDIT_OPERATING_LEASE_VARIANT',
	DELETE_OPERATING_LEASE_VARIANT: 'DELETE_OPERATING_LEASE_VARIANT'
})
