import { AbstractFactory } from '@inzeraty/models'
import PremiseEntityHistory from './PremiseEntityHistory'

export default class PremiseFactoryHistory extends AbstractFactory {
	constructor() {
		super(PremiseEntityHistory)
	}

	transformData(data = {}) {
		return {
			changedBy: data.changed_by,
			changes: data.changes,
			id: data.id,
			ipAddress: data.ip_address,
			serviceName: data.service_name,
			time: data.time
		}
	}
}
