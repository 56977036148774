import React from 'react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import CategorySelect from 'app/component/select/categorySelect/CategorySelect'
import Label from '../label/Label'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const CategorySelectWidget = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		changeFormLineEntities = DEFAULT_PROPS.FUNCTION
	} = props

	const { id, value: { id: selectedId } = {}, label, placeholder, options = [] } = formLineEntity

	const handleSelect = (selectedItem) => {
		const selectedOption = options.find(({ id }) => selectedItem && id === selectedItem.id) || {}

		if (selectedId !== selectedOption.id) {
			changeFormLineEntities([
				{
					id,
					value: selectedOption.id ? selectedOption : undefined
				}
			])
		}
	}

	const linkedSelectAndLabel = AutoComplete.getIdsPropsHelper(id)

	return (
		<div>
			<Label id={linkedSelectAndLabel.labelId} htmlFor={linkedSelectAndLabel.inputId}>
				{label}
			</Label>

			<div data-dot='select-type'>
				<CategorySelect
					{...linkedSelectAndLabel}
					selectedItem={options.find(({ id }) => id === selectedId) || null}
					items={options}
					placeholder={placeholder}
					disabled={!options.length}
					size='small'
					allowNoSelection={true}
					onSelect={handleSelect}
				/>
			</div>
		</div>
	)
}

CategorySelectWidget.propTypes = {
	changeFormLineEntities: PropTypes.func,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity)
}

export default React.memo(CategorySelectWidget)
