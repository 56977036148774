import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'
import { CategoryEntity } from '@inzeraty/models'
import { Responsive, Loading } from '@inzeraty/components'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { CATEGORIES, RESPONSIVE } from 'app/base/Constants'
import { useLocalize } from 'app/base/componentHelpers'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import AutoComplete, {
	filterItemsIgnoreSpecChars,
	pipe
} from 'app/component/autoComplete/AutoComplete'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import AutoCompleteFilteredForm from 'app/component/autoComplete/forms/filtered/AutoCompleteFilteredForm'
import AutoCompleteAllForm from 'app/component/autoComplete/forms/all/AutoCompleteAllForm'
import { StickyPanelVisibilityManager } from 'app/component/filters/FiltersUtils'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import { Icon } from '@sznds/react'

import 'app/base/BaseCS.json'
import './AllBrandsSearch.less'
import './AllBrandsSearchCS.json'

const CLASSNAME = 'c-all-brands-search'

const DROPDOWN = 'DROPDOWN'
const POPUP = 'POPUP'

const stickyPanelVisibilityManager = new StickyPanelVisibilityManager()

const AllBrandsSearch = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		categoryEntity = DEFAULT_PROPS.OBJECT,
		changeFilter = DEFAULT_PROPS.FUNCTION,
		advertsCountForBrands: {
			filteredAdvertsCount = DEFAULT_PROPS.OBJECT,
			isLoadingFilteredAdvertsCount,
			loadFilteredAdvertsCount = DEFAULT_PROPS.FUNCTION
		} = DEFAULT_PROPS.OBJECT,
		getSortedFavoriteBrands = DEFAULT_PROPS.FUNCTION,
		onBrandSelect = DEFAULT_PROPS.FUNCTION,
		onClose = DEFAULT_PROPS.FUNCTION,
		autoFocus = false,
		initialIsOpen = false
	} = props

	const localize = useLocalize()

	const [searchedBrandName, setSearchedBrandName] = useState('')

	// pri zmene nepotrebujeme prekreslovat (proto useRef misto useState)
	const activeModalTypeRef = useRef()

	useEffect(() => {
		if (initialIsOpen) {
			loadFilteredAdvertsCount()
			stickyPanelVisibilityManager.hide()
		}
	}, [])

	const renderInput = (formLineEntity, downshift) => {
		const {
			getInputProps,
			getToggleButtonProps,
			getClearButtonProps,

			isOpen,
			inputValue
		} = downshift

		const { name, options = [] } = formLineEntity

		const filteredItems = options.filter((option) =>
			filterItemsIgnoreSpecChars(inputValue, option.name)
		)

		const areBrandsLoaded = formLineEntity.options ? formLineEntity.options.length : false

		return (
			<div className={`${CLASSNAME}__wrapper`}>
				<AutoCompleteInput
					isLoading={!areBrandsLoaded}
					inputSurfaceProps={{
						size: 'small',
						className: `${CLASSNAME}__search-brand`,
						'data-e2e': 'all-brands-search-input',
						'data-dot': 'search'
					}}
					inputProps={getInputProps({
						name,
						placeholder: localize('Base.searchBrand'),
						autoFocus
					})}
					toggleButtonProps={
						!inputValue
							? getToggleButtonProps({
									isOpen
							  })
							: undefined
					}
					clearButtonProps={inputValue ? getClearButtonProps() : undefined}
					renderLeftIcon={() => <Icon symbol={SEARCH_OUTLINE_24} />}
				/>

				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => renderPopup(formLineEntity, filteredItems, downshift)}
					renderDesktopElement={() => renderDropdown(formLineEntity, filteredItems, downshift)}
				/>
			</div>
		)
	}

	const renderDropdown = (formLineEntity, filteredItems, downshift) => {
		activeModalTypeRef.current = DROPDOWN

		const {
			isOpen,

			getDropdownProps,
			renderDropdown: Dropdown
		} = downshift

		return (
			isOpen && (
				<Dropdown {...getDropdownProps()}>
					{Boolean(filteredItems.length) && renderItems(filteredItems, downshift)}
				</Dropdown>
			)
		)
	}

	const renderPopup = (formLineEntity, filteredItems, downshift) => {
		activeModalTypeRef.current = POPUP

		const {
			isOpen,

			getInputProps,
			getClearButtonProps,
			getPopupProps,
			renderPopup: Popup
		} = downshift

		const { options = [] } = formLineEntity

		return (
			isOpen && (
				<PopupRenderer
					popupComponent={Popup}
					getPopupProps={getPopupProps}
					getInputProps={getInputProps}
					getClearButtonProps={getClearButtonProps}
					allItems={options}
					renderItems={(items) => renderItems(items, downshift)}
				/>
			)
		)
	}

	const renderItems = (items, downshift) => {
		const { inputValue, getItemProps, highlightedIndex } = downshift

		const renderItem = (isRenderedWithIcon = false) => (option, index) => {
			const { value: brandValue, name, seoName } = option

			return (
				<AutoCompleteOption
					{...getItemProps({
						key: brandValue,
						index,
						item: option,
						isHighlighted: highlightedIndex === index,
						className: classnames({
							[`${CLASSNAME}__item`]: true,
							[`${CLASSNAME}__item--regular`]: !isRenderedWithIcon,
							[`${CLASSNAME}__item--with-icon`]: isRenderedWithIcon
						}),
						'data-dot': 'choose-value',
						'data-dot-data': `{"value": "${name}"}`
					})}
				>
					{isRenderedWithIcon && (
						<BrandIcon
							value={brandValue}
							className={`${CLASSNAME}__icon`}
							seoName={seoName}
							categorySeoName={categoryEntity.seoName}
						/>
					)}

					<span>{name}</span>

					<div className={`${CLASSNAME}__count`}>
						{isLoadingFilteredAdvertsCount ? (
							<Loading
								className={`${CLASSNAME}__loading-dots`}
								loadingType={Loading.loadingType.ONLY_DOT}
							/>
						) : (
							filteredAdvertsCount[brandValue] || 0
						)}
					</div>
				</AutoCompleteOption>
			)
		}

		const showAllBrandIcons = CATEGORIES.PASSENGER_CARS.id === categoryEntity.id

		return inputValue ? (
			<AutoCompleteFilteredForm
				filteredItemsLabel={localize('AllBrandsSearch.labelFound')}
				filteredItems={items}
				renderItem={renderItem(showAllBrandIcons)}
			/>
		) : (
			<AutoCompleteAllForm
				allItemsLabel={localize('AllBrandsSearch.labelAll')}
				items={items}
				favoriteItemsLabel={localize('AllBrandsSearch.labelFavorite')}
				favoriteItems={getSortedFavoriteBrands(items)}
				renderItem={renderItem(showAllBrandIcons)}
				renderFavoriteItem={renderItem(true)}
			/>
		)
	}

	const handleSelect = (selectedItem) => {
		if (selectedItem) {
			const { id, value = new Map() } = formLineEntity
			const { value: brandValue } = selectedItem

			setSearchedBrandName('')

			onBrandSelect(selectedItem)

			changeFilter(id, new Map([...value, [brandValue, value.get(brandValue) || new Set()]]))
		}
	}

	const stateReducer = (state, changes) => {
		const loadFilteredAdvertsCountOnOpen = (changes) => {
			// pri otevreni dropdownu nebo popupu automaticky nacteme
			// cisilka poctu inzeraty pro jednotlive znacky
			if (!state.isOpen && changes.isOpen) {
				loadFilteredAdvertsCount()
			}

			return changes
		}

		const evalStickyPanelVisibility = (changes) => {
			if (!state.isOpen && changes.hasOwnProperty('isOpen') && changes.isOpen) {
				stickyPanelVisibilityManager.hide()
			}

			if (state.isOpen && changes.hasOwnProperty('isOpen') && !changes.isOpen) {
				stickyPanelVisibilityManager.restore()
				onClose()
			}

			// kvuli enteru na popupu
			if (state.isOpen && changes.type === AutoComplete.stateChangeTypes.keyDownEnter) {
				stickyPanelVisibilityManager.restore()
				onClose()
			}

			return changes
		}

		const closeDropdownOnNothingFound = (changes) => {
			// pokud se nepodari pri zafiltrovani najit zadnou znacku,
			// tak dropdown zavreme
			if ((state.isOpen || changes.isOpen) && activeModalTypeRef.current === DROPDOWN) {
				const { options: allBrands = [] } = formLineEntity
				const inputValue = changes.inputValue || state.inputValue

				const filteredBrands = allBrands.filter((option) =>
					filterItemsIgnoreSpecChars(inputValue, option.name)
				)

				if (!filteredBrands.length) {
					return Object.assign({}, changes, {
						isOpen: false
					})
				}
			}

			return changes
		}

		return pipe(
			loadFilteredAdvertsCountOnOpen,
			evalStickyPanelVisibility,
			closeDropdownOnNothingFound
		)(changes)
	}

	return (
		<AutoComplete
			inputValue={searchedBrandName}
			onInputValueChange={setSearchedBrandName}
			onSelect={handleSelect}
			stateReducer={stateReducer}
			initialIsOpen={initialIsOpen}
			size='small'
		>
			{(downshift) => renderInput(formLineEntity, downshift)}
		</AutoComplete>
	)
}

AllBrandsSearch.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	categoryEntity: PropTypes.instanceOf(CategoryEntity).isRequired,
	changeFilter: PropTypes.func.isRequired,
	advertsCountForBrands: PropTypes.object.isRequired,
	getSortedFavoriteBrands: PropTypes.func.isRequired,
	onBrandSelect: PropTypes.func,
	onClose: PropTypes.func,
	autoFocus: PropTypes.bool,
	initialIsOpen: PropTypes.bool
}

export default React.memo(AllBrandsSearch)

const PopupRenderer = ({
	popupComponent: Popup,
	getPopupProps,
	getClearButtonProps,
	allItems,
	renderItems
}) => {
	const localize = useLocalize()

	const [searchedBrandName, setSearchedBrandName] = useState('')

	useEffect(() => {
		// HACK: nechceme, aby na mobilu po zavreni popupu byl focus na inputu
		document.activeElement?.blur()
	}, [])

	const filteredItems = allItems.filter((item) =>
		filterItemsIgnoreSpecChars(searchedBrandName, item.name)
	)

	return (
		<Popup
			{...getPopupProps({
				title: localize('AllBrandsSearch.chooseBrand'),
				className: `${CLASSNAME}__popup`
			})}
		>
			<div className={`${CLASSNAME}__popup-input-wrapper`}>
				<AutoCompleteInput
					inputSurfaceProps={{
						size: 'small'
					}}
					inputProps={{
						placeholder: localize('Base.searchBrand'),
						value: searchedBrandName,
						onChange: (event) => setSearchedBrandName(event.target.value)
					}}
					clearButtonProps={
						searchedBrandName
							? getClearButtonProps({
									onClick: () => setSearchedBrandName('')
							  })
							: undefined
					}
					renderLeftIcon={() => <Icon symbol={SEARCH_OUTLINE_24} />}
				/>
			</div>

			{Boolean(filteredItems.length) && renderItems(filteredItems)}
		</Popup>
	)
}

PopupRenderer.propTypes = {
	renderItems: PropTypes.func,
	getPopupProps: PropTypes.func,
	getClearButtonProps: PropTypes.func,
	popupComponent: PropTypes.func,
	allItems: PropTypes.arrayOf(PropTypes.object)
}
