import FilterConstants from 'app/model/filter/FilterConstants'
import PopupOpenerLogic from '../openers/PopupOpenerLogic'

export default class DependentPopupsOpenerLogic extends PopupOpenerLogic {
	constructor(props, context) {
		super(props, context)

		this._openDependentPopup = this._openDependentPopup.bind(this)
		this._clearDependentFilter = this._clearDependentFilter.bind(this)
	}

	render() {
		const { children } = this.props

		return children({
			openMainPopup: this._openPopup,
			clearMainFilter: this._clearFilter,

			openDependentPopup: this._openDependentPopup,
			clearDependentFilter: this._clearDependentFilter
		})
	}

	_openDependentPopup(value) {
		const {
			formLineEntity: { id },
			context
		} = this.props

		this.fire('openFilterPopup', {
			filterId: id,
			openedByValue: value,
			context,
			filterPopupType: FilterConstants.filterPopupType.BACK,
			filterButtonType: FilterConstants.filterButtonType.CONTINUE,
			updateUrlParams: false
		})
	}

	_clearDependentFilter(newValue) {
		const {
			formLineEntity: { id },
			changeFilter
		} = this.props

		changeFilter(id, newValue)
	}
}
