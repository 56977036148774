import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import { CategoryEntity } from '@inzeraty/models'
import HomeUrlConvertor from 'app/page/userweb/home/HomeUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import select from 'ima-plugin-select'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import STATE_KEYS from 'app/page/userweb/home/HomeStateKeys'
import { Surface } from '@sznds/react'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import { CATEGORIES } from 'app/base/Constants'

import './Menu.less'

const CLASSNAME = 'c-menu'

class Menu extends AbstractComponent {
	static get propTypes() {
		return {
			categories: PropTypes.arrayOf(PropTypes.instanceOf(CategoryEntity)),
			selectedCategory: PropTypes.instanceOf(CategoryEntity)
		}
	}

	constructor(props, context) {
		super(props, context)
	}

	render() {
		const { categories } = this.props

		return (
			<ul className={`${CLASSNAME}__category-list`} data-dot='menu'>
				{categories.map((category, index) => this._renderCategory(category, index))}
			</ul>
		)
	}

	_renderCategory(category, index) {
		const { id, name, seoName } = category
		const classNames = this.cssClasses({
			[`${CLASSNAME}__category-link`]: true,
			[`${CLASSNAME}__category-link--active`]: this.props.selectedCategory.id === id
		})
		const categorySeoName = HomeUrlConvertor.getCategorySeoNameForAppParams(seoName)
		const url = this.link(ROUTE_NAMES.USERWEB.HOMEPAGE, {
			[HomeUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_SEO_NAME]: categorySeoName
		})

		const categoryIcon = Object.values(CATEGORIES).find(({ id: categoryId }) => id === categoryId)
			.svgIcon
		const categoryName = name.split(' ')[0]

		return (
			<li className={`${CLASSNAME}__item`} key={id}>
				<Surface
					tagName='a'
					surface={0}
					className={classNames}
					href={url}
					data-dot='item'
					data-dot-data={`{"order": "${index + 1}"}`}
				>
					<SvgIcon icon={categoryIcon} className={`${CLASSNAME}__category-icon`} />
					{categoryName}
				</Surface>
			</li>
		)
	}
}

const MenuSelector = (state) => ({
	categories: state[STATE_KEYS.CATEGORIES],
	selectedCategory: state[FiltersExtension.stateIds.CATEGORY]
})

export default select(MenuSelector)(Menu)
