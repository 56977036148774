import { AbstractFactory } from '@inzeraty/models'
import PremiseEntityImportLogItem from './PremiseEntityImportLogItem'

export default class PremiseFactoryImportLogItem extends AbstractFactory {
	constructor() {
		super(PremiseEntityImportLogItem)
	}

	transformData(data = {}) {
		return {
			advertId: data.advert_id,
			datacenter: data.datacenter,
			duration: data.duration,
			rpcMethod: data.rpc_method,
			rpcStatusCode: data.rpc_status_code,
			rpcStatusMessage: data.rpc_status_message,
			timestamp: data.timestamp
		}
	}
}
