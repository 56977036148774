import React, { memo, useContext, useState, useEffect } from 'react'
import Context from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import HPLink from 'app/page/userweb/home/hpLink/HPLink'
import classnames from 'classnames'
import { Format } from '@inzeraty/helpers'
import Favorite from 'app/component/favorite/Favorite'
import ROUTE_NAMES from 'app/base/RouteNames'
import STATE_KEYS from 'app/component/favorite/FavoriteStateKeys'
import select from 'ima-plugin-select'
import { UserService } from '@inzeraty/models'
import UserwebBaseController from 'app/base/UserwebBaseController'
import LinkPlaceholder from 'app/page/userweb/home/placeholder/linkPlaceholder/LinkPlaceholder'
import PropTypes from 'prop-types'
import { UserPropTypes } from '@inzeraty/models'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import './FavoritesLink.less'
import './FavoritesLinkCS.json'

const CLASSNAME = 'c-favorites-link'

const FavoritesLink = memo(({ favoriteIds, userSelf }) => {
	const localize = useLocalize()
	const { $Router: router } = useContext(Context)

	const [user, setUser] = useState(null)
	const [isLogged, setIsLogged] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		setIsLogged(UserService.isUserLogged(userSelf))
		setUser(userSelf)
		setIsLoaded(true)
	}, [userSelf])

	const renderFavoritesLink = () => {
		const iconLeft = <Favorite className={`${CLASSNAME}__favorite-icon`} />

		const linkProps = {
			secondLine: ''
		}

		if (favoriteIds && isLogged) {
			const count = favoriteIds.size

			linkProps.href = router.link(ROUTE_NAMES.USERWEB.FAVORITE_LIST)
			linkProps.secondLine = count
				? localize('FavoritesLink.itemsCount', { COUNT: Format.number(count) })
				: localize('FavoritesLink.itemsNotCount')
		} else {
			linkProps.onClick = () => {
				LoginHelper.openLogin({
					sourceComponent: LoginHelper.SOURCE_COMPONENT.FAVORITES_SHOW
				})
			}
			linkProps.secondLine = `${localize('FavoritesLink.logoutSecondLine')} ${localize(
				'FavoritesLink.logoutLogin'
			)}`
		}

		return <HPLink iconLeft={iconLeft} firstLine={localize('FavoritesLink.title')} {...linkProps} />
	}

	if (isLoaded) {
		if (user !== null) {
			return (
				<div
					className={classnames({
						[CLASSNAME]: true,
						[`${CLASSNAME}--logout`]: !isLogged
					})}
					data-dot='go-to-favorite'
				>
					{renderFavoritesLink()}
				</div>
			)
		} else {
			return null
		}
	} else {
		return <LinkPlaceholder />
	}
})

const selector = (state) => ({
	favoriteIds: state[STATE_KEYS.FAVORITE_IDS],
	userSelf: state[UserwebBaseController.STATE_KEYS.USER_SELF]
})

FavoritesLink.propTypes = {
	favoriteIds: PropTypes.instanceOf(Set),
	userSelf: PropTypes.shape(UserPropTypes)
}

export default select(selector)(FavoritesLink)
