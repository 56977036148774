import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import BaseTextWidget from 'app/page/userweb/newAdvert/component/widgets/text/TextWidget'
import { VIN_WIDGET_ID } from 'app/page/userweb/newAdvert/addVIN/components/widgets/vin/VINWidget'
import { CLASSNAME as IA_CLASSNAME } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import BaseWidget from '../baseWidget/BaseWidget'
import CebiaDataLoadBtn from 'app/component/cebia/CebiaDataLoadBtn'

import './VINWidget.less'

export { VIN_WIDGET_ID }

const CLASSNAME = 'c-ia-vin-widget'

const VINWidget = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT } = props

	const { id, value } = formLineEntity

	const inputId = `${id}-input`

	return (
		<BaseWidget formLineEntity={formLineEntity} inputId={inputId}>
			<div className={`${IA_CLASSNAME}__input`}>
				<BaseTextWidget {...props} id={inputId} />
			</div>
			<CebiaDataLoadBtn vin={value} className={`${CLASSNAME}__cebia-data-load-btn`} />
		</BaseWidget>
	)
}

VINWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(VINWidget)
