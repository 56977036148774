export default class FeedbackService {
	constructor(feedbackResource, feedbackFactory, paginationFactory) {
		this._feedbackResource = feedbackResource
		this._feedbackFactory = feedbackFactory
		this._paginationFactory = paginationFactory
	}

	async getFeedbacks(params, options) {
		const {
			body: { results = [], pagination = {} } = {}
		} = await this._feedbackResource.getFeedbacks(params, options)

		const feedbacksData = results.map(this._feedbackFactory.transformData)

		const feedbackEntities = this._feedbackFactory.createEntityList(feedbacksData)
		const paginationEntity = this._paginationFactory.createPagination(pagination)

		return { feedbackEntities, paginationEntity }
	}

	sendFeedback(params, options) {
		return this._feedbackResource.sendFeedback(params, options)
	}

	deleteFeedback(feedbackId) {
		return this._feedbackResource.deleteFeedback(feedbackId)
	}
}
