
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['PromoInsert'] = {
  tabRus: function(d) { return "Jednorázová inzerce"; },
  tabPremise: function(d) { return "Pro autobazary"; },
  title: function(d) { return "Co získáte s jednorázovou inzercí"; },
  rusPrice: function(d) { return "Ceník pro jednorázovou inzerci"; },
  premiseTitle: function(d) { return "Co získáte inzercí na Sauto.cz"; },
  premisePrice: function(d) { return "Ceník pro autobazary"; },
  premisePriceInfo: function(d) { return "(Uvedené ceny jsou bez DPH)"; },
  getListed: function(d) { return "Zalistování inzerátu do největší internetové inzerce"; },
  getStats: function(d) { return "Základní statistiky o výkonu inzerátu"; },
  getDetailStats: function(d) { return "Detailní statistiky o výkonu inzerátu"; },
  getSupport: function(d) { return "Zákaznickou podporu"; },
  getMobileApp: function(d) { return "Zobrazení inzerátu v mobilní aplikaci"; },
  getMobileEdit: function(d) { return "Jednoduchou editaci inzerátu z mobilu"; },
  getPromotion: function(d) { return "Možnost propagace inzerátů"; },
  getMassActions: function(d) { return "Nástroj pro hromadný import inzerátů"; },
  getPromoCard: function(d) { return "Vizitku s kompletními údaji a celou vaší nabídkou"; },
  wannaInsert: function(d) { return "Chcete vložit inzerát?"; },
  signIn: function(d) { return "Přihlaste se"; },
  logAndInsert: function(d) { return "Přihlásit a vložit inzerát"; },
  insertAdvert: function(d) { return "Vložit inzerát"; },
  signUp: function(d) { return "Zaregistrujte se"; },
  signUpText: function(d) { return ", pokud nemáte účet u Seznamu"; },
  cardDays: function(d) { return "na " + d.COUNT + " " + plural(d.COUNT, 0, cs, { one: "den", few: "dny", other: "dní" }); },
  walletText: function(d) { return "přes účet "; },
  wallets: function(d) { return "Peněženky."; },
  smsText: function(d) { return " přes SMS."; },
  smsDisabled: function(d) { return "Nelze platit přes SMS."; },
  flatRate: function(d) { return "Pro prodejce a autobazary nabízíme paušální poplatky inzerce."; },
  priceList: function(d) { return "Zobrazit ceník autobazarů"; },
  sautoAdvert: function(d) { return "Inzerát na Sauto.cz"; },
  motoAdvert: function(d) { return "Inzeráty pro motorky a čtyřkolky"; },
  registerNewPremise: function(d) { return "Zaregistrovat novou firmu"; },
  adverts_header: function(d) { return "Počet inzerátů"; },
  price_header: function(d) { return "Cena za rok"; },
  adverts: function(d) { return "inzerátů"; },
  complete: function(d) { return "Kompletní "; },
  priceListDownload: function(d) { return "ceník ke stažení."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	