
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SoftwareKeyWidget'] = {
  chooseKey: function(d) { return "Vybrat softwarový klíč"; },
  addKey: function(d) { return "Přidat softwarový klíč"; },
  removeKey: function(d) { return "Smazat softwarový klíč"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	