
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['VerifyPinAndPay'] = {
  placeholder: function(d) { return "Zadejte PIN"; },
  submit: function(d) { return "Potvrdit a zveřejnit"; },
  wrongPin: function(d) { return " Zadaný PIN je nesprávný"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	