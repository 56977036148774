
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['FormStepper'] = {
  gotoPrev: function(d) { return "Zpět na " + d.STEP; },
  gotoNext: function(d) { return "Pokračovat na " + d.STEP; },
  saveAndLeave: function(d) { return "Uložit a zavřít"; },
  finishLater: function(d) { return "Dokončit později"; },
  prolongPublishing: function(d) { return "Prodloužit platnost"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	