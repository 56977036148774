import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './Label.less'

const CLASSNAME = 'c-client-premise-filter-label'

const Label = (props) => {
	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label
			{...props}
			className={classnames({
				[CLASSNAME]: true,
				[props.className]: !!props.className
			})}
		/>
	)
}

Label.propTypes = {
	className: PropTypes.string
}

export default React.memo(Label)
