import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './Badge.less'

const CLASSNAME = 'c-badge'

const Badge = (props) => {
	const { className, children } = props

	return (
		<span
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			{children}
		</span>
	)
}

Badge.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
}

export default React.memo(Badge)
