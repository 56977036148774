import React from 'react'
import { Format } from '@inzeraty/helpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import IAPreferredOffersController from './IAPreferredOffersController'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { PaginationEntity } from '@inzeraty/models'
import PreferredOffersEntity from 'app/model/preferredOffers/PreferredOffersEntity'
import { CATEGORIES } from 'app/base/Constants'

const IAPreferredOffers = ({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading
}) => {
	const getProductValue = (id, manufacturerCb = {}) => {
		const { name: manufacturerName = '' } = manufacturerCb

		let name = ''

		if (id) {
			const category = Object.keys(CATEGORIES).find((cat) => CATEGORIES[cat].id === id)

			name = category ? CATEGORIES[category].name : ''
		}

		return name
			? `Přednostní výpis: ${name.toLocaleLowerCase()} - ${manufacturerName}`
			: 'Přednostní výpis'
	}

	return (
		<Layout title='Přednostní výpis'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='id' sortParam='id' isDefaultDescending={true} alignRight={true}>
						ID PV
					</TableHeadSorter>,
					<TableHeadSorter key='registration_id' sortParam='registration_id'>
						ID registrace
					</TableHeadSorter>,
					<TableHeadSorter key='premise_id' sortParam='premise_id' alignRight={true}>
						ASK ID
					</TableHeadSorter>,
					<TableHead key='premise_name'>Název firmy</TableHead>,
					<TableHead key='product'>Produkt</TableHead>,
					<TableHead key='state'>Stav</TableHead>,
					<TableHeadSorter key='date_from' sortParam='date_from'>
						Datum od
					</TableHeadSorter>,
					<TableHeadSorter key='date_to' sortParam='date_to'>
						Datum do
					</TableHeadSorter>
				]}
				rowCells={[
					({ id }) => [id, { isNumber: true }],
					({ registrationId }) => [registrationId, { isNumber: true }],
					({ premiseId }) => [premiseId, { isNumber: true }],
					({ premiseName }) => premiseName,
					({ categoryId, manufacturerCb }) => getProductValue(categoryId, manufacturerCb),
					({ status }) =>
						Object.values(IAPreferredOffersController.STATUS).find((option) => option.id === status)
							.name,
					({ dateFrom }) => Format.date(dateFrom),
					({ dateTo }) => Format.date(dateTo)
				]}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAPreferredOffers.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(PreferredOffersEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool
}

export default IAPreferredOffers
