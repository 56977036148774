import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import { Ribbon as RibbonBase } from '@inzeraty/components'
import AdminMenu from 'app/component/adminMenu/AdminMenu'
import { useFire } from 'app/base/componentHelpers'
import RouterEvents from 'ima/router/Events'
import useMedia from 'use-media'
import RibbonSearch from './ribbonSearch/RibbonSearch'
import FallbackRibbon from './FallbackRibbon'

import './Ribbon.less'

export const CLASSNAME = 'c-ribbon'

export const ROUTE_NAMES_WITH_ADMIN_MENU = [
	ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST,
	ROUTE_NAMES.CLIENT_ADMIN.CLIENT_PROFILE,
	ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS
]

const ROUTE_NAMES_WITHOUT_STICKY_HEADER_MOBILE = [
	ROUTE_NAMES.USERWEB.ADVERT_DETAIL,
	ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL,
	ROUTE_NAMES.USERWEB.ADVERT_LIST,
	ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES,
	...ROUTE_NAMES_WITH_ADMIN_MENU
]

const ROUTE_NAMES_WITHOUT_STICKY_HEADER_DESKTOP = [...ROUTE_NAMES_WITH_ADMIN_MENU]

const Ribbon = ({ widgetData, isPremise }) => {
	const { $Router, $Dispatcher } = useContext(Context)
	const fire = useFire()

	const isBiggerThenMobile = useMedia({ minWidth: '48em' })

	const [routeName, setRouteName] = useState('')
	const [isSticky, setIsSticky] = useState(true)

	const getIsSticky = useCallback(
		(routeName) => {
			const routeNamesWithoutStickyHeader = isBiggerThenMobile
				? ROUTE_NAMES_WITHOUT_STICKY_HEADER_DESKTOP
				: ROUTE_NAMES_WITHOUT_STICKY_HEADER_MOBILE

			return routeName && !routeNamesWithoutStickyHeader.includes(routeName)
		},
		[isBiggerThenMobile]
	)

	useEffect(() => {
		let routeName

		try {
			const { route } = $Router.getCurrentRouteInfo()
			routeName = route.getName()
		} catch (e) {
			routeName = ''
		}

		setRouteName(routeName)
		setIsSticky(getIsSticky(routeName))
		$Dispatcher.listen(RouterEvents.BEFORE_HANDLE_ROUTE, handleRouteChange)

		return () => {
			$Dispatcher.unlisten(RouterEvents.BEFORE_HANDLE_ROUTE, handleRouteChange)
		}
	}, [])

	useEffect(() => {
		setIsSticky(getIsSticky(routeName))
	}, [isBiggerThenMobile, routeName])

	const handleRouteChange = useCallback(
		(pageData) => {
			try {
				setRouteName(pageData.route.getName())
			} catch (e) {
				// na chybu nebudeme nijak reagovat
			}
		},
		[setRouteName]
	)

	const renderRibbonCenter = useCallback(() => {
		if (Object.values(ROUTE_NAMES.USERWEB).includes(routeName)) return <RibbonSearch />
		return ROUTE_NAMES_WITH_ADMIN_MENU.includes(routeName) && isPremise ? <AdminMenu /> : null
	}, [routeName, isPremise])

	const renderFallbackRibbon = () => {
		return <FallbackRibbon isPremise={isPremise} />
	}

	const handleRibbonEvent = useCallback((event) => {
		fire('redirectToNewAdvertOrPromo')
	})

	const handleError = (error) => {
		// pomoci asset detekujeme, ze doslo k chybe kvuli chybejici ES verzi (tj. pouzivame
		// zastaraly prohlizec). Vracenim true z teto funkce rikame, ze nebudeme renderovat
		// fallback, ale nechame HTML vyrenderovane ze serveru.
		return error && !!error.asset
	}

	const getClassname = () => {
		if (Object.values(ROUTE_NAMES.USERWEB).includes(routeName))
			return `${CLASSNAME} ${CLASSNAME}--with-search`
		return CLASSNAME
	}

	const className = getClassname()

	const isRenderedOnServer = typeof window === 'undefined'

	if (isRenderedOnServer) {
		// fallback ribbon je nastylovany tak, aby vzhledove odpovidal normalnimu ribbonu.
		// Lisi se pouze v chybejicim medailonku a notifikacich. Snazime se o to, aby
		// pri oziveni webovky na klientovi nedochazelo k "poskoceni" stranky. Predevsim na
		// mobilech nam to kazilo metriku CLS (Cumulative Layout Shift).
		return renderFallbackRibbon()
	} else {
		if (widgetData === null) {
			// null znamena, ze nebudeme ribbon hlavicku vubec zobrazovat. Undefined je
			// porad platna hodnota, ktera muze prijit pri prechodu na jinou stranku.
			return undefined
		} else {
			return (
				<RibbonBase
					widgetData={widgetData}
					className={className}
					isSticky={isSticky}
					renderRibbonCenter={renderRibbonCenter}
					handleRibbonEvent={handleRibbonEvent}
					renderFallbackRibbon={renderFallbackRibbon}
					onError={handleError}
				/>
			)
		}
	}
}

Ribbon.propTypes = {
	widgetData: PropTypes.object,
	isPremise: PropTypes.bool
}

Ribbon.defaultProps = {
	isPremise: false
}

export default Ribbon
