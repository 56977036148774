
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['SellerOperatingLeaseMeta'] = {
  title: function(d) { return "Operativní leasing " + d.PREMISE_NAME + " " + d.FILTERS + " | Sauto.cz"; },
  titleNoFilters: function(d) { return "Operativní leasing " + d.PREMISE_NAME + " | Sauto.cz"; },
  description: function(d) { return d.ADVERT_COUNT_TEXT + " na operativní leasing od " + d.PREMISE_NAME + " " + d.FILTERS + " na Sauto.cz ⭐ Přehled a srovnání operáků " + d.PREMISE_NAME + " pro firmy, OSVČ i fyzické osoby, na 6, 12, 24, 36 i 48 měsíců ⭐ Vyberte si nejvýhodnější operativní leasing u " + d.PREMISE_NAME; },
  descriptionNoFilters: function(d) { return d.ADVERT_COUNT_TEXT + " na operativní leasing od " + d.PREMISE_NAME + " na Sauto.cz ⭐ Přehled a srovnání operáků " + d.PREMISE_NAME + " pro firmy, OSVČ i fyzické osoby, na 6, 12, 24, 36 i 48 měsíců ⭐ Vyberte si nejvýhodnější operativní leasing u " + d.PREMISE_NAME; },
  advertCount: function(d) { return plural(d.COUNT, 0, cs, { one: d.COUNT_FORMATTED + " vůz", few: d.COUNT_FORMATTED + " vozy", other: d.COUNT_FORMATTED + " vozů" }); }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	