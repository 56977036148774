import React, { useContext } from 'react'
import Context from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import Modal, { ModalFooterButtons } from 'app/component/modal/ModalView'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'

import './PublishModalCS.json'
import './PublishModal.less'

const CLASSNAME = 'c-publish-modal'

export default function PublishModal({ isOpen = false, onClose = () => {}, advertId }) {
	const localize = useLocalize()
	const { $Router } = useContext(Context)

	const advertEditUrl = $Router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO, {
		[AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: advertId
	})

	if (isOpen) {
		return (
			<Modal
				isOpen={true}
				title={localize('PublishModal.title')}
				modalStyle={Modal.STYLE.OFFSET}
				className={CLASSNAME}
				setIsOpenCallback={(isOpen) => {
					if (!isOpen) {
						onClose()
					}
				}}
			>
				<p>{localize('PublishModal.text')}</p>

				<ModalFooterButtons
					buttonsProps={[
						{
							text: localize('PublishModal.close'),
							onClick: onClose
						},
						{
							text: localize('PublishModal.edit'),
							primary: true,
							href: advertEditUrl
						}
					]}
				/>
			</Modal>
		)
	} else {
		return null
	}
}
