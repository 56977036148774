import { AbstractFactory } from '@inzeraty/models'
import PremiseEntityPublic from './PremiseEntityPublic'

export default class PremiseFactoryPublic extends AbstractFactory {
	constructor() {
		super(PremiseEntityPublic)
	}

	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformData(data = {}) {
		const { locality, opening_hours, subject } = data

		return {
			id: data.id,
			oldId: data.old_id,
			externalId: data.external_id,
			name: data.name,
			description: data.description,
			seoName: data.seo_name,
			web: data.web,
			logo: data.logo,
			emails: data.emails,
			phones: data.phones,
			locality: locality
				? {
						address: locality.address,
						addressId: locality.address_id,
						citypart: locality.citypart,
						countryId: locality.country_id,
						district: locality.district,
						districtId: locality.district_id,
						districtSeoName: locality.district_seo_name,
						entityId: locality.entity_id,
						entityType: locality.entity_type,
						gpsLat: locality.gps_lat,
						gpsLon: locality.gps_lon,
						housenumber: locality.housenumber,
						municipality: locality.municipality,
						municipalityId: locality.municipality_id,
						municipalitySeoName: locality.municipality_seo_name,
						quarter: locality.quarter,
						quarterId: locality.quarter_id,
						region: locality.region,
						regionId: locality.region_id,
						regionSeoName: locality.region_seo_name,
						source: locality.source,
						street: locality.street,
						streetId: locality.street_id,
						streetnumber: locality.streetnumber,
						ward: locality.ward,
						wardId: locality.ward_id,
						zip: locality.zip
				  }
				: undefined,
			openingHours: opening_hours
				? {
						openingTime: opening_hours.opening_time_st,
						openingTimeAdditionalInfo: opening_hours.opening_time_additional_info,
						openingTimeDescription: opening_hours.opening_time_description,
						openingTimeHolidayInfo: opening_hours.opening_time_holiday_info,
						openingTimeVisible: opening_hours.opening_time_visible
				  }
				: undefined,
			softwareKeys: data.software_keys,
			subject: subject
				? {
						ico: subject.ico,
						id: subject.id
				  }
				: undefined,
			retargetingId: data.retargeting_id,
			homeDelivery: data.home_delivery,
			advertsCount: data.adverts_count,
			reviews: data.reviews,
			useAdvertLocality: data.use_advert_locality
		}
	}
}
