import { Format, HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import ClientBaseController from 'app/base/ClientBaseController'
import ClientStatisticsUrlConvertor from './ClientStatisticsUrlConvertor'
import STATE_KEYS from './ClientStatisticsStateKeys'
import ROUTE_NAMES from 'app/base/RouteNames'
import { getPreviousMonth, getYesterday } from 'app/helpers/date/dateHelpers'
import UserwebBaseController from 'app/base/UserwebBaseController'

const yesterday = getYesterday()

// pokud neni zadano Obdobi 1, tak defaultne nastavujeme obdobi za posledni mesic
const DEFAULT_TIME_SPAN_1_FROM = Format.dateEN(getPreviousMonth(yesterday), true)
const DEFAULT_TIME_SPAN_1_TO = Format.dateEN(yesterday, true)

class ClientStatisticsController extends ClientBaseController {
	constructor(dependenciesHelper, advertService, advertStatisticsService, pageLoaderExtension) {
		super(dependenciesHelper, true /* uzivatel musi byt prihlasen jako bazarnik */)

		this._advertService = advertService
		this._advertStatisticsService = advertStatisticsService
		this._pageLoaderExtension = pageLoaderExtension

		this._isComparisonModeActive = this._isComparisonModeActive.bind(this)
		this._getKPIMetricsTimeSpan1 = this._getKPIMetricsTimeSpan1.bind(this)
		this._getKPIMetricsTimeSpan2 = this._getKPIMetricsTimeSpan2.bind(this)
		this._getGraphMetricsTimeSpan1 = this._getGraphMetricsTimeSpan1.bind(this)
		this._getGraphMetricsTimeSpan2 = this._getGraphMetricsTimeSpan2.bind(this)
		this._getDataTableMetricsTimeSpan1 = this._getDataTableMetricsTimeSpan1.bind(this)
		this._getDataTableMetricsTimeSpan1Wrapper = this._getDataTableMetricsTimeSpan1Wrapper.bind(this)
		this._getDataTableMetricsTimeSpan2 = this._getDataTableMetricsTimeSpan2.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._pageLoaderExtension)
		this._pageLoaderExtension.show()
	}

	static get DEAL_TYPE() {
		return {
			SALES: 'sales',
			OPERATING_LEASE: 'operating_lease',
			MIRRORING: 'mirroring'
		}
	}

	load() {
		const superState = super.load()
		const {
			[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfState,
			[ClientBaseController.STATE_KEYS.PREMISE_INFO]: premiseEntity
		} = superState

		const initData = this._getInitData(premiseEntity)
		const dataTableMetricsSpan1Wrapped = initData.then(this._getDataTableMetricsTimeSpan1Wrapper)

		const kpiMetricsTimeSpan1DataPromise = initData.then(this._getKPIMetricsTimeSpan1)

		const waitIfRedirectingPromise = Promise.all([initData, kpiMetricsTimeSpan1DataPromise]).then(
			async ([{ premiseExternalId } = {}, kpiMetricsData]) => {
				const urlAppParams = this.getRouteParams()

				const tryRedirectToOperatingLeasing =
					!kpiMetricsData && Object.keys(urlAppParams).length === 0

				// pokud jsme na /statistiky bez jakykoliv dalsich parametru v URL a
				// zaroven bazarnik nema zadne statistiky pro bezny prodej, tak se pokusime
				// presmerovat na statistiky s operaky. Ale pouze v pripad, ze nejake statistiky
				// pro operaky ma, jinak zustavame na stejne URL.
				if (tryRedirectToOperatingLeasing) {
					await this._redirectIfOperatingLeaseNotEmpty(premiseExternalId)
				}
			}
		)

		return Object.assign(superState, {
			// chceme pockat az bude resolvnuta i tato promise (jinak by probliklo, ze se
			// nepovedlo nacist zadna data pred samotnym redirectem)
			waitIfRedirectingPromise,
			[STATE_KEYS.IS_LOADING]: true,
			[STATE_KEYS.IS_OPERATING_LEASE]: this._isOperatingLease(),
			[STATE_KEYS.IS_MIRRORING]: this._isMirroring(),
			[STATE_KEYS.IS_COMPARISON_MODE_ACTIVE]: initData.then(this._isComparisonModeActive),
			...this._getDateRangeState(),
			[STATE_KEYS.KPI_METRICS_TIME_SPAN_1_DATA]: kpiMetricsTimeSpan1DataPromise,
			[STATE_KEYS.KPI_METRICS_TIME_SPAN_2_DATA]: initData.then(this._getKPIMetricsTimeSpan2),
			[STATE_KEYS.GRAPH_METRICS_SPAN_1]: initData.then(this._getGraphMetricsTimeSpan1),
			[STATE_KEYS.GRAPH_METRICS_SPAN_2]: initData.then(this._getGraphMetricsTimeSpan2),
			[STATE_KEYS.TABLE_TIME_SPAN_1_DATA]: dataTableMetricsSpan1Wrapped.then(([, data]) => data),
			[STATE_KEYS.TABLE_TIME_SPAN_2_DATA]: dataTableMetricsSpan1Wrapped.then(([args]) =>
				this._getDataTableMetricsTimeSpan2(args)
			),
			[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]: this._getPremiseWalletInfo(
				userSelfState
			)
		})
	}

	activate() {
		super.activate()

		this._pageLoaderExtension.hide()

		this.setState({
			[STATE_KEYS.IS_LOADING]: false
		})
	}

	update(prevParams) {
		const willOnlyDataTableUpdate = (() => {
			const currentParams = this._getRouteParamsWithDefaults()

			return (
				currentParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT] !==
					prevParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT] ||
				currentParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE] !==
					prevParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE]
			)
		})()

		const initData = this._getInitData()

		const KPIMetricsSpan1 = initData.then(this._getKPIMetricsTimeSpan1)
		const KPIMetricsSpan2 = initData.then(this._getKPIMetricsTimeSpan2)

		const graphMetricsSpan1 = initData.then(this._getGraphMetricsTimeSpan1)
		const graphMetricsSpan2 = initData.then(this._getGraphMetricsTimeSpan2)

		const dataTableMetricsSpan1Wrapped = initData.then(this._getDataTableMetricsTimeSpan1Wrapper)
		const dataTableMetricsSpan2 = dataTableMetricsSpan1Wrapped.then(([args]) =>
			this._getDataTableMetricsTimeSpan2(args)
		)

		this._pageLoaderExtension.show()

		const newState = {
			...this._getDateRangeState(),
			[STATE_KEYS.IS_LOADING]: !willOnlyDataTableUpdate,
			[STATE_KEYS.IS_OPERATING_LEASE]: this._isOperatingLease(),
			[STATE_KEYS.IS_MIRRORING]: this._isMirroring(),
			[STATE_KEYS.IS_COMPARISON_MODE_ACTIVE]: initData.then(this._isComparisonModeActive),
			[STATE_KEYS.KPI_METRICS_TIME_SPAN_1_DATA]: KPIMetricsSpan1,
			[STATE_KEYS.KPI_METRICS_TIME_SPAN_2_DATA]: KPIMetricsSpan2,
			[STATE_KEYS.GRAPH_METRICS_SPAN_1]: graphMetricsSpan1,
			[STATE_KEYS.GRAPH_METRICS_SPAN_2]: graphMetricsSpan2,
			[STATE_KEYS.TABLE_TIME_SPAN_1_DATA]: dataTableMetricsSpan1Wrapped.then(([, data]) => data),
			[STATE_KEYS.TABLE_TIME_SPAN_2_DATA]: dataTableMetricsSpan2
		}

		Promise.all([
			KPIMetricsSpan1,
			KPIMetricsSpan2,
			graphMetricsSpan1,
			graphMetricsSpan2,
			dataTableMetricsSpan1Wrapped,
			dataTableMetricsSpan2
		]).finally(() => {
			this._pageLoaderExtension.hide()

			this.setState({
				[STATE_KEYS.IS_LOADING]: false
			})
		})

		return newState
	}

	async _redirectIfOperatingLeaseNotEmpty(premiseExternalId) {
		try {
			const urlAppParams = this.getRouteParams()

			const newUrlAppParams = Object.assign({}, urlAppParams, {
				[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE]:
					ClientStatisticsUrlConvertor.constants.DEAL_TYPE_OPTIONS.OPERATING_LEASE
			})

			const [kpiApiParamsForTimeSpan1] = ClientStatisticsUrlConvertor.getApiParamsForKPIMetrics(
				premiseExternalId,
				this._getRouteParamsWithDefaults(newUrlAppParams)
			)

			if (kpiApiParamsForTimeSpan1) {
				const { statisticsEntities = [] } = await this._advertStatisticsService.getStatistics(
					Object.assign({}, kpiApiParamsForTimeSpan1, {
						[ClientStatisticsUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: true
					})
				)

				const [{ metrics } = {}] = statisticsEntities

				if (metrics) {
					const { route } = this._router.getCurrentRouteInfo()
					this._router.redirect(this._router.link(route.getName(), newUrlAppParams))
				}
			}
		} catch (e) {
			// na chybu nebudeme nijak reagovat, zustavame na stejne URL
		}
	}

	_isOperatingLease() {
		const routeParams = this.getRouteParams()

		return (
			routeParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE] ===
			ClientStatisticsUrlConvertor.constants.DEAL_TYPE_OPTIONS.OPERATING_LEASE
		)
	}

	_isMirroring() {
		const routeParams = this.getRouteParams()

		return (
			routeParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE] ===
			ClientStatisticsUrlConvertor.constants.DEAL_TYPE_OPTIONS.MIRRORING
		)
	}

	async _getInitData(premiseEntity) {
		const { [ClientBaseController.STATE_KEYS.PREMISE_INFO]: premiseEntityState } = this.getState()

		const { premiseServices } = (await premiseEntity) || (await premiseEntityState) || {}

		if (premiseServices && premiseServices.length) {
			const { externalId } = premiseServices[0]

			return { premiseExternalId: externalId }
		}

		return {}
	}

	_isComparisonModeActive() {
		const appParams = this._getRouteParamsWithDefaults()

		return ClientStatisticsUrlConvertor.isCompareToTimeSpanActive(appParams)
	}

	async _getDataTableMetricsTimeSpan1Wrapper(initData, skipSettingErrorState = false) {
		const dataTableMetricsSpan1 = await this._getDataTableMetricsTimeSpan1(
			initData,
			skipSettingErrorState
		)

		if (this._isComparisonModeActive()) {
			this.setState({
				[STATE_KEYS.TABLE_TIME_SPAN_2_ERROR]:
					dataTableMetricsSpan1 === undefined
						? () => this._retryGetDataTableMetrics(undefined, true)
						: false
			})
		}

		const { entities: statisticsEntities = [] } = dataTableMetricsSpan1 || {}

		const advertIds = statisticsEntities.map(({ groupBy: { id: advertId } = {} }) => advertId)

		const argsForSpan2 = Object.assign({}, initData, { advertIds })

		return [argsForSpan2, dataTableMetricsSpan1]
	}

	async _getKPIMetricsTimeSpan1({ premiseExternalId }) {
		const [kpiApiParamsForTimeSpan1] = ClientStatisticsUrlConvertor.getApiParamsForKPIMetrics(
			premiseExternalId,
			this._getRouteParamsWithDefaults()
		)

		if (kpiApiParamsForTimeSpan1) {
			return this._getKPIMetrics(kpiApiParamsForTimeSpan1)
		}
	}

	async _getKPIMetricsTimeSpan2({ premiseExternalId }) {
		const [, kpiApiParamsForTimeSpan2] = ClientStatisticsUrlConvertor.getApiParamsForKPIMetrics(
			premiseExternalId,
			this._getRouteParamsWithDefaults()
		)

		if (kpiApiParamsForTimeSpan2) {
			return this._getKPIMetrics(kpiApiParamsForTimeSpan2, true)
		}
	}

	async _getKPIMetrics(params, isForTimeSpan2 = false) {
		const errorStateKey = isForTimeSpan2
			? STATE_KEYS.KPI_METRICS_TIME_SPAN_2_ERROR
			: STATE_KEYS.KPI_METRICS_TIME_SPAN_1_ERROR

		try {
			const { statisticsEntities = [] } = await this._advertStatisticsService.getStatistics(
				Object.assign({}, params, {
					[ClientStatisticsUrlConvertor.constants.URL_API_PARAMS
						.OPERATING_LEASE]: this._isOperatingLease()
				})
			)

			const [{ metrics } = {}] = statisticsEntities

			this.setState({ [errorStateKey]: false })
			return metrics
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)

			// pri 422 neumoznime uzivateli opakovat request na api
			if (httpStatus === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
				this.setState({ [errorStateKey]: false })
			} else {
				this.setState({ [errorStateKey]: () => this._retryGetKPIMetrics(params, isForTimeSpan2) })
			}

			return undefined
		}
	}

	async _retryGetKPIMetrics(params, isForTimeSpan2 = false) {
		const dataStateKey = isForTimeSpan2
			? STATE_KEYS.KPI_METRICS_TIME_SPAN_2_DATA
			: STATE_KEYS.KPI_METRICS_TIME_SPAN_1_DATA

		const metrics = await this._getKPIMetrics(params, isForTimeSpan2)

		this.setState({
			[dataStateKey]: metrics
		})
	}

	async _getGraphMetricsTimeSpan1({ premiseExternalId }) {
		const [graphApiParamsForTimeSpan1] = ClientStatisticsUrlConvertor.getApiParamsForGraph(
			premiseExternalId,
			this._getRouteParamsWithDefaults()
		)

		if (graphApiParamsForTimeSpan1) {
			return this._getGraphMetrics(graphApiParamsForTimeSpan1)
		}
	}

	async _getGraphMetricsTimeSpan2({ premiseExternalId }) {
		const [, graphApiParamsForTimeSpan2] = ClientStatisticsUrlConvertor.getApiParamsForGraph(
			premiseExternalId,
			this._getRouteParamsWithDefaults()
		)

		if (graphApiParamsForTimeSpan2) {
			return this._getGraphMetrics(graphApiParamsForTimeSpan2, true)
		}
	}

	async _getGraphMetrics(apiParams, isForTimeSpan2 = false) {
		const errorStateKey = isForTimeSpan2
			? STATE_KEYS.GRAPH_METRICS_SPAN_2_ERROR
			: STATE_KEYS.GRAPH_METRICS_SPAN_1_ERROR

		try {
			const { statisticsEntities } = await this._advertStatisticsService.getStatistics(
				Object.assign({}, apiParams, {
					[ClientStatisticsUrlConvertor.constants.URL_API_PARAMS
						.OPERATING_LEASE]: this._isOperatingLease()
				})
			)

			this.setState({ [errorStateKey]: false })
			return statisticsEntities
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)

			// pri 422 neumoznime uzivateli opakovat request na api
			if (httpStatus === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
				this.setState({ [errorStateKey]: false })
			} else {
				this.setState({
					[errorStateKey]: () => this._retryGetGraphMetrics(apiParams, isForTimeSpan2)
				})
			}

			return undefined
		}
	}

	async _retryGetGraphMetrics(apiParams, isForTimeSpan2 = false) {
		const dataStateKey = isForTimeSpan2
			? STATE_KEYS.GRAPH_METRICS_SPAN_2
			: STATE_KEYS.GRAPH_METRICS_SPAN_1

		const statisticsEntities = await this._getGraphMetrics(apiParams, isForTimeSpan2)

		this.setState({
			[dataStateKey]: statisticsEntities
		})
	}

	async _getDataTableMetricsTimeSpan1({ premiseExternalId }, skipSettingErrorState = false) {
		const [apiParamsForTimeSpan1] = ClientStatisticsUrlConvertor.getApiParamsForDataTable(
			premiseExternalId,
			this._getRouteParamsWithDefaults()
		)

		if (apiParamsForTimeSpan1) {
			return this._getDataTableMetrics(apiParamsForTimeSpan1, false, skipSettingErrorState)
		}
	}

	async _getDataTableMetricsTimeSpan2({ premiseExternalId, advertIds }) {
		const [, getApiParamsForTimeSpan2] = ClientStatisticsUrlConvertor.getApiParamsForDataTable(
			premiseExternalId,
			this._getRouteParamsWithDefaults()
		)

		if (getApiParamsForTimeSpan2 && advertIds.length) {
			return this._getDataTableMetrics(getApiParamsForTimeSpan2(advertIds), true)
		}
	}

	async _getDataTableMetrics(params, isForTimeSpan2 = false, skipSettingErrorState = false) {
		const errorStateKey = isForTimeSpan2
			? STATE_KEYS.TABLE_TIME_SPAN_2_ERROR
			: STATE_KEYS.TABLE_TIME_SPAN_1_ERROR

		try {
			const {
				statisticsEntities,
				paginationEntity
			} = await this._advertStatisticsService.getStatistics(
				Object.assign({}, params, {
					[ClientStatisticsUrlConvertor.constants.URL_API_PARAMS
						.OPERATING_LEASE]: this._isOperatingLease()
				})
			)

			!skipSettingErrorState && this.setState({ [errorStateKey]: false })
			return {
				entities: statisticsEntities,
				paginationEntity
			}
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)

			// pri 422 neumoznime uzivateli opakovat request na api
			if (httpStatus === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
				!skipSettingErrorState && this.setState({ [errorStateKey]: false })

				// neni to uplne idealni, ale v _getDataTableMetricsTimeSpan1Wrapper
				// potrebujeme rozlisit k jake chybe doslo
				return null
			} else {
				!skipSettingErrorState &&
					this.setState({
						[errorStateKey]: () => this._retryGetDataTableMetrics(params, isForTimeSpan2)
					})
			}

			return undefined
		}
	}

	async _retryGetDataTableMetrics(params, isForTimeSpan2 = false) {
		const dataStateKey = isForTimeSpan2
			? STATE_KEYS.TABLE_TIME_SPAN_2_DATA
			: STATE_KEYS.TABLE_TIME_SPAN_1_DATA

		let data

		if (isForTimeSpan2) {
			// pro druhe obdobi je to slozitejsi diky vice zavislostem na ruzna api
			const initData = await this._getInitData()
			const [args, timeSpan1Data] = await this._getDataTableMetricsTimeSpan1Wrapper(initData, true)

			if (timeSpan1Data) {
				data = await this._getDataTableMetricsTimeSpan2(args)
			}
		} else {
			data = await this._getDataTableMetrics(params, isForTimeSpan2)
		}

		this.setState({
			[dataStateKey]: data
		})
	}

	_getRouteParamsWithDefaults(urlAppParams = this.getRouteParams()) {
		return Object.assign(
			{},
			{
				[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS
					.TIME_SPAN_1_FROM]: DEFAULT_TIME_SPAN_1_FROM,
				[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS
					.TIME_SPAN_1_TO]: DEFAULT_TIME_SPAN_1_TO
			},
			urlAppParams
		)
	}

	onSelectFilterSubmit({ filterBy, filterByValue }) {
		const { route } = this._router.getCurrentRouteInfo()
		const { URL_APP_PARAMS } = ClientStatisticsUrlConvertor.constants

		const restParams = Object.assign({}, this.getRouteParams())

		delete restParams[URL_APP_PARAMS.FILTER_BY]
		delete restParams[URL_APP_PARAMS.FILTER_BY_VALUE]
		delete restParams[URL_APP_PARAMS.PAGE]

		const newParams = Object.assign(
			{},
			restParams,
			filterByValue && {
				[URL_APP_PARAMS.FILTER_BY]: filterBy,
				[URL_APP_PARAMS.FILTER_BY_VALUE]: filterByValue
			}
		)

		this._router.redirect(this._router.link(route.getName(), newParams))
	}

	_getDateRangeState() {
		const {
			[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS
				.TIME_SPAN_1_FROM]: firstPeriodFrom = DEFAULT_TIME_SPAN_1_FROM,
			[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS
				.TIME_SPAN_1_TO]: firstPeriodTo = DEFAULT_TIME_SPAN_1_TO,
			[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.TIME_SPAN_2_FROM]: secondPeriodFrom,
			[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.TIME_SPAN_2_TO]: secondPeriodTo
		} = this.getRouteParams()

		return {
			[STATE_KEYS.FIRST_PERIOD_DATES]: {
				valueFrom: firstPeriodFrom,
				valueTo: firstPeriodTo
			},
			[STATE_KEYS.SECOND_PERIOD_DATES]: {
				valueFrom: secondPeriodFrom,
				valueTo: secondPeriodTo
			}
		}
	}

	onDateRangeChange(newValues) {
		const routeParams = this.getRouteParams()
		let hasChange = false

		for (const valueName in newValues) {
			const newValue = newValues[valueName]

			if (routeParams[valueName] !== newValue) {
				if (newValue) {
					routeParams[valueName] = newValue
				} else {
					delete routeParams[valueName]
				}

				hasChange = true
			}
		}

		if (hasChange) {
			delete routeParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE]
			this._router.redirect(
				this._router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS, routeParams)
			)
		}
	}

	onRemoveSecondPeriod() {
		const {
			TIME_SPAN_2_FROM,
			TIME_SPAN_2_TO,
			PAGE
		} = ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS
		const routeParams = this.getRouteParams()

		if (routeParams[TIME_SPAN_2_FROM] || routeParams[TIME_SPAN_2_TO]) {
			delete routeParams[TIME_SPAN_2_FROM]
			delete routeParams[TIME_SPAN_2_TO]
			delete routeParams[PAGE]

			this._router.redirect(
				this._router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS, routeParams)
			)
		}
	}

	onDealTypeChange(dealType = ClientStatisticsController.DEAL_TYPE.SALES) {
		const newRouteParams = Object.assign({}, this.getRouteParams())

		delete newRouteParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE]
		delete newRouteParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT]
		delete newRouteParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE]

		if (dealType === ClientStatisticsController.DEAL_TYPE.OPERATING_LEASE) {
			newRouteParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE] =
				ClientStatisticsUrlConvertor.constants.DEAL_TYPE_OPTIONS.OPERATING_LEASE
		} else if (dealType === ClientStatisticsController.DEAL_TYPE.MIRRORING) {
			newRouteParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.DEAL_TYPE] =
				ClientStatisticsUrlConvertor.constants.DEAL_TYPE_OPTIONS.MIRRORING
		}

		const currentUrl = this._router.getUrl()
		const newUrl = this._router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS, newRouteParams)

		if (currentUrl !== newUrl) {
			this._router.redirect(newUrl)
		}
	}
}

export default ClientStatisticsController
