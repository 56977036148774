import React from 'react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { CloseBtn as CloseButton } from '@inzeraty/components'
import PropTypes from 'prop-types'

import './Header.less'
import './HeaderCS.json'

const CLASSNAME = 'c-client-header'

const Header = (props) => {
	const { onReset = DEFAULT_PROPS.FUNCTION, onClose = DEFAULT_PROPS.FUNCTION } = props

	const localize = useLocalize()

	return (
		<div className={CLASSNAME}>
			<span className={`${CLASSNAME}__title`}>{localize('Header.filter')}</span>

			<button
				className={`${CLASSNAME}__reset-button`}
				type='button'
				onClick={onReset}
				data-dot='reset'
			>
				{localize('Header.reset')}
			</button>
			<CloseButton
				className={`${CLASSNAME}__close-button`}
				data-dot='filter-close'
				onClick={onClose}
			/>
		</div>
	)
}

Header.propTypes = {
	onReset: PropTypes.func,
	onClose: PropTypes.func
}

export default React.memo(Header)
