const RESOURCE = '/items'

export default class AdvertCountsResource {
	/**
	 * @constructor
	 * @param {RestClient} restClient
	 **/
	constructor(restClient) {
		this._restClient = restClient
	}

	/**
	 * Vrati pocty inzeratu dostupnych pro jeden filtr v zavislosti na ostatnich filtrech.
	 *
	 * @public
	 * @method getFilteredAdvertsCount
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>} Pocty inzeratu pro jeden dany filtr.
	 */
	getFilteredAdvertsCount(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/search/counts`, params, options)
	}
}
