import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import FilterConstants from 'app/model/filter/FilterConstants'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import PopupLogic from './PopupLogic'

class DependentPopupsLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			popupDefinition: PropTypes.object,
			dependentPopupDefinition: PropTypes.object,
			value: PropTypes.number, //TODO lepsi nazev?
			dependentValue: PropTypes.number,
			filterLogicData: PropTypes.object.isRequired,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			filterLogicData: DEFAULT_PROPS.OBJECT
		}
	}

	constructor(props, context) {
		super(props, context)

		this._closeDependentPopup = this._closeDependentPopup.bind(this)
		this._closeDependentPopupWithoutSavingChanges = this._closeDependentPopupWithoutSavingChanges.bind(
			this
		)
		this._resetDependentFilter = this._resetDependentFilter.bind(this)
	}

	render() {
		const { filterLogicData, children } = this.props

		return (
			<PopupLogic
				{...this.props}
				filterLogicData={Object.assign({}, filterLogicData, {
					advertsCount: filterLogicData.advertsCountForMainPopup
				})}
				autoCloseOnUnmount={false}
			>
				{(popupLogicChildrenProps) => {
					return children({
						mainPopup: popupLogicChildrenProps,

						dependentPopup: {
							isDefaultPopupType: this._isDependentPopupDefaultPopupType(),

							resetFilter: () => this._resetDependentFilter(popupLogicChildrenProps),

							closePopup: () => this._closeDependentPopup(popupLogicChildrenProps),
							canBePopupClosed: () => true,
							closePopupWithoutSavingChanges: this._closeDependentPopupWithoutSavingChanges
						}
					})
				}}
			</PopupLogic>
		)
	}

	componentDidMount() {
		const {
			filterLogicData: {
				advertsCountForDependentPopup: {
					loadFilteredAdvertsCount: loadFilteredAdvertsCountForDependentPopup = () => {}
				} = {}
			},
			dependentValue
		} = this.props

		// nacteme cisla jen pro zavisly popup, cisla pro hlavni popup se nacitaji v
		// komponente PopupLogic
		if (dependentValue) {
			loadFilteredAdvertsCountForDependentPopup(dependentValue)
		}
	}

	_closeDependentPopup(mainPopupLogicChildrenProps) {
		const {
			popupDefinition: { updateUrlParams } = {},
			formLineEntity: { id, value },
			dependentValue,
			redirect,
			changeFilter,
			filterLogicData: {
				advertsCountForDependentPopup: { calcFilteredAdvertsCountTotal = () => {} } = {}
			}
		} = this.props
		const { closePopupWithoutSavingChanges: closeMainPopup } = mainPopupLogicChildrenProps
		closeMainPopup()

		this.fire('closeFilterPopup', {
			filterId: id,
			openedByValue: dependentValue
		})

		changeFilter(id, value, (newFormLineEntity) => {
			calcFilteredAdvertsCountTotal(newFormLineEntity)

			if (updateUrlParams) {
				redirect()
			}
		})
	}

	_closeDependentPopupWithoutSavingChanges() {
		const {
			formLineEntity: { id },
			dependentValue
		} = this.props

		this.fire('closeFilterPopup', {
			filterId: id,
			openedByValue: dependentValue
		})
	}

	_resetDependentFilter(mainPopupLogicChildrenProps) {
		const {
			formLineEntity: { id, extra, value: valueData },
			popupDefinition: { updateUrlParams } = {},
			dependentValue,
			redirect,
			changeFilter,
			filterLogicData: {
				advertsCountForDependentPopup: { calcFilteredAdvertsCountTotal = () => {} } = {}
			}
		} = this.props
		const { closePopupWithoutSavingChanges: closeMainPopup } = mainPopupLogicChildrenProps

		// TODO pro filtry typu znacky/modely nebo kraje/okresy - mozna sem posilat novou hodnotu pres parametry?
		const shouldResetOnlyOneValue = dependentValue && valueData.has && valueData.has(dependentValue)

		const getMapWithoutValue = (map, value) => {
			const newMap = new Map(map)
			newMap.delete(value)

			return newMap
		}

		const newValue = shouldResetOnlyOneValue
			? getMapWithoutValue(valueData, dependentValue)
			: extra.emptyValue

		changeFilter(id, newValue, (newFormLineEntity) => {
			closeMainPopup()

			this.fire('closeFilterPopup', {
				filterId: id,
				openedByValue: dependentValue
			})

			calcFilteredAdvertsCountTotal(newFormLineEntity)

			if (updateUrlParams) {
				redirect()
			}
		})
	}

	_isDependentPopupDefaultPopupType() {
		const { dependentPopupDefinition } = this.props

		return dependentPopupDefinition
			? dependentPopupDefinition.filterPopupType === FilterConstants.filterPopupType.DEFAULT
			: true
	}
}

const selectors = (state) => ({
	redirect: state[FiltersExtension.stateIds.REDIRECT],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(selectors)(DependentPopupsLogic)
