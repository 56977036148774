import React, { useContext } from 'react'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import { Format } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import { EXTERNAL_LINK_OUTLINE } from '@sznds/icons'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import ActionButton from 'app/component/actionButton/ActionButton'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import PremiseEntityImportLog from 'app/model/premise/PremiseEntityImportLog'

export default function IAImporListView({
	entitiesAndPagination,
	isLoading = false,
	filterFormLineEntities,
	isFilterLoading = false
}) {
	const { $Router } = useContext(Context)

	return (
		<Layout title='Výpis importů'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />
			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='premise.id' sortParam='premise_id'>
						ASK ID
					</TableHeadSorter>,
					<TableHead key='premise.name'>Klient</TableHead>,
					<TableHeadSorter
						key='timestamp'
						sortParam='timestamp'
						isDefaultAscending={true}
						alignRight={true}
					>
						Čas importu
					</TableHeadSorter>,
					<TableHead key='counts.add_edit_all'>Vozů celkem</TableHead>,
					<TableHead key='counts.add_edit_stored'>Vozů zatříděných</TableHead>,
					<TableHeadSorter key='software_key' sortParam='software_key'>
						SW klíč
					</TableHeadSorter>,
					<TableHead key='action'>Akce</TableHead>
				]}
				rowCells={[
					({ premise: { id } }) => [id, { isNumber: true }],
					({ premise: { name } }) => name,
					({ timestamp }) => Format.dateTimeSeconds(timestamp),
					({ counts: { addEditCarAll } }) => Format.number(addEditCarAll),
					({ counts: { addEditCarSuccessful } }) => Format.number(addEditCarSuccessful),
					({ softwareKey }) => softwareKey,
					({ sessionId, timestamp }, actionButtonProps = {}) => (
						<ActionButton
							icon={EXTERNAL_LINK_OUTLINE}
							key='detail'
							title='Detail logu'
							href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.IMPORT_DETAIL, {
								[InternalAdminUrlConvertor.constants.SESSION_ID]: sessionId,
								[InternalAdminUrlConvertor.constants.SESSION_DATE]: Format.dateEN(timestamp)
							})}
							{...actionButtonProps}
						/>
					)
				]}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAImporListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(PremiseEntityImportLog)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	isLoading: PropTypes.bool,
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool
}
