export default class ModificationDetailFactory {
	transformData(data) {
		const detail = data[0] ? data[0] : {}

		const generation = detail.generation || {}

		return {
			id: detail.id,
			images: detail.images,
			name: detail.name,
			seoName: detail.seo_name,
			yearEnd: detail.year_end,
			yearStart: detail.year_start,
			generation: {
				id: generation.id,
				name: generation.name,
				seoName: generation.seo_name,
				model: generation.model
					? {
							id: generation.model.id,
							name: generation.model.name,
							seoName: generation.model.seo_name,
							manufacturer: generation.model.manufacturer
								? {
										id: generation.model.manufacturer.id,
										name: generation.model.manufacturer.name,
										seoName: generation.model.manufacturer.seo_name
								  }
								: {}
					  }
					: {}
			},
			data: detail.data
				? {
						maxSpeed: detail.data.maxspeed,
						engineVolume: detail.data.engineDisplacement,
						fuel: detail.data.fuel,
						fuelConsumptionUrbanMin: detail.data.fuelConsumptionUrbanMin,
						fuelConsumptionUrbanMax: detail.data.fuelConsumptionUrbanMax,
						fuelConsumptionExtraurbanMin: detail.data.fuelConsumptionExtraurbanMin,
						fuelConsumptionExtraurbanMax: detail.data.fuelConsumptionExtraurbanMax,
						fuelConsumptionCombinedMin: detail.data.fuelConsumptionCombinedMin,
						fuelConsumptionCombinedMax: detail.data.fuelConsumptionCombinedMax,
						fuelSeo: detail.data.fuel_seo,
						powerHp: detail.data.powerHp,
						powerKw: detail.data.powerKw,
						power: detail.data.power,
						powerRpmHigh: detail.data.powerRpmHigh,
						powerRpmLow: detail.data.powerRpmLow,
						vehicleBody: detail.data.coupe,
						vehicleBodySeo: detail.data.vehicle_body_seo,
						seats: detail.data.places,
						doors: detail.data.doors,
						co2Max: detail.data.co2Max,
						co2Min: detail.data.co2Min,
						cilinders: detail.data.cilinders,
						torqueNm: detail.data.torqueNm,
						torqueRpmHigh: detail.data.torqueRpmHigh,
						torqueRpmLow: detail.data.torqueRpmLow,
						emissionStandard: detail.data.emissionStandard,
						acceleration: detail.data.acceleration,
						acceleration200: detail.data.acceleration200,
						trailerLoadBraked12perc: detail.data.trailerLoadBraked12perc,
						trailerLoadUNBraked: detail.data.trailerLoadUNBraked,
						gearboxATNumber: detail.data.gearboxAT,
						gearboxATType: detail.data.gearboxATType,
						gearboxMTNumber: detail.data.gearboxMT,
						maxEngineSpeed: detail.data.maxEngineSpeed,
						engineCode: detail.data.engineCode,
						fuelSystem: detail.data.fuelSystem,
						positionCilinders: detail.data.positioncilinders,
						valvesPerCilinder: detail.data.valvesPerCilinder,
						compressionRatio: detail.data.compressionRatio,
						stroke: detail.data.stroke,
						bore: detail.data.bore,
						turbine: detail.data.turbine,
						valvetrain: detail.data.valvetrain,
						engineOilCapacity: detail.data.engineOilCapacity,
						carLength: detail.data.length,
						carHeight: detail.data.height,
						carWidth: detail.data.width,
						wheelbase: detail.data.wheelbase,
						frontTrackMin: detail.data.frontTrackMin,
						frontTrackMax: detail.data.frontTrackMax,
						frontOverhang: detail.data.frontOverhang,
						rearTrackMin: detail.data.rearTrackMin,
						rearTrackMax: detail.data.rearTrackMax,
						rearOverhang: detail.data.rearOverhang,
						curbWeightMin: detail.data.curbWeightMin,
						curbWeightMax: detail.data.curbWeightMax,
						carMaxWeigh: detail.data.maxWeightMax,
						noseWeight: detail.data.noseweight,
						roofLoad: detail.data.roofLoad,
						luggageMin: detail.data.luggageMaxMin,
						luggageMax: detail.data.luggageMaxMax,
						tankVolume: detail.data.tankVolume,
						adblueTankVolume: detail.data.adblueTankVolume,
						rim: detail.data.rim
				  }
				: {}
		}
	}
}
