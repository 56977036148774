import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import GenericError from 'ima/error/GenericError'

export default class AbstractOpenerLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			context: PropTypes.string.isRequired,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._clearFilter = this._clearFilter.bind(this)
	}

	render() {
		const {
			formLineEntity: { id }
		} = this.props

		throw new GenericError(
			`Je potřeba implementovat metodu render logiky openeru pro formLineEntity.id = '${id}'`
		)
	}

	_clearFilter() {
		const {
			formLineEntity: { id, extra },
			changeFilter
		} = this.props

		changeFilter(id, extra.emptyValue)
	}
}
