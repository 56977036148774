import STATE_KEYS from 'app/page/userweb/newAdvert/payment/PaymentStateKeys'

export default Object.freeze({
	ADVERT_ENTITY: STATE_KEYS.ADVERT_ENTITY,
	PAYMENTS: STATE_KEYS.PAYMENTS,
	PAYMENT_TYPE: STATE_KEYS.PAYMENT_TYPE,
	TERMS_AND_CONDITIONS_CHECKED: STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED,
	SET_PAYMENT_TYPE: STATE_KEYS.SET_PAYMENT_TYPE,
	SET_TERMS_AND_CONDITIONS: STATE_KEYS.SET_TERMS_AND_CONDITIONS,
	LOADING: STATE_KEYS.LOADING,
	ERROR_MESSAGE: STATE_KEYS.ERROR_MESSAGE,
	REFRESH_PAYMENTS: STATE_KEYS.REFRESH_PAYMENTS,
	HANDLE_WALLET_PAYMENT: STATE_KEYS.HANDLE_WALLET_PAYMENT,
	HANDLE_CREDIT_CARD_PAYMENT: STATE_KEYS.HANDLE_CREDIT_CARD_PAYMENT
})
