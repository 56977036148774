import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import BaseWidget from '../base/BaseWidget'
import { Surface } from '@sznds/react'
import { SERVICE } from 'app/base/Constants'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './SourceWidget.less'
import './SourceWidgetCS.json'

export const SOURCE_WIDGET_ID = 'source_widget'
export const SOURCE_OPTIONS = {
	SAUTO: SERVICE,
	FIRMY: 'firmy'
}
const CLASSNAME = 'c-cp-source-widget'

function SourceWidget({ formLineEntity, onChange, section }) {
	const localize = useLocalize()

	return (
		<BaseWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={SOURCE_WIDGET_ID}
			onChange={(_, value) => {
				onChange({
					id: formLineEntity.id,
					value,
					widget: SOURCE_WIDGET_ID,
					section
				})
			}}
			renderWidget={(
				formLineEntity,
				renderLabel,
				renderInput,
				renderMessage,
				renderErrorMessage
			) => {
				const Input = renderInput()
				const Label = renderLabel()

				return (
					<>
						<span className={`${CLASSNAME}__description`}>{localize('SourceWidget.show')}</span>
						<Surface surface={3} className={`${CLASSNAME}__switcher`}>
							<Input source={SOURCE_OPTIONS.SAUTO} />
							<Label source={SOURCE_OPTIONS.SAUTO} />
							<Input source={SOURCE_OPTIONS.FIRMY} />
							<Label source={SOURCE_OPTIONS.FIRMY} />
						</Surface>
					</>
				)
			}}
			renderInput={(formLineEntity, { className, id, value, onFocus, ...props }) => {
				const Input = ({ source }) => (
					<input
						{...props}
						className={`${CLASSNAME}__input`}
						type='radio'
						id={`${id}-${source}`}
						checked={formLineEntity.value === source}
						value={source}
					/>
				)

				Input.propTypes = {
					source: PropTypes.string
				}

				return Input
			}}
			renderLabel={({ extra: { sourceLabel } }, { className, htmlFor, ...props }) => {
				const Label = ({ source }) => (
					<label className={`${CLASSNAME}__label`} htmlFor={`${htmlFor}-${source}`} {...props}>
						<span className={`${CLASSNAME}__accessible-label`}>
							{`${localize('SourceWidget.show')}${localize('SourceWidget.from', {
								LABEL_FROM: sourceLabel
							})}`}
						</span>
						{localize(`SourceWidget.${source}`)}
					</label>
				)

				Label.propTypes = {
					source: PropTypes.string
				}

				return Label
			}}
		/>
	)
}

SourceWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func.isRequired,
	section: PropTypes.string
}

export default SourceWidget
