import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImaContext from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'
import TextareaCompWidget, {
	TEXTAREA_WIDGET_ID
} from 'app/component/formLineWidgets/textarea/TextareaCompWidget'
import { Button } from '@sznds/react'
import { FORM_LINES_IDS, FORM_LINES_NAMES } from './ReviewReplyFormLineConstants'
import select from 'ima-plugin-select'
import ReviewsExtension from '../ReviewsExtension'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import 'app/base/BaseCS'
import './ReviewReplyFormCS.json'
import './ReviewReplyForm.less'
import 'app/page/userweb/newAdvert/basicInfo/BasicInfoCS.json'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

const { Form } = FormLines

const WIDGETS_BY_ID = Object.freeze({
	[TEXTAREA_WIDGET_ID]: TextareaCompWidget
})

const CLASSNAME = 'c-review-reply-form'

const ReviewReplyForm = ({ reviewId, setIsOpen, onSubmit }) => {
	const localize = useLocalize()
	const { $Dictionary } = useContext(ImaContext)

	const getFormLineEntities = () => {
		return FormLines.createEntityList([
			{
				widget: TEXTAREA_WIDGET_ID,
				id: FORM_LINES_IDS.REVIEW_REPLY,
				label: localize('ReviewReplyForm.reviewReplyLabel'),
				placeholder: localize('ReviewReplyForm.reviewReplyPlaceholder'),
				extra: {
					getData: (value) => ({ [FORM_LINES_NAMES.REVIEW_REPLY]: value }),
					isFormLineValid: (formLine) => formLine.value
				}
			}
		])
	}

	const [readyToSubmit, setReadyToSubmit] = useState(false)
	const [isSending, setIsSending] = useState(false)
	const [formLineEntities, setFormLineEntities] = useState(getFormLineEntities())
	const [serverErrorValues, setServerErrorValues] = useState([])

	useEffect(() => {
		setFormLineEntities(FormLines.updateEntities(formLineEntities, serverErrorValues))
	}, [serverErrorValues])

	useEffect(() => {
		setIsSending(false)
	}, [serverErrorValues])

	const onChange = ({ id, value, errorMessage }) => {
		const changes = [
			{
				id,
				value,
				errorMessage
			}
		]

		if (id === FORM_LINES_IDS.REVIEW_REPLY) {
			setReadyToSubmit(value.trim() !== '')
		}

		setFormLineEntities(FormLines.updateEntities(formLineEntities, changes))
	}

	const onBlur = () => {}

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			formLineEntities,
			{
				onChange,
				onBlur
			},
			WIDGETS_BY_ID
		)
	}

	const renderSubmitButton = (props) => {
		return (
			<div className={`${CLASSNAME}__form-btns`}>
				<Button
					text={localize('Base.close')}
					size='small'
					onClick={() => {
						setIsOpen(false)
					}}
					className={classnames({
						[`${CLASSNAME}__form-btn`]: true,
						[`${CLASSNAME}__form-btn--close`]: true
					})}
				/>
				<Button
					{...props}
					disabled={isSending || !readyToSubmit}
					loading={isSending}
					primary={true}
					size='small'
					text={localize('ReviewReplyForm.submit')}
					className={classnames({
						[`${CLASSNAME}__form-btn`]: true,
						[`${CLASSNAME}__form-btn--submit`]: true
					})}
					data-e2e='add-review-reply-button'
				/>
			</div>
		)
	}

	const onSubmitForm = () => {
		setIsSending(true)
		const formData = FormLines.getFormData(formLineEntities)
		onSubmit(reviewId, formData, onSubmitSuccess, onSubmitError)
	}

	const onSubmitSuccess = (result) => {
		setIsOpen(false)
	}

	const onSubmitError = (errors = []) => {
		setIsSending(false)

		if (errors.length) {
			const errorValues = []

			errors.forEach((error) => {
				const { error_code: errorCode, fields } = error

				const error_code =
					errorCode === ERROR_CODES.REQUIRED ? ERROR_CODES.REQUIRED : ERROR_CODES.NOT_VALID

				fields.forEach((field_id) => {
					const dictKey = `ReviewReplyForm.${field_id}_${error_code}`

					let errorMessage = $Dictionary.has(dictKey)
						? localize(dictKey)
						: localize(`ReviewReplyForm.value_${error_code}`)

					let id = field_id

					errorValues.push({
						id,
						errorMessage
					})
				})
			})

			setServerErrorValues(errorValues)
		}
	}

	return (
		<Form
			className={CLASSNAME}
			formLineEntities={formLineEntities}
			isSending={isSending}
			renderWidgets={renderWidgets}
			onSubmit={onSubmitForm}
			renderSubmitButton={renderSubmitButton}
		/>
	)
}

ReviewReplyForm.propTypes = {
	reviewId: PropTypes.number.isRequired,
	setIsOpen: PropTypes.func,
	onSubmit: PropTypes.func
}

ReviewReplyForm.defaultProps = {
	setIsOpen: DEFAULT_PROPS.FUNCTION
}

const selectors = (state) => {
	return {
		onSubmit: state[ReviewsExtension.STATE_IDS.ON_ADD_REVIEW_REPLY_SUBMIT]
	}
}

export default React.memo(select(selectors)(ReviewReplyForm))
