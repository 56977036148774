import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Radio, Spinner } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { WatchDogConstants as MySavedSearchConstants } from '@inzeraty/models'
import { Popup } from '@inzeraty/components'
import { useLocalize } from 'app/base/componentHelpers'
import MySavedSearchPropTypes from 'app/model/mySavedSearch/MySavedSearchPropTypes'
import MySavedSearchParamsPrint from '../paramsPrint/MySavedSearchParamsPrint'

import 'app/base/BaseCS.json'
import './MySavedSearchSaveFormCS.json'
import './MySavedSearchSaveForm.less'

const CLASSNAME = 'c-my-saved-search-save-form'

const { NOTIFICATION_INTERVAL } = MySavedSearchConstants

const MySavedSearchSaveForm = ({
	isPopupOpened,
	isLoading,
	mySavedSearch,
	renderStatusMessage,
	onClose,
	onSave
}) => {
	const localize = useLocalize()

	const OPTIONS = [
		{
			value: NOTIFICATION_INTERVAL.HOURLY,
			label: localize('MySavedSearchSaveForm.immediately')
		},

		{
			value: NOTIFICATION_INTERVAL.DAILY,
			label: localize('MySavedSearchSaveForm.daily'),
			labelNote: localize('MySavedSearchSaveForm.dailyNote')
		},

		{
			value: NOTIFICATION_INTERVAL.NEVER,
			label: localize('MySavedSearchSaveForm.notToSend'),
			labelNote: localize('MySavedSearchSaveForm.notToSendNote')
		}
	]

	const [isFormSubmiting, setIsFormSubmiting] = useState(false)

	const [notificationInterval, setNotificationInterval] = useState(
		mySavedSearch.notificationInterval
	)

	const [ribbonNotificationEnabled, setRibbonNotificationEnabled] = useState(
		mySavedSearch.ribbonNotificationEnabled
	)

	useEffect(() => {
		const { notificationInterval, ribbonNotificationEnabled } = mySavedSearch

		notificationInterval && setNotificationInterval(notificationInterval)
		setRibbonNotificationEnabled(ribbonNotificationEnabled)
	}, [mySavedSearch])

	const submitForm = async (event) => {
		event.preventDefault()

		setIsFormSubmiting(true)
		await onSave(mySavedSearch, notificationInterval, ribbonNotificationEnabled)
		setIsFormSubmiting(false)
	}

	const renderedStatusMessage = renderStatusMessage()

	const renderedContent = isLoading ? (
		<div className={`${CLASSNAME}__spinner-wrapper`}>
			<Spinner className={`${CLASSNAME}__spinner`} />
			{localize('MySavedSearchSaveForm.contentLoading')}
		</div>
	) : (
		<>
			<MySavedSearchParamsPrint className={`${CLASSNAME}__params`} mySavedSearch={mySavedSearch} />

			<h3 className={`${CLASSNAME}__options-title`}>
				{localize('MySavedSearchSaveForm.optionsTitle')}
			</h3>

			<div className={`${CLASSNAME}__description`}>
				{localize('MySavedSearchSaveForm.description')}
			</div>

			<div>
				{OPTIONS.map((option) => (
					<Option
						key={option.value}
						selectedValue={notificationInterval}
						option={option}
						onChange={setNotificationInterval}
					/>
				))}
			</div>

			<div className={`${CLASSNAME}__ribbon-notifications-desc`}>
				{localize('MySavedSearchSaveForm.ribbonNotificationsDescription')}
			</div>

			<Checkbox
				checked={ribbonNotificationEnabled}
				label={localize('MySavedSearchSaveForm.ribbonNotifications')}
				data-e2e={
					ribbonNotificationEnabled
						? 'ribbon-notification-checked'
						: 'ribbon-notification-unchecked'
				}
				onChange={(event) => {
					event.stopPropagation()
					setRibbonNotificationEnabled(!ribbonNotificationEnabled)
				}}
			/>
		</>
	)

	return (
		isPopupOpened && (
			<div className={CLASSNAME}>
				<Popup
					isOpen={true}
					hasLightbox={true}
					title={
						mySavedSearch.id
							? localize('MySavedSearchSaveForm.update')
							: localize('MySavedSearchSaveForm.add')
					}
					dictionary={{
						close: localize('Base.close')
					}}
					onClose={onClose}
				>
					<form className={`${CLASSNAME}__form`} onSubmit={submitForm} data-dot='watch-dog'>
						<div className={`${CLASSNAME}__content`}>
							{renderedStatusMessage && (
								<div className={`${CLASSNAME}__status-message`}>{renderedStatusMessage}</div>
							)}

							{renderedContent}
						</div>

						<div className={`${CLASSNAME}__button-wrapper`}>
							<Button
								className={`${CLASSNAME}__button ${CLASSNAME}__button--close`}
								text={localize('Base.close')}
								onClick={() => onClose()}
							/>

							<Button
								className={`${CLASSNAME}__button`}
								type='submit'
								primary={true}
								loading={isFormSubmiting}
								disabled={isLoading}
								text={
									mySavedSearch.id
										? localize('MySavedSearchSaveForm.update')
										: localize('MySavedSearchSaveForm.add')
								}
								data-e2e='add-my-saved-search'
							/>
						</div>
					</form>
				</Popup>
			</div>
		)
	)
}

MySavedSearchSaveForm.propTypes = {
	isPopupOpened: PropTypes.bool,
	isLoading: PropTypes.bool,
	mySavedSearch: PropTypes.shape(MySavedSearchPropTypes),
	renderStatusMessage: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired
}

MySavedSearchSaveForm.defaultProps = {
	isPopupOpened: false,
	isLoading: false,
	mySavedSearch: DEFAULT_PROPS.OBJECT,
	renderStatusMessage: DEFAULT_PROPS.FUNCTION,
	onClose: DEFAULT_PROPS.FUNCTION,
	onSave: DEFAULT_PROPS.FUNCTION
}

export default React.memo(MySavedSearchSaveForm)

const Option = ({ selectedValue, option, onChange }) => {
	const { value, label, labelNote } = option

	const renderedLabel = (
		<>
			{label}{' '}
			{Boolean(labelNote) && <span className={`${CLASSNAME}__label-note`}>{labelNote}</span>}
		</>
	)

	const isChecked = value === selectedValue

	return (
		<div className={`${CLASSNAME}__option-row`}>
			<Radio
				id={`opt-${value}`}
				name='notificationInterval'
				checked={isChecked}
				value={value}
				label={renderedLabel}
				data-e2e={isChecked ? 'checked' : 'unchecked'}
				onChange={() => onChange(value)}
			/>
		</div>
	)
}

Option.propTypes = {
	selectedValue: PropTypes.string,
	option: PropTypes.shape({
		value: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		labelNote: PropTypes.string
	}).isRequired,
	onChange: PropTypes.func
}

Option.defaultProps = {
	option: DEFAULT_PROPS.OBJECT,
	onChange: DEFAULT_PROPS.FUNCTION
}
