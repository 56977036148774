import React from 'react'
import BlankManagedRootView from 'ima/page/renderer/BlankManagedRootView'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import AdminEntityContext from './AdminEntityContext'

export default class InternalAdminManagedRootView extends BlankManagedRootView {
	render() {
		return React.createElement(
			AdminEntityContext.Provider,
			{ value: this.props[AbstractInternalAdminController.stateId.ADMIN_SELF] },
			super.render()
		)
	}
}
