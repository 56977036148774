import AbstractComponent from 'app/base/AbstractComponent'
import React from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import STATE_KEYS from 'app/page/userweb/seller/SellerStateKeys'
import PremiseEntity from 'app/model/premise/PremiseEntity'
import SellerCard from 'app/component/sellerCard/SellerCard'
import SellerContactHeader from 'app/component/sellerContactHeader/SellerContactHeader'
import AdvertListExtension from 'app/component/advertList/AdvertListExtension'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './SellerContactSectionView.less'

const CLASSNAME = 'c-seller-contact'

class SellerContactSection extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			advertListAndPagination: PropTypes.object,
			premiseEntity: PropTypes.instanceOf(PremiseEntity),
			isPremiseEntityLoaded: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			advertListAndPagination: DEFAULT_PROPS.OBJECT,
			isPremiseEntityLoaded: false
		}
	}

	constructor(props) {
		super(props)

		this.state = {
			advertsCount: 0
		}
	}

	render() {
		const { premiseEntity, advertListAndPagination, isPremiseEntityLoaded, className } = this.props
		const { paginationEntity: { total } = {} } = advertListAndPagination

		return (
			<div
				className={this.cssClasses({
					[className]: !!className,
					[CLASSNAME]: true
				})}
			>
				<SellerContactHeader
					premiseEntity={premiseEntity}
					isDataLoaded={!!premiseEntity}
					isAdvertListLoaded={!!advertListAndPagination.advertList}
					premiseAdvertsCount={total}
					className={`${CLASSNAME}__header`}
				/>

				<SellerCard
					premiseEntity={premiseEntity}
					isPremiseSeller={true}
					showDescription={true}
					isDataLoaded={isPremiseEntityLoaded}
				/>
			</div>
		)
	}
}

const SellerContactSectionSelector = (state) => ({
	advertListAndPagination: state[AdvertListExtension.stateIds.ADVERT_LIST_PAGINATION],
	premiseEntity: state[STATE_KEYS.PREMISE_ENTITY],
	isPremiseEntityLoaded: state[STATE_KEYS.IS_PREMISE_ENTITY_LOADED]
})

export default select(SellerContactSectionSelector)(SellerContactSection)
