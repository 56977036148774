import React, { useContext } from 'react'
import { BOOK, TRASH_FILLED } from '@sznds/icons'
import { Format } from '@inzeraty/helpers'
import { useFire } from 'app/base/componentHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import ActionButton from 'app/component/actionButton/ActionButton'
import TableHead from 'app/component/tableHead/TableHead'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import Context from 'ima/page/context'
import IAReviewListController from './IAReviewListController'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import ReviewEntity from 'app/model/review/ReviewEntity'
import ROUTE_NAMES from 'app/base/RouteNames'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

const { ACTIONS } = IAReviewListController

const revviewTypeMapTable = {
	positive: 'Pozitivní',
	negative: 'Negativní',
	neutral: 'Neutrální'
}

export default function IAReviewList({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading
}) {
	const { $Router } = useContext(Context)
	const { rights = [] } = useContext(AdminEntityContext) || {}
	const fire = useFire()

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		const actions = [
			({ id }, actionButtonProps = {}) => (
				<ActionButton
					icon={BOOK}
					key='reactions'
					title='Výpis odpovědí'
					href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.REVIEW_REPLY_LIST, {
						[InternalAdminUrlConvertor.constants.FILTER_BY]:
							InternalAdminUrlConvertor.constants.REVIEW_ID,
						[InternalAdminUrlConvertor.constants.FILTER_BY_VALUE]: id
					})}
					{...actionButtonProps}
				/>
			)
		]

		if (rights.includes(ADMIN_RIGHTS.REVIEWS_DELETE)) {
			actions.push((entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='delete'
					title='Smazat'
					onClick={() => action(ACTIONS.DELETE, [entity])}
					{...actionButtonProps}
				/>
			))
		}

		return actions
	}

	return (
		<Layout title='Výpis recenzí'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHead key='id'>ID recenze</TableHead>,
					<TableHead key='manufacturer'>Výrobce a model</TableHead>,
					<TableHead key='userId'>ID uživatele</TableHead>,
					<TableHead key=''>Hodnocení</TableHead>,
					<TableHead key='title'>Titulek</TableHead>,
					<TableHeadSorter key='create_date' sortParam='create_date' isDefaultDescending={true}>
						Vytvořeno
					</TableHeadSorter>
				]}
				rowCells={[
					({ id }) => id,
					({ manufacturerCb = {}, modelCb = {} }) => `${manufacturerCb.name} ${modelCb.name}`,
					({ userId }) => userId,
					({ type }) => revviewTypeMapTable[type],
					({ title }) => title,
					({ createDate }) => Format.dateTime(createDate)
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAReviewList.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(ReviewEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool
}
