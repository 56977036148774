
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['IAPremiseEdit'] = {
  id: function(d) { return "ASK ID:"; },
  externalId: function(d) { return "Sauto ID:"; },
  advertCount: function(d) { return "Počet inzerátů:"; },
  productList: function(d) { return "Výpis produktů:"; },
  retargetingId: function(d) { return "Retargeting ID:"; },
  isActive: function(d) { return "Aktivní:"; },
  importData: function(d) { return "Přihlašovací údaje pro import"; },
  login: function(d) { return "Login:"; },
  password: function(d) { return "Nové heslo:"; },
  softwareKeys: function(d) { return "Softwarové klíče"; },
  softwareKey: function(d) { return "Softwarový klíč:"; },
  walletPlaceholder: function(d) { return "Email k peněžence"; },
  hideVin: function(d) { return "Skrýt VIN:"; },
  homeDelivery: function(d) { return "Auto domů:"; },
  useAdvertLocality: function(d) { return "Použít adresu z inzerátu:"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	