import React, { useState } from 'react'
import classnames from 'classnames'
import { ElementInViewportDetector } from '@inzeraty/components'
import PropTypes from 'prop-types'

import './ToastsContainer.less'

const CLASSNAME = 'c-toasts-container'

const ToastsContainer = (props) => {
	const { children } = props

	const [isSticky, setIsSticky] = useState(false)

	const onElementInViewportRecalc = (data) => {
		const { isElementInsideViewport } = data

		setIsSticky(!isElementInsideViewport)
	}

	return (
		<ElementInViewportDetector onRecalc={onElementInViewportRecalc}>
			<div className={CLASSNAME}>
				<div
					className={classnames({
						[`${CLASSNAME}__content`]: true,
						[`${CLASSNAME}__content--sticky`]: isSticky
					})}
				>
					{children}
				</div>
			</div>
		</ElementInViewportDetector>
	)
}

ToastsContainer.propTypes = {
	children: PropTypes.node
}

export default React.memo(ToastsContainer)
