
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['MonthYearSelectBase'] = {
  monthPlaceholder: function(d) { return "Měsíc"; },
  yearPlaceholder: function(d) { return "Zadejte rok"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	