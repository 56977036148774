import PropTypes from 'prop-types'

const premisePropTypes = {
	description: PropTypes.string,
	emails: PropTypes.arrayOf(
		PropTypes.shape({
			default: PropTypes.bool,
			email: PropTypes.string,
			id: PropTypes.number,
			name: PropTypes.string
		})
	),
	id: PropTypes.number,
	locality: PropTypes.shape({
		address: PropTypes.string,
		citypart: PropTypes.string,
		district: PropTypes.string,
		municipality: PropTypes.string,
		quarter: PropTypes.string
	}),
	logo: PropTypes.string,
	name: PropTypes.string,
	oldId: PropTypes.number,
	openingHours: PropTypes.shape({
		openingTime: PropTypes.array
	}),
	phones: PropTypes.arrayOf(
		PropTypes.shape({
			default: PropTypes.bool,
			phone: PropTypes.string,
			id: PropTypes.number,
			name: PropTypes.string
		})
	),
	seoName: PropTypes.string,
	web: PropTypes.string
}

export default premisePropTypes
