import { AbstractFactory } from '@inzeraty/models'
import FeedbackEntity from './FeedbackEntity'

export default class FeedbackFactory extends AbstractFactory {
	constructor() {
		super(FeedbackEntity)
	}

	transformData(data = {}) {
		return {
			id: data.id,
			email: data.email,
			url: data.url,
			createDate: data.create_date,
			feedbackType: data.feedback_type,
			feedbackSubject: data.feedback_subject,
			feedbackText: data.feedback_text,
			hasText: data.has_text,
			userAgent: data.user_agent
		}
	}
}
