import UserwebBaseController from 'app/base/UserwebBaseController'
import STATUS_CODE from 'app/base/HttpStatusCode'
import ROUTE_NAMES from 'app/base/RouteNames'
import ArticlesMeta from './ArticlesMeta'
import ArticlesUrlConvertor from './ArticlesUrlConvertor'

import 'app/base/BaseCS.json'

export default class ArticlesController extends UserwebBaseController {
	constructor(dependenciesHelper, articleService, backToHpExtension) {
		super(dependenciesHelper)

		this._articleService = articleService
		this._backToHpExtension = backToHpExtension
	}

	static get STATE_KEYS() {
		return Object.assign({
			ARTICLES_TITLE: 'articlesTitle',
			ARTICLES: 'articles'
		})
	}

	init() {
		super.init()

		this.addExtension(this._backToHpExtension)
	}

	load() {
		this._checkUrl()

		const { header } = this._getMetas()

		return super.load({
			[ArticlesController.STATE_KEYS.ARTICLES_TITLE]: header,
			[ArticlesController.STATE_KEYS.ARTICLES]: this._getArticles()
		})
	}

	update(prevParams) {
		this._setPlaceholders()

		return super.update(prevParams, {
			[ArticlesController.STATE_KEYS.ARTICLES]: this._getArticles()
		})
	}

	getDataForSsp() {
		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {}
		}
	}

	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const { title, description } = this._getMetas()

		const metaTitle = title + dictionary.get('Base.titleSuffix')
		metaManager.setTitle(metaTitle)
		metaManager.setMetaName('twitter:title', metaTitle)
		metaManager.setMetaProperty('og:title', metaTitle)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('twitter:description', description)
		metaManager.setMetaProperty('og:description', description)
	}

	_getArticles() {
		const params = ArticlesUrlConvertor.getApiParams(this.getRouteParams())

		return this._articleService.getArticlesWithPagination(params, { cache: true }).catch(() => {
			return {
				articlesList: [],
				pagination: {}
			}
		})
	}

	_setPlaceholders() {
		this.setState({
			[ArticlesController.STATE_KEYS.ARTICLES]: {
				articlesList: undefined
			}
		})
	}

	_getMetas() {
		const routeParams = this.getRouteParams()
		return ArticlesMeta.getMetas(routeParams, this._utils.$Dictionary)
	}

	_checkUrl() {
		const updatedParams = Object.assign({}, this.getRouteParams())
		const {
			[ArticlesUrlConvertor.constants.URL_APP_PARAMS.OLD_CATEGORY]: oldCategory,
			[ArticlesUrlConvertor.constants.URL_APP_PARAMS.OLD_SEARCH]: oldSearch
		} = updatedParams

		if (typeof oldCategory !== 'undefined' || typeof oldSearch !== 'undefined') {
			switch (Number(oldCategory)) {
				case 0: // novinky
					updatedParams[ArticlesUrlConvertor.constants.URL_APP_PARAMS.CATEGORY] =
						ArticlesUrlConvertor.categories.NEWS
					break
				case 1: // Recenze
				case 5: // testy
					updatedParams[ArticlesUrlConvertor.constants.URL_APP_PARAMS.CATEGORY] =
						ArticlesUrlConvertor.categories.TESTS
					break
			}

			delete updatedParams[ArticlesUrlConvertor.constants.URL_APP_PARAMS.OLD_CATEGORY]
			delete updatedParams[ArticlesUrlConvertor.constants.URL_APP_PARAMS.OLD_SEARCH]

			const url = this._utils.$Router.link(ROUTE_NAMES.USERWEB.ARTICLES, updatedParams)
			this._utils.$Router.redirect(url, {
				httpStatus: STATUS_CODE.MOVED_PERMANENTLY
			})
		}
	}
}
