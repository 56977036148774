import FilterConstants from 'app/model/filter/FilterConstants'
import AbstractOpenerLogic from './AbstractOpenerLogic'

export default class DropdownOpenerLogic extends AbstractOpenerLogic {
	constructor(props, context) {
		super(props, context)

		this._openDropdown = this._openDropdown.bind(this)
	}

	render() {
		const { children } = this.props

		return children({
			openDropdown: this._openDropdown,
			clearFilter: this._clearFilter
		})
	}

	_openDropdown(value) {
		const {
			formLineEntity: { id },
			context
		} = this.props

		this.fire('openFilterDropdown', {
			filterId: id,
			openedByValue: value,
			context,
			filterDropdownType: FilterConstants.filterDropdownType.DEFAULT,
			updateUrlParams: false
		})
	}
}
