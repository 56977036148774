import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import FilterConstants from 'app/model/filter/FilterConstants'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import radioFilters from './radioFiltersList'

/**
 * @class FilterFormLinesToMySavedSearchConvertor
 * Prevadi formlines filtru na vypise inzeratu na strukturu filtru ulozeneho hledani.
 */
export default class FilterFormLinesToMySavedSearchConvertor {
	static convert(category = {}, filterFormLineEntities = []) {
		return Object.assign(
			{
				[UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: category.id
			},
			FilterFormLinesToMySavedSearchConvertor.convertMultiOptionFormLines(filterFormLineEntities),
			FilterFormLinesToMySavedSearchConvertor.convertRadioFormLines(filterFormLineEntities),
			FilterFormLinesToMySavedSearchConvertor.convertItemAgeFormLine(filterFormLineEntities),
			FilterFormLinesToMySavedSearchConvertor.convertBrandModelsFormLine(filterFormLineEntities),
			FilterFormLinesToMySavedSearchConvertor.convertRegionDistrictsFormLine(
				filterFormLineEntities
			),
			FilterFormLinesToMySavedSearchConvertor.convertPhraseFormLine(filterFormLineEntities)
		)
	}

	static convertMultiOptionFormLines(filterFormLineEntities = []) {
		return filterFormLineEntities
			.filter((formLineEntity) => FilterConstants.multiOptionFilters.includes(formLineEntity.id))
			.reduce((result, formLineEntity) => {
				const { id, value = new Map(), options = [] } = formLineEntity

				return Object.assign(
					{},
					result,
					value.size > 0
						? {
								[id]: Array.from(value.keys()).map((optionValue) => {
									const option = options.find((o) => o.value === optionValue) || {}

									return {
										value: optionValue,
										name: option.name,
										seo_name: option.seoName
									}
								})
						  }
						: undefined
				)
			}, {})
	}

	static convertRadioFormLines(filterFormLineEntities = []) {
		return filterFormLineEntities.reduce((result, formLineEntity) => {
			const { id, value = {} } = formLineEntity
			const radioFilter = radioFilters.find(({ formLineId }) => formLineId === id)

			if (radioFilter) {
				const { apiParamFromName, apiParamToName } = radioFilter
				const { valueFrom, valueTo } = value

				return Object.assign(
					{},
					result,
					!isNullOrUndefined(valueFrom) && {
						[apiParamFromName]: valueFrom
					},
					!isNullOrUndefined(valueTo) && {
						[apiParamToName]: valueTo
					}
				)
			} else {
				return result
			}
		}, {})
	}

	static convertItemAgeFormLine(filterFormLineEntities = []) {
		const itemAgeFormLineEntity = filterFormLineEntities.find(
			({ id }) => id === FilterConstants.formLineIds.ITEM_AGE
		)

		if (itemAgeFormLineEntity) {
			const { id, value: { value } = {}, options = [] } = itemAgeFormLineEntity

			const option = options.find((option) => option.value === value)

			if (option) {
				return {
					[id]: Object.assign(
						{
							value: option.value,
							name: option.name,
							seo_name: option.seoName
						},
						!isNullOrUndefined(option.valueFrom) && {
							value_from: option.valueFrom
						},
						!isNullOrUndefined(option.valueTo) && {
							value_to: option.valueTo
						}
					)
				}
			}
		}

		return {}
	}

	static convertBrandModelsFormLine(filterFormLineEntities = []) {
		const brandModelFormLineEntity = filterFormLineEntities.find(
			({ id }) => id === FilterConstants.formLineIds.BRAND_MODEL
		)

		if (brandModelFormLineEntity) {
			const { value = new Map(), options = [] } = brandModelFormLineEntity
			const { URL_API_PARAMS } = AdvertListUrlConvertor.constants

			return value.size > 0
				? {
						[URL_API_PARAMS.BRANDS_MODELS_VALUES]: Array.from(value.keys()).map((brandValue) => {
							const modelValues = value.get(brandValue) || new Set()
							const brand = options.find((brand) => brand.value === brandValue)

							if (brand) {
								return {
									value: brand.value,
									name: brand.name,
									seo_name: brand.seoName,
									models: (brand.children || [])
										.filter((model) => modelValues.has(model.value))
										.map((model) => ({
											value: model.value,
											name: model.name,
											seo_name: model.seoName
										}))
								}
							}
						})
				  }
				: {}
		}

		return {}
	}

	static convertRegionDistrictsFormLine(filterFormLineEntities = []) {
		const regionDistrictsFormLineEntity = filterFormLineEntities.find(
			({ id }) => id === FilterConstants.formLineIds.REGION_DISTRICT
		)

		if (regionDistrictsFormLineEntity) {
			const { value = new Map(), options = [] } = regionDistrictsFormLineEntity
			const { URL_API_PARAMS } = AdvertListUrlConvertor.constants

			return value.size > 0
				? {
						[URL_API_PARAMS.REGIONS_DISTRICTS_VALUES]: Array.from(value.keys()).map(
							(regionValue) => {
								const districtValues = value.get(regionValue) || new Set()
								const region = options.find((region) => region.value === regionValue)

								if (region) {
									return {
										value: region.value,
										name: region.name,
										seo_name: region.seoName,
										districts: (region.children || [])
											.filter((district) => districtValues.has(district.value))
											.map((district) => ({
												value: district.value,
												name: district.name,
												seo_name: district.seoName
											}))
									}
								}
							}
						)
				  }
				: {}
		}

		return {}
	}

	static convertPhraseFormLine(filterFormLineEntities = []) {
		const phraseFormLineEntity = filterFormLineEntities.find(
			({ id }) => id === FilterConstants.formLineIds.PHRASE
		)

		if (phraseFormLineEntity) {
			const { value } = phraseFormLineEntity
			const { URL_API_PARAMS } = AdvertListUrlConvertor.constants

			return value
				? {
						[URL_API_PARAMS.PHRASE]: value
				  }
				: {}
		}

		return {}
	}
}
