import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Icon, InputSurface } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import { useLocalize } from 'app/base/componentHelpers'
import Split from '../../split/Split'
import BasicSelectWidget from '../basicSelect/BasicSelectWidget'
import { CLOSE_OUTLINE_16 } from '@sznds/icons'

import './MonthYearSelect.less'
import './MonthYearSelectBaseCS.json'

const CLASSNAME = 'c-month-year-select-base'

export const MONTH_AND_YEAR_SELECT_WIDGET_ID = 'month_and_year_select_widget'

export const getCustomMonthFormLineId = (id) => `${id}-month`
export const getCustomYearFormLineId = (id) => `${id}-year`

const MonthYearSelect = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		onChange = DEFAULT_PROPS.FUNCTION,
		selectId
	} = props

	const localize = useLocalize()

	const [isYearFocused, setIsYearFocused] = useState(false)

	const onMonthChange = (change) => {
		const { value: newMonth = {} } = change
		const { id, value: [, currentYear] = [] } = formLineEntity

		onChange(
			Object.assign(
				{},
				change,
				{ id },
				{
					value: newMonth.value >= 0 || currentYear > 0 ? [newMonth, currentYear] : undefined,
					errorMessage: undefined
				}
			)
		)
	}

	const onYearChange = (year) => {
		const { id, value: [currentMonth = {}] = [] } = formLineEntity

		onChange({
			id,
			value: currentMonth.value >= 0 || year > 0 ? [currentMonth, year] : undefined,
			errorMessage: undefined
		})
	}

	const onYearClear = () => {
		const { id, value: [currentMonth = {}] = [] } = formLineEntity

		onChange({
			id,
			value: currentMonth.value >= 0 ? [currentMonth, undefined] : undefined,
			errorMessage: undefined
		})
	}

	const { id, value: [currentMonthValue, currentYear] = [] } = formLineEntity

	return (
		<Split
			className={CLASSNAME}
			renderLeft={() => (
				<BasicSelectWidget
					inputId={selectId}
					formLineEntity={FormLines.createEntity(
						Object.assign({}, formLineEntity, {
							id: getCustomMonthFormLineId(id),
							placeholder: localize('MonthYearSelectBase.monthPlaceholder'),
							value: currentMonthValue,
							errorMessage: undefined,
							extra: Object.assign({}, formLineEntity.extra, {
								cebiaError: undefined
							})
						})
					)}
					onChange={onMonthChange}
				/>
			)}
			renderRight={() => {
				const { disabled, extra: { cebiaError } = {} } = formLineEntity

				return (
					<InputSurface
						tagName='div'
						className={classnames({
							[`${CLASSNAME}__year-wrap`]: true,
							['sds-input']: true,
							['sds-input--disabled']: disabled,
							['sds-inputsurface--error']: !!formLineEntity.errorMessage
						})}
						focused={isYearFocused}
						error={!!formLineEntity.errorMessage}
					>
						<NumberFormat
							id={getCustomYearFormLineId(id)}
							className={classnames({
								['c-basic-widget__cebia-warn']: !!cebiaError
							})}
							tagName='input'
							type='tel'
							format='####'
							value={currentYear || ''}
							allowEmptyFormatting={false}
							allowNegative={false}
							onChange={(e) => onYearChange(e.target.value)}
							error={!!formLineEntity.errorMessage}
							placeholder={localize('MonthYearSelectBase.yearPlaceholder')}
							disabled={disabled}
							onFocus={() => {
								setIsYearFocused(true)
							}}
							onBlur={() => {
								setIsYearFocused(false)
							}}
						/>
						{!disabled && !!currentYear && (
							<button
								type='button'
								onMouseDown={(e) => {
									e.preventDefault()
								}}
								className={`${CLASSNAME}__year-button`}
								onClick={() => onYearClear()}
							>
								<Icon
									className={classnames({
										[`${CLASSNAME}__icon`]: true,
										[`${CLASSNAME}__icon-clear`]: true
									})}
									symbol={CLOSE_OUTLINE_16}
								/>
							</button>
						)}
					</InputSurface>
				)
			}}
		/>
	)
}

MonthYearSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	selectId: PropTypes.string
}

export default React.memo(MonthYearSelect)
