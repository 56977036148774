import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Tag } from '@inzeraty/components'

export default class ItemAgeTag extends AbstractPureComponent {
	static get propTypes() {
		return {
			id: PropTypes.string,
			text: PropTypes.string,
			onOpen: PropTypes.func,
			deleteTag: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			text: '',
			onOpen: DEFAULT_PROPS.FUNCTION,
			deleteTag: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._openFilter = this._openFilter.bind(this)
		this._deleteTag = this._deleteTag.bind(this)
	}

	render() {
		const { text } = this.props

		return (
			<Tag
				size='small'
				onClick={this._deleteTag}
				data-dot='delete'
				data-dot-data={`{"value": "${text}"}`}
			>
				{text}
			</Tag>
		)
	}

	_openFilter() {
		const { onOpen } = this.props

		onOpen()
	}

	_deleteTag() {
		const { deleteTag } = this.props

		deleteTag({})
	}
}
