
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PhotosUploader'] = {
  inputPhotos: function(d) { return "Sem nahrajte nebo přetáhněte fotografie"; },
  openerText: function(d) { return "Vybrat fotografie"; },
  addNext: function(d) { return "Přidat další"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	