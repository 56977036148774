import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import BaseSelectWidget, {
	CONDITION_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/vehicleCondition/VehicleConditionWidget'
import { CLASSNAME as IA_CLASSNAME } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import BaseWidget from '../baseWidget/BaseWidget'

export { CONDITION_WIDGET_ID }

const ConditionWidget = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT } = props

	const { id } = formLineEntity

	const inputId = `${id}-input`

	return (
		<BaseWidget formLineEntity={formLineEntity} inputId={inputId}>
			<div className={`${IA_CLASSNAME}__input`}>
				<BaseSelectWidget {...props} inputId={inputId} />
			</div>
		</BaseWidget>
	)
}

ConditionWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	renderSelectedOption: PropTypes.func
}

export default React.memo(ConditionWidget)
