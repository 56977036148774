import React, { memo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './StateMark.less'

export const TYPE = {
	OK: 'ok',
	WARN: 'warn',
	ERROR: 'error'
}

const CLASSNAME = 'c-state-mark'

const StateMark = memo(({ children, type }) => {
	return (
		<span className={CLASSNAME}>
			<span
				className={classnames({
					[`${CLASSNAME}__dot`]: true,
					[`${CLASSNAME}__dot--${type}`]: !!type
				})}
			/>
			{children}
		</span>
	)
})

StateMark.propTypes = {
	children: PropTypes.node,
	type: PropTypes.string
}

export default StateMark
