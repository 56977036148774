import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { TRASH_FILLED, REFRESH_OUTLINE, VISIBILITY_OUTLINE } from '@sznds/icons'
import { Button } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import { PaginationEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import { FEEDBACK_TYPE, FEEDBACK_SUBJECT } from 'app/model/feedback/FeedbackConstants'
import FeedbackEntity from 'app/model/feedback/FeedbackEntity'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import IMAGES from 'app/base/ImagesConstants'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import ActionButton from 'app/component/actionButton/ActionButton'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import EntityForm from 'app/component/entityForm/EntityForm'
import EntityFormPopup from '../components/entityFormPopup/EntityFormPopup'
import IAFeedbackListController from './IAFeedbackListController'

import 'app/component/feedback/PopupFeedbackFormCS.json'
import './IAFeedbackListView.less'

const CLASSNAME = 'ia-feedback-list'

const { ACTIONS } = IAFeedbackListController

const getMailTo = (email) =>
	`mailto:${email}?subject=${encodeURIComponent('Odpověď na zpětnou vazbu sauto')}`

export default function IAFeedbackListView(props) {
	const {
		entitiesAndPagination,
		filterFormLineEntities,
		isFilterLoading,
		isFeedbackDetailPopupOpened,
		feedbackEntityDetail,
		isLoading
	} = props

	const fire = useFire()
	const { rights = [] } = useContext(AdminEntityContext) || {}

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		let actions = [
			(entity, actionButtonProps = {}) => (
				<ActionButton
					icon={VISIBILITY_OUTLINE}
					key='detail'
					title='Zobrazit detail'
					onClick={() =>
						fire('action', {
							action: ACTIONS.OPEN_FEEDBACK_DETAIL_POPUP,
							entities: [entity]
						})
					}
					{...actionButtonProps}
				/>
			),
			(entity = {}, actionButtonProps = {}) => (
				<ActionButton
					icon={REFRESH_OUTLINE}
					key='reply'
					title='Odpovědět'
					href={getMailTo(entity.email)}
					{...actionButtonProps}
				/>
			)
		]

		if (rights.includes(ADMIN_RIGHTS.SERVICE_FEEDBACK_DELETE)) {
			actions.push((entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='delete'
					title='Smazat zpětnou vazbu'
					onClick={() => action(ACTIONS.DELETE, [entity])}
					{...actionButtonProps}
				/>
			))
		}

		return actions
	}

	return (
		<Layout title='Zpětná vazba'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHead key='username'>Uživatel</TableHead>,
					<TableHead key='emoji'>Hodnocení</TableHead>,
					<TableHead key='subject'>Předmět</TableHead>,
					<TableHeadSorter key='date' sortParam='create_date' isDefaultDescending={true}>
						Datum
					</TableHeadSorter>,
					<TableHead key='text'>Vlastní text</TableHead>
				]}
				rowCells={[
					({ email }) => email,
					({ feedbackType }) => <FeedbackType type={feedbackType} />,
					({ feedbackSubject }) => <FeedbackSubject subject={feedbackSubject} />,
					({ createDate }) => Format.date(createDate),
					({ hasText }) => (hasText ? 'Ano' : 'Ne')
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>

			{isFeedbackDetailPopupOpened && (
				<EntityFormPopup
					title='Zpětná vazba'
					onClose={() =>
						fire('action', {
							action: ACTIONS.CLOSE_FEEDBACK_DETAIL_POPUP
						})
					}
				>
					<FeedbackDetail feedbackEntity={feedbackEntityDetail} />
				</EntityFormPopup>
			)}
		</Layout>
	)
}

IAFeedbackListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(FeedbackEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isFeedbackDetailPopupOpened: PropTypes.bool,
	feedbackEntityDetail: PropTypes.instanceOf(FeedbackEntity),
	isLoading: PropTypes.bool
}

const FeedbackDetailRow = ({ label, children }) => (
	<tr>
		<th className={`${CLASSNAME}__detail-label`}>{label}:</th>
		<td>{children}</td>
	</tr>
)

FeedbackDetailRow.propTypes = {
	label: PropTypes.string.isRequired,
	children: PropTypes.node
}

const FeedbackDetail = ({ feedbackEntity = DEFAULT_PROPS.OBJECT }) => {
	const { email, url, feedbackType, feedbackSubject, feedbackText, userAgent } = feedbackEntity

	return (
		<EntityForm
			onSubmit={() => {}}
			renderFormContent={() => (
				<table>
					<tbody>
						<FeedbackDetailRow label='Uživatel'>{email}</FeedbackDetailRow>
						<FeedbackDetailRow label='Hodnocení'>
							<FeedbackType type={feedbackType} />
						</FeedbackDetailRow>
						{feedbackSubject && (
							<FeedbackDetailRow label='Předmět'>
								<FeedbackSubject subject={feedbackSubject} />
							</FeedbackDetailRow>
						)}
						<FeedbackDetailRow label='User agent'>{userAgent}</FeedbackDetailRow>
						<FeedbackDetailRow label='URL'>
							<a href={url}>{url}</a>
						</FeedbackDetailRow>
						{feedbackText && <FeedbackDetailRow label='Text'>{feedbackText}</FeedbackDetailRow>}
					</tbody>
				</table>
			)}
			renderButton={() => (
				<Button
					primary={true}
					size='small'
					text='Odpovědět na zpětnou vazbu'
					href={getMailTo(email)}
				/>
			)}
		/>
	)
}

FeedbackDetail.propTypes = {
	feedbackEntity: PropTypes.instanceOf(FeedbackEntity).isRequired
}

const FeedbackType = ({ type }) => {
	const localize = useLocalize()

	const ICONS = {
		[FEEDBACK_TYPE.EXCELLENT]: IMAGES['image__emoji-excellent'],
		[FEEDBACK_TYPE.GOOD]: IMAGES['image__emoji-good'],
		[FEEDBACK_TYPE.NEUTRAL]: IMAGES['image__emoji-neutral'],
		[FEEDBACK_TYPE.BAD]: IMAGES['image__emoji-bad'],
		[FEEDBACK_TYPE.TERRIBLE]: IMAGES['image__emoji-terrible']
	}

	const TEXTS = {
		[FEEDBACK_TYPE.EXCELLENT]: localize('PopupFeedbackForm.excellent'),
		[FEEDBACK_TYPE.GOOD]: localize('PopupFeedbackForm.good'),
		[FEEDBACK_TYPE.NEUTRAL]: localize('PopupFeedbackForm.neutral'),
		[FEEDBACK_TYPE.BAD]: localize('PopupFeedbackForm.bad'),
		[FEEDBACK_TYPE.TERRIBLE]: localize('PopupFeedbackForm.terrible')
	}

	const srcEmoji = ICONS[type] || ''
	const text = TEXTS[type] || ''

	return (
		<span className={`${CLASSNAME}__feedback-type`}>
			<img className={`${CLASSNAME}__emoji-img`} src={srcEmoji} alt='' />
			{text}
		</span>
	)
}

FeedbackType.propTypes = {
	type: PropTypes.string.isRequired
}

const FeedbackSubject = ({ subject }) => {
	const localize = useLocalize()

	const TEXTS = {
		[FEEDBACK_SUBJECT.ADVERT_INFO]: localize('PopupFeedbackForm.feedbackSubjectAdvertInfo'),
		[FEEDBACK_SUBJECT.ADVERT_SEARCH_AND_FILTERING]: localize(
			'PopupFeedbackForm.feedbackSubjectAdvertSearchAndFiltering'
		),
		[FEEDBACK_SUBJECT.IRRELEVANT_SEARCH_RESULTS]: localize(
			'PopupFeedbackForm.feedbackSubjectIrrelevantSearchResults'
		),
		[FEEDBACK_SUBJECT.WEB_CLARITY]: localize('PopupFeedbackForm.feedbackSubjectWebClarity'),
		[FEEDBACK_SUBJECT.TECHNICAL_ISSUES]: localize(
			'PopupFeedbackForm.feedbackSubjectTechnicalIssues'
		),
		[FEEDBACK_SUBJECT.OTHER]: localize('PopupFeedbackForm.feedbackSubjectOther')
	}

	const text = TEXTS[subject] || ''

	return <span>{text}</span>
}

FeedbackSubject.propTypes = {
	subject: PropTypes.string
}
