import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Format } from '@inzeraty/helpers'
import Context from 'ima/page/context'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import ClientStatisticsUrlConvertor from '../../ClientStatisticsUrlConvertor'
import percentageGrowthCalc from '../KPIMetrics/PercentageGrowthCalc'
import MetricsDataHelper from '../MetricsDataHelper'

import './ClientStatisticsTable.less'
import './ClientStatisticsTableCS.json'
import '../../ClientStatisticsCS.json'

const CLASSNAME = 'c-client-stat-table'

// nahradi nezalomitelnou mezeru s obycejnou mezerou
const formatNumber = (num) => Format.number(num).replace(/\xa0/g, ' ')

const ClientStatisticsTable = (props) => {
	const {
		isLoading,
		isOperatingLease = false,
		isMirroring = false,
		isComparisonMode = false,
		advertStatistics = DEFAULT_PROPS.OBJECT,
		advertStatisticsCompare = DEFAULT_PROPS.OBJECT
	} = props

	const localize = useLocalize()
	const context = useContext(Context)
	const router = context.$Router

	const separator = ', '

	const isSales = !isOperatingLease && !isMirroring

	const advertStatisticsCompareDictionary = (() => {
		const { entities = [] } = advertStatisticsCompare

		return entities.reduce((acc, entity) => {
			const { groupBy: { id: advertId } = {} } = entity

			acc[advertId] = entity

			return acc
		}, {})
	})()

	const renderPagingSummary = ({ rangeFrom, rangeTo, total }) => {
		return (
			<div className={`${CLASSNAME}__paging-summary`}>
				{`${Format.number(rangeFrom)}–${Format.number(rangeTo)}`}
				{total &&
					` ${localize('ClientStatistics.pagingSummary', {
						TOTAL: total,
						TOTAL_FORMATTED: Format.number(total)
					})}`}
			</div>
		)
	}

	const renderFoot = ({ renderPaging }) => {
		const getPaginationUrl = (page) => {
			const { params = {} } = ClientStatisticsUrlConvertor.getRouteInfo(router)
			const updatedParams = Object.assign({}, params)

			if (page && page > 1) {
				updatedParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE] = page
			} else {
				delete updatedParams[ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE]
			}

			return ClientStatisticsUrlConvertor.getUrl(router, updatedParams)
		}

		return <div className={`${CLASSNAME}__foot`}>{renderPaging(getPaginationUrl)}</div>
	}

	const renderComparisonRow = ({ rowId, entityData }) => {
		const { groupBy: { id: advertId } = {} } = entityData

		const entityDataToCompare = advertStatisticsCompareDictionary[advertId] || {}

		const getColumns = ({ metrics = {} } = {}) => [
			MetricsDataHelper.getListViewMetric(isOperatingLease, isMirroring, metrics),
			MetricsDataHelper.getDetailViewMetric(isOperatingLease, isMirroring, metrics),
			MetricsDataHelper.getDetailViewFromListMetric(isOperatingLease, isMirroring, metrics),
			MetricsDataHelper.getDetailViewFromOtherMetric(isOperatingLease, isMirroring, metrics),
			isSales ? metrics.POView : 0,
			isSales ? metrics.detailViewFromPO : 0,
			MetricsDataHelper.getReplySentMetric(isOperatingLease, isMirroring, metrics),
			MetricsDataHelper.getPhoneViewMetric(isOperatingLease, isMirroring, metrics)
		]

		const renderAdvert = () => {
			const { groupBy: { id } = {}, customIds = [], vins = [] } = entityData
			const columns = getColumns(entityData)

			const renderCell = (content) => (
				<td
					className={classnames({
						[`${CLASSNAME}__cell`]: true,
						[`${CLASSNAME}__cell--top`]: true
					})}
				>
					<div className={`${CLASSNAME}__advert-cell`}>{content}</div>
				</td>
			)

			const renderExtendedCell = (content) => (
				<td
					className={classnames({
						[`${CLASSNAME}__cell`]: true,
						[`${CLASSNAME}__cell--top`]: true,
						[`${CLASSNAME}__cell--bottom`]: true
					})}
					rowSpan='4'
				>
					<div className={`${CLASSNAME}__advert-cell`}>
						<span>{content}</span>
					</div>
				</td>
			)

			return (
				<tr key={`${rowId}-advert`}>
					{renderCell(id)}
					{renderExtendedCell(vins.join(separator))}
					{renderExtendedCell(customIds.join(separator))}
					{columns.map((_, idx) => (
						<td
							key={idx}
							className={classnames({
								[`${CLASSNAME}__cell`]: true,
								[`${CLASSNAME}__cell--top`]: true
							})}
						></td>
					))}
				</tr>
			)
		}

		const _renderStatsForTimeSpan = (key, label, columns) => (
			<tr key={key}>
				<td
					className={classnames({
						[`${CLASSNAME}__cell`]: true,
						[`${CLASSNAME}__time-span-cell`]: true
					})}
				>
					{label}:
				</td>
				{columns.map((number, idx) => (
					<td
						key={idx}
						className={classnames({
							[`${CLASSNAME}__cell`]: true,
							[`${CLASSNAME}__cell--number`]: true
						})}
					>
						{formatNumber(number || 0)}
					</td>
				))}
			</tr>
		)

		const renderStats = () =>
			_renderStatsForTimeSpan(
				`${rowId}-stats`,
				localize('ClientStatisticsTable.timeSpan1'),
				getColumns(entityData)
			)

		const renderComparisonStats = () =>
			_renderStatsForTimeSpan(
				`${rowId}-stats-comp`,
				localize('ClientStatisticsTable.timeSpan2'),
				getColumns(entityDataToCompare)
			)

		const renderPercentageGrowth = () => {
			const columns = getColumns(entityData)
			const columnsToCompare = getColumns(entityDataToCompare)

			const formatPercentage = (percentage) => {
				if (percentage >= 0) {
					return `+${formatNumber(percentage)}%`
				} else if (percentage < 0) {
					return `${formatNumber(percentage)}%`
				}
			}

			return (
				<tr key={`${rowId}-diff`}>
					<td
						className={classnames({
							[`${CLASSNAME}__cell`]: true,
							[`${CLASSNAME}__cell--bottom`]: true,
							[`${CLASSNAME}__diff-cell`]: true
						})}
					>
						{localize('ClientStatisticsTable.diff')}:
					</td>
					{columns.map((number = 0, idx) => {
						const numberToCompare = columnsToCompare[idx] || 0

						return (
							<td
								key={idx}
								className={classnames({
									[`${CLASSNAME}__cell`]: true,
									[`${CLASSNAME}__cell--bottom`]: true,
									[`${CLASSNAME}__cell--number`]: true,
									[`${CLASSNAME}__cell--percentage`]: true
								})}
							>
								{formatPercentage(percentageGrowthCalc(numberToCompare, number))}
							</td>
						)
					})}
				</tr>
			)
		}

		return (
			<tbody key={rowId} className={`${CLASSNAME}__row`}>
				{renderAdvert()}
				{renderStats()}
				{renderComparisonStats()}
				{renderPercentageGrowth()}
			</tbody>
		)
	}

	const renderComparisonBody = (renderedBodyContent) => renderedBodyContent

	const normalModeProps = {
		rowCells: [
			({ groupBy: { id } = {} }) => id,
			({ vins = [] }) => <span>{vins.join(separator)}</span>,
			({ customIds = [] }) => <span>{customIds.join(separator)}</span>,
			({ metrics = {} }) => [
				Format.number(
					MetricsDataHelper.getListViewMetric(isOperatingLease, isMirroring, metrics) || 0
				),
				{ isNumber: true }
			],
			({ metrics = {} }) => [
				Format.number(
					MetricsDataHelper.getDetailViewMetric(isOperatingLease, isMirroring, metrics) || 0
				),
				{ isNumber: true }
			],
			({ metrics = {} }) => [
				Format.number(
					MetricsDataHelper.getDetailViewFromListMetric(isOperatingLease, isMirroring, metrics) || 0
				),
				{ isNumber: true }
			],
			({ metrics = {} }) => [
				Format.number(
					MetricsDataHelper.getDetailViewFromOtherMetric(isOperatingLease, isMirroring, metrics) ||
						0
				),
				{ isNumber: true }
			],
			({ metrics = {} }) => [isSales ? Format.number(metrics.POView || 0) : 0, { isNumber: true }],
			({ metrics = {} }) => [
				isSales ? Format.number(metrics.detailViewFromPO || 0) : 0,
				{ isNumber: true }
			],
			({ metrics = {} }) => [
				Format.number(
					MetricsDataHelper.getReplySentMetric(isOperatingLease, isMirroring, metrics) || 0
				),
				{ isNumber: true }
			],
			({ metrics = {} }) => [
				Format.number(
					MetricsDataHelper.getPhoneViewMetric(isOperatingLease, isMirroring, metrics) || 0
				),
				{ isNumber: true }
			]
		].filter((row) => !!row)
	}

	const compareModeProps = {
		renderBody: renderComparisonBody,
		renderRow: renderComparisonRow
	}

	const additionalProps = isComparisonMode ? compareModeProps : normalModeProps

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}--comparison`]: isComparisonMode
			})}
		>
			<EntitiesTable
				entitiesAndPagination={advertStatistics}
				entityIdGetter={({ groupBy: { id } = {} }) => id}
				rowsWithStripes={true}
				isLoading={isLoading}
				renderPagingSummary={renderPagingSummary}
				headCells={[
					<TableHead key='advert_id'>{localize('ClientStatistics.advertId')}</TableHead>,
					<TableHead key='vin'>{localize('ClientStatistics.vin')}</TableHead>,
					<TableHead key='custom_id'>{localize('ClientStatistics.customId')}</TableHead>,
					<TableHeadSorter
						key='list_view'
						sortParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT}
						sortParam={
							isOperatingLease
								? 'list_view_operating_lease'
								: isMirroring
								? 'list_view_mirroring'
								: 'list_view'
						}
						pageParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE}
						alignRight={true}
					>
						{localize('ClientStatistics.listView')}
					</TableHeadSorter>,
					<TableHeadSorter
						key='detail_view'
						sortParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT}
						sortParam={
							isOperatingLease
								? 'detail_view_operating_lease'
								: isMirroring
								? 'detail_view_mirroring'
								: 'detail_view'
						}
						pageParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE}
						alignRight={true}
					>
						{localize('ClientStatistics.detailView')}
					</TableHeadSorter>,
					<TableHeadSorter
						key='detail_view_from_list'
						sortParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT}
						sortParam={
							isOperatingLease
								? 'detail_view_from_list_operating_lease'
								: isMirroring
								? 'detail_view_from_list_mirroring'
								: 'detail_view_from_list'
						}
						pageParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE}
						alignRight={true}
					>
						{localize('ClientStatistics.detailViewFromList')}
					</TableHeadSorter>,
					<TableHeadSorter
						key='detail_view_from_other'
						sortParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT}
						sortParam={
							isOperatingLease
								? 'detail_view_from_other_operating_lease'
								: isMirroring
								? 'detail_view_from_other_mirroring'
								: 'detail_view_from_other'
						}
						pageParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE}
						alignRight={true}
					>
						{localize('ClientStatistics.detailViewFromOther')}
					</TableHeadSorter>,
					<TableHeadSorter
						key='po_view'
						sortParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT}
						sortParam='po_view'
						pageParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE}
						alignRight={true}
					>
						{localize('ClientStatistics.POView')}
					</TableHeadSorter>,
					<TableHeadSorter
						key='detail_view_from_po'
						sortParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT}
						sortParam='detail_view_from_po'
						pageParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE}
						alignRight={true}
					>
						{localize('ClientStatistics.detailViewFromPO')}
					</TableHeadSorter>,
					<TableHeadSorter
						key='reply_sent'
						sortParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT}
						sortParam={
							isOperatingLease
								? 'reply_sent_operating_lease'
								: isMirroring
								? 'reply_sent_mirroring'
								: 'reply_sent'
						}
						pageParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE}
						alignRight={true}
					>
						{localize('ClientStatistics.replySent')}
					</TableHeadSorter>,
					<TableHeadSorter
						key='phone_view'
						sortParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.SORT}
						sortParam={
							isOperatingLease
								? 'phone_view_operating_lease'
								: isMirroring
								? 'phone_view_mirroring'
								: 'phone_view'
						}
						pageParamName={ClientStatisticsUrlConvertor.constants.URL_APP_PARAMS.PAGE}
						alignRight={true}
					>
						{localize('ClientStatistics.phoneView')}
					</TableHeadSorter>
				].filter((head) => !!head)}
				renderFoot={renderFoot}
				isNoHorizontalScrollOnDesktopPreferred={false}
				{...additionalProps}
			/>
		</div>
	)
}

ClientStatisticsTable.propTypes = {
	isLoading: PropTypes.bool,
	isOperatingLease: PropTypes.bool,
	isMirroring: PropTypes.bool,
	isComparisonMode: PropTypes.bool,
	advertStatistics: PropTypes.object,
	advertStatisticsCompare: PropTypes.object
}

export default React.memo(ClientStatisticsTable)
