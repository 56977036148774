import AbstractNewAdvertBaseController from 'app/page/userweb/newAdvert/AbstractNewAdvertBaseController'
import STATE_KEYS from './ToppedPaymentStateKeys'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import ROUTE_NAMES from 'app/base/RouteNames'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import ToastHelper from 'app/component/toastMessages/ToastHelper'
import { StatusMessage } from '@inzeraty/components'
import { HttpErrorHelper } from '@inzeraty/helpers'
import UserwebBaseController from 'app/base/UserwebBaseController'
import * as Sentry from '@sentry/browser'
import PaymentController from 'app/page/userweb/newAdvert/payment/PaymentController'

import 'app/base/ActionMessageCS.json'

class ToppedPaymentController extends AbstractNewAdvertBaseController {
	constructor(dependenciesHelper, paymentService, advertService) {
		super(dependenciesHelper)
		this._paymentService = paymentService
		this._advertService = advertService

		this._setPaymentType = this._setPaymentType.bind(this)
		this._setTermsAndConditions = this._setTermsAndConditions.bind(this)
		this._refreshAvailablePayments = this._refreshAvailablePayments.bind(this)

		this._handleCreditCardPayment = this._handleCreditCardPayment.bind(this)
		this._processCreditCardPayment = this._processCreditCardPayment.bind(this)
		this._processCreditCardPaymentError = this._processCreditCardPaymentError.bind(this)

		this._processCreditCardConfirmation = this._processCreditCardConfirmation.bind(this)
		this._processConfirmationError = this._processConfirmationError.bind(this)

		this._handleWalletPayment = this._handleWalletPayment.bind(this)
		this._processWalletPaymentsError = this._processWalletPaymentsError.bind(this)
		this._processWalletPayments = this._processWalletPayments.bind(this)
	}

	load() {
		const superState = super.load()

		return Object.assign({}, superState, {
			[STATE_KEYS.PAYMENTS]: this._paymentService
				.getAvailablePayments(this._getAdvertId(), true)
				.catch(() => []),
			[STATE_KEYS.SET_PAYMENT_TYPE]: this._setPaymentType,
			[STATE_KEYS.PAYMENT_TYPE]: PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_CREDIT_CARD,
			[STATE_KEYS.SET_TERMS_AND_CONDITIONS]: this._setTermsAndConditions,
			[STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED]: false,
			[STATE_KEYS.ERROR_MESSAGE]: undefined,
			[STATE_KEYS.LOADING]: this._hasPaymentId(),
			[STATE_KEYS.ADVERT_ENTITY]: this._getAdvertEntity(),
			[STATE_KEYS.REFRESH_PAYMENTS]: this._refreshAvailablePayments,
			[STATE_KEYS.HANDLE_CREDIT_CARD_PAYMENT]: this._handleCreditCardPayment,
			[STATE_KEYS.HANDLE_WALLET_PAYMENT]: this._handleWalletPayment
		})
	}

	activate() {
		super.activate()
		if (this._hasPaymentId()) {
			this._confirmCreditCardPayment()
		}
	}

	_setTermsAndConditions(checked) {
		this.setState({
			[STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED]: checked
		})
	}

	_setPaymentType(type) {
		this.setState({
			[STATE_KEYS.PAYMENT_TYPE]: type,
			[STATE_KEYS.ERROR_MESSAGE]: undefined
		})
	}

	_refreshAvailablePayments() {
		this._paymentService
			.getAvailablePayments(this._getAdvertId(), true)
			.then((payments) => {
				this.setState({
					[STATE_KEYS.PAYMENTS]: payments
				})
			})
			.catch(() => {})
	}

	_hasPaymentId() {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const routeParamsKeys = Object.keys(this.getRouteParams())

		return routeParamsKeys.indexOf(URL_APP_PARAMS.PAYMENT_ID) > -1
	}

	async _confirmCreditCardPayment() {
		const { [UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfPromise } = this.getState()

		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants

		const { [URL_APP_PARAMS.PAYMENT_ID]: paymentId } = this.getRouteParams()

		const user = await userSelfPromise

		try {
			Sentry.addBreadcrumb({
				category: 'payment',
				message: `Credit card payment confirmation for user ${user.id}`,
				level: Sentry.Severity.Info,
				data: {
					user: `${user.name} (${user.id})`,
					cookie: document.cookie
				}
			})

			const response = await this._paymentService.closePayment(this._getAdvertId(), paymentId)
			this._processCreditCardConfirmation(response)
		} catch (err) {
			Sentry.addBreadcrumb({
				category: 'payment',
				message: `Credit card payment confirmation failed for user ${user.id}`,
				level: Sentry.Severity.Error,
				data: {
					user: `${user.name} (${user.id})`,
					cookie: document.cookie
				}
			})
			Sentry.captureException(err)

			this._processConfirmationError(err)
		}
	}

	async _processConfirmationError(error) {
		PaymentController.processConfirmationError(
			error,
			() => this._processSuccessCallback(),
			() => this._setError()
		)
	}

	async _processCreditCardConfirmation(confirmationResponse) {
		PaymentController.processCreditCardConfirmation(
			confirmationResponse,
			() => this._processSuccessCallback(),
			() => this._setError(),
			() => this._redirectToPaymentConfirmation()
		)
	}

	async _redirectToPaymentConfirmation() {
		const {
			URL_APP_PARAMS: { ADVERT_ID, PAYMENT_ID }
		} = AdvertAdminUrlConvertor.constants

		const { [PAYMENT_ID]: paymentId } = this.getRouteParams()
		const router = this._router

		router.redirect(
			router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT_CONFIRMATION, {
				[ADVERT_ID]: this._getAdvertId(),
				[PAYMENT_ID]: paymentId
			})
		)
	}

	_handleCreditCardPayment() {
		const { [STATE_KEYS.PAYMENT_TYPE]: paymentType } = this.getState()

		const advertId = this._getAdvertId()

		const currentUrl = this._router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_TOPPED_PAYMENT, {
			[AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: advertId
		})

		this._paymentService
			.postPayment(advertId, {
				payment_type: paymentType,
				return_url: currentUrl,
				fail_url: currentUrl,
				payment_reason: PAYMENTS_CONSTANTS.PAYMENT_REASON.TOP
			})
			.then(this._processCreditCardPayment)
			.catch(this._processCreditCardPaymentError)

		this._setLoading()
	}

	_processCreditCardPayment(paymentResponse) {
		PaymentController.processCreditCardPayment(
			paymentResponse,
			(url) => this._router.redirect(url),
			(error) => this._setError(error)
		)
	}

	_processCreditCardPaymentError(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)

		if (httpStatus >= HTTP_STATUS_CODE.FORBIDDEN) {
			const { body: { errors = [] } = {} } = HttpErrorHelper.getParams(error)
			const [paymentError = {}] = errors
			const {
				ERROR: { CREDIT_CARD }
			} = PAYMENTS_CONSTANTS

			this._setError(CREDIT_CARD[paymentError.error_code])
		}
	}

	_handleWalletPayment() {
		const { [STATE_KEYS.PAYMENT_TYPE]: paymentType } = this.getState()
		const router = this._router

		const advertId = this._getAdvertId()

		const currentUrl = router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_TOPPED_PAYMENT, {
			[AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: advertId
		})

		const advertListUrl = router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST)

		this._paymentService
			.postPayment(advertId, {
				payment_type: paymentType,
				return_url: advertListUrl,
				fail_url: currentUrl,
				payment_reason: PAYMENTS_CONSTANTS.PAYMENT_REASON.TOP
			})
			.then(this._processWalletPayments)
			.catch(this._processWalletPaymentsError)

		this._setLoading()
	}

	_processWalletPaymentsError(error) {
		const { body: { errors = [] } = {} } = HttpErrorHelper.getParams(error)
		const [paymentError = {}] = errors
		const { error_code } = paymentError

		if (error_code === PAYMENTS_CONSTANTS.ERROR.WALLET.LOW_CREDIT) {
			this._setError(PAYMENTS_CONSTANTS.ERROR.WALLET.LOW_CREDIT)
		} else {
			this._setError()
		}
	}

	_processWalletPayments() {
		this._processSuccessCallback()
	}

	async _processSuccessCallback() {
		const dictionary = this._utils.$Dictionary
		const router = this._router

		const dictData = {
			COUNT: 1,
			IDS: this._getAdvertId()
		}

		const messageData = {
			type: StatusMessage.TYPE.SUCCESS,
			title: dictionary.get('ActionMessage.titleSuccess'),
			text: dictionary.get('ActionMessage.toppedSuccess', dictData)
		}

		ToastHelper.setToast(messageData)

		router.redirect(router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST))
	}

	_setError(error = PAYMENTS_CONSTANTS.ERROR.DEFAULT) {
		this.setState({
			[STATE_KEYS.LOADING]: false,
			[STATE_KEYS.ERROR_MESSAGE]: error
		})
	}

	_setLoading() {
		this.setState({
			[STATE_KEYS.LOADING]: true
		})
	}

	_getAdvertEntity() {
		return this._advertService.getAdvertDetail(Number(this._getAdvertId()))
	}

	_getAdvertId() {
		const {
			[AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: advertId
		} = this.getRouteParams()

		return advertId
	}
}

export default ToppedPaymentController
