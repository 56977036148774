export default class AdvertHistoryEntity {
	/**
	 * @constructor
	 * @param {Object} data
	 * @param {Number} data.id
	 * @param {String} data.ipAddress
	 * @param {Object} data.changes
	 * @param {String} data.changedBy
	 * @param {Date}   data.time
	 */
	constructor(data) {
		this.id = data.id
		this.ipAddress = data.ipAddress
		this.changes = data.changes
		this.changedBy = data.changedBy
		this.time = data.time
	}
}
