import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './HPTitle.less'

const CLASSNAME = 'c-hp-title'

const HPTitle = memo(({ tagName = 'h2', className = '', children }) => {
	const TagName = tagName

	return (
		<TagName
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			{children}
		</TagName>
	)
})

HPTitle.propTypes = {
	tagName: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default HPTitle
