import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './CountDown.less'

const CLASSNAME = 'c-count-down'

const CountDown = ({ className, value, limit }) => {
	if (limit) {
		const result = limit - (value?.length || 0)
		return (
			<div
				className={classnames({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{result >= 0 ? result : 0}/{limit}
			</div>
		)
	} else {
		return null
	}
}

CountDown.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	limit: PropTypes.number
}

CountDown.defaultProps = {
	className: '',
	value: '',
	limit: 0
}

export default CountDown
