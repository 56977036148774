
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['DetailInfo'] = {
  generalInformation: function(d) { return "Obecné informace"; },
  brand: function(d) { return "Značka"; },
  model: function(d) { return "Model"; },
  generation: function(d) { return "Generace"; },
  modification: function(d) { return "Modifikace"; },
  startProduction: function(d) { return "Zahájení produkce"; },
  bodyType: function(d) { return "Karoserie"; },
  seats: function(d) { return "Počet míst"; },
  doors: function(d) { return "Počet dveří"; },
  perfomaceInformation: function(d) { return "Jízdní vlastnosti"; },
  fuelConsumptionUrban: function(d) { return "Spotřeba paliva – ve městě"; },
  fuelConsumptionExtraUrban: function(d) { return "Spotřeba paliva – mimo město"; },
  fuelConsumptionCombined: function(d) { return "Spotřeba paliva – kombinovaná"; },
  maxSpeed: function(d) { return "Maximální rychlost"; },
  emission: function(d) { return "CO2 emise"; },
  emissionStandart: function(d) { return "Emisní standard"; },
  engineInformation: function(d) { return "Motor"; },
  power: function(d) { return "Výkon"; },
  torque: function(d) { return "Točivý moment"; },
  acceleration: function(d) { return "Zrychlení z 0 na 100 km/h"; },
  acceleration200: function(d) { return "Zrychlení z 0 na 200 km/h"; },
  trailerLoadBraked12perc: function(d) { return "Přípustné zatížení přívěsu s brzdami (12%)"; },
  trailerLoadUNBraked: function(d) { return "Povolené zatížení přívěsu bez brzd"; },
  gearboxATNumber: function(d) { return "Počet rychlostních stupňů (automatická převodovka)"; },
  gearboxMTNumber: function(d) { return "Počet rychlostních stupňů (manuální převodovka)"; },
  maxEngineSpeed: function(d) { return "Maximální otáčky motoru"; },
  engineCode: function(d) { return "Model motoru"; },
  engineVolume: function(d) { return "Zdvihový objem"; },
  fuelType: function(d) { return "Typ paliva"; },
  fuelSystem: function(d) { return "Palivový systém"; },
  positionCilinders: function(d) { return "Poloha válců"; },
  valvesPerCilinder: function(d) { return "Počet ventilů na válec"; },
  compressionRatio: function(d) { return "Kompresní poměr"; },
  stroke: function(d) { return "Délka zdvihu"; },
  bore: function(d) { return "Vývrt"; },
  turbine: function(d) { return "Turbo"; },
  valvetrain: function(d) { return "Ventilový rozvod"; },
  engineOilCapacity: function(d) { return "Objem motorového oleje"; },
  vehicleBodyInformation: function(d) { return "Karoserie"; },
  carLength: function(d) { return "Délka"; },
  carHeight: function(d) { return "Výška"; },
  carWidth: function(d) { return "Šířka vozu"; },
  wheelbase: function(d) { return "Rozvor"; },
  frontTrack: function(d) { return "Přední rozchod náprav"; },
  frontOverhang: function(d) { return "Přední převis"; },
  rearTrack: function(d) { return "Zadní rozchod náprav"; },
  rearOverhang: function(d) { return "Zadní převis"; },
  curbWeight: function(d) { return "Pohotovostní hmotnost vozidla"; },
  carMaxWeigh: function(d) { return "Maximální hmotnost"; },
  noseWeight: function(d) { return "Maximální zatížení tažného zařízení"; },
  roofLoad: function(d) { return "Maximální zatížení střechy"; },
  luggage: function(d) { return "Zavazadlový prostor"; },
  tankVolume: function(d) { return "Objem palivové nádrže"; },
  adblueTankVolume: function(d) { return "Objem nádrže na Adblue"; },
  rim: function(d) { return "Velikost ráfků kol"; },
  milimeters: function(d) { return "mm"; },
  liter: function(d) { return "l"; },
  speedPerMinute: function(d) { return "ot./min."; },
  second: function(d) { return "s"; },
  gramPerKg: function(d) { return "g/km"; },
  horses: function(d) { return "koní"; },
  powerSpeedPerMinuteUnit: function(d) { return ", při " + d.VALUE; },
  newtonMeter: function(d) { return "Nm"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	