import { SEARCH_TYPE_SESSION } from 'app/base/StorageIds'

const SEARCH_TYPE_SESSION_VALUES = ['fulltext', 'homepage', 'filters', 'unknown']

export const getSearchTypeSession = ($SessionStorage) => {
	try {
		const searchTypeSession = $SessionStorage.get(SEARCH_TYPE_SESSION)

		if (SEARCH_TYPE_SESSION_VALUES.includes(searchTypeSession?.searchType)) {
			return searchTypeSession
		} else {
			throw new Error()
		}
	} catch (e) {
		return { searchType: 'unknown' }
	}
}

export const getSearchTypeSessionOnAdvertDetail = ($SessionStorage) => {
	try {
		const { searchType } = getSearchTypeSession($SessionStorage)

		return { searchType }
	} catch (e) {
		return { searchType: 'unknown' }
	}
}
