import React from 'react'
import PropTypes from 'prop-types'

import './NewAdvertSectionHeading.less'

const CLASSNAME = 'c-new-advert-section-heading'

const NewAdvertSectionHeading = React.forwardRef(({ children }, ref) => (
	<h2 className={CLASSNAME} ref={ref}>
		{children}
	</h2>
))

NewAdvertSectionHeading.displayName = 'NewAdvertSectionHeading'

NewAdvertSectionHeading.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default NewAdvertSectionHeading
