import React from 'react'
import { Surface } from '@sznds/react'
import AbstractNewAdvertAdminView from '../AbstractNewAdvertAdminView'
import PhotosUploader from 'app/component/photosUploader/PhotosUploader'
import VideoUploader from 'app/component/videoUploader/VideoUploader'
import Modal from 'app/component/modal/ModalView'
import IMAGES from 'app/base/ImagesConstants'
import FormStepper from '../component/stepper/FormStepperView'
import PhotosUploaderExtension from 'app/component/photosUploader/PhotosUploaderExtension'
import VideoUploaderExtension from 'app/component/videoUploader/VideoUploaderExtension'
import { UPLOADER_CONSTANTS } from 'app/base/Constants'

import './PhotosCS.json'
import './PhotosView.less'

const CLASSNAME = 'p-uw-photos'

/**
 * Stranka pro vlozeni noveho inzeratu (vlozeni fotek).
 * @class PhotosView
 * @namespace app.page.userweb.newAdvert
 * @extends app.page.userweb.newAdvert.AbstractNewAdvertAdminView
 * @module app
 * @submodule app.page
 */
export default class PhotosView extends AbstractNewAdvertAdminView {
	constructor(props, context) {
		super(props, context)
	}

	_getPhotosLimit() {
		const { [PhotosUploaderExtension.stateKeys.MINIMAL_PHOTOS]: photosLimit } = this.props
		return photosLimit
	}

	_getLayoutProps(defaultLayoutProps) {
		return Object.assign({}, defaultLayoutProps, {
			className: CLASSNAME
		})
	}

	_renderModalHow() {
		const recommendedPhotosList = [
			{
				title: this.localize('Photos.modalHow.recommandedPhotos.front'),
				image: 'image__photo-example__front'
			},
			{
				title: this.localize('Photos.modalHow.recommandedPhotos.frontLeft'),
				image: 'image__photo-example__front-left'
			},
			{
				title: this.localize('Photos.modalHow.recommandedPhotos.backLeft'),
				image: 'image__photo-example__back-left'
			},
			{
				title: this.localize('Photos.modalHow.recommandedPhotos.back'),
				image: 'image__photo-example__back'
			},
			{
				title: this.localize('Photos.modalHow.recommandedPhotos.interior'),
				image: 'image__photo-example__interior'
			},
			{
				title: this.localize('Photos.modalHow.recommandedPhotos.frontSeats'),
				image: 'image__photo-example__front-seats'
			},
			{
				title: this.localize('Photos.modalHow.recommandedPhotos.backSeats'),
				image: 'image__photo-example__back-seats'
			},
			{
				title: this.localize('Photos.modalHow.recommandedPhotos.storage'),
				image: 'image__photo-example__storage'
			}
		]

		const complementaryPhotosList = [
			{
				title: this.localize('Photos.modalHow.complementaryPhotos.damage'),
				image: 'image__photo-example__damage'
			},
			{
				title: this.localize('Photos.modalHow.complementaryPhotos.wheels'),
				image: 'image__photo-example__wheels'
			},
			{
				title: this.localize('Photos.modalHow.complementaryPhotos.engine'),
				image: 'image__photo-example__engine'
			},
			{
				title: this.localize('Photos.modalHow.complementaryPhotos.equipment'),
				image: 'image__photo-example__equipment'
			}
		]

		const renderPhotoImage = (photo) => {
			return (
				<picture>
					<source srcSet={IMAGES[photo.image]} type='image/webp' />
					<img src={IMAGES[`${photo.image}-jpg`]} alt='' />
				</picture>
			)
		}

		return (
			<Modal
				dictionary={{
					ariaOpener: this.localize('Photos.modalHow.title'),
					ariaTitle: this.localize('Photos.modalHow.title')
				}}
				openerText={this.localize('Photos.modalHow.title')}
				title={this.localize('Photos.modalHow.title')}
			>
				<div className={`${CLASSNAME}__modal-content`}>
					{this.localize('Photos.modalHow.description')}
					<h4 className={`${CLASSNAME}__modal-header`}>
						{this.localize('Photos.modalHow.recommandedPhotos.title')}
					</h4>
					<ul className={`${CLASSNAME}__modal-list`}>
						{recommendedPhotosList.map((photo, index) => {
							return (
								<li className={`${CLASSNAME}__modal-list-item`} key={`photo_${index}`}>
									{photo.title}
									{renderPhotoImage(photo)}
								</li>
							)
						})}
					</ul>
					<h4 className={`${CLASSNAME}__modal-header`}>
						{this.localize('Photos.modalHow.complementaryPhotos.title')}
					</h4>
					<ul className={`${CLASSNAME}__modal-list`}>
						{complementaryPhotosList.map((photo, index) => {
							return (
								<li className={`${CLASSNAME}__modal-list-item`} key={`photo_${index}`}>
									{photo.title}
									{renderPhotoImage(photo)}
								</li>
							)
						})}
					</ul>
				</div>
			</Modal>
		)
	}

	_renderFormBtns() {
		const { [PhotosUploaderExtension.stateKeys.PHOTOS]: photos = [] } = this.props

		const photosLimit = this._getPhotosLimit()

		const okPhotos = photos.filter(({ error, serverId }) => !error && serverId)

		return <FormStepper disableNext={okPhotos.length < photosLimit} continueBySubmit={false} />
	}

	_renderContent() {
		const {
			[PhotosUploaderExtension.stateKeys.PHOTOS]: photos,
			[VideoUploaderExtension.stateKeys.VIDEO]: video
		} = this.props

		const photosLimit = this._getPhotosLimit()

		return (
			<div className={`${CLASSNAME}__content`}>
				<Surface className={`${CLASSNAME}__photos`} surface={5}>
					<div className={`${CLASSNAME}__media-box`}>
						<div className={`${CLASSNAME}__top-part`}>
							<label className={`${CLASSNAME}__label`}>{this.localize('Photos.photos')}</label>
							{this._renderModalHow()}
							<span className={`${CLASSNAME}__info`} data-e2e='photos-info'>
								{this.localize('Photos.photosInfo', {
									MIN_WIDTH: UPLOADER_CONSTANTS.SIZE.PHOTO_MIN_WIDTH,
									MIN_HEIGHT: UPLOADER_CONSTANTS.SIZE.PHOTO_MIN_HEIGHT,
									LIMIT: photosLimit
								})}
								&nbsp;
							</span>
						</div>

						<PhotosUploader photos={photos} />
					</div>

					<div className={`${CLASSNAME}__media-box`}>
						<div className={`${CLASSNAME}__top-part`}>
							<label className={`${CLASSNAME}__label`}>{this.localize('Photos.videos')}</label>
							<span className={`${CLASSNAME}__info`}>
								{this.localize('Photos.videosInfo')}&nbsp;
							</span>
						</div>

						<VideoUploader video={video} />
					</div>
				</Surface>

				{this._renderFormBtns()}
			</div>
		)
	}
}
