import React from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import PremiseService from 'app/model/premise/PremiseService'
import PremiseLogo from 'app/component/premiseLogo/PremiseLogo'
import PremiseSlots from 'app/component/premiseSlots/PremiseSlots'
import ClientBaseController from 'app/base/ClientBaseController'
import Wallet from 'app/component/wallet/Wallet'

import './PremiseHeaderAndSlots.less'

const CLASSNAME = 'c-premise-head-slots'

const PremiseHeaderAndSlots = ({ premiseInfo = {}, walletInfo = {} }) => {
	const { logo } = premiseInfo
	const { name } = PremiseService.getPremiseDataForPublic(premiseInfo)

	return (
		<div className={CLASSNAME}>
			{name && (
				<>
					{logo && <PremiseLogo className={`${CLASSNAME}__logo`} url={logo} />}
					<h1 className={`${CLASSNAME}__header`}>{name}</h1>
				</>
			)}
			<PremiseSlots className={`${CLASSNAME}__slots`} />
			<div className={`${CLASSNAME}__wallet`}>
				<Wallet.Label />
				<Wallet.Info walletInfo={walletInfo} premiseInfo={premiseInfo} />
			</div>
			<div className={`${CLASSNAME}__clear`}></div>
		</div>
	)
}

PremiseHeaderAndSlots.propTypes = {
	premiseInfo: PropTypes.object,
	walletInfo: PropTypes.shape({
		credit: PropTypes.number,
		walletLogin: PropTypes.string,
		walletUserId: PropTypes.string,
		serverError: PropTypes.bool
	})
}

const selectors = (state) => ({
	premiseInfo: state[ClientBaseController.STATE_KEYS.PREMISE_INFO],
	walletInfo: state[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]
})

export default React.memo(select(selectors)(PremiseHeaderAndSlots))
