
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['NewOperatingLeaseVariantForm'] = {
  headingNew: function(d) { return "Nová varianta"; },
  headingEdit: function(d) { return "Úprava varianty"; },
  periodMustBePositiveError: function(d) { return "Doba pronájmu musí být kladné číslo."; },
  periodRequiredError: function(d) { return "Zadejte prosím dobu pronájmu."; },
  distanceMustBePositiveError: function(d) { return "Roční nájezd musí být kladné číslo."; },
  distanceRequiredError: function(d) { return "Zadejte roční nájezd."; },
  additionalInfoTooLongError: function(d) { return "Překročena maximální délka 500 znaků."; },
  priceMustBePositiveError: function(d) { return "Cena musí být kladné číslo."; },
  priceRequiredError: function(d) { return "Zadejte měsíční cenu bez DPH."; },
  defaultSaveError: function(d) { return "Variantu operativního leasingu se nepodařilo uložit."; },
  alreadyDeletedError: function(d) { return "Upravovanou variantu operativního leasingu se nepodařilo uložit, byla již smazána."; },
  alreadyExistsError: function(d) { return "Stejná varianta operativního leasingu již jednou existuje."; },
  save: function(d) { return "Uložit nastavení"; },
  cancel: function(d) { return "Zrušit nastavení"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	