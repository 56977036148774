
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ModelsSearch'] = {
  chooseModel: function(d) { return "Vyberte model"; },
  searchModel: function(d) { return "Hledat model"; },
  addModel: function(d) { return "Přidat model"; },
  labelFavorite: function(d) { return "Nejhledanější modely"; },
  labelAll: function(d) { return "Všechny modely"; },
  labelFound: function(d) { return "Našli jsme"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	