
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PremiseHistoryRow'] = {
  category__firmy: function(d) { return "Data o firmě z Firmy.cz"; },
  catevory__subject: function(d) { return "Data o subjektu z Firmy.cz"; },
  category__sauto: function(d) { return "Data o firmě z Sauto.cz"; },
  category__phones: function(d) { return "Kontakty - telefony"; },
  category__emails: function(d) { return "Kontakty - email"; },
  category__users: function(d) { return "Uživatelé"; },
  category__admins: function(d) { return "Správci / obchodníci"; },
  category__sw_keys: function(d) { return "SW klíče"; },
  category__unknown: function(d) { return "Data"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	