import React from 'react'
import { Surface, Button } from '@sznds/react'
import { useLocalize, useFire } from 'app/base/componentHelpers'

import './PreviewMessage.less'
import './PreviewMessageCS.json'

const CLASSNAME = 'c-preview-message'

const PreviewMessage = () => {
	const fire = useFire()
	const localize = useLocalize()

	return (
		<Surface className={CLASSNAME} surface={4}>
			<div className={`${CLASSNAME}__content-wrapper`}>
				<div className={`${CLASSNAME}__content`}>
					<p className={`${CLASSNAME}__description`}>{localize('PreviewMessage.description')}</p>

					<Button
						className={`${CLASSNAME}__button`}
						size='small'
						text={localize('PreviewMessage.return')}
						onClick={() => {
							fire('returnFromPreview')
						}}
					/>
				</div>
			</div>
		</Surface>
	)
}

export default React.memo(PreviewMessage)
