import { Format } from '@inzeraty/helpers'
import FilterConstants from 'app/model/filter/FilterConstants'
import FilterToUrlAppConvertor from 'app/model/filter/filterConvertor/FilterToUrlAppConvertor'
import { CATEGORIES } from 'app/base/Constants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { CAR_TYPES } from 'app/page/userweb/home/carTypes/CarTypesConstants'
import filterUrlParamsCompare from 'app/helpers/filterUrlParamsCompare/FilterUrlParamsCompare'

import './AdvertListCS.json'
import 'app/base/BaseCS.json'

const areSetsEqual = (a, b) => a.size === b.size && [...a].every((value) => b.has(value))

const areDefaultsAppliedForConditionFilter = (conditionFormLineValue) => {
	const defaultConditionValuesSet = FilterConstants.defaultConditionValues
	const selectedConditionValuesSet = new Set(
		Array.from(conditionFormLineValue).map(([valueKey]) => valueKey)
	)

	return areSetsEqual(defaultConditionValuesSet, selectedConditionValuesSet)
}

export default class AdvertListMeta {
	static getTitleValue(dictionary, selectedCategory = {}, filterFormLineEntities = []) {
		const mergedTitle = AdvertListMeta.mergeAppliedFiltersToText(
			dictionary,
			selectedCategory,
			filterFormLineEntities
		)

		if (mergedTitle) {
			return dictionary.get('AdvertList.titleListSpecific', {
				TEXT: mergedTitle
			})
		} else {
			const titleValues = new Map([
				[CATEGORIES.PASSENGER_CARS.seoName, dictionary.get('AdvertList.titleListCategoryOsobni')],
				[CATEGORIES.UTILITY_CARS.seoName, dictionary.get('AdvertList.titleListCategoryUzitkova')],
				[CATEGORIES.TRUCKS.seoName, dictionary.get('AdvertList.titleListCategoryNakladni')],
				[CATEGORIES.MOTORCYCLE.seoName, dictionary.get('AdvertList.titleListCategoryMotorky')],
				[CATEGORIES.QUAD.seoName, dictionary.get('AdvertList.titleListCategoryCtyrkolky')],
				[CATEGORIES.TRAILERS.seoName, dictionary.get('AdvertList.titleListCategoryPrivesyNavesy')],
				[CATEGORIES.CAMPER.seoName, dictionary.get('AdvertList.titleListCategoryObytne')],
				[
					CATEGORIES.WORKING_MACHINES.seoName,
					dictionary.get('AdvertList.titleListCategoryPracovniStroje')
				],
				[CATEGORIES.BUSES.seoName, dictionary.get('AdvertList.titleListCategoryAutobusy')]
			])

			return titleValues.get(selectedCategory.seoName)
		}
	}

	static getDescriptionValue(
		dictionary,
		selectedCategory = {},
		filterFormLineEntities = [],
		filteredAdvertsCountTotal
	) {
		const carTypeSubCategoryText = AdvertListMeta.detectIfCarTypeSubCategoryIsSelected(
			dictionary,
			selectedCategory,
			filterFormLineEntities
		)
		const mergedTitle = AdvertListMeta.mergeAppliedFiltersToText(
			dictionary,
			selectedCategory,
			filterFormLineEntities
		)

		if (AdvertListMeta.isOnlyOneManufacturerAndMaxOneModelSelected(filterFormLineEntities)) {
			return dictionary.get('AdvertList.descriptionListSpecific', {
				TEXT: mergedTitle,
				COUNT: filteredAdvertsCountTotal
			})
		} else if (carTypeSubCategoryText || mergedTitle) {
			return dictionary.get('AdvertList.descriptionListGeneric', {
				TEXT: carTypeSubCategoryText || mergedTitle
			})
		} else {
			const descriptionValues = new Map([
				[
					CATEGORIES.PASSENGER_CARS.seoName,
					dictionary.get('AdvertList.descriptionListCategoryOsobni')
				],
				[
					CATEGORIES.UTILITY_CARS.seoName,
					dictionary.get('AdvertList.descriptionListCategoryUzitkova')
				],
				[CATEGORIES.TRUCKS.seoName, dictionary.get('AdvertList.descriptionListCategoryNakladni')],
				[
					CATEGORIES.MOTORCYCLE.seoName,
					dictionary.get('AdvertList.descriptionListCategoryMotorky')
				],
				[CATEGORIES.QUAD.seoName, dictionary.get('AdvertList.descriptionListCategoryCtyrkolky')],
				[
					CATEGORIES.TRAILERS.seoName,
					dictionary.get('AdvertList.descriptionListCategoryPrivesyNavesy')
				],
				[CATEGORIES.CAMPER.seoName, dictionary.get('AdvertList.descriptionListCategoryObytne')],
				[
					CATEGORIES.WORKING_MACHINES.seoName,
					dictionary.get('AdvertList.descriptionListCategoryPracovniStroje')
				],
				[CATEGORIES.BUSES.seoName, dictionary.get('AdvertList.descriptionListCategoryAutobusy')]
			])

			return descriptionValues.get(selectedCategory.seoName)
		}
	}

	static getAppliedFilters(filterFormLineEntities = []) {
		return filterFormLineEntities.filter((formLine) => {
			const { value } = formLine

			if (value instanceof Map) {
				return Array.from(value).length !== 0
			} else {
				return Object.keys(value).length !== 0
			}
		})
	}

	static isOnlyOneManufacturerAndMaxOneModelSelected(filterFormLineEntities = []) {
		const appliedFilters = AdvertListMeta.getAppliedFilters(filterFormLineEntities)

		const isAppliedDefaultCondition = (filterFormLineEntities) => {
			const conditionFormLineEntity = filterFormLineEntities.find(
				(f) => f.id === FilterConstants.formLineIds.CONDITION
			)

			if (conditionFormLineEntity) {
				const { value = new Map() } = conditionFormLineEntity

				return areDefaultsAppliedForConditionFilter(value)
			} else {
				return false
			}
		}

		if (
			appliedFilters.length === 2 &&
			isAppliedDefaultCondition(filterFormLineEntities) &&
			appliedFilters.map((f) => f.id).includes(FilterConstants.formLineIds.BRAND_MODEL)
		) {
			const manufacturerFormLineEntity = appliedFilters.find(
				(f) => f.id === FilterConstants.formLineIds.BRAND_MODEL
			)
			const { value = new Map() } = manufacturerFormLineEntity

			const valueArr = Array.from(value)

			if (valueArr.length === 1) {
				const [[, models = new Set()]] = valueArr
				const modelsArr = Array.from(models)

				return modelsArr.length <= 1
			}
		}
	}

	static convertToText(options = [], separator = ' ') {
		const sortBySeoOrder = (a = {}, b = {}) => a.seoOrder - b.seoOrder

		return [...options]
			.filter(({ seoOrder }) => !isNullOrUndefined(seoOrder))
			.sort(sortBySeoOrder)
			.map(({ seoTitle = '' } = {}) => seoTitle.trim())
			.join(separator)
	}

	static mergeAppliedFiltersToText(dictionary, selectedCategory, filterFormLineEntities = []) {
		// prvne musime zkontrolovat, ze neni zafiltrovana zadna specialni podkategorie
		// na Osobnich jako 'Levne vozy do 50 000 Kc', 'Rodinne vozy' atd.
		const carTypeSubCategoryText = AdvertListMeta.detectIfCarTypeSubCategoryIsSelected(
			dictionary,
			selectedCategory,
			filterFormLineEntities
		)

		if (carTypeSubCategoryText) {
			return carTypeSubCategoryText + ' |'
		} else {
			const allAppliedOptions = [
				...AdvertListMeta.getAppliedOptionForSelectedCategory(dictionary, selectedCategory),
				...AdvertListMeta.getAppliedOptionsForManufacturerFilter(filterFormLineEntities),
				...AdvertListMeta.getAppliedOptionsForPhrases(filterFormLineEntities),
				...AdvertListMeta.getAppliedOptionsForMultiOptionFilters(filterFormLineEntities),
				...AdvertListMeta.getAppliedOptionsForRangeFilters(dictionary, filterFormLineEntities)
			]

			return AdvertListMeta.convertToText(allAppliedOptions)
		}
	}

	static detectIfCarTypeSubCategoryIsSelected(
		dictionary,
		selectedCategory = {},
		filterFormLineEntities = []
	) {
		const { id: selectedCategoryId } = selectedCategory
		const currentAppParams = FilterToUrlAppConvertor.getFilterAdvertListUrlParams(
			filterFormLineEntities
		)

		const matchedCarTypeSubCategory = CAR_TYPES.find(
			({ categoryId: carTypeMainCategoryId, filters: carTypeAppParams }) =>
				carTypeMainCategoryId === selectedCategoryId &&
				filterUrlParamsCompare(carTypeAppParams, currentAppParams)
		)

		if (matchedCarTypeSubCategory) {
			const { type } = matchedCarTypeSubCategory
			const seoTextKey = `CarTypes.type-${type}`

			return dictionary.has(seoTextKey)
				? dictionary.get(seoTextKey, { CURRENCY: dictionary.get('Base.CZK') })
				: ''
		}
	}

	static getAppliedOptionForSelectedCategory(dictionary, selectedCategory = {}) {
		const { id, name } = selectedCategory

		if (id === CATEGORIES.PASSENGER_CARS.id) {
			return []
		} else {
			let seoTitle = name

			if (id === CATEGORIES.UTILITY_CARS.id) {
				seoTitle = dictionary.get('AdvertList.utilityCarsSeoTitle')
			} else if (id === CATEGORIES.TRUCKS.id) {
				seoTitle = dictionary.get('AdvertList.trucksSeoTitle')
			} else if (id === CATEGORIES.CAMPER.id) {
				seoTitle = dictionary.get('AdvertList.campersSeoTitle')
			}

			return [
				{
					seoOrder: 25,
					seoTitle
				}
			]
		}
	}

	static getAppliedOptionsForManufacturerFilter(filterFormLineEntities = []) {
		const { options = [], value = new Map() } =
			filterFormLineEntities.find(
				(formLineEntity) => formLineEntity.id === FilterConstants.formLineIds.BRAND_MODEL
			) || {}

		return Array.from(value).reduce((acc, [brandValue, modelsSet = new Set()]) => {
			const appliedBrandOption = options.find((o) => o.value === brandValue)

			if (appliedBrandOption) {
				const { children: modelOptions = [] } = appliedBrandOption

				const appliedModelOptions = Array.from(modelsSet)
					.map((modelValue) => modelOptions.find((o) => o.value === modelValue))
					.filter((m) => !!m)

				return acc.concat([appliedBrandOption, ...appliedModelOptions])
			}

			return acc
		}, [])
	}

	static getAppliedOptionsForPhrases(filterFormLineEntities = []) {
		const { value = '' } =
			filterFormLineEntities.find(
				(formLineEntity) => formLineEntity.id === FilterConstants.formLineIds.PHRASE
			) || {}

		if (!value) return []

		return [
			{
				seoOrder: 45,
				seoTitle: value
			}
		]
	}

	static getAppliedOptionsForMultiOptionFilters(filterFormLineEntities = []) {
		const MULTI_OPTION_FILTERS = [
			FilterConstants.formLineIds.CONDITION,
			FilterConstants.formLineIds.OPERATING_LEASE_SERVICES,
			FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR,
			FilterConstants.formLineIds.FUEL,
			FilterConstants.formLineIds.VEHICLE_BODY,
			FilterConstants.formLineIds.GEARBOX,
			FilterConstants.formLineIds.COUNTRY_OF_ORIGIN,
			FilterConstants.formLineIds.VENDOR,
			FilterConstants.formLineIds.AIR_CONDITIONING,
			FilterConstants.formLineIds.CERTIFIED_PROGRAM,
			FilterConstants.formLineIds.COLOR,
			FilterConstants.formLineIds.BUS_SEATS,
			FilterConstants.formLineIds.PRICE_OPTION,
			FilterConstants.formLineIds.HISTORY_INFO,
			FilterConstants.formLineIds.EQUIPMENT,
			FilterConstants.formLineIds.DRIVE,
			FilterConstants.formLineIds.OTHER_SPECIFICATION
		]

		return filterFormLineEntities
			.filter(({ id }) => MULTI_OPTION_FILTERS.includes(id))
			.reduce((result, formLineEntity) => {
				const { id, value = new Map(), options = [] } = formLineEntity

				if (
					id === FilterConstants.formLineIds.CONDITION &&
					areDefaultsAppliedForConditionFilter(value)
				) {
					return result
				}

				return result.concat(
					Array.from(value)
						.map(([optionValue]) => options.find((o) => o.value === optionValue))
						.filter((option) => !!option)
				)
			}, [])
	}

	static getAppliedOptionsForRangeFilters(dictionary, filterFormLineEntities = []) {
		const OPERATING_LEASE_RANGE_FILTERS = [
			FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT,
			FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE,
			FilterConstants.formLineIds.OPERATING_LEASE_PERIOD
		]

		const getCustomSeoTitleFrom = (filterLabel, valueFrom, unit) =>
			dictionary.get('AdvertList.rangeFrom', {
				FILTER_NAME: filterLabel,
				FROM: valueFrom,
				UNIT: unit
			})

		const getCustomSeoTitleTo = (filterLabel, valueTo, unit) =>
			dictionary.get('AdvertList.rangeTo', {
				FILTER_NAME: filterLabel,
				TO: valueTo,
				UNIT: unit
			})

		const getCustomSeoTitleFromTo = (filterLabel, valueFrom, valueTo, unit) =>
			dictionary.get('AdvertList.rangeFromTo', {
				FILTER_NAME: filterLabel,
				FROM: valueFrom,
				TO: valueTo,
				UNIT: unit
			})

		const getCustomSeoTitle = (formLineEntity = {}) => {
			const {
				id,
				label,
				value: { valueFrom, valueTo } = {},
				extra: { unit = '' } = {}
			} = formLineEntity
			const isNumber = (num) => typeof num === 'number'

			const getUnit = (value, defaultUnit) => {
				const unitOverrideFunction = RANGE_FILTERS_UNIT_GETTER[id]

				if (unitOverrideFunction) {
					return unitOverrideFunction(value)
				} else {
					return defaultUnit
				}
			}

			const formatNumber = (number) => {
				if (OPERATING_LEASE_RANGE_FILTERS.includes(id) && number) {
					return Format.number(number)
				} else {
					return number
				}
			}

			if (isNumber(valueFrom) && valueFrom >= 0 && !isNumber(valueTo)) {
				return getCustomSeoTitleFrom(label, formatNumber(valueFrom), getUnit(valueFrom, unit))
			} else if (!isNumber(valueFrom) && isNumber(valueTo) && valueTo >= 0) {
				return getCustomSeoTitleTo(label, formatNumber(valueTo), getUnit(valueTo, unit))
			} else if (isNumber(valueFrom) && valueFrom >= 0 && isNumber(valueTo) && valueTo >= 0) {
				return getCustomSeoTitleFromTo(
					label,
					formatNumber(valueFrom),
					formatNumber(valueTo),
					getUnit(valueTo, unit)
				)
			}
		}

		const RANGE_FILTERS = [
			FilterConstants.formLineIds.AGE,
			FilterConstants.formLineIds.ITEM_AGE,
			FilterConstants.formLineIds.PRICE,
			...OPERATING_LEASE_RANGE_FILTERS,
			FilterConstants.formLineIds.TACHOMETER,
			FilterConstants.formLineIds.PERFORMANCE,
			FilterConstants.formLineIds.DISPLACEMENT,
			FilterConstants.formLineIds.FUEL_CONSUMPTION,
			FilterConstants.formLineIds.AIRBAGS,
			FilterConstants.formLineIds.CAPACITY,
			FilterConstants.formLineIds.DOORS,
			FilterConstants.formLineIds.WEIGHT,
			FilterConstants.formLineIds.BEDS,
			FilterConstants.formLineIds.MACHINE_HOURS
		]

		const RANGE_FILTERS_UNIT_GETTER = {
			[FilterConstants.formLineIds.AIRBAGS]: (value) => {
				return dictionary.get('RangeInputOpener.airbags', { AIRBAGS_COUNT: value })
			},
			[FilterConstants.formLineIds.CAPACITY]: (value) => {
				return dictionary.get('RangeInputOpener.capacity', { CAPACITY_COUNT: value })
			},
			[FilterConstants.formLineIds.DOORS]: () => {
				return dictionary.get('RangeInputOpener.doors')
			},
			[FilterConstants.formLineIds.WEIGHT]: () => {
				return dictionary.get('RangeInputOpener.weight')
			},
			[FilterConstants.formLineIds.BEDS]: (value) => {
				return dictionary.get('AdvertList.beds', { BEDS_COUNT: value })
			},
			[FilterConstants.formLineIds.MACHINE_HOURS]: () => {
				return dictionary.get('RangeInputOpener.machineHours')
			},
			[FilterConstants.formLineIds.OPERATING_LEASE_PERIOD]: (value) => {
				return dictionary.get('AdvertList.months', { MONTHS: value })
			}
		}

		const isCustomSeoTitle = (formLineEntity = {}, option = {}) => {
			const { id } = formLineEntity

			if (id === FilterConstants.formLineIds.ITEM_AGE) {
				return false
			} else if (id === FilterConstants.formLineIds.AGE) {
				return option.seoName === 'vlastni'
			} else if (OPERATING_LEASE_RANGE_FILTERS.includes(id)) {
				return true
			} else {
				return isNullOrUndefined(option.valueFrom) && isNullOrUndefined(option.valueTo)
			}
		}

		return filterFormLineEntities
			.filter(({ id }) => RANGE_FILTERS.includes(id))
			.map((formLineEntity) => {
				const { value: { value: optionValue } = {}, options = [] } = formLineEntity
				const option = options.find((o) => o.value === optionValue)

				if (option) {
					return Object.assign(
						{},
						option,
						isCustomSeoTitle(formLineEntity, option) && {
							seoTitle: getCustomSeoTitle(formLineEntity)
						}
					)
				}
			})
			.filter((option) => !!option)
	}
}
