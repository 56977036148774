import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '@sznds/react'

import './AddNewButton.less'

const CLASSNAME = 'c-ia-add-new-button'

const AddNewButton = (props) => {
	const { className } = props

	return (
		<Button
			surface={3}
			size='small'
			{...props}
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		/>
	)
}

AddNewButton.propTypes = {
	className: PropTypes.string
	// a dalsi viz. Button z @sznds/react
}

export default React.memo(AddNewButton)
