import React from 'react'
import PropTypes from 'prop-types'

import './FormElementError.less'

const CLASSNAME = 'c-form-element-error'

const FormElementError = ({ message }) =>
	Boolean(message) && <div className={CLASSNAME}>{message}</div>

FormElementError.propTypes = {
	message: PropTypes.string
}

export default FormElementError
