
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['RadioInlineForm'] = {
  from: function(d) { return "Od"; },
  to: function(d) { return "Do"; },
  hpPriceFrom: function(d) { return "Cena od"; },
  hpPriceTo: function(d) { return "Cena do"; },
  hpAgeFrom: function(d) { return "Rok výroby od"; },
  hpTachometerTo: function(d) { return "Najeto do"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	