import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import FilterConstants from 'app/model/filter/FilterConstants'
import FiltersExtension, { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'

class MultiOptionFilterLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			context: PropTypes.string.isRequired,
			scopedContext: PropTypes.bool,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			scopedContext: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			filterUrlParams: props.filterUrlParams,

			filteredAdvertsCountTotal: props.filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal: props.isLoadingFilteredAdvertsCountTotal,

			filteredAdvertsCount: {},
			isLoadingFilteredAdvertsCount: props.context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL_INLINE,

			beenFilterChanged: false
		}

		this._changeFilter = this._changeFilter.bind(this)

		this._loadFilteredAdvertsCount = this._loadFilteredAdvertsCount.bind(this)
		this._calcFilteredAdvertsCountTotal = this._calcFilteredAdvertsCountTotal.bind(this)
	}

	componentDidUpdate(prevProps) {
		const {
			filterUrlParams,
			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal
		} = this.props
		const {
			filterUrlParams: prevFilterUrlParams,
			filteredAdvertsCountTotal: prevFilteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal: prevIsLoadingFilteredAdvertsCountTotal
		} = prevProps

		if (filterUrlParams !== prevFilterUrlParams) {
			this.setState({
				filterUrlParams
			})
		}

		if (filteredAdvertsCountTotal !== prevFilteredAdvertsCountTotal) {
			this.setState({
				filteredAdvertsCountTotal
			})
		}

		if (isLoadingFilteredAdvertsCountTotal !== prevIsLoadingFilteredAdvertsCountTotal) {
			this.setState({
				isLoadingFilteredAdvertsCountTotal
			})
		}
	}

	componentWillUnmount() {
		const {
			setFilteredAdvertsCountTotal: globalSetFilteredAdvertsCountTotal,
			scopedContext
		} = this.props
		const { filteredAdvertsCountTotal } = this.state

		if (scopedContext) {
			globalSetFilteredAdvertsCountTotal(filteredAdvertsCountTotal)
		}
	}

	render() {
		const { children, formLineEntity, categoryEntity } = this.props
		const {
			filteredAdvertsCount,
			isLoadingFilteredAdvertsCount,
			filterUrlParams,
			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal,
			beenFilterChanged
		} = this.state

		return children({
			form: {
				isInlineFormVisible: this._isInlineFormVisible(),

				validateForm: this._validateForm,
				changeFilter: this._changeFilter,
				beenFilterChanged
			},
			advertsCount: {
				filteredAdvertsCountTotal,
				isLoadingFilteredAdvertsCountTotal,
				calcFilteredAdvertsCountTotal: this._calcFilteredAdvertsCountTotal,

				filteredAdvertsCount,
				isLoadingFilteredAdvertsCount,
				loadFilteredAdvertsCount: this._loadFilteredAdvertsCount
			},
			other: {
				categoryEntity,
				formLineEntity,
				filterUrlParams
			}
		})
	}

	_changeFilter(id, value) {
		const { changeFilter, getFilterUrlParams } = this.props

		this.setState({
			beenFilterChanged: true
		})

		changeFilter(id, value, (newFormLineEntity) => {
			this._calcFilteredAdvertsCountTotal(newFormLineEntity)

			//TODO tady se mozna v urcitych pripadech zbytecne pocitaji url parametry
			this.setState(
				{
					filterUrlParams: getFilterUrlParams(newFormLineEntity)
				},
				() => {
					if (FilterConstants.specialFilters.includes(id)) {
						const { filterUrlParams } = this.state
						this._loadFilteredAdvertsCount(filterUrlParams)
					}
				}
			)
		})
	}

	_validateForm() {
		return true
	}

	_isInlineFormVisible() {
		const { openedFilterPopups } = this.props
		const [lastPopup = {}] = openedFilterPopups.slice(-1)

		return (
			lastPopup.filterId === FilterConstants.filterIds.EXTENDED_FILTER_ID ||
			openedFilterPopups.length === 0
		)
	}

	_loadFilteredAdvertsCount(filterUrlParams) {
		const {
			formLineEntity: { id },
			loadFilteredAdvertsCount,
			initialAdvertsCount = {}
		} = this.props

		this.setState(
			{
				isLoadingFilteredAdvertsCount: true
			},
			async () => {
				try {
					const { counts } = await loadFilteredAdvertsCount(
						id,
						filterUrlParams,
						initialAdvertsCount[id]
					)

					this.setState({
						filteredAdvertsCount: counts,
						isLoadingFilteredAdvertsCount: false
					})
				} catch (e) {
					// na chybu nebudeme nijak reagovat
				}
			}
		)
	}

	async _calcFilteredAdvertsCountTotal(newFormLineEntity) {
		const {
			loadFilteredAdvertsCountTotal,
			setFilteredAdvertsCountTotal: globalSetFilteredAdvertsCountTotal,
			categoryEntity,
			getFilterUrlParams,
			scopedContext
		} = this.props
		const { filteredAdvertsCount } = this.state
		const { id, value } = newFormLineEntity

		const setFilteredAdvertsCountTotal = (total) => {
			if (scopedContext) {
				this.setState({
					filteredAdvertsCountTotal: total
				})
			} else {
				globalSetFilteredAdvertsCountTotal(total)
			}
		}

		if (value.size && Object.keys(filteredAdvertsCount).length) {
			if (
				(value.size > 1 && id === FilterConstants.formLineIds.CERTIFIED_PROGRAM) ||
				FilterConstants.specialFilters.includes(id)
			) {
				// a) pro Overovaci programy musime volat vypisove API pri zaskrtnuti vice moznosti.
				// Kvuli Cebii a prekryti s ostatnimi overovacimi programy jako SKODA Plus
				// musime zavolat vypisove API, ktere vrati celkovou sumu inzeratu pro vybrane
				// moznosti. Diky prekryti by se totiz mohlo stat, ze uzivatel vybere Cebii
				// (1000 inzeratu) a SKODA Plus (taky 1000 inzeratu), tak suma nemusi byt 2000
				// inzeratu, ale klidne jen 1500. Takhle zajistime, ze uzivatel uvidi spravne cislo.
				// Viz komentar dole k prevodovce.
				//
				// b) pro specialni filtry jako Vybava, Dalsi moznosti ceny volame vypisove API vzdy.
				const urlParams = getFilterUrlParams(newFormLineEntity)
				const total = await loadFilteredAdvertsCountTotal(urlParams, categoryEntity)
				setFilteredAdvertsCountTotal(total)
			} else {
				const filterValuesToCount = Array.from(value.keys())

				const newTotal = filterValuesToCount.reduce((acc, val) => {
					if (filteredAdvertsCount[val]) return acc + (filteredAdvertsCount[val] || 0)
					else return acc
				}, 0)

				setFilteredAdvertsCountTotal(newTotal)
			}
		} else {
			// v pripade, ze neni vybrana ani jedna polozka v seznamu, nedokazeme celkovou
			// sumu vypocitat. Muze se totiz stat, ze nektere filtry pracuji nad daty,
			// ktere nejsou povinne. Pak celkova suma neni jen sumou vsech
			// polozek v seznamu.
			//
			// Napriklad filtr pro prevodovku:
			//
			// [ ]		manualni			250
			// [ ]		poloautomaticka		0
			// [ ]		automaticka			2
			//
			// Zadna polozka neni zaskrtnuta, vsechny jsou prazdne ([ ]).
			// Nemuzeme tedy vypocitat celkovy pocet dostupnych inzeratu jako 250+0+2=252.
			// Protoze muzou existovat inzeraty, ktere prevodovku nemaji uvedenou.
			// Celkovy pocet inzeratu muze byt tedy klidne vyssi, napriklad 300.
			const urlParams = getFilterUrlParams(newFormLineEntity)
			const total = await loadFilteredAdvertsCountTotal(urlParams, categoryEntity)
			setFilteredAdvertsCountTotal(total)
		}
	}
}

//TODO pridat shouldComponentUpdate, at se nam porad tato komponenta zbytecne neprekresluje
const selectors = (state) => ({
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS],
	loadFilteredAdvertsCount: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT],
	loadFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT_TOTAL],
	setFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.SET_FILTERED_ADVERTS_COUNT_TOTAL],
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	filterUrlParams: state[FiltersExtension.stateIds.URL_PARAMS],
	getFilterUrlParams: state[FiltersExtension.stateIds.GET_URL_PARAMS],
	redirect: state[FiltersExtension.stateIds.REDIRECT],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL],
	isLoadingFilteredAdvertsCountTotal:
		state[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL],
	initialAdvertsCount: {
		[FilterConstants.formLineIds.VEHICLE_BODY]: state.seoVehicleBodyWithAdvertsValues,
		[FilterConstants.formLineIds.FUEL]: state.seoFuelWithAdvertsValues,
		[FilterConstants.formLineIds.CONDITION]: state.seoConditionWithAdvertsValues,
		[FilterConstants.formLineIds.COUNTRY_OF_ORIGIN]: state.seoCountryOfOriginWithAdvertsValues,
		[FilterConstants.formLineIds.VENDOR]: state.seoVendorWithAdvertsValues,
		[FilterConstants.formLineIds.CERTIFIED_PROGRAM]: state.seoCertifiedProgramWithAdvertsValues
	}
})

export default select(selectors)(MultiOptionFilterLogic)
