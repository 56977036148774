
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SaveCert'] = {
  addText: function(d) { return "Zkopírujte číslo kuponu z výsledku prověření historie vašeho vozu. Číslo kuponu naleznete v záhlaví prověření nebo v sekci Sdílet > Základní prověření"; },
  cebiaReport: function(d) { return "Číslo kuponu"; },
  certPlaceholder: function(d) { return "Vložte číslo kuponu"; },
  certSave: function(d) { return "Připojit prověření"; },
  cebiaReportRequired: function(d) { return "Je třeba vyplnit číslo Cebia certifikátu"; },
  cebiaReportNotValid: function(d) { return "Číslo Cebia certifikátu je v chybném formátu"; },
  unknownCebiaFormat: function(d) { return "Neznámý formát CEBIA kupónu"; },
  invalidCebiaCoupon: function(d) { return "Nevalidní číslo CEBIA kupónu"; },
  invalidCebiaReport: function(d) { return "Nevalidní kód CEBIA certifikátu"; },
  invalidCebiaSmartCodeUrl: function(d) { return "Nevalidní URL adresa CEBIA kupónu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	