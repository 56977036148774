import React from 'react'
import AbstractComponent from 'app/base/AbstractComponent'

import './HomeHeaderView.less'
import './HomeHeaderCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-home-header'

class HomeHeader extends AbstractComponent {
	render() {
		return (
			<h1 className={`${CLASSNAME}`}>
				{this.localize('HomeHeader.titlePart_1')}
				<span className={`${CLASSNAME}__highlight`}>
					{this.localize('HomeHeader.titlehighlight')}
				</span>
				<span className={`${CLASSNAME}__long`}>{this.localize('HomeHeader.titlePart_2_long')}</span>
				<span className={`${CLASSNAME}__short`}>
					{this.localize('HomeHeader.titlePart_2_short')}
				</span>
			</h1>
		)
	}
}

export default HomeHeader
