const RESOURCE = '/articles'

export default class ArticlesResource {
	/**
	 * constructor
	 * @method constructor
	 * @param {RestClient} restClient
	 */
	constructor(restClient) {
		this._restClient = restClient
	}

	/**
	 * Získá a vrátí články.
	 *
	 * @method getArticlesList
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>} Promise, která vrací články.
	 */
	getArticlesList(params, options) {
		return this._restClient.get(`${RESOURCE}/models`, params, options)
	}

	/**
	 * Získá a vrátí detail článků
	 *
	 * @method getArticlesList
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<Array<ArticleEntity>>} Promise, která vrací detail článků.
	 */

	getArticlesDetail(params, options) {
		return this._restClient.get(`${RESOURCE}`, params, options)
	}

	getArticlesCachedDetail(params, options) {
		return this._restClient.get(`${RESOURCE}/cached`, params, options)
	}

	deleteArticle(id, params, options) {
		return this._restClient.delete(`${RESOURCE}/${id}`, params, options)
	}

	editArticle(id, params, options) {
		return this._restClient.patch(`${RESOURCE}/${id}`, params, options)
	}
}
