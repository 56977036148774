import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractComponent from 'app/base/AbstractComponent'
import * as FormLines from '@inzeraty/form-lines'
import FilterRow from '../../components/filterRow/FilterRowView'
import { Format } from '@inzeraty/helpers'

import './RegionForm.less'

const CLASSNAME = 'cf-region-form'

export default class RegionForm extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			context: PropTypes.string,
			filteredRegionName: PropTypes.string,
			filteredAdvertsCount: PropTypes.object,
			isLoadingFilteredAdvertsCount: PropTypes.bool,
			onRegionClick: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			className: '',
			formLineEntity: DEFAULT_PROPS.OBJECT,
			filteredRegionName: '',
			filteredAdvertsCount: DEFAULT_PROPS.OBJECT,
			isLoadingFilteredAdvertsCount: false,
			onRegionClick: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderRegion = this._renderRegion.bind(this)
		this._filterRegions = this._filterRegions.bind(this)
	}

	render() {
		const { className, formLineEntity } = this.props
		const { options = [] } = formLineEntity

		const filteredRegions = options.filter(this._filterRegions)

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				<ul>{filteredRegions.map(this._renderRegion)}</ul>
			</div>
		)
	}

	_filterRegions(region) {
		const regionName = Format.removeDiacritics(region.name).toLowerCase()

		const filteredRegionName = Format.removeDiacritics(this.props.filteredRegionName).toLowerCase()

		return regionName.includes(filteredRegionName)
	}

	_renderRegion(region) {
		const { value } = region
		const { filteredAdvertsCount, isLoadingFilteredAdvertsCount } = this.props

		return (
			<li key={value}>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
				<div className={`${CLASSNAME}__region`} onClick={() => this._onRegionClick(value)}>
					<FilterRow
						count={filteredAdvertsCount[value]}
						isCountLoading={isLoadingFilteredAdvertsCount}
						label={region.name}
					/>
				</div>
			</li>
		)
	}

	_onRegionClick(regionValue) {
		const { onRegionClick } = this.props

		onRegionClick(regionValue)
	}
}
