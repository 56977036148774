import React from 'react'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'
import Layout from 'app/component/layout/LayoutView'
import { useLocalize } from 'app/base/componentHelpers'
import PromoInsert from 'app/component/promoInsert/PromoInsert'
import UserwebBaseController from 'app/base/UserwebBaseController'
import ClientBaseController from 'app/base/ClientBaseController'

import './PromoInsertion.less'
import '../newAdvert/addVIN/AddVINViewCS.json'

const CLASSNAME = 'p-insertion'

const PromoInsertion = ({
	[ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise,
	[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelf
}) => {
	const localize = useLocalize()

	return (
		<Layout>
			<div className={CLASSNAME}>
				<div className={`${CLASSNAME}__content`}>
					<BackToHpButton />
					<h1 className={`${CLASSNAME}__header-main`}>{localize('AddVINView.title')}</h1>
					<p className={`${CLASSNAME}__promo-info`}>{localize('AddVINView.subTitleAboutUs')}</p>
				</div>

				<PromoInsert
					className={`${CLASSNAME}__promo-insert`}
					userSelf={userSelf}
					isPremise={isPremise}
				/>
			</div>
		</Layout>
	)
}

export default PromoInsertion
