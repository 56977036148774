import React from 'react'
import PremiseLogo from 'app/component/premiseLogo/PremiseLogo'
import * as FormLines from '@inzeraty/form-lines'
import { Format } from '@inzeraty/helpers'
import { SOURCE_WIDGET_ID, SOURCE_OPTIONS } from './widgets/source/SourceWidget'
import { TEXT_WIDGET_ID } from './widgets/text/TextWidget'
import { TEXTAREA_WIDGET_ID } from './widgets/textarea/TextareaWidget'
import { SIMPLE_DATA_WIDGET_ID } from './widgets/simpleData/SimpleDataWidget'
import {
	CONTACTS_WIDGET_ID,
	CONTACT_INPUT_TYPE,
	CONTACT_ERROR_MESSAGE
} from './widgets/contacts/ContactsWidget'
import { FORM_LINES_IDS } from './ClientProfileFormLineIds'
import {
	OPENING_HOURS_WIDGET_ID,
	OPENING_HOURS_ERROR
} from './widgets/openingHours/OpeningHoursWidget'
import { WALLET_WIDGET_ID } from './widgets/wallet/WalletWidget'
import { Icon } from '@sznds/react'
import { PHONE_FILLED_24, MAIL_ALT1_FILLED_24, WEB_OUTLINE_24 } from '@sznds/icons'
import { LOCALITY_WIDGET_ID } from './widgets/locality/LocalityWidget'
import { LOGO_WIDGET_ID } from './widgets/logo/LogoWidget'
import PremiseService from 'app/model/premise/PremiseService'
import { CONTACT_INPUT_NAMES } from './widgets/contacts/ContactsConstants'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './ClientProfileCS.json'

export const createFormLineEntities = (premiseEntity = {}, dictionary, isInternalAdmin = false) => {
	const serviceData = PremiseService.getPremiseDataByService(premiseEntity)

	const seissonData = {}

	const setSeissonData = (source, formLineEnetity, dafaultValue) => {
		const { id, value, errorMessage = '' } = formLineEnetity

		// pokud nejsou ulozena data, tak si ulozime vychozi data
		if (!seissonData[id]) {
			seissonData[id] = {
				value: dafaultValue,
				errorMessage: ''
			}

			// pokud prechazim na zdroj FIRMY, tak si ulozim data pro zdroj SAUTO
		} else if (source !== SOURCE_OPTIONS.SAUTO) {
			seissonData[id] = {
				value,
				errorMessage
			}
		}
	}

	const getSeissonDataValue = (formLineEntity) => {
		const { id } = formLineEntity
		const { value = '' } = seissonData[id] || {}
		return value
	}

	const getSeissonDataErrorMessage = (formLineEntity) => {
		const { id } = formLineEntity
		const { errorMessage = '' } = seissonData[id] || {}
		return errorMessage
	}

	const getOpeningHoursDependantData = (source, index, formLineEntity) => {
		const getOpeningHours = ({ openingHours: { openingTime } = {} }) => {
			return openingTime ? openingTime[index] : {}
		}
		setSeissonData(source, formLineEntity, getOpeningHours(serviceData))

		return {
			value:
				source === SOURCE_OPTIONS.SAUTO
					? getSeissonDataValue(formLineEntity)
					: getOpeningHours(premiseEntity),
			errorMessage:
				source === SOURCE_OPTIONS.SAUTO ? getSeissonDataErrorMessage(formLineEntity) : '',
			disabled: source === SOURCE_OPTIONS.FIRMY,
			extra: {
				getData: (formValue, { opening_hours = {} }) => {
					if (source === SOURCE_OPTIONS.SAUTO) {
						const { opening_time_st = [] } = opening_hours
						opening_time_st[index] = formValue

						return { opening_hours: { ...opening_hours, opening_time_st } }
					} else {
						return {}
					}
				},
				getValidationMessage: getOpeningHoursValidationMessage
			}
		}
	}

	const getOpeningHoursValidationMessage = (
		amStartMinutes,
		amEndMinutes,
		pmStartMinutes,
		pmEndMinutes
	) => {
		let amMessage = ''
		let pmMessage = ''

		if (amStartMinutes >= amEndMinutes || (amStartMinutes === 0 && amEndMinutes === 0)) {
			amMessage = dictionary.get('ClientProfile.openingHoursRangeNotValid')
		}

		if (pmStartMinutes >= pmEndMinutes || (pmStartMinutes === 0 && pmEndMinutes === 0)) {
			pmMessage = dictionary.get('ClientProfile.openingHoursRangeNotValid')
		}

		if (amEndMinutes >= pmStartMinutes) {
			pmMessage = pmMessage ? (
				<>
					{pmMessage}
					<br />
					{dictionary.get('ClientProfile.openingHoursPmNotValid')}
				</>
			) : (
				dictionary.get('ClientProfile.openingHoursPmNotValid')
			)
		}

		return {
			[OPENING_HOURS_ERROR.AM]: amMessage,
			[OPENING_HOURS_ERROR.PM]: pmMessage
		}
	}

	const getContactsErrorMessage = (errors = [], formValue, errorDictionary) => {
		const errorMessage = {}

		errors.forEach((error) => {
			const { fields } = error

			const fieldsParts = fields[0].split('.')
			const formLineEntityId = fieldsParts[0]
			const index = fieldsParts[1]
			const elementName = fieldsParts[2]

			const value = formValue[index][elementName]
			const errorMsg = value
				? errorDictionary[formLineEntityId][elementName][ERROR_CODES.NOT_VALID]
				: errorDictionary[formLineEntityId][elementName][ERROR_CODES.REQUIRED]

			if (!errorMessage[elementName]) {
				errorMessage[elementName] = []
			}

			errorMessage[elementName].push({
				[CONTACT_ERROR_MESSAGE.INDEX]: parseFloat(index),
				[CONTACT_ERROR_MESSAGE.MESSAGE]: errorMsg
			})
		})

		return errorMessage
	}

	const getContactsValidationMessage = (formValue = '', inputName, isRequired = false, id) => {
		let isEmpty = `${formValue}`.trim() === ''
		switch (inputName) {
			case CONTACT_INPUT_NAMES.CONTACT_EMAIL: {
				/* eslint-disable */
				const re = new RegExp(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/, 'i')
				/* eslint-enable */
				const isValid = re.test(formValue)
				return isEmpty && isRequired
					? dictionary.get('ClientProfile.emailRequired')
					: !isValid && !isEmpty
					? dictionary.get('ClientProfile.emailNotValid')
					: ''
			}

			case CONTACT_INPUT_NAMES.CONTACT_PHONE: {
				isEmpty = `${formValue}`.replace('+', '').trim() === ''
				const isValid = `${formValue}`.replace(/ /g, '').length === 13
				return isEmpty && isRequired
					? dictionary.get('ClientProfile.phoneRequired')
					: !isValid && !isEmpty
					? dictionary.get('ClientProfile.phoneNotValid')
					: ''
			}
		}
	}

	const formLinesList = [
		// sekce Zakladni informace
		{
			widget: SOURCE_WIDGET_ID,
			id: FORM_LINES_IDS.BASIC_INFORMATION_SOURCE,
			value:
				serviceData.useServiceName && serviceData.useServiceDescription
					? SOURCE_OPTIONS.SAUTO
					: SOURCE_OPTIONS.FIRMY,
			extra: {
				sourceLabel: dictionary.get('ClientProfile.basicInformationSourceLabel'),
				getData: (formValue) => {
					if (formValue === SOURCE_OPTIONS.SAUTO) {
						return { use_service_name: true, use_service_description: true }
					} else {
						return { use_service_name: false, use_service_description: false }
					}
				}
			}
		},
		{
			widget: LOGO_WIDGET_ID,
			id: FORM_LINES_IDS.LOGO,
			label: dictionary.get('ClientProfile.image'),
			value: premiseEntity.logo ? (
				<PremiseLogo url={premiseEntity.logo} />
			) : (
				dictionary.get('ClientProfile.imageUnavailable')
			)
		},
		{
			widget: TEXT_WIDGET_ID,
			id: FORM_LINES_IDS.NAME,
			label: dictionary.get('ClientProfile.name'),
			required: true,
			extra: {
				limit: 150,
				dependency: FORM_LINES_IDS.BASIC_INFORMATION_SOURCE,
				dependentData: (source, formLineEntity) => {
					setSeissonData(source, formLineEntity, serviceData.name)

					return {
						value:
							source === SOURCE_OPTIONS.SAUTO
								? getSeissonDataValue(formLineEntity)
								: premiseEntity.name,
						errorMessage:
							source === SOURCE_OPTIONS.SAUTO ? getSeissonDataErrorMessage(formLineEntity) : '',
						disabled: source === SOURCE_OPTIONS.FIRMY,
						extra: {
							getData: (formValue) => {
								if (source === SOURCE_OPTIONS.SAUTO) {
									return { name: formValue }
								} else {
									return {}
								}
							},
							getValidationMessage: (formValue = '', isRequired = false) => {
								const isEmpty = `${formValue}`.trim() === ''
								return isEmpty && isRequired ? dictionary.get('ClientProfile.nameRequired') : ''
							}
						}
					}
				}
			}
		},
		{
			widget: SIMPLE_DATA_WIDGET_ID,
			id: FORM_LINES_IDS.ICO,
			label: dictionary.get('ClientProfile.ico'),
			value: premiseEntity.subject?.ico
		},
		{
			widget: WALLET_WIDGET_ID,
			id: FORM_LINES_IDS.WALLET_ACOUNT,
			label: dictionary.get('ClientProfile.walletLabelAcount')
		},
		{
			widget: WALLET_WIDGET_ID,
			id: FORM_LINES_IDS.WALLET_INFO,
			label: dictionary.get('ClientProfile.walletLabelState')
		},
		{
			widget: SIMPLE_DATA_WIDGET_ID,
			id: FORM_LINES_IDS.ACTIVE_UNTIL,
			label: dictionary.get('ClientProfile.activeUntil'),
			value: !premiseEntity.registrationActiveUntil ? (
				dictionary.get('ClientProfile.activeUntilPlaceholder')
			) : new Date().getTime() < premiseEntity.registrationActiveUntil.getTime() ? (
				Format.date(premiseEntity.registrationActiveUntil)
			) : (
				<p>
					{dictionary.get('ClientProfile.activeUntilExpired')}
					{premiseEntity.admins?.length ? (
						<a
							href={`mailto:${premiseEntity.admins[0].email}?subject=${encodeURIComponent(
								dictionary.get('ClientProfile.emailSubject', {
									PREMISE_NAME: premiseEntity.name,
									ICO: premiseEntity.subject?.ico
								})
							)}`}
						>
							{dictionary.get('ClientProfile.contactBusinessRepresentative')}
						</a>
					) : (
						dictionary.get('ClientProfile.contactBusinessRepresentative')
					)}
				</p>
			)
		},
		{
			widget: SIMPLE_DATA_WIDGET_ID,
			id: FORM_LINES_IDS.BUSINESS_REPRESENTATIVE,
			label: dictionary.get('ClientProfile.businessRepresentative'),
			value: premiseEntity.admins?.length ? (
				<a href={`mailto:${premiseEntity.admins[0].email}`}>{premiseEntity.admins[0].name}</a>
			) : (
				dictionary.get('ClientProfile.businessRepresentativePlaceholder')
			)
		},
		{
			widget: SIMPLE_DATA_WIDGET_ID,
			id: FORM_LINES_IDS.LOGIN_EMAIL,
			label: dictionary.get('ClientProfile.loginEmail'),
			value: premiseEntity.users?.map(({ login }) => login).join(', ')
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.DESCRIPTION,
			label: dictionary.get('ClientProfile.description'),
			value: premiseEntity.description,
			extra: {
				limit: 500,
				dependency: FORM_LINES_IDS.BASIC_INFORMATION_SOURCE,
				dependentData: (source, formLineEntity) => {
					setSeissonData(source, formLineEntity, serviceData.description)

					return {
						value:
							source === SOURCE_OPTIONS.SAUTO
								? getSeissonDataValue(formLineEntity)
								: premiseEntity.description,
						errorMessage:
							source === SOURCE_OPTIONS.SAUTO ? getSeissonDataErrorMessage(formLineEntity) : '',
						disabled: source === SOURCE_OPTIONS.FIRMY,
						extra: {
							getData: (formValue) => {
								if (source === SOURCE_OPTIONS.SAUTO) {
									return { description: formValue }
								} else {
									return {}
								}
							}
						}
					}
				}
			}
		},
		// sekce Oteviraci doba
		{
			widget: SOURCE_WIDGET_ID,
			id: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
			value: serviceData.useServiceOpeningHours ? SOURCE_OPTIONS.SAUTO : SOURCE_OPTIONS.FIRMY,
			extra: {
				sourceLabel: dictionary.get('ClientProfile.openingHoursSourceLabel'),
				getData: (formValue) => {
					if (formValue === SOURCE_OPTIONS.SAUTO) {
						return { use_service_opening_hours: true }
					} else {
						return { use_service_opening_hours: false }
					}
				}
			}
		},
		{
			widget: OPENING_HOURS_WIDGET_ID,
			id: FORM_LINES_IDS.MONDAY_OPENING_HOURS,
			label: dictionary.get('ClientProfile.mondayOpeningHours'),
			extra: {
				inputLabel: dictionary.get('ClientProfile.mondayOpeningHoursLabel'),
				dependency: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
				dependentData: (source, formLineEntity) => ({
					...getOpeningHoursDependantData(source, 0, formLineEntity)
				})
			}
		},
		{
			widget: OPENING_HOURS_WIDGET_ID,
			id: FORM_LINES_IDS.TUESDAY_OPENING_HOURS,
			label: dictionary.get('ClientProfile.tuesdayOpeningHours'),
			extra: {
				inputLabel: dictionary.get('ClientProfile.tuesdayOpeningHoursLabel'),
				dependency: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
				dependentData: (source, formLineEntity) => ({
					...getOpeningHoursDependantData(source, 1, formLineEntity)
				})
			}
		},
		{
			widget: OPENING_HOURS_WIDGET_ID,
			id: FORM_LINES_IDS.WEDNESDAY_OPENING_HOURS,
			label: dictionary.get('ClientProfile.wednesdayOpeningHours'),
			extra: {
				inputLabel: dictionary.get('ClientProfile.wednesdayOpeningHoursLabel'),
				dependency: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
				dependentData: (source, formLineEntity) => ({
					...getOpeningHoursDependantData(source, 2, formLineEntity)
				})
			}
		},
		{
			widget: OPENING_HOURS_WIDGET_ID,
			id: FORM_LINES_IDS.THURSDAY_OPENING_HOURS,
			label: dictionary.get('ClientProfile.thursdayOpeningHours'),
			extra: {
				inputLabel: dictionary.get('ClientProfile.thursdayOpeningHoursLabel'),
				dependency: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
				dependentData: (source, formLineEntity) => ({
					...getOpeningHoursDependantData(source, 3, formLineEntity)
				})
			}
		},
		{
			widget: OPENING_HOURS_WIDGET_ID,
			id: FORM_LINES_IDS.FRIDAY_OPENING_HOURS,
			label: dictionary.get('ClientProfile.fridayOpeningHours'),
			extra: {
				inputLabel: dictionary.get('ClientProfile.fridayOpeningHoursLabel'),
				dependency: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
				dependentData: (source, formLineEntity) => ({
					...getOpeningHoursDependantData(source, 4, formLineEntity)
				})
			}
		},
		{
			widget: OPENING_HOURS_WIDGET_ID,
			id: FORM_LINES_IDS.SATURDAY_OPENING_HOURS,
			label: dictionary.get('ClientProfile.saturdayOpeningHours'),
			extra: {
				inputLabel: dictionary.get('ClientProfile.saturdayOpeningHoursLabel'),
				dependency: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
				dependentData: (source, formLineEntity) => ({
					...getOpeningHoursDependantData(source, 5, formLineEntity)
				})
			}
		},
		{
			widget: OPENING_HOURS_WIDGET_ID,
			id: FORM_LINES_IDS.SUNDAY_OPENING_HOURS,
			label: dictionary.get('ClientProfile.sundayOpeningHours'),
			extra: {
				inputLabel: dictionary.get('ClientProfile.sundayOpeningHoursLabel'),
				dependency: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
				dependentData: (source, formLineEntity) => ({
					...getOpeningHoursDependantData(source, 6, formLineEntity)
				})
			}
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.OPENING_HOURS_DESCRIPTION,
			label: dictionary.get('ClientProfile.openingHoursDescription'),
			extra: {
				dependency: FORM_LINES_IDS.OPENING_HOURS_SOURCE,
				dependentData: (source, formLineEntity) => {
					setSeissonData(
						source,
						formLineEntity,
						serviceData.openingHours && serviceData.openingHours.openingTimeAdditionalInfo
					)

					return {
						value:
							source === SOURCE_OPTIONS.SAUTO
								? getSeissonDataValue(formLineEntity)
								: premiseEntity.openingHours?.openingTimeAdditionalInfo,
						errorMessage:
							source === SOURCE_OPTIONS.SAUTO ? getSeissonDataErrorMessage(formLineEntity) : '',
						disabled: source === SOURCE_OPTIONS.FIRMY,
						extra: {
							getData: (formValue, { opening_hours = {} }) => {
								if (source === SOURCE_OPTIONS.SAUTO) {
									return {
										opening_hours: { ...opening_hours, opening_time_additional_info: formValue }
									}
								} else {
									return {}
								}
							}
						}
					}
				}
			}
		},

		// sekce Kontakty
		{
			widget: SOURCE_WIDGET_ID,
			id: FORM_LINES_IDS.CONTACT_SOURCE,
			value:
				serviceData.useServiceContacts && serviceData.useServiceWeb
					? SOURCE_OPTIONS.SAUTO
					: SOURCE_OPTIONS.FIRMY,
			extra: {
				sourceLabel: dictionary.get('ClientProfile.contactSourceLabel'),
				getData: (formValue) => {
					if (formValue === SOURCE_OPTIONS.SAUTO) {
						return { use_service_contacts: true, use_service_web: true }
					} else {
						return { use_service_contacts: false, use_service_web: false }
					}
				}
			}
		},
		{
			widget: CONTACTS_WIDGET_ID,
			id: FORM_LINES_IDS.CONTACT_EMAILS,
			label: (
				<>
					<Icon symbol={MAIL_ALT1_FILLED_24} /> {dictionary.get('ClientProfile.email')}
				</>
			),
			required: true,
			extra: {
				dependency: FORM_LINES_IDS.CONTACT_SOURCE,
				dependentData: (source, formLineEntity) => {
					const emails = premiseEntity.emails?.length ? premiseEntity.emails : []
					setSeissonData(source, formLineEntity, [
						...emails.filter((contact) => contact.sourceLocal)
					])

					return {
						value:
							source === SOURCE_OPTIONS.SAUTO
								? getSeissonDataValue(formLineEntity)
								: [...emails.filter((contact) => contact.sourceAsk)],
						errorMessage:
							source === SOURCE_OPTIONS.SAUTO ? getSeissonDataErrorMessage(formLineEntity) : '',
						disabled: source === SOURCE_OPTIONS.FIRMY || !isInternalAdmin,
						extra: {
							limits: {
								name: 50,
								value: 100
							},
							valueInputName: CONTACT_INPUT_NAMES.CONTACT_EMAIL,
							inputValueType: CONTACT_INPUT_TYPE.EMAIL,
							inputValueMode: 'email',
							inputValuePlaceholder: dictionary.get('ClientProfile.emailPlaceholder'),
							inputNamePlaceholder: dictionary.get('ClientProfile.emailNamePlaceholder'),
							defaultContact: dictionary.get('ClientProfile.defaultEmail'),
							addContact: dictionary.get('ClientProfile.addEmail'),
							getData: (formValue) => {
								if (source === SOURCE_OPTIONS.SAUTO) {
									const emails = formValue.map((contact) => ({
										name: contact.name,
										email: contact.email,
										default: contact.default
									}))

									return { emails }
								} else {
									return {}
								}
							},
							getErrorMessages: getContactsErrorMessage,
							getValidationMessage: getContactsValidationMessage
						}
					}
				}
			},
			value: [
				{
					name: '',
					email: '',
					default: true
				}
			]
		},
		{
			widget: TEXT_WIDGET_ID,
			id: FORM_LINES_IDS.CONTACT_WEB,
			label: (
				<>
					<Icon symbol={WEB_OUTLINE_24} /> {dictionary.get('ClientProfile.web')}
				</>
			),
			extra: {
				limit: 150,
				dependency: FORM_LINES_IDS.CONTACT_SOURCE,
				dependentData: (source, formLineEntity) => {
					setSeissonData(source, formLineEntity, serviceData.web)

					return {
						value:
							source === SOURCE_OPTIONS.SAUTO
								? getSeissonDataValue(formLineEntity)
								: premiseEntity.web,
						errorMessage:
							source === SOURCE_OPTIONS.SAUTO ? getSeissonDataErrorMessage(formLineEntity) : '',
						disabled: source === SOURCE_OPTIONS.FIRMY || !isInternalAdmin,
						extra: {
							getData: (formValue) => {
								if (source === SOURCE_OPTIONS.SAUTO) {
									return { web: formValue }
								} else {
									return {}
								}
							},
							getValidationMessage: (formValue = '', isRequired = false) => {
								const isEmpty = `${formValue}`.trim() === ''
								const re = new RegExp(
									/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
									'i'
								) // fragment locator
								const isValid = re.test(formValue)
								return isEmpty && isRequired
									? dictionary.get('ClientProfile.webRequired')
									: !isValid && !isEmpty
									? dictionary.get('ClientProfile.webNotValid')
									: ''
							}
						}
					}
				}
			}
		},
		{
			widget: CONTACTS_WIDGET_ID,
			id: FORM_LINES_IDS.CONTACT_PHONES,
			label: (
				<>
					<Icon symbol={PHONE_FILLED_24} /> {dictionary.get('ClientProfile.phone')}
				</>
			),
			required: true,
			extra: {
				dependency: FORM_LINES_IDS.CONTACT_SOURCE,
				dependentData: (source, formLineEntity) => {
					const phones = premiseEntity.phones?.length ? premiseEntity.phones : []
					setSeissonData(source, formLineEntity, [
						...phones?.filter((contact) => contact.sourceLocal)
					])

					return {
						value:
							source === SOURCE_OPTIONS.SAUTO
								? getSeissonDataValue(formLineEntity)
								: [...phones?.filter((contact) => contact.sourceAsk)],
						errorMessage:
							source === SOURCE_OPTIONS.SAUTO ? getSeissonDataErrorMessage(formLineEntity) : '',
						disabled: source === SOURCE_OPTIONS.FIRMY || !isInternalAdmin,
						extra: {
							valueInputName: CONTACT_INPUT_NAMES.CONTACT_PHONE,
							inputValueType: CONTACT_INPUT_TYPE.PHONE,
							inputValuePatern: '[0-9]',
							inputValueMode: 'numeric',
							inputValuePlaceholder: dictionary.get('ClientProfile.phonePlaceholder'),
							inputNamePlaceholder: dictionary.get('ClientProfile.phoneNamePlaceholder'),
							defaultContact: dictionary.get('ClientProfile.defaultPhone'),
							addContact: dictionary.get('ClientProfile.addPhone'),
							inputValueMaskProps: {
								mask: '+999 999 999 999',
								maskChar: '',
								alwaysShowMask: true
							},
							limits: {
								name: 50
							},
							getData: (formValue) => {
								if (source === SOURCE_OPTIONS.SAUTO) {
									const phones = formValue.map((contact) => ({
										name: contact.name,
										phone: contact.phone.replace(/ /g, ''),
										default: contact.default
									}))

									return { phones }
								} else {
									return {}
								}
							},
							getErrorMessages: getContactsErrorMessage,
							getValidationMessage: getContactsValidationMessage
						}
					}
				}
			},
			value: [
				{
					name: '',
					phone: '',
					default: true
				}
			]
		},

		// sekce Adresa
		{
			widget: SOURCE_WIDGET_ID,
			id: FORM_LINES_IDS.ADDRESS_SOURCE,
			value: serviceData.useServiceLocality ? SOURCE_OPTIONS.SAUTO : SOURCE_OPTIONS.FIRMY,
			extra: {
				sourceLabel: dictionary.get('ClientProfile.addressSourceLabel'),
				getData: (formValue) => {
					if (formValue === SOURCE_OPTIONS.SAUTO) {
						return { use_service_locality: true }
					} else {
						return { use_service_locality: false }
					}
				}
			}
		},
		{
			widget: LOCALITY_WIDGET_ID,
			id: FORM_LINES_IDS.ADDRESS,
			label: dictionary.get('ClientProfile.address'),
			required: true,
			extra: {
				dependency: FORM_LINES_IDS.ADDRESS_SOURCE,
				dependentData: (source, formLineEntity) => {
					setSeissonData(source, formLineEntity, serviceData.locality)

					return {
						value:
							source === SOURCE_OPTIONS.SAUTO
								? getSeissonDataValue(formLineEntity) || {}
								: premiseEntity.locality,
						errorMessage:
							source === SOURCE_OPTIONS.SAUTO ? getSeissonDataErrorMessage(formLineEntity) : '',
						disabled: source === SOURCE_OPTIONS.FIRMY,
						extra: {
							getData: (locality) => {
								if (source === SOURCE_OPTIONS.SAUTO && locality) {
									const { entityId, entityType } = locality

									return {
										locality: {
											entity_id: entityId,
											entity_type: entityType
										}
									}
								} else {
									return {}
								}
							},
							getValidationMessage: (inputValue = '', formValue = {}, isRequired = false) => {
								const { entityId, entityType } = formValue

								const isEmpty = `${inputValue}`.trim() === ''
								const isValid = !!entityId && !!entityType

								return isEmpty && isRequired
									? dictionary.get('ClientProfile.addressRequired')
									: !isValid
									? dictionary.get('ClientProfile.addressNotValid')
									: ''
							}
						}
					}
				}
			}
		}
	].filter((formLine) => !!formLine)

	return FormLines.createEntityList(formLinesList)
}

export const updateDepentantFormLineEntities = (formLineEntities) => {
	const dependencyValues = {}

	return formLineEntities.map((formLineEntity) => {
		const { extra: { dependency, dependentData } = {} } = formLineEntity

		if (!dependency) {
			return formLineEntity
		}

		if (!dependencyValues[dependency]) {
			const { value } = formLineEntities.find(({ id }) => id === dependency)
			dependencyValues[dependency] = value
		}

		const source = dependencyValues[dependency]

		const { extra, ...formLineDependentData } = dependentData(source, formLineEntity)
		const { value: formLineValue } = formLineEntity
		const { value: dependentDataValue } = formLineDependentData

		const value = dependentDataValue ? dependentDataValue : formLineValue

		const formLineDependentDataUpdated = Object.assign({}, formLineDependentData, {
			value
		})

		return {
			...formLineEntity,
			...formLineDependentDataUpdated,
			extra: {
				...formLineEntity.extra,
				...extra
			}
		}
	})
}

export const getFlatEntities = (formLineSections) => [].concat(...Object.values(formLineSections))
