import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import FilterConstants from 'app/model/filter/FilterConstants'

class RegionDistrictFilterLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			regionValue: PropTypes.number,
			context: PropTypes.string.isRequired,
			scopedContext: PropTypes.bool,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			scopedContext: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			filterUrlParams: props.filterUrlParams,

			filteredAdvertsCountTotal: props.filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal: props.isLoadingFilteredAdvertsCountTotal,

			filteredAdvertsCountForRegions: {},
			isLoadingFilteredAdvertsCountForRegions: false,

			filteredAdvertsCountForDistricts: {},
			isLoadingFilteredAdvertsCountForDistricts: false
		}

		this._changeFilter = this._changeFilter.bind(this)

		this._loadFilteredAdvertsCountForRegions = this._loadFilteredAdvertsCountForRegions.bind(this)
		this._loadFilteredAdvertsCountForDistricts = this._loadFilteredAdvertsCountForDistricts.bind(
			this
		)
		this._loadFilteredAdvertsCountForAll = this._loadFilteredAdvertsCountForAll.bind(this)

		this._calcFilteredAdvertsCountTotal = this._calcFilteredAdvertsCountTotal.bind(this)
	}

	componentDidUpdate(prevProps) {
		const {
			filterUrlParams,
			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal
		} = this.props
		const {
			filterUrlParams: prevFilterUrlParams,
			filteredAdvertsCountTotal: prevFilteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal: prevIsLoadingFilteredAdvertsCountTotal
		} = prevProps

		if (filterUrlParams !== prevFilterUrlParams) {
			this.setState({
				filterUrlParams
			})
		}

		if (filteredAdvertsCountTotal !== prevFilteredAdvertsCountTotal) {
			this.setState({
				filteredAdvertsCountTotal
			})
		}

		if (isLoadingFilteredAdvertsCountTotal !== prevIsLoadingFilteredAdvertsCountTotal) {
			this.setState({
				isLoadingFilteredAdvertsCountTotal
			})
		}
	}

	componentWillUnmount() {
		const {
			setFilteredAdvertsCountTotal: globalSetFilteredAdvertsCountTotal,
			scopedContext
		} = this.props
		const { filteredAdvertsCountTotal } = this.state

		if (scopedContext) {
			globalSetFilteredAdvertsCountTotal(filteredAdvertsCountTotal)
		}
	}

	render() {
		const { children, formLineEntity, categoryEntity } = this.props
		const {
			filteredAdvertsCountForRegions,
			isLoadingFilteredAdvertsCountForRegions,

			filteredAdvertsCountForDistricts,
			isLoadingFilteredAdvertsCountForDistricts,

			filteredAdvertsCountForAll,
			isLoadingFilteredAdvertsCountForAll,

			filterUrlParams,

			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal
		} = this.state

		return children({
			form: {
				validateForm: this._validateDistrictForm,
				changeFilter: this._changeFilter
			},
			advertsCountForRegions: {
				filteredAdvertsCount: filteredAdvertsCountForRegions,
				isLoadingFilteredAdvertsCount: isLoadingFilteredAdvertsCountForRegions,
				loadFilteredAdvertsCount: this._loadFilteredAdvertsCountForRegions
			},
			advertsCountForDistricts: {
				filteredAdvertsCountTotal,
				isLoadingFilteredAdvertsCountTotal,
				calcFilteredAdvertsCountTotal: this._calcFilteredAdvertsCountTotal,

				filteredAdvertsCount: filteredAdvertsCountForDistricts,
				isLoadingFilteredAdvertsCount: isLoadingFilteredAdvertsCountForDistricts,
				loadFilteredAdvertsCount: this._loadFilteredAdvertsCountForDistricts
			},
			advertsCountForAll: {
				filteredAdvertsCount: filteredAdvertsCountForAll,
				isLoadingFilteredAdvertsCount: isLoadingFilteredAdvertsCountForAll,
				loadFilteredAdvertsCount: this._loadFilteredAdvertsCountForAll
			},
			other: {
				categoryEntity,
				formLineEntity,
				filterUrlParams
			}
		})
	}

	_changeFilter(id, value) {
		const { changeFilter, getFilterUrlParams } = this.props

		changeFilter(id, value, (newFormLineEntity) => {
			const urlParams = getFilterUrlParams(newFormLineEntity)

			this._calcFilteredAdvertsCountTotal(newFormLineEntity)

			//TODO tady se mozna v urcitych pripadech zbytecne pocitaji url parametry
			this.setState({
				filterUrlParams: urlParams
			})
		})
	}

	_validateDistrictForm() {
		return true
	}

	_loadFilteredAdvertsCountForRegions() {
		const {
			formLineEntity: { id },
			loadFilteredAdvertsCount
		} = this.props

		this.setState(
			{
				isLoadingFilteredAdvertsCountForRegions: true
			},
			async () => {
				try {
					const { counts } = await loadFilteredAdvertsCount(id)

					this.setState({
						filteredAdvertsCountForRegions: counts,
						isLoadingFilteredAdvertsCountForRegions: false
					})
				} catch (e) {
					// na chybu nebudeme nijak reagovat
				}
			}
		)
	}

	_loadFilteredAdvertsCountForDistricts() {
		const { formLineEntity, regionValue, loadFilteredAdvertsCount, getFilterUrlParams } = this.props

		this.setState(
			{
				isLoadingFilteredAdvertsCountForDistricts: true
			},
			async () => {
				const newValue = new Map(formLineEntity.value)

				if (!newValue.has(regionValue)) {
					newValue.set(regionValue, new Set())
				}

				const newFormLineEntity = Object.assign({}, formLineEntity, {
					value: newValue
				})

				const customUrlParams = getFilterUrlParams(newFormLineEntity)

				try {
					const { counts } = await loadFilteredAdvertsCount(
						FilterConstants.filterIds.DISTRICT,
						customUrlParams
					)

					this.setState({
						filteredAdvertsCountForDistricts: counts,
						isLoadingFilteredAdvertsCountForDistricts: false
					})
				} catch (e) {
					// na chybu nebudeme nijak reagovat
				}
			}
		)
	}

	_loadFilteredAdvertsCountForAll() {
		const { formLineEntity, loadFilteredAdvertsCount, getFilterUrlParams } = this.props

		this.setState(
			{
				isLoadingFilteredAdvertsCountForAll: true
			},
			async () => {
				const updatedFormLineEntity = Object.assign({}, formLineEntity, {
					value: new Map() // chceme cisla pro vsechny okresy
				})

				const customUrlParams = getFilterUrlParams(updatedFormLineEntity)

				try {
					const [{ counts: countsForRegions }, { counts: countsForDistricts }] = await Promise.all([
						loadFilteredAdvertsCount(formLineEntity.id),
						loadFilteredAdvertsCount(FilterConstants.filterIds.DISTRICT, customUrlParams)
					])

					this.setState({
						filteredAdvertsCountForAll: [countsForRegions, countsForDistricts],
						isLoadingFilteredAdvertsCountForAll: false
					})
				} catch (e) {
					// na chybu nebudeme nijak reagovat
				}
			}
		)
	}

	async _calcFilteredAdvertsCountTotal(newFormLineEntity) {
		const {
			setFilteredAdvertsCountTotal: globalSetFilteredAdvertsCountTotal,
			getFilterUrlParams,
			loadFilteredAdvertsCountTotal,
			categoryEntity,
			scopedContext
		} = this.props

		const setFilteredAdvertsCountTotal = (total) => {
			if (scopedContext) {
				this.setState({
					filteredAdvertsCountTotal: total
				})
			} else {
				globalSetFilteredAdvertsCountTotal(total)
			}
		}

		// zadny kraj ani okres neni vybran, musime nacist celkovou sumu ze serveru
		const urlParams = getFilterUrlParams(newFormLineEntity)
		const newTotal = await loadFilteredAdvertsCountTotal(urlParams, categoryEntity)
		setFilteredAdvertsCountTotal(newTotal)
	}
}

//TODO pridat shouldComponentUpdate, at se nam porad tato komponenta zbytecne neprekresluje
const selectors = (state) => ({
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS],
	loadFilteredAdvertsCount: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT],
	loadFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT_TOTAL],
	setFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.SET_FILTERED_ADVERTS_COUNT_TOTAL],
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	filterUrlParams: state[FiltersExtension.stateIds.URL_PARAMS],
	getFilterUrlParams: state[FiltersExtension.stateIds.GET_URL_PARAMS],
	redirect: state[FiltersExtension.stateIds.REDIRECT],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL],
	isLoadingFilteredAdvertsCountTotal:
		state[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(selectors)(RegionDistrictFilterLogic)
