import { LocalStorage } from '@inzeraty/helpers'
import { CATEGORIES } from 'app/base/Constants'
import FilterFormLinesToMySavedSearchConvertor from 'app/model/mySavedSearch/convertors/FilterFormLinesToMySavedSearchConvertor'
import MySavedSearchPrint from 'app/model/mySavedSearch/print/MySavedSearchPrint'
import {
	LAST_SEARCH_TITLE_LOCAL_STORAGE,
	LAST_SEARCH_SENTENCE_LOCAL_STORAGE,
	LAST_SEARCH_URL_LOCAL_STORAGE
} from 'app/base/StorageIds'

export default class SearchHelper {
	static getTitleAndSentence(
		localize,
		categorySeoName,
		filterFormLineEntities = [],
		isOperatingLease = false
	) {
		const category = Object.values(CATEGORIES).find(
			(category) => category.seoName === categorySeoName
		)

		const mySavedSearch = {
			operatingLease: isOperatingLease,
			filter: FilterFormLinesToMySavedSearchConvertor.convert(category, filterFormLineEntities)
		}

		return {
			title: MySavedSearchPrint.printTitle(mySavedSearch, ', '),
			sentence: MySavedSearchPrint.printSentence(localize, mySavedSearch)
		}
	}

	static saveLastSearch(
		localize,
		categorySeoName,
		filterFormLineEntities = [],
		url,
		isOperatingLease = false
	) {
		const { title, sentence } = SearchHelper.getTitleAndSentence(
			localize,
			categorySeoName,
			filterFormLineEntities,
			isOperatingLease
		)

		LocalStorage.setItem(LAST_SEARCH_TITLE_LOCAL_STORAGE, title)
		LocalStorage.setItem(LAST_SEARCH_SENTENCE_LOCAL_STORAGE, sentence)
		LocalStorage.setItem(LAST_SEARCH_URL_LOCAL_STORAGE, url)
	}

	static loadLastSearch() {
		return {
			title: LocalStorage.getItem(LAST_SEARCH_TITLE_LOCAL_STORAGE),
			sentence: LocalStorage.getItem(LAST_SEARCH_SENTENCE_LOCAL_STORAGE),
			url: LocalStorage.getItem(LAST_SEARCH_URL_LOCAL_STORAGE)
		}
	}
}
