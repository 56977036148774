
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['GetCert'] = {
  moreText: function(d) { return "Zkontrolujte níže uvedené informace o vašem vozidlu a pokračujte v prověření."; },
  carTypeAndModel: function(d) { return "Typ a model vozu:"; },
  carTypeAndModelText: function(d) { return d.NAME + ", vyrobeno " + d.DATE; },
  carEngine: function(d) { return "Motorizace:"; },
  carEngineText: function(d) { return d.ENGINE_VOLUME + " ccm, " + d.FUEL_NAME + ", najeto " + d.TACHOMETER + " km"; },
  vinCode: function(d) { return "VIN kód:"; },
  checkCar: function(d) { return "Prověřit vozidlo"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	