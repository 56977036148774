import { PROFILE_SECTIONS, SECTIONS } from 'app/page/userweb/clientProfile/ClientProfileSections'
import { FORM_LINES_IDS } from 'app/page/internalAdmin/premiseEdit/IAPremiseEditFormLineIds'

export const IA_SECTION = {
	SOFTWARE_KEYS: 'SOFTWARE_KEYS',
	IMPORT_DATA: 'IMPORT_DATA'
}

export const IA_SECTIONS = {
	...SECTIONS,
	[PROFILE_SECTIONS.BASIC_INFORMATION]: [
		...SECTIONS[PROFILE_SECTIONS.BASIC_INFORMATION],
		FORM_LINES_IDS.PREMISE_ID,
		FORM_LINES_IDS.EXTERNAL_ID,
		FORM_LINES_IDS.IS_ACTIVE,
		FORM_LINES_IDS.ADVERT_COUNT,
		FORM_LINES_IDS.PRODUCT_LIST,
		FORM_LINES_IDS.RETARGETING_ID,
		FORM_LINES_IDS.USE_ADVERT_LOCALITY,
		FORM_LINES_IDS.HIDE_VIN,
		FORM_LINES_IDS.HOME_DELIVERY
	],
	[IA_SECTION.SOFTWARE_KEYS]: [FORM_LINES_IDS.SOFTWARE_KEYS],
	[IA_SECTION.IMPORT_DATA]: [FORM_LINES_IDS.IMPORT_LOGIN, FORM_LINES_IDS.IMPORT_PASSWORD]
}
