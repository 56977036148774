
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['HistoryForm'] = {
  historySection: function(d) { return "Historie vozidla"; },
  guaranteeDateLabel: function(d) { return "Platnost záruky"; },
  euroLeverLabel: function(d) { return "Jakou normu EURO plní?"; },
  euroLevelPlaceholder: function(d) { return "Vyberte EURO normu"; },
  environmentalTaxPaidLabel: function(d) { return "Zaplacená ekologická daň"; },
  firstOwnerLabel: function(d) { return "Jste prvním majitelem?"; },
  adjustmentsForHandicappedLabel: function(d) { return "Úpravy pro zdravotně postižené"; },
  crashedInPastLabel: function(d) { return "Bylo vozidlo v minulosti havarováno?"; },
  tuningLabel: function(d) { return "Prošlo auto nějakým vylepšením?"; },
  serviceBookLabel: function(d) { return "Má auto servisní knížku?"; },
  guaranteeDateNotValid: function(d) { return "Neplatná hodnota \"Platnost záruky\""; },
  euroLevelNotValid: function(d) { return "Neplatná hodnota \"EURO norma\""; },
  environmentalTaxPaidNotValid: function(d) { return "Neplatná hodnota \"Zaplacená ekologická daň\""; },
  firstOwnerNotValid: function(d) { return "Neplatná hodnota \"Jste prvním majitelem?\""; },
  adjustmentsForHandicappedNotValid: function(d) { return "Neplatná hodnota \"Úpravy pro zdravotně postižené\""; },
  crashedInPastNotValid: function(d) { return "Neplatná hodnota \"Bylo vozidlo v minulosti havarováno?\""; },
  tuningNotValid: function(d) { return "Neplatná hodnota \"Prošlo auto nějakým vylepšením?\""; },
  serviceBookNotValid: function(d) { return "Neplatná hodnota \"Má auto servisní knížku?\""; },
  gotoPrev: function(d) { return "Zpět na Fotografie"; },
  submit: function(d) { return "Pokračovat na Výbavu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	