import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS, HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import { CategoryEntity, WatchDogConstants as MySavedSearchConstants } from '@inzeraty/models'
import { StatusMessage } from '@inzeraty/components'
import ROUTE_NAMES from 'app/base/RouteNames'
import { useLocalize, useRouteName } from 'app/base/componentHelpers'
import FilterFormLinesToMySavedSearchConvertor from 'app/model/mySavedSearch/convertors/FilterFormLinesToMySavedSearchConvertor'
import MySavedSearchSaveForm from '../saveForm/MySavedSearchSaveForm'

import './AddMySavedSearchCS.json'

const { NOTIFICATION_INTERVAL } = MySavedSearchConstants

const AddMySavedSearch = ({
	category,
	filterFormLineEntities,
	isPopupOpened,
	onClose: onCloseProp,
	showToastMessage,
	addMySavedSearch
}) => {
	const localize = useLocalize()
	const routeName = useRouteName()

	const [mySavedSearch, setMySavedSearch] = useState(undefined)
	const [statusMessage, setStatusMessage] = useState(undefined)

	useEffect(() => {
		if (isPopupOpened) {
			setMySavedSearch(undefined)
			setStatusMessage(undefined)

			const isOperatingLease = routeName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES

			const mySavedSearchFilter = FilterFormLinesToMySavedSearchConvertor.convert(
				category,
				filterFormLineEntities
			)

			setMySavedSearch({
				filter: mySavedSearchFilter,
				notificationInterval: NOTIFICATION_INTERVAL.DAILY,
				ribbonNotificationEnabled: true,
				operatingLease: isOperatingLease
			})
		}
	}, [isPopupOpened, category, filterFormLineEntities, routeName])

	const onClose = () => {
		onCloseProp()
		setMySavedSearch(undefined)
		setStatusMessage(undefined)
	}

	const showSuccessToastMessage = () => {
		showToastMessage({
			title: localize('AddMySavedSearch.success'),
			type: StatusMessage.TYPE.SUCCESS
		})
	}

	const handleAddMySavedSearch = async (
		mySavedSearch = {},
		notificationInterval,
		ribbonNotificationEnabled
	) => {
		try {
			setStatusMessage(undefined)
			await addMySavedSearch(
				mySavedSearch.filter,
				notificationInterval,
				ribbonNotificationEnabled,
				mySavedSearch.operatingLease
			)
			onClose()
			showSuccessToastMessage()
		} catch (error) {
			const status = HttpErrorHelper.getHttpStatus(error)

			let errorDescription = undefined

			if (status === HttpStatusCodes.FORBIDDEN) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				const { errors = [] } = body

				if (errors.find(({ error_code }) => error_code === 'watchdog_limit_reached')) {
					errorDescription = localize('AddMySavedSearch.limitReachedError')
				} else if (errors.find(({ error_code }) => error_code === 'watchdog_already_exists')) {
					errorDescription = localize('AddMySavedSearch.alreadyExistsError')
				}
			}

			setStatusMessage({
				title: localize('AddMySavedSearch.saveError'),
				children: errorDescription
			})
		}
	}

	return (
		<MySavedSearchSaveForm
			isPopupOpened={isPopupOpened}
			isLoading={!mySavedSearch}
			mySavedSearch={mySavedSearch}
			renderStatusMessage={() =>
				statusMessage ? (
					<StatusMessage type={StatusMessage.TYPE.ERROR} title={statusMessage.title}>
						{statusMessage.children}
					</StatusMessage>
				) : undefined
			}
			onClose={onClose}
			onSave={handleAddMySavedSearch}
		/>
	)
}

AddMySavedSearch.propTypes = {
	category: PropTypes.instanceOf(CategoryEntity).isRequired,
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isPopupOpened: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	showToastMessage: PropTypes.func,
	addMySavedSearch: PropTypes.func.isRequired
}

AddMySavedSearch.defaultProps = {
	category: new CategoryEntity(DEFAULT_PROPS.OBJECT),
	filterFormLineEntities: DEFAULT_PROPS.ARRAY,
	isPopupOpened: false,
	onClose: DEFAULT_PROPS.FUNCTION,
	showToastMessage: DEFAULT_PROPS.FUNCTION,
	addMySavedSearch: DEFAULT_PROPS.FUNCTION
}

export default React.memo(AddMySavedSearch)
