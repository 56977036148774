import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Responsive } from '@inzeraty/components'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import DropDownButton from 'app/component/dropDownButton/DropDownButton'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import { RESPONSIVE } from 'app/base/Constants'
import { useLocalize } from 'app/base/componentHelpers'

import './BasicSelectWidget.less'
import './BasicSelectWidgetCS.json'

export const BASIC_SELECT_WIDGET_ID = 'basic_select_widget'

const CLASSNAME = 'c-basic-select-widget-base'

const NO_SELECTION_OPTION = -1

const BasicSelectWidget = (props) => {
	const localize = useLocalize()

	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		onChange = DEFAULT_PROPS.FUNCTION,
		hasNoSelectedOption = true,
		inputId = ''
	} = props

	const renderDropDownButton = (formLineEntity, downshift) => {
		const renderText = (loadingMessage) => {
			const { value: { value: selectedValue } = {}, placeholder, options = [] } = formLineEntity

			if (loadingMessage) {
				return loadingMessage
			} else if (isOptionSelected()) {
				const selectedOption = options.find((o) => o.value === selectedValue) || {}

				return renderSelectedOption(selectedOption)
			} else {
				return placeholder
			}
		}

		const { getToggleButtonProps, isOpen, getInputProps } = downshift

		const { disabled, errorMessage, options, extra: { cebiaError } = {} } = formLineEntity

		const { ref: openerRef } = getInputProps()

		return (
			<div className={`${CLASSNAME}__wrapper`}>
				<DropDownButton
					className={classnames({
						'c-basic-widget__cebia-warn': cebiaError,
						[props.className]: !!props.className
					})}
					isLoading={!options}
					isOpen={isOpen}
					isOptionSelected={isOptionSelected()}
					renderText={renderText}
					error={errorMessage}
					disabled={disabled}
					{...getToggleButtonProps(
						inputId && {
							id: inputId
						}
					)}
					ref={openerRef}
					data-e2e={formLineEntity?.id}
				/>

				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => renderPopup(formLineEntity, downshift)}
					renderDesktopElement={() => renderDropdown(formLineEntity, downshift)}
				/>
			</div>
		)
	}

	const isOptionSelected = () => {
		const { value: { value: selectedValue } = {}, options } = formLineEntity

		return options && options.length && selectedValue >= 0
	}

	const renderSelectedOption = (option = {}) => {
		if (props.renderSelectedOption) {
			return props.renderSelectedOption(option)
		} else {
			return option.name
		}
	}

	const renderDropdown = (formLineEntity, downshift) => {
		const { isOpen, getDropdownProps, renderDropdown: Dropdown } = downshift

		return isOpen && <Dropdown {...getDropdownProps()}>{renderItems(downshift)}</Dropdown>
	}

	const renderPopup = (formLineEntity, downshift) => {
		const {
			isOpen,

			getPopupProps,
			renderPopup: Popup,

			closeMenu
		} = downshift

		return (
			isOpen && (
				<Popup
					{...getPopupProps({
						title: formLineEntity.label,
						onClose: closeMenu
					})}
				>
					{renderItems(downshift)}
				</Popup>
			)
		)
	}

	const renderItems = (downshift) => {
		const { options: items = [] } = formLineEntity
		const { getItemProps, highlightedIndex } = downshift

		return (
			<ul>
				{hasNoSelectedOption && (
					<AutoCompleteOption
						{...getItemProps({
							key: NO_SELECTION_OPTION,
							index: 0,
							item: NO_SELECTION_OPTION,
							isHighlighted: highlightedIndex === 0,
							className: `${CLASSNAME}__item`,
							'data-e2e': formLineEntity?.id
						})}
					>
						{localize('BasicSelectWidget.noOptionSelected')}
					</AutoCompleteOption>
				)}

				{items.map((item, index) => (
					// eslint-disable-next-line react/jsx-key
					<AutoCompleteOption
						{...getItemProps({
							key: item.value,
							index: index + 1,
							item,
							isHighlighted: highlightedIndex === index + 1,
							className: `${CLASSNAME}__item`,
							'data-e2e': formLineEntity?.id
						})}
					>
						{renderSelectedOption(item)}
					</AutoCompleteOption>
				))}
			</ul>
		)
	}

	const handleSelect = (selectedItem) => {
		if (selectedItem === NO_SELECTION_OPTION) {
			onChange({
				id: formLineEntity.id,
				value: undefined,
				errorMessage: undefined
			})
		} else if (selectedItem) {
			const { value, name } = selectedItem

			onChange({
				id: formLineEntity.id,
				value: {
					value,
					name
				},
				errorMessage: undefined
			})
		}
	}

	const restProps = Object.assign({}, props)

	delete restProps.formLineEntity
	delete restProps.onChange
	delete restProps.inputId
	delete restProps.renderSelectedOption

	return (
		<AutoComplete {...restProps} onSelect={handleSelect}>
			{(downshift) => renderDropDownButton(formLineEntity, downshift)}
		</AutoComplete>
	)
}

BasicSelectWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	inputId: PropTypes.string,
	renderSelectedOption: PropTypes.func,
	className: PropTypes.string,
	hasNoSelectedOption: PropTypes.bool
}

export default React.memo(BasicSelectWidget)
