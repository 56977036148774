import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AbstractComponent from 'app/base/AbstractComponent'
import { Radio } from '@sznds/react'
import FilterRow from './FilterRowView'

import './RadioFilterRowView.less'

const CLASSNAME = 'cf-radio-row'

export default class RadioFilterRow extends AbstractComponent {
	static get propTypes() {
		return {
			value: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			count: PropTypes.number,
			isCountShownWithAddText: PropTypes.bool,
			isCountLoading: PropTypes.bool,
			isSelected: PropTypes.bool,
			label: PropTypes.string.isRequired,
			onChange: PropTypes.func,
			index: PropTypes.number,
			isCompactFilter: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			count: 0,
			isCountShownWithAddText: false,
			isCountLoading: false,
			isSelected: false,
			isCompactFilter: false,
			onChange: (e) => {}
		}
	}

	render() {
		const {
			value,
			count,
			label,
			name,
			isCountLoading,
			isSelected,
			isCountShownWithAddText,
			onChange,
			index,
			isLastValue,
			isCompactFilter
		} = this.props

		return (
			// eslint-disable-next-line jsx-a11y/label-has-associated-control
			<label
				className={classnames({
					[CLASSNAME]: true,
					[`${CLASSNAME}--selected`]: isSelected
				})}
			>
				<FilterRow
					label={label}
					count={count}
					isCountShownWithAddText={isCountShownWithAddText}
					isCountLoading={isCountLoading}
					isSelected={!isCompactFilter && isSelected}
					index={index}
					isHpFilter={!isCompactFilter}
					dataDot={isLastValue ? 'choose-range' : 'choose-preset'}
				>
					<Radio
						className={`${CLASSNAME}__radio`}
						name={name}
						value={value}
						checked={isSelected}
						onChange={onChange}
					/>
				</FilterRow>
			</label>
		)
	}
}
