/**
 * @class ReviewEntity
 * @description Obsahuje data, pro zkusenost majitele.
 * @module models
 */
export default class ReviewEntity {
	/**
	 * @constructor
	 * @param {Object} data
	 * @param {Date}   data.createDate               Datum vzniku zkusenosti
	 * @param {Date}   data.editDate                 Datum posledni editace zkusenosti
	 * @param {Object} data.fuelCb                   Palivo vozu
	 * @param {Number} data.id                       Id zkusenosti
	 * @param {Object} data.manufacturerCb           Vyrobce vozu
	 * @param {Number} data.manufacturingYear        Rok vyroby vozu
	 * @param {Object} data.modelCb                  Model vozu
	 * @param {String} data.negatives                Popis negativnich zkusenosti
	 * @param {Number} data.negativeFeedbackCount    Pocet oznaceni zkusenosti jako neuzitecne
	 * @param {String} data.positives                Popis pozitivnich zkusenosti
	 * @param {Number} data.positiveFeedbackCount    Pocet oznaceni zkusenosti jako uzitecne
	 * @param {Number} data.power                    Vykon vozu
	 * @param {String} data.review                   Popis zkusenosti
	 * @param {String} data.reviewReplies            Odpovedi ke zkusenosti
	 * @param {Number} data.repliesCount             Pocet odpovedi ke zkusenosti
	 * @param {String} data.title                    Nadpis zkusenosti
	 * @param {String} data.type                     Typ zkusenosti 'positive/negative/neutral'
	 * @param {String} data.userEmailPrefix          Cast emailu pred zavinacem (autora zkusenosti)
	 * @param {String} data.userFeedbackType         Typ hodnoceni zkusenosti 'positive/negative/none'
	 * @param {Number} data.userId                   Id uzivatele (autora zkusenosti)
	 * @param {String} data.userName                 Jmeno uzivatele (autora zkusenosti)
	 */
	constructor(data) {
		this.createDate = data.createDate
		this.editDate = data.editDate
		this.fuelCb = data.fuelCb
		this.id = data.id
		this.manufacturerCb = data.manufacturerCb
		this.manufacturingYear = data.manufacturingYear
		this.modelCb = data.modelCb
		this.negatives = data.negatives
		this.negativeFeedbackCount = Number(data.negativeFeedbackCount || 0)
		this.positives = data.positives
		this.positiveFeedbackCount = Number(data.positiveFeedbackCount || 0)
		this.power = data.power
		this.review = data.review
		this.reviewReplies = data.reviewReplies || []
		this.repliesCount = Number(data.repliesCount || 0)
		this.title = data.title
		this.type = data.type
		this.userEmailPrefix = data.userEmailPrefix
		this.userFeedbackType = data.userFeedbackType
		this.userId = data.userId
		this.userName = data.userName
	}
}
