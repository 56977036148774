import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Checkbox } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { operatingLeaseDefinitionPropTypes } from 'app/model/operatingLease/OperatingLeasePropTypes'
import OPERATING_LEASE_DEFINITION, {
	getDefinition
} from 'app/component/operatingLease/OperatingLeaseDefinition'

import './OperatingLeaseIntend.less'
import './OperatingLeaseIntendCS.json'

const CLASSNAME = 'c-operating-lease-intend'

const OperatingLeaseIntend = ({
	className,
	operatingLeaseDefinitionData = DEFAULT_PROPS.ARRAY,
	operatingLeaseIntendedFor = DEFAULT_PROPS.ARRAY,
	onOperatingLeaseIntendedForChange = DEFAULT_PROPS.FUNCTION
}) => {
	const localize = useLocalize()

	const { options = [] } = getDefinition(
		operatingLeaseDefinitionData,
		OPERATING_LEASE_DEFINITION.INTENDED_FOR_CB
	)

	const handleCheckboxChange = (event) => {
		const { value: stringValue, checked } = event.target
		const value = Number(stringValue)

		onOperatingLeaseIntendedForChange(
			checked
				? [...operatingLeaseIntendedFor, value]
				: operatingLeaseIntendedFor.filter((v) => v !== value)
		)
	}

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<div className={`${CLASSNAME}__heading`}>{localize('OperatingLeaseIntend.heading')}</div>

			<div className={`${CLASSNAME}__checkboxes`}>
				{options.length
					? options.map(({ id, name, value }) => (
							<Checkbox
								key={id}
								className={`${CLASSNAME}__checkbox`}
								label={name}
								value={value}
								checked={operatingLeaseIntendedFor.includes(value)}
								data-e2e={`operating-lease-intend-${value}`}
								onChange={handleCheckboxChange}
							/>
					  ))
					: [0, 1, 2].map((placeholder, index, placeholders) => (
							<CheckboxPlaceholder
								key={placeholder}
								className={`${CLASSNAME}__checkbox`}
								isLarge={index === placeholders.length - 1}
							/>
					  ))}
			</div>
		</div>
	)
}

OperatingLeaseIntend.propTypes = {
	className: PropTypes.string,
	operatingLeaseDefinitionData: PropTypes.arrayOf(
		PropTypes.shape(operatingLeaseDefinitionPropTypes)
	),
	operatingLeaseIntendedFor: PropTypes.arrayOf(PropTypes.number),
	onOperatingLeaseIntendedForChange: PropTypes.func
}

export default OperatingLeaseIntend

const CheckboxPlaceholder = ({ className, isLarge }) => (
	<div
		className={classnames({
			[`${CLASSNAME}__placeholder`]: true,
			[className]: !!className
		})}
	>
		<div className={`${CLASSNAME}__placeholder-check`} />
		<div
			className={classnames({
				[`${CLASSNAME}__placeholder-text`]: true,
				[`${CLASSNAME}__placeholder-text--large`]: isLarge
			})}
		/>
	</div>
)

CheckboxPlaceholder.propTypes = {
	className: PropTypes.string,
	isLarge: PropTypes.bool
}
