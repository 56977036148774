
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['Catalog'] = {
  search: function(d) { return "Zobrazit " + plural(d.ADVERT_COUNT, 0, cs, { one: d.COUNT_NICE + " inzerát", few: d.COUNT_NICE + " inzeráty", other: d.COUNT_NICE + " inzerátů" }); },
  noResults: function(d) { return "Nenašli jsme žádné inzeráty"; },
  ShowAdvertsPrefix: function(d) { return "Zobrazit"; },
  ShowAdvertsSuffix: function(d) { return "inzerátů"; },
  speedUnit: function(d) { return "km/h"; },
  engineVolumeUnit: function(d) { return "cm3"; },
  titleBrands: function(d) { return "Katalog automobilů - všechny značky aut a specifikace"; },
  descriptionBrands: function(d) { return "Na Sauto.cz najdete specifikace, rozměry a detailní informace pro všechny značky aut, stovky modelů a tísíce aktuálních a historických motorizací a provedení."; },
  titleModels: function(d) { return d.NAME + " modelové řady, specifikace, technické parametry"; },
  descriptionModels: function(d) { return "Přehled " + d.COUNT + " " + plural(d.COUNT, 0, cs, { one: "model", other: "modelů vozu" }) + " značky " + d.NAME + ". V katalogu modelů " + d.NAME + " na Sauto.cz najdete specifikace, rozměry, spotřebu, velikost kufru, technické parametry a další data."; },
  titleModelLine: function(d) { return d.BRAND + " " + d.MODEL + " všechny modely, specifikace, technické parametry"; },
  descriptionModelLine: function(d) { return "Přehled " + d.COUNT + " " + plural(d.COUNT, 0, cs, { one: "model", other: "modelů vozu" }) + " " + d.BRAND + " " + d.MODEL + ". V katalogu " + d.BRAND + " " + d.MODEL + " na Sauto.cz najdete specifikace, rozměry, spotřebu, velikost kufru, technické parametry a další data."; },
  titleModifications: function(d) { return d.BRAND + " " + d.MODIFICATION + " všechny motorizace, specifikace, technické parametry"; },
  descriptionModifications: function(d) { return "Přehled " + d.COUNT + " " + plural(d.COUNT, 0, cs, { one: "motorizace", other: "motorizací" }) + " " + d.BRAND + " " + d.MODIFICATION + ". V katalogu " + d.BRAND + " " + d.MODIFICATION + " na Sauto.cz najdete specifikace, rozměry, spotřebu, velikost kufru, technické parametry a další data."; },
  titleModificationDetailWithInfo: function(d) { return d.BRAND + " " + d.MODEL + " " + d.MODIFICATION + " " + d.INFO + " specifikace, rozměry, technická data"; },
  titleModificationDetailWithoutInfo: function(d) { return d.BRAND + " " + d.MODEL + " " + d.MODIFICATION + " specifikace, rozměry, technická data"; },
  descriptionModificationDetailWithInfo: function(d) { return "Technická data " + d.BRAND + " " + d.MODEL + " " + d.MODIFICATION + " " + d.INFO + ". Na Sauto.cz najdete specifikace, rozměry, spotřebu, objem kufru, technické parametry a další data."; },
  descriptionModificationDetailWithoutInfo: function(d) { return "Technická data " + d.BRAND + " " + d.MODEL + " " + d.MODIFICATION + ". Na Sauto.cz najdete specifikace, rozměry, spotřebu, objem kufru, technické parametry a další data."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	