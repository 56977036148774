
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertGallery'] = {
  inactiveAdvert: function(d) { return "Inzerát již není aktivní"; },
  startSphereView: function(d) { return "Spustit prohlídku"; },
  stopSphereView: function(d) { return "Zavřít virtuální prohlídku"; },
  sphereViewUsage: function(d) { return "Pro ovládání virtuální prohlídky použijte myš"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	