import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './Split.less'

const CLASSNAME = 'c-split'

const Split = (props) => {
	const {
		className,
		renderLeft = DEFAULT_PROPS.FUNCTION,
		renderRight = DEFAULT_PROPS.FUNCTION
	} = props

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<div className={`${CLASSNAME}__left-content`}>{renderLeft()}</div>
			<div className={`${CLASSNAME}__right-content`}>{renderRight()}</div>
		</div>
	)
}

Split.propTypes = {
	className: PropTypes.string,
	renderLeft: PropTypes.func.isRequired,
	renderRight: PropTypes.func.isRequired
}

export default React.memo(Split)
