import React from 'react'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import IMAGES from 'app/base/ImagesConstants'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const CLASSNAME = 'c-rating-stars'

import './RatingStars.less'

const MAX_COUNT = 5

const RatingStars = (props) => {
	const { className = '', starClassName = '', emptyStarClassName = '' } = props
	let { stars = 0 } = props

	// Ošetření pro případ kdyby nám v hodnocení přišla nesmyslná data, někdy chodila -1
	stars = Number(stars)
	if (isNaN(stars) || stars < 0) stars = 0

	const starsFloor = Math.floor(stars)

	const fullStarsArray = [...Array(starsFloor).keys()]

	const isHalfStar = starsFloor < stars

	const emptyStarsCount = MAX_COUNT - Math.ceil(stars)

	const emptyStarsArray = [...Array(emptyStarsCount).keys()]

	const renderStar = (isFull, index) => {
		const classNameAddition = isFull ? 'full' : 'empty'
		const propsClassName = isFull ? starClassName : emptyStarClassName

		return (
			<SvgIcon
				icon='RATING_STAR'
				className={classnames({
					[`${CLASSNAME}__star`]: true,
					[`${CLASSNAME}__star-${classNameAddition}`]: true,
					[propsClassName]: !!propsClassName
				})}
				key={`star-${index}`}
			/>
		)
	}

	return (
		<div
			className={classnames({
				[`${CLASSNAME}__stars`]: true,
				[className]: !!className
			})}
		>
			{fullStarsArray.map((star) => renderStar(true, star))}
			{isHalfStar && (
				<img
					className={classnames({
						[`${CLASSNAME}__star`]: true,
						[starClassName]: !!starClassName
					})}
					src={IMAGES['image__rating-halfstart']}
					alt=''
				/>
			)}
			{emptyStarsArray.map((star) => renderStar(false, star))}
		</div>
	)
}

RatingStars.propTypes = {
	stars: PropTypes.number,
	className: PropTypes.string,
	starClassName: PropTypes.string,
	emptyStarClassName: PropTypes.string
}

export default React.memo(RatingStars)
