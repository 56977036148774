import React from 'react'
import { uuid } from 'uuidv4'
import PremiseService from 'app/model/premise/PremiseService'
import * as FormLines from '@inzeraty/form-lines'
import { FORM_LINES_IDS } from './IAPremiseEditFormLineIds'
import { SIMPLE_DATA_WIDGET_ID } from 'app/page/userweb/clientProfile/widgets/simpleData/SimpleDataWidget'
import { ASK_ID_WIDGET_ID } from './components/askId/AskIdWidget'
import { SELECT_WIDGET_ID } from 'app/page/userweb/clientProfile/widgets/select/SelectWidget'
import { TEXT_WIDGET_ID } from 'app/page/userweb/clientProfile/widgets/text/TextWidget'
import { SOFTWARE_KEY_WIDGET_ID } from 'app/page/userweb/clientProfile/widgets/softwareKey/SoftwareKeyWidget'
import { LOGIN_EMAIL_WIDGET_ID } from './components/loginEmail/LoginEmailWidget'
import ROUTE_NAMES from 'app/base/RouteNames'
import { filterBy, filterByValue } from 'app/component/entitiesFilter/EntitiesFilter'
import md5 from 'md5'
import { IA_WALLET_WIDGET_ID } from './components/wallet/WalletWidget'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { ADMIN_RIGHTS } from 'app/base/Constants'

import './IAPremiseEditCS.json'
import 'app/page/userweb/clientProfile/ClientProfileCS.json'
import 'app/base/BaseCS.json'

export const createIAFormLineEntities = (
	premiseEntity = {},
	softwareKeys,
	walletInfo = {},
	dictionary,
	router,
	rights = []
) => {
	const serviceData = PremiseService.getPremiseDataByService(premiseEntity)
	const { PREMISE_ID } = InternalAdminUrlConvertor.constants

	const { walletLogin } = walletInfo

	return FormLines.createEntityList([
		{
			widget: TEXT_WIDGET_ID,
			id: FORM_LINES_IDS.WALLET_ACOUNT,
			label: dictionary.get('ClientProfile.walletLabelAcount'),
			placeholder: dictionary.get('IAPremiseEdit.walletPlaceholder'),
			value: isNullOrUndefined(walletLogin) ? '' : walletLogin,
			extra: {
				getData: (formValue) => {
					const walletValue = formValue ? formValue : null

					return { wallet_login: walletValue }
				},
				getValidationMessage: (formValue = '') => {
					const isEmpty = formValue.trim() === ''
					/* eslint-disable */
					const re = new RegExp(
						/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/,
						'i'
					)
					/* eslint-enable */
					const isValid = re.test(formValue)
					return !isValid && !isEmpty ? dictionary.get('ClientProfile.walletNotValid') : ''
				}
			}
		},
		{
			widget: IA_WALLET_WIDGET_ID,
			id: FORM_LINES_IDS.WALLET_INFO,
			label: dictionary.get('ClientProfile.walletLabelState')
		},
		{
			widget: ASK_ID_WIDGET_ID,
			id: FORM_LINES_IDS.PREMISE_ID,
			label: dictionary.get('IAPremiseEdit.id'),
			value: premiseEntity.id
		},
		{
			widget: serviceData.externalId ? SIMPLE_DATA_WIDGET_ID : null,
			id: FORM_LINES_IDS.EXTERNAL_ID,
			label: dictionary.get('IAPremiseEdit.externalId'),
			value: serviceData.externalId
		},
		{
			widget: LOGIN_EMAIL_WIDGET_ID,
			id: FORM_LINES_IDS.LOGIN_EMAIL,
			label: dictionary.get('ClientProfile.loginEmail'),
			value: premiseEntity.users.map(({ login }) => ({
				itemId: uuid(), // interni identifikator prihlasovaciho emailu
				login
			})),
			extra: {
				errors: {}
			}
		},
		{
			widget: SIMPLE_DATA_WIDGET_ID,
			id: FORM_LINES_IDS.ADVERT_COUNT,
			label: dictionary.get('IAPremiseEdit.advertCount'),
			value: (
				<a
					href={router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
						[filterBy]: 'premise_id',
						[filterByValue]: premiseEntity.id
					})}
				>
					{premiseEntity.allAdvertsCount}
				</a>
			)
		},
		{
			widget: SIMPLE_DATA_WIDGET_ID,
			id: FORM_LINES_IDS.PRODUCT_LIST,
			label: dictionary.get('IAPremiseEdit.productList'),
			value: (
				<>
					<a
						href={router.link(ROUTE_NAMES.INTERNAL_ADMIN.REGISTRATIONS_LIST, {
							[filterBy]: PREMISE_ID,
							[filterByValue]: premiseEntity.id
						})}
					>
						Registrace
					</a>
					<a
						href={router.link(ROUTE_NAMES.INTERNAL_ADMIN.REGISTRATION_INCRASES_LIST, {
							[filterBy]: PREMISE_ID,
							[filterByValue]: premiseEntity.id
						})}
					>
						Navýšení registrací
					</a>
					<a
						href={router.link(ROUTE_NAMES.INTERNAL_ADMIN.PREFERRED_OFFERS, {
							[filterBy]: PREMISE_ID,
							[filterByValue]: premiseEntity.id
						})}
					>
						Přednostní výpis
					</a>
					<a
						href={router.link(ROUTE_NAMES.INTERNAL_ADMIN.MIRRORING_LIST, {
							[filterBy]: PREMISE_ID,
							[filterByValue]: premiseEntity.id
						})}
					>
						Zrcadlení inzerce
					</a>
				</>
			)
		},
		{
			widget: TEXT_WIDGET_ID,
			id: FORM_LINES_IDS.RETARGETING_ID,
			label: dictionary.get('IAPremiseEdit.retargetingId'),
			value: serviceData.retargetingId,
			extra: {
				type: 'number',
				getData: (formValue) => ({
					retargeting_id: formValue || null
				})
			}
		},
		{
			widget: SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.IS_ACTIVE,
			label: dictionary.get('IAPremiseEdit.isActive'),
			value: serviceData.serviceEnabled,
			disabled: !premiseEntity.enabled,
			extra: {
				values: [
					{ name: dictionary.get('Base.yes'), value: true },
					{ name: dictionary.get('Base.no'), value: false }
				],
				getData: (formValue) => ({
					service_enabled: formValue
				})
			}
		},
		{
			widget: SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.USE_ADVERT_LOCALITY,
			label: dictionary.get('IAPremiseEdit.useAdvertLocality'),
			value: serviceData.useAdvertLocality,
			disabled: !rights.includes(ADMIN_RIGHTS.PREMISE_EDIT_USE_ADVERT_LOCALITY),
			extra: {
				values: [
					{ name: dictionary.get('Base.yes'), value: true },
					{ name: dictionary.get('Base.no'), value: false }
				],
				getData: (formValue) => ({
					use_advert_locality: formValue
				})
			}
		},
		{
			widget: SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.HIDE_VIN,
			label: dictionary.get('IAPremiseEdit.hideVin'),
			value: serviceData.extraData?.hideVin ? serviceData.extraData.hideVin : 'no',
			extra: {
				values: [
					{ name: dictionary.get('Base.no'), value: 'no' },
					{ name: dictionary.get('Base.middle'), value: 'middle' },
					{ name: dictionary.get('Base.end'), value: 'end' }
				],
				getData: (formValue) => ({
					hide_vin: formValue
				})
			}
		},
		{
			widget: SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.HOME_DELIVERY,
			label: dictionary.get('IAPremiseEdit.homeDelivery'),
			value: serviceData.extraData?.homeDelivery ? serviceData.extraData.homeDelivery : false,
			extra: {
				values: [
					{ name: dictionary.get('Base.yes'), value: true },
					{ name: dictionary.get('Base.no'), value: false }
				],
				getData: (formValue) => ({
					home_delivery: formValue
				})
			}
		},

		// Prihlasovaci udaje pro import
		{
			widget: TEXT_WIDGET_ID,
			id: FORM_LINES_IDS.IMPORT_LOGIN,
			label: dictionary.get('IAPremiseEdit.login'),
			value: serviceData.importData ? serviceData.importData.login : '',
			extra: {
				getData: (formValue, { import_data = {} }) => {
					if (formValue) {
						return {
							import_data: { ...import_data, login: formValue }
						}
					} else {
						return {}
					}
				}
			}
		},
		{
			widget: TEXT_WIDGET_ID,
			id: FORM_LINES_IDS.IMPORT_PASSWORD,
			label: dictionary.get('IAPremiseEdit.password'),
			value: '',
			extra: {
				type: 'password',
				getData: (formValue, { import_data = {} }) => {
					if (formValue) {
						return {
							import_data: { ...import_data, password_hash: md5(formValue) }
						}
					} else {
						return {}
					}
				}
			}
		},

		// Softwarove klice
		{
			widget: SOFTWARE_KEY_WIDGET_ID,
			id: FORM_LINES_IDS.SOFTWARE_KEYS,
			label: dictionary.get('IAPremiseEdit.softwareKey'),
			value: premiseEntity.softwareKeys.map(({ id }) => id),
			extra: {
				values: softwareKeys
					.sort((a, b) => {
						return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
					})
					.map(({ name, id }) => ({
						name,
						value: id
					})),
				getData: (formValue) => {
					if (formValue) {
						return { software_keys: formValue }
					} else {
						return {}
					}
				}
			}
		}
	])
}
