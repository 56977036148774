import { Parser } from 'json2csv'
import { DownloadFile, Format } from '@inzeraty/helpers'
import { SERVICE } from 'app/base/Constants'
import PremiseEntity from './PremiseEntity'

export default class PremiseService {
	constructor(
		premiseResource,
		premiseFactoryPublic,
		premiseFactory,
		premiseFactoryExport,
		premiseFactoryHistory,
		premiseFactoryImportLog,
		premiseFactoryImportLogItem,
		paginationFactory,
		premiseWalletFactory
	) {
		this._premiseResource = premiseResource
		this._premiseFactoryPublic = premiseFactoryPublic
		this._premiseFactory = premiseFactory
		this._premiseFactoryExport = premiseFactoryExport
		this._premiseFactoryHistory = premiseFactoryHistory
		this._premiseFactoryImportLog = premiseFactoryImportLog
		this._premiseFactoryImportLogItem = premiseFactoryImportLogItem
		this._paginationFactory = paginationFactory
		this._premiseWalletFactory = premiseWalletFactory

		this._cacheWallet = undefined
	}

	/**
	 * Vrátí data entity pro danou službu.
	 *
	 * @static
	 * @param {PremiseEntity} premiseEntity Premise entita
	 * @param {String}        service       Identifikátor služby, pro kterou chceme vrátit data.
	 * @returns {Object}
	 */
	static getPremiseDataByService(premiseEntity = {}, service = SERVICE) {
		const { premiseServices = [] } = premiseEntity
		const premiseData = premiseServices.find(({ serviceName }) => serviceName === service)
		return premiseData || {}
	}

	/**
	 * Vrátí id firmy. Vybere správnou hodnotu z oldId a id.
	 * (Primárně bere oldId, nové firmy nemají oldId, tak vrací id)
	 *
	 * @static
	 * @param {PremiseEntity|Object} entity
	 * @returns {Number} Id firmy.
	 */
	static getPremiseId(entity) {
		if (entity instanceof PremiseEntity) {
			return entity.oldId || entity.id
		} else {
			const ent = entity || {}
			const id = ent.id
			const oldId = ent.oldId

			return oldId || id
		}
	}

	/**
	 * Vrátí data pro aktuálně nastavenou premiseEntity.
	 * Respektuje nastavení zda se mají brát hodnoty z firem nebo z nastavení pro sauto.
	 *
	 * @static
	 * @param {PremiseEntity} premiseEntity
	 * @returns {Object} Návratová hodnota obsahuje { name }
	 */
	static getPremiseDataForPublic(premiseEntity = {}) {
		const data = PremiseService.getPremiseDataByService(premiseEntity)

		return {
			name: data.useServiceName ? data.name : premiseEntity.name,
			locality: data.useServiceLocality ? data.locality : premiseEntity.locality
		}
	}

	/**
	 * Získá a vrátí firmy.
	 *
	 * @method getPremises
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<Array<PremiseEntity>>} Promise, která vrací firmy.
	 */
	getPremises(params, options) {
		return this._premiseResource.getPremises(params, options).then((response) => {
			const { results, pagination } = response.body
			const premisesData = results.map((item) => this._premiseFactory.transformData(item))

			return {
				premiseEntities: this._premiseFactory.createEntityList(premisesData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	getPremise(id, params, options) {
		return this._premiseResource.getPremise(id, params, options).then((response) => {
			const transformedData = this._premiseFactory.transformData(response.body.result)
			return this._premiseFactory.createEntity(transformedData)
		})
	}

	getPremiseHistory(id, params, options) {
		return this._premiseResource.getPremiseHistory(id, params, options).then((response) => {
			const { results, pagination } = response.body
			const premiseHistoryData = results.map((item) =>
				this._premiseFactoryHistory.transformData(item)
			)

			return {
				premiseHistoryEntities: this._premiseFactoryHistory.createEntityList(premiseHistoryData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	createPremise(params, options) {
		return this._premiseResource.createPremise(params, options)
	}

	patchPremise(id, params) {
		return this._premiseResource.patchPremise(id, params)
	}

	getPublicPremises(params, options) {
		return this._premiseResource.getPublicPremises(params, options).then(({ body = {} }) => {
			const { results = [], pagination = {} } = body
			const premisesData = results.map((item) => this._premiseFactoryPublic.transformData(item))

			return {
				premiseEntities: this._premiseFactoryPublic.createEntityList(premisesData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	getPublicPremise(params, options) {
		return this._premiseResource.getPublicPremise(params, options).then((response) => {
			const transformedData = this._premiseFactoryPublic.transformData(response.body.result)
			return this._premiseFactoryPublic.createEntity(transformedData)
		})
	}

	/**
	 * Provede stažení csv souboru se seznamem firem.
	 *
	 * @public
	 * @returns {Promise}
	 */
	downloadPremisesCSV() {
		return new Promise((resolve, reject) => {
			// Chceme stáhnout všechny firmy, proto tak vysoký limit.
			this._premiseResource
				.getPremisesExport({ limit: 100000, offset: 0 })
				.then((response) => {
					const { results } = response.body
					const premisesData = results.map((item) => this._premiseFactoryExport.transformData(item))
					const premiseEntities = this._premiseFactoryExport.createEntityList(premisesData)

					const premises = premiseEntities.map((premiseEntity) => {
						const {
							id,
							subject = {},
							allAdvertsCount,
							advertReportsCount,
							softwareKeys = [],
							emails = [],
							users = [],
							admins = [],
							registrations = []
						} = premiseEntity

						const [registration = {}] = registrations
						const { date_to: dateTo = '' } = registration

						return {
							'ASK ID': id,
							'Sauto ID': PremiseService.getPremiseDataByService(premiseEntity).oldId || '',
							nazev: PremiseService.getPremiseDataForPublic(premiseEntity).name,
							ic: subject.ico,
							'pocet inzeratu': allAdvertsCount,
							nahlaseni: advertReportsCount,
							obchodnik: admins.map(({ name }) => name).join(', '),
							'importni klic': softwareKeys.map(({ name }) => name).join(', '),
							email: emails.map(({ email }) => email).join(', '),
							'prihlasovaci email': users.map(({ login }) => login).join(', '),
							'aktivni objednavka': dateTo ? 'ano' : 'ne',
							'objednavka do': dateTo ? Format.date(dateTo) : ''
						}
					})

					try {
						const parser = new Parser()
						const csv = parser.parse(premises)

						DownloadFile({
							content: ['\ufeff' + csv], //"\ufeff" je BOM -> pro windows fixne kodovani csv v excelu
							type: 'text/csv',
							fileName: 'companies.csv'
						})

						resolve()
					} catch (error) {
						reject()
					}
				})
				.catch((error) => {
					reject()
				})
		})
	}

	async getPremiseImportLogs(params, options) {
		try {
			const {
				body: { results, pagination } = {}
			} = await this._premiseResource.getPremiseImportLogs(params, options)

			const transformedData = results.map((item) => {
				return Object.assign({}, this._premiseFactoryImportLog.transformData(item), { counts: {} })
			})

			return {
				premiseEntities: this._premiseFactoryImportLog.createEntityList(transformedData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		} catch (error) {
			return {
				premiseEntities: {},
				paginationEntity: {}
			}
		}
	}

	async getPremiseImportLogsCount(params, options) {
		const { body: { results } = {} } = await this._premiseResource.getPremiseImportLogsCount(
			params,
			options
		)

		return results.map((item) => {
			return this._premiseFactoryImportLog.transformData(item)
		})
	}

	getPremiseImportLog(id, params, options) {
		return this._premiseResource.getPremiseImportLog(id, params, options).then((response) => {
			const transformedData = this._premiseFactoryImportLog.transformData(response.body)

			return this._premiseFactoryImportLog.createEntity(transformedData)
		})
	}

	getPremiseImportLogItems(id, params, options) {
		return this._premiseResource.getPremiseImportLogItems(id, params, options).then((response) => {
			const { results, pagination } = response.body
			const transformedData = results.map((item) =>
				this._premiseFactoryImportLogItem.transformData(item)
			)

			return {
				premiseEntities: this._premiseFactoryImportLogItem.createEntityList(transformedData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	addUserToPremise(premiseId, params, options) {
		return this._premiseResource.addUserToPremise(premiseId, params, options)
	}

	removeUserFromPremise(premiseId, userId, params, options) {
		return this._premiseResource.removeUserFromPremise(premiseId, userId, params, options)
	}

	addAdminToPremise(premiseId, params, options) {
		return this._premiseResource.addAdminToPremise(premiseId, params, options)
	}

	sendRegistrationEmail(data, params, options) {
		return this._premiseResource.sendRegistrationEmail(data, params, options)
	}

	getPremiseWallet(id, params = {}, options = {}) {
		const { cache = true } = options

		if (this._cacheWallet && cache) {
			return Promise.resolve(this._cacheWallet)
		} else {
			return this._premiseResource.getPremiseWallet(id, params, options).then((response) => {
				const transformedData = this._premiseWalletFactory.transformData(response.body.result)

				const walletInfo = this._premiseWalletFactory.createEntity(transformedData)

				this._cacheWallet = walletInfo

				return walletInfo
			})
		}
	}

	dissectPremise(id, params = {}, options = {}) {
		return this._premiseResource.dissectPremise(id, params, options)
	}

	agreeWithTerms(id, params = {}, options = {}) {
		return this._premiseResource.agreeWithTerms(id, params, options)
	}
}
