import React, { useState } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import ActionsMenu from 'app/component/actionsMenu/ActionsMenu'
import Report from 'app/component/report/Report'
import { WARNING_24 } from '@sznds/icons'

import './OtherActionsCS.json'
import './OtherActions.less'

const CLASSNAME = 'c-other-actions'

const OtherActions = () => {
	const localize = useLocalize()

	const [isOpen, setIsOpen] = useState(false)
	const [isReportOpen, setIsReportOpen] = useState(false)

	const actions = [
		{
			tagName: 'button',
			icon: WARNING_24,
			text: localize('OtherActions.reportBtn'),
			actionProps: {
				onClick: () => {
					setIsOpen(false)
					setIsReportOpen(!isReportOpen)
				}
			}
		}
	]

	return (
		<div className={CLASSNAME}>
			<ActionsMenu
				modalTitle={localize('OtherActions.moreActions')}
				actions={actions}
				isOpen={isOpen}
				setIsOpenCallback={(newIsOpen) => {
					setIsOpen(newIsOpen)
				}}
			/>
			<Report
				isOpen={isReportOpen}
				setIsOpenCallback={(isOpen) => {
					setIsReportOpen(isOpen)
				}}
			/>
		</div>
	)
}

export default OtherActions
