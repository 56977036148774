import * as FormLines from '@inzeraty/form-lines'
import { DateHelpers } from '@inzeraty/helpers'
import range from 'app/helpers/range/Range'
import {
	CATEGORIES,
	ELECTRIC_VEHICLE_FUEL_OPTION_NAME,
	CAR_CONDITION,
	AVAILABILITY
} from 'app/base/Constants'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import { FORM_LINES_IDS } from './BasicInfoFormLineIds'
import { DEAL_TYPE_WIDGET_ID } from './components/widgets/dealType/DealTypeWidget'
import { LOCALITY_SELECT_WIDGET_ID } from './components/widgets/localitySelect/LocalitySelect'
import { BRAND_SELECT_WIDGET_ID } from './components/widgets/brandSelect/BrandSelect'
import { MODEL_SELECT_WIDGET_ID } from './components/widgets/modelSelect/ModelSelect'
import {
	MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID,
	IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID,
	MONTH_AND_YEAR_SELECT_WIDGET_ID
} from './components/widgets/monthYearSelect/MonthYearSelect'
import { COLOR_AND_TONE_SELECT_WIDGET_ID } from './components/widgets/colorAndToneSelect/ColorAndToneSelect'
import {
	DOORS_AND_CAPACITY_SELECT_WIDGET_ID,
	DOORS_AND_SEATS_SELECT_WIDGET_ID
} from './components/widgets/doorsSelect/DoorsSelect'
import { PHONE_WIDGET_ID } from './components/widgets/phone/PhoneWidget'
import { EMAIL_WIDGET_ID } from './components/widgets/email/EmailWidget'
import { TEXT_WIDGET_ID } from './components/widgets/text/TextWidget'
import { NUMBER_WIDGET_ID } from './components/widgets/number/NumberWidget'
import { TEXTAREA_WIDGET_ID } from './components/widgets/textarea/TextareaWidget'
import { BASIC_SELECT_WIDGET_ID } from './components/widgets/basicSelect/BasicSelectWidget'
import { PRICE_WIDGET_ID } from './components/widgets/price/Price'
import { splitFormLinesIntoSections } from './BasicInfoSections'

import './BasicInfoCS.json'
import 'app/base/BaseCS.json'

const isNullOrUndefined = (value) => value === null || value === undefined

const isAmountValid = (amount, min, max, isRequired) => {
	const isNotNullAndUndefined = !isNullOrUndefined(amount)
	const isInValidRange = amount > min && amount <= max

	if (isRequired) {
		return isNotNullAndUndefined && isInValidRange
	} else {
		if (isNotNullAndUndefined) {
			return isInValidRange
		} else {
			return true
		}
	}
}

const formatDateForServer = (month = 1, year, date = 1) =>
	1 <= month && month <= 12 && year > 0 ? `${year}-${month}-${date}` : undefined

export const createDeliveryFormLineEntities = (
	advertEntity = {},
	isPremise = false,
	isNewVehicleCondition = false,
	dictionary
) => {
	const deliveryDateParsed = DateHelpers.parseDate(advertEntity.deliveryDate)

	return [
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.AVAILABILITY_CB,
			label: dictionary.get('BasicInfo.availabilityLabel'),
			placeholder: dictionary.get('BasicInfo.availabilityPlaceholder'),
			value: advertEntity.availabilityCb,
			extra: {
				getFormData: ({ value } = {}) => ({
					availability_cb: isPremise && isNewVehicleCondition ? value : undefined
				})
			}
		},
		{
			widget: MONTH_AND_YEAR_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.DELIVERY_DATE,
			label: dictionary.get('BasicInfo.deliveryDateLabel'),
			value: deliveryDateParsed
				? [{ value: deliveryDateParsed.month }, deliveryDateParsed.year, deliveryDateParsed.date]
				: undefined,
			required: true,
			options: getOptionsForMonths(dictionary),
			extra: {
				getFormData: ([{ value: month } = {}, year, date] = [], formLineEntities) => ({
					delivery_date:
						isPremise && isNewVehicleCondition && isDateAvailabilitySelected(formLineEntities)
							? formatDateForServer(month, year, date)
							: undefined
				}),
				isFormLineValid: (formLine) => validateDate(formLine)
			}
		}
	]
}

export const createFormLineEntities = (advertEntity = {}, isPremise = false, dictionary) => {
	const { category: { id: categoryId } = {} } = advertEntity

	const manufacturingDateParsed = DateHelpers.parseDate(advertEntity.manufacturingDate)
	const inOperationDateParsed = DateHelpers.parseDate(advertEntity.inOperationDate)
	const stkDateParsed = DateHelpers.parseDate(advertEntity.stkDate)

	const MAX_PRICE = 1000000000
	const MAX_PAYMENT_COUNT = 10000

	const allFormLineEntities = FormLines.createEntityList([
		// sekce Typ inzerce
		{
			widget: DEAL_TYPE_WIDGET_ID,
			id: FORM_LINES_IDS.DEAL_TYPE,
			value: advertEntity.dealType,
			extra: {
				getFormData: (value) => ({
					deal_type: value
				}),
				isFormLineValid: (formLine = {}, formLines, operatingLeaseVariants = []) => {
					const { value } = formLine

					if (value === DEAL_TYPE.OPERATING_LEASE || value === DEAL_TYPE.SALE_OR_LEASE) {
						return operatingLeaseVariants.length > 0
					} else {
						return true
					}
				}
			}
		},
		// sekce Nastaveni operativniho leasingu
		{
			id: FORM_LINES_IDS.OPERATING_LEASE_INTENDED_FOR_CB,
			value: advertEntity.operatingLeaseIntendedForCb,
			extra: {
				getFormData: (formLineValue = []) => ({
					operating_lease_intended_for_cb: formLineValue.map(({ value }) => value)
				})
			}
		},

		// sekce Typ a model vozu
		{
			widget: BRAND_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.MANUFACTURER_CB,
			label: dictionary.get('BasicInfo.manufacturerLabel'),
			placeholder: dictionary.get('BasicInfo.manufacturerPlaceholder'),
			value: advertEntity.manufacturerCb,
			required: true,
			extra: {
				getFormData: ({ value } = {}) => ({
					manufacturer_cb: value
				}),
				isFormLineValid: (formLine) => formLine.value && formLine.value.value >= 0
			}
		},
		{
			widget: MODEL_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.MODEL_CB,
			label: dictionary.get('BasicInfo.modelLabel'),
			placeholder: dictionary.get('BasicInfo.modelPlaceholder'),
			value: advertEntity.modelCb,
			required: true,
			extra: {
				getFormData: ({ value } = {}) => ({
					model_cb: value
				}),
				isFormLineValid: (formLine) => formLine.value && formLine.value.value >= 0
			}
		},
		{
			widget: TEXT_WIDGET_ID,
			id: FORM_LINES_IDS.ADDITIONAL_MODEL_NAME,
			label: dictionary.get('BasicInfo.additionalModelName'),
			placeholder: dictionary.get('BasicInfo.additionalModelPlaceholder'),
			value: advertEntity.additionalModelName,
			extra: {
				limit: 30,
				getFormData: (value) => ({
					additional_model_name: value
				})
			}
		},
		{
			widget: MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.MANUFACTURING_DATE,
			label: dictionary.get('BasicInfo.manufacturingDateLabel'),
			value: manufacturingDateParsed
				? [
						{ value: manufacturingDateParsed.month },
						manufacturingDateParsed.year,
						manufacturingDateParsed.date
				  ]
				: undefined,
			required: true,
			options: getOptionsForMonths(dictionary),
			extra: {
				getFormData: ([{ value: month } = {}, year, date] = []) => ({
					manufacturing_date: formatDateForServer(month, year, date)
				}),
				isFormLineValid: (manufacturingDateFormLine, allFormLineEntities = []) => {
					const inOperationDateFormLine = allFormLineEntities.find(
						(f) => f.id === FORM_LINES_IDS.IN_OPERATION_DATE
					)

					return validateDependentDates(manufacturingDateFormLine, inOperationDateFormLine)
				}
			}
		},
		{
			widget: IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.IN_OPERATION_DATE,
			label: dictionary.get('BasicInfo.inOperationDateLabel'),
			value: inOperationDateParsed
				? [
						{ value: inOperationDateParsed.month },
						inOperationDateParsed.year,
						inOperationDateParsed.date
				  ]
				: undefined,
			options: getOptionsForMonths(dictionary),
			required: true,
			extra: {
				getFormData: ([{ value: month } = {}, year, date] = []) => ({
					in_operation_date: formatDateForServer(month, year, date)
				}),
				isFormLineValid: (inOperationDateFormLine, allFormLineEntities = []) => {
					const manufacturingDateFormLine = allFormLineEntities.find(
						(f) => f.id === FORM_LINES_IDS.MANUFACTURING_DATE
					)

					return validateDependentDates(inOperationDateFormLine, manufacturingDateFormLine)
				}
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.VEHICLE_BODY_CB,
			label: dictionary.get('BasicInfo.vehicleBodyLabel'),
			placeholder: dictionary.get('BasicInfo.vehicleBodyPlaceholder'),
			value: advertEntity.vehicleBodyCb,
			required: true,
			extra: {
				getFormData: ({ value } = {}) => ({
					vehicle_body_cb: value
				}),
				isFormLineValid: (formLine) => formLine.value && formLine.value.value >= 0
			}
		},
		{
			widget: DOORS_AND_CAPACITY_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.DOORS_AND_CAPACITY,
			label: dictionary.get('BasicInfo.doorsAndCapacityLabel'),
			value:
				advertEntity.doors >= 0 || advertEntity.capacity >= 0
					? [{ value: advertEntity.doors }, { value: advertEntity.capacity }]
					: undefined,
			extra: {
				getFormData: ([{ value: doors } = {}, { value: capacity } = {}] = []) => ({
					doors,
					capacity
				})
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.CAPACITY,
			label: dictionary.get('BasicInfo.capacityLabel'),
			placeholder: dictionary.get('BasicInfo.capacityPlaceholder'),
			value: { value: advertEntity.capacity },
			extra: {
				getFormData: ({ value } = {}) => ({
					capacity: value
				})
			}
		},
		{
			widget: DOORS_AND_SEATS_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.DOORS_AND_SEATS,
			label: dictionary.get('BasicInfo.doorsAndSeatsLabel'),
			value:
				advertEntity.doors >= 0 || advertEntity.seatsCb
					? [{ value: advertEntity.doors }, advertEntity.seatsCb]
					: undefined,
			extra: {
				getFormData: ([{ value: doors } = {}, { value: seatsValue } = {}] = []) => ({
					doors,
					seats_cb: seatsValue
				})
			}
		},
		{
			widget: COLOR_AND_TONE_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.COLOR_AND_TONE_CB,
			label: dictionary.get('BasicInfo.colorAndToneLabel'),
			value:
				advertEntity.colorCb || advertEntity.colorToneCb
					? [advertEntity.colorCb, advertEntity.colorToneCb]
					: undefined,
			required:
				categoryId === CATEGORIES.PASSENGER_CARS.id ||
				categoryId === CATEGORIES.UTILITY_CARS.id ||
				categoryId === CATEGORIES.TRAILERS.id ||
				categoryId === CATEGORIES.CAMPER.id ||
				categoryId === CATEGORIES.BUSES.id,
			extra: {
				getFormData: ([{ value: colorValue } = {}, { value: colorToneValue } = {}] = []) => ({
					color_cb: colorValue,
					color_tone_cb: colorToneValue
				}),
				isFormLineValid: (formLine) => {
					const { value: [color = {}] = [], required } = formLine

					// povinna je jenom barva, odstin barvy uz ne
					return required ? color.value >= 0 : true
				}
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.COLOR_TYPE_CB,
			label: dictionary.get('BasicInfo.colorTypeLabel'),
			placeholder: dictionary.get('BasicInfo.colorTypePlaceholder'),
			value: advertEntity.colorTypeCb,
			extra: {
				getFormData: ({ value } = {}) => ({
					color_type_cb: value
				})
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.AIRCONDITION_CB,
			label: dictionary.get('BasicInfo.airconditionLabel'),
			placeholder: dictionary.get('BasicInfo.airconditionPlaceholder'),
			value: advertEntity.airconditionCb,
			extra: {
				getFormData: ({ value } = {}) => ({
					aircondition_cb: value
				})
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.AIRBAGS,
			label: dictionary.get('BasicInfo.airbagsLabel'),
			placeholder: dictionary.get('BasicInfo.airbagsPlaceholder'),
			value: { value: advertEntity.airbags },
			extra: {
				getFormData: ({ value } = {}) => ({
					airbags: value
				})
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.WEIGHT,
			label: dictionary.get('BasicInfo.weightLabel'),
			placeholder: dictionary.get('BasicInfo.weightPlaceholder'),
			value: advertEntity.weight,
			extra: {
				description: dictionary.get('Base.KG'),
				getFormData: (value) => ({
					weight: value
				})
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.LOAD_CAPACITY,
			label: dictionary.get('BasicInfo.loadCapacityLabel'),
			placeholder: dictionary.get('BasicInfo.loadCapacityPlaceholder'),
			value: advertEntity.loadCapacity,
			extra: {
				description: dictionary.get('Base.KG'),
				getFormData: (value) => ({
					load_capacity: value
				})
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.BEDS,
			label: dictionary.get('BasicInfo.bedsLabel'),
			placeholder: dictionary.get('BasicInfo.bedsPlaceholder'),
			value: { value: advertEntity.beds },
			extra: {
				getFormData: ({ value } = {}) => ({
					beds: value
				})
			}
		},

		// sekce Motorizace
		//
		// Nektere formularove prvky se zobrazuji/skryvaji na zaklade vybraneho paliva.
		// Napr. pro elektro vozy je potreba pridat kapacitu akumulatoru nebo dojezd.
		// Vsechny formliny jsou nastavene zde a az potom podle potreby se nezobrazi
		// pri samotnem vykresleni.
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.ENGINE_VOLUME,
			label: dictionary.get('BasicInfo.engineVolumeLabel'),
			placeholder: dictionary.get('BasicInfo.engineVolumePlaceholder'),
			value: advertEntity.engineVolume,
			required:
				categoryId === CATEGORIES.PASSENGER_CARS.id ||
				categoryId === CATEGORIES.UTILITY_CARS.id ||
				categoryId === CATEGORIES.TRUCKS.id ||
				categoryId === CATEGORIES.MOTORCYCLE.id ||
				categoryId === CATEGORIES.QUAD.id ||
				categoryId === CATEGORIES.BUSES.id,
			extra: {
				description: dictionary.get('Base.CCM'),
				getFormData: (value, allFormLineEntities = []) => ({
					engine_volume: isElectricFuelSelected(allFormLineEntities) ? undefined : value
				}),
				isFormLineValid: (formLine) => {
					const { value, required } = formLine
					const isValidRange = (value) => value >= 0 && value <= 25000

					if (required) {
						return !isNullOrUndefined(value) && isValidRange(value)
					} else {
						return isNullOrUndefined(value) ? true : isValidRange(value)
					}
				}
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.BATTERY_CAPACITY,
			label: dictionary.get('BasicInfo.batteryCapacityLabel'),
			placeholder: dictionary.get('BasicInfo.batteryCapacityPlaceholder'),
			value: advertEntity.batteryCapacity,
			extra: {
				description: dictionary.get('Base.batteryCapacity'),
				getFormData: (value, allFormLineEntities = []) => ({
					battery_capacity: isElectricFuelSelected(allFormLineEntities) ? value : undefined
				}),
				isFormLineValid: (formLine) => {
					return isNullOrUndefined(formLine.value)
						? true
						: formLine.value >= 0 && formLine.value <= 1000
				}
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.ENGINE_POWER,
			label: dictionary.get('BasicInfo.enginePowerLabel'),
			placeholder: dictionary.get('BasicInfo.enginePowerPlaceholder'),
			value: advertEntity.enginePower,
			extra: {
				description: dictionary.get('Base.KW'),
				getFormData: (value) => ({
					engine_power: value
				}),
				isFormLineValid: (formLine) => {
					return isNullOrUndefined(formLine.value)
						? true
						: formLine.value >= 0 && formLine.value <= 2000
				}
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.VEHICLE_RANGE,
			label: dictionary.get('BasicInfo.vehicleRangeLabel'),
			placeholder: dictionary.get('BasicInfo.vehicleRangePlaceholder'),
			value: advertEntity.vehicleRange,
			extra: {
				description: dictionary.get('Base.KM'),
				getFormData: (value, allFormLineEntities = []) => ({
					vehicle_range: isElectricFuelSelected(allFormLineEntities) ? value : undefined
				}),
				isFormLineValid: (formLine) => {
					return isNullOrUndefined(formLine.value)
						? true
						: formLine.value >= 0 && formLine.value <= 40000
				}
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.FUEL_CB,
			label: dictionary.get('BasicInfo.fuelLabel'),
			placeholder: dictionary.get('BasicInfo.fuelPlaceholder'),
			value: advertEntity.fuelCb,
			required:
				categoryId === CATEGORIES.PASSENGER_CARS.id ||
				categoryId === CATEGORIES.UTILITY_CARS.id ||
				categoryId === CATEGORIES.TRUCKS.id ||
				categoryId === CATEGORIES.CAMPER.id ||
				categoryId === CATEGORIES.BUSES.id,
			extra: {
				getFormData: ({ value } = {}) => ({
					fuel_cb: value
				}),
				isFormLineValid: (formLine) => {
					const { value, required } = formLine

					if (required) {
						return value && value.value >= 0
					} else {
						return true
					}
				}
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.AVERAGE_GAS_MILEAGE,
			label: dictionary.get('BasicInfo.fuelConsumptionLabel'),
			placeholder: dictionary.get('BasicInfo.fuelConsumptionPlaceholder'),
			value: advertEntity.averageGasMileage,
			extra: {
				description: dictionary.get('Base.gasMileage'),
				getFormData: (value, allFormLineEntities = []) => ({
					average_gas_mileage: isElectricFuelSelected(allFormLineEntities) ? undefined : value
				}),
				isFormLineValid: (formLine) => {
					return isNullOrUndefined(formLine.value)
						? true
						: formLine.value >= 0 && formLine.value <= 1000
				}
			}
		},
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.AVERAGE_ELECTRIC_MILEAGE,
			label: dictionary.get('BasicInfo.fuelConsumptionLabel'),
			placeholder: dictionary.get('BasicInfo.fuelConsumptionPlaceholder'),
			value: advertEntity.averageElectricMileage,
			extra: {
				description: dictionary.get('Base.electricMileage'),
				getFormData: (value, allFormLineEntities = []) => ({
					average_electric_mileage: isElectricFuelSelected(allFormLineEntities) ? value : undefined
				}),
				isFormLineValid: (formLine) => {
					return isNullOrUndefined(formLine.value)
						? true
						: formLine.value >= 0 && formLine.value <= 1000
				}
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.GEARBOX_CB,
			label: dictionary.get('BasicInfo.gearboxLabel'),
			placeholder: dictionary.get('BasicInfo.gearboxPlaceholder'),
			value: advertEntity.gearboxCb,
			extra: {
				getFormData: ({ value } = {}) => ({
					gearbox_cb: value
				})
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.GEARBOX_LEVELS_CB,
			label: dictionary.get('BasicInfo.gearboxLevelsLabel'),
			placeholder: dictionary.get('BasicInfo.gearboxLevelsPlaceholder'),
			value: advertEntity.gearboxLevelsCb,
			extra: {
				getFormData: ({ value } = {}) => ({
					gearbox_levels_cb: value
				})
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.DRIVE_CB,
			label: dictionary.get('BasicInfo.driveLabel'),
			placeholder: dictionary.get('BasicInfo.drivePlaceholder'),
			value: advertEntity.driveCb,
			extra: {
				getFormData: ({ value } = {}) => ({
					drive_cb: value
				})
			}
		},
		// sekce Stav vozu
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.TACHOMETER,
			label: dictionary.get('BasicInfo.tachometerLabel'),
			placeholder: dictionary.get('BasicInfo.tachometerPlaceholder'),
			value: advertEntity.tachometer,
			required:
				categoryId === CATEGORIES.PASSENGER_CARS.id ||
				categoryId === CATEGORIES.UTILITY_CARS.id ||
				categoryId === CATEGORIES.TRUCKS.id ||
				categoryId === CATEGORIES.BUSES.id,
			extra: {
				description: dictionary.get('Base.KM'),
				getFormData: (value) => ({
					tachometer: value
				}),
				isFormLineValid: (formLine) => {
					const { value, required } = formLine
					const isValidRange = (value) => value >= 0

					if (required) {
						return !isNullOrUndefined(value) && isValidRange(value)
					} else {
						return isNullOrUndefined(value) ? true : isValidRange(value)
					}
				}
			}
		},
		...createDeliveryFormLineEntities(
			advertEntity,
			isPremise,
			isNewVehicleConditionSelected(advertEntity),
			dictionary
		),
		{
			widget: NUMBER_WIDGET_ID,
			id: FORM_LINES_IDS.MACHINE_HOURS,
			label: dictionary.get('BasicInfo.machineHoursLabel'),
			placeholder: dictionary.get('BasicInfo.machineHoursPlaceholder'),
			value: advertEntity.machineHours,
			extra: {
				description: dictionary.get('Base.HOURS'),
				getFormData: (value) => ({
					machine_hours: value
				})
			}
		},
		{
			widget: MONTH_AND_YEAR_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.STK_DATE,
			label: dictionary.get('BasicInfo.stkDateLabel'),
			value: stkDateParsed
				? [{ value: stkDateParsed.month }, stkDateParsed.year, stkDateParsed.date]
				: undefined,
			options: getOptionsForMonths(dictionary),
			extra: {
				getFormData: ([{ value: month } = {}, year, date] = []) => ({
					stk_date: formatDateForServer(month, year, date)
				}),
				isFormLineValid: (formLine) => validateDate(formLine)
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.COUNTRY_OF_ORIGIN_CB,
			label: dictionary.get('BasicInfo.countryOfOriginLabel'),
			placeholder: dictionary.get('BasicInfo.countryOfOriginPlaceholder'),
			value: advertEntity.countryOfOriginCb,
			required:
				categoryId === CATEGORIES.PASSENGER_CARS.id ||
				categoryId === CATEGORIES.UTILITY_CARS.id ||
				categoryId === CATEGORIES.TRUCKS.id ||
				categoryId === CATEGORIES.TRAILERS.id ||
				categoryId === CATEGORIES.CAMPER.id ||
				categoryId === CATEGORIES.BUSES.id,
			extra: {
				getFormData: ({ value } = {}) => ({
					country_of_origin_cb: value
				}),
				isFormLineValid: (formLine) => {
					const { value, required } = formLine

					if (required) {
						return value && value.value >= 0
					} else {
						return true
					}
				}
			}
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.DESCRIPTION,
			label: dictionary.get('BasicInfo.descriptionLabel'),
			placeholder: dictionary.get('BasicInfo.descriptionPlaceholder'),
			value: advertEntity.description,
			extra: {
				limit: 1000,
				getFormData: (value) => ({
					description: value
				})
			}
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.WINDSHIELD_NOTE,
			label: dictionary.get('BasicInfo.windshieldNoteLabel'),
			placeholder: dictionary.get('BasicInfo.windshieldNotePlaceholder'),
			value: advertEntity.windshieldNote,
			extra: {
				limit: 100,
				getFormData: (value) => ({
					windshield_note: value
				})
			}
		},
		// sekce Cena
		{
			widget: PRICE_WIDGET_ID,
			id: FORM_LINES_IDS.PRICE,
			label: dictionary.get('BasicInfo.priceLabel'),
			placeholder: dictionary.get('BasicInfo.pricePlaceholder'),
			value: {
				priceOriginal: advertEntity.priceOriginal,
				priceIsVatDeductible: advertEntity.priceIsVatDeductible,
				priceLeasing: advertEntity.priceLeasing,
				pricePayment: advertEntity.pricePayment,
				pricePaymentCount: advertEntity.pricePaymentCount,
				priceIsWithoutVat: advertEntity.priceIsWithoutVat
			},
			required: true,
			extra: {
				getErrorMessages: (formLine) => {
					const {
						value: { priceOriginal, priceLeasing, pricePayment, pricePaymentCount } = {},
						extra: {
							isFormLineValid = () => true,
							isPriceValid = () => true,
							isPriceLeasingValid = () => true,
							areCancelLeasingFieldsValid = () => true
						} = {}
					} = formLine

					if (!isFormLineValid(formLine)) {
						const messages = {
							priceOriginal: isPriceValid(priceOriginal)
								? undefined
								: dictionary.get('BasicInfoForm.priceNotValid'),
							priceLeasing: isPriceLeasingValid(priceLeasing)
								? undefined
								: dictionary.get('BasicInfoForm.priceLeasingNotValid')
						}

						const isCancelLeasingValid = areCancelLeasingFieldsValid(
							pricePayment,
							pricePaymentCount
						)

						return Object.assign(
							{},
							messages,
							!isCancelLeasingValid
								? {
										pricePayment: isAmountValid(pricePayment, 0, MAX_PRICE, true)
											? undefined
											: dictionary.get('BasicInfoForm.pricePaymentNotValid'),
										pricePaymentCount: isAmountValid(pricePaymentCount, 0, MAX_PAYMENT_COUNT, true)
											? undefined
											: dictionary.get('BasicInfoForm.pricePaymentCountNotValid')
								  }
								: {}
						)
					}
				},
				getFormData: (value = {}, allFormLineEntities = []) => {
					const isOnlyOperLeaseSelected = isOnlyOperatingLeaseDealTypeSelected(allFormLineEntities)

					if (isOnlyOperLeaseSelected) {
						return {
							price: undefined,
							price_is_vat_deductible: undefined,
							price_leasing: undefined,
							price_payment: undefined,
							price_payment_count: undefined,
							price_is_without_vat: undefined
						}
					} else {
						// cena nebo odstupne v zavislosti na tom, zda-li je vyplnena
						// vyse splatek a jejich pocet
						const {
							priceOriginal,
							priceIsVatDeductible,
							priceLeasing,
							pricePayment,
							pricePaymentCount,
							priceIsWithoutVat
						} = value

						return {
							price: priceOriginal,
							price_is_vat_deductible: priceIsVatDeductible,
							price_leasing: priceLeasing,
							price_payment: pricePayment,
							price_payment_count: pricePaymentCount,
							price_is_without_vat: priceIsWithoutVat
						}
					}
				},
				isFormLineValid: (formLine) => {
					const {
						value: { priceOriginal, priceLeasing, pricePayment, pricePaymentCount } = {},
						extra: {
							isPriceValid = () => true,
							isPriceLeasingValid = () => true,
							areCancelLeasingFieldsValid = () => true
						} = {}
					} = formLine

					return (
						isPriceValid(priceOriginal) &&
						isPriceLeasingValid(priceLeasing) &&
						areCancelLeasingFieldsValid(pricePayment, pricePaymentCount)
					)
				},
				isPriceValid: (price) => isAmountValid(price, 0, MAX_PRICE, true),
				isPriceLeasingValid: (priceLeasing) => isAmountValid(priceLeasing, 0, MAX_PRICE, false),
				isPricePaymentValid: (pricePayment) => isAmountValid(pricePayment, 0, MAX_PRICE, false),
				isPricePaymentCountValid: (pricePaymentCount) =>
					isAmountValid(pricePaymentCount, 0, MAX_PAYMENT_COUNT, false),
				areCancelLeasingFieldsValid: (pricePayment, pricePaymentCount) =>
					[pricePayment, pricePaymentCount].some((value) => value !== null && value !== undefined)
						? isAmountValid(pricePayment, 0, MAX_PRICE, true) &&
						  isAmountValid(pricePaymentCount, 0, MAX_PAYMENT_COUNT, true)
						: true
			}
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.PRICE_NOTE,
			label: dictionary.get('BasicInfo.priceNoteLabel'),
			value: advertEntity.priceNote,
			extra: {
				limit: 150,
				getFormData: (value, allFormLineEntities = []) => ({
					price_note: isOnlyOperatingLeaseDealTypeSelected(allFormLineEntities) ? undefined : value
				})
			}
		},
		// sekce Kontaktni udaje
		{
			widget: LOCALITY_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.LOCALITY,
			label: dictionary.get('BasicInfo.localityLabel'),
			placeholder: dictionary.get('BasicInfo.localityPlaceholder'),
			value: Object.assign({}, advertEntity.locality, {
				// ujistime se, ze dva nejdulezitejsi prvky jsou
				// nacteny se spravnym jmenem
				entityId: advertEntity.locality.entityId,
				entityType: advertEntity.locality.entityType
			}),
			required: true,
			extra: {
				getFormData: (value = {}) => ({
					locality: {
						entity_id: value.entityId,
						entity_type: value.entityType
					}
				}),
				isFormLineValid: (formLine) => {
					const { value: { entityId, entityType } = {} } = formLine

					return entityId && entityType
				}
			}
		},
		{
			widget: PHONE_WIDGET_ID,
			id: FORM_LINES_IDS.PHONE,
			label: dictionary.get('BasicInfo.phoneLabel'),
			placeholder: dictionary.get('BasicInfo.phonePlaceholder'),
			value: {
				phone: (advertEntity.phone || '').replace(/^(\+420)/, ''), // odstranime tel. predvolbu
				dontShowPhone: advertEntity.dontShowPhone
			},
			required: true,
			extra: {
				isPhoneValid: !!advertEntity.phone,
				getFormData: (value = {}) => ({
					phone: value.phone,
					dont_show_phone: value.dontShowPhone
				}),
				isFormLineValid: (formLine) => {
					const { extra: { isPhoneValid } = {} } = formLine

					if (formLine.value && formLine.value.phone && isPhoneValid) {
						return formLine.value.phone.length > 0
					} else {
						return false
					}
				}
			}
		},
		{
			widget: EMAIL_WIDGET_ID,
			id: FORM_LINES_IDS.EMAIL,
			label: dictionary.get('BasicInfo.emailLabel'),
			placeholder: dictionary.get('BasicInfo.emailPlaceholder'),
			value: advertEntity.email,
			required: true,
			extra: {
				isEmailValid: !!advertEntity.email,
				getFormData: (value) => ({
					email: value
				}),
				isFormLineValid: (formLine) => {
					const { extra: { isEmailValid } = {} } = formLine

					if (formLine.value && isEmailValid) {
						return formLine.value.length > 0
					} else {
						return false
					}
				}
			}
		}
	])

	return filterFormLinesByCategory(categoryId, allFormLineEntities)
}

const filterFormLinesByCategory = (categoryId, allFormLineEntities = []) => {
	const allFormLineEntitiesBySections =
		splitFormLinesIntoSections(categoryId, allFormLineEntities) || {}

	return Object.keys(allFormLineEntitiesBySections).reduce(
		(flatten, sectionId) => flatten.concat(allFormLineEntitiesBySections[sectionId] || []),
		[]
	)
}

const validateDate = (formLine = {}) => {
	const { value: [, year] = [], required } = formLine

	// u datumu staci zadat jen rok, mesic neni povinny
	const isValidYear = (year) => year > 0

	if (required) {
		return !isNullOrUndefined(year) && isValidYear(year)
	} else {
		return isNullOrUndefined(year) ? true : isValidYear(year)
	}
}

// validace pro 'Rok vyroby' a 'V provozu od', kdy jen jedna z polozek je povinna
const validateDependentDates = (firstDateFormLine, secondDateFormLine) => {
	return validateDate(firstDateFormLine) || validateDate(secondDateFormLine)
}

const getOptionsForMonths = (dictionary) => {
	const MONTH_OPTIONS = [
		{ value: 1, name: dictionary.get('Base.January') },
		{ value: 2, name: dictionary.get('Base.February') },
		{ value: 3, name: dictionary.get('Base.March') },
		{ value: 4, name: dictionary.get('Base.April') },
		{ value: 5, name: dictionary.get('Base.May') },
		{ value: 6, name: dictionary.get('Base.June') },
		{ value: 7, name: dictionary.get('Base.July') },
		{ value: 8, name: dictionary.get('Base.August') },
		{ value: 9, name: dictionary.get('Base.September') },
		{ value: 10, name: dictionary.get('Base.October') },
		{ value: 11, name: dictionary.get('Base.November') },
		{ value: 12, name: dictionary.get('Base.December') }
	]

	return MONTH_OPTIONS
}

export const createOptions = (formLineId, { rangeMin, rangeMax } = {}, dictionary) => {
	// preklady pro podporovane formlines
	const SUPPORTED_FORM_LINE_IDS = Object.freeze({
		[FORM_LINES_IDS.AIRBAGS]: 'BasicInfo.airbagsOption',
		[FORM_LINES_IDS.DOORS]: 'BasicInfo.doorsOption',
		[FORM_LINES_IDS.CAPACITY]: 'BasicInfo.capacityOption',
		[FORM_LINES_IDS.BEDS]: 'BasicInfo.bedsOption'
	})

	if (
		SUPPORTED_FORM_LINE_IDS[formLineId] &&
		rangeMin >= 0 &&
		rangeMax >= 0 &&
		rangeMin <= rangeMax
	) {
		return [...range(rangeMin, rangeMax)].map((count) => ({
			value: count,
			name: dictionary.get(SUPPORTED_FORM_LINE_IDS[formLineId], { COUNT: count })
		}))
	} else {
		return []
	}
}

export const getFormData = (filteredFormLineEntities = [], allFormLineEntities) => {
	const allFormData = filteredFormLineEntities.reduce((allFormData, formLineEntity) => {
		const { value, extra: { getFormData } = {} } = formLineEntity

		if (getFormData) {
			return Object.assign(
				{},
				allFormData,
				getFormData(value, allFormLineEntities || filteredFormLineEntities)
			)
		} else {
			/* eslint-disable */
			console.warn(`Field "${f.id}" is missing "getFormData" function.`)
			/* eslint-enable */

			return allFormData
		}
	}, {})

	const formData = Object.keys(allFormData).reduce(
		(formDataProcessed, formKey) =>
			Object.assign({}, formDataProcessed, {
				[formKey]: allFormData[formKey] === undefined ? null : allFormData[formKey]
			}),
		{}
	)

	return formData
}

export const validateFormLines = (formLineEntities = [], operatingLeaseVariants = []) => {
	const invalidFormLines = formLineEntities.filter((formLine) => {
		const { required, extra: { isFormLineValid } = {} } = formLine

		if (required && !isFormLineValid) {
			/* eslint-disable */
			console.warn(`Required field "${formLine.id}" is missing "isFormLineValid" function.`)
			/* eslint-enable */
			return false
		} else if (isFormLineValid) {
			return !isFormLineValid(formLine, formLineEntities, operatingLeaseVariants)
		} else {
			return false
		}
	})

	const isValid = !invalidFormLines.length

	return [isValid, invalidFormLines]
}

const isElectricFuelSelected = (formLineEntities = []) => {
	const fuelFormLineEntity = formLineEntities.find(({ id }) => id === FORM_LINES_IDS.FUEL_CB)

	if (fuelFormLineEntity) {
		const { value, options = [] } = fuelFormLineEntity

		const electricVehicleOption = options.find(
			({ name }) => name === ELECTRIC_VEHICLE_FUEL_OPTION_NAME
		)

		return value && electricVehicleOption && value.value === electricVehicleOption.value
	}

	return false
}

export const filterFormLineEntitiesToShowBasedOnSelection = (
	formLineEntitiesToShow,
	advertEntity,
	isPremise,
	allFormLineEntities
) => {
	let filteredFormLineEntitiesToShow = filterFormLineEntitiesBasedOnSelectedDealType(
		formLineEntitiesToShow,
		allFormLineEntities || formLineEntitiesToShow
	)

	filteredFormLineEntitiesToShow = filterFormLineEntitiesBasedOnSelectedFuel(
		filteredFormLineEntitiesToShow,
		allFormLineEntities || filteredFormLineEntitiesToShow
	)

	return filterFormLineEntitiesBasedOnSelectedCondition(
		filteredFormLineEntitiesToShow,
		isPremise,
		isNewVehicleConditionSelected(advertEntity),
		allFormLineEntities || filteredFormLineEntitiesToShow
	)
}

// pro vozy ciste jen na operativni leasing je potreba schovat standardni formularove
// prvky pro zadani ceny (ty pocitaji s tim, ze se jedna o standardni prodej)
export const filterFormLineEntitiesBasedOnSelectedDealType = (
	formLineEntitiesToShow = [],
	allFormLineEntities = []
) => {
	const isOnlyOperLeaseSelected = isOnlyOperatingLeaseDealTypeSelected(allFormLineEntities)

	if (isOnlyOperLeaseSelected) {
		const STANDARD_PRICE_FIELDS = [FORM_LINES_IDS.PRICE, FORM_LINES_IDS.PRICE_NOTE]

		return formLineEntitiesToShow.filter(({ id }) => !STANDARD_PRICE_FIELDS.includes(id))
	}

	return formLineEntitiesToShow
}

const isOnlyOperatingLeaseDealTypeSelected = (allFormLineEntities = []) => {
	const dealTypeFormLine = allFormLineEntities.find((f) => f.id === FORM_LINES_IDS.DEAL_TYPE)

	if (dealTypeFormLine) {
		const { value } = dealTypeFormLine

		return value === DEAL_TYPE.OPERATING_LEASE
	}

	return false
}

// pro elektricke vozy je potreba nektere standardni formularove prvky schovat (napr. objem motoru) a
// zobrazit specialni prvky jako dojezd, kapacita akumulatoru atd.
export const filterFormLineEntitiesBasedOnSelectedFuel = (
	formLineEntitiesToShow = [],
	allFormLineEntities = []
) => {
	const STANDARD_VEHICLE_FIELDS = [FORM_LINES_IDS.ENGINE_VOLUME, FORM_LINES_IDS.AVERAGE_GAS_MILEAGE]
	const ELECTRIC_VEHICLE_FIELDS = [
		FORM_LINES_IDS.BATTERY_CAPACITY,
		FORM_LINES_IDS.VEHICLE_RANGE,
		FORM_LINES_IDS.AVERAGE_ELECTRIC_MILEAGE
	]

	if (isElectricFuelSelected(allFormLineEntities)) {
		// je vybrane Elektro jako palivo
		return formLineEntitiesToShow.filter(({ id }) => !STANDARD_VEHICLE_FIELDS.includes(id))
	} else {
		return formLineEntitiesToShow.filter(({ id }) => !ELECTRIC_VEHICLE_FIELDS.includes(id))
	}
}

const isNewVehicleConditionSelected = (advertEntity = {}) => {
	const { conditionCb: { value } = {} } = advertEntity

	return value === CAR_CONDITION.NEW.value
}

const isDateAvailabilitySelected = (formLineEntities = []) => {
	const availabilityFormLineEntity = formLineEntities.find(
		({ id }) => id === FORM_LINES_IDS.AVAILABILITY_CB
	)

	if (availabilityFormLineEntity) {
		const { value = {} } = availabilityFormLineEntity

		return value.value === AVAILABILITY.DATE.value
	}

	return false
}

export const filterFormLineEntitiesBasedOnSelectedCondition = (
	formLineEntitiesToShow = [],
	isPremise = false,
	isNewVehicleCondition = false,
	allFormLineEntities = []
) => {
	const DELIVERY_FIELDS = [FORM_LINES_IDS.AVAILABILITY_CB, FORM_LINES_IDS.DELIVERY_DATE]

	if (isPremise && isNewVehicleCondition) {
		if (isDateAvailabilitySelected(allFormLineEntities)) {
			return formLineEntitiesToShow
		} else {
			return formLineEntitiesToShow.filter(({ id }) => id !== FORM_LINES_IDS.DELIVERY_DATE)
		}
	} else {
		return formLineEntitiesToShow.filter(({ id }) => !DELIVERY_FIELDS.includes(id))
	}
}
