import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../AbstractNewAdvertStateKeys'

export default Object.freeze({
	ADVERT_ENTITY: ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY,
	PAYMENTS: 'PAYMENTS',
	PAYMENT_TYPE: 'PAYMENT_TYPE',
	PUBLICATION_LENGTH: 'PUBLICATION_LENGTH',
	TERMS_AND_CONDITIONS_CHECKED: 'TERMS_AND_CONDITIONS_CHECKED',
	SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
	SET_PUBLICATION_LENGTH: 'SET_PUBLICATION_LENGTH',
	SET_TERMS_AND_CONDITIONS: 'SET_TERMS_AND_CONDITIONS',
	HANDLE_PAYMENT: 'HANDLE_PAYMENT',
	IS_SMS_PAYMENT_CHOSEN: 'IS_SMS_PAYMENT_CHOSEN',
	HANDLE_CLOSE_SMS_PAYMENT: 'HANDLE_CLOSE_SMS_PAYMENT',
	HANDLE_SMS_PAYMENT: 'HANDLE_SMS_PAYMENT',
	SMS_URL: 'SMS_URL',
	LOADING: 'LOADING',
	ERROR_MESSAGE: 'ERROR_MESSAGE',
	REFRESH_PAYMENTS: 'REFRESH_PAYMENTS',
	HANDLE_WALLET_PAYMENT: 'HANDLE_WALLET_PAYMENT',
	HANDLE_CREDIT_CARD_PAYMENT: 'HANDLE_CREDIT_CARD_PAYMENT',
	IS_SMS_14_DAYS_PAYMENT_AVAILABLE: 'isSms14daysPaymentAvailable'
})
