import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import FilterConstants from 'app/model/filter/FilterConstants'

class BrandModelFilterLogic extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			brandValue: PropTypes.number,
			context: PropTypes.string.isRequired,
			scopedContext: PropTypes.bool,
			children: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			scopedContext: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			filterUrlParams: props.filterUrlParams,

			filteredAdvertsCountTotal: props.filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal: props.isLoadingFilteredAdvertsCountTotal,

			filteredAdvertsCountForBrands: {},
			isLoadingFilteredAdvertsCountForBrands: false,

			filteredAdvertsCountForModels: {},
			isLoadingFilteredAdvertsCountForModels: false
		}

		this._changeFilter = this._changeFilter.bind(this)

		this._loadFilteredAdvertsCountForBrands = this._loadFilteredAdvertsCountForBrands.bind(this)
		this._loadFilteredAdvertsCountForModels = this._loadFilteredAdvertsCountForModels.bind(this)

		this._calcFilteredAdvertsCountTotal = this._calcFilteredAdvertsCountTotal.bind(this)
	}

	componentDidUpdate(prevProps) {
		const {
			filterUrlParams,
			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal
		} = this.props
		const {
			filterUrlParams: prevFilterUrlParams,
			filteredAdvertsCountTotal: prevFilteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal: prevIsLoadingFilteredAdvertsCountTotal
		} = prevProps

		if (filterUrlParams !== prevFilterUrlParams) {
			this.setState({
				filterUrlParams
			})
		}

		if (filteredAdvertsCountTotal !== prevFilteredAdvertsCountTotal) {
			this.setState({
				filteredAdvertsCountTotal
			})
		}

		if (isLoadingFilteredAdvertsCountTotal !== prevIsLoadingFilteredAdvertsCountTotal) {
			this.setState({
				isLoadingFilteredAdvertsCountTotal
			})
		}
	}

	componentWillUnmount() {
		const {
			setFilteredAdvertsCountTotal: globalSetFilteredAdvertsCountTotal,
			scopedContext
		} = this.props
		const { filteredAdvertsCountTotal } = this.state

		if (scopedContext) {
			globalSetFilteredAdvertsCountTotal(filteredAdvertsCountTotal)
		}
	}

	render() {
		const { children, formLineEntity, categoryEntity } = this.props
		const {
			filteredAdvertsCountForBrands,
			isLoadingFilteredAdvertsCountForBrands,

			filteredAdvertsCountForModels,
			isLoadingFilteredAdvertsCountForModels,

			filterUrlParams,

			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal
		} = this.state

		return children({
			form: {
				validateForm: this._validateModelForm,
				changeFilter: this._changeFilter
			},
			advertsCountForBrands: {
				filteredAdvertsCount: filteredAdvertsCountForBrands,
				isLoadingFilteredAdvertsCount: isLoadingFilteredAdvertsCountForBrands,
				loadFilteredAdvertsCount: this._loadFilteredAdvertsCountForBrands
			},
			advertsCountForModels: {
				filteredAdvertsCountTotal,
				isLoadingFilteredAdvertsCountTotal,
				calcFilteredAdvertsCountTotal: this._calcFilteredAdvertsCountTotal,

				filteredAdvertsCount: filteredAdvertsCountForModels,
				isLoadingFilteredAdvertsCount: isLoadingFilteredAdvertsCountForModels,
				loadFilteredAdvertsCount: this._loadFilteredAdvertsCountForModels
			},
			other: {
				categoryEntity,
				formLineEntity,
				filterUrlParams
			}
		})
	}

	_changeFilter(id, value) {
		const { changeFilter, getFilterUrlParams } = this.props

		changeFilter(id, value, (newFormLineEntity) => {
			const urlParams = getFilterUrlParams(newFormLineEntity)

			this._calcFilteredAdvertsCountTotal(newFormLineEntity)

			//TODO tady se mozna v urcitych pripadech zbytecne pocitaji url parametry
			this.setState({
				filterUrlParams: urlParams
			})
		})
	}

	_validateModelForm() {
		return true
	}

	_loadFilteredAdvertsCountForBrands() {
		const {
			formLineEntity: { id },
			loadFilteredAdvertsCount
		} = this.props

		this.setState(
			{
				isLoadingFilteredAdvertsCountForBrands: true
			},
			async () => {
				try {
					const { counts } = await loadFilteredAdvertsCount(id)

					this.setState({
						filteredAdvertsCountForBrands: counts,
						isLoadingFilteredAdvertsCountForBrands: false
					})
				} catch (e) {
					// na chybu nebudeme nijak reagovat
				}
			}
		)
	}

	_loadFilteredAdvertsCountForModels() {
		const { formLineEntity, brandValue, loadFilteredAdvertsCount, getFilterUrlParams } = this.props

		this.setState(
			{
				isLoadingFilteredAdvertsCountForModels: true
			},
			async () => {
				const newValue = new Map(formLineEntity.value)

				if (brandValue && !newValue.has(brandValue)) {
					newValue.set(brandValue, new Set())
				}

				const newFormLineEntity = Object.assign({}, formLineEntity, {
					value: newValue
				})

				const customUrlParams = getFilterUrlParams(newFormLineEntity)

				try {
					const { counts } = await loadFilteredAdvertsCount(
						FilterConstants.filterIds.MODEL,
						customUrlParams
					)

					this.setState({
						filteredAdvertsCountForModels: counts,
						isLoadingFilteredAdvertsCountForModels: false
					})
				} catch (e) {
					// na chybu nebudeme nijak reagovat
				}
			}
		)
	}

	async _calcFilteredAdvertsCountTotal(newFormLineEntity) {
		const {
			setFilteredAdvertsCountTotal: globalSetFilteredAdvertsCountTotal,
			getFilterUrlParams,
			loadFilteredAdvertsCountTotal,
			categoryEntity,
			scopedContext
		} = this.props

		const setFilteredAdvertsCountTotal = (total) => {
			if (scopedContext) {
				this.setState({
					filteredAdvertsCountTotal: total
				})
			} else {
				globalSetFilteredAdvertsCountTotal(total)
			}
		}

		// nacteme celkovou sumu ze serveru
		const urlParams = getFilterUrlParams(newFormLineEntity)
		const newTotal = await loadFilteredAdvertsCountTotal(urlParams, categoryEntity)
		setFilteredAdvertsCountTotal(newTotal)
	}
}

//TODO pridat shouldComponentUpdate, at se nam porad tato komponenta zbytecne neprekresluje
const selectors = (state) => ({
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS],
	loadFilteredAdvertsCount: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT],
	loadFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.LOAD_FILTERED_ADVERTS_COUNT_TOTAL],
	setFilteredAdvertsCountTotal: state[FiltersExtension.stateIds.SET_FILTERED_ADVERTS_COUNT_TOTAL],
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	filterUrlParams: state[FiltersExtension.stateIds.URL_PARAMS],
	getFilterUrlParams: state[FiltersExtension.stateIds.GET_URL_PARAMS],
	redirect: state[FiltersExtension.stateIds.REDIRECT],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL],
	isLoadingFilteredAdvertsCountTotal:
		state[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(selectors)(BrandModelFilterLogic)
