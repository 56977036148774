
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['OperatingLeaseVariantsList'] = {
  heading: function(d) { return "Varianty operativního leasingu"; },
  noServices: function(d) { return "Bez služeb"; },
  "delete": function(d) { return "Smazat variantu"; },
  deleteError: function(d) { return "Variantu operativního leasingu se nepodařilo smazat."; },
  edit: function(d) { return "Upravit variantu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	