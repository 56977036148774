import React, { useContext } from 'react'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import { useLocalize } from 'app/base/componentHelpers'

import './CreateAdvertLink.less'
import './CreateAdvertLinkCS.json'

const CLASSNAME = 'c-client-create-item-link'

const CreateAdvertLink = () => {
	const localize = useLocalize()
	const context = useContext(Context)
	const router = context.$Router

	return (
		<a className={CLASSNAME} href={router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT)}>
			{localize('CreateAdvertLink.createNewAdvert')}
		</a>
	)
}

export default React.memo(CreateAdvertLink)
