
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['Base'] = {
  CZK: function(d) { return "Kč"; },
  CCM: function(d) { return "ccm"; },
  KM: function(d) { return "km"; },
  KW: function(d) { return "kW"; },
  KG: function(d) { return "kg"; },
  HOURS: function(d) { return "hod."; },
  month: function(d) { return plural(d.MONTH, 0, cs, { one: d.MONTH_FORMATTED + " měsíc", few: d.MONTH_FORMATTED + " měsíce", other: d.MONTH_FORMATTED + " měsíců" }); },
  pricePerMonth: function(d) { return d.CURRENCY + " / měs."; },
  pricePerMonthWithoutVAT: function(d) { return d.CURRENCY + " / měs. bez DPH"; },
  gasMileage: function(d) { return "l / 100 km"; },
  electricMileage: function(d) { return "kWh / 100 km"; },
  batteryCapacity: function(d) { return "kWh"; },
  loading: function(d) { return "Nahrávám"; },
  titlePromo: function(d) { return "Prodej aut, inzerce automobilů"; },
  titleService: function(d) { return " - Sauto.cz"; },
  titleSuffix: function(d) { return " | Sauto.cz"; },
  titleSeparator: function(d) { return " - "; },
  current: function(d) { return " (aktuální)"; },
  keywords: function(d) { return "inzeraty, auta, autobazar"; },
  formEmailLabel: function(d) { return "E-mailová adresa"; },
  formEmailPlaceholder: function(d) { return "@"; },
  formEmailErrorEmpty: function(d) { return "Vyplňte prosím svůj e-mail."; },
  formEmailErrorValue: function(d) { return "Zadejte e-mail ve správném formátu jmeno@domena.cz."; },
  January: function(d) { return "Leden"; },
  February: function(d) { return "Únor"; },
  March: function(d) { return "Březen"; },
  April: function(d) { return "Duben"; },
  May: function(d) { return "Květen"; },
  June: function(d) { return "Červen"; },
  July: function(d) { return "Červenec"; },
  August: function(d) { return "Srpen"; },
  September: function(d) { return "Září"; },
  October: function(d) { return "Říjen"; },
  November: function(d) { return "Listopad"; },
  December: function(d) { return "Prosinec"; },
  close: function(d) { return "Zavřít"; },
  yes: function(d) { return "Ano"; },
  no: function(d) { return "Ne"; },
  selected: function(d) { return "Vybráno"; },
  "delete": function(d) { return "Smazat"; },
  statusMessageSuccess: function(d) { return "Akce se zdařila."; },
  statusMessageError: function(d) { return "Něco se pokazilo, zkuste akci opakovat."; },
  searchBrand: function(d) { return "Hledat značku"; },
  backToHp: function(d) { return "Zpět na Úvodní stránku"; },
  goBackHome: function(d) { return "Zpět na hlavní stranu"; },
  middle: function(d) { return "Uprostřed"; },
  end: function(d) { return "Konec"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	