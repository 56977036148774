import { createEntityList } from '@inzeraty/form-lines'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

export default class IAUserListController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, entitiesFilterExtension, userService) {
		super(dependenciesHelper)

		this._router = this._utils.$Router

		this._entitiesFilterExtension = entitiesFilterExtension
		this._userService = userService

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		})
	}

	async getEntitiesAndPagination() {
		const fetchUserList = async (params) => {
			try {
				const { userEntities, pagination: paginationEntity } = await this._userService.getUserList(
					params
				)

				return {
					entities: userEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = 'id'
		}

		this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchUserList(params)

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	async _filterInitiezerClb(routeParams) {
		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'login',
					name: 'Login'
				},
				{
					value: 'id',
					name: 'RUS ID'
				},
				{
					value: 'phone',
					name: 'Telefon'
				}
			]),
			this._entitiesFilterExtension.getSelect('enabled', 'Stav', 'Vyberte stav', [
				{
					value: '1',
					name: 'Povolený'
				},
				{
					value: '0',
					name: 'Zakázaný'
				}
			])
		])
	}
}
