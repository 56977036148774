import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { CLASSNAME as IA_CLASSNAME } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import classnames from 'classnames'

import './BaseWidget.less'

const CLASSNAME = 'c-ia-base-widget'

const BaseWidget = ({ formLineEntity, labelId, inputId, requiredMark, children, className }) => {
	const { label, required = false, errorMessage = '' } = formLineEntity

	const hasError = Boolean(errorMessage)

	return (
		<div
			className={classnames({
				[className]: !!className,
				[CLASSNAME]: true,
				[IA_CLASSNAME]: true,
				[`${IA_CLASSNAME}--form`]: true
			})}
		>
			<div className={`${IA_CLASSNAME}__input-box`}>
				<label id={labelId} htmlFor={inputId} className={`${IA_CLASSNAME}__label`}>
					{label}
					{required && <span className={`${CLASSNAME}__require-mark`}>{requiredMark}</span>}
				</label>
				{children}
			</div>
			{hasError && <div className={`${IA_CLASSNAME}__error`}>{errorMessage}</div>}
		</div>
	)
}

BaseWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	labelId: PropTypes.string,
	inputId: PropTypes.string.isRequired,
	requiredMark: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string
}

BaseWidget.defaultProps = {
	labelId: '',
	requiredMark: '*',
	className: ''
}

export default React.memo(BaseWidget)
