import AbstractDocumentView from 'app/component/document/AbstractDocumentView'
import React from 'react'
import { PageLoader } from '@inzeraty/components'
import IMAGES from 'app/base/ImagesConstants'
import 'ima-plugin-self-xss/locales/ima-plugin-self-xssCS.json'

/**
 * @class DocumentView
 * @namespace app.component.document
 * @extends app.component.document.AbstractDocumentView
 * @module app
 * @submodule app.component
 */
export default class DocumentView extends AbstractDocumentView {
	_renderHead() {
		const IS_DEV = this.utils.$Settings.$Env === 'dev'

		return (
			<head>
				{this._renderMetaTags()}
				{this._renderTitle()}

				{!IS_DEV && this._getPreloadLinks()}
				{!IS_DEV && this._getPreconnectLinks()}
				{!IS_DEV && this._getDnsPrefetchLinks()}

				{this._renderScripts()}
				{this._renderStyles()}

				{this._renderIcons()}

				{this._renderRobots()}
				{this._renderJSONLD()}

				{this._renderUserFriendlyCookieWall()}
			</head>
		)
	}

	_renderBody() {
		return (
			<body>
				<PageLoader />
				<div
					id={AbstractDocumentView.masterElementId}
					dangerouslySetInnerHTML={{ __html: this.props.page }}
				/>
			</body>
		)
	}

	_renderMetaTags() {
		return (
			<>
				<meta charSet='utf-8' />
				<meta httpEquiv='X-UA-Compatible' content='IE=edge' />
				<meta
					name='viewport'
					content='width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
				/>

				<meta name='description' content={this.props.metaManager.getMetaName('description')} />
				<meta name='keywords' content={this.props.metaManager.getMetaName('keywords')} />

				<meta property='og:title' content={this.props.metaManager.getMetaProperty('og:title')} />
				<meta
					property='og:description'
					content={this.props.metaManager.getMetaProperty('og:description')}
				/>
				<meta property='og:type' content={this.props.metaManager.getMetaProperty('og:type')} />
				<meta property='og:url' content={this.props.metaManager.getMetaProperty('og:url')} />
				<meta property='og:image' content={this.props.metaManager.getMetaProperty('og:image')} />

				<meta name='msapplication-TileColor' content='#ffffff' />
				<meta name='msapplication-TileImage' content={IMAGES['image__favicon-ms-144']} />
				<meta name='msapplication-config' content='/static/img/favicons/browserconfig.xml' />
				<meta name='theme-color' content='#ffffff' />
			</>
		)
	}

	_renderIcons() {
		return (
			<>
				<link rel='shortcut icon' href={IMAGES.image__favicon} />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-57']} sizes='57x57' />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-114']} sizes='114x114' />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-60']} sizes='60x60' />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-120']} sizes='120x120' />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-72']} sizes='72x72' />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-144']} sizes='144x144' />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-76']} sizes='76x76' />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-152']} sizes='152x152' />
				<link rel='apple-touch-icon' href={IMAGES['image__favicon-180']} />
				<link rel='icon' type='image/png' href={IMAGES['image__favicon-256']} sizes='256x256' />
				<link rel='icon' type='image/png' href={IMAGES['image__favicon-128']} sizes='128x128' />
				<link rel='icon' type='image/png' href={IMAGES['image__favicon-90']} sizes='90x90' />
				<link rel='icon' type='image/png' href={IMAGES['image__favicon-60']} sizes='60x60' />
				<link rel='icon' type='image/png' href={IMAGES['image__favicon-48']} sizes='48x48' />
				<link rel='icon' type='image/png' href={IMAGES['image__favicon-32']} sizes='32x32' />
				<link rel='icon' type='image/png' href={IMAGES['image__favicon-16']} sizes='16x16' />
			</>
		)
	}

	_renderTitle() {
		return <title>{this.props.metaManager.getTitle()}</title>
	}

	/**
	 * <script defer type='module' ... />
	 * <script defer nomodule ... />
	 *
	 * Moderni prohlizec vi, ze nemusi stahovat 'nomodule' skripty
	 * a stahne jen prvni
	 *
	 * Starsi prohlizec naopak ignoruje prvni skript, protoze nezna typ
	 * 'module' a stahne jen druhy
	 *
	 * https://jakearchibald.com/2017/es-modules-in-browsers/
	 */
	_renderScripts() {
		const IS_DEV = this.utils.$Settings.$Env === 'dev'

		return (
			<>
				<script
					defer
					id='revivalSettings'
					dangerouslySetInnerHTML={{ __html: this.props.revivalSettings }}
				></script>
				{__WEBPACK_APP_MANIFEST__.js.modern.map((scriptUrl) => {
					return (
						<script
							key={scriptUrl}
							defer
							type={!IS_DEV ? 'module' : false}
							src={scriptUrl}
						></script>
					)
				})}
				{__WEBPACK_APP_MANIFEST__.js.legacy.map((scriptUrl) => {
					return <script key={scriptUrl} defer noModule={!IS_DEV} src={scriptUrl}></script>
				})}
			</>
		)
	}

	_renderStyles() {
		return __WEBPACK_APP_MANIFEST__.css.map((styleUrl) => {
			return <link key='link-styles' rel='stylesheet' href={styleUrl} />
		})
	}

	_getPreloadLinks() {
		return [
			...__WEBPACK_APP_MANIFEST__.css.map((href) => (
				<link key={href} href={href} rel='preload' as='style' />
			))
		]
	}

	_getPreconnectLinks() {
		const scripts = this.utils.$Settings.resources.preconnect

		return scripts.map((url) => <link key={url} rel='preconnect' href={url} />)
	}

	_getDnsPrefetchLinks() {
		const scripts = this.utils.$Settings.resources.dnsPrefetch

		return scripts.map((url) => <link key={url} rel='dns-prefetch' href={url} />)
	}

	_renderRobots() {
		const isSeznamBot = this.props.metaManager.getMetaName('robots') !== ''

		if (isSeznamBot) {
			return (
				<meta
					key='meta-robots'
					name='robots'
					content={this.props.metaManager.getMetaName('robots')}
				/>
			)
		}
	}

	_renderJSONLD() {
		const JSON_LD = this.props.metaManager.getMetaProperty('JSON-LD') || ''

		if (JSON_LD) {
			return (
				<script
					key='json-ld'
					type='application/ld+json'
					dangerouslySetInnerHTML={{
						__html: JSON_LD
					}}
				></script>
			)
		} else {
			return null
		}
	}

	/**
	 * Implementace lidstejsi verze Cookie Wall. I uzivatel, ktery nedal zatim souhlas,
	 * vidi pri prvni navsteve webovku Sauta (neni tedy okamzite presmerovan na cmp.seznam.cz).
	 * Jenom se mu dole zobrazuje drobna Cookie Wall lista. Jakmile uzivatel zacne s
	 * webovkou vic pracovat (= dojde ke zmene URL), tak se mu zobrazi dialog pro udeleni
	 * souhlasu.
	 *
	 * Tyka se zatim jenom vybranych stranek (HP, vypisy), zalezi na nastaveni CW.
	 *
	 * Odkaz na dokumentaci: https://gitlab.seznam.net/szn/cwl/widget
	 */
	_renderUserFriendlyCookieWall() {
		return (
			<>
				{/* defaultne CWL listu schovame, zobrazime ji az podle toho, na jake route jsme */}
				<szn-cwl style={{ visibility: 'hidden' }}></szn-cwl>
				<script
					src={this.utils.$Settings.$App.cwlUrl}
					async='true'
					crossOrigin='anonymous'
				></script>
			</>
		)
	}
}
