import React from 'react'
import AbstractNewAdvertAdminView from '../AbstractNewAdvertAdminView'
import PaymentInfo from './components/PaymentInfo'
import TermsAndConditions from './components/termsAndConditions/TermsAndConditions'
import PAYMENT_STATE_KEYS from './PaymentStateKeys'
import SMSPayment from './sms/SMSPayment'
import AdvertPreview from './components/advertPreview/AdvertPreview'
import FormStepper from '../component/stepper/FormStepperView'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import { STATUS_CONSTANTS } from 'app/model/advert/AdvertConstants'
import { StatusMessage } from '@inzeraty/components'

import '../basicInfo/BasicInfoViewCS.json'
import './PaymentViewCS.json'
import './PaymentView.less'

const CLASSNAME = 'p-uw-payment'

/**
 * Stranka pro vlozeni noveho inzeratu (platba).
 * @class PaymentView
 * @namespace app.page.userweb.newAdvert
 * @extends app.page.useeweb.newAdvert.AbstractNewAdvertAdminView
 * @module app
 * @submodule app.page
 */
export default class PaymentView extends AbstractNewAdvertAdminView {
	constructor(props, context) {
		super(props, context)
	}

	_renderSubheader() {
		const { [PAYMENT_STATE_KEYS.IS_SMS_PAYMENT_CHOSEN]: isSMSPaymentChosen } = this.props

		if (isSMSPaymentChosen) {
			return <div className={`${CLASSNAME}__subheader-sms`}>{super._renderSubheader()}</div>
		} else {
			return super._renderSubheader()
		}
	}

	_getLayoutProps(defaultLayoutProps) {
		return Object.assign({}, defaultLayoutProps, {
			className: CLASSNAME
		})
	}

	_renderStepper() {
		const {
			[PAYMENT_STATE_KEYS.LOADING]: isLoading,
			[PAYMENT_STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED]: isTermsChecked,
			[PAYMENT_STATE_KEYS.ADVERT_ENTITY]: advertEntity
		} = this.props

		const isDraft = advertEntity?.status === STATUS_CONSTANTS.DRAFT

		return (
			<FormStepper
				loading={isLoading}
				nextBtnProps={this._getNextBtnProps()}
				disableNext={!isTermsChecked || isDraft}
				continueBySubmit={false}
			/>
		)
	}

	_renderPaymentContent() {
		return (
			<>
				<div className={`${CLASSNAME}__desktop`}>
					<div className={`${CLASSNAME}__no-wrap`}>
						<div className={`${CLASSNAME}__form`}>
							<PaymentInfo />
							<TermsAndConditions />
						</div>
						<AdvertPreview showCard={true} />
					</div>

					{this._renderStepper()}
				</div>

				<div className={`${CLASSNAME}__mobile`}>
					<PaymentInfo />
					<TermsAndConditions />

					<div className={`${CLASSNAME}__mobile-nav`}>
						<AdvertPreview showCard={false} className={`${CLASSNAME}__preview-button`} />

						{this._renderStepper()}
					</div>
				</div>
			</>
		)
	}

	_getNextBtnProps() {
		const {
			[PAYMENT_STATE_KEYS.LOADING]: isLoading,
			[PAYMENT_STATE_KEYS.PAYMENT_TYPE]: paymentType,
			[PAYMENT_STATE_KEYS.SMS_URL]: smsUrl,
			[PAYMENT_STATE_KEYS.HANDLE_WALLET_PAYMENT]: handleWalletPayment,
			[PAYMENT_STATE_KEYS.HANDLE_CREDIT_CARD_PAYMENT]: handleCreditCardPayment
		} = this.props

		let submitButtonText = ''

		if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_CREDIT_CARD) {
			submitButtonText = this.localize('PaymentView.creditPay')
		} else if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET) {
			submitButtonText = this.localize('PaymentView.walletPay')
		} else if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS) {
			submitButtonText = this.localize('PaymentView.smsPay')
		}

		const buttonProps = {
			text: submitButtonText,
			loading: isLoading
		}

		if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS && smsUrl) {
			buttonProps.href = smsUrl
		} else if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET) {
			buttonProps.onClick = (event) => {
				event.stopPropagation()
				handleWalletPayment()
			}
		} else if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_CREDIT_CARD) {
			buttonProps.onClick = (event) => {
				event.stopPropagation()
				handleCreditCardPayment()
			}
		}

		return buttonProps
	}

	_renderContent() {
		const {
			[PAYMENT_STATE_KEYS.IS_SMS_PAYMENT_CHOSEN]: isSMSPaymentChosen,
			[PAYMENT_STATE_KEYS.ERROR_MESSAGE]: errorMessage,
			[PAYMENT_STATE_KEYS.LOADING]: isLoading,
			[PAYMENT_STATE_KEYS.ADVERT_ENTITY]: advertEntity
		} = this.props

		const isDraft = advertEntity?.status === STATUS_CONSTANTS.DRAFT

		return (
			<div className={`${CLASSNAME}__content`}>
				{isDraft && (
					<StatusMessage
						title={this.localize('PaymentView.draftError')}
						type={StatusMessage.TYPE.ERROR}
						style={StatusMessage.STYLE.INLINE}
						className={`${CLASSNAME}__error-message`}
					/>
				)}
				{isSMSPaymentChosen ? (
					<SMSPayment errorMessage={errorMessage} disabled={isLoading} isDraft={isDraft} />
				) : (
					this._renderPaymentContent()
				)}
			</div>
		)
	}
}
