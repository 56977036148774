import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './ContentTabs.less'

const CLASSNAME = 'c-content-tabs'

const TabItem = ({ active, text, ...tabProps }) => {
	const TabTag = tabProps.hasOwnProperty('href') ? 'a' : 'button'

	return (
		<li className={`${CLASSNAME}__item`}>
			<TabTag
				className={classnames({
					[`${CLASSNAME}__item-cta`]: true,
					[`${CLASSNAME}__item-cta--active`]: active
				})}
				{...tabProps}
			>
				{text}
			</TabTag>
		</li>
	)
}

TabItem.propTypes = {
	active: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired
}

const ContentTabs = ({ activeTabIndex, setActiveTabIndex, className, tabs }) => {
	const tabsProps = tabs.map((tab, index) => {
		const tabProps = Object.assign({}, tab, {
			onClick: () => {
				setStateActiveTabIndex(index)
				setActiveTabIndex(index)
				if (tab.onClick instanceof Function) {
					tab.onClick(index)
				}
			}
		})
		delete tabProps.content

		return tabProps
	})

	const [stateActiveTabIndex, setStateActiveTabIndex] = useState(activeTabIndex)

	useEffect(() => {
		setStateActiveTabIndex(activeTabIndex)
	}, [activeTabIndex])

	return (
		<div
			className={classnames({
				[className]: !!className,
				[CLASSNAME]: true
			})}
		>
			<ul className={`${CLASSNAME}__tabs`}>
				{tabsProps.map((tabProps, index) => {
					return <TabItem active={stateActiveTabIndex === index} key={index} {...tabProps} />
				})}
			</ul>
			<div className={`${CLASSNAME}__content-wrap`}>
				<div className={`${CLASSNAME}__content`}>{tabs[stateActiveTabIndex].content}</div>
			</div>
		</div>
	)
}

ContentTabs.propTypes = {
	activeTabIndex: PropTypes.number,
	className: PropTypes.string,
	setActiveTabIndex: PropTypes.func,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			content: PropTypes.node.isRequired
		})
	).isRequired
}

ContentTabs.defaultProps = {
	activeTabIndex: 0,
	className: '',
	setActiveTabIndex: DEFAULT_PROPS.FUNCTION,
	tabs: DEFAULT_PROPS.ARRAY
}

export default ContentTabs
