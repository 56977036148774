export default class AdvertStatisticsEntity {
	/**
	 * @constructor
	 * @param {Object} data
	 *
	 * @param {String} data.groupBy.dimension Podle ceho se grupuje (premise, datum, kategorie, ...).
	 * @param {Number} data.groupBy.id Identifikator pro dimenzi (napr. pro dimenzi kategorie tady bude id pro osobni, nakladni, ...).
	 * @param {String} data.groupBy.name Jmeno pro dimenzi (napr. pro dimenzi kategorie tady bude Osobni, Nakladni, ...).
	 *
	 * @param {Number} data.metrics.activeAdvert Celkovy pocet inzeratu.
	 * @param {Number} data.metrics.detailView Pocet zobrazeni inzeratu.
	 * @param {Number} data.metrics.detailViewFromList Pocet prokliknuti na inzerat z vypisu.
	 * @param {Number} data.metrics.detailViewFromOther Pocet prokliknuti na inzerat odjinud (napr. z firem.cz).
	 * @param {Number} data.metrics.listView Pocet zobrazeni inzeratu na vypise.
	 * @param {Number} data.metrics.phoneView Pocet zobrazeni telefonu.
	 * @param {Number} data.metrics.emailView Pocet zobrazeni emailu.
	 * @param {Number} data.metrics.replySent Pocet odpovedi na inzerat.
	 * @param {Number} data.metrics.topped Pocet topovanych inzeratu.
	 * @param {Number} data.metrics.toppedPriceSum Celkova suma za topnuti vsech inzeratu.
	 *
	 *
	 * @param {Array<String>} data.customIds Pole importnich idecek k danemu inzeratu (vraci se jen pro dimension: advert_id).
	 * @param {Array<String>} data.vins Pole VINu k danemu inzeratu (vraci se jen pro dimension: advert_id).
	 */
	constructor(data) {
		this.groupBy = data.groupBy
		this.metrics = data.metrics

		if (data.customIds) {
			this.customIds = data.customIds
		}
		if (data.vins) {
			this.vins = data.vins
		}
	}
}
