export default Object.freeze({
	IS_LOADING: 'isLoading',
	IS_OPERATING_LEASE: 'isOperatingLease',
	IS_MIRRORING: 'isMirroring',
	IS_COMPARISON_MODE_ACTIVE: 'isComparisonModeActive',
	KPI_METRICS_TIME_SPAN_1_DATA: 'kpiMetricsTimeSpan1Data',
	KPI_METRICS_TIME_SPAN_1_ERROR: 'kpiMetricsTimeSpan1Error',
	KPI_METRICS_TIME_SPAN_2_DATA: 'kpiMetricsTimeSpan2Data',
	KPI_METRICS_TIME_SPAN_2_ERROR: 'kpiMetricsTimeSpan2Error',
	FIRST_PERIOD_DATES: 'firstPeriodDates',
	SECOND_PERIOD_DATES: 'secondsPeriodDates',
	GRAPH_METRICS_SPAN_1: 'graphMetricsSpan1',
	GRAPH_METRICS_SPAN_1_ERROR: 'graphMetricsSpan1Error',
	GRAPH_METRICS_SPAN_2: 'graphMetricsSpan2',
	GRAPH_METRICS_SPAN_2_ERROR: 'graphMetricsSpan2Error',
	TABLE_TIME_SPAN_1_DATA: 'tableTimeSpan1Data',
	TABLE_TIME_SPAN_1_ERROR: 'tableTimeSpan1Error',
	TABLE_TIME_SPAN_2_DATA: 'tableTimeSpan2Data',
	TABLE_TIME_SPAN_2_ERROR: 'tableTimeSpan2Error'
})
