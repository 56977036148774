import { Parser } from 'json2csv'
import { DownloadFile, Format } from '@inzeraty/helpers'

export default class MirroringService {
	constructor(mirroringResource, mirroringFactory, paginationFactory) {
		this._mirroringResource = mirroringResource
		this._mirroringFactory = mirroringFactory
		this._paginationFactory = paginationFactory
	}

	async getMirrorings(params = {}, options = {}) {
		const response = await this._mirroringResource.getMirrorings(params, options)

		const { results = [], pagination } = response?.body || {}

		const mirroringsData = results.map(this._mirroringFactory.transformData)

		return {
			mirroringEntities: this._mirroringFactory.createEntityList(mirroringsData),
			paginationEntity: this._paginationFactory.createEntity(pagination)
		}
	}

	async downloadMirroringsCSV(params, mirroringStatus = {}) {
		const _getMirroringStatus = (data = {}) => {
			const { status } = data

			return Object.values(mirroringStatus).find((option) => option.id === status)?.name ?? status
		}

		const { mirroringEntities = [] } = await this.getMirrorings(params)

		const mirrorings = mirroringEntities.map((record) => {
			const mirroring = {
				'ID zrcadlení': record.id,
				'Sauto ID': record.premiseExternalId,
				'ASK ID': record.premiseId,
				'Název firmy': record.premiseName,
				Produkt: 'Zrcadlení',
				Stav: _getMirroringStatus(record),
				'Datum od': Format.date(record.dateFrom),
				'Datum do': Format.date(record.dateTo)
			}

			return mirroring
		})

		return this._parseAndDownloadFile(mirrorings, 'mirroring.csv')
	}

	_parseAndDownloadFile(data, filename = '') {
		const parser = new Parser()
		const csv = parser.parse(data)

		DownloadFile({
			content: ['\ufeff' + csv], //"\ufeff" je BOM -> pro windows fixne kodovani csv v excelu
			type: 'text/csv;charset=utf-8;',
			fileName: filename
		})
	}
}
