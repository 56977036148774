import React from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import { Surface } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import operatingLeasePropTypes, {
	operatingLeaseDefinitionPropTypes
} from 'app/model/operatingLease/OperatingLeasePropTypes'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import NewAdvertSectionHeading from 'app/component/newAdvertSectionHeading/NewAdvertSectionHeading'
import OperatingLeaseForm from 'app/component/operatingLease/form/OperatingLeaseForm'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'
import { SECTIONS_ID } from '../BasicInfoSections'
import FORM_STATUS_CODES from '../../component/formStatusMessage/FormStatusCodes'
import { ERROR_CODES, getErrorMessages } from 'app/component/errorMessages/ErrorMessages'
import { SPECIAL_ERROR_TO_FORM_LINE_ID_MAPPING, getErrorDictionary } from './BasicInfoFormErrors'
import {
	validateFormLines,
	filterFormLineEntitiesToShowBasedOnSelection
} from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLines'
import FormStatusMessage from '../../component/formStatusMessage/FormStatusMessage'
import FormStatusMessageWrap from '../../component/formStatusMessage/FormStatusMessageWrap'
import flattenFormLineEntities from 'app/helpers/formLineEntities/flattenFormLineEntities'

import BasicWidget, { BASIC_WIDGET_ID } from '../../component/widgets/basic/BasicWidget'
import BasicSelectWidget, { BASIC_SELECT_WIDGET_ID } from './widgets/basicSelect/BasicSelectWidget'
import BrandSelectWidget, { BRAND_SELECT_WIDGET_ID } from './widgets/brandSelect/BrandSelect'
import ModelSelectWidget, { MODEL_SELECT_WIDGET_ID } from './widgets/modelSelect/ModelSelect'
import MonthYearSelectWidget, {
	MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID,
	IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID,
	MONTH_AND_YEAR_SELECT_WIDGET_ID
} from './widgets/monthYearSelect/MonthYearSelect'
import PriceWidget, { PRICE_WIDGET_ID } from './widgets/price/Price'
import LocalitySelectWidget, {
	LOCALITY_SELECT_WIDGET_ID
} from './widgets/localitySelect/LocalitySelect'
import PhoneWidget, { PHONE_WIDGET_ID } from './widgets/phone/PhoneWidget'
import EmailWidget, { EMAIL_WIDGET_ID } from './widgets/email/EmailWidget'
import TextWidget, { TEXT_WIDGET_ID } from './widgets/text/TextWidget'
import NumberWidget, { NUMBER_WIDGET_ID } from './widgets/number/NumberWidget'
import TextareaWidget, { TEXTAREA_WIDGET_ID } from './widgets/textarea/TextareaWidget'
import ColorAndToneSelectWidget, {
	COLOR_AND_TONE_SELECT_WIDGET_ID
} from './widgets/colorAndToneSelect/ColorAndToneSelect'
import DoorsSelectWidget, {
	DOORS_AND_CAPACITY_SELECT_WIDGET_ID,
	DOORS_AND_SEATS_SELECT_WIDGET_ID
} from './widgets/doorsSelect/DoorsSelect'
import DealTypeWidget from './widgets/dealType/DealTypeWidget'
import FormStepper from '../../component/stepper/FormStepperView'
import BasicInfoPlaceholders from './placeholders/BasicInfoPlaceholders'

import './BasicInfoFormCS.json'
import './BasicInfoForm.less'

const CLASSNAME = 'c-basic-info-form'

const { SZNDS_WIDGET_BY_ID, Form } = FormLines

const WIDGETS_BY_ID = Object.freeze(
	Object.assign(
		{
			[BASIC_WIDGET_ID]: BasicWidget,
			[BASIC_SELECT_WIDGET_ID]: BasicSelectWidget,
			[BRAND_SELECT_WIDGET_ID]: BrandSelectWidget,
			[MODEL_SELECT_WIDGET_ID]: ModelSelectWidget,
			[MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID]: MonthYearSelectWidget,
			[IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID]: MonthYearSelectWidget,
			[MONTH_AND_YEAR_SELECT_WIDGET_ID]: MonthYearSelectWidget,
			[COLOR_AND_TONE_SELECT_WIDGET_ID]: ColorAndToneSelectWidget,
			[DOORS_AND_CAPACITY_SELECT_WIDGET_ID]: DoorsSelectWidget,
			[DOORS_AND_SEATS_SELECT_WIDGET_ID]: DoorsSelectWidget,
			[PRICE_WIDGET_ID]: PriceWidget,
			[LOCALITY_SELECT_WIDGET_ID]: LocalitySelectWidget,
			[PHONE_WIDGET_ID]: PhoneWidget,
			[EMAIL_WIDGET_ID]: EmailWidget,
			[TEXT_WIDGET_ID]: TextWidget,
			[NUMBER_WIDGET_ID]: NumberWidget,
			[TEXTAREA_WIDGET_ID]: TextareaWidget
		},
		SZNDS_WIDGET_BY_ID
	)
)

const BasicInfoForm = (props) => {
	const localize = useLocalize()

	const {
		isPremise,
		formLineEntitiesBySections = {},
		advertEntity,
		isFormBeingSubmitted = false,
		submitForm,
		scrollPageToTop,
		formStatus,
		setFormStatus,
		operatingLeaseDefinitionData,
		operatingLeaseVariants,
		fetchOperatingLeasesVariants,
		addNewOperatingLeaseVariant,
		editOperatingLeaseVariant,
		deleteOperatingLeaseVariant
	} = props

	const allFormLineEntities = flattenFormLineEntities(formLineEntitiesBySections)

	const ERROR_DICTIONARY = getErrorDictionary(localize)

	const renderWidgets = (sectionId) => {
		return FormLines.renderWidgetsByIds(
			filterFormLineEntitiesToShowBasedOnSelection(
				formLineEntitiesBySections[sectionId],
				advertEntity,
				isPremise,
				allFormLineEntities
			),
			{
				onChange
			},
			WIDGETS_BY_ID
		)
	}

	const renderSubmitButton = () => {
		return <FormStepper loading={isFormBeingSubmitted} />
	}

	const renderFormContent = (renderWidgets, renderSubmitButton) => {
		const sections = [
			{
				id: SECTIONS_ID.TYPE_AND_MODEL_SECTION,
				label: localize('BasicInfoForm.typeAndModelSection')
			},
			{
				id: SECTIONS_ID.ENGINE_SECTION,
				label: localize('BasicInfoForm.engineSection')
			},
			{
				id: SECTIONS_ID.VEHICLE_CONDITION_SECTION,
				label: localize('BasicInfoForm.vehicleConditionSection')
			},
			{
				id: SECTIONS_ID.PRICE_SECTION,
				label: localize('BasicInfoForm.priceSection')
			},
			{
				id: SECTIONS_ID.CONTACT_SECTION,
				label: localize('BasicInfoForm.contactSection')
			}
		]

		const areFormLinesLoaded =
			formLineEntitiesBySections &&
			(flattenFormLineEntities(formLineEntitiesBySections) || []).length > 0

		return (
			<>
				<Surface surface={5} className={`${CLASSNAME}__form`}>
					{!isPremise && renderFormStatusMessage()}

					{sections.map(({ id, label }) => {
						const widgetsCount = (formLineEntitiesBySections[id] || []).length

						if (widgetsCount > 0) {
							const widgets = renderWidgets(id)

							return (
								widgets &&
								widgets.length > 0 && (
									<div key={id} className={`${CLASSNAME}__section`}>
										<NewAdvertSectionHeading>{label}</NewAdvertSectionHeading>
										{widgets}
									</div>
								)
							)
						} else if (!areFormLinesLoaded) {
							return <BasicInfoPlaceholders key={id} />
						}

						return null
					})}
				</Surface>

				<div className={`${CLASSNAME}__submit`}>{renderSubmitButton()}</div>
			</>
		)
	}

	const renderFormStatusMessage = () => {
		return (
			<FormStatusMessageWrap>
				{formStatus && formStatus.statusCode ? <FormStatusMessage status={formStatus} /> : null}
			</FormStatusMessageWrap>
		)
	}

	const onChange = (changes) => {
		if (changes instanceof Array) {
			props.onChange && props.onChange(changes)
		} else {
			props.onChange && props.onChange([changes])
		}
	}

	const onSubmit = () => {
		const filteredFormLineEntities = filterFormLineEntitiesToShowBasedOnSelection(
			allFormLineEntities,
			advertEntity,
			isPremise
		)

		const displayedErrors = filteredFormLineEntities.filter((formLine) => !!formLine.errorMessage)
		const [, invalidFields] = validateFormLines(filteredFormLineEntities, operatingLeaseVariants)

		const canBeFormSubmittedToServer = !displayedErrors.length && !invalidFields.length

		if (canBeFormSubmittedToServer) {
			// odesleme zadana data ve formulari na server
			submitForm({
				formLineEntities: allFormLineEntities,
				onErrorCallback: onSubmitFormError
			})
		} else {
			// nastavime chybovou hlasku pro nevyplnene povinne prvky ve formulari
			onClientSideFormError(displayedErrors, invalidFields)
		}
	}

	const onClientSideFormError = (displayedErrorsFormLines, invalidFields) => {
		clearAllPreviousErrors()

		const errorsCount = new Set([
			...displayedErrorsFormLines.map((f) => f.id),
			...invalidFields.map((f) => f.id)
		]).size

		setFormStatus({
			statusCode: FORM_STATUS_CODES.VALIDATION_ERRORS,
			extra: { failedFieldsCount: errorsCount }
		})

		onChange(getErrorMessages(invalidFields, ERROR_DICTIONARY))

		scrollPageToTop()
	}

	const onSubmitFormError = (errors) => {
		clearAllPreviousErrors()

		const formLinesErrors = errors.reduce((processedErrors, newError) => {
			const { error_code: errorCode, fields } = newError

			const moreProcessedErrors = fields
				.map((field) => {
					const id = SPECIAL_ERROR_TO_FORM_LINE_ID_MAPPING[field] || field

					if (ERROR_DICTIONARY[id]) {
						return {
							id,
							errorMessage:
								ERROR_DICTIONARY[id][errorCode] || ERROR_DICTIONARY[id][ERROR_CODES.UNKNOWN]
						}
					} else {
						return undefined
					}
				})
				.filter((err) => !!err)

			return processedErrors.concat(moreProcessedErrors)
		}, [])

		if (formLinesErrors.length) {
			setFormStatus({
				statusCode: FORM_STATUS_CODES.VALIDATION_ERRORS,
				extra: { failedFieldsCount: formLinesErrors.filter((f) => !!f.errorMessage).length }
			})

			onChange(formLinesErrors)
		} else {
			// doslo k nezname chybe, napr. chyba site, ...
			setFormStatus({
				statusCode: FORM_STATUS_CODES.VALIDATION_ERRORS_UNKNOWN
			})
		}

		scrollPageToTop()
	}

	const clearAllPreviousErrors = () => {
		const formLineEntities = flattenFormLineEntities(formLineEntitiesBySections)

		onChange(
			formLineEntities.map(({ id }) => ({
				id,
				errorMessage: undefined
			}))
		)
	}

	const handleOperatingLeaseIntendedForChange = (selectedIntendedForValues = []) => {
		const operLeaseIntendedForFormLineEntity = allFormLineEntities.find(
			(f) => f.id === FORM_LINES_IDS.OPERATING_LEASE_INTENDED_FOR_CB
		)

		if (operLeaseIntendedForFormLineEntity) {
			const { options = [] } = operLeaseIntendedForFormLineEntity

			onChange({
				id: FORM_LINES_IDS.OPERATING_LEASE_INTENDED_FOR_CB,
				value: options.filter((option) => selectedIntendedForValues.includes(option.value))
			})
		}
	}

	const formLineEntitiesWithErrors = filterFormLineEntitiesToShowBasedOnSelection(
		allFormLineEntities,
		advertEntity,
		isPremise
	).filter((f) => !!f.errorMessage)

	const dealTypeFormLineEntity = allFormLineEntities.find((f) => f.id === FORM_LINES_IDS.DEAL_TYPE)
	const isOperatingLeaseSectionVisible = dealTypeFormLineEntity
		? dealTypeFormLineEntity.value === DEAL_TYPE.OPERATING_LEASE ||
		  dealTypeFormLineEntity.value === DEAL_TYPE.SALE_OR_LEASE
		: false

	const operLeaseIntendedForFormLineEntity = allFormLineEntities.find(
		(f) => f.id === FORM_LINES_IDS.OPERATING_LEASE_INTENDED_FOR_CB
	)

	return (
		<div className={CLASSNAME}>
			{isPremise && (
				<Surface surface={5} className={`${CLASSNAME}__deal-type`} data-e2e='deal-type-section'>
					{renderFormStatusMessage()}
					<DealTypeWidget
						formLineEntity={dealTypeFormLineEntity}
						operatingLeaseVariants={operatingLeaseVariants}
						onChange={onChange}
					/>
				</Surface>
			)}

			{isPremise && isOperatingLeaseSectionVisible && (
				<Surface surface={5} className={`${CLASSNAME}__operating-lease`}>
					<OperatingLeaseForm
						operatingLeaseDefinitionData={operatingLeaseDefinitionData}
						operatingLeaseVariants={operatingLeaseVariants}
						operatingLeaseIntendedFor={operLeaseIntendedForFormLineEntity?.value?.map(
							({ value }) => value
						)}
						onOperatingLeaseIntendedForChange={handleOperatingLeaseIntendedForChange}
						fetchOperatingLeasesVariants={fetchOperatingLeasesVariants}
						addNewOperatingLeaseVariant={addNewOperatingLeaseVariant}
						editOperatingLeaseVariant={editOperatingLeaseVariant}
						deleteOperatingLeaseVariant={deleteOperatingLeaseVariant}
					/>
				</Surface>
			)}

			<Form
				formLineEntities={formLineEntitiesWithErrors}
				isSending={isFormBeingSubmitted}
				renderWidgets={renderWidgets}
				renderFormContent={renderFormContent}
				onSubmit={onSubmit}
				renderSubmitButton={renderSubmitButton}
			/>
		</div>
	)
}

BasicInfoForm.propTypes = {
	isPremise: PropTypes.bool,
	formLineEntitiesBySections: PropTypes.shape({
		[PropTypes.string]: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity))
	}),
	advertEntity: PropTypes.shape(advertPropTypes),
	onChange: PropTypes.func,
	isFormBeingSubmitted: PropTypes.bool,
	submitForm: PropTypes.func,
	formStatus: PropTypes.object,
	setFormStatus: PropTypes.func,
	scrollPageToTop: PropTypes.func,
	operatingLeaseDefinitionData: PropTypes.arrayOf(
		PropTypes.shape(operatingLeaseDefinitionPropTypes)
	),
	operatingLeaseVariants: PropTypes.arrayOf(PropTypes.shape(operatingLeasePropTypes)),
	fetchOperatingLeasesVariants: PropTypes.func,
	addNewOperatingLeaseVariant: PropTypes.func,
	editOperatingLeaseVariant: PropTypes.func,
	deleteOperatingLeaseVariant: PropTypes.func
}

export default React.memo(BasicInfoForm)
