export default class OperatingLeaseEntity {
	/**
	 * @constructor
	 * @param {Number} data.id					ID varianty operativniho leasingu
	 * @param {Number} data.period				Doba pronajmu v mesicich
	 * @param {Number} data.annualDistance		Rocni najezd v km
	 * @param {Number} data.priceWithVat		Mesicni cena vcetne DPH
	 * @param {Number} data.priceWithoutVat		Mesicni cena bez DPH
	 * @param {String} data.additionalInfo		Libovolne doplnujici informace
	 * @param {Array<Object>} data.servicesCb	Vybrane sluzby
	 */
	constructor(data) {
		this.id = data.id
		this.period = data.period
		this.annualDistance = data.annualDistance
		this.priceWithVat = data.priceWithVat
		this.priceWithoutVat = data.priceWithoutVat
		this.additionalInfo = data.additionalInfo
		this.servicesCb = data.servicesCb
	}
}
