import { AdminAuth } from '@inzeraty/helpers'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'

export const ROLE_NAME_IDS = Object.freeze({
	ACCOUNT_MANAGER: 'account_manager'
})

export const USER_HISTORY_BLOCKING_ACTIONS = [
	'user_disabled',
	'user_enabled',
	'user_service_disabled',
	'user_service_enabled'
]

export const FORBIDDEN_ROUTES_FOR_ROLE_NAME_IDS = Object.freeze({
	[ROLE_NAME_IDS.ACCOUNT_MANAGER]: [
		ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST_REPORTED,
		ROUTE_NAMES.INTERNAL_ADMIN.SOFTWARE_KEYS,
		ROUTE_NAMES.INTERNAL_ADMIN.ADMIN_LIST,
		ROUTE_NAMES.INTERNAL_ADMIN.ROLE_LIST,
		ROUTE_NAMES.INTERNAL_ADMIN.RIGHTS
	]
})

export default class InternalAdminHelper {
	constructor(router, adminService) {
		this._router = router
		this._adminService = adminService
	}

	getAdminSelf() {
		const urlParams = Object.assign({}, this._router.getCurrentRouteInfo().params)
		const codeParam = urlParams[UrlConvertor.constants.URL_APP_PARAMS.CODE] || ''
		const stateParam = urlParams[UrlConvertor.constants.URL_APP_PARAMS.STATE]
		const loggedAdmin = this._adminService.getLoggedAdmin(
			{},
			{ headers: { 'X-Auth-Code': codeParam } }
		)

		if (typeof loggedAdmin.then === 'function') {
			return loggedAdmin.then((adminEntity) => {
				if (codeParam) {
					// pokud je k dispozici 'code', tak jde o volani ihned po prihlaseni.
					// Provedeme presmerovani na zvolenou URL, pripadne na "homepage" interniho
					// adminu.
					try {
						const decodedStateParam = AdminAuth.decodeStateParam(stateParam) || {}
						const { redirectUrl } = decodedStateParam

						this._router.redirect(
							redirectUrl ? redirectUrl : this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST)
						)
					} catch {
						this._router.redirect(this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST))
					}
				}

				return adminEntity
			})
		} else {
			return loggedAdmin
		}
	}

	getIsRouteForbidden(routeName = '', roleNameId = '') {
		const testRoutesNames = FORBIDDEN_ROUTES_FOR_ROLE_NAME_IDS[roleNameId] || []
		return testRoutesNames.includes(routeName)
	}

	getIsLinkForbidden(href = '', roleNameId = '') {
		if (href) {
			const link = new URL(href)
			const linkPathName = link.pathname

			const forbidenRoutesNames = []
			Object.values(FORBIDDEN_ROUTES_FOR_ROLE_NAME_IDS).forEach((routes) =>
				forbidenRoutesNames.push(...routes.filter((route) => !forbidenRoutesNames.includes(route)))
			)

			const testRoutesName = forbidenRoutesNames.find((routeName) => {
				const routeLink = new URL(this._router.link(routeName))
				const routePathName = routeLink.pathname
				return linkPathName.indexOf(routePathName) >= 0
			})

			return this.getIsRouteForbidden(testRoutesName, roleNameId)
		} else {
			return false
		}
	}
}
