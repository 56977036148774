import UserwebBaseController from 'app/base/UserwebBaseController'
import { HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { DefaultProps } from '@inzeraty/helpers'

import 'app/component/mySavedSearch/MySavedSearchStatusMessageCS.json'

export const STATE_KEYS = Object.freeze({
	IS_LOADING: 'isLoading',
	MY_SAVED_SEARCHES: 'mySavedSearches',
	GET_MY_SAVED_SEARCH: 'getMySavedSearch',
	UPDATE_MY_SAVED_SEARCH: 'updateMySavedSearch',
	SHOW_TOAST_MESSAGE: 'showToastMessage'
})

class MySavedSearchController extends UserwebBaseController {
	constructor(dependenciesHelper, backToHpExtension, mySavedSearchHelper) {
		super(dependenciesHelper)

		this._backToHpExtension = backToHpExtension
		this._mySavedSearchHelper = mySavedSearchHelper

		this._getMySavedSearch = this._getMySavedSearch.bind(this)
		this._updateMySavedSearches = this._updateMySavedSearches.bind(this)
		this._showStatusMessageToast = this._showStatusMessageToast.bind(this)

		this.setIsWholePageAfterLogin()
	}

	init() {
		super.init()

		this.addExtension(this._backToHpExtension)
	}

	load(state = {}) {
		return super.load(
			Object.assign({}, state, {
				[STATE_KEYS.MY_SAVED_SEARCHES]: DefaultProps.ARRAY,
				[STATE_KEYS.IS_LOADING]: true,
				[STATE_KEYS.GET_MY_SAVED_SEARCH]: this._getMySavedSearch,
				[STATE_KEYS.UPDATE_MY_SAVED_SEARCH]: this._updateMySavedSearches,
				[STATE_KEYS.SHOW_TOAST_MESSAGE]: this._showStatusMessageToast
			})
		)
	}

	activate() {
		super.activate()

		this._getMySavedSearchesWithAdvertCounts()

		return {
			[STATE_KEYS.IS_LOADING]: true
		}
	}

	getDataForSsp() {
		return {}
	}

	_showStatusMessageToast(toastConfig) {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, toastConfig)
	}

	async _updateMySavedSearches(
		mySaveSearchToUpdateId,
		notificationInterval,
		ribbonNotificationEnabled
	) {
		await this._mySavedSearchHelper.updateMySavedSearch(
			mySaveSearchToUpdateId,
			notificationInterval,
			ribbonNotificationEnabled
		)

		const { [STATE_KEYS.MY_SAVED_SEARCHES]: mySavedSearches } = this.getState()

		const newMySavedSearches = [...mySavedSearches].map((savedSearch) => {
			if (savedSearch.id === mySaveSearchToUpdateId) {
				return Object.assign({}, savedSearch, { notificationInterval, ribbonNotificationEnabled })
			} else {
				return savedSearch
			}
		})

		this.setState({
			[STATE_KEYS.MY_SAVED_SEARCHES]: newMySavedSearches
		})
	}

	async _getMySavedSearchesWithAdvertCounts() {
		const sortMySavedSearchesFromNewest = (mySavedSearches) =>
			mySavedSearches.sort((mySavedSearch1, mySavedSearch2) => {
				const createDate1 = new Date(mySavedSearch1.createDate)
				const createDate2 = new Date(mySavedSearch2.createDate)

				return createDate2 - createDate1
			})

		const mySavedSearches = await this._mySavedSearchHelper
			.getMySavedSearchesWithAdvertCounts({}, { cache: false })
			.then((result) => sortMySavedSearchesFromNewest(result))
			.catch((error) => undefined) // k chybe muze dojit i pokud uzivatel neni prihlasen

		this.setState({
			[STATE_KEYS.MY_SAVED_SEARCHES]: mySavedSearches,
			[STATE_KEYS.IS_LOADING]: false
		})
	}

	async _getMySavedSearch(mySavedSearchId, options) {
		try {
			// tato metoda se vola pri editaci ulozeneho hledani, aby se ze serveru
			// stahly nejnovejsi data
			return await this._mySavedSearchHelper.getMySavedSearch(mySavedSearchId, options)
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)

			if (httpStatus === HttpStatusCodes.NOT_FOUND) {
				// ulozene hledani jiz bylo smazano, takze upravime seznam ulozenych hledani, ktere uzivatel vidi
				this._removeMySavedSearchFromList(mySavedSearchId)
			}

			// predame vyjimku, at muzeme dal reagovat ve view
			throw error
		}
	}

	async onDeleteMySavedSearch(mySavedSearchId) {
		try {
			await this._mySavedSearchHelper.deleteMySavedSearch(mySavedSearchId)
			this._removeMySavedSearchFromList(mySavedSearchId)

			this._showStatusMessageToast({
				title: this._utils.$Dictionary.get(
					'MySavedSearchStatusMessage.mySavedSearchDeletedSucessMsg'
				),
				type: StatusMessage.TYPE.SUCCESS
			})
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)

			if (httpStatus === HttpStatusCodes.NOT_FOUND) {
				// pes jiz byl vymazan, v tichosti upravime seznam ulozenych hledani, ktere
				// uzivatel vidi
				this._removeMySavedSearchFromList(mySavedSearchId)
			} else {
				this._showStatusMessageToast({
					title: this._utils.$Dictionary.get(
						'MySavedSearchStatusMessage.mySavedSearchDeletedErrorMsg'
					),
					type: StatusMessage.TYPE.ERROR
				})
			}
		}
	}

	_removeMySavedSearchFromList(mySavedSearchId) {
		const { mySavedSearches } = this.getState()

		this.setState({
			mySavedSearches: mySavedSearches.filter(
				(mySavedSearch) => mySavedSearch.id !== mySavedSearchId
			)
		})
	}
}

export default MySavedSearchController
