import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import FilterRow from '../../components/filterRow/FilterRowView'
import { Checkbox } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import FilterConstants from 'app/model/filter/FilterConstants'
import { CategoryEntity } from '@inzeraty/models'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import getRouteName from 'app/helpers/routeName/getRouteName'
import {
	isSpecialSeoMultiOptionFilter,
	getAdvertListSeoLink
} from '../../seo/multiOptionFilterLinks'
import { SEO_MAPPING_FOR_TESTING } from 'app/base/SeoTesting'
import select from 'ima-plugin-select'

import './Multioption.less'
import './MultioptionCS.json'
import 'app/page/userweb/newAdvert/equipment/components/widgets/equipment/EquipmentWidgetCS.json'

const CLASSNAME = 'cf-multioption'

const DEFAULT_CATEGORY_PROP = new CategoryEntity(DEFAULT_PROPS.OBJECT)
const DEFAULT_VISIBLE_ITEMS_COUNT = 6

class Multioption extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			formLineEntity: PropTypes.instanceOf(FormLines.Entity),
			context: PropTypes.string,
			onChange: PropTypes.func,
			filteredAdvertsCount: PropTypes.object,
			isLoadingFilteredAdvertsCount: PropTypes.bool,
			isCountShownWithAddText: PropTypes.bool,
			categoryEntity: PropTypes.instanceOf(CategoryEntity),
			seoAdvertCounts: PropTypes.object,
			showMoreLessButtonWhen: PropTypes.func,
			onShowMoreLessButtonClick: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			className: '',
			formLineEntity: DEFAULT_PROPS.OBJECT,
			onChange: DEFAULT_PROPS.FUNCTION,
			filteredAdvertsCount: DEFAULT_PROPS.OBJECT,
			isLoadingFilteredAdvertsCount: false,
			isCountShownWithAddText: false,
			categoryEntity: DEFAULT_CATEGORY_PROP,
			seoAdvertCounts: DEFAULT_PROPS.OBJECT
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			isCollapsed: true,
			searchValue: ''
		}

		this._renderCheckBox = this._renderCheckBox.bind(this)
		this._onItemChange = this._onItemChange.bind(this)
	}

	render() {
		const { formLineEntity } = this.props
		const { options = [] } = formLineEntity

		return this._renderForm(options)
	}

	_isCompactFilter() {
		const { context } = this.props

		return (
			context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL ||
			context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL_INLINE ||
			context === FILTER_CONTEXT.EXTENDED_FILTER_INLINE
		)
	}

	_renderForm(options) {
		const { className } = this.props

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{this._renderOptions(options)}
			</div>
		)
	}

	_renderOptions(options) {
		const { isCollapsed } = this.state
		const {
			formLineEntity,
			seoAdvertCounts,
			showMoreLessButtonWhen,
			onShowMoreLessButtonClick
		} = this.props

		const sortedOptions = [...options].sort((a, b) => {
			if (a.isFavorite === b.isFavorite) return 0
			if (a.isFavorite === true) return -1
			if (b.isFavorite === true) return 1
		})

		const favoriteOptions = sortedOptions.filter((option) => option.isFavorite)

		const visibleItemsCount = favoriteOptions.length
			? favoriteOptions.length
			: DEFAULT_VISIBLE_ITEMS_COUNT

		const { id } = formLineEntity

		const renderSeoLinkForOption = (option) => {
			const { name, seoName, value } = option
			const { counts: filterCounts = {} } = seoAdvertCounts[id] || {}

			const isLinkToItemsWithCount = Object.keys(filterCounts).length > 0 && filterCounts[value] > 0

			// pre filter stav neodkazujeme pre moznosti "ojete" a "havarovane"
			const isConditionFilter = id === FilterConstants.formLineIds.CONDITION
			const isOptionUsedOrCrashed = value === 2 || value === 3

			const isConditionFilterAndUsedOrCrashedValue = isConditionFilter && isOptionUsedOrCrashed

			if (isLinkToItemsWithCount && !isConditionFilterAndUsedOrCrashedValue) {
				const router = this.utils.$Router
				const link = getAdvertListSeoLink(router, formLineEntity, seoName)
				return (
					link && (
						<a
							className={`${CLASSNAME}__seo-link`}
							href={link}
							data-seoth={SEO_MAPPING_FOR_TESTING[id]}
						>
							{name}
						</a>
					)
				)
			}
		}

		const canBeShowMoreLessButtonUsed =
			FilterConstants.filtersOpenedByDefault.includes(id) || favoriteOptions.length > 0

		const isShowMoreLessButtonUsed = showMoreLessButtonWhen
			? showMoreLessButtonWhen()
			: canBeShowMoreLessButtonUsed &&
			  sortedOptions.length > visibleItemsCount &&
			  this._isCompactFilter()

		const isOperatingLeasePage = [
			ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES,
			ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
		].includes(getRouteName(this.utils.$Router))

		const shouldRenderSeoLink =
			isSpecialSeoMultiOptionFilter(formLineEntity) && !isOperatingLeasePage

		return (
			<div className={`${CLASSNAME}__options`}>
				{sortedOptions.map((option, index) => {
					const shouldRenderCheckbox =
						!isShowMoreLessButtonUsed || !isCollapsed || index < visibleItemsCount

					return (
						<label key={option.value} className={`${CLASSNAME}__label`}>
							{shouldRenderSeoLink && renderSeoLinkForOption(option)}

							{shouldRenderCheckbox && this._renderCheckBox(option, index)}
						</label>
					)
				})}
				{isShowMoreLessButtonUsed && (
					<button
						type='button'
						className={`${CLASSNAME}__show-button`}
						onClick={() => {
							if (onShowMoreLessButtonClick) {
								onShowMoreLessButtonClick()
							} else {
								this.setState((prevState) => ({
									isCollapsed: !prevState.isCollapsed
								}))
							}
						}}
						data-dot='show-more'
					>
						{isCollapsed
							? this.localize('Multioption.showMore')
							: this.localize('Multioption.showLess')}
					</button>
				)}
			</div>
		)
	}

	_renderCheckBox(option, index) {
		const {
			formLineEntity,
			filteredAdvertsCount,
			isLoadingFilteredAdvertsCount,
			isCountShownWithAddText,
			categoryEntity
		} = this.props
		const { value: itemValue, id } = formLineEntity
		const { value, name, seoName, colorCode = '' } = option

		const isChecked = itemValue.length === 0 ? false : itemValue.has(value)

		return (
			<FilterRow
				count={filteredAdvertsCount[value]}
				isCountShownWithAddText={isCountShownWithAddText}
				isCountLoading={isLoadingFilteredAdvertsCount}
				label={option.name}
				dataDot='choose-value'
				index={index}
				dataDotDataType={id}
				filterId={id}
				colorCode={colorCode}
				seoName={seoName}
				isHpFilter={!this._isCompactFilter()}
				categoryEntity={categoryEntity}
			>
				<Checkbox
					className={`${CLASSNAME}__checkbox`}
					checked={isChecked}
					value={option.value}
					onChange={(event) => this._onItemChange(event, name)}
				/>
			</FilterRow>
		)
	}

	_onItemChange(event, name) {
		event.stopPropagation()
		const { formLineEntity, onChange } = this.props
		const { value: stringValue, checked } = event.target
		const { value: itemValue, id, options = [] } = formLineEntity
		const value = Number(stringValue)

		const selectedItems = new Map(itemValue)

		if (!selectedItems.has(value) && checked) {
			selectedItems.set(value, name)
		} else if (selectedItems.has(value) && !checked) {
			selectedItems.delete(value)
		}

		if (id === FilterConstants.formLineIds.AIR_CONDITIONING) {
			const AUTOMATIC_AIR_CON_VALUE = 3
			const AUTOMATIC_TWO_ZONE_AIR_CON_VALUE = 4
			const AUTOMATIC_THREE_ZONE_AIR_CON_VALUE = 5
			const AUTOMATIC_FOUR_ZONE_AIR_CON_VALUE = 6

			if (checked && value === AUTOMATIC_AIR_CON_VALUE) {
				// prave byla vybrana pouze automaticka klimatizace, nastavime i dalsi typy
				// automaticke klimatizace jako dvouzonova atd.
				const optionValuesToSet = [
					AUTOMATIC_TWO_ZONE_AIR_CON_VALUE,
					AUTOMATIC_THREE_ZONE_AIR_CON_VALUE,
					AUTOMATIC_FOUR_ZONE_AIR_CON_VALUE
				]

				optionValuesToSet.forEach((optionValue) => {
					const option = options.find((o) => o.value === optionValue)

					if (option) {
						selectedItems.set(option.value, option.name)
					}
				})
			}
		}

		onChange(id, selectedItems)
	}
}

const selectors = (state) => ({
	seoAdvertCounts: {
		[FilterConstants.formLineIds.VEHICLE_BODY]: state.seoVehicleBodyWithAdvertsValues,
		[FilterConstants.formLineIds.FUEL]: state.seoFuelWithAdvertsValues,
		[FilterConstants.formLineIds.CONDITION]: state.seoConditionWithAdvertsValues,
		[FilterConstants.formLineIds.COUNTRY_OF_ORIGIN]: state.seoCountryOfOriginWithAdvertsValues,
		[FilterConstants.formLineIds.VENDOR]: state.seoVendorWithAdvertsValues,
		[FilterConstants.formLineIds.CERTIFIED_PROGRAM]: state.seoCertifiedProgramWithAdvertsValues
	}
})

export default select(selectors)(Multioption)
