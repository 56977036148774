import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ImaContext from 'ima/page/context'
import { RESPONSIVE } from 'app/base/Constants'
import SspCollocationOptions from 'app/helpers/sspCollocation/SspCollocationOptions'
import SspContext from '../SspContext'
import SspHelper from 'app/helpers/sspHelper/SspHelper'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'

const CLASSNAME = 'c-ssp-sky-component'

import './SkyscraperComponent.less'

const SkyscraperComponent = (props) => {
	const {
		id,
		zoneId,
		callAdd,
		callbackFunc,
		dependencyArrayItem,
		minWidthBreakpoint = RESPONSIVE.DESKTOP_WIDE
	} = props

	const imaContext = useContext(ImaContext)
	const sspContextData = useContext(SspContext)
	const userEntity = useContext(UserEntityContext)

	// priznak, zda si uzivatel plati "Seznam bez reklam" (SBR)
	const isSbrUser = userEntity?.isSbr

	const sspHelper = imaContext.SspHelper

	const rootElm = useRef(null)

	const getAdForSsp = (pageData) => {
		const { current } = rootElm

		if (current) {
			sspHelper.cleanElement(current)
			current.classList.remove(SspHelper.adFullClassName)
		}

		const { matches } = window.matchMedia(`(min-width: ${minWidthBreakpoint / 16}em)`)
		const options = SspCollocationOptions.getOptions(pageData)

		if (matches && options && callAdd) {
			sspHelper.getAds([
				{
					id,
					zoneId,
					width: 300,
					height: 600,
					options,
					callback: (advert, zone) => {
						sspHelper.writeAd(advert, zone)

						callbackFunc && callbackFunc()
					}
				}
			])
		}
	}

	useEffect(() => {
		getAdForSsp(sspContextData)
	}, [dependencyArrayItem])

	if (isSbrUser) return null

	return <div id={id} ref={rootElm} className={CLASSNAME}></div>
}

SkyscraperComponent.propTypes = {
	id: PropTypes.number.isRequired,
	zoneId: PropTypes.number.isRequired,
	callAdd: PropTypes.bool.isRequired,
	callbackFunc: PropTypes.func,
	dependencyArrayItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	minWidthBreakpoint: PropTypes.number
}

export default SkyscraperComponent
