import BaseController from 'app/base/BaseController'
import { Events as ImaLoginEvents } from '@ima/plugin-login'
import GenericError from 'ima/error/GenericError'
import { UserService } from '@inzeraty/models'
import { HttpStatusCodes } from '@inzeraty/helpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import { ASSIGN_EMAIL_EVENTS } from '@inzeraty/components'

import 'app/base/AssignEmailReasonsCS.json'

const CWL_CLICK_SCRIPT_ID = 'cwl-click-script'

/**
 * @class UserwebBaseController
 * @namespace app.base
 * @extends app.base.BaseController
 * @module app
 * @submodule app.base
 */
export default class UserwebBaseController extends BaseController {
	static get EVENT() {
		return {
			USER_SELF_UPDATED: 'userSelfUpdatedEvent'
		}
	}
	/**
	 * @constructor
	 * @method constructor
	 * @param {ns.app.helpers.dependenciesHelper.DependenciesHelper} dependenciesHelper
	 */
	constructor(dependenciesHelper) {
		super(dependenciesHelper)

		this._utils = this._dependenciesHelper.getDependency('utils')
		this._userService = this._dependenciesHelper.getDependency('userService')
		this._premiseService = this._dependenciesHelper.getDependency('premiseService')
		this._internalAdminHelper = this._dependenciesHelper.getDependency('internalAdminHelper')
		this._seoService = this._dependenciesHelper.getDependency('seoService')

		this._handleUserBadge = this._handleUserBadge.bind(this)
		this._handleUserLogged = this._handleUserLogged.bind(this)
		this._handleUserLoggedOut = this._handleUserLoggedOut.bind(this)
		this._handleUserForget = this._handleUserForget.bind(this)
		this.getRouteName = this.getRouteName.bind(this)
		this._handleUpdateUserSelf = this._handleUpdateUserSelf.bind(this)

		this._userEntityPromise = Promise.resolve()

		this._autoScrollManager = this._dependenciesHelper.getDependency('autoScrollManager')

		this._utils.$Dispatcher.listen(ImaLoginEvents.BADGE, this._handleUserBadge)

		// TODO - fallback pro medailonek pri prechodu ze slabsi sluzby (sauto) na silnejsi (klientska zone)
		this._userIdBeforeForget = ''

		// Určuje jeslti je celá stránka zobrazitelná až po přihlášení
		this._isWholePageAfterLogin = false
	}

	init() {
		super.init()

		if (this._autoScrollManager) {
			this._autoScrollManager.init()
		}
	}

	static get STATE_KEYS() {
		return {
			USER_SELF: 'userSelf',
			DATA_FOR_SSP: 'dataForSsp',
			HTTP_STATUS: 'httpStatus',
			SEO_FOOTER_LINKS: 'seoFooterLinks'
		}
	}

	setIsWholePageAfterLogin(isWholePageAfterLogin = true) {
		this._isWholePageAfterLogin = isWholePageAfterLogin
	}

	load(state = {}) {
		const seoFooterLinksPromise = this._seoService
			.getCachedSeoFooterLinks(
				{},
				{
					ttl: 10 * 60 * 1000, // - 10 minut
					cache: true
				}
			)
			.then((seoFooterLinks) => {
				let links = {}

				if (typeof seoFooterLinks === 'object') {
					links = seoFooterLinks
				} else if (typeof seoFooterLinks === 'string') {
					try {
						links = JSON.parse(seoFooterLinks)
					} catch (e) {
						links = {}
					}
				}

				const { default: seoLinks = {} } = links

				return seoLinks
			})
			.catch((error) => {
				return {}
			})

		const superState = Object.assign(
			{
				[UserwebBaseController.STATE_KEYS.USER_SELF]: this._getUserSelfState(),
				[UserwebBaseController.STATE_KEYS.DATA_FOR_SSP]: this.getDataForSsp(),
				[UserwebBaseController.STATE_KEYS.SEO_FOOTER_LINKS]: seoFooterLinksPromise
			},
			state
		)

		if (this._isInternalAdminRoute()) {
			Object.assign(superState, {
				[AbstractInternalAdminController.stateId
					.ADMIN_SELF]: this._internalAdminHelper.getAdminSelf()
			})
		}

		if (this._isWholePageAfterLogin) {
			this._setHttpStatusCode()
		}

		return super.load(superState)
	}

	update(prevParams = {}, state = {}) {
		return Object.assign(
			{
				[UserwebBaseController.STATE_KEYS.DATA_FOR_SSP]: this.getDataForSsp()
			},
			state
		)
	}

	activate() {
		super.activate()

		if (this._autoScrollManager) {
			this._autoScrollManager.activated()
		}

		// flag for cypress that web is ready for testing
		window.readyForCypress = true

		this._utils.$Dispatcher.listen(ImaLoginEvents.LOGIN, this._handleUserLogged)
		this._utils.$Dispatcher.listen(ImaLoginEvents.LOGOUT, this._handleUserLoggedOut)
		this._utils.$Dispatcher.listen(ImaLoginEvents.FORGET, this._handleUserForget)

		this._utils.$Dispatcher.listen(ASSIGN_EMAIL_EVENTS.UPDATE_USER_SELF, this._handleUpdateUserSelf)

		// Pokud máme uživatelovo rusId, tak je potřeba jej nastavit do analytiky.
		this._userEntityPromise.then((userEntity = {}) => {
			this._setDotRus(userEntity)
			// TODO - fallback pro medailonek
			this._userIdBeforeForget = this._getLoggedUserId(userEntity)
		})

		this._loadCwlClickScript()
	}

	async onRedirectToNewAdvertOrPromo() {
		const useEntity = await this._getUserSelfState()

		const url = this._utils.$Router.link(
			UserService.isUserLogged(useEntity)
				? ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT
				: ROUTE_NAMES.USERWEB.PROMO_INSERTION
		)
		this._utils.$Router.redirect(url)
	}

	deactivate() {
		super.deactivate()

		this._utils.$Dispatcher.unlisten(ImaLoginEvents.LOGIN, this._handleUserLogged)
		this._utils.$Dispatcher.unlisten(ImaLoginEvents.LOGOUT, this._handleUserLoggedOut)
		this._utils.$Dispatcher.unlisten(ImaLoginEvents.FORGET, this._handleUserForget)
		this._utils.$Dispatcher.unlisten(ImaLoginEvents.BADGE, this._handleUserBadge)

		this._utils.$Dispatcher.unlisten(
			ASSIGN_EMAIL_EVENTS.UPDATE_USER_SELF,
			this._handleUpdateUserSelf
		)

		this._unloadCwlClickScript()
	}

	_loadCwlClickScript() {
		const ROUTES_TO_BLOCK_WITH_CWL_CLICK = [
			ROUTE_NAMES.USERWEB.ADVERT_DETAIL,
			ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL,
			ROUTE_NAMES.USERWEB.SELLER,
			ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES,
			ROUTE_NAMES.USERWEB.SELLER_OLD
		]
		const routeName = this.getRouteName()

		if (!ROUTES_TO_BLOCK_WITH_CWL_CLICK.includes(routeName)) {
			return
		}

		const scriptElement = document.createElement('script')

		scriptElement.id = CWL_CLICK_SCRIPT_ID
		// skript upraven oproti https://gitlab.seznam.net/inzeraty/cwl-click tak, aby
		// neblokoval interni odkazy. Odkazy blokujeme nebo povolujeme az na urovni
		// IMA routeru (trida CustomClientRouter). Mysleno tedy interni odkazy, externi
		// odkazy mimo Sauto nechceme blokovat vubec (ty se neblokuji ani v puvodnim
		// skriptu).
		// Bez teto upravy by se neslo na detailech prokliknout pres paticku do KA.
		scriptElement.innerHTML = `
			(function() {
				var eventTypes = [ "touchstart", "mousedown", "focus"];
			
				for (var eventType of eventTypes) {
					window.addEventListener(eventType, openCWL, true);
				}
			
				function openCWL(event) {
					if (window.CWL && window.CWL.consent === false) {
						var target = event.target;
			
						if (target === window || target?.tagName?.toLowerCase() === 'szn-cwl') return;
			
						var anchorElm = getAnchorElm(target);
				
						if (anchorElm && anchorElm.href || target.tagName?.toUpperCase() === 'SZN-LOGIN-WIDGET') {
							// do nothing
						} else {
							event.preventDefault();
							event.stopImmediatePropagation();
			
							var url = location.href;
				
							window.dispatchEvent(
								new CustomEvent('cwl:open-dialog', { detail: { url: url } })
							);  
						}
					}
				}
				
				function getAnchorElm(element) {
					if (!element) return null;
					if (element.tagName?.toLowerCase() === 'a') return element;
					return getAnchorElm(element.parentElement);
				}
			})();
		`

		document.head.appendChild(scriptElement)
	}

	_unloadCwlClickScript() {
		const scriptElement = document.getElementById(CWL_CLICK_SCRIPT_ID)

		if (scriptElement) {
			scriptElement.remove()
		}
	}

	getDataForSsp() {
		throw new GenericError(
			'Je nutné implementovat metodu getDataForSsp, která by měla nastavit data potrebne pre ssp.'
		)
	}

	getRouteName() {
		try {
			const { route } = this._utils.$Router.getCurrentRouteInfo()
			return route.getName()
		} catch (e) {
			return ''
		}
	}

	_handleUpdateUserSelf() {
		this._setUserEntity(false)
	}

	async _setHttpStatusCode() {
		const userEntity = await this._getUserSelfState()

		if (!UserService.isUserLogged(userEntity)) {
			this.status = HttpStatusCodes.UNAUTHORIZED

			this.setState({
				[UserwebBaseController.STATE_KEYS.HTTP_STATUS]: HttpStatusCodes.UNAUTHORIZED
			})
		}
	}

	/**
	 * Nastaví do analytiky uživatelovo rusId.
	 *
	 * @method _setDotRus
	 * @public
	 * @param {UserEntity} userEntity
	 */
	_setDotRus(userEntity = {}) {
		if (this._utils.$Window.isClient()) {
			const { id = '', login } = userEntity
			this._utils.DotAnalytic.setRusId(id)
			this._utils.DotAnalytic.setLoginState(id ? (login ? 'green' : 'orange') : 'red')
		}
	}

	/**
	 * Set seo params.
	 *
	 * @method setSeoParams
	 * @param {ima.meta.MetaManager} metaManager
	 * @param {ima.router.Router} router
	 */
	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)
	}

	_getLoggedUserId(userEntity = {}) {
		const { id = '', login } = userEntity
		// - ulozim si jen userId uzivatele ve stavu 'green'
		return login ? id : ''
	}

	_handleUserBadge() {
		this._setUserEntity()
		this._userEntityPromise.then((userEntity) => {
			this._utils.DotAnalytic.disableLazyLoading()
			// TODO - fallback pro medailonek
			// po prihlaseni si ulozim userId prihlaseneho uzivatele
			this._userIdBeforeForget = this._getLoggedUserId(userEntity)
		})
	}

	async _handleUserLogged() {
		const { id: userId } = await this._getUserSelfState(true)
		// TODO - fallback pro medailonek
		// pri zmene prihlaseneho uzivatele refreshneme stranku,
		// at se cela appka nacte znovu
		if (this._userIdBeforeForget != userId) {
			window.location.reload()
		}
	}

	_handleUserLoggedOut() {
		// TODO - fallback pro medailonek
		// pokud se uzivatel odhlasi smazu si jeho ulozene userId
		this._userIdBeforeForget = ''

		// pri zmene prihlaseneho uzivatele refreshneme stranku,
		// at se cela appka nacte znovu
		window.location.reload()
	}

	_handleUserForget() {
		// TODO - fallback pro medailonek
		// uzivatel se muze nechat na sluzbe zapomenout jen po odhlaseni
		// ale pokud me odhlasi medailonek pri prechodu do klientske zony, tak mam ulozene userId puvodne prihlaseneho uzivatele
		// pokud byl uzivatel pri pozadavku na zapomenuti prihlasen, tak se jej pokusim znovu prihlasit.
		// jinak provedu zapomenuti na sluzbe
		if (this._userIdBeforeForget && this._utils.LoginHelper.login) {
			this._utils.LoginHelper.login.autologin()
		} else {
			this._forgetUserSelf()
		}
	}

	_forgetUserSelf() {
		this._setDotRus()
		this.setState({
			[UserwebBaseController.STATE_KEYS.USER_SELF]: this._userService.forgetUserSelf()
		})
	}

	_setUserEntity(useCache = true) {
		this._getUserSelfState(true, useCache).then((userEntity) => {
			this.setState({
				[UserwebBaseController.STATE_KEYS.USER_SELF]: userEntity
			})
			this._utils.$Dispatcher.fire(UserwebBaseController.EVENT.USER_SELF_UPDATED, { userEntity })
		})
	}

	_getUserSelfState(downloadOrigin = false, useCache = true) {
		// - pri pouziti controlleru pro interni admin zablokuji volani userSelf, ktere je v IA blokovano a presmerovava na HP IA
		if (this._isInternalAdminRoute()) {
			this._userEntityPromise = Promise.resolve({})
		} else {
			this._userEntityPromise = this._userService
				.getUserSelf(downloadOrigin, useCache)
				.then((userEntity) => {
					this._setDotRus(userEntity)
					return userEntity
				})
				.catch(() => undefined)
		}

		return this._userEntityPromise
	}

	_isInternalAdminRoute() {
		try {
			return Object.values(ROUTE_NAMES.INTERNAL_ADMIN).includes(this.getRouteName())
		} catch (error) {
			return false
		}
	}
}
