/**
 * Podle potreby odstrani priznaky o chybe z Cebie.
 *
 * @public
 * @method clearCebiaErrorFlags
 * @param {Array<FormLineEntity>} formLinesEntities formlines formulare
 * @param {Array<Object>} changes zmeny, ktere budou provedeny na formulari
 * @return {Array<Object>} 	sloucene zmeny, tedy vstupni zmeny + zmeny pro odstraneni
 * 							Cebia priznaku
 */
const clearCebiaErrorFlags = (formLinesEntities, changes) => {
	// vytvorime si asociativni pole pro rychlejsi hledani
	const formLinesEntitiesDictionary = formLinesEntities.reduce(
		(dictionary, formLineEntity) =>
			Object.assign({}, dictionary, {
				[formLineEntity.id]: formLineEntity
			}),
		{}
	)

	// priznak chyby Cebie chceme mazat pri zmene inputu uzivatelem...
	const formLineEntitiesIdsWithInputChange = changes
		.filter((change) => {
			const formLineEntity = formLinesEntitiesDictionary[change.id]

			return formLineEntity && formLineEntity.value !== change.value
		})
		.map((change) => change.id)

	// ...nebo pokud ma dojit k nastaveni chybove hlasky
	const formLineEntitiesIdsWithErrorMessage = changes
		.filter((change) => !!change.errorMessage)
		.map((change) => change.id)

	// pripravime prislusne zmeny na vymazani Cebia priznaku
	const changesToClearFlag = Array.from(
		new Set([...formLineEntitiesIdsWithInputChange, ...formLineEntitiesIdsWithErrorMessage])
	)
		.map((formLineEntityId) => {
			const formLineEntity = formLinesEntitiesDictionary[formLineEntityId]

			const { extra: { cebiaError } = {} } = formLineEntity || {}

			if (cebiaError) {
				return {
					id: formLineEntity.id,
					extra: Object.assign({}, formLineEntity.extra, {
						cebiaError: false
					})
				}
			} else {
				return undefined
			}
		})
		.filter((change) => !!change)

	// sloucime nase zmeny do puvodnich zmen
	return changes.map((change) => {
		const clearFlagChange = changesToClearFlag.find((ch) => ch.id === change.id) || {}

		return Object.assign({}, change, clearFlagChange)
	})
}

export default clearCebiaErrorFlags
