import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'

import './CebiaReportView.less'
import './CebiaReportCS.json'

const CLASSNAME = 'c-cebia-report'

export default class CebiaReportView extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			variant: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			className: '',
			variant: CebiaReportView.types.COL
		}
	}

	static get types() {
		return {
			ROW: 'row', // - logo a hvezdy na radek
			COL: 'col' // - logo a hvezdicky pod sebou
		}
	}

	render() {
		const { className, variant } = this.props

		return (
			<div
				className={this.cssClasses({
					[className]: !!className,
					[CLASSNAME]: true,
					[`${CLASSNAME}--${variant}`]: true
				})}
				title={this.localize('CebiaReport.title')}
			>
				<span className={`${CLASSNAME}__logo-box`}>
					<SvgIcon icon='LOGO_CEBIA' className={`${CLASSNAME}__logo`} />
				</span>
			</div>
		)
	}
}
