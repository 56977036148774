import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import carComparePropTypes from 'app/model/carCompare/CarComparePropTypes'
import { useLocalize } from 'app/base/componentHelpers'
import { Surface } from '@sznds/react'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import classnames from 'classnames'
import InfoPopup from './infoPopup/InfoPopup'
import TextLink from 'app/component/textLink/TextLink'
import { Icon } from '@sznds/react'
import { INFO_SQUIRCLE_FILLED_24 } from '@sznds/icons'
import ImaContext from 'ima/page/context'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'

import 'app/base/BaseCS.json'
import './CarCompareCS.json'
import './CarCompare.less'

export const CLASSNAME = 'c-car-compare'

export const PRICE_LEVELS_MAPPING = {
	lowest_price: 'lowest',
	low_price: 'low',
	regular_price: 'regular',
	high_price: 'high',
	highest_price: 'highest'
}

export const getDictionaryText = (dictKey, dictionary, localize) => {
	return dictionary.has(dictKey) ? localize(dictKey) : ''
}

const CarCompare = (props) => {
	const { compareData, advertEntity = DEFAULT_PROPS.OBJECT } = props
	const [open, setOpen] = useState(false)
	const localize = useLocalize()
	const { $Dictionary } = useContext(ImaContext)

	const renderBar = () => {
		const { priceLevels = [], result, price } = compareData || {}
		const priceValue = PRICE_LEVELS_MAPPING[result]

		const getPointerLeftValue = () => {
			const resultIndex = priceLevels.findIndex((l) => l.priceType === result)
			const resultValue = priceLevels[resultIndex]

			if (resultValue) {
				const interval = 100 / priceLevels.length

				const { priceFrom, priceTo } = resultValue

				const sum1 = priceTo - priceFrom
				const sum2 = price - priceFrom

				const percentageIntervalValue = sum1 === 0 ? interval : (interval * sum2) / sum1

				const additionalPercentage = resultIndex * interval

				let percent = percentageIntervalValue + additionalPercentage
				percent = Math.min(percent, 100)
				percent = Math.max(percent, 0)
				return percent
			} else {
				return 0
			}
		}

		const left = getPointerLeftValue()

		return (
			<div className={`${CLASSNAME}__bar`}>
				<div
					className={classnames({
						notranslate: true,
						[`${CLASSNAME}__price-label`]: true,
						[`${CLASSNAME}__price-label--${priceValue}`]: !!priceValue
					})}
					style={{
						left: `${left}%`
					}}
					translate='no'
					lang='cs-cz'
				>
					{localize('CarCompare.price', {
						PRICE: Format.number(price),
						CURRENCY: localize('Base.CZK')
					})}
				</div>
				<div className={`${CLASSNAME}__levels`}>
					{priceLevels.map((level, index) => {
						const { priceType, priceFrom } = level
						const levelVal = PRICE_LEVELS_MAPPING[priceType]

						const isPriceVisible = index > 0

						return (
							!!levelVal && (
								<div key={priceType} className={`${CLASSNAME}__bar-item`}>
									<div
										className={classnames(
											`${CLASSNAME}__price-info`,
											`${CLASSNAME}__price-text-info`
										)}
									>
										<div
											className={classnames({
												[`${CLASSNAME}__price`]: true,
												[`${CLASSNAME}__price--${levelVal}`]: !!levelVal
											})}
										>
											{getDictionaryText(`CarCompare.${levelVal}`, $Dictionary, localize)}
										</div>
									</div>
									<div
										className={classnames({
											[`${CLASSNAME}__bar-level`]: true,
											[`${CLASSNAME}__level--${levelVal}`]: !!levelVal
										})}
									></div>
									{isPriceVisible && (
										<div className={`${CLASSNAME}__price-info`}>
											<div
												className={`notranslate ${CLASSNAME}__price-value`}
												translate='no'
												lang='cs-cz'
											>
												{localize('CarCompare.price', {
													PRICE: Format.number(priceFrom),
													CURRENCY: localize('Base.CZK')
												})}
											</div>
										</div>
									)}
								</div>
							)
						)
					})}
					<div
						className={classnames({
							[`${CLASSNAME}__pointer`]: true,
							[`${CLASSNAME}__level--${priceValue}`]: !!priceValue
						})}
						style={{
							left: `${left}%`
						}}
					></div>
				</div>
			</div>
		)
	}

	const renderSentence = () => {
		const { priceLevels = [], result, totalMatches } = compareData || {}
		const resultIndex = priceLevels.find((l) => l.priceType === result)

		if (resultIndex) {
			const { priceType } = resultIndex

			const priceTextResult = PRICE_LEVELS_MAPPING[priceType]

			return (
				!!priceTextResult && (
					<div className={`${CLASSNAME}__sentence`}>
						<span>{localize('CarCompare.text1')}</span>
						<span className={`${CLASSNAME}__text-bold`}>
							{localize('CarCompare.textNumber', {
								COUNT: totalMatches
							})}
						</span>
						<span>
							{localize('CarCompare.text2', {
								COUNT: totalMatches
							})}
						</span>
					</div>
				)
			)
		} else {
			return null
		}
	}

	const renderTitle = () => {
		const { manufacturerCb = {}, modelCb = {} } = advertEntity
		const { name: manufacturerName = '' } = manufacturerCb
		const { name: modelName = '' } = modelCb

		return (
			<h2 className={`${CLASSNAME}__title`}>
				{localize('CarCompare.title1')}
				<span className={`${CLASSNAME}__title-value`}>{`${manufacturerName} ${modelName}`}</span>
			</h2>
		)
	}

	const renderMobileInfo = () => {
		const { priceLevels = [] } = compareData || {}

		return (
			<div className={`${CLASSNAME}__mobile-info`}>
				{priceLevels.map((level) => {
					const { priceType, priceFrom, priceTo } = level
					const levelVal = PRICE_LEVELS_MAPPING[priceType]

					return (
						!!levelVal && (
							<div key={priceType} className={`${CLASSNAME}__info-item`}>
								<div className={`${CLASSNAME}__info-text`}>
									<div
										className={classnames({
											[`${CLASSNAME}__info-text-point`]: true,
											[`${CLASSNAME}__level--${levelVal}`]: !!levelVal
										})}
									></div>
									<div className={`${CLASSNAME}__info-text-price`}>
										{getDictionaryText(`CarCompare.${levelVal}`, $Dictionary, localize)}
									</div>
								</div>
								<div
									className={`notranslate ${CLASSNAME}__info-text-range`}
									translate='no'
									lang='cs-cz'
								>
									{localize('CarCompare.range', {
										COUNT1: Format.number(priceFrom),
										COUNT2: Format.number(priceTo)
									})}
									{localize('Base.CZK')}
								</div>
							</div>
						)
					)
				})}
			</div>
		)
	}

	const renderPopupInfo = () => {
		return (
			<div className={`${CLASSNAME}__popup-info`}>
				<Icon className={`${CLASSNAME}__popup-info-icon`} symbol={INFO_SQUIRCLE_FILLED_24} />
				<TextLink
					onClick={() => {
						setOpen(true)
					}}
					className={`${CLASSNAME}__popup-info-text`}
				>
					{localize('CarCompare.info')}
				</TextLink>
				{open && <InfoPopup onClose={() => setOpen(false)} />}
			</div>
		)
	}

	const renderCompareInfo = () => {
		return (
			<div className={`${CLASSNAME}__compare-info`}>{localize('CarCompare.compareInfoData')}</div>
		)
	}

	if (compareData) {
		return (
			<Surface surface={5} className={CLASSNAME}>
				{renderTitle()}
				{renderSentence()}
				{renderBar()}
				{renderMobileInfo()}
				{renderPopupInfo()}
				{renderCompareInfo()}
			</Surface>
		)
	} else {
		return null
	}
}

CarCompare.propTypes = {
	compareData: PropTypes.shape(carComparePropTypes),
	advertEntity: PropTypes.shape(advertPropTypes)
}

export default CarCompare
