import FilterConstants from 'app/model/filter/FilterConstants'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'

const { URL_API_PARAMS } = AdvertListUrlConvertor.constants

const radioFilters = [
	{
		formLineId: FilterConstants.formLineIds.AGE,
		apiParamFromName: URL_API_PARAMS.AGE_FROM,
		apiParamToName: URL_API_PARAMS.AGE_TO
	},
	{
		formLineId: FilterConstants.formLineIds.PRICE,
		apiParamFromName: URL_API_PARAMS.PRICE_FROM,
		apiParamToName: URL_API_PARAMS.PRICE_TO,
		unit: 'Kč'
	},
	{
		formLineId: FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT,
		apiParamFromName: URL_API_PARAMS.OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM,
		apiParamToName: URL_API_PARAMS.OPERATING_LEASE_PRICE_WITHOUT_VAT_TO,
		unit: 'Kč / měs.'
	},
	{
		formLineId: FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE,
		apiParamFromName: URL_API_PARAMS.OPERATING_LEASE_ANNUAL_DISTANCE_FROM,
		apiParamToName: URL_API_PARAMS.OPERATING_LEASE_ANNUAL_DISTANCE_TO,
		unit: 'km'
	},
	{
		formLineId: FilterConstants.formLineIds.OPERATING_LEASE_PERIOD,
		apiParamFromName: URL_API_PARAMS.OPERATING_LEASE_PERIOD_FROM,
		apiParamToName: URL_API_PARAMS.OPERATING_LEASE_PERIOD_TO,
		unit: 'měs.'
	},
	{
		formLineId: FilterConstants.formLineIds.TACHOMETER,
		apiParamFromName: URL_API_PARAMS.TACHOMETER_FROM,
		apiParamToName: URL_API_PARAMS.TACHOMETER_TO,
		unit: 'km'
	},
	{
		formLineId: FilterConstants.formLineIds.PERFORMANCE,
		apiParamFromName: URL_API_PARAMS.PERFORMANCE_FROM,
		apiParamToName: URL_API_PARAMS.PERFORMANCE_TO,
		unit: 'kW'
	},
	{
		formLineId: FilterConstants.formLineIds.DISPLACEMENT,
		apiParamFromName: URL_API_PARAMS.DISPLACEMENT_FROM,
		apiParamToName: URL_API_PARAMS.DISPLACEMENT_TO,
		unit: 'ccm'
	},
	{
		formLineId: FilterConstants.formLineIds.FUEL_CONSUMPTION,
		apiParamFromName: URL_API_PARAMS.FUEL_CONSUMPTION_FROM,
		apiParamToName: URL_API_PARAMS.FUEL_CONSUMPTION_TO,
		unit: 'l / 100 km'
	},
	{
		formLineId: FilterConstants.formLineIds.AIRBAGS,
		apiParamFromName: URL_API_PARAMS.AIRBAGS_FROM,
		apiParamToName: URL_API_PARAMS.AIRBAGS_TO
	},
	{
		formLineId: FilterConstants.formLineIds.CAPACITY,
		apiParamFromName: URL_API_PARAMS.CAPACITY_FROM,
		apiParamToName: URL_API_PARAMS.CAPACITY_TO
	},
	{
		formLineId: FilterConstants.formLineIds.DOORS,
		apiParamFromName: URL_API_PARAMS.DOORS_FROM,
		apiParamToName: URL_API_PARAMS.DOORS_TO
	},
	{
		formLineId: FilterConstants.formLineIds.WEIGHT,
		apiParamFromName: URL_API_PARAMS.WEIGHT_FROM,
		apiParamToName: URL_API_PARAMS.WEIGHT_TO,
		unit: 'kg'
	},
	{
		formLineId: FilterConstants.formLineIds.BEDS,
		apiParamFromName: URL_API_PARAMS.BEDS_FROM,
		apiParamToName: URL_API_PARAMS.BEDS_TO
	},
	{
		formLineId: FilterConstants.formLineIds.MACHINE_HOURS,
		apiParamFromName: URL_API_PARAMS.MACHINE_HOURS_FROM,
		apiParamToName: URL_API_PARAMS.MACHINE_HOURS_TO,
		unit: 'hod.'
	}
]

export default radioFilters
