
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['BasicInfoTooltip'] = {
  additionalModelTooltipNote: function(d) { return "Zde můžete uvést informace týkající se přímo modelu vozu. Titulek se objeví za výrobcem a modelem vozu např.: Škoda Octavia, \"1.9 TDI - Servisováno\"."; },
  fuelConsumptionTooltipNote: function(d) { return "Uveďte, kolik litrů paliva spotřebuje vozidlo na sto kilometrů."; },
  priceNoteTooltip: function(d) { return "Do \"Poznámka k ceně\" prosím uvádějte pouze informace týkající se ceny. Informace týkající se vozidla uveďte do pole \"Poznámka o stavu vozidla\"."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	