import FilterConstants from 'app/model/filter/FilterConstants'

/**
 * Hodnoty pro nastavení atributu elementů "data-seot" (seo testing) nebo
 * "data-seoth" (seo testing hidden).
 */
const DATA_SEOT = {
	// Seznam odkazů na značky.
	['BRANDS_LINKS']: 'brands-links',

	// Seznam odkazů na modely dané značky.
	['MODELS_LINKS']: 'models-links',

	// Filtry na HP.
	['HP_FILTERS']: 'hp-filters',

	// Odkaz na výpis s aplikovaným filtrem paliva.
	['FUEL_LINK']: 'fuel-link',

	// Odkaz na výpis s aplikovaným filtrem karosérie.
	['VEHICLE_BODY_LINK']: 'vehicle-body-link',

	// Odkaz na výpis s aplikovaným filtrem stav vozidla.
	['CONDITION_LINK']: 'condition-link',

	// Odkaz na výpis s aplikovaným filtrem země původu.
	['COUNTRY_OF_ORIGIN_LINK']: 'country-of-origin-link',

	// Odkaz na výpis s aplikovaným filtrem ověřovací programy.
	['CERTIFIED_PROGRAM_LINK']: 'certified-program-link',

	// Odkaz na výpis s aplikovaným filtrem typ prodejce.
	['VENDOR_LINK']: 'vendor-link',

	// Nadpis stránky výpisu inzerátů.
	['ADVERT_LIST_HEADER']: 'items-list-header',

	// Odkaz v nadpisu výpisu inzerátů.
	['ADVERT_LIST_HEADER_LINK']: 'items-list-hlink',

	// Počet inzerátů.
	['ADVERTS_COUNT']: 'items-count',

	// Popisek prodejce.
	['SELLER_DESCRIPTION']: 'seller-desc'
}

const SEO_MAPPING_FOR_TESTING = {
	[FilterConstants.formLineIds.FUEL]: DATA_SEOT.FUEL_LINK,
	[FilterConstants.formLineIds.VEHICLE_BODY]: DATA_SEOT.VEHICLE_BODY_LINK,
	[FilterConstants.formLineIds.CONDITION]: DATA_SEOT.CONDITION_LINK,
	[FilterConstants.formLineIds.COUNTRY_OF_ORIGIN]: DATA_SEOT.COUNTRY_OF_ORIGIN_LINK,
	[FilterConstants.formLineIds.CERTIFIED_PROGRAM]: DATA_SEOT.CERTIFIED_PROGRAM_LINK,
	[FilterConstants.formLineIds.VENDOR]: DATA_SEOT.VENDOR_LINK
}

export { DATA_SEOT, SEO_MAPPING_FOR_TESTING }
