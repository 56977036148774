const RESOURCE = '/reviews'

export default class ReviewResource {
	/**
	 * @constructor
	 * @param {RestClient} restClient
	 **/
	constructor(restClient) {
		this._restClient = restClient
	}

	_getReviewFeedbackUrl(reviewId) {
		return `${RESOURCE}/${reviewId}/feedback`
	}

	_getRepliesUrl(reviewId) {
		return `${RESOURCE}/${reviewId}/replies`
	}

	_getReplyFeedbackUrl(reviewId, replyId) {
		return `${RESOURCE}/${reviewId}/replies/${replyId}/feedback`
	}

	/**
	 * Na zaklade categorie a modelu vraci seznam recenzi
	 *
	 * @public
	 * @method getReviewsList
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>} Seznam zkusenosti.
	 */
	getReviewsList(params = {}, options = {}) {
		return this._restClient.get(RESOURCE, params, options)
	}

	/**
	 * Ulozi zkusenost.
	 *
	 * @method saveReview
	 * @public
	 * @param {Object} data Form data
	 * @return {Promise<JSON>} Data odpovědi
	 */
	saveReview(data = {}) {
		return this._restClient.post(RESOURCE, data)
	}

	/**
	 * Smaze zkusenost.
	 *
	 * @method deleteReview
	 * @public
	 * @param {Number} reviewId Id recenze ke smazani
	 * @return {Promise<JSON>} Data odpovědi
	 */
	deleteReview(reviewId) {
		return this._restClient.delete(`${RESOURCE}/${reviewId}`)
	}

	/**
	 * Ulozi hodnoceni zkusenosti.
	 *
	 * @method saveReviewFeedback
	 * @public
	 * @param {Number} reviewId Id recenze
	 * @param {Object} data Data hodnoceni
	 * @return {Promise<JSON>} Data odpovědi
	 */
	saveReviewFeedback(reviewId, data = {}) {
		return this._restClient.post(this._getReviewFeedbackUrl(reviewId), data)
	}

	/**
	 * Zmeni hodnoceni zkusenosti.
	 *
	 * @method changeReviewFeedback
	 * @public
	 * @param {Number} reviewId Id recenze
	 * @param {Object} data Data hodnoceni
	 * @return {Promise<JSON>} Data odpovědi
	 */
	changeReviewFeedback(reviewId, data = {}) {
		return this._restClient.patch(this._getReviewFeedbackUrl(reviewId), data)
	}

	/**
	 * Smaze hodnoceni zkusenosti.
	 *
	 * @method deleteReviewFeedback
	 * @public
	 * @param {Number} reviewId Id recenze
	 * @return {Promise<JSON>} Data odpovědi
	 */
	deleteReviewFeedback(reviewId) {
		return this._restClient.delete(this._getReviewFeedbackUrl(reviewId))
	}

	/**
	 * Vraci seznam odpovedi na zkusenost uzivatele
	 *
	 * @public
	 * @method getRepliesList
	 * @param {Number} reviewId Id recenze
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>} Seznam zkusenosti.
	 */
	getRepliesList(reviewId, params = {}, options = {}) {
		return this._restClient.get(this._getRepliesUrl(reviewId), params, options)
	}

	/**
	 * Vrátí seznam všech odpovědí na recenze.
	 *
	 * @public
	 * @method getReplyList
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>}
	 */
	getReplyList(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/replies`, params, options)
	}

	/**
	 * Ulozi odpoved na zkusenost.
	 *
	 * @method saveReply
	 * @public
	 * @param {Number} reviewId Id recenze
	 * @param {Object} data Form data
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	saveReply(reviewId, data = {}) {
		return this._restClient.post(this._getRepliesUrl(reviewId), data)
	}

	/**
	 * Smaze odoved na zkusenost zkusenost.
	 *
	 * @method deleteReply
	 * @public
	 * @param {Number} reviewId Id recenze
	 * @param {Number} replyId Id odpovedi
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	deleteReply(reviewId, replyId) {
		return this._restClient.delete(`${this._getRepliesUrl(reviewId)}/${replyId}`)
	}

	/**
	 * Ulozi hodnoceni odpovedi na zkusenost.
	 *
	 * @method saveReplyFeedback
	 * @public
	 * @param {Number} reviewId Id recenze
	 * @param {Number} replyId Id odpovedi
	 * @param {Object} data Data hodnoceni
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	saveReplyFeedback(reviewId, replyId, data = {}) {
		return this._restClient.post(this._getReplyFeedbackUrl(reviewId, replyId), data)
	}

	/**
	 * Zmeni hodnoceni odpovedi na zkusenost.
	 *
	 * @method changeReplyFeedback
	 * @public
	 * @param {Number} reviewId Id recenze
	 * @param {Number} replyId Id odpovedi
	 * @param {Object} data Data hodnoceni
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	changeReplyFeedback(reviewId, replyId, data = {}) {
		return this._restClient.patch(this._getReplyFeedbackUrl(reviewId, replyId), data)
	}

	/**
	 * Smaze hodnoceni odpovedi na zkusenost.
	 *
	 * @method deleteReplyFeedback
	 * @public
	 * @param {Number} reviewId Id recenze
	 * @param {Number} replyId Id odpovedi
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	deleteReplyFeedback(reviewId, replyId) {
		return this._restClient.delete(this._getReplyFeedbackUrl(reviewId, replyId))
	}
}
