import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'

/**
 * Komponenta umoznuje drzet a upravovat formline (definice filtru) pouze lokalne.
 * To je vhodne v situacich, kdy pri zmene filtru nechceme, aby se tyto zmeny
 * okamzite propsaly do globalniho stavu - napriklad kdyz upravujeme filtr otevreny
 * v popupu nebo dropdownu, zmeny chceme propsat az pri zavreni.
 */
export default class ScopedFormLineEntity extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			localFormLineEntity: props.formLineEntity
		}

		this._localChangeFilter = this._localChangeFilter.bind(this)
	}

	componentDidUpdate(prevProps) {
		const { formLineEntity } = this.props
		const { formLineEntity: prevFormLineEntity } = prevProps

		if (formLineEntity !== prevFormLineEntity) {
			this.setState({
				localFormLineEntity: formLineEntity
			})
		}
	}

	render() {
		const { localFormLineEntity } = this.state
		const { children } = this.props

		return children({
			localFormLineEntity,
			localChangeFilter: this._localChangeFilter
		})
	}

	_localChangeFilter(id, value, callback = () => {}) {
		const { localFormLineEntity } = this.state
		const newLocalFormLineEntity = new FormLines.Entity(
			Object.assign({}, localFormLineEntity, {
				value
			})
		)

		this.setState(
			{
				localFormLineEntity: newLocalFormLineEntity
			},
			() => {
				callback(newLocalFormLineEntity)
			}
		)
	}
}
