
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['DissectPremisePopup'] = {
  dissectPremise: function(d) { return "Přepárovat firmu"; },
  dissectDescription: function(d) { return "Pro úspěšné přepárování si nachystejte ASK ID z původní firmy."; },
  setOldAskIdPlaceholder: function(d) { return "Zadejte původní ASK ID"; },
  oldAskIdErrorRequired: function(d) { return "Je třeba vyplnit ASK ID původní firmy"; },
  oldAskIdErrorNotValid: function(d) { return "Chybný formát původního ASK ID"; },
  equalPremiseAndOldAskIdError: function(d) { return "Nelze přepárovat aktuální ASK ID"; },
  dissectWithAdverts: function(d) { return "Přepárovat s inzeráty"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	