import React, { useContext, useState } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { MENU_BURGER_16 } from '@sznds/icons'
import { Icon } from '@sznds/react'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import classnames from 'classnames'
import { Popup } from '@inzeraty/components'
import Logo from 'app/component/logo/Logo'

import 'app/base/BaseCS.json'
import './AdminMenuCS.json'
import './AdminMenu.less'

const CLASSNAME = 'c-admin-menu'

const Categories = () => {
	const localize = useLocalize()
	const context = useContext(Context)
	const router = context.$Router

	const { route } = router.getCurrentRouteInfo()
	const currentRoute = route.getName()

	const categories = [
		{
			name: localize('AdminMenu.adverts'),
			href: router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST),
			isActive: currentRoute === ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST
		},
		{
			name: localize('AdminMenu.profile'),
			href: router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_PROFILE),
			isActive: currentRoute === ROUTE_NAMES.CLIENT_ADMIN.CLIENT_PROFILE
		},
		{
			name: localize('AdminMenu.statistics'),
			href: router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS),
			isActive: currentRoute === ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS
		}
	]

	return (
		<nav role='navigation' className={`${CLASSNAME}__categories-wrapper`}>
			<ul className={`${CLASSNAME}__categories`}>
				{categories.map(({ name, href, isActive }) => (
					<li key={name} className={`${CLASSNAME}__category`}>
						<a
							href={href}
							className={classnames({
								[`${CLASSNAME}__category-name`]: true,
								[`${CLASSNAME}__category-name--active`]: isActive
							})}
						>
							{name}
							{isActive && (
								<span className={`${CLASSNAME}__category-current`}>{localize('Base.current')}</span>
							)}
						</a>
					</li>
				))}
			</ul>
		</nav>
	)
}

export default function AdminMenu() {
	const localize = useLocalize()
	const [menuOpen, setMenuOpen] = useState(false)

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__categories-desktop`}>
				<Categories />
			</div>
			<div className={`${CLASSNAME}__categories-mobile`}>
				<button
					onClick={() => setMenuOpen(true)}
					className={`${CLASSNAME}__categories-opener`}
					aria-expanded={menuOpen}
					aria-label={localize('AdminMenu.openNavigation')}
				>
					<Icon symbol={MENU_BURGER_16} className={`${CLASSNAME}__icon-menu`} />
				</button>
				{menuOpen && (
					<Popup
						dictionary={{
							close: localize('AdminMenu.closeNavigation')
						}}
						className={`${CLASSNAME}__categories-popup`}
						isOpen={true}
						onClose={() => setMenuOpen(false)}
						renderHeader={(renderCloseButton) => (
							<div className={`${CLASSNAME}__categories-popup-header`}>{renderCloseButton()}</div>
						)}
						isFullscreen={false}
						hasLightbox={true}
					>
						<Logo isPremiseAdmin={true} className={`${CLASSNAME}__logo`} />
						<Categories />
					</Popup>
				)}
			</div>
		</div>
	)
}
