
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Gallery'] = {
  prev: function(d) { return "Předchozí obrázek"; },
  next: function(d) { return "Následující obrázek"; },
  pickImage: function(d) { return "Vybrat obrázek " + d.INDEX + " z " + d.LENGTH; },
  pickVideo: function(d) { return "Vybrat video " + d.INDEX + " z " + d.LENGTH; },
  close: function(d) { return "zavřít"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	