import React, { useEffect, useState } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { Icon } from '@sznds/react'
import { OVERFLOW_MENU_HORIZONTAL_FILLED } from '@sznds/icons'
import { Tooltip } from '@inzeraty/components'
import ActionElm from 'app/component/actionElement/ActionElement'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ActionItem from 'app/component/actionsMenu/ActionsItem'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import 'app/base/BaseCS'
import './ActionsMenu.less'
import './ActionsMenuCS.json'

const CLASSNAME = 'c-actions-menu'

const ActionsMenu = ({
	className,
	openerText,
	modalTitle,
	blindClosePrefix,
	actions,
	isOpen: isOpenComp = false,
	setIsOpenCallback = DEFAULT_PROPS.FUNCTION
}) => {
	const [isOpen, setIsOpen] = useState(isOpenComp)

	useEffect(() => {
		setIsOpen(isOpenComp)
	}, [isOpenComp])

	useEffect(() => {
		setIsOpenCallback(isOpen)
	}, [isOpen])

	const localize = useLocalize()

	const renderActions = () => {
		return (
			<>
				{actions.map(({ renderAction: externalRenderAction, ...restActionProps }) => {
					if (externalRenderAction) {
						return externalRenderAction(restActionProps)
					} else {
						return <ActionItem {...restActionProps} />
					}
				})}
			</>
		)
	}

	const actionsMenuDictionary = {
		ariaClose: localize('Base.close'),
		blindClosePrefix: blindClosePrefix || localize('ActionsMenu.blindClosePrefix'),
		ariaOpener: modalTitle,
		ariaTitle: modalTitle
	}

	return (
		<div
			className={classnames({
				[className]: !!className,
				[CLASSNAME]: true
			})}
			data-dot='option'
		>
			<Tooltip
				renderOpener={(openerProps) => {
					openerProps.className = `${openerProps.className} ${CLASSNAME}__opener`
					return (
						<ActionElm data-dot='show-option' tagName='button' {...openerProps}>
							<Icon symbol={OVERFLOW_MENU_HORIZONTAL_FILLED} className={`${CLASSNAME}__icon`} />
							{!!openerText && <span className={`${CLASSNAME}__text`}>{openerText}</span>}
						</ActionElm>
					)
				}}
				dictionary={actionsMenuDictionary}
				isOpen={isOpen}
				setIsOpenCallback={(isOpen) => {
					setIsOpen(isOpen)
				}}
				title={modalTitle}
				hideHeader={true}
				enableOpenByOpenerHover={false}
				position={Tooltip.POSITION.BOTTOM_LEFT}
				modalPosition={Tooltip.MODAL_POSITION.BOTTOM}
				modalStyle={Tooltip.MODAL_STYLE.FULLSIZE}
			>
				{renderActions()}
			</Tooltip>
		</div>
	)
}

ActionsMenu.propTypes = {
	className: PropTypes.string,
	openerText: PropTypes.string,
	modalTitle: PropTypes.string.isRequired,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			tagName: PropTypes.string,
			text: PropTypes.string,
			icon: PropTypes.string,
			actionProps: PropTypes.object,
			renderAction: PropTypes.func
		}).isRequired
	),
	blindClosePrefix: PropTypes.string,
	isOpen: PropTypes.bool,
	setIsOpenCallback: PropTypes.func
}

export default ActionsMenu
