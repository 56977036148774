import React from 'react'
import classnames from 'classnames'
import { Surface, Button } from '@sznds/react'
import { useFire } from 'app/base/componentHelpers'
import InternalAdminWidgets, {
	VARIANT
} from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import { StatusMessage } from '@inzeraty/components'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './EntityForm.less'

const CLASSNAME = 'c-entity-form'

export default function EntityForm({
	className,
	formLineEntities,
	variant = VARIANT.FORM,
	isWaiting,
	entityFormError,
	submitButtonText = 'Uložit',
	isSubmitButtonEnabled = true,
	onChange: onChangeProp,
	onSubmit: onSubmitProp,
	renderFormContent,
	renderButton
}) {
	const fire = useFire()

	const onChange = (data) => {
		onChangeProp ? onChangeProp(data) : fire('entityFormChange', data)
	}

	const onSubmit = (event) => {
		event.preventDefault()
		onSubmitProp ? onSubmitProp() : fire('entityFormSubmit')
	}

	const renderStatus = () => {
		if (entityFormError) {
			const { title, content } = entityFormError

			return (
				<StatusMessage type={StatusMessage.TYPE.ERROR} title={title}>
					{content}
				</StatusMessage>
			)
		}
	}

	return (
		<Surface
			className={classnames({
				[className]: !!className,
				[CLASSNAME]: true
			})}
		>
			{renderStatus()}

			<form onSubmit={onSubmit}>
				{renderFormContent ? (
					renderFormContent()
				) : (
					<InternalAdminWidgets
						formLineEntities={formLineEntities}
						variant={variant}
						onChange={onChange}
					/>
				)}

				<div className={`${CLASSNAME}__submit`}>
					{renderButton ? (
						renderButton()
					) : (
						<Button
							primary={true}
							text={submitButtonText}
							type='submit'
							size='small'
							loading={isWaiting}
							disabled={!isSubmitButtonEnabled}
						/>
					)}
				</div>
			</form>
		</Surface>
	)
}

EntityForm.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.string,
	isWaiting: PropTypes.bool,
	submitButtonText: PropTypes.string,
	isSubmitButtonEnabled: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	renderFormContent: PropTypes.func,
	renderButton: PropTypes.func
}
