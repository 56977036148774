import RegionService from 'app/model/region/RegionService'
import DistrictService from 'app/model/district/DistrictService'
import SellerListController from './SellerListController'
import { REGIONS } from 'app/base/Constants'
import SellerListUrlConvertor from './SellerListUrlConvertor'

import './SellerListCS.json'
import 'app/base/BaseCS.json'

const getLocality = (routeParams) => {
	const regionEntities = RegionService.getRegions()
	const districtEntities = DistrictService.getDistricts()

	const {
		selectedRegionEntity,
		seledtedDicstrictEntity
	} = SellerListController.getSelectedLocality(routeParams, regionEntities, districtEntities)

	const getRegionName = ({ id, name = '' }) => {
		let regionName = name
		if (id === REGIONS.PRAHA.id) {
			regionName = 'Praha'
		}

		return regionName
	}

	let locality = ''

	if (seledtedDicstrictEntity) {
		const { name = '' } = SellerListUrlConvertor.getUpdatedDistrictData(seledtedDicstrictEntity)
		locality = name
	} else if (selectedRegionEntity) {
		const { id: selectedId } = selectedRegionEntity
		const regionEntity = regionEntities.find(({ id }) => id === selectedId) || {}
		locality = getRegionName(regionEntity)
	}

	return locality
}
export default class SellerListMeta {
	static getTitle(dictionary, routeParams, count) {
		let title = `${dictionary.get('SellerList.headerMeta')} - ${count} ${dictionary.get(
			'SellerList.headerMetaSecond',
			{
				COUNT: count
			}
		)}`

		const locality = getLocality(routeParams)

		if (locality) {
			title = `${dictionary.get('SellerList.headerLocality')} ${locality}`
		}

		title += dictionary.get('Base.titleSuffix')

		return title
	}

	static getDescriptionValue(dictionary, routeParams, count) {
		let description = dictionary.get('SellerList.description')

		const locality = getLocality(routeParams)

		if (locality) {
			description = `${dictionary.get('SellerList.descriptionLocality', {
				COUNT: count
			})} ${locality}. ${dictionary.get('SellerList.descriptionLocalitySecond')}`
		}

		return description
	}
}
