
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['RangeInputForm'] = {
  "engine_power-from": function(d) { return "Výkon od"; },
  "engine_power-to": function(d) { return "Výkon do"; },
  "engine_power-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí výkonu"; },
  "engine_power-placeholderFrom": function(d) { return "0"; },
  "engine_power-placeholderTo": function(d) { return "Neomezeně"; },
  "weight-from": function(d) { return "Hmotnost od"; },
  "weight-to": function(d) { return "Hmotnost do"; },
  "weight-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí hmotnosti"; },
  "weight-placeholderFrom": function(d) { return "0"; },
  "weight-placeholderTo": function(d) { return "Neomezeně"; },
  "tachometer-from": function(d) { return "Najeto od"; },
  "tachometer-to": function(d) { return "Najeto do"; },
  "tachometer-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí tachometru"; },
  "tachometer-placeholderFrom": function(d) { return "0"; },
  "tachometer-placeholderTo": function(d) { return "Neomezeně"; },
  "price-from": function(d) { return "Cena od"; },
  "price-to": function(d) { return "Cena do"; },
  "price-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí ceny"; },
  "price-placeholderFrom": function(d) { return "0"; },
  "price-placeholderTo": function(d) { return "Neomezeně"; },
  "operating_lease_price_without_vat-from": function(d) { return "Měsíční sazba od"; },
  "operating_lease_price_without_vat-to": function(d) { return "Měsíční sazba do"; },
  "operating_lease_price_without_vat-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí měsíční sazby"; },
  "operating_lease_price_without_vat-placeholderFrom": function(d) { return "0"; },
  "operating_lease_price_without_vat-placeholderTo": function(d) { return "Neomezeně"; },
  "operating_lease_annual_distance-from": function(d) { return "Roční nájezd od"; },
  "operating_lease_annual_distance-to": function(d) { return "Roční nájezd do"; },
  "operating_lease_annual_distance-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí ročního nájezdu"; },
  "operating_lease_annual_distance-placeholderFrom": function(d) { return "0"; },
  "operating_lease_annual_distance-placeholderTo": function(d) { return "Neomezeně"; },
  "operating_lease_period-from": function(d) { return "Doba pronájmu od"; },
  "operating_lease_period-to": function(d) { return "Doba pronájmu do"; },
  "operating_lease_period-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí doby pronájmu"; },
  "operating_lease_period-placeholderFrom": function(d) { return "0"; },
  "operating_lease_period-placeholderTo": function(d) { return "Neomezeně"; },
  "machine_hours-from": function(d) { return "Počet motohodin od"; },
  "machine_hours-to": function(d) { return "Počet motohodin do"; },
  "machine_hours-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí počtu motohodin"; },
  "machine_hours-placeholderFrom": function(d) { return "0"; },
  "machine_hours-placeholderTo": function(d) { return "Neomezeně"; },
  "average_gas_mileage-from": function(d) { return "Spotřeba od"; },
  "average_gas_mileage-to": function(d) { return "Spotřeba do"; },
  "average_gas_mileage-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí spotřeby"; },
  "average_gas_mileage-placeholderFrom": function(d) { return "0"; },
  "average_gas_mileage-placeholderTo": function(d) { return "Neomezeně"; },
  "door-from": function(d) { return "Počet dveří od"; },
  "door-to": function(d) { return "Počet dveří do"; },
  "door-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí počtu dveří"; },
  "door-placeholderFrom": function(d) { return "0"; },
  "door-placeholderTo": function(d) { return "Neomezeně"; },
  "engine_volume-from": function(d) { return "Objem od"; },
  "engine_volume-to": function(d) { return "Objem do"; },
  "engine_volume-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí objemu"; },
  "engine_volume-placeholderFrom": function(d) { return "0"; },
  "engine_volume-placeholderTo": function(d) { return "Neomezeně"; },
  "capacity-from": function(d) { return "Počet míst od"; },
  "capacity-to": function(d) { return "Počet míst do"; },
  "capacity-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí počtu míst"; },
  "capacity-placeholderFrom": function(d) { return "0"; },
  "capacity-placeholderTo": function(d) { return "Neomezeně"; },
  "beds-from": function(d) { return "Počet lůžek od"; },
  "beds-to": function(d) { return "Počet lůžek do"; },
  "beds-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí počtu lůžek"; },
  "beds-placeholderFrom": function(d) { return "0"; },
  "beds-placeholderTo": function(d) { return "Neomezeně"; },
  "airbag-from": function(d) { return "Počet airbagů od"; },
  "airbag-to": function(d) { return "Počet airbagů do"; },
  "airbag-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí počtu airbagů"; },
  "airbag-placeholderFrom": function(d) { return "0"; },
  "airbag-placeholderTo": function(d) { return "Neomezeně"; },
  "vehicle_age-from": function(d) { return "Rok od"; },
  "vehicle_age-to": function(d) { return "Rok do"; },
  "vehicle_age-rangeValidationError": function(d) { return "Vyplňte prosím správné rozmezí stáří vozu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	