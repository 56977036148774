import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocalize } from 'app/base/componentHelpers'
import { PaginationEntity } from '@inzeraty/models'
import { Button } from '@sznds/react'
import { CHEVRON_LEFT_OUTLINE_24, CHEVRON_RIGHT_OUTLINE_24 } from '@sznds/icons'

import './PagingView.less'
import './PagingCS.json'

const CLASSNAME = 'c-paging'

const PagingView = (props) => {
	const localize = useLocalize()

	const {
		className = '',
		size = 'regular',
		paginationEntity = {},
		urlPageCreator,
		pageOnClick,
		showPrevOnFirstPage = false,
		showNextOnLastPage = false,
		prevText = localize('Paging.prev'),
		nextText = localize('Paging.next')
	} = props

	const { total, limit, offset } = paginationEntity

	const actualPage = offset / limit + 1
	const pagesCount = Math.ceil(total / limit)

	const prevActive = actualPage > 1
	const nextActive = actualPage < pagesCount

	if (pagesCount > 1 || showPrevOnFirstPage || showNextOnLastPage) {
		const prevParams = {}
		const nextParams = {}

		if (pageOnClick) {
			prevParams.onClick = () => {
				pageOnClick(actualPage - 1)
			}
			nextParams.onClick = () => {
				pageOnClick(actualPage + 1)
			}
		}

		return (
			<div
				className={classnames({
					[`${CLASSNAME}__box`]: true,
					[className]: !!className
				})}
				data-dot='pagination'
			>
				{(prevActive || showPrevOnFirstPage) && (
					<Button
						surface={5}
						icon={CHEVRON_LEFT_OUTLINE_24}
						className={`${CLASSNAME}__btn ${CLASSNAME}__btn-prev`}
						href={urlPageCreator(actualPage - 1)}
						text={!nextActive ? prevText : ''}
						title={nextActive ? prevText : ''}
						data-dot='previous'
						size={size}
						{...prevParams}
					/>
				)}

				{(nextActive || showNextOnLastPage) && (
					<Button
						surface={5}
						icon={CHEVRON_RIGHT_OUTLINE_24}
						className={`${CLASSNAME}__btn ${CLASSNAME}__btn-next`}
						href={urlPageCreator(actualPage + 1)}
						text={nextText}
						data-dot='next'
						size={size}
						{...nextParams}
					/>
				)}
			</div>
		)
	} else {
		return null
	}
}

PagingView.propTypes = {
	className: PropTypes.string,
	size: PropTypes.oneOf(['regular', 'small', 'x-small']),
	paginationEntity: PropTypes.instanceOf(PaginationEntity).isRequired, // Entita s daty stránkování
	urlPageCreator: PropTypes.func.isRequired, // Funkce, která vytvoří url další/předchozí stránky. Jako parametr dostane číslo stránky.
	pageOnClick: PropTypes.func, // Funkce, která se provola při kliku na stranku. Parametrem je stranka
	showPrevOnFirstPage: PropTypes.bool,
	showNextOnLastPage: PropTypes.bool,
	prevText: PropTypes.string,
	nextText: PropTypes.string
}

export default React.memo(PagingView)
