import { AbstractFactory } from '@inzeraty/models'
import { DateHelpers } from '@inzeraty/helpers'
import PremiseEntity from './PremiseEntity'

const SERVICE_NAME_FIRMY = 'firmy'
const SERVICE_NAME_SAUTO = 'sauto'

export default class PremiseFactory extends AbstractFactory {
	constructor() {
		super(PremiseEntity)
	}

	transformData(data = {}) {
		const { locality, opening_hours, subject, premise_services, phones, emails } = data

		return {
			admins: data.admins,
			id: data.id,
			oldId: data.old_id,
			name: data.name,
			enabled: data.enabled,
			description: data.description,
			seoName: data.seo_name,
			isPaid: data.is_paid,
			web: data.web,
			logo: data.logo,
			emails: emails ? emails.map(this._emailMap) : undefined,
			phones: phones ? phones.map(this._phoneMap) : undefined,
			users: data.users,
			activeAdvertsCount: data.active_adverts_count,
			allAdvertsCount: data.all_adverts_count,
			advertReportsCount: data.advert_reports_count,
			registrationActiveUntil: data.registration_active_until
				? DateHelpers.getDateObject(data.registration_active_until)
				: undefined,
			premiseServices: premise_services
				? premise_services
						.filter((premiseService) => premiseService.service_name === SERVICE_NAME_SAUTO)
						.map((premise_service) => {
							const {
								locality: service_locality,
								opening_hours: service_opening_hours,
								extra_data,
								import_data
							} = premise_service

							return {
								id: premise_service.id,
								oldId: premise_service.old_id,
								premiseId: premise_service.premise_id,
								externalId: premise_service.external_id,
								serviceName: premise_service.service_name,
								serviceEnabled: premise_service.service_enabled,
								name: premise_service.name,
								useServiceName: premise_service.use_service_name,
								useAdvertLocality: premise_service.use_advert_locality,
								description: premise_service.description,
								useServiceDescription: premise_service.use_service_description,
								web: premise_service.web,
								useServiceWeb: premise_service.use_service_web,
								walletUser: premise_service.wallet_user,
								locality: service_locality
									? {
											address: service_locality.address,
											addressId: service_locality.address_id,
											citypart: service_locality.citypart,
											countryId: service_locality.country_id,
											district: service_locality.district,
											districtId: service_locality.district_id,
											districtSeoName: service_locality.district_seo_name,
											entityId: service_locality.entity_id,
											entityType: service_locality.entity_type,
											gpsLat: service_locality.gps_lat,
											gpsLon: service_locality.gps_lon,
											housenumber: service_locality.housenumber,
											municipality: service_locality.municipality,
											municipalityId: service_locality.municipality_id,
											municipalitySeoName: service_locality.municipality_seo_name,
											quarter: service_locality.quarter,
											quarterId: service_locality.quarter_id,
											region: service_locality.region,
											regionId: service_locality.region_id,
											regionSeoName: service_locality.region_seo_name,
											source: service_locality.source,
											street: service_locality.street,
											streetId: service_locality.street_id,
											streetnumber: service_locality.streetnumber,
											ward: service_locality.ward,
											wardId: service_locality.ward_id,
											zip: service_locality.zip
									  }
									: undefined,
								useServiceLocality: premise_service.use_service_locality,
								openingHours: service_opening_hours
									? {
											openingTime: service_opening_hours.opening_time_st,
											openingTimeAdditionalInfo: service_opening_hours.opening_time_additional_info,
											openingTimeDescription: service_opening_hours.opening_time_description,
											openingTimeHolidayInfo: service_opening_hours.opening_time_holiday_info,
											openingTimeVisible: service_opening_hours.opening_time_visible
									  }
									: undefined,
								useServiceOpeningHours: premise_service.use_service_opening_hours,
								useServiceContacts: premise_service.use_service_contacts,
								createDate: premise_service.create_date,
								extraData: extra_data
									? {
											hideVin: extra_data.hide_vin,
											homeDelivery: extra_data.home_delivery
									  }
									: undefined,
								importData: import_data
									? {
											login: import_data.login,
											passwordHash: import_data.password_hash
									  }
									: undefined,
								retargetingId: premise_service.retargeting_id,
								advertsLimit: premise_service.adverts_limit
							}
						})
				: undefined,
			locality: locality
				? {
						address: locality.address,
						addressId: locality.address_id,
						citypart: locality.citypart,
						countryId: locality.country_id,
						district: locality.district,
						districtId: locality.district_id,
						districtSeoName: locality.district_seo_name,
						entityId: locality.entity_id,
						entityType: locality.entity_type,
						gpsLat: locality.gps_lat,
						gpsLon: locality.gps_lon,
						housenumber: locality.housenumber,
						municipality: locality.municipality,
						municipalityId: locality.municipality_id,
						municipalitySeoName: locality.municipality_seo_name,
						quarter: locality.quarter,
						quarterId: locality.quarter_id,
						region: locality.region,
						regionId: locality.region_id,
						regionSeoName: locality.region_seo_name,
						source: locality.source,
						street: locality.street,
						streetId: locality.street_id,
						streetnumber: locality.streetnumber,
						ward: locality.ward,
						wardId: locality.ward_id,
						zip: locality.zip
				  }
				: undefined,
			openingHours: opening_hours
				? {
						openingTime: opening_hours.opening_time_st,
						openingTimeAdditionalInfo: opening_hours.opening_time_additional_info,
						openingTimeDescription: opening_hours.opening_time_description,
						openingTimeHolidayInfo: opening_hours.opening_time_holiday_info,
						openingTimeVisible: opening_hours.opening_time_visible
				  }
				: undefined,
			softwareKeys: data.software_keys,
			subject: subject
				? {
						ico: subject.ico,
						id: subject.id
				  }
				: undefined
		}
	}

	_phoneMap(phone) {
		return {
			default: phone.default,
			id: phone.id,
			isDeleted: phone.is_deleted,
			name: phone.name,
			phone: phone.phone,
			sourceAsk: !phone.is_deleted && phone.service_name === SERVICE_NAME_FIRMY,
			sourceLocal: !phone.is_deleted && phone.service_name === SERVICE_NAME_SAUTO,
			verified: phone.verified
		}
	}

	_emailMap(email) {
		return {
			default: email.default,
			id: email.id,
			isDeleted: email.is_deleted,
			name: email.name,
			email: email.email,
			sourceAsk: !email.is_deleted && email.service_name === SERVICE_NAME_FIRMY,
			sourceLocal: !email.is_deleted && email.service_name === SERVICE_NAME_SAUTO,
			verified: email.verified
		}
	}
}
