
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AddVINView'] = {
  notLoggedTitle: function(d) { return "Pro vložení inzerátu je třeba se přihlásit"; },
  title: function(d) { return "Prodejte svůj vůz snadno a rychle"; },
  subTitleAboutUs: function(d) { return "Měsíčně naše stránky navštíví přes 1 000 000 reálných uživatelů."; },
  subTitleRecommendation: function(d) { return "Pro hladké zadání inzerátu si připravte technický průkaz vozidla."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	