import UserwebBaseController from 'app/base/UserwebBaseController'
import JsonLd from 'app/helpers/jsonLd/JsonLd'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import ROUTE_NAMES from 'app/base/RouteNames'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import clearObject from 'app/helpers/clearObject/clearObject'
import AdvertDetailMeta from './AdvertDetailMeta'
import AdvertDetailOperatingLeaseMeta from './AdvertDetailOperatingLeaseMeta'
import { exceptionalHeaderModelIds, SDN, CATEGORIES, SHOW_CAR_INSURANCE } from 'app/base/Constants'
import STATE_KEYS from 'app/page/userweb/advertDetail/AdvertDetailStateKeys'
import { LIST_TYPE, STATUS_CONSTANTS } from 'app/model/advert/AdvertConstants'
import FavoriteListUrlConvertor from 'app/page/userweb/favoriteList/FavoriteListUrlConvertor'
import ReviewsExtension from 'app/component/reviews/ReviewsExtension'
import ReviewsUrlConvertor from 'app/component/reviews/ReviewsUrlConvertor'
import ReportExtension from 'app/component/report/ReportExtension'
import { HttpStatusCodes } from '@inzeraty/helpers'

import 'app/base/BaseCS.json'
import 'app/page/userweb/advertDetail/detailSubheader/DetailSubheaderCS.json'

/**
 * @class AdvertDetailController
 * @namespace app.page.userweb.advertDetail
 * @class UserwebAdvertDetailController
 * @module app
 * @submodule app.page
 */
export default class AdvertDetailController extends UserwebBaseController {
	/**
	 * @method constructor
	 * @constructor
	 * @param {ns.app.helpers.dependenciesHelper.DependenciesHelper} dependenciesHelper
	 * @param {@inzeraty.models.AdvertService} advertService
	 */
	constructor(
		dependenciesHelper,
		advertService,
		popupDemandFormExtension,
		favoriteService,
		reviewsExtension,
		reportExtension,
		carCompareService,
		articlesService,
		dotAnalytic
	) {
		super(dependenciesHelper)

		this._advertService = advertService
		this._popupDemandFormExtension = popupDemandFormExtension
		this._favoriteService = favoriteService
		this._reviewsExtension = reviewsExtension
		this._reportExtension = reportExtension
		this._carCompareService = carCompareService
		this._articlesService = articlesService
		this._dotAnalytic = dotAnalytic

		this._router = this._utils.$Router
		this._dictionary = this._utils.$Dictionary

		this._advertPromise = undefined

		this._setOperatingLeaseVariant = this._setOperatingLeaseVariant.bind(this)
	}

	/**
	 * Typy návratů z detailu na jiné stránky.
	 */
	static get historyBackType() {
		return Object.freeze({
			BACK_TO_SELLER: 'historyBackToSeller',
			BACK_TO_ADVERT_LIST: 'historyBackToAdvertList',
			BACK_TO_ADVERT_DETAIL: 'historyBackToAdvertDetail'
		})
	}

	init() {
		super.init()

		this.addExtension(this._popupDemandFormExtension)
		this.addExtension(this._reviewsExtension)
		this.addExtension(this._reportExtension)
	}

	/**
	 * Load advert for page.
	 *
	 * @method load
	 * @return {Object}
	 */
	load() {
		const advertId = this._getAdvertId()
		const { prevRouteName, prevRouteParams } = this._getPreviousRouteData()
		const advertEntityAndHttpStatusPromise = this._getAdvertEntity(advertId)
		const advertEntityPromise = advertEntityAndHttpStatusPromise.then(
			({ advertEntity } = {}) => advertEntity
		)
		const cachedAdvertEntity = this._getAdvertEntityCache(advertId, prevRouteName)
		this._checkUrl(advertEntityPromise)
		const isAdvertEntityLoaded = advertEntityPromise.then(() => true)

		const { enabled: isPreviewModeEnabled } = this._isPreviewModeEnabled()

		if (!advertId) {
			this.status = HttpStatusCodes.NOT_FOUND
		}

		return super.load({
			[STATE_KEYS.HTTP_STATUS]: this._setHttpStatus(advertEntityAndHttpStatusPromise),
			[STATE_KEYS.RELATED_ADVERT_ENTITIES]: [],
			[STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise,
			[STATE_KEYS.CACHED_ADVERT_ENTITY]: cachedAdvertEntity,
			[STATE_KEYS.PREV_ROUTE_NAME]: prevRouteName,
			[STATE_KEYS.PREV_ROUTE_PARAMS]: prevRouteParams,
			[STATE_KEYS.PREV_NEXT_ADVERT]: this._getPrevNextAdvert(
				advertId,
				cachedAdvertEntity,
				prevRouteName,
				prevRouteParams
			),
			[STATE_KEYS.GO_BACK_DATA]: this._getGoBackData(
				advertEntityPromise,
				prevRouteName,
				prevRouteParams
			),
			[STATE_KEYS.IS_ADVERT_ENTITY_LOADED]: isAdvertEntityLoaded,
			[STATE_KEYS.IS_PREVIEW_MODE]: isPreviewModeEnabled,
			[ReviewsExtension.STATE_IDS.PARAMS]: this._getReviewsAndPaginationParams(advertEntityPromise),
			[ReportExtension.STATE_IDS.ADVERT_ENTITY]: advertEntityPromise,
			[STATE_KEYS.IS_INACTIVE_MODE]: advertEntityPromise.then((advertEntity) =>
				this._isInactiveMode(advertEntity)
			),
			[STATE_KEYS.IS_PREMISE_ADVERTS_COUNT_LOADED]: false,
			[STATE_KEYS.ARTICLES]: undefined,
			[STATE_KEYS.IS_OPERATING_LEASE_PAGE]: this._isOperatingLeasePage(),
			[STATE_KEYS.SELECTED_OPERATING_LEASE_VARIANT]: this._getOperatingLeaseVariant(
				advertEntityPromise
			),
			[STATE_KEYS.SET_SELECTED_OPERATING_LEASE_VARIANT]: this._setOperatingLeaseVariant,
			[STATE_KEYS.MTLP_PRICES]: this._getMTLPPrices(advertEntityPromise)
		})
	}

	update(prevParams) {
		const {
			[STATE_KEYS.PREV_ROUTE_NAME]: prevRouteName,
			[STATE_KEYS.PREV_ROUTE_PARAMS]: prevRouteParams
		} = this.getState()

		this._advertPromise = undefined
		this._resetAdvertState()

		const advertId = this._getAdvertId()
		const cachedAdvertEntity = this._getAdvertEntityCache(advertId, prevRouteName, true)
		const advertEntityAndHttpStatusPromise = this._getAdvertEntity(advertId)
		const advertEntityPromise = advertEntityAndHttpStatusPromise.then(
			({ advertEntity } = {}) => advertEntity
		)

		this._checkUrl(advertEntityPromise)
		advertEntityPromise.then(() => {
			this.setState({
				[STATE_KEYS.IS_ADVERT_ENTITY_LOADED]: true
			})
		})

		const { enabled: isPreviewModeEnabled } = this._isPreviewModeEnabled()

		if (!advertId) {
			this.status = HttpStatusCodes.NOT_FOUND
		}

		return super.update(prevParams, {
			[STATE_KEYS.HTTP_STATUS]: this._setHttpStatus(advertEntityAndHttpStatusPromise),
			[STATE_KEYS.RELATED_ADVERT_ENTITIES]: [],
			[STATE_KEYS.CACHED_ADVERT_ENTITY]: cachedAdvertEntity,
			[STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise,
			[STATE_KEYS.PREV_NEXT_ADVERT]: this._getPrevNextAdvert(
				advertId,
				cachedAdvertEntity,
				prevRouteName,
				prevRouteParams
			),
			[STATE_KEYS.GO_BACK_DATA]: this._getGoBackData(
				advertEntityPromise,
				prevRouteName,
				prevRouteParams
			),
			[STATE_KEYS.IS_ADVERT_ENTITY_LOADED]: false,
			[STATE_KEYS.IS_PREVIEW_MODE]: isPreviewModeEnabled,
			[ReviewsExtension.STATE_IDS.PARAMS]: this._getReviewsAndPaginationParams(advertEntityPromise),
			[STATE_KEYS.IS_INACTIVE_MODE]: advertEntityPromise.then((advertEntity) =>
				this._isInactiveMode(advertEntity)
			),
			[STATE_KEYS.IS_PREMISE_ADVERTS_COUNT_LOADED]: false,
			[STATE_KEYS.IS_OPERATING_LEASE_PAGE]: this._isOperatingLeasePage(),
			[STATE_KEYS.SELECTED_OPERATING_LEASE_VARIANT]: this._getOperatingLeaseVariant(
				advertEntityPromise
			),
			[STATE_KEYS.MTLP_PRICES]: this._getMTLPPrices(advertEntityPromise)
		})
	}

	async onFetchArticles(params) {
		try {
			const defaultParams = {
				limit: 5, // pocet clanku
				is_shown: true
			}

			const entitiesAndPagination = await this._articlesService.getArticlesCachedDetail(
				Object.assign({}, defaultParams, params)
			)

			this.setState({
				[STATE_KEYS.ARTICLES]: entitiesAndPagination
			})
		} catch (e) {
			this.setState({
				[STATE_KEYS.ARTICLES]: undefined
			})
		}
	}

	async _getMTLPPrices(advertEntityPromise) {
		try {
			const {
				manufacturerCb: { name: manufacturerName = '' } = {},
				modelCb: { name: modelName = '' } = {},
				engineVolume,
				enginePower,
				category
			} = (await advertEntityPromise) || {}

			if (
				category?.id === CATEGORIES.PASSENGER_CARS.id &&
				!this._isOperatingLeasePage() &&
				engineVolume &&
				SHOW_CAR_INSURANCE
			) {
				this._dotAnalytic.hit({
					action: 'car-insurance',
					actionType: 'detail-loaded'
				})

				const mtlpParams = {
					manufacturer_name: manufacturerName,
					model_name: modelName,
					engine_power: enginePower,
					engine_volume: engineVolume
				}

				return await this._advertService.getMTPLPrices(mtlpParams)
			}
		} catch (e) {
			return undefined
		}
	}

	onSetCarCompare() {
		this._getCompareCars().then((compareData) => {
			this.setState({
				[STATE_KEYS.CAR_COMPARE]: compareData
			})
		})
	}

	onCarInsuranceHit() {
		this._dotAnalytic.hit({
			action: 'car-insurance',
			actionType: 'show-section'
		})
	}

	onSetPremiseAdvertsCount() {
		this._getPremiseAdvertsCount().then((premiseAdvertsCount) => {
			this.setState({
				[STATE_KEYS.PREMISE_ADVERTS_COUNT]: premiseAdvertsCount,
				[STATE_KEYS.IS_PREMISE_ADVERTS_COUNT_LOADED]: true
			})
		})
	}

	async _setHttpStatus(advertEntityAndHttpStatusPromise) {
		const { httpStatus } = await advertEntityAndHttpStatusPromise

		if (httpStatus) {
			// prenastavime vychozi HTTP status
			this.status = httpStatus
		}

		return this.status
	}

	_isIAAdvertPreview() {
		return [
			ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_PREVIEW,
			ROUTE_NAMES.INTERNAL_ADMIN.OPERATING_LEASE_PREVIEW
		].includes(this.getRouteName())
	}

	_isOperatingLeasePage() {
		return [
			ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL,
			ROUTE_NAMES.INTERNAL_ADMIN.OPERATING_LEASE_PREVIEW
		].includes(this.getRouteName())
	}

	async _getOperatingLeaseVariant(advertEntityPromise) {
		if (this._isOperatingLeasePage()) {
			const { operatingLeaseVariants = [] } = (await advertEntityPromise) || {}

			const params = this.getRouteParams() || {}
			const {
				[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS
					.OPERATING_LEASE_VARIANT_ID]: operatingLeaseVariantId
			} = params

			if (operatingLeaseVariantId) {
				// odmazeme z url parametr pro vybranou variantu operativniho leasingu
				if (typeof history !== 'undefined') {
					const trimmedParams = { ...params }
					delete trimmedParams[
						AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_VARIANT_ID
					]

					history.replaceState(
						history.state,
						'',
						this._router.link(this.getRouteName(), trimmedParams)
					)
				}

				// varianta operativniho leasingu je vybrana pomoci parametru v URL
				const selectedVariant = operatingLeaseVariants.find(
					(variant) => variant.id === Number(operatingLeaseVariantId)
				)

				if (selectedVariant) {
					return selectedVariant
				}
			}

			// jinak vybereme nejlevnejsi variantu jako vychozi
			const sortedVariantsByPrice = [...operatingLeaseVariants].sort(
				(a, b) => a.priceWithVat - b.priceWithVat
			)
			const [cheapestVariant] = sortedVariantsByPrice

			return cheapestVariant
		}
	}

	_setOperatingLeaseVariant(variant) {
		this.setState({
			[STATE_KEYS.SELECTED_OPERATING_LEASE_VARIANT]: variant
		})
	}

	onDownloadRelatedAdverts() {
		const {
			[STATE_KEYS.IS_OPERATING_LEASE_PAGE]: isOperatingLeasePage,
			[STATE_KEYS.ADVERT_ENTITY]: advertEntity = {}
		} = this.getState()
		const { id, premise } = advertEntity

		if (premise) {
			this._advertService
				.getRelatedAdvertList({
					id,
					[AdvertDetailUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLeasePage
				})
				.then((advertEntities) => {
					this.setState({
						[STATE_KEYS.RELATED_ADVERT_ENTITIES]: advertEntities
					})
				})
				.catch(() => {})
		}
	}

	onAdvertClick() {
		this._historyStorage.setActualData({
			[AdvertDetailController.historyBackType.BACK_TO_ADVERT_DETAIL]: true
		})
	}

	async getDataForSsp() {
		const advertId = this._getAdvertId()

		const { httpStatus, advertEntity } = await this._getAdvertEntity(advertId)
		const { enabled: isPreviewModeEnabled } = this._isPreviewModeEnabled()

		// jestli vubec chceme volat/zobrazovat reklamu
		const shouldBeSspCalled =
			httpStatus === HTTP_STATUS_CODES.OK &&
			!this._isInactiveMode(advertEntity) &&
			!this._isIAAdvertPreview() &&
			!isPreviewModeEnabled

		if (shouldBeSspCalled) {
			const routeName = this.getRouteName()

			return {
				routeName,
				pageState: {
					advertEntity
				}
			}
		} else {
			return {}
		}
	}

	_getCompareCars() {
		const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity = {} } = this.getState()
		const httpStatus = this.getHttpStatus()

		if (httpStatus === HTTP_STATUS_CODES.OK && !this._isInactiveMode(advertEntity)) {
			const { id } = advertEntity

			return this._carCompareService
				.getCompareCars(id)
				.then((compareData) => compareData)
				.catch(() => undefined)
		}

		return Promise.resolve(undefined)
	}

	_getReviewsAndPaginationParams(advertEntityPromise) {
		const { ADVERT_ENTITY, TYPE } = ReviewsExtension.PARAMS

		const {
			URL_APP_PARAMS: { PER_PAGE }
		} = UrlConvertor.constants

		return advertEntityPromise.then((advertEntity) => {
			return {
				[ADVERT_ENTITY]: advertEntity,
				[PER_PAGE]: ReviewsUrlConvertor.constants.DEFAULTS.PER_PAGE,
				[TYPE]: ReviewsExtension.TYPE.ALL
			}
		})
	}

	/**
	 * Vrati data stranky posledni navstivene routy
	 *
	 * @method _getPreviousRouteData
	 * @private
	 * @returns {Object}
	 */
	_getPreviousRouteData() {
		if (this._historyStorage.getPreviousRouteName()) {
			const currentRouteName = this._historyStorage.getActualRouteName()
			let prevIndex = this._historyStorage.getActualIndex() - 1

			while (this._historyStorage.getIndexRouteName(prevIndex) === currentRouteName) {
				prevIndex--
			}

			return {
				prevRouteName: this._historyStorage.getIndexRouteName(prevIndex),
				prevRouteParams: this._historyStorage.getIndexRouteParams(prevIndex)
			}
		} else {
			return {}
		}
	}

	_getAdvertId() {
		const { URL_APP_PARAMS } = AdvertDetailUrlConvertor.constants
		const {
			[URL_APP_PARAMS.ADVERT_ID]: advertId,
			[URL_APP_PARAMS.ADVERT_ID_OLD]: advertIdOld
		} = this.getRouteParams()

		const finalAdvertId = Number(advertId || advertIdOld)

		if (isNaN(finalAdvertId)) {
			return ''
		} else {
			return finalAdvertId
		}
	}

	_getAdvertDetailParams() {
		const { URL_API_PARAMS } = AdvertDetailUrlConvertor.constants
		return this._isOperatingLeasePage() ? { [URL_API_PARAMS.OPERATING_LEASE]: true } : undefined
	}

	_isPreviewModeEnabled() {
		const { URL_APP_PARAMS, URL_APP_PARAMS_VALUES } = AdvertDetailUrlConvertor.constants

		const { [URL_APP_PARAMS.PREVIEW]: preview } = this.getRouteParams()

		if (this._isIAAdvertPreview()) {
			return { enabled: true }
		} else if (preview) {
			if (preview === URL_APP_PARAMS_VALUES.PREVIEW) {
				return { enabled: true }
			} else {
				return { error: true }
			}
		} else {
			return { enabled: false }
		}
	}

	/**
	 * Vrátí cache s uloženými entitami inzerátů.
	 * ak sa vola vo funkcii update, tak berieme data z tabulky advertList
	 *
	 * @param {Number} advertId
	 * @param {String} listRouteName
	 * @param {Boolean} update
	 */
	async _getAdvertEntityCache(advertId, listRouteName, update = false) {
		let listType

		if (update) {
			if (listRouteName === ROUTE_NAMES.USERWEB.SELLER) {
				listType = LIST_TYPE.SELLER
			} else if (listRouteName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES) {
				listType = LIST_TYPE.SELLER_WITH_OPERATING_LEASES
			} else if (listRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES) {
				listType = LIST_TYPE.REGULAR_WITH_OPERATING_LEASES
			} else {
				listType = LIST_TYPE.REGULAR
			}
		} else {
			if (listRouteName === ROUTE_NAMES.USERWEB.SELLER) {
				listType = LIST_TYPE.SELLER
			} else if (listRouteName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES) {
				listType = LIST_TYPE.SELLER_WITH_OPERATING_LEASES
			} else if (listRouteName === ROUTE_NAMES.USERWEB.HOMEPAGE) {
				listType = LIST_TYPE.HOMEPAGE
			} else if (listRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES) {
				listType = LIST_TYPE.REGULAR_WITH_OPERATING_LEASES
			} else {
				listType = LIST_TYPE.REGULAR
			}
		}

		const cachedAdvertEntity = await this._advertService.getAdvertEntityCache(advertId, listType)

		return cachedAdvertEntity
	}

	/**
	 * Vrati data předchozího a nasledujiciho inzeratu z cache
	 *
	 * @method _getPrevNextAdvert
	 * @param {Number} advertId
	 * @param {Promise} cachedAdvertEntity
	 * @param {String} listRouteName Vypis, ze ktereho jsme se dostali na detail
	 * @param {Object} listRouteParams
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _getPrevNextAdvert(advertId, cachedAdvertEntity, listRouteName, listRouteParams) {
		const advertEntity = await cachedAdvertEntity

		if (advertEntity && listRouteName) {
			if (
				listRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST ||
				listRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
			) {
				const params = await this._getRegularListParams(
					advertEntity,
					listRouteName,
					listRouteParams
				)

				return {
					listRouteName,
					prev: await this._advertService.getPrevAdvert(advertId, params),
					next: await this._advertService.getNextAdvert(advertId, params)
				}
			} else if (
				listRouteName === ROUTE_NAMES.USERWEB.SELLER ||
				listRouteName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
			) {
				const params = await this._getSellerListParams(advertEntity, listRouteName, listRouteParams)

				return {
					listRouteName,
					prev: await this._advertService.getPrevAdvert(advertId, params),
					next: await this._advertService.getNextAdvert(advertId, params)
				}
			} else {
				return {}
			}
		} else if (listRouteName === ROUTE_NAMES.USERWEB.FAVORITE_LIST) {
			const params = FavoriteListUrlConvertor.getApiParams(this.getRouteParams())

			return {
				listRouteName,
				prev: await this._favoriteService.getPrevAdvert(advertId, params),
				next: await this._favoriteService.getNextAdvert(advertId, params)
			}
		} else {
			return {}
		}
	}

	/**
	 * Vrati parametry z vypisu, ve kterem se nachazi aktualni inzerat
	 *
	 * @method _getRegularListParams
	 * @param {Promise} cachedAdvertEntity
	 * @param {String} listRouteName
	 * @param {Object} listRouteParams
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _getRegularListParams(cachedAdvertEntity, listRouteName, listRouteParams) {
		const {
			URL_API_PARAMS: { OFFSET }
		} = UrlConvertor.constants

		const advertEntity = await cachedAdvertEntity

		if (!this._baseAdvertListParams && advertEntity) {
			const { category: { id } = {} } = advertEntity

			this._baseAdvertListParams = Object.assign(
				{},
				AdvertListUrlConvertor.getApiParams(listRouteParams, listRouteName),
				UrlConvertor.getApiSorting(listRouteParams),
				{
					[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: id,
					[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]:
						listRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
				}
			)
		}

		return Object.assign({}, this._baseAdvertListParams, {
			[OFFSET]: await this._getAdvertOffsetValue(advertEntity)
		})
	}

	/**
	 * Vrati parametry z vypisu, ve kterem se nachazi aktualni inzerat
	 *
	 * @method _getSellerListParams
	 * @param {Promise} cachedAdvertEntity
	 * @param {String} listRouteName
	 * @param {Object} listRouteParams
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _getSellerListParams(cachedAdvertEntity, listRouteName, listRouteParams) {
		const {
			URL_API_PARAMS: { OFFSET }
		} = UrlConvertor.constants

		const advertEntity = await cachedAdvertEntity

		if (!this._baseAdvertListParams && advertEntity) {
			const {
				premise: { id: sellerId },
				category: { id: categoryId } = {}
			} = advertEntity

			this._baseAdvertListParams = Object.assign(
				{},
				SellerUrlConvertor.getApiParams(listRouteParams, listRouteName),
				UrlConvertor.getApiSorting(listRouteParams),
				{
					[SellerUrlConvertor.constants.URL_API_PARAMS.SELLER_ID]: sellerId,
					[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId,
					[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]:
						listRouteName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
				}
			)
		}

		return Object.assign({}, this._baseAdvertListParams, {
			[OFFSET]: await this._getAdvertOffsetValue(cachedAdvertEntity)
		})
	}

	/**
	 * Vrati hodnotu offset pro aktualni inzerat
	 * Perametr offset ukazuje na jake strance inzerat je
	 * Offset nastavujeme pri vkladani do cache
	 *
	 * @method _getAdvertOffsetValue
	 * @param {Promise} cachedAdvertEntity
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _getAdvertOffsetValue(cachedAdvertEntity) {
		const {
			URL_API_PARAMS: { OFFSET }
		} = UrlConvertor.constants
		const advertEntity = await cachedAdvertEntity

		if (advertEntity) {
			return advertEntity[OFFSET] || 0
		} else {
			return 0
		}
	}

	_getAdvertEntity(advertId) {
		const { enabled: isPreviewModeEnabled, error: previewModeError } = this._isPreviewModeEnabled()

		if (!this._advertPromise) {
			this._advertPromise = this._advertService
				.getAdvertDetail(advertId, this._getAdvertDetailParams(), { cache: !isPreviewModeEnabled })
				.then((advertEntity) => {
					if (
						this._isIAAdvertPreview() ||
						(isPreviewModeEnabled && advertEntity.status !== STATUS_CONSTANTS.DELETED)
					) {
						// nahled z IA nebo z KA
						return {
							httpStatus: HTTP_STATUS_CODES.OK,
							advertEntity
						}
					} else {
						if (previewModeError) {
							// nahled z KA ma spatny format url
							return {
								httpStatus: HTTP_STATUS_CODES.BAD_REQUEST,
								advertEntity: undefined
							}
						} else if (
							advertEntity.status === STATUS_CONSTANTS.DISABLED ||
							advertEntity.status === STATUS_CONSTANTS.DELETED
						) {
							// zakazany nebo smazany inzerat nezobrazujeme (advertEntitu vracime
							// jen kvuli analytice)
							return {
								httpStatus: HTTP_STATUS_CODES.GONE,
								advertEntity
							}
						} else if (
							this._isInactiveMode(advertEntity) ||
							advertEntity.status === STATUS_CONSTANTS.ACTIVE
						) {
							// neaktivni inzerat, pro ktery zobrazujeme specialni stranku,
							// nebo skutecne aktivni inzerat
							return {
								httpStatus: HTTP_STATUS_CODES.OK,
								advertEntity
							}
						}
					}
				})
		}

		return this._advertPromise
	}

	_isInactiveMode(advertEntity = {}) {
		const { enabled: isPreviewModeEnabled } = this._isPreviewModeEnabled()

		return !isPreviewModeEnabled && advertEntity.status === STATUS_CONSTANTS.INACTIVE
	}

	/**
	 * Ziska data pro tlacitko zpet
	 *
	 * @method _getGoBackData
	 * @param {Promise<AdvertEntity>} advertEntityPromise
	 * @param {String} prevRouteName
	 * @param {Object} prevRouteParams
	 * @private
	 **/
	async _getGoBackData(advertEntityPromise, prevRouteName, prevRouteParams) {
		const goBackData = {
			text: '',
			url: ''
		}

		const historyPrevData = this._historyStorage.getPreviousData()

		const getReturnUrl = (routeParamsOverride) => {
			const params = clearObject(Object.assign({}, prevRouteParams, routeParamsOverride))

			if (parseInt(params[UrlConvertor.constants.URL_APP_PARAMS.PAGE]) === 1) {
				delete params[UrlConvertor.constants.URL_APP_PARAMS.PAGE]
			}

			return this._router.link(prevRouteName, params)
		}

		const getPageParamForReturnUrl = async () => {
			const advertId = this._getAdvertId()

			if (advertId) {
				const {
					DEFAULTS: { PER_PAGE },
					URL_APP_PARAMS: { PAGE }
				} = UrlConvertor.constants

				// zkusime najit, jestli nemame inzerat v cache, abysme zjistili, z jake stranky
				// vypisu jsme na detail prisli
				const cachedAdvertEntityPromise = this._getAdvertEntityCache(
					Number(advertId),
					prevRouteName
				)

				const advertListPage =
					(await this._getAdvertOffsetValue(cachedAdvertEntityPromise)) / PER_PAGE + 1

				return { [PAGE]: advertListPage }
			}

			return Promise.resolve({})
		}

		if (historyPrevData[AdvertDetailController.historyBackType.BACK_TO_ADVERT_DETAIL]) {
			// Tato podmínka odpovídá přechodu na detail z jiného detailu proklikem na náhled inzerátu.
			// Týká se např. prokliku na podobný inzerát prodejce. Netýká se přechodu na
			// předchozí/další inzerát.
			goBackData.text = this._dictionary.get('DetailSubheader.backToDetail')
			goBackData.url = this._router.link(
				this._historyStorage.getPreviousRouteName(),
				this._historyStorage.getPreviousRouteParams()
			)
		} else if (
			prevRouteName &&
			(prevRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST ||
				prevRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES ||
				prevRouteName === ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST ||
				prevRouteName === ROUTE_NAMES.USERWEB.FAVORITE_LIST)
		) {
			// Toto odpovídá situaci, kdy se na detail dostane z výpisu inzerátů (i z výpisu oblíbených).
			goBackData.text = this._dictionary.get('DetailSubheader.backToList')
			goBackData.url = getReturnUrl()
			goBackData.callback = () => {
				this._historyStorage.setActualData({
					[AdvertDetailController.historyBackType.BACK_TO_ADVERT_LIST]: true
				})
			}

			// nechceme blokovat vykresleni tlacitka zpet. Pokud bychom cekali na dobehnuti
			// teto funkce, tak by na strance problikl placeholder, to pusobilo rusive a je
			// to vlastne zbytecne
			getPageParamForReturnUrl().then((pageParam) => {
				this.setState({
					[STATE_KEYS.GO_BACK_DATA]: Object.assign({}, goBackData, {
						url: getReturnUrl(pageParam)
					})
				})
			})
		} else if (
			prevRouteName &&
			(prevRouteName === ROUTE_NAMES.USERWEB.SELLER ||
				prevRouteName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES)
		) {
			// Toto odpovídá situaci, kdy se na detail dostane z detailu prodejce.
			goBackData.text = this._dictionary.get('DetailSubheader.backToSeller')
			goBackData.url = getReturnUrl()
			goBackData.callback = () => {
				this._historyStorage.setActualData({
					[AdvertDetailController.historyBackType.BACK_TO_SELLER]: true
				})
			}

			// nechceme blokovat vykresleni tlacitka zpet. Pokud bychom cekali na dobehnuti
			// teto funkce, tak by na strance problikl placeholder, to pusobilo rusive a je
			// to vlastne zbytecne
			getPageParamForReturnUrl().then((pageParam) => {
				this.setState({
					[STATE_KEYS.GO_BACK_DATA]: Object.assign({}, goBackData, {
						url: getReturnUrl(pageParam)
					})
				})
			})
		} else {
			// je potreba pockat na nacteni advertEntity, nastaveni na undefined bude
			// mit za nasledek zobrazeni placeholderu misto tlacitka zpet
			this.setState({
				[STATE_KEYS.GO_BACK_DATA]: undefined
			})

			const advertEntity = (await advertEntityPromise) || {}

			const { category, manufacturerCb, modelCb } = advertEntity

			if (this._isInactiveMode(advertEntity)) {
				// pro neaktivni inzeraty, na ktere jsme pravdepodobne presli z vyhledavace,
				// se chceme vracet na HP
				goBackData.text = this._dictionary.get('Base.goBackHome')
				goBackData.url = this._router.link(ROUTE_NAMES.USERWEB.HOMEPAGE)
			} else if (category && manufacturerCb && modelCb) {
				const currentRouteName = this.getRouteName()

				// vracime se na vypis inzeratu pro danou znacku a model
				let brand = manufacturerCb.name
				let model = modelCb.name

				// Pro modely "Ostatní" zobrazíme jméno hlavní kategorie a výrobce.
				if (exceptionalHeaderModelIds.indexOf(modelCb.value) >= 0) {
					brand = category.name
					model = manufacturerCb.name
				}

				goBackData.text = this._dictionary.get('DetailSubheader.backToCategory', {
					BRAND: brand,
					MODEL: model
				})
				const { URL_APP_PARAMS } = AdvertListUrlConvertor.constants

				goBackData.url = this._router.link(
					currentRouteName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
						? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
						: ROUTE_NAMES.USERWEB.ADVERT_LIST,
					{
						[URL_APP_PARAMS.CATEGORY_NAME]: category.seoName,
						[URL_APP_PARAMS.BRAND_NAME]: manufacturerCb.seoName,
						[URL_APP_PARAMS.MODEL_NAME]: modelCb.seoName
					}
				)
			} else {
				goBackData.text = this._dictionary.get('Base.goBackHome')
				goBackData.url = this._router.link(ROUTE_NAMES.USERWEB.HOMEPAGE)
			}
		}

		return goBackData
	}

	/**
	 * Zkontroluje jestli je url ve správném tvaru. Pokud není, tak redirectne na správný tvar.
	 *
	 * @method _checkUrl
	 * @private
	 * @param {Promise} advertEntity
	 */
	_checkUrl(advertEntity) {
		// - pro nahled inzeratu z IA nebudeme kontrolovat URL
		if (!this._isIAAdvertPreview()) {
			const { URL_APP_PARAMS } = AdvertDetailUrlConvertor.constants
			const {
				brandName,
				categoryName,
				modelName,
				[URL_APP_PARAMS.ADVERT_ID_OLD]: advertIdOld
			} = this.getRouteParams()

			advertEntity.then((advertData = {}) => {
				const { category = {}, manufacturerCb = {}, modelCb = {}, oldId, id } = advertData

				const { seoName: realCategorySeoName } = category
				const { seoName: realBrandSeoName } = manufacturerCb
				const { seoName: realModelSeoName } = modelCb

				if (
					realCategorySeoName &&
					realBrandSeoName &&
					realModelSeoName &&
					(brandName !== realBrandSeoName ||
						categoryName !== realCategorySeoName ||
						modelName !== realModelSeoName ||
						advertIdOld) // pokud máme v url id inzerátu ve starém parametru, tak přesměrujeme na novou podobu
				) {
					const newParams = Object.assign(
						{},
						{
							[URL_APP_PARAMS.CATEGORY_NAME]: realCategorySeoName,
							[URL_APP_PARAMS.BRAND_NAME]: realBrandSeoName,
							[URL_APP_PARAMS.MODEL_NAME]: realModelSeoName,
							[URL_APP_PARAMS.ADVERT_ID]: oldId || id
						}
					)

					const url = this._router.link(this.getRouteName(), newParams)
					this._router.redirect(url, { httpStatus: HTTP_STATUS_CODES.MOVED_PERMANENTLY })
				}
			})
		}
	}

	/**
	 * Vrati pocet inzeratu prodejce
	 *
	 * @method _getPremiseAdvertsCount
	 * @private
	 * @return {Promise<Number>} Pocet inzeratu prodejce
	 **/
	async _getPremiseAdvertsCount() {
		const {
			[STATE_KEYS.IS_OPERATING_LEASE_PAGE]: isOperatingLeasePage,
			[STATE_KEYS.ADVERT_ENTITY]: advertEntity = {}
		} = this.getState()
		const { premise } = advertEntity

		if (!premise) {
			return Promise.resolve(0)
		} else {
			try {
				const total = await this._advertService.getAdvertsTotalCount(
					{
						[UrlConvertor.constants.URL_API_PARAMS.LIMIT]: 0,
						[SellerUrlConvertor.constants.URL_API_PARAMS.SELLER_ID]: premise.id,
						[UrlConvertor.constants.URL_API_PARAMS.OFFSET]: 0,
						[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLeasePage
					},
					{},
					false
				)

				return total
			} catch (err) {
				return Promise.resolve(0)
			}
		}
	}

	onReturnFromPreview() {
		if (this._isIAAdvertPreview()) {
			window.close()
		} else if (this._historyStorage.getPreviousRouteName()) {
			this._historyStorage.goBack()
		} else {
			this._router.redirect(this._router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST))
		}
	}

	/**
	 * Set seo params.
	 *
	 * @method setSeoParams
	 * @param {Object} loadedResources
	 * @param {Core.Interface.MetaManager} metaManager
	 * @param {Core.Interface.Router} router
	 * @param {Core.Interface.Dictionary} dictionary
	 * @param {Object} settings
	 */
	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)
		const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity = {} } = loadedResources
		const { price, images = [] } = advertEntity

		const isOperatingLease = this._isOperatingLeasePage()

		const description = isOperatingLease
			? AdvertDetailOperatingLeaseMeta.getDetailDescription(dictionary, advertEntity)
			: AdvertDetailMeta.getDetailDescription(dictionary, advertEntity)
		const title = isOperatingLease
			? AdvertDetailOperatingLeaseMeta.getDetailTitle(dictionary, advertEntity)
			: AdvertDetailMeta.getDetailTitle(dictionary, advertEntity)
		const keywords = dictionary.get('Base.keywords')

		const advertImage = images.length ? images[0].url + SDN.w1024_WATERMARK : ''
		if (advertImage) {
			metaManager.setMetaProperty('og:image', advertImage)
		}

		metaManager.setTitle(title)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('keywords', keywords)

		metaManager.setMetaName('twitter:title', title)
		metaManager.setMetaName('twitter:description', description)

		metaManager.setMetaProperty('og:title', title)
		metaManager.setMetaProperty('og:description', description)

		// generovani json-LD
		const jsonLd = JsonLd.getUserwebAdvertDetail({
			itemName: title,
			itemDescription: description,
			itemPrice: price,
			itemImageURL: advertImage,
			itemURL: router.getUrl()
		})

		metaManager.setMetaProperty('JSON-LD', jsonLd)
	}

	/**
	 * Resetne stav po prechodu na dalsi inzerat
	 *
	 * @method _resetAdvertState
	 * @private
	 */
	_resetAdvertState() {
		this.setState({
			[STATE_KEYS.ADVERT_ENTITY]: undefined,
			[STATE_KEYS.CACHED_ADVERT_ENTITY]: undefined,
			[STATE_KEYS.PREV_NEXT_ADVERT]: undefined,
			[STATE_KEYS.PREMISE_ADVERTS_COUNT]: undefined,
			[STATE_KEYS.CAR_COMPARE]: undefined
		})
	}
}
