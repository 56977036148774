import React, { useState } from 'react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Responsive } from '@inzeraty/components'
import { RESPONSIVE } from 'app/base/Constants'
import Opener from './opener/Opener'
import SidePanel from './sidePanel/SidePanel'
import Popup from './popup/Popup'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const PremiseFiltersOpener = (props) => {
	const {
		premiseFiltersFormLines = DEFAULT_PROPS.ARRAY,
		changePremiseFiltersFormLines = DEFAULT_PROPS.FUNCTION,
		total,
		isLoadingTotal,
		loadPremiseAdvertsTotal = DEFAULT_PROPS.FUNCTION,
		loadAllBrandsForCategory = DEFAULT_PROPS.FUNCTION,
		loadAllModelsForBrand = DEFAULT_PROPS.FUNCTION
	} = props

	const [isOpen, setIsOpen] = useState(false)

	const open = () => setIsOpen(true)
	const close = () => setIsOpen(false)

	const closeWithRedirect = (formLines = []) => {
		close()
		changePremiseFiltersFormLinesAndRedirect(formLines.map(({ id, value }) => ({ id, value })))
	}

	const changePremiseFiltersFormLinesAndRedirect = (changes) => {
		changePremiseFiltersFormLines(changes, { autoRedirect: true })
	}

	const activeFiltersCount = premiseFiltersFormLines.filter((f) => !!f.value).length

	return (
		<>
			<Opener count={activeFiltersCount} onClick={open} />

			{isOpen && (
				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => {
						return (
							<Popup
								premiseFiltersFormLines={premiseFiltersFormLines}
								total={total}
								isLoadingTotal={isLoadingTotal}
								loadPremiseAdvertsTotal={loadPremiseAdvertsTotal}
								loadAllBrandsForCategory={loadAllBrandsForCategory}
								loadAllModelsForBrand={loadAllModelsForBrand}
								onClose={(internalFormLines) => closeWithRedirect(internalFormLines)}
								onUnmount={(internalFormLines) => {
									changePremiseFiltersFormLinesAndRedirect(
										internalFormLines.map(({ id, value }) => ({ id, value }))
									)
								}}
							/>
						)
					}}
					renderDesktopElement={() => {
						return (
							<SidePanel
								premiseFiltersFormLines={premiseFiltersFormLines}
								changePremiseFiltersFormLines={changePremiseFiltersFormLinesAndRedirect}
								total={total}
								isLoadingTotal={isLoadingTotal}
								onClose={() => close()}
								onOuterClick={() => close()}
							/>
						)
					}}
				/>
			)}
		</>
	)
}

PremiseFiltersOpener.propTypes = {
	premiseFiltersFormLines: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	total: PropTypes.number,
	isLoadingTotal: PropTypes.bool,
	changePremiseFiltersFormLines: PropTypes.func,
	loadPremiseAdvertsTotal: PropTypes.func,
	loadAllBrandsForCategory: PropTypes.func,
	loadAllModelsForBrand: PropTypes.func
}

export default React.memo(PremiseFiltersOpener)
