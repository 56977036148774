
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['EquipmentForm'] = {
  equipmentHeading: function(d) { return "Výbava"; },
  backToHistory: function(d) { return "Zpět na Historii vozidla"; },
  continueToPayment: function(d) { return "Pokračovat na Platbu"; },
  statusValidationErrorsUnknown: function(d) { return "Formulář se nepodařilo odeslat."; },
  statusCebiaDataLoad: function(d) { return "Automatické vyplnění výbavy"; },
  statusCebiaDataLoadDescription: function(d) { return "Podle parametrů vkládaného vozidla zjistíme hodnoty výbavy a vyplníme je za Vás. Po načtení výbavy překontrolujte, jestli odpovídá reálnému stavu a pokračujte dále."; },
  statusCebiaDataLoadButton: function(d) { return "Načíst výbavu vozidla"; },
  paymentPromise: function(d) { return "Zveřejnit inzerát"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	