import React, { useContext, useState, useEffect } from 'react'
import Context from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import HPLink from 'app/page/userweb/home/hpLink/HPLink'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import STATE_KEYS from 'app/page/userweb/home/HomeStateKeys'
import select from 'ima-plugin-select'
import { UserService } from '@inzeraty/models'
import UserwebBaseController from 'app/base/UserwebBaseController'
import LinkPlaceholder from 'app/page/userweb/home/placeholder/linkPlaceholder/LinkPlaceholder'
import PropTypes from 'prop-types'
import { UserPropTypes } from '@inzeraty/models'
import { Icon } from '@sznds/react'
import { ICON } from 'app/component/mySavedSearch/addButton/MySavedSearchAddButton'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import './MySavedSearchesLink.less'
import './MySavedSearchesLinkCS.json'

const CLASSNAME = 'c-my-saved-searches-link'

const MySavedSearchesLink = ({
	mySavedSearchesSummary = DEFAULT_PROPS.OBJECT,
	isLoadingMySavedSearchesSummary = false,
	userSelf
}) => {
	const localize = useLocalize()
	const { $Router: router } = useContext(Context)

	const [isLogged, setIsLogged] = useState(userSelf && UserService.isUserLogged(userSelf))

	useEffect(() => {
		setIsLogged(UserService.isUserLogged(userSelf))
	}, [userSelf])

	const renderMySavedSearchesLink = () => {
		const linkProps = {
			secondLine: ''
		}

		if (isLogged) {
			linkProps.href = router.link(ROUTE_NAMES.USERWEB.MY_SAVED_SEARCH)

			if (mySavedSearchesSummary && mySavedSearchesSummary.mySavedSearchesCount) {
				const { newAdvertsCount = 0, mySavedSearchesCount = 0 } = mySavedSearchesSummary

				linkProps.secondLine = (
					<>
						{`${localize('MySavedSearchesLink.searchCount')} ${Format.number(
							mySavedSearchesCount
						)} `}
						<span className={`${CLASSNAME}__count-plural`}>
							{`${localize('MySavedSearchesLink.searchCountPlural', {
								COUNT: Format.number(mySavedSearchesCount)
							})} `}
						</span>
						{localize('MySavedSearchesLink.searches')}
					</>
				)

				if (newAdvertsCount) {
					linkProps.renderRightIcon = () => (
						<span className={`${CLASSNAME}__count`}>
							{localize('MySavedSearchesLink.newAdvertsCount', {
								COUNT: Format.number(newAdvertsCount)
							})}
						</span>
					)
				}
			} else {
				linkProps.secondLine = localize('MySavedSearchesLink.noSearchesSaved')
			}
		} else {
			linkProps.secondLine = localize('MySavedSearchesLink.logoutSecondLine')
			linkProps.onClick = () => {
				LoginHelper.openLogin({
					sourceComponent: LoginHelper.SOURCE_COMPONENT.SAVED_SEARCH_SHOW
				})
			}
			linkProps.renderRightIcon = () => null
		}

		return (
			<HPLink
				firstLine={localize('MySavedSearchesLink.title')}
				iconLeft={<Icon symbol={ICON} />}
				{...linkProps}
			/>
		)
	}

	if (!isLoadingMySavedSearchesSummary) {
		return (
			<div
				className={classnames({
					[CLASSNAME]: true,
					[`${CLASSNAME}--logout`]: !isLogged
				})}
				data-dot='go-to-my-saved-search'
			>
				{renderMySavedSearchesLink()}
			</div>
		)
	} else {
		return <LinkPlaceholder />
	}
}

const selector = (state) => ({
	mySavedSearchesSummary: state[STATE_KEYS.MY_SAVED_SEARCHES_SUMMARY],
	isLoadingMySavedSearchesSummary: state[STATE_KEYS.IS_LOADING_MY_SAVED_SEARCHES_SUMMARY],
	userSelf: state[UserwebBaseController.STATE_KEYS.USER_SELF]
})

MySavedSearchesLink.propTypes = {
	mySavedSearchesSummary: PropTypes.object,
	isLoadingMySavedSearchesSummary: PropTypes.bool,
	userSelf: PropTypes.shape(UserPropTypes)
}

export default select(selector)(MySavedSearchesLink)
