import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Footer from 'app/component/footer/Footer'
import { useToasts, useNewToastEventListener } from 'app/component/toastMessages/ToastsHooks'
import ToastsContainer from 'app/component/toastMessages/container/ToastsContainer'
import Toast from 'app/component/toastMessages/toast/Toast'
import classnames from 'classnames'
import { useTouchState } from '@inzeraty/components'
import Branding from 'app/component/sspAdverts/branding/Branding'
import Context from 'ima/page/context'
import RouterEvents from 'ima/router/Events'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import Error from 'app/page/error/Error'

import './LayoutView.less'

const CLASSNAME = 'c-layout'

const BRANDING_CLASSNAME = `${CLASSNAME}__branding`
const BRANDING_CLASSNAME_CLASSIC = 'brandingClassic'
const BRANDING_CLASSNAME_WIDE = `${CLASSNAME}__branding--wide`
const BRANDING_CLASSNAME_IFRAME = 'iframeBranding'
const BRANDING_CONTENT_CLASSNAME = `${CLASSNAME}__branding-content`
const SKYSCRAPER_LIST_CLASSNAME = 'skyList'

export const RENDER_BRANDING_EVENT = 'RENDER_BRANDING_EVENT'

/**
 * @class LayoutView
 * @namespace app.component.layput
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.component
 */
export default function Layout({ httpStatus, children, className }) {
	const { toasts, addToast, removeToast } = useToasts()
	const isTouch = useTouchState()

	const [brandingClass, setBrandingClass] = useState('')
	const [brandingContentClass, setBrandingContentClass] = useState('')

	const context = useContext(Context)
	const sspHelper = context.SspHelper

	useNewToastEventListener(addToast)

	const { $Dispatcher } = useContext(Context)

	useEffect(() => {
		$Dispatcher.listen(RENDER_BRANDING_EVENT, addClassToLayout)

		return () => $Dispatcher.unlisten(RENDER_BRANDING_EVENT, addClassToLayout)
	}, [])

	// pri zmene routy musime odstranit pridane classy
	useEffect(() => {
		$Dispatcher.listen(RouterEvents.BEFORE_HANDLE_ROUTE, onAfterHandleRoute)

		return () => $Dispatcher.unlisten(RouterEvents.BEFORE_HANDLE_ROUTE, onAfterHandleRoute)
	}, [])

	// pri nacitani brandingu pridame na layout classu pre spravne vykreslenie
	const addClassToLayout = (data) => {
		const { isWide, isIframe, isSkyOnList } = data

		if (isIframe) {
			setBrandingClass(`${BRANDING_CLASSNAME} ${BRANDING_CLASSNAME_IFRAME}`)
		} else if (isWide) {
			const wideClass = isSkyOnList
				? `${BRANDING_CLASSNAME_WIDE} ${SKYSCRAPER_LIST_CLASSNAME}`
				: BRANDING_CLASSNAME_WIDE

			setBrandingClass(wideClass)
		} else {
			setBrandingClass(`${BRANDING_CLASSNAME} ${BRANDING_CLASSNAME_CLASSIC}`)
		}

		setBrandingContentClass(BRANDING_CONTENT_CLASSNAME)
	}

	const onAfterHandleRoute = () => {
		sspHelper.resetVisibilityElements()
		setBrandingClass('')
		setBrandingContentClass('')
	}

	const isSuccess = httpStatus === HTTP_STATUS_CODE.OK

	return (
		<>
			{/* atribut key nemazat, udájně se bez něj stránka v nějakých případech renderoval chybně */}
			<div key='layout-success' className={`${CLASSNAME}__wrapper`}>
				{isSuccess && (
					<>
						<Branding />
						<div className={classnames(className, CLASSNAME, brandingClass, isTouch && 'touch')}>
							<ToastsContainer>
								{toasts.map((toast) => (
									<Toast {...toast.data} key={toast.id} removeToast={() => removeToast(toast.id)} />
								))}
							</ToastsContainer>

							<div className={classnames(`${CLASSNAME}__content`, brandingContentClass)}>
								{children}
							</div>
							<Footer />
						</div>
					</>
				)}
			</div>

			{/* atribut key nemazat, udájně se bez něj stránka v nějakých případech renderoval chybně */}
			<div key='layout-error'>{!isSuccess && <Error status={httpStatus} />}</div>
		</>
	)
}

Layout.propTypes = {
	className: PropTypes.string,
	httpStatus: PropTypes.number,
	children: PropTypes.node
}

Layout.defaultProps = {
	className: '',
	httpStatus: HTTP_STATUS_CODE.OK
}
