export const FORM_LINES_IDS = {
	GUARANTEE_DATE: 'guarantee_date',
	EURO_LEVEL_CB: 'euro_level_cb',
	ENVIRONMENTAL_TAX_PAID: 'environmental_tax_paid',
	FIRST_OWNER: 'first_owner',
	ADJUSTMENTS_FOR_HANDICAPPED: 'adjustments_for_handicapped',
	CRASHED_IN_PAST: 'crashed_in_past',
	TUNING: 'tuning',
	SERVICE_BOOK: 'service_book'
}
