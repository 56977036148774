import UserwebBaseController from 'app/base/UserwebBaseController'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import GenericError from 'ima/error/GenericError'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import { getIdFromSeoName } from '../helper/IdFromSeoName'
import CatalogMeta from '../CatalogMeta'

export default class ModelLinesOverviewCatalogController extends UserwebBaseController {
	constructor(dependenciesHelper, catalogService) {
		super(dependenciesHelper)

		this._catalogService = catalogService
	}

	static get STATE_KEYS() {
		return {
			MODELS_LINES_LIST: 'MODELS_LINES_LIST'
		}
	}

	init() {
		super.init()
	}

	load() {
		const modelLinesList = this._loadAllModelLinesForModel()

		return super.load({
			[ModelLinesOverviewCatalogController.STATE_KEYS.MODELS_LINES_LIST]: modelLinesList
		})
	}

	getDataForSsp() {
		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {}
		}
	}

	_loadAllModelLinesForModel() {
		const { URL_API_PARAMS, URL_APP_PARAMS } = CatalogUrlConvertor.constants

		const { [URL_APP_PARAMS.MODEL_SEO_NAME]: seoName } = this.getRouteParams()

		const modelId = getIdFromSeoName(seoName)

		if (modelId) {
			return this._catalogService.getModelLines({ [URL_API_PARAMS.MODEL_ID]: modelId })
		} else {
			// neplatna URL, vracime 400.
			const error = new GenericError('Neplatna URL.', {
				status: HTTP_STATUS_CODES.BAD_REQUEST
			})
			return Promise.reject(error)
		}
	}

	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const {
			[ModelLinesOverviewCatalogController.STATE_KEYS.MODELS_LINES_LIST]: modelLines = []
		} = loadedResources

		const { model = {} } = modelLines ? modelLines[0] || {} : {}
		const { name: modelName = '', manufacturer = {} } = model
		const { name: brandName = '' } = manufacturer

		const info = {
			count: modelLines.length,
			brandName,
			modelName
		}

		const { title, description } = this._getMetas(info)

		const metaTitle = title + dictionary.get('Base.titleSuffix')
		metaManager.setTitle(metaTitle)
		metaManager.setMetaName('twitter:title', metaTitle)
		metaManager.setMetaProperty('og:title', metaTitle)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('twitter:description', description)
		metaManager.setMetaProperty('og:description', description)
	}

	_getMetas(info) {
		const { route } = this._utils.$Router.getCurrentRouteInfo()
		const routeName = route.getName()

		return CatalogMeta.getMetas(routeName, this._utils.$Dictionary, info)
	}
}
