import React, { useContext } from 'react'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import { CATEGORIES } from 'app/base/Constants'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import TableHead from 'app/component/tableHead/TableHead'
import * as FormLines from '@inzeraty/form-lines'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import { Format } from '@inzeraty/helpers'
import ArticlesEntity from 'app/model/articles/ArticlesEntity'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'

import './IAArticlesListView.less'

const CLASSNAME = 'p-ia-articles-list'

const IAArticlesListView = ({
	isLoading = true,
	entitiesAndPagination,
	filterFormLineEntities = {},
	isFilterLoading = true
}) => {
	const context = useContext(Context)
	const router = context.$Router

	return (
		<Layout title='Výpis článků'>
			{entitiesAndPagination && (
				<>
					<EntitiesFilter
						formLineEntities={filterFormLineEntities}
						isFilterLoading={isFilterLoading}
					/>

					<EntitiesTable
						entitiesAndPagination={entitiesAndPagination}
						headCells={[
							<TableHead key='manufacturer'>Výrobce</TableHead>,
							<TableHead key='model'>Model</TableHead>,
							<TableHead key='category'>Typ vozidla</TableHead>,
							<TableHead key='count'>Počet článků</TableHead>,
							<TableHead key='link'></TableHead>
						]}
						rowCells={[
							({ manufacturerCb = {} }) => manufacturerCb.name,
							({ modelCb = {} }) => modelCb.name,
							({ categoryId }) => {
								const category = Object.values(CATEGORIES).find(
									(category) => category.id === categoryId
								)
								return category ? category.name : ''
							},
							({ articlesCount }) => Format.number(articlesCount),
							({ manufacturerCb, modelCb, categoryId }) => (
								<a
									className={`${CLASSNAME}__link`}
									href={router.link(ROUTE_NAMES.INTERNAL_ADMIN.ARTICLES_DETAIL, {
										category_id: categoryId,
										model_cb: modelCb.value,
										manufacturer_cb: manufacturerCb.value,
										manufacturer: manufacturerCb.name,
										model: modelCb.name
									})}
								>
									Zobrazit články
								</a>
							)
						]}
						isLoading={isLoading}
					/>
				</>
			)}
		</Layout>
	)
}

IAArticlesListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.shape(ArticlesEntity)),
		paginationEntity: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isLoading: PropTypes.bool,
	isFilterLoading: PropTypes.bool
}

export default IAArticlesListView
