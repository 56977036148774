const RESOURCE = '/items/filter_page'

export default class FilterResource {
	/**
	 * constructor
	 * @method constructor
	 * @param {RestClient} restClient
	 */
	constructor(restClient) {
		this._restClient = restClient
	}

	/**
	 * Stáhne data pro filtr.
	 *
	 * @method getFilter
	 * @public
	 * @param {Object} params
	 * @param {Number} params.category_id Id kategorie.
	 * @param {Object} options
	 * @return {Promise<JSONResource>}
	 */
	getFilter(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}`, params, options)
	}
}
