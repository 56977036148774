import { CATEGORIES } from 'app/base/Constants'
import { FORM_LINES_IDS } from './BasicInfoFormLineIds'

export const SECTIONS_ID = {
	DEAL_TYPE_SECTION: 'DEAL_TYPE_SECTION_ID',
	OPERATING_LEASE_SECTION: 'OPERATING_LEASE_SECTION_ID',
	TYPE_AND_MODEL_SECTION: 'TYPE_AND_MODEL_SECTION_ID',
	ENGINE_SECTION: 'ENGINE_SECTION_ID',
	VEHICLE_CONDITION_SECTION: 'VEHICLE_CONDITION_SECTION_ID',
	PRICE_SECTION: 'PRICE_SECTION_ID',
	CONTACT_SECTION: 'CONTACT_SECTION_ID'
}

const DEFAULT_DEAL_TYPE_SECTION = [FORM_LINES_IDS.DEAL_TYPE]

const DEFAULT_OPERATING_LEASE_SECTION = [FORM_LINES_IDS.OPERATING_LEASE_INTENDED_FOR_CB]

const PASSENGER_CARS_TYPE_AND_MODEL_SECTION = [
	FORM_LINES_IDS.MANUFACTURER_CB,
	FORM_LINES_IDS.MODEL_CB,
	FORM_LINES_IDS.ADDITIONAL_MODEL_NAME,
	FORM_LINES_IDS.MANUFACTURING_DATE,
	FORM_LINES_IDS.IN_OPERATION_DATE,
	FORM_LINES_IDS.VEHICLE_BODY_CB,
	FORM_LINES_IDS.DOORS_AND_CAPACITY,
	FORM_LINES_IDS.COLOR_AND_TONE_CB,
	FORM_LINES_IDS.COLOR_TYPE_CB,
	FORM_LINES_IDS.AIRCONDITION_CB,
	FORM_LINES_IDS.AIRBAGS
]

const UTILITY_CARS_TYPE_AND_MODEL_SECTION = [
	FORM_LINES_IDS.MANUFACTURER_CB,
	FORM_LINES_IDS.MODEL_CB,
	FORM_LINES_IDS.ADDITIONAL_MODEL_NAME,
	FORM_LINES_IDS.MANUFACTURING_DATE,
	FORM_LINES_IDS.IN_OPERATION_DATE,
	FORM_LINES_IDS.VEHICLE_BODY_CB,
	FORM_LINES_IDS.DOORS_AND_CAPACITY,
	FORM_LINES_IDS.COLOR_AND_TONE_CB,
	FORM_LINES_IDS.COLOR_TYPE_CB,
	FORM_LINES_IDS.WEIGHT,
	FORM_LINES_IDS.LOAD_CAPACITY,
	FORM_LINES_IDS.AIRCONDITION_CB,
	FORM_LINES_IDS.AIRBAGS
]

const TRUCKS_TYPE_AND_MODEL_SECTION = UTILITY_CARS_TYPE_AND_MODEL_SECTION

const MOTORCYCLE_TYPE_AND_MODEL_SECTION = [
	FORM_LINES_IDS.MANUFACTURER_CB,
	FORM_LINES_IDS.MODEL_CB,
	FORM_LINES_IDS.ADDITIONAL_MODEL_NAME,
	FORM_LINES_IDS.MANUFACTURING_DATE,
	FORM_LINES_IDS.IN_OPERATION_DATE,
	FORM_LINES_IDS.VEHICLE_BODY_CB,
	FORM_LINES_IDS.CAPACITY,
	FORM_LINES_IDS.COLOR_AND_TONE_CB,
	FORM_LINES_IDS.COLOR_TYPE_CB
]

const QUAD_TYPE_AND_MODEL_SECTION = MOTORCYCLE_TYPE_AND_MODEL_SECTION

const TRAILERS_TYPE_AND_MODEL_SECTION = [
	FORM_LINES_IDS.MANUFACTURER_CB,
	FORM_LINES_IDS.MODEL_CB,
	FORM_LINES_IDS.ADDITIONAL_MODEL_NAME,
	FORM_LINES_IDS.MANUFACTURING_DATE,
	FORM_LINES_IDS.IN_OPERATION_DATE,
	FORM_LINES_IDS.VEHICLE_BODY_CB,
	FORM_LINES_IDS.COLOR_AND_TONE_CB,
	FORM_LINES_IDS.COLOR_TYPE_CB,
	FORM_LINES_IDS.WEIGHT,
	FORM_LINES_IDS.LOAD_CAPACITY
]

const CAMPER_TYPE_AND_MODEL_SECTION = [
	FORM_LINES_IDS.MANUFACTURER_CB,
	FORM_LINES_IDS.MODEL_CB,
	FORM_LINES_IDS.ADDITIONAL_MODEL_NAME,
	FORM_LINES_IDS.MANUFACTURING_DATE,
	FORM_LINES_IDS.IN_OPERATION_DATE,
	FORM_LINES_IDS.VEHICLE_BODY_CB,
	FORM_LINES_IDS.DOORS_AND_CAPACITY,
	FORM_LINES_IDS.BEDS,
	FORM_LINES_IDS.COLOR_AND_TONE_CB,
	FORM_LINES_IDS.COLOR_TYPE_CB,
	FORM_LINES_IDS.WEIGHT,
	FORM_LINES_IDS.LOAD_CAPACITY,
	FORM_LINES_IDS.AIRCONDITION_CB,
	FORM_LINES_IDS.AIRBAGS
]

const WORKING_MACHINES_TYPE_AND_MODEL_SECTION = [
	FORM_LINES_IDS.MANUFACTURER_CB,
	FORM_LINES_IDS.MODEL_CB,
	FORM_LINES_IDS.ADDITIONAL_MODEL_NAME,
	FORM_LINES_IDS.MANUFACTURING_DATE,
	FORM_LINES_IDS.IN_OPERATION_DATE,
	FORM_LINES_IDS.VEHICLE_BODY_CB,
	FORM_LINES_IDS.CAPACITY,
	FORM_LINES_IDS.COLOR_AND_TONE_CB,
	FORM_LINES_IDS.COLOR_TYPE_CB,
	FORM_LINES_IDS.WEIGHT,
	FORM_LINES_IDS.LOAD_CAPACITY,
	FORM_LINES_IDS.AIRBAGS
]

const BUSES_TYPE_AND_MODEL_SECTION = [
	FORM_LINES_IDS.MANUFACTURER_CB,
	FORM_LINES_IDS.MODEL_CB,
	FORM_LINES_IDS.ADDITIONAL_MODEL_NAME,
	FORM_LINES_IDS.MANUFACTURING_DATE,
	FORM_LINES_IDS.IN_OPERATION_DATE,
	FORM_LINES_IDS.VEHICLE_BODY_CB,
	FORM_LINES_IDS.DOORS_AND_SEATS,
	FORM_LINES_IDS.COLOR_AND_TONE_CB,
	FORM_LINES_IDS.COLOR_TYPE_CB,
	FORM_LINES_IDS.AIRCONDITION_CB,
	FORM_LINES_IDS.AIRBAGS
]

const DEFAULT_ENGINE_SECTION = [
	FORM_LINES_IDS.FUEL_CB,
	FORM_LINES_IDS.ENGINE_VOLUME,
	FORM_LINES_IDS.BATTERY_CAPACITY,
	FORM_LINES_IDS.VEHICLE_RANGE,
	FORM_LINES_IDS.ENGINE_POWER,
	FORM_LINES_IDS.AVERAGE_GAS_MILEAGE,
	FORM_LINES_IDS.AVERAGE_ELECTRIC_MILEAGE,
	FORM_LINES_IDS.GEARBOX_CB,
	FORM_LINES_IDS.GEARBOX_LEVELS_CB,
	FORM_LINES_IDS.DRIVE_CB
]

const CAMPER_ENGINE_SECTION = [
	FORM_LINES_IDS.FUEL_CB,
	FORM_LINES_IDS.ENGINE_VOLUME,
	FORM_LINES_IDS.BATTERY_CAPACITY,
	FORM_LINES_IDS.VEHICLE_RANGE,
	FORM_LINES_IDS.ENGINE_POWER,
	FORM_LINES_IDS.AVERAGE_GAS_MILEAGE,
	FORM_LINES_IDS.AVERAGE_ELECTRIC_MILEAGE,
	FORM_LINES_IDS.GEARBOX_CB
]

const WORKING_MACHINES_ENGINE_SECTION = CAMPER_ENGINE_SECTION

const BUSES_ENGINE_SECTION = [
	FORM_LINES_IDS.FUEL_CB,
	FORM_LINES_IDS.ENGINE_VOLUME,
	FORM_LINES_IDS.BATTERY_CAPACITY,
	FORM_LINES_IDS.VEHICLE_RANGE,
	FORM_LINES_IDS.ENGINE_POWER,
	FORM_LINES_IDS.AVERAGE_GAS_MILEAGE,
	FORM_LINES_IDS.AVERAGE_ELECTRIC_MILEAGE,
	FORM_LINES_IDS.GEARBOX_CB,
	FORM_LINES_IDS.GEARBOX_LEVELS_CB
]

const MOTORCYCLE_ENGINE_SECTION = [
	FORM_LINES_IDS.FUEL_CB,
	FORM_LINES_IDS.ENGINE_VOLUME,
	FORM_LINES_IDS.BATTERY_CAPACITY,
	FORM_LINES_IDS.VEHICLE_RANGE,
	FORM_LINES_IDS.ENGINE_POWER,
	FORM_LINES_IDS.AVERAGE_GAS_MILEAGE,
	FORM_LINES_IDS.AVERAGE_ELECTRIC_MILEAGE,
	FORM_LINES_IDS.GEARBOX_CB,
	FORM_LINES_IDS.GEARBOX_LEVELS_CB
]

const QUAD_ENGINE_SECTION = MOTORCYCLE_ENGINE_SECTION

const TRAILERS_ENGINE_SECTION = []

const DEFAULT_CONDITION_SECTION = [
	FORM_LINES_IDS.TACHOMETER,
	FORM_LINES_IDS.AVAILABILITY_CB,
	FORM_LINES_IDS.DELIVERY_DATE,
	FORM_LINES_IDS.STK_DATE,
	FORM_LINES_IDS.COUNTRY_OF_ORIGIN_CB,
	FORM_LINES_IDS.DESCRIPTION,
	FORM_LINES_IDS.WINDSHIELD_NOTE
]

const TRAILERS_CONDITION_SECTION = [
	FORM_LINES_IDS.AVAILABILITY_CB,
	FORM_LINES_IDS.DELIVERY_DATE,
	FORM_LINES_IDS.STK_DATE,
	FORM_LINES_IDS.COUNTRY_OF_ORIGIN_CB,
	FORM_LINES_IDS.DESCRIPTION,
	FORM_LINES_IDS.WINDSHIELD_NOTE
]

const WORKING_MACHINES_CONDITION_SECTION = [
	FORM_LINES_IDS.TACHOMETER,
	FORM_LINES_IDS.AVAILABILITY_CB,
	FORM_LINES_IDS.DELIVERY_DATE,
	FORM_LINES_IDS.MACHINE_HOURS,
	FORM_LINES_IDS.STK_DATE,
	FORM_LINES_IDS.COUNTRY_OF_ORIGIN_CB,
	FORM_LINES_IDS.DESCRIPTION,
	FORM_LINES_IDS.WINDSHIELD_NOTE
]

const DEFAULT_PRICE_SECTION = [FORM_LINES_IDS.PRICE, FORM_LINES_IDS.PRICE_NOTE]

const DEFAULT_CONTACT_SECTION = [
	FORM_LINES_IDS.LOCALITY,
	FORM_LINES_IDS.PHONE,
	FORM_LINES_IDS.EMAIL
]

const SECTIONS_BY_CATEGORY_ID = {
	[CATEGORIES.PASSENGER_CARS.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: PASSENGER_CARS_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: DEFAULT_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: DEFAULT_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	},
	[CATEGORIES.UTILITY_CARS.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: UTILITY_CARS_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: DEFAULT_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: DEFAULT_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	},
	[CATEGORIES.TRUCKS.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: TRUCKS_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: DEFAULT_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: DEFAULT_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	},

	[CATEGORIES.MOTORCYCLE.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: MOTORCYCLE_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: MOTORCYCLE_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: DEFAULT_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	},
	[CATEGORIES.QUAD.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: QUAD_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: QUAD_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: DEFAULT_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	},
	[CATEGORIES.TRAILERS.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: TRAILERS_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: TRAILERS_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: TRAILERS_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	},

	[CATEGORIES.CAMPER.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: CAMPER_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: CAMPER_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: DEFAULT_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	},
	[CATEGORIES.WORKING_MACHINES.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: WORKING_MACHINES_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: WORKING_MACHINES_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: WORKING_MACHINES_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	},
	[CATEGORIES.BUSES.id]: {
		[SECTIONS_ID.DEAL_TYPE_SECTION]: DEFAULT_DEAL_TYPE_SECTION,
		[SECTIONS_ID.OPERATING_LEASE_SECTION]: DEFAULT_OPERATING_LEASE_SECTION,
		[SECTIONS_ID.TYPE_AND_MODEL_SECTION]: BUSES_TYPE_AND_MODEL_SECTION,
		[SECTIONS_ID.ENGINE_SECTION]: BUSES_ENGINE_SECTION,
		[SECTIONS_ID.VEHICLE_CONDITION_SECTION]: DEFAULT_CONDITION_SECTION,
		[SECTIONS_ID.PRICE_SECTION]: DEFAULT_PRICE_SECTION,
		[SECTIONS_ID.CONTACT_SECTION]: DEFAULT_CONTACT_SECTION
	}
}

export const splitFormLinesIntoSections = (categoryId, formLineEntities = []) => {
	const sections = SECTIONS_BY_CATEGORY_ID[categoryId] || {}

	return Object.keys(sections).reduce((split, sectionId) => {
		const formLinesIds = sections[sectionId]

		return Object.assign({}, split, {
			[sectionId]: formLinesIds
				.map((id) => formLineEntities.find((f) => f.id === id))
				.filter((f) => !!f)
		})
	}, {})
}
