import * as FormLines from '@inzeraty/form-lines'
import { DateHelpers } from '@inzeraty/helpers'
import { FORM_LINES_IDS } from './HistoryFormLineIds'
import { GUARANTEE_MONTH_AND_YEAR_SELECT_WIDGET_ID } from '../widgets/monthYearSelect/MonthYearSelect'
import { BASIC_SELECT_WIDGET_ID } from 'app/page/userweb/newAdvert/basicInfo/components/widgets/basicSelect/BasicSelectWidget'
import { YES_NO_NOT_STATED_WIDGET_ID } from '../widgets/yesNoNotStated/YesNoNotStatedWidget'

import './HistoryFormCS.json'
import 'app/base/BaseCS.json'

export const createFormLineEntities = (advertEntity = {}, dictionary) => {
	const guaranteeDateParsed = DateHelpers.parseDate(advertEntity.guaranteeDate)

	const formatDateForServer = (month = 1, year, date = 1) =>
		1 <= month && month <= 12 && year > 0 ? `${year}-${month}-${date}` : undefined

	return FormLines.createEntityList([
		{
			widget: GUARANTEE_MONTH_AND_YEAR_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.GUARANTEE_DATE,
			label: dictionary.get('HistoryForm.guaranteeDateLabel'),
			value: guaranteeDateParsed
				? [{ value: guaranteeDateParsed.month }, guaranteeDateParsed.year, guaranteeDateParsed.date]
				: undefined,
			options: getOptionsForMonths(dictionary),
			extra: {
				getFormData: ([{ value: month } = {}, year, date] = []) => ({
					guarantee_date: formatDateForServer(month, year, date)
				})
			}
		},
		{
			widget: BASIC_SELECT_WIDGET_ID,
			id: FORM_LINES_IDS.EURO_LEVEL_CB,
			label: dictionary.get('HistoryForm.euroLeverLabel'),
			placeholder: dictionary.get('HistoryForm.euroLevelPlaceholder'),
			value: advertEntity.euroLevelCb,
			extra: {
				getFormData: ({ value } = {}) => ({
					euro_level_cb: value
				})
			}
		},
		{
			widget: YES_NO_NOT_STATED_WIDGET_ID,
			id: FORM_LINES_IDS.ENVIRONMENTAL_TAX_PAID,
			label: dictionary.get('HistoryForm.environmentalTaxPaidLabel'),
			value: advertEntity.environmentalTaxPaid,
			extra: {
				getFormData: (value) => ({
					environmental_tax_paid: getSwitchFormData(value)
				})
			}
		},
		{
			widget: YES_NO_NOT_STATED_WIDGET_ID,
			id: FORM_LINES_IDS.FIRST_OWNER,
			label: dictionary.get('HistoryForm.firstOwnerLabel'),
			value: advertEntity.firstOwner,
			extra: {
				getFormData: (value) => ({
					first_owner: getSwitchFormData(value)
				})
			}
		},
		{
			widget: YES_NO_NOT_STATED_WIDGET_ID,
			id: FORM_LINES_IDS.ADJUSTMENTS_FOR_HANDICAPPED,
			label: dictionary.get('HistoryForm.adjustmentsForHandicappedLabel'),
			value: advertEntity.adjustmentsForHandicapped,
			extra: {
				getFormData: (value) => ({
					adjustments_for_handicapped: getSwitchFormData(value)
				})
			}
		},
		{
			widget: YES_NO_NOT_STATED_WIDGET_ID,
			id: FORM_LINES_IDS.CRASHED_IN_PAST,
			label: dictionary.get('HistoryForm.crashedInPastLabel'),
			value: advertEntity.crashedInPast,
			extra: {
				getFormData: (value) => ({
					crashed_in_past: getSwitchFormData(value)
				})
			}
		},
		{
			widget: YES_NO_NOT_STATED_WIDGET_ID,
			id: FORM_LINES_IDS.TUNING,
			label: dictionary.get('HistoryForm.tuningLabel'),
			value: advertEntity.tuning,
			extra: {
				getFormData: (value) => ({
					tuning: getSwitchFormData(value)
				})
			}
		},
		{
			widget: YES_NO_NOT_STATED_WIDGET_ID,
			id: FORM_LINES_IDS.SERVICE_BOOK,
			label: dictionary.get('HistoryForm.serviceBookLabel'),
			value: advertEntity.serviceBook,
			extra: {
				getFormData: (value) => ({
					service_book: getSwitchFormData(value)
				})
			}
		}
	])
}

const getSwitchFormData = (value) => {
	return value === '' ? null : value
}

const getOptionsForMonths = (dictionary) => {
	const MONTH_OPTIONS = [
		{ value: 1, name: dictionary.get('Base.January') },
		{ value: 2, name: dictionary.get('Base.February') },
		{ value: 3, name: dictionary.get('Base.March') },
		{ value: 4, name: dictionary.get('Base.April') },
		{ value: 5, name: dictionary.get('Base.May') },
		{ value: 6, name: dictionary.get('Base.June') },
		{ value: 7, name: dictionary.get('Base.July') },
		{ value: 8, name: dictionary.get('Base.August') },
		{ value: 9, name: dictionary.get('Base.September') },
		{ value: 10, name: dictionary.get('Base.October') },
		{ value: 11, name: dictionary.get('Base.November') },
		{ value: 12, name: dictionary.get('Base.December') }
	]

	return MONTH_OPTIONS
}
