
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['DatePickerGroup'] = {
  period1: function(d) { return "Období 1"; },
  period2: function(d) { return "Období 2"; },
  addSecondPeriod: function(d) { return "+ Přidat období ke srovnání"; },
  removeSecondPeriod: function(d) { return "Odebrat druhé období"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	