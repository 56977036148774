
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PopupFeedbackForm'] = {
  header: function(d) { return "Co říkáte na Sauto?"; },
  title: function(d) { return "Odeslat zpětnou vazbu"; },
  satisfaction: function(d) { return "Jak jste spokojeni s Sautem?"; },
  ariaClose: function(d) { return "Zavřít"; },
  excellent: function(d) { return "Výborné"; },
  good: function(d) { return "Dobré"; },
  neutral: function(d) { return "Neutrální"; },
  bad: function(d) { return "Špatné"; },
  terrible: function(d) { return "Hrozné"; },
  feedbackSubjectLabel: function(d) { return "V čem se můžeme zlepšit"; },
  feedbackSubjectPlaceholder: function(d) { return "Vyberte důvod"; },
  feedbackSubjectError: function(d) { return "Vyberte důvod"; },
  feedbackSubjectAdvertInfo: function(d) { return "Informace o vozidlech"; },
  feedbackSubjectAdvertSearchAndFiltering: function(d) { return "Hledání a filtrace vozidel"; },
  feedbackSubjectIrrelevantSearchResults: function(d) { return "Nerelevatní výsledky na vyhledávání"; },
  feedbackSubjectWebClarity: function(d) { return "Přehlednost webu"; },
  feedbackSubjectTechnicalIssues: function(d) { return "Technické problémy"; },
  feedbackSubjectOther: function(d) { return "Ostatní"; },
  feedbackTextLabel: function(d) { return "Upřesněte důvod"; },
  feedbackTextPlaceholder: function(d) { return "Upřesněte Váš důvod"; },
  feedbackTextError: function(d) { return "Napište nám prosím důvod pro Vaše hodnocení"; },
  emailLabel: function(d) { return "Váš email"; },
  emailPlaceholder: function(d) { return "Zadejte Váš email"; },
  emailError: function(d) { return "Vyplňte Váš email"; },
  close: function(d) { return "Zavřít"; },
  submit: function(d) { return "Odeslat"; },
  formSubmitError: function(d) { return "Formulář se nepodařilo odeslat."; },
  formSubmitSuccessHeader: function(d) { return "Děkujeme za zpětnou vazbu"; },
  formSubmitSuccess: function(d) { return "Vaší zpětné vazby si vážíme. Pomáhá nám zlepšovat Sauto."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	