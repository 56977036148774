export const MOST_RECENT = 'MOST_RECENT'

const ORDER_DIRECTIONS = Object.freeze({
	ASC: 'asc',
	DESC: 'desc'
})

const PRICE_ASC_SORT_KEY = 'price'
const PRICE_DESC_SORT_KEY = '-price'

export default class SortConstants {
	static get defaultValue() {
		return MOST_RECENT
	}

	static get orderDirections() {
		return ORDER_DIRECTIONS
	}

	static get sortValues() {
		return Object.freeze({
			[MOST_RECENT]: {
				NAME: 'mostRecent'
			},
			PRICE_ASC: {
				NAME: 'priceAscending',
				APP: 'od-nejlevnejsich',
				API: 'price'
			},
			PRICE_DESC: {
				NAME: 'priceDescending',
				APP: 'od-nejdrazsich',
				API: '-price'
			},
			TACHOMETER_ASC: {
				NAME: 'tachometerAscending',
				APP: 'stav-tachometru',
				API: 'tachometer'
			},
			MANUFACTURED_DESC: {
				NAME: 'manufacturingDateDescending',
				APP: 'rok-vyroby',
				API: '-manufacturing_date'
			}
		})
	}

	static get sortKeys() {
		return Object.freeze({
			[PRICE_ASC_SORT_KEY]: [
				{
					keys: ['price'],
					direction: ORDER_DIRECTIONS.ASC
				},
				{
					keys: ['id'],
					direction: ORDER_DIRECTIONS.DESC
				}
			],

			[PRICE_DESC_SORT_KEY]: [
				{
					keys: ['price'],
					direction: ORDER_DIRECTIONS.DESC
				},
				{
					keys: ['id'],
					direction: ORDER_DIRECTIONS.DESC
				}
			],

			tachometer: [
				{
					keys: ['tachometer'],
					direction: ORDER_DIRECTIONS.ASC
				},
				{
					keys: ['cebiaReportRating'],
					direction: ORDER_DIRECTIONS.DESC
				},
				{
					keys: ['manufacturingDate', 'inOperationDate'],
					direction: ORDER_DIRECTIONS.DESC
				},
				{
					keys: ['price'],
					direction: ORDER_DIRECTIONS.ASC
				}
			],

			'-manufacturing_date': [
				{
					keys: ['manufacturingDate', 'inOperationDate'],
					direction: ORDER_DIRECTIONS.DESC
				},
				{
					keys: ['price'],
					direction: ORDER_DIRECTIONS.ASC
				},
				{
					keys: ['cebiaReportRating'],
					direction: ORDER_DIRECTIONS.DESC
				},
				{
					keys: ['tachometer'],
					direction: ORDER_DIRECTIONS.ASC
				}
			]
		})
	}
}

export class OperatingLeaseSortConstants {
	static get defaultValue() {
		return SortConstants.defaultValue
	}

	static get orderDirections() {
		return SortConstants.orderDirections
	}

	static get sortValues() {
		return Object.freeze({
			[MOST_RECENT]: {
				NAME: 'mostRecent'
			},
			PRICE_ASC: {
				NAME: 'priceAscending',
				APP: 'od-nejlevnejsich',
				API: 'operating_lease_price_without_vat'
			},
			TACHOMETER_ASC: {
				NAME: 'tachometerAscending',
				APP: 'stav-tachometru',
				API: 'tachometer'
			},
			MANUFACTURED_DESC: {
				NAME: 'manufacturingDateDescending',
				APP: 'rok-vyroby',
				API: '-manufacturing_date'
			}
		})
	}

	static get sortKeys() {
		const filteredSortKeys = Object.fromEntries(
			Object.entries(SortConstants.sortKeys).filter(
				([key]) => key !== PRICE_ASC_SORT_KEY && key !== PRICE_DESC_SORT_KEY
			)
		)

		return Object.freeze({
			...filteredSortKeys,
			operating_lease_price_without_vat: [
				{
					keys: ['operating_lease_price_without_vat'],
					direction: ORDER_DIRECTIONS.ASC
				},
				{
					keys: ['id'],
					direction: ORDER_DIRECTIONS.DESC
				}
			]
		})
	}
}
