import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import BasicInfoTooltip from 'app/page/userweb/newAdvert/basicInfo/components/tooltip/BasicInfoTooltip'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'
import BaseMonthYearSelect, {
	MONTH_AND_YEAR_SELECT_WIDGET_ID,
	getCustomMonthFormLineId
} from 'app/page/userweb/newAdvert/component/widgets/monthYearSelect/MonthYearSelect'
import BasicWidget from 'app/page/userweb/newAdvert/component/widgets/basic/BasicWidget'

import './MonthYearSelect.less'
import './MonthYearSelectCS.json'

const CLASSNAME = 'c-month-year-select'

export { MONTH_AND_YEAR_SELECT_WIDGET_ID }
export const MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID =
	'manufacturing_month_and_year_select_widget'
export const IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID =
	'in_operation_month_and_year_select_widget'

const MonthYearSelect = (props) => {
	const localize = useLocalize()

	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const selectId = `${getCustomMonthFormLineId(formLineEntity.id)}-input`

	const handleChange = (change) => {
		onChange(change)
		clearErrorOnDependentField(change)
	}

	const clearErrorOnDependentField = (change) => {
		// 'Rok vyroby' a 'V provozu od' jsou zavisla policka, jenom jedno z nich
		// je povinne. Takze v pripade, ze dojde ke zmene jednoho z nich, pro to
		// druhe musime vymazat pripadnou chybu.
		const { id } = change

		if (id === FORM_LINES_IDS.MANUFACTURING_DATE) {
			onChange({
				id: FORM_LINES_IDS.IN_OPERATION_DATE,
				errorMessage: undefined
			})
		} else if (id === FORM_LINES_IDS.IN_OPERATION_DATE) {
			onChange({
				id: FORM_LINES_IDS.MANUFACTURING_DATE,
				errorMessage: undefined
			})
		}
	}

	const renderLabel = () => {
		const { label, required, widget } = formLineEntity

		const stars =
			widget === MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID ||
			widget === IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID
				? '**'
				: '*'

		return (
			<>
				<label className='wdg__label' htmlFor={selectId}>
					{label}
					{required && <span className='c-basic-widget__required'>{stars}</span>}
				</label>
				<BasicInfoTooltip formLineEntity={formLineEntity} />
			</>
		)
	}

	const { widget, errorMessage } = formLineEntity

	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			renderLabel={renderLabel}
			renderInput={() => (
				<BaseMonthYearSelect
					formLineEntity={formLineEntity}
					onChange={handleChange}
					selectId={selectId}
				/>
			)}
			renderWidget={(
				formLineEntity,
				renderLabel,
				renderInput,
				renderMessage,
				renderErrorMessage
			) => {
				return (
					<div>
						<div className='c-basic-widget'>
							<div className='c-basic-widget__label-box'>{renderLabel()}</div>

							<div className='c-basic-widget__content'>
								<div className='c-basic-widget__input'>{renderInput()}</div>
								{errorMessage && renderErrorMessage()}
							</div>
						</div>

						{widget === IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID && (
							<div className={`${CLASSNAME}__linked-req-fields-description`}>
								<span className={`${CLASSNAME}__stars`}>**</span>
								<span>{localize('MonthYearSelect.linkedRequiredFieldsDescription')}</span>
							</div>
						)}
					</div>
				)
			}}
		/>
	)
}

MonthYearSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(MonthYearSelect)
