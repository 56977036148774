
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AssignEmailReasons'] = {
  continueOnThisPage: function(d) { return "Doplňte e-mail, bez něj není možné na této stránce pokračovat"; },
  reviewFeedback: function(d) { return "Doplňte e-mail, bez něj nelze odpovědět na recenzi"; },
  reviewRate: function(d) { return "Doplňte e-mail, bez něj nelze hodnotit recenzi"; },
  reviewAdd: function(d) { return "Doplňte e-mail, bez něj nelze vložit recenzi"; },
  addMySaveSearch: function(d) { return "Doplňte e-mail, bez něj nelze uložit hledání"; },
  favorite: function(d) { return "Doplňte e-mail, bez něj nelze přidat inzerát do oblíbených"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	