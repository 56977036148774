
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['HomeHeader'] = {
  titlePart_1: function(d) { return "Největší "; },
  titlehighlight: function(d) { return "autoinzerce"; },
  titlePart_2_long: function(d) { return " v České republice"; },
  titlePart_2_short: function(d) { return " v ČR"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	