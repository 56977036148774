import React, { memo } from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './WidgetPremiseInfo.less'

const CLASSNAME = 'c-ia-premise-info'

const WidgetPremiseInfo = memo(({ className, formLineEntity = {} }) => {
	const { value: { id: askId, name, subject: { ico } = {} } = {} } = formLineEntity

	return (
		<div className={className}>
			<div>
				<span className={`${CLASSNAME}__label`}>ASK ID:</span>
				<span>{askId}</span>
			</div>
			<div>
				<span className={`${CLASSNAME}__label`}>Název firmy:</span>
				<span>{name}</span>
			</div>
			<div>
				<span className={`${CLASSNAME}__label`}>IČO:</span>
				<span>{ico}</span>
			</div>
		</div>
	)
})

WidgetPremiseInfo.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	className: PropTypes.string
}

export default WidgetPremiseInfo
