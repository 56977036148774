
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['ListHeader'] = {
  header: function(d) { return "Moje inzeráty"; },
  count: function(d) { return plural(d.COUNT, 0, cs, { "0": "", one: d.COUNT + " inzerát", few: d.COUNT + " inzeráty", other: d.COUNT + " inzerátů" }); },
  premiseCount: function(d) { return d.FROM + " – " + d.TO + " z " + plural(d.TOTAL, 0, cs, { one: d.TOTAL + " inzerátu", other: d.TOTAL + " inzerátů" }); },
  select: function(d) { return "Označit všechny inzeráty"; },
  deselect: function(d) { return "Odznačit všechny inzeráty"; },
  filterById: function(d) { return "ID inzerátu"; },
  filterByName: function(d) { return "název inzerátu"; },
  filterByVin: function(d) { return "VIN"; },
  filterByImportId: function(d) { return "ID importu"; },
  banStatusTitle: function(d) { return "Některé Vaše inzeráty byly zablokované administrátorem"; },
  banStatusText: function(d) { return "Důvody pro zablokování Vašich inzerátů jsme poslali na Váš email."; },
  banStatusLink: function(d) { return "Zobrazit inzeráty"; },
  banStatusLinkRichSaleText: function(d) { return "Zobrazit zablokované inzeráty na prodej"; },
  banStatusLinkRichOperatingLeaseText: function(d) { return "na operativní leasing"; },
  banStatusLinkRichSeparator: function(d) { return " a "; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	