export default class PremiseEntity {
	constructor(data) {
		this.admins = data.admins
		this.description = data.description
		this.emails = data.emails
		this.enabled = data.enabled
		this.id = data.id
		this.isPaid = data.isPaid
		this.locality = data.locality
		this.name = data.name
		this.logo = data.logo
		this.openingHours = data.openingHours
		this.phones = data.phones
		this.premiseServices = data.premiseServices
		this.registrationActiveUntil = data.registrationActiveUntil
		this.seoName = data.seoName
		this.subject = data.subject
		this.users = data.users
		this.allAdvertsCount = data.allAdvertsCount
		this.advertReportsCount = data.advertReportsCount
		this.web = data.web
		this.oldId = data.oldId
		this.softwareKeys = data.softwareKeys
		this.activeAdvertsCount = data.activeAdvertsCount
		this.walletUser = data.walletUser
	}
}
