import React, { useState } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { Popup } from '@inzeraty/components'
import { Button } from '@sznds/react'
import select from 'ima-plugin-select'
import STATE_KEYS from '../../AddVINStateKeys'
import PropTypes from 'prop-types'

import './CebiaPopupCS.json'
import './CebiaPopup.less'

const CLASSNAME = 'c-cebia-popup'

const CebiaPopup = (props) => {
	const {
		closeCebiaPopup,
		keepCebiaCategory,
		changeCebiaCategory,
		cebiaCategory,
		userCategory
	} = props
	const [isKeepCategorySelected, setIsKeepCategorySelected] = useState(false)
	const [isChangeCategorySeleceted, setIsChangeCategorySeleceted] = useState(false)

	const localize = useLocalize()

	const renderHeader = (renderCloseButton, renderTitle) => {
		return (
			<div>
				{renderTitle()}
				{renderCloseButton()}
				<div className={`${CLASSNAME}__subtitle`}>
					<p>
						{localize('CebiaPopup.info', {
							CEBIA_CAT: cebiaCategory,
							USER_CAT: userCategory
						})}
					</p>
					<p>{localize('CebiaPopup.infoNote')}</p>
				</div>
			</div>
		)
	}

	const handleKeepCategory = () => {
		setIsKeepCategorySelected(true)

		keepCebiaCategory()
	}

	const handleChangeCategory = () => {
		setIsChangeCategorySeleceted(true)

		changeCebiaCategory()
	}

	return (
		<div className={CLASSNAME}>
			<Popup
				title={localize('CebiaPopup.title')}
				renderHeaderContent={renderHeader}
				isOpen={true}
				onClose={() => {
					if (!isChangeCategorySeleceted && !isKeepCategorySelected) {
						closeCebiaPopup()
					}
				}}
				dictionary={{
					close: localize('CebiaPopup.close')
				}}
				hasLightbox={true}
			>
				<div className={`${CLASSNAME}__content`}>
					<Button
						primary={false}
						loading={isKeepCategorySelected}
						disabled={isChangeCategorySeleceted}
						text={localize('CebiaPopup.keepIt')}
						onClick={handleKeepCategory}
						className={`${CLASSNAME}__keep-btn`}
					/>
					<Button
						primary={true}
						loading={isChangeCategorySeleceted}
						disabled={isKeepCategorySelected}
						text={localize('CebiaPopup.changeIt')}
						onClick={handleChangeCategory}
					/>
				</div>
			</Popup>
		</div>
	)
}

const selectors = (state) => ({
	closeCebiaPopup: state[STATE_KEYS.CLOSE_CEBIA_POPUP],
	keepCebiaCategory: state[STATE_KEYS.KEEP_CEBIA_CATEGORY],
	changeCebiaCategory: state[STATE_KEYS.CHANGE_CEBIA_CATEGORY],
	cebiaCategory: state[STATE_KEYS.CEBIA_CATEGORY],
	userCategory: state[STATE_KEYS.USER_CATEGORY]
})

CebiaPopup.propTypes = {
	closeCebiaPopup: PropTypes.func,
	keepCebiaCategory: PropTypes.func,
	changeCebiaCategory: PropTypes.func,
	cebiaCategory: PropTypes.string,
	userCategory: PropTypes.string
}

export default React.memo(select(selectors)(CebiaPopup))
