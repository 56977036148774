import AbstractExtension from 'ima/extension/AbstractExtension'

export default class ContactSectionExtension extends AbstractExtension {
	static get stateIds() {
		return {
			GET_MOST_FREQUENT_LOCALITIES: 'getMostFrequentLocalities',
			GET_LOCALITY_SUGGESTS: 'getLocalitySuggests',
			GET_LOCALITY_BY_GPS_COORDINATES: 'getLocalityByGpsCoordinates'
		}
	}

	constructor(localityService) {
		super()

		this._localityService = localityService

		this._getMostFrequentLocalities = this._getMostFrequentLocalities.bind(this)
		this._getLocalitySuggests = this._getLocalitySuggests.bind(this)
		this._getLocalityByGpsCoordinates = this._getLocalityByGpsCoordinates.bind(this)

		this.canGetLocalitySuggestsOnInit = false
	}

	load() {
		return {
			[ContactSectionExtension.stateIds.GET_MOST_FREQUENT_LOCALITIES]: this
				._getMostFrequentLocalities,
			[ContactSectionExtension.stateIds.GET_LOCALITY_SUGGESTS]: this._getLocalitySuggests,
			[ContactSectionExtension.stateIds.GET_LOCALITY_BY_GPS_COORDINATES]: this
				._getLocalityByGpsCoordinates
		}
	}

	activate() {
		this.canGetLocalitySuggestsOnInit = true
	}

	getAllowedStateKeys() {
		return [
			ContactSectionExtension.stateIds.GET_MOST_FREQUENT_LOCALITIES,
			ContactSectionExtension.stateIds.GET_LOCALITY_SUGGESTS,
			ContactSectionExtension.stateIds.GET_LOCALITY_BY_GPS_COORDINATES
		]
	}

	_getMostFrequentLocalities() {
		return this._localityService.getMostFrequentLocalities()
	}

	_getLocalitySuggests(params) {
		if (this.canGetLocalitySuggestsOnInit) {
			return this._localityService.getSuggests(params)
		} else {
			this.canGetLocalitySuggestsOnInit = true
			return Promise.resolve([])
		}
	}

	_getLocalityByGpsCoordinates(params) {
		return this._localityService.getLocalityByGpsCoordinates(params)
	}
}
