import { FORM_LINES_IDS } from './ClientProfileFormLineIds'

export const PROFILE_SECTIONS = Object.freeze({
	BASIC_INFORMATION: 'BASIC_INFORMATION',
	OPENING_HOURS: 'OPENING_HOURS',
	CONTACT_INFORMATION: 'CONTACT_INFORMATION',
	ADDRESS: 'ADDRESS'
})

export const SECTIONS = {
	[PROFILE_SECTIONS.BASIC_INFORMATION]: [
		FORM_LINES_IDS.BASIC_INFORMATION_SOURCE,
		FORM_LINES_IDS.LOGO,
		FORM_LINES_IDS.NAME,
		FORM_LINES_IDS.ICO,
		FORM_LINES_IDS.WALLET_ACOUNT,
		FORM_LINES_IDS.WALLET_INFO,
		FORM_LINES_IDS.ACTIVE_UNTIL,
		FORM_LINES_IDS.BUSINESS_REPRESENTATIVE,
		FORM_LINES_IDS.LOGIN_EMAIL,
		FORM_LINES_IDS.DESCRIPTION
	],
	[PROFILE_SECTIONS.OPENING_HOURS]: [
		FORM_LINES_IDS.OPENING_HOURS_SOURCE,
		FORM_LINES_IDS.MONDAY_OPENING_HOURS,
		FORM_LINES_IDS.TUESDAY_OPENING_HOURS,
		FORM_LINES_IDS.WEDNESDAY_OPENING_HOURS,
		FORM_LINES_IDS.THURSDAY_OPENING_HOURS,
		FORM_LINES_IDS.FRIDAY_OPENING_HOURS,
		FORM_LINES_IDS.SATURDAY_OPENING_HOURS,
		FORM_LINES_IDS.SUNDAY_OPENING_HOURS,
		FORM_LINES_IDS.OPENING_HOURS_DESCRIPTION
	],
	[PROFILE_SECTIONS.CONTACT_INFORMATION]: [
		FORM_LINES_IDS.CONTACT_SOURCE,
		FORM_LINES_IDS.CONTACT_EMAILS,
		FORM_LINES_IDS.CONTACT_WEB,
		FORM_LINES_IDS.CONTACT_PHONES
	],
	[PROFILE_SECTIONS.ADDRESS]: [FORM_LINES_IDS.ADDRESS_SOURCE, FORM_LINES_IDS.ADDRESS]
}

export const splitFormLinesIntoSections = (formLineEntities = [], sections = SECTIONS) => {
	const formLinesSections = {}

	Object.keys(sections).forEach((key) => {
		formLinesSections[key] = sections[key]
			.map((id) => formLineEntities.find((f) => f.id === id))
			.filter((formline) => !!formline)
	})

	return formLinesSections
}
