import React, { useState, useContext } from 'react'
import { Icon } from '@sznds/react'
import { CHEVRON_DOWN_OUTLINE_24, DOT_OUTLINE_8 } from '@sznds/icons'
import { Popup, Responsive, Dropdown } from '@inzeraty/components'
import { RESPONSIVE } from 'app/base/Constants'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import Context from 'ima/page/context'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

import './PerPageButton.less'
import './PerPageButtonCS.json'

const CLASSNAME = 'c-per-page-btn'
const PER_PAGE_OPTIONS = [20, 50, 100]

const PerPageButton = ({ className = '' }) => {
	const { $Router } = useContext(Context)
	const {
		params: { [UrlConvertor.constants.URL_APP_PARAMS.PER_PAGE]: limit }
	} = $Router.getCurrentRouteInfo()
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const localize = useLocalize()
	const [selectedOption, setSelectedOption] = useState(Number(limit) || 20)
	const fire = useFire()

	const closePopup = () => {
		setIsPopupOpen(false)
	}

	const openPopup = () => {
		setIsPopupOpen(true)
	}

	const renderDotIcon = () => {
		return (
			<div className={`${CLASSNAME}__dot-wrapper`}>
				<Icon symbol={DOT_OUTLINE_8} className={`${CLASSNAME}__dot`} />
			</div>
		)
	}

	const renderPopupItems = () => {
		return (
			<ul className={`${CLASSNAME}__list`}>
				{PER_PAGE_OPTIONS.map((option) => {
					return (
						<li key={option} className={`${CLASSNAME}__item`}>
							<button
								className={classnames({
									[`${CLASSNAME}__link`]: true,
									[`${CLASSNAME}__link--selected`]: option === selectedOption
								})}
								onClick={() => {
									if (option !== selectedOption) {
										setSelectedOption(option)
										fire('changedPerPage', option)
									}
									closePopup()
								}}
							>
								{option === selectedOption && renderDotIcon()}
								<span>{option}</span>
							</button>
						</li>
					)
				})}
			</ul>
		)
	}

	const renderPopup = () => {
		return (
			<Popup
				className={`${CLASSNAME}__popup`}
				isOpen={true}
				onClose={closePopup}
				title={localize('PerPageButton.perPage')}
				isFullscreen={false}
				hasLightbox={true}
			>
				{renderPopupItems()}
			</Popup>
		)
	}

	const renderDropdown = () => {
		return (
			<Dropdown className={`${CLASSNAME}__dropdown`} onClose={closePopup}>
				{renderPopupItems()}
			</Dropdown>
		)
	}

	const renderButton = () => {
		return (
			<button className={`${CLASSNAME}__button`} onClick={openPopup}>
				<span className={`${CLASSNAME}__text`}>
					{selectedOption} / {localize('PerPageButton.page')}
				</span>
				<Icon symbol={CHEVRON_DOWN_OUTLINE_24} className={`${CLASSNAME}__icon`} />
			</button>
		)
	}

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<span className={`${CLASSNAME}__prefix`}>
				{localize('PerPageButton.perPagePrefix')}&nbsp;
			</span>
			{renderButton()}
			{isPopupOpen && (
				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={renderPopup}
					renderDesktopElement={renderDropdown}
				/>
			)}
		</div>
	)
}

PerPageButton.propTypes = {
	className: PropTypes.string
}

export default PerPageButton
