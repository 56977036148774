import React from 'react'
import BaseWidget from '../base/BaseWidget'
import { Textarea } from '@sznds/react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './TextareaWidget.less'

export const TEXTAREA_WIDGET_ID = 'textarea_widget'

const CLASSNAME = 'c-cp-textarea-widget'

function TextareaWidget({ formLineEntity, onChange, section }) {
	return (
		<BaseWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={TEXTAREA_WIDGET_ID}
			onChange={(_, value) => {
				onChange({
					id: formLineEntity.id,
					value,
					section
				})
			}}
			renderInput={(formLineEntity, { className, ...props }) => {
				return (
					<Textarea
						className={`${className} ${CLASSNAME}__input`}
						maxLength={formLineEntity.extra?.limit}
						disabled={Boolean(formLineEntity.disabled)}
						error={!!formLineEntity.errorMessage}
						{...props}
					/>
				)
			}}
		/>
	)
}

TextareaWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func.isRequired,
	section: PropTypes.string
}

export default TextareaWidget
