import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import CodebooksValuesEntity from 'app/model/codebooks/CodebooksValuesEntity'
import { PaginationEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import ActionButton from 'app/component/actionButton/ActionButton'
import AddNewButton from '../../components/addNewButton/AddNewButton'
import { EDIT_FILLED, TRASH_FILLED, EXTERNAL_LINK_OUTLINE } from '@sznds/icons'
import { ACTIONS } from './IACodebookManufacturerListController'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import { useFire } from 'app/base/componentHelpers'
import EntityFormPopup from '../../components/entityFormPopup/EntityFormPopup'
import EntityForm from 'app/component/entityForm/EntityForm'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import { CATEGORIES } from 'app/base/Constants'

import 'app/page/userweb/newAdvert/equipment/components/widgets/equipment/EquipmentWidgetCS.json'
import './IACodebookManufacturerListView.less'

const CLASSNAME = 'p-ia-codebook-manufacturer-list'

export default function IACodebookManufacturerListView({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading,
	isEditPopupOpened,
	formLineEntities = DEFAULT_PROPS.ARRAY,
	entityFormError,
	isWaiting
}) {
	const { $Router } = useContext(Context)
	const fire = useFire()
	const { rights = [] } = useContext(AdminEntityContext) || {}

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		const actions = []

		const { params: routeParams } = $Router.getCurrentRouteInfo()

		// - modely
		actions.push((entity, actionButtonProps = {}) => {
			const { id } = entity

			return (
				<ActionButton
					icon={EXTERNAL_LINK_OUTLINE}
					key='models'
					title='Modely výrobce'
					href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.CODEBOOK_MODEL_LIST, {
						[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]: [
							routeParams[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]
						],
						[InternalAdminUrlConvertor.constants.CODEBOOKS_PARENT_ID]: id
					})}
					{...actionButtonProps}
				/>
			)
		})

		if (rights.includes(ADMIN_RIGHTS.CODEBOOK_VALUES_EDIT)) {
			// - editace
			actions.push((entity, actionButtonProps = {}) => {
				return (
					<ActionButton
						icon={EDIT_FILLED}
						key='edit'
						title='Upravit výrobce'
						onClick={() => action(ACTIONS.OPEN_EDIT_POPUP, [entity])}
						{...actionButtonProps}
					/>
				)
			})
		}

		if (rights.includes(ADMIN_RIGHTS.CODEBOOK_VALUES_DELETE)) {
			actions.push((entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='delete'
					title='Smazat'
					onClick={() => action(ACTIONS.DELETE_VALUE, [entity])}
					{...actionButtonProps}
				/>
			))
		}

		return actions
	}

	return (
		<Layout title='Výpis výrobců'>
			{rights.includes(ADMIN_RIGHTS.CODEBOOK_VALUES_CREATE) && (
				<AddNewButton
					text='Přidat výrobce'
					onClick={() =>
						fire('action', {
							action: ACTIONS.OPEN_EDIT_POPUP
						})
					}
				/>
			)}

			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='name' sortParam='name' isDefaultAscending={true}>
						Výrobce
					</TableHeadSorter>
				]}
				rowCells={[
					({ name, seoName, codebookCategoryId }) => {
						const { seoName: categorySeoName } = Object.values(CATEGORIES).find(
							(category) => category.id === codebookCategoryId
						)

						return (
							<div className={`${CLASSNAME}__brand-box`}>
								<BrandIcon seoName={seoName} categorySeoName={categorySeoName} />
								{name}
							</div>
						)
					}
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>

			{isEditPopupOpened && (
				<EntityFormPopup
					title={
						formLineEntities.length > 0
							? formLineEntities.find((f) => f.id === 'value')
								? 'Editace výrobce'
								: 'Nový výrobce'
							: ''
					}
					onClose={() =>
						fire('action', {
							action: ACTIONS.CLOSE_EDIT_POPUP
						})
					}
				>
					<EntityForm
						variant={VARIANT.POPUP_FORM}
						formLineEntities={formLineEntities}
						entityFormError={entityFormError}
						isWaiting={isWaiting}
					/>
				</EntityFormPopup>
			)}
		</Layout>
	)
}

IACodebookManufacturerListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(CodebooksValuesEntity)),
		paginationEntity: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool,
	isEditPopupOpened: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	isWaiting: PropTypes.bool
}
