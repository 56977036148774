import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import FilterPopup from '../../components/filterPopup/FilterPopup'
import * as FormLines from '@inzeraty/form-lines'

export default class UndoablePopup extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			title: PropTypes.string,
			defaultType: PropTypes.bool,
			undoChangesOnClose: PropTypes.bool,
			onClose: PropTypes.func,
			onReset: PropTypes.func,
			onChange: PropTypes.func,
			contentRef: PropTypes.shape({
				current: PropTypes.object
			}),
			children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
			headerDataDotData: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			className: '',
			formLineEntity: {},
			title: '',
			defaultType: true,
			undoChangesOnClose: true,
			onClose: () => {},
			onReset: () => {},
			onChange: () => {}
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			undoValue: props.formLineEntity.value
		}

		this._onClose = this._onClose.bind(this)
	}

	render() {
		const {
			className,
			formLineEntity: { id: filterId },
			title,
			defaultType,
			onReset,
			contentRef,
			children,
			headerDataDotData
		} = this.props

		return (
			<FilterPopup
				className={className}
				filterId={filterId}
				title={title}
				defaultType={defaultType}
				onClose={this._onClose}
				onReset={onReset}
				contentRef={contentRef}
				headerDataDotData={headerDataDotData}
			>
				{children}
			</FilterPopup>
		)
	}

	_onClose() {
		const { undoValue } = this.state
		const {
			onChange,
			onClose,
			formLineEntity: { id },
			undoChangesOnClose
		} = this.props

		if (undoChangesOnClose) onChange(id, undoValue)

		onClose(id)
	}
}
