
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['IAHistoryForm'] = {
  historySection: function(d) { return "Historie vozidla"; },
  submit: function(d) { return "Uložit a vrátit se na výpis"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	