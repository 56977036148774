import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { CategoryEntity } from '@inzeraty/models'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import { Surface } from '@sznds/react'
import FiltersExtension, { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import AdvertListExtension from 'app/component/advertList/AdvertListExtension'
import MainForm from '../main/MainForm'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import CategoryPickerButton from 'app/component/pickerButton/CategoryPickerButtonView'
import FilterConstants from 'app/model/filter/FilterConstants'
import { SEARCH_TYPE_SESSION } from 'app/base/StorageIds'

import './SidePanel.less'

const CLASSNAME = 'c-side-panel'

const DEFAULT_CATEGORY_PROP = new CategoryEntity(DEFAULT_PROPS.OBJECT)

const ALL_SECTIONS_COLLAPSED = -1
const STICKY_OFFSET = 16 // Offset sticky boxu shora a zdolu

class SidePanel extends AbstractPureComponent {
	static get propTypes() {
		return {
			advertListAndPagination: PropTypes.object,
			className: PropTypes.string,
			formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)).isRequired,
			changeFilter: PropTypes.func.isRequired,
			categoryEntity: PropTypes.instanceOf(CategoryEntity).isRequired,
			openedFilterDropdown: PropTypes.object,
			redirect: PropTypes.func,
			showCategoryPicker: PropTypes.bool,
			isSeller: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			advertListAndPagination: DEFAULT_PROPS.OBJECT,
			className: '',
			formLineEntities: DEFAULT_PROPS.ARRAY,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			categoryEntity: DEFAULT_CATEGORY_PROP,
			redirect: DEFAULT_PROPS.FUNCTION,
			showCategoryPicker: false,
			isSeller: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			openedSectionIndex: 0
		}

		this._expandableContainerRef = React.createRef()

		this._renderSection = this._renderSection.bind(this)
		this._onSectionToggle = this._onSectionToggle.bind(this)

		this._redirectOnChange = this._redirectOnChange.bind(this)

		this._recalcExpandableContainerHeight = this._recalcExpandableContainerHeight.bind(this)
	}

	componentDidUpdate(prevProps) {
		const { openedFilterDropdown } = this.props
		const { openedFilterDropdown: prevOpenedFilterDropdown } = prevProps

		if (openedFilterDropdown !== prevOpenedFilterDropdown) {
			this._recalcExpandableContainerHeight()
		}
	}

	render() {
		const { showCategoryPicker, className, advertListAndPagination = {} } = this.props
		const { paginationEntity: { total = 0, limit = 1 } = {}, advertList } = advertListAndPagination
		const hasPaging = Math.ceil(total / limit) > 1
		const isEmpty = advertList && advertList.length === 0

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[`${CLASSNAME}--paging`]: hasPaging,
					[className]: !!className,
					[`${CLASSNAME}--empty`]: isEmpty
				})}
			>
				{showCategoryPicker && <CategoryPickerButton className={`${CLASSNAME}__sort-btn`} />}
				{this._renderContent()}
			</div>
		)
	}

	_renderContent() {
		const { isOperatingLease, formLineEntities, categoryEntity, openedFilterDropdown } = this.props
		const { openedSectionIndex } = this.state

		const mainFormFormLineEntities = this._filterFormLineEntities(formLineEntities)

		return (
			<div ref={this._expandableContainerRef}>
				<Surface
					tagName='div'
					surface={3}
					className={this.cssClasses({
						[`${CLASSNAME}__content`]: true
					})}
				>
					{formLineEntities.length > 0 ? (
						<MainForm
							isOperatingLease={isOperatingLease}
							formLineEntities={mainFormFormLineEntities}
							changeFilter={this._redirectOnChange}
							categoryEntity={categoryEntity}
							context={FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL}
							openedFilterDropdown={openedFilterDropdown}
							openedSectionIndex={openedSectionIndex}
							onSectionToggle={this._onSectionToggle}
							renderSection={this._renderSection}
						/>
					) : (
						this._renderSideFilterPlaceholder()
					)}
				</Surface>
			</div>
		)
	}

	_filterFormLineEntities(formLineEntities) {
		const { isSeller } = this.props

		if (isSeller) {
			return formLineEntities.filter(
				(formLine) => formLine.id !== FilterConstants.formLineIds.VENDOR
			)
		} else {
			return formLineEntities
		}
	}

	_recalcExpandableContainerHeight() {
		// podle potreby prifukujeme obalujici kontejner tak, aby otevreny dropdown nevytekl
		// mimo tento kontejner. Tato komponenta byva vetsinou stickovana, takze pak dochazelo k
		// situacim, kdy uzivatel otevrel dropdown a musel zascrollovat uplne dolu (tim se vypnulo
		// stickovani), aby videl jeho cely obsah.
		const container = this._expandableContainerRef.current
		const dropdown = document && document.querySelector(`.${CLASSNAME} .ob-c-dropdown`)

		if (container) {
			if (dropdown) {
				const { bottom: dropdownBottomPosition } = dropdown.getBoundingClientRect()
				const {
					bottom: containerBottomPosition,
					height: containerHeight
				} = container.getBoundingClientRect()

				if (dropdownBottomPosition > containerBottomPosition) {
					container.style.height = `${
						containerHeight + (dropdownBottomPosition - containerBottomPosition)
					}px`
				}
			} else {
				// vratime puvodni vysku, neni otevren zadny dropdown
				container.style.height = 'auto'
			}
		}
	}

	_redirectOnChange(id, value, callback = () => {}) {
		const { changeFilter, redirect, formLineEntities } = this.props
		const formLineEntity = formLineEntities.find((formLineEntity) => formLineEntity.id === id)

		const { AnalyticsDataSender, $Router, $SessionStorage } = this._utils

		const { route } = $Router.getCurrentRouteInfo()
		const routeName = route.getName()

		AnalyticsDataSender.hitFilterChange(formLineEntity, value, 'Sidefilter', routeName)
		$SessionStorage.set(SEARCH_TYPE_SESSION, {
			searchType: 'filters',
			query: undefined,
			lastUrlRouteParams: $Router.getCurrentRouteInfo().params
		})

		changeFilter(id, value, (...args) => {
			callback(...args)
			redirect()
		})
	}

	_renderSection(sectionIndex, sectionName, renderedSection) {
		return <div className={this._getSectionClassName(sectionIndex)}>{renderedSection}</div>
	}

	_renderSideFilterPlaceholder() {
		return (
			<div className={`${CLASSNAME}__placeholder`}>
				<div className={`${CLASSNAME}__placeholder-filter`}>
					{this._renderPlaceholderHeader()}
					<div className={`${CLASSNAME}__placeholder-brands-items`}>
						<span className={`${CLASSNAME}__placeholder-brand`}></span>
						<span className={`${CLASSNAME}__placeholder-brand`}></span>
						<span className={`${CLASSNAME}__placeholder-brand`}></span>
						<span className={`${CLASSNAME}__placeholder-brand`}></span>
					</div>
					<div className={`${CLASSNAME}__placeholder-brands-search`}></div>
				</div>
				{this._renderPlaceholderRange()}
				{this._renderPlaceholderRange()}
				{this._renderPlaceholderChechbox()}
				{this._renderPlaceholderChechbox()}
			</div>
		)
	}

	_renderPlaceholderRange() {
		return (
			<div className={`${CLASSNAME}__placeholder-filter`}>
				{this._renderPlaceholderHeader()}
				<div className={`${CLASSNAME}__placeholder-input-wrapper`}>
					<div className={`${CLASSNAME}__placeholder-input`}></div>
					<div className={`${CLASSNAME}__placeholder-input`}></div>
				</div>
			</div>
		)
	}

	_renderPlaceholderChechbox() {
		return (
			<div className={`${CLASSNAME}__placeholder-filter`}>
				{this._renderPlaceholderHeader()}
				<div className={`${CLASSNAME}__placeholder-checkbox-wrapper`}>
					<div className={`${CLASSNAME}__placeholder-checkbox`}></div>
					<div className={`${CLASSNAME}__placeholder-checkbox`}></div>
				</div>
			</div>
		)
	}

	_renderPlaceholderHeader() {
		return <div className={`${CLASSNAME}__placeholder-header`}></div>
	}

	_onSectionToggle(sectionIndex, sectionName, isOpened) {
		const openedSectionIndex = isOpened ? sectionIndex : ALL_SECTIONS_COLLAPSED

		this.setState(
			() => ({
				openedSectionIndex
			}),
			() => {
				if (openedSectionIndex >= 0) {
					this._checkSectionVisibility(openedSectionIndex)
				}
			}
		)
	}

	_checkSectionVisibility(openedSectionIndex) {
		const sectionElement = document.querySelector(
			`.${this._getSectionClassName(openedSectionIndex)}`
		)
		const sectionElementStartPosition = sectionElement.getBoundingClientRect().top
		const sectionElementEndPosition = sectionElement.getBoundingClientRect().bottom

		if (sectionElementStartPosition < 0 || sectionElementEndPosition > window.innerHeight) {
			animateScrollTo(window.scrollY + sectionElementStartPosition - STICKY_OFFSET)
		}
	}

	_getSectionClassName(index) {
		return `${CLASSNAME}__section-${index}`
	}
}

const selectors = (state) => ({
	isOperatingLease: state[FiltersExtension.stateIds.IS_OPERATING_LEASE],
	advertListAndPagination: state[AdvertListExtension.stateIds.ADVERT_LIST_PAGINATION],
	formLineEntities: state[FiltersExtension.stateIds.FORM_LINE_ENTITIES],
	changeFilter: state[FiltersExtension.stateIds.CHANGE_FILTER],
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	openedFilterDropdown: state[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN],
	redirect: state[FiltersExtension.stateIds.REDIRECT]
})

export default select(selectors)(SidePanel)
