import React, { useState, useContext } from 'react'
import Context from 'ima/page/context'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import {
	WALLET_FILLED_24,
	EDIT_FILLED_24,
	TRASH_FILLED_24,
	SUBTRACT_SQUIRCLE_FILLED_24,
	PLAY_FILLED_24,
	CONTRACT_32,
	BOOKMARK_ADD_ALT1_FILLED_24,
	VISIBILITY_FILLED_24
} from '@sznds/icons'
import { ACTION_TYPE } from './AdvertActionsConstants'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import ClientStatisticsUrlConvertor from 'app/page/userweb/clientStatistics/ClientStatisticsUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import PropTypes from 'prop-types'
import ActionsMenu from 'app/component/actionsMenu/ActionsMenu'
import { PREFERRED_OFFER } from 'app/base/IconsConstants'
import PublishModal from '../publishModal/PublishModal'
import { ADVERT_STATE } from '../getAdvertState'
import ToppedModal from '../toppedModal/ToppedModal'

import './AdvertActionsCS.json'

export default function AdvertActions({
	advertState,
	isOperatingLease,
	isPremise,
	advertId,
	preferredOffer,
	premiseId
}) {
	const [open, setOpen] = useState(false)
	const localize = useLocalize()
	const fire = useFire()
	const { $Router: router } = useContext(Context)
	const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
	const [isToppedModalOpen, setIsToppedModalOpen] = useState(false)

	const handleAction = (action) => {
		// Pokud je inzerát deaktivovaný adminem a uživatel ho chce aktivovat, tak ho upozorníme aby si inzerát upravil.
		if (
			action === ACTION_TYPE.PUBLISH &&
			advertState === ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED
		) {
			setIsPublishModalOpen(true)
		} else if (action === ACTION_TYPE.TOPPED_ADVERT) {
			//isPremise kontrola mi umozni zjistit jestli se jedna o bazarnika ci nikoliv
			if (isPremise) {
				setIsToppedModalOpen(true)
			} else {
				const url = router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_TOPPED_PAYMENT, {
					[AdvertAdminUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: advertId
				})
				router.redirect(url)
			}
		} else {
			fire(action, advertId)
		}

		setOpen(false)
	}

	const getPreparedActions = (actionProps) => {
		return getActions({ localize, router, ...actionProps }).map(
			({ action, text, icon, href, target, dataDotText }) => {
				const tagName = href ? 'a' : 'button'

				const actionProps = {}

				if (action) {
					Object.assign(actionProps, {
						onClick: () => handleAction(action)
					})
				}

				if (href) {
					Object.assign(actionProps, {
						href,
						target,
						tabIndex: 0
					})
				}

				return {
					tagName,
					icon,
					text,
					actionProps,
					dataDotText
				}
			}
		)
	}

	return (
		<>
			<ActionsMenu
				openerText={localize('AdvertActions.actions')}
				modalTitle={localize('AdvertActions.advertActions')}
				blindClosePrefix={localize('AdvertActions.actionsBlindClosePrefix')}
				actions={getPreparedActions({
					advertState,
					advertId,
					isOperatingLease,
					isPremise,
					preferredOffer,
					isForMassActionsBar: false,
					showToppedAction: true,
					showDeactivatedAction: true,
					showActivatedAction: true
				})}
				isOpen={open}
				setIsOpenCallback={(isOpen) => {
					setOpen(isOpen)
				}}
			/>
			<PublishModal
				isOpen={isPublishModalOpen}
				onClose={() => setIsPublishModalOpen(false)}
				advertId={advertId}
			/>
			<ToppedModal
				isOpen={isToppedModalOpen}
				onClose={() => setIsToppedModalOpen(false)}
				advertIds={[advertId]}
				premiseId={premiseId}
			/>
		</>
	)
}

export const getActions = ({
	localize,
	advertState,
	isOperatingLease,
	isPremise,
	advertId,
	router,
	isForMassActionsBar, // sluzi pre zobrazenie akcie pre hromadne akcie
	preferredOffer: { addPOButton, removePOButton } = {},
	showToppedAction,
	showDeactivatedAction,
	showActivatedAction
}) => {
	const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
	const {
		URL_APP_PARAMS: URL_STATISTICS_PARAMS,
		FILTER_OPTIONS,
		DEAL_TYPE_OPTIONS
	} = ClientStatisticsUrlConvertor.constants
	const {
		ACTIVE,
		INACTIVE,
		INACTIVE_EXPIRED,
		INACTIVE_NOT_PAID,
		INACTIVE_USER_DEACTIVATED,
		INACTIVE_ADMIN_DEACTIVATED,
		INACTIVE_ADMIN_DEACTIVATED_REJECTED
	} = ADVERT_STATE

	const is = (state) => advertState === state

	const actions = []

	if (advertId) {
		actions.push(
			{
				href: router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_BASIC_INFO, {
					[URL_APP_PARAMS.ADVERT_ID]: advertId
				}),
				text: localize('AdvertActions.edit'),
				icon: EDIT_FILLED_24,
				dataDotText: 'edit'
			},
			{
				action: removePOButton ? ACTION_TYPE.REMOVE_FROM_PO : ACTION_TYPE.ADD_TO_PO,
				text: removePOButton
					? localize('AdvertActions.removeFromPO')
					: localize('AdvertActions.addToPO'),
				icon: PREFERRED_OFFER,
				isShown: isPremise && !isForMassActionsBar && is(ACTIVE),
				dataDotText: removePOButton ? 'not-prefered' : 'prefered'
			},
			{
				action: ACTION_TYPE.TOPPED_ADVERT,
				text: localize('AdvertActions.topped'),
				icon: BOOKMARK_ADD_ALT1_FILLED_24,
				isShown: is(ACTIVE) && !isForMassActionsBar && showToppedAction,
				dataDotText: 'topped'
			},
			{
				href: router.link(
					ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS,
					Object.assign(
						{
							[URL_STATISTICS_PARAMS.FILTER_BY]: FILTER_OPTIONS.ADVERT_ID,
							[URL_STATISTICS_PARAMS.FILTER_BY_VALUE]: advertId
						},
						isOperatingLease && {
							[URL_STATISTICS_PARAMS.DEAL_TYPE]: DEAL_TYPE_OPTIONS.OPERATING_LEASE
						}
					)
				),
				text: localize('AdvertActions.statistics'),
				icon: VISIBILITY_FILLED_24,
				isShown: isPremise && !isForMassActionsBar,
				dataDotText: 'statistics'
			}
		)
	}

	actions.push(
		{
			action: ACTION_TYPE.SUSPEND,
			text: localize('AdvertActions.deactivate'),
			icon: SUBTRACT_SQUIRCLE_FILLED_24,
			isShown: is(ACTIVE) || (isForMassActionsBar && showDeactivatedAction),
			dataDotText: 'inactive'
		},
		{
			action: ACTION_TYPE.PUBLISH,
			text: localize('AdvertActions.activate'),
			icon: PLAY_FILLED_24,
			isShown:
				is(INACTIVE) ||
				is(INACTIVE_USER_DEACTIVATED) ||
				is(INACTIVE_ADMIN_DEACTIVATED) ||
				is(INACTIVE_ADMIN_DEACTIVATED_REJECTED) ||
				(isForMassActionsBar && showActivatedAction),
			dataDotText: 'activate'
		}
	)

	if (advertId) {
		actions.push(
			{
				href: router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, {
					[URL_APP_PARAMS.ADVERT_ID]: advertId
				}),
				text: localize('AdvertActions.pay'),
				icon: WALLET_FILLED_24,
				isShown:
					!isPremise &&
					(is(ACTIVE) ||
						is(INACTIVE) ||
						is(INACTIVE_EXPIRED) ||
						is(INACTIVE_NOT_PAID) ||
						is(INACTIVE_USER_DEACTIVATED) ||
						is(INACTIVE_ADMIN_DEACTIVATED)),
				dataDotText: 'go-to-payment'
			},
			{
				href: router.link(ROUTE_NAMES.CLIENT_ADMIN.BOARD_BEHIND_WINDSCREEN, {
					[URL_APP_PARAMS.ADVERT_ID]: advertId
				}),
				target: `boadr_behind_screen_${advertId}`,
				text: localize('AdvertActions.createBoardBehindWindscreen'),
				icon: CONTRACT_32,
				dataDotText: 'create-banner'
			}
		)
	}

	actions.push(
		{
			action: ACTION_TYPE.REMOVE,
			text: localize('AdvertActions.remove'),
			icon: TRASH_FILLED_24,
			dataDotText: 'delete'
		},
		{
			action: ACTION_TYPE.REMOVE_FROM_PO,
			text: localize('AdvertActions.removeFromPO'),
			icon: PREFERRED_OFFER,
			isShown: isPremise && isForMassActionsBar && removePOButton,
			dataDotText: 'not-prefered'
		},
		{
			action: ACTION_TYPE.ADD_TO_PO,
			text: localize('AdvertActions.addToPO'),
			icon: PREFERRED_OFFER,
			isShown: isPremise && isForMassActionsBar && addPOButton,
			dataDotText: 'prefered'
		},
		{
			action: ACTION_TYPE.TOPPED_ADVERT,
			text: localize('AdvertActions.topped'),
			icon: BOOKMARK_ADD_ALT1_FILLED_24,
			isShown: isPremise && isForMassActionsBar && showToppedAction,
			dataDotText: 'topped'
		}
	)

	return actions.filter(({ isShown = true }) => isShown)
}

AdvertActions.propTypes = {
	advertState: PropTypes.string,
	isOperatingLease: PropTypes.bool,
	isPremise: PropTypes.bool,
	advertId: PropTypes.number,
	preferredOffer: PropTypes.bool,
	premiseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
