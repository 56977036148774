import React from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import ClientBaseController from 'app/base/ClientBaseController'
import * as FormLines from '@inzeraty/form-lines'
import Wallet from 'app/component/wallet/Wallet'
import { FORM_LINES_IDS } from '../../ClientProfileFormLineIds'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { SimpleData } from '../simpleData/SimpleDataWidget'

import './WalletWidget.less'

export const WALLET_WIDGET_ID = 'wallet_widget'

const CLASSNAME = 'c-cp-wallet-widget'

const WalletWidget = ({ formLineEntity, walletInfo, premiseInfo }) => {
	const { walletLogin } = walletInfo
	const { label } = formLineEntity

	if (formLineEntity.id === FORM_LINES_IDS.WALLET_ACOUNT) {
		return (
			<SimpleData label={label}>
				<Wallet.Acount walletInfo={walletInfo} premiseInfo={premiseInfo} />
			</SimpleData>
		)
	} else if (formLineEntity.id === FORM_LINES_IDS.WALLET_INFO) {
		return (
			!isNullOrUndefined(walletLogin) && (
				<SimpleData label={label}>
					<Wallet.Info
						walletInfo={walletInfo}
						premiseInfo={premiseInfo}
						className={`${CLASSNAME}__wallet`}
					/>
				</SimpleData>
			)
		)
	} else {
		return null
	}
}

WalletWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	walletInfo: PropTypes.shape({
		credit: PropTypes.number,
		walletLogin: PropTypes.string,
		walletUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		serverError: PropTypes.bool
	}),
	premiseInfo: PropTypes.object
}

WalletWidget.defaultProps = {
	walletInfo: DEFAULT_PROPS.OBJECT,
	premiseInfo: DEFAULT_PROPS.OBJECT
}

const selectors = (state) => ({
	premiseInfo: state[ClientBaseController.STATE_KEYS.PREMISE_INFO],
	walletInfo: state[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]
})

export default React.memo(select(selectors)(WalletWidget))
