import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { CategoryEntity } from '@inzeraty/models'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import FilterConstants from 'app/model/filter/FilterConstants'
import FiltersExtension, { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import FilterPopup from 'app/component/filters/components/filterPopup/FilterPopup'
import FilterPopupButton from './shared/FilterPopupButton'
import FilterPopupWithOpener from './shared/FilterPopupWithOpener'
import MainForm from 'app/component/filters/forms/main/MainForm'
import ROUTE_NAMES from 'app/base/RouteNames'

import './ExtendedFilterPopupWithOpener.less'
import './ExtendedFilterPopupWithOpenerCS.json'

const CLASSNAME = 'c-extended-filter-popup-with-opener'

const DEFAULT_CATEGORY_PROP = new CategoryEntity(DEFAULT_PROPS.OBJECT)

class ExtendedFilterPopupWithOpener extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)).isRequired,
			changeFilter: PropTypes.func.isRequired,
			context: PropTypes.string,
			categoryEntity: PropTypes.instanceOf(CategoryEntity).isRequired,
			filterUrlParams: PropTypes.object.isRequired,
			openedFilterPopups: PropTypes.array.isRequired,
			filteredAdvertsCountTotal: PropTypes.number.isRequired,
			isLoadingFilteredAdvertsCountTotal: PropTypes.bool.isRequired,
			renderOpener: PropTypes.func.isRequired
		}
	}

	static get defaultProps() {
		return {
			formLineEntities: DEFAULT_PROPS.ARRAY,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			categoryEntity: DEFAULT_CATEGORY_PROP,
			filterUrlParams: DEFAULT_PROPS.OBJECT,
			openedFilterPopups: DEFAULT_PROPS.ARRAY,
			isLoadingFilteredAdvertsCountTotal: false,
			renderOpener: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderPopup = this._renderPopup.bind(this)
		this._renderHeaderContent = this._renderHeaderContent.bind(this)

		this._closePopup = this._closePopup.bind(this)
		this._resetAllFilters = this._resetAllFilters.bind(this)

		this._validateRangeFilters = this._validateRangeFilters.bind(this)
	}

	render() {
		const { renderOpener } = this.props

		const popupDefinition = this._getPopupDefinition()

		return (
			<FilterPopupWithOpener
				popupDefinition={popupDefinition}
				renderOpener={renderOpener}
				renderPopup={this._renderPopup}
			/>
		)
	}

	_renderPopup() {
		const popupDefinition = this._getPopupDefinition()

		const { filterId } = popupDefinition

		const isPopupDefaultType = this._isDefaultPopupType(popupDefinition)

		return (
			<FilterPopup
				wrapperClassName={CLASSNAME}
				className={`${CLASSNAME}__content`}
				filterId={filterId}
				resetButtonText={this.localize('ExtendedFilterPopupWithOpener.cancelAllFilters')}
				defaultType={isPopupDefaultType}
				hasStickyHeader={false}
				renderHeaderContent={this._renderHeaderContent}
				onClose={this._closePopup}
				onReset={this._resetAllFilters}
			>
				<div className={`${CLASSNAME}__form`}>{this._renderExtendedFilterForm()}</div>

				<div className={`${CLASSNAME}__sticky-panel`}>{this._renderButton(popupDefinition)}</div>
			</FilterPopup>
		)
	}

	_renderHeaderContent(data) {
		const { renderResetButton, renderCloseButton } = data

		return (
			<div>
				<div className={`${CLASSNAME}__heading`}>
					<span className={`${CLASSNAME}__title`}>
						{this.localize('ExtendedFilterPopupWithOpener.specifyYourSelection')}
					</span>
					<span className={`${CLASSNAME}__dash`}>&ndash;</span>
					{renderResetButton()}
				</div>
				{renderCloseButton()}
			</div>
		)
	}

	_filterFormLineEntities(formLineEntities) {
		const { route } = this.utils.$Router.getCurrentRouteInfo()
		const isSellerRoute = [
			ROUTE_NAMES.USERWEB.SELLER,
			ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
		].includes(route.getName())

		if (isSellerRoute) {
			return formLineEntities.filter(
				(formLine) => formLine.id !== FilterConstants.formLineIds.VENDOR
			)
		} else {
			return formLineEntities
		}
	}

	_renderExtendedFilterForm() {
		const {
			isOperatingLease,
			formLineEntities,
			changeFilter,
			categoryEntity,
			openedFilterPopups,
			openedFilterDropdown
		} = this.props

		return (
			<MainForm
				isOperatingLease={isOperatingLease}
				formLineEntities={this._filterFormLineEntities(formLineEntities)}
				changeFilter={changeFilter}
				categoryEntity={categoryEntity}
				context={FILTER_CONTEXT.EXTENDED_FILTER}
				openedFilterPopups={openedFilterPopups}
				openedFilterDropdown={openedFilterDropdown}
			/>
		)
	}

	_validateRangeFilters() {
		const { formLineEntities } = this.props

		const validateRange = (value) => {
			const { valueFrom, valueTo } = value

			if (valueFrom >= 0 && valueTo >= 0) return valueFrom <= valueTo
			else return true
		}

		const isRangeFilter = (formLineEntity) => {
			const { value: { valueFrom, valueTo } = {} } = formLineEntity

			return valueFrom >= 0 || valueTo >= 0
		}

		return formLineEntities.every((formLineEntity) => {
			if (isRangeFilter(formLineEntity)) {
				return validateRange(formLineEntity.value)
			} else {
				return true
			}
		})
	}

	_renderButton(popupDefinition = {}) {
		const {
			isOperatingLease,
			filteredAdvertsCountTotal,
			isLoadingFilteredAdvertsCountTotal,
			categoryEntity,
			filterUrlParams
		} = this.props

		const filterPopupLogicData = {
			closePopup: this._closePopup,
			closePopupWithoutSavingChanges: () =>
				this.fire('closeFilterPopup', {
					filterId: FilterConstants.filterIds.EXTENDED_FILTER_ID
				}),
			canBePopupClosed: () => this._validateRangeFilters()
		}

		const filterFormLogicData = {
			form: {
				validateForm: () => this._validateRangeFilters()
			},
			advertsCount: {
				filteredAdvertsCountTotal,
				isLoadingFilteredAdvertsCountTotal
			},
			other: {
				categoryEntity,
				filterUrlParams
			}
		}

		return (
			<FilterPopupButton
				isOperatingLease={isOperatingLease}
				filterPopupLogicData={filterPopupLogicData}
				filterFormLogicData={filterFormLogicData}
				popupDefinition={popupDefinition}
			/>
		)
	}

	_closePopup() {
		if (this._validateRangeFilters()) {
			this.fire('closeFilterPopupAndRedirect', {
				filterId: FilterConstants.filterIds.EXTENDED_FILTER_ID
			})
		}
	}

	_resetAllFilters() {
		this.fire('filterResetAll')
	}

	_getPopupDefinition() {
		const { openedFilterPopups } = this.props

		return openedFilterPopups.find(
			(p) => p.filterId === FilterConstants.filterIds.EXTENDED_FILTER_ID
		)
	}

	_isDefaultPopupType(popupDefinition) {
		return popupDefinition
			? popupDefinition.filterPopupType === FilterConstants.filterPopupType.DEFAULT
			: true
	}
}

const selectors = (state) => ({
	isOperatingLease: state[FiltersExtension.stateIds.IS_OPERATING_LEASE],
	formLineEntities: state[FiltersExtension.stateIds.FORM_LINE_ENTITIES],
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	filterUrlParams: state[FiltersExtension.stateIds.URL_PARAMS],
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS],
	openedFilterDropdown: state[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL],
	isLoadingFilteredAdvertsCountTotal:
		state[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(selectors)(ExtendedFilterPopupWithOpener)
