import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import UndoablePopup from 'app/component/filters/popups/undoablePopup/UndoablePopup'

import './UndoablePopup.less'

const CLASSNAME = 'c-undoable-filter-popup'

export default class UndoablePopupFactory extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			onChange: PropTypes.func,
			title: PropTypes.string,
			filterPopupLogicData: PropTypes.object.isRequired,
			renderContent: PropTypes.func.isRequired,
			renderFooter: PropTypes.func,
			headerDataDotData: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			onChange: DEFAULT_PROPS.FUNCTION,
			title: '',
			filterPopupLogicData: DEFAULT_PROPS.OBJECT,
			renderContent: DEFAULT_PROPS.FUNCTION,
			renderFooter: DEFAULT_PROPS.FUNCTION
		}
	}

	render() {
		const {
			formLineEntity,
			onChange,
			title,
			filterPopupLogicData,
			renderContent,
			renderFooter,
			headerDataDotData
		} = this.props
		const {
			isDefaultPopupType,
			closePopup,
			closePopupWithoutSavingChanges,
			resetFilter
		} = filterPopupLogicData

		// kvuli problemum se scrollovanim na iOS je potreba predat
		// referenci na scrollovany obsah
		const scrollContentRef = React.createRef()

		return (
			<UndoablePopup
				className={`${CLASSNAME}__content`}
				formLineEntity={formLineEntity}
				title={title}
				defaultType={isDefaultPopupType}
				undoChangesOnClose={!isDefaultPopupType}
				onClose={isDefaultPopupType ? closePopup : closePopupWithoutSavingChanges}
				onReset={resetFilter}
				onChange={onChange}
				contentRef={scrollContentRef}
				headerDataDotData={headerDataDotData}
			>
				<div
					data-e2e={`cf-popup-form-${formLineEntity.id}`}
					className={`${CLASSNAME}__form`}
					ref={scrollContentRef}
				>
					{renderContent(filterPopupLogicData)}
				</div>

				{renderFooter(filterPopupLogicData)}
			</UndoablePopup>
		)
	}
}
