import React, { memo, Fragment } from 'react'
import classnames from 'classnames'
import { renderWidgetsByIds } from '@inzeraty/form-lines'
import { WIDGET_BY_ID } from './widgets/WidgetIds'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './InternalAdminWidgets.less'

export const VARIANT = {
	FORM: 'form',
	POPUP_FORM: 'popup-form',
	FILTER: 'filter'
}
export const CLASSNAME = 'c-ia-widget'

const InternalAdminWidgets = memo(
	({ formLineEntities = [], onChange = (data) => {}, variant, className }) => {
		return (
			<Fragment>
				{renderWidgetsByIds(
					formLineEntities,
					{
						onChange,
						className: classnames({
							[CLASSNAME]: true,
							[`${CLASSNAME}--${variant}`]: true,
							[className]: !!className
						})
					},
					WIDGET_BY_ID
				)}
			</Fragment>
		)
	}
)

InternalAdminWidgets.propTypes = {
	formLineEntities: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)])
	),
	onChange: PropTypes.func,
	className: PropTypes.string,
	variant: PropTypes.string
}

export default InternalAdminWidgets
