import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import BaseTextWidget, {
	TEXT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/text/TextWidget'
import { CLASSNAME as IA_CLASSNAME } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import BaseWidget from '../baseWidget/BaseWidget'

export { TEXT_WIDGET_ID }

const TextWidget = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT } = props

	const { id } = formLineEntity

	const inputId = `${id}-input`

	return (
		<BaseWidget formLineEntity={formLineEntity} inputId={inputId}>
			<div className={`${IA_CLASSNAME}__input`}>
				<BaseTextWidget {...props} id={inputId} />
			</div>
		</BaseWidget>
	)
}

TextWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(TextWidget)
