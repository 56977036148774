import ROUTE_NAMES from 'app/base/RouteNames'
import { DrtgHelper as InzeratyDrtgHelper } from '@inzeraty/helpers'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'

const GENERIC_RETARGET_ID = 20423

const RETARGET_PRICE_LEVELS = [
	{
		PATH: 'od1000',
		MIN_PRICE: 1000001,
		MAX_PRICE: Number.MAX_SAFE_INTEGER
	},
	{
		PATH: '500-1000',
		MIN_PRICE: 500001,
		MAX_PRICE: 1000000
	},
	{
		PATH: '300-500',
		MIN_PRICE: 300001,
		MAX_PRICE: 500000
	},
	{
		PATH: '150-300',
		MIN_PRICE: 150001,
		MAX_PRICE: 300000
	},
	{
		PATH: '50-150',
		MIN_PRICE: 50001,
		MAX_PRICE: 150000
	},
	{
		PATH: 'do50',
		MIN_PRICE: 0,
		MAX_PRICE: 50000
	}
]

// Nastaveni dynamickeho retargetingu (DRTG). Ten slouzi ke sbirani informaci o tom,
// ktere inzeraty uzivatel navstivil, respektive inzeraty jakych inzerentu.
export default class DrtgHelper {
	constructor(analyticsDataSender) {
		this._analyticsDataSender = analyticsDataSender
	}

	hitPageView(pageData) {
		const actualRouteName = pageData.route.getName()

		if (
			actualRouteName === ROUTE_NAMES.USERWEB.ADVERT_DETAIL ||
			actualRouteName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
		) {
			const { advertEntity: { premise: { retargetingId } = {} } = {} } = pageData.response.pageState

			if (retargetingId) {
				InzeratyDrtgHelper.retargetingHit({
					rtgId: retargetingId
				})
			}
		}

		if (Object.values(ROUTE_NAMES.USERWEB).includes(actualRouteName)) {
			InzeratyDrtgHelper.retargetingHit({
				rtgId: GENERIC_RETARGET_ID
			})
		}

		if (
			actualRouteName === ROUTE_NAMES.USERWEB.ADVERT_LIST ||
			actualRouteName === ROUTE_NAMES.USERWEB.SELLER
		) {
			const { PRICE_FROM, PRICE_TO } = AdvertListUrlConvertor.constants.URL_APP_PARAMS
			const priceFrom = parseInt(pageData.params[PRICE_FROM])
			const priceTo = parseInt(pageData.params[PRICE_TO])
			if (priceFrom || priceTo) {
				InzeratyDrtgHelper.retargetingHit({
					rtgId: GENERIC_RETARGET_ID,
					rtgUrl: this._getRetargetingPriceUrl(priceFrom, priceTo)
				})
			}
		}
	}

	_getRetargetingPriceUrl(priceFrom, priceTo) {
		const pricePath = this._getRetargetingPricePath(priceFrom, priceTo)
		return `${location.origin}${location.pathname}?cena=${pricePath}`
	}

	_getRetargetingPricePath(priceFrom, priceTo) {
		let comparePrice
		if (priceTo) {
			comparePrice = priceTo
		} else if (priceFrom) {
			comparePrice = priceFrom
		}
		if (comparePrice) {
			for (let PRICE of RETARGET_PRICE_LEVELS) {
				if (comparePrice >= PRICE.MIN_PRICE && comparePrice <= PRICE.MAX_PRICE) {
					return PRICE.PATH
				}
			}
		}
		return ''
	}
}
