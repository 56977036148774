import LOCALITY_CONSTANTS from './LocalityConstants'

export const SEPARATORS = {
	NEWLINE: '\n',
	COMMA: ','
}

export default class LocalityHelper {
	/**
	 * Vrátí řetězec lokality.
	 *
	 * @static
	 * @param {Object} locality Data lokality.
	 * @param {String} separator
	 * @return {String}
	 */
	static localitySentence(
		{
			street = '',
			address = '',
			municipality = '',
			district = '',
			quarter = '',
			ward = '',
			zip = ''
		},
		separator = SEPARATORS.NEWLINE
	) {
		let localitySentence = ''

		const districtName = district
			? district.indexOf(LOCALITY_CONSTANTS.district) >= 0
				? district
				: `${LOCALITY_CONSTANTS.district} ${district}`
			: ''
		const wardName = ward
			? ward.indexOf(LOCALITY_CONSTANTS.ward) >= 0
				? ward
				: `${LOCALITY_CONSTANTS.ward} ${ward}`
			: ''

		// - ulice pripadne obec
		if ((municipality && address) || street) {
			localitySentence += street ? this.editStreetName(street) : municipality
			localitySentence += address ? ` ${address}` : ''
			localitySentence += `${separator} `
		}

		// - PSC a obec
		if (municipality) {
			// - PSC
			localitySentence += zip ? `${zip} ` : ''

			// - obec
			if (ward && ward.indexOf(municipality)) {
				localitySentence += `${municipality}, ${wardName}`
			} else {
				localitySentence += `${municipality}`
			}

			// - cast obce
		} else if (localitySentence && ward) {
			localitySentence += wardName
		}

		// - ctvrt
		if (localitySentence && !ward && quarter && quarter.indexOf(municipality) < 0) {
			localitySentence += `, ${quarter}`
		}

		// - okres
		if (district && (!municipality || district.indexOf(municipality) < 0)) {
			localitySentence += localitySentence ? `${separator} ${districtName}` : `${districtName}`
		}

		return localitySentence
	}

	static editStreetName(street) {
		const streetName = street ? street.split(' ') : []

		if (streetName[0] === LOCALITY_CONSTANTS.street) {
			return street.substring(LOCALITY_CONSTANTS.street.length + 1)
		} else {
			return street
		}
	}
}
