
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Price'] = {
  showAdvancedPriceOptions: function(d) { return "Pokročilé předvolby ceny a leasingu"; },
  hideAdvancedPriceOptions: function(d) { return "Skrýt předvolby ceny a leasingu"; },
  priceVatDeductible: function(d) { return "Možnost odpočtu DPH"; },
  priceVat: function(d) { return "Cena bez DPH"; },
  sellViaLeasing: function(d) { return "Chci prodat auto na leasing"; },
  priceLeasingLabel: function(d) { return "Prodejní cena při využití leasingu"; },
  priceLeasingPlaceholder: function(d) { return "Cena při využití leasingu"; },
  priceLeasingDescription: function(d) { return "Vyplňte celkovou cenu, kterou by zájemce zaplatil při využití leasingu"; },
  cancelLeasing: function(d) { return "Chci odstoupit od leasingu"; },
  pricePaymentLabel: function(d) { return "Výše splátky"; },
  pricePaymentPlaceholder: function(d) { return "Výše jednotlivé splátky"; },
  pricePaymentDescription: function(d) { return "Jaká je výše jedné splátky, popř. kolik máte smluvně určeno"; },
  pricePaymentCountLabel: function(d) { return "Počet splátek"; },
  pricePaymentCountPlaceholder: function(d) { return "Množství zbývajících splátek"; },
  pricePaymentCountDescription: function(d) { return "Na kolik splátek je rozložená zbývající část ceny vozidla"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	