import React from 'react'
import { Select } from '@inzeraty/components'
import AbstractRadioButtonForm from '../AbstractRadioButtonForm'

import './AgeForm.less'
import './AgeFormCS.json'

const CLASSNAME = 'cf-age-form'

export default class AgeForm extends AbstractRadioButtonForm {
	static get propTypes() {
		return AbstractRadioButtonForm.propTypes
	}

	static get defaultProps() {
		return AbstractRadioButtonForm.defaultProps
	}

	constructor(props, context) {
		super(props, context)

		this._updateValue = this._updateValue.bind(this)
		this._onSelectFromChange = this._onSelectFromChange.bind(this)
		this._onSelectToChange = this._onSelectToChange.bind(this)
	}

	render() {
		const { className, formLineEntity } = this.props
		const { value: entityValue } = formLineEntity
		const { value: actualValue } = entityValue

		const lastValue = this._getLastOptionValue(formLineEntity)
		const isLastValue = actualValue === lastValue

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{this._renderShortCuts()}

				<div
					className={`${CLASSNAME}__range`}
					ref={(elm) => (this._rangeInputsRef = elm)}
					data-dot='choose-range'
				>
					{this._renderSelect(true, isLastValue)}
					<span className={`${CLASSNAME}__range-separator`}>&ndash;</span>
					{this._renderSelect(false, isLastValue)}
				</div>
			</div>
		)
	}

	_renderSelect(isFrom, isLastValue) {
		const YEAR_FROM = this._getMinYear()
		const YEAR_TO = this._getMaxYear()

		const label = isFrom ? this.localize('AgeForm.from') : this.localize('AgeForm.to')

		const value = isFrom ? this._getValueFrom() : this._getValueTo()

		const onChange = isFrom ? this._onSelectFromChange : this._onSelectToChange

		const filterYears = isFrom
			? (year) => year <= this._getValueTo()
			: (year) => year >= this._getValueFrom()

		const years = []
		for (let i = YEAR_TO; i >= YEAR_FROM; i--) {
			years.push(i)
		}

		return (
			<label className={`${CLASSNAME}__range-item`}>
				<span className={`${CLASSNAME}__range-label`}>{label}</span>
				<Select
					tabIndex={isLastValue ? 0 : -1}
					className={`${CLASSNAME}__select`}
					value={String(value)}
					onChange={onChange}
					onFocus={this._updateValue}
					onClick={this._updateValue}
				>
					{years.filter(this._groupYears).filter(filterYears).map(this._renderOption)}
				</Select>
			</label>
		)
	}

	_groupYears(year) {
		const breakpoint_1 = 1990
		const breakpoint_2 = 1980

		if (year < breakpoint_1 && year > breakpoint_2) {
			if (year % 5 === 0) {
				return year
			}
		} else if (year < breakpoint_2) {
			if (year % 10 === 0) {
				return year
			}
		} else {
			return year
		}
	}

	_renderOption(year) {
		return (
			<option key={year} value={String(year)}>
				{year}
			</option>
		)
	}

	_onChange(event) {
		event.stopPropagation()

		const value = Number(event.target.value)
		const { options, value: valueData } = this.props.formLineEntity

		const newValue = {
			value
		}

		if (value !== this._getLastOptionValue()) {
			const { valueFrom, valueTo } = options.find((option) => option.value === value)

			newValue.valueFrom = valueFrom || this._getMinYear()
			newValue.valueTo = valueTo || this._getMaxYear()
		} else {
			if (!valueData.valueFrom) {
				newValue.valueFrom = this._getMinYear()
			}
			if (!valueData.valueTo) {
				newValue.valueTo = this._getMaxYear()
			}

			this._rangeInputsRef.querySelector('select').focus()
		}

		this._changeValue(newValue)
	}

	_updateValue(event) {
		this._changeValue({
			value: this._getLastOptionValue(),
			valueFrom: this._getValueFrom(),
			valueTo: this._getValueTo()
		})
	}

	_getMaxYear() {
		return this._getLastOption().valueTo
	}

	_getMinYear() {
		return this._getLastOption().valueFrom
	}

	_onSelectFromChange(event) {
		this._changeValue({
			valueFrom: Number(event.target.value)
		})
	}

	_onSelectToChange(event) {
		this._changeValue({
			valueTo: Number(event.target.value)
		})
	}

	_getValueFrom() {
		const { valueFrom } = this.props.formLineEntity.value
		return valueFrom || this._getMinYear()
	}

	_getValueTo() {
		const { valueTo } = this.props.formLineEntity.value
		return valueTo || this._getMaxYear()
	}
}
