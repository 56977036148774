import React from 'react'
import { Format } from '@inzeraty/helpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { PaginationEntity } from '@inzeraty/models'
import RegistrationEntity from 'app/model/registration/RegistrationEntity'
import { useFire } from 'app/base/componentHelpers'
import ExportButton from 'app/page/internalAdmin/components/exportButton/ExportButton'
import IARegistrationsListController from './IARegistrationsListController'

const IARegistrationsListView = ({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading
}) => {
	const fire = useFire()

	return (
		<Layout title='Výpis registrací'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<ExportButton
				onClick={() => {
					fire('exportRegistration')
				}}
				buttonText='Exportovat registrace'
			/>

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='id' sortParam='id' isDefaultDescending={true} alignRight={true}>
						ID registrace
					</TableHeadSorter>,
					<TableHeadSorter
						key='premise_external_id'
						sortParam='premise_external_id'
						alignRight={true}
					>
						Sauto ID
					</TableHeadSorter>,
					<TableHeadSorter key='premise_id' sortParam='premise_id' alignRight={true}>
						ASK ID
					</TableHeadSorter>,
					<TableHead key='premise_name'>Název firmy</TableHead>,
					<TableHead key='product'>Produkt</TableHead>,
					<TableHead key='state'>Stav</TableHead>,
					<TableHeadSorter key='date_from' sortParam='date_from'>
						Datum od
					</TableHeadSorter>,
					<TableHeadSorter key='date_to' sortParam='date_to'>
						Datum do
					</TableHeadSorter>
				]}
				rowCells={[
					({ id }) => [id, { isNumber: true }],
					({ premiseExternalId }) => [premiseExternalId, { isNumber: true }],
					({ premiseId }) => [premiseId, { isNumber: true }],
					({ premiseName }) => premiseName,
					({ registrationModule }) =>
						registrationModule.id !== 100
							? `Registrace: ${registrationModule.modules_from} - ${registrationModule.modules_to} modulů`
							: 'Registrace: Extra',
					({ status }) =>
						Object.values(IARegistrationsListController.REGISTRATION_STATUS).find(
							(option) => option.id === status
						)?.name ?? status,
					({ dateFrom }) => Format.date(dateFrom),
					({ dateTo }) => Format.date(dateTo)
				]}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IARegistrationsListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(RegistrationEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool
}

export default IARegistrationsListView
