import React from 'react'
import PropTypes from 'prop-types'

import './FormStatusMessageWrap.less'

const CLASSNAME = 'c-form-status-message-wrap'

const FormStatusMessageWrap = ({ children }) => {
	return (
		<div className={CLASSNAME} aria-live='assertive'>
			{children}
		</div>
	)
}

FormStatusMessageWrap.propTypes = {
	children: PropTypes.node
}

export default FormStatusMessageWrap
