/**
 * Defaultní kategorie
 * @const
 */
const DEFAULT_SEO_NAME_VALUE = 'osobni'

export default class CategoryPickerConstants {
	static get defaultValue() {
		return DEFAULT_SEO_NAME_VALUE
	}
}
