import AbstractExtension from 'ima/extension/AbstractExtension'
import HomeUrlConvertor from 'app/page/userweb/home/HomeUrlConvertor'

export default class BackToHpExtension extends AbstractExtension {
	static get STATE_KEYS() {
		return {
			CATEGORY_SEO_NAME: 'categorySeoName'
		}
	}

	constructor(historyStorage) {
		super()

		this._historyStorage = historyStorage
	}

	load() {
		return {
			[BackToHpExtension.STATE_KEYS.CATEGORY_SEO_NAME]: this._getCategorySeoName()
		}
	}

	/**
	 * Vrati data stranky posledni navstivene routy
	 *
	 * @method _getPreviousRouteData
	 * @private
	 * @returns {Object}
	 */
	_getPreviousRouteData() {
		if (this._historyStorage.getPreviousRouteName()) {
			const currentRouteName = this._historyStorage.getActualRouteName()
			let prevIndex = this._historyStorage.getActualIndex() - 1

			while (this._historyStorage.getIndexRouteName(prevIndex) === currentRouteName) {
				prevIndex--
			}

			return {
				prevRouteParams: this._historyStorage.getIndexRouteParams(prevIndex)
			}
		} else {
			return {}
		}
	}

	_getCategorySeoName() {
		const { prevRouteParams = {} } = this._getPreviousRouteData()

		const {
			[HomeUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_SEO_NAME]: categeorySeoName = ''
		} = prevRouteParams

		return categeorySeoName
	}

	getAllowedStateKeys() {
		return [BackToHpExtension.STATE_KEYS.CATEGORY_SEO_NAME]
	}
}
