
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['RegionDistrictSearch'] = {
  chooseRegionDistrict: function(d) { return "Vyberte místo prodeje"; },
  searchRegionDistrict: function(d) { return "Hledat kraj, okres"; },
  wholeCountry: function(d) { return "Celá ČR"; },
  labelFound: function(d) { return "Našli jsme"; },
  regions: function(d) { return "Kraje"; },
  districts: function(d) { return "Okresy"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	