import ns from 'ima/namespace'

ns.namespace('app.helpers.deepFreeze')

/**
 * Freeze objektu do hloubky (přebráno z https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze)
 *
 * @param {Object|Array} obj Objekt, který chceme 'zmrazit'
 * @returns {Object}
 */
export default function deepFreeze(obj) {
	// Retrieve the property names defined on obj
	const propNames = Object.getOwnPropertyNames(obj)

	// Freeze properties before freezing self
	propNames.forEach((name) => {
		const prop = obj[name]

		// Freeze prop if it is an object
		if (typeof prop === 'object' && prop !== null) deepFreeze(prop)
	})

	// Freeze self (no-op if already frozen)
	return Object.freeze(obj)
}

ns.app.helpers.deepFreeze.deepFreeze = deepFreeze
