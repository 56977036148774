const RESOURCE = '/footer'

/**
 * @class SeoResource
 * @namespace app.model.seo
 * @module app
 * @submodule app.model
 */
export default class SeoResource {
	/**
	 * @method constructor
	 * @constructor
	 * @param {app.base.RestClient}  restClient
	 */
	constructor(restClient) {
		this._restClient = restClient
	}

	/**
	 *  Vrati JSON pro seo paticku do interniho admin
	 *
	 * @method getSeoFooterLinks
	 * @param  {Object} params
	 * @param  {Object} options
	 * @return {Promise<JSON>} Struktura SEO linku pro paticku
	 */
	getSeoFooterLinks(params = {}, options = {}) {
		return this._restClient.get(RESOURCE, params, options).then((response) => {
			return response.body.result
		})
	}

	/**
	 *  Vrati JSON pro seo paticku
	 *
	 * @method getCachedSeoFooterLinks
	 * @param  {Object} params
	 * @param  {Object} options
	 * @return {Promise<JSON>} Struktura SEO linku pro paticku
	 */
	getCachedSeoFooterLinks(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/cached`, params, options).then((response) => {
			return response.body.result
		})
	}

	/**
	 * Editace JSONu pro seo paticku v internim adminu
	 *
	 * @method putSeoFooterLinks
	 * @param {object} [params={}] Data o roli
	 * @return {Promise<JSONResource>}
	 */
	putSeoFooterLinks(params = {}, options = {}) {
		return this._restClient.update(RESOURCE, params, options)
	}
}
