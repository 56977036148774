import * as FormLines from '@inzeraty/form-lines'
import { CATEGORIES } from 'app/base/Constants'
import FilterConstants from 'app/model/filter/FilterConstants'
import FilterToUrlAppConvertor from 'app/model/filter/filterConvertor/FilterToUrlAppConvertor'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import radioFilters from './radioFiltersList'

/**
 * @class MySavedSearchToUrlAppParamsConvertor
 * Prevadi strukturu ulozeneho hledani na URL parametry pro vypis inzeratu.
 */
export default class MySavedSearchToUrlAppParamsConvertor {
	static convert(mySavedSearch = {}) {
		const { filter = {} } = mySavedSearch

		// TODO zbyva doplnit parametry id, hash a timestamp, ktere se pouziji pro
		// presmerovani na URL s nove pridanymi inzeraty u ulozeneho hledani
		return Object.assign(
			{},
			MySavedSearchToUrlAppParamsConvertor.convertCategoryParam(filter),
			MySavedSearchToUrlAppParamsConvertor.convertMultiOptionFilterParams(filter),
			MySavedSearchToUrlAppParamsConvertor.convertRadioFilterParams(filter),
			MySavedSearchToUrlAppParamsConvertor.convertItemAgeFilterParam(filter),
			MySavedSearchToUrlAppParamsConvertor.convertBrandModelsFilterParams(filter),
			MySavedSearchToUrlAppParamsConvertor.convertRegionDistrictsFilterParams(filter),
			MySavedSearchToUrlAppParamsConvertor.convertPhraseFilterParam(filter),
			MySavedSearchToUrlAppParamsConvertor.convertMySavedSearchParams(mySavedSearch)
		)
	}

	static convertCategoryParam(mySavedSearchFilter = {}) {
		const { URL_APP_PARAMS, URL_API_PARAMS } = AdvertListUrlConvertor.constants
		const categoryId = mySavedSearchFilter[URL_API_PARAMS.CATEGORY_ID]
		const { seoName } = Object.values(CATEGORIES).find(({ id }) => id === categoryId) || {}

		return seoName
			? {
					[URL_APP_PARAMS.CATEGORY_NAME]: seoName
			  }
			: {}
	}

	static convertMultiOptionFilterParams(mySavedSearchFilter = {}) {
		const fakeFormLines = Object.keys(mySavedSearchFilter)
			.filter((formLineId) => FilterConstants.multiOptionFilters.includes(formLineId))
			.map((formLineId) => {
				const apiValue = mySavedSearchFilter[formLineId] || []

				return {
					id: formLineId,
					value: apiValue.reduce((result, { value }) => {
						result.add(value)
						return result
					}, new Set()),
					options: apiValue.map(({ value, seo_name: seoName, name }) => ({
						value,
						seoName,
						name
					}))
				}
			})

		return FilterToUrlAppConvertor.getMultiChoiceUrlParams(
			FormLines.getFormData(fakeFormLines),
			fakeFormLines
		)
	}

	static convertRadioFilterParams(mySavedSearchFilter = {}) {
		const fakeFormData = radioFilters.reduce(
			(formData, { formLineId, apiParamFromName, apiParamToName }) => {
				const valueFrom = mySavedSearchFilter[apiParamFromName]
				const valueTo = mySavedSearchFilter[apiParamToName]

				if (!isNullOrUndefined(valueFrom) || !isNullOrUndefined(valueTo)) {
					return Object.assign({}, formData, {
						[formLineId]: Object.assign(
							{},
							!isNullOrUndefined(valueFrom)
								? {
										valueFrom
								  }
								: undefined,
							!isNullOrUndefined(valueTo)
								? {
										valueTo
								  }
								: undefined
						)
					})
				} else {
					return formData
				}
			},
			{}
		)

		return Object.assign(
			{},
			FilterToUrlAppConvertor.getFromToUrlParams(fakeFormData),
			FilterToUrlAppConvertor.getPerformanceParams(fakeFormData)
		)
	}

	static convertItemAgeFilterParam(mySavedSearchFilter = {}) {
		const apiValue = mySavedSearchFilter[FilterConstants.formLineIds.ITEM_AGE]

		if (apiValue) {
			const fakeFormLines = [
				{
					id: FilterConstants.formLineIds.ITEM_AGE,
					value: { value: apiValue.value },
					options: [
						{
							value: apiValue.value,
							name: apiValue.name,
							seoName: apiValue.seo_name
						}
					]
				}
			]

			return Object.assign(
				{},
				FilterToUrlAppConvertor.getItemAgeUrlParams(
					FormLines.getFormData(fakeFormLines),
					fakeFormLines
				)
			)
		}

		return {}
	}

	static convertBrandModelsFilterParams(mySavedSearchFilter = {}) {
		const { URL_API_PARAMS } = AdvertListUrlConvertor.constants
		const apiValue = mySavedSearchFilter[URL_API_PARAMS.BRANDS_MODELS_VALUES] || []

		const fakeFormLines = [
			{
				id: FilterConstants.formLineIds.BRAND_MODEL,
				value: apiValue.reduce((result, { value: brandValue, models = [] }) => {
					result.set(brandValue, new Set(models.map(({ value }) => value)))
					return result
				}, new Map()),
				options: apiValue.map((brand) => {
					const { value: brandValue, seo_name: brandSeoName, name: brandName, models = [] } = brand

					return {
						value: brandValue,
						seoName: brandSeoName,
						name: brandName,
						children: models.map(
							({ value: modelValue, seo_name: modelSeoName, name: modelName }) => ({
								value: modelValue,
								seoName: modelSeoName,
								name: modelName
							})
						)
					}
				})
			}
		]

		return FilterToUrlAppConvertor.getBrandModelUrlParams(
			FormLines.getFormData(fakeFormLines),
			fakeFormLines
		)
	}

	static convertRegionDistrictsFilterParams(mySavedSearchFilter = {}) {
		const { URL_API_PARAMS } = AdvertListUrlConvertor.constants
		const apiValue = mySavedSearchFilter[URL_API_PARAMS.REGIONS_DISTRICTS_VALUES] || []

		const fakeFormLines = [
			{
				id: FilterConstants.formLineIds.REGION_DISTRICT,
				value: apiValue.reduce((result, { value: regionValue, districts = [] }) => {
					result.set(regionValue, new Set(districts.map(({ value }) => value)))
					return result
				}, new Map()),
				options: apiValue.map((region) => {
					const {
						value: regionValue,
						seo_name: regionSeoName,
						name: regionName,
						districts = []
					} = region

					return {
						value: regionValue,
						seoName: regionSeoName,
						name: regionName,
						children: districts.map(
							({ value: districtValue, seo_name: districtSeoName, name: districtName }) => ({
								value: districtValue,
								seoName: districtSeoName,
								name: districtName
							})
						)
					}
				})
			}
		]

		return FilterToUrlAppConvertor.getRegionDistrictUrlParams(
			FormLines.getFormData(fakeFormLines),
			fakeFormLines
		)
	}

	static convertPhraseFilterParam(mySavedSearchFilter = {}) {
		const { URL_API_PARAMS } = AdvertListUrlConvertor.constants

		const fakeFormLines = [
			{
				id: FilterConstants.formLineIds.PHRASE,
				value: mySavedSearchFilter[URL_API_PARAMS.PHRASE]
			}
		]

		return FilterToUrlAppConvertor.getPhraseUrlParams(FormLines.getFormData(fakeFormLines))
	}

	static convertMySavedSearchParams({ newCount, id, watchdogHash: hash, timestamp }) {
		const mySavedSearchParams = {}

		if (newCount && id && hash && timestamp) {
			const { URL_APP_PARAMS } = AdvertListUrlConvertor.constants

			Object.assign(mySavedSearchParams, {
				[URL_APP_PARAMS.MY_SAVED_SEARCH_ID]: id,
				[URL_APP_PARAMS.MY_SAVED_SEARCH_HASH]: hash,
				[URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP]: timestamp
			})
		}

		return mySavedSearchParams
	}
}
