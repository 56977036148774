import React, { useContext } from 'react'
import ImaContext from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import Layout from 'app/component/layout/LayoutView'
import ModelLinesOverviewController from './ModelLinesOverviewController'
import ModelsOverviewList from './components/modelsOverviewList/ModelsOverviewList'
import PathSeparator, { getHomePath } from '../components/pathSeparator/PathSeparator'
import ROUTE_NAMES from 'app/base/RouteNames'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'

import './ModelLinesOverviewView.less'

const CLASSNAME = 'p-uw-catalog-models-overview'

const ModelLinesOverviewView = (props) => {
	const { [ModelLinesOverviewController.STATE_KEYS.MODELS_LINES_LIST]: modelLinesList } = props

	const context = useContext(ImaContext)
	const router = context.$Router
	const localize = useLocalize()

	const dataLoaded = !!(modelLinesList && modelLinesList.length)

	const getPaths = () => {
		if (dataLoaded) {
			const { brandName, modelName, brandSeoName } = getModelName()

			return [
				getHomePath(localize, router),
				{
					name: brandName,
					url: router.link(ROUTE_NAMES.USERWEB.CATALOG_MODELS, {
						[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: brandSeoName
					})
				},
				{
					name: modelName
				}
			]
		} else {
			return []
		}
	}

	const getModelName = () => {
		const { model = {} } = modelLinesList ? modelLinesList[0] || {} : {}
		const { name: modelName = '', manufacturer = {} } = model
		const { name: brandName, seoName: brandSeoName } = manufacturer

		return {
			brandName,
			modelName,
			brandSeoName
		}
	}

	const renderTitle = () => {
		if (dataLoaded) {
			const { brandName, modelName } = getModelName()

			return (
				<h1 className={`${CLASSNAME}__header`}>
					{brandName} {modelName}
				</h1>
			)
		} else {
			return <div className={`${CLASSNAME}__header-placeholder`}></div>
		}
	}

	const paths = getPaths()

	return (
		<Layout className={CLASSNAME}>
			<div className={`${CLASSNAME}__content`} data-dot='catalog'>
				<div className={`${CLASSNAME}__header-content`}>
					<PathSeparator paths={paths} dataLoaded={dataLoaded} />
					{renderTitle()}
				</div>
				<ModelsOverviewList modelLinesList={modelLinesList} />
			</div>
		</Layout>
	)
}

export default ModelLinesOverviewView
