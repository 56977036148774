import React, { memo, useContext } from 'react'
import { Button, Spinner } from '@sznds/react'
import { getFormData } from '@inzeraty/form-lines'
import Context from 'ima/page/context'
import { useFire } from 'app/base/componentHelpers'
import InternalAdminWidgets, {
	VARIANT,
	CLASSNAME as IA_WIDGET_CLASSNAME
} from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './EntitiesFilter.less'

const CLASSNAME = 'c-efilter'

export const nonValue = 'nonValue'
export const nonValueOption = {
	value: nonValue,
	name: 'Nevybráno'
}
export const filterBy = 'filterBy'
export const filterByValue = 'filterByValue'

const EntitiesFilter = memo(
	({ formLineEntities = [], isFilterLoading = false, getFormDataOnSubmit: customGetFormData }) => {
		const { $Router } = useContext(Context)
		const fire = useFire()

		const onChange = (data) => {
			fire('entitiesFilterChange', data)
		}

		const onSubmit = (event) => {
			event.preventDefault()
			const formData = customGetFormData
				? customGetFormData(formLineEntities)
				: getFormData(formLineEntities)
			const { params = {}, route } = $Router.getCurrentRouteInfo()

			const paramsAndData = Object.assign({ limit: params.limit }, formData)

			// Pokud má filter filterByValue prázdnou hodnotu, tak smaže i hodnotu filerBy
			const filterByVal = paramsAndData[filterByValue]
			if (
				typeof filterByVal === 'undefined' ||
				(typeof filterByVal === 'string' && filterByVal.trim() === '')
			) {
				delete paramsAndData[filterBy]
				delete paramsAndData[filterByValue]
			}

			// Promaže formData, která nejsou zadána
			Object.keys(paramsAndData).forEach((key) => {
				if (!paramsAndData[key] || paramsAndData[key] === nonValue) {
					delete paramsAndData[key]
				}
			})

			const url = $Router.link(route.getName(), paramsAndData)
			$Router.redirect(url)
		}

		return (
			<form className={CLASSNAME} onSubmit={onSubmit}>
				{isFilterLoading && (
					<div className={`${CLASSNAME}__loader`}>
						<Spinner className={`${CLASSNAME}__spinner`} />
					</div>
				)}

				<div className={`${CLASSNAME}__lines`}>
					<InternalAdminWidgets
						onChange={onChange}
						formLineEntities={formLineEntities}
						variant={VARIANT.FILTER}
					/>

					<div
						className={`${IA_WIDGET_CLASSNAME} ${IA_WIDGET_CLASSNAME}--filter ${CLASSNAME}__submit`}
					>
						<Button text='Vyhledat' type='submit' primary={true} />
					</div>
				</div>

				<div className={`${CLASSNAME}__separator`} />
			</form>
		)
	}
)

EntitiesFilter.propTypes = {
	isFilterLoading: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	getFormDataOnSubmit: PropTypes.func
}

export default EntitiesFilter
