const RESOURCE = '/operating_leases'

export default class OperatingLeaseResource {
	constructor(restClient) {
		this._restClient = restClient
	}

	getOperatingLeaseVariants(params = {}, options = {}) {
		return this._restClient.get(RESOURCE, params, options)
	}

	addOperatingLeaseVariant(params = {}, options = {}) {
		return this._restClient.post(RESOURCE, params, options)
	}

	editOperatingLeaseVariant(params = {}, options = {}) {
		return this._restClient.update(`${RESOURCE}/${params.id}`, params, options)
	}

	deleteOperatingLeaseVariant(operatingLeaseVariantId) {
		return this._restClient.delete(`${RESOURCE}/${operatingLeaseVariantId}`)
	}
}
