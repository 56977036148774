export default Object.assign({
	HTTP_STATUS: 'httpStatus',
	ADVERT_ENTITY: 'advertEntity',
	CACHED_ADVERT_ENTITY: 'cachedAdvertEntity',
	PREV_ROUTE_NAME: 'prevRouteName',
	PREV_ROUTE_PARAMS: 'prevRouteParams',
	PREV_NEXT_ADVERT: 'prevNextAdvert',
	GO_BACK_DATA: 'goBackData',
	PREMISE_ADVERTS_COUNT: 'premiseAdvertsCount',
	IS_PREMISE_ADVERTS_COUNT_LOADED: 'isPremiseAdvertsCountLoaded',
	IS_ADVERT_ENTITY_LOADED: 'isAdvertEntityLoaded',
	IS_PREVIEW_MODE: 'isPreviewMode',
	IS_INACTIVE_MODE: 'isInactiveMode',
	RELATED_ADVERT_ENTITIES: 'relatedAdvertEntities',
	CAR_COMPARE: 'carCompare',
	ARTICLES: 'articles',
	IS_OPERATING_LEASE_PAGE: 'isOperatingLeasePage',
	SELECTED_OPERATING_LEASE_VARIANT: 'selectedOperatingLeaseVariant',
	SET_SELECTED_OPERATING_LEASE_VARIANT: 'setSelectedOperatingLeaseVariant',
	MTLP_PRICES: 'mtplPrices'
})
