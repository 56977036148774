
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Empty'] = {
  title: function(d) { return "K této generaci nemáme další informace"; },
  subTitle: function(d) { return "Může se jednat o koncepty nebo okrajové modelové řady"; },
  buttonName: function(d) { return "Zpět na " + d.NAME; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	