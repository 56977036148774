import { CodebooksService as CodebooksServiceBase } from '@inzeraty/models'

export default class CodebooksService extends CodebooksServiceBase {
	/**
	 * @method constructor
	 * @constructor
	 * @param {models.advert.CodebooksResource} codebooksResource
	 * @param {models.advert.CodebooksFactory} codebooksFactory
	 * @param {models.advert.CodebooksValuesFactory} codebooksValuesFactory
	 */
	constructor(codebooksResource, codebooksFactory, codebooksValuesFactory, paginationFactory) {
		super(codebooksResource, codebooksFactory)

		this._codebooksResource = codebooksResource
		this._codebooksFactory = codebooksFactory
		this._codebooksValuesFactory = codebooksValuesFactory
		this._paginationFactory = paginationFactory
	}

	/**
	 * Získá číselníky.
	 *
	 * @method getCodebooksValues
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 */
	getCodebooksValues(params, options) {
		return this._codebooksResource.getCodebooksValues(params, options).then((response) => {
			const { results, pagination } = response.body
			const codebooksData = results.map((codebook) =>
				this._codebooksValuesFactory.transformValueData(codebook)
			)

			return {
				entities: this._codebooksValuesFactory.createValueEntityList(codebooksData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	/**
	 * Uloží nový číselník.
	 *
	 * @method postCodebooksValues
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 */
	postCodebooksValues(params, options) {
		return this._codebooksResource.postCodebooksValues(params, options)
	}

	/**
	 * Editace o číselníku.
	 *
	 * @method putCodebooksValues
	 * @public
	 * @param {Number} codebookId
	 * @param {Object} params
	 * @param {Object} options
	 */
	putCodebooksValues(codebookId, params, options) {
		return this._codebooksResource.putCodebooksValues(codebookId, params, options)
	}

	/**
	 * Změna části existujícího číselníku.
	 *
	 * @method patchCodebooksValues
	 * @public
	 * @param {Number} codebookId
	 * @param {Object} params
	 * @param {Object} options
	 */
	patchCodebooksValues(codebookId, params, options) {
		return this._codebooksResource.patchCodebooksValues(codebookId, params, options)
	}

	/**
	 * Smazání číselníku.
	 *
	 * @method deleteCodebooksValues
	 * @public
	 * @param {Number} codebookId
	 * @param {Object} urlParams
	 * @param {Object} params
	 * @param {Object} options
	 */
	deleteCodebooksValues(codebookId, urlParams, params, options) {
		return this._codebooksResource.deleteCodebooksValues(codebookId, urlParams, params, options)
	}
}
