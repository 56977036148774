import CodebooksValuesEntity from './CodebooksValuesEntity'

export default class CodebooksValuesFactory {
	createValueEntity(data = {}) {
		return new CodebooksValuesEntity(data)
	}

	createValueEntityList(data = []) {
		return data.map((itemData) => this.createValueEntity(itemData))
	}

	transformValueData(data = {}) {
		return {
			id: data.id,
			name: data.name,
			seoName: data.seo_name,
			forFilters: data.for_filters,
			category: data.category,
			value: data.value,
			isFavorite: data.is_favorite,
			codebook: this.transformCodebookData(data.codebook),
			parent: this.transformParentData(data.parent || {})
		}
	}

	transformCodebookCategoryData(data = {}) {
		return {
			id: data.id,
			name: data.name
		}
	}

	transformCodebookData(data = {}) {
		return {
			category: this.transformCodebookCategoryData(data.category),
			id: data.id,
			name: data.name
		}
	}

	transformParentData(data = {}) {
		return {
			id: data.id,
			name: data.name,
			value: data.value
		}
	}
}
