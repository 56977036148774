import FilterConstants from 'app/model/filter/FilterConstants'

export const calcActiveFiltersCounter = (formlineEntity) => {
	const { id, value } = formlineEntity

	if (id === FilterConstants.formLineIds.BRAND_MODEL) {
		const map = value || new Map()

		return map.size
	} else if (id === FilterConstants.formLineIds.REGION_DISTRICT) {
		const map = value || new Map()

		return Array.from(map).reduce((total, [region, districtsValues = new Set()]) => {
			if (districtsValues.size) {
				// zapocitame jen okresy
				return total + districtsValues.size
			} else {
				// zapocitame jen kraj
				return total + 1
			}
		}, 0)
	} else if (
		id === FilterConstants.formLineIds.AGE ||
		id === FilterConstants.formLineIds.PRICE ||
		id === FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT ||
		id === FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE ||
		id === FilterConstants.formLineIds.OPERATING_LEASE_PERIOD ||
		id === FilterConstants.formLineIds.TACHOMETER ||
		id === FilterConstants.formLineIds.PERFORMANCE ||
		id === FilterConstants.formLineIds.DISPLACEMENT ||
		id === FilterConstants.formLineIds.FUEL_CONSUMPTION ||
		id === FilterConstants.formLineIds.AIRBAGS ||
		id === FilterConstants.formLineIds.CAPACITY ||
		id === FilterConstants.formLineIds.DOORS ||
		id === FilterConstants.formLineIds.WEIGHT ||
		id === FilterConstants.formLineIds.BEDS ||
		id === FilterConstants.formLineIds.MACHINE_HOURS
	) {
		const { valueFrom, valueTo } = value || {}

		return valueFrom > 0 || valueTo > 0 ? 1 : 0
	} else if (
		id === FilterConstants.formLineIds.CONDITION ||
		id === FilterConstants.formLineIds.OPERATING_LEASE_SERVICES ||
		id === FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR ||
		id === FilterConstants.formLineIds.FUEL ||
		id === FilterConstants.formLineIds.VEHICLE_BODY ||
		id === FilterConstants.formLineIds.GEARBOX ||
		id === FilterConstants.formLineIds.COUNTRY_OF_ORIGIN ||
		id === FilterConstants.formLineIds.VENDOR ||
		id === FilterConstants.formLineIds.AIR_CONDITIONING ||
		id === FilterConstants.formLineIds.CERTIFIED_PROGRAM ||
		id === FilterConstants.formLineIds.COLOR ||
		id === FilterConstants.formLineIds.BUS_SEATS ||
		id === FilterConstants.formLineIds.PRICE_OPTION ||
		id === FilterConstants.formLineIds.HISTORY_INFO ||
		id === FilterConstants.formLineIds.EQUIPMENT ||
		id === FilterConstants.formLineIds.DRIVE ||
		id === FilterConstants.formLineIds.OTHER_SPECIFICATION
	) {
		const map = value || new Map()

		return map.size
	} else if (id === FilterConstants.formLineIds.ITEM_AGE) {
		const { value: selectedValue } = value || {}

		return selectedValue > 0 ? 1 : 0
	}
}
