import { FORM_IDS } from './AddVINForm'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './AddVINFormCS.json'

export const getErrorDictionary = (localize) => ({
	[FORM_IDS.VEHICLE_CATEGORY]: {
		[ERROR_CODES.REQUIRED]: localize('AddVINForm.vehicleCategoryRequired'),
		[ERROR_CODES.NOT_VALID]: localize('AddVINForm.vehicleCategoryNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('AddVINForm.vehicleCategoryNotValid')
	},
	[FORM_IDS.VEHICLE_CONDITION]: {
		[ERROR_CODES.REQUIRED]: localize('AddVINForm.vehicleConditionRequired'),
		[ERROR_CODES.NOT_VALID]: localize('AddVINForm.vehicleConditionNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('AddVINForm.vehicleConditionNotValid')
	},
	[FORM_IDS.VEHICLE_VIN]: {
		[ERROR_CODES.REQUIRED]: localize('AddVINForm.vinRequired'),
		[ERROR_CODES.NOT_VALID]: localize('AddVINForm.vinNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('AddVINForm.vinNotValid'),
		[ERROR_CODES.VIN_DUPLICATION]: localize('AddVINForm.vinDuplication')
	}
})
