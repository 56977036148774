import { createEntityList, updateEntities, getFormData } from '@inzeraty/form-lines'
import { HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import PremiseService from 'app/model/premise/PremiseService'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { nonValue } from 'app/component/entitiesFilter/EntitiesFilter'

const BUSINESSMAN_ROLE_ID = 3

const FORM_LINE_ASK_ID = 'id'
const FORM_LINE_USER_EMAIL = 'user_email'
const FORM_LINE_BUSINESSMAN_LOGIN = 'admin_login'

export default class IAPremiseListController extends AbstractInternalAdminController {
	static get ACTIONS() {
		return Object.freeze({
			ACTIVATE: 'activate',
			DEACTIVATE: 'deactivate',
			OPEN_NEW_PREMISE_POPUP: 'openNewPremisePopup',
			CLOSE_NEW_PREMISE_POPUP: 'closeNewPremisePopup'
		})
	}
	static get NEW_PREMISE_FORM_WIZARD() {
		return Object.freeze({
			STEP1: 'NEW_PREMISE_FORM_WIZARD_STEP_1',
			STEP2: 'NEW_PREMISE_FORM_WIZARD_STEP_2'
		})
	}

	constructor(dependenciesHelper, entitiesFilterExtension, softwareKeyService) {
		super(dependenciesHelper)

		this._router = this._utils.$Router

		this._entitiesFilterExtension = entitiesFilterExtension

		this._softwareKeyService = softwareKeyService

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)

		this._getFormLinesNewPremiseFormStep1 = this._getFormLinesNewPremiseFormStep1.bind(this)
		this._getFormLinesNewPremiseFormStep2 = this._getFormLinesNewPremiseFormStep2.bind(this)
		this._validateNewPremiseFormStep1 = this._validateNewPremiseFormStep1.bind(this)
		this._validateNewPremiseFormStep2 = this._validateNewPremiseFormStep2.bind(this)
		this._submitNewPremiseFormStep1 = this._submitNewPremiseFormStep1.bind(this)
		this._submitNewPremiseFormStep2 = this._submitNewPremiseFormStep2.bind(this)
		this._handleSuccessNewPremiseFormStep1 = this._handleSuccessNewPremiseFormStep1.bind(this)
		this._handleSuccessNewPremiseFormStep2 = this._handleSuccessNewPremiseFormStep2.bind(this)
		this._handleErrorNewPremiseFormStep1 = this._handleErrorNewPremiseFormStep1.bind(this)
		this._handleErrorNewPremiseFormStep2 = this._handleErrorNewPremiseFormStep2.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isNewPremisePopupOpened: false,
			validateNewPremiseStep2: this._validateNewPremiseFormStep2
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const fetchPremises = async (params) => {
			try {
				const { premiseEntities, paginationEntity } = await this._premiseService.getPremises(params)

				return {
					entities: premiseEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = 'external_id'
		}

		this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchPremises(params)

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	_handleAction({ action, entities = [], entitiesIds = [] }) {
		const [id] = entitiesIds
		const [entity] = entities
		const premiseName = PremiseService.getPremiseDataForPublic(entity).name
		let errorMessage
		let data = {}

		switch (action) {
			case IAPremiseListController.ACTIONS.OPEN_NEW_PREMISE_POPUP:
				this._newPremiseAskId = undefined
				this.setState({
					isNewPremisePopupOpened: true,
					newPremiseStepShown: IAPremiseListController.NEW_PREMISE_FORM_WIZARD.STEP1,
					formLineEntitiesNewPremiseStep1: this._getFormLinesNewPremiseFormStep1(),
					formLineEntitiesNewPremiseStep2: undefined,
					entityFormErrorNewPremiseStep1: undefined,
					entityFormErrorNewPremiseStep2: undefined
				})
				return {}

			case IAPremiseListController.ACTIONS.CLOSE_NEW_PREMISE_POPUP:
				this.setState({
					isNewPremisePopupOpened: false
				})
				return {}

			case IAPremiseListController.ACTIONS.ACTIVATE:
				data = { service_enabled: true }
				errorMessage = `Nepodařilo se aktivovat firmu ${premiseName}`
				break

			case IAPremiseListController.ACTIONS.DEACTIVATE:
				data = { service_enabled: false }
				errorMessage = `Nepodařilo se zakázat firmu ${premiseName}`
				break
		}

		return {
			promise: this._premiseService.patchPremise(id, data),
			getErrorMessage: () => errorMessage
		}
	}

	async _processSuccessAction(data) {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	async _filterInitiezerClb(routeParams) {
		const { softwareKeyEntities } = await this._softwareKeyService.getSoftwareKeys({ limit: 1000 })

		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'name',
					name: 'Název klienta'
				},
				{
					value: 'subject_ico',
					name: 'IČ'
				},
				{
					value: 'id',
					name: 'ASK ID'
				},
				{
					value: 'external_id',
					name: 'Sauto ID'
				},
				{
					value: 'admin_id',
					name: 'ID obchodníka'
				},
				{
					value: 'user_login',
					name: 'RUS účet'
				},
				{
					value: 'wallet_login',
					name: 'Peněženkový účet'
				}
			]),
			this._entitiesFilterExtension.getSelect(
				'software_key',
				'Softwarový klíč',
				'Vyberte softwarový klíč',
				softwareKeyEntities
					.sort((a, b) => a.name.localeCompare(b.name))
					.map(({ name }) => ({
						value: name,
						name
					}))
			),
			this._entitiesFilterExtension.getSelect('enabled', 'Stav', 'Vyberte stav', [
				{
					value: '1',
					name: 'Aktivní'
				},
				{
					value: '0',
					name: 'Zakázaný'
				}
			])
		])
	}

	_getFormLinesNewPremiseFormStep1() {
		return createEntityList([
			{
				id: FORM_LINE_ASK_ID,
				placeholder: 'Vyplňte ASK ID',
				value: '',
				widget: IDS.TEXT
			}
		])
	}

	async _getFormLinesNewPremiseFormStep2() {
		const [premiseEntity, { adminEntities }] = await Promise.all([
			this._premiseService.getPremise(this._newPremiseAskId),
			this._adminService.getAdmins({
				[InternalAdminUrlConvertor.constants.ROLE_ID]: BUSINESSMAN_ROLE_ID,
				limit: 1000
			})
		])

		return createEntityList([
			{
				id: 'premise_info',
				value: premiseEntity,
				widget: IDS.PREMISE_INFO
			},
			{
				id: FORM_LINE_USER_EMAIL,
				label: 'Email',
				placeholder: 'Vyplňte email',
				value: '',
				widget: IDS.TEXT,
				required: true
			},
			{
				id: FORM_LINE_BUSINESSMAN_LOGIN,
				label: 'Obchodník',
				placeholder: 'Vyberte obchodníka',
				widget: IDS.SELECT,
				required: true,
				options: adminEntities.map(({ login, name }) => ({
					value: login,
					name
				}))
			}
		])
	}

	_validateNewPremiseFormStep1() {
		const { formLineEntitiesNewPremiseStep1 = [] } = this.getState()

		const { value: askIdValue } =
			formLineEntitiesNewPremiseStep1.find(({ id }) => id === FORM_LINE_ASK_ID) || {}

		return !!askIdValue
	}

	_validateNewPremiseFormStep2() {
		const { formLineEntitiesNewPremiseStep2 = [] } = this.getState()

		const { value: emailValue } =
			formLineEntitiesNewPremiseStep2.find(({ id }) => id === FORM_LINE_USER_EMAIL) || {}
		const { value: adminValue } =
			formLineEntitiesNewPremiseStep2.find(({ id }) => id === FORM_LINE_BUSINESSMAN_LOGIN) || {}

		return !!emailValue && !!adminValue
	}

	onNewPremiseStep1Change(data) {
		const { formLineEntitiesNewPremiseStep1 } = this.getState()

		this.setState({
			formLineEntitiesNewPremiseStep1: updateEntities(formLineEntitiesNewPremiseStep1, [data])
		})
	}

	onNewPremiseStep2Change(data) {
		const { formLineEntitiesNewPremiseStep2 } = this.getState()

		this.setState({
			formLineEntitiesNewPremiseStep2: updateEntities(formLineEntitiesNewPremiseStep2, [data])
		})
	}

	_newPremiseFormSubmit({
		stateKeys: [formLineEntitiesStateKey, errorStateKey, isWaitingStateKey] = [],
		getSubmitPromise = () => Promise.resolve(),
		validate = () => true,
		handleSubmitSuccess = () => null,
		handleSubmitError = () => null
	}) {
		const { [formLineEntitiesStateKey]: formLineEntities } = this.getState()

		if (validate()) {
			this.setState({
				[isWaitingStateKey]: true
			})

			const formData = getFormData(formLineEntities)

			Object.keys(formData).forEach((key) => {
				if (formData[key] === nonValue) {
					formData[key] = null
				}
			})

			getSubmitPromise(formData)
				.then((...args) => {
					this.setState({
						[isWaitingStateKey]: false,
						[errorStateKey]: undefined
					})

					handleSubmitSuccess(...args)
				})
				.catch((error) => {
					const entitiesAndFormError = handleSubmitError(error, formLineEntities, formData)

					this.setState(
						Object.assign(
							{
								[isWaitingStateKey]: false
							},
							entitiesAndFormError
						)
					)
				})
		}
	}

	onNewPremiseStep1Submit() {
		const { formLineEntitiesNewPremiseStep1 = [] } = this.getState()

		const { value: askIdValue } =
			formLineEntitiesNewPremiseStep1.find(({ id }) => id === FORM_LINE_ASK_ID) || {}

		this._newPremiseAskId = askIdValue

		this._newPremiseFormSubmit({
			stateKeys: [
				'formLineEntitiesNewPremiseStep1',
				'entityFormErrorNewPremiseStep1',
				'isWaitingNewPremiseStep1'
			],
			getSubmitPromise: this._submitNewPremiseFormStep1,
			validate: this._validateNewPremiseFormStep1,
			handleSubmitSuccess: this._handleSuccessNewPremiseFormStep1,
			handleSubmitError: this._handleErrorNewPremiseFormStep1
		})
	}

	onNewPremiseStep2Submit() {
		this._newPremiseFormSubmit({
			stateKeys: [
				'formLineEntitiesNewPremiseStep2',
				'entityFormErrorNewPremiseStep2',
				'isWaitingNewPremiseStep2'
			],
			getSubmitPromise: this._submitNewPremiseFormStep2,
			validate: this._validateNewPremiseFormStep2,
			handleSubmitSuccess: this._handleSuccessNewPremiseFormStep2,
			handleSubmitError: this._handleErrorNewPremiseFormStep2
		})
	}

	_submitNewPremiseFormStep1(formData) {
		return this._premiseService.createPremise(formData)
	}

	async _submitNewPremiseFormStep2(formData) {
		const handleError = (error, alreadyExistsServerErrorCode) => {
			const status = HttpErrorHelper.getHttpStatus(error)

			if (status === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				const { errors = [] } = body

				if (
					errors.find(({ error_code: errorCode }) => errorCode === alreadyExistsServerErrorCode)
				) {
					return { hardError: false, error }
				}
			}

			return { hardError: true, error }
		}

		const addUser = this._premiseService.addUserToPremise(this._newPremiseAskId, {
			user_email: formData.user_email
		})

		return addUser
			.catch((error) => handleError(error, 'premise_user_unique'))
			.then((addUserResult) => {
				const addUserFailed = addUserResult.hardError

				if (!addUserFailed) {
					const addAdmin = this._premiseService.addAdminToPremise(this._newPremiseAskId, {
						admin_login: formData.admin_login
					})
					addAdmin
						.catch((error) => handleError(error, 'premise_admin_unique'))
						.then((addAdminResult) => {
							const addAdminFailed = addAdminResult.hardError
							if (!addAdminFailed) {
								// povedlo se pridat noveho uzivatele i admina (pokud jiz nebyli pridani drive)
								return Promise.resolve()
							} else {
								return Promise.reject([
									undefined,
									addAdminFailed ? addAdminResult.error : undefined
								])
							}
						})
				} else {
					return Promise.reject([addUserFailed ? addUserResult.error : undefined])
				}
			})
	}

	async _handleSuccessNewPremiseFormStep1() {
		this.setState({
			newPremiseStepShown: IAPremiseListController.NEW_PREMISE_FORM_WIZARD.STEP2
		})

		const formLineEntitiesNewPremiseStep2 = await this._getFormLinesNewPremiseFormStep2()

		this.setState({
			formLineEntitiesNewPremiseStep2
		})
	}

	_handleSuccessNewPremiseFormStep2() {
		this.setState({
			isNewPremisePopupOpened: false
		})

		this._router.redirect(
			this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_EDIT, {
				[InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID]: this._newPremiseAskId
			})
		)
	}

	_handleErrorNewPremiseFormStep1(error) {
		const status = HttpErrorHelper.getHttpStatus(error)

		if (status === HttpStatusCodes.NOT_FOUND) {
			return {
				entityFormErrorNewPremiseStep1: {
					title: 'Firma se zadaným ASK ID neexistuje'
				}
			}
		}

		let errorMessage = 'Nepodařilo se odeslat formulář'

		if (status === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(error)
			const { errors = [] } = body

			const [premiseAlreadyExistsError] = errors
				.map(({ error_code: errorCode }) => {
					if (errorCode === 'premise_already_exists') {
						return 'Firma se stejným ASK ID již existuje'
					}
				})
				.filter((error) => !!error)

			if (premiseAlreadyExistsError) {
				errorMessage = premiseAlreadyExistsError
			}
		}

		return {
			entityFormErrorNewPremiseStep1: {
				title: errorMessage
			}
		}
	}

	_handleErrorNewPremiseFormStep2([addUserError = {}, addAdminError = {}] = []) {
		const status = HttpErrorHelper.getHttpStatus(addUserError)

		let errorMessage = 'Firmu se nepodařilo přidat'

		if (status === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(addUserError)
			const { errors = [] } = body

			const [userIsAlreadyOnOtherPremiseError] = errors
				.map(({ error_code: errorCode }) => {
					if (errorCode === 'user_has_premise') {
						return 'Uživatel s tímto emailem je již přiřazen k jiné firmě'
					}
				})
				.filter((error) => !!error)

			if (userIsAlreadyOnOtherPremiseError) {
				errorMessage = userIsAlreadyOnOtherPremiseError
			}
		}

		return {
			entityFormErrorNewPremiseStep2: {
				title: errorMessage
			}
		}
	}
}
