import React from 'react'
import PropTypes from 'prop-types'
import { NO_RESULTS } from 'app/base/IconsConstants'

import './ErrorPageBoxView.less'

const CLASSNAME = 'c-error-box'

export default function ErrorPageBox({ children, title, className }) {
	const renderImage = () => {
		return <img className={`${CLASSNAME}__img`} src={NO_RESULTS} alt='' />
	}

	return (
		<div className={`${className} ${CLASSNAME}`}>
			{renderImage()}

			<h1 className={`${CLASSNAME}__title`}>{title}</h1>

			{children}
		</div>
	)
}
ErrorPageBox.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.node
}

ErrorPageBox.defaultProps = {
	className: ''
}

ErrorPageBox.CLASSNAME = CLASSNAME
