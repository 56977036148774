import React from 'react'
import classnames from 'classnames'
import { Button } from '@sznds/react'

import './ModalFooterButtons.less'

const CLASSNAME = 'c-modal-footer-buttons'

export default function ModalFooterButtons({ buttonsProps = [] }) {
	if (buttonsProps.length) {
		return (
			<div className={CLASSNAME}>
				{buttonsProps.map(({ className, ...buttonProps }, idx) => (
					<Button
						key={idx}
						className={classnames(`${CLASSNAME}__btn`, className)}
						{...buttonProps}
					/>
				))}
			</div>
		)
	} else {
		return null
	}
}
