import React from 'react'
import { Checkbox } from '@sznds/react'
import { Sort } from '@inzeraty/helpers'
import ExtendedInfo from 'app/component/extendedInfo/ExtendedInfo'
import { useLocalize } from 'app/base/componentHelpers'
import { CATEGORIES } from '../../../EquipmentConstants'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './EquipmentWidgetCS.json'
import './EquipmentWidget.less'

const CLASSNAME = 'c-equipment-widget'

export const EQUIPMENT_WIDGET_ID = 'EQUIPMENT_WIDGET_ID'

const NO_CATEGORY_SPECIFIED = 'no_category_specified'

const EquipmentWidget = (props) => {
	const localize = useLocalize()

	const { formLineEntity: { value = [], options = [] } = {}, onChange = () => {} } = props

	const splitEquipmentOptionsByCategories = (options) => {
		const addOptionToCategory = (object, option, category) => {
			const { [category]: optionsInCategory = [] } = object

			return Object.assign({}, object, {
				[category]: [...optionsInCategory, option]
			})
		}

		const optionsByCategories = options.reduce((result, option) => {
			const { category } = option

			if (category) {
				return addOptionToCategory(result, option, category)
			} else {
				return addOptionToCategory(result, option, NO_CATEGORY_SPECIFIED)
			}
		}, {})

		return optionsByCategories
	}

	const getCategoryLabel = (categoryName) => {
		return categoryName !== NO_CATEGORY_SPECIFIED
			? localize(`EquipmentWidget.${categoryName}`)
			: undefined
	}

	const renderCategoryContent = (optionsByCategory = []) => {
		const sortOptionsAlphabetically = (optionA = {}, optionB = {}) => {
			const { name: nameA = '' } = optionA
			const { name: nameB = '' } = optionB

			return Sort.sortAlphabetically(nameA, nameB)
		}

		const handleCheckboxChange = (event) => {
			const { value: optionValueString, checked } = event.target
			const optionValue = Number(optionValueString)

			if (checked) {
				onChange({
					value: [...value, optionValue]
				})
			} else {
				onChange({
					value: value.filter((val) => val !== optionValue)
				})
			}
		}

		return (
			<div className={`${CLASSNAME}__category-content`}>
				{optionsByCategory.sort(sortOptionsAlphabetically).map((option) => {
					const { value: optionIdentifier, name } = option
					const isChecked = value.includes(optionIdentifier)

					return (
						<div className={`${CLASSNAME}__item`} key={optionIdentifier}>
							<Checkbox
								checked={isChecked}
								value={optionIdentifier}
								label={name}
								onChange={handleCheckboxChange}
								data-e2e={name}
							/>
						</div>
					)
				})}
			</div>
		)
	}

	const renderCategoriesMobile = (categoriesToRender) => {
		const renderCategoryWrapperMobile = (categoryName, optionsByCategory) => {
			const categoryLabel = getCategoryLabel(categoryName)

			return categoryLabel ? (
				<ExtendedInfo
					text={getCategoryLabel(categoryName)}
					key={categoryName}
					isSectionOpenedByDefault={categoriesToRender[0] === categoryName}
				>
					{renderCategoryContent(optionsByCategory)}
				</ExtendedInfo>
			) : (
				<div key={categoryName}>{renderCategoryContent(optionsByCategory)}</div>
			)
		}

		return categoriesToRender.map((categoryName) =>
			renderCategoryWrapperMobile(categoryName, optionsByCategories[categoryName])
		)
	}

	const renderCategoriesDesktop = () => {
		const renderCategoryWrapperDesktop = (categoryName, optionsByCategory) => {
			const categoryLabel = getCategoryLabel(categoryName)

			return (
				<div key={categoryName} className={`${CLASSNAME}__category-section`}>
					{categoryLabel && <h2 className={`${CLASSNAME}__category-heading`}>{categoryLabel}</h2>}

					{renderCategoryContent(optionsByCategory)}
				</div>
			)
		}

		return categoriesToRender.map((categoryName) =>
			renderCategoryWrapperDesktop(categoryName, optionsByCategories[categoryName])
		)
	}

	const optionsByCategories = splitEquipmentOptionsByCategories(options)

	const categoriesToRender = [NO_CATEGORY_SPECIFIED, ...CATEGORIES].filter((categoryName) => {
		const optionsByCategory = optionsByCategories[categoryName]

		return optionsByCategory && optionsByCategory.length > 0
	})

	return (
		<>
			<div className={`${CLASSNAME}__mobile`}>{renderCategoriesMobile(categoriesToRender)}</div>
			<div className={`${CLASSNAME}__desktop`}>{renderCategoriesDesktop(categoriesToRender)}</div>
		</>
	)
}

EquipmentWidget.propTypes = {
	onChange: PropTypes.func,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity)
}

export default React.memo(EquipmentWidget)
