import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import IMAGES from 'app/base/ImagesConstants'
import { CATEGORIES, RESPONSIVE, CAR_BODY } from 'app/base/Constants'

const DRIVE_NAME = {
	ALL_WHEELS: '4x4'
}

const PERFORMANCE_UNIT = {
	KW: 'kw'
}

const getSourceProps = (image, type) => {
	const isWebp = type === 'webp'
	const imageSuffix = isWebp ? '-webp' : ''

	return {
		srcSet: `
			${IMAGES[`${image}-328x200${imageSuffix}`]} 328w,
			${IMAGES[`${image}-656x400${imageSuffix}`]} 656w
		`,
		sizes: `
			(min-width: ${RESPONSIVE.DESKTOP}px) 331px,
			128px
		`,
		type: isWebp ? 'image/webp' : 'image/jpeg'
	}
}

const getImgProps = (image) => {
	return {
		src: IMAGES[`${image}-656x400`],
		sourceWebpProps: getSourceProps(image, 'webp'),
		sourceJpgProps: getSourceProps(image, 'jpg')
	}
}

const CAR_TYPES = [
	{
		categoryId: CATEGORIES.PASSENGER_CARS.id,
		type: 'cheap',
		imgProps: getImgProps('image__car-type__cheap'),
		filters: {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_TO]: 50000
		}
	},
	{
		categoryId: CATEGORIES.PASSENGER_CARS.id,
		type: 'family',
		imgProps: getImgProps('image__car-type__family'),
		filters: {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_FROM]: 100000,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_TO]: 750000,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.VEHICLE_BODY_NAME]: [
				CAR_BODY.COMBI.seoName,
				CAR_BODY.LIFTBACK.seoName,
				CAR_BODY.SUV.seoName,
				CAR_BODY.CUV.seoName,
				CAR_BODY.MPV.seoName
			].join(',')
		}
	},
	{
		categoryId: CATEGORIES.PASSENGER_CARS.id,
		type: 'offRoad',
		imgProps: getImgProps('image__car-type__off-road'),
		filters: {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_FROM]: 150000,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.VEHICLE_BODY_NAME]: [
				CAR_BODY.SUV.seoName,
				CAR_BODY.OFF_ROAD.seoName,
				CAR_BODY.PICK_UP.seoName,
				CAR_BODY.CUV.seoName
			].join(','),
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.DRIVE_NAME]: [DRIVE_NAME.ALL_WHEELS].join(
				','
			)
		}
	},
	{
		categoryId: CATEGORIES.PASSENGER_CARS.id,
		type: 'small',
		imgProps: getImgProps('image__car-type__small'),
		filters: {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_FROM]: 50000,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.VEHICLE_BODY_NAME]: [
				CAR_BODY.HATCHBACK.seoName
			].join(',')
		}
	},
	{
		categoryId: CATEGORIES.PASSENGER_CARS.id,
		type: 'managers',
		imgProps: getImgProps('image__car-type__managers'),
		filters: {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_FROM]: 250000,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.AGE_FROM]: 2017,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.VEHICLE_BODY_NAME]: [
				CAR_BODY.COMBI.seoName,
				CAR_BODY.LIFTBACK.seoName,
				CAR_BODY.SEDANLIMUZINA.seoName
			].join(',')
		}
	},
	{
		categoryId: CATEGORIES.PASSENGER_CARS.id,
		type: 'sports',
		imgProps: getImgProps('image__car-type__sports'),
		filters: {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_FROM]: 150000,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.AGE_FROM]: 2010,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.TACHOMETER_TO]: 200000,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS
				.PERFORMANCE_FROM]: `200${PERFORMANCE_UNIT.KW}`,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.VEHICLE_BODY_NAME]: [
				CAR_BODY.COMBI.seoName,
				CAR_BODY.LIFTBACK.seoName,
				CAR_BODY.HATCHBACK.seoName,
				CAR_BODY.CUPE.seoName,
				CAR_BODY.ROADSTER.seoName,
				CAR_BODY.SEDANLIMUZINA.seoName,
				CAR_BODY.CABRIOLET.seoName
			].join(',')
		}
	}
]

export { CAR_TYPES }
