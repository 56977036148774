import { CAR_CONDITION } from 'app/base/Constants'
import { FORM_IDS } from 'app/page/userweb/newAdvert/addVIN/components/AddVINForm'
import {
	filterFormLineEntitiesBasedOnSelectedDealType,
	filterFormLineEntitiesBasedOnSelectedFuel,
	filterFormLineEntitiesBasedOnSelectedCondition
} from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLines'

export const filterFormLineEntitiesToShowBasedOnSelection = (
	formLineEntitiesToShow,
	isPremise,
	allFormLineEntities
) => {
	let filteredFormLineEntitiesToShow = filterFormLineEntitiesBasedOnSelectedDealType(
		formLineEntitiesToShow,
		allFormLineEntities || formLineEntitiesToShow
	)

	filteredFormLineEntitiesToShow = filterFormLineEntitiesBasedOnSelectedFuel(
		filteredFormLineEntitiesToShow,
		allFormLineEntities || filteredFormLineEntitiesToShow
	)

	return filterFormLineEntitiesBasedOnSelectedCondition(
		filteredFormLineEntitiesToShow,
		isPremise,
		isNewVehicleConditionSelected(allFormLineEntities || filteredFormLineEntitiesToShow),
		allFormLineEntities || filteredFormLineEntitiesToShow
	)
}

export const isNewVehicleConditionSelected = (allFormLineEntities = []) => {
	const conditionFormLineEntity = allFormLineEntities.find(
		({ id }) => id === FORM_IDS.VEHICLE_CONDITION
	)

	if (conditionFormLineEntity) {
		const { value } = conditionFormLineEntity

		return value === CAR_CONDITION.NEW.value
	}

	return false
}
