
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PublicationLength'] = {
  length: function(d) { return d.LENGTH + " dní za " + d.PRICE + " " + d.CURRENCY + " včetně DPH"; },
  header: function(d) { return "Chci inzerát na:"; },
  top: function(d) { return "Topovat inzerát za " + d.PRICE + " " + d.CURRENCY + " včetně DPH"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	