import PropTypes from 'prop-types'

const CodeBookItemPropType = PropTypes.shape({
	value: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	seo_name: PropTypes.string.isRequired
})

const MySavedSearchPropTypes = {
	id: PropTypes.number,
	watchdogHash: PropTypes.string,
	notificationInterval: PropTypes.string,
	ribbonNotificationEnabled: PropTypes.bool,
	operatingLease: PropTypes.bool,
	filter: PropTypes.shape({
		category_id: PropTypes.number,
		manufacturer_model_cb: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.number.isRequired,
				name: PropTypes.string.isRequired,
				seo_name: PropTypes.string.isRequired,
				models: PropTypes.arrayOf(CodeBookItemPropType)
			})
		),
		phrase: PropTypes.string,
		vehicle_age_from: PropTypes.number,
		vehicle_age_to: PropTypes.number,
		price_from: PropTypes.number,
		price_to: PropTypes.number,
		price_option_cb: PropTypes.arrayOf(CodeBookItemPropType),
		tachometer_from: PropTypes.number,
		tachometer_to: PropTypes.number,
		fuel_cb: PropTypes.arrayOf(CodeBookItemPropType),
		vehicle_body_cb: PropTypes.arrayOf(CodeBookItemPropType),
		condition_cb: PropTypes.arrayOf(CodeBookItemPropType),
		gearbox_cb: PropTypes.arrayOf(CodeBookItemPropType),
		engine_power_from: PropTypes.number,
		engine_power_to: PropTypes.number,
		engine_volume_from: PropTypes.number,
		engine_volume_to: PropTypes.number,
		drive_cb: PropTypes.arrayOf(CodeBookItemPropType),
		history_info_cb: PropTypes.arrayOf(CodeBookItemPropType),
		capacity_from: PropTypes.number,
		capacity_to: PropTypes.number,
		door_from: PropTypes.number,
		door_to: PropTypes.number,
		aircondition_cb: PropTypes.arrayOf(CodeBookItemPropType),
		airbag_from: PropTypes.number,
		airbag_to: PropTypes.number,
		average_gas_mileage_from: PropTypes.number,
		average_gas_mileage_to: PropTypes.number,
		color_cb: PropTypes.arrayOf(CodeBookItemPropType),
		country_of_origin_cb: PropTypes.arrayOf(CodeBookItemPropType),
		vendor_cb: PropTypes.arrayOf(CodeBookItemPropType),
		certified_program_cb: PropTypes.arrayOf(CodeBookItemPropType),
		region_district_cb: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.number.isRequired,
				name: PropTypes.string.isRequired,
				seo_name: PropTypes.string.isRequired,
				districts: PropTypes.arrayOf(CodeBookItemPropType)
			})
		),
		item_age_cb: CodeBookItemPropType,
		other_specification_cb: PropTypes.arrayOf(CodeBookItemPropType),
		equipment_cb: PropTypes.arrayOf(CodeBookItemPropType),
		seats_cb: PropTypes.arrayOf(CodeBookItemPropType),
		beds_from: PropTypes.number,
		beds_to: PropTypes.number,
		weight_from: PropTypes.number,
		weight_to: PropTypes.number,
		machine_hours_from: PropTypes.number,
		machine_hours_to: PropTypes.number
	}),
	newCount: PropTypes.number,
	count: PropTypes.number,
	timestamp: PropTypes.instanceOf(Date),
	createDate: PropTypes.instanceOf(Date)
}

export default MySavedSearchPropTypes
