export default Object.freeze({
	INTENDED_FOR_CB: 'operating_lease_intended_for_cb',
	PERIOD: 'operating_lease_period',
	ANNUAL_DISTANCE: 'operating_lease_annual_distance',
	SERVICES_CB: 'operating_lease_services_cb',
	ADDITIONAL_INFO: 'operating_lease_additional_info',
	PRICE: 'operating_lease_price'
})

export const getDefinition = (definitions = [], definitionName) =>
	definitions.find(({ name }) => name === definitionName) || {}
