import React from 'react'
import ErrorPageBox from 'app/component/errorPageBox/ErrorPageBoxView'
import PropTypes from 'prop-types'

export default function ErrorPageBoxDescription({ children }) {
	return <p className={`${ErrorPageBox.CLASSNAME}__desc`}>{children}</p>
}

ErrorPageBoxDescription.propTypes = {
	children: PropTypes.node
}
