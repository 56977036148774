import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractRadioButtonForm from '../../../AbstractRadioButtonForm'
import { Input, Radio } from '@sznds/react'
import NumberFormat from 'react-number-format'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'
import FilterConstants from 'app/model/filter/FilterConstants'
import classnames from 'classnames'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import {
	DROPDOWN_DEFAULT_YEARS_VALUES,
	DROPDOWN_DEFAULT_PRICE_VALUES,
	DROPDOWN_DEFAULT_TACHOMETER_VALUES,
	DROPDOWN_DEFAULT_PERFORMANCE_VALUES,
	DROPDOWN_DEFAULT_OPERATING_LEASE_ANNUAL_DISTANCE_VALUES,
	DROPDOWN_DEFAULT_OPERATING_LEASE_PERIOD_VALUES
} from './DropdownDefaultOptions'
import { Responsive } from '@inzeraty/components'
import { RESPONSIVE } from 'app/base/Constants'
import AutoComplete, { filterItems } from 'app/component/autoComplete/AutoComplete'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import AutoCompleteAllForm from 'app/component/autoComplete/forms/all/AutoCompleteAllForm'
import { Format } from '@inzeraty/helpers'
import FilterRow from 'app/component/filters/components/filterRow/FilterRowView'
import Count from 'app/component/filters/components/count/CountView'

import 'app/base/BaseCS.json'
import './RadioInlineForm.less'
import './RadioInlineFormCS.json'
import '../../../rangeInput/RangeInputFormCS.json'

const RANGE_INPUT_FROM = 'range-input-from'
const RANGE_INPUT_TO = 'range-input-to'

const CLASSNAME = 'c-radio-inline-form'

export default class RadioInlineForm extends AbstractRadioButtonForm {
	static get propTypes() {
		return Object.assign({}, AbstractRadioButtonForm.propTypes, {
			filteredAdvertsCountFromInput: PropTypes.object,
			isLoadingFilteredAdvertsCountFromInput: PropTypes.bool,
			loadFilteredAdvertsCountFromInput: PropTypes.func,

			filteredAdvertsCountToInput: PropTypes.object,
			isLoadingFilteredAdvertsCountToInput: PropTypes.bool,
			loadFilteredAdvertsCountToInput: PropTypes.func,
			renderType: PropTypes.oneOf(RadioInlineForm.RENDER_TYPE)
		})
	}

	static get defaultProps() {
		return Object.assign({}, AbstractRadioButtonForm.defaultProps, {
			filteredAdvertsCountFromInput: DEFAULT_PROPS.OBJECT,
			isLoadingFilteredAdvertsCountFromInput: false,
			loadFilteredAdvertsCountFromInput: DEFAULT_PROPS.FUNCTION,

			filteredAdvertsCountToInput: DEFAULT_PROPS.OBJECT,
			isLoadingFilteredAdvertsCountToInput: false,
			loadFilteredAdvertsCountToInput: DEFAULT_PROPS.FUNCTION,
			renderType: RadioInlineForm.RENDER_TYPE.DEFAULT
		})
	}

	static get RENDER_TYPE() {
		return {
			DEFAULT: 'defaultRange',
			FROM: 'fromRange',
			TO: 'toRange'
		}
	}

	constructor(props, context) {
		super(props, context)

		const {
			formLineEntity: {
				value: { value, valueFrom, valueTo }
			},
			isFormValid: isFormValidByDefault
		} = props

		this.state = {
			// kvuli validaci az pri opusteni inputu je potreba drzet jejich stav zde,
			// nikoliv pouze primo v props.formLineEntity. Jinak by k validaci dochazelo
			// okamzite behem psani. K zapisu do props.formLineEntity dojde pri opusteni
			// inputu, takze az v tuto chvili se pripadne vypise chybova hlaska.
			inputValueFrom: valueFrom,
			inputValueTo: valueTo,
			lastlyChangedInputValue: null,
			isFormValid: isFormValidByDefault,
			selectedOptionValue: value || this._getLastOptionValue()
		}

		this._onInputFromChange = this._onInputFromChange.bind(this)
		this._onInputToChange = this._onInputToChange.bind(this)
		this._onInputFromBlur = this._onInputFromBlur.bind(this)
		this._onInputToBlur = this._onInputToBlur.bind(this)
		this._validateForm = this._validateForm.bind(this)
		this._onInputFocus = this._onInputFocus.bind(this)
		this._onOptionSelect = this._onOptionSelect.bind(this)
		this._onInputFromDropdownChange = this._onInputFromDropdownChange.bind(this)
		this._handleDropdownSelect = this._handleDropdownSelect.bind(this)
		this._onInputToDropdownChange = this._onInputToDropdownChange.bind(this)
		this._isAllowed = this._isAllowed.bind(this)
	}

	componentDidUpdate(prevProps) {
		const { formLineEntity, isFormValid: isFormValidByDefault } = this.props
		const { inputValueFrom, inputValueTo } = this.state

		if (prevProps.formLineEntity !== formLineEntity) {
			const {
				value: { value, valueFrom, valueTo }
			} = formLineEntity

			this.setState({
				inputValueFrom: valueFrom,
				inputValueTo: valueTo,
				selectedOptionValue: value || this._getLastOptionValue()
			})

			if (inputValueFrom !== valueFrom || inputValueTo !== valueTo) {
				this.setState(
					{
						lastlyChangedInputValue: null,
						isFormValid: isFormValidByDefault
					},
					() => this._validateForm()
				)
			}
		}
	}

	_getDictionary() {
		const {
			formLineEntity: { id }
		} = this.props

		return {
			from: this.localize(`RangeInputForm.${id}-from`),
			to: this.localize(`RangeInputForm.${id}-to`),
			rangeValidationError: this.localize(`RangeInputForm.${id}-rangeValidationError`),
			placeholderFrom: this.localize('RadioInlineForm.from'),
			placeholderTo: this.localize('RadioInlineForm.to'),
			placeholderFromAgeHp: this.localize('RadioInlineForm.hpAgeFrom'),
			placeholderFromPriceHp: this.localize('RadioInlineForm.hpPriceFrom'),
			placeholderToPriceHp: this.localize('RadioInlineForm.hpPriceTo'),
			placeholderToTachometerHp: this.localize('RadioInlineForm.hpTachometerTo')
		}
	}

	render() {
		const { isFormValid, lastlyChangedInputValue } = this.state
		const { formLineEntity, className, renderType } = this.props
		const { id } = formLineEntity

		const isHPFilter = this._isHPFilter()

		const inputSize = isHPFilter ? 'regular' : 'small'

		const thousandSeparator = id === FilterConstants.formLineIds.AGE ? '' : ' '
		const format = id === FilterConstants.formLineIds.AGE ? '####' : undefined

		const dictionary = this._getDictionary()

		const filtersWithDropdownOnDesktop =
			id === FilterConstants.formLineIds.AGE ||
			id === FilterConstants.formLineIds.PRICE ||
			id === FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE ||
			id === FilterConstants.formLineIds.OPERATING_LEASE_PERIOD ||
			id === FilterConstants.formLineIds.TACHOMETER ||
			id === FilterConstants.formLineIds.PERFORMANCE

		const showOrdinaryInputFrom = !filtersWithDropdownOnDesktop
		const showOrdinaryInputTo = !filtersWithDropdownOnDesktop

		const renderFrom =
			renderType === RadioInlineForm.RENDER_TYPE.DEFAULT ||
			renderType === RadioInlineForm.RENDER_TYPE.FROM
		const renderTo =
			renderType === RadioInlineForm.RENDER_TYPE.DEFAULT ||
			renderType === RadioInlineForm.RENDER_TYPE.TO

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
				data-e2e={formLineEntity.id}
				data-dot='filter'
				data-dot-data={`{"type": "${formLineEntity.id}"}`}
			>
				{this._renderPresets()}
				<div className={`${CLASSNAME}__range`} data-dot='choose-range'>
					{(!isHPFilter || renderFrom) &&
						this._inputFrom(
							inputSize,
							thousandSeparator,
							format,
							formLineEntity,
							showOrdinaryInputFrom
						)}
					{(!isHPFilter || (renderFrom && renderTo)) && (
						<span className={`${CLASSNAME}__range-separator`}></span>
					)}
					{(!isHPFilter || renderTo) &&
						this._inputTo(
							inputSize,
							thousandSeparator,
							format,
							formLineEntity,
							showOrdinaryInputTo
						)}
				</div>
				{!isFormValid &&
					((lastlyChangedInputValue === RANGE_INPUT_FROM && renderFrom) ||
						(lastlyChangedInputValue === RANGE_INPUT_TO && renderTo)) && (
						<div className={`${CLASSNAME}__error`}>{dictionary.rangeValidationError}</div>
					)}
			</div>
		)
	}

	_isAllowed(values) {
		const { floatValue } = values

		if (floatValue !== undefined) return floatValue >= 0 && floatValue <= 999999999999
		else return true
	}

	_onInputFocus() {
		// kdyz uzivatel klikne do inputu pro zadani vlastniho rozsahu, tak chceme
		// prepnout radio na 'Vlastni', a dovolime uzivateli z tohoto stavu odejit
		// jedine tak, ze vybere nejaky preset. Zaroven nechceme upravovat samotnou
		// formline, protoze pak by se vsechno muselo znova prekreslit.
		this.setState({ selectedOptionValue: this._getLastOptionValue() })
	}

	_renderPresets() {
		const { formLineEntity, isLoadingFilteredAdvertsCount, filteredAdvertsCount } = this.props
		const { selectedOptionValue } = this.state
		const { options = [] } = formLineEntity

		if (options.length && !this._isHPFilter()) {
			return (
				<div className={`${CLASSNAME}__presets`}>
					{options.map(({ id, name, value }, index) => {
						return (
							// eslint-disable-next-line jsx-a11y/label-has-associated-control
							<label key={id} className={`${CLASSNAME}__preset`}>
								<FilterRow
									label={name}
									count={filteredAdvertsCount[index + 1] || 0}
									isCountLoading={isLoadingFilteredAdvertsCount}
									index={index}
									isHpFilter={false}
								>
									<Radio
										value={value}
										checked={value === selectedOptionValue}
										onChange={this._onOptionSelect}
										dataDot='filter-preset'
									/>
								</FilterRow>
							</label>
						)
					})}
				</div>
			)
		} else {
			return null
		}
	}

	_renderPlaceholderFrom(formLineEntity = {}) {
		const dictionary = this._getDictionary()
		const isHPFilter = this._isHPFilter()

		if (isHPFilter) {
			const { id } = formLineEntity
			switch (id) {
				case FilterConstants.formLineIds.PRICE:
					return dictionary.placeholderFromPriceHp

				case FilterConstants.formLineIds.AGE:
					return dictionary.placeholderFromAgeHp
			}
		}

		return dictionary.placeholderFrom
	}

	_renderPlaceholderTo(formLineEntity = {}) {
		const dictionary = this._getDictionary()
		const isHPFilter = this._isHPFilter()

		if (isHPFilter) {
			const { id } = formLineEntity
			switch (id) {
				case FilterConstants.formLineIds.PRICE:
					return dictionary.placeholderToPriceHp

				case FilterConstants.formLineIds.TACHOMETER:
					return dictionary.placeholderToTachometerHp
			}
		}

		return dictionary.placeholderTo
	}

	_renderUnits() {
		const unit = this._getFilterUnit()
		const isHPFilter = this._isHPFilter()

		if (unit) {
			return (
				<span
					className={classnames({
						[`${CLASSNAME}__units`]: true,
						[`${CLASSNAME}__units-hp`]: isHPFilter
					})}
				>
					{unit}
				</span>
			)
		}
	}

	_getFilterUnit() {
		const { formLineEntity } = this.props
		const {
			id,
			extra: { unit }
		} = formLineEntity

		let filterUnit

		if (id === FORM_LINES_IDS.WEIGHT) {
			filterUnit = this.localize('Base.KG')
		} else if (id === FORM_LINES_IDS.MACHINE_HOURS) {
			filterUnit = this.localize('Base.HOURS')
		} else {
			filterUnit = unit
		}

		return filterUnit
	}

	_isHPFilter() {
		const { context } = this.props

		return context === FILTER_CONTEXT.MAIN_MENU || context === FILTER_CONTEXT.MAIN_MENU_INLINE
	}

	_inputClassName() {
		const { formLineEntity } = this.props
		const { id } = formLineEntity
		const unit = this._getFilterUnit()
		const isHPFilter = this._isHPFilter()

		let classNameValue = ''

		if (unit) {
			if (id === FORM_LINES_IDS.AVERAGE_GAS_MILEAGE) {
				classNameValue = `${CLASSNAME}__input--short`
			} else if (id === FORM_LINES_IDS.ENGINE_VOLUME || id === FORM_LINES_IDS.MACHINE_HOURS) {
				classNameValue = `${CLASSNAME}__input--medium`
			} else {
				classNameValue = `${CLASSNAME}__input--wide`
			}
		}

		return {
			className: classnames({
				[`${CLASSNAME}__input`]: true,
				[classNameValue]: !!unit,
				[`${CLASSNAME}__hp-input`]: isHPFilter
			})
		}
	}

	_onChange(event) {
		const value = Number(event.target.value)

		const valueData = {
			value
		}

		this._changeValue(valueData)
	}

	_onOptionSelect(event) {
		if (event) {
			event.stopPropagation()
		}
		const selectedValue = Number(event.target.value)

		const {
			formLineEntity: { options }
		} = this.props

		const index = options.findIndex((option) => option.value === selectedValue)

		const { value, valueFrom, valueTo } = options[index]

		const isLastOption = index === options.length - 1

		this.setState({ selectedOptionValue: value })

		if (!isLastOption) {
			// pro posledni moznost 'Vlastni' samotnou formlinu nemenime,
			// neni k tomu duvod, co je nastaveno v inputech nechceme prepsat
			this._changeValue({ value, valueFrom, valueTo })
		}
	}

	_onInputFromChange(event) {
		this.setState({
			inputValueFrom: this._getNumberFromString(event.target.value)
		})
	}

	_onInputFromDropdownChange(value, callback) {
		const isValidValue = this._canBeValueInDropdownChanged(value)

		if (isValidValue) {
			this.setState(
				{
					inputValueFrom: this._getNumberFromString(value)
				},
				() => callback && callback()
			)
		}
	}

	_canBeValueInDropdownChanged(value) {
		// input sa zmeni len ak vymazeme hodnotu alebo zadame cislo
		if (value) {
			return !isNaN(Number(value.replace(/\s/g, '')))
		} else {
			return true
		}
	}

	_onInputFromBlur() {
		const {
			formLineEntity: {
				value: { valueFrom: formLineValueFrom, valueTo: formLineValueTo } = {}
			} = {}
		} = this.props
		const { inputValueFrom, inputValueTo } = this.state

		this._validateForm()

		if (formLineValueFrom !== inputValueFrom) {
			if (formLineValueTo === inputValueTo) {
				this.setState({
					lastlyChangedInputValue: RANGE_INPUT_FROM
				})
			}

			this._changeValue({
				value: this._getChosenOptionValue(inputValueFrom, inputValueTo),
				valueFrom: inputValueFrom,
				valueTo: inputValueTo
			})
		}
	}

	_onInputToChange(event) {
		this.setState({
			inputValueTo: this._getNumberFromString(event.target.value)
		})
	}

	_onInputToDropdownChange(value, callback) {
		const isValidValue = this._canBeValueInDropdownChanged(value)

		if (isValidValue) {
			this.setState(
				{
					inputValueTo: this._getNumberFromString(value)
				},
				() => callback && callback()
			)
		}
	}

	_onInputToBlur() {
		const {
			formLineEntity: {
				value: { valueFrom: formLineValueFrom, valueTo: formLineValueTo } = {}
			} = {}
		} = this.props
		const { inputValueFrom, inputValueTo } = this.state

		this._validateForm()

		if (formLineValueTo !== inputValueTo) {
			if (formLineValueFrom === inputValueFrom) {
				this.setState({
					lastlyChangedInputValue: RANGE_INPUT_TO
				})
			}

			this._changeValue({
				value: this._getChosenOptionValue(inputValueFrom, inputValueTo),
				valueFrom: inputValueFrom,
				valueTo: inputValueTo
			})
		}
	}

	_getNumberFromString(value = '') {
		if (value) {
			return Number(value.replace(/[^\d]/g, ''))
		} else {
			return undefined
		}
	}

	_validateForm() {
		const { inputValueFrom, inputValueTo } = this.state
		let isValid

		if (inputValueFrom >= 0 && inputValueTo >= 0) {
			isValid = inputValueFrom <= inputValueTo
		} else {
			isValid = true
		}

		this.setState({
			isFormValid: isValid
		})
	}

	_tryGetMatchedPresetOption(newValue) {
		const { formLineEntity: { options = [] } = {} } = this.props
		const { valueFrom: actualValueFrom = 0, valueTo: actualValueTo } = newValue

		return options
			.filter((o) => o.valueFrom > 0 || o.valueTo > 0)
			.find(
				({ valueFrom = 0, valueTo }) => valueFrom === actualValueFrom && valueTo === actualValueTo
			)
	}

	_getChosenOptionValue(valueFrom, valueTo) {
		const selectedPresetOption = this._tryGetMatchedPresetOption({
			valueFrom,
			valueTo
		})

		if (selectedPresetOption) {
			return selectedPresetOption.value
		} else if (valueFrom >= 0 || valueTo >= 0) {
			return Number(this._getLastOptionValue())
		} else {
			return undefined
		}
	}

	_handleDropdownSelect(value, from = true) {
		if (value) {
			if (from) {
				this._onInputFromDropdownChange(value, this._onInputFromBlur)
			} else {
				this._onInputToDropdownChange(value, this._onInputToBlur)
			}
		}
	}

	_ordinaryInputFrom(inputSize, thousandSeparator, format, inputValueFrom) {
		const { lastlyChangedInputValue, isFormValid } = this.state
		const dictionary = this._getDictionary()

		return (
			<label className={`${CLASSNAME}__range-item`}>
				<span className={`${CLASSNAME}__range-label`}>{dictionary.from}</span>
				<NumberFormat
					customInput={Input}
					allowNegative={false}
					size={inputSize}
					thousandSeparator={thousandSeparator}
					type='tel'
					tagName='input'
					format={format}
					isAllowed={this._isAllowed}
					value={inputValueFrom}
					onChange={this._onInputFromChange}
					error={!isFormValid && lastlyChangedInputValue === RANGE_INPUT_FROM}
					onFocus={this._onInputFocus}
					onBlur={this._onInputFromBlur}
					onKeyPress={(e) => {
						if (e.which === 13 || e.keyCode === 13) {
							this._onInputFromBlur()
						}
					}}
					placeholder={this._renderPlaceholderFrom()}
					data-e2e='input-search-from'
					{...this._inputClassName()}
				/>
				{this._renderUnits()}
			</label>
		)
	}

	_ordinaryInputTo(inputSize, thousandSeparator, format, inputValueTo) {
		const { lastlyChangedInputValue, isFormValid } = this.state
		const dictionary = this._getDictionary()

		return (
			<label className={`${CLASSNAME}__range-item`}>
				<span className={`${CLASSNAME}__range-label`}>{dictionary.to}</span>
				<NumberFormat
					customInput={Input}
					allowNegative={false}
					size={inputSize}
					thousandSeparator={thousandSeparator}
					type='tel'
					tagName='input'
					format={format}
					isAllowed={this._isAllowed}
					value={inputValueTo}
					onChange={this._onInputToChange}
					error={!isFormValid && lastlyChangedInputValue === RANGE_INPUT_TO}
					onFocus={this._onInputFocus}
					onBlur={this._onInputToBlur}
					onKeyPress={(e) => {
						if (e.which === 13 || e.keyCode === 13) {
							this._onInputToBlur()
						}
					}}
					placeholder={this._renderPlaceholderTo()}
					data-e2e='input-search-to'
					{...this._inputClassName()}
				/>
				{this._renderUnits()}
			</label>
		)
	}

	_inputFrom(inputSize, thousandSeparator, format, formLineEntity, showOrdinary = false) {
		const { loadFilteredAdvertsCountFromInput } = this.props
		const { inputValueFrom = '' } = this.state

		if (showOrdinary) {
			return this._ordinaryInputFrom(inputSize, thousandSeparator, format, inputValueFrom)
		} else {
			return (
				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => {
						return this._ordinaryInputFrom(inputSize, thousandSeparator, format, inputValueFrom)
					}}
					renderDesktopElement={() => {
						return (
							<div className={`${CLASSNAME}__autocomplete`}>
								<AutoComplete
									stateReducer={getDropdownStateReducer(loadFilteredAdvertsCountFromInput)}
									inputValue={this._formatInputValue(inputValueFrom, formLineEntity.id)}
									onInputValueChange={(value) => this._onInputFromDropdownChange(value)}
									onSelect={(value) => this._handleDropdownSelect(value)}
									onInputBlur={this._onInputFromBlur}
									initialIsOpen={false}
									size={inputSize}
								>
									{(downshift) =>
										this._renderAutoCompleteInput(
											formLineEntity,
											downshift,
											() => this._onInputFromDropdownChange(undefined, this._onInputFromBlur),
											true
										)
									}
								</AutoComplete>
							</div>
						)
					}}
				/>
			)
		}
	}

	_inputTo(inputSize, thousandSeparator, format, formLineEntity, showOrdinary = false) {
		const { loadFilteredAdvertsCountToInput } = this.props
		const { inputValueTo = '' } = this.state

		if (showOrdinary) {
			return this._ordinaryInputTo(inputSize, thousandSeparator, format, inputValueTo)
		} else {
			return (
				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => {
						return this._ordinaryInputTo(inputSize, thousandSeparator, format, inputValueTo)
					}}
					renderDesktopElement={() => {
						return (
							<div className={`${CLASSNAME}__autocomplete`}>
								<AutoComplete
									stateReducer={getDropdownStateReducer(loadFilteredAdvertsCountToInput)}
									inputValue={this._formatInputValue(inputValueTo, formLineEntity.id)}
									onInputValueChange={(value) => this._onInputToDropdownChange(value)}
									onSelect={(value) => this._handleDropdownSelect(value, false)}
									onInputBlur={this._onInputToBlur}
									initialIsOpen={false}
									size={inputSize}
								>
									{(downshift) =>
										this._renderAutoCompleteInput(
											formLineEntity,
											downshift,
											() => this._onInputToDropdownChange(undefined, this._onInputToBlur),
											false
										)
									}
								</AutoComplete>
							</div>
						)
					}}
				/>
			)
		}
	}

	_filterOptions(formLineEntity, inputValue, from = true) {
		const { value = {}, id } = formLineEntity
		const { valueFrom, valueTo } = value

		let filterOptions = []

		if (id === FilterConstants.formLineIds.AGE) {
			filterOptions = DROPDOWN_DEFAULT_YEARS_VALUES
		} else if (id === FilterConstants.formLineIds.PRICE) {
			filterOptions = DROPDOWN_DEFAULT_PRICE_VALUES
		} else if (id === FilterConstants.formLineIds.TACHOMETER) {
			filterOptions = DROPDOWN_DEFAULT_TACHOMETER_VALUES
		} else if (id === FilterConstants.formLineIds.PERFORMANCE) {
			filterOptions = DROPDOWN_DEFAULT_PERFORMANCE_VALUES
		} else if (id === FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE) {
			filterOptions = DROPDOWN_DEFAULT_OPERATING_LEASE_ANNUAL_DISTANCE_VALUES
		} else if (id === FilterConstants.formLineIds.OPERATING_LEASE_PERIOD) {
			filterOptions = DROPDOWN_DEFAULT_OPERATING_LEASE_PERIOD_VALUES.map((option) => ({
				...option,
				name: this.localize('Base.month', {
					MONTH: option.value,
					MONTH_FORMATTED: Format.number(option.value)
				})
			}))
		}

		if (from) {
			// ak je zadana hodnota do, tak odfiltrujeme nerelevantne
			// hodnoty pre input od
			if (valueTo) {
				filterOptions = filterOptions.filter((opt) => valueTo >= opt.value)
			}
		} else {
			// rovnako odfiltrujeme nerelevantne hodnoty aj pre input do
			if (valueFrom) {
				filterOptions = filterOptions.filter((opt) => opt.value >= valueFrom)
			}
		}

		const filteredItems = filterOptions
			.filter((option) => filterItems(String(inputValue).replace(/\s/g, ''), String(option.value)))
			.map((opt) => Object.assign({}, opt, { value: this._formatInputValue(opt.value, id) }))

		return filteredItems
	}

	_formatInputValue(value, formLineId) {
		if (formLineId === FilterConstants.formLineIds.AGE) {
			return value
		} else {
			return Format.number(value)
		}
	}

	_noOptions(downshift, formLineEntity, label = '') {
		const { inputValue } = downshift
		const { label: filterName = '' } = formLineEntity
		const unit = this._getFilterUnit()

		return (
			<div className={`${CLASSNAME}__no-opt`}>
				<span>{filterName}</span>
				<span className={`${CLASSNAME}__no-opt-label`}>{label.toLowerCase()}</span>
				<span className={`${CLASSNAME}__no-opt-value`}>{inputValue}</span>
				{unit && <span>{unit}</span>}
			</div>
		)
	}

	_renderAutoCompleteInput(formLineEntity, downshift, clearFn, from = true) {
		const {
			getInputProps,
			getToggleButtonProps,
			getClearButtonProps,

			isOpen,
			inputValue
		} = downshift

		const isHPFilter = this._isHPFilter()

		const placeholder = from
			? this._renderPlaceholderFrom(formLineEntity)
			: this._renderPlaceholderTo(formLineEntity)

		const rangeInput = from ? RANGE_INPUT_FROM : RANGE_INPUT_TO

		const { lastlyChangedInputValue, isFormValid } = this.state
		const error = !isFormValid && lastlyChangedInputValue === rangeInput

		const filteredItems = this._filterOptions(formLineEntity, inputValue, from)

		const dataE2eInput = from ? 'input-search-from' : 'input-search-to'

		return (
			<div className={`${CLASSNAME}__wrapper`}>
				<AutoCompleteInput
					inputSurfaceProps={{
						size: isHPFilter ? 'regular' : 'small',
						'data-e2e': dataE2eInput
					}}
					inputProps={getInputProps({
						placeholder,
						className: classnames({
							[`${CLASSNAME}__search-term`]: true,
							[`${CLASSNAME}__search-term--hp-filter`]: isHPFilter
						}),
						error,
						onFocus: this._onInputFocus
					})}
					toggleButtonProps={
						!inputValue
							? getToggleButtonProps({
									isOpen
							  })
							: undefined
					}
					clearButtonProps={
						inputValue
							? getClearButtonProps({
									onClick: () => {
										downshift.setState({
											inputValue: '',
											selectedItem: undefined
										})

										clearFn && clearFn()
									}
							  })
							: undefined
					}
				/>
				{this._renderDropdown(formLineEntity, filteredItems, downshift, placeholder, from)}
			</div>
		)
	}

	_renderDropdown(formLineEntity, filteredItems, downshift, label, from = true) {
		const { isOpen, getDropdownProps, renderDropdown: Dropdown, inputValue } = downshift

		// [{ value: inputValue }] - stav ked nenajdeme defaultnu hodnotu, ale zaroven chceme prejst na moznost cez klavesnicu
		const renderedFilterItems = filteredItems.length ? filteredItems : [{ value: inputValue }]
		const noOptions = filteredItems.length === 0

		return (
			isOpen && (
				<Dropdown
					{...getDropdownProps({
						className: `${CLASSNAME}__dropdown`
					})}
				>
					{this._renderItems(
						renderedFilterItems,
						downshift,
						formLineEntity,
						label,
						noOptions,
						from
					)}
				</Dropdown>
			)
		)
	}

	_renderItems(items, downshift, formLineEntity, label, noOptions = false, from = true) {
		const {
			isLoadingFilteredAdvertsCountFromInput,
			filteredAdvertsCountFromInput,
			isLoadingFilteredAdvertsCountToInput,
			filteredAdvertsCountToInput
		} = this.props
		const { getItemProps, highlightedIndex } = downshift

		const [isAdvertCountLoading, advertCounts] = from
			? [isLoadingFilteredAdvertsCountFromInput, filteredAdvertsCountFromInput]
			: [isLoadingFilteredAdvertsCountToInput, filteredAdvertsCountToInput]

		const renderItem = (option = {}, index) => {
			const { id, value, name } = option
			const unit = this._getFilterUnit()

			return (
				<AutoCompleteOption
					{...getItemProps({
						key: value,
						index,
						item: String(value),
						isHighlighted: highlightedIndex === index,
						className: `${CLASSNAME}__item`
					})}
				>
					{noOptions ? (
						this._noOptions(downshift, formLineEntity, label)
					) : (
						<div className={`${CLASSNAME}__option-item`}>
							{name ? (
								<span>{name}</span>
							) : (
								<>
									<span>{value}</span>
									{unit && <span className={`${CLASSNAME}__option-unit`}>{unit}</span>}
								</>
							)}
							<Count
								className={`${CLASSNAME}__option-advert-count`}
								count={advertCounts[id] || 0}
								isCountShownWithAddText={true}
								isCountLoading={isAdvertCountLoading}
							/>
						</div>
					)}
				</AutoCompleteOption>
			)
		}

		return <AutoCompleteAllForm items={items} renderItem={renderItem} />
	}
}

const getDropdownStateReducer = (loadFilteredAdvertsCount) => (state, changes) => {
	const loadFilteredAdvertsCountOnOpen = (changes) => {
		// pri otevreni dropdownu automaticky nacteme cisilka poctu
		// inzeratu pro jednotlive polozky
		if (!state.isOpen && changes.isOpen) {
			loadFilteredAdvertsCount && loadFilteredAdvertsCount()
		}

		return changes
	}

	return loadFilteredAdvertsCountOnOpen(changes)
}
