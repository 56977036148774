import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Footer from 'app/component/footer/Footer'
import { UserService } from '@inzeraty/models'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import { useToasts, useNewToastEventListener } from 'app/component/toastMessages/ToastsHooks'
import ToastsContainer from 'app/component/toastMessages/container/ToastsContainer'
import Toast from 'app/component/toastMessages/toast/Toast'
import { useTouchState } from '@inzeraty/components'
import classnames from 'classnames'
import PageLoader from 'app/component/pageLoader/PageLoader'
import Error from 'app/page/error/Error'
import { HttpStatusCodes } from '@inzeraty/helpers'
import TermsAgreement from 'app/component/termsAgreement/TermsAgreement'

import './LayoutAdmin.less'

const CLASSNAME = 'c-layout-admin'

const getHttpStatus = (httpStatus, userEntity, isPremiseAdmin) => {
	switch (httpStatus) {
		case HttpStatusCodes.UNAUTHORIZED:
		case HttpStatusCodes.FORBIDDEN:
		case HttpStatusCodes.NOT_FOUND:
		case HttpStatusCodes.OK:
			return httpStatus

		default: {
			// neni zadan httpStatus (nebo ho zatim neosetrujeme), pokusime se
			// odhadnout spravny stav stranky (je to jen fallback pro starsi
			// stranky, preferovana je varianta se zadanym httpStatus)
			if (UserService.isUserLogged(userEntity)) {
				const { premises = [] } = userEntity

				if (isPremiseAdmin && !premises.length) {
					// uzivatel je prihlasen, ale ne jako bazarnik, zobrazime chybovou stranku
					return HttpStatusCodes.FORBIDDEN
				} else {
					// zobrazime normalni stranku
					return HttpStatusCodes.OK
				}
			} else {
				// uzivatel neni prihlasen, zobrazime chybovou stranku
				return HttpStatusCodes.UNAUTHORIZED
			}
		}
	}
}
export default function LayoutAdmin({
	children,
	httpStatus: originalHttpStatus,
	isPremiseAdmin,
	className
}) {
	const isTouch = useTouchState()
	const userEntity = useContext(UserEntityContext)

	const { toasts, addToast, removeToast } = useToasts()

	useNewToastEventListener(addToast)

	const httpStatus = getHttpStatus(originalHttpStatus, userEntity, isPremiseAdmin)
	const isSuccess = httpStatus === HttpStatusCodes.OK

	return (
		<>
			{/* atribut key nemazat, udájně se bez něj stránka v nějakých případech renderoval chybně */}
			<div key='layout-admin-success'>
				{isSuccess && (
					<div className={classnames(className, CLASSNAME, isTouch && 'touch')}>
						<PageLoader />

						<ToastsContainer>
							{toasts.map((toast) => (
								<Toast
									key={toast.id}
									wrapperClassName={`${CLASSNAME}__toast`}
									{...toast.data}
									removeToast={() => removeToast(toast.id)}
								/>
							))}
						</ToastsContainer>

						<div className={`${CLASSNAME}__content`}>{children}</div>

						<Footer isAdmin={true} />
						<TermsAgreement />
					</div>
				)}
			</div>

			{/* atribut key nemazat, udájně se bez něj stránka v nějakých případech renderoval chybně */}
			<div key='layout-admin-error'>{!isSuccess && <Error status={httpStatus} />}</div>
		</>
	)
}

LayoutAdmin.propTypes = {
	className: PropTypes.string,
	httpStatus: PropTypes.number,
	children: PropTypes.node,
	isPremiseAdmin: PropTypes.bool
}

LayoutAdmin.defaultProps = {
	className: ''
}
