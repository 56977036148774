import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import { Button } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'
import { getErrorDictionary } from './SaveCertErrors'

import './SaveCert.less'
import './SaveCertCS.json'

const CLASSNAME = 'c-history-save-cert'

export const SAVE_CERT_FORM_IDS = {
	CEBIA_REPORT: 'cebia_report'
}

const { SZNDS_WIDGET_BY_ID, Form } = FormLines

const SaveCert = ({ submitSaveCertForm }) => {
	const localize = useLocalize()

	const [isFormSubmitting, setIsFormSubmitting] = useState(false)
	const [formLineEntities, setFormLineEntities] = useState(
		FormLines.createEntityList([
			{
				widget: FormLines.IDS.TEXT,
				id: SAVE_CERT_FORM_IDS.CEBIA_REPORT,
				label: localize('SaveCert.cebiaReport'),
				placeholder: localize('SaveCert.certPlaceholder'),
				required: true
			}
		])
	)

	const ERROR_DICTIONARY = getErrorDictionary(localize)

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			formLineEntities,
			{
				onChange
			},
			SZNDS_WIDGET_BY_ID
		)
	}

	const renderAddCebiaCertFormContent = (renderWidgets, renderAddCebiaCertSubmitButton) => {
		return (
			<div className={`${CLASSNAME}__content`}>
				<p className={`${CLASSNAME}__note`}>{localize('SaveCert.addText')}</p>
				{renderWidgets()}
				{renderAddCebiaCertSubmitButton()}
			</div>
		)
	}

	const renderAddCebiaCertSubmitButton = (props) => {
		return (
			<Button
				{...props}
				loading={isFormSubmitting}
				primary={true}
				text={localize('SaveCert.certSave')}
				className={`${CLASSNAME}__submit`}
				data-e2e='save-cebia-cert-button'
			/>
		)
	}

	const onChange = (event, newValue, id) => {
		setFormLineEntities(
			FormLines.updateEntities(formLineEntities, [
				{
					id,
					value: newValue
				}
			])
		)
	}

	const onSubmit = () => {
		setIsFormSubmitting(true)

		const formData = FormLines.getFormData(formLineEntities)

		submitSaveCertForm({
			formData,
			onErrorCallback: onSubmitError,
			onSuccessCallback: onSubmitSuccess
		})
	}

	const onSubmitSuccess = () => {
		setIsFormSubmitting(false)
	}

	const onSubmitError = (errors) => {
		const clearAllPreviousErrors = () =>
			formLineEntities.map(({ id }) => ({
				id,
				errorMessage: ''
			}))

		setIsFormSubmitting(false)

		const formLinesErrors = errors.reduce((processedErrors, newError) => {
			const { error_code: errorCode, fields } = newError

			const moreProcessedErrors = fields
				.map((id) => {
					if (ERROR_DICTIONARY[id]) {
						return {
							id,
							errorMessage:
								ERROR_DICTIONARY[id][errorCode] || ERROR_DICTIONARY[id][ERROR_CODES.UNKNOWN]
						}
					} else {
						return undefined
					}
				})
				.filter((err) => !!err)

			return processedErrors.concat(moreProcessedErrors)
		}, clearAllPreviousErrors())

		setFormLineEntities(FormLines.updateEntities(formLineEntities, formLinesErrors))
	}

	return (
		<Form
			formLineEntities={formLineEntities}
			isSending={isFormSubmitting}
			renderWidgets={renderWidgets}
			renderFormContent={renderAddCebiaCertFormContent}
			onSubmit={onSubmit}
			renderSubmitButton={renderAddCebiaCertSubmitButton}
			tagName='div'
		/>
	)
}

SaveCert.propTypes = {
	submitSaveCertForm: PropTypes.func
}

SaveCert.defultProps = {
	submitSaveCertForm: DEFAULT_PROPS.FUNCTION
}

export default React.memo(SaveCert)
