import * as Sentry from '@sentry/browser'
import UserwebBaseController from 'app/base/UserwebBaseController'
import AbstractNewAdvertBaseController from '../AbstractNewAdvertBaseController'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../AbstractNewAdvertStateKeys'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import STATE_KEYS from './PaymentStateKeys'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import ROUTE_NAMES from 'app/base/RouteNames'
import GenericError from 'ima/error/GenericError'
import ToastHelper from 'app/component/toastMessages/ToastHelper'
import { Format } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import { CATEGORIES } from 'app/base/Constants'
import { STATUS_CONSTANTS, DEACTIVATION_REASON } from 'app/model/advert/AdvertConstants'

import 'app/base/ActionMessageCS.json'

export default class PaymentController extends AbstractNewAdvertBaseController {
	constructor(dependenciesHelper, paymentService, pageLoaderExtension) {
		super(dependenciesHelper)

		this._router = this._utils.$Router
		this._paymentService = paymentService
		this._pageLoaderExtension = pageLoaderExtension

		this._setPaymentType = this._setPaymentType.bind(this)
		this._setPublicationLength = this._setPublicationLength.bind(this)
		this._setTermsAndConditions = this._setTermsAndConditions.bind(this)
		this._handleSMSPayment = this._handleSMSPayment.bind(this)
		this._processCreditCardPayment = this._processCreditCardPayment.bind(this)
		this._processCreditCardPaymentError = this._processCreditCardPaymentError.bind(this)
		this._processCreditCardConfirmation = this._processCreditCardConfirmation.bind(this)
		this._processConfirmationError = this._processConfirmationError.bind(this)
		this._processSMSAndWalletPayments = this._processSMSAndWalletPayments.bind(this)
		this._refreshAvailablePayments = this._refreshAvailablePayments.bind(this)
		this._handleWalletPayment = this._handleWalletPayment.bind(this)
		this._processSMSAndWalletPaymentsError = this._processSMSAndWalletPaymentsError.bind(this)
		this._handleCreditCardPayment = this._handleCreditCardPayment.bind(this)
		this._setSmsUrlAndSmsPublicationLength = this._setSmsUrlAndSmsPublicationLength.bind(this)
	}

	static processCreditCardPayment(paymentResponse, onSuccess = () => {}, onError = () => {}) {
		const { body: { payment_id, payment_url } = {} } = paymentResponse

		if (payment_id && payment_url) {
			onSuccess(payment_url)
		} else {
			onError(PAYMENTS_CONSTANTS.ERROR.CREDIT_CARD.UNAVAILABLE)
		}
	}

	static processCreditCardConfirmation(
		confirmationResponse,
		onSuccess = () => {},
		onError = () => {},
		onWaiting = () => {}
	) {
		const { body: { status_code, payment_status } = {} } = confirmationResponse

		if (status_code === HTTP_STATUS_CODE.OK) {
			if (payment_status === PAYMENTS_CONSTANTS.PAYMENT_STATUS.PROCESSED) {
				onSuccess()
			} else {
				onError()
			}
		} else {
			onWaiting()
		}
	}

	static processConfirmationError(error, onSuccess = () => {}, onError = () => {}) {
		const httpStatus = error.getHttpStatus ? error.getHttpStatus() : undefined

		if (httpStatus === HTTP_STATUS_CODE.FORBIDDEN) {
			const { body: { errors = [] } = {} } = error.getParams()
			const [paymentError = {}] = errors
			const { error_code, error_data } = paymentError

			if (error_code === PAYMENTS_CONSTANTS.ERROR.CREDIT_CARD.INVALID_PAYMENT_STATUS) {
				const { payment_status } = error_data

				if (payment_status === PAYMENTS_CONSTANTS.PAYMENT_STATUS.PROCESSED) {
					onSuccess()
				} else {
					onError()
				}
			} else {
				onError()
			}
		} else if (httpStatus > HTTP_STATUS_CODE.FORBIDDEN) {
			onError()
		}
	}

	load() {
		const superState = super.load()

		const {
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.HTTP_STATUS]: statusPromise,
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.HIDDEN_ADVERT_ENTITY]: advertEntityPromise
		} = superState

		const advertId = this._getAdvertId()

		const isSMSPaymentChosen = this._isSMSPaymentChosen()
		let paymentType

		if (isSMSPaymentChosen) {
			paymentType = PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS
		} else {
			paymentType = PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_CREDIT_CARD
		}

		return Object.assign({}, superState, {
			[STATE_KEYS.PAYMENTS]: this._paymentService.getAvailablePayments(advertId).catch(() => []),
			[STATE_KEYS.PAYMENT_TYPE]: paymentType,
			[STATE_KEYS.PUBLICATION_LENGTH]: advertEntityPromise.then((advertEntity) => {
				const isSms14daysPaymentAvailable = this._sms14daysPaymentAvaliable(advertEntity)

				if (isSMSPaymentChosen) {
					return this._getSmsPaymentLength(isSms14daysPaymentAvailable)
				} else {
					return PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.TWO_WEEKS
				}
			}),
			[STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED]: false,
			[STATE_KEYS.SET_PAYMENT_TYPE]: this._setPaymentType,
			[STATE_KEYS.SET_PUBLICATION_LENGTH]: this._setPublicationLength,
			[STATE_KEYS.SET_TERMS_AND_CONDITIONS]: this._setTermsAndConditions,
			[STATE_KEYS.IS_SMS_PAYMENT_CHOSEN]: isSMSPaymentChosen,
			[STATE_KEYS.HANDLE_CLOSE_SMS_PAYMENT]: this._handleCloseSMSPayment,
			[STATE_KEYS.HANDLE_SMS_PAYMENT]: this._handleSMSPayment,
			[STATE_KEYS.SMS_URL]: '',
			[STATE_KEYS.LOADING]: this._hasPaymentId(),
			[STATE_KEYS.ERROR_MESSAGE]: undefined,
			[STATE_KEYS.REFRESH_PAYMENTS]: this._refreshAvailablePayments,
			[STATE_KEYS.HANDLE_WALLET_PAYMENT]: this._handleWalletPayment,
			[STATE_KEYS.HANDLE_CREDIT_CARD_PAYMENT]: this._handleCreditCardPayment,
			[STATE_KEYS.IS_SMS_14_DAYS_PAYMENT_AVAILABLE]: advertEntityPromise.then((advertEntity) => {
				return this._sms14daysPaymentAvaliable(advertEntity)
			}),
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.HTTP_STATUS]: Promise.all([
				statusPromise,
				advertEntityPromise
			]).then(([status, advertEntity]) => {
				if (advertEntity) {
					const { premise } = advertEntity

					if (premise) {
						// prenastavime defaultni http status kod, stranka s platbou nejde zobrazit
						// pro inzerat patrici bazarnikovi
						this.status = HTTP_STATUS_CODE.NOT_FOUND
						return this.status
					}
				}

				// vratime puvodni http status
				return status
			})
		})
	}

	init() {
		super.init()

		this.addExtension(this._pageLoaderExtension)

		if (!this._isSMSPaymentChosen()) {
			this._pageLoaderExtension.show()
		}
	}

	activate() {
		super.activate()

		if (this._hasPaymentId()) {
			this._confirmCreditCardPayment()
		}

		const {
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.HTTP_STATUS]: statusPromise,
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.HIDDEN_ADVERT_ENTITY]: advertEntityPromise
		} = this.getState()

		Promise.all([statusPromise, advertEntityPromise]).finally(() => {
			this._pageLoaderExtension.hide()
		})
	}

	_isSMSPaymentChosen() {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const { [URL_APP_PARAMS.PAYMENT_TYPE]: paymentType } = this.getRouteParams()

		if (paymentType) {
			if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS) {
				return true
			} else {
				const error = new GenericError('Url adresa neexistuje.', {
					status: HTTP_STATUS_CODE.NOT_FOUND
				})
				return Promise.reject(error)
			}
		} else {
			return false
		}
	}

	_getSmsPaymentLength(isSms14daysPaymentAvailable) {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const {
			[URL_APP_PARAMS.PAYMENT_LENGTH]: paymentLength = PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.WEEK
		} = this.getRouteParams()

		const paymentLengthInt = Number(paymentLength)

		if (
			paymentLengthInt === PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.WEEK ||
			paymentLengthInt === PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.TWO_WEEKS
		) {
			if (isSms14daysPaymentAvailable) {
				return paymentLengthInt
			} else {
				// pre ostatne kategorie podporujeme len platbu sms na 7 dni
				if (paymentLengthInt === PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.WEEK) {
					return paymentLengthInt
				} else {
					this._redirectFromSmsToPayment()
				}
			}
		} else {
			this._redirectFromSmsToPayment()
		}
	}

	_redirectFromSmsToPayment() {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const { [URL_APP_PARAMS.ADVERT_ID]: advertId } = this.getRouteParams()

		this._router.redirect(
			this._router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, {
				[URL_APP_PARAMS.ADVERT_ID]: advertId
			})
		)
	}

	_sms14daysPaymentAvaliable(advertEntity) {
		const { category = {} } = advertEntity
		const { id: categoryId } = category

		const CATEGORY_IDS_14_DAYS_PAYMENT_AVAILABLE = [CATEGORIES.MOTORCYCLE.id, CATEGORIES.QUAD.id]

		return CATEGORY_IDS_14_DAYS_PAYMENT_AVAILABLE.includes(categoryId)
	}

	_setPaymentType(type) {
		this.setState({
			[STATE_KEYS.PAYMENT_TYPE]: type,
			[STATE_KEYS.ERROR_MESSAGE]: undefined
		})

		if (type === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS) {
			this._setSmsUrlAndSmsPublicationLength(type)
		}
	}

	_setSmsUrlAndSmsPublicationLength(type) {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const { [URL_APP_PARAMS.ADVERT_ID]: advertId } = this.getRouteParams()

		const {
			[STATE_KEYS.PUBLICATION_LENGTH]: publicationLength,
			[STATE_KEYS.IS_SMS_14_DAYS_PAYMENT_AVAILABLE]: isSms14daysPaymentAvailable
		} = this.getState()

		if (type === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS) {
			const optionalPaymentLength =
				publicationLength === PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.TWO_WEEKS &&
				isSms14daysPaymentAvailable
					? {
							[URL_APP_PARAMS.PAYMENT_LENGTH]: publicationLength
					  }
					: {}

			const smsUrl = this._utils.$Router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, {
				[URL_APP_PARAMS.ADVERT_ID]: advertId,
				[URL_APP_PARAMS.PAYMENT_TYPE]: type,
				...optionalPaymentLength
			})

			this.setState({
				[STATE_KEYS.PUBLICATION_LENGTH]: isSms14daysPaymentAvailable
					? publicationLength
					: PAYMENTS_CONSTANTS.PUBLICATION_LENGTH.WEEK,
				[STATE_KEYS.SMS_URL]: smsUrl
			})
		}
	}

	_setPublicationLength(length) {
		const { [STATE_KEYS.PAYMENT_TYPE]: type } = this.getState()

		this.setState({
			[STATE_KEYS.PUBLICATION_LENGTH]: length
		})

		if (type === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS) {
			this._setSmsUrlAndSmsPublicationLength(type)
		}
	}

	_setTermsAndConditions(checked) {
		this.setState({
			[STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED]: checked
		})
	}

	_refreshAvailablePayments() {
		this._paymentService
			.getAvailablePayments(this._getAdvertId())
			.then((payments) => {
				this.setState({
					[STATE_KEYS.PAYMENTS]: payments
				})
			})
			.catch(() => {})
	}

	_handleSMSPayment(pin, onErrorCallback) {
		const {
			[STATE_KEYS.PAYMENT_TYPE]: paymentType,
			[STATE_KEYS.PUBLICATION_LENGTH]: publicationLength
		} = this.getState()

		if (pin) {
			const currentUrl = this._utils.$Router.getUrl()
			const advertId = this._getAdvertId()

			this._setLoading()

			this._paymentService
				.postPayment(advertId, {
					payment_type: paymentType,
					sms_pin: pin,
					return_url: currentUrl,
					fail_url: currentUrl,
					advert_publication_length: publicationLength,
					payment_reason: PAYMENTS_CONSTANTS.PAYMENT_REASON.ADVERT
				})
				.then(this._processSMSAndWalletPayments)
				.catch((error) => {
					const httpStatus = error.getHttpStatus()
					const { body: { errors = [] } = {} } = error.getParams()
					const [paymentError = {}] = errors
					const { error_code } = paymentError

					if (httpStatus >= HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY) {
						onErrorCallback(error_code)

						this.setState({
							[STATE_KEYS.LOADING]: false
						})
					} else {
						this._setError()
					}
				})
		} else {
			onErrorCallback()
		}
	}

	_handleWalletPayment() {
		const {
			[STATE_KEYS.PAYMENT_TYPE]: paymentType,
			[STATE_KEYS.PUBLICATION_LENGTH]: publicationLength
		} = this.getState()

		const currentUrl = this._utils.$Router.getUrl()
		const advertId = this._getAdvertId()

		this._setLoading()

		this._paymentService
			.postPayment(advertId, {
				payment_type: paymentType,
				return_url: currentUrl,
				fail_url: currentUrl,
				advert_publication_length: publicationLength,
				payment_reason: PAYMENTS_CONSTANTS.PAYMENT_REASON.ADVERT
			})
			.then(this._processSMSAndWalletPayments)
			.catch(this._processSMSAndWalletPaymentsError)
	}

	_handleCreditCardPayment() {
		const {
			[STATE_KEYS.PAYMENT_TYPE]: paymentType,
			[STATE_KEYS.PUBLICATION_LENGTH]: publicationLength
		} = this.getState()

		const advertId = this._getAdvertId()
		const currentUrl = this._getCurrentUrl()

		this._paymentService
			.postPayment(advertId, {
				payment_type: paymentType,
				return_url: currentUrl,
				fail_url: currentUrl,
				advert_publication_length: publicationLength,
				payment_reason: PAYMENTS_CONSTANTS.PAYMENT_REASON.ADVERT
			})
			.then(this._processCreditCardPayment)
			.catch(this._processCreditCardPaymentError)

		this._setLoading()
	}

	async _confirmCreditCardPayment() {
		const { [UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfPromise } = this.getState()

		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants

		const advertId = this._getAdvertId()
		const { [URL_APP_PARAMS.PAYMENT_ID]: paymentId } = this.getRouteParams()

		const user = await userSelfPromise

		try {
			Sentry.addBreadcrumb({
				category: 'payment',
				message: `Credit card payment confirmation for user ${user.id}`,
				level: Sentry.Severity.Info,
				data: {
					user: `${user.name} (${user.id})`,
					cookie: document.cookie
				}
			})

			const response = await this._paymentService.closePayment(advertId, paymentId)
			this._processCreditCardConfirmation(response)
		} catch (err) {
			Sentry.addBreadcrumb({
				category: 'payment',
				message: `Credit card payment confirmation failed for user ${user.id}`,
				level: Sentry.Severity.Error,
				data: {
					user: `${user.name} (${user.id})`,
					cookie: document.cookie
				}
			})
			Sentry.captureException(err)

			this._processConfirmationError(err)
		}
	}

	_processCreditCardPayment(paymentResponse) {
		PaymentController.processCreditCardPayment(
			paymentResponse,
			(url) => this._router.redirect(url),
			(error) => this._setError(error)
		)
	}

	_processCreditCardPaymentError(error) {
		const httpStatus = error.getHttpStatus()
		if (httpStatus >= HTTP_STATUS_CODE.FORBIDDEN) {
			const { body: { errors = [] } = {} } = error.getParams()
			const [paymentError = {}] = errors
			const {
				ERROR: { CREDIT_CARD, VIN_DUPLICATION }
			} = PAYMENTS_CONSTANTS
			const { error_code } = paymentError
			if (error_code === VIN_DUPLICATION) {
				this._setError(VIN_DUPLICATION)
			} else {
				this._setError(CREDIT_CARD[paymentError.error_code])
			}
		}
	}

	_processSMSAndWalletPaymentsError(error) {
		const httpStatus = error.getHttpStatus()
		const { body: { errors = [] } = {} } = error.getParams()
		const [paymentError = {}] = errors
		const { error_code } = paymentError

		if (error_code === PAYMENTS_CONSTANTS.ERROR.WALLET.LOW_CREDIT) {
			this._setError(PAYMENTS_CONSTANTS.ERROR.WALLET.LOW_CREDIT)
		} else if (error_code === PAYMENTS_CONSTANTS.ERROR.VIN_DUPLICATION) {
			this._setError(PAYMENTS_CONSTANTS.ERROR.VIN_DUPLICATION)
		} else if (httpStatus >= HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY) {
			this._setError(PAYMENTS_CONSTANTS.ERROR.DEFAULT)
		} else {
			this._setError()
		}
	}

	async _processCreditCardConfirmation(confirmationResponse) {
		PaymentController.processCreditCardConfirmation(
			confirmationResponse,
			() => this._processSuccessCallback(),
			() => this._setError(),
			() => this._redirectToPaymentConfirmation()
		)
	}

	async _redirectToPaymentConfirmation() {
		const {
			URL_APP_PARAMS: { ADVERT_ID, PAYMENT_ID }
		} = AdvertAdminUrlConvertor.constants

		const { [PAYMENT_ID]: paymentId } = this.getRouteParams()

		const routeUrl = this._router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT_CONFIRMATION, {
			[ADVERT_ID]: this._getAdvertId(),
			[PAYMENT_ID]: paymentId
		})

		this._router.redirect(routeUrl)
	}

	async _processConfirmationError(error) {
		PaymentController.processConfirmationError(
			error,
			() => this._processSuccessCallback(),
			() => this._setError()
		)
	}

	onErrorClose() {
		this._resetUrl()

		this.setState({
			[STATE_KEYS.ERROR_MESSAGE]: undefined
		})
	}

	_resetUrl() {
		history.replaceState(history.state, '', this._getCurrentUrl())
	}

	_getCurrentUrl() {
		const { [STATE_KEYS.PAYMENT_TYPE]: paymentType } = this.getState()

		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants

		const urlParams = {}

		urlParams[URL_APP_PARAMS.ADVERT_ID] = this._getAdvertId()

		if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS) {
			urlParams[URL_APP_PARAMS.PAYMENT_TYPE] = paymentType
		}

		return this._router.link(ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT, urlParams)
	}

	_setLoading() {
		this.setState({
			[STATE_KEYS.LOADING]: true
		})
	}

	_setError(error = PAYMENTS_CONSTANTS.ERROR.DEFAULT) {
		this.setState({
			[STATE_KEYS.LOADING]: false,
			[STATE_KEYS.ERROR_MESSAGE]: error
		})
	}

	_hasPaymentId() {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const routeParams = this.getRouteParams()

		if (Object.keys(routeParams).indexOf(URL_APP_PARAMS.PAYMENT_ID) === -1) {
			return false
		} else {
			return true
		}
	}

	_processSMSAndWalletPayments(paymentResponse) {
		this._processSuccessCallback()
	}

	async _processSuccessCallback() {
		const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity = {} } = this.getState()
		const { status } = advertEntity

		const {
			validToDate,
			status: actualStatus = '',
			deactivationReason = ''
		} = await this._getAdvertEntityPromise()

		const dictData = {
			COUNT: 1,
			IDS: this._getAdvertId(),
			DATE: Format.date(validToDate)
		}

		const messageData = {
			type: StatusMessage.TYPE.SUCCESS,
			title: this._dictionary.get('ActionMessage.titleSuccess')
		}

		const isAdvertActive = actualStatus === STATUS_CONSTANTS.ACTIVE

		if (isAdvertActive) {
			// predlzujeme uz aktivny inzerat
			if (status === STATUS_CONSTANTS.ACTIVE) {
				messageData.text = this._dictionary.get('ActionMessage.prolongSuccess', dictData)
			} else {
				messageData.text = this._dictionary.get('ActionMessage.activateSuccess', dictData)
			}
		} else {
			let text = this._dictionary.get('ActionMessage.activateError', dictData)

			if (deactivationReason === DEACTIVATION_REASON.IMAGES) {
				text = this._dictionary.get('ActionMessage.insufficientImagesErrorBody', dictData)
			} else if (deactivationReason === DEACTIVATION_REASON.VIN_DUPLICATION) {
				text = this._dictionary.get('ActionMessage.vinDuplicationErrorBody', dictData)
			}

			messageData.type = StatusMessage.TYPE.WARNING
			messageData.title = this._dictionary.get('ActionMessage.titleError')
			messageData.text = text
		}

		ToastHelper.setToast(messageData)

		this._redirectToAdvertList()
	}
}
