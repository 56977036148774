import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import BasicInfoTooltip from 'app/page/userweb/newAdvert/basicInfo/components/tooltip/BasicInfoTooltip'
import BaseMonthYearSelect, {
	MONTH_AND_YEAR_SELECT_WIDGET_ID,
	getCustomMonthFormLineId
} from 'app/page/userweb/newAdvert/component/widgets/monthYearSelect/MonthYearSelect'
import BasicWidget from 'app/page/userweb/newAdvert/component/widgets/basic/BasicWidget'

export { MONTH_AND_YEAR_SELECT_WIDGET_ID }
export const GUARANTEE_MONTH_AND_YEAR_SELECT_WIDGET_ID = 'guarantee_month_and_year_select_widget'

const MonthYearSelect = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const selectId = `${getCustomMonthFormLineId(formLineEntity.id)}-input`

	const renderLabel = () => {
		const { label, required } = formLineEntity

		return (
			<>
				<label className='wdg__label' htmlFor={selectId}>
					{label}
					{required && <span className='c-basic-widget__required'>*</span>}
				</label>
				<BasicInfoTooltip formLineEntity={formLineEntity} />
			</>
		)
	}

	return (
		<BasicWidget
			formLineEntity={formLineEntity}
			renderLabel={renderLabel}
			renderInput={() => (
				<div className='c-basic-widget__input'>
					<BaseMonthYearSelect
						formLineEntity={formLineEntity}
						onChange={onChange}
						selectId={selectId}
					/>
				</div>
			)}
		/>
	)
}

MonthYearSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(MonthYearSelect)
