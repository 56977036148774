import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Tooltip } from '@inzeraty/components'

const CLASSNAME = 'c-rating-tooltip'

import './RatingTooltip.less'
import './RatingTooltipCS.json'
import 'app/base/BaseCS.json'

const RatingTooltip = ({ className = '' }) => {
	const localize = useLocalize()

	return (
		<Tooltip
			dictionary={{
				ariaClose: localize('Base.close'),
				blindClosePrefix: localize('RatingTooltip.blindClosePrefix'),
				ariaOpener: localize('RatingTooltip.ariaOpener')
			}}
			allowContentMouseOver={true}
			hideHeader={true}
			position={Tooltip.POSITION.RIGHT_BOTTOM}
			modalPosition={Tooltip.MODAL_POSITION.CENTER}
			modalHideHeader={true}
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<div className={`${CLASSNAME}__content`}>{localize('RatingTooltip.description')}</div>
		</Tooltip>
	)
}

RatingTooltip.propTypes = {
	className: PropTypes.string
}

export default React.memo(RatingTooltip)
