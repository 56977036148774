import React from 'react'
import { Icon } from '@sznds/react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import 'app/base/BaseCS'
import './ActionsItem.less'

const CLASSNAME = 'c-actions-item'

const ActionsItem = ({
	tagName = 'button',
	text,
	icon,
	actionProps: { className: actionClassName, ...actionProps } = {},
	dataDotText = ''
}) => {
	const TagName = tagName

	return (
		<TagName
			key={text}
			className={classnames({
				[actionClassName]: !!actionClassName,
				[CLASSNAME]: true
			})}
			{...actionProps}
			data-dot={dataDotText}
		>
			{!!icon && <Icon symbol={icon} className={`${CLASSNAME}__icon`} />}
			{text}
		</TagName>
	)
}

ActionsItem.propTypes = {
	tagName: PropTypes.string,
	text: PropTypes.string,
	icon: PropTypes.string,
	actionProps: PropTypes.object,
	dataDotText: PropTypes.string
}

export default ActionsItem
