import AbstractNewAdvertBaseController from '../AbstractNewAdvertBaseController'
import * as FormLines from '@inzeraty/form-lines'
import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import STATE_KEYS from './BasicInfoStateKeys'
import { createFormLineEntities, getFormData } from './BasicInfoFormLines'
import { splitFormLinesIntoSections } from './BasicInfoSections'
import FORM_STATUS_CODES from '../component/formStatusMessage/FormStatusCodes'
import flattenFormLineEntities from 'app/helpers/formLineEntities/flattenFormLineEntities'
import {
	flattenCreatePageData,
	processOptionsForFormLineEntities,
	processValuesForFormLineEntities
} from './createPage/processDataForFormLineEntities'
import applyOptionsForFormLineEntities from './createPage/applyOptionsForFormLineEntities'
import { FORM_LINES_IDS } from './BasicInfoFormLineIds'
import shouldLoadDataFromCebia from './cebia/shouldLoadDataFromCebia'
import processDataFromCebia from './cebia/processDataFromCebia'
import clearCebiaErrorFlags from './cebia/clearCebiaErrorFlags'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../AbstractNewAdvertStateKeys'
import getBrandOption from './models/getBrandOption'
import getBrandAndModelsFormLineEntities from './models/getBrandAndModelsFormLineEntities'
import UserwebBaseController from 'app/base/UserwebBaseController'
import ClientBaseController from 'app/base/ClientBaseController'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import { CEBIA_CATEGORY_ID } from 'app/base/Constants'
import OPERATING_LEASE_DEFINITION from 'app/component/operatingLease/OperatingLeaseDefinition'

const VEHICLE_CATEGORY_API_PARAM = 'category_id'
const VEHICLE_CONDITION_API_PARAM = 'condition_cb'
const VEHICLE_VIN_API_PARAM = 'vin'
const TERMS_OF_USE_API_PARAM = 'terms_of_use'

export default class BasicInfoController extends AbstractNewAdvertBaseController {
	constructor(
		dependenciesHelper,
		contactSectionExtension,
		localitySelectInputExtension,
		VINVehicleDataService,
		codebooksService,
		premiseService,
		operatingLeaseService
	) {
		super(dependenciesHelper)

		this._contactSectionExtension = contactSectionExtension
		this._localitySelectInputExtension = localitySelectInputExtension
		this._VINVehicleDataService = VINVehicleDataService
		this._codebooksService = codebooksService
		this._premiseService = premiseService
		this._operatingLeaseService = operatingLeaseService

		this._dictionary = this._utils.$Dictionary

		this._fillPhone = this._fillPhone.bind(this)
		this._fillEmail = this._fillEmail.bind(this)
		this._fillLocality = this._fillLocality.bind(this)
		this._loadDataFromCebia = this._loadDataFromCebia.bind(this)
		this._loadModels = this._loadModels.bind(this)
		this._clearModels = this._clearModels.bind(this)

		this._changeFormLineEntities = this._changeFormLineEntities.bind(this)
		this._submitForm = this._submitForm.bind(this)

		this._setFormStatus = this._setFormStatus.bind(this)

		this._scrollPageToTop = this._scrollPageToTop.bind(this)

		this._refetchAllOperatingLeasesVariants = this._refetchAllOperatingLeasesVariants.bind(this)
		this._addNewOperatingLeaseVariant = this._addNewOperatingLeaseVariant.bind(this)
		this._editOperatingLeaseVariant = this._editOperatingLeaseVariant.bind(this)
		this._deleteOperatingLeaseVariant = this._deleteOperatingLeaseVariant.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._contactSectionExtension)
		this.addExtension(this._localitySelectInputExtension)
	}

	load() {
		const superState = super.load()

		const { [ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise } = superState
		const { [ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise = false } = superState

		// sestaveni prvku na formulari
		const formLineEntitiesBySectionsPromise = Promise.all([advertEntityPromise, isPremise]).then(
			([advertEntity, isPremise]) => {
				if (!advertEntity) {
					return
				}

				const { category: { id: categoryId } = {} } = advertEntity

				const formLineEntities = createFormLineEntities(advertEntity, isPremise, this._dictionary)

				const premiseDisabledWidgetIds = [
					FORM_LINES_IDS.LOCALITY,
					FORM_LINES_IDS.PHONE,
					FORM_LINES_IDS.EMAIL
				]

				formLineEntities.map((formLineEntity) => {
					if (premiseDisabledWidgetIds.includes(formLineEntity.id)) {
						formLineEntity.disabled = !!isPremise
					}
				})

				return splitFormLinesIntoSections(categoryId, formLineEntities)
			}
		)

		return Object.assign({}, superState, {
			[STATE_KEYS.FORM_LINES_BY_SECTION]: formLineEntitiesBySectionsPromise,
			[STATE_KEYS.ON_CHANGE]: this._changeFormLineEntities,

			[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false,
			[STATE_KEYS.SUBMIT_FORM]: this._submitForm,

			[STATE_KEYS.SET_FORM_STATUS]: this._setFormStatus,

			[STATE_KEYS.SCROLL_PAGE_TO_TOP]: this._scrollPageToTop,

			[STATE_KEYS.LOAD_MODELS]: this._loadModels,
			[STATE_KEYS.CLEAR_MODELS]: this._clearModels,

			[STATE_KEYS.OPERATING_LEASE_VARIANTS]: advertEntityPromise.then(
				(advertEntity = {}) => advertEntity.operatingLeaseVariants
			),
			[STATE_KEYS.FETCH_OPERATING_LEASES_VARIANTS]: this._refetchAllOperatingLeasesVariants,
			[STATE_KEYS.ADD_NEW_OPERATING_LEASE_VARIANT]: this._addNewOperatingLeaseVariant,
			[STATE_KEYS.EDIT_OPERATING_LEASE_VARIANT]: this._editOperatingLeaseVariant,
			[STATE_KEYS.DELETE_OPERATING_LEASE_VARIANT]: this._deleteOperatingLeaseVariant
		})
	}

	activate() {
		super.activate()

		// chceme, aby uzivatel videl UI co nejdriv. Nechceme zobrazovat placeholdery
		// po celou dobu, nez budou nactena vsechna data jako napr. polozky do selectu.
		// Je to takto uzivatelsky lepsi, proto tento kod je tady a ne v loadu.

		const loadFormFieldsData = async (advertEntity) => {
			const { category: { id: categoryId } = {} } = advertEntity

			const createPageData = await this._advertService.getCreatePage(
				{
					[AdvertAdminUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId
				},
				{ cache: true }
			)

			const formLineEntitiesOptions = processOptionsForFormLineEntities(
				createPageData,
				this._dictionary
			)

			const formLineEntitiesValues = processValuesForFormLineEntities(createPageData)

			const {
				[STATE_KEYS.FORM_LINES_BY_SECTION]: formLineEntitiesBySections = {}
			} = this.getState()

			const formLineEntities = flattenFormLineEntities(formLineEntitiesBySections)

			const fillPhone = await this._fillPhone(advertEntity, formLineEntitiesValues)
			const fillEmail = await this._fillEmail(advertEntity, formLineEntitiesValues)
			const fillLocality = await this._fillLocality()

			this._changeFormLineEntities([
				...applyOptionsForFormLineEntities(formLineEntities, formLineEntitiesOptions),
				fillPhone,
				fillEmail,
				fillLocality
			])
		}

		const loadModels = (advertEntity) => {
			const { manufacturerCb: { value: selectedBrandValue } = {} } = advertEntity

			if (selectedBrandValue) {
				this._loadModels(selectedBrandValue)
			}
		}

		const loadDataFromCebia = async (formLineEntitiesBySections, advertEntity) => {
			const formLineEntities = flattenFormLineEntities(formLineEntitiesBySections)
			const { vin } = advertEntity

			if (shouldLoadDataFromCebia(formLineEntities)) {
				// volame Cebii i pro vybavu, at si muzeme vysledky nacacheovat
				// do dalsiho kroku. Na vysledek ale v tomto kroku nemusime cekat.
				// Vybavu nacitame dopredu jenom pro prvni pruchod vkladanim.
				this._loadEquipmentDataFromCebia(vin)

				return this._loadDataFromCebia(vin, { cache: true })
			}
		}

		const loadOperatingLeaseFieldsData = async (advertEntity) => {
			const { category: { id: categoryId } = {} } = advertEntity

			// getCreatePage si muzeme dovolit zavolat znovu, protoze data jsou nacacheovana
			const createPageData = await this._advertService.getCreatePage(
				{
					[AdvertAdminUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId
				},
				{ cache: true }
			)

			const flatCreatePageData = flattenCreatePageData(createPageData)

			const operatingLeaseDefData = flatCreatePageData.filter(({ name }) =>
				Object.values(OPERATING_LEASE_DEFINITION).includes(name)
			)

			this.setState({
				[STATE_KEYS.OPERATING_LEASE_DEFINITION_DATA]: operatingLeaseDefData
			})
		}

		const {
			[STATE_KEYS.FORM_LINES_BY_SECTION]: formLineEntitiesBySections,
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntity
		} = this.getState()

		if (!advertEntity) {
			return
		}

		// volani na Cebii, abysme mohli uzivateli predvyplnit formular.
		// Pri prechode z prveho kroku sa data z cebie beru z cache,
		// pri refreshe stranky sa vola cebia api.
		const cebiaDataPromise = loadDataFromCebia(formLineEntitiesBySections, advertEntity)

		// nacteni dat (jako volby do selectu, atd.) pro prvky na formulari
		const dataForFormLineEntitiesLoadedPromise = loadFormFieldsData(advertEntity).then(() => {
			// nacteni dat pro specialni select s modely, kde nejprve musime pockat,
			// az bude nacten seznam znacek
			loadModels(advertEntity)
		})

		Promise.all([dataForFormLineEntitiesLoadedPromise, cebiaDataPromise]).then(([_, cebiaData]) => {
			// pri zpracovani je take potreba pockat, az bude nacten seznam znacek
			const { category: { id: categoryId } = {}, vin } = advertEntity

			if (vin && cebiaData) {
				this._processDataFromCebia(cebiaData, categoryId)
			}
		})

		loadOperatingLeaseFieldsData(advertEntity)
	}

	async _refetchAllOperatingLeasesVariants() {
		const { [ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntity = {} } = this.getState()
		const { id } = advertEntity

		if (id) {
			const {
				operatingLeaseVariantEntities
			} = await this._operatingLeaseService.getOperatingLeaseVariants(
				{ advert_id: id, limit: 100 },
				{ cache: false }
			)

			this.setState({
				[STATE_KEYS.OPERATING_LEASE_VARIANTS]: operatingLeaseVariantEntities
			})
		}
	}

	async _addNewOperatingLeaseVariant(formData = {}) {
		const { [ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntity = {} } = this.getState()
		const { id } = advertEntity

		if (id) {
			await this._operatingLeaseService.addOperatingLeaseVariant(
				Object.assign({ advert_id: id }, formData)
			)

			await this._refetchAllOperatingLeasesVariants()
		}
	}

	async _editOperatingLeaseVariant(variantToEdit = {}) {
		const { id } = variantToEdit

		if (id) {
			await this._operatingLeaseService.editOperatingLeaseVariant(variantToEdit)

			await this._refetchAllOperatingLeasesVariants()
		}
	}

	async _deleteOperatingLeaseVariant(variantToDelete = {}) {
		const { id } = variantToDelete

		if (id) {
			await this._operatingLeaseService.deleteOperatingLeaseVariant(id)

			await this._refetchAllOperatingLeasesVariants()
		}
	}

	async _fillPhone({ phone: advertEntityPhone } = {}, elements) {
		const { [ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise } = this.getState()

		const element = elements.find((e) => e.id === FORM_LINES_IDS.PHONE)
		const PHONE_LENGTH = 9

		const trimmedPhone = (phone) => {
			return phone.length > PHONE_LENGTH ? phone.replace(/^(\+?420)/, '') : phone
		}

		const fillPhone = (phone) => {
			const flattenFormLineEntities = this._getFlattenFormLineEntities()

			const formLineEntity = flattenFormLineEntities.find((f) => f.id === FORM_LINES_IDS.PHONE)

			if (formLineEntity) {
				return {
					id: FORM_LINES_IDS.PHONE,
					value: Object.assign({}, formLineEntity.value, {
						phone
					}),
					extra: Object.assign({}, formLineEntity.extra, {
						isPhoneValid: true
					})
				}
			}
		}

		if (isPremise) {
			const premiseData = await this._getPremiseData()

			const { premiseServices = [], phones = [] } = premiseData

			// zdroj dat pre telefon
			// ak je true tak firma ma nastavene kontakty z sauto ak false tak z Asku
			const shouldBeUsedPhoneFromSauto = premiseServices[0] && premiseServices[0].useServiceContacts

			const validPhones = phones.filter((ph) => {
				const source = shouldBeUsedPhoneFromSauto ? 'sourceLocal' : 'sourceAsk'

				return !ph.isDeleted && ph.default && ph[source]
			})

			const [phone] = validPhones

			if (phone) {
				const trimmedPhoneValue = trimmedPhone(phone.phone)

				return fillPhone(trimmedPhoneValue)
			}
		} else {
			if (!advertEntityPhone && element && element.value) {
				const { value: phone } = element

				const trimmedPhoneValue = trimmedPhone(phone)

				return fillPhone(trimmedPhoneValue)
			}
		}
	}

	async _getPremiseData() {
		const { [UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfState } = this.getState()

		const premiseId = await this._getPremiseId(userSelfState)

		const premiseData = await this._premiseService.getPremise(premiseId, {}, { cache: false })

		return premiseData
	}

	async _fillLocality() {
		const { [ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise } = this.getState()

		if (isPremise) {
			const premiseData = await this._getPremiseData()

			const { locality = {} } = premiseData

			const flattenFormLineEntities = this._getFlattenFormLineEntities()

			const formLineEntity = flattenFormLineEntities.find((f) => f.id === FORM_LINES_IDS.LOCALITY)

			if (formLineEntity) {
				return {
					id: FORM_LINES_IDS.LOCALITY,
					value: Object.assign({}, locality)
				}
			}
		}
	}

	async _fillEmail({ email: advertEntityEmail } = {}, elements) {
		const { [ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise } = this.getState()

		const fillEmail = (email) => {
			const flattenFormLineEntities = this._getFlattenFormLineEntities()

			const formLineEntity = flattenFormLineEntities.find((f) => f.id === FORM_LINES_IDS.EMAIL)

			if (formLineEntity) {
				return {
					id: FORM_LINES_IDS.EMAIL,
					value: email,
					extra: Object.assign({}, formLineEntity.extra, {
						isEmailValid: true
					})
				}
			}
		}

		if (isPremise) {
			const premiseData = await this._getPremiseData()

			const { premiseServices = [], emails = [] } = premiseData

			// zdroj dat pre email
			// ak je true tak firma ma nastavene kontakty z sauto ak false tak z Asku
			const shouldBeUsedEmailFromSauto = premiseServices[0] && premiseServices[0].useServiceContacts

			const validEmails = emails.filter((em) => {
				const source = shouldBeUsedEmailFromSauto ? em.sourceLocal : em.sourceAsk
				return !em.isDeleted && em.default && source
			})

			const [email] = validEmails

			if (email) {
				return fillEmail(email.email)
			}
		} else {
			const element = elements.find((e) => e.id === FORM_LINES_IDS.EMAIL)

			if (!advertEntityEmail && element && element.value) {
				const { value: email } = element

				return fillEmail(email)
			}
		}
	}

	async _loadModels(brandValueToLoadModelsFor) {
		const changeModelsFormLineEntity = (data) => {
			this._changeFormLineEntities([Object.assign({}, data, { id: FORM_LINES_IDS.MODEL_CB })])
		}

		if (brandValueToLoadModelsFor) {
			const formLineEntities = this._getFlattenFormLineEntities()

			const [formLineEntityForBrand, formLineEntityForModel] = getBrandAndModelsFormLineEntities(
				formLineEntities
			)

			const brand = getBrandOption(brandValueToLoadModelsFor, formLineEntityForBrand)

			if (brand) {
				changeModelsFormLineEntity({
					options: [],
					extra: Object.assign({}, formLineEntityForModel.extra, { waiting: true })
				})

				const models = await this._codebooksService.getCodebooks({
					[AdvertAdminUrlConvertor.constants.URL_API_PARAMS.PARENT_ID]: brand.id
				})

				changeModelsFormLineEntity({
					options: models,
					extra: Object.assign({}, formLineEntityForModel.extra, { waiting: false })
				})
			}
		}
	}

	_clearModels() {
		this._changeFormLineEntities([
			{
				id: FORM_LINES_IDS.MODEL_CB,
				value: undefined,
				errorMessage: undefined,
				options: []
			}
		])
	}

	async _processDataFromCebia(cebiaData = {}, categoryId) {
		if (categoryId === cebiaData[CEBIA_CATEGORY_ID]) {
			await this._loadModels(cebiaData.manufacturerCb)

			const flattenFormLineEntities = this._getFlattenFormLineEntities()

			const { processedData, failedFormLineIds } = processDataFromCebia(
				cebiaData,
				flattenFormLineEntities
			)

			this._changeFormLineEntities([
				...processedData,
				...failedFormLineIds.map((id) => {
					const failedFormLineEntity = flattenFormLineEntities.find((f) => f.id === id)

					return {
						id,
						extra: failedFormLineEntity
							? Object.assign({}, failedFormLineEntity.extra, { cebiaError: true })
							: {}
					}
				})
			])

			if (failedFormLineIds.length) {
				this._setFormStatus({
					statusCode: FORM_STATUS_CODES.DATA_FROM_VIN_FAILED,
					extra: {
						failedFieldsCount: failedFormLineIds.length
					}
				})
			} else {
				this._setFormStatus({
					statusCode: FORM_STATUS_CODES.DATA_FROM_VIN_OK
				})
			}
		} else {
			this._setFormStatus({
				statusCode: undefined
			})
		}
	}

	async _loadDataFromCebia(vin) {
		if (vin && vin.trim()) {
			// pokud byl uzivatelem zadan VIN vozidla, tak se dotazeme na API,
			// ktere nam z Cebie stahne dostupna data o vozidle (znacka,
			// model atd.). V tomto kroku zadavani noveho inzeratu
			// predvyplnime uzivateli temito daty formular.

			const cachedCebiaData = this._VINVehicleDataService.getVehicleDataCached(vin)

			if (cachedCebiaData) {
				return Promise.resolve(cachedCebiaData)
			} else {
				this._setFormStatus({
					statusCode: FORM_STATUS_CODES.CEBIA_DATA_LOADING
				})

				try {
					return await this._VINVehicleDataService.getVehicleData(vin)
				} catch (error) {
					// pokud se neco pokazi pri stahovani dat z Cebie,
					// tak i presto umoznime uzivateli pokracovat v
					// zadavani noveho inzeratu
				}
			}
		}

		return Promise.resolve({})
	}

	async _loadEquipmentDataFromCebia(vin) {
		if (vin && vin.trim()) {
			// pokud byl uzivatelem zadan VIN vozidla, tak se dotazeme na API,
			// ktere nam z Cebie stahne dostupna data o vybave vozidle (ABS,
			// klimatizace atd.). Tyto data sice tedka potrebovat nebudeme
			// (budou se hodit az na kroku Vybava), ale volanim API si je
			// nacacheujeme.
			try {
				await this._VINVehicleDataService.getVehicleEquipmentData(vin)
			} catch (error) {
				// pokud se neco pokazi pri stahovani dat z Cebie,
				// tak i presto umoznime uzivateli pokracovat v
				// zadavani noveho inzeratu
			}
		}
	}

	_changeFormLineEntities(changesParam) {
		const { [STATE_KEYS.FORM_LINES_BY_SECTION]: formLineEntitiesBySections = {} } = this.getState()

		const changes = changesParam.filter((change) => !!change)

		const updatedFormLineEntitiesBySections = Object.keys(formLineEntitiesBySections).reduce(
			(updatedSections, sectionId) => {
				const formLinesEntities = formLineEntitiesBySections[sectionId]

				const updatedChanges = clearCebiaErrorFlags(formLinesEntities, changes)

				return Object.assign({}, updatedSections, {
					[sectionId]: FormLines.updateEntities(formLinesEntities, updatedChanges)
				})
			},
			{}
		)

		this.setState({
			[STATE_KEYS.FORM_LINES_BY_SECTION]: updatedFormLineEntitiesBySections
		})
	}

	async _submitForm({ formLineEntities = [], onErrorCallback = () => {} }) {
		const {
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntity = {},
			[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelfState
		} = this.getState()

		const {
			category: { id: categoryId } = {},
			conditionCb: { value: conditionCbValue } = {},
			vin
		} = advertEntity

		// povinna data, ktera musime poslat s daty z formulare, aby
		// prosla validace na serveru
		const defaultFormData = {
			[VEHICLE_CATEGORY_API_PARAM]: categoryId,
			[VEHICLE_CONDITION_API_PARAM]: conditionCbValue,
			[VEHICLE_VIN_API_PARAM]: vin,
			[TERMS_OF_USE_API_PARAM]: true //TODO je potreba doresit s backendem, zatim posilame porad true
		}

		const enabledFormLineEntities = formLineEntities.filter(({ disabled = false }) => !disabled)

		const formData = Object.assign(
			{},
			getFormData(enabledFormLineEntities, formLineEntities),
			defaultFormData
		)

		if (await this._isPremise(userSelfState)) {
			const premiseId = await this._getPremiseId(userSelfState)
			formData[UrlConvertor.constants.URL_API_PARAMS.PREMISE_ID] = premiseId
		}

		try {
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: true
			})

			const advertId = this._getAdvertId()

			// pokud inzerat neobsahuje vyrobce, tak predpokladame,
			// ze se jedna o prvni pruchod timto krokem vkladani
			const firstRun = !advertEntity.manufacturerCb

			if (firstRun) {
				await this._advertService.putAdvert(advertId, formData)
			} else {
				// pro dalsiho pruchody timto krokem je potreba volat PATCH,
				// aby nedoslo k prepsani cele entity a tim padem i dat z dalsich
				// kroku vkladani
				await this._advertService.patchAdvert(advertId, formData)
			}

			this._onSubmitFormSuccessCallback()
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)
			let errors = []

			if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				errors = body.errors
			}

			this._onSubmitFormErrorCallback()

			onErrorCallback(errors)
		} finally {
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false
			})
		}
	}

	_setFormStatus({ statusCode, extra }) {
		this.setState({
			[STATE_KEYS.FORM_STATUS]: { statusCode, extra }
		})
	}

	_getFlattenFormLineEntities() {
		const { [STATE_KEYS.FORM_LINES_BY_SECTION]: formLineEntitiesBySections = {} } = this.getState()

		return flattenFormLineEntities(formLineEntitiesBySections)
	}

	_scrollPageToTop() {
		animateScrollTo(0)
	}
}
