
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['DetailSubheader'] = {
  backToList: function(d) { return "Zpět na výpis"; },
  backToCategory: function(d) { return "Další inzeráty " + d.BRAND + " " + d.MODEL; },
  backToSeller: function(d) { return "Zpět na prodejce"; },
  nextAdvert: function(d) { return "Následující"; },
  backToDetail: function(d) { return "Zpět na detail"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	