import { useContext } from 'react'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'

export const useHideAdvert = () => {
	const { $Router } = useContext(Context)
	const { route } = $Router.getCurrentRouteInfo()
	const currentRoute = route.getName()
	const hideAdvertRoutes = Object.values(ROUTE_NAMES.CLIENT_ADMIN)

	return hideAdvertRoutes.includes(currentRoute)
}
