
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['VideoUploader'] = {
  openerText: function(d) { return "Vybrat video"; },
  removeErrorLabel: function(d) { return "Smazat chybové hlášení"; },
  data_too_big: function(d) { return "Video soubor je příliš velký"; },
  advert_has_video: function(d) { return "Inzerát již má video"; },
  backend_error: function(d) { return "Video se nepodařilo nahrát"; },
  remove_error: function(d) { return "Video se nepodařilo smazat"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	