import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import IMAGES from 'app/base/ImagesConstants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { useLocalize } from 'app/base/componentHelpers'

import './EmptyList.less'
import './EmptyListCS.json'

const CLASSNAME = 'c-empty-list'

const EmptyImage = () => <img src={IMAGES['image__empty-list-empty']} alt='' />
const NothingFoundImage = () => <img src={IMAGES['image__empty-list-nothing-found']} alt='' />

const EmptyList = ({ renderImage, title, text, renderLink }) => {
	const localize = useLocalize()

	return (
		<div className={`${CLASSNAME}`}>
			<div className={`${CLASSNAME}__image-wrapper`}>
				{renderImage ? renderImage() : <EmptyImage />}
			</div>
			<div className={`${CLASSNAME}__title`}>
				{!isNullOrUndefined(title) ? title : localize('EmptyList.title')}
			</div>
			<div className={`${CLASSNAME}__text`}>
				{!isNullOrUndefined(text) ? text : localize('EmptyList.text')}
			</div>
			{renderLink()}
		</div>
	)
}

EmptyList.propTypes = {
	renderImage: PropTypes.func,
	title: PropTypes.string,
	text: PropTypes.string,
	renderLink: PropTypes.func
}

EmptyList.defaultProps = {
	renderLink: DEFAULT_PROPS.FUNCTION
}

EmptyList.EmptyImage = EmptyImage
EmptyList.NothingFoundImage = NothingFoundImage

export default EmptyList
