import { useState, useContext, useEffect, useRef } from 'react'
import { uuid } from 'uuidv4'
import Context from 'ima/page/context'
import ToastHelper from 'app/component/toastMessages/ToastHelper'

export const useToasts = ({ globalAutoDismissTimeout = 3000 } = {}) => {
	const [toasts, setToasts] = useState([])

	const removedToasts = useRef([])

	useEffect(() => {
		if (removedToasts.current.length) {
			removedToasts.current.map(_notifyClientAboutClose)

			removedToasts.current = []
		}
	})

	useEffect(() => {
		// - Pri componentDidMount si zjistim, jestli nemam zobrazit nejakou ulozenou toastMessage, z predchozi stranky
		if (ToastHelper.isToastShown()) {
			const messageData = ToastHelper.getToast()

			if (messageData) {
				addToast(messageData)

				ToastHelper.setToast(undefined)
			}
		}
	}, [])

	const _removeToasts = (removeWhenCallback = () => false) => {
		setToasts((toasts) => {
			const { toastToBeRemoved, toastToBeKept } = toasts.reduce(
				(result, toast) => {
					if (removeWhenCallback(toast)) {
						if (toast.autoDismissTimeoutId) {
							clearTimeout(toast.autoDismissTimeoutId)
						}
						result.toastToBeRemoved = [...result.toastToBeRemoved, toast]
					} else {
						result.toastToBeKept = [...result.toastToBeKept, toast]
					}

					return result
				},
				{ toastToBeRemoved: [], toastToBeKept: [] }
			)

			removedToasts.current = [...removedToasts.current, ...toastToBeRemoved]

			return toastToBeKept
		})
	}

	const _notifyClientAboutClose = (toast = {}) => {
		const { data: { onClose } = {} } = toast

		onClose && onClose(toast)
	}

	const addToast = ({ autoDismissTimeout, ...data }) => {
		const newToastId = uuid()

		const autoDismissTimeoutId = setTimeout(
			removeToast,
			autoDismissTimeout || globalAutoDismissTimeout,
			newToastId
		)

		const toast = {
			autoDismissTimeoutId,
			id: newToastId,
			timestamp: Date.now(),
			data
		}

		setToasts((toasts) => [...toasts, toast])
	}

	const removeToast = (toastId) => _removeToasts((toast) => toast.id === toastId)

	return {
		addToast,
		removeToast,
		toasts
	}
}

export const SHOW_NEW_TOAST_MESSAGE_EVENT = 'SHOW_NEW_TOAST_MESSAGE_EVENT'

export const useNewToastEventListener = (addToast) => {
	const { $Dispatcher } = useContext(Context)

	useEffect(() => {
		$Dispatcher.listen(SHOW_NEW_TOAST_MESSAGE_EVENT, addToast)

		return () => $Dispatcher.unlisten(SHOW_NEW_TOAST_MESSAGE_EVENT, addToast)
	}, [])
}
