import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import BasicWidget from '../../../../component/widgets/basic/BasicWidget'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import { useLocalize } from 'app/base/componentHelpers'
import LocalitySelectInput from 'app/component/localitySelectInput/LocalitySelectInput'

import './LocalitySelect.less'
import './LocalitySelectCS.json'

export const LOCALITY_SELECT_WIDGET_ID = 'locality'

const CLASSNAME = 'c-locality-select'

const LocalitySelect = (props) => {
	const { formLineEntity, onChange } = props
	const localize = useLocalize()

	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={LOCALITY_SELECT_WIDGET_ID}
			renderLabel={(formLineEntity, props) => {
				const { label, required } = formLineEntity
				const { labelId, inputId } = AutoComplete.getIdsPropsHelper(formLineEntity.id)

				return (
					<label {...props} id={labelId} htmlFor={inputId}>
						{label}
						{required && <span className='c-basic-widget__required'>*</span>}
					</label>
				)
			}}
			renderInput={(formLineEntity, props) => (
				<div className={`${CLASSNAME}__content`}>
					<LocalitySelectInput
						formLineEntity={formLineEntity}
						onChange={(data) => {
							onChange(
								Object.assign({}, data, {
									id: formLineEntity.id
								})
							)
						}}
						widgetProps={props}
					/>

					{!formLineEntity.disabled && (
						<span className={`${CLASSNAME}__privacy-statement`}>
							{localize('LocalitySelect.privacyStatement')}
						</span>
					)}
				</div>
			)}
		/>
	)
}

LocalitySelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(LocalitySelect)
