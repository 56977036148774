import AbstractNewAdvertAdminView from '../AbstractNewAdvertAdminView'
import React from 'react'
import EquipmentForm from './components/EquipmentForm'

import './EquipmentView.less'
import '../basicInfo/BasicInfoViewCS.json'

const CLASSNAME = 'p-uw-equipment'

/**
 * Stranka pro vlozeni noveho inzeratu (Vybava).
 * @class EquipmentView
 * @namespace app.page.userweb.newAdvert
 * @extends app.page.userweb.newAdvert.AbstractNewAdvertAdminView
 * @module app
 * @submodule app.page
 */
export default class EquipmentView extends AbstractNewAdvertAdminView {
	constructor(props, context) {
		super(props, context)
	}

	_getLayoutProps(defaultLayoutProps) {
		return Object.assign({}, defaultLayoutProps, {
			className: CLASSNAME
		})
	}

	_renderContent() {
		return (
			<div className={`${CLASSNAME}__content`}>
				<EquipmentForm />
			</div>
		)
	}
}
