import { AbstractFactory } from '@inzeraty/models'
import VINVehicleDataEntity from './VINVehicleDataEntity'

export default class VINVehicleDataFactory extends AbstractFactory {
	/**
	 * @constructor
	 */
	constructor() {
		super(VINVehicleDataEntity)
	}

	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformData(data) {
		return {
			additionalModelName: data.additional_model_name,
			averageGasMileage: data.average_gas_mileage,
			beds: data.beds,
			categoryCebia: data.category_cebia,
			categoryId: data.category_id,
			categoryIiztp: data.category_iiztp,
			categoryName: data.category_name,
			categoryZtp: data.category_ztp,
			colorCb: data.color_cb,
			colorName: data.color_name,
			colorNameEng: data.color_name_eng,
			countryOfOriginCb: data.country_of_origin_cb,
			countryOfOriginName: data.country_of_origin_name,
			enginePower: data.engine_power,
			engineVolume: data.engine_volume,
			equipment: data.equipment,
			firstAbroadRegistration: data.first_abroad_registration,
			fuelCb: data.fuel_cb,
			fuelCode: data.fuel_code,
			generation: data.generation,
			keyless: data.keyless,
			manufacturerCb: data.manufacturer_cb,
			manufacturerName: data.manufacturer_name,
			manufacturerNameEng: data.manufacturer_name_eng,
			manufacturingDate: data.manufacturing_date,
			modelCb: data.model_cb,
			modelName: data.model_name,
			modelNameEng: data.model_name_eng,
			placesSitting: data.places_sitting,
			placesStanding: data.places_standing,
			tradeMark: data.trade_mark,
			vehicleBodyCb: data.vehicle_body_cb,
			vehicleBodyName: data.vehicle_body_name,
			vin: data.vin,
			weight: data.weight,
			weightDrivingSet: data.weight_driving_set,
			weightEmergency: data.weight_emergency,
			ztpNote: data.ztp_note
		}
	}
}
