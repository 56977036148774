/**
 * @class CarCompare
 * @description Obsahuje data pre porovnanie modelov
 * @module models
 */
export default class CarCompareEntity {
	constructor(data) {
		this.result = data.result
		this.price = data.price
		this.priceLevels = data.priceLevels
		this.totalMatches = data.totalMatches
		this.filters = data.filters
	}
}
