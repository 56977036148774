import React from 'react'
import BasicWidget from '../basic/BasicWidget'
import { Textarea } from '@sznds/react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './TextareaWidget.less'

export const TEXTAREA_WIDGET_ID = 'textarea_widget'

const CLASSNAME = 'c-review-textarea-widget'

const TextareaWidget = ({ formLineEntity, onChange }) => {
	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={TEXTAREA_WIDGET_ID}
			onChange={(_, value) => {
				onChange({
					id: formLineEntity.id,
					value,
					errorMessage: ''
				})
			}}
			renderInput={(formLineEntity, { className, ...props }) => {
				return (
					<Textarea
						className={`${className} ${CLASSNAME}__input`}
						maxLength={300}
						disabled={Boolean(formLineEntity.disabled)}
						error={!!formLineEntity.errorMessage}
						{...props}
					/>
				)
			}}
		/>
	)
}

TextareaWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func
}

export default React.memo(TextareaWidget)
