import React from 'react'
import { DefaultProps } from '@inzeraty/helpers'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './SimpleTooltipView.less'

const CLASSNAME = 'c-simple-tooltip'

const SimpleTooltip = ({
	className = '',
	tooltipClassName = '',
	descriptionClassName = '',
	description = '',
	ariaHidden = false,
	renderContent = DefaultProps.FUNCTION,
	align = SimpleTooltip.ALIGN.LEFT
}) => {
	return (
		<div
			className={classnames({
				[className]: !!className
			})}
		>
			{renderContent(`${CLASSNAME}__content`)}
			<div
				className={classnames({
					[tooltipClassName]: !!tooltipClassName,
					[`${CLASSNAME}__description-wrap`]: true,
					[`${CLASSNAME}__description-wrap--${align}`]: true
				})}
			>
				<div
					aria-hidden={ariaHidden}
					className={classnames({
						[`${CLASSNAME}__description`]: true,
						[descriptionClassName]: !!descriptionClassName
					})}
				>
					{description}
				</div>
			</div>
		</div>
	)
}

SimpleTooltip.propTypes = {
	className: PropTypes.string,
	tooltipClassName: PropTypes.string,
	descriptionClassName: PropTypes.string,
	description: PropTypes.string,
	ariaHidden: PropTypes.bool,
	renderContent: PropTypes.func,
	align: PropTypes.string
}

SimpleTooltip.ALIGN = Object.freeze({
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right'
})

export default SimpleTooltip
