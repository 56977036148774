import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'
import { useLocalize } from 'app/base/componentHelpers'
import Tooltip from 'app/page/userweb/newAdvert/component/tooltip/Tooltip'
import { FORM_LINES_IDS } from '../../BasicInfoFormLineIds'
import IMAGES from 'app/base/ImagesConstants'

import './BasicInfoTooltip.less'
import './BasicInfoTooltipCS.json'

const CLASSNAME = 'c-basic-info-tooltip'

const BasicInfoTooltip = (props) => {
	const localize = useLocalize()

	const { formLineEntity = {} } = props
	const { id, label } = formLineEntity

	const getTooltipProps = () => {
		const defaultProps = {
			title: label
		}

		const renderContent = (description, imgUrl) => (
			<div className={`${CLASSNAME}__content`}>
				{description && (
					<div
						className={classnames({
							[`${CLASSNAME}__margin-bottom`]: !!imgUrl
						})}
					>
						{description}
					</div>
				)}

				{imgUrl && <img src={imgUrl} className={`${CLASSNAME}__picture`} alt='' />}
			</div>
		)

		if (id === FORM_LINES_IDS.MANUFACTURER_CB) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__manufacturer'])
			})
		} else if (id === FORM_LINES_IDS.MODEL_CB) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__model'])
			})
		} else if (id === FORM_LINES_IDS.ADDITIONAL_MODEL_NAME) {
			return Object.assign({}, defaultProps, {
				children: renderContent(
					localize('BasicInfoTooltip.additionalModelTooltipNote'),
					IMAGES['image__new-item__additional-model-name']
				)
			})
		} else if (id === FORM_LINES_IDS.COLOR_AND_TONE_CB) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__color'])
			})
		} else if (id === FORM_LINES_IDS.FUEL_CB) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__fuel'])
			})
		} else if (id === FORM_LINES_IDS.ENGINE_VOLUME) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__engine-value'])
			})
		} else if (id === FORM_LINES_IDS.ENGINE_POWER) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__engine-power'])
			})
		} else if (id === FORM_LINES_IDS.AVERAGE_GAS_MILEAGE) {
			return Object.assign({}, defaultProps, {
				children: renderContent(localize('BasicInfoTooltip.fuelConsumptionTooltipNote'))
			})
		} else if (id === FORM_LINES_IDS.WEIGHT || id === FORM_LINES_IDS.LOAD_CAPACITY) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__weight'])
			})
		} else if (id === FORM_LINES_IDS.IN_OPERATION_DATE) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__in-operation-date'])
			})
		} else if (id === FORM_LINES_IDS.GEARBOX_CB) {
			return Object.assign({}, defaultProps, {
				children: renderContent(undefined, IMAGES['image__new-item__gearbox'])
			})
		} else if (id === FORM_LINES_IDS.PRICE_NOTE) {
			return Object.assign({}, defaultProps, {
				children: renderContent(localize('BasicInfoTooltip.priceNoteTooltip'))
			})
		}
	}

	const tooltipProps = getTooltipProps()

	if (tooltipProps) {
		return <Tooltip elementId={id} tooltipProps={tooltipProps} />
	} else {
		return null
	}
}

BasicInfoTooltip.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired
}

export default React.memo(BasicInfoTooltip)
