import React, { useContext } from 'react'
import ImaContext from 'ima/page/context'
import PaymentInfo from 'app/page/userweb/newAdvert/payment/components/PaymentInfo'
import AdvertPreview from 'app/page/userweb/newAdvert/payment/components/advertPreview/AdvertPreview'
import TermsAndConditions from 'app/page/userweb/newAdvert/payment/components/termsAndConditions/TermsAndConditions'
import NavButton, { BUTTON_TYPE } from 'app/component/navButton/NavButtonView'
import RouteNames from 'app/base/RouteNames'
import { useLocalize } from 'app/base/componentHelpers'
import { Button } from '@sznds/react'
import PropTypes from 'prop-types'
import STATE_KEYS from './ToppedPaymentStateKeys'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractNewAdvertAdminView from 'app/page/userweb/newAdvert/AbstractNewAdvertAdminView'

import './ToppedPaymentCS.json'
import './ToppedPaymentView.less'

const CLASSNAME = 'c-topped-payment'

export default class ToppedPaymentView extends AbstractNewAdvertAdminView {
	constructor(props, context) {
		super(props, context)
	}

	_getLayoutProps(defaultLayoutProps) {
		return defaultLayoutProps
	}

	_renderSubheader() {
		return null
	}

	_renderContent() {
		return (
			<div className={`${CLASSNAME}__content`}>
				<ToppedPayment {...this.props} />
			</div>
		)
	}
}

const ToppedPayment = ({
	[STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED]: conditionsChecked = false,
	[STATE_KEYS.PAYMENT_TYPE]: paymentType,
	[STATE_KEYS.HANDLE_CREDIT_CARD_PAYMENT]: handleCreditCardPayment = DEFAULT_PROPS.FUNCTION,
	[STATE_KEYS.HANDLE_WALLET_PAYMENT]: handleWalletPayment = DEFAULT_PROPS.FUNCTION
}) => {
	const localize = useLocalize()
	const context = useContext(ImaContext)
	const router = context.$Router

	const backToAdvertListUrl = router.link(RouteNames.CLIENT_ADMIN.CLIENT_ADVERT_LIST)

	const getButtonText = () => {
		if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_CREDIT_CARD) {
			return localize('ToppedPayment.goToPayGate')
		} else if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET) {
			return localize('ToppedPayment.payWithWallet')
		}
	}

	const renderSubmitButton = () => {
		return (
			<Button
				primary={true}
				disabled={!conditionsChecked}
				onClick={(event) => {
					event.stopPropagation()
					if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_CREDIT_CARD) {
						handleCreditCardPayment()
					} else if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET) {
						handleWalletPayment()
					}
				}}
				text={getButtonText()}
				className={`${CLASSNAME}__btn-submit`}
			/>
		)
	}

	return (
		<div className={CLASSNAME}>
			<NavButton
				text={localize('ToppedPayment.backToAdvertListUrl')}
				url={backToAdvertListUrl}
				type={BUTTON_TYPE.BACK}
			/>
			<div className={`${CLASSNAME}__desktop`}>
				<div className={`${CLASSNAME}__payment`}>
					<PaymentInfo isForTopped={true} />
					<TermsAndConditions />
				</div>
				<AdvertPreview showCard={true} />
			</div>

			<div className={`${CLASSNAME}__btn-submit-desktop`}>{renderSubmitButton()}</div>

			<div className={`${CLASSNAME}__mobile`}>
				<PaymentInfo isForTopped={true} />
				<TermsAndConditions />

				<div className={`${CLASSNAME}__mobile-nav`}>
					<AdvertPreview showCard={false} className={`${CLASSNAME}__preview-button`} />
					{renderSubmitButton()}
				</div>
			</div>
		</div>
	)
}

ToppedPayment.propTypes = {
	[STATE_KEYS.TERMS_AND_CONDITIONS_CHECKED]: PropTypes.bool,
	[STATE_KEYS.PAYMENT_TYPE]: PropTypes.string,
	[STATE_KEYS.HANDLE_CREDIT_CARD_PAYMENT]: PropTypes.func,
	[STATE_KEYS.HANDLE_WALLET_PAYMENT]: PropTypes.func
}
