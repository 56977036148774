import React from 'react'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import { Button } from '@sznds/react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import ReviewsExtension from '../ReviewsExtension'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import './ReviewFeedback.less'
import './ReviewFeedbackCS.json'
import 'app/base/AssignEmailReasonsCS.json'

const CLASSNAME = 'c-review-feedback'

const ReviewFeedback = React.memo(
	({
		className,
		negativeCount,
		positiveCount,
		userFeedbackType = ReviewsExtension.USER_FEEDBACK_TYPE.NONE,
		disabled = false,
		onClick = DEFAULT_PROPS.FUNCTION
	}) => {
		const localize = useLocalize()
		const fire = useFire()

		const likeIcon = {
			d:
				'm19 8c-2.088 0-4.047 0.244-5.724 0.56 0.548-0.905 1.724-3.025 1.724-4.56 0-1-0.593-3-2-3-0.563 0-0.968 1.019-1.618 2.722-0.651 1.704-2.169 4.481-3.382 6.278v9c0 1.064 1 2 2 2h4c4.094 0 7-6.154 7-10 0-2-0.729-3-2-3zm-13 13h-2c-0.553 0-1-0.447-1-1v-9c0-0.553 0.447-1 1-1h3v10c0 0.553-0.447 1-1 1z',
			size: 24
		}

		const renderButtons = () => {
			const types = [
				ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE,
				ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE
			]

			return <>{types.map((type) => renderButton(type))}</>
		}

		const renderButton = (type) => {
			let count = '…'
			let isActive = false
			let emlTitle = ''

			if (
				type === ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE &&
				Number.isInteger(positiveCount)
			) {
				count = Format.number(positiveCount)
				isActive = userFeedbackType === ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE
				emlTitle = isActive
					? localize('ReviewFeedback.btnUnmarkAsPositive')
					: localize('ReviewFeedback.btnMarkAsPositive')
			} else if (
				type === ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE &&
				Number.isInteger(negativeCount)
			) {
				count = Format.number(negativeCount)
				isActive = userFeedbackType === ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE
				emlTitle = isActive
					? localize('ReviewFeedback.btnUnmarkAsNegative')
					: localize('ReviewFeedback.btnMarkAsNegative')
			}

			return (
				<Button
					key={type}
					className={classnames({
						[`${CLASSNAME}__button`]: true,
						[`${CLASSNAME}__button--active`]: isActive,
						[`${CLASSNAME}__button--${type}`]: true,
						[`${CLASSNAME}__button--disabled`]: disabled
					})}
					surface={3}
					icon={likeIcon}
					text={count}
					size='x-small'
					onClick={() => {
						onRate(type)
					}}
					title={emlTitle}
					disabled={disabled}
					data-e2e={
						type === ReviewsExtension.USER_FEEDBACK_TYPE.POSITIVE
							? 'positive-review-feedback-button'
							: type === ReviewsExtension.USER_FEEDBACK_TYPE.NEGATIVE
							? 'negative-review-feedback-button'
							: ''
					}
				/>
			)
		}

		const onRate = (type) => {
			fire('checkIsUserLogged', {
				reason: localize('AssignEmailReasons.reviewRate'),
				onLogin: () => {
					onClick(type)
				},
				sourceComponent: LoginHelper.SOURCE_COMPONENT.REVIEW_LIKE
			})
		}

		return (
			<div
				className={classnames({
					[className]: !!className,
					[CLASSNAME]: true
				})}
			>
				{renderButtons()}
			</div>
		)
	}
)

ReviewFeedback.TYPE = Object.freeze({
	POSITIVE: 'positive',
	NEGATIVE: 'negative'
})

ReviewFeedback.propTypes = {
	className: PropTypes.string,
	negativeCount: PropTypes.number,
	positiveCount: PropTypes.number,
	userFeedbackType: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired
}

export default ReviewFeedback
