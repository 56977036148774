import { CATEGORIES } from 'app/base/Constants'
import isNullOrUndefined from '../isNullOrUndefined/IsNullOrUndefined'

/**
 * Tabulka rozmezí cen, na které cílíme.
 * @constant
 * @type {Array<Object>}
 */
const PRICE_INTERVALS = [
	{ from: 0, to: 50000 },
	{ from: 50000, to: 100000 },
	{ from: 100001, to: 150000 },
	{ from: 150001, to: 200000 },
	{ from: 200001, to: 250000 },
	{ from: 250001, to: Number.MAX_SAFE_INTEGER }
]

/**
 * Tabulka rozmezí cen osobních vozů, na které cílíme.
 * @constant
 * @type {Array<Object>}
 */
const PRICE_INTERVALS_PASSENGER_CARS = [
	{ from: 0, to: 50000 },
	{ from: 50001, to: 100000 },
	{ from: 100001, to: 200000 },
	{ from: 200001, to: Number.MAX_SAFE_INTEGER }
]

/**
 * Tabulka rozmezí pro zaokrouhlení vlastních hodnot cen vozů
 * @constant
 * @type {Array<Object>}
 */
const PRICE_INTERVALS_TO_ROUND_CUSTOM_VALUES = [
	{ MIN: 0, MAX: 50000, ROUNDED: 50000 },
	{ MIN: 50001, MAX: 150000, ROUNDED: 100000 },
	{ MIN: 150001, MAX: 250000, ROUNDED: 200000 },
	{ MIN: 250001, MAX: 350000, ROUNDED: 300000 },
	{ MIN: 350001, MAX: 450000, ROUNDED: 400000 },
	{ MIN: 450001, MAX: Number.MAX_SAFE_INTEGER, ROUNDED: 500000 }
]

/**
 * Tabulka rozmezí nájezdů, na které cílíme.
 * @constant
 * @type {Array<Object>}
 */
const TACHOMETER_INTERVALS = [
	{ from: 0, to: 30000 },
	{ from: 30001, to: 100000 },
	{ from: 100001, to: 150000 },
	{ from: 150001, to: 200000 },
	{ from: 200001, to: Number.MAX_SAFE_INTEGER }
]

/**
 * Tabulka rozmezí nájezdů osobních vozů, na které cílíme.
 * @constant
 * @type {Array<Object>}
 */
const TACHOMETER_INTERVALS_PASSENGER_CARS = [
	{ from: 0, to: 100000 },
	{ from: 100001, to: 150000 },
	{ from: 150001, to: 200000 },
	{ from: 200001, to: Number.MAX_SAFE_INTEGER }
]

/**
 * Tabulka rozmezí pro zaokrouhlení vlastních hodnot nájezdů
 * @constant
 * @type {Array<Object>}
 */
const TACHOMETER_INTERVALS_TO_ROUND_CUSTOM_VALUES = [
	{ MIN: 0, MAX: 50000, ROUNDED: 50000 },
	{ MIN: 50001, MAX: 150000, ROUNDED: 100000 },
	{ MIN: 150001, MAX: 200000, ROUNDED: 150000 },
	{ MIN: 200001, MAX: 250000, ROUNDED: 200000 },
	{ MIN: 250001, MAX: 300000, ROUNDED: 300000 },
	{ MIN: 300001, MAX: Number.MAX_SAFE_INTEGER, ROUNDED: 400000 }
]

/**
 * Tabulka rozmezí stáří vozu, na které cílíme.
 * @constant
 * @type {Array<Object>}
 */
const AGE_INTERVALS = [
	{ from: 0, to: 2 },
	{ from: 3, to: 4 },
	{ from: 5, to: 8 },
	{ from: 9, to: Number.MAX_SAFE_INTEGER }
]

/**
 * Tabulka rozmezí stáří osobních vozů, na které cílíme.
 * @constant
 * @type {Array<Object>}
 */
const AGE_INTERVALS_PASSENGER_CARS = [
	{ from: 0, to: 3 },
	{ from: 4, to: 6 },
	{ from: 7, to: 10 },
	{ from: 11, to: Number.MAX_SAFE_INTEGER }
]

/**
 * Prefixy jednotlivých hodnot, které se posílají v collocation.
 * @constant
 * @type {Object}
 */
const COLLOCATION_PREFIXES = {
	PRICE: 'price',
	FUEL: 'fuel',
	BODY: 'body',
	AGE: 'age',
	TACHOMETER: 'tachometr'
}

/**
 * Tabulka rozmezí pro zaokrouhlení vlastních hodnot stáří vozů
 * @constant
 * @type {Array<Object>}
 */
const AGE_INTERVALS_TO_ROUND_CUSTOM_VALUES = [
	{ MIN: 0, MAX: 3, ROUNDED: 3 },
	{ MIN: 4, MAX: 6, ROUNDED: 6 },
	{ MIN: 7, MAX: 9, ROUNDED: 9 },
	{ MIN: 10, MAX: 12, ROUNDED: 12 },
	{ MIN: 13, MAX: 15, ROUNDED: 15 },
	{ MIN: 16, MAX: Number.MAX_SAFE_INTEGER, ROUNDED: 16 }
]

const _getRoundedValue = (valueToRound, table) => {
	for (let LEVEL of table) {
		if (valueToRound >= LEVEL.MIN && valueToRound <= LEVEL.MAX) {
			return LEVEL.ROUNDED
		}
	}
	return valueToRound
}

const _isCustomValue = (valueToCheck, intervalTable) => {
	if (!_isValidCollocationValue(valueToCheck)) {
		return false
	}
	for (let level of intervalTable) {
		if (level.from === valueToCheck || level.to === valueToCheck) {
			return false
		}
	}
	return true
}

const _isValidCollocationValue = (value) => {
	if (isNaN(value)) {
		return false
	}
	if (value === 0) {
		return false
	}
	return !isNullOrUndefined(value)
}

const _getCollocationByInterval = (valueFrom, valueTo, intervalTable, roundTable, prefix) => {
	let numericFrom = parseInt(valueFrom)
	let numericTo = parseInt(valueTo)
	const isNumericFromValid = _isValidCollocationValue(numericFrom)
	const isNumericToValid = _isValidCollocationValue(numericTo)
	const isNumericFromCustom = _isCustomValue(numericFrom, intervalTable)
	const isNumericToCustom = _isCustomValue(numericTo, intervalTable)

	if (isNumericFromValid && isNumericToValid) {
		if (isNumericFromCustom) {
			numericFrom = _getRoundedValue(numericFrom, roundTable)
		}
		if (isNumericToCustom) {
			numericTo = _getRoundedValue(numericTo, roundTable)
		}

		return `${prefix}-from${numericFrom}to${numericTo}`
	} else if (isNumericFromValid && !isNumericToValid) {
		if (isNumericFromCustom) {
			numericFrom = _getRoundedValue(numericFrom, roundTable)
		}

		return `${prefix}-from${numericFrom}`
	} else if (!isNumericFromValid && isNumericToValid) {
		if (isNumericToCustom) {
			numericTo = _getRoundedValue(numericTo, roundTable)
		}

		return `${prefix}-to${numericTo}`
	}
	return ''
}

export const _getAgeInterval = (yearFrom, yearTo) => {
	const actualYear = new Date().getFullYear()
	const ageFrom = yearTo ? actualYear - yearTo : undefined
	const ageTo = yearFrom ? actualYear - yearFrom + 1 : undefined
	return { ageFrom, ageTo }
}

/**
 * Vrátí collocations pro palivo, karoserii, cenu, nájezd a stáří.
 *
 * @private
 * @method sspCollocationParams
 * @param {String}                  param.fuels          Řetězec seo názvů paliva oddělených čárkou (,).
 * @param {String}                  param.vehicleBodies  Řetězec seo názvů karoserií oddělených čárkou (,).
 * @param {Number|String|undefined} param.priceFrom      Cena od.
 * @param {Number|String|undefined} param.priceTo        Cena do.
 * @param {Number|String|undefined} param.tachometerFrom Nájezd od.
 * @param {Number|String|undefined} param.tachometerTo   Nájezd do.
 * @param {Number|String|undefined} param.yearFrom       Rok výroby od.
 * @param {Number|String|undefined} param.yearTo         Rok výroby do.
 * @param {String|undefined}     params.categorySeoName  SEO kategorie.
 * @return {Array<String>} Pole collocations.
 */
const sspCollocationParams = ({
	fuels,
	vehicleBodies,
	priceFrom,
	priceTo,
	tachometerFrom,
	tachometerTo,
	yearFrom,
	yearTo,
	categorySeoName
}) => {
	let collocationParams = []

	const isPassengerCar = categorySeoName === CATEGORIES.PASSENGER_CARS.seoName

	// Nastavení paliva
	if (typeof fuels !== 'undefined') {
		fuels
			.split(',')
			.forEach((fuel) => collocationParams.push(`${COLLOCATION_PREFIXES.FUEL}-${fuel}`))
	}

	// Nastavení karoserie
	if (typeof vehicleBodies !== 'undefined') {
		vehicleBodies
			.split(',')
			.forEach((vehicleBody) =>
				collocationParams.push(`${COLLOCATION_PREFIXES.BODY}-${vehicleBody}`)
			)
	}

	// Nastavení ceny
	const priceIntervals = isPassengerCar ? PRICE_INTERVALS_PASSENGER_CARS : PRICE_INTERVALS
	const priceCollocation = _getCollocationByInterval(
		priceFrom,
		priceTo,
		priceIntervals,
		PRICE_INTERVALS_TO_ROUND_CUSTOM_VALUES,
		COLLOCATION_PREFIXES.PRICE
	)
	collocationParams = collocationParams.concat(priceCollocation)

	// Nastavení nájezdu
	const tachometerIntervals = isPassengerCar
		? TACHOMETER_INTERVALS_PASSENGER_CARS
		: TACHOMETER_INTERVALS
	const tachometerCollocation = _getCollocationByInterval(
		tachometerFrom,
		tachometerTo,
		tachometerIntervals,
		TACHOMETER_INTERVALS_TO_ROUND_CUSTOM_VALUES,
		COLLOCATION_PREFIXES.TACHOMETER
	)
	collocationParams = collocationParams.concat(tachometerCollocation)

	// Nastavení stáří
	const ageIntervals = isPassengerCar ? AGE_INTERVALS_PASSENGER_CARS : AGE_INTERVALS
	const { ageFrom, ageTo } = _getAgeInterval(yearFrom, yearTo)
	const ageCollocation = _getCollocationByInterval(
		ageFrom,
		ageTo,
		ageIntervals,
		AGE_INTERVALS_TO_ROUND_CUSTOM_VALUES,
		COLLOCATION_PREFIXES.AGE
	)
	collocationParams = collocationParams.concat(ageCollocation)

	return collocationParams
}

export default sspCollocationParams
