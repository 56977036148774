import React from 'react'
import PropTypes from 'prop-types'
import { CategoryEntity } from '@inzeraty/models'
import AbstractComponent from 'app/base/AbstractComponent'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import { Surface } from '@sznds/react'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import select from 'ima-plugin-select'
import { CAR_TYPES } from 'app/page/userweb/home/carTypes/CarTypesConstants'
import HPTitle from '../hpTitle/HPTitle'

import 'app/base/BaseCS.json'
import './CarTypes.less'
import './CarTypesCS.json'

const CLASSNAME = 'c-car-types'

class CarTypes extends AbstractComponent {
	static get propTypes() {
		return {
			selectedCategory: PropTypes.instanceOf(CategoryEntity)
		}
	}

	constructor(props, context) {
		super(props, context)
	}

	render() {
		const { selectedCategory } = this.props
		const { id: selectedCategoryId, seoName: categorySeoName } = selectedCategory

		const carTypes = CAR_TYPES.filter((carType) => carType.categoryId === selectedCategoryId)

		if (carTypes.length) {
			return (
				<div className={CLASSNAME} data-dot='car-types-selection'>
					<HPTitle>{this.localize(`CarTypes.title-${categorySeoName}`)}</HPTitle>
					<ul className={`${CLASSNAME}__list`}>
						{carTypes.map((carType, index) => this._renderCarType(carType, index))}
					</ul>
				</div>
			)
		} else {
			return null
		}
	}

	_renderCarType(carType, index) {
		const { type, imgProps, filters } = carType

		const { selectedCategory } = this.props

		const url = this.link(ROUTE_NAMES.USERWEB.ADVERT_LIST, {
			...filters,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: selectedCategory.seoName
		})

		return (
			<li className={`${CLASSNAME}__item`} key={type}>
				<Surface
					tagName='a'
					surface={4}
					className={`${CLASSNAME}__link`}
					href={url}
					data-dot='item'
					data-dot-data={`{"order": "${index + 1}"}`}
					onClick={() => this.fire('resetCache')}
				>
					{this._renderImage(imgProps)}
					<span className={`${CLASSNAME}__link-texts`}>
						<span className={`${CLASSNAME}__link-title`}>
							{this.localize(`CarTypes.type-${type}`, { CURRENCY: this.localize('Base.CZK') })}
						</span>
					</span>
				</Surface>
			</li>
		)
	}

	_renderImage({ src, sourceWebpProps, sourceJpgProps }) {
		return (
			<div className={`${CLASSNAME}__img-wrap`}>
				<picture>
					<source {...sourceWebpProps} />
					<source {...sourceJpgProps} />
					<img src={src} className={`${CLASSNAME}__img`} alt='' />
				</picture>
			</div>
		)
	}
}

const CarTypesSelector = (state) => ({
	selectedCategory: state[FiltersExtension.stateIds.CATEGORY]
})

export default select(CarTypesSelector)(CarTypes)
