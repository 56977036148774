export const isBrandAndModelExistForModification = async (
	catalogService,
	brandSeoName,
	modelSeoName
) => {
	const brands = await catalogService.loadAllBrands()

	const sautoBrand = brands.find((brand) => brand.seoName === brandSeoName)

	if (sautoBrand) {
		const models = await catalogService.loadAllModelsForBrand(sautoBrand.id)

		const sautoModelExist = models.some((model) => model.seoName === modelSeoName)

		return sautoModelExist
	} else {
		return false
	}
}

export const getBrandModelSeoName = (seoName) => {
	const splitSeoName = seoName.split('-')
	const nameWithoutNumber = splitSeoName.slice(1)

	return nameWithoutNumber.join('-')
}

export const getMinMaxYears = (modifications = []) => {
	const actualYear = new Date().getFullYear()

	const years = modifications
		.reduce((acc, modification) => {
			const { yearStart, yearEnd } = modification

			const yearTo = yearEnd ? yearEnd : actualYear

			return acc.concat(yearStart, yearTo)
		}, [])
		.filter((year) => !!year)

	const min = Math.min(...years)
	const max = Math.max(...years)

	return {
		min,
		max
	}
}

export const getPowerKwRange = (powerKw) => {
	const RANGE_CONSTANT = 3

	const powerKwFrom = powerKw ? powerKw - RANGE_CONSTANT : undefined
	const powerKwTo = powerKw ? powerKw + RANGE_CONSTANT : undefined

	return {
		powerKwFrom,
		powerKwTo
	}
}

export const getEngineVolumeRange = (volume) => {
	const RANGE_CONSTANT = 100

	const engineVolumeFrom = parseInt(volume)
	const engineVolumeTo = engineVolumeFrom ? engineVolumeFrom + RANGE_CONSTANT : undefined

	return {
		engineVolumeFrom,
		engineVolumeTo
	}
}
