import PropTypes from 'prop-types'

const carComparePropTypes = {
	result: PropTypes.string,
	price: PropTypes.number,
	priceLevels: PropTypes.arrayOf(
		PropTypes.shape({
			priceFrom: PropTypes.number,
			priceTo: PropTypes.number,
			priceType: PropTypes.string
		})
	),
	filters: PropTypes.shape({
		category: PropTypes.number,
		condition: PropTypes.array,
		enginePowerFrom: PropTypes.number,
		enginePowerTo: PropTypes.number,
		model: PropTypes.array,
		tachometerFrom: PropTypes.number,
		tachometerTo: PropTypes.number,
		vehicleAgeFrom: PropTypes.number,
		vehicleAgeTo: PropTypes.number,
		vehicleBody: PropTypes.number
	})
}

export default carComparePropTypes
