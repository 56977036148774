import { AbstractFactory } from '@inzeraty/models'
import RibbonSearchEntity from './RibbonSearchEntity'

export default class RibbonSearchFactory extends AbstractFactory {
	constructor() {
		super(RibbonSearchEntity)
	}

	transformData(data = {}) {
		return {
			categories: data.categories,
			sentence: data.sentence,
			manufacturerCb: data.manufacturer_cb,
			modelCb: data.model_cb
		}
	}
}
