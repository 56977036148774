import { FORM_LINES_IDS } from './ReportFormLineConstants'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './ReportCS.json'

export const getErrorDictionary = (localize) => ({
	[FORM_LINES_IDS.USER_EMAIL]: {
		[ERROR_CODES.REQUIRED]: localize('Report.user_email_required'),
		[ERROR_CODES.NOT_VALID]: localize('Report.user_email_not_valid'),
		[ERROR_CODES.UNKNOWN]: localize('Report.user_email_not_valid')
	},
	[FORM_LINES_IDS.REPORT_REASON]: {
		[ERROR_CODES.REQUIRED]: localize('Report.report_reason_required'),
		[ERROR_CODES.NOT_VALID]: localize('Report.value_not_valid'),
		[ERROR_CODES.UNKNOWN]: localize('Report.value_not_valid')
	},
	[FORM_LINES_IDS.REPORT_MESSAGE]: {
		[ERROR_CODES.REQUIRED]: localize('Report.report_message_required'),
		[ERROR_CODES.NOT_VALID]: localize('Report.value_not_valid'),
		[ERROR_CODES.UNKNOWN]: localize('Report.value_not_valid')
	}
})
