import ArticlesUrlConvertor from './ArticlesUrlConvertor'

export default class ArticlesMeta {
	static getMetas(routeParams, dictionary) {
		const { [ArticlesUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: category } = routeParams
		const metas = {
			title: '',
			description: '',
			header: ''
		}

		if (typeof category === 'undefined') {
			metas.title = dictionary.get('Articles.titleAll')
			metas.description = dictionary.get('Articles.descriptionAll')
			metas.header = dictionary.get('Articles.headerAll')
		} else {
			switch (category) {
				case ArticlesUrlConvertor.categories.NEWS:
					metas.title = dictionary.get('Articles.titleNews')
					metas.description = dictionary.get('Articles.descriptionNews')
					metas.header = dictionary.get('Articles.headerNews')
					break
				case ArticlesUrlConvertor.categories.TESTS:
					metas.title = dictionary.get('Articles.titleTests')
					metas.description = dictionary.get('Articles.descriptionTests')
					metas.header = dictionary.get('Articles.headerTests')
					break
				case ArticlesUrlConvertor.categories.TIPS:
					metas.title = dictionary.get('Articles.titleTips')
					metas.description = dictionary.get('Articles.descriptionTips')
					metas.header = dictionary.get('Articles.headerTips')
					break
			}
		}

		return metas
	}
}
