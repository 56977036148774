import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import AbstractComponent from 'app/base/AbstractComponent'
import FilterConstants from 'app/model/filter/FilterConstants'
import MultioptionForm from 'app/component/filters/forms/multioption/Multioption'
import Equipment from 'app/component/filters/forms/equipment/Equipment'

import './MultioptionInlineForm.less'

const ID_PREFIX = 'cf-inline-form-'

const CLASSNAME = 'cf-multioption-inline-form'

const DEF_PROP_FORM_LINE_ENTITY = {}

export default class MultioptionInlineForm extends AbstractComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity)
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEF_PROP_FORM_LINE_ENTITY
		}
	}

	render() {
		const { formLineEntity } = this.props
		const { id } = formLineEntity

		return (
			<div id={ID_PREFIX + id} data-dot='filter' data-dot-data={`{"type": "${formLineEntity.id}"}`}>
				{id === FilterConstants.formLineIds.EQUIPMENT ? (
					<Equipment className={CLASSNAME} {...this.props} />
				) : (
					<MultioptionForm className={CLASSNAME} {...this.props} />
				)}
			</div>
		)
	}
}
