import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Checkbox } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './CheckboxWidget.less'

const CLASSNAME = 'ca-checkbox-widget'

const CheckboxWidget = ({ className, formLineEntity, changeFormLineEntities, dataDotText }) => {
	const { id, label, value: checked = false } = formLineEntity

	const _handleOnChange = (event) => {
		event.stopPropagation()

		changeFormLineEntities([
			{
				id,
				value: event.target.checked
			}
		])
	}

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
			data-dot={dataDotText}
			data-dot-data={`{"checked": "${!checked}"}`}
		>
			<Checkbox label={label} checked={checked} onChange={_handleOnChange} />
		</div>
	)
}

CheckboxWidget.propTypes = {
	className: PropTypes.string,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	changeFormLineEntities: PropTypes.func,
	dataDotText: PropTypes.string
}

CheckboxWidget.defaultProps = {
	formLineEntity: DEFAULT_PROPS.OBJECT,
	changeFormLineEntities: DEFAULT_PROPS.FUNCTION
}

export default CheckboxWidget
