import React from 'react'
import AbstractOpener from '../AbstractOpener'

export default class MultioptionOpener extends AbstractOpener {
	static get propTypes() {
		return AbstractOpener.propTypes
	}

	static get defaultProps() {
		return AbstractOpener.defaultProps
	}

	_renderOpener() {
		return <React.Fragment>{super._renderOpener()}</React.Fragment>
	}

	_getTextAndHasValue() {
		const { formLineEntity } = this.props
		const { label } = formLineEntity

		let hasValue = false
		let text = this._getText()

		if (text) {
			hasValue = true
		} else {
			text = label
			hasValue = false
		}

		return {
			text,
			hasValue
		}
	}

	_getText() {
		const { formLineEntity } = this.props
		const { value } = formLineEntity
		const inputTextArr = []
		let inputText = ''

		if (value && value.size) {
			value.forEach((val) => {
				inputTextArr.push(val)
			})

			inputText = inputTextArr.filter((val) => val).join(', ')
		}

		return inputText
	}
}
