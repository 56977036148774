import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from '@sznds/react'
import { ADD_SQUIRCLE_FILLED_16 } from '@sznds/icons'
import * as FormLines from '@inzeraty/form-lines'
import { CategoryEntity } from '@inzeraty/models'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import TextLink from 'app/component/textLink/TextLink'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import SelectedBrand from './selectedBrand/SelectedBrand'
import AllBrandsSearch from './allBrandsSearch/AllBrandsSearch'

import './AdvertListBrandModelOpener.less'
import './AdvertListBrandModelOpenerCS.json'

const CLASSNAME = 'c-item-list-brand-model-opener'

const MAX_FAVORITE_BRANDS_TO_DISPLAY = 6

const AdvertListBrandModelOpener = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		categoryEntity = DEFAULT_PROPS.OBJECT,
		changeFilter = DEFAULT_PROPS.FUNCTION,
		advertsCountForBrands = DEFAULT_PROPS.OBJECT,
		advertsCountForModels = DEFAULT_PROPS.OBJECT,
		getSortedFavoriteBrands = DEFAULT_PROPS.FUNCTION,
		increaseFavoriteBrandHit = DEFAULT_PROPS.FUNCTION
	} = props

	const localize = useLocalize()

	const [showAddBrandButton, setShowAddBrandButton] = useState(true)
	const [showBrandPopup, setShowBrandPopup] = useState(false)

	const renderFavoriteBrands = () => {
		const selectBrand = (brandValue) => {
			const { id, value = new Map() } = formLineEntity

			changeFilter(id, new Map([...value, [brandValue, new Set()]]))
		}

		const { options = [] } = formLineEntity

		const favoriteBrands =
			getSortedFavoriteBrands(options).slice(0, MAX_FAVORITE_BRANDS_TO_DISPLAY) || []

		return (
			<div className={`${CLASSNAME}__favorite-brands`}>
				{favoriteBrands.map((brand, index) => {
					const { value, seoName, name } = brand

					return (
						<div
							key={value}
							data-dot='show-values'
							data-dot-data={`{"type": "manufacturer-top", "value": "${name}", "order": "${
								index + 1
							}"}`}
						>
							<BrandIcon
								value={value}
								seoName={seoName}
								categorySeoName={categoryEntity.seoName}
								onClick={() => {
									increaseFavoriteBrandHit(value)
									selectBrand(value)
								}}
							/>
						</div>
					)
				})}
			</div>
		)
	}

	const renderAddAnotherBrand = () => {
		const renderAddBrandButton = (additionalProps = {}) => {
			return (
				<TextLink
					className={`${CLASSNAME}__add-brand-button`}
					iconLeft={<Icon symbol={ADD_SQUIRCLE_FILLED_16} />}
					{...additionalProps}
				>
					{localize('AdvertListBrandModelOpener.addBrand')}
				</TextLink>
			)
		}

		return (
			<>
				<div className={`${CLASSNAME}__mobile`}>
					{renderAddBrandButton({
						onClick: () => setShowBrandPopup(true)
					})}

					{showBrandPopup &&
						renderAllBrandsSearch({
							initialIsOpen: true,
							onClose: () => setShowBrandPopup(false)
						})}
				</div>

				<div className={`${CLASSNAME}__desktop`}>
					{showAddBrandButton
						? renderAddBrandButton({
								onClick: () => setShowAddBrandButton(false)
						  })
						: renderAllBrandsSearch({
								className: `${CLASSNAME}__search-brand-wrapper`,
								autoFocus: true,
								initialIsOpen: true,
								onClose: () => setShowAddBrandButton(true)
						  })}
				</div>
			</>
		)
	}

	const renderAllBrandsSearch = (additionalProps = {}) => {
		const restProps = Object.assign({}, additionalProps)

		delete restProps.className

		return (
			<div
				className={classnames({
					[additionalProps.className]: !!additionalProps.className
				})}
			>
				<AllBrandsSearch
					formLineEntity={formLineEntity}
					categoryEntity={categoryEntity}
					changeFilter={changeFilter}
					advertsCountForBrands={advertsCountForBrands}
					getSortedFavoriteBrands={getSortedFavoriteBrands}
					{...restProps}
				/>
			</div>
		)
	}

	const renderSelectedBrands = () => {
		const { value: brands = new Map(), options: allBrands = [] } = formLineEntity

		return (
			<div className={`${CLASSNAME}__selected-brands`}>
				{Array.from(brands).map(([brandValue]) => {
					const brand = allBrands.find((brand) => brand.value === brandValue)

					return (
						<SelectedBrand
							key={brandValue}
							formLineEntity={formLineEntity}
							category={categoryEntity}
							brand={brand}
							advertsCountForModels={advertsCountForModels}
							changeFilter={changeFilter}
						/>
					)
				})}
			</div>
		)
	}

	const { value = new Map() } = formLineEntity

	return (
		<div
			data-e2e={formLineEntity.id}
			data-dot='filter'
			data-dot-data={`{"type": "${formLineEntity.id}"}`}
		>
			{value.size ? (
				<div>
					{renderSelectedBrands()}
					{renderAddAnotherBrand()}
				</div>
			) : (
				<div>
					{renderFavoriteBrands()}
					{renderAllBrandsSearch()}
				</div>
			)}
		</div>
	)
}

AdvertListBrandModelOpener.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	categoryEntity: PropTypes.instanceOf(CategoryEntity).isRequired,
	changeFilter: PropTypes.func.isRequired,
	advertsCountForBrands: PropTypes.object.isRequired,
	advertsCountForModels: PropTypes.object.isRequired,
	getSortedFavoriteBrands: PropTypes.func.isRequired,
	increaseFavoriteBrandHit: PropTypes.func.isRequired
}

export default React.memo(AdvertListBrandModelOpener)
