import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import BasicWidget from '../../../../component/widgets/basic/BasicWidget'
import * as FormLines from '@inzeraty/form-lines'
import { Radio } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'

import './YesNoNotStatedWidget.less'
import './YesNoNotStatedWidgetCS.json'

export const YES_NO_NOT_STATED_WIDGET_ID = 'yes_no_not_stated_id'

const CLASSNAME = 'c-yes-no-not-stated-widget'

const YesNoNotStatedWidget = ({ formLineEntity, onChange }) => {
	const localize = useLocalize()

	const { value = '', extra = {} } = formLineEntity
	const { disabled: isDisabled = false } = extra
	const strValue = `${value}`

	return (
		<BasicWidget
			className={classnames({
				[CLASSNAME]: true,
				[`${CLASSNAME}--disabled`]: isDisabled
			})}
			formLineEntity={formLineEntity}
			name={YES_NO_NOT_STATED_WIDGET_ID}
			onChange={(_, value) => {
				const { id } = formLineEntity
				onChange({
					id,
					value,
					errorMessage: ''
				})
			}}
			renderInput={(formLineEntity, props) => {
				return (
					<div className={`${CLASSNAME}__input-wrapper`}>
						<Radio
							{...props}
							value={true}
							checked={strValue === 'true' && !isDisabled}
							error={!!formLineEntity.errorMessage}
							label={localize('YesNoNotStatedWidget.yes')}
							className={`${CLASSNAME}__input`}
							disabled={isDisabled}
							data-e2e={`${formLineEntity.id}-yes`}
						/>
						<Radio
							{...props}
							value={false}
							checked={strValue === 'false' && !isDisabled}
							error={!!formLineEntity.errorMessage}
							label={localize('YesNoNotStatedWidget.no')}
							className={`${CLASSNAME}__input`}
							disabled={isDisabled}
							data-e2e={`${formLineEntity.id}-no`}
						/>
						<Radio
							{...props}
							value=''
							checked={strValue === '' && !isDisabled}
							error={!!formLineEntity.errorMessage}
							label={localize('YesNoNotStatedWidget.notStated')}
							className={`${CLASSNAME}__input`}
							disabled={isDisabled}
							data-e2e={`${formLineEntity.id}-not-stated`}
						/>
					</div>
				)
			}}
		/>
	)
}

YesNoNotStatedWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(YesNoNotStatedWidget)
