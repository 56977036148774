import { LocalStorage } from '@inzeraty/helpers'
import { LOCAL_STORAGE_CLICKED_ADVERTS_ARRAY } from './AdvertListConstants'

const CACHE_ITEM_TIMEOUT = 20000 // 20s - ak su inzeraty v LS dlhsie tak ich odfiltrujem, aby sme nezatazovali LS

const _isValidItem = (item = {}) =>
	// time nesmi ukazovat do budoucnosti
	Date.now() >= item.time &&
	// time nesmi byt prilis stary
	Date.now() - item.time <= CACHE_ITEM_TIMEOUT

const _getCachedItems = () => {
	try {
		const items = LocalStorage.getItem(LOCAL_STORAGE_CLICKED_ADVERTS_ARRAY)

		if (items) {
			return JSON.parse(items).filter(_isValidItem)
		}
	} catch (e) {
		// na chybu nebudeme nijak reagovat
	}

	return []
}

const _setCachedItems = (items = []) => {
	LocalStorage.setItem(LOCAL_STORAGE_CLICKED_ADVERTS_ARRAY, JSON.stringify(items))
}

export const addDetailInfoToLocalStorage = (advertId, clickedOnAdvertFrom) => {
	const advertsClickedOnList = _getCachedItems()

	_setCachedItems([
		...advertsClickedOnList,
		{ advertId, from: clickedOnAdvertFrom, time: Date.now() }
	])
}

export const getDetailInfoFromLocalStorage = (advertId) => {
	const advertsClickedOnList = _getCachedItems()

	const isAdvertClickedFromList = advertsClickedOnList.find((item) => item.advertId === advertId)

	if (isAdvertClickedFromList) {
		_setCachedItems(advertsClickedOnList.filter((item) => item.advertId !== advertId))
	}

	return isAdvertClickedFromList
}
