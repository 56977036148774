import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

export default class ArticlesUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				OLD_SEARCH: 'text', // Url parametr hledání v článcích na starém sautu.
				OLD_CATEGORY: 'articleType', // Url parametr určující kategorii článků na starém sautu.
				CATEGORY: 'category',
				PAGE: 'page'
			},
			URL_API_PARAMS: {
				CATEGORY: 'type'
			},
			SORT: {
				CREATE: '-create_date'
			}
		})
	}

	static get categories() {
		return Object.freeze({
			NEWS: 'novinky',
			TESTS: 'recenze-aut',
			TIPS: 'tipy-rady'
		})
	}

	static get apiCategories() {
		return Object.freeze({
			NEWS: 'novinky',
			TESTS: 'testy',
			TIPS: 'tipy_a_rady'
		})
	}

	static getApiParams(appParams = {}) {
		const { URL_APP_PARAMS } = UrlConvertor.constants

		const apiParams = {
			...UrlConvertor.getApiPaging(
				appParams[ArticlesUrlConvertor.constants.URL_APP_PARAMS.PAGE],
				appParams[URL_APP_PARAMS.PER_PAGE]
			),
			[UrlConvertor.constants.URL_API_PARAMS.SORT]: ArticlesUrlConvertor.constants.SORT.CREATE
		}

		const category = appParams[ArticlesUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]
		switch (category) {
			case ArticlesUrlConvertor.categories.NEWS:
				apiParams[ArticlesUrlConvertor.constants.URL_API_PARAMS.CATEGORY] =
					ArticlesUrlConvertor.apiCategories.NEWS
				break
			case ArticlesUrlConvertor.categories.TESTS:
				apiParams[ArticlesUrlConvertor.constants.URL_API_PARAMS.CATEGORY] =
					ArticlesUrlConvertor.apiCategories.TESTS
				break
			case ArticlesUrlConvertor.categories.TIPS:
				apiParams[ArticlesUrlConvertor.constants.URL_API_PARAMS.CATEGORY] =
					ArticlesUrlConvertor.apiCategories.TIPS
				break
		}

		return apiParams
	}

	static createPaginationUrl(page, router) {
		const { params } = router.getCurrentRouteInfo()

		if (page > 1) {
			params[ArticlesUrlConvertor.constants.URL_APP_PARAMS.PAGE] = page
		} else {
			delete params[ArticlesUrlConvertor.constants.URL_APP_PARAMS.PAGE]
		}

		return UrlConvertor.getUrl(router, params)
	}
}
