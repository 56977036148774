
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SellerContactSection'] = {
  privateSeller: function(d) { return "Soukromý prodejce"; },
  callSeller: function(d) { return "Zavolat"; },
  textSeller: function(d) { return "Napsat prodejci"; },
  sellerContactHeader: function(d) { return "Prodejce"; },
  showMore: function(d) { return "Zobrazit více"; },
  lawStuff: function(d) { return "Veškeré údaje obsažené v tomto inzerátu mají pouze informativní charakter a nejsou tak ve smyslu občanského zákoníku: (i) návrhem na uzavření smlouvy dle § 1731 a § 1732; ani (ii) veřejným příslibem dle § 1733. Uveřejněním tohoto inzerátu nevzniká uživateli služby ani jakýmkoli třetím osobám nárok na uzavření smlouvy, zejména se vylučuje aplikace § 1732 odst. 2 občanského zákoníku."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	