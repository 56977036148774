import UserwebBaseController from 'app/base/UserwebBaseController'
import { UserService } from '@inzeraty/models'
import { HttpStatusCodes } from '@inzeraty/helpers'
import { ASSIGN_EMAIL_EVENTS } from '@inzeraty/components'

import 'app/base/AssignEmailReasonsCS.json'

/**
 * @class ClientBaseController
 * @namespace app.base
 * @extends app.base.UserwebBaseController
 * @module app
 * @submodule app.base
 */
export default class ClientBaseController extends UserwebBaseController {
	/**
	 * @constructor
	 * @method constructor
	 * @param {ns.app.helpers.dependenciesHelper.DependenciesHelper} dependenciesHelper
	 */
	constructor(dependenciesHelper, userHasToBeLoggedAsPremise = false) {
		super(dependenciesHelper)

		this._utils = this._dependenciesHelper.getDependency('utils')
		this._router = this._utils.$Router

		this._userHasToBeLoggedAsPremise = userHasToBeLoggedAsPremise

		this.setIsWholePageAfterLogin()
	}

	static get STATE_KEYS() {
		return {
			IS_PREMISE: 'IS_PREMISE',
			PREMISE_INFO: 'PREMISE_INFO',
			PREMISE_WALLET_INFO: 'PREMISE_WALLET_INFO'
		}
	}

	load(state = {}) {
		return super.load(
			Object.assign(
				{
					[ClientBaseController.STATE_KEYS.IS_PREMISE]: this._isPremise(),
					[ClientBaseController.STATE_KEYS.PREMISE_INFO]: this._getPremiseInfo(),
					[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]: undefined // data pre penazenku sa nastavuju v jednotlivych kontroleroch ktore dedia z tohto
				},
				state
			)
		)
	}

	activate() {
		super.activate()

		setTimeout(() => {
			this._utils.$Dispatcher.fire(ASSIGN_EMAIL_EVENTS.SHOW_DIALOG, {
				reason: this._utils.$Dictionary.get('AssignEmailReasons.continueOnThisPage'),
				onCloseWithError: () => {
					this._redirectToHP()
				}
			})
		}, 100)
	}

	getDataForSsp() {
		return {}
	}

	async _setHttpStatusCode() {
		const userEntity = await this._getUserSelfState()
		const isPremise = await this._isPremise(userEntity)

		if (!UserService.isUserLogged(userEntity)) {
			this.status = HttpStatusCodes.UNAUTHORIZED
		} else {
			if (this._userHasToBeLoggedAsPremise && !isPremise) {
				this.status = HttpStatusCodes.FORBIDDEN
			}
		}
	}

	async _isPremise(userSelfState = this._getUserSelfState()) {
		const userSelf = await userSelfState
		const { premises = [] } = userSelf || {}
		return Boolean(premises.length)
	}

	async _getPremiseId(userSelfState = this._getUserSelfState()) {
		const { premises } = await userSelfState
		const [premise = {}] = premises || []
		return premise.id
	}

	async _getPremiseInfo(userSelfState = this._getUserSelfState(), requestOptions = {}) {
		if (await this._isPremise(userSelfState)) {
			const premiseId = await this._getPremiseId(userSelfState)

			return await this._premiseService.getPremise(premiseId, {}, requestOptions)
		}
	}

	async _getPremiseWalletInfo(userSelfState = this._getUserSelfState(), options = {}) {
		if (await this._isPremise(userSelfState)) {
			const premiseId = await this._getPremiseId(userSelfState)

			try {
				return await this._premiseService.getPremiseWallet(premiseId, {}, options)
			} catch (error) {
				return {
					serverError: true
				}
			}
		}
	}

	async onGetPremiseWalletInfo(userSelfState = this._getUserSelfState()) {
		if (await this._isPremise(userSelfState)) {
			try {
				const walletInfo = await this._getPremiseWalletInfo(userSelfState, { cache: false })

				this.setState({
					[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]: walletInfo
				})
			} catch (error) {
				this.setState({
					[ClientBaseController.STATE_KEYS.PREMISE_WALLET_INFO]: {
						serverError: true
					}
				})
			}
		}
	}

	async onAgreeWithTerms({ id, callback, errorCallback }) {
		const apiParams = {
			terms_of_use: true
		}
		try {
			const { status } = await this._premiseService.agreeWithTerms(id, apiParams)
			if (status === HttpStatusCodes.OK) return callback()
			errorCallback()
		} catch (e) {
			errorCallback()
		}
	}
}
