
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PhoneWidget'] = {
  privacyStatement: function(d) { return "Tel. číslo bude na inzerátu skryto a zobrazí se jen zájemcům"; },
  unknown: function(d) { return "Toto telefonní číslo zatím neznáme."; },
  sendPin: function(d) { return "Odeslat ověřovací SMS"; },
  pinMsg1: function(d) { return "Na toto číslo "; },
  pinMsg2: function(d) { return " jsme vám zaslali SMS s číselným PIN kódem."; },
  pinVerified: function(d) { return "Vaše telefonní číslo je ověřeno"; },
  wrongNumber: function(d) { return "Zkontrolujte prosím číslo. Tento formát neznáme."; },
  hideNumber: function(d) { return "Skrýt telefonní číslo"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	