export default class PaymentService {
	constructor(PaymentResource, PaymentFactory) {
		this._paymentResource = PaymentResource
		this._paymentFactory = PaymentFactory
	}

	async getAvailablePayments(advertId = '', paymentsForTop = false) {
		const { body: { results = {} } = {} } = await this._paymentResource.getAvailablePayments(
			advertId,
			{},
			{ cache: false }
		)

		const apiData = paymentsForTop ? results.top : results.advert
		const appData = this._paymentFactory.createPaymentsEntityList(apiData)

		return appData
	}

	postPayment(advertId = '', params) {
		return this._paymentResource.postPayment(advertId, params)
	}

	closePayment(advertId = '', paymentId = '', params) {
		return this._paymentResource.closePayment(advertId, paymentId, params)
	}

	topPayment(params = {}, options = {}) {
		return this._paymentResource.topPayment(params, options)
	}
}
