import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Radio } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import operatingLeasePropTypes from 'app/model/operatingLease/OperatingLeasePropTypes'
import { useLocalize } from 'app/base/componentHelpers'

import 'app/base/BaseCS.json'
import './OperatingLeaseServiceRadio.less'

const CLASSNAME = 'c-operating-lease-detail-services-radio'

const OperatingLeaseServiceRadio = ({
	className,
	variant = DEFAULT_PROPS.OBJECT,
	checked,
	priceDiff = 0,
	onSelect = DEFAULT_PROPS.FUNCTION
}) => {
	const localize = useLocalize()

	const { id, servicesCb: services = [], additionalInfo = '' } = variant

	return (
		<label
			className={classnames({
				[`${CLASSNAME}__radio`]: true,
				[className]: !!className,
				[`${CLASSNAME}__radio--active`]: checked
			})}
			onChange={() => onSelect(variant)}
		>
			<div className={`${CLASSNAME}__radio-label`}>
				<Radio value={id} checked={checked} />
				<div className={`${CLASSNAME}__service-group`}>
					{services.length ? (
						services.map(({ name: serviceName }) => (
							<div
								key={serviceName}
								className={classnames({
									[`${CLASSNAME}__service`]: true,
									[`${CLASSNAME}__service--active`]: checked
								})}
							>
								{serviceName}
							</div>
						))
					) : (
						<div
							className={classnames({
								[`${CLASSNAME}__non-service`]: true,
								[`${CLASSNAME}__non-service--active`]: checked
							})}
						>
							{localize('OperatingLeaseDetailServices.withoutServices')}
						</div>
					)}
				</div>
				<div className={`notranslate ${CLASSNAME}__price`} translate='no' lang='cs-cz'>
					{checked
						? localize('OperatingLeaseDetailServices.selected')
						: `${priceDiff < 0 ? '-' : '+'}\xa0${Format.number(Math.abs(priceDiff))}\xa0${localize(
								'Base.CZK'
						  )}`}
				</div>
			</div>
			<div
				className={classnames({
					[`${CLASSNAME}__radio-note`]: true,
					[`${CLASSNAME}__radio-note--active`]: checked
				})}
			>
				{additionalInfo}
			</div>
		</label>
	)
}

OperatingLeaseServiceRadio.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.shape(operatingLeasePropTypes),
	checked: PropTypes.bool,
	priceDiff: PropTypes.number,
	onSelect: PropTypes.func
}

export default OperatingLeaseServiceRadio
