import AbstractExtension from 'ima/extension/AbstractExtension'
import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import { CLIENT_PROFILE_FORM_CLASSNAME } from 'app/page/userweb/clientProfile/ClientProfileView'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import STATE_KEYS from 'app/page/userweb/clientProfile/ClientProfileStateKeys'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'
import {
	getErrorDictionary,
	SPECIAL_ERROR_TO_FORM_LINE_ID_MAPPING
} from 'app/page/userweb/clientProfile/ClientProfileErrors'
import * as FormLines from '@inzeraty/form-lines'
import flattenFormLineEntities from 'app/helpers/formLineEntities/flattenFormLineEntities'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { StatusMessage } from '@inzeraty/components'

export default class ClientProfileValidationExtension extends AbstractExtension {
	constructor(utils) {
		super()
		this._ERROR_DICTIONARY = getErrorDictionary(utils.$Dictionary)

		this._utils = utils
		this._dictionary = utils.$Dictionary
	}

	load() {
		return {}
	}

	processErrors(error) {
		const { [STATE_KEYS.FORM_DATA]: formData } = this.getState()
		const errors = []

		// - sloucim errory pro kontakty
		this._processServerErrors(error).forEach((error) => {
			const { fields } = error

			const fieldsParts = fields[0].split('.')

			if (fieldsParts.length === 1) {
				errors.push(error)
			} else {
				const combineError = errors.filter((error) => error.fields[0] === fieldsParts[0])

				if (combineError.length) {
					errors.map((err) => {
						if (err.fields[0] === fieldsParts[0]) {
							err.errors.push(error)
							return null
						} else {
							return err
						}
					})
				} else {
					errors.push({
						fields: [fieldsParts[0]],
						errors: [error]
					})
				}
			}
		})

		for (const sectionKey in formData) {
			const sectionFormLineEntities = formData[sectionKey].map((formLineEntity) => {
				const { id, value, extra: { getErrorMessages } = {} } = formLineEntity

				let errorMessage = ''

				errors.forEach((error) => {
					const { fields } = error

					if (fields[0] === id || SPECIAL_ERROR_TO_FORM_LINE_ID_MAPPING[fields[0]] === id) {
						if (getErrorMessages) {
							errorMessage = getErrorMessages(error.errors, value, this._ERROR_DICTIONARY)
						} else {
							errorMessage = value
								? this._ERROR_DICTIONARY[id][ERROR_CODES.NOT_VALID]
								: this._ERROR_DICTIONARY[id][ERROR_CODES.REQUIRED]
						}
					}
				})

				return FormLines.updateEntity(formLineEntity, {
					errorMessage,
					value
				})
			})

			formData[sectionKey] = sectionFormLineEntities
		}

		this.setState({
			[STATE_KEYS.FORM_DATA]: { ...formData }
		})

		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			title: this._dictionary.get('ClientProfile.sendErrorMessage'),
			type: StatusMessage.TYPE.ERROR
		})

		this._scrollToErrorStatusMessage()
	}

	_processServerErrors(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)
		let errors = []

		if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(error)
			errors = body.errors
		}

		return errors
	}

	/**
	 * Slouží k nascrollování na error status message.
	 *
	 * @private
	 * @method _scrollToErrorStatusMessage
	 */
	_scrollToErrorStatusMessage() {
		const scrollToElement = document.querySelector(`.${CLIENT_PROFILE_FORM_CLASSNAME}`)

		if (window && scrollToElement && window.scrollY > scrollToElement.offsetTop) {
			animateScrollTo(scrollToElement)
		}
	}

	getAllowedStateKeys() {
		return [STATE_KEYS.FORM_DATA]
	}

	getFormlineEntitiesErrors(errorValidationCallbackFn) {
		const { [STATE_KEYS.FORM_DATA]: formData } = this.getState()

		const formLineEntities = flattenFormLineEntities(formData)

		return formLineEntities.filter(errorValidationCallbackFn)
	}
}
