import React from 'react'
import { LayoutAdvertEdit } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import HistoryForm from './component/IAHistoryForm'

export default function IAHistoryView(props) {
	return (
		<LayoutAdvertEdit>
			<HistoryForm />
		</LayoutAdvertEdit>
	)
}
