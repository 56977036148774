
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ReviewFeedback'] = {
  btnMarkAsPositive: function(d) { return "Označit zkušenost jako užitečnou"; },
  btnMarkAsNegative: function(d) { return "Označit zkušenost jako neužitečnou"; },
  btnUnmarkAsPositive: function(d) { return "Zrušit označení zkušenosti jako užitečné"; },
  btnUnmarkAsNegative: function(d) { return "Zrušit označení zkušenosti jako neužitečné"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	