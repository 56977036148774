
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['ReviewItem'] = {
  positive: function(d) { return "Pozitivní"; },
  negative: function(d) { return "Negativní"; },
  neutral: function(d) { return "Neutrální"; },
  pluses: function(d) { return "Klady:"; },
  minuses: function(d) { return "Zápory:"; },
  addFeedback: function(d) { return "Odpovědět"; },
  showNextReplies: function(d) { return "Zobrazit " + plural(d.COUNT, 0, cs, { one: "další reakci", few: "další " + d.COUNT + " reakce", other: "dalších " + d.COUNT + " reakcí" }); }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	