import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'

/**
 * Vycisti zadany objekt na vstupu od hodnot typu null a undefined.
 * @param {Object} object Libovolny objekt
 * @returns novy objekt vycisteny od null a undefined.
 */
const clearObject = (object) => {
	const clearedObject = Object.assign({}, object)

	Object.keys(clearedObject).forEach((key) => {
		if (isNullOrUndefined(clearedObject[key])) {
			delete clearedObject[key]
		}
	})

	return clearedObject
}

export default clearObject
