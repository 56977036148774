import React, { useContext } from 'react'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import TableHead from 'app/component/tableHead/TableHead'
import { Format } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import PremiseEntityImportLog from 'app/model/premise/PremiseEntityImportLog'
import PremiseEntityImportLogItem from 'app/model/premise/PremiseEntityImportLogItem'
import { Checkbox } from '@sznds/react'
import ImaContext from 'ima/page/context'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

import './IAImportDetailCS.json'
import './IAImportDetailView.less'

const CLASSNAME = 'p-ia-import-detail'

const ImportInfo = ({ importLogEntity, filterStatusCodes = [] }) => {
	const localize = useLocalize()
	const context = useContext(ImaContext)
	const router = context.$Router
	const { params = {}, route } = router.getCurrentRouteInfo()

	if (importLogEntity) {
		const {
			timestamp,
			sessionId,
			premise: { id: premiseId, name: premiseName } = {},
			softwareKey,
			counts: { addEditCarAll, addEditCarSuccessful, statusCodes: countsStatusCodes }
		} = importLogEntity

		const errorStatuses = Object.keys(countsStatusCodes).filter((status) => Number(status) >= 400)

		const errorsCount = errorStatuses.reduce(
			(total, status) => total + Number(countsStatusCodes[status]),
			0
		)

		const handleErrorFilterOnChange = (event) => {
			const status = event.target.value

			let newFilterStatusCodes = [...filterStatusCodes]

			if (event.target.checked) {
				if (!newFilterStatusCodes.includes(`${status}`)) {
					newFilterStatusCodes.push(status)
				}
			} else {
				newFilterStatusCodes.splice(
					newFilterStatusCodes.findIndex((code) => code === status),
					1
				)
			}

			const newParams = Object.assign({}, params, {
				[InternalAdminUrlConvertor.constants.STATUS_CODES]: newFilterStatusCodes.join(',')
			})
			delete newParams[InternalAdminUrlConvertor.constants.PAGE]

			if (!newFilterStatusCodes.length) {
				delete newParams[InternalAdminUrlConvertor.constants.STATUS_CODES]
			}

			router.redirect(router.link(route.getName(), newParams))
		}

		return (
			<div className={`${CLASSNAME}__info`}>
				<h2 className={`${CLASSNAME}__info-title`}>
					{localize('IAImportDetail.title')} {!!timestamp && Format.dateTimeSeconds(timestamp)}
				</h2>
				<table className={`${CLASSNAME}__info-table`}>
					<tbody>
						<tr>
							<th>{localize('IAImportDetail.sessionId')}:</th>
							<td>{sessionId}</td>
						</tr>
						<tr>
							<th>{localize('IAImportDetail.premiseId')}:</th>
							<td>{premiseId}</td>
						</tr>
						<tr>
							<th>{localize('IAImportDetail.premiseName')}:</th>
							<td>{premiseName}</td>
						</tr>
						<tr>
							<th>{localize('IAImportDetail.softwareKey')}:</th>
							<td>{softwareKey}</td>
						</tr>
						<tr>
							<th>{localize('IAImportDetail.beginning')}:</th>
							<td>{!!timestamp && Format.dateTimeSeconds(timestamp)}</td>
						</tr>
						<tr>
							<th>{localize('IAImportDetail.carsAll')}:</th>
							<td>{Format.number(addEditCarAll)}</td>
						</tr>
						<tr>
							<th>{localize('IAImportDetail.carsStored')}:</th>
							<td>{Format.number(addEditCarSuccessful)}</td>
						</tr>
						<tr>
							<th>{localize('IAImportDetail.importErrorCount')}:</th>
							<td>
								{localize('IAImportDetail.error', { COUNT: Format.number(errorsCount) })}
								{errorStatuses.length > 0 && (
									<div className={`${CLASSNAME}__status-code-list`}>
										{errorStatuses.map((status) => {
											return (
												<Checkbox
													key={status}
													className={`${CLASSNAME}__status-code-item`}
													value={status}
													label={`${status} (${countsStatusCodes[status]})`}
													checked={filterStatusCodes.includes(`${status}`)}
													onChange={handleErrorFilterOnChange}
												/>
											)
										})}
									</div>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	} else {
		return null
	}
}

ImportInfo.propTypes = {
	importLogEntity: PropTypes.instanceOf(PremiseEntityImportLog),
	filterStatusCodes: PropTypes.arrayOf(PropTypes.string)
}

const IAImporDetailView = ({
	importLogEntity,
	entitiesAndPagination,
	isLoading = false,
	filterStatusCodes = []
}) => {
	const localize = useLocalize()

	return (
		<Layout title='Detail importu'>
			<ImportInfo importLogEntity={importLogEntity} filterStatusCodes={filterStatusCodes} />
			<br />
			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHead key='action'>{localize('IAImportDetail.action')}</TableHead>,
					<TableHead key='timestamp'>{localize('IAImportDetail.timestamp')}</TableHead>,
					<TableHead key='status'>{localize('IAImportDetail.status')}</TableHead>,
					<TableHead key='statusMessage'>{localize('IAImportDetail.statusMessage')}</TableHead>
				]}
				rowCells={[
					({ rpcMethod }) => rpcMethod,
					({ timestamp }) => (timestamp ? Format.dateTime(timestamp) : ''),
					({ rpcStatusCode }) => rpcStatusCode,
					({ advertId, rpcStatusMessage }) => (
						<>
							{advertId ? `${localize('IAImportDetail.advertId')}: ${advertId} - ` : ''}
							{rpcStatusMessage}
						</>
					)
				]}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAImporDetailView.propTypes = {
	importLogEntity: PropTypes.instanceOf(PremiseEntityImportLog),
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(PremiseEntityImportLogItem)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	isLoading: PropTypes.bool,
	filterStatusCodes: PropTypes.arrayOf(PropTypes.string)
}

export default IAImporDetailView
