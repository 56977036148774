import AbstractExtension from 'ima/extension/AbstractExtension'
import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'

export default class ContactSectionExtension extends AbstractExtension {
	constructor(phoneService, emailService) {
		super()

		this._phoneService = phoneService
		this._emailService = emailService
	}

	load() {
		return {}
	}

	/**
	 * Ošetřuje událost odeslání SMS
	 *
	 * @method onSendMessage
	 * @public
	 * @param {String}   phone      Telefonní číslo, na které se odešle sms
	 * @param {Function} callback 	Callback funckce pro zobrazení dalšího postupu
	 */
	onSendMessage({ phone, callback = () => {} }) {
		const promise = this._phoneService.verifyPhone({ phone })

		this._handlePromise(promise, callback)
	}

	onSendEmailMessage({ email, callback = () => {} }) {
		const promise = this._emailService.sendVerificationEmail({ email })

		this._handlePromise(promise, callback)
	}

	async onValidatePhone({ phone = '', callback = () => {} } = {}) {
		try {
			if (phone) {
				const isPhoneValid = await this._phoneService.checkPhone(phone)
				callback(phone, isPhoneValid)
			} else {
				callback(phone, false)
			}
		} catch (error) {
			callback(phone, false)
		}
	}

	onValidateEmail(data) {
		const { callback = () => {}, formLineEntity = {} } = data
		const { value = '' } = formLineEntity

		const isEmailValid = this._emailService.isVerified(value)

		isEmailValid
			.then((res) => {
				const { body = {} } = res
				const { verified } = body
				callback(verified)
			})
			.catch((err) => {
				const httpStatus = HttpErrorHelper.getHttpStatus(err)
				let errorCode = ''

				if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
					const { body = {} } = HttpErrorHelper.getParams(err)
					const { error_code } = body.errors[0]
					errorCode = error_code
				}

				callback(false, errorCode)
			})
	}

	/**
	 * Ošetřuje událost verifikace PIN čísla
	 *
	 * @method onVerifyPin
	 * @public
	 * @param {String}   phone      Telefonní číslo, které se bude ověřovat
	 * @param {String}   pin        PIN zadaný uživatelem
	 * @param {Function} callback 	Callback funckce pro zobrazení dalšího postupu
	 */
	onVerifyPin({ phone = '', pin = '', callback = () => {}, saveToProfile = false }) {
		const promise = this._phoneService.verifyPhone({
			phone,
			pin,
			save_as_contact_phone_number_to_profile: saveToProfile
		})

		this._handlePromise(promise, callback)
	}

	onVerifyEmailPin({ email = '', pin = '', callback = () => {} }) {
		const promise = this._emailService.verifyEmail({ email, pin })

		this._handlePromise(promise, callback)
	}

	_handlePromise(promise, callback) {
		promise
			.then((response) => {
				callback()
			})
			.catch((err) => {
				const httpStatus = HttpErrorHelper.getHttpStatus(err)
				let error = []

				if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
					const { body = {} } = HttpErrorHelper.getParams(err)
					error = body.errors
				}

				callback(error)
			})
	}
}
