
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ClientAdvertItem'] = {
  status_active: function(d) { return "Aktivní"; },
  status_draft: function(d) { return "Rozpracovaný"; },
  status_inactive: function(d) { return "Neaktivní"; },
  status_deleted: function(d) { return "Smazaný"; },
  status_disabled: function(d) { return "Zamítnutý"; },
  select: function(d) { return "Označit"; },
  deselect: function(d) { return "Odznačit"; },
  created: function(d) { return "Vytvořeno"; },
  edited: function(d) { return "Editováno"; },
  id: function(d) { return "ID"; },
  cid: function(d) { return "CID"; },
  lastTopped: function(d) { return "Topováno"; },
  toppedCount: function(d) { return "Počet topování"; },
  operatingLeasePricePerMonth: function(d) { return "Od " + d.PRICE + " " + d.CURRENCY + " / měs. bez DPH"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	