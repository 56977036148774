import { useEffect, useRef } from 'react'
import ROUTE_NAMES from 'app/base/RouteNames'
import { useRouteName } from 'app/base/componentHelpers'

import './LiveChat.less'

function loadScript() {
	if (typeof window !== 'undefined') {
		// Set webchat configuration
		var daktelaGuiConfig = {
			server: 'https://seznam.daktela.com/',
			accessToken: '1n5r3o29q383s5nn9rrq17o673n8r51r'
		}

		// Create async script element
		var daktelaScriptEl = document.createElement('script')

		;(function (attrs) {
			Object.keys(attrs).forEach(function (key) {
				daktelaScriptEl.setAttribute(key, attrs[key])
			})
		})({
			src: daktelaGuiConfig.server + 'external/web/web.js',
			type: 'text/javascript',
			async: true,
			charset: 'utf-8'
		})

		daktelaScriptEl.onload = function () {
			var daktelaWeb = new window.daktelaGui()
			daktelaWeb.init(daktelaGuiConfig)
		}

		document.getElementsByTagName('head')[0].appendChild(daktelaScriptEl)
	}
}

const querySelectorWrapper = (selector) =>
	typeof document !== 'undefined' && document.querySelector(selector)

const setCssDisplayProperty = (domElement, displayPropertyValue = 'block') => {
	if (domElement) {
		domElement.style.display = displayPropertyValue
	}
}

export default function LiveChat({ isPremise = false }) {
	const isLoaded = useRef(false)
	const routeName = useRouteName()

	useEffect(() => {
		const shouldBeChatEnabled =
			isPremise &&
			[
				ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST,
				ROUTE_NAMES.CLIENT_ADMIN.CLIENT_PROFILE,
				ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS
			].includes(routeName)

		if (!isLoaded.current && shouldBeChatEnabled) {
			loadScript()
			isLoaded.current = true
		} else {
			const chatWidget = querySelectorWrapper('#daktela-web')
			setCssDisplayProperty(chatWidget, shouldBeChatEnabled ? 'block' : 'none')
		}
	}, [routeName, isPremise])

	return null
}
