import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { InputSurface, Checkbox, Surface } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import { useLocalize } from 'app/base/componentHelpers'
import TextLink from 'app/component/textLink/TextLink'
import Tooltip from 'app/page/userweb/newAdvert/component/tooltip/Tooltip'

import './Price.less'
import './PriceCS.json'

export const PRICE_WIDGET_ID = 'price'

const CLASSNAME = 'c-price-widget-base'

const PRICE_LEASING = 'price_leasing'
const PRICE_PAYMENT = 'price_payment'
const PRICE_PAYMENT_COUNT = 'price_payment_count'

const MAX_PRICE_NUMBER = 999999999999
const MAX_COUNT_NUMBER = 999999

const Price = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const localize = useLocalize()

	const [showAdvancedPriceOptions, setShowAdvancedPriceOptions] = useState(false)
	const [showFirstAdvancedPriceOptionTab, setShowFirstAdvancedPriceOptionTab] = useState(true)

	const prevFormLineEntity = useRef(undefined)

	useEffect(() => {
		const {
			value: {
				priceOriginal,
				priceIsVatDeductible,
				priceLeasing,
				pricePayment,
				pricePaymentCount
			} = {},
			errorMessage: {
				priceLeasing: priceLeasingErrorMessage,
				pricePayment: pricePaymentErrorMessage,
				pricePaymentCount: pricePaymentCountErrorMessage
			} = {}
		} = formLineEntity

		const {
			value: {
				priceOriginal: prevPrice,
				priceIsVatDeductible: prevPriceIsVatDeductible,
				priceLeasing: prevPriceLeasing,
				pricePayment: prevPricePayment,
				pricePaymentCount: prevPricePaymentCount
			} = {}
		} = prevFormLineEntity.current || {}

		const areValuesChanged =
			priceOriginal !== prevPrice ||
			priceIsVatDeductible !== prevPriceIsVatDeductible ||
			priceLeasing !== prevPriceLeasing ||
			pricePayment !== prevPricePayment ||
			pricePaymentCount !== prevPricePaymentCount

		const isSellViaLeasingErrorSet = priceLeasingErrorMessage
		const areCancelLeasingErrorsSet = pricePaymentErrorMessage || pricePaymentCountErrorMessage

		const areErrorsForAdvancedOptionsSet = isSellViaLeasingErrorSet || areCancelLeasingErrorsSet

		if (!areValuesChanged && areErrorsForAdvancedOptionsSet) {
			// otevreme sekci 'Pokrocile predvolby ceny a leasingu'
			setShowAdvancedPriceOptions(true)

			if (
				isSellViaLeasingErrorSet &&
				!showFirstAdvancedPriceOptionTab &&
				!areCancelLeasingErrorsSet
			) {
				// zobrazime zalozku pro sjednani leasingu
				setShowFirstAdvancedPriceOptionTab(true)
			} else if (
				areCancelLeasingErrorsSet &&
				showFirstAdvancedPriceOptionTab &&
				!isSellViaLeasingErrorSet
			) {
				// zobrazime zalozku pro zruseni leasingu
				setShowFirstAdvancedPriceOptionTab(false)
			}
		}

		prevFormLineEntity.current = formLineEntity
	}, [formLineEntity])

	const convertStringToNumber = (string = '') =>
		string ? Number(string.replace(/ /g, '')) : undefined

	const onPriceChange = (event) => {
		const priceOriginal = convertStringToNumber(event.target.value)

		change({
			value: {
				priceOriginal
			}
		})
	}

	const onAdvancedPriceOptionsButtonClick = () =>
		setShowAdvancedPriceOptions(!showAdvancedPriceOptions)

	const onPriceIsVatDeductibleToggle = () => {
		const { value: { priceIsVatDeductible } = {} } = formLineEntity

		change({
			value: {
				priceIsVatDeductible: !priceIsVatDeductible
			}
		})
	}

	const onIsVatToggle = () => {
		const { value: { priceIsWithoutVat } = {} } = formLineEntity

		change({
			value: {
				priceIsWithoutVat: !priceIsWithoutVat
			}
		})
	}

	const onPriceLeasingChange = (event) => {
		const priceLeasing = convertStringToNumber(event.target.value)

		change({
			value: {
				priceLeasing
			}
		})
	}

	const onPricePaymentChange = (event) => {
		const pricePayment = convertStringToNumber(event.target.value)

		change({
			value: {
				pricePayment
			}
		})
	}

	const onPricePaymentCountChange = (event) => {
		const pricePaymentCount = convertStringToNumber(event.target.value)

		change({
			value: {
				pricePaymentCount
			}
		})
	}

	const change = (changes) => {
		const {
			id,
			value,
			errorMessage,
			extra: {
				isPriceValid = () => true,
				isPriceLeasingValid = () => true,
				isPricePaymentValid = () => true,
				isPricePaymentCountValid = () => true
			} = {}
		} = formLineEntity

		const newValue = Object.assign({}, value, changes.value)

		const { priceOriginal, priceLeasing, pricePayment, pricePaymentCount } = newValue

		// automaticky procistime chybove hlasky podle toho, ktere policko je
		// validni nebo ne
		const newErrorMessage = Object.assign(
			{},
			errorMessage,
			isPriceValid(priceOriginal) ? { priceOriginal: undefined } : {},
			isPriceLeasingValid(priceLeasing) ? { priceLeasing: undefined } : {},
			isPricePaymentValid(pricePayment) ? { pricePayment: undefined } : {},
			isPricePaymentCountValid(pricePaymentCount) ? { pricePaymentCount: undefined } : {},
			changes.errorMessage
		)

		onChange(
			Object.assign({}, changes, {
				id,
				value: newValue,
				errorMessage: Object.values(newErrorMessage).some((e) => e) ? newErrorMessage : undefined
			})
		)
	}

	const isAllowed = (maximum) => (values) => {
		const { floatValue } = values

		if (floatValue !== undefined) return floatValue >= 0 && floatValue <= maximum
		else return true
	}

	const renderInput = () => {
		const {
			placeholder,
			disabled,
			value: { priceOriginal = '' } = {},
			errorMessage: { priceOriginal: priceErrorMessage } = {}
		} = formLineEntity

		const restProps = Object.assign({}, props)

		delete restProps.formLineEntity
		delete restProps.onChange
		delete restProps.renderErrorMessage

		return (
			<div className={`${CLASSNAME}__main-content`}>
				<NumberFormat
					{...restProps}
					allowNegative={false}
					customInput={InputSurface}
					tagName='input'
					className={classnames({
						[props.className]: !!props.className
					})}
					thousandSeparator=' '
					isAllowed={isAllowed(MAX_PRICE_NUMBER)}
					type='tel'
					value={priceOriginal}
					onChange={onPriceChange}
					error={priceErrorMessage}
					placeholder={placeholder}
					disabled={disabled}
				/>
				<div
					className={classnames({
						[`${CLASSNAME}__desktop`]: true,
						[`${CLASSNAME}__price-options-button`]: true
					})}
				>
					{renderAdvancedPriceOptionsButton()}
				</div>
			</div>
		)
	}

	const renderErrorMessage = () => {
		const { renderErrorMessage } = props
		const { errorMessage: { priceOriginal: priceErrorMessage } = {} } = formLineEntity

		if (renderErrorMessage) {
			return renderErrorMessage()
		} else {
			return <div className={`${CLASSNAME}__error`}>{priceErrorMessage}</div>
		}
	}

	const renderAdvancedPriceOptionsButton = () => {
		return (
			<TextLink onClick={onAdvancedPriceOptionsButtonClick}>
				{showAdvancedPriceOptions
					? localize('Price.hideAdvancedPriceOptions')
					: localize('Price.showAdvancedPriceOptions')}
			</TextLink>
		)
	}

	const renderIsVatDeductibleCheckbox = () => {
		const { disabled, value: { priceIsVatDeductible = false } = {} } = formLineEntity

		return (
			<Checkbox
				className={`${CLASSNAME}__price-extra-checkbox`}
				checked={priceIsVatDeductible}
				label={localize('Price.priceVatDeductible')}
				disabled={disabled}
				data-e2e='vat-deductible-checkbox'
				onChange={onPriceIsVatDeductibleToggle}
			/>
		)
	}

	const renderIsVatCheckbox = () => {
		const { disabled, value: { priceIsWithoutVat = false } = {} } = formLineEntity

		return (
			<Checkbox
				className={`${CLASSNAME}__price-extra-checkbox`}
				checked={priceIsWithoutVat}
				label={localize('Price.priceVat')}
				disabled={disabled}
				data-e2e='price-without-vat-checkbox'
				onChange={onIsVatToggle}
			/>
		)
	}

	const renderAdvancedPriceOptionsContent = () => {
		const renderSectionHeader = ({ title = '', selected = false, onClick = () => {} }) => {
			return (
				<button
					type='button'
					className={classnames({
						[`${CLASSNAME}__option-header`]: true,
						[`${CLASSNAME}__option-header--selected`]: selected
					})}
					onClick={onClick}
				>
					{title}
				</button>
			)
		}

		const renderOptionInput = ({
			id,
			label,
			value = '',
			maximum = MAX_PRICE_NUMBER,
			placeholder,
			description,
			errorMessage,
			disabled,
			onChange
		}) => {
			return (
				<div className={`${CLASSNAME}__option-item`} key={id}>
					<div className={`${CLASSNAME}__option-label-wrapper`}>
						<label className={`${CLASSNAME}__option-label`} id={`${id}-label`} htmlFor={id}>
							{label}
						</label>

						<Tooltip
							elementId={id}
							tooltipProps={{
								title: label,
								children: `${description}.`
							}}
						/>
					</div>

					<div className={`${CLASSNAME}__option-input-wrapper`}>
						<NumberFormat
							id={id}
							allowNegative={false}
							className={`${CLASSNAME}__option-input`}
							customInput={InputSurface}
							tagName='input'
							thousandSeparator=' '
							isAllowed={isAllowed(maximum)}
							type='tel'
							value={value}
							placeholder={placeholder}
							error={!!errorMessage}
							onChange={onChange}
							disabled={disabled}
						/>

						<div
							className={classnames({
								[`${CLASSNAME}__option-description`]: true
							})}
						>
							{description}
						</div>
					</div>

					{errorMessage && <div className={`${CLASSNAME}__option-error`}>{errorMessage}</div>}
				</div>
			)
		}

		const renderSellVehicleViaLeasingSection = () => {
			const {
				disabled,
				value: { priceLeasing } = {},
				errorMessage: { priceLeasing: priceLeasingErrorMessage } = {}
			} = formLineEntity

			return (
				<div className={`${CLASSNAME}__options-content`}>
					{renderOptionInput({
						id: PRICE_LEASING,
						label: localize('Price.priceLeasingLabel'),
						value: priceLeasing,
						maximum: MAX_PRICE_NUMBER,
						placeholder: localize('Price.priceLeasingPlaceholder'),
						description: localize('Price.priceLeasingDescription'),
						errorMessage: priceLeasingErrorMessage,
						disabled,
						onChange: onPriceLeasingChange
					})}
				</div>
			)
		}

		const renderCancelLeasingSection = () => {
			const {
				disabled,
				value: { pricePayment, pricePaymentCount } = {},
				errorMessage: {
					pricePayment: pricePaymentErrorMessage,
					pricePaymentCount: pricePaymentCountErrorMessage
				} = {}
			} = formLineEntity

			return (
				<div className={`${CLASSNAME}__options-content`}>
					{renderOptionInput({
						id: PRICE_PAYMENT,
						label: localize('Price.pricePaymentLabel'),
						value: pricePayment,
						maximum: MAX_PRICE_NUMBER,
						placeholder: localize('Price.pricePaymentPlaceholder'),
						description: localize('Price.pricePaymentDescription'),
						errorMessage: pricePaymentErrorMessage,
						disabled,
						onChange: onPricePaymentChange
					})}
					{renderOptionInput({
						id: PRICE_PAYMENT_COUNT,
						label: localize('Price.pricePaymentCountLabel'),
						value: pricePaymentCount,
						maximum: MAX_COUNT_NUMBER,
						placeholder: localize('Price.pricePaymentCountPlaceholder'),
						description: localize('Price.pricePaymentCountDescription'),
						errorMessage: pricePaymentCountErrorMessage,
						disabled,
						onChange: onPricePaymentCountChange
					})}
				</div>
			)
		}

		return (
			<Surface tagName='div' surface={5} className={`${CLASSNAME}__options-container`}>
				<Surface tagName='div' surface={4} className={`${CLASSNAME}__options-container-header`}>
					{renderSectionHeader({
						title: localize('Price.sellViaLeasing'),
						selected: showFirstAdvancedPriceOptionTab,
						onClick: () => setShowFirstAdvancedPriceOptionTab(true)
					})}

					{renderSectionHeader({
						title: localize('Price.cancelLeasing'),
						selected: !showFirstAdvancedPriceOptionTab,
						onClick: () => setShowFirstAdvancedPriceOptionTab(false)
					})}
				</Surface>

				{showFirstAdvancedPriceOptionTab
					? renderSellVehicleViaLeasingSection()
					: renderCancelLeasingSection()}
			</Surface>
		)
	}

	const { errorMessage: { priceOriginal: priceErrorMessage } = {} } = formLineEntity

	return (
		<div className={CLASSNAME}>
			{renderInput()}
			{priceErrorMessage && renderErrorMessage()}

			<div>{renderIsVatCheckbox()}</div>

			<div>{renderIsVatDeductibleCheckbox()}</div>

			<div className={`${CLASSNAME}__mobile`}>{renderAdvancedPriceOptionsButton()}</div>

			{showAdvancedPriceOptions && renderAdvancedPriceOptionsContent()}
		</div>
	)
}

Price.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	renderErrorMessage: PropTypes.func,
	className: PropTypes.string
}

export default React.memo(Price)
