import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import Count from '../../components/count/CountView'
import FilterConstants from 'app/model/filter/FilterConstants'
import IMAGES from 'app/base/ImagesConstants'
import { CATEGORIES } from 'app/base/Constants'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { CategoryEntity } from '@inzeraty/models'
import { areRegressionTestsRunning } from 'app/page/componentRegressionTest/ComponentRegressionTestUtils'

import './FilterRowView.less'
import 'app/base/BaseCS.json'

const CLASSNAME = 'cf-row'

const DEFAULT_CATEGORY_PROP = new CategoryEntity(DEFAULT_PROPS.OBJECT)

export default class FilterRow extends AbstractComponent {
	static get propTypes() {
		return {
			count: PropTypes.number,
			isCountShownWithAddText: PropTypes.bool,
			isCountLoading: PropTypes.bool,
			isSelected: PropTypes.bool,
			label: PropTypes.string.isRequired,
			url: PropTypes.string, // url slouží pro potřeby SEO. Popisek je vyrenderován
			// v odkazu a tento odkaz má při kliknutí preventované
			// defaultní chování.
			preventDefaultOnElementOnly: PropTypes.bool, // urcuje, zda chceme preventovat
			// defaultni akce vsude (tedy i na
			// rodicich) nebo jen na tomto
			// elementu.
			index: PropTypes.number,
			dataDot: PropTypes.string,
			dataDotDataType: PropTypes.string,
			filterId: PropTypes.string,
			colorCode: PropTypes.string,
			isHpFilter: PropTypes.bool,
			seoName: PropTypes.string,
			categoryEntity: PropTypes.instanceOf(CategoryEntity)
		}
	}

	static get defaultProps() {
		return {
			count: 0,
			isCountShownWithAddText: false,
			isCountLoading: false,
			isSelected: false,
			preventDefaultOnElementOnly: false,
			index: 0,
			filterId: '',
			colorCode: '',
			isHpFilter: true,
			seoName: '',
			categoryEntity: DEFAULT_CATEGORY_PROP
		}
	}

	render() {
		const { label, index, dataDot, dataDotDataType } = this.props

		const dataDotProps = dataDot ? { 'data-dot': dataDot } : {}
		const dataDotDataProps = dataDotDataType
			? {
					'data-dot-data': `{"type": "${dataDotDataType}", "value": "${label}", "order": "${
						index + 1
					}"}`
			  }
			: { 'data-dot-data': `{"value": "${label}", "order": "${index + 1}"}` }

		return (
			<div className={CLASSNAME} {...dataDotProps} {...dataDotDataProps}>
				{this._renderChildren()}
				{this._renderVisualAddition()}
				{this._renderLabel()}
				{this._renderCount()}
			</div>
		)
	}

	_renderChildren() {
		const { children } = this.props

		if (children) {
			return <span className={`${CLASSNAME}__children`}>{children}</span>
		}
	}

	_renderLabel() {
		const { isSelected, label, url, children, preventDefaultOnElementOnly, isHpFilter } = this.props

		let Element
		let props

		if (url) {
			Element = 'a'
			props = {
				href: url,
				onClick: (event) => {
					if (preventDefaultOnElementOnly) {
						// chceme preventovat pouze defaultni akci na 'a' elementu,
						// tedy prechod na novou url. Nikoliv dalsi defaultni akce
						// na rodicich.
						event.preventDefault()

						if (event.target.parentNode) event.target.parentNode.click()
					} else {
						event.preventDefault()
					}
				}
			}
		} else {
			Element = 'div'
			props = {}
		}

		return (
			<Element
				{...props}
				className={this.cssClasses({
					[`${CLASSNAME}__label`]: true,
					[`${CLASSNAME}__label--selected`]: isSelected,
					[`${CLASSNAME}__label--empty`]: !children,
					[`${CLASSNAME}__label--compact`]: !isHpFilter
				})}
			>
				{label}
			</Element>
		)
	}

	_renderVisualAddition() {
		const { filterId, colorCode, seoName, categoryEntity } = this.props
		const { id: categoryId } = categoryEntity

		// Pri regresnych testoch nechceme zobrazovat obrazky karoserii pretoze nie su dostupne
		const areRegTestRunning = areRegressionTestsRunning(this.utils.$Router)

		if (filterId === FilterConstants.formLineIds.COLOR) {
			return (
				<span
					className={`${CLASSNAME}__color`}
					style={{
						backgroundColor: `#${colorCode}`
					}}
				></span>
			)
		} else if (
			filterId === FilterConstants.formLineIds.VEHICLE_BODY &&
			categoryId === CATEGORIES.PASSENGER_CARS.id &&
			!areRegTestRunning
		) {
			return (
				<img
					loading='lazy'
					className={`${CLASSNAME}__img`}
					src={IMAGES[`image__vehicle-body__${seoName}`]}
					alt=''
				/>
			)
		}
	}

	_renderCount() {
		const { count, isCountShownWithAddText, isCountLoading, isSelected, isHpFilter } = this.props

		return (
			<Count
				className={this.cssClasses({
					[`${CLASSNAME}__count`]: true,
					[`${CLASSNAME}__count--loading`]: isCountLoading
				})}
				count={count}
				isCountShownWithAddText={isCountShownWithAddText}
				isCountLoading={isCountLoading}
				isSelected={isSelected}
				isHpFilterCount={isHpFilter}
			/>
		)
	}
}
