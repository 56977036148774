import AbstractExtension from 'ima/extension/AbstractExtension'
import { UPLOADER_CONSTANTS, SDN } from 'app/base/Constants'
import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import { CATEGORIES, CAR_CONDITION } from 'app/base/Constants'

export default class PhotosUploaderExtension extends AbstractExtension {
	constructor(advertAttachmentsService, blueimpLoadImageLoader, canvasToBlob) {
		super()

		this._advertAttachmentsService = advertAttachmentsService
		this._blueimpLoadImageLoader = blueimpLoadImageLoader
		this._canvasToBlob = canvasToBlob

		// Fronta ideček fotek pro sériové nahrání.
		// Jde o to abychom při nahrání x fotek zajistili jejich správné pořadí.
		this._photoIdsUploadQueue = [] // todo zamyslet se nad paralelním nahráváním
	}

	static get stateKeys() {
		return deepFreeze({
			ADVERT_ENTITY: 'photosUploaderExtensionAdvertEntity',
			PHOTOS: 'photosUploaderExtensionPhotos',
			MINIMAL_PHOTOS: 'photosUploaderExtensionMinimalPhotos'
		})
	}

	load() {
		return {
			[PhotosUploaderExtension.stateKeys.PHOTOS]: this._getAdvertPhotos(),
			[PhotosUploaderExtension.stateKeys.MINIMAL_PHOTOS]: this._getMinimalPhotos()
		}
	}

	/**
	 * Provede přidání souborů fotek. Nad soubory se provede převedení do canvasu, kontrola rozměrů, případně zmenšení, nastavení potřebných parametrů a následné nahrávání na server.
	 *
	 * @method onAddPhotos
	 * @public
	 * @param {FileList} files Soubory fotek.
	 */
	async onAddPhotos(files) {
		const {
			SIZE: { PHOTO_MAX_WIDTH, PHOTO_MAX_HEIGHT, PHOTO_MIN_WIDTH, PHOTO_MIN_HEIGHT },
			ERROR
		} = UPLOADER_CONSTANTS

		let photosToAdd = await this._blueimpLoadImageLoader.loadImages(files, {
			maxWidth: PHOTO_MAX_WIDTH,
			maxHeight: PHOTO_MAX_HEIGHT,
			canvas: true,
			orientation: true,
			meta: true
		})

		photosToAdd = photosToAdd.map((image) => {
			const {
				data: { originalHeight, originalWidth }
			} = image
			const isTooSmall = originalHeight < PHOTO_MIN_HEIGHT || originalWidth < PHOTO_MIN_WIDTH

			return Object.assign(image, {
				error: isTooSmall ? ERROR.TOO_SMALL : '',
				isLoading: !isTooSmall
			})
		})

		const photos = this._getPhotos()
		this._setPhotosToState([...photos, ...photosToAdd])

		this._setPhotosToUploadQueue(photosToAdd)
	}

	/**
	 * Nastaví fotku jako první.
	 *
	 * @method onSetFirstPhoto
	 * @public
	 * @param {String} photoId Id fotky, kterou má nastavit jako první.
	 */
	onSetFirstPhoto(photoId) {
		const newOrderPhotos = [this._getPhoto(photoId), ...this._getPhotosExceptId(photoId)]
		this.onSetPhotosOrder(newOrderPhotos)
	}

	/**
	 * Uloží nové pořadí fotek.
	 *
	 * @method onSetPhotosOrder
	 * @public
	 * @param {Array<Object>} newOrderPhotos Pole s nově seřazenými objekty fotek.
	 */
	onSetPhotosOrder(newOrderPhotos) {
		const advertId = this._getAdvertId()
		const photos = this._getPhotos()

		this._setPhotosToState(newOrderPhotos)

		const ids = newOrderPhotos.filter((photo) => !!photo.serverId).map((photo) => photo.serverId)

		this._advertAttachmentsService.saveImageOrder(ids, advertId).catch((error) => {
			// todo zobrazit stavovou hlášku, že se nepodařilo uložit pořadí na serveru

			// Při chybě změny pořadí nastaví původní pořadí.
			this._setPhotosToState(photos)
		})
	}

	/**
	 * Provede úplně smazání fotky (ze state i z backendu).
	 *
	 * @method onRemovePhoto
	 * @public
	 * @param {String} photoId Id fotky, kterou má smazat.
	 */
	onRemovePhoto(photoId) {
		const advertId = this._getAdvertId()

		const photo = this._getPhoto(photoId)

		if (photo) {
			if (photo.serverId) {
				this._updatePhotoState(photoId, {
					isLoading: true
				})

				this._advertAttachmentsService
					.deleteImage(photo.serverId, advertId)
					.then(() => {
						this._removePhotoFromState(photoId)
					})
					.catch((error) => {
						// Todo - zobrazit stavovou hlášku, že se nepodařilo fotku smazat
						this._updatePhotoState(photoId, {
							isLoading: false
						})
					})
			} else {
				this._removePhotoFromState(photoId)
			}
		}
	}

	/**
	 * Vrátí fotky, které jsou už uložený k inzerátu.
	 *
	 * @method _getAdvertPhotos
	 * @private
	 * @returns {Array<Object>} Pole objektů s daty fotek, které jsou už uložené.
	 */
	async _getAdvertPhotos() {
		const { [PhotosUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntity } = this.getState()

		const { images = [] } = (await advertEntity) || {}

		return images.map(({ url, id }) => ({
			id: `${id}`,
			image: url + SDN.w320x240,
			serverId: id
		}))
	}

	/**
	 * Nastaví přidané fotky do fronty pro nahrání.
	 *
	 * @method _setPhotosToUploadQueue
	 * @private
	 * @param {Array<Object>} photos Pole objektů s daty fotek, které nastaví fronty pro nahrání.
	 */
	_setPhotosToUploadQueue(photos) {
		const isNotUploading = !this._photoIdsUploadQueue.length

		photos.forEach(({ id, error }) => {
			if (!error) {
				this._photoIdsUploadQueue.push(id)
			}
		})

		if (isNotUploading) {
			this._upload()
		}
	}

	/**
	 * Provede nahrání fotek z fronty fotek k nahrání.
	 *
	 * @method _upload
	 * @private
	 */
	_upload() {
		const uploadBlob = (advertId, idToUpload, cameraModelName = '', blob) => {
			this._advertAttachmentsService
				.saveImage(advertId, {
					file: blob,
					model_name: cameraModelName
				})
				.then((data) => {
					this._updatePhotoState(idToUpload, {
						isLoading: false,
						serverId: data.id
					})
				})
				.catch((err) => {
					const { TOO_SMALL } = UPLOADER_CONSTANTS.ERROR

					let error = 'backendError'

					if (err === TOO_SMALL) {
						error = TOO_SMALL
					}

					this._updatePhotoState(idToUpload, {
						isLoading: false,
						error
					})
				})
				.finally(() => {
					if (this._photoIdsUploadQueue.length) {
						this._upload()
					}
				})
		}

		const advertId = this._getAdvertId()
		const idToUpload = this._photoIdsUploadQueue.shift()
		const photo = this._getPhoto(idToUpload)

		if (photo && photo.image) {
			this._canvasToBlob.toBlob(
				photo.image,
				(blob) => {
					if (photo.data?.exif) {
						let allTags = photo.data.exif.getAll ? photo.data.exif.getAll() : {}

						uploadBlob(advertId, idToUpload, allTags.Model, blob)
					} else {
						uploadBlob(advertId, idToUpload, '', blob)
					}
				},
				'image/jpeg'
			)
		} else {
			if (this._photoIdsUploadQueue.length) {
				this._upload()
			}
		}
	}

	/**
	 * Upraví data jednoho objektu fotky a přenastaví stav.
	 *
	 * @method _updatePhotoState
	 * @private
	 * @param {String} photoId Id fotky, jejiž stav se bude měnit.
	 * @param {Object} update  Data, která se mají změnit.
	 */
	_updatePhotoState(photoId, update = {}) {
		const photos = this._getPhotos()

		const updatedPhotos = photos.map((photo) => {
			if (photo.id === photoId) {
				return Object.assign({}, photo, update)
			} else {
				return photo
			}
		})

		this._setPhotosToState(updatedPhotos)
	}

	/**
	 * Odebere fotku ze state.
	 *
	 * @method _removePhotoFromState
	 * @private
	 * @param {String} photoId Id fotky, kterou má odebrat ze state.
	 */
	_removePhotoFromState(photoId) {
		this._setPhotosToState(this._getPhotosExceptId(photoId))
	}

	/**
	 * Nastaví do state pole s daty fotek. Provede u nich seřezení, tak aby nejprve byly fotky, které se nahrály v pořádku a až poté fotky s chybou.
	 *
	 * @method _setPhotosToState
	 * @private
	 * @param {Array<Object>} photos Pole objektů s daty fotek, které nastaví do state.
	 */
	_setPhotosToState(photos) {
		const okPhotos = photos.filter(({ error }) => !error)
		const errPhotos = photos.filter(({ error }) => error)

		this.setState({
			[PhotosUploaderExtension.stateKeys.PHOTOS]: [...okPhotos, ...errPhotos]
		})
	}

	/**
	 * Vrátí objekt fokty podle jeho ID.
	 *
	 * @method _getPhoto
	 * @private
	 * @param {String} photoId Id fotky, kterou má vrátit.
	 * @returns {Object|Undefined} Objekt s daty fotky nebo undefined pokud ji nenajde.
	 */
	_getPhoto(photoId) {
		const photos = this._getPhotos()
		return photos.find(({ id }) => id === photoId)
	}

	/**
	 * Vrátí pole všech fotek, kromě té, které odpovídá zadné id.
	 *
	 * @method _getPhotosExceptId
	 * @private
	 * @param {String} photoId Id fotky, kterou má vyloučit ze seznamu všech fotek.
	 * @returns {Array<Object>} Seznam všech fotek kromě "vyloučené".
	 */
	_getPhotosExceptId(photoId) {
		const photos = this._getPhotos()
		return photos.filter((photo) => photo.id !== photoId)
	}

	async _getMinimalPhotos() {
		const { [PhotosUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntity } = this.getState()

		const { conditionCb = {}, category = {} } = (await advertEntity) || {}
		const { id: categoryId } = category
		const { value: conditionValue } = conditionCb

		// pro kategorii osobni automobily a stav nove je povinna 1 fotka, jinak 3
		return categoryId === CATEGORIES.PASSENGER_CARS.id && conditionValue === CAR_CONDITION.NEW.value
			? 1
			: 3
	}

	_getAdvertId() {
		const { [PhotosUploaderExtension.stateKeys.ADVERT_ENTITY]: advertEntity } = this.getState()
		return advertEntity.id
	}

	_getPhotos() {
		const { [PhotosUploaderExtension.stateKeys.PHOTOS]: photos } = this.getState()
		return photos
	}
}
