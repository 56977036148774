import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './AutoCompleteFilteredForm.less'

const CLASSNAME = 'c-auto-complete-filtered-form'

const AutoCompleteFilteredForm = (props) => {
	const {
		filteredItemsLabel = '',
		filteredItems = DEFAULT_PROPS.ARRAY,
		renderItem = DEFAULT_PROPS.FUNCTION
	} = props

	return (
		Boolean(filteredItems.length) && (
			<>
				{filteredItemsLabel && (
					<div className={`${CLASSNAME}__group-found`}>{filteredItemsLabel}</div>
				)}
				<div>{filteredItems.map((option, index) => renderItem(option, index))}</div>
			</>
		)
	)
}

AutoCompleteFilteredForm.propTypes = {
	filteredItemsLabel: PropTypes.string.isRequired,
	filteredItems: PropTypes.array.isRequired,
	renderItem: PropTypes.func.isRequired
}

export default React.memo(AutoCompleteFilteredForm)
