import { FORM_LINES_IDS } from './HistoryFormLineIds'

export const HISTORY_SECTION = [
	FORM_LINES_IDS.GUARANTEE_DATE,
	FORM_LINES_IDS.EURO_LEVEL_CB,
	FORM_LINES_IDS.ENVIRONMENTAL_TAX_PAID,
	FORM_LINES_IDS.FIRST_OWNER,
	FORM_LINES_IDS.ADJUSTMENTS_FOR_HANDICAPPED,
	FORM_LINES_IDS.CRASHED_IN_PAST,
	FORM_LINES_IDS.TUNING,
	FORM_LINES_IDS.SERVICE_BOOK
]
