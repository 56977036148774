import { AbstractFactory } from '@inzeraty/models'
import AdvertStatisticsEntity from './AdvertStatisticsEntity'

export default class AdvertStatisticsFactory extends AbstractFactory {
	constructor() {
		super(AdvertStatisticsEntity)
	}

	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformData(data = {}) {
		const { group_by = {}, metrics = {} } = data
		const { custom_id, vin } = metrics

		return Object.assign(
			{
				groupBy: {
					dimension: group_by.dimension,
					id: group_by.id,
					name: group_by.name
				},
				metrics: {
					activeAdvert: metrics.active_advert,
					detailView: metrics.detail_view,
					detailViewOperatingLease: metrics.detail_view_operating_lease,
					detailViewMirroring: metrics.detail_view_mirroring,
					detailViewFromList: metrics.detail_view_from_list,
					detailViewFromListOperatingLease: metrics.detail_view_from_list_operating_lease,
					detailViewFromListMirroring: metrics.detail_view_from_list_mirroring,
					detailViewFromOther: metrics.detail_view_from_other,
					detailViewFromOtherOperatingLease: metrics.detail_view_from_other_operating_lease,
					detailViewFromOtherMirroring: metrics.detail_view_from_other_mirroring,
					listView: metrics.list_view,
					listViewOperatingLease: metrics.list_view_operating_lease,
					listViewMirroring: metrics.list_view_mirroring,
					phoneView: metrics.phone_view,
					phoneViewOperatingLease: metrics.phone_view_operating_lease,
					phoneViewMirroring: metrics.phone_view_mirroring,
					emailView: metrics.email_view,
					emailViewOperatingLease: metrics.email_view_operating_lease,
					emailViewMirroring: metrics.email_view_mirroring,
					replySent: metrics.reply_sent,
					replySentOperatingLease: metrics.reply_sent_operating_lease,
					replySentMirroring: metrics.reply_sent_mirroring,
					POView: metrics.po_view,
					detailViewFromPO: metrics.detail_view_from_po,
					topped: metrics.topped,
					toppedPriceSum: metrics.topped_price_sum,
					toppedPriceSumWithoutVat: metrics.topped_price_sum_without_vat,
					toppedCount: metrics.topped_count
				}
			},
			custom_id && { customIds: custom_id },
			vin && { vins: vin }
		)
	}

	transformDataForListItems(data = {}) {
		return Object.keys(data).reduce((res, advertId) => {
			const {
				detail_view: detailView,
				detail_view_operating_lease: detailViewOperatingLease,
				detail_view_mirroring: detailViewMirroring,
				phone_view: phoneView,
				phone_view_operating_lease: phoneViewOperatingLease,
				phone_view_mirroring: phoneViewMirroring,
				reply_sent: replySent,
				reply_sent_operating_lease: replySentOperatingLease,
				reply_sent_mirroring: replySentMirroring,
				favorite
			} = data[advertId] || {}

			return Object.assign({}, res, {
				[advertId]: {
					detailView,
					detailViewOperatingLease,
					detailViewMirroring,
					phoneView,
					phoneViewOperatingLease,
					phoneViewMirroring,
					replySent,
					replySentOperatingLease,
					replySentMirroring,
					favorite
				}
			})
		}, {})
	}
}
