
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SellerCard'] = {
  open: function(d) { return "otevřeno"; },
  closed: function(d) { return "zavřeno"; },
  nonstop: function(d) { return "nonstop"; },
  monday: function(d) { return "pondělí"; },
  tuesday: function(d) { return "úterý"; },
  wednesday: function(d) { return "středa"; },
  thursday: function(d) { return "čtvrtek"; },
  friday: function(d) { return "pátek"; },
  saturday: function(d) { return "sobota"; },
  sunday: function(d) { return "neděle"; },
  today: function(d) { return "dnes"; },
  showPhone: function(d) { return "Zobrazit telefon"; },
  showEmail: function(d) { return "Zobrazit e-mail"; },
  showMore: function(d) { return "Více"; },
  moreInfo: function(d) { return "Více informací"; },
  textLoaded: function(d) { return "Načteno"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	