import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'
import { Icon } from '@sznds/react'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import { Responsive, Loading } from '@inzeraty/components'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { RESPONSIVE } from 'app/base/Constants'
import { useLocalize } from 'app/base/componentHelpers'
import AutoComplete, { filterItems, pipe } from 'app/component/autoComplete/AutoComplete'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import AutoCompleteAllForm from 'app/component/autoComplete/forms/all/AutoCompleteAllForm'
import { StickyPanelVisibilityManager } from 'app/component/filters/FiltersUtils'

import './RegionDistrictSearch.less'
import './RegionDistrictSearchCS.json'

const CLASSNAME = 'c-region-district-search'

const DROPDOWN = 'DROPDOWN'
const POPUP = 'POPUP'

const REGION = 'REGION'
const DISTRICT = 'DISTRICT'

// protoze v teto komponente slucujeme kraje a okresy do jednoho seznamu,
// musime upravit id okresu tak, aby nedochazelo ke kolizim s id kraji.
const OFFSET_FOR_DISTRICTS = 1000

const stickyPanelVisibilityManager = new StickyPanelVisibilityManager()

const getMergedRegionsAndDistricts = (formLineEntity = {}) => {
	const { options = [] } = formLineEntity

	const regions = options.map((region) => {
		const { id, name, value } = region
		return {
			id,
			name,
			value,
			type: REGION
		}
	})

	const districts = options.reduce((accumulator, region) => {
		const { children } = region
		const regionDistricts = children.map((district) => {
			const { id, name, value } = district
			return {
				id: id + OFFSET_FOR_DISTRICTS,
				name,
				value: value + OFFSET_FOR_DISTRICTS,
				type: DISTRICT,
				parentRegion: region
			}
		})
		return accumulator.concat(regionDistricts)
	}, [])

	return regions.concat(districts)
}

const findItem = (selectedItem = {}, mergedRegionAndDistricts = []) => {
	const region = mergedRegionAndDistricts.find(
		({ value, name }) => value === selectedItem.value && name === selectedItem.name
	)

	if (region) {
		return region
	} else {
		const disctrict = mergedRegionAndDistricts.find(
			({ value, name }) =>
				value === selectedItem.value + OFFSET_FOR_DISTRICTS && name === selectedItem.name
		)

		if (disctrict) {
			return disctrict
		}
	}
}

const sortOptionsByTypeAndAlphabetically = (itemA = {}, itemB = {}) => {
	const { name: nameA = '', type: typeA = '' } = itemA
	const { name: nameB = '', type: typeB = '' } = itemB

	if (typeA === typeB) {
		return nameA.localeCompare(nameB)
	} else if (typeA === REGION) {
		return -1
	} else {
		return 1
	}
}

const RegionDistrictSearch = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		changeFilter = DEFAULT_PROPS.FUNCTION,
		removeFilter = DEFAULT_PROPS.FUNCTION,
		advertsCount: {
			filteredAdvertsCount = DEFAULT_PROPS.ARRAY,
			isLoadingFilteredAdvertsCount,
			loadFilteredAdvertsCount = DEFAULT_PROPS.FUNCTION
		} = DEFAULT_PROPS.OBJECT,
		onSelect = DEFAULT_PROPS.FUNCTION,
		onClose = DEFAULT_PROPS.FUNCTION,
		autoFocus = false,
		initialIsOpen = false
	} = props

	const { options = [] } = formLineEntity

	const regions = options.map((region) => {
		const { id, name, value } = region

		return {
			id,
			name,
			value,
			type: REGION
		}
	})

	const mergedRegionAndDistricts = getMergedRegionsAndDistricts(formLineEntity)

	const selectedItem = findItem(props.selectedItem, mergedRegionAndDistricts)

	const localize = useLocalize()

	const [searchedTerm, setSearchedTerm] = useState(selectedItem ? selectedItem.name : '')

	// pri zmene nepotrebujeme prekreslovat (proto useRef misto useState)
	const activeModalTypeRef = useRef()

	useEffect(() => {
		if (initialIsOpen) {
			loadFilteredAdvertsCount()
			stickyPanelVisibilityManager.hide()
		}
	}, [])

	const renderInput = (formLineEntity, downshift) => {
		const {
			getInputProps,
			getToggleButtonProps,
			getClearButtonProps,

			isOpen,
			inputValue
		} = downshift

		const { name } = formLineEntity

		const filteredItems = mergedRegionAndDistricts
			.filter((item) => filterItems(inputValue, item.name))
			.sort(sortOptionsByTypeAndAlphabetically)

		return (
			<div className={`${CLASSNAME}__wrapper`}>
				<AutoCompleteInput
					isLoading={!mergedRegionAndDistricts.length}
					inputSurfaceProps={{
						size: 'small',
						className: `${CLASSNAME}__search-term`,
						'data-e2e': 'region-district-search-input',
						'data-dot': 'show-values'
					}}
					inputProps={getInputProps({
						name,
						autoFocus,
						placeholder: localize('RegionDistrictSearch.searchRegionDistrict')
					})}
					toggleButtonProps={
						!inputValue
							? getToggleButtonProps({
									isOpen
							  })
							: undefined
					}
					clearButtonProps={
						inputValue
							? getClearButtonProps({
									onClick: () => {
										downshift.setState({
											inputValue: '',
											selectedItem: undefined
										})

										if (props.selectedItem) {
											removeFilter()
										}
									}
							  })
							: undefined
					}
					renderLeftIcon={() => <Icon symbol={SEARCH_OUTLINE_24} />}
				/>

				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => renderPopup(formLineEntity, filteredItems, downshift)}
					renderDesktopElement={() => renderDropdown(formLineEntity, filteredItems, downshift)}
				/>
			</div>
		)
	}

	const renderDropdown = (formLineEntity, filteredItems, downshift) => {
		activeModalTypeRef.current = DROPDOWN

		const {
			isOpen,

			getDropdownProps,
			renderDropdown: Dropdown
		} = downshift

		return (
			isOpen && (
				<Dropdown {...getDropdownProps()}>
					{Boolean(filteredItems.length) && renderItems(filteredItems, downshift)}
				</Dropdown>
			)
		)
	}

	const renderPopup = (formLineEntity, filteredItems, downshift) => {
		activeModalTypeRef.current = POPUP

		const {
			isOpen,
			getInputProps,
			getClearButtonProps,
			getPopupProps,
			renderPopup: Popup
		} = downshift

		return (
			isOpen && (
				<PopupRenderer
					popupComponent={Popup}
					getPopupProps={getPopupProps}
					getInputProps={getInputProps}
					getClearButtonProps={getClearButtonProps}
					filteredItems={filteredItems}
					renderItems={renderItems}
					downshift={downshift}
					handleInputBlur={handleInputBlur}
					selected={props.selectedItem}
					formLineEntity={formLineEntity}
					regions={regions}
				/>
			)
		)
	}

	const renderItems = (items, downshift) => {
		const { getItemProps, highlightedIndex } = downshift
		const regions = items.filter((item) => item.type === REGION)
		const districts = items.filter((item) => item.type === DISTRICT)

		const renderItem = (option = {}, index) => {
			const getAdvertCount = (option) => {
				const { type, value } = option
				const [countsForRegions = {}, countsForDistricts = {}] = filteredAdvertsCount

				if (type === REGION) {
					return countsForRegions[value] || 0
				} else if (type === DISTRICT) {
					return countsForDistricts[value - OFFSET_FOR_DISTRICTS] || 0
				}

				return 0
			}

			const { value, name } = option

			return (
				<AutoCompleteOption
					{...getItemProps({
						key: value,
						index,
						item: option,
						isHighlighted: highlightedIndex === index,
						className: classnames({
							[`${CLASSNAME}__item`]: true
						}),
						'data-dot': 'choose-value',
						'data-dot-data': `{"value": "${name}"}`
					})}
				>
					<span>{name}</span>

					<div className={`${CLASSNAME}__count`}>
						{isLoadingFilteredAdvertsCount ? (
							<Loading
								className={`${CLASSNAME}__loading-dots`}
								loadingType={Loading.loadingType.ONLY_DOT}
							/>
						) : (
							getAdvertCount(option)
						)}
					</div>
				</AutoCompleteOption>
			)
		}

		return <ItemsForm regions={regions} districts={districts} renderItem={renderItem} />
	}

	const handleSelect = (selectedItem) => {
		const removeInitiallySelectedRegionOrDistrict = (
			selectedItem = {},
			newSelectedRegions = new Map()
		) => {
			const type = selectedItem.parentRegion ? DISTRICT : REGION

			if (type === REGION) {
				const { value: regionValue } = selectedItem

				const result = new Map([...newSelectedRegions])
				result.delete(regionValue)

				return result
			} else if (type === DISTRICT) {
				const { value: districtValue, parentRegion: { value: regionValue } = {} } = selectedItem

				const districtsForRegion = new Set(newSelectedRegions.get(regionValue))

				districtsForRegion.delete(districtValue)

				if (districtsForRegion.size) {
					return new Map([...newSelectedRegions, [regionValue, districtsForRegion]])
				} else {
					const result = new Map([...newSelectedRegions])

					result.delete(regionValue)

					return result
				}
			}

			return newSelectedRegions
		}

		if (selectedItem) {
			const { id, value = new Map() } = formLineEntity
			const { type, value: selectedValue } = selectedItem
			const { value: initialValue } = findItem(props.selectedItem, mergedRegionAndDistricts) || {}

			if (selectedValue === initialValue) {
				return
			}

			setSearchedTerm('')

			onSelect(selectedItem)

			if (type === REGION) {
				const { value: regionValue } = selectedItem

				const newSelectedRegions = new Map([
					...removeInitiallySelectedRegionOrDistrict(props.selectedItem, value),
					// pri vyberu celeho kraje vymazeme dilci okresy pro dany kraj
					[regionValue, new Set()]
				])

				changeFilter(id, newSelectedRegions)
			} else if (type === DISTRICT) {
				const { value: districtValue, parentRegion: { value: regionValue } = {} } = selectedItem

				const updatedValue = removeInitiallySelectedRegionOrDistrict(props.selectedItem, value)

				const newDistrictsSetForRegion = new Set(updatedValue.get(regionValue))

				newDistrictsSetForRegion.add(districtValue - OFFSET_FOR_DISTRICTS)

				const newSelectedRegions = new Map([
					...updatedValue,
					[regionValue, newDistrictsSetForRegion]
				])

				changeFilter(id, newSelectedRegions)
			}
		}
	}

	const stateReducer = (state, changes) => {
		const loadFilteredAdvertsCountOnOpen = (changes) => {
			// pri otevreni dropdownu nebo popupu automaticky nacteme
			// cisilka poctu inzeraty pro jednotlive kraje a okresy
			if (!state.isOpen && changes.isOpen) {
				loadFilteredAdvertsCount()
			}

			return changes
		}

		const evalStickyPanelVisibility = (changes) => {
			if (!state.isOpen && changes.hasOwnProperty('isOpen') && changes.isOpen) {
				stickyPanelVisibilityManager.hide()
			}

			if (state.isOpen && changes.hasOwnProperty('isOpen') && !changes.isOpen) {
				stickyPanelVisibilityManager.restore()
				onClose()
			}

			// kvuli enteru na popupu
			if (state.isOpen && changes.type === AutoComplete.stateChangeTypes.keyDownEnter) {
				stickyPanelVisibilityManager.restore()
				onClose()
			}

			return changes
		}

		const closeDropdownOnNothingFound = (changes) => {
			// pokud se nepodari pri zafiltrovani nic najit,
			// tak dropdown zavreme
			if ((state.isOpen || changes.isOpen) && activeModalTypeRef.current === DROPDOWN) {
				const inputValue = changes.inputValue || state.inputValue

				const filteredItems = mergedRegionAndDistricts.filter((item) =>
					filterItems(inputValue, item.name)
				)

				if (!filteredItems.length) {
					return Object.assign({}, changes, {
						isOpen: false
					})
				}
			}

			return changes
		}

		return pipe(
			loadFilteredAdvertsCountOnOpen,
			evalStickyPanelVisibility,
			closeDropdownOnNothingFound
		)(changes)
	}

	const handleInputBlur = (stateAndHelpers) => {
		// chceme upravovat stav pouze pokud upravujeme jiz
		// pridany kraj nebo okres
		if (!props.selectedItem) {
			return
		}

		const { inputValue, selectedItem, setState } = stateAndHelpers

		if (!inputValue) {
			// uzivatel smazal input uplne, odstranime tento filtr
			removeFilter()
		} else if (selectedItem && selectedItem.name !== inputValue) {
			// uzivatel castecne prepsal input, pokusime se input nastavit
			// podle interne ulozene hodnoty selectu
			setState({
				inputValue: selectedItem.name
			})
		} else if (props.selectedItem && props.selectedItem.name !== inputValue) {
			// uzivatel castecne prepsal input, pokusime se input nastavit
			// podle hodnoty, ktera byla vybrana jako prvni (v props)
			const selectedItem = findItem(props.selectedItem, mergedRegionAndDistricts)

			setState({
				selectedItem,
				inputValue: selectedItem.name
			})
		}
	}

	return (
		<AutoComplete
			inputValue={searchedTerm}
			onInputValueChange={setSearchedTerm}
			initialSelectedItem={selectedItem}
			onSelect={handleSelect}
			onInputBlur={handleInputBlur}
			stateReducer={stateReducer}
			initialIsOpen={initialIsOpen}
			size='small'
		>
			{(downshift) => renderInput(formLineEntity, downshift)}
		</AutoComplete>
	)
}

RegionDistrictSearch.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	selectedItem: PropTypes.object,
	changeFilter: PropTypes.func.isRequired,
	removeFilter: PropTypes.func,
	advertsCount: PropTypes.object.isRequired,
	onSelect: PropTypes.func,
	onClose: PropTypes.func,
	autoFocus: PropTypes.bool,
	initialIsOpen: PropTypes.bool
}

export default React.memo(RegionDistrictSearch)

const PopupRenderer = ({
	popupComponent: Popup,
	getPopupProps,
	getClearButtonProps,
	renderItems,
	downshift,
	handleInputBlur,
	selected,
	formLineEntity,
	regions
}) => {
	const localize = useLocalize()

	useEffect(() => {
		// HACK: nechceme, aby na mobilu po zavreni popupu byl focus na inputu
		document.activeElement?.blur()
	}, [])

	const { closeMenu } = downshift

	const mergedRegionAndDistricts = getMergedRegionsAndDistricts(formLineEntity)

	const selectedItem = findItem(selected, mergedRegionAndDistricts)
	const [searchedTerm, setSearchedTerm] = useState(selectedItem ? selectedItem.name : '')

	const filteredItems = mergedRegionAndDistricts
		.filter((item) => filterItems(searchedTerm, item.name))
		.sort(sortOptionsByTypeAndAlphabetically)

	return (
		<Popup
			{...getPopupProps({
				title: localize('RegionDistrictSearch.chooseRegionDistrict'),
				className: `${CLASSNAME}__popup`,
				onClose: () => closeMenu(() => handleInputBlur(downshift))
			})}
		>
			<div className={`${CLASSNAME}__popup-input-wrapper`}>
				<AutoCompleteInput
					inputSurfaceProps={{
						size: 'small'
					}}
					inputProps={{
						placeholder: localize('RegionDistrictSearch.searchRegionDistrict'),
						value: searchedTerm,
						onChange: (event) => setSearchedTerm(event.target.value),
						onBlur: () => {} // prepiseme defaultni chovani, na blur
						// nechceme nijak reagovat
					}}
					clearButtonProps={
						searchedTerm
							? getClearButtonProps({
									onClick: () => setSearchedTerm('')
							  })
							: undefined
					}
					renderLeftIcon={() => <Icon symbol={SEARCH_OUTLINE_24} />}
				/>
			</div>

			{Boolean(filteredItems.length) && renderItems(filteredItems, downshift)}
		</Popup>
	)
}

PopupRenderer.propTypes = {
	popupComponent: PropTypes.func,
	getPopupProps: PropTypes.func,
	getClearButtonProps: PropTypes.func,
	renderItems: PropTypes.func,
	downshift: PropTypes.object,
	handleInputBlur: PropTypes.func,
	selected: PropTypes.object,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	regions: PropTypes.arrayOf(PropTypes.object)
}

const ItemsForm = ({
	regions = DEFAULT_PROPS.ARRAY,
	districts = DEFAULT_PROPS.ARRAY,
	renderItem = DEFAULT_PROPS.FUNCTION
}) => {
	const localize = useLocalize()
	return (
		<AutoCompleteAllForm
			allItemsLabel={localize('RegionDistrictSearch.districts')}
			items={districts}
			favoriteItemsLabel={localize('RegionDistrictSearch.regions')}
			favoriteItems={regions}
			renderItem={renderItem}
			renderFavoriteItem={renderItem}
		/>
	)
}

ItemsForm.propTypes = {
	regions: PropTypes.array.isRequired,
	districts: PropTypes.array.isRequired,
	renderItem: PropTypes.func.isRequired
}
