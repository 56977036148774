import React from 'react'
import { Surface } from '@sznds/react'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

import './AutoMotoArticlesPlaceholders.less'

const CLASSNAME = 'c-auto-moto-placeholders'

const AutoMotoArticlesPlaceholders = () => {
	const PLACEHOLDER_ARRAY = Array(UrlConvertor.constants.DEFAULTS.PER_PAGE).fill({})

	const renderArticle = () => {
		return (
			<Surface surface={5} className={`${CLASSNAME}__article`}>
				<div className={`${CLASSNAME}__img-wrap`}></div>
				<div className={`${CLASSNAME}__article-content`}>
					<div className={`${CLASSNAME}__title-wrapper`}></div>
					<div className={`${CLASSNAME}__perex`}>
						<div className={`${CLASSNAME}__perex-1`}></div>
						<div className={`${CLASSNAME}__perex-2`}></div>
						<div className={`${CLASSNAME}__perex-3`}></div>
					</div>
				</div>
			</Surface>
		)
	}

	return (
		<div className={CLASSNAME}>
			{PLACEHOLDER_ARRAY.map((placeholder, index) => (
				<div key={`${index}-placeholder`} className={`${CLASSNAME}__article-wrapper`}>
					{renderArticle()}
				</div>
			))}
		</div>
	)
}

export default AutoMotoArticlesPlaceholders
