import React, { memo, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import PremiseService from 'app/model/premise/PremiseService'
import { useLocalize, useLink } from 'app/base/componentHelpers'
import UserwebAdvert from 'app/component/adverts/UserwebAdvertView'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import RouteNames from 'app/base/RouteNames'
import { useFire } from 'app/base/componentHelpers'
import { CATEGORIES } from 'app/base/Constants'
import Context from 'ima/page/context'
import { ElementInViewportDetector } from '@inzeraty/components'

import './RelatedAdverts.less'
import './RelatedAdvertsCS.json'

const CLASSNAME = 'c-related-items'

const RelatedAdverts = ({
	isAdvertEntityLoaded = false,
	relatedAdvertEntities = DEFAULT_PROPS.ARRAY,
	isOperatingLease = false,
	advertEntity = DEFAULT_PROPS.OBJECT
}) => {
	const localize = useLocalize()
	const fire = useFire()
	const link = useLink()

	const [isReadyToDownloadRelatedAdverts, setIsReadyToDownloadRelatedAdverts] = useState(false)
	const [isReadyToSendAnalytics, setIsReadyToSendAnalytics] = useState(false)
	const { $Router, AnalyticsDataSender } = useContext(Context)
	const { premise = {}, category = {} } = advertEntity

	useEffect(() => {
		setIsReadyToDownloadRelatedAdverts(true)
		setIsReadyToSendAnalytics(true)
	}, [isAdvertEntityLoaded])

	const getSellerUrl = () => {
		const { seoName: sellerSeoName } = premise
		const { seoName: categorySeoName } = category

		return link(
			isOperatingLease
				? RouteNames.USERWEB.SELLER_WITH_OPERATING_LEASES
				: RouteNames.USERWEB.SELLER,
			Object.assign(
				{},
				{
					[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_SEO_NAME]: sellerSeoName,
					[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID]: PremiseService.getPremiseId(
						premise
					)
				},
				categorySeoName && categorySeoName !== CATEGORIES.PASSENGER_CARS.seoName
					? {
							[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: categorySeoName
					  }
					: {}
			)
		)
	}

	const _getRouteName = () => {
		const { route } = $Router.getCurrentRouteInfo()
		return route.getName()
	}

	const _sendAnalyticsDataOnVisibleImpress = () => {
		AnalyticsDataSender.hitForVisibleImpress({
			elementType: 'Related',
			page: _getRouteName()
		})
	}

	const _onElementInViewportRecalc = ({ isElementInsideViewport }) => {
		if (
			isElementInsideViewport &&
			isAdvertEntityLoaded &&
			isReadyToSendAnalytics &&
			relatedAdvertEntities.length
		) {
			setIsReadyToSendAnalytics(false)
			_sendAnalyticsDataOnVisibleImpress()
		}
	}

	const _onElementInViewportRecalcForDownloadAdverts = ({ isElementInsideViewport }) => {
		if (isElementInsideViewport && isAdvertEntityLoaded && isReadyToDownloadRelatedAdverts) {
			setIsReadyToDownloadRelatedAdverts(false)
			fire('downloadRelatedAdverts')
		}
	}

	return (
		<>
			<ElementInViewportDetector
				onRecalc={_onElementInViewportRecalcForDownloadAdverts}
				offsetTop={500}
			></ElementInViewportDetector>

			<ElementInViewportDetector onRecalc={_onElementInViewportRecalc} offsetTop={-150}>
				{Boolean(relatedAdvertEntities.length) && (
					<div className={CLASSNAME} data-dot='related-items'>
						<h2 className={`${CLASSNAME}__header`}>{localize('RelatedAdverts.header')}</h2>
						<ul>
							{relatedAdvertEntities.map((advertEntity, idx) => {
								const { id } = advertEntity

								return (
									<UserwebAdvert
										key={id}
										index={idx}
										advertEntity={advertEntity}
										isOperatingLease={isOperatingLease}
									/>
								)
							})}
						</ul>
						<div className={`${CLASSNAME}__bottom`}>
							<Button
								href={getSellerUrl()}
								className={`${CLASSNAME}__button`}
								text={localize('RelatedAdverts.nextAdverts')}
								size='small'
								data-dot='go-to-seller'
							/>
						</div>
					</div>
				)}
			</ElementInViewportDetector>
		</>
	)
}

RelatedAdverts.propTypes = {
	isAdvertEntityLoaded: PropTypes.bool,
	advertEntity: PropTypes.shape(advertPropTypes),
	isOperatingLease: PropTypes.bool,
	relatedAdvertEntities: PropTypes.arrayOf(PropTypes.shape(advertPropTypes))
}

export default memo(RelatedAdverts)
