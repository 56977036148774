import React, { forwardRef } from 'react'
import { Modal as ModalComp } from '@inzeraty/components'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ModalFooterButtons from './ModalFooterButtons'

import './ModalCS.json'
import './ModalView.less'

const CLASSNAME = 'c-modal'

const Modal = forwardRef((props, ref) => {
	const localize = useLocalize()
	const { className } = props

	const modalProps = Object.assign({}, props, {
		dictionary: Object.assign(
			{
				ariaClose: localize('Modal.ariaClose'),
				blindClosePrefix: localize('Modal.blindClosePrefix')
			},
			props.dictionary
		),
		className: classnames({
			[className]: !!className,
			[CLASSNAME]: true
		})
	})

	return <ModalComp {...modalProps} />
})

Modal.POSITION = ModalComp.POSITION

Modal.STYLE = ModalComp.STYLE

Modal.propTypes = {
	className: PropTypes.string,
	dictionary: PropTypes.shape({
		[PropTypes.string]: PropTypes.string
	})
}

export default Modal
export { ModalFooterButtons }
