
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Wallet'] = {
  walletLabel: function(d) { return "Peněženka:"; },
  walletRechargeLink: function(d) { return "Dobít kredit"; },
  walletNotConnected: function(d) { return "Není připojena"; },
  walletConnect: function(d) { return "Připojit"; },
  walletActivateLink: function(d) { return "Aktivovat"; },
  walletNotActivated: function(d) { return "Není aktivovaná"; },
  walletOutOfOrder: function(d) { return "Mimo provoz"; },
  refreshPage: function(d) { return "Načíst znova"; },
  emailSubject: function(d) { return "Prodejce " + d.PREMISE_NAME + " žádá o přidělení peněženkového účtu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	