
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['HomeDelivery'] = {
  carToHome: function(d) { return "Auto domů"; },
  tooltipText: function(d) { return "Máte vyhlídnuté auto, ale současný stav vám nedovoluje zajet se na něj podívat? Nevadí. Vybraní prodejci vozidel nově nabízejí službu, kdy vám přivezou vůz k prohlídce až před váš dům. Přesné podmínky služby vám sdělí konkrétní inzerent."; },
  tooltipAriaClose: function(d) { return "Zavřít"; },
  tooltipBlindClosePrefix: function(d) { return "Konec tooltipu - "; },
  tooltipAriaOpener: function(d) { return "Nápověda k akci \"Auto domů\""; },
  tooltipAriaTitle: function(d) { return "Nápověda zní: "; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	