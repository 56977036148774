
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertBasicInfo'] = {
  priceWithoutVat: function(d) { return "Bez DPH"; },
  showMorePriceInfo: function(d) { return "Zobrazit více o ceně"; },
  priceLeasing: function(d) { return "Leasing"; },
  priceCompensation: function(d) { return "Odstupné"; },
  pricePayment: function(d) { return "Splátky"; },
  priceNote: function(d) { return "Poznámka k ceně"; },
  dateAvailability: function(d) { return "Termín dodání " + d.DATE; },
  inStockAvailability: function(d) { return "Skladem"; },
  orderAvailability: function(d) { return "Na objednání"; },
  countInsurance: function(d) { return "Vypočítat povinné ručení"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	