import { Format } from '@inzeraty/helpers'
import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'

import './AdvertDetailMetaCS.json'
import 'app/base/BaseCS.json'

export default class AdvertDetailMeta {
	static get constants() {
		return deepFreeze({
			CATEGORY_ID: {
				BUS_ID: 846,
				VANS_ID: 839,
				MOTORCYCLE_ID: 841
			},
			VEHICLE_BODY_ID: {
				BUS: {
					CITY_BUS: 23,
					INTERCITY_BUS: 24,
					HIGHWAY_BUS: 25
				},
				MOTORCYCLE: {
					ROAD_CYCLE: 35,
					TERAIN_CYCLE: 39
				}
			}
		})
	}

	static getDetailTitle(dictionary, advertEntity = {}) {
		const { manufacturerCb = {}, modelCb = {}, nameSuffix } = advertEntity

		const brandName = manufacturerCb.name ? `${manufacturerCb.name}` : ''
		const modelName = modelCb.name ? ` ${modelCb.name}` : ''
		const titleAddition = nameSuffix ? ` ${nameSuffix}` : ''

		return `${brandName}${modelName}${titleAddition} ${dictionary.get(
			'AdvertDetailMeta.titleService'
		)}`
	}

	static getDetailDescription(dictionary, advertEntity = {}) {
		const {
			manufacturerCb,
			modelCb,
			nameSuffix,
			category,
			vehicleBodyCb,
			conditionCb,
			engineVolume,
			fuelCb,
			colorCb,
			tachometer,
			price,
			premise,
			locality = {}
		} = advertEntity

		const categoryId = AdvertDetailMeta.constants.CATEGORY_ID

		let descriptionSentence = ''
		descriptionSentence += dictionary.get('AdvertDetailMeta.descIntro')
		if (category && category.id) {
			if (category.id === categoryId.VANS_ID) {
				descriptionSentence += ` ${dictionary.get('AdvertDetailMeta.descVans')}`
			} else if (category.id === categoryId.BUS_ID) {
				descriptionSentence += AdvertDetailMeta.renderBusName(vehicleBodyCb, dictionary)
			} else if (category.id === categoryId.MOTORCYCLE_ID) {
				descriptionSentence += AdvertDetailMeta.renderMotorcycleName(vehicleBodyCb, dictionary)
			} else {
				if (vehicleBodyCb && vehicleBodyCb.name) {
					descriptionSentence += ` ${vehicleBodyCb.name}`
				}
			}
		}
		descriptionSentence += manufacturerCb && manufacturerCb.name ? ` ${manufacturerCb.name}` : ''
		descriptionSentence += modelCb && modelCb.name ? ` ${modelCb.name}` : ''
		descriptionSentence += nameSuffix ? `, ${nameSuffix}.` : '.'
		descriptionSentence +=
			conditionCb && conditionCb.name
				? ` ${dictionary.get('AdvertDetailMeta.descCondition')}: ${conditionCb.name};`
				: ''
		descriptionSentence += engineVolume
			? ` ${dictionary.get('AdvertDetailMeta.descEngine')}: ${Format.number(
					engineVolume
			  )} ${dictionary.get('Base.CCM')}`
			: ''
		descriptionSentence += fuelCb && fuelCb.name ? `, ${fuelCb.name};` : ''
		descriptionSentence +=
			colorCb && colorCb.name
				? ` ${dictionary.get('AdvertDetailMeta.descColor')} ${colorCb.name};`
				: ''
		descriptionSentence += tachometer
			? ` ${dictionary.get('AdvertDetailMeta.descTachometer')} ${Format.number(
					tachometer
			  )} ${dictionary.get('Base.KM')};`
			: ''
		descriptionSentence += price
			? ` ${dictionary.get('AdvertDetailMeta.descPrice')} ${Format.number(price)} ${dictionary.get(
					'Base.CZK'
			  )};`
			: ''
		descriptionSentence += premise
			? ` ${dictionary.get('AdvertDetailMeta.descSeller')} ${premise.name}`
			: ''
		if (premise && locality.district) {
			descriptionSentence += ` - ${dictionary.get('AdvertDetailMeta.descDistrict')} ${
				locality.district
			}`
		} else if (locality.district) {
			descriptionSentence += ` ${dictionary.get('AdvertDetailMeta.descDistrict')} ${
				locality.district
			}`
		}

		return descriptionSentence
	}

	static renderBusName(vehicleBodyCb = {}, dictionary) {
		const vehicleBodyCbValue = AdvertDetailMeta.constants.VEHICLE_BODY_ID.BUS

		if (vehicleBodyCb.value && vehicleBodyCb.name) {
			if (
				vehicleBodyCb.value === vehicleBodyCbValue.CITY_BUS ||
				vehicleBodyCb.value === vehicleBodyCbValue.INTERCITY_BUS ||
				vehicleBodyCb.value === vehicleBodyCbValue.HIGHWAY_BUS
			) {
				return ` ${vehicleBodyCb.name} ${dictionary.get('AdvertDetailMeta.descBus')}`
			} else {
				return ` ${vehicleBodyCb.name}`
			}
		} else {
			return ''
		}
	}

	static renderMotorcycleName(vehicleBodyCb = {}, dictionary) {
		const vehicleBodyCbValue = AdvertDetailMeta.constants.VEHICLE_BODY_ID.MOTORCYCLE

		if (vehicleBodyCb.value && vehicleBodyCb.name) {
			if (
				vehicleBodyCb.value === vehicleBodyCbValue.ROAD_CYCLE ||
				vehicleBodyCb.value === vehicleBodyCbValue.TERAIN_CYCLE
			) {
				return ` ${vehicleBodyCb.name} ${dictionary.get('AdvertDetailMeta.descMotorcycle')}`
			} else {
				return ` ${vehicleBodyCb.name}`
			}
		} else {
			return ''
		}
	}
}
