import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './PremiseLogo.less'

const CLASSNAME = 'c-premise-logo'

export default function PremiseLogo({ url, className = '' }) {
	return (
		<div className={classnames(CLASSNAME, className)}>
			<span className={`${CLASSNAME}__img`} style={{ backgroundImage: `url(${url})` }} />
		</div>
	)
}

PremiseLogo.propTypes = {
	url: PropTypes.string,
	className: PropTypes.string
}
