
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['MySavedSearchStatusMessage'] = {
  mySavedSearchAlreadyDeletedMsg: function(d) { return "Uložené hledání se nepodařilo najít, bylo již smazáno."; },
  mySavedSearchDeletedSucessMsg: function(d) { return "Uložené hledání bylo úspěšně smazáno."; },
  mySavedSearchDeletedErrorMsg: function(d) { return "Někde se stala chyba, uložené hledání nebylo smazáno. Zkuste to znovu."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	