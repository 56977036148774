import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import BasicWidget from 'app/page/userweb/newAdvert/component/widgets/basic/BasicWidget'
import BasicInfoTooltip from 'app/page/userweb/newAdvert/basicInfo/components/tooltip/BasicInfoTooltip'
import BaseBasicSelectWidget, {
	BASIC_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/basicSelect/BasicSelectWidget'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './BasicSelectWidget.less'

export { BASIC_SELECT_WIDGET_ID }

const CLASSNAME = 'c-basic-select-widget'

const BasicSelectWidget = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT } = props

	const linkedSelectAndLabel = AutoComplete.getIdsPropsHelper(formLineEntity.id)

	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			renderLabel={(formLineEntity, props) => {
				const { label, required } = formLineEntity
				const { labelId, inputId } = linkedSelectAndLabel

				return (
					<>
						<label {...props} id={labelId} htmlFor={inputId}>
							{label}
							{required && <span className='c-basic-widget__required'>*</span>}
						</label>
						<BasicInfoTooltip formLineEntity={formLineEntity} />
					</>
				)
			}}
			renderInput={(formLineEntity, inputWrapperProps) => {
				return (
					<div className={inputWrapperProps.className}>
						<BaseBasicSelectWidget {...props} {...linkedSelectAndLabel} />
					</div>
				)
			}}
		/>
	)
}

BasicSelectWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	renderSelectedOption: PropTypes.func
}

export default React.memo(BasicSelectWidget)
