import { DEACTIVATION_REASON } from 'app/model/advert/AdvertConstants'

export const ERROR_CODES = {
	REQUIRED: 'required',
	NOT_VALID: 'not_valid',
	UNKNOWN: 'unknown',
	EMAIL_ERROR_CODE: 'email_verified_error',
	VIN_DUPLICATION: DEACTIVATION_REASON.VIN_DUPLICATION
}

export const getErrorMessages = (formLineEntities = [], ERROR_DICTIONARY = {}) => {
	return formLineEntities.map((formLineEntity) => {
		const { id, value, extra: { getErrorMessages } = {} } = formLineEntity

		if (getErrorMessages) {
			return {
				id,
				errorMessage: getErrorMessages(formLineEntity)
			}
		} else {
			const errorMessage = value
				? ERROR_DICTIONARY[id][ERROR_CODES.NOT_VALID]
				: ERROR_DICTIONARY[id][ERROR_CODES.REQUIRED]

			return {
				id,
				errorMessage: errorMessage || ERROR_DICTIONARY[id][ERROR_CODES.UNKNOWN]
			}
		}
	})
}
