import React from 'react'

import './EquipmentPlaceholders.less'

const CLASSNAME = 'c-equipment-pl'

const EquipmentPlaceholders = () => {
	const renderCheckboxPlaceholders = (item) => {
		return (
			<div key={item} className={CLASSNAME}>
				<div className={`${CLASSNAME}__heading`} />

				<div className={`${CLASSNAME}__option-1`} />
				<div className={`${CLASSNAME}__option-2`} />
				<div className={`${CLASSNAME}__option-3`} />
			</div>
		)
	}

	return [0, 1, 2].map(renderCheckboxPlaceholders)
}

export default EquipmentPlaceholders
