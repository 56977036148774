import React, { useCallback } from 'react'
import { DateRange } from '@inzeraty/components'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import PropTypes from 'prop-types'
import { getYesterday, getReleaseDate } from 'app/helpers/date/dateHelpers'

import './DateRangePickerCS.json'

const DateRangePicker = ({ nameFrom, valueFrom, nameTo, valueTo, className = '' }) => {
	const localize = useLocalize()
	const fire = useFire()
	const onChange = useCallback(([newValueFrom, newValueTo]) => {
		fire('dateRangeChange', {
			[nameFrom]: newValueFrom,
			[nameTo]: newValueTo
		})
	}, [])

	const labelFrom = localize('DateRangePicker.dateFrom')
	const labelTo = localize('DateRangePicker.dateTo')

	return (
		<DateRange
			hideLabels
			position='bottomRight'
			className={className}
			onChange={onChange}
			withConfirmation
			withPresetPicker
			disableBefore={getReleaseDate()}
			disableAfter={getYesterday()}
			fromInputProps={{
				label: labelFrom,
				placeholder: labelFrom,
				name: nameFrom,
				value: valueFrom
			}}
			toInputProps={{
				label: labelTo,
				placeholder: labelTo,
				name: nameTo,
				value: valueTo
			}}
		/>
	)
}

DateRangePicker.propTypes = {
	nameFrom: PropTypes.string,
	valueFrom: PropTypes.string,
	nameTo: PropTypes.string,
	valueTo: PropTypes.string,
	className: PropTypes.string
}

export default DateRangePicker
