
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['Photos'] = {
  photos: function(d) { return "Fotografie"; },
  photosInfo: function(d) { return "Nahrávejte fotografie v min. rozlišení " + d.MIN_WIDTH + "x" + d.MIN_HEIGHT + ", do 5 MB. Min. počet " + plural(d.LIMIT, 0, cs, { one: d.LIMIT + " fotografie", few: d.LIMIT + " fotografie", other: d.LIMIT + " fotografií" }) + "."; },
  videos: function(d) { return "Video"; },
  videosInfo: function(d) { return "Nahrávejte v min. rozlišení 640x480, do 1 GB."; },
  modalHow: {
    title: function(d) { return "Jak správně nafotit auto?"; },
    description: function(d) { return "Nafoťte auto ze všech stran. Nezapomeňte na interiér, palubní desku, sedačky a pokud má vůz nějaké kosmetické vady, vyfoťte i jejich detail, aby měl zájemce co nejjasnější představu o stavu vašeho vozidla. Pokud natáčíte video, doporučujeme nechat auto nastartované."; },
    recommandedPhotos: {
      title: function(d) { return "Doporučené fotografie"; },
      front: function(d) { return "Zepředu"; },
      frontLeft: function(d) { return "Zepředu–zleva"; },
      backLeft: function(d) { return "Zezadu–zleva"; },
      back: function(d) { return "Zezadu"; },
      interior: function(d) { return "Přední Interiér"; },
      frontSeats: function(d) { return "Přední sedačky"; },
      backSeats: function(d) { return "Zadní sedačky"; },
      storage: function(d) { return "Uložný prostor"; }
    },
    complementaryPhotos: {
      title: function(d) { return "Doplňující fotografie"; },
      damage: function(d) { return "Detaily poškození"; },
      wheels: function(d) { return "Kola a podvozek"; },
      engine: function(d) { return "Motor"; },
      equipment: function(d) { return "Detaily výbavy"; }
    }
  }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	