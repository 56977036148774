import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { Icon, Surface } from '@sznds/react'
import { CHEVRON_RIGHT_OUTLINE_24 } from '@sznds/icons'

import './ArrowButton.less'

const CLASSNAME = 'c-arrow-button'

export default class ArrowButton extends AbstractPureComponent {
	static get propTypes() {
		return {
			text: PropTypes.string.isRequired,
			className: PropTypes.string,
			symbol: PropTypes.object,
			iconPath: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			className: '',
			symbol: CHEVRON_RIGHT_OUTLINE_24
		}
	}

	render() {
		const { className, symbol } = this.props

		return (
			<Surface
				tagName='span'
				surface={2}
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{this._renderText()}
				<Icon symbol={symbol} className={`${CLASSNAME}__symbol`} />
			</Surface>
		)
	}

	_renderText() {
		const { text, iconPath } = this.props

		if (iconPath) {
			const iconStyles = {
				backgroundImage: `url(${iconPath})`
			}

			return (
				<div className={`${CLASSNAME}__text`}>
					<span className={`${CLASSNAME}__icon`} style={iconStyles}></span>
					<span>{text}</span>
				</div>
			)
		} else {
			return text
		}
	}
}
