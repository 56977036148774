import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Responsive } from '@inzeraty/components'
import AutoComplete, { pipe } from 'app/component/autoComplete/AutoComplete'
import DropDownButton from 'app/component/dropDownButton/DropDownButton'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import { RESPONSIVE } from 'app/base/Constants'
import { useLocalize } from 'app/base/componentHelpers'

import './Select.less'
import './SelectCS.json'

const CLASSNAME = 'c-select'

const DROPDOWN = 'DROPDOWN'
const POPUP = 'POPUP'

const Select = (props) => {
	const localize = useLocalize()

	const activeModalTypeRef = useRef()

	const noSelectionItemRef = useRef({
		index: 0,
		item: {
			name: localize('Select.noItemSelected')
		}
	})

	const NO_SELECTION_ITEM = noSelectionItemRef.current

	const renderDropDownButton = (downshift) => {
		const { getToggleButtonProps, isOpen, selectedItem, getInputProps } = downshift

		const {
			size = 'regular',
			placeholder,
			inputId, // abysme mohli provazat oteviratko selectu s nejakym labelem
			items,
			disabled,
			hasError
		} = props

		const { ref: openerRef } = getInputProps()

		const renderTextForSelectOpener = (loadingMessage) => {
			if (loadingMessage) {
				return loadingMessage
			} else if (selectedItem && selectedItem !== NO_SELECTION_ITEM.item) {
				return renderItem(selectedItem)
			} else {
				return placeholder
			}
		}

		const renderModal = () => (
			<div className={`${CLASSNAME}__wrapper`}>
				<Responsive
					breakpoint={RESPONSIVE.TABLET}
					renderMobileElement={() => renderPopup(downshift)}
					renderDesktopElement={() => renderDropdown(downshift)}
				/>
			</div>
		)

		return (
			<div>
				<DropDownButton
					size={size}
					isDisabled={disabled}
					isLoading={!items}
					isOpen={isOpen}
					isOptionSelected={selectedItem && selectedItem !== NO_SELECTION_ITEM.item}
					renderText={renderTextForSelectOpener}
					error={hasError}
					ref={openerRef}
					{...getToggleButtonProps({
						id: inputId
					})}
				/>

				{renderModal()}
			</div>
		)
	}

	const renderItem = (item) => {
		const { renderItem: renderItemProp } = props

		if (renderItemProp) {
			return renderItemProp(item)
		} else {
			return item ? item.name : ''
		}
	}

	const renderDropdown = (downshift) => {
		activeModalTypeRef.current = DROPDOWN

		const { isOpen, getDropdownProps, renderDropdown: Dropdown } = downshift

		return isOpen && <Dropdown {...getDropdownProps()}>{renderItems(downshift)}</Dropdown>
	}

	const renderPopup = (downshift) => {
		activeModalTypeRef.current = POPUP

		const {
			isOpen,

			getPopupProps,
			renderPopup: Popup,

			closeMenu
		} = downshift

		const { popupTitle = localize('Select.popupTitle') } = props

		return (
			isOpen && (
				<Popup
					{...getPopupProps({
						title: popupTitle,
						onClose: closeMenu
					})}
				>
					{renderItems(downshift)}
				</Popup>
			)
		)
	}

	const shouldBeSpecialNoSelectionItemInserted = () => {
		const { allowNoSelection = false } = props

		return allowNoSelection
	}

	const renderItems = (downshift) => {
		const { items = [], size } = props

		const { getItemProps, highlightedIndex } = downshift

		const classNamesForItem = classnames({
			[`${CLASSNAME}__item`]: true,
			[`${CLASSNAME}__item--small`]: size === 'small' && activeModalTypeRef.current === DROPDOWN
		})

		const renderedNoSelectionItem = shouldBeSpecialNoSelectionItemInserted() && (
			<AutoCompleteOption
				{...getItemProps({
					key: NO_SELECTION_ITEM.item,
					index: NO_SELECTION_ITEM.index,
					item: NO_SELECTION_ITEM.item,
					isHighlighted: highlightedIndex === NO_SELECTION_ITEM.index,
					className: classNamesForItem
				})}
			>
				{localize('Select.noItemSelected')}
			</AutoCompleteOption>
		)

		const indexOffset = renderedNoSelectionItem ? 1 : 0

		return (
			<ul>
				{renderedNoSelectionItem}

				{items.map((item, index) => {
					// zalezi, jestli jako prvni polozku vkladame 'Nevybrano' nebo ne.
					// 'Nevybrano' slouzi k tomu, abysme mohli vymazat obsah selectu.
					const offset = index + indexOffset

					return (
						// eslint-disable-next-line react/jsx-key
						<AutoCompleteOption
							{...getItemProps({
								key: item.name,
								index: offset,
								item,
								isHighlighted: highlightedIndex === offset,
								className: classNamesForItem
							})}
						>
							{renderItem(item)}
						</AutoCompleteOption>
					)
				})}
			</ul>
		)
	}

	const stateReducer = (state, changes) => {
		const highlightSelectedItemOnOpen = (changes) => {
			const { selectedItem } = state

			if (activeModalTypeRef.current === DROPDOWN && changes.isOpen && selectedItem) {
				const { items = [] } = props
				const { name } = selectedItem
				const { type } = changes

				// defaultni posun v ramci seznamu, kdy podle potreby pridavame jako
				// prvni polozku 'Nevybrano'
				const itemIndex = items.findIndex((i) => i.name === name)
				const itemsOffset =
					itemIndex >= 0 ? itemIndex + (shouldBeSpecialNoSelectionItemInserted() ? 1 : 0) : 0

				// prepsani defaultniho chovani downshiftu, kdy pri stisknuti sipky
				// nahoru a dolu se otevre sice dropdown, ale zaroven dojde i k nechtenemu
				// posunu na predchozi nebo nasledujici polozku
				const downshiftOffset =
					type === AutoComplete.stateChangeTypes.keyDownArrowDown
						? -1
						: type === AutoComplete.stateChangeTypes.keyDownArrowUp
						? 1
						: 0

				return Object.assign({}, changes, {
					highlightedIndex: itemsOffset + downshiftOffset
				})
			}

			return changes
		}

		return pipe(highlightSelectedItemOnOpen)(changes)
	}

	return (
		<AutoComplete {...props} stateReducer={stateReducer}>
			{(downshift) => renderDropDownButton(downshift)}
		</AutoComplete>
	)
}

Select.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired
		})
	),
	placeholder: PropTypes.string,
	popupTitle: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	size: PropTypes.string, // stejne jak v SDS: 'small' nebo 'regular'
	allowNoSelection: PropTypes.bool,
	onSelect: PropTypes.func,
	renderItem: PropTypes.func,
	inputId: PropTypes.string
	// jeste dalsi props jsou k dispozici, viz nase komponenta
	// AutoComplete.jsx a knihovna Downshift
}

export default React.memo(Select)
