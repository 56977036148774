/**
 * Cookies, local storage a session storage používaná na sautu.
 *
 * !!! POZOR !!!
 * Cookies (ale i storages) musí odpovídat seznamu cookies, který ukazujeme uživatelům.
 * Při změnách cookies je potřeba upravit i tento seznam!
 */

/**
 * Cookie, pro uložení používanosti oblíbených značek konkrétním uživatelem.
 */
export const FAVORITE_BRANDS_USAGE_COOKIE = 'sauto_FBU'

/**
 * Cookie pro AB testy.
 */
export const AB_TEST_COOKIE = 'sautoAB'

/**
 * Local storage pro titulek posledního hledání
 */
export const LAST_SEARCH_TITLE_LOCAL_STORAGE = 'sautoLastSearchTitle'
/**
 * Local storage pro popis posledního hledání
 */
export const LAST_SEARCH_SENTENCE_LOCAL_STORAGE = 'sautoLastSearchSentence'
/**
 * Local storage pro url posledního hledání
 */
export const LAST_SEARCH_URL_LOCAL_STORAGE = 'sautoLastSearchUrl'

/**
 * Local storage uchovávající uložená hledání uživatele
 */
export const MY_SAVED_SEARCHES_LOCAL_STORAGE = 'sautoMySavedSearches'

/**
 * Cookie aktuální verze webové aplikace (rozuměj tag).
 */
export const APP_VERSION_COOKIE = 'appver'

/**
 * Session pro uchování informace o zavření informačního dialogu pro Internet Explorer.
 */
export const IE_MODAL_CLOSED_SESSION = 'ie-modal-closed'

/**
 * Session pro uchování informace o zavření dialogu smluvních podmínek.
 */
export const TERMS_AGREEMENT_CLOSED_SESSION = 'terms-agreement-closed'

/**
 * Session pro analytiku,kde si drzime, zda jsme pouzili fulltext, filtry, hp, nebo jsme přešli z neznámého zdroje (copy link).
 */
export const SEARCH_TYPE_SESSION = 'search-type'

/**
 * Seznam cookies blokovaných na základě nastavení CMP
 */
export const BLOCKED_COOKIES_CMP = [FAVORITE_BRANDS_USAGE_COOKIE, AB_TEST_COOKIE]

/**
 * Seznam local storage blokovaných na základě nastavení CMP
 */
export const BLOCKED_LOCALSTORAGE_CMP = [
	LAST_SEARCH_TITLE_LOCAL_STORAGE,
	LAST_SEARCH_SENTENCE_LOCAL_STORAGE,
	LAST_SEARCH_URL_LOCAL_STORAGE
]
