import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import { Icon } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import { Responsive } from '@inzeraty/components'
import AutoComplete, { filterItems } from 'app/component/autoComplete/AutoComplete'
import AutoCompleteInput from 'app/component/autoComplete/AutoCompleteInput'
import { RESPONSIVE } from 'app/base/Constants'
import { Popup } from '@inzeraty/components'

import './ResponsiveAutoComplete.less'

const CLASSNAME = 'c-responsive-auto-complete'

const isExactMatch = (inputValue, itemValue) => {
	const inputValueTransformed = Format.removeDiacritics(inputValue).toLowerCase().trim()

	const itemValueTransformed = Format.removeDiacritics(itemValue).toLowerCase().trim()

	return itemValueTransformed === inputValueTransformed
}

const ResponsiveAutoComplete = (props) => {
	const {
		className,
		placeholder = '',
		disabled = false,
		hasError = false,
		size = 'regular',
		inputId,
		inputValue,
		onInputValueChange = DEFAULT_PROPS.FUNCTION,
		renderInputLeftIcon = () => <Icon symbol={SEARCH_OUTLINE_24} />,
		allItems = DEFAULT_PROPS.ARRAY,
		renderDropdown = DEFAULT_PROPS.FUNCTION,
		renderPopup = DEFAULT_PROPS.FUNCTION,
		onSelect = DEFAULT_PROPS.FUNCTION,
		onSelectedItemDelete = DEFAULT_PROPS.FUNCTION
	} = props

	const [searchedTerm, setSearchedTerm] = useState('')

	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const [isPopupInputFocused, setIsPopupInputFocused] = useState(true)
	const popupSelectedItem = useRef()
	const initialInputValue = useRef(inputValue)

	// dropdownSelectedItem na rozdil od popupSelectedItem musime drzet ve stavu,
	// protoze tuto promennou pouzivame jako prop do AutoCompletu, takze pri
	// jakekoliv zmene chceme tu komponentu prekreslit
	const [dropdownSelectedItem, setDropdownSelectedItem] = useState(null)

	useEffect(() => {
		setSearchedTerm(inputValue)
	}, [inputValue])

	useEffect(() => {
		onInputValueChange(searchedTerm)
	}, [searchedTerm])

	useEffect(() => {
		const items = allItems.filter(({ name }) => isExactMatch(inputValue, name))

		if (items.length === 1) {
			const [selectedItem] = items

			if (!popupSelectedItem.current) {
				popupSelectedItem.current = selectedItem
			}
		}
	}, [])

	useEffect(() => {
		const items = allItems.filter(({ name }) => isExactMatch(inputValue, name))

		if (items.length === 1) {
			const [selectedItem] = items

			if (!dropdownSelectedItem) {
				setDropdownSelectedItem(selectedItem)
			}
		}
	})

	// uzivatel i pote, co provedl vyber nejake hodnoty, muze dale upravovat
	// textovy input. Napr. uzivatel vybere 'Volvo', jakmile upravi na 'Volvo  ',
	// tak se nic nedeje. Ale kdyz upravi input na 'Volv', tak interni vybranou
	// hodnotu odmazeme.
	const evalSelectedItem = (currentSelection, currentSearchTerm) => {
		if (currentSelection) {
			const items = allItems.filter(({ name }) => isExactMatch(currentSearchTerm, name))

			// pokud se nenajde prave jeden vysledek, tak vybranou hodnotu smazeme
			if (items.length !== 1) {
				return undefined
			}
		}

		return currentSelection
	}

	const handleSelect = (selectedItem) => {
		if (selectedItem) {
			initialInputValue.current = inputValue
			setDropdownSelectedItem(selectedItem)
			onSelect(selectedItem)
		}
	}

	const _renderInputForDropdown = (downshift) => {
		const {
			getInputProps,
			getToggleButtonProps,
			getClearButtonProps,

			isOpen,
			inputValue
		} = downshift

		const filteredItems = allItems.filter((item) => filterItems(inputValue, item.name))

		return (
			<div className={`${CLASSNAME}__dropdown-input-wrapper`}>
				<AutoCompleteInput
					isLoading={disabled ? false : !allItems.length}
					inputSurfaceProps={{
						className: classnames({
							[className]: !!className
						}),
						size
					}}
					inputProps={getInputProps({
						placeholder,
						disabled,
						error: hasError,
						onBlur: () => {
							if (!dropdownSelectedItem) {
								if (initialInputValue.current) {
									setSearchedTerm(initialInputValue.current)
								} else {
									setSearchedTerm('')
								}
							}
						}
					})}
					toggleButtonProps={
						!inputValue
							? getToggleButtonProps({
									isOpen
							  })
							: undefined
					}
					clearButtonProps={
						inputValue
							? getClearButtonProps({
									onClick: () => {
										onSelectedItemDelete()
										setDropdownSelectedItem(null)
										initialInputValue.current = ''
									}
							  })
							: undefined
					}
					renderLeftIcon={renderInputLeftIcon}
				/>

				{_renderDropdown(filteredItems, downshift)}
			</div>
		)
	}

	const _renderDropdown = (filteredItems, downshift) => {
		const {
			isOpen,

			getDropdownProps,
			renderDropdown: Dropdown
		} = downshift

		const getItemProps = (customItemProps) => {
			const { className } = customItemProps

			return downshift.getItemProps(
				Object.assign({}, customItemProps, {
					className: classnames({
						[`${CLASSNAME}__item`]: true,
						[`${CLASSNAME}__item--small`]: size === 'small',
						[className]: !!className
					})
				})
			)
		}

		return (
			isOpen &&
			renderDropdown({
				Dropdown,
				getDropdownProps,

				getItemProps,
				filteredItems,

				downshift
			})
		)
	}

	const _renderInputForPopup = () => {
		const filteredItems = allItems.filter((item) => filterItems(searchedTerm, item.name))

		return (
			<div>
				<AutoCompleteInput
					isLoading={disabled ? false : !allItems.length}
					inputSurfaceProps={{
						className: classnames({
							[className]: !!className
						}),
						size
					}}
					inputProps={{
						id: inputId,
						onClick: () => {
							setIsPopupOpen(true)
							setIsPopupInputFocused(true)
						},
						onChange: (event) => {
							setSearchedTerm(event.target.value)
							setIsPopupOpen(true)
							setIsPopupInputFocused(true)
						},
						value: searchedTerm,
						placeholder,
						disabled,
						error: hasError
					}}
					toggleButtonProps={
						!searchedTerm
							? {
									onClick: () => setIsPopupOpen(true)
							  }
							: undefined
					}
					clearButtonProps={
						searchedTerm
							? {
									onClick: () => {
										setSearchedTerm('')
										popupSelectedItem.current = undefined
										onSelectedItemDelete()
									}
							  }
							: undefined
					}
					renderLeftIcon={renderInputLeftIcon}
				/>

				{_renderPopup(filteredItems)}
			</div>
		)
	}

	const _renderPopup = (filteredItems) => {
		const getPopupProps = (customPopupProps) => {
			const { onClose } = customPopupProps

			return Object.assign(
				{
					stickyHeader: false
				},
				customPopupProps,
				{
					isOpen: isPopupOpen,
					onClose: () => {
						setIsPopupOpen(false)

						if (!popupSelectedItem.current) {
							onSelectedItemDelete()
						}
						onClose && onClose()
					}
				}
			)
		}

		const renderPopupInput = (popupInputProps) => (
			<div className={`${CLASSNAME}__popup-input-wrapper`}>
				<AutoCompleteInput
					isLoading={disabled ? false : !allItems.length}
					isFocused={isPopupInputFocused}
					inputSurfaceProps={{
						className: classnames({
							[className]: !!className
						}),
						size
					}}
					inputProps={popupInputProps}
					clearButtonProps={
						searchedTerm
							? {
									onClick: () => {
										setSearchedTerm('')
										popupSelectedItem.current = undefined
										onSelectedItemDelete()
									}
							  }
							: undefined
					}
					renderLeftIcon={renderInputLeftIcon}
				/>
			</div>
		)

		const getPopupInputProps = (customPopupInputProps) => {
			const { onChange, onFocus, onBlur } = customPopupInputProps

			return Object.assign({ value: searchedTerm }, customPopupInputProps, {
				onChange: (event) => {
					const { value } = event.target

					setSearchedTerm(value)

					popupSelectedItem.current = evalSelectedItem(popupSelectedItem.current, value)

					onChange && onChange(value)
				},
				onFocus: () => {
					setIsPopupInputFocused(true)
					onFocus && onFocus()
				},
				onBlur: () => {
					setIsPopupInputFocused(false)
					onBlur && onBlur()
				}
			})
		}

		const getItemProps = (customItemProps) => {
			const { className, item } = customItemProps

			return Object.assign({}, customItemProps, {
				className: classnames({
					[`${CLASSNAME}__item`]: true,
					[className]: !!className
				}),
				onClick: () => {
					setIsPopupOpen(false)
					popupSelectedItem.current = item
					onSelect(item)
				}
			})
		}

		return (
			isPopupOpen &&
			renderPopup({
				Popup,
				getPopupProps,

				PopupInput: renderPopupInput,
				getPopupInputProps,

				filteredItems,
				getItemProps,

				inputValue: searchedTerm
			})
		)
	}

	const handleStateChange = (changes) => {
		const { type, inputValue } = changes

		if (
			type === AutoComplete.stateChangeTypes.changeInput &&
			changes.hasOwnProperty('inputValue')
		) {
			setDropdownSelectedItem(evalSelectedItem(dropdownSelectedItem, inputValue) || null)
		}
	}

	return (
		<Responsive
			breakpoint={RESPONSIVE.TABLET}
			renderMobileElement={() => _renderInputForPopup()}
			renderDesktopElement={() => (
				<AutoComplete
					inputValue={searchedTerm}
					onInputValueChange={setSearchedTerm}
					selectedItem={dropdownSelectedItem}
					{...props}
					onSelect={handleSelect}
					onStateChange={handleStateChange}
					size={size}
				>
					{(downshift) => _renderInputForDropdown(downshift)}
				</AutoComplete>
			)}
		/>
	)
}

ResponsiveAutoComplete.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	size: PropTypes.string, // stejne jak v SDS: 'small' nebo 'regular'
	renderInputLeftIcon: PropTypes.func,
	inputValue: PropTypes.string,
	onInputValueChange: PropTypes.func,
	allItems: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired
		})
	),
	renderDropdown: PropTypes.func.isRequired,
	renderPopup: PropTypes.func.isRequired,
	onSelect: PropTypes.func,
	onSelectedItemDelete: PropTypes.func,
	inputId: PropTypes.string
}

export default React.memo(ResponsiveAutoComplete)
