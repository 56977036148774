import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractComponent from 'app/base/AbstractComponent'
import * as FormLines from '@inzeraty/form-lines'
import FilterRow from '../../components/filterRow/FilterRowView'

import './RegionDistrictForm.less'
import './RegionDistrictFormCS.json'

const CLASSNAME = 'cf-region-district-form'

const ALL_DISTRICTS_VALUE_CONST = -1
const NO_REGION_VALUE_CONST = -1

export default class RegionDistrictForm extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			regionValue: PropTypes.number.isRequired,
			onChange: PropTypes.func,
			isLoadingFilteredAdvertsCount: PropTypes.bool,
			filteredAdvertsCount: PropTypes.object,
			isCountShownWithAddText: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			className: '',
			formLineEntity: DEFAULT_PROPS.OBJECT,
			regionValue: NO_REGION_VALUE_CONST,
			onChange: DEFAULT_PROPS.FUNCTION,
			isLoadingFilteredAdvertsCount: false,
			filteredAdvertsCount: DEFAULT_PROPS.OBJECT,
			isCountShownWithAddText: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderDistrict = this._renderDistrict.bind(this)
		this._onDistrictChange = this._onDistrictChange.bind(this)
	}

	componentDidMount() {
		const {
			formLineEntity: { value = new Map() },
			regionValue
		} = this.props

		if (!value.has(regionValue)) this._onChange(ALL_DISTRICTS_VALUE_CONST, true)
	}

	render() {
		const { className } = this.props
		const { children: districts = [] } = this._getSelectedRegion()

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{this._renderDistrict({
					value: ALL_DISTRICTS_VALUE_CONST,
					name: this.localize('RegionDistrictForm.entireRegion')
				})}

				{this._renderList(
					this.localize('RegionDistrictForm.allDistricts'),
					districts.map(this._renderDistrict)
				)}
			</div>
		)
	}

	_renderList(header, items = []) {
		if (items.length) {
			return (
				<React.Fragment>
					{header && <div className={`${CLASSNAME}__header`}>{header}</div>}

					<ul>{items}</ul>
				</React.Fragment>
			)
		}
	}

	static getRegion(formLineEntity, regionValue) {
		const { options = [] } = formLineEntity

		return options.find((option) => option.value === regionValue) || {}
	}

	_getSelectedRegion() {
		const { regionValue, formLineEntity } = this.props

		return RegionDistrictForm.getRegion(formLineEntity, regionValue)
	}

	_getAdvertsCountTotalForRegion(filteredAdvertsCount) {
		const { children: regionDistricts = [] } = this._getSelectedRegion()

		const total = regionDistricts.reduce((total, { value: districtValue }) => {
			const countForDistrict = filteredAdvertsCount[districtValue] || 0
			return total + countForDistrict
		}, 0)

		return total
	}

	_renderDistrict(district) {
		const { value: regionValue } = this._getSelectedRegion()
		const {
			isCountShownWithAddText,
			filteredAdvertsCount,
			isLoadingFilteredAdvertsCount
		} = this.props

		const { value } = district
		const {
			formLineEntity: { value: selectedRegions = new Map() }
		} = this.props

		const selectedDistricts = selectedRegions.get(regionValue) || new Set()

		const isChecked =
			value === ALL_DISTRICTS_VALUE_CONST
				? selectedDistricts.size === 0
				: selectedDistricts.has(value)

		return (
			// eslint-disable-next-line jsx-a11y/label-has-associated-control
			<label key={value}>
				<FilterRow
					count={
						value === ALL_DISTRICTS_VALUE_CONST
							? this._getAdvertsCountTotalForRegion(filteredAdvertsCount)
							: filteredAdvertsCount[value]
					}
					isCountShownWithAddText={isCountShownWithAddText}
					isCountLoading={isLoadingFilteredAdvertsCount}
					label={district.name}
					isSelected={isChecked}
				>
					<Checkbox
						className={`${CLASSNAME}__checkbox`}
						value={value}
						checked={isChecked}
						onChange={this._onDistrictChange}
					/>
				</FilterRow>
			</label>
		)
	}

	_onDistrictChange(event) {
		const { value: stringValue, checked } = event.target
		const value = Number(stringValue)
		this._onChange(value, checked)
	}

	_onChange(value, checked) {
		const {
			formLineEntity: { id, value: selectedRegions = new Map() },
			regionValue,
			onChange
		} = this.props

		const selectedDistrictsForRegion = new Set(selectedRegions.get(regionValue)) || new Set()

		if (value !== ALL_DISTRICTS_VALUE_CONST && checked) {
			selectedDistrictsForRegion.add(value)
		} else if (value !== ALL_DISTRICTS_VALUE_CONST && !checked) {
			selectedDistrictsForRegion.delete(value)
		}

		if (value === ALL_DISTRICTS_VALUE_CONST || selectedDistrictsForRegion.size === 0) {
			selectedDistrictsForRegion.clear()
		}

		const newFormLineEntityValue = new Map(selectedRegions)
		newFormLineEntityValue.set(regionValue, selectedDistrictsForRegion)

		onChange(id, newFormLineEntityValue)
	}
}
