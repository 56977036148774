import deepEqual from 'fast-deep-equal'

/**
 * @class MySavedSearchComparison
 * Porovnava nastaveni filtru u ulozenych hledani.
 */
export default class MySavedSearchComparison {
	static areEqual(mySavedSearchFilterA = {}, mySavedSearchFilterB = {}) {
		const mySavedSearchFilterAKeys = Object.keys(mySavedSearchFilterA)
		const mySavedSearchFilterBKeys = Object.keys(mySavedSearchFilterB)

		if (mySavedSearchFilterAKeys.length === mySavedSearchFilterBKeys.length) {
			return mySavedSearchFilterAKeys.every((key) =>
				arePropertiesEqual(mySavedSearchFilterA[key], mySavedSearchFilterB[key])
			)
		}

		return false
	}
}

const isCodeBookObject = (item) =>
	item &&
	item.hasOwnProperty('value') &&
	item.hasOwnProperty('seo_name') &&
	item.hasOwnProperty('name')

const areCodeBookObjectsEqual = (itemA = {}, itemB = {}) => {
	return (
		itemA.value === itemB.value && itemA.seo_name === itemB.seo_name && itemA.name === itemB.name
	)
}

const areArraysEqual = (arrayA = [], arrayB = []) => {
	if (arrayA.length === arrayB.length) {
		return arrayA.every((itemA) => {
			const itemB = arrayB.find((itemB) => itemB.value === itemA.value)

			if (itemB) {
				return (
					areCodeBookObjectsEqual(itemA, itemB) &&
					(itemA.models || itemB.models ? areArraysEqual(itemA.models, itemB.models) : true) &&
					(itemA.districts || itemB.districts
						? areArraysEqual(itemA.districts, itemB.districts)
						: true)
				)
			}
		})
	}

	return false
}

const arePropertiesEqual = (valueA, valueB) => {
	if (Array.isArray(valueA) && Array.isArray(valueB)) {
		return areArraysEqual(valueA, valueB)
	} else if (isCodeBookObject(valueA) && isCodeBookObject(valueB)) {
		// porovnani objektu pro filtr stari inzeratu
		return areCodeBookObjectsEqual(valueA, valueB)
	} else {
		return deepEqual(valueA, valueB)
	}
}
