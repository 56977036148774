
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PaymentInfo'] = {
  header: function(d) { return "Platba"; },
  cardPaymentErrorHeader: function(d) { return "Platba kartou se nepodařila"; },
  walletPaymentErrorHeader: function(d) { return "Platba peněženkou se nepodařila"; },
  smsPaymentErrorHeader: function(d) { return "Platba přes SMS se nepodařila"; },
  "payment-failed": function(d) { return "Platbu se nepodařilo provést, zkuste platbu znova."; },
  "credit-card-unavailable": function(d) { return "Nepodařilo se navázat spojení s platební bránou."; },
  "credit-card-error": function(d) { return "Platbu se nepodařilo provést, zkuste platbu znova."; },
  "credit-card-not-owner": function(d) { return "Platbu se nepodařilo provést, zkuste platbu znova."; },
  "credit-card-advert-not-active": function(d) { return "Platbu se nepodařilo provést, zkuste platbu znova."; },
  "credit-card-invalid-payment-type": function(d) { return "Platbu se nepodařilo provést, zkuste platbu znova."; },
  low_credit: function(d) { return "Nemáte dostatek kreditu. Dobijte si prosím peněženku."; },
  advertExpired: function(d) { return "Inzerát je expirovaný, pro aktivaci je potřeba ho zaplatit."; },
  advertProlong: function(d) { return "Inzerát je zaplacený do " + d.DATE + ", ale můžete jeho platnost prodloužit."; },
  vinDuplicationError: function(d) { return "Inzerát nelze zaplatit, jelikož VIN vozidla se shoduje s jiným inzerátem."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	