import React, { memo } from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const WidgetHeader = memo(({ className, formLineEntity = {} }) => {
	const { label } = formLineEntity

	return (
		<div className={className}>
			<strong>{label}</strong>
		</div>
	)
})

WidgetHeader.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	className: PropTypes.string
}

export default WidgetHeader
