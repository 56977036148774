import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import RadioFilterRow from '../components/filterRow/RadioFilterRowView'
import * as FormLines from '@inzeraty/form-lines'
import GenericError from 'ima/error/GenericError'

const DEF_PROPS = {
	FORM_LINE_ENTITY: {},
	ON_CAHNGE: () => {},
	ON_PRESET_CLICK: () => {},
	FILTERED_ADVERTS_COUNT: {}
}

export default class AbstractRadioButtonForm extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			formLineEntity: PropTypes.instanceOf(FormLines.Entity),
			isFormValid: PropTypes.bool,
			onChange: PropTypes.func,
			onPresetClick: PropTypes.func,
			filteredAdvertsCount: PropTypes.object,
			isLoadingFilteredAdvertsCount: PropTypes.bool,
			isLastOptionCustomInput: PropTypes.bool,
			isCountShownWithAddText: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			className: '',
			formLineEntity: DEF_PROPS.FORM_LINE_ENTITY,
			isFormValid: false,
			onChange: DEF_PROPS.ON_CAHNGE,
			onPresetClick: DEF_PROPS.ON_CAHNGE,
			filteredAdvertsCount: DEF_PROPS.FILTERED_ADVERTS_COUNT,
			isLoadingFilteredAdvertsCount: false,
			isLastOptionCustomInput: true,
			isCountShownWithAddText: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			showLoadingDotsOnlyForLastOption: false
		}

		this._onChange = this._onChange.bind(this)
	}

	render() {
		throw new GenericError('Je nutné implementovat render metodu.')
	}

	_renderShortCuts(isCompactFilter = false) {
		const {
			formLineEntity,
			context = '',
			isCountShownWithAddText,
			filteredAdvertsCount
		} = this.props
		const { id, options, value: entityValue = {} } = formLineEntity
		const { value: actualValue } = entityValue

		return options.map(({ value, name }, index) => {
			const isLastValue = options.length - 1 === index

			return (
				<RadioFilterRow
					key={value}
					label={name}
					count={filteredAdvertsCount[value]}
					isCountShownWithAddText={isCountShownWithAddText}
					isCountLoading={this._showLoadingDots(value, isCompactFilter)}
					isSelected={value === actualValue}
					name={`${id}_${context}`}
					value={String(value)}
					onChange={this._onChange}
					index={index}
					isLastValue={isLastValue}
					isCompactFilter={isCompactFilter}
				/>
			)
		})
	}

	_onChange(event) {
		throw new GenericError('Je nutné implementovat metodu _onChange.')
	}

	_changeValue(newValue) {
		const { formLineEntity, onChange, onPresetClick } = this.props
		const { value, id } = formLineEntity
		const updatedValue = Object.assign({}, value, newValue)

		this.setState({
			showLoadingDotsOnlyForLastOption: true
		})

		onChange(id, updatedValue, (newFormLineEntity) => {
			if (this._isPresetSelected(newFormLineEntity)) {
				onPresetClick()
			}
		})
	}

	// preset je standardne predvybrana hodnota jako 'Do 2 let', 'Do 4 let' atd.
	_isPresetSelected(newFormLineEntity) {
		const { isLastOptionCustomInput } = this.props
		const lastOptionValue = this._getLastOptionValue()

		return isLastOptionCustomInput ? lastOptionValue !== newFormLineEntity.value.value : true
	}

	_showLoadingDots(currentValue, isCompactFilter) {
		const { showLoadingDotsOnlyForLastOption } = this.state
		const { isLoadingFilteredAdvertsCount } = this.props

		if (isLoadingFilteredAdvertsCount) {
			if (isCompactFilter) {
				return true
			} else {
				return showLoadingDotsOnlyForLastOption ? this._getLastOptionValue() === currentValue : true
			}
		} else {
			return false
		}
	}

	_getLastOption() {
		const {
			formLineEntity: { options }
		} = this.props
		return options[options.length - 1] || {}
	}

	_getLastOptionValue() {
		return this._getLastOption().value
	}
}
