import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { Input } from '@sznds/react'
import { SEARCH_OUTLINE_24, CLOSE_OUTLINE_24 } from '@sznds/icons'
import FilterPopup from '../../components/filterPopup/FilterPopup'

import './SearchPopup.less'

const CLASSNAME = 'cf-search-popup'

export default class SearchPopup extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			filterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			title: PropTypes.string,
			searchInputPlaceholder: PropTypes.string,
			defaultType: PropTypes.bool,
			onClose: PropTypes.func,
			onReset: PropTypes.func,
			contentRef: PropTypes.shape({
				current: PropTypes.object
			}),
			children: PropTypes.func,
			headerDataDotData: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			className: '',
			filterId: '',
			title: '',
			searchInputPlaceholder: '',
			defaultType: true,
			onClose: () => {},
			onReset: () => {},
			children: () => {}
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			filterInputValue: ''
		}

		this._renderHeaderContentSection = this._renderHeaderContentSection.bind(this)

		this._resetFilterInputValue = this._resetFilterInputValue.bind(this)
		this._filterInputValueChange = this._filterInputValueChange.bind(this)
	}

	render() {
		const {
			className,
			filterId,
			title,
			defaultType,
			onClose,
			onReset,
			contentRef,
			children,
			headerDataDotData
		} = this.props
		const { filterInputValue } = this.state

		return (
			<FilterPopup
				className={className}
				filterId={filterId}
				title={title}
				defaultType={defaultType}
				renderHeaderContentSection={this._renderHeaderContentSection}
				onClose={onClose}
				onReset={onReset}
				contentRef={contentRef}
				headerDataDotData={headerDataDotData}
			>
				{children(filterInputValue)}
			</FilterPopup>
		)
	}

	_renderHeaderContentSection() {
		const { searchInputPlaceholder } = this.props
		const { filterInputValue } = this.state

		return (
			<div className={`${CLASSNAME}__search`} data-dot='search'>
				<Input
					placeholder={searchInputPlaceholder}
					value={filterInputValue}
					onChange={this._filterInputValueChange}
					iconLeft={SEARCH_OUTLINE_24}
					iconRight={filterInputValue ? CLOSE_OUTLINE_24 : undefined}
					onIconRightClick={this._resetFilterInputValue}
				/>
			</div>
		)
	}

	_filterInputValueChange(event) {
		this.setState({
			filterInputValue: event.target.value
		})
	}

	_resetFilterInputValue() {
		this.setState({
			filterInputValue: ''
		})
	}
}
