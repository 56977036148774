
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ModificationsList'] = {
  present: function(d) { return "do současnosti"; },
  speed: function(d) { return "Max. rychlost:"; },
  engineVolume: function(d) { return "Objem:"; },
  fuel: function(d) { return "Palivo:"; },
  fuelConsumption: function(d) { return "Spotřeba:"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	