import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'
import BaseMonthYearSelect, {
	MONTH_AND_YEAR_SELECT_WIDGET_ID,
	getCustomMonthFormLineId
} from 'app/page/userweb/newAdvert/component/widgets/monthYearSelect/MonthYearSelect'
import {
	MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID,
	IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/basicInfo/components/widgets/monthYearSelect/MonthYearSelect'
import { GUARANTEE_MONTH_AND_YEAR_SELECT_WIDGET_ID } from 'app/page/userweb/newAdvert/history/component/widgets/monthYearSelect/MonthYearSelect'
import { CLASSNAME as IA_CLASSNAME } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import BaseWidget from '../baseWidget/BaseWidget'

import './MonthYearSelect.less'

export { MONTH_AND_YEAR_SELECT_WIDGET_ID }
export { MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID }
export { IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID }
export { GUARANTEE_MONTH_AND_YEAR_SELECT_WIDGET_ID }

const CLASSNAME = 'c-ia-month-year-select'

const MonthYearSelect = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const handleChange = (change) => {
		onChange(change)
		clearErrorOnDependentField(change)
	}

	const clearErrorOnDependentField = (change) => {
		// 'Rok vyroby' a 'V provozu od' jsou zavisla policka, jenom jedno z nich
		// je povinne. Takze v pripade, ze dojde ke zmene jednoho z nich, pro to
		// druhe musime vymazat pripadnou chybu.
		const { id } = change

		if (id === FORM_LINES_IDS.MANUFACTURING_DATE) {
			onChange({
				id: FORM_LINES_IDS.IN_OPERATION_DATE,
				errorMessage: undefined
			})
		} else if (id === FORM_LINES_IDS.IN_OPERATION_DATE) {
			onChange({
				id: FORM_LINES_IDS.MANUFACTURING_DATE,
				errorMessage: undefined
			})
		}
	}

	const { id, widget } = formLineEntity

	const selectId = `${getCustomMonthFormLineId(id)}`

	return (
		<>
			<BaseWidget
				formLineEntity={formLineEntity}
				inputId={selectId}
				requiredMark={
					widget === MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID ||
					widget === IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID
						? '**'
						: '*'
				}
			>
				<div className={`${IA_CLASSNAME}__input`}>
					<BaseMonthYearSelect
						formLineEntity={formLineEntity}
						onChange={handleChange}
						selectId={selectId}
					/>
				</div>
			</BaseWidget>
			{widget === IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID && (
				<div className={`${CLASSNAME}__linked-req-fields-description`}>
					<span className={`${CLASSNAME}__stars`}>**</span>
					<span>Vyplňte jednu z položek &quot;Rok výroby&quot; nebo &quot;V provozu od&quot;.</span>
				</div>
			)}
		</>
	)
}

MonthYearSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(MonthYearSelect)
