import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Input, Button } from '@sznds/react'
import { SEARCH_OUTLINE_24, CLOSE_OUTLINE_16 } from '@sznds/icons'
import * as FormLines from '@inzeraty/form-lines'
import { CategoryEntity } from '@inzeraty/models'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { filterItems } from 'app/component/autoComplete/AutoComplete'
import { CATEGORIES } from 'app/page/userweb/newAdvert/equipment/EquipmentConstants'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import EquipmentPopup from 'app/component/filters/popups/equipmentPopup/EquipmentPopup'
import Multioption from 'app/component/filters/forms/multioption/Multioption'
import MultiOptionFilterLogic from 'app/component/filters/logic/MultiOptionFilterLogic'

import './Equipment.less'
import './EquipmentCS.json'
import 'app/page/userweb/newAdvert/equipment/components/widgets/equipment/EquipmentWidgetCS.json'

const CLASSNAME = 'c-equipment'

const NO_CATEGORY_SPECIFIED = 'no_category_specified'

const Equipment = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		context,
		onChange = DEFAULT_PROPS.FUNCTION,
		filteredAdvertsCount = DEFAULT_PROPS.OBJECT,
		isLoadingFilteredAdvertsCount = false,
		categoryEntity = DEFAULT_PROPS.OBJECT
	} = props

	const localize = useLocalize()

	const [isEquipmentDialogShown, setIsEquipmentDialogShown] = useState(false)

	const openDialogWithAllEquipments = () => {
		setIsEquipmentDialogShown(true)
	}

	const closeDialogWithAllEquipments = (localFormLineEntity) => {
		setIsEquipmentDialogShown(false)

		if (localFormLineEntity && localFormLineEntity.id) {
			// propiseme lokalni zmeny provedene v dialogu do globalniho stavu
			onChange(localFormLineEntity.id, localFormLineEntity.value)
		}
	}

	return (
		<div className={CLASSNAME}>
			{isEquipmentDialogShown && (
				<EquipmentDialog
					formLineEntity={formLineEntity}
					categoryEntity={categoryEntity}
					context={context}
					onClose={closeDialogWithAllEquipments}
				/>
			)}

			<div
				className={classnames({
					[`${CLASSNAME}__category-section`]: true,
					[`${CLASSNAME}__category-section--inline`]: true
				})}
			>
				<div
					className={classnames({
						[`${CLASSNAME}__category-heading`]: true,
						[`${CLASSNAME}__category-heading--inline`]: true
					})}
				>
					{localize('Equipment.favoriteEquipment')}
				</div>

				<Multioption
					formLineEntity={Object.assign({}, formLineEntity, {
						options: (formLineEntity.options || []).filter((option) => option.isFavorite)
					})}
					context={context}
					onChange={onChange}
					filteredAdvertsCount={filteredAdvertsCount}
					isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
					isCountShownWithAddText={false}
					categoryEntity={categoryEntity}
					showMoreLessButtonWhen={() => true}
					onShowMoreLessButtonClick={openDialogWithAllEquipments}
				/>
			</div>
		</div>
	)
}

Equipment.propTypes = {
	className: PropTypes.string,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	context: PropTypes.string,
	onChange: PropTypes.func,
	filteredAdvertsCount: PropTypes.object,
	isLoadingFilteredAdvertsCount: PropTypes.bool,
	categoryEntity: PropTypes.instanceOf(CategoryEntity)
}

export default Equipment

// plati pouze pro desktop, na mobilu je popup vzdy pres celou obrazovku
const MAX_EQUIPMENT_OPTIONS_FOR_SMALL_DIALOG = 30

const EquipmentDialog = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		categoryEntity = DEFAULT_PROPS.OBJECT,
		context,
		onClose = DEFAULT_PROPS.FUNCTION
	} = props

	// zmeny ve formlinach v dialogu nechceme propisovat ihned do globalniho stavu
	const [localFormLineEntity, setLocalFormLineEntity] = useState(formLineEntity)

	useEffect(() => {
		setLocalFormLineEntity(formLineEntity)
	}, [formLineEntity])

	const onLocalFormLineEntityChange = (formLineId, newValue, callback) => {
		const updatedLocalFormLineEntity = FormLines.updateEntity(formLineEntity, { value: newValue })

		setLocalFormLineEntity(updatedLocalFormLineEntity)
		callback && callback(updatedLocalFormLineEntity)
	}

	const onDialogClose = () => {
		onClose(localFormLineEntity)
	}

	return (
		<EquipmentPopup
			isSmall={formLineEntity.options.length < MAX_EQUIPMENT_OPTIONS_FOR_SMALL_DIALOG}
			onClose={onDialogClose}
		>
			<MultiOptionFilterLogic
				formLineEntity={localFormLineEntity}
				changeFilter={onLocalFormLineEntityChange}
				context={context}
				scopedContext={true}
			>
				{(filterLogicChildrenProps) => {
					const {
						form: { changeFilter },
						advertsCount: {
							filteredAdvertsCount,
							isLoadingFilteredAdvertsCount,
							loadFilteredAdvertsCount
						}
					} = filterLogicChildrenProps

					return (
						<EquipmentForm
							formLineEntity={localFormLineEntity}
							categoryEntity={categoryEntity}
							context={context}
							filteredAdvertsCount={filteredAdvertsCount}
							isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
							loadFilteredAdvertsCount={loadFilteredAdvertsCount}
							onChange={changeFilter}
							onConfirmButtonClick={onDialogClose}
						/>
					)
				}}
			</MultiOptionFilterLogic>
		</EquipmentPopup>
	)
}

EquipmentDialog.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	categoryEntity: PropTypes.instanceOf(CategoryEntity),
	context: PropTypes.string,
	onClose: PropTypes.func
}

const EquipmentForm = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		categoryEntity = DEFAULT_PROPS.OBJECT,
		context,
		filteredAdvertsCount = DEFAULT_PROPS.OBJECT,
		isLoadingFilteredAdvertsCount = false,
		loadFilteredAdvertsCount = DEFAULT_PROPS.FUNCTION,
		onChange = DEFAULT_PROPS.FUNCTION,
		onConfirmButtonClick = DEFAULT_PROPS.FUNCTION
	} = props

	const localize = useLocalize()

	const [searchValue, setSearchValue] = useState('')

	useEffect(() => {
		loadFilteredAdvertsCount()
	}, [])

	const getCategoryLabel = (categoryName) => {
		return categoryName !== NO_CATEGORY_SPECIFIED
			? localize(`EquipmentWidget.${categoryName}`)
			: undefined
	}

	const splitEquipmentOptionsByCategories = (options) => {
		const addOptionToCategory = (acc, option, category) => {
			const { [category]: optionsInCategory = [] } = acc

			return Object.assign({}, acc, {
				[category]: [...optionsInCategory, option]
			})
		}

		const optionsByCategories = options.reduce((result, option) => {
			const { category } = option

			if (category) {
				return addOptionToCategory(result, option, category)
			} else {
				return addOptionToCategory(result, option, NO_CATEGORY_SPECIFIED)
			}
		}, {})

		return optionsByCategories
	}

	const renderFormsForCategories = (categoryName, optionsByCategory) => {
		const categoryLabel = getCategoryLabel(categoryName)

		return (
			<div key={categoryName} className={`${CLASSNAME}__category-section`}>
				{categoryLabel && <div className={`${CLASSNAME}__category-heading`}>{categoryLabel}</div>}
				<Multioption
					formLineEntity={Object.assign({}, formLineEntity, { options: optionsByCategory })}
					context={context}
					onChange={onChange}
					filteredAdvertsCount={filteredAdvertsCount}
					isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
					isCountShownWithAddText={false}
					categoryEntity={categoryEntity}
					showMoreLessButtonWhen={() => false}
				/>
			</div>
		)
	}

	const { options } = formLineEntity

	const filteredItems = options.filter((option) => filterItems(searchValue, option.name))

	const optionsByCategories = splitEquipmentOptionsByCategories(filteredItems)

	const categoriesToRender = [NO_CATEGORY_SPECIFIED, ...CATEGORIES].filter((categoryName) => {
		const optionsByCategory = optionsByCategories[categoryName]

		return optionsByCategory && optionsByCategory.length > 0
	})

	return (
		<>
			<div className={`${CLASSNAME}__scroll-area`}>
				<div className={`${CLASSNAME}__search`}>
					<Input
						size='small'
						iconLeft={SEARCH_OUTLINE_24}
						iconRight={searchValue && CLOSE_OUTLINE_16}
						onIconRightClick={() => setSearchValue('')}
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
						placeholder={localize('Equipment.search')}
						data-dot='search'
					/>
				</div>

				<div className={`${CLASSNAME}__categories-list`}>
					{categoriesToRender.map((categoryName) =>
						renderFormsForCategories(categoryName, optionsByCategories[categoryName])
					)}
				</div>
			</div>

			<div className={`${CLASSNAME}__confirm-button-wrapper`}>
				<Button
					className={`${CLASSNAME}__confirm-button`}
					primary={context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL_INLINE}
					text={localize('Equipment.confirm')}
					onClick={onConfirmButtonClick}
					data-dot='submit'
					data-dot-data='{"type": "confirm"}'
				/>
			</div>
		</>
	)
}

EquipmentForm.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	categoryEntity: PropTypes.instanceOf(CategoryEntity),
	context: PropTypes.string,
	filteredAdvertsCount: PropTypes.object,
	isLoadingFilteredAdvertsCount: PropTypes.bool,
	loadFilteredAdvertsCount: PropTypes.func,
	onChange: PropTypes.func,
	onConfirmButtonClick: PropTypes.func
}
