
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ClientProfile'] = {
  basicInformation: function(d) { return "Základní informace"; },
  basicInformationSourceLabel: function(d) { return "základní informace"; },
  image: function(d) { return "Logo společnosti:"; },
  imageUnavailable: function(d) { return "Logo není k dispozici, nastavíte si ho na Firmy.cz"; },
  name: function(d) { return "Název:"; },
  ico: function(d) { return "IČ:"; },
  description: function(d) { return "Popisek:"; },
  activeUntil: function(d) { return "Aktivní do:"; },
  activeUntilPlaceholder: function(d) { return "Neznámé datum"; },
  activeUntilExpired: function(d) { return "Vaše inzerce vypršela, "; },
  contactBusinessRepresentative: function(d) { return "kontaktujte obchodního zástupce"; },
  businessRepresentative: function(d) { return "Obchodní zástupce:"; },
  businessRepresentativePlaceholder: function(d) { return "Žádný obchodní zástupce"; },
  loginEmail: function(d) { return "Přihlašovací email:"; },
  walletLabelAcount: function(d) { return "Peněženka účet:"; },
  walletLabelState: function(d) { return "Peněženka stav:"; },
  openingHours: function(d) { return "Otevírací doba"; },
  openingHoursSourceLabel: function(d) { return "otevírací dobu"; },
  mondayOpeningHours: function(d) { return "Pondělí:"; },
  mondayOpeningHoursLabel: function(d) { return "v pondělí"; },
  tuesdayOpeningHours: function(d) { return "Úterý:"; },
  tuesdayOpeningHoursLabel: function(d) { return "v úterý"; },
  wednesdayOpeningHours: function(d) { return "Středa:"; },
  wednesdayOpeningHoursLabel: function(d) { return "ve středu"; },
  thursdayOpeningHours: function(d) { return "Čtvrtek:"; },
  thursdayOpeningHoursLabel: function(d) { return "ve čtvrtek"; },
  fridayOpeningHours: function(d) { return "Pátek:"; },
  fridayOpeningHoursLabel: function(d) { return "v pátek"; },
  saturdayOpeningHours: function(d) { return "Sobota:"; },
  saturdayOpeningHoursLabel: function(d) { return "v sobotu"; },
  sundayOpeningHours: function(d) { return "Neděle:"; },
  sundayOpeningHoursLabel: function(d) { return "v neděli"; },
  openingHoursDescription: function(d) { return "Poznámka:"; },
  openingHoursRangeNotValid: function(d) { return "Nevalidní rozsah"; },
  openingHoursPmNotValid: function(d) { return "Začíná před koncem dopolední otevírací doby"; },
  contact: function(d) { return "Kontakty"; },
  contactSourceLabel: function(d) { return "kontakty"; },
  email: function(d) { return "E-mail:"; },
  emailPlaceholder: function(d) { return "jmeno@email.cz"; },
  emailNamePlaceholder: function(d) { return "Přidejte název emailu"; },
  defaultEmail: function(d) { return "Kontaktní email"; },
  addEmail: function(d) { return "Přidat email"; },
  web: function(d) { return "Web:"; },
  phone: function(d) { return "Telefon:"; },
  phonePlaceholder: function(d) { return "+420 123 456 789"; },
  phoneNamePlaceholder: function(d) { return "Přidejte název kontaktu"; },
  defaultPhone: function(d) { return "Kontaktní telefon"; },
  addPhone: function(d) { return "Přidat telefon"; },
  emailSubject: function(d) { return "Obnova registrace - " + d.PREMISE_NAME + ", " + d.ICO; },
  address: function(d) { return "Adresa"; },
  addressSourceLabel: function(d) { return "adresu"; },
  save: function(d) { return "Uložit změny"; },
  sendSuccessMessage: function(d) { return "Profil byl úspěšně uložen."; },
  sendErrorMessage: function(d) { return "Profil se nepodařilo uložit."; },
  nameRequired: function(d) { return "Vyplňte název společnosti"; },
  nameNotValid: function(d) { return "Neplatný název společnosti"; },
  descriptionRequired: function(d) { return "Vyplňte popisek společnosti"; },
  descriptionNotValid: function(d) { return "Neplatný popisek společnosti"; },
  openingOursDescriptionRequired: function(d) { return "Vyplňte poznámku"; },
  openingOursDescriptionNotValid: function(d) { return "Neplatná poznámka"; },
  emailRequired: function(d) { return "Vyplňte kontaktní email"; },
  emailNotValid: function(d) { return "Neplatný kontaktní email"; },
  emailNameRequired: function(d) { return "Vyplňte název kontaktního emailu"; },
  emailNameNotValid: function(d) { return "Neplatný název kontaktního emailu"; },
  webRequired: function(d) { return "Vyplňte web"; },
  webNotValid: function(d) { return "Neplatný web"; },
  phoneRequired: function(d) { return "Vyplňte kontatkní telefon"; },
  phoneNotValid: function(d) { return "Neplatný kontaktní telefon"; },
  phoneNameRequired: function(d) { return "Vyplňte název kontaktního telefonu"; },
  phoneNameNotValid: function(d) { return "Neplatný název kontaktního telefonu"; },
  addressRequired: function(d) { return "Vyplňte adresu"; },
  addressNotValid: function(d) { return "Neplatná adresa"; },
  walletNotValid: function(d) { return "Neplatný email k peněžence"; },
  errorToastOpeningHours: function(d) { return "Máte špatně zadanou otevírací dobu."; },
  errorToastAddress: function(d) { return "Máte špatně zadanou adresu."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	