import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { StatusMessage } from '@inzeraty/components'
import select from 'ima-plugin-select'
import { Surface } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import EQUIPMENT_STATE_KEYS from '../IAEquipmentStateKeys'
import EQUIPMENT_STATUS_CODES from 'app/page/userweb/newAdvert/equipment/EquipmentStatusCodes'
import EquipmentWidget, {
	EQUIPMENT_WIDGET_ID
} from 'app/page/userweb/newAdvert/equipment/components/widgets/equipment/EquipmentWidget'
import EquipmentPlaceholders from 'app/page/userweb/newAdvert/equipment/components/placeholders/EquipmentPlaceholders'
import FormStepper from 'app/page/userweb/newAdvert/component/stepper/FormStepperView'
import PropTypes from 'prop-types'
import AdvertPropTypes from 'app/model/advert/AdvertPropTypes'
import CebiaDataLoadBtn from 'app/component/cebia/CebiaDataLoadBtn'

import './IAEquipmentFormCS.json'
import './IAEquipmentForm.less'

const CLASSNAME = 'c-ia-equipment-form'

const { Form } = FormLines

const WIDGETS_BY_ID = Object.freeze({
	[EQUIPMENT_WIDGET_ID]: EquipmentWidget
})

const IAEquipmentForm = (props) => {
	const localize = useLocalize()

	const {
		advertEntity = {},
		formLineEntity,
		onChange,
		isFormBeingSubmitted = false,
		submitForm,
		scrollPageToTop,
		formStatus,
		setFormStatus
	} = props

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			[formLineEntity],
			{
				onChange
			},
			WIDGETS_BY_ID
		)
	}

	const renderSubmitButton = () => {
		// - tlacitko s prazdnym textem se nevyrenderuje
		const saveAndLeaveBtnProps = { text: '' }

		return (
			<FormStepper
				loading={isFormBeingSubmitted}
				nextBtnProps={{
					type: 'submit',
					text: localize('IAEquipmentForm.save')
				}}
				isAdmin={true}
				saveAndLeaveBtnProps={saveAndLeaveBtnProps}
			/>
		)
	}

	const renderFormContent = (renderWidgets, renderSubmitButton) => {
		const { vin } = advertEntity

		return (
			<>
				<Surface surface={5} className={`${CLASSNAME}__form`}>
					<div className={`${CLASSNAME}__header`}>
						<h1 className={`${CLASSNAME}__heading`}>
							{localize('IAEquipmentForm.equipmentHeading')}
						</h1>

						<CebiaDataLoadBtn
							vin={vin}
							disabled={!vin}
							className={`${CLASSNAME}__cebia-data-load-btn`}
						/>
					</div>

					<div>{renderFormStatusMessage()}</div>

					{formLineEntity ? renderWidgets() : <EquipmentPlaceholders />}
				</Surface>

				{renderSubmitButton()}
			</>
		)
	}

	const onSubmit = () => {
		submitForm({
			formLineEntity,
			onErrorCallback: onSubmitFormError
		})
	}

	const onSubmitFormError = (errors) => {
		// doslo k nezname chybe, napr. chyba site, ...
		setFormStatus({
			statusCode: EQUIPMENT_STATUS_CODES.VALIDATION_ERRORS_UNKNOWN
		})

		scrollPageToTop()
	}

	const renderFormStatusMessage = () => {
		if (formStatus && formStatus.statusCode === EQUIPMENT_STATUS_CODES.VALIDATION_ERRORS_UNKNOWN) {
			return (
				<StatusMessage
					className={`${CLASSNAME}__status-msg`}
					type={StatusMessage.TYPE.ERROR}
					title={localize('IAEquipmentForm.statusValidationErrorsUnknown')}
				/>
			)
		}
	}

	return (
		<Form
			formLineEntities={[]}
			isSending={isFormBeingSubmitted}
			renderWidgets={renderWidgets}
			renderFormContent={renderFormContent}
			onSubmit={onSubmit}
			renderSubmitButton={renderSubmitButton}
		/>
	)
}

const selectors = (state) => ({
	advertEntity: state[EQUIPMENT_STATE_KEYS.ADVERT_ENTITY],
	formLineEntity: state[EQUIPMENT_STATE_KEYS.FORM_LINE],
	onChange: state[EQUIPMENT_STATE_KEYS.ON_CHANGE],

	isFormBeingSubmitted: state[EQUIPMENT_STATE_KEYS.IS_FORM_BEING_SUBMITTED],
	submitForm: state[EQUIPMENT_STATE_KEYS.SUBMIT_FORM],

	formStatus: state[EQUIPMENT_STATE_KEYS.FORM_STATUS],
	setFormStatus: state[EQUIPMENT_STATE_KEYS.SET_FORM_STATUS],

	scrollPageToTop: state[EQUIPMENT_STATE_KEYS.SCROLL_PAGE_TO_TOP]
})

IAEquipmentForm.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	onChange: PropTypes.func,
	isFormBeingSubmitted: PropTypes.bool,
	submitForm: PropTypes.func,
	scrollPageToTop: PropTypes.func,
	formStatus: PropTypes.object,
	setFormStatus: PropTypes.func,
	advertEntity: PropTypes.shape(AdvertPropTypes)
}

export default React.memo(select(selectors)(IAEquipmentForm))
