import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { Button } from '@sznds/react'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import { Format } from '@inzeraty/helpers'
import { Loading } from '@inzeraty/components'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { SEARCH_TYPE_SESSION } from 'app/base/StorageIds'
import getRouteName from 'app/helpers/routeName/getRouteName'
import ROUTE_NAMES from 'app/base/RouteNames'

import './ShowItemsButton.less'
import './ShowItemsButtonCS.json'

const CLASSNAME = 'cf-show-items-button'

export default class ShowItemsButton extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			url: PropTypes.string,
			count: PropTypes.number,
			isLoading: PropTypes.bool,
			disabled: PropTypes.bool,
			showIcon: PropTypes.bool,
			onClick: PropTypes.func,
			renderText: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			className: '',
			isLoading: false,
			disabled: false,
			showIcon: true,
			onClick: () => {}
		}
	}

	constructor(props, context) {
		super(props, context)

		this._handleShowItemsButtonClick = this._handleShowItemsButtonClick.bind(this)
	}

	render() {
		const { className, url, disabled, showIcon } = this.props

		const buttonIconProp = showIcon ? { icon: SEARCH_OUTLINE_24 } : {}

		const restProps = Object.assign({}, this.props)

		delete restProps.className
		delete restProps.url
		delete restProps.count
		delete restProps.isLoading
		delete restProps.disabled
		delete restProps.showIcon
		delete restProps.onClick
		delete restProps.renderText

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				<Button
					primary={true}
					disabled={disabled}
					text={this._getText()}
					className={`${CLASSNAME}__btn`}
					href={url}
					{...buttonIconProp}
					onClick={this._handleShowItemsButtonClick}
					{...restProps}
				/>
			</div>
		)
	}

	_handleShowItemsButtonClick(event) {
		const { onClick } = this.props

		const routeName = getRouteName(this._utils.$Router)

		this.utils.$SessionStorage.set(SEARCH_TYPE_SESSION, {
			searchType: routeName === ROUTE_NAMES.USERWEB.HOMEPAGE ? 'homepage' : 'filters',
			query: undefined,
			lastUrlRouteParams: this._utils.$Router.getCurrentRouteInfo().params
		})

		onClick(event)
	}

	_getText() {
		const { isLoading, count, renderText } = this.props

		const renderLoadingDots = () => (
			<Loading
				className={`${CLASSNAME}__loading-dots`}
				loadingType={Loading.loadingType.ONLY_DOT}
			/>
		)

		if (renderText) {
			return renderText(renderLoadingDots)
		}

		if (isLoading) {
			return (
				<React.Fragment>
					{this.localize('ShowItemsButton.ShowAdvertsPrefix')}
					{renderLoadingDots()}
					{this.localize('ShowItemsButton.ShowAdvertsSuffix')}
				</React.Fragment>
			)
		} else {
			if (!isNullOrUndefined(count) && count > 0) {
				return this.localize('ShowItemsButton.ShowAdverts', {
					ADVERT_COUNT: count,
					COUNT_NICE: Format.number(count)
				})
			} else {
				return this.localize('ShowItemsButton.noResults')
			}
		}
	}
}
