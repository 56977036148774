import React from 'react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import ItemAgeForm from 'app/component/filters/forms/itemAge/ItemAgeForm'
import ResetFilter from 'app/component/filters/components/resetFilter/ResetFilterView'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './ItemAgeInlineForm.less'

const CLASSNAME = 'c-item-age-inline-form'

const ItemAgeInlineForm = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const { id, value = {} } = formLineEntity

	const isSelectionMade = value.value > 0

	return (
		<div className={CLASSNAME} data-dot='filter' data-dot-data={`{"type": "${formLineEntity.id}"}`}>
			{isSelectionMade && <ResetFilter onClick={() => onChange(id, {})} />}

			<div className={`${CLASSNAME}__form`}>
				<ItemAgeForm {...props} />
			</div>
		</div>
	)
}

ItemAgeInlineForm.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func
}

export default React.memo(ItemAgeInlineForm)
