import React, { memo } from 'react'
import { Checkbox } from '@sznds/react'
import { CLASSNAME } from '../InternalAdminWidgets'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './WidgetCheckboxOptions.less'

const OPT_CLASSNAME = 'c-ia-widget-checkbox-options'

const WidgetCheckboxOptions = memo(({ className, formLineEntity = {}, onChange }) => {
	const {
		id,
		label,
		value = [],
		required = false,
		disabled = false,
		options = [],
		errorMessage
	} = formLineEntity

	const isError = Boolean(errorMessage)

	return (
		<div className={className}>
			<div className={`${CLASSNAME}__input-box ${OPT_CLASSNAME}`}>
				{!!label && (
					<label htmlFor={id} className={`${CLASSNAME}__label`}>
						{label}
						{required && <span className={`${CLASSNAME}__req`}>*</span>}
					</label>
				)}
				{isError && <div className={`${CLASSNAME}__error`}>{errorMessage}</div>}
				<div className={`${OPT_CLASSNAME}__options`}>
					{options.map(({ name: optName, value: optValue }, index) => {
						return (
							<Checkbox
								key={index}
								label={optName}
								checked={value.includes(optValue)}
								onChange={(event) => {
									event.stopPropagation()
									const newValue = [...value]
									const valueIndex = value.findIndex((val) => val === optValue)
									if (event.target.checked && valueIndex < 0) {
										newValue.push(optValue)
									} else if (!event.target.checked) {
										newValue.splice(valueIndex, 1)
									}
									onChange({
										id,
										value: newValue
									})
								}}
								disabled={disabled}
								className={`${OPT_CLASSNAME}__option`}
								error={isError}
							/>
						)
					})}
				</div>
			</div>
		</div>
	)
})

WidgetCheckboxOptions.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func,
	className: PropTypes.string,
	options: PropTypes.array
}

export default WidgetCheckboxOptions
