import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'

import './ResetFilterView.less'
import './ResetFilterCS.json'

const CLASSNAME = 'cf-reset'

export default class ResetFilterView extends AbstractPureComponent {
	static get propTypes() {
		return {
			text: PropTypes.string
		}
	}

	render() {
		const { text = this.localize('ResetFilter.reset') } = this.props

		return (
			<div className={CLASSNAME}>
				<button
					className={`${CLASSNAME}__btn`}
					type='button'
					onClick={this.props.onClick}
					data-dot='delete'
				>
					{text}
				</button>
			</div>
		)
	}
}
