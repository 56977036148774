import React from 'react'
import AbstractRadioButtonForm from '../AbstractRadioButtonForm'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'

import './ItemAgeForm.less'

const CLASSNAME = 'cf-item-age-form'

export default class ItemAgeForm extends AbstractRadioButtonForm {
	static get propTypes() {
		return AbstractRadioButtonForm.propTypes
	}

	static get defaultProps() {
		return AbstractRadioButtonForm.defaultProps
	}

	constructor(props, context) {
		super(props, context)

		this._isCompactFilter = this._isCompactFilter.bind(this)
	}

	render() {
		const { className } = this.props

		const isCompactFilter = this._isCompactFilter()

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				{this._renderShortCuts(isCompactFilter)}
			</div>
		)
	}

	_isCompactFilter() {
		const { context } = this.props

		return (
			context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL ||
			context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL_INLINE ||
			context === FILTER_CONTEXT.EXTENDED_FILTER_INLINE
		)
	}

	_onChange(event) {
		const { context } = this.props

		if (context !== FILTER_CONTEXT.EXTENDED_FILTER_INLINE) {
			event.stopPropagation()
		}

		const value = Number(event.target.value)

		const valueData = {
			value
		}

		this._changeValue(valueData)
	}
}
