import React from 'react'
import PropTypes from 'prop-types'
import BasicWidget from '../basic/BasicWidget'
import * as FormLines from '@inzeraty/form-lines'
import BaseTextWidget, {
	TEXT_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/text/TextWidget'

import './TextWidget.less'

export { TEXT_WIDGET_ID }

const CLASSNAME = 'c-review-text-widget'

const TextWidget = ({ formLineEntity, onChange, onBlur }) => {
	return (
		<BasicWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={TEXT_WIDGET_ID}
			renderInput={(formLineEntity, inputProps) => {
				return (
					<div className={`${CLASSNAME}__input-wrapper`}>
						<BaseTextWidget
							{...inputProps}
							formLineEntity={formLineEntity}
							onChange={onChange}
							onBlur={onBlur}
						/>
					</div>
				)
			}}
		/>
	)
}

TextWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired
}

export default React.memo(TextWidget)
