import React from 'react'
import BlankManagedRootView from 'ima/page/renderer/BlankManagedRootView'
import UserwebBaseController from 'app/base/UserwebBaseController'
import UserEntityContext from './UserEntityContext'
import SspContext from 'app/component/sspAdverts/SspContext'
import Ribbon from 'app/component/ribbon/Ribbon'
import { AssignEmail, RibbonExtension } from '@inzeraty/components'
import DialogInternetExplorer from 'app/component/dialogInternetExplorer/DialogInternetExplorer'
import ClientBaseController from 'app/base/ClientBaseController'
import LiveChat from 'app/component/liveChat/LiveChat'
import IMAGES from 'app/base/ImagesConstants'

export default class ManagedRootView extends BlankManagedRootView {
	render() {
		const {
			[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelf,
			[RibbonExtension.stateKeys.RIBBON_WIDGET_DATA]: ribbonWidgetData,
			[ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise
		} = this.props

		return (
			<UserEntityContext.Provider value={userSelf}>
				<SspContext.Provider value={this.props[UserwebBaseController.STATE_KEYS.DATA_FOR_SSP]}>
					<Ribbon widgetData={ribbonWidgetData} isPremise={isPremise} />
					<DialogInternetExplorer />
					{super.render()}
					<LiveChat isPremise={isPremise} />
					<AssignEmail userSelf={userSelf} logo={IMAGES.image__logo} />
				</SspContext.Provider>
			</UserEntityContext.Provider>
		)
	}
}
