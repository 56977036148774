import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocalize, useFire, useLink } from 'app/base/componentHelpers'
import { CATEGORIES } from 'app/base/Constants'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import { Format, DefaultProps, HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import classnames from 'classnames'
import { Surface } from '@sznds/react'
import ActionsMenu from 'app/component/actionsMenu/ActionsMenu'
import { NOTIFICATIONS_FILLED_24, TRASH_FILLED_24 } from '@sznds/icons'
import MySavedSearchSaveForm from 'app/component/mySavedSearch//saveForm/MySavedSearchSaveForm'
import MySavedSearchToUrlAppParamsConvertor from 'app/model/mySavedSearch/convertors/MySavedSearchToUrlAppParamsConvertor'
import MySavedSearchPrint from 'app/model/mySavedSearch/print/MySavedSearchPrint'
import ROUTE_NAMES from 'app/base/RouteNames'
import { StatusMessage } from '@inzeraty/components'
import MySavedSearchPropTypes from 'app/model/mySavedSearch/MySavedSearchPropTypes'

import 'app/component/mySavedSearch/addMySavedSearch/AddMySavedSearchCS.json'

import 'app/base/BaseCS'
import './MySavedSearchList.less'
import './MySavedSearchListCS.json'

const CLASSNAME_ITEM = 'c-my-saved-search-item'

const MySavedSearchItem = ({ mySavedSearchEntity, editSearchEntity }) => {
	const { id, filter, newCount, count, operatingLease = false } = mySavedSearchEntity

	const localize = useLocalize()
	const fire = useFire()
	const link = useLink()

	const [actionsIsOpen, setActionsIsOpen] = useState(false)

	const isPlh = !id

	let categoryIcon = ''
	let filterName = ''
	let filterDescription = ''
	let actions = []
	let searchUrl = ''

	const handleAction = (action) => {
		fire(action, id)
		setActionsIsOpen(false)
	}

	if (!isPlh) {
		const { category_id } = filter

		const categoryData = Object.values(CATEGORIES).find(({ id }) => id === category_id)
		categoryIcon = categoryData ? categoryData.svgIcon : ''

		filterName = MySavedSearchPrint.printTitle(mySavedSearchEntity)
		filterDescription = MySavedSearchPrint.printSentence(localize, mySavedSearchEntity)

		actions = [
			{
				tagName: 'button',
				icon: NOTIFICATIONS_FILLED_24,
				text: localize('MySavedSearchList.actionEditNotification'),
				actionProps: {
					onClick: () => {
						setActionsIsOpen(false)
						editSearchEntity(mySavedSearchEntity)
					}
				}
			},
			{
				tagName: 'button',
				icon: TRASH_FILLED_24,
				text: localize('Base.delete'),
				actionProps: {
					onClick: () => {
						handleAction('deleteMySavedSearch')
					}
				}
			}
		]

		const routeName = operatingLease
			? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
			: ROUTE_NAMES.USERWEB.ADVERT_LIST
		const urlParams = MySavedSearchToUrlAppParamsConvertor.convert(mySavedSearchEntity)

		searchUrl = link(routeName, urlParams)
	}

	return (
		<li
			className={classnames({
				[CLASSNAME_ITEM]: true,
				[`${CLASSNAME_ITEM}--plh`]: isPlh
			})}
		>
			<div className={`${CLASSNAME_ITEM}__search-wrap`}>
				{isPlh && <div className={`${CLASSNAME_ITEM}__category-icon`}></div>}
				{!isPlh && <SvgIcon icon={categoryIcon} className={`${CLASSNAME_ITEM}__category-icon`} />}
				<div className={`${CLASSNAME_ITEM}__info`}>
					{isPlh && <span className={`${CLASSNAME_ITEM}__link`}></span>}
					{!isPlh && (
						<a
							className={`${CLASSNAME_ITEM}__link`}
							href={searchUrl}
							onClick={() => fire('resetCache')}
						>
							{filterName}
						</a>
					)}
					<div className={`${CLASSNAME_ITEM}__filter`}>{!isPlh && filterDescription}</div>
				</div>
				<div className={`${CLASSNAME_ITEM}__actions-wrap`}>
					{isPlh && <div className={`${CLASSNAME_ITEM}__actions`}></div>}
					{!isPlh && (
						<ActionsMenu
							className={`${CLASSNAME_ITEM}__actions`}
							modalTitle={localize('MySavedSearchList.savedSearchActions')}
							blindClosePrefix={localize('MySavedSearchList.actionsBlindClosePrefix')}
							actions={actions}
							isOpen={actionsIsOpen}
							setIsOpenCallback={(isOpen) => {
								setActionsIsOpen(isOpen)
							}}
						/>
					)}
				</div>
			</div>
			<div className={`${CLASSNAME_ITEM}__count-wrap`}>
				{isPlh ? (
					<span className={`${CLASSNAME_ITEM}__count`}></span>
				) : (
					<a
						className={classnames({
							[`${CLASSNAME_ITEM}__count`]: true,
							[`${CLASSNAME_ITEM}__count--new`]: newCount > 0
						})}
						href={searchUrl}
						onClick={() => fire('resetCache')}
					>
						{newCount > 0
							? localize('MySavedSearchList.newsCount', {
									COUNT: Format.number(newCount)
							  })
							: localize('MySavedSearchList.itemsCount', {
									COUNT: Format.number(count)
							  })}
					</a>
				)}
			</div>
		</li>
	)
}

MySavedSearchItem.propTypes = {
	mySavedSearchEntity: PropTypes.shape(MySavedSearchPropTypes).isRequired,
	editSearchEntity: PropTypes.func.isRequired
}

MySavedSearchItem.defaultProps = {
	mySavedSearchEntity: DefaultProps.OBJECT,
	editSearchEntity: DefaultProps.FUNCTION
}

const MySavedSearchList = ({
	mySavedSearchEntities,
	isLoading,
	getMySavedSearch,
	updateMySavedSearch,
	showToastMessage
}) => {
	const localize = useLocalize()

	const placeholders = Array.from(new Array(5))

	const [isEditPopupOpened, setIsEditPopupOpened] = useState(false)
	const [mySavedSearchEntityToUpdate, setMySavedSearchEntityToUpdate] = useState(undefined)
	const [statusMessage, setStatusMessage] = useState(undefined)

	const onClose = () => {
		setIsEditPopupOpened(false)
		setMySavedSearchEntityToUpdate(undefined)
		setStatusMessage(undefined)
	}

	const handleStartEditingMySavedSearch = async (mySavedSearch = {}) => {
		const { id } = mySavedSearch

		if (id) {
			setIsEditPopupOpened(true)

			try {
				// volame proto, abysme si stahli ze serveru nejnovejsi data
				const mySavedSearchToUpdate = await getMySavedSearch(id, { cache: false })
				setMySavedSearchEntityToUpdate(mySavedSearchToUpdate)
			} catch (error) {
				const httpStatus = HttpErrorHelper.getHttpStatus(error)

				if (httpStatus === HttpStatusCodes.NOT_FOUND) {
					onClose()
					showToastMessage({
						title: localize('MySavedSearchStatusMessage.mySavedSearchAlreadyDeletedMsg'),
						type: StatusMessage.TYPE.SUCCESS
					})
				} else {
					setStatusMessage({
						title: localize('MySavedSearchList.failedToLoadMySavedSearch')
					})
				}
			}
		}
	}

	const onSave = async (mySavedSearchEntity, notificationInterval, ribbonNotificationEnabled) => {
		try {
			await updateMySavedSearch(
				mySavedSearchEntity.id,
				notificationInterval,
				ribbonNotificationEnabled
			)
			onClose()
			showToastMessage({
				title: localize('AddMySavedSearch.success'),
				type: StatusMessage.TYPE.SUCCESS
			})
		} catch (e) {
			setStatusMessage({
				title: localize('AddMySavedSearch.saveError')
			})
		}
	}

	return (
		<>
			<Surface tagName='ul' surface={4}>
				{(isLoading ? placeholders : mySavedSearchEntities).map((mySavedSearchEntity, index) => (
					<MySavedSearchItem
						key={index}
						mySavedSearchEntity={mySavedSearchEntity}
						editSearchEntity={handleStartEditingMySavedSearch}
					/>
				))}

				{isEditPopupOpened && (
					<MySavedSearchSaveForm
						isPopupOpened={true}
						isLoading={!mySavedSearchEntityToUpdate}
						mySavedSearch={mySavedSearchEntityToUpdate}
						onClose={onClose}
						onSave={onSave}
						renderStatusMessage={() =>
							statusMessage ? (
								<StatusMessage type={StatusMessage.TYPE.ERROR} title={statusMessage.title}>
									{statusMessage.children}
								</StatusMessage>
							) : undefined
						}
					/>
				)}
			</Surface>
		</>
	)
}

MySavedSearchList.propTypes = {
	mySavedSearchEntities: PropTypes.arrayOf(PropTypes.shape(MySavedSearchPropTypes)),
	isLoading: PropTypes.bool,
	getMySavedSearch: PropTypes.func,
	updateMySavedSearch: PropTypes.func,
	showToastMessage: PropTypes.func
}

MySavedSearchList.defaultProps = {
	mySavedSearchEntities: DefaultProps.ARRAY,
	isLoading: false,
	getMySavedSearch: DefaultProps.FUNCTION,
	updateMySavedSearch: DefaultProps.FUNCTION,
	showToastMessage: DefaultProps.FUNCTION
}

export default React.memo(MySavedSearchList)
