import React, { useRef, useContext, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ImaContext from 'ima/page/context'
import { Icon, Spinner } from '@sznds/react'
import { CLOSE_OUTLINE_24, PIN_OUTLINE_24 } from '@sznds/icons'
import { useLocalize } from 'app/base/componentHelpers'
import SimpleTooltip from 'app/component/simpleTooltip/SimpleTooltipView'

import './PhotoPreview.less'
import './PhotoPreviewCS.json'

const CLASSNAME = 'c-photo-prev'

const PhotoPreview = React.memo(
	({ index, photo: { id, error, image, isLoading }, photosCount, className }) => {
		const { $EventBus } = useContext(ImaContext)
		const ref = useRef(null)
		const localize = useLocalize()

		const isFirst = index === 0
		const showPin = !isFirst && !error

		const textContent = error
			? localize(`PhotoPreview.${error}`)
			: isFirst
			? localize('PhotoPreview.firstPhoto')
			: ''

		const wrapperRef = useRef(null)
		const pinConfirmationRef = useRef(null)

		useLayoutEffect(() => {
			// Pokud image není string, mělo by se jednat o canvas.
			if (typeof image !== 'string') {
				const wraperElm = wrapperRef.current
				wraperElm.innerHTML = ''
				wraperElm.appendChild(image)
			}
		}, [])

		const renderText = (textContent) => (
			<div
				className={classnames({
					[`${CLASSNAME}__text`]: true,
					[`${CLASSNAME}__text--error`]: error
				})}
			>
				{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
				<span tabIndex={0}>{textContent}</span>
			</div>
		)

		const renderPin = () => (
			<SimpleTooltip
				className={`${CLASSNAME}__pin-wrap`}
				tooltipClassName={`${CLASSNAME}__pin-tooltip`}
				description={localize('PhotoPreview.pinTitle')}
				ariaHidden={true}
				renderContent={(className) => (
					<button
						type='button'
						className={`${CLASSNAME}__pin ${className}`}
						onClick={() => {
							pinConfirmationRef.current.focus()
							$EventBus.fire(ref.current, 'setFirstPhoto', id)
						}}
						aria-label={localize('PhotoPreview.pinTitleAria', {
							INDEX: index + 1,
							COUNT: photosCount
						})}
					>
						<Icon symbol={PIN_OUTLINE_24} />
					</button>
				)}
			/>
		)

		const renderPinConfirmation = () => (
			<span
				tabIndex='-1'
				ref={pinConfirmationRef}
				className={`${CLASSNAME}__accessible-confirmation`}
			>
				{localize('PhotoPreview.pinConfirmation')}
			</span>
		)

		const renderLoading = () => (
			<div className={`${CLASSNAME}__loader`}>
				<Spinner className={`${CLASSNAME}__spinner`} />
				{localize('PhotoPreview.loading')}
			</div>
		)

		const renderRemove = () => (
			<button
				type='button'
				className={`${CLASSNAME}__remove`}
				onClick={() => {
					$EventBus.fire(ref.current, 'removePhoto', id)
				}}
				aria-label={localize('PhotoPreview.removeLabel')}
			>
				<Icon symbol={CLOSE_OUTLINE_24} />
			</button>
		)

		return (
			<div
				ref={ref}
				className={classnames({
					[className]: !!className,
					[CLASSNAME]: true,
					[`${CLASSNAME}--error`]: error
				})}
			>
				<div className={`${CLASSNAME}__inner`}>
					<div className={`${CLASSNAME}__wrap`} data-e2e='photo-preview' ref={wrapperRef}>
						{
							/* Pokud dostane image jako string, tak se jedná o url a vloží normálně obrátzek. */
							typeof image === 'string' && (
								<img src={image} className={`${CLASSNAME}__img`} alt='' />
							)
						}
					</div>
				</div>

				{isLoading && renderLoading()}

				{!isLoading && showPin && renderPin()}
				{!isLoading && renderPinConfirmation()}
				{!isLoading && textContent && renderText(textContent)}
				{!isLoading && renderRemove()}
			</div>
		)
	}
)

PhotoPreview.propTypes = {
	/**
	 * Soubor fotky, jehož náhled se pokusí zobrazit.
	 */
	photo: PropTypes.object.isRequired,
	/**
	 * Poradi fotky.
	 */
	index: PropTypes.number,
	/**
	 * Příznak načítání obrázku.
	 */
	isLoading: PropTypes.bool,
	/**
	 * Počet všech fotek.
	 */
	photosCount: PropTypes.number,
	className: PropTypes.string
}

PhotoPreview.defaultProps = {
	isLoading: false,
	photosCount: 1
}

export default PhotoPreview
