import React from 'react'
import { Format } from '@inzeraty/helpers'
import AbstractOpener from '../AbstractOpener'
import FilterConstants from 'app/model/filter/FilterConstants'

import './RangeInputOpenerCS.json'

export default class RangeInputOpener extends AbstractOpener {
	static get propTypes() {
		return AbstractOpener.propTypes
	}

	static get defaultProps() {
		return AbstractOpener.defaultProps
	}

	_renderOpener() {
		const {
			formLineEntity: { label }
		} = this.props

		return (
			<React.Fragment>
				<div>{label}</div>
				{super._renderOpener()}
			</React.Fragment>
		)
	}

	_getTextAndHasValue() {
		const { formLineEntity } = this.props
		const { value = {} } = formLineEntity
		const { value: optionValue, valueFrom, valueTo } = value

		let text = ''
		let hasValue

		if (optionValue && (valueFrom || valueTo)) {
			if (valueFrom && !valueTo) {
				const textFrom = `${this.localize('RangeInputOpener.From')} ${Format.number(valueFrom)}`

				text = `${textFrom} ${this._getUnit(valueFrom)}`
			} else if (!valueFrom && valueTo) {
				text = `${this.localize('RangeInputOpener.To')} ${Format.number(valueTo)} ${this._getUnit(
					valueTo
				)}`
			} else if (valueFrom && valueTo) {
				if (valueFrom === valueTo) {
					text = `${Format.number(valueFrom)} ${this._getUnit(valueTo, true)}`
				} else {
					text = `${Format.number(valueFrom)}–${Format.number(valueTo)} ${this._getUnit(valueTo)}`
				}
			}

			hasValue = true
		} else {
			text = formLineEntity.placeholder
			hasValue = false
		}

		return {
			text,
			hasValue
		}
	}

	_getUnit(number, sameValue = false) {
		const { formLineEntity } = this.props
		const { id, extra = {} } = formLineEntity

		if (extra.unit) {
			return extra.unit
		} else if (FilterConstants.formLineIds.AIRBAGS === id) {
			if (sameValue) {
				return this.localize('RangeInputOpener.airbagsSameValue', { AIRBAGS_COUNT: number })
			} else {
				return this.localize('RangeInputOpener.airbags', { AIRBAGS_COUNT: number })
			}
		} else if (FilterConstants.formLineIds.CAPACITY === id) {
			if (sameValue) {
				return this.localize('RangeInputOpener.capacitySameValue', { CAPACITY_COUNT: number })
			} else {
				return this.localize('RangeInputOpener.capacity', { CAPACITY_COUNT: number })
			}
		} else if (FilterConstants.formLineIds.DOORS === id) {
			if (sameValue) {
				return this.localize('RangeInputOpener.doorsSameValue', { DOORS_COUNT: number })
			} else {
				return this.localize('RangeInputOpener.doors')
			}
		} else if (FilterConstants.formLineIds.WEIGHT === id) {
			return this.localize('RangeInputOpener.weight')
		} else if (FilterConstants.formLineIds.BEDS === id) {
			return this.localize('RangeInputOpener.beds', { BEDS_COUNT: number })
		} else if (FilterConstants.formLineIds.MACHINE_HOURS) {
			return this.localize('RangeInputOpener.machineHours')
		} else {
			return ''
		}
	}
}
