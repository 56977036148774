import { AdvertReportsService, AdvertConstants } from '@inzeraty/models'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import IAAdvertReportUrlConvertor from './IAAdvertReportUrlConvertor'

import 'app/component/report/ReportCS.json'

export default class IAAdvertReportController extends AbstractInternalAdminController {
	constructor(
		dependenciesHelper,
		advertService,
		advertReportsService,
		userService,
		forbidUserPopupExtension,
		forbidAdvertPopupExtension
	) {
		super(dependenciesHelper)

		this._advertService = advertService
		this._advertReportsService = advertReportsService
		this._userService = userService
		this._forbidUserExtension = forbidUserPopupExtension
		this._forbidAdvertExtension = forbidAdvertPopupExtension
		this._router = this._utils.$Router

		this._setAdvertRejected = this._setAdvertRejected.bind(this)
		this._setUserEnabledChanged = this._setUserEnabledChanged.bind(this)
	}

	static get ACTIONS() {
		return Object.freeze({
			ACTIVATE: 'activate',
			DEACTIVATE: 'deactivate',
			STATUS_NEW: 'statusNew',
			STATUS_PROCESSED: 'statusProcessed',
			OPEN_USER_FORBID: 'openUserForbid',
			OPEN_ADVERT_FORBID: 'openAdvertForbid'
		})
	}

	static get STATUS() {
		return Object.freeze({
			NEW: {
				value: AdvertReportsService.STATUS.NEW,
				name: 'Nový'
			}
		})
	}

	init() {
		super.init()
		this.addExtension(this._forbidUserExtension)
		this.addExtension(this._forbidAdvertExtension)

		this._forbidUserExtension.setSuccessHandler(this._setUserEnabledChanged)
		this._forbidAdvertExtension.setSuccessHandler(this._setAdvertRejected)
	}

	load() {
		const advertEntity = this._getAdvertEntity()
		const reportsAndPagination = this._getReportsAndPagination()
		const userEntity = this._getUserEntity(advertEntity)
		const historyEntitiesAndPagination = this._getAdvertHistory()

		return super.load({
			isFullyLoaded: Promise.all([advertEntity, reportsAndPagination, userEntity]).then(() => {
				return true
			}),
			advertEntity,
			reportsCount: this._getReportsCount(advertEntity),
			tableIsLoading: false,
			historyIsLoading: false,
			userHistoryIsLoading: false,
			reportsAndPagination,
			isUserForbidOpened: false,
			userEntity,
			[AbstractInternalAdminController.stateId.GO_BACK_LIST_URL]: this._getBackUrl(
				ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST_REPORTED
			),
			isNewNoteSaving: false,
			historyEntitiesAndPagination,
			userHistoryAndPagination: this._getUserHistoryAndPagination(advertEntity)
		})
	}

	update(prevParams = {}) {
		const actualUrlParams = this.getRouteParams()
		let updateParams = {}
		const historyEntitiesAndPagination = this._getAdvertHistory()
		const reportsAndPagination = this._getReportsAndPagination()
		const userHistoryAndPagination = this._getUserHistoryAndPagination(this._getAdvertEntity())
		this._pageLoaderExtension.show()

		//TODO : Jakmile dojde k navigaci na jedne tabulce, tak se automaticky provolaji requesty i na ostatnich (nezmenenych) tabulkach. -> upravit
		Promise.all([
			reportsAndPagination,
			historyEntitiesAndPagination,
			userHistoryAndPagination
		]).finally(() => {
			this._pageLoaderExtension.hide()
		})

		const { HISTORY_PAGE, HISTORY_LIMIT, HISTORY_SORT } = IAAdvertReportUrlConvertor.constants
		if (
			prevParams[HISTORY_LIMIT] !== actualUrlParams[HISTORY_LIMIT] ||
			prevParams[HISTORY_PAGE] !== actualUrlParams[HISTORY_PAGE] ||
			prevParams[HISTORY_SORT] !== actualUrlParams[HISTORY_SORT]
		) {
			updateParams = {
				...updateParams,
				historyIsLoading: true,
				historyEntitiesAndPagination
			}
		}

		const {
			USER_HISTORY_PAGE,
			USER_HISTORY_LIMIT,
			USER_HISTORY_SORT
		} = IAAdvertReportUrlConvertor.userHistoryConstants
		if (
			prevParams[USER_HISTORY_LIMIT] !== actualUrlParams[USER_HISTORY_LIMIT] ||
			prevParams[USER_HISTORY_PAGE] !== actualUrlParams[USER_HISTORY_PAGE] ||
			prevParams[USER_HISTORY_SORT] !== actualUrlParams[USER_HISTORY_SORT]
		) {
			updateParams = {
				...updateParams,
				userHistoryIsLoading: true,
				userHistoryAndPagination
			}
		}

		const { LIMIT, PAGE } = InternalAdminUrlConvertor.constants
		if (
			prevParams[LIMIT] !== actualUrlParams[LIMIT] ||
			prevParams[PAGE] !== actualUrlParams[PAGE]
		) {
			updateParams = {
				...updateParams,
				tableIsLoading: true,
				reportsAndPagination
			}
		}

		return updateParams
	}

	async _setAdvertRejected() {
		const { advertEntity } = this.getState()
		advertEntity.approvalAdmin = AdvertConstants.APPROVAL_STATUS.REJECTED

		const historyEntitiesAndPagination = await this._getAdvertHistory()

		this.setState({
			advertEntity,
			historyEntitiesAndPagination
		})
	}

	async _setUserEnabledChanged() {
		const {
			userEntity: { id }
		} = this.getState()

		const userEntity = await this._userService.getUserDetail(id)

		this.setState({
			userEntity
		})
	}

	_getUserEntity(advertEntity) {
		return advertEntity.then((advertEntity) => {
			const { user = {} } = advertEntity
			const { id } = user

			if (id) {
				return this._userService.getUserDetail(id)
			} else {
				return {}
			}
		})
	}

	_getAdvertId() {
		const { [InternalAdminUrlConvertor.constants.ADVERT_ID]: advertId } = this.getRouteParams()
		return advertId
	}

	_getReportId() {
		const { reportsAndPagination } = this.getState()
		const { reportsEntities } = reportsAndPagination
		const [report] = reportsEntities
		return report.id
	}

	_getAdvertEntity() {
		const advertId = this._getAdvertId()
		return this._advertService.getAdvertDetail(advertId, { for_editing: 1 }, { cache: false })
	}

	async _getReportsCount(advertEntityPromise) {
		const advertEntity = await advertEntityPromise

		const { premise = {}, user = {} } = advertEntity
		const { id: premiseId } = premise
		const { id: userId } = user

		const params = {}
		if (premiseId) {
			params.premise_id = premiseId
		} else {
			params.user_id = userId
		}

		const { reportsEntities = [] } = await this._advertReportsService.getReports(params)
		return reportsEntities.length
	}

	async _getReportsAndPagination() {
		const advertId = this._getAdvertId()
		const routeParams = Object.assign({}, this.getRouteParams())
		delete routeParams[InternalAdminUrlConvertor.constants.ADVERT_ID]
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		const reportsAndPagination = await this._advertReportsService.getAdvertReports(advertId, params)

		this.setState({
			tableIsLoading: false
		})

		return reportsAndPagination
	}

	async _getUserHistoryAndPagination(advertEntityPromise) {
		const { user } = await advertEntityPromise

		let response
		if (user?.id) {
			response = await this._userService.getUserHistoryList(
				user.id,
				IAAdvertReportUrlConvertor.getApiParamsForUserHistoryTable(this.getRouteParams())
			)
		}

		this.setState({
			userHistoryIsLoading: false
		})

		return {
			entities: response?.userHistoryEntities,
			paginationEntity: response?.pagination
		}
	}

	async _getAdvertHistory() {
		const advertId = this._getAdvertId()
		const params = IAAdvertReportUrlConvertor.getApiParamsForHistoryTable(this.getRouteParams())

		const {
			advertHistoryEntities,
			paginationEntity
		} = await this._advertService.getAdvertHistory(advertId, params, { cache: false })

		this.setState({
			historyIsLoading: false
		})

		return {
			entities: advertHistoryEntities,
			paginationEntity
		}
	}

	async _processSuccessAction(data) {
		const reportsAndPagination = await this._getReportsAndPagination()

		this.setState({
			reportsAndPagination
		})
	}

	_handleAction({ action, entities, entitiesIds = [] }) {
		let promise
		let getErrorMessage = () => ''
		const textAdvert = entitiesIds.length === 1 ? 'inzerát' : 'inzeráty'
		const textSuffix = `${textAdvert} s id ${entitiesIds.join(', ')}`
		const [entity] = entities
		const reportId = this._getReportId()

		switch (action) {
			case IAAdvertReportController.ACTIONS.ACTIVATE:
				promise = this._advertService.updateAdvertStatus({
					ids: [entity.id],
					status_active: true
				})
				getErrorMessage = () => `Nepodařilo se aktivovat ${textSuffix}`
				break

			case IAAdvertReportController.ACTIONS.DEACTIVATE:
				promise = this._advertService.updateAdvertStatus({
					ids: [entity.id],
					status_active: false
				})
				getErrorMessage = () => `Nepodařilo se vypnout ${textSuffix}`
				break

			case IAAdvertReportController.ACTIONS.STATUS_NEW:
				promise = this._advertReportsService.update(entity.id, reportId, {
					report_status: IAAdvertReportController.STATUS.NEW.value
				})
				getErrorMessage = () => `Nepodařilo se nastavit jako nový nahlášení s id ${reportId}`
				break

			case IAAdvertReportController.ACTIONS.STATUS_PROCESSED:
				promise = this._advertReportsService.advertReportsProcessed(entity.id)
				getErrorMessage = () => `Nepodařilo se nastavit jako zpracovaný nahlášení s id ${reportId}`
				break

			case IAAdvertReportController.ACTIONS.OPEN_USER_FORBID:
				this._forbidUserExtension.openPopup(this._getAdvertId(), entity.user?.id)
				break

			case IAAdvertReportController.ACTIONS.OPEN_ADVERT_FORBID:
				this._forbidAdvertExtension.openPopup(this._getAdvertId())
				break
		}
		return {
			promise,
			getErrorMessage
		}
	}
}
