import EntityFormExtension from 'app/component/entityForm/EntityFormExtension'
import { REASONS } from 'app/component/report/ReportFormLineConstants'
import { FORBID_REASONS } from 'app/model/advert/AdvertConstants'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { Validation } from '@inzeraty/helpers'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { StatusMessage } from '@inzeraty/components'
import { updateEntities } from '@inzeraty/form-lines'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'

import 'app/base/ActionMessageCS.json'

export default class ForbidAdvertPopupExtension extends EntityFormExtension {
	constructor(advertService, advertReportsService, utils) {
		super()
		this._advertService = advertService
		this._advertReportsService = advertReportsService
		this._utils = utils

		/**
		 * Určuje jestli se má před zakázáním inzerátu provést přidání do seznamu nahlášených.
		 * Týká se jen interního adminu
		 * Použití je v IA ve výpisu inzerátů, kdy chce inzerát admin zakázat bez předchozího nahlášení.
		 */
		this._addToReportedListInIA = false
	}

	static get STATE_KEYS() {
		return {
			...EntityFormExtension.STATE_KEYS,
			IS_ADVERT_FORBID_OPENED: 'isAdvertForbidOpened'
		}
	}

	static getReasonText(value, dictionary) {
		const hashTable = {
			[REASONS.SUSPICIOUS_ADVERT]: dictionary.get('Report.formSuspiciousAdvertLabel'),
			[REASONS.WRONG_CATEGORY]: dictionary.get('Report.formWrongCategoryLabel'),
			[REASONS.FALSE_INFO]: dictionary.get('Report.formFalseInfoLabel'),
			[REASONS.BAD_PHOTOS]: dictionary.get('Report.formBadPhotosLabel'),
			[REASONS.OTHER]: dictionary.get('Report.formOtherLabel')
		}
		return hashTable[value]
	}

	init() {
		this.initialize({
			getSubmitPromise: this._getFormSubmitPromise.bind(this),
			validations: this._advertFormValidations.bind(this),
			handleSubmitSuccess: this._handleAdvertFormSubmitSuccess.bind(this),
			handleSubmitError: this._handleAdvertFormSubmitError.bind(this)
		})
	}

	setAddToReportedListInIA() {
		this._addToReportedListInIA = true
	}

	load() {
		return super.load({
			[ForbidAdvertPopupExtension.STATE_KEYS.IS_ADVERT_FORBID_OPENED]: false
		})
	}

	setSuccessHandler(callBack) {
		this._successHandler = callBack
	}

	onCloseAdvertPopup() {
		this.setState({
			[ForbidAdvertPopupExtension.STATE_KEYS.IS_ADVERT_FORBID_OPENED]: false,
			entityFormError: undefined
		})
		this.setFormLineEntitiesData([])
		this._advertId = null
	}

	openPopup(advertId) {
		this._advertId = advertId
		this.setState({
			[ForbidAdvertPopupExtension.STATE_KEYS.IS_ADVERT_FORBID_OPENED]: true
		})
		this.setFormLineEntitiesData(this._getFormAdvertForbidData())
	}

	onSubmitAdvertForbid() {
		return this.onEntityFormSubmit()
	}

	_getFormAdvertForbidData() {
		return [
			{
				id: 'reason',
				label: 'Důvod zakázání',
				value: FORBID_REASONS.SUSPICIOUS,
				widget: IDS.SELECT,
				options: [
					{
						value: FORBID_REASONS.SUSPICIOUS,
						name: this._utils.$Dictionary.get('Report.formSuspiciousAdvertLabel')
					},
					{
						value: FORBID_REASONS.WRONG_CATEGORY,
						name: this._utils.$Dictionary.get('Report.formWrongCategoryLabel')
					},
					{
						value: FORBID_REASONS.FALSE_INFO,
						name: this._utils.$Dictionary.get('Report.formFalseInfoLabel')
					},
					{
						value: FORBID_REASONS.BAD_PHOTOS,
						name: this._utils.$Dictionary.get('Report.formBadPhotosLabel')
					},
					{
						value: FORBID_REASONS.OTHER,
						name: this._utils.$Dictionary.get('Report.formOtherLabel')
					}
				],
				required: true
			},
			{
				id: 'note',
				label: 'Popis důvodu',
				widget: IDS.TEXTAREA
			}
		]
	}

	_advertFormValidations() {
		return {
			action_note: ({ value }) => (Validation.isNotEmpty(value) ? '' : 'Popište důvod změny stavu')
		}
	}

	async _getFormSubmitPromise(formData) {
		const { reason, note } = formData

		if ((reason === FORBID_REASONS.OTHER && !note) || (note && note.trim() === '')) {
			return Promise.reject({
				type: 'noteIsRequired'
			})
		} else {
			const {
				[AbstractInternalAdminController.stateId.ADMIN_SELF]: adminEntity = {}
			} = this.getState()
			const { email } = adminEntity

			if (this._addToReportedListInIA) {
				const reasonMap = {
					[FORBID_REASONS.SUSPICIOUS]: REASONS.SUSPICIOUS_ADVERT,
					[FORBID_REASONS.WRONG_CATEGORY]: REASONS.WRONG_CATEGORY,
					[FORBID_REASONS.FALSE_INFO]: REASONS.FALSE_INFO,
					[FORBID_REASONS.BAD_PHOTOS]: REASONS.BAD_PHOTOS,
					[FORBID_REASONS.OTHER]: REASONS.OTHER
				}

				await this._advertService.saveReport(this._advertId, {
					user_email: email,
					report_reason: reasonMap[reason],
					report_message: note
				})
			}

			return Promise.all([
				this._advertService.forbidAdvert(this._advertId, reason, note),
				this._advertReportsService.advertReportsProcessed(this._advertId)
			])
		}
	}

	_handleAdvertFormSubmitSuccess() {
		const advertId = this._advertId

		this._successHandler().then(() => {
			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				title: this._utils.$Dictionary.get('ActionMessage.titleSuccess'),
				type: StatusMessage.TYPE.SUCCESS,
				text: this._utils.$Dictionary.get('ActionMessage.forbidSuccess', {
					COUNT: 1,
					IDS: advertId
				})
			})
		})

		this.onCloseAdvertPopup()
	}

	_handleAdvertFormSubmitError(error, formLineEntities, formData) {
		if (error && error.type && error.type === 'noteIsRequired') {
			return {
				formLineEntities: updateEntities(formLineEntities, [
					{
						id: 'note',
						errorMessage: 'Doplňte popis důvodu'
					}
				])
			}
		} else {
			return {
				entityFormError: {
					title: 'Inzerát se nepodařilo zakázat'
				}
			}
		}
	}
}
