
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['StatusLine'] = {
  fromDate: function(d) { return " od " + d.DATE; },
  tillDate: function(d) { return " do " + d.DATE; },
  active: function(d) { return "Aktivní"; },
  inactive: function(d) { return "Neaktivní"; },
  inactiveExpired: function(d) { return "Expirovaný"; },
  inactiveInsufficientImages: function(d) { return "Nesplňuje min. počet fotek"; },
  innactiveVinDuplication: function(d) { return "Duplicitní VIN"; },
  innactiveVinDuplicationFraud: function(d) { return "Opakované vkládání VIN"; },
  inactiveNotPaid: function(d) { return "Nezaplacený"; },
  inactiveInsufficientModules: function(d) { return "Překročen limit slotů"; },
  inactiveUserDeactivated: function(d) { return "Zneaktivněný uživatelem"; },
  inactiveAdminDeactivated: function(d) { return "Zneaktivněný administrátorem"; },
  inactiveAdminDeactivatedRejected: function(d) { return "Inzerát byl zneaktivněn administrátorem, důvody jsme zaslali na Váš email. Před zveřejněním jej opravte."; },
  disabled: function(d) { return "Zakázaný administrátorem"; },
  disabledRejected: function(d) { return "Inzerát byl zakázán administrátorem, důvody jsme zaslali na Váš email. Kontaktujte podporu."; },
  payAdvert: function(d) { return "Zaplatit inzerát"; },
  contactHelp: function(d) { return "Kontaktovat podporu"; },
  prolong: function(d) { return "Prodloužit"; },
  finish: function(d) { return "Dokončit"; },
  publish: function(d) { return "Zveřejnit"; },
  contactHelpSubject: function(d) { return "Potřebuji pomoct s inzerátem id: " + d.ID; },
  contactHelpVinDuplicationFraudSubject: function(d) { return "Opakované vkládání VIN u inzerátu id: " + d.ID; },
  preferredOffer: function(d) { return "Přednostní výpis"; },
  toppedAdvert: function(d) { return "Topováno"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	