export const EQUIPMENT_CB = 'equipment_cb'

const SAFETY = 'safety'
const ASSIST = 'assist'
const SECURITY = 'security'
const INTERIOR = 'interior'
const SYSTEMS = 'systems'
const SEATS = 'seats'
const LIGHTS = 'lights'
const EXTERIOR = 'exterior'
const DRIVE = 'drive'
const OTHER = 'other'

export const CATEGORIES = Object.freeze([
	SAFETY,
	ASSIST,
	SECURITY,
	INTERIOR,
	SYSTEMS,
	SEATS,
	LIGHTS,
	EXTERIOR,
	DRIVE,
	OTHER
])
