import React from 'react'
import { Input } from '@sznds/react'
import { Widget } from '@inzeraty/form-lines'
import PropTypes from 'prop-types'

import './BasicCompWidget.less'

export const BASIC_WIDGET_ID = 'basic_comp_widget'

const CLASSNAME = 'c-basic-comp-widget'

function BasicCompWidget({ className = '', ...restProps }) {
	return (
		<Widget
			className={`${CLASSNAME} ${className}`}
			renderWidget={(
				formLineEntity,
				renderLabel,
				renderInput,
				renderMessage,
				renderErrorMessage
			) => (
				<>
					<div className={`${CLASSNAME}__label-box`}>{renderLabel()}</div>
					<div className={`${CLASSNAME}__content`}>
						<div className={`${CLASSNAME}__input-wrapper`}>
							{renderInput()}
							{renderMessage()}
							{renderErrorMessage()}
						</div>
					</div>
				</>
			)}
			renderInput={(formLineEntity, { className, ...props }) => (
				<Input
					className={`${className} ${CLASSNAME}__input`}
					error={!!formLineEntity.errorMessage}
					{...props}
				/>
			)}
			renderLabel={(formLineEntity, { className, ...props }) => (
				<label className={`${className} ${CLASSNAME}__label`} {...props}>
					{formLineEntity.label}
					{formLineEntity.required && <span className={`${CLASSNAME}__required`}>*</span>}
				</label>
			)}
			{...restProps}
		/>
	)
}

BasicCompWidget.propTypes = {
	className: PropTypes.string
}

export default BasicCompWidget
