
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['AdvertList'] = {
  advertsCounter: function(d) { return plural(d.COUNT, 0, cs, { one: d.COUNT + " inzerát", few: d.COUNT + " inzeráty", other: d.COUNT + " inzerátů" }); },
  headerBrandsModels: function(d) { return "Vybraní výrobci a jejich modely"; },
  headerBrandModels: function(d) { return "Vybrané modely " + d.BRAND; },
  titleListCategoryOsobni: function(d) { return "Bazar a prodej | Sauto.cz"; },
  titleListCategoryNakladni: function(d) { return "Prodej nákladních vozů, bazar | Sauto.cz"; },
  titleListCategoryObytne: function(d) { return "Obytná auta a dodávky bazar a prodej | Sauto.cz"; },
  titleListCategoryPracovniStroje: function(d) { return "Pracovní stroje inzerce, bazar a prodej | Sauto.cz"; },
  titleListCategoryUzitkova: function(d) { return "Bazar a prodej užitkových vozů | Sauto.cz"; },
  titleListCategoryCtyrkolky: function(d) { return "Čtyřkolky prodej a bazar | Sauto.cz"; },
  titleListCategoryAutobusy: function(d) { return "Autobusy prodej | Sauto.cz"; },
  titleListCategoryPrivesyNavesy: function(d) { return "Přívěsy, návěsy a valníky prodej | Sauto.cz"; },
  titleListCategoryMotorky: function(d) { return "Motorky, motocykly bazar a prodej | Sauto.cz"; },
  descriptionListCategoryOsobni: function(d) { return "Bazar, ojetá auta i předváděcí a nové vozy. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej vozu."; },
  descriptionListCategoryUzitkova: function(d) { return "Bazar, ojeté užitkové vozy i předváděcí a nová auta. Mrkněte na Sauto.cz - online autobazar kde vyřešíte koupi i prodej užitkového auta."; },
  descriptionListCategoryNakladni: function(d) { return "Bazar, ojetá nákladní auta a tahače. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej náklaďáku auta."; },
  descriptionListCategoryMotorky: function(d) { return "Bazar, prodej a bazar motorek a motocyklů. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej motorek."; },
  descriptionListCategoryObytne: function(d) { return "Bazar, ojetá obytná auta a dodávky. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej obytného vozu."; },
  descriptionListCategoryPracovniStroje: function(d) { return "Bazar, použité pracovní stroje a zemědělská technika. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej pracovních strojů."; },
  descriptionListCategoryCtyrkolky: function(d) { return "Bazar, použité i nové čtyřkolky. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej čtyřkolky."; },
  descriptionListCategoryAutobusy: function(d) { return "Bazar, ojeté autobusy, midibusy a minibusy na prodej. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej mikrobusu."; },
  descriptionListCategoryPrivesyNavesy: function(d) { return "Bazar, použité i nové přívěsy, návěsy a valníky. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej valníku či návěsu."; },
  titleListSpecific: function(d) { return d.TEXT + " bazar a prodej nových vozů | Sauto.cz"; },
  descriptionListGeneric: function(d) { return d.TEXT + " na prodej – bazar, ojetá auta i předváděcí a nové. Mrkněte na Sauto.cz - online autobazar, kde vyřešíte koupi i prodej vozu, motorky i stroje."; },
  descriptionListSpecific: function(d) { return d.TEXT + " na prodej – bazar, ojetá auta i předváděcí a nové vozy. " + plural(d.COUNT, 0, cs, { one: d.COUNT + " inzerát", few: d.COUNT + " inzeráty", other: d.COUNT + " inzerátů" }) + " v aktuální nabídce. Mrkněte na Sauto.cz - online autobazar " + d.TEXT + ", kde vyřešíte koupi i prodej vozu, motorky i stroje."; },
  utilityCarsSeoTitle: function(d) { return "Užitkové vozy"; },
  trucksSeoTitle: function(d) { return "Nákladní vozy"; },
  campersSeoTitle: function(d) { return "Obytné vozy"; },
  rangeFrom: function(d) { return d.FILTER_NAME + " od " + d.FROM + " " + d.UNIT; },
  rangeTo: function(d) { return d.FILTER_NAME + " do " + d.TO + " " + d.UNIT; },
  rangeFromTo: function(d) { return d.FILTER_NAME + " od " + d.FROM + " do " + d.TO + " " + d.UNIT; },
  beds: function(d) { return plural(d.BEDS_COUNT, 0, cs, { one: "lůžka", other: "lůžek" }); },
  months: function(d) { return plural(d.MONTHS, 0, cs, { one: "měsíce", other: "měsíců" }); },
  noResultsLink: function(d) { return "Vrátit se o krok zpět"; },
  noResultsHP: function(d) { return "Přejít na hlavní stránku"; },
  noResultsDescription: function(d) { return "Zkuste změnit nastavení filtrů ve vašem hledání, případně můžete dostávat upozornění na nové inzeráty, pokud si toto hledání přidáte do uložených hledání."; },
  noResultsAddButton: function(d) { return "Přidat uložené hledání"; },
  showOlderAdverts: function(d) { return "Zobrazit starší"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	