
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AutoMotoArticles'] = {
  noArticles: function(d) { return "Nenašli jsme žádný článek"; },
  sourceSuffix: function(d) { return ".cz"; },
  all: function(d) { return "Všechny"; },
  news: function(d) { return "Novinky"; },
  tests: function(d) { return "Testy"; },
  tipsAndAdvices: function(d) { return "Tipy a rady"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	