import SortConstants, {
	MOST_RECENT as SORT_CONSTANT_MOST_RECENT
} from 'app/component/sortButton/SortConstants'

export default class ClientAdminSortConstants {
	static get defaultValue() {
		return 'MOST_RECENT'
	}

	static get sortValues() {
		// vyfiltrujeme vychozi razeni od nejnovejsiho
		const filteredSortValues = Object.fromEntries(
			Object.entries(SortConstants.sortValues).filter(([key]) => key !== SORT_CONSTANT_MOST_RECENT)
		)

		return Object.freeze({
			MOST_RECENT: {
				NAME: 'clientAdminMostRecent'
			},
			MOST_RECENT_ASC: {
				NAME: 'clientAdminMostRecentAscending',
				APP: 'od-nejstarsich',
				API: 'create-date'
			},
			...filteredSortValues
		})
	}
}
