import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import { Format } from '@inzeraty/helpers'
import { Surface, Button } from '@sznds/react'
import { VISIBILITY_FILLED } from '@sznds/icons'
import { SDN } from 'app/base/Constants'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import HomeDelivery from 'app/component/homeDelivery/HomeDelivery'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import Favorite from 'app/component/favorite/Favorite'
import CebiaReport from 'app/component/cebiaReport/CebiaReportView'

import './UserwebAdvertView.less'
import './UserwebAdvertCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-item'
const PLACEHOLDER = '--plh'

const LOOK_TYPE = {
	HORIZONTAL: 'HORIZONTAL',
	PREVIEW: 'PREVIEW'
}

const LOOK_TYPE_MODIFIER = {
	HORIZONTAL: '--hor',
	PREVIEW: '--preview'
}

const AdvertPicture = ({ webpUrl, jpegUrl, imageLazyload = false }) => {
	return (
		<picture>
			<source srcSet={webpUrl} type='image/webp' />
			<source srcSet={jpegUrl} type='image/jpeg' />
			<img
				className={`${CLASSNAME}__image`}
				src={jpegUrl}
				alt=''
				loading={imageLazyload ? 'lazy' : 'eager'}
			/>
		</picture>
	)
}

AdvertPicture.propTypes = {
	webpUrl: PropTypes.string,
	jpegUrl: PropTypes.string,
	imageLazyload: PropTypes.bool
}

/**
 * @class UserwebAdvertView
 * @namespace app.component.adverts
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.component
 */
export default class UserwebAdvertView extends AbstractComponent {
	static get propTypes() {
		return {
			isOperatingLease: PropTypes.bool,
			advertEntity: PropTypes.shape(advertPropTypes),
			index: PropTypes.number,
			lookType: PropTypes.string,
			className: PropTypes.string,
			isHybrid: PropTypes.bool,
			onHandle: PropTypes.func,
			imageLazyload: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			isOperatingLease: false,
			lookType: LOOK_TYPE.HORIZONTAL,
			className: '',
			isHybrid: false,
			imageLazyload: true
		}
	}

	static get getLookType() {
		return LOOK_TYPE
	}

	static getItemClassName(id) {
		return `${CLASSNAME}-${id}`
	}

	constructor(props, context) {
		super(props, context)

		// - aby mela dlazdice hover efekt, tak musi mit defionvan onClick nebo href
		this._tileHoverEffect = DEFAULT_PROPS.FUNCTION
		this._handleAdvertClick = this._handleAdvertClick.bind(this)
	}

	static getAdvertUrlParams(advertEntity = {}, isOperatingLease = false) {
		const { id, oldId, manufacturerCb = {}, modelCb = {}, category = {} } = advertEntity
		const urlId = oldId || id

		return [
			isOperatingLease
				? ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
				: ROUTE_NAMES.USERWEB.ADVERT_DETAIL,
			{
				[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: urlId,
				[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: category.seoName,
				[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: manufacturerCb.seoName,
				[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]: modelCb.seoName
			}
		]
	}

	render() {
		const { advertEntity = {}, index, lookType, className, isHybrid, isOperatingLease } = this.props
		const { id, oldId } = advertEntity
		const urlId = oldId || id

		const advertUrl = this.link(
			...UserwebAdvertView.getAdvertUrlParams(advertEntity, isOperatingLease)
		)

		const Wrapper = (props) =>
			lookType === LOOK_TYPE.PREVIEW ? <div {...props} /> : <li {...props} />

		const dataDotProps =
			lookType !== LOOK_TYPE.PREVIEW
				? {
						'data-dot': 'advert-item',
						'data-dot-data': `{"order": "${index + 1}"}`
				  }
				: {}

		const surfaceProps = {
			tagName: 'div',
			surface: 5,
			className: `${CLASSNAME}__container`
		}

		if (lookType !== LOOK_TYPE.PREVIEW) {
			surfaceProps.onClick = this._tileHoverEffect
		}

		return (
			<Wrapper
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: true,
					[`${CLASSNAME}${LOOK_TYPE_MODIFIER[lookType]}`]: true,
					[UserwebAdvertView.getItemClassName(urlId)]: lookType !== LOOK_TYPE.PREVIEW,
					[`${CLASSNAME}--hybrid`]: isHybrid
				})}
			>
				<Surface {...surfaceProps}>
					<div className={`${CLASSNAME}__content`} {...dataDotProps}>
						<div className={`${CLASSNAME}__data-wrap`}>
							{this._renderAdvertLink(advertUrl)}
							<div className={`${CLASSNAME}__info-wrap`}>
								{this._renderName(true)}
								{this._renderInfo()}
							</div>
							<div className={`${CLASSNAME}__data`}>
								{this._renderPrice()}
								{this._renderSellerAndLocality()}
							</div>
						</div>
						{this._renderImages()}
					</div>
				</Surface>
			</Wrapper>
		)
	}

	_renderAdvertLink(advertUrl) {
		const { lookType, onHandle } = this.props

		const surfaceProps = {
			tagName: 'a',
			surface: 0,
			className: `${CLASSNAME}__link`,
			href: advertUrl,
			onClick: this._handleAdvertClick
		}

		if (this._isPlaceholder() || lookType === LOOK_TYPE.PREVIEW) {
			surfaceProps.clickable = false
			surfaceProps.tagName = 'span'
			delete surfaceProps.href
			delete surfaceProps.onClick
		} else if (onHandle) {
			// hitneme ked uzivatel bud klikne na inzerat, alebo ho otvori v novom tabe
			surfaceProps.onMouseDown = () => {
				onHandle()
			}
			// hitneme ak uzivatel otvori inzerat pomocou klavesnice
			surfaceProps.onKeyDown = (event) => {
				if (event.which == 13 || event.keyCode == 13) {
					onHandle()
				}
			}
		}

		return <Surface {...surfaceProps}>{this._renderName()}</Surface>
	}

	_handleAdvertClick() {
		const { advertEntity = {} } = this.props
		const { id, oldId } = advertEntity
		const advertId = oldId || id

		this.fire('advertClick', advertId)
	}

	_renderName(hide = false) {
		const { advertEntity = {}, lookType } = this.props
		const { namePrefix, nameSuffix } = advertEntity

		const dataDotProps =
			lookType !== LOOK_TYPE.PREVIEW
				? {
						'data-dot': 'link'
				  }
				: {}

		return (
			<span
				className={this.cssClasses({
					[`${CLASSNAME}__name`]: true,
					[`${CLASSNAME}__name--hide`]: hide,
					[`${CLASSNAME}__name${PLACEHOLDER}`]: this._isPlaceholder()
				})}
				{...dataDotProps}
			>
				{namePrefix}
				{nameSuffix && <span className={`${CLASSNAME}__name--suffix`}>{nameSuffix}</span>}
			</span>
		)
	}

	_renderInfo() {
		const { advertEntity = {} } = this.props
		const { manufacturingDate, inOperationDate, tachometer } = advertEntity

		const manufacturYear = manufacturingDate ? new Date(manufacturingDate).getFullYear() : null
		const inOperationYear = inOperationDate ? inOperationDate.getFullYear() : null
		// primarne zobrazujeme manufactur year
		const resultManufacturYear = manufacturYear ?? inOperationYear

		let info = []

		if (resultManufacturYear) {
			info.push(resultManufacturYear)
		}

		if (tachometer >= 0) {
			info.push(`${Format.number(tachometer)} ${this.localize('Base.KM')}`)
		}

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__info`]: true,
					[`${CLASSNAME}__info${PLACEHOLDER}`]: this._isPlaceholder()
				})}
			>
				{info.join(', ')}
				{this._renderInfoAdditionForMobileMedium()}
				{this._renderInfoAdditionForMobileWide()}
			</div>
		)
	}

	_renderInfoAdditionForMobileMedium() {
		const { advertEntity = {} } = this.props
		const { fuelCb = {} } = advertEntity
		const { name = '' } = fuelCb

		if (name) {
			return <span className={`${CLASSNAME}__info-mobile-medium`}>{`, ${name}`}</span>
		}
	}

	_renderInfoAdditionForMobileWide() {
		const { advertEntity = {} } = this.props
		const { gearboxCb = {} } = advertEntity
		const { name = '' } = gearboxCb

		if (name) {
			return <span className={`${CLASSNAME}__info-mobile-wide`}>{`, ${name}`}</span>
		}
	}

	_renderPrice() {
		const { advertEntity = {}, isOperatingLease } = this.props
		const { price, operatingLeasePriceWithoutVat } = advertEntity

		return (
			<div
				className={this.cssClasses({
					notranslate: true,
					[`${CLASSNAME}__price`]: true,
					[`${CLASSNAME}__price${PLACEHOLDER}`]: this._isPlaceholder()
				})}
				translate='no'
				lang='cs-cz'
			>
				{isOperatingLease
					? operatingLeasePriceWithoutVat > 0 &&
					  this.localize('UserwebAdvert.operatingLeasePricePerMonth', {
							PRICE: Format.number(operatingLeasePriceWithoutVat),
							CURRENCY: this.localize('Base.CZK')
					  })
					: price > 0 && `${Format.number(price)} ${this.localize('Base.CZK')}`}
			</div>
		)
	}

	_renderImages() {
		const { advertEntity = {} } = this.props
		const { homeDelivery, isCebiaSmartCodeUrlVerified } = advertEntity

		return (
			<div className={`${CLASSNAME}__images`}>
				{homeDelivery && <HomeDelivery />}
				{isCebiaSmartCodeUrlVerified && <CebiaReport className={`${CLASSNAME}__cebia-box`} />}
				{this._renderMainImage()}
			</div>
		)
	}

	_renderMainImage() {
		const { advertEntity = {}, lookType, imageLazyload } = this.props
		const { images } = advertEntity

		const imagesTotalCount =
			lookType === LOOK_TYPE.PREVIEW ? images && images.length : advertEntity.imagesTotalCount
		const isPlaceholder = this._isPlaceholder()
		const mainImgUrlJpeg = imagesTotalCount && images[0].url ? images[0].url + SDN.w360 : ''
		const mainImgUrlWebp = imagesTotalCount && images[0].url ? images[0].url + SDN.w360_WEBP : ''
		const hasImages = imagesTotalCount > 0

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__image-wrap`]: true,
					[`${CLASSNAME}__image-wrap--no-image`]: !hasImages && !isPlaceholder,
					[`${CLASSNAME}__image-wrap${PLACEHOLDER}`]: isPlaceholder
				})}
			>
				{!isPlaceholder && lookType !== LOOK_TYPE.PREVIEW && (
					<Favorite id={advertEntity.id} className={`${CLASSNAME}__favorite-icon`} />
				)}
				{hasImages && (
					<AdvertPicture
						webpUrl={mainImgUrlWebp}
						jpegUrl={mainImgUrlJpeg}
						imageLazyload={imageLazyload}
					/>
				)}
				{!hasImages && !isPlaceholder && (
					<SvgIcon icon='NO_IMAGE' className={`${CLASSNAME}__image`} />
				)}
				{lookType === LOOK_TYPE.PREVIEW && this._renderMainImagePreviewOverlay()}
			</div>
		)
	}

	_renderMainImagePreviewOverlay() {
		const { advertEntity, isOperatingLease } = this.props

		const [routeName, routeParams] = UserwebAdvertView.getAdvertUrlParams(
			advertEntity,
			isOperatingLease
		)

		return (
			<div className={`${CLASSNAME}__preview-box`}>
				<Button
					text={this.localize('UserwebAdvert.showPreview')}
					icon={VISIBILITY_FILLED}
					disabled={!advertEntity}
					href={this.link(
						routeName,
						Object.assign({}, routeParams, {
							[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.PREVIEW]:
								AdvertDetailUrlConvertor.constants.URL_APP_PARAMS_VALUES.PREVIEW
						})
					)}
				/>
			</div>
		)
	}

	_renderSellerAndLocality() {
		const { advertEntity = {} } = this.props
		const { premise, locality: { district } = {} } = advertEntity
		const sellerName = premise ? premise.name : this.localize('UserwebAdvert.privateSeller')

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__seller-locality`]: true,
					[`${CLASSNAME}__seller-locality${PLACEHOLDER}`]: this._isPlaceholder()
				})}
			>
				<div className={`${CLASSNAME}__seller`}>{sellerName}</div>
				<div className={`${CLASSNAME}__locality`}>{district}</div>
			</div>
		)
	}

	/**
	 * Proveri zda se jedna o placeholader
	 *
	 * @method _isPlaceholder
	 * @private
	 * @return {Boolean}
	 */
	_isPlaceholder() {
		const { isImg, isName, isPrice, isTachometer, isYear } = this._getPlaceholders()
		return isImg && isName && isPrice && isTachometer && isYear
	}

	/**
	 * Proveri zda se maji misto dat zbrazit placeholdery
	 *
	 * @method _getPlaceholders
	 * @private
	 * @return {Object}
	 */
	_getPlaceholders() {
		const { advertEntity = {}, isOperatingLease } = this.props
		const {
			images,
			price,
			operatingLeasePriceWithoutVat,
			tachometer,
			manufacturingDate,
			inOperationDate,
			name
		} = advertEntity

		return {
			isImg: !(images && images.length >= 0),
			isName: !name,
			isPrice: isOperatingLease
				? !(operatingLeasePriceWithoutVat || operatingLeasePriceWithoutVat === 0)
				: !(price || price === 0),
			isTachometer: !(tachometer || tachometer === 0),
			isYear: !(manufacturingDate || inOperationDate)
		}
	}
}
