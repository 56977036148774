
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertHistoryRow'] = {
  approval_admin: function(d) { return "Stav schvaleni"; },
  category_id: function(d) { return "ID kategorie"; },
  create_date: function(d) { return "Datum vytvoření"; },
  custom_id: function(d) { return "Vlastní ID inzerátu"; },
  deactivation_date: function(d) { return "Datum deaktivace"; },
  deactivation_reason: function(d) { return "Důvod deaktivace"; },
  delete_date: function(d) { return "Datum smazání"; },
  description: function(d) { return "Poznámka o stavu vozidla"; },
  dont_show_phone: function(d) { return "Neukazovat tel. číslo"; },
  email: function(d) { return "Email"; },
  name: function(d) { return "Název inzerátu"; },
  old_id: function(d) { return "Staré ID inzerátu"; },
  phone: function(d) { return "Telefon"; },
  premise_id: function(d) { return "ID firmy"; },
  price: function(d) { return "Cena"; },
  sold: function(d) { return "Inzerát prodán"; },
  sorting_date: function(d) { return "Datum pro řazení na výpise"; },
  source: function(d) { return "Zdroj"; },
  status: function(d) { return "Status"; },
  status_draft: function(d) { return "Status rozpracovaný"; },
  status_active: function(d) { return "Status aktivní"; },
  status_deleted: function(d) { return "Status smazaný"; },
  status_disabled: function(d) { return "Status zakázaný"; },
  topped: function(d) { return "Topován"; },
  user_id: function(d) { return "ID uživatele"; },
  valid_to: function(d) { return "Validní do"; },
  data__additional_model_name: function(d) { return "Titulek inzerátu"; },
  data__adjustments_for_handicapped: function(d) { return "Úpravy pro zdravotně postižené"; },
  data__airbags: function(d) { return "Počet airbagů"; },
  data__aircondition_cb: function(d) { return "Klimatizace"; },
  data__average_gas_mileage: function(d) { return "Průměrná spotřeba"; },
  data__beds: function(d) { return "Počet lůžek"; },
  data__capacity: function(d) { return "Počet míst"; },
  data__cebia_report: function(d) { return "CEBIA číslo osvedčení"; },
  data__cebia_report_pdf_attachment: function(d) { return "CEBIA odkaz na certifikát"; },
  data__cebia_report_rating: function(d) { return "CEBIA hodnocení"; },
  data__cebia_report_status: function(d) { return "CEBIA status"; },
  data__certification_cb: function(d) { return "Ověřovací program"; },
  data__color_cb: function(d) { return "Barva"; },
  data__color_type_cb: function(d) { return "Úprava barvy"; },
  data__color_tone_cb: function(d) { return "Odstín barvy"; },
  data__condition_cb: function(d) { return "Stav vozidla"; },
  data__country_of_origin_cb: function(d) { return "Země původu"; },
  data__crashed_in_past: function(d) { return "Havarováno v minulosti"; },
  data__doors: function(d) { return "Počet dveří"; },
  data__drive_cb: function(d) { return "Pohon"; },
  data__engine_power: function(d) { return "Výkon"; },
  data__engine_volume: function(d) { return "Objem motoru"; },
  data__environmental_tax_paid: function(d) { return "Zaplacená ekologická daň"; },
  data__equipment_cb: function(d) { return "Výbava"; },
  data__euro_level_cb: function(d) { return "EURO emisní norma"; },
  data__first_owner: function(d) { return "První majitel"; },
  data__fuel_cb: function(d) { return "Palivo"; },
  data__gearbox_cb: function(d) { return "Typ převodovky"; },
  data__gearbox_levels_cb: function(d) { return "Počet rychlostí"; },
  data__guarantee_date: function(d) { return "Platnost záruky"; },
  data__in_operation_date: function(d) { return "V provozu od"; },
  data__load_capacity: function(d) { return "Nosnost"; },
  data__machine_hours: function(d) { return "Motohodiny"; },
  data__manufacturer_cb: function(d) { return "Výrobce"; },
  data__manufacturing_date: function(d) { return "Rok výroby"; },
  data__model_cb: function(d) { return "Model"; },
  data__out_of_order_date: function(d) { return "Mimo provoz od"; },
  data__seats_cb: function(d) { return "Počet míst"; },
  data__service_book: function(d) { return "Servisní knížka"; },
  data__stk_date: function(d) { return "Platnost STK"; },
  data__tachometer: function(d) { return "Nájezd"; },
  data__tuning: function(d) { return "Tuning"; },
  data__vehicle_body_cb: function(d) { return "Typ karosérie"; },
  data__vin: function(d) { return "VIN"; },
  data__weight: function(d) { return "Hmotnost"; },
  price_data__price_leasing: function(d) { return "Cena při využití leasingu"; },
  price_data__price_note: function(d) { return "Poznámka k ceně"; },
  price_data__price_payment: function(d) { return "Výše splátky"; },
  price_data__price_payment_count: function(d) { return "Počet splátek"; },
  price_data__price_original_compensation: function(d) { return "Odstupné"; },
  price_data__price_is_vat_deductible: function(d) { return "Možnost odpočtu DPH"; },
  price_data__price_original: function(d) { return "Zadaná cena"; },
  price_data__price_without_vat: function(d) { return "Cena bez DPH"; },
  price_data__price_is_without_vat: function(d) { return "Cena je bez DPH"; },
  locality__gps_lat: function(d) { return "Lokalita - GPS zeměpisná šířka"; },
  locality__gps_lon: function(d) { return "Lokalita - GPS zeměpisná délka"; },
  locality__address: function(d) { return "Lokalita - adresa"; },
  locality__address_id: function(d) { return "Lokalita - ID adresy"; },
  locality__municipality: function(d) { return "Lokalita - obec"; },
  locality__municipality_id: function(d) { return "Lokalita - ID obce"; },
  locality__municipality_seo_name: function(d) { return "Lokalita - SEO obce"; },
  locality__street: function(d) { return "Lokalita - ulice"; },
  locality__street_id: function(d) { return "Lokalita - ID ulice"; },
  locality__streetnumber: function(d) { return "Lokalita - číslo orientační"; },
  locality__housenumber: function(d) { return "Lokalita - číslo popisné"; },
  locality__ward: function(d) { return "Lokalita - obvod"; },
  locality__ward_id: function(d) { return "Lokalita - ID obvodu"; },
  locality__quarter: function(d) { return "Lokalita - čtvrť"; },
  locality__quarter_id: function(d) { return "Lokalita - ID čtvrti"; },
  locality__district: function(d) { return "Lokalita - okres"; },
  locality__district_id: function(d) { return "Lokalita - ID okresu"; },
  locality__district_seo_name: function(d) { return "Lokalita - SEO okresu"; },
  locality__region: function(d) { return "Lokalita - kraj"; },
  locality__region_id: function(d) { return "Lokalita - ID kraje"; },
  locality__region_seo_name: function(d) { return "Lokalita - SEO kraje"; },
  locality__entity_id: function(d) { return "Lokalita - ID entity"; },
  locality__entity_type: function(d) { return "Lokalita - typ entity"; },
  locality__seo_name: function(d) { return "Lokalita - SEO jméno"; },
  locality__country_id: function(d) { return "Lokalita - ID země"; },
  locality__citypart: function(d) { return "Lokalita - městská část"; },
  locality__zip: function(d) { return "Lokalita - PSČ"; },
  locality__source: function(d) { return "Lokalita - zdroj"; },
  attachment_created: function(d) { return "Příloha vytvořena"; },
  attachment_deleted: function(d) { return "Příloha smazána"; },
  photo_created: function(d) { return "Fotografie přidána"; },
  photo_recycled: function(d) { return "Fotografie recyklována"; },
  images_order_changed: function(d) { return "Pořadí fotek"; },
  photo_updated: function(d) { return "Fotografie změněná"; },
  photo_deleted: function(d) { return "Fotografie smazána"; },
  video_created: function(d) { return "Video přidáno"; },
  video_update: function(d) { return "Video změněné"; },
  video_deleted: function(d) { return "Video smazáno"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	