import React from 'react'
import PropTypes from 'prop-types'

import './ExportButton.less'

const CLASSNAME = 'c-ia-export-button'

const ExportButton = ({ onClick = () => {}, buttonText = '' }) => {
	return (
		<div className={`${CLASSNAME}__export-wrap`}>
			<button className={`${CLASSNAME}__export`} onClick={onClick}>
				<img src='/static/img/share.svg' alt='' className={`${CLASSNAME}__export-ico`} />
				<span className={`${CLASSNAME}__export-txt`}>{buttonText}</span>
			</button>
		</div>
	)
}

ExportButton.propTypes = {
	onClick: PropTypes.func,
	buttonText: PropTypes.string
}

export default ExportButton
