import React, { forwardRef } from 'react'
import classnames from 'classnames'
import { Surface } from '@sznds/react'
import PropTypes from 'prop-types'

import './ActionElement.less'

const CLASSNAME = 'c-action-elm'

const ActionElement = forwardRef(({ className, ...elmProps }, ref) => {
	const { tagName, href, onClick } = elmProps
	if (!tagName) {
		let tagName = 'span'
		if (href) {
			tagName = 'a'
		} else if (onClick) {
			tagName = 'button'
		}
		Object.assign(elmProps, { tagName })
	}
	return (
		<Surface
			surface={0}
			className={classnames({
				[className]: !!className,
				[CLASSNAME]: true
			})}
			ref={ref}
			{...elmProps}
		/>
	)
})

ActionElement.propTypes = {
	className: PropTypes.string
}

export default ActionElement
