/**
 * Stavy registraci.
 */
const RegistrationStatus = Object.freeze({
	RESERVATION: 'reservation', // rezervace
	ORDER: 'order', // objednano
	PAID: 'paid', // zaplaceno
	RUNNING: 'running', // bezi
	PAUSED: 'paused', // pozastaveno
	FINISHED: 'finished', // uspesne ukonceno
	CANCELED: 'canceled' // zruseno
})

export default RegistrationStatus
