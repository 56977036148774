import React, { useContext } from 'react'
import Context from 'ima/page/context'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import PropTypes from 'prop-types'
import { PaginationEntity, UserPropTypes } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import ActionButton from 'app/component/actionButton/ActionButton'
import { EDIT_FILLED, EXTERNAL_LINK } from '@sznds/icons'
import ROUTE_NAMES from 'app/base/RouteNames'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import UserService from 'app/model/user/UserService'

export default function IAUserListView({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading
}) {
	const { $Router } = useContext(Context)
	const { rights = [] } = useContext(AdminEntityContext) || {}

	const getActions = () => {
		let actions = []

		if (rights.includes(ADMIN_RIGHTS.USERS_EDIT)) {
			actions = actions.concat([
				({ id }, actionButtonProps = {}) => (
					<ActionButton
						icon={EDIT_FILLED}
						key='editovat'
						title='Editovat'
						href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.USER_DETAIL, {
							[InternalAdminUrlConvertor.constants.USER_EDIT_ID]: id
						})}
						{...actionButtonProps}
					/>
				)
			])
		}

		if (rights.includes(ADMIN_RIGHTS.ADVERTS_VIEW)) {
			actions = actions.concat([
				({ id }, actionButtonProps = {}) => (
					<ActionButton
						icon={EXTERNAL_LINK}
						key='inzeraty'
						title='Inzeráty'
						href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
							[InternalAdminUrlConvertor.constants.FILTER_BY]:
								InternalAdminUrlConvertor.constants.USER_ID,
							[InternalAdminUrlConvertor.constants.FILTER_BY_VALUE]: id
						})}
						{...actionButtonProps}
					/>
				)
			])
		}

		return actions
	}

	return (
		<Layout title='Výpis uživatelů'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='id' sortParam='id' isDefaultAscending={true} alignRight={true}>
						RUS ID
					</TableHeadSorter>,
					<TableHeadSorter key='login' sortParam='login'>
						Login
					</TableHeadSorter>,
					<TableHeadSorter key='enabled' sortParam='enabled'>
						Stav
					</TableHeadSorter>
				]}
				rowCells={[
					({ id }) => [id, { isNumber: true }],
					({ login }) => login,
					(entity) => {
						const sautoService = UserService.getSautoServiceData(entity)
						const state = entity.enabled && sautoService?.serviceEnabled ? 'Povolený' : 'Zakázaný'
						return state
					}
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAUserListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.shape(UserPropTypes)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool
}
