import React, { useState } from 'react'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import { Surface } from '@sznds/react'
import PaymentType from './paymentType/PaymentType'
import PublicationLength from './publicationLength/PublicationLength'
import select from 'ima-plugin-select'
import STATE_KEYS from '../PaymentStateKeys'
import { StatusMessage } from '@inzeraty/components'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import PropTypes from 'prop-types'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import getAdvertState, { ADVERT_STATE } from 'app/page/userweb/clientAdvertList/getAdvertState'
import { Format } from '@inzeraty/helpers'

import './PaymentInfo.less'
import './PaymentInfoCS.json'

const CLASSNAME = 'c-payment-info'

const PaymentErrorStatusMessage = ({ paymentType, errorMessage }) => {
	const localize = useLocalize()
	const fire = useFire()

	if (errorMessage) {
		let errorHeader = ''

		if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_CREDIT_CARD) {
			errorHeader = localize('PaymentInfo.cardPaymentErrorHeader')
		} else if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET) {
			errorHeader = localize('PaymentInfo.walletPaymentErrorHeader')
		} else if (paymentType === PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_SMS) {
			errorHeader = localize('PaymentInfo.smsPaymentErrorHeader')
		}

		const text =
			errorMessage === PAYMENTS_CONSTANTS.ERROR.VIN_DUPLICATION
				? localize('PaymentInfo.vinDuplicationError')
				: localize(`PaymentInfo.${errorMessage}`)

		return (
			<StatusMessage
				type={StatusMessage.TYPE.ERROR}
				title={errorHeader}
				className={`${CLASSNAME}__status-message`}
				onClose={() => {
					fire('errorClose')
				}}
			>
				{text}
			</StatusMessage>
		)
	} else {
		return null
	}
}

PaymentErrorStatusMessage.propTypes = {
	errorMessage: PropTypes.string,
	paymentType: PropTypes.string
}

const StatusInfo = ({ advertEntity }) => {
	const localize = useLocalize()

	const { validToDate } = advertEntity
	const status = getAdvertState(advertEntity, false)

	const [isActive, setIsActive] = useState(status === ADVERT_STATE.ACTIVE)
	const [isExpired, setIsExpired] = useState(status === ADVERT_STATE.EXPIRED)

	return (
		<>
			{isActive && (
				<StatusMessage
					className={`${CLASSNAME}__status-message`}
					type={StatusMessage.TYPE.SUCCESS}
					title={localize('PaymentInfo.advertProlong', { DATE: Format.date(validToDate) })}
					onClose={() => {
						setIsActive(false)
					}}
				/>
			)}
			{isExpired && (
				<StatusMessage
					className={`${CLASSNAME}__status-message`}
					type={StatusMessage.TYPE.WARNING}
					title={localize('PaymentInfo.advertExpired')}
					onClose={() => {
						setIsExpired(false)
					}}
				/>
			)}
		</>
	)
}

StatusInfo.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes)
}

const PaymentInfo = ({
	errorMessage,
	paymentType,
	advertEntity,
	isLoading,
	isForTopped = false
}) => {
	const localize = useLocalize()

	return (
		<Surface surface={5} className={CLASSNAME}>
			<div className={`${CLASSNAME}__header`}>{localize('PaymentInfo.header')}</div>
			<div>
				<PaymentErrorStatusMessage errorMessage={errorMessage} paymentType={paymentType} />
				{!isLoading && advertEntity && !isForTopped && <StatusInfo advertEntity={advertEntity} />}
			</div>
			<PublicationLength />
			<PaymentType />
		</Surface>
	)
}

const paymentSelector = (state) => ({
	errorMessage: state[STATE_KEYS.ERROR_MESSAGE],
	paymentType: state[STATE_KEYS.PAYMENT_TYPE],
	advertEntity: state[STATE_KEYS.ADVERT_ENTITY],
	isLoading: state[STATE_KEYS.LOADING]
})

PaymentInfo.propTypes = {
	errorMessage: PropTypes.string,
	paymentType: PropTypes.string,
	advertEntity: PropTypes.shape(advertPropTypes),
	isLoading: PropTypes.bool,
	isForTopped: PropTypes.bool
}

export default select(paymentSelector)(PaymentInfo)
