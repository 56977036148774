import { DateHelpers } from '@inzeraty/helpers'
import { FORM_LINES_IDS } from '../BasicInfoFormLineIds'

// mapovani dat z Cebie na formlines - kazdy prvke v poli obsahuje
// identifikator prislusne formline a funkci 'processData', ktera
// zpracuje data z Cebie pro jednu danou formline
export default [
	{
		formLineId: FORM_LINES_IDS.MANUFACTURER_CB,
		processData: (formLineEntities = [], cebiaData = {}) => {
			const { manufacturerCb: brandValue } = cebiaData
			const { options: allBrands = [] } =
				formLineEntities.find((f) => f.id === FORM_LINES_IDS.MANUFACTURER_CB) || {}

			const brand = allBrands.find((b) => b.value === brandValue)

			return brand
				? {
						value: brandValue,
						name: brand.name
				  }
				: undefined
		}
	},
	{
		formLineId: FORM_LINES_IDS.MODEL_CB,
		processData: (formLineEntities = [], cebiaData = {}) => {
			const { modelCb: modelValue } = cebiaData
			const { options: allModels = [] } =
				formLineEntities.find((f) => f.id === FORM_LINES_IDS.MODEL_CB) || {}

			const model = allModels.find((b) => b.value === modelValue)

			return model
				? {
						value: modelValue,
						name: model.name
				  }
				: undefined
		}
	},
	{
		formLineId: FORM_LINES_IDS.MANUFACTURING_DATE,
		processData: (formLineEntities = [], cebiaData = {}) => {
			const parsedDate = DateHelpers.parseDate(cebiaData.manufacturingDate)

			return parsedDate ? [{ value: parsedDate.month }, parsedDate.year] : undefined
		}
	},
	{
		formLineId: FORM_LINES_IDS.VEHICLE_BODY_CB,
		processData: (formLineEntities = [], cebiaData = {}) => processCodebook(cebiaData.vehicleBodyCb)
	},
	{
		formLineId: FORM_LINES_IDS.COLOR_AND_TONE_CB,
		processData: (formLineEntities = [], cebiaData = {}) => {
			const { colorCb } = cebiaData

			return colorCb ? [{ value: cebiaData.colorCb }] : undefined
		}
	},
	{
		formLineId: FORM_LINES_IDS.ENGINE_VOLUME,
		processData: (formLineEntities = [], cebiaData = {}) => processNumber(cebiaData.engineVolume)
	},
	{
		formLineId: FORM_LINES_IDS.ENGINE_POWER,
		processData: (formLineEntities = [], cebiaData = {}) => processNumber(cebiaData.enginePower)
	},
	{
		formLineId: FORM_LINES_IDS.FUEL_CB,
		processData: (formLineEntities = [], cebiaData = {}) => processCodebook(cebiaData.fuelCb)
	},
	{
		formLineId: FORM_LINES_IDS.COUNTRY_OF_ORIGIN_CB,
		processData: (formLineEntities = [], cebiaData = {}) =>
			processCodebook(cebiaData.countryOfOriginCb)
	},
	{
		formLineId: FORM_LINES_IDS.TACHOMETER,
		processData: (formLineEntities = [], cebiaData = {}) => processNumber(cebiaData.tachometer)
	},
	{
		formLineId: FORM_LINES_IDS.MACHINE_HOURS,
		processData: (formLineEntities = [], cebiaData = {}) => processNumber(cebiaData.machineHours)
	}
]

const processNumber = (num) => (num !== null && num !== undefined ? num : undefined)

const processCodebook = (cb) => (cb ? { value: cb } : undefined)
