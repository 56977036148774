import { FORM_LINES_IDS as CA_FORM_LINES_IDS } from 'app/page/userweb/clientProfile/ClientProfileFormLineIds'

export const FORM_LINES_IDS = {
	PREMISE_ID: 'premise_id',
	EXTERNAL_ID: 'external_id',
	LOGIN_EMAIL: CA_FORM_LINES_IDS.LOGIN_EMAIL, // chceme prepsat vlastnim widgetem v IA
	RETARGETING_ID: 'retargeting_id',
	ADVERT_COUNT: 'advert_count',
	IS_ACTIVE: 'is_active',
	PRODUCT_LIST: 'product_list',
	IMPORT_LOGIN: 'import_login',
	IMPORT_PASSWORD: 'import_password',
	SOFTWARE_KEYS: 'software_keys',
	WALLET_ACOUNT: CA_FORM_LINES_IDS.WALLET_ACOUNT, // chceme prepsat vlastnim widgetem v IA
	WALLET_INFO: CA_FORM_LINES_IDS.WALLET_INFO, // chceme prepsat vlastnim widgetem v IA,
	HIDE_VIN: 'hide_vin',
	HOME_DELIVERY: 'home_delivery',
	USE_ADVERT_LOCALITY: 'use_advert_locality'
}
