import React from 'react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import Badge from 'app/component/badge/Badge'
import PropTypes from 'prop-types'

import './Opener.less'
import './OpenerCS.json'

const CLASSNAME = 'c-client-opener'

const Opener = (props) => {
	const { count, onClick = DEFAULT_PROPS.FUNCTION } = props

	const localize = useLocalize()

	return (
		<button data-dot='open-filter' className={CLASSNAME} onClick={onClick}>
			{localize('Opener.filter')}
			{count > 0 && <Badge className={`${CLASSNAME}__count`}>{count}</Badge>}
		</button>
	)
}

Opener.propTypes = {
	count: PropTypes.number,
	onClick: PropTypes.func
}

export default React.memo(Opener)
