import React from 'react'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import { updateEntities } from '@inzeraty/form-lines'
import { HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import { STATUS_CONSTANTS } from 'app/model/advert/AdvertConstants'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { FILTERS } from 'app/component/entitiesFilter/EntitiesFilterExtension'
import UserService from 'app/model/user/UserService'
import { USER_HISTORY_BLOCKING_ACTIONS } from 'app/helpers/internalAdmin/InternalAdminHelper'

export default class IAUserEditController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, userService, entityFormExtension) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._router = this._utils.$Router
		this._userService = userService
		this._entityFormExtension = entityFormExtension

		this._enabledservicePrefix = 'enabled-service-'
	}

	init() {
		super.init()

		this.addExtension(this._entityFormExtension)

		this._entityFormExtension.initialize({
			getSubmitPromise: this._getEditFormSubmitPromise.bind(this),
			validations: this._editFormValidations.bind(this),
			handleSubmitSuccess: this._handleEditFormSubmitSuccess.bind(this),
			handleSubmitError: this._handleEditFormSubmitError.bind(this)
		})
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false,
			isWaiting: false,
			entityFormError: null
		})
	}

	load() {
		const userEntity = this.getEntity()

		return super.load({
			userEntity,
			entitiesAndPagination: this._getEntitiesAndPagination(),
			isLoading: true,
			tableIsLoading: false,
			[AbstractInternalAdminController.stateId.GO_BACK_LIST_URL]: this._getBackUrl(
				ROUTE_NAMES.INTERNAL_ADMIN.USER_LIST
			),
			formLineEntities: userEntity.then((user) =>
				this._getFormLineEntitiesForEditForm(user).then((formLineEntities) => formLineEntities)
			)
		})
	}

	update(prevParams = {}) {
		const actualUrlParams = this.getRouteParams()
		let updateParams = {}
		const entitiesAndPagination = this._getEntitiesAndPagination()

		this._pageLoaderExtension.show()
		Promise.all([entitiesAndPagination]).finally(() => {
			this._pageLoaderExtension.hide()
		})

		const { LIMIT, PAGE } = InternalAdminUrlConvertor.constants
		if (
			prevParams[LIMIT] !== actualUrlParams[LIMIT] ||
			prevParams[PAGE] !== actualUrlParams[PAGE]
		) {
			updateParams = {
				...updateParams,
				tableIsLoading: true,
				entitiesAndPagination
			}
		}

		return updateParams
	}

	_getEditFormSubmitPromise(formData) {
		const { userEntity } = this.getState()
		const { id: userId } = userEntity

		const finalData = Object.assign({}, formData)
		delete finalData.items
		delete finalData.itemsActive

		finalData.email_values = formData.emails.map(({ email }) => email)
		finalData.emails = formData.emails.map(({ email, isDeleted }) => ({
			value: email,
			is_deleted: isDeleted
		}))

		finalData.phone_values = formData.phones.map(({ phone }) => phone)
		finalData.phones = formData.phones.map(({ phone, isDeleted }) => ({
			value: phone,
			is_deleted: isDeleted
		}))

		finalData.action_note = formData.action_note || ''
		finalData.note = formData.note || ''

		return this._userService.saveUser(userId, finalData)
	}

	_editFormValidations() {
		return {
			emails: ({ value: emails = [], required }) => {
				const verifiedContacts = emails.filter(({ email, verified }) => !!email && verified)
				return verifiedContacts.length === 0 && required
					? 'Musí být vyplněn alespoň jeden ověřený email'
					: ''
			},
			phones: ({ value: phones = [], required }) => {
				const verifiedContacts = phones.map(({ phone, verified }) => !!phone && verified)
				return verifiedContacts.length === 0 && required
					? 'Musí být vyplněn alespoň jeden ověřený telefon'
					: ''
			}
		}
	}

	_handleEditFormSubmitSuccess() {
		const {
			[AbstractInternalAdminController.stateId.GO_BACK_LIST_URL]: backUrl = ''
		} = this.getState()
		if (backUrl) {
			this._router.redirect(backUrl)
		}
	}

	_getformLineEntitiesWithoutErrorMessages(formLineEntities) {
		const updates = []

		formLineEntities.forEach(({ id, value }) => {
			if (id === 'emails' || id === 'phones') {
				const newValue = value.map((valueData) => {
					delete valueData.errorMessage
					return valueData
				})
				updates.push({
					id,
					value: newValue,
					errorMessage: ''
				})
			} else {
				updates.push({
					id,
					errorMessage: ''
				})
			}
		})

		return updateEntities(formLineEntities, updates)
	}

	_getContactErrorMessage(valueName, error_code) {
		const texts = {
			email: {
				not_valid: 'Email nemá správný formát',
				required: 'Email je nutné vyplnit'
			},
			phone: {
				not_valid: 'Telefon nemá správný formát',
				required: 'Telefon je nutné vyplnit'
			}
		}

		if (texts[valueName] && texts[valueName][error_code]) {
			return texts[valueName][error_code]
		} else {
			return error_code === 'required' ? 'Pole je nutné vyplnit' : 'Chybný formát'
		}
	}

	_handleEditFormSubmitError(error, formLineEntities, formData) {
		let updatedFormLineEntities = this._getformLineEntitiesWithoutErrorMessages(formLineEntities)
		const status = HttpErrorHelper.getHttpStatus(error)

		if (status === HttpStatusCodes.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(error)
			const { errors = [] } = body
			const updates = []

			errors.forEach(({ error_code, fields }) => {
				if (error_code === 'not_valid' || error_code === 'required') {
					fields.forEach((field) => {
						const formLineEntity = updatedFormLineEntities.find(({ id }) => id === field)

						if (!formLineEntity) {
							const name = field.split('_')[0]
							const index = Number(field.split('.')[1])

							// Pokusim se dohledat prislusnou formLineEntitu kontaktu
							const contactFormLineEntity = updatedFormLineEntities.find(
								({ widget, extra: { valueName = '' } = {} }) =>
									valueName === name && widget === IDS.CONTACTS
							)

							if (contactFormLineEntity) {
								const {
									id: contactId,
									extra: { valueName }
								} = contactFormLineEntity
								const { value } =
									updates.find(({ id }) => id === contactId) || contactFormLineEntity

								const newValue = [...value]

								if (newValue[index]) {
									Object.assign(newValue[index], {
										errorMessage: this._getContactErrorMessage(valueName, error_code)
									})
								}

								updates.push({
									id: contactId,
									value: newValue
								})
							}
						} else if (field === 'action_note') {
							updates.push({
								id: 'action_note',
								errorMessage: 'Je třeba vyplnit důvod změny stavu'
							})
						}

						updatedFormLineEntities = updateEntities(formLineEntities, updates)
					})
				}
			})
		}

		return {
			formLineEntities: updatedFormLineEntities,
			entityFormError: {
				title: 'Nepodařilo se odeslat formulář'
			}
		}
	}

	async _getEntitiesAndPagination() {
		const routeParams = this.getRouteParams()

		let params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)
		params.action = USER_HISTORY_BLOCKING_ACTIONS.join()

		const response = await this._userService.getUserHistoryList(routeParams.userId, params)

		this.setState({
			tableIsLoading: false
		})

		return {
			entities: response.userHistoryEntities,
			paginationEntity: response.pagination
		}
	}

	async _getFormLineEntitiesForEditForm(userEntity = {}) {
		const {
			id,
			login,
			advertsNum = 0,
			activeAdvertsNum = 0,
			emails = [],
			phones = [],
			enabled,
			note = ''
		} = userEntity

		const { serviceEnabled } = UserService.getSautoServiceData(userEntity)

		const stateOptions = [
			{
				value: true,
				name: 'Povolený'
			},
			{
				value: false,
				name: 'Zakázaný'
			}
		]

		const formLineEntities = [
			{
				id: 'id',
				label: 'ID',
				value: id,
				widget: IDS.TEXT,
				disabled: true
			},
			{
				id: 'login',
				label: 'Login',
				value: login,
				widget: IDS.TEXT,
				disabled: true
			},
			{
				id: 'items',
				label: 'Inzerátů',
				value: (
					<a
						href={this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
							[InternalAdminUrlConvertor.constants.FILTER_BY]:
								InternalAdminUrlConvertor.constants.USER_ID,
							[InternalAdminUrlConvertor.constants.FILTER_BY_VALUE]: id
						})}
					>
						{advertsNum}
					</a>
				),
				widget: IDS.CONTENT
			},
			{
				id: 'itemsActive',
				label: 'Aktivních',
				value: (
					<a
						href={this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST, {
							[InternalAdminUrlConvertor.constants.FILTER_BY]:
								InternalAdminUrlConvertor.constants.USER_ID,
							[InternalAdminUrlConvertor.constants.FILTER_BY_VALUE]: id,
							[FILTERS.STATUS.ID]: STATUS_CONSTANTS.ACTIVE
						})}
					>
						{activeAdvertsNum}
					</a>
				),
				widget: IDS.CONTENT
			},
			{
				id: 'enabled',
				label: 'Stav uživatele',
				value: enabled,
				widget: IDS.SELECT,
				options: stateOptions
			}
		]

		formLineEntities.push({
			id: `service_enabled`,
			label: `Stav služby`,
			value: serviceEnabled,
			widget: IDS.SELECT,
			options: stateOptions
		})

		formLineEntities.push({
			id: 'action_note',
			label: 'Důvod změny stavu',
			value: '',
			widget: IDS.TEXTAREA,
			extra: {
				rows: 2
			}
		})

		formLineEntities.push({
			id: 'emails',
			label: 'Emaily',
			value: emails.filter(({ isDeleted }) => !isDeleted),
			widget: IDS.CONTACTS,
			required: true,
			type: 'email',
			extra: {
				valueName: 'email'
			}
		})

		formLineEntities.push({
			id: 'phones',
			label: 'Telefony',
			value: phones.filter(({ isDeleted }) => !isDeleted),
			widget: IDS.CONTACTS,
			required: true,
			type: 'tel',
			extra: {
				valueName: 'phone'
			}
		})

		formLineEntities.push({
			id: 'note',
			label: 'Admin info',
			value: note,
			widget: IDS.TEXTAREA,
			extra: {
				rows: 5
			}
		})

		return formLineEntities
	}

	async getEntity() {
		const routeParams = this.getRouteParams()
		const { [InternalAdminUrlConvertor.constants.USER_EDIT_ID]: userId, ...params } = routeParams

		return await this._userService.getUserDetail(userId, params)
	}
}
