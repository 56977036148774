import React from 'react'

import './BrandsPlaceholder.less'

const CLASSNAME = 'c-catalog-brands-placeholder'

const BrandsPlaceholder = () => {
	const renderGroup = (item) => {
		const itemsArr = Array.from(Array(item).keys())

		return (
			<div className={`${CLASSNAME}__group-wrap`}>
				<div className={`${CLASSNAME}__title`}></div>
				<div className={`${CLASSNAME}__group`}>
					{itemsArr.map((item) => {
						return renderItem(`${item}__key`)
					})}
				</div>
			</div>
		)
	}

	const renderItem = (key) => {
		return <div key={key} className={`${CLASSNAME}__item`}></div>
	}

	return (
		<div className={CLASSNAME}>
			{renderGroup(9)}
			{renderGroup(6)}
			{renderGroup(5)}
			{renderGroup(7)}
		</div>
	)
}

export default BrandsPlaceholder
