import * as FormLines from '@inzeraty/form-lines'
import { FORM_LINES_IDS, FORM_LINES_NAMES, REASONS } from './ReportFormLineConstants'
import { RADIOS_WIDGET_ID } from 'app/component/formLineWidgets/radios/RadiosCompWidget'
import { EMAIL_WIDGET_ID } from 'app/component/formLineWidgets/email/EmailCompWidget'
import { TEXTAREA_WIDGET_ID } from 'app/component/formLineWidgets/textarea/TextareaCompWidget'

import './ReportCS.json'
import 'app/page/userweb/newAdvert/basicInfo/BasicInfoCS.json'

export const reportFormLineEntities = (dictionary) => {
	return FormLines.createEntityList([
		{
			widget: RADIOS_WIDGET_ID,
			id: FORM_LINES_IDS.REPORT_REASON,
			label: dictionary.get('Report.formReasonsLabel'),
			options: [
				{
					id: 1,
					name: dictionary.get('Report.formSuspiciousAdvertLabel'),
					value: REASONS.SUSPICIOUS_ADVERT
				},
				{
					id: 2,
					name: dictionary.get('Report.formWrongCategoryLabel'),
					value: REASONS.WRONG_CATEGORY
				},
				{
					id: 3,
					name: dictionary.get('Report.formFalseInfoLabel'),
					value: REASONS.FALSE_INFO
				},
				{
					id: 4,
					name: dictionary.get('Report.formBadPhotosLabel'),
					value: REASONS.BAD_PHOTOS
				},
				{
					id: 5,
					name: dictionary.get('Report.formOtherLabel'),
					value: REASONS.OTHER
				}
			],
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.REPORT_REASON]: value }),
				isFormLineValid: (formLine) => formLine.value
			},
			required: true
		},
		{
			widget: EMAIL_WIDGET_ID,
			id: FORM_LINES_IDS.USER_EMAIL,
			label: dictionary.get('Report.formEmailLabel'),
			placeholder: dictionary.get('Base.formEmailPlaceholder'),
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.USER_EMAIL]: value }),
				isFormLineValid: (formLine) => formLine.value
			},
			required: true
		},
		{
			widget: TEXTAREA_WIDGET_ID,
			id: FORM_LINES_IDS.REPORT_MESSAGE,
			label: dictionary.get('Report.formReportMessageLabel'),
			placeholder: dictionary.get('Report.formReportMessagePlaceholder'),
			extra: {
				getData: (value) => ({ [FORM_LINES_NAMES.REPORT_MESSAGE]: value }),
				isFormLineValid: (formLine) => formLine.value
			}
		}
	])
}

export const validateFormLines = (allFormLineEntities = []) => {
	const invalidFormLines = allFormLineEntities.filter((formLine) => {
		const { required, extra: { isFormLineValid } = {} } = formLine

		if (required && !isFormLineValid) {
			/* eslint-disable */
			console.warn(`Required field "${formLine.id}" is missing "isFormLineValid" function.`)
			/* eslint-enable */
			return false
		} else if (isFormLineValid) {
			return required && !isFormLineValid(formLine, allFormLineEntities)
		} else {
			return false
		}
	})

	const isValid = !invalidFormLines.length

	return [isValid, invalidFormLines]
}
