import React from 'react'
import PremiseService from 'app/model/premise/PremiseService'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import { ClientProfileForm, WIDGETS_BY_ID } from 'app/page/userweb/clientProfile/ClientProfileView'
import { PROFILE_SECTIONS } from 'app/page/userweb/clientProfile/ClientProfileSections'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import { IA_SECTION } from 'app/page/internalAdmin/premiseEdit/IAPremiseEditSections'
import PropTypes from 'prop-types'
import PremiseEntity from 'app/model/premise/PremiseEntity'
import * as FormLines from '@inzeraty/form-lines'
import LoginEmailWidget, { LOGIN_EMAIL_WIDGET_ID } from './components/loginEmail/LoginEmailWidget'
import WalletWidget, { IA_WALLET_WIDGET_ID } from './components/wallet/WalletWidget'
import AskIdWidget, { ASK_ID_WIDGET_ID } from './components/askId/AskIdWidget'
import DissectPremisePopup from '../components/dissectPremisePopup/DissectPremisePopup'

import 'app/page/userweb/clientProfile/ClientProfileCS.json'
import './IAPremiseEditCS.json'

const IA_WIDGETS_BY_ID = Object.freeze(
	Object.assign(
		{
			[LOGIN_EMAIL_WIDGET_ID]: LoginEmailWidget,
			[IA_WALLET_WIDGET_ID]: WalletWidget,
			[ASK_ID_WIDGET_ID]: AskIdWidget
		},
		WIDGETS_BY_ID
	)
)

const IAPremiseEditView = ({
	premiseEntity,
	formData,
	isSending,
	showSuccessMessage,
	formError,
	isDissectPremisePopupOpened = false,
	dissetPremiseStatusErrorData = null
}) => {
	const localize = useLocalize()
	const fire = useFire()
	const { name } = PremiseService.getPremiseDataForPublic(premiseEntity)

	const sections = [
		{
			sectionId: PROFILE_SECTIONS.BASIC_INFORMATION,
			label: localize('ClientProfile.basicInformation')
		},
		{
			sectionId: PROFILE_SECTIONS.OPENING_HOURS,
			label: localize('ClientProfile.openingHours')
		},
		{
			sectionId: PROFILE_SECTIONS.CONTACT_INFORMATION,
			label: localize('ClientProfile.contact')
		},
		{
			sectionId: PROFILE_SECTIONS.ADDRESS,
			label: localize('ClientProfile.address')
		},
		{
			sectionId: IA_SECTION.SOFTWARE_KEYS,
			label: localize('IAPremiseEdit.softwareKeys')
		},
		{
			sectionId: IA_SECTION.IMPORT_DATA,
			label: localize('IAPremiseEdit.importData')
		}
	]

	return (
		<Layout title={`Detail firmy ${name ? name : '...'}`}>
			{formData && (
				<>
					<ClientProfileForm
						widgetsById={IA_WIDGETS_BY_ID}
						formData={formData}
						isSending={isSending}
						sections={sections}
						showSuccessMessage={showSuccessMessage}
						formError={formError}
					/>
					{isDissectPremisePopupOpened && (
						<DissectPremisePopup
							onClose={() => {
								fire('setIsDissectPremisePopupOpened', false)
							}}
							onSubmit={(data) => {
								fire('dissectPremise', data)
							}}
							statusErrorData={dissetPremiseStatusErrorData}
							premiseId={premiseEntity.id}
						/>
					)}
				</>
			)}
		</Layout>
	)
}

IAPremiseEditView.propTypes = {
	premiseEntity: PropTypes.instanceOf(PremiseEntity),
	formData: PropTypes.shape({
		[PropTypes.string]: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity))
	}),
	isSending: PropTypes.bool,
	showSuccessMessage: PropTypes.bool,
	formError: PropTypes.string,
	isDissectPremisePopupOpened: PropTypes.bool,
	dissetPremiseStatusErrorData: PropTypes.object
}

export default IAPremiseEditView
