import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import FilterConstants from 'app/model/filter/FilterConstants'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import AbstractModalLogic from './AbstractModalLogic'

class PopupLogic extends AbstractModalLogic {
	static get propTypes() {
		return Object.assign({}, AbstractModalLogic.propTypes, {
			popupDefinition: PropTypes.object,
			autoCloseOnUnmount: PropTypes.bool
		})
	}

	static get defaultProps() {
		return Object.assign({}, AbstractModalLogic.defaultProps, {
			autoCloseOnUnmount: true
		})
	}

	constructor(props, context) {
		super(props, context)

		this._canBePopupClosed = this._canBePopupClosed.bind(this)
		this._closePopup = this._closePopup.bind(this)
		this._closePopupWithoutSavingChanges = this._closePopupWithoutSavingChanges.bind(this)
		this._resetFilter = this._resetFilter.bind(this)

		this._isMounted = false
	}

	componentDidMount() {
		super.componentDidMount()

		this._isMounted = true
	}

	componentWillUnmount() {
		const {
			formLineEntity: { id },
			autoCloseOnUnmount
		} = this.props

		if (autoCloseOnUnmount) this._fireClosePopupEvent(id)

		this._isMounted = false
	}

	render() {
		const { children } = this.props

		return children({
			isDefaultPopupType: this._isDefaultPopupType(),

			resetFilter: this._resetFilter,

			closePopup: this._closePopup,
			closePopupWithoutSavingChanges: this._closePopupWithoutSavingChanges,
			canBePopupClosed: this._canBePopupClosed
		})
	}

	_closePopup() {
		const { popupDefinition } = this.props

		this._closeModal(popupDefinition, this._canBePopupClosed, (id) => {
			this._fireClosePopupEvent(id)
		})
	}

	_closePopupWithoutSavingChanges() {
		this._closeModalWithoutSavingChanges(this._canBePopupClosed, (id) => {
			this._fireClosePopupEvent(id)
		})
	}

	_fireClosePopupEvent(id) {
		if (this._isMounted) {
			this.fire('closeFilterPopup', {
				filterId: id
			})
		}
	}

	_resetFilter() {
		const {
			formLineEntity: { id, extra },
			popupDefinition: { updateUrlParams } = {},
			redirect,
			changeFilter,
			filterLogicData: { advertsCount: { calcFilteredAdvertsCountTotal = () => {} } = {} }
		} = this.props

		changeFilter(id, extra.emptyValue, (newFormLineEntity) => {
			this._fireClosePopupEvent(id)

			calcFilteredAdvertsCountTotal(newFormLineEntity)

			if (updateUrlParams) {
				redirect()
			}
		})
	}

	_canBePopupClosed() {
		const { popupDefinition } = this.props

		return this._canBeModalClosed(popupDefinition)
	}

	_isDefaultPopupType() {
		const { popupDefinition } = this.props

		return popupDefinition
			? popupDefinition.filterPopupType === FilterConstants.filterPopupType.DEFAULT
			: true
	}
}

const selectors = (state) => ({
	redirect: state[FiltersExtension.stateIds.REDIRECT],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(selectors)(PopupLogic)
