
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['FavoriteList'] = {
  header: function(d) { return "Oblíbené inzeráty"; },
  count: function(d) { return plural(d.COUNT, 0, cs, { "0": "", one: d.COUNT + " oblíbený inzerát", few: d.COUNT + " oblíbené inzeráty", other: d.COUNT + " oblíbených inzerátů" }); },
  emptyHeader: function(d) { return "Nemáte žádné oblíbené inzeráty"; },
  emptyText: function(d) { return "Inzerát si přidáte do oblíbených zaškrtnutím symbolu srdce v pravém horním rohu inzerátu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	