import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Context from 'ima/page/context'
import { Checkbox, Textarea, Button } from '@sznds/react'
import { Format, DefaultProps as DEFAULT_PROPS, HttpErrorHelper } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import operatingLeasePropTypes, {
	operatingLeaseDefinitionPropTypes
} from 'app/model/operatingLease/OperatingLeasePropTypes'
import { useLocalize } from 'app/base/componentHelpers'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import Label from 'app/component/label/Label'
import FormElementError from 'app/component/formElementError/FormElementError'
import InputNumber from 'app/component/inputNumber/InputNumber'
import InputNumberWithOptionsBase from 'app/component/inputNumber/InputNumberWithOptions'
import OPERATING_LEASE_DEFINITION, {
	getDefinition
} from 'app/component/operatingLease/OperatingLeaseDefinition'

import 'app/base/BaseCS.json'
import './NewOperatingLeaseVariantFormCS.json'
import './NewOperatingLeaseVariantForm.less'

const CLASSNAME = 'c-new-op-lease-variant-form'

const API_OPER_LEASE_FIELDS = Object.freeze({
	PERIOD: 'period',
	ANNUAL_DISTANCE: 'annual_distance',
	SERVICES_CB: 'services_cb',
	ADDITIONAL_INFO: 'additional_info',
	PRICE: 'price'
})

const transformNumericInput = (value, originalValue) => (originalValue === '' ? undefined : value)

const getValidationSchema = (localize) =>
	yup.object({
		[API_OPER_LEASE_FIELDS.PERIOD]: yup
			.number()
			.transform(transformNumericInput)
			.positive(localize('NewOperatingLeaseVariantForm.periodMustBePositiveError'))
			.required(localize('NewOperatingLeaseVariantForm.periodRequiredError'))
			.typeError(localize('NewOperatingLeaseVariantForm.periodMustBePositiveError')),
		[API_OPER_LEASE_FIELDS.ANNUAL_DISTANCE]: yup
			.number()
			.transform(transformNumericInput)
			.positive(localize('NewOperatingLeaseVariantForm.distanceMustBePositiveError'))
			.required(localize('NewOperatingLeaseVariantForm.distanceRequiredError'))
			.typeError(localize('NewOperatingLeaseVariantForm.distanceMustBePositiveError')),
		[API_OPER_LEASE_FIELDS.ADDITIONAL_INFO]: yup
			.string()
			.max(500, localize('NewOperatingLeaseVariantForm.additionalInfoTooLongError')),
		[API_OPER_LEASE_FIELDS.PRICE]: yup
			.number()
			.transform(transformNumericInput)
			.positive(localize('NewOperatingLeaseVariantForm.priceMustBePositiveError'))
			.required(localize('NewOperatingLeaseVariantForm.priceRequiredError'))
			.typeError(localize('NewOperatingLeaseVariantForm.priceMustBePositiveError'))
	})

const getPeriodOptions = (localize) =>
	[3, 6, 12, 18, 24, 36, 48, 60].map((month) => ({
		value: month,
		name: localize('Base.month', {
			MONTH: month,
			MONTH_FORMATTED: Format.number(month)
		})
	}))

const getDistanceOptions = (localize) =>
	[10000, 12000, 15000, 20000, 25000, 30000, 35000, 40000, 50000].map((distance) => ({
		value: distance,
		name: `${Format.number(distance)} ${localize('Base.KM')}`
	}))

const getDefaultValues = (variantToEdit) => {
	if (variantToEdit) {
		return {
			[API_OPER_LEASE_FIELDS.PERIOD]: variantToEdit.period || '',
			[API_OPER_LEASE_FIELDS.ANNUAL_DISTANCE]: variantToEdit.annualDistance || '',
			[API_OPER_LEASE_FIELDS.SERVICES_CB]: variantToEdit.servicesCb
				? variantToEdit.servicesCb.map(({ value }) => value)
				: null,
			[API_OPER_LEASE_FIELDS.ADDITIONAL_INFO]: variantToEdit.additionalInfo || '',
			[API_OPER_LEASE_FIELDS.PRICE]: variantToEdit.priceWithoutVat || ''
		}
	} else {
		return {
			[API_OPER_LEASE_FIELDS.PERIOD]: '',
			[API_OPER_LEASE_FIELDS.ANNUAL_DISTANCE]: '',
			[API_OPER_LEASE_FIELDS.SERVICES_CB]: null,
			[API_OPER_LEASE_FIELDS.ADDITIONAL_INFO]: '',
			[API_OPER_LEASE_FIELDS.PRICE]: ''
		}
	}
}

const NewOperatingLeaseVariantForm = ({
	className,
	operatingLeaseDefinitionData = DEFAULT_PROPS.ARRAY,
	variantToEdit,
	fetchOperatingLeasesVariants = DEFAULT_PROPS.FUNCTION,
	addNewOperatingLeaseVariant = DEFAULT_PROPS.FUNCTION,
	editOperatingLeaseVariant = DEFAULT_PROPS.FUNCTION,
	onFormClose = DEFAULT_PROPS.FUNCTION
}) => {
	const { $Dispatcher } = useContext(Context)
	const localize = useLocalize()

	const { control, reset, handleSubmit, formState: { errors = {}, isSubmitting } = {} } = useForm({
		defaultValues: getDefaultValues(variantToEdit),
		resolver: yupResolver(getValidationSchema(localize))
	})

	useEffect(() => {
		reset(getDefaultValues(variantToEdit))
	}, [variantToEdit, reset])

	const isEditMode = !!variantToEdit?.id

	const { text: periodLabel, unit: periodUnit } = getDefinition(
		operatingLeaseDefinitionData,
		OPERATING_LEASE_DEFINITION.PERIOD
	)
	const periodOptions = getPeriodOptions(localize)

	const { text: distanceLabel, unit: distanceUnit } = getDefinition(
		operatingLeaseDefinitionData,
		OPERATING_LEASE_DEFINITION.ANNUAL_DISTANCE
	)
	const distanceOptions = getDistanceOptions(localize)

	const { text: servicesLabel, options: servicesOptions = [] } = getDefinition(
		operatingLeaseDefinitionData,
		OPERATING_LEASE_DEFINITION.SERVICES_CB
	)
	const { text: infoLabel } = getDefinition(
		operatingLeaseDefinitionData,
		OPERATING_LEASE_DEFINITION.ADDITIONAL_INFO
	)
	const { text: priceLabel } = getDefinition(
		operatingLeaseDefinitionData,
		OPERATING_LEASE_DEFINITION.PRICE
	)

	const onSubmit = async (data) => {
		const refetchAllOperatingLeasesVariants = async () => {
			try {
				await fetchOperatingLeasesVariants()
			} catch {
				// na chybu nebudeme nijak reagovat
			}
		}

		try {
			if (isEditMode) {
				await editOperatingLeaseVariant(Object.assign({ id: variantToEdit?.id }, data))
			} else {
				await addNewOperatingLeaseVariant(data)
			}
			onFormClose()
		} catch (error) {
			let errorDescription = localize('NewOperatingLeaseVariantForm.defaultSaveError')

			const status = HttpErrorHelper.getHttpStatus(error)

			if (status) {
				const { body = {} } = HttpErrorHelper.getParams(error) || {}
				const { errors = [] } = body || {}

				if (errors.find(({ error_code }) => error_code === 'deleted_operating_lease')) {
					errorDescription = localize('NewOperatingLeaseVariantForm.alreadyDeletedError')
					await refetchAllOperatingLeasesVariants()
					onFormClose()
				} else if (errors.find(({ error_code }) => error_code === 'duplicate_variant')) {
					errorDescription = localize('NewOperatingLeaseVariantForm.alreadyExistsError')
					await refetchAllOperatingLeasesVariants()
				}
			}

			$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				title: errorDescription,
				type: StatusMessage.TYPE.ERROR
			})
		}
	}

	return (
		<form
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className={`${CLASSNAME}__heading`}>
				{isEditMode
					? localize('NewOperatingLeaseVariantForm.headingEdit')
					: localize('NewOperatingLeaseVariantForm.headingNew')}
			</div>

			<FormGroup>
				<FormGroupItem>
					<Label
						className={`${CLASSNAME}__label`}
						htmlFor={API_OPER_LEASE_FIELDS.PERIOD}
						isRequired
					>
						{`${periodLabel} ${periodUnit}`}
					</Label>
					<Controller
						control={control}
						name={API_OPER_LEASE_FIELDS.PERIOD}
						render={({ field: { ref, ...restField } = {} }) => (
							<InputNumberWithOptions
								id={API_OPER_LEASE_FIELDS.PERIOD}
								field={restField}
								options={periodOptions}
								error={!!errors[API_OPER_LEASE_FIELDS.PERIOD]}
								data-e2e='operating-lease-period'
							/>
						)}
					/>
					<FormElementError message={errors[API_OPER_LEASE_FIELDS.PERIOD]?.message} />
				</FormGroupItem>

				<FormGroupItem>
					<Label
						className={`${CLASSNAME}__label`}
						htmlFor={API_OPER_LEASE_FIELDS.ANNUAL_DISTANCE}
						isRequired
					>
						{`${distanceLabel} ${distanceUnit}`}
					</Label>
					<Controller
						control={control}
						name={API_OPER_LEASE_FIELDS.ANNUAL_DISTANCE}
						render={({ field: { ref, ...restField } = {} }) => (
							<InputNumberWithOptions
								id={API_OPER_LEASE_FIELDS.ANNUAL_DISTANCE}
								field={restField}
								options={distanceOptions}
								error={!!errors[API_OPER_LEASE_FIELDS.ANNUAL_DISTANCE]}
								data-e2e='operating-lease-annual-distance'
							/>
						)}
					/>
					<FormElementError message={errors[API_OPER_LEASE_FIELDS.ANNUAL_DISTANCE]?.message} />
				</FormGroupItem>
			</FormGroup>

			<FormItem>
				<Label className={`${CLASSNAME}__label`}>{servicesLabel}</Label>
				<Controller
					control={control}
					name={API_OPER_LEASE_FIELDS.SERVICES_CB}
					render={({ field }) => (
						<div className={`${CLASSNAME}__checkboxes`}>
							{servicesOptions.map(({ value, name }) => (
								<Checkbox
									key={value}
									className={`${CLASSNAME}__checkbox`}
									label={name}
									value={value}
									checked={field.value ? field.value.includes(value) : false}
									data-e2e={`operating-lease-service-${value}`}
									onChange={(event) => {
										const { value: stringValue, checked } = event.target
										const value = Number(stringValue)

										if (checked) {
											field.onChange(field.value ? [...field.value, value] : [value])
										} else {
											field.onChange(field.value ? field.value.filter((v) => v !== value) : [])
										}
									}}
								/>
							))}
						</div>
					)}
				/>
			</FormItem>

			<FormItem>
				<Label className={`${CLASSNAME}__label`} htmlFor={API_OPER_LEASE_FIELDS.ADDITIONAL_INFO}>
					{infoLabel}
				</Label>
				<Controller
					control={control}
					name={API_OPER_LEASE_FIELDS.ADDITIONAL_INFO}
					render={({ field: { ref, ...restField } = {} }) => (
						<Textarea
							className={`${CLASSNAME}__textarea`}
							id={API_OPER_LEASE_FIELDS.ADDITIONAL_INFO}
							{...restField}
							data-e2e='operating-lease-additional-info'
						/>
					)}
				/>
				<FormElementError message={errors[API_OPER_LEASE_FIELDS.ADDITIONAL_INFO]?.message} />
			</FormItem>

			<FormItem>
				<Label className={`${CLASSNAME}__label`} htmlFor={API_OPER_LEASE_FIELDS.PRICE} isRequired>
					{priceLabel}
				</Label>
				<Controller
					control={control}
					name={API_OPER_LEASE_FIELDS.PRICE}
					render={({ field: { ref, ...restField } = {} }) => (
						<InputNumber
							className={`${CLASSNAME}__input`}
							id={API_OPER_LEASE_FIELDS.PRICE}
							{...restField}
							error={!!errors[API_OPER_LEASE_FIELDS.PRICE]}
							data-e2e='operating-lease-price'
						/>
					)}
				/>
				<FormElementError message={errors[API_OPER_LEASE_FIELDS.PRICE]?.message} />
			</FormItem>

			<FormGroup>
				<FormGroupItem>
					<Button
						className={`${CLASSNAME}__button`}
						type='submit'
						primary={true}
						text={localize('NewOperatingLeaseVariantForm.save')}
						loading={isSubmitting}
						data-e2e='operating-lease-save-new-variant-button'
					/>
				</FormGroupItem>
				<FormGroupItem>
					<Button
						className={`${CLASSNAME}__button`}
						type='button'
						primary={false}
						text={localize('NewOperatingLeaseVariantForm.cancel')}
						onClick={onFormClose}
					/>
				</FormGroupItem>
			</FormGroup>
		</form>
	)
}

NewOperatingLeaseVariantForm.propTypes = {
	className: PropTypes.string,
	operatingLeaseDefinitionData: PropTypes.arrayOf(
		PropTypes.shape(operatingLeaseDefinitionPropTypes)
	),
	variantToEdit: PropTypes.shape(operatingLeasePropTypes),
	fetchOperatingLeasesVariants: PropTypes.func,
	addNewOperatingLeaseVariant: PropTypes.func,
	editOperatingLeaseVariant: PropTypes.func,
	onFormClose: PropTypes.func
}

export default NewOperatingLeaseVariantForm

const FormItem = ({ children }) => <div className={`${CLASSNAME}__item`}>{children}</div>

FormItem.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const FormGroup = ({ children }) => <div className={`${CLASSNAME}__group`}>{children}</div>

FormGroup.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const FormGroupItem = ({ children }) => <div className={`${CLASSNAME}__group-item`}>{children}</div>

FormGroupItem.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const InputNumberWithOptions = ({ id, field, options, error, 'data-e2e': dataE2E }) => (
	<InputNumberWithOptionsBase
		value={String(field.value)}
		onChange={(inputValue = '') => field.onChange(inputValue)}
		options={options}
		renderInput={({ AutoCompleteInput, getAutoCompleteInputProps, getInputProps }) => (
			<AutoCompleteInput
				{...getAutoCompleteInputProps()}
				inputProps={getInputProps({
					className: `${CLASSNAME}__input`,
					id,
					...field,
					error,
					'data-e2e': dataE2E
				})}
			/>
		)}
		renderOptions={({
			filteredOptions,
			Dropdown,
			getDropdownProps,
			AutoCompleteOption,
			getItemProps
		}) => (
			<Dropdown {...getDropdownProps()}>
				{filteredOptions.map((option, index) => (
					<AutoCompleteOption
						key={option.value}
						{...getItemProps(option, index)}
						data-e2e={dataE2E}
					>
						{option.name}
					</AutoCompleteOption>
				))}
			</Dropdown>
		)}
	/>
)

InputNumberWithOptions.propTypes = {
	id: PropTypes.string,
	field: PropTypes.object.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		})
	),
	error: PropTypes.bool,
	'data-e2e': PropTypes.string
}
