import { AbstractFactory } from '@inzeraty/models'
import PremiseEntityExport from './PremiseEntityExport'

export default class PremiseFactoryExport extends AbstractFactory {
	constructor() {
		super(PremiseEntityExport)
	}

	transformData(data = {}) {
		const { subject, premise_services } = data

		return {
			admins: data.admins,
			id: data.id,
			name: data.name,
			emails: data.emails,
			users: data.users,
			allAdvertsCount: data.all_adverts_count,
			advertReportsCount: data.advert_reports_count,
			premiseServices: premise_services
				? premise_services.map((premise_service) => {
						return {
							oldId: premise_service.old_id,
							name: premise_service.name,
							useServiceName: premise_service.use_service_name,
							serviceName: premise_service.service_name
						}
				  })
				: undefined,
			subject: subject
				? {
						ico: subject.ico,
						id: subject.id
				  }
				: undefined,
			registrations: data.registrations,
			softwareKeys: data.software_keys
		}
	}
}
