import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import * as FormLines from '@inzeraty/form-lines'
import { CategoryEntity } from '@inzeraty/models'
import FilterConstants from 'app/model/filter/FilterConstants'
import FilterExtendedInfo from 'app/component/filters/components/filterExtendedInfo/FilterExtendedInfo'
import BrandModelFilterPopupWithOpener from '../../factories/popups/BrandModelFilterPopupWithOpener'
import BrandModelFilterDropdownWithOpener from '../../factories/dropdowns/BrandModelFilterDropdownWithOpener'
import RegionDistrictFilterPopupWithOpener from '../../factories/popups/RegionDistrictFilterPopupWithOpener'
import RegionDistrictFilterDropdownWithOpener from '../../factories/dropdowns/RegionDistrictFilterDropdownWithOpener'
import MultiOptionFilterDropdownWithOpener from '../../factories/dropdowns/MultiOptionFilterDropdownWithOpener'
import MultiOptionInlineFilter from '../../factories/inline/MultiOptionInlineFilter'
import RadioInlineForm from 'app/component/filters/forms/main/inlineForms/radioInlineForm/RadioInlineForm'
import RadioInlineFilter from '../../factories/inline/RadioInlineFilter'
import AdvancedBrandModelOpener from 'app/component/filters/forms/main/openers/brandModelOpener/AdvancedBrandModelOpener'
import BrandModelFilterLogic from 'app/component/filters/logic/BrandModelFilterLogic'
import {
	FILTER_CONTEXT,
	FILTER_CONTEXT_INLINE_MAPPING
} from 'app/component/filters/FiltersExtension'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { calcActiveFiltersCounter } from '../../counters/activeFiltersCounterCalculator'
import { areRegressionTestsRunning } from 'app/page/componentRegressionTest/ComponentRegressionTestUtils'
import { DATA_SEOT } from 'app/base/SeoTesting'
import Phrase from '../phrase/Phrase'
import select from 'ima-plugin-select'
import classnames from 'classnames'
import DealTypeSwitch from 'app/component/filters/components/dealTypeSwitch/DealTypeSwitch'

import './MainForm.less'
import '../../FiltersViewCS.json'

const CLASSNAME = 'c-main-form'

const RadioFactory = (props) => {
	const { formLineEntity, formLineEntities, changeFilter, context } = props

	const inlineFilterProps = {
		formLineEntity,
		changeFilter,
		formLineEntities,
		context: FILTER_CONTEXT_INLINE_MAPPING[context]
	}

	if (context === FILTER_CONTEXT.MAIN_MENU) {
		const { renderTypes = [''] } = hpFilters.find(
			(filter) => filter.formLineId === formLineEntity.id
		)

		return renderTypes.map((renderType) => {
			inlineFilterProps.renderType = renderType

			return (
				<div className={`${CLASSNAME}__filter`} key={renderType}>
					<div className={`${CLASSNAME}__desktop-opener`}>
						<RadioInlineFilter {...inlineFilterProps} />
					</div>
				</div>
			)
		})
	} else {
		return <RadioInlineFilter {...inlineFilterProps} />
	}
}

const BrandModelFactory = (props) => {
	const {
		formLineEntity,
		formLineEntities,
		changeFilter: globalChangeFilter,
		openedFilterPopups,
		openedFilterDropdown,
		context,
		categoryEntity
	} = props

	const { id } = formLineEntity

	const popupDefinition = openedFilterPopups.find((popup) => popup.filterId === id)
	const dropdownDefinition = openedFilterDropdown.filterId === id ? openedFilterDropdown : undefined

	const dropdownOpenerProps = {
		formLineEntity,
		changeFilter: globalChangeFilter,
		dropdownDefinition,
		context
	}

	const popupOpenerProps = {
		formLineEntity,
		changeFilter: globalChangeFilter,
		popupDefinition,
		context
	}

	if (context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL) {
		return <BrandModelFilterDropdownWithOpener {...dropdownOpenerProps} />
	} else if (context === FILTER_CONTEXT.MAIN_MENU) {
		return (
			<div className={`${CLASSNAME}__desktop-opener ${CLASSNAME}__manufacturer`}>
				<BrandModelFilterLogic
					formLineEntity={formLineEntity}
					changeFilter={globalChangeFilter}
					context={context}
				>
					{(brandModelFilterLogic) => {
						const {
							form: { changeFilter },
							advertsCountForBrands,
							advertsCountForModels
						} = brandModelFilterLogic

						return (
							<AdvancedBrandModelOpener
								formLineEntity={formLineEntity}
								context={context}
								categoryEntity={categoryEntity}
								changeFilter={changeFilter}
								dropdownDefinition={dropdownDefinition}
								advertsCountForBrands={advertsCountForBrands}
								advertsCountForModels={advertsCountForModels}
								filteredFormLineEntities={formLineEntities}
							/>
						)
					}}
				</BrandModelFilterLogic>
			</div>
		)
	} else {
		return <BrandModelFilterPopupWithOpener {...popupOpenerProps} />
	}
}

const RegionDistrictFactory = (props) => {
	const { formLineEntity, changeFilter, openedFilterPopups, openedFilterDropdown, context } = props
	const { id } = formLineEntity

	const popupDefinition = openedFilterPopups.find((popup) => popup.filterId === id)
	const dropdownDefinition = openedFilterDropdown.filterId === id ? openedFilterDropdown : undefined

	return context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL ? (
		<RegionDistrictFilterDropdownWithOpener
			formLineEntity={formLineEntity}
			changeFilter={changeFilter}
			dropdownDefinition={dropdownDefinition}
			context={context}
		/>
	) : (
		<RegionDistrictFilterPopupWithOpener
			formLineEntity={formLineEntity}
			changeFilter={changeFilter}
			popupDefinition={popupDefinition}
			context={context}
		/>
	)
}

const selectors = (state) => ({
	seoAdvertCounts: {
		[FilterConstants.formLineIds.VEHICLE_BODY]: state.seoVehicleBodyWithAdvertsValues,
		[FilterConstants.formLineIds.FUEL]: state.seoFuelWithAdvertsValues
	}
})

const MultiOptionFactory = select(selectors)((props) => {
	const {
		formLineEntity,
		changeFilter,
		formLineEntities,
		openedFilterDropdown,
		context,
		isSectionOpened,
		categoryEntity,
		seoAdvertCounts = {}
	} = props
	const { id } = formLineEntity

	const dropdownDefinition = openedFilterDropdown.filterId === id ? openedFilterDropdown : undefined

	const dropdownOpenerProps = {
		formLineEntity,
		changeFilter,
		dropdownDefinition,
		context,
		className: `${CLASSNAME}__multioption-main-menu`
	}

	const inlineFilterProps = {
		formLineEntity,
		changeFilter,
		formLineEntities,
		context: FILTER_CONTEXT_INLINE_MAPPING[context],
		isVisible: isSectionOpened,
		categoryEntity
	}

	if (context === FILTER_CONTEXT.MAIN_MENU) {
		return (
			<div className={`${CLASSNAME}__filter`}>
				<div className={`${CLASSNAME}__desktop-opener`}>
					<MultiOptionFilterDropdownWithOpener
						{...dropdownOpenerProps}
						seoAdvertCounts={seoAdvertCounts[id]}
					/>
				</div>
			</div>
		)
	} else {
		return <MultiOptionInlineFilter {...inlineFilterProps} />
	}
})

const widgetIds = FilterConstants.widgetIds

/**
 * TODO
 * Jakmile bude v provozu nasazena úprava api, která nám bude posílat nové widgety, můžeme původní převody na komponenty smazat.
 */
const widgetByIdRules = Object.freeze(
	Object.assign(
		{
			[widgetIds.BRAND]: BrandModelFactory,
			[widgetIds.REGION]: RegionDistrictFactory,
			[widgetIds.MULTIOPTION]: MultiOptionFactory,
			// TODO tech rozsahovych filtru by tu melo byt vic - mame ruzne filtry pro stari vozu, stari inzeratu a treba tachometr
			[widgetIds.RANGE]: RadioFactory,
			[widgetIds.PHRASE]: Phrase
		},
		{
			[widgetIds.BRAND]: BrandModelFactory,
			[widgetIds.AGE]: RadioFactory,
			[widgetIds.PRICE]: RadioFactory,
			[widgetIds.OPERATING_LEASE_PRICE_WITHOUT_VAT]: RadioFactory,
			[widgetIds.OPERATING_LEASE_ANNUAL_DISTANCE]: RadioFactory,
			[widgetIds.OPERATING_LEASE_PERIOD]: RadioFactory,
			[widgetIds.OPERATING_LEASE_SERVICES]: MultiOptionFactory,
			[widgetIds.OPERATING_LEASE_INTENDED_FOR]: MultiOptionFactory,
			[widgetIds.CONDITION]: MultiOptionFactory,
			[widgetIds.FUEL]: MultiOptionFactory,
			[widgetIds.VEHICLE_BODY]: MultiOptionFactory,
			[widgetIds.TACHOMETER]: RadioFactory,
			[widgetIds.GEARBOX]: MultiOptionFactory,
			[widgetIds.MULTIOPTION]: MultiOptionFactory,
			[widgetIds.PERFORMANCE]: RadioFactory,
			[widgetIds.COUNTRY_OF_ORIGIN]: MultiOptionFactory,
			[widgetIds.VENDOR]: MultiOptionFactory,
			[widgetIds.REGION]: RegionDistrictFactory,
			[widgetIds.DISPLACEMENT]: RadioFactory,
			[widgetIds.AIR_CONDITIONING]: MultiOptionFactory,
			[widgetIds.FUEL_CONSUMPTION]: RadioFactory,
			[widgetIds.CERTIFIED_PROGRAM]: MultiOptionFactory,
			[widgetIds.AIRBAGS]: RadioFactory,
			[widgetIds.COLOR]: MultiOptionFactory,
			[widgetIds.CAPACITY]: RadioFactory,
			[widgetIds.DOORS]: RadioFactory,
			[widgetIds.BUS_SEATS]: MultiOptionFactory,
			[widgetIds.ITEM_AGE]: RadioFactory,
			[widgetIds.PRICE_OPTION]: MultiOptionFactory,
			[widgetIds.HISTORY_INFO]: MultiOptionFactory,
			[widgetIds.EQUIPMENT]: MultiOptionFactory,
			[widgetIds.DRIVE]: MultiOptionFactory,
			[widgetIds.OTHER_SPECIFICATION]: MultiOptionFactory,
			[widgetIds.WEIGHT]: RadioFactory,
			[widgetIds.BEDS]: RadioFactory,
			[widgetIds.MACHINE_HOURS]: RadioFactory
		}
	)
)

const hpFilters = [
	{
		formLineId: FilterConstants.formLineIds.BRAND_MODEL
	},
	{
		formLineId: FilterConstants.formLineIds.PRICE,
		renderTypes: [RadioInlineForm.RENDER_TYPE.FROM, RadioInlineForm.RENDER_TYPE.TO]
	},
	{
		formLineId: FilterConstants.formLineIds.AGE,
		renderTypes: [RadioInlineForm.RENDER_TYPE.FROM]
	},
	{
		formLineId: FilterConstants.formLineIds.TACHOMETER,
		renderTypes: [RadioInlineForm.RENDER_TYPE.TO]
	},
	{
		formLineId: FilterConstants.formLineIds.VEHICLE_BODY
	},
	{
		formLineId: FilterConstants.formLineIds.FUEL
	}
]

const DEF_PROP_CATEGORY = new CategoryEntity({})

export default class MainForm extends AbstractComponent {
	static get propTypes() {
		return {
			isOperatingLease: PropTypes.bool,
			formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
			changeFilter: PropTypes.func.isRequired,
			categoryEntity: PropTypes.instanceOf(CategoryEntity),
			context: PropTypes.string,
			openedFilterPopups: PropTypes.array,
			openedSectionIndex: PropTypes.number,
			renderSection: PropTypes.func,
			onSectionToggle: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			isOperatingLease: false,
			formLineEntities: DEFAULT_PROPS.ARRAY,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			categoryEntity: DEF_PROP_CATEGORY,
			openedFilterPopups: DEFAULT_PROPS.ARRAY,
			onSectionToggle: DEFAULT_PROPS.FUNCTION
		}
	}

	static get widgetIds() {
		return widgetIds
	}

	render() {
		const { isOperatingLease, formLineEntities, context, categoryEntity } = this.props

		if (context === FILTER_CONTEXT.MAIN_MENU) {
			const order = hpFilters.map((formLineEntity) => formLineEntity.formLineId)
			const hideByCategory = FilterConstants.hpHideFilterByCategory[categoryEntity.id] || []

			const filteredFormLineEntities = formLineEntities.filter(
				(formLineEntity) =>
					order.includes(formLineEntity.id) && !hideByCategory.includes(formLineEntity.id)
			)

			const orderedFormLineEntities = this._orderFormLineEntities(filteredFormLineEntities, order)

			const brandsFilter = orderedFormLineEntities.filter(
				(f) => f.id === FilterConstants.formLineIds.BRAND_MODEL
			)
			const restHPFilters = orderedFormLineEntities.filter(
				(f) => f.id !== FilterConstants.formLineIds.BRAND_MODEL
			)

			const restFiltersCount = restHPFilters.reduce((total, formLineEntity) => {
				const { renderTypes = [''] } = hpFilters.find(
					(hpFilter) => hpFilter.formLineId === formLineEntity.id
				)

				return total + renderTypes.length
			}, 0)

			return (
				<div className={`${CLASSNAME}__desktop`}>
					{this._renderFilterFormLine(brandsFilter)}
					<div
						className={classnames({
							[`${CLASSNAME}__filters-hp`]: true,
							[`${CLASSNAME}__filters-hp--ordered`]: restFiltersCount > 3
						})}
						data-seot={DATA_SEOT.HP_FILTERS}
					>
						{this._renderFilterFormLine(restHPFilters)}
					</div>
				</div>
			)
		} else {
			// nechceme kvuli zmene poradi filtru prefocovat reg. testy
			// porad dokola, takze na reg. testech je poradi fixni.
			if (areRegressionTestsRunning(this.utils.$Router)) {
				return (
					<div className={`${CLASSNAME}__wrapper`}>
						<DealTypeSwitch
							className={`${CLASSNAME}__deal-type-switch`}
							isOperatingLeaseSelected={isOperatingLease}
							context={context}
						/>
						{this._renderFilters(formLineEntities)}
					</div>
				)
			}

			const order = FilterConstants.defaultFiltersOrder

			const orderedFormLineEntities = this._orderFormLineEntities(formLineEntities, order)

			return (
				<div className={`${CLASSNAME}__wrapper`}>
					<DealTypeSwitch
						className={`${CLASSNAME}__deal-type-switch`}
						isOperatingLeaseSelected={isOperatingLease}
						context={context}
					/>
					{this._renderFilters(orderedFormLineEntities)}
				</div>
			)
		}
	}

	_orderFormLineEntities(formLineEntities, order) {
		const orderedFormLineEntities = [...formLineEntities].sort(
			(formLineEntityA, formLineEntityB) => {
				const formLineEntityAOrder = order.findIndex((id) => formLineEntityA.id === id)
				const formLineEntityBOrder = order.findIndex((id) => formLineEntityB.id === id)

				if (formLineEntityAOrder < 0) return 1

				if (formLineEntityBOrder < 0) return -1

				return formLineEntityAOrder - formLineEntityBOrder
			}
		)

		return orderedFormLineEntities
	}

	_renderFormLineText(id, label) {
		if (id === FilterConstants.formLineIds.OTHER_SPECIFICATION) {
			return this.localize('FiltersView.forDisabledPeople')
		} else if (id === FilterConstants.formLineIds.VENDOR) {
			return this.localize('FiltersView.vendorType')
		} else {
			return label
		}
	}

	_renderFilters(formLineEntities) {
		const { formLineEntities: allFormLineEntities, changeFilter, context } = this.props

		return formLineEntities.map((formLine) => {
			const { label, id } = formLine

			const isFilterOpenedByDefault = FilterConstants.filtersOpenedByDefault.includes(id)

			const counter = calcActiveFiltersCounter(formLine)

			const formLineText = this._renderFormLineText(id, label)

			const filterExtendedInfoProps = {
				formLineEntity: formLine,
				text: formLineText,
				isFilterOpen: isFilterOpenedByDefault,
				counter: counter,
				dataE2E: id,
				changeFilter: changeFilter,
				context: context
			}

			if (id === FilterConstants.formLineIds.PRICE_OPTION) {
				// filtr na moznosti ceny uz nezobrazujeme samostatne, je soucasti filtru na cenu
				return null
			} else if (id === FilterConstants.formLineIds.PRICE) {
				const priceOptionFormLine = allFormLineEntities.find(
					({ id }) => id === FilterConstants.formLineIds.PRICE_OPTION
				)

				// s filtrem ceny chceme zobrazit zaroven i filtr na dalsi moznosti ceny,
				// pokud nahodou neni tento filtr k dispozici, tak pokracujeme dal a cenu
				// vykreslime beznym zpusobem
				if (priceOptionFormLine) {
					const priceOptionCounter = calcActiveFiltersCounter(priceOptionFormLine)

					return (
						<FilterExtendedInfo
							key={id}
							{...filterExtendedInfoProps}
							counter={counter + priceOptionCounter}
						>
							{() => (
								<>
									{this._renderFilterFormLine([formLine])}
									<div className={`${CLASSNAME}__price-options`}>
										{this._renderFilterFormLine([priceOptionFormLine])}
									</div>
								</>
							)}
						</FilterExtendedInfo>
					)
				}
			}

			return (
				<FilterExtendedInfo key={id} {...filterExtendedInfoProps}>
					{() => this._renderFilterFormLine([formLine])}
				</FilterExtendedInfo>
			)
		})
	}

	_renderFilterFormLine(formLineEntitiesToRender) {
		const {
			changeFilter,
			categoryEntity,
			context,
			formLineEntities,
			openedFilterPopups,
			openedFilterDropdown
		} = this.props

		return FormLines.renderWidgetsByIds(
			formLineEntitiesToRender,
			{
				categoryEntity,
				formLineEntities,
				changeFilter,
				context,
				openedFilterPopups,
				openedFilterDropdown
			},
			widgetByIdRules
		)
	}
}

RegionDistrictFactory.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	context: PropTypes.string,
	changeFilter: PropTypes.func,
	openedFilterPopups: PropTypes.arrayOf(PropTypes.object),
	openedFilterDropdown: PropTypes.object
}
