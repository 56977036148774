
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PaymentView'] = {
  creditPay: function(d) { return "Přejít na platební bránu"; },
  smsPay: function(d) { return "Přejít na SMS platbu"; },
  walletPay: function(d) { return "Zaplatit peněženkou"; },
  vinDuplicationError: function(d) { return "Inzerát nelze zaplatit, jelikož VIN vozidla se shoduje s jiným inzerátem."; },
  draftError: function(d) { return "Inzerát nelze zaplatit, jelikož je rozpracovaný."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	