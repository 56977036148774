import AbstractNewAdvertBaseController from '../AbstractNewAdvertBaseController'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'

// Kontrolujeme stav platby kazdych 10 vterin
const PENDING_STATUS_REFRESH_FREQUENCY = 10 * 1000

class PaymentConfirmationController extends AbstractNewAdvertBaseController {
	constructor(dependenciesHelper, paymentService) {
		super(dependenciesHelper)

		this._router = this._utils.$Router
		this._paymentService = paymentService
		this._pendingPaymentTimeout = null

		this._processCreditCardConfirmation = this._processCreditCardConfirmation.bind(this)
		this._processConfirmationError = this._processConfirmationError.bind(this)
	}

	activate() {
		super.activate()

		this._pendingPaymentTimeout = setTimeout(() => {
			this._checkPaymentStatus()
		}, PENDING_STATUS_REFRESH_FREQUENCY)
	}

	deactivate() {
		super.deactivate()

		if (this._pendingPaymentTimeout) {
			this._clearPendingPaymentTimeout()
		}
	}

	_checkPaymentStatus() {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const {
			[URL_APP_PARAMS.ADVERT_ID]: advertId,
			[URL_APP_PARAMS.PAYMENT_ID]: paymentId
		} = this.getRouteParams()

		this._paymentService
			.closePayment(advertId, paymentId)
			.then(this._processCreditCardConfirmation)
			.catch(this._processConfirmationError)
	}

	_processCreditCardConfirmation({ body: { status_code } }) {
		if (status_code === HTTP_STATUS_CODE.OK) {
			this._redirectToAdvertList()
		}
	}

	_processConfirmationError(error) {
		const httpStatus = error.getHttpStatus()

		// - platba uz byla pripsana
		if (httpStatus === HTTP_STATUS_CODE.FORBIDDEN) {
			this._redirectToAdvertList()
		}
	}

	onRedirectToAdvertListClick() {
		this._redirectToAdvertList()
	}

	_clearPendingPaymentTimeout() {
		clearTimeout(this._pendingPaymentTimeout)
	}
}

export default PaymentConfirmationController
