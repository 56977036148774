import React, { useContext } from 'react'
import ImaContext from 'ima/page/context'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import Layout from 'app/component/layout/LayoutView'
import PathSeparator, { getHomePath } from '../components/pathSeparator/PathSeparator'
import ROUTE_NAMES from 'app/base/RouteNames'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import ModelLineModificationsController from './ModelLineModificationsController'
import ModificationsList, { getYear } from './components/modificationsList/ModificationsList'
import { Button } from '@sznds/react'
import Gallery from 'app/component/gallery/GalleryView'
import CatalogImageResponsiveSettings from '../CatalogImageResponsiveSettings'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { Format } from '@inzeraty/helpers'
import { Loading } from '@inzeraty/components'
import { getMinMaxYears } from '../helper/modificationsHelper'
import classnames from 'classnames'
import Empty from '../components/empty/Empty'
import ModificationsPlaceholder from '../placeholders/modificationsPlaceholder/ModificationsPlaceholder'

import './ModelLineModificationsView.less'
import '../CatalogCS.json'

const CLASSNAME = 'p-uw-catalog-modifications'

const ModelLineModificationsView = (props) => {
	const {
		[ModelLineModificationsController.STATE_KEYS.MODIFICATIONS_LIST]: modificationsList,
		[ModelLineModificationsController.STATE_KEYS.ADVERTS_COUNT]: count,
		[ModelLineModificationsController.STATE_KEYS.LINK_URL]: linkUrl,
		[ModelLineModificationsController.STATE_KEYS.DATA_LOADED]: dataLoaded,
		[ModelLineModificationsController.STATE_KEYS.GENERATION_INFO]: generationInfo
	} = props

	const context = useContext(ImaContext)
	const router = context.$Router
	const localize = useLocalize()
	const fire = useFire()

	const hasModifications = dataLoaded && modificationsList && modificationsList.length > 0

	const getPaths = () => {
		if (dataLoaded) {
			const { brandName, modelName, brandSeoName, modificationName } = getModelName()

			return [
				getHomePath(localize, router),
				{
					name: brandName,
					url: router.link(ROUTE_NAMES.USERWEB.CATALOG_MODELS, {
						[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: brandSeoName
					})
				},
				{
					name: modelName,
					url: getGenerationLink()
				},
				{
					name: modificationName
				}
			]
		} else {
			return []
		}
	}

	const getGenerationLink = () => {
		const { brandSeoName, modelSeoName } = getModelName()

		return router.link(ROUTE_NAMES.USERWEB.CATALOG_MODEL_LINES, {
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: brandSeoName,
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME]: modelSeoName
		})
	}

	const getModelName = () => {
		const { name: modificationName = '', model = {} } = generationInfo || {}
		const { name: modelName, manufacturer = {}, seoName: modelSeoName } = model
		const { name: brandName, seoName: brandSeoName } = manufacturer

		return {
			brandName,
			modelName,
			brandSeoName,
			modelSeoName,
			modificationName
		}
	}

	const renderTitle = () => {
		if (dataLoaded) {
			if (hasModifications) {
				const { images = [] } = modificationsList ? modificationsList[0] || {} : {}
				const { brandName, modificationName } = getModelName()

				return (
					<h1
						className={classnames({
							[`${CLASSNAME}__header`]: true,
							[`${CLASSNAME}__header--no-image`]: images.length === 0
						})}
					>
						{brandName} {modificationName}
					</h1>
				)
			}
		} else {
			return <div className={`${CLASSNAME}__header-placeholder`}></div>
		}
	}

	const renderSubtitle = () => {
		if (hasModifications) {
			const actualYear = new Date().getFullYear()

			const { min, max } = getMinMaxYears(modificationsList)

			const yearTo = max === actualYear ? undefined : max

			return <div className={`${CLASSNAME}__subtitle`}>{getYear(localize, min, yearTo)}</div>
		}
	}

	const renderSearchButton = () => {
		const disabled = isNullOrUndefined(count) || count === 0 || !linkUrl

		return (
			hasModifications && (
				<Button
					primary={true}
					text={getButtonText()}
					className={`${CLASSNAME}__button`}
					href={linkUrl}
					disabled={disabled}
					onClick={() => fire('resetCache')}
					data-dot='go-to-adverts'
				/>
			)
		)
	}

	const getButtonText = (isPlaceholder = false) => {
		const renderLoadingDots = () => (
			<Loading
				className={`${CLASSNAME}__loading-dots`}
				loadingType={Loading.loadingType.ONLY_DOT}
			/>
		)

		if (isNullOrUndefined(count) || isPlaceholder) {
			return (
				<React.Fragment>
					{localize('Catalog.ShowAdvertsPrefix')}
					{renderLoadingDots()}
					{localize('Catalog.ShowAdvertsSuffix')}
				</React.Fragment>
			)
		} else {
			if (count > 0) {
				return localize('Catalog.search', {
					ADVERT_COUNT: count,
					COUNT_NICE: Format.number(count)
				})
			} else {
				return localize('Catalog.noResults')
			}
		}
	}

	const renderImageGallery = () => {
		if (dataLoaded) {
			if (hasModifications) {
				const { images = [] } = modificationsList ? modificationsList[0] || {} : {}

				if (images.length === 0) {
					return null
				} else {
					const { brandName, modificationName } = getModelName()

					const alt = `${brandName} ${modificationName}`
					const imagesWithAlt = images.map((image) => {
						return Object.assign({}, image, {
							alt
						})
					})

					return (
						<div className={`${CLASSNAME}__image`} data-dot='modifications'>
							<Gallery
								images={imagesWithAlt}
								previewImageResponsiveSettings={CatalogImageResponsiveSettings.previewSettings}
								normalImageResponsiveSettings={CatalogImageResponsiveSettings.normalSettings}
								fullscreenImageResponsiveSettings={
									CatalogImageResponsiveSettings.fullscreenSettings
								}
							/>
						</div>
					)
				}
			}
		} else {
			return <div className={`${CLASSNAME}__image-placeholder`}></div>
		}
	}

	const paths = getPaths()

	const renderContent = () => {
		if (dataLoaded) {
			if (hasModifications) {
				return <ModificationsList modificationsList={modificationsList} />
			} else {
				const { brandName = '', modelName = '' } = getModelName()
				const name = `${brandName} ${modelName}`

				return <Empty name={name} url={getGenerationLink()} />
			}
		} else {
			return <ModificationsPlaceholder />
		}
	}

	const renderHeaderContent = () => {
		if (dataLoaded) {
			if (hasModifications) {
				return (
					<div className={`${CLASSNAME}__header-image`}>
						{renderImageGallery()}
						<div className={`${CLASSNAME}__title-button`} data-dot='modifications'>
							{renderTitle()}
							{renderSubtitle()}
							{renderSearchButton()}
						</div>
					</div>
				)
			}
		} else {
			return renderHeaderImagePlaceholder()
		}
	}

	const renderHeaderImagePlaceholder = () => {
		return (
			<div className={`${CLASSNAME}__header-image`}>
				<div className={`${CLASSNAME}__image-placeholder`}></div>
				<div className={`${CLASSNAME}__title-button`}>
					<div className={`${CLASSNAME}__header-placeholder`}></div>
					<Button
						primary={true}
						text={getButtonText(true)}
						className={`${CLASSNAME}__button`}
						disabled={true}
					/>
				</div>
			</div>
		)
	}

	return (
		<Layout className={CLASSNAME}>
			<div className={`${CLASSNAME}__content`} data-dot='catalog'>
				<div className={`${CLASSNAME}__header-content`}>
					<PathSeparator paths={paths} dataLoaded={dataLoaded} />
					{renderHeaderContent()}
				</div>
				{renderContent()}
			</div>
		</Layout>
	)
}

export default ModelLineModificationsView
