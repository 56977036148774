import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocalize } from 'app/base/componentHelpers'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import { Button, Surface } from '@sznds/react'
import Modal from 'app/component/modal/ModalView'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import GetCert from '../getCert/GetCert'
import SaveCert from '../saveCert/SaveCert'

import './Cebia.less'
import './CebiaCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-history-cebia'

const Cebia = ({ advertEntity, submitSaveCertForm, verified }) => {
	const localize = useLocalize()

	const getCertModalRef = useRef(null)
	const addCertModalRef = useRef(null)

	const renderVerifiedContent = () => {
		return (
			<>
				<h2 className={`${CLASSNAME}__title-verified`}>{localize('Cebia.titleVerified')}</h2>
				<SvgIcon icon='LOGO_CEBIA' className={`${CLASSNAME}__logo-verified`} />
			</>
		)
	}

	const renderCreateCertificateContent = () => {
		return (
			<>
				<h2 className={`${CLASSNAME}__title-create`}>{localize('Cebia.titleCreateCertificate')}</h2>
				<div className={`${CLASSNAME}__for-box`}>
					<div className={`${CLASSNAME}__for-text`}>{localize('Cebia.for')}</div>
					<div className={`notranslate ${CLASSNAME}__sale`} translate='no' lang='cs-cz'>
						{localize('Cebia.sale', { CURRENCY: localize('Base.CZK') })}
					</div>
					<SvgIcon icon='LOGO_CEBIA' className={`${CLASSNAME}__logo-create`} />
				</div>
				<p className={`${CLASSNAME}__text`}>{localize('Cebia.text')}</p>

				<Modal
					renderOpener={(openerProps) => (
						<Button
							{...openerProps}
							surface={5}
							text={localize('Cebia.btnMore')}
							className={`${openerProps.className} ${CLASSNAME}__btn-more`}
						/>
					)}
					title={localize('Cebia.modalMoreTitle')}
					ref={getCertModalRef}
				>
					<GetCert advertEntity={advertEntity} />
				</Modal>

				<Modal
					renderOpener={(openerProps) => (
						<Button
							{...openerProps}
							surface={0}
							text={localize('Cebia.btnAddCert')}
							className={`${openerProps.className} ${CLASSNAME}__btn-add`}
							data-e2e='open-cebia-dialog'
						/>
					)}
					title={localize('Cebia.modalTitle')}
					ref={addCertModalRef}
				>
					<SaveCert submitSaveCertForm={submitSaveCertForm} />
				</Modal>
			</>
		)
	}

	return (
		<>
			<Surface
				className={classnames({
					[CLASSNAME]: true,
					[`${CLASSNAME}--create`]: !verified,
					[`${CLASSNAME}--verified`]: verified
				})}
				surface={5}
			>
				{verified && renderVerifiedContent()}
				{!verified && renderCreateCertificateContent()}
			</Surface>
		</>
	)
}

Cebia.propTypes = {
	verified: PropTypes.bool,
	advertEntity: PropTypes.shape(advertPropTypes).isRequired,
	submitSaveCertForm: PropTypes.func
}

Cebia.defultProps = {
	verified: false,
	submitSaveCertForm: DEFAULT_PROPS.FUNCTION
}

export default React.memo(Cebia)
