/**
 * @class SeoService
 * @namespace app.model.seo
 * @module app
 * @submodule app.model
 */
export default class SeoService {
	/**
	 * @method constructor
	 * @constructor
	 * @param {app.model.seo.SeoResource} SeoResource
	 */
	constructor(seoResource) {
		this._seoResource = seoResource
	}

	/**
	 * Vrati SEO linky pro paticku pro interni admin
	 *
	 * @metehod getSeoFooterLinks
	 * @param  {Object} params
	 * @param  {Object} options
	 * @return {Promise<Object>} Struktura SEO linku pro paticku
	 */
	getSeoFooterLinks(params = {}, options = {}) {
		return this._seoResource.getSeoFooterLinks(params, options).then((data) => {
			const { footer } = data
			return footer
		})
	}

	/**
	 * Vrati SEO linky pro paticku
	 *
	 * @metehod getCachedSeoFooterLinks
	 * @param  {Object} params
	 * @param  {Object} options
	 * @return {Promise<Object>} Struktura SEO linku pro paticku
	 */
	getCachedSeoFooterLinks(params = {}, options = {}) {
		return this._seoResource.getCachedSeoFooterLinks(params, options).then((data) => {
			const { footer } = data
			return footer
		})
	}

	/**
	 * Ulozi SEO linky pro paticku v internim adminu
	 *
	 * @metehod saveSeoFooterLinks
	 * @param  {Object} params
	 * @param  {Object} options
	 * @return {Promise<Object>} Struktura SEO linku pro paticku
	 */
	saveSeoFooterLinks(params = {}, options = {}) {
		return this._seoResource.putSeoFooterLinks(params, options)
	}
}
