import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Surface } from '@sznds/react'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Button } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'
import VINWidget, { VIN_WIDGET_ID } from './widgets/vin/VINWidget'
import CategoryWidget, { CATEGORY_WIDGET_ID } from './widgets/vehicleCategory/VehicleCategoryWidget'
import ConditionWidget, {
	CONDITION_WIDGET_ID
} from './widgets/vehicleCondition/VehicleConditionWidget'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'
import { getErrorDictionary } from 'app/page/userweb/newAdvert/addVIN/components/AddVINFormErrors'

import './AddVINForm.less'
import './AddVINFormCS.json'

const CLASSNAME = 'c-add-vin-form'

export const FORM_IDS = {
	VEHICLE_CATEGORY: 'category_id',
	VEHICLE_CONDITION: 'condition_cb',
	VEHICLE_VIN: 'vin'
}

const { SZNDS_WIDGET_BY_ID, Form } = FormLines

export const WIDGETS_BY_ID = Object.freeze(
	Object.assign(
		{
			[VIN_WIDGET_ID]: VINWidget,
			[CATEGORY_WIDGET_ID]: CategoryWidget,
			[CONDITION_WIDGET_ID]: ConditionWidget
		},
		SZNDS_WIDGET_BY_ID
	)
)

const getConditionDefaultValue = (conditions) => {
	if (conditions.length) {
		const conditionIndexDefault = conditions.findIndex((condition) => condition.id == 4588) // 4588 = Ojeté
		return conditions[conditionIndexDefault > -1 ? conditionIndexDefault : 0].value
	} else {
		return undefined
	}
}

getConditionDefaultValue.propTypes = {
	conditions: PropTypes.array
}

const AddVINForm = ({ submitVINForm, categories, conditions }) => {
	const localize = useLocalize()
	const [isFormSubmitting, setIsFormSubmitting] = useState(false)
	const [formLineEntities, setFormLineEntities] = useState(
		FormLines.createEntityList([
			{
				widget: CATEGORY_WIDGET_ID,
				id: FORM_IDS.VEHICLE_CATEGORY,
				label: localize('AddVINForm.vehicleCategoryLabel'),
				required: true,
				options: categories,
				value: categories.length ? categories[0].id : undefined
			},
			{
				widget: CONDITION_WIDGET_ID,
				id: FORM_IDS.VEHICLE_CONDITION,
				label: localize('AddVINForm.vehicleConditionLabel'),
				required: true,
				options: conditions,
				value: getConditionDefaultValue(conditions)
			},
			{
				widget: VIN_WIDGET_ID,
				id: FORM_IDS.VEHICLE_VIN,
				label: localize('AddVINForm.vinLabel'),
				placeholder: localize('AddVINForm.vinPlaceholder'),
				required: true
			}
		])
	)

	useEffect(() => {
		setFormLineEntities(
			FormLines.updateEntities(formLineEntities, [
				{
					id: FORM_IDS.VEHICLE_CATEGORY,
					options: categories,
					value: categories.length ? categories[0].id : undefined
				},
				{
					id: FORM_IDS.VEHICLE_CONDITION,
					options: conditions,
					value: getConditionDefaultValue(conditions)
				}
			])
		)
	}, [categories, conditions])

	const ERROR_DICTIONARY = getErrorDictionary(localize)

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			formLineEntities,
			{
				onChange
			},
			WIDGETS_BY_ID
		)
	}

	const renderSubmitButton = (props) => {
		return (
			<Button
				{...props}
				loading={isFormSubmitting}
				primary={true}
				text={localize('AddVINForm.submit')}
				className={`${CLASSNAME}__submit`}
				data-e2e='add-vin-form-submit-button'
			/>
		)
	}

	const renderFormContent = (renderWidgets, renderSubmitButton) => {
		return (
			<>
				<div className={`${CLASSNAME}__section`}>{renderWidgets()}</div>

				<div className={`${CLASSNAME}__section`}>
					<p className={`${CLASSNAME}__vin-short-description`}>
						{localize('AddVINForm.vinShortDescription')}
					</p>
					{renderSubmitButton()}
				</div>
			</>
		)
	}

	const onChange = (event, newValue, id) => {
		setFormLineEntities(
			FormLines.updateEntities(formLineEntities, [
				{
					id,
					value: newValue
				}
			])
		)
	}

	const onSubmit = () => {
		setIsFormSubmitting(true)

		const formData = FormLines.getFormData(formLineEntities)

		submitVINForm({
			formData,
			onErrorCallback: onSubmitError,
			onSuccessCallback: onSubmitSuccess
		})
	}

	const onSubmitSuccess = () => {
		setIsFormSubmitting(false)
	}

	const onSubmitError = (errors) => {
		const clearAllPreviousErrors = () =>
			formLineEntities.map(({ id }) => ({
				id,
				errorMessage: ''
			}))

		setIsFormSubmitting(false)

		const formLinesErrors = errors.reduce((processedErrors, newError) => {
			const { error_code: errorCode, fields } = newError

			const moreProcessedErrors = fields
				.map((id) => {
					// pokud se chyba vztahuje i na policko VIN, tak uz nechceme
					// zobrazovat chybovou hlasku i u kategorie a stavu vozidla
					const skip = fields.includes(FORM_IDS.VEHICLE_VIN) && id !== FORM_IDS.VEHICLE_VIN

					if (ERROR_DICTIONARY[id] && !skip) {
						return {
							id,
							errorMessage:
								ERROR_DICTIONARY[id][errorCode] || ERROR_DICTIONARY[id][ERROR_CODES.UNKNOWN]
						}
					} else {
						return undefined
					}
				})
				.filter((err) => !!err)

			return processedErrors.concat(moreProcessedErrors)
		}, clearAllPreviousErrors())

		setFormLineEntities(FormLines.updateEntities(formLineEntities, formLinesErrors))
	}

	return (
		<Surface surface={5} className={`${CLASSNAME}__wrapper`}>
			<Form
				className={CLASSNAME}
				formLineEntities={formLineEntities}
				isSending={isFormSubmitting}
				renderWidgets={renderWidgets}
				renderFormContent={renderFormContent}
				onSubmit={onSubmit}
				renderSubmitButton={renderSubmitButton}
			/>
		</Surface>
	)
}

AddVINForm.propTypes = {
	submitVINForm: PropTypes.func,
	categories: PropTypes.array,
	conditions: PropTypes.array
}

AddVINForm.defaultProps = {
	submitVINForm: DEFAULT_PROPS.FUNCTION,
	categories: DEFAULT_PROPS.ARRAY,
	conditions: DEFAULT_PROPS.ARRAY
}

export default React.memo(AddVINForm)
