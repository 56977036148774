
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['FormStatusMessage'] = {
  statusVINOK: function(d) { return "Vyplnili jsme za Vás informace o vozidle dle zadaného VINu."; },
  statusVINFailedPart1: function(d) { return "Vyplnili jsme za Vás informace o vozidle dle zadaného VINu, "; },
  statusVINFailedPart2: function(d) { return "ale nepodařilo se nám načíst " + plural(d.FAILED_FIELDS_COUNT, 0, cs, { one: d.FAILED_FIELDS_COUNT + " povinný údaj", few: d.FAILED_FIELDS_COUNT + " povinné údaje", other: d.FAILED_FIELDS_COUNT + " povinných údajů" }) + "."; },
  statusValidationErrorsPart1: function(d) { return "Formulář se nepodařilo odeslat. "; },
  statusValidationErrorsPart2: function(d) { return "Pro pokračování je nutné vyplnit nebo opravit " + plural(d.FAILED_FIELDS_COUNT, 0, cs, { one: d.FAILED_FIELDS_COUNT + " pole", few: d.FAILED_FIELDS_COUNT + " pole", other: d.FAILED_FIELDS_COUNT + " polí" }) + "."; },
  statusValidationErrorsUnknown: function(d) { return "Formulář se nepodařilo odeslat."; },
  statusCebiaDataLoading: function(d) { return "Načítáme data z Cebie: vyčkejte chvíli..."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	