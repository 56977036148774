import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'

export const CONTACTS_STATUS = deepFreeze({
	DEFAULT: 'default', // Defaultní stav widgetu.
	UNKNOWN: 'unknown', // Neznámá hodnota (potřebuje verifikaci).
	PIN_SENT: 'sent', // Je poslána zpráva s PINem.
	VERIFIED: 'verified', // Je ověřeno (z minula).
	PIN_EXPIRED: 'expired', // Zaslaný PIN ztratil platnost
	PIN_VERIFIED: 'pinVerified', // Zaslaný PIN ověřil telefon
	WRONG_PIN: 'wrongPin' // Neplatny PIN
})
