import React, { useState } from 'react'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'
import Layout from 'app/component/layout/LayoutView'
import { useLocalize } from 'app/base/componentHelpers'
import IMAGES from 'app/base/ImagesConstants'
import { Input, Button } from '@sznds/react'
import { CEBIA_BASE_URL } from 'app/base/Constants'

import './PromoCebia.less'
import './PromoCebiaCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'p-cebia'

const DEFAULT_CHECK_LINK =
	'https://cz.cebia.com/SmartCode/7BAooae77pBa74fNBaMjc2ot5xssKoaa3KB6ffHsKlRAqfAFoIoadUK7Mlo27Btaj0Fa7gaQMMFqKu3wKvaa93xS1aA5XeWCGU0Keo7IiKshF7PD9N?_gl=1*ehhcld*_ga*MjEzMjAwODc3Mi4xNjczNjIwNDg3*_ga_5Q3QPPQT6S*MTcwMjM4MTY4OC4zMjguMS4xNzAyMzgxNzAxLjAuMC4w'

const PromoCebia = () => {
	const localize = useLocalize()
	const keyWords = ['check1', 'check2', 'check3', 'check4']

	return (
		<Layout>
			<div className={CLASSNAME}>
				<BackToHpButton />
				<div className={`${CLASSNAME}__flex`}>
					<div className={`${CLASSNAME}__flex-items`}>
						<h1 className={`${CLASSNAME}__header-main`}>{localize('PromoCebia.mainHeader')}</h1>
						<p>{localize('PromoCebia.paragraph_1')}</p>
						<p>{localize('PromoCebia.paragraph_2')}</p>
						<a
							className={`${CLASSNAME}__link`}
							href={DEFAULT_CHECK_LINK}
							target='cebia'
							rel='noopener noreferrer'
						>
							{localize('PromoCebia.link_1')}
						</a>
						<p className={`${CLASSNAME}__header-sections`}>{localize('PromoCebia.listTitle')}</p>
						<ul className={`${CLASSNAME}__list`}>
							{keyWords.map((keyWord, index) => (
								<li key={index}>{localize(`PromoCebia.${keyWord}`)}</li>
							))}
						</ul>
					</div>
					<div className={`${CLASSNAME}__flex-items ${CLASSNAME}__cebia-certificate`}>
						<img
							className={`${CLASSNAME}__img`}
							src={IMAGES['image__check-cebia-provereni']}
							alt={localize('PromoCebia.imgAlt_1')}
						/>
					</div>
					<div className={`${CLASSNAME}__flex-items`}>
						<p>{localize('PromoCebia.paragraph_3')}</p>
						<p className={`${CLASSNAME}__header-sections`}>{localize('PromoCebia.header_1')}</p>
						<p>{localize('PromoCebia.paragraph_4')}</p>
						<p className={`${CLASSNAME}__certified-vehicles`}>
							<a className={`${CLASSNAME}__link`} href='/inzerce/osobni?overene=cebia'>
								{localize('PromoCebia.link_2')}
							</a>
						</p>
						<img
							className={`${CLASSNAME}__img`}
							src={IMAGES['image__check-cebia-filtr']}
							alt={localize('PromoCebia.imgAlt_2')}
						/>
						<p>{localize('PromoCebia.paragraph_5')}</p>
						<img
							className={`${CLASSNAME}__img`}
							src={IMAGES['image__check-cebia-vypis']}
							alt={localize('PromoCebia.imgAlt_3')}
						/>
						<p>{localize('PromoCebia.paragraph_6')}</p>
						<img
							className={`${CLASSNAME}__img`}
							src={IMAGES['image__check-cebia-history-free']}
							alt={localize('PromoCebia.imgAlt_4')}
						/>
						<p className={`${CLASSNAME}__header-secondary`}>{localize('PromoCebia.header_2')}</p>
						<p>{localize('PromoCebia.paragraph_7')}</p>
						<p>{localize('PromoCebia.paragraph_8')}</p>
						<ValidateVIN />
						<p>{localize('PromoCebia.paragraph_9')}</p>
						<img
							className={`${CLASSNAME}__img`}
							src={IMAGES['image__check-cebia-history']}
							alt={localize('PromoCebia.imgAlt_5')}
						/>
						<p className='notranslate' translate='no' lang='cs-cz'>
							{localize('PromoCebia.paragraph_10')}
						</p>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default PromoCebia

const ValidateVIN = () => {
	const localize = useLocalize()
	const [counterInput, setCounterInput] = useState(0)
	const [error, setError] = useState(false)
	const [VIN, setVIN] = useState('')
	const maxLengthVin = 17
	const url = `${CEBIA_BASE_URL}/?vin=${VIN}&utm_source=widget&utm_medium=tlacitko&utm_content=affiliate&utm_campaign=WG2017_bezP_sauto`

	const vinInputChange = (inputValue) => {
		setCounterInput(inputValue.length)
		setVIN(inputValue)
		setError(false)
	}

	return (
		<div className={`${CLASSNAME}__vin-validate`}>
			<p className={`${CLASSNAME}__vin-text`}>
				{localize('PromoCebia.vinValidateText')}{' '}
				<strong className='notranslate' translate='no' lang='cs-cz'>
					{localize('PromoCebia.vinValidateDiscount', { CURRENCY: localize('Base.CZK') })}
				</strong>
			</p>
			<div className={`${CLASSNAME}__interactive-section`}>
				<div className={`${CLASSNAME}__input-wrap`}>
					<Input
						placeholder={localize('PromoCebia.insertVinCode')}
						className={`${CLASSNAME}__input`}
						maxLength={maxLengthVin}
						onChange={(e) => {
							vinInputChange(e.target.value)
						}}
						error={error}
					/>
					<span className={`${CLASSNAME}__input-addon`}>
						{' '}
						{counterInput}/{maxLengthVin}
					</span>
					{error && (
						<span className={`${CLASSNAME}__error-vin`}>
							{localize('PromoCebia.validationError')}
						</span>
					)}
				</div>
				<Button
					size='x-medium'
					text={localize('PromoCebia.validate')}
					className={`${CLASSNAME}__button`}
					href={counterInput == maxLengthVin ? url : ''}
					target='_blank'
					rel='noopener'
					onClick={() => {
						setError(counterInput != maxLengthVin)
					}}
				/>
			</div>
		</div>
	)
}
