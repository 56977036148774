import { AbstractFactory } from '@inzeraty/models'
import ArticlesDetailEntity from './ArticlesDetailEntity'
export default class ArticlesDetailFactory extends AbstractFactory {
	/**
	 * @constructor
	 */
	constructor() {
		super(ArticlesDetailEntity)
	}

	/**
	 * Prevede detaily článků z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */

	transformDetailData(data = {}) {
		return {
			createDate: data.create_date,
			editDate: data.edit_date,
			filters: data.filters,
			id: data.id,
			isShown: data.is_shown,
			modelId: data.model_id,
			source: data.source,
			title: data.title,
			url: data.url,
			imageUrl: data.image_url,
			perex: data.perex
		}
	}
}
