import UserService from 'app/model/user/UserService'
import { CATEGORIES } from 'app/base/Constants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import MySavedSearchToUrlAppParamsConvertor from 'app/model/mySavedSearch/convertors/MySavedSearchToUrlAppParamsConvertor'
import MySavedSearchComparison from 'app/model/mySavedSearch/comparison/MySavedSearchComparison'
import MySavedSearchCache from './MySavedSearchCache'
import ROUTE_NAMES from 'app/base/RouteNames'

export const mySavedSearchesListToSummary = (mySavedSearches = []) => {
	return {
		mySavedSearchesCount: mySavedSearches.length,
		newAdvertsCount: mySavedSearches.reduce(
			(total, { newCount }) =>
				!isNullOrUndefined(newCount) && newCount > 0 ? total + newCount : total,
			0
		)
	}
}

export default class MySavedSearchHelper {
	constructor(watchDogService, userService) {
		this._watchDogService = watchDogService
		this._userService = userService

		this.getMySavedSearchesWithAdvertCounts = this.getMySavedSearchesWithAdvertCounts.bind(this)
		this.getMySavedSearch = this.getMySavedSearch.bind(this)
		this.isMySavedSearchAlreadySaved = this.isMySavedSearchAlreadySaved.bind(this)
		this.addMySavedSearch = this.addMySavedSearch.bind(this)
		this.updateMySavedSearch = this.updateMySavedSearch.bind(this)
		this.deleteMySavedSearch = this.deleteMySavedSearch.bind(this)

		this._getUserId = this._getUserId.bind(this)
	}

	async getMySavedSearchesSummary(options) {
		const userId = await this._getUserId()
		const cachedSummary = MySavedSearchCache.getSummary(userId)

		if (cachedSummary) {
			return cachedSummary
		} else {
			const mySavedSearches = await this.getMySavedSearchesWithAdvertCounts(
				{
					no_total_count: true
				},
				Object.assign({ cache: false }, options)
			)

			MySavedSearchCache.saveIntoCache(userId, mySavedSearches)

			return mySavedSearchesListToSummary(mySavedSearches)
		}
	}

	async getMySavedSearchesWithAdvertCounts(params, options) {
		const userId = await this._getUserId()

		const {
			watchDogs: mySavedSearches = []
		} = await this._watchDogService.getWatchDogsWithAdvertCounts(userId, params, options)

		MySavedSearchCache.saveIntoCache(userId, mySavedSearches)

		return mySavedSearches
	}

	async getMySavedSearch(mySavedSearchId, options) {
		const userId = await this._getUserId()

		return this._watchDogService.getWatchDog(userId, mySavedSearchId, options)
	}

	async isMySavedSearchAlreadySaved(mySavedSearchFilter = {}, isOperatingLease = false) {
		const userId = await this._getUserId()
		let mySavedSearches = MySavedSearchCache.getMySavedSearches(userId)

		if (!mySavedSearches) {
			mySavedSearches = await this.getMySavedSearchesWithAdvertCounts(
				{
					no_total_count: true
				},
				{ cache: false }
			)
		}

		return !!mySavedSearches.find(
			(mySavedSearch) =>
				mySavedSearch.operatingLease === isOperatingLease &&
				MySavedSearchComparison.areEqual(mySavedSearchFilter, mySavedSearch.filter)
		)
	}

	async addMySavedSearch(
		mySavedSearchFilter = {},
		notificationInterval,
		ribbonNotificationEnabled,
		isOperatingLease = false
	) {
		const getFilterApiParams = (urlParams = {}) => {
			const {
				[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName
			} = urlParams

			const category = Object.values(CATEGORIES).find(
				(category) => category.seoName === categorySeoName
			)

			if (category) {
				const filterParams = Object.assign(
					{},
					AdvertListUrlConvertor.getApiParams(
						urlParams,
						isOperatingLease
							? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
							: ROUTE_NAMES.USERWEB.ADVERT_LIST,
						false
					),
					{
						[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: category.id
					}
				)

				return filterParams
			}
		}

		const userId = await this._getUserId()

		const result = await this._watchDogService.addWatchDog(
			userId,
			Object.assign(
				{},
				getFilterApiParams(
					MySavedSearchToUrlAppParamsConvertor.convert({
						filter: mySavedSearchFilter
					})
				),
				{
					notification_interval: notificationInterval,
					notification_enabled: ribbonNotificationEnabled,
					operating_lease: isOperatingLease
				}
			)
		)

		const { body: resultBody } = result

		MySavedSearchCache.onMySavedSearchAdded(userId, {
			mySavedSearchId: resultBody.id,
			mySavedSearchHash: resultBody.watchdog_hash,
			mySavedSearchFilter,
			mySavedSearchIsOperatingLease: isOperatingLease
		})

		return result
	}

	async updateMySavedSearch(mySavedSearchId, notificationInterval, ribbonNotificationEnabled) {
		const userId = await this._getUserId()

		return this._watchDogService.updateWatchDog(userId, {
			id: mySavedSearchId,
			notification_interval: notificationInterval,
			notification_enabled: ribbonNotificationEnabled
		})
	}

	async deleteMySavedSearch(mySavedSearchId) {
		const userId = await this._getUserId()

		const result = await this._watchDogService.deleteWatchDog(userId, mySavedSearchId)

		MySavedSearchCache.onMySavedSearchDeleted(userId, mySavedSearchId)

		return result
	}

	async _getUserId() {
		const user = await this._userService.getUser()

		if (UserService.isUserLogged(user)) {
			const { id } = user

			return id
		} else {
			return undefined
		}
	}
}
