
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['CarTypes'] = {
  "title-osobni": function(d) { return "Vybrané typy vozů"; },
  "title-uzitkova": function(d) { return "Vybrané typy vozů"; },
  "title-nakladni": function(d) { return "Vybrané typy vozů"; },
  "title-motorky": function(d) { return "Vybrané typy motorek"; },
  "title-ctyrkolky": function(d) { return "Vybrané typy čtyřkolek"; },
  "title-privesy-navesy": function(d) { return "Vybrané typy přívěsů a návěsů"; },
  "title-obytne": function(d) { return "Vybrané typy obytných vozů"; },
  "title-pracovni-stroje": function(d) { return "Vybrané typy paracovních strojů"; },
  "title-autobusy": function(d) { return "Vybrané typy autobusů"; },
  "type-cheap": function(d) { return "Levné vozy do 50 000 " + d.CURRENCY; },
  "type-family": function(d) { return "Rodinné vozy"; },
  "type-offRoad": function(d) { return "Terénní vozy"; },
  "type-small": function(d) { return "Malé městské vozy"; },
  "type-managers": function(d) { return "Manažerské vozy"; },
  "type-sports": function(d) { return "Sportovní vozy"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	