import React from 'react'
import PropTypes from 'prop-types'
import premisePropTypes from 'app/model/premise/PremisePropTypes'
import PremiseService from 'app/model/premise/PremiseService'
import AbstractComponent from 'app/base/AbstractComponent'
import RouteNames from 'app/base/RouteNames'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import { Format } from '@inzeraty/helpers'
import { Icon } from '@sznds/react'
import { CHEVRON_RIGHT_OUTLINE_16 } from '@sznds/icons'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import Rating from 'app/component/rating/Rating'
import TextLink from 'app/component/textLink/TextLink'
import { CATEGORIES } from 'app/base/Constants'

import './SellerContactHeader.less'
import './SellerContactHeaderCS.json'

const CLASSNAME = 'c-seller-contact-header'

export default class SellerContactHeader extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			premiseEntity: PropTypes.shape(premisePropTypes).isRequired,
			premiseAdvertsCount: PropTypes.number,
			isDataLoaded: PropTypes.bool,
			isAdvertListLoaded: PropTypes.bool,
			isPremiseAdvertsCountLoaded: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			className: '',
			premiseEntity: DEFAULT_PROPS.OBJECT,
			isAdvertListLoaded: false,
			isPremiseAdvertsCountLoaded: false
		}
	}

	constructor(props, context) {
		super(props, context)
	}

	render() {
		const { className } = this.props

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__contact-header`]: true,
					[className]: !!className
				})}
			>
				<div className={`${CLASSNAME}__info`}>
					{this._renderSellectContactName()}
					<div className={`${CLASSNAME}__reviews-seller-link`}>
						{this._renderReviews()}
						{this._renderSellerLink()}
					</div>
				</div>
				{this._renderSellerLogo()}
			</div>
		)
	}

	/**
	 * Vyrenderuje nazev firmy / prodejce.
	 *
	 * @method _renderSellectContactName
	 * @private
	 * @return {HTMLElement}
	 */
	_renderSellectContactName() {
		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__title`]: true,
					[`${CLASSNAME}__title--plh`]: !this._isDataLoaded()
				})}
			>
				{!this._isDataLoaded()
					? null
					: this._isPremiseSeller()
					? this._renderPremiseSellerName()
					: this.localize('SellerContactHeader.regularSeller')}
			</div>
		)
	}

	/**
	 * Vyrenderuje nazev firmy
	 *
	 * @method _renderPremiseSellerName
	 * @private
	 * @return {String|HTMLElement}
	 */
	_renderPremiseSellerName() {
		const {
			premiseEntity: { name }
		} = this.props

		if (this._isSellerRouteUsed()) {
			return <h1>{name}</h1>
		} else {
			return (
				<a
					className={`${CLASSNAME}__premise-name`}
					href={this._getSellerUrl(RouteNames.USERWEB.SELLER)}
					data-dot='go-to-seller-detail'
					onClick={() => this.fire('resetCache')}
				>
					{name}
				</a>
			)
		}
	}

	/**
	 * Vyrenderuje logo firmy.
	 *
	 * @method _renderSellerLogo
	 * @private
	 * @return {HTMLElement}
	 */
	_renderSellerLogo() {
		if (this._isPremiseSeller()) {
			const {
				premiseEntity: { logo, name }
			} = this.props
			const isLoaded = this._isDataLoaded()

			return (
				<div
					className={this.cssClasses({
						[`${CLASSNAME}__logo-wrapper`]: true,
						[`${CLASSNAME}__logo-wrapper--plh`]: !isLoaded,
						[`${CLASSNAME}__logo-wrapper--empty`]: isLoaded && !logo
					})}
				>
					{isLoaded && logo && (
						<img
							className={`${CLASSNAME}__logo`}
							alt={this.localize('SellerContactHeader.companyLogo', { NAME: name })}
							src={logo}
						/>
					)}
				</div>
			)
		}
	}

	_renderReviews() {
		if (this._isPremiseSeller()) {
			const { premiseEntity } = this.props
			const { reviews, id } = premiseEntity

			const anchor = reviews ? 'hodnoceni' : 'pridat-hodnoceni'

			const reviewLink = `https://www.firmy.cz/detail/${id}#${anchor}`

			if (reviews) {
				const { count, stars } = reviews
				return <Rating count={count} stars={stars} reviewLink={reviewLink} />
			} else {
				return (
					<TextLink href={reviewLink} className={`${CLASSNAME}__review-link`} newTab={true}>
						{this.localize('SellerContactHeader.reviewLink')}
					</TextLink>
				)
			}
		}
	}

	/**
	 * Vyrenderuje odkaz na stranku prodejce.
	 *
	 * @method _renderSellerLink
	 * @private
	 * @return {HTMLElement}
	 */
	_renderSellerLink() {
		if (this._isPremiseSeller()) {
			const { premiseAdvertsCount, isPremiseAdvertsCountLoaded } = this.props
			const sellerLink = this._getSellerUrl()
			const isSellerRouteUsed = this._isSellerRouteUsed()

			const isLoading =
				!this._isDataLoaded() || (!isSellerRouteUsed && !isPremiseAdvertsCountLoaded)

			const linkText =
				!isSellerRouteUsed && premiseAdvertsCount > 1
					? this.localize('SellerContactHeader.otherSellerOffers', {
							AMOUNT: premiseAdvertsCount - 1,
							FORMATTED: Format.number(premiseAdvertsCount - 1)
					  })
					: this.localize('SellerContactHeader.showSellerProfile')

			if (!isSellerRouteUsed) {
				return (
					<div
						className={this.cssClasses({
							[`${CLASSNAME}__link-wrapper`]: true,
							[`${CLASSNAME}__link-wrapper--plh`]: isLoading
						})}
					>
						{!isLoading && (
							<a
								className={`${CLASSNAME}__link`}
								href={sellerLink}
								data-dot='go-to-seller-detail'
								onClick={() => this.fire('resetCache')}
							>
								<span>{linkText}</span>
								<Icon symbol={CHEVRON_RIGHT_OUTLINE_16} className={`${CLASSNAME}__link-icon`} />
							</a>
						)}
					</div>
				)
			} else {
				return null
			}
		}
	}

	/**
	 * Vrati URL stranky prodejce
	 *
	 * @method _getSellerUrl
	 * @private
	 * @return {String}
	 */
	_getSellerUrl(routeNameOverride) {
		const { isOperatingLease, premiseEntity = {} } = this.props
		const { seoName } = premiseEntity
		const { params } = this.utils.$Router.getCurrentRouteInfo()

		const {
			[UrlConvertor.constants.URL_APP_PARAMS.SORT]: sorting,
			[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: category,
			[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categoryName
		} = params

		const routeName =
			routeNameOverride ||
			(isOperatingLease
				? RouteNames.USERWEB.SELLER_WITH_OPERATING_LEASES
				: RouteNames.USERWEB.SELLER)

		return this.link(
			routeName,
			Object.assign(
				{},
				{
					[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_SEO_NAME]: seoName,

					//pokud je k dispozici OLD ID, tak uprednostnujeme pouzivani OLD ID
					[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID]: PremiseService.getPremiseId(
						premiseEntity
					)
				},
				categoryName && categoryName !== CATEGORIES.PASSENGER_CARS.seoName
					? {
							[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: categoryName
					  }
					: {},
				this._isSellerRouteUsed() && sorting
					? {
							[UrlConvertor.constants.URL_APP_PARAMS.SORT]: sorting
					  }
					: {},
				this._isSellerRouteUsed() && category
					? {
							[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: category
					  }
					: {},
				this._isSellerRouteUsed() ? params : {}
			)
		)
	}

	/**
	 * Zjisti, zda jsou data nacteny.
	 *
	 * @method _isDataLoaded
	 * @private
	 * @return {Boolean}
	 */
	_isDataLoaded() {
		const { isDataLoaded } = this.props
		return isDataLoaded
	}

	/**
	 * Zjisti, zda dany inzerat je od firmy.
	 *
	 * @method _isPremiseSeller
	 * @private
	 * @return {Boolean}
	 */
	_isPremiseSeller() {
		const { premiseEntity } = this.props

		return !!premiseEntity.id
	}

	/**
	 * Vraci true, pokud je vybrana routa pro detail prodejce.
	 *
	 * @method _isSellerRouteUsed
	 * @private
	 * @return {Boolean}
	 */
	_isSellerRouteUsed() {
		const routeInfo = this.utils.$Router.getCurrentRouteInfo()
		const routeName = routeInfo.route._name

		return (
			routeName === RouteNames.USERWEB.SELLER ||
			routeName === RouteNames.USERWEB.SELLER_WITH_OPERATING_LEASES ||
			routeInfo.params.component === 'seller-detail'
		)
	}
}
