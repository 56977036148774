import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import ReviewsExtension from './ReviewsExtension'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

export default class ReviewsUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			DEFAULTS: {
				REVIEW_TYPE: ReviewsExtension.TYPE.ALL,
				PER_PAGE: 4
			},

			URL_APP_PARAMS: {
				CATEGORY_ID: 'categoryId',
				MANUFACTURER_CB_ID: 'manufacturerCbId',
				MODEL_CB_ID: 'modelCbId',
				MANUFACTURING_YEAR: 'manufacturingYear',
				FUEL_CB_ID: 'fuelCbId',
				ENGINE_POWER: 'enginePower',
				REVIEW_TYPE: 'reviewType'
			},

			URL_API_PARAMS: {
				CATEGORY_ID: 'category_id',
				MANUFACTURER_CB_ID: 'manufacturer_cb',
				MODEL_CB_ID: 'model_cb',
				MANUFACTURING_YEAR: 'manufacturing_year',
				FUEL_CB_ID: 'fuel_cb',
				ENGINE_POWER: 'engine_power',
				REVIEW_ID: 'id',
				REVIEW_TITLE: 'title',
				REVIEW_TYPE: 'type',
				REVIEW_DESCRIPTION: 'review',
				REVIEW_POSITIVES: 'positives',
				REVIEW_NEGATIVES: 'negatives',
				REVIEW_REPLY: 'review_reply'
			},
			SORT: {
				CREATE: '-create_date'
			}
		})
	}

	/**
	 * Prevede parametry z url na parametry pro API.
	 *
	 * @static
	 * @method getApiParams
	 * @param {Object} params Parametry z url.
	 * @return {Object} Parametry pro API.
	 */
	static getApiParams(params = {}) {
		const appParams = Object.assign({}, params)
		const apiParams = {}

		const { URL_APP_PARAMS: URL_CONVERTOR_APP_PARAMS } = UrlConvertor.constants

		const { URL_APP_PARAMS, URL_API_PARAMS } = ReviewsUrlConvertor.constants

		// parametry jednoduché transformace (transformace názvu parametru)
		const transformParamIds = [
			'CATEGORY_ID',
			'MANUFACTURER_CB_ID',
			'MODEL_CB_ID',
			'MANUFACTURING_YEAR',
			'FUEL_CB_ID',
			'ENGINE_POWER',
			'REVIEW_TITLE',
			'REVIEW_TYPE',
			'REVIEW_DESCRIPTION',
			'REVIEW_POSITIVES',
			'REVIEW_NEGATIVES'
		]

		const transformedParams = transformParamIds
			.map((id) => ({ [URL_API_PARAMS[id]]: params[URL_APP_PARAMS[id]] }))
			.filter(
				(param) => typeof Object.values(param)[0] !== 'undefined' && Object.values(param)[0] !== ''
			)
			.reduce((accumulator, param) => Object.assign(accumulator, param), {})

		const apiPagingParams = UrlConvertor.getApiPaging(
			appParams[URL_CONVERTOR_APP_PARAMS.PAGE],
			appParams[URL_CONVERTOR_APP_PARAMS.PER_PAGE]
		)

		return Object.assign({}, apiParams, apiPagingParams, transformedParams, {
			[UrlConvertor.constants.URL_API_PARAMS.SORT]: ReviewsUrlConvertor.constants.SORT.CREATE
		})
	}
}
