import React from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import ResponsiveAutoComplete from '../ResponsiveAutoComplete'
import AutoCompleteOption from 'app/component/autoComplete/AutoCompleteOption'
import AutoCompleteFilteredForm from 'app/component/autoComplete/forms/filtered/AutoCompleteFilteredForm'
import AutoCompleteAllForm from 'app/component/autoComplete/forms/all/AutoCompleteAllForm'
import { sortModelsAlphabetically } from 'app/helpers/models/ModelsHelper'

import './ModelAutoCompleteCS.json'

const ModelAutoComplete = (props) => {
	const localize = useLocalize()

	const {
		placeholder = localize('ModelAutoComplete.placeholder'),
		popupTitle = localize('ModelAutoComplete.popupTitle'),
		popupPlaceholder = localize('ModelAutoComplete.popupPlaceholder')
	} = props

	const _renderItems = ({ items, inputValue, getItemProps, highlightedIndex }) => {
		const _renderItem = (item, index, isRenderedAsFavourite = false) => {
			const { value: modelValue, name } = item

			return (
				<AutoCompleteOption
					{...getItemProps({
						key: `${modelValue}__${isRenderedAsFavourite}`,
						index,
						item,
						isHighlighted: highlightedIndex === index
					})}
				>
					<span>{name}</span>
				</AutoCompleteOption>
			)
		}

		return inputValue ? (
			<AutoCompleteFilteredForm
				filteredItemsLabel={localize('ModelAutoComplete.labelFound')}
				filteredItems={sortModelsAlphabetically(items)}
				renderItem={_renderItem}
			/>
		) : (
			<AutoCompleteAllForm
				allItemsLabel={localize('ModelAutoComplete.labelAll')}
				items={sortModelsAlphabetically(items)}
				favoriteItemsLabel={localize('ModelAutoComplete.labelFavorite')}
				favoriteItems={sortModelsAlphabetically(items.filter((item) => item.isFavorite))}
				renderItem={_renderItem}
				renderFavoriteItem={(...args) => _renderItem(...args, true)}
			/>
		)
	}

	_renderItems.propTypes = {
		items: PropTypes.array,
		getItemProps: PropTypes.func,
		inputValue: PropTypes.string,
		highlightedIndex: PropTypes.number
	}

	const _renderDropdown = ({
		Dropdown,
		getDropdownProps,
		filteredItems,
		getItemProps,
		downshift
	}) => {
		const { inputValue, highlightedIndex } = downshift

		return (
			filteredItems.length > 0 && (
				<Dropdown {...getDropdownProps()}>
					{_renderItems({
						items: filteredItems,
						inputValue,
						getItemProps,
						highlightedIndex
					})}
				</Dropdown>
			)
		)
	}

	_renderDropdown.propTypes = {
		Dropdown: PropTypes.func,
		getDropdownProps: PropTypes.func,
		filteredItems: PropTypes.array,
		getItemProps: PropTypes.func,
		downshift: PropTypes.object
	}

	const _renderPopup = ({
		Popup,
		getPopupProps,
		PopupInput,
		getPopupInputProps,
		filteredItems,
		getItemProps,
		inputValue
	}) => {
		return (
			<Popup
				{...getPopupProps({
					title: popupTitle
				})}
			>
				<PopupInput
					{...getPopupInputProps({
						placeholder: popupPlaceholder
					})}
				/>

				{_renderItems({
					items: filteredItems,
					inputValue,
					getItemProps
				})}
			</Popup>
		)
	}

	_renderPopup.propTypes = {
		Popup: PropTypes.func,
		getPopupProps: PropTypes.func,
		PopupInput: PropTypes.func,
		getPopupInputProps: PropTypes.func,
		filteredItems: PropTypes.array,
		getItemProps: PropTypes.func,
		inputValue: PropTypes.string
	}

	return (
		<ResponsiveAutoComplete
			renderDropdown={_renderDropdown}
			renderPopup={_renderPopup}
			{...props}
			placeholder={placeholder}
		/>
	)
}

ModelAutoComplete.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	popupTitle: PropTypes.string,
	popupPlaceholder: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	size: PropTypes.string, // stejne jak v SDS: 'small' nebo 'regular'
	allItems: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			isFavorite: PropTypes.bool
		})
	),
	renderDropdown: PropTypes.func,
	renderPopup: PropTypes.func,
	onSelect: PropTypes.func,
	onSelectedItemDelete: PropTypes.func
}

export default React.memo(ModelAutoComplete)
