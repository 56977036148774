import React, { useContext, useState } from 'react'
import classnames from 'classnames'
import { Surface } from '@sznds/react'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { useFire } from 'app/base/componentHelpers'
import { Format } from '@inzeraty/helpers'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { CATEGORIES } from 'app/base/Constants'

import './InternalAdminMenu.less'

const CLASSNAME = 'c-ia-menu'

const getImportDateRouteParamFormat = (days = 0) => {
	const date = new Date()
	date.setDate(date.getDate() + days)

	const day = Format.padding(date.getDate())
	const month = Format.padding(date.getMonth() + 1)
	const year = date.getFullYear()

	return `${year}-${month}-${day}`
}

const MENU = [
	{
		id: 'adverts_tab',
		title: 'Inzeráty',
		sub: [
			{
				id: 'adverts',
				title: 'Inzeráty',
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST,
				requiredRights: [ADMIN_RIGHTS.ADVERTS_VIEW]
			},
			{
				id: 'adverts_reported',
				title: 'Nahlášené inzeráty',
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST_REPORTED,
				requiredRights: [ADMIN_RIGHTS.ADVERTS_REPORTS]
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_REPORT
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_HISTORY
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_PHOTOS
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_EQUIPMENT
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_HISTORY
			}
		]
	},
	{
		id: 'premises',
		title: 'Firmy',
		sub: [
			{
				id: 'premises-list',
				title: 'Výpis firem',
				route: ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_LIST,
				requiredRights: [ADMIN_RIGHTS.PREMISE_VIEW]
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_EDIT
			},
			{
				id: 'premises-soft-keys',
				title: 'Výpis softwarových klíčů',
				route: ROUTE_NAMES.INTERNAL_ADMIN.SOFTWARE_KEYS,
				requiredRights: [ADMIN_RIGHTS.PREMISE_VIEW]
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_HISTORY
			}
		]
	},
	{
		id: 'users',
		title: 'Uživatelé',
		sub: [
			{
				id: 'user-list',
				title: 'Výpis uživatelů',
				route: ROUTE_NAMES.INTERNAL_ADMIN.USER_LIST,
				requiredRights: [ADMIN_RIGHTS.USERS_VIEW]
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.USER_DETAIL,
				requiredRights: [ADMIN_RIGHTS.USERS_EDIT]
			}
		]
	},
	{
		id: 'importList',
		title: 'Importy',
		sub: [
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.IMPORT_LIST,
				requiredRights: [ADMIN_RIGHTS.PREMISE_IMPORT_VIEW],
				params: { date_from: getImportDateRouteParamFormat(-1) }
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.IMPORT_DETAIL,
				requiredRights: [ADMIN_RIGHTS.PREMISE_IMPORT_VIEW]
			}
		]
	},
	{
		id: 'admins',
		title: 'Admini',
		sub: [
			{
				id: 'admins-list',
				title: 'Výpis adminů',
				route: ROUTE_NAMES.INTERNAL_ADMIN.ADMIN_LIST,
				requiredRights: [ADMIN_RIGHTS.ADMINS_VIEW]
			},
			{
				id: 'role-list',
				title: 'Práva a role',
				route: ROUTE_NAMES.INTERNAL_ADMIN.ROLE_LIST,
				requiredRights: [ADMIN_RIGHTS.ROLES_VIEW]
			},
			{
				route: ROUTE_NAMES.INTERNAL_ADMIN.RIGHTS
			}
		]
	},
	{
		id: 'statistics',
		title: 'Statistiky',
		sub: [
			{
				id: 'statistics-list',
				title: 'Statistiky',
				route: ROUTE_NAMES.INTERNAL_ADMIN.STATISTICS,
				requiredRights: [ADMIN_RIGHTS.ADVERT_STATISTICS_VIEW]
			},
			{
				id: 'payments-list',
				title: 'Platby',
				route: ROUTE_NAMES.INTERNAL_ADMIN.PAYMENTS,
				requiredRights: [ADMIN_RIGHTS.ADVERT_STATISTICS_VIEW] // platby pracuji nad stejnym api jako obycejne statistiky, ponechame stejna prava
			}
		]
	},
	{
		id: 'products',
		title: 'Produkty',
		sub: [
			{
				id: 'registrations',
				title: 'Registrace',
				route: ROUTE_NAMES.INTERNAL_ADMIN.REGISTRATIONS_LIST,
				requiredRights: [ADMIN_RIGHTS.REGISTRATIONS_VIEW]
			},
			{
				id: 'regiatration-incrases',
				title: 'Navýšení registrací',
				route: ROUTE_NAMES.INTERNAL_ADMIN.REGISTRATION_INCRASES_LIST,
				requiredRights: [ADMIN_RIGHTS.REGISTRATIONS_VIEW]
			},
			{
				id: 'preferred-offers',
				title: 'Přednostní výpis',
				route: ROUTE_NAMES.INTERNAL_ADMIN.PREFERRED_OFFERS,
				requiredRights: [ADMIN_RIGHTS.PREFERRED_OFFERS_VIEW]
			},
			{
				id: 'mirroring',
				title: 'Zrcadlení inzerce',
				route: ROUTE_NAMES.INTERNAL_ADMIN.MIRRORING_LIST,
				requiredRights: [ADMIN_RIGHTS.MIRRORING_VIEW]
			}
		]
	},
	{
		id: 'reviews',
		title: 'Recenze',
		sub: [
			{
				id: 'reviewList',
				title: 'Výpis recenzí',
				route: ROUTE_NAMES.INTERNAL_ADMIN.REVIEW_LIST,
				requiredRights: [ADMIN_RIGHTS.REVIEWS_VIEW]
			},
			{
				id: 'replyList',
				title: 'Výpis odpovědí',
				route: ROUTE_NAMES.INTERNAL_ADMIN.REVIEW_REPLY_LIST,
				requiredRights: [ADMIN_RIGHTS.REVIEWS_VIEW]
			}
		]
	},
	{
		id: 'codebookList',
		title: 'Číselníky',
		sub: [
			{
				id: 'codebookEquipmentList',
				title: 'Výbava',
				route: ROUTE_NAMES.INTERNAL_ADMIN.CODEBOOK_EQUIPMENT_LIST,
				requiredRights: [ADMIN_RIGHTS.CODEBOOK_VALUES_EDIT]
			},
			{
				id: 'codebookManufacturerList',
				title: 'Výrobci',
				route: ROUTE_NAMES.INTERNAL_ADMIN.CODEBOOK_MANUFACTURER_LIST,
				requiredRights: [ADMIN_RIGHTS.CODEBOOK_VALUES_EDIT],
				params: {
					[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]: CATEGORIES.PASSENGER_CARS.id
				}
			},
			{
				id: 'codebookModelList',
				title: 'Modely',
				route: ROUTE_NAMES.INTERNAL_ADMIN.CODEBOOK_MODEL_LIST,
				requiredRights: [ADMIN_RIGHTS.CODEBOOK_VALUES_EDIT],
				params: {
					[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]: CATEGORIES.PASSENGER_CARS.id
				}
			}
		]
	},
	{
		id: 'feedback',
		title: 'Zpětná vazba',
		sub: [
			{
				id: 'feedback-list',
				title: 'Výpis zpětné vazby',
				route: ROUTE_NAMES.INTERNAL_ADMIN.FEEDBACK_LIST,
				requiredRights: [ADMIN_RIGHTS.SERVICE_FEEDBACK_VIEW]
			}
		]
	},
	{
		id: 'articles',
		title: 'Články',
		sub: [
			{
				id: 'articles-list',
				title: 'Výpis článků',
				route: ROUTE_NAMES.INTERNAL_ADMIN.ARTICLES_LIST,
				requiredRights: [ADMIN_RIGHTS.ARTICLES_VIEW]
			}
		]
	}
]

const InternalAdminMenu = () => {
	const { $Router, InternalAdminHelper } = useContext(Context)
	const { route } = $Router.getCurrentRouteInfo()
	const routeName = route.getName()
	const { rights = [], roleNameId = '' } = useContext(AdminEntityContext) || {}
	const fire = useFire()

	const menu = MENU.map((menuItem) => {
		// V tomto bloku se odfiltrují všechny sub položky, které nemají dostatečná oprávnění.
		const { sub = [] } = menuItem
		const newSub = sub.filter(({ route, requiredRights = [] }) => {
			const hasRights =
				!requiredRights.length || requiredRights.every((right) => rights.includes(right))
			const isRouteForbidden = InternalAdminHelper.getIsRouteForbidden(route, roleNameId)
			return !!route && hasRights && !isRouteForbidden
		})

		return Object.assign({}, menuItem, {
			sub: newSub,
			subRouteNames: newSub.map(({ route }) => route)
		})
	}).filter(({ route, requiredRights = [], sub = [], title = '' }) => {
		// V tomto bloku se odfiltrují všechny hlavní položky, které nemají podpoložky anebo nemají dostatečná oprávnění.
		const hasRights =
			(!requiredRights.length && !!title) || requiredRights.every((right) => rights.includes(right))
		// hlavni polozka nemusi mit definovanout route a v tom pripade prebira route z prvni podpolozky
		const isRouteForbidden = InternalAdminHelper.getIsRouteForbidden(
			route || (sub[0] ? sub[0].route : undefined),
			roleNameId
		)

		// pokud ma hlavni polozka definovanou route a ma opravneni
		// nebo jsou dostupne podpolozky, ktere maji prava
		// a neni omezeno na pravem roleId
		return ((!!route && hasRights) || !!sub.length) && !isRouteForbidden
	})

	const selectedMenu =
		menu.find(
			({ route, subRouteNames }) => route === routeName || subRouteNames.includes(routeName)
		) || {}
	const [openedMenuId, setOpenedMenuId] = useState(selectedMenu.id)

	const renderSubmenu = () => {
		const { sub = [] } = menu.find(({ id }) => id === openedMenuId) || {}
		let subItems = sub.filter(({ title }) => !!title)

		if (subItems.length === 1 && !selectedMenu.route) {
			subItems = []
		}

		return (
			!!subItems.length && (
				<ul className={`${CLASSNAME}__sub-menu`}>
					{subItems.map(({ id, title, route, onClick, params = {} }) => {
						const props = {
							key: id,
							className: classnames({
								[`${CLASSNAME}__sub-item`]: true,
								[`${CLASSNAME}__sub-item--selected`]: route === routeName
							})
						}

						if (route) {
							props.href = $Router.link(route, params)
						}
						if (onClick) {
							props.onClick = (event) => {
								onClick(event, fire)
							}
						}

						// eslint-disable-next-line react/jsx-key
						return <a {...props}>{title}</a>
					})}
				</ul>
			)
		)
	}

	return (
		<Surface className={`${CLASSNAME}`} surface={3}>
			<ul>
				{menu.map(({ id, title, route, params = {}, sub = [], subRouteNames = [] }) => {
					const hasSub = !!sub.length
					const props = {
						className: [`${CLASSNAME}__item-action`]
					}

					if (hasSub) {
						const [firstOpenableSub] = sub.filter(({ route }) => !!route)

						const { route, params } = firstOpenableSub

						Object.assign(props, {
							href: $Router.link(route, params),
							onClick: () => {
								setOpenedMenuId(id)
							}
						})
					} else {
						Object.assign(props, {
							href: $Router.link(route, params)
						})
					}

					return (
						<li
							key={id}
							className={classnames({
								[`${CLASSNAME}__item`]: true,
								[`${CLASSNAME}__item--selected`]:
									route === routeName || subRouteNames.includes(routeName),
								[`${CLASSNAME}__item--opened`]: id === openedMenuId
							})}
						>
							<a {...props}>{title}</a>
						</li>
					)
				})}
			</ul>

			{renderSubmenu()}
		</Surface>
	)
}

export default React.memo(InternalAdminMenu)
