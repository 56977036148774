import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import BasicWidget from 'app/page/userweb/newAdvert/component/widgets/basic/BasicWidget'
import BasePriceWidget, {
	PRICE_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/price/Price'

export { PRICE_WIDGET_ID }

const Price = (props) => {
	const { formLineEntity, onChange } = props

	return (
		<BasicWidget
			formLineEntity={formLineEntity}
			name={PRICE_WIDGET_ID}
			renderWidget={(formLineEntity, renderLabel, renderInput) => {
				return (
					<>
						<div className='c-basic-widget__label-box'>{renderLabel()}</div>
						<div className='c-basic-widget__content'>{renderInput()}</div>
					</>
				)
			}}
			renderInput={(formLineEntity, inputProps) => {
				return (
					<BasePriceWidget {...inputProps} formLineEntity={formLineEntity} onChange={onChange} />
				)
			}}
		/>
	)
}

Price.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(Price)
