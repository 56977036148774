import AbstractExtension from 'ima/extension/AbstractExtension'
import { CATEGORIES } from 'app/base/Constants'
import ROUTE_NAMES from 'app/base/RouteNames'
import RibbonSearchToUrlAppParamsConvertor from './RibbonSearchToUrlAppParamsConvertor'

export default class RibbonSearchExtension extends AbstractExtension {
	constructor(ribbonSearchService, categoryService, utils) {
		super()

		this._ribbonSearchService = ribbonSearchService
		this._categoryService = categoryService
		this._router = utils.$Router
		this._sessionStorage = utils.$SessionStorage
	}

	static get STATE_KEYS() {
		return {
			SUGGESTIONS: 'SUGGESTIONS'
		}
	}

	load(state = {}) {
		return Object.assign(
			{
				[RibbonSearchExtension.STATE_KEYS.SUGGESTIONS]: []
			},
			state
		)
	}

	getAllowedStateKeys() {
		return [RibbonSearchExtension.STATE_KEYS.SUGGESTIONS]
	}

	async onSuggest(params) {
		try {
			const suggestion = await this._ribbonSearchService.getSuggest(params)

			this.setState({
				[RibbonSearchExtension.STATE_KEYS.SUGGESTIONS]: suggestion
			})
		} catch (e) {
			//Na error nebudeme reagovat
		}
	}

	async onSearch(params) {
		try {
			const { phrase, category: categoryId } = params
			let category
			if (!categoryId) {
				category = await this.getCategoryWithMostAdvertCount(params)
			} else {
				category = Object.values(CATEGORIES).find((category) => category.id === categoryId)
			}

			if (!category) category = CATEGORIES.PASSENGER_CARS

			const filters = await this._ribbonSearchService.getDetectedFilters({
				category_id: category.id,
				phrase: phrase
			})

			this._router.redirect(
				this._router.link(
					ROUTE_NAMES.USERWEB.ADVERT_LIST,
					RibbonSearchToUrlAppParamsConvertor.convert(filters, category.seoName)
				)
			)
		} catch (e) {
			//Na error nebudeme reagovat
		}
	}

	async getCategoryWithMostAdvertCount(params) {
		try {
			const categories = await this._categoryService.getCategoriesIncludingAdvertsCount(params)
			let highestAdvertCount = -1
			let chosenCategory
			categories.forEach((category) => {
				if (category.advertsCount > highestAdvertCount) {
					highestAdvertCount = category.advertsCount
					chosenCategory = category
				}
			})
			return chosenCategory
		} catch (e) {
			return undefined
		}
	}
}
