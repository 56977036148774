import React, { useContext } from 'react'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import ErrorLayout from 'app/component/layout/error/ErrorLayout'
import ErrorPageBox from 'app/component/errorPageBox/ErrorPageBoxView'
import ErrorPageBoxDescription from 'app/component/errorPageBox/ErrorPageBoxDescription'
import ErrorPageBoxButton from 'app/component/errorPageBox/ErrorPageBoxButton'
import ROUTE_NAMES, { isClientAdminRoute } from 'app/base/RouteNames'
import WEB_TYPE from 'app/base/WebType'
import Context from 'ima/page/context'
import PropTypes from 'prop-types'
import { HttpStatusCodes } from '@inzeraty/helpers'
import ActionElm from 'app/component/actionElement/ActionElement'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import './Error.less'
import './ErrorCS.json'

const CLASSNAME = 'p-error'

const RegisterNote = ({ registerSourceComponent }) => {
	const { $Dictionary } = useContext(Context)

	return (
		<p className={`${CLASSNAME}__info`}>
			{$Dictionary.get('Error.registerSentence')}
			<ActionElm
				className={`${CLASSNAME}__register`}
				onClick={() => {
					LoginHelper.openRegister({ sourceComponent: registerSourceComponent })
				}}
			>
				{$Dictionary.get('Error.register')}
			</ActionElm>
		</p>
	)
}

RegisterNote.propTypes = {
	registerSourceComponent: PropTypes.string
}
RegisterNote.defaultProps = {
	registerSourceComponent: ''
}

const Error = ({ status }) => {
	const { $Dictionary, $Router } = useContext(Context)
	let route = ''
	let routeOptions
	let routeParams

	try {
		const { route: routeInfo, params } = $Router.getCurrentRouteInfo()
		route = routeInfo
		routeOptions = routeInfo.getOptions()
		routeParams = params
	} catch (e) {
		// pre kontroler 500 alebo 4xx moze vyhodit chybu
	}

	const routeName = route ? route.getName() : ''
	const isInternalAdmin = routeOptions ? routeOptions.webType === WEB_TYPE.INTERNAL_ADMIN : false

	const pageName = getPageName(routeName, $Dictionary, status)
	let pageTitle = ''
	let pageDescription = ''
	let buttonProps = null
	let showRegisterNote = false
	const { sourceComponent, registerSourceComponent } = getSourceComponent(routeName)

	switch (status) {
		case HttpStatusCodes.SERVER_ERROR:
			pageTitle = $Dictionary.get('Error.errorTitle500')
			pageDescription = $Dictionary.get('Error.description')
			buttonProps = {
				text: $Dictionary.get('Error.buttonText'),
				href: $Router.link(ROUTE_NAMES.USERWEB.HOMEPAGE)
			}
			break

		case HttpStatusCodes.UNAUTHORIZED:
			pageTitle = $Dictionary.get('Error.errorTitle401', { PAGE_NAME: pageName })
			buttonProps = {
				text: $Dictionary.get('Error.login'),
				onClick: () => {
					if (isInternalAdmin) {
						// odmazeme 'code' parametr z URL, tim znovu vynutime presmerovani na
						// seznamacky auth server a tedy prihlaseni
						const urlParams = Object.assign({}, routeParams)
						delete urlParams[UrlConvertor.constants.URL_APP_PARAMS.CODE]

						if (routeName) {
							$Router.redirect($Router.link(routeName, urlParams))
						}
					} else {
						LoginHelper.openLogin({ sourceComponent })
					}
				}
			}
			showRegisterNote = !isInternalAdmin
			break

		case HttpStatusCodes.FORBIDDEN:
			pageTitle = $Dictionary.get('Error.errorTitle403', { PAGE_NAME: pageName })
			buttonProps = {
				text: $Dictionary.get('Error.login'),
				onClick: () => {
					LoginHelper.openLogin({ sourceComponent })
				}
			}
			showRegisterNote = true
			break

		case HttpStatusCodes.NOT_FOUND:
		default:
			pageTitle = $Dictionary.get('Error.errorTitle404')
			pageDescription = $Dictionary.get('Error.description')
			buttonProps = {
				text: $Dictionary.get('Error.buttonText'),
				href: $Router.link(ROUTE_NAMES.USERWEB.HOMEPAGE)
			}
			break
	}

	return (
		<ErrorLayout>
			<ErrorPageBox title={pageTitle}>
				{pageDescription && <ErrorPageBoxDescription>{pageDescription}</ErrorPageBoxDescription>}
				{buttonProps && <ErrorPageBoxButton {...buttonProps} />}
				{showRegisterNote && <RegisterNote registerSourceComponent={registerSourceComponent} />}
			</ErrorPageBox>
		</ErrorLayout>
	)
}

function getPageName(routeName, $Dictionary, status) {
	switch (routeName) {
		case ROUTE_NAMES.CLIENT_ADMIN.BOARD_BEHIND_WINDSCREEN:
			return $Dictionary.get('Error.pageNameBoardBehindWindscreen')
		case ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST:
			return $Dictionary.get('Error.pageNameClientAdvertList')
		case ROUTE_NAMES.USERWEB.MY_SAVED_SEARCH:
			return status === HttpStatusCodes.UNAUTHORIZED
				? $Dictionary.get('Error.pageNameMySaveSearch401')
				: $Dictionary.get('Error.pageNameMySaveSearch')
		case ROUTE_NAMES.CLIENT_ADMIN.CLIENT_PROFILE:
			return $Dictionary.get('Error.pageNameProfile')
		case ROUTE_NAMES.USERWEB.FAVORITE_LIST:
			return $Dictionary.get('Error.pageNameFavorite')
		case ROUTE_NAMES.CLIENT_ADMIN.CLIENT_STATISTICS:
			return $Dictionary.get('Error.pageNameStatistics')
		default:
			return $Dictionary.get('Error.pageNameDefault')
	}
}

function getSourceComponent(routeName) {
	let sourceComponent = ''
	let registerSourceComponent = ''

	switch (routeName) {
		case ROUTE_NAMES.USERWEB.MY_SAVED_SEARCH:
			sourceComponent = LoginHelper.SOURCE_COMPONENT.SAVED_SEARCH_SHOW
			registerSourceComponent = LoginHelper.SOURCE_COMPONENT.SAVED_SEARCH_SHOW
			break

		case ROUTE_NAMES.USERWEB.FAVORITE_LIST:
			sourceComponent = LoginHelper.SOURCE_COMPONENT.FAVORITES_SHOW
			registerSourceComponent = LoginHelper.SOURCE_COMPONENT.FAVORITES_SHOW
			break

		default:
			sourceComponent = isClientAdminRoute(routeName)
				? LoginHelper.SOURCE_COMPONENT.SELLER_LOGIN
				: ''
			registerSourceComponent = isClientAdminRoute(routeName)
				? LoginHelper.SOURCE_COMPONENT.SELLER_REGISTER
				: ''
			break
	}

	return {
		sourceComponent,
		registerSourceComponent
	}
}

Error.propTypes = {
	status: PropTypes.number
}

Error.defaultProps = {
	status: 404
}

export default Error
