export const FORM_LINES_IDS = {
	ADDITIONAL_MODEL_NAME: 'additional_model_name',
	AIRBAGS: 'airbags',
	AIRCONDITION_CB: 'aircondition_cb',
	AVAILABILITY_CB: 'availability_cb',
	AVERAGE_ELECTRIC_MILEAGE: 'average_electric_mileage',
	AVERAGE_GAS_MILEAGE: 'average_gas_mileage',
	BATTERY_CAPACITY: 'battery_capacity',
	BEDS: 'beds',
	CAPACITY: 'capacity',
	CEBIA_REPORT: 'cebia_report',
	CERTIFICATION_CB: 'certification_cb',
	COLOR_AND_TONE_CB: 'color_and_tone_cb',
	COLOR_CB: 'color_cb',
	COLOR_TONE_CB: 'color_tone_cb',
	COLOR_TYPE_CB: 'color_type_cb',
	CONDITION_CB: 'condition_cb',
	COUNTRY_OF_ORIGIN_CB: 'country_of_origin_cb',
	DEAL_TYPE: 'deal_type',
	DELIVERY_DATE: 'delivery_date',
	DESCRIPTION: 'description',
	DONT_SHOW_PHONE: 'dont_show_phone',
	DOORS_AND_CAPACITY: 'doors_and_capacity',
	DOORS: 'doors',
	DOORS_AND_SEATS: 'doors_and_seats',
	SEATS_CB: 'seats_cb',
	DRIVE_CB: 'drive_cb',
	EMAIL: 'email',
	ENGINE_POWER: 'engine_power',
	ENGINE_VOLUME: 'engine_volume',
	EQUIPMENT_CB: 'equipment_cb',
	FUEL_CB: 'fuel_cb',
	GEARBOX_CB: 'gearbox_cb',
	GEARBOX_LEVELS_CB: 'gearbox_levels_cb',
	IN_OPERATION_DATE: 'in_operation_date',
	LOAD_CAPACITY: 'load_capacity',
	LOCALITY: 'locality',
	MACHINE_HOURS: 'machine_hours',
	MANUFACTURER_CB: 'manufacturer_cb',
	MANUFACTURING_DATE: 'manufacturing_date',
	MODEL_CB: 'model_cb',
	OPERATING_LEASE_INTENDED_FOR_CB: 'operating_lease_intended_for_cb',
	OUT_OF_ORDER_DATE: 'out_of_order_date',
	PHONE: 'phone',
	PHOTO: 'photo',
	PRICE: 'price',
	PRICE_IS_VAT_DEDUCTIBLE: 'price_is_vat_deductible',
	PRICE_IS_WITHOUT_VAT: 'price_is_without_vat',
	PRICE_LEASING: 'price_leasing',
	PRICE_NOTE: 'price_note',
	PRICE_PAYMENT: 'price_payment',
	PRICE_PAYMENT_COUNT: 'price_payment_count',
	STK_DATE: 'stk_date',
	TACHOMETER: 'tachometer',
	TERMS_OF_USE: 'terms_of_use',
	VEHICLE_BODY_CB: 'vehicle_body_cb',
	VEHICLE_RANGE: 'vehicle_range',
	VIDEO: 'video',
	VIN: 'vin',
	WEIGHT: 'weight',
	WINDSHIELD_NOTE: 'windshield_note'
}

// nektere formlines slucuji vice inputu do sebe, takze pak jsme schopni
// je vykreslit ve formulari na jednom radku. Tento objekt drzi
// asociace mezi temito sloucenymi a obycejnymi formlines.
export const MERGED_FORM_LINES_IDS = {
	[FORM_LINES_IDS.COLOR_AND_TONE_CB]: [FORM_LINES_IDS.COLOR_CB, FORM_LINES_IDS.COLOR_TONE_CB],
	[FORM_LINES_IDS.DOORS_AND_CAPACITY]: [FORM_LINES_IDS.DOORS, FORM_LINES_IDS.CAPACITY],
	[FORM_LINES_IDS.DOORS_AND_SEATS]: [FORM_LINES_IDS.DOORS, FORM_LINES_IDS.SEATS_CB]
}
