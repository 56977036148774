import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	ResponsiveContainer,
	LineChart,
	CartesianGrid,
	Tooltip,
	Line,
	XAxis,
	YAxis,
	Dot
} from 'recharts'
import classnames from 'classnames'
import { Surface } from '@sznds/react'
import Select from 'app/component/select/Select'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import AdvertStatisticsEntity from 'app/model/advert/statistics/AdvertStatisticsEntity'
import { useLocalize } from 'app/base/componentHelpers'
import { Format } from '@inzeraty/helpers'
import MetricsDataHelper from '../MetricsDataHelper'

import './ClientStatisticsGraph.less'
import '../../ClientStatisticsCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-kpi-graph'

const metrics = [
	{
		id: 'listView',
		color: '#0099E9'
	},
	{
		id: 'replySent',
		color: '#E3BCA6'
	},
	{
		id: 'phoneView',
		color: '#AFE3A6'
	},
	{
		id: 'detailView',
		color: '#E3A6A6'
	},
	{
		id: 'detailViewFromList',
		color: '#E3E1A6'
	},
	{
		id: 'detailViewFromOther',
		color: '#E3A6DD'
	},
	{
		id: 'POView',
		color: '#A6E3D8'
	},
	{
		id: 'detailViewFromPO',
		color: '#A6CEE3'
	}
]

const compareSuffix = 'Compare'

const priceFormated = (price) => {
	return !isNullOrUndefined(price) ? Format.number(price / 100, 2) : price
}

const getGraphItemData = (isOperatingLease, isMirroring, groupBy = {}, metrics = {}) => ({
	// osa x
	date: groupBy.name,
	// osy y
	// (at uz pracujeme s daty pro prodej, operak nebo zrcadleni, tak data pro graf se jmenuji porad stejne)
	listView: MetricsDataHelper.getListViewMetric(isOperatingLease, isMirroring, metrics) || 0,
	replySent: MetricsDataHelper.getReplySentMetric(isOperatingLease, isMirroring, metrics) || 0,
	phoneView: MetricsDataHelper.getPhoneViewMetric(isOperatingLease, isMirroring, metrics) || 0,
	detailView: MetricsDataHelper.getDetailViewMetric(isOperatingLease, isMirroring, metrics) || 0,
	detailViewFromList:
		MetricsDataHelper.getDetailViewFromListMetric(isOperatingLease, isMirroring, metrics) || 0,
	detailViewFromOther:
		MetricsDataHelper.getDetailViewFromOtherMetric(isOperatingLease, isMirroring, metrics) || 0,
	POView: !isOperatingLease && !isMirroring ? metrics.POView || 0 : 0,
	detailViewFromPO: !isOperatingLease && !isMirroring ? metrics.detailViewFromPO || 0 : 0,
	topped: metrics.topped || 0,
	toppedPriceSum: metrics.toppedPriceSum || 0,
	toppedPriceSumWithoutVat: metrics.toppedPriceSumWithoutVat || 0,
	toppedCount: metrics.toppedCount || 0
})

const ClientStatisticsGraph = ({
	isOperatingLease = false,
	isMirroring = false,
	advertStatisticEntities = DEFAULT_PROPS.ARRAY,
	advertStatisticEntitiesCompare = DEFAULT_PROPS.ARRAY
}) => {
	const localize = useLocalize()

	const [activeMetricId, setActiveMetricId] = useState(metrics[0].id)

	const data = advertStatisticEntities.map(({ groupBy, metrics }) =>
		getGraphItemData(isOperatingLease, isMirroring, groupBy, metrics)
	)

	for (
		let i = 0;
		i < Math.min(advertStatisticEntities.length, advertStatisticEntitiesCompare.length);
		i++
	) {
		const { groupBy, metrics } = advertStatisticEntitiesCompare[i]

		const graphItem = getGraphItemData(isOperatingLease, isMirroring, groupBy, metrics)

		for (const [key, value] of Object.entries(graphItem)) {
			data[i][`${key}${compareSuffix}`] = value
		}
	}

	const getActualData = (actualDate) => {
		const values = Object.values(data)
		return values.find(({ date }) => actualDate === date)
	}

	const getWidthForYAxis = () => {
		const SMALL_NUMBER_THRESHOLD = 100000
		const MEDIUM_NUMBER_THRESHOLD = 100 * SMALL_NUMBER_THRESHOLD

		const maxNumberToDisplay = Math.max(
			...data.map((item) => {
				const value = item[activeMetricId]
				const valueCompare = item[`${activeMetricId}${compareSuffix}`]

				return !isNullOrUndefined(valueCompare) ? Math.max(value, valueCompare) : value
			})
		)

		if (maxNumberToDisplay < SMALL_NUMBER_THRESHOLD) {
			return 55
		} else if (maxNumberToDisplay < MEDIUM_NUMBER_THRESHOLD) {
			return 65
		} else {
			return 80
		}
	}

	const items = metrics.map(({ id }) => ({
		name: localize(`ClientStatistics.${id}`),
		id
	}))

	return (
		<Surface surface={4} className={CLASSNAME}>
			<div className={`${CLASSNAME}__header`}>{localize('ClientStatistics.graphHeader')}</div>
			<div className={`${CLASSNAME}__select-wrap`}>
				<Select
					onSelect={(selectedItem = {}) => setActiveMetricId(selectedItem.id)}
					selectedItem={items.find((item) => item.id === activeMetricId) || null}
					items={items}
					size='small'
				/>
			</div>
			{/* IE11 - potrebuje mit graf obaleny */}
			<div>
				<ResponsiveContainer width='100%' aspect={2.5 / 1}>
					<LineChart data={data} margin={{ top: 0, right: 8, bottom: 0, left: 0 }}>
						<CartesianGrid stroke='#E0E0E0' />
						<Tooltip
							content={({ label }) => {
								const actualData = getActualData(label)

								return (
									<div className={`${CLASSNAME}__tooltip`}>
										{actualData && (
											<>
												<div>{actualData.date}</div>
												<div>
													{localize(`ClientStatistics.${activeMetricId}`)}:{' '}
													{actualData[activeMetricId]?.toLocaleString()}
												</div>
												{Boolean(actualData.toppedCount) && (
													<ToppedCountInfo count={actualData.toppedCount} />
												)}
												{Boolean(actualData.toppedPriceSum) && (
													<ToppedPriceInfo price={actualData.toppedPriceSum} />
												)}
												{typeof actualData.dateCompare !== 'undefined' && (
													<div className={`${CLASSNAME}__compare`}>
														<div>{actualData.dateCompare}</div>
														<div>
															{localize(`ClientStatistics.${activeMetricId}`)}:{' '}
															{actualData[`${activeMetricId}${compareSuffix}`]?.toLocaleString()}
															{Boolean(actualData.toppedCountCompare) && (
																<ToppedCountInfo count={actualData.toppedCountCompare} />
															)}
															{Boolean(actualData.toppedPriceSumCompare) && (
																<ToppedPriceInfo price={actualData.toppedPriceSumCompare} />
															)}
														</div>
													</div>
												)}
											</>
										)}
									</div>
								)
							}}
						/>
						<XAxis dataKey='date' stroke='#6D6D6D' axisLine={false} tickLine={false} />
						<YAxis
							stroke='#6D6D6D'
							axisLine={false}
							tickLine={false}
							width={getWidthForYAxis()}
							tickFormatter={(value) => value?.toLocaleString()}
						/>
						{metrics.map(
							({ id, color }) =>
								id === activeMetricId && (
									<Line
										key={id}
										type='monotone'
										dataKey={id}
										stroke={color}
										strokeWidth={2}
										dot={(dotProps) => {
											const { cx, cy, payload = {}, r } = dotProps
											const { toppedCount } = payload

											return (
												<CustomDot
													cx={cx}
													cy={cy}
													radius={r}
													color={color}
													topped={Boolean(toppedCount)}
													strokeColor={color}
												/>
											)
										}}
										activeDot={(dotProps) => {
											const { cx, cy, payload = {} } = dotProps
											const { toppedCount } = payload

											return (
												<CustomDot
													cx={cx}
													cy={cy}
													radius={8}
													color={color}
													topped={Boolean(toppedCount)}
													isActive={true}
												/>
											)
										}}
										animationDuration={700}
									/>
								)
						)}
						{advertStatisticEntitiesCompare.length &&
							metrics.map(
								({ id, color }) =>
									id === activeMetricId && (
										<Line
											key={id + compareSuffix}
											type='monotone'
											dataKey={id + compareSuffix}
											stroke={color}
											strokeWidth={2}
											strokeDasharray='5 5'
											dot={(dotProps) => {
												const { cx, cy, payload = {}, r } = dotProps
												const { toppedCompare } = payload

												return (
													<CustomDot
														cx={cx}
														cy={cy}
														radius={r}
														color={color}
														topped={Boolean(toppedCompare)}
														strokeColor={color}
													/>
												)
											}}
											activeDot={(dotProps) => {
												const { cx, cy, payload = {} } = dotProps
												const { toppedCompare } = payload

												return (
													<CustomDot
														cx={cx}
														cy={cy}
														radius={8}
														color={color}
														topped={Boolean(toppedCompare)}
														isActive={true}
													/>
												)
											}}
											animationDuration={700}
										/>
									)
							)}
					</LineChart>
				</ResponsiveContainer>
			</div>
			<div className={`${CLASSNAME}__switchers`}>
				{metrics.map(({ id, color }) => (
					<label
						key={id}
						className={classnames({
							[`${CLASSNAME}__switcher`]: true,
							[`${CLASSNAME}__switcher--active`]: id === activeMetricId
						})}
					>
						<input
							className={`${CLASSNAME}__radio`}
							type='radio'
							name='metric'
							value={id}
							checked={id === activeMetricId}
							onChange={() => {
								setActiveMetricId(id)
							}}
						/>
						<div className={`${CLASSNAME}__dot`} style={{ backgroundColor: color }}></div>
						<span className={`${CLASSNAME}__text`}>{localize(`ClientStatistics.${id}`)}</span>
					</label>
				))}
			</div>
		</Surface>
	)
}

ClientStatisticsGraph.propTypes = {
	isOperatingLease: PropTypes.bool,
	isMirroring: PropTypes.bool,
	advertStatisticEntities: PropTypes.arrayOf(AdvertStatisticsEntity),
	advertStatisticEntitiesCompare: PropTypes.arrayOf(AdvertStatisticsEntity)
}

const CustomDot = ({ topped, radius, cx, cy, color, strokeColor = '#fff', isActive = false }) => {
	const colorValue = topped ? '#CC0000' : color
	const strokeColorValue = topped && !isActive ? '#CC0000' : strokeColor

	return (
		<Dot cx={cx} cy={cy} r={radius} stroke={strokeColorValue} strokeWidth={2} fill={colorValue} />
	)
}

CustomDot.propTypes = {
	radius: PropTypes.number,
	cx: PropTypes.number,
	cy: PropTypes.number,
	topped: PropTypes.bool,
	color: PropTypes.string,
	strokeColor: PropTypes.string,
	isActive: PropTypes.bool
}

const ToppedCountInfo = ({ count }) => {
	const localize = useLocalize()

	return (
		<div>
			{localize('ClientStatistics.toppedCount')}: {Format.number(count)}
		</div>
	)
}

ToppedCountInfo.propTypes = {
	count: PropTypes.number
}

const ToppedPriceInfo = ({ price }) => {
	const localize = useLocalize()

	return (
		<div>
			{localize('ClientStatistics.toppedPrice')}:{' '}
			<span className='notranslate' translate='no' lang='cs-cz'>
				{priceFormated(price)} {localize('Base.CZK')}
			</span>
		</div>
	)
}

ToppedPriceInfo.propTypes = {
	price: PropTypes.number
}

export default React.memo(ClientStatisticsGraph)
