
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PaymentType'] = {
  creditCard: function(d) { return "Platební karta"; },
  wallet: function(d) { return "Seznam Peněženka"; },
  sms: function(d) { return "SMS"; },
  header: function(d) { return "Platbu provedu:"; },
  infoCreditCard: function(d) { return "Budete přesměrováni na platební bránu, kde vyplníte údaje z vaší platební karty."; },
  infoWallet1: function(d) { return "Bezpečná platba v rámci Seznamu bez poplatku operátorovi."; },
  infoWallet2: function(d) { return "V peněžence nyní máte "; },
  infoWalletLink: function(d) { return "Dobít peněženku"; },
  infoSMS: function(d) { return "Rychlá, pohodlná, ale dražší platba. Operátor si totiž účtuje poplatek za transakci."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	