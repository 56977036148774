
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['ToppedModal'] = {
  title: function(d) { return "Topovat " + plural(d.COUNT, 0, cs, { one: "inzerát", other: "inzeráty" }); },
  text: function(d) { return "Chystáte se topovat " + plural(d.COUNT, 0, cs, { one: "vybraný inzerát", other: "vybrané inzeráty" }) + ", za topování " + d.COUNT + " " + plural(d.COUNT, 0, cs, { one: "inzerátu", other: "inzerátů" }) + " zaplatíte " + d.PRICE + " " + d.CURRENCY + " bez DPH"; },
  close: function(d) { return "Zavřít"; },
  top: function(d) { return "Topovat"; },
  maximumAdvertError: function(d) { return "Topovat můžete maximálně " + d.COUNT + " inzerátů najednou."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	