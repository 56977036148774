import { SAVE_CERT_FORM_IDS } from './SaveCert'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './SaveCertCS.json'

export const SAVE_CERT_FORM_ERRORS = {
	UNKNOWN_CEBIA_FORMAT: 'unknown_cebia_format',
	INVALID_COUPON: 'invalid_cebia_coupon',
	INVALID_REPORT: 'invalid_cebia_report',
	INVALID_SMART_CODE_URL: 'invalid_cebia_smart_code_url'
}

export const getErrorDictionary = (localize) => ({
	[SAVE_CERT_FORM_IDS.CEBIA_REPORT]: {
		[SAVE_CERT_FORM_ERRORS.UNKNOWN_CEBIA_FORMAT]: localize('SaveCert.unknownCebiaFormat'),
		[SAVE_CERT_FORM_ERRORS.INVALID_COUPON]: localize('SaveCert.invalidCebiaCoupon'),
		[SAVE_CERT_FORM_ERRORS.INVALID_REPORT]: localize('SaveCert.invalidCebiaReport'),
		[SAVE_CERT_FORM_ERRORS.INVALID_SMART_CODE_URL]: localize('SaveCert.invalidCebiaSmartCodeUrl'),
		[ERROR_CODES.REQUIRED]: localize('SaveCert.cebiaReportRequired'),
		[ERROR_CODES.NOT_VALID]: localize('SaveCert.cebiaReportNotValid'),
		[ERROR_CODES.UNKNOWN]: localize('SaveCert.cebiaReportNotValid')
	}
})
