import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from '@inzeraty/components'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import classnames from 'classnames'
import canBeDropdownRenderedBelow from 'app/helpers/dropdown/DropdownHelper'

import './FloatingDropdown.less'

const CLASSNAME = 'c-floating-dropdown'

const FloatingDropdown = ({ className, children, onClose, openerRef }) => {
	const dropdownRef = useRef()

	const [alignLeft, setAlignLeft] = useState(true)
	const [isDropdownInDefaultPlace, setIsDropdownInDefaultPlace] = useState(true)

	useEffect(() => {
		if (dropdownRef.current) {
			const dropdownRightBorderPosition = dropdownRef.current.getBoundingClientRect().right
			const windowRightBorderPosition = window.innerWidth

			if (dropdownRightBorderPosition >= windowRightBorderPosition) {
				setAlignLeft(false)
			}

			setIsDropdownInDefaultPlace(
				canBeDropdownRenderedBelow(openerRef.current, dropdownRef.current)
			)
		}
	}, [dropdownRef.current])

	return (
		<div className={`${CLASSNAME}__wrapper`}>
			<div
				className={classnames({
					[`${CLASSNAME}__dropdown`]: true,
					[`${CLASSNAME}__dropdown--align-right`]: !alignLeft,
					[`${CLASSNAME}__dropdown--opens-above-input`]: !isDropdownInDefaultPlace
				})}
				ref={dropdownRef}
			>
				<Dropdown className={className} onClose={onClose}>
					<div className={`${CLASSNAME}__content`}>{children}</div>
				</Dropdown>
			</div>
		</div>
	)
}

FloatingDropdown.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	openerRef: PropTypes.object
}

FloatingDropdown.defaultProps = {
	className: '',
	onClose: DEFAULT_PROPS.FUNCTION
}

export default FloatingDropdown
