import React from 'react'
import { InputSurface } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'
import InputMask from 'react-input-mask'
import { Icon } from '@sznds/react'
import { TRASH_FILLED_24, HISTORY_24 } from '@sznds/icons'
import SimpleTooltip from 'app/component/simpleTooltip/SimpleTooltipView'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export const OPENING_HOURS_WIDGET_ID = 'opening_hours_widget'

import './OpeningHoursWidget.less'
import './OpeningHoursWidgetCS.json'

const CLASSNAME = 'c-cp-opening-hours-widget'

export const OPENING_HOURS_ERROR = {
	AM: 'am',
	PM: 'pm'
}

const OPENING_HOURS = {
	AM_START: 'amStart',
	AM_END: 'amEnd',
	PM_START: 'pmStart',
	PM_END: 'pmEnd'
}

const OPENING_HOURS_STATES = {
	CLOSED: 'closed',
	NONSTOP: 'nonstop',
	OPEN: 'open',
	OPEN_DETAILED: 'open_detailed'
}

const deserializer = (number) => String(number).padStart(2, '0')

const deserialize = ({ hour = 0, minute = 0 } = {}) =>
	`${deserializer(hour)}:${deserializer(minute)}`

const deserializeMinute = ({ hour = 0, minute = 0 }) => hour * 60 + minute

const serialize = (value) => {
	const [hourValue, minuteValue] = value.split(':')
	const hour = Number(hourValue)
	const minute = Number(minuteValue)

	return {
		hour: hour < 24 ? hour : 23,
		minute: minute < 60 ? minute : 59
	}
}

const UseOpeningHoursState = ({
	[OPENING_HOURS.AM_START]: amStart,
	[OPENING_HOURS.AM_END]: amEnd,
	[OPENING_HOURS.PM_START]: pmStart,
	[OPENING_HOURS.PM_END]: pmEnd,
	specification_id
} = {}) => {
	if (specification_id === OPENING_HOURS_STATES.OPEN) {
		if (amStart && amEnd && pmStart && pmEnd) {
			return OPENING_HOURS_STATES.OPEN_DETAILED
		} else {
			return OPENING_HOURS_STATES.OPEN
		}
	} else if (specification_id === OPENING_HOURS_STATES.NONSTOP) {
		return OPENING_HOURS_STATES.NONSTOP
	} else {
		return OPENING_HOURS_STATES.CLOSED
	}
}

export const getValidationErrorMessage = (formLineEntity) => {
	let messages = {}
	const state = UseOpeningHoursState(formLineEntity.value)

	if (state === OPENING_HOURS_STATES.NONSTOP || state === OPENING_HOURS_STATES.CLOSED) {
		return {}
	} else if (state === OPENING_HOURS_STATES.OPEN) {
		const {
			[OPENING_HOURS.AM_START]: amS = {},
			[OPENING_HOURS.AM_END]: amE = {}
		} = formLineEntity.value

		const amSM = deserializeMinute(amS)
		const amEM = deserializeMinute(amE)

		messages = formLineEntity.extra.getValidationMessage(amSM, amEM, 0, 0) || {}
		delete messages[OPENING_HOURS_ERROR.PM]
	} else {
		const {
			[OPENING_HOURS.AM_START]: amS = {},
			[OPENING_HOURS.AM_END]: amE = {},
			[OPENING_HOURS.PM_START]: pmS = {},
			[OPENING_HOURS.PM_END]: pmE = {}
		} = formLineEntity.value

		const amSM = deserializeMinute(amS)
		const amEM = deserializeMinute(amE)
		const pmSM = deserializeMinute(pmS)
		const pmEM = deserializeMinute(pmE)

		messages = formLineEntity.extra.getValidationMessage(amSM, amEM, pmSM, pmEM)
	}

	// - projdu zpravy a predam jen ty s textem, pokud nejsou predam undefined
	let errorMessage = {}
	Object.keys(messages).forEach((key) => {
		const message = messages[key]

		if (message) {
			if (!errorMessage) {
				errorMessage = {}
			}

			errorMessage[key] = message
		}
	})

	return errorMessage
}

const OpeningHoursInput = ({ disabled, error, value, inputId, onChange, onBlur }) => {
	return (
		<InputMask
			mask='99:99'
			maskChar='0'
			alwaysShowMask={true}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			disabled={disabled}
			name={inputId}
		>
			{(inputMaskProps) => (
				<InputSurface
					tagName='input'
					className={`${CLASSNAME}__input`}
					type='text'
					inputMode='numeric'
					pattern='[0-9]*'
					size='small'
					disabled={disabled}
					error={error}
					{...inputMaskProps}
				/>
			)}
		</InputMask>
	)
}

function OpeningHoursWidget({ className = '', ...restProps }) {
	const { formLineEntity, onChange, section } = restProps

	const { id, errorMessage } = formLineEntity

	const localize = useLocalize()
	const state = UseOpeningHoursState(formLineEntity.value)

	const renderActionButton = () => {
		const getNewValue = () => {
			if (state === OPENING_HOURS_STATES.OPEN) {
				return {
					...formLineEntity.value,
					[OPENING_HOURS.PM_START]: {},
					[OPENING_HOURS.PM_END]: {}
				}
			} else if (state === OPENING_HOURS_STATES.OPEN_DETAILED) {
				const {
					[OPENING_HOURS.PM_START]: pmStart,
					[OPENING_HOURS.PM_END]: pmEnd,
					...amValues
				} = formLineEntity.value

				return { ...amValues }
			} else {
				return {
					specification_id: OPENING_HOURS_STATES.OPEN,
					[OPENING_HOURS.AM_START]: {},
					[OPENING_HOURS.AM_END]: {}
				}
			}
		}

		const isDisabled = (errorMessage = {}) => {
			const { am: amError } = errorMessage
			return amError && state === OPENING_HOURS_STATES.OPEN
		}
		const errorMessage = getValidationErrorMessage(formLineEntity)
		const isActionDisabled = isDisabled(errorMessage)
		return (
			<button
				onClick={() => {
					onChange({
						id: formLineEntity.id,
						value: getNewValue(),
						errorMessage,
						section
					})
				}}
				type='button'
				className={classnames({
					[`${CLASSNAME}__simple-action`]: true,
					[`${CLASSNAME}__simple-action--disabled`]: isActionDisabled
				})}
				disabled={isActionDisabled}
			>
				{localize(`OpeningHoursWidget.${state}_action`)}
				<span className={`${CLASSNAME}__accessible-label`}>
					{` ${formLineEntity.extra.inputLabel}`}
				</span>
			</button>
		)
	}

	const renderActionIcon = (label, value, icon) => {
		return (
			<SimpleTooltip
				tooltipClassName={`${CLASSNAME}__action-tooltip`}
				description={label}
				ariaHidden={true}
				align={SimpleTooltip.ALIGN.RIGHT}
				renderContent={(className) => (
					<button
						onClick={() => {
							const errorMessage = getValidationErrorMessage(formLineEntity)

							onChange({
								id: formLineEntity.id,
								value,
								errorMessage,
								section
							})
						}}
						type='button'
						className={`${className} ${CLASSNAME}__action-icon`}
					>
						<span className={`${CLASSNAME}__accessible-label`}>
							{`${label} ${formLineEntity.extra.inputLabel}`}
						</span>
						<Icon symbol={icon} className={`${CLASSNAME}__icon`} />
					</button>
				)}
			/>
		)
	}

	const renderActionIcons = () => {
		return (
			<div className={`${CLASSNAME}__action-icons`}>
				{state !== OPENING_HOURS_STATES.NONSTOP &&
					renderActionIcon(
						localize('OpeningHoursWidget.nonstop'),
						{ specification_id: OPENING_HOURS_STATES.NONSTOP },
						HISTORY_24
					)}
				{state !== OPENING_HOURS_STATES.CLOSED &&
					renderActionIcon(
						localize('OpeningHoursWidget.closed'),
						{ specification_id: OPENING_HOURS_STATES.CLOSED },
						TRASH_FILLED_24
					)}
			</div>
		)
	}

	const renderInput = (type, error = false) => {
		const label = localize(`OpeningHoursWidget.${state}_${type}`)

		return (
			<label className={`${CLASSNAME}__label`}>
				<span className={`${CLASSNAME}__accessible-label`}>
					{`${localize('OpeningHoursWidget.openingHours')} ${
						formLineEntity.extra.inputLabel
					} ${label}`}
				</span>
				<OpeningHoursInput
					onChange={({ target: { value } }) =>
						onChange({
							id: formLineEntity.id,
							value: {
								...formLineEntity.value,
								[type]: serialize(value)
							},
							section
						})
					}
					onBlur={() => {
						const errorMessage = getValidationErrorMessage(formLineEntity)

						onChange({
							id: formLineEntity.id,
							errorMessage,
							section
						})
					}}
					value={deserialize(formLineEntity.value[type])}
					inputId={type}
					disabled={formLineEntity.disabled}
					error={error}
				/>
			</label>
		)
	}

	const renderErrorMessage = (errorMessage = '') => {
		const props = {
			id: `wdg-${id}-err`,
			className: 'wdg__error',
			role: 'tooltip'
		}

		return <>{errorMessage && <div {...props}>{errorMessage}</div>}</>
	}

	const renderValue = () => {
		if (state === OPENING_HOURS_STATES.CLOSED || state === OPENING_HOURS_STATES.NONSTOP) {
			return (
				<div className={`${CLASSNAME}__simple-state`}>
					{localize(`OpeningHoursWidget.${state}`)}
					{!formLineEntity.disabled && <span className={`${CLASSNAME}__separator`}>–</span>}
				</div>
			)
		} else {
			return (
				<>
					<div className={`${CLASSNAME}__input-wrapper`}>
						<div className={`${CLASSNAME}__inputs`}>
							{renderInput(OPENING_HOURS.AM_START, !!errorMessage[OPENING_HOURS_ERROR.AM])}
							<span className={`${CLASSNAME}__separator`}>–</span>
							{renderInput(OPENING_HOURS.AM_END, !!errorMessage[OPENING_HOURS_ERROR.AM])}
						</div>
						{renderErrorMessage(errorMessage[OPENING_HOURS_ERROR.AM])}
					</div>
					{state === OPENING_HOURS_STATES.OPEN_DETAILED && (
						<>
							<span className={`${CLASSNAME}__input-group-separator`}>
								{localize('OpeningHoursWidget.and')}
							</span>
							<div className={`${CLASSNAME}__input-wrapper`}>
								<div className={`${CLASSNAME}__inputs`}>
									{renderInput(OPENING_HOURS.PM_START, !!errorMessage[OPENING_HOURS_ERROR.PM])}
									<span className={`${CLASSNAME}__separator`}>–</span>
									{renderInput(OPENING_HOURS.PM_END, !!errorMessage[OPENING_HOURS_ERROR.PM])}
								</div>
								{renderErrorMessage(errorMessage[OPENING_HOURS_ERROR.PM])}
							</div>
						</>
					)}
				</>
			)
		}
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__description-box`}>
				<span className={`${CLASSNAME}__description`}>{formLineEntity.label}</span>
			</div>
			<div className={`${CLASSNAME}__content`}>
				{renderValue()}
				{!formLineEntity.disabled && (
					<div className={`${CLASSNAME}__actions`}>
						{renderActionButton()}
						{renderActionIcons()}
					</div>
				)}
			</div>
		</div>
	)
}

OpeningHoursWidget.propTypes = {
	className: PropTypes.string
}

OpeningHoursInput.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	value: PropTypes.string,
	inputId: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func
}

export default OpeningHoursWidget
