import IAAdvertEditBaseController from '../IAAdvertEditBaseController'
import STATE_KEYS from './IAEquipmentStateKeys'
import * as FormLines from '@inzeraty/form-lines'
import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import { EQUIPMENT_CB } from 'app/page/userweb/newAdvert/equipment/EquipmentConstants'
import { createEquipmentFormLineEntity } from 'app/page/userweb/newAdvert/equipment/EquipmentFormLine'
import STEPS_STATE_KEYS from 'app/page/userweb/newAdvert/component/stepper/StepperStateKeys'
import { loadDataForSteps } from 'app/page/userweb/newAdvert/component/stepper/StepperUtils'
import { getSteps } from '../stepper/steps'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

export default class IAEquipmentController extends IAAdvertEditBaseController {
	constructor(dependenciesHelper, advertService, VINVehicleDataService) {
		super(dependenciesHelper)

		this._advertService = advertService
		this._VINVehicleDataService = VINVehicleDataService

		this._changeFormLineEntity = this._changeFormLineEntity.bind(this)

		this._submitForm = this._submitForm.bind(this)

		this._setFormStatus = this._setFormStatus.bind(this)

		this._scrollPageToTop = this._scrollPageToTop.bind(this)
	}

	init() {
		super.init()
	}

	load() {
		const advertId = this._getAdvertId()

		const advertEntityPromise = this._advertService.getAdvertDetail(
			Number(advertId),
			{ for_editing: 1 },
			{ cache: false }
		)

		const steps = loadDataForSteps(
			advertId,
			advertEntityPromise,
			this._advertService,
			(categoryId) => getSteps(categoryId, advertId, this._utils.$Router)
		)

		const createPagePromise = advertEntityPromise.then((advertEntity) => {
			const { category: { id: categoryId } = {} } = advertEntity

			return this._advertService.getCreatePage(
				{
					[UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId
				},
				{ cache: true }
			)
		})

		const formLineEntity = Promise.all([createPagePromise, advertEntityPromise]).then(
			([createPage, advertEntity]) => {
				const elements = createPage.sections.reduce((allElements, section) => {
					const { elements } = section
					return [...allElements, ...elements]
				}, [])

				const { options: equipmentOptions = [] } =
					elements.find((e) => e.name === EQUIPMENT_CB) || {}

				return createEquipmentFormLineEntity(advertEntity, equipmentOptions)
			}
		)

		return super.load({
			[STEPS_STATE_KEYS.STEPS]: steps,

			[STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise,
			[STATE_KEYS.FORM_LINE]: formLineEntity,
			[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false,
			[STATE_KEYS.SUBMIT_FORM]: this._submitForm,
			[STATE_KEYS.ON_CHANGE]: this._changeFormLineEntity,
			[STATE_KEYS.SET_FORM_STATUS]: this._setFormStatus,
			[STATE_KEYS.SCROLL_PAGE_TO_TOP]: this._scrollPageToTop
		})
	}

	async _submitForm({ formLineEntity, onErrorCallback }) {
		const { id, value, extra: { getFormData } = {} } = formLineEntity

		if (!getFormData) {
			/* eslint-disable */
			console.warn(`Field "${id}" is missing "getFormData" function.`)
			/* eslint-enable */
		}

		const formData = getFormData && getFormData(value)

		this._pageLoaderExtension.show()

		try {
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: true
			})

			const advertId = this._getAdvertId()

			await this._advertService.patchAdvert(advertId, formData)

			this._onSubmitFormSuccessCallback()
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)
			let errors = []

			if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				errors = body.errors
			}

			this._onSubmitFormErrorCallback()

			onErrorCallback(errors)
		} finally {
			this._pageLoaderExtension.hide()

			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false
			})
		}
	}

	_changeFormLineEntity(change) {
		const { [STATE_KEYS.FORM_LINE]: formLineEntity } = this.getState()

		this.setState({
			[STATE_KEYS.FORM_LINE]: FormLines.updateEntity(formLineEntity, change)
		})
	}

	_setFormStatus({ statusCode, extra }) {
		this.setState({
			[STATE_KEYS.FORM_STATUS]: { statusCode, extra }
		})
	}

	_scrollPageToTop() {
		animateScrollTo(0)
	}

	async onGetCebiaData({ vin = '', callback = DEFAULT_PROPS.FUNCTION }) {
		this._clearCebiaData()

		await this._loadEquipmentDataFromCebia(vin)

		// - zavolam callback z fire
		callback()
	}

	_clearCebiaData() {
		const { [STATE_KEYS.FORM_LINE]: formLineEntity } = this.getState()

		const { id } = formLineEntity

		this._changeFormLineEntity({
			id,
			value: undefined,
			errorMessage: undefined
		})
	}

	async _loadEquipmentDataFromCebia(vin) {
		const mergeWithFormLineValue = (cebiaValues = []) => {
			const { [STATE_KEYS.FORM_LINE]: formLineEntity } = this.getState()

			const { value = [], options = [] } = formLineEntity

			return Array.from(
				new Set([
					...value,
					...cebiaValues.filter((value) => options.find((o) => o.value === value))
				])
			)
		}

		if (vin && vin.trim()) {
			try {
				const data = await this._VINVehicleDataService.getVehicleEquipmentData(vin)

				this._changeFormLineEntity({
					value: mergeWithFormLineValue(data)
				})
			} catch (error) {
				// pokud se neco pokazi pri stahovani dat z Cebie,
				// tak i presto umoznime uzivateli pokracovat v
				// editaci inzeratu
			}
		}
	}
}
