import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { OVERFLOW_MENU_HORIZONTAL_FILLED } from '@sznds/icons'
import { Icon } from '@sznds/react'
import { Dropdown } from '@inzeraty/components'
import ActionElm from 'app/component/actionElement/ActionElement'

import './ActionsWrapper.less'

const CLASSNAME = 'c-actions-wrapper'

const MAX_ACTIONS_THRESHOLD = 5

const ActionsWrapper = (props) => {
	const { entityData, actions } = props

	const [isOpen, setIsOpen] = useState(false)

	const actionsDirectRendered = React.Children.toArray(
		actions.map((action) => action(entityData, { isCompact: true }))
	)
	const actionsViaDropdownRendered = React.Children.toArray(
		actions.map((action) => action(entityData, { isCompact: false }))
	)

	const renderDots = (actions) => {
		return (
			actions.length > 0 && (
				<div className={`${CLASSNAME}__opener-wrapper`}>
					<ActionElm
						className={classnames({
							[`${CLASSNAME}__opener`]: true,
							[`${CLASSNAME}__opener--opened`]: isOpen
						})}
						surface={5}
						onClick={() => setIsOpen((prevState) => !prevState)}
					>
						<Icon symbol={OVERFLOW_MENU_HORIZONTAL_FILLED} className={`${CLASSNAME}__icon`} />
					</ActionElm>
					{isOpen && (
						<div className={`${CLASSNAME}__dropdown-wrapper`}>
							<Dropdown onClose={() => setIsOpen(false)}>
								<div className={`${CLASSNAME}__dropdown-body`}>{actions}</div>
							</Dropdown>
						</div>
					)}
				</div>
			)
		)
	}

	return (
		<>
			<div className={`${CLASSNAME} ${CLASSNAME}--compact-view`}>
				{renderDots(actionsViaDropdownRendered)}
			</div>

			<div className={`${CLASSNAME} ${CLASSNAME}--full-view`}>
				{actionsDirectRendered.slice(0, MAX_ACTIONS_THRESHOLD)}
				{renderDots(actionsViaDropdownRendered.slice(MAX_ACTIONS_THRESHOLD))}
			</div>
		</>
	)
}

ActionsWrapper.propTypes = {
	entityData: PropTypes.object,
	actions: PropTypes.array
}

export default React.memo(ActionsWrapper)
