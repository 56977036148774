import React from 'react'
import AbstractOpener from '../AbstractOpener'

export default class ItemAgeOpener extends AbstractOpener {
	static get propTypes() {
		return AbstractOpener.propTypes
	}

	static get defaultProps() {
		return AbstractOpener.defaultProps
	}

	_renderOpener() {
		const {
			formLineEntity: { label }
		} = this.props

		return (
			<React.Fragment>
				<div>{label}</div>
				{super._renderOpener()}
			</React.Fragment>
		)
	}

	_getTextAndHasValue() {
		let text = ''
		let hasValue
		const { formLineEntity } = this.props

		if (formLineEntity.value && formLineEntity.value.value) {
			const selectedOption = formLineEntity.options.find((option) => {
				return option.value === formLineEntity.value.value
			})

			text = selectedOption.name
			hasValue = true
		} else {
			text = formLineEntity.placeholder
			hasValue = false
		}

		return {
			text,
			hasValue
		}
	}
}
