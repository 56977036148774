
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ReviewReplyForm'] = {
  reviewReplyLabel: function(d) { return "Odpověď na uživatelskou zkušenost"; },
  reviewReplyPlaceholder: function(d) { return "Odpověď na uživatelskou zkušenost"; },
  value_not_valid: function(d) { return "Chybný formát"; },
  value_required: function(d) { return "Povinná položka"; },
  reply_required: function(d) { return "Odpověď nelze přidat bez popisu"; },
  submit: function(d) { return "Odeslat odpověď"; },
  addReviewReplySuccess: function(d) { return "Odpověď byla úspěšně odeslána."; },
  addReviewReplyError: function(d) { return "Odpověď se nepodařilo odeslat. Zkuste to ještě jednou."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	