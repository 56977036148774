import ImaRouteNames from 'ima/router/RouteNames'

const ROUTE_NAMES = Object.freeze({
	NOT_FOUND: ImaRouteNames.NOT_FOUND,
	ERROR: ImaRouteNames.ERROR,

	REGRESSION_TEST: 'componentRegressionTest',

	USERWEB: {
		HOMEPAGE: 'home',
		ADVERT_LIST: 'advertList',
		ADVERT_LIST_WITH_OPERATING_LEASES: 'advertListWithOperatingLeases',
		ADVERT_DETAIL: 'advertDetail',
		OPERATING_LEASE_DETAIL: 'operatingLeaseDetail',
		SELLER: 'seller',
		SELLER_WITH_OPERATING_LEASES: 'sellerWithOperatingLeases',
		SELLER_OLD: 'sellerOld', //jedna se o url pouzivane v historickych variantach sauta
		SELLER_LIST: 'sellerList',
		ARTICLES: 'articles',
		CATALOG: 'catalog',
		CATALOG_MODELS: 'catalogModels',
		CATALOG_MODEL_LINES: 'catalogModelLines',
		CATALOG_MODIFICATIONS: 'catalogModifications',
		CATALOG_MODIFICATION_DETAIL: 'catalogModificationDetail',
		IMPORT_DOCUMENTATION: 'importDocumentation',
		PROMO_CEBIA: 'promoCebia',
		PROMO_INSERTION: 'promoInsertion',
		REGISTER_COMPANY: 'registerCompany',
		FAVORITE_LIST: 'favoriteList',
		MY_SAVED_SEARCH: 'mySavedSearch'
	},

	CLIENT_ADMIN: {
		BOARD_BEHIND_WINDSCREEN: 'boardBehindWindscreen',
		NEW_ADVERT: 'newAdvert',
		NEW_ADVERT_BASIC_INFO: 'newAdvertBasicInfo',
		NEW_ADVERT_PHOTOS: 'newAdvertPhotos',
		NEW_ADVERT_HISTORY: 'newAdvertHistory',
		NEW_ADVERT_EQUIPMENT: 'newAdvertEquipment',
		NEW_ADVERT_PAYMENT: 'newAdvertPayment',
		NEW_ADVERT_PAYMENT_CONFIRMATION: 'newAdvertPaymentConfirmation',
		CLIENT_ADVERT_LIST: 'clientAdvertList',
		CLIENT_PROFILE: 'clientProfile',
		CLIENT_STATISTICS: 'clientStatistics',
		CLIENT_ADVERT_TOPPED_PAYMENT: 'clientAdvertToppedPayment'
	},

	INTERNAL_ADMIN: {
		ADVERT_LIST: 'iaAdvertList',
		ADVERT_LIST_REPORTED: 'advertListReported',
		ADVERT_EDIT_BASIC_INFO: 'aiAdvertEditBasicInfo',
		ADVERT_EDIT_PHOTOS: 'aiAdvertEditPhotos',
		ADVERT_EDIT_EQUIPMENT: 'iaAdvertEditEquipment',
		ADVERT_EDIT_HISTORY: 'iaAdvertEditHistory',
		ADVERT_HISTORY: 'iaAdvertHistory',
		ADVERT_PREVIEW: 'iaAdvertPreview',
		OPERATING_LEASE_PREVIEW: 'iaOperatingLeasePreview',
		ADVERT_REPORT: 'adverReport',
		IMPORT_LIST: 'iaImportList',
		IMPORT_DETAIL: 'iaImportDetail',
		USER_LIST: 'iaUserlist',
		USER_DETAIL: 'iaUserDetail',
		PREMISE_LIST: 'iaPremiseList',
		PREMISE_EDIT: 'iaPremiseEdit',
		PREMISE_HISTORY: 'iaPremiseHistory',
		ADMIN_LIST: 'iaAdminList',
		ROLE_LIST: 'iaRoleList',
		RIGHTS: 'iaRights',
		FORBIDDEN_ACCESS: 'iaForbiddenAccess',
		STATISTICS: 'iaStatistics',
		PAYMENTS: 'iaPayments',
		SOFTWARE_KEYS: 'iaPremiseSoftKey',
		REGISTRATIONS_LIST: 'iaProductRegistrationsList',
		REGISTRATION_INCRASES_LIST: 'iaProductRegistrationIncrasesList',
		PREFERRED_OFFERS: 'iaPreferredOffers',
		REVIEW_LIST: 'iaReviewList',
		REVIEW_REPLY_LIST: 'iaReviewReplyList',
		SEO_FOOTER_EDIT: 'seoFooterEdit',
		CODEBOOK_EQUIPMENT_LIST: 'iaCodebookEquipmentList',
		CODEBOOK_MANUFACTURER_LIST: 'iaCodebookManufacturerList',
		CODEBOOK_MODEL_LIST: 'iaCodebookModelList',
		FEEDBACK_LIST: 'iaFeedbackList',
		ARTICLES_LIST: 'iaArticlesList',
		ARTICLES_DETAIL: 'iaArticlesDetail',
		MIRRORING_LIST: 'iaMirroringList'
	}
})

export default ROUTE_NAMES

const isRouteNameFound = (routes, routeName) => Object.values(routes).includes(routeName)

export const isClientAdminRoute = (routeName) =>
	isRouteNameFound(ROUTE_NAMES.CLIENT_ADMIN, routeName)

export const isInternalAdminRoute = (routeName) =>
	isRouteNameFound(ROUTE_NAMES.INTERNAL_ADMIN, routeName)

export const isRegressionTestRoute = (routeName) => routeName === ROUTE_NAMES.REGRESSION_TEST

export const isDetailRoute = (routeName) =>
	routeName === ROUTE_NAMES.USERWEB.ADVERT_DETAIL ||
	routeName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
