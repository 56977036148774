export default class PaymentEntity {
	/**
	 * @constructor
	 * @param {Number} data.advertPublicationLength     Dlzka publikacie
	 * @param {Number} data.price                       Cena
	 * @param {String} data.credit                      Kredit na penazenke
	 * @param {String} data.paymentType                 Typ platby
	 */
	constructor(data) {
		this.advertPublicationLength = data.advertPublicationLength
		this.price = data.price
		this.credit = data.credit
		this.paymentType = data.paymentType
	}
}
