
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['InactiveAdvert'] = {
  title: function(d) { return "Tento inzerát již není aktivní"; },
  description: function(d) { return "Našli jsme podobné nabídky jako je tento inzerát. Můžete si je zobrazit."; },
  showSimilarAdverts: function(d) { return "Zobrazit podobné inzeráty"; },
  showThisAdvert: function(d) { return "Zobrazit neaktivní inzerát"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	