import { Format, Sort } from '@inzeraty/helpers'
import { CATEGORIES } from 'app/base/Constants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import FilterConstants from 'app/model/filter/FilterConstants'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import radioFilters from '../convertors/radioFiltersList'

import './MySavedSearchPrintCS.json'

const SEPARATOR = ', '

const sortItemsAlphabetically = (itemA = {}, itemB = {}) => {
	const { name: nameA = '' } = itemA
	const { name: nameB = '' } = itemB

	return Sort.sortAlphabetically(nameA, nameB)
}

export default class MySavedSearchPrint {
	static printTitle(mySavedSearch = {}, brandSeparator = ' | ') {
		const { URL_API_PARAMS } = AdvertListUrlConvertor.constants
		const { filter = {} } = mySavedSearch

		let text = ''

		const brands = filter[URL_API_PARAMS.BRANDS_MODELS_VALUES]

		if (brands) {
			const sortedBrands = [...brands].sort(sortItemsAlphabetically)

			sortedBrands.forEach(({ name = '', models = [] }) => {
				text += text ? `${brandSeparator}${name}` : name

				if (models.length) {
					text += ` ${[...models]
						.sort(sortItemsAlphabetically)
						.map((model) => model.name)
						.join(SEPARATOR)}`
				}
			})
		} else {
			const categoryId = filter[URL_API_PARAMS.CATEGORY_ID]
			const { name } =
				Object.values(CATEGORIES).find((category) => category.id === categoryId) || {}
			text = name
		}

		return text || ''
	}

	static printSentence(localize = () => '', mySavedSearch = {}) {
		const { URL_API_PARAMS } = AdvertListUrlConvertor.constants
		const { filter: mySavedSearchFilter = {}, operatingLease = false } = mySavedSearch

		const FILTER_LABELS = {
			[FilterConstants.formLineIds.CONDITION]: localize('MySavedSearchPrint.condition'),
			[FilterConstants.formLineIds.PHRASE]: localize('MySavedSearchPrint.phrase'),
			[FilterConstants.formLineIds.AGE]: localize('MySavedSearchPrint.age'),
			[FilterConstants.formLineIds.PRICE]: localize('MySavedSearchPrint.price'),
			[FilterConstants.formLineIds.FUEL]: localize('MySavedSearchPrint.fuel'),
			[FilterConstants.formLineIds.TACHOMETER]: localize('MySavedSearchPrint.tachometer'),
			[FilterConstants.formLineIds.VEHICLE_BODY]: localize('MySavedSearchPrint.vehicleBody'),
			[FilterConstants.formLineIds.GEARBOX]: localize('MySavedSearchPrint.gearbox'),
			[FilterConstants.formLineIds.PERFORMANCE]: localize('MySavedSearchPrint.performance'),
			[FilterConstants.formLineIds.COUNTRY_OF_ORIGIN]: localize(
				'MySavedSearchPrint.countryOfOrigin'
			),
			[FilterConstants.formLineIds.VENDOR]: localize('MySavedSearchPrint.vendor'),
			[FilterConstants.formLineIds.REGION_DISTRICT]: localize('MySavedSearchPrint.regionDistrict'),
			[FilterConstants.formLineIds.DISPLACEMENT]: localize('MySavedSearchPrint.displacement'),
			[FilterConstants.formLineIds.AIR_CONDITIONING]: localize(
				'MySavedSearchPrint.airConditioning'
			),
			[FilterConstants.formLineIds.FUEL_CONSUMPTION]: localize(
				'MySavedSearchPrint.fuelConsumption'
			),
			[FilterConstants.formLineIds.CERTIFIED_PROGRAM]: localize(
				'MySavedSearchPrint.certifiedProgram'
			),
			[FilterConstants.formLineIds.AIRBAGS]: localize('MySavedSearchPrint.airbags'),
			[FilterConstants.formLineIds.COLOR]: localize('MySavedSearchPrint.color'),
			[FilterConstants.formLineIds.CAPACITY]: localize('MySavedSearchPrint.capacity'),
			[FilterConstants.formLineIds.DOORS]: localize('MySavedSearchPrint.doors'),
			[FilterConstants.formLineIds.BUS_SEATS]: localize('MySavedSearchPrint.busSeats'),
			[FilterConstants.formLineIds.ITEM_AGE]: localize('MySavedSearchPrint.itemAge'),
			[FilterConstants.formLineIds.PRICE_OPTION]: localize('MySavedSearchPrint.priceOption'),
			[FilterConstants.formLineIds.HISTORY_INFO]: localize('MySavedSearchPrint.historyInfo'),
			[FilterConstants.formLineIds.EQUIPMENT]: localize('MySavedSearchPrint.equipment'),
			[FilterConstants.formLineIds.DRIVE]: localize('MySavedSearchPrint.drive'),
			[FilterConstants.formLineIds.OTHER_SPECIFICATION]: localize(
				'MySavedSearchPrint.otherSpecification'
			),
			[FilterConstants.formLineIds.WEIGHT]: localize('MySavedSearchPrint.weight'),
			[FilterConstants.formLineIds.BEDS]: localize('MySavedSearchPrint.beds'),
			[FilterConstants.formLineIds.MACHINE_HOURS]: localize('MySavedSearchPrint.machineHours'),
			[FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT]: localize(
				'MySavedSearchPrint.operatingLeasePriceWithoutVat'
			),
			[FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR]: localize(
				'MySavedSearchPrint.operatingLeaseIntendedFor'
			),
			[FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE]: localize(
				'MySavedSearchPrint.operatingLeaseAnnualDistance'
			),
			[FilterConstants.formLineIds.OPERATING_LEASE_PERIOD]: localize(
				'MySavedSearchPrint.operatingLeasePeriod'
			),
			[FilterConstants.formLineIds.OPERATING_LEASE_SERVICES]: localize(
				'MySavedSearchPrint.operatingLeaseServices'
			)
		}

		const printedFilters = FilterConstants.searchPrintOrder
			.filter((formLineId) => formLineId !== FilterConstants.formLineIds.BRAND_MODEL)
			.map((formLineId) => {
				const radioFilter = radioFilters.find(
					(radioFilter) => radioFilter.formLineId === formLineId
				)

				if (radioFilter) {
					const formatNumber = (number) => {
						if (formLineId === FilterConstants.formLineIds.AGE) {
							return number
						} else {
							return Format.number(number)
						}
					}

					const { apiParamFromName, apiParamToName, unit } = radioFilter
					const valueFrom = mySavedSearchFilter[apiParamFromName]
					const valueTo = mySavedSearchFilter[apiParamToName]

					return printRadioFilter(
						{
							filter: FILTER_LABELS[formLineId],
							from: localize('MySavedSearchPrint.from'),
							to: localize('MySavedSearchPrint.to')
						},
						valueFrom,
						valueTo,
						unit,
						formatNumber
					)
				} else if (formLineId === FilterConstants.formLineIds.REGION_DISTRICT) {
					return printRegionDistrictsFilter(
						FILTER_LABELS[formLineId],
						mySavedSearchFilter[URL_API_PARAMS.REGIONS_DISTRICTS_VALUES]
					)
				} else if (FilterConstants.multiOptionFilters.includes(formLineId)) {
					return printMultiOptionFilter(FILTER_LABELS[formLineId], mySavedSearchFilter[formLineId])
				} else if (formLineId === FilterConstants.formLineIds.ITEM_AGE) {
					return printItemAgeFilter(FILTER_LABELS[formLineId], mySavedSearchFilter[formLineId])
				} else if (formLineId === FilterConstants.formLineIds.PHRASE) {
					return printLabelAndText(FILTER_LABELS[formLineId], mySavedSearchFilter[formLineId])
				}
			})
			.filter((text) => !!text)

		if (operatingLease) {
			return [localize('MySavedSearchPrint.operatingLease'), ...printedFilters].join(SEPARATOR)
		} else {
			return printedFilters.join(SEPARATOR)
		}
	}
}

const printLabelAndText = (label, text) => (label && text ? `${label}: ${text}` : text)

const printMultiOptionFilter = (label, values = []) => {
	const text = values.map(({ name }) => name).join(SEPARATOR) || ''

	return printLabelAndText(label, text)
}

const printRadioFilter = (labels = {}, valueFrom, valueTo, unit, formatNumber) => {
	let text = ''
	const { from, to, filter: filterLabel } = labels

	text += !isNullOrUndefined(valueFrom) ? `${from} ${formatNumber(valueFrom)}` : ''
	text += !isNullOrUndefined(valueFrom) && unit ? ` ${unit}` : ''
	text += !isNullOrUndefined(valueFrom) && !isNullOrUndefined(valueTo) ? ' ' : ''
	text += !isNullOrUndefined(valueTo) ? `${to} ${formatNumber(valueTo)}` : ''
	text += !isNullOrUndefined(valueTo) && unit ? ` ${unit}` : ''

	return printLabelAndText(filterLabel, text)
}

const printItemAgeFilter = (label, value = {}) => printLabelAndText(label, value.name)

const printRegionDistrictsFilter = (label, regions) => {
	let text = ''

	if (regions) {
		regions.forEach(({ name: regionName = '', districts = [] }) => {
			if (districts.length) {
				const districtsNames = districts.map((district) => district.name).join(SEPARATOR)
				text += text ? `, ${districtsNames}` : districtsNames
			} else {
				text += text ? `, ${regionName}` : regionName
			}
		})
	}

	return printLabelAndText(label, text)
}
