import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import Select from 'app/component/select/Select'
import Label from '../label/Label'

const SelectWidget = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		changeFormLineEntities = DEFAULT_PROPS.FUNCTION,
		dataDotText = '',
		allowNoSelection = true
	} = props

	const { id, value: { id: selectedId } = {}, label, placeholder, options = [] } = formLineEntity

	const handleSelect = (selectedItem) => {
		const selectedOption = options.find(({ id }) => selectedItem && id === selectedItem.id)

		changeFormLineEntities([
			{
				id,
				value: selectedOption ? selectedOption : undefined
			}
		])
	}

	const linkedSelectAndLabel = AutoComplete.getIdsPropsHelper(id)

	return (
		<div>
			<Label id={linkedSelectAndLabel.labelId} htmlFor={linkedSelectAndLabel.inputId}>
				{label}
			</Label>

			<div data-dot={dataDotText}>
				<Select
					{...linkedSelectAndLabel}
					selectedItem={options.find(({ id }) => id === selectedId) || null}
					items={options}
					placeholder={placeholder}
					disabled={!options.length}
					size='small'
					allowNoSelection={allowNoSelection}
					onSelect={handleSelect}
				/>
			</div>
		</div>
	)
}

SelectWidget.propTypes = {
	changeFormLineEntities: PropTypes.func,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	dataDotText: PropTypes.string,
	allowNoSelection: PropTypes.bool
}

export default React.memo(SelectWidget)
