import AbstractExtension from 'ima/extension/AbstractExtension'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import HTTP_STATUS_CODE from 'app/base/HttpStatusCode'
import SortConstants, { OperatingLeaseSortConstants } from 'app/component/sortButton/SortConstants'
import CategoryPickerConstants from 'app/component/pickerButton/CategoryPickerConstants'
import { PaginationHelper } from '@inzeraty/models'
import deepEqual from 'fast-deep-equal'
import getRouteName from 'app/helpers/routeName/getRouteName'
import clearObject from 'app/helpers/clearObject/clearObject'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'

export const isValidSortParam = (routeName, sortParamValue) => {
	const SortConstantsToUse = [
		ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES,
		ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
	].includes(routeName)
		? OperatingLeaseSortConstants
		: SortConstants

	return Object.keys(SortConstantsToUse.sortValues).some(
		(key) => SortConstantsToUse.sortValues[key]?.APP === sortParamValue
	)
}

export default class AdvertListExtension extends AbstractExtension {
	static get stateIds() {
		return {
			ADVERT_LIST_PARAMS_PROMISE: 'advertListParamsPromise',
			ADVERT_LIST_PAGINATION: 'advertListAndPagination',
			ADVERT_LIST_PLACEHOLDERS: 'advertListPlaceholders',
			ADVERT_LIST_PREFERRED: 'advertListPreferred'
		}
	}

	constructor(advertService, advertFactory, utils, cacheHelper, historyStorage) {
		super()

		this._advertService = advertService
		this._advertFactory = advertFactory
		this._router = utils.$Router
		this._cacheHelper = cacheHelper
		this._historyStorage = historyStorage
		this._sessionStorage = utils.$SessionStorage
		this._dotAnalytic = utils.DotAnalytic

		// Určuje jeslti se mají ve výpisu inzerátů načítat inzeráty přednostního výpisu
		this._loadPrefered = false
	}

	setLoadPreferred(isSet = true) {
		this._loadPrefered = isSet
	}

	setOnAdvertPaginationLoaded(callback) {
		this._onAdvertPaginationLoaded = callback
	}

	load() {
		this._checkSortParams(this.getRouteParams())

		const prevRouteName = this._historyStorage.getPreviousRouteName()

		const advertsAndPagination = this._getAdvertsAndPagination()

		this._onAdvertPaginationLoaded?.(advertsAndPagination)

		return {
			[AdvertListExtension.stateIds.ADVERT_LIST_PLACEHOLDERS]: this._getAdvertsPlaceholders(),
			[AdvertListExtension.stateIds.ADVERT_LIST_PAGINATION]: advertsAndPagination,
			[AdvertListExtension.stateIds.ADVERT_LIST_PREFERRED]: this._getPreferredAdverts(
				!prevRouteName
			)
		}
	}

	update(prevParams) {
		const didOnlyPageChange = () => {
			const prevUrlParams = Object.assign({}, prevParams)
			const currentUrlParams = Object.assign({}, this.getRouteParams())

			const notCompareParams = [
				UrlConvertor.constants.URL_APP_PARAMS.PAGE,
				AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_ID,
				AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_HASH,
				AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP
			]

			// pred porovnanim vymazi parametry ktere nechci porovnavat
			notCompareParams.forEach((param) => {
				delete prevUrlParams[param]
				delete currentUrlParams[param]
			})

			return deepEqual(prevUrlParams, currentUrlParams)
		}

		this._checkSortParams(this.getRouteParams())

		this._setAdvertsPlaceholders()

		const actualParams = this.getRouteParams()
		const isMySavedSearchPage = actualParams.hasOwnProperty(
			AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP
		)
		const wasMySavedSearchPage = prevParams.hasOwnProperty(
			AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP
		)

		// prichazim s vypisu na ulozene hledani - napr. pri pohybu historii zpet. Musim vymazat cache abych si stahnul aktualni data
		if (!wasMySavedSearchPage && isMySavedSearchPage) {
			this._clearCache()
		}

		// poukd predchozi stranka byla ulozene hledani
		if (wasMySavedSearchPage && !isMySavedSearchPage) {
			this._clearCache()
		} else if (!didOnlyPageChange()) {
			this._clearCache()
		}

		const advertsAndPagination = this._getAdvertsAndPagination()

		this._onAdvertPaginationLoaded?.(advertsAndPagination)

		return {
			[AdvertListExtension.stateIds.ADVERT_LIST_PAGINATION]: advertsAndPagination,
			[AdvertListExtension.stateIds.ADVERT_LIST_PREFERRED]: this._getPreferredAdverts(false)
		}
	}

	_clearCache() {
		this._cacheHelper.resetCache()
	}

	/**
	 * Zkontroluje URL parametr razeni a pripadne presmeruje uzivatele na spravnou adresu
	 * @method _checkSortParams
	 * @private
	 * @param {Object} params
	 */
	_checkSortParams(params = {}) {
		const sortParam = params[UrlConvertor.constants.URL_APP_PARAMS.SORT]

		if (sortParam) {
			const routeName = getRouteName(this._router)

			if (!isValidSortParam(routeName, sortParam)) {
				const newParams = Object.assign({}, params)
				delete newParams[UrlConvertor.constants.URL_APP_PARAMS.SORT]

				this._router.redirect(this._router.link(routeName, newParams), {
					httpStatus: HTTP_STATUS_CODE.MOVED_PERMANENTLY
				})
			}
		}
	}

	/**
	 * Vrátí inzeráty se stránkováním.
	 *
	 * @private
	 * @method _getAdvertsAndPagination
	 * @return {Promise<Object>}
	 */
	_getAdvertsAndPagination() {
		const {
			[AdvertListExtension.stateIds.ADVERT_LIST_PARAMS_PROMISE]: advertListParamsPromise
		} = this.getState()

		return advertListParamsPromise.then((params) => {
			const {
				[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease
			} = params

			const newParams = Object.assign(
				UrlConvertor.getApiSorting(
					this.getRouteParams(),
					isOperatingLease ? OperatingLeaseSortConstants : SortConstants
				),
				params
			)

			return this._advertService.getSearchAdvertList(newParams).then((advertListAndPagination) => {
				const { paginationEntity = {} } = advertListAndPagination

				// Uloží si aktuální stránkování pro výpočet placeholderů při update.
				this._actualPaginationEntity = paginationEntity

				return advertListAndPagination
			})
		})
	}

	_getPreferredAdverts(cache = true) {
		if (!this._loadPrefered) return []

		const {
			[AdvertListExtension.stateIds.ADVERT_LIST_PARAMS_PROMISE]: advertListParamsPromise
		} = this.getState()

		return advertListParamsPromise.then((params) => {
			const newParams = {}
			if (params[AdvertListUrlConvertor.constants.URL_API_PARAMS.BRANDS_MODELS_SEO]) {
				newParams[AdvertListUrlConvertor.constants.URL_API_PARAMS.BRANDS_MODELS_SEO] =
					params[AdvertListUrlConvertor.constants.URL_API_PARAMS.BRANDS_MODELS_SEO]
			} else if (params[AdvertListUrlConvertor.constants.URL_API_PARAMS.BRANDS_MODELS_VALUES]) {
				newParams[AdvertListUrlConvertor.constants.URL_API_PARAMS.BRANDS_MODELS_VALUES] =
					params[AdvertListUrlConvertor.constants.URL_API_PARAMS.BRANDS_MODELS_VALUES]
			}

			if (params[UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]) {
				newParams[UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID] =
					params[UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]
			}

			return this._advertService.getPreferred(newParams, { cache }).then((preferredList) => {
				return preferredList
			})
		})
	}

	/**
	 * Nastaví placeholdery inzerátů.
	 *
	 * @private
	 * @method _setAdvertsPlaceholders
	 */
	_setAdvertsPlaceholders() {
		const paginationEntity = this._actualPaginationEntity

		this.setState({
			[AdvertListExtension.stateIds.ADVERT_LIST_PAGINATION]: {
				advertList: this._getAdvertsPlaceholders(PaginationHelper.getItemsCount(paginationEntity)),
				paginationEntity: {}
			},
			[AdvertListExtension.stateIds.ADVERT_LIST_PREFERRED]: undefined
		})
	}

	/**
	 * Vrátí prázdné inzeráty pro potřebu vykreslení placeholderů.
	 *
	 * @private
	 * @method _getAdvertsPlaceholders
	 * @param {Number} advertsCount Počet placeholderů.
	 * @return {Array<AdvertEntity>} Prázné inzeráty jen pro potřebu vykreslení placeholderů.
	 */
	_getAdvertsPlaceholders(advertsCount = UrlConvertor.constants.DEFAULTS.PER_PAGE) {
		const emptyAdverts = []

		for (let advertId = 0; advertId < advertsCount; advertId++) {
			emptyAdverts.push({ id: advertId })
		}

		return emptyAdverts.map((emptyAdvert) => this._advertFactory.transformData(emptyAdvert))
	}

	/**
	 * Osetreni udalosti kliku na polozku filtru razeni
	 *
	 * @method onSortLinkClick
	 * @param {String} key
	 */
	onSortLinkClick(key) {
		const newParams = Object.assign({}, this.getRouteParams())
		delete newParams[UrlConvertor.constants.URL_APP_PARAMS.SORT]
		delete newParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE]

		if (key !== SortConstants.defaultValue) {
			Object.assign(newParams, {
				[UrlConvertor.constants.URL_APP_PARAMS.SORT]: SortConstants.sortValues[key].APP
			})
		}

		this._router.redirect(this._router.link(getRouteName(this._router), clearObject(newParams)))
	}

	/**
	 * Osetreni udalosti kliku na polozku vyber kategorie
	 *
	 * @method onCategoryLinkClick
	 * @param {String} key
	 */
	onCategoryLinkClick(key) {
		const newParams = Object.assign({}, this.getRouteParams())

		delete newParams[UrlConvertor.constants.URL_APP_PARAMS.SORT]
		delete newParams[UrlConvertor.constants.URL_APP_PARAMS.PAGE]
		delete newParams[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]
		for (const appParam in AdvertListUrlConvertor.constants.URL_APP_PARAMS) {
			delete newParams[AdvertListUrlConvertor.constants.URL_APP_PARAMS[appParam]]
		}

		if (key !== CategoryPickerConstants.defaultValue) {
			Object.assign(newParams, {
				[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: key
			})
		}

		this._router.redirect(this._router.link(getRouteName(this._router), clearObject(newParams)))
	}
}
