import { CodebooksResource as CodebooksResourceBase } from '@inzeraty/models'

const RESOURCE = '/codebooks'

/**
 * @class CodebooksResource
 * @namespace models.codebooks
 * @module models
 */
export default class CodebooksResource extends CodebooksResourceBase {
	/**
	 * Získá číselníky.
	 *
	 * @method getCodebooksValues
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 */
	getCodebooksValues(params, options) {
		return this._restClient.get(`${RESOURCE}/values`, params, options)
	}

	/**
	 * Uloží nový číselník.
	 *
	 * @method postCodebooksValues
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 */
	postCodebooksValues(params, options) {
		return this._restClient.post(`${RESOURCE}/values`, params, options)
	}

	/**
	 * Editace o číselníku.
	 *
	 * @method putCodebooksValues
	 * @public
	 * @param {Number} codebookId
	 * @param {Object} params
	 * @param {Object} options
	 */
	putCodebooksValues(codebookId, params, options) {
		return this._restClient.update(`${RESOURCE}/values/${codebookId}`, params, options)
	}

	/**
	 * Změna části existujícího číselníku.
	 *
	 * @method patchCodebooksValues
	 * @public
	 * @param {Number} codebookId
	 * @param {Object} params
	 * @param {Object} options
	 */
	patchCodebooksValues(codebookId, params, options) {
		return this._restClient.patch(`${RESOURCE}/values/${codebookId}`, params, options)
	}

	/**
	 * Smazání číselníku.
	 *
	 * @method getCodebooksValues
	 * @public
	 * @param {Number} codebookId
	 * @param {Object} params
	 * @param {Object} options
	 */
	deleteCodebooksValues(codebookId, urlParams, params, options) {
		return this._restClient.delete(
			`${RESOURCE}/values/${codebookId}?${this._getEncodedUrlParams(urlParams)}`,
			params,
			options
		)
	}

	_getEncodedUrlParams(urlParams) {
		return Object.keys(urlParams)
			.map(function (key) {
				return encodeURIComponent(key) + '=' + encodeURIComponent(urlParams[key])
			})
			.join('&')
	}
}
