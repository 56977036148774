import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import Square from 'app/component/sspAdverts/square/SquareView'
import Rectangle from 'app/component/sspAdverts/rectangle/Rectangle'
import Logo from 'app/component/logo/Logo'
import PopupFeedbackForm from 'app/component/feedback/PopupFeedbackForm'
import ROUTE_NAMES from 'app/base/RouteNames'
import { useLocalize } from 'app/base/componentHelpers'
import IMAGES from 'app/base/ImagesConstants'
import { SERVICE } from 'app/base/Constants'
import Context from 'ima/page/context'
import select from 'ima-plugin-select'
import UserwebBaseController from 'app/base/UserwebBaseController'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'

import './Footer.less'
import './FooterCS.json'

const CLASSNAME = 'c-footer'

const seoFooterLinksMenuItemPropTypes = PropTypes.shape({
	links: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string,
					url: PropTypes.string
				})
			),
			PropTypes.shape({
				_: PropTypes.shape({
					title: PropTypes.string,
					url: PropTypes.string
				})
			})
		])
	),
	title: PropTypes.string
})

const seoFooterLinksMenuPropTypes = PropTypes.shape({
	horizontal: PropTypes.arrayOf(seoFooterLinksMenuItemPropTypes),
	vertical: PropTypes.arrayOf(seoFooterLinksMenuItemPropTypes)
})

const LINK = {
	SAUTO: {
		TERMS: 'https://napoveda.seznam.cz/cz/sauto-cz/sauto-pravidla/smluvni-podminky-sluzby-sauto.cz',
		DATA_PROTECTION: 'https://o.seznam.cz/ochrana-udaju/sauto-cz/',
		HINT: 'https://napoveda.seznam.cz/cz/sauto-cz/prihlaseni-sauto/',
		CONTACT: 'https://napoveda.seznam.cz/cz/sauto-cz/kontakty-sauto.cz/',
		COMPLAINTS: 'https://napoveda.seznam.cz/cz/stiznosti-sauto/',
		PROMO_CEBIA: '/promo-cebia',
		PRODUCT_LIST: 'https://www.seznam.cz/reklama/cz/obsahovy-web/ceniky/',
		BLOG: 'https://blog.seznam.cz/stitek/sauto-cz/'
	},
	SEZNAM: {
		HINT: 'https://napoveda.seznam.cz/cz/',
		TERMS: 'https://napoveda.seznam.cz/cz/smluvni-podminky/podminky-registrace/',
		DATA_PROTECTION: 'https://o.seznam.cz/ochrana-udaju/',
		ABOUT_SEZNAM: 'https://o.seznam.cz',
		COMMERCIAL: 'https://www.seznam.cz/reklama/',
		WITHDRAW_AGREEMENT: 'https://h.seznam.cz/html/odvolat-souhlas.html',
		HP: 'https://www.seznam.cz/'
	}
}

const SAutoLogo = () => (
	<div className={`${CLASSNAME}__logo ${CLASSNAME}__logo-sauto`}>
		<Logo className={`${CLASSNAME}__logo-link`} />
	</div>
)

const Advert = () => {
	const { $Router } = useContext(Context)
	const { route } = $Router.getCurrentRouteInfo()
	const actualRoute = route.getName()

	return (
		<div className={`${CLASSNAME}__abox`}>
			{
				// V detailu inzerátu nebude zobrazovat reklamu square v patičce.
				actualRoute !== ROUTE_NAMES.USERWEB.ADVERT_DETAIL &&
					actualRoute !== ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL && (
						<Square id='ssp-square-footer' className={`${CLASSNAME}__square`} />
					)
			}
			<Rectangle />
		</div>
	)
}

const FooterLink = ({ url, name, cl, order, newTab, stopPropagation }) => {
	const props = {}

	if (newTab) {
		props.rel = 'noopener noreferrer'
		props.target = '_blank'
	}

	if (stopPropagation) {
		props.onClick = (event) => {
			event.stopPropagation()
		}
	}

	return (
		<a
			className={`${CLASSNAME}__link-${cl}`}
			href={url}
			{...props}
			data-dot='item'
			data-dot-data={`{"order": "${order}"}`}
		>
			{name}
		</a>
	)
}

FooterLink.propTypes = {
	url: PropTypes.string,
	name: PropTypes.string,
	cl: PropTypes.string,
	order: PropTypes.number,
	newTab: PropTypes.bool,
	stopPropagation: PropTypes.bool
}

FooterLink.defaultProps = {
	newTab: true,
	stopPropagation: false
}

const FooterButton = ({ cl, order, onClick = DEFAULT_PROPS.FUNCTION, children, ...restProps }) => {
	return (
		<button
			className={`${CLASSNAME}__link-${cl}`}
			onClick={onClick}
			data-dot='item'
			data-dot-data={`{"order": "${order}"}`}
			{...restProps}
		>
			{children}
		</button>
	)
}

FooterButton.propTypes = {
	cl: PropTypes.string,
	order: PropTypes.number,
	onClick: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const SeznamLogo = () => {
	const hp = LINK.SEZNAM.HP

	return (
		<div className={`${CLASSNAME}__logo ${CLASSNAME}__logo-seznam`}>
			<a
				href={hp}
				className={`${CLASSNAME}__logo-link`}
				rel='noopener noreferrer'
				target='_blank'
				data-dot='go-to-seznam'
			>
				<img
					className={`${CLASSNAME}__logo-img`}
					src={IMAGES['image__logo-seznam']}
					alt='seznam.cz'
					loading='lazy'
				/>
			</a>
		</div>
	)
}

const Feedback = ({ order }) => {
	const localize = useLocalize()

	return (
		<PopupFeedbackForm
			renderOpener={(openerProps) => (
				<FooterButton
					{...openerProps}
					cl={SERVICE}
					order={order}
					data-dot='show-form-feedback'
					data-e2e='show-feedback'
				>
					{localize('PopupFeedbackForm.header')}
				</FooterButton>
			)}
		/>
	)
}

Feedback.propTypes = {
	order: PropTypes.number
}

const ConsentManagementPlatform = ({ order }) => {
	const { $Dispatcher } = useContext(Context)
	const localize = useLocalize()

	const handleOnClick = (event) => {
		event.preventDefault()
		event.stopPropagation()

		const { scmp } = window

		if (scmp && scmp.open) {
			scmp.open()
		} else {
			$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				title: localize('Footer.consentManagementPlatformError'),
				type: StatusMessage.TYPE.ERROR
			})
		}
	}

	return (
		<FooterButton cl={SERVICE} order={order} onClick={handleOnClick}>
			{localize('Footer.consentManagementPlatform')}
		</FooterButton>
	)
}

ConsentManagementPlatform.propTypes = {
	order: PropTypes.number
}

const SeoPart = ({ seoLinks }) => {
	if (seoLinks) {
		const { vertical: seoLinksVertical = [], horizontal: seoLinksHorizontal = [] } = seoLinks

		const hasVertical = seoLinksVertical.length > 0
		const hasHorizontal = seoLinksHorizontal.length > 0

		if (hasVertical || hasHorizontal) {
			return (
				<div className={`${CLASSNAME}__section ${CLASSNAME}__section-seo`} data-dot='navigation'>
					{hasVertical && (
						<SeoMenu
							key='verti'
							links={seoLinksVertical}
							className={`${CLASSNAME}__seo-vertical`}
						/>
					)}
					{hasHorizontal && (
						<SeoMenu
							key='horiz'
							links={seoLinksHorizontal}
							className={`${CLASSNAME}__seo-horizontal`}
						/>
					)}
				</div>
			)
		} else {
			return null
		}
	} else {
		return null
	}
}

SeoPart.propTypes = {
	seoLinks: seoFooterLinksMenuPropTypes
}

const SeoMenu = ({ key = '', links = [], className }) => {
	if (links.length) {
		return (
			<div className={className}>
				{links.map((menu, menuIndex) => {
					const { title, links: sublinks } = menu

					const menuLinks = sublinks instanceof Object ? Object.values(sublinks) : sublinks

					return (
						<div className={`${CLASSNAME}__seo-menu`} key={`menu-${key}-${menuIndex}`}>
							<h5 className={`${CLASSNAME}__seo-menu-title`}>{title}</h5>
							<ul className={`${CLASSNAME}__seo-links`}>
								{menuLinks.map(({ title, url }, linkIndex) => (
									<SeoLink title={title} url={url} key={`link-${menuIndex}-${linkIndex}`} />
								))}
							</ul>
						</div>
					)
				})}
			</div>
		)
	}
}

SeoMenu.propTypes = {
	links: PropTypes.arrayOf(seoFooterLinksMenuItemPropTypes),
	className: PropTypes.string,
	key: PropTypes.string
}

const SeoLink = ({ title, url }) => {
	if (title && url) {
		return (
			<li className={`${CLASSNAME}__seo-menu-item`}>
				<a
					href={url}
					className={`${CLASSNAME}__seo-menu-link`}
					target='_blank'
					rel='noopener noreferrer'
					data-dot='link'
					data-dot-data={`{"type": "${title}"}`}
				>
					{title}
				</a>
			</li>
		)
	} else {
		return null
	}
}

SeoLink.propTypes = {
	title: PropTypes.string,
	url: PropTypes.string
}

const Footer = ({ isAdmin = false, seoFooterLinks }) => {
	const sautoLink = LINK.SAUTO
	const seznamLink = LINK.SEZNAM

	const { $Router } = useContext(Context)
	const localize = useLocalize()

	return (
		<div className={CLASSNAME}>
			<Advert />

			<div className={`${CLASSNAME}__content`} data-dot='footer'>
				<div className={`${CLASSNAME}__section ${CLASSNAME}__section-sauto`}>
					<SAutoLogo />
					<div
						className={`${CLASSNAME}__sauto-links`}
						data-dot='navigation'
						data-dot-data='{"type": "site-section"}'
					>
						<ul className={`${CLASSNAME}__list`}>
							<li className={`${CLASSNAME}__list-item`}>
								<Feedback order={0} />
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={sautoLink.TERMS}
									name={localize('Footer.terms')}
									cl={SERVICE}
									order={1}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={$Router.link(ROUTE_NAMES.USERWEB.IMPORT_DOCUMENTATION)}
									name={localize('Footer.importDocumentation')}
									cl={SERVICE}
									order={2}
									newTab={false}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={sautoLink.COMPLAINTS}
									name={localize('Footer.complaints')}
									cl={SERVICE}
									order={3}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={sautoLink.DATA_PROTECTION}
									name={localize('Footer.dataProtection')}
									cl={SERVICE}
									order={4}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={sautoLink.PRODUCT_LIST}
									name={localize('Footer.productList')}
									cl={SERVICE}
									order={5}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={sautoLink.HINT}
									name={localize('Footer.hint')}
									cl={SERVICE}
									order={6}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={sautoLink.CONTACT}
									name={localize('Footer.contact')}
									cl={SERVICE}
									order={7}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={sautoLink.BLOG}
									name={localize('Footer.blog')}
									cl={SERVICE}
									order={8}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={$Router.link(ROUTE_NAMES.CLIENT_ADMIN.CLIENT_ADVERT_LIST)}
									name={localize('Footer.myAdverts')}
									cl={SERVICE}
									order={9}
									newTab={false}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={sautoLink.PROMO_CEBIA}
									name={localize('Footer.promoCebia')}
									cl={SERVICE}
									order={10}
									newTab={false}
								/>
							</li>
							{isAdmin ? null : (
								<li className={`${CLASSNAME}__list-item`}>
									<ConsentManagementPlatform order={11} />
								</li>
							)}
						</ul>
					</div>
				</div>

				<SeoPart seoLinks={seoFooterLinks} />

				<div className={`${CLASSNAME}__section ${CLASSNAME}__section-seznam`}>
					<SeznamLogo />
					<div
						className={`${CLASSNAME}__seznam-links`}
						data-dot='navigation'
						data-dot-data='{"type": "general-section"}'
					>
						<ul className={`${CLASSNAME}__list`}>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={seznamLink.HINT}
									name={localize('Footer.hint')}
									cl='seznam'
									order={1}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={seznamLink.TERMS}
									name={localize('Footer.terms')}
									cl='seznam'
									order={2}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={seznamLink.DATA_PROTECTION}
									name={localize('Footer.dataProtection')}
									cl='seznam'
									order={3}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={seznamLink.ABOUT_SEZNAM}
									name={localize('Footer.about')}
									cl='seznam'
									order={4}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={seznamLink.COMMERCIAL}
									name={localize('Footer.commercial')}
									cl='seznam'
									order={5}
								/>
							</li>
							<li className={`${CLASSNAME}__list-item`}>
								<FooterLink
									url={seznamLink.WITHDRAW_AGREEMENT}
									name={localize('Footer.withdrawAgreement')}
									cl='seznam'
									order={6}
								/>
							</li>
						</ul>
						<div className={`${CLASSNAME}__info`}>
							{localize('Footer.info', {
								year: new Date().getFullYear()
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

Footer.propTypes = {
	isAdmin: PropTypes.bool,
	seoFooterLinks: seoFooterLinksMenuPropTypes
}

const FooterSelector = (state) => ({
	seoFooterLinks: state[UserwebBaseController.STATE_KEYS.SEO_FOOTER_LINKS]
})

export default select(FooterSelector)(Footer)
