import React from 'react'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntityForm from 'app/component/entityForm/EntityForm'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

export default function IARightsView({ isWaiting, formLineEntities, entityFormError }) {
	return (
		<Layout title='Editace práv'>
			<EntityForm
				formLineEntities={formLineEntities}
				isWaiting={isWaiting}
				entityFormError={entityFormError}
			/>
		</Layout>
	)
}

IARightsView.propTypes = {
	isWaiting: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object
}
