export default class ModificationsFactory {
	transformData(modifications, parent = {}) {
		return {
			modifications: this._mapModifications(modifications),
			generation: this._mapGeneration(parent)
		}
	}

	_mapModifications(modifications = []) {
		return modifications.map((modification) => {
			const generation = modification.generation || {}

			return {
				id: modification.id,
				images: modification.images,
				name: modification.name,
				seoName: modification.seo_name,
				yearEnd: modification.year_end,
				yearStart: modification.year_start,
				generation: this._mapGeneration(generation),
				data: modification.data
					? {
							maxSpeed: modification.data.maxspeed,
							engineVolume: modification.data.engineDisplacement,
							fuel: modification.data.fuel,
							fuelConsumptionUrbanMin: modification.data.fuelConsumptionUrbanMin,
							fuelConsumptionUrbanMax: modification.data.fuelConsumptionUrbanMax,
							fuelConsumptionExtraurbanMin: modification.data.fuelConsumptionExtraurbanMin,
							fuelConsumptionExtraurbanMax: modification.data.fuelConsumptionExtraurbanMax,
							fuelConsumptionCombinedMin: modification.data.fuelConsumptionCombinedMin,
							fuelConsumptionCombinedMax: modification.data.fuelConsumptionCombinedMax,
							vehicleBodySeo: modification.data.vehicle_body_seo,
							powerKw: modification.data.powerKw
					  }
					: {}
			}
		})
	}

	_mapGeneration(generation = {}) {
		return {
			id: generation.id,
			name: generation.name,
			seoName: generation.seo_name,
			model: generation.model
				? {
						id: generation.model.id,
						name: generation.model.name,
						seoName: generation.model.seo_name,
						manufacturer: generation.model.manufacturer
							? {
									id: generation.model.manufacturer.id,
									name: generation.model.manufacturer.name,
									seoName: generation.model.manufacturer.seo_name
							  }
							: {}
				  }
				: {}
		}
	}
}
