import React from 'react'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'

export default function IAAdvertListView() {
	return (
		<Layout title='Nepovolený přístup'>
			<strong>403</strong> - Do této části interního adminu nemáte přístup. Pokud přístup
			potřebujete, požádejte administrátora o přidělení práv.
		</Layout>
	)
}
