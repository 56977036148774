import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import { Surface } from '@sznds/react'
import BrandsPlaceholder from '../../../placeholders/brandsPlaceholder/BrandsPlaceholder'
import { SDN, CATEGORIES } from 'app/base/Constants'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import { orderedListToAlphabetGroups } from '../../../helper/OrderListToAlphabetGroups'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'
import { Input } from '@sznds/react'
import { filterItemsIgnoreSpecChars } from 'app/component/autoComplete/AutoComplete'
import { useLocalize } from 'app/base/componentHelpers'
import classnames from 'classnames'

import 'app/base/BaseCS.json'
import 'app/component/filters/forms/main/openers/brandModelOpener/AdvancedBrandModelOpenerCS.json'
import './BrandsList.less'

const CLASSNAME = 'c-catalog-brands-list'

const BrandsList = (props) => {
	const { brandsList = DEFAULT_PROPS.ARRAY } = props

	const context = useContext(Context)
	const router = context.$Router
	const localize = useLocalize()

	const [dataLoaded, setDataLoaded] = useState(false)
	const [hasResults, setHasResults] = useState(true)
	const [brandsGroups, setBandsGroups] = useState(DEFAULT_PROPS.OBJECT)
	const [searchText, setSearchText] = useState('')

	useEffect(() => {
		setBandsGroups(orderedListToAlphabetGroups(brandsList))
		setDataLoaded(brandsList && brandsList.length > 0)
	}, [brandsList])

	useEffect(() => {
		let newBrandsList = brandsList

		if (searchText) {
			newBrandsList = [...brandsList].filter((brand) =>
				filterItemsIgnoreSpecChars(searchText, brand.name)
			)

			setHasResults(newBrandsList.length > 0)
		} else {
			setHasResults(true)
		}

		setBandsGroups(orderedListToAlphabetGroups(newBrandsList))
	}, [searchText])

	const getBrandUrl = (brand) => {
		const { seoName } = brand

		return router.link(ROUTE_NAMES.USERWEB.CATALOG_MODELS, {
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: seoName
		})
	}

	const getBrandSeoName = (seoName) => {
		const splitSeoName = seoName.split('-')
		const nameWithoutNumber = splitSeoName.slice(1)
		return nameWithoutNumber.join('-')
	}

	return (
		<>
			<div className={`${CLASSNAME}__search-wrap`}>
				<Input
					className={classnames({
						[`${CLASSNAME}__search`]: true,
						'sds-inputsurface--disabled': !dataLoaded
					})}
					iconLeft={SEARCH_OUTLINE_24}
					onChange={({ target }) => {
						setSearchText(target.value)
					}}
					placeholder={localize('Base.searchBrand')}
					disabled={!dataLoaded}
				/>
			</div>
			<Surface surface={3} className={CLASSNAME} data-dot='manufactures'>
				{dataLoaded ? (
					hasResults ? (
						Object.keys(brandsGroups).map((value) => {
							const brands = brandsGroups[value]
							const title = value
							return (
								<div key={`${title}__group`} className={`${CLASSNAME}__group`}>
									<div className={`${CLASSNAME}__brands-group-title`}>{title}</div>
									<div className={`${CLASSNAME}__brands-group`}>
										{brands.map((brand) => {
											const { name, id, seoName, logoUrl } = brand

											const url = getBrandUrl(brand)
											const brandSeoName = getBrandSeoName(seoName)
											const imageUrl = logoUrl ? logoUrl + SDN.w200 : undefined

											return (
												<a
													href={url}
													key={id}
													className={`${CLASSNAME}__brands-item`}
													data-dot='item'
													data-dot-data={`{"value": "${name}"}`}
												>
													<BrandIcon
														className={`${CLASSNAME}__brandicon`}
														seoName={brandSeoName}
														categorySeoName={CATEGORIES.PASSENGER_CARS.seoName}
														imageUrl={imageUrl}
													/>
													<span>{name}</span>
												</a>
											)
										})}
									</div>
								</div>
							)
						})
					) : (
						<div className={`${CLASSNAME}__brands-zero`}>
							{localize('AdvancedBrandModelOpener.filterZeroPart1')}
							<strong>{searchText}</strong>
							{localize('AdvancedBrandModelOpener.filterZeroPart2')}
						</div>
					)
				) : (
					<BrandsPlaceholder />
				)}
			</Surface>
		</>
	)
}

BrandsList.propTypes = {
	brandsList: PropTypes.array
}

export default React.memo(BrandsList)
