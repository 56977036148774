import React from 'react'
import Layout from 'app/component/layout/LayoutView'
import ArticlesController from './ArticlesController'
import AutoMotoArticles from './component/autoMotoArticles/AutoMotoArticles'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'

import './ArticlesView.less'
import './ArticlesCS.json'

const CLASSNAME = 'p-uw-articles'
export const CONTENT_CLASSNAME = `${CLASSNAME}__content`

const ArticlesView = ({
	[ArticlesController.STATE_KEYS.ARTICLES_TITLE]: title,
	[ArticlesController.STATE_KEYS.ARTICLES]: articles
}) => {
	return (
		<Layout className={CLASSNAME}>
			<div className={CONTENT_CLASSNAME}>
				<BackToHpButton />
				<h1 className={`${CLASSNAME}__header`}>{title}</h1>
				<AutoMotoArticles articles={articles} />
			</div>
		</Layout>
	)
}

export default ArticlesView
