import React from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import { Button } from '@sznds/react'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import { Format } from '@inzeraty/helpers'
import { CEBIA_BASE_URL } from 'app/base/Constants'

import './GetCert.less'
import './GetCertCS.json'

const CLASSNAME = 'c-history-get-cert'

// Format.dateShort vrati datum bez mesice jestli jde o prvni mesic
const DATE_WITHOUT_FIRT_MONTH = true

const GetCert = ({ advertEntity }) => {
	const localize = useLocalize()

	const { vin, name, fuelCb = {}, tachometer, manufacturingDate, engineVolume } = advertEntity

	const { name: fuelName } = fuelCb

	return (
		<div className={`${CLASSNAME}__content`}>
			<p className={`${CLASSNAME}__note`}>{localize('GetCert.moreText')}</p>
			<dl>
				<dt className={`${CLASSNAME}__label`}>{localize('GetCert.carTypeAndModel')}</dt>
				<dd className={`${CLASSNAME}__value`}>
					{localize('GetCert.carTypeAndModelText', {
						NAME: name,
						DATE: Format.dateShort(manufacturingDate, DATE_WITHOUT_FIRT_MONTH)
					})}
				</dd>
			</dl>
			{!!fuelName && !!engineVolume && !!tachometer && (
				<dl>
					<dt className={`${CLASSNAME}__label`}>{localize('GetCert.carEngine')}</dt>
					<dd className={`${CLASSNAME}__value`}>
						{localize('GetCert.carEngineText', {
							ENGINE_VOLUME: Format.number(engineVolume),
							FUEL_NAME: fuelName,
							TACHOMETER: Format.number(tachometer)
						})}
					</dd>
				</dl>
			)}
			{!!vin && (
				<dl>
					<dt className={`${CLASSNAME}__label`}>{localize('GetCert.vinCode')}</dt>
					<dd className={`${CLASSNAME}__value`}>{vin}</dd>
				</dl>
			)}

			<Button
				primary={true}
				text={localize('GetCert.checkCar')}
				className={`${CLASSNAME}__submit`}
				target='cebia'
				href={`${CEBIA_BASE_URL}/Home?vin=${vin}&utm_source=Karta_vozu_tlacitko&utm_medium=formular&utm_content=Landing_page_provereni&utm_campaign=Sauto_2013`}
			/>
		</div>
	)
}

GetCert.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes).isRequired
}

export default React.memo(GetCert)
