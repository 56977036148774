import ClientBaseController from 'app/base/ClientBaseController'
import STATE_KEYS from './BoardBehindWindscreenStateKeys'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'

class BoardBehindWindscreenController extends ClientBaseController {
	constructor(dependenciesHelper, advertService) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._advertService = advertService
	}

	load() {
		const advertId = this._getAdvertId()

		return super.load({
			[STATE_KEYS.ADVERT_ENTITY]: this._getAdvertEntity(advertId)
		})
	}

	_getAdvertId() {
		const { URL_APP_PARAMS } = AdvertAdminUrlConvertor.constants
		const { [URL_APP_PARAMS.ADVERT_ID]: advertId } = this.getRouteParams()

		return advertId
	}

	async _getAdvertEntity(advertId) {
		try {
			// pokusime se stahnout detail inzeratu s parametrem pro operativni leasing
			return await this._advertService.getAdvertDetail(Number(advertId), { operating_lease: true })
		} catch {
			// pri chybe stahneme obycejny detail inzeratu, pokud dojde k nejake dalsi
			// chybe zde, tak tu chybu uz uvidi uzivatel (404 atd.)
			return await this._advertService.getAdvertDetail(Number(advertId))
		}
	}
}

export default BoardBehindWindscreenController
