import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import * as FormLines from '@inzeraty/form-lines'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'

const FORM_IDS = {
	VERTICAL: 'vertical',
	HORIZONTAL: 'horizontal'
}

export default class IASeoFooterEditController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, adminService, seoService, entityFormExtension) {
		super(dependenciesHelper)
		this._adminService = adminService
		this._seoService = seoService
		this._entitityFormExtension = entityFormExtension
	}

	init() {
		super.init()

		this.addExtension(this._entitityFormExtension)
		this._entitityFormExtension.initialize({
			getFormLineEntitiesData: this.getFormLineEntities.bind(this),
			getSubmitPromise: this._getEditFormSubmitPromise.bind(this),
			validations: this._editFormValidations.bind(this),
			handleSubmitSuccess: this._handleEditFormSubmitSuccess.bind(this),
			handleSubmitError: this._handleEditFormSubmitError.bind(this)
		})
	}

	_getEditFormSubmitPromise(formData) {
		const { [FORM_IDS.VERTICAL]: verticalValue, [FORM_IDS.HORIZONTAL]: horizontalValue } = formData
		const params = {
			footer: {
				default: Object.assign({}, this._strToJson(verticalValue), this._strToJson(horizontalValue))
			}
		}
		return this._seoService.saveSeoFooterLinks(params)
	}

	_validateFormLine({ value }) {
		const jsonValue = this._strToJson(value)
		return jsonValue ? '' : 'Zadajte platný JSON'
	}

	_editFormValidations() {
		return {
			[FORM_IDS.HORIZONTAL]: this._validateFormLine.bind(this),
			[FORM_IDS.VERTICAL]: this._validateFormLine.bind(this)
		}
	}

	_handleEditFormSubmitError() {
		return {
			entityFormError: {
				title: 'Nepodařilo se odeslat formulář'
			}
		}
	}

	_handleEditFormSubmitSuccess() {
		this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
			type: StatusMessage.TYPE.SUCCESS,
			title: 'Akce byla úspěšně provedena.',
			text: 'SEO patička byla aktulizována'
		})
	}

	_processSeoFooterLinks(data) {
		let vertical = ''
		let horizontal = ''

		try {
			const { default: defObj } = data
			const { horizontal: horiLinks = {}, vertical: vertLinks = {} } = defObj

			horizontal = JSON.stringify({ horizontal: horiLinks }, null, 2)
			vertical = JSON.stringify({ vertical: vertLinks }, null, 2)
		} catch (error) {
			return []
		}

		const formLineEntities = [
			{
				widget: FormLines.IDS.TEXTAREA,
				id: FORM_IDS.VERTICAL,
				label: 'Vertikální linky',
				value: vertical,
				required: true
			},
			{
				widget: FormLines.IDS.TEXTAREA,
				id: FORM_IDS.HORIZONTAL,
				label: 'Horizontální linky',
				value: horizontal,
				required: true
			}
		]

		return formLineEntities
	}

	_strToJson(str) {
		try {
			return JSON.parse(str)
		} catch (e) {
			return null
		}
	}

	async getFormLineEntities() {
		return this._seoService
			.getSeoFooterLinks({}, { cache: false })
			.then((data) => this._processSeoFooterLinks(data))
	}
}
