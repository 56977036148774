import 'app/base/BaseCS.json'

import ns from 'ima/namespace'
import AbstractController from 'ima/controller/AbstractController'
import ROUTE_NAMES from 'app/base/RouteNames'
import { SERVICE } from 'app/base/Constants'

ns.namespace('app.base')

/**
 * Base controller.
 *
 * @class BaseController
 * @extends ima.controller.AbstractController
 * @namespace app.base
 * @module BaseController
 * @submodule app.base
 */
export default class BaseController extends AbstractController {
	/**
	 * Initializes the controller.
	 *
	 * @constructor
	 * @method constructor
	 */
	constructor(dependenciesHelper) {
		super()

		this._dependenciesHelper = dependenciesHelper

		this._historyStorage = this._dependenciesHelper.getDependency('historyStorage')
		this._cacheHelper = this._dependenciesHelper.getDependency('cacheHelper')
		this._favoriteExtension = this._dependenciesHelper.getExtension('favoriteExtension')
		this._utils = this._dependenciesHelper.getDependency('utils')
		this._ribbonExtension = this._dependenciesHelper.getExtension('ribbonExtension')
	}

	load(state = {}) {
		return Object.assign(state)
	}

	/**
	 * @inheritdoc
	 * @override
	 * @method init
	 */
	init() {
		if (this._dependenciesHelper) {
			this._dependenciesHelper.addExtensionsToController(this)
		}

		if (this._ribbonExtension) {
			this._ribbonExtension.setServiceId(SERVICE)

			this._ribbonExtension.setRouteNamesWithRibbon(
				[
					ROUTE_NAMES.ERROR,
					ROUTE_NAMES.NOT_FOUND,
					ROUTE_NAMES.REGRESSION_TEST,
					...Object.values(ROUTE_NAMES.USERWEB),
					...Object.values(ROUTE_NAMES.CLIENT_ADMIN)
				],
				true
			)
		}
	}

	/**
	 * Presmeruje na HP
	 *
	 * @method _redirectToHP
	 * @private
	 **/
	_redirectToHP(options = {}) {
		const router = this._utils.$Router
		const hpUrl = router.link(ROUTE_NAMES.USERWEB.HOMEPAGE)

		router.redirect(hpUrl, options)
	}

	/**
	 * Set seo params.
	 *
	 * @method setSeoParams
	 * @param {Object} loadedResources
	 * @param {ima.meta.MetaManager} metaManager
	 * @param {ima.router.Router} router
	 * @param {ima.dictionary.Dictionary} dictionary
	 * @param {Object} settings
	 */
	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		const title = dictionary.get('Base.titlePromo') + dictionary.get('Base.titleService')
		const description = dictionary.get('Base.titlePromo') + dictionary.get('Base.titleService')
		const keywords = dictionary.get('Base.keywords')
		const image = router.getDomain() + settings.$Static.image + '/og-image.jpg'

		const url = router.getUrl()

		metaManager.setTitle(title)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('keywords', keywords)

		metaManager.setMetaName('twitter:title', title)
		metaManager.setMetaName('twitter:description', description)
		metaManager.setMetaName('twitter:card', 'summary')
		metaManager.setMetaName('twitter:image', image)
		metaManager.setMetaName('twitter:url', url)

		metaManager.setMetaProperty('og:title', title)
		metaManager.setMetaProperty('og:description', description)
		metaManager.setMetaProperty('og:type', 'website')
		metaManager.setMetaProperty('og:image', image)
		metaManager.setMetaProperty('og:url', url)
	}

	/**
	 * Nastaví state pro změnu v layoutProps (proměnné pro komponenetu layoutu).
	 *
	 * @method setLayoutPropsState
	 * @public
	 * @param {Object} newLayoutProps
	 */
	setLayoutPropsState(newLayoutProps = {}) {
		const { layoutProps = {} } = this.getState()

		const layoutPropsState = Object.assign({}, layoutProps, newLayoutProps)

		this.setState({
			layoutProps: layoutPropsState
		})
	}

	/**
	 * Reset timestampu pro vypis inzeratu a smazani cache
	 *
	 * @method onResetCache
	 * @param {String} objectStoreName
	 * @public
	 */
	onResetCache(objectStoreName = '') {
		if (objectStoreName) {
			this._cacheHelper.deleteEntities(objectStoreName)
		} else {
			this._cacheHelper.resetCache()
		}
	}
}

ns.app.base.BaseController = BaseController
