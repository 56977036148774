import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import EntityFormPopup from '../entityFormPopup/EntityFormPopup'
import { useLocalize } from 'app/base/componentHelpers'
import { Button, Checkbox, Input } from '@sznds/react'
import { StatusMessage } from '@inzeraty/components'

import './DissectPremisePopup.less'
import './DissectPremisePopupCS.json'

const CLASSNAME = 'c-dissect-premise-popup'

const DissectPremisePopup = ({
	premiseId,
	statusErrorData: {
		statusMessage: propsStatusMessage,
		oldAskIdErrorMessage: propsOldAskIdErrorMessage
	},
	onClose,
	onSubmit
}) => {
	const localize = useLocalize()

	const [isProcessing, setIsProcessing] = useState(false)
	const [oldAskId, setOldAskId] = useState('')
	const [statusMessage, setStatusMessage] = useState(propsStatusMessage)
	const [oldAskIdErrorMessage, setOldAskIdErrorMessage] = useState(propsOldAskIdErrorMessage)
	const [withAdverts, setWithAdverts] = useState(false)

	useEffect(() => {
		setOldAskIdErrorMessage(getOldAskIdErrorMessage())
	}, [oldAskId])

	useEffect(() => {
		setStatusMessage(propsStatusMessage)
		if (propsStatusMessage) {
			setIsProcessing(false)
		}
	}, [propsStatusMessage])

	useEffect(() => {
		setOldAskIdErrorMessage(propsOldAskIdErrorMessage)
		if (propsOldAskIdErrorMessage) {
			setIsProcessing(false)
		}
	}, [propsOldAskIdErrorMessage])

	const onDissectPremise = () => {
		if (!oldAskId) {
			setOldAskIdErrorMessage(getOldAskIdErrorMessage())
		} else {
			setOldAskIdErrorMessage('')
			setStatusMessage('')
			setIsProcessing(true)

			onSubmit({
				oldAskId,
				withAdverts
			})
		}
	}

	const getOldAskIdErrorMessage = () => {
		if (!oldAskId) {
			return localize('DissectPremisePopup.oldAskIdErrorRequired')
		} else if (parseFloat(oldAskId) === parseFloat(premiseId)) {
			return localize('DissectPremisePopup.equalPremiseAndOldAskIdError')
		} else {
			if (String(parseFloat(oldAskId)) !== oldAskId) {
				return localize('DissectPremisePopup.oldAskIdErrorNotValid')
			} else {
				return ''
			}
		}
	}

	return (
		<EntityFormPopup onClose={onClose} title={localize('DissectPremisePopup.dissectPremise')}>
			<div className={CLASSNAME}>
				{!!statusMessage && (
					<StatusMessage
						type={StatusMessage.TYPE.ERROR}
						title={statusMessage}
						className={`${CLASSNAME}__status-error`}
					/>
				)}
				<div className={`${CLASSNAME}__description`}>
					{localize('DissectPremisePopup.dissectDescription')}
				</div>
				<div className={`${CLASSNAME}__input-wrap`}>
					<Input
						type='number'
						value={oldAskId}
						onChange={(e) => {
							setOldAskId(e.target.value)
						}}
						error={!!oldAskIdErrorMessage}
						placeholder={localize('DissectPremisePopup.setOldAskIdPlaceholder')}
						className={`${CLASSNAME}__input`}
					/>
					{!!oldAskIdErrorMessage && (
						<div className={`${CLASSNAME}__error`}>{oldAskIdErrorMessage}</div>
					)}
				</div>
				<Checkbox
					checked={withAdverts}
					onChange={(e) => {
						setWithAdverts(e.target.checked)
					}}
					label={localize('DissectPremisePopup.dissectWithAdverts')}
					className={`${CLASSNAME}__checkbox`}
				/>
				<Button
					disabled={!!oldAskIdErrorMessage}
					primary={true}
					loading={isProcessing}
					text={localize('DissectPremisePopup.dissectPremise')}
					className={`${CLASSNAME}__btn-submit`}
					onClick={onDissectPremise}
				/>
			</div>
		</EntityFormPopup>
	)
}

DissectPremisePopup.propTypes = {
	premiseId: PropTypes.number.isRequired,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	statusErrorData: PropTypes.shape({
		statusMessage: PropTypes.string,
		oldAskIdErrorMessage: PropTypes.string
	})
}

DissectPremisePopup.defaultProps = {
	onClose: DEFAULT_PROPS.FUNCTION,
	onSubmit: DEFAULT_PROPS.FUNCTION,
	statusErrorData: {
		statusMessage: '',
		oldAskIdErrorMessage: ''
	}
}

export default React.memo(DissectPremisePopup)
