import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Context from 'ima/page/context'
import { Button } from '@sznds/react'
import { NOTES_OUTLINE, TRASH_OUTLINE } from '@sznds/icons'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { StatusMessage } from '@inzeraty/components'
import operatingLeasePropTypes, {
	operatingLeaseDefinitionPropTypes
} from 'app/model/operatingLease/OperatingLeasePropTypes'
import { useLocalize } from 'app/base/componentHelpers'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'

import 'app/base/BaseCS.json'
import './OperatingLeaseVariantsListCS.json'
import './OperatingLeaseVariantsList.less'

const CLASSNAME = 'c-operating-lease-variants-list'

const OperatingLeaseVariant = ({
	operatingLeaseDefinitionData = DEFAULT_PROPS.ARRAY,
	operatingLeaseVariant = DEFAULT_PROPS.OBJECT,
	onEdit = DEFAULT_PROPS.FUNCTION,
	deleteOperatingLeaseVariant = DEFAULT_PROPS.FUNCTION
}) => {
	const { $Dispatcher } = useContext(Context)
	const localize = useLocalize()
	const [isDeleteRunning, setIsDeleteRunning] = useState(false)

	const isPlaceholderShown = !operatingLeaseDefinitionData.length

	const { annualDistance, period, servicesCb = [], priceWithoutVat } = operatingLeaseVariant

	const periodText = localize('Base.month', {
		MONTH: period,
		MONTH_FORMATTED: Format.number(period)
	})
	const annualDistanceText = `${Format.number(annualDistance)} ${localize('Base.KM')}`
	const servicesText = servicesCb.length
		? servicesCb.map(({ name: label }) => label).join(', ')
		: localize('OperatingLeaseVariantsList.noServices')
	const priceWithoutVatText = `${Format.number(priceWithoutVat)} ${localize('Base.CZK')}`

	const handleEditButtonClick = () => onEdit(operatingLeaseVariant)

	const handleDeleteButtonClick = async () => {
		try {
			setIsDeleteRunning(true)
			await deleteOperatingLeaseVariant(operatingLeaseVariant)
		} catch (e) {
			$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				title: localize('OperatingLeaseVariantsList.deleteError'),
				type: StatusMessage.TYPE.ERROR
			})
		} finally {
			setIsDeleteRunning(false)
		}
	}

	return (
		<div className={`${CLASSNAME}__variant`}>
			<div className={`notranslate ${CLASSNAME}__variant-text`} translate='no' lang='cs-cz'>
				{[periodText, annualDistanceText, servicesText, priceWithoutVatText].join(' | ')}
			</div>
			<div className={`${CLASSNAME}__variant-buttons`}>
				<Button
					className={`${CLASSNAME}__edit-button`}
					surface={0}
					icon={NOTES_OUTLINE}
					size='x-small'
					title={localize('OperatingLeaseVariantsList.edit')}
					disabled={isPlaceholderShown}
					onClick={handleEditButtonClick}
				/>
				<Button
					className={`${CLASSNAME}__delete-button`}
					surface={0}
					icon={TRASH_OUTLINE}
					size='x-small'
					title={localize('OperatingLeaseVariantsList.delete')}
					loading={isDeleteRunning}
					disabled={isPlaceholderShown}
					onClick={handleDeleteButtonClick}
				/>
			</div>
		</div>
	)
}

OperatingLeaseVariant.propTypes = {
	operatingLeaseDefinitionData: PropTypes.arrayOf(
		PropTypes.shape(operatingLeaseDefinitionPropTypes)
	),
	operatingLeaseVariant: PropTypes.shape(operatingLeasePropTypes),
	onEdit: PropTypes.func,
	deleteOperatingLeaseVariant: PropTypes.func
}

const OperatingLeaseVariantsList = ({
	operatingLeaseDefinitionData,
	operatingLeaseVariants = DEFAULT_PROPS.ARRAY,
	onEdit,
	deleteOperatingLeaseVariant
}) => {
	const localize = useLocalize()

	return (
		<div className={CLASSNAME} data-e2e='operating-lease-variants-list'>
			<div className={`${CLASSNAME}__heading`}>
				{localize('OperatingLeaseVariantsList.heading')}
			</div>
			<div>
				{operatingLeaseVariants.map((variant) => (
					<OperatingLeaseVariant
						key={variant.id}
						operatingLeaseDefinitionData={operatingLeaseDefinitionData}
						operatingLeaseVariant={variant}
						onEdit={onEdit}
						deleteOperatingLeaseVariant={deleteOperatingLeaseVariant}
					/>
				))}
			</div>
		</div>
	)
}

OperatingLeaseVariantsList.propTypes = {
	operatingLeaseDefinitionData: PropTypes.arrayOf(
		PropTypes.shape(operatingLeaseDefinitionPropTypes)
	),
	operatingLeaseVariants: PropTypes.arrayOf(PropTypes.shape(operatingLeasePropTypes)),
	onEdit: PropTypes.func,
	deleteOperatingLeaseVariant: PropTypes.func
}

export default OperatingLeaseVariantsList
