import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import operatingLeasePropTypes from 'app/model/operatingLease/OperatingLeasePropTypes'
import { useLocalize } from 'app/base/componentHelpers'
import OperatingLeaseServiceRadio from './OperatingLeaseServiceRadio'

import './OperatingLeaseDetailServices.less'
import './OperatingLeaseDetailServicesCS.json'

const CLASSNAME = 'c-operating-lease-detail-services'

const OperatingLeaseDetailServices = ({
	isLoading = false,
	variants = DEFAULT_PROPS.ARRAY,
	selectedVariant = DEFAULT_PROPS.OBJECT,
	onSelect = DEFAULT_PROPS.FUNCTION
}) => {
	const localize = useLocalize()

	return (
		<div className={`${CLASSNAME}`}>
			{isLoading ? (
				[0, 1].map((placeholderId) => (
					<div key={placeholderId} className={`${CLASSNAME}__radio`}>
						<div className={`${CLASSNAME}__placeholder`} />
					</div>
				))
			) : (
				<>
					{localize('OperatingLeaseDetailServices.services')}
					{variants.map((variant) => (
						<OperatingLeaseServiceRadio
							key={variant.id}
							className={`${CLASSNAME}__radio`}
							variant={variant}
							checked={selectedVariant.id === variant.id}
							priceDiff={variant.priceWithoutVat - (selectedVariant.priceWithoutVat || 0)}
							onSelect={onSelect}
						/>
					))}
				</>
			)}
		</div>
	)
}

OperatingLeaseDetailServices.propTypes = {
	isLoading: PropTypes.bool,
	variants: PropTypes.arrayOf(PropTypes.shape(operatingLeasePropTypes)),
	selectedVariant: PropTypes.shape(operatingLeasePropTypes),
	onSelect: PropTypes.func
}

export default OperatingLeaseDetailServices
