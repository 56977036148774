import React, { useContext } from 'react'
import { EDIT_FILLED, TRASH_FILLED, EMOJI_ACTIVATED_FILLED, LOCKED_FILLED } from '@sznds/icons'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import Context from 'ima/page/context'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { useFire } from 'app/base/componentHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import ActionButton from 'app/component/actionButton/ActionButton'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import ROUTE_NAMES from 'app/base/RouteNames'
import IARoleListController from './IARoleListController'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { PaginationEntity, RoleEntity } from '@inzeraty/models'
import EntityForm from 'app/component/entityForm/EntityForm'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import EntityFormPopup from '../components/entityFormPopup/EntityFormPopup'
import TableHead from 'app/component/tableHead/TableHead'
import AddNewButton from '../components/addNewButton/AddNewButton'

const { ACTIONS } = IARoleListController

export default function IARoleListView({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isEditRolePopupOpened,
	formLineEntities = DEFAULT_PROPS.ARRAY,
	entityFormError,
	isWaiting,
	isLoading
}) {
	const { $Router } = useContext(Context)
	const { rights = [] } = useContext(AdminEntityContext) || {}
	const fire = useFire()

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		const actions = []

		if (rights.includes(ADMIN_RIGHTS.ROLES_EDIT)) {
			actions.push((entity, actionButtonProps = {}) => (
				<ActionButton
					icon={EDIT_FILLED}
					key='editovat'
					title='Editovat roli'
					onClick={() =>
						fire('action', {
							action: ACTIONS.OPEN_EDIT_ROLE_POPUP,
							entities: [entity]
						})
					}
					{...actionButtonProps}
				/>
			))
		}

		actions.push(({ id }, actionButtonProps = {}) => (
			<ActionButton
				icon={LOCKED_FILLED}
				key='prava'
				title='Upravit práva'
				href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.RIGHTS, { id })}
				{...actionButtonProps}
			/>
		))

		if (rights.includes(ADMIN_RIGHTS.ADMINS_VIEW)) {
			actions.push(({ id, name }, actionButtonProps = {}) => (
				<ActionButton
					icon={EMOJI_ACTIVATED_FILLED}
					key='admini'
					title={`Admini s rolí ${name}`}
					href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADMIN_LIST, {
						[InternalAdminUrlConvertor.constants.ROLE_ID]: id
					})}
					{...actionButtonProps}
				/>
			))
		}

		if (rights.includes(ADMIN_RIGHTS.ROLES_DELETE)) {
			actions.push((entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='smazat'
					title='Smazat roli'
					onClick={() => action(ACTIONS.DELETE, [entity])}
					{...actionButtonProps}
				/>
			))
		}

		return actions
	}

	return (
		<Layout title='Správa rolí'>
			{rights.includes(ADMIN_RIGHTS.ROLES_CREATE) && (
				<AddNewButton
					text='Přidat roli'
					onClick={() =>
						fire('action', {
							action: ACTIONS.OPEN_EDIT_ROLE_POPUP
						})
					}
				/>
			)}

			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHead key='id' alignRight={true}>
						ID
					</TableHead>,
					<TableHead key='name'>Název</TableHead>
				]}
				rowCells={[({ id }) => [id, { isNumber: true }], ({ name }) => name]}
				actions={getActions()}
				isLoading={isLoading}
			/>

			{isEditRolePopupOpened && (
				<EntityFormPopup
					title={
						formLineEntities.find((f) => f.id === 'id' && f.value) ? 'Editace role' : 'Nová role'
					}
					onClose={() =>
						fire('action', {
							action: ACTIONS.CLOSE_EDIT_ROLE_POPUP
						})
					}
				>
					<EntityForm
						variant={VARIANT.POPUP_FORM}
						formLineEntities={formLineEntities}
						entityFormError={entityFormError}
						isWaiting={isWaiting}
					/>
				</EntityFormPopup>
			)}
		</Layout>
	)
}

IARoleListView.propTypes = {
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(RoleEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	isEditRolePopupOpened: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	isWaiting: PropTypes.bool,
	isLoading: PropTypes.bool
}
