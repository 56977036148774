import ns from 'ima/namespace'
import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'

ns.namespace('app.page.userweb.advertDetail')

/**
 * @class AdvertDetailUrlConvertor
 * @namespace app.page.userweb.advertDetail
 * @module app
 * @submodule app.page
 */
export default class AdvertDetailUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				ADVERT_ID: 'advertId',
				ADVERT_ID_OLD: 'id', // jedná se o id inzerátu používané v historických variantách url
				CATEGORY_NAME: 'categoryName',
				BRAND_NAME: 'brandName',
				MODEL_NAME: 'modelName',
				PREVIEW: 'preview', // pro zobrazeni nahledu pri vkladani noveho inzeratu,
				OPERATING_LEASE_VARIANT_ID: 'varianta-oper-leasingu' // predvybrana varianta operaku
			},
			URL_APP_PARAMS_VALUES: {
				PREVIEW: 'nahled'
			},
			URL_API_PARAMS: {
				OPERATING_LEASE: 'operating_lease' // bool hodnota, jestli se jedna o operak nebo ne
			}
		})
	}
}

ns.app.page.userweb.advertDetail.AdvertDetailUrlConvertor = AdvertDetailUrlConvertor
