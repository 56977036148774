import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import { Input } from '@sznds/react'

import './TextWidget.less'

export const TEXT_WIDGET_ID = 'text_id'

const CLASSNAME = 'c-text-widget-base'

const TextWidget = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const {
		id,
		placeholder = '',
		disabled = false,
		value,
		required,
		errorMessage,
		extra: { limit } = {}
	} = formLineEntity

	const restProps = Object.assign({}, props)

	delete restProps.formLineEntity
	delete restProps.onChange

	return (
		<Input
			placeholder={placeholder}
			disabled={disabled}
			value={value}
			required={required}
			error={!!errorMessage}
			maxLength={limit}
			{...restProps}
			className={classnames({
				[CLASSNAME]: true,
				[props.className]: !!props.className
			})}
			type='text'
			onChange={(event) => {
				const value = event.target.value

				onChange({
					id,
					value,
					errorMessage: ''
				})
			}}
		/>
	)
}

TextWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string
}

export default TextWidget
