
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ModelAutoComplete'] = {
  placeholder: function(d) { return "Vyberte model"; },
  popupTitle: function(d) { return "Vyberte model"; },
  popupPlaceholder: function(d) { return "Hledat model"; },
  labelFavorite: function(d) { return "Nejhledanější modely"; },
  labelAll: function(d) { return "Všechny modely"; },
  labelFound: function(d) { return "Našli jsme"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	