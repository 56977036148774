import React, { useState } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { Widget } from '@inzeraty/form-lines'
import { LabelWrapper, Input } from '@sznds/react'
import { Popup } from '@inzeraty/components'
import TextLink from 'app/component/textLink/TextLink'

import './VINWidget.less'
import './VINWidgetCS.json'

export const VIN_WIDGET_ID = 'vin'

const CLASSNAME = 'c-vin-widget'

const VINWidget = (props) => {
	const localize = useLocalize()

	const [isVINExplanationPopupOpened, setIsVINExplanationPopupOpened] = useState(false)

	const renderVINExplanationButton = () => {
		return (
			<TextLink
				className={`${CLASSNAME}__button`}
				onClick={() => setIsVINExplanationPopupOpened(true)}
			>
				{localize('VINWidget.vinExplanation')}
			</TextLink>
		)
	}

	const renderWidget = (
		formLineEntity,
		renderLabel,
		renderInput,
		renderMessage,
		renderErrorMessage
	) => {
		return (
			<div className={`${CLASSNAME}__widget`}>
				<LabelWrapper
					label={formLineEntity.label}
					description={renderMessage()}
					errorDescription={renderErrorMessage()}
				>
					{renderInput()}
				</LabelWrapper>

				<div className={`${CLASSNAME}__vin-explanation`}>{renderVINExplanationButton()}</div>
			</div>
		)
	}

	const renderInput = (formLineEntity, props) => {
		return <Input {...props} error={!!formLineEntity.errorMessage} />
	}

	const renderMessage = (formLineEntity, props) => {
		return <span {...props}>{formLineEntity.message}</span>
	}

	const renderErrorMessage = (formLineEntity, props) => {
		return <span {...props}>{formLineEntity.errorMessage}</span>
	}

	const renderPopup = () => {
		return (
			<Popup
				title={localize('VINWidget.vinExplanationTitle')}
				isOpen={isVINExplanationPopupOpened}
				onClose={() => setIsVINExplanationPopupOpened(false)}
				dictionary={{
					close: localize('VINWidget.close')
				}}
				hasLightbox={true}
			>
				<p>{localize('VINWidget.vinExplanationTextFirst')}</p>

				<div className={`${CLASSNAME}__licence-picture`} />

				<p>{localize('VINWidget.vinExplanationTextSecond')}</p>
			</Popup>
		)
	}

	return (
		<div className={CLASSNAME}>
			<Widget
				{...props}
				name={VIN_WIDGET_ID}
				renderWidget={renderWidget}
				renderInput={renderInput}
				renderMessage={renderMessage}
				renderErrorMessage={renderErrorMessage}
			/>

			{isVINExplanationPopupOpened && renderPopup()}
		</div>
	)
}

export default React.memo(VINWidget)
