import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { COLOR_CODES } from 'app/base/Constants'
import Split from '../../split/Split'
import BasicSelectWidget from '../basicSelect/BasicSelectWidget'

import './ColorAndToneSelect.less'
import './ColorAndToneSelectCS.json'

export const COLOR_AND_TONE_SELECT_WIDGET_ID = 'color_and_tone_select_widget'

const CLASSNAME = 'c-color-tone-select-widget'

const CUSTOM_COLOR_FORM_LINE_ID = 'color'
const CUSTOM_COLOR_TONE_FORM_LINE_ID = 'color-tone'

const ColorAndToneSelect = (props) => {
	const localize = useLocalize()

	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		onChange = DEFAULT_PROPS.FUNCTION,
		colorSelectId
	} = props

	const renderSelectedColor = (colorOption = {}) => {
		return (
			<>
				<span
					className={`${CLASSNAME}__color`}
					style={{
						backgroundColor: COLOR_CODES[colorOption.value]
					}}
				/>
				<span>{colorOption.name}</span>
			</>
		)
	}

	const onColorChange = (change) => {
		const { value: newColor } = change
		const { id, value: [, currentColorTone] = [] } = formLineEntity

		onChange(
			Object.assign(
				{},
				change,
				{ id },
				{
					value: [newColor, currentColorTone]
				}
			)
		)
	}

	const onColorToneChange = (change) => {
		const { value: newColorTone } = change
		const { id, value: [currentColor] = [], extra: { cebiaError } = {} } = formLineEntity

		onChange(
			Object.assign(
				{},
				change,
				{ id },
				{
					value: [currentColor, newColorTone]
				}
			)
		)

		// pri zmene value v predchozim volani automaticky dojde ke smazani priznaku o
		// chybe z Cebie. Timto volanim puvodni priznak obnovime.
		onChange({
			id,
			extra: Object.assign({}, formLineEntity.extra, { cebiaError })
		})
	}

	const { value: [currentColorValue, currentColorToneValue] = [] } = formLineEntity

	return (
		<Split
			className={CLASSNAME}
			renderLeft={() => (
				<BasicSelectWidget
					inputId={colorSelectId}
					formLineEntity={FormLines.createEntity(
						Object.assign({}, formLineEntity, {
							id: CUSTOM_COLOR_FORM_LINE_ID,
							label: localize('ColorAndToneSelect.color'),
							placeholder: localize('ColorAndToneSelect.colorPlaceholder'),
							value: currentColorValue
						})
					)}
					onChange={onColorChange}
					renderSelectedOption={renderSelectedColor}
				/>
			)}
			renderRight={() => {
				const extra = Object.assign({}, formLineEntity.extra)

				delete extra.cebiaError

				return (
					<BasicSelectWidget
						formLineEntity={FormLines.createEntity(
							Object.assign({}, formLineEntity, {
								id: CUSTOM_COLOR_TONE_FORM_LINE_ID,
								label: localize('ColorAndToneSelect.colorTone'),
								required: false,
								placeholder: localize('ColorAndToneSelect.colorTonePlaceholder'),
								value: currentColorToneValue,
								options: formLineEntity.extra.options,
								errorMessage: undefined,
								extra
							})
						)}
						onChange={onColorToneChange}
					/>
				)
			}}
		/>
	)
}

ColorAndToneSelect.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	colorSelectId: PropTypes.string
}

export default React.memo(ColorAndToneSelect)
