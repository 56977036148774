const RESOURCE = '/catalog/public'

/**
 * @class CatalogResource
 */
export default class CatalogResource {
	constructor(restClient) {
		this._restClient = restClient
	}

	getBrands(options = {}) {
		return this._restClient.get(RESOURCE, {}, options)
	}

	getModels(params, options = {}) {
		return this._restClient.get(RESOURCE, params, options)
	}
}
