import React, { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import { ArticleEntity } from '@inzeraty/models'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { RESPONSIVE } from 'app/base/Constants'
import IMAGES from 'app/base/ImagesConstants'
import { getSource, getImageSDNFilter } from './articlesHelper'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'

import './GarazArticles.less'
import './GarazArticlesCS.json'

const CLASSNAME = 'c-garaz-articles'

const GarazArticles = memo(({ articleEntities = DEFAULT_PROPS.ARRAY }) => {
	const localize = useLocalize()
	const context = useContext(Context)
	const router = context.$Router

	const renderImage = (imageUrl, extraData) => {
		if (imageUrl) {
			const sizes = `
                    (min-width: ${RESPONSIVE.DESKTOP}px) 225px,
                    (min-width: ${RESPONSIVE.MOBILE_WIDE}px) calc((100vw - 24px - 16px - 24px) / 2),
                    calc(100vw - 2 * 16px)
                `

			return (
				<picture>
					{getSource(imageUrl, 'webp', extraData, sizes)}
					{getSource(imageUrl, 'jpg', extraData, sizes)}
					<img
						src={imageUrl + getImageSDNFilter(extraData, 'jpg', 600)}
						className={`${CLASSNAME}__img`}
						alt=''
						loading='lazy'
					/>
				</picture>
			)
		} else {
			return <img src={IMAGES['image__garaz-placeholder']} className={`${CLASSNAME}__img`} alt='' />
		}
	}

	const renderArticle = ({ title, imageUrl, url, extraData }) => {
		return (
			<a
				key={url}
				href={url}
				className={`${CLASSNAME}__article`}
				data-dot='article'
				rel='noopener noreferrer'
				target='_blank'
			>
				<span className={`${CLASSNAME}__img-wrap`}>{renderImage(imageUrl, extraData)}</span>
				<span className={`${CLASSNAME}__article-title`}>{title}</span>
			</a>
		)
	}

	if (articleEntities.length) {
		const articlesUrl = router.link(ROUTE_NAMES.USERWEB.ARTICLES)

		return (
			<div className={CLASSNAME}>
				<div className={`${CLASSNAME}__wrap`} data-dot='hp-articles'>
					<a href={articlesUrl} className={`${CLASSNAME}__main-link`}>
						{localize('GarazArticles.mainLink')}
					</a>

					<a href={articlesUrl} className={`${CLASSNAME}__sec-link`} data-dot='go-to-web'>
						{localize('GarazArticles.secondaryLink')}
					</a>

					<div className={`${CLASSNAME}__articles`}>{articleEntities.map(renderArticle)}</div>
				</div>
			</div>
		)
	} else {
		return null
	}
})

GarazArticles.propTypes = {
	articleEntities: PropTypes.arrayOf(PropTypes.instanceOf(ArticleEntity))
}

export default GarazArticles
