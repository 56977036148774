import { AbstractFactory } from '@inzeraty/models'
import { Format } from '@inzeraty/helpers'
import PaymentEntity from './PaymentEntity'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'

/**
 * Vrátí naformátovanou cenu v korunách, která byla zadána v halířích.
 *
 * @method _getFormatedPrice
 * @private
 * @param {Number} price Cena v halířích
 * @return {String} Naformátovaná cena v korunách
 */
export const getFormatedPrice = (price) => {
	return !isNullOrUndefined(price) ? Format.number(price / 100) : price
}

export default class PaymentFactory extends AbstractFactory {
	constructor() {
		super(PaymentEntity)
	}

	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformData(data = {}) {
		return {
			advertPublicationLength: data.advert_publication_length,
			price: getFormatedPrice(data.price),
			credit: getFormatedPrice(data.credit),
			paymentType: data.payment_type
		}
	}

	/**
	 * Vytvori pole entit platebnich metod
	 *
	 * @method createPaymentsEntityList
	 * @public
	 * @param  {Object} data data o vsech platebnich metodach
	 * @return {Array} data pole entit platebnich metod
	 */
	createPaymentsEntityList(data = []) {
		return data.map((payment) => this.transformData(payment))
	}
}
