import RegionService from 'app/model/region/RegionService'
import DistrictService from 'app/model/district/DistrictService'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import UserwebBaseController from 'app/base/UserwebBaseController'
import ROUTE_NAMES from 'app/base/RouteNames'
import STATUS_CODE from 'app/base/HttpStatusCode'
import SellerListUrlConvertor from './SellerListUrlConvertor'
import SellerListMeta from './SellerListMeta'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import { CATEGORIES } from 'app/base/Constants'

export default class SellerListController extends UserwebBaseController {
	constructor(dependenciesHelper, premiseService, categoryService, backToHpExtension) {
		super(dependenciesHelper)

		this._premiseService = premiseService
		this._categoryService = categoryService
		this._backToHpExtension = backToHpExtension

		this._regionEntities = []
		this._districtEntities = []
	}

	static get STATE_KEYS() {
		return Object.assign({
			ROUTE_PARAMS: 'routeParams',
			PREMISES_PAGINATION: 'premisesAndPagination'
		})
	}

	static getSelectedLocality(routeParams = {}) {
		const regionEntities = RegionService.getRegions()
		const districtEntities = DistrictService.getDistricts()
		const { [SellerListUrlConvertor.constants.URL_APP_PARAMS.LOCALITY]: locality } = routeParams

		const updatedRegionEntities = regionEntities.map((regionEntity) =>
			SellerListUrlConvertor.getUpdatedRegionData(regionEntity)
		)

		return {
			selectedRegionEntity: updatedRegionEntities.find(({ seoName }) => seoName === locality),
			seledtedDicstrictEntity: districtEntities.find(({ seoName }) => seoName === locality)
		}
	}

	static get placeholders() {
		const placeholders = []

		for (let i = 0; i < UrlConvertor.constants.DEFAULTS.PER_PAGE; i++) {
			placeholders.push({})
		}

		return placeholders
	}

	init() {
		super.init()

		this.addExtension(this._backToHpExtension)
	}

	load() {
		this._validateUrl()

		return super.load({
			[SellerListController.STATE_KEYS.ROUTE_PARAMS]: this.getRouteParams(),
			[SellerListController.STATE_KEYS.PREMISES_PAGINATION]: this._getPremiseEntities()
		})
	}

	update(prevParams) {
		this._setPremisesPlaceholders()

		return super.update(prevParams, {
			[SellerListController.STATE_KEYS.ROUTE_PARAMS]: this.getRouteParams(),
			[SellerListController.STATE_KEYS.PREMISES_PAGINATION]: this._getPremiseEntities()
		})
	}

	getDataForSsp() {
		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {}
		}
	}

	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const { [SellerListController.STATE_KEYS.PREMISES_PAGINATION]: paginationData = {} } =
			loadedResources || {}
		const { paginationEntity = {} } = paginationData
		const { total = 0 } = paginationEntity

		const routeParams = this.getRouteParams()
		const title = SellerListMeta.getTitle(dictionary, routeParams, total)
		const description = SellerListMeta.getDescriptionValue(dictionary, routeParams, total)

		metaManager.setTitle(title)
		metaManager.setMetaName('description', description)

		metaManager.setMetaName('twitter:title', title)

		metaManager.setMetaProperty('og:title', title)
	}

	async onSellerLinkClick({ id, routeParams }) {
		let routeParamsExtend

		try {
			const categoryEntities = await this._categoryService.getCategoriesIncludingAdvertsCount({
				[SellerUrlConvertor.constants.URL_API_PARAMS.SELLER_ID]: id
			})

			const passangerCarsCategoryEntity = categoryEntities.find(
				({ seoName }) => seoName === CATEGORIES.PASSENGER_CARS.seoName
			)

			if (passangerCarsCategoryEntity?.advertsCount === 0) {
				const { seoName } = categoryEntities.sort((a, b) => b.advertsCount - a.advertsCount)[0]
				routeParamsExtend = { [SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: seoName }
			}
		} catch (err) {
			routeParamsExtend = {}
		} finally {
			const sellerUrl = this._utils.$Router.link(
				ROUTE_NAMES.USERWEB.SELLER,
				Object.assign({}, routeParams, routeParamsExtend)
			)
			this._utils.$Router.redirect(sellerUrl)
		}
	}

	_validateUrl() {
		const routeParams = Object.assign({}, this.getRouteParams())
		const {
			[SellerListUrlConvertor.constants.URL_APP_PARAMS.LETTER]: letter,
			[SellerListUrlConvertor.constants.URL_APP_PARAMS.PER_PAGE]: perPage
		} = routeParams

		if (letter || perPage) {
			delete routeParams[SellerListUrlConvertor.constants.URL_APP_PARAMS.LETTER]
			delete routeParams[SellerListUrlConvertor.constants.URL_APP_PARAMS.PER_PAGE]

			const url = this._utils.$Router.link(ROUTE_NAMES.USERWEB.SELLER_LIST, routeParams)
			this._utils.$Router.redirect(url, {
				httpStatus: STATUS_CODE.MOVED_PERMANENTLY
			})
		}
	}

	_getPremiseEntities() {
		const params = SellerListUrlConvertor.getApiParams(this.getRouteParams())
		return this._premiseService.getPublicPremises(params)
	}

	_setPremisesPlaceholders() {
		this.setState({
			[SellerListController.STATE_KEYS.PREMISES_PAGINATION]: {
				premiseEntities: SellerListController.placeholders,
				paginationEntity: {}
			}
		})
	}
}
