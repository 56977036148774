import React from 'react'
import classnames from 'classnames'
import { Input } from '@sznds/react'
import { Widget } from '@inzeraty/form-lines'
import BasicInfoTooltip from 'app/page/userweb/newAdvert/basicInfo/components/tooltip/BasicInfoTooltip'
import PropTypes from 'prop-types'

import './BasicWidget.less'

export const BASIC_WIDGET_ID = 'basic_widget'

const CLASSNAME = 'c-basic-widget'

const BasicWidget = (props) => {
	const restProps = Object.assign({}, props)

	delete restProps.className

	const { className } = props

	return (
		<Widget
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
			renderWidget={(
				formLineEntity,
				renderLabel,
				renderInput,
				renderMessage,
				renderErrorMessage
			) => {
				const renderedLabel = renderLabel()

				return (
					<>
						{renderedLabel && <div className={`${CLASSNAME}__label-box`}>{renderedLabel}</div>}
						<div className={`${CLASSNAME}__content`}>
							<div className={`${CLASSNAME}__input-wrapper`}>{renderInput()}</div>
							{renderErrorMessage()}
						</div>
					</>
				)
			}}
			renderInput={(formLineEntity, props) => {
				const { className } = props
				const { extra = {} } = formLineEntity
				const { cebiaError } = extra

				return (
					<Input
						{...props}
						className={classnames({
							[className]: true,
							[`${CLASSNAME}__cebia-warn`]: !!cebiaError
						})}
						error={!!formLineEntity.errorMessage}
					/>
				)
			}}
			renderLabel={(formLineEntity, props) => {
				const { label, required } = formLineEntity

				return (
					<>
						<label {...props}>
							{label}
							{required && <span className={`${CLASSNAME}__required`}>*</span>}
						</label>
						<BasicInfoTooltip formLineEntity={formLineEntity} />
					</>
				)
			}}
			onChange={() => {}}
			{...restProps}
		/>
	)
}

BasicWidget.propTypes = {
	className: PropTypes.string
}

export default React.memo(BasicWidget)
