
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ImportDocumentation'] = {
  dataImport: function(d) { return "Import dat na server Sauto.cz"; },
  descriptionServerSauto: function(d) { return "Server Sauto.cz umožňuje svým klientům ukládání dat do databáze nejen on-line webovým formulářem v klientských stránkách, ale také pomocí importu přes rozhraní XML-RPC (případně FastRPC). Tuto možnost mají všichni registrovaní uživatelé, není třeba ji samostatně aktivovat, nebo dokonce připlácet. Množství importovaných dat je omezeno pouze množstvím volných modulů klienta, počet aktualizací během dne není omezen."; },
  descriptionXMLRPC: function(d) { return "XML-RPC aktualizace dat je prováděna rozdílově - klient může inzerát vložit, změnit v něm některé údaje, nebo ho smazat."; },
  descriptionAutentizace: function(d) { return "Autentizace klienta je prováděna pomocí jeho uživatelského jména a challenge-answer procedurou s užitím md5 hashe hesla. Rozhraní poskytuje okamžitou odezvu na každý požadavek."; },
  descriptionDokumentace: function(d) { return "Podrobnou dokumentaci ke XML-RPC rozhraní najdete na "; },
  descriptionHodnotyArgumentu: function(d) { return " Přípustné hodnoty jednotlivých argumentů metod naleznete v číselnících."; },
  descriptionCiselnikModelu: function(d) { return "Číselník modelů, výrobců a druhů aut, kde je pro jednotlivé výrobce uvedeno jaké druhy a modely vyrábějí je na "; },
  descriptionXMLSchema: function(d) { return " a XML schema na "; },
  descriptionCiselnikAktualizace: function(d) { return " Tento číselník je v případě vložení nebo úpravy modelu či výrobce okamžitě aktualizován."; },
  descriptionCiselnikVybaveni: function(d) { return "Číselník vybavení, ve kterém je uvedeno jaké vybavení mohou mít jednotlivé druhy vozidel, je na URL "; },
  descriptionJehoSchema: function(d) { return " a jeho schéma je na "; },
  descriptionCiselnikRegionu: function(d) { return "Číselník regionů je na "; },
  descriptionSchemaNajdete: function(d) { return ", schéma pak najdete na "; },
  globalInformation: function(d) { return "Obecné informace"; },
  codeAddresses: function(d) { return "Adresy číselníků"; },
  schemaAddresses: function(d) { return "Adresy schémat"; },
  contact: function(d) { return "Kontaktní e-mail pro bližší informace k importu: "; },
  descriptionDriveValues: function(d) { return "Hodnoty číselníku pohon jsou na URL "; },
  descriptionDestinationDrive: function(d) { return " Určení, které pohony mohou být nastavené inzerátu dané kategorie naleznete v číselníku na URL "; },
  drive: function(d) { return " - položka drive."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	