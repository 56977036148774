import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import ActionElm from 'app/component/actionElement/ActionElement'

import './AskIdWidget.less'
import './AskIdWidgetCS.json'

export const ASK_ID_WIDGET_ID = 'ask_id_widget'

const CLASSNAME = 'c-cp-ask-id-widget'

function AskIdWidget({ formLineEntity, className = '' }) {
	const localize = useLocalize()
	const fire = useFire()

	return (
		<SimpleData label={formLineEntity.label} className={className}>
			{formLineEntity.value} &nbsp;
			<ActionElm
				type='button'
				onClick={() => {
					fire('setIsDissectPremisePopupOpened', true)
				}}
			>
				{localize('AskIdWidget.dissectPremise')}
			</ActionElm>
		</SimpleData>
	)
}

AskIdWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	className: PropTypes.string
}

export const SimpleData = ({ children, label = '', className = '' }) => {
	return (
		<div className={classnames(CLASSNAME, className)}>
			<span className={`${CLASSNAME}__label`}>{label}</span>
			<span className={`${CLASSNAME}__value`}>{children}</span>
		</div>
	)
}

SimpleData.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string
}

export default AskIdWidget
