import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ImaContext from 'ima/page/context'
import select from 'ima-plugin-select'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import classnames from 'classnames'
import { Button, Spinner } from '@sznds/react'
import { Popup } from '@inzeraty/components'
import * as FormLines from '@inzeraty/form-lines'
import { Format, DateHelpers } from '@inzeraty/helpers'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'
import { getErrorDictionary } from './ReviewFormErrors'
import {
	FORM_LINES_IDS,
	ALTERNATIVE_FORM_LINES_IDS,
	FORM_LINES_NAMES
} from './ReviewFormLineConstants'
import {
	reviewFirstStepFormLineEntities,
	reviewSecondStepFormLineEntities,
	validateFormLines
} from './ReviewFormLines'
import BasicSelectWidget, { BASIC_SELECT_WIDGET_ID } from './widgets/basicSelect/BasicSelectWidget'
import TextWidget, { TEXT_WIDGET_ID } from './widgets/text/TextWidget'
import NumberWidget, { NUMBER_WIDGET_ID } from './widgets/number/NumberWidget'
import TextareaWidget, { TEXTAREA_WIDGET_ID } from './widgets/textarea/TextareaWidget'
import RadiosWidget, { RADIOS_WIDGET_ID } from './widgets/radios/RadiosWidget'
import ReviewsExtension from '../ReviewsExtension'
import { processOptionsForFormLineEntities } from 'app/page/userweb/newAdvert/basicInfo/createPage/processDataForFormLineEntities'
import applyOptionsForFormLineEntities from 'app/page/userweb/newAdvert/basicInfo/createPage/applyOptionsForFormLineEntities'
import ActionElm from 'app/component/actionElement/ActionElement'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import 'app/base/BaseCS'
import './ReviewFormCS.json'
import './ReviewForm.less'
import 'app/page/userweb/newAdvert/basicInfo/BasicInfoCS.json'
import 'app/base/AssignEmailReasonsCS.json'

const { SZNDS_WIDGET_BY_ID, Form } = FormLines

const CLASSNAME = 'c-review-form'

const FORM_STEPS = 2

const WIDGETS_BY_ID = Object.freeze(
	Object.assign(
		{
			[BASIC_SELECT_WIDGET_ID]: BasicSelectWidget,
			[TEXT_WIDGET_ID]: TextWidget,
			[NUMBER_WIDGET_ID]: NumberWidget,
			[TEXTAREA_WIDGET_ID]: TextareaWidget,
			[RADIOS_WIDGET_ID]: RadiosWidget
		},
		SZNDS_WIDGET_BY_ID
	)
)

const ReviewForm = ({ params, createPage, onSubmit }) => {
	const localize = useLocalize()
	const fire = useFire()
	const blindTitleRef = useRef(null)
	const ERROR_DICTIONARY = getErrorDictionary(localize)
	const { $Dictionary } = useContext(ImaContext)

	const [initialStep, setInitialStep] = useState(1)
	const [isOpen, setIsOpen] = useState(false)

	const [isSending, setIsSending] = useState(false)
	const [formLineEntities, setFormLineEntities] = useState([])
	const [step, setStep] = useState(initialStep)
	const [reviewData, setReviewData] = useState(null)
	const [serverErrorValues, setServerErrorValues] = useState([])
	const [isContentScrolled, setIsContentScrolled] = useState(false)

	const contentRef = useRef(null)

	useEffect(() => {
		setServerErrorValues([])
		setStep(initialStep)
		setIsSending(false)

		if (contentRef && contentRef.current && contentRef.current.parentNode) {
			if (isOpen) {
				contentRef.current.parentNode.addEventListener('scroll', getIsContentScrolled, false)
			} else {
				contentRef.current.parentNode.removeEventListener('scroll', getIsContentScrolled, false)
			}
		}
	}, [isOpen])

	useEffect(() => {
		if (params) {
			serDefaultReviewData()
		}
	}, [params])

	useEffect(() => {
		if (reviewData && createPage) {
			const stepFormLineEntities =
				step === 1
					? getFilledFirstStepFormLineEntities(reviewData, createPage)
					: reviewSecondStepFormLineEntities(reviewData, $Dictionary)

			if (step === 1) {
				const [isValid] = validateFormLines(stepFormLineEntities)
				if (isValid) setInitialStep(2)
			}

			setFormLineEntities(FormLines.updateEntities(stepFormLineEntities, serverErrorValues))
		}
	}, [reviewData, createPage, step, serverErrorValues])

	useEffect(() => {
		if (isOpen && createPage) {
			setFocus()
		}
	}, [isOpen, createPage])

	useEffect(() => {
		setIsSending(false)
	}, [serverErrorValues])

	useEffect(() => {
		setStep(initialStep)
	}, [initialStep])

	useEffect(() => {
		if (isOpen) {
			fire('getCreatePageData')
		}
	}, [step, isOpen])

	const getIsContentScrolled = () => {
		setIsContentScrolled(contentRef.current.parentNode.scrollTop > 0)
	}

	const serDefaultReviewData = () => {
		const { [ReviewsExtension.PARAMS.ADVERT_ENTITY]: advertEntity } = params

		const {
			category: { id: categoryId },
			manufacturerCb,
			modelCb,
			fuelCb,
			manufacturingDate,
			inOperationDate,
			enginePower
		} = advertEntity

		const manufacturingDateParsed = DateHelpers.parseDate(manufacturingDate || inOperationDate)

		setReviewData({
			// Pro vyrobce a model inputy nevykreslujeme,
			// jen nastavujeme hodnoty do stavu.
			[FORM_LINES_NAMES.MANUFACTURER_CB]: manufacturerCb,
			[FORM_LINES_NAMES.MODEL_CB]: modelCb,

			[FORM_LINES_NAMES.CATEGORY_ID]: categoryId,
			[FORM_LINES_NAMES.MANUFACTURING_YEAR]: manufacturingDateParsed
				? manufacturingDateParsed.year
				: '',
			[FORM_LINES_NAMES.FUEL_CB]: fuelCb,
			[FORM_LINES_NAMES.ENGINE_POWER]: enginePower,
			[FORM_LINES_NAMES.REVIEW_TITLE]: '',
			[FORM_LINES_NAMES.REVIEW_TYPE]: ReviewsExtension.TYPE.NEUTRAL,
			[FORM_LINES_NAMES.REVIEW_DECRIPTION]: '',
			[FORM_LINES_NAMES.POSITIVES]: '',
			[FORM_LINES_NAMES.NEGATIVES]: ''
		})
	}

	const getFilledFirstStepFormLineEntities = (reviewData, createPage) => {
		const {
			[FORM_LINES_NAMES.MANUFACTURER_CB]: { name: manufacturerCbName, value: manufacturerCbValue },
			[FORM_LINES_NAMES.MODEL_CB]: { name: modelCbName, value: modelCbValue }
		} = reviewData

		const elements = createPage
			? createPage.sections.reduce((allElements, section) => {
					const { elements } = section
					return [...allElements, ...elements]
			  }, [])
			: []

		const formLineEntities = reviewFirstStepFormLineEntities(reviewData, $Dictionary)
			// - vyfiltruji si formLineEntity ktere jsou v creagePage
			.filter((formLineEntity) => !!elements.find((element) => element.name === formLineEntity.id))

		// - vyplnim si options z createPage, krome manufacturerCb a modelCb
		const formLineEntitiesOptions = processOptionsForFormLineEntities(
			createPage,
			$Dictionary
		).filter((opt) => {
			return opt.id !== FORM_LINES_IDS.MANUFACTURER_CB && opt.id !== FORM_LINES_IDS.MODEL_CB
		})

		if (manufacturerCbName && manufacturerCbValue) {
			// - napnim si options pro manufacturerCb
			formLineEntitiesOptions.push({
				id: FORM_LINES_IDS.MANUFACTURER_CB,
				options: [
					{
						id: 1,
						name: manufacturerCbName,
						value: manufacturerCbValue
					}
				]
			})
		}

		if (modelCbName && modelCbValue) {
			// - napnim si options pro modelCb
			formLineEntitiesOptions.push({
				id: FORM_LINES_IDS.MODEL_CB,
				options: [
					{
						id: 1,
						name: modelCbName,
						value: modelCbValue
					}
				]
			})
		}

		return FormLines.updateEntities(
			formLineEntities,
			applyOptionsForFormLineEntities(formLineEntities, formLineEntitiesOptions)
		)
	}

	const setFocus = () => {
		if (blindTitleRef && blindTitleRef.current) {
			blindTitleRef.current.focus()
		}
	}

	const renderForm = () => {
		return (
			<Form
				formLineEntities={formLineEntities}
				isSending={isSending}
				renderWidgets={renderWidgets}
				onSubmit={onSubmitForm}
				className={`${CLASSNAME}__form`}
				renderSubmitButton={renderSubmitButton}
			/>
		)
	}

	const renderWidgets = () => {
		return FormLines.renderWidgetsByIds(
			formLineEntities,
			{
				onChange,
				onBlur
			},
			WIDGETS_BY_ID
		)
	}

	const onChange = ({ id, value }) => {
		setFormLineEntities(
			FormLines.updateEntities(formLineEntities, [
				{
					id,
					value,
					errorMessage: ''
				}
			])
		)
	}

	const onBlur = () => {}

	const updateReviewData = () => {
		const formLinesWithData = formLineEntities
			.filter(({ extra: { getData } = {} }) => Boolean(getData))
			.filter(({ id }) => id !== FORM_LINES_IDS.MANUFACTURER_CB && id !== FORM_LINES_IDS.MODEL_CB)

		const formValues = formLinesWithData.reduce(
			(formValues, { value, extra: { getData } }) =>
				Object.assign(formValues, getData(value, formValues)),
			{}
		)

		setReviewData(Object.assign(reviewData, formValues))
	}

	const onSubmitForm = (event) => {
		const [isValid, invalidFormLineEntities] = validateFormLines(formLineEntities)

		if (isValid) {
			updateReviewData()

			if (step < FORM_STEPS) {
				setStep(step + 1)
			} else {
				setIsSending(true)

				onSubmit(reviewData, onSubmitSuccess, onSubmitError)
			}
		} else {
			const updatedValues = invalidFormLineEntities.map((formLineEntitiy) => {
				const { id, value } = formLineEntitiy

				const errorMessage = value
					? ERROR_DICTIONARY[id][ERROR_CODES.NOT_VALID]
					: ERROR_DICTIONARY[id][ERROR_CODES.REQUIRED]

				return {
					id,
					errorMessage
				}
			})

			setFormLineEntities(FormLines.updateEntities(formLineEntities, updatedValues))
		}
	}

	const onSubmitSuccess = (result) => {
		setIsOpen(false)
		serDefaultReviewData()
	}

	const onSubmitError = (errors = []) => {
		if (!errors.length) {
			setIsSending(false)
		} else {
			const errorValues = []

			errors.forEach((error) => {
				const { error_code: errorCode, fields } = error

				const error_code =
					errorCode === ERROR_CODES.REQUIRED ? ERROR_CODES.REQUIRED : ERROR_CODES.NOT_VALID

				fields.forEach((field_id) => {
					const dictKey = `ReviewForm.${field_id}_${error_code}`

					let errorMessage = $Dictionary.has(dictKey)
						? localize(dictKey)
						: localize(`ReviewForm.value_${error_code}`)

					if (error.forbidden_words) {
						errorMessage = `${localize(
							'ReviewForm.review_text_in_blacklist'
						)} ${error.forbidden_words.join(', ')}`
					}

					let id = field_id

					switch (field_id) {
						case ALTERNATIVE_FORM_LINES_IDS.MANUFACTURING_YEAR: {
							id = FORM_LINES_IDS.MANUFACTURING_DATE
							break
						}

						case ALTERNATIVE_FORM_LINES_IDS.ENGINE_POWER: {
							id = FORM_LINES_IDS.ENGINE_POWER
							break
						}

						case FORM_LINES_NAMES.REVIEW_DECRIPTION: {
							id = FORM_LINES_IDS.REVIEW_DECRIPTION
							break
						}
					}

					errorValues.push({
						id,
						errorMessage
					})
				})

				const firstStepFormLineEntities = getFilledFirstStepFormLineEntities(reviewData, createPage)

				// - dohledam, jestli je error na prvni strance, pokud ano, tak na ni prepnu
				const hasFIrstStepError = fields.filter(
					(fileId) =>
						!!firstStepFormLineEntities.find((formLineEntity) => formLineEntity.id === fileId)
				)

				if (hasFIrstStepError.length) {
					setStep(1)
				}
			})

			setServerErrorValues(errorValues)
		}
	}

	const renderSubmitButton = (props) => {
		const isLastStep = step === FORM_STEPS

		const submitProps = Object.assign({}, props, {
			disabled: isLastStep && isSending
		})

		return (
			<Button
				{...submitProps}
				loading={isLastStep && isSending}
				primary={true}
				text={!isLastStep ? localize('ReviewForm.continueSubmit') : localize('ReviewForm.submit')}
				className={`${CLASSNAME}__submit`}
				data-dot='submit'
				data-e2e={!isLastStep ? 'review-continue-button' : 'review-submit-button'}
			/>
		)
	}

	const renderFirstStepSubtitle = () => {
		let name = ''

		if (reviewData) {
			const {
				[FORM_LINES_NAMES.MANUFACTURER_CB]: { name: manufacturerName },
				[FORM_LINES_NAMES.MODEL_CB]: { name: modelName }
			} = reviewData

			// Pro pripad, kdy mame Ostatni Ostatni
			name = manufacturerName === modelName ? manufacturerName : `${manufacturerName} ${modelName}`
		}

		return (
			<div className={`${CLASSNAME}__subtitle`}>
				<span className={`${CLASSNAME}__subtitle-description`}>
					{localize('ReviewForm.manufacturerAndModel')}
				</span>
				{name}
			</div>
		)
	}

	const renderSecondStepSubtitle = () => {
		const {
			[FORM_LINES_NAMES.MANUFACTURER_CB]: { name: manufacturerName },
			[FORM_LINES_NAMES.MODEL_CB]: { name: modelName },
			[FORM_LINES_NAMES.MANUFACTURING_YEAR]: manufacturingYear,
			[FORM_LINES_NAMES.FUEL_CB]: { name: fuelName } = {},
			[FORM_LINES_NAMES.ENGINE_POWER]: enginePower
		} = reviewData

		// Pro pripad, kdy mame Ostatni Ostatni
		let subtitle =
			manufacturerName === modelName ? manufacturerName : `${manufacturerName} ${modelName}`
		subtitle += `, ${manufacturingYear}`

		// U privesu a navesu nemame fuelName a enginePower
		if (fuelName) {
			subtitle += `, ${fuelName}`
		}

		if (enginePower) {
			subtitle += `, ${Format.number(enginePower)} ${localize('Base.KW')}`
		}

		return <div className={`${CLASSNAME}__subtitle`}>{subtitle}</div>
	}

	const renderContent = () => {
		if (!createPage) {
			return (
				<div className={`${CLASSNAME}__content-loading`}>
					<Spinner className={`${CLASSNAME}__content-spinner`} />
					{localize('ReviewForm.contentLoading')}
				</div>
			)
		} else {
			return (
				<>
					<span className={`${CLASSNAME}__blind`} ref={blindTitleRef} tabIndex='-1'>
						{`${localize('ReviewForm.title')} ${localize(`ReviewForm.stepNo${step}`)}`}
					</span>
					{step === 1 && (
						<>
							{renderFirstStepSubtitle()}
							<div className={`${CLASSNAME}__first-step-description`}>
								{localize('ReviewForm.description')}
							</div>
						</>
					)}
					{step === 2 && (
						<>
							{renderSecondStepSubtitle()}
							<ActionElm
								className={`${CLASSNAME}__edit-spec`}
								onClick={() => {
									setStep(step - 1)
								}}
							>
								{localize('ReviewForm.changeSpecification')}
							</ActionElm>
						</>
					)}
					{renderForm()}
				</>
			)
		}
	}

	return (
		<>
			<Button
				size='small'
				primary={true}
				text={localize('ReviewForm.title')}
				className={`${CLASSNAME}__opener`}
				onClick={() => {
					fire('checkIsUserLogged', {
						reason: localize('AssignEmailReasons.reviewAdd'),
						onLogin: () => {
							setIsOpen(true)
						},
						sourceComponent: LoginHelper.SOURCE_COMPONENT.REVIEW_ADD
					})
				}}
			/>

			{isOpen && (
				<Popup
					isOpen={isOpen}
					onClose={() => {
						setIsOpen(false)
					}}
					hasLightbox={isOpen}
					renderHeader={(renderCloseButton) => (
						<div
							className={classnames({
								[`${CLASSNAME}__header`]: true,
								[`${CLASSNAME}__header--scrolled`]: isContentScrolled
							})}
						>
							<h3 tabIndex={-1} className={`${CLASSNAME}__title`}>
								{localize('ReviewForm.title')}
							</h3>
							{renderCloseButton()}
						</div>
					)}
				>
					<div className={`${CLASSNAME}__content`} ref={contentRef}>
						{renderContent()}
					</div>
				</Popup>
			)}
		</>
	)
}

ReviewForm.propTypes = {
	params: PropTypes.object,
	createPage: PropTypes.shape({
		sections: PropTypes.arrayOf(PropTypes.object)
	}),
	onSubmit: PropTypes.func
}

const selectors = (state) => ({
	params: state[ReviewsExtension.STATE_IDS.PARAMS],
	createPage: state[ReviewsExtension.STATE_IDS.CREATE_PAGE_DATA],
	onSubmit: state[ReviewsExtension.STATE_IDS.ON_ADD_REVIEW_SUBMIT]
})

export default React.memo(select(selectors)(ReviewForm))
