import { updateEntities } from '@inzeraty/form-lines'
import AbstractExtension from 'ima/extension/AbstractExtension'
import { STATUS_CONSTANTS, DEACTIVATION_REASON, DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import RegionService from 'app/model/region/RegionService'
import DistrictService from 'app/model/district/DistrictService'
import { CATEGORIES } from 'app/base/Constants'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { nonValue, nonValueOption, filterBy, filterByValue } from './EntitiesFilter'
import { SEARCH_OUTLINE_24 } from '@sznds/icons'

const deactivationReasonAndApprovalValuesMerge = (reason, approval) =>
	`${reason}${approval ? `,${approval}` : ''}`

export const FILTERS = {
	CATEGORY: {
		ID: 'category_id',
		NAME: 'Kategorie',
		PLACEHOLDER: 'Vyberte kategorii',
		OPTIONS: [
			{
				value: CATEGORIES.PASSENGER_CARS.id,
				name: CATEGORIES.PASSENGER_CARS.name
			},
			{
				value: CATEGORIES.UTILITY_CARS.id,
				name: CATEGORIES.UTILITY_CARS.name
			},
			{
				value: CATEGORIES.TRUCKS.id,
				name: CATEGORIES.TRUCKS.name
			},
			{
				value: CATEGORIES.MOTORCYCLE.id,
				name: CATEGORIES.MOTORCYCLE.name
			},
			{
				value: CATEGORIES.QUAD.id,
				name: CATEGORIES.QUAD.name
			},
			{
				value: CATEGORIES.TRAILERS.id,
				name: CATEGORIES.TRAILERS.name
			},
			{
				value: CATEGORIES.CAMPER.id,
				name: CATEGORIES.CAMPER.name
			},
			{
				value: CATEGORIES.WORKING_MACHINES.id,
				name: CATEGORIES.WORKING_MACHINES.name
			},
			{
				value: CATEGORIES.BUSES.id,
				name: CATEGORIES.BUSES.name
			}
		]
	},
	MANUFACTURER: {
		ID: 'manufacturer_cb',
		NAME: 'Výrobce',
		PLACEHOLDER: 'Vyberte výrobce'
	},
	MODEL: {
		ID: 'model_cb',
		NAME: 'Model',
		PLACEHOLDER: 'Vyberte model'
	},
	REGION: {
		ID: InternalAdminUrlConvertor.constants.REGION,
		NAME: 'Kraj',
		PLACEHOLDER: 'Vyberte kraj'
	},
	DISTRICT: {
		ID: InternalAdminUrlConvertor.constants.DISTRICT,
		NAME: 'Okres',
		PLACEHOLDER: 'Vyberte okres'
	},
	STATUS: {
		ID: 'status',
		NAME: 'Stav',
		PLACEHOLDER: 'Vyberte stav',
		OPTIONS: [
			{
				value: STATUS_CONSTANTS.ACTIVE,
				name: 'Aktivní'
			},
			{
				value: STATUS_CONSTANTS.INACTIVE,
				name: 'Neaktivní'
			},
			{
				value: STATUS_CONSTANTS.DISABLED,
				name: 'Zakázaný'
			},
			{
				value: STATUS_CONSTANTS.DELETED,
				name: 'Smazaný'
			}
		]
	},
	DEACTIVATION_REASON: {
		ID: 'deactivation_reason',
		NAME: 'Důvod deaktivace',
		PLACEHOLDER: 'Vyberte důvod deaktivace',
		OPTIONS: [
			{
				value: 'advert_expired',
				name: 'Expirace'
			},
			{
				value: 'insufficient_images_count',
				name: 'Nesplňuje min. počet fotek'
			},
			{
				value: 'not_paid',
				name: 'Nezaplacený'
			},
			{
				value: 'insufficient_modules_count',
				name: 'Překročen limit slotů'
			},
			{
				value: 'user_deactivated',
				name: 'Zneaktivněný uživatelem'
			},
			{
				value: deactivationReasonAndApprovalValuesMerge('admin_deactivated', 'unchecked,approved'),
				name: 'Zneaktivněný administrátorem'
			},
			{
				value: deactivationReasonAndApprovalValuesMerge('', 'rejected'),
				name: 'Zneaktivněný s banem'
			},
			{
				value: DEACTIVATION_REASON.VIN_DUPLICATION,
				name: 'Duplicitní VIN'
			},
			{
				value: DEACTIVATION_REASON.VIN_DUPLICATION_FRAUD,
				name: 'Opakované vkládání VIN'
			}
		]
	},
	PREFERRED_OFFERS: {
		ID: 'preferred_offer',
		NAME: 'Přednostní výpis',
		PLACEHOLDER: 'Vyberte',
		OPTIONS: [
			{
				value: true,
				name: 'Ano'
			},
			{
				value: false,
				name: 'Ne'
			}
		]
	},
	ADVERTISING: {
		ID: 'seller_type',
		NAME: 'Inzerující',
		PLACEHOLDER: 'Vyberte inzerující',
		OPTIONS: [
			{
				value: 'premise',
				name: 'Firma'
			},
			{
				value: 'user',
				name: 'RUS'
			}
		]
	},
	DEAL_TYPE: {
		ID: 'deal_type',
		NAME: 'Typ prodeje',
		PLACEHOLDER: 'Vyberte',
		OPTIONS: [
			{
				value: [DEAL_TYPE.SALE, DEAL_TYPE.SALE_OR_LEASE].join(','),
				name: 'Prodej'
			},
			{
				value: [DEAL_TYPE.OPERATING_LEASE, DEAL_TYPE.SALE_OR_LEASE].join(','),
				name: 'Operativní leasing'
			}
		]
	}
}

export default class EntitiesFilterExtension extends AbstractExtension {
	constructor(codebooksService) {
		super()

		this._codebooksService = codebooksService

		this._regionEntities = RegionService.getRegions()
		this._districtEntities = DistrictService.getDistricts()

		// Slouží k uložení informací o codeboocích pro danou kategorii
		this._tmpCategoryCodebooks = {
			forCategory: null,
			entities: []
		}
	}

	load() {
		return {
			filterFormLineEntities: this._createFilter(),
			isFilterLoading: false
		}
	}

	update() {
		return {
			filterFormLineEntities: this._createFilter(),
			isFilterLoading: false
		}
	}

	/**
	 * Nastaví callback pro vytvoření filtru.
	 *
	 * @public
	 * @param {Function} callback
	 */
	setFilterInitiezer(callback) {
		this._filterInitiezerClb = callback
	}

	/**
	 * Vrátí data pro filtr filterBy/filterByValue.
	 * Jedná se o select, který určuje podle jakého parametru se bude hledat
	 * a inputu, který určuje podle jaké hodnoty se bude hledat.
	 *
	 * @public
	 * @param {Array<Object>} options Parametry podle který se může hledat.
	 * @returns {Array<Object>} Vrací data pro vytvoření fitlrů filterBy a filterByValue.
	 */
	getFilterByAndValue(options) {
		const routeParams = this.getRouteParams()

		return [
			{
				widget: IDS.SELECT,
				id: filterBy,
				label: 'Hledat podle',
				value: routeParams[filterBy] || options[0].value,
				options
			},
			this.getSearch(filterByValue, 'Hledaný výraz', undefined, '')
		]
	}

	/**
	 * Vrátí data pro vytvoření checkboxového filtru.
	 *
	 * @public
	 * @param {String} id    Identifikátor filtru.
	 * @param {String} label Popisek filtru.
	 * @returns {Object} Data checkboxového filtru.
	 */
	getCheckbox(id, label) {
		const routeParams = this.getRouteParams()

		return {
			widget: IDS.CHECKBOX,
			id,
			label,
			value: routeParams[id] === 'true'
		}
	}

	/**
	 * Vrátí data pro vytvoření select filtru.
	 *
	 * @public
	 * @param {String}        id      Identifikátor filtru.
	 * @param {String}        label   Popisek filtru.
	 * @param {Array<Object>} options Možnosti selectu.
	 * @param {Boolean}       hasNoValueOption Doplni se ne option pro nevybranou hodnotu
	 * @returns {Object} Data select filtru.
	 */
	getSelect(id, label, placeholder, options = [], hasNoValueOption = true) {
		const routeParams = this.getRouteParams()

		const selectOptions = [...options]

		if (hasNoValueOption) {
			selectOptions.unshift(nonValueOption)
		}

		return {
			widget: IDS.SELECT,
			id,
			label,
			placeholder,
			value: routeParams[id],
			options: selectOptions,
			extra: {}
		}
	}

	/**
	 * Vrátí data pro vytvoření vyhledavaciho pole.
	 *
	 * @public
	 * @param {String}        id      Identifikátor filtru.
	 * @param {String}        label   Popisek filtru.
	 * @returns {Object} Data select filtru.
	 */
	getSearch(id, label, placeholder = 'Hledaný výraz', iconLeft = SEARCH_OUTLINE_24) {
		const routeParams = this.getRouteParams()
		const extra = {}

		if (iconLeft && iconLeft != '') {
			Object.assign(extra, { iconLeft })
		}

		return {
			widget: IDS.TEXT,
			id,
			label,
			placeholder,
			value: routeParams[id],
			extra
		}
	}

	/**
	 * Vrátí data pro vytvoření filtrů pro výběr kategorie, výrobce a modelu, které jsou na sobě závislé.
	 *
	 * @public
	 * @returns {Array<Object>} Data pro vytvoření fitlrů kategorie, výrovce a modelu.
	 */
	getCategoryManufacturerModelSelects() {
		return [
			this.getSelect(
				FILTERS.CATEGORY.ID,
				FILTERS.CATEGORY.NAME,
				FILTERS.CATEGORY.PLACEHOLDER,
				FILTERS.CATEGORY.OPTIONS
			),
			this.getSelect(
				FILTERS.MANUFACTURER.ID,
				FILTERS.MANUFACTURER.NAME,
				FILTERS.MANUFACTURER.PLACEHOLDER
			),
			this.getSelect(FILTERS.MODEL.ID, FILTERS.MODEL.NAME, FILTERS.MODEL.PLACEHOLDER)
		]
	}

	/**
	 * Vrátí data pro vytvoření filtru kraje a okresu, které jsou na sobě závislé.
	 *
	 * @public
	 * @returns {Array<Object>} Data pro vytvoření filtrů kraje a okresu.
	 */
	getRegionDistrictSelects() {
		return [
			this.getSelect(FILTERS.REGION.ID, FILTERS.REGION.NAME, FILTERS.REGION.PLACEHOLDER),
			this.getSelect(FILTERS.DISTRICT.ID, FILTERS.DISTRICT.NAME, FILTERS.DISTRICT.PLACEHOLDER)
		]
	}

	/**
	 * Vrátí filtr (select) pro stav inzerátu.
	 *
	 * @public
	 * @returns {Object} Filtr (select) pro stav inzerátu.
	 */
	getAdvertStatusSelect() {
		return this.getSelect(
			FILTERS.STATUS.ID,
			FILTERS.STATUS.NAME,
			FILTERS.STATUS.PLACEHOLDER,
			FILTERS.STATUS.OPTIONS
		)
	}

	getDeactivationReasonSelect() {
		const { deactivation_reason: reason = '', approval_admin: approval } = this.getRouteParams()

		return Object.assign(
			{},
			this.getSelect(
				FILTERS.DEACTIVATION_REASON.ID,
				FILTERS.DEACTIVATION_REASON.NAME,
				FILTERS.DEACTIVATION_REASON.PLACEHOLDER,
				FILTERS.DEACTIVATION_REASON.OPTIONS
			),
			{
				value: deactivationReasonAndApprovalValuesMerge(reason, approval)
			}
		)
	}

	/**
	 * Vrátí data pro vytvoření filtrů stavu a důvodu deaktivace inzerátu, které jsou na sobě závislé.
	 *
	 * @public
	 * @returns {Array<Object>} Data pro vytvoření filtrů stavu a důvodu deaktivace inzerátu.
	 */
	getStatusDeactivationReasonSelects() {
		return [this.getAdvertStatusSelect(), this.getDeactivationReasonSelect()]
	}

	/**
	 * Vrati filter k prednostnemu vypisu
	 */
	getPreferredOffersSelects() {
		return [
			this.getSelect(
				FILTERS.PREFERRED_OFFERS.ID,
				FILTERS.PREFERRED_OFFERS.NAME,
				FILTERS.PREFERRED_OFFERS.PLACEHOLDER,
				FILTERS.PREFERRED_OFFERS.OPTIONS
			)
		]
	}

	/**
	 * Vrati filtr k typu prodeje
	 */
	getDealTypeSelect(defaultValue) {
		const routeParams = this.getRouteParams()

		return Object.assign(
			{},
			this.getSelect(
				FILTERS.DEAL_TYPE.ID,
				FILTERS.DEAL_TYPE.NAME,
				FILTERS.DEAL_TYPE.PLACEHOLDER,
				FILTERS.DEAL_TYPE.OPTIONS,
				false
			),
			{
				value: routeParams[FILTERS.DEAL_TYPE.ID] || defaultValue
			}
		)
	}

	/**
	 * Vrati filtr k Inzerujícím
	 */
	getAdvertisingSelects() {
		return [
			this.getSelect(
				FILTERS.ADVERTISING.ID,
				FILTERS.ADVERTISING.NAME,
				FILTERS.ADVERTISING.PLACEHOLDER,
				FILTERS.ADVERTISING.OPTIONS
			)
		]
	}

	/**
	 * Provede upravení filtru na základě změny hodnoty některé z položek filtru.
	 *
	 * @public
	 * @param {Object} data Data o změněné položce filtru - jeho identifikátor a hodnota.
	 */
	async onEntitiesFilterChange({ id, value }) {
		this.setState({
			isFilterLoading: true
		})

		// Pokud došlo ke změně kategorie nastaví číselníky pro tuto kategorii.
		if (id === FILTERS.CATEGORY.ID) {
			await this._setCategoryCodebooks(value)
		}

		const { filterFormLineEntities } = this.getState()
		let updatedFilterFormLineEntities = updateEntities(filterFormLineEntities, [{ id, value }])

		// Pokud se změní kategorie, tak vymaže hodnotu pro výrobce.
		if (id === FILTERS.CATEGORY.ID) {
			updatedFilterFormLineEntities = updateEntities(updatedFilterFormLineEntities, [
				{
					id: FILTERS.MANUFACTURER.ID,
					value: undefined
				}
			])
		}

		// Pokud se změní kategorie nebo výrobce, tak vymaže hodnotu pro model.
		if (id === FILTERS.CATEGORY.ID || id === FILTERS.MANUFACTURER.ID) {
			updatedFilterFormLineEntities = updateEntities(updatedFilterFormLineEntities, [
				{
					id: FILTERS.MODEL.ID,
					value: undefined
				}
			])
		}

		// Pokud se změní kraj, tak smaže hodnotu pro okres.
		if (id === FILTERS.REGION.ID) {
			updatedFilterFormLineEntities = updateEntities(updatedFilterFormLineEntities, [
				{
					id: FILTERS.DISTRICT.ID,
					value: undefined
				}
			])
		}

		updatedFilterFormLineEntities = await this._updateDependentSelects(
			updatedFilterFormLineEntities,
			id
		)

		this.setState({
			filterFormLineEntities: updatedFilterFormLineEntities,
			isFilterLoading: false
		})
	}

	/**
	 * Vytvoří filtr.
	 * @private
	 */
	async _createFilter() {
		this.setState({
			isFilterLoading: true
		})

		const routeParams = this.getRouteParams()

		// U filtrů, kde se používá kategorie nastaví číselníky pro tuto kategorii.
		const categoryId = routeParams[FILTERS.CATEGORY.ID]
		await this._setCategoryCodebooks(categoryId)

		let filterFormLineEntities = await this._filterInitiezerClb(routeParams)

		// Pokud je nastavene filtr pro region (kraj), tak si stáhne data pro regiony.
		// A nastaví je jako optiony pro tento select.
		const regionFormLineEntity = filterFormLineEntities.find(({ id }) => id === FILTERS.REGION.ID)
		if (regionFormLineEntity) {
			filterFormLineEntities = updateEntities(filterFormLineEntities, [
				{
					id: regionFormLineEntity.id,
					options: [
						nonValueOption,
						...this._regionEntities.map(({ id, name }) => ({
							value: id,
							name
						}))
					]
				}
			])
		}

		filterFormLineEntities = await this._updateDependentSelects(filterFormLineEntities)

		this.setState({
			isFilterLoading: false
		})

		return filterFormLineEntities
	}

	async _updateDependentSelects(formLineEntities, changeFilterId) {
		let updatedFormLineEntities = formLineEntities

		const updateAll = changeFilterId === undefined

		// Provede update závislých filtrů pro kategorii, výrobce a model (pokud jsou použity).
		if (
			updatedFormLineEntities.find(({ id }) => id === FILTERS.CATEGORY.ID) &&
			updatedFormLineEntities.find(({ id }) => id === FILTERS.MANUFACTURER.ID) &&
			updatedFormLineEntities.find(({ id }) => id === FILTERS.MODEL.ID) &&
			(updateAll ||
				changeFilterId === FILTERS.CATEGORY.ID ||
				changeFilterId === FILTERS.MANUFACTURER.ID ||
				changeFilterId === FILTERS.MODEL.ID)
		) {
			updatedFormLineEntities = await this._updateCategoryManufacturerModel(updatedFormLineEntities)
		}

		// Provede update závislých filtrů pro kraj a okres (pokud jsou použity).
		if (
			updatedFormLineEntities.find(({ id }) => id === FILTERS.REGION.ID) &&
			updatedFormLineEntities.find(({ id }) => id === FILTERS.DISTRICT.ID) &&
			(updateAll || changeFilterId === FILTERS.REGION.ID || changeFilterId === FILTERS.DISTRICT.ID)
		) {
			updatedFormLineEntities = await this._updateRegionDistrictSelects(updatedFormLineEntities)
		}

		// Provede update závislých filtrů pro stav a duvod deaktivace (pokud jsou použity).
		if (
			updatedFormLineEntities.find(({ id }) => id === FILTERS.STATUS.ID) &&
			updatedFormLineEntities.find(({ id }) => id === FILTERS.DEACTIVATION_REASON.ID) &&
			(updateAll ||
				changeFilterId === FILTERS.STATUS.ID ||
				changeFilterId === FILTERS.DEACTIVATION_REASON.ID)
		) {
			updatedFormLineEntities = await this._updateStatusDeactivationReasonSelects(
				updatedFormLineEntities
			)
		}

		return updatedFormLineEntities
	}

	async _updateRegionDistrictSelects(formLineEntities) {
		let updatedFormLineEntities = formLineEntities
		const { value } = updatedFormLineEntities.find(({ id }) => id === FILTERS.DISTRICT.ID)
		const { value: regionValue } = updatedFormLineEntities.find(
			({ id }) => id === FILTERS.REGION.ID
		)

		if (typeof regionValue === 'undefined' || regionValue === nonValue) {
			updatedFormLineEntities = updateEntities(updatedFormLineEntities, [
				this._getDisabledSelect(
					FILTERS.DISTRICT.ID,
					FILTERS.DISTRICT.NAME,
					FILTERS.DISTRICT.PLACEHOLDER
				)
			])
		} else {
			updatedFormLineEntities = updateEntities(updatedFormLineEntities, [
				Object.assign(
					this.getSelect(
						FILTERS.DISTRICT.ID,
						FILTERS.DISTRICT.NAME,
						FILTERS.DISTRICT.PLACEHOLDER,
						this._districtEntities
							.filter(({ region }) => String(region.id) === regionValue)
							.map(({ id, name }) => ({
								value: id,
								name
							}))
					),
					{ value }
				)
			])
		}

		return updatedFormLineEntities
	}

	async _updateStatusDeactivationReasonSelects(formLineEntities) {
		let updatedFormLineEntities = formLineEntities

		const { value: statusValue } = updatedFormLineEntities.find(
			({ id }) => id === FILTERS.STATUS.ID
		)
		const { value: deactivationReasonValue } = updatedFormLineEntities.find(
			({ id }) => id === FILTERS.DEACTIVATION_REASON.ID
		)

		if (
			typeof statusValue === 'undefined' ||
			statusValue === nonValue ||
			statusValue !== STATUS_CONSTANTS.INACTIVE
		) {
			updatedFormLineEntities = updateEntities(updatedFormLineEntities, [
				this._getDisabledSelect(
					FILTERS.DEACTIVATION_REASON.ID,
					FILTERS.DEACTIVATION_REASON.NAME,
					FILTERS.DEACTIVATION_REASON.PLACEHOLDER
				)
			])
		} else {
			updatedFormLineEntities = updateEntities(updatedFormLineEntities, [
				Object.assign(
					this.getDeactivationReasonSelect(
						FILTERS.DEACTIVATION_REASON.ID,
						FILTERS.DEACTIVATION_REASON.NAME,
						FILTERS.DEACTIVATION_REASON.PLACEHOLDER,
						FILTERS.DEACTIVATION_REASON.OPTIONS
					),
					{
						value: deactivationReasonValue
					}
				)
			])
		}

		return updatedFormLineEntities
	}

	async _updateCategoryManufacturerModel(formLineEntities) {
		let updatedFilterFormLineEntities = formLineEntities

		// Řeší update filtru výrobců, který je závislý na filtru kategorie
		updatedFilterFormLineEntities = await this._updateCodebooksSelect(
			updatedFilterFormLineEntities,
			FILTERS.MANUFACTURER,
			FILTERS.CATEGORY.ID,
			false
		)

		// Řeší update filtru modelů, který je závislý na filtru výrobců
		updatedFilterFormLineEntities = await this._updateCodebooksSelect(
			updatedFilterFormLineEntities,
			FILTERS.MODEL,
			FILTERS.MANUFACTURER.ID,
			true
		)

		return updatedFilterFormLineEntities
	}

	async _updateCodebooksSelect(formLineEntities, actualFilter, parentId, useParentId) {
		let updatedFormLineEntities = formLineEntities
		const { value, placeholder } = updatedFormLineEntities.find(({ id }) => id === actualFilter.ID)
		const parent = updatedFormLineEntities.find(({ id }) => id === parentId)
		const { value: parentValue } = parent

		if (typeof parentValue === 'undefined' || parentValue === nonValue) {
			updatedFormLineEntities = updateEntities(updatedFormLineEntities, [
				this._getDisabledSelect(actualFilter.ID, actualFilter.NAME, actualFilter.PLACEHOLDER)
			])
		} else {
			const { id, name, nameId } = this._getInfoEntitiy(actualFilter.ID)

			const params = {
				codebook_id: id,
				limit: 1000
			}
			if (useParentId) {
				const { id: parentId } = parent.options.find(({ value }) => value === Number(parentValue))
				params.parent_id = parentId
			}

			const codebooksEntities = await this._codebooksService.getCodebooks(params)
			const select = this.getSelect(
				nameId,
				name,
				placeholder,
				codebooksEntities.map(({ id, value, name }) => ({
					id,
					value,
					name
				}))
			)

			updatedFormLineEntities = updateEntities(updatedFormLineEntities, [
				Object.assign(select, {
					value
				})
			])
		}

		return updatedFormLineEntities
	}

	/**
	 * Vrátí data pro vytvoření disabled filtru.
	 *
	 * @private
	 * @param {String} id    Identifikátor filtru.
	 * @param {String} label Popisek filtru.
	 * @returns {Object} Data po disabled filtr.
	 */
	_getDisabledSelect(id, label, placeholder) {
		return {
			widget: IDS.SELECT,
			id,
			label,
			placeholder,
			options: [nonValueOption],
			value: undefined,
			extra: {
				disabled: true
			}
		}
	}

	/**
	 * Nastaví vybranou kategorii a její codebooky.
	 *
	 * @private
	 * @param {Number} category Id kategorie.
	 */
	async _setCategoryCodebooks(category) {
		const { forCategory, entities } = this._tmpCategoryCodebooks

		if (typeof category === 'undefined' || category === nonValue) {
			this._tmpCategoryCodebooks = {
				forCategory: null,
				entities: []
			}
			return []
		} else if (category === forCategory) {
			return entities
		} else {
			const newEntities = await this._codebooksService.getCategoryCodebooks(category)
			this._tmpCategoryCodebooks = {
				forCategory: category,
				entities: newEntities
			}
			return newEntities
		}
	}

	_getInfoEntitiy(id) {
		const { entities } = this._tmpCategoryCodebooks
		return entities.find(({ nameId }) => nameId === id)
	}
}
