import React from 'react'
import AbstractComponent from 'app/base/AbstractComponent'
import PropTypes from 'prop-types'
import { Icon } from '@sznds/react'
import { CHEVRON_UP_OUTLINE_24, CHEVRON_DOWN_OUTLINE_24, DOT_OUTLINE_8 } from '@sznds/icons'
import { Popup, Responsive, Dropdown } from '@inzeraty/components'
import CacheConstants from 'app/helpers/cache/cacheConstants'
import Opener from 'app/component/filters/components/opener/OpenerView'
import { RESPONSIVE } from 'app/base/Constants'
import select from 'ima-plugin-select'
import STATE_KEYS from 'app/page/userweb/seller/SellerStateKeys'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import IMAGES from 'app/base/ImagesConstants'

import './CategoryPickerButtonCS.json'
import './CategoryPickerButtonView.less'

const CLASSNAME = 'c-category-picker-btn'

class CategoryPickerButtonView extends AbstractComponent {
	static get propTypes() {
		return {
			categories: PropTypes.array,
			selectedCategory: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			categories: DEFAULT_PROPS.ARRAY
		}
	}

	constructor(props) {
		super(props)

		this.state = {
			isModalOpen: false
		}

		this._openModal = this._openModal.bind(this)
		this._closeModal = this._closeModal.bind(this)

		this._renderPopup = this._renderPopup.bind(this)
		this._renderDropdown = this._renderDropdown.bind(this)
		this._renderOpenerIconText = this._renderOpenerIconText.bind(this)
	}

	render() {
		return (
			<div className={CLASSNAME}>
				<div className={`${CLASSNAME}__title`}>{this.localize('CategoryPickerButton.title')}</div>
				{this._renderButton()}
				{this.state.isModalOpen && (
					<Responsive
						breakpoint={RESPONSIVE.TABLET}
						renderMobileElement={this._renderPopup}
						renderDesktopElement={this._renderDropdown}
					/>
				)}
			</div>
		)
	}

	/**
	 * Vyrenderuje tlacitko razeni inzeratu
	 *
	 * @method _renderButton
	 * @private
	 * @return {HTMLElement}
	 */
	_renderButton() {
		const { categories, selectedCategory } = this.props
		const { isModalOpen } = this.state
		const { name: categoryName = '', seoName = '' } =
			categories.find((cat) => cat.seoName === selectedCategory) || {}

		const iconSymbol = isModalOpen ? CHEVRON_UP_OUTLINE_24 : CHEVRON_DOWN_OUTLINE_24

		return (
			<Opener
				onClick={this._openModal}
				iconSymbol={iconSymbol}
				text={this._renderOpenerIconText(seoName, categoryName)}
			/>
		)
	}

	/**
	 * Vyrenderuje popup
	 *
	 * @method _renderPopup
	 * @private
	 * @return {HTMLElement}
	 */
	_renderPopup() {
		const dataDotProps = {
			form: 'category-pick',
			close: 'close'
		}

		return (
			<Popup
				className={`${CLASSNAME}__popup`}
				isOpen={true}
				onClose={this._closeModal}
				dictionary={{
					close: this.localize('CategoryPickerButton.close')
				}}
				title={this.localize('CategoryPickerButton.pick')}
				isFullscreen={false}
				hasLightbox={true}
				dataDot={dataDotProps}
			>
				{this._renderModalItems()}
			</Popup>
		)
	}

	_renderOpenerIconText(seoName, categoryName) {
		const { filteredAdvertsCountTotal } = this.props

		if (categoryName) {
			return (
				<div className={`${CLASSNAME}__opener`}>
					{this._renderCategoryIcon(seoName, true)}
					<span className={`${CLASSNAME}__opener-text`}>{categoryName}</span>
					{filteredAdvertsCountTotal >= 0 && (
						<span className={`${CLASSNAME}__opener-count`}>{`(${Format.number(
							filteredAdvertsCountTotal
						)})`}</span>
					)}
				</div>
			)
		} else {
			return <div className={`${CLASSNAME}__opener-placeholder`}></div>
		}
	}

	/**
	 * Vyrenderuje dropdown
	 *
	 * @method _renderDropdown
	 * @private
	 * @return {HTMLElement}
	 */
	_renderDropdown() {
		return (
			<Dropdown className={`${CLASSNAME}__dropdown`} onClose={this._closeModal}>
				{this._renderModalItems()}
			</Dropdown>
		)
	}

	/**
	 * Vyrenderuje obsah popupu
	 *
	 * @method _renderModalItems
	 * @private
	 * @return {HTMLElement}
	 */
	_renderModalItems() {
		const { categories, selectedCategory, filteredAdvertsCountTotal } = this.props

		return (
			<ul className={`${CLASSNAME}__list`}>
				{categories.length > 0 &&
					categories
						.sort((a, b) => b.advertsCount - a.advertsCount)
						.map((category) => (
							<li
								key={category.id}
								className={this.cssClasses({
									[`${CLASSNAME}__item`]: true,
									[`${CLASSNAME}__item--current`]: category.seoName === selectedCategory
								})}
							>
								<button
									className={this.cssClasses({
										[`${CLASSNAME}__link`]: true,
										[`${CLASSNAME}__link--current`]: category.seoName === selectedCategory,
										[`${CLASSNAME}__link-disabled`]: category.advertsCount === 0
									})}
									aria-label={
										this.localize('CategoryPickerButton.category') + category.name.toLowerCase()
									}
									onClick={() => {
										if (category.advertsCount > 0) {
											if (category.seoName !== selectedCategory) {
												this.fire('resetCache', CacheConstants.SELLER_LIST.NAME)
												this.fire('categoryLinkClick', category.seoName)
											}

											this._closeModal()
										}
									}}
									data-dot='choose-sorting'
									data-dot-data={`{"value": "${category.seoName}"}`}
								>
									<div className={`${CLASSNAME}__category-icon-name`}>
										{category.seoName === selectedCategory && this._renderDotIcon()}
										{this._renderCategoryIcon(
											category.seoName,
											category.seoName === selectedCategory,
											category.advertsCount === 0
										)}
										<span>{category.name}</span>
									</div>
									<div className={`${CLASSNAME}__category-count`}>
										{Format.number(
											category.seoName === selectedCategory
												? filteredAdvertsCountTotal || 0
												: category.advertsCount
										)}
									</div>
								</button>
							</li>
						))}
			</ul>
		)
	}

	/**
	 * Vyrenderuje ikonku aktivni polozky
	 *
	 * @method _renderDotIcon
	 * @private
	 * @return {HTMLElement}
	 */
	_renderDotIcon() {
		return (
			<div className={`${CLASSNAME}__icon-dot-wrapper`}>
				<Icon symbol={DOT_OUTLINE_8} className={`${CLASSNAME}__icon-dot`} />
			</div>
		)
	}

	_renderCategoryIcon(categoryName, selected = false, disabled = false) {
		const iconPath = IMAGES[`image__categories__${categoryName}`]

		const iconStyles = {
			backgroundImage: `url(${iconPath})`
		}

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__icon-category`]: true,
					[`${CLASSNAME}__icon-category--selected`]: selected,
					[`${CLASSNAME}__icon-category-disabled`]: disabled
				})}
				style={iconStyles}
			></div>
		)
	}

	/**
	 * Zmeni stav a otevre popup
	 *
	 * @method _openModal
	 * @private
	 */
	_openModal() {
		this.setState({
			isModalOpen: true
		})
	}

	/**
	 * Zmeni stav a zavre popup
	 *
	 * @method _closeModal
	 * @private
	 */
	_closeModal() {
		this.setState({
			isModalOpen: false
		})
	}
}

const CategoryPickerButtonSelector = (state) => ({
	categories: state[STATE_KEYS.CATEGORIES],
	selectedCategory: state[STATE_KEYS.SELECTED_CATEGORY],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(CategoryPickerButtonSelector)(CategoryPickerButtonView)
