
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['CarProperties'] = {
  body: function(d) { return "Karoserie"; },
  fuel: function(d) { return "Palivo"; },
  engineVolume: function(d) { return "Objem"; },
  gearbox: function(d) { return "Převodovka"; },
  averageGasMileage: function(d) { return "Spotřeba/100km"; },
  averageElectricMileage: function(d) { return "Spotřeba"; },
  batteryCapacity: function(d) { return "Kapacita akumulátoru"; },
  vehicleRange: function(d) { return "Dojezd"; },
  enginePower: function(d) { return "Výkon"; },
  color: function(d) { return "Barva"; },
  liters: function(d) { return "litrů"; },
  description: function(d) { return "Popis prodejce"; },
  showMore: function(d) { return "Zobrazit více"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	