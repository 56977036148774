import React, { useContext } from 'react'
import classnames from 'classnames'
import AdvertActions from '../advertActions/AdvertActions'
import PropTypes from 'prop-types'
import StatusLine from '../statusLine/StatusLineView'
import { Surface, Checkbox } from '@sznds/react'
import { SDN } from 'app/base/Constants'
import { Format } from '@inzeraty/helpers'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import Context from 'ima/page/context'
import SvgIcon from 'app/component/atoms/svgIcon/SvgIconView'
import UserwebAdvert from 'app/component/adverts/UserwebAdvertView'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import AdvertStats from '../advertStats/AdvertStatsView'
import getAdvertState, { ADVERT_STATE } from '../getAdvertState'

import './ClientAdvertItemView.less'
import './ClientAdvertItemCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-client-item'

const AdvertCheckbox = ({ className = '', isSelected, id, ...checkboxProps }) => {
	const fire = useFire()
	const localize = useLocalize()

	return (
		<div className={`${CLASSNAME}__checkbox-wrapper ${className}`}>
			<Checkbox
				className={`${CLASSNAME}__checkbox`}
				checked={isSelected}
				onChange={(event) => {
					event.stopPropagation()

					if (isSelected) {
						fire('deselect', id)
					} else {
						fire('select', id)
					}
				}}
				label={
					<span className={`${CLASSNAME}__accessible-label`}>
						{localize(`ClientAdvertItem.${isSelected ? 'deselect' : 'select'}`)}
					</span>
				}
				{...checkboxProps}
			/>
		</div>
	)
}

const PremiseInfo = ({ advertEntity }) => {
	const { createDate, editDate, id, customId, lastToppedDate, toppedCount } = advertEntity

	const localize = useLocalize()

	const infos = [
		{
			label: localize('ClientAdvertItem.created'),
			value: Format.date(createDate)
		},
		{
			label: localize('ClientAdvertItem.id'),
			value: id
		}
	]

	if (editDate) {
		infos.splice(1, 0, {
			label: localize('ClientAdvertItem.edited'),
			value: Format.date(editDate)
		})
	}

	if (customId) {
		infos.push({
			label: localize('ClientAdvertItem.cid'),
			value: customId
		})
	}

	if (lastToppedDate) {
		infos.push({
			label: localize('ClientAdvertItem.lastTopped'),
			value: Format.date(lastToppedDate)
		})
	}

	if (toppedCount) {
		infos.push({
			label: localize('ClientAdvertItem.toppedCount'),
			value: Format.number(toppedCount)
		})
	}

	return (
		<div className={`${CLASSNAME}__premise-infos`}>
			{infos.map(({ label, value }, index) => (
				<>
					{index % 2 === 0 && index !== 0 && (
						<div className={`${CLASSNAME}__premise-info-spacer-2`}></div>
					)}
					{index % 3 === 0 && index !== 0 && (
						<div className={`${CLASSNAME}__premise-info-spacer-3`}></div>
					)}
					<div className={`${CLASSNAME}__premise-info`} key={`${id}-${index}`}>
						<span className={`${CLASSNAME}__premise-info-label`}>{label}: </span>
						{value}
					</div>
				</>
			))}
		</div>
	)
}

const ClientAdvertItemView = ({
	isSelected,
	isOperatingLease,
	isPremise,
	advertEntity,
	isLoadingStatistics,
	statistics
}) => {
	const {
		id,
		namePrefix,
		nameSuffix,
		images,
		price,
		operatingLeasePriceWithoutVat,
		preferredOffer
	} = advertEntity
	const {
		constants: { URL_APP_PARAMS, URL_APP_PARAMS_VALUES }
	} = AdvertDetailUrlConvertor

	const premiseId = advertEntity.premise && advertEntity.premise.id ? advertEntity.premise.id : ''

	const { $Router } = useContext(Context)
	const localize = useLocalize()
	const advertState = getAdvertState(advertEntity, isPremise)

	const getAdvertUrl = () => {
		const [routeName, routeParams] = UserwebAdvert.getAdvertUrlParams(
			advertEntity,
			isOperatingLease
		)

		if (advertState !== ADVERT_STATE.ACTIVE) {
			routeParams[URL_APP_PARAMS.PREVIEW] = URL_APP_PARAMS_VALUES.PREVIEW
		}

		return $Router.link(routeName, routeParams)
	}

	return (
		<Surface
			tagName='li'
			surface={4}
			className={classnames(
				CLASSNAME,
				isPremise && `${CLASSNAME}--premise`,
				isSelected && `${CLASSNAME}--selected`
			)}
			data-dot='advert'
		>
			{isPremise && <AdvertCheckbox id={id} isSelected={isSelected} />}
			<a href={getAdvertUrl()} className={`${CLASSNAME}__photo-wrapper`}>
				{images && images.length ? (
					<img src={images[0].url + SDN.w360} className={`${CLASSNAME}__photo`} alt='' />
				) : (
					<div className={`${CLASSNAME}__placeholder`}>
						<SvgIcon icon='NO_IMAGE' className={`${CLASSNAME}__placeholder-icon`} />
					</div>
				)}
			</a>
			<div className={`${CLASSNAME}__first-part`}>
				<a href={getAdvertUrl()} className={`${CLASSNAME}__name`} data-dot='go-to-link'>
					{namePrefix}
					{nameSuffix && <span className={`${CLASSNAME}__name--suffix`}>{nameSuffix}</span>}
				</a>
				<span className={`notranslate ${CLASSNAME}__price`} translate='no' lang='cs-cz'>
					{isOperatingLease
						? localize('ClientAdvertItem.operatingLeasePricePerMonth', {
								PRICE: Format.number(operatingLeasePriceWithoutVat),
								CURRENCY: localize('Base.CZK')
						  })
						: `${Format.number(price)} ${localize('Base.CZK')}`}
				</span>
				<StatusLine isPremise={isPremise} advertState={advertState} advertEntity={advertEntity} />
				<AdvertStats
					isLoadingStatistics={isLoadingStatistics}
					isOperatingLease={isOperatingLease}
					statistics={statistics}
				/>
			</div>
			<div
				className={classnames({
					[`${CLASSNAME}__second-part`]: true,
					[`${CLASSNAME}__second-part--premise`]: isPremise
				})}
			>
				{isPremise && <PremiseInfo advertEntity={advertEntity} />}
				{isPremise && (
					<AdvertCheckbox
						className={`${CLASSNAME}__checkbox-wrapper--mobile`}
						id={id}
						isSelected={isSelected}
						size='small'
					/>
				)}
				<AdvertActions
					isOperatingLease={isOperatingLease}
					isPremise={isPremise}
					premiseId={premiseId}
					advertState={advertState}
					advertId={id}
					preferredOffer={{
						addPOButton: !preferredOffer,
						removePOButton: preferredOffer
					}}
				/>
			</div>
		</Surface>
	)
}

ClientAdvertItemView.propTypes = {
	isSelected: PropTypes.bool,
	isOperatingLease: PropTypes.bool,
	isPremise: PropTypes.bool,
	advertEntity: PropTypes.object.isRequired,
	isLoadingStatistics: PropTypes.bool,
	statistics: PropTypes.object
}

AdvertCheckbox.propTypes = {
	className: PropTypes.string,
	isSelected: PropTypes.bool,
	id: PropTypes.number
}

PremiseInfo.propTypes = {
	advertEntity: PropTypes.object.isRequired
}

export default ClientAdvertItemView
