import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

const DATA_TABLE_ITEMS_PER_PAGE = 10

class ClientStatisticsUrlConvertor {
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				DEAL_TYPE: 'typ-prodeje',
				FILTER_BY: 'filtr',
				FILTER_BY_VALUE: 'filtr-hodnota',
				TIME_SPAN_1_FROM: 'obdobi-od',
				TIME_SPAN_1_TO: 'obdobi-do',
				TIME_SPAN_2_FROM: 'obdobi-srovnani-od',
				TIME_SPAN_2_TO: 'obdobi-srovnani-do',
				SORT: 'razeni',
				PAGE: UrlConvertor.constants.URL_APP_PARAMS.PAGE
			},

			DEAL_TYPE_OPTIONS: {
				OPERATING_LEASE: 'operativni-leasing',
				MIRRORING: 'zrcadleni'
			},

			FILTER_OPTIONS: {
				ADVERT_ID: 'id',
				VIN: 'vin',
				IMPORT_ID: 'import'
			},

			URL_API_PARAMS: {
				OPERATING_LEASE: 'operating_lease',
				GROUP_BY: 'group_by',
				PREMISE_EXTERNAL_ID: 'premise_external_id',
				ADVERT_ID: 'item_id',
				VIN: 'vin',
				IMPORT_ID: 'custom_id',
				DATE: 'date',
				ADVERT_IDS: 'item_ids',
				DATE_FROM: 'date_from',
				DATE_TO: 'date_to',
				SORT: 'sort',
				LIMIT: 'limit'
			}
		})
	}

	static getUrl(router, routeParams) {
		return UrlConvertor.getUrl(router, routeParams)
	}

	static getRouteInfo(router) {
		return UrlConvertor.getRouteInfo(router)
	}

	static getFilterApiParams(appParams = {}) {
		const {
			URL_APP_PARAMS,
			FILTER_OPTIONS,
			URL_API_PARAMS
		} = ClientStatisticsUrlConvertor.constants

		const MAPPING = {
			[FILTER_OPTIONS.ADVERT_ID]: URL_API_PARAMS.ADVERT_ID,
			[FILTER_OPTIONS.VIN]: URL_API_PARAMS.VIN,
			[FILTER_OPTIONS.IMPORT_ID]: URL_API_PARAMS.IMPORT_ID
		}

		const filterBy = appParams[URL_APP_PARAMS.FILTER_BY]
		const filterByValue = appParams[URL_APP_PARAMS.FILTER_BY_VALUE]

		if (filterBy && MAPPING[filterBy] && filterByValue) {
			return [MAPPING[filterBy], filterByValue]
		}
	}

	static isCompareToTimeSpanActive(appParams = {}) {
		const [
			,
			apiParamsForCompareToTimeSpan
		] = ClientStatisticsUrlConvertor._getApiParamsForTimeSpans(appParams)

		return !!apiParamsForCompareToTimeSpan
	}

	static getApiParamsForKPIMetrics(premiseExternalId, appParams = {}) {
		if (!premiseExternalId) {
			return []
		}

		const { URL_API_PARAMS } = ClientStatisticsUrlConvertor.constants

		const sharedApiParams = {}

		sharedApiParams[URL_API_PARAMS.GROUP_BY] = URL_API_PARAMS.PREMISE_EXTERNAL_ID
		sharedApiParams[URL_API_PARAMS.PREMISE_EXTERNAL_ID] = premiseExternalId

		const filter = ClientStatisticsUrlConvertor.getFilterApiParams(appParams)

		if (filter) {
			const [filterByApiParamName, filterByValue] = filter
			sharedApiParams[filterByApiParamName] = filterByValue
		}

		const [
			apiParamsForTimeSpan1,
			apiParamsForTimeSpan2
		] = ClientStatisticsUrlConvertor._getApiParamsForTimeSpans(appParams)

		return ClientStatisticsUrlConvertor._mergeApiParamsForEachTimeSpan(
			sharedApiParams,
			apiParamsForTimeSpan1,
			apiParamsForTimeSpan2
		)
	}

	static getApiParamsForGraph(premiseExternalId, appParams = {}) {
		if (!premiseExternalId) {
			return []
		}

		const { URL_API_PARAMS } = ClientStatisticsUrlConvertor.constants

		const sharedApiParams = {}

		sharedApiParams[URL_API_PARAMS.GROUP_BY] = URL_API_PARAMS.DATE
		sharedApiParams[URL_API_PARAMS.PREMISE_EXTERNAL_ID] = premiseExternalId
		sharedApiParams[URL_API_PARAMS.SORT] = 'id'
		sharedApiParams[URL_API_PARAMS.LIMIT] = 1000000

		const filter = ClientStatisticsUrlConvertor.getFilterApiParams(appParams)

		if (filter) {
			const [filterByApiParamName, filterByValue] = filter
			sharedApiParams[filterByApiParamName] = filterByValue
		}

		const [
			apiParamsForTimeSpan1,
			apiParamsForTimeSpan2
		] = ClientStatisticsUrlConvertor._getApiParamsForTimeSpans(appParams)

		return ClientStatisticsUrlConvertor._mergeApiParamsForEachTimeSpan(
			sharedApiParams,
			apiParamsForTimeSpan1,
			apiParamsForTimeSpan2
		)
	}

	static getApiParamsForDataTable(premiseExternalId, appParams = {}) {
		if (!premiseExternalId) {
			return []
		}

		const getApiSort = (sortParam) => {
			if (sortParam) {
				return {
					[URL_API_PARAMS.SORT]: sortParam
				}
			}
		}
		const { URL_APP_PARAMS, URL_API_PARAMS } = ClientStatisticsUrlConvertor.constants

		const sharedApiParams = {}

		sharedApiParams[URL_API_PARAMS.GROUP_BY] = URL_API_PARAMS.ADVERT_ID
		sharedApiParams[URL_API_PARAMS.PREMISE_EXTERNAL_ID] = premiseExternalId

		const filter = ClientStatisticsUrlConvertor.getFilterApiParams(appParams)

		if (filter) {
			const [filterByApiParamName, filterByValue] = filter
			sharedApiParams[filterByApiParamName] = filterByValue
		}

		const [
			apiParamsForTimeSpan1,
			apiParamsForTimeSpan2
		] = ClientStatisticsUrlConvertor._getApiParamsForTimeSpans(appParams)

		const [
			mergedApiParamsForTimeSpan1,
			mergedApiParamsForTimeSpan2
		] = ClientStatisticsUrlConvertor._mergeApiParamsForEachTimeSpan(
			sharedApiParams,
			Object.assign(
				{},
				UrlConvertor.getApiPaging(appParams[URL_APP_PARAMS.PAGE], DATA_TABLE_ITEMS_PER_PAGE),
				getApiSort(appParams[URL_APP_PARAMS.SORT]),
				apiParamsForTimeSpan1
			),
			apiParamsForTimeSpan2
		)

		const getFinalApiParamsForTimeSpan2 = (advertIds) => {
			const finalApiParams = Object.assign({}, mergedApiParamsForTimeSpan2)

			finalApiParams[URL_API_PARAMS.ADVERT_IDS] = advertIds

			return finalApiParams
		}

		return [
			mergedApiParamsForTimeSpan1,
			apiParamsForTimeSpan2 ? getFinalApiParamsForTimeSpan2 : undefined
		]
	}

	static _getApiParamsForTimeSpans(appParams = {}) {
		const { URL_APP_PARAMS, URL_API_PARAMS } = ClientStatisticsUrlConvertor.constants

		const setApiParamsForTimeSpans = (apiParams, timeSpanFrom, timeSpanTo) => {
			if (timeSpanFrom) {
				apiParams[URL_API_PARAMS.DATE_FROM] = timeSpanFrom
			}
			if (timeSpanTo) {
				apiParams[URL_API_PARAMS.DATE_TO] = timeSpanTo
			}
		}

		const apiParamsForTimeSpan1 = {}
		let apiParamsForTimeSpan2 = undefined

		setApiParamsForTimeSpans(
			apiParamsForTimeSpan1,
			appParams[URL_APP_PARAMS.TIME_SPAN_1_FROM],
			appParams[URL_APP_PARAMS.TIME_SPAN_1_TO]
		)

		if (appParams[URL_APP_PARAMS.TIME_SPAN_2_FROM] || appParams[URL_APP_PARAMS.TIME_SPAN_2_TO]) {
			apiParamsForTimeSpan2 = {}
			setApiParamsForTimeSpans(
				apiParamsForTimeSpan2,
				appParams[URL_APP_PARAMS.TIME_SPAN_2_FROM],
				appParams[URL_APP_PARAMS.TIME_SPAN_2_TO]
			)
		}

		return [apiParamsForTimeSpan1, apiParamsForTimeSpan2]
	}

	static _mergeApiParamsForEachTimeSpan(
		sharedApiParams = {},
		apiParamsForTimeSpan1,
		apiParamsForTimeSpan2
	) {
		return [
			Object.assign({}, sharedApiParams, apiParamsForTimeSpan1),
			apiParamsForTimeSpan2 ? Object.assign({}, sharedApiParams, apiParamsForTimeSpan2) : undefined
		]
	}
}

export default ClientStatisticsUrlConvertor
