import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'

const PAYMENTS_CONSTANTS = deepFreeze({
	PAYMENT_TYPE: {
		PAYMENT_CREDIT_CARD: 'credit_card',
		PAYMENT_WALLET: 'wallet',
		PAYMENT_SMS: 'sms'
	},

	PUBLICATION_LENGTH: {
		WEEK: 7,
		TWO_WEEKS: 14
	},

	PAYMENT_STATUS: {
		PROCESSED: 'processed',
		FAILED: 'failed'
	},

	PAYMENT_REASON: {
		ADVERT: 'advert',
		TOP: 'top'
	},

	ERROR: {
		CREDIT_CARD: {
			INVALID_PAYMENT_STATUS: 'invalid_payment_status',
			DEFAULT: 'payment-failed',
			UNAVAILABLE: 'credit-card-unavailable',
			error: 'credit-card-error',
			not_owner: 'credit-card-not-owner',
			advert_not_active: 'credit-card-advert-not-active',
			payment_invalid_payment_type: 'credit-card-invalid-payment-type'
		},
		WALLET: {
			WITHOUT_WALLET: 'premise_without_wallet',
			LOW_CREDIT: 'low_credit',
			NOT_ACTIVE_ADVERT: 'invalid_advert_ids'
		},

		DEFAULT: 'payment-failed',
		VIN_DUPLICATION: 'duplicate_advert_vin'
	}
})

export { PAYMENTS_CONSTANTS }
