import { FORM_LINES_IDS } from './ReviewFormLineConstants'
import { ERROR_CODES } from 'app/component/errorMessages/ErrorMessages'

import './ReviewFormCS.json'

export const getErrorDictionary = (localize) => ({
	[FORM_LINES_IDS.MANUFACTURING_DATE]: {
		[ERROR_CODES.REQUIRED]: localize('ReviewForm.manufacturing_year_required'),
		[ERROR_CODES.NOT_VALID]: localize('ReviewForm.manufacturing_year_not_valid'),
		[ERROR_CODES.UNKNOWN]: localize('ReviewForm.manufacturing_year_not_valid')
	},
	[FORM_LINES_IDS.FUEL_CB]: {
		[ERROR_CODES.REQUIRED]: localize('ReviewForm.fuel_cb_required'),
		[ERROR_CODES.NOT_VALID]: localize('ReviewForm.value_not_valid'),
		[ERROR_CODES.UNKNOWN]: localize('ReviewForm.value_not_valid')
	},
	[FORM_LINES_IDS.ENGINE_POWER]: {
		[ERROR_CODES.REQUIRED]: localize('ReviewForm.power_required'),
		[ERROR_CODES.NOT_VALID]: localize('ReviewForm.value_not_valid'),
		[ERROR_CODES.UNKNOWN]: localize('ReviewForm.value_not_valid')
	},
	[FORM_LINES_IDS.REVIEW_TITLE]: {
		[ERROR_CODES.REQUIRED]: localize('ReviewForm.review_title_required'),
		[ERROR_CODES.NOT_VALID]: localize('ReviewForm.value_not_valid'),
		[ERROR_CODES.UNKNOWN]: localize('ReviewForm.value_not_valid')
	},
	[FORM_LINES_IDS.REVIEW_DECRIPTION]: {
		[ERROR_CODES.REQUIRED]: localize('ReviewForm.review_description_required'),
		[ERROR_CODES.NOT_VALID]: localize('ReviewForm.value_not_valid'),
		[ERROR_CODES.UNKNOWN]: localize('ReviewForm.value_not_valid')
	}
})
