export default class PremiseEntityPublic {
	/**
	 * @constructor
	 * @param {Object} data
	 * @param {Number} data.id
	 * @param {Number} data.oldId
	 * @param {Number} data.externalId
	 * @param {String} data.name         Název firmy.
	 * @param {String} data.seoName      Seo název firmy.
	 * @param {String} data.web          Url webových stránek firmy.
	 * @param {String} data.logo         Url loga firmy.
	 * @param {Array}  data.emails       Emaily.
	 * @param {Array}  data.phones       Telefonní čísla.
	 * @param {Object} data.locality     Informace o lokalitě.
	 * @param {Object} data.openingHours Informace o otvírací době.
	 * @param {Object} data.subject      Informace o subjektu (ičo, ...).
	 * @param {Boolean} data.useAdvertLocality	Informace zda se ma primarne pouzit adresa z inzeratu
	 */
	constructor(data) {
		this.id = data.id
		this.oldId = data.oldId
		this.externalId = data.externalId
		this.name = data.name
		this.seoName = data.seoName
		this.web = data.web
		this.logo = data.logo
		this.emails = data.emails
		this.phones = data.phones
		this.locality = data.locality
		this.openingHours = data.openingHours
		this.subject = data.subject
		this.description = data.description
		this.retargetingId = data.retargetingId
		this.homeDelivery = data.homeDelivery
		this.advertsCount = data.advertsCount
		this.reviews = data.reviews
		this.softwareKeys = data.softwareKeys
		this.useAdvertLocality = data.useAdvertLocality
	}
}
