import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import MultiOptionFilterLogic from 'app/component/filters/logic/MultiOptionFilterLogic'
import PopupOpener from 'app/component/filters/forms/main/openers/multioptionOpener/MultioptionOpener'
import MultioptionForm from 'app/component/filters/forms/multioption/Multioption'
import FilterConstants from 'app/model/filter/FilterConstants'
import BasicFilterPopup from './shared/BasicFilterPopup'
import FilterPopupButton from './shared/FilterPopupButton'
import PopupLogic from 'app/component/filters/logic/modals/PopupLogic'
import FilterPopupWithOpener from './shared/FilterPopupWithOpener'
import PopupOpenerLogic from 'app/component/filters/logic/openers/PopupOpenerLogic'
import ScopedFormLineEntity from 'app/component/filters/logic/ScopedFormLineEntity'

export default class MultiOptionFilterPopupWithOpener extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			popupDefinition: PropTypes.object,
			context: PropTypes.string.isRequired,
			value: PropTypes.number, //TODO lepsi nazev?
			filterIndex: PropTypes.number,
			renderOpener: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderOpener = this._renderOpener.bind(this)
		this._renderPopup = this._renderPopup.bind(this)

		this._renderFilterForm = this._renderFilterForm.bind(this)
		this._renderButton = this._renderButton.bind(this)
	}

	render() {
		const { popupDefinition, context, value } = this.props

		return (
			<FilterPopupWithOpener
				popupDefinition={popupDefinition}
				context={context}
				value={value}
				renderOpener={this._renderOpener}
				renderPopup={this._renderPopup}
			/>
		)
	}

	_renderOpener() {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			context,
			filterIndex,
			renderOpener
		} = this.props

		return (
			<MultiOptionFilterLogic
				formLineEntity={globalFormLineEntity}
				changeFilter={globalChangeFilter}
				context={context}
			>
				{(filterLogicChildrenProps) => {
					const {
						form: { changeFilter }
					} = filterLogicChildrenProps

					return (
						<PopupOpenerLogic
							formLineEntity={globalFormLineEntity}
							changeFilter={changeFilter}
							context={context}
						>
							{(popupOpenerLogicData) => {
								const { openPopup, clearFilter } = popupOpenerLogicData

								return renderOpener ? (
									renderOpener(popupOpenerLogicData) //TODO proc neposilame ven i filterLogicChildrenProps, respektive nemusime tady renderovat cely strom
								) : (
									<PopupOpener
										formLineEntity={globalFormLineEntity}
										context={context}
										filterIndex={filterIndex}
										onOpen={openPopup}
										onResetFilter={clearFilter}
									/>
								)
							}}
						</PopupOpenerLogic>
					)
				}}
			</MultiOptionFilterLogic>
		)
	}

	_renderPopup() {
		const { formLineEntity: globalFormLineEntity, context } = this.props

		return (
			<ScopedFormLineEntity formLineEntity={globalFormLineEntity}>
				{(scopedFormLineEntityChildrenProps) => {
					const { localFormLineEntity, localChangeFilter } = scopedFormLineEntityChildrenProps

					return (
						<MultiOptionFilterLogic
							formLineEntity={localFormLineEntity}
							changeFilter={localChangeFilter}
							context={context}
						>
							{(filterLogicChildrenProps) =>
								this._renderFilterPopup(scopedFormLineEntityChildrenProps, filterLogicChildrenProps)
							}
						</MultiOptionFilterLogic>
					)
				}}
			</ScopedFormLineEntity>
		)
	}

	_renderFilterPopup(scopedFormLineEntityChildrenProps, filterLogicChildrenProps) {
		const { changeFilter: globalChangeFilter, popupDefinition, value } = this.props
		const { localFormLineEntity } = scopedFormLineEntityChildrenProps

		return (
			<PopupLogic
				formLineEntity={localFormLineEntity}
				changeFilter={globalChangeFilter}
				popupDefinition={popupDefinition}
				value={value}
				filterLogicData={filterLogicChildrenProps}
			>
				{(filterPopupLogicChildrenProps) => {
					return (
						<BasicFilterPopup
							formLineEntity={localFormLineEntity}
							title={localFormLineEntity.placeholder}
							value={value}
							filterPopupLogicData={filterPopupLogicChildrenProps}
							renderContent={(filterPopupLogicData) =>
								this._renderFilterForm(filterPopupLogicData, filterLogicChildrenProps)
							}
							renderFooter={(filterPopupLogicData) =>
								this._renderButton(filterPopupLogicData, filterLogicChildrenProps)
							}
						/>
					)
				}}
			</PopupLogic>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderFilterForm(filterPopupLogicChildrenProps, filterLogicChildrenProps) {
		const { popupDefinition = {} } = this.props
		const { filterButtonType } = popupDefinition
		const {
			form: { changeFilter },
			advertsCount: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity }
		} = filterLogicChildrenProps

		return (
			<MultioptionForm
				formLineEntity={formLineEntity}
				onChange={changeFilter}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				filteredAdvertsCount={filteredAdvertsCount}
				// pokud je videt CTA tlacitko s poctem nabidek (a textem 'Zobrazit xxx
				// nabidek'), tak nechceme v bublinach s pocty inzeratu znovu vypisovat
				// text 'xxx nabidek', ale pouze 'xxx'
				isCountShownWithAddText={filterButtonType !== FilterConstants.filterButtonType.SEARCH}
			/>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderButton(filterPopupLogicChildrenProps, filterLogicChildrenProps) {
		const { popupDefinition } = this.props

		return (
			<FilterPopupButton
				filterPopupLogicData={filterPopupLogicChildrenProps}
				filterFormLogicData={filterLogicChildrenProps}
				popupDefinition={popupDefinition}
			/>
		)
	}
}
