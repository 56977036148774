import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import VinInfo from 'app/component/vinInfo/VinInfoView'
import { Format } from '@inzeraty/helpers'
import { ELECTRIC_VEHICLE_FUEL_OPTION_NAME } from 'app/base/Constants'
import {
	ENGINE_VALUES,
	HISTORY_VALUES,
	SPEC_VALUES,
	CAR_CONDITION
} from './CarDetailsSectionConstants'
import { CATEGORIES } from 'app/page/userweb/newAdvert/equipment/EquipmentConstants'
import classnames from 'classnames'
import CebiaLink from 'app/component/cebiaLink/CebiaLinkView'

import './CarDetailsSection.less'
import './CarDetailsSectionCS.json'
import 'app/page/userweb/newAdvert/equipment/components/widgets/equipment/EquipmentWidgetCS.json'
import { isForbiddenCertifiedProgram } from 'app/helpers/forbiddenCertifiedPrograms/ForbiddenCertifiedPrograms'
import CheckConditionLink from 'app/component/checkConditionLink/CheckConditionLink'

const CLASSNAME = 'c-car-details-section'

const ENVIRONMENTAL_TAX = 'environmentalTaxPaid'
const CRASHED_IN_PAST = 'crashedInPast'
const ENGINE_VOLUME = 'engineVolume'
const BATTERY_CAPACITY = 'batteryCapacity'
const VEHICLE_RANGE = 'vehicleRange'
const AVERAGE_GAS_MILEAGE = 'averageGasMileage'
const AVERAGE_ELECTRIC_MILEAGE = 'averageElectricMileage'

const NO_CATEGORY_SPECIFIED = 'no_category_specified'
const NUMBER_OF_VISIBLE_EQUIPMENT_ITEMS = 4

export default class CarDetailsSection extends AbstractPureComponent {
	static get propTypes() {
		return {
			advertEntity: PropTypes.shape(advertPropTypes).isRequired
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			showMore: true
		}

		this._renderVinInfo = this._renderVinInfo.bind(this)
	}

	render() {
		return (
			<div className={`${CLASSNAME}__content`}>
				{this._renderCarConditionSection()}
				{this._renderSpecSection()}
				{this._renderEngineSection()}
				{this._renderHistorySection()}
				{this._renderEquipmentSection()}
			</div>
		)
	}

	_renderCarConditionSection() {
		const { advertEntity } = this.props
		const { cebiaReport, certificationCb } = advertEntity

		if (!cebiaReport) {
			delete CAR_CONDITION.cebiaReport
		}

		if (
			!certificationCb ||
			(certificationCb && isForbiddenCertifiedProgram(certificationCb.value))
		) {
			delete CAR_CONDITION.certificationCb
		}

		return this._renderTable(
			this.localize('CarDetailsSection.condition'),
			this._renderTableData(CAR_CONDITION)
		)
	}

	_renderEngineSection() {
		const {
			advertEntity: { averageGasMileage }
		} = this.props

		if (averageGasMileage <= 0) {
			delete ENGINE_VALUES.averageGasMileage
		}

		return this._renderTable(
			this.localize('CarDetailsSection.engine'),
			this._renderTableData(ENGINE_VALUES)
		)
	}

	_renderHistorySection() {
		return this._renderTable(
			this.localize('CarDetailsSection.historyAndDocuments'),
			this._renderTableData(HISTORY_VALUES)
		)
	}

	_renderSpecSection() {
		return this._renderTable(
			this.localize('CarDetailsSection.vehicleSpec'),
			this._renderTableData(SPEC_VALUES)
		)
	}

	_renderTable(tableHeading, rows) {
		const rowsToDisplay = rows.filter((row) => !!row)

		if (rowsToDisplay.length) {
			return (
				<React.Fragment>
					<h3 className={`${CLASSNAME}__heading`}>{tableHeading}</h3>
					<table className={`${CLASSNAME}__table`}>
						<tbody className={`${CLASSNAME}__table-body`}>{rowsToDisplay}</tbody>
					</table>
				</React.Fragment>
			)
		}
	}

	_renderTableRow(label, value) {
		if (value) {
			return (
				<tr key={label} className={`${CLASSNAME}__table-row`}>
					<th className={`${CLASSNAME}__table-label`}>{`${label}:`}</th>
					<td className={`${CLASSNAME}__table-value`}>{value}</td>
				</tr>
			)
		} else {
			return null
		}
	}

	_hideEnvironmentalTax() {
		const { advertEntity } = this.props
		const { euroLevelCb = {} } = advertEntity
		const { value } = euroLevelCb

		return value >= 3
	}

	_hideCrashedInPast() {
		const { advertEntity } = this.props
		const { crashedInPast } = advertEntity
		return crashedInPast !== true
	}

	_hideFuelSpecificFields(filterValue) {
		const { advertEntity = {} } = this.props
		const { fuelCb: { name: fuelName } = {} } = advertEntity
		const isElectricVehicle = fuelName === ELECTRIC_VEHICLE_FUEL_OPTION_NAME

		const DEFAULT_FIELDS = [ENGINE_VOLUME, AVERAGE_GAS_MILEAGE]
		const ELECTRIC_VEHICLE_FIELDS = [BATTERY_CAPACITY, VEHICLE_RANGE, AVERAGE_ELECTRIC_MILEAGE]

		if (DEFAULT_FIELDS.includes(filterValue)) {
			return isElectricVehicle
		}

		if (ELECTRIC_VEHICLE_FIELDS.includes(filterValue)) {
			return !isElectricVehicle
		}

		return false
	}

	_renderTableData(valuesObject) {
		const FUEL_SPECIFIC_FIELDS = [
			ENGINE_VOLUME,
			BATTERY_CAPACITY,
			VEHICLE_RANGE,
			AVERAGE_GAS_MILEAGE,
			AVERAGE_ELECTRIC_MILEAGE
		]

		return Object.keys(valuesObject)
			.filter((filterValue) => {
				if (filterValue === ENVIRONMENTAL_TAX) {
					return !this._hideEnvironmentalTax()
				}
				if (filterValue === CRASHED_IN_PAST) {
					return !this._hideCrashedInPast()
				}
				if (FUEL_SPECIFIC_FIELDS.includes(filterValue)) {
					return !this._hideFuelSpecificFields(filterValue)
				}

				return true
			})
			.map((value) =>
				this._renderTableRow(
					this.localize(`CarDetailsSection.${value}`),
					this._getValueData(valuesObject, value)
				)
			)
	}

	_getValueData(valuesObject, value) {
		const renderVin = this._renderVinInfo
		const { advertEntity } = this.props
		const {
			noTemplate = false,
			isBoolean = false,
			getData = () => {},
			isVin = false,
			multipleValues = false
		} = valuesObject[value]

		const getObjectData = () =>
			multipleValues
				? getData(multipleValues.map((value) => advertEntity[value]))
				: getData(advertEntity[value])

		const getRegularData = () => getObjectData()

		const getFormattedData = () => this.localize(`CarDetailsSection.${value}Value`, getObjectData())

		const getTextData = () => {
			if (!advertEntity[value] && typeof advertEntity[value] !== 'number') {
				return null
			} else {
				if (noTemplate) {
					return getRegularData()
				} else {
					return getFormattedData()
				}
			}
		}

		const getBoolData = () =>
			advertEntity[value] === true
				? this.localize('CarDetailsSection.yesAnswer')
				: advertEntity[value] === false
				? this.localize('CarDetailsSection.noAnswer')
				: null

		const getValueData = () => (isBoolean ? getBoolData() : getTextData())

		const getVinData = () => (advertEntity[value] ? renderVin() : null)

		const renderValue = !isVin ? getValueData() : getVinData()

		if (renderValue) {
			return (
				<>
					{renderValue}
					{this._renderCebiaLink(value)}
					{this._renderCheckCondition(value)}
				</>
			)
		} else {
			return null
		}
	}

	_renderVinInfo() {
		return (
			<VinInfo
				advertEntity={this.props.advertEntity}
				localize={this.localize.bind(this)}
				analyticsDataSender={this.utils.AnalyticsDataSender}
			/>
		)
	}

	_renderCebiaLink(value) {
		if (value === 'tachometer') {
			return <CebiaLink advertEntity={this.props.advertEntity} />
		}
	}

	_renderCheckCondition(value) {
		if (value === 'conditionCb') {
			const { advertEntity, isOperatingLease } = this.props

			return <CheckConditionLink advertEntity={advertEntity} isOperatingLease={isOperatingLease} />
		}
	}

	_renderCategoryContent(optionsByCategory = []) {
		if (optionsByCategory.length > 0) {
			return optionsByCategory
				.map((equipment) => Format.capitalizeFirstLetter(equipment.name))
				.join(', ')
		}
	}

	_renderEquipmentSection() {
		const { advertEntity = {} } = this.props
		const { showMore } = this.state
		const { equipmentCb = [] } = advertEntity

		const renderCategoryWrapper = (categoryName, optionsByCategory) => {
			const categoryLabel = this._getCategoryLabel(categoryName)

			return (
				<tr key={categoryName} className={`${CLASSNAME}__equipment-row`}>
					<th
						className={classnames({
							[`${CLASSNAME}__table-label`]: true,
							[`${CLASSNAME}__equipment-label`]: true
						})}
					>
						{`${categoryLabel}:`}
					</th>
					<td
						className={classnames({
							[`${CLASSNAME}__table-value`]: true,
							[`${CLASSNAME}__equipment-value`]: true
						})}
					>
						{this._renderCategoryContent(optionsByCategory)}
					</td>
				</tr>
			)
		}

		const optionsByCategories = this._splitEquipmentOptionsByCategories(equipmentCb)

		const categoriesToRender = [NO_CATEGORY_SPECIFIED, ...CATEGORIES].filter((categoryName) => {
			const optionsByCategory = optionsByCategories[categoryName]

			return optionsByCategory && optionsByCategory.length > 0
		})

		const isShowMoreButtonVisible = categoriesToRender.length > NUMBER_OF_VISIBLE_EQUIPMENT_ITEMS

		const categoriesArr =
			showMore && isShowMoreButtonVisible
				? categoriesToRender.slice(0, NUMBER_OF_VISIBLE_EQUIPMENT_ITEMS)
				: categoriesToRender

		if (equipmentCb.length) {
			return (
				<div className={CLASSNAME}>
					<h3 className={`${CLASSNAME}__heading`}>
						{this.localize('CarDetailsSection.equipmentHeading')}
					</h3>
					<table
						className={classnames({
							[`${CLASSNAME}__table`]: true,
							[`${CLASSNAME}__table-short`]: isShowMoreButtonVisible
						})}
					>
						<tbody className={`${CLASSNAME}__table-body`}>
							{categoriesArr.map((categoryName) =>
								renderCategoryWrapper(categoryName, optionsByCategories[categoryName])
							)}
						</tbody>
					</table>
					{isShowMoreButtonVisible && (
						<button
							type='button'
							className={`${CLASSNAME}__show-button`}
							onClick={() => {
								this.setState((prevState) => ({
									showMore: !prevState.showMore
								}))
							}}
						>
							{showMore
								? this.localize('CarDetailsSection.showMore')
								: this.localize('CarDetailsSection.showLess')}
						</button>
					)}
				</div>
			)
		}
	}

	_splitEquipmentOptionsByCategories(options) {
		const addOptionToCategory = (object, option, category) => {
			const { [category]: optionsInCategory = [] } = object

			return Object.assign({}, object, {
				[category]: [...optionsInCategory, option]
			})
		}

		const optionsByCategories = options.reduce((result, option) => {
			const { category } = option

			if (category) {
				return addOptionToCategory(result, option, category)
			} else {
				return addOptionToCategory(result, option, NO_CATEGORY_SPECIFIED)
			}
		}, {})

		return optionsByCategories
	}

	_getCategoryLabel(categoryName) {
		return categoryName !== NO_CATEGORY_SPECIFIED
			? this.localize(`EquipmentWidget.${categoryName}`)
			: this.localize('CarDetailsSection.equipmentnNoSection')
	}
}
