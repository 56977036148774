import React, { useRef, useContext, useState, useEffect } from 'react'
import classnames from 'classnames'
import ImaContext from 'ima/page/context'
import { DropZone, DropZoneType } from '@inzeraty/components'
import VideoPreview from './VideoPreview'
import { useLocalize } from 'app/base/componentHelpers'
import { Icon } from '@sznds/react'
import { CLOSE_OUTLINE_24 } from '@sznds/icons'
import Preloader from 'app/component/preloader/Preloader'
import PropTypes from 'prop-types'

import './VideoUploader.less'
import './VideoUploaderCS.json'

const CLASSNAME = 'c-video-uploader'

const VideoInput = ({ isCompact = false }) => {
	const { $EventBus } = useContext(ImaContext)
	const ref = useRef(null)
	const localize = useLocalize()
	const type = isCompact ? DropZoneType.COMPACT : DropZoneType.FULL

	return (
		<div
			ref={ref}
			className={classnames({
				[`${CLASSNAME}__drop--compact`]: isCompact
			})}
		>
			<DropZone
				className={`${CLASSNAME}__dropzone`}
				type={type}
				multiple={false}
				openerText={localize('VideoUploader.openerText')}
				accept='video/*'
				onChange={(event) => {
					$EventBus.fire(ref.current, 'addVideo', event.target.files)
				}}
			/>
		</div>
	)
}

const VideoUploader = ({ video }) => {
	const { $EventBus } = useContext(ImaContext)
	const closeErrRef = useRef(null)
	const localize = useLocalize()

	const [tmpVideo, setTmpVideo] = useState(null)

	useEffect(() => {
		if (video) {
			setTmpVideo(video)
		}
	}, [video])

	// TODO - predelat do na komponentu stavovych hlasek
	const renderError = () => (
		<div className={`${CLASSNAME}__error`}>
			<p className={`${CLASSNAME}__error-text`}>{localize(`VideoUploader.${tmpVideo.error}`)}</p>
			<button
				type='button'
				className={`${CLASSNAME}__error-closer`}
				ref={closeErrRef}
				onClick={(event) => {
					$EventBus.fire(closeErrRef.current, 'removeVideoError')
				}}
				aria-label={localize('VideoUploader.removeErrorLabel')}
			>
				<Icon symbol={CLOSE_OUTLINE_24} />
			</button>
		</div>
	)

	if (tmpVideo !== null) {
		const isEmpty = !Object.keys(tmpVideo).length

		return (
			<>
				{tmpVideo.error && renderError()}

				{(isEmpty || tmpVideo.error) && <VideoInput />}

				{!isEmpty && !tmpVideo.error && (
					<div className={CLASSNAME}>
						<VideoPreview video={tmpVideo} />
					</div>
				)}
			</>
		)
	} else {
		return <Preloader className={`${CLASSNAME}__preloader`} />
	}
}

VideoUploader.propTypes = {
	video: PropTypes.object
}

VideoInput.propTypes = {
	isCompact: PropTypes.bool
}

export default VideoUploader
