
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['EquipmentWidget'] = {
  safety: function(d) { return "Bezpečnostní systémy"; },
  assist: function(d) { return "Asistenční systémy"; },
  security: function(d) { return "Zabezpečení vozidla"; },
  interior: function(d) { return "Vnitřní výbava a komfort"; },
  systems: function(d) { return "Palubní systémy a konektivita"; },
  seats: function(d) { return "Sedadla"; },
  lights: function(d) { return "Světelná technika"; },
  exterior: function(d) { return "Vnější výbava"; },
  drive: function(d) { return "Pohon a podvozek"; },
  other: function(d) { return "Ostatní"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	