
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SourceWidget'] = {
  show: function(d) { return "Chci zobrazovat"; },
  sauto: function(d) { return "Sauto"; },
  firmy: function(d) { return "Firmy.cz"; },
  from: function(d) { return " " + d.LABEL_FROM + " z "; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	