import AbstractComponent from 'app/base/AbstractComponent'
import React from 'react'
import LayoutAdmin from 'app/component/layout/admin/LayoutAdmin'
import { Surface } from '@sznds/react'
import Stepper from 'app/page/userweb/newAdvert/component/stepper/StepperView'
import STEPS_STATE_KEYS from 'app/page/userweb/newAdvert/component/stepper/StepperStateKeys'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from './AbstractNewAdvertStateKeys'
import GenericError from 'ima/error/GenericError'
import { STATUS_CONSTANTS } from 'app/model/advert/AdvertConstants'

import './AbstractNewAdvertAdminView.less'

const CLASSNAME = 'p-uw-new-advert-admin'

/**
 * Abstraktni komponenta pro stranky vkladani noveho inzeratu.
 * @class AbstractNewAdvertAdminView
 * @namespace app.page.userweb.newAdvert
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.page
 */
export default class AbstractNewAdvertAdminView extends AbstractComponent {
	constructor(props, context) {
		super(props, context)
	}

	render() {
		const { [ABSTRACT_NEW_ADVERT_STATE_KEYS.HTTP_STATUS]: httpStatus } = this.props
		const defaultLayoutProps = {
			httpStatus,
			notLoggedTitle: this.localize('BasicInfoView.notLoggedTitle')
		}

		return (
			<LayoutAdmin {...this._getLayoutProps(defaultLayoutProps)} isNewAdvert={true}>
				{this._renderSubheader()}
				{this._renderContent()}
			</LayoutAdmin>
		)
	}

	_renderSubheader() {
		const {
			[STEPS_STATE_KEYS.STEPS]: steps,
			[ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntity
		} = this.props

		const isDraft = advertEntity?.status === STATUS_CONSTANTS.DRAFT

		return (
			<Surface surface={4}>
				<div className={`${CLASSNAME}__stepper`}>
					<Stepper steps={steps} isDraft={isDraft} />
				</div>
			</Surface>
		)
	}

	_getLayoutProps() {
		throw new GenericError('Metoda _getLayoutProps je abstraktní a musí být implementována.')
	}

	_renderContent() {
		throw new GenericError('Metoda _renderContent() je abstraktní a musí být implementována.')
	}
}
