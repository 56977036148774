/**
 * @class ArticlesDetailEntity
 * @description Obsahuje data pro porovnání detailu článků
 * @module models
 */
export default class ArticlesDetailEntity {
	constructor(data) {
		this.createDate = data.createDate
		this.editDate = data.editDate
		this.filters = data.filters
		this.id = data.id
		this.isShown = data.isShown
		this.modelId = data.modelId
		this.source = data.source
		this.title = data.title
		this.url = data.url
		this.imageUrl = data.imageUrl
		this.perex = data.perex
	}
}
