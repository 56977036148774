import { createEntityList } from '@inzeraty/form-lines'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { StatusMessage } from '@inzeraty/components'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { STATUS_CONSTANTS, DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'

const NOT_ONLY_ONE_PREMISE_ERROR = 'notOnlyOnePremiseError'

import 'app/base/ActionMessageCS.json'

export const getCanBeSelectedEntitiesTopped = (selectedEntities) => {
	return selectedEntities.every((entity) => {
		const isActive = entity.status === STATUS_CONSTANTS.ACTIVE
		const hasPremiseId = entity.premise?.id
		const hasSamePremiseId = entity.premise?.id === selectedEntities[0].premise?.id
		return isActive && hasPremiseId && hasSamePremiseId
	})
}

const DEFAULT_DEAL_TYPE_API_PARAM_VALUE = [DEAL_TYPE.SALE, DEAL_TYPE.SALE_OR_LEASE].join(',')

export default class IAAdvertListController extends AbstractInternalAdminController {
	static get ACTIONS() {
		return Object.freeze({
			ACTIVATE: 'activate',
			DEACTIVATE: 'deactivate',
			DELETE: 'delete',
			FORBID: 'forbid',
			ADD_TO_PO: 'addToPO',
			REMOVE_FROM_PO: 'removeFromPO',
			ALLOW: 'allow',
			RESTORE: 'restore',
			TOPPED_ADVERT: 'toppedAdvert'
		})
	}

	static get SOURCE() {
		return {
			import_source: 'Import',
			web_source: 'Ručně'
		}
	}

	constructor(
		dependenciesHelper,
		entitiesFilterExtension,
		advertService,
		paymentService,
		premiseService,
		forbidAdvertPopupExtension
	) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._advertService = advertService
		this._paymentService = paymentService
		this._premiseService = premiseService
		this._forbidAdvertPopupExtension = forbidAdvertPopupExtension

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
		this._setAdvertEntitiesToState = this._setAdvertEntitiesToState.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)
		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)

		this.addExtension(this._forbidAdvertPopupExtension)
		this._forbidAdvertPopupExtension.setAddToReportedListInIA()
		this._forbidAdvertPopupExtension.setSuccessHandler(this._setAdvertEntitiesToState)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			statusErrorMessage: null
		})
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			statusErrorMessage: null,
			isLoading: true
		}
	}

	async getEntitiesAndPagination() {
		const fetchAdvertList = async (params) => {
			try {
				const { advertList, paginationEntity } = await this._advertService.getAdvertList(params)

				return {
					entities: advertList,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = '-id'
		}

		if (!params.deal_type) {
			params.deal_type = DEFAULT_DEAL_TYPE_API_PARAM_VALUE
		}

		const pageLoaderId = this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchAdvertList(params)

		this._pageLoaderExtension.hide(pageLoaderId)

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	_handleAction({ action, entities, entitiesIds = [] }) {
		let promise
		let getErrorMessage = () => ''
		let getSuccessMessage = () => ''

		const dictData = {
			COUNT: entitiesIds.length,
			IDS: entitiesIds.join(', ')
		}

		switch (action) {
			case IAAdvertListController.ACTIONS.ACTIVATE:
				promise = this._advertService.updateAdvertStatus({
					ids: entitiesIds,
					status_active: true
				})
				getSuccessMessage = () => this._dictionary.get('ActionMessage.activateSuccess', dictData)
				getErrorMessage = () => this._dictionary.get('ActionMessage.activateError', dictData)
				break

			case IAAdvertListController.ACTIONS.DEACTIVATE:
				promise = this._advertService.updateAdvertStatus({
					ids: entitiesIds,
					status_active: false
				})
				getSuccessMessage = () => this._dictionary.get('ActionMessage.deactivateSuccess', dictData)
				getErrorMessage = () => this._dictionary.get('ActionMessage.deactivateError', dictData)
				break

			case IAAdvertListController.ACTIONS.FORBID:
				this._forbidAdvertPopupExtension.openPopup(entitiesIds[0])
				break

			case IAAdvertListController.ACTIONS.DELETE:
				if (window.confirm(this._dictionary.get('ActionMessage.removeConfirmation', dictData))) {
					promise = this._advertService.deleteAdverts(entitiesIds)
					getSuccessMessage = () => this._dictionary.get('ActionMessage.removeSuccess', dictData)
					getErrorMessage = () => this._dictionary.get('ActionMessage.removeError', dictData)
				} else {
					return {}
				}
				break

			case IAAdvertListController.ACTIONS.ALLOW:
				promise = this._advertService.updateAdvertStatusAndHandleNotPaidError({
					ids: entitiesIds,
					status_disabled: false
				})
				getSuccessMessage = () => this._dictionary.get('ActionMessage.allowSuccess', dictData)
				getErrorMessage = () => this._dictionary.get('ActionMessage.allowError', dictData)
				break

			case IAAdvertListController.ACTIONS.ADD_TO_PO:
				promise = this._advertService.updateAdvert({
					ids: entitiesIds,
					entity: {
						preferred_offer: true
					}
				})
				getSuccessMessage = () => this._dictionary.get('ActionMessage.addToPOSuccess', dictData)
				getErrorMessage = () => this._dictionary.get('ActionMessage.addToPOError', dictData)
				break
			case IAAdvertListController.ACTIONS.REMOVE_FROM_PO:
				promise = this._advertService.updateAdvert({
					ids: entitiesIds,
					entity: {
						preferred_offer: false
					}
				})
				getSuccessMessage = () =>
					this._dictionary.get('ActionMessage.removeFromPOSuccess', dictData)
				getErrorMessage = () => this._dictionary.get('ActionMessage.removeFromPOError', dictData)
				break

			case IAAdvertListController.ACTIONS.RESTORE:
				if (window.confirm(this._dictionary.get('ActionMessage.restoreConfirmation', dictData))) {
					promise = this._advertService.updateAdvertStatusAndHandleNotPaidError({
						ids: entitiesIds,
						status_deleted: false
					})
					getSuccessMessage = () => this._dictionary.get('ActionMessage.restoreSuccess', dictData)
					getErrorMessage = () => this._dictionary.get('ActionMessage.restoreError', dictData)
				} else {
					return {}
				}
				break

			case IAAdvertListController.ACTIONS.TOPPED_ADVERT: {
				const premiseIds = entities.map((entity) => entity.premise?.id)
				const premiseId = premiseIds[0]

				if (getCanBeSelectedEntitiesTopped(entities)) {
					promise = this._paymentService.topPayment({
						payment_type: PAYMENTS_CONSTANTS.PAYMENT_TYPE.PAYMENT_WALLET,
						payment_reason: PAYMENTS_CONSTANTS.PAYMENT_REASON.TOP,
						advert_ids: entitiesIds,
						premise_id: premiseId
					})
				} else {
					promise = Promise.reject(NOT_ONLY_ONE_PREMISE_ERROR)
				}
				getSuccessMessage = (data) => {
					if (!entitiesIds.length) {
						return ''
					} else {
						return this._dictionary.get('ActionMessage.toppedSuccess', dictData)
					}
				}
				getErrorMessage = (error) => {
					if (error === NOT_ONLY_ONE_PREMISE_ERROR) {
						return this._dictionary.get('ActionMessage.toppedOnlyOnePremiseError')
					} else {
						const { body } = error.getParams()

						const errors = !isNullOrUndefined(body) ? body.errors : []

						const [toppedError = {}] = errors
						const {
							ERROR: { WALLET }
						} = PAYMENTS_CONSTANTS

						const premiseWalletInfo = this.getWalletInfo(premiseId)

						const { error_code: errorCode = '' } = toppedError
						const { walletUserId, credit } = premiseWalletInfo

						let reason = ''

						if (errorCode === WALLET.WITHOUT_WALLET) {
							reason = walletUserId
								? this._dictionary.get('ActionMessage.toppedNotActivatedWallet')
								: this._dictionary.get('ActionMessage.toppedNotConnectedWallet')
						} else if (errorCode === WALLET.LOW_CREDIT) {
							reason =
								walletUserId && isNullOrUndefined(credit)
									? this._dictionary.get('ActionMessage.toppedNotActivatedWallet')
									: this._dictionary.get('ActionMessage.toppedLowCredit')
						} else if (errorCode === WALLET.NOT_ACTIVE_ADVERT) {
							reason = this._dictionary.get('ActionMessage.toppedNotActive')
						}

						return this._dictionary.get(
							'ActionMessage.toppedError',
							Object.assign({}, dictData, {
								REASON: reason
							})
						)
					}
				}
				break
			}
		}

		return {
			promise,
			getErrorMessage,
			getSuccessMessage
		}
	}

	onAdvertTop({ advertIds }) {
		const {
			entitiesAndPagination: { entities = [] }
		} = this.getState()

		const selectedEntities = entities.filter((entity) => advertIds.includes(entity.id))

		this.onAction({
			action: IAAdvertListController.ACTIONS.TOPPED_ADVERT,
			entities: selectedEntities
		})
	}

	async getWalletInfo(premiseId) {
		const padeLoaderId = this._pageLoaderExtension.show()

		const premiseWalletInfo = await this._premiseService.getPremiseWallet(
			premiseId,
			{},
			{ cache: false }
		)

		this._pageLoaderExtension.hide(padeLoaderId)

		return premiseWalletInfo
	}

	async _processSuccessAction(data) {
		let hasOperationError = false

		if (data && data.length) {
			// funkcia spracovava error stavy z 2 resource-ov
			// jeden vracia pole objektov a druhy pole ideciek
			const advertIds = data.reduce((acc, item) => {
				if (typeof item === 'object') {
					const { operation_ok, advert_id } = item

					if (!operation_ok) {
						acc.push(advert_id)
					}
				} else {
					acc.push(item)
				}

				return acc
			}, [])

			if (advertIds.length > 0) {
				hasOperationError = true

				this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
					type: StatusMessage.TYPE.ERROR,
					title: this._dictionary.get('ActionMessage.titleError'),
					text: this._dictionary.get('ActionMessage.actionError', {
						COUNT: advertIds.length,
						IDS: advertIds.join(', ')
					})
				})
			}
		}

		this._setAdvertEntitiesToState()

		return hasOperationError
	}

	async _setAdvertEntitiesToState() {
		const entitiesAndPagination = await this.getEntitiesAndPagination()

		this.setState({
			entitiesAndPagination
		})
	}

	_filterInitiezerClb(routeParams) {
		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'name',
					name: 'Název inzerátu'
				},
				{
					value: 'premise_name',
					name: 'Název klienta'
				},
				{
					value: 'id',
					name: 'ID inzerátu'
				},
				{
					value: InternalAdminUrlConvertor.constants.PREMISE_ID,
					name: 'ASK ID'
				},
				{
					value: InternalAdminUrlConvertor.constants.USER_ID,
					name: 'ID RUS'
				},
				{
					value: 'subject_ico',
					name: 'IČ klienta'
				},
				{
					value: InternalAdminUrlConvertor.constants.EMAIL,
					name: 'Email'
				},
				{
					value: InternalAdminUrlConvertor.constants.PHONE,
					name: 'Telefon'
				},
				{
					value: 'custom_id',
					name: 'ID importní'
				},
				{
					value: 'vin',
					name: 'VIN'
				}
			]),
			{
				widget: IDS.DATE,
				id: 'create_date_from',
				label: 'Datum vložení od',
				value: routeParams.create_date_from
			},
			{
				widget: IDS.DATE,
				id: 'create_date_to',
				label: 'Datum vložení do',
				value: routeParams.create_date_to
			},
			...this._entitiesFilterExtension.getCategoryManufacturerModelSelects(),
			...this._entitiesFilterExtension.getRegionDistrictSelects(),
			this._entitiesFilterExtension.getSelect(
				'source',
				'Zadáno',
				'Vyberte',
				Object.keys(IAAdvertListController.SOURCE).map((sourceKey) => {
					return {
						value: sourceKey,
						name: IAAdvertListController.SOURCE[sourceKey]
					}
				})
			),
			...this._entitiesFilterExtension.getStatusDeactivationReasonSelects(),
			...this._entitiesFilterExtension.getPreferredOffersSelects(),
			...this._entitiesFilterExtension.getAdvertisingSelects(),
			this._entitiesFilterExtension.getDealTypeSelect(DEFAULT_DEAL_TYPE_API_PARAM_VALUE),
			this._entitiesFilterExtension.getCheckbox('with_video', 'Jen inzeráty s videem')
		])
	}
}
