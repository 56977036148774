export default class PremiseEntityImportLogItem {
	/**
	 * @constructor
	 * @param {Object} data
	 * @param {Number} data.asdvertId
	 * @param {Object} data.datacenter
	 * @param {Number} data.duration
	 * @param {String} data.rpcMethod
	 * @param {String} data.rpcStatusCode
	 * @param {Date} data.timestamp
	 */
	constructor(data) {
		this.advertId = data.advertId
		this.datacenter = data.datacenter
		this.duration = data.duration
		this.rpcMethod = data.rpcMethod
		this.rpcStatusCode = data.rpcStatusCode
		this.rpcStatusMessage = data.rpcStatusMessage
		this.timestamp = data.timestamp
	}
}
