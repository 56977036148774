import React from 'react'
import { Surface } from '@sznds/react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './LinkPlaceholder.less'

const CLASSNAME = 'c-link-placeholder'

const LinkPlaceholder = (props) => {
	const { showHeader = false } = props

	return (
		<div className={CLASSNAME}>
			{showHeader && <div className={`${CLASSNAME}__header`}></div>}
			<Surface
				surface={4}
				className={classnames({
					[`${CLASSNAME}__wrapper`]: true,
					[`${CLASSNAME}__wrapper--with-header`]: showHeader
				})}
			>
				<div className={`${CLASSNAME}__icon`}></div>
				<div className={`${CLASSNAME}__info-wrapper`}>
					<div className={`${CLASSNAME}__title`}></div>
					<div className={`${CLASSNAME}__text`}></div>
				</div>
			</Surface>
		</div>
	)
}

LinkPlaceholder.propTypes = {
	showHeader: PropTypes.bool
}

export default LinkPlaceholder
