import ns from 'ima/namespace'

ns.namespace('app.helpers.dependenciesHelper')

/**
 * Helper nastavovani zavislosti.
 *
 * @class DependenciesHelper
 * @namespace app.helpers.dependenciesHelper
 * @extends app.helpers.dependenciesHelper.DependenciesHelper
 * @module app
 * @submodule app.helpers
 */
export default class DependenciesHelper {
	/**
	 * @method constructor
	 * @constructor
	 * @param {Object<ima.extension.AbstractExtension>} extensions
	 * @param {Object} dependencies
	 */
	constructor(extensions, dependencies) {
		/**
		 * Each extensions
		 *
		 * @property _extensions
		 * @type {Object<ima.extension.AbstractExtension>}
		 */
		this._extensions = extensions

		this._dependencies = dependencies
	}

	/**
	 * Add each extension to defined controller.
	 *
	 * @method addExtensionsToController
	 * @param {ima.controller.Controller} controller
	 */
	addExtensionsToController(controller) {
		const extensionsValues = Object.values(this._extensions)

		extensionsValues.forEach((extension) => {
			controller.addExtension(extension)
		})
	}

	/**
	 * Get dependency by Name.
	 *
	 * @method getDependency
	 * @param {ima.controller.Controller} controller
	 */
	getDependency(dependencyName) {
		if (this._dependencies[dependencyName] instanceof Object) {
			return this._dependencies[dependencyName]
		} else {
			return null
		}
	}

	/**
	 * Get extension by Name.
	 *
	 * @method getExtension
	 * @param {ima.controller.Controller} controller
	 */
	getExtension(extensionName) {
		if (this._extensions[extensionName] instanceof Object) {
			return this._extensions[extensionName]
		} else {
			return null
		}
	}
}

ns.app.helpers.dependenciesHelper.DependenciesHelper = DependenciesHelper
