const RESOURCE = '/cebia'

export default class VINVehicleDataResource {
	/**
	 * @constructor
	 * @param {RestClient} restClient
	 **/
	constructor(restClient) {
		this._restClient = restClient
	}

	/**
	 * Na zaklade zadaneho VINu vraci dostupne informace o vozidle
	 * (napr. znacku, model, rok vyroby atd.)
	 *
	 * @public
	 * @method getVehicleData
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>} Dostupna data o vozidle.
	 */
	getVehicleData(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/car_info`, params, options)
	}

	/**
	 * Na zaklade zadaneho VINu vraci dostupne informace o vybave vozidle
	 * (napr. zda ma vozidlo ABS, klimatizaci atd.)
	 *
	 * @public
	 * @method getVehicleEquipmentData
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<JSON>} Dostupna data o vybave vozidla.
	 */
	getVehicleEquipmentData(params = {}, options = {}) {
		return this._restClient.get(`${RESOURCE}/equipment`, params, options)
	}
}
