import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractComponent from 'app/base/AbstractComponent'
import { Gallery, GalleryPhoto, GalleryVideo } from '@inzeraty/components'
import ImgCount from 'app/component/imgCount/ImgCountView'

import './GalleryView.less'
import './GalleryCS.json'

const CLASSNAME = 'c-gallery'

export default class GalleryView extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			// zobrazit neco v galerii je mozne dvema zpusoby:
			// 	1) vykreslime si vlastni komponenty do children nebo
			// 	2) nastavime props images a videos, kam posleme jenom pole objektu. Timto
			// 	   ale prijdeme o moznost ridit si poradi.
			images: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					url: PropTypes.string.isRequired
				})
			),
			videos: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					url: PropTypes.string.isRequired
				})
			),
			renderContent: PropTypes.func,
			renderFullscreenContent: PropTypes.func,
			renderFooter: PropTypes.func,
			previewImageResponsiveSettings: PropTypes.object,
			normalImageResponsiveSettings: PropTypes.object,
			fullscreenImageResponsiveSettings: PropTypes.object,
			previewSphereImageResponsiveSettings: PropTypes.object,
			normalSphereImageResponsiveSettings: PropTypes.object,
			fullscreenSphereImageResponsiveSettings: PropTypes.object
		}
	}

	static get defaultProps() {
		return {
			className: '',
			images: DEFAULT_PROPS.ARRAY,
			videos: DEFAULT_PROPS.ARRAY
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderCustomListOpener = this._renderCustomListOpener.bind(this)

		this._dictionary = {
			prev: this.localize('Gallery.prev'),
			next: this.localize('Gallery.next'),
			close: this.localize('Gallery.close'),
			pickImage: (index, total) =>
				this.localize('Gallery.pickImage', { INDEX: index, LENGTH: total }),
			pickVideo: (index, total) =>
				this.localize('Gallery.pickVideo', { INDEX: index, LENGTH: total })
		}
	}

	render() {
		const {
			className,
			images,
			videos,
			children,
			renderContent,
			renderFullscreenContent,
			renderFooter,
			previewImageResponsiveSettings,
			normalImageResponsiveSettings,
			fullscreenImageResponsiveSettings,
			previewSphereImageResponsiveSettings,
			normalSphereImageResponsiveSettings,
			fullscreenSphereImageResponsiveSettings
		} = this.props

		const dataDotProps = {
			gallery: 'gallery',
			inPage: 'inpage',
			inPageItem: 'show-fullscreen',
			fullscreen: 'fullscreen',
			fullscreenItem: 'show-photo',
			previews: 'grid',
			previewsItem: 'show-photo',
			next: 'next',
			prev: 'previous',
			close: 'close',
			showList: 'show-grid'
		}

		return (
			<Gallery
				className={className}
				dictionary={this._dictionary}
				renderContent={renderContent}
				renderFullscreenContent={renderFullscreenContent}
				renderListOpener={this._renderCustomListOpener}
				renderFooter={renderFooter}
				previewImageResponsiveSettings={previewImageResponsiveSettings}
				normalImageResponsiveSettings={normalImageResponsiveSettings}
				fullscreenImageResponsiveSettings={fullscreenImageResponsiveSettings}
				previewSphereImageResponsiveSettings={previewSphereImageResponsiveSettings}
				normalSphereImageResponsiveSettings={normalSphereImageResponsiveSettings}
				fullscreenSphereImageResponsiveSettings={fullscreenSphereImageResponsiveSettings}
				dataDot={dataDotProps}
			>
				{children
					? children
					: [
							...videos.map((video) => ({ isVideo: true, video })),
							...images.map((image) => ({ isVideo: false, image }))
					  ].map((data, index) =>
							data.isVideo ? (
								<GalleryVideo key={`${data.video.url}_${index}`} video={data.video} />
							) : (
								<GalleryPhoto key={`${data.image.url}_${index}`} image={data.image} />
							)
					  )}
			</Gallery>
		)
	}

	_renderCustomListOpener(currentItemIndex = 0, itemsTotal = 0, openGalleryWithListCallback) {
		const text = itemsTotal > 0 ? `${currentItemIndex}/${itemsTotal}` : 0

		return (
			<button
				className={`${CLASSNAME}__list-opener`}
				onClick={openGalleryWithListCallback}
				data-dot='show-full-all'
			>
				<ImgCount text={text} />
			</button>
		)
	}
}
