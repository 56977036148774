import { createOptions } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLines'

// pro nektere elementy nam prijdou vsechny mozne hodnoty v options (plati pro
// ciselniky jako napr. prevodovka nebo palivo),
// pro nektere elementy si musime mozne hodnoty vypocitat sami, prijde nam jen
// rozsah hodnot ve validation objektu (plati napr. pro pocet dveri nebo mist)
const getOptions = (element, dictionary) => {
	const {
		name: id,
		options,
		validation: { min_limit: rangeMin, max_limit: rangeMax } = {}
	} = element

	return {
		id,
		options:
			options && options.length ? options : createOptions(id, { rangeMin, rangeMax }, dictionary)
	}
}

export const flattenCreatePageData = (createPageData = {}) => {
	const { sections = [] } = createPageData

	return sections.reduce((allElements, section) => {
		const { elements } = section
		return [...allElements, ...elements]
	}, [])
}

export const processOptionsForFormLineEntities = (createPageData, dictionary) => {
	return flattenCreatePageData(createPageData)
		.map((element) => getOptions(element, dictionary))
		.filter(({ options = [] }) => options.length)
}

export const processValuesForFormLineEntities = (createPageData) => {
	return flattenCreatePageData(createPageData)
		.map((element) => ({
			id: element.name,
			value: element.value
		}))
		.filter(({ value }) => !!value)
}
