import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import * as FormLines from '@inzeraty/form-lines'
import { CategoryEntity } from '@inzeraty/models'
import ShowItemsButton from 'app/component/showItemsButton/ShowItemsButton'
import { Button } from '@sznds/react'
import FilterConstants from 'app/model/filter/FilterConstants'
import MainForm from './forms/main/MainForm'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import select from 'ima-plugin-select'
import FiltersExtension, { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import ExtendedFilterPopupWithOpener from './factories/popups/ExtendedFilterPopupWithOpener'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'

import './FiltersView.less'
import './FiltersViewCS.json'

const DEFAULT_CATEGORY_PROP = new CategoryEntity(DEFAULT_PROPS.OBJECT)

const CLASSNAME = 'c-filters'

class FiltersView extends AbstractComponent {
	static get propTypes() {
		return {
			isOnHP: PropTypes.bool,
			formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
			changeFilter: PropTypes.func,
			categoryEntity: PropTypes.instanceOf(CategoryEntity),
			filterUrlParams: PropTypes.object,
			filteredAdvertsCountTotal: PropTypes.number,
			isLoadingFilteredAdvertsCountTotal: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			isOnHP: false,
			formLineEntities: DEFAULT_PROPS.ARRAY,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			categoryEntity: DEFAULT_CATEGORY_PROP,
			filterUrlParams: DEFAULT_PROPS.OBJECT,
			isLoadingFilteredAdvertsCountTotal: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this._openExtendedFilterPopup = this._openExtendedFilterPopup.bind(this)

		this._redirectOnChange = this._redirectOnChange.bind(this)
	}

	render() {
		const { isOnHP } = this.props

		if (isOnHP) {
			return (
				<React.Fragment>
					{this._renderHomePageFilters()}
					<div className={`${CLASSNAME}__buttons`}>
						{this._renderShowAllFiltersButton()}
						{this._renderShowItemsButton()}
					</div>
				</React.Fragment>
			)
		} else {
			return <React.Fragment>{this._renderFilterButton()}</React.Fragment>
		}
	}

	_redirectOnChange(id, value, callback = () => {}) {
		const { changeFilter, formLineEntities } = this.props
		const formLineEntity = formLineEntities.find((formLineEntity) => formLineEntity.id === id)

		const { AnalyticsDataSender, $Router } = this._utils

		const { route } = $Router.getCurrentRouteInfo()
		const routeName = route.getName()

		AnalyticsDataSender.hitFilterChange(formLineEntity, value, 'Filter', routeName)

		changeFilter(id, value, callback)
	}

	_renderShowItemsButton() {
		const {
			isOperatingLease,
			categoryEntity = {},
			filterUrlParams,
			isLoadingFilteredAdvertsCountTotal,
			filteredAdvertsCountTotal
		} = this.props

		const url = this.link(
			isOperatingLease
				? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
				: ROUTE_NAMES.USERWEB.ADVERT_LIST,
			Object.assign(
				{},
				{
					[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categoryEntity.seoName
				},
				filterUrlParams
			)
		)

		return (
			<ShowItemsButton
				className={`${CLASSNAME}__show-items`}
				url={url}
				count={filteredAdvertsCountTotal}
				isLoading={isLoadingFilteredAdvertsCountTotal}
				showIcon={false}
				data-dot='show-adverts'
				data-e2e='filters-submit'
				onClick={() => this.fire('resetCache')}
			/>
		)
	}

	_renderShowAllFiltersButton() {
		const {
			categoryEntity: { seoName }
		} = this.props

		return (
			<ExtendedFilterPopupWithOpener
				changeFilter={this._redirectOnChange}
				context={FILTER_CONTEXT.MAIN_MENU}
				renderOpener={() => (
					<Button
						className={`${CLASSNAME}__show-filters`}
						primary={true}
						text={this.localize(`FiltersView.showAllFilters--${seoName}`)}
						onClick={this._openExtendedFilterPopup}
						data-dot='show-all-filters'
						data-e2e='all-filters'
					/>
				)}
			/>
		)
	}

	_renderFilterButton() {
		return (
			<ExtendedFilterPopupWithOpener
				changeFilter={this._redirectOnChange}
				context={FILTER_CONTEXT.TAGS}
				renderOpener={() => (
					<Button
						primary={true}
						size='small'
						text={this.localize('FiltersView.filter')}
						onClick={this._openExtendedFilterPopup}
						data-dot='show-all-filters'
						data-e2e='all-filters'
					/>
				)}
			/>
		)
	}

	_renderHomePageFilters() {
		const {
			isOperatingLease,
			formLineEntities,
			categoryEntity,
			openedFilterPopups,
			openedFilterDropdown
		} = this.props

		if (formLineEntities && formLineEntities.length > 0) {
			return (
				<div className={`${CLASSNAME}__homepage`}>
					<MainForm
						isOperatingLease={isOperatingLease}
						formLineEntities={formLineEntities}
						changeFilter={this._redirectOnChange}
						categoryEntity={categoryEntity}
						context={FILTER_CONTEXT.MAIN_MENU}
						openedFilterPopups={openedFilterPopups}
						openedFilterDropdown={openedFilterDropdown}
					/>
				</div>
			)
		} else {
			return (
				<div className={`${CLASSNAME}__placeholder`}>
					{this._renderBrandsPlaceholder()}
					<div className={`${CLASSNAME}__placeholder-inputs`}>
						{this._renderFilterPlaceholder()}
						{this._renderFilterPlaceholder()}
						{this._renderFilterPlaceholder()}
						{this._renderFilterPlaceholder()}
					</div>
				</div>
			)
		}
	}

	_openExtendedFilterPopup() {
		const { isOnHP } = this.props

		this.fire('openFilterPopup', {
			filterId: FilterConstants.filterIds.EXTENDED_FILTER_ID,
			filterPopupType: FilterConstants.filterPopupType.DEFAULT,
			filterButtonType: FilterConstants.filterButtonType.SEARCH,
			updateUrlParams: !isOnHP
		})
	}

	_renderFilterPlaceholder() {
		return (
			<div className={`${CLASSNAME}__placeholder--wrapper`}>
				<div className={`${CLASSNAME}__placeholder--filter`}></div>
			</div>
		)
	}

	_renderBrandsPlaceholder() {
		return (
			<div className={`${CLASSNAME}__placeholder--brands`}>{this._generateBrandPlaceholders()}</div>
		)
	}

	_generateBrandPlaceholders(count = 12) {
		const brands = []
		for (let i = 0; i < count; i++) {
			brands.push(
				<div key={i} className={`${CLASSNAME}__placeholder--brand-wrapper`}>
					<div className={`${CLASSNAME}__placeholder--brand`}></div>
				</div>
			)
		}

		return brands
	}
}

const FiltersSelector = (state) => ({
	isOperatingLease: state[FiltersExtension.stateIds.IS_OPERATING_LEASE],
	formLineEntities: state[FiltersExtension.stateIds.FORM_LINE_ENTITIES],
	changeFilter: state[FiltersExtension.stateIds.CHANGE_FILTER],
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY],
	filterUrlParams: state[FiltersExtension.stateIds.URL_PARAMS],
	openedFilterPopups: state[FiltersExtension.stateIds.OPENED_FILTER_POPUPS],
	openedFilterDropdown: state[FiltersExtension.stateIds.OPENED_FILTER_DROPDOWN],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL],
	isLoadingFilteredAdvertsCountTotal:
		state[FiltersExtension.stateIds.IS_LOADING_FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(FiltersSelector)(FiltersView)
