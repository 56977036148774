import { AbstractFactory } from '@inzeraty/models'
import ReviewReplyEntity from './ReviewReplyEntity'

export default class ReviewReplyFactory extends AbstractFactory {
	/**
	 * @constructor
	 */
	constructor() {
		super(ReviewReplyEntity)
	}

	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformData(data) {
		return {
			createDate: data.create_date,
			editDate: data.edit_date,
			id: data.id,
			negativeFeedbackCount: data.negative_feedback_count,
			positiveFeedbackCount: data.positive_feedback_count,
			reviewReply: data.review_reply,
			userEmailPrefix: data.user_email_prefix,
			userFeedbackType: data.user_feedback_type,
			userId: data.user_id,
			userName: data.user_name,
			reviewId: data.review_id,
			reviewTitle: data.review_title
		}
	}
}
