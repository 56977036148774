import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocalize } from 'app/base/componentHelpers'
import ActionElm from 'app/component/actionElement/ActionElement'

import './ShowMoreTextView.less'
import './ShowMoreTextCS.json'

const CLASSNAME = 'c-show-more-text'

const ShowMoreText = ({
	tagName = 'div',
	text = '',
	className = '',
	limitLength,
	dataDotData,
	...restProps
}) => {
	const Tag = tagName
	const localize = useLocalize()

	const [isOpen, setIsOpen] = useState(false)

	const renderToggleSwitch = () => {
		const text = isOpen
			? localize('ShowMoreText.showLessText')
			: localize('ShowMoreText.showMoreText')
		const dataDot = isOpen ? 'show-less' : 'show-more'

		const toggleProps = {
			className: `${CLASSNAME}__toggle`,
			tagName: 'button',
			onClick: () => {
				setIsOpen(!isOpen)
			}
		}

		if (dataDotData) {
			Object.assign(toggleProps, {
				dataDot,
				dataDotData
			})
		}

		return <ActionElm {...toggleProps}>{text}</ActionElm>
	}

	const getLimitClosestSpaceIndex = () => {
		const beforeLimitSpaceIndex = text.lastIndexOf(' ', limitLength)
		const afterLimitSpaceIndex = text.indexOf(' ', limitLength)

		if (beforeLimitSpaceIndex > 0 && afterLimitSpaceIndex > 0) {
			return beforeLimitSpaceIndex - limitLength < afterLimitSpaceIndex - limitLength
				? beforeLimitSpaceIndex
				: afterLimitSpaceIndex
		} else if (beforeLimitSpaceIndex > 0) {
			return beforeLimitSpaceIndex
		} else if (afterLimitSpaceIndex > 0) {
			return afterLimitSpaceIndex
		} else {
			return limitLength
		}
	}

	const renderLimitedText = () => (
		<Tag
			className={classnames({
				[className]: !!className,
				[CLASSNAME]: true
			})}
			{...restProps}
		>
			{isOpen ? text : text.substring(0, getLimitClosestSpaceIndex())}
			{!isOpen ? <>&nbsp;… </> : ' '}
			{renderToggleSwitch()}
		</Tag>
	)

	const renderOriginalText = () => (
		<Tag
			className={classnames({
				[className]: !!className
			})}
			{...restProps}
		>
			{text}
		</Tag>
	)

	if (!limitLength || text.length < limitLength) {
		return renderOriginalText()
	} else {
		return renderLimitedText()
	}
}

ShowMoreText.propTypes = {
	tagName: PropTypes.string,
	text: PropTypes.string.isRequired,
	className: PropTypes.string,
	limitLength: PropTypes.number,
	dataDotData: PropTypes.string
}

export default React.memo(ShowMoreText)
