const RESOURCE = '/items'

/**
 * @class CarCompareResource
 * @module models
 */
export default class CarCompareResource {
	/**
	 * constructor
	 * @method constructor
	 * @param {RestClient} restClient
	 */
	constructor(restClient) {
		this._restClient = restClient
	}

	/**
	 * Vrati porovnanie cien modelov s danym inzeratom
	 *
	 * @method getCompareCars
	 * @public
	 * @param {Number|String}	id		Id inzerátu
	 * @param {Object}			options
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	getCompareCars(id, options = {}) {
		return this._restClient.get(`${RESOURCE}/${id}/compare`, options)
	}
}
