
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['EmailWidget'] = {
  unknown: function(d) { return "Tento e-mail zatím neznáme."; },
  sendPin: function(d) { return "Odeslat ověřovací e-mail"; },
  pinMsg1: function(d) { return "Na "; },
  pinMsg2: function(d) { return " jsme zaslali zprávu s číselným PIN kódem."; },
  pinVerified: function(d) { return "Váš e-mail je ověřen"; },
  wrongEmail: function(d) { return "Neplatný e-mail."; },
  emailInfo: function(d) { return "Na e-mail posíláme jen zprávy od zájemců"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	