export default class ReviewService {
	/**
	 * @constructor
	 * @param {ReviewResource} ReviewResource
	 * @param {ReviewFactory}  ReviewFactory
	 * @param {ReviewReplyFactory}  ReviewReplyFactory
	 */
	constructor(reviewResource, reviewFactory, reviewReplyFactory, paginationFactory) {
		this._reviewResource = reviewResource
		this._reviewFactory = reviewFactory
		this._reviewReplyFactory = reviewReplyFactory
		this._paginationFactory = paginationFactory
	}

	/**
	 * Vrati seznam zkusenosti se znackou a modelem
	 *
	 * @public
	 * @method getReviewsList
	 */
	async getReviewsList(params, options = { cache: false }) {
		return this._reviewResource.getReviewsList(params, options).then((response) => {
			const { results, pagination } = response.body

			const reviewsData = results.map((review) => {
				const transformedReviewData = this._reviewFactory.transformData(review)

				return Object.assign(transformedReviewData, {
					reviewReplies: this._convertReviewRepliesToList(transformedReviewData.reviewReplies)
				})
			})

			return {
				reviewEntities: this._reviewFactory.createEntityList(reviewsData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	_convertReviewRepliesToList(reviewReplies = []) {
		// Prevedu si data odpovedi ke zkusenosti
		let transformedReviewReplies = reviewReplies.map((reply) =>
			this._reviewReplyFactory.transformData(reply)
		)

		// Pokud mam u zkusenosti vice odpovedi, seradim je od nejstarsi
		if (transformedReviewReplies.length > 1) {
			transformedReviewReplies = transformedReviewReplies.sort(
				(a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
			)
		}

		return this._reviewReplyFactory.createEntityList(transformedReviewReplies)
	}

	/**
	 * Ulozi zkusenost majitele
	 *
	 * @public
	 * @method saveReview
	 */
	async saveReview(data) {
		return this._reviewResource.saveReview(data)
	}

	/**
	 * Smaze zkusenost majitele
	 *
	 * @public
	 * @method deleteReview
	 */
	async deleteReview(reviewId) {
		return this._reviewResource.deleteReview(reviewId)
	}

	/**
	 * Ulozi hodnoceni zkusenosti majitele
	 *
	 * @public
	 * @method saveReviewFeedback
	 */
	async saveReviewFeedback(reviewId, data) {
		return this._reviewResource.saveReviewFeedback(reviewId, data)
	}

	/**
	 * Zmeni hodnoceni zkusenosti majitele
	 *
	 * @public
	 * @method changeReviewFeedback
	 */
	async changeReviewFeedback(reviewId, data) {
		return this._reviewResource.changeReviewFeedback(reviewId, data)
	}

	/**
	 * Smaze hodnoceni zkusenosti majitele
	 *
	 * @public
	 * @method deleteReviewFeedback
	 */
	async deleteReviewFeedback(reviewId) {
		return this._reviewResource.deleteReviewFeedback(reviewId)
	}

	/**
	 * Vrati seznam odpovedi k dane zkusenosti
	 *
	 * @public
	 * @method getRepliesList
	 */
	async getRepliesList(reviewId, params, options = { cache: false }) {
		return this._reviewResource.getRepliesList(reviewId, params, options).then((response) => {
			const { results, pagination } = response.body
			const repliesData = results.map((reply) => this._reviewReplyFactory.transformData(reply))

			return {
				reviewEntities: this._reviewReplyFactory.createEntityList(repliesData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	/**
	 * Vrati seznam odpovedi ke všem recenzím.
	 */
	async getReplyList(params, options) {
		return this._reviewResource.getReplyList(params, options).then((response) => {
			const { results, pagination } = response.body
			const repliesData = results.map((reply) => this._reviewReplyFactory.transformData(reply))

			return {
				reviewEntities: this._reviewReplyFactory.createEntityList(repliesData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	/**
	 * Ulozi odpoved na zkusenost majitele
	 *
	 * @public
	 * @method saveReply
	 * @param {Number} reviewId	Id recenze
	 */
	async saveReply(reviewId, data) {
		return this._reviewResource.saveReply(reviewId, data)
	}

	/**
	 * Smaze odpoved na zkusenost majitele
	 *
	 * @public
	 * @method deleteReply
	 * @param {Number} reviewId	Id recenze
	 * @param {Number} replyId	Id odpovedi
	 */
	async deleteReply(reviewId, replyId) {
		return this._reviewResource.deleteReply(reviewId, replyId)
	}

	/**
	 * Ulozi hodnoceni odpovedi na zkusenost majitele
	 *
	 * @public
	 * @method saveReplyFeedback
	 * @param {Number} reviewId	Id recenze
	 * @param {Number} replyId	Id odpovedi
	 */
	async saveReplyFeedback(reviewId, replyId, data) {
		return this._reviewResource.saveReplyFeedback(reviewId, replyId, data)
	}

	/**
	 * Zmeni hodnoceni odpovedi na zkusenost majitele
	 *
	 * @public
	 * @method changeReplyFeedback
	 * @param {Number} reviewId	Id recenze
	 * @param {Number} replyId	Id odpovedi
	 */
	async changeReplyFeedback(reviewId, replyId, data) {
		return this._reviewResource.changeReplyFeedback(reviewId, replyId, data)
	}

	/**
	 * Smaze hodnoceni odpovedi na zkusenost majitele
	 *
	 * @public
	 * @method deleteReplyFeedback
	 * @param {Number} reviewId	Id recenze
	 * @param {Number} replyId	Id odpovedi
	 */
	async deleteReplyFeedback(reviewId, replyId) {
		return this._reviewResource.deleteReplyFeedback(reviewId, replyId)
	}
}
