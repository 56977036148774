import React, { useRef, useState, useEffect } from 'react'
import classnames from 'classnames'
import { useDrop, useDrag } from 'react-dnd'
import { DndProvider } from 'react-dnd-multi-backend'
import MultiBackend from 'react-dnd-multi-backend'
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'
import { DropZone, DropZoneType } from '@inzeraty/components'
import PhotoPreview from './PhotoPreview'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import Preloader from 'app/component/preloader/Preloader'
import PropTypes from 'prop-types'

import './PhotosUploader.less'
import './PhotosUploaderCS.json'

const CLASSNAME = 'c-photos-uploader'
const CLASSNAME_ITEM = `${CLASSNAME}__item`

const DNDItem = ({ id, children, index, onMove, onDrop }) => {
	const ref = useRef(null)

	const [, drop] = useDrop({
		accept: 'PHOTO',
		hover: (item) => {
			if (!ref.current || item.index === index) {
				return
			}

			onMove({
				moveId: item.id,
				targetId: id
			})

			item.index = index
		},
		drop: () => {
			onDrop()
		}
	})

	const [{ isDragging }, drag] = useDrag({
		item: {
			id,
			type: 'PHOTO'
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	})

	drag(drop(ref))

	return (
		<div
			className={classnames({
				[CLASSNAME_ITEM]: true,
				[`${CLASSNAME}__dnd-preview`]: true,
				[`${CLASSNAME}__dnd-preview--dragging`]: isDragging
			})}
			ref={ref}
		>
			{children}
		</div>
	)
}

const PhotosContainer = ({ photos, onMove, onDrop }) => {
	const photosCount = photos.length

	return (
		<>
			{photos.map((photo, index) => {
				const DNDWrapper = (children) => (
					<DNDItem key={photo.id} id={photo.id} index={index} onMove={onMove} onDrop={onDrop}>
						{children}
					</DNDItem>
				)

				const isDNDItem = !photo.error && !photo.isLoading

				const photoPreview = <PhotoPreview photosCount={photosCount} photo={photo} index={index} />

				return !isDNDItem ? (
					<div className={CLASSNAME_ITEM} key={photo.id}>
						{photoPreview}
					</div>
				) : (
					DNDWrapper(photoPreview)
				)
			})}
		</>
	)
}

const PhotosInput = ({ isCompact = false }) => {
	const fire = useFire()
	const localize = useLocalize()
	const type = isCompact ? DropZoneType.COMPACT : DropZoneType.FULL

	return (
		<div
			className={classnames({
				[`${CLASSNAME}__add-item`]: true,
				[`${CLASSNAME}__add-item--compact`]: isCompact
			})}
		>
			<div className={`${CLASSNAME}__add-item-content`}>
				<DropZone
					className={`${CLASSNAME}__dropzone`}
					type={type}
					multiple={true}
					openerText={localize('PhotosUploader.openerText')}
					accept='image/*'
					onChange={(event) => {
						fire('addPhotos', event.target.files)
					}}
				>
					{localize(`PhotosUploader.${isCompact ? 'addNext' : 'inputPhotos'}`)}
				</DropZone>
			</div>
		</div>
	)
}

const PhotosUploader = ({ photos }) => {
	const fire = useFire()
	const [tmpPhotos, setTmpPhotos] = useState(null)

	useEffect(() => {
		if (photos) {
			setTmpPhotos(photos)
		}
	}, [photos])

	const gePhotoIdx = (id) => {
		return tmpPhotos.findIndex((photo) => id === photo.id)
	}

	const onMove = ({ moveId, targetId }) => {
		const newPhotos = [...tmpPhotos]

		const moveIdx = gePhotoIdx(moveId)
		const movePhoto = newPhotos[moveIdx]
		newPhotos.splice(moveIdx, 1)

		const targetIdx = gePhotoIdx(targetId)
		newPhotos.splice(targetIdx, 0, movePhoto)

		setTmpPhotos(newPhotos)
	}

	const onDrop = () => {
		fire('setPhotosOrder', tmpPhotos)
	}

	if (tmpPhotos) {
		return (
			<div
				className={classnames({
					[CLASSNAME]: true,
					[`${CLASSNAME}--compact`]: Boolean(tmpPhotos.length)
				})}
			>
				<DndProvider backend={MultiBackend} options={HTML5toTouch}>
					<PhotosContainer photos={tmpPhotos} onMove={onMove} onDrop={onDrop} />
				</DndProvider>
				<PhotosInput isCompact={Boolean(tmpPhotos.length)} />
			</div>
		)
	} else {
		return <Preloader className={`${CLASSNAME}__preloader`} />
	}
}

PhotosUploader.propTypes = {
	photos: PropTypes.arrayOf(PropTypes.object)
}

PhotosInput.propTypes = {
	isCompact: PropTypes.bool
}

PhotosContainer.propTypes = {
	photos: PropTypes.arrayOf(PropTypes.object),
	onMove: PropTypes.func,
	onDrop: PropTypes.func
}

DNDItem.propTypes = {
	id: PropTypes.string,
	index: PropTypes.number,
	onDrop: PropTypes.func,
	onMove: PropTypes.func,
	children: PropTypes.node
}

export default PhotosUploader
