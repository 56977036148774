
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Reviews'] = {
  loadingError: function(d) { return "Nepodařilo se načíst zkušenosti k tomuto vozidlu."; },
  tryAgain: function(d) { return "Zkusit načíst znovu"; },
  tabAll: function(d) { return "Vše"; },
  tabPositive: function(d) { return "Pozitivní"; },
  tabNeutral: function(d) { return "Neutrální"; },
  tabNegative: function(d) { return "Negativní"; },
  type_positive: function(d) { return "pozitivní"; },
  type_neutral: function(d) { return "neutrální"; },
  type_negative: function(d) { return "negativní"; },
  addExperience: function(d) { return "Přidat zkušenost"; },
  allExperiences: function(d) { return "Zobrazit všechny zkušenosti"; },
  emptyPerex1: function(d) { return "K tomuto vozidlu"; },
  emptyPerex2: function(d) { return "zatím nemáme"; },
  noExperience: function(d) { return "žádné " + d.TYPE_NAME + " zkušenosti"; },
  emptySufix: function(d) { return "Buďte první, kdo napíše zkušenost pro toto vozidlo"; },
  addReviewSuccess: function(d) { return "Zkušenost byla úspěšně přidána."; },
  removeReviewSuccess: function(d) { return "Zkušenost byla úspěšně smazána."; },
  addReplySuccess: function(d) { return "Reakce byla úspěšně přidána."; },
  removeReplySuccess: function(d) { return "Reakce byla úspěšně smazána."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	