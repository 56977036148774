import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import EntityForm from 'app/component/entityForm/EntityForm'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'

import './IASeoFooterEditView.less'

const CLASSNAME = 'c-ia-seo-footer'

const IASeoFooterEditView = ({ formLineEntities, entityFormError, isWaiting }) => {
	return (
		<Layout title='Editace linků pro SEO patičku'>
			<EntityForm
				className={`${CLASSNAME}__form`}
				formLineEntities={formLineEntities}
				entityFormError={entityFormError}
				isWaiting={isWaiting}
			/>
		</Layout>
	)
}

IASeoFooterEditView.propTypes = {
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)).isRequired,
	entityFormError: PropTypes.object,
	isWaiting: PropTypes.bool
}

IASeoFooterEditView.defaultProps = {
	formLineEntities: DEFAULT_PROPS.OBJECT,
	entityFormError: null,
	isWaiting: false
}

export default IASeoFooterEditView
