import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import FilterConstants from 'app/model/filter/FilterConstants'
import ROUTE_NAMES from 'app/base/RouteNames'

/**
 * @class AdvertListUrlConvertor
 * @namespace app.component.paging
 * @module app
 * @submodule app.component
 */
export default class AdvertListUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				CATEGORY_NAME: 'categoryName',
				CONDITION_NAME: 'stav',
				BRANDS_MODELS: 'znacky-modely',
				BRAND_NAME: 'brandName',
				MODEL_NAME: 'modelName',
				PRICE_FROM: 'cena-od',
				PRICE_TO: 'cena-do',
				AGE_FROM: 'vyrobeno-od',
				AGE_TO: 'vyrobeno-do',
				FUEL_NAME: 'palivo',
				TACHOMETER_FROM: 'km-od',
				TACHOMETER_TO: 'km-do',
				VEHICLE_BODY_NAME: 'typ',
				GEARBOX_NAME: 'prevodovka',
				PERFORMANCE_FROM: 'vykon-od',
				PERFORMANCE_TO: 'vykon-do',
				COUNTRY_OF_ORIGIN_NAME: 'dovoz',
				VENDOR_NAME: 'prodejce',
				REGION_NAME: 'kraj',
				DISTRICT_NAME: 'okres',
				DISPLACEMENT_FROM: 'objem-od',
				DISPLACEMENT_TO: 'objem-do',
				AIR_CONDITIONING_NAME: 'klimatizace',
				FUEL_CONSUMPTION_FROM: 'spotreba-od',
				FUEL_CONSUMPTION_TO: 'spotreba-do',
				CERTIFIED_PROGRAM_NAME: 'overene',
				AIRBAGS_FROM: 'airbag-od',
				AIRBAGS_TO: 'airbag-do',
				COLOR_NAME: 'barva',
				CAPACITY_FROM: 'pocet-mist-od',
				CAPACITY_TO: 'pocet-mist-do',
				DOORS_FROM: 'pocet-dveri-od',
				DOORS_TO: 'pocet-dveri-do',
				BUS_SEATS_NAME: 'pocet-sedadel',
				ITEM_AGE_NAME: 'stari-inzeratu',
				PRICE_OPTION_NAME: 'moznosti-ceny',
				HISTORY_INFO_NAME: 'historie',
				EQUIPMENT_NAME: 'vybava',
				DRIVE_NAME: 'pohon',
				OTHER_SPECIFICATION_NAME: 'dalsi-specifikace',
				WEIGHT_FROM: 'hmotnost-od',
				WEIGHT_TO: 'hmotnost-do',
				BEDS_FROM: 'pocet-luzek-od',
				BEDS_TO: 'pocet-luzek-do',
				MACHINE_HOURS_FROM: 'pocet-motohodin-od',
				MACHINE_HOURS_TO: 'pocet-motohodin-do',
				PHRASE: 'varianta',
				OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM: 'mesicni-cena-od',
				OPERATING_LEASE_PRICE_WITHOUT_VAT_TO: 'mesicni-cena-do',
				OPERATING_LEASE_ANNUAL_DISTANCE_FROM: 'rocni-najezd-od',
				OPERATING_LEASE_ANNUAL_DISTANCE_TO: 'rocni-najezd-do',
				OPERATING_LEASE_PERIOD_FROM: 'doba-pronajmu-od',
				OPERATING_LEASE_PERIOD_TO: 'doba-pronajmu-do',
				OPERATING_LEASE_SERVICES_NAME: 'dalsi-sluzby',
				OPERATING_LEASE_INTENDED_FOR_NAME: 'pro-koho',
				MY_SAVED_SEARCH_HASH: 'watchdog_hash',
				MY_SAVED_SEARCH_ID: 'watchdog_id',
				MY_SAVED_SEARCH_TIMESTAMP: 'watchdog_timestamp'
			},

			URL_API_PARAMS: {
				OPERATING_LEASE: 'operating_lease', // bool hodnota, jestli se jedna o operak nebo ne
				CATEGORY_ID: UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID,
				CONDITION_NAME: 'condition_seo',
				BRANDS_MODELS_SEO: 'manufacturer_model_seo',
				BRANDS_MODELS_VALUES: 'manufacturer_model_cb',
				PRICE_FROM: 'price_from',
				PRICE_TO: 'price_to',
				AGE_FROM: 'vehicle_age_from',
				AGE_TO: 'vehicle_age_to',
				FUEL_NAME: 'fuel_seo',
				TACHOMETER_FROM: 'tachometer_from',
				TACHOMETER_TO: 'tachometer_to',
				VEHICLE_BODY_NAME: 'vehicle_body_seo',
				GEARBOX_NAME: 'gearbox_seo',
				FILTER_NAME: 'filter_name',
				PERFORMANCE_FROM: 'engine_power_from',
				PERFORMANCE_TO: 'engine_power_to',
				COUNTRY_OF_ORIGIN_NAME: 'country_of_origin_seo',
				VENDOR_NAME: 'vendor_seo',
				REGION_NAME: 'region_seo',
				DISTRICT_NAME: 'district_seo',
				REGIONS_DISTRICTS_VALUES: 'region_district_cb',
				DISPLACEMENT_FROM: 'engine_volume_from',
				DISPLACEMENT_TO: 'engine_volume_to',
				AIR_CONDITIONING_NAME: 'aircondition_seo',
				FUEL_CONSUMPTION_FROM: 'average_gas_mileage_from',
				FUEL_CONSUMPTION_TO: 'average_gas_mileage_to',
				CERTIFIED_PROGRAM_NAME: 'certified_program_seo',
				AIRBAGS_FROM: 'airbag_from',
				AIRBAGS_TO: 'airbag_to',
				COLOR_NAME: 'color_seo',
				CAPACITY_FROM: 'capacity_from',
				CAPACITY_TO: 'capacity_to',
				DOORS_FROM: 'door_from',
				DOORS_TO: 'door_to',
				BUS_SEATS_NAME: 'seats_seo',
				ITEM_AGE_NAME: 'item_age_seo',
				PRICE_OPTION_NAME: 'price_option_seo',
				HISTORY_INFO_NAME: 'history_info_seo',
				EQUIPMENT_NAME: 'equipment_seo',
				DRIVE_NAME: 'drive_seo',
				OTHER_SPECIFICATION_NAME: 'other_specification_seo',
				WEIGHT_FROM: 'weight_from',
				WEIGHT_TO: 'weight_to',
				BEDS_FROM: 'beds_from',
				BEDS_TO: 'beds_to',
				MACHINE_HOURS_FROM: 'machine_hours_from',
				MACHINE_HOURS_TO: 'machine_hours_to',
				PHRASE: 'phrase',
				OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM: 'operating_lease_price_without_vat_from',
				OPERATING_LEASE_PRICE_WITHOUT_VAT_TO: 'operating_lease_price_without_vat_to',
				OPERATING_LEASE_ANNUAL_DISTANCE_FROM: 'operating_lease_annual_distance_from',
				OPERATING_LEASE_ANNUAL_DISTANCE_TO: 'operating_lease_annual_distance_to',
				OPERATING_LEASE_PERIOD_FROM: 'operating_lease_period_from',
				OPERATING_LEASE_PERIOD_TO: 'operating_lease_period_to',
				OPERATING_LEASE_SERVICES_NAME: 'operating_lease_services_seo',
				OPERATING_LEASE_INTENDED_FOR_NAME: 'operating_lease_intended_for_seo',
				MY_SAVED_SEARCH_HASH: 'watchdog_hash',
				MY_SAVED_SEARCH_ID: 'watchdog_id',
				MY_SAVED_SEARCH_TIMESTAMP: 'watchdog_timestamp'
			}
		})
	}

	/**
	 * Prevede parametry z URL APP na parametry pro API
	 *
	 * @method getApiParams
	 * @param {Object} params - parametry APP k prevedeni
	 * @return {Object}
	 */
	static getApiParams(
		params = {},
		routeName = ROUTE_NAMES.USERWEB.ADVERT_LIST,
		includeApiPagingParams = true
	) {
		const appParams = Object.assign({}, params)
		const apiParams = {}

		const { URL_APP_PARAMS: URL_CONVERTOR_APP_PARAMS } = UrlConvertor.constants
		const { URL_APP_PARAMS, URL_API_PARAMS } = AdvertListUrlConvertor.constants

		// stránkování
		const apiPagingParams = UrlConvertor.getApiPaging(
			appParams[URL_CONVERTOR_APP_PARAMS.PAGE],
			appParams[URL_CONVERTOR_APP_PARAMS.PER_PAGE]
		)

		// modely, značky
		const brandsModelsParams = {}
		const brandSeoName = appParams[URL_APP_PARAMS.BRAND_NAME]
		const brandsModels = appParams[URL_APP_PARAMS.BRANDS_MODELS]

		if (brandsModels) {
			brandsModelsParams[URL_API_PARAMS.BRANDS_MODELS_VALUES] = brandsModels
		} else if (brandSeoName) {
			const modelSeoName = appParams[URL_APP_PARAMS.MODEL_NAME]
			let param = brandSeoName
			param += modelSeoName ? `:${modelSeoName}` : ''
			brandsModelsParams[URL_API_PARAMS.BRANDS_MODELS_SEO] = param
		}

		// parametry jednoduché transformace (transformace názvu parametru)
		const transformParamIds = [
			'CONDITION_NAME',
			'PRICE_FROM',
			'PRICE_TO',
			'OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM',
			'OPERATING_LEASE_PRICE_WITHOUT_VAT_TO',
			'OPERATING_LEASE_ANNUAL_DISTANCE_FROM',
			'OPERATING_LEASE_ANNUAL_DISTANCE_TO',
			'OPERATING_LEASE_PERIOD_FROM',
			'OPERATING_LEASE_PERIOD_TO',
			'OPERATING_LEASE_PERIOD_TO',
			'OPERATING_LEASE_PERIOD_TO',
			'OPERATING_LEASE_SERVICES_NAME',
			'OPERATING_LEASE_INTENDED_FOR_NAME',
			'AGE_FROM',
			'AGE_TO',
			'FUEL_NAME',
			'TACHOMETER_FROM',
			'TACHOMETER_TO',
			'VEHICLE_BODY_NAME',
			'GEARBOX_NAME',
			'COUNTRY_OF_ORIGIN_NAME',
			'VENDOR_NAME',
			'REGION_NAME',
			'DISTRICT_NAME',
			'DISPLACEMENT_FROM',
			'DISPLACEMENT_TO',
			'AIR_CONDITIONING_NAME',
			'FUEL_CONSUMPTION_FROM',
			'FUEL_CONSUMPTION_TO',
			'CERTIFIED_PROGRAM_NAME',
			'AIRBAGS_FROM',
			'AIRBAGS_TO',
			'COLOR_NAME',
			'CAPACITY_FROM',
			'CAPACITY_TO',
			'DOORS_FROM',
			'DOORS_TO',
			'BUS_SEATS_NAME',
			'ITEM_AGE_NAME',
			'PRICE_OPTION_NAME',
			'HISTORY_INFO_NAME',
			'EQUIPMENT_NAME',
			'DRIVE_NAME',
			'OTHER_SPECIFICATION_NAME',
			'WEIGHT_FROM',
			'WEIGHT_TO',
			'BEDS_FROM',
			'BEDS_TO',
			'MACHINE_HOURS_FROM',
			'MACHINE_HOURS_TO',
			'PHRASE'
		]
		const transformedParams = transformParamIds
			.map((id) => ({ [URL_API_PARAMS[id]]: params[URL_APP_PARAMS[id]] }))
			.filter((param) => typeof Object.values(param)[0] !== 'undefined')
			.reduce((accumulator, param) => Object.assign(accumulator, param), {})

		const isSameAsDefault = AdvertListUrlConvertor.validateDefaultConditionParams(
			transformedParams[URL_API_PARAMS.CONDITION_NAME]
		)

		if (!transformedParams[URL_API_PARAMS.CONDITION_NAME] || isSameAsDefault) {
			// defaultne hodnoty pre filter condition
			const conditionName = AdvertListUrlConvertor.constants.URL_API_PARAMS.CONDITION_NAME
			const defaultConditionValue = {
				[conditionName]:
					routeName === ROUTE_NAMES.USERWEB.SELLER ||
					routeName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
						? FilterConstants.allConditionApiParam
						: FilterConstants.defaultConditionApiParams
			}

			Object.assign(transformedParams, defaultConditionValue)
		} else if (
			transformedParams[URL_API_PARAMS.CONDITION_NAME] === FilterConstants.allConditionApiParam
		) {
			delete transformedParams[URL_API_PARAMS.CONDITION_NAME]
		}

		// výkon motoru - hodnota obsahuje jednotu, té se potřebujeme zbavit
		const performanceParams = {}
		const performanceFrom = appParams[URL_APP_PARAMS.PERFORMANCE_FROM]
		if (performanceFrom) {
			performanceParams[URL_API_PARAMS.PERFORMANCE_FROM] = parseInt(performanceFrom, 10)
		}
		const performanceTo = appParams[URL_APP_PARAMS.PERFORMANCE_TO]
		if (performanceTo) {
			performanceParams[URL_API_PARAMS.PERFORMANCE_TO] = parseInt(performanceTo, 10)
		}

		// ulozena hledani
		const mySavedSearchParams = {}
		const mySavedSearchId = appParams[URL_APP_PARAMS.MY_SAVED_SEARCH_ID]
		const mySavedSearchHash = appParams[URL_APP_PARAMS.MY_SAVED_SEARCH_HASH]
		const mySavedSearchTimestamp = appParams[URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP]
		if (mySavedSearchId && mySavedSearchHash && mySavedSearchTimestamp) {
			mySavedSearchParams[URL_API_PARAMS.MY_SAVED_SEARCH_ID] = mySavedSearchId
			mySavedSearchParams[URL_API_PARAMS.MY_SAVED_SEARCH_HASH] = mySavedSearchHash
			mySavedSearchParams[URL_API_PARAMS.MY_SAVED_SEARCH_TIMESTAMP] = mySavedSearchTimestamp
		}

		return Object.assign(
			{},
			apiParams,
			includeApiPagingParams ? apiPagingParams : undefined,
			brandsModelsParams,
			transformedParams,
			performanceParams,
			mySavedSearchParams
		)
	}

	/**
	 * Upraví parametry pro stránku strankovaní
	 *
	 * @method getPagingPageParams
	 * @param {number} page - číslo stránky pro kterou potřebuji sestavit URL
	 * @param {Object} params - parametry routy pro sestaveni URL
	 * @return {Object}
	 */
	static getPagingPageParams(page, params = {}) {
		return UrlConvertor.getPagingPageParams(page, params)
	}

	/**
	 * Zisti ci vybrane hodnoty su rovnake ako defaultne
	 *
	 * @method validateDefaultConditionParams
	 * @param {String/Array} conditionParams vybrane hodnoty condition filtru
	 * @return {Boolean}
	 */
	static validateDefaultConditionParams(conditionParams) {
		if (conditionParams) {
			let conditionParamsArray
			const defaultParams = FilterConstants.defaultConditionApiParams.split(',')
			if (typeof conditionParams === 'string') {
				conditionParamsArray = conditionParams.split(',')
			} else {
				conditionParamsArray = conditionParams
			}

			if (defaultParams.length !== conditionParamsArray.length) {
				return false
			} else {
				const arr1 = defaultParams.concat().sort()
				const arr2 = conditionParamsArray.concat().sort()

				for (let i = 0; i < arr1.length; i++) {
					if (arr1[i] !== arr2[i]) {
						return false
					}
				}

				return true
			}
		} else {
			return false
		}
	}
}
