
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['FiltersView'] = {
  close: function(d) { return "zavřít"; },
  "showAllFilters--osobni": function(d) { return "Vybrat osobní vozidlo"; },
  "showAllFilters--uzitkova": function(d) { return "Vybrat užitkové vozidlo"; },
  "showAllFilters--nakladni": function(d) { return "Vybrat nákladní vozidlo"; },
  "showAllFilters--motorky": function(d) { return "Vybrat motorku"; },
  "showAllFilters--ctyrkolky": function(d) { return "Vybrat čtyřkolku"; },
  "showAllFilters--privesy-navesy": function(d) { return "Vybrat přívěs"; },
  "showAllFilters--obytne": function(d) { return "Vybrat obytné vozidlo"; },
  "showAllFilters--pracovni-stroje": function(d) { return "Vybrat pracovní stroj"; },
  "showAllFilters--autobusy": function(d) { return "Vybrat autobus"; },
  filter: function(d) { return "Filtry"; },
  forDisabledPeople: function(d) { return "Pro zdravotně postižené"; },
  vendorType: function(d) { return "Typ prodejce"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	