import React from 'react'
import BaseWidget from '../base/BaseWidget'
import LocalitySelectInput from 'app/component/localitySelectInput/LocalitySelectInput'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import Locality from 'app/component/locality/LocalityView'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

import './LocalityWidget.less'

export const LOCALITY_WIDGET_ID = 'locality_widget'

const CLASSNAME = 'c-cp-locality-widget'

function LocalityWidget({ formLineEntity, onChange, section }) {
	const {
		extra: { getValidationMessage },
		value,
		required
	} = formLineEntity

	return (
		<BaseWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={LOCALITY_WIDGET_ID}
			renderWidget={(
				formLineEntity,
				renderLabel,
				renderInput,
				renderMessage,
				renderErrorMessage
			) => (
				<>
					{renderLabel()}
					<div className={`${CLASSNAME}__content`}>
						<div className={`${CLASSNAME}__input-wrapper`}>
							{renderInput()}
							{renderMessage()}
							{renderErrorMessage()}
						</div>
						<Locality
							className={`${CLASSNAME}__locality`}
							locality={{
								...formLineEntity.value,
								gpsLon: 0,
								gpsLat: 0
							}}
							mapProps={{
								mapWidth: 992,
								mapHeight: 208
							}}
							showText={false}
						/>
					</div>
				</>
			)}
			renderLabel={(formLineEntity, props) => {
				const { label } = formLineEntity
				const { labelId, inputId } = AutoComplete.getIdsPropsHelper(formLineEntity.id)

				return (
					<label
						{...props}
						id={labelId}
						htmlFor={inputId}
						className={`${CLASSNAME}__accessible-label`}
					>
						{label}
					</label>
				)
			}}
			renderInput={(formLineEntity, { className, ...props }) => {
				return (
					<LocalitySelectInput
						className={`${CLASSNAME}__locality-input`}
						formLineEntity={formLineEntity}
						widgetProps={props}
						onChange={(data) => {
							onChange({
								id: formLineEntity.id,
								...data,
								section
							})
						}}
						onBlur={(inputValue = '') => {
							if (getValidationMessage) {
								const errorMessage = getValidationMessage(inputValue, value, required) || ''
								onChange({
									id: formLineEntity.id,
									errorMessage,
									section
								})
							}
						}}
						inputSurfaceProps={{
							size: 'small',
							className: `${CLASSNAME}__input-surface`
						}}
					/>
				)
			}}
		/>
	)
}

LocalityWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func,
	section: PropTypes.string
}

export default LocalityWidget
