import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'

const FEEDBACK_TYPE = deepFreeze({
	EXCELLENT: 'excellent',
	GOOD: 'good',
	NEUTRAL: 'neutral',
	BAD: 'bad',
	TERRIBLE: 'terrible'
})

const FEEDBACK_SUBJECT = deepFreeze({
	ADVERT_INFO: 'advert_info',
	ADVERT_SEARCH_AND_FILTERING: 'advert_search_and_filtering',
	IRRELEVANT_SEARCH_RESULTS: 'irrelevant_search_results',
	WEB_CLARITY: 'web_clarity',
	TECHNICAL_ISSUES: 'technical_issues',
	OTHER: 'other'
})

export { FEEDBACK_TYPE, FEEDBACK_SUBJECT }
