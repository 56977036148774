import React, { useState, useEffect } from 'react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AutoComplete from 'app/component/autoComplete/AutoComplete'
import BrandAutoComplete from 'app/component/autoComplete/brand/BrandAutoComplete'
import Label from '../../label/Label'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'

const BrandAutoCompleteWidget = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		disabled = false,
		categoryData,
		changeFormLineEntities = DEFAULT_PROPS.FUNCTION
	} = props

	const {
		id,
		value: { name: brandName = '', value: selectedBrandValue } = {},
		label,
		placeholder,
		options = DEFAULT_PROPS.ARRAY
	} = formLineEntity

	const [searchedBrandName, setSearchedBrandName] = useState(brandName)

	useEffect(() => {
		const { value: { name: brandName = '' } = {} } = formLineEntity

		setSearchedBrandName(brandName)
	}, [formLineEntity])

	const handleSelect = (selectedItem) => {
		const selectedOption =
			options.find(({ value }) => selectedItem && value === selectedItem.value) || {}

		if (selectedBrandValue !== selectedOption.value) {
			changeFormLineEntities([
				{
					id,
					value: selectedOption.value ? selectedOption : undefined
				}
			])
		}
	}

	const handleSelectedItemDelete = () => {
		changeFormLineEntities([
			{
				id,
				value: undefined
			}
		])
	}

	const linkedSelectAndLabel = AutoComplete.getIdsPropsHelper(id)

	return (
		<div>
			<Label id={linkedSelectAndLabel.labelId} htmlFor={linkedSelectAndLabel.inputId}>
				{label}
			</Label>

			<div data-dot='select-manufacture'>
				<BrandAutoComplete
					{...linkedSelectAndLabel}
					inputValue={searchedBrandName}
					onInputValueChange={setSearchedBrandName}
					allItems={options}
					placeholder={placeholder}
					disabled={disabled}
					size='small'
					categoryData={categoryData}
					onSelect={handleSelect}
					onSelectedItemDelete={handleSelectedItemDelete}
				/>
			</div>
		</div>
	)
}

BrandAutoCompleteWidget.propTypes = {
	changeFormLineEntities: PropTypes.func,
	formLineEntity: PropTypes.instanceOf(FormLines.Entity),
	disabled: PropTypes.bool,
	categoryData: PropTypes.object
}

export default React.memo(BrandAutoCompleteWidget)
