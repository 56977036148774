import React from 'react'
import AbstractComponent from 'app/base/AbstractComponent'
import PropTypes from 'prop-types'
import { Icon } from '@sznds/react'
import { CHEVRON_DOWN_OUTLINE_24, DOT_OUTLINE_8 } from '@sznds/icons'
import { Popup, Responsive, Dropdown } from '@inzeraty/components'
import SortConstants from 'app/component/sortButton/SortConstants'
import { RESPONSIVE } from 'app/base/Constants'
import SortingTooltip from './sortingTooltip/SortingTooltip'

import './SortButtonView.less'
import './SortButtonCS.json'

const CLASSNAME = 'c-sort-btn'

export default class SortButton extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			advertListSortParam: PropTypes.string,
			sortConstants: PropTypes.shape({
				defaultValue: PropTypes.string.isRequired,
				sortValues: PropTypes.object.isRequired
			}),
			showTooltip: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			className: '',
			sortConstants: SortConstants,
			showTooltip: false
		}
	}

	constructor(props) {
		super(props)

		this.state = {
			isPopupOpen: false
		}

		this._openPopup = this._openPopup.bind(this)
		this._closePopup = this._closePopup.bind(this)

		this._renderPopup = this._renderPopup.bind(this)
		this._renderDropdown = this._renderDropdown.bind(this)
	}

	render() {
		return (
			<div className={`${CLASSNAME} ${this.props.className}`}>
				{this.props.showTooltip && <SortingTooltip className={`${CLASSNAME}__sorting-tooltip`} />}
				<span className={`${CLASSNAME}__prefix`}>
					{this.localize('SortButton.sortPrefix')}&nbsp;
				</span>
				{this._renderButton()}
				{this.state.isPopupOpen && (
					<Responsive
						breakpoint={RESPONSIVE.TABLET}
						renderMobileElement={this._renderPopup}
						renderDesktopElement={this._renderDropdown}
					/>
				)}
			</div>
		)
	}

	/**
	 * Vyrenderuje tlacitko razeni inzeratu
	 *
	 * @method _renderButton
	 * @private
	 * @return {HTMLElement}
	 */
	_renderButton() {
		const { sortConstants } = this.props
		const currentSortValue = this._getAdvertListSortKey()
		const sortText = this.localize(`SortButton.${sortConstants.sortValues[currentSortValue].NAME}`)

		return (
			<button
				className={`${CLASSNAME}__button`}
				onClick={this._openPopup}
				aria-label={this.localize('SortButton.sortedBy') + sortText.toLowerCase()}
				aria-expanded={this.state.isPopupOpen}
				data-dot='show-sorting'
				data-dot-data={`{"value": "${sortText}"}`}
			>
				<span className={`${CLASSNAME}__text`}>{sortText}</span>
				<Icon symbol={CHEVRON_DOWN_OUTLINE_24} className={`${CLASSNAME}__icon`} />
			</button>
		)
	}

	/**
	 * Vyrenderuje popup
	 *
	 * @method _renderPopup
	 * @private
	 * @return {HTMLElement}
	 */
	_renderPopup() {
		const currentSortValue = this._getAdvertListSortKey()

		const dataDotProps = {
			form: 'sorting',
			close: 'close'
		}

		return (
			<Popup
				className={`${CLASSNAME}__popup`}
				isOpen={true}
				onClose={this._closePopup}
				dictionary={{
					close: this.localize('SortButton.close')
				}}
				title={this.localize('SortButton.sort')}
				isFullscreen={false}
				hasLightbox={true}
				dataDot={dataDotProps}
			>
				{this._renderPopupItems(currentSortValue)}
			</Popup>
		)
	}

	/**
	 * Vyrenderuje dropdown
	 *
	 * @method _renderDropdown
	 * @private
	 * @return {HTMLElement}
	 */
	_renderDropdown() {
		const currentSortValue = this._getAdvertListSortKey()

		return (
			<Dropdown className={`${CLASSNAME}__dropdown`} onClose={this._closePopup}>
				{this._renderPopupItems(currentSortValue)}
			</Dropdown>
		)
	}

	/**
	 * Vyrenderuje obsah popupu
	 *
	 * @method _renderPopupItems
	 * @param {String} currentSortValue
	 * @private
	 * @return {HTMLElement}
	 */
	_renderPopupItems(currentSortValue) {
		const { sortConstants } = this.props
		return (
			<ul className={`${CLASSNAME}__list`}>
				{Object.keys(sortConstants.sortValues).map((key) => {
					const sortText = this.localize(`SortButton.${sortConstants.sortValues[key].NAME}`)

					return (
						<li
							key={key}
							className={this.cssClasses({
								[`${CLASSNAME}__item`]: true,
								[`${CLASSNAME}__item--current`]: key === currentSortValue
							})}
						>
							<button
								className={this.cssClasses({
									[`${CLASSNAME}__link`]: true,
									[`${CLASSNAME}__link--current`]: key === currentSortValue
								})}
								aria-label={this.localize('SortButton.sort') + sortText.toLowerCase()}
								onClick={() => {
									if (key !== currentSortValue) {
										this.fire('sortLinkClick', key)
									}

									this._closePopup()
								}}
								data-dot='choose-sorting'
								data-dot-data={`{"value": "${sortText}"}`}
							>
								{key === currentSortValue && this._renderDotIcon()}
								<span>{sortText}</span>
							</button>
						</li>
					)
				})}
			</ul>
		)
	}

	/**
	 * Vyrenderuje ikonku aktivni polozky
	 *
	 * @method _renderDotIcon
	 * @private
	 * @return {HTMLElement}
	 */
	_renderDotIcon() {
		return (
			<div className={`${CLASSNAME}__icon-dot-wrapper`}>
				<Icon symbol={DOT_OUTLINE_8} className={`${CLASSNAME}__icon-dot`} />
			</div>
		)
	}

	/**
	 * Vrati aktualni klic razeni inzeratu
	 *
	 * @method _getAdvertListSortKey
	 * @private
	 * @return {String}
	 */
	_getAdvertListSortKey() {
		const { advertListSortParam, sortConstants } = this.props

		const sortKey = Object.keys(sortConstants.sortValues).find(
			(sortConstantKey) => sortConstants.sortValues[sortConstantKey].APP === advertListSortParam
		)

		return advertListSortParam && sortKey ? sortKey : sortConstants.defaultValue
	}

	/**
	 * Zmeni stav a otevre popup
	 *
	 * @method _openPopup
	 * @private
	 */
	_openPopup() {
		this.setState({
			isPopupOpen: true
		})
	}

	/**
	 * Zmeni stav a zavre popup
	 *
	 * @method _closePopup
	 * @private
	 */
	_closePopup() {
		this.setState({
			isPopupOpen: false
		})
	}
}
