
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['ClientAdminAdvertList'] = {
  emptyList: function(d) { return "Nemáte žádné inzeráty"; },
  emptyListInactive: function(d) { return "Nemáte žádné neaktivní inzeráty"; },
  emptyListBanned: function(d) { return "Nemáte žádné zablokované inzeráty"; },
  emptyListDescription: function(d) { return "Pro vytvoření inzerátu klikněte na vytvořit nový inzerát"; },
  emptyListNothingFound: function(d) { return "Nenašli jsme žádné inzeráty"; },
  emptyListNothingFoundDescription: function(d) { return "Zkuste změnit nastavení filtrů a nebo zadat jiný dotaz pro vaše hledání"; },
  emptyListNothingFoundInactive: function(d) { return "Nenašli jsme žádné neaktivní inzeráty"; },
  emptyListNothingFoundBanned: function(d) { return "Nenašli jsme žádné zablokované inzeráty"; },
  backToAllAdverts: function(d) { return "Zpět na výpis inzerátů"; },
  chosen: function(d) { return plural(d.COUNT, 0, cs, { "0": "", one: "Vybraný ", other: "Vybrané " }) + " "; },
  adverts: function(d) { return plural(d.COUNT, 0, cs, { one: d.COUNT + " inzerát", few: d.COUNT + " inzeráty", other: d.COUNT + " inzerátů" }); },
  clear: function(d) { return "Zrušit výběr"; },
  actions: function(d) { return "Akce"; },
  advertActions: function(d) { return "Akce selekce"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	