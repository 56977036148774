import { UserService as InzeratyUserService } from '@inzeraty/models'

const SERVICE = 'sauto'

export default class UserService extends InzeratyUserService {
	/**
	 * Vrátí data uživatele pro službu sauto.cz.
	 * @static
	 * @method getSautoServiceData
	 * @param {UserEntity} userEntity
	 * @returns {Object} Data uživatele pro službu sauto.cz
	 */
	static getSautoServiceData(userEntity = {}) {
		const { userServices = [] } = userEntity

		return userServices.find(({ serviceName }) => serviceName === SERVICE) || {}
	}

	/**
	 * Získá userEntitu přihlášeného uživatele.
	 *
	 * @public
	 * @method getUser
	 * @param {Boolean} downloadOrigin Donutí stažení entity z api.
	 * @return {Promise<ns.app.model.user.UserEntity>} Promise jehož hodnotou je entita přihlášeného uživatele.
	 */
	getUser(downloadOrigin = false) {
		return super.getUserSelf(downloadOrigin)
	}

	/**
	 * Zmeni inzerenta - RUSe.
	 *
	 * @method saveUser
	 * @param {String} userId id usera
	 * @param {Object} params hodnoty ke zmene u usera
	 * @return {Promise}
	 **/
	saveUser(userId, params = {}) {
		const updatedParams = Object.assign({}, params, {
			service_name: SERVICE
		})
		return super.saveUser(userId, updatedParams)
	}
}
