import AbstractComponent from 'app/base/AbstractComponent'
import React from 'react'
import LayoutAdmin from 'app/component/layout/admin/LayoutAdmin'
import AddVINForm from './components/AddVINForm'
import STATE_KEYS from './AddVINStateKeys'
import CebiaPopup from './components/cebiaPopup/CebiaPopup'
import PromoInsert from 'app/component/promoInsert/PromoInsert'
import UserwebBaseController from 'app/base/UserwebBaseController'
import ClientBaseController from 'app/base/ClientBaseController'

import './AddVINView.less'
import './AddVINViewCS.json'

const CLASSNAME = 'p-uw-add-vin'

/**
 * Stranka pro vlozeni noveho inzeratu (prvni krok s vlozenim VINu).
 * @class AddVINView
 * @namespace app.page.userweb.newAdvert
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.page
 */
export default class AddVINView extends AbstractComponent {
	render() {
		return (
			<LayoutAdmin
				className={CLASSNAME}
				notLoggedTitle={this.localize('AddVINView.notLoggedTitle')}
				isNewAdvert={true}
			>
				{this._renderContent()}
			</LayoutAdmin>
		)
	}

	_renderContent() {
		const {
			[STATE_KEYS.SUBMIT_VIN_FORM]: submitVINForm,
			[STATE_KEYS.CATEGORIES]: categories,
			[STATE_KEYS.CONDITIONS]: conditions,
			[STATE_KEYS.CEBIA_POPUP_OPEN]: cebiaPopupOpen,
			[ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise,
			[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelf
		} = this.props

		return (
			<>
				<div className={`${CLASSNAME}__content`}>
					<div className={`${CLASSNAME}__heading-section`}>
						<div className={`${CLASSNAME}__picture`} />

						<div>
							<h1 className={`${CLASSNAME}__title`}>{this.localize('AddVINView.title')}</h1>

							<div className={`${CLASSNAME}__sub-title-section`}>
								<p className={`${CLASSNAME}__about-us`}>
									{this.localize('AddVINView.subTitleAboutUs')}
								</p>
								<p>{this.localize('AddVINView.subTitleRecommendation')}</p>
							</div>
						</div>
					</div>

					<AddVINForm
						submitVINForm={submitVINForm}
						categories={categories}
						conditions={conditions}
					/>
					{cebiaPopupOpen && <CebiaPopup />}
				</div>

				<PromoInsert
					className={`${CLASSNAME}__promo-insert`}
					userSelf={userSelf}
					isPremise={isPremise}
				/>
			</>
		)
	}
}
