import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import AbstractNewAdvertBaseController from '../AbstractNewAdvertBaseController'
import * as FormLines from '@inzeraty/form-lines'
import AdvertAdminUrlConvertor from 'app/page/userweb/newAdvert/AdvertAdminUrlConvertor'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import STATE_KEYS from './HistoryStateKeys'
import ABSTRACT_NEW_ADVERT_STATE_KEYS from '../AbstractNewAdvertStateKeys'
import { FORM_LINES_IDS } from './component/historyForm/HistoryFormLineIds'
import { createFormLineEntities } from './component/historyForm/HistoryFormLines'
import { HISTORY_SECTION } from './component/historyForm/HistorySections'

export default class HistoryController extends AbstractNewAdvertBaseController {
	constructor(dependenciesHelper) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._router = this._utils.$Router

		this.submitSaveCertForm = this.submitSaveCertForm.bind(this)
		this._processServerErrors = this._processServerErrors.bind(this)

		this._changeFormLineEntities = this._changeFormLineEntities.bind(this)
		this._submitForm = this._submitForm.bind(this)

		this._setFormStatus = this._setFormStatus.bind(this)

		this._scrollPageToTop = this._scrollPageToTop.bind(this)
	}

	init() {
		super.init()
	}

	load() {
		const superState = super.load()

		const { [ABSTRACT_NEW_ADVERT_STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise } = superState

		const cebiaVerified = advertEntityPromise.then(
			(advertEntity = {}) => !!advertEntity.cebiaReport
		)

		// volani na create page, ktera nam vraci mozne hodnoty pro jednotlive prvky formulare
		const createPagePromise = advertEntityPromise.then((advertEntity) => {
			if (!advertEntity) {
				return
			}

			const { category: { id: categoryId } = {} } = advertEntity
			const { URL_API_PARAMS } = AdvertAdminUrlConvertor.constants

			const params = {
				[URL_API_PARAMS.CATEGORY_ID]: categoryId
			}

			return this._advertService.getCreatePage(params, { cache: true })
		})

		// sestaveni prvku na formulari
		const formLineEntitiesPromise = Promise.all([advertEntityPromise, createPagePromise]).then(
			([advertEntity, createPage]) => {
				if (!advertEntity) {
					return
				}

				const elements = createPage.sections.reduce((allElements, section) => {
					const { elements } = section
					return [...allElements, ...elements]
				}, [])

				const formLineEntities = createFormLineEntities(advertEntity, this._dictionary)
					// - vyfiltruji si formLineEntity, pro danou sekci
					.filter(({ id }) => HISTORY_SECTION.includes(id))
					// - vyfiltruji si formLineEntity ktere jsou v creagePage
					.filter(
						(formLineEntity) => !!elements.find((element) => element.name === formLineEntity.id)
					)
					// - nastavim formLineEntitam options
					.map((formLineEntity) => {
						const element = elements.find((element) => element.name === formLineEntity.id)
						const { options = [] } = formLineEntity
						return FormLines.updateEntity(formLineEntity, {
							options: options.length ? options : element.options
						})
					})

				return this._setDisabledEnvironmentalTaxPaidByEuroLevel(formLineEntities)
			}
		)

		return Object.assign({}, superState, {
			[STATE_KEYS.SUBMIT_SAVE_CERT_FORM]: this.submitSaveCertForm,
			[STATE_KEYS.CEBIA_REPORT_VERIFIED]: cebiaVerified,

			[STATE_KEYS.FORM_LINES]: formLineEntitiesPromise,
			[STATE_KEYS.ON_CHANGE]: this._changeFormLineEntities,
			[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false,
			[STATE_KEYS.SUBMIT_FORM]: this._submitForm,
			[STATE_KEYS.SET_FORM_STATUS]: this._setFormStatus,
			[STATE_KEYS.SCROLL_PAGE_TO_TOP]: this._scrollPageToTop
		})
	}

	async submitSaveCertForm({ formData, onErrorCallback, onSuccessCallback }) {
		const advertId = this._getAdvertId()

		try {
			await this._advertService.saveCebiaCert(Number(advertId), formData)

			this.setState({
				[STATE_KEYS.CEBIA_REPORT_VERIFIED]: true
			})

			onSuccessCallback()
		} catch (error) {
			const errors = this._processServerErrors(error)
			onErrorCallback(errors)
		}
	}

	_processServerErrors(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)
		let errors = []

		if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(error)
			errors = body.errors
		}

		return errors
	}

	_setDisabledEnvironmentalTaxPaidByEuroLevel(formLineEntities) {
		const euroLevelFormLineEntity = formLineEntities.find(
			(formLineEntity) => formLineEntity.id === FORM_LINES_IDS.EURO_LEVEL_CB
		)
		const envTaxFormLineEntity = formLineEntities.find(
			(formLineEntity) => formLineEntity.id === FORM_LINES_IDS.ENVIRONMENTAL_TAX_PAID
		)

		const changes = {}

		if (euroLevelFormLineEntity && envTaxFormLineEntity) {
			const { value: euroLevel = {} } = euroLevelFormLineEntity

			changes.id = FORM_LINES_IDS.ENVIRONMENTAL_TAX_PAID

			changes.extra = Object.assign({}, envTaxFormLineEntity.extra, {
				disabled: euroLevel.value > 2
			})

			if (changes.extra.disabled) {
				changes.value = ''
			}
		}

		return FormLines.updateEntities(formLineEntities, [changes])
	}

	_changeFormLineEntities(changes) {
		const { [STATE_KEYS.FORM_LINES]: formLinesEntities = {} } = this.getState()

		const updatedFormLineEntities = FormLines.updateEntities(formLinesEntities, changes)

		this.setState({
			[STATE_KEYS.FORM_LINES]: this._setDisabledEnvironmentalTaxPaidByEuroLevel(
				updatedFormLineEntities
			)
		})
	}

	async _submitForm({ formLineEntities, onErrorCallback }) {
		const defaultFormData = {}

		const formData = formLineEntities.reduce((allFormData, f) => {
			const { value, extra: { getFormData } = {} } = f

			if (getFormData) {
				return Object.assign({}, allFormData, getFormData(value))
			} else {
				/* eslint-disable */
				console.warn(`Field "${f.id}" is missing "getFormData" function.`)
				/* eslint-enable */

				return allFormData
			}
		}, defaultFormData)

		const formDataProcessed = Object.keys(formData).reduce(
			(formDataProcessed, formKey) =>
				Object.assign({}, formDataProcessed, {
					[formKey]: formData[formKey] === undefined ? null : formData[formKey]
				}),
			{}
		)

		try {
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: true
			})

			const advertId = this._getAdvertId()

			await this._advertService.patchAdvert(advertId, formDataProcessed)

			this._onSubmitFormSuccessCallback()
		} catch (error) {
			const httpStatus = HttpErrorHelper.getHttpStatus(error)
			let errors = []

			if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
				const { body = {} } = HttpErrorHelper.getParams(error)
				errors = body.errors
			}

			this._onSubmitFormErrorCallback()

			onErrorCallback(errors)
		} finally {
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false
			})
		}
	}

	_setFormStatus({ statusCode, extra }) {
		this.setState({
			[STATE_KEYS.FORM_STATUS]: { statusCode, extra }
		})
	}

	_scrollPageToTop() {
		animateScrollTo(0)
	}
}
