import { AbstractFactory } from '@inzeraty/models'
import ArticlesEntity from './ArticlesEntity'
export default class ArticlesFactory extends AbstractFactory {
	/**
	 * @constructor
	 */
	constructor() {
		super(ArticlesEntity)
	}
	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */

	transformData(data = {}) {
		return {
			categoryId: data.category_id,
			articlesCount: data.article_count,
			manufacturerCb: data.manufacturer_cb,
			modelCb: data.model_cb
		}
	}
}
