import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import operatingLeasePropTypes from 'app/model/operatingLease/OperatingLeasePropTypes'
import { useLocalize } from 'app/base/componentHelpers'
import Select from 'app/component/select/Select'
import PeriodItem from './models/PeriodItem'
import AnnualDistanceItem from './models/AnnualDistanceItem'

import './OperatingLeaseDetailMainChoice.less'
import './OperatingLeaseDetailMainChoiceCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-operating-lease-detail-main-choice'

const DISTANCE_SELECT_ID = 'oper-lease-distance-select'
const PERIOD_SELECT_ID = 'oper-lease-period-select'

const OperatingLeaseDetailMainChoice = ({
	isLoading = false,
	variants = DEFAULT_PROPS.ARRAY,
	selectedAnnualDistance,
	selectedPeriod,
	onChange = DEFAULT_PROPS.FUNCTION
}) => {
	const localize = useLocalize()

	const allSortedAnnualDistances = useMemo(
		() =>
			[...new Set(variants.map((variant) => variant.annualDistance))].sort(
				(annualDistanceA, annualDistanceB) => annualDistanceA - annualDistanceB
			),
		[variants]
	)

	const allSortedPeriods = useMemo(
		() =>
			[...new Set(variants.map((variant) => variant.period))].sort(
				(periodA, periodB) => periodA - periodB
			),
		[variants]
	)

	const getSortedPeriodsForAnnualDistance = (annualDistance) =>
		allSortedPeriods.filter((period) =>
			variants.find(
				(variant) => variant.period === period && variant.annualDistance === annualDistance
			)
		)

	const onAnnualDistanceChange = (item) => {
		const newAnnualDistance = item?.id

		if (newAnnualDistance !== selectedAnnualDistance) {
			const [firstValidPeriod] = getSortedPeriodsForAnnualDistance(newAnnualDistance)
			onChange(newAnnualDistance, firstValidPeriod)
		}
	}

	const onPeriodChange = (item) => {
		const newPeriod = item?.id

		if (newPeriod !== selectedPeriod) {
			onChange(selectedAnnualDistance, newPeriod)
		}
	}

	const annualDistanceItems = allSortedAnnualDistances.map(
		(distance) => new AnnualDistanceItem(distance, localize)
	)

	const periodItems = getSortedPeriodsForAnnualDistance(selectedAnnualDistance).map(
		(item) => new PeriodItem(item, localize)
	)

	return (
		<div className={`${CLASSNAME}__type-selection`}>
			<div className={`${CLASSNAME}__range-item`}>
				{isLoading ? (
					<div className={`${CLASSNAME}__placeholder`} />
				) : (
					<>
						<label htmlFor={DISTANCE_SELECT_ID} className={`${CLASSNAME}__range-label`}>
							{localize('OperatingLeaseDetailMainChoice.annualDistance')}
						</label>
						<Select
							inputId={DISTANCE_SELECT_ID}
							items={annualDistanceItems}
							className={`${CLASSNAME}__select`}
							onSelect={onAnnualDistanceChange}
							selectedItem={annualDistanceItems.find((item) => item.id === selectedAnnualDistance)}
							size='small'
						/>
					</>
				)}
			</div>
			<div className={`${CLASSNAME}__range-item`}>
				{isLoading ? (
					<div className={`${CLASSNAME}__placeholder`} />
				) : (
					<>
						<label htmlFor={PERIOD_SELECT_ID} className={`${CLASSNAME}__range-label`}>
							{localize('OperatingLeaseDetailMainChoice.period')}
						</label>
						<Select
							inputId={PERIOD_SELECT_ID}
							items={periodItems}
							className={`${CLASSNAME}__select`}
							onSelect={onPeriodChange}
							selectedItem={periodItems.find((item) => item.id === selectedPeriod)}
							size='small'
						/>
					</>
				)}
			</div>
		</div>
	)
}

OperatingLeaseDetailMainChoice.propTypes = {
	isLoading: PropTypes.bool,
	variants: PropTypes.arrayOf(PropTypes.shape(operatingLeasePropTypes)),
	selectedAnnualDistance: PropTypes.number,
	selectedPeriod: PropTypes.number,
	onChange: PropTypes.func
}

export default OperatingLeaseDetailMainChoice
