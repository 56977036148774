
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['AdvertListOperatingLeaseMeta'] = {
  title: function(d) { return "Operativní leasing " + d.FILTERS + ", auta na leasing a operák | Sauto.cz"; },
  titleNoFilters: function(d) { return "Operativní leasing, auta na leasing a operák | Sauto.cz"; },
  description: function(d) { return d.OPER_LEASE_COUNT_TEXT + " " + d.FILTERS + " na Sauto.cz ⭐ Přehled a srovnání operáků pro firmy, OSVČ i fyzické osoby, na 6, 12, 24, 36 i 48 měsíců ⭐ Vyberte si nejvýhodnější operativní leasing " + d.FILTERS; },
  descriptionNoFilters: function(d) { return d.OPER_LEASE_COUNT_TEXT + " na Sauto.cz ⭐ Přehled a srovnání operáků pro firmy, OSVČ i fyzické osoby, na 6, 12, 24, 36 i 48 měsíců ⭐ Vyberte si nejvýhodnější operativní leasing"; },
  operatingLease: function(d) { return plural(d.COUNT, 0, cs, { one: d.COUNT_FORMATTED + " operativní leasing", few: d.COUNT_FORMATTED + " operativní leasingy", other: d.COUNT_FORMATTED + " operativních leasingů" }); }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	