import UserwebBaseController from 'app/base/UserwebBaseController'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import GenericError from 'ima/error/GenericError'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import { getIdFromSeoName } from '../helper/IdFromSeoName'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import { CATEGORIES } from 'app/base/Constants'
import FilterConstants from 'app/model/filter/FilterConstants'
import ROUTE_NAMES from 'app/base/RouteNames'
import {
	getBrandModelSeoName,
	getMinMaxYears,
	isBrandAndModelExistForModification
} from '../helper/modificationsHelper'
import CatalogMeta from '../CatalogMeta'

export default class ModelLineModificationsCatalogController extends UserwebBaseController {
	constructor(dependenciesHelper, catalogService, advertService) {
		super(dependenciesHelper)

		this._catalogService = catalogService
		this._advertService = advertService
		this._router = this._utils.$Router
	}

	static get STATE_KEYS() {
		return {
			MODIFICATIONS_LIST: 'MODIFICATIONS_LIST',
			ADVERTS_COUNT: 'ADVERTS_COUNT',
			LINK_URL: 'LINK_URL',
			DATA_LOADED: 'DATA_LOADED',
			GENERATION_INFO: 'GENERATION_INFO'
		}
	}

	init() {
		super.init()
	}

	load() {
		const modificationsList = this._loadAllModificationsForModelLine()

		return super.load({
			[ModelLineModificationsCatalogController.STATE_KEYS
				.MODIFICATIONS_LIST]: modificationsList.then((data) => {
				const { modifications } = data
				return modifications
			}),
			[ModelLineModificationsCatalogController.STATE_KEYS.GENERATION_INFO]: modificationsList.then(
				(data) => {
					const { generation } = data
					return generation
				}
			),
			[ModelLineModificationsCatalogController.STATE_KEYS.DATA_LOADED]: modificationsList.then(
				() => true
			),
			[ModelLineModificationsCatalogController.STATE_KEYS.LINK_URL]: modificationsList.then(
				(data) => {
					const { modifications } = data
					return this._getLinkToList(modifications)
				}
			),
			[ModelLineModificationsCatalogController.STATE_KEYS.ADVERTS_COUNT]: modificationsList.then(
				(data) => {
					const { modifications } = data
					return this._getAdvertsCount(modifications)
				}
			)
		})
	}

	getDataForSsp() {
		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {}
		}
	}

	async _loadAllModificationsForModelLine() {
		const { URL_API_PARAMS, URL_APP_PARAMS } = CatalogUrlConvertor.constants

		const { [URL_APP_PARAMS.GENERATION_SEO_NAME]: seoName } = this.getRouteParams()

		const generationId = getIdFromSeoName(seoName)

		if (generationId) {
			const modificationsData = await this._catalogService.getModifications({
				[URL_API_PARAMS.GENERATION_ID]: generationId
			})

			return modificationsData
		} else {
			// neplatna URL, vracime 400.
			const error = new GenericError('Neplatna URL.', {
				status: HTTP_STATUS_CODES.BAD_REQUEST
			})
			return Promise.reject(error)
		}
	}

	/**
	 * Ziska pocet inzeratov pre parametry:
	 * Znacka, Model, Karoserie, Rok vyroby, Vykov
	 * @param {ARRAY} modifications
	 */
	async _getAdvertsCount(modifications = []) {
		if (modifications.length > 0) {
			const isBrandAndModelExists = await this._isBrandAndModelExists(modifications)

			if (isBrandAndModelExists) {
				const defaultAppParams = this._getAdvertListAppParams(modifications)
				const optionalAppParams = this._getOptionalAppParams(modifications)

				const filterAppParams = Object.assign({}, defaultAppParams, optionalAppParams)

				const apiParams = Object.assign(
					{},
					AdvertListUrlConvertor.getApiParams(filterAppParams, this.getRouteName()),
					{
						[UrlConvertor.constants.URL_API_PARAMS.LIMIT]: 0,
						[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]:
							CATEGORIES.PASSENGER_CARS.id
					}
				)

				return this._advertService.getAdvertsTotalCount(apiParams, {}, false)
			} else {
				return 0
			}
		} else {
			return 0
		}
	}

	async _getLinkToList(modifications = []) {
		if (modifications.length > 0) {
			const isBrandAndModelExists = await this._isBrandAndModelExists(modifications)

			if (isBrandAndModelExists) {
				const defaultAppParams = this._getAdvertListAppParams(modifications)
				const optionalAppParams = this._getOptionalAppParams(modifications)

				const urlParams = Object.assign({}, defaultAppParams, optionalAppParams)

				return this._router.link(ROUTE_NAMES.USERWEB.ADVERT_LIST, urlParams)
			} else {
				return undefined
			}
		} else {
			return undefined
		}
	}

	async _isBrandAndModelExists(modifications = []) {
		const { brandSeoName, modelSeoName } = this._getModificationsInfo(modifications)

		const isBrandAndModelExists = await isBrandAndModelExistForModification(
			this._catalogService,
			brandSeoName,
			modelSeoName
		)

		return isBrandAndModelExists
	}

	_getModificationsInfo(modifications = []) {
		const { generation = {} } = modifications[0] || {}
		const { model = {} } = generation
		const { manufacturer = {}, seoName: modelSeo } = model
		const { seoName: brandSeo } = manufacturer

		const { min, max } = getMinMaxYears(modifications)
		const brandSeoName = getBrandModelSeoName(brandSeo)
		const modelSeoName = getBrandModelSeoName(modelSeo)

		return {
			min,
			max,
			brandSeoName,
			modelSeoName
		}
	}

	_getVehicleBodyName(modifications = []) {
		const allVehicleBodies = modifications.filter((modification) => {
			const { data = {} } = modification
			const { vehicleBodySeo = '' } = data

			return !!vehicleBodySeo
		})

		const { data = {} } = allVehicleBodies[0] || {}
		const { vehicleBodySeo = '' } = data

		return vehicleBodySeo
	}

	_getOptionalAppParams(modifications = []) {
		const vehicleBodySeo = this._getVehicleBodyName(modifications)

		const optionalAppParams = {}

		if (vehicleBodySeo) {
			optionalAppParams[
				AdvertListUrlConvertor.constants.URL_APP_PARAMS.VEHICLE_BODY_NAME
			] = vehicleBodySeo
		}

		return optionalAppParams
	}

	_getAdvertListAppParams(modifications = []) {
		const { min, max, brandSeoName, modelSeoName } = this._getModificationsInfo(modifications)

		return {
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]:
				CATEGORIES.PASSENGER_CARS.seoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: brandSeoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]: modelSeoName,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CONDITION_NAME]:
				FilterConstants.allConditionApiParam,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.AGE_FROM]: min,
			[AdvertListUrlConvertor.constants.URL_APP_PARAMS.AGE_TO]: max
		}
	}

	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const {
			[ModelLineModificationsCatalogController.STATE_KEYS.GENERATION_INFO]: generationInfo = {},
			[ModelLineModificationsCatalogController.STATE_KEYS.MODIFICATIONS_LIST]: modifications = []
		} = loadedResources

		const { name: modificationName = '', model = {} } = generationInfo || {}
		const { manufacturer = {} } = model
		const { name: brandName } = manufacturer

		const info = {
			count: modifications.length,
			brandName,
			modificationName
		}

		const { title, description } = this._getMetas(info)

		const metaTitle = title + dictionary.get('Base.titleSuffix')
		metaManager.setTitle(metaTitle)
		metaManager.setMetaName('twitter:title', metaTitle)
		metaManager.setMetaProperty('og:title', metaTitle)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('twitter:description', description)
		metaManager.setMetaProperty('og:description', description)
	}

	_getMetas(info) {
		const { route } = this._utils.$Router.getCurrentRouteInfo()
		const routeName = route.getName()

		return CatalogMeta.getMetas(routeName, this._utils.$Dictionary, info)
	}
}
