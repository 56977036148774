import React, { memo, useContext, useReducer, useEffect, useRef, useCallback } from 'react'
import classnames from 'classnames'
import { useInView } from 'react-intersection-observer'
import { CHEVRON_RIGHT_OUTLINE_24, CHEVRON_LEFT_OUTLINE_24 } from '@sznds/icons'
import { Checkbox, Button } from '@sznds/react'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Select } from '@inzeraty/components'
import Context from 'ima/page/context'
import { INTERNAL_ADMIN } from 'app/base/Constants'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import EmptyList from 'app/component/emptyList/EmptyList'
import ActionsWrapper from './actionsWrapper/ActionsWrapper'
import GenericError from 'ima/error/GenericError'
import ROUTE_NAMES from 'app/base/RouteNames'

import './EntitiesTable.less'

const initialState = {
	count: undefined,
	selected: new Set([]),
	massAction: undefined,
	massActions: DEFAULT_PROPS.ARRAY
}
const reducer = ({ count, selected, massAction, massActions }, { type, payload }) => {
	switch (type) {
		case 'initEntitiesCount':
			return {
				count: payload,
				selected,
				massAction,
				massActions
			}

		case 'toggleSelected': {
			const id = payload

			selected.has(id) ? selected.delete(id) : selected.add(id)

			return {
				count,
				selected: new Set(selected),
				massAction,
				massActions
			}
		}

		case 'selectAll':
			return {
				count,
				selected: new Set(payload),
				massAction,
				massActions
			}

		case 'unselectAll':
			return {
				count,
				selected: new Set([]),
				massAction,
				massActions
			}

		case 'setMassAction':
			return {
				count,
				selected,
				massAction: payload,
				massActions
			}

		case 'setMassActions':
			return {
				count,
				selected,
				massAction,
				massActions: payload
			}

		default:
			throw new Error()
	}
}

export const CLASSNAME = 'c-etable'

const isValidCallback = (callback) => callback && typeof callback === 'function'

const processCellCallbackResult = (result) => (Array.isArray(result) ? result : [result])

const calcSum = (items = [], callback) =>
	items.reduce((acc, item) => {
		return acc + (isValidCallback(callback) ? callback(item) : 0)
	}, 0)

const EntitiesTable = memo(
	({
		entitiesAndPagination = DEFAULT_PROPS.OBJECT,
		getMassActions = () => DEFAULT_PROPS.ARRAY,
		headCells = DEFAULT_PROPS.ARRAY,
		rowCells = DEFAULT_PROPS.ARRAY,
		summaryCells = DEFAULT_PROPS.ARRAY,
		entityIdGetter = ({ id }) => id,
		actions = DEFAULT_PROPS.ARRAY,
		renderPagingSummary: renderPagingSummaryOverride,
		renderEmptyBody,
		renderBody: renderBodyOverride,
		renderRow: renderRowOverride,
		renderFoot: renderFootOverride,
		rowsWithStripes = false,
		isLoading,
		limitParam = InternalAdminUrlConvertor.constants.LIMIT,
		pageParam = InternalAdminUrlConvertor.constants.PAGE,
		isNoHorizontalScrollOnDesktopPreferred = true
	}) => {
		const { $Router } = useContext(Context)
		const [state, dispatch] = useReducer(reducer, initialState)

		const { ref: normalTableHeadInViewRef, inView: normalTableHeadInView } = useInView({
			// marginy jsou brany z pohledu viewportu (rootu)
			rootMargin: '-50px 0px 0px 0px'
		})
		const { ref: tableContainerInViewRef, inView: tableContainerInView } = useInView({
			// marginy jsou brany z pohledu viewportu (rootu)
			rootMargin: '-75px 0px -10px 0px'
		})

		const isStickyHeadVisible = tableContainerInView && !normalTableHeadInView

		const { entities = [], paginationEntity = {} } = entitiesAndPagination

		const normalTableHeadElm = useRef(null)
		const stickyTableHeadElm = useRef(null)
		const tableContainerElm = useRef(null)
		const tableElm = useRef(null)

		const isMassEnabled = Boolean(state.massActions.length)
		const isActions = Boolean(actions.length)

		const setNormalTableHeadRefs = useCallback(
			(node) => {
				normalTableHeadElm.current = node
				normalTableHeadInViewRef(node)
			},
			[normalTableHeadInViewRef]
		)

		const setTableContainerRefs = useCallback(
			(node) => {
				tableContainerElm.current = node
				tableContainerInViewRef(node)
			},
			[tableContainerInViewRef]
		)

		const recalcStickyHeader = useCallback(() => {
			const setStickyHeadWidth = () => {
				if (!tableContainerElm.current || !stickyTableHeadElm.current) {
					return
				}

				const { width } = tableContainerElm.current.getBoundingClientRect()
				stickyTableHeadElm.current.style.width = `${width}px`
			}

			const setStickyHeadCellWidths = () => {
				if (!normalTableHeadElm.current || !stickyTableHeadElm.current) {
					return
				}

				const normalTableHeadCellElms = normalTableHeadElm.current.querySelectorAll(
					`th.${CLASSNAME}__cell`
				)
				const stickyTableHeadCellElms = stickyTableHeadElm.current.querySelectorAll(
					`div.${CLASSNAME}__cell`
				)

				normalTableHeadCellElms.forEach((normalTableHeadCellElm, index) => {
					const stickyTableHeadCellElm = stickyTableHeadCellElms[index]

					if (stickyTableHeadCellElm) {
						const { width } = normalTableHeadCellElm.getBoundingClientRect()
						stickyTableHeadCellElm.style.width = `${width}px`
					}
				})
			}

			const setStickyHeadHorizontalScrollPosition = () => {
				if (!tableContainerElm.current || !stickyTableHeadElm.current) {
					return
				}

				stickyTableHeadElm.current.scrollLeft = tableContainerElm.current.scrollLeft
			}

			try {
				setStickyHeadWidth()
				setStickyHeadCellWidths()
				setStickyHeadHorizontalScrollPosition()
			} catch (e) {
				console.error(e)
			}
		}, [])

		const recalcStickyHeaderIfVisibleOnly = useCallback(() => {
			if (isStickyHeadVisible) {
				recalcStickyHeader()
			}
		}, [isStickyHeadVisible, recalcStickyHeader])

		useEffect(() => {
			window.addEventListener('resize', recalcStickyHeaderIfVisibleOnly)

			return () => {
				window.removeEventListener('resize', recalcStickyHeaderIfVisibleOnly)
			}
		}, [recalcStickyHeaderIfVisibleOnly])

		useEffect(() => {
			dispatch({ type: 'initEntitiesCount', payload: entities.length })
		}, [entitiesAndPagination])

		useEffect(() => {
			const massActions = getMassActions(state.selected)
			if (Array.isArray(massActions)) {
				dispatch({ type: 'setMassActions', payload: massActions })
			} else {
				throw new GenericError('EntitiesTable - props getMassActions nevraci pole')
			}
		}, [state.selected])

		useEffect(() => {
			recalcStickyHeader()
		})

		const scrollTableIntoView = () => {
			if (tableElm && tableElm.current) {
				tableElm.current.scrollIntoView()
			}
		}

		const renderHeadCells = (HeadCellTagName = 'th') => {
			return (
				<>
					{isMassEnabled && (
						<HeadCellTagName className={`${CLASSNAME}__cell`}>
							{renderMassActionCheckbox()}
						</HeadCellTagName>
					)}
					{headCells.map((cell, idx) => (
						<HeadCellTagName key={idx} className={`${CLASSNAME}__cell ${CLASSNAME}__cell--head`}>
							{cell}
						</HeadCellTagName>
					))}
					{isActions && (
						<HeadCellTagName className={`${CLASSNAME}__cell`}>
							<span className={`${CLASSNAME}__cell--actions`}>Akce</span>
						</HeadCellTagName>
					)}
				</>
			)
		}

		const renderHead = () => {
			return (
				<thead className={`${CLASSNAME}__head`} ref={setNormalTableHeadRefs}>
					<tr>{renderHeadCells()}</tr>
				</thead>
			)
		}

		const renderStickyHead = () => {
			return (
				<div className={`${CLASSNAME}__head ${CLASSNAME}__head--sticky`} ref={stickyTableHeadElm}>
					{isStickyHeadVisible && (
						<div className={`${CLASSNAME}__head-sticky-wrapper`}>{renderHeadCells('div')}</div>
					)}
				</div>
			)
		}

		const renderRow = (entityData) => {
			const id = entityIdGetter(entityData)
			const checked = state.selected.has(id)

			if (renderRowOverride) {
				return renderRowOverride({
					rowId: id,
					entityData,
					rowCells
				})
			} else {
				return (
					<tr
						key={id}
						className={classnames({
							[`${CLASSNAME}__row`]: true,
							[`${CLASSNAME}__row--stripable`]: rowsWithStripes,
							[`${CLASSNAME}__row--selected`]: checked
						})}
					>
						{isMassEnabled && (
							<RowMassActionsCell>
								<Checkbox
									value={id}
									checked={checked}
									onChange={(event) => {
										event.stopPropagation()
										dispatch({
											type: 'toggleSelected',
											payload: id
										})
									}}
								/>
							</RowMassActionsCell>
						)}
						{rowCells.map((cellCallback, idx) => {
							if (!isValidCallback(cellCallback)) return

							const [renderedCell, options = {}] = processCellCallbackResult(
								cellCallback(entityData)
							)

							return (
								<RowCell key={idx} options={options}>
									{renderedCell}
								</RowCell>
							)
						})}
						{isActions && (
							<RowActionsCell>
								<ActionsWrapper entityData={entityData} actions={actions} />
							</RowActionsCell>
						)}
					</tr>
				)
			}
		}

		const renderSummary = (entities) => {
			return (
				summaryCells.length > 0 && (
					<tr className={`${CLASSNAME}__row`}>
						{/* vykresli jen prazdny <td>, pro souhrn zadne hromadne akce nejsou */}
						{isMassEnabled && <RowMassActionsCell />}
						{summaryCells.map((cellCallback, idx) => {
							if (!isValidCallback(cellCallback)) return

							const [renderedCell, options = {}] = processCellCallbackResult(
								cellCallback(entities, calcSum)
							)

							return (
								<RowCell key={idx} options={options}>
									{renderedCell}
								</RowCell>
							)
						})}
						{/* vykresli jen prazdny <td>, pro souhrn zadne akce nejsou */}
						{isActions && <RowActionsCell />}
					</tr>
				)
			)
		}

		const renderMassActionCheckbox = () => {
			if (isMassEnabled) {
				return (
					<Checkbox
						checked={state.selected.size === state.count}
						onChange={(event) => {
							event.stopPropagation()

							if (state.selected.size === state.count) {
								dispatch({
									type: 'unselectAll'
								})
							} else {
								dispatch({
									type: 'selectAll',
									payload: entities.map((entity) => entityIdGetter(entity))
								})
							}
						}}
					/>
				)
			}
		}

		const renderMassActions = () => {
			if (isMassEnabled) {
				return (
					<div className={`${CLASSNAME}__footer-box`}>
						{renderMassActionCheckbox()}
						<Select
							className={`${CLASSNAME}__select ${CLASSNAME}__select--mass`}
							value={state.massAction}
							placeholder='Vyberte akci'
							onChange={(event) => {
								dispatch({
									type: 'setMassAction',
									payload: Number(event.target.value)
								})
							}}
						>
							{state.massActions.map(({ text }, idx) => {
								return (
									<option key={idx} value={idx}>
										{text}
									</option>
								)
							})}
						</Select>
						<Button
							text='Provést'
							type='button'
							size='small'
							onClick={() => {
								const selectedEntities = entities.filter((entity) =>
									state.selected.has(entityIdGetter(entity))
								)
								state.massActions[state.massAction] &&
									state.massActions[state.massAction].action(selectedEntities)
							}}
							disabled={!state.selected.size}
						/>
					</div>
				)
			}
		}

		const renderPerPageSelect = () => {
			const isIAAdvertList =
				$Router.getCurrentRouteInfo().route.getName() === ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST
			const perPageOptions = isIAAdvertList
				? INTERNAL_ADMIN.ADVERTS_PER_PAGE_OPTIONS
				: INTERNAL_ADMIN.PER_PAGE_OPTIONS
			return (
				<div className={`${CLASSNAME}__footer-box`}>
					<span>Záznamů na stránku</span>
					<Select
						className={`${CLASSNAME}__select`}
						value={paginationEntity.limit}
						onChange={(event) => {
							const limit = Number(event.target.value)

							if (paginationEntity.limit !== limit) {
								const { params = {}, route } = $Router.getCurrentRouteInfo()

								const updatedParams = Object.assign({}, params)
								if (INTERNAL_ADMIN.DEFAULT_PER_PAGE === limit) {
									delete updatedParams[limitParam]
								} else {
									updatedParams[limitParam] = limit
								}

								delete updatedParams[pageParam]

								const url = $Router.link(route.getName(), updatedParams)
								$Router.redirect(url)
							}
						}}
					>
						{perPageOptions.map((perPage) => {
							return (
								<option key={perPage} value={perPage}>
									{perPage}
								</option>
							)
						})}
					</Select>
				</div>
			)
		}

		const renderPagingSummary = () => {
			const { limit, offset, total } = paginationEntity

			const rangeFrom = offset + 1
			const rangeTo = offset + limit

			const rangeToLimited = total ? Math.min(rangeTo, total) : rangeTo

			const validToShow = rangeFrom > 0 && rangeToLimited > 0 && (total ? offset <= total : true)

			return (
				validToShow &&
				(renderPagingSummaryOverride ? (
					renderPagingSummaryOverride({
						rangeFrom,
						rangeTo: rangeToLimited,
						total
					})
				) : (
					<div className={`${CLASSNAME}__current-page-info`}>
						{`${Format.number(rangeFrom)}–${Format.number(rangeToLimited)}`}
						{total && ` z ${Format.number(total)} ${total === 1 ? 'záznamu' : 'záznamů'}`}
					</div>
				))
			)
		}

		const getPaginationUrl = (page) => {
			const { params = {} } = InternalAdminUrlConvertor.getRouteInfo($Router)
			const updatedParams = Object.assign({}, params)
			if (page && page > 1) {
				updatedParams[pageParam] = page
			} else {
				delete updatedParams[pageParam]
			}

			return InternalAdminUrlConvertor.getUrl($Router, updatedParams)
		}

		const renderPaging = (getUrl = getPaginationUrl) => {
			const { next, prev, limit, offset, total } = paginationEntity

			const isNext = total ? offset + limit < total : next
			const isPrev = total ? offset > 0 : prev

			const getPageNumber = () => {
				return Number(offset / limit) + 1
			}

			return (
				<div className={`${CLASSNAME}__footer-box`}>
					<Button
						className={classnames({
							[`${CLASSNAME}__previous-page-button`]: true,
							[`${CLASSNAME}__previous-page-button--enabled`]: isPrev
						})}
						size='small'
						icon={CHEVRON_LEFT_OUTLINE_24}
						title='Předchozí stránka'
						disabled={!isPrev}
						href={getUrl(getPageNumber() - 1)}
						onClick={(event) => {
							scrollTableIntoView()
						}}
					/>
					<Button
						className={classnames({
							[`${CLASSNAME}__next-page-button`]: true,
							[`${CLASSNAME}__next-page-button--enabled`]: isNext
						})}
						size='small'
						icon={CHEVRON_RIGHT_OUTLINE_24}
						text='Další stránka'
						title='Další stránka'
						disabled={!isNext}
						href={getUrl(getPageNumber() + 1)}
						onClick={(event) => {
							scrollTableIntoView()
						}}
					/>
				</div>
			)
		}

		const renderFoot = () => {
			return renderFootOverride ? (
				renderFootOverride({
					renderMassActions,
					renderPerPageSelect,
					renderPaging
				})
			) : (
				<div className={`${CLASSNAME}__foot`}>
					{renderMassActions()}
					{renderPerPageSelect()}
					{renderPaging()}
				</div>
			)
		}

		const renderBody = () => {
			const renderedBodyContent = entities.map((entityData) => renderRow(entityData))
			const renderedSummary = renderSummary(entities)

			if (renderBodyOverride) {
				return renderBodyOverride(renderedBodyContent, renderedSummary)
			} else {
				return <tbody>{[renderedBodyContent, renderedSummary]}</tbody>
			}
		}

		if (!isLoading) {
			if (entities && entities.length) {
				return (
					<div
						className={classnames({
							[CLASSNAME]: true,
							[`${CLASSNAME}--no-horizontal-scroll-preferred`]: isNoHorizontalScrollOnDesktopPreferred
						})}
					>
						{renderPagingSummary()}
						<div>
							<div
								className={`${CLASSNAME}__table-container`}
								ref={setTableContainerRefs}
								onScroll={recalcStickyHeaderIfVisibleOnly}
							>
								<table className={`${CLASSNAME}__table`} ref={tableElm}>
									{renderHead()}
									{renderBody()}
								</table>
							</div>
							{renderFoot()}
						</div>
						{renderStickyHead()}
					</div>
				)
			} else {
				return renderEmptyBody ? renderEmptyBody() : <EmptyList />
			}
		} else {
			return null
		}
	}
)

EntitiesTable.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.object),
		paginationEntity: PropTypes.instanceOf(PaginationEntity)
	}),
	getMassActions: PropTypes.func,
	headCells: PropTypes.arrayOf(PropTypes.node),
	rowCells: PropTypes.array,
	summaryCells: PropTypes.array,
	entityIdGetter: PropTypes.func,
	actions: PropTypes.array,
	renderPagingSummary: PropTypes.func,
	renderEmptyBody: PropTypes.func,
	renderBody: PropTypes.func,
	renderRow: PropTypes.func,
	renderFoot: PropTypes.func,
	rowsWithStripes: PropTypes.bool,
	isLoading: PropTypes.bool,
	limitParam: PropTypes.string,
	pageParam: PropTypes.string,
	isNoHorizontalScrollOnDesktopPreferred: PropTypes.bool
}

export default EntitiesTable

const SummaryLabel = ({ text = 'Celkem:' }) => {
	return <span className={`${CLASSNAME}__summary-label`}>{text}</span>
}

SummaryLabel.propTypes = {
	text: PropTypes.string
}

EntitiesTable.SummaryLabel = SummaryLabel

const RowMassActionsCell = ({ children }) => {
	return <td className={`${CLASSNAME}__cell ${CLASSNAME}__cell--content`}>{children}</td>
}

RowMassActionsCell.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const RowCell = ({ options = DEFAULT_PROPS.OBJECT, children }) => {
	const { isNumber = false } = options

	return (
		<td
			className={classnames({
				[`${CLASSNAME}__cell`]: true,
				[`${CLASSNAME}__cell--content`]: true,
				[`${CLASSNAME}__cell--number`]: isNumber
			})}
		>
			{children}
		</td>
	)
}

RowCell.propTypes = {
	options: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const RowActionsCell = ({ children }) => {
	return (
		<td className={`${CLASSNAME}__cell ${CLASSNAME}__cell--content ${CLASSNAME}__cell--actions`}>
			{children}
		</td>
	)
}

RowActionsCell.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
