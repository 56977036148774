
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['MySavedSearchAddBanner'] = {
  heading: function(d) { return "Nepromeškejte nové inzeráty."; },
  description: function(d) { return "Uložte si hledání a dostávejte upozornění na nově přidané inzeráty."; },
  headingNotLoggedIn: function(d) { return "Chcete-li uložit hledání, přihlašte se."; },
  descriptionNotLoggedIn: function(d) { return "Uložte si hledání a dostávejte upozornění na nově přidané inzeráty."; },
  login: function(d) { return "Přihlásit se"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	