import deepEqual from 'fast-deep-equal'
import { AdvertService as AdvertServiceBase } from '@inzeraty/models'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import SellerUrlConvertor from 'app/page/userweb/seller/SellerUrlConvertor'
import {
	ADVERT_ID,
	LIST_TYPE,
	LIST_CACHE_CONSTANTS,
	HOMEPAGE_ID
} from 'app/model/advert/AdvertConstants'
import DEFAULT_OBJECT_STORES from 'app/helpers/cache/cacheConstants'
import SortConstants, { OperatingLeaseSortConstants } from 'app/component/sortButton/SortConstants'
import { STATUS_CONSTANTS, DEACTIVATION_REASON } from 'app/model/advert/AdvertConstants'
import { Parser } from 'json2csv'
import { DownloadFile, Format } from '@inzeraty/helpers'
import { ADVERT_STATE } from 'app/page/userweb/clientAdvertList/getAdvertState'
import GenericError from 'ima/error/GenericError'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import { SAVE_CERT_FORM_ERRORS } from 'app/page/userweb/newAdvert/history/component/saveCert/SaveCertErrors'

const {
	URL_API_PARAMS: { SORT: sortKey, TIMESTAMP_TO: timestampKey, OFFSET: offsetKey }
} = UrlConvertor.constants

const {
	URL_API_PARAMS: { SELLER_ID: sellerKey }
} = SellerUrlConvertor.constants

export default class AdvertService extends AdvertServiceBase {
	/**
	 * @method constructor
	 * @constructor
	 * @param {models.advert.AdvertResource} advertResource
	 * @param {models.advert.AdvertFactory} advertFactory
	 * @param {models.pagination.PaginationFactory} paginationFactory
	 */
	constructor(
		advertResource,
		advertFactory,
		advertHistoryFactory,
		paginationFactory,
		cacheHelper,
		historyStorage
	) {
		super(advertResource, advertFactory, paginationFactory)

		this._cacheHelper = cacheHelper
		this._historyStorage = historyStorage
		this._advertResource = advertResource
		this._advertHistoryFactory = advertHistoryFactory

		this._advertCustomCachedData = new Map()
	}

	/**
	 * Nacte a vrati seznam pro vypis inzeratu
	 *
	 * Nejprve zkontroluje lokalni cache
	 * Zavola API a ulozi vysledky do cache, pokud data nenajde
	 *
	 * @method getSearchAdvertList
	 * @param {Object} params
	 * @param {Object} options
	 * @param {Boolean} cached
	 * @public
	 * @async
	 * @returns {Object}
	 */
	async getSearchAdvertList(params, options) {
		const {
			[sortKey]: sortValue,
			[sellerKey]: sellerValue,
			[offsetKey]: offsetValue,
			[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease
		} = params

		const timestampValue = this._cacheHelper.getTimestamp()

		const listType = sellerValue
			? isOperatingLease
				? LIST_TYPE.SELLER_WITH_OPERATING_LEASES
				: LIST_TYPE.SELLER
			: isOperatingLease
			? LIST_TYPE.REGULAR_WITH_OPERATING_LEASES
			: LIST_TYPE.REGULAR
		const paginationKey = sellerValue || timestampValue
		let result

		if (await this._hasPaginationCache(listType, paginationKey)) {
			if (await this._hasAdvertListCache(listType, { name: offsetKey, value: offsetValue })) {
				result = {
					advertList: await this._getAdvertListCache(listType, offsetValue, sortValue),
					paginationEntity: await this._getPaginationCache(listType, paginationKey, offsetValue)
				}
			} else {
				result = await this._fetchAdvertList(timestampValue, params, options)
				this._addAdvertListCache(listType, result, offsetValue)
			}
		} else {
			result = await this._fetchAdvertList(timestampValue, params, options)
			const key =
				listType === LIST_TYPE.SELLER
					? { [sellerKey]: sellerValue }
					: { [timestampKey]: timestampValue }
			this._addAdvertListCache(listType, result, offsetValue)
			this._addPaginationCache(listType, result, key)
		}

		return result
	}

	/**
	 * Vrátí podobné inzeráty k inzerátu s daným id.
	 *
	 * @method getRelatedAdvertList
	 * @public
	 * @param {Object}          params
	 * @param {Object}          options
	 * @return {Promise<Array>}	Data podobných inzerátů
	 */
	getRelatedAdvertList(params, options) {
		return this._advertResource.getRelated(params, options).then((response) => {
			return response.body.results.map((apiData) => this._advertFactory.transformData(apiData))
		})
	}

	/**
	 * Vrati data o prednostnom vypise
	 *
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<Array>} Data o prednostnom vypise
	 */
	getPreferred(params, options = {}) {
		return this._advertResource.getPreferred(params, options).then((response) => {
			return response.body.results.map((apiData) => this._advertFactory.transformData(apiData))
		})
	}

	/**
	 * Nacte a vrati detail inzeratu
	 *
	 * Nejprve zkontroluje lokalni cache
	 * Zavola API a ulozi vysledek do cache, pokud data nenajde
	 *
	 * @method getAdvertDetail
	 * @param {Number} advertId
	 * @public
	 * @async
	 * @returns {Object}
	 */
	async getAdvertDetail(advertId, params = {}, options = {}) {
		const {
			[AdvertDetailUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease = false
		} = params
		const { cache = true } = options
		let result

		if (cache && (await this._hasAdvertDetailCache(advertId, isOperatingLease))) {
			result = await this._getAdvertDetailCache(advertId, isOperatingLease)
		} else {
			result = await super.getAdvertDetail(advertId, params, options)

			if (cache) {
				this._addAdvertDetailCache(result, isOperatingLease)
			}
		}

		return result
	}

	/**
	 * Nacte a vrati data pro vkladani a editaci inzeratu
	 *
	 * @method getCreatePage
	 * @param {Object} params
	 * @param {Object} options
	 * @public
	 * @async
	 * @returns {Object}
	 */
	async getCreatePage(params, options) {
		const result = await this._advertResource.getCreatePage(params, options)

		return result.body.result
	}

	/**
	 * Nacte data z API
	 *
	 * @method _fetchAdvertList
	 * @param {Number} timestampValue
	 * @param {Object} params
	 * @param {Object} options
	 * @async
	 * @private
	 * @returns {Promise}
	 */
	async _fetchAdvertList(timestampValue, params, options) {
		return await super.getSearchAdvertList(
			Object.assign({}, params, { [timestampKey]: timestampValue }),
			options
		)
	}

	/**
	 * Zkontroluje, zda v cache existuji data strankovani pro zadany klic (timestamp)
	 *
	 * @method _hasPaginationCache
	 * @param {String} listType
	 * @param {String|Number|Object} indexKey
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _hasPaginationCache(listType, indexKey = '') {
		return await this._cacheHelper.countEntities(
			LIST_CACHE_CONSTANTS[listType].PAGINATION,
			indexKey
		)
	}

	/**
	 * Zkontroluje, zda v cache existuji data inzeratu pro zadany klic (offset)
	 *
	 * @method _hasAdvertListCache
	 * @param {String} listType
	 * @param {Object} indexKey
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _hasAdvertListCache(listType, indexKey) {
		return await this._cacheHelper.countEntities(
			LIST_CACHE_CONSTANTS[listType].ADVERT_LIST,
			indexKey
		)
	}

	/**
	 * Zkontroluje, zda v cache existuji data inzeratu pro zadany klic (advertId)
	 *
	 * @method _hasAdvertDetailCache
	 * @param {Number} advertId
	 * @param {Boolean} isOperatingLease
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _hasAdvertDetailCache(advertId, isOperatingLease) {
		const { NAME: STORE_NAME } = this._getAdvertDetailStore(isOperatingLease) || {}

		return await this._cacheHelper.countEntities(STORE_NAME, advertId)
	}

	/**
	 * Upravy inzeraty podla zadanych parametrov
	 *
	 * @param {Object} params
	 * @param {Object} options
	 */
	updateAdvert(params, options) {
		return this._advertResource
			.updateAdvert(params, options)
			.then(({ body: { fail_ids = [] } = {} }) => fail_ids)
	}

	/**
	 * Najde a vrati data strankovani z cache
	 *
	 * @method _getPaginationCache
	 * @param {String} listType
	 * @param {Number} key
	 * @param {Number} offsetValue
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _getPaginationCache(listType, key, offsetValue) {
		const cacheEntity =
			(await this._cacheHelper.getEntity(LIST_CACHE_CONSTANTS[listType].PAGINATION, key)) || {}
		return Object.assign(cacheEntity, { [offsetKey]: offsetValue })
	}

	/**
	 * Najde a vrati data inzeratu z cache
	 *
	 * @method _getAdvertListCache
	 * @param {String} listType
	 * @param {Number} offsetValue
	 * @param {String} sortValue
	 * @async
	 * @private
	 * @returns {Promise}
	 */
	async _getAdvertListCache(listType, offsetValue, sortValue) {
		const result = await this._cacheHelper.getEntities(LIST_CACHE_CONSTANTS[listType].ADVERT_LIST, {
			name: offsetKey,
			value: offsetValue
		})

		// Razeni podle hodnoty entity. Pokud dve nabidky maji stejnou hodnotu,
		// radime podle IDcka.
		//
		// Hodnotu pro razeni dle hodnoty (1 nebo -1) dostavame ze SortConstants
		const sortByValue = (firstItem, secondItem) => {
			const prepareValue = (value) => {
				if (value instanceof Number) {
					return value
				} else if (value instanceof Date) {
					return value.getTime()
				} else {
					return value
				}
			}

			const SortConstantsToUse = [
				LIST_TYPE.REGULAR_WITH_OPERATING_LEASES,
				LIST_TYPE.SELLER_WITH_OPERATING_LEASES
			].includes(listType)
				? OperatingLeaseSortConstants
				: SortConstants

			const ORDER_DIRECTION = SortConstantsToUse.orderDirections || {}
			const SORT_KEYS = SortConstantsToUse.sortKeys[sortValue] || []

			let firstItemKey
			let secondItemKey

			// vyhledam klice pro porovnavane hodnoty
			const sortKeys = SORT_KEYS.find((sortKey) => {
				const { keys } = sortKey

				const firstKey = keys.find((key) =>
					firstItem[key] ? !!prepareValue(firstItem[key]) : false
				)
				const secondKey = keys.find((key) =>
					secondItem[key] ? !!prepareValue(secondItem[key]) : false
				)

				if (
					!!firstKey &&
					!!secondKey &&
					prepareValue(firstItem[firstKey]) !== prepareValue(secondItem[secondKey])
				) {
					firstItemKey = firstKey
					secondItemKey = secondKey

					return true
				} else {
					return false
				}
			})

			if (sortKeys) {
				const position = sortKeys.direction === ORDER_DIRECTION.ASC ? -1 : 1

				if (prepareValue(firstItem[firstItemKey]) < prepareValue(secondItem[secondItemKey])) {
					return position
				} else {
					return -position
				}
			} else {
				return 1
			}
		}

		if (sortValue) {
			return result.sort(sortByValue)
		} else {
			return result.reverse()
		}
	}

	/**
	 * Najde a vrati data inzeratu z cache
	 *
	 * @param {String} key
	 * @async
	 * @private
	 * @returns {Promise}
	 */
	async _getHomePageAdvertListCache(key) {
		const listType = LIST_TYPE.HOMEPAGE

		const result = await this._cacheHelper.getEntities(LIST_CACHE_CONSTANTS[listType].ADVERT_LIST, {
			name: HOMEPAGE_ID.VEHICLE_BODY_NAME,
			value: key
		})
		return result.reverse()
	}

	/**
	 * Najde a vrati data o strankovani z cache
	 *
	 * @param {String} listType
	 * @param {String} key
	 * @private
	 * @async
	 *
	 * @returns {Promise}
	 */
	async _getHomePagePaginationCache(key) {
		const listType = LIST_TYPE.HOMEPAGE

		const result = await this._cacheHelper.getEntity(LIST_CACHE_CONSTANTS[listType].PAGINATION, key)
		return result
	}

	/**
	 * Najde a vrati detail inzeratu z cache
	 *
	 * @method _getAdvertDetailCache
	 * @param {Number} advertId
	 * @param {Boolean} isOperatingLease
	 * @private
	 * @async
	 * @returns {Promise}
	 */
	async _getAdvertDetailCache(advertId, isOperatingLease) {
		const { NAME: STORE_NAME } = this._getAdvertDetailStore(isOperatingLease) || {}

		return await this._cacheHelper.getEntity(STORE_NAME, advertId)
	}

	/**
	 * Ulozi data strankovani do cache
	 *
	 * @method _addPaginationCache
	 * @param {String} listType
	 * @param {Object} result Data z API
	 * @param {Object} key
	 * @async
	 * @private
	 */
	async _addPaginationCache(listType, result, key) {
		const { paginationEntity } = result

		this._cacheHelper.addEntity(
			LIST_CACHE_CONSTANTS[listType].PAGINATION,
			Object.assign({}, paginationEntity, key)
		)
	}

	/**
	 * Ulozi data inzeratu do cache
	 *
	 * @method _addAdvertListCache
	 * @param {String} listType
	 * @param {Object} result Data z API
	 * @param {Number} offsetValue
	 * @param {String} vehicleBodyName
	 * @async
	 * @private
	 */
	async _addAdvertListCache(listType, result, offsetValue, vehicleBodyName = '') {
		const { advertList } = result
		const getKey = ({ sortingDate, id }) => sortingDate.getTime() + String(id)
		const additionalKey = vehicleBodyName ? { vehicleBodyName } : {}

		this._cacheHelper.addEntities(
			LIST_CACHE_CONSTANTS[listType].ADVERT_LIST,
			advertList.map((advertEntity) =>
				Object.assign(
					{},
					advertEntity,
					{
						[LIST_CACHE_CONSTANTS[listType].LIST_KEY]: getKey(advertEntity),
						[offsetKey]: offsetValue
					},
					additionalKey
				)
			)
		)
	}

	/**
	 * Ulozi detail inzeratu do cache
	 *
	 * @method _addAdvertDetailCache
	 * @param {Object} advertEntity
	 * @param {Boolean} isOperatingLease
	 * @async
	 * @private
	 */
	async _addAdvertDetailCache(advertEntity, isOperatingLease) {
		const STORE = this._getAdvertDetailStore(isOperatingLease) || {}

		this._cacheHelper.addEntity(
			STORE.NAME,
			Object.assign({}, advertEntity, {
				[STORE.KEY_PATH]: advertEntity.oldId || advertEntity.id
			})
		)
	}

	/**
	 * Vrátí cache s uloženými entitami inzerátů.
	 *
	 * @method getAdvertEntityCache
	 * @public
	 * @param {Number} id
	 * @param {String} listType
	 * @public
	 * @return {Promise}
	 */
	async getAdvertEntityCache(id, listType) {
		return Promise.all([
			this._cacheHelper.getEntity(LIST_CACHE_CONSTANTS[listType].ADVERT_LIST, {
				name: ADVERT_ID.OLD_ID,
				value: id
			}),
			this._cacheHelper.getEntity(LIST_CACHE_CONSTANTS[listType].ADVERT_LIST, {
				name: ADVERT_ID.NEW_ID,
				value: id
			})
		]).then(([oldIdEntity, newIdEntity]) => {
			return oldIdEntity || newIdEntity
		})
	}

	/**
	 * Vrati celkovy pocet inzeratu pro zadane parametry
	 *
	 * @method getAdvertsTotalCount
	 * @param {Object} params
	 * @param {Object} options
	 * @param {Boolean} allowCache
	 * @public
	 * @async
	 * @returns {Promise}
	 */
	async getAdvertsTotalCount(params, options, allowCache = true) {
		const {
			[sellerKey]: sellerValue,
			[offsetKey]: offsetValue,
			[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease
		} = params
		const timestampValue = this._cacheHelper.getTimestamp()

		const listType = sellerValue
			? isOperatingLease
				? LIST_TYPE.SELLER_WITH_OPERATING_LEASES
				: LIST_TYPE.SELLER
			: isOperatingLease
			? LIST_TYPE.REGULAR_WITH_OPERATING_LEASES
			: LIST_TYPE.REGULAR
		const paginationKey = sellerValue || timestampValue

		if (allowCache && (await this._hasPaginationCache(listType, paginationKey))) {
			const { total } = await this._getPaginationCache(listType, paginationKey, offsetValue)
			return total
		} else {
			// musime si pamatovat s jakymi URL parametry jsme volali API. Muze se
			// stat, ze nam odpovedi nemusi vzdy prijit ve stejnem poradi, jak jsme
			// odeslali dotazy. Tak at nam nejaka starsi opozdena odpoved neprepise
			// spravna data.
			this._latestUrlParamsForTotal = params

			const {
				paginationEntity: { total }
			} = await this._fetchAdvertList(timestampValue, params, options)

			// stare odpovedi ignorujeme
			if (!deepEqual(this._latestUrlParamsForTotal, params)) {
				throw new Error('It is a delayed response.')
			} else {
				return total
			}
		}
	}

	/**
	 * Vrati nasledujici inzerat z cache
	 *
	 * @method getNextAdvert
	 * @param {Number} advertId
	 * @param {Object} currentPageParams
	 * @async
	 * @public
	 * @returns {Promise|Object}
	 */
	async getNextAdvert(advertId, currentPageParams) {
		const {
			DEFAULTS: { PER_PAGE }
		} = UrlConvertor.constants
		const { advertList } = await this.getSearchAdvertList(currentPageParams)
		const nextAdvertListPosition =
			advertList.findIndex(
				(advertEntity) => advertEntity.id === advertId || advertEntity.oldId === advertId
			) + 1

		if (nextAdvertListPosition < PER_PAGE) {
			return advertList[nextAdvertListPosition] || {}
		} else {
			const params = Object.assign({}, currentPageParams, {
				[offsetKey]: currentPageParams[offsetKey] + PER_PAGE
			})
			const { advertList } = await this.getSearchAdvertList(params)

			return advertList.length ? advertList[0] : {}
		}
	}

	/**
	 * Vrati předchozí inzerat z cache
	 *
	 * @method getPrevAdvert
	 * @param {Number} advertId
	 * @param {Object} currentPageParams
	 * @async
	 * @public
	 * @returns {Promise|Object}
	 */
	async getPrevAdvert(advertId, currentPageParams) {
		const {
			DEFAULTS: { PER_PAGE }
		} = UrlConvertor.constants
		const { advertList } = await this.getSearchAdvertList(currentPageParams)
		const prevAdvertListPosition =
			advertList.findIndex(
				(advertEntity) => advertEntity.id === advertId || advertEntity.oldId === advertId
			) - 1

		if (prevAdvertListPosition >= 0) {
			return advertList[prevAdvertListPosition] || {}
		} else if (currentPageParams[offsetKey] === 0) {
			return {}
		} else {
			const params = Object.assign({}, currentPageParams, {
				[offsetKey]: currentPageParams[offsetKey] - PER_PAGE
			})
			const { advertList } = await this.getSearchAdvertList(params)
			return advertList[PER_PAGE - 1]
		}
	}

	/**
	 * Ulozi cebia certifikat k inzerátu s daným id.
	 *
	 * @method saveCebiaCert
	 * @public
	 * @async
	 * @param {Number|String}	id		Id inzerátu
	 * @param {Object}			data	Form data
	 * @return {Promise<JSON>}	Data odpovědi
	 */
	async saveCebiaCert(id, data) {
		const { cebia_report = '' } = data

		const codeRegExp = new RegExp(/^\d{10}$/)
		const reportRegExp = new RegExp(/^CR-\w{4}-\d{2}-\d{7}$/)
		const urlRegExp = new RegExp(/^\s*https:\/\/cz\.cebia\.com\/smartCode\/.*$/)

		const isCode = codeRegExp.test(cebia_report)
		const isReport = reportRegExp.test(cebia_report)
		const isUrl = urlRegExp.test(cebia_report)

		try {
			if (isCode) {
				return await this._advertResource.saveCebiaCoupon(id, {
					cebia_coupon: cebia_report
				})
			} else if (isReport) {
				return await this._advertResource.saveCebiaCert(id, data)
			} else if (isUrl) {
				return await this._advertResource.saveCebiaSmartCodeUrl(id, {
					cebia_smart_code_url: cebia_report
				})
			}

			throw new GenericError('unknown_cebia_report_format', {
				body: {
					errors: [
						{
							error_code: SAVE_CERT_FORM_ERRORS.UNKNOWN_CEBIA_FORMAT,
							error_message: 'Neznámý formát kupónu',
							fields: ['cebia_report']
						}
					]
				},
				status: HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY
			})
		} catch (oldError) {
			const { body = {} } = oldError.getParams()
			const { errors = [] } = body

			const newErrors = errors.map((error) => {
				return { ...error, fields: ['cebia_report'] }
			})

			throw new GenericError('', {
				...oldError.getParams(),
				body: {
					...body,
					errors: newErrors
				}
			})
		}
	}

	/**
	 * Ulozi k id inzeratu libovolna data.
	 *
	 * @method saveAdvertCachedData
	 * @public
	 * @param {Number|String}	advertId 	Id inzeratu
	 * @param {Object}			data 		Libovolna data
	 * @param {boolean}			rewrite 	True, maji-li se data uplne prepsat, pokud uz jsou v cache nejaka data ulozena
	 */
	saveAdvertCachedData(advertId, data, rewrite = true) {
		const dataToSave = rewrite
			? data
			: Object.assign({}, this._advertCustomCachedData.get(advertId), data)
		this._advertCustomCachedData.set(advertId, dataToSave)
	}

	/**
	 * Ziska data ulozena k id inzeratu.
	 *
	 * @method getAdvertCachedData
	 * @public
	 * @param {Number|String}	advertId Id inzeratu
	 * @return {Object}	Data, ktera jsme si k inzeratu ulozili
	 */
	getAdvertCachedData(advertId) {
		return this._advertCustomCachedData.get(advertId)
	}

	getAdvertHistory(advertId, params, options) {
		return this._advertResource.getAdvertHistory(advertId, params, options).then((response) => {
			const { results, pagination } = response.body
			const advertHistoryData = results.map((item) =>
				this._advertHistoryFactory.transformData(item)
			)

			return {
				advertHistoryEntities: this._advertHistoryFactory.createEntityList(advertHistoryData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}

	/**
	 * Uloží změnu stavu inzerátu s daným ID, pokud je inzerát nezaplacený a neaktivní
	 * přepíše výsledek operace jako uspěšný.
	 * Vytvořeno, protože API v tomto případě provede změnu stavu inzerátu
	 * , ale tuto operaci označí jako neúspěšnou.
	 *
	 * @method activateAdvertStatus
	 * @public
	 * @param {Object} params
	 * @param {Object} options
	 * @returns {Promise}
	 */
	updateAdvertStatusAndHandleNotPaidError(params, options) {
		return this.updateAdvertStatus(params, options).then((summary) =>
			this._handleNotPaidError(summary)
		)
	}

	/**
	 * Smaže errory a přepíše výsledek operace jako uspěšný
	 * , pokud je inzerát nezaplacený, neaktivní a operace byla označena jako neúspěšná.
	 *
	 * @method _handleNotPaidError
	 * @private
	 * @param {Array} summary
	 * @returns {Array}
	 */
	_handleNotPaidError(summary) {
		return summary.map((result) => {
			const { operation_ok = '', current_status = '', errors = [] } = result
			if (
				operation_ok === false &&
				current_status === STATUS_CONSTANTS.INACTIVE &&
				this._isOnlyNotPaidError(errors)
			) {
				return { ...result, errors: [], operation_ok: true }
			}
			return result
		})
	}

	/**
	 * Zkontroluje, zda vstupní pole obsahuje pouze not paid error
	 *
	 * @method _isOnlyNotPaidError
	 * @private
	 * @param {Array} errors
	 * @returns {Boolean}
	 */
	_isOnlyNotPaidError(errors) {
		const ERRORS_LENGT = 1
		return errors.length === ERRORS_LENGT && errors.includes(DEACTIVATION_REASON.NOT_PAID)
	}

	getMTPLPrices(params) {
		return this._advertResource.getMTLPPrices(params).then((response) => {
			const { body: { result: { max_price, min_price } = {} } = {} } = response || {}

			return {
				maxPrice: Math.round(max_price),
				minPrice: Math.round(min_price)
			}
		})
	}

	/**
	 * Provede stažení csv souboru se seznamem inzerátů.
	 *
	 * @public
	 * @param {Object} params
	 * @returns {Promise}
	 */
	downloadAdvertsCSV(params) {
		const STATUSES = {
			[ADVERT_STATE.DRAFT]: 'Rozpracovaný',
			[ADVERT_STATE.ACTIVE]: 'Aktivní',
			[ADVERT_STATE.INACTIVE]: 'Neaktivní',
			[ADVERT_STATE.INACTIVE_EXPIRED]: 'Expirovaný',
			[ADVERT_STATE.INACTIVE_INSUFFICIENT_IMAGES]: 'Nesplňuje min. počet fotek',
			[ADVERT_STATE.INACTIVE_NOT_PAID]: 'Nezaplacený',
			[ADVERT_STATE.INACTIVE_INSUFFICIENT_MODULES]: 'Překročen limit slotů',
			[ADVERT_STATE.INACTIVE_USER_DEACTIVATED]: 'Zneaktivněný uživatelem',
			[ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED]: 'Zneaktivněný administrátorem',
			[ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED]: 'Zneaktivněný s banem',
			[ADVERT_STATE.INACTIVE_VIN_DUPLICATION]: 'Duplicitní VIN',
			[ADVERT_STATE.INACTIVE_VIN_DUPLICATION_FRAUD]: 'Opakované vkládání VIN',
			[ADVERT_STATE.DISABLED]: 'Zakázaný administrátorem',
			[ADVERT_STATE.DISABLED_REJECTED]: 'Zakázaný s banem',
			[ADVERT_STATE.DELETED]: 'Smazaný'
		}

		return new Promise((resolve, reject) => {
			this._advertResource
				.getAdverts(params)
				.then((response) => {
					const { results } = response.body
					const advertsData = results.map((item) => this._advertFactory.transformData(item))

					const adverts = advertsData.map((advert) => {
						const {
							id,
							category = {},
							price,
							manufacturerCb = {},
							modelCb = {},
							createDate,
							editDate,
							status,
							source,
							advertReportsCount,
							preferredOffer,
							lastToppedDate
						} = advert
						return {
							Stav: STATUSES[status],
							ID: id,
							Kategorie: category.name,
							Výrobce: manufacturerCb.name,
							Model: modelCb.name,
							Cena: price,
							'Datum vložení': Format.date(createDate),
							'Datum editace': Format.date(editDate),
							Zadáno: source === 'web_source' ? 'Ručně' : 'Importem',
							Závadný: advertReportsCount,
							'Přednostní výpis': preferredOffer === true ? 'Ano' : 'Ne',
							'Datum posledního topování': Format.date(lastToppedDate)
						}
					})

					try {
						const parser = new Parser()
						const csv = parser.parse(adverts)

						DownloadFile({
							content: ['\ufeff' + csv], //"\ufeff" je BOM -> pro windows fixne kodovani csv v excelu
							type: 'text/csv;charset=utf-8;',
							fileName: 'adverts.csv'
						})

						resolve()
					} catch (error) {
						reject()
					}
				})
				.catch((error) => {
					reject()
				})
		})
	}

	_getAdvertDetailStore(isOperatingLease) {
		return isOperatingLease
			? DEFAULT_OBJECT_STORES.ADVERT_DETAIL_WITH_OPERATING_LEASE
			: DEFAULT_OBJECT_STORES.ADVERT_DETAIL
	}
}
