import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'

import './SimpleDataWidget.less'

export const SIMPLE_DATA_WIDGET_ID = 'simple_data_widget'

const CLASSNAME = 'c-cp-simple-data-widget'

function SimpleDataWidget({ formLineEntity, className = '' }) {
	return (
		<SimpleData label={formLineEntity.label} className={className}>
			{formLineEntity.value}
		</SimpleData>
	)
}

SimpleDataWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	className: PropTypes.string
}

export const SimpleData = ({ children, label = '', className = '' }) => {
	return (
		<div className={classnames(CLASSNAME, className)}>
			<span className={`${CLASSNAME}__label`}>{label}</span>
			<span className={`${CLASSNAME}__value`}>{children}</span>
		</div>
	)
}

SimpleData.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string
}

export default SimpleDataWidget
