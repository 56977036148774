import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { Icon } from '@sznds/react'
import { CHEVRON_LEFT_OUTLINE_24 } from '@sznds/icons'

import './BackView.less'

const CLASSNAME = 'cf-back'

export default class BackView extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			label: PropTypes.string.isRequired,
			onClick: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			className: '',
			onClick: DEFAULT_PROPS.FUNCTION
		}
	}

	render() {
		const { label, className, onClick } = this.props

		return (
			<div
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
			>
				<button type='button' className={`${CLASSNAME}__btn`} onClick={onClick} data-dot='back'>
					<Icon className={`${CLASSNAME}__icon`} symbol={CHEVRON_LEFT_OUTLINE_24} />
					<span className={`${CLASSNAME}__label`}>{label}</span>
				</button>
			</div>
		)
	}
}
