import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ImaContext from 'ima/page/context'
import { useLocalize } from 'app/base/componentHelpers'
import HistoryTableRow from 'app/page/internalAdmin/components/historyTableRow/HistoryTableRow'

import './PremiseHistoryRowCS.json'

export default function PremiseHistoryRowView({ entityData, rowCells }) {
	const localize = useLocalize()
	const { $Dictionary } = useContext(ImaContext)

	const renderChanges = () => {
		const { changes = {} } = entityData
		const categories = Object.keys(changes)

		return categories.map((category, index) => {
			const titleLocalizeKey = `PremiseHistoryRow.category__${category}`
			const title = $Dictionary.has(titleLocalizeKey)
				? localize(titleLocalizeKey)
				: localize('PremiseHistoryRow.category__unknown')

			return (
				<HistoryTableRow.ChangesGroup key={`group-${category}-${index}`} title={title}>
					{changes[category].map((change, index) => {
						const { item, new: newValue, old: oldValue } = change

						return (
							<HistoryTableRow.ChangesGroupContentLine
								key={`change-${item}-${index}`}
								name={item.replace(/_/g, '_\n')} // aby se nam kvuli dlouhym nazvum nerozbil layout tabulky
								newValue={JSON.stringify(newValue)}
								oldValue={JSON.stringify(oldValue)}
							/>
						)
					})}
				</HistoryTableRow.ChangesGroup>
			)
		})
	}

	return (
		<HistoryTableRow entityData={entityData} rowCells={rowCells} renderChanges={renderChanges} />
	)
}

PremiseHistoryRowView.propTypes = {
	entityData: PropTypes.object.isRequired,
	rowCells: PropTypes.array.isRequired
}
