import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import { createEntityList } from '@inzeraty/form-lines'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'

const DATE_FROM_FORM_LINE_ENTITY_ID = 'date_from_egreater'
const DATE_TO_FORM_LINE_ENTITY_ID = 'date_to_eless'

export default class IAPreferredOffersController extends AbstractInternalAdminController {
	static get STATUS() {
		return Object.freeze({
			RESERVATION: {
				id: 'reservation',
				name: 'Rezervace'
			},
			ORDER: {
				id: 'order',
				name: 'Objednávka'
			},
			PAID: {
				id: 'paid',
				name: 'Zaplaceno'
			},
			RUNNING: {
				id: 'running',
				name: 'Běží'
			},
			PAUSED: {
				id: 'paused',
				name: 'Pozastaveno'
			},
			FINISHED: {
				id: 'finished',
				name: 'Úspěšně ukončeno'
			},
			CANCELED: {
				id: 'canceled',
				name: 'Stornováno'
			}
		})
	}

	constructor(dependenciesHelper, entitiesFilterExtension, preferredOffersService) {
		super(dependenciesHelper)

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
		this._entitiesFilterExtension = entitiesFilterExtension
		this._preferredOffersService = preferredOffersService
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		})
	}

	update() {
		return {
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		}
	}

	getOptions(source = {}) {
		return Object.values(source).map(({ id, name }) => ({
			value: id,
			name
		}))
	}

	async getEntitiesAndPagination() {
		const fetchPreferredOffers = async (params) => {
			try {
				const {
					preferredOffersEntities,
					paginationEntity
				} = await this._preferredOffersService.getPreferredOffers(params)

				return {
					entities: preferredOffersEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.sort) {
			params.sort = '-id'
		}

		this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchPreferredOffers(params)

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	async _filterInitiezerClb() {
		const routeParams = this.getRouteParams()

		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'id',
					name: 'ID přednostního výpisu'
				},
				{
					value: 'registration_id',
					name: 'ID registrace'
				},
				{
					value: 'premise_id',
					name: 'ASK ID'
				},
				{
					value: 'premise_name',
					name: 'Název firmy'
				}
			]),
			this._entitiesFilterExtension.getSelect(
				'status',
				'Stav',
				'Vyberte stav',
				this.getOptions(IAPreferredOffersController.STATUS)
			),
			{
				widget: IDS.DATE,
				id: DATE_FROM_FORM_LINE_ENTITY_ID,
				label: 'Datum od',
				value: routeParams[DATE_FROM_FORM_LINE_ENTITY_ID] || ''
			},
			{
				widget: IDS.DATE,
				id: DATE_TO_FORM_LINE_ENTITY_ID,
				label: 'Datum do',
				value: routeParams[DATE_TO_FORM_LINE_ENTITY_ID] || ''
			}
		])
	}
}
