import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import AbstractModalLogic from './AbstractModalLogic'

class DropdownLogic extends AbstractModalLogic {
	static get propTypes() {
		return Object.assign({}, AbstractModalLogic.propTypes, {
			dropdownDefinition: PropTypes.object,
			autoCloseOnUnmount: PropTypes.bool
		})
	}

	static get defaultProps() {
		return Object.assign({}, AbstractModalLogic.defaultProps, {
			autoCloseOnUnmount: true
		})
	}

	constructor(props, context) {
		super(props, context)

		this._canBeDropdownClosed = this._canBeDropdownClosed.bind(this)
		this._closeDropdown = this._closeDropdown.bind(this)
		this._closeDropdownWithoutSavingChanges = this._closeDropdownWithoutSavingChanges.bind(this)

		this._isMounted = false
	}

	componentDidMount() {
		super.componentDidMount()

		this._isMounted = true
	}

	componentWillUnmount() {
		const {
			formLineEntity: { id },
			autoCloseOnUnmount
		} = this.props

		if (autoCloseOnUnmount) this._fireCloseDropdownEvent(id)

		this._isMounted = false
	}

	render() {
		const { children } = this.props

		return children({
			closeDropdown: this._closeDropdown,
			closeDropdownWithoutSavingChanges: this._closeDropdownWithoutSavingChanges,
			canBeDropdownClosed: this._canBeDropdownClosed
		})
	}

	_closeDropdown() {
		const { dropdownDefinition } = this.props

		this._closeModal(dropdownDefinition, this._canBeDropdownClosed, (id) => {
			this._fireCloseDropdownEvent(id)
		})
	}

	_closeDropdownWithoutSavingChanges() {
		this._closeModalWithoutSavingChanges(this._canBeDropdownClosed, (id) => {
			this._fireCloseDropdownEvent(id)
		})
	}

	_fireCloseDropdownEvent(id) {
		if (this._isMounted) {
			this.fire('closeFilterDropdown', {
				filterId: id
			})
		}
	}

	_canBeDropdownClosed() {
		const {
			formLineEntity,
			filterLogicData: { form: { validateForm = () => true } = {} }
		} = this.props

		// na dropdownech validujeme pouze to, zda-li uzivatel vypln formular spravne,
		// celkova suma zafiltrovanych inzeratu nas nezajima
		return validateForm(formLineEntity)
	}
}

const selectors = (state) => ({
	redirect: state[FiltersExtension.stateIds.REDIRECT],
	filteredAdvertsCountTotal: state[FiltersExtension.stateIds.FILTERED_ADVERTS_COUNT_TOTAL]
})

export default select(selectors)(DropdownLogic)
