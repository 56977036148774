import {
	STATUS_CONSTANTS,
	DEACTIVATION_REASON,
	APPROVAL_ADMIN
} from 'app/model/advert/AdvertConstants'

export const ADVERT_STATE = {
	// rozpracovany inzerat, uzivatel jej v KA ale nevidi
	DRAFT: STATUS_CONSTANTS.DRAFT,

	// aktivni inzerat
	ACTIVE: STATUS_CONSTANTS.ACTIVE,

	// neaktivni inzerat
	INACTIVE: STATUS_CONSTANTS.INACTIVE,
	// detailnejsi cleneni stavu neaktivnich inzeratu (tyka se jen novych inzeratu,
	// stare inzeraty toto cleneni nemaji):
	// vyprsela platnost inzeratu, tyka se jen jednorazovkaru
	INACTIVE_EXPIRED: 'inactive_expired',
	// nedostatecny pocet fotek
	INACTIVE_INSUFFICIENT_IMAGES: 'inactive_insufficient_images',
	// inzerat nebyl jeste zaplacen, tyka se jen jednorazovkaru
	INACTIVE_NOT_PAID: 'inactive_not_paid',
	// nedostatecny pocet modulu, tyka se jen bazarniku
	INACTIVE_INSUFFICIENT_MODULES: 'inactive_insufficient_modules',
	// uzivatel si inzerat sam zneaktivnil
	INACTIVE_USER_DEACTIVATED: 'inactive_user_deactivated',
	// obyčejné zneaktivnění adminem bez důvodu, uživatel jej může bezpodmínečně aktivovat
	INACTIVE_ADMIN_DEACTIVATED: 'inactive_admin_deactivated',
	// tzv. "soft ban", v tomto případě došlo k zneaktivnění inzerátu adminem a
	// uživatel jej musí zeditovat podle pokynů a přeuložit
	INACTIVE_ADMIN_DEACTIVATED_REJECTED: 'inactive_admin_deactivated_rejected',
	// duplicitny VIN
	INACTIVE_VIN_DUPLICATION: DEACTIVATION_REASON.VIN_DUPLICATION,
	// opakovane vkladany VIN. Inzerent vlozil inzerat, po nejakem case ho smazal a vytvoril
	// novy se stejnym VINem. Vse s cilem obejit topovani a dostat se zase na predni pozici
	// ve vypise inzeratu.
	INACTIVE_VIN_DUPLICATION_FRAUD: DEACTIVATION_REASON.VIN_DUPLICATION_FRAUD,

	// zakazany inzerat adminem
	DISABLED: STATUS_CONSTANTS.DISABLED,
	// tzv. "hard ban", uzivatel jej musi zeditovat a pote kontaktovat podporu,
	// sam nemuze inzerat zaktivovat
	DISABLED_REJECTED: 'disabled_admin_rejected',

	// smazany inzerat, uzivatel jej v KA uz nevidi, v DB ale jeste nejakou dobu je
	DELETED: STATUS_CONSTANTS.DELETED
}

export const UNFINISHED_STATES = [
	ADVERT_STATE.INACTIVE_INSUFFICIENT_MODULES,
	ADVERT_STATE.INACTIVE_INSUFFICIENT_IMAGES,
	ADVERT_STATE.INACTIVE_NOT_PAID
]

export default function getAdvertState(advertEntity) {
	const { status, deactivationReason, approvalAdmin } = advertEntity

	let advertState = status

	// nektere stavy maji moznost podrobnejsiho cleneni
	if (advertState === ADVERT_STATE.INACTIVE) {
		switch (deactivationReason) {
			case DEACTIVATION_REASON.EXPIRED:
				advertState = ADVERT_STATE.INACTIVE_EXPIRED
				break
			case DEACTIVATION_REASON.IMAGES:
				advertState = ADVERT_STATE.INACTIVE_INSUFFICIENT_IMAGES
				break
			case DEACTIVATION_REASON.VIN_DUPLICATION:
				advertState = ADVERT_STATE.INACTIVE_VIN_DUPLICATION
				break
			case DEACTIVATION_REASON.VIN_DUPLICATION_FRAUD:
				advertState = ADVERT_STATE.INACTIVE_VIN_DUPLICATION_FRAUD
				break
			case DEACTIVATION_REASON.NOT_PAID:
				advertState = ADVERT_STATE.INACTIVE_NOT_PAID
				break
			case DEACTIVATION_REASON.MODULES:
				advertState = ADVERT_STATE.INACTIVE_INSUFFICIENT_MODULES
				break
			case DEACTIVATION_REASON.USER:
				advertState = ADVERT_STATE.INACTIVE_USER_DEACTIVATED
				break
			case DEACTIVATION_REASON.ADMIN:
				advertState = ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED
				if (approvalAdmin === APPROVAL_ADMIN.REJECTED) {
					advertState = ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED
				}
				break
		}
	} else if (advertState === ADVERT_STATE.DISABLED) {
		if (approvalAdmin === APPROVAL_ADMIN.REJECTED) {
			advertState = ADVERT_STATE.DISABLED_REJECTED
		}
	}

	// soft ban (inzerat zneaktiveny adminem) a hard ban (inzerat zakazany adminem)
	// maji vyssi prioritu nez ostatni dilci stavy. Tzn. pokud uzivatel dostane napr. soft
	// ban a nejakym zpusobem by si inzerat i sam vypnul (uz by se nemelo stavat), tak
	// porad chceme, aby se inzerat choval jako by mel soft ban, ne jako by ho vypnul
	// uzivatel.
	if (approvalAdmin === APPROVAL_ADMIN.REJECTED) {
		if (status === ADVERT_STATE.DISABLED) {
			advertState = ADVERT_STATE.DISABLED_REJECTED
		} else if (status === ADVERT_STATE.INACTIVE) {
			advertState = ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED
		}
	}

	return advertState
}
