import DEFAULT_OBJECT_STORES, { HOMEPAGE_OBJECT_STORE } from 'app/helpers/cache/cacheConstants'

const ADVERT_ID = {
	NEW_ID: 'id',
	OLD_ID: 'oldId'
}

const HOMEPAGE_ID = {
	VEHICLE_BODY_NAME: 'vehicleBodyName'
}

const LIST_TYPE = {
	REGULAR: 'regular',
	REGULAR_WITH_OPERATING_LEASES: 'regularWithOperatingLeases',
	SELLER: 'seller',
	SELLER_WITH_OPERATING_LEASES: 'sellerWithOperatingLeases',
	HOMEPAGE: 'homepage'
}

const LIST_CACHE_CONSTANTS = {
	[LIST_TYPE.REGULAR]: {
		LIST_KEY: DEFAULT_OBJECT_STORES.ADVERT_LIST.KEY_PATH,
		ADVERT_LIST: DEFAULT_OBJECT_STORES.ADVERT_LIST.NAME,
		PAGINATION: DEFAULT_OBJECT_STORES.PAGINATION.NAME
	},
	[LIST_TYPE.REGULAR_WITH_OPERATING_LEASES]: {
		LIST_KEY: DEFAULT_OBJECT_STORES.ADVERT_LIST_WITH_OPERATING_LEASES.KEY_PATH,
		ADVERT_LIST: DEFAULT_OBJECT_STORES.ADVERT_LIST_WITH_OPERATING_LEASES.NAME,
		PAGINATION: DEFAULT_OBJECT_STORES.PAGINATION_WITH_OPERATING_LEASES.NAME
	},
	[LIST_TYPE.SELLER]: {
		LIST_KEY: DEFAULT_OBJECT_STORES.SELLER_LIST.KEY_PATH,
		ADVERT_LIST: DEFAULT_OBJECT_STORES.SELLER_LIST.NAME,
		PAGINATION: DEFAULT_OBJECT_STORES.SELLER_PAGINATION.NAME
	},
	[LIST_TYPE.SELLER_WITH_OPERATING_LEASES]: {
		LIST_KEY: DEFAULT_OBJECT_STORES.SELLER_LIST_WITH_OPERATING_LEASES.KEY_PATH,
		ADVERT_LIST: DEFAULT_OBJECT_STORES.SELLER_LIST_WITH_OPERATING_LEASES.NAME,
		PAGINATION: DEFAULT_OBJECT_STORES.SELLER_PAGINATION_WITH_OPERATING_LEASES.NAME
	},
	[LIST_TYPE.HOMEPAGE]: {
		LIST_KEY: HOMEPAGE_OBJECT_STORE.HOMEPAGE_LIST.KEY_PATH,
		ADVERT_LIST: HOMEPAGE_OBJECT_STORE.HOMEPAGE_LIST.NAME,
		PAGINATION: HOMEPAGE_OBJECT_STORE.HOMEPAGE_PAGINATION.NAME
	}
}

// Stavy inzerátu.
const STATUS_CONSTANTS = {
	DRAFT: 'draft', // draft je tu jen pro uplnost, jedna se o interni stav na BE
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	DISABLED: 'disabled',
	DELETED: 'deleted'
}

// Duvody deaktivace inzeratu.
// Tyka se pouze inzeratu se stavem STATUS_CONSTANTS.INACTIVE.
const DEACTIVATION_REASON = {
	USER: 'user_deactivated',
	ADMIN: 'admin_deactivated',
	EXPIRED: 'advert_expired',
	IMAGES: 'insufficient_images_count',
	NOT_PAID: 'not_paid',
	MODULES: 'insufficient_modules_count',
	VIN_DUPLICATION: 'vin_duplication',
	VIN_DUPLICATION_FRAUD: 'vin_duplication_fraud'
}

/**
 * Možné důvody zakázání inzerátu adminem.
 */
const FORBID_REASONS = Object.freeze({
	SUSPICIOUS: 'suspicious_advert',
	WRONG_CATEGORY: 'wrong_category',
	FALSE_INFO: 'false_info',
	BAD_PHOTOS: 'bad_photos',
	OTHER: 'other'
})

/**
 * Stavy schválení adminem.
 */
const APPROVAL_ADMIN = Object.freeze({
	REJECTED: 'rejected' // Zablokováno adminem.
})

/**
 * Typy prodeje.
 */
const DEAL_TYPE = Object.freeze({
	SALE: 'sale', // normalni prodej
	OPERATING_LEASE: 'operating_lease',
	SALE_OR_LEASE: 'sale_or_lease'
})

export {
	ADVERT_ID,
	HOMEPAGE_ID,
	LIST_TYPE,
	LIST_CACHE_CONSTANTS,
	STATUS_CONSTANTS,
	DEACTIVATION_REASON,
	FORBID_REASONS,
	APPROVAL_ADMIN,
	DEAL_TYPE
}
