import { AbstractFactory } from '@inzeraty/models'
import RibbonSearchDetectedFiltersEntity from './RibbonSearchDetectedFiltersEntity'

export default class RibbonSearchDetectedFiltersFactory extends AbstractFactory {
	constructor() {
		super(RibbonSearchDetectedFiltersEntity)
	}

	transformData(data = {}) {
		return {
			filterName: data.filter_name,
			isCodebook: data.is_codebook,
			options: data.options,
			valueFrom: data.value_from,
			valueTo: data.value_to,
			value: data.value,
			values: data.values
		}
	}
}
