
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Cebia'] = {
  titleVerified: function(d) { return "Prověření historie Cebia je připojeno"; },
  titleCreateCertificate: function(d) { return "Připojte prověření historie Cebia"; },
  text: function(d) { return "Zvyšte důvěryhodnost svého inzerátu a úspěšnost prodeje vozidla připojením základního prověření historie Cebia. Zájemcům o váš vůz tak umožníte bezplatný přístup k výsledkům kontroly najetých kilometrů, kontroly odcizení, kontroly provozu jako taxi a mnoha dalším důležitým informacím."; },
  moreTitle: function(d) { return "Připojení prověření Cebia"; },
  "for": function(d) { return "Pro návštěvníky Sauto.cz"; },
  sale: function(d) { return "Sleva 100 " + d.CURRENCY; },
  btnMore: function(d) { return "Chci zjistit více"; },
  btnAddCert: function(d) { return "Vložit číslo kupónu"; },
  modalTitle: function(d) { return "Připojení prověření Cebia"; },
  modalMoreTitle: function(d) { return "Získání prověření historie vozidla"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	