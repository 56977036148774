import React, { useCallback } from 'react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DateRange } from '@inzeraty/components'

const RangeDateWidget = ({
	formLineEntityDateFrom = DEFAULT_PROPS.OBJECT,
	formLineEntityDateTo = DEFAULT_PROPS.OBJECT,
	changeFormLineEntities = DEFAULT_PROPS.FUNCTION
}) => {
	const onChange = useCallback(
		([valueFrom, valueTo]) => {
			changeFormLineEntities([
				{ id: formLineEntityDateFrom.id, value: valueFrom },
				{ id: formLineEntityDateTo.id, value: valueTo }
			])
		},
		[changeFormLineEntities]
	)

	return (
		<DateRange
			desktopMonths={1}
			position='topLeft'
			single
			onChange={onChange}
			fromInputProps={{
				name: formLineEntityDateFrom.id,
				value: formLineEntityDateFrom.value,
				label: formLineEntityDateFrom.label,
				placeholder: formLineEntityDateFrom.placeholder,
				'data-dot': 'date-from'
			}}
			toInputProps={{
				name: formLineEntityDateTo.id,
				value: formLineEntityDateTo.value,
				label: formLineEntityDateTo.label,
				placeholder: formLineEntityDateTo.placeholder,
				'data-dot': 'date-to'
			}}
		/>
	)
}

RangeDateWidget.propTypes = {
	changeFormLineEntities: PropTypes.func,
	formLineEntityDateTo: PropTypes.instanceOf(FormLines.Entity),
	formLineEntityDateFrom: PropTypes.instanceOf(FormLines.Entity)
}

export default RangeDateWidget
