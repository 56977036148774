import deepEqual from 'fast-deep-equal'

export default class RibbonSearchService {
	constructor(ribbonSearchResource, ribbonSearchFactory, ribbonSearchDetectedFiltersFactory) {
		this._ribbonSearchResource = ribbonSearchResource
		this._ribbonSearchFactory = ribbonSearchFactory
		this._ribbonSearchDetectedFiltersFactory = ribbonSearchDetectedFiltersFactory
	}

	async getSuggest(params = {}, options = {}) {
		this._latestSuggestParams = params

		const { body: { results = [] } = {} } = await this._ribbonSearchResource.getSuggest(params)

		const suggestData = results.map((suggest) => this._ribbonSearchFactory.transformData(suggest))

		if (!deepEqual(this._latestSuggestParams, params)) {
			throw new Error('It is a delayed response.')
		} else {
			return this._ribbonSearchFactory.createEntityList(suggestData)
		}
	}

	async getDetectedFilters(params = {}, options = {}) {
		const {
			body: { detected_filters = [] } = {}
		} = await this._ribbonSearchResource.getDetectedFilters(params)

		const detectedFiltersData = detected_filters.map((filter) =>
			this._ribbonSearchDetectedFiltersFactory.transformData(filter)
		)

		return this._ribbonSearchDetectedFiltersFactory.createEntityList(detectedFiltersData)
	}
}
