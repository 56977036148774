import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import Select from 'app/component/select/Select'
import SearchInput from 'app/component/searchInput/SearchInput'
import ClientStatisticsUrlConvertor from '../../ClientStatisticsUrlConvertor'

import './Search.less'
import 'app/page/userweb/clientAdvertList/listHeader/ListHeaderCS.json'

const CLASSNAME = 'c-stat-search'

const Search = ({ initiallySelectedItem, initialInputValue }) => {
	const localize = useLocalize()
	const fire = useFire()

	const { FILTER_OPTIONS } = ClientStatisticsUrlConvertor.constants

	const filterByItems = [
		{
			name: localize('ListHeader.filterById'),
			id: FILTER_OPTIONS.ADVERT_ID
		},
		{
			name: localize('ListHeader.filterByVin'),
			id: FILTER_OPTIONS.VIN
		},
		{
			name: localize('ListHeader.filterByImportId'),
			id: FILTER_OPTIONS.IMPORT_ID
		}
	]

	const getDefaultFilterById = (initiallySelectedItem) => {
		const selectedItem =
			filterByItems.find((item) => item.id === initiallySelectedItem) || filterByItems[0]

		return selectedItem ? selectedItem.id : undefined
	}

	const [filterBy, setFilterBy] = useState(getDefaultFilterById(initiallySelectedItem))

	useEffect(() => {
		setFilterBy(getDefaultFilterById(initiallySelectedItem))
	}, [initiallySelectedItem])

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__select-box`}>
				<Select
					onSelect={(selectedItem = {}) => setFilterBy(selectedItem.id)}
					selectedItem={filterByItems.find((item) => item.id === filterBy) || null}
					items={filterByItems}
					size='small'
				/>
			</div>
			<div className={`${CLASSNAME}__search-input`}>
				<SearchInput
					inputValue={initialInputValue || ''}
					onSubmit={(filterByValue) =>
						fire('selectFilterSubmit', {
							filterBy,
							filterByValue
						})
					}
				/>
			</div>
		</div>
	)
}

Search.propTypes = {
	initiallySelectedItem: PropTypes.string,
	initialInputValue: PropTypes.string
}

export default React.memo(Search)
