import React from 'react'
import PropTypes from 'prop-types'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import ROUTE_NAMES from 'app/base/RouteNames'
import { useLocalize, useRouter, useRouteName } from 'app/base/componentHelpers'
import LinkArrow, { ARROW_TYPE } from 'app/component/linkArrow/LinkArrowView'

import './SaleOperatingLeaseDetailSwitchCS.json'
import './SaleOperatingLeaseDetailSwitch.less'

const CLASSNAME = 'c-sale-oper-lease-detail-switch'

const SaleOperatingLeaseDetailSwitch = ({ dealType, showSwitchToOperatingLease = true }) => {
	const localize = useLocalize()
	const router = useRouter()
	const routeName = useRouteName()

	const isInternalAdminPreview = [
		ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_PREVIEW,
		ROUTE_NAMES.INTERNAL_ADMIN.OPERATING_LEASE_PREVIEW
	].includes(routeName)

	const { params: routeParams = {} } = router.getCurrentRouteInfo()

	const text = showSwitchToOperatingLease
		? localize('SaleOperatingLeaseDetailSwitch.descriptionAboutOperLeasing')
		: localize('SaleOperatingLeaseDetailSwitch.descriptionAboutSale')

	const redirectUrl = showSwitchToOperatingLease
		? router.link(
				isInternalAdminPreview
					? ROUTE_NAMES.INTERNAL_ADMIN.OPERATING_LEASE_PREVIEW
					: ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL,
				routeParams
		  )
		: router.link(
				isInternalAdminPreview
					? ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_PREVIEW
					: ROUTE_NAMES.USERWEB.ADVERT_DETAIL,
				routeParams
		  )

	return (
		dealType === DEAL_TYPE.SALE_OR_LEASE && (
			<div className={CLASSNAME}>
				<div className={`${CLASSNAME}__description`}>{text}</div>
				<LinkArrow className={`${CLASSNAME}__link`} url={redirectUrl} type={ARROW_TYPE.RIGHT}>
					{localize('SaleOperatingLeaseDetailSwitch.link')}
				</LinkArrow>
			</div>
		)
	)
}

SaleOperatingLeaseDetailSwitch.propTypes = {
	dealType: PropTypes.string.isRequired,
	showSwitchToOperatingLease: PropTypes.bool
}

export default SaleOperatingLeaseDetailSwitch
