import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import ResetFilter from '../resetFilter/ResetFilterView'
import Back from '../back/BackView'
import { Popup } from '@inzeraty/components'
import FilterConstants from 'app/model/filter/FilterConstants'

import './FilterPopup.less'
import './FilterPopupCS.json'

const CLASSNAME = 'cf-filter-popup'

export default class FilterPopup extends AbstractComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			wrapperClassName: PropTypes.string,
			filterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			title: PropTypes.string,
			resetButtonText: PropTypes.string,
			defaultType: PropTypes.bool,
			renderHeaderContent: PropTypes.func,
			renderHeaderContentSection: PropTypes.func,
			onClose: PropTypes.func,
			onReset: PropTypes.func,
			contentRef: PropTypes.shape({
				current: PropTypes.object
			}),
			hasStickyHeader: PropTypes.bool,
			hasLightbox: PropTypes.bool,
			children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
		}
	}

	static get defaultProps() {
		return {
			className: '',
			wrapperClassName: '',
			filterId: '',
			title: '',
			resetButtonText: undefined,
			defaultType: true,
			hasStickyHeader: true,
			onClose: () => {},
			onReset: () => {}
		}
	}

	constructor(props, context) {
		super(props, context)

		this._dictionary = {
			close: this.localize('FilterPopup.close')
		}

		this._renderHeaderContent = this._renderHeaderContent.bind(this)

		this._onClose = this._onClose.bind(this)
		this._onReset = this._onReset.bind(this)
	}

	render() {
		const {
			className,
			wrapperClassName,
			title,
			children,
			contentRef,
			filterId,
			hasStickyHeader,
			hasLightbox
		} = this.props

		const dataDot = {
			close: 'close'
		}

		let dataDotData = {}

		const extendFilterId = FilterConstants.filterIds.EXTENDED_FILTER_ID

		if (filterId === extendFilterId) {
			dataDot.form = 'filters-all'
		} else {
			dataDot.form = 'filters-values'
			dataDotData = {
				dataDotData: `{"type": "${filterId}"}`
			}
		}

		const popupClassNames = wrapperClassName ? { className: wrapperClassName } : {}

		return (
			<Popup
				{...popupClassNames}
				isOpen={true}
				onClose={this._onClose}
				title={title}
				dictionary={this._dictionary}
				renderHeaderContent={this._renderHeaderContent}
				contentRef={contentRef}
				stickyHeader={hasStickyHeader}
				hasLightbox={hasLightbox}
				dataDot={dataDot}
				{...dataDotData}
			>
				<div
					className={this.cssClasses({
						[`${CLASSNAME}__content`]: true,
						[className]: !!className
					})}
				>
					{children}
				</div>
			</Popup>
		)
	}

	_renderHeaderContent(renderCloseButton, renderTitle) {
		const {
			title,
			resetButtonText,
			defaultType,
			renderHeaderContent,
			renderHeaderContentSection,
			headerDataDotData
		} = this.props

		const headerProps = {}

		if (headerDataDotData) {
			headerProps['data-dot-data'] = headerDataDotData
		}

		const renderBackButton = () => <Back label={title} onClick={this._onClose} />

		const renderResetButton = () => <ResetFilter text={resetButtonText} onClick={this._onReset} />

		if (renderHeaderContent) {
			return (
				<div {...headerProps}>
					{renderHeaderContent({
						isPopupDefaultType: defaultType,
						renderBackButton,
						renderTitle,
						renderResetButton,
						renderCloseButton
					})}
				</div>
			)
		}

		if (defaultType) {
			return (
				<div {...headerProps}>
					{renderTitle()}
					{renderCloseButton()}

					{renderHeaderContentSection
						? renderHeaderContentSection(this._onClose, this._onReset)
						: renderResetButton()}
				</div>
			)
		} else {
			return (
				<div {...headerProps}>
					{renderBackButton()}

					{renderHeaderContentSection
						? renderHeaderContentSection(this._onClose, this._onReset)
						: renderResetButton()}
				</div>
			)
		}
	}

	_onClose() {
		const { filterId, onClose } = this.props

		onClose(filterId)
	}

	_onReset() {
		const { filterId, onReset } = this.props

		onReset(filterId)
	}
}
