
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			var cs = function(n, ord
) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one'
      : ((i >= 2 && i <= 4) && v0) ? 'few'
      : (!v0) ? 'many'
      : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

$IMA.i18n['RangeInputOpener'] = {
  From: function(d) { return "Od"; },
  to: function(d) { return "do"; },
  To: function(d) { return "Do"; },
  airbags: function(d) { return plural(d.AIRBAGS_COUNT, 0, cs, { one: "airbagu", other: "airbagů" }); },
  airbagsSameValue: function(d) { return plural(d.AIRBAGS_COUNT, 0, cs, { one: "airbag", few: "airbagy", other: "airbagů" }); },
  capacity: function(d) { return plural(d.CAPACITY_COUNT, 0, cs, { one: "místa", other: "míst" }); },
  capacitySameValue: function(d) { return plural(d.CAPACITY_COUNT, 0, cs, { one: "místo", few: "místa", other: "míst" }); },
  doors: function(d) { return "dveří"; },
  doorsSameValue: function(d) { return plural(d.DOORS_COUNT, 0, cs, { one: "dveře", few: "dveře", other: "dveří" }); },
  weight: function(d) { return "kg"; },
  beds: function(d) { return plural(d.BEDS_COUNT, 0, cs, { few: "lůžka", other: "lůžek" }); },
  machineHours: function(d) { return "hod."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	