import React, { useContext, useEffect } from 'react'
import ImaContext from 'ima/page/context'
import SspHelper from 'app/helpers/sspHelper/SspHelper'
import { RESPONSIVE } from 'app/base/Constants'
import SspCollocationOptions from 'app/helpers/sspCollocation/SspCollocationOptions'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import SspContext from '../SspContext'

const CLASSNAME = 'c-ssp-calculator'

const ELEMENT_ID_DESKTOP = 'calc-desktop'
const ELEMENT_ID_MOBILE = 'calc-mobile'

const ZONE_ID_DESKTOP = 49744
const ZONE_ID_MOBILE = 242786

import './Calculator.less'

const Calculator = () => {
	const imaContext = useContext(ImaContext)
	const sspContextData = useContext(SspContext)
	const userEntity = useContext(UserEntityContext)

	const sspHelper = imaContext.SspHelper

	// priznak, zda si uzivatel plati "Seznam bez reklam" (SBR)
	const isSbrUser = userEntity?.isSbr

	const getAdForSsp = (pageData) => {
		const cleanUp = (domElement) => {
			if (domElement) {
				sspHelper.cleanElement(domElement)
				domElement.parentElement &&
					domElement.parentElement.classList &&
					domElement.parentElement.classList.remove(SspHelper.adFullClassName)
				domElement.classList && domElement.classList.remove(SspHelper.adFullClassName)
			}
		}

		const desktopElement = document.getElementById(ELEMENT_ID_DESKTOP)
		const mobileElement = document.getElementById(ELEMENT_ID_MOBILE)

		cleanUp(desktopElement)
		cleanUp(mobileElement)

		const { matches: matchesDesktop } = window.matchMedia(
			`(min-width: ${RESPONSIVE.TABLET / 16}em)`
		)

		const [elementId, element, zoneId, size] = matchesDesktop
			? [ELEMENT_ID_DESKTOP, desktopElement, ZONE_ID_DESKTOP, { width: 712, height: 250 }]
			: [ELEMENT_ID_MOBILE, mobileElement, ZONE_ID_MOBILE, { width: 300, height: 300 }]

		const options = SspCollocationOptions.getOptions(pageData)

		if (element && elementId && size && options) {
			const additionalOptions = matchesDesktop
				? {
						minWidth: 712
				  }
				: {}

			sspHelper.getAds([
				{
					id: elementId,
					zoneId,
					width: size.width,
					height: size.height,
					options,
					...additionalOptions,
					callback: (advert, zone) => {
						if (advert.type !== 'empty' && element) {
							element.parentElement &&
								element.parentElement.classList &&
								element.parentElement.classList.add(SspHelper.adFullClassName)
						}
						sspHelper.writeAd(advert, zone)
					}
				}
			])
		}
	}

	useEffect(() => {
		getAdForSsp(sspContextData)
	}, [sspContextData])

	if (isSbrUser) return null

	return (
		<div className={`${CLASSNAME} ${CLASSNAME}__desktop`}>
			<div className={`${CLASSNAME}__in`} id={ELEMENT_ID_DESKTOP} />
		</div>
	)
}

const CalculatorMobileRenderer = () => {
	const userEntity = useContext(UserEntityContext)

	// priznak, zda si uzivatel plati "Seznam bez reklam" (SBR)
	const isSbrUser = userEntity?.isSbr

	if (isSbrUser) return null

	return (
		<div className={`${CLASSNAME} ${CLASSNAME}__mobile`}>
			<div className={`${CLASSNAME}__in`} id={ELEMENT_ID_MOBILE} />
		</div>
	)
}

Calculator.MobileRenderer = CalculatorMobileRenderer

export default Calculator
