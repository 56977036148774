import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocalize } from 'app/base/componentHelpers'
import Layout from 'app/component/layout/LayoutView'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'
import { Button } from '@sznds/react'
import MySavedSearchList from './mySavedSearchList/MySavedSearchList'
import { STATE_KEYS } from './MySavedSearchController'
import UserwebBaseController from 'app/base/UserwebBaseController'
import PageTitle from 'app/component/pageTitle/PageTitle'
import MySavedSearchPropTypes from 'app/model/mySavedSearch/MySavedSearchPropTypes'
import { DefaultProps, Format } from '@inzeraty/helpers'

import 'app/base/BaseCS.json'
import './MySavedSearchCS.json'
import './MySavedSearchView.less'

const CLASSNAME = 'p-uw-client-my-saved-search'

const MySavedSearchListEmpty = () => {
	const localize = useLocalize()

	return (
		<div className={`${CLASSNAME}__empty`}>
			<div className={`${CLASSNAME}__empty-header`}>{localize('MySavedSearch.noResultsTitle')}</div>
			<div className={`${CLASSNAME}__empty-text`}>{localize('MySavedSearch.noResultsText')}</div>
			<Button
				className={`${CLASSNAME}__empty-button`}
				primary={true}
				href='/'
				text={localize('Base.backToHp')}
			/>
		</div>
	)
}

const MySavedSearchListView = ({
	[STATE_KEYS.MY_SAVED_SEARCHES]: mySavedSearchEntities,
	[STATE_KEYS.IS_LOADING]: isLoading,
	[STATE_KEYS.GET_MY_SAVED_SEARCH]: getMySavedSearch,
	[STATE_KEYS.UPDATE_MY_SAVED_SEARCH]: updateMySavedSearch,
	[STATE_KEYS.SHOW_TOAST_MESSAGE]: showToastMessage,
	[UserwebBaseController.STATE_KEYS.HTTP_STATUS]: httpStatus
}) => {
	const localize = useLocalize()

	const hasResults = isLoading || (mySavedSearchEntities && mySavedSearchEntities.length > 0)

	return (
		<Layout className={CLASSNAME} httpStatus={httpStatus}>
			<div className={`${CLASSNAME}__content`}>
				{hasResults && (
					<>
						<BackToHpButton />
						<PageTitle className={`${CLASSNAME}__header`}>
							{localize('MySavedSearch.pageTitle')}
						</PageTitle>

						<div
							className={classnames({
								[`${CLASSNAME}__count`]: true,
								[`${CLASSNAME}__count--plh`]: isLoading
							})}
						>
							{!isLoading &&
								mySavedSearchEntities &&
								localize('MySavedSearch.count', {
									COUNT: Format.number(mySavedSearchEntities.length)
								})}
						</div>

						<MySavedSearchList
							mySavedSearchEntities={mySavedSearchEntities}
							isLoading={isLoading}
							getMySavedSearch={getMySavedSearch}
							updateMySavedSearch={updateMySavedSearch}
							showToastMessage={showToastMessage}
						/>
					</>
				)}
				{!hasResults && <MySavedSearchListEmpty />}
			</div>
		</Layout>
	)
}

MySavedSearchListView.propTypes = {
	[STATE_KEYS.MY_SAVED_SEARCHES]: PropTypes.arrayOf(PropTypes.shape(MySavedSearchPropTypes)),
	[STATE_KEYS.IS_LOADING]: PropTypes.bool,
	[STATE_KEYS.GET_MY_SAVED_SEARCH]: PropTypes.func,
	[STATE_KEYS.UPDATE_MY_SAVED_SEARCH]: PropTypes.func,
	[STATE_KEYS.SHOW_TOAST_MESSAGE]: PropTypes.func
}

MySavedSearchListView.defaultProps = {
	[STATE_KEYS.MY_SAVED_SEARCHES]: DefaultProps.ARRAY,
	[STATE_KEYS.IS_LOADING]: false,
	[STATE_KEYS.GET_MY_SAVED_SEARCH]: DefaultProps.FUNCTION,
	[STATE_KEYS.UPDATE_MY_SAVED_SEARCH]: DefaultProps.FUNCTION,
	[STATE_KEYS.SHOW_TOAST_MESSAGE]: DefaultProps.FUNCTION
}

export default MySavedSearchListView
