import { RestClient, HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import WEB_TYPE from 'app/base/WebType'
import ROUTE_NAMES from 'app/base/RouteNames'

/**
 * Pomocná třída pro práci se zdroji dat dostupnými skrz REST API.
 * @class Controller
 * @namespace app.base
 * @module app
 * @submodule app.base
 */
export default class SautoRestClient extends RestClient {
	/**
	 * @constructor
	 * @method constructor
	 * @param {Object}                   $Settings Settings aplikace (app/config/settings.js)
	 * @param {Core.Interface.HttpAgent} $Http
	 * @param {Core.Router.Request}      $Request
	 * @param {Core.Window.Window}       $Window
	 * @param {Core.Interface.Cache}	 $Cache
	 * @param {ima.Http.CookieStorage} 	 cookieStorage
	 */

	constructor($Settings, $Http, $Request, $Window, $Cache, $UserAgent, $Router, cookieStorage) {
		super($Settings, $Http, $Request, $Window, $Cache, $UserAgent, $Router, cookieStorage)

		this._settings = $Settings
		this._router = $Router
		this._cookieStorage = cookieStorage
	}

	/**
	 * Provede GET požadavek na resource.
	 *
	 * @method get
	 * @public
	 * @param {String} resource Název zdroje v REST API
	 * @param {Object} data     Data (parametry) dotazu
	 * @param {Object} options  Optiony dotazu
	 * @return {Promise<JSONResource>} Promise jehož hodnotou jsou data resource.
	 */
	get(resource, data = {}, options = {}) {
		const { webType } = this.getRouteOptions()

		let defaultOptions = {}

		if (webType === WEB_TYPE.INTERNAL_ADMIN) {
			defaultOptions = {
				cache: false, // pro interni admin vypneme defaultni cachovani vysledku
				timeout: 30000
			}
		}

		return super.get(resource, data, Object.assign(defaultOptions, options))
	}

	/**
	 * Provede DELETE požadavek na resource.
	 *
	 * @method delete
	 * @public
	 * @param {String} resource Název zdroje v REST API
	 * @param {Object} data     Data (parametry) dotazu
	 * @param {Object} options  Optiony dotazu
	 * @return {Promise<JSONResource>} Promise jehož hodnotou jsou data resource.
	 */
	delete(resource, data = {}, options = {}) {
		const { webType } = this.getRouteOptions()

		let defaultOptions = {}

		if (webType === WEB_TYPE.INTERNAL_ADMIN) {
			defaultOptions = {
				cache: false, // pro interni admin vypneme defaultni cachovani
				timeout: 30000
			}
		}

		return super.delete(resource, data, Object.assign(defaultOptions, options))
	}

	_handleResponseError(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)
		const { webType } = this.getRouteOptions()
		const { [UrlConvertor.constants.URL_APP_PARAMS.CODE]: code } = this.getRouteParams()

		if (httpStatus === HttpStatusCodes.UNAUTHORIZED) {
			if (webType === WEB_TYPE.INTERNAL_ADMIN && !code) {
				// pokud doslo k 401 chybe v IA, tak provedeme presmerovani na seznamacky
				// auth server, at se admin muze prihlasit.
				// Kontrola na 'code' je tu pro pripad, ze by doslo k nejake chybe se samotnym
				// prihlasenim, tak aby nedochazelo k zacykleni mezi webovkou a auth serverem.
				// Parametr 'code' totiz nastavuje auth server pri presmerovani zpet na sluzbu.
				const redirectUri = this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST)
				const clientId = this._settings.$App.adminAuth.clientId
				const state = {
					redirectUrl: this._router.getUrl()
				}

				this.adminAuthLogin(
					error,
					`${this._settings.$App.adminAuth.host}/oauth2/authorize/`,
					redirectUri,
					clientId,
					state
				)
			}
		} else if (httpStatus === HttpStatusCodes.FORBIDDEN) {
			if (webType === WEB_TYPE.INTERNAL_ADMIN) {
				// pokud admin dostane v internim adminu 403 - insufficient_rights, tak bude presmerovany na stranku -> nemate dostatecny pristup
				const errorParams = HttpErrorHelper.getParams(error)
				if (
					errorParams &&
					['insufficient_rights', 'no_such_admin', 'admin_disabled'].indexOf(
						errorParams.body.errors[0].error_code
					) !== -1
				) {
					this._router.redirect(this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.FORBIDDEN_ACCESS))
				}
			}
		}

		return Promise.reject(error)
	}

	/**
	 * Vrátí vlastnosti aktuální routy
	 *
	 * @method getRouteOptions
	 * @public
	 * @return {Object}
	 */
	getRouteOptions() {
		const { route } = UrlConvertor.getRouteInfo(this._router)

		if (route) {
			return route.getOptions()
		} else {
			return {}
		}
	}

	/**
	 * Vrati parametry aktualni routy.
	 *
	 * @method getRouteParams
	 * @public
	 * @return {Object}
	 */
	getRouteParams() {
		const { params } = UrlConvertor.getRouteInfo(this._router)

		if (params) {
			return params
		} else {
			return {}
		}
	}
}
