import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Format } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { intendedForPropTypes } from 'app/model/operatingLease/OperatingLeasePropTypes'

import 'app/base/BaseCS.json'
import './OperatingLeaseDetailPrice.less'
import './OperatingLeaseDetailPriceCS.json'

const CLASSNAME = 'c-operating-lease-detail-price'

const formatIntendedFor = (intendedForList = []) => intendedForList.map((i) => i.name).join(', ')

const OperatingLeaseDetailPrice = ({
	isLoading = false,
	intendedFor = DEFAULT_PROPS.ARRAY,
	priceWithVat,
	priceWithoutVat
}) => {
	const localize = useLocalize()

	const intendedForPrinted = formatIntendedFor(intendedFor)

	return (
		<>
			<div className={`${CLASSNAME}__price`}>
				{isLoading ? (
					<div className={`${CLASSNAME}__price--placeholder`} />
				) : (
					priceWithoutVat > 0 && (
						<div
							className={`notranslate ${CLASSNAME}__price`}
							translate='no'
							lang='cs-cz'
						>{`${Format.number(priceWithoutVat)}${localize('OperatingLeaseDetailPrice.priceNoVat', {
							CURRENCY: localize('Base.CZK')
						})}`}</div>
					)
				)}
			</div>

			{!isLoading && priceWithVat > 0 && (
				<div className='notranslate' translate='no' lang='cs-cz'>{`${Format.number(
					priceWithVat
				)}${localize('OperatingLeaseDetailPrice.price', { CURRENCY: localize('Base.CZK') })}`}</div>
			)}

			{isLoading ? (
				<div className={`${CLASSNAME}__intended-for`}>
					<div className={`${CLASSNAME}__intended-for--placeholder`} />
				</div>
			) : (
				!!intendedForPrinted && (
					<div className={`${CLASSNAME}__intended-for`}>
						<span>{localize('OperatingLeaseDetailPrice.intendedFor')}</span>
						{intendedForPrinted}
					</div>
				)
			)}
		</>
	)
}

OperatingLeaseDetailPrice.propTypes = {
	isLoading: PropTypes.bool,
	intendedFor: PropTypes.arrayOf(PropTypes.shape(intendedForPropTypes)),
	priceWithVat: PropTypes.number,
	priceWithoutVat: PropTypes.number
}

export default OperatingLeaseDetailPrice
