import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import { Surface } from '@sznds/react'
import CarDetailsSection from './carDetailsSection/CarDetailsSection'
import select from 'ima-plugin-select'
import STATE_KEYS from 'app/page/userweb/advertDetail/AdvertDetailStateKeys'

import './CarDetails.less'
import './CarDetailsCS.json'

const CLASSNAME = 'c-car-details'

class CarDetails extends AbstractComponent {
	static get propTypes() {
		return {
			advertEntity: PropTypes.shape(advertPropTypes),
			className: PropTypes.string
		}
	}

	constructor(props, context) {
		super(props, context)
	}

	render() {
		const { className } = this.props

		return (
			<Surface tagName='div' surface={5} className={`${className} ${CLASSNAME}`}>
				<h2 className={`${CLASSNAME}__title`}>{this.localize('CarDetails.title')}</h2>
				{this._renderContent()}
			</Surface>
		)
	}

	_renderContent() {
		const { advertEntity = {} } = this.props
		const { status } = advertEntity

		if (status) {
			return <CarDetailsSection advertEntity={advertEntity} />
		} else {
			return this._renderPlaceholder()
		}
	}

	_renderPlaceholder() {
		return (
			<div className={`${CLASSNAME}__placeholder`}>
				<div className={`${CLASSNAME}__placeholder-title`}></div>
				{this._renderPlaholderRows(3)}
				<div className={`${CLASSNAME}__placeholder-title`}></div>
				{this._renderPlaholderRows(4)}
			</div>
		)
	}

	_renderPlaholderRows(count) {
		const arr = [...Array(count).keys()]

		return arr.map((item) => (
			<div key={item} className={`${CLASSNAME}__placeholder-row`}>
				<div className={`${CLASSNAME}__placeholder-name`}></div>
				<div className={`${CLASSNAME}__placeholder-value`}></div>
			</div>
		))
	}
}

const CarDetailsSelector = (state) => ({
	advertEntity: state[STATE_KEYS.ADVERT_ENTITY]
})

export default select(CarDetailsSelector)(CarDetails)
