import React from 'react'
import Select from 'app/component/select/Select'
import IMAGES from 'app/base/ImagesConstants'

import './CategorySelect.less'

const CLASSNAME = 'c-category-select'

const CategorySelect = (props) => {
	const renderItem = (item) => {
		if (item) {
			const { name, seoName } = item

			const iconSrc = IMAGES[`image__categories__${seoName}`]

			return (
				<div className={`${CLASSNAME}__item`}>
					<img src={iconSrc} alt='' className={`${CLASSNAME}__icon`} />
					<span>{name}</span>
				</div>
			)
		}
	}

	return <Select {...props} renderItem={renderItem} />
}

export default React.memo(CategorySelect)
