import { TimestampHelper, CacheStorage } from '@inzeraty/helpers'
import DEFAULT_OBJECT_STORES, { HOMEPAGE_OBJECT_STORE } from './cacheConstants'

export default class CacheHelper {
	constructor() {
		this._timestampHelper = new TimestampHelper()

		/**
		 * Konfigurace IndexedDB
		 *
		 * Pro zvyseni verze (a nasledne pridani / odebirani tabulek) pouzit navod:
		 * https://developers.google.com/web/ilt/pwa/working-with-indexeddb#using_database_versioning
		 */
		this._cacheStorage = new CacheStorage({
			name: 'sauto-db',
			version: 4,
			upgradeCb: (upgradeDB) => {
				/* eslint-disable */
				switch (upgradeDB.oldVersion) {
					case 0:
						for (const objectStore in DEFAULT_OBJECT_STORES) {
							const { NAME, KEY_PATH, INDEXES = [] } = DEFAULT_OBJECT_STORES[objectStore]
							const store = upgradeDB.createObjectStore(NAME, {
								keyPath: KEY_PATH,
								autoIncrement: false
							})
							INDEXES.forEach((index) => store.createIndex(index, index))
						}

					case 1:
						for (const objectStore in HOMEPAGE_OBJECT_STORE) {
							const { NAME, KEY_PATH, INDEXES = [] } = HOMEPAGE_OBJECT_STORE[objectStore]
							const store = upgradeDB.createObjectStore(NAME, {
								keyPath: KEY_PATH,
								autoIncrement: false
							})
							INDEXES.forEach((index) => store.createIndex(index, index))
						}

					case 2:
					case 3:
						for (const objectStore in DEFAULT_OBJECT_STORES) {
							const { NAME, KEY_PATH, INDEXES = [] } = DEFAULT_OBJECT_STORES[objectStore]

							if (!upgradeDB.objectStoreNames.contains(NAME)) {
								const store = upgradeDB.createObjectStore(NAME, {
									keyPath: KEY_PATH,
									autoIncrement: false
								})
								INDEXES.forEach((index) => store.createIndex(index, index))
							}
						}
				}
				/* eslint-enable */
			}
		})

		this.resetCache()
	}

	/**
	 * Vrati aktualni timestamp pro vypis inzeratu
	 *
	 * @method getTimestamp
	 * @public
	 * @returns {Number}
	 */
	getTimestamp() {
		return this._timestampHelper.getTimestamp()
	}

	/**
	 * Vyresetuje timestamp a cache
	 *
	 * @method resetCache
	 * @public
	 * @returns {Promise}
	 */
	resetCache() {
		this._timestampHelper.resetTimestamp()
		return this._cacheStorage.clearAll()
	}

	/**
	 * Pridani polozky do tabulky
	 *
	 * @method addEntity
	 * @param {String} storeName
	 * @param {Object} value
	 * @public
	 * @returns {Promise}
	 */
	addEntity(storeName, value) {
		return this._cacheStorage.add(storeName, value)
	}

	/**
	 * Pridani polozek do tabulky
	 *
	 * @method addEntities
	 * @param {String} storeName
	 * @param {Array<Objects>} value
	 * @public
	 * @returns {Promise}
	 */
	addEntities(storeName, values) {
		return this._cacheStorage.add(storeName, values, true)
	}

	/**
	 * Smazani polozky z tabulky
	 *
	 * @method deleteEntity
	 * @param {String} storeName
	 * @param {String|Number} key
	 * @public
	 * @returns {Promise}
	 */
	deleteEntity(storeName, key) {
		return this._cacheStorage.delete(storeName, key)
	}

	/**
	 * Vycisteni tabulky
	 *
	 * @method deleteEntities
	 * @param {String} storeName
	 * @public
	 * @returns {Promise}
	 */
	deleteEntities(storeName) {
		return this._cacheStorage.clear(storeName)
	}

	/**
	 * Vrati data z tabulky dle klice
	 *
	 * @method getEntity
	 * @param {String} storeName
	 * @param {Object|Number|String} key
	 * @public
	 * @returns {Promise}
	 */
	getEntity(storeName, key = '') {
		const isPrimaryKey = typeof key !== 'object'
		return this._cacheStorage.get(storeName, key, isPrimaryKey)
	}

	/**
	 * V vrati bud' vsechna data z tabulky, anebo vyfiltruje dle indexu
	 *
	 * @method getEntities
	 * @param {String} storeName
	 * @param {Object} key
	 * @public
	 * @returns {Promise}
	 */
	getEntities(storeName, key = '') {
		const isPrimaryKey = typeof key !== 'object'
		return this._cacheStorage.getAll(storeName, key, isPrimaryKey)
	}

	/**
	 * Vrati pocet polozek v cele tabulce, nebo jeji podmozine
	 *
	 * @method countEntities
	 * @param {String} storeName
	 * @param {Object|String|Number} key
	 * @public
	 * @returns {Promise}
	 */
	countEntities(storeName, key = '') {
		const isPrimaryKey = typeof key !== 'object'
		return this._cacheStorage.count(storeName, key, isPrimaryKey)
	}
}
