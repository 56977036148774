import React from 'react'
import PropTypes from 'prop-types'
import BasicWidget from '../../../../component/widgets/basic/BasicWidget'
import * as FormLines from '@inzeraty/form-lines'
import BaseTextareaWidget, {
	TEXTAREA_WIDGET_ID
} from 'app/page/userweb/newAdvert/component/widgets/textarea/TextareaWidget'

export { TEXTAREA_WIDGET_ID }

const TextareaWidget = ({ formLineEntity, onChange }) => (
	<BasicWidget
		formLineEntity={formLineEntity}
		name={TEXTAREA_WIDGET_ID}
		renderInput={(formLineEntity, inputProps) => (
			<BaseTextareaWidget {...inputProps} formLineEntity={formLineEntity} onChange={onChange} />
		)}
	/>
)

TextareaWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default TextareaWidget
