import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import FilterConstants from 'app/model/filter/FilterConstants'
import BasicFilterPopup from './shared/BasicFilterPopup'
import FilterPopupButton from './shared/FilterPopupButton'
import PopupLogic from 'app/component/filters/logic/modals/PopupLogic'
import FilterPopupWithOpener from './shared/FilterPopupWithOpener'
import PopupOpenerLogic from 'app/component/filters/logic/openers/PopupOpenerLogic'
import ScopedFormLineEntity from 'app/component/filters/logic/ScopedFormLineEntity'
import RadioFilterLogic from 'app/component/filters/logic/RadioFilterLogic'
import RangeInputOpener from 'app/component/filters/forms/main/openers/rangeInputOpener/RangeInputOpener'
import AgeOpener from 'app/component/filters/forms/main/openers/ageOpener/AgeOpener'
import ItemAgeOpener from 'app/component/filters/forms/main/openers/itemAgeOpener/ItemAgeOpener'
import RadioInputForm from 'app/component/filters/forms/rangeInput/RangeInputForm'
import AgeForm from 'app/component/filters/forms/age/AgeForm'
import ItemAgeForm from 'app/component/filters/forms/itemAge/ItemAgeForm'

export default class RadioFilterPopupWithOpener extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			popupDefinition: PropTypes.object,
			context: PropTypes.string.isRequired,
			value: PropTypes.number, //TODO lepsi nazev?
			filterIndex: PropTypes.number,
			renderOpener: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderOpener = this._renderOpener.bind(this)
		this._renderPopup = this._renderPopup.bind(this)

		this._renderFilterForm = this._renderFilterForm.bind(this)
		this._renderButton = this._renderButton.bind(this)
	}

	render() {
		const { popupDefinition, context, value } = this.props

		return (
			<FilterPopupWithOpener
				popupDefinition={popupDefinition}
				context={context}
				value={value}
				renderOpener={this._renderOpener}
				renderPopup={this._renderPopup}
			/>
		)
	}

	_renderOpener() {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			context,
			filterIndex,
			renderOpener
		} = this.props
		const { id } = globalFormLineEntity

		const PopupOpener =
			id === FilterConstants.formLineIds.AGE
				? AgeOpener
				: id === FilterConstants.formLineIds.ITEM_AGE
				? ItemAgeOpener
				: RangeInputOpener

		return (
			<RadioFilterLogic
				formLineEntity={globalFormLineEntity}
				changeFilter={globalChangeFilter}
				context={context}
				isLastOptionCustomInput={id !== FilterConstants.formLineIds.ITEM_AGE}
			>
				{(filterLogicChildrenProps) => {
					const {
						form: { changeFilter }
					} = filterLogicChildrenProps

					return (
						<PopupOpenerLogic
							formLineEntity={globalFormLineEntity}
							changeFilter={changeFilter}
							context={context}
						>
							{(popupOpenerLogicData) => {
								const { openPopup, clearFilter } = popupOpenerLogicData

								return renderOpener ? (
									renderOpener(popupOpenerLogicData)
								) : (
									<PopupOpener
										formLineEntity={globalFormLineEntity}
										context={context}
										filterIndex={filterIndex}
										onOpen={openPopup}
										onResetFilter={clearFilter}
										isPopup={true}
									/>
								)
							}}
						</PopupOpenerLogic>
					)
				}}
			</RadioFilterLogic>
		)
	}

	_renderPopup() {
		const { formLineEntity: globalFormLineEntity, context } = this.props

		return (
			<ScopedFormLineEntity formLineEntity={globalFormLineEntity}>
				{(scopedFormLineEntityChildrenProps) => {
					const { localFormLineEntity, localChangeFilter } = scopedFormLineEntityChildrenProps

					return (
						<RadioFilterLogic
							formLineEntity={localFormLineEntity}
							changeFilter={localChangeFilter}
							context={context}
							isLastOptionCustomInput={
								localFormLineEntity.id !== FilterConstants.formLineIds.ITEM_AGE
							}
						>
							{(filterLogicChildrenProps) =>
								this._renderFilterPopup(scopedFormLineEntityChildrenProps, filterLogicChildrenProps)
							}
						</RadioFilterLogic>
					)
				}}
			</ScopedFormLineEntity>
		)
	}

	_renderFilterPopup(scopedFormLineEntityChildrenProps, filterLogicChildrenProps) {
		const { changeFilter: globalChangeFilter, popupDefinition, value } = this.props
		const { localFormLineEntity } = scopedFormLineEntityChildrenProps

		return (
			<PopupLogic
				formLineEntity={localFormLineEntity}
				changeFilter={globalChangeFilter}
				popupDefinition={popupDefinition}
				value={value}
				filterLogicData={filterLogicChildrenProps}
			>
				{(filterPopupLogicChildrenProps) => {
					return (
						<BasicFilterPopup
							formLineEntity={localFormLineEntity}
							title={localFormLineEntity.placeholder}
							value={value}
							filterPopupLogicData={filterPopupLogicChildrenProps}
							renderContent={(filterPopupLogicData) =>
								this._renderFilterForm(filterPopupLogicData, filterLogicChildrenProps)
							}
							renderFooter={(filterPopupLogicData) =>
								this._renderButton(filterPopupLogicData, filterLogicChildrenProps)
							}
						/>
					)
				}}
			</PopupLogic>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderFilterForm(filterPopupLogicChildrenProps, filterLogicChildrenProps) {
		const { popupDefinition = {} } = this.props
		const { filterButtonType } = popupDefinition
		const { closePopup } = filterPopupLogicChildrenProps
		const {
			form: { changeFilter, validateForm },
			advertsCount: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity }
		} = filterLogicChildrenProps
		const { id } = formLineEntity

		const isFormValid = validateForm()

		const Form =
			id === FilterConstants.formLineIds.AGE
				? AgeForm
				: id === FilterConstants.formLineIds.ITEM_AGE
				? ItemAgeForm
				: RadioInputForm

		return (
			<Form
				formLineEntity={formLineEntity}
				isFormValid={isFormValid}
				onChange={changeFilter}
				onPresetClick={closePopup}
				filteredAdvertsCount={filteredAdvertsCount}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				isLastOptionCustomInput={id !== FilterConstants.formLineIds.ITEM_AGE}
				// pokud je videt CTA tlacitko s poctem nabidek (a textem 'Zobrazit xxx
				// nabidek'), tak nechceme v bublinach s pocty inzeratu znovu vypisovat
				// text 'xxx nabidek', ale pouze 'xxx'
				isCountShownWithAddText={filterButtonType !== FilterConstants.filterButtonType.SEARCH}
			/>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderButton(filterPopupLogicChildrenProps, filterLogicChildrenProps) {
		const { popupDefinition } = this.props

		return (
			<FilterPopupButton
				filterPopupLogicData={filterPopupLogicChildrenProps}
				filterFormLogicData={filterLogicChildrenProps}
				popupDefinition={popupDefinition}
			/>
		)
	}
}
