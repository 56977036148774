import React from 'react'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import Modal, { ModalFooterButtons } from 'app/component/modal/ModalView'

import './ToppedModalCS.json'
import './ToppedModal.less'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-topped-modal'

const ToppedModal = ({ isOpen = false, onClose = () => {}, advertIds = [], premiseId }) => {
	const localize = useLocalize()
	const price = 4 // cena v Kc za 1 topnuty inzerat
	const MAXIMUM_COUNT = 100

	const isToppedAllowed = advertIds.length <= MAXIMUM_COUNT

	const bodyText = isToppedAllowed ? (
		<span className='notranslate' translate='no' lang='cs-cz'>
			{localize('ToppedModal.text', {
				COUNT: advertIds.length,
				PRICE: advertIds.length * price,
				CURRENCY: localize('Base.CZK')
			})}
			.
		</span>
	) : (
		`${localize('ToppedModal.maximumAdvertError', {
			COUNT: MAXIMUM_COUNT
		})}`
	)

	const fire = useFire()

	if (isOpen) {
		return (
			<Modal
				isOpen={true}
				title={localize('ToppedModal.title', {
					COUNT: advertIds.length
				})}
				modalStyle={Modal.STYLE.OFFSET}
				className={CLASSNAME}
				setIsOpenCallback={(isOpen) => {
					if (!isOpen) {
						onClose()
					}
				}}
			>
				<div className={`${CLASSNAME}__text`}>{bodyText}</div>

				<ModalFooterButtons
					buttonsProps={[
						{
							text: localize('ToppedModal.close'),
							onClick: onClose
						},
						{
							text: localize('ToppedModal.top'),
							primary: true,
							onClick: () => {
								fire('advertTop', {
									advertIds,
									premiseId
								})
								onClose()
							},
							disabled: !isToppedAllowed
						}
					]}
				/>
			</Modal>
		)
	} else {
		return null
	}
}

export default React.memo(ToppedModal)
