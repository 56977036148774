
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['OtherActions'] = {
  moreActions: function(d) { return "Další možnosti"; },
  reportBtn: function(d) { return "Nahlásit inzerát"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	