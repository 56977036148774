import React from 'react'
import PropTypes from 'prop-types'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import BrandIcon from '../../brandIcon/BrandIconView'
import SelectedItem, { defaultAdditionalTextBuilder } from '../SelectedItem'

import './BrandModelsView.less'
import './BrandModelsViewCS.json'

const CLASSNAME = 'cf-brand-models'

export default class BrandModelsView extends AbstractPureComponent {
	static get propTypes() {
		return {
			brandValue: PropTypes.number.isRequired,
			brand: PropTypes.string.isRequired,
			models: PropTypes.arrayOf(
				PropTypes.shape({
					value: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired
				})
			).isRequired,
			selectedModelIds: PropTypes.arrayOf(PropTypes.number).isRequired,
			onClick: PropTypes.func.isRequired,
			onRemove: PropTypes.func.isRequired,
			seoName: PropTypes.string,
			categorySeoName: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			seoName: '',
			categorySeoName: ''
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderContent = this._renderContent.bind(this)
	}

	render() {
		const { brandValue, brand, onClick, onRemove } = this.props

		return (
			<SelectedItem
				mainText={brand}
				additionalText={this._getTextForModels()}
				renderContent={this._renderContent}
				onClick={(event) => onClick(event, brandValue)}
				onRemove={(event) => onRemove(event, brandValue)}
			/>
		)
	}

	_getTextForModels() {
		const { models, selectedModelIds } = this.props

		return defaultAdditionalTextBuilder(
			models,
			selectedModelIds,
			this.localize('BrandModelsView.allModels')
		)
	}

	_renderContent(renderedBrandText, renderedModelsText) {
		const { brandValue, seoName, categorySeoName } = this.props

		return (
			<React.Fragment>
				<BrandIcon
					value={brandValue}
					className={`${CLASSNAME}__icon`}
					seoName={seoName}
					categorySeoName={categorySeoName}
				/>
				<span className={`${CLASSNAME}__text`}>
					{renderedBrandText}
					{renderedModelsText}
				</span>
			</React.Fragment>
		)
	}
}
