
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PhotoPreview'] = {
  too_small: function(d) { return "Fotografie nesplňuje minimální rozlišení"; },
  backendError: function(d) { return "Fotografii se nepodařilo nahrát"; },
  removeLabel: function(d) { return "Smazat fotografii"; },
  pinTitle: function(d) { return "Nastavit jako titulní fotografii"; },
  pinTitleAria: function(d) { return "Nastavit " + d.INDEX + ". z " + d.COUNT + " jako titulní fotografii"; },
  loading: function(d) { return "Nahrávám"; },
  pinConfirmation: function(d) { return "Titulní fotografie byla změněna"; },
  firstPhoto: function(d) { return "Titulní fotografie"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	