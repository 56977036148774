
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertDetailMeta'] = {
  titleService: function(d) { return "| Sauto.cz"; },
  descIntro: function(d) { return "Inzerát na prodej"; },
  descVans: function(d) { return "užitkový vůz, dodávka"; },
  descBus: function(d) { return "autobus"; },
  descCondition: function(d) { return "Stav"; },
  descColor: function(d) { return "barva"; },
  descPrice: function(d) { return "cena"; },
  descTachometer: function(d) { return "tachometr"; },
  descEngine: function(d) { return "motor"; },
  descSeller: function(d) { return "prodejce"; },
  descDistrict: function(d) { return "okres"; },
  descMotorcycle: function(d) { return "motorka"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	