import React from 'react'

import './ModelLinesPlaceholder.less'

const CLASSNAME = 'c-catalog-model-lines-placeholder'

const ModelLinesPlaceholder = () => {
	const itemsArr = Array.from(Array(14).keys())

	const renderItem = (key) => {
		return (
			<div key={key} className={`${CLASSNAME}__item`}>
				<div className={`${CLASSNAME}__name`}></div>
				<div className={`${CLASSNAME}__year`}></div>
			</div>
		)
	}

	return (
		<div className={CLASSNAME}>
			{itemsArr.map((item) => {
				return renderItem(`${item}__key`)
			})}
		</div>
	)
}

export default ModelLinesPlaceholder
