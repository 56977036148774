
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['ReviewForm'] = {
  manufacturerAndModel: function(d) { return "Výrobce a model: "; },
  title: function(d) { return "Přidat zkušenost"; },
  stepNo1: function(d) { return "- krok: specifikace vozu"; },
  stepNo2: function(d) { return "- krok: vyplnění zkušenosti"; },
  description: function(d) { return "Pro přidání zkušenosti je potřeba vozidlo přesně specifikovat, abychom dokázali lépe poradit ostatním uživatelům"; },
  reviewTitle: function(d) { return "Titulek"; },
  changeSpecification: function(d) { return "Upravit specifikaci vozidla"; },
  reviewType: function(d) { return "Sdělte nám zkušenosti s vozidlem"; },
  reviewTypePositive: function(d) { return "Pozitivní"; },
  reviewTypeNegative: function(d) { return "Negativní"; },
  reviewTypeNeutral: function(d) { return "Neutrální"; },
  reviewDescription: function(d) { return "Popište zkušenost s vozidlem"; },
  reviewDescriptionPlaceholder: function(d) { return "Vaše zkušenost s tímto vozem / motorizací?"; },
  reviewPositives: function(d) { return "Klady"; },
  reviewPositivesPlaceholder: function(d) { return "Světlé stránky tohoto vozu?"; },
  reviewNegatives: function(d) { return "Zápory"; },
  reviewNegativesPlaceholder: function(d) { return "Záporné stránky tohoto vozu?"; },
  value_not_valid: function(d) { return "Chybný formát"; },
  value_required: function(d) { return "Povinná položka"; },
  manufacturing_year_required: function(d) { return "Je třeba vyplnit rok výroby"; },
  manufacturing_year_not_valid: function(d) { return "Chybný formát roku výroby"; },
  fuel_cb_required: function(d) { return "Je třeba vybrat palivo"; },
  power_required: function(d) { return "Je třeba vyplnit výkon vozidla"; },
  review_title_required: function(d) { return "Zkušenost nelze přidat bez titulku"; },
  review_description_required: function(d) { return "Zkušenost nelze přidat bez popisu "; },
  review_text_in_blacklist: function(d) { return "Použili jste nevhodný výraz:"; },
  continueSubmit: function(d) { return "Pokračovat v přidání zkušenosti"; },
  submit: function(d) { return "Uložit zkušenost"; },
  contentLoading: function(d) { return "Načítám data."; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	