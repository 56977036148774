import React from 'react'
import PropTypes from 'prop-types'
import { Surface, Tag } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { CategoryEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import BrandIcon from 'app/component/filters/components/brandIcon/BrandIconView'
import SelectedItem from 'app/component/filters/components/selectedItem/SelectedItem'
import ModelsSearch from '../modelsSearch/ModelsSearch'

import './SelectedBrand.less'

const CLASSNAME = 'c-selected-brand'

const SelectedBrand = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		category = DEFAULT_PROPS.OBJECT,
		brand = DEFAULT_PROPS.OBJECT,
		advertsCountForModels = DEFAULT_PROPS.OBJECT,
		changeFilter = DEFAULT_PROPS.FUNCTION
	} = props

	const getSelectedModels = (brand) => {
		const { value: selectedBrands = new Map() } = formLineEntity

		const { value: brandValue, children: allModels = [] } = brand

		const selectedModelValues = selectedBrands.get(brandValue) || new Set()

		return Array.from(selectedModelValues).map((modelValue) =>
			allModels.find((model) => model.value === modelValue)
		)
	}

	const removeBrand = () => {
		const { id, value: selectedBrands = new Map() } = formLineEntity

		const newSelectedBrands = new Map(selectedBrands)

		newSelectedBrands.delete(brandValue)

		changeFilter(id, newSelectedBrands)
	}

	const removeModel = (model) => {
		const { id, value: selectedBrands = new Map() } = formLineEntity
		const { value: brandValue } = brand
		const { value: modelValue } = model

		const newSelectedModels = new Set()
		selectedBrands.get(brandValue).forEach((model) => {
			newSelectedModels.add(model)
		})

		newSelectedModels.delete(modelValue)

		changeFilter(id, new Map([...selectedBrands, [brandValue, newSelectedModels]]))
	}

	const { seoName: categorySeoName } = category

	const { value: brandValue, name: brandName, seoName: brandSeoName } = brand

	const selectedModels = getSelectedModels(brand)

	return (
		<Surface className={`${CLASSNAME}__wrapper`}>
			<SelectedItem
				mainText={brandName}
				additionalText=''
				onRemove={removeBrand}
				renderContent={() => (
					<div className={`${CLASSNAME}__brand`}>
						<BrandIcon
							value={brandValue}
							className={`${CLASSNAME}__icon`}
							seoName={brandSeoName}
							categorySeoName={categorySeoName}
						/>
						<span className={`${CLASSNAME}__brand-name`}>{brandName}</span>
					</div>
				)}
			/>

			<div>
				{selectedModels.map((model) => (
					<Tag
						key={model.id}
						className={`${CLASSNAME}__tag`}
						size='x-small'
						surface={4}
						data-dot='delete-model'
						onClick={() => removeModel(model)}
					>
						{model.name}
					</Tag>
				))}
			</div>

			<ModelsSearch
				formLineEntity={formLineEntity}
				changeFilter={changeFilter}
				brand={brand}
				advertsCountForModels={advertsCountForModels}
			/>
		</Surface>
	)
}

SelectedBrand.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	category: PropTypes.instanceOf(CategoryEntity).isRequired,
	brand: PropTypes.object.isRequired,
	advertsCountForModels: PropTypes.object.isRequired,
	changeFilter: PropTypes.func.isRequired
}

export default React.memo(SelectedBrand)
