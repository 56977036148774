import UserwebBaseController from 'app/base/UserwebBaseController'
import CatalogMeta from '../CatalogMeta'

export default class BrandsCatalogController extends UserwebBaseController {
	constructor(dependenciesHelper, catalogService) {
		super(dependenciesHelper)

		this._catalogService = catalogService
	}

	static get STATE_KEYS() {
		return {
			BRANDS_LIST: 'BRANDS_LIST'
		}
	}

	init() {
		super.init()
	}

	load() {
		return super.load({
			[BrandsCatalogController.STATE_KEYS.BRANDS_LIST]: this._loadAllBrands()
		})
	}

	getDataForSsp() {
		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {}
		}
	}

	_loadAllBrands() {
		return this._catalogService.getBrands()
	}

	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const { title, description } = this._getMetas()

		const metaTitle = title + dictionary.get('Base.titleSuffix')
		metaManager.setTitle(metaTitle)
		metaManager.setMetaName('twitter:title', metaTitle)
		metaManager.setMetaProperty('og:title', metaTitle)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('twitter:description', description)
		metaManager.setMetaProperty('og:description', description)
	}

	_getMetas() {
		const { route } = this._utils.$Router.getCurrentRouteInfo()
		const routeName = route.getName()

		return CatalogMeta.getMetas(routeName, this._utils.$Dictionary)
	}
}
