import React from 'react'

import './HistoryPlaceholders.less'

const CLASSNAME = 'c-history-pl'

const HistoryPlaceholders = () => {
	const textInputArray = [0, 1]
	const textRadiosArray = [0, 1, 2, 3, 4, 5]

	const renderTextInputItem = (key) => {
		const keyPreset = 'text-input'
		return (
			<div key={`${keyPreset}-${key}`} className={`${CLASSNAME}__item-input`}>
				<div className={`${CLASSNAME}__text-${key}`}></div>
				<div className={`${CLASSNAME}__input`}></div>
			</div>
		)
	}

	const renderTextRadiosItem = (key) => {
		const keyPreset = 'text-radios'

		return (
			<div key={`${keyPreset}-${key}`} className={`${CLASSNAME}__item-radio`}>
				<div className={`${CLASSNAME}__text-${key}`}></div>
				<div className={`${CLASSNAME}__radios`}>
					<div className={`${CLASSNAME}__radio-1`}></div>
					<div className={`${CLASSNAME}__radio-2`}></div>
					<div className={`${CLASSNAME}__radio-3`}></div>
				</div>
			</div>
		)
	}

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__list`}>
				{textInputArray.map((item) => renderTextInputItem(item))}
				{textRadiosArray.map((item) => renderTextRadiosItem(item))}
			</div>
		</div>
	)
}

export default HistoryPlaceholders
