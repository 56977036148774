import { INTERNAL_ADMIN } from 'app/base/Constants'
import UrlConvertor from './UrlConvertor'
import { filterBy, filterByValue } from 'app/component/entitiesFilter/EntitiesFilter'

export default class InternalAdminUrlConvertor {
	static get constants() {
		return Object.freeze({
			PAGE: 'page',
			LIMIT: 'limit',
			ADVERT_ID: 'advertId',
			DISTRICT: 'district_cb',
			REGION: 'region_cb',
			ROLE_ID: 'role_id',
			GROUP_BY: 'group_by',
			FILTER_BY: filterBy,
			FILTER_BY_VALUE: filterByValue,
			DATE_FROM: 'date_from',
			DATE_TO: 'date_to',
			SESSION_ID: 'sessionId',
			USER_ID: 'user_id',
			USER_EDIT_ID: 'userId',
			EMAIL: 'email',
			PHONE: 'phone',
			PREMISE_ID: 'premise_id',
			PREMISE_EDIT_ID: 'premiseId',
			REVIEW_ID: 'review_id',
			STATUS_CODES: 'status_codes',
			SESSION_DATE: 'session_date',
			CODEBOOKS_CATEGORY_ID: 'codebook_category_id',
			CODEBOOKS_CATEGORY_IDS: 'codebook_category_ids',
			CODEBOOKS_NAME_ID: 'codebook_name_id',
			CODEBOOKS_SECTION: 'category',
			CODEBOOKS_FOR_FILTERS: 'for_filters',
			CODEBOOKS_PARENT_ID: 'parent_id',
			FEEDBACK_USER: 'email',
			FEEDBACK_TYPE: 'feedback_type',
			FEEDBACK_SUBJECT: 'feedback_subject',
			FEEDBACK_TEXT: 'has_text',
			OPERATING_LEASE: 'operating_lease',
			DEAL_TYPE: 'deal_type'
		})
	}

	static processRouteParamsForEntityList(routeParams) {
		const params = Object.assign({}, routeParams)

		if (!params.limit) {
			params.limit = INTERNAL_ADMIN.DEFAULT_PER_PAGE
		}

		const { PAGE, DISTRICT, REGION } = InternalAdminUrlConvertor.constants
		if (params.hasOwnProperty(PAGE)) {
			params.offset = (params[PAGE] - 1) * params.limit
			delete params[PAGE]
		}

		if (params.hasOwnProperty(filterBy) && params.hasOwnProperty(filterByValue)) {
			params[params[filterBy]] = params[filterByValue]

			delete params[filterBy]
			delete params[filterByValue]
		}

		// Upraví parametry pro lokalitu.
		if (params.hasOwnProperty(DISTRICT)) {
			params.locality = `district,${params[DISTRICT]}`
			delete params[DISTRICT]
			delete params[REGION]
		} else if (params.hasOwnProperty(REGION)) {
			params.locality = `region,${params[REGION]}`
			delete params[REGION]
		}

		return params
	}

	static getUrl(router, routeParams) {
		return UrlConvertor.getUrl(router, routeParams)
	}

	static getRouteInfo(router) {
		return UrlConvertor.getRouteInfo(router)
	}

	static getAdvertId(router) {
		const { params } = router.getCurrentRouteInfo()

		return params[InternalAdminUrlConvertor.constants.ADVERT_ID]
	}

	static getTableApiParams(routeParams, tableRouteParamsConfig) {
		const {
			defaultSort,
			limitRouteParamName,
			pageRouteParamName,
			sortRouteParamName
		} = tableRouteParamsConfig
		const urlParams = { ...routeParams }
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(urlParams)

		if (params.hasOwnProperty(limitRouteParamName)) {
			params.limit = parseInt(params[limitRouteParamName])
			delete params[limitRouteParamName]
		} else {
			params.limit = INTERNAL_ADMIN.DEFAULT_PER_PAGE
		}

		if (params.hasOwnProperty(pageRouteParamName)) {
			params.offset = (parseInt(params[pageRouteParamName]) - 1) * params.limit
			delete params[pageRouteParamName]
		} else {
			params.offset = 0
		}

		if (params[sortRouteParamName]) {
			params.sort = params[sortRouteParamName]
			delete params[sortRouteParamName]
		} else {
			params.sort = defaultSort
		}

		return params
	}

	static getStatusCodesParams(routeParams = {}) {
		const { [InternalAdminUrlConvertor.constants.STATUS_CODES]: statusCodes = '' } = routeParams

		return statusCodes.split(',').filter((code) => !!code)
	}
}
