import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon, InputSurface, Spinner } from '@sznds/react'
import { CHEVRON_UP_OUTLINE_24, CHEVRON_DOWN_OUTLINE_24, CLOSE_OUTLINE_16 } from '@sznds/icons'
import { useLocalize } from 'app/base/componentHelpers'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'

import './AutoCompleteInput.less'
import './AutoCompleteInputCS.json'

const CLASSNAME = 'c-auto-complete-input'

const ICON_CLEAR = 'clear'
const ICON_ARROW_UP = 'arrowUp'
const ICON_ARROW_DOWN = 'arrowDown'

const AutoCompleteInput = ({
	isLoading,
	isFocused,
	inputSurfaceProps = {},
	customInput: CustomInput = 'input',
	inputProps = {},
	toggleButtonProps,
	clearButtonProps,
	renderLeftIcon
}) => {
	const localize = useLocalize()

	const { ref: propsInputRef } = inputProps

	const undefinedInputRef = useRef()

	const inputRef = propsInputRef || undefinedInputRef

	const [focused, setFocused] = useState(isFocused)

	useEffect(() => {
		const { autoFocus } = inputProps

		if (autoFocus) {
			return
		}

		if (!isNullOrUndefined(isFocused)) {
			setFocused(isFocused)

			if (isFocused) {
				inputRef.current && inputRef.current.focus()
			} else {
				inputRef.current && inputRef.current.blur()
			}
		}
	}, [inputRef, isFocused, inputProps])

	useEffect(() => {
		const activeElement = document.activeElement

		if (inputRef.current && !inputRef.current.contains(activeElement)) {
			setFocused(false)
		}
	}, [inputRef.current])

	const handleFocus = (event) => {
		const { onFocus } = inputProps

		setFocused(true)
		onFocus && typeof onFocus === 'function' && onFocus(event)
	}

	const handleBlur = (event) => {
		const { onBlur } = inputProps

		setFocused(false)
		onBlur && typeof onBlur === 'function' && onBlur(event)
	}

	const getIcon = () => {
		if (toggleButtonProps) {
			const { isOpen } = toggleButtonProps

			return isOpen ? ICON_ARROW_UP : ICON_ARROW_DOWN
		} else if (clearButtonProps) {
			return ICON_CLEAR
		}
	}

	const icon = getIcon()

	const buttonProps = [ICON_ARROW_UP, ICON_ARROW_DOWN].includes(icon)
		? toggleButtonProps
		: Object.assign({}, clearButtonProps, {
				type: 'button',
				role: 'button'
		  })

	delete buttonProps.isOpen

	const restInputProps = Object.assign({}, inputProps)

	delete restInputProps.error

	const isDisabled = isLoading || inputProps.disabled

	const hasRightButton = toggleButtonProps || clearButtonProps

	return (
		<InputSurface
			{...inputSurfaceProps}
			tagName='div'
			className={classnames({
				[CLASSNAME]: true,
				['sds-input']: true,
				['sds-input--disabled']: isDisabled,
				[`${CLASSNAME}--small`]: inputSurfaceProps.size === 'small',
				[inputSurfaceProps.className]: !!inputSurfaceProps.className
			})}
			focused={focused}
			error={inputProps.error}
		>
			<CustomInput
				{...restInputProps}
				className={classnames({
					[inputProps.className]: !!inputProps.className,
					['sds-typography_body']: true,
					[`${CLASSNAME}__with-left-icon`]: Boolean(renderLeftIcon),
					[`${CLASSNAME}__with-right-icon`]: hasRightButton
				})}
				placeholder={isLoading ? localize('AutoCompleteInput.loading') : inputProps.placeholder}
				onFocus={handleFocus}
				onBlur={handleBlur}
				disabled={isDisabled}
				ref={inputRef}
			/>
			{Boolean(renderLeftIcon) && (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
				<div
					className={`${CLASSNAME}__icon-left`}
					onClick={(e) => {
						if (!isDisabled) {
							inputRef.current && inputRef.current.focus()
							inputProps.onClick && inputProps.onClick(e)
						}
					}}
				>
					{renderLeftIcon()}
				</div>
			)}

			{isLoading ? (
				<Spinner className={`${CLASSNAME}__spinner`} />
			) : (
				hasRightButton &&
				!isDisabled && (
					<button
						{...buttonProps}
						disabled={isDisabled}
						onMouseDown={(e) => {
							e.preventDefault()
							inputRef.current && inputRef.current.focus()
						}}
						className={classnames({
							[buttonProps.className]: !!buttonProps.className,
							[`${CLASSNAME}__button`]: true
						})}
					>
						<Icon
							className={classnames({
								[`${CLASSNAME}__icon`]: true,
								[`${CLASSNAME}__icon-clear`]: icon === ICON_CLEAR,
								[`${CLASSNAME}__icon-arrow`]: [ICON_ARROW_UP, ICON_ARROW_DOWN].includes(icon)
							})}
							symbol={
								icon === ICON_CLEAR
									? CLOSE_OUTLINE_16
									: icon === ICON_ARROW_UP
									? CHEVRON_UP_OUTLINE_24
									: CHEVRON_DOWN_OUTLINE_24
							}
						/>
					</button>
				)
			)}
		</InputSurface>
	)
}

AutoCompleteInput.propTypes = {
	isLoading: PropTypes.bool,
	isFocused: PropTypes.bool,
	inputSurfaceProps: PropTypes.object,
	customInput: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	inputProps: PropTypes.object,
	toggleButtonProps: PropTypes.object,
	clearButtonProps: PropTypes.object,
	renderLeftIcon: PropTypes.func
}

export default AutoCompleteInput
