import React from 'react'
import ErrorPageBox from 'app/component/errorPageBox/ErrorPageBoxView'
import PropTypes from 'prop-types'
import { Button } from '@sznds/react'

export default function ErrorPageBoxButton(props) {
	return <Button {...props} primary={true} className={`${ErrorPageBox.CLASSNAME}__btn`} />
}

ErrorPageBox.propTypes = {
	text: PropTypes.string.isRequired,
	href: PropTypes.string,
	onClick: PropTypes.func
}
