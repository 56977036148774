import { Format } from '@inzeraty/helpers'
import { toMetricHorsePowerConversionRate } from 'app/base/Constants'

const CAR_CONDITION = {
	conditionCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	tachometer: {
		getData: (tachometer) => ({
			TACHOMETER_VALUE: Format.number(tachometer)
		})
	},
	manufacturingDate: {
		noTemplate: true,
		getData: (manufacturingDate) => Format.dateShort(manufacturingDate, true)
	},
	inOperationDate: {
		noTemplate: true,
		getData: (inOperationDate) => Format.dateShort(inOperationDate, true)
	},
	vin: {
		isVin: true
	},
	certificationCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	cebiaReport: {
		noTemplate: true,
		getData: () => 'Cebia'
	}
}

const ENGINE_VALUES = {
	fuelCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	engineVolume: {
		getData: (engineVolume) => ({
			ENGINE_VOLUME: Format.number(engineVolume)
		})
	},
	enginePower: {
		getData: (enginePowerKiloWatts) => ({
			POWER_KW: Format.number(enginePowerKiloWatts),
			POWER_HP: Format.number(enginePowerKiloWatts * toMetricHorsePowerConversionRate)
		})
	},
	batteryCapacity: {
		getData: (batteryCapacity) => ({
			BATTERY_CAPACITY: Format.number(batteryCapacity)
		})
	},
	vehicleRange: {
		getData: (vehicleRange) => ({
			VEHICLE_RANGE: Format.number(vehicleRange)
		})
	},
	gearboxCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	gearboxLevelsCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	driveCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	averageGasMileage: {
		getData: (averageGasMileage) => ({
			FUEL_CONSUMPTION: Format.number(averageGasMileage, 1)
		})
	},
	averageElectricMileage: {
		getData: (averageElectricMileage) => ({
			AVERAGE_ELECTRIC_MILEAGE: Format.number(averageElectricMileage)
		})
	},
	euroLevelCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	}
}

const HISTORY_VALUES = {
	firstOwner: {
		isBoolean: true
	},
	serviceBook: {
		isBoolean: true
	},
	countryOfOriginCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	stkDate: {
		noTemplate: true,
		getData: (stkDate) => Format.dateShort(stkDate)
	},
	guaranteeDate: {
		noTemplate: true,
		getData: (guaranteeDate) => Format.dateShort(guaranteeDate)
	},
	crashedInPast: {
		isBoolean: true
	},
	environmentalTaxPaid: {
		isBoolean: true
	}
}

const SPEC_VALUES = {
	vehicleBodyCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	colorCb: {
		noTemplate: true,
		multipleValues: ['colorCb', 'colorToneCb', 'colorTypeCb'],
		getData: ([{ name: colorName }, { name: toneName } = {}, { name: typeName } = {}]) =>
			[Format.capitalizeFirstLetter(colorName), toneName, typeName]
				.filter((value) => !!value)
				.join(', ')
	},
	airconditionCb: {
		noTemplate: true,
		getData: ({ name }) => Format.capitalizeFirstLetter(name)
	},
	weight: {
		getData: (weight) => ({
			WEIGHT_VALUE: Format.number(weight)
		})
	},
	loadCapacity: {
		getData: (loadCapacity) => ({
			LOAD_CAPACITY_VALUE: Format.number(loadCapacity)
		})
	},
	airbags: {
		noTemplate: true,
		getData: (airbags) => Format.number(airbags)
	},
	doors: {
		noTemplate: true,
		getData: (doors) => Format.number(doors)
	},
	capacity: {
		noTemplate: true,
		getData: (capacity) => Format.number(capacity)
	},
	beds: {
		noTemplate: true,
		getData: (beds) => Format.number(beds)
	},
	adjustmentsForHandicapped: {
		isBoolean: true
	},
	tuning: {
		isBoolean: true
	}
}

export { CAR_CONDITION, ENGINE_VALUES, HISTORY_VALUES, SPEC_VALUES }
