import React, { useContext } from 'react'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import TableHead from 'app/component/tableHead/TableHead'
import * as FormLines from '@inzeraty/form-lines'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import ArticlesEntity from 'app/model/articles/ArticlesEntity'
import Context from 'ima/page/context'
import ActionButton from 'app/component/actionButton/ActionButton'
import { EDIT_FILLED, TRASH_FILLED } from '@sznds/icons'
import { useFire } from 'app/base/componentHelpers'
import EntityForm from 'app/component/entityForm/EntityForm'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import IAArticlesDetailController from './IAArticlesDetailController'
import EntityFormPopup from '../components/entityFormPopup/EntityFormPopup'
import IMAGES from 'app/base/ImagesConstants'

import './IAArticlesDetailView.less'

const CLASSNAME = 'p-ia-articles-detail'

const IAArticlesDetailView = ({
	isLoading = true,
	entitiesAndPagination,
	filterFormLineEntities = {},
	isFilterLoading = true,
	formLineEntities = [],
	isEditPopupOpened,
	entityFormError,
	popupTitle
}) => {
	const { $Router } = useContext(Context)
	const { params = {} } = $Router.getCurrentRouteInfo()
	const MANUFACTURER_NAME = params['manufacturer']
	const MODEL_NAME = params['model']
	const fire = useFire()
	const { ACTIONS } = IAArticlesDetailController

	const getFormDataOnSubmit = (formLineEntities) => {
		const formData = FormLines.getFormData(formLineEntities)
		const stableParams = {
			category_id: params.category_id,
			model_cb: params.model_cb,
			manufacturer_cb: params.manufacturer_cb,
			manufacturer: params.manufacturer,
			model: params.model
		}

		return Object.assign(stableParams, formData)
	}

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		return [
			(entity, actionButtonProps = {}) => (
				<ActionButton
					icon={EDIT_FILLED}
					key='editovat'
					title='Editovat'
					onClick={() => action(ACTIONS.OPEN_EDIT_POPUP, [entity])}
					{...actionButtonProps}
				/>
			),
			(entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='smazat'
					title='Smazat'
					onClick={() => action(ACTIONS.DELETE, [entity])}
					{...actionButtonProps}
				/>
			)
		]
	}

	return (
		<Layout
			className={CLASSNAME}
			title={
				MANUFACTURER_NAME && MODEL_NAME ? `Články pro ${MANUFACTURER_NAME} ${MODEL_NAME}` : 'Články'
			}
		>
			{entitiesAndPagination && (
				<>
					<EntitiesFilter
						formLineEntities={filterFormLineEntities}
						isFilterLoading={isFilterLoading}
						getFormDataOnSubmit={getFormDataOnSubmit}
					/>

					<EntitiesTable
						entitiesAndPagination={entitiesAndPagination}
						headCells={[
							<TableHead key='id'>ID</TableHead>,
							<TableHead key='title'>Titulek</TableHead>,
							<TableHead key='source'>Zdroj</TableHead>,
							<TableHead key='isShown'>Zobrazeno</TableHead>
						]}
						rowCells={[
							({ id }) => id,
							({ title, url }) => (
								<a className={`${CLASSNAME}__title`} href={url}>
									{title}
								</a>
							),
							({ source }) => `${source}.cz`,
							({ isShown }) => (
								<span className={`${CLASSNAME}__is-shown`}>
									<img
										className={`${CLASSNAME}__icon`}
										src={
											isShown ? IMAGES['image__articles-check'] : IMAGES['image__articles-cross']
										}
										alt='Ikona pro zobrazeno'
									/>
									{isShown ? 'Ano' : 'Ne'}
								</span>
							)
						]}
						actions={getActions()}
						isLoading={isLoading}
					/>
				</>
			)}
			{isEditPopupOpened && (
				<EntityFormPopup title={popupTitle} onClose={() => action(ACTIONS.CLOSE_EDIT_POPUP)}>
					<EntityForm
						variant={VARIANT.POPUP_FORM}
						formLineEntities={formLineEntities}
						entityFormError={entityFormError}
					/>
				</EntityFormPopup>
			)}
		</Layout>
	)
}

IAArticlesDetailView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(ArticlesEntity)),
		paginationEntity: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isLoading: PropTypes.bool,
	isFilterLoading: PropTypes.bool,
	manufacturer: PropTypes.string,
	model: PropTypes.string,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isEditPopupOpened: PropTypes.bool,
	entityFormError: PropTypes.object,
	popupTitle: PropTypes.string
}

export default IAArticlesDetailView
