import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import FilterConstants from 'app/model/filter/FilterConstants'
import DropdownOpenerLogic from '../openers/DropdownOpenerLogic'

export default class DependentDropdownsOpenerLogic extends DropdownOpenerLogic {
	constructor(props, context) {
		super(props, context)

		this._openDependentDropdown = this._openDependentDropdown.bind(this)
		this._clearDependentFilter = this._clearDependentFilter.bind(this)
	}

	render() {
		const { children } = this.props

		return children({
			openMainDropdown: this._openDropdown,
			clearMainFilter: this._clearFilter,

			openDependentDropdown: this._openDependentDropdown,
			clearDependentFilter: this._clearDependentFilter
		})
	}

	_openDependentDropdown(value) {
		const {
			formLineEntity: { id },
			context
		} = this.props

		this.fire('openFilterDropdown', {
			filterId: id,
			openedByValue: value,
			context,
			filterDropdownType: FilterConstants.filterDropdownType.BACK,
			updateUrlParams: context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL
		})
	}

	_clearDependentFilter(newValue) {
		const {
			formLineEntity: { id },
			changeFilter
		} = this.props

		changeFilter(id, newValue)
	}
}
