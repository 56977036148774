import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import FilterConstants from 'app/model/filter/FilterConstants'
import RadioFilterLogic from 'app/component/filters/logic/RadioFilterLogic'
import RadioInlineForm from 'app/component/filters/forms/main/inlineForms/radioInlineForm/RadioInlineForm'
import ItemAgeInlineForm from 'app/component/filters/forms/main/inlineForms/itemAgeInlineForm/ItemAgeInlineForm'
import InlineFormAdvertsCountLoader from '../../forms/main/inlineForms/InlineFormAdvertsCountLoader'

export default class RadioInlineFilter extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)).isRequired,
			context: PropTypes.string.isRequired,
			renderType: PropTypes.oneOf(RadioInlineForm.RENDER_TYPE)
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			formLineEntities: DEFAULT_PROPS.ARRAY
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderFilterForm = this._renderFilterForm.bind(this)
	}

	render() {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			context
		} = this.props
		const { id } = globalFormLineEntity

		return (
			<RadioFilterLogic
				formLineEntity={globalFormLineEntity}
				changeFilter={globalChangeFilter}
				context={context}
				isLastOptionCustomInput={id !== FilterConstants.formLineIds.ITEM_AGE}
			>
				{(filterLogicChildrenProps) => {
					return this._renderFilterForm(filterLogicChildrenProps)
				}}
			</RadioFilterLogic>
		)
	}

	_renderFilterForm(filterLogicChildrenProps) {
		const { context, formLineEntities, renderType } = this.props

		const {
			form: { validateForm },
			advertsCount: {
				// presety
				filteredAdvertsCount,
				isLoadingFilteredAdvertsCount,
				loadFilteredAdvertsCount,

				// dropdown s inputem 'od'
				filteredAdvertsCountFromInput,
				isLoadingFilteredAdvertsCountFromInput,
				loadFilteredAdvertsCountFromInput,

				// dropdown s inputem 'do'
				filteredAdvertsCountToInput,
				isLoadingFilteredAdvertsCountToInput,
				loadFilteredAdvertsCountToInput
			},
			other: { formLineEntity }
		} = filterLogicChildrenProps
		const { id } = formLineEntity

		const isFormValid = validateForm()

		const changeFilter = (...args) => {
			const [, change] = args
			const {
				form: { changeFilter },
				other: { formLineEntity }
			} = filterLogicChildrenProps

			const newValue = Object.assign({}, formLineEntity.value, change)

			const newFormLineEntity = Object.assign({}, formLineEntity, {
				value: newValue
			})

			const isFormValid = validateForm(newFormLineEntity)

			if (
				isFormValid ||
				(context !== FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL &&
					context !== FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL_INLINE)
			) {
				changeFilter(...args)
			}
		}

		const isHpFilter =
			context === FILTER_CONTEXT.MAIN_MENU || context === FILTER_CONTEXT.MAIN_MENU_INLINE

		if (id === FilterConstants.formLineIds.ITEM_AGE) {
			return (
				<InlineFormAdvertsCountLoader
					formLineEntity={formLineEntity}
					formLineEntities={formLineEntities}
					isVisible={true}
					loadFilteredAdvertsCount={loadFilteredAdvertsCount}
				>
					<ItemAgeInlineForm
						formLineEntity={formLineEntity}
						filteredAdvertsCount={filteredAdvertsCount}
						isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
						context={context}
						onChange={changeFilter}
					/>
				</InlineFormAdvertsCountLoader>
			)
		} else {
			return (
				<InlineFormAdvertsCountLoader
					formLineEntity={formLineEntity}
					formLineEntities={formLineEntities}
					isVisible={true}
					loadFilteredAdvertsCount={isHpFilter ? () => {} : loadFilteredAdvertsCount}
				>
					<RadioInlineForm
						formLineEntity={formLineEntity}
						// pro presety
						filteredAdvertsCount={filteredAdvertsCount}
						isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
						// pro dropdown 'od'
						filteredAdvertsCountFromInput={filteredAdvertsCountFromInput}
						isLoadingFilteredAdvertsCountFromInput={isLoadingFilteredAdvertsCountFromInput}
						loadFilteredAdvertsCountFromInput={loadFilteredAdvertsCountFromInput}
						// pro dropdown 'do'
						filteredAdvertsCountToInput={filteredAdvertsCountToInput}
						isLoadingFilteredAdvertsCountToInput={isLoadingFilteredAdvertsCountToInput}
						loadFilteredAdvertsCountToInput={loadFilteredAdvertsCountToInput}
						// dalsi props
						isFormValid={isFormValid}
						context={context}
						onChange={changeFilter}
						renderType={renderType}
					/>
				</InlineFormAdvertsCountLoader>
			)
		}
	}
}
