import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SspHelper from 'app/helpers/sspHelper/SspHelper'
import SspCollocationOptions from 'app/helpers/sspCollocation/SspCollocationOptions'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import { RESPONSIVE } from 'app/base/Constants'
import SspContext from '../SspContext'
import ImaContext from 'ima/page/context'
import classnames from 'classnames'
import { useHideAdvert } from 'app/component/sspAdverts/sspHelpers'

import './SquareView.less'

/**
 * ClassName komponenty.
 * @constant
 * @type {String}
 */
const CLASSNAME = 'c-ssp-square'

/**
 * Zone Id ssp reklamní pozice.
 * @constant
 * @type {Number}
 */
const ZONE_ID = 130501

export const RICH_MEDIA_COLLOCATION = 'RM'

const SquareView = (props) => {
	const { id, className = '', height, width, extraCollocation = '' } = props

	const imaContext = useContext(ImaContext)
	const sspContextData = useContext(SspContext)
	const userEntity = useContext(UserEntityContext)

	// priznak, zda si uzivatel plati "Seznam bez reklam" (SBR)
	const isSbrUser = userEntity?.isSbr

	const sspHelper = imaContext.SspHelper

	const squareComponentRef = useRef(null)
	const squareAdvertRef = useRef(null)

	const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true)
	const [isAdFull, setIsAdFull] = useState(false)

	const hideAdvert = useHideAdvert()

	useEffect(() => {
		setIsPlaceholderVisible(sspHelper.isEnabled())
		getAdForSsp(sspContextData)
	}, [sspContextData])

	const getAdForSsp = (pageData) => {
		sspHelper.cleanElement(squareAdvertRef.current)
		squareComponentRef.current?.classList.remove(SspHelper.adFullClassName)

		const options = SspCollocationOptions.getOptions(pageData)

		if (options) {
			const { collocation = '' } = options

			const updatedOptions = Object.assign({}, options, {
				collocation: `${collocation} ${extraCollocation}`
			})

			getAd(updatedOptions)
		} else {
			setIsPlaceholderVisible(false)
		}
	}

	const getAd = (options = {}) => {
		//TODO
		// nez budou na desktop pripravene nove sablony reklamy
		// tak prozatim od 768px nebudeme volat reklamu
		const { matches } = window.matchMedia(`(max-width: ${RESPONSIVE.MOBILE_WIDE_MAX / 16}em)`)

		if (matches) {
			sspHelper.getAds([
				{
					zoneId: ZONE_ID,
					id,
					width: width || 480,
					height: height || 480,
					options,
					callback: (advert, zone) => {
						const { current: elem } = squareComponentRef

						if (advert.type !== 'empty' && elem) {
							setIsAdFull(true)
							elem.style.maxWidth = `${advert.width}px`
						}
						//Prvne tomu nastavime adFull (minimalni vysku a maximalni sirku), potom zavolame writeAd... kdyz toto bylo nad nastavením těchto parametrů, rozstřelila se reklama
						setIsPlaceholderVisible(false)
						sspHelper.writeAd(advert, zone)
					}
				}
			])
		} else {
			setIsPlaceholderVisible(false)
		}
	}

	if (isSbrUser) return null

	return (
		<div
			ref={squareComponentRef}
			className={classnames({
				[`${CLASSNAME}`]: true,
				[className]: !!className,
				[`${CLASSNAME}__placeholder`]: isPlaceholderVisible && !hideAdvert,
				[SspHelper.adFullClassName]: isAdFull
			})}
		>
			<div className={`${CLASSNAME}__wrap`}>
				<div id={id} ref={squareAdvertRef}></div>
			</div>
		</div>
	)
}

SquareView.propTypes = {
	id: PropTypes.string.isRequired,
	className: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
	extraCollocation: PropTypes.string
}

export default React.memo(SquareView)
