
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PremiseSlots'] = {
  limitUsed: function(d) { return "Využito:"; },
  limitValue: function(d) { return "inzerátů"; },
  limitLink: function(d) { return "Navýšit limit"; },
  emailSubject: function(d) { return "Prodejce " + d.PREMISE_NAME + " žádá o navýšení limitu inzerce"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	