import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'
import { SDN } from 'app/base/Constants'

export default class AdvertImageResponsiveSettings {
	/**
	 * Vrati nastaveni pro responzivni obrazky v nahledu
	 *
	 * @method previewSettings
	 * @return {Object}
	 */
	static get previewSettings() {
		return deepFreeze({
			src: (url) => url + SDN.w200
		})
	}

	/**
	 * Vrati nastaveni pro responzivni obrazky
	 *
	 * @method normalSettings
	 * @return {Object}
	 */
	static get normalSettings() {
		return deepFreeze({
			src: (url) => url + SDN.w1024_WATERMARK_CROP,
			sizes: `
				(min-width: 1324px) 800px,
				(min-width: 1200px) calc(100vw - 2 * 48px - 32px - 300px),
				(min-width: 1000px) calc(100vw - 2 * 32px - 32px - 300px),
				(min-width: 600px) calc(100vw - 2 * 24px),
				calc(100vw - 2 * 16px)
			`,
			srcSet: (url) => `
				${url + SDN.w320_WATERMARK_CROP} 320w,
				${url + SDN.w640_WATERMARK_CROP} 640w,
				${url + SDN.w800_WATERMARK_CROP} 800w,
				${url + SDN.w1024_WATERMARK_CROP} 1024w
			`
		})
	}

	/**
	 * Vrati nastaveni pro responzivni obrazky ve fullscreenu
	 *
	 * @method fullscreenSettings
	 * @return {Object}
	 */
	static get fullscreenSettings() {
		return deepFreeze({
			src: (url) => url + SDN.w1024_WATERMARK,
			sizes: '100vw',
			srcSet: (url) => `
				${url + SDN.w320_WATERMARK} 320w,
				${url + SDN.w640_WATERMARK} 640w,
				${url + SDN.w800_WATERMARK} 800w,
				${url + SDN.w1024_WATERMARK} 1024w
			`
		})
	}

	/**
	 * Vrati nastaveni pro sfericke obrazky v nahledu
	 *
	 * @method previewSphereSettings
	 * @return {Object}
	 */
	static get previewSphereSettings() {
		return deepFreeze({
			src: (url) => url + SDN.w800_WATERMARK
		})
	}

	/**
	 * Vrati nastaveni pro sfericke obrazky
	 *
	 * @method normalSphereSettings
	 * @return {Object}
	 */
	static get normalSphereSettings() {
		return deepFreeze({
			// knihovna pro sfericke fotky neumi pracovat se sizes a srcSet
			src: (url) => url + SDN.w1920_WATERMARK
		})
	}

	/**
	 * Vrati nastaveni pro sfericke obrazky ve fullscreenu
	 *
	 * @method fullscreenSphereSettings
	 * @return {Object}
	 */
	static get fullscreenSphereSettings() {
		return deepFreeze({
			// knihovna pro sfericke fotky neumi pracovat se sizes a srcSet
			src: (url) => url + SDN.w1920_WATERMARK
		})
	}
}
