const RESOURCE = '/registrations'

export default class RegistrationResource {
	constructor(restClient) {
		this._restClient = restClient
	}

	getRegistrations(params, options) {
		return this._restClient.get(RESOURCE, params, options)
	}

	getIncrases(params, options) {
		return this._restClient.get(`${RESOURCE}/increase`, params, options)
	}
}
