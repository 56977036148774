import React from 'react'
import PropTypes from 'prop-types'
import LayoutInternalAdmin from 'app/component/layout/internalAdmin/LayoutInternalAdmin'
import Stepper from 'app/page/userweb/newAdvert/component/stepper/StepperView'

export const Layout = React.memo((props) => {
	return <LayoutInternalAdmin {...props} />
})

export const LayoutAdvertEdit = React.memo((props) => {
	const { children, ...restProps } = props

	return (
		<LayoutInternalAdmin {...restProps}>
			<Stepper isEdit={true} />
			{children}
		</LayoutInternalAdmin>
	)
})

LayoutAdvertEdit.propTypes = {
	children: PropTypes.node
}
