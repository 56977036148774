import { AbstractFactory } from '@inzeraty/models'
import ReviewEntity from './ReviewEntity'

export default class ReviewFactory extends AbstractFactory {
	/**
	 * @constructor
	 */
	constructor() {
		super(ReviewEntity)
	}

	/**
	 * Prevede data z api na data entity.
	 *
	 * @method transformData
	 * @param {Object} data
	 * @return {Object}
	 */
	transformData(data) {
		return {
			createDate: data.create_date,
			editDate: data.edit_date,
			fuelCb: data.fuel_cb,
			id: data.id,
			manufacturerCb: data.manufacturer_cb,
			manufacturingYear: data.manufacturing_year,
			modelCb: data.model_cb,
			negatives: data.negatives,
			negativeFeedbackCount: data.negative_feedback_count,
			positives: data.positives,
			positiveFeedbackCount: data.positive_feedback_count,
			power: data.power,
			review: data.review,
			reviewReplies: data.review_replies,
			repliesCount: data.replies_count,
			title: data.title,
			type: data.type,
			userEmailPrefix: data.user_email_prefix,
			userFeedbackType: data.user_feedback_type,
			userId: data.user_id,
			userName: data.user_name
		}
	}
}
