import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import SimpleDataWidget from 'app/page/userweb/clientProfile/widgets/simpleData/SimpleDataWidget.jsx'

import './logoWidget.less'

export const LOGO_WIDGET_ID = 'logo_widget'

const CLASSNAME = 'c-cp-logo-widget'

function LogoWidget({ formLineEntity }) {
	return (
		<div className={CLASSNAME}>
			<SimpleDataWidget formLineEntity={formLineEntity} className={CLASSNAME} />
		</div>
	)
}

LogoWidget.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)])
}

export default LogoWidget
