import IAAdvertEditBaseController from '../IAAdvertEditBaseController'
import { HttpErrorHelper } from '@inzeraty/helpers'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import * as FormLines from '@inzeraty/form-lines'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import STATE_KEYS from './IAHistoryStateKeys'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/history/component/historyForm/HistoryFormLineIds'
import { createFormLineEntities } from 'app/page/userweb/newAdvert/history/component/historyForm/HistoryFormLines'
import { HISTORY_SECTION } from 'app/page/userweb/newAdvert/history/component/historyForm/HistorySections'
import STEPS_STATE_KEYS from 'app/page/userweb/newAdvert/component/stepper/StepperStateKeys'
import { loadDataForSteps } from 'app/page/userweb/newAdvert/component/stepper/StepperUtils'
import { getSteps } from '../stepper/steps'
import { StatusMessage } from '@inzeraty/components'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'

export default class IAHistoryController extends IAAdvertEditBaseController {
	constructor(dependenciesHelper, advertService) {
		super(dependenciesHelper)

		this._advertService = advertService
		this._dictionary = this._utils.$Dictionary

		this._changeFormLineEntities = this._changeFormLineEntities.bind(this)
		this._setFormStatus = this._setFormStatus.bind(this)
		this._submitForm = this._submitForm.bind(this)
		this._scrollPageToTop = this._scrollPageToTop.bind(this)
		this._processServerErrors = this._processServerErrors.bind(this)
		this.submitSaveCertForm = this.submitSaveCertForm.bind(this)
	}

	init() {
		super.init()
	}

	load() {
		const advertId = this._getAdvertId()

		const advertEntityPromise = this._advertService.getAdvertDetail(
			Number(advertId),
			{ for_editing: 1 },
			{ cache: false }
		)

		const steps = loadDataForSteps(
			advertId,
			advertEntityPromise,
			this._advertService,
			(categoryId) => getSteps(categoryId, advertId, this._utils.$Router)
		)

		const createPagePromise = advertEntityPromise.then((advertEntity) => {
			const { category: { id: categoryId } = {} } = advertEntity

			return this._advertService.getCreatePage(
				{
					[UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: categoryId
				},
				{ cache: true }
			)
		})

		// sestaveni prvku na formulari
		const formLineEntitiesPromise = Promise.all([advertEntityPromise, createPagePromise]).then(
			([advertEntity, createPage]) => {
				const elements = createPage.sections.reduce((allElements, section) => {
					const { elements } = section
					return [...allElements, ...elements]
				}, [])

				const formLineEntities = createFormLineEntities(advertEntity, this._dictionary)
					// - vyfiltruji si formLineEntity, pro danou sekci
					.filter(({ id }) => HISTORY_SECTION.includes(id))
					// - vyfiltruji si formLineEntity ktere jsou v creagePage
					.filter(
						(formLineEntity) => !!elements.find((element) => element.name === formLineEntity.id)
					)
					// - nastavim formLineEntitam options
					.map((formLineEntity) => {
						const element = elements.find((element) => element.name === formLineEntity.id)
						const { options = [] } = formLineEntity
						return FormLines.updateEntity(formLineEntity, {
							options: options.length ? options : element.options
						})
					})

				return this._setDisabledEnvironmentalTaxPaidByEuroLevel(formLineEntities)
			}
		)

		return super.load({
			[STEPS_STATE_KEYS.STEPS]: steps,

			[STATE_KEYS.FORM_LINES]: formLineEntitiesPromise,
			[STATE_KEYS.ON_CHANGE]: this._changeFormLineEntities,
			[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false,
			[STATE_KEYS.SUBMIT_FORM]: this._submitForm,
			[STATE_KEYS.SET_FORM_STATUS]: this._setFormStatus,
			[STATE_KEYS.SCROLL_PAGE_TO_TOP]: this._scrollPageToTop,

			[STATE_KEYS.SUBMIT_SAVE_CERT_FORM]: this.submitSaveCertForm,
			[STATE_KEYS.ADVERT_ENTITY]: advertEntityPromise
		})
	}

	_setDisabledEnvironmentalTaxPaidByEuroLevel(formLineEntities) {
		const euroLevelFormLineEntity = formLineEntities.find(
			(formLineEntity) => formLineEntity.id === FORM_LINES_IDS.EURO_LEVEL_CB
		)
		const envTaxFormLineEntity = formLineEntities.find(
			(formLineEntity) => formLineEntity.id === FORM_LINES_IDS.ENVIRONMENTAL_TAX_PAID
		)

		const changes = {}

		if (euroLevelFormLineEntity && envTaxFormLineEntity) {
			const { value: euroLevel = {} } = euroLevelFormLineEntity

			changes.id = FORM_LINES_IDS.ENVIRONMENTAL_TAX_PAID

			changes.extra = Object.assign({}, envTaxFormLineEntity.extra, {
				disabled: euroLevel.value > 2
			})

			if (changes.extra.disabled) {
				changes.value = ''
			}
		}

		return FormLines.updateEntities(formLineEntities, [changes])
	}

	_changeFormLineEntities(changes) {
		const { [STATE_KEYS.FORM_LINES]: formLinesEntities = {} } = this.getState()

		const updatedFormLineEntities = FormLines.updateEntities(formLinesEntities, changes)

		this.setState({
			[STATE_KEYS.FORM_LINES]: this._setDisabledEnvironmentalTaxPaidByEuroLevel(
				updatedFormLineEntities
			)
		})
	}

	async _submitForm({ formLineEntities, onErrorCallback }) {
		const defaultFormData = {}

		const formData = formLineEntities.reduce((allFormData, f) => {
			const { value, extra: { getFormData } = {} } = f

			if (getFormData) {
				return Object.assign({}, allFormData, getFormData(value))
			} else {
				/* eslint-disable */
				console.warn(`Field "${f.id}" is missing "getFormData" function.`)
				/* eslint-enable */

				return allFormData
			}
		}, defaultFormData)

		const formDataProcessed = Object.keys(formData).reduce(
			(formDataProcessed, formKey) =>
				Object.assign({}, formDataProcessed, {
					[formKey]: formData[formKey] === undefined ? null : formData[formKey]
				}),
			{}
		)

		this._pageLoaderExtension.show()

		try {
			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: true
			})

			const advertId = this._getAdvertId()

			await this._advertService.patchAdvert(advertId, formDataProcessed)

			this._onSubmitFormSuccessCallback()
		} catch (error) {
			const errors = this._processServerErrors(error)
			this._onSubmitFormErrorCallback()

			onErrorCallback(errors)
		} finally {
			this._pageLoaderExtension.hide()

			this.setState({
				[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: false
			})
		}
	}

	_setFormStatus({ statusCode, extra }) {
		this.setState({
			[STATE_KEYS.FORM_STATUS]: { statusCode, extra }
		})
	}

	_scrollPageToTop() {
		animateScrollTo(0)
	}

	_processServerErrors(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)
		let errors = []

		if (httpStatus === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
			const { body = {} } = HttpErrorHelper.getParams(error)
			errors = body.errors
		}

		return errors
	}

	async submitSaveCertForm({ formData, onErrorCallback, onSuccessCallback }) {
		const advertId = this._getAdvertId()

		try {
			await this._advertService.saveCebiaCert(Number(advertId), formData)
			onSuccessCallback()

			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				type: StatusMessage.TYPE.SUCCESS,
				title: this._dictionary.get('ActionMessage.titleSuccess'),
				text: 'Podařilo se uložit cebia certifikát'
			})
		} catch (error) {
			const errors = this._processServerErrors(error)
			onErrorCallback(errors)
		}
	}
}
