import React from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'
import { Button, Surface } from '@sznds/react'
import { DrtgHelper as InzeratyDrtgHelper } from '@inzeraty/helpers'
import getRouteName from 'app/helpers/routeName/getRouteName'
import AbstractComponent from 'app/base/AbstractComponent'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import operatingLeasePropTypes from 'app/model/operatingLease/OperatingLeasePropTypes'
import SellerCard from 'app/component/sellerCard/SellerCard'
import SellerContactHeader from 'app/component/sellerContactHeader/SellerContactHeader'
import PopupDemandForm from 'app/page/userweb/advertDetail/sellerContactSection/popupDemandForm/PopupDemandForm'
import { stickyHOC } from '@inzeraty/components'
import select from 'ima-plugin-select'
import STATE_KEYS from 'app/page/userweb/advertDetail/AdvertDetailStateKeys'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

import './SellerContactSection.less'
import './SellerContactSectionCS.json'

const CLASSNAME = 'c-seller-contact-section'
export const STICKY_PANEL_CLASSNAME = `${CLASSNAME}__sticky-panel`
const StickyWrapper = stickyHOC((props) => <div>{props.children}</div>)

class SellerContactSection extends AbstractComponent {
	static get propTypes() {
		return {
			advertEntity: PropTypes.shape(advertPropTypes),
			cachedAdvertEntity: PropTypes.shape(advertPropTypes),
			premiseAdvertsCount: PropTypes.number,
			isAdvertEntityLoaded: PropTypes.bool,
			isPremiseAdvertsCountLoaded: PropTypes.bool,
			isOperatingLeasePage: PropTypes.bool,
			selectedOperatingLeaseVariant: PropTypes.shape(operatingLeasePropTypes)
		}
	}

	static get defaultProps() {
		return {
			cachedAdvertEntity: DEFAULT_PROPS.OBJECT,
			isAdvertEntityLoaded: false,
			isOperatingLeasePage: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this._handleClickOnCallButton = this._handleClickOnCallButton.bind(this)
		this._updateStickyCTA = this._updateStickyCTA.bind(this)
		this._stickContactButtonsToTop = this._stickContactButtonsToTop.bind(this)
		this._stickContactButtonsWhen = this._stickContactButtonsWhen.bind(this)
		this._onOpenDemandForm = this._onOpenDemandForm.bind(this)
		this._getLocationOnPage = this._getLocationOnPage.bind(this)

		this._isTopSticky = false
		this._isSticky = false
		this._locationOnPage = SellerCard.LOCATION_ON_PAGE.STATIC
		this._locationForDemandForm = SellerCard.LOCATION_ON_PAGE.STATIC

		this.state = {
			isFullSellerDescriptionShown: false,
			isContactOpen: false
		}
	}

	componentDidMount() {
		this.utils.$Dispatcher.listen('updateStickyCTA', this._updateStickyCTA, this)
	}

	componentWillUnmount() {
		this.utils.$Dispatcher.unlisten('updateStickyCTA', this._updateStickyCTA, this)
	}

	render() {
		return (
			<Surface tagName='div' surface={5} className={CLASSNAME} data-dot='seller-card'>
				<React.Fragment>
					<h2 className={`${CLASSNAME}__heading`}>
						{this.localize('SellerContactSection.sellerContactHeader')}
					</h2>
					{this._renderResponsiveContent()}
					{this._renderSellerCard()}

					<div className={`${CLASSNAME}__law-stuff`}>
						{this.localize('SellerContactSection.lawStuff')}
					</div>
				</React.Fragment>
			</Surface>
		)
	}

	_renderResponsiveContent() {
		return (
			<div className={`${CLASSNAME}__responsive`}>
				{this._renderSellerContactHeader()}
				{this._renderSellerDescription()}
				{this._renderStickyElement(this._renderContactButtons())}
			</div>
		)
	}

	_renderStickyElement(stickyElement) {
		const { advertEntity } = this.props

		if (advertEntity) {
			return (
				<StickyWrapper
					classNameForStickyPanel={STICKY_PANEL_CLASSNAME}
					stickToTop={this._stickContactButtonsToTop}
					stickWhen={this._stickContactButtonsWhen}
				>
					{stickyElement}
				</StickyWrapper>
			)
		} else {
			return stickyElement
		}
	}

	_getLocationOnPage() {
		return this._locationOnPage
	}

	_setLocationOnPage() {
		if (!this._isSticky) {
			this._locationOnPage = SellerCard.LOCATION_ON_PAGE.STATIC
		} else if (this._isTopSticky) {
			this._locationOnPage = SellerCard.LOCATION_ON_PAGE.STICKY_TOP
		} else {
			this._locationOnPage = SellerCard.LOCATION_ON_PAGE.STICKY_BOTTOM
		}
	}

	_onOpenDemandForm() {
		this._locationForDemandForm = this._getLocationOnPage()
		this._sendContactSellerAnalyticsData('ShowForm')
		this._sendConversionHit()

		this.setState({
			isContactOpen: true
		})
	}

	_renderSellerCard() {
		const { isAdvertEntityLoaded } = this.props
		const loadedAdvertEntity = this._getAdvertEntity()
		const { premise: premiseEntity } = loadedAdvertEntity

		return (
			<SellerCard
				className={`${CLASSNAME}__seller-card`}
				advertEntity={loadedAdvertEntity}
				premiseEntity={premiseEntity}
				isDataLoaded={isAdvertEntityLoaded}
				classNameForPremiseSeller={`${CLASSNAME}__premise-card`}
				classNameForPrivateSeller={`${CLASSNAME}__private-card`}
				getLocationOnPage={this._getLocationOnPage}
			/>
		)
	}

	_renderSellerContactHeader() {
		const {
			isOperatingLeasePage,
			premiseAdvertsCount,
			isAdvertEntityLoaded,
			isPremiseAdvertsCountLoaded
		} = this.props
		const loadedAdvertEntity = this._getAdvertEntity()
		const { premise: premiseEntity } = loadedAdvertEntity

		return (
			<SellerContactHeader
				className={`${CLASSNAME}__header`}
				premiseAdvertsCount={premiseAdvertsCount}
				isDataLoaded={isAdvertEntityLoaded}
				premiseEntity={premiseEntity}
				isOperatingLease={isOperatingLeasePage}
				isPremiseAdvertsCountLoaded={isPremiseAdvertsCountLoaded}
			/>
		)
	}

	_renderSellerDescription() {
		const { isFullSellerDescriptionShown } = this.state
		const { premise: { description } = {} } = this._getAdvertEntity() || {}

		if (description) {
			return (
				<div className={`${CLASSNAME}__seller-desc`}>
					<div className={`${CLASSNAME}__seller-desc--mobile`}>
						<Truncate
							lines={isFullSellerDescriptionShown ? false : 2} // false znamena, ze pocet radku neni nijak omezen
							trimWhitespace={true}
							ellipsis={
								<>
									...{' '}
									<button
										className={`${CLASSNAME}__seller-desc-show-more`}
										onClick={() => this.setState({ isFullSellerDescriptionShown: true })}
									>
										{this.localize('SellerContactSection.showMore')}
									</button>
								</>
							}
						>
							{description}
						</Truncate>
					</div>
					<div className={`${CLASSNAME}__seller-desc--desktop`}>{description}</div>
				</div>
			)
		} else {
			return null
		}
	}

	_stickContactButtonsToTop(data) {
		const { viewportTopBorderPosition, elementTopBorderPosition } = data

		this._isTopSticky = viewportTopBorderPosition >= elementTopBorderPosition

		this._setLocationOnPage()

		return this._isTopSticky
	}

	_stickContactButtonsWhen(data) {
		const {
			isElementInsideViewport,
			isElementCrossingViewportTopBorder,
			isElementCrossingViewportBottomBorder,
			isElementStuck,
			viewportTopBorderPosition,
			isElementAlreadyStuckForOnce
		} = data

		if (isElementInsideViewport) {
			// element prave zacina byt videt ve viewportu, ale dokud neni videt cely,
			// tak ho porad stickujeme
			this._isSticky =
				(isElementStuck && isElementCrossingViewportTopBorder) ||
				(isElementStuck && isElementCrossingViewportBottomBorder)
		} else {
			// element neni videt
			this._isSticky = viewportTopBorderPosition > 0 || isElementAlreadyStuckForOnce
		}

		this._setLocationOnPage()

		return this._isSticky
	}

	_renderContactButtons() {
		const { advertEntity, advertEntity: { phone, dontShowPhone = false } = {} } = this.props

		if (!advertEntity) {
			// placeholder pro tlacitko
			return (
				<div className={`${CLASSNAME}__contact-buttons`}>
					<Button primary={true} text={' '} className={`${CLASSNAME}__button`} disabled={true} />
				</div>
			)
		}

		if (phone && !dontShowPhone) {
			return (
				<div className={`${CLASSNAME}__contact-buttons`}>
					<Button
						text={this.localize('SellerContactSection.callSeller')}
						href={`tel:${phone}`}
						onClick={this._handleClickOnCallButton}
						className={`${CLASSNAME}__button ${CLASSNAME}__call-button`}
						data-dot='call'
					/>

					{this._renderSendDemandButton()}
				</div>
			)
		} else {
			return (
				<div className={`${CLASSNAME}__send-demand-button`}>{this._renderSendDemandButton()}</div>
			)
		}
	}

	_renderSendDemandButton() {
		const advertEntity = this._getAdvertEntity()
		const { premise: { name } = {} } = advertEntity
		const sellerName = name || this.localize('SellerContactSection.privateSeller')
		const { isOperatingLeasePage, selectedOperatingLeaseVariant } = this.props
		const { isContactOpen } = this.state

		return (
			<>
				<Button
					primary={true}
					surface={3}
					text={this.localize('SellerContactSection.textSeller')}
					className={`${CLASSNAME}__button`}
					data-dot='show-form-answer'
					onClick={this._onOpenDemandForm}
				/>
				{isContactOpen && (
					<PopupDemandForm
						advertEntity={advertEntity}
						selectedOperatingLeaseVariant={
							isOperatingLeasePage ? selectedOperatingLeaseVariant : undefined
						}
						sellerName={sellerName}
						getLocationOnPage={() => this._locationForDemandForm}
						isOpen={isContactOpen}
						onClose={() => {
							this.setState({
								isContactOpen: false
							})
						}}
					/>
				)}
			</>
		)
	}

	_handleClickOnCallButton() {
		this._sendAnalyticsDataOnClickCallButton()
		this._sendContactSellerAnalyticsData('Call')
		this._sendConversionHit()
	}

	_getRouteName() {
		return getRouteName(this.utils.$Router) || ''
	}

	_sendAnalyticsDataOnClickCallButton() {
		const { advertEntity } = this.props
		const { AnalyticsDataSender } = this.utils

		AnalyticsDataSender.hitForPhoneNumber(advertEntity, this._getRouteName())
	}

	_sendContactSellerAnalyticsData(type) {
		const { AnalyticsDataSender } = this.utils

		AnalyticsDataSender.hitContactSeller({
			type,
			page: this._getRouteName(),
			location: this._getLocationOnPage()
		})
	}

	_updateStickyCTA() {
		this.forceUpdate()
	}

	_getAdvertEntity() {
		const { advertEntity, cachedAdvertEntity } = this.props
		return advertEntity || cachedAdvertEntity
	}

	_sendConversionHit() {
		const { advertEntity } = this.props
		const { premise: { externalId } = {} } = advertEntity

		if (externalId === 9664) {
			// 9664 je Sauto ID (= external id) pro 'KARIREAL a.s. - Autorizovaný partner ŠKODA',
			// jenom pro tento jeden konkretni bazar chceme posilat konverzni hity
			InzeratyDrtgHelper.conversionHit({
				id: 100110039,
				seznamValue: 1000
			})
		}
	}
}

const SellerContactSectionSelector = (state) => ({
	advertEntity: state[STATE_KEYS.ADVERT_ENTITY],
	cachedAdvertEntity: state[STATE_KEYS.CACHED_ADVERT_ENTITY],
	premiseAdvertsCount: state[STATE_KEYS.PREMISE_ADVERTS_COUNT],
	isAdvertEntityLoaded: state[STATE_KEYS.IS_ADVERT_ENTITY_LOADED],
	isPremiseAdvertsCountLoaded: state[STATE_KEYS.IS_PREMISE_ADVERTS_COUNT_LOADED],
	isOperatingLeasePage: state[STATE_KEYS.IS_OPERATING_LEASE_PAGE],
	selectedOperatingLeaseVariant: state[STATE_KEYS.SELECTED_OPERATING_LEASE_VARIANT]
})

export default select(SellerContactSectionSelector)(SellerContactSection)
