import {
	AdvertResource,
	AdvertStatisticsResource as AdvertStatisticsResourceBase
} from '@inzeraty/models'

const RESOURCE = '/statistics'

export default class AdvertStatisticsResource extends AdvertStatisticsResourceBase {
	/**
	 * Ziska dilci statistiky pro jednotlive inzeraty na vypise inzeratu v KA.
	 *
	 * @method getStatisticsForListItems
	 * @param {Object} params
	 * @param {Object} options
	 * @return {Promise<Object>} Asociativni pole, kde klicem jsou idecka inzeratu
	 **/
	getStatisticsForListItems(params, options) {
		return this._restClient.get(`${AdvertResource.RESOURCE}${RESOURCE}/items_list`, params, options)
	}

	exportStatistics(params, options) {
		return this._restClient.post(`${AdvertResource.RESOURCE}${RESOURCE}/export`, params, options)
	}
}
