import { DateHelpers } from '@inzeraty/helpers'

export default class AdvertFactory {
	constructor(premiseFactory) {
		this._premiseFactory = premiseFactory
	}

	static getAdvertNamesToDisplay(fullName = '', restName = '') {
		const trimmedRestName = restName && restName.trim()
		let index = -1

		if (fullName && fullName.endsWith(trimmedRestName))
			index = fullName.lastIndexOf(trimmedRestName)

		return {
			namePrefix: index >= 0 ? fullName.substr(0, index) : fullName || '',
			nameSuffix: index >= 0 ? fullName.substr(index) : ''
		}
	}

	transformData(data) {
		const locality = data.locality || {}
		const fuelCb = data.fuel_cb
		const vehicleBodyCb = data.vehicle_body_cb
		const user = data.user || {}
		const user_service = user.user_service || {}
		const equipment = data.equipment_cb
		const operatingLeaseVariants = data.operating_lease_variants

		const { namePrefix, nameSuffix } = AdvertFactory.getAdvertNamesToDisplay(
			data.name,
			data.additional_model_name
		)

		return {
			additionalModelName: data.additional_model_name,
			adjustmentsForHandicapped: data.adjustments_for_handicapped,
			advertReportsCount: data.advert_reports_count,
			airbags: data.airbags,
			airconditionCb: data.aircondition_cb || undefined,
			approvalAdmin: data.approval_admin,
			availabilityCb: data.availability_cb || undefined,
			averageElectricMileage: data.average_electric_mileage,
			averageGasMileage: data.average_gas_mileage,
			deactivationDate: data.deactivation_date
				? DateHelpers.getDateObject(data.deactivation_date)
				: undefined,
			deactivationReason: data.deactivation_reason,
			dealType: data.deal_type,
			batteryCapacity: data.battery_capacity,
			beds: data.beds,
			capacity: data.capacity,
			category: data.category
				? {
						id: data.category.id,
						name: data.category.name,
						seoName: data.category.seo_name
				  }
				: undefined,
			cebiaReport: data.cebia_report,
			cebiaSmartCodeUrl: data.cebia_smart_code_url,
			certificationCb: data.certification_cb || undefined,
			colorCb: data.color_cb || undefined,
			colorToneCb: data.color_tone_cb || undefined,
			colorTypeCb: data.color_type_cb || undefined,
			conditionCb: data.condition_cb || undefined,
			countryOfOriginCb: data.country_of_origin_cb || undefined,
			crashedInPast: data.crashed_in_past,
			createDate: data.create_date ? DateHelpers.getDateObject(data.create_date) : undefined,
			customId: data.custom_id || '',
			deliveryDate: data.delivery_date ? DateHelpers.getDateObject(data.delivery_date) : undefined,
			description: data.description,
			dontShowPhone: data.dont_show_phone,
			doors: data.doors,
			driveCb: data.drive_cb || undefined,
			editDate: data.edit_date ? DateHelpers.getDateObject(data.edit_date) : undefined,
			email: data.email,
			enginePower: data.engine_power,
			engineVolume: data.engine_volume,
			environmentalTaxPaid: data.environmental_tax_paid,
			equipmentCb: equipment ? this._mapEquipment(equipment) : undefined,
			euroLevelCb: data.euro_level_cb || undefined,
			firstOwner: data.first_owner,
			fuelCb: fuelCb && {
				name: fuelCb.name,
				seoName: fuelCb.seo_name,
				value: fuelCb.value
			},
			gearboxCb: data.gearbox_cb || undefined,
			gearboxLevelsCb: data.gearbox_levels_cb || undefined,
			guaranteeDate: data.guarantee_date
				? DateHelpers.getDateObject(data.guarantee_date)
				: undefined,
			hideVin: data.hide_vin,
			homeDelivery: data.home_delivery || false,
			id: data.id,
			images: data.images || undefined,
			imagesTotalCount: data.images_total_count,
			inOperationDate: data.in_operation_date
				? DateHelpers.getDateObject(data.in_operation_date)
				: undefined,
			isCebiaSmartCodeUrlVerified: data.is_cebia_smart_code_url_verified || false,
			loadCapacity: data.load_capacity,
			lastToppedDate: data.last_topped_date,
			locality: {
				address: locality.address,
				addressId: locality.address_id,
				cityPart: locality.citypart,
				countryId: locality.country_id,
				district: locality.district,
				districtId: locality.district_id,
				districtSeoName: locality.district_seo_name,
				entityId: locality.entity_id,
				entityType: locality.entity_type,
				houseNumber: locality.housenumber,
				municipality: locality.municipality,
				municipalityId: locality.municipality_id,
				municipalitySeoName: locality.municipality_seo_name,
				quarter: locality.quarter,
				quarterId: locality.quarter_id,
				region: locality.region,
				regionId: locality.region_id,
				regionSeoName: locality.region_seo_name,
				source: locality.source,
				street: locality.street,
				streetId: locality.street_id,
				streetNumber: locality.streetnumber,
				ward: locality.ward,
				wardId: locality.ward_id
			},
			machineHours: data.machine_hours,
			manufacturerCb: data.manufacturer_cb
				? {
						name: data.manufacturer_cb.name,
						seoName: data.manufacturer_cb.seo_name,
						value: data.manufacturer_cb.value
				  }
				: undefined,
			manufacturingDate: data.manufacturing_date
				? DateHelpers.getDateObject(data.manufacturing_date)
				: undefined,
			modelCb: data.model_cb
				? {
						name: data.model_cb.name,
						seoName: data.model_cb.seo_name,
						value: data.model_cb.value
				  }
				: undefined,
			name: data.name,
			namePrefix,
			nameSuffix,
			oldId: data.old_id, // jen docasne reseni, kvuli statistikam potrebujeme uchovavat id inzeratu ze stareho Sauta
			operatingLeaseIntendedForCb: data.operating_lease_intended_for_cb
				? data.operating_lease_intended_for_cb.map((intend) => ({
						name: intend.name,
						seoName: intend.seo_name,
						value: intend.value
				  }))
				: undefined,
			operatingLeaseVariants: operatingLeaseVariants
				? this._mapOperatingLease(operatingLeaseVariants)
				: undefined,
			operatingLeasePriceWithVat: data.operating_lease_price_with_vat,
			operatingLeasePriceWithoutVat: data.operating_lease_price_without_vat,
			phone: data.phone,
			price: data.price || undefined,
			priceOriginal: data.price_original || undefined,
			priceCompensation: data.price_original_compensation || undefined, // odstupne jen v pripade splatek
			priceLeasing: data.price_leasing || undefined,
			priceNote: data.price_note,
			pricePayment: data.price_payment || undefined,
			pricePaymentCount: data.price_payment_count || undefined,
			priceIsVatDeductible: data.price_is_vat_deductible,
			priceWithoutVat: data.price_without_vat || undefined,
			priceIsWithoutVat: data.price_is_without_vat,
			preferredOffer: data.preferred_offer,
			seatsCb: data.seats_cb || undefined,
			seoName: data.seo_name,
			serviceBook: data.service_book,
			sortingDate: data.sorting_date ? DateHelpers.getDateObject(data.sorting_date) : undefined,
			source: data.source,
			status: data.status,
			stkDate: data.stk_date ? DateHelpers.getDateObject(data.stk_date) : undefined,
			tachometer: data.tachometer,
			topped: data.topped,
			toppedCount: data.topped_count,
			tuning: data.tuning,
			user: {
				enabled: user.enabled,
				id: user.id,
				note: user.note,
				portraitUrl: user.portrait_url,
				uboxCreatedDate: user.ubox_created_date
					? DateHelpers.getDateObject(user.ubox_created_date)
					: undefined,
				userService: {
					createdDate: user_service.created_date
						? DateHelpers.getDateObject(user_service.created_date)
						: undefined,
					serviceEnabled: user_service.service_enabled,
					serviceName: user_service.service_name,
					shopDescription: user_service.shop_description,
					shopName: user_service.shop_name,
					shopUrl: user_service.shop_url
				}
			},
			premise: data.premise
				? this._premiseFactory.createEntity(this._premiseFactory.transformData(data.premise))
				: undefined,
			validToDate: data.valid_to ? DateHelpers.getDateObject(data.valid_to) : undefined,
			vehicleBodyCb: vehicleBodyCb && {
				name: vehicleBodyCb.name,
				seoName: vehicleBodyCb.seo_name,
				value: vehicleBodyCb.value
			},
			vehicleRange: data.vehicle_range,
			videos: data.videos || undefined,
			vin: data.vin,
			weight: data.weight,
			windshieldNote: data.windshield_note
		}
	}

	_mapEquipment(equipmentArr = []) {
		return equipmentArr.map((equipment) => {
			return {
				name: equipment.name,
				value: equipment.value,
				category: equipment.equipment_category
			}
		})
	}

	_mapOperatingLease(operatingLeaseArr = []) {
		return operatingLeaseArr.map((operatingLease) => {
			return {
				id: operatingLease.id,
				period: operatingLease.period,
				annualDistance: operatingLease.annual_distance,
				servicesCb: operatingLease.services_cb || undefined,
				additionalInfo: operatingLease.additional_info,
				priceWithVat: operatingLease.price_with_vat,
				priceWithoutVat: operatingLease.price_without_vat
			}
		})
	}
}
