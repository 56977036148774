import { Format } from '@inzeraty/helpers'
import SelectItem from './SelectItem'

export default class PeriodItem extends SelectItem {
	constructor(id, localize) {
		super(id)
		this.name = localize('Base.month', {
			MONTH: id,
			MONTH_FORMATTED: Format.number(id)
		})
	}
}
