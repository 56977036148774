import deepFreeze from 'app/helpers/deepFreeze/DeepFreeze'

export default class CatalogUrlConvertor {
	/**
	 * Vrati konstanty Url konvertoru
	 *
	 * @method constants
	 * @return {Object}
	 */
	static get constants() {
		return deepFreeze({
			URL_APP_PARAMS: {
				BRAND_SEO_NAME: 'brandSeoName',
				MODEL_SEO_NAME: 'modelSeoName',
				GENERATION_SEO_NAME: 'generationSeoName',
				MODIFITACION_SEO_NAME: 'modificationSeoName'
			},
			URL_API_PARAMS: {
				MANUFACTURER_ID: 'manufacturer_id',
				MODEL_ID: 'model_id',
				GENERATION_ID: 'generation_id',
				MODIFICATION_ID: 'modification_id'
			}
		})
	}
}
