import { createEntityList } from '@inzeraty/form-lines'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { FILTERS } from 'app/component/entitiesFilter/EntitiesFilterExtension'
import { CATEGORIES } from 'app/base/Constants'

export default class IAArticlesListController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, articlesService, entitiesFilterExtension) {
		super(dependenciesHelper)

		this._articlesService = articlesService
		this._entitiesFilterExtension = entitiesFilterExtension
		this._router = this._utils.$Router
		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)
		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})

		const routeParams = this.getRouteParams()

		if (!routeParams.category_id) {
			const { route } = this._router.getCurrentRouteInfo()
			this._router.redirect(
				this._router.link(route.getName(), {
					category_id: CATEGORIES.PASSENGER_CARS.id
				})
			)
		}
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		})
	}

	async getEntitiesAndPagination() {
		const pageLoaderId = this._pageLoaderExtension.show()

		const routeParams = this.getRouteParams()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)

		if (!params.category_id) {
			return
		}

		const entitiesAndPagination = await this._articlesService.getArticlesList(params)

		this._pageLoaderExtension.hide(pageLoaderId)

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	update() {
		return {
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isLoading: true
		}
	}

	_filterInitiezerClb() {
		return createEntityList([
			this._entitiesFilterExtension.getSelect(
				FILTERS.CATEGORY.ID,
				FILTERS.CATEGORY.NAME,
				FILTERS.CATEGORY.PLACEHOLDER,
				FILTERS.CATEGORY.OPTIONS,
				false
			),
			this._entitiesFilterExtension.getSelect(
				FILTERS.MANUFACTURER.ID,
				FILTERS.MANUFACTURER.NAME,
				FILTERS.MANUFACTURER.PLACEHOLDER
			),
			this._entitiesFilterExtension.getSelect(
				FILTERS.MODEL.ID,
				FILTERS.MODEL.NAME,
				FILTERS.MODEL.PLACEHOLDER
			)
		])
	}
}
