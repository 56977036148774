export default class PreferredOffersService {
	constructor(preferredOffersResource, preferredOffersFactory, paginationFactory) {
		this._preferredOffersResource = preferredOffersResource
		this._preferredOffersFactory = preferredOffersFactory
		this._paginationFactory = paginationFactory
	}

	getPreferredOffers(params = {}, options = {}) {
		return this._preferredOffersResource.getPreferredOffers(params, options).then((response) => {
			const { results, pagination } = response.body
			const preferredOffersData = results.map((offer) =>
				this._preferredOffersFactory.transformData(offer)
			)

			return {
				preferredOffersEntities: this._preferredOffersFactory.createEntityList(preferredOffersData),
				paginationEntity: this._paginationFactory.createEntity(pagination)
			}
		})
	}
}
