export default class CodebooksValuesEntity {
	/**
	 * @constructor
	 * @param {Object}  data
	 * @param {Number}  data.id         Id
	 * @param {String}  data.name       Název
	 * @param {String}  data.seoName    SEO název
	 * @param {Number}	data.value
	 * @param {Boolean} data.forFilters Určuje jestli je určený pro filtrovaní
	 * @param {Boolean} data.isFavorite Určuje jestli je oblíbený
	 * @param {String}	data.category
	 * @param {Object}  data.codebook
	 * @param {Number}	data.codebook.id	Id ciselniku
	 * @param {String}	data.codebook.name	Název číselníku
	 * @param {Object}	data.cedebook.category
	 * @param {Number}	data.codebook.category.id	Id kategorie číselníku
	 * @param {String}	data.codebook.category.name	Název kategorie číselníku
	 * @param {Number}	data.parent.id
	 * @param {String}	data.parent.name
	 * @param {Number}	data.parent.value
	 */
	constructor(data) {
		const { id: codebookId, name: codebookName, category: codebookCategory = {} } = data.codebook

		const { id: codebookCategoryId, name: codebookCategoryName } = codebookCategory

		const { id: parentId, name: parentName, value: parentValue } = data.parent || {}

		this.id = data.id
		this.name = data.name
		this.seoName = data.seoName
		this.value = data.value
		this.forFilters = data.forFilters
		this.isFavorite = data.isFavorite
		this.category = data.category
		this.codebookId = codebookId
		this.codebookName = codebookName
		this.codebookCategoryId = codebookCategoryId
		this.codebookCategoryName = codebookCategoryName
		this.parentId = parentId
		this.parentName = parentName
		this.parentValue = parentValue
	}
}
