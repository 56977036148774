
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['IAAdvertHistory'] = {
  title: function(d) { return "Historie změn inzerátu " + d.ADVERT_ID; },
  nothingFound: function(d) { return "Nenašli jsme žadnou historii změn"; },
  date: function(d) { return "Změněno"; },
  ipAddress: function(d) { return "IP adresa"; },
  author: function(d) { return "Autor"; },
  changes: function(d) { return "Změny"; },
  showDetails: function(d) { return "Zobrazit detaily"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	