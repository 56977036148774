import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { Icon, Surface } from '@sznds/react'
import { CHEVRON_UP_FILLED_16, CHEVRON_DOWN_FILLED_16 } from '@sznds/icons'
import { isSpecialSeoMultiOptionFilter } from '../../seo/multiOptionFilterLinks'

import './FilterExtendedInfo.less'

const CLASSNAME = 'c-filter-extended-info'

const FilterExtendedInfo = (props) => {
	const {
		formLineEntity = DEFAULT_PROPS.OBJECT,
		text,
		children,
		isFilterOpen,
		counter,
		dataE2E
	} = props

	const [isOpen, setIsOpen] = useState(isFilterOpen)

	const iconSymbol = isOpen ? CHEVRON_UP_FILLED_16 : CHEVRON_DOWN_FILLED_16

	const isRangeFilter = (formLineEntity) => {
		const { value: { valueFrom, valueTo } = {} } = formLineEntity

		return valueFrom >= 0 || valueTo >= 0
	}

	const defaultContent = () => {
		const shouldRender =
			isOpen || isRangeFilter(formLineEntity) || isSpecialSeoMultiOptionFilter(formLineEntity)

		return (
			shouldRender && (
				<div
					className={classnames({
						[`${CLASSNAME}__content`]: true,
						[`${CLASSNAME}__hidden`]: !isOpen
					})}
				>
					{children(isOpen)}
				</div>
			)
		)
	}

	return (
		<div className={CLASSNAME} data-e2e={dataE2E}>
			<button
				className={`${CLASSNAME}__opener`}
				type='button'
				onClick={() => setIsOpen(!isOpen)}
				data-dot='chevron-filter'
				data-dot-data={`{
                    "value": "${text}",
                    "isOpen": "${isOpen}"
                }`}
			>
				<Surface
					tagName='span'
					surface={2}
					className={classnames({
						[`${CLASSNAME}__opener-content`]: true,
						[`${CLASSNAME}__opener-content--open`]: isOpen
					})}
				>
					<Icon symbol={iconSymbol} className={`${CLASSNAME}__icon`} />
					<span className={`${CLASSNAME}__text`}>{text}</span>
					{counter > 0 && !isOpen && <div className={`${CLASSNAME}__counter`}>{counter}</div>}
				</Surface>
			</button>
			{defaultContent()}
		</div>
	)
}

FilterExtendedInfo.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	text: PropTypes.string,
	isFilterOpen: PropTypes.bool,
	counter: PropTypes.number,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	dataE2E: PropTypes.string
}

FilterExtendedInfo.defaultProps = {
	text: '',
	isFilterOpen: false
}

export default React.memo(FilterExtendedInfo)
