import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Surface } from '@sznds/react'
import { useLocalize } from 'app/base/componentHelpers'

import './SendSMS.less'
import './SendSMSCS.json'

const CLASSNAME = 'c-send-sms'

const SendSMS = (props) => {
	const localize = useLocalize()

	const { icon, dict, sendMessage, loading } = props

	const { boldText, buttonText } = dict

	return (
		<Surface surface={5} className={`${CLASSNAME}`}>
			<Icon symbol={icon} className={`${CLASSNAME}__icon`} />
			<div className={`${CLASSNAME}__text`}>
				<div className={`${CLASSNAME}__sentence`}>
					<p className={`${CLASSNAME}__sentence--bold`}>{boldText}</p>
					<p>{localize('SendSMS.info')}</p>
				</div>
				<div className={`${CLASSNAME}__btn`}>
					<Button
						loading={loading}
						onClick={sendMessage}
						className={`${CLASSNAME}__btn-get-pin`}
						text={buttonText}
					/>
				</div>
			</div>
		</Surface>
	)
}

SendSMS.propTypes = {
	icon: PropTypes.string.isRequired,
	dict: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	sendMessage: PropTypes.func.isRequired
}

SendSMS.defaultProps = {
	loading: false
}

export default React.memo(SendSMS)
