const VIN_API_PARAM = 'vin'

export default class VINVehicleDataService {
	/**
	 * @constructor
	 * @param {VINVehicleDataResource} VINVehicleDataResource
	 * @param {VINVehicleDataFactory}  VINVehicleDataFactory
	 */
	constructor(VINVehicleDataResource, VINVehicleDataFactory) {
		this._VINVehicleDataResource = VINVehicleDataResource
		this._VINVehicleDataFactory = VINVehicleDataFactory

		this._cachedVINData = {}
		this._cachedVINEquipmentData = {}
	}

	/**
	 * Na zaklade zadaneho VINu vraci dostupne informace o vozidle
	 * (napr. znacku, model, rok vyroby atd.)
	 *
	 * @public
	 * @method getVehicleData
	 */
	async getVehicleData(vin, options) {
		const cachedData = this.getVehicleDataCached(vin)

		if (cachedData) {
			return Promise.resolve(cachedData)
		} else {
			const response = await this._VINVehicleDataResource.getVehicleData(
				{
					[VIN_API_PARAM]: vin
				},
				options
			)

			const apiData = response.body.result
			const appData = this._VINVehicleDataFactory.transformData(apiData)

			this._cachedVINData = this._VINVehicleDataFactory.createEntity(appData)

			return this._cachedVINData
		}
	}

	/**
	 * Na zaklade zadaneho VINu vraci dostupne informace o vozidle
	 * (napr. znacku, model, rok vyroby atd.). Nedotazuje se na API,
	 * ale vraci jen nacacheovana data.
	 *
	 * @public
	 * @method getVehicleDataCached
	 */
	getVehicleDataCached(vin) {
		if (Object.keys(this._cachedVINData).length && this._cachedVINData.vin === vin) {
			return this._cachedVINData
		} else {
			return undefined
		}
	}

	/**
	 * Na zaklade zadaneho VINu vraci dostupne informace o vybave vozidle
	 * (napr. zda ma vozidlo ABS, klimatizaci atd.)
	 *
	 * @public
	 * @method getVehicleEquipmentData
	 */
	async getVehicleEquipmentData(vin, options) {
		const cachedEquipmentData = this.getVehicleEquipmentDataCached(vin)

		if (cachedEquipmentData) {
			return Promise.resolve(cachedEquipmentData)
		} else {
			const response = await this._VINVehicleDataResource.getVehicleEquipmentData(
				{
					[VIN_API_PARAM]: vin
				},
				options
			)

			const { equipment = [] } = response.body.result

			this._cachedVINEquipmentData[vin] = equipment

			return equipment
		}
	}

	/**
	 * Na zaklade zadaneho VINu vraci dostupne informace o vybave vozidle
	 * (napr. zda ma vozidlo ABS, klimatizaci atd.). Nedotazuje se na API,
	 * ale vraci jen nacacheovana data.
	 *
	 * @public
	 * @method getVehicleEquipmentDataCached
	 */
	getVehicleEquipmentDataCached(vin) {
		if (this._cachedVINEquipmentData && vin) {
			return this._cachedVINEquipmentData[vin]
		} else {
			return undefined
		}
	}
}
