const restoreIds = {}

export default class ScrollRestore {
	static getId(routeName) {
		return restoreIds[routeName]
	}

	static setId(id, routeName) {
		restoreIds[routeName] = id
	}
}
