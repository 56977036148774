import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import { createEntityList } from '@inzeraty/form-lines'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { StatusMessage, PageLoader } from '@inzeraty/components'
import RegistrationIncreaseStatus from 'app/model/registration/RegistrationIncreaseStatus'

const DATE_FROM_FORM_LINE_ENTITY_ID = 'date_from_egreater'
const DATE_TO_FORM_LINE_ENTITY_ID = 'date_to_eless'

export default class IARegistrationIncrasesListController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, entitiesFilterExtension, registrationService) {
		super(dependenciesHelper)

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)
		this._entitiesFilterExtension = entitiesFilterExtension
		this._registrationService = registrationService
	}

	static get REGISTRATION_INCREASE_STATUS() {
		return Object.freeze({
			ORDER: {
				id: RegistrationIncreaseStatus.ORDER,
				name: 'Objednávka'
			},
			RUNNING: {
				id: RegistrationIncreaseStatus.RUNNING,
				name: 'Běží'
			},
			PAUSED: {
				id: RegistrationIncreaseStatus.PAUSED,
				name: 'Pozastaveno'
			},
			FINISHED: {
				id: RegistrationIncreaseStatus.FINISHED,
				name: 'Úspěšně ukončeno'
			},
			CANCELED: {
				id: RegistrationIncreaseStatus.CANCELED,
				name: 'Stornováno'
			}
		})
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		})
	}

	update() {
		return {
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		}
	}

	getOptions(source = {}) {
		return Object.values(source).map(({ id, name }) => ({
			value: id,
			name
		}))
	}

	_getApiParams() {
		const routeParams = this.getRouteParams()
		const params = Object.assign(
			{},
			InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)
		)

		if (!params.sort) {
			params.sort = '-id'
		}

		return params
	}

	async getEntitiesAndPagination() {
		const fetchRegistrationIncreases = async (params) => {
			try {
				const {
					registrationEntities,
					paginationEntity
				} = await this._registrationService.getIncrases(params)

				return {
					entities: registrationEntities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const params = this._getApiParams()

		this._pageLoaderExtension.show()

		const entitiesAndPagination = await fetchRegistrationIncreases(params)

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	onExportIncreases() {
		PageLoader.start()

		const params = Object.assign({}, this._getApiParams(), {
			offset: 0,
			limit: 100000
		})

		this._registrationService
			.downloadIncreasesCSV(
				params,
				IARegistrationIncrasesListController.REGISTRATION_INCREASE_STATUS
			)
			.catch(() => {
				this._showStatusMessageToast({
					type: StatusMessage.TYPE.ERROR,
					title: 'Nepodařilo se provést export navýšení.'
				})
			})
			.finally(() => {
				PageLoader.end()
			})
	}

	async _filterInitiezerClb() {
		const routeParams = this.getRouteParams()

		return createEntityList([
			...this._entitiesFilterExtension.getFilterByAndValue([
				{
					value: 'id',
					name: 'ID navýšení registrace'
				},
				{
					value: 'registration_id',
					name: 'ID registrace'
				},
				{
					value: 'premise_external_id',
					name: 'Sauto ID'
				},
				{
					value: 'premise_id',
					name: 'ASK ID'
				},
				{
					value: 'premise_name',
					name: 'Název firmy'
				}
			]),
			this._entitiesFilterExtension.getSelect(
				'status',
				'Stav',
				'Vyberte stav',
				this.getOptions(IARegistrationIncrasesListController.REGISTRATION_INCREASE_STATUS)
			),
			{
				widget: IDS.DATE,
				id: DATE_FROM_FORM_LINE_ENTITY_ID,
				label: 'Datum od',
				value: routeParams[DATE_FROM_FORM_LINE_ENTITY_ID] || ''
			},
			{
				widget: IDS.DATE,
				id: DATE_TO_FORM_LINE_ENTITY_ID,
				label: 'Datum do',
				value: routeParams[DATE_TO_FORM_LINE_ENTITY_ID] || ''
			}
		])
	}
}
