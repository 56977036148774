import React from 'react'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import LayoutAdmin from 'app/component/layout/admin/LayoutAdmin'
import AdminMenu from 'app/component/adminMenu/AdminMenu'
import * as FormLines from '@inzeraty/form-lines'
import ClientBaseController from 'app/base/ClientBaseController'
import SourceWidget, { SOURCE_WIDGET_ID } from './widgets/source/SourceWidget'
import TextWidget, { TEXT_WIDGET_ID } from './widgets/text/TextWidget.jsx'
import TextareaWidget, { TEXTAREA_WIDGET_ID } from './widgets/textarea/TextareaWidget'
import SimpleDataWidget, { SIMPLE_DATA_WIDGET_ID } from './widgets/simpleData/SimpleDataWidget'
import OpeningHoursWidget, {
	OPENING_HOURS_WIDGET_ID
} from './widgets/openingHours/OpeningHoursWidget'
import ContactsWidget, { CONTACTS_WIDGET_ID } from './widgets/contacts/ContactsWidget.jsx'
import LocalityWidget, { LOCALITY_WIDGET_ID } from './widgets/locality/LocalityWidget'
import SelectWidget, { SELECT_WIDGET_ID } from './widgets/select/SelectWidget'
import SoftwareKeyWidget, {
	SOFTWARE_KEY_WIDGET_ID
} from 'app/page/userweb/clientProfile/widgets/softwareKey/SoftwareKeyWidget'
import LogoWidget, { LOGO_WIDGET_ID } from './widgets/logo/LogoWidget'
import STATE_KEYS from './ClientProfileStateKeys'
import { PROFILE_SECTIONS } from './ClientProfileSections'
import { getFlatEntities } from './ClientProfileFormLines'
import { Button } from '@sznds/react'
import PropTypes from 'prop-types'
import WalletWidget, { WALLET_WIDGET_ID } from './widgets/wallet/WalletWidget'

import './ClientProfileView.less'
import './ClientProfileCS.json'

const { SZNDS_WIDGET_BY_ID, Form } = FormLines
const CLASSNAME = 'p-uw-client-profile'
export const CLIENT_PROFILE_FORM_CLASSNAME = `${CLASSNAME}__form`

export const WIDGETS_BY_ID = Object.freeze(
	Object.assign(
		{
			[TEXT_WIDGET_ID]: TextWidget,
			[TEXTAREA_WIDGET_ID]: TextareaWidget,
			[SIMPLE_DATA_WIDGET_ID]: SimpleDataWidget,
			[SOURCE_WIDGET_ID]: SourceWidget,
			[OPENING_HOURS_WIDGET_ID]: OpeningHoursWidget,
			[CONTACTS_WIDGET_ID]: ContactsWidget,
			[LOCALITY_WIDGET_ID]: LocalityWidget,
			[SELECT_WIDGET_ID]: SelectWidget,
			[LOGO_WIDGET_ID]: LogoWidget,
			[WALLET_WIDGET_ID]: WalletWidget,

			[SOFTWARE_KEY_WIDGET_ID]: SoftwareKeyWidget
		},
		SZNDS_WIDGET_BY_ID
	)
)

export const ClientProfileForm = ({
	widgetsById = WIDGETS_BY_ID,
	formData,
	isSending,
	sections
}) => {
	const localize = useLocalize()
	const fire = useFire()

	return (
		<Form
			className={CLIENT_PROFILE_FORM_CLASSNAME}
			formLineEntities={getFlatEntities(formData)}
			isSending={isSending}
			renderWidgets={(sectionId) => {
				return FormLines.renderWidgetsByIds(
					formData[sectionId],
					{
						onChange: (change) => fire('change', change),
						section: sectionId
					},
					widgetsById
				)
			}}
			renderFormContent={(renderWidgets, renderSubmitButton) => {
				return (
					<>
						<div className={`${CLASSNAME}__form-content`}>
							{sections.map(({ sectionId, label }) => (
								<div key={sectionId} className={`${CLASSNAME}__section`}>
									<div className={`${CLASSNAME}__section-heading`}>{label}</div>
									{renderWidgets(sectionId)}
								</div>
							))}
						</div>

						{renderSubmitButton()}
					</>
				)
			}}
			onSubmit={() => fire('submit', getFlatEntities(formData))}
			renderSubmitButton={(props) => {
				return (
					<Button
						{...props}
						loading={isSending}
						primary={true}
						text={localize('ClientProfile.save')}
						className={`${CLASSNAME}__submit`}
					/>
				)
			}}
		/>
	)
}

const ClientProfileView = ({
	[ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise,
	[STATE_KEYS.FORM_DATA]: formData,
	[STATE_KEYS.IS_SENDING]: isSending
}) => {
	const localize = useLocalize()

	const sections = [
		{
			sectionId: PROFILE_SECTIONS.BASIC_INFORMATION,
			label: localize('ClientProfile.basicInformation')
		},
		{
			sectionId: PROFILE_SECTIONS.OPENING_HOURS,
			label: localize('ClientProfile.openingHours')
		},
		{
			sectionId: PROFILE_SECTIONS.CONTACT_INFORMATION,
			label: localize('ClientProfile.contact')
		},
		{
			sectionId: PROFILE_SECTIONS.ADDRESS,
			label: localize('ClientProfile.address')
		}
	]

	return (
		<LayoutAdmin
			isPremiseAdmin={true}
			headerContent={isPremise ? <AdminMenu /> : null}
			className={CLASSNAME}
		>
			<div className={`${CLASSNAME}__content`}>
				{formData && (
					<ClientProfileForm formData={formData} isSending={isSending} sections={sections} />
				)}
			</div>
		</LayoutAdmin>
	)
}

ClientProfileForm.propTypes = {
	sections: PropTypes.array,
	widgetsById: PropTypes.object,
	formData: PropTypes.object,
	isSending: PropTypes.bool
}

export default ClientProfileView
