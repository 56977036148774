import React from 'react'
import PropTypes from 'prop-types'
import RouteNames from 'app/base/RouteNames'
import AbstractComponent from 'app/base/AbstractComponent'
import Layout from 'app/component/layout/LayoutView'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import AdvertList from 'app/component/advertList/AdvertListView'
import SidePanel from '../../../component/filters/forms/sidePanel/SidePanel'
import { DATA_SEOT } from 'app/base/SeoTesting'
import FilterConstants from 'app/model/filter/FilterConstants'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import AdvertListFilterItems from 'app/page/userweb/advertList/advertListFilterItems/AdvertListFilterItems'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'
import { CategoryEntity, UserService } from '@inzeraty/models'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import MySavedSearchAddBanner from 'app/component/mySavedSearch/addBanner/MySavedSearchAddBanner'
import MySavedSearchAlreadySavedBanner from 'app/component/mySavedSearch/alreadySavedBanner/MySavedSearchAlreadySavedBanner'
import MySavedSearchAddButton from 'app/component/mySavedSearch/addButton/MySavedSearchAddButton'
import AddMySavedSearch from 'app/component/mySavedSearch/addMySavedSearch/AddMySavedSearch'
import EmptyList from 'app/component/emptyList/EmptyList'
import UserwebBaseController from 'app/base/UserwebBaseController'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import SkyscraperList from 'app/component/sspAdverts/skyscraperList/SkyscraperList'
import LoginHelper from 'app/helpers/loginHelper/LoginHelper'

import './AdvertListView.less'

const CLASSNAME = 'p-uw-item-list'

export const CONTAINER_CLASSNAME = `${CLASSNAME}__main-container`

const DEFAULT_CATEGORY_PROP = new CategoryEntity(DEFAULT_PROPS.OBJECT)

/**
 * @class AdvertListView
 * @namespace app.page.userweb.advertList
 * @extends ima.page.AbstractComponent
 * @module app
 * @submodule app.page
 */
export default class AdvertListView extends AbstractComponent {
	static get propTypes() {
		return {
			header: PropTypes.string,
			category: PropTypes.instanceOf(CategoryEntity)
		}
	}

	static get defaultProps() {
		return {
			category: DEFAULT_CATEGORY_PROP
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			isOpenAddMySavedSearchPopup: false
		}

		this._listRef = React.createRef()

		this._urlPageCreator = this._urlPageCreator.bind(this)
		this._renderAdvertListHeader = this._renderAdvertListHeader.bind(this)
		this._renderMySavedSearchAddBanner = this._renderMySavedSearchAddBanner.bind(this)
		this._renderMySavedSearchAlreadySaved = this._renderMySavedSearchAlreadySaved.bind(this)
		this._renderNoResultsForMySavedSearch = this._renderNoResultsForMySavedSearch.bind(this)
	}

	componentDidMount() {
		document.body.setAttribute('data-dot', 'advert-list')
	}

	componentWillUnmount() {
		document.body.removeAttribute('data-dot')
	}

	render() {
		return <Layout className={CLASSNAME}>{this._renderContent()}</Layout>
	}

	_renderContent() {
		const {
			isMySavedSearchAlreadySaved,
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease,
			[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelf
		} = this.props

		const advertListProps = Object.assign(
			{
				isOperatingLease,
				listRef: this._listRef,
				renderHeader: this._renderAdvertListHeader,
				renderSubHeader: this._renderMySavedSearchAlreadySaved,
				renderSubSubHeader: this._renderMySavedSearchAddBanner,
				renderNoResults: this._renderNoResultsForMySavedSearch,
				// komponenta AdvertList sice nebere isMySavedSearchAlreadySaved a userSelf jako props.
				// Chceme ale, aby se pri jeji zmene vypis znovu prekreslil a zmenou
				// libovolne props (byt neplatne) toho docilime.
				isMySavedSearchAlreadySaved,
				userSelf
			},
			this._getPagingProps()
		)

		return (
			<div className={CONTAINER_CLASSNAME}>
				{this._renderModelsSeoLinks()}
				<SidePanel className={`${CLASSNAME}__side-panel`} />
				<div className={`${CLASSNAME}__list-wrapper`}>
					{this._renderHeader()}
					<AdvertListFilterItems className={`${CLASSNAME}__tags`} />
					{this._renderAddMySavedSearchPopup()}
					<AdvertList {...advertListProps} isList={true} showSortTooltip={true} />
				</div>
				<div className={`${CLASSNAME}__skyscraper`}>
					<SkyscraperList />
				</div>
			</div>
		)
	}

	_renderModelsSeoLinks() {
		const {
			seoModelsWithAdvertsValues = [],
			category,
			filterFormLineEntities = [],
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease
		} = this.props

		if (seoModelsWithAdvertsValues.length) {
			const { value = new Map(), options = [] } =
				filterFormLineEntities.find(
					(widget) => widget.id === FilterConstants.formLineIds.BRAND_MODEL
				) || {}
			const brandIterator = value[Symbol.iterator]()
			const [brandValue] = brandIterator.next().value || []
			const { children: models = [], seoName: brandSeoName } =
				options.find((brand) => brand.value === brandValue) || {}

			return (
				<div data-seoth={DATA_SEOT.MODELS_LINKS}>
					{models
						.filter(({ value }) => seoModelsWithAdvertsValues.includes(value))
						.map(({ name: modelName, seoName: modelSeoName }) => {
							const url = this.link(
								isOperatingLease
									? RouteNames.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
									: RouteNames.USERWEB.ADVERT_LIST,
								{
									[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: category.seoName,
									[AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: brandSeoName,
									[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]: modelSeoName
								}
							)

							return (
								<a key={url} href={url}>
									{modelName}
								</a>
							)
						})}
				</div>
			)
		}
	}

	_renderHeader() {
		const {
			header,
			category: { seoName = '' },
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease
		} = this.props
		const { URL_APP_PARAMS } = AdvertListUrlConvertor.constants
		const {
			[URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName,
			[URL_APP_PARAMS.BRAND_NAME]: brandSeoName,
			[URL_APP_PARAMS.MODEL_NAME]: modelSeoName
		} = this.utils.$Router.getCurrentRouteInfo().params

		const modelLink =
			categorySeoName && brandSeoName && modelSeoName
				? this.link(
						isOperatingLease
							? RouteNames.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
							: RouteNames.USERWEB.ADVERT_LIST,
						{
							[URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName,
							[URL_APP_PARAMS.BRAND_NAME]: brandSeoName,
							[URL_APP_PARAMS.MODEL_NAME]: modelSeoName
						}
				  )
				: null
		const modelLinkIsSameAsCurrentUrl = this.utils.$Router.getUrl() === modelLink

		return (
			<div className={`${CLASSNAME}__header`}>
				<BackToHpButton categorySeoName={seoName} className={`${CLASSNAME}__link`} />
				<h1
					className={this.cssClasses({
						[`${CLASSNAME}__header-text`]: true,
						[`${CLASSNAME}__header-text--plh`]: !header
					})}
					data-seot={DATA_SEOT.ADVERT_LIST_HEADER}
				>
					{modelLink && !modelLinkIsSameAsCurrentUrl ? (
						<a
							className={`${CLASSNAME}__header-link`}
							href={modelLink}
							data-seot={DATA_SEOT.ADVERT_LIST_HEADER_LINK}
						>
							{header}
						</a>
					) : (
						header
					)}
				</h1>
			</div>
		)
	}

	_renderAdvertListHeader({ renderAdvertsCount, renderSortButton } = {}) {
		const { isMySavedSearchAlreadySaved } = this.props

		return (
			<>
				{renderAdvertsCount && renderAdvertsCount()}
				{/* kvuli "podminenemu" renderovani (zpusobenem cache pro ulozena hledani) je potreba zabalit do divu s key */}
				<div
					key='already-saved-banner'
					className={`${CLASSNAME}__search-already-saved-banner-desktop`}
				>
					{isMySavedSearchAlreadySaved && <MySavedSearchAlreadySavedBanner />}
				</div>
				{renderSortButton && renderSortButton()}
			</>
		)
	}

	_renderAddMySavedSearchPopup() {
		const { isOpenAddMySavedSearchPopup } = this.state
		const {
			category,
			filterFormLineEntities = [],
			addMySavedSearch,
			evalIfMySavedSearchIsAlreadySaved
		} = this.props

		return (
			<AddMySavedSearch
				category={category}
				filterFormLineEntities={filterFormLineEntities}
				isPopupOpened={isOpenAddMySavedSearchPopup}
				onClose={() => {
					evalIfMySavedSearchIsAlreadySaved()
					this._setOpenAddMySavedSearchPopup(false)
				}}
				showToastMessage={(toastConfig) =>
					this.utils.$Dispatcher.fire(
						SHOW_NEW_TOAST_MESSAGE_EVENT,
						Object.assign(
							{
								className: `${CLASSNAME}__toast`
							},
							toastConfig
						)
					)
				}
				addMySavedSearch={addMySavedSearch}
			/>
		)
	}

	_renderMySavedSearchAddBanner() {
		const {
			[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelf,
			isMySavedSearchAlreadySaved,
			evalIfMySavedSearchIsAlreadySaved
		} = this.props

		return (
			// obcas se tato komponenta zvlastne vyrenderovala kvuli "podminenemu" renderovani,
			// proto je zabalena do divu, ktery je v DOMu vzdy.
			<div key='add-banner-wrapper'>
				{/* kvuli "podminenemu" renderovani (zpusobenem cache pro ulozena hledani) je potreba zabalit do divu s key */}
				<div key='not-saved-yet'>
					{!isMySavedSearchAlreadySaved && (
						<MySavedSearchAddBanner
							className={`${CLASSNAME}__add-my-saved-search-banner`}
							isPlaceholderShown={
								isNullOrUndefined(isMySavedSearchAlreadySaved) || isNullOrUndefined(userSelf)
							}
							userSelf={userSelf}
							onMySavedSearchAddClick={async () => {
								const isAlreadySaved = await evalIfMySavedSearchIsAlreadySaved()

								if (!isAlreadySaved) {
									this._setOpenAddMySavedSearchPopup(true)
								}
							}}
							onLoginClick={saveSearchOpenLogin}
						/>
					)}
				</div>
			</div>
		)
	}

	_renderMySavedSearchAlreadySaved() {
		const { isMySavedSearchAlreadySaved } = this.props

		return (
			// obcas se tato komponenta zvlastne vyrenderovala kvuli "podminenemu" renderovani,
			// proto je zabalena do divu, ktery je v DOMu vzdy.
			<div key='already-saved-wrapper'>
				{/* kvuli "podminenemu" renderovani (zpusobenem cache pro ulozena hledani) je potreba zabalit do divu s key */}
				<div key='already-saved'>
					{isMySavedSearchAlreadySaved && (
						<MySavedSearchAlreadySavedBanner
							className={`${CLASSNAME}__search-already-saved-banner-mobile`}
						/>
					)}
				</div>
			</div>
		)
	}

	_renderNoResultsForMySavedSearch({ renderDefaultNoResults } = {}) {
		const {
			[UserwebBaseController.STATE_KEYS.USER_SELF]: userSelf,
			isMySavedSearchAlreadySaved,
			evalIfMySavedSearchIsAlreadySaved
		} = this.props

		return (
			<div key='no-results' className={`${CLASSNAME}__no-results`}>
				{/* kvuli "podminenemu" renderovani (zpusobenem cache pro ulozena hledani) je potreba zabalit do divu s key */}
				<div key='no-results-default-text'>
					{isMySavedSearchAlreadySaved && (
						<>
							<MySavedSearchAlreadySavedBanner
								className={`${CLASSNAME}__search-already-saved-banner-mobile`}
							/>
							{renderDefaultNoResults ? renderDefaultNoResults() : null}
						</>
					)}
				</div>
				{/* kvuli "podminenemu" renderovani (zpusobenem cache pro ulozena hledani) je potreba zabalit do divu s key */}
				<div key='no-results-custom-text'>
					{!isMySavedSearchAlreadySaved && (
						<EmptyList
							text={this.localize('AdvertList.noResultsDescription')}
							renderLink={() => (
								<MySavedSearchAddButton
									className={`${CLASSNAME}__add-my-saved-search-no-results`}
									text={this.localize('AdvertList.noResultsAddButton')}
									onClick={
										UserService.isUserLogged(userSelf)
											? async () => {
													const isAlreadySaved = await evalIfMySavedSearchIsAlreadySaved()

													if (!isAlreadySaved) {
														this._setOpenAddMySavedSearchPopup(true)
													}
											  }
											: saveSearchOpenLogin
									}
								/>
							)}
						/>
					)}
				</div>
			</div>
		)
	}

	_setOpenAddMySavedSearchPopup(isOpen) {
		this.setState({
			isOpenAddMySavedSearchPopup: isOpen
		})
	}

	_getPagingProps() {
		const pagingProps = {
			urlPageCreator: this._urlPageCreator
		}

		if (this.props.advertListAndPagination) {
			const { paginationEntity = {} } = this.props.advertListAndPagination
			const { total, limit, offset } = paginationEntity

			const isLastPage = total - limit <= offset

			if (isLastPage && this._getIsMySavedSearch()) {
				Object.assign(pagingProps, {
					showNextOnLastPage: true,
					prevText: '',
					nextText: this.localize('AdvertList.showOlderAdverts')
				})
			}
		}

		return pagingProps
	}

	_getIsMySavedSearch() {
		return this.utils.$Router
			.getCurrentRouteInfo()
			.params.hasOwnProperty(AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_ID)
	}

	/**
	 * Sestaví URL adresu pro stránkování.
	 *
	 * @method _urlPageCreator
	 * @param {number} page - číslo stránky pro kterou potřebuji sestavit URL
	 * @param {boolean} removeMySavedSearchParams - odstranit z URL parametry pro ulozene hledani
	 * @return {string}
	 */
	_urlPageCreator(page) {
		const { URL_APP_PARAMS } = AdvertListUrlConvertor.constants
		const { paginationEntity = {} } = this.props.advertListAndPagination || {}
		const { total, limit, offset } = paginationEntity

		const { route, params } = this.utils.$Router.getCurrentRouteInfo()

		const actualPage = offset / limit + 1
		const isLastPage = total - limit <= offset
		const isNextPage = actualPage < page
		const isLastPageFull = total === offset + limit

		let finalPage = page

		// pokud jde o posledni stranku ulozenych hledani tak pro nasledujici stranku odstranim z URL parametry pro ulozene hledani
		if (isNextPage && isLastPage && this._getIsMySavedSearch()) {
			if (params.hasOwnProperty(UrlConvertor.constants.URL_APP_PARAMS.SORT)) {
				// pokud je pouzito razeni pro ulozene hledani, tak pro zobrazeni starsich prejdu na prvni stranku
				finalPage = 1
			} else if (!isLastPageFull) {
				// pokud neni posledni stranka ulozeneho hledani zcela zaplnena, tak zustanu na stejne strance a donactu starsi inzeraty
				finalPage = actualPage
			}

			delete params[URL_APP_PARAMS.MY_SAVED_SEARCH_HASH]
			delete params[URL_APP_PARAMS.MY_SAVED_SEARCH_ID]
			delete params[URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP]
		}

		const newParams = AdvertListUrlConvertor.getPagingPageParams(finalPage, params)

		return this.link(route.getName(), newParams)
	}
}

function saveSearchOpenLogin() {
	LoginHelper.openLogin({
		sourceComponent: LoginHelper.SOURCE_COMPONENT.SAVED_SEARCH_ADD
	})
}
