
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SortButton'] = {
  close: function(d) { return "Zavřít"; },
  sort: function(d) { return "Seřadit dle "; },
  sortedBy: function(d) { return "Seřazeno dle "; },
  sortPrefix: function(d) { return "Seřadit:"; },
  mostRecent: function(d) { return "Datum vložení"; },
  clientAdminMostRecent: function(d) { return "Nejnovější inzeráty"; },
  clientAdminMostRecentAscending: function(d) { return "Nejstarší inzeráty"; },
  priceAscending: function(d) { return "Nejlevnější"; },
  priceDescending: function(d) { return "Nejdražší"; },
  tachometerAscending: function(d) { return "Stav tachometru"; },
  manufacturingDateDescending: function(d) { return "Rok výroby"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	