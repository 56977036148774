export default class RegistrationEntity {
	constructor(data) {
		this.id = data.id
		this.premiseId = data.premiseId
		this.premiseName = data.premiseName
		this.status = data.status
		this.dateFrom = data.dateFrom
		this.dateTo = data.dateTo
		this.registrationId = data.registrationId
		this.registrationModule = data.registrationModule
		this.premiseExternalId = data.premiseExternalId
	}
}
