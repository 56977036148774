import React from 'react'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import select from 'ima-plugin-select'
import BrandModelOpener from 'app/component/filters/forms/main/openers/brandModelOpener/BrandModelOpener'
import AdvertListBrandModelOpener from 'app/component/filters/forms/main/openers/brandModelOpener/AdvertListBrandModelOpener'
import BrandForm from 'app/component/filters/forms/brand/BrandForm'
import FiltersExtension, { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import FilterDropdownWithOpener from './shared/FilterDropdownWithOpener'
import DependentDropdownsOpenerLogic from 'app/component/filters/logic/openers/DependentDropdownsOpenerLogic'
import BrandModelFilterLogic from 'app/component/filters/logic/BrandModelFilterLogic'
import ScopedFormLineEntity from 'app/component/filters/logic/ScopedFormLineEntity'
import DependentDropdownsLogic from 'app/component/filters/logic/modals/DependentDropdownsLogic'
import FilterDropdownButton from './shared/FilterDropdownButton'
import BrandModelForm from 'app/component/filters/forms/brand/BrandModelForm'
import Back from 'app/component/filters/components/back/BackView'
import FilterConstants from 'app/model/filter/FilterConstants'
import FloatingDropdown from 'app/component/floatingDropdown/FloatingDropdown'

import './BrandModelFilterDropdownWithOpener.less'

const CLASSNAME = 'c-brand-model-dropdown-with-opener'

class BrandModelFilterDropdownWithOpener extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			changeFilter: PropTypes.func.isRequired,
			dropdownDefinition: PropTypes.object,
			context: PropTypes.string.isRequired,
			value: PropTypes.number, //TODO lepsi nazev? brandValue mozna
			filterIndex: PropTypes.number,
			renderOpener: PropTypes.func,
			isInHP: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			changeFilter: DEFAULT_PROPS.FUNCTION,
			isInHP: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderOpener = this._renderOpener.bind(this)
		this._renderDropdown = this._renderDropdown.bind(this)

		this._renderBrandForm = this._renderBrandForm.bind(this)
	}

	render() {
		const { dropdownDefinition, context, value: brandValue } = this.props

		return (
			<FilterDropdownWithOpener
				dropdownDefinition={dropdownDefinition}
				context={context}
				value={brandValue}
				renderOpener={this._renderOpener}
				renderDropdown={this._renderDropdown}
			/>
		)
	}

	_renderOpener() {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			categoryEntity,
			dropdownDefinition,
			context,
			filterIndex,
			renderOpener
		} = this.props

		return (
			<BrandModelFilterLogic
				formLineEntity={globalFormLineEntity}
				changeFilter={globalChangeFilter}
				context={context}
			>
				{(brandModelFilterLogic) => {
					const {
						form: { changeFilter },
						advertsCountForBrands,
						advertsCountForModels
					} = brandModelFilterLogic

					return (
						<DependentDropdownsOpenerLogic
							formLineEntity={globalFormLineEntity}
							changeFilter={changeFilter}
							context={context}
						>
							{(dropdownOpenerLogicData) => {
								const {
									openMainDropdown: openAllBrandsDropdown,
									openDependentDropdown: openBrandModelsDropdown,
									clearDependentFilter: clearBrandModelsFilter
								} = dropdownOpenerLogicData

								return renderOpener ? (
									renderOpener(dropdownOpenerLogicData)
								) : context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL ||
								  context === FILTER_CONTEXT.EXTENDED_FILTER ? (
									<AdvertListBrandModelOpener
										formLineEntity={globalFormLineEntity}
										categoryEntity={categoryEntity}
										changeFilter={changeFilter}
										advertsCountForBrands={advertsCountForBrands}
										advertsCountForModels={advertsCountForModels}
										getSortedFavoriteBrands={this.utils.FilterHelper.getSortedFavoriteOptions}
										increaseFavoriteBrandHit={this.utils.FilterHelper.increaseFavoriteBrandClick}
									/>
								) : (
									<BrandModelOpener
										formLineEntity={globalFormLineEntity}
										context={context}
										filterIndex={filterIndex}
										isOpened={!!dropdownDefinition}
										categoryEntity={categoryEntity}
										onOpen={openAllBrandsDropdown}
										onOpenBrandModels={openBrandModelsDropdown}
										clearBrandModelsFilter={clearBrandModelsFilter}
									/>
								)
							}}
						</DependentDropdownsOpenerLogic>
					)
				}}
			</BrandModelFilterLogic>
		)
	}

	_renderDropdown() {
		const { formLineEntity: globalFormLineEntity, dropdownDefinition = {}, context } = this.props

		return (
			<ScopedFormLineEntity formLineEntity={globalFormLineEntity}>
				{(scopedFormLineEntityChildrenProps) => {
					const { localFormLineEntity, localChangeFilter } = scopedFormLineEntityChildrenProps

					return (
						<BrandModelFilterLogic
							formLineEntity={localFormLineEntity}
							changeFilter={localChangeFilter}
							context={context}
							scopedContext={true}
							brandValue={dropdownDefinition.openedByValue}
						>
							{(filterLogicChildrenProps) => {
								return dropdownDefinition.openedByValue
									? this._renderBrandModelDropdown(filterLogicChildrenProps)
									: this._renderBrandDropdown(filterLogicChildrenProps)
							}}
						</BrandModelFilterLogic>
					)
				}}
			</ScopedFormLineEntity>
		)
	}

	_renderBrandDropdown(filterLogicChildrenProps) {
		const { changeFilter: globalChangeFilter, dropdownDefinition } = this.props
		const {
			other: { formLineEntity: localFormLineEntity }
		} = filterLogicChildrenProps

		return (
			<DependentDropdownsLogic
				formLineEntity={localFormLineEntity}
				changeFilter={globalChangeFilter}
				dropdownDefinition={dropdownDefinition}
				filterLogicData={Object.assign({}, filterLogicChildrenProps, {
					advertsCountForMainDropdown: filterLogicChildrenProps.advertsCountForBrands,
					advertsCountForDependentDropdown: filterLogicChildrenProps.advertsCountForModels
				})}
			>
				{(filterDropdownLogicChildrenProps) => {
					const {
						mainDropdown: { closeDropdownWithoutSavingChanges }
					} = filterDropdownLogicChildrenProps

					return (
						<FloatingDropdown
							className={`${CLASSNAME}__dropdown-brands`}
							onClose={closeDropdownWithoutSavingChanges}
						>
							{this._renderBrandForm(filterDropdownLogicChildrenProps, filterLogicChildrenProps)}
						</FloatingDropdown>
					)
				}}
			</DependentDropdownsLogic>
		)
	}

	_renderBrandModelDropdown(filterLogicChildrenProps) {
		const {
			formLineEntity: globalFormLineEntity,
			changeFilter: globalChangeFilter,
			dropdownDefinition,
			context,
			isInHP
		} = this.props
		const {
			other: { formLineEntity: localFormLineEntity }
		} = filterLogicChildrenProps

		if (dropdownDefinition.openedByValue > 0 && dropdownDefinition.context === context) {
			const { openedByValue: brandValue, filterDropdownType } = dropdownDefinition

			const { options = [] } = localFormLineEntity
			const { name: brandName } = options.find((option) => option.value === brandValue) || {}

			return (
				<DependentDropdownsOpenerLogic
					formLineEntity={globalFormLineEntity}
					changeFilter={globalChangeFilter}
					context={context}
				>
					{(dropdownOpenerLogicData) => {
						const { openMainDropdown: openAllBrandsDropdown } = dropdownOpenerLogicData

						return (
							<DependentDropdownsLogic
								formLineEntity={localFormLineEntity}
								changeFilter={globalChangeFilter}
								dependentDropdownDefinition={dropdownDefinition}
								dependentValue={dropdownDefinition.openedByValue}
								filterLogicData={Object.assign({}, filterLogicChildrenProps, {
									advertsCountForMainDropdown: filterLogicChildrenProps.advertsCountForBrands,
									advertsCountForDependentDropdown: filterLogicChildrenProps.advertsCountForModels
								})}
							>
								{(filterDropdownLogicChildrenProps) => {
									const {
										dependentDropdown: { closeDropdown, closeDropdownWithoutSavingChanges }
									} = filterDropdownLogicChildrenProps

									return (
										<FloatingDropdown
											className={this.cssClasses({
												[`${CLASSNAME}__dropdown-brand-models`]: true,
												[`${CLASSNAME}__dropdown-brand-models--hp`]: isInHP
											})}
											onClose={closeDropdown}
										>
											{filterDropdownType === FilterConstants.filterDropdownType.BACK &&
												context !== FILTER_CONTEXT.MAIN_MENU && (
													<Back
														className={`${CLASSNAME}__back-button`}
														label={brandName}
														onClick={() => {
															closeDropdownWithoutSavingChanges()
															openAllBrandsDropdown()
														}}
													/>
												)}
											{this._renderBrandModelForm(
												filterDropdownLogicChildrenProps,
												filterLogicChildrenProps,
												brandValue
											)}
											{this._renderButton(
												filterDropdownLogicChildrenProps,
												filterLogicChildrenProps
											)}
										</FloatingDropdown>
									)
								}}
							</DependentDropdownsLogic>
						)
					}}
				</DependentDropdownsOpenerLogic>
			)
		} else {
			return null
		}
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderBrandForm(filterDropdownLogicChildrenProps, filterLogicChildrenProps, searchTerm) {
		const { context } = this.props
		const {
			advertsCountForBrands: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity, categoryEntity }
		} = filterLogicChildrenProps

		return (
			<BrandForm
				className={`${CLASSNAME}__brand-form`}
				formLineEntity={formLineEntity}
				context={context}
				categoryEntity={categoryEntity}
				filteredBrandName={searchTerm}
				filteredAdvertsCount={filteredAdvertsCount}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				onBrandClick={(brandValue) => {
					// zavreme dropdown se vsemi znackami
					this.fire('closeFilterDropdown', {
						filterId: formLineEntity.id
					})

					// otevreme dropdown s modely pro vybranou znacku
					this.fire('openFilterDropdown', {
						filterId: formLineEntity.id,
						openedByValue: brandValue,
						context,
						filterDropdownType: FilterConstants.filterDropdownType.BACK,
						updateUrlParams: false
					})
				}}
			/>
		)
	}

	_renderBrandModelForm(filterDropdownLogicChildrenProps, filterLogicChildrenProps, brandValue) {
		const {
			form: { changeFilter },
			advertsCountForModels: { filteredAdvertsCount, isLoadingFilteredAdvertsCount },
			other: { formLineEntity }
		} = filterLogicChildrenProps

		return (
			<BrandModelForm
				className={`${CLASSNAME}__brand-model-form`}
				formLineEntity={formLineEntity}
				onChange={changeFilter}
				brandValue={brandValue}
				isLoadingFilteredAdvertsCount={isLoadingFilteredAdvertsCount}
				filteredAdvertsCount={filteredAdvertsCount}
				// nechceme v bublinach s pocty inzeratu vypisovat
				// text 'xxx nabidek', ale pouze 'xxx'
				isCountShownWithAddText={false}
			/>
		)
	}

	// TODO kandidat na hozeni do spolecneho modulu i pro ostatni filtry
	_renderButton(filterDropdownLogicChildrenProps, filterLogicChildrenProps) {
		const { dependentDropdown } = filterDropdownLogicChildrenProps

		return (
			<FilterDropdownButton
				className={`${CLASSNAME}__confirm-button`}
				filterDropdownLogicData={dependentDropdown}
			/>
		)
	}
}

const selector = (state) => ({
	categoryEntity: state[FiltersExtension.stateIds.CATEGORY]
})

export default select(selector)(BrandModelFilterDropdownWithOpener)
