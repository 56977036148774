import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import MetricsDataHelper from '../MetricsDataHelper'
import KPIMetricTile from './KPIMetricTile'

import './KPIMetricsList.less'
import './KPIMetricsListCS.json'
import '../../ClientStatisticsCS.json'

const CLASSNAME = 'c-kpi-metrics-list'

const KPIMetricsList = (props) => {
	const {
		isComparisonMode = false,
		isOperatingLease = false,
		isMirroring = false,
		timeSpan1Data = DEFAULT_PROPS.OBJECT,
		timeSpan2Data = DEFAULT_PROPS.OBJECT
	} = props

	const localize = useLocalize()

	const extractAmount1 = (value) => value || 0
	const extractAmount2 = (value) => (isComparisonMode ? value || 0 : undefined)

	const isSales = !isOperatingLease && !isMirroring

	return (
		<div className={CLASSNAME}>
			<div className={`${CLASSNAME}__row`}>
				<KPIMetricTile
					className={`${CLASSNAME}__tile`}
					title={localize('ClientStatistics.listView')}
					unitLabel={localize('KPIMetricsList.view')}
					amount1={extractAmount1(
						MetricsDataHelper.getListViewMetric(isOperatingLease, isMirroring, timeSpan1Data)
					)}
					amount2={extractAmount2(
						MetricsDataHelper.getListViewMetric(isOperatingLease, isMirroring, timeSpan2Data)
					)}
				/>
				<KPIMetricTile
					className={`${CLASSNAME}__tile`}
					title={localize('ClientStatistics.replySent')}
					unitLabel={localize('KPIMetricsList.send')}
					amount1={extractAmount1(
						MetricsDataHelper.getReplySentMetric(isOperatingLease, isMirroring, timeSpan1Data)
					)}
					amount2={extractAmount2(
						MetricsDataHelper.getReplySentMetric(isOperatingLease, isMirroring, timeSpan2Data)
					)}
				/>
				<KPIMetricTile
					className={`${CLASSNAME}__tile`}
					title={localize('ClientStatistics.phoneView')}
					unitLabel={localize('KPIMetricsList.view')}
					amount1={extractAmount1(
						MetricsDataHelper.getPhoneViewMetric(isOperatingLease, isMirroring, timeSpan1Data)
					)}
					amount2={extractAmount2(
						MetricsDataHelper.getPhoneViewMetric(isOperatingLease, isMirroring, timeSpan2Data)
					)}
				/>
				<KPIMetricTile
					className={`${CLASSNAME}__tile`}
					title={localize('ClientStatistics.detailView')}
					unitLabel={localize('KPIMetricsList.view')}
					amount1={extractAmount1(
						MetricsDataHelper.getDetailViewMetric(isOperatingLease, isMirroring, timeSpan1Data)
					)}
					amount2={extractAmount2(
						MetricsDataHelper.getDetailViewMetric(isOperatingLease, isMirroring, timeSpan2Data)
					)}
				/>
			</div>

			<div className={`${CLASSNAME}__row`}>
				<KPIMetricTile
					className={`${CLASSNAME}__tile`}
					title={localize('ClientStatistics.detailViewFromList')}
					unitLabel={localize('KPIMetricsList.view')}
					amount1={extractAmount1(
						MetricsDataHelper.getDetailViewFromListMetric(
							isOperatingLease,
							isMirroring,
							timeSpan1Data
						)
					)}
					amount2={extractAmount2(
						MetricsDataHelper.getDetailViewFromListMetric(
							isOperatingLease,
							isMirroring,
							timeSpan2Data
						)
					)}
				/>
				<KPIMetricTile
					className={`${CLASSNAME}__tile`}
					title={localize('ClientStatistics.detailViewFromOther')}
					unitLabel={localize('KPIMetricsList.view')}
					amount1={extractAmount1(
						MetricsDataHelper.getDetailViewFromOtherMetric(
							isOperatingLease,
							isMirroring,
							timeSpan1Data
						)
					)}
					amount2={extractAmount2(
						MetricsDataHelper.getDetailViewFromOtherMetric(
							isOperatingLease,
							isMirroring,
							timeSpan2Data
						)
					)}
				/>
				<KPIMetricTile
					className={`${CLASSNAME}__tile`}
					title={localize('ClientStatistics.POView')}
					unitLabel={localize('KPIMetricsList.view')}
					amount1={extractAmount1(isSales ? timeSpan1Data.POView : undefined)}
					amount2={extractAmount2(isSales ? timeSpan2Data.POView : undefined)}
				/>
				<KPIMetricTile
					className={`${CLASSNAME}__tile`}
					title={localize('ClientStatistics.detailViewFromPO')}
					unitLabel={localize('KPIMetricsList.view')}
					amount1={extractAmount1(isSales ? timeSpan1Data.detailViewFromPO : undefined)}
					amount2={extractAmount2(isSales ? timeSpan2Data.detailViewFromPO : undefined)}
				/>
			</div>
			{/* 
				aby se nerozsypal layout pri responsivu, je potreba na kazdem radku
				zobrazovat 4 dlazdice, pripade podle potreby doplnit timto prazdnym 
				divem:

				<div className={`${CLASSNAME}__tile ${CLASSNAME}__tile--empty`} />
			*/}
		</div>
	)
}

KPIMetricsList.propTypes = {
	isComparisonMode: PropTypes.bool,
	isOperatingLease: PropTypes.bool,
	isMirroring: PropTypes.bool,
	timeSpan1Data: PropTypes.object,
	timeSpan2Data: PropTypes.object
}

export default React.memo(KPIMetricsList)
