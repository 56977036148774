export default class ModelLinesOverviewCatalogFactory {
	transformData(data) {
		return this._mapModelLines(data)
	}

	_mapModelLines(modelLines = []) {
		return modelLines.map((modelLine) => {
			const model = modelLine.model || {}

			return {
				id: modelLine.id,
				images: modelLine.images,
				vehicleBody: modelLine.vehicle_body,
				yearEnd: modelLine.year_end,
				yearStart: modelLine.year_start,
				name: modelLine.name,
				seoName: modelLine.seo_name,
				hasNoData: modelLine.no_data,
				model: {
					id: model.id,
					name: model.name,
					seoName: model.seo_name,
					manufacturer: model.manufacturer
						? {
								id: model.manufacturer.id,
								name: model.manufacturer.name,
								seoName: model.manufacturer.seo_name
						  }
						: {}
				}
			}
		})
	}
}
