import React, { useContext } from 'react'
import ImaContext from 'ima/page/context'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import Layout from 'app/component/layout/LayoutView'
import PathSeparator, { getHomePath } from '../components/pathSeparator/PathSeparator'
import ROUTE_NAMES from 'app/base/RouteNames'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import ModificationDetailController from './ModificationDetailController'
import { Button } from '@sznds/react'
import Gallery from 'app/component/gallery/GalleryView'
import CatalogImageResponsiveSettings from '../CatalogImageResponsiveSettings'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { Format } from '@inzeraty/helpers'
import { Loading } from '@inzeraty/components'
import DetailInfo from './components/detailInfo/DetailInfo'

import './ModificationDetailView.less'
import './ModificationDetailCS.json'
import '../CatalogCS.json'

const CLASSNAME = 'p-uw-catalog-modification-detail'

const ModificationDetailView = (props) => {
	const {
		[ModificationDetailController.STATE_KEYS.MODIFICATION_DETAIL]: detail,
		[ModificationDetailController.STATE_KEYS.ADVERTS_COUNT]: count,
		[ModificationDetailController.STATE_KEYS.LINK_URL]: linkUrl
	} = props

	const context = useContext(ImaContext)
	const router = context.$Router
	const localize = useLocalize()
	const fire = useFire()

	const dataLoaded = !!detail

	const getPaths = () => {
		if (dataLoaded) {
			const {
				brandName,
				modelName,
				generationName,
				brandSeoName,
				modelSeoName,
				generationSeoName,
				modificationName
			} = getModelName()

			return [
				getHomePath(localize, router),
				{
					name: brandName,
					url: router.link(ROUTE_NAMES.USERWEB.CATALOG_MODELS, {
						[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: brandSeoName
					})
				},
				{
					name: modelName,
					url: router.link(ROUTE_NAMES.USERWEB.CATALOG_MODEL_LINES, {
						[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: brandSeoName,
						[CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME]: modelSeoName
					})
				},
				{
					name: generationName,
					url: router.link(ROUTE_NAMES.USERWEB.CATALOG_MODIFICATIONS, {
						[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: brandSeoName,
						[CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME]: modelSeoName,
						[CatalogUrlConvertor.constants.URL_APP_PARAMS.GENERATION_SEO_NAME]: generationSeoName
					})
				},
				{
					name: modificationName
				}
			]
		} else {
			return []
		}
	}

	const getModelName = () => {
		const { generation = {}, name: modificationName } = detail || {}
		const { name: generationName, seoName: generationSeoName = '', model = {} } = generation
		const { name: modelName, manufacturer = {}, seoName: modelSeoName } = model
		const { name: brandName, seoName: brandSeoName } = manufacturer

		return {
			brandName,
			modelName,
			generationName,
			brandSeoName,
			modelSeoName,
			generationSeoName,
			modificationName
		}
	}

	const renderTitle = () => {
		if (dataLoaded) {
			const { brandName, modelName, modificationName } = getModelName()

			return (
				<h1 className={`${CLASSNAME}__header`}>
					{brandName} {modelName} {modificationName}
				</h1>
			)
		} else {
			return <div className={`${CLASSNAME}__header-placeholder`}></div>
		}
	}

	const renderSearchButton = () => {
		const disabled = isNullOrUndefined(count) || count === 0 || !linkUrl

		return (
			<Button
				primary={true}
				text={getButtonText()}
				className={`${CLASSNAME}__button`}
				href={linkUrl}
				disabled={disabled}
				onClick={() => fire('resetCache')}
				data-dot='go-to-adverts'
			/>
		)
	}

	const getButtonText = () => {
		const renderLoadingDots = () => (
			<Loading
				className={`${CLASSNAME}__loading-dots`}
				loadingType={Loading.loadingType.ONLY_DOT}
			/>
		)

		if (isNullOrUndefined(count)) {
			return (
				<React.Fragment>
					{localize('Catalog.ShowAdvertsPrefix')}
					{renderLoadingDots()}
					{localize('Catalog.ShowAdvertsSuffix')}
				</React.Fragment>
			)
		} else {
			if (!isNullOrUndefined(count) && count > 0) {
				return localize('Catalog.search', {
					ADVERT_COUNT: count,
					COUNT_NICE: Format.number(count)
				})
			} else {
				return localize('Catalog.noResults')
			}
		}
	}

	const renderImageGallery = () => {
		if (dataLoaded) {
			const { images = [] } = detail || {}

			if (images.length === 0) {
				return null
			} else {
				const { brandName, modelName, modificationName } = getModelName()

				const alt = `${brandName} ${modelName} ${modificationName}`
				const imagesWithAlt = images.map((image) => {
					return Object.assign({}, image, {
						alt
					})
				})

				return (
					<div className={`${CLASSNAME}__image`} data-dot='modification-detail'>
						<Gallery
							images={imagesWithAlt}
							previewImageResponsiveSettings={CatalogImageResponsiveSettings.previewSettings}
							normalImageResponsiveSettings={CatalogImageResponsiveSettings.normalSettings}
							fullscreenImageResponsiveSettings={CatalogImageResponsiveSettings.fullscreenSettings}
						/>
					</div>
				)
			}
		} else {
			return <div className={`${CLASSNAME}__image-placeholder`}></div>
		}
	}

	const paths = getPaths()

	return (
		<Layout className={CLASSNAME}>
			<div className={`${CLASSNAME}__content`} data-dot='catalog'>
				<div className={`${CLASSNAME}__header-content`}>
					<PathSeparator paths={paths} dataLoaded={dataLoaded} />
					<div className={`${CLASSNAME}__header-image`}>
						{renderImageGallery()}
						<div className={`${CLASSNAME}__title-button`} data-dot='modification-detail'>
							{renderTitle()}
							{renderSearchButton()}
						</div>
					</div>
				</div>
				<DetailInfo detail={detail} />
			</div>
		</Layout>
	)
}

export default ModificationDetailView
