import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import select from 'ima-plugin-select'
import NavButton, { BUTTON_TYPE } from 'app/component/navButton/NavButtonView'
import { Icon } from '@sznds/react'
import { CHECKER_NORMAL_OUTLINE_24 } from '@sznds/icons'
import STEPS_STATE_KEYS from './StepperStateKeys'
import ROUTE_NAMES from 'app/base/RouteNames'

import './StepperCS.json'
import './StepperView.less'

const CLASSNAME = 'c-stepper'

const StepperView = ({ steps = [], isEdit = false, isDraft = false }) => {
	const localize = useLocalize()

	const curentStepIndex = steps.findIndex((step) => {
		return step.actual
	})

	// - ziskani url pro tlacitko zpet
	const backUrl = curentStepIndex > 0 ? steps[curentStepIndex - 1].url : ''

	const backBtnProps = {
		className: `${CLASSNAME}__back`
	}

	const renderSteps = () => {
		return (
			<ul className={`${CLASSNAME}__steps`}>
				{steps.map((step, index) => {
					const isPaymentStep = step.routeName === ROUTE_NAMES.CLIENT_ADMIN.NEW_ADVERT_PAYMENT

					const clickable =
						!step.actual && step.url && (step.visited || isEdit) && !(isDraft && isPaymentStep)

					const elmProps = {
						className: classnames({
							[`${CLASSNAME}__link`]: true,
							[`${CLASSNAME}__link--active`]: step.actual,
							[`${CLASSNAME}__link--filled`]: step.filled
						})
					}

					if (!step.actual) {
						elmProps.href = step.url
					}

					return (
						<li
							className={classnames({
								[`${CLASSNAME}__item`]: true,
								[`${CLASSNAME}__item--active`]: step.actual,
								[`${CLASSNAME}__item--filled`]: step.filled,
								[`${CLASSNAME}__item--visited`]: step.visited
							})}
							key={`step-${index}`}
						>
							{React.createElement(
								!clickable ? 'span' : 'a',
								elmProps,
								<>
									<span className={`${CLASSNAME}__item-step`}>
										{step.filled && (
											<Icon symbol={CHECKER_NORMAL_OUTLINE_24} className={`${CLASSNAME}__icon`} />
										)}
										{localize('Stepper.step')}&nbsp;{index + 1}/{steps.length}:{' '}
									</span>
									<span className={`${CLASSNAME}__item-text`}>{step.text}</span>
								</>
							)}
						</li>
					)
				})}
			</ul>
		)
	}

	return (
		<div className={CLASSNAME}>
			{backUrl && (
				<NavButton
					text={localize('Stepper.back')}
					url={backUrl}
					type={BUTTON_TYPE.BACK}
					{...backBtnProps}
				/>
			)}
			{steps.length > 0 ? renderSteps() : <div className={`${CLASSNAME}__placeholder`}></div>}
		</div>
	)
}

StepperView.propTypes = {
	steps: PropTypes.array.isRequired,
	isEdit: PropTypes.bool,
	isDraft: PropTypes.bool
}

const selectors = (state) => ({
	steps: state[STEPS_STATE_KEYS.STEPS],
	isEdit: state[STEPS_STATE_KEYS.IS_EDIT]
})

export default React.memo(select(selectors)(StepperView))
