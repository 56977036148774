import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { createEntityList } from '@inzeraty/form-lines'
import { CATEGORIES } from 'app/base/Constants'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { Validation } from '@inzeraty/helpers'
import { nonValue, nonValueOption } from 'app/component/entitiesFilter/EntitiesFilter'
import { StatusMessage } from '@inzeraty/components'
import { HttpErrorHelper, HttpStatusCodes } from '@inzeraty/helpers'

import 'app/base/ActionMessageCS.json'
import 'app/page/userweb/newAdvert/equipment/components/widgets/equipment/EquipmentWidgetCS.json'

export const ACTIONS = {
	DELETE_VALUE: 'codebookValueDalete',
	OPEN_EDIT_POPUP: 'openEditPopup',
	CLOSE_EDIT_POPUP: 'closeEditPopup'
}

export const MANUFACTURER_CB = 'manufacturer_cb'

const booleanOptions = [
	{
		value: true,
		name: 'Ano'
	},
	{
		value: false,
		name: 'Ne'
	}
]

export default class IACodebookManufacturerListController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, entitiesFilterExtension, codebooksService, entityFormExtension) {
		super(dependenciesHelper)

		this._entitiesFilterExtension = entitiesFilterExtension
		this._codebooksService = codebooksService
		this._entityFormExtension = entityFormExtension

		this._filterInitiezerClb = this._filterInitiezerClb.bind(this)

		this._successMessage = ''
	}

	init() {
		super.init()

		this.addExtension(this._entitiesFilterExtension)

		this._entitiesFilterExtension.setFilterInitiezer(this._filterInitiezerClb)

		this.addExtension(this._entityFormExtension)

		this._entityFormExtension.initialize({
			getSubmitPromise: this._getEditFormSubmitPromise.bind(this),
			validations: this._editFormValidations.bind(this),
			handleSubmitSuccess: this._handleEditFormSubmitSuccess.bind(this),
			handleSubmitError: this._handleEditFormSubmitError.bind(this)
		})
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination(),
			isEditPopupOpened: false
		})
	}

	update() {
		return {
			isLoading: true,
			entitiesAndPagination: this.getEntitiesAndPagination()
		}
	}

	async getEntitiesAndPagination() {
		const fetchCodebooksList = async (params) => {
			try {
				const { entities, paginationEntity } = await this._codebooksService.getCodebooksValues(
					params
				)

				return {
					entities,
					paginationEntity
				}
			} catch (e) {
				return undefined
			}
		}

		const pageLoaderId = this._pageLoaderExtension.show()

		const routeParams = this.getRouteParams()
		const params = Object.assign(
			{
				[InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: MANUFACTURER_CB
			},
			InternalAdminUrlConvertor.processRouteParamsForEntityList(routeParams)
		)

		if (!params.sort) {
			params.sort = 'name'
		}

		const entitiesAndPagination = await fetchCodebooksList(params)

		this._pageLoaderExtension.hide(pageLoaderId)

		this.setState({
			isLoading: false
		})

		return entitiesAndPagination
	}

	async _handleAction({ action, entities = [] }) {
		const [entity = {}] = entities
		const { value, name, codebookCategoryId } = entity

		let promise
		let getErrorMessage = () => ''

		switch (action) {
			case ACTIONS.OPEN_EDIT_POPUP:
				this._entityFormExtension.setFormLineEntitiesData([])

				this.setState({
					isEditPopupOpened: true,
					entityFormError: null,
					isWaiting: true
				})

				this._entityFormExtension.setFormLineEntitiesData(
					await this._getFormLineEntitiesForEditForm(entity)
				)

				this.setState({
					isWaiting: false
				})
				return {}

			case ACTIONS.CLOSE_EDIT_POPUP:
				this.setState({
					isEditPopupOpened: false
				})
				this._entityFormExtension.setFormLineEntitiesData([])
				return {}

			case ACTIONS.DELETE_VALUE:
				if (window.confirm(`Opravdu si přejete smazat výrobce "${name}"`)) {
					const urlParams = {
						[InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: MANUFACTURER_CB,
						[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]: codebookCategoryId
					}
					promise = this._codebooksService.deleteCodebooksValues(value, urlParams)
					getErrorMessage = (error) => {
						if (this._isHasAdvertError(error)) {
							return `Nepodařilo se smazat výrobce "${name}", protože je použit u inzerátu`
						} else {
							return `Nepodařilo se smazat výrobce "${name}"`
						}
					}
				} else {
					return {}
				}
				break
		}

		return {
			promise,
			getErrorMessage
		}
	}

	_isHasAdvertError(error) {
		const httpStatus = HttpErrorHelper.getHttpStatus(error)
		const { body: { errors = [] } = {} } = HttpErrorHelper.getParams(error)
		const { error_code: errorCode } = errors[0] || {}

		return httpStatus === HttpStatusCodes.FORBIDDEN && errorCode === 'codebook_value_has_adverts'
	}

	async _filterInitiezerClb(routeParams) {
		return createEntityList([
			this._entitiesFilterExtension.getSelect(
				InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID,
				'Kategorie',
				'Vyberte kategorii',
				this._getCarCategories(),
				false
			),

			this._entitiesFilterExtension.getSearch('name', 'Výrobce')
		])
	}

	_getCarCategories() {
		return Object.values(CATEGORIES).map((category) => ({
			value: category.id,
			name: category.name
		}))
	}

	_changeForFilters(entity, newValue) {
		const { value, codebookCategoryId } = entity

		const params = {
			[InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: MANUFACTURER_CB,
			[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_IDS]: [codebookCategoryId],
			[InternalAdminUrlConvertor.constants.CODEBOOKS_FOR_FILTERS]: newValue
		}

		return this._codebooksService.patchCodebooksValues(value, params)
	}

	async _getFormLineEntitiesForEditForm(codebookValueEntity = {}) {
		const { id, value, name, codebookCategoryId, isFavorite = false } = codebookValueEntity
		const isNew = !id

		const routeParams = this.getRouteParams()

		const formLineEntities = []

		if (!isNew) {
			formLineEntities.push({
				id: 'value',
				value: value,
				widget: IDS.HIDDEN
			})
		}

		formLineEntities.push({
			id: InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID,
			label: 'Kategorie',
			value:
				codebookCategoryId ||
				routeParams[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID] ||
				nonValue,
			widget: IDS.SELECT,
			required: true,
			options: [nonValueOption, ...this._getCarCategories()],
			extra: {
				disabled: !isNew
			}
		})

		formLineEntities.push({
			id: 'name',
			label: 'Název',
			value: name,
			widget: IDS.TEXT,
			required: true
		})

		formLineEntities.push({
			id: 'is_favorite',
			label: 'Oblíbený výrobce',
			value: isFavorite,
			widget: IDS.SELECT,
			options: booleanOptions,
			required: true
		})

		return formLineEntities
	}

	_handleEditFormSubmitError(error) {
		this._pageLoaderExtension.hide()
		return {
			entityFormError: {
				title: 'Nepodařilo se odeslat formulář'
			}
		}
	}

	_handleEditFormSubmitSuccess() {
		this._pageLoaderExtension.hide()

		this._processSuccessAction()
		this.setState({
			isEditPopupOpened: false
		})
	}

	async _processSuccessAction() {
		this.setState({
			isLoading: true
		})

		const entitiesAndPagination = await this.getEntitiesAndPagination()

		if (this._successMessage) {
			this._showStatusMessageToast({
				type: StatusMessage.TYPE.SUCCESS,
				title: this._dictionary.get('ActionMessage.titleSuccess'),
				text: `${this._successMessage}`,
				autoDismissTimeout: 4000
			})

			this._successMessage = ''
		}

		this.setState({
			entitiesAndPagination
		})
	}

	_editFormValidations() {
		return {
			name: ({ value }) => (Validation.isNotEmpty(value) ? '' : 'Zadejte název výrobce'),
			[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]: ({ value }) =>
				value !== nonValue ? '' : 'Vyberte kategorii'
		}
	}

	_getEditFormSubmitPromise(formData) {
		const {
			value,
			[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]: categoryId,
			...data
		} = formData

		const routeParams = this.getRouteParams()

		let promise

		const categoryIds =
			categoryId || routeParams[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_ID]

		const saveData = Object.assign(
			{
				[InternalAdminUrlConvertor.constants.CODEBOOKS_NAME_ID]: MANUFACTURER_CB,
				[InternalAdminUrlConvertor.constants.CODEBOOKS_CATEGORY_IDS]: [categoryIds]
			},
			data
		)

		const { name: categoryName = '' } =
			this._getCarCategories().find(({ value }) => categoryIds == value) || {}

		if (value) {
			promise = this._codebooksService.putCodebooksValues(value, saveData)
			this._successMessage = `Výrobce "${formData.name}" v kategorii "${categoryName}" byl úspěšně upraven`
		} else {
			promise = this._codebooksService.postCodebooksValues(saveData)
			this._successMessage = `Výrobce "${formData.name}" byl úspěšně přidán do kategorie "${categoryName}"`
		}

		return promise
	}
}
