import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import ConfirmButton from 'app/component/filters/components/confirmButton/ConfirmButton'

const CLASSNAME = 'c-filter-dropdown-button'

export default class FilterDropdownButton extends AbstractPureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			filterDropdownLogicData: PropTypes.object.isRequired
		}
	}

	static get defaultProps() {
		return {
			className: '',
			filterDropdownLogicData: DEFAULT_PROPS.OBJECT
		}
	}

	render() {
		const { className, filterDropdownLogicData } = this.props
		const { closeDropdown, canBeDropdownClosed } = filterDropdownLogicData

		return (
			<ConfirmButton
				className={this.cssClasses({
					[CLASSNAME]: true,
					[className]: !!className
				})}
				disabled={!canBeDropdownClosed()}
				onClick={closeDropdown}
			/>
		)
	}
}
