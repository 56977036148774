import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Context from 'ima/page/context'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import classnames from 'classnames'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import IMAGES from 'app/base/ImagesConstants'
import PremiseService from 'app/model/premise/PremiseService'
import { getFormatedPrice } from 'app/model/payment/PaymentFactory'

import './WalletCS.json'
import 'app/base/BaseCS.json'
import './Wallet.less'

const CLASSNAME = 'c-wallet'

const WalletInfo = (props) => {
	const { className, walletInfo, showActionButton, premiseInfo } = props

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<WalletInfoContent
				walletInfo={walletInfo}
				premiseInfo={premiseInfo}
				showActionButton={showActionButton}
			/>
		</div>
	)
}

WalletInfo.propTypes = {
	className: PropTypes.string,
	walletInfo: PropTypes.shape({
		credit: PropTypes.number,
		walletLogin: PropTypes.string,
		walletUserId: PropTypes.string,
		serverError: PropTypes.bool
	}),
	showActionButton: PropTypes.bool,
	premiseInfo: PropTypes.object
}

WalletInfo.defaultProps = {
	className: '',
	walletInfo: DEFAULT_PROPS.OBJECT,
	showActionButton: true,
	premiseInfo: DEFAULT_PROPS.OBJECT
}

const WalletInfoContent = ({ walletInfo, premiseInfo, showActionButton }) => {
	const localize = useLocalize()

	const context = useContext(Context)
	const settings = context.$Settings.$App

	const { credit, walletUserId, serverError = false } = walletInfo

	const walletUrl = `${settings.walletUrl}${encodeURIComponent(
		'/credit?language=cz&new-order=true&service=sauto'
	)}`

	if (!isNullOrUndefined(credit) && credit >= 0) {
		const formatedCredit = getFormatedPrice(credit)

		return (
			<>
				<div className='notranslate' translate='no' lang='cs-cz'>{`${formatedCredit} ${localize(
					'Base.CZK'
				)}`}</div>
				<RefreshButton />
				{showActionButton && (
					<a href={walletUrl} className={`${CLASSNAME}__link`} target='seznam-penezenka'>
						{localize('Wallet.walletRechargeLink')}
					</a>
				)}
			</>
		)
	} else if (walletUserId) {
		return (
			<>
				<div>{localize('Wallet.walletNotActivated')}</div>
				{showActionButton && (
					<>
						<RefreshButton />
						<a className={`${CLASSNAME}__link`} href={walletUrl} target='seznam-penezenka'>
							{localize('Wallet.walletActivateLink')}
						</a>
					</>
				)}
			</>
		)
	} else if (serverError) {
		return (
			<>
				<div>{localize('Wallet.walletOutOfOrder')}</div>
				<button
					className={`${CLASSNAME}__link`}
					type='button'
					onClick={() => window.location.reload()}
				>
					{localize('Wallet.refreshPage')}
				</button>
			</>
		)
	} else {
		return <WalletNotConnected premiseInfo={premiseInfo} showActionButton={showActionButton} />
	}
}

const RefreshButton = () => {
	const fire = useFire()

	return (
		<div className={`${CLASSNAME}__refresh-wrapper`}>
			(
			<button
				type='button'
				onClick={() => {
					fire('getPremiseWalletInfo')
				}}
			>
				<img
					alt=''
					src={IMAGES['image__payment-refresh']}
					className={`${CLASSNAME}__refresh-img`}
				/>
			</button>
			)
		</div>
	)
}

const WalletNotConnected = ({ premiseInfo, showActionButton }) => {
	const localize = useLocalize()

	const { admins = [] } = premiseInfo
	const email = admins.length ? admins[0].email : ''
	const { name } = PremiseService.getPremiseDataForPublic(premiseInfo)

	return (
		<div className={`${CLASSNAME}__not-connected`}>
			<div>{localize('Wallet.walletNotConnected')}</div>
			{showActionButton && (
				<a
					className={`${CLASSNAME}__link`}
					href={`mailto:${email}?subject=${encodeURIComponent(
						localize('Wallet.emailSubject', {
							PREMISE_NAME: name
						})
					)}`}
				>
					{localize('Wallet.walletConnect')}
				</a>
			)}
		</div>
	)
}

WalletNotConnected.propTypes = {
	premiseInfo: PropTypes.object,
	showActionButton: PropTypes.bool
}

WalletNotConnected.defaultProps = {
	premiseInfo: DEFAULT_PROPS.OBJECT,
	showActionButton: true
}

const WalletAcount = (props) => {
	const { walletInfo, premiseInfo, showActionButton } = props
	const { walletLogin } = walletInfo

	if (walletLogin) {
		return <div>{walletLogin}</div>
	} else {
		return <WalletNotConnected premiseInfo={premiseInfo} showActionButton={showActionButton} />
	}
}

WalletAcount.propTypes = {
	walletInfo: PropTypes.shape({
		credit: PropTypes.number,
		walletLogin: PropTypes.string,
		walletUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		serverError: PropTypes.bool
	}),
	premiseInfo: PropTypes.object,
	showActionButton: PropTypes.bool
}

WalletAcount.defaultProps = {
	walletInfo: DEFAULT_PROPS.OBJECT,
	premiseInfo: DEFAULT_PROPS.OBJECT,
	showActionButton: true
}

const WalletLabel = () => {
	const localize = useLocalize()

	return <div className={`${CLASSNAME}__label`}>{localize('Wallet.walletLabel')}</div>
}

// kontainer pre komponenty penazenky
const Wallet = () => null

Wallet.Acount = React.memo(WalletAcount)

Wallet.Info = React.memo(WalletInfo)

Wallet.Label = WalletLabel

export default Wallet
