import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import { useLocalize, useLink, useFire, useRouteName } from 'app/base/componentHelpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import AdvertImageResponsiveSettings from '../AdvertImageResponsiveSettings'

import './InactiveAdvert.less'
import './InactiveAdvertCS.json'
import 'app/base/BaseCS.json'

const CLASSNAME = 'c-inactive-item'

const InactiveAdvert = ({ advertEntity, children: renderedAdvert }) => {
	const localize = useLocalize()
	const routeName = useRouteName()
	const link = useLink()
	const fire = useFire()

	const [showThisAdvert, setShowThisAdvert] = useState(false)

	const {
		category: { seoName: categorySeoName } = {},
		name = '',
		price,
		images = [],
		manufacturerCb: { seoName: brandSeoName } = {},
		modelCb: { seoName: modelSeoName } = {},
		manufacturingDate
	} = advertEntity

	const handleShowThisAdvertClick = () => {
		setShowThisAdvert(true)
		animateScrollTo(0, { minDuration: 0, maxDuration: 0 })
	}

	if (showThisAdvert) {
		return renderedAdvert
	} else {
		const manufacturingYear = manufacturingDate
			? new Date(manufacturingDate).getFullYear()
			: undefined

		const similarAdvertsLink = link(
			routeName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
				? ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES
				: ROUTE_NAMES.USERWEB.ADVERT_LIST,
			Object.assign(
				{
					[AdvertListUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: categorySeoName,
					[AdvertListUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: brandSeoName,
					[AdvertListUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]: modelSeoName
				},
				manufacturingYear && {
					[AdvertListUrlConvertor.constants.URL_APP_PARAMS.AGE_FROM]: manufacturingYear,
					[AdvertListUrlConvertor.constants.URL_APP_PARAMS.AGE_TO]: manufacturingYear
				}
			)
		)

		return (
			<div className={CLASSNAME}>
				<div className={`${CLASSNAME}__photo-wrapper`}>
					<div className={`${CLASSNAME}__photo-placeholder`}>
						{images.length > 0 && (
							<img
								className={`${CLASSNAME}__photo`}
								src={AdvertImageResponsiveSettings.normalSettings.src(images[0].url)}
								srcSet={AdvertImageResponsiveSettings.normalSettings.srcSet(images[0].url)}
								alt={images[0].alt}
							/>
						)}
					</div>
					<div className={`${CLASSNAME}__info`}>
						<span className={`${CLASSNAME}__name`}>{name}</span>
						<span className={`notranslate ${CLASSNAME}__price`} translate='no' lang='cs-cz'>
							{Format.number(price)},- {localize('Base.CZK')}
						</span>
					</div>
				</div>

				<div className={`${CLASSNAME}__title`}>{localize('InactiveAdvert.title')}</div>
				<div className={`${CLASSNAME}__description`}>{localize('InactiveAdvert.description')}</div>

				<div className={`${CLASSNAME}__cta-buttons`}>
					<Button
						className={`${CLASSNAME}__similar-cta-button`}
						size='small'
						primary={true}
						text={localize('InactiveAdvert.showSimilarAdverts')}
						href={similarAdvertsLink}
						onClick={() => fire('resetCache')}
					/>
					<Button
						size='small'
						text={localize('InactiveAdvert.showThisAdvert')}
						onClick={handleShowThisAdvertClick}
					/>
				</div>

				<a className={`${CLASSNAME}__back-to-hp`} href={link(ROUTE_NAMES.USERWEB.HOMEPAGE)}>
					{localize('Base.goBackHome')}
				</a>
			</div>
		)
	}
}

InactiveAdvert.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes)
}

InactiveAdvert.defaultProps = {
	advertEntity: DEFAULT_PROPS.OBJECT
}

export default InactiveAdvert
