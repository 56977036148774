import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useInView } from 'react-intersection-observer'
import { Button } from '@sznds/react'
import operatingLeasePropTypes, {
	operatingLeaseDefinitionPropTypes
} from 'app/model/operatingLease/OperatingLeasePropTypes'
import { useLocalize } from 'app/base/componentHelpers'
import animateScrollTo from 'app/helpers/animateScrollTo/AnimateScrollTo'
import NewAdvertSectionHeading from 'app/component/newAdvertSectionHeading/NewAdvertSectionHeading'
import OperatingLeaseIntend from './intend/OperatingLeaseIntend'
import OperatingLeaseVariantsList from './variantsList/OperatingLeaseVariantsList'
import NewOperatingLeaseVariantForm from './newVariant/NewOperatingLeaseVariantForm'

import './OperatingLeaseFormCS.json'
import './OperatingLeaseForm.less'

const CLASSNAME = 'c-operating-lease-form'

const RIBBON_HEIGHT = 56

const OperatingLeaseForm = ({
	className,
	operatingLeaseDefinitionData,
	operatingLeaseVariants,
	operatingLeaseIntendedFor,
	onOperatingLeaseIntendedForChange,
	fetchOperatingLeasesVariants,
	addNewOperatingLeaseVariant,
	editOperatingLeaseVariant,
	deleteOperatingLeaseVariant
}) => {
	const localize = useLocalize()

	const { ref: sectionHeadingInViewRef, inView: sectionHeadingInView } = useInView()
	const sectionHeadingRef = useRef()
	const { ref: sectionNewVariantInViewRef, inView: sectionNewVariantInView } = useInView()
	const sectionNewVariantRef = useRef()

	const [isNewVariantFormOpened, setIsNewVariantFormOpened] = useState(false)
	const [variantToEdit, setVariantToEdit] = useState()

	const setSectionHeadingRefs = useCallback(
		(node) => {
			sectionHeadingRef.current = node
			sectionHeadingInViewRef(node)
		},
		[sectionHeadingInViewRef]
	)

	const setSectionNewVariantRefs = useCallback(
		(node) => {
			sectionNewVariantRef.current = node
			sectionNewVariantInViewRef(node)
		},
		[sectionNewVariantInViewRef]
	)

	const handleNewVariantButtonClick = () => {
		setVariantToEdit(undefined) // zadnou variantu upravovat nechceme, chceme vytvorit novou
		setIsNewVariantFormOpened(true)
	}

	const handleFormClose = () => {
		// po zavreni formulare pro vlozeni/editaci varianty zascrollujeme na
		// nadpis sekce. Dochazelo k neprijemnemu poskoceni, kdy uzivatel pak
		// videl jen napr. sekci pro nastaveni motorizace a musel scrollovat zpet.
		if (sectionHeadingRef.current && !sectionHeadingInView) {
			animateScrollTo(sectionHeadingRef.current, {
				offset: -RIBBON_HEIGHT
			})
		}

		setIsNewVariantFormOpened(false)
		setVariantToEdit(undefined)
	}

	const handleEditButtonClick = (variant) => {
		setVariantToEdit(variant)
		setIsNewVariantFormOpened(true)

		if (sectionNewVariantRef.current && !sectionNewVariantInView) {
			animateScrollTo(sectionNewVariantRef.current, {
				offset: -RIBBON_HEIGHT
			})
		}
	}

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<NewAdvertSectionHeading ref={setSectionHeadingRefs}>
				{localize('OperatingLeaseForm.heading')}
			</NewAdvertSectionHeading>
			<OperatingLeaseIntend
				className={`${CLASSNAME}__intend`}
				operatingLeaseDefinitionData={operatingLeaseDefinitionData}
				operatingLeaseIntendedFor={operatingLeaseIntendedFor}
				onOperatingLeaseIntendedForChange={onOperatingLeaseIntendedForChange}
			/>
			<OperatingLeaseVariantsList
				operatingLeaseDefinitionData={operatingLeaseDefinitionData}
				operatingLeaseVariants={operatingLeaseVariants}
				deleteOperatingLeaseVariant={deleteOperatingLeaseVariant}
				onEdit={handleEditButtonClick}
			/>
			<div ref={setSectionNewVariantRefs}>
				{isNewVariantFormOpened ? (
					<NewOperatingLeaseVariantForm
						operatingLeaseDefinitionData={operatingLeaseDefinitionData}
						variantToEdit={variantToEdit}
						fetchOperatingLeasesVariants={fetchOperatingLeasesVariants}
						addNewOperatingLeaseVariant={addNewOperatingLeaseVariant}
						editOperatingLeaseVariant={editOperatingLeaseVariant}
						onFormClose={handleFormClose}
					/>
				) : (
					<Button
						className={`${CLASSNAME}__new-variant-button`}
						text={localize('OperatingLeaseForm.newVariant')}
						type='button'
						primary={true}
						disabled={!operatingLeaseDefinitionData?.length}
						data-e2e='operating-lease-new-variant-button'
						onClick={handleNewVariantButtonClick}
					/>
				)}
			</div>
		</div>
	)
}

OperatingLeaseForm.propTypes = {
	className: PropTypes.string,
	operatingLeaseDefinitionData: PropTypes.arrayOf(
		PropTypes.shape(operatingLeaseDefinitionPropTypes)
	),
	operatingLeaseVariants: PropTypes.arrayOf(PropTypes.shape(operatingLeasePropTypes)),
	operatingLeaseIntendedFor: PropTypes.arrayOf(PropTypes.number),
	onOperatingLeaseIntendedForChange: PropTypes.func,
	fetchOperatingLeasesVariants: PropTypes.func,
	addNewOperatingLeaseVariant: PropTypes.func,
	editOperatingLeaseVariant: PropTypes.func,
	deleteOperatingLeaseVariant: PropTypes.func
}

export default OperatingLeaseForm
