import React, { memo } from 'react'
import classnames from 'classnames'
import { Textarea } from '@sznds/react'
import { CLASSNAME } from '../InternalAdminWidgets'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'

const WidgetTextarea = memo(
	({ className, formLineEntity = {}, onChange = DEFAULT_PROPS.FUNCTION }) => {
		const {
			id,
			label,
			value = '',
			placeholder,
			required = false,
			disabled = false,
			errorMessage,
			extra: { rows, cols } = {}
		} = formLineEntity

		const isError = Boolean(errorMessage)

		return (
			<div className={className}>
				<div className={`${CLASSNAME}__input-box`}>
					{!!label && (
						<label htmlFor={id} className={`${CLASSNAME}__label`}>
							{label}
							{required && <span className={`${CLASSNAME}__req`}>*</span>}
						</label>
					)}

					<Textarea
						className={classnames({
							[`${CLASSNAME}__input`]: true,
							[`${CLASSNAME}__input--disabled`]: disabled
						})}
						id={id}
						value={value}
						placeholder={placeholder}
						onChange={(event) => {
							onChange({
								id,
								value: event.target.value
							})
						}}
						disabled={disabled}
						error={isError}
						rows={rows}
						cols={cols}
					/>
				</div>
				{isError && <div className={`${CLASSNAME}__error`}>{errorMessage}</div>}
			</div>
		)
	}
)

WidgetTextarea.propTypes = {
	formLineEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(FormLines.Entity)]),
	onChange: PropTypes.func,
	className: PropTypes.string
}

export default WidgetTextarea
