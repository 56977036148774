
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['VerifyPin'] = {
  retypePin: function(d) { return "Ten prosím opište a potvrďte."; },
  placeholder: function(d) { return "Zadejte PIN"; },
  checkPin: function(d) { return "Ověřit"; },
  wrongPin: function(d) { return "Zadejte správný ověřovací kód"; },
  sendAgain: function(d) { return "Poslat nový PIN kód"; },
  pinExpired: function(d) { return "Tento PIN kód už je starý, nebo bylo příliš mnoho pokusů o uhodnutí."; },
  cancelVerification: function(d) { return "Zrušit ověřování"; },
  checkbox: function(d) { return "Uložit jako kontaktní telefon Seznam účtu"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	