export const getYesterday = (today = new Date()) => {
	const yesterday = new Date(today)

	yesterday.setDate(today.getDate() - 1)
	yesterday.setHours(0, 0, 0, 0)

	return yesterday
}

export const getPreviousMonth = (today = new Date()) => {
	const previousMonth = new Date(today)

	previousMonth.setMonth(previousMonth.getMonth() - 1)

	return previousMonth
}

export const getReleaseDate = () => new Date(2021, 5, 14)
