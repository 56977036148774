import React from 'react'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import { Format, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize } from 'app/base/componentHelpers'
import { useFire } from 'app/base/componentHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EmptyList from 'app/component/emptyList/EmptyList'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import AdvertHistoryRow from './advertHistoryRow/AdvertHistoryRow'

import './IAAdvertHistoryCS.json'
import './IAAdvertHistoryView.less'

const CLASSNAME = 'p-ia-history'

export default function IAAdvertHistoryView({
	advertId = '',
	entitiesAndPagination = DEFAULT_PROPS.ARRAY,
	isLoading = false,
	isAllRowsDetailsOpened = false
}) {
	const localize = useLocalize()
	const fire = useFire()

	const renderRow = ({ rowId, entityData, rowCells }) => {
		return (
			<AdvertHistoryRow
				key={rowId}
				entityData={entityData}
				rowCells={rowCells}
				isAllRowsDetailsOpen={isAllRowsDetailsOpened}
			/>
		)
	}

	return (
		<Layout title={localize('IAAdvertHistory.title', { ADVERT_ID: advertId })}>
			<button
				className={`${CLASSNAME}__toggle`}
				onClick={() => {
					fire('toggleAllRowsDetailsOpened')
				}}
			>
				{isAllRowsDetailsOpened ? 'Zabalit vše' : 'Rozbalit vše'}
			</button>
			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHeadSorter key='time' sortParam='id' isDefaultDescending={true}>
						{localize('IAAdvertHistory.date')}
					</TableHeadSorter>,
					<TableHead key='changedBy'>{localize('IAAdvertHistory.author')}</TableHead>,
					<TableHead key='ipAddress'>{localize('IAAdvertHistory.ipAddress')}</TableHead>,
					<TableHead key='changes'>{localize('IAAdvertHistory.changes')}</TableHead>
				]}
				rowCells={[
					({ time }) => Format.dateTime(time),
					({ changedBy }) => changedBy,
					({ ipAddress }) => ipAddress,
					() => [localize('IAAdvertHistory.showDetails'), { isOpener: true }]
				]}
				renderEmptyBody={() => (
					<EmptyList title={localize('IAAdvertHistory.nothingFound')} text='' />
				)}
				renderRow={renderRow}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAAdvertHistoryView.propTypes = {
	advertId: PropTypes.string,
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.object),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	isLoading: PropTypes.bool,
	isAllRowsDetailsOpened: PropTypes.bool
}
