/**
 * Trieda sluzi ku komunikacii medzi vice kontrolery
 * ked je potrebne poslat informaciu ci sa ma zobrazit toast
 */
export default class ToastHelper {
	static isToastShown() {
		return !!ToastHelper.toast
	}

	static getToast() {
		return ToastHelper.toast
	}

	static setToast(toast) {
		ToastHelper.toast = toast
	}
}
