
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['LocalitySelectInput'] = {
  mostFrequentLocalities: function(d) { return "Nejčastější volby"; },
  useGeolocation: function(d) { return "Použít aktuální lokaci"; },
  useGeolocationAdditionalText: function(d) { return "Zaměřit moji polohu"; },
  geolocationRunning: function(d) { return "Hledám…"; },
  geolocationFailed: function(d) { return "Nepodařilo se zjistit vaši lokaci."; },
  district: function(d) { return "okres"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	