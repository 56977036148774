import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { LayoutAdvertEdit } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import STATE_KEYS from './IAAdvertPhotosEditStateKeys'
import PhotosUploader from 'app/component/photosUploader/PhotosUploader'
import VideoUploader from 'app/component/videoUploader/VideoUploader'
import { Surface } from '@sznds/react'
import PhotosUploaderExtension from 'app/component/photosUploader/PhotosUploaderExtension'
import VideoUploaderExtension from 'app/component/videoUploader/VideoUploaderExtension'
import FormStepper from 'app/page/userweb/newAdvert/component/stepper/FormStepperView'
import NewAdvertSectionHeading from 'app/component/newAdvertSectionHeading/NewAdvertSectionHeading'

import 'app/page/userweb/newAdvert/photos/PhotosCS.json'
import './IAAdvertPhotosEditView.less'

const CLASSNAME = 'c-ia-photos'

export default function IAAdvertPhotosEditView(props) {
	const { [STATE_KEYS.ADVERT_ENTITY]: advertEntity } = props

	const localize = useLocalize()

	const {
		[PhotosUploaderExtension.stateKeys.PHOTOS]: photos = [],
		[PhotosUploaderExtension.stateKeys.MINIMAL_PHOTOS]: photosLimit,
		[VideoUploaderExtension.stateKeys.VIDEO]: video
	} = props

	const renderFormStepper = () => {
		const okPhotos = photos.filter(({ error, serverId }) => !error && serverId)

		return (
			<FormStepper
				disableNext={okPhotos.length < photosLimit}
				continueBySubmit={false}
				isAdmin={true}
			/>
		)
	}

	return (
		<LayoutAdvertEdit>
			{advertEntity && (
				<Surface className={`${CLASSNAME}__form`} surface={5}>
					<div className={`${CLASSNAME}__section`}>
						<NewAdvertSectionHeading>{localize('Photos.photos')}</NewAdvertSectionHeading>
						<PhotosUploader photos={photos} />
					</div>

					<div className={`${CLASSNAME}__section`}>
						<NewAdvertSectionHeading>{localize('Photos.videos')}</NewAdvertSectionHeading>
						<span className={`${CLASSNAME}__section-note`}>
							{localize('Photos.videosInfo')}&nbsp;
						</span>

						<VideoUploader video={video} />
					</div>
				</Surface>
			)}

			{renderFormStepper()}
		</LayoutAdvertEdit>
	)
}
