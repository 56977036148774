import AbstractComponent from 'app/base/AbstractComponent'
import React from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import STATE_KEYS from 'app/page/userweb/advertDetail/AdvertDetailStateKeys'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import NavButton, { BUTTON_TYPE } from 'app/component/navButton/NavButtonView'
import ROUTE_NAMES from 'app/base/RouteNames'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import { Button } from '@sznds/react'
import { CHEVRON_RIGHT_OUTLINE_24, CHEVRON_LEFT_OUTLINE_24 } from '@sznds/icons'

import './DetailSubheaderView.less'
import './DetailSubheaderCS.json'

const CLASSNAME = 'c-detail-subheader'

class DetailSubheader extends AbstractComponent {
	static get propTypes() {
		return {
			prevNextAdvert: PropTypes.object,
			goBackData: PropTypes.object,
			prevRouteName: PropTypes.string,
			prevRouteParams: PropTypes.object
		}
	}

	static get defaultProps() {
		return {
			goBackData: DEFAULT_PROPS.OBJECT
		}
	}

	render() {
		const { isPreviewMode = false } = this.props

		return (
			<div className={CLASSNAME}>
				{!isPreviewMode && (
					<div className={`${CLASSNAME}__wrap`}>
						{this._renderBackToList()}
						<div>
							{this._renderPrevAdvertButton()}
							{this._renderNextAdvertButton()}
						</div>
					</div>
				)}
			</div>
		)
	}

	_renderBackToList() {
		const { goBackData = {} } = this.props
		const { text = '', url = '', callback = DEFAULT_PROPS.FUNCTION } = goBackData

		if (text && url) {
			return <NavButton text={text} url={url} type={BUTTON_TYPE.BACK} onClick={callback} />
		} else {
			return <div className={`${CLASSNAME}__back-to-list-placeholder`} />
		}
	}

	_renderPrevAdvertButton() {
		const { prevNextAdvert = {} } = this.props
		const { listRouteName, prev: prevAdvert, next: nextAdvert } = prevNextAdvert

		const isFirst = this._isValidAdvert(nextAdvert) && !this._isValidAdvert(prevAdvert)

		if (this._isValidAdvert(prevAdvert) || isFirst) {
			return this._renderPrevNextBtn({
				className: `${CLASSNAME}__prev-button`,
				href: isFirst ? '' : this._getAdvertUrl(listRouteName, prevAdvert),
				icon: CHEVRON_LEFT_OUTLINE_24,
				'data-dot': 'go-to-prev',
				disabled: isFirst
			})
		}
	}

	_renderNextAdvertButton() {
		const { prevNextAdvert = {} } = this.props
		const { listRouteName, next: nextAdvert, prev: prevAdvert } = prevNextAdvert

		const isLast = !this._isValidAdvert(nextAdvert) && this._isValidAdvert(prevAdvert)

		if (this._isValidAdvert(nextAdvert) || isLast) {
			return this._renderPrevNextBtn({
				className: `${CLASSNAME}__next-button`,
				text: this.localize('DetailSubheader.nextAdvert'),
				href: isLast ? '' : this._getAdvertUrl(listRouteName, nextAdvert),
				icon: CHEVRON_RIGHT_OUTLINE_24,
				'data-dot': 'go-to-next',
				disabled: isLast
			})
		}
	}

	_renderPrevNextBtn(props) {
		return <Button {...props} primary={false} surface={3} size='small' />
	}

	_getAdvertUrl(listRouteName, prevOrNextAdvert = {}) {
		const getAdvertRouteName = () => {
			if (listRouteName === ROUTE_NAMES.USERWEB.FAVORITE_LIST) {
				// na soucasny detail jsme prisli z vypisu oblibenych inzeratu. Na tomto vypise
				// mohou byt inzeraty promichane dohromady (obycejne vs na operak), proto se
				// musime divat na dealType nasledujiciho inzeratu
				const { dealType } = prevOrNextAdvert

				return dealType === DEAL_TYPE.OPERATING_LEASE
					? ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
					: ROUTE_NAMES.USERWEB.ADVERT_DETAIL
			} else {
				// na soucasny detail jsme prisli z vypisu nebo vypisu na prodejci, behem
				// navigace drzime jeden odpovidajici typ inzeratu
				return [
					ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES,
					ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
				].includes(listRouteName)
					? ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
					: ROUTE_NAMES.USERWEB.ADVERT_DETAIL
			}
		}

		const { id, oldId, category, manufacturerCb, modelCb } = prevOrNextAdvert

		const advertRouteName = getAdvertRouteName()

		return this.link(advertRouteName, {
			[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: oldId || id,
			[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]: category.seoName,
			[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]: manufacturerCb.seoName,
			[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]: modelCb.seoName
		})
	}

	_isValidAdvert(advertEntity) {
		const { id, oldId, category, manufacturerCb, modelCb } = advertEntity || {}

		return (id || oldId) && category && manufacturerCb && modelCb
	}
}

const SubheaderSelector = (state) => ({
	prevNextAdvert: state[STATE_KEYS.PREV_NEXT_ADVERT],
	goBackData: state[STATE_KEYS.GO_BACK_DATA],
	prevRouteName: state[STATE_KEYS.PREV_ROUTE_NAME],
	prevRouteParams: state[STATE_KEYS.PREV_ROUTE_PARAMS],
	advertEntity: state[STATE_KEYS.ADVERT_ENTITY],
	cachedAdvertEntity: state[STATE_KEYS.CACHED_ADVERT_ENTITY],
	isPreviewMode: state[STATE_KEYS.IS_PREVIEW_MODE]
})

export default select(SubheaderSelector)(DetailSubheader)
