
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['MySavedSearchSaveForm'] = {
  contentLoading: function(d) { return "Načítám data"; },
  optionsTitle: function(d) { return "Nastavit upozornění"; },
  description: function(d) { return "Pošleme vám oznámení na email, když se objeví nové inzeráty."; },
  immediately: function(d) { return "Ihned"; },
  daily: function(d) { return "Jednou denně"; },
  dailyNote: function(d) { return "(v 18:00)"; },
  notToSend: function(d) { return "Nezasílat"; },
  notToSendNote: function(d) { return "(jen uložit hledání)"; },
  ribbonNotificationsDescription: function(d) { return "Pošleme vám oznámení do Seznam lišty, když se objeví nové inzeráty."; },
  ribbonNotifications: function(d) { return "Zasílat notifikace do Seznam lišty"; },
  add: function(d) { return "Uložit hledání"; },
  update: function(d) { return "Uložit nastavení"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	