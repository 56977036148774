import React from 'react'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import { Format } from '@inzeraty/helpers'
import { Surface } from '@sznds/react'
import ArticlesDetailEntity from 'app/model/articles/ArticlesDetailEntity'

import './Articles.less'
import './AdvertDetailArticlesCS.json'

const CLASSNAME = 'c-articles'

const Articles = ({ articles = [] }) => {
	const localize = useLocalize()

	if (articles.length < 1) return null

	return (
		<Surface tagName='div' surface={5} className={`${CLASSNAME}__articles-surface`}>
			<div className={CLASSNAME}>
				<div className={`${CLASSNAME}__title`}>{localize('AdvertDetailArticles.title')}</div>
				{articles.map((article) => {
					return (
						<div key={article.id} className={`${CLASSNAME}__article-wrap`}>
							<div className={`${CLASSNAME}__image-wrap`}>
								<img
									className={`${CLASSNAME}__image`}
									width={100}
									height={64}
									src={article.imageUrl}
									alt='Obrázek článku'
								/>
							</div>
							<div className={`${CLASSNAME}__text-wrap`}>
								<a
									className={`${CLASSNAME}__article-title`}
									href={article.url}
									target='_blank'
									rel='noopener noreferrer'
								>
									{article.title}
								</a>
								<div className={`${CLASSNAME}__under-title`}>
									{`${Format.date(article.createDate)} – ${article.source}.cz`}
								</div>

								<div className={`${CLASSNAME}__perex`}>{article.perex}</div>
							</div>
						</div>
					)
				})}
			</div>
		</Surface>
	)
}

Articles.propTypes = {
	articles: PropTypes.arrayOf(PropTypes.instanceOf(ArticlesDetailEntity))
}

export default Articles
