import React from 'react'
import PropTypes from 'prop-types'
import select from 'ima-plugin-select'
import { PageLoaderOverlay } from '@inzeraty/components'
import PageLoaderExtension from './PageLoaderExtension'

export const CLASSNAME = 'c-page-loader'

const PageLoader = (props) => <PageLoaderOverlay {...props} />

PageLoader.propTypes = {
	isLoading: PropTypes.bool,
	note: PropTypes.string
}

PageLoader.defaultProps = {
	isLoading: false,
	note: ''
}

const PageLoaderSelector = (state) => ({
	isLoading: state[PageLoaderExtension.STATE_KEYS.IS_LOADING],
	note: state[PageLoaderExtension.STATE_KEYS.LOADING_NOTE]
})

export default select(PageLoaderSelector)(PageLoader)
