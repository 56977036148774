import UserwebBaseController from 'app/base/UserwebBaseController'
import SellerUrlConvertor from './SellerUrlConvertor'
import RouteNames from 'app/base/RouteNames'
import AdvertListExtension from 'app/component/advertList/AdvertListExtension'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import ROUTE_NAMES from 'app/base/RouteNames'
import CacheConstants from 'app/helpers/cache/cacheConstants'
import getRouteName from 'app/helpers/routeName/getRouteName'
import STATE_KEYS from 'app/page/userweb/seller/SellerStateKeys'
import CategoryPickerConstants from 'app/component/pickerButton/CategoryPickerConstants'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import GenericError from 'ima/error/GenericError'
import { CATEGORIES } from 'app/base/Constants'
import deepEqual from 'fast-deep-equal'
import ScrollRestore from 'app/helpers/scrollRestore/ScrollRestore'
import SellerListUrlConvertor from '../sellerList/SellerListUrlConvertor'
import SellerOperatingLeaseMeta from './SellerOperatingLeaseMeta'

import './SellerViewCS.json'
import 'app/base/BaseCS.json'

export default class SellerController extends UserwebBaseController {
	/**
	 * @method constructor
	 * @constructor
	 * @param {ns.app.helpers.dependenciesHelper.DependenciesHelper} dependenciesHelper
	 * @param {PremiseService}                                       premiseService
	 * @param {AdvertListExtension}                                  advertListExtension
	 */
	constructor(
		dependenciesHelper,
		premiseService,
		advertListExtension,
		categoryService,
		filtersExtension
	) {
		super(dependenciesHelper)

		this._premiseService = premiseService
		this._categoryService = categoryService
		this._filtersExtension = filtersExtension

		this._router = this._utils.$Router
		this._dictionary = this._utils.$Dictionary

		this._advertListExtension = advertListExtension
		this._historyStepValue = -1
		this._initialIndex = 0

		this._premisePromise = undefined
	}

	init() {
		super.init()

		this.addExtension(this._advertListExtension)
		this.addExtension(this._filtersExtension)

		this._cacheHelper.deleteEntities(CacheConstants.SELLER_LIST.NAME)
		this._advertListExtension.setLoadPreferred(false)
	}

	load() {
		const categories = this._categoryService.getCategoriesIncludingAdvertsCount()
		const category = this._getCategory(categories)
		const filterFormLineEntities = category.then((categoryEntity) =>
			this._getFormLineEntities(categoryEntity)
		)
		this._initialIndex = this._historyStorage.getHistoryArrayUrl().length - 1

		const premisePromise = this._getPremiseEntity()
		const advertsParamsPromise = this._getAdvertsAndPaginationParams(premisePromise, categories)

		const premiseCategoriesPromise = premisePromise.then((premiseEntity) =>
			this._getPremiseCategories(premiseEntity)
		)

		this._checkUrl(premisePromise, premiseCategoriesPromise)

		return super.load({
			[STATE_KEYS.CATEGORIES]: premiseCategoriesPromise,
			[STATE_KEYS.SELECTED_CATEGORY]: this._getSelectedCategory(),
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: this._isOperatingLeasePage(),
			[FiltersExtension.stateIds.CATEGORY]: category,
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntities,
			[FiltersExtension.stateIds.URL_PARAMS]: filterFormLineEntities.then((formLineEntities) =>
				this._filtersExtension.getAdvertListUrlParams(formLineEntities)
			),
			[AdvertListExtension.stateIds.ADVERT_LIST_PARAMS_PROMISE]: advertsParamsPromise,
			[STATE_KEYS.PREMISE_ENTITY]: premisePromise.then((premiseEntity) => premiseEntity),
			[STATE_KEYS.GO_BACK_DATA]: this._getGoBackData(premisePromise),
			[STATE_KEYS.IS_PREMISE_ENTITY_LOADED]: premisePromise.then(() => true),
			[STATE_KEYS.PREV_ROUTE_NAME]: this._historyStorage.getPreviousRouteName()
		})
	}

	update(prevParams) {
		const isUserClickOnBackOrForward = !!this._historyStorage.getActualUrl()
		const actualUrl = this._historyStorage.getActualUrl()
		const actualHistoryArrayUrl = this._historyStorage.getHistoryArrayUrl()
		const categories = this._categoryService.getCategoriesIncludingAdvertsCount()
		const category = this._getCategory(categories)
		const filterFormLineEntities = category.then((categoryEntity) =>
			this._getFormLineEntities(categoryEntity)
		)

		// uzivatel klikol na spat alebo dopredu v prehliadaci
		if (isUserClickOnBackOrForward) {
			const previousPageIndex = this._initialIndex - 1
			const actualIndexHistory = actualHistoryArrayUrl.findIndex((url) => url === actualUrl)
			this._historyStepValue = -Math.abs(actualIndexHistory - previousPageIndex)

			//uzivatel klikol na next page alebo prev page alebo zmenil poradie
		} else {
			this._historyStepValue -= 1
		}

		const { [STATE_KEYS.PREMISE_ENTITY]: premiseEntity } = this.getState()
		const premisePromise = new Promise((resolve, reject) => resolve(premiseEntity))

		return super.update(prevParams, {
			[STATE_KEYS.CATEGORIES]: this._getPremiseCategories(premiseEntity),
			[STATE_KEYS.SELECTED_CATEGORY]: this._getSelectedCategory(),
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: this._isOperatingLeasePage(),
			[FiltersExtension.stateIds.CATEGORY]: category,
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntities,
			[STATE_KEYS.PREMISE_ENTITY]: premisePromise.then((premiseEntity) => premiseEntity),
			[FiltersExtension.stateIds.URL_PARAMS]: filterFormLineEntities.then((formLineEntities) =>
				this._filtersExtension.getAdvertListUrlParams(formLineEntities)
			),
			[AdvertListExtension.stateIds
				.ADVERT_LIST_PARAMS_PROMISE]: this._getAdvertsAndPaginationParams(
				premisePromise,
				categories
			)
		})
	}

	async getDataForSsp() {
		const premiseEntity = await this._getPremiseEntity()

		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {
				premiseEntity
			}
		}
	}

	_getSelectedCategory() {
		const {
			params: { [SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: selectedCategoryParam }
		} = this._utils.$Router.getCurrentRouteInfo()

		return selectedCategoryParam ? selectedCategoryParam : CategoryPickerConstants.defaultValue
	}

	_isOperatingLeasePage() {
		return this.getRouteName() === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
	}

	async onDealTypeChanged(isOperatingLeaseSelected) {
		const { [FiltersExtension.stateIds.CATEGORY]: categoryEntity } = this.getState()

		// zavolame api pro definice odpovidajicich filtru
		const newFormLineEntities = await this._getFormLineEntities(
			categoryEntity,
			isOperatingLeaseSelected
		)

		// nastavime nove formliny
		this._filtersExtension.setFormLineEntitiesOnDealTypeChange(
			newFormLineEntities,
			isOperatingLeaseSelected
		)
	}

	/**
	 * Vráti formLineEntities pro filtr.
	 *
	 * @method _getFormLineEntities
	 * @private
	 * @param {Object} categoryEntity
	 * @return {Promise<Array<inzeraty/form-lines/Entity>>}
	 */
	_getFormLineEntities(categoryEntity, isOperatingLease = this._isOperatingLeasePage()) {
		const { id } = categoryEntity

		return this._filtersExtension.getFormLineEntities({
			[AdvertListUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: id,
			[AdvertListUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease
		})
	}

	/**
	 * Vrati aktualni kategorii
	 *
	 * @method _getCategory
	 * @private
	 * @param {Array} categoryEntities
	 * @param {String} categorySeoName
	 * @return {Promise} Aktualni kategorie
	 */
	_getCategory(categoryEntities) {
		const categorySeoName = this._getSelectedCategory()
		return categoryEntities.then((categories) => {
			const categoryEntity = categories.find((c) => c.seoName === categorySeoName)

			if (categoryEntity) {
				return categoryEntity
			} else {
				const error = new GenericError(`Kategorie '${categorySeoName}' neexistuje.`, {
					status: HTTP_STATUS_CODES.NOT_FOUND
				})

				return Promise.reject(error)
			}
		})
	}

	_getPremiseCategories(premise) {
		const { id } = premise

		return this._categoryService.getCategoriesIncludingAdvertsCount({
			[SellerUrlConvertor.constants.URL_API_PARAMS.SELLER_ID]: id,
			[SellerUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: this._isOperatingLeasePage()
		})
	}

	onAdvertClick(advertId) {
		const routeName = this._isOperatingLeasePage()
			? ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
			: ROUTE_NAMES.USERWEB.SELLER
		ScrollRestore.setId(advertId, routeName)

		this._historyStorage.setActualData({
			scrollPosition: {
				x: window ? window.scrollX : 0,
				y: window ? window.scrollY : 0
			}
		})
	}

	/**
	 * Ziska data pro tlacitko zpet
	 *
	 * @method _getGoBackData
	 * @param {@inzeraty.models.CategoryEntity} categoryEntity
	 * @private
	 **/
	async _getGoBackData(premisePromise) {
		const prevIndex = this._getPrevPageHistoryIndex()
		const prevRouteName = this._historyStorage.getIndexRouteName(prevIndex)
		const prevRouteParams = this._historyStorage.getIndexRouteParams(prevIndex)

		const goBackData = {
			text: '',
			url: ''
		}

		// je dostupna informace z history storage a predchozi stranka je detail inzeratu tak provedu pohyb zpet v historii
		if (prevRouteName && prevRouteName === RouteNames.USERWEB.ADVERT_DETAIL && prevRouteParams) {
			goBackData.text = this._dictionary.get('SellerView.backToDetail')
			goBackData.url = this._router.link(prevRouteName, prevRouteParams)
			goBackData.callback = (event) => {
				this._historyStorage.goBack(event, -(this._historyStorage.getActualIndex() - prevIndex))
			}

			// pokud se na stranku uzivatel dostane pres vypis firem, vratime ho zpet na vypis
		} else if (
			prevRouteName &&
			prevRouteParams &&
			prevRouteName === RouteNames.USERWEB.SELLER_LIST
		) {
			goBackData.text = this._dictionary.get('SellerView.backToList')
			goBackData.url = this._router.link(prevRouteName, prevRouteParams)
			goBackData.callback = (event) => {
				this._historyStorage.goBack(event, -(this._historyStorage.getActualIndex() - prevIndex))
			}

			// jinak se vratim na homepage
		} else {
			const { locality = {} } = (await premisePromise) || {}

			const { district, districtId, districtSeoName, region, regionId, regionSeoName } = locality

			if (regionId || districtId) {
				let localityData = {}

				if (districtId) {
					localityData = SellerListUrlConvertor.getUpdatedDistrictData({
						id: districtId,
						name: district,
						seoName: districtSeoName
					})
				} else {
					localityData = SellerListUrlConvertor.getUpdatedRegionData({
						id: regionId,
						name: region,
						seoName: regionSeoName
					})
				}

				const { name = '', seoName = '' } = localityData

				goBackData.text = `${this._dictionary.get('SellerView.backToSellerList')} ${name}`
				goBackData.url = this._router.link(ROUTE_NAMES.USERWEB.SELLER_LIST, {
					[SellerListUrlConvertor.constants.URL_APP_PARAMS.LOCALITY]: seoName
				})
			} else {
				goBackData.text = this._dictionary.get('Base.goBackHome')
				goBackData.url = this._router.link(RouteNames.USERWEB.HOMEPAGE)
			}
		}

		return goBackData
	}

	/**
	 * Ziska hodnotu indexu zaznamu v historii pro predchozi stranku
	 * pred prechodem na stranku prodejce
	 *
	 * @method _getPrevPageHistoryIndex
	 * @return {Number}
	 */
	_getPrevPageHistoryIndex() {
		this._prevHistoryIndex = this._historyStorage.getActualIndex() - 1

		if (this._historyStorage.getPreviousRouteName()) {
			const {
				URL_APP_PARAMS: { SELLER_ID }
			} = SellerUrlConvertor.constants
			const {
				USERWEB: { ADVERT_DETAIL, SELLER, SELLER_LIST }
			} = RouteNames
			const ALLOWED_ROUTES = [ADVERT_DETAIL, SELLER, SELLER_LIST]

			const actualRouteName = this._historyStorage.getActualRouteName()
			const actualRouteParams = this._historyStorage.getActualRouteParams()

			// V cyklu projdeme vsechny zaznamy z historii
			// a najdeme nejdrivejsi polozku, u ktere nasledujici zaznam odpovida
			// aktualni strance prodejce
			for (let index = this._prevHistoryIndex; index >= 0; index--) {
				if (!ALLOWED_ROUTES.includes(this._historyStorage.getIndexRouteName(index))) {
					break
				}

				const nextRouteName = this._historyStorage.getIndexRouteName(index + 1)
				const nextRouteParams = this._historyStorage.getIndexRouteParams(index + 1)

				if (
					nextRouteName === actualRouteName &&
					nextRouteParams[SELLER_ID] === actualRouteParams[SELLER_ID]
				) {
					this._prevHistoryIndex = index
				} else if (index === 0) {
					this._prevHistoryIndex = -1
				}
			}
		}

		return this._prevHistoryIndex
	}

	/**
	 * Set seo params.
	 *
	 * @method setSeoParams
	 * @param {Object} loadedResources
	 * @param {Core.Interface.MetaManager} metaManager
	 * @param {Core.Interface.Router} router
	 * @param {Core.Interface.Dictionary} dictionary
	 * @param {Object} settings
	 */
	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const {
			[STATE_KEYS.PREMISE_ENTITY]: premiseEntity = {},
			[AdvertListExtension.stateIds.ADVERT_LIST_PAGINATION]: advertListAndPagination = {},
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: filterFormLineEntities = []
		} = loadedResources
		const { name = '', description: premiseDescription = '' } = premiseEntity
		const { paginationEntity = {} } = advertListAndPagination
		const { total } = paginationEntity

		const isOperatingLeasePage = this._isOperatingLeasePage()

		const title = isOperatingLeasePage
			? SellerOperatingLeaseMeta.getTitleValue(dictionary, name, filterFormLineEntities)
			: dictionary.get('SellerView.metaTitle', { NAZEV: name })
		const description = isOperatingLeasePage
			? SellerOperatingLeaseMeta.getDescriptionValue(
					dictionary,
					name,
					filterFormLineEntities,
					total
			  )
			: dictionary.get('SellerView.metaDescription', {
					POCET: total,
					NAZEV: name,
					POPIS: premiseDescription
			  })
		const keywords = dictionary.get('Base.keywords')

		metaManager.setTitle(title)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('keywords', keywords)

		metaManager.setMetaName('twitter:title', title)
		metaManager.setMetaName('twitter:description', description)

		metaManager.setMetaProperty('og:title', title)
		metaManager.setMetaProperty('og:description', description)
	}

	/**
	 * Vrátí parametry pro stažení inzerátů prodejce a stránkování.
	 *
	 * @private
	 * @method _getAdvertsAndPaginationParams
	 * @return {Promise<Object>} Stránkování a inzeráty.
	 */
	_getAdvertsAndPaginationParams(premisePromise, categoriesPromise) {
		return Promise.all([premisePromise, categoriesPromise]).then(
			([premiseEntity, categoriesEntity]) => {
				const { id } = premiseEntity
				const newParams = Object.assign({}, this.getRouteParams())
				const {
					[SellerUrlConvertor.constants.URL_APP_PARAMS.CATEGORY]: category
				} = this.getRouteParams()

				//nahradime old ID novym ID, protoze resource /items/search nerozumi old id
				newParams[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID] = id

				let categoryId
				// najdeme kategoriu, ak nie je pouzijeme defaultnu => osobni
				if (category) {
					const selectedCategory = categoriesEntity.find((cat) => cat.seoName === category)
					categoryId = selectedCategory.id
				} else {
					const selectedCategory = categoriesEntity.find(
						(cat) => cat.seoName === CategoryPickerConstants.defaultValue
					)
					categoryId = selectedCategory.id
				}

				newParams[UrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID] = categoryId

				return SellerUrlConvertor.getApiParams(newParams, this.getRouteName())
			}
		)
	}

	/**
	 * Vrátí premiseEntitu podle id v url.
	 *
	 * @private
	 * @method _getPremiseEntity
	 * @return {Promise<PremiseEntity>}
	 */
	_getPremiseEntity() {
		const {
			[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_ID]: premiseId,
			[SellerUrlConvertor.constants.URL_APP_PARAMS.SELLER_OLD_ID]: premiseIdOld
		} = this.getRouteParams()

		if (!this._premisePromise) {
			this._premisePromise = this._premiseService.getPublicPremise({
				id: premiseId || premiseIdOld
			})
		}

		return this._premisePromise
	}

	/**
	 * Zkontroluje url adresu prodejce.
	 * Ve chvíli kdy nesedí seoName v url, tak přesměruje na správný tvar.
	 *
	 * @private
	 * @method _checkUrl
	 * @param {PremiseEntity} premiseEntity
	 */
	_checkUrl(premisePromise, premiseCategoriesPromise) {
		Promise.all([premisePromise, premiseCategoriesPromise]).then(
			([premiseEntity, nonEmptyCategories]) => {
				const routeParams = this.getRouteParams()

				const {
					SELLER_OLD_ID,
					SELLER_SEO_NAME,
					SELLER_ID,
					CATEGORY
				} = SellerUrlConvertor.constants.URL_APP_PARAMS

				const {
					[SELLER_OLD_ID]: paramOldId,
					[SELLER_SEO_NAME]: paramSeoName,
					[CATEGORY]: category
				} = routeParams

				const { seoName, oldId, id } = premiseEntity

				let isRedirect = false
				const newRouteParams = Object.assign({}, routeParams)

				if (paramSeoName !== seoName || paramOldId) {
					Object.assign(newRouteParams, {
						[SELLER_SEO_NAME]: seoName,
						[SELLER_ID]: oldId || id
					})

					delete newRouteParams[SELLER_OLD_ID]
					isRedirect = true
				}

				const actualCategorySeoName = category ?? CATEGORIES.PASSENGER_CARS.seoName
				const isSelectedCategoryEmpty = !nonEmptyCategories.find(
					({ seoName }) => seoName === actualCategorySeoName
				)

				if (isSelectedCategoryEmpty && nonEmptyCategories.length) {
					const { seoName } = nonEmptyCategories.sort((a, b) => b.advertsCount - a.advertsCount)[0]

					if (seoName === CATEGORIES.PASSENGER_CARS.seoName) {
						delete newRouteParams[CATEGORY]
					} else {
						Object.assign(newRouteParams, { [CATEGORY]: seoName })
					}

					isRedirect = true
				}

				// redirektnu jen v pripade, ze se zmeni parametry pro url
				if (isRedirect && !deepEqual(routeParams, newRouteParams)) {
					const correctUrl = this._utils.$Router.link(
						getRouteName(this._utils.$Router) || RouteNames.USERWEB.SELLER,
						newRouteParams
					)

					this._utils.$Router.redirect(correctUrl, {
						httpStatus: HTTP_STATUS_CODES.MOVED_PERMANENTLY
					})
				}
			}
		)
	}
}
