
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['OpeningHoursWidget'] = {
  openingHours: function(d) { return "Otevírací doba"; },
  open_action: function(d) { return "Přidat další otevírací dobu"; },
  open_detailed_action: function(d) { return "Odebrat"; },
  closed_action: function(d) { return "Přidat otevírací dobu"; },
  nonstop_action: function(d) { return "Změnit otevírací dobu"; },
  open_amStart: function(d) { return "od"; },
  open_amEnd: function(d) { return "do"; },
  open_detailed_amStart: function(d) { return "dopoledne od"; },
  open_detailed_amEnd: function(d) { return "dopoledne do"; },
  open_detailed_pmStart: function(d) { return "odpoledne od"; },
  open_detailed_pmEnd: function(d) { return "odpoledne do"; },
  and: function(d) { return "a"; },
  closed: function(d) { return "Zavřeno"; },
  nonstop: function(d) { return "Nonstop"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	