import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Surface } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import ModelsPlaceholder from '../../../placeholders/modelsPlaceholder/ModelsPlaceholder'
import { orderedListToAlphabetGroups } from '../../../helper/OrderListToAlphabetGroups'
import CatalogUrlConvertor from 'app/page/userweb/catalog/CatalogUrlConvertor'
import Context from 'ima/page/context'
import ROUTE_NAMES from 'app/base/RouteNames'
import { filterNoDataItem } from '../../../helper/filterNoDataItems'

import './ModelsList.less'

const CLASSNAME = 'c-catalog-models-list'

const ModelsList = (props) => {
	const { modelsList = DEFAULT_PROPS.ARRAY } = props

	const context = useContext(Context)
	const router = context.$Router

	const modelsGroups = orderedListToAlphabetGroups(modelsList)

	const dataLoaded = modelsList && modelsList.length > 0

	const getUrlToModelLines = (model) => {
		const { seoName, manufacturer = {} } = model
		const { seoName: manufacturerSeoName = '' } = manufacturer

		return router.link(ROUTE_NAMES.USERWEB.CATALOG_MODEL_LINES, {
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.BRAND_SEO_NAME]: manufacturerSeoName,
			[CatalogUrlConvertor.constants.URL_APP_PARAMS.MODEL_SEO_NAME]: seoName
		})
	}

	const renderModelsList = () => {
		return Object.keys(modelsGroups).map((value) => {
			const models = modelsGroups[value]
			const title = value
			return (
				<div key={`${title}__group`} className={`${CLASSNAME}__group`}>
					<div className={`${CLASSNAME}__models-group-title`}>{title}</div>
					<div className={`${CLASSNAME}__models-group`}>
						{models.filter(filterNoDataItem).map((model) => {
							const { name, id } = model

							const url = getUrlToModelLines(model)

							return (
								<a
									href={url}
									key={id}
									className={`${CLASSNAME}__item`}
									data-dot='item'
									data-dot-data={`{"value": "${name}"}`}
								>
									<div className={`${CLASSNAME}__item-name`}>{name}</div>
								</a>
							)
						})}
					</div>
				</div>
			)
		})
	}

	return (
		<Surface surface={3} className={CLASSNAME} data-dot='models'>
			{dataLoaded ? renderModelsList() : <ModelsPlaceholder />}
		</Surface>
	)
}

ModelsList.propTypes = {
	modelsList: PropTypes.array
}

export default React.memo(ModelsList)
