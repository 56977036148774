import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'

export default class FilterPopupWithOpener extends React.Component {
	static get propTypes() {
		return {
			popupDefinition: PropTypes.object,
			context: PropTypes.string,
			value: PropTypes.number, //TODO lepsi nazev?

			renderOpener: PropTypes.func,
			renderPopup: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			renderOpener: DEFAULT_PROPS.FUNCTION,
			renderPopup: DEFAULT_PROPS.FUNCTION
		}
	}

	componentDidMount() {
		this._domContainerForPopup = document.createElement('div')

		const bodyElement = document.querySelector('body')

		bodyElement.appendChild(this._domContainerForPopup)

		if (this._isPopupOpened()) {
			this.forceUpdate()
		}
	}

	componentWillUnmount() {
		const bodyElement = document.querySelector('body')

		bodyElement.removeChild(this._domContainerForPopup)
	}

	render() {
		const { renderOpener, renderPopup } = this.props

		const isPopupOpened = this._isPopupOpened()

		if (isPopupOpened) {
			const renderedPopup = renderPopup()

			return (
				<React.Fragment>
					{renderOpener()}

					{/* nechceme vnorovat popupy do sebe (kvuli problemum s CSS), takze je 
						pomoci portalu renderujeme za sebe do 'body' HTML elementu */}
					{this._domContainerForPopup &&
						renderedPopup &&
						ReactDOM.createPortal(renderedPopup, this._domContainerForPopup)}
				</React.Fragment>
			)
		} else {
			return renderOpener()
		}
	}

	_isPopupOpened() {
		const { popupDefinition, value, context } = this.props

		if (context === FILTER_CONTEXT.TAGS && value) {
			return popupDefinition && popupDefinition.openedByValue === value
		} else if (context) {
			return popupDefinition && popupDefinition.context === context
		} else {
			return !!popupDefinition
		}
	}
}
