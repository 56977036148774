import React from 'react'
import * as FormLines from '@inzeraty/form-lines'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useLocalize, useFire } from 'app/base/componentHelpers'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import OperatingLeaseForm from 'app/component/operatingLease/form/OperatingLeaseForm'
import DealTypeWidget from 'app/page/userweb/newAdvert/basicInfo/components/widgets/dealType/DealTypeWidget'
import VINWidget, { VIN_WIDGET_ID } from '../../widgets/vin/VINWidget'
import CategoryWidget, {
	CATEGORY_WIDGET_ID
} from '../../widgets/vehicleCategory/VehicleCategoryWidget'
import ConditionWidget, {
	CONDITION_WIDGET_ID
} from '../../widgets/vehicleCondition/VehicleConditionWidget'
import {
	SECTIONS_ID,
	splitFormLinesIntoSections
} from 'app/page/userweb/newAdvert/basicInfo/BasicInfoSections'
import { validateFormLines } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLines'
import { filterFormLineEntitiesToShowBasedOnSelection } from '../IAAdvertBasicInfoEditFormLines'
import STATE_KEYS from '../IAAdvertBasicInfoEditStateKeys'
import { FORM_IDS as ADD_VIN_FORM_LINES_IDS } from 'app/page/userweb/newAdvert/addVIN/components/AddVINForm'
import { FORM_LINES_IDS } from 'app/page/userweb/newAdvert/basicInfo/BasicInfoFormLineIds'
import { ERROR_CODES, getErrorMessages } from 'app/component/errorMessages/ErrorMessages'
import {
	SPECIAL_ERROR_TO_FORM_LINE_ID_MAPPING,
	getErrorDictionary
} from 'app/page/userweb/newAdvert/basicInfo/components/BasicInfoFormErrors'
import { getErrorDictionary as getErrorDictionaryAddVIN } from 'app/page/userweb/newAdvert/addVIN/components/AddVINFormErrors'
import FormStepper from 'app/page/userweb/newAdvert/component/stepper/FormStepperView'

import BrandSelectWidget, {
	BRAND_SELECT_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/brandSelect/BrandSelect'
import ModelSelectWidget, {
	MODEL_SELECT_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/modelSelect/ModelSelect'
import LocalitySelectWidget, {
	LOCALITY_SELECT_WIDGET_ID
} from 'app/page/userweb/newAdvert/basicInfo/components/widgets/localitySelect/LocalitySelect'
import NumberWidget, {
	NUMBER_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/number/NumberWidget'
import TextareaWidget, {
	TEXTAREA_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/textarea/TextareaWidget'
import TextWidget, {
	TEXT_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/text/TextWidget'
import PriceWidget, {
	PRICE_WIDGET_ID
} from 'app/page/userweb/newAdvert/basicInfo/components/widgets/price/Price'
import BasicSelectWidget, {
	BASIC_SELECT_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/basicSelect/BasicSelectWidget'
import MonthYearSelectWidget, {
	MONTH_AND_YEAR_SELECT_WIDGET_ID,
	MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID,
	IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/monthYearSelect/MonthYearSelect'
import ColorAndToneSelectWidget, {
	COLOR_AND_TONE_SELECT_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/colorAndToneSelect/ColorAndToneSelect'
import DoorsSelectWidget, {
	DOORS_AND_CAPACITY_SELECT_WIDGET_ID,
	DOORS_AND_SEATS_SELECT_WIDGET_ID
} from 'app/page/internalAdmin/advertEdit/widgets/doorsSelect/DoorsSelect'
import EmailWidget, {
	EMAIL_WIDGET_ID
} from 'app/page/userweb/newAdvert/basicInfo/components/widgets/email/EmailWidget'
import PhoneWidget, {
	PHONE_WIDGET_ID
} from 'app/page/userweb/newAdvert/basicInfo/components/widgets/phone/PhoneWidget'
import NewAdvertSectionHeading from 'app/component/newAdvertSectionHeading/NewAdvertSectionHeading'
import AdvertOwnerInfo from './AdvertOwnerInfo'

import './IAAdvertBasicInfoEditForm.less'

import 'app/page/userweb/newAdvert/basicInfo/components/BasicInfoFormCS.json'

const CLASSNAME = 'c-ia-basic-info-edit-form'
const ADD_VIN_SECTION_ID = 'ADD_VIN_SECTION_ID'

const { Form } = FormLines

const IAAdvertBasicInfoEditForm = (props) => {
	const localize = useLocalize()
	const fire = useFire()

	const {
		[STATE_KEYS.FORM_LINE_ENTITIES]: formLineEntities = DEFAULT_PROPS.ARRAY,
		[STATE_KEYS.ON_CHANGE]: onChange = DEFAULT_PROPS.FUNCTION,

		[STATE_KEYS.IS_FORM_BEING_SUBMITTED]: isFormBeingSubmitted = false,
		[STATE_KEYS.SUBMIT_FORM]: submitForm = DEFAULT_PROPS.FUNCTION,

		[STATE_KEYS.ADVERT_ENTITY]: advertEntity = DEFAULT_PROPS.OBJECT,

		[STATE_KEYS.LOAD_MODELS]: loadModels = DEFAULT_PROPS.FUNCTION,
		[STATE_KEYS.CLEAR_MODELS]: clearModels = DEFAULT_PROPS.FUNCTION,
		[STATE_KEYS.SUBMIT_EXPIRATION_DATE_CHANGE]: submitExpirationDateChange = DEFAULT_PROPS.FUNCTION,

		[STATE_KEYS.OPERATING_LEASE_DEFINITION_DATA]: operatingLeaseDefinitionData,
		[STATE_KEYS.OPERATING_LEASE_VARIANTS]: operatingLeaseVariants,
		[STATE_KEYS.FETCH_OPERATING_LEASES_VARIANTS]: fetchOperatingLeasesVariants,
		[STATE_KEYS.ADD_NEW_OPERATING_LEASE_VARIANT]: addNewOperatingLeaseVariant,
		[STATE_KEYS.EDIT_OPERATING_LEASE_VARIANT]: editOperatingLeaseVariant,
		[STATE_KEYS.DELETE_OPERATING_LEASE_VARIANT]: deleteOperatingLeaseVariant
	} = props

	const { category: { id: categoryId } = {} } = advertEntity
	const isAdvertEnteredByPremise = !!advertEntity.premise

	const ERROR_DICTIONARY = Object.assign(
		{},
		getErrorDictionary(localize),
		getErrorDictionaryAddVIN(localize)
	)

	const AddVINWidgets = Object.freeze({
		[VIN_WIDGET_ID]: VINWidget,
		[CATEGORY_WIDGET_ID]: CategoryWidget,
		[CONDITION_WIDGET_ID]: ConditionWidget
	})

	const AddVINWidgetIds = Object.keys(AddVINWidgets)

	const formLineEntitiesBySections = Object.assign(
		{},
		{
			ADD_VIN_SECTION_ID: formLineEntities.filter((formLineEntity) =>
				AddVINWidgetIds.includes(formLineEntity.widget)
			)
		},
		splitFormLinesIntoSections(categoryId, formLineEntities)
	)

	const renderWidgets = (sectionId) => {
		const renderMonthYearSelectWidget = (formLineEntity) => (
			<MonthYearSelectWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
		)

		const renderDoorsSelectWidget = (formLineEntity) => (
			<DoorsSelectWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
		)

		const widgets = {
			[CATEGORY_WIDGET_ID]: (formLineEntity) => (
				<CategoryWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[CONDITION_WIDGET_ID]: (formLineEntity) => (
				<ConditionWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[VIN_WIDGET_ID]: (formLineEntity) => (
				<VINWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[BRAND_SELECT_WIDGET_ID]: (formLineEntity) => (
				<BrandSelectWidget
					formLineEntity={formLineEntity}
					onChange={handleOnChange}
					loadModels={loadModels}
					onBrandDeleted={clearModels}
				/>
			),
			[MODEL_SELECT_WIDGET_ID]: (formLineEntity) => (
				<ModelSelectWidget
					formLineEntity={formLineEntity}
					formLineEntityForBrand={formLineEntities.find(
						(f) => f.id === FORM_LINES_IDS.MANUFACTURER_CB
					)}
					onChange={handleOnChange}
				/>
			),
			[LOCALITY_SELECT_WIDGET_ID]: (formLineEntity) => (
				<LocalitySelectWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[NUMBER_WIDGET_ID]: (formLineEntity) => (
				<NumberWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[TEXTAREA_WIDGET_ID]: (formLineEntity) => (
				<TextareaWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[TEXT_WIDGET_ID]: (formLineEntity) => (
				<TextWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[PRICE_WIDGET_ID]: (formLineEntity) => (
				<PriceWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[BASIC_SELECT_WIDGET_ID]: (formLineEntity) => (
				<BasicSelectWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[MANUFACTURING_MONTH_AND_YEAR_SELECT_WIDGET_ID]: renderMonthYearSelectWidget,
			[IN_OPERATION_MONTH_AND_YEAR_SELECT_WIDGET_ID]: renderMonthYearSelectWidget,
			[MONTH_AND_YEAR_SELECT_WIDGET_ID]: renderMonthYearSelectWidget,
			[COLOR_AND_TONE_SELECT_WIDGET_ID]: (formLineEntity) => (
				<ColorAndToneSelectWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[DOORS_AND_CAPACITY_SELECT_WIDGET_ID]: renderDoorsSelectWidget,
			[DOORS_AND_SEATS_SELECT_WIDGET_ID]: renderDoorsSelectWidget,
			[EMAIL_WIDGET_ID]: (formLineEntity) => (
				<EmailWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			),
			[PHONE_WIDGET_ID]: (formLineEntity) => (
				<PhoneWidget formLineEntity={formLineEntity} onChange={handleOnChange} />
			)
		}

		return filterFormLineEntitiesToShowBasedOnSelection(
			formLineEntitiesBySections[sectionId],
			isAdvertEnteredByPremise,
			formLineEntities
		).map((formLineEntity) => {
			const { widget, id } = formLineEntity
			const widgetComponent = widgets[widget]

			if (widgetComponent) {
				return <div key={id}>{widgetComponent(formLineEntity)}</div>
			} else {
				/* eslint-disable */
				console.warn(`Widget for widget id "${widget}" is unknown.`)
				/* eslint-enable */
				return undefined
			}
		})
	}

	const renderSubmitButton = () => {
		return <FormStepper loading={isFormBeingSubmitted} isAdmin={true} />
	}

	const renderFormContent = (renderWidgets, renderSubmitButton) => {
		const sections = [
			{
				id: ADD_VIN_SECTION_ID,
				label: 'Typ inzerátu'
			},
			{
				id: SECTIONS_ID.TYPE_AND_MODEL_SECTION,
				label: 'Typ a model vozu'
			},
			{
				id: SECTIONS_ID.ENGINE_SECTION,
				label: 'Motorizace'
			},
			{
				id: SECTIONS_ID.VEHICLE_CONDITION_SECTION,
				label: 'Stav vozu'
			},
			{
				id: SECTIONS_ID.PRICE_SECTION,
				label: 'Cena'
			},
			{
				id: SECTIONS_ID.CONTACT_SECTION,
				label: 'Kontaktní údaje'
			}
		]

		return (
			<>
				<div className={`${CLASSNAME}__form`}>
					{sections.map(({ id, label }) => {
						const widgets = renderWidgets(id)

						return (
							widgets &&
							widgets.length > 0 && (
								<div key={id} className={`${CLASSNAME}__section`}>
									{!!label && <NewAdvertSectionHeading>{label}</NewAdvertSectionHeading>}
									{widgets}
								</div>
							)
						)
					})}
				</div>

				{renderSubmitButton()}
			</>
		)
	}

	const handleOnChange = (changes) => {
		if (changes instanceof Array) {
			onChange(changes)
		} else {
			onChange([changes])
		}
	}

	const onSubmit = () => {
		const filteredFormLineEntities = filterFormLineEntitiesToShowBasedOnSelection(
			formLineEntities,
			isAdvertEnteredByPremise
		)
		const displayedErrors = filteredFormLineEntities.filter((f) => !!f.errorMessage)
		const [, invalidFormLines] = validateFormLines(filteredFormLineEntities, operatingLeaseVariants)

		const canBeFormSubmittedToServer = !displayedErrors.length && !invalidFormLines.length

		if (canBeFormSubmittedToServer) {
			// odesleme zadana data ve formulari na server
			submitForm({
				formLineEntities,
				onErrorCallback: onServerSideFormError
			})
		} else {
			// nastavime chybovou hlasku pro nevyplnene povinne prvky ve formulari
			onClientSideFormError(invalidFormLines)
		}
	}

	const onClientSideFormError = (invalidFormLines) => {
		clearAllPreviousErrors()

		onChange(getErrorMessages(invalidFormLines, ERROR_DICTIONARY))

		fire('saveError')
	}

	const onServerSideFormError = (errors) => {
		clearAllPreviousErrors()

		const formLinesErrors = errors.reduce((processedErrors, newError) => {
			const { error_code: errorCode, fields } = newError

			const moreProcessedErrors = fields
				.map((field) => {
					const id = SPECIAL_ERROR_TO_FORM_LINE_ID_MAPPING[field] || field

					// pokud se chyba vztahuje i na policko VIN, tak uz nechceme
					// zobrazovat chybovou hlasku i u kategorie a stavu vozidla
					const skip =
						fields.includes(ADD_VIN_FORM_LINES_IDS.VEHICLE_VIN) &&
						id !== ADD_VIN_FORM_LINES_IDS.VEHICLE_VIN

					if (ERROR_DICTIONARY[id] && !skip) {
						return {
							id,
							errorMessage:
								ERROR_DICTIONARY[id][errorCode] || ERROR_DICTIONARY[id][ERROR_CODES.UNKNOWN]
						}
					} else {
						return undefined
					}
				})
				.filter((err) => !!err)

			return processedErrors.concat(moreProcessedErrors)
		}, [])

		if (formLinesErrors.length) {
			onChange(formLinesErrors)
			fire('saveError')
		}
	}

	const clearAllPreviousErrors = () => {
		onChange(
			formLineEntities.map(({ id }) => ({
				id,
				errorMessage: undefined
			}))
		)
	}

	const handleOperatingLeaseIntendedForChange = (selectedIntendedForValues = []) => {
		const operLeaseIntendedForFormLineEntity = formLineEntities.find(
			(formLine) => formLine.id === FORM_LINES_IDS.OPERATING_LEASE_INTENDED_FOR_CB
		)

		if (operLeaseIntendedForFormLineEntity) {
			const { options = [] } = operLeaseIntendedForFormLineEntity

			handleOnChange({
				id: FORM_LINES_IDS.OPERATING_LEASE_INTENDED_FOR_CB,
				value: options.filter((option) => selectedIntendedForValues.includes(option.value))
			})
		}
	}

	const formLineEntitiesWithErrors = filterFormLineEntitiesToShowBasedOnSelection(
		formLineEntities,
		isAdvertEnteredByPremise
	).filter((f) => !!f.errorMessage)

	const dealTypeFormLineEntity = formLineEntities.find((f) => f.id === FORM_LINES_IDS.DEAL_TYPE)
	const isOperatingLeaseSectionVisible = dealTypeFormLineEntity
		? dealTypeFormLineEntity.value === DEAL_TYPE.OPERATING_LEASE ||
		  dealTypeFormLineEntity.value === DEAL_TYPE.SALE_OR_LEASE
		: false

	const operLeaseIntendedForFormLineEntity = formLineEntities.find(
		(formLine) => formLine.id === FORM_LINES_IDS.OPERATING_LEASE_INTENDED_FOR_CB
	)

	return (
		<>
			<AdvertOwnerInfo
				advertEntity={advertEntity}
				submitExpirationDateChange={submitExpirationDateChange}
			/>

			{isAdvertEnteredByPremise && (
				<DealTypeWidget
					className={`${CLASSNAME}__deal-type`}
					formLineEntity={dealTypeFormLineEntity}
					isFeatureFlagShown={false}
					operatingLeaseVariants={operatingLeaseVariants}
					onChange={handleOnChange}
				/>
			)}

			{isAdvertEnteredByPremise && isOperatingLeaseSectionVisible && (
				<OperatingLeaseForm
					className={`${CLASSNAME}__oper-lease`}
					operatingLeaseDefinitionData={operatingLeaseDefinitionData}
					operatingLeaseVariants={operatingLeaseVariants}
					operatingLeaseIntendedFor={operLeaseIntendedForFormLineEntity?.value?.map(
						({ value }) => value
					)}
					onOperatingLeaseIntendedForChange={handleOperatingLeaseIntendedForChange}
					fetchOperatingLeasesVariants={fetchOperatingLeasesVariants}
					addNewOperatingLeaseVariant={addNewOperatingLeaseVariant}
					editOperatingLeaseVariant={editOperatingLeaseVariant}
					deleteOperatingLeaseVariant={deleteOperatingLeaseVariant}
				/>
			)}

			<Form
				className={CLASSNAME}
				formLineEntities={formLineEntitiesWithErrors}
				isSending={isFormBeingSubmitted}
				renderWidgets={renderWidgets}
				renderFormContent={renderFormContent}
				onSubmit={onSubmit}
				renderSubmitButton={renderSubmitButton}
			/>
		</>
	)
}

export default React.memo(IAAdvertBasicInfoEditForm)
