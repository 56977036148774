import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '@sznds/react'
import { useLocalize, useFire } from 'app/base/componentHelpers'

import './CebiaDataLoadBtnCS.json'

const CLASSNAME = 'c-cebia-load-data-btn'

const CebiaDataLoadBtn = ({ className = '', vin = '' }) => {
	const localize = useLocalize()
	const fire = useFire()

	const [isLoading, setIsLoading] = useState(false)

	const onClick = () => {
		setIsLoading(true)

		fire('getCebiaData', {
			vin,
			callback: () => {
				setIsLoading(false)
			}
		})
	}

	return (
		<Button
			className={classnames({
				[className]: !!className,
				[CLASSNAME]: true
			})}
			onClick={onClick}
			primary={true}
			text={localize('CebiaDataLoadBtn.loadCebiaData')}
			disabled={!vin}
			loading={isLoading}
		/>
	)
}

CebiaDataLoadBtn.propTypes = {
	className: PropTypes.string,
	vin: PropTypes.string
}

export default React.memo(CebiaDataLoadBtn)
