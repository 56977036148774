export default class PremiseEntityImportLog {
	/**
	 * @constructor
	 * @param {Object} data
	 * @param {Object} data.counts
	 * @param {Number} 	data.counts.all
	 * @param {Number} 	data.counts.successful
	 * @param {Number} 	data.counts.addEditCarAll
	 * @param {Number} 	data.counts.addEditCarSuccessful
	 * @param {Object} data.premise
	 * @param {Number} 	data.premise.id
	 * @param {String} 	data.premise.name
	 * @param {String} data.sessionId
	 * @param {String} data.softwareKey
	 * @param {String} data.timestamp
	 */
	constructor(data) {
		this.counts = data.counts
		this.premise = data.premise
		this.sessionId = data.sessionId
		this.softwareKey = data.softwareKey
		this.timestamp = data.timestamp
	}
}
