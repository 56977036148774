import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Surface } from '@sznds/react'
import Context from 'ima/page/context'
import { useFire } from 'app/base/componentHelpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import IMAGES from 'app/base/ImagesConstants'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { StatusMessage } from '@inzeraty/components'
import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import select from 'ima-plugin-select'
import InternalAdminMenu from 'app/component/internalAdminMenu/InternalAdminMenu'
import { useToasts, useNewToastEventListener } from 'app/component/toastMessages/ToastsHooks'
import ToastsContainer from 'app/component/toastMessages/container/ToastsContainer'
import Toast from 'app/component/toastMessages/toast/Toast'
import NavButton, { BUTTON_TYPE } from 'app/component/navButton/NavButtonView'
import PageLoader from 'app/component/pageLoader/PageLoader'

import './LayoutInternalAdmin.less'

const CLASSNAME = 'c-layout-iadmin'

export const CLASSNAME_DIRECT_LINK = `${CLASSNAME}__direct-link`

export const InternalAdminHeader = () => {
	const { $Router, $Dispatcher } = useContext(Context)
	const adminEntity = useContext(AdminEntityContext)

	const { name = '' } = adminEntity || {}

	return (
		<Surface className={`${CLASSNAME}__header`}>
			<a
				className={`${CLASSNAME}__logo`}
				href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST)}
			>
				<img
					className={`${CLASSNAME}__logo-img`}
					src={IMAGES.image__logo_interni_admin}
					alt='sauto.cz interní admin'
				/>
			</a>

			<div className={`${CLASSNAME}__login-badge`}>
				<span className={`${CLASSNAME}__login-badge__name`}>{name}</span>
				<button onClick={() => $Dispatcher.fire('admin-logout')}>Odhlásit se</button>
			</div>
		</Surface>
	)
}

const LayoutInternalAdmin = ({
	children,
	className,
	title,
	statusMessage,
	goBackListUrl,
	directLinkData
}) => {
	const fire = useFire()
	const { toasts, addToast, removeToast } = useToasts()

	useNewToastEventListener(addToast)

	const renderFooter = () => {
		return (
			<Surface className={`${CLASSNAME}__footer`}>
				<div>
					Copyright © 1996–{new Date().getFullYear()} Seznam.cz, a.s. Všechna práva vyhrazena. All
					rights reserved.
				</div>
				<div>
					<a href='https://www.sauto.cz'>Sauto</a> <a href='https://www.seznam.cz'>Seznam</a>
				</div>
			</Surface>
		)
	}

	const renderContent = () => {
		const {
			text: directLinkText,
			url: directLinkUrl,
			className: directLinkClassName
		} = directLinkData

		return (
			<div className={`${CLASSNAME}__relative-content`}>
				{!!goBackListUrl && (
					<NavButton text='Zpět na výpis' url={goBackListUrl} type={BUTTON_TYPE.BACK} />
				)}
				<div className={`${CLASSNAME}__title-wrap`}>
					{!!title && <h1 className={`${CLASSNAME}__title`}>{title}</h1>}
					{!!directLinkText && !!directLinkUrl && (
						<a
							href={directLinkUrl}
							className={classnames({
								[CLASSNAME_DIRECT_LINK]: true,
								[directLinkClassName]: !!directLinkClassName
							})}
						>
							{directLinkText}
						</a>
					)}
				</div>
				{children}
			</div>
		)
	}

	const _renderStatusMessage = () => {
		if (statusMessage) {
			const { type, title, text } = statusMessage

			return (
				<StatusMessage
					className={`${CLASSNAME}__status-message`}
					type={type}
					title={title}
					onClose={() => {
						fire('errorClose')
					}}
				>
					{text}
				</StatusMessage>
			)
		} else return null
	}

	return (
		<div className={`${className} ${CLASSNAME}`}>
			<PageLoader />
			<InternalAdminHeader />
			<InternalAdminMenu />
			<ToastsContainer>
				{toasts.map((toast) => (
					<Toast {...toast.data} key={toast.id} removeToast={() => removeToast(toast.id)} />
				))}
			</ToastsContainer>

			<div className={`${CLASSNAME}__content`}>
				{_renderStatusMessage()}
				{renderContent()}
			</div>

			{renderFooter()}
		</div>
	)
}

LayoutInternalAdmin.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string,
	statusMessage: PropTypes.object,
	goBackListUrl: PropTypes.string,
	directLinkData: PropTypes.object
}

LayoutInternalAdmin.defaultProps = {
	className: '',
	goBackListUrl: '',
	directLinkData: {}
}

const selectors = (state) => ({
	statusMessage: state[AbstractInternalAdminController.stateId.STATUS_MESSAGE],
	goBackListUrl: state[AbstractInternalAdminController.stateId.GO_BACK_LIST_URL],
	directLinkData: state[AbstractInternalAdminController.stateId.DIRECT_LINK_DATA]
})

export default React.memo(select(selectors)(LayoutInternalAdmin))
