import 'app/base/BaseCS.json'

import UserwebBaseController from 'app/base/UserwebBaseController'
import JsonLd from 'app/helpers/jsonLd/JsonLd'
import HomeUrlConvertor from 'app/page/userweb/home/HomeUrlConvertor'
import GenericError from 'ima/error/GenericError'
import HTTP_STATUS_CODES from 'app/base/HttpStatusCode'
import FiltersExtension from 'app/component/filters/FiltersExtension'
import HomeMeta from './HomeMeta'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import STATE_KEYS from 'app/page/userweb/home/HomeStateKeys'
import FilterConstants from 'app/model/filter/FilterConstants'
import { UserService } from '@inzeraty/models'

/**
 * @class HomeController
 * @namespace app.page.userweb.home
 * @extends app.base.UserwebBaseController
 * @module app
 * @submodule app.page
 */
export default class HomeController extends UserwebBaseController {
	/**
	 * @method constructor
	 * @constructor
	 * @param {ns.app.helpers.dependenciesHelper.DependenciesHelper}	dependenciesHelper
	 * @param {@inzeraty.models.CategoryService} categoryService
	 * @param {FiltersExtension} filtersExtension
	 * @param {AdvertService} advertService
	 */
	constructor(
		dependenciesHelper,
		categoryService,
		filtersExtension,
		advertService,
		advertsCountService,
		articleService,
		mySavedSearchHelper
	) {
		super(dependenciesHelper)

		this._categoryService = categoryService

		this._filtersExtension = filtersExtension
		this._advertService = advertService
		this._advertsCountService = advertsCountService
		this._articleService = articleService
		this._mySavedSearchHelper = mySavedSearchHelper

		this._categoriesPromise = undefined
		this._ribbonSearchExtension = this._dependenciesHelper.getDependency('ribbonSearchExtension')
	}

	init() {
		super.init()

		this.addExtension(this._filtersExtension)
	}

	/**
	 * Load data.
	 *
	 * @method load
	 * @return {Object}
	 */
	load() {
		const categories = this._getCategoriesAndAdvertsCount()
		const category = categories.then((categoryEntities) =>
			this._getSelectedCategory(categoryEntities)
		)

		this._cacheHelper.resetCache()

		const mySavedSearchSummary = this._userService
			.getUserSelf(false)
			.then((userEntity) => this._getMySavedSearchesSummary(userEntity))
			.catch(() => undefined)

		return super.load({
			[STATE_KEYS.ARTICLE_ENTITIES]: this._getArticles(),
			[STATE_KEYS.CATEGORIES]: categories,
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: false,
			[FiltersExtension.stateIds.CATEGORY]: category,
			[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: category.then((categoryEntity) =>
				this._getFilterFormLineEntities(categoryEntity)
			),
			seoBrandsWithAdvertsValues: category.then((categoryEntity) =>
				this._getSeoCountsForSelectedFilter(categoryEntity, FilterConstants.formLineIds.BRAND_MODEL)
			),
			seoVehicleBodyWithAdvertsValues: category.then((categoryEntity) =>
				this._getSeoCountsForSelectedFilter(
					categoryEntity,
					FilterConstants.formLineIds.VEHICLE_BODY
				)
			),
			seoFuelWithAdvertsValues: category.then((categoryEntity) =>
				this._getSeoCountsForSelectedFilter(categoryEntity, FilterConstants.formLineIds.FUEL)
			),
			[STATE_KEYS.MY_SAVED_SEARCHES_SUMMARY]: mySavedSearchSummary,
			[STATE_KEYS.IS_LOADING_MY_SAVED_SEARCHES_SUMMARY]: true
		})
	}

	activate() {
		super.activate()

		this.setState({
			[STATE_KEYS.IS_LOADING_MY_SAVED_SEARCHES_SUMMARY]: false
		})
	}

	update(prevParams) {
		const {
			[STATE_KEYS.CATEGORIES]: categories = [],
			[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease = false
		} = this.getState()

		if (categories.length) {
			const category = this._getSelectedCategory(categories)

			// pri zmene kategorie zavrieme vypis vsetkych znaciek
			this._utils.$Dispatcher.fire('showAllBrandsReset')

			const { userSelf } = this.getState()
			const mySavedSearchSummary = this._getMySavedSearchesSummary(userSelf).catch(() => undefined)

			return super.update(prevParams, {
				[FiltersExtension.stateIds.IS_OPERATING_LEASE]: isOperatingLease,
				[FiltersExtension.stateIds.CATEGORY]: category,
				[FiltersExtension.stateIds.FORM_LINE_ENTITIES]: this._getFilterFormLineEntities(
					category,
					isOperatingLease
				),
				[STATE_KEYS.MY_SAVED_SEARCHES_SUMMARY]: mySavedSearchSummary
			})
		}
	}

	async _getMySavedSearchesSummary(userEntity) {
		try {
			this.setState({
				[STATE_KEYS.IS_LOADING_MY_SAVED_SEARCHES_SUMMARY]: true
			})

			if (userEntity && UserService.isUserLogged(userEntity)) {
				return await this._mySavedSearchHelper.getMySavedSearchesSummary()
			}
		} catch (e) {
			// na chybu nebudeme nijak reagovat
		} finally {
			this.setState({
				[STATE_KEYS.IS_LOADING_MY_SAVED_SEARCHES_SUMMARY]: false
			})
		}
	}

	async getDataForSsp() {
		const categories = await this._getCategoriesAndAdvertsCount()
		const category = this._getSelectedCategory(categories)

		const routeName = this.getRouteName()

		return {
			routeName,
			pageState: {
				category
			}
		}
	}

	_getArticles() {
		return this._articleService
			.getArticles({
				limit: 4,
				sort: '-create_date'
			})
			.catch(() => [])
	}

	/**
	 * Vrátí object s poctami pre jednotlive idečka.
	 * @private
	 * @param {inzeraty/models/CategoryEntity} categoryEntity kategorie.
	 * @param {String} filterName nazov filtru
	 * @return {Promise|Undefined} Promise jejíž hodnotou je object s poctami pre jednotlive idečka.
	 */
	_getSeoCountsForSelectedFilter(categoryEntity, filterName) {
		const API_PARAMS = AdvertListUrlConvertor.constants.URL_API_PARAMS

		const apiParams = Object.assign(
			{},
			AdvertListUrlConvertor.getApiParams(this.getRouteParams(), this.getRouteName()),
			{
				[API_PARAMS.CATEGORY_ID]: categoryEntity.id,
				[API_PARAMS.FILTER_NAME]: filterName
			}
		)

		return this._advertsCountService
			.getFilteredAdvertsCount(apiParams)
			.then(({ counts = {} }) => {
				return {
					counts,
					apiParams
				}
			})
			.catch(() => undefined)
	}

	/**
	 * Vrátí vybranou kategorii (na základě url parametrů).
	 *
	 * @method _getSelectedCategory
	 * @private
	 * @param {Array<inzeraty/models/CategoryEntity>} categoryEntities Hlavní kategorie.
	 * @return {inzeraty/models/CategoryEntity} Vybraná kategorie podle aktuálních parametrů url.
	 */
	_getSelectedCategory(categoryEntities = []) {
		const { parsedCategorySeoName } = HomeUrlConvertor.parseCategorySeoNameFromAppParams(
			this.getRouteParams()
		)

		const selectedCategoryEntity = categoryEntities.find(
			(category) => category.seoName === parsedCategorySeoName
		)

		// Zkontroluje jestli je v url správná kategorie.
		return this._validateUrl(selectedCategoryEntity)
	}

	/**
	 * Zkontroluje jestli je kategorie v url zadaná správně. Pokud není, vyhodí 404.
	 *
	 * @method _validateUrl
	 * @private
	 * @param {inzeraty/models/CategoryEntity} categoryEntity Hlavní kategorie.
	 */
	_validateUrl(categoryEntity) {
		const {
			origCategorySeoName,
			redirectToHome
		} = HomeUrlConvertor.parseCategorySeoNameFromAppParams(this.getRouteParams())

		if (redirectToHome) {
			this._redirectToHP({
				httpStatus: HTTP_STATUS_CODES.MOVED_PERMANENTLY
			})
		} else {
			if (categoryEntity) {
				return categoryEntity
			} else {
				throw new GenericError(`Kategorie '${origCategorySeoName}' neexistuje.`, {
					status: HTTP_STATUS_CODES.NOT_FOUND
				})
			}
		}
	}

	/**
	 * Vráti formLineEntities pro filtr.
	 *
	 * @method _getFilterFormLineEntities
	 * @private
	 * @param {inzeraty/models/CategoryEntity} categoryEntity Entita vybrané kategorie.
	 * @param {Boolean} isOperatingLease Priznak, zda-li se maji stahovat filtry pro operaky.
	 * @return {Promise<Array<inzeraty/form-lines/Entity>>}
	 */
	_getFilterFormLineEntities(categoryEntity, isOperatingLease = false) {
		const { id } = categoryEntity

		return this._filtersExtension.getFormLineEntities({
			[HomeUrlConvertor.constants.URL_API_PARAMS.CATEGORY_ID]: id,
			[HomeUrlConvertor.constants.URL_API_PARAMS.OPERATING_LEASE]: isOperatingLease
		})
	}

	async onDealTypeChanged(isOperatingLeaseSelected) {
		const { [FiltersExtension.stateIds.CATEGORY]: category } = this.getState()

		// zavolame api pro definice odpovidajicich filtru
		const newFormLineEntities = await this._getFilterFormLineEntities(
			category,
			isOperatingLeaseSelected
		)

		// nastavime nove formliny
		this._filtersExtension.setFormLineEntitiesOnDealTypeChange(
			newFormLineEntities,
			isOperatingLeaseSelected
		)
	}

	_getCategoriesAndAdvertsCount() {
		if (!this._categoriesPromise) {
			this._categoriesPromise = this._categoryService.getCategoriesIncludingAdvertsCount()
		}

		return this._categoriesPromise
	}

	/**
	 * Set seo params.
	 *
	 * @method setSeoParams
	 * @param {Object} loadedResources
	 * @param {Core.Interface.MetaManager} metaManager
	 * @param {Core.Interface.Router} router
	 * @param {Core.Interface.Dictionary} dictionary
	 * @param {Object} settings
	 */
	setMetaParams(loadedResources, metaManager, router, dictionary, settings) {
		super.setMetaParams(loadedResources, metaManager, router, dictionary, settings)

		const selectedCategory = loadedResources.category ? loadedResources.category.seoName : ''

		const title = selectedCategory ? HomeMeta.getTitleValue(dictionary, selectedCategory) : ''
		const description = selectedCategory
			? HomeMeta.getDescriptionValue(dictionary, selectedCategory)
			: ''

		const keywords = dictionary.get('Base.keywords')

		metaManager.setTitle(title)

		metaManager.setMetaName('description', description)
		metaManager.setMetaName('keywords', keywords)

		metaManager.setMetaName('twitter:title', title)
		metaManager.setMetaName('twitter:description', description)

		metaManager.setMetaProperty('og:title', title)
		metaManager.setMetaProperty('og:description', description)

		// generovani json-LD
		const jsonLd = JsonLd.getHomepage(settings.images.logo)

		metaManager.setMetaProperty('JSON-LD', jsonLd)
	}
}
