
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['SortingTooltip'] = {
  title: function(d) { return "Informace k řazení"; },
  blindClosePrefix: function(d) { return "Konec informací k řazení"; },
  ariaOpener: function(d) { return "Informace k řazení"; },
  description: function(d) { return "Při určení pořadí hrála roli platba"; },
  moreInfo: function(d) { return "Více informací"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	