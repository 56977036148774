/**
 * @class ReviewReplyEntity
 * @description Obsahuje data, pro odpoved na zkusenost majitele.
 * @module models
 */
export default class ReviewReplyEntity {
	/**
	 * @constructor
	 * @param {Object} data
	 * @param {Date}   data.createDate               Datum vzniku odpovedi na zkusenost
	 * @param {Date}   data.editDate                 Datum posledni editace odpovedi na zkusenost
	 * @param {Number} data.id                       Id odpovedi na zkusenost
	 * @param {Number} data.negativeFeedbackCount    Pocet oznaceni odpovedi na zkusenost jako neuzitecne
	 * @param {Number} data.positiveFeedbackCount    Pocet oznaceni odpovedi na zkusenost jako uzitecne
	 * @param {String} data.reviewReply              Text odpovedi na zkusenost
	 * @param {String} data.userEmailPrefix          Cast emailu pred zavinacem (autora odpovedi na zkusenost)
	 * @param {String} data.userFeedbackType         Typ hodnoceni odpovedi na zkusenost 'positive/negative/none'
	 * @param {Number} data.userId                   Id uzivatele (autora odpovedi na zkusenost)
	 * @param {String} data.userName                 Jmeno uzivatele (autora odpovedi na zkusenost)
	 */
	constructor(data) {
		this.createDate = data.createDate
		this.editDate = data.editDate
		this.id = data.id
		this.negativeFeedbackCount = Number(data.negativeFeedbackCount || 0)
		this.positiveFeedbackCount = Number(data.positiveFeedbackCount || 0)
		this.reviewReply = data.reviewReply
		this.userEmailPrefix = data.userEmailPrefix
		this.userFeedbackType = data.userFeedbackType
		this.userId = data.userId
		this.userName = data.userName
		this.reviewId = data.reviewId
		this.reviewTitle = data.reviewTitle
	}
}
