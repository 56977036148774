import { FavoriteService as BaseFavoriteService } from '@inzeraty/models'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'

export default class FavoriteService extends BaseFavoriteService {
	constructor(favoriteResource, advertFactory, paginationFactory) {
		super(favoriteResource)

		this._advertFactory = advertFactory
		this._paginationFactory = paginationFactory

		this._favoriteIdsCache = null
	}

	async getFavoritesIds() {
		if (!this._favoriteIdsCache) {
			try {
				const {
					body: { results }
				} = await super.getFavoritesIds()
				this._favoriteIdsCache = new Set(results.map(({ advert_id }) => advert_id))
			} catch (e) {
				return new Set()
			}
		}

		return this._favoriteIdsCache
	}

	async getFavorites(apiParams = {}, options = {}) {
		const {
			body: { pagination, results }
		} = await super.getFavorites(apiParams, options)

		return {
			advertList: results.map((data) => this._advertFactory.transformData(data)),
			pagination: this._paginationFactory.createEntity(
				this._paginationFactory.transformData(pagination)
			)
		}
	}

	async getNextAdvert(advertId, pageParams) {
		const {
			DEFAULTS: { PER_PAGE },
			URL_API_PARAMS: { OFFSET: offsetKey }
		} = UrlConvertor.constants
		const { advertList } = await this.getFavorites(pageParams)
		const nextAdvertListPosition =
			advertList.findIndex(
				(advertEntity) => advertEntity.id === advertId || advertEntity.oldId === advertId
			) + 1

		if (nextAdvertListPosition < PER_PAGE) {
			return advertList[nextAdvertListPosition] || {}
		} else {
			const params = Object.assign({}, pageParams, {
				[offsetKey]: pageParams[offsetKey] + PER_PAGE
			})
			const { advertList } = await this.getFavorites(params)

			return advertList.length ? advertList[0] : {}
		}
	}

	async getPrevAdvert(advertId, pageParams) {
		const {
			DEFAULTS: { PER_PAGE },
			URL_API_PARAMS: { OFFSET: offsetKey }
		} = UrlConvertor.constants
		const { advertList } = await this.getFavorites(pageParams)
		const prevAdvertListPosition =
			advertList.findIndex(
				(advertEntity) => advertEntity.id === advertId || advertEntity.oldId === advertId
			) - 1

		if (prevAdvertListPosition >= 0) {
			return advertList[prevAdvertListPosition] || {}
		} else if (pageParams[offsetKey] === 0) {
			return {}
		} else {
			const params = Object.assign({}, pageParams, {
				[offsetKey]: pageParams[offsetKey] - PER_PAGE
			})
			const { advertList } = await this.getFavorites(params)
			return advertList[PER_PAGE - 1]
		}
	}

	addToCache(id) {
		this._favoriteIdsCache.add(id)
	}

	removeFromCache(id) {
		this._favoriteIdsCache.delete(id)
	}

	clearCache() {
		this._favoriteIdsCache = null
	}
}
