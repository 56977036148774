import React, { useContext } from 'react'
import { TRASH_FILLED } from '@sznds/icons'
import { Format } from '@inzeraty/helpers'
import { useFire } from 'app/base/componentHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import ActionButton from 'app/component/actionButton/ActionButton'
import TableHead from 'app/component/tableHead/TableHead'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import IAReviewReplyListController from './IAReviewReplyListController'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import ReviewReplyEntity from 'app/model/review/ReviewReplyEntity'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'

const { ACTIONS } = IAReviewReplyListController

export default function IAReviewReplyListView({
	entitiesAndPagination,
	filterFormLineEntities,
	isFilterLoading,
	isLoading
}) {
	const { rights = [] } = useContext(AdminEntityContext) || {}
	const fire = useFire()

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		const actions = []

		if (rights.includes(ADMIN_RIGHTS.REVIEWS_DELETE)) {
			actions.push((entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='delete'
					title='Smazat'
					onClick={() => action(ACTIONS.DELETE, [entity])}
					{...actionButtonProps}
				/>
			))
		}

		return actions
	}

	return (
		<Layout title='Výpis odpovědí'>
			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHead key='id'>ID recenze</TableHead>,
					<TableHead key='replyId'>ID odpovědi</TableHead>,
					<TableHead key='title'>Titulek</TableHead>,
					<TableHead key='userId'>ID uživatele</TableHead>,
					<TableHead key='reply'>Odpověď</TableHead>,
					<TableHeadSorter key='create_date' sortParam='create_date' isDefaultDescending={true}>
						Vytvořeno
					</TableHeadSorter>
				]}
				rowCells={[
					({ reviewId }) => reviewId,
					({ id }) => id,
					({ reviewTitle }) => reviewTitle,
					({ userId }) => userId,
					({ reviewReply }) => reviewReply,
					({ createDate }) => Format.dateTime(createDate)
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>
		</Layout>
	)
}

IAReviewReplyListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(ReviewReplyEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool
}
