import PropTypes from 'prop-types'
import ImaAbstractPureComponent from 'ima/page/AbstractPureComponent'

/**
 * The base class for all view components.
 *
 * @abstract
 * @class AbstractComponent
 * @extends ima.page.AbstractComponent
 * @namespace app.base
 * @module app
 * @submodule app.base
 */
export default class AbstractComponent extends ImaAbstractPureComponent {
	static get contextTypes() {
		return {
			$Utils: PropTypes.object
		}
	}
}
