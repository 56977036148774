import React, { useContext } from 'react'
import { EDIT_FILLED, TRASH_FILLED, CLOSE_OUTLINE, CHECKER_NORMAL } from '@sznds/icons'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import { useFire } from 'app/base/componentHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import ActionButton from 'app/component/actionButton/ActionButton'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import StateMark, { TYPE } from 'app/component/stateMark/StateMark'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import IAAdminListController from './IAAdminListController'
import PropTypes from 'prop-types'
import { PaginationEntity, AdminEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import EntityForm from 'app/component/entityForm/EntityForm'
import { VARIANT } from 'app/component/internalAdminWidgets/InternalAdminWidgets'
import EntityFormPopup from '../components/entityFormPopup/EntityFormPopup'
import AddNewButton from '../components/addNewButton/AddNewButton'

const { ACTIONS } = IAAdminListController

export default function IAAdminListView(props) {
	const {
		entitiesAndPagination,
		filterFormLineEntities,
		isFilterLoading,
		isEditAdminPopupOpened,
		formLineEntities = DEFAULT_PROPS.ARRAY,
		entityFormError,
		isWaiting,
		isLoading
	} = props

	const fire = useFire()
	const { rights = [] } = useContext(AdminEntityContext) || {}

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getActions = () => {
		let actions = []

		if (rights.includes(ADMIN_RIGHTS.ADMINS_EDIT)) {
			actions = [
				(entity, actionButtonProps = {}) => (
					<ActionButton
						icon={EDIT_FILLED}
						key='editovat'
						title='Editovat'
						onClick={() =>
							fire('action', {
								action: ACTIONS.OPEN_EDIT_ADMIN_POPUP,
								entities: [entity]
							})
						}
						{...actionButtonProps}
					/>
				),
				(entity, actionButtonProps = {}) => {
					if (entity.enabled) {
						return (
							<ActionButton
								icon={CLOSE_OUTLINE}
								key='deaktivovat'
								title='Zakázat admina'
								onClick={() => action(ACTIONS.DEACTIVATE, [entity])}
								{...actionButtonProps}
							/>
						)
					} else {
						return (
							<ActionButton
								icon={CHECKER_NORMAL}
								key='aktivovat'
								title='Povolit admina'
								onClick={() => action(ACTIONS.ACTIVATE, [entity])}
								{...actionButtonProps}
							/>
						)
					}
				}
			]
		}

		if (rights.includes(ADMIN_RIGHTS.ADMINS_DELETE)) {
			actions.push((entity, actionButtonProps = {}) => (
				<ActionButton
					icon={TRASH_FILLED}
					key='smazat'
					title='Smazat admina'
					onClick={() => action(ACTIONS.DELETE, [entity])}
					{...actionButtonProps}
				/>
			))
		}

		return actions
	}

	return (
		<Layout title='Výpis adminů'>
			{rights.includes(ADMIN_RIGHTS.ADMINS_CREATE) && (
				<AddNewButton
					text='Přidat admina'
					onClick={() =>
						fire('action', {
							action: ACTIONS.OPEN_EDIT_ADMIN_POPUP
						})
					}
				/>
			)}

			<EntitiesFilter formLineEntities={filterFormLineEntities} isFilterLoading={isFilterLoading} />

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				headCells={[
					<TableHead key='state'>Stav</TableHead>,
					<TableHeadSorter key='id' sortParam='id' isDefaultAscending={true} alignRight={true}>
						ID
					</TableHeadSorter>,
					<TableHeadSorter key='name' sortParam='name'>
						Jméno
					</TableHeadSorter>,
					<TableHeadSorter key='login' sortParam='login'>
						Login
					</TableHeadSorter>,
					<TableHeadSorter key='role' sortParam={InternalAdminUrlConvertor.constants.ROLE_ID}>
						Role
					</TableHeadSorter>
				]}
				rowCells={[
					({ enabled }) => (
						<StateMark type={enabled ? TYPE.OK : TYPE.ERROR}>
							{enabled ? 'Povolený' : 'Zakázaný'}
						</StateMark>
					),
					({ id }) => [id, { isNumber: true }],
					({ name }) => name,
					({ login }) => login,
					({ roleName }) => roleName
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>

			{isEditAdminPopupOpened && (
				<EntityFormPopup
					title={
						formLineEntities.find((f) => f.id === 'id' && f.value) ? 'Editace admina' : 'Nový admin'
					}
					onClose={() =>
						fire('action', {
							action: ACTIONS.CLOSE_EDIT_ADMIN_POPUP
						})
					}
				>
					<EntityForm
						variant={VARIANT.POPUP_FORM}
						formLineEntities={formLineEntities}
						entityFormError={entityFormError}
						isWaiting={isWaiting}
					/>
				</EntityFormPopup>
			)}
		</Layout>
	)
}

IAAdminListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.instanceOf(AdminEntity)),
		pagination: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isEditAdminPopupOpened: PropTypes.bool,
	formLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	entityFormError: PropTypes.object,
	isWaiting: PropTypes.bool,
	isLoading: PropTypes.bool
}
