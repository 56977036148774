export default class MirroringEntity {
	constructor(data) {
		this.id = data.id
		this.premiseId = data.premiseId
		this.premiseName = data.premiseName
		this.premiseExternalId = data.premiseExternalId
		this.status = data.status
		this.dateFrom = data.dateFrom
		this.dateTo = data.dateTo
		this.registrationId = data.registrationId
	}
}
