import getAdvertState from 'app/page/userweb/clientAdvertList/getAdvertState'

export const loadDataForSteps = async (
	advertId,
	advertEntityPromise = Promise.resolve({}),
	advertService,
	getStepsDefinitionCallback = () => {}
) => {
	if (!advertService) {
		return
	}

	const cachedData = advertService.getAdvertCachedData(advertId)

	if (cachedData) {
		const { categoryId, advertState } = cachedData
		const steps = getStepsDefinitionCallback(categoryId, advertState)
		return fillStepsState(steps)
	} else {
		const advertEntity = (await advertEntityPromise) || {}

		const { category: { id: categoryId } = {} } = advertEntity

		const advertState = getAdvertState(advertEntity)

		if (categoryId) {
			advertService.saveAdvertCachedData(advertId, { categoryId, advertState })
			const steps = getStepsDefinitionCallback(categoryId, advertState)
			return fillStepsState(steps)
		}
	}
}

const fillStepsState = (steps = []) => {
	const cloneArray = (arr) => arr.map((o) => Object.assign({}, o))

	const newSteps = cloneArray(steps)

	const curentStepIndex = newSteps.findIndex((step) => step.actual)

	// - oznaceni proslych kroku
	// - TODO je treba napojit na api
	newSteps.forEach((step, index) => {
		step.visited = index < curentStepIndex
	})

	// - oznaceni vyplnenych kroku
	// - TODO je treba napojit na api
	newSteps.forEach((step, index) => {
		step.filled = index < curentStepIndex
	})

	return newSteps.filter((step) => !step.skip)
}

export const getNextStepUrl = (steps = []) => {
	const currentStepIndex = steps.findIndex((step) => step.actual)

	const nextStep = currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1] : null

	return nextStep ? nextStep.url : ''
}
