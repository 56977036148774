import AbstractInternalAdminController from 'app/base/internalAdmin/AbstractInternalAdminController'
import ROUTE_NAMES from 'app/base/RouteNames'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'

export default class IAAdvertHistoryController extends AbstractInternalAdminController {
	constructor(dependenciesHelper, advertService) {
		super(dependenciesHelper)

		this._dictionary = this._utils.$Dictionary
		this._router = this._utils.$Router
		this._advertService = advertService
	}

	init() {
		super.init()
	}

	activate() {
		super.activate()

		this.setState({
			isLoading: false
		})
	}

	load() {
		return super.load({
			advertId: this._getAdvertId(),
			entitiesAndPagination: this._getAdvertHistory(),
			isLoading: true,
			isAllRowsDetailsOpened: false,
			[AbstractInternalAdminController.stateId.GO_BACK_LIST_URL]: this._getBackUrl(
				ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST
			),
			[AbstractInternalAdminController.stateId.DIRECT_LINK_DATA]: this._getDirectLinkData()
		})
	}

	update() {
		return {
			entitiesAndPagination: this._getAdvertHistory(),
			isLoading: true
		}
	}

	onToggleAllRowsDetailsOpened() {
		const { isAllRowsDetailsOpened } = this.getState()
		this.setState({
			isAllRowsDetailsOpened: !isAllRowsDetailsOpened
		})
	}

	_getAdvertId() {
		const { [InternalAdminUrlConvertor.constants.ADVERT_ID]: advertId } = this.getRouteParams()

		return advertId
	}

	_getDirectLinkData() {
		const advertId = this._getAdvertId()

		return {
			text: 'Editace inzerátu',
			url: this._router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO, {
				[InternalAdminUrlConvertor.constants.ADVERT_ID]: advertId
			})
		}
	}

	async _getAdvertHistory() {
		const advertId = this._getAdvertId()
		const params = InternalAdminUrlConvertor.processRouteParamsForEntityList(this.getRouteParams())

		if (!params.sort) {
			params.sort = '-id'
		}

		this._pageLoaderExtension.show()

		const {
			advertHistoryEntities,
			paginationEntity
		} = await this._advertService.getAdvertHistory(advertId, params, { cache: false })

		this._pageLoaderExtension.hide()

		this.setState({
			isLoading: false
		})

		return {
			entities: advertHistoryEntities,
			paginationEntity
		}
	}
}
