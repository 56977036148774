import React from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { StatusMessage } from '@inzeraty/components'
import FORM_STATUS_CODES from './FormStatusCodes'

import './FormStatusMessageCS.json'

const FormStatusMessage = ({ status }) => {
	const localize = useLocalize()

	const { statusCode, extra = {} } = status

	if (statusCode === FORM_STATUS_CODES.DATA_FROM_VIN_OK) {
		return (
			<StatusMessage
				type={StatusMessage.TYPE.SUCCESS}
				title={localize('FormStatusMessage.statusVINOK')}
			/>
		)
	} else if (statusCode === FORM_STATUS_CODES.DATA_FROM_VIN_FAILED) {
		const { failedFieldsCount } = extra

		return (
			<StatusMessage
				type={StatusMessage.TYPE.WARNING}
				title={localize('FormStatusMessage.statusVINFailedPart1')}
			>
				{localize('FormStatusMessage.statusVINFailedPart2', {
					FAILED_FIELDS_COUNT: failedFieldsCount
				})}
			</StatusMessage>
		)
	} else if (statusCode === FORM_STATUS_CODES.VALIDATION_ERRORS) {
		const { failedFieldsCount } = extra

		return (
			<StatusMessage
				type={StatusMessage.TYPE.ERROR}
				title={localize('FormStatusMessage.statusValidationErrorsPart1')}
			>
				{localize('FormStatusMessage.statusValidationErrorsPart2', {
					FAILED_FIELDS_COUNT: failedFieldsCount
				})}
			</StatusMessage>
		)
	} else if (statusCode === FORM_STATUS_CODES.VALIDATION_ERRORS_UNKNOWN) {
		return (
			<StatusMessage
				type={StatusMessage.TYPE.ERROR}
				title={localize('FormStatusMessage.statusValidationErrorsUnknown')}
			/>
		)
	} else if (statusCode === FORM_STATUS_CODES.CEBIA_DATA_LOADING) {
		return (
			<StatusMessage
				type={StatusMessage.TYPE.WARNING}
				title={localize('FormStatusMessage.statusCebiaDataLoading')}
			/>
		)
	} else {
		return null
	}
}

export default FormStatusMessage
