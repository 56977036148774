import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import AbstractPureComponent from 'ima/page/AbstractPureComponent'
import SearchPopup from 'app/component/filters/popups/searchPopup/SearchPopup'

import './SearchFilterPopup.less'

const CLASSNAME = 'c-search-filter-popup'

export default class SearchFilterPopup extends AbstractPureComponent {
	static get propTypes() {
		return {
			formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
			title: PropTypes.string.isRequired,
			searchInputPlaceholder: PropTypes.string.isRequired,
			filterPopupLogicData: PropTypes.object.isRequired,
			renderContent: PropTypes.func.isRequired,
			headerDataDotData: PropTypes.string
		}
	}

	static get defaultProps() {
		return {
			formLineEntity: DEFAULT_PROPS.OBJECT,
			title: '',
			searchInputPlaceholder: '',
			filterPopupLogicData: DEFAULT_PROPS.OBJECT,
			renderContent: DEFAULT_PROPS.FUNCTION
		}
	}

	render() {
		const {
			formLineEntity,
			title,
			searchInputPlaceholder,
			filterPopupLogicData,
			renderContent,
			headerDataDotData
		} = this.props
		const { isDefaultPopupType, closePopup, resetFilter } = filterPopupLogicData

		// kvuli problemum se scrollovanim na iOS je potreba predat
		// referenci na scrollovany obsah
		const scrollContentRef = React.createRef()

		return (
			<SearchPopup
				className={`${CLASSNAME}__content`}
				filterId={formLineEntity.id}
				title={title}
				searchInputPlaceholder={searchInputPlaceholder}
				defaultType={isDefaultPopupType}
				onClose={closePopup}
				onReset={resetFilter}
				contentRef={scrollContentRef}
				headerDataDotData={headerDataDotData}
			>
				{(searchTerm) => (
					<div
						data-e2e={`cf-popup-form-${formLineEntity.id}`}
						className={`${CLASSNAME}__form`}
						ref={scrollContentRef}
					>
						{renderContent(filterPopupLogicData, searchTerm)}
					</div>
				)}
			</SearchPopup>
		)
	}
}
