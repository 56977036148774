import { AbstractFactory } from '@inzeraty/models'
import OperatingLeaseEntity from './OperatingLeaseEntity'

export default class OperatingLeaseFactory extends AbstractFactory {
	constructor() {
		super(OperatingLeaseEntity)

		this.transformData = this.transformData.bind(this)
	}

	transformData(data = {}) {
		return {
			id: data.id,
			period: data.period,
			annualDistance: data.annual_distance,
			priceWithVat: data.price_with_vat,
			priceWithoutVat: data.price_without_vat,
			additionalInfo: data.additional_info,
			servicesCb: data.services_cb || undefined
		}
	}
}
