/**
 * @class VINVehicleDataEntity
 * @description Obsahuje data, ktere je mozne ziskat z Cebie na
 * 				zaklade zadaneho VINu.
 * @module models
 */
export default class VINVehicleDataEntity {
	constructor(data) {
		this.additionalModelName = data.additionalModelName
		this.averageGasMileage = data.averageGasMileage
		this.beds = data.beds
		this.categoryCebia = data.categoryCebia
		this.categoryId = data.categoryId
		this.categoryIiztp = data.categoryIiztp
		this.categoryName = data.categoryName
		this.categoryZtp = data.categoryZtp
		this.colorCb = data.colorCb
		this.colorName = data.colorName
		this.colorNameEng = data.colorNameEng
		this.countryOfOriginCb = data.countryOfOriginCb
		this.countryOfOriginName = data.countryOfOriginName
		this.enginePower = data.enginePower
		this.engineVolume = data.engineVolume
		this.equipment = data.equipment
		this.firstAbroadRegistration = data.firstAbroadRegistration
		this.fuelCb = data.fuelCb
		this.fuelCode = data.fuelCode
		this.generation = data.generation
		this.keyless = data.keyless
		this.manufacturerCb = data.manufacturerCb
		this.manufacturerName = data.manufacturerName
		this.manufacturerNameEng = data.manufacturerNameEng
		this.manufacturingDate = data.manufacturingDate
		this.modelCb = data.modelCb
		this.modelName = data.modelName
		this.modelNameEng = data.modelNameEng
		this.placesSitting = data.placesSitting
		this.placesStanding = data.placesStanding
		this.tradeMark = data.tradeMark
		this.vehicleBodyCb = data.vehicleBodyCb
		this.vehicleBodyName = data.vehicleBodyName
		this.vin = data.vin
		this.weight = data.weight
		this.weightDrivingSet = data.weightDrivingSet
		this.weightEmergency = data.weightEmergency
		this.ztpNote = data.ztpNote
	}
}
