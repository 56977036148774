import * as FormLines from '@inzeraty/form-lines'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import FilterConstants from '../FilterConstants'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'

/**
 * @class
 * Převede datové struktury a hodnoty filtrů (javascritp) na parametry pro použití v url.
 */
export default class FilterToUrlAppConvertor {
	/**
	 * Vrátí url parametry pro výpis inzerátů podle nastavení filtru.
	 *
	 * @method getFilterAdvertListUrlParams
	 * @public
	 * @param  {Array<FormLineEntity>} formLineEntities
	 * @param {Boolean} autoBrandsSplit Rozdelenie 1 znacky a modelu <= 1 do vlastneho url parametru,
	 * 	alebo spojenie do parametru znacky-modely
	 * @return {Object} Parametry pro url výpisu inzerátu.
	 */
	static getFilterAdvertListUrlParams(formLineEntities = [], autoBrandsSplit = false) {
		const formData = FormLines.getFormData(formLineEntities)

		const brandModelParams = FilterToUrlAppConvertor.getBrandModelUrlParams(
			formData,
			formLineEntities,
			autoBrandsSplit
		)
		const regionDistrictParams = FilterToUrlAppConvertor.getRegionDistrictUrlParams(
			formData,
			formLineEntities
		)
		const fromToParams = FilterToUrlAppConvertor.getFromToUrlParams(formData)
		const performanceParams = FilterToUrlAppConvertor.getPerformanceParams(formData)
		const multiChoiceParams = FilterToUrlAppConvertor.getMultiChoiceUrlParams(
			formData,
			formLineEntities
		)
		const itemAgeParams = FilterToUrlAppConvertor.getItemAgeUrlParams(formData, formLineEntities)
		const phraseParams = FilterToUrlAppConvertor.getPhraseUrlParams(formData)

		return Object.assign(
			{},
			brandModelParams,
			regionDistrictParams,
			fromToParams,
			performanceParams,
			multiChoiceParams,
			itemAgeParams,
			phraseParams
		)
	}

	/**
	 * Vrátí url parametry pro značky a modely podle nastavení filtru.
	 *
	 * @method getBrandModelUrlParams
	 * @public
	 * @param {Object}                formData
	 * @param {Array<FormLineEntity>} formLineEntities
	 * @return {Object} Parametry pro značky a modely.
	 */
	static getBrandModelUrlParams(formData, formLineEntities, autoBrandsSplit) {
		const {
			BRANDS_MODELS,
			BRAND_NAME,
			MODEL_NAME
		} = AdvertListUrlConvertor.constants.URL_APP_PARAMS
		const { [FilterConstants.formLineIds.BRAND_MODEL]: brandsModels = new Map() } = formData
		const params = {}
		const brandsCount = brandsModels.size

		if (brandsCount) {
			const { options = [] } = formLineEntities.find(
				(formLineEntity) => formLineEntity.id === FilterConstants.formLineIds.BRAND_MODEL
			)

			let brandsModelsParam = ''

			brandsModels.forEach((brandModels, brandValue, map) => {
				const modelsCount = brandModels.size

				const { seoName: brandSeoName, children = [] } =
					options.find((brand) => brand.value === brandValue) || {}

				if (brandsCount === 1 && modelsCount <= 1 && !autoBrandsSplit) {
					params[BRAND_NAME] = brandSeoName

					if (modelsCount) {
						const modelValue = Array.from(brandModels)[0]
						const { seoName: modelSeoName } =
							children.find((model) => model.value === modelValue) || {}

						params[MODEL_NAME] = modelSeoName
					}
				} else {
					brandsModelsParam += brandsModelsParam ? '|' : ''
					brandsModelsParam += modelsCount ? `${brandValue}:` : `${brandValue}`
					brandsModelsParam += Array.from(brandModels).join(',')
				}
			})

			if (brandsModelsParam) {
				params[BRANDS_MODELS] = brandsModelsParam
			}
		}

		return params
	}

	/**
	 * Vrátí url parametry pro kraje a okresy podle nastavení filtru.
	 *
	 * @method getRegionDistrictUrlParams
	 * @public
	 * @param {Object}                formData
	 * @param {Array<FormLineEntity>} formLineEntities
	 * @return {Object} Parametry pro kraje a okresy.
	 */
	static getRegionDistrictUrlParams(formData, formLineEntities) {
		const { REGION_NAME, DISTRICT_NAME } = AdvertListUrlConvertor.constants.URL_APP_PARAMS
		const { [FilterConstants.formLineIds.REGION_DISTRICT]: regionDistricts = new Map() } = formData
		const params = {}

		if (regionDistricts.size) {
			const { options = [] } =
				formLineEntities.find(
					(formLineEntity) => formLineEntity.id === FilterConstants.formLineIds.REGION_DISTRICT
				) || {}

			let regionParams = []
			let districtParams = []

			regionDistricts.forEach((districtValues, regionValue) => {
				const { seoName: regionSeoName, children: districts } = options.find(
					(option) => option.value === regionValue
				)

				if (districtValues.size) {
					districtParams = [
						...districtParams,
						...Array.from(districtValues).map((districtValue) => {
							const { seoName: districtSeoName } = districts.find(
								(option) => option.value === districtValue
							)
							return districtSeoName
						})
					]
				} else {
					regionParams = [...regionParams, regionSeoName]
				}
			})

			if (regionParams.length) {
				params[REGION_NAME] = regionParams.join(',')
			}

			if (districtParams.length) {
				params[DISTRICT_NAME] = districtParams.join(',')
			}
		}

		return params
	}

	/**
	 * Vrátí url parametry pro hodnoty nastavované od - do (cena, stáří vozu).
	 *
	 * @method getFromToUrlParams
	 * @public
	 * @param {Object} formData
	 * @return {Object} Parametry pro hodnoty nastavované od - do.
	 */
	static getFromToUrlParams(formData) {
		const {
			PRICE_FROM,
			PRICE_TO,
			AGE_FROM,
			AGE_TO,
			TACHOMETER_FROM,
			TACHOMETER_TO,
			DISPLACEMENT_FROM,
			DISPLACEMENT_TO,
			FUEL_CONSUMPTION_FROM,
			FUEL_CONSUMPTION_TO,
			AIRBAGS_FROM,
			AIRBAGS_TO,
			CAPACITY_FROM,
			CAPACITY_TO,
			DOORS_FROM,
			DOORS_TO,
			WEIGHT_FROM,
			WEIGHT_TO,
			BEDS_FROM,
			BEDS_TO,
			MACHINE_HOURS_FROM,
			MACHINE_HOURS_TO,
			OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM,
			OPERATING_LEASE_PRICE_WITHOUT_VAT_TO,
			OPERATING_LEASE_ANNUAL_DISTANCE_FROM,
			OPERATING_LEASE_ANNUAL_DISTANCE_TO,
			OPERATING_LEASE_PERIOD_FROM,
			OPERATING_LEASE_PERIOD_TO
		} = AdvertListUrlConvertor.constants.URL_APP_PARAMS
		const params = {}

		const fromTo = [
			{
				id: FilterConstants.formLineIds.PRICE,
				from: PRICE_FROM,
				to: PRICE_TO
			},
			{
				id: FilterConstants.formLineIds.OPERATING_LEASE_PRICE_WITHOUT_VAT,
				from: OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM,
				to: OPERATING_LEASE_PRICE_WITHOUT_VAT_TO
			},
			{
				id: FilterConstants.formLineIds.OPERATING_LEASE_ANNUAL_DISTANCE,
				from: OPERATING_LEASE_ANNUAL_DISTANCE_FROM,
				to: OPERATING_LEASE_ANNUAL_DISTANCE_TO
			},
			{
				id: FilterConstants.formLineIds.OPERATING_LEASE_PERIOD,
				from: OPERATING_LEASE_PERIOD_FROM,
				to: OPERATING_LEASE_PERIOD_TO
			},
			{
				id: FilterConstants.formLineIds.AGE,
				from: AGE_FROM,
				to: AGE_TO
			},
			{
				id: FilterConstants.formLineIds.TACHOMETER,
				from: TACHOMETER_FROM,
				to: TACHOMETER_TO
			},
			{
				id: FilterConstants.formLineIds.DISPLACEMENT,
				from: DISPLACEMENT_FROM,
				to: DISPLACEMENT_TO
			},
			{
				id: FilterConstants.formLineIds.FUEL_CONSUMPTION,
				from: FUEL_CONSUMPTION_FROM,
				to: FUEL_CONSUMPTION_TO
			},
			{
				id: FilterConstants.formLineIds.AIRBAGS,
				from: AIRBAGS_FROM,
				to: AIRBAGS_TO
			},
			{
				id: FilterConstants.formLineIds.CAPACITY,
				from: CAPACITY_FROM,
				to: CAPACITY_TO
			},
			{
				id: FilterConstants.formLineIds.DOORS,
				from: DOORS_FROM,
				to: DOORS_TO
			},
			{
				id: FilterConstants.formLineIds.WEIGHT,
				from: WEIGHT_FROM,
				to: WEIGHT_TO
			},
			{
				id: FilterConstants.formLineIds.BEDS,
				from: BEDS_FROM,
				to: BEDS_TO
			},
			{
				id: FilterConstants.formLineIds.MACHINE_HOURS,
				from: MACHINE_HOURS_FROM,
				to: MACHINE_HOURS_TO
			}
		]

		fromTo.forEach((fromToValue) => {
			const { id, from, to } = fromToValue
			const { valueFrom, valueTo } = formData[id] || {}

			if (!isNullOrUndefined(valueFrom) && valueFrom >= 0) {
				params[from] = valueFrom
			}

			if (!isNullOrUndefined(valueTo) && valueTo >= 0) {
				params[to] = valueTo
			}
		})

		return params
	}

	/**
	 * Vrátí url parametry pro výkon motoru
	 *
	 * @method getPerformanceParams
	 * @public
	 * @param {Object} formData
	 * @return {Object} Parametry pro výkon motoru.
	 */
	static getPerformanceParams(formData) {
		const params = {}
		const PERFORMANCE_UNIT = 'kw'

		const { PERFORMANCE_FROM, PERFORMANCE_TO } = AdvertListUrlConvertor.constants.URL_APP_PARAMS

		const { valueFrom, valueTo } = formData[FilterConstants.formLineIds.PERFORMANCE] || {}

		if (!isNullOrUndefined(valueFrom) && valueFrom >= 0) {
			params[PERFORMANCE_FROM] = valueFrom + PERFORMANCE_UNIT
		}

		if (!isNullOrUndefined(valueTo) && valueTo >= 0) {
			params[PERFORMANCE_TO] = valueTo + PERFORMANCE_UNIT
		}

		return params
	}

	/**
	 * Vrátí url parametry pro filtry, které umožňují vybrat více hodnot.
	 *
	 * @method getMultiChoiceUrlParams
	 * @public
	 * @param {Object}                formData
	 * @param {Array<FormLineEntity>} formLineEntities
	 * @return {Object} Parametry pro filtry s možností vybrat více hodnot.
	 */
	static getMultiChoiceUrlParams(formData = {}, formLineEntities = []) {
		const {
			CONDITION_NAME,
			OPERATING_LEASE_SERVICES_NAME,
			OPERATING_LEASE_INTENDED_FOR_NAME,
			FUEL_NAME,
			VEHICLE_BODY_NAME,
			GEARBOX_NAME,
			COUNTRY_OF_ORIGIN_NAME,
			VENDOR_NAME,
			AIR_CONDITIONING_NAME,
			CERTIFIED_PROGRAM_NAME,
			COLOR_NAME,
			BUS_SEATS_NAME,
			PRICE_OPTION_NAME,
			HISTORY_INFO_NAME,
			EQUIPMENT_NAME,
			DRIVE_NAME,
			OTHER_SPECIFICATION_NAME
		} = AdvertListUrlConvertor.constants.URL_APP_PARAMS
		const multiChoiceFilters = [
			{
				formLineEntityId: FilterConstants.formLineIds.CONDITION,
				urlParamId: CONDITION_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.OPERATING_LEASE_SERVICES,
				urlParamId: OPERATING_LEASE_SERVICES_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.OPERATING_LEASE_INTENDED_FOR,
				urlParamId: OPERATING_LEASE_INTENDED_FOR_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.FUEL,
				urlParamId: FUEL_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.VEHICLE_BODY,
				urlParamId: VEHICLE_BODY_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.GEARBOX,
				urlParamId: GEARBOX_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.COUNTRY_OF_ORIGIN,
				urlParamId: COUNTRY_OF_ORIGIN_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.VENDOR,
				urlParamId: VENDOR_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.AIR_CONDITIONING,
				urlParamId: AIR_CONDITIONING_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.CERTIFIED_PROGRAM,
				urlParamId: CERTIFIED_PROGRAM_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.COLOR,
				urlParamId: COLOR_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.BUS_SEATS,
				urlParamId: BUS_SEATS_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.PRICE_OPTION,
				urlParamId: PRICE_OPTION_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.HISTORY_INFO,
				urlParamId: HISTORY_INFO_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.EQUIPMENT,
				urlParamId: EQUIPMENT_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.DRIVE,
				urlParamId: DRIVE_NAME
			},
			{
				formLineEntityId: FilterConstants.formLineIds.OTHER_SPECIFICATION,
				urlParamId: OTHER_SPECIFICATION_NAME
			}
		]

		return multiChoiceFilters.reduce((params, { formLineEntityId, urlParamId }) => {
			const { options = [] } =
				formLineEntities.find((formLineEntity) => formLineEntity.id === formLineEntityId) || {}
			const { [formLineEntityId]: data = new Map() } = formData
			const param = []

			data.forEach((_, key) => {
				const option = options.find((option) => option.value === key)

				if (option) param.push(option.seoName)
			})

			if (param.length) {
				const isSameAsDefault = AdvertListUrlConvertor.validateDefaultConditionParams(param)

				if (
					urlParamId === AdvertListUrlConvertor.constants.URL_APP_PARAMS.CONDITION_NAME &&
					isSameAsDefault
				) {
					return {}
				}

				return Object.assign({}, params, {
					[urlParamId]: param.join(',')
				})
			} else {
				if (urlParamId === AdvertListUrlConvertor.constants.URL_APP_PARAMS.CONDITION_NAME) {
					return { [urlParamId]: FilterConstants.allConditionApiParam }
				}
				return params
			}
		}, {})
	}

	/**
	 * Vrátí url parametry pro filtr staří inzeratu.
	 *
	 * @method getItemAgeUrlParams
	 * @public
	 * @param {Object}                formData
	 * @param {Array<FormLineEntity>} formLineEntities
	 * @return {Object} Parametry pro filtry s možností vybrat více hodnot.
	 */
	static getItemAgeUrlParams(formData = {}, formLineEntities = []) {
		const { ITEM_AGE_NAME } = AdvertListUrlConvertor.constants.URL_APP_PARAMS
		const params = {}

		const { value } = formData[FilterConstants.formLineIds.ITEM_AGE] || {}

		const itemAgeEntity = formLineEntities.find((formLineEntity) => {
			return formLineEntity.id === FilterConstants.formLineIds.ITEM_AGE
		})

		if (itemAgeEntity) {
			const selectedOption = itemAgeEntity.options.find((option) => {
				return value === option.value
			})

			if (selectedOption) {
				params[ITEM_AGE_NAME] = selectedOption.seoName
			}
		}

		return params
	}

	static getPhraseUrlParams(formData = {}) {
		const phrase = formData[FilterConstants.formLineIds.PHRASE]

		return phrase ? { [AdvertListUrlConvertor.constants.URL_APP_PARAMS.PHRASE]: phrase } : {}
	}
}
