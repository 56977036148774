import React from 'react'
import PropTypes from 'prop-types'
import AbstractComponent from 'app/base/AbstractComponent'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import premisePropTypes from 'app/model/premise/PremisePropTypes'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import { Format } from '@inzeraty/helpers'
import { Icon } from '@sznds/react'
import {
	POI_FILLED_24,
	TIME_ALT1_OUTLINE_24,
	CHEVRON_UP_OUTLINE_16,
	CHEVRON_DOWN_OUTLINE_16,
	PHONE_FILLED_24,
	MAIL_ALT1_FILLED_24,
	WEB_OUTLINE_24
} from '@sznds/icons'
import LocalityView from 'app/component/locality/LocalityView'
import LocalityHelper from 'app/component/locality/LocalityHelper'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { DATA_SEOT } from 'app/base/SeoTesting'
import { ElementInViewportDetector } from '@inzeraty/components'
import classnames from 'classnames'

import './SellerCard.less'
import './SellerCardCS.json'

const CLASSNAME = 'c-seller-card'

const sortDefaultContactFirst = (itemA = {}, itemB = {}) => {
	const { default: defaultA } = itemA
	const { default: defaultB } = itemB

	// kontakty, ktere maji 'default' nastaveny na true, chceme mit vepredu
	return defaultA === defaultB ? 0 : defaultA ? -1 : 1
}

export default class SellerCard extends AbstractComponent {
	static get LOCATION_ON_PAGE() {
		return {
			STICKY_TOP: 'STICKY_TOP',
			STICKY_BOTTOM: 'STICKY_BOTTOM',
			STATIC: 'STATIC'
		}
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			advertEntity: PropTypes.shape(advertPropTypes).isRequired,
			premiseEntity: PropTypes.shape(premisePropTypes).isRequired,
			showDescription: PropTypes.bool,
			isPremiseSeller: PropTypes.bool,
			isDataLoaded: PropTypes.bool,
			classNameForPrivateSeller: PropTypes.string,
			classNameForPremiseSeller: PropTypes.string,
			getLocationOnPage: PropTypes.func
		}
	}

	static get defaultProps() {
		return {
			className: '',
			showDescription: false,
			premiseEntity: DEFAULT_PROPS.OBJECT,
			advertEntity: DEFAULT_PROPS.OBJECT,
			isDataLoaded: false,
			classNameForPrivateSeller: '',
			classNameForPremiseSeller: '',
			getLocationOnPage: DEFAULT_PROPS.FUNCTION
		}
	}

	constructor(props, context) {
		super(props, context)

		this.state = {
			isPhoneShown: false,
			isHoursOpen: false,
			isEmailShown: false
		}

		this.isReadyToSendAnalytics = true

		this._handleClickOnCallButton = this._handleClickOnCallButton.bind(this)
		this._handleClickOnShowPhoneButton = this._handleClickOnShowPhoneButton.bind(this)
		this._handleClickOnShowEmailButton = this._handleClickOnShowEmailButton.bind(this)
		this._handleClickOnEmail = this._handleClickOnEmail.bind(this)
		this._onElementInViewportRecalc = this._onElementInViewportRecalc.bind(this)
		this._handleClickMap = this._handleClickMap.bind(this)
		this._handleClickWeb = this._handleClickWeb.bind(this)

		this._toggleOpenHours = this._toggleOpenHours.bind(this)

		this._sendAnalyticsDataOnViewingPhoneNumber = this._sendAnalyticsDataOnViewingPhoneNumber.bind(
			this
		)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Slouží pro přenastavení příznaku zaměření zobrazení kontaktu při přechodech na další/předchozí inzerát.
		// Na stránce prodejce toto přenastavení nedělá.
		if (
			prevProps.isDataLoaded === false &&
			this.props.isDataLoaded === true &&
			!this.props.isPremiseSeller
		) {
			this.isReadyToSendAnalytics = true
		}
	}

	_onElementInViewportRecalc({ isElementInsideViewport }) {
		if (isElementInsideViewport && this.props.isDataLoaded && this.isReadyToSendAnalytics) {
			this._sendAnalyticsDataOnVisibleImpress()
			this.isReadyToSendAnalytics = false
		}
	}

	render() {
		const { className } = this.props

		return (
			<ElementInViewportDetector
				onRecalc={this._onElementInViewportRecalc}
				className={this.cssClasses({
					[className]: !!className
				})}
				offsetTop={-150}
			>
				{this._isPremiseSeller() ? this._renderPremiseSeller() : this._renderPrivateSeller()}
			</ElementInViewportDetector>
		)
	}

	_getRouteName() {
		const { route } = this._utils.$Router.getCurrentRouteInfo()
		return route.getName()
	}

	_sendAnalyticsDataOnVisibleImpress() {
		const { AnalyticsDataSender } = this.utils

		AnalyticsDataSender.hitForVisibleImpress({
			elementType: 'SellerCard',
			page: this._getRouteName()
		})
	}

	_renderPrivateSeller() {
		const { advertEntity, isDataLoaded, classNameForPrivateSeller } = this.props
		const { locality, dontShowPhone = false } = advertEntity
		const { isPhoneShown } = this.state
		const phone = this._getPhone()

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__private`]: true,
					[classNameForPrivateSeller]: !!classNameForPrivateSeller
				})}
			>
				{isDataLoaded ? (
					<div className={`${CLASSNAME}__private-info`}>
						{locality && this._renderLocalityText(locality)}
						{Boolean(phone) && !dontShowPhone && (
							<div className={`${CLASSNAME}__private-item ${CLASSNAME}__private-phone`}>
								{isPhoneShown ? this._renderPhone(phone) : this._renderShowPhoneButton()}
							</div>
						)}
					</div>
				) : (
					<div className={`${CLASSNAME}__desktop-placeholder ${CLASSNAME}__placeholder-private`}>
						<div className={`${CLASSNAME}__desktop-placeholder-item`}>
							{this._renderPlaceholder()}
						</div>
					</div>
				)}
				<div>
					{locality && (
						<div className={`${CLASSNAME}__private-item ${CLASSNAME}__private-map`}>
							<LocalityView
								locality={locality}
								className={`${CLASSNAME}__locality-private`}
								onClick={this._handleClickMap}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}

	_renderPremiseSeller() {
		const {
			premiseEntity,
			showDescription,
			isPremiseSeller,
			isDataLoaded,
			classNameForPremiseSeller,
			advertEntity = {}
		} = this.props

		const {
			locality: premiseLocality = {},
			description,
			web,
			emails: premiseEmails = [],
			phones: premisePhones = [],
			useAdvertLocality = false
		} = premiseEntity

		const { phone: advertPhone, email: advertEmail, locality: advertLocality } = advertEntity

		const { isPhoneShown, isEmailShown } = this.state

		const phones = premisePhones.length
			? premisePhones
			: advertPhone
			? [{ phone: advertPhone }]
			: []
		const emails = premiseEmails.length
			? premiseEmails
			: advertEmail
			? [{ email: advertEmail }]
			: []
		const locality =
			// pouze na detailu inzeratu nas zajima, jestli je useAdvertLocality nastaveno
			this._isAdvertDetail(advertEntity) && useAdvertLocality ? advertLocality : premiseLocality

		const isPhone = phones && phones.length > 0

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__premise`]: true,
					[`${CLASSNAME}__premise--column`]: locality && isPremiseSeller,
					[classNameForPremiseSeller]: !!classNameForPremiseSeller
				})}
			>
				{isDataLoaded ? (
					<div className={`${CLASSNAME}__premise-info`}>
						{showDescription && description && (
							<div className={`${CLASSNAME}__premise-item ${CLASSNAME}__premise-desc`}>
								{this._renderSellerDescription()}
							</div>
						)}
						<div className={`${CLASSNAME}__premise-item`}>
							{locality && this._renderLocalityText(locality)}
						</div>
						<div className={`${CLASSNAME}__premise-item ${CLASSNAME}__premise-opening`}>
							{this._renderOpeningTime()}
						</div>
						{isPhone && (
							<div className={`${CLASSNAME}__premise-item ${CLASSNAME}__premise-phone`}>
								{isPhoneShown ? this._renderPhoneList(phones) : this._renderShowPhoneButton()}
							</div>
						)}
						{emails && (
							<div className={`${CLASSNAME}__premise-item ${CLASSNAME}__premise-email`}>
								{isEmailShown ? this._renderEmailList(emails) : this._renderShowEmailButton()}
							</div>
						)}
						{web && (
							<div className={`${CLASSNAME}__premise-item ${CLASSNAME}__premise-web`}>
								{this._renderWeb(web)}
							</div>
						)}
					</div>
				) : (
					this._renderDesktopPlaceholder()
				)}
				{locality && (
					<div
						className={this.cssClasses({
							[`${CLASSNAME}__premise-item`]: true,
							[`${CLASSNAME}__premise-map`]: true,
							[`${CLASSNAME}__premise-card`]: isPremiseSeller
						})}
					>
						<LocalityView
							locality={locality}
							className={`${CLASSNAME}__locality-premise`}
							onClick={this._handleClickMap}
						/>
					</div>
				)}
			</div>
		)
	}

	_renderDesktopPlaceholder() {
		const { isPremiseSeller } = this.props

		return (
			<div className={`${CLASSNAME}__desktop-placeholder`}>
				{isPremiseSeller && (
					<React.Fragment>
						<div className={`${CLASSNAME}__desktop-placeholder-desc`}>
							{this._renderPlaceholder('desc1')}
						</div>
						<div className={`${CLASSNAME}__desktop-placeholder-desc`}>
							{this._renderPlaceholder('desc2')}
						</div>
						<div className={`${CLASSNAME}__desktop-placeholder-desc`}>
							{this._renderPlaceholder('desc3')}
						</div>
					</React.Fragment>
				)}
				<div className={`${CLASSNAME}__desktop-placeholder-item`}>{this._renderPlaceholder()}</div>
				<div className={`${CLASSNAME}__desktop-placeholder-item`}>{this._renderPlaceholder()}</div>
				<div className={`${CLASSNAME}__desktop-placeholder-item`}>{this._renderPlaceholder()}</div>
				<div className={`${CLASSNAME}__desktop-placeholder-locality`}>
					{this._renderPlaceholder('locality')}
				</div>
			</div>
		)
	}

	_renderLocalityText(locality) {
		const sentence = LocalityHelper.localitySentence(locality, ',')
		const localityClassname = `${CLASSNAME}__locality-text`
		return (
			sentence && (
				<div
					className={classnames({
						[localityClassname]: true,
						[`${localityClassname}--in-seller-description`]: true
					})}
				>
					{this._renderIcon(POI_FILLED_24)}
					{sentence}
				</div>
			)
		)
	}

	_renderSellerDescription() {
		const {
			showDescription,
			premiseEntity: { description }
		} = this.props

		if (showDescription && description) {
			return (
				<React.Fragment>
					<div className={`${CLASSNAME}__seller-desc`} data-seot={DATA_SEOT.SELLER_DESCRIPTION}>
						{description}
					</div>
				</React.Fragment>
			)
		} else {
			return null
		}
	}

	_renderOpeningTime() {
		if (this._isDataLoaded()) {
			const { isHoursOpen } = this.state
			const { premiseEntity } = this.props
			const { openingHours } = premiseEntity

			if (openingHours) {
				const { openingTime = [], openingTimeAdditionalInfo = '' } = openingHours

				const timeArray = openingTime.map((time) => {
					const { specification_id, amStart, amEnd, pmStart, pmEnd } = time

					const timeObj = {}

					const formatTime = (time) => {
						let newTime = time
						if (isNullOrUndefined(newTime)) {
							newTime = 0
						}
						return Format.padding(newTime)
					}

					switch (specification_id) {
						case 'open':
							timeObj.opening = ''
							if (amStart) {
								timeObj.open = `${formatTime(amStart.hour)}:${formatTime(amStart.minute)}`
							}
							if (amEnd) {
								timeObj.close = `${formatTime(amEnd.hour)}:${formatTime(amEnd.minute)}`
							}
							if (pmStart) {
								timeObj.openSec = `${formatTime(pmStart.hour)}:${formatTime(pmStart.minute)}`
							}
							if (pmEnd) {
								timeObj.closeSec = `${formatTime(pmEnd.hour)}:${formatTime(pmEnd.minute)}`
							}
							break

						case 'nonstop':
							timeObj.opening = Format.capitalizeFirstLetter(this.localize('SellerCard.nonstop'))
							break

						case 'closed':
						case 'reservation_only':
						case 'reservation_only_am':
						case 'reservation_only_pm':
						default:
							timeObj.opening = Format.capitalizeFirstLetter(this.localize('SellerCard.closed'))
							break
					}

					return timeObj
				})

				// Prodejce může mít celý týden zavřeno.
				const hasOpenHour = timeArray.find((time) => time.open || time.close || time.opening)

				if (hasOpenHour) {
					return (
						<div className={`${CLASSNAME}__opening-wrap`}>
							<div className={`${CLASSNAME}__opening`}>
								{this._renderIcon(TIME_ALT1_OUTLINE_24)}
								<table className={`${CLASSNAME}__opening-table`}>
									<tbody>
										{this._renderTodayOpening(timeArray)}
										{isHoursOpen && this._openingHoursList(timeArray)}
									</tbody>
								</table>
							</div>
							{openingTimeAdditionalInfo && (
								<div className={`${CLASSNAME}__opening-info`}>{openingTimeAdditionalInfo}</div>
							)}
						</div>
					)
				}
			}
		} else {
			return this._renderPlaceholder()
		}
	}

	_renderPlaceholder(name = '') {
		return (
			<div
				className={`${CLASSNAME}__placeholder ${name && `${CLASSNAME}__placeholder--${name}`}`}
			></div>
		)
	}

	_openingHoursList(openingHours) {
		const weekDays = [
			this.localize('SellerCard.monday'),
			this.localize('SellerCard.tuesday'),
			this.localize('SellerCard.wednesday'),
			this.localize('SellerCard.thursday'),
			this.localize('SellerCard.friday'),
			this.localize('SellerCard.saturday'),
			this.localize('SellerCard.sunday')
		]

		return openingHours.map((hour, index) => {
			if (hour.open && hour.opening === '') {
				return (
					<tr key={index} className={`${CLASSNAME}__opening-table--item`}>
						<th className={`${CLASSNAME}__opening-table--day`}>
							{Format.capitalizeFirstLetter(weekDays[index])}:
						</th>
						<td>{`${hour.open}–${hour.close}`}</td>
						{hour.openSec && <td>{`, ${hour.openSec}–${hour.closeSec}`}</td>}
					</tr>
				)
			} else {
				return (
					<tr key={index} className={`${CLASSNAME}__opening-table--item`}>
						<th className={`${CLASSNAME}__opening-table--day`}>
							{Format.capitalizeFirstLetter(weekDays[index])}:
						</th>
						<td>{hour.opening}</td>
					</tr>
				)
			}
		})
	}

	_renderTodayOpening(openingHours) {
		const { isHoursOpen } = this.state

		let nowDay = new Date().getDay()

		//v pripade ze je nedele tak chceme posledni den
		if (!nowDay) {
			nowDay = openingHours.length
		}

		const openHourDay = openingHours[nowDay - 1]

		const { open, close, openSec, closeSec, opening } = openHourDay

		let openingText

		if (openHourDay && open && (close || closeSec)) {
			const secText = openSec ? `, ${openSec}–${closeSec}` : ''
			openingText = `${open}–${close}${secText}`
		} else if (openHourDay && opening) {
			openingText = opening
		} else {
			openingText = Format.capitalizeFirstLetter(this.localize('SellerCard.closed'))
		}

		return (
			<tr className={`${CLASSNAME}__opening-table--head`}>
				<th colSpan='3'>
					<button
						className={`${CLASSNAME}__opening-table--button`}
						onClick={this._toggleOpenHours}
						data-dot='show-more'
						data-dot-data={`{"type": "openning-hours", "opened": "${isHoursOpen}"}`}
					>
						<span className={`${CLASSNAME}__opening-table--today`}>
							{Format.capitalizeFirstLetter(this.localize('SellerCard.today'))}:
						</span>
						<span>{openingText}</span>
						<Icon
							symbol={isHoursOpen ? CHEVRON_UP_OUTLINE_16 : CHEVRON_DOWN_OUTLINE_16}
							className={`${CLASSNAME}__opening-icon`}
						/>
					</button>
				</th>
			</tr>
		)
	}

	_toggleOpenHours() {
		this.setState((prevState) => ({
			isHoursOpen: !prevState.isHoursOpen
		}))
	}

	_renderIcon(icon) {
		return <Icon symbol={icon} className={`${CLASSNAME}__icon`} />
	}

	_renderShowPhoneButton() {
		if (this._isDataLoaded() || this._isAdvertDetail()) {
			return (
				<button
					className={`${CLASSNAME}__show-phone-button`}
					onClick={this._handleClickOnShowPhoneButton}
				>
					{this._renderIcon(PHONE_FILLED_24)}
					<span className={`${CLASSNAME}__show-phone-button-text`} data-dot='show-phone-number'>
						{this.localize('SellerCard.showPhone')}
					</span>
				</button>
			)
		} else {
			return this._renderPlaceholder()
		}
	}

	_renderShowEmailButton() {
		const {
			premiseEntity: { emails }
		} = this.props
		//TODO vymenit static ikonu email za ikonu z SDS
		if (this._isDataLoaded() && Boolean(emails)) {
			return (
				<button
					className={`${CLASSNAME}__show-email-button`}
					onClick={this._handleClickOnShowEmailButton}
				>
					{this._renderIcon(MAIL_ALT1_FILLED_24)}
					<span className={`${CLASSNAME}__show-email-button-text`} data-dot='show-e-mail'>
						{this.localize('SellerCard.showEmail')}
					</span>
				</button>
			)
		} else {
			return this._renderPlaceholder()
		}
	}

	_renderPhone(phone) {
		return (
			<div className={`${CLASSNAME}__phone`}>
				{this._renderIcon(PHONE_FILLED_24)}
				<span className={`${CLASSNAME}__phone-text`}>{Format.phoneNumber(phone)}</span>
			</div>
		)
	}

	_renderPhoneList(phones = []) {
		return (
			<div className={`${CLASSNAME}__phones`}>
				{this._renderIcon(PHONE_FILLED_24)}
				<ul className={`${CLASSNAME}__phones-list`}>
					{[...phones].sort(sortDefaultContactFirst).map((phone) => (
						<li
							key={phone.id}
							className={this.cssClasses({
								[`${CLASSNAME}__phones-list--item`]: true,
								[`${CLASSNAME}__phones-list--noname`]: !phone.name
							})}
						>
							<span className={`${CLASSNAME}__phones-list--name`}>{phone.name}</span>
							<a
								href={`tel:${phone.phone}`}
								className={`${CLASSNAME}__phones-list--number`}
								onClick={this._handleClickOnCallButton}
								data-dot='phone'
							>
								{Format.phoneNumber(phone.phone)}
							</a>
						</li>
					))}
				</ul>
			</div>
		)
	}

	_renderEmailList(emails = []) {
		//TODO vymenit static ikonu email za ikonu z SDS
		return (
			<div className={`${CLASSNAME}__emails`}>
				{this._renderIcon(MAIL_ALT1_FILLED_24)}
				<ul className={`${CLASSNAME}__emails-list`}>
					{[...emails].sort(sortDefaultContactFirst).map((email) => (
						<li
							key={email.id}
							className={this.cssClasses({
								[`${CLASSNAME}__emails-list--item`]: true,
								[`${CLASSNAME}__emails-list--noname`]: !email.name
							})}
						>
							<span className={`${CLASSNAME}__emails-list--name`}>{email.name}</span>
							<a
								href={`mailto:${email.email}`}
								className={`${CLASSNAME}__emails-list--email`}
								onClick={this._handleClickOnEmail}
								data-dot='e-mail'
							>
								{email.email}
							</a>
						</li>
					))}
				</ul>
			</div>
		)
	}

	_renderWeb(web) {
		if (this._isDataLoaded()) {
			if (web) {
				return (
					<div className={`${CLASSNAME}__web`}>
						{this._renderIcon(WEB_OUTLINE_24)}
						<a
							href={this._getWebWithUtm(web)}
							className={`${CLASSNAME}__web-link`}
							data-dot='go-to-client-site'
							target='_blank'
							rel='nofollow noopener noreferrer'
							onClick={this._handleClickWeb}
						>
							{web}
						</a>
					</div>
				)
			}
		} else {
			return this._renderPlaceholder('web')
		}
	}
	/**
	 * Vrátí url webu doplněnou o UTM parametry.
	 * @private
	 * @param {String} web Url webu
	 * @return {String} Vrátí textový řetězec, URL webu, který je doplňen o příslušné UTM parametry.
	 */
	_getWebWithUtm(web) {
		const UTM_SOURCE = 'utm_source=sauto'
		const UTM_MEDIUM_ADVERT = 'utm_medium=detail_inzeratu'
		const UTM_MEDIUM_SELLER = 'utm_medium=detail_prodejce'

		return `${web}${web.split('/').pop().includes('?') ? '&' : '?'}${UTM_SOURCE}&${
			this._isAdvertDetail() ? UTM_MEDIUM_ADVERT : UTM_MEDIUM_SELLER
		}`
	}

	_getPhone() {
		const { advertEntity = {} } = this.props
		return advertEntity.phone
	}

	_handleClickOnShowPhoneButton() {
		this.setState({
			isPhoneShown: true
		})
		this._sendAnalyticsDataOnViewingPhoneNumber()
		this._sendContactSellerAnalyticsData('ShowNumber')
	}

	_handleClickOnShowEmailButton() {
		this.setState({
			isEmailShown: true
		})
		this._sendAnalyticsDataOnViewingEmail()
		this._sendContactSellerAnalyticsData('ShowEmail')
	}

	_handleClickOnCallButton() {
		this._sendAnalyticsDataOnClickCallButton()
		this._sendContactSellerAnalyticsData('Call')
	}

	_handleClickOnEmail() {
		this._sendAnalyticsDataOnViewingEmail()
	}

	_handleClickWeb() {
		this._sendContactSellerAnalyticsData('VisitWebsite')
	}

	_handleClickMap() {
		this._sendContactSellerAnalyticsData('GoMapy')
	}

	_sendAnalyticsDataOnClickCallButton() {
		const { advertEntity } = this.props
		const { AnalyticsDataSender } = this.utils

		AnalyticsDataSender.hitForPhoneNumber(advertEntity, this._getRouteName())
	}

	_sendAnalyticsDataOnViewingPhoneNumber() {
		const { advertEntity } = this.props
		const { AnalyticsDataSender } = this.utils

		AnalyticsDataSender.hitForShowPhoneNumber(advertEntity, this._getRouteName())
	}

	_sendAnalyticsDataOnViewingEmail() {
		const { advertEntity } = this.props
		const { AnalyticsDataSender } = this.utils

		AnalyticsDataSender.hitForShowEmail(advertEntity, this._getRouteName())
	}

	_sendContactSellerAnalyticsData(type) {
		const { getLocationOnPage } = this.props
		const { AnalyticsDataSender } = this.utils

		AnalyticsDataSender.hitContactSeller({
			type,
			page: this._getRouteName(),
			location: getLocationOnPage() || SellerCard.LOCATION_ON_PAGE.STATIC
		})
	}

	/**
	 * Vrati ci je sa jedna o firmu
	 *
	 */
	_isPremiseSeller() {
		const { premiseEntity, isPremiseSeller } = this.props

		// na detailu prodejce vieme ze sa jedna o firmu a kvoli placeholderom bol pridany prop isPremiseSeller
		return isPremiseSeller || !!premiseEntity.id
	}

	/**
	 * Vrati ci sme na detailu inzeratu
	 */
	_isAdvertDetail() {
		const { advertEntity = {} } = this.props

		return !!advertEntity.status
	}

	_isDataLoaded() {
		const { premiseEntity } = this.props

		return !!premiseEntity.id
	}
}
