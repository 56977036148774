
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['PublishModal'] = {
  title: function(d) { return "Zveřejnění zablokovaného inzerátu"; },
  text: function(d) { return "Chystáte se zveřejnit inzerát, který byl zablokovaný administrátorem. Pro opětovné zvěřejnění je potřeba inzerát upravit dle pokynů zaslaných na Váš email."; },
  close: function(d) { return "Zavřít"; },
  edit: function(d) { return "Editovat"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	