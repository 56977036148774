import range from 'app/helpers/range/Range'

const currentYear = new Date().getFullYear()

export const DROPDOWN_DEFAULT_YEARS_VALUES = [1960, 1970, 1980, 1985, ...range(1990, currentYear)]
	.sort((yearA, yearB) => yearB - yearA) // seradime sestupne dle roku
	.map((year, index) => ({ id: index + 1, value: year })) // dogenerujeme idecka

export const DROPDOWN_DEFAULT_PRICE_VALUES = [
	{ id: 1, value: 15000 },
	{ id: 2, value: 30000 },
	{ id: 3, value: 50000 },
	{ id: 4, value: 75000 },
	{ id: 5, value: 100000 },
	{ id: 6, value: 150000 },
	{ id: 7, value: 200000 },
	{ id: 8, value: 250000 },
	{ id: 9, value: 300000 },
	{ id: 10, value: 350000 },
	{ id: 11, value: 400000 },
	{ id: 12, value: 500000 },
	{ id: 13, value: 750000 },
	{ id: 14, value: 1000000 },
	{ id: 15, value: 2000000 },
	{ id: 16, value: 4000000 }
]

export const DROPDOWN_DEFAULT_TACHOMETER_VALUES = [
	{ id: 1, value: 5000 },
	{ id: 2, value: 15000 },
	{ id: 3, value: 30000 },
	{ id: 4, value: 50000 },
	{ id: 5, value: 75000 },
	{ id: 6, value: 100000 },
	{ id: 7, value: 125000 },
	{ id: 8, value: 150000 },
	{ id: 9, value: 200000 },
	{ id: 10, value: 300000 }
]

export const DROPDOWN_DEFAULT_PERFORMANCE_VALUES = [
	{ id: 1, value: 40 },
	{ id: 2, value: 50 },
	{ id: 3, value: 60 },
	{ id: 4, value: 70 },
	{ id: 5, value: 80 },
	{ id: 6, value: 90 },
	{ id: 7, value: 100 },
	{ id: 8, value: 125 },
	{ id: 9, value: 150 },
	{ id: 10, value: 175 },
	{ id: 11, value: 200 },
	{ id: 12, value: 250 },
	{ id: 13, value: 300 }
]

export const DROPDOWN_DEFAULT_OPERATING_LEASE_ANNUAL_DISTANCE_VALUES = [
	{ id: 1, value: 10000 },
	{ id: 2, value: 12000 },
	{ id: 3, value: 15000 },
	{ id: 4, value: 20000 },
	{ id: 5, value: 25000 },
	{ id: 6, value: 30000 },
	{ id: 7, value: 35000 },
	{ id: 8, value: 40000 },
	{ id: 9, value: 50000 }
]

export const DROPDOWN_DEFAULT_OPERATING_LEASE_PERIOD_VALUES = [
	{ id: 1, value: 3 },
	{ id: 2, value: 6 },
	{ id: 3, value: 12 },
	{ id: 4, value: 18 },
	{ id: 5, value: 24 },
	{ id: 6, value: 36 },
	{ id: 7, value: 48 },
	{ id: 8, value: 60 }
]
