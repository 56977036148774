
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['AdvertStats'] = {
  detailView: function(d) { return "Počet zobrazení inzerátu"; },
  phoneView: function(d) { return "Počet odkrytí telefonního čísla"; },
  replySent: function(d) { return "Počet odeslaných odpovědí"; },
  favorite: function(d) { return "Počet přidání do oblíbených"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	