import React, { useContext, useState } from 'react'
import {
	IMAGE,
	BOOK,
	EDIT_FILLED,
	HISTORY,
	TRASH_FILLED,
	WARNING_FILLED,
	CLOSE_OUTLINE,
	CHECKER_NORMAL,
	EXTERNAL_LINK_OUTLINE,
	REFRESH,
	RENEW_VERTICAL_OUTLINE_16,
	BOOKMARK_ADD_ALT1_FILLED_24,
	VISIBILITY_FILLED
} from '@sznds/icons'
import { Format, DateHelpers, DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import { useFire, useLocalize } from 'app/base/componentHelpers'
import { getYesterday } from 'app/helpers/date/dateHelpers'
import { Layout } from 'app/base/internalAdmin/InternalAdminViewHelpers'
import EntitiesTable from 'app/component/entitiesTable/EntitiesTable'
import EntitiesFilter from 'app/component/entitiesFilter/EntitiesFilter'
import ActionButton from 'app/component/actionButton/ActionButton'
import TableHeadSorter from 'app/component/tableHeadSorter/TableHeadSorter'
import TableHead from 'app/component/tableHead/TableHead'
import StateMark, { TYPE } from 'app/component/stateMark/StateMark'
import { STATUS_CONSTANTS } from 'app/model/advert/AdvertConstants'
import Context from 'ima/page/context'
import AdminEntityContext from 'app/component/managedRootView/AdminEntityContext'
import { ADMIN_RIGHTS } from 'app/base/Constants'
import IAAdvertListController, { getCanBeSelectedEntitiesTopped } from './IAAdvertListController'
import InternalAdminUrlConvertor from 'app/helpers/urlConvertor/InternalAdminUrlConvertor'
import ROUTE_NAMES from 'app/base/RouteNames'
import AdvertDetailUrlConvertor from 'app/page/userweb/advertDetail/AdvertDetailUrlConvertor'
import PropTypes from 'prop-types'
import { PaginationEntity } from '@inzeraty/models'
import * as FormLines from '@inzeraty/form-lines'
import { DEAL_TYPE } from 'app/model/advert/AdvertConstants'
import AdvertPropTypes from 'app/model/advert/AdvertPropTypes'
import { PREFERRED_OFFER } from 'app/base/IconsConstants'
import ClientStatisticsUrlConvertor from 'app/page/userweb/clientStatistics/ClientStatisticsUrlConvertor'
import { filterBy, filterByValue } from 'app/component/entitiesFilter/EntitiesFilter'
import getAdvertState, { ADVERT_STATE } from 'app/page/userweb/clientAdvertList/getAdvertState'
import ToppedModal from 'app/page/userweb/clientAdvertList/toppedModal/ToppedModal'
import isNullOrUndefined from 'app/helpers/isNullOrUndefined/IsNullOrUndefined'
import ForbidAdvertPopup from '../components/forbidAdvertPopup/ForbidAdvertPopup'
import ExportButton from 'app/page/internalAdmin/components/exportButton/ExportButton'
import IAStatisticsController from 'app/page/internalAdmin/statistics/IAStatisticsController'

import './IAAdvertListView.less'
import 'app/base/BaseCS.json'

const CLASSNAME = 'p-ia-advert-list'

const { ACTIONS } = IAAdvertListController
const { ADVERT_ID } = ClientStatisticsUrlConvertor.constants.URL_API_PARAMS

export default function IAAdvertListView({
	entitiesAndPagination = DEFAULT_PROPS.OBJECT,
	filterFormLineEntities = DEFAULT_PROPS.ARRAY,
	isFilterLoading,
	isLoading
}) {
	const { $Router, InternalAdminHelper } = useContext(Context)
	const { rights = [], roleNameId = '' } = useContext(AdminEntityContext) || {}
	const fire = useFire()
	const localize = useLocalize()
	const { paginationEntity: { total } = {} } = entitiesAndPagination
	const { params: currentRouteParams = {} } = $Router.getCurrentRouteInfo()
	const isOperatingLease = currentRouteParams.deal_type?.includes(DEAL_TYPE.OPERATING_LEASE)

	const [toppedModalData, setToppedModalData] = useState({ isOpen: false })
	const openToppedModal = (entities) => {
		setToppedModalData({
			isOpen: true,
			entityIds: entities.map((entity) => entity.id)
		})
	}

	const action = (action, entities = []) => {
		fire('action', {
			action,
			entities
		})
	}

	const getStatus = (advertEntity) => {
		const { preferredOffer, topped } = advertEntity

		let textAddition = preferredOffer ? ', Pv' : ''
		textAddition += topped ? ', Topováno' : ''

		const STATUSES = {
			[ADVERT_STATE.DRAFT]: { text: 'Rozpracovaný', type: TYPE.WARN },
			[ADVERT_STATE.ACTIVE]: { text: 'Aktivní', type: TYPE.OK },
			[ADVERT_STATE.INACTIVE]: { text: 'Neaktivní', type: TYPE.WARN },
			[ADVERT_STATE.INACTIVE_EXPIRED]: { text: 'Expirovaný', type: TYPE.WARN },
			[ADVERT_STATE.INACTIVE_INSUFFICIENT_IMAGES]: {
				text: 'Nesplňuje min. počet fotek',
				type: TYPE.WARN
			},
			[ADVERT_STATE.INACTIVE_NOT_PAID]: { text: 'Nezaplacený', type: TYPE.WARN },
			[ADVERT_STATE.INACTIVE_INSUFFICIENT_MODULES]: {
				text: 'Překročen limit slotů',
				type: TYPE.WARN
			},
			[ADVERT_STATE.INACTIVE_USER_DEACTIVATED]: {
				text: 'Zneaktivněný uživatelem',
				type: TYPE.WARN
			},
			[ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED]: {
				text: 'Zneaktivněný administrátorem',
				type: TYPE.WARN
			},
			[ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED]: {
				text: 'Zneaktivněný s banem',
				type: TYPE.WARN
			},
			[ADVERT_STATE.INACTIVE_VIN_DUPLICATION]: {
				text: 'Duplicitní VIN',
				type: TYPE.WARN
			},
			[ADVERT_STATE.INACTIVE_VIN_DUPLICATION_FRAUD]: {
				text: 'Opakované vkládání VIN',
				type: TYPE.WARN
			},
			[ADVERT_STATE.DISABLED]: { text: 'Zakázaný administrátorem', type: TYPE.ERROR },
			[ADVERT_STATE.DISABLED_REJECTED]: { text: 'Zakázaný s banem', type: TYPE.ERROR },
			[ADVERT_STATE.DELETED]: { text: 'Smazaný', type: TYPE.ERROR }
		}

		const status = STATUSES[getAdvertState(advertEntity)]

		return status && <StateMark type={status.type}>{status.text + textAddition}</StateMark>
	}

	const getMassActions = (selectedIds = new Set([])) => {
		const actions = []

		if (rights.includes(ADMIN_RIGHTS.ADVERTS_EDIT)) {
			const { entities = [] } = entitiesAndPagination || {}

			const selectedEntities = entities.filter((entity) => selectedIds.has(entity.id))

			actions.push(
				{
					type: ACTIONS.ACTIVATE,
					text: 'Aktivovat',
					action: (entities = []) => action(ACTIONS.ACTIVATE, entities)
				},
				{
					type: ACTIONS.DEACTIVATE,
					text: 'Zneaktivnit',
					action: (entities = []) => action(ACTIONS.DEACTIVATE, entities)
				},
				{
					type: ACTIONS.DELETE,
					text: 'Smazat',
					action: (entities = []) => action(ACTIONS.DELETE, entities)
				},
				{
					type: ACTIONS.ADD_TO_PO,
					text: 'Přidat do Pv',
					action: (entities = []) => action(ACTIONS.ADD_TO_PO, entities)
				},
				{
					type: ACTIONS.REMOVE_FROM_PO,
					text: 'Odebrat z Pv',
					action: (entities = []) => action(ACTIONS.REMOVE_FROM_PO, entities)
				}
			)

			if (selectedEntities.count === 0 || getCanBeSelectedEntitiesTopped(selectedEntities)) {
				actions.push({
					type: ACTIONS.TOPPED_ADVERT,
					text: 'Topovat',
					action: (entities = []) => openToppedModal(entities)
				})
			}

			if (
				selectedEntities.count === 0 ||
				selectedEntities.every((entity) => entity.status === STATUS_CONSTANTS.DELETED)
			) {
				actions.push({
					type: ACTIONS.RESTORE,
					text: 'Obnovit',
					action: (entities = []) => action(ACTIONS.RESTORE, entities)
				})
			}

			if (
				selectedEntities.count === 0 ||
				selectedEntities.every((entity) => entity.status === STATUS_CONSTANTS.DISABLED)
			) {
				actions.push({
					type: ACTIONS.ALLOW,
					text: 'Povolit',
					action: (entities = []) => action(ACTIONS.ALLOW, entities)
				})
			}
		}

		return actions
	}

	const getActions = () => {
		if (rights.includes(ADMIN_RIGHTS.ADVERTS_EDIT)) {
			return [
				({ id }, actionButtonProps = {}) => (
					<ActionButton
						icon={EDIT_FILLED}
						key='editovat'
						title='Editovat'
						href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_BASIC_INFO, {
							[InternalAdminUrlConvertor.constants.ADVERT_ID]: id
						})}
						{...actionButtonProps}
					/>
				),
				(entity, actionButtonProps = {}) => {
					const premiseId = entity.premise?.id
					const isPremise = !isNullOrUndefined(premiseId)
					const ROUTE = isPremise
						? ROUTE_NAMES.INTERNAL_ADMIN.PREMISE_EDIT
						: ROUTE_NAMES.INTERNAL_ADMIN.USER_DETAIL
					const PARAM = {
						[isPremise
							? InternalAdminUrlConvertor.constants.PREMISE_EDIT_ID
							: InternalAdminUrlConvertor.constants.USER_EDIT_ID]: premiseId ?? entity.user.id
					}
					return (
						<ActionButton
							icon={VISIBILITY_FILLED}
							key='detail'
							title='Detail prodejce'
							href={$Router.link(ROUTE, PARAM)}
							{...actionButtonProps}
						/>
					)
				},
				(entity, actionButtonProps = {}) =>
					entity.status !== STATUS_CONSTANTS.DRAFT && (
						<ActionButton
							icon={IMAGE}
							key='fotogalerie'
							title='Fotogalerie'
							href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_EDIT_PHOTOS, {
								[InternalAdminUrlConvertor.constants.ADVERT_ID]: entity.id
							})}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) => {
					const createDateString = Format.dateEN(entity.createDate, true)

					const linkUrlParamsToStatistics = Object.assign(
						{
							[InternalAdminUrlConvertor.constants.FILTER_BY]: 'advert_id',
							[InternalAdminUrlConvertor.constants.FILTER_BY_VALUE]: entity.id,
							[InternalAdminUrlConvertor.constants.GROUP_BY]: 'advert_id',
							[InternalAdminUrlConvertor.constants.DATE_FROM]: createDateString,
							[InternalAdminUrlConvertor.constants.DEAL_TYPE]: isOperatingLease
								? IAStatisticsController.DEAL_TYPE.OPERATING_LEASE.key
								: IAStatisticsController.DEAL_TYPE.SALES.key
						},
						// defaultne je na strance se statistikama vybrano 'Datum do' jako vcerejsek.
						// Je to z duvodu rychlosti, kdy volani statistik pro dnesek je pomale.
						// Akorat v pripade inzeratu, ktere byly vytvoreny dnes, musime nastavit i
						// vlastni 'Datum do', jinak by bylo 'Datum od' vetsi jako 'Datum do'.
						DateHelpers.compareDates(entity.createDate, getYesterday()) === 1 && {
							[InternalAdminUrlConvertor.constants.DATE_TO]: createDateString
						}
					)

					const isStatisticAccessible =
						DateHelpers.compareDates(entity.createDate, new Date()) === -1

					return (
						entity.status !== STATUS_CONSTANTS.DRAFT && (
							<ActionButton
								icon={BOOK}
								key='statistiky'
								title={
									isStatisticAccessible ? 'Statistiky' : 'Statistiky pro dnešní den nejsou dostupné'
								}
								href={$Router.link(
									ROUTE_NAMES.INTERNAL_ADMIN.STATISTICS,
									linkUrlParamsToStatistics
								)}
								disabled={!isStatisticAccessible}
								{...actionButtonProps}
							/>
						)
					)
				},
				(entity, actionButtonProps = {}) =>
					entity.status !== STATUS_CONSTANTS.DRAFT && (
						<ActionButton
							icon={HISTORY}
							key='historie'
							title='Historie změn'
							href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_HISTORY, {
								[InternalAdminUrlConvertor.constants.ADVERT_ID]: entity.id
							})}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) => {
					const { preferredOffer } = entity
					const actionId = preferredOffer ? ACTIONS.REMOVE_FROM_PO : ACTIONS.ADD_TO_PO

					const classNameValue = preferredOffer
						? `${CLASSNAME}__preferred-offer--success`
						: `${CLASSNAME}__preferred-offer`

					return (
						entity.status !== STATUS_CONSTANTS.DRAFT && (
							<ActionButton
								icon={PREFERRED_OFFER}
								key='prednostni_vypis'
								title={
									preferredOffer ? 'Odebrat z přednostního výpisu' : 'Přidat do přednostního výpisu'
								}
								onClick={() => action(actionId, [entity])}
								className={classNameValue}
								{...actionButtonProps}
							/>
						)
					)
				},
				(entity, actionButtonProps = {}) =>
					entity.status === STATUS_CONSTANTS.ACTIVE &&
					entity.premise?.id && (
						<ActionButton
							icon={BOOKMARK_ADD_ALT1_FILLED_24}
							key='topovat'
							title='Topovat inzerát'
							onClick={() => openToppedModal([entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					[
						ADVERT_STATE.INACTIVE_USER_DEACTIVATED,
						ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED,
						ADVERT_STATE.INACTIVE_ADMIN_DEACTIVATED_REJECTED,
						ADVERT_STATE.INACTIVE,
						rights.includes(ADMIN_RIGHTS.ADVERTS_VIN_DUPLICATION_FRAUD_EDIT) &&
							ADVERT_STATE.INACTIVE_VIN_DUPLICATION_FRAUD
					].includes(getAdvertState(entity)) && (
						<ActionButton
							icon={CHECKER_NORMAL}
							key='aktivovat'
							title='Aktivovat'
							onClick={() => action(ACTIONS.ACTIVATE, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					entity.status === STATUS_CONSTANTS.ACTIVE && (
						<ActionButton
							icon={CLOSE_OUTLINE}
							key='zneaktivnit'
							title='Zneaktivnit'
							onClick={() => action(ACTIONS.DEACTIVATE, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					entity.status === STATUS_CONSTANTS.ACTIVE && (
						<ActionButton
							icon={WARNING_FILLED}
							key='zakazat'
							title='Zakázat inzerát'
							onClick={() => action(ACTIONS.FORBID, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					entity.status !== STATUS_CONSTANTS.DELETED && (
						<ActionButton
							icon={TRASH_FILLED}
							key='smazat'
							title='Smazat'
							onClick={() => action(ACTIONS.DELETE, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					entity.status === STATUS_CONSTANTS.DISABLED && (
						<ActionButton
							icon={REFRESH}
							key='povolit'
							title='Povolit'
							onClick={() => action(ACTIONS.ALLOW, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) =>
					entity.status === STATUS_CONSTANTS.DELETED && (
						<ActionButton
							icon={RENEW_VERTICAL_OUTLINE_16}
							key='obnovit'
							title='Obnovit'
							onClick={() => action(ACTIONS.RESTORE, [entity])}
							{...actionButtonProps}
						/>
					),
				(entity, actionButtonProps = {}) => {
					const { id } = entity

					const routeParams = {
						[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: id
					}

					return (
						entity.status !== STATUS_CONSTANTS.DRAFT && (
							<ActionButton
								icon={EXTERNAL_LINK_OUTLINE}
								key='nahled'
								title='Náhled inzerátu'
								target='_blank'
								rel='noopener noreferrer'
								href={$Router.link(
									isOperatingLease
										? ROUTE_NAMES.INTERNAL_ADMIN.OPERATING_LEASE_PREVIEW
										: ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_PREVIEW,
									routeParams
								)}
								{...actionButtonProps}
							/>
						)
					)
				}
			]
		} else {
			return []
		}
	}

	const getFormDataOnSubmit = (formLineEntities) => {
		const formData = FormLines.getFormData(formLineEntities)

		// select pro Duvod deaktivace nastavuje nejen url parametr deactivation_reason, ale
		// muze nastavovat i approval_admin
		const [reason, ...approval] = (formData.deactivation_reason || '').split(',')

		return Object.assign(formData, {
			deactivation_reason: reason,
			approval_admin: approval && approval.length ? approval : undefined
		})
	}

	return (
		<Layout title='Výpis inzerátů'>
			<EntitiesFilter
				formLineEntities={filterFormLineEntities}
				isFilterLoading={isFilterLoading}
				getFormDataOnSubmit={getFormDataOnSubmit}
			/>

			{total < 10000 && (
				<ExportButton
					onClick={() => {
						fire('exportAdverts')
					}}
					buttonText='Exportovat inzeráty'
				/>
			)}

			<EntitiesTable
				entitiesAndPagination={entitiesAndPagination}
				getMassActions={getMassActions}
				headCells={[
					<TableHead key='state'>Stav</TableHead>,
					<TableHeadSorter key='id' sortParam='id' isDefaultDescending={true} alignRight={true}>
						ID
					</TableHeadSorter>,
					<TableHead key='category'>Druh</TableHead>,
					<TableHead key='manufacturer'>Výrobce a model</TableHead>,
					<TableHeadSorter key='price' sortParam='price' alignRight={true}>
						Cena
					</TableHeadSorter>,
					<TableHeadSorter key='create_date' sortParam='create_date'>
						Datum vložení
					</TableHeadSorter>,
					<TableHead key='edit_date'>Datum editace</TableHead>,
					<TableHead key='source'>Zadáno</TableHead>,
					<TableHead key='reported'>Závadný</TableHead>
				]}
				rowCells={[
					(advertEntity) => getStatus(advertEntity),
					({ id, status, oldId, category, manufacturerCb, modelCb }) => {
						let displayId
						if (status === STATUS_CONSTANTS.ACTIVE) {
							displayId = (
								<a
									key={`web-${id}`}
									target='_blank'
									rel='noopener noreferrer'
									href={$Router.link(
										isOperatingLease
											? ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL
											: ROUTE_NAMES.USERWEB.ADVERT_DETAIL,
										{
											[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.ADVERT_ID]: oldId || id,
											[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.CATEGORY_NAME]:
												category.seoName,
											[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.BRAND_NAME]:
												manufacturerCb.seoName,
											[AdvertDetailUrlConvertor.constants.URL_APP_PARAMS.MODEL_NAME]:
												modelCb.seoName
										}
									)}
								>
									{id}
								</a>
							)
						} else {
							displayId = id
						}
						return [displayId, { isNumber: true }]
					},
					({ category = {} }) => category.name,
					({ manufacturerCb = {}, modelCb = {} }) => `${manufacturerCb.name} ${modelCb.name}`,
					({ price, operatingLeasePriceWithoutVat }) => {
						const priceText = price
							? `${Format.number(price)} ${localize('Base.CZK')}`
							: 'neuvedeno'
						const operatingLeasePriceText = operatingLeasePriceWithoutVat
							? `od ${Format.number(
									operatingLeasePriceWithoutVat
							  )} ${localize('Base.pricePerMonthWithoutVAT', { CURRENCY: localize('Base.CZK') })}`
							: 'neuvedeno'
						return [isOperatingLease ? operatingLeasePriceText : priceText, { isNumber: true }]
					},
					({ createDate }) => Format.dateTime(createDate),
					({ editDate }) => Format.dateTime(editDate),
					({ source }) => IAAdvertListController.SOURCE[source],
					({ id, advertReportsCount }) => {
						const isLink = !InternalAdminHelper.getIsRouteForbidden(
							ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST_REPORTED,
							roleNameId
						)

						const count = isLink ? (
							<a
								key={id}
								href={$Router.link(ROUTE_NAMES.INTERNAL_ADMIN.ADVERT_LIST_REPORTED, {
									[filterBy]: ADVERT_ID,
									[filterByValue]: id
								})}
							>
								{advertReportsCount}
							</a>
						) : (
							advertReportsCount
						)
						return [count, { isNumber: true }]
					}
				]}
				actions={getActions()}
				isLoading={isLoading}
			/>

			<ToppedModal
				isOpen={toppedModalData.isOpen}
				onClose={() => setToppedModalData({ isOpen: false })}
				advertIds={toppedModalData?.entityIds}
			/>

			<ForbidAdvertPopup />
		</Layout>
	)
}

IAAdvertListView.propTypes = {
	entitiesAndPagination: PropTypes.shape({
		entities: PropTypes.arrayOf(PropTypes.shape(AdvertPropTypes)),
		paginationEntity: PropTypes.instanceOf(PaginationEntity)
	}),
	filterFormLineEntities: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	isFilterLoading: PropTypes.bool,
	isLoading: PropTypes.bool
}
