import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import { Textarea } from '@sznds/react'
import BasicCompWidget from '../basic/BasicCompWidget'

import './TextareaCompWidget.less'

export const TEXTAREA_WIDGET_ID = 'textarea_id'

const CLASSNAME = 'c-textarea-comp-widget'

const TextareaCompWidget = ({
	formLineEntity = DEFAULT_PROPS.OBJECT,
	onChange = DEFAULT_PROPS.FUNCTION
}) => {
	return (
		<BasicCompWidget
			className={CLASSNAME}
			formLineEntity={formLineEntity}
			name={TEXTAREA_WIDGET_ID}
			renderInput={(formLineEntity, inputProps) => {
				const {
					id,
					placeholder = '',
					disabled = false,
					value,
					required,
					errorMessage,
					extra: { limit } = {}
				} = formLineEntity

				return (
					<div className={`${CLASSNAME}__input-wrapper`}>
						<Textarea
							placeholder={placeholder}
							disabled={disabled}
							value={value}
							required={required}
							error={!!errorMessage}
							maxLength={limit}
							{...inputProps}
							className={`${CLASSNAME}__input`}
							onChange={(event) => {
								const value = event.target.value

								onChange({
									id,
									value,
									errorMessage: ''
								})
							}}
						/>
					</div>
				)
			}}
		/>
	)
}

TextareaCompWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired
}

export default React.memo(TextareaCompWidget)
