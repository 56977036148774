import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import * as FormLines from '@inzeraty/form-lines'
import { InputSurface } from '@sznds/react'
import CountDown from 'app/component/formLineWidgets/component/countDown/CountDown'

import './TextareaWidget.less'

export const TEXTAREA_WIDGET_ID = 'textarea_id'

const CLASSNAME = 'c-textarea-widget'

const TextareaWidget = (props) => {
	const { formLineEntity = DEFAULT_PROPS.OBJECT, onChange = DEFAULT_PROPS.FUNCTION } = props

	const {
		id,
		placeholder = '',
		disabled = false,
		value,
		required,
		errorMessage,
		extra: { limit } = {}
	} = formLineEntity

	const restProps = Object.assign({}, props)

	const [focused, setFocused] = useState(false)

	delete restProps.formLineEntity
	delete restProps.onChange

	return (
		<InputSurface
			error={!!errorMessage}
			focused={focused}
			className={classnames({
				[CLASSNAME]: true,
				[props.className]: !!props.className
			})}
		>
			<textarea
				placeholder={placeholder}
				disabled={disabled}
				value={value}
				required={required}
				rows='6'
				maxLength={limit}
				{...restProps}
				className={`${CLASSNAME}__textarea`}
				onChange={(event) => {
					const value = event.target.value

					onChange({
						id,
						value,
						errorMessage: ''
					})
				}}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
			<CountDown className={`${CLASSNAME}__count-down`} value={value} limit={limit} />
		</InputSurface>
	)
}

TextareaWidget.propTypes = {
	formLineEntity: PropTypes.instanceOf(FormLines.Entity).isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string
}

export default TextareaWidget
