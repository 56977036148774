import {
	createEntityList,
	updateEntities,
	getFormData,
	validate,
	isSomeError
} from '@inzeraty/form-lines'
import AbstractExtension from 'ima/extension/AbstractExtension'
import { nonValue } from 'app/component/entitiesFilter/EntitiesFilter'

export default class EntityFormExtension extends AbstractExtension {
	constructor() {
		super()
	}

	load(state = {}) {
		return {
			...state,
			isWaiting: false,
			formLineEntities: this._getFormLineEntities(),
			entityFormError: undefined
		}
	}

	static get STATE_KEYS() {
		return Object.freeze({
			IS_WAITING: 'isWaiting',
			FORM_LINE_ENTITIES: 'formLineEntities',
			ENTITY_FORM_ERROR: 'entityFormError'
		})
	}

	getAllowedStateKeys() {
		return [
			EntityFormExtension.STATE_KEYS.IS_WAITING,
			EntityFormExtension.STATE_KEYS.FORM_LINE_ENTITIES,
			EntityFormExtension.STATE_KEYS.ENTITY_FORM_ERROR
		]
	}

	initialize({
		getFormLineEntitiesData,
		getSubmitPromise,
		validations = () => {},
		handleSubmitSuccess,
		handleSubmitError,
		onEntityFormChangeCallback
	}) {
		this._getFormLineEntitiesData = getFormLineEntitiesData
		this._getSubmitPromise = getSubmitPromise
		this._validations = validations
		this._handleSubmitSuccess = handleSubmitSuccess
		this._handleSubmitError = handleSubmitError
		this._onEntityFormChangeCallback = onEntityFormChangeCallback
	}

	setFormLineEntitiesData(formLineEntitiesData) {
		this.setState({
			formLineEntities: createEntityList(formLineEntitiesData)
		})
	}

	onEntityFormChange(data) {
		if (this._onEntityFormChangeCallback) {
			this._onEntityFormChangeCallback(data)
		} else {
			const { formLineEntities } = this.getState()
			const updatedFilterFormLineEntities = updateEntities(formLineEntities, [data])

			this.setState({
				formLineEntities: updatedFilterFormLineEntities
			})
		}
	}

	onEntityFormSubmit() {
		const { formLineEntities } = this.getState()

		const validations = validate(formLineEntities, this._validations())

		this.setState({
			formLineEntities: updateEntities(formLineEntities, validations),
			entityFormError: undefined
		})

		if (!isSomeError(validations)) {
			this.setState({
				isWaiting: true
			})

			const formData = getFormData(formLineEntities)

			Object.keys(formData).forEach((key) => {
				if (formData[key] === nonValue) {
					formData[key] = null
				}
			})

			this._getSubmitPromise(formData)
				.then((...args) => {
					this.setState({
						isWaiting: false,
						entityFormError: undefined
					})

					this._handleSubmitSuccess(...args)
				})
				.catch((error) => {
					const entitiesAndFormError = this._handleSubmitError(error, formLineEntities, formData)

					this.setState(
						Object.assign(
							{
								isWaiting: false
							},
							entitiesAndFormError
						)
					)
				})
		}
	}

	async _getFormLineEntities() {
		const formLineEntities =
			this._getFormLineEntitiesData && typeof this._getFormLineEntitiesData === 'function'
				? await this._getFormLineEntitiesData()
				: []

		return createEntityList(formLineEntities)
	}
}
