import UserService from 'app/model/user/UserService'
import { IDS } from 'app/component/internalAdminWidgets/widgets/WidgetIds'
import { Validation } from '@inzeraty/helpers'
import EntityFormExtension from 'app/component/entityForm/EntityFormExtension'
import { SHOW_NEW_TOAST_MESSAGE_EVENT } from 'app/component/toastMessages/ToastsHooks'
import { StatusMessage } from '@inzeraty/components'
import { updateEntities } from '@inzeraty/form-lines'

import 'app/base/ActionMessageCS.json'

export default class ForbidUserPopupExtension extends EntityFormExtension {
	constructor(userService, advertReportsService, utils) {
		super()
		this._userService = userService
		this._advertReportsService = advertReportsService
		this._utils = utils
	}

	static get STATE_KEYS() {
		return {
			...EntityFormExtension.STATE_KEYS,
			IS_USER_FORBID_OPENED: 'isUserForbidOpened',
			USER_ENTITY: 'forbidUserEntity'
		}
	}

	getAllowedStateKeys() {
		return [
			...super.getAllowedStateKeys(),
			ForbidUserPopupExtension.STATE_KEYS.IS_USER_FORBID_OPENED,
			ForbidUserPopupExtension.STATE_KEYS.USER_ENTITY
		]
	}

	init() {
		this.initialize({
			getSubmitPromise: this._getFormSubmitPromise.bind(this),
			validations: this._userFormValidations.bind(this),
			handleSubmitSuccess: this._handleUserFormSubmitSuccess.bind(this),
			handleSubmitError: this._handleUserFormSubmitError.bind(this)
		})
	}

	load() {
		return super.load({
			[ForbidUserPopupExtension.STATE_KEYS.IS_USER_FORBID_OPENED]: false,
			[ForbidUserPopupExtension.STATE_KEYS.USER_ENTITY]: null
		})
	}

	setSuccessHandler(callBack) {
		this._successHandler = callBack
	}

	onCloseUserPopup() {
		this.setState({
			[ForbidUserPopupExtension.STATE_KEYS.IS_USER_FORBID_OPENED]: false,
			[ForbidUserPopupExtension.STATE_KEYS.USER_ENTITY]: null,
			[EntityFormExtension.STATE_KEYS.ENTITY_FORM_ERROR]: undefined
		})
		this.setFormLineEntitiesData([])
		this._advertId = null
		this._userEntity = null
	}

	async openPopup(advertId, userId) {
		this.setState({
			[ForbidUserPopupExtension.STATE_KEYS.IS_USER_FORBID_OPENED]: true,
			[ForbidUserPopupExtension.STATE_KEYS.USER_ENTITY]: null,
			[EntityFormExtension.STATE_KEYS.IS_WAITING]: true
		})

		this._advertId = advertId
		this._userEntity = await this._userService.getUserDetail(userId)

		this.setState({
			[ForbidUserPopupExtension.STATE_KEYS.USER_ENTITY]: this._userEntity,
			[EntityFormExtension.STATE_KEYS.IS_WAITING]: false
		})

		this.setFormLineEntitiesData(this._getFormUserForbidData())
	}

	onSubmitUserForbid() {
		return this.onEntityFormSubmit()
	}

	_getFormUserForbidData() {
		const { enabled: userEnabled } = this._userEntity
		const { serviceEnabled } = UserService.getSautoServiceData(this._userEntity)

		const options = [
			{
				value: true,
				name: 'Povolený'
			},
			{
				value: false,
				name: 'Zakázaný'
			}
		]

		return [
			{
				id: 'enabled',
				label: 'Stav uživatele',
				value: userEnabled,
				widget: IDS.SELECT,
				options,
				required: true
			},
			{
				id: 'service_enabled',
				label: 'Stav služby',
				value: serviceEnabled,
				widget: IDS.SELECT,
				options,
				required: true
			},
			{
				id: 'action_note',
				label: 'Důvod změny stavu',
				widget: IDS.TEXTAREA,
				required: true
			}
		]
	}

	_userFormValidations() {
		return {
			action_note: ({ value }) => (Validation.isNotEmpty(value) ? '' : 'Popište důvod změny stavu')
		}
	}

	_getFormSubmitPromise(formData) {
		const { id } = this._userEntity

		return Promise.all([
			this._userService.saveUser(id, formData),
			this._advertReportsService.advertReportsProcessed(this._advertId)
		])
	}

	_handleUserFormSubmitSuccess() {
		const { login = '' } = this._userEntity

		this._successHandler().then(() => {
			this._utils.$Dispatcher.fire(SHOW_NEW_TOAST_MESSAGE_EVENT, {
				title: this._utils.$Dictionary.get('ActionMessage.titleSuccess'),
				type: StatusMessage.TYPE.SUCCESS,
				text: this._utils.$Dictionary.get('ActionMessage.forbidUserSuccess', { USER: login })
			})
		})

		this.onCloseUserPopup()
	}

	_handleUserFormSubmitError(error, formLineEntities, formData) {
		if (error && error.type && error.type === 'noteIsRequired') {
			return {
				formLineEntities: updateEntities(formLineEntities, [
					{
						id: 'note',
						errorMessage: 'Doplňte popis důvodu'
					}
				])
			}
		} else {
			return {
				[EntityFormExtension.STATE_KEYS.ENTITY_FORM_ERROR]: {
					title: 'Stav uživatele se nepodařilo změnit'
				}
			}
		}
	}
}
