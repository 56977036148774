export default class RibbonSearchDetectedFiltersEntity {
	constructor(data) {
		this.filterName = data.filterName
		this.isCodebook = data.isCodebook
		this.options = data.options
		this.valueFrom = data.valueFrom
		this.valueTo = data.valueTo
		this.value = data.value
		this.values = data.values
	}
}
