import React, { useEffect, useRef } from 'react'
import { Surface } from '@sznds/react'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import ShowItemsButton from 'app/component/showItemsButton/ShowItemsButton'
import Header from '../form/header/Header'
import Form from '../form/Form'
import PropTypes from 'prop-types'
import * as FormLines from '@inzeraty/form-lines'
import { CLASSNAME as PAGE_LOADER_CLASSNAME } from 'app/component/pageLoader/PageLoader'

import './SidePanel.less'

const CLASSNAME = 'c-client-side-panel'

const SidePanel = (props) => {
	const {
		premiseFiltersFormLines = DEFAULT_PROPS.ARRAY,
		changePremiseFiltersFormLines = DEFAULT_PROPS.FUNCTION,
		total,
		isLoadingTotal,
		onClose = DEFAULT_PROPS.FUNCTION,
		onOuterClick = DEFAULT_PROPS.FUNCTION
	} = props

	const sidePanelRef = useRef(null)

	useEffect(() => {
		if (window) {
			window.addEventListener('click', handleClick, true)

			return () => window.removeEventListener('click', handleClick, true)
		}
	})

	const handleClick = (event) => {
		// v KA je pouzit celostrankovy loader, pri kliku na nej nechceme, aby doslo k zavreni
		// postranniho panelu
		const isPageLoaderActive =
			event.target && event.target.classList?.contains(PAGE_LOADER_CLASSNAME)

		if (
			!isPageLoaderActive &&
			sidePanelRef.current &&
			!sidePanelRef.current.contains(event.target)
		) {
			onOuterClick()
		}
	}

	const handleReset = () => {
		changePremiseFiltersFormLines(
			premiseFiltersFormLines.map(({ id }) => ({ id, value: undefined }))
		)
	}

	return (
		<Surface className={CLASSNAME} ref={sidePanelRef}>
			<Header onClose={onClose} onReset={handleReset} />

			<Form
				premiseFiltersFormLines={premiseFiltersFormLines}
				changePremiseFiltersFormLines={changePremiseFiltersFormLines}
				renderSubmitButton={(renderTextForSubmitButton) => (
					<ShowItemsButton
						className={`${CLASSNAME}__submit`}
						count={total}
						isLoading={isLoadingTotal}
						showIcon={false}
						onClick={onClose}
						renderText={(renderDefaultLoadingDots) =>
							renderTextForSubmitButton(total, isLoadingTotal, renderDefaultLoadingDots)
						}
					/>
				)}
			/>
		</Surface>
	)
}

SidePanel.propTypes = {
	premiseFiltersFormLines: PropTypes.arrayOf(PropTypes.instanceOf(FormLines.Entity)),
	changePremiseFiltersFormLines: PropTypes.func,
	total: PropTypes.number,
	isLoadingTotal: PropTypes.bool,
	onClose: PropTypes.func,
	onOuterClick: PropTypes.func
}

export default React.memo(SidePanel)
