import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useRouter, useLocalize, useFire } from 'app/base/componentHelpers'
import ROUTE_NAMES from 'app/base/RouteNames'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import AdvertListUrlConvertor from 'app/page/userweb/advertList/AdvertListUrlConvertor'
import { isValidSortParam } from 'app/component/advertList/AdvertListExtension'
import { FILTER_CONTEXT } from 'app/component/filters/FiltersExtension'
import NewFeatureFlag from 'app/component/newFeatureFlag/NewFeatureFlag'

import './DealTypeSwitchCS.json'
import './DealTypeSwitch.less'

const CLASSNAME = 'c-deal-type-switch'

const SALES_SPECIFIC_FILTERS = [
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_FROM,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_TO,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.PRICE_OPTION_NAME
]

const OPERATING_LEASE_SPECIFIC_FILTERS = [
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_PRICE_WITHOUT_VAT_FROM,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_PRICE_WITHOUT_VAT_TO,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_ANNUAL_DISTANCE_FROM,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_ANNUAL_DISTANCE_TO,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_PERIOD_FROM,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_PERIOD_TO,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_SERVICES_NAME,
	AdvertListUrlConvertor.constants.URL_APP_PARAMS.OPERATING_LEASE_INTENDED_FOR_NAME
]

const getLink = (routeNameTo, currentRouteParams = {}) => {
	const deleteRouteParams = (routeParams = {}, routeParamsToDelete = []) => {
		const newRouteParams = Object.assign({}, routeParams)
		routeParamsToDelete.forEach((filterUrlParamName) => delete newRouteParams[filterUrlParamName])
		return newRouteParams
	}

	let newRouteParams = Object.assign({}, currentRouteParams)

	newRouteParams = deleteRouteParams(newRouteParams, [
		UrlConvertor.constants.URL_APP_PARAMS.PAGE,
		AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_ID,
		AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_HASH,
		AdvertListUrlConvertor.constants.URL_APP_PARAMS.MY_SAVED_SEARCH_TIMESTAMP
	])

	newRouteParams = deleteRouteParams(newRouteParams, SALES_SPECIFIC_FILTERS)
	newRouteParams = deleteRouteParams(newRouteParams, OPERATING_LEASE_SPECIFIC_FILTERS)

	if (!isValidSortParam(routeNameTo, newRouteParams[UrlConvertor.constants.URL_APP_PARAMS.SORT])) {
		newRouteParams = deleteRouteParams(newRouteParams, [UrlConvertor.constants.URL_APP_PARAMS.SORT])
	}

	return [routeNameTo, newRouteParams]
}

const SeoLink = ({ href = '', children }) => {
	const trimmedHref = href.split('?')[0]

	return (
		<a className={`${CLASSNAME}__hidden`} href={trimmedHref}>
			{children}
		</a>
	)
}

SeoLink.propTypes = {
	href: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const DealType = ({ isSelected, href, onClick, children }) => {
	const elementProps = isSelected ? {} : { onClick }

	return (
		<>
			<button
				className={classnames({
					[`${CLASSNAME}__type`]: true,
					[`${CLASSNAME}__type--selected`]: isSelected
				})}
				{...elementProps}
			>
				{children}
			</button>
			{!isSelected && <SeoLink href={href}>{children}</SeoLink>}
		</>
	)
}

DealType.propTypes = {
	isSelected: PropTypes.bool,
	href: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

const DealTypeSwitch = ({
	className,
	isOperatingLeaseSelected = false,
	salesHref,
	onSalesClick,
	operatingLeaseHref,
	onOperatingLeaseClick
}) => {
	const localize = useLocalize()

	return (
		<div
			className={classnames({
				[CLASSNAME]: true,
				[className]: !!className
			})}
		>
			<DealType isSelected={!isOperatingLeaseSelected} href={salesHref} onClick={onSalesClick}>
				{localize('DealTypeSwitch.sale')}
			</DealType>
			<DealType
				isSelected={isOperatingLeaseSelected}
				href={operatingLeaseHref}
				onClick={onOperatingLeaseClick}
			>
				{isOperatingLeaseSelected ? (
					<h1 className={`${CLASSNAME}__selected-heading`}>
						{localize('DealTypeSwitch.operatingLease')}
					</h1>
				) : (
					localize('DealTypeSwitch.operatingLease')
				)}
				<NewFeatureFlag className={`${CLASSNAME}__new-feature`} />
			</DealType>
		</div>
	)
}

DealTypeSwitch.propTypes = {
	className: PropTypes.string,
	isOperatingLeaseSelected: PropTypes.bool,
	salesHref: PropTypes.string,
	onSalesClick: PropTypes.func,
	operatingLeaseHref: PropTypes.string,
	onOperatingLeaseClick: PropTypes.func
}

const DealTypeSwitchWrapper = ({
	className,
	isOperatingLeaseSelected: isOperatingLeaseSelectedProps = false,
	context
}) => {
	const router = useRouter()
	const fire = useFire()
	const [isOperatingLeaseSelected, setIsOperatingLeaseSelected] = useState(
		isOperatingLeaseSelectedProps
	)

	const { params: routeParams = {}, route } = router.getCurrentRouteInfo()
	const routeName = route.getName()

	useEffect(() => {
		setIsOperatingLeaseSelected(isOperatingLeaseSelectedProps)
	}, [isOperatingLeaseSelectedProps])

	const handleDealTypeChangeWithRedirect = (url) => url && router.redirect(url)

	const handleDealTypeChange = (newIsOperatingLeaseSelected = false) => {
		setIsOperatingLeaseSelected(newIsOperatingLeaseSelected)
		fire('dealTypeChanged', newIsOperatingLeaseSelected)
	}

	const handleSalesClick = () => handleDealTypeChange(false)
	const handleOperatingLeaseClick = () => handleDealTypeChange(true)

	let additionalProps = {}

	if (context === FILTER_CONTEXT.ADVERT_LIST_SIDE_PANEL) {
		// v tomto pripade budeme pri zmene vybraneho typu prodeje hned menit URL

		const isSellerRoute = [
			ROUTE_NAMES.USERWEB.SELLER,
			ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES
		].includes(routeName)

		let salesHref
		let operatingLeaseHref

		if (isSellerRoute) {
			// detail prodejce
			salesHref = router.link(...getLink(ROUTE_NAMES.USERWEB.SELLER, routeParams))
			operatingLeaseHref = router.link(
				...getLink(ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES, routeParams)
			)
		} else {
			// jinak predpokladame, ze se jedna o obycejny vypis inzeratu
			salesHref = router.link(...getLink(ROUTE_NAMES.USERWEB.ADVERT_LIST, routeParams))
			operatingLeaseHref = router.link(
				...getLink(ROUTE_NAMES.USERWEB.ADVERT_LIST_WITH_OPERATING_LEASES, routeParams)
			)
		}

		additionalProps = {
			salesHref,
			operatingLeaseHref,
			onSalesClick: () => handleDealTypeChangeWithRedirect(salesHref),
			onOperatingLeaseClick: () => handleDealTypeChangeWithRedirect(operatingLeaseHref)
		}
	} else {
		additionalProps = {
			onSalesClick: handleSalesClick,
			onOperatingLeaseClick: handleOperatingLeaseClick
		}
	}

	return (
		<DealTypeSwitch
			className={className}
			isOperatingLeaseSelected={isOperatingLeaseSelected}
			{...additionalProps}
		/>
	)
}

DealTypeSwitchWrapper.propTypes = {
	className: PropTypes.string,
	isOperatingLeaseSelected: PropTypes.bool,
	context: PropTypes.string
}

export default DealTypeSwitchWrapper
