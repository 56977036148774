import React from 'react'
import PropTypes from 'prop-types'
import { DefaultProps as DEFAULT_PROPS } from '@inzeraty/helpers'
import LayoutAdmin from 'app/component/layout/admin/LayoutAdmin'
import STATE_KEYS from './ClientAdvertListStateKeys'
import ListHeader from './listHeader/ListHeader'
import AdvertList from './advertList/ClientAdminAdvertList'
import AdminMenu from 'app/component/adminMenu/AdminMenu'
import ClientBaseController from 'app/base/ClientBaseController'

import './ClientAdvertListView.less'

const CLASSNAME = 'p-uw-client-item-list'

const ClientAdvertListView = ({
	[STATE_KEYS.IS_OPERATING_LEASE]: isOperatingLease,
	[STATE_KEYS.ADVERT_LIST]: advertListData,
	[STATE_KEYS.IS_LOADING_STATISTICS]: isLoadingStatistics,
	[STATE_KEYS.STATISTICS]: statistics,
	[STATE_KEYS.IS_LOADING]: isLoading,
	[STATE_KEYS.FILTER_STATUS]: filterStatus,
	[ClientBaseController.STATE_KEYS.IS_PREMISE]: isPremise,
	[ClientBaseController.STATE_KEYS.PREMISE_INFO]: premiseInfo,
	[STATE_KEYS.SELECTED_ADVERTS]: selectedAdverts,
	[STATE_KEYS.PREMISE_FILTERS_FORM_LINES]: premiseFiltersFormLines,
	[STATE_KEYS.CHANGE_PREMISE_FILTERS_FORM_LINES]: changePremiseFiltersFormLines,
	[STATE_KEYS.LOAD_PREMISE_ADVERTS_TOTAL]: loadPremiseAdvertsTotal,
	[STATE_KEYS.LOAD_ALL_BRANDS_FOR_CATEGORY]: loadAllBrandsForCategory,
	[STATE_KEYS.LOAD_ALL_MODELS_FOR_BRAND]: loadAllModelsForBrand,
	[STATE_KEYS.BANNED_ADVERTS]: bannedAdverts
}) => {
	return (
		<LayoutAdmin
			isPremiseAdmin={isPremise}
			headerContent={isPremise ? <AdminMenu /> : null}
			className={CLASSNAME}
		>
			<div
				className={`${CLASSNAME}__content`}
				data-dot='my-adverts'
				data-dot-data={`{"isCompany": "${isPremise}"}`}
			>
				<ListHeader
					isPremise={isPremise}
					isLoading={isLoading}
					advertListData={advertListData}
					filterStatus={filterStatus}
					selectedAdvertsCount={selectedAdverts.size}
					premiseFiltersFormLines={isPremise ? premiseFiltersFormLines : undefined}
					changePremiseFiltersFormLines={isPremise ? changePremiseFiltersFormLines : undefined}
					loadPremiseAdvertsTotal={isPremise ? loadPremiseAdvertsTotal : undefined}
					loadAllBrandsForCategory={isPremise ? loadAllBrandsForCategory : undefined}
					loadAllModelsForBrand={isPremise ? loadAllModelsForBrand : undefined}
					bannedAdverts={bannedAdverts}
				/>
				<AdvertList
					isOperatingLease={isOperatingLease}
					isPremise={isPremise}
					isLoading={isLoading}
					advertListData={advertListData}
					isLoadingStatistics={isLoadingStatistics}
					statistics={statistics}
					filterStatus={filterStatus}
					selectedAdverts={selectedAdverts}
					premiseInfo={premiseInfo}
				/>
			</div>
		</LayoutAdmin>
	)
}

ClientAdvertListView.propTypes = {
	[STATE_KEYS.IS_OPERATING_LEASE]: PropTypes.bool,
	[STATE_KEYS.ADVERT_LIST]: PropTypes.shape({
		advertList: PropTypes.array,
		paginationEntity: PropTypes.object
	}).isRequired,
	[STATE_KEYS.IS_LOADING_STATISTICS]: PropTypes.bool,
	[STATE_KEYS.STATISTICS]: PropTypes.object
}

ClientAdvertListView.defaultProps = {
	[STATE_KEYS.ADVERT_LIST]: {
		advertList: DEFAULT_PROPS.ARRAY,
		paginationEntity: DEFAULT_PROPS.OBJECT
	},
	[STATE_KEYS.STATISTICS]: DEFAULT_PROPS.OBJECT
}

export default ClientAdvertListView
